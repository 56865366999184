<aw-wizard class="" id="part 1" @ngIfAnimation navBarLayout="large-filled-symbols">
  <aw-wizard-step [title]="('KEYWORD.patient' | translate | titlecase) + ' Name and Date of Birth'"
                  navigationSymbol="1">
    <div class="container" id="step-1">
      <div class="row clearfix">
        <div class="full-width rel">
          <!-- <div class="text-center"> -->
          <h2 class="text-center white rm-mt">First, let's just confirm it's you...</h2>
          <mat-card>
            <div class="clearfix" *ngIf="isPromoterOrAdmin == true">
              <p class="help-label"></p>
            </div>
            <hr class="mt mb" *ngIf="isPromoterOrAdmin == true"/>
            <!--- <p class="help-label">Please enter your Personal and Contact details into the form below.</p> -->
            <div class="row clearfix">
              <form #createForm="ngForm" name="form">
                <div class="column">
                  <div class="icons-col">
                    <mat-icon>person</mat-icon>
                  </div>
                  <div class="row-content-col">
                    <div class="clearfix">
                      <div class="half-width">
                        <div class="col-left">
                          <mat-form-field class="full-width" appearance="outline">
                            <mat-label>Gender</mat-label>

                            <mat-select class="full-width" [(ngModel)]="patient.gender" placeholder="Gender"
                                        name="gender" required>
                              <mat-option></mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="half-width">
                        <div class="col-right">
                          <mat-form-field class="full-width" appearance="outline">
                            <mat-label>Title</mat-label>
                            <mat-select class="full-width" [(ngModel)]="patient.title" placeholder="Title" name="title">
                              <mat-option *ngFor="let title of titles" [value]="title.Label"></mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                      </div>
                    </div>

                    <div class="row clearfix flex">
                      <!-- First Name -->
                      <mat-form-field class="full-width" appearance="outline">
                        <mat-label>First Name</mat-label>
                        <input class="mat-block" [(ngModel)]="patient.firstname" matInput
                               placeholder="{{ 'PLACEHOLDER.First Name' | translate }}" name="firstname" required/>
                      </mat-form-field>
                      <!-- Middle Name -->
                      <mat-form-field class="full-width" appearance="outline">
                        <mat-label>Middle Name</mat-label>
                        <input class="mat-block" [(ngModel)]="patient.middlename" matInput
                               placeholder="{{ 'PLACEHOLDER.Middle Name' | translate }}" name="middlename"/>
                      </mat-form-field>
                      <!-- Surname -->
                      <mat-form-field class="full-width" appearance="outline">
                        <mat-label>Surname</mat-label>
                        <input class="mat-block" [(ngModel)]="patient.lastname" matInput
                               placeholder="{{ 'PLACEHOLDER.Surname' | translate }}" name="lastzazazadaname" required/>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </mat-card>
          <!-- </div> -->
        </div>
      </div>

      <div class="row clearfix">
        <div class="step-button-row">
          <button class="btn-outline" (click)="cancel()" mat-raised-button color="primary">
            <mat-icon>arrow_back</mat-icon> Exit
          </button>
          <button class="btn-large" (click)="scrollTop()" mat-raised-button color="accent" awNextStep>
            Continue
            <mat-icon>arrow_forwards</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </aw-wizard-step>

  <aw-wizard-step [title]="('KEYWORD.patient' | translate | titlecase) + ' Name and Date of Birth'"
                  navigationSymbol="2">
    <div class="container" id="step-1">
      <div class="row clearfix">
        <div class="full-width rel">
          <!-- <div class="text-center"> -->
          <h2 class="white text-center rm-mt">... and confirm your date of birth</h2>
          <mat-card>
            <div class="row clearfix">
              <form #createForm="ngForm" name="form">
                <div class="column">
                  <div class="row clearfix">
                    <div class="icons-col">
                      <mat-icon>cake</mat-icon>
                    </div>
                    <div class="row-content-col">
                      <div class="row clearfix">
                        <div class="full-width" placeholder="Date of Birth">
                          <!-- Date of birth -->
                          <!-- <app-date-input [minAge]="1" [maxAge]="120" name="dob" [(ngModel)]="patient.dob" required></app-date-input> -->
                          <app-date-input [minAge]="1" [maxAge]="120" name="dob" required></app-date-input>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </mat-card>
          <!-- </div> -->
        </div>
      </div>

      <div class="row clearfix">
        <div class="step-button-row">
          <button class="btn-outline" (click)="scrollTop()" mat-raised-button awPreviousStep color="primary">
            <mat-icon>arrow_back</mat-icon> Back
          </button>
          <button class="btn-large" (click)="scrollTop()" mat-raised-button color="accent" awNextStep>
            Continue
            <mat-icon>arrow_forwards</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </aw-wizard-step>

  <aw-wizard-step navigationSymbol="3" title="Preliminary Questions">
    <div class="container" id="step-2">
      <div class="row clearfix">
        <div class="full-width rel">
          <div class="text-center">
            <h2 class="white text-center rm-mt">Preliminary Questions</h2>
            <mat-card>
              <div class="clearfix" *ngIf="isPromoterOrAdmin == true">
                <p class="help-label"></p>
              </div>
              <hr class="mt mb" *ngIf="isPromoterOrAdmin == true"/>

              <div class="row clearfix">
                <form #createForm="ngForm" name="form">
                  <div class="column">
                    <div class="icons-col">
                      <mat-icon class="fas fa-question"></mat-icon>
                    </div>
                    <div class="row-content-col">
                      <div class="row clearfix">
                        <label class="inline-radio pull-left"
                               style="margin-bottom: 10px; color: #777; font-size: 1rem">Are you completing this form on
                          behalf of a child, dependant or someone you care for?</label>
                        <mat-radio-group class="inline-radio pull-right" [(ngModel)]="dependant" name="dependant">
                          <mat-radio-button value="1">Yes</mat-radio-button>
                          <mat-radio-button value="0">No</mat-radio-button>
                        </mat-radio-group>
                      </div>
                      <div class="row clearfix">
                        <label class="inline-radio pull-left"
                               style="margin-bottom: 10px; color: #777; font-size: 1rem">Are you personally responsible
                          for
                          settlement of the account?
                        </label>
                        <mat-radio-group class="inline-radio pull-right">
                          <mat-radio-button value="1">Yes</mat-radio-button>
                          <mat-radio-button value="0">No</mat-radio-button>
                        </mat-radio-group>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </mat-card>
          </div>
        </div>
      </div>

      <div class="row clearfix">
        <div class="step-button-row">
          <button class="btn-outline" (click)="scrollTop()" mat-raised-button awPreviousStep color="primary">
            <mat-icon>arrow_back</mat-icon> Back
          </button>
          <button class="btn-large" (click)="scrollTop()" mat-raised-button color="accent" awNextStep>
            Continue
            <mat-icon>arrow_forwards</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </aw-wizard-step>

  <aw-wizard-step navigationSymbol="4" title="Contact Details">
    <div class="container" id="step-3">
      <div class="row clearfix">
        <div class="full-width rel">
          <div class="text-center">
            <h2 class="white text-center rm-mt">Contact Information</h2>
            <mat-card>
              <div class="clearfix" *ngIf="isPromoterOrAdmin == true">
                <p class="help-label"></p>
              </div>

              <hr class="mt mb" *ngIf="isPromoterOrAdmin == true"/>

              <div class="row clearfix">
                <form #createForm="ngForm" name="form">
                  <div class="column">
                    <div class="row">
                      <div class="icons-col">
                        <mat-icon>phone</mat-icon>
                      </div>
                      <div class="row-content-col">
                        <div class="row clearfix flex">
                          <app-phone-input class="full-width" [userDataName]="'patient.mobile'" [label]="'Mobile'"
                                           [required]="true" (validate)="validateMobile($event)">
                          </app-phone-input>
                          <app-phone-input class="full-width" [userDataName]="'patient.homePhone'" [isLandLine]="true"
                                           [label]="'Home'" [required]="false" (validate)="validateHomePhone($event)">
                          </app-phone-input>
                          <app-phone-input class="full-width" [userDataName]="'patient.workPhone'" [isLandLine]="true"
                                           [label]="'Work'" [required]="false" (validate)="validateWorkPhone($event)">
                          </app-phone-input>
                        </div>
                      </div>

                      <div class="icons-col">
                        <mat-icon>email</mat-icon>
                      </div>
                      <div class="row-content-col">
                        <!-- Email Address -->
                        <app-email-input class="full-width" [userDataName]="'patient.email'" [label]="'Email'"
                                         [required]="true" (getEmail)="consolePhone($event)"
                                         (validate)="validateEmail($event)">
                        </app-email-input>
                      </div>

                      <div class="row clearfix">
                        <div class="icons-col">
                          <mat-icon>ring_volume</mat-icon>
                        </div>
                        <div class="row-content-col">
                          <div class="row">
                            <!-- preferred contact method -->
                            <mat-form-field class="full-width" appearance="outline">
                              <mat-label>Preferred method of contact</mat-label>
                              <mat-select class="full-width" [(ngModel)]="patient.contactMethod"
                                          placeholder="Preferred method of contact" name="contactMethod" required>
                                <mat-option *ngFor="let prefContactMethod of prefContactMethods"></mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>
                      </div>

                      <div class="row clearfix">
                        <div class="icons-col">
                          <mat-icon>schedule</mat-icon>
                        </div>
                        <div class="row-content-col">
                          <div class="row">
                            <!-- Best contact time -->
                            <mat-form-field class="full-width" appearance="outline">
                              <mat-label>When is the best time to contact you?</mat-label>
                              <mat-select class="full-width" [(ngModel)]="patient.contactTime"
                                          placeholder="When is the best time to contact you?" name="contactTime"
                                          required>
                                <mat-option *ngFor="let contactTime of contactTimes"></mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </mat-card>
          </div>
        </div>
      </div>

      <div class="row clearfix">
        <div class="step-button-row">
          <button class="btn-outline" (click)="scrollTop()" mat-raised-button awPreviousStep color="primary">
            <mat-icon>arrow_back</mat-icon> Back
          </button>
          <button class="btn-large" (click)="scrollTop()" mat-raised-button color="accent" awNextStep>
            Continue
            <mat-icon>arrow_forwards</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </aw-wizard-step>

  <aw-wizard-step navigationSymbol="5" title="Address Details">
    <div class="container" id="step-4">
      <div class="row clearfix">
        <div class="full-width rel">
          <div class="text-center">
            <h2 class="white text-center rm-mt">Address</h2>
            <mat-card>
              <div class="clearfix" *ngIf="isPromoterOrAdmin == true">
                <p class="help-label"></p>
              </div>

              <hr class="mt mb" *ngIf="isPromoterOrAdmin == true"/>

              <div class="row clearfix">
                <form #createForm="ngForm" name="form">
                  <div class="column">
                    <app-address-input class="full-width"></app-address-input>
                  </div>

                  <hr class="mt mb"/>

                  <div class="row clearfix">
                    <div class="column">
                      <div class="icons-col">
                        <mat-icon>person_pin_circle</mat-icon>
                      </div>
                      <div class="row-content-col">
                        <div class="row clearfix">
                          <label style="margin-bottom: 10px; color: #777; font-size: 1rem">Same Postal Address as
                            above?</label>
                          <mat-radio-group class="alternate-theme" [(ngModel)]="samePostalAddress"
                                           name="samePostalAddress">
                            <mat-radio-button value="1">Yes</mat-radio-button>
                            <mat-radio-button value="0">No</mat-radio-button>
                          </mat-radio-group>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row clearfix animated fadeInDown" *ngIf="samePostalAddress == 0">
                    <hr class="mb"/>
                    <p class="help-label">Please enter your Postal Address details below.</p>
                    <div class="column">
                      <app-address-input class="full-width"></app-address-input>
                    </div>
                  </div>
                </form>
              </div>
            </mat-card>
          </div>
        </div>
      </div>

      <div class="row clearfix">
        <div class="step-button-row">
          <button class="btn-outline" (click)="scrollTop()" mat-raised-button awPreviousStep color="primary">
            <mat-icon>arrow_back</mat-icon> Back
          </button>
          <button class="btn-large" (click)="scrollTop()" mat-raised-button color="accent" awNextStep>
            Continue
            <mat-icon>arrow_forwards</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </aw-wizard-step>

  <aw-wizard-step navigationSymbol="6" title="Occupation">
    <div class="container" id="step-5">
      <div class="row clearfix">
        <div class="full-width rel">
          <div class="text-center">
            <h2 class="white text-center rm-mt">Occupation</h2>
            <mat-card>
              <div class="clearfix" *ngIf="isPromoterOrAdmin == true">
                <p class="help-label"></p>
              </div>

              <hr class="mt mb" *ngIf="isPromoterOrAdmin == true"/>

              <div class="row clearfix">
                <form #createForm="ngForm" name="form">
                  <div class="row clearfix">
                    <div class="icons-col">
                      <mat-icon>work</mat-icon>
                    </div>
                    <div class="row-content-col">
                      <div class="row clearfix">
                        <mat-form-field class="full-width" appearance="outline">
                          <mat-label>Occupation</mat-label>

                          <mat-select class="full-width" [(ngModel)]="patient.occupation" placeholder="Occupation"
                                      name="occupation" required>
                            <mat-option *ngFor="let o of occupations"></mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </mat-card>
          </div>
        </div>
      </div>

      <div class="row clearfix">
        <div class="step-button-row">
          <button class="btn-outline" (click)="scrollTop()" mat-raised-button awPreviousStep color="primary">
            <mat-icon>arrow_back</mat-icon> Back
          </button>
          <button class="btn-large" (click)="scrollTop()" mat-raised-button color="accent" awNextStep>
            Continue
            <mat-icon>arrow_forwards</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </aw-wizard-step>

  <aw-wizard-step navigationSymbol="7" title="Emergency Contact">
    <div class="container" id="step-6">
      <div class="row clearfix">
        <div class="full-width rel">
          <div class="text-center">
            <h2 class="white text-center rm-mt">Emergency Contact Details</h2>
            <mat-card>
              <div class="clearfix" *ngIf="isPromoterOrAdmin == true">
                <p class="help-label"></p>
              </div>

              <hr class="mt mb" *ngIf="isPromoterOrAdmin == true"/>

              <div class="row clearfix">
                <form #createForm="ngForm" name="form">
                  <div class="row clearfix">
                    <div class="column">
                      <div class="icons-col">
                        <mat-icon>person_add</mat-icon>
                      </div>
                      <div class="row-content-col">
                        <div class="row clearfix flex">
                          <!-- First Name -->
                          <mat-form-field class="full-width" appearance="outline">
                            <mat-label>First Name</mat-label>
                            <input class="mat-block" [(ngModel)]="emergency.firstname" matInput
                                   placeholder="{{ 'PLACEHOLDER.First Name' | translate }}" name="firstname" required/>
                          </mat-form-field>
                          <!-- Surname -->
                          <mat-form-field class="full-width" appearance="outline">
                            <mat-label>Surname</mat-label>
                            <input class="mat-block" [(ngModel)]="emergency.lastname" matInput
                                   placeholder="{{ 'PLACEHOLDER.Surname' | translate }}" name="lasgtgttname" required/>
                          </mat-form-field>
                        </div>
                      </div>

                      <div class="row clearfix">
                        <div class="icons-col">
                          <mat-icon>contact_phone</mat-icon>
                        </div>
                        <div class="row-content-col">
                          <div class="row clearfix flex">
                            <!-- Mobile Number -->
                            <app-phone-input class="full-width" [label]="'Mobile'" [userDataName]="'emergency.mobile'"
                                             [required]="true" (validate)="validateMobile($event)"></app-phone-input>
                            <app-phone-input class="full-width" [isLandLine]="true" [label]="'Home'"
                                             [userDataName]="'emergency.homePhone'" [required]="false"
                                             (validate)="validateHomePhone($event)"></app-phone-input>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="column">
                    <div class="icons-col">
                      <mat-icon>group</mat-icon>
                    </div>
                    <div class="row-content-col">
                      <div class="full-width">
                        <div class="col-left">
                          <div class="row clearfix flex">
                            <!-- Relationship Status -->
                            <mat-form-field class="full-width" appearance="outline">
                              <mat-label>Relationship</mat-label>

                              <mat-select class="full-width" [(ngModel)]="emergency.relationship"
                                          placeholder="Relationship" name="relationship" required>
                                <mat-option *ngFor="let relationStatus of relationStatuss"></mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </mat-card>
          </div>
        </div>
      </div>

      <div class="row clearfix">
        <div class="step-button-row">
          <button class="btn-outline" (click)="scrollTop()" mat-raised-button awPreviousStep color="primary">
            <mat-icon>arrow_back</mat-icon> Back
          </button>
          <button class="btn-large" (click)="scrollTop()" mat-raised-button color="accent" awNextStep>
            Continue
            <mat-icon>arrow_forwards</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </aw-wizard-step>

  <aw-wizard-step *ngIf="dependant == 1" navigationSymbol="8" title="Guardian Details">
    <div class="container" id="step-7">
      <div class="row clearfix">
        <div class="full-width rel">
          <div class="text-center">
            <h2 class="white text-center rm-mt">Guardian Information</h2>
            <mat-card>
              <div class="clearfix" *ngIf="isPromoterOrAdmin == true">
                <p class="help-label"></p>
              </div>

              <hr class="mt mb" *ngIf="isPromoterOrAdmin == true"/>

              <div class="row clearfix">
                <form #createForm="ngForm" name="form">
                  <!-- <div class="half-width"> -->
                  <div class="column">
                    <div class="icons-col">
                      <mat-icon>person</mat-icon>
                    </div>
                    <div class="row-content-col">
                      <div class="clearfix">
                        <div class="half-width">
                          <div class="col-left">
                            <!-- Gender Select -->
                            <mat-form-field class="full-width" appearance="outline">
                              <mat-label>Gender</mat-label>

                              <mat-select class="full-width" [(ngModel)]="guardian.gender" placeholder="Gender"
                                          name="gender" required>
                                <mat-option>
                                  <!--{{ gender.Label }}-->
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>

                        <div class="half-width">
                          <div class="col-right">
                            <!-- Title Select -->
                            <mat-form-field class="full-width" appearance="outline">
                              <mat-label>Title</mat-label>

                              <mat-select class="full-width" placeholder="Title">
                                <mat-option *ngFor="let title of titles" [(ngModel)]="guardian.title"
                                            [value]="title.Label" name="title" required>
                                  <!---{{ title.Label }}-->
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>
                      </div>

                      <div class="row clearfix flex">
                        <!-- First Name -->
                        <mat-form-field class="full-width" appearance="outline">
                          <mat-label>First Name</mat-label>
                          <input class="mat-block" [(ngModel)]="guardian.firstname" matInput placeholder="First Name"
                                 name="firstname" required/>
                        </mat-form-field>
                        <!-- Surname -->
                        <mat-form-field class="full-width" appearance="outline">
                          <mat-label>Last Name</mat-label>
                          <input class="mat-block" [(ngModel)]="guardian.lastname" matInput placeholder="Last Name"
                                 name="lastnagrgrgrme" required/>
                        </mat-form-field>
                      </div>
                    </div>

                    <div class="row clearfix">
                      <div class="icons-col">
                        <mat-icon>cake</mat-icon>
                      </div>
                      <div class="row-content-col">
                        <div class="row clearfix">
                          <div class="full-width" [(ngModel)]="guardian.dob" placeholder="Date of Birth" name="dob"
                               required>
                            <!-- Date of birth -->
                            <app-date-input [minAge]="18" [maxAge]="120"></app-date-input>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row clearfix">
                      <div class="icons-col">
                        <mat-icon>group</mat-icon>
                      </div>
                      <div class="row-content-col">
                        <div class="full-width">
                          <div class="row clearfix flex">
                            <!-- Relationship Status -->
                            <mat-form-field class="full-width" appearance="outline">
                              <mat-label>Relationship to {{ "KEYWORD.patient" | translate | titlecase }}</mat-label>

                              <mat-select class="full-width" [(ngModel)]="guardian.relationship"
                                          [placeholder]="'Relationship to ' + ('KEYWORD.patient' | translate | titlecase)"
                                          name="relationship"
                                          required>
                                <mat-option *ngFor="let relationStatus of relationStatuss"></mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>
                      </div>
                    </div>

                    <hr class="mt mb"/>

                    <div class="row clearfix">
                      <div class="column">
                        <div class="icons-col">
                          <mat-icon>contacts</mat-icon>
                        </div>
                        <div class="row-content-col">
                          <div class="row clearfix">
                            <label style="margin-bottom: 10px; color: #777; font-size: 1rem">Use the same contact
                              details as filled out for patient?</label>
                            <mat-radio-group class="alternate-theme" [(ngModel)]="sameContact" name="sameContact">
                              <mat-radio-button value="1">Yes</mat-radio-button>
                              <mat-radio-button value="0">No</mat-radio-button>
                            </mat-radio-group>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row" *ngIf="sameContact == 0">
                      <hr class="mb"/>
                      <div class="icons-col">
                        <mat-icon>phone</mat-icon>
                      </div>
                      <div class="row-content-col">
                        <div class="row clearfix flex">
                          <!-- Mobile Number -->
                          <app-phone-input class="full-width" [userDataName]="'guardian.mobile'" [label]="'Mobile'"
                                           [required]="true" (validate)="validateMobile($event)">
                          </app-phone-input>
                          <app-phone-input class="full-width" [userDataName]="'guardian.homePhone'" [isLandLine]="true"
                                           [label]="'Home'" [required]="false" (validate)="validateHomePhone($event)">
                          </app-phone-input>
                        </div>
                      </div>

                      <div class="icons-col">
                        <mat-icon>email</mat-icon>
                      </div>
                      <div class="row-content-col">
                        <!-- Email Address -->
                        <app-email-input class="full-width" [userDataName]="'guardian.email'" [label]="'Email'"
                                         [required]="true" (getEmail)="consolePhone($event)"
                                         (validate)="validateEmail($event)">
                        </app-email-input>
                      </div>
                    </div>

                    <hr class="mt mb"/>

                    <div class="row clearfix">
                      <div class="column">
                        <div class="icons-col">
                          <mat-icon>person_pin_circle</mat-icon>
                        </div>
                        <div class="row-content-col">
                          <div class="row clearfix">
                            <label style="margin-bottom: 10px; color: #777; font-size: 1rem">Use the same address
                              details as filled out for patient?</label>
                            <mat-radio-group class="alternate-theme" [(ngModel)]="sameAddressGuardian"
                                             name="sameAddressGuardian">
                              <mat-radio-button value="1">Yes</mat-radio-button>
                              <mat-radio-button value="0">No</mat-radio-button>
                            </mat-radio-group>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row clearfix full-width" *ngIf="sameAddressGuardian == 0">
                      <hr class="mb"/>
                      <!-- Address Input -->
                      <app-address-input class="full-width"></app-address-input>
                    </div>
                  </div>
                </form>
              </div>
            </mat-card>
          </div>
        </div>
      </div>

      <div class="row clearfix">
        <div class="step-button-row">
          <button class="btn-outline" (click)="scrollTop()" mat-raised-button awPreviousStep color="primary">
            <mat-icon>arrow_back</mat-icon> Back
          </button>
          <button class="btn-large" (click)="scrollTop()" mat-raised-button color="accent" awNextStep>
            Continue
            <mat-icon>arrow_forwards</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </aw-wizard-step>

  <aw-wizard-step navigationSymbol="9" title="Medical Details">
    <div class="container" id="step-8">
      <div class="row clearfix">
        <div class="full-width rel">
          <div class="text-center">
            <h2 class="white text-center rm-mt"> {{ 'PHRASE.medical practice' | translate | titlecase}}</h2>
            <mat-card>
              <div class="clearfix" *ngIf="isPromoterOrAdmin == true">
                <p class="help-label"></p>
              </div>

              <hr class="mt mb" *ngIf="isPromoterOrAdmin == true"/>

              <div class="row clearfix">
                <form #createForm="ngForm" name="form">
                  <div class="column">
                    <div class="icons-col">
                      <mat-icon class="fas fa-hospital"></mat-icon>
                    </div>

                    <div class="row-content-col">
                      <div class="row clearfix">
                        <label style="margin-bottom: 10px; color: #777; font-size: 1rem">Are you under the care of a GP
                          or Medical Practicioner?</label>
                        <mat-radio-group class="alternate-theme" [(ngModel)]="gP" name="gP">
                          <mat-radio-button value="1">Yes</mat-radio-button>
                          <mat-radio-button value="0">No</mat-radio-button>
                        </mat-radio-group>
                      </div>
                    </div>
                  </div>

                  <div class="row" *ngIf="gP == 1">
                    <hr class="mb"/>
                    <div class="row clearfix">
                      <div class="column">
                        <div class="icons-col">
                          <mat-icon class="fas fa-user-mat"></mat-icon>
                        </div>
                        <div class="row-content-col">
                          <div class="row clearfix">
                            <div class="half-width">
                              <div class="col-left">
                                <mat-form-field class="full-width" appearance="outline">
                                  <mat-label>First Name (Medical Practicioner)</mat-label>
                                  <input class="mat-block" [(ngModel)]="gp.firstname" matInput
                                         placeholder="First Name (Medical Practicioner)" name="firstname" required/>
                                </mat-form-field>
                              </div>
                            </div>
                            <div class="half-width">
                              <div class="col-right">
                                <mat-form-field class="full-width" appearance="outline">
                                  <mat-label>Last Name (Medical Practicioner)</mat-label>
                                  <input class="mat-block" [(ngModel)]="gp.lastname" matInput
                                         placeholder="Last Name (Medical Practicioner)" name="lastname" required/>
                                </mat-form-field>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row clearfix">
                      <div class="column">
                        <div class="icons-col">
                          <mat-icon>contact_phone</mat-icon>
                        </div>
                        <div class="row-content-col">
                          <div class="row clearfix">
                            <div class="half-width">
                              <div class="col-left">
                                <app-phone-input class="full-width" [(ngModel)]="gp.phone" [label]="'Contact Number'"
                                                 [required]="true" name="phone" required>
                                </app-phone-input>
                              </div>
                            </div>
                            <div class="half-width">
                              <div class="col-right">
                                <mat-form-field class="full-width" appearance="outline">
                                  <mat-label>Email</mat-label>
                                  <input class="mat-block" [(ngModel)]="gp.email" matInput placeholder="Email"
                                         name="email" required/>
                                </mat-form-field>
                              </div>
                            </div>
                          </div>
                        </div>
                        <app-address-input class="full-width"></app-address-input>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </mat-card>
          </div>
        </div>
      </div>
      <div class="row clearfix">
        <div class="step-button-row">
          <button class="btn-outline" (click)="scrollTop()" mat-raised-button awPreviousStep color="primary">
            <mat-icon>arrow_back</mat-icon> Back
          </button>
          <button class="btn-large" (click)="scrollTop()" mat-raised-button color="accent" awNextStep>
            Continue
            <mat-icon>arrow_forwards</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </aw-wizard-step>

  <aw-wizard-step navigationSymbol="10" title="Medical Questionnaire">
    <div class="container" id="step-9">
      <div class="row clearfix">
        <div class="full-width rel">
          <div class="text-center">
            <h2 class="white text-center rm-mt">Medical Questionnaire</h2>
            <mat-card>
              <div class="clearfix" *ngIf="isPromoterOrAdmin == true">
                <p class="help-label"></p>
              </div>

              <hr class="mt mb" *ngIf="isPromoterOrAdmin == true"/>
              <div class="row clearfix">
                <form #createForm="ngForm" name="form">
                  <div class="row clearfix">
                    <div class="row column">
                      <div class="icons-col">
                        <mat-icon>local_hospital</mat-icon>
                      </div>
                      <div class="row-content-col">
                        <div class="row">
                          <mat-form-field class="full-width" appearance="outline">
                            <mat-label>Have you had any of the following? Please tick those that apply and provide an
                              explanation.
                            </mat-label>

                            <mat-select class="full-width" [(ngModel)]="questions.injuries" [formControl]="symptom"
                              placeholder="Have you had any of the following? Please tick those that apply and provide an explanation."
                                        name="injuries" required multiple>
                              <mat-option *ngFor="let symptom of symptomList" [value]="symptom">{{
                                symptom
                                }}</mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                      </div>
                    </div>

                    <div class="row column">
                      <div class="icons-col">
                        <mat-icon class="fas fa-allergies"></mat-icon>
                      </div>
                      <div class="row-content-col">
                        <div class="row">
                          <mat-form-field class="full-width" appearance="outline">
                            <mat-label>Are you allergic or have you ever had a reaction to:</mat-label>

                            <mat-select class="full-width" [(ngModel)]="questions.allergies" [formControl]="allergy"
                                        placeholder="Are you allergic or have you ever had a reaction to:"
                                        name="allergies"
                                        required multiple>
                              <mat-option *ngFor="let allergy of allergyList" [value]="allergy">{{
                                allergy
                                }}</mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                      </div>
                    </div>

                    <div class="row-content-col">
                      <div class="row clearfix">
                        <label class="inline-radio pull-left"
                               style="margin-bottom: 10px; color: #777; font-size: 1rem">Do you smoke? Please note below
                          how
                          many cigarettes you smoke per day.</label>
                        <mat-radio-group class="inline-radio pull-right">
                          <mat-radio-button value="1">Yes</mat-radio-button>
                          <mat-radio-button value="0">No</mat-radio-button>
                        </mat-radio-group>
                      </div>

                      <div class="row clearfix">
                        <label class="inline-radio pull-left"
                               style="margin-bottom: 10px; color: #777; font-size: 1rem">Do you drink alcohol?</label>
                        <mat-radio-group class="inline-radio pull-right">
                          <mat-radio-button value="1">Yes</mat-radio-button>
                          <mat-radio-button value="0">No</mat-radio-button>
                        </mat-radio-group>
                      </div>

                      <div class="row clearfix">
                        <label class="inline-radio pull-left"
                               style="margin-bottom: 10px; color: #777; font-size: 1rem">Are you pregnant?</label>
                        <mat-radio-group class="inline-radio pull-right">
                          <mat-radio-button value="1">Yes</mat-radio-button>
                          <mat-radio-button value="0">No</mat-radio-button>
                        </mat-radio-group>
                      </div>

                      <div class="row clearfix">
                        <label class="inline-radio pull-left"
                               style="margin-bottom: 10px; color: #777; font-size: 1rem">Are you currently taking any
                          birth
                          control or hormone replacement medication?</label>
                        <mat-radio-group class="inline-radio pull-right">
                          <mat-radio-button value="1">Yes</mat-radio-button>
                          <mat-radio-button value="0">No</mat-radio-button>
                        </mat-radio-group>
                      </div>

                      <div class="row clearfix">
                        <label class="inline-radio pull-left"
                               style="margin-bottom: 10px; color: #777; font-size: 1rem">Have you had any serious
                          illness in
                          the last 2 years?</label>
                        <mat-radio-group class="inline-radio pull-right">
                          <mat-radio-button value="1">Yes</mat-radio-button>
                          <mat-radio-button value="0">No</mat-radio-button>
                        </mat-radio-group>
                      </div>

                      <div class="row clearfix">
                        <label class="inline-radio pull-left"
                               style="margin-bottom: 10px; color: #777; font-size: 1rem">Are you currently taking any
                          medication or tablets regularly?</label>
                        <mat-radio-group class="inline-radio pull-right">
                          <mat-radio-button value="1">Yes</mat-radio-button>
                          <mat-radio-button value="0">No</mat-radio-button>
                        </mat-radio-group>
                      </div>

                      <div class="row clearfix">
                        <label class="inline-radio pull-left"
                               style="margin-bottom: 10px; color: #777; font-size: 1rem">Are you from a different
                          cultural,
                          linguistic, religious or other background?</label>
                        <mat-radio-group class="inline-radio pull-right">
                          <mat-radio-button value="1">Yes</mat-radio-button>
                          <mat-radio-button value="0">No</mat-radio-button>
                        </mat-radio-group>
                      </div>

                      <mat-form-field class="full-width" appearance="outline">
                        <mat-label>Leave a comment</mat-label>
                        <textarea [(ngModel)]="questions.comment1" matInput placeholder="Leave a comment"
                                  name="comment1"></textarea>
                      </mat-form-field>
                    </div>
                  </div>
                </form>
              </div>
            </mat-card>
          </div>
        </div>
      </div>
      <div class="row clearfix">
        <div class="step-button-row">
          <button class="btn-outline" (click)="scrollTop()" mat-raised-button awPreviousStep color="primary">
            <mat-icon>arrow_back</mat-icon> Back
          </button>
          <button class="btn-large" (click)="scrollTop()" mat-raised-button color="accent" awNextStep>
            Continue
            <mat-icon>arrow_forwards</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </aw-wizard-step>

  <aw-wizard-step [title]="('KEYWORD.dental' | translate | titlecase) + ' Questionnaire'" navigationSymbol="11">
    <div class="container" id="step-10">
      <div class="row clearfix">
        <div class="full-width rel">
          <div class="text-center">
            <h2 class="white text-center rm-mt">{{ "KEYWORD.dental" | translate | titlecase }} Questionnaire</h2>
            <mat-card>
              <div class="clearfix" *ngIf="isPromoterOrAdmin == true">
                <p class="help-label"></p>
              </div>

              <hr class="mt mb" *ngIf="isPromoterOrAdmin == true"/>
              <div class="row clearfix">
                <form #createForm="ngForm" name="form">
                  <div class="row clearfix">
                    <div class="row column">
                      <div class="icons-col">
                        <mat-icon class="fas {{'fa-tooth' | translate}}"></mat-icon>
                      </div>
                      <div class="row-content-col">
                        <div class="row">
                          <mat-form-field class="full-width" appearance="outline">
                            <mat-label>Have you suffered with any of the following dental problems? Please tick those
                              that
                              apply.
                            </mat-label>

                            <mat-select class="full-width" [(ngModel)]="questions.dentalInjuries" [formControl]="dental"
                              placeholder="Have you suffered with any of the following dental problems? Please tick those that apply."
                                        name="dentalInjuries" required multiple>
                              <mat-option *ngFor="let dental of dentalList" [value]="dental">{{ dental }}</mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row clearfix">
                    <div class="column">
                      <div class="row-content-col">
                        <div class="clearfix">
                          <div class="half-width">
                            <div class="col-left">
                              <mat-form-field class="full-width" appearance="outline">
                                <mat-label>How long since your last dental appointment?</mat-label>

                                <mat-select class="full-width" [(ngModel)]="questions.dentalLast"
                                            placeholder="How long since your last dental appointment?" name="dentalLast"
                                            required>
                                  <mat-option *ngFor="let duration of durations" [value]="duration">{{ duration }}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                            </div>
                          </div>
                          <div class="half-width">
                            <div class="col-right">
                              <mat-form-field class="full-width" appearance="outline">
                                <mat-label>How often do you have a dental examination?</mat-label>

                                <mat-select class="full-width" [(ngModel)]="questions.dentalFreq"
                                            placeholder="How often do you have a dental examination?" name="dentalFreq"
                                            required>
                                  <mat-option *ngFor="let duration of durations" [value]="duration">{{ duration }}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row-content-col">
                    <div class="row clearfix">
                      <label class="inline-radio pull-left"
                             style="margin-bottom: 10px; color: #777; font-size: 1rem">Have you had any dental x-rays in
                        the
                        last 12 months?</label>
                      <mat-radio-group class="inline-radio pull-right">
                        <mat-radio-button value="1">Yes</mat-radio-button>
                        <mat-radio-button value="0">No</mat-radio-button>
                      </mat-radio-group>
                    </div>

                    <div class="row clearfix">
                      <label class="inline-radio pull-left" style="margin-bottom: 10px; color: #777; font-size: 1rem">Do
                        you wear a dental night guard?</label>
                      <mat-radio-group class="inline-radio pull-right">
                        <mat-radio-button value="1">Yes</mat-radio-button>
                        <mat-radio-button value="0">No</mat-radio-button>
                      </mat-radio-group>
                    </div>

                    <div class="row clearfix">
                      <label class="inline-radio pull-left"
                             style="margin-bottom: 10px; color: #777; font-size: 1rem">Have you ever had peridontal
                        (gum)
                        {{"KEYWORD.treatment" | translate}}?</label>
                      <mat-radio-group class="inline-radio pull-right">
                        <mat-radio-button value="1">Yes</mat-radio-button>
                        <mat-radio-button value="0">No</mat-radio-button>
                      </mat-radio-group>
                    </div>

                    <div class="row clearfix">
                      <label class="inline-radio pull-left"
                             style="margin-bottom: 10px; color: #777; font-size: 1rem">Have you ever had your bite
                        adjusted?</label>
                      <mat-radio-group class="inline-radio pull-right">
                        <mat-radio-button value="1">Yes</mat-radio-button>
                        <mat-radio-button value="0">No</mat-radio-button>
                      </mat-radio-group>
                    </div>

                    <div class="row clearfix">
                      <label class="inline-radio pull-left" style="margin-bottom: 10px; color: #777; font-size: 1rem">Do
                        you bite your lips or cheeks often?</label>
                      <mat-radio-group class="inline-radio pull-right">
                        <mat-radio-button value="1">Yes</mat-radio-button>
                        <mat-radio-button value="0">No</mat-radio-button>
                      </mat-radio-group>
                    </div>

                    <div class="row clearfix">
                      <label class="inline-radio pull-left" style="margin-bottom: 10px; color: #777; font-size: 1rem">Do
                        your teeth hurt when you bite hard?</label>
                      <mat-radio-group class="inline-radio pull-right">
                        <mat-radio-button value="1">Yes</mat-radio-button>
                        <mat-radio-button value="0">No</mat-radio-button>
                      </mat-radio-group>
                    </div>

                    <div class="row clearfix">
                      <label class="inline-radio pull-left" style="margin-bottom: 10px; color: #777; font-size: 1rem">Do
                        you usually need antibiotics before {{"PHRASE.dental treatment" | translate}}?</label>
                      <mat-radio-group class="inline-radio pull-right">
                        <mat-radio-button value="1">Yes</mat-radio-button>
                        <mat-radio-button value="0">No</mat-radio-button>
                      </mat-radio-group>
                    </div>

                    <div class="row clearfix">
                      <label class="inline-radio pull-left"
                             style="margin-bottom: 10px; color: #777; font-size: 1rem">Have you ever had any adverse or
                        allergics reactions to previous {{"PHRASE.dental treatment" | translate}}?</label>
                      <mat-radio-group class="inline-radio pull-right">
                        <mat-radio-button value="1">Yes</mat-radio-button>
                        <mat-radio-button value="0">No</mat-radio-button>
                      </mat-radio-group>
                    </div>

                    <div class="row clearfix">
                      <label class="inline-radio pull-left" style="margin-bottom: 10px; color: #777; font-size: 1rem">Is
                        there anything else you would like us to know? (Please note below).</label>
                      <mat-radio-group class="inline-radio pull-right">
                        <mat-radio-button value="1">Yes</mat-radio-button>
                        <mat-radio-button value="0">No</mat-radio-button>
                      </mat-radio-group>
                    </div>

                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Leave a comment</mat-label>
                      <textarea [(ngModel)]="questions.comment2" matInput placeholder="Leave a comment"
                                name="comment2"></textarea>
                    </mat-form-field>
                  </div>
                </form>
              </div>
            </mat-card>
          </div>
        </div>
      </div>
      <div class="row clearfix">
        <div class="step-button-row">
          <button class="btn-outline" (click)="scrollTop()" mat-raised-button awPreviousStep color="primary">
            <mat-icon>arrow_back</mat-icon> Back
          </button>
          <button class="btn-large" (click)="scrollTop()" mat-raised-button color="accent" awNextStep>
            Continue
            <mat-icon>arrow_forwards</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </aw-wizard-step>

  <aw-wizard-step navigationSymbol="12" title="Insurance and Medicare">
    <div class="container" id="step-11">
      <div class="row clearfix">
        <div class="full-width rel">
          <div class="text-center">
            <h2 class="white text-center rm-mt">Insurance and Medicare Information</h2>
            <mat-card>
              <div class="clearfix" *ngIf="isPromoterOrAdmin == true">
                <p class="help-label"></p>
              </div>
              <hr class="mt mb" *ngIf="isPromoterOrAdmin == true"/>

              <div class="row clearfix">
                <form #createForm="ngForm" name="form">
                  <div class="row-content-col">
                    <div class="row clearfix">
                      <label class="inline-radio pull-left" style="margin-bottom: 10px; color: #777; font-size: 1rem">Do
                        you have Health Insurance?</label>
                      <mat-radio-group class="inline-radio pull-right" [(ngModel)]="healthInsurance"
                                       name="healthInsurance">
                        <mat-radio-button value="1">Yes</mat-radio-button>
                        <mat-radio-button value="0">No</mat-radio-button>
                      </mat-radio-group>
                    </div>
                  </div>

                  <div class="row clearfix" *ngIf="healthInsurance == 1">
                    <hr class="mb"/>
                    <div class="column">
                      <div class="icons-col">
                        <mat-icon class="fas fa-user-injured"></mat-icon>
                      </div>
                      <div class="row-content-col">
                        <div class="row">
                          <div class="half-width">
                            <div class="col-left">
                              <mat-form-field class="full-width" appearance="outline">
                                <mat-label>Health Fund Name</mat-label>

                                <mat-select class="full-width" [(ngModel)]="health.insuranceName"
                                            placeholder="Health Fund Name" name="insuranceName" required>
                                  <mat-option *ngFor="let insurer of insurers" [value]="insurer">{{ insurer }}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                            </div>
                          </div>
                          <div class="half-width">
                            <div class="col-right">
                              <mat-form-field class="full-width" appearance="outline">
                                <mat-label>Health Insurance Number</mat-label>

                                <mat-form-field class="full-width" appearance="outline">
                                  <input [(ngModel)]="health.insuranceNumber" matInput
                                         placeholder="Health Insurance Number" name="insuranceNumber" required/>
                                </mat-form-field>
                              </mat-form-field>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr class="mt mb"/>

                  <div class="row-content-col">
                    <div class="row clearfix">
                      <label class="inline-radio pull-left" style="margin-bottom: 10px; color: #777; font-size: 1rem">Do
                        you have a Health Card?</label>
                      <mat-radio-group class="inline-radio pull-right" [(ngModel)]="medicare" name="medicare">
                        <mat-radio-button value="1">Yes</mat-radio-button>
                        <mat-radio-button value="0">No</mat-radio-button>
                      </mat-radio-group>
                    </div>
                  </div>

                  <div class="row clearfix" *ngIf="medicare == 1">
                    <hr class="mb"/>
                    <div class="column">
                      <div class="icons-col">
                        <mat-icon class="fas fa-id-card"></mat-icon>
                      </div>
                      <div class="row-content-col">
                        <div class="row">
                          <div class="half-width">
                            <div class="col-left">
                              <mat-form-field class="full-width" appearance="outline">
                                <mat-label>Card Type</mat-label>

                                <mat-select class="full-width" [(ngModel)]="health.medicardType" placeholder="Card Type"
                                            name="medicardType" required>
                                  <mat-option *ngFor="let cardType of cardTypes" [value]="cardType">{{ cardType }}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                            </div>
                          </div>
                          <div class="half-width">
                            <div class="col-right">
                              <mat-form-field class="full-width" appearance="outline">
                                <mat-label>Card Number</mat-label>
                                <input [(ngModel)]="health.cardNumber" matInput placeholder="Card Number"
                                       name="Card Number" required/>
                              </mat-form-field>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </mat-card>
          </div>
        </div>
      </div>
      <div class="row clearfix">
        <div class="step-button-row">
          <button class="btn-outline" (click)="scrollTop()" mat-raised-button awPreviousStep color="primary">
            <mat-icon>arrow_back</mat-icon> Back
          </button>
          <button class="btn-large" (click)="scrollTop()" mat-raised-button color="accent" awNextStep>
            Continue
            <mat-icon>arrow_forwards</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </aw-wizard-step>

  <aw-wizard-step navigationSymbol="13" title="Payment Details">
    <div class="container" id="step-12">
      <div class="row clearfix">
        <div class="full-width rel">
          <div class="text-center">
            <h2 class="white text-center rm-mt">Payment Details</h2>
            <mat-card>
              <div class="clearfix" *ngIf="isPromoterOrAdmin == true">
                <p class="help-label"></p>
              </div>
              <hr class="mt mb" *ngIf="isPromoterOrAdmin == true"/>
              <div class="row clearfix">
                <form #createForm="ngForm" name="form">
                  <div class="column">
                    <div class="icons-col">
                      <mat-icon class="fas fa-dollar-sign"></mat-icon>
                    </div>
                    <div class="row-content-col">
                      <div class="row clearfix">
                        <label style="margin-bottom: 10px; color: #777; font-size: 1rem">Preferred Method of
                          Payment:</label>
                        <mat-radio-group class="alternate-theme">
                          <mat-radio-button value="1">Cash</mat-radio-button>
                          <mat-radio-button value="0">Credit/Debit Card</mat-radio-button>
                        </mat-radio-group>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </mat-card>
          </div>
        </div>
      </div>

      <div class="row clearfix">
        <div class="step-button-row">
          <button class="btn-outline" (click)="scrollTop()" mat-raised-button awPreviousStep color="primary">
            <mat-icon>arrow_back</mat-icon> Back
          </button>
          <button class="btn-large" (click)="scrollTop()" mat-raised-button color="accent" awNextStep>
            Continue
            <mat-icon>arrow_forwards</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </aw-wizard-step>

  <aw-wizard-step navigationSymbol="14" title="Privacy Policy">
    <div class="container" id="step-13">
      <div class="row clearfix">
        <div class="full-width rel">
          <div class="text-center">
            <h2 class="white text-center rm-mt">Privacy Policy</h2>
            <mat-card>
              <div class="clearfix" *ngIf="isPromoterOrAdmin == true">
                <p class="help-label"></p>
              </div>
              <hr class="mt mb" *ngIf="isPromoterOrAdmin == true"/>

              <div class="row clearfix">
                <div class="row clearfix">
                  <p class="larger">
                    YOUR PERSONAL INFORMATION WILL NOT BE SHARED, SOLD, RENTED OR DISCLOSED OTHER THAN AS DESCRIBED IN
                    THIS PRIVACY POLICY.
                  </p>
                </div>
                <div class="row clearfix mt">
                  <div class="column">
                    <div class="row-content-col">
                      <p class="rm-mt">
                        By continuing to use this site you consent to us (Smileright Pty Ltd ACN 601 445 041, our
                        related bodies corporate, affiliates and agents) and some other entities collecting, using,
                        holding and disclosing personal information and credit-related information about you. You can
                        find out more about how we deal with your privacy by viewing our privacy policy at
                        http://smileright.com.au/privacy. We may seek and obtain further personal information (including
                        sensitive information) and credit-related information about you during the course of our
                        dealings with you. The terms of this consent apply to the collection, use and disclosure of that
                        information. If you do not provide us with this consent or provide us with your personal
                        information and credit-related information we may not be able to arrange finance for you or
                        provide other services.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="row clearfix">
                  <p class="larger">
                    BY USING THE SITE, YOU AGREE TO PRIVACY POLICY; IF YOU DO NOT AGREE, DO NOT USE THE SITE.
                  </p>
                </div>
                <div class="row clearfix mt">
                  <div class="column">
                    <div class="row-content-col">
                      <p class="rm-mt">
                        We may collect, use, hold and disclose personal information, and credit-related information
                        about you for the purposes of arranging or providing credit to you, assessing whether to accept
                        a guarantee of credit from you, managing that credit, direct marketing of products and services
                        by us, complying with laws and managing our relationship with you. We may collect this
                        information from you or from third parties. We are also required to collect your personal
                        information to comply with our obligations under Australian law, including the Anti-Money
                        Laundering and Counter-Terrorism Financing Act 2006 (Cth). Credit information includes
                        information such as the type and amount of credit provided to you, repayment history
                        information, default information (including overdue payments) and court information. Credit
                        eligibility information is credit reporting information supplied to us by a credit reporting
                        body, and any information that we derive from it. Collectively, we refer to this as
                        ‘credit-related information'. Personal information includes any information or an opinion from
                        which your identity is apparent or reasonably apparent.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="row clearfix">
                  <p class="larger">Privacy Policies</p>
                </div>
                <div class="row clearfix mt">
                  <div class="column">
                    <div class="row-content-col">
                      <p class="rm-mt">
                        You may gain access to the personal information and credit-related information that we hold
                        about you by contacting us. A copy of our privacy policy can be obtained at
                        http://smileright.com.au/privacy, or by contacting us on {{ 'BRANDING.Contact Phone' | translate
                        }}. Links to the privacy
                        policies and credit reporting policies of our credit reporting bodies (CRBs) are shown at the
                        end of this consent. The privacy policies and credit reporting policies contain information
                        about how you may access or seek correction of your personal information and credit-related
                        information, how that information is managed, how you may complain about a breach of your
                        privacy and how that complaint will be dealt with. They also contain information on ‘notifiable
                        matters' including things such as the information we use to assess your creditworthiness, the
                        fact that CRBs may provide your personal information and credit-related information to credit
                        providers to assist in an assessment of your credit worthiness, what happens if you fail to meet
                        your credit obligations or commit a serious credit infringement - including our right to report
                        a default or a serious credit infringement to CRBs, your right to request that CRBs not use your
                        credit-related information for the purposes of pre-screening credit offers, and your right to
                        request a CRB not to use or disclose credit-related information about you if you believe you are
                        a victim of fraud.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="row clearfix">
                  <div class="column">
                    <div class="row-content-col">
                      <p class="rm-mt">
                        All information you have given to us is accurate and not misleading. <br/>
                        You acknowledge that we are relying on that information to enter this agreement.
                      </p>
                    </div>
                  </div>
                </div>

                <mat-dialog-actions class="borderTop">
                  <div class="full-width row clearfix text-center">
                    <button class="btn-clear btn-small" mat-button>View Privacy Policy</button>
                    <mat-slide-toggle class="slide-toggle-large alternate-theme" [(ngModel)]="isAgree">
                      <span class="content-label">I have read and agree to the Privacy Policy</span>
                    </mat-slide-toggle>
                  </div>
                </mat-dialog-actions>
              </div>
            </mat-card>
          </div>
        </div>
      </div>

      <div class="row clearfix">
        <div class="step-button-row">
          <button class="btn-outline" (click)="scrollTop()" mat-raised-button awPreviousStep color="primary">
            <mat-icon>arrow_back</mat-icon> Back
          </button>
          <button class="btn-large" [disabled]="!isAgree" (click)="scrollTop()" mat-raised-button color="accent"
                  awNextStep>
            Continue
            <mat-icon>arrow_forwards</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </aw-wizard-step>

  <aw-wizard-completion-step navigationSymbol="15" title="Conclusion">
    <div class="container" id="step-14">
      <div class="row clearfix">
        <div class="full-width rel">
          <div class="text-center">
            <h2 class="white text-center rm-mt">Consent of Service Agreement</h2>
            <mat-card>
              <div class="clearfix" *ngIf="isPromoterOrAdmin == true"></div>
              <hr class="mt mb" *ngIf="isPromoterOrAdmin == true"/>

              <div class="row clearfix">
                <div class="row clearfix">
                  <p class="larger">By filling out this form, you have made the following declarations:</p>
                </div>
                <div class="row clearfix mt">
                  <div class="column">
                    <div class="row-content-col">
                      <p class="rm-mt">You have carefully read this form and the accompanying terms and conditions.</p>
                    </div>
                  </div>
                </div>
                <div class="row clearfix">
                  <div class="column">
                    <div class="row-content-col">
                      <p class="rm-mt">
                        All information you have given to us is accurate and not misleading. <br/>
                        You acknowledge that we are relying on that information to enter this agreement.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="row clearfix">
                  <div class="row clearfix">
                    <p class="lead rm-mt rm-mb"><em></em></p>
                    <p class="small">Please click to agree to the declarations to complete your application.</p>
                    <mat-divider></mat-divider>
                    <mat-slide-toggle class="slide-toggle-large alternate-theme" [(ngModel)]="isAgreed">
                      I make the declarations above
                    </mat-slide-toggle>
                  </div>
                </div>
              </div>
            </mat-card>
          </div>
        </div>
      </div>
      <div class="row clearfix">
        <div class="step-button-row">
          <button class="btn-outline" (click)="scrollTop()" mat-raised-button awPreviousStep color="primary">
            <mat-icon>arrow_back</mat-icon>Back
          </button>
          <button class="btn-large" [disabled]="!isAgreed" (click)="finishFunction()" mat-raised-button color="accent">
            Submit
            <mat-icon>arrow_forwards</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </aw-wizard-completion-step>
</aw-wizard>
