import { BehaviorSubject, merge as observableMerge, Observable } from 'rxjs';

import { DataSource } from '@angular/cdk/table';
import { Component, ElementRef, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { map } from 'rxjs/operators';
import { CustomDatePipe } from '../../../shared/pipes/custom-date.pipe';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { TreatmentProductEditMerchantComponent } from '../../treatment/treatment-product-edit-merchant/treatment-product-edit-merchant.component';
import { PriceServiceEditComponent } from '../price-service-edit/price-service-edit.component';
import { PriceServiceViewComponent } from '../price-service-view/price-service-view.component';
import { PriceServiceService } from '../shared/price-service.service';

@Component({
  selector: 'app-product-service-list',
  templateUrl: './price-service-list.component.html',
  styleUrls: ['./price-service-list.component.css'],
})
export class PriceServiceListComponent implements OnInit {
  @Input()
  pageSize = Settings.global['listPageSize'] || 20;
  pageSizeOptions = [10, Number(this.pageSize), Number(this.pageSize) * 2, Number(this.pageSize) * 3];

  utils = new UtilsClass();
  displayedColumns = ['ServiceCategory', 'ServiceType', 'Brand', 'PriceEffectiveSale', 'PriceEffectiveCost', 'Actions'];

  filters = [];

  public listDB: LoadRecords | null;
  dataSource: RecordDataSource | null;

  filteredSize = null;

  destroyEvent = new EventEmitter();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('filter', { static: false }) filter: ElementRef;

  constructor(
    private priceServiceService: PriceServiceService,
    private dialog: MatDialog,
    private customDate: CustomDatePipe
  ) {}

  ngOnInit() {
    this.listDB = new LoadRecords(this.priceServiceService, this.destroyEvent);
    this.dataSource = new RecordDataSource(this.listDB, this.paginator, this.sort);
  }

  editModal() {
    const ref = this.dialog.open(TreatmentProductEditMerchantComponent, {
      width: '800px',
    });
  }

  openQuickViewDialog(id) {
    const ref = this.dialog.open(PriceServiceViewComponent, {
      width: '800px',
    });
  }

  openPriceListModal(row) {
    const ref = this.dialog.open(PriceServiceEditComponent, {
      data: row['ID'],
      width: '800px',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close;
    });

    ref.componentInstance.saveObject.subscribe((res) => {
      if (res) {
        row = res;
      }
    });
  }

  setFilter(event, field) {
    let filter;
    if (typeof event == 'object' && event != null) {
      const v = this.customDate.transform(event, Settings.global['dateFormat']);
      filter = {
        field,
        value: v,
      };
    } else if (event) {
      filter = {
        field,
        value: event,
      };
    } else {
      filter = {
        field,
        value: '',
      };
    }

    this.dataSource.filter = filter;
    this.filteredSize = this.dataSource.filteredData.length;
  }

  ngOnDestroy() {
    this.destroyEvent.emit(true);
  }
}

export class LoadRecords implements OnInit {
  public dataChange: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  items: any;
  count: any;

  get data(): any[] {
    return this.dataChange.value;
  }

  isDestoyed = false;

  serviceRef;

  constructor(private priceServiceService: PriceServiceService, private destroyEvent) {
    this.destroyEvent.subscribe((res) => {
      this.isDestoyed = res;
    });

    const payload = {
      section: 0,
    };

    this.serviceRef = this.priceServiceService.getPriceServiceList(payload).subscribe((res) => {
      this.count = res.length;
      this.items = res;

      this.dataChange.next(this.items.slice());

      const innerFunction = (section) => {
        section = section + 1;
        UtilsClass.loadingDataSection(section);
        payload.section = section;

        this.serviceRef = this.priceServiceService.getPriceServiceList(payload).subscribe((res) => {
          if (res.length > 0) {
            this.count = res.count;
            this.items = this.items.concat(res);

            this.dataChange.next(this.items.slice());
            if (this.isDestoyed != true) {
              innerFunction(section);
            }
          } else {
            return true;
          }
        });
        UtilsClass.stopLoadingInterceptor();
      };

      if (this.isDestoyed != true) {
        innerFunction(1);
      }
    });
  }

  ngOnInit() {}

  ngOnDestroy() {
    if (this.serviceRef) {
      this.serviceRef.unsubscribe();
    }
  }
}

export class RecordDataSource extends DataSource<any> {
  _filterChange = new BehaviorSubject('');
  field = '';

  get filter(): any {
    return this._filterChange.value;
  }

  set filter(item: any) {
    this.field = item.field;
    this._filterChange.next(item.value);
  }

  filteredData: any[] = [];

  constructor(private _tableDatabase: LoadRecords, private _paginator: MatPaginator, private _sort: MatSort) {
    super();
    this._filterChange.subscribe(() => (this._paginator.pageIndex = 0));
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<any[]> {
    const displayDataChanges = [
      this._tableDatabase.dataChange,
      this._filterChange,
      this._paginator.page,
      this._sort.sortChange,
    ];

    return observableMerge(...displayDataChanges).pipe(
      map(() => {
        // Filter data
        this.filteredData = this._tableDatabase.data.slice().filter((item: any) => {
          let f = '';

          f = item[this.field] || '';

          const searchStr = f.toLowerCase();
          return searchStr.indexOf(this.filter.toLowerCase()) != -1;
        });
        const data = this.getSortedData(this.filteredData.slice());
        // Grab the page's slice of data.
        const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
        return data.splice(startIndex, this._paginator.pageSize);
      })
    );
  }

  disconnect() {}

  /** Returns a sorted copy of the database data. */
  getSortedData(data: any[]): any[] {
    // const data = data;
    if (!this._sort.active || this._sort.direction == '') {
      return data;
    }

    return data.sort((a, b) => {
      let propertyA: number | string = '';
      let propertyB: number | string = '';

      [
        'ServiceCategory',
        'ServiceType',
        'Brand',
        'PriceEffectiveSale',
        'PriceEffectiveCost',
        // "descriptionDiagnosis",
        // "descriptionTreatment"
      ];

      switch (this._sort.active) {
        case 'ServiceCategory':
          [propertyA, propertyB] = [a['ServiceCategory'], b['ServiceCategory']];
          break;
        case 'ServiceType':
          [propertyA, propertyB] = [a['ServiceType'], b['ServiceType']];
          break;
        case 'Brand':
          [propertyA, propertyB] = [a['Brand'], b['Brand']];
          break;
        case 'PriceEffectiveSale':
          [propertyA, propertyB] = [a['PriceEffectiveSale'], b['PriceEffectiveSale']];
          break;
        case 'PriceEffectiveCost':
          [propertyA, propertyB] = [a['PriceEffectiveCost'], b['PriceEffectiveCost']];
          break;
        // case "StatusLabel":
        //   [propertyA, propertyB] = [a['Status.Label'], b['Status.Label']];
        //   break;
        // case "InvoiceDate":
        //   [propertyA, propertyB] = [new Date(a['Invoice.Date']).getTime(), new Date(b['Invoice.Date']).getTime()];
        //   break;
      }

      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

      return (valueA < valueB ? -1 : 1) * (this._sort.direction == 'asc' ? 1 : -1);
    });
  }
}
