import { Component, Input, OnChanges, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { EMPTY_REPORT } from '../../../../../../shared/components/data-table/shared/data/data';
import { DataTablePrintModalComponent } from '../../../../../../shared/components/data-table/shared/modals/data-table-print-modal/data-table-print-modal.component';
import { DataTablePrintModalConfig } from '../../../../../../shared/components/data-table/shared/types/data-table.type';
import { Merchant } from '../../../../../merchant/shared/types/merchant.type';
import { SettlementPaymentsReportService } from '../../services/settlement-payments-report.service';
import { PatientPaymentRecord, PatientPaymentReport } from '../../types/settlement-payments-report.type';

@Component({
  selector: 'ipv-settlement-payments-report-patient-payments',
  templateUrl: './settlement-payments-report-patient-payments.component.html',
  styleUrls: ['./settlement-payments-report-patient-payments.component.css'],
})
export class SettlementPaymentsReportPatientPaymentsComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('treatmentValue', { static: false }) public treatmentValue: TemplateRef<any>;
  @ViewChild('settlementAmount', { static: false }) public settlementAmount: TemplateRef<any>;

  @Input() patientPaymentReport: PatientPaymentReport;
  @Input() merchant: Partial<Merchant>;

  reportTable = EMPTY_REPORT;

  constructor(private settlementPaymentsReportService: SettlementPaymentsReportService, private dialog: MatDialog) {}

  ngOnInit() {
    this.settlementPaymentsReportService
      .getPrintButtonClicked()
      .pipe(untilDestroyed(this))
      .subscribe((tableName) => {
        if (tableName === 'patient-payments') {
          this.openTablePrint();
        }
      });
  }

  ngOnChanges() {
    if (
      this.patientPaymentReport &&
      this.patientPaymentReport.Date_Processed &&
      this.patientPaymentReport.Date_Processed.length > 0
    ) {
      this.reportTable = this.getPatientPaymentTables(this.patientPaymentReport);
    }
  }

  ngOnDestroy() {
    // required for untilDestroyed();
  }

  getPatientPaymentTables(patientPaymentReport: PatientPaymentReport): {
    fullTable: PatientPaymentRecord[];
    table: PatientPaymentRecord[];
    totalRecord: PatientPaymentRecord;
  } {
    const table: PatientPaymentRecord[] = [];

    let totalSettlementAmount = 0;

    const recordsLength = patientPaymentReport.Date_Processed.length;

    for (let index = 0; index < recordsLength; index++) {
      totalSettlementAmount = totalSettlementAmount + patientPaymentReport.Settled_Amount[index];

      const record: PatientPaymentRecord = {
        date: patientPaymentReport.Date_Processed[index],
        patient: patientPaymentReport.Patient_Name[index],
        product: patientPaymentReport.Product_Label[index],
        treatmentValue: patientPaymentReport.Treatment_Value[index],
        settlementAmount: patientPaymentReport.Settled_Amount[index],
      };

      table.push(record);
    }

    table.sort((first, second) => (first.date > second.date ? 1 : second.date > first.date ? -1 : 0));

    const totalRecord: PatientPaymentRecord = {
      date: 'Total',
      patient: '',
      product: '',
      treatmentValue: 0,
      settlementAmount: totalSettlementAmount,
    };

    return {
      fullTable: table.concat(totalRecord),
      table,
      totalRecord,
    };
  }

  openTablePrint() {
    this.dialog.open<DataTablePrintModalComponent, DataTablePrintModalConfig>(DataTablePrintModalComponent, {
      data: {
        table: this.reportTable.fullTable,
        customComponents: {
          treatmentValue: this.treatmentValue,
          settlementAmount: this.settlementAmount,
        },
        title: 'Patient Payment Report for' + this.merchant.TradingAs,
        formatHeaders: {
          treatmentValue: 'right',
          settlementAmount: 'right',
        },
        rename: {
          treatmentValue: 'Treatment Value',
          settlementAmount: 'Settlement Amount',
        },
        practiceOrMerchant: this.merchant,
      },
      width: '700px',
    });
  }
}
