/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./admin-logs.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../log/log-list/log-list.component.ngfactory";
import * as i3 from "../../../../shared/pipes/custom-date.pipe";
import * as i4 from "../../../log/log-list/log-list.component";
import * as i5 from "../../../log/shared/log.service";
import * as i6 from "@angular/material/dialog";
import * as i7 from "../../../authentication/shared/custom-date-time.pipe";
import * as i8 from "../../../session/shared/sessions.service";
import * as i9 from "./admin-logs.component";
var styles_AdminLogsComponent = [i0.styles];
var RenderType_AdminLogsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AdminLogsComponent, data: {} });
export { RenderType_AdminLogsComponent as RenderType_AdminLogsComponent };
export function View_AdminLogsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-log-list", [], null, null, null, i2.View_LogListComponent_0, i2.RenderType_LogListComponent)), i1.ɵprd(512, null, i3.CustomDatePipe, i3.CustomDatePipe, []), i1.ɵdid(2, 245760, null, 0, i4.LogListComponent, [i5.LogService, i6.MatDialog, i3.CustomDatePipe, i7.CustomDateTimePipe, i8.SessionsService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_AdminLogsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "admin-logs", [], null, null, null, View_AdminLogsComponent_0, RenderType_AdminLogsComponent)), i1.ɵdid(1, 114688, null, 0, i9.AdminLogsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AdminLogsComponentNgFactory = i1.ɵccf("admin-logs", i9.AdminLogsComponent, View_AdminLogsComponent_Host_0, {}, {}, []);
export { AdminLogsComponentNgFactory as AdminLogsComponentNgFactory };
