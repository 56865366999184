import { BehaviorSubject, merge as observableMerge, Observable } from 'rxjs';

import { Component, ElementRef, EventEmitter, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { map } from 'rxjs/operators';

import { DataSource } from '@angular/cdk/table';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import * as moment from 'moment';
import { CustomDatePipe } from '../../../shared/pipes/custom-date.pipe';
import { UtilsService } from '../../../shared/services/utils.service';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { InvitationService } from '../../invitation/shared/services/invitation.service';

@Component({
  selector: 'app-patient-invitation-list',
  templateUrl: './patient-invitation-list.component.html',
  styleUrls: ['./patient-invitation-list.component.css'],
  providers: [CustomDatePipe],
})
export class PatientInvitationListComponent implements OnInit {
  @Input()
  pageSize = 10;
  pageSizeOptions = [15, Number(this.pageSize), Number(this.pageSize) * 2, Number(this.pageSize) * 3];

  util = new UtilsClass();
  dateNow;

  displayedColumnsInv = [
    'Date',
    'Operator',
    'Email',
    'Treatment',
    'Amount',
    'Product',
    'ProductGroup',
    'Source',
    'EmailStatus',
    'InvitationStatus',
    'ApplicationStatus',
    'ContractStatus',
    // "timeElapsed",
    'Response',
    // "Actions"
  ];

  filters = [];

  searchVals = new FormControl();
  searchValList = [
    'Operator',
    'Email',
    'Treatment',
    'Amount',
    'Product',
    'Product Group',
    'Email Status',
    'Application Status',
    'Contract Status',
    'Invitation Status',
  ];
  existingPatientID; // existingPatientID = "6E744472500ACF4683AAAFC815FF53E5";
  expandedRow: number;
  public listDB: LoadRecords | null;
  dataSource: RecordDataSource | null;
  filteredSize = null;

  destroyEvent = new EventEmitter();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('filter', { static: false }) filter: ElementRef;

  constructor(
    private UtilsService: UtilsService,
    private invitationService: InvitationService,
    private customDate: CustomDatePipe
  ) {}

  ngOnInit() {
    this.listDB = new LoadRecords(this.invitationService, this.destroyEvent);
    this.dataSource = new RecordDataSource(this.listDB, this.paginator, this.sort);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.UtilsService.getServerDate().subscribe((res) => {
      this.dateNow = res;
    });

    this.listDB = new LoadRecords(this.invitationService, this.destroyEvent);
    this.dataSource = new RecordDataSource(this.listDB, this.paginator, this.sort);
  }

  setFilter(event, field) {
    let filter;
    if (typeof event == 'object' && event != null) {
      const v = this.customDate.transform(event, Settings.global['dateFormat']);
      filter = {
        field,
        value: v,
      };
    } else if (event) {
      filter = {
        field,
        value: event,
      };
    } else {
      filter = {
        field,
        value: '',
      };
    }
    this.dataSource.filter = filter;
    this.filteredSize = this.dataSource.filteredData.length;
  }

  //   deleteTestimony(id) {
  //     const confirmMessage = new ConfirmDialog(
  //       "info",
  //       "Delete Testimony",
  //       "Are you sure you want to detele this testimony?",
  //       "No",
  //       "Yes"
  //     );
  //     let ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
  //       data: confirmMessage,
  //       width: "650px"
  //     })
  //     ref.componentInstance.onConfirm.subscribe(res => {
  //       if (res == true) {
  //         this.testimonyService.deleteTestimony(id).subscribe(res => {
  //           if (res) {

  //             this.dataSource.removeItem = res;
  //             NotifyAppComponent.displayToast(
  //               "success",
  //               "Successful operation",
  //               "You successfully delele this testimony"
  //             );
  //             ref.close();
  //           }
  //         })
  //       }
  //       else {
  //         ref.close();
  //       }
  //     })

  //   }

  //   viewTestimony(id) {
  //     let ref = RootAppComponent.dialog.open(TestimonyViewComponent, {
  //       data: id,
  //       width: "650px"
  //     })
  //     ref.componentInstance.close.subscribe(res => {
  //       ref.close();
  //     })

  //     ref.componentInstance.update.subscribe(res => {
  //       this.dataSource.replaceItem = res;
  //     })

  //     ref.componentInstance.delete.subscribe(res => {
  //       ref.close();
  //       this.dataSource.removeItem = res;
  //     })

  //   }

  //   activateTestimony(id) {
  //     const confirmMessage = new ConfirmDialog(
  //       "info",
  //       "Activate Testimony",
  //       "Are you sure you want to activate this testimony?",
  //       "No",
  //       "Yes"
  //     );
  //     let ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
  //       data: confirmMessage,
  //       width: "650px"
  //     })
  //     ref.componentInstance.onConfirm.subscribe(res => {
  //       if (res == true) {
  //         let payload = {
  //           isActive: true
  //         }
  //         this.testimonyService.activeTestimony(id, payload).subscribe(res => {
  //           if (res) {

  //             this.dataSource.replaceItem = res;

  //             NotifyAppComponent.displayToast(
  //               "success",
  //               "Successful operation",
  //               "You successfully activate this testimony"
  //             );
  //             ref.close();
  //           }
  //         })
  //       }
  //       else {
  //         ref.close();
  //       }
  //     })
  //   }

  //   deactivateTestimony(id) {
  //     const confirmMessage = new ConfirmDialog(
  //       "info",
  //       "Deactivate Testimony",
  //       "Are you sure you want to deactivate this testimony?",
  //       "No",
  //       "Yes"
  //     );
  //     let ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
  //       data: confirmMessage,
  //       width: "650px"
  //     })
  //     ref.componentInstance.onConfirm.subscribe(res => {
  //       if (res == true) {
  //         let payload = {
  //           isActive: false
  //         }
  //         this.testimonyService.activeTestimony(id, payload).subscribe(res => {
  //           if (res) {
  //             this.dataSource.replaceItem = res;
  //             NotifyAppComponent.displayToast(
  //               "success",
  //               "Successful operation",
  //               "You successfully deactivate this testimony"
  //             );
  //             ref.close();
  //           }
  //         })
  //       }
  //       else {
  //         ref.close();
  //       }
  //     })
  //   }

  //   viewProspect(id) {

  //     RootAppComponent.dialog.open(ProspectViewComponent, {
  //       data: id,
  //       width: "750px",
  //       panelClass: "noCard"
  //     });

  //   }

  //   viewMerchant(id) {

  //     let ref = RootAppComponent.dialog.open(MerchantViewComponent, {
  //       data: id,
  //       width: "600px"
  //     });

  //     ref.componentInstance.close.subscribe(res => {
  //       ref.close();
  //     })

  //   }

  ngOnDestroy() {
    this.destroyEvent.emit(true);
  }
}

export class LoadRecords implements OnInit {
  public dataChange: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  items: any;
  count: any;
  existingPatientID; // = "6E744472500ACF4683AAAFC815FF53E5";
  get data(): any[] {
    return this.dataChange.value;
  }

  firstLoad = false;

  get firstLoadEvent() {
    return this.firstLoad;
  }

  isDestoyed = false;

  serviceRef;

  constructor(private invitationService: InvitationService, private destroyEvent) {
    this.destroyEvent.subscribe((res) => {
      this.isDestoyed = res;
    });

    const payload = {
      section: 0,
      fields:
        'ID,DateTimeCreated,DentistContactName,ExistingPatientID,Product_Key,' +
        'DentistContact_key,Email,DateTimeCreated,Product.ThisGroupOnly.Label,Product.ThisGroupOnly.Code,PatientDeclineReasons.Labels,' +
        'LastName,FirstName,Product.Label.Marketing,Status.Email.Short_NE,Voided,' +
        'Status.Invitation.Short_NE,PatientChoice.Label_LongThirdPerson,PatientChoice.Code,' +
        'Status.Contract.Short_NE,Status.Application.Short_NE,Service.Type,treatment.Value,Date.Responded,Date.Commenced,' +
        'Status.Application.Colour,Status.Invitation.Colour,Status.Email.Colour,Status.Contract.Colour',
    };

    this.serviceRef = this.invitationService.getListExistingID(this.existingPatientID, payload).subscribe((res) => {
      this.count = res.length;
      this.items = res;
      this.dataChange.next(this.items.slice());
      this.firstLoad = true;
    });
  }

  ngOnInit() {}

  ngOnDestroy() {
    if (this.serviceRef) {
      this.serviceRef.unsubscribe();
    }
  }
}

export class RecordDataSource extends DataSource<any> {
  _filterChange = new BehaviorSubject('');
  field = '';

  get filter(): any {
    return this._filterChange.value;
  }

  set filter(item: any) {
    this.field = item.field;
    this._filterChange.next(item.value);
  }

  filteredData: any[] = [];

  constructor(private _tableDatabase: LoadRecords, private _paginator: MatPaginator, private _sort: MatSort) {
    super();
    this._filterChange.subscribe(() => (this._paginator.pageIndex = 0));
  }

  set replaceItem(item) {
    const _index = this._tableDatabase.data.findIndex((_obj) => _obj._id == item._id);
    this._tableDatabase.data[_index] = item;
    this._tableDatabase.dataChange.next(this._tableDatabase.data);
  }

  set removeItem(id) {
    const data = this._tableDatabase.data.filter((row) => row._id != id);
    this._tableDatabase.dataChange.next(data);
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<any[]> {
    const displayDataChanges = [
      this._tableDatabase.dataChange,
      this._filterChange,
      this._paginator.page,
      this._sort.sortChange,
    ];

    return observableMerge(...displayDataChanges).pipe(
      map(() => {
        // Filter data
        this.filteredData = this._tableDatabase.data.slice().filter((item: any) => {
          let f = '';

          f = item[this.field] || '';

          const searchStr = f.toLowerCase();
          return searchStr.indexOf(this.filter.toLowerCase()) != -1;
        });
        const data = this.getSortedData(this.filteredData.slice());
        // Grab the page's slice of data.
        const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
        return data.splice(startIndex, this._paginator.pageSize);
      })
    );
  }

  disconnect() {}

  /** Returns a sorted copy of the database data. */
  getSortedData(data: any[]): any[] {
    // const data = data;
    if (!this._sort.active || this._sort.direction == '') {
      return data;
    }

    return data.sort((a, b) => {
      let propertyA: number | string = '';
      let propertyB: number | string = '';

      switch (this._sort.active) {
        case 'Date':
          [propertyA, propertyB] = [
            moment(a['DateTimeCreated']).toDate().getTime(),
            moment(b['DateTimeCreated']).toDate().getTime(),
          ];
          break;
        case 'Operator':
          [propertyA, propertyB] = [a.DentistContactName, b.DentistContactName];
          break;
        case 'Email':
          [propertyA, propertyB] = [a['Service.Email'], b['Service.Email']];
          break;

        case 'Treatment':
          [propertyA, propertyB] = [a['Service.Type'], b['Service.Type']];
          break;
        case 'Amount':
          [propertyA, propertyB] = [a['treatment.Value'], b['treatment.Value']];
          break;
        case 'Product':
          [propertyA, propertyB] = [a['Product.Label.Marketing'], b['Product.Label.Marketing']];
          break;
        case 'ProductGroup':
          [propertyA, propertyB] = [a['Product.ThisGroupOnly.Label'], b['Product.ThisGroupOnly.Label']];
          break;
        case 'Source':
          [propertyA, propertyB] = [a['PatientChoice.Code'], b['PatientChoice.Code']];
          break;
        case 'EmailStatus':
          [propertyA, propertyB] = [a['Status.Email.Short'], b['Status.Email.Short']];
          break;
        case 'InvitationStatus':
          [propertyA, propertyB] = [a['Status.Invitation.Short'], b['Status.Invitation.Short']];
          break;
        case 'ApplicationStatus':
          [propertyA, propertyB] = [a['Status.Application.Short'], b['Status.Application.Short']];
          break;
        case 'ContractStatus':
          [propertyA, propertyB] = [a['Status.Contract.Short'], b['Status.Contract.Short']];
          break;
        case 'Response':
          [propertyA, propertyB] = [a['PatientChoice.Label_LongThirdPerson'], b['PatientChoice.Label_LongThirdPerson']];
          break;

        case 'timeElapsed':
          [propertyA, propertyB] = [
            moment(a['DateTimeCreated']).toDate().getTime(),
            moment(b['DateTimeCreated']).toDate().getTime(),
          ];
          break;

        case 'timeInvited':
          [propertyA, propertyB] = [
            moment(a['DateTimeCreated']).toDate().getTime(),
            moment(b['DateTimeCreated']).toDate().getTime(),
          ];
          break;
        case 'timeResponded':
          [propertyA, propertyB] = [
            moment(a['Date.Responded']).toDate().getTime(),
            moment(b['Date.Responded']).toDate().getTime(),
          ];
          break;
      }

      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

      return (valueA < valueB ? -1 : 1) * (this._sort.direction == 'asc' ? 1 : -1);
    });
  }
}
