<div class="container" @ngIfAnimation>


  <mat-card class="card changeLogCard" *ngIf="newItem == true">
    <mat-card-header>
      <mat-card-title>Version 1.4.0</mat-card-title>
      <mat-card-subtitle>Tuesday 1<sup>st</sup> October 2019</mat-card-subtitle>
    </mat-card-header>

    <div class="row clearfix">
      <div class="thrd-width">
        <div class="col-left">
          <img src="https://email-graphics-smileright.s3-ap-southeast-2.amazonaws.com/img/supplier-treatment-info.jpg"
            class="full-width mt">
        </div>
      </div>
      <div class="two-thrd-width">
        <p class="lead">
          ADA Schedule of Service
        </p>
        <p>We're proud to announce that in conjunction with the Australian Dental Association, the   {{"AppMainBrand" | translate}} platform
          now has the ADA Schedule of Service in a convenient digital format, right inside your dashboard!</p>
        <p>All the information on services and products you find inside the physical Schedule of Service manual can now
          be easily accessed through the 'Treatment' menu inside your app. The functionality has been extended to also
          include all industry brands and products lists to the item codes.</p>
        <p class="lead">
          Suppliers at your fingertips
        </p>
        <p>Your dashboard now contains a fully-fledged supplier database, allowing you to quickly access all the
          information and contact details of your {{ "General.practices" | translate }} suppliers. </p>
        <p>Previously only displaying down to ADA item codes, you can now search through supplier brands and products as
          well as view important instructional documents and videos, plus any instructional or marketing content that
          will be sent to your {{ 'General.patients' | translate }}.</p>
        <p>From here you have full control over deciding which documents will get included in treatment invitations to
          your
          {{ 'General.patients' | translate }}, and you can adjust the item/product costings to better reflect your individual {{ "General.practice" | translate }} needs.</p>
      </div>
    </div>
    <div class="row clearfix">
      <div class="two-thrd-width">
        <p class="lead">A more engaging {{ 'General.patient' | translate }} experience</p>
        <p>With the   {{"AppMainBrand" | translate}} 1.4.0 release, your {{ 'General.patients' | translate }} will see a more engaging {{ 'General.patient' | translate }} card with video
          instructions and an improved front-page layout.
        </p>
        <p>We've been looking at how your {{ 'General.patient' | translate }} have been interacting with their treatment plans and {{ 'General.patient' | translate }} cards,
          and have made some changes that will help {{ 'General.patients' | translate }} streamline their experience. We've added an instructional
          video at the top of their page, which will help guide them through their decision-making process.
          Additionally, the {{ 'General.patient' | translate }} card experience has been refined to help drive them towards accepting ideal
          treatment.</p>
        <p class="lead">Help is a click away</p>
        <p>You'll notice that instructional videos will begin to appear throughout your merchant application, which will
          help make
          your workflow even easier.</p>
        <p>Keep an eye out for the blue help icons - <mat-icon class="fas fa-info-circle mat-primary"></mat-icon> - which
          when clicked will display an instructional video
          containing handy tips for using the   {{"AppMainBrand" | translate}} system. </p>
        <p>More videos will be added as new features are added, and if you require any further assistance you can always
          click the 'Contact Us' button in the bottom of the page to get in touch with one of our friendly   {{"AppMainBrand" | translate}}
          staff who will gladly guide you through the application.</p>
      </div>
      <div class="thrd-width">
        <div class="col-right">
          <img
            src="https://email-graphics-smileright.s3-ap-southeast-2.amazonaws.com/img/Apple-iPhone-11-Pro-PatCard.jpg"
            class="full-width" />
        </div>
      </div>
    </div>
    <div class="changes">
      <p class="lineItem"><span class="chip add">Add</span> ADA Schedule of Service &amp; Informed Consent Documents</p>
      <p class="lineItem"><span class="chip add">Add</span> Instructional videos</p>
      <p class="lineItem"><span class="chip fix">Fix</span> Updates to Suppliers interface</p>
      <p class="lineItem"><span class="chip fix">Fix</span> Updates to Treatment Documents</p>
    </div>
  </mat-card>


  <mat-card class="card changeLogCard" *ngIf="newItem == true">
    <mat-card-header>
      <mat-card-title>Version 1.3.1</mat-card-title>
      <mat-card-subtitle>Wednesday 4<sup>th</sup> September 2019</mat-card-subtitle>
    </mat-card-header>
    <div class="row clearfix">
      <p class="lead">An improved {{ 'General.patient' | translate }} detail screen, with focus on your {{ 'General.patient' | translate }}</p>
      <p>We've been re-working the way you manage your {{ "General.patient's'"| translate }} details, with a more centralised {{ 'General.patient' | translate }} detail page.
      </p>
      <p>Now whenever you view the details of an invitation - from the sent invitation list or from the dashboard - the
        invitation details will now open within the main {{ 'General.patient' | translate }} detail page. This allows you to have more convenient
        access to other aspects of your {{ 'General.patient' | translate }}, such as personal information or financial details, without needing to
        navigate into other sections of the application.</p>
    </div>
    <div class="changes">
      <p class="lineItem"><span class="chip add">Add</span> Note/Activity interface</p>
      <p class="lineItem"><span class="chip fix">Fix</span> Update {{ 'General.patient' | translate }} detail pages</p>
    </div>
  </mat-card>


  <mat-card class="card changeLogCard" *ngIf="newItem == true">
    <mat-card-header>
      <mat-card-title>Version 1.3.0</mat-card-title>
      <mat-card-subtitle>Tuesday 20<sup>th</sup> August 2019</mat-card-subtitle>
    </mat-card-header>
    <div class="row clearfix">
      <p class="lead">New modules to improve {{ 'General.patient' | translate }} and {{ "General.practice" | translate }} management</p>
      <p>We've just released version 1.3.0 of the   {{"AppMainBrand" | translate}} App, which includes a newly rebuilt {{ 'General.patient' | translate }} details page,
        allowing you to take much more control over your {{ 'General.patient' | translate }} profile information, health fund information, and next
        of kin and other contact information.</p>
      <p>There is also a quick lookup button in the app's top-right, where you can quickly find information on a {{ 'General.patient' | translate }}
        with only a couple of clicks.</p>
      <p>Other updates improves the handling of documents and suppliers, and a re-arranged side menu to better fit
        laptops and smaller screens.</p>
    </div>
    <div class="changes">
      <p class="lineItem"><span class="chip add">Add</span> Updated {{ 'General.patient' | translate }} details interface</p>
      <p class="lineItem"><span class="chip add">Add</span> {{ 'General.Patient' | translate }} 'quick lookup' added</p>
      <p class="lineItem"><span class="chip fix">Fix</span> Update menu item layout &amp; nested menus </p>
      <p class="lineItem"><span class="chip fix">Fix</span> Update supplier interface </p>
      <p class="lineItem"><span class="chip fix">Fix</span> Update document handling system </p>
      <p class="lineItem"><span class="chip fix">Fix</span> Improved back-end stability </p>
    </div>
  </mat-card>

  <mat-card class="card changeLogCard" *ngIf="newItem == true">
    <mat-card-header>
      <mat-card-title>Version 1.2.4</mat-card-title>
      <mat-card-subtitle>Wednesday 5<sup>th</sup> June 2019</mat-card-subtitle>
    </mat-card-header>
    <div class="row clearfix">
      <p class="lead">Usability Update</p>
      <p>Version 1.2.4 of the   {{"AppMainBrand" | translate}} app fixes some minor security and performance issues, as well as improving
        general interface usability.</p>
      <p>List views within the application are now clickable - allowing you to trigger 'quick views' of data simply
        by clicking the row in the list. Most lists will have a default 'quick view' that will be triggered. You can
        still access the Actions menu on each row as you could before.</p>
      <p>Other updates improves the handling of documents and suppliers, as well as additional promoter-level tools to
        assist with auditing.</p>
    </div>
    <div class="changes">
      <p class="lineItem"><span class="chip add">Add</span> Usability improvements</p>
      <p class="lineItem"><span class="chip fix">Fix</span> General performance improvements
      </p>
    </div>
  </mat-card>


  <mat-card class="card changeLogCard" *ngIf="newItem == true">
    <mat-card-header>
      <mat-card-title>Version 1.2.3</mat-card-title>
      <mat-card-subtitle>Friday 17<sup>th</sup> May 2019</mat-card-subtitle>
    </mat-card-header>
    <div class="row clearfix">
      <p class="lead">New Features Update</p>
      <p>In continuing the roll-out of critical {{ 'General.patient' | translate }} communication features,   {{"AppMainBrand" | translate}} is pleased to announce the
        addition of our <strong>Informed Consent Module</strong>, along with the ability to offer <strong>cash
          discounts</strong>.
      </p>
      <h3 class="sr-title">Informed Consent</h3>
      <label>Beta testing now live for selected Merchants!</label>
      <p>This feature allows you to attach the required informed consent documentation to a {{ 'General.patients' | translate }} invitation quickly
        and easily. Instead of sending your {{ 'General.patient' | translate }} home with physical paperwork which they may not read, all your
        mandatory reading material is included on their {{ 'General.Patient' | translate }} Card, and prompts the {{ 'General.patient' | translate }} to read the documents
        before proceeding. You'll see directly on your dashboard whether they have opened the documents, and at what
        time.
      </p>
      <p>To try out this new feature, simply start with a new treatment invitation. </p>
      <ol class="spaced">
        <li>First, enter your {{ "General.patient's'"| translate }} details on the first screen as you normally do.</li>
        <li>Then on the second step, enter the treatment price and attach your treatment plan pdf, if you have one.</li>
        <li>Now you'll see a section below your document uploader which asks for three things - Category, Type, and
          Brand. These filters allow you to narrow down the type of treatment which is being performed, right down to
          the brand of your equipment.</li>
        <li>The   {{"AppMainBrand" | translate}} system then finds the appropriate documentation required for this type of treatment, and
          attaches it to the invitation for you.</li>
        <li>That's it! You can now press Next and continue to send your invitation as you normally would.</li>
      </ol>
      <p>When your {{ 'General.patient' | translate }} receives their invitation, a notification will tell them how many documents are needed to
        review for informed consent. Once they've opened these documents, the date and time will be recorded on your
        dashboard so that you can be sure your {{ 'General.patients' | translate }} are fully informed.</p>

    </div>
    <div class="row clearfix">
      <div class="two-thrd-width">
        <div class="col-left">
          <h3 class="sr-title">Get customers smiling with cash discounts</h3>
          <p>Everyone loves to save money, and we love to help our {{ 'General.patients' | translate }}. That's why we've added the ability to
            provide
            your {{ 'General.patients' | translate }} with a small percentage discount if they pay their account promptly to you, using cash or
            card.
            This incentivises {{ 'General.patients' | translate }} to accept their treatment, as there is a perception that they may not get a good
            deal elsewhere if they don't. </p>
          <p>This feature is enabled automatically for you when you create invitations, however you have full control
            over
            what level of discount is offered and what threshold it should be limited to.</p>
        </div>
      </div>
      <div class="thrd-width">
        <div class="col-right text-center">
          <div class="image-block nobg full-width">
            <img src="https://s3-ap-southeast-2.amazonaws.com/email-graphics-smileright/screenshots/discount.png"
              class="full-width" />
            <p class="small"><em>What your customers see on their {{ 'General.patient' | translate }} card.</em></p>
          </div>
        </div>
      </div>
    </div>
    <div class="changes">
      <p class="lineItem"><span class="chip add">Add</span> <strong>Beta:</strong> Informed consent module</p>
      <p class="lineItem"><span class="chip add">Add</span> Discount module activated</p>
      <p class="lineItem"><span class="chip fix">Fix</span> Usability updates to profile cards for merchants, {{ 'General.patients' | translate }}
        and suppliers</p>
      <p class="lineItem"><span class="chip fix">Fix</span>Performance and stability improvements</p>
    </div>
  </mat-card>



  <mat-card class="card changeLogCard" *ngIf="newItem == true">
    <mat-card-header>
      <mat-card-title>Version 1.2.2</mat-card-title>
      <mat-card-subtitle>Wednesday 1<sup>st</sup> May 2019</mat-card-subtitle>
    </mat-card-header>
    <div class="row clearfix">
      <p class="lead">Performance Update</p>
      <p>Version 1.2.2 brings several small visual and performance fixes, including usability fixes to some utilities
        such as the finance calculator to improve your experience.</p>
    </div>
    <div class="changes">
      <p class="lineItem"><span class="chip fix">Fix</span> Visual updates to some list screens</p>
      <p class="lineItem"><span class="chip fix">Fix</span> Usability and performance improvements to utility modules
      </p>
    </div>
  </mat-card>


  <mat-card class="card changeLogCard" *ngIf="newItem == true">
    <mat-card-header>
      <mat-card-title>Version 1.2.1</mat-card-title>
      <mat-card-subtitle>Wednesday 27<sup>th</sup> March 2019</mat-card-subtitle>
    </mat-card-header>
    <div class="row clearfix">

      <p class="lead">Provide more personalisation, and reward {{ 'General.patients' | translate }} for early payment</p>

      <p>The latest release of the   {{"AppMainBrand" | translate}} platform introduces some new features for creating better experiences for
        your {{ 'General.patients' | translate }}. With the inclusion of personal message tools to speed up invitation delivery as well as the
        ability to include discounts with treatment invitations, your {{ 'General.patients' | translate }} and your {{ "General.practice" | translate }} will benefit from an
        increase in {{ 'General.patient' | translate }} engagement.</p>
    </div>
    <div class="row clearfix">
      <div class="thrd-width">
        <div class="col-left text-center">
          <div class="image-block full-width">
            <img src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/scs/discount-setting.png" class="full-width" />

            <p class="small"><em>Cash discounts can be added on the invitation creation page.</em></p>
          </div>
        </div>
      </div>
      <div class="two-thrd-width">
        <div class="col-right">
          <h3 class="sr-title">Include discounts for early cash payment</h3>
          <label>Beta testing now live for selected Merchants!</label>
          <p>Help nudge your {{ 'General.patients' | translate }} into accepting treatment by offering them small discounts for early settlement of
            their treatment costs, with cash or card payments on the day.</p>
          <p>When creating a new invitation, you can select <strong>offer a discount</strong> on the final step before
            sending. By ticking the box, you are shown details of how much a {{ 'General.patient' | translate }} can save off their treatment costs.
          </p>
          <p>This will set to a <strong>2% discount by default</strong>, however by clicking <strong>discount
              settings</strong> you can fine-tune this by setting a lower or higher percentage.</p>
          <p>You can also set an expiry time on the discount on offer, to gently nudge {{ 'General.patients' | translate }} into accepting
            treatments sooner. A <strong>discount threshold</strong> can also be applied, which sets a minimum treamtent
            value before a discount will come into effect.</p>
          <p>You've got complete control over how much of a saving to offer your {{ 'General.patients' | translate }}, giving you the ability to
            tailor treatment costs to better suit your {{ "General.patient's'"| translate }} circumstances.</p>
          <label>This feature is currently only active on selected Merchants, and will be fully released across the
            platform in the coming days.</label>
        </div>
      </div>
    </div>
    <div class="row clearfix">
      <div class="two-thrd-width">
        <div class="col-left">

          <h3 class="sr-title">Save default messages</h3>
          <p>In your <strong>Invitation settings panel</strong>, you now have the ability to create and store
            quick-access personal messages to attach to your invitations. Creating these messages only takes a few
            mintues, and then can be added to an invitation in seconds.</p>
          <p>To get started, follow these steps:</p>
          <ol class="spaced">
            <li>In the side menu, click <strong>Settings</strong>, then <strong>Invitation Settings</strong>.</li>
            <li>Press <strong>Create new message</strong> to open the message editing window</li>
            <li>Begin by setting the <strong>message type</strong>. For instance, if your message will be used on
              treatment invitations, select 'Invitations for Merchants'.</li>
            <li>Then, set the <strong>product group</strong>. You can set different default messages for different kinds
              of product offerings.</li>
            <li>Now fill out the <strong>title</strong> of your message - this will only be visible to you, {{ "General.patient's'"| translate }}
              don't see this.</li>
            <li>You can now write your message. Using the <strong>insert personalisation</strong> button, you can add
              extra dynamic fields such as a {{ "General.patient's'"| translate }} name, your {{ "General.practice" | translate }} details, or sign with your name. Simply click
              the word you want and it will be automatically inserted.</li>
          </ol>
          <p>Now when you create treatment invitations to your {{ 'General.patients' | translate }}, you have the option of inserting your new
            messages directly in the <strong>new invitation</strong> page!</p>
        </div>
      </div>
      <div class="thrd-width">
        <div class="col-right text-center">
          <div class="image-block full-width">
            <img src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/scs/new-message.png" class="full-width" />


            <p class="small"><em>The new message editing window.</em></p>
          </div>
        </div>
      </div>
    </div>

    <div class="changes">
      <p class="lineItem"><span class="chip add">Add</span> <strong>Beta:</strong> Custom discount module</p>
      <p class="lineItem"><span class="chip add">Add</span> Default message interface</p>
      <p class="lineItem"><span class="chip fix">Fix</span> Improved drag-and-drop document uploading</p>
      <p class="lineItem"><span class="chip fix">Fix</span> Improved display for attached documentation</p>
      <p class="lineItem"><span class="chip fix">Fix</span> Improvements to page styling, and better mobile interaction
      </p>
    </div>
  </mat-card>



  <mat-card class="card changeLogCard" *ngIf="newItem == true">
    <mat-card-header>
      <mat-card-title>Version 1.1.0</mat-card-title>
      <mat-card-subtitle>Friday 15<sup>th</sup> Febraury 2019</mat-card-subtitle>
    </mat-card-header>
    <p class="lead">Performance and Features Update</p>
    <p>Version 1.1.0 includes enhancements to the dashboard, list screens and detail windows, as well as a refreshed
      {{ 'KEYWORD.patient' | translate }} card!</p>
    <p>You'll notice many visual updates surrounding the quick view modals, designed to make your data easier to read
      and your patient lists easier to manage. An refreshed patient experience also helps your {{ 'General.patients' | translate }} undertand their
      treatment and payment options more effectively, and has been designed to look great on all mobile devices.</p>
    <p>We'll be rolling out progressive enhancements across the suite in the coming days, so keep your eyes peeled for
      some great new features!</p>
    <p>Extra new features include;</p>
    <div class="changes">
      <p class="lineItem"><span class="chip add">Add</span> Updated dashboard chart &amp; list components</p>
      <p class="lineItem"><span class="chip add">Add</span> New and improved patient card</p>
      <p class="lineItem"><span class="chip fix">Fix</span> Speed and performance improvements</p>
    </div>
  </mat-card>

  <!-- <mat-card class="card changeLogCard" *ngIf="newItem == true">
    <mat-card-header>
      <mat-card-title>Version 1.1.0</mat-card-title>
      <mat-card-subtitle>Friday 23<sup>rd</sup> November 2018</mat-card-subtitle>
    </mat-card-header>
    <div class="row clearfix">
      <div class="two-thrd-width">
        <div class="col-left">
          <p class="lead">Settle, Grettle!</p>
          <p>Version 1.1.0 brings you a major change in the way you handle your settlement workflow!</p>
          <p>We've activated the   {{"AppMainBrand" | translate}} Settlement Module on your dashboard, allowing you to now
            request settlements against your {{ "General.patient's'"| translate }} contracts quickly and easily. The process is straight forward -
            use the 'Settlements' tab to see a list of {{ 'General.patients' | translate }} with contracts, and then hit 'New settlement' to look
            up the right patient.</p>
          <p>From there, the system will send them an authorisation code to their mobile device, and once
            authorised will allow you to add your invoice and send the request directly to   {{"AppMainBrand" | translate}}. Easy!</p>
          <p>If your patient doesn't have their mobile with them, you can also print out a physical
            authorisation form for them to sign.</p>
          <p>The Settlements module will allow you to keep track of the remaining balance in your
            {{ "General.patient's'"| translate }} contract, as well as give you a more concise interface to manage your   {{"AppMainBrand" | translate}} finances.</p>
            <button *ngIf="isModuleSettlementsActive==true" mat-raised-button [routerLink]="['/merchant/', {outlets: {'page': ['settlement-list-overview']}}]" (click)="setHeaderLabel('Treatment Plan Settlements')">Check out the Settlements Module <mat-icon>chevron_right</mat-icon></button>
        </div>
      </div>
      <div class="thrd-width">
        <div class="col-right text-center">
          <div class="image-block full-width">
            <img src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/scs/settlementCreate.png" class="full-width" />
          </div>
          <p class="small"><em>The settlement request interface is sleek and easy to use...</em></p>
        </div>
      </div>
    </div>
    <div class="changes">
      <p class="lineItem"><span class="chip add">Add</span> Automated Settlements Module</p>
      <p class="lineItem"><span class="chip fix">Fix</span> Perfomrance and reliability improvements</p>
    </div>
  </mat-card> -->



  <mat-card class="card changeLogCard" *ngIf="newItem == true">
    <mat-card-header>
      <mat-card-title>Version 1.0.3</mat-card-title>
      <mat-card-subtitle>Thursday 1<sup>st</sup> November 2018</mat-card-subtitle>
    </mat-card-header>
    <p class="lead">Invoicing and Charts</p>
    <p>Version 1.0.3 includes enhancements to the performance pages and dashboard, providing you with much
      clearer metrics on how your {{ "General.practice" | translate }} is performing. Monitor treatment conversion rates and the way {{ 'General.patients' | translate }} are
      responding to your invitations in greater detail than before!</p>
    <p>You'll also notice the introduction of settlement tax invoices arriving via your email, as a
      precursor to our full settlement module being introduced in our next coming release.</p>
    <p>Extra new features include;</p>
    <div class="changes">
      <p class="lineItem"><span class="chip add">Add</span> Updated dashboard chart components</p>
      <p class="lineItem"><span class="chip add">Add</span> Addition of '{{ "General.Practice" | translate }} Images' in your {{ "General.practice" | translate }}
        settings page</p>
      <p class="lineItem"><span class="chip fix">Fix</span> Revised customer internal messaging</p>
      <p class="lineItem"><span class="chip fix">Fix</span> Speed and performance improvements</p>
    </div>
  </mat-card>

  <mat-card class="card changeLogCard" *ngIf="newItem == true">
    <mat-card-header>
      <mat-card-title>Version 1.0.2</mat-card-title>
      <mat-card-subtitle>Thursday 27<sup>th</sup> September 2018</mat-card-subtitle>
    </mat-card-header>
    <p class="lead">Keep a close eye on the performance of your {{ "General.Practice" | translate }}</p>
    <p>With version 1.0.2 of the   {{"AppMainBrand" | translate}} Platform, we've released the first iteration of our
      performance and overview pages. These pages can be accessed from the side menu and give you details on the number
      of {{ "General.patient's'"| translate }} you've been communicating with and how much return you're seeing from their treatments.</p>
    <p>Other new improvements include;</p>
    <ul>
      <li>Enhancements to search filters for quickly finding patient records in the patient list</li>
      <li>Enhanced charting and statistic display</li>
      <li>Improved application error handling</li>
      <li>...and much more</li>
    </ul>
    <div class="changes">
      <p class="lineItem"><span class="chip add">Add</span> Included Overview and Performance pages</p>
      <p class="lineItem"><span class="chip fix">Fix</span> Performance and database improvements</p>
    </div>
  </mat-card>

  <mat-card class="card changeLogCard" *ngIf="newItem == true">
    <mat-card-header>
      <mat-card-title>Version 1.0.1</mat-card-title>
      <mat-card-subtitle>Thursday 20<sup>th</sup> September 2018</mat-card-subtitle>
    </mat-card-header>
    <p class="lead">We're enhancing the way you manage invites!</p>
    <p>With version 1.0.1 of the   {{"AppMainBrand" | translate}} Platform, we've enhanced the way you can create and modify
      invitations! We've added some advanced features such as;</p>
    <ul>
      <li>Fine control of patient application statuses from the invitation list action menus</li>
      <li>More available data showing finance value remaining, patient interaction and engagement</li>
      <li>Leave and return to half-complete invitations using the Draft Invitations list</li>
      <li>Customise your profile with avatars and profile details</li>
      <li>...and much more</li>
    </ul>
    <div class="changes">
      <p class="lineItem"><span class="chip add">Add</span> Added change log</p>
      <p class="lineItem"><span class="chip fix">Fix</span> Performance and database improvements</p>
      <p class="lineItem"><span class="chip fix">Fix</span> File upload issues repaired</p>
    </div>
  </mat-card>


  <div class="well" *ngIf="newItem == false">
    <p class="lead">No updates to display.</p>
  </div>

</div>
