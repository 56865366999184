<div class="float-close">
  <!-- Modal dismiss button -->
  <button class="btn-close btn-clear mat-button" (click)="closeModal()">
    <mat-icon>clear</mat-icon>
  </button>
</div>

<mat-tab label="Email Template">
  <div class="row clearFix full-width">
    <div class="row full-width clearfix" *ngIf="emailSubject">
      <p><strong>Subject Line:</strong> {{ emailSubject }}</p>
    </div>

    <div class="row full-width clearfix">
      <app-blob-view
        [content]="emailContent"
        [extension]="'html'"
        [label]="utilMarketing.getTemplateLabel(selectedTemplate)"
      ></app-blob-view>
    </div>
  </div>
</mat-tab>
