<mat-tab-group dynamicHeight="true">
  <!-- repeat simple page array -->

  <mat-tab id="simplePageTab" *ngFor="let simple of simplepages">
    <mat-card>
      <ng-template mat-tab-label>
        {{ simple.title }}

        <!--  title -->
      </ng-template>

      <app-simple-page-edit [simplePage]="simple" (deleted)="removePage($event)"></app-simple-page-edit>
      <!--  simple-page-edit -->
    </mat-card>
  </mat-tab>
</mat-tab-group>
