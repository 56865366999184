/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./emoji-input-modal.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../container-modal/container-modal.component.ngfactory";
import * as i3 from "../container-modal/container-modal.component";
import * as i4 from "@angular/material/dialog";
import * as i5 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i6 from "@angular/material/icon";
import * as i7 from "../../../../../node_modules/@ctrl/ngx-emoji-mart/ctrl-ngx-emoji-mart.ngfactory";
import * as i8 from "@ctrl/ngx-emoji-mart";
import * as i9 from "./emoji-input-modal.component";
var styles_EmojiInputModalComponent = [i0.styles];
var RenderType_EmojiInputModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EmojiInputModalComponent, data: {} });
export { RenderType_EmojiInputModalComponent as RenderType_EmojiInputModalComponent };
export function View_EmojiInputModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 18, "ipv-container-modal", [], null, null, null, i2.View_ContainerModalComponent_0, i2.RenderType_ContainerModalComponent)), i1.ɵdid(1, 114688, null, 0, i3.ContainerModalComponent, [i4.MAT_DIALOG_DATA], { enableDragging: [0, "enableDragging"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 13, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 12, "div", [["style", "display: flex; justify-content: space-between"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 6, "div", [["style", "display: flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i5.View_MatIcon_0, i5.RenderType_MatIcon)), i1.ɵdid(6, 9158656, null, 0, i6.MatIcon, [i1.ElementRef, i6.MatIconRegistry, [8, null], [2, i6.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, [" emoji_emotions"])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["style", "display: block"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "h2", [["class", "summary-header rm-m"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Emoji Keyboard"])), (_l()(), i1.ɵeld(11, 0, null, null, 4, "button", [["class", "btn-close btn-clear mat-button white"], ["matDialogClose", ""], ["style", "height: 24px"]], [[1, "aria-label", 0], [1, "type", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 12).dialogRef.close(i1.ɵnov(_v, 12).dialogResult) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.close() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(12, 606208, null, 0, i4.MatDialogClose, [[2, i4.MatDialogRef], i1.ElementRef, i4.MatDialog], { _matDialogClose: [0, "_matDialogClose"] }, null), (_l()(), i1.ɵeld(13, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i5.View_MatIcon_0, i5.RenderType_MatIcon)), i1.ɵdid(14, 9158656, null, 0, i6.MatIcon, [i1.ElementRef, i6.MatIconRegistry, [8, null], [2, i6.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["clear"])), (_l()(), i1.ɵeld(16, 0, null, 1, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 1, "emoji-mart", [], null, [[null, "emojiClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("emojiClick" === en)) {
        var pd_0 = (_co.selectEmoji($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_PickerComponent_0, i7.RenderType_PickerComponent)), i1.ɵdid(18, 114688, null, 0, i8.PickerComponent, [i1.ChangeDetectorRef, i8.EmojiFrequentlyService], { showPreview: [0, "showPreview"] }, { emojiClick: "emojiClick" })], function (_ck, _v) { var currVal_0 = true; _ck(_v, 1, 0, currVal_0); _ck(_v, 6, 0); var currVal_5 = ""; _ck(_v, 12, 0, currVal_5); _ck(_v, 14, 0); var currVal_8 = false; _ck(_v, 18, 0, currVal_8); }, function (_ck, _v) { var currVal_1 = i1.ɵnov(_v, 6).inline; var currVal_2 = (((i1.ɵnov(_v, 6).color !== "primary") && (i1.ɵnov(_v, 6).color !== "accent")) && (i1.ɵnov(_v, 6).color !== "warn")); _ck(_v, 5, 0, currVal_1, currVal_2); var currVal_3 = (i1.ɵnov(_v, 12).ariaLabel || null); var currVal_4 = i1.ɵnov(_v, 12).type; _ck(_v, 11, 0, currVal_3, currVal_4); var currVal_6 = i1.ɵnov(_v, 14).inline; var currVal_7 = (((i1.ɵnov(_v, 14).color !== "primary") && (i1.ɵnov(_v, 14).color !== "accent")) && (i1.ɵnov(_v, 14).color !== "warn")); _ck(_v, 13, 0, currVal_6, currVal_7); }); }
export function View_EmojiInputModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "sr-emoji-input-modal", [], null, null, null, View_EmojiInputModalComponent_0, RenderType_EmojiInputModalComponent)), i1.ɵdid(1, 114688, null, 0, i9.EmojiInputModalComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EmojiInputModalComponentNgFactory = i1.ɵccf("sr-emoji-input-modal", i9.EmojiInputModalComponent, View_EmojiInputModalComponent_Host_0, {}, { closed: "closed", emojiClicked: "emojiClicked" }, []);
export { EmojiInputModalComponentNgFactory as EmojiInputModalComponentNgFactory };
