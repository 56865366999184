import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output, SimpleChanges } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ExistingPatientViewComponent } from '../../existing-patient/existing-patient-view/existing-patient-view.component';
import { ExistingPatientService } from '../../existing-patient/shared/existing-patient.service';
import { InvitationViewModalComponent } from '../invitation-view-modal/invitation-view-modal.component';
import { InvitationService } from '../shared/services/invitation.service';

@Component({
  selector: 'app-invitation-existing-patient-list',
  templateUrl: './invitation-existing-patient-list.component.html',
  styleUrls: ['./invitation-existing-patient-list.component.css'],
  animations: [
    trigger('smoothHeightAnim', [
      transition('void => *', [
        query('div#InvitationRecord', style({ opacity: 0 }), { optional: true }),
        query(
          'div#InvitationRecord',
          stagger('100ms', [
            animate(
              '1s ease-out',
              keyframes([
                style({ opacity: 0, maxHeight: '20vh', offset: 0 }),
                style({ opacity: 1, maxHeight: '65vh', offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('div#InvitationRecord', style({ opacity: 1 }), { optional: true }),
        query(
          'div#InvitationRecord',
          stagger('100ms', [
            animate(
              '1s ease-in',
              keyframes([
                style({ opacity: 0, maxHeight: '20vh', offset: 0 }),
                style({ opacity: 1, maxHeight: '65vh', offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.row', style({ opacity: 0 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.row', style({ opacity: 1 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class InvitationExistingPatientListComponent implements OnInit {
  contentState = 'full';

  @Input() existingPatientID;
  @Output()
  closeModal = new EventEmitter();
  existingPatient = {};
  invitations;
  role;

  @Input()
  membership = false;

  merchantName;

  isModal = false;
  sessionType = 'guest';

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private existingPatientService: ExistingPatientService,
    private invitationService: InvitationService,
    private authenticationService: AuthenticationService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data && typeof data == 'string') {
      this.existingPatientID = data;
      this.isModal = true;
    }
  }

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((sessionType) => {
      if (sessionType) {
        this.sessionType = sessionType;
      }

      this.authenticationService.getCurrentUser().subscribe((res) => {
        if (res && res.success && res.data['Role'] && res.data['Role']) {
          this.role = res.data['Role'];

          this.existingPatientService
            .getExistingPatientDetails(this.existingPatientID, {}, this.sessionType)
            .subscribe((res) => {
              this.existingPatient = res;

              if (this.existingPatient) {
                const payload = {
                  section: 0,
                  fields:
                    'ID,DateTimeCreated,Product.Label.Marketing,DentistContactName,MerchantName,' +
                    'Email,' +
                    'LastName,FirstName,' +
                    'Status.Invitation.Short_NE,' +
                    'Status.Application.Short_NE,treatment.Value,' +
                    'Status.Application.Colour,Status.Invitation.Colour',
                };

                if (this.role == 'merchant') {
                  this.invitationService
                    .getListExistingID(this.existingPatientID, payload, this.membership)
                    .subscribe((res) => {
                      this.invitations = res;
                    });
                } else if (this.role == 'admin' || this.role == 'promoter') {
                  this.invitationService
                    .getGlobalListExistingID(this.existingPatientID, payload, this.membership)
                    .subscribe((res) => {
                      this.invitations = res;
                    });
                }
              }
            });
        }
      });
    });
  }

  openInviteViewDialog(ID) {
    const ref = this.dialog.open(InvitationViewModalComponent, {
      data: {
        invitationID: ID,
        membership: false,
      },
      width: '900px',
    });

    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });

    ref.componentInstance.openDetails.subscribe((id) => {
      ref.close();
      ref.afterClosed().subscribe((res) => {
        this.router.navigate(['/merchant', { outlets: { page: ['invitation-view', id] } }]);
      });
    });
  }

  close() {
    this.closeModal.emit(true);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.authenticationService.getCurrentUser().subscribe((res) => {
      if (res && res.success && res.data['Role'] && res.data['Role']) {
        this.role = res.data['Role'];

        this.existingPatientService
          .getExistingPatientDetails(this.existingPatientID, {}, this.sessionType)
          .subscribe((res) => {
            this.existingPatient = res;

            if (this.existingPatient) {
              const payload = {
                section: 0,
                fields:
                  'ID,DateTimeCreated,Product.Label.Marketing,DentistContactName,' +
                  'Email,' +
                  'LastName,FirstName,' +
                  'Status.Invitation.Short_NE,' +
                  'Status.Application.Short_NE,treatment.Value,' +
                  'Status.Application.Colour,Status.Invitation.Colour',
              };

              if (this.role == 'merchant') {
                this.invitationService
                  .getListExistingID(this.existingPatientID, payload, this.membership)
                  .subscribe((res) => {
                    this.invitations = res;
                  });
              } else if (this.role == 'admin' || this.role == 'promoter') {
                this.invitationService
                  .getGlobalListExistingID(this.existingPatientID, payload, this.membership)
                  .subscribe((res) => {
                    this.invitations = res;
                  });
              }
            }
          });
      }
    });
  }

  openCustomerViewModal() {
    const ref = this.dialog.open(ExistingPatientViewComponent, {
      data: this.existingPatientID,
      width: '650px',
      panelClass: 'noCard',
    });
  }
}
