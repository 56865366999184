import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { InvitationService } from '../shared/services/invitation.service';

@Component({
  selector: 'app-invitation-resend',
  templateUrl: './invitation-resend.component.html',
  styleUrls: ['./invitation-resend.component.css'],
})
export class InvitationResendComponent implements OnInit {
  @Input()
  invitationID = null;

  @Input()
  membership = false;

  @Input()
  isTreatmentInvite = true;

  @Output()
  getInvitation = new EventEmitter();

  @Output()
  close = new EventEmitter();

  dateTobeSentDefault = new Date();

  dateTobeSent = new Date();

  invitation = {};

  phoneNumber;
  email;
  sendEmail = true;
  sendSms = false;

  util = new UtilsClass();
  isPromoterOrAdmin = false;
  profileLink;
  sessionType = 'guest';

  constructor(
    private invitationService: InvitationService,
    private authenticationService: AuthenticationService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.invitationID) {
        this.invitationID = data.invitationID;
      }

      if (data.isTreatmentInvite != null) {
        this.isTreatmentInvite = data.isTreatmentInvite;
      }

      if (data.membership != null) {
        this.membership = data.membership;
      }
    }
  }

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((sessionType) => {
      if (sessionType) {
        this.sessionType = sessionType;
      }

      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isPromoterOrAdmin = res;

        if (this.isTreatmentInvite == true) {
          this.invitationService
            .getInvitationDetails(this.invitationID, {}, this.sessionType, this.membership)
            .subscribe((res) => {
              if (res) {
                this.invitation = res;

                this.getPicture();
                this.phoneNumber = this.invitation['Mobile'];
                this.email = this.invitation['Email'];

                if (this.invitation['DateToBeSent'] != '0000-00-00') {
                  const d = moment(this.invitation['DateToBeSent'], 'DD-MM-YYYYY').toDate();

                  if (moment(d).isAfter(new Date())) {
                    this.dateTobeSent = moment(this.invitation['DateToBeSent'], 'DD-MM-YYYYY').toDate();
                  }
                }
              }
            });
        } else {
          this.invitationService.getOneInvitationCommunication(this.invitationID, this.sessionType).subscribe((res) => {
            if (res) {
              this.invitation = res;
              this.phoneNumber = this.invitation['Mobile'];
              this.email = this.invitation['Email'];

              if (this.invitation['DateToBeSent'] != '0000-00-00') {
                const d = moment(this.invitation['DateToBeSent'], 'DD-MM-YYYYY').toDate();

                if (moment(d).isAfter(new Date())) {
                  this.dateTobeSent = moment(this.invitation['DateToBeSent'], 'DD-MM-YYYYY').toDate();
                }
              }
            }
          });
        }
      });
    });
  }

  getEmail(e) {
    this.email = e;
  }

  getPhone(p) {
    this.phoneNumber = p;
  }

  selectedDate(p) {
    if (p) {
      this.selectedDate = p;
    }
  }

  resend() {
    if (this.isTreatmentInvite == true) {
      const payload = {
        email: this.email,
        phone: this.phoneNumber,
        sendEmail: this.sendEmail,
        sendSms: this.sendSms,
        dateToBeSent: this.util.EPdateFormat(this.selectedDate),
      };

      this.invitationService.resend(this.invitationID, payload, this.membership).subscribe((res) => {
        if (res) {
          this.getInvitation.emit(res);
          NotifyAppComponent.displayToast('success', 'Success!', 'The Invitation is successfully sent  ');

          this.close.emit(true);
        }
      });
    } else {
      const payload = {
        email: this.email,
        phone: this.phoneNumber,
        sendEmail: this.sendEmail,
        sendSms: this.sendSms,
        dateToBeSent: this.util.EPdateFormat(this.selectedDate),
      };

      this.invitationService.resendInvitationCommunication(this.invitationID, payload).subscribe((res) => {
        if (res) {
          this.getInvitation.emit(res);
          NotifyAppComponent.displayToast('success', 'Success!', 'The Invitation is successfully sent  ');

          this.close.emit(true);
        }
      });
    }
  }

  closeDialog() {
    this.close.emit(true);
  }

  selectDate(e) {
    this.dateTobeSentDefault = e;
  }

  getPicture() {
    this.profileLink = null;
    if (this.invitation['Prospect_key']) {
      this.profileLink = this.invitationService.getProfilePicStreamLink(this.invitation['Prospect_key']);
    }
  }
}
