import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DentistInviteCreateComponent } from '../dentist-invite-create/dentist-invite-create.component';
import { DentistListMapComponent } from '../dentist-list-map/dentist-list-map.component';
import { DentistMapOverViewComponent } from '../dentist-map-overview/dentist-map-overview.component';
import { DentistViewConsumerComponent } from '../dentist-view-consumer/dentist-view-consumer.component';
import { DentistViewModalComponent } from '../dentist-view-modal/dentist-view-modal.component';
import { DentistViewPictureComponent } from '../dentist-view-picture/dentist-view-picture.component';
import { DentistViewComponent } from '../dentist-view/dentist-view.component';
import { DentistWorkingHoursOverviewComponent } from '../dentist-working-hours-overview/dentist-working-hours-overview.component';

const routes: Routes = [
  {
    path: 'dentist-view',
    component: DentistViewComponent,
  },
  {
    path: 'dentist-view-consumer',
    component: DentistViewConsumerComponent,
  },
  {
    path: 'dentist-view-picture',
    component: DentistViewPictureComponent,
  },
  {
    path: 'dentist-view-modal',
    component: DentistViewModalComponent,
  },
  {
    path: 'dentist-create',
    component: DentistInviteCreateComponent,
  },
  {
    path: 'DentistMapOverViewComponent',
    component: DentistMapOverViewComponent,
  },
  {
    path: 'DentistListMapComponent',
    component: DentistListMapComponent,
  },
  {
    path: 'DentistWorkingHoursOverviewComponent',
    component: DentistWorkingHoursOverviewComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DentistRoutingModule {}
