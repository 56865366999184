<div class="container">
  <!-- split cards -->

  <!--  if refferal merchant views page, display #referredFrom card -->
  <!--  if orig referring merchant views page, display #referredTo card -->
  <!--  if PROMOTER views page, display both blocks in tabs -->

  <div class="row clearfix flex">
    <mat-card class="card full-width">
      <div class="card-header primary-gradient-img clearfix inModal">
        <div class="row clearfix">
          <div class="row titleArea clearfix">
            <mat-icon class="pull-left">perm_phone_msg</mat-icon>
            <h2 class="summary-header rm-m">
              Referral details
            </h2>
          </div>
        </div>
      </div>
      <div class="row clearfix">
        <div class="details">
          <div class="row clearfix flex">
            <p>Referral made:</p>
            <p><strong>00/00/0000 at 12:00</strong></p>
          </div>
          <div class="row clearfix flex">
            <p>Seen by {{"KEYWORD.practice" | translate}}:</p>
            <p><strong>00/00/0000 at 12:00</strong></p>
          </div>
          <div class="row clearfix flex">
            <p>Response by {{"KEYWORD.practice" | translate}}:</p>
            <p><strong>00/00/0000 at 12:00</strong></p>
          </div>
          <div class="row clearfix flex">
            <p>Seen by {{"KEYWORD.patient" | translate}}:</p>
            <p><strong>00/00/0000 at 12:00</strong></p>
          </div>
          <div class="row clearfix flex">
            <p>Response by {{"KEYWORD.patient" | translate}}:</p>
            <p><strong>00/00/0000 at 12:00</strong></p>
          </div>
        </div>
      </div>
    </mat-card>

    <mat-card class="card full-width">
      <div class="card-header primary-gradient-img clearfix inModal">
        <div class="row clearfix">
          <div class="row titleArea clearfix">
            <mat-icon class="fas fa-user pull-left"></mat-icon>
            <h2 class="summary-header rm-m">
              {{ "KEYWORD.patient" | translate | titlecase }} details
            </h2>
          </div>
        </div>
      </div>
      <div class="row clearfix">
        <div class="details">
          <div class="row clearfix flex">
            <p>Name:</p>
            <p><strong>Mr Firstname Lastname</strong></p>
          </div>
          <div class="row clearfix flex">
            <p>Gender:</p>
            <p><strong>Male</strong></p>
          </div>
          <div class="row clearfix flex">
            <p>Date of Birth:</p>
            <p><strong>10/10/1980</strong></p>
          </div>
          <div class="row clearfix flex">
            <p>Address:</p>
            <p><strong>1234 Address Street, City 1234</strong></p>
          </div>
          <div class="row clearfix flex">
            <p>Phone number:</p>
            <p><strong>08 1234 1234</strong></p>
          </div>
          <div class="row clearfix flex">
            <p>Mobile number:</p>
            <p><strong>0400 000 000</strong></p>
          </div>
          <div class="row clearfix flex">
            <p>Email:</p>
            <p><strong>firstname@lastname.com</strong></p>
          </div>
        </div>
      </div>
    </mat-card>

    <!-- REFERRAL CARD FROM -->
    <mat-card class="card full-width" #referredFrom *ngIf="isReferredFrom && !isPromoterOrAdmin">
      <div class="card-header primary-gradient-img clearfix inModal">
        <div class="row clearfix">
          <div class="row titleArea clearfix">
            <mat-icon class="fas fa-user-mat pull-left"></mat-icon>
            <h2 class="summary-header rm-m">
              Referring {{ "KEYWORD.practice" | translate }} details
            </h2>
          </div>
        </div>
        <div class="action-button">
          <button class="actionMenuToggle" [matMenuTriggerFor]="btnPracMenu" mat-raised-button>
            Actions <mat-icon>more_vert</mat-icon>
          </button>

          <mat-menu #btnPracMenu="matMenu">
            <button class="" (click)="contact()" mat-menu-item color="accent">
              <mat-icon>contact_mail</mat-icon>
              Contact referring {{"KEYWORD.practice" | translate}}
            </button>
          </mat-menu>
        </div>
      </div>

      <div class="row clearfix">
        <div class="details">
          <div class="row clearfix flex">
            <p>{{ "KEYWORD.practice" | translate | titlecase}} name:</p>
            <p><strong>Jims Orthodontics</strong></p>
          </div>
          <div class="row clearfix flex">
            <p>{{"KEYWORD.practitioner" | translate | titlecase}} name:</p>
            <p><strong>Dr Firstname Lastname</strong></p>
          </div>
          <div class="row clearfix flex">
            <p>Address:</p>
            <p><strong>1234 Address Street, City 1234</strong></p>
          </div>
          <div class="row clearfix flex">
            <p>Primary contact number:</p>
            <p><strong>08 1234 1234</strong></p>
          </div>
          <div class="row clearfix flex">
            <p>Email:</p>
            <p><strong>firstname@lastname.com</strong></p>
          </div>
        </div>
      </div>
    </mat-card>

    <!-- REFERRAL CARD TO -->
    <mat-card class="card full-width" #referredTo *ngIf="isReferredTo && !isPromoterOrAdmin">
      <div class="card-header primary-gradient-img clearfix inModal">
        <div class="row clearfix">
          <div class="row titleArea clearfix">
            <mat-icon class="fas fa-user-mat pull-left"></mat-icon>
            <h2 class="summary-header rm-m">
              Referred to {{ "KEYWORD.practice" | translate }}
            </h2>
          </div>
        </div>
        <div class="action-button">
          <button class="actionMenuToggle" [matMenuTriggerFor]="btnPracMenu" mat-raised-button>
            Actions <mat-icon>more_vert</mat-icon>
          </button>

          <mat-menu #btnPracMenu="matMenu">
            <button class="" (click)="contact()" mat-menu-item color="accent">
              <mat-icon>contact_mail</mat-icon>
              Contact referring {{"KEYWORD.practice" | translate}}
            </button>
            <mat-divider></mat-divider>
            <button class="" (click)="contact()" mat-menu-item color="accent">
              <mat-icon>contact_mail</mat-icon>
              Contact referred {{"KEYWORD.practice" | translate}}
            </button>
          </mat-menu>
        </div>
      </div>

      <div class="row clearfix">
        <div class="details">
          <div class="row clearfix flex">
            <p>{{ "KEYWORD.practice" | translate | titlecase}} name:</p>
            <p><strong>Jims Orthodontics</strong></p>
          </div>
          <div class="row clearfix flex">
            <p>{{"KEYWORD.practitioner" | translate | titlecase}} name:</p>
            <p><strong>Dr Firstname Lastname</strong></p>
          </div>
          <div class="row clearfix flex">
            <p>Address:</p>
            <p><strong>1234 Address Street, City 1234</strong></p>
          </div>
          <div class="row clearfix flex">
            <p>Primary contact number:</p>
            <p><strong>08 1234 1234</strong></p>
          </div>
          <div class="row clearfix flex">
            <p>Email:</p>
            <p><strong>firstname@lastname.com</strong></p>
          </div>
        </div>
      </div>
    </mat-card>

    <!-- REFERRAL CARD ADMIN -->
    <mat-card class="card full-width" #referredAdmin *ngIf="isPromoterOrAdmin">
      <div class="card-header primary-gradient-img clearfix inModal">
        <div class="row clearfix">
          <div class="row titleArea clearfix">
            <mat-icon class="fas fa-user-mat pull-left"></mat-icon>
            <h2 class="summary-header rm-m">
              Referring {{ "KEYWORD.practice" | translate }} details
            </h2>
          </div>
        </div>
        <div class="action-button">
          <button class="actionMenuToggle" [matMenuTriggerFor]="btnPracMenu" mat-raised-button>
            Actions <mat-icon>more_vert</mat-icon>
          </button>

          <mat-menu #btnPracMenu="matMenu">
            <button class="" (click)="contact()" mat-menu-item color="accent">
              <mat-icon>contact_mail</mat-icon>
              Contact referring {{"KEYWORD.practice" | translate}}
            </button>
          </mat-menu>
        </div>
      </div>

      <div class="row clearfix">
        <mat-tab-group class="adminTabs">
          <mat-tab label="Referred to">
            <div class="details">
              <div class="row clearfix flex">
                <p>{{ "KEYWORD.practice" | translate | titlecase}} name:</p>
                <p><strong>Jims Orthodontics</strong></p>
              </div>
              <div class="row clearfix flex">
                <p>{{"KEYWORD.practitioner" | translate | titlecase}} name:</p>
                <p><strong>Dr Firstname Lastname</strong></p>
              </div>
              <div class="row clearfix flex">
                <p>Address:</p>
                <p><strong>1234 Address Street, City 1234</strong></p>
              </div>
              <div class="row clearfix flex">
                <p>Primary contact number:</p>
                <p><strong>08 1234 1234</strong></p>
              </div>
              <div class="row clearfix flex">
                <p>Email:</p>
                <p><strong>firstname@lastname.com</strong></p>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="Referred from">
            <div class="details">
              <div class="row clearfix flex">
                <p>{{ "KEYWORD.practice" | translate | titlecase}} name:</p>
                <p><strong>Jims Orthodontics</strong></p>
              </div>
              <div class="row clearfix flex">
                <p>{{"KEYWORD.practitioner" | translate | titlecase}} name:</p>
                <p><strong>Dr Firstname Lastname</strong></p>
              </div>
              <div class="row clearfix flex">
                <p>Address:</p>
                <p><strong>1234 Address Street, City 1234</strong></p>
              </div>
              <div class="row clearfix flex">
                <p>Primary contact number:</p>
                <p><strong>08 1234 1234</strong></p>
              </div>
              <div class="row clearfix flex">
                <p>Email:</p>
                <p><strong>firstname@lastname.com</strong></p>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </mat-card>
  </div>

  <!-- single card -->
  <div class="row clearfix">
    <mat-card class="">
      <div class="card-header primary-gradient-img clearfix inModal">
        <div class="row clearfix">
          <div class="row titleArea clearfix">
            <mat-icon class="fas {{'fa-tooth' | translate}} pull-left"></mat-icon>
            <h2 class="summary-header rm-m">
              {{ "KEYWORD.treatment" | translate | titlecase }} details
              <span
                class="subLabel">Notes and documentation regarding FirstName's required {{"KEYWORD.treatment" | translate}}</span>
            </h2>
          </div>
        </div>
      </div>

      <div class="row clearfix">
        <div class="icons-col">
          <mat-icon class="fas fa-comment"></mat-icon>
        </div>
        <div class="row-content-col">
          <div class="row clearfix">
            <h3 class="sr-title sm-h3 rm-m mt">{{ "KEYWORD.treatment" | translate | titlecase }} notes</h3>
          </div>

          <div class="details">
            <div class="row clearfix">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
                ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
                fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
                deserunt mollit anim id est laborum.
              </p>
            </div>
          </div>
        </div>
      </div>

      <hr/>

      <div class="row clearfix">
        <div class="icons-col">
          <mat-icon class="fas {{'fa-tooth' | translate}}"></mat-icon>
        </div>
        <div class="row-content-col">
          <div class="row clearfix">
            <h3 class="sr-title sm-h3 rm-m mt">{{ "KEYWORD.treatment" | translate | titlecase }} items</h3>
            <label>Information and documentation regarding FirstName's
              required {{"KEYWORD.treatment" | translate}}</label>
          </div>

          <div class="details">
            <div class="row clearfix flex">
              <p>Category / Type / Brand</p>
              <p>Qty: <strong>1</strong></p>
              <p><strong>Product Name</strong></p>
              <button mat-icon-button>
                <mat-icon>more_vert</mat-icon>
              </button>
            </div>
            <div class="row clearfix flex">
              <p>Category / Type / Brand</p>
              <p>Qty: <strong>1</strong></p>
              <p><strong>Product Name</strong></p>
              <button mat-icon-button>
                <mat-icon>more_vert</mat-icon>
              </button>
            </div>
            <div class="row clearfix flex">
              <p>Category / Type / Brand</p>
              <p>Qty: <strong>1</strong></p>
              <p><strong>Product Name</strong></p>
              <button mat-icon-button>
                <mat-icon>more_vert</mat-icon>
              </button>
            </div>
            <div class="row clearfix flex">
              <p>Category / Type / Brand</p>
              <p>Qty: <strong>1</strong></p>
              <p><strong>Product Name</strong></p>
              <button mat-icon-button>
                <mat-icon>more_vert</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>

      <hr/>

      <div class="row clearfix">
        <div class="icons-col">
          <mat-icon class="fas fa-file-alt"></mat-icon>
        </div>
        <div class="row-content-col">
          <div class="row clearfix">
            <h3 class="sr-title sm-h3 rm-m mt">{{ "KEYWORD.treatment" | translate | titlecase }} documents</h3>
            <label>Uploaded case file documents from <strong>ReferringMerchantName</strong></label>
          </div>

          <div class="details">
            <div class="row clearfix">
              <p class="small">file list</p>
            </div>
          </div>
        </div>
      </div>

      <div class="row clearfix text-right">
        <hr/>
        <button mat-button color="primary">Print copy <mat-icon>print</mat-icon></button>
        <button mat-button color="primary">View costings <mat-icon>attach_money</mat-icon></button>
        <button
          mat-raised-button
          color="accent"
          [matTooltip]="'Start a new invitation based on this ' + ('KEYWORD.treatment' | translate) + ' information'"
        >
          New {{ "KEYWORD.treatment" | translate }} invitation
          <mat-icon>send</mat-icon>
        </button>
      </div>
    </mat-card>
  </div>
</div>
