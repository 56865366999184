<div class="rel" *ngIf="listDB.firstLoadEvent == false" @ngIfAnimation>
  <div class="row clearfix padded rel">
    <h1 class="dataHeader rm-mt transparent">Fetching data...</h1>
  </div>
</div>

<div [hidden]="listDB.data.length == 0">
  <div class="row clearfix">
    <h1 class="dataHeader rm-m pull-left">
      {{ title }}
      <span class="badge" [endVal]="dataSource.filteredData.length" countUp></span>
    </h1>

    <div class="pull-right rel buttonrow">
      <button class="clearfix pull-right" (click)="createNew()" mat-raised-button color="primary">
        <mat-icon>add</mat-icon>
        Create new Template
      </button>
    </div>

    <div class="pull-right selection-items" @ngIfAnimation>
      <button class="animated mr" (click)="selectAll()" mat-stroked-button color="" matTooltip="Select All">
        <mat-icon>border_all</mat-icon>
      </button>

      <button class="animated mr" *ngIf="selectedIDs && selectedIDs.length > 0" [matMenuTriggerFor]="ActionBtnmenu"
              mat-stroked-button matTooltip="Options">
        <mat-icon class="fas fa-cog"></mat-icon>  <span *ngIf="selectedIDs && selectedIDs.length>0"
          class="selected-number-item  accent-background">{{selectedIDs.length}}</span>
      </button>
      <mat-menu #ActionBtnmenu="matMenu">
        <button class="row select-btn" (click)="unselect()" mat-menu-item color="">
          <mat-icon>select_all</mat-icon>
          Deselect
        </button>

        <button class="row select-btn" (click)="disableGroup()" mat-menu-item color="">
          <mat-icon>cancel</mat-icon>
          Disable
        </button>

        <button class="row select-btn" (click)="enableGroup()" mat-menu-item color="">
          <mat-icon>check_circle</mat-icon>
          Enable
        </button>

        <mat-divider></mat-divider>

        <button class="row select-btn" (click)="addTagsGroup()" mat-menu-item color="">
          <mat-icon>label</mat-icon>
          Add Tags
        </button>

        <button class="row select-btn" (click)="removeTagsGroup()" mat-menu-item color="">
          <mat-icon>label_off</mat-icon>
          Remove Tags
        </button>

        <mat-divider></mat-divider>

        <button class="row select-btn" *ngIf="isPromoterOrAdmin == true" (click)="markDefaultGroup()" mat-menu-item
                color="">
          Set as {{ "BRANDING.Brand Name" | translate | titlecase }} Template
        </button>

        <button class="row select-btn" *ngIf="isPromoterOrAdmin == true" (click)="removeDefaultGroup()" mat-menu-item
                color="">
          Set as {{ "KEYWORD.practice" | translate | titlecase}} Template
        </button>

        <button class="row select-btn" *ngIf="selectedIDs && selectedIDs.length > 0" (click)="removeGroup()"
                mat-menu-item color="">
          <mat-icon>delete</mat-icon>
          Remove
        </button>
      </mat-menu>

      <!-- <button mat-stroked-button color="" class="animated" (click)="selectPage()"
        matTooltip="{{'Select Page' | translate }}">
        <mat-icon>border_outer</mat-icon>
      </button> -->

      <mat-button-toggle-group class="btn-group rm-m selectType" [(ngModel)]="typeFilter"
                               (ngModelChange)="setFilter({ value: searchInput, field: 'label' }, { value: typeFilter, field: 'type' })">
        <mat-button-toggle class="" [value]="null"> All</mat-button-toggle>

        <mat-button-toggle class="" [value]="'user'">
          <mat-icon class="fas fa-user"></mat-icon>
          {{ "KEYWORD.practice" | translate | titlecase}}
        </mat-button-toggle>

        <mat-button-toggle class="" [value]="'default'">
          <mat-icon class="fas {{'fa-tooth' | translate}}"></mat-icon>
          {{ "BRANDING.Brand Name" | translate | titlecase }}
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>

  <div class="row clearfix">
    <mat-card class="list-card">
      <div class="row clearfix mb searchArea primary-gradient-img">
        <div class="row clearfix flex">
          <div class="full-width flex animated fadeInDown">
            <mat-icon class="mt mr">search</mat-icon>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Label, Description...etc</mat-label>
              <input class="clearfix" [(ngModel)]="searchInput"
                (input)="setFilter({ value: searchInput, field: 'fullName' }, { value: typeFilter, field: 'type' })"
                     name="Labels" matInput/>
            </mat-form-field>
            <mat-form-field class="white thrd-width" *ngIf="merchantTags.length > 0" appearance="outline">
              <mat-label>Tags</mat-label>
              <mat-select class="" (selectionChange)="setFilter({ value: $event.value, field: 'Tags' })" name="Tag"
                          placeholder="Tag" multiple>
                <mat-option *ngFor="let a of merchantTags | orderBy" [value]="a">
                  {{ a }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="white thrd-width" *ngIf="productGroups.length > 0" appearance="outline">
              <mat-label>Product Groups</mat-label>
              <mat-select class="" (selectionChange)="setFilter({ value: $event.value, field: 'productGroups' })"
                          name="ProductGroups" placeholder="Product Groups" multiple>
                <mat-option *ngFor="let a of productGroups | orderBy" [value]="a">
                  {{ a }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>

      <!-- Table -->
      <mat-table class="list-table" #table [dataSource]="dataSource" matSort>
        <ng-container cdkColumnDef="selectCheckBox" sticky>
          <mat-header-cell *cdkHeaderCellDef></mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <div class="margin-btm pull-left">
              <mat-checkbox [disabled]="isPromoterOrAdmin != true && row.type == 'default'"
                            [checked]="isSelected(row['_id'])" (change)="select(row['_id'], $event.checked)"
                            color="accent">
              </mat-checkbox>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Label" sticky>
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Label</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="viewTreatment(row['_id'])" matTooltip="{{ row['label'] }}">
            <h3>
              {{ row['label'] }}
              <span class="value chip-new pull-right" *ngIf="isNew(row['created_at']) == true">NEW</span>
            </h3>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Tags">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Tags</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="viewTreatment(row['_id'])">
            <div *ngIf="row && row.content && row.content.tags">
              <mat-chip-list>
                <mat-chip *ngFor="let tag of row.content.tags" [ngClass]="{ 'default-chip': indexOfTag(tag) }">
                  {{ tag }}
                </mat-chip>
              </mat-chip-list>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="productGroupLabel">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Product Group</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="viewTreatment(row['_id'])"
                    matTooltip="{{ row['productGroupLabel'] }}">
            <span class="text-center"> {{ row['productGroupLabel'] || 'All Product Groups' }}</span>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Description">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Description</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="viewTreatment(row['_id'])" matTooltip="{{ row['description'] }}">
            <span class="truncate"> {{ row['description'] }} </span></mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Value">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Value</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="viewTreatment(row['_id'])">
            <span *ngIf="row && row['content'] && row['content']['totalTreatmentValue']">
              {{ row['content']['totalTreatmentValue'] | customCurrency }}
            </span>

            <span *ngIf="row && (!row['content'] || (row['content'] && !row['content']['totalTreatmentValue']))">
              {{ 0 | customCurrency }}
            </span>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="applyCount">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Times used</mat-header-cell>
          <mat-cell class="text-center" *cdkCellDef="let row" (click)="viewTreatment(row['_id'])"
                    matTooltip="This Template has been applied {{ row.applyCount }} Times">
            <p class="text-center rm-m full-width">{{ row['applyCount'] }}</p>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Date">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Last update</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="viewTreatment(row['_id'])"
                    matTooltip="{{ row['created_at'] | customDateTime }}">
            <span> {{ row['created_at'] | TimeLabelPast }} ago</span>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="type">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Type</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="viewTreatment(row['_id'])">
            <span class="chip chip-enable" *ngIf="row.type != 'default'">
              <mat-icon class="fas fa-user"></mat-icon>
              {{ getTypeLabel(row.type) }}
            </span>
            <span class="chip" *ngIf="row.type == 'default'">
              <mat-icon class="fas {{'fa-tooth' | translate}}"></mat-icon>
              {{ getTypeLabel(row.type) }}
            </span>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="IsActive">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Active</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="viewTreatment(row['_id'])">
            <span class="list-label smaller" *ngIf="row['isActive'] == true" matTooltip="Active"
                  style="background-color: rgb(90, 186, 71)">
              <mat-icon>check</mat-icon>
            </span>
            <span class="list-label smaller" *ngIf="row['isActive'] != true" matTooltip="Inactive"
                  style="background-color: rgb(239, 71, 39)">
              <mat-icon>close</mat-icon>
            </span>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Actions">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Actions</mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <div style="float: right">
              <button [matMenuTriggerFor]="menu" mat-icon-button>
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button (click)="viewTreatment(row['_id'])" mat-menu-item>
                  <mat-icon>description</mat-icon>
                  <span>View Template</span>
                </button>

                <button (click)="clone(row)" mat-menu-item>
                  <mat-icon>file_copy</mat-icon>
                  <span>Clone and Edit</span>
                </button>

                <button *ngIf="row && (row.type != 'default' || (row.type == 'default' && isPromoterOrAdmin == true))"
                        (click)="editTreatment(row['_id'])" mat-menu-item>
                  <mat-icon>edit</mat-icon>
                  <span>Edit Template</span>
                </button>

                <button *ngIf="row['_id']" (click)="viewAllThirdParty(row['_id'])" mat-menu-item>
                  <mat-icon class="fas fa-user-shield"></mat-icon>
                  <span>Third Party Links</span>
                </button>

                <hr/>

                <button *ngIf="
                    row['isActive'] == true &&
                    (row.type != 'default' || (row.type == 'default' && isPromoterOrAdmin == true))
                  " (click)="disable(row['_id'])" mat-menu-item>
                  <mat-icon>cancel</mat-icon>
                  <span>Disable</span>
                </button>

                <button *ngIf="
                    row['isActive'] != true &&
                    (row.type != 'default' || (row.type == 'default' && isPromoterOrAdmin == true))
                  " (click)="enable(row['_id'])" mat-menu-item>
                  <mat-icon>check_circle</mat-icon>
                  <span>Enable</span>
                </button>

                <button *ngIf="row.type != 'default' && isPromoterOrAdmin == true" (click)="markDefault(row['_id'])"
                        mat-menu-item>
                  <mat-icon>ballot</mat-icon>
                  <span>Convert to a {{ "BRANDING.Brand Name" | translate | titlecase }} Template</span>
                </button>

                <button *ngIf="row.type == 'default' && isPromoterOrAdmin == true" (click)="removeDefault(row['_id'])"
                        mat-menu-item>
                  <mat-icon>ballot</mat-icon>
                  <span>Convert to a {{ "KEYWORD.practice" | translate | titlecase}} Template</span>
                </button>

                <button *ngIf="row.type != 'default' || (row.type == 'default' && isPromoterOrAdmin == true)"
                        (click)="remove(row['_id'])" mat-menu-item>
                  <mat-icon>delete</mat-icon>
                  <span>Remove</span>
                </button>
              </mat-menu>
            </div>
          </mat-cell>
        </ng-container>
        <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *cdkRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
      <mat-paginator #paginator [length]="dataSource.filteredData.length" [pageIndex]="0" [pageSize]="pageSize"
                     [pageSizeOptions]="pageSizeOptions">
      </mat-paginator>
    </mat-card>
  </div>
</div>
<div *ngIf="listDB.data.length == 0 && listDB.firstLoadEvent != false">
  <div class="pull-right rel buttonrow">
    <button class="clearfix pull-right" (click)="createNew()" mat-raised-button color="primary">
      <mat-icon>add</mat-icon>
      Create new Template
    </button>
  </div>

  <app-empty-list-message></app-empty-list-message>
</div>
