<div class="row clearfix masonry" *ngIf="quotation && patient">
  <!-- <div class="row clearfix">
    <h2 class="sr-title ndge rm-mt pull-left">Application referred</h2>


  </div> -->
  <mat-card class="mb clearfix">
    <div class="card-header primary-gradient-img row">
      <div class="row clearfix flex flex-center mb">
        <div class="full-width">
          <div class="row clearfix">
            <app-ep-document-view-image
              class="patientView userIconView pull-left mr"
              [loading]="loading"
              [defaultPicture]="
                'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/profile_placeholder.png)'
              "
            ></app-ep-document-view-image>

            <h2 class="summary-header rm-m white">
              {{ patient['CalculatedName'] }}
              <span class="subLabel">
                Quote Date: <strong>{{ quotation['Quotation.Date'] | customDate }}</strong></span
              >
            </h2>
          </div>
        </div>

        <div class="full-width ref-container">
          <div class="row clearfix text-right">
            <!-- referred -->
            <p class="lead white" *ngIf="quotation['Quotation.Status.Label'] != 'Won'">Referred</p>

            <!-- converted -->
            <p
              class="lead white"
              *ngIf="quotation['Quotation.Status.Label'] == 'Won' && quotation['ContractDate'] == '0000-00-00'"
            >
              Converted to contract
            </p>

            <!-- active -->
            <p class="lead white" *ngIf="quotation['ContractDate'] && quotation['ContractDate'] != '0000-00-00'">
              Contract is active
            </p>
          </div>
        </div>
      </div>

      <div class="action-button">
        <button class="actionMenuToggle" [matMenuTriggerFor]="menu" mat-raised-button>
          Actions <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button (click)="gotoCustomerProfile()" mat-menu-item>
            <mat-icon class="fas fa-user"></mat-icon>
            <span>View {{ "KEYWORD.patient" | translate | titlecase }}</span>
          </button>

          <mat-divider></mat-divider>

          <button (click)="gotoMerchantProfile()" mat-menu-item>
            <mat-icon class="fas fa-store"></mat-icon>
            <span>View Merchant</span>
          </button>

          <!-- <button mat-menu-item>
            <mat-icon class="fa fa-list-alt"></mat-icon>
            <span>{{"View merchant logs" | translate}}</span>
          </button>
          <button mat-menu-item>
            <mat-icon class="fa fa-key"></mat-icon>
            <span>{{"Merchant Access" | translate}}</span>
          </button> -->
        </mat-menu>
      </div>
    </div>

    <div class="row clearfix flex mt" *ngIf="quotation">
      <div class="row clearfix full-width value mt">
        <p class="bold">
          <mat-icon class="fas fa-phone"></mat-icon>
          {{ quotation['Customer.Mobile'] | customPhone }}
        </p>

        <p class="bold">
          <mat-icon class="fas fa-envelope"></mat-icon>
          {{ quotation['Customer.Email'] }}
        </p>

        <p class="bold">
          <mat-icon class="fas fa-map-marker-alt"></mat-icon>
          {{ patient['addresses.Calculated'] }}
        </p>
      </div>
      <div class="row clearfix full-width">
        <div class="block">
          <mat-icon class="fas fa-hand-holding-heart"></mat-icon>
          <label>Product</label>
          <h4 class="sr-title rm-mt">{{ quotation['ProductName'] }}</h4>
        </div>
      </div>

      <div class="row clearfix full-width">
        <div class="block">
          <app-dentist-view-picture class="headNavView userIconView"></app-dentist-view-picture>
          <label>{{ "KEYWORD.practitioner" | translate | titlecase }}</label>
          <h4 class="sr-title rm-mt">{{ quotation['MerchantName'] }}</h4>
        </div>
      </div>
    </div>

    <!-- <div class="row clearfix">
    {{quotation['Quotation.Status.Label']}}
</div> -->
  </mat-card>
</div>

<div class="row clearfix" *ngIf="quotation && patient">
  <!--  referred -->

  <mat-tab-group
    class="tab-container"
    [(selectedIndex)]="setReferredTab"
    (selectedIndexChange)="subTabChange()"
    dynamicHeight
  >
    <mat-tab #overview label="Overview">
      <ng-template mat-tab-label>
        <mat-icon class="fas fa-tasks"></mat-icon>
        Overview
      </ng-template>

      <ng-template matTabContent>
        <mat-card class="mb" *ngIf="quotation['ContractDate'] == '0000-00-00'">
          <div
            class="mb"
            *ngIf="quotation['Quotation.Status.Label'] == 'Won' && quotation['ContractDate'] == '0000-00-00'"
          >
            <p class="confirm">
              <mat-icon class="fas fa-file-signature"></mat-icon>
              Converted to contract
            </p>
          </div>

          <div class="colour-stripe"></div>

          <button class="pull-right pos" [matMenuTriggerFor]="menu" mat-raised-button color="accent">
            <mat-icon>sync</mat-icon>
            Convert To ...
          </button>

          <mat-menu #menu="matMenu">
            <section *ngFor="let p of possibleStatus; let i = index">
              <button (click)="changeStatus(p)" mat-menu-item>
                {{ p['NewStatus.Code'] }}
              </button>
              <hr *ngIf="i == 0"/>
            </section>
          </mat-menu>

          <h3 class="sr-title sm-h3 rm-m">Overview</h3>
          <hr/>

          <div class="overview-table">
            <div class="row clearfix flex">
              <div class="half-width">
                <p class="small">Check Performed</p>
              </div>

              <div class="thrd-width">
                <p class="small">Result</p>
              </div>

              <div class="full-width">
                <p class="small">Result of review</p>
              </div>
            </div>
            <hr/>
            <!-- Capacity Check, dont show if payment plan -->
            <div class="row clearfix flex" *ngIf="quotation['ProductName'] != 'Payment Plan'">
              <div class="half-width">
                <p class="rm-m larger">Capacity Check</p>
              </div>

              <div class="thrd-width">
                <p>
                  <strong
                    class="chip pass"
                    *ngIf="quotation['CapacityCheck.Status'] && quotation['CapacityCheck.Status'] == 'Accept'"
                  >
                    <mat-icon class="fas fa-thumbs-up"></mat-icon>
                    PASS
                  </strong>
                  <strong
                    class="chip fail"
                    *ngIf="quotation['CapacityCheck.Status'] && quotation['CapacityCheck.Status'] != 'Accept'"
                  >
                    <mat-icon class="fas fa-thumbs-down"></mat-icon>
                    FAIL
                  </strong>
                  <strong class="chip fail" *ngIf="!quotation['CapacityCheck.Status']">
                    <mat-icon class="fas fa-question-circle"></mat-icon>
                    Not Performed
                  </strong>
                </p>
              </div>

              <div class="full-width">
                <div class="row clearfix flex">
                  <button class="btn-small view-btn" (click)="changeTabs(1)" mat-raised-button color="primary">
                    View
                  </button>

                  <button
                    class="btn-small mr"
                    *ngIf="
                      quotation['CapacityCheck.Status'] == 'Hard Refer' || quotation['CapacityCheck.Status'] == 'Refer'
                    "
                    (click)="makeDecision('Capacity')"
                    mat-raised-button
                    color="accent"
                  >
                    Decide
                  </button>

                  <button
                    class="btn-small"
                    *ngIf="!quotation['CapacityCheck.Status']"
                    (click)="perform('Capacity')"
                    mat-raised-button
                    color="accent"
                  >
                    Perform <mat-icon>chevron_right</mat-icon>
                  </button>

                  <p *ngIf="quotation['CapacityCheck.Status']">
                    <strong>{{ quotation['CapacityCheck.Status'] }}</strong>
                    {{ quotation['CapacityCheck'] | customDate }} - {{ quotation['AccountManagerName'] }}
                  </p>

                  <button
                    class="btn-small mr"
                    *ngIf="quotation['CapacityCheck.Status'] == 'Decline'"
                    (click)="makeDecision('Capacity')"
                    mat-raised-button
                    color="accent"
                  >
                    Override
                  </button>
                </div>
              </div>
            </div>
            <hr *ngIf="quotation['ProductName'] != 'Payment Plan'"/>
            <!-- Credit Check -->
            <div class="row clearfix flex" [class.ytd]="!quotation['CreditCheck.Status']">
              <div class="half-width">
                <p class="rm-m larger">Credit Check</p>
              </div>

              <div class="thrd-width">
                <p>
                  <strong
                    class="chip pass"
                    *ngIf="quotation['CreditCheck.Status'] && quotation['CreditCheck.Status'] == 'Accept'"
                  >
                    <mat-icon class="fas fa-thumbs-up"></mat-icon>
                    PASS
                  </strong>
                  <strong
                    class="chip fail"
                    *ngIf="quotation['CreditCheck.Status'] && quotation['CreditCheck.Status'] != 'Accept'"
                  >
                    <mat-icon class="fas fa-thumbs-down"></mat-icon>
                    FAIL
                  </strong>
                  <strong class="chip default" *ngIf="!quotation['CreditCheck.Status']">
                    <mat-icon class="fas fa-question-circle"></mat-icon>
                    Not Performed
                  </strong>
                </p>
              </div>

              <div class="full-width">
                <div class="row clearfix flex">
                  <button class="btn-small view-btn" (click)="changeTabs(2)" mat-raised-button color="primary">
                    View
                  </button>

                  <button
                    class="btn-small"
                    *ngIf="
                      quotation['CreditCheck.Status'] == 'Hard Refer' || quotation['CreditCheck.Status'] == 'Refer'
                    "
                    (click)="makeDecision('Credit')"
                    mat-raised-button
                    color="accent"
                  >
                    Decide
                  </button>

                  <button
                    class="btn-small"
                    *ngIf="!quotation['CreditCheck.Status']"
                    (click)="perform('Credit')"
                    mat-raised-button
                    color="accent"
                  >
                    Perform <mat-icon>chevron_right</mat-icon>
                  </button>

                  <p *ngIf="quotation['CreditCheck.Status']">
                    <strong>{{ quotation['CreditCheck.Status'] }}</strong> {{ quotation['CreditCheck'] | customDate }} -
                    {{ quotation['AccountManagerName'] }}
                  </p>

                  <button
                    class="btn-small mr"
                    *ngIf="quotation['CreditCheck.Status'] == 'Decline'"
                    (click)="makeDecision('Credit')"
                    mat-raised-button
                    color="accent"
                  >
                    Override
                  </button>
                </div>
              </div>
            </div>
            <hr/>
            <!-- ID Check -->
            <div class="row clearfix flex" [class.ytd]="!quotation['IdentityCheckStatus']">
              <div class="half-width">
                <p class="rm-m larger">ID Check</p>
              </div>

              <div class="thrd-width">
                <p>
                  <strong
                    class="chip pass"
                    *ngIf="quotation['IdentityCheckStatus'] && quotation['IdentityCheckStatus'] == 'Accept'"
                  >
                    <mat-icon class="fas fa-thumbs-up"></mat-icon>
                    PASS
                  </strong>
                  <strong
                    class="chip fail"
                    *ngIf="quotation['IdentityCheckStatus'] && quotation['IdentityCheckStatus'] != 'Accept'"
                  >
                    <mat-icon class="fas fa-thumbs-down"></mat-icon>
                    FAIL
                  </strong>
                  <strong class="chip default" *ngIf="!quotation['IdentityCheckStatus']">
                    <mat-icon class="fas fa-question-circle"></mat-icon>
                    Not Performed
                  </strong>
                </p>
              </div>

              <div class="full-width">
                <div class="row clearfix flex">
                  <button class="btn-small view-btn" (click)="changeTabs(3)" mat-raised-button color="primary">
                    View
                  </button>

                  <button
                    class="btn-small"
                    *ngIf="
                      quotation['IdentityCheckStatus'] == 'Hard Refer' || quotation['IdentityCheckStatus'] == 'Refer'
                    "
                    (click)="makeDecision('Identity')"
                    mat-raised-button
                    color="accent"
                  >
                    Decide
                  </button>

                  <button
                    class="btn-small"
                    *ngIf="!quotation['IdentityCheckStatus']"
                    (click)="perform('Identity')"
                    mat-raised-button
                    color="accent"
                  >
                    Perform <mat-icon>chevron_right</mat-icon>
                  </button>

                  <p *ngIf="quotation['IdentityCheckStatus']">
                    <strong>{{ quotation['IdentityCheckStatus'] }}</strong>
                    {{ quotation['IdentityCheck'] | customDate }} - {{ quotation['AccountManagerName'] }}
                  </p>

                  <button
                    class="btn-small mr"
                    *ngIf="quotation['IdentityCheckStatus'] == 'Decline'"
                    (click)="makeDecision('Identity')"
                    mat-raised-button
                    color="accent"
                  >
                    Override
                  </button>
                </div>
              </div>
            </div>
            <hr/>

            <!-- Document Check -->
            <div class="row clearfix flex" [class.ytd]="!quotation['IdentityCheckStatus']">
              <div class="half-width">
                <p class="rm-m larger">Supporting Documents</p>
              </div>

              <div class="thrd-width">
                <p>
                  <strong class="chip pass" *ngIf="supportingDocPromoterDecision == 'PASS'">
                    <mat-icon class="fas fa-thumbs-up"></mat-icon>
                    Complete
                  </strong>
                  <strong class="chip fail" *ngIf="supportingDocPromoterDecision == 'REJECT'">
                    <mat-icon class="fas fa-thumbs-down"></mat-icon>
                    Waiting on borrower
                  </strong>
                  <strong class="chip default" *ngIf="supportingDocPromoterDecision == 'TODO'">
                    <mat-icon class="fas fa-question-circle"></mat-icon>
                    To be done
                  </strong>
                </p>
              </div>

              <div class="full-width">
                <div class="row clearfix flex">
                  <button class="btn-small view-btn" (click)="changeTabs(6)" mat-raised-button color="primary">
                    View
                  </button>

                  <!-- <button mat-raised-button color="accent" class="btn-small"
                    *ngIf="quotation['IdentityCheckStatus']=='Hard Refer' || quotation['IdentityCheckStatus']=='Refer'"
                    (click)="makeDecision('Identity')">
                    Decide
                  </button> -->

                  <!-- <button mat-raised-button color="accent" class="btn-small" *ngIf="!quotation['IdentityCheckStatus']"
                    (click)="perform('Identity')">
                    Perform <mat-icon>chevron_right</mat-icon>
                  </button> -->

                  <!-- <p *ngIf="quotation['IdentityCheckStatus']"><strong>{{quotation['IdentityCheckStatus']}}</strong>
                    {{quotation['IdentityCheck'] | customDate}} - {{quotation['AccountManagerName']}}</p>

                  <button mat-raised-button color="accent" class="btn-small mr"
                    *ngIf="quotation['IdentityCheckStatus']=='Decline'" (click)="makeDecision('Identity')">
                    Override
                  </button> -->
                </div>
              </div>
            </div>
            <hr/>

            <!-- Deposit and Payment -->
            <div class="row clearfix flex" *ngIf="false" [class.ytd]="!quotation['IdentityCheckStatus']">
              <div class="half-width">
                <p class="rm-m larger">Deposit &amp; First Payment</p>
              </div>

              <div class="thrd-width">
                <p>
                  <strong
                    class="chip pass"
                    *ngIf="quotation['IdentityCheckStatus'] && quotation['IdentityCheckStatus'] == 'Accept'"
                  >
                    <mat-icon class="fas fa-thumbs-up"></mat-icon>
                    Paid
                  </strong>
                  <strong class="chip default" *ngIf="!quotation['IdentityCheckStatus']">
                    <mat-icon class="fas fa-question-circle"></mat-icon>
                    Pending
                  </strong>
                </p>
              </div>

              <div class="full-width">
                <div class="row clearfix flex">
                  <button class="btn-small view-btn" (click)="changeTabs(3)" mat-raised-button color="primary">
                    View
                  </button>

                  <button
                    class="btn-small"
                    *ngIf="
                      quotation['IdentityCheckStatus'] == 'Hard Refer' || quotation['IdentityCheckStatus'] == 'Refer'
                    "
                    (click)="makeDecision('Identity')"
                    mat-raised-button
                    color="accent"
                  >
                    Decide
                  </button>

                  <!--
                  <button mat-raised-button color="accent" class="btn-small" *ngIf="!quotation['IdentityCheckStatus']"
                    (click)="perform('Identity')">
                    Perform <mat-icon>chevron_right</mat-icon>
                  </button> -->

                  <p *ngIf="quotation['IdentityCheckStatus']">
                    <strong>{{ quotation['IdentityCheckStatus'] }}</strong>
                    {{ quotation['IdentityCheck'] | customDate }} - {{ quotation['AccountManagerName'] }}
                  </p>

                  <button
                    class="btn-small mr"
                    *ngIf="quotation['IdentityCheckStatus'] == 'Decline'"
                    (click)="makeDecision('Identity')"
                    mat-raised-button
                    color="accent"
                  >
                    Override
                  </button>
                </div>
              </div>
            </div>
            <hr/>
          </div>

          <!-- <div class="row clearfix">
            <table mat-table [dataSource]="dataReferred" class="full-width mb">


              <ng-container matColumnDef="check">
                <th mat-header-cell *matHeaderCellDef> Check Performed </th>
                <td mat-cell *matCellDef="let element"> {{element.check}} </td>
              </ng-container>


              <ng-container matColumnDef="result">
                <th mat-header-cell *matHeaderCellDef> Result </th>
                <td mat-cell *matCellDef="let element">

                  <ng-container *ngIf="element.result=='PASS'">
                    <strong class="chip pass">
                      <mat-icon class="fas fa-thumbs-up"></mat-icon>
                      {{element.result}}
                    </strong>
                  </ng-container>
                  <ng-container *ngIf="element.result=='FAIL'">
                    <strong class="chip fail">
                      <mat-icon class="fas fa-thumbs-down"></mat-icon>
                      {{element.result}}
                    </strong>
                  </ng-container>
                </td>
              </ng-container>



              <ng-container matColumnDef="view">
                <th mat-header-cell *matHeaderCellDef> </th>
                <td mat-cell *matCellDef="let element">
                  <button mat-raised-button color="primary" class="btn-small">
                    View
                  </button>

                </td>
              </ng-container>


              <ng-container matColumnDef="review">
                <th mat-header-cell *matHeaderCellDef> Result of review </th>
                <td mat-cell *matCellDef="let element">

                  <ng-container *ngIf="element.review=='approve'">
                    <strong class="green">
                      Approved 00/00/0000 - OperatorName
                    </strong>
                  </ng-container>
                  <ng-container *ngIf="element.review=='decide'">
                    <button mat-raised-button color="accent" class="btn-small">
                      Decide
                    </button>
                  </ng-container>

                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </div> -->
        </mat-card>

        <!-- <mat-card class="mb" *ngIf="quotation['ContractDate'] && quotation['ContractDate']!='0000-00-00'">
          <p>Contract is active as of {{quotation['ContractDate']}}</p>


        </mat-card> -->

        <app-contract-detail-overview
          *ngIf="quotation['ContractDate'] && quotation['ContractDate'] != '0000-00-00'"
          [contractID]="quotation['ID']"
        ></app-contract-detail-overview>
      </ng-template>
    </mat-tab>

    <mat-tab #capcheck label="Capacity Check">
      <ng-template mat-tab-label>
        <mat-icon class="fas fa-check-double"></mat-icon>
        Capacity Check
      </ng-template>

      <ng-template matTabContent>
        <!-- internal tabs -->
        <mat-tab-group
          class="sub-tab-group"
          *ngIf="quotation['ContractDate'] == '0000-00-00' && quotation['ProductName'] != 'Payment Plan'"
        >
          <!-- capacity check EP screen -->
          <mat-tab #capcheckov label="Capacity Check">
            <ng-template mat-tab-label>
              <mat-icon class="fas fa-check-double"></mat-icon>
              Capacity Check
            </ng-template>

            <ng-template matTabContent>
              <app-contract-capacity-check
                [contractID]="quotation['ID']"
                [isDisplayFull]="false"
                [checkstatus]="quotation['CapacityCheck.Status']"
              >
              </app-contract-capacity-check>
            </ng-template>
          </mat-tab>

          <!-- capacity check personal circumstances -->
          <mat-tab #overview label="Personal Circumstances">
            <ng-template mat-tab-label>
              <mat-icon class="fas fa-user-check"></mat-icon>
              Personal Circumstances
            </ng-template>
            <ng-template matTabContent>
              <!-- <mat-card class="mb no-pad">
                <div class="colour-stripe "></div>


                <div class="row clearfix">
                  <div class="column"> -->
              <app-personal-circumstances
                [contractID]="quotation['ID']"
                [checkstatus]="quotation['CapacityCheck.Status']"
              ></app-personal-circumstances>
              <!-- </div>
                </div>
              </mat-card> -->
            </ng-template>
          </mat-tab>
        </mat-tab-group>
      </ng-template>
    </mat-tab>

    <mat-tab #credcheck label="Credit Check">
      <ng-template mat-tab-label>
        <mat-icon class="fas fa-credit-card"></mat-icon>
        Credit Check
      </ng-template>

      <ng-template matTabContent>
        <div class="row clearfix flex" *ngIf="quotation['ContractDate'] == '0000-00-00'">
          <app-contract-credit-check
            [contractID]="quotation['ID']"
            [isDisplayFull]="false"
            [checkstatus]="quotation['CreditCheck.Status']"
          ></app-contract-credit-check>
        </div>
      </ng-template>
    </mat-tab>

    <mat-tab #idcheck label="ID Check">
      <ng-template mat-tab-label>
        <mat-icon class="fas fa-id-card"></mat-icon>
        ID Check
      </ng-template>

      <ng-template matTabContent>
        <app-contract-identity-check
          *ngIf="quotation['ContractDate'] == '0000-00-00'"
          [contractID]="quotation['ID']"
          [patient]="patient"
          [checkstatus]="quotation['IdentityCheckStatus.Status']"
        ></app-contract-identity-check>
      </ng-template>
    </mat-tab>

    <!-- show on all -->
    <mat-tab #notes label="Notes">
      <ng-template mat-tab-label>
        <mat-icon class="fas fa-sticky-note"></mat-icon>
        Notes
      </ng-template>

      <ng-template matTabContent>
        <mat-card class="">
          <div class="pull-right">
            <button (click)="creatNote()" mat-raised-button color="accent">New Note</button>
          </div>

          <app-event-log-list
            [largeHeading]="false"
            [type]="'contract'"
            [withoutAutoAction]="true"
            [isExpanded]="true"
            [contractID]="quotation['ID']"
          >
          </app-event-log-list>
        </mat-card>
      </ng-template>
    </mat-tab>

    <mat-tab #messages label="Messages">
      <ng-template mat-tab-label>
        <mat-icon class="fas fa-comments"></mat-icon>
        Messages
      </ng-template>

      <ng-template matTabContent>
        <mat-card class="">
          <app-message-timeline class="clearfix" [contractID]="quotation['ID']"></app-message-timeline>
        </mat-card>
      </ng-template>
    </mat-tab>

    <mat-tab #documents label="Supporting Documents">
      <ng-template mat-tab-label>
        <mat-icon class="fas fa-folder-open"></mat-icon>
        Supporting Documents
      </ng-template>

      <ng-template matTabContent>
        <app-contract-supporting-documents [contractID]="quotation['ID']"></app-contract-supporting-documents>
      </ng-template>
    </mat-tab>

    <!-- show if contract is active -->
    <mat-tab #documents *ngIf="quotation['ContractDate'] && quotation['ContractDate'] != '0000-00-00'" label="Payments">
      <ng-template mat-tab-label>
        <mat-icon class="fas fa-money-bill-wave"></mat-icon>
        Payments
      </ng-template>

      <ng-template matTabContent>
        <!-- internal tabs -->
        <mat-tab-group class="sub-tab-group">
          <!-- Payment Schedule EP screen -->
          <mat-tab #capcheckov label="Payment Schedule">
            <ng-template mat-tab-label>
              <mat-icon class="fas fa-check-double"></mat-icon>
              Payment Schedule
            </ng-template>

            <ng-template matTabContent>
              <app-contract-detail-payments [contractID]="quotation['ID']"></app-contract-detail-payments>
            </ng-template>
          </mat-tab>

          <!-- Invoices Settlements -->
          <mat-tab #overview label="Invoices &amp; Settlements">
            <ng-template mat-tab-label>
              <mat-icon class="fas fa-file-invoice-dollar"></mat-icon>
              Invoices &amp; Settlements
            </ng-template>
            <ng-template matTabContent>
              <app-contract-invoices-settlements [contractID]="quotation['ID']"></app-contract-invoices-settlements>
            </ng-template>
          </mat-tab>

          <!-- waivers and direct payment -->
          <mat-tab #overview label="Waivers &amp; Direct Payments">
            <ng-template mat-tab-label>
              <mat-icon class="fas fa-coins"></mat-icon>
              Waivers &amp; Direct Payments
            </ng-template>
            <ng-template matTabContent>
              <app-contract-waivers [contractID]="quotation['ID']"></app-contract-waivers>
            </ng-template>
          </mat-tab>

          <!-- Arrangements -->
          <mat-tab #overview label="Arrangements">
            <ng-template mat-tab-label>
              <mat-icon class="fas fa-handshake"></mat-icon>
              Arrangements
            </ng-template>
            <ng-template matTabContent>
              <app-contract-arrangements [contractID]="quotation['ID']"></app-contract-arrangements>
            </ng-template>
          </mat-tab>
        </mat-tab-group>
      </ng-template>
    </mat-tab>

    <mat-tab #payment label="Payment Methods">
      <ng-template mat-tab-label>
        <mat-icon class="fas fa-credit-card"></mat-icon>
        Payment Methods
      </ng-template>

      <ng-template matTabContent>
        <mat-card class="">
          <div class="colour-stripe"></div>
          <h3 class="sr-title sm-h3 rm-m">Payment Methods</h3>
          <hr/>
          <mat-card class="clearfix">
            <app-bank-account-list-user class="full-width" [userID]="patient['ID']"></app-bank-account-list-user>
          </mat-card>
        </mat-card>
      </ng-template>
    </mat-tab>

    <mat-tab #payment label="Audit History">
      <ng-template mat-tab-label>
        <mat-icon class="fas fa-clipboard-check"></mat-icon>
        Audit History
      </ng-template>

      <ng-template matTabContent>
        <mat-card class="">
          <app-event-log-list
            [largeHeading]="false"
            [type]="'contract'"
            [onlyAutoAction]="true"
            [isExpanded]="true"
            [contractID]="quotation['ID']"
          >
          </app-event-log-list>
        </mat-card>
      </ng-template>
    </mat-tab>
  </mat-tab-group>

  <!-- <mat-tab-group dynamicHeight="true" (selectedIndexChange)="tabChange($event)">
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="fas fa-calculator"></mat-icon> Calculator
        </ng-template>
        <div class="row clearfix">
          <div class="row clearfix flex">
            <mat-card class="clearfix full-width">
              <div class="colour-stripe "></div>

              <h3 class="sr-title sm-h3 rm-m">{{ "KEYWORD.patient" | translate | titlecase }}</h3>
              <hr>

              <div class="row clearfix">
                <div class="half-width block">
                  <mat-icon class="fas fa-user"></mat-icon>
                  <label>Name</label>
                  <h4 class="sr-title rm-mt">{{quotation['Customer.FullName']}}
                  </h4>
                </div>

                <div class="half-width block">
                  <mat-icon class="fas fa-mobile"></mat-icon>
                  <label>Mobile</label>
                  <h4 class="sr-title rm-mt">{{quotation['Customer.Mobile'] | customPhone}}
                  </h4>
                </div>
              </div>
              <div class="row clearfix">
                <div class="half-width block">
                  <mat-icon class="fas fa-envelope"></mat-icon>
                  <label>Email</label>
                  <h4 class="sr-title rm-mt">{{quotation['Customer.Email']}}
                  </h4>
                </div>

                <div class="half-width block">
                  <mat-icon class="fas fa-map-marked-alt"></mat-icon>
                  <label>Address</label>
                  <h4 class="sr-title rm-mt">{{patient['addresses.Calculated']}}
                  </h4>
                </div>
              </div>

            </mat-card>

            <mat-card class="clearfix full-width">
              <div class="colour-stripe "></div>
              <h3 class="sr-title sm-h3 rm-m">Funding Details</h3>
              <hr>

              <div class="row clearfix">
                <div class="half-width block">

                  <app-dentist-view-picture class=" headNavView userIconView">
                  </app-dentist-view-picture>
                  <label>Funder</label>
                  <h4 class="sr-title rm-mt">{{quotation['FunderName']}}
                  </h4>
                </div>

                <div class="half-width block">
                  <mat-icon class="fas fa-hand-holding-heart"></mat-icon>
                  <label>Product</label>
                  <h4 class="sr-title rm-mt">{{quotation['ProductName']}}
                  </h4>
                </div>
              </div>
              <div class="row clearfix">
                <div class="half-width block">
                  <mat-icon class="fas fa-question-circle"></mat-icon>
                  <label>Product Type</label>
                  <h4 class="sr-title rm-mt">{{quotation['ProductTypeLabel']}}
                  </h4>
                </div>

                <div class="half-width block">
                  <app-dentist-view-picture class=" headNavView userIconView">
                  </app-dentist-view-picture>
                  <label>{{ "KEYWORD.practitioner" | translate | titlecase }}</label>
                  <h4 class="sr-title rm-mt">{{quotation['MerchantName']}}
                  </h4>
                </div>
              </div>

            </mat-card>
          </div>

          <div class="row clearfix flex">
         <mat-card class="clearfix full-width">
              <div class="colour-stripe "></div>
              <h3 class="sr-title sm-h3 rm-m"></h3>
            </mat-card>
            <h2 class="sr-title">
              Invioce Details as Anticipated
            </h2>
          </div>

          <div class="row clearfix flex">
            <mat-card class="clearfix full-width">
              <div class="colour-stripe "></div>
              <h3 class="sr-title sm-h3 rm-m">{{ "KEYWORD.patient" | translate | titlecase }}</h3>
              <hr>


              <mat-list class="values-list calculation">
                <mat-list-item class="total accent-color">
                  <p class="bold accent-color">Invoice Amount:</p>
                  <span class="value accent-color">{{quotation['Amount.Invoice'] | customCurrency}}</span>
                </mat-list-item>
                <mat-list-item>
                  <p>
                    <mat-icon class="fas fa-minus-circle"></mat-icon> {{ "KEYWORD.patient" | translate | titlecase }} Contribution:
                  </p>
                  <span class="value">{{quotation['Amount.Contribution'] | customCurrency}}</span>
                </mat-list-item>
                <mat-list-item class="">
                  <p class="bold primary-color">
                    <mat-icon class="fas fa-equals"></mat-icon> Loan Amount:
                  </p>
                  <span
                    class=" primary-color value">{{quotation['Amount.FinancedBeforeDeposit'] | customCurrency}}</span>
                </mat-list-item>
                <mat-list-item>

                  <p>
                    <mat-icon class="fas fa-minus-circle"></mat-icon> Deposit:
                  </p>
                  <span class="value">{{quotation['Deposit'] | customCurrency}}</span>
                </mat-list-item>
                <mat-list-item>
                  <p class="bold primary-color">
                    <mat-icon class="fas fa-equals"></mat-icon> Amount Financed:
                  </p>
                  <span class=" primary-color value">{{quotation['Amount.Financed'] | customCurrency}}</span>
                </mat-list-item>
                <mat-list-item>

                  <p>
                    <mat-icon class="fas fa-plus-circle"></mat-icon> Establishment Fee:
                  </p>
                  <span class="value">{{quotation['EstablishmentFee'] | customCurrency}}</span>

                </mat-list-item>
                <mat-list-item>
                  <p>
                    <mat-icon class="fas fa-plus-circle"></mat-icon> Interest:
                  </p>
                  <span class="value">{{quotation['OriginalInterest'] | customCurrency}}</span>
                </mat-list-item>
                <mat-list-item>
                  <p>
                    <mat-icon class="fas fa-plus-circle"></mat-icon> Account Keeping Fees:
                  </p>
                  <span class="value">{{quotation['AccountKeepingFees'] | customCurrency}}</span>

                </mat-list-item>
                <mat-list-item>
                  <p>
                    <mat-icon class="fas fa-plus-circle"></mat-icon> Commission:
                  </p>
                  <span class="value">{{quotation['CustomerCommission'] | customCurrency}}</span>
                </mat-list-item>
                <mat-list-item>
                  <p class="primary-color">
                    <mat-icon class="fas fa-equals"></mat-icon> Sub Total:
                  </p>
                  <span class="primary-color value">
                    {{(toNumber(quotation['EstablishmentFee']) + toNumber(quotation['OriginalInterest']) + toNumber(quotation['AccountKeepingFees'])
                + toNumber(quotation['CustomerCommission'])) | customCurrency}}
                  </span>
                </mat-list-item>
                <mat-list-item class="primary-bg total">

                  <p class="bold">Total ex. Deposit:</p>
                  <span class="value">{{quotation['Amount.Loan.Original'] | customCurrency}}</span>

                </mat-list-item>
                <mat-list-item>
                  <p class="primary-color"><strong>Total inc. Deposit:</strong> </p>
                  <span class="primary-color value">{{quotation['Amount.Original'] | customCurrency}}</span>

                </mat-list-item>
              </mat-list>

            </mat-card>

            <div class="full-width card-column">

              <mat-card class="clearfix">
                <div class="colour-stripe "></div>
                <h3 class="sr-title sm-h3 rm-m">Payment Details</h3>
                <hr>
                <div class="row clearfix">
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Repayment Duration</mat-label>
                    <input matInput name="duration" placeholder="Repayment Duration" [(ngModel)]="selectedDuration"
                      (change)="changeDuration($event.target.value)"
                      [disabled]="quotation['Quotation.Status.Code']!='PENDING'">
                  </mat-form-field>
                </div>
                <div class="row clearfix">

                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Repayment Frequency</mat-label>

                    <mat-select class="full-width" placeholder="Repayment Frequency" [(ngModel)]="selectedFrequency"
                      (ngModelChange)="changeFrequency($event)"
                      [disabled]="quotation['Quotation.Status.Code']!='PENDING' && quotation['Quotation.Status.Code']!='COMPLETE'">
                      <mat-mat-option *ngFor="let f of frequency" [value]="f['Code']">{{f['Label']}}</mat-mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="row clearfix text-center"
                  *ngIf="quotation['Quotation.Status.Code']=='PENDING' || quotation['Quotation.Status.Code']=='COMPLETE'">
                  <button mat-raised-button color="primary" class="btn-small text-center"
                    [disabled]="enableSubmitBtn==false" (click)="submit()">
                    {{"ChangeFrequency" | translate}}
                  </button>
                </div>
              </mat-card>

              <mat-card class="clearfix">
                <div class="colour-stripe "></div>
                <div class="row clearfix">
                  <h3 class="sr-title sm-h3 rm-m">Interest Rate(flat)</h3>
                  <hr>
                  <div class="row clearfix">
                    <div class="half-width text-center">
                      <p class="lessmb">Annual</p>
                      <h3 class="primary-color rm-m">{{quotation['InterestRate.Annual']}}%</h3>
                    </div>
                    <div class="half-width text-center">
                      <p class="lessmb">True</p>
                      <h3 class="primary-color rm-m">{{quotation['InterestRate.True']}}%</h3>
                    </div>
                  </div>
                </div>
              </mat-card>

              <mat-card class="clearfix">
                <div class="colour-stripe "></div>
                <div class="row clearfix">
                  <h3 class="sr-title sm-h3 rm-m">Promotion</h3>
                  <hr>
                  <mat-list class="values-list">
                    <mat-list-item *ngIf="quotation['PromoCode']">
                      <p>Code:</p>
                      <span class="value">{{quotation['PromoCode']}}</span>
                    </mat-list-item>
                    <mat-list-item>
                      <p>Interest Free Period:</p>
                      <span class="value">MISSING</span>
                    </mat-list-item>
                  </mat-list>
                </div>
              </mat-card>

            </div>

            <mat-card class="clearfix full-width">
              <div class="colour-stripe "></div>
              <h3 class="sr-title sm-h3 rm-m">{{ "KEYWORD.practitioner" | translate | titlecase }}</h3>
              <hr>
              <mat-list class="values-list calculation">
                <mat-list-item class="total primary-color">
                  <p><strong>Draw Downs:</strong></p>
                  <span class="value"><strong>{{quotation['TreatmentValue'] | customCurrency}}</strong></span>
                </mat-list-item>
                <mat-list-item>
                  <p><em>Less {{ "KEYWORD.practitioner" | translate | titlecase }} Contribution</em></p>
                </mat-list-item>
                <mat-list-item>
                  <p>Interest:</p>
                  <span class="value">{{quotation['Merchant.Interest'] | customCurrency}}</span>
                </mat-list-item>
                <mat-list-item>
                  <p>
                    <mat-icon class="fas fa-plus-circle"></mat-icon> Commission:
                  </p>
                  <span class="value">{{quotation['Merchant.Commission'] | customCurrency}}</span>
                </mat-list-item>
                <mat-list-item>
                  <p>
                    <mat-icon class="fas fa-plus-circle"></mat-icon> Establishment Fee:
                  </p>
                  <span class="value">{{quotation['Merchant.EstablishmentFee'] | customCurrency}}</span>
                </mat-list-item>
                <mat-list-item>
                  <p>
                    <mat-icon class="fas fa-plus-circle"></mat-icon> Account Keeping Fees:
                  </p>
                  <span class="value">{{quotation['Merchant.AccountKeepingFees'] | customCurrency}}</span>
                </mat-list-item>
                <mat-list-item>
                  <p class="primary-color bold">
                    <mat-icon class="fas fa-equals"></mat-icon> <strong>Sub Total</strong>:
                  </p>
                  <span
                    class="primary-color value"><strong>{{quotation['Merchant.ContributionsSubtotal'] | customCurrency}}</strong></span>
                </mat-list-item>
                <mat-list-item>
                  <p><em>Less {{ "KEYWORD.practitioner" | translate | titlecase }} Fees</em></p>
                </mat-list-item>
                <mat-list-item>
                  <p>Recourse Release MSF: </p>
                  <span class="value">{{quotation['Recourse_Release_MSF'] | customCurrency}}</span>
                </mat-list-item>
                <mat-list-item>
                  <p>
                    <mat-icon class="fas fa-plus-circle"></mat-icon> Merchant Service Fee:
                  </p>
                  <span class="value">{{quotation['Merchant_Service_Fee'] | customCurrency}}</span>
                </mat-list-item>
                <mat-list-item>
                  <p class="primary-color bold">
                    <mat-icon class="fas fa-equals"></mat-icon> <strong>Sub Total</strong>:
                  </p>
                  <span class="primary-color value">
                    <strong>{{(toNumber(quotation['Recourse_Release_MSF']) + toNumber(quotation['Merchant_Service_Fee']) )| customCurrency}}</strong>
                  </span>
                </mat-list-item>
                <mat-list-item class="primary-bg total">
                  <p><strong>Net to Merchant</strong>:</p>
                  <span class="value"><strong>{{quotation['NetToMerchant'] | customCurrency}}</strong></span>
                </mat-list-item>
              </mat-list>

            </mat-card>


          </div>

        </div>
      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="fas fa-hand-holding-usd"></mat-icon> Payments
        </ng-template>


        <mat-card class="nudge-btm" *ngIf="paymentsTab==true">
          <div class="colour-stripe "></div>
          <h3 class="sr-title sm-h3 rm-m">Payment details</h3>
          <label>Scroll down for more results</label>
          <hr>


          <div class="static-table">

            <div class="row clearfix flex">
              <h4 class="full-width duePadding">Due</h4>
              <h4 class="full-width">Borrower Repayment</h4>
              <h4 class="full-width" *ngIf="quotationProductSubType=='With Interest'">
                Loan Establishment Fee</h4>
              <h4 class="full-width" *ngIf="quotationProductSubType=='With Interest'">
                Interest on Establishment Fee</h4>
              <h4 class="full-width" *ngIf="quotationProductSubType=='With Interest'">
                Account Keeping Fee</h4>
              <h4 class="full-width" *ngIf="quotation['Deposit']!=0">Deposit</h4>
              <h4 class="full-width">Sub Total</h4>
            </div>
            <div class="scrollPane">

              <div class="row clearfix" *ngFor="let payment of paymentList; let i=index" [class.greyRow]="i%2==0">
                <div class="clearfix flex" *ngIf="payment['offsetHowMany']=='0'">
                  <p class="full-width duePadding">Acceptance Date</p>
                  <p class="full-width">{{payment['repayments'] | customCurrency}}</p>
                  <p class="full-width" *ngIf="quotationProductSubType=='With Interest'">
                    {{payment['fee'] | customCurrency}}</p>
                  <p class="full-width" *ngIf="quotationProductSubType=='With Interest'">
                    {{payment['interest'] | customCurrency}}</p>
                  <p class="full-width" *ngIf="quotationProductSubType=='With Interest'">&nbsp;</p>
                  <p class="full-width" *ngIf="quotation['Deposit']!=0">{{quotation['Deposit'] | customCurrency}}</p>
                  <p class="full-width">
                    <span *ngIf="quotationProductSubType=='With Interest'">
                      {{(toNumber(payment['repayments']) + toNumber(payment['fee']) +
                      toNumber(payment['interest']) + toNumber(quotation['Deposit'])) | customCurrency}}
                    </span>
                    <span *ngIf="quotationProductSubType!='With Interest'">
                      {{(toNumber(payment['repayments']) + toNumber(quotation['Deposit'])) | customCurrency}}
                    </span>
                  </p>
                </div>

                <div class="clearfix flex" *ngIf="payment['offsetHowMany']!='0'">
                  <p class="full-width duePadding">Acceptance Date + {{payment['offsetHowMany']}}
                    <span *ngIf="payment['offsetCode']">{{getOffsetCodeLabel(payment['offsetCode'])}}<span
                        *ngIf="payment['offsetHowMany']!=1">s</span></span>
                    <span *ngIf="!payment['offsetCode']">Month<span *ngIf="payment['offsetHowMany']!=1">s</span></span>
                  </p>
                  <p class="full-width">{{payment['repayments'] | customCurrency}}</p>
                  <p class="full-width" *ngIf="quotationProductSubType=='With Interest'">
                    {{payment['fee'] | customCurrency}}</p>
                  <p class="full-width" *ngIf="quotationProductSubType=='With Interest'">
                    {{payment['interest'] | customCurrency}}</p>
                  <p class="full-width" *ngIf="quotationProductSubType=='With Interest'">
                    {{payment['akf'] | customCurrency}}</p>
                  <p class="full-width" *ngIf="quotation['Deposit']!=0">&nbsp;</p>
                  <p class="full-width"
                    *ngIf="quotation['RepaymentFrequency.Code']!='4' && quotationProductSubType=='With Interest'">
                    <span *ngIf="!payment['akf']">
                      {{(toNumber(payment['repayments']) + toNumber(payment['fee']) +
                    toNumber(payment['interest'])) | customCurrency}}
                    </span>
                    <span *ngIf="payment['akf']">{{payment['akf'] | customCurrency}}</span>
                  </p>
                  <p class="full-width"
                    *ngIf="quotation['RepaymentFrequency.Code']=='4' && quotationProductSubType=='With Interest'">
                    {{(toNumber(payment['repayments']) + toNumber(payment['fee']) +
                  toNumber(payment['interest']) + toNumber(payment['akf'])) | customCurrency}}
                  </p>
                  <p class="full-width"
                    *ngIf="quotation['RepaymentFrequency.Code']!='4' && quotationProductSubType!='With Interest'">
                    {{payment['repayments'] | customCurrency}}
                  </p>
                </div>

              </div>

            </div>

            <div class="row clearfix flex totals-area primary-bg">
              <h4 class="full-width duePadding">Total</h4>
              <h4 class="full-width">{{totalRepayment | customCurrency}}</h4>
              <h4 class="full-width" *ngIf="quotationProductSubType=='With Interest'">
                {{quotation['EstablishmentFee'] | customCurrency}}</h4>
              <h4 class="full-width" *ngIf="quotationProductSubType=='With Interest'">
                {{totalInterest | customCurrency}}</h4>
              <h4 class="full-width" *ngIf="quotationProductSubType=='With Interest'">
                {{quotation['AccountKeepingFees'] | customCurrency}}</h4>
              <h4 class="full-width" *ngIf="quotation['Deposit']!=0">{{quotation['Deposit'] | customCurrency}}</h4>
              <h4 class="full-width">{{quotation['Amount.Original'] | customCurrency}}</h4>
            </div>

          </div>


        </mat-card>


      </mat-tab>

   <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="fas fa-check-circle"></mat-icon> Compliance Tasks
        </ng-template>
        <div class="row clearfix" *ngIf="complianceTasksTab==true">
          <mat-card class="nudge-btm">
            <div class="colour-stripe "></div>
            <app-compliance-task [contractID]="quotation['ID']" [largeHeading]="false"></app-compliance-task>
          </mat-card>
        </div>
      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="fas fa-clipboard-list"></mat-icon> Action Log
        </ng-template>
        <div class="row clearfix">
          <mat-card class="nudge-btm" *ngIf="actionLogTab==true">
            <div class="colour-stripe "></div>

          </mat-card>
        </div>
      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="fas fa-file-alt"></mat-icon>
        </ng-template>
        <div class="row clearfix" *ngIf="documentsTab==true">

        </div>
      </mat-tab>
    </mat-tab-group> -->
  <!-- </div> -->
</div>


