<div class="clearfix" *ngIf="isAdminOrPromoter == true && load==true">
  <div *ngIf="hideExtraComponents == false">
    <app-operator-filter
      class="dash"
      id="part1"
      [displayMerchant]="false"
      [displayDateFrom]="true"
      [displayDateTo]="true"
      [sendNull]="true"
      [selectDateRange]="selectedRange"
      [autoSelectMerchant]="true"
      [displayDentist]="false"
      (getMerchant)="getMerchant($event)"
      (getDateTo)="getDateTo($event)"
      (getDateFrom)="getDateFrom($event)"
      (search)="applySearch($event)"
    ></app-operator-filter>
  </div>

  <div class="row clearfix" *ngIf="displayResult==true">
    <app-contract-list-global
      [pageSize]="pageSize"
      [status]="status"
      [dateFrom]="dateFrom"
      [dateTo]="dateTo"
      (detailsView)="detailsView($event)"
    ></app-contract-list-global>
  </div>
</div>
