<div class="pinned-timer-information">
  <div class="mr ml" style="display: flex; flex-direction: column">
    <div class="text-center" style="margin-bottom: 5px">Duration</div>
    <div [ngClass]="{ blink: isTimerStopped, running: isTimerRunning }" style="font-weight: bold; text-align: center">
      {{ this.duration }}
    </div>
  </div>

  <div class="mr ml" style="display: flex; flex-direction: column">
    <div class="text-center" style="margin-bottom: 5px">Start</div>
    <div style="font-weight: bold; text-align: center">
      {{ this.startTime }}
    </div>
  </div>

  <div class="mr ml" style="display: flex; flex-direction: column">
    <div class="text-center" style="margin-bottom: 5px">End</div>
    <div style="font-weight: bold; text-align: center">
      {{ this.endTime }}
    </div>
  </div>

  <div
    *ngIf="!disabled && showTimerButtons"
    style="display: flex; width: 146px; flex-direction: column; padding-left: 20px"
  >
    <button
      class="note__timer-button"
      *ngIf="isTimerReset"
      (click)="startTimer()"
      mat-raised-button
      style="margin-bottom: 5px"
      color="primary"
    >
      Start
    </button>

    <button
      class="note__timer-button"
      *ngIf="!isTimerReset && !isTimerRunning"
      (click)="resumeTimer()"
      mat-raised-button
      style="margin-bottom: 5px"
      color="primary"
    >
      Start
    </button>
    <button
      class="note__timer-button"
      *ngIf="!isTimerReset && isTimerRunning"
      (click)="stopTimer()"
      mat-raised-button
      style="margin-bottom: 5px"
      color="primary"
    >
      Stop
    </button>
    <button class="note__timer-button" [disabled]="isTimerReset" (click)="resetTimer()" mat-raised-button color="warn">
      Reset
    </button>
  </div>
</div>
