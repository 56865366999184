<div class="container error-page">
  <mat-card class="error text-center">
    <img class="character_error" />
    <div class="circle"></div>

    <h1 class="accent-color">Oops!</h1>
    <h2>Something went wrong.</h2>
    <p>Please try again soon.</p>
    <div class="drop-buttons">
      <button class="" (click)="contactUs()" mat-raised-button color="warn">Contact us</button>
      <button class="btn-return" (click)="goHome()" mat-raised-button color="accent">
        Go Home <span *ngIf="countdown">({{ countdown / 1000 }}) </span>
      </button>
    </div>
  </mat-card>
</div>
