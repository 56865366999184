<div class="row clearfix padded">
  <mat-button-toggle-group
    class="btn-group flex full-width text-center"
    #group="matButtonToggleGroup"
    value="profilePage"
  >
    <mat-button-toggle class="text-center full-width" (click)="page = 'profilePage'" value="profilePage">
      Profile
    </mat-button-toggle>
    <mat-button-toggle class="text-center full-width" (click)="page = 'contactPage'" value="contactPage">
      Contacts
    </mat-button-toggle>
    <mat-button-toggle class="text-center full-width" (click)="page = 'lendingPage'" value="lendingPage">
      Lending
    </mat-button-toggle>
    <mat-button-toggle class="text-center full-width" (click)="page = 'marketingPage'" value="marketingPage">
      Marketing
    </mat-button-toggle>
    <mat-button-toggle class="text-center full-width" (click)="page = 'messagingPage'" value="messagingPage">
      Messaging
    </mat-button-toggle>
    <mat-button-toggle class="text-center full-width" (click)="page = 'actionLogPage'" value="actionLogPage">
      Action Log
    </mat-button-toggle>
    <mat-button-toggle class="text-center full-width" (click)="page = 'verificationPage'" value="verificationPage">
      Verification
    </mat-button-toggle>
    <mat-button-toggle class="text-center full-width" (click)="page = 'documentsPage'" value="documentsPage">
      Documents
    </mat-button-toggle>
    <mat-button-toggle class="text-center full-width" (click)="page = 'logonsPage'" value="logonsPage">
      Logons
    </mat-button-toggle>
  </mat-button-toggle-group>
</div>

<div class="row clearfix">
  <div class="masonry" *ngIf="page == 'profilePage'" @ngIfAnimation>
    <div class="item wide" *ngIf="mainInformation == true" @ngIfAnimation>
      <mat-card class="clearfix">
        <div class="colour-stripe"></div>
        <mat-card-header>
          <mat-card-title>Main information</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="row clearfix">
            <div class="thrd-width">
              <div class="col-left">
                <mat-select class="full-width" name="customerType" placeholder="Type">
                  <mat-option> Consumer - Individual</mat-option>
                </mat-select>
                <mat-select class="full-width" name="customerSalutation" placeholder="Salutation">
                  <mat-option> Dr</mat-option>
                </mat-select>
                <mat-form-field class="full-width" appearance="outline">
                  <input
                    class="mat-block"
                    matInput
                    placeholder="{{ 'PLACEHOLDER.First Name' | translate }}"
                    name="firstname"
                  />
                </mat-form-field>
                <mat-form-field class="full-width" appearance="outline">
                  <input
                    class="mat-block"
                    matInput
                    placeholder="{{ 'PLACEHOLDER.Middle Name' | translate }}"
                    name="middlename"
                  />
                </mat-form-field>
                <mat-form-field class="full-width" appearance="outline">
                  <input
                    class="mat-block"
                    matInput
                    placeholder="{{ 'PLACEHOLDER.Surname' | translate }}"
                    name="surname"
                  />
                </mat-form-field>
                <mat-form-field class="full-width" appearance="outline">
                  <input
                    class="mat-block"
                    matInput
                    placeholder="Maternal name"
                    name="maternalName"
                  />
                </mat-form-field>
                <mat-form-field class="full-width" appearance="outline">
                  <input
                    class="mat-block"
                    matInput
                    placeholder="Preferred name"
                    name="preferName"
                  />
                </mat-form-field>
              </div>
            </div>
            <div class="thrd-width">
              <mat-select class="full-width" name="customerStatus" placeholder="Status">
                <mat-option> Active</mat-option>
              </mat-select>
              <mat-select class="full-width" name="customerType" placeholder="Cons. Type">
                <mat-option> Customer</mat-option>
              </mat-select>
              <mat-select class="full-width" name="accManager" placeholder="Account manager">
                <mat-option> ...</mat-option>
              </mat-select>
              <mat-select class="full-width" name="gender" placeholder="Gender">
                <mat-option> Male</mat-option>
              </mat-select>

              <div class="full-width">
                <app-date-input class="pull-left"></app-date-input>
                <p class="pull-right">00/00/0000</p>
              </div>
              <mat-select class="full-width" name="relationship" placeholder="Relationship">
                <mat-option> Single</mat-option>
              </mat-select>
            </div>
            <div class="thrd-width">
              <div class="col-right">
                <mat-select class="full-width" name="occupation" placeholder="Occupation">
                  <mat-option> PFT</mat-option>
                </mat-select>
                <mat-form-field class="full-width" appearance="outline">
                  <input
                    class="mat-block"
                    matInput
                    placeholder="Drivers License"
                    name="driversLicense"
                  />
                </mat-form-field>

                <mat-form-field class="full-width" appearance="outline">
                  <input class="mat-block" matInput placeholder="Chat address" name="chatAddress"/>
                </mat-form-field>
                <mat-select class="full-width" name="chatType" placeholder="Chat account type">
                  <mat-option> Facebook</mat-option>
                </mat-select>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="item wide" *ngIf="addressDetails == true" @ngIfAnimation>
      <mat-card class="clearfix">
        <div class="colour-stripe"></div>
        <mat-card-header>
          <mat-card-title>Address details</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="row clearfix">
            <div class="two-thrd-width">
              <mat-select class="full-width" name="country" placeholder="Country" disabled>
                <mat-option> Australia</mat-option>
              </mat-select>
              <mat-select class="full-width" name="addressType" placeholder="Address">
                <mat-option> Physical</mat-option>
              </mat-select>
              <app-address-input></app-address-input>
            </div>
            <div class="thrd-width">
              <div class="row clearfix flex">
                <mat-checkbox>Send</mat-checkbox>
                <mat-checkbox>Primary</mat-checkbox>
              </div>
              <p>Calculated Address</p>
              <p>Map coordinates</p>
              <button mat-raised-button>View on map</button>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="item" *ngIf="contactDetails == true" @ngIfAnimation>
      <mat-card class="clearfix">
        <div class="colour-stripe"></div>
        <mat-card-header>
          <mat-card-title>Contact Details</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="row clearfix">
            <div class="row clearfix flex">
              <mat-select class="full-width" name="phoneType" placeholder="Type">
                <mat-option> Home</mat-option>
              </mat-select>
              <mat-form-field class="full-width" appearance="outline">
                <input class="mat-block" matInput placeholder="Phone/Number" name="phoneNumber"/>
              </mat-form-field>
              <mat-checkbox>Send</mat-checkbox>
              <mat-checkbox>Primary</mat-checkbox>
            </div>

            <div class="row clearfix flex">
              <mat-select class="full-width" name="phoneType" placeholder="Type">
                <mat-option> Home</mat-option>
              </mat-select>
              <mat-form-field class="full-width" appearance="outline">
                <input class="mat-block" matInput placeholder="Mobile" name="mobileNumber"/>
              </mat-form-field>
              <mat-checkbox>Send</mat-checkbox>
              <mat-checkbox>Primary</mat-checkbox>
            </div>

            <div class="row clearfix flex">
              <mat-select class="full-width" name="phoneType" placeholder="Type">
                <mat-option> Home</mat-option>
              </mat-select>
              <mat-form-field class="full-width" appearance="outline">
                <input class="mat-block" matInput placeholder="Fax" name="faxNumber"/>
              </mat-form-field>
              <mat-checkbox>Send</mat-checkbox>
              <mat-checkbox>Primary</mat-checkbox>
            </div>

            <div class="row clearfix flex">
              <mat-select class="full-width" name="phoneType" placeholder="Type">
                <mat-option> Home</mat-option>
              </mat-select>
              <mat-form-field class="full-width" appearance="outline">
                <input class="mat-block" matInput placeholder="Email" name="emailAddress"/>
              </mat-form-field>
              <mat-checkbox>Send</mat-checkbox>
              <mat-checkbox>Primary</mat-checkbox>
            </div>
            <mat-form-field class="full-width" appearance="outline">
              <input class="mat-block" matInput placeholder="Website" name="webAddress"/>
            </mat-form-field>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="item" *ngIf="preferencesDetails == true" @ngIfAnimation>
      <mat-card class="clearfix">
        <div class="colour-stripe"></div>
        <mat-card-header>
          <mat-card-title>Preferences</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="row clearfix">
            Contact Method<br/>
            Contact Time<br/>
            Message type<br/>
            Default remittance country<br/>
            Electronic Consent Accepted<br/>
            Marketing Consent
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="item" *ngIf="profileImage == true" @ngIfAnimation>
      <mat-card class="clearfix">
        <div class="colour-stripe"></div>
        <mat-card-header>
          <mat-card-title>Profile image</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="row clearfix">
            <div class="row clearfix image">
              <app-ep-document-view-image
                class="clearfix full-width display-image"
                [defaultPicture]="
                  'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/profile_placeholder.png)'
                "
              ></app-ep-document-view-image>
            </div>
            <div class="row clearfix">
              <h4 class="sr-title sm-h4 rm-mb">Upload Avatar</h4>
              <p class="small rm-m">To upload a new avatar image, please drag your file into the grey area below.</p>
              <app-file-uploader
                class="text-center clearfix full-width"
                [isFixedAspectRatio]="true"
                [isImageCropping]="true"
                [canSkip]="false"
                [limit]="1"
                [uploadDirectly]="true"
                [isDragable]="true"
              >
              </app-file-uploader>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <!--   contract list -->

  <div class="masonry" *ngIf="page == 'contactPage'" @ngIfAnimation>
    <div class="item wide">
      <mat-card class="clearfix">
        <div class="colour-stripe"></div>
        <mat-card-header>
          <mat-card-title>Contact List</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="row clearfix">...</div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <!--  Lending -->

  <div class="masonry" *ngIf="page == 'lendingPage'" @ngIfAnimation>
    <div class="item wide">
      <mat-card class="clearfix">
        <div class="colour-stripe"></div>
        <mat-card-header>
          <mat-card-title>Quotations</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="row clearfix">...</div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="item wide">
      <mat-card class="clearfix">
        <div class="colour-stripe"></div>
        <mat-card-header>
          <mat-card-title>Contracts</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="row clearfix">...</div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="item">
      <mat-card class="clearfix">
        <div class="colour-stripe"></div>
        <mat-card-header>
          <mat-card-title>Payment methods</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="row clearfix">...</div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="item">
      <mat-card class="clearfix">
        <div class="colour-stripe"></div>
        <mat-card-header>
          <mat-card-title>Payment history</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="row clearfix">...</div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <!-- marketing -->

  <div class="masonry" *ngIf="page == 'marketingPage'" @ngIfAnimation>
    <div class="item">
      <mat-card class="clearfix">
        <div class="colour-stripe"></div>
        <mat-card-header>
          <mat-card-title>General</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="row clearfix">
            <div class="half-width">
              Gender<br/>
              Date of birth<br/>
              Industry type<br/>
              {{ "KEYWORD.patient" | translate | titlecase }} group<br/>
              Consent to marketing<br/>
              Referral sourse<br/>
              Specifics
            </div>
            <div class="half-width">
              {{ 'KEYWORD.patient' | translate }}  type<br/>
              Status<br/>
              Account manager
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="item">
      <mat-card class="clearfix">
        <div class="colour-stripe"></div>
        <mat-card-header>
          <mat-card-title>Marketing fields</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="row clearfix">...</div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <!-- messaging -->

  <div class="masonry" *ngIf="page == 'messagingPage'" @ngIfAnimation>
    <div class="item wide">
      <mat-card class="clearfix">
        <div class="colour-stripe"></div>
        <mat-card-header>
          <mat-card-title>Messages</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="row clearfix">...</div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <!-- action log -->

  <div class="masonry" *ngIf="page == 'actionLogPage'" @ngIfAnimation>
    <div class="item wide">
      <mat-card class="clearfix">
        <div class="colour-stripe"></div>
        <mat-card-header>
          <mat-card-title>ACtion Log</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="row clearfix">...</div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <!-- verification -->

  <div class="masonry" *ngIf="page == 'verificationPage'" @ngIfAnimation>
    <div class="item wide">
      <mat-card class="clearfix">
        <div class="colour-stripe"></div>
        <mat-card-header>
          <mat-card-title>Capacity Checks</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="row clearfix">...</div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="item wide">
      <mat-card class="clearfix">
        <div class="colour-stripe"></div>
        <mat-card-header>
          <mat-card-title>Credit Checks</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="row clearfix">...</div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="item wide">
      <mat-card class="clearfix">
        <div class="colour-stripe"></div>
        <mat-card-header>
          <mat-card-title>ID Checks</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="row clearfix">...</div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="item wide">
      <mat-card class="clearfix">
        <div class="colour-stripe"></div>
        <mat-card-header>
          <mat-card-title>IDs</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="row clearfix">...</div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <!-- documents -->

  <div class="masonry" *ngIf="page == 'documentsPage'" @ngIfAnimation>
    <div class="item wide">
      <mat-card class="clearfix">
        <div class="colour-stripe"></div>
        <mat-card-header>
          <mat-card-title>Documents</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="row clearfix">...</div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <!-- logons -->

  <div class="masonry" *ngIf="page == 'logonsPage'" @ngIfAnimation>
    <div class="item wide">
      <mat-card class="clearfix">
        <div class="colour-stripe"></div>
        <mat-card-header>
          <mat-card-title>eConnect</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="row clearfix">
            <div class="two-thrd-width">
              User Name<br/>
              Password<br/>
              Re-enter Password<br/>
              <mat-checkbox>Logon enabled</mat-checkbox>
              <br/>
              Mobile site preference
            </div>
            <div class="thrd-width">
              <button mat-raised-button>Show groups</button>
              <br/>
              <mat-checkbox>Is Administrator</mat-checkbox>
              <br/>
              <mat-checkbox>Is Wakanda Master User</mat-checkbox>
              <br/>
              Role
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="item wide">
      <mat-card class="clearfix">
        <div class="colour-stripe"></div>
        <mat-card-header>
          <mat-card-title>ePremium</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="row clearfix">
            User Name<br/>
            Password<br/>
            Re-enter Password<br/>
            <mat-checkbox>Logon enabled</mat-checkbox>
            <br/>
            <mat-checkbox>Administrator</mat-checkbox>
            <br/>
            Management level<br/>
            <button mat-raised-button>Show groups</button>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
