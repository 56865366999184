import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { ProductViewModalComponent } from '../../product/product-view-modal/product-view-modal.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { EventLogListComponent } from '../../action-log/event-log-list/event-log-list.component';
import { MessageTimelineComponent, MessageType } from '../../message/message-timeline/message-timeline.component';
import { InvitationViewModalComponent } from '../invitation-view-modal/invitation-view-modal.component';
import { WakandaFilesComponent } from '../wakanda-files-view/wakanda-files-view.component';
import { CustomerProspectViewModelComponent } from '../../customer-prospect/customer-prospect-view-model/customer-prospect-view-model.component';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { NotesModal, NotesModalConfig } from '../../notes/modals/notes-modal/notes.modal';
import { MerchantViewComponent } from '../../merchant/merchant-view/merchant-view.component';
import { SmsConversationComponent } from '../../message/sms-conversation/sms-conversation.component';
import { DentistViewComponent } from '../../dentist/dentist-view/dentist-view.component';
import { SmsPromoterComponent } from '../../message/sms-promoter/sms-promoter.component';
import { environment } from 'environments/environment';
import { Settings } from '../../../shared/types/settings';
import { UtilsService } from '../../../shared/services/utils.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ContractViewComponent } from '../../contract/contract-view/contract-view.component';
import { InvitationCloneInfoComponent } from '../invitation-clone-info/invitation-clone-info.component';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { InvitationService } from '../shared/services/invitation.service';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { MerchantAuthCodeModalComponent } from '../../merchant/merchant-auth-code-modal/merchant-auth-code-modal.component';
import { InstantOfferViewComponent } from '../../instant-finance-offer/instant-offer-view/instant-offer-view.component';
import { IframeViewComponent } from '../../../shared/components/iframe-view/iframe-view.component';
import { TreatmentSaveModalComponent } from '../../invitation-template/treatment-save-modal/treatment-save-modal.component';
import { InvitationTemplateMergerComponent } from '../../invitation-template/invitation-template-merger/invitation-template-merger.component';
import { InvitationResendComponent } from '../invitation-resend/invitation-resend.component';
import { PatientService } from '../../patient/shared/patient.service';
import { ConfirmOptionDialog } from '../../../shared/types/confirm-option-dialog';
import { PatientCardDeferModalComponent } from '../../patient/patient-card-defer-modal/patient-card-defer-modal.component';
import { PatientCardChangesModalComponent } from '../../patient/patient-card-changes-modal/patient-card-changes-modal.component';
import { PatientCardStopModalComponent } from '../../patient/patient-card-stop-modal/patient-card-stop-modal.component';
import { SettlementViewComponent } from '../../settlement/settlement-view/settlement-view.component';
import { SettlementRequestComponent } from '../../settlement/settlement-request/settlement-request.component';
import { SettlementApproveViewComponent } from '../../settlement/settlement-approve-view/settlement-approve-view.component';

@Component({
  selector: 'app-invitation-actions',
  templateUrl: './invitation-actions.component.html',
  styleUrls: ['./invitation-actions.component.css'],
})
export class InvitationActionsComponent implements OnInit {
  @Input()
  customerID;
  @Input()
  contractID;
  @Input()
  invitationID;
  @Input()
  merchantID;
  @Input()
  settlement;

  @Input()
  buttonType = 'action';
  @Input()
  buttonLabel = 'Actions';
  @Input()
  landingPageID;

  @Input()
  campaignID;

  @Input()
  invitation;

  @Input()
  voided = false;

  @Input()
  campaignTag;
  @Input()
  instantOfferID;

  @Input()
  contract;

  @Input()
  productID;
  @Input()
  wakandaID;
  @Input()
  dentistID;
  isPromoterOrAdmin = false;
  sessionType;
  @Input()
  displayContractDetails = true;

  @Input()
  displayDeleteInvitation = true;

  @Input()
  displayInvitationDetails = true;

  @Input()
  settlementOnList = true;

  @Input()
  displayNewSettlement = true;

  @Input()
  displaySettlementActions = true;
  @Input()
  items = [
    'contract',
    'invitation',
    'customer',
    'dentist',
    'merchant',
    'instantOffer',
    'appointment',
    'campaign',
    'landingPage',
    'settlement',
    'note',
  ];

  @Output()
  close = new EventEmitter();
  @Output()
  addContractDocument = new EventEmitter();
  @Output()
  getSettlementUpdate = new EventEmitter();

  @Output()
  getContractUpdate = new EventEmitter();
  @Output()
  getInvitationUpdate = new EventEmitter();
  @Output()
  getDeletedInvitationID = new EventEmitter();

  @Input()
  membership = false;

  random = btoa(Math.random().toString()).substring(2, 20);

  context = Settings.global['context'];

  isModulePatientSMSActive = Settings.global['isModulePatientSMSActive'];
  isModuleNoteFeaturesActive = Settings.global['isModuleNoteFeaturesActive'];
  isModuleExperimentalFeaturesActive = Settings.global['isModuleExperimentalFeaturesActive'];
  isModuleTreatmentTemplateActive = Settings.global['isModuleTreatmentTemplateActive'];
  isModuleAppointmentActive = Settings.global['isModuleAppointmentActive'];

  isModulePatientPortalAccessActive = Settings.global['isModulePatientPortalAccessActive'];

  constructor(
    private utilsService: UtilsService,
    private authenticationService: AuthenticationService,

    private patientService: PatientService,
    private invitationService: InvitationService,
    private router: Router,
    private activeRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.setup();

    this.utilsService.getCurrentAccess().subscribe((access) => {
      if (access) {
        this.isModuleNoteFeaturesActive = access['isModuleNoteFeaturesActive'];
        this.isModuleTreatmentTemplateActive = access['isModuleTreatmentTemplateActive'];
        this.isModuleExperimentalFeaturesActive = access['isModuleExperimentalFeaturesActive'];
        this.isModuleAppointmentActive = access['isModuleAppointmentActive'];
        this.isModulePatientPortalAccessActive = access['isModulePatientPortalAccessActive'];
        this.isModulePatientSMSActive = access['isModulePatientSMSActive'];
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.setup();
  }
  setup() {
    if (!this.items || (this.items && this.items.length <= 0)) {
      this.items = [
        'contract',
        'invitation',
        'customer',
        'dentist',
        'merchant',
        'instantOffer',
        'appointment',
        'campaign',
        'landingPage',
        'settlement',
        'note',
      ];
    }
    this.authenticationService.isPromoterOrAdmin().subscribe((d) => {
      this.isPromoterOrAdmin = d;
      this.authenticationService.getSessionType().subscribe((sd) => {
        this.sessionType = sd;
        if (this.isPromoterOrAdmin == false || (this.isPromoterOrAdmin == true && !this.merchantID)) {
          this.authenticationService.getCurrentPractice().subscribe((m) => {
            if (m && m.ID) {
              this.merchantID = m.ID;
            }
          });
        }
      });
    });
  }

  openProductView(id) {
    const ref = RootAppComponent.dialog.open(ProductViewModalComponent, {
      data: id,
      width: '750px',
      panelClass: 'noCard',
    });

    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }
  viewContractLogs(id) {
    const ref = RootAppComponent.dialog.open(EventLogListComponent, {
      data: {
        contractID: id,
        type: 'contract',
        itemID: id,
      },
      width: '900px',
    });
    ref.componentInstance.closeModal.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  viewMessagesContract(id) {
    const ref = RootAppComponent.dialog.open(MessageTimelineComponent, {
      data: {
        contractID: id,
        messageType: MessageType.message,
      },
      width: '900px',
    });
    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  openInvitationView(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(InvitationViewModalComponent, {
        data: {
          invitationID: id,
          membership: false,
        },
        width: '900px',
      });

      ref.componentInstance.close.subscribe((res) => {
        if (res == true) {
          ref.close();
        }
      });
    }
  }

  fullInvitationDetails(invitationID, patientID) {
    if (invitationID && patientID) {
      const confirm = new ConfirmDialog(
        'fas fa-info',

        'You are about to leave this page.',
        'Do you want to continue?',
        'Cancel',
        'Okay'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
        width: '500px',
      });

      ref.componentInstance.onConfirm.subscribe((res) => {
        if (res == true) {
          ref.close();

          this.router.navigate([
            '/merchant',
            {
              outlets: {
                page: ['customer-profile', patientID, 'treatment', invitationID],
              },
            },
          ]);

          this.close.emit(true)
        } else {
          ref.close();
        }
      });
    }
  }

  viewMessagesInvitation(id) {
    const ref = RootAppComponent.dialog.open(MessageTimelineComponent, {
      data: {
        invitationID: id,
        messageType: MessageType.message,
      },
      width: '900px',
    });
    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }
  wakandaFiles(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(WakandaFilesComponent, {
        data: {
          invitationID: id,
        },
        width: '810px',
      });

      ref.componentInstance.closeEvent.subscribe((res) => {
        ref.close();
      });

      ref.componentInstance.getResult.subscribe((res) => {
        if (res && res.ID && res.DocumentType_Code) {
          this.utilsService.getDocumentTypeByCode(res.DocumentType_Code, { fields: 'Label' }).subscribe((r) => {
            if (r && r.Label) {
              res.DocumentType_Label = r.Label;
            }

            this.addContractDocument.emit(res);
            ref.close();
          });
        } else {
          ref.close();
        }
      });
    }
  }
  openDetailsViewEPContract(id) {
    if (id) {
      const confirm = new ConfirmDialog(
        'fas fa-info',

        'You are about to leave this page.',
        'Do you want to continue?',
        'Cancel',
        'Okay'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
        width: '500px',
      });

      ref.componentInstance.onConfirm.subscribe((res) => {
        if (res == true) {
          ref.close();

          this.router.navigate([
            '/merchant',
            {
              outlets: { page: ['contract-overview-details', id] },
              data: { title: 'Referred Application' },
            },
          ]);
          this.close.emit(true)
        } else {
          ref.close();
        }
      });
    }
  }
  quickView(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(CustomerProspectViewModelComponent, {
        data: {
          patientID: id,
          isFullDetails: true,
        },
        width: '700px',
      });
      ref.componentInstance.detailedView.subscribe((res) => {
        ref.close();
        ref.afterClosed().subscribe((r) => {
          this.router.navigate([
            '/merchant',
            {
              outlets: {
                page: ['customer-profile', id],
              },
            },
          ]);
        });
      });

      ref.componentInstance.close.subscribe((res) => {
        if (res == true) {
          ref.close();
        }
      });
    }
  }
  viewMessages(id) {
    const ref = RootAppComponent.dialog.open(MessageTimelineComponent, {
      data: {
        cardID: id,
        messageType: MessageType.message,
      },
      width: '900px',
    });
    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }
  contactDentist(id) {
    if (id) {
      const data = {
        targetType: 'contact',
        targetID: id,
        asGuest: false,
        asProfile: true,
        asPractice: true,
      };
      AuthenticationService.contactInputEvent.emit(data);
    }
  }

  viewNotes(patientID: string) {
    RootAppComponent.dialog.open<NotesModal, NotesModalConfig>(NotesModal, {
      data: {
        parentRoute: 'merchant',
        patientID,
      },
    });
  }

  createNote(patientID: string, contractID: string) {
    RootAppComponent.dialog.open<NotesModal, NotesModalConfig>(NotesModal, {
      data: {
        patientID,
        parentRoute: 'merchant',
        noteListMode: 'create',
        relationshipLink: {
          label: 'contract',
          route: `customer-profile/${patientID}/contract/${contractID}`,
          itemID: contractID,
        },
      },
    });
  }
  toNumber(n) {
    if (n) {
      return Number(n);
    } else {
      return 0;
    }
  }

  requestSettlement(id) {
    const ref = RootAppComponent.dialog.open(SettlementRequestComponent, {
      data: id,
      width: '800px',
      panelClass: 'noCard',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });

    ref.componentInstance.result.subscribe((res) => {
      if (res) {
        this.getSettlementUpdate.emit(res);
        ref.close();
      }
    });

    ref.componentInstance.updateItem.subscribe((res) => {
      if (res) {
        if (res['ConsentedViaPaperForm'] == '1' && !res['SecurityCode_key']) {
          res['Status.Label'] = 'Pending-Authorised by Form';
        } else if (res['ConsentedViaPaperForm'] == '0' && res['SecurityCode_key']) {
          res['Status.Label'] = 'Pending-Authorised by Security Code';
        }
        this.getSettlementUpdate.emit(res);

        ref.close();
      }
    });
  }

  withdrawSettlement(id, groupID) {
    if (id && groupID == '') {
      const confirm = new ConfirmDialog('block', '', 'Are you sure you want to withdraw this settlement?', 'No', 'Yes');

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation == true) {
          this.invitationService.cancelSettlement(id, this.isPromoterOrAdmin).subscribe((res) => {
            if (res) {
              this.getSettlementUpdate.emit(res);
              NotifyAppComponent.displayToast('success', 'Success!', 'Your Settlement has been withdrawn');

              ref.close();
            }
          });
        } else {
          ref.close();
        }
      });
    } else if (id && groupID != '') {
      const confirm = new ConfirmDialog('block', '', 'Are you sure you want to withdraw this settlement?', 'No', 'Yes');

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation == true) {
          this.invitationService.cancelSettlement(id, this.isPromoterOrAdmin, true).subscribe((res) => {
            if (res) {
              if (res.groupArray && res.groupArray.length > 0) {
                for (let i = 0; i < res.groupArray.length; i++) {
                  this.getSettlementUpdate.emit(res.groupArray[i]);
                }
              }
              NotifyAppComponent.displayToast('success', 'Success!', 'Your Settlement has been withdrawn');
              ref.close();
            }
          });
        } else {
          ref.close();
        }
      });
    }
  }
  viewSettlementLogs(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(EventLogListComponent, {
        data: {
          itemID: id,
        },
        width: '900px',
      });

      ref.componentInstance.closeModal.subscribe((res) => {
        if (res == true) {
          ref.close();
        }
      });
    }
  }
  decideSettlment(item) {
    if (item && item.ID && item['Status.Code'] == 'REQU') {
      const ref = RootAppComponent.dialog.open(SettlementApproveViewComponent, {
        data: {
          settlementID: item['ID'],
        },
        // width: '90vw',
        // height:'80vh',
        panelClass: ['max-width-panel-90vw','noCard'],
      });
      ref.componentInstance.close.subscribe((res) => {
        if (res == true) {
          ref.close();
        }
      });

      ref.componentInstance.getResult.subscribe((res) => {
        if (res && res.ID) {
          this.getSettlementUpdate.emit(res);
          ref.close();
        }
      });
    }
  }
  openSettlementView(ID) {
    const ref = RootAppComponent.dialog.open(SettlementViewComponent, {
      data: ID,
      width: '900px',
      panelClass: 'noCard',
    });
    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  viewMerchant(id) {
    const ref = RootAppComponent.dialog.open(MerchantViewComponent, {
      data: id,
      width: '600px',
    });
    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }
  sendEmail(id) {
    if (id) {
      const data = {
        targetType: 'customerProspect',
        targetID: id,
        asGuest: false,
        asProfile: true,
        asPractice: true,
      };
      AuthenticationService.contactInputEvent.emit(data);
    }
  }
  contactSMSDentist(id) {
    if (id) {
      const ref2 = RootAppComponent.dialog.open(SmsConversationComponent, {
        data: {
          cardID: id,
          isContactStaff: true,
          singleChat: true,
        },
        panelClass: 'bigger-screen',
        width: '70%',
        height: '95vh',
      });
      const sub = ref2.componentInstance.close.subscribe((data) => {
        ref2.close();
      });
    }
  }

  viewDentist(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(DentistViewComponent, {
        data: id,
        width: '550px',
      });
      ref.componentInstance.close.subscribe((res) => {
        if (res == true) {
          ref.close();
        }
      });
    }
  }
  sendSMS(id, merchantID) {
    if (id && merchantID) {
      const ref2 = RootAppComponent.dialog.open(SmsPromoterComponent, {
        data: {
          cardID: id,
          merchantID: merchantID,
          singleChat: true,
          contractID: id,
        },
        panelClass: 'noCard',
        width: '500px',
      });
      ref2.componentInstance.close.subscribe((data) => {
        ref2.close();
      });

      ref2.componentInstance.getResult.subscribe((data) => {
        if (data) {
          const ref = RootAppComponent.dialog.open(SmsConversationComponent, {
            data,
            panelClass: 'bigger-screen',
            width: '70%',
            height: '95vh',
          });
          const sub = ref.componentInstance.close.subscribe((data) => {
            ref.close();
          });
        }

        ref2.close();
      });
    }
  }

  sendSMSMerchant(id) {
    if (id) {
      const ref2 = RootAppComponent.dialog.open(SmsConversationComponent, {
        data: {
          cardID: id,
          singleChat: true,
        },
        panelClass: 'bigger-screen',
        width: '70%',
        height: '95vh',
      });
      const sub = ref2.componentInstance.close.subscribe((data) => {
        ref2.close();
      });
    }
  }

  openCustomerView(id) {
    const confirm = new ConfirmDialog(
      'fas fa-info',

      'You are about to leave this page.',
      'Do you want to continue?',
      'Cancel',
      'Okay'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirm,
      width: '500px',
    });

    ref.componentInstance.onConfirm.subscribe((res) => {
      if (res == true) {
        ref.close();

        this.router.navigate([
          '/merchant',
          {
            outlets: {
              page: ['customer-profile', id, 'patient'],
            },
          },
        ]);
        this.close.emit(true)
      } else {
        ref.close();
      }
    });
  }

  createSettlement(id) {
    debugger
    if (id) {

      AuthenticationService.newSettlement.emit({ contractID: id });
    }
  }

  settlementDetails(id) {
    if (id) {
      const confirm = new ConfirmDialog(
        'fas fa-info',

        'You are about to leave this page.',
        'Do you want to continue?',
        'Cancel',
        'Okay'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
        width: '500px',
      });

      ref.componentInstance.onConfirm.subscribe((res) => {
        if (res == true) {
          ref.close();

          this.router.navigate(['/merchant', { outlets: { page: ['contract-view', id] } }]);
          this.close.emit(true)
        } else {
          ref.close();
        }
      });
    }
  }

  settlementListOverview(contractID) {
    if (contractID) {
      AuthenticationService.settlementList.emit({ contractID: contractID,pageSize:100 });
    }
  }

  quickViewContract(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(ContractViewComponent, {
        data: {
          contractID: id,
          isSimple: false,
        },
        width: '1200px',
        panelClass: 'noCard',
      });

      ref.componentInstance.closeModal.subscribe((res) => {
        if (res == true) {
          ref.close();
        }
      });
    }
  }

  viewSettlement(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(ContractViewComponent, {
        data: {
          contractID: id,
          isSimple: true,
        },
        width: '900px',
        panelClass: 'noCard',
      });

      ref.componentInstance.closeModal.subscribe((res) => {
        if (res == true) {
          ref.close();
        }
      });
    }
  }

  openCloneInvitationModal(id, patientID) {
    const ref = RootAppComponent.dialog.open(InvitationCloneInfoComponent, {
      data: {},
      width: '650px',
    });

    if (ref.componentInstance.InvitationCloneInfo == true) {
      ref.close();
      ref.afterClosed().subscribe(() => {
        this.cloneInvitation(id, patientID);
      });
    } else {
      ref.componentInstance.closeModal.subscribe((res) => {
        if (res == true) {
          ref.close();
          ref.afterClosed().subscribe(() => {
            this.cloneInvitation(id, patientID);
          });
        }
      });
    }

    ref.backdropClick().subscribe(() => {
      ref.close();
      ref.afterClosed().subscribe(() => {
        this.cloneInvitation(id, patientID);
      });
    });
  }

  cloneInvitation(id, patientID) {
    const confirm = new ConfirmDialog(
      'fas fa-info',
      'Clone Invitation',
      'Are you sure you want to clone this invitation ?',
      'No',
      'Yes'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirm,
    });
    ref.componentInstance.onConfirm.subscribe((confirmation) => {
      if (confirmation === false) {
        ref.close();
      } else {
        ref.close();

        const confirmArchive = new ConfirmDialog(
          'fas fa-info',
          'Archive old invitation',
          'Are you sure you want to archive old invitation ?',
          'No',
          'Yes'
        );

        const refArchive = RootAppComponent.dialog.open(ConfirmDialogComponent, {
          data: confirmArchive,
        });

        refArchive.componentInstance.onConfirm.subscribe((confirmArchive) => {
          if (confirmArchive == false) {
            this.invitationService.cloneInvitation(id, this.isPromoterOrAdmin, false).subscribe((res) => {
              if (res) {
                refArchive.close();

                if (this.context == 'retail') {
                  this.router.navigate(['/merchant', { outlets: { page: ['lending-invitation-create', res] } }]);
                } else {
                  this.router.navigate(['/merchant', { outlets: { page: ['invitation-create', res] } }]);
                }
              } else {
                refArchive.close();
              }
            });
          } else {
            this.invitationService.deleteInvitation(id, this.isPromoterOrAdmin, this.membership).subscribe((res) => {
              if (res == true) {
                this.getDeletedInvitationID.emit(id);

                NotifyAppComponent.displayToast(
                  'success',
                  'Successful operation',
                  'The invitation has been successfully archived'
                );

                this.invitationService.cloneInvitation(id, this.isPromoterOrAdmin, false).subscribe((res) => {
                  if (res) {
                    refArchive.close();

                    if (this.context == 'retail') {
                      this.router.navigate(['/merchant', { outlets: { page: ['lending-invitation-create', res] } }]);
                    } else {
                      this.router.navigate(['/merchant', { outlets: { page: ['invitation-create', res] } }]);
                    }
                  } else {
                    refArchive.close();
                  }
                });
              }
            });
          }
        });
      }
    });
  }
  openCampaign(id) {
    const confirm = new ConfirmDialog(
      'fas fa-info',

      'You are about to leave this page.',
      'Do you want to continue?',
      'Cancel',
      'Okay'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirm,
      width: '500px',
    });

    ref.componentInstance.onConfirm.subscribe((res) => {
      if (res == true) {
        ref.close();

        this.router.navigate(['/merchant', { outlets: { page: ['marketing-campaign-view', id] } }]);
        this.close.emit(true)
      } else {
        ref.close();
      }
    });
  }

  openDocumentLandingPage(id) {
    if (id) {
      this.authenticationService.getCurrentPractice().subscribe((r) => {
        if (r && r.ID) {
          const link = `${Settings.global['publicSiteFrontendLink']}/doc/${id}?m=${r.ID}`;

          const ref2 = RootAppComponent.dialog.open(IframeViewComponent, {
            data: {
              link,
            },
            width: '80%',
            height: '92vh',
            panelClass: 'noCard',
          });

          ref2.componentInstance.close.subscribe((res) => {
            ref2.close();
          });
        }
      });
    }
  }
  requestAppointment(ID) {
    const confirm = new ConfirmDialog(
      'fas fa-info',

      'You are about to leave this page.',
      'Do you want to continue?',
      'Cancel',
      'Okay'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirm,
      width: '500px',
    });

    ref.componentInstance.onConfirm.subscribe((res) => {
      if (res == true) {
        ref.close();

        this.router.navigate([
          '/merchant',
          {
            outlets: {
              page: ['appointment', 'appointment-create-merchant', 'invitation', ID],
            },
          },
        ]);
        this.close.emit(true)
      } else {
        ref.close();
      }
    });
  }

  viewInstantOffer(id) {
    if (id) {
      const ref2 = RootAppComponent.dialog.open(InstantOfferViewComponent, {
        data: {
          instantOfferID: id,
        },
        width: '950px',
      });

      ref2.componentInstance.close.subscribe((res) => {
        ref2.close();
      });
    }
  }
  openAuthCodeDialog(id, patientID, merchantID) {
    const ref = RootAppComponent.dialog.open(MerchantAuthCodeModalComponent, {
      data: {
        merchantID,
        type: 'invitation',
        invitationID: id,
        patientID,
      },
      width: '850px',
    });
    const sub = ref.componentInstance.close.subscribe((data) => {
      ref.close();
    });
  }

  redirect() {
    if (this.invitation && this.invitation.ID) {
      if (this.invitation && this.invitation.Instant_Finance_Offer_Key) {
        const url = `${environment.nodeUrl}/d/` + this.invitation.Instant_Finance_Offer_Key;
        window.open(url, '_blank', 'noopener');
      } else if (this.invitation && this.invitation.WakandaID) {
        const url =
          Settings.global['consumerFrontendLink'] +
          '/consumer/(page:invitation-card/' +
          this.invitation.WakandaID +
          ')';
        window.open(url, '_blank', 'noopener');
      }
    } else if (this.invitationID) {
      this.invitationService.getInvitationDetails(this.invitationID, {}, this.sessionType).subscribe((int) => {
        if (int && int.ID) {
          this.invitation = int;

          if (this.invitation && this.invitation.ID) {
            if (this.invitation && this.invitation.Instant_Finance_Offer_Key) {
              const url = `${environment.nodeUrl}/d/` + this.invitation.Instant_Finance_Offer_Key;
              window.open(url, '_blank', 'noopener');
            } else if (this.invitation && this.invitation.WakandaID) {
              const url =
                Settings.global['consumerFrontendLink'] +
                '/consumer/(page:invitation-card/' +
                this.invitation.WakandaID +
                ')';
              window.open(url, '_blank', 'noopener');
            }
          }
        }
      });
    }
  }

  createTemplateEvent(wakandaID) {
    if (wakandaID) {
      const _ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: {
          confirmMessage: '<p>Are you sure  You want to create a Treatment Template based on this invitation ? .</p>',
          noMessage: 'Cancel',
          yesMessage: 'Yes',
          title: 'Apply Treatment  Template',
          icon: 'check_circle',
          isInformation: false,
        },
        // SET TO PAYING CASH CARD
      });

      _ref.componentInstance.onConfirm.subscribe((val) => {
        if (val == true) {
          _ref.close();
          _ref.afterClosed().subscribe((res) => {
            const ref = RootAppComponent.dialog.open(TreatmentSaveModalComponent, {
              data: {},

              width: '450px',
            });

            ref.componentInstance.result.subscribe((payload) => {
              if (payload) {
                const _payload = payload;
                _payload.invitationID = wakandaID;

                this.invitationService.createInvitationTreatmentTemplate(wakandaID, _payload).subscribe((res) => {
                  if (res) {
                    NotifyAppComponent.displayToast('success', 'Successful operation', 'Treatment Template is Saved');

                    if (res._id) {
                      ref.close();
                      ref.afterClosed().subscribe((c) => {
                        const ref2 = RootAppComponent.dialog.open(InvitationTemplateMergerComponent, {
                          data: {
                            templateID: res._id,
                            viewOnly: true,
                          },

                          width: '1200px',
                        });

                        ref2.componentInstance.delete.subscribe((res) => {
                          ref2.close();
                        });

                        ref2.componentInstance.close.subscribe((res) => {
                          ref2.close();
                        });
                      });
                    } else {
                      NotifyAppComponent.displayToast(
                        'Error',
                        'Error',
                        'We could not create a treatment template based on this invitation'
                      );
                    }
                  }
                });
              }
            });

            ref.componentInstance.closeModal.subscribe((res) => {
              if (res == true) {
                ref.close();
              }
            });
          });
        } else {
          _ref.close();
        }
      });
    }
  }

  createTemplate(ID) {
    if (ID && !this.wakandaID) {
      this.invitationService.getInvitationDetails(ID, { fields: 'WakandaID' }, this.sessionType).subscribe((int) => {
        if (int && int.WakandaID) {
          this.wakandaID = int.WakandaID;
          this.createTemplateEvent(this.wakandaID);
        }
      });
    } else if (ID && this.wakandaID) {
      this.createTemplateEvent(this.wakandaID);
    }
  }

  resendInvitation(ID) {
    if (ID) {
      this.invitationService
        .getInvitationDetails(ID, { fields: 'ID,Status.Invitation.Short_NE' }, this.sessionType)
        .subscribe((item) => {
          if (item && item['Status.Invitation.Short_NE'] != 'Expired') {
            const ref = RootAppComponent.dialog.open(InvitationResendComponent, {
              data: {
                invitationID: item['ID'],
                membership: this.membership,
              },
              width: '600px',
              panelClass: 'noCard',
            });

            ref.componentInstance.close.subscribe((res) => {
              if (res == true) {
                ref.close();
              }
            });

            ref.componentInstance.getInvitation.subscribe((res) => {
              if (res) {
                if (this.voided == true) {
                  this.getDeletedInvitationID.emit(item.ID);
                } else {
                  this.getInvitationUpdate.emit(res);
                }

                ref.close();
              }
            });
          } else if (item && item['Status.Invitation.Short_NE'] == 'Expired') {
            const confirm = new ConfirmDialog(
              'fas fa-info',
              'Invitation has expired',
              'The invitation you are trying to resend has expired and cannot be resent. <br>You can however clone this invitation and send the new link to the patient. <br><br> Would you like to clone this invitation?',
              'Cancel',
              'Proceed'
            );

            const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
              data: confirm,
              width: '650px',
            });

            ref.componentInstance.onConfirm.subscribe((res) => {
              if (res == true) {
                ref.close();
                this.openCloneInvitationModal(item['ID'], item['Prospect_key']);
              } else {
                ref.close();
              }
            });
          }
        });
    }
  }

  payCashInvitation(id) {
    const confirmDialog = new ConfirmDialog(
      'fas fa-info',
      'Paying with Cash/Card',
      '<p>Your patient has agreed on their treatment and plans to settle their account by paying with cash or card at the time of their appointment.</p> <p class="noFurther">They will not recieve further notifications from the Smile Right system.</p>',
      'Cancel',
      'Okay'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
      // SET TO PAYING CASH CARD
    });
    ref.componentInstance.onConfirm.subscribe((val) => {
      if (val == true) {
        ref.close();
        ref.afterClosed().subscribe((res) => {
          this.patientService.notFinanceInivation(id, true).subscribe((res) => {
            if (res) {
              this.getInvitationUpdate.emit(res);

              NotifyAppComponent.displayToast(
                'success',
                'Status Updated',
                'Patient will pay cash or card at the time of their appointment.'
              );
            }
          });
        });
      } else {
        ref.close();
      }
    });
  }

  payCashInvitationWithDiscount(id) {
    const confirmDialog = new ConfirmDialog(
      'fas fa-info',
      'Paying with Cash/Card',
      '<p>Your patient has agreed on their treatment and plans to settle their account by paying with cash or card at the time of their appointment.</p> <p class="noFurther">They will not recieve further notifications from the Smile Right system.</p>',
      'Cancel',
      'Okay'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
      // SET TO PAYING CASH CARD
    });
    ref.componentInstance.onConfirm.subscribe((val) => {
      if (val == true) {
        ref.close();
        ref.afterClosed().subscribe((res) => {
          this.patientService.notFinanceInivationWithDiscount(id, true).subscribe((res) => {
            if (res) {
              this.getInvitationUpdate.emit(res);

              NotifyAppComponent.displayToast(
                'success',
                'Status Updated',
                'Patient will pay cash or card at the time of their appointment.'
              );
            }
          });
        });
      } else {
        ref.close();
      }
    });
  }

  deferInvitation(id) {
    const confirmOptionDialog = new ConfirmOptionDialog(
      'access_alarms',
      'Defer Treatment',
      '<p>Your patient has requested to hold off further notifications about their treatment for a period of time.</p>',
      'Please select time to defer',
      'Cancel',
      'Okay'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmOptionDialog,
    });

    ref.componentInstance.onConfirm.subscribe((res) => {
      if (res == true) {
        ref.close();
        ref.afterClosed().subscribe((res) => {
          const ref1 = RootAppComponent.dialog.open(PatientCardDeferModalComponent, {
            data: {
              invitationID: id,
              force: true,
            },
            width: '500px',
          });
          let deferWeeks;
          ref1.componentInstance.done.subscribe((res3) => {
            deferWeeks = res3;
          });
          ref1.componentInstance.invitation.subscribe((res2) => {
            this.getInvitationUpdate.emit(res2);
            ref1.close();
          });
        });
      }
    });

    // LAUNCH DEFER TREATMENT MODAL ON 'OK' CLICK
  }

  changesToInvitation(id) {
    const confirmDialog = new ConfirmDialog(
      'fas fa-info',
      'Changes requested',
      '<p>Your patient requests to modify their treatment plan before proceeding.</p>',
      'Cancel',
      'Okay'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,

      // LAUNCH CHANGES TO INVITATION MODAL ON 'OK' CLICK
    });
    ref.componentInstance.onConfirm.subscribe((res) => {
      if (res == true) {
        ref.close();
        ref.afterClosed().subscribe((res) => {
          const ref1 = RootAppComponent.dialog.open(PatientCardChangesModalComponent, {
            data: {
              invitationID: id,
              force: true,
            },
            width: '500px',
          });

          ref1.componentInstance.invitation.subscribe((res2) => {
            this.getInvitationUpdate.emit(res2);
            ref1.close();
            ref1.afterClosed().subscribe((res) => {
              if (res2 && res2.newID) {
                const ref2 = RootAppComponent.dialog.open(ConfirmDialogComponent, {
                  data: new ConfirmDialog(
                    'fas fa-info',
                    'Create new invitation?',
                    "<p>By pressing 'Okay' you will start creating a new invitation.</p>",
                    'Cancel',
                    'Okay'
                  ),
                });
                ref2.componentInstance.onConfirm.subscribe((res) => {
                  if (res == true) {
                    ref2.close();
                    ref2.afterClosed().subscribe((res) => {
                      if (this.context == 'retail') {
                        this.router.navigate([
                          '/merchant',
                          { outlets: { page: ['lending-invitation-create', res2.newID] } },
                        ]);
                      } else {
                        this.router.navigate(['/merchant', { outlets: { page: ['invitation-create', res2.newID] } }]);
                      }
                    });
                  } else {
                    ref2.close();
                  }
                });
              }
            });
          });
        });
      }
    });
  }

  notProceedingInvitation(id) {
    const confirmDialog = new ConfirmDialog(
      'remove_circle',
      'Does not wish to proceed',
      '<p>Your patient has decided not to proceed with their treatment.</p><p class="noFurther">They will not recieve further notifications from the Smile Right system.</p>',
      'Cancel',
      'Okay'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,

      // LAUNCH NOT PROCEEDING REASONS / QUESTIONS MODAL ON 'OKAY' CLICK
    });

    ref.componentInstance.onConfirm.subscribe((res) => {
      if (res == true) {
        ref.close();
        ref.afterClosed().subscribe((res) => {
          const ref1 = RootAppComponent.dialog.open(PatientCardStopModalComponent, {
            data: {
              invitationID: id,
              force: true,
            },
            width: '500px',
            panelClass: 'flex',
            disableClose: true,
          });

          ref1.componentInstance.invitation.subscribe((data) => {
            this.getInvitationUpdate.emit(data);
            ref1.close();
          });
        });
      }
    });
  }

  resetPatientChoice(id, _void, patientID) {
    const confirm = new ConfirmDialog(
      'fas fa-info',
      'Reset Patient Decision',
      'By doing this will reset the selection previously made by patient and allow the patient to make a new selection.',
      'No',
      'Yes'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirm,
      width: '650px',
    });

    ref.componentInstance.onConfirm.subscribe((res) => {
      if (res == true) {
        ref.close();

        if (_void == '1') {
          // inv expired, asking to clone
          const confirmExpired = new ConfirmDialog(
            'warning',
            'Invitation Expired',
            'Would you like to clone to create a new invitation from the old invitation',
            'No',
            'Yes'
          );

          const refExpired = RootAppComponent.dialog.open(ConfirmDialogComponent, {
            data: confirmExpired,
            width: '650px',
          });

          refExpired.componentInstance.onConfirm.subscribe((resExpired) => {
            if (resExpired == true) {
              refExpired.close();
              this.openCloneInvitationModal(id, patientID);
            } else {
              refExpired.close();
            }
          });
        } else {
          // reset selection
          this.patientService.resetChoiceInivation(id).subscribe((res) => {
            if (res) {
              NotifyAppComponent.displayToast(
                'success',
                'Reset Patient Decision',
                'This inivitaiton is now ready for the patient to make new decision.'
              );
              this.getInvitationUpdate.emit(res);

              ref.close();
            }
          });
        }
      } else {
        ref.close();
      }
    });
  }

  deleteInvitation(id) {
    const confirmDialog = new ConfirmDialog(
      'delete',
      'Archive Invitation?',
      '<p><strong>Are you sure?</strong></p><p class="noFurther">They will not recieve further notifications from the Smile Right system.</p>',
      'No',
      'Yes, archive'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
    });
    ref.componentInstance.onConfirm.subscribe((val) => {
      if (val == true) {
        this.invitationService.deleteInvitation(id, this.isPromoterOrAdmin, this.membership).subscribe((res) => {
          if (res == true) {
            this.getDeletedInvitationID.emit(id);

            NotifyAppComponent.displayToast(
              'success',
              'Successful operation',
              'The invitation has been successfully archived'
            );
            ref.close();
          }
        });
      } else {
        ref.close();
      }
    });
  }

  payCashInvitationMerchant(id) {
    const confirmDialog = new ConfirmDialog(
      'fas fa-info',
      'Paying with Cash/Card',
      '<p>Your patient has agreed on their treatment and plans to settle their account by paying with cash or card at the time of their appointment.</p> <p class="noFurther">They will not recieve further notifications from the Smile Right system.</p>',
      'Cancel',
      'Okay'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
      // SET TO PAYING CASH CARD
    });
    ref.componentInstance.onConfirm.subscribe((val) => {
      if (val == true) {
        ref.close();
        ref.afterClosed().subscribe((res) => {
          this.patientService.notFinanceInivation(id, true).subscribe((res) => {
            if (res) {
              this.getInvitationUpdate.emit(res);

              NotifyAppComponent.displayToast(
                'success',
                'Status Updated',
                'Patient will pay cash or card at the time of their appointment.'
              );
            }
          });
        });
      } else {
        ref.close();
      }
    });
  }

  payCashInvitationWithDiscountMerchant(id) {
    const confirmDialog = new ConfirmDialog(
      'fas fa-info',
      'Paying with Cash/Card',
      '<p>Your patient has agreed on their treatment and plans to settle their account by paying with cash or card at the time of their appointment.</p> <p class="noFurther">They will not recieve further notifications from the Smile Right system.</p>',
      'Cancel',
      'Okay'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
      // SET TO PAYING CASH CARD
    });
    ref.componentInstance.onConfirm.subscribe((val) => {
      if (val == true) {
        ref.close();
        ref.afterClosed().subscribe((res) => {
          this.patientService.notFinanceInivationWithDiscount(id, true).subscribe((res) => {
            if (res) {
              this.getInvitationUpdate.emit(res);

              NotifyAppComponent.displayToast(
                'success',
                'Status Updated',
                'Patient will pay cash or card at the time of their appointment.'
              );
            }
          });
        });
      } else {
        ref.close();
      }
    });
  }

  deferInvitationMerchant(id) {
    const confirmOptionDialog = new ConfirmOptionDialog(
      'access_alarms',
      'Defer Treatment',
      '<p>Your patient has requested to hold off further notifications about their treatment for a period of time.</p>',
      'Please select time to defer',
      'Cancel',
      'Okay'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmOptionDialog,
    });

    ref.componentInstance.onConfirm.subscribe((res) => {
      if (res == true) {
        ref.close();
        ref.afterClosed().subscribe((res) => {
          const ref1 = RootAppComponent.dialog.open(PatientCardDeferModalComponent, {
            data: {
              invitationID: id,
              force: true,
            },
            width: '500px',
          });
          let deferWeeks;
          ref1.componentInstance.done.subscribe((res3) => {
            deferWeeks = res3;
          });
          ref1.componentInstance.invitation.subscribe((res2) => {
            this.getInvitationUpdate.emit(res2);
            ref1.close();
          });
        });
      }
    });

    // LAUNCH DEFER TREATMENT MODAL ON 'OK' CLICK
  }

  changesToInvitationMerchant(id) {
    const confirmDialog = new ConfirmDialog(
      'fas fa-info',
      'Changes requested',
      '<p>Your patient requests to modify their treatment plan before proceeding.</p>',
      'Cancel',
      'Okay'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,

      // LAUNCH CHANGES TO INVITATION MODAL ON 'OK' CLICK
    });
    ref.componentInstance.onConfirm.subscribe((res) => {
      if (res == true) {
        ref.close();
        ref.afterClosed().subscribe((res) => {
          const ref1 = RootAppComponent.dialog.open(PatientCardChangesModalComponent, {
            data: {
              invitationID: id,
              force: true,
            },
            width: '500px',
          });

          ref1.componentInstance.invitation.subscribe((res2) => {
            this.getInvitationUpdate.emit(res2);
            ref1.close();
            ref1.afterClosed().subscribe((res) => {
              if (res2 && res2.newID) {
                const ref2 = RootAppComponent.dialog.open(ConfirmDialogComponent, {
                  data: new ConfirmDialog(
                    'fas fa-info',
                    'Create new invitation?',
                    "<p>By pressing 'Okay' you will start creating a new invitation.</p>",
                    'Cancel',
                    'Okay'
                  ),
                });
                ref2.componentInstance.onConfirm.subscribe((res) => {
                  if (res == true) {
                    ref2.close();
                    ref2.afterClosed().subscribe((res) => {
                      if (this.context == 'retail') {
                        this.router.navigate([
                          '/merchant',
                          { outlets: { page: ['lending-invitation-create', res2.newID] } },
                        ]);
                      } else {
                        this.router.navigate(['/merchant', { outlets: { page: ['invitation-create', res2.newID] } }]);
                      }
                    });
                  } else {
                    ref2.close();
                  }
                });
              }
            });
          });
        });
      }
    });
  }

  notProceedingInvitationMerchant(id) {
    const confirmDialog = new ConfirmDialog(
      'remove_circle',
      'Does not wish to proceed',
      '<p>Your patient has decided not to proceed with their treatment.</p><p class="noFurther">They will not recieve further notifications from the Smile Right system.</p>',
      'Cancel',
      'Okay'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,

      // LAUNCH NOT PROCEEDING REASONS / QUESTIONS MODAL ON 'OKAY' CLICK
    });

    ref.componentInstance.onConfirm.subscribe((res) => {
      if (res == true) {
        ref.close();
        ref.afterClosed().subscribe((res) => {
          const ref1 = RootAppComponent.dialog.open(PatientCardStopModalComponent, {
            data: {
              invitationID: id,
              force: true,
            },
            width: '500px',
            panelClass: 'flex',
            disableClose: true,
          });

          ref1.componentInstance.invitation.subscribe((data) => {
            this.getInvitationUpdate.emit(data);
            ref1.close();
          });
        });
      }
    });
  }

  resetPatientChoiceMerchant(id, _void, patientID) {
    const confirm = new ConfirmDialog(
      'fas fa-info',
      'Reset Patient Decision',
      'By doing this will reset the selection previously made by patient and allow the patient to make a new selection.',
      'No',
      'Yes'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirm,
      width: '650px',
    });

    ref.componentInstance.onConfirm.subscribe((res) => {
      if (res == true) {
        ref.close();

        if (_void == '1') {
          // inv expired, asking to clone
          const confirmExpired = new ConfirmDialog(
            'warning',
            'Invitation Expired',
            'Would you like to clone to create a new invitation from the old invitation',
            'No',
            'Yes'
          );

          const refExpired = RootAppComponent.dialog.open(ConfirmDialogComponent, {
            data: confirmExpired,
            width: '650px',
          });

          refExpired.componentInstance.onConfirm.subscribe((resExpired) => {
            if (resExpired == true) {
              refExpired.close();
              this.openCloneInvitationModal(id, patientID);
            } else {
              refExpired.close();
            }
          });
        } else {
          // reset selection
          this.patientService.resetChoiceInivation(id).subscribe((res) => {
            if (res) {
              NotifyAppComponent.displayToast(
                'success',
                'Reset Patient Decision',
                'This inivitaiton is now ready for the patient to make new decision.'
              );
              this.getInvitationUpdate.emit(res);

              ref.close();
            }
          });
        }
      } else {
        ref.close();
      }
    });
  }

  deleteInvitationMerchant(id) {
    const confirmDialog = new ConfirmDialog(
      'delete',
      'Archive Invitation?',
      '<p><strong>Are you sure?</strong></p><p class="noFurther">They will not recieve further notifications from the Smile Right system.</p>',
      'No',
      'Yes, archive'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
    });
    ref.componentInstance.onConfirm.subscribe((val) => {
      if (val == true) {
        this.invitationService.deleteInvitation(id, this.isPromoterOrAdmin, this.membership).subscribe((res) => {
          if (res == true) {
            this.getDeletedInvitationID.emit(id);

            NotifyAppComponent.displayToast(
              'success',
              'Successful operation',
              'The invitation has been successfully archived'
            );
            ref.close();
          }
        });
      } else {
        ref.close();
      }
    });
  }
}
