<mat-card class="rel supplier-card brandView clearfix mb" *ngIf="treatment" @ngIfAnimation>
  <div class="card-header primary-gradient-img clearfix inModal rel">
    <div class="row clearfix content">
      <button class="btn-close btn-clear mat-button white" *ngIf="isModal == true" (click)="closeModal()">
        <mat-icon>clear</mat-icon>
      </button>
      <div class="row titleArea clearfix">
        <app-type-view-logo
          class="supplierIcon pull-left"
          [typeID]="treatment['ID']"
          [LastModified]="treatment['LastModified']"
        >
        </app-type-view-logo>

        <h2 class="summary-header rm-m" *ngIf="treatment['Label'] != 'N/A'">
          {{ treatmentUtil.getLabel(treatment['Label']) }}
        </h2>

        <h2 class="summary-header rm-m" *ngIf="treatment['Label'] == 'N/A' && treatment['Category.Label'] != 'N/A'">
          {{ treatmentUtil.getLabel(treatment['Category.Label']) }}
        </h2>

        <div class="date-chip-area">
          <p class="small rm-m mr" *ngIf="treatment.DateTimeCreated && treatment.DateTimeCreated != '0000-00-00'">
            <mat-icon class="fas fa-clock"></mat-icon>
            {{ treatment.DateTimeCreated | TimeLabelPast }} ago
          </p>

          <p class="clearfix small rm-m" *ngIf="treatment['Active'] == '1' && isPromoterOrAdmin == true">
            <span class="chip-enable">Active</span>
          </p>
          <p class="clearfix small rm-m" *ngIf="treatment['Active'] == '0' && isPromoterOrAdmin == true">
            <span class="chip-disable">Not active</span>
          </p>
        </div>
      </div>
    </div>
    <div class="colourOverlay" [ngStyle]="{ background: treatment['Category.Colour'] }"></div>
  </div>

  <mat-dialog-content class="stacked-card-view">
    <div class="row clearfix flex catType">
      <div class="block full-width clicktext" *ngIf="treatment['Category.Label']">
        <mat-icon class="fas fa-th"></mat-icon>
        <div class="row-content">
          <p class="small rm-m">Service Category</p>
          <h4
            class="reduce-marg"
            [ngStyle]="{ color: treatment['Category.Colour'] }"
            (click)="viewCategory(treatment['Category_key'])"
          >
            {{ treatmentUtil.getLabel(treatment['Category.Label']) }}
          </h4>
        </div>
      </div>

      <div class="block qtr-width" *ngIf="treatment['Dafault_Item_Code']">
        <div class="row-content">
          <p class="small rm-m">Item Code</p>
          <h5 class="reduce-marg" [ngStyle]="{ color: treatment['Category.Colour'] }">
            {{ treatmentUtil.getLabel(treatment['Dafault_Item_Code']) }}
          </h5>
        </div>
      </div>
    </div>

    <hr/>

    <div class="row clearfix">
      <p class="clearfix" [innerHTML]="treatment['Description'] | safeHtml">
        <!-- treatment product desctiption text -->
      </p>
    </div>
  </mat-dialog-content>
</mat-card>
