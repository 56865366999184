import { Component, EventEmitter, Inject, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-medical-history-language-modal',
  templateUrl: './medical-history-language-modal.component.html',
  styleUrls: ['./medical-history-language-modal.component.css'],
})
export class MedicalHistoryLanguageModalComponent implements OnInit {
  @Output()
  closeModal = new EventEmitter();

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      // data
    }
  }

  ngOnInit() {}

  close() {
    this.closeModal.emit(true);
  }
}
