<div class="row clearfix">
  <h3 class="sm-h3 sr-title rm-mt rm-mb">Informed Consent: Medical History</h3>
</div>
<mat-dialog-content class="infCons">
  <div class="row clearfix">
    <p>I hereby consent to have my medical history collected and stored by the
      dental {{"KEYWORD.practice" | translate}}</p>
    <div class="consent-area">
      <ul>
        <li>Medical History consent text line 1;</li>
        <li>Medical History consent text line 2;</li>
        <li>Medical History consent text line 3;</li>
        <li>Medical History consent text line 4;</li>
        <li>Medical History consent text line 5;</li>
        <li>Medical History consent text line 6;</li>
        <li>Medical History consent text line 7;</li>
        <li>Medical History consent text line 8;</li>
        <li>Medical History consent text line 9;</li>
        <li>Medical History consent text line 10;</li>
        <li>Medical History consent text line 11;</li>
        <li>Medical History consent text line 2;</li>
      </ul>
    </div>
    <div class="consent-area">
      <div *ngIf="merchantInformedConsent" [innerHTML]="merchantInformedConsent | safeHtml"></div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="borderTop">
  <div class="full-width row clearfix text-center">
    <mat-slide-toggle class="slide-toggle-large alternate-theme" [(ngModel)]="isAgree">
      I make the declarations above
    </mat-slide-toggle>
  </div>
  <div class="full-width row clearfix text-center">
    <button class="proceed" [disabled]="!isAgree" (click)="changeAgreement()" mat-raised-button color="accent">
      Proceed
    </button>
  </div>
</mat-dialog-actions>
