import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';

@Component({
  selector: 'app-two-factor-modal',
  templateUrl: './two-factor-modal.component.html',
  styleUrls: ['./two-factor-modal.component.css'],
})
export class TwoFactorModalComponent implements OnInit {
  @Input()
  mobileNumber;

  timeLeft: number;
  interval;
  code;
  isModal = false;

  @Output()
  close = new EventEmitter();
  @Output()
  codeVerfied = new EventEmitter();

  authenticationSuccess;

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      this.mobileNumber = data;

      this.isModal = true;
    }
  }

  ngOnInit() {
    this.countDown();
  }

  closeModal() {
    this.close.emit(true);
  }

  verfityCode() {
    if (this.code == 123456) {
      this.codeVerfied.emit(true);
      NotifyAppComponent.displayToast('success', 'Code verified', 'You are successfully verified by us.');
    } else {
      NotifyAppComponent.displayToast(
        'warning',
        'Incorrect code',
        'Please re-enter the code or wait to resend the code.'
      );
    }
  }

  countDown() {
    this.timeLeft = 60;
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        clearInterval(this.interval);
      }
    }, 1000);
  }
}
