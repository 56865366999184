<div class="row clearfix patient-edit" *ngIf="patient">
  <form #editForm="ngForm">
    <div class="card-header primary-gradient-img clearfix inModal">
      <div class="row clearfix">
        <button class="btn-close btn-clear mat-button white" *ngIf="isModal == true" (click)="closeEvent()">
          <mat-icon>clear</mat-icon>
        </button>
        <div class="row titleArea clearfix">
          <app-invitation-customer-prospect-view-picture
            class="pull-left customer-prospect-edit mr"
            [patientID]="patientID"
            [modifiedDate]="modifiedDate"
          >
          </app-invitation-customer-prospect-view-picture>

          <h2 class="summary-header rm-m">
            Edit {{ "KEYWORD.patient" | translate | titlecase }}
          </h2>
        </div>
      </div>
    </div>

    <mat-dialog-content>
      <div class="row clearfix mt">
        <div class="icons-col">
          <mat-icon>person</mat-icon>
        </div>
        <div class="row-content-col">
          <div class="row clearfix flex">
            <!-- Gender Select -->
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Gender</mat-label>
              <mat-select
                class=""
                [(ngModel)]="patient['Gender.Code']"
                name="gender"
                placeholder="Gender"
                required
              >
                <mat-option *ngFor="let gender of genders" [value]="gender.Code">
                  {{ gender.Label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <!-- Title Select -->
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Title</mat-label>
              <mat-select class="full-width" [(ngModel)]="patient.Salutation" placeholder="Title" name="title">
                <mat-option *ngFor="let title of titles" [value]="title.Label">
                  {{ title.Label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="row clearfix flex">
            <!-- First Name -->
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>First Name</mat-label>
              <input
                class="mat-block"
                [(ngModel)]="patient.FirstName"
                matInput
                placeholder="{{ 'PLACEHOLDER.First Name' | translate }} "
                name="firstname"
                required
              />
            </mat-form-field>

            <!-- Middle Name -->
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Middle Name</mat-label>
              <input
                class="mat-block"
                [(ngModel)]="patient.MiddleName"
                matInput
                placeholder="{{ 'PLACEHOLDER.Middle Name' | translate }} "
                name="middlename"
              />
            </mat-form-field>

            <!-- Surname -->
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Surname</mat-label>
              <input
                class="mat-block"
                [(ngModel)]="patient.Name"
                matInput
                placeholder="{{ 'PLACEHOLDER.Surname' | translate }} "
                name="lastname"
                required
              />
            </mat-form-field>
          </div>
        </div>
      </div>


      <div class="patient-details row clearfix" #patientContactBlock>
        <div class="row clearfix">
          <div class="icons-col">
            <mat-icon class="">phone</mat-icon>
          </div>
          <div class="row-content-col">
            <div class="row clearfix flex">

            <app-phone-input
                class="full-width"
                [label]="'Mobile'"
                [required]="true"
                [phoneNumber]="patient['mobiles.Number']"
                (validate)="validateMobile($event)"
                (getPhone)="getMobile($event)"
              >
              </app-phone-input>

              <app-phone-input
                class="full-width"
                [isLandLine]="true"
                [label]="'Phone'"
                [required]="false"
                [phoneNumber]="patient['phones.Number']"
                (validate)="validatePhone($event)"
                (getPhone)="getPhone($event)"
              >
              </app-phone-input>
            </div>
          </div>
        </div>
        <div class="row clearfix">
          <div class="icons-col">
            <mat-icon>email</mat-icon>
          </div>
          <div class="row-content-col">
            <!-- Email Address -->
            <app-email-input
              class="full-width"
              [label]="'Email'"
              [required]="true"
              [email]="patient['emails.Email']"
              (getEmail)="getEmail($event)"
              (validate)="validateEmail($event)"
            >
            </app-email-input>
          </div>
        </div>
        <div class="row clearfix">
          <div class="icons-col">
            <mat-icon>account_circle</mat-icon>
          </div>
          <div class="row-content-col">
            <!-- Email Address -->
            <app-date-input
              class="full-width"
              [defaultDate]="dateOfBirth"
              [startView]="'multi-year'"
              [isTime]="false"
              [maxDate]="'toDay'"
              (selectedDate)="selectDOB($event)"
              label="Date of birth"
            >
            </app-date-input>
          </div>
        </div>
      </div>

      <hr/>

      <div class="row clearfix text-right">
        <button
          class=""
          [disabled]="!editForm.form.valid || !isEmailValid || !isMobileValid || !isPhoneValid"
          (click)="modify()"
          mat-raised-button
          color="accent"
        >
          Modify
        </button>
      </div>
    </mat-dialog-content>
  </form>
</div>
