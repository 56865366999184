import { Component, Input, OnInit } from '@angular/core';
import { MessageService } from '../shared/message.service';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { Router } from '@angular/router';
import { HeaderService } from '../../../shared/services/header.service';
import { Settings } from '../../../shared/types/settings';
import { MerchantViewComponent } from '../../merchant/merchant-view/merchant-view.component';
import * as _ from 'lodash';
import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { ActivatedRoute } from '@angular/router';
import { MessageUtil } from '../shared/messageUtil.type';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { ConfirmDialogSingleButton } from '../../../shared/types/confirm-dialog-single-button';
import { ConfirmDialogSingleButtonComponent } from '../../../shared/components/confirm-dialog-single-button/confirm-dialog-single-button.component';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { UtilsService } from '../../../shared/services/utils.service';
import { MessageGroupLocalListComponent } from '../message-group-local-list/message-group-local-list.component';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';

@Component({
  selector: 'app-message-group-list-overview',
  templateUrl: './message-group-list-overview.component.html',
  styleUrls: ['./message-group-list-overview.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('*', style({ opacity: 0 }), { optional: true }),
        query(
          '*',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('*', style({ opacity: 1 }), { optional: true }),
        query(
          '*',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class MessageGroupListOverviewComponent implements OnInit {
  messageLabel = 'SMS';

  isAdminOrPromoter = false;
  @Input()
  pageSize = 10;
  merchants = [];
  merchantID;
  _merchantID;
  isLoaded = false;

  globalStatistics = {
    complete: 0,
    canceled: 0,
    Sending: 0,
    Scheduled: 0,
    ScheduledMessages: 0,
    Delivered: 0,
    Queued: 0,
    Submitted: 0,
    progress: 0,
    totalProgress: 0,
    sent: 0,
  };
  statistics = null;
  totalMessages = 0;
  chartVisitorData = [];
  colorScheme = [];

  totalMessagesLocal = 0;
  chartVisitorDataLocal = [];
  colorSchemeLocal = [];
  isQuickView = false;
  isSMS = true;
  activeStatusArray = [];

  status = 'Complete';

  isStateLoad = false;

  isModuleBulkSMS = Settings.global['isModuleBulkSMSActive'];
  messageUtil = new MessageUtil();

  statusColors = this.messageUtil.statusColors;
  currentPractice;
  messageStatusColors = this.messageUtil.messageStatusColors;
  isPromoterOrAdmin = false;
  sessionType;
  statusMessageArray1 = this.messageUtil.statusMessageArray1;
  statusMessageArray2 = this.messageUtil.statusMessageArray2;
  statusMessageArray3 = this.messageUtil.statusMessageArray3;

  messageGroupStatus = this.messageUtil.messageGroupStatus;
  statusArray = this.messageUtil.statusArray;
  constructor(
    private messageService: MessageService,

    private utilService: UtilsService,
    private authenticationService: AuthenticationService,
    private router: Router
  ) {}

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((sessionType) => {
      this.sessionType = sessionType;

      this.utilService.getCurrentAccess().subscribe((access) => {
        if (access) {
          this.isModuleBulkSMS = access['isModuleBulkSMSActive'];
        }

        if (this.isModuleBulkSMS != true) {
          this.router.navigate(['404']);
        }
        this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
          this.isAdminOrPromoter = res;
          if (this.isSMS == true) {
            this.statusMessageArray1 = this.messageUtil.statusMessageArray1SMS;
            this.statusMessageArray2 = this.messageUtil.statusMessageArray2SMS;
            this.statusMessageArray3 = this.messageUtil.statusMessageArray3SMS;
          }

          this.authenticationService.isPromoterOrAdmin().subscribe((isPromoterOrAdmin) => {
            this.isPromoterOrAdmin = isPromoterOrAdmin;

            if (isPromoterOrAdmin == true) {
              this.authenticationService.getCurrentPractice().subscribe((CurrentPractice) => {
                if (CurrentPractice && CurrentPractice.ID) {
                  this.merchantID = CurrentPractice.ID;
                  this._merchantID = CurrentPractice.ID;
                  this.currentPractice = CurrentPractice;
                }

                this.authenticationService.getMerchantList({ fields: 'ID,TradingAs' }).subscribe((merchants) => {
                  if (merchants) {
                    this.merchants = merchants;
                  }

                  this.setup();
                });
              });
            } else {
              this.setup();
            }
            this.messageService.getMessageGroupStatusColors().subscribe((colors) => {
              if (colors) {
                this.statusColors = colors || {};

                this.messageUtil.setStatusColors(this.statusColors);
              }
            });

            this.messageService.getMessageGroupmessageStatusColors().subscribe((messageStatusColors) => {
              if (messageStatusColors) {
                this.messageStatusColors = messageStatusColors || {};

                this.messageUtil.setMessageStatusColors(this.messageStatusColors);
              }
            });
          });
        });
      });
    });
  }

  setup() {
    this.isLoaded = false;
    this.isStateLoad = false;
    this.statistics = null;
    this.globalStatistics = {
      complete: 0,
      canceled: 0,
      Sending: 0,
      Scheduled: 0,
      ScheduledMessages: 0,
      Delivered: 0,
      Queued: 0,
      Submitted: 0,
      progress: 0,
      totalProgress: 0,

      sent: 0,
    };
    let p: any = {};

    if (this.merchantID) {
      p.merchantID = this.merchantID;
    }

    this.messageService.getCampaignStatistics(p, this.sessionType).subscribe((r) => {
      if (r && r.messageGroup && r.messageGroup.length > 0) {
        this.statistics = r;

        this.addChart();
      } else {
        this.isStateLoad = true;
      }
    });
  }

  addChart() {
    this.isStateLoad = false;

    this.totalMessages = 0;
    this.chartVisitorData = [];

    this.totalMessagesLocal = 0;
    this.chartVisitorDataLocal = [];

    this.colorScheme = [];
    this.colorSchemeLocal = [];

    this.activeStatusArray = [];
    this.globalStatistics = {
      complete: 0,
      Sending: 0,
      canceled: 0,
      ScheduledMessages: 0,
      Scheduled: 0,
      Delivered: 0,
      Queued: 0,
      Submitted: 0,
      progress: 0,
      totalProgress: 0,
      sent: 0,
    };

    if (this.statistics && this.statistics.messageGroup) {
      this.statistics.messageGroup.map((item) => {
        if (item && item.totalCampagins > 0) {
          this.activeStatusArray.push(item);
        }
        if (item && item.status === 'Canceled') {
          this.globalStatistics.canceled = item.totalCampagins || 0;
        }

        if (item && item.status === 'Complete') {
          this.globalStatistics.complete = item.totalCampagins || 0;
          this.globalStatistics.Submitted = this.globalStatistics.Submitted + (item.totalTargets || 0);
        }

        if (item && item.status === 'Sending') {
          this.globalStatistics.Sending = this.globalStatistics.Sending + (item.totalCampagins || 0);
          this.globalStatistics.Submitted = this.globalStatistics.Submitted + (item.totalTargets || 0);
        }

        if (item && item.status === 'Pending') {
          this.globalStatistics.Sending = this.globalStatistics.Sending + (item.totalCampagins || 0);
          this.globalStatistics.Submitted = this.globalStatistics.Submitted + (item.totalTargets || 0);
        }
        if (item && item.status === 'Scheduled') {
          this.globalStatistics.Scheduled = this.globalStatistics.Scheduled + (item.totalCampagins || 0);
          this.globalStatistics.Submitted = this.globalStatistics.Submitted + (item.totalTargets || 0);
        }
      });
    }

    this.activeStatusArray = _.orderBy(this.activeStatusArray, 'status');

    let objectStatus = {};

    this.activeStatusArray.map((d) => {
      if (d && d.totalCampagins > 0) {
        objectStatus[d.status] = true;
      }
    });

    if (objectStatus['Complete'] === true) {
      this.status = 'Complete';
    } else if (objectStatus['Submitted'] === true) {
      this.status = 'Submitted';
    } else if (objectStatus['Sending'] === true) {
      this.status = 'Sending';
    } else if (objectStatus['Pending'] === true) {
      this.status = 'Pending';
    } else if (objectStatus['Draft'] === true) {
      this.status = 'Draft';
    } else if (objectStatus['Scheduled'] === true) {
      this.status = 'Scheduled';
    } else {
      this.status = null;
    }

    if (this.statistics && this.statistics.messages) {
      this.statistics.messages.map((item) => {
        if (item && item.count > 0 && item.status && item.status != 'null' && item.status != 'Total') {
          this.colorScheme.push(item.color);
          this.totalMessages = this.totalMessages + Number(item.count);
        }

        if (item && item.status === 'Delivered') {
          this.globalStatistics.Delivered = this.globalStatistics.Delivered + Number(item.count || 0);
        }
        if (item && item.status === 'Sent') {
          this.globalStatistics.Delivered = this.globalStatistics.Delivered + Number(item.count || 0);
        } else if (item && item.status === 'Printed') {
          this.globalStatistics.Delivered = this.globalStatistics.Delivered + Number(item.count || 0);
        } else if (item && item.status === 'Read') {
          this.globalStatistics.Delivered = this.globalStatistics.Delivered + Number(item.count || 0);
        } else if (item && item.status === 'Clicked') {
          this.globalStatistics.Delivered = this.globalStatistics.Delivered + Number(item.count || 0);
        } else if (item && item.status === 'Received') {
          this.globalStatistics.Delivered = this.globalStatistics.Delivered + Number(item.count || 0);
        } else if (item && item.status === 'Read_Incoming') {
          this.globalStatistics.Delivered = this.globalStatistics.Delivered + Number(item.count || 0);
        } else if (item && item.status === 'Opened') {
          this.globalStatistics.Delivered = this.globalStatistics.Delivered + Number(item.count || 0);
        }
      });

      this.statistics.messages.map((item) => {
        if (item) {
          if (item && item.count > 0 && item.status && item.status != 'null' && item.status != 'Total') {
            this.chartVisitorData.push({
              code: item.status,
              name: `${item.status} - ${Math.trunc((Number(item.count) / Number(this.totalMessages)) * 100)}%`,
              value: Number(item.count),
            });
          }
        }
      });
    }

    if (this.statistics && this.statistics.queue) {
      this.statistics.queue.map((item) => {
        if (item && item.count > 0 && item.status && item.status != 'null' && item.status != 'Total') {
          this.colorSchemeLocal.push(item.color);
          this.totalMessagesLocal = this.totalMessagesLocal + Number(item.count);
        }
      });

      this.statistics.queue.map((item) => {
        if (item) {
          if (item && item.count > 0 && item.status && item.status != 'null' && item.status != 'Total') {
            this.chartVisitorDataLocal.push({
              code: item.status,
              name: `${item.status} - ${Math.trunc((Number(item.count) / Number(this.totalMessagesLocal)) * 100)}%`,
              value: Number(item.count),
            });
          }

          if (item && item.status === 'Pending') {
            this.globalStatistics.Queued = this.globalStatistics.Queued + (item.count || 0);
            this.globalStatistics.totalProgress = this.globalStatistics.totalProgress + (item.count || 0);
          }
          if (item && item.status === 'Scheduled') {
            this.globalStatistics.ScheduledMessages = this.globalStatistics.ScheduledMessages + (item.count || 0);
            this.globalStatistics.totalProgress = this.globalStatistics.totalProgress + (item.count || 0);
          }

          if (item && item.status === 'Sent') {
            this.globalStatistics.sent = this.globalStatistics.sent + (item.count || 0);
            this.globalStatistics.totalProgress = this.globalStatistics.totalProgress + (item.count || 0);
          }

          if (item && item.status === 'Paused') {
            this.globalStatistics.totalProgress = this.globalStatistics.totalProgress + (item.count || 0);
          }
        }
      });
    }

    if (this.globalStatistics.sent > 0 && this.globalStatistics.totalProgress > 0) {
      this.globalStatistics.progress = Math.trunc(
        (Number(this.globalStatistics.sent) / Number(this.globalStatistics.totalProgress)) * 100
      );
    }

    this.activeStatusArray = JSON.parse(JSON.stringify(this.activeStatusArray));
    this.isStateLoad = true;
  }

  displayStatusDescription(s) {
    if (s && this.messageUtil.getMessageDescription(s)) {
      const confirmDialog2 = new ConfirmDialogSingleButton(
       this.messageUtil.getMessageStatusIcon(s),
        s,
        '<p>' + this.messageUtil.getMessageDescription(s) + '</p>',
        'OKAY',
        false,
        this.messageUtil.getStatusColor(s, false)
      );
      const ref2 = RootAppComponent.dialog.open(ConfirmDialogSingleButtonComponent, {
        data: confirmDialog2,
        width: '450px',
      });
      ref2.componentInstance.onConfirm.subscribe((res) => {
        ref2.close();
      });
    }
  }

  refreshMessageGroup() {
    let confirm = new ConfirmDialog(
      'fas fa-sync-alt',
      '',
      'Are you sure you want to <strong>Refresh</strong>  the statistics  ?',
      'No',
      'Yes'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirm,
    });
    ref.componentInstance.onConfirm.subscribe((confirmation) => {
      if (confirmation === false) {
        ref.close();
      } else {
        ref.close();
        this.setup();
      }
    });
  }

  add() {
    this.router.navigate([
      '/merchant',
      {
        outlets: {
          page: ['message-group-create'],
        },
      },
    ]);
  }
  displayStatusGroupDescription(s) {
    if (s && this.messageUtil.getMessageGroupDescription(s)) {
      const confirmDialog2 = new ConfirmDialogSingleButton(
        this.messageUtil.getStatusIcon(s),
        s,
        '<p>' + this.messageUtil.getMessageGroupDescription(s) + '</p>',
        'OKAY',
        false,
        this.messageUtil.getStatusColor(s, true)
      );
      const ref2 = RootAppComponent.dialog.open(ConfirmDialogSingleButtonComponent, {
        data: confirmDialog2,
        width: '450px',
      });
      ref2.componentInstance.onConfirm.subscribe((res) => {
        ref2.close();
      });
    }
  }

  viewMessageList(status = null, messageStatus = null) {
    if (status || messageStatus) {
      const ref = RootAppComponent.dialog.open(MessageGroupLocalListComponent, {
        data: {
          merchantID: this.merchantID,
          status: status,
          messageStatus: messageStatus,
        },
        width: '900px',
        panelClass: 'noCard',
      });

      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    }
  }
  changeMerchant() {
    if (this._merchantID != this.merchantID) {
      this.isStateLoad = false;
      this.merchantID = this._merchantID;
      this.setup();
    }
  }

  splitCampaginStatusArray(s) {
    if (s == 1) {
      return this.messageGroupStatus.slice(0, 4);
    } else {
      return this.messageGroupStatus.slice(4, 9);
    }
  }
  hardRefresh() {

      const confirm = new ConfirmDialog(
        'fas fa-redo-alt',
        '',
        '<p><strong> Hard Refresh </strong> will force the update of the status of all sent SMS messages. do you wish to continue?</p>',
        'No',
        'Yes'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation === false) {
          ref.close();
        } else {
          this.messageService
            .messageGroupHardRefresh({ merchantID: this.merchantID }, this.sessionType)
            .subscribe((res) => {
              if (res) {
                this.setup();

                NotifyAppComponent.displayToast('success', 'Successful operation', '');
              }
              ref.close();
            });
        }
      });

  }

}
