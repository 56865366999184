import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
@Injectable()
export class SocialMediaService {
  constructor(private http: HttpClient) {}

  createSocialMedia(payload, sessionType) {
    if (sessionType == 'admin' || sessionType == 'promoter') {
      const urlPatient = environment.nodeUrl + '/social-media/global';
      return this.http.post(urlPatient, payload).pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      const urlPatient = environment.nodeUrl + '/social-media';
      return this.http.post(urlPatient, payload).pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }
  createSocialMediaPost(payload, sessionType) {
    if (sessionType == 'admin' || sessionType == 'promoter') {
      const urlPatient = environment.nodeUrl + '/sm/global';
      return this.http.post(urlPatient, payload).pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      const urlPatient = environment.nodeUrl + '/sm';
      return this.http.post(urlPatient, payload).pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getCamaignList(payload, isAdminOrPromoter = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (isAdminOrPromoter == true) {
      return this.http
        .get(environment.nodeUrl + '/marketing/global', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/marketing', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getSocialMediaDetails(id, payload, sessionType) {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (sessionType == 'admin' || sessionType == 'promoter') {
      const urlPatient = environment.nodeUrl + '/social-media/global/' + id;
      return this.http.get(urlPatient, options).pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      const urlPatient = environment.nodeUrl + '/social-media/' + id;
      return this.http.get(urlPatient, options).pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getSocialMediaPostDetails(id, payload, sessionType) {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (sessionType == 'admin' || sessionType == 'promoter') {
      const urlPatient = environment.nodeUrl + '/sm/global/' + id;
      return this.http.get(urlPatient, options).pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      const urlPatient = environment.nodeUrl + '/sm/' + id;
      return this.http.get(urlPatient, options).pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  editSocialMediaGroup(payload): Observable<any> {
    return this.http
      .put(`${environment.nodeUrl}/social-media/group`, payload)
      .pipe(map((res: Response) => res['data']));
  }

  editSocialMedia(id, payload, sessionType) {
    if (sessionType == 'admin' || sessionType == 'promoter') {
      const urlPatient = environment.nodeUrl + '/social-media/global/' + id;
      return this.http.put(urlPatient, payload).pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      const urlPatient = environment.nodeUrl + '/social-media/' + id;
      return this.http.put(urlPatient, payload).pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }
  editSocialMediaPost(id, payload, sessionType) {
    if (sessionType == 'admin' || sessionType == 'promoter') {
      const urlPatient = environment.nodeUrl + '/sm/global/' + id;
      return this.http.put(urlPatient, payload).pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      const urlPatient = environment.nodeUrl + '/sm/' + id;
      return this.http.put(urlPatient, payload).pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }
  getMarketingTemplate(ID, payload = {}) {
    const params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key) && payload[key]) {
          params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }

    return this.http
      .get(`${environment.nodeUrl}/marketing/template/` + ID, options)
      .pipe(map((res: Response) => res['data']));
  }

  getTemplateImage(id) {
    return this.http
      .get(`${environment.nodeUrl}/template/picture/${id}`)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getMarketingDetails(id, payload, sessionType = 'guest'): Observable<any> {
    const params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key) && payload[key]) {
          params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }

    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http
        .get(environment.nodeUrl + '/marketing/global/' + id, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
      return this.http
        .get(environment.nodeUrl + '/marketing/' + id, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/marketing/public/' + id, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  editMarketingSocialMedia(id, payload, sessionType) {
    if (sessionType == 'admin' || sessionType == 'promoter') {
      const urlPatient = environment.nodeUrl + '/marketing/social-media/global/' + id;
      return this.http.put(urlPatient, payload).pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      const urlPatient = environment.nodeUrl + '/marketing/social-media/' + id;
      return this.http.put(urlPatient, payload).pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }
  getSocialMediaList(payload = {}, sessionType) {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http
        .get(`${environment.nodeUrl}/social-media/global`, options)
        .pipe(map((res: Response) => res['data']));
    } else {
      return this.http.get(`${environment.nodeUrl}/social-media`, options).pipe(map((res: Response) => res['data']));
    }
  }
  getSocialMediaPostList(payload = {}, sessionType) {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http.get(`${environment.nodeUrl}/sm/global`, options).pipe(map((res: Response) => res['data']));
    } else {
      return this.http.get(`${environment.nodeUrl}/sm`, options).pipe(map((res: Response) => res['data']));
    }
  }

  getPostImageStreamLink(ID, LastModified = null) {
    if (
      LastModified &&
      LastModified != '' &&
      LastModified != undefined &&
      LastModified != 'null' &&
      LastModified != 'undefined '
    ) {
      return `${environment.nodeUrl}/social-media/post-picture-stream-cached/${LastModified}/` + ID;
    } else if (ID) {
      return `${environment.nodeUrl}/social-media/post-picture-stream/` + ID;
    }
    return null;
  }

  getThumbnailImageStreamLink(ID, LastModified = null) {
    if (
      LastModified &&
      LastModified != '' &&
      LastModified != undefined &&
      LastModified != 'null' &&
      LastModified != 'undefined '
    ) {
      return `${environment.nodeUrl}/social-media/thumbnail-stream-cached/${LastModified}/` + ID;
    } else if (ID) {
      return `${environment.nodeUrl}/social-media/thumbnail-stream/` + ID;
    }
    return null;
  }

  getPostImage(ID) {
    return this.http
      .get(`${environment.nodeUrl}/social-media/post-picture/` + ID, {})
      .pipe(map((res: Response) => res['data']));
  }

  getThumbnailImage(ID) {
    return this.http
      .get(`${environment.nodeUrl}/social-media/thumbnail/` + ID, {})
      .pipe(map((res: Response) => res['data']));
  }

  uploadPostImage(ID) {
    if (ID) {
      return `${environment.nodeUrl}/social-media/post-picture-stream/` + ID;
    }
    return null;
  }

  uploadThumbnailImage(ID) {
    if (ID) {
      return `${environment.nodeUrl}/social-media/thumbnail-stream/` + ID;
    }
    return null;
  }

  removeSocialMedia(ID): Observable<any> {
    return this.http
      .delete(`${environment.nodeUrl}/social-media/` + ID, {})
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }
  removeSocialMediaGroup(ids): Observable<any> {
    let _ids;
    if (Array.isArray(ids) == true) {
      _ids = ids.join(',');
    } else if (typeof ids == 'string') {
      _ids = ids;
    }

    if (_ids) {
      return this.http
        .delete(environment.nodeUrl + '/social-media/group/' + _ids)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }
  removeSocialMediaPost(ID): Observable<any> {
    return this.http.delete(`${environment.nodeUrl}/sm/` + ID, {}).pipe(map((res: HttpResponse<any>) => res['data']));
  }
  removeSocialMediaPostGroup(ids): Observable<any> {
    let _ids;
    if (Array.isArray(ids) == true) {
      _ids = ids.join(',');
    } else if (typeof ids == 'string') {
      _ids = ids;
    }

    if (_ids) {
      return this.http
        .delete(environment.nodeUrl + '/sm/group/' + _ids)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }
  getBeneficiaryList(payload = {}, isPromoterOrAdmin = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }

    if (isPromoterOrAdmin == true) {
      return this.http
        .get(environment.nodeUrl + '/beneficiary/global/', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/beneficiary/', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  confirmShareSocialMediaPost(id, sessionType) {
    if (sessionType == 'admin' || sessionType == 'promoter') {
      const urlPatient = environment.nodeUrl + '/sm/confirm/global/' + id;
      return this.http.put(urlPatient, {}).pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      const urlPatient = environment.nodeUrl + '/sm/confirm/' + id;
      return this.http.put(urlPatient, {}).pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }
}
