/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./logout.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/menu/typings/index.ngfactory";
import * as i3 from "@angular/material/menu";
import * as i4 from "@angular/common";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i7 from "@angular/material/icon";
import * as i8 from "./logout.component";
import * as i9 from "../shared/authentication.service";
import * as i10 from "@angular/router";
import * as i11 from "../../../shared/services/calendar-share.service";
var styles_LogoutComponent = [i0.styles];
var RenderType_LogoutComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LogoutComponent, data: {} });
export { RenderType_LogoutComponent as RenderType_LogoutComponent };
export function View_LogoutComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "button", [["class", "mat-menu-item"], ["mat-menu-item", ""]], [[1, "role", 0], [2, "mat-menu-item-highlighted", null], [2, "mat-menu-item-submenu-trigger", null], [1, "tabindex", 0], [1, "aria-disabled", 0], [1, "disabled", 0]], [[null, "click"], [null, "mouseenter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1)._checkDisabled($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1)._handleMouseEnter() !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (_co.displayConfirmModal() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_MatMenuItem_0, i2.RenderType_MatMenuItem)), i1.ɵdid(1, 180224, null, 0, i3.MatMenuItem, [i1.ElementRef, i4.DOCUMENT, i5.FocusMonitor, [2, i3.MAT_MENU_PANEL]], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 4, "p", [["class", "small main-description"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "mat-icon", [["class", "fas fa-sign-out-alt mat-icon notranslate"], ["role", "img"], ["style", "color:red  !important;"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i6.View_MatIcon_0, i6.RenderType_MatIcon)), i1.ɵdid(4, 9158656, null, 0, i7.MatIcon, [i1.ElementRef, i7.MatIconRegistry, [8, null], [2, i7.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "strong", [["style", "color:red  !important;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Log Out"]))], function (_ck, _v) { _ck(_v, 4, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).role; var currVal_1 = i1.ɵnov(_v, 1)._highlighted; var currVal_2 = i1.ɵnov(_v, 1)._triggersSubmenu; var currVal_3 = i1.ɵnov(_v, 1)._getTabIndex(); var currVal_4 = i1.ɵnov(_v, 1).disabled.toString(); var currVal_5 = (i1.ɵnov(_v, 1).disabled || null); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_6 = i1.ɵnov(_v, 4).inline; var currVal_7 = (((i1.ɵnov(_v, 4).color !== "primary") && (i1.ɵnov(_v, 4).color !== "accent")) && (i1.ɵnov(_v, 4).color !== "warn")); _ck(_v, 3, 0, currVal_6, currVal_7); }); }
export function View_LogoutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-logout", [], null, null, null, View_LogoutComponent_0, RenderType_LogoutComponent)), i1.ɵdid(1, 114688, null, 0, i8.LogoutComponent, [i9.AuthenticationService, i10.Router, i10.ActivatedRoute, i11.CalendarShareService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LogoutComponentNgFactory = i1.ɵccf("app-logout", i8.LogoutComponent, View_LogoutComponent_Host_0, {}, { close: "close" }, []);
export { LogoutComponentNgFactory as LogoutComponentNgFactory };
