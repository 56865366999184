import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, Inject, Input, OnInit, Optional, ViewChild } from '@angular/core';
import { WizardComponent } from 'angular-archwizard';
import { MerchantViewComponent } from '../../merchant/merchant-view/merchant-view.component';
import { MarketingService } from '../shared/marketing.service';
import { MarketingUtilClass } from '../shared/marketingUtil';

import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { HeaderService } from '../../../shared/services/header.service';
import { UtilsService } from '../../../shared/services/utils.service';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { CustomerProspectBoostModalComponent } from '../../customer-prospect/customer-prospect-boost-modal/customer-prospect-boost-modal.component';
import { DentistService } from '../../dentist/shared/dentist.service';
import { MerchantService } from '../../merchant/shared/merchant.service';
import { SocialMediaGalleryListComponent } from '../../social-media/social-media-gallery-list/social-media-gallery-list.component';
import { TagsService } from '../../tags/shared/tags.service';
import { MarketingCampaignViewComponent } from '../marketing-campaign-view/marketing-campaign-view.component';

@Component({
  selector: 'app-marketing-campaign-create-edit',
  templateUrl: './marketing-campaign-create-edit.component.html',
  styleUrls: ['./marketing-campaign-create-edit.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.animate', style({ opacity: 0 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.4s ease-out',
              keyframes([
                style({ opacity: 0, offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.animate', style({ opacity: 1 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.4s ease-in',
              keyframes([
                style({ opacity: 1, offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
    trigger('labelAnimation', [
      transition('void => *', [
        query('label', style({ opacity: 0 }), { optional: true }),
        query(
          'label',
          stagger('100ms', [
            animate(
              '0.15s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateX(-30px)', offset: 0 }),
                style({ opacity: 1, transform: 'translateX(5px)', offset: 0.5 }),
                style({ opacity: 1, transform: 'translateX(0)', offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class MarketingCampaignCreateEditComponent implements OnInit {
  @ViewChild(WizardComponent, { static: false }) public wizard: WizardComponent;

  @Input()
  campaignID;

  @Input()
  isBusiness = false;

  @Input()
  isBusinessOrDefault = true;

  @Input()
  isMerchantTemplate = null;
  @Input()
  isManualCreatedOrDefault = true;

  @Input()
  isCampaign = true;

  @Input()
  isActive = true;

  @Input()
  isForMerchant = false;

  delayDate = false;
  displayCTA = true;
  isPublicCampaign = true;
  loadTempalates = false;
  respectMarketing = false;
  hasExpirationDate = false;
  exprirationDate;
  respectReferredPatient = false;
  displayPracticePage = true;
  statusLabel;
  utilMarketing = new MarketingUtilClass();

  userData;
  title = 'Campaign Templates';
  description = 'This is the Campaign Template List';

  content;

  validateUserData = false;

  util = new UtilsClass();
  selectedTemplateID;
  selectedTemplate;

  campaignDescription;
  campaignName;
  actionCallLabel;
  actionCallDescription;

  isSendEmail = false;
  isSendSMS = false;
  isSent = false;
  whenToSend = moment().add(20, 'minutes').toDate();
  selectedDateObject;
  selectedDate = moment().add(20, 'minutes').toDate();

  minDate = moment().add(20, 'minutes').toDate();

  completCompaignButton = true;

  preview = 'email';

  newAudience = false;
  public selectedAudience = 0;

  planeFly = false;

  contacts = [];
  merchants = [];
  isPromoterOrAdmin = false;
  createdCampaign = null;
  displayCampaignReview = false;
  merchantID;
  selectedTemplatePreviewID;
  isSending = true;
  isSended;

  contactID;
  smsSupport = true;
  emailSupport = true;
  dateTimeSelected = false;
  editMode = false;

  successedEmail = 0;
  successedSMS = 0;

  failedEmail = 0;
  failedSMS = 0;

  allEmail = 0;
  allSMS = 0;

  allTarget = 0;
  successedTarget = 0;
  failedTarget = 0;

  patientNumber: any = '0';
  customerNumber: any = '0';
  allNumber: any = '0';

  target;
  displayMerchantSelect = true;

  isParams = false;

  showDateInput = false;

  defaultExpirationDate = moment().add(1, 'months').toDate();

  isModuleBoostReachActive = Settings.global['isModuleBoostReachActive'];
  isModuleExperimentalFeaturesActive = Settings.global['isModuleExperimentalFeaturesActive'];
  isModuleMarketingSocialMediaActive = Settings.global['isModuleMarketingSocialMediaActive'];
  boostFeature = true;
  SessionType = 'merchant';

  @Input()
  hasSocialCampaign = true;
  currentUser;
  filterID = 'none';
  existingFilterArray = [];
  showFilterCreate = false;

  @ViewChild('tagInput', { static: false }) tagInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', { static: false }) matAutocomplete: MatAutocomplete;
  tags = [];
  staticAudiences = [];
  selectedTags = [];
  selectedStaticAudiences = [];
  tag;
  staticAudience;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  sessionType;

  constructor(
    private router: Router,
    private merchantService: MerchantService,
    private authenticationService: AuthenticationService,
    private marketingService: MarketingService,
    private utilService: UtilsService,
    private dentistService: DentistService,
    private activeRoute: ActivatedRoute,
    private tagsService: TagsService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data && data.campaignID) {
      this.campaignID = data.campaignID;
    }
  }

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((sessionType) => {
      this.sessionType = sessionType;

      this.authenticationService.getCurrentUser().subscribe((u) => {
        if (u && u.data) {
          this.currentUser = u.data;
        }

        this.utilService.getCurrentAccess().subscribe((access) => {
          if (access) {
            this.isModuleBoostReachActive = access['isModuleBoostReachActive'];
            this.isModuleExperimentalFeaturesActive = access['isModuleExperimentalFeaturesActive'];
            this.isModuleMarketingSocialMediaActive = access['isModuleMarketingSocialMediaActive'];
          }

          this.activeRoute.params.subscribe((params) => {
            this.getUtilService();

            if (params['templateID']) {
              this.selectedTemplateID = params['templateID'];
              this.isParams = true;

              this.utilService.getTemplateDetails(this.selectedTemplateID).subscribe((res) => {
                if (res && res['Tag']) {
                  this.utilService.previewMessageByTemplate(res['Tag']).subscribe((res) => {
                    if (res) {
                      this.content = res;
                    }
                  });
                }
              });
            }

            this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
              this.isPromoterOrAdmin = res;

              const __p = {
                isActive: true,
              };

              if (this.isPromoterOrAdmin == true) {
                __p['merchantID'] = this.merchantID;
              }

              this.marketingService.getFilterList(__p, this.isPromoterOrAdmin).subscribe((filter) => {
                if (filter && filter.length > 0) {
                  this.existingFilterArray = filter;
                }
              });

              this.tagsService
                .getTagsList(
                  {
                    fields: 'Label,Static_Audiance',
                    merchantID: this.merchantID,
                    orderBy: 'Label',
                  },
                  this.sessionType
                )
                .subscribe((res) => {
                  if (res && res.length > 0) {
                    this.tags = res.filter((item) => {
                      if (item && item['Static_Audiance'] !== '1') {
                        return true;
                      }
                      return false;
                    });

                    this.staticAudiences = res.filter((item) => {
                      if (item && item['Static_Audiance'] == '1') {
                        return true;
                      }
                      return false;
                    });
                  }
                });

              this.marketingService.getPatientStatistics(__p, this.isPromoterOrAdmin).subscribe((stats) => {
                if (stats) {
                  this.patientNumber = String(stats['prospects'] || 0);
                  this.customerNumber = String(stats['customers'] || 0);
                  this.allNumber = String(stats['all'] || 0);
                }

                if (this.isPromoterOrAdmin == true) {
                  const payload = {
                    fields: 'ID,TradingAs,CalculatedName,Status',
                  };
                  this.merchantService.getList(payload, this.isPromoterOrAdmin).subscribe((res) => {
                    this.merchants = res;
                    this.editModeSetup();
                  });

                  const _mID = JSON.parse(localStorage.getItem('selectedMerchant'));
                  if (_mID) {
                    this.merchantID = _mID;
                    // this.displayMerchantSelect = false;
                  }

                  HeaderService.getPromoterViewAsObject().subscribe((res) => {
                    if (res && res['promoterViewAs'] && res['promoterViewAs'] == 'promoter') {
                      this.merchantID = null;
                      this.displayMerchantSelect = true;
                    } else if (res && res['promoterViewAs'] && res['promoterViewAs'] == 'merchant') {
                      this.merchantID = res['merchantID'];
                      this.displayMerchantSelect = false;
                    }
                  });
                } else {
                  this.editModeSetup();
                }
              });
            });
          });
        });
      });
    });
  }

  chooseExistingFilter() {
    this.showFilterCreate = false;
    this.filterID = 'none';
  }

  createFilter() {
    this.showFilterCreate = true;

    this.filterID = 'none';
    // let ref = RootAppComponent.dialog.open(MarketingFilterCreateComponent,{
    //   data: {
    //     merchantID: this.merchantID,
    //   },
    //   width: '1150px',
    //   height: '1000px'
    // })

    // ref.componentInstance.getResult.subscribe(res => {
    //   if (res) {

    //     this.existingFilterArray.push(res);
    //     this.filterID = res['ID'];
    //     ref.close();
    //   }
    // })

    // ref.componentInstance.close.subscribe(res => {
    //   ref.close();
    // })
  }

  getUtilService() {
    this.utilService.getCurrentAccess().subscribe((access) => {
      if (access) {
        this.isModuleBoostReachActive = access['isModuleBoostReachActive'];
      }
    });
  }

  selectTemplateEvent(t) {
    if (t && t['Tag'] && t['ID']) {
      this.selectedTemplate = t;
      this.selectedTemplateID = t['ID'];

      if (this.selectedTemplate['Supports.SMS'] == '1') {
        this.isSendSMS = true;
        this.smsSupport = true;
      } else {
        this.isSendSMS = false;
        this.smsSupport = false;
      }

      if (this.selectedTemplate['Supports.Email'] == '1') {
        this.isSendEmail = true;
        this.emailSupport = true;
      } else {
        this.isSendEmail = false;
        this.emailSupport = false;
      }

      this.buildTemplate();

      this.utilService.previewMessageByTemplate(this.selectedTemplate['Tag']).subscribe((res) => {
        if (res) {
          this.content = res;
        }
      });
    }
  }

  selectProceed(t) {
    if (t && t['Tag'] && t['ID']) {
      if (t && t.MetaData && t.MetaData['Meta_Data)']) {
        t.MetaData = t.MetaData['Meta_Data)'];
      }

      this.selectedTemplate = t;
      this.selectedTemplateID = t['ID'];

      if (this.selectedTemplate['Supports.SMS'] == '1') {
        this.isSendSMS = true;
        this.smsSupport = true;
      } else {
        this.isSendSMS = false;
        this.smsSupport = false;
      }

      if (this.selectedTemplate['Supports.Email'] == '1') {
        this.isSendEmail = true;
        this.emailSupport = true;
      } else {
        this.isSendEmail = false;
        this.emailSupport = false;
      }

      this.wizard.goToNextStep();
    }
  }

  editModeSetup() {
    this.activeRoute.params.subscribe((params) => {
      if (params['campaignID']) {
        this.campaignID = params['campaignID'];
      }
      if (this.campaignID) {
        this.authenticationService.getSessionType().subscribe((SessionType) => {
          this.SessionType = SessionType;

          this.marketingService.getMarketingDetails(this.campaignID, {}, this.SessionType).subscribe((campaign) => {
            if (campaign) {
              this.utilService.getTemplateTag(campaign['Template.Tag']).subscribe((res) => {
                if (res) {
                  this.utilService.previewMessageByTemplate(res['Tag']).subscribe((res) => {
                    if (res) {
                      this.content = res;
                    }
                  });
                  this.loadTempalates = false;

                  this.wizard.goToNextStep();
                  this.selectedTemplate = res;
                  this.selectedTemplatePreviewID = res['ID'];
                  this.selectedTemplateID = res['ID'];

                  this.merchantID = campaign['Merchant_key'];
                  this.campaignName = campaign['Label'];
                  this.statusLabel = campaign['Status.Label'];
                  this.campaignDescription = campaign['Description'];
                  this.actionCallLabel = campaign['ActionCall.Label'];
                  this.actionCallDescription = campaign['ActionCall.Description'];

                  if (campaign['WhenToSend'] && campaign['WhenToSend'] != '0000-00-00') {
                    this.whenToSend = moment(campaign['WhenToSend'], 'YYYY-MM-DD').toDate();
                  }

                  if (campaign['UserData'] && campaign['UserData'][0]) {
                    this.userData = JSON.parse(JSON.stringify(campaign['UserData'][0]));
                  }

                  if (this.selectedTemplate['Supports.SMS'] == '1') {
                    this.isSendSMS = true;
                    this.smsSupport = true;
                  } else {
                    this.isSendSMS = false;
                    this.smsSupport = false;
                  }

                  if (this.selectedTemplate['Supports.Email'] == '1') {
                    this.isSendEmail = true;
                    this.emailSupport = true;
                  } else {
                    this.isSendEmail = false;
                    this.emailSupport = false;
                  }

                  if (this.selectedTemplate['Is_Public'] == '1') {
                    this.isPublicCampaign = true;
                  } else {
                    this.isPublicCampaign = false;
                  }

                  if (campaign['WhenToExpire']) {
                    this.hasExpirationDate = true;
                    this.defaultExpirationDate = moment(campaign['WhenToExpire'], 'YYYY-MM-DD HH:mm').toDate();
                  } else {
                    this.hasExpirationDate = false;
                    this.defaultExpirationDate = moment().add(1, 'months').toDate();
                  }

                  if (campaign['Send.SMS'] == '1') {
                    this.isSendSMS = true;
                    this.smsSupport = true;
                  } else {
                    this.isSendSMS = false;
                    this.smsSupport = false;
                  }

                  if (campaign['Send.Email'] == '1') {
                    this.isSendEmail = true;
                    this.emailSupport = true;
                  } else {
                    this.isSendEmail = false;
                    this.emailSupport = false;
                  }

                  if (campaign['Targets.RespectMarketingConsent'] == '1') {
                    this.respectMarketing = true;
                  } else {
                    this.respectMarketing = false;
                  }

                  this.buildTemplate();

                  this.editMode = true;

                  if (campaign['Status.Code'] != 'PEND') {
                    this.displayCampaignReview = true;

                    NotifyAppComponent.displayToast('warning', 'Sorry', 'You can only edit Pending marketing campaign');
                  }
                }
              });
            } else {
              this.loadTempalates = true;
              this.editMode = false;
            }
          });
        });
      } else if (this.selectedTemplateID) {
        this.utilService.getTemplateDetails(this.selectedTemplateID).subscribe((res) => {
          if (res) {
            this.loadTempalates = false;
            this.selectedTemplate = res;
            this.selectedTemplateID = res['ID'];

            this.selectedTemplatePreviewID = res['ID'];

            if (this.selectedTemplate['Supports.SMS'] == '1') {
            }

            if (this.selectedTemplate['Supports.SMS'] == '1') {
            }
            if (this.selectedTemplate['Supports.SMS'] == '1') {
              this.isSendSMS = true;
              this.smsSupport = true;
            } else {
              this.isSendSMS = false;
              this.smsSupport = false;
            }

            if (this.selectedTemplate['Supports.Email'] == '1') {
              this.isSendEmail = true;
              this.emailSupport = true;
            } else {
              this.isSendEmail = false;
              this.smsSupport = false;
            }
            this.buildTemplate();

            this.wizard.goToNextStep();
          }
        });
      } else {
        this.loadTempalates = true;
      }
    });
  }

  cons(t) {
    console.log(t);
  }

  removeTag(t: string, _static = false): void {
    if (_static == false) {
      const index = this.selectedTags.indexOf(t);

      if (index >= 0) {
        this.selectedTags.splice(index, 1);
      }
    } else {
      const index = this.selectedStaticAudiences.indexOf(t);

      if (index >= 0) {
        this.selectedStaticAudiences.splice(index, 1);
      }
    }
  }

  selected(event: MatAutocompleteSelectedEvent, _static = false): void {
    if (event && event.option && event.option.viewValue) {
      if (_static == false) {
        const v = event.option.viewValue;
        const index = this.selectedTags.indexOf(v);

        if (index == -1 && typeof v == 'string') {
          this.selectedTags.push(v);
        }
        this.tagInput.nativeElement.value = '';
        this.tag = null;
      } else {
        const v = event.option.viewValue;
        const index = this.selectedStaticAudiences.indexOf(v);

        if (index == -1 && typeof v == 'string') {
          this.selectedStaticAudiences.push(v);
        }
        this.tagInput.nativeElement.value = '';
        this.staticAudience = null;
      }
    }
  }

  changeMerchant() {
    const __p = {
      merchantID: this.merchantID,
    };

    this.marketingService.getPatientStatistics(__p, this.isPromoterOrAdmin).subscribe((stats) => {
      if (stats) {
        this.patientNumber = String(stats['prospects'] || 0);
        this.customerNumber = String(stats['customers'] || 0);
        this.allNumber = String(stats['all'] || 0);
      }

      this.contactID = null;
      this.buildTemplate();
    });
  }

  buildTemplate() {
    if (!this.actionCallLabel && this.selectedTemplate['ActionCall.Label']) {
      this.actionCallLabel = this.selectedTemplate['ActionCall.Label'];
    }
    if (!this.actionCallDescription && this.selectedTemplate['ActionCall.Description']) {
      this.actionCallDescription = this.selectedTemplate['ActionCall.Description'];
    }

    if (this.selectedTemplate['LandingPageCode'] == '' && this.selectedTemplate['LandingPageURL'] == '') {
      this.displayPracticePage = false;
      this.isPublicCampaign = false;
      this.displayCTA = false;
    } else {
      this.displayPracticePage = true;
      this.isPublicCampaign = true;
      this.displayCTA = true;
    }

    if (this.selectedTemplate && this.selectedTemplate['MetaData'] && this.selectedTemplate['MetaData']['Amount']) {
      if (this.isPromoterOrAdmin == true && this.merchantID) {
        const patload = {
          fields: 'ID,CalculatedName,ContactForCard_key',
          merchantID: this.merchantID,
        };

        this.dentistService.getFiltersContact(patload, this.isPromoterOrAdmin).subscribe((res) => {
          if (res) {
            this.contacts = res;
          }
        });
      } else {
        const patload = {
          fields: 'ID,CalculatedName,ContactForCard_key',
          merchantID: this.merchantID,
        };

        this.dentistService.getFiltersContact(patload, false).subscribe((res) => {
          if (res) {
            this.contacts = res;
          }
        });
      }
    }
  }

  chooseTemplate() {
    this.wizard.goToNextStep();
  }

  templateChoice() {
    this.loadTempalates = true;
    this.respectMarketing = false;
    this.respectReferredPatient = false;
    this.wizard.goToPreviousStep();
  }

  goBackStep() {
    this.wizard.goToPreviousStep();
  }

  viewMerchant() {
    const ref = RootAppComponent.dialog.open(MerchantViewComponent, {
      data: this.merchantID,
      width: '600px',
    });
    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  selectedExpirationDate(e) {
    this.exprirationDate = e;
  }

  selectDateObject(e) {
    this.selectedDateObject = e;
    this.dateTimeSelected = true;
  }

  changeDelay() {
    if (this.delayDate != true) {
      this.dateTimeSelected = false;
    }
  }

  selectDate(e) {
    this.selectedDate = e;
    this.dateTimeSelected = true;
  }

  sendNow() {
    this.showDateInput = false;
    this.selectedDate = new Date(moment().toDate().toISOString());
  }

  openDateInput() {
    this.showDateInput = true;
  }

  getFilterEvent(e) {
    if (e) {
      this.existingFilterArray.push(e);
      this.filterID = e['ID'];
    }
  }

  createCampaignPending() {
    // let _selectedDate;
    // if (this.selectedDate) {
    //   _selectedDate = this.utilMarketing.getTimeDifference(this.selectedDate, 'add');
    // }

    let _tags = [];

    if (this.filterID == 'allTags_Select') {
      _tags = this.selectedTags;
    } else if (this.filterID == 'allTags_Select_Static_Adience') {
      _tags = this.selectedStaticAudiences;
    }
    let payload: any = {};
    if (this.isPromoterOrAdmin == true) {
      payload = {
        templateID: this.selectedTemplate['ID'],
        merchantID: this.merchantID,
        label: this.campaignName,
        actionCallDescription: this.actionCallDescription,
        actionCallLabel: this.actionCallLabel,
        description: this.campaignDescription,
        template: this.selectedTemplate['Tag'],
        sendSms: this.isSendSMS,
        sendEmail: this.isSendEmail,
        date: this.selectedDate,
        respectMarketing: !this.respectMarketing,
        status: 'PEND',
        userData: this.userData,
        contactID: this.contactID,
        tags: _tags,
        isPublicCampaign: this.isPublicCampaign,
        sendNow: !this.delayDate,
      };
    } else {
      payload = {
        templateID: this.selectedTemplate['ID'],
        label: this.campaignName,
        actionCallDescription: this.actionCallDescription,
        actionCallLabel: this.actionCallLabel,
        description: this.campaignDescription,
        template: this.selectedTemplate['Tag'],
        sendSms: this.isSendSMS,
        sendEmail: this.isSendEmail,
        date: this.selectedDate,
        respectMarketing: !this.respectMarketing,
        status: 'PEND',
        userData: this.userData,
        contactID: this.contactID,
        tags: _tags,
        isPublicCampaign: this.isPublicCampaign,
        updateDate: true,
        sendNow: !this.delayDate,
      };
    }

    if (this.hasExpirationDate == true) {
      payload.expirationDate = this.exprirationDate;
    }

    if (
      this.filterID &&
      this.filterID != 'allTags_Select_Static_Adience' &&
      this.filterID != 'allTags_Select' &&
      this.filterID != 'none'
    ) {
      payload['filterID'] = this.filterID;
    }

    if (this.campaignID) {
      this.marketingService.editMarketing(this.campaignID, payload, this.isPromoterOrAdmin).subscribe((res) => {
        if (res) {
          this.wizard.goToNextStep();
          this.campaignID = res['ID'];
          this.createdCampaign = res;
          this.fillResultStats();
        }
      });
    } else {
      this.marketingService.createMarketing(payload, this.isPromoterOrAdmin).subscribe((res) => {
        if (res) {
          this.wizard.goToNextStep();
          this.campaignID = res['ID'];
          this.createdCampaign = res;
          this.fillResultStats();
        }
      });
    }
  }

  fillResultStats() {
    if (this.createdCampaign) {
      if (this.createdCampaign['Targets.Distinct']) {
        this.allTarget = Number(this.createdCampaign['Targets.Distinct']);
      }

      if (this.createdCampaign['Reached.Distinct']) {
        this.successedTarget = Number(this.createdCampaign['Reached.Distinct']);
      }

      if (this.createdCampaign['Targets.Distinct'] && this.createdCampaign['Reached.Distinct']) {
        this.failedTarget =
          Number(this.createdCampaign['Targets.Distinct']) - Number(this.createdCampaign['Reached.Distinct']);
      }

      if (this.createdCampaign['Reached.Email']) {
        this.allEmail = Number(this.createdCampaign['Reached.Email']);
      }

      if (this.createdCampaign['Sent.Email']) {
        this.successedEmail = Number(this.createdCampaign['Sent.Email']);
      }

      this.failedEmail = this.allEmail - this.successedEmail;

      if (this.createdCampaign['Reached.SMS']) {
        this.allSMS = Number(this.createdCampaign['Reached.SMS']);
      }

      if (this.createdCampaign['Sent.SMS']) {
        this.successedSMS = Number(this.createdCampaign['Sent.SMS']);
      }

      this.failedSMS = this.allSMS - this.successedSMS;
    }
  }

  confirmDateSelected() {
    if (this.dateTimeSelected == true || this.delayDate != true) {
      this.createCampaignPending();
    } else {
      let confirmDialog;
      if (this.smsSupport == true && this.emailSupport == true) {
        confirmDialog = new ConfirmDialog(
          'fas fa-info',
          'Continue Process?',
          'This campaign will be set on ' +
            this.selectedDateObject.format('DD/MM/YYYY') +
            ' at ' +
            this.selectedDateObject.format('hh:mm A') +
            ' to send to Email, and on ' +
            this.selectedDateObject.format('DD/MM/YYYY') +
            ' at ' +
            this.selectedDateObject.format('hh:mm A') +
            ' to send to Sms.' +
            '<br/>' +
            'Is this correct?',
          'No',
          'Yes'
        );
      } else if (this.smsSupport == true && this.emailSupport == false) {
        confirmDialog = new ConfirmDialog(
          'fas fa-info',
          'Continue Process?',
          'This campaign will be set on ' +
            this.selectedDateObject.format('DD/MM/YYYY') +
            ' at ' +
            this.selectedDateObject.format('hh:mm A') +
            ' to send to Sms.' +
            '<br/>' +
            'Is this correct?',
          'No',
          'Yes'
        );
      } else if (this.smsSupport == false && this.emailSupport == true) {
        confirmDialog = new ConfirmDialog(
          'fas fa-info',
          'Continue Process?',
          'This campaign will be set on ' +
            this.selectedDateObject.format('DD/MM/YYYY') +
            ' at ' +
            this.selectedDateObject.format('hh:mm A') +
            ' to send to Email.' +
            '<br/>' +
            'Is this correct?',
          'No',
          'Yes'
        );
      }

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirmDialog,
      });
      ref.componentInstance.onConfirm.subscribe((res) => {
        if (res == false) {
          ref.close();
        } else {
          ref.close();
          this.createCampaignPending();
        }
      });
    }
  }

  changeTemplate() {
    if (this.selectedTemplate && this.selectedTemplate['Tag']) {
      this.utilService.getTemplateTag(this.selectedTemplate['Tag']).subscribe((res) => {
        if (res) {
          this.selectedTemplate = res;
          this.selectedTemplateID = res['ID'];

          if (this.selectedTemplate['Supports.Email'] == '1') {
            this.emailSupport = true;
            this.isSendEmail = true;
          } else {
            this.emailSupport = false;
            this.isSendEmail = false;
          }

          if (this.selectedTemplate['Supports.SMS'] == '1') {
            this.smsSupport = true;
            this.isSendSMS = true;
          } else {
            this.smsSupport = false;
            this.isSendSMS = false;
          }
        }
      });
    }
  }

  edit() {
    if (this.campaignID) {
      let payload = {};

      payload = {
        status: 'ABRT',
      };

      this.marketingService.editMarketing(this.campaignID, payload, this.isPromoterOrAdmin).subscribe((res) => {
        if (res) {
          this.wizard.goToPreviousStep();
          this.campaignID = null;
          this.createdCampaign = null;
        }
      });
    } else {
      this.wizard.goToPreviousStep();
      this.campaignID = null;
      this.createdCampaign = null;
    }
  }

  completCompainEvent() {
    let _selectedDate;
    if (this.selectedDate) {
      _selectedDate = this.utilMarketing.getTimeDifference(this.selectedDate, 'add');
    }

    this.completCompaignButton = false;
    this.planeFly = true;
    setTimeout(() => {
      let payload: any = {};

      if (this.isPromoterOrAdmin == true) {
        payload = {
          merchantID: this.merchantID,
          label: this.campaignName,
          actionCallDescription: this.actionCallDescription,
          actionCallLabel: this.actionCallLabel,
          description: this.campaignDescription,
          template: this.selectedTemplate['Tag'],
          sendSms: this.isSendSMS,
          sendEmail: this.isSendEmail,
          date: this.selectedDate,
          respectMarketing: !this.respectMarketing,
          userData: this.userData,
          status: 'ACTV',

          sendNow: !this.delayDate,
          updateDate: true,
          isPublicCampaign: this.isPublicCampaign,
        };
        this.isSended = true;
      } else {
        payload = {
          label: this.campaignName,
          actionCallDescription: this.actionCallDescription,
          actionCallLabel: this.actionCallLabel,
          description: this.campaignDescription,
          template: this.selectedTemplate['Tag'],
          sendSms: this.isSendSMS,
          sendEmail: this.isSendEmail,
          date: this.selectedDate,
          respectMarketing: !this.respectMarketing,
          status: 'ACTV',

          updateDate: true,

          sendNow: !this.delayDate,

          isPublicCampaign: this.isPublicCampaign,
        };
      }
      if (this.hasExpirationDate == true) {
        payload.expirationDate = this.exprirationDate;
      }
      if (this.campaignID) {
        this.marketingService.editMarketing(this.campaignID, payload, this.isPromoterOrAdmin).subscribe((res) => {
          this.completCompaignButton = true;

          if (res) {
            this.isSent = true;
            this.wizard.goToNextStep();
            this.campaignID = res['ID'];
            this.createdCampaign = res;
          }
        });
      } else {
        this.marketingService.createMarketing(payload, this.isPromoterOrAdmin).subscribe((res) => {
          this.completCompaignButton = true;

          if (res) {
            this.isSent = true;
            this.wizard.goToNextStep();
            this.campaignID = res['ID'];
            this.createdCampaign = res;
          }
        });
      }
    }, 1800);
  }

  completCompaign() {
    if (this.createdCampaign['Send.SMS'] == '1' && Number(this.createdCampaign['Unsent.SMS']) > 0) {
      const confirm = new ConfirmDialog(
        'fas fa-info',
        'Are you sure you want to proceed?',
        `<p>You are about to send <strong> ${this.createdCampaign['Unsent.SMS']} SMS </strong> To your patients</p>
        <p>Your account will be charged extra fees based on that</p> `,
        'No',
        'Yes, Proceed'
      );
      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((res) => {
        if (res == false) {
          ref.close();
        } else {
          this.completCompainEvent();
          ref.close();
        }
      });
    } else {
      this.completCompainEvent();
    }
  }

  toNumber(n) {
    if (n) {
      return Number(n);
    } else {
      return 0;
    }
  }

  openCampaignReview() {
    this.displayCampaignReview = true;
  }

  cancelCampaignReview() {
    this.campaignID = null;
    this.displayCampaignReview = false;
  }

  viewCampaignModal() {
    const ref = RootAppComponent.dialog.open(MarketingCampaignViewComponent, {
      data: {
        campaignID: this.campaignID,
        templateID: this.selectedTemplateID,
      },
      width: '1200px',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  viewCampaign() {
    this.router.navigate(['/merchant', { outlets: { page: ['marketing-campaign-view', this.createdCampaign['ID']] } }]);
  }

  viewSocialMedia(campaignID) {
    if (campaignID) {
      const ref = RootAppComponent.dialog.open(SocialMediaGalleryListComponent, {
        data: {
          campaignID,
        },
        width: '1200px',
        panelClass: ['noCard', 'top-modal'],
      });

      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });

      ref.componentInstance.confirmShare.subscribe((res) => {
        ref.close();
      });
    }
  }

  openConfirmDialogPatients() {
    if (this.respectMarketing == false) {
      const confirm = new ConfirmDialog(
        'fas fa-info',
        'Are you sure?',
        'You are about to override your patients request to opt out of marketing messages',
        'No',
        'Yes, Proceed'
      );
      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((res) => {
        if (res == false) {
          this.respectMarketing = false;
          ref.close();
        } else {
          this.respectMarketing = true;
          ref.close();
        }
      });

      ref.backdropClick().subscribe((res) => {
        this.respectMarketing = false;
        ref.close();
      });

      ref.componentInstance.closeEvent.subscribe((res) => {
        this.respectMarketing = false;
        ref.close();
      });
    }
  }

  openConfirmDialogReferredPatients() {
    if (this.respectReferredPatient == false) {
      const confirm = new ConfirmDialog(
        'fas fa-info',
        'Are you sure?',
        'You are about to turn off the marketing messages sending to your referred patients',
        'No',
        'Yes, Proceed'
      );
      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((res) => {
        if (res == false) {
          this.respectReferredPatient = false;
          ref.close();
        } else {
          this.respectReferredPatient = true;
          ref.close();
        }
      });
    }
  }

  getUserData(e) {
    if (e) {
      this.userData = e;
    }
  }

  validateUserDataEvent(e) {
    this.validateUserData = e || false;
  }

  validateMataData(obj) {
    if (!obj) {
      return true;
    } else {
      return Object.keys(obj).length === 0 && obj.constructor === Object;
    }
  }

  testCampaign() {
    const title = 'Send Test Campaign';
    let description = `<p>To send a test Campaign, we will enable the marketing consent for you.<br><br>`;

    if (this.currentUser['emails.Email']) {
      description =
        description + ` The message will be sent to:  <strong>${this.currentUser['emails.Email']}</strong></p>`;
    } else {
      description = description + `</p>`;
    }

    const confirm = new ConfirmDialog('fas fa-info', title, description, 'No', 'Yes');

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirm,
      width: '650px',
    });

    ref.componentInstance.onConfirm.subscribe((res) => {
      if (res == false) {
        ref.close();
      } else {
        // Mark settlement status to ' Awaiting Merchant Response'

        this.marketingService.sendTemplate(this.createdCampaign.ID, {}).subscribe((res) => {
          if (res) {
            ref.close();
            NotifyAppComponent.displayToast(
              'success',
              'Successful Operation',
              'You have successfully sent a test campaign to yourself, please check your email.'
            );
          }
        });
      }
    });
  }

  backToTemplateGallery() {
    this.router.navigate([
      '/merchant',
      {
        outlets: { page: ['marketing-campaign-preview'] },
      },
    ]);
  }

  triggerNewSocialCampaign() {
    if (this.campaignID) {
      this.router.navigate(['/merchant', { outlets: { page: ['marketing-social-create', this.campaignID] } }]);
    } else {
      this.router.navigate(['/merchant', { outlets: { page: ['marketing-social-create-redirection'] } }]);
    }
  }

  openBoostDialog() {
    const ref = RootAppComponent.dialog.open(CustomerProspectBoostModalComponent, {
      data: {
        merchantID: this.merchantID,
      },
      width: '1250px',
      panelClass: 'noCard',
    });

    ref.componentInstance.closeModal.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }
}
