import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared/shared.module';
import { TranslationManagementRoutingModule } from './shared/services/translation-routing.module';
import { TranslationPage } from './translation-management-create/translation-management-create.component';

import { TranslationManagementExportComponent } from './translation-management-export/translation-management-export.component';

@NgModule({
  imports: [SharedModule, TranslationManagementRoutingModule],
  declarations: [TranslationPage, TranslationManagementExportComponent],
  exports: [TranslationPage, TranslationManagementExportComponent],
})
export class TranslationManagementModule {}
