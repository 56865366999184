import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { LookupService } from '../../../shared/services/lookup.service';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { PersonalCircumstancesService } from '../shared/personal-circumstances.service';

@Component({
  selector: 'app-pci-employment-benefit',
  templateUrl: './employment-benefit.component.html',
  styleUrls: ['./employment-benefit.component.css'],
})
export class PCIEmploymentBenefitComponent implements OnInit {
  @Input()
  personalCirID;

  @Input()
  pciEntryObj;

  @Input()
  title;

  @Input()
  disabled = false;

  @Output()
  close = new EventEmitter();

  @Output()
  getResult = new EventEmitter();

  isModal = false;

  pcDetail = {
    updatedReason: null,
    updatedComment: null,
    incomeValue: null,
    incomeFrequency: null,
    typeCode: null,
    startYear: null,
    startMonth: null,
    timeInRoleYears: null,
    timeInRoleMonths: null,
    nextPayDate: null,
    employmentTypeCode: null,
    employmentRole: null,
    employmentHours: null,
    employerName: null,
    employerContactName: null,
    isOnProbation: null,
    modifiedField: null,
    addressStreetSuburb: null,
    addressStreetState: null,
    addressStreetPostCode: null,
    addressStreetNumber: null,
    addressStreetName: null,
    addressUnit: null,
    addressStreetType: null,
    employerNumber: null,
    employerEmail: null,
    fileID: null,
    benefitType: null,

    tradingName: null,
    abn: null,
  };

  isPromoterOrAdmin = false;

  employmentStatus = [];
  employmentTypes = [];
  benefitTypes = [];

  persCircumUpdReasons = [];

  frequencies = [];
  fileID;

  isValidEmail = false;
  isValidPhone = false;
  isValidAddress = false;

  dateConfig = {
    format: 'MMM YYYY',
    monthFormat: 'MMM YYYY',
  };

  startDate;

  constructor(
    private authenticationService: AuthenticationService,
    private pciService: PersonalCircumstancesService,
    private lookupService: LookupService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.personalCirID != null) {
        this.personalCirID = data.personalCirID;
      }

      this.isModal = true;
    }
  }

  ngOnInit() {
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;
      if (this.isPromoterOrAdmin == true) {
        this.getDetails();

        this.lookup();
      }
    });
  }

  closeModalEvent() {
    this.close.emit(true);
  }

  lookup() {
    this.lookupService.getLookup('CodeLookup', 'PersCircumUpdReason').subscribe((res) => {
      if (res) {
        this.persCircumUpdReasons = res;
      }
    });

    this.lookupService.getLookup('CodeLookup', 'EmploymentStatus').subscribe((res) => {
      if (res) {
        this.employmentStatus = res;
      }
    });

    this.lookupService.getLookup('CodeLookup', 'Frequency').subscribe((res) => {
      if (res) {
        this.frequencies = res;
      }
    });

    this.lookupService.getLookup('CodeLookup', 'EmploymentType').subscribe((res) => {
      if (res) {
        this.employmentTypes = res;
      }
    });

    this.lookupService.getLookup('CodeLookup', 'BenefitType').subscribe((res) => {
      if (res) {
        this.benefitTypes = res;
      }
    });

    this.lookupService.getLookup('CodeLookup', 'StudentBenefit').subscribe((res) => {
      if (res) {
        this.benefitTypes = res;
      }
    });
  }

  submit() {
    this.pciService.editPCIEmploymentBenefit(this.pciEntryObj['ID'], this.pcDetail).subscribe((res) => {
      if (res) {
        NotifyAppComponent.displayToast('success', 'Modify record', 'You have successfully modified this record');

        this.pciEntryObj = res;

        this.getDetails();

        const modifiedField = this.pciEntryObj['Modified_Fields'].split('|');
        for (let i = 0; i < modifiedField.length; i++) {
          this.highlightField(modifiedField[i]);
        }

        this.getResult.emit(res);
      }
    });
  }

  create() {
    this.pcDetail['personalCircumstanceID'] = this.personalCirID;

    this.pciService.createPCIEmploymentBenefit(this.pcDetail).subscribe((res) => {
      if (res) {
        NotifyAppComponent.displayToast('success', 'Create record', 'You have successfully created a record');

        if (res['Info.Employment.Start.Year'] && res['Info.Employment.Start.Month']) {
          this.startDate = moment(`${res['Info.Employment.Start.Year']}-${res['Info.Employment.Start.Month']}`).format(
            'MMM YYYY'
          );
          this.datePicker(this.startDate);
        }

        this.getResult.emit(res);
      }
    });
  }

  getDetails() {
    if (this.pciEntryObj) {
      // this.pcDetail = this.pciEntryObj;

      if (this.pciEntryObj['Updating_Reason.Code']) {
        this.pcDetail.updatedReason = this.pciEntryObj['Updating_Reason.Code'];
      }

      if (this.pciEntryObj['Updating_Reason.Comment']) {
        this.pcDetail.updatedComment = this.pciEntryObj['Updating_Reason.Comment'];
      }

      if (this.pciEntryObj['TypeCode']) {
        this.pcDetail.typeCode = this.pciEntryObj['TypeCode'];
      }

      if (this.pciEntryObj['Income.Value']) {
        this.pcDetail.incomeValue = this.pciEntryObj['Income.Value'];
      }

      if (this.pciEntryObj['Income.Frequency']) {
        this.pcDetail.incomeFrequency = this.pciEntryObj['Income.Frequency'];
      }

      if (this.pciEntryObj['Info.Employment.Start.Year']) {
        this.pcDetail.startYear = this.pciEntryObj['Info.Employment.Start.Year'];
      }

      if (this.pciEntryObj['Info.Employment.Start.Month']) {
        this.pcDetail.startMonth = this.pciEntryObj['Info.Employment.Start.Month'];
      }

      if (this.pcDetail.startYear && this.pcDetail.startMonth) {
        this.startDate = moment(`${this.pcDetail.startYear}-${this.pcDetail.startMonth}`).format('MMM YYYY');
        this.datePicker(this.startDate);
      }

      if (this.pciEntryObj['Info.Employment.TimeInRole.Years']) {
        this.pcDetail.timeInRoleYears = this.pciEntryObj['Info.Employment.TimeInRole.Years'];
      }

      if (this.pciEntryObj['Info.Employment.TimeInRole.Months']) {
        this.pcDetail.timeInRoleMonths = this.pciEntryObj['Info.Employment.TimeInRole.Months'];
      }

      if (
        this.pciEntryObj['Info.Employment.NextPayDate'] &&
        this.pciEntryObj['Info.Employment.NextPayDate'] != '0000-00-00'
      ) {
        this.pcDetail.nextPayDate = this.pciEntryObj['Info.Employment.NextPayDate'];
      }

      if (this.pciEntryObj['Info.Employment.Type.Code']) {
        this.pcDetail.employmentTypeCode = this.pciEntryObj['Info.Employment.Type.Code'];
      }

      if (this.pciEntryObj['Info.Employment.Role']) {
        this.pcDetail.employmentRole = this.pciEntryObj['Info.Employment.Role'];
      }

      if (this.pciEntryObj['Info.Employment.Hours']) {
        this.pcDetail.employmentHours = this.pciEntryObj['Info.Employment.Hours'];
      }

      if (this.pciEntryObj['Info.Employment.Employer.Name']) {
        this.pcDetail.employerName = this.pciEntryObj['Info.Employment.Employer.Name'];
      }

      if (this.pciEntryObj['Info.Employment.Employer.ContactName']) {
        this.pcDetail.employerContactName = this.pciEntryObj['Info.Employment.Employer.ContactName'];
      }

      if (this.pciEntryObj['Info.Employment.OnProbation']) {
        this.pcDetail.isOnProbation = this.pciEntryObj['Info.Employment.OnProbation'];
      }

      if (this.pciEntryObj['Modified_Fields']) {
        this.pcDetail.modifiedField = this.pciEntryObj['Modified_Fields'];
      }

      if (this.pciEntryObj['Address.Suburb']) {
        this.pcDetail.addressStreetSuburb = this.pciEntryObj['Address.Suburb'];
      }

      if (this.pciEntryObj['Address.State']) {
        this.pcDetail.addressStreetState = this.pciEntryObj['Address.State'];
      }

      if (this.pciEntryObj['Address.Postcode']) {
        this.pcDetail.addressStreetPostCode = this.pciEntryObj['Address.Postcode'];
      }

      if (this.pciEntryObj['Address.StreetNr']) {
        this.pcDetail.addressStreetNumber = this.pciEntryObj['Address.StreetNr'];
      }

      if (this.pciEntryObj['Address.StreetName']) {
        this.pcDetail.addressStreetName = this.pciEntryObj['Address.StreetName'];
      }

      if (this.pciEntryObj['Address.Unit']) {
        this.pcDetail.addressUnit = this.pciEntryObj['Address.Unit'];
      }

      if (this.pciEntryObj['Address.StreetType.Abbreviated']) {
        this.pcDetail.addressStreetType = this.pciEntryObj['Address.StreetType.Abbreviated'];
      }

      if (this.pciEntryObj['Info.Employment.Employer.Number']) {
        this.pcDetail.employerNumber = this.pciEntryObj['Info.Employment.Employer.Number'].replace(/\s/g, '');
      }

      if (this.pciEntryObj['Info.Employment.Employer.Email']) {
        this.pcDetail.employerEmail = this.pciEntryObj['Info.Employment.Employer.Email'];
      }

      if (this.pciEntryObj['Info.Employment.TradingName']) {
        this.pcDetail.tradingName = this.pciEntryObj['Info.Employment.TradingName'];
      }

      if (this.pciEntryObj['Info.Employment.ABN']) {
        this.pcDetail.abn = this.pciEntryObj['Info.Employment.ABN'];
      }

      if (this.pciEntryObj['Info.Employment.BenefitType']) {
        this.pcDetail.benefitType = this.pciEntryObj['Info.Employment.BenefitType'];
      }
    }
  }

  getEmployerAddress(address) {
    if (address) {
      this.pcDetail.addressUnit = address.unitNumber;
      this.pcDetail.addressStreetNumber = address.streetNumber;
      this.pcDetail.addressStreetName = address.streetName;
      this.pcDetail.addressStreetType = address.streetType;
      this.pcDetail.addressStreetSuburb = address.suburb;
      this.pcDetail.addressStreetState = address.state;
      this.pcDetail.addressStreetPostCode = address.postCode;
    }
  }

  isEmployerAdressValidEvent(v) {
    this.isValidAddress = v;
  }

  getEmployerEmail(e) {
    this.pcDetail.employerEmail = e;
  }

  validateEmployerEmailEvent(v) {
    this.isValidEmail = v;
  }

  validateEmployerPhoneEvent(v) {
    this.isValidPhone = v;
  }

  getEmployerPhone(e) {
    this.pcDetail.employerNumber = e;
  }

  onCompleteAll(e) {
    if (e && e[0]) {
      this.pcDetail.fileID = e[0];
    }
  }

  removeFile(e) {
    if (e && e == this.pcDetail.fileID) {
      this.pcDetail.fileID = null;
    }
  }

  highlightField(field) {
    let result = '';

    if (this.isModal == false && this.pcDetail.modifiedField) {
      const _modifiedField = this.pcDetail.modifiedField.split('|');

      if (field == 'Address') {
        for (let i = 0; i < _modifiedField.length; i++) {
          if (_modifiedField[i].includes(field)) {
            result = 'highlight';
          }
        }
      } else {
        if (_modifiedField.indexOf(field) != -1) {
          result = 'highlight';
        }
      }
    }

    return result;
  }

  getDate(e) {
    this.pcDetail.nextPayDate = e;
  }

  calculateDurationYear() {}

  calculateDurationMonth() {}

  datePicker(e) {
    if (e) {
      const aa = e.split(' ');

      if (aa.length > 0 && aa.length == 2) {
        const startMonth = aa[0];
        const startYear = aa[1];

        let _startMonth;

        if (startMonth == 'Jan') {
          _startMonth = '1';
        } else if (startMonth == 'Feb') {
          _startMonth = '2';
        } else if (startMonth == 'Mar') {
          _startMonth = '3';
        } else if (startMonth == 'Apr') {
          _startMonth = '4';
        } else if (startMonth == 'May') {
          _startMonth = '5';
        } else if (startMonth == 'Jun') {
          _startMonth = '6';
        } else if (startMonth == 'Jul') {
          _startMonth = '7';
        } else if (startMonth == 'Aug') {
          _startMonth = '8';
        } else if (startMonth == 'Sep') {
          _startMonth = '9';
        } else if (startMonth == 'Oct') {
          _startMonth = '10';
        } else if (startMonth == 'Nov') {
          _startMonth = '11';
        } else if (startMonth == 'Dec') {
          _startMonth = '12';
        }

        const nowDate = new Date();

        const nowYear = nowDate.getFullYear();
        const nowMonth = nowDate.getMonth() + 1;

        const a = moment([Number(startYear), Number(_startMonth)]);
        const b = moment([nowYear, nowMonth]);

        this.pcDetail.timeInRoleYears = b.diff(a, 'years');
        this.pcDetail.timeInRoleMonths = b.diff(a, 'months');

        if (this.pcDetail.timeInRoleMonths >= 12) {
          this.pcDetail.timeInRoleMonths = this.pcDetail.timeInRoleMonths - 12 * this.pcDetail.timeInRoleYears;
        }

        this.pcDetail.startYear = startYear;
        this.pcDetail.startMonth = startMonth;
      }
    }
  }
}
