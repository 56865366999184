import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'merchant-health-funds-overview',
  templateUrl: './merchant-health-funds-overview.component.html',
  styleUrls: ['./merchant-health-funds-overview.component.css'],
})
export class MerchantHealthFundsOverviewComponent implements OnInit {
  tip: any;
  constructor() {}

  ngOnInit() {}

  createTip(tip) {
    this.tip = tip;
  }
}
