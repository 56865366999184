import { of as observableOf } from 'rxjs';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import 'rxjs/add/operator/map';
import { catchError, map } from 'rxjs/operators';
import { AuthenticationService } from '../../core/authentication/shared/authentication.service';

@Injectable()
export class AuthGuardCustomer implements CanActivate {
  customerID;
  merchantID;
  invitationID;
  medicalInvitationID;
  navigation = 'medical';
  page = 'customer';

  constructor(private authService: AuthenticationService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let r = route;
    while (r.firstChild) {
      r = r.firstChild;
    }

    const rParams = r.params;

    let url;
    this.navigation = 'medical';

    if (r && r['_routerState'] && r['_routerState'].url) {
      url = r['_routerState'].url;
    }

    if (rParams['navigation']) {
      this.navigation = rParams['navigation'];
    }
    if (rParams['merchantID']) {
      this.merchantID = rParams['merchantID'];
    }
    if (rParams['invitationID']) {
      this.invitationID = rParams['invitationID'];
    }
    if (rParams['medicalInvitationID']) {
      this.medicalInvitationID = rParams['medicalInvitationID'];
    }
    if (rParams['customerID']) {
      this.customerID = rParams['customerID'];
    }

    if (url && url.indexOf('/customer/') != -1 && url.indexOf(':medical/') != -1) {
      this.navigation = 'medical';
    }
    if (url && url.indexOf('/customer-app/') != -1) {
      this.navigation = 'customer';
    } else {
      this.navigation = 'medical';
    }

    return this.authService.getCurrentUser().pipe(
      map((user) => {
        if (user.success) {
          let role = null;

          if (user && user.data && user.data.Role) {
            role = user.data.Role;
          }

          if (user && user.data) {
            if (user.data.ID) {
              this.customerID = user.data.ID;
            }

            if (user.data.merchantID) {
              this.merchantID = user.data.merchantID;
            }

            if (user.data.invitationID) {
              this.invitationID = user.data.invitationID;
            }

            if (user.data.medicalInvitationID) {
              this.medicalInvitationID = user.data.medicalInvitationID;
            }

            if (user.data.navigation) {
              this.navigation = user.data.navigation;
            }
          }

          if (state.url.indexOf('/customer-login') !== -1) {
            if (role === 'merchant' || role === 'merchant-admin' || role === 'admin' || role === 'promoter') {
              this.router.navigate(['/merchant/']);
            } else if (role == 'customer' && user) {
              if (user && user.data) {
                if (user.data.ID) {
                  this.customerID = user.data.ID;
                }

                if (user.data.merchantID) {
                  this.merchantID = user.data.merchantID;
                }

                if (user.data.invitationID) {
                  this.invitationID = user.data.invitationID;
                }

                if (user.data.medicalInvitationID) {
                  this.medicalInvitationID = user.data.medicalInvitationID;
                }

                if (user.data.navigation) {
                  this.navigation = user.data.navigation;
                }

                this.redirectConsumer();
              }
            } else {
              return false;
            }
          } else {
            if (role == 'customer' || role == 'admin' || role == 'promoter') {
              return true;
            } else if (role == 'merchant' || role == 'merchant-admin') {
              this.router.navigate(['/merchant/']);
            } else {
              if (state && state.url && state.url.indexOf('/login') != -1) {
                return true;
              } else {
                if (url) {
                  const __url = url.replace(/\//g, '$');

                  this.router.navigate(['/login/' + __url]);
                } else {
                  this.router.navigate(['/login']);
                }
              }
            }
          }
        } else {
          this.redirectConsumerLogin();
        }
      }),
      catchError(() => {
        if (state.url.indexOf('/customer-login') !== -1) {
          return observableOf(true);
        } else {
          this.redirectConsumerLogin();

          return observableOf(false);
        }
      })
    );
  }

  redirectConsumerLogin() {
    if (this.customerID && !this.merchantID) {
      this.router.navigate(['/customer-login/' + this.navigation + '/patient/', this.customerID]);
    } else if (this.customerID && this.merchantID) {
      this.router.navigate(['/customer-login/' + this.navigation + '/', this.customerID, this.merchantID]);
    } else if (this.invitationID) {
      this.router.navigate(['/customer-login/' + this.navigation + '/invitation/', this.invitationID]);
    } else if (this.medicalInvitationID) {
      this.router.navigate(['/customer-login/' + this.navigation + '/medical-invitation/', this.medicalInvitationID]);
    } else {
      this.router.navigate(['/login']);
    }
  }

  redirectConsumer() {
    if (this.customerID && !this.merchantID) {
      const outlet = {};
      outlet['page'] = [this.navigation + '/patient', this.customerID, this.merchantID];

      this.router.navigate(['/' + this.page, { outlets: outlet }]);
    } else if (this.customerID && this.merchantID) {
      const outlet = {};
      outlet['page'] = [this.navigation, this.customerID, this.merchantID];

      this.router.navigate(['/' + this.page, { outlets: outlet }]);
    } else if (this.invitationID) {
      const outlet = {};
      outlet['page'] = [this.navigation + '/invitation', this.invitationID];

      this.router.navigate(['/' + this.page, { outlets: outlet }]);
    } else if (this.medicalInvitationID) {
      const outlet = {};
      outlet['page'] = [this.navigation + '/medical-invitation', this.medicalInvitationID];

      this.router.navigate(['/' + this.page, { outlets: outlet }]);
    } else {
      const outlet = {};
      outlet['page'] = [this.navigation + '/patient', this.customerID, this.merchantID];

      this.router.navigate(['/' + this.page, { outlets: outlet }]);
    }
  }
}
