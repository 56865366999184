import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { AuthenticationService } from '../../authentication/shared/authentication.service';
import { WikiService } from '../shared/wiki.service';

@Component({
  selector: 'app-wiki-summary',
  templateUrl: './wiki-summary.component.html',
  styleUrls: ['./wiki-summary.component.css'],
})
export class WikiSummaryComponent implements OnInit {
  popularPages = [];
  @Input()
  sessionTypeParams;

  utils = new UtilsClass();
  tags = [];
  sessionType;

  constructor(
    public authenticationService: AuthenticationService,
    private router: Router,
    private wikiService: WikiService,
    private activeRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    const _res = this.utils.getSessionTypeParams(this.router.url);

    if (_res) {
      this.sessionTypeParams = _res;
    }

    this.authenticationService.getSessionType().subscribe((res) => {
      if (res && this.sessionTypeParams && (res == 'admin' || res == 'promoter')) {
        this.sessionType = this.sessionTypeParams;
      } else if (res) {
        this.sessionType = res;
        this.sessionTypeParams = null;
      }

      const params2 = {
        limit: 5,
        fields: '_id,title,views',
        orderBy: '-views',
        isActive: true,
      };

      this.wikiService.getWikiList(params2, this.sessionType).subscribe((res) => {
        if (res) {
          this.popularPages = res;
        }
      });

      this.wikiService.getTags(this.sessionType).subscribe((res) => {
        if (res) {
          this.tags = res;
        }
      });
    });
  }

  moveToPage(id) {
    if (id) {
      if (this.sessionTypeParams) {
        this.router.navigate([
          '/wiki-' + this.sessionTypeParams,
          {
            outlets: {
              wikiPageRoute: ['view', id],
            },
          },
        ]);
      } else {
        this.router.navigate([
          '/wiki',
          {
            outlets: {
              wikiPageRoute: ['view', id],
            },
          },
        ]);
      }
    }
  }

  searchEvent(t) {
    if (t) {
      if (this.sessionTypeParams) {
        this.router.navigate([
          '/wiki-' + this.sessionTypeParams,
          {
            outlets: {
              wikiPageRoute: ['tag', t],
            },
          },
        ]);
      } else {
        this.router.navigate([
          '/wiki',
          {
            outlets: {
              wikiPageRoute: ['tag', t],
            },
          },
        ]);
      }
    }
  }
}
