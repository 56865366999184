<div [hidden]="listDB.data.length == 0">
  <div>
    <h1 class="dataHeader">
      Invitations
      <span class="badge" [endVal]="dataSource.filteredData.length" countUp></span>
    </h1>
    <mat-card class="list-card">
      <!-- Search -->
      <div class="row clearfix mb">
        <div class="row clearfix">
          <div class="half-width" id="search">
            <mat-icon class="schIcon">search</mat-icon>
            <mat-select class="schSel" [formControl]="searchVals" matInput placeholder="Add Filters" multiple>
              <mat-select-trigger>
                {{ searchVals.value ? searchVals.value[0] : '' }}
                <span class="additional-selection" *ngIf="searchVals.value?.length > 1">
                  (+{{ searchVals.value.length - 1 }} others)
                </span>
              </mat-select-trigger>
              <mat-option *ngFor="let searchVal of searchValList" [value]="searchVal">{{ searchVal }}</mat-option>
            </mat-select>
          </div>
        </div>
        <div class="row clearfix flex well miniWell animated fadeInDown" *ngIf="searchVals.value?.length > 0">
          <mat-form-field class="margin-btm" *ngIf="searchVals.value?.indexOf('Email') !== -1">
            <mat-label>Email</mat-label>
            <input class="clearfix" (input)="setFilter($event.target.value, 'Email')" name="Email" placeholder="Email"
                   matInput/>
          </mat-form-field>

          <mat-form-field class="margin-btm" *ngIf="searchVals.value?.indexOf('Amount') !== -1">
            <mat-label>Amount</mat-label>
            <input class="clearfix" (input)="setFilter($event.target.value, 'treatment.Value')" name="Amount"
                   placeholder="Amount" CurrenyMask matInput/>
          </mat-form-field>
        </div>
      </div>
      <!-- data table -->
      <mat-table class="list-table" #table [dataSource]="dataSource" matSort>
        <!--- Note that these columns can be defined in any order.
                    The actual rendered columns are set as a property on the row definition" -->
        <!-- ID Column -->
        <ng-container cdkColumnDef="Date">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Date</mat-header-cell>
          <mat-cell *cdkCellDef="let row" matTooltip="{{ row['DateTimeCreated'] | customDateTime }}">
            {{ row['DateTimeCreated'] | customDateTime }}
          </mat-cell>
        </ng-container>
        <!-- Progress Column -->
        <ng-container cdkColumnDef="Operator">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Operator</mat-header-cell>
          <mat-cell *cdkCellDef="let row" matTooltip="{{ row.DentistContactName }}">
            {{ row.DentistContactName }}</mat-cell>
        </ng-container>
        <!-- Name Column -->
        <ng-container cdkColumnDef="Customer">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header>
            Customer
          </mat-header-cell>
          <mat-cell *cdkCellDef="let row"
                    matTooltip="{{ row.FirstName | lowercase | ucfirst }} {{ row.LastName | lowercase | ucfirst }}">
            {{ row.FirstName | lowercase | ucfirst }} {{ row.LastName | lowercase | ucfirst }}
          </mat-cell>
        </ng-container>
        <ng-container cdkColumnDef="Email">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header>
            Email
          </mat-header-cell>
          <mat-cell *cdkCellDef="let row" matTooltip="{{ row.Email }}"> {{ row.Email }} </mat-cell>
        </ng-container>
        <!-- Color Column -->
        <ng-container cdkColumnDef="Treatment">
          <mat-header-cell *cdkHeaderCellDef
                           mat-sort-header> {{ "KEYWORD.treatment" | translate | titlecase }} </mat-header-cell>
          <mat-cell *cdkCellDef="let row" matTooltip="{{ row['Service.Type'] }}"> {{ row['Service.Type'] }} </mat-cell>
        </ng-container>
        <!-- Color Column -->
        <ng-container cdkColumnDef="Amount">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Amount</mat-header-cell>
          <mat-cell *cdkCellDef="let row" matTooltip="{{ row['treatment.Value'] | customCurrency }}">
            {{ row['treatment.Value'] | customCurrency }}
          </mat-cell>
        </ng-container>
        <!-- Color Column -->
        <ng-container cdkColumnDef="Product">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Product</mat-header-cell>
          <mat-cell *cdkCellDef="let row" matTooltip="{{ row['Product.Label.Marketing'] }}">
            {{ row['Product.Label.Marketing'] }}
          </mat-cell>
        </ng-container>
        <ng-container cdkColumnDef="ProductGroup">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header>Product Group</mat-header-cell>
          <mat-cell *cdkCellDef="let row" matTooltip="{{ row['Product.ThisGroupOnly.Label'] }}">
            {{ row['Product.ThisGroupOnly.Code'] }}
          </mat-cell>
        </ng-container>
        <!-- Color Column -->
        <ng-container cdkColumnDef="Source">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Source</mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <span *ngIf="row['PatientChoice.Code'] == 'COLD'" matTooltip="Web"><i class="fas fa-globe"></i></span>
            <span *ngIf="row['PatientChoice.Code'] != 'COLD'" matTooltip="Invitation"><i
              class="fas fa-envelope"></i></span>
          </mat-cell>
        </ng-container>
        <!-- Email Status Column -->
        <ng-container cdkColumnDef="EmailStatus">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Email status </mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <span class="list-label" [style.background]="row['Status.Email.Colour'] || '#b4bcc1'"
                  matTooltip="{{ row['Status.Email.Short_NE'] }}">{{ row['Status.Email.Short_NE'] }}
            </span>
          </mat-cell>
        </ng-container>
        <!-- Invitation Status Column -->
        <ng-container cdkColumnDef="InvitationStatus">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Invitation status </mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <span class="list-label" [style.background]="row['Status.Invitation.Colour'] || '#b4bcc1'"
                  matTooltip="{{ row['Status.Invitation.Short_NE'] }}">{{ row['Status.Invitation.Short_NE'] }}
            </span>
          </mat-cell>
        </ng-container>
        <ng-container cdkColumnDef="ApplicationStatus">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Application status </mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <span class="list-label" [style.background]="row['Status.Application.Colour'] || '#b4bcc1'"
                  matTooltip="{{ row['Status.Application.Short_NE'] }}">{{ row['Status.Application.Short_NE'] }}
            </span>
          </mat-cell>
        </ng-container>
        <ng-container cdkColumnDef="ContractStatus">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Contract Status </mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <span class="list-label" [style.background]="row['Status.Contract.Colour'] || '#b4bcc1'"
                  matTooltip="{{ row['Status.Contract.Short_NE'] }}">{{ row['Status.Contract.Short_NE'] }}</span>
          </mat-cell>
        </ng-container>
        <ng-container cdkColumnDef="timeElapsed">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Time Elapsed </mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <span class="list-label" [style.background]="utils.daysPastColor(row['DateTimeCreated'])"
                  matTooltip="{{ row['DateTimeCreated'] | customDateTime }}">
              {{ row['DateTimeCreated'] | TimeLabelPast }} ago
            </span>
          </mat-cell>
        </ng-container>
        <ng-container cdkColumnDef="timeInvited">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Invited</mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <span class="list-label" [style.background]="utils.daysPastColor(row['DateTimeCreated'])"
                  matTooltip="{{ row['DateTimeCreated'] | customDateTime }}">
              {{ row['DateTimeCreated'] | TimeLabelPast }} ago
            </span>
          </mat-cell>
        </ng-container>
        <ng-container cdkColumnDef="timeResponded">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Responded</mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <span class="list-label" [style.background]="utils.daysPastColor(row['Date.Responded'])"
                  matTooltip="{{ row['Date.Responded'] }}">
              {{ row['Date.Responded'] | TimeLabelPast }} ago
            </span>
          </mat-cell>
        </ng-container>
        <!-- Response Column -->
        <ng-container cdkColumnDef="Response">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Response</mat-header-cell>
          <mat-cell *cdkCellDef="let row" matTooltip="{{ row['PatientDeclineReasons.Labels'] }}">
            {{ row['PatientChoice.Label_LongThirdPerson'] }}
          </mat-cell>
        </ng-container>
        <!-- Action Button Column -->
        <!-- <ng-container cdkColumnDef="Actions">
              <mat-header-cell *cdkHeaderCellDef mat-sort-header> {{"Actions" | translate}} </mat-header-cell>
              <mat-cell *cdkCellDef="let row">
                <div style="float : right;">
                  <button mat-icon-button [matMenuTriggerFor]="menu">
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="openInviteViewDialog(row['ID'])">
                      <mat-icon>search</mat-icon>
                      <span>{{"QuickView" | translate}}</span>
                    </button>
                    <button mat-menu-item (click)="openInviteViewDetails(row['ID'])">
                      <mat-icon>folder_shared</mat-icon>
                      <span>{{"ViewDetailsAudit" | translate}}</span>
                    </button>
                    <button *ngIf='(row["Status.Email.Short_NE"] != "N/A" &&  row["Voided"]=="0"  &&  row["Status.Application.Short_NE"] == "N/A" ) ||  row["Voided"]=="1"'
                      mat-menu-item (click)="resendInvitation(row['ID'])">
                      <mat-icon>refresh</mat-icon>
                      <span>Resend Invitation</span>
                    </button>
                    <button *ngIf="row['ExistingPatientID'] && row['ExistingPatientID']!='00000000000000000000000000000000'&& row['ExistingPatientID']!='20202020202020202020202020202020'"
                      mat-menu-item (click)="viewCustomer(row['ExistingPatientID'])">
                      <mat-icon>portrait</mat-icon>
                      <span>{{"PatientProfile" | translate}}</span>
                    </button>
                    <button *ngIf="row['DentistContact_key'] && row['DentistContact_key']!='00000000000000000000000000000000'"
                      mat-menu-item (click)="viewOperator(row['DentistContact_key'])">
                      <mat-icon>portrait</mat-icon>
                      <span>{{"DentistProfile" | translate}}</span>
                    </button>
                    <button mat-menu-item *ngIf="row['contractID']" (click)="openQuickViewDialog(row['contractID'])">
                      <mat-icon>payment</mat-icon>
                      <span>{{"View Contract" | translate}}</span>
                    </button>
                    <button *ngIf="row['Email']" mat-menu-item (click)="contact(row['ID'])">
                      <mat-icon>contact_mail</mat-icon>
                      <span>{{"Contact" | translate}} {{row.FirstName | lowercase| ucfirst }} {{row.LastName| lowercase |
                        ucfirst }}</span>
                    </button>


                    <div [hidden]=" row['Date.Commenced'] != '0000-00-00'|| row['Voided']!='0'">
                      <button mat-menu-item [matMenuTriggerFor]="submenu">
                        <mat-icon>help_outline</mat-icon> Set Status
                      </button>
                      <mat-menu #submenu="matMenu">
                        <button mat-menu-item (click)="payCashInvitation(row)" *ngIf="context!='retail' && row['Status.Invitation.Short_NE'] != 'Not viewed' && row['Status.Invitation.Short_NE'] != 'Submitted' && row['Status.Invitation.Short_NE'] != 'Proceeded' && row['Status.Invitation.Short_NE'] != 'Pay Cash/Card' && row['Status.Invitation.Short_NE'] != 'Expired' && row['Status.Invitation.Short_NE'] != 'Not Proceeding' && row['Status.Invitation.Short_NE'] != 'Intends to Proceed' && row['Status.Invitation.Short_NE'] != 'Via Web'">
                          <mat-icon>thumb_up</mat-icon> Proceeding with Cash
                        </button>
                        <button mat-menu-item (click)="deferInvitation(row)" *ngIf="context!='retail' && row['Status.Invitation.Short_NE'] != 'Not viewed' && row['Status.Invitation.Short_NE'] != 'Submitted' && row['Status.Invitation.Short_NE'] != 'Proceeded' && row['Status.Invitation.Short_NE'] != 'Pay Cash/Card' && row['Status.Invitation.Short_NE'] != 'Expired' && row['Status.Invitation.Short_NE'] != 'Not Proceeding' && row['Status.Invitation.Short_NE'] != 'Intends to Proceed' && row['Status.Invitation.Short_NE'] != 'Via Web'">
                          <mat-icon>alarm</mat-icon> Deferring
                        </button>
                        <button mat-menu-item (click)="changesToInvitation(row)" *ngIf="context!='retail' && row['Status.Invitation.Short_NE'] != 'Not viewed' && row['Status.Invitation.Short_NE'] != 'Submitted' && row['Status.Invitation.Short_NE'] != 'Proceeded' && row['Status.Invitation.Short_NE'] != 'Pay Cash/Card' && row['Status.Invitation.Short_NE'] != 'Expired' && row['Status.Invitation.Short_NE'] != 'Not Proceeding' && row['Status.Invitation.Short_NE'] != 'Intends to Proceed' && row['Status.Invitation.Short_NE'] != 'Via Web'">
                          <mat-icon>edit</mat-icon> Requires Changes
                        </button>
                        <button mat-menu-item (click)="notProceedingInvitation(row)" *ngIf="context!='retail' && row['Status.Invitation.Short_NE'] != 'Not viewed' && row['Status.Invitation.Short_NE'] != 'Submitted' && row['Status.Invitation.Short_NE'] != 'Proceeded' && row['Status.Invitation.Short_NE'] != 'Pay Cash/Card' && row['Status.Invitation.Short_NE'] != 'Expired' && row['Status.Invitation.Short_NE'] != 'Not Proceeding' && row['Status.Invitation.Short_NE'] != 'Intends to Proceed' && row['Status.Invitation.Short_NE'] != 'Via Web'">
                          <mat-icon>report_problem</mat-icon> Not Proceeding
                        </button>
                        <mat-divider></mat-divider>
                        <button mat-menu-item *ngIf="row['Voided']=='0'&&  row['Status.Invitation.Short_NE']!='Submitted' &&  row['Status.Application.Short_NE']?.indexOf('Contract -')==-1 &&  row['Status.Invitation.Short_NE']!='Proceeded'"
                          (click)="deleteInvitation(row)">
                          <mat-icon class="warn-color">delete</mat-icon>
                          <span class="warn-color">{{"Archive" | translate}}</span>
                        </button>
                      </mat-menu>
                    </div>

                    <div [hidden]="row['Date.Commenced'] == '0000-00-00'||  row['Voided']!='0' ||  row['Status.Invitation.Short_NE']=='Submitted' || row['Status.Application.Short_NE']?.indexOf('Contract -')==-1  ||  row['Status.Invitation.Short_NE']=='Proceeded' ">
                      <button mat-menu-item (click)="deleteInvitation(row)">
                        <mat-icon class="warn-color">delete</mat-icon>
                        <span class="warn-color">{{"Archive" | translate}}</span>
                      </button>
                    </div>
                  </mat-menu>
                </div>
              </mat-cell>
            </ng-container> -->
        <mat-header-row *cdkHeaderRowDef="displayedColumnsInv"></mat-header-row>
        <mat-row *cdkRowDef="let row; columns: displayedColumnsInv"></mat-row>
      </mat-table>
      <mat-paginator #paginator [length]="dataSource.filteredData.length" [pageIndex]="0" [pageSize]="pageSize"
                     [pageSizeOptions]="pageSizeOptions">
      </mat-paginator>
    </mat-card>
  </div>
</div>
<div class="clearfix full-width" *ngIf="listDB.data.length == 0 && listDB.firstLoadEvent != false">
  <app-empty-list-message></app-empty-list-message>
</div>
