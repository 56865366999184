<div class="rel text-center">
  <mat-card class="wizardModal">
    <div class="card-header primary-gradient-img clearfix inModal">
      <div class="row clearfix">
        <!-- merchant calculated name -->
        <div class="row text-left clearfix">
          <!-- Modal dismiss button -->
          <button class="btn-close btn-clear mat-button white" (click)="close()">
            <mat-icon>clear</mat-icon>
          </button>
          <h2 class="summary-header rm-m">
            HICAPS payments
            <span class="subLabel">Record a payment and rebate the claim</span>
          </h2>
        </div>
      </div>
    </div>
    <mat-dialog-content>
      <aw-wizard class="settlementCreate" navBarLayout="large-filled-symbols">
        <aw-wizard-step [navigationSymbol]="{ symbol: 1 }">
          <img
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/smile_right/settlement-header-2.png"
            mat-card-image/>
          <div class="row clearfix text-center">
            <p class="lead rm-mt">
              So you have processed a HICAP's claim for your {{ "KEYWORD.patient" | translate }} but found out they have
              financed with
              {{ "BRANDING.Brand Name" | translate | titlecase }} and now you want to know how to deal with the payment.
            </p>
            <p class="larger">
              Simple, we've made it easy as to record the payment, rebate the claim amount off future settlements and
              reflect this in the {{ "KEYWORD.patient" | translate }}'s loan in a straightforward process. Click 'read
              more' below to see how...
            </p>
            <div class="control-buttons row clearfix mb">
              <button class="pull-left" mat-mini-fab color="primary" disabled>
                <mat-icon>keyboard_arrow_left</mat-icon>
              </button>
              <div class="pull-right">
                <h3 class="sr-title readMore">Read more...</h3>
                <button class="pull-right" mat-mini-fab color="primary" awNextStep>
                  <mat-icon>chevron_right</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </aw-wizard-step>

        <aw-wizard-step [navigationSymbol]="{ symbol: 2 }">
          <div class="row clearfix text-center">
            <img class="half-width mb"
                 src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/smile_right/settlement-hicaps-1.png"/>
          </div>
          <div class="row clearfix text-center">
            <p class="lead rm-mt">
              <strong>1.</strong> Swipe your {{ "KEYWORD.patient" | translate }}'s card as you normally would
            </p>

            <p class="larger">
              Once you've finished your {{ "KEYWORD.patient" | translate }}'s {{"KEYWORD.treatment" | translate}},
              swipe their healthcare card
              into your HICAPS terminal as you normally would when processing a payment. Ensure the transaction is
              successful and note the amount that you have been paid.
            </p>
            <div class="control-buttons row clearfix mb">
              <button class="pull-left" mat-mini-fab color="primary" awPreviousStep>
                <mat-icon>chevron_left</mat-icon>
              </button>
              <div class="pull-right">
                <h3 class="sr-title readMore">Learn more...</h3>
                <button class="pull-right" mat-mini-fab color="primary" awNextStep>
                  <mat-icon>chevron_right</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </aw-wizard-step>

        <aw-wizard-step [navigationSymbol]="{ symbol: 3 }">
          <img
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/smile_right/settlement-hicaps-2.jpg"
            mat-card-image/>
          <div class="row clearfix text-center">
            <p class="lead rm-mt"><strong>2.</strong> Enter the value and details of the claim</p>
            <p class="larger">
              When next performing a settlement request for the patient, under deductions section select health fund
              claim and enter the value as shown in your HICAPS terminal or receipt.
            </p>
            <div class="control-buttons row clearfix mb">
              <button class="pull-left" mat-mini-fab color="primary" awPreviousStep>
                <mat-icon>chevron_left</mat-icon>
              </button>
              <div class="pull-right">
                <h3 class="sr-title readMore">Learn more...</h3>
                <button class="pull-right" mat-mini-fab color="primary" awNextStep>
                  <mat-icon>chevron_right</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </aw-wizard-step>

        <aw-wizard-step [navigationSymbol]="{ symbol: 4 }">
          <img
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/smile_right/settlement-hicaps-3.jpg"
            mat-card-image/>
          <div class="row clearfix text-center">
            <p class="lead rm-mt"><strong>3.</strong> Continue with your settlement request</p>

            <p class="larger">
              Once your {{ "KEYWORD.patient" | translate }} has approved the settlement
              request, {{ "BRANDING.Brand Name" | translate | titlecase }} will
              automatically apply the HICAPS claim amount to the {{ "KEYWORD.patient" | translate }}'s loan with
              {{ "BRANDING.Brand Name" | translate | titlecase }}, thus reducing the amount they have to pay and also
              reducing the
              amount you can claim from future settlements.
            </p>
            <hr class="mb"/>
            <div class="control-buttons row clearfix mb">
              <button class="pull-left" mat-mini-fab color="primary" awPreviousStep>
                <mat-icon>chevron_left</mat-icon>
              </button>
            </div>
          </div>
        </aw-wizard-step>
      </aw-wizard>
    </mat-dialog-content>

    <div class="row clearfix">
      <hr/>
      <mat-checkbox class="pull-left" [(ngModel)]="showOnHealthfund">Dont Show Again</mat-checkbox>
      <button class="pull-right" (click)="doNotShow()" mat-raised-button color="accent">
        Got It! &nbsp;
        <mat-icon class="fas fa-hand-point-right"></mat-icon>
      </button>
    </div>
  </mat-card>
  <!-- <button mat-raised-button color="primary" class="gradient green btn-large" *ngIf="isModuleTutorialsActive == true">See the tutorial!</button> -->
</div>
