import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-merchant-list-not-active',
  templateUrl: './merchant-list-not-active.component.html',
  styleUrls: ['./merchant-list-not-active.component.css'],
})
export class MerchantListNotActiveComponent implements OnInit {
  status = 'Archived';

  constructor() {}

  ngOnInit() {}
}
