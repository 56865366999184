import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';

@Component({
  selector: 'app-subscription-plan-updated',
  templateUrl: './subscription-plan-updated.component.html',
  styleUrls: ['./subscription-plan-updated.component.css'],
})
export class SubscriptionPlanUpdatedComponent implements OnInit {
  close = new EventEmitter();

  constructor(private router: Router, @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {}

  closeEvent() {
    this.close.emit(true);
  }

  redirect() {
    this.router.navigate([
      '/merchant',
      {
        outlets: {
          page: ['subscription-plan-view-current', 'current'],
        },
      },
    ]);

    this.closeEvent();
  }
}
