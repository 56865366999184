import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CdkTableModule } from '@angular/cdk/table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSortModule } from '@angular/material';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ArchwizardModule } from 'angular-archwizard';
import { EmailEditorModule } from 'angular-email-editor';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgxPaginationModule } from 'ngx-pagination';
import { PinchZoomModule } from 'ngx-pinch-zoom';
import { NgPipesModule } from 'ngx-pipes';
import { MerchantModule } from '../../feature/merchant/merchant.module';
import { SharedModule } from '../../shared/shared.module';
import { AuthenticationModule } from '../authentication/authentication.module';
import { ModuleModule } from '../module/module.module';
import { ModuleRoutingModule } from '../module/shared/module-routing.module';
import { SettingsRoutingModule } from '../settings/shared/settings-routing.module';
import { FeatureMainLabelCoverViewComponent } from './feature-main-label-cover-view/feature-main-label-cover-view.component';
import { FeatureMainLabelCreateEditComponent } from './feature-main-label-create-edit/feature-main-label-create-edit.component';
import { FeatureMainLabelImageViewComponent } from './feature-main-label-image-view/feature-main-label-image-view.component';
import { FeatureMainLabelListComponent } from './feature-main-label-list/feature-main-label-list.component';
import { FeatureMainLabelQuickEditComponent } from './feature-main-label-quick-edit/feature-main-label-quick-edit.component';
import { FeatureSubLabelCoverViewComponent } from './feature-sub-label-cover-view/feature-sub-label-cover-view.component';
import { FeatureSubLabelCreateEditComponent } from './feature-sub-label-create-edit/feature-sub-label-create-edit.component';
import { FeatureSubLabelCreateRedirectComponent } from './feature-sub-label-create-redirect/feature-sub-label-create-redirect.component';
import { FeatureSubLabelListComponent } from './feature-sub-label-list/feature-sub-label-list.component';
import { FeatureSubLabelQuickEditComponent } from './feature-sub-label-quick-edit/feature-sub-label-quick-edit.component';
import { FeatureSubLabelViewComponent } from './feature-sub-label-view/feature-sub-label-view.component';
import { SubscriptionPlanRoutingModule } from './shared/subscription-plan-routing.module';
import { SubscriptionPlanService } from './shared/subscription-plan.service';
import { SubscriptionPlanCalculatorComponent } from './subscription-plan-calculator/subscription-plan-calculator.component';
import { SubscriptionPlanCardListGlobalComponent } from './subscription-plan-card-list-global/subscription-plan-card-list-global.component';
import { SubscriptionPlanCardListComponent } from './subscription-plan-card-list/subscription-plan-card-list.component';
import { SubscriptionPlanCongratulationComponent } from './subscription-plan-congratulation/subscription-plan-congratulation.component';
import { SubscriptionPlanCreateEditComponent } from './subscription-plan-create-edit/subscription-plan-create-edit.component';
import { SubscriptionPlanListOverviewComponent } from './subscription-plan-list-overview/subscription-plan-list-overview.component';
import { SubscriptionPlanLookupComponent } from './subscription-plan-lookup/subscription-plan-lookup.component';
import { SubscriptionPlanUpdatedComponent } from './subscription-plan-updated/subscription-plan-updated.component';
import { SubscriptionPlanUserEditComponent } from './subscription-plan-user-edit/subscription-plan-user-edit.component';
import { SubscriptionPlanUserListComponent } from './subscription-plan-user-list/subscription-plan-user-list.component';
import { SubscriptionPlanViewModalComponent } from './subscription-plan-view-modal/subscription-plan-view-modal.component';
import { SubscriptionPlanViewComponent } from './subscription-plan-view/subscription-plan-view.component';

@NgModule({
  imports: [
    ArchwizardModule,
    ReactiveFormsModule,
    CdkTableModule,
    CommonModule,
    ModuleRoutingModule,
    ColorPickerModule,
    NgPipesModule,
    FormsModule,
    SharedModule,
    EmailEditorModule,
    PinchZoomModule,
    SettingsRoutingModule,
    AuthenticationModule,
    MerchantModule,
    NgxChartsModule,
    NgxPaginationModule,
    MatSortModule,
    SubscriptionPlanRoutingModule,
    ModuleModule,
  ],
  declarations: [
    SubscriptionPlanViewComponent,
    SubscriptionPlanCreateEditComponent,
    FeatureMainLabelCreateEditComponent,
    FeatureMainLabelListComponent,
    FeatureSubLabelCreateEditComponent,
    FeatureSubLabelListComponent,
    SubscriptionPlanCardListComponent,
    SubscriptionPlanListOverviewComponent,
    SubscriptionPlanCardListGlobalComponent,
    FeatureMainLabelImageViewComponent,
    FeatureMainLabelCoverViewComponent,
    SubscriptionPlanViewModalComponent,
    SubscriptionPlanCongratulationComponent,
    SubscriptionPlanUserListComponent,
    SubscriptionPlanUserEditComponent,
    SubscriptionPlanLookupComponent,
    FeatureSubLabelCreateRedirectComponent,
    SubscriptionPlanCalculatorComponent,
    FeatureSubLabelCoverViewComponent,
    FeatureSubLabelQuickEditComponent,
    FeatureMainLabelQuickEditComponent,
    FeatureSubLabelViewComponent,
    SubscriptionPlanUpdatedComponent,
  ],
  exports: [
    SubscriptionPlanViewComponent,
    SubscriptionPlanCreateEditComponent,
    FeatureMainLabelCreateEditComponent,
    FeatureMainLabelListComponent,
    FeatureSubLabelCreateEditComponent,
    FeatureSubLabelListComponent,
    SubscriptionPlanCardListComponent,
    SubscriptionPlanListOverviewComponent,
    SubscriptionPlanCardListGlobalComponent,
    FeatureMainLabelImageViewComponent,
    FeatureMainLabelCoverViewComponent,
    SubscriptionPlanViewModalComponent,
    SubscriptionPlanCongratulationComponent,
    SubscriptionPlanUserListComponent,
    SubscriptionPlanUserEditComponent,
    SubscriptionPlanLookupComponent,
    FeatureSubLabelCreateRedirectComponent,
    SubscriptionPlanCalculatorComponent,
    FeatureSubLabelCoverViewComponent,
    FeatureSubLabelQuickEditComponent,
    FeatureMainLabelQuickEditComponent,
    FeatureSubLabelViewComponent,
    SubscriptionPlanUpdatedComponent,
  ],
  providers: [SubscriptionPlanService],
})
export class SubscriptionPlanModule {}
