var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { UtilsService } from '../../../shared/services/utils.service';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { InvitationService } from '../../invitation/shared/services/invitation.service';
import { MerchantService } from '../../merchant/shared/merchant.service';
import { AppointmentService } from '../shared/appointment.service';
import { AppointmentLookupService } from '../shared/appointmentLookup.service';
export class AppointmentCreateCustomerComponent {
    constructor(utilService, authenticationService, appointmentService, merchantService, invitationService, appointmentLookupService, router, activeRoute, data) {
        this.utilService = utilService;
        this.authenticationService = authenticationService;
        this.appointmentService = appointmentService;
        this.merchantService = merchantService;
        this.invitationService = invitationService;
        this.appointmentLookupService = appointmentLookupService;
        this.router = router;
        this.activeRoute = activeRoute;
        this.data = data;
        this.isModal = true;
        this.defaultProfile = 'assets/img/placeholder.png';
        this.bestContactTimes = [];
        this.types = [];
        this.subTypes = [];
        this.isCreated = false;
        this.isApplicationProceed = false;
        this.displayReasonForVisiting = true;
        this.cancelLabel = 'Cancel';
        this.submitLabel = 'Submit';
        this.result = new EventEmitter();
        this.close = new EventEmitter();
        if (data) {
            if (data.invitationID) {
                this.invitationID = data.invitationID;
            }
            if (data.typeID) {
                this.typeID = data.typeID;
            }
            if (data.subTypeID) {
                this.subTypeID = data.subTypeID;
            }
            if (data.cancelLabel) {
                this.cancelLabel = data.cancelLabel;
            }
            if (data.submitLabel) {
                this.submitLabel = data.submitLabel;
            }
            if (data.isApplicationProceed != null) {
                this.isApplicationProceed = data.isApplicationProceed;
            }
            if (data.displayReasonForVisiting != null) {
                this.displayReasonForVisiting = data.displayReasonForVisiting;
            }
        }
    }
    ngOnInit() {
        return __awaiter(this, void 0, void 0, function* () {
            this.sessionType = yield this.authenticationService.getSessionType().toPromise();
            let res;
            if (!this.invitationID) {
                this.invitationID = this.activeRoute.snapshot.paramMap.get('invitationID');
            }
            if (this.invitationID) {
                const payload = {
                    fields: 'ID,FirstName,LastName,Merchant_Key,MerchantName,Prospect_key',
                };
                res = yield this.invitationService.getInvitationDetails(this.invitationID, payload, 'guest').toPromise();
            }
            else if (this.sessionType === 'customer') {
                res = yield this.authenticationService.getCurrentUser().toPromise();
            }
            if (res) {
                this.merchantID = this.invitationID ? res.Merchant_Key : res.data.merchantID;
            }
            else {
                this.router.navigate(['/404']);
            }
            this.setup();
        });
    }
    closeEvent() {
        this.close.emit(true);
    }
    addSubType() {
        if (this.type && this.type.Code) {
            const payload = {
                category: this.type.Code,
                isActive: true,
                merchantID: this.merchantID,
                fields: 'Lookup_Type,Category,Code,Label,Description,Sort_Order,DefaultDuration,Buffers_Pre,Buffers_Post',
            };
            this.appointmentLookupService.list(payload, this.sessionType).subscribe((res) => {
                if (res) {
                    this.subTypes = res;
                    if (this.subTypeID) {
                        const index = this.subTypes.findIndex((item) => {
                            if (item && item.Code && item.Code == this.subTypeID) {
                                return true;
                            }
                            return false;
                        });
                        if (index != -1) {
                            this.subType = this.subTypes[index];
                        }
                    }
                }
            });
        }
    }
    setup() {
        if (this.merchantID) {
            this.merchantProfile = this.merchantService.getPracticePicStreamLink(this.merchantID);
            if (this.displayReasonForVisiting == true) {
                const payload = {
                    merchantID: this.merchantID,
                    isActive: true,
                    lookupType: 'AppointmentTypes',
                    fields: 'Lookup_Type,Category,Code,Label,Description,Sort_Order,DefaultDuration,Buffers_Pre,Buffers_Post',
                };
                this.appointmentLookupService.list(payload, this.sessionType).subscribe((res) => {
                    if (res) {
                        this.types = res;
                        if (this.typeID) {
                            const index = this.types.findIndex((item) => {
                                if (item && item.Code && item.Code == this.typeID) {
                                    return true;
                                }
                                return false;
                            });
                            if (index != -1) {
                                this.type = this.types[index];
                            }
                        }
                    }
                });
            }
            const payload2 = {
                merchantID: this.merchantID,
                isActive: true,
                lookupType: 'BestAppointmentTime',
                fields: 'Lookup_Type,Category,Code,Label,Description,Sort_Order,DefaultDuration,Buffers_Pre,Buffers_Post',
            };
            this.appointmentLookupService.list(payload2, this.sessionType).subscribe((res) => {
                if (res) {
                    this.bestContactTimes = res;
                }
            });
            this.merchantService.getMerchantDetails(this.merchantID, {}, this.sessionType).subscribe((res) => __awaiter(this, void 0, void 0, function* () {
                if (res) {
                    this.merchant = res;
                }
                else {
                    this.router.navigate(['/404']);
                }
            }));
        }
    }
    book() {
        const p = {
            bestAppointmentCode: this.bestContactTime,
            comment: this.comment,
        };
        if (this.type && this.type.Code) {
            p.typeCode = this.type.Code;
        }
        else if (this.typeID) {
            p.typeCode = this.typeID;
        }
        if (this.type && this.type.Label) {
            p.typeDescription = this.type.Label;
        }
        if (this.subType && this.subType.Code) {
            p.subTypeCode = this.subType.Code;
        }
        else if (this.subTypeID) {
            p.subTypeCode = this.subTypeID;
        }
        if (this.subType && this.subType.Label) {
            p.subTypeDescription = this.subType.Label;
        }
        if (this.invitationID) {
            p.isApplicationProceed = this.isApplicationProceed;
            this.appointmentService.createForInvitation(this.invitationID, p).subscribe((res) => {
                if (res) {
                    this.isCreated = true;
                    this.result.emit(res);
                    NotifyAppComponent.displayToast('success', 'Success!', 'Your dentist has been notified for your appointment request.');
                }
            });
        }
        else {
            this.appointmentService.createForCustomer(p).subscribe((res) => {
                if (res) {
                    this.isCreated = true;
                    this.result.emit(res);
                    NotifyAppComponent.displayToast('success', 'Success!', 'Your dentist has been notified for your appointment request.');
                }
            });
        }
    }
    replaceAllString(e) {
        if (e && typeof e == 'string') {
            return String(e).replace(/�/g, "'");
        }
    }
}
