<app-customer-details-card-edit
  class=""
  *ngIf="patient && patient['ID'] && patient['TableName'] == 'Customer'"
  [customerID]="patient['ID']"
></app-customer-details-card-edit>
<app-prospect-details-card-edit
  class=""
  *ngIf="patient && patient['ID'] && patient['TableName'] == 'Prospect'"
  [prospectID]="patient['ID']"
></app-prospect-details-card-edit>
