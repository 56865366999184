<div class="rel dentist-card dc row clearfix" *ngIf="dentist" @onChangeAnimation>
  <div class="animate clearfix">
    <div class="row full-width clearfix heading" *ngIf="embedded == true">



      <app-card-picture class="pull-left  mr invitationContactLookup1208 " [cardID]="dentist['ID']"
        [LastModified]="dentist['LastModified']" [tableName]="'Contact'">
      </app-card-picture>



      <h2 class="summary-header rm-m ml">
        {{ dentist.CalculatedName }}
        <span class="subLabel" *ngIf="dentist['ContactType.Label']">{{ dentist['ContactType.Label'] }}</span>
      </h2>

      <button class="pull-right "
        *ngIf="displayActions == true &&(dentist['mobiles.Number']  || dentist['emails.Email']  || dentist['addresses.Calculated'])"
        [matMenuTriggerFor]="btnDentMenu" mat-icon-button>
        Actions <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #btnDentMenu="matMenu">
        <button *ngIf="dentist['mobiles.Number']" (click)="contactSMS(dentist.ID)" mat-menu-item>
          <mat-icon class="fas fa-sms  smr"></mat-icon>
          <span> Contact By SMS </span>
        </button>

        <button *ngIf="dentist['emails.Email']" (click)="contact(dentist.ID)" mat-menu-item>
          <mat-icon class="fas fa-at smr"></mat-icon>
          <span> Contact By Email </span>
        </button>
        <button *ngIf="dentist['addresses.Calculated']" (click)="displayMap()" mat-menu-item>
          <mat-icon class="smr">place</mat-icon>
          <span class="mobHide">View on map</span>
        </button>
      </mat-menu>
    </div>

    <div class="card-header primary-gradient-img clearfix dentHeading" *ngIf="embedded == false">
      <div class="row clearfix">
        <!-- Modal dismiss button -->
        <button class="btn-close btn-clear mat-button white" *ngIf="isModal == true" (click)="closeModal()">
          <mat-icon>clear</mat-icon>
        </button>
        <!-- merchant calculated name -->
        <div class="row titleArea clearfix">

          <app-card-picture class="pull-left  mr invitationContactLookup1208 " [cardID]="dentist['ID']"
            [LastModified]="dentist['LastModified']" [tableName]="'Contact'">
          </app-card-picture>
          <h2 class="summary-header rm-m">
            {{ dentist.CalculatedName }}
            <span class="subLabel" *ngIf="dentist['ContactType.Label']">{{ dentist['ContactType.Label'] }}


              <span *ngIf="(isPromoterOrAdmin==true || isMerchantAdmin==true ) && dentist['operatorIsAdmin']=='1'"
                class="chip chip-enable ml">
                Merchant Admin
              </span>
            </span>


          </h2>
        </div>
      </div>
      <div class="action-button"
        *ngIf="dentist['mobiles.Number']  || dentist['emails.Email']  || dentist['addresses.Calculated']">
        <button class="actionMenuToggle" [matMenuTriggerFor]="btnDentMenu" mat-raised-button>
          Actions <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #btnDentMenu="matMenu">
          <button *ngIf="dentist['mobiles.Number']" (click)="contactSMS(dentist.ID)" mat-menu-item>
            <mat-icon class="fas fa-sms  smr"></mat-icon>
            <span> Contact By SMS </span>
          </button>

          <button *ngIf="dentist['emails.Email']" (click)="contact(dentist.ID)" mat-menu-item>
            <mat-icon class="fas fa-at smr"></mat-icon>
            <span> Contact By Email </span>
          </button>
          <button *ngIf="dentist['addresses.Calculated']" (click)="displayMap()" mat-menu-item>
            <mat-icon class="smr">place</mat-icon>
            <span class="mobHide">View on map</span>
          </button>
        </mat-menu>
      </div>
    </div>

    <div class="full-width clearfix row">
      <div class="row mt clearfix details flex" *ngIf="dentist['mobiles.Number'] && displayPhone == true
      && (sessionType==='merchant' || sessionType==='merchant-admin' || sessionType=='admin' || sessionType=='promoter')
      ">
        <!-- <div class="icons"> -->
        <mat-icon class="ico fas fa-mobile"></mat-icon>
        <!-- </div> -->
        <!-- Phone numbers -->
        <div class="row-content">
          <!--  -->
          <p class="rm-mb rm-mt lead">{{ dentist['mobiles.Number']  }}</p>
          <p class="small">Mobile</p>
        </div>
      </div>


      <div class="row mt clearfix details flex" *ngIf="dentist['phones.Number'] && displayPhone == true">
        <!-- <div class="icons"> -->
        <mat-icon class="ico">phone</mat-icon>
        <!-- </div> -->
        <!-- Phone numbers -->
        <div class="row-content">
          <!--  -->
          <p class="rm-mb rm-mt lead">{{ dentist['phones.Number'] | customPhone: 'landLine' }}</p>
          <p class="small">Work</p>
        </div>
      </div>
      <hr *ngIf="dentist['emails.Email'] && displayEmail == true" />
      <!-- Email Addresses -->
      <div class="row mt clearfix details flex" *ngIf="dentist['emails.Email'] && displayEmail == true">
        <!-- <div class="icons"> -->
        <mat-icon class="ico">email</mat-icon>
        <!-- </div> -->
        <div class="row-content">
          <p class="rm-mb rm-mt lead">{{ dentist['emails.Email'] }}</p>
          <p class="small">Email</p>
        </div>
      </div>
      <hr *ngIf="dentist['addresses.Calculated'] && displayAdress == true" />
      <div class="row mt clearfix details flex" *ngIf="dentist['addresses.Calculated'] && displayAdress == true">
        <!-- <div class="icons"> -->
        <mat-icon class="ico">location_on</mat-icon>
        <!-- </div> -->
        <div class="row-content">
          <p class="rm-mb rm-mt lead">{{ dentist['addresses.Calculated'] }}</p>
          <p class="small">Address</p>
        </div>
      </div>


      <hr
        *ngIf="dentist['operatorID'] && (isMerchantAdmin==true || sessionType=='promoter' || sessionType=='admin') && dentist['operatorUserName']" />
      <div class="row mt clearfix details flex"
        *ngIf="dentist['operatorID']  && (isMerchantAdmin==true || sessionType=='promoter' || sessionType=='admin') && dentist['operatorUserName']">
        <!-- <div class="icons"> -->
        <mat-icon class="ico">lock</mat-icon>
        <!-- </div> -->
        <div class="row-content">
          <p class="rm-mb rm-mt lead">{{ dentist['operatorUserName'] }}</p>
          <p class="small">Username</p>
        </div>
      </div>

    </div>
  </div>
</div>
