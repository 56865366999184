import { ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatAutocomplete } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { LookupService } from '../../../shared/services/lookup.service';
import { UtilsService } from '../../../shared/services/utils.service';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { ContactService } from '../../contact/shared/contact.service';
import { PatientHealthcareComponent } from '../../customer-prospect/patient-healthcare-create/patient-healthcare-create.component';
import { CustomerProspectService } from '../../customer-prospect/shared/customerProspect.service';
import { CustomerService } from '../../customer/shared/customer.service';
import { DentistService } from '../../dentist/shared/dentist.service';
import { InvitationService } from '../../invitation/shared/services/invitation.service';
import { MedicalHistoryService } from '../../medical-history/shared/medical-history.service';
import { TagsService } from '../../tags/shared/tags.service';
import { TagPatientManagerComponent } from '../../tags/tag-patient-manager/tag-patient-manager.component';

export class FilterTag {
  name: string;
}

@Component({
  selector: 'app-customer-profile-tabs',
  templateUrl: './customer-profile-tabs.component.html',
  styleUrls: ['./customer-profile-tabs.component.css'],
})
export class CustomerProfileTabsComponent implements OnInit {
  @Input()
  patientID;

  medicalHistoryInvitation;
  @Input()
  appointmentID;

  @Input()
  isIntegrated = false;
  context=Settings.global['context'];
  @Output()
  goBack = new EventEmitter();

  @Input()
  displayTabs = true;

  birthdayDate;
  patient;

  @Input()
  tabs;

  @Input()
  itemID;

  selectedTabIndex = 0;

  @Output()
  merchantID = new EventEmitter();

  firstLoad = false;
  @Output()
  birthday = new EventEmitter();

  genders = [];
  titles = [];

  cardStatus;
  isPromoterOrAdmin = false;

  myPhoneNumber;
  phoneType = 'Primary';

  myMobile;
  mobileType = 'Primary';

  myEmail;
  emailType = 'Primary';

  addressType;

  addressTypes;
  phoneTypes;
  mobileTypes;
  emailTypes;

  myState;
  myPostCode;
  mySuburb;
  myStreetNumber;
  myStreetName;
  myStreetType;
  myUnitNumber;
  myLatitude;
  myLongitude;

  preferredContactTimeOptions = [];
  preferredContactMethodOptions = [];
  preferredMessageTypeOptions = [];

  myContactTime;
  myContactMethod;
  myMessageType;

  myDataConsent;
  myElectronicCommsConsent;
  marketingConsent;
  marketingConsentEmail;
  myPrivacyConsent;

  validateMobile;
  validatePhone;
  validateEmail;
  validateAddress;

  referralSource;
  referralCategories = [];
  referralSpecifics = [];
  myReferralSource;
  myReferralSpecific;

  statusList = [];
  myStatus;

  myOccupation;
  validateOccupation;

  relationshipList = [];
  myRelationship;

  accountManagers = [];

  contactList = [];

  myMedicare = {
    id: null,
    type: null,
    number: null,
    referenceNumber: null,
    validTo: null,
  };
  myPrivateHealthFund = {
    id: null,
    type: null,
    issuerCode: null,
    issuerName: null,
    issuerNameOther: null,
    number: null,
    referenceNumber: null,
  };

  isPrimaryAddress = true;
  isPrimaryPhone = true;
  isPrimaryMobile = true;
  isPrimaryEmail = true;

  profileLink;

  showContactCreate = false;

  isEditContact = false;
  selectedContactID;

  treatmentTab = false;
  financeTab = false;
  healthTab = false;
  contactTab = false;
  noteTab = false;

  refreshContactList = true;

  myInvitationID;
  myMedicalHistoryID;
  medicalHistoryID;

  @Input()
  selectedMerchant = localStorage.getItem('selectedMerchant');

  sessionType = 'merchant';
  medicalHistoryList;

  selectedSubTabIndex = 0;
  contractTab = false;
  paymentMethodTab = false;
  myQuotationID;
  myContractID;
  myPaymentMethodID;
  mySettlementID;

  setContractTab = 'overview';
  setApplicationsTab = 'overview';
  @Input()
  settings = 'patient';
  subSettings = 'quotation';

  isModulePatientHistoryActive = Settings.global['isModulePatientHistoryActive'];
  isModuleNoteFeaturesActive = Settings.global['isModulePatientHistoryActive'];

  isModuleAppointmentActive = Settings.global['isModuleAppointmentActive'];
  isModulePatientDocumentActive = Settings.global['isModulePatientDocumentActive'];
  //  filter tags
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER];
  tagCtrl;
  patientTags = [];
  merchantTags = [];
  merchantTagsFull = [];
  patientTagsFull = [];

  authenticationMethodNotFound = false;

  @ViewChild('tagInput', { static: false }) tagInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', { static: false }) matAutocomplete: MatAutocomplete;
  // end filter tags
  currentPractice;
  isAppointmentDisabled = false;
  healthHistoryActive = true;

  constructor(
    private customerService: CustomerService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private lookupService: LookupService,
    private utilService: UtilsService,
    private medicalHistoryService: MedicalHistoryService,
    private dentistService: DentistService,
    private contactService: ContactService,
    private customerProspectService: CustomerProspectService,
    private invitationService: InvitationService,
    private tagsService: TagsService
  ) {}

  ngOnInit() {
    AuthenticationService.healthHistoryAccess.subscribe((r) => {
      this.healthHistoryActive = r;
    });

    AuthenticationService.appoinmentAccess.subscribe((r) => {
      this.isAppointmentDisabled = r;
    });

    this.authenticationService.getCurrentPractice().subscribe((currentPractice) => {
      if (currentPractice) {
        this.currentPractice = currentPractice;
        this.checkBookingEngine();
        this.checkHH();
      }
    });

    this.activeRoute.params.subscribe((params) => {
      if (params['patientID']) {
        this.patientID = params['patientID'];
      }

      if (params['merchantID']) {
        this.selectedMerchant = params['merchantID'];
      }

      if (params['appointmentID']) {
        this.appointmentID = params['appointmentID'];
      }

      this.utilService.getCurrentAccess().subscribe((access) => {
        if (access) {
          this.isModulePatientHistoryActive = access['isModulePatientHistoryActive'];
          this.isModulePatientDocumentActive = access['isModulePatientDocumentActive'];
          this.isModuleNoteFeaturesActive = access['isModuleNoteFeaturesActive'];
          this.isModuleAppointmentActive = access['isModuleAppointmentActive'];
        }

        this.authenticationService.getSessionType().subscribe((res) => {
          this.sessionType = res;

          this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
            this.isPromoterOrAdmin = res;

            if (this.isPromoterOrAdmin == true) {
              const payload = {
                fields: 'ID,CalculatedName',
              };
              this.dentistService.getList(payload).subscribe((res) => {
                if (res) {
                  this.accountManagers = res;
                }
              });
            }

            if (this.isPromoterOrAdmin == false) {
              this.subSettings = 'settlement';
            }
            this.setupLookup();

            if (this.tabs) {
              this.settings = this.tabs;

              if (this.settings == 'quotation' && this.itemID) {
                this.settings = 'finance';
                this.subSettings = 'quotation';
                this.myQuotationID = this.itemID;
              } else if (this.settings == 'contract' && this.itemID) {
                this.settings = 'finance';
                this.subSettings = 'contract';
                this.myContractID = this.itemID;
              } else if (this.settings == 'payment' && this.itemID) {
                this.settings = 'finance';
                this.subSettings = 'payment';
                this.myPaymentMethodID = this.itemID;
              } else if (this.settings == 'treatment' && this.itemID) {
                this.myInvitationID = this.itemID;

                const p = {
                  fields: 'QuotationORContract_Key',
                };

                this.invitationService
                  .getInvitationDetails(this.myInvitationID, p, this.sessionType, false)
                  .subscribe((res) => {
                    if (res && res['QuotationORContract_Key']) {
                      this.myContractID = res['QuotationORContract_Key'];
                    }
                  });
              } else if (this.settings == 'health' && this.itemID) {
                this.myMedicalHistoryID = this.itemID;
              } else if (this.settings == 'settlement') {
                this.settings = 'finance';
                this.subSettings = 'settlement';
                this.mySettlementID = this.itemID;
              } else if (this.settings == 'appointments' && this.itemID) {
                this.settings = 'appointments';
                this.appointmentID = this.itemID;
              }
            }

            this.setupPatient();
          });
        });
      });
    });
  }

  notFoundAuthenticationMethod(e) {
    this.authenticationMethodNotFound = e;
  }

  // for opening contract list
  setMyContractID(e) {
    this.myContractID = e;
  }

  // for opening quotation list
  setMyQuotationID(e) {
    this.myQuotationID = e;
  }

  // reset menus on change
  setSubSettingView(value) {
    if (value == 'quotation') {
      this.subSettings = 'quotation';
      this.setContractTab = 'overview';
      this.myContractID = '';
    } else if (value == 'contract') {
      this.subSettings = 'contract';
      this.setApplicationsTab = 'overview';
      this.myQuotationID = '';
      //
    } else if (value == 'payment') {
      this.subSettings = 'payment';
      this.setContractTab = 'overview';
      this.setApplicationsTab = 'overview';
      this.myContractID = '';
      this.myQuotationID = '';
    } else if (value == 'settlement') {
      this.subSettings = 'settlement';
      this.setContractTab = 'overview';
      this.setApplicationsTab = 'overview';
      this.myContractID = '';
      this.myQuotationID = '';
    }
  }

  checkBookingEngine() {
    if (this.currentPractice && this.currentPractice['ThirdPartyBooking_URL']) {
      this.isAppointmentDisabled = true;
    } else {
      this.isAppointmentDisabled = false;
    }
  }

  checkHH() {
    if (this.currentPractice && this.currentPractice['HealthHistoryEnabled.Overridden'] == 'Y') {
      this.healthHistoryActive = true;
    } else {
      this.healthHistoryActive = false;
    }
  }

  getPicture() {
    this.profileLink = null;
    if (this.patient['ID']) {
      this.profileLink = this.customerService.getProfilePicStreamLink(this.patient['ID']);
    }
  }

  setupPatient() {
    if (this.patientID && typeof this.patientID == 'string') {
      this.customerService.getCustomerDetails(this.patientID, {}, this.sessionType).subscribe((res) => {
        if (res) {
          this.patient = res;
          this.merchantID.emit(this.patient['Merchant_key']);
          this.birthday.emit(this.patient['DateOfBirth']);
          this.getPicture();
          this.patient['DateOfBirth'] = new Date(this.patient['DateOfBirth']);

          this.myPhoneNumber = this.patient['phones.Number'];
          this.myMobile = this.patient['mobiles.Number'];
          this.myEmail = this.patient['emails.Email'];

          this.myState = this.patient['addresses.State'];
          this.myPostCode = this.patient['addresses.Postcode'];
          this.mySuburb = this.patient['addresses.Suburb'];
          this.myStreetNumber = this.patient['addresses.Street Nr'];
          this.myStreetName = this.patient['addresses.Street Name'];
          this.myStreetType = this.patient['addresses.Street Type'];
          this.myUnitNumber = this.patient['addresses.Unit'];
          this.myLatitude = this.patient['Address.Latitude'];
          this.myLongitude = this.patient['Address.Longitude'];

          // this.myContactMethod = this.patient['PreferredContactMethod.Code'];
          // this.myContactTime = this.patient['PreferredContactTime.Code'];
          // this.myMessageType = this.patient['PreferredMessageType.Code'];

          this.myDataConsent = this.patient['DataConsent.Given'] == '1' ? true : false;
          this.myElectronicCommsConsent = this.patient['ElectronicCommsConsent.Given'] == '1' ? true : false;
          this.marketingConsent = this.patient['MarketingConsent.Given'] == '1' ? true : false;
          this.marketingConsentEmail = this.patient['MarketingConsent_Email.Given'] == '1' ? true : false;

          this.myPrivacyConsent = this.patient['PrivacyConsent.Given'] == '1' ? true : false;

          // this.myReferralSource = this.patient['ReferralSource.Category'];
          this.getReferralSpecific(this.patient['ReferralSource.Category']);
          // this.myReferralSpecific = this.patient['ReferralSource.Specific'];

          this.myStatus = this.patient['Status'];
          this.myOccupation = this.patient['Occupation'];

          if (this.patient['addresses.Labels']) {
            this.addressTypes = this.patient['addresses.Labels'].split(';');
          }
          if (this.patient['phones.Labels']) {
            this.phoneTypes = this.patient['phones.Labels'].split(';');
          }
          if (this.patient['mobiles.Labels']) {
            this.mobileTypes = this.patient['mobiles.Labels'].split(';');
          }
          if (this.patient['emails.Labels']) {
            this.emailTypes = this.patient['emails.Labels'].split(';');
          }

          this.addressType = this.patient['addresses.Primary'];
          this.mobileType = this.patient['mobiles.Primary'];
          this.phoneType = this.patient['phones.Primary'];
          this.emailType = this.patient['emails.Primary'];

          this.getContactList();

          this.getRecentMedicalHistory();

          const payloadHealthCare = {
            ownerID: this.patient['ID'],
            isHealthCare: true,
          };
          this.customerProspectService.getHealthcare(payloadHealthCare, this.sessionType).subscribe((res) => {
            if (res) {
              const _medicareObject = res.find((x) => x['Type.Code'] == 'AU_IDM');
              if (_medicareObject) {
                if (_medicareObject['ID']) {
                  this.myMedicare.id = _medicareObject['ID'];
                }
                if (_medicareObject['Type.Code']) {
                  this.myMedicare.type = _medicareObject['Type.Code'];
                }
                if (_medicareObject['Number']) {
                  this.myMedicare.number = _medicareObject['Number'];
                }
                if (_medicareObject['Text1']) {
                  this.myMedicare.referenceNumber = _medicareObject['Text1'];
                }
                if (_medicareObject['Date.Expiry']) {
                  this.myMedicare.validTo = _medicareObject['Date.Expiry'];
                }
              }

              const _privateHealthFundObject = res.find((x) => x['Type.Code'] == 'AU_PHF');
              if (_privateHealthFundObject) {
                if (_privateHealthFundObject['ID']) {
                  this.myPrivateHealthFund.id = _privateHealthFundObject['ID'];
                }
                if (_privateHealthFundObject['Type.Code']) {
                  this.myPrivateHealthFund.type = _privateHealthFundObject['Type.Code'];
                }
                if (_privateHealthFundObject['Issuer.Code']) {
                  this.myPrivateHealthFund.issuerCode = _privateHealthFundObject['Issuer.Code'];
                }
                if (_privateHealthFundObject['Issuer.Label']) {
                  this.myPrivateHealthFund.issuerName = _privateHealthFundObject['Issuer.Label'];
                }

                if (_privateHealthFundObject['issuer.LabelOther']) {
                  this.myPrivateHealthFund.issuerNameOther = _privateHealthFundObject['issuer.LabelOther'];
                }

                if (_privateHealthFundObject['Number']) {
                  this.myPrivateHealthFund.number = _privateHealthFundObject['Number'];
                }
                if (_privateHealthFundObject['Text1']) {
                  this.myPrivateHealthFund.referenceNumber = _privateHealthFundObject['Text1'];
                }
              }
            }
          });

          const __p = {};
          this.customerProspectService.getMerchantTag(__p, this.isPromoterOrAdmin).subscribe((res) => {
            if (res && res.length > 0) {
              this.merchantTagsFull = res;
              this.merchantTags = res.map((x) => x['Label']);
            }
          });

          const __p1 = {
            patientID: this.patient['ID'],
          };
          this.customerProspectService.getMerchantTag(__p1, this.isPromoterOrAdmin).subscribe((res) => {
            if (res && res.length > 0) {
              this.patientTagsFull = res;
              this.patientTags = res.map((x) => x['Label']);
            }
          });
        }
      });
    }
  }

  getRecentMedicalHistory() {
    if (this.patient && this.patient['ID']) {
      const payload = {
        cardID: this.patient['ID'],
        patientID: this.patient['ID'],
        fields: 'ID,Date.Activated,DateTimeCreated,Date.Completed,Status',
        orderBy: '-Date.Activated',
      };

      if (this.selectedMerchant) {
        payload['merchantID'] = this.selectedMerchant;
      } else if (this.patient['Merchant_key']) {
        payload['merchantID'] = this.patient['Merchant_key'];
      }

      if (this.patient['ID']) {
        this.medicalHistoryService
          .getMedicalHistoryInvitationByPatient(this.patient['ID'], payload['merchantID'], null, this.sessionType)
          .subscribe((res) => {
            if (res) {
              this.medicalHistoryInvitation = res;
            }
          });
      }

      this.medicalHistoryService.getMedicalHistoryList(payload, this.sessionType).subscribe((res) => {
        if (res) {
          this.medicalHistoryList = res;

          if (this.myMedicalHistoryID) {
            this.medicalHistoryID = this.myMedicalHistoryID;
          } else if (res && res[0] && res[0]['ID']) {
            this.medicalHistoryID = res[0]['ID'];
            this.myMedicalHistoryID = res[0]['ID'];
          }
        }

        this.firstLoad = true;
      });
    }
  }

  changeMedicalID() {
    if (this.myMedicalHistoryID) {
      this.medicalHistoryID = this.myMedicalHistoryID;
    }
  }

  getContactList() {
    const payload = {
      fields: 'ID,CalculatedName',
    };
    this.contactService.getContactList(this.patient['ID'], payload, 'patient').subscribe((res) => {
      if (res) {
        this.contactList = res;
      }
    });
  }

  setupLookup() {
    this.lookupService.getLookup('SimpleLookup', 'Salutation').subscribe((res) => {
      this.titles = res;
    });

    this.lookupService.getLookup('CodeLookup', 'Gender').subscribe((res) => {
      this.genders = res;
    });

    this.lookupService.getLookup('CodeLookup', 'ContactTime').subscribe((res) => {
      this.preferredContactTimeOptions = res;
    });

    this.lookupService.getLookup('CodeLookup', 'ContactMethod').subscribe((res) => {
      this.preferredContactMethodOptions = res;
    });

    this.lookupService.getLookup('CodeLookup', 'MessageType').subscribe((res) => {
      this.preferredMessageTypeOptions = res;
    });

    this.utilService.getReferralSource().subscribe((res) => {
      if (res) {
        this.referralCategories = res.map((x) => x['Category']);
        this.referralSource = res;
      }
    });

    this.lookupService.getLookup('CodeLookup', 'CardStatus').subscribe((res) => {
      this.statusList = res;
    });

    this.lookupService.getLookup('CodeLookup', 'RelationshipStatus').subscribe((res) => {
      this.relationshipList = res;
    });
  }

  getOccupation(e) {
    if (e) {
      this.myOccupation = e.Label;
    }
  }

  validateOccupationEvent(v) {
    this.validateOccupation = v;
  }

  onCompleteAll(fileID) {
    if (fileID && fileID[0] && this.patient['ID']) {
      const payload = {
        fileID: fileID[0],
      };

      this.profileLink = null;
      this.customerProspectService.uploadProfilePic(this.patient['ID'], payload).subscribe((res) => {
        this.getPicture();
      });
    }
  }

  getReferralSpecific(category) {
    if (category && this.referralSource) {
      this.referralSpecifics = this.referralSource.filter((x) => x['Category'] == category)[0]['Specific'].split(';');
    }
  }

  modifySpecialContact() {
    const payload = {
      guardian_key: this.patient['Gardian_key'],
      carer_key: this.patient['Carer_key'],
      emergency_key: this.patient['Emergency_key'],
      gp_key: this.patient['GP_key'],
      generalDentist_key: this.patient['GeneralDentist_key'],
      responsiblePartyForFees_key: this.patient['ResponsiblePartyForFees_key'],
    };

    this.customerService.editCustomer(this.patient['ID'], payload).subscribe((res) => {
      if (res) {
        this.patient['Gardian_key'] = res['Gardian_key'];
        this.patient['Carer_key'] = res['Carer_key'];
        this.patient['Emergency_key'] = res['Emergency_key'];
        this.patient['GP_key'] = res['GP_key'];
        this.patient['GeneralDentist_key'] = res['GeneralDentist_key'];
        this.patient['ResponsiblePartyForFees_key'] = res['ResponsiblePartyForFees_key'];

        NotifyAppComponent.displayToast(
          'success',
          'Update Special Contact',
          'You have successfully updated special contacts!'
        );
      }
    });
  }

  getDate(e) {
    this.birthdayDate = e;
  }

  savePatientDetails() {
    const payload = {
      id: this.patient['ID'],
      firstName: this.patient['FirstName'],
      midleName: this.patient['MiddleName'] || 'void',
      lastName: this.patient['Name'],
      // email: this.myEmail,
      // mobile: this.myMobile,
      // workPhone: this.myPhoneNumber,
      title: this.patient['Salutation'],
      birthday: this.birthdayDate || 'void',
      // streetName: this.myStreetName,
      // streetNumber: this.myStreetNumber,
      // streetType: this.myStreetType,
      // unitNumber: this.myUnitNumber,
      // suburb: this.mySuburb,
      // app-state: this.myState,
      // postCode: this.myPostCode,
      // latitude: this.myLatitude,
      // longitude: this.myLongitude,
      isReffered: true,
      status: this.myStatus,
      preferredContactMethod: this.patient['PreferredContactMethod.Code'],
      preferredContactTime: this.patient['PreferredContactTime.Code'],
      preferredMessageType: this.patient['PreferredMessageType.Code'],
      referralSourceCategory: this.patient['ReferralSource.Category'],
      referralSourceSpecific: this.patient['ReferralSource.Specific'],
      electronicCommsConsent: this.myElectronicCommsConsent,
      marketingConsent: this.marketingConsent,
      marketingConsentEmail: this.marketingConsentEmail,
      dataConsent: this.myDataConsent,
      privateConsent: this.myPrivacyConsent,
      relationshipStatus: this.patient['RelationshipStatus.Code'],
      occupation: this.myOccupation || 'void',
      accountManagerKey: this.patient['AccountManager_key'],
    };

    this.customerService.editCustomer(this.patient['ID'], payload).subscribe((res) => {
      if (res) {
        this.goBack.emit({ closeOnly: true });
        NotifyAppComponent.displayToast(
          'success',
          'Update Patient Details',
          "You have successfully updated patient's details"
        );
      }
    });
  }

  removeHealthcare(card, label) {
    const confirm = new ConfirmDialog(
      'fas fa-info',
      `Remove ${label}`,
      'You are about to remove this record. <br> Are you sure about it?',
      'Cancel',
      'Proceed'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirm,
      width: '650px',
    });

    ref.componentInstance.onConfirm.subscribe((res) => {
      if (res == true) {
        this.customerProspectService.removeHealthcare(card.id, this.sessionType).subscribe((res) => {
          if (res == true) {
            ref.close();
            NotifyAppComponent.displayToast('success', `Remove ${label}`, `You have successfully removed ${label}`);

            if (card.type == 'AU_IDM') {
              this.myMedicare = {
                id: null,
                type: null,
                number: null,
                referenceNumber: null,
                validTo: null,
              };
            } else if (card.type == 'AU_PHF') {
              this.myPrivateHealthFund = {
                id: null,
                type: null,
                issuerCode: null,
                issuerName: null,
                issuerNameOther: null,
                number: null,
                referenceNumber: null,
              };
            }
          }
        });
      } else {
        ref.close();
      }
    });
  }

  modifyHealthcare(card, type) {
    const ref = RootAppComponent.dialog.open(PatientHealthcareComponent, {
      data: {
        recordID: card.id,
        ownerID: this.patient['ID'],
        type,
      },
      width: '650px',
    });

    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });

    ref.componentInstance.sendResult.subscribe((res) => {
      if (res) {
        if (res['Type.Code'] == 'AU_IDM') {
          this.myMedicare = {
            id: res['ID'],
            type: res['Type.Code'],
            number: res['Number'],
            referenceNumber: res['Text1'],
            validTo: res['Date.Expiry'],
          };
        } else if (res['Type.Code'] == 'AU_PHF') {
          this.myPrivateHealthFund = {
            id: res['ID'],
            type: res['Type.Code'],
            issuerCode: res['Issuer.Code'],
            issuerName: res['Issuer.Label'],
            issuerNameOther: res['issuer.LabelOther'],
            referenceNumber: res['Text1'],
            number: res['Number'],
          };
        }
      }
    });
  }

  convertToLowerCase(val) {
    return val.toLowerCase();
  }

  createNewContact() {
    this.showContactCreate = true;
    this.refreshContactList = false;
  }

  hideContactCreate() {
    this.showContactCreate = false;
    this.refreshContactList = true;
    this.getContactList();
  }

  openContactEditEvent(e) {
    if (e) {
      this.isEditContact = true;
      this.selectedContactID = e;
      this.refreshContactList = false;
    }
  }

  hideContactEdit() {
    this.isEditContact = false;
    this.selectedContactID = null;
    this.refreshContactList = true;
    this.getContactList();
  }

  displayInvitationList() {
    this.myInvitationID = null;
  }

  displayQuotationList() {
    this.myQuotationID = null;
  }

  displayContractList() {
    this.myContractID = null;
  }

  inviteMedicalHistory() {
    if (this.patient && this.patient.ID) {
      this.router.navigate([
        '/merchant',
        { outlets: { page: ['medical-history', 'medical-history-invitation-create', this.patient.ID] } },
      ]);
    }
  }

  createTag() {
    if (this.patientID) {
      const ref = RootAppComponent.dialog.open(TagPatientManagerComponent, {
        data: {
          patientID: this.patientID,
          patientTags: this.patientTags || [],
        },
        width: '700px',
      });

      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });

      ref.componentInstance.getResult.subscribe((res) => {
        if (res) {
          this.patientTags = res || [];
        }
      });
    }
  }

  removeTag(value: string): void {
    if (value) {
      const confirmDialog = new ConfirmDialog(
        'cancel',
        'Remove ' + value + ' Tag',
        '<p>Are you sure?</p>',

        'Cancel',
        'Okay'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirmDialog,
        // SET TO PAYING CASH CARD
      });
      ref.componentInstance.onConfirm.subscribe((val) => {
        if (val == true) {
          const p = {
            label: value,
            cardIDs: [this.patientID],
          };
          this.tagsService.deleteTagForCard(p, this.sessionType).subscribe((res) => {
            if (res) {
              NotifyAppComponent.displayToast('success', 'Success!', 'Tag is removed.');

              const index = this.patientTags.indexOf(value);

              if (index >= 0) {
                this.patientTags.splice(index, 1);
              }
              ref.close();
            }
          });
        } else {
          ref.close();
        }
      });
    }
  }

  goBackEvent() {
    this.goBack.emit(true);
  }
}
