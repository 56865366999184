import { BehaviorSubject, merge as observableMerge, Observable } from 'rxjs';

import { map } from 'rxjs/operators';

import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { DataSource } from '@angular/cdk/table';
import { Component, ElementRef, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { TextMessageCreateComponent } from '../../message/text-message-create/text-message-create.component';
import { ContractService } from '../shared/contract.service';
import * as _ from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { SideMenuService } from '../../../shared/services/side-menu.service';
import { NotesModal, NotesModalConfig } from '../../notes/modals/notes-modal/notes.modal';
import { CustomPhonePipe } from '../../../shared/pipes/custom-phone.pipe';
import { ContractViewComponent } from '../contract-view/contract-view.component';

@Component({
  selector: 'app-contract-arrears-list',
  templateUrl: './contract-arrears-list.component.html',
  styleUrls: ['./contract-arrears-list.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.row', style({ opacity: 0 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.row', style({ opacity: 1 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class ContractArrearsListComponent implements OnInit {
  @Input()
  dateFrom;

  @Input()
  dateTo;

  statusCodes;

  @Input()
  pageSize = Settings.global['listPageSize'] || 20;
  pageSizeOptions = [10, Number(this.pageSize), Number(this.pageSize) * 2, Number(this.pageSize) * 3];

  utils = new UtilsClass();
  displayedColumns = [
    'patientPicture',
    'BorrowerName',

    'merchantPicture',
    'Merchant',
    'status',
    'ArrearsDays',
    'AmountFinanced',
    'Outstanding',
    'ArrearsAmount',
    'Actions',
  ];

  filtersLoaded = false;

  gaugeType = 'arch';
  gaugeValue = 0;
  gaugeLabel;
  items = [
    'contract',
    'settlement',
    'invitation',
    'customer',
    'dentist',
    'merchant',
    'instantOffer',
    'appointment',
    'campaign',
    'landingPage',
    'note',
  ];

  lessIsMore = {
    '1': { color: '#8bc34a' },
    '2': { color: '#ffeb3b' },
    '3': { color: '#ffc107' },
    '4': { color: '#ff9800' },
    '5': { color: '#ff5722' },
  };

  approvedOrActive;

  filters = [];

  searchVals = new FormControl();

  searchValList = ['Status', 'Customer FullName', 'Mobile', 'Email', 'Product Name', 'Loan Purpose'];

  public listDB: LoadRecords | null;
  dataSource: RecordDataSource | null;

  filteredSize = null;

  isPromoterOrAdmin = false;

  arrearsDaysFilter = ['7 days', '14 days', '30 days', '60 days', '90 days', '90+ days'];

  selectedArrearID = 'alldays';

  arrearDaysTOP = [
    {
      id: 'alldays',
      label: 'All Arrears',
      minValue: null,
      maxValue: null,
    },
    {
      id: '7days',
      label: '1-7 days',
      minValue: 1,
      maxValue: 7,
    },

    {
      id: '14days',
      label: '8-14 days',
      minValue: 8,
      maxValue: 14,
    },

    {
      id: '30days',
      label: '15-30 days',
      minValue: 15,
      maxValue: 30,
    },

    {
      id: '60days',
      label: '31-60 days',
      minValue: 31,
      maxValue: 60,
    },

    {
      id: '90days',
      label: '61-90 days',
      minValue: 61,
      maxValue: 90,
    },

    {
      id: '90days+',
      label: '90+ days',
      minValue: 91,
      maxValue: null,
    },
  ];

  isPositive = true;
  merchantsFilter = [];
  statusCodesFilter = [];
  status;

  destroyEvent = new EventEmitter();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('filter', { static: false }) filter: ElementRef;

  constructor(
    private contractService: ContractService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private dialog: MatDialog,
    private customPhonePipe: CustomPhonePipe
  ) {}

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      if (params['status']) {
        this.status = params['status'];
      }
      if (params['statusCodes']) {
        this.statusCodes = params['statusCodes'];
      }

      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isPromoterOrAdmin = res;

        if (this.isPromoterOrAdmin == false) {
          this.router.navigate(['/merchant']);
        }

        SideMenuService.goBack.subscribe((res) => {
          if (res == true) {
          }
        });
      });

      let p = {
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
        isPositive: this.isPositive,
        status: this.status,
        statusCodes: this.statusCodes,
      };
      this.listDB = new LoadRecords(this.contractService, this.destroyEvent, p, this.customPhonePipe);
      this.dataSource = new RecordDataSource(this.listDB, this.paginator, this.sort);

      this.listDB.getMerchants.subscribe((tags) => {
        this.merchantsFilter = JSON.parse(JSON.stringify(tags || []));
      });

      this.listDB.getStatusCodes.subscribe((tags) => {
        this.statusCodesFilter = JSON.parse(JSON.stringify(tags || []));
      });
    });
  }

  getTitle() {
    if (this.status) {
      if (this.status === 'ACTIVE') {
        return 'Active Contracts';
      } else if (this.status === 'ARRGMNT') {
        return 'Contracts In Arrangement';
      }
      if (this.status === 'COMPLETE') {
        return 'Completed Contracts ';
      } else if (this.status === 'RECOVERY') {
        return 'Contracts In Recovery';
      }
      if (this.status === 'SUBMITTED') {
        return 'Submitted Contracts ';
      } else if (this.status === 'SUSP') {
        return 'Suspended Contracts ';
      }
    } else if (this.statusCodes) {
      if (this.statusCodes === 'RECOVERY,RECOV_FULL,RECOV_PRT') {
        return 'Contracts In Recovery ';
      }
    }

    return 'Contracts';
  }

  isPositiveEvent(e) {
    let p = {
      dateFrom: this.dateFrom,
      dateTo: this.dateTo,
      isPositive: e,
      status: this.status,
      statusCodes: this.statusCodes,
    };
    this.listDB = new LoadRecords(this.contractService, this.destroyEvent, p, this.customPhonePipe);
    this.dataSource = new RecordDataSource(this.listDB, this.paginator, this.sort);
  }

  setFilter(event, field) {
    let filter;

    if (field === 'arrears') {
      this.selectedArrearID = event;
    }

    if (event) {
      filter = {
        field,
        value: event,
      };
    } else {
      filter = {
        field,
        value: '',
      };
    }

    this.dataSource.filter = filter;
    this.filteredSize = this.dataSource.filteredData.length;
  }

  toNumber(n) {
    if (n) {
      return Number(n);
    } else {
      return 0;
    }
  }

  sendSMS(patientID) {
    const ref = RootAppComponent.dialog.open(TextMessageCreateComponent, {
      data: {
        patientID,
      },
      width: '650px',
    });

    ref.componentInstance.closeModal.subscribe((res) => {
      ref.close();
    });
  }

  viewContract(contractID, patientID) {
    const ref = RootAppComponent.dialog.open(ContractViewComponent, {
      data: {
        contractID: contractID,
        isSimple: false,
      },
      width: '900px',
      panelClass: 'noCard',
    });

    ref.componentInstance.closeModal.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  createNote(patientID: string, contractID: string) {
    this.dialog.open<NotesModal, NotesModalConfig>(NotesModal, {
      data: {
        patientID,
        parentRoute: 'merchant',
        noteListMode: 'create',
        relationshipLink: {
          label: 'contract',
          route: `customer-profile/${patientID}/contract/${contractID}`,
          itemID: contractID,
        },
      },
    });
  }

  viewNotes(patientID: string) {
    this.dialog.open<NotesModal, NotesModalConfig>(NotesModal, {
      data: {
        parentRoute: 'merchant',
        patientID,
      },
    });
  }

  getTotalColumn(field) {
    return this.dataSource.filteredData.map((t) => t[field]).reduce((acc, value) => Number(acc) + Number(value), 0);
  }

  ngOnDestroy() {
    this.destroyEvent.emit(true);
  }
}

export class LoadRecords implements OnInit {
  public dataChange: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  items: any;
  count: any;

  public getStatusCodes = new EventEmitter();
  public getMerchants = new EventEmitter();

  merchants = [];
  statusCodes = [];

  get data(): any[] {
    return this.dataChange.value;
  }

  firstLoad = false;

  get firstLoadEvent() {
    return this.firstLoad;
  }

  util = new UtilsClass();
  isDestoyed = false;

  serviceRef;

  constructor(
    private contractService: ContractService,
    private destroyEvent,
    private params,
    private customPhonePipe: CustomPhonePipe
  ) {
    this.destroyEvent.subscribe((res) => {
      this.isDestoyed = res;
    });

    const payload: any = {
      section: 0,
      isPosArrears: this.params.isPositive,
      orderBy: '-Arrears.days',
      onlyContract: true,
      status: this.params.status,

      statusCodes: this.params.statusCodes,

      fields:
        'ID,MerchantName,Customer.FullName,Customer_key,Product.Label.Internal,Product.Label.Marketing,Amount.Financed,Status,Amount.Outstanding,FunderName,ProductName,Customer.FullName,Customer.Mobile,Customer.Email,Merchant_key,' +
        'Arrears.Amount,Arrears.AmountOverdue,Arrears.CalculationDate,Arrears.days,Arrears.DaysOverdue,Invitation_key,Product_key,Customer_key,Settlements.NotYetDrawn,' +
        'Status.Code,LoanInstalments,RepaymentFrequency.Noun.Singular,FunderName,' +
        'Deposit,Amount.FinancedBeforeDeposit,' +
        'Arrears.days,LastEvent.DateTimeCreated,LastEvent.EventCode,LastEvent.EventDescription,Quotation.Status.Code,Quotation.Status.Label',
    };
    if (this.params.dateFrom) {
      payload.dateFrom = this.util.EPdateFormat(this.params.dateFrom);
    }

    if (this.params.dateTo) {
      payload.dateTo = this.util.EPdateFormat(this.params.dateTo);
    }

    this.serviceRef = this.contractService.getContractListGlobal(payload).subscribe((res) => {
      this.count = res.length;
      this.items = res;
      for (let i = 0; i < this.items.length; i++) {
        this.buildRecord(i);
      }

      this.merchants = _.uniqBy(this.merchants, 'ID');
      this.getMerchants.emit(this.merchants);
      this.statusCodes = _.uniqBy(this.statusCodes, 'code');
      this.getStatusCodes.emit(this.statusCodes);

      this.dataChange.next(this.items.slice());
      this.firstLoad = true;
      const innerFunction = (section) => {
        section = section + 1;

        const length = this.items.length;
        UtilsClass.loadingDataSection(section);
        payload.section = section;

        this.serviceRef = this.contractService.getContractListGlobal(payload).subscribe((res) => {
          if (res.length > 0) {
            this.count = res.count;
            this.items = this.items.concat(res);
            for (let i = length; i < this.items.length; i++) {
              this.buildRecord(i);
            }

            this.merchants = _.uniqBy(this.merchants, 'ID');
            this.getMerchants.emit(this.merchants);
            this.statusCodes = _.uniqBy(this.statusCodes, 'code');
            this.getStatusCodes.emit(this.statusCodes);

            this.dataChange.next(this.items.slice());
            if (this.isDestoyed != true) {
              innerFunction(section);
            }
          } else {
            return true;
          }
        });
        UtilsClass.stopLoadingInterceptor();
      };

      if (this.isDestoyed != true) {
        innerFunction(1);
      }
    });
  }

  buildRecord(i) {
    if (this.items[i]) {
      this.items[i]['fullName'] = '';

      if (this.items[i]['ID']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['ID'];
      }
      if (this.items[i]['MerchantName']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['MerchantName'];
      }

      if (this.items[i]['Status']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['Status'];
      }

      if (this.items[i]['Status.Code']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['Status.Code'];
      }

      if (this.items[i]['Quotation.Status.Code']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['Quotation.Status.Code'];
      }

      if (this.items[i]['Quotation.Status.Label']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['Quotation.Status.Label'];
      }

      if (this.items[i]['Customer.FullName']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['Customer.FullName'];
      }
      if (this.items[i]['Customer.Email']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['Customer.Email'];
      }
      if (this.items[i]['ProductName']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['ProductName'];
      }
      if (this.items[i]['Invitation_key']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['Invitation_key'];
      }
      if (this.items[i]['Merchant_key']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['Merchant_key'];
      }

      if (this.items[i]['Product.Label.Internal']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['Product.Label.Internal'];
      }
      if (this.items[i]['Product.Label.Marketing']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['Product.Label.Marketing'];
      }
      if (this.items[i]['Product.Label.Web']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['Product.Label.Web'];
      }

      if (this.items[i]['Customer.Mobile']) {
        this.items[i]['fullName'] =
          this.items[i]['fullName'] +
          ' ' +
          this.customPhonePipe.transform(this.items[i]['Customer.Mobile'], 'none').replace(/ /g, '');
      }
      if (this.items[i]['Customer.Mobile']) {
        this.items[i]['fullName'] =
          this.items[i]['fullName'] + ' ' + this.customPhonePipe.transform(this.items[i]['Customer.Mobile'], 'none');
      }
      if (this.items[i]['FunderName']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['FunderName'];
      }
      if (this.items[i]['LastEvent.EventDescription']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['LastEvent.EventDescription'];
      }
      if (this.items[i]['LastEvent.EventCode']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['LastEvent.EventCode'];
      }

      const p = {
        Label: this.items[i]['MerchantName'],
        ID: this.items[i]['Merchant_key'],
      };

      this.merchants.push(p);

      const p2 = {
        code: this.items[i]['Status.Code'],
        icon: this.items[i]['Status.Icon'],

        color: this.items[i]['Status.Color'],

        label: this.items[i]['Status'],
      };

      this.statusCodes.push(p2);
    }
  }
  ngOnInit() {}

  ngOnDestroy() {
    if (this.serviceRef) {
      this.serviceRef.unsubscribe();
    }
  }
}

export class RecordDataSource extends DataSource<any> {
  _filterChange = new BehaviorSubject('');
  field = '';

  get filter(): any {
    return this._filterChange.value;
  }

  set filter(item: any) {
    this.field = item.field;
    this._filterChange.next(item.value);
  }

  filteredData: any[] = [];

  constructor(private _tableDatabase: LoadRecords, private _paginator: MatPaginator, private _sort: MatSort) {
    super();
    this._filterChange.subscribe(() => (this._paginator.pageIndex = 0));
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<any[]> {
    const displayDataChanges = [
      this._tableDatabase.dataChange,
      this._filterChange,
      this._paginator.page,
      this._sort.sortChange,
    ];

    return observableMerge(...displayDataChanges).pipe(
      map(() => {
        // Filter data
        this.filteredData = this._tableDatabase.data.slice().filter((item: any) => {
          if (this.field == 'arrears') {
            let f = '';

            f = item['Arrears.days'] || '';

            const searchStr = f.toLowerCase();

            switch (this.filter) {
              case '7days':
                return Number(searchStr) >= 1 && Number(searchStr) <= 7;
              case '14days':
                return Number(searchStr) > 7 && Number(searchStr) <= 14;
              case '30days':
                return Number(searchStr) > 14 && Number(searchStr) <= 30;
              case '60days':
                return Number(searchStr) > 30 && Number(searchStr) <= 60;
              case '90days':
                return Number(searchStr) > 60 && Number(searchStr) <= 90;
              case '90days+':
                return Number(searchStr) > 90;
              default:
                return true;
            }
          } else if (this.field == 'Arrears.days') {
            let f = '';

            f = item[this.field] || '';

            const searchStr = f.toLowerCase();

            switch (this.filter) {
              case '7 days':
                return Number(searchStr) >= 0 && Number(searchStr) <= 7;
              case '14 days':
                return Number(searchStr) > 7 && Number(searchStr) <= 14;
              case '30 days':
                return Number(searchStr) > 14 && Number(searchStr) <= 30;
              case '60 days':
                return Number(searchStr) > 30 && Number(searchStr) <= 60;
              case '90 days':
                return Number(searchStr) > 60 && Number(searchStr) <= 90;
              case '90+ days':
                return Number(searchStr) > 90;
              default:
                return searchStr;
            }
          } else {
            let f = '';

            f = item[this.field] || '';

            const searchStr = f.toLowerCase();
            return searchStr.indexOf(this.filter.toLowerCase()) != -1;
          }
        });
        const data = this.getSortedData(this.filteredData.slice());
        // Grab the page's slice of data.
        const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
        return data.splice(startIndex, this._paginator.pageSize);
      })
    );
  }

  disconnect() {}

  /** Returns a sorted copy of the database data. */
  getSortedData(data: any[]): any[] {
    // const data = data;
    if (!this._sort.active || this._sort.direction == '') {
      return data;
    }

    return data.sort((a, b) => {
      let propertyA: number | string = '';
      let propertyB: number | string = '';

      switch (this._sort.active) {
        case 'Merchant':
          [propertyA, propertyB] = [a['MerchantName'], b['MerchantName']];
          break;
        case 'BorrowerName':
          [propertyA, propertyB] = [a['Customer.FullName'], b['Customer.FullName']];
          break;
        case 'AmountFinanced':
          [propertyA, propertyB] = [a['Amount.Financed'], b['Amount.Financed']];
          break;
        case 'Outstanding':
          [propertyA, propertyB] = [a['Amount.Outstanding'], b['Amount.Outstanding']];
          break;
        case 'ArrearsAmount':
          [propertyA, propertyB] = [a['Arrears.Amount'], b['Arrears.Amount']];
          break;

        case 'ArrearsDays':
          [propertyA, propertyB] = [a['Arrears.days'], b['Arrears.days']];
          break;
      }

      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

      return (valueA < valueB ? -1 : 1) * (this._sort.direction == 'asc' ? 1 : -1);
    });
  }
}
