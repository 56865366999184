<div class="full-width clearfix">
  <div class="full-width disable-hh" *ngIf="healthHistoryActiveReal != true && isFirstLoad == true">
    <div class="full-width clearfix hh-engine-panel">
      <div class="card-header primary-gradient-img clearfix confirmModal disable-modal">
        <div class="row clearfix">
          <div class="row titleArea clearfix">
            <mat-icon class="white pull-left">error</mat-icon>
            <h2 class="summary-header rm-m">You no longer have access to our Health History feature.</h2>
          </div>
        </div>
      </div>

      <div class="hh-engine-panel-inner mt">
        <p class="clearfix small">
          You disabled the health history feature, your {{ "KEYWORD.patient" | translate }} will not be able to update
          their medical history, Click
          on <strong>enable</strong>
          to make use of that feature again
        </p>

        <hr class="mt"/>
        <div class="row clearfix text-center mt">
          <button
            class="btn-large"
            (click)="enableHH()"
            mat-raised-button
            color="accent"
            matTooltip=" Click to Enable Health Hisotry"
          >
            <mat-icon class="mr">check_circle</mat-icon> Enable
          </button>
        </div>
      </div>
    </div>
  </div>

  <mat-card class="card details clearfix">
    <div class="colour-stripe"></div>

    <div class="full-width clearfix">
      <div class="full-width clearfix flex row">
        <div class="full-width clearfix row mr ml">
          <h3 class="sm-h3 sr-title rm-mb rm-pt">Health History</h3>

          <p>
            If you Disable this feature your {{ "KEYWORD.patient" | translate }}s will not be able to update
            your {{"KEYWORD.practice" | translate}} with an accurate health
            history
          </p>

          <mat-slide-toggle class="full-width mr mb" [(ngModel)]="healthHistoryActive" name="healthHistoryActive">
            Enable Health History
          </mat-slide-toggle>
        </div>

        <div class="full-width clearfix row mr ml">
          <h3 class="sm-h3 sr-title rm-mb rm-pt">Notification</h3>

          <p>
            When the health history survey has expired, the system can automatically send a request to
            your {{ "KEYWORD.patient" | translate }} to
            review and update.
          </p>

          <mat-slide-toggle
            class="full-width mr mb"
            [(ngModel)]="healthHistoryAutoUpdate"
            name="healthHistoryAutoUpdate"
          >Enable auto review and update
          </mat-slide-toggle>
        </div>

        <div class="full-width clearfix row mr ml">
          <h3 class="sm-h3 sr-title rm-mb rm-pt">Expiry period</h3>
          <p>Enter the period before the survey is out of date and needs the {{ "KEYWORD.patient" | translate }} to
            update</p>

          <div class="full-width clearfix">
            <p class="mr pull-left">Time between updates (days)</p>
            <mat-form-field class="duration-input pull-left" appearance="outline">
              <input
                class="mat-block"
                [(ngModel)]="healthHistoryUpdateDuration"
                [disabled]="healthHistoryAutoUpdate != true"
                type="number"
                matInput
                name="healthHistoryUpdateDuration"
              />
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="full-width text-center mt">
        <button
          class="button btn-large pull-right"
          (click)="saveHH()"
          mat-raised-button
          color="accent"
          matTooltip=" Click to Enable Health Hisotry"
        >
          <mat-icon class="mr">save</mat-icon> Save
        </button>
      </div>
    </div>
  </mat-card>

  <mat-card class="card details clearfix mt no-box-card">
    <div class="colour-stripe"></div>

    <div class="row clearfix mt mb">
      <div class="row clearfix pull-right ml mb">
        <button
          class="pull-right"
          (click)="viewVideoInfo('Default messages', 'vimeo.com/358964874/19331ac58f')"
          mat-raised-button
          color="primary"
        >
          What's this?
          <mat-icon class="fas fa-info-circle"></mat-icon>
        </button>
      </div>
      <h3 class="sm-h3 sr-title rm-mb rm-pt">Default Health history message</h3>
      <p>
        Create customised messages to send with your Health history invitations. You can use the tools below to create
        as many different messages as you wish, and personalised tags (such as a
        {{ "KEYWORD.patient" | translate }}'s name) can be added using the 'insert personalisation' button.
      </p>
      <hr class="mb"/>

      <app-default-message-list-overview
        class="clearfix mb mt"
        [messageMode]="'default'"
        [isGlobal]="isGlobal"
        [title]="title"
        [purposeCodes]="purposeCodes"
        [purposeCode]="purposeCode"
        [inputMerchantID]="merchantID"
        [hideFilter]="hideFilter"
      >
      </app-default-message-list-overview>
    </div>
  </mat-card>
</div>
