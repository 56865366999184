import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, ViewChildren } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { SessionsService } from '../../../core/session/shared/sessions.service';
import { ContactService } from '../../../feature/contact/shared/contact.service';
import { MerchantTradingHoursOverviewComponent } from '../../../feature/merchant/merchant-trading-hours-overview/merchant-trading-hours-overview.component';
import { MerchantService } from '../../../feature/merchant/shared/merchant.service';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { UtilsService } from '../../../shared/services/utils.service';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';

@Component({
  selector: 'profile-session-over-view',
  templateUrl: './profile-session-over-view.component.html',
  styleUrls: ['./profile-session-over-view.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('*', style({ opacity: 0 }), { optional: true }),
        query(
          '*',
          stagger('10ms', [
            animate(
              '0.2s ease-in',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-10%)', offset: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('*', style({ opacity: 1 }), { optional: true }),
        query(
          '*',
          stagger('10ms', [
            animate(
              '0.2s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-10%)', offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class ProfileSessionOverViewComponent implements OnInit {
  @ViewChildren('sidenav') sidenav: MatSidenav;
  isPromoterOrAdmin = false;
  myPractice;
  profileLink;
  currentUser;
  disableEditPractice;
  settings = 'personal';

  isCreateTradingTime = false;
  merchantTimezone;
  tradingHours;
  util = new UtilsClass();

  navMode = 'side';
  openFlag = true;

  isNewProfilePicture = false;

  isModuleMarketingActive = Settings.global['isModuleMarketingActive'];
  isModuleAppointmentActive = Settings.global['isModuleAppointmentActive'];
  isModulePatientPortalAccessActive = Settings.global['isModulePatientPortalAccessActive'];
  isModulePatientSMSActive = Settings.global['isModulePatientSMSActive'];

  isModulePatientHistoryActive = Settings.global['isModulePatientHistoryActive'];

  practiceLegalName;

  context = Settings.global['context']

  constructor(
    private activeRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private sessionService: SessionsService,
    private contactService: ContactService,
    private merchantService: MerchantService,
    private utilService: UtilsService
  ) {}

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      if (params['settings']) {
        this.settings = params['settings'];
      }
    });

    this.utilService.getCurrentAccess().subscribe((access) => {
      if (access) {
        this.isModuleMarketingActive = access['isModuleMarketingActive'];
        this.isModuleAppointmentActive = access['isModuleAppointmentActive'];
        this.isModulePatientPortalAccessActive = access['isModulePatientPortalAccessActive'];
        this.isModulePatientHistoryActive = access['isModulePatientHistoryActive'];
        this.isModulePatientSMSActive = access['isModulePatientSMSActive'];
      }

      this.authenticationService.isPromoterOrAdmin().subscribe((res3) => {
        this.isPromoterOrAdmin = res3;

        this.setupMyProfile();
        this.setupPracticeProfiel();
      });
    });
    AuthenticationService.updateProfilePicture.subscribe((res) => {
      if (res) {
        this.isNewProfilePicture = !this.isNewProfilePicture;
        this.getProfilePictureTimer();
      }
    });
  }

  setupMyProfile() {
    this.authenticationService.getCurrentUser().subscribe((res2) => {
      if (res2 && res2.success && res2.data.Role && (res2.data.Role == 'admin' || res2.data.Role == 'merchant-admin')) {
        this.disableEditPractice = false;
      }

      if (res2 && res2.success && res2.data.contactKey) {
        this.authenticationService.getCurrentDentist().subscribe((res) => {
          if (res) {
            this.currentUser = res;
          }
        });
        this.getImage();
      }
    });
  }

  getImage() {
    this.profileLink = null;
    this.profileLink = this.authenticationService.getProfilePicStreamLink();
  }

  getProfilePictureTimer() {
    this.profileLink = null;
    let countdown = 0;
    const interval = 1000;
    const duration = 3 * 1000;
    const stream$ = Observable.timer(0, interval)
      .finally(() => {
        this.profileLink = this.authenticationService.getProfilePicStreamLink();
      })
      .takeUntil(Observable.timer(duration + interval))
      .map((value) => duration - value * interval);
    stream$.subscribe((value) => (countdown = value));
  }

  setupPracticeProfiel() {
    this.authenticationService.getCurrentPractice().subscribe((res1) => {
      this.myPractice = res1;

      if (this.myPractice['CardType'] == 'Commercial - Sole-Trader') {
        this.practiceLegalName =
          this.myPractice['Salutation'] + ' ' + this.myPractice['FirstName'] + ' ' + this.myPractice['Name'];
      } else {
        this.practiceLegalName = this.myPractice['Name'];
      }

      this.getTradingHours();
    });
  }

  convertToLowerCase(val) {
    return val.toLowerCase();
  }

  getTradingHours() {
    const payload = {};
    if (this.myPractice['ID'] && this.isPromoterOrAdmin == true) {
      payload['merchantID'] = this.myPractice['ID'];
    }

    this.merchantService.getTradingHourList(payload, this.isPromoterOrAdmin).subscribe((res2) => {
      if (res2.length == 0 || res2.length < 7) {
        this.isCreateTradingTime = true;
      } else {
        this.merchantTimezone = res2[0]['TimeZone'];

        const order = { Monday: 1, Tuesday: 2, Wednesday: 3, Thursday: 4, Friday: 5, Saturday: 6, Sunday: 7 };

        this.tradingHours = res2.sort(function (a, b) {
          return order[a['DayOfWeek.Label.Singular']] - order[b['DayOfWeek.Label.Singular']];
        });
      }
    });
  }

  setupTradingHours() {
    const ref = RootAppComponent.dialog.open(MerchantTradingHoursOverviewComponent, {
      data: {
        merchantID: this.myPractice['ID'],
        isSetup: this.isCreateTradingTime,
      },
      width: '800px',
    });

    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
        this.getTradingHours();
      }
    });
  }

  changeLink() {
    if (this.settings) {
      window.history.replaceState({}, '', `/merchant/(page:dentist-overview/${this.settings})`);
    }
  }
}
