import { AppState } from './app-state.type';

export const selectAppState = (state: AppState) => ({
  practice: state.practice,
  user: state.user,
  sessionType: state.sessionType,
  isPromoterOrAdmin: state.isPromoterOrAdmin,
  dentist: state.dentist,
  publicSettings: state.publicSettings,
  publicModules: state.publicModules,
});
