import * as moment from 'moment-timezone';

export class MarketingUtilClass {
  status = {
    all: [
      'PEND',
      'TIMD',
      // 'ACTV',
      'CMPL',
      'CNCL',
      'ABRT',
      'expired',
    ],
    won: [
      // 'ACTV',
      'TIMD',
      'CMPL',
    ],
    lost: ['CNCL', 'ABRT', 'expired'],
    maybe: ['PEND'],
  };

  templates = {
    all: [
      'Campaign-M-Prospect-Comm1Prospect-introduction',
      'Campaign-M-Prospect-Comm3-Membership-Intro',
      'Campaign-M-Prospect-Comm2-WeListened',
    ],
    Customer: ['Campaign-M-Prospect-Comm1Prospect-introduction', 'Campaign-M-Prospect-Comm2-WeListened'],
    Merchant: ['Campaign-M-Prospect-Comm3-Membership-Intro'],
    MerchantProspect: ['Campaign-M-Prospect-Comm3-Membership-Intro'],
    Prospect: ['Campaign-M-Prospect-Comm1Prospect-introduction', 'Campaign-M-Prospect-Comm2-WeListened'],
  };

  targets = {
    all: ['Customer', 'Merchant', 'MerchantProspect', 'Prospect'],
    promoter: ['Customer', 'Merchant', 'MerchantProspect', 'Prospect'],
    merchant: ['Prospect', 'Customer'],
  };

  constructor() {}

  ngOnInit() {}

  getTargetFromTemplate(template) {
    const tags = template.split('-');
    if (tags.length > 3 && (tags[0] === 'Campaign' || tags[0] === 'campaign')) {
      let target = String(tags[2]).toLocaleLowerCase();
      if (target == 'merchantprospect') {
        return 'MerchantProspect';
      }
      target = target[0].toUpperCase() + target.slice(1);
      return target;
    }
    return null;
  }

  getIconClass(status) {
    // if (status == 'ACTV') {
    //   return "fa-envelope-open-text"
    // }
    if (status == 'TIMD') {
      return 'fa-clock';
    } else if (status == 'PEND') {
      return 'fa-file-invoice';
    } else if (status == 'CMPL') {
      return 'fa-award';
    } else if (status == 'CNCL') {
      return 'fa-minus-circle';
    } else if (status == 'CNCL,ABRT') {
      return 'fa-minus-circle';
    } else if (status == 'TIMD,CMPL') {
      return 'fas fa-clipboard-check';
    } else if (status == 'ABRT') {
      return 'fa-exclamation-circle';
    } else if (status == 'expired') {
      return 'fa-calendar-times';
    }
  }

  getStatusLabel(code) {
    if (!code) {
      return 'undefined';
    }

    const labelObject = {
      PEND: 'Draft',
      // ACTV: 'Active',
      'TIMD,CMPL': 'All Valid',
      TIMD: 'Pending',
      CMPL: 'Complete',
      CNCL: 'Cancelled',
      'CNCL,ABRT': 'Cancelled',
      ABRT: 'Aborted',
      expired: 'Expired',
    };

    if (labelObject[code]) {
      return labelObject[code];
    } else {
      return code;
    }
  }

  getTemplateLabel(code) {
    if (!code) {
      return 'undefined';
    }

    const labelObject = {
      'Campaign-M-Prospect-Comm1Prospect-introduction': 'Introduction',
      'Campaign-M-Prospect-Comm3-Membership-Intro': 'Membership',
      'Campaign-M-Prospect-Comm2-WeListened': 'We Listened',
    };

    if (labelObject[code]) {
      return labelObject[code];
    } else {
      return code;
    }
  }

  getTargetLabel(code) {
    if (!code) {
      return 'undefined';
    }

    const labelObject = {
      all: 'All',
      Customersandprospects: 'All patients',
      CustomersAndProspects: 'All patients',
      'Customer|Prospect': 'All patients',
      'Prospect|Customer': 'All patients',
      Customer: 'Financed with Smile Right',
      Prospect: 'Recieved invitations',
      Merchant: 'Merchants',
      MerchantProspect: 'Merchant Prospects',
    };

    if (labelObject[code]) {
      return labelObject[code];
    } else {
      return code;
    }
  }

  getTimeDifference(time, operation) {
    let result;

    const local = moment();
    const localOffset = local.utcOffset();
    local.tz('Australia/Sydney');
    const centralOffset = local.utcOffset();
    const diff = (centralOffset - localOffset) / 60;

    if (operation == 'sub') {
      result = moment(time).subtract(diff, 'hours').format('YYYY-MM-DD HH:mm');
    } else {
      result = moment(time).add(diff, 'hours').format('YYYY-MM-DD HH:mm');
    }

    return result;
  }

  getSubTag(tag) {
    let result;
    if (tag) {
      const _tag = tag.split('-');

      if (_tag.length > 3) {
        result = _tag.slice(3).join('-');
      }
    }
    return result;
  }

  getMsgStatusLabel(code) {
    if (!code) {
      return 'undefined';
    }

    const labelObject = {
      SENT: 'Sent',
      DLVR: 'Deliverd',
      CLICKD: 'Clicked',
      READ: 'Read',
      BOUNCE: 'Bounced',
      BNCE_H: 'Hard Bounced',
      BNCE_S: 'Soft Bounced',
      FAIL: 'Failed',
      REJ: 'Rejected',
    };

    if (labelObject[code]) {
      return labelObject[code];
    } else {
      return code;
    }
  }
}
