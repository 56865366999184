<mat-card class="list-card">
  <div class="search-table-header">
    <p>
      Archived Settlements:<span class="badge">{{ invitationsDB.data.length }}</span>
    </p>

    <mat-form-field class="full-width" appearance="outline">
      <mat-label>Select search field</mat-label>
      <mat-select [(ngModel)]="selectedField" (ngModelChange)="selectFeild()" placeholder="Select search field">
        <mat-option>None</mat-option>
        <mat-option *ngFor="let item of fields" [value]="item.value">{{ item.field }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="searchable-list" floatPlaceholder="never">
      <mat-label>Type your search here</mat-label>
      <input #filter matInput placeholder="Tap your search here"/>
    </mat-form-field>

    <mat-form-field class="full-width" appearance="outline">
      <mat-label>Select Operator</mat-label>
      <mat-select #filter [(ngModel)]="selectedValue" (change)="filterInvitations()" placeholder="">
        <mat-option [value]="''">Select Operator</mat-option>
        <mat-option *ngFor="let f of filters" [value]="f">{{ f }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <mat-table class="list-table" #table [dataSource]="dataSource" matSort>
    <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

    <!-- ID Column -->
    <ng-container cdkColumnDef="Date">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header> Date</mat-header-cell>
      <mat-cell *cdkCellDef="let row"> {{ row['DateTimeCreated'] | customDate }} </mat-cell>
    </ng-container>

    <!-- Progress Column -->
    <ng-container cdkColumnDef="Operator">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header> Operator</mat-header-cell>
      <mat-cell *cdkCellDef="let row"> {{ row.DentistContactName }}</mat-cell>
    </ng-container>

    <!-- Name Column -->
    <ng-container cdkColumnDef="Customer">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header> Customer</mat-header-cell>
      <mat-cell *cdkCellDef="let row"> {{ row.FirstName }} {{ row.LastName }} </mat-cell>
    </ng-container>

    <!-- Color Column -->
    <ng-container cdkColumnDef="Treatment">
      <mat-header-cell *cdkHeaderCellDef
                       mat-sort-header> {{ "KEYWORD.treatment" | translate | titlecase }} </mat-header-cell>
      <mat-cell *cdkCellDef="let row"> {{ row['Service.Type'] }} </mat-cell>
    </ng-container>

    <!-- Color Column -->
    <ng-container cdkColumnDef="Amount">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header> Amount</mat-header-cell>
      <mat-cell *cdkCellDef="let row"> {{ row['Service.Value'] }} </mat-cell>
    </ng-container>
    <!-- Color Column -->
    <ng-container cdkColumnDef="Product">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header> Product</mat-header-cell>
      <mat-cell *cdkCellDef="let row"> {{ row.ProductName }} </mat-cell>
    </ng-container>
    <!-- Color Column -->
    <ng-container cdkColumnDef="Source">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header> Source</mat-header-cell>
      <mat-cell *cdkCellDef="let row">
        <span *ngIf="row['PatientChoice.Code'] == 'COLD'">Via web</span>
        <span *ngIf="row['PatientChoice.Code'] != 'COLD'">Invitation</span>
      </mat-cell>
    </ng-container>
    <!-- Email Status Column -->
    <ng-container cdkColumnDef="EmailStatus">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header> Email status </mat-header-cell>
      <mat-cell *cdkCellDef="let row" [style.color]="row['Status.Email.Colour']">
        {{ row['Status.Email.Short'] }}
      </mat-cell>
      -->
    </ng-container>
    <!-- Invitation Status Column -->
    <ng-container cdkColumnDef="InvitationStatus">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header> Invitation status</mat-header-cell>
      <mat-cell *cdkCellDef="let row">
        <span class="list-label" [style.background]="row['Status.Invitation.Colour']">{{ row['Status.Invitation.Short']
          }}
        </span></mat-cell>
    </ng-container>
    <ng-container cdkColumnDef="ApplicationStatus">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header> Application Status </mat-header-cell>
      <mat-cell *cdkCellDef="let row">
        <span class="list-label" [style.background]="row['Status.Application.Colour']">{{
          row['Status.Application.Short'] }}
        </span></mat-cell>
    </ng-container>
    <ng-container cdkColumnDef="ContractStatus">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header> Contract Status</mat-header-cell>
      <mat-cell *cdkCellDef="let row">
        <span class="list-label" [style.background]="row['Status.Contract.Colour']">{{
          row['Status.Contract.Short']
          }}</span>
      </mat-cell>
    </ng-container>
    <!-- Response Column -->
    <ng-container cdkColumnDef="Response">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header> Response</mat-header-cell>
      <mat-cell *cdkCellDef="let row"> {{ row['PatientChoice.Label_LongThirdPerson'] }} </mat-cell>
    </ng-container>

    <!-- Action Button Column -->
    <ng-container cdkColumnDef="Actions">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header> Actions</mat-header-cell>
      <mat-cell *cdkCellDef="let row">
        <div style="float: right">
          <button [matMenuTriggerFor]="menu" mat-icon-button>
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button [routerLink]="['/merchant/', { outlets: { page: ['customer-view'] } }]" mat-menu-item>
              <mat-icon>portrait</mat-icon>
              <span>{{ "KEYWORD.patient" | translate | titlecase }} Profile</span>
            </button>
          </mat-menu>
        </div>
      </mat-cell>
    </ng-container>

    <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *cdkRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>

  <mat-paginator #paginator [length]="dataSource.filteredData.length" [pageIndex]="0" [pageSize]="25"
                 [pageSizeOptions]="[5, 10, 25, 100]">
  </mat-paginator>
</mat-card>
