import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSortModule } from '@angular/material/sort';
import { SharedModule } from '../../shared/shared.module';

import { DentistryTypeCreateComponent } from './dentistry-type-create/dentistry-type-create.component';
import { DentistryTypeEditComponent } from './dentistry-type-edit/dentistry-type-edit.component';
import { DentistryTypesListComponent } from './dentistry-types-list/dentistry-types-list.component';
import { DentistryTypesOverviewComponent } from './dentistry-types-overview/dentistry-types-overview.component';
import { DentistryTypesRoutingModule } from './shared/dentistry-types-routing.module';

@NgModule({
  imports: [CommonModule, DentistryTypesRoutingModule, CdkTableModule, SharedModule, MatSortModule],
  declarations: [
    DentistryTypeEditComponent,
    DentistryTypesListComponent,
    DentistryTypeCreateComponent,
    DentistryTypesOverviewComponent,
  ],
  exports: [
    DentistryTypeEditComponent,
    DentistryTypesListComponent,
    DentistryTypeCreateComponent,
    DentistryTypesOverviewComponent,
  ],
  providers: [],
})
export class DentistryTypesModule {}
