import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { DataSource } from '@angular/cdk/table';
import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Optional,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { filter, map as _map } from 'lodash';
import { BehaviorSubject, merge as observableMerge, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EpDocumentViewComponent } from '../../../shared/components/ep-document-view/ep-document-view.component';
import { CustomDatePipe } from '../../../shared/pipes/custom-date.pipe';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';

import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { TreatmentService } from '../../treatment/shared/treatment.service';
import { TreatmentDocumentEditComponent } from '../../treatment/treatment-document-edit/treatment-document-edit.component';

import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { UtilsService } from '../../../shared/services/utils.service';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { treatmentUtil } from '../shared/treatmentUtil';
import { TreatmentBrandViewComponent } from '../treatment-brand-view/treatment-brand-view.component';
import { TreatmentCategoryViewComponent } from '../treatment-category-view/treatment-category-view.component';
import { TreatmentDocumentCreateComponent } from '../treatment-document-create/treatment-document-create.component';
import { TreatmentGroupDocumentList } from '../treatment-group-document-list/treatment-group-document-list.component';
import { TreatmentProductViewComponent } from '../treatment-product-view/treatment-product-view.component';
import { TreatmentTypeViewComponent } from '../treatment-type-view/treatment-type-view.component';

@Component({
  selector: 'app-treatment-document-gallery',
  templateUrl: './treatment-document-gallery.component.html',
  styleUrls: ['./treatment-document-gallery.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.row', style({ opacity: 0 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.5s ease-out',
              keyframes([
                style({
                  opacity: 0,
                  transform: 'translateY(-75%)',
                  offset: 0,
                  height: '0',
                }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({
                  opacity: 1,
                  transform: 'translateY(0)',
                  offset: 1.0,
                  height: '*',
                }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.row', style({ opacity: 1 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.5s ease-in',
              keyframes([
                style({
                  opacity: 1,
                  transform: 'translateY(0)',
                  offset: 0,
                  height: '*',
                }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({
                  opacity: 0,
                  transform: 'translateY(-75%)',
                  offset: 1.0,
                  height: '0',
                }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class TreatmentDocumentGalleryComponent implements OnInit {
  @Input()
  merchantID;

  @Input()
  manufacturerID;

  @Input()
  distributorID;

  @Input()
  supplierID;

  @Input()
  isActive;

  @Input()
  documentTypes;

  @Input()
  categoryID;

  @Input()
  brandID;

  @Input()
  typeID;

  @Input()
  dateFrom;

  @Input()
  dateTo;

  @Input()
  productID;

  @Input()
  title = 'Documents';

  @Input()
  pageSize = Settings.global['listPageSize'] || 20;
  pageSizeOptions = [10, Number(this.pageSize), Number(this.pageSize) * 2, Number(this.pageSize) * 3];

  @Input()
  includeInherited = true;

  @Input() isDownload = true;
  @Input() isRedirection = true;
  @Input() isPrint = true;

  @Output()
  displaySlideToggle = new EventEmitter();

  // @Output()
  // groupFilter: EventEmitter<any> = new EventEmitter<any>();

  // @Input()
  // _groupFilter = "all";

  @Output()
  viewSupplierEvent = new EventEmitter();

  isModal = false;
  typeFilter = 'all';

  @Output()
  closeModal = new EventEmitter();

  @Input()
  displayActiveFilter = true;

  @Input()
  displayedColumns = [
    'selectCheckBox',
    'mediaThumbnail',
    'documentLabel',
    'itemCode',
    'Brand',
    'mediaMedium',
    'documentType',
    // "Category",
    // "Type",
    // "Product",
    // "Date",
    // "MandatoryStatus",
    'ActiveStatus',
    'Actions',
  ];

  isActiveFilter = '0';
  filters = [];
  selectedIDs = [];

  treatmentUtil = new treatmentUtil();
  searchVals = new FormControl();

  // searchValList = ['Document Label', 'Document Type', 'Category', 'Type', 'Brand', 'Product', 'Manufacturer', 'Distributor'];

  searchValList = ['Document Label', 'Document Type', 'Manufacturer', 'Distributor'];

  public listDB: LoadRecords | null;
  dataSource: RecordDataSource | null;

  filteredSize = null;

  isPromoterOrAdmin = false;
  utils = new UtilsClass();

  destroyEvent = new EventEmitter();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('filter', { static: false }) filter: ElementRef;

  constructor(
    private treatmentService: TreatmentService,
    private customDate: CustomDatePipe,
    private utilService: UtilsService,
    private authenticationService: AuthenticationService,
    private dialog: MatDialog,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.supplierID) {
        this.supplierID = data.supplierID;
        this.pageSize = 8;

        this.isModal = true;
      }

      if (data.title) {
        this.title = data.title;
      }
    }
  }

  ngOnInit() {
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;

      if (this.isPromoterOrAdmin == false) {
        this.isDownload = false;
        this.isRedirection = false;
        this.isPrint = false;
      }

      if (this.brandID) {
        const index = this.displayedColumns.indexOf('Brand');

        this.displayedColumns.splice(index, 1);
      }

      if (this.productID) {
        const index = this.displayedColumns.indexOf('Product');

        this.displayedColumns.splice(index, 1);
      }

      // switch (this._groupFilter) {
      //   case "all":
      //     // this.documentTypes = "'SRVPRE,SRVPST,SRVMKT,SRVSTC,SRVSPL'";
      //     break;
      //   case "treatment":
      //     // this.documentTypes = "";
      //     break;
      //   case "patient":
      //     this.documentTypes = "'SRVPRE,SRVPST,SRVMKT,SRVSTC'";
      //     break;
      //   case "dentist":
      //     this.documentTypes = "'SRVSPL'";
      //     break;
      //   case "practice":
      //     // this.documentTypes = "";
      //     break;
      //   case "supplier":
      //     // this.documentTypes = "";
      //     break;
      // }

      const params = {
        productID: this.productID,
        supplierID: this.supplierID,
        documentTypes: this.documentTypes,
        merchantID: this.merchantID,
        isActive: this.isActive,
        manufacturerID: this.manufacturerID,
        distributorID: this.distributorID,
        typeID: this.typeID,
        includeInherited: this.includeInherited,
        categoryID: this.categoryID,
        brandID: this.brandID,
        isPromoterOrAdmin: this.isPromoterOrAdmin,
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
      };
      this.listDB = new LoadRecords(this.treatmentService, this.destroyEvent, this.utilService, params);
      this.dataSource = new RecordDataSource(this.listDB, this.paginator, this.sort);
      this.displaySlideToggle.emit(true);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    // switch (this._groupFilter) {
    //   case "all":
    //     this.documentTypes = "'SRVPRE,SRVPST,SRVMKT,SRVSTC,SRVSPL'";
    //     break;
    //   case "treatment":
    //     this.documentTypes = "";
    //     break;
    //   case "patient":
    //     this.documentTypes = "'SRVPRE,SRVPST,SRVMKT,SRVSTC'";
    //     break;
    //   case "dentist":
    //     this.documentTypes = "'SRVSPL'";
    //     break;
    //   case "practice":
    //     this.documentTypes = "";
    //     break;
    //   case "supplier":
    //     this.documentTypes = "";
    //     break;
    // }

    const params = {
      productID: this.productID,
      supplierID: this.supplierID,
      documentTypes: this.documentTypes,
      includeInherited: this.includeInherited,
      merchantID: this.merchantID,
      isActive: this.isActive,
      manufacturerID: this.manufacturerID,
      distributorID: this.distributorID,
      typeID: this.typeID,
      categoryID: this.categoryID,
      brandID: this.brandID,
      isPromoterOrAdmin: this.isPromoterOrAdmin,
      dateFrom: this.dateFrom,
      dateTo: this.dateTo,
    };
    this.listDB = new LoadRecords(this.treatmentService, this.destroyEvent, this.utilService, params);
    this.dataSource = new RecordDataSource(this.listDB, this.paginator, this.sort);
    this.displaySlideToggle.emit(true);
  }

  closeEvent() {
    this.closeModal.emit(true);
  }

  // change doc group
  // changeDocumentGroup(event: any): void {
  //   if (event === "all") {
  //     event = null;
  //   }
  //   this.groupFilter.emit(event);
  //   this._groupFilter = event;
  // }

  setFilter(event, field) {
    let filter;

    if (event === 'all') {
      event = null;
    }

    if (typeof event == 'object' && event != null) {
      const v = this.customDate.transform(event, Settings.global['dateFormat']);
      filter = {
        field,
        value: v,
      };
    } else if (event) {
      filter = {
        field,
        value: event,
      };
    } else {
      filter = {
        field,
        value: '',
      };
    }

    this.dataSource.filter = filter;
    this.filteredSize = this.dataSource.filteredData.length;
  }

  select(id, isSelected) {
    if (isSelected == true && this.selectedIDs.indexOf(id) == -1) {
      this.selectedIDs.push(id);
    } else {
      const _index = this.selectedIDs.indexOf(id);
      this.selectedIDs.splice(_index, 1);
    }
  }

  unselect() {
    this.selectedIDs = [];
  }

  isSelected(id) {
    if (!this.selectedIDs || this.selectedIDs.length <= 0) {
      return false;
    } else {
      if (this.selectedIDs.indexOf(id) != -1) {
        return true;
      }
    }

    return false;
  }

  enableGroup() {
    if (this.selectedIDs && this.selectedIDs.length > 0) {
      const confirmDialog = new ConfirmDialog(
        'check_circle',
        'Enable Selected Documents',
        '<p>Once the Document is enabled it will  appear as an option for the user.</p>',
        'Cancel',
        'Okay'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirmDialog,
        // SET TO PAYING CASH CARD
      });
      ref.componentInstance.onConfirm.subscribe((val) => {
        if (val == true) {
          ref.close();
          ref.afterClosed().subscribe((res) => {
            const payload = {
              isActive: true,
              merchantID: this.merchantID,
            };
            this.treatmentService
              .editTreatmentDocumentGroup(this.selectedIDs, payload, this.isPromoterOrAdmin)
              .subscribe((res) => {
                if (res && res.length > 0) {
                  for (let i = 0; i < res.length; i++) {
                    res[i].type = this.utils.getDocumentType(res[i]);

                    this.dataSource.replaceItemWithNewID = res[i];
                  }

                  this.selectedIDs = [];

                  NotifyAppComponent.displayToast('success', 'Success!', 'Documents are enabled.');
                }

                ref.close();
              });
          });
        } else {
          ref.close();
        }
      });
    }
  }

  disableGroup() {
    if (this.selectedIDs && this.selectedIDs.length > 0) {
      const confirmDialog = new ConfirmDialog(
        'cancel',
        'Disable Selected Documents',
        '<p>Once the Document is disabled it will not appear as an option for the user.</p>',
        'Cancel',
        'Okay'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirmDialog,
        // SET TO PAYING CASH CARD
      });
      ref.componentInstance.onConfirm.subscribe((val) => {
        if (val == true) {
          ref.close();
          ref.afterClosed().subscribe((res) => {
            const payload = {
              isActive: false,
              userID: this.merchantID,
            };
            this.treatmentService
              .editTreatmentDocumentGroup(this.selectedIDs, payload, this.isPromoterOrAdmin)
              .subscribe((res) => {
                if (res && res.length > 0) {
                  for (let i = 0; i < res.length; i++) {
                    res[i].type = this.utils.getDocumentType(res[i]);

                    this.dataSource.replaceItemWithNewID = res[i];
                  }

                  this.selectedIDs = [];

                  NotifyAppComponent.displayToast('success', 'Success!', 'Documents are disabled.');
                }
                ref.close();
              });
          });
        } else {
          ref.close();
        }
      });
    }
  }

  removeDocumentGroup() {
    if (this.selectedIDs && this.selectedIDs.length > 0) {
      const confirm = new ConfirmDialog(
        'fas fa-info',
        '',
        'Are you sure you want to remove these documents  ?',
        'No',
        'Yes'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation === false) {
          ref.close();
        } else {
          this.treatmentService.deleteTreatmentDocumentGroupGlobal(this.selectedIDs).subscribe((res) => {
            if (res && res.length > 0) {
              for (let i = 0; i < res.length; i++) {
                this.dataSource.removeItem = res[i];
              }

              this.selectedIDs = [];
            }
            ref.close();
          });
        }
      });
    }
  }

  viewDocument(id, title, description) {
    if (id) {
      const ref = this.dialog.open(EpDocumentViewComponent, {
        data: {
          isDownload: this.isDownload,
          isPrint: this.isPrint,
          isRedirection: this.isRedirection,
          documentID: id,
          title,
          description,
        },
        width: '800px',
        panelClass: 'noCard',
      });

      ref.componentInstance.closeModal.subscribe((res) => {
        ref.close();
      });

      ref.componentInstance.noFileFound.subscribe((res) => {
        NotifyAppComponent.displayToast('warning', 'Sorry!', 'We could not get the requested file(s)');
      });
    }
  }

  isNew(date) {
    if (date) {
      const days = this.utils.daysPast(date);

      if (days < 15) {
        return true;
      } else {
        return false;
      }
    }

    return false;
  }

  getDocumentLabel(t) {
    let label = 'Document';

    if (t == 'video') {
      label = 'Video';
    } else if (t == 'link') {
      label = 'External link';
    }

    // else if (t == "image") {
    //   label = "Image"
    // }
    return label;
  }

  addDocument() {
    let _brandID;
    let _productID;

    if (this.brandID) {
      const IDSplit = this.brandID.split(':');
      if (IDSplit && IDSplit.length > 0) {
        _brandID = IDSplit[0];
      }
    }

    if (this.productID) {
      const IDSplit = this.productID.split(':');
      if (IDSplit && IDSplit.length > 0) {
        _productID = IDSplit[0];
      }
    }

    const ref = this.dialog.open(TreatmentDocumentCreateComponent, {
      width: '950px',
      data: {
        brandID: _brandID,
        productID: _productID,
        categoryID: this.categoryID,
        typeID: this.typeID,
        multiCreate: false,
      },
    });

    ref.componentInstance.create.subscribe((res) => {
      if (res) {
        res.type = this.utils.getDocumentType(res);

        if (this.documentTypes) {
          let _types;
          if (typeof this.documentTypes === 'string') {
            _types = this.documentTypes.split(',');
          } else if (Array.isArray(this.documentTypes)) {
            _types = this.documentTypes;
          }

          if (_types && _types.length > 0) {
            if (res && res['Document.Type.Code'] && _types.indexOf(res['Document.Type.Code']) != -1) {
              this.dataSource.addItem = res;

              this.pageSize = this.pageSize + 1;
            }
          } else {
            this.dataSource.addItem = res;

            this.pageSize = this.pageSize + 1;
          }
        } else {
          this.dataSource.addItem = res;

          this.pageSize = this.pageSize + 1;
        }

        ref.close();
      }
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  viewSupplier(id) {
    if (id) {
      this.viewSupplierEvent.emit(id);
    }
  }

  enableDocument(id) {
    if (id) {
      const confirm = new ConfirmDialog(
        'fas fa-info',
        '',
        'Are you sure you want to enable this document  ?',
        'No',
        'Yes'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation === false) {
          ref.close();
        } else {
          const payload = {
            merchantID: this.merchantID,
            isActive: true,
          };

          this.treatmentService.editTreatmentDocument(id, payload, this.isPromoterOrAdmin).subscribe((res) => {
            if (res) {
              res.type = this.utils.getDocumentType(res);

              this.dataSource.replaceItemWithNewID = res;
              ref.close();
            }
          });
        }
      });
    }
  }

  disableDocument(id) {
    if (id) {
      const confirm = new ConfirmDialog(
        'fas fa-info',
        '',
        'Are you sure you want to disable this document  ?',
        'No',
        'Yes'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation === false) {
          ref.close();
        } else {
          const payload = {
            merchantID: this.merchantID,
            isActive: false,
          };

          this.treatmentService.editTreatmentDocument(id, payload, this.isPromoterOrAdmin).subscribe((res) => {
            if (res) {
              res.type = this.utils.getDocumentType(res);

              this.dataSource.replaceItemWithNewID = res;
              ref.close();
            }
          });
        }
      });
    }
  }

  requireDocument(id) {
    if (id) {
      const confirm = new ConfirmDialog(
        'fas fa-info',
        '',
        'Are you sure you want to make this document Mandatory  ?',
        'No',
        'Yes'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation === false) {
          ref.close();
        } else {
          const payload = {
            merchantID: this.merchantID,
            isRequired: true,
          };

          this.treatmentService.editTreatmentDocument(id, payload, this.isPromoterOrAdmin).subscribe((res) => {
            if (res) {
              res.type = this.utils.getDocumentType(res);

              this.dataSource.replaceItemWithNewID = res;
              ref.close();
            }
          });
        }
      });
    }
  }

  notRequiredDocument(id) {
    if (id) {
      const confirm = new ConfirmDialog(
        'fas fa-info',
        '',
        'Are you sure you want to make  this document  Mandatory ?',
        'No',
        'Yes'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation === false) {
          ref.close();
        } else {
          const payload = {
            merchantID: this.merchantID,
            isRequired: false,
          };

          this.treatmentService.editTreatmentDocument(id, payload, this.isPromoterOrAdmin).subscribe((res) => {
            if (res) {
              res.type = this.utils.getDocumentType(res);

              this.dataSource.replaceItemWithNewID = res;
              ref.close();
            }
          });
        }
      });
    }
  }

  removeDocument(id) {
    if (id) {
      const confirm = new ConfirmDialog(
        'fas fa-info',
        '',
        'Are you sure you want to remove this document  ?',
        'No',
        'Yes'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation === false) {
          ref.close();
        } else {
          this.treatmentService.deleteTreatmentDocumentGlobal(id).subscribe((res) => {
            if (res && res.length > 0) {
              for (let i = 0; i < res.length; i++) {
                this.dataSource.removeItem = res[i];
              }
            }
            ref.close();
          });
        }
      });
    }
  }

  viewType(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(TreatmentTypeViewComponent, {
        data: {
          typeID: id,
          isGlobal: true,
        },

        width: '700px',
        panelClass: 'noCard',
      });

      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    }
  }

  viewCategory(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(TreatmentCategoryViewComponent, {
        data: {
          categoryID: id,
        },

        width: '700px',
        panelClass: 'noCard',
      });

      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    }
  }

  viewBrand(id) {
    if (id) {
      const ref = this.dialog.open(TreatmentBrandViewComponent, {
        data: {
          brandID: id,
          merchantID: this.merchantID,
          isGlobal: false,
        },

        width: '800px',
        panelClass: 'noCard',
      });

      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    }
  }

  viewProduct(id) {
    if (id) {
      const ref = this.dialog.open(TreatmentProductViewComponent, {
        data: {
          productID: id,
          merchantID: this.merchantID,
          isGlobal: false,
        },

        width: '800px',
        panelClass: 'noCard',
      });

      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    }
  }

  viewDocumentTreatmentGroup(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(TreatmentGroupDocumentList, {
        data: {
          documentID: id,
          merchantID: this.merchantID,
        },

        width: '700px',
      });

      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    }
  }

  modifyDocument(id) {
    if (id) {
      const ref = this.dialog.open(TreatmentDocumentEditComponent, {
        data: {
          treatmentDocumentID: id,
        },
        width: '950px',
      });

      ref.componentInstance.result.subscribe((res) => {
        if (res) {
          res.type = this.utils.getDocumentType(res);

          this.dataSource.replaceItemWithNewID = res;
          ref.close();
        }
      });

      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    }
  }

  expandProduct(item) {
    this.dataSource.showAllItem = item;
  }

  ngOnDestroy() {
    this.destroyEvent.emit(true);
  }
}

export class LoadRecords implements OnInit {
  public dataChange: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  items: any;
  count: any;

  get data(): any[] {
    return this.dataChange.value;
  }

  firstLoad = false;

  get firstLoadEvent() {
    return this.firstLoad;
  }

  isDestoyed = false;

  util = new UtilsClass();
  serviceRef;

  constructor(
    private treatmentService: TreatmentService,
    private destroyEvent,
    private utilService: UtilsService,
    params
  ) {
    this.destroyEvent.subscribe((res) => {
      this.isDestoyed = res;
    });

    const payload = {
      section: 0,
      includeOmmitted: true,

      orderBy: 'Document.Label',
      fields:
        'ID,Document_key,DateTimeCreated,TreatmentBrand_key,TreatmentType_key,TreatmentProduct_key,TreatmentBrand.Label,TreatmentProduct.Label,Document.Extension,' +
        'Mandatory.Effective,Document.Label,Document.Description,Document.Type.Code,Document.Type.Label,' +
        'Document.URL,Omitted,Distributor.Name,Manufacturer.Name,TreatmentCategory.Label,TreatmentType.Label,TreatmentProduct.ItemCode',
      supplierID: params.supplierID,
      documentTypes: params.documentTypes,
      productID: params.productID,
      includeInherited: params.includeInherited,
      merchantID: params.merchantID,
      isActive: params.isActive,
      manufacturerID: params.manufacturerID,
      distributorID: params.distributorID,
      typeID: params.typeID,
      categoryID: params.categoryID,
      brandID: params.brandID,
      dateFrom: params.dateFrom,
      dateTo: params.dateTo,
    };

    this.serviceRef = this.treatmentService
      .getTreatmentDocumentList(payload, params.isPromoterOrAdmin)
      .subscribe((_res) => {
        const res = _res['data'];
        this.count = res.length;

        this.items = res;

        for (let i = 0; i < this.items.length; i++) {
          this.setDocumentType(i);
          res[i]['fullLabel'] = res[i]['Document.Label'] + ' ' + res[i]['TreatmentProduct.ItemCode'];
        }

        this.dataChange.next(this.items.slice());
        this.firstLoad = true;

        const innerFunction = (section) => {
          section = section + 1;
          UtilsClass.loadingDataSection(section);
          payload.section = section;

          this.serviceRef = this.treatmentService
            .getTreatmentDocumentList(payload, params.isPromoterOrAdmin)
            .subscribe((_res) => {
              const res = _res['data'];
              if (res.length > 0) {
                this.count = res.count;

                const length = this.items.length;

                this.items = this.items.concat(res);

                for (let i = length; i < this.items.length; i++) {
                  this.setDocumentType(i);
                  this.items[i]['fullLabel'] =
                    this.items[i]['Document.Label'] + ' ' + this.items[i]['TreatmentProduct.ItemCode'];
                }

                this.dataChange.next(this.items.slice());

                if (this.isDestoyed != true) {
                  innerFunction(section);
                }
              } else {
                return true;
              }
            });
          UtilsClass.stopLoadingInterceptor();
        };

        if (this.isDestoyed != true) {
          innerFunction(1);
        }
      });
  }

  setThumbnail(i) {
    this.setDocumentType(i);
    this.items[i]['loading'] = true;

    if (this.items[i] && this.items[i]['Document_key']) {
      this.utilService.getEpdocumentThumbnail(this.items[i]['Document_key']).subscribe((res) => {
        if (res) {
          this.items[i]['pic'] = res;
        }

        this.items[i]['loading'] = false;
      });
    }
  }

  setDocumentType(i) {
    let type = this.util.getDocumentType(this.items[i]);

    if (type === 'pdf') {
      type = 'document';
    }

    this.items[i]['type'] = type;
  }

  ngOnInit() {}

  ngOnDestroy() {
    if (this.serviceRef) {
      this.serviceRef.unsubscribe();
    }
  }
}

export class RecordDataSource extends DataSource<any> {
  _filterChange = new BehaviorSubject('');
  field = '';
  expandingItems = [];

  get filter(): any {
    return this._filterChange.value;
  }

  set filter(item: any) {
    this.field = item.field;
    this._filterChange.next(item.value);
  }

  set replaceItemWithNewID(item) {
    let _index = this._tableDatabase.data.findIndex((_obj) => _obj.ID == item.oldID);

    if (_index < 0) {
      _index = this._tableDatabase.data.findIndex((_obj) => _obj.ID == item.ID);
    }

    this._tableDatabase.data[_index] = item;

    this._tableDatabase.dataChange.next(this._tableDatabase.data);
  }

  set replaceItem(item) {
    const _index = this._tableDatabase.data.findIndex((_obj) => _obj.ID == item.ID);
    this._tableDatabase.data[_index] = item;

    this._tableDatabase.dataChange.next(this._tableDatabase.data);
  }

  set addItem(res) {
    this._tableDatabase.data.unshift(res);

    this._tableDatabase.dataChange.next(this._tableDatabase.data);
  }

  set removeItem(id) {
    const data = this._tableDatabase.data.filter((row) => row.ID != id);
    this._tableDatabase.dataChange.next(data);
  }

  set showAllItem(item) {
    this.expandingItems.push(item['Document.Label']);
    this._tableDatabase.dataChange.next(this._tableDatabase.data);
  }

  filteredData: any[] = [];

  constructor(private _tableDatabase: LoadRecords, private _paginator: MatPaginator, private _sort: MatSort) {
    super();
    this._filterChange.subscribe(() => (this._paginator.pageIndex = 0));
  }

  connect(): Observable<any[]> {
    const displayDataChanges = [
      this._tableDatabase.dataChange,
      this._filterChange,
      this._paginator.page,
      this._sort.sortChange,
    ];

    return observableMerge(...displayDataChanges).pipe(
      map(() => {
        let data = this.getMergedData(this._tableDatabase.data.slice());
        data = data.slice().filter((item: any) => {
          let f = '';
          f = item[this.field] || '';

          const searchStr = f.toLowerCase();
          return searchStr.indexOf(this.filter.toLowerCase()) != -1;
        });
        data = this.getSortedData(data.slice());
        this.filteredData = data.slice();

        const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
        return data.splice(startIndex, this._paginator.pageSize);
      })
    );
  }

  disconnect() {}

  getMergedData(data: any[]) {
    const key = 'Document.Label';

    const uniqueData = [...new Map(data.map((item) => [item[key], item])).values()];

    const finalData = [];
    uniqueData.forEach((it) => {
      const l = data.filter((o) => o[key] === it[key]).length;

      const isMerged = !this.expandingItems.includes(it[key]) && l > 1;
      if (isMerged) {
        it.isMerged = true;
        it.mergedLength = l;

        const filteredItems = filter(data, (item) => {
          if (item && item[key] && item[key] == it[key]) {
            return true;
          }

          return false;
        });

        if (filteredItems && filteredItems.length > 0) {
          const fullLabel = _map(filteredItems, (item) => {
            return item['TreatmentProduct.ItemCode'];
          });

          if (fullLabel && fullLabel.length > 0) {
            it['fullLabel'] = it['Document.Label'] + ' ' + fullLabel.join('|');
          }
        }

        finalData.push(it);
      } else {
        it.isMerged = false;

        it['fullLabel'] = it['Document.Label'] + ' ' + it['TreatmentProduct.ItemCode'];
        finalData.push(...data.filter((o) => o[key] === it[key]));
      }
    });

    return finalData;
  }

  getSortedData(data: any[]): any[] {
    if (!this._sort.active || this._sort.direction == '') {
      return data;
    }

    return data.sort((a, b) => {
      let propertyA: number | string = '';
      let propertyB: number | string = '';

      switch (this._sort.active) {
        case 'documentLabel':
          [propertyA, propertyB] = [a['Document.Label'], b['Document.Label']];
          break;
        case 'documentType':
          [propertyA, propertyB] = [a['Document.Type.Label'], b['Document.Type.Label']];
          break;
        case 'supplierName':
          [propertyA, propertyB] = [a['Supplier_Label'], b['Supplier_Label']];
          break;
        case 'Date':
          [propertyA, propertyB] = [new Date(a['DateTimeCreated']).getTime(), new Date(b['DateTimeCreated']).getTime()];
          break;
      }

      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

      return (valueA < valueB ? -1 : 1) * (this._sort.direction == 'asc' ? 1 : -1);
    });
  }
}
