import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { ClipboardService } from 'ngx-clipboard';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ConfirmDialogSingleButton } from '../../types/confirm-dialog-single-button';
import { NotifyAppComponent } from '../../types/notify-app-component';
import { Settings } from '../../types/settings';
import { UtilsClass } from '../../types/utils/utils.class';
import { ConfirmDialogSingleButtonComponent } from '../confirm-dialog-single-button/confirm-dialog-single-button.component';
import { CodeHTMLViewComponent } from '../html-view/html-view.component';
import { RootAppComponent } from '../root-component/root-component.component';

@Component({
  selector: 'app-html-iframe-input',
  templateUrl: './html-iframe-input.component.html',
  styleUrls: ['./html-iframe-input.component.css'],
})
export class HtmlIframeInputComponent implements OnInit {
  @Input()
  inputType = 'appointment';

  @Input()
  title;

  @Input()
  link;

  @Input()
  description = 'How do you want to proceed?';
  isMerchant = false;

  merchantID;

  @Input()
  isCopy = true;

  @Input()
  isPreview = true;
  @Input()
  integrationType = 'popup';

  @Input()
  isInsert = true;
  componentID = 'ID' + btoa(Math.random().toString()).substring(2, 20);
  isModal = true;
  @Output()
  close = new EventEmitter();
  @Output()
  getResult = new EventEmitter();
  @Output()
  getStyle = new EventEmitter();

  @Output()
  getContent = new EventEmitter();

  eventContent;
  editorOptionsCss = {
    fontSize: 12,
    formatOnType: true,
    formatOnPaste: true,
    language: 'css',
    autoIndent: true,
    autoClosingBrackets: 'always',
    dragAndDrop: true,
    fixedOverflowWidgets: true,
    theme: 'vs-dark',
    automaticLayout: true,
  };
  options = {
    displaySlider: true,
    TextfontSize: '1.5rem',
    TextColor: '#000000',
    step1Text: 'Step 1: What is the approximate cost of your  treatment?',
    step2Text: 'Step 2: Choose a payment option that  suits you best.',
    amount: 2100,
    headerBackground: '#1b8bdd',
    title: 'What are my options?',
    description: 'Find the payment option that suits your budget',

    headerColor: '#1b8bdd',
    addBackground: false,
    background: '#ffffff',
  };

  isLargeButton = true;
  buttonStyle;
  buttonColor = '#ffffff';
  buttonFontSize = '1.1rem';
  buttonBackground = Settings.global['primaryColor'];
  iframeStyle;

  buttonText;

  linkText;
  linkColor = '#1b8bdd';
  linkFontSize = '1rem';

  settings = Settings.global;
  iframeLink;
  buttonFontSizerem = [
    '0.5rem',
    '0.6rem',
    '0.7rem',
    '0.8rem',
    '0.9rem',
    '1rem',
    '1.1rem',
    '1.2rem',
    '1.3rem',
    '1.4rem',
    '1.5rem',
    '1.6rem',
    '1.7rem',
    '1.8rem',
    '1.9rem',
    '2rem',
    '2.1rem',
    '2.2rem',
    '2.3rem',
    '2.4rem',
    '2.5rem',
    '2.6rem',
    '2.7rem',
    '2.8rem',
    '2.9rem',
    '3rem',
    '3.1rem',
    '3.2rem',
  ];

  buttonFontSizepx = [
    '8px',
    '9px',
    '10px',
    '11px',
    '12px',
    '13px',
    '14px',
    '15px',
    '16px',
    '17px',
    '18px',
    '19px',
    '20px',
    '21px',
    '22px',
    '23px',
    '24px',
    '25px',
    '26px',
    '27px',
    '28px',
    '29px',
    '30px',
  ];

  style;
  content;
  preview;

  utils = new UtilsClass();

  isPromoterOrAdmin = false;
  merchants = [];
  icon = 'fas fa-calculator-alt';
  iframeHeight;
  iframeWidth;
  popupHeight;
  popupWidth;
  popupTop;
  popupLeft;

  constructor(
    private authenticationService: AuthenticationService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private _clipboardService: ClipboardService
  ) {
    if (data) {
      if (data.title) {
        this.title = data.title;
      }

      if (data.inputType !== null) {
        this.inputType = data.inputType;
      }

      if (data.isMerchant !== null) {
        this.isMerchant = data.isMerchant;
      }

      if (data.content) {
        this.content = data.content;
      }

      if (data.integrationType !== null) {
        this.integrationType = data.integrationType;
      }

      if (data.link) {
        this.link = data.link;
      }
      if (data.isCopy !== null) {
        this.isCopy = data.isCopy;
      }
      if (data.isPreview !== null) {
        this.isPreview = data.isPreview;
      }

      if (data.isInsert !== null) {
        this.isInsert = data.isInsert;
      }
    }
  }

  ngOnInit() {
    if (this.isMerchant === true) {
      this.authenticationService.isPromoterOrAdmin().subscribe((r) => {
        this.isPromoterOrAdmin = r;

        this.authenticationService.getCurrentPractice().subscribe((res) => {
          if (res && res.ID) {
            this.merchantID = res.ID;
          }

          if (this.isPromoterOrAdmin == true) {
            this.authenticationService.getMerchantList({ fields: 'ID,TradingAs' }).subscribe((merchants) => {
              if (merchants) {
                this.merchants = merchants;
              }
            });
          }
        });
      });
    }

    this.setup();
  }

  setup() {
    if (this.inputType === 'calculator') {
      this.title = 'Product Calculator';
      this.description = 'Customize the integration of  the product Calculator';
      this.icon = 'fas fa-calculator';
      this.buttonText = 'Calculate My Repayments';

      this.iframeHeight = '1430px';
      this.iframeWidth = '100%';
      this.popupHeight = 800;
      this.popupWidth = 1000;

      this.popupTop = 100;
      this.popupLeft = 100;

      this.linkText = 'Calculate My Repayments';
    } else if (this.inputType === 'contact') {
      this.title = 'Contact Us';
      this.description = 'Customize the integration of  the contact us panel';
      this.icon = 'fas fa-envelope';
      this.buttonText = 'Contact Us';

      this.iframeHeight = '650px';
      this.iframeWidth = '750px';
      this.popupHeight = 650;
      this.popupWidth = 750;

      this.popupTop = 100;
      this.popupLeft = 100;

      this.linkText = 'Contact Us';

      this.options.title = 'Contact Us';
      this.options.description = 'Use the form below to get in touch with us';
    } else if (this.inputType === 'appointment') {
      this.title = 'Appointment Request';
      this.description = 'Customize the integration of  the appoinement request';
      this.icon = 'fas fa-calendar';
      this.buttonText = 'Request An Appointment';

      this.iframeHeight = '800px';
      this.iframeWidth = '100%';
      this.popupHeight = 800;
      this.popupWidth = 750;

      this.popupTop = 100;
      this.popupLeft = 100;

      this.linkText = 'Request An Appointment';

      this.options.title = 'Request An Appointment ';
      this.options.description = 'Let us know what time might suit you best for an appointment.';
    } else if (this.inputType === 'investment') {
    }
  }

  buildIframeLink() {
    let link = '';

    if (this.merchantID) {
      link = link + 'merchantID=' + this.merchantID;
    } else {
      link = link + 'merchantID=null';
    }
    if (this.inputType === 'calculator') {
      if (this.options.displaySlider === true) {
        if (this.options.step1Text) {
          link = link + `&step1=${this.options.step1Text.replace(/&/g, '++')}`;
        }

        if (this.options.step2Text) {
          link = link + `&step2=${this.options.step2Text.replace(/&/g, '++')}`;
        }

        if (this.options.addBackground == true) {
          link = link + `&background=${this.options.background}`;
        }

        let style = null;

        if (this.options.TextColor) {
          if (!style) {
            style = '';
          }

          style = style + `color:${this.options.TextColor};`;
        }

        if (this.options.TextfontSize) {
          if (!style) {
            style = '';
          }

          style = style + `font-size:${this.options.TextfontSize};`;
        }

        if (style) {
          link = link + `&style=${style}`;
        }
      } else {
        link = link + '&slider=false';

        if (this.options.amount) {
          link = link + `&amount=${this.options.amount}`;
        }
      }

      if (this.options.headerColor) {
        link = link + `&headerColor=${this.options.headerColor}`;
      }
      if (this.integrationType === 'embeded') {
        if (this.options.description) {
          link = link + `&description=${this.options.description.replace(/&/g, '++')}`;
        }
      } else if (this.integrationType === 'popup') {
        if (this.options.title) {
          link = link + `&title=${this.options.title.replace(/&/g, '++')}`;
        }

        if (this.options.description) {
          link = link + `&description=${this.options.description.replace(/&/g, '++')}`;
        }

        link = link + '&isModal=true';
        link = link + '&isCloseWindow=true';
      } else if (this.integrationType === 'link') {
        if (this.options.title) {
          link = link + `&title=${this.options.title.replace(/&/g, '++')}`;
        }

        if (this.options.description) {
          link = link + `&description=${this.options.description.replace(/&/g, '++')}`;
        }

        link = link + '&isModal=false';
        link = link + '&isCloseWindow=false';
        link = link + '&standAlone=true';
      }

      link = link.replace(/\#/g, '{}{}');

      link = link.replace(/\?/g, '**');
      if (this.integrationType === 'link') {
        this.iframeLink =
          this.settings['publicSiteFrontendLink'] + `/consumer/(page:integration/${this.inputType})?` + link;
      } else {
        this.iframeLink = this.settings['publicSiteFrontendLink'] + `/integration/${this.inputType}?` + link;
      }
    } else if (this.inputType == 'contact' || this.inputType == 'appointment') {
      if (this.options.addBackground == true) {
        link = link + `&background=${this.options.background}`;
      }

      if (this.options.headerColor) {
        link = link + `&headerColor=${this.options.headerColor}`;
      }
      if (this.integrationType === 'embeded') {
        link = link + '&integration=true';
      } else if (this.integrationType === 'popup') {
        if (this.options.title) {
          link = link + `&title=${this.options.title.replace(/&/g, '++')}`;
        }

        if (this.options.description) {
          link = link + `&description=${this.options.description.replace(/&/g, '++')}`;
        }

        link = link + '&isModal=true';
        link = link + '&isCloseWindow=true';
      } else if (this.integrationType === 'link') {
        if (this.options.title) {
          link = link + `&title=${this.options.title.replace(/&/g, '++')}`;
        }

        if (this.options.description) {
          link = link + `&description=${this.options.description.replace(/&/g, '++')}`;
        }

        link = link + '&isModal=false';
        link = link + '&isCloseWindow=false';
        link = link + '&standAlone=true';
      }

      link = link.replace(/\#/g, '{}{}');

      link = link.replace(/\?/g, '**');
      if (this.integrationType === 'link') {
        this.iframeLink =
          this.settings['publicSiteFrontendLink'] + `/consumer/(page:integration/${this.inputType})?` + link;
      } else {
        this.iframeLink = this.settings['publicSiteFrontendLink'] + `/integration/${this.inputType}?` + link;
      }
    }
  }

  buildResult() {
    this.buildIframeLink();
    if (this.integrationType === 'popup') {
      let inlineStyle = `color: #ffffff;background: #1e88e5; font-size: 1.1rem;
      box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
      box-sizing: border-box; position: relative;  -webkit-user-select: none; -moz-user-select: none;
      -ms-user-select: none; user-select: none; cursor: pointer; outline: 0;
      border: none;-webkit-tap-highlight-color: transparent;  display: inline-block;
      white-space: nowrap;text-decoration: none;    vertical-align: baseline;
      text-align: center;margin: 0; min-width: 64px ; line-height: 36px; overflow: visible;
      transform: translate3d(0,0,0);
      transition: background .4s cubic-bezier(.25,.8,.25,1),box-shadow 280ms cubic-bezier(.4,0,.2,1);
      text-rendering: auto;  appearance: auto;   writing-mode: horizontal-tb;
      word-spacing: normal;   text-indent: 0px;  text-shadow: none;border-radius: 4px;
      align-items: flex-start;
      `;

      this.style = `/* START: ${this.title} Button*/\n\n#${this.componentID}{\n
        box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%) !important;
        box-sizing: border-box !important;
        position: relative !important;
        -webkit-user-select: none !important;
        -moz-user-select: none !important;
        -ms-user-select: none !important;
        user-select: none !important;
        cursor: pointer !important;
        outline: 0 !important;
        border: none !important;
        -webkit-tap-highlight-color: transparent !important;
        display: inline-block !important;
        white-space: nowrap !important;
        text-decoration: none !important;
        vertical-align: baseline !important;
        text-align: center !important;
        margin: 0 !important;
        min-width: 64px !important;
        line-height: 36px !important;
        overflow: visible !important;
        transform: translate3d(0,0,0) !important;
        transition: background .4s cubic-bezier(.25,.8,.25,1),box-shadow 280ms cubic-bezier(.4,0,.2,1) !important;
        text-rendering: auto !important;
        appearance: auto !important;
        writing-mode: horizontal-tb !important;
        word-spacing: normal !important;
        text-indent: 0px !important;
        text-shadow: none !important;
        border-radius: 4px  !important;
        align-items: flex-start !important;\n`;

      if (this.isLargeButton == true) {
        this.style =
          this.style +
          `letter-spacing: 0.5px !important;
        padding: 8px 20px !important;
        text-transform: capitalize !important;\n`;

        inlineStyle = inlineStyle + `letter-spacing: 0.5px;  padding: 8px 20px;text-transform: capitalize;`;
      }

      if (this.buttonBackground) {
        this.style = this.style + `background:${this.buttonBackground} !important;\n`;
        inlineStyle = inlineStyle + `background:${this.buttonBackground};`;
      } else {
        this.style = this.style + `background:#1e88e5 !important;\n`;
        inlineStyle = inlineStyle + `background:#1e88e5;`;
      }
      if (this.buttonColor) {
        this.style = this.style + `color:${this.buttonColor} !important;\n`;
        inlineStyle = inlineStyle + `color:${this.buttonColor} ;`;
      } else {
        this.style = this.style + `color:#ffffff  !important;\n`;
        inlineStyle = inlineStyle + `color:#ffffff  ;`;
      }
      if (this.buttonFontSize) {
        this.style = this.style + `font-size:${this.buttonFontSize} !important;\n`;
        inlineStyle = inlineStyle + `font-size:${this.buttonFontSize} ;`;
      } else {
        this.style = this.style + `font-size: 1.1rem !important;\n`;
        inlineStyle = inlineStyle + `font-size: 1.1rem ;`;
      }

      this.style = this.style + `}\n/* END: ${this.title} Button*/\n\n`;

      let link = this.iframeLink;

      if (link.indexOf('http') === -1) {
        link = 'https://' + link;
      }

      this.eventContent = `onclick='window.open("${link}", "${this.title}","directories=no,titlebar=no,toolbar=no,location=no,status=no,resizable=no,menubar=no,scrollbars=no,popup=yes,width=${this.popupWidth},height=${this.popupHeight},top=${this.popupTop},left=${this.popupLeft}");'`;
      this.content = `<!--START: ${this.title} Button-->\n<button  id="${this.componentID}" style="${inlineStyle}"
        ${this.eventContent}

      >${this.buttonText}</button>\n<!--END:  ${this.title} Button-->`;

      this.preview = this.utils.getPreviewHtmlContent(this.content, this.style);
    } else if (this.integrationType === 'link') {
      let inlineStyle = ` cursor: pointer;  text-decoration: underline;   display: block ;`;

      this.style = `/* START: ${this.title} Link*/\n\n#${this.componentID}{\n`;

      if (this.linkColor) {
        inlineStyle = inlineStyle + `color:${this.linkColor} ;`;
      } else {
        inlineStyle = inlineStyle + `color: #1b8bdd ;`;
      }
      if (this.linkFontSize) {
        inlineStyle = inlineStyle + `font-size:${this.linkFontSize};`;
      } else {
        inlineStyle = inlineStyle + `font-size: 1rem ;`;
      }

      this.style =
        this.style +
        `\n}\n\n#${this.componentID}:hover{\n
        text-decoration: none  !important;
      }`;

      this.style = this.style + `\n/* END: ${this.title} Link*/\n\n`;

      this.content = `<!--START: ${this.title} Link-->\n<a  id="${this.componentID}"
      href="${this.iframeLink}" target="_blank"
      >${this.linkText}</a>\n<!--END:  ${this.title} Link-->`;

      this.preview = this.utils.getPreviewHtmlContent(this.content, this.style);
    } else {
      let height = this.iframeHeight;
      if (this.options.displaySlider !== true) {
        height = '1150px';
      }

      const inlineStyle = `width: ${this.iframeWidth};height: ${height};margin: 0 auto;border: none;overflow:hidden ; `;

      this.style = `/* START: ${this.title} Iframe*/\n\n#${this.componentID} {\n
        width:100% !important
      }\n`;
      this.style =
        this.style +
        `#${this.componentID} iframe {\n
          width: ${this.iframeWidth} !important;
          height: ${height} !important;
          margin: 0 auto !important;
          border: none !important;
          overflow:hidden !important;
      }\n/* END: ${this.title} Iframe*/\n\n`;

      this.content = `<!--START: ${this.title} Iframe-->\n<div  id="${this.componentID}" style="width:100%" ><iframe   style="${inlineStyle}"
            src="${this.iframeLink}" title="${this.title}">
        </iframe></div>\n<!--END:  ${this.title} Iframe-->`;

      this.preview = this.utils.getPreviewHtmlContent(this.content, this.style);
    }
  }

  previewBuild() {
    this.buildResult();

    const ref2 = RootAppComponent.dialog.open(CodeHTMLViewComponent, {
      data: {
        icons: 'fas fa-eye',
        title: 'preview',
        content: this.preview,
        style: this.style,
        isPreview: true,
        isCode: true,
      },
      width: '1000px',
    });
    ref2.componentInstance.onConfirm.subscribe((res) => {
      ref2.close();
    });
  }

  Copy(d) {
    if (d) {
      this._clipboardService.copy(d);

      NotifyAppComponent.displayToast('success', 'success', 'Copied');
    }
  }

  inserResult(isCopy = false) {
    this.buildResult();

    const p = {
      style: this.style,
      content: this.content,
      isCopy,
      isReplace: false,
    };

    if (isCopy == true) {
      this.Copy(p.content);
    }

    this.getResult.emit(p);
    this.getStyle.emit(p.style);
    this.getContent.emit(p.content);
  }

  copyClickEvent() {
    this.buildResult();

    if (this.eventContent) {
      const show = localStorage.getItem('dontShowClickPopupEvent');

      if (show === 'true') {
        const p = {
          content: this.eventContent,
          isCopy: true,
          isReplace: false,
        };

        this.Copy(p.content);

        this.getResult.emit(p);
        this.getStyle.emit(null);
        this.getContent.emit(null);
      } else {
        const confirmDialog2 = new ConfirmDialogSingleButton(
          'fas fa-copy',
          'Click Event',
          "<p style='text-align:center;'>The <strong>onClick</strong> Event will be copied to your clipboard , you can add it  to <strong> 'a' </strong> or <strong>'button'</strong> Tag.</p>",
          'Proceed',
          true
        );
        const ref2 = RootAppComponent.dialog.open(ConfirmDialogSingleButtonComponent, {
          data: confirmDialog2,
          width: '500px',
        });
        ref2.componentInstance.onClick.subscribe((res) => {
          if (res === true) {
            localStorage.setItem('dontShowClickPopupEvent', 'true');
          }

          ref2.close();
          ref2.afterClosed().subscribe(() => {
            const p = {
              content: this.eventContent,
              isCopy: true,
              isReplace: false,
            };

            this.Copy(p.content);

            this.getResult.emit(p);
            this.getStyle.emit(null);
            this.getContent.emit(null);
          });
        });
      }
    }
  }

  closeEvent() {
    this.close.emit(true);
  }
}
