import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'environments/environment';
import * as mime from 'mime-types';
import { ClipboardService } from 'ngx-clipboard';
import { NotifyAppComponent } from '../../types/notify-app-component';
import { UtilsClass } from '../../types/utils/utils.class';

@Component({
  selector: 'app-iframe-view',
  templateUrl: './iframe-view.component.html',
  styleUrls: ['./iframe-view.component.css'],
  animations: [
    trigger('simpleFadeAnimation', [
      state('in', style({ opacity: 1, height: '*' })),
      transition(':enter', [style({ opacity: 0, height: 0 }), animate(300)]),
      transition(':leave', animate(300, style({ opacity: 0, height: 0 }))),
    ]),
  ],
})
export class IframeViewComponent implements OnInit {
  close = new EventEmitter();
  iframeSrc;
  link;
  background;
  title;
  isFile = false;
  isDownload = false;
  isAction = false;
  isOpenNewTab = false;
  getSubmit = new EventEmitter();
  isSubmit = false;
  isCopy = true;
  SubmitLabel = 'Submit';
  actionLabel = 'Create';
  extension;
  documentType;
  mimeDocumentType;
  extraDetails = true;
  pinchZoomProperties = [
    {
      wheel: true,
      autoHeight: true,
    },
  ];
  utils = new UtilsClass();
  getAction = new EventEmitter();

  constructor(
    private sanitizer: DomSanitizer,
    private _clipboardService: ClipboardService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data && data.link) {
      this.link = data.link;
    }
    if (data && data.title) {
      this.title = data.title;
    }
    if (data && data.background) {
      this.background = data.background;
    }
    if (data && data.SubmitLabel) {
      this.SubmitLabel = data.SubmitLabel;
    }
    if (data && data.isOpenNewTab != null) {
      this.isOpenNewTab = data.isOpenNewTab;
    }

    if (data && data.isDownload != null) {
      this.isDownload = data.isDownload;
    }
    if (data && data.isAction != null) {
      this.isAction = data.isAction;
    }
    if (data && data.isAction != null) {
      this.isAction = data.isAction;
    }

    if (data && data.extraDetails != null) {
      this.extraDetails = data.extraDetails;
    }

    if (data && data.actionLabel != null) {
      this.actionLabel = data.actionLabel;
    }

    if (data && data.isSubmit != null) {
      this.isSubmit = data.isSubmit;
    }
    if (data && data.isCopy != null) {
      this.isCopy = data.isCopy;
    }
    if (data && data.extension) {
      this.extension = data.extension;
    }
  }

  ngOnInit() {
    if (this.extension) {
      this.documentType = this.utils.getDocumentTypeFromExtension(this.extension);

      if (this.extension) {
        this.mimeDocumentType = mime.lookup(this.extension);
      }

      if (this.documentType) {
        this.isFile = true;
      }
    }
    if (this.link) {
      this.iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.link);
    }
  }

  closeEvent() {
    this.close.emit(true);
  }

  preview() {
    if (this.link) {
      window.open(this.link, '_blank');
    }
  }

  submitEvent() {
    this.getSubmit.emit(true);
  }

  copy() {
    if (this.link) {
      this._clipboardService.copy(this.link);
      NotifyAppComponent.displayToast('success', 'Success', 'Copied');
    }
  }

  downloadFile() {
    UtilsClass.startLoadingForce();
    if (this.extension && this.link) {
      const fileName = 'document.' + this.extension;

      // Create XMLHTTP Request.
      const req = new XMLHttpRequest();

      let __link = this.link;

      __link = __link.replace(/\//g, '$');
      __link = __link.replace('?', '!!');

      if (__link) {
        const d = environment.nodeUrl + '/files/out-stream-raw/' + __link;
        req.open('GET', d, true);
        req.responseType = 'blob';
        req.onload = function () {
          // Convert the Byte Data to BLOB object.
          const blob = new Blob([req.response], {
            type: 'application/octetstream',
          });

          const url = window.URL;
          const link = url.createObjectURL(blob);
          const a = document.createElement('a');
          a.setAttribute('download', fileName);
          a.setAttribute('target', '_black');
          a.setAttribute('href', link);
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          UtilsClass.stopLoadingForce();
        };
      }
      req.onerror = function () {
        UtilsClass.stopLoadingForce();
      };
      req.send();
    } else {
      UtilsClass.stopLoadingForce();
    }
  }

  getActionEvent() {
    this.getAction.emit(this.link);
  }
}
