import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';

import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { MatPaginator } from '@angular/material/paginator';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';

import { MatTableDataSource } from '@angular/material';
import { MerchantService } from '../../merchant/shared/merchant.service';
import { MerchantServiceGroupCreateComponent } from '../merchant-service-group-create/merchant-service-group-create.component';
import { MerchantServiceGroupEditComponent } from '../merchant-service-group-edit/merchant-service-group-edit.component';

@Component({
  selector: 'app-merchant-service-group-list',
  templateUrl: './merchant-service-group-list.component.html',
  styleUrls: ['./merchant-service-group-list.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.row', style({ opacity: 0 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.row', style({ opacity: 1 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class MerchantServiceGroupListComponent implements OnInit {
  @Input()
  pageSize = Settings.global['listPageSize'] || 20;
  pageSizeOptions = [10, Number(this.pageSize), Number(this.pageSize) * 2, Number(this.pageSize) * 3];

  displayedColumns = ['Label', 'Description', 'Actions'];

  merchantServicesGroup = [];

  datasource: MatTableDataSource<any>;

  firstLoadEvent = false;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('filter', { static: false }) filter: ElementRef;

  constructor(private merchantService: MerchantService) {}

  ngOnInit() {
    this.merchantService.getMerchantServicesGroupList({}).subscribe((data) => {
      this.firstLoadEvent = true;
      this.merchantServicesGroup = data.sort((a, b) => (a.Label > b.Label ? 1 : -1));
      this.datasource = new MatTableDataSource(this.merchantServicesGroup);
      this.datasource.paginator = this.paginator;
    });
  }

  openCreateMerchantServiceGroupDialog() {
    const ref = RootAppComponent.dialog.open(MerchantServiceGroupCreateComponent, { width: '600px' });

    ref.componentInstance.create.subscribe((merchantCreateServiceGroup) => {
      this.merchantService.createMerchantServicesGroup(merchantCreateServiceGroup).subscribe((result) => {
        if (result) {
          this.merchantServicesGroup.push(...result);

          const merchantServicesGroupSorted = this.merchantServicesGroup.sort((a, b) => (a.Label > b.Label ? 1 : -1));

          this.datasource.data = [...merchantServicesGroupSorted];

          NotifyAppComponent.displayToast(
            'success',
            'Successful operation',
            'The service group has been successfully created'
          );

          ref.close();
        }
      });
    });
  }

  openEditMerchantServiceGroupDialog(merchantServicesGroup) {
    const ref = RootAppComponent.dialog.open(MerchantServiceGroupEditComponent, {
      data: {
        ID: merchantServicesGroup.ID,
        Label: merchantServicesGroup.Label,
        Description: merchantServicesGroup.Description,
      },
      width: '600px',
    });

    ref.componentInstance.update.subscribe((merchantCreateServiceGroup) => {
      this.merchantService.updateMerchantServicesGroup(merchantCreateServiceGroup).subscribe((result) => {
        if (result) {
          const updatedIndex = this.merchantServicesGroup.findIndex(
            (oldMerchantServiceGroup) => oldMerchantServiceGroup.ID === merchantCreateServiceGroup.ID
          );

          if (updatedIndex > -1) {
            this.merchantServicesGroup[updatedIndex] = merchantCreateServiceGroup;

            const merchantServicesGroupSorted = this.merchantServicesGroup.sort((a, b) => (a.Label > b.Label ? 1 : -1));

            this.datasource.data = [...merchantServicesGroupSorted];
          }

          NotifyAppComponent.displayToast(
            'success',
            'Successful operation',
            'The service group has been successfully edited'
          );

          ref.close();
        }
      });
    });
  }

  openDeleteMerchantServiceGroupDialog(id) {
    const confirm = new ConfirmDialog('delete', '', 'Are you sure you want to delete this service group?', 'No', 'Yes');

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, { data: confirm });

    ref.componentInstance.onConfirm.subscribe((val) => {
      if (val == true) {
        this.merchantService.deleteMerchantServicesGroup(id).subscribe((result) => {
          if (result) {
            const updatedIndex = this.merchantServicesGroup.findIndex(
              (oldMerchantServiceGroup) => oldMerchantServiceGroup.ID === id
            );

            if (updatedIndex > -1) {
              this.merchantServicesGroup.splice(updatedIndex, 1);

              this.datasource.data = [...this.merchantServicesGroup];
            }

            NotifyAppComponent.displayToast(
              'success',
              'Successful operation',
              'The service group has been successfully deleted'
            );

            ref.close();
          }
        });
      } else {
        ref.close();
      }
    });
  }
}
