import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { LookupService } from '../../../shared/services/lookup.service';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { CustomerProspectService } from '../shared/customerProspect.service';

@Component({
  selector: 'app-patient-healthcare',
  templateUrl: './patient-healthcare-create.component.html',
  styleUrls: ['./patient-healthcare-create.component.css'],
})
export class PatientHealthcareComponent implements OnInit {
  @Input()
  recordID;

  @Input()
  ownerID;

  @Input()
  type;

  @Output()
  close = new EventEmitter();

  @Output()
  sendResult = new EventEmitter();

  @Output()
  issuerViewValue = new EventEmitter();

  cardObject = {
    cardNumber: null,
    referenceNumber: null,
    expireDate: null,
    issuerCode: null,
  };

  cardFirstName;
  cardMiddleName;
  cardLastName;

  title = 'HealthCare';

  isModal = false;
  config = {
    format: 'MMMM, YYYY',
    monthFormat: 'MMMM, YYYY',
  };

  healthfunds = [];
  fundCode;
  sessionType = 'guest';

  constructor(
    private authenticationService: AuthenticationService,
    private lookupService: LookupService,
    private customerProspectService: CustomerProspectService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.ownerID) {
        this.ownerID = data.ownerID;
      }
      if (data.type) {
        this.type = data.type;
      }

      if (data.recordID) {
        this.recordID = data.recordID;
      }

      this.isModal = true;
    }
  }

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((sessionType) => {
      if (sessionType) {
        this.sessionType = sessionType;
      }

      this.lookupService.getLookup('CodeLookup', 'PrivateInsurance').subscribe((res) => {
        this.healthfunds = res;
      });

      if (this.type && this.type == 'AU_IDM') {
        this.title = 'medicare';

        this.config['min'] = moment();
      } else if (this.type && this.type == 'AU_PHF') {
        this.title = 'private health fund';
      }

      if (this.ownerID) {
        const payload = {
          fields: 'FirstName,MiddleName,Name',
        };
        this.customerProspectService
          .getCustomerProspectDetails(this.ownerID, payload, this.sessionType)
          .subscribe((res) => {
            if (res) {
              this.cardFirstName = res['FirstName'];
              this.cardMiddleName = res['MiddleName'];
              this.cardLastName = res['Name'];
            }
          });
      }
    });
  }

  closeEvent() {
    this.close.emit(true);
  }

  create() {
    if (this.recordID) {
      const payload = {
        ownerID: this.ownerID,
        typeCode: this.type,
        cardFirstName: this.cardFirstName,
        cardMiddleName: this.cardMiddleName,
        cardLastName: this.cardLastName,
        number: this.cardObject.cardNumber,
        issuerCode: this.cardObject.issuerCode,
        referenceNumber: this.cardObject.referenceNumber,
        expireDate: this.cardObject.expireDate,
      };

      this.customerProspectService
        .deActiveAndCreateHealthcare(this.recordID, payload, this.sessionType)
        .subscribe((res) => {
          if (res) {
            NotifyAppComponent.displayToast(
              'success',
              'Update Healthcare',
              'You have successfully updated healthcare record'
            );
            this.sendResult.emit(res);
            this.close.emit(true);
          }
        });
    } else {
      const payload = {
        ownerID: this.ownerID,
        typeCode: this.type,
        cardFirstName: this.cardFirstName,
        cardMiddleName: this.cardMiddleName,
        cardLastName: this.cardLastName,
        number: this.cardObject.cardNumber,
        issuerCode: this.cardObject.issuerCode,
        referenceNumber: this.cardObject.referenceNumber,
        expireDate: this.cardObject.expireDate,
      };

      this.customerProspectService.createHealthcare(payload, this.sessionType).subscribe((res) => {
        if (res) {
          NotifyAppComponent.displayToast(
            'success',
            'Create Healthcare',
            'You have successfully created healthcare record'
          );
          this.sendResult.emit(res);
          this.close.emit(true);
        }
      });
    }
  }
}
