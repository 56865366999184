/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./merchant-trading-hours-view.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/material/dialog";
import * as i4 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i5 from "@angular/material/icon";
import * as i6 from "@angular/common";
import * as i7 from "../../../../../node_modules/@angular/material/list/typings/index.ngfactory";
import * as i8 from "@angular/material/list";
import * as i9 from "./merchant-trading-hours-view.component";
import * as i10 from "../../../core/authentication/shared/authentication.service";
import * as i11 from "../shared/merchant.service";
var styles_MerchantTradingHoursViewComponent = [i0.styles];
var RenderType_MerchantTradingHoursViewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MerchantTradingHoursViewComponent, data: { "animation": [{ type: 7, name: "ngIfAnimation", definitions: [{ type: 1, expr: "void => *", animation: [{ type: 11, selector: ".animate", animation: { type: 6, styles: { opacity: 0 }, offset: null }, options: { optional: true } }, { type: 11, selector: ".animate", animation: { type: 12, timings: "100ms", animation: [{ type: 4, styles: { type: 5, steps: [{ type: 6, styles: { opacity: 0, transform: "translateY(-10%)", offset: 0, height: 0 }, offset: null }, { type: 6, styles: { opacity: 1, transform: "translateY(0)", offset: 1, height: "*" }, offset: null }] }, timings: "0.15s ease-in-out" }] }, options: { optional: true } }], options: null }, { type: 1, expr: "* => void", animation: [{ type: 11, selector: ".animate", animation: { type: 6, styles: { opacity: 1 }, offset: null }, options: { optional: true } }, { type: 11, selector: ".animate", animation: { type: 12, timings: "100ms", animation: [{ type: 4, styles: { type: 5, steps: [{ type: 6, styles: { opacity: 1, transform: "translateY(0)", offset: 0, height: "*" }, offset: null }, { type: 6, styles: { opacity: 0, transform: "translateY(5%)", offset: 1, height: 0 }, offset: null }] }, timings: "0.15s ease-in-out" }] }, options: { optional: true } }], options: null }], options: {} }] } });
export { RenderType_MerchantTradingHoursViewComponent as RenderType_MerchantTradingHoursViewComponent };
function View_MerchantTradingHoursViewComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "h2", [["class", "summary-header rm-m"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Business Hours "])), (_l()(), i1.ɵeld(2, 0, null, null, 5, "span", [["class", "subLabel"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", " timezone: "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(5, 1), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["style", "font-weight: 600"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, _ck(_v, 5, 0, i1.ɵnov(_v.parent.parent, 0), i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("KEYWORD.practice")))); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.merchantTimezone; _ck(_v, 7, 0, currVal_1); }); }
function View_MerchantTradingHoursViewComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "card-header primary-gradient-img clearfix inModal"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "row clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "button", [["class", "btn-close btn-clear mat-button white"], ["matDialogClose", ""]], [[1, "aria-label", 0], [1, "type", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).dialogRef.close(i1.ɵnov(_v, 3).dialogResult) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.closeEvent() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 606208, null, 0, i3.MatDialogClose, [[2, i3.MatDialogRef], i1.ElementRef, i3.MatDialog], { _matDialogClose: [0, "_matDialogClose"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i4.View_MatIcon_0, i4.RenderType_MatIcon)), i1.ɵdid(5, 9158656, null, 0, i5.MatIcon, [i1.ElementRef, i5.MatIconRegistry, [8, null], [2, i5.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["clear"])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "row titleArea clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MerchantTradingHoursViewComponent_2)), i1.ɵdid(9, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = ""; _ck(_v, 3, 0, currVal_2); _ck(_v, 5, 0); var currVal_5 = _co.displayBusinessHours; _ck(_v, 9, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 3).ariaLabel || null); var currVal_1 = i1.ɵnov(_v, 3).type; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵnov(_v, 5).inline; var currVal_4 = (((i1.ɵnov(_v, 5).color !== "primary") && (i1.ɵnov(_v, 5).color !== "accent")) && (i1.ɵnov(_v, 5).color !== "warn")); _ck(_v, 4, 0, currVal_3, currVal_4); }); }
function View_MerchantTradingHoursViewComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [["class", "sr-title m-h2 rm-mt"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Business Hours"])), (_l()(), i1.ɵeld(3, 0, null, null, 4, "p", [["class", "large"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " Timezone: "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["style", "font-weight: 600"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("KEYWORD.practice")); _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.merchantTimezone; _ck(_v, 7, 0, currVal_1); }); }
function View_MerchantTradingHoursViewComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "span", [["class", "small"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "dayLabel"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "tradingHour"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", " - ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.context.$implicit["DayOfWeek.Label.Singular"]; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.util.timeConvert(_v.parent.context.$implicit["OpeningTime"].slice(0, 5)); var currVal_2 = _co.util.timeConvert(_v.parent.context.$implicit["ClosingTime"].slice(0, 5)); _ck(_v, 4, 0, currVal_1, currVal_2); }); }
function View_MerchantTradingHoursViewComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "span", [["class", "small"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "dayLabel"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "tradingHour"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Close"]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit["DayOfWeek.Label.Singular"]; _ck(_v, 2, 0, currVal_0); }); }
function View_MerchantTradingHoursViewComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "mat-list-item", [["class", "flex flexList busHours mat-list-item"]], [[2, "mat-list-item-avatar", null], [2, "mat-list-item-with-avatar", null]], null, null, i7.View_MatListItem_0, i7.RenderType_MatListItem)), i1.ɵdid(1, 1228800, null, 3, i8.MatListItem, [i1.ElementRef, i1.ChangeDetectorRef, [2, i8.MatNavList], [2, i8.MatList]], null, null), i1.ɵqud(603979776, 1, { _lines: 1 }), i1.ɵqud(603979776, 2, { _avatar: 0 }), i1.ɵqud(603979776, 3, { _icon: 0 }), (_l()(), i1.ɵand(16777216, null, 2, 1, null, View_MerchantTradingHoursViewComponent_5)), i1.ɵdid(6, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 2, 1, null, View_MerchantTradingHoursViewComponent_6)), i1.ɵdid(8, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_2 = (_v.context.$implicit["NotOpen"] == "0"); _ck(_v, 6, 0, currVal_2); var currVal_3 = (_v.context.$implicit["NotOpen"] == "1"); _ck(_v, 8, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1)._avatar || i1.ɵnov(_v, 1)._icon); var currVal_1 = (i1.ɵnov(_v, 1)._avatar || i1.ɵnov(_v, 1)._icon); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_MerchantTradingHoursViewComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i6.TitleCasePipe, []), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MerchantTradingHoursViewComponent_1)), i1.ɵdid(2, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MerchantTradingHoursViewComponent_3)), i1.ɵdid(4, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 8, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(6, 16384, null, 0, i3.MatDialogContent, [], null, null), i1.ɵprd(512, null, i6.ɵNgClassImpl, i6.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(8, 278528, null, 0, i6.NgClass, [i6.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(9, { "no-modal-height": 0 }), (_l()(), i1.ɵeld(10, 0, null, null, 3, "mat-list", [["class", "mat-list mat-list-base"]], null, null, null, i7.View_MatList_0, i7.RenderType_MatList)), i1.ɵdid(11, 704512, null, 0, i8.MatList, [i1.ElementRef], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_MerchantTradingHoursViewComponent_4)), i1.ɵdid(13, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isModal == true); _ck(_v, 2, 0, currVal_0); var currVal_1 = ((_co.isModal == false) && _co.displayBusinessHours); _ck(_v, 4, 0, currVal_1); var currVal_2 = _ck(_v, 9, 0, (_co.isModal != true)); _ck(_v, 8, 0, currVal_2); var currVal_3 = _co.tradingHours; _ck(_v, 13, 0, currVal_3); }, null); }
export function View_MerchantTradingHoursViewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-merchant-trading-hours-view", [], null, null, null, View_MerchantTradingHoursViewComponent_0, RenderType_MerchantTradingHoursViewComponent)), i1.ɵdid(1, 114688, null, 0, i9.MerchantTradingHoursViewComponent, [i10.AuthenticationService, i11.MerchantService, [2, i3.MAT_DIALOG_DATA]], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MerchantTradingHoursViewComponentNgFactory = i1.ɵccf("app-merchant-trading-hours-view", i9.MerchantTradingHoursViewComponent, View_MerchantTradingHoursViewComponent_Host_0, { merchantID: "merchantID", isModal: "isModal", displayBusinessHours: "displayBusinessHours" }, { close: "close" }, []);
export { MerchantTradingHoursViewComponentNgFactory as MerchantTradingHoursViewComponentNgFactory };
