import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { TipService } from '../shared/tip.service';

@Component({
  selector: 'app-tip-edit',
  templateUrl: './tip-edit.component.html',
  styleUrls: ['./tip-edit.component.css'],
})
export class TipEditComponent implements OnInit {
  tip;

  @Input()
  tipID;

  @Output() update = new EventEmitter();
  @Output() close = new EventEmitter();

  content;

  isModal = false;

  constructor(
    private tipService: TipService,
    private activeRouter: ActivatedRoute,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.tipID) {
        this.tipID = data.tipID;
        this.isModal = true;
      }
    }
  }

  ngOnInit() {
    this.activeRouter.params.subscribe((params) => {
      if (params['tipID']) {
        this.tipID = params['tipID'];
      }
      if (this.tipID) {
        this.tipService.getTipDetails(this.tipID).subscribe((res) => {
          if (res) {
            this.tip = res;

            this.content = this.tip.content;
          }
        });
      }
    });
  }

  updateTip() {
    if (this.tipID) {
      this.tipService.updateTip(this.tipID, this.tip).subscribe((res) => {
        if (res['success']) {
          this.update.emit(res['data']);
          NotifyAppComponent.displayToast('success', 'Success!', 'Your new tip has been added');
        }
      });
    }
  }

  closeModalEvent() {
    this.close.emit(true);
  }

  getContent(c) {
    this.tip.content = c;
  }
}
