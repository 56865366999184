<!-- <button mat-raised-button *ngIf="displayContractView==false" color="primary" class="pull-right gradient green " matTooltip="New Settlement"
  [routerLink]="['/merchant/', {outlets: {'page': ['settlement-create']}}]">
  <mat-icon aria-hidden="true" class="fas fa-comment-dollar"></mat-icon> {{"SettlementNew" | translate}}
</button> -->
<!-- new settlement btn requested to be hidden -->

<div class="rel" *ngIf="listDB.firstLoadEvent == false" @ngIfAnimation>
  <div class="row clearfix padded rel">
    <h1 class="dataHeader rm-mt transparent">Fetching data...</h1>
  </div>
</div>

<div [hidden]="listDB.data.length == 0">

  <div [hidden]="displayContractView == true">

    <div class="pull-right selection-items" @ngIfAnimation>
      <button class="animated mr" (click)="selectAll()" mat-stroked-button color="" matTooltip="Select All">
        <mat-icon>border_all</mat-icon>
      </button>
      <button class="animated mr" (click)="selectPage()" mat-stroked-button color="" matTooltip="Select Page">
        <mat-icon>border_outer</mat-icon>
      </button>

      <button class="animated mr" *ngIf="selectedIDs && selectedIDs.length > 0" [matMenuTriggerFor]="ActionBtnmenu"
        mat-stroked-button matTooltip="Options">
        <mat-icon class="fas fa-cog"></mat-icon> <span *ngIf="selectedIDs && selectedIDs.length>0"
          class="selected-number-item  accent-background">{{selectedIDs.length}}</span>
      </button>
      <mat-menu #ActionBtnmenu="matMenu">
        <button class="row select-btn" (click)="unselect()" mat-menu-item color="">
          <mat-icon>select_all</mat-icon> Deselect
        </button>

        <hr />



        <button class="row select-btn" *ngIf="selectedIDs && selectedIDs.length > 0" (click)="cancelGroup()"
          mat-menu-item color="">
          <mat-icon>cancel</mat-icon>
          Cancel
        </button>

      </mat-menu>
    </div>

    <h1 class="dataHeader">
      <!-- Contracts -->
      Settlements
      <span class="badge" [endVal]="dataSource.filteredData.length" countUp></span>
    </h1>
    <mat-card class="list-card">
      <div class="row clearfix searchArea primary-gradient-img">
        <div class="row justify flex">
          <!-- <div class="qtr-width flex">

            <div class="" id="search">
              <mat-icon class="schIcon">search</mat-icon>
              <mat-form-field class="schSel" appearance="outline">
                <mat-label>Add Filters</mat-label>
                <mat-select class="" [formControl]="searchVals" matInput placeholder="Add Filters" multiple>
                  <mat-select-trigger>
                    {{ searchVals.value ? searchVals.value[0] : '' }}
                    <span class="additional-selection" *ngIf="searchVals.value?.length > 1">
                      (+{{ searchVals.value.length - 1 }} others)
                    </span>
                  </mat-select-trigger>
                  <mat-option *ngFor="let searchVal of searchValList" [value]="searchVal">{{ searchVal }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div> -->

          <div class="full-width clearfix flex animated fadeInDown">

            <mat-form-field class="margin-btm full-width" style="margin-left: 0px !important;" appearance="outline">
              <mat-label>Search...</mat-label>
              <input class="clearfix" (input)="setFilter($event.target.value, 'fullName')" name="LasfullNametName"
                placeholder="Search..." matInput />
            </mat-form-field>


          </div>
          <div class="full-width clearfix flex animated fadeInDown" *ngIf="searchVals.value?.length > 0">
            <mat-form-field class="margin-btm" *ngIf="searchVals.value?.indexOf('First Name') !== -1"
              appearance="outline">
              <mat-label>First Name</mat-label>
              <input class="clearfix" (input)="setFilter($event.target.value, 'Customer.FirstName')" name="FirstName"
                placeholder="First Name" matInput />
            </mat-form-field>

            <mat-form-field class="margin-btm" *ngIf="searchVals.value?.indexOf('Last Name') !== -1"
              appearance="outline">
              <mat-label>Last Name</mat-label>
              <input class="clearfix" (input)="setFilter($event.target.value, 'Customer.LastName')" name="LastName"
                placeholder="Last Name" matInput />
            </mat-form-field>

            <mat-form-field class="full-width" *ngIf="searchVals.value?.indexOf('Settlement Status') !== -1"
              appearance="outline">
              <mat-label>Settlement Status</mat-label>

              <mat-select class="margin-btm" (selectionChange)="setFilter($event.value, 'Status.Label')"
                name="SettlementStatus" placeholder="Settlement Status">
                <mat-option [value]="null">
                  All
                </mat-option>
                <mat-option *ngFor="
                    let p of dataSource.filteredData
                      | objectPropertyValue: 'Status.Label'
                      | unique
                      | without: ['', null]
                      | orderBy
                  " [value]="p">
                  {{ p }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="full-width" *ngIf="searchVals.value?.indexOf('Invoice Type') !== -1"
              appearance="outline">
              <mat-label>Type of Invoice</mat-label>

              <mat-select class="margin-btm" (selectionChange)="setFilter($event.value, 'Invoice.Description')"
                name="InvoiceType" placeholder="Type of Invoice">
                <mat-option [value]="null">
                  All
                </mat-option>
                <mat-option *ngFor="
                    let p of dataSource.filteredData
                      | objectPropertyValue: 'Invoice.Description'
                      | unique
                      | without: ['', null]
                      | orderBy
                  " [value]="p">
                  {{ p }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="full-width" *ngIf="searchVals.value?.indexOf('Payment Status') !== -1"
              appearance="outline">
              <mat-label>Payment Status</mat-label>

              <mat-select class="margin-btm" (selectionChange)="setFilter($event.value, 'PaymentStatus.Label')"
                name="PaymentStatus" placeholder="Payment Status">
                <mat-option [value]="null">
                  All
                </mat-option>
                <mat-option *ngFor="
                    let p of dataSource.filteredData
                      | objectPropertyValue: 'PaymentStatus.Label'
                      | unique
                      | without: ['', null]
                      | orderBy
                  " [value]="p">
                  {{ p }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="margin-btm full-width" appearance="outline">
              <mat-label>Search...</mat-label>
              <input class="clearfix" (input)="setFilter($event.target.value, 'fullName')" name="LasfullNametName"
                placeholder="Search..." matInput />
            </mat-form-field>

          </div>
        </div>
      </div>

      <!-- Table -->
      <mat-table class="list-table" #table [dataSource]="dataSource" matSort>
        <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->
        <!-- ID Column -->

        <ng-container cdkColumnDef="selectCheckBox" sticky>
          <mat-header-cell *cdkHeaderCellDef></mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <div class="margin-btm pull-left">
              <mat-checkbox [checked]="isSelected(row['ID'])" (change)="select(row['ID'], $event.checked)"
                color="accent">
              </mat-checkbox>
            </div>
          </mat-cell>
        </ng-container>


        <ng-container cdkColumnDef="InvoiceReference">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Invoice Reference</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="openSettlementView(row['ID'])">
            {{ row['Invoice.Reference'] }}
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Date">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Date</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="openSettlementView(row['ID'])"
            matTooltip="{{ row['DateTimeCreated'] | customDate }}">
            {{ row['DateTimeCreated'] | customDate }}
          </mat-cell>
        </ng-container>


        <ng-container cdkColumnDef="merchantPicture">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header>
          </mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="openSettlementView(row['ID'])">


            <app-card-picture class="sms-merchant-pic-settlement text-center pull-left " [hideZoomButton]="true"
              [cardID]="row.Merchant_key" [hideZoomButton]="false" [tableName]="'Merchant'">
            </app-card-picture>
          </mat-cell>
        </ng-container>
        <ng-container cdkColumnDef="patientPicture">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header>
          </mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="openSettlementView(row['ID'])">


            <app-card-picture class="sms-merchant-pic-settlement text-center pull-left " [hideZoomButton]="true"
              [cardID]="row.Customer_key" [hideZoomButton]="false" [tableName]="'Customer'">
            </app-card-picture>

          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="PatientName">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header>{{ "KEYWORD.patient" | translate | titlecase }}Name
          </mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="openSettlementView(row['ID'])">



            {{ row['Customer.FirstName'] }} {{ row['Customer.LastName'] }}
          </mat-cell>
        </ng-container>
        <ng-container cdkColumnDef="SettlementAmount">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Settlement Amount</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="openSettlementView(row['ID'])"
            matTooltip="{{ row['Settlement.Amount'] | customCurrency }}">
           <strong>  {{ row['Settlement.Amount'] | customCurrency }}</strong>
          </mat-cell>
        </ng-container>
        <ng-container cdkColumnDef="InvoiceAmount">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> {{"KEYWORD.Treatment" | translate}}Amount
          </mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="openSettlementView(row['ID'])"
            matTooltip="{{ row['Invoice.Amount'] | customCurrency }}">
            {{ row['Invoice.Amount'] | customCurrency }}
          </mat-cell>
        </ng-container>
        <ng-container cdkColumnDef="InvoiceType">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Type of Invoice</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="openSettlementView(row['ID'])"
            matTooltip="{{ row['Invoice.Description'] | customCurrency }}">
            {{ row['Invoice.Description'] | customCurrency }}
          </mat-cell>
        </ng-container>
        <ng-container cdkColumnDef="SettlementStatus">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Settlement Status</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="openSettlementView(row['ID'])"
            matTooltip="{{ row['Status.Label'] }}">
            <span class="chip" [class.requested]="row['Status.Code'] == 'REQU'"
              [class.cancelled]="row['Status.Code'] == 'WDRN'" [class.declined]="row['Status.Code'] == 'DECL'"
              [class.pending]="row['Status.Code'] == 'PEND'"
              [class.approved]="row['Status.Code'] == 'APPR'">


              <mat-icon class="{{row['Status.Icon']}} smr" style="font-size: 0.8rem !important;"></mat-icon>

              {{ row['Status.Label'] }}
            </span>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="MaterialRequest">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Material Advance</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="openSettlementView(row['ID'])">
            <span class="list-label smaller" *ngIf="row['Is_Materials_Request'] == '1'" matTooltip="Material Request"
              style="background-color: rgb(90, 186, 71)">
              <mat-icon>check</mat-icon>
            </span>
            <span class="list-label smaller" *ngIf="row['Is_Materials_Request'] != '1'" matTooltip="Finance Settlement"
              style="background-color: rgb(239, 71, 39)">
              <mat-icon>close</mat-icon>
            </span>
          </mat-cell>
        </ng-container>


        <ng-container cdkColumnDef="PaymentStatus">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Payment Status</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="openSettlementView(row['ID'])"
            matTooltip="{{ row['PaymentStatus.Label'] }}">
            <span class="chip" [class.requested]="row['PaymentStatus.Label'] == 'Suspended'"
              [class.cancelled]="row['PaymentStatus.Label'] == 'Cancelled'"
              [class.declined]="row['PaymentStatus.Label'] == 'Blocked'"
              [class.pending]="row['PaymentStatus.Label'] == 'Pending'"
              [class.approved]="row['PaymentStatus.Label'] == 'Paid'">{{ row['PaymentStatus.Label'] || '...' }}
            </span>
          </mat-cell>
        </ng-container>

        <!-- hidden field colour -->
        <ng-container cdkColumnDef="fieldColor">
          <mat-header-cell *cdkHeaderCellDef></mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="openSettlementView(row['ID'])"
            [class.requested]="row['Status.Code'] == 'REQU'" [class.cancelled]="row['Status.Code'] == 'WDRN'"
            [class.declined]="row['Status.Code'] == 'DECL'" [class.pending]="row['Status.Code'] == 'PEND'"
            [class.approved]="row['Status.Code'] == 'APPR'">
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Actions">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Actions</mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <div style="float: right">


              <app-invitation-actions *ngIf="row" [customerID]="row['Customer_key']" [settlement]="row" [items]="items"
                [settlementOnList]="false" [contractID]="row['Contract_key']" [merchantID]="row['Merchant_Key']"
                [productID]="row['Product_key']" (getSettlementUpdate)="getSettlementUpdate($event)">
              </app-invitation-actions>



              <!-- <button [matMenuTriggerFor]="menu" mat-icon-button>
                <mat-icon>more_vert</mat-icon>
              </button> -->
              <mat-menu #menu="matMenu">
                <button *ngIf="row['ID']" (click)="openSettlementView(row['ID'])" mat-menu-item>
                  <mat-icon>description</mat-icon>
                  <span>Settlement Quick View</span>
                </button>

                <button *ngIf="row['Contract_key']" (click)="openContractView(row)" mat-menu-item>
                  <mat-icon>subject</mat-icon>
                  <span>Contract View</span>
                </button>

                <button *ngIf="row['Customer_key']" (click)="viewPatient(row['Customer_key'])" mat-menu-item>
                  <mat-icon>person</mat-icon>
                  <span>{{ "KEYWORD.patient" | translate | titlecase }} Profile</span>
                </button>

                <button *ngIf="row['Status.Code'] == 'PEND'" (click)="requestSettlement(row['ID'])" mat-menu-item>
                  <mat-icon>description</mat-icon>
                  <span>Continue settlement request</span>
                </button>

                <button *ngIf="row['Status.Code'] == 'PEND' || row['Status.Code'] == 'REQU'"
                  (click)="withdrawSettlement(row['ID'], row['DrawDownGroupID'])" mat-menu-item>
                  <mat-icon>block</mat-icon>
                  <span>Withdraw settlement </span>
                </button>
              </mat-menu>
            </div>
          </mat-cell>
        </ng-container>
        <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row class="listRow separatedRow" *cdkRowDef="let row; columns: displayedColumns"
          [class.requested]="row['Status.Code'] == 'REQU'" [class.cancelled]="row['Status.Code'] == 'WDRN'"
          [class.declined]="row['Status.Code'] == 'DECL'" [class.pending]="row['Status.Code'] == 'PEND'"
          [class.approved]="row['Status.Code'] == 'APPR'"></mat-row>
      </mat-table>
      <mat-paginator #paginator [length]="dataSource.filteredData.length" [pageIndex]="0" [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions">
      </mat-paginator>
    </mat-card>
  </div>
  <div class="contract container" *ngIf="contractID != null && displayContractView == true">
    <app-contract-view [contractID]="contractID"></app-contract-view>
    <div class="full-width text-center">
      <button class="mb mt pull-left" (click)="hideContractView()" mat-raised-button color="primary">
        <mat-icon>keyboard_arrow_left</mat-icon>
        Settlement list
      </button>
    </div>
  </div>
</div>
<div *ngIf="listDB.data.length == 0 && listDB.firstLoadEvent != false">
  <app-empty-list-message></app-empty-list-message>
</div>
