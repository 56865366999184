<div id="color-palette">
  <!-- https://github.com/zefoy/ngx-color-picker/tree/4.x.x/
    Here is the ngx-color-picker documentation -->
  <div class="row flex clearfix full-width">
    <input
      class="color-input-core mr full-width pull-left"
      *ngIf="isPalette == false"
      [(colorPicker)]="coreColor"
      [disabled]="displayCoreColor"
      [value]="coreColor"
      [cpPosition]="position"
      [cpOutputFormat]="hex"
      [style.background]="coreColor"
      (cpSliderDragEnd)="changeColor()"
      (cpInputChange)="changeColor()"
      (change)="changeColor()"
      placeholder="#ffffff"
    />

    <input
      class="color-input-core mr two-thrd-width pull-left"
      *ngIf="isPalette == true"
      [(colorPicker)]="coreColor"
      [disabled]="displayCoreColor"
      [value]="coreColor"
      [cpPosition]="position"
      [cpOutputFormat]="hex"
      [style.background]="coreColor"
      (colorPickerChange)="changeColor()"
      (change)="changeColor()"
      placeholder="#ffffff"
    />

    <div class="pull-left palette-check-box" *ngIf="coreColor && isPalette == true">
      <mat-checkbox [(ngModel)]="displayPalette" name="displayPalette" color="primary"> Show Palette</mat-checkbox>
    </div>
  </div>

  <div class="margin-btm" *ngIf="coreColor && displayPalette == true && isPalette == true">
    <div class="row full-width clearfix" *ngFor="let p of palette">
      <input
        class="color-input two-thrd-width"
        [(colorPicker)]="p['hex']"
        [value]="p['hex']"
        [disabled]="!canEditPalette"
        [cpPosition]="position"
        [cpOutputFormat]="hex"
        [style.background]="p['hex']"
        (colorPickerChange)="sendPalette()"
      />

      <input
        class="contrastInput pull-left"
        [(colorPicker)]="p['hex']"
        [cpOutputFormat]="hex"
        [disabled]="'true'"
        [style.background]="getContrast(p['darkContrast'])"
        [cpPosition]="position"
      />
      <span class="colorName pull-left">{{ p['name'] }}</span>
    </div>
  </div>
</div>
