<div class="rel prospectModal" *ngIf="prospect">
  <div class="row clearfix">
    <!-- merchant calculated name -->
    <div class="row titleArea clearfix">
      <app-ep-document-view-image
        class="prospect patientView userIconView pull-left"
        [defaultPicture]="
          'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/profile_placeholder.png)'
        "
        [link]="profileLink"
      >
      </app-ep-document-view-image>
      <h2 class="summary-header rm-m">
        {{ prospect.Salutation }} {{ prospect.CalculatedName }}
        <span class="subLabel">{{ "KEYWORD.patient" | translate | titlecase }}</span>
      </h2>
    </div>
  </div>

  <div class="row clearfix">
    <div class="row clearfix" *ngIf="prospect['Gender.Label']">
      <p class="rm-m">{{ prospect['Gender.Label'] }}</p>
      <p class="small">Gender</p>
    </div>
    <p class="rm-m">
      {{ prospect['Salutation'] }} {{ prospect['FirstName'] }} {{ prospect['MiddleName'] }} {{ prospect['Name'] }}
    </p>
    <p class="small">Name</p>
  </div>

  <mat-divider></mat-divider>

  <div class="row clearfix" *ngIf="prospect['DateOfBirth'] && prospect['DateOfBirth'] != '0000-00-00'">
    <p class="rm-m">{{ prospect['DateOfBirth'] | customDate }}</p>
    <p class="small">Date of birth</p>
  </div>

  <mat-divider *ngIf="prospect['DateOfBirth'] && prospect['DateOfBirth'] != '0000-00-00'"></mat-divider>

  <div class="row clearfix" *ngIf="prospect['phones.Number'] || prospect['mobiles.Number']">
    <div class="row clearfix" *ngIf="prospect['mobiles.Number']">
      <p class="rm-m">{{ prospect['mobiles.Number'] | customPhone }}</p>
      <p class="small">Phone/Mobile</p>
    </div>
    <!--  -->
    <div class="row clearfix" *ngIf="prospect['phones.Number']">
      <p class="rm-m">
        {{ prospect['phones.Number'] | customPhone: 'landLine' }}
      </p>
      <p class="small" *ngIf="prospect['phones.Number']">Phone</p>
    </div>
  </div>

  <mat-divider *ngIf="prospect['phones.Number'] || prospect['mobiles.Number']"></mat-divider>
  <!-- Email Addresses -->
  <div class="row clearfix" *ngIf="prospect['emails.Email']">
    <p class="rm-m">{{ prospect['emails.Email'] }}</p>
    <p class="small">Primary Email</p>
  </div>

  <mat-divider *ngIf="prospect['emails.Email']"></mat-divider>
  <!-- Location Information -->
  <div class="row clearfix" *ngIf="prospect['addresses.Calculated']">
    <p class="rm-m">{{ prospect['addresses.Calculated'] }}</p>
    <p class="small">Home Address</p>
  </div>

  <p *ngIf="isReferral == true">
    <span class="status-box pull-right">Referral </span>
  </p>
</div>
