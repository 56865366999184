import { Observable } from 'rxjs';

import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class ModuleService {
  constructor(private http: HttpClient) {}

  getPromoterPlanList(payload = null, sessionType) {
    let url = 'subscription-plan-promoter';
    if (sessionType == 'admin' || sessionType == 'promoter') {
      url = 'subscription-plan-promoter/global';
    }
    let params: HttpParams = new HttpParams();
    let options = {};
    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }
      options = {
        params,
      };
    }
    return this.http.get(`${environment.nodeUrl}/${url}`, options).pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      })
    );
  }

  getModuleList(payload = null, sessionType) {
    let url = 'ep-module';

    if (sessionType == 'admin' || sessionType == 'promoter') {
      url = 'ep-module/global';
    }

    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }

    return this.http.get(`${environment.nodeUrl}/${url}`, options).pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      })
    );
  }

  getModuleListHidden(payload = null, sessionType) {
    let url = 'ep-module/hidden';

    if (sessionType == 'admin' || sessionType == 'promoter') {
      url = 'ep-module/hidden/global';
    }

    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }

    return this.http.get(`${environment.nodeUrl}/${url}`, options).pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      })
    );
  }

  getModuleDetails(id, payload = null, sessionType) {
    let url = 'ep-module';

    if (sessionType == 'admin' || sessionType == 'promoter') {
      url = 'ep-module/global';
    }

    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }

    return this.http.get(`${environment.nodeUrl}/${url}/${id}`, options).pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      })
    );
  }
  getModuleDetailsByCode(code, payload = null, sessionType) {
    let url = 'ep-module/code';

    if (sessionType == 'admin' || sessionType == 'promoter') {
      url = 'ep-module/code/global';
    }

    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }

    return this.http.get(`${environment.nodeUrl}/${url}/${code}`, options).pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      })
    );
  }

  sync(payload = {}): Observable<any> {
    return this.http.post(`${environment.nodeUrl}/ep-module/sync`, payload).pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      })
    );
  }

  editModule(id, payload) {
    return this.http.put(`${environment.nodeUrl}/ep-module/${id}`, payload).pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      })
    );
  }

  editModuleGroup(payload = {}): Observable<any> {
    return this.http
      .put(environment.nodeUrl + '/ep-module/group', payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  deleteModule(id) {
    return this.http.delete(`${environment.nodeUrl}/ep-module/${id}`, {}).pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      })
    );
  }

  getCoverImageStreamLink(ID, LastModified = null) {
    if (
      ID &&
      LastModified &&
      LastModified != '' &&
      LastModified != undefined &&
      LastModified != 'null' &&
      LastModified != 'undefined '
    ) {
      return `${environment.nodeUrl}/ep-module/cover-stream-cached/${LastModified}/` + ID;
    } else if (ID) {
      return `${environment.nodeUrl}/ep-module/cover-stream/` + ID;
    }
    return null;
  }

  getThumbnailImageStreamLink(ID, LastModified = null) {
    if (
      ID &&
      LastModified &&
      LastModified != '' &&
      LastModified != undefined &&
      LastModified != 'null' &&
      LastModified != 'undefined '
    ) {
      return `${environment.nodeUrl}/ep-module/thumbnail-stream-cached/${LastModified}/` + ID;
    } else if (ID) {
      return `${environment.nodeUrl}/ep-module/thumbnail-stream/` + ID;
    }
    return null;
  }

  getCoverImage(ID) {
    return this.http.get(`${environment.nodeUrl}/ep-module/cover/` + ID, {}).pipe(map((res: Response) => res['data']));
  }

  getMarketingHTMLContent(ID) {
    return this.http
      .get(`${environment.nodeUrl}/ep-module/marketing-html/` + ID, {})
      .pipe(map((res: Response) => res['data']));
  }

  getThumbnailImage(ID) {
    return this.http
      .get(`${environment.nodeUrl}/ep-module/thumbnail/` + ID, {})
      .pipe(map((res: Response) => res['data']));
  }

  syncSubPlanMerchant(payload) {
    const url = 'subscription-plan-merchant';

    return this.http.post(`${environment.nodeUrl}/${url}/sync`, payload).pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      })
    );
  }
  syncSubPlanMerchantHidden(payload) {
    const url = 'subscription-plan-merchant';

    return this.http.post(`${environment.nodeUrl}/${url}/sync-hidden`, payload).pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      })
    );
  }
}
