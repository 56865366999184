<div class="rel text-center">
  <mat-card class="card">
    <div class="row clearfix">
      <div class="pull-right">
        <!-- Modal dismiss button -->
        <button class="btn-close btn-clear mat-button" (click)="doNotShow()" matDialogClose>
          <mat-icon>clear</mat-icon>
        </button>
      </div>
    </div>
    <hr/>
    <div class="colour-stripe"></div>
    <p>
      <img class="animated zoomIn feature-img character_thumbsup"/>
    </p>
    <p class="lead">This is your {{ "KEYWORD.practice" | translate }} performance overview!</p>
    <p>
      This page gives you a run-down of how your {{ "KEYWORD.practice" | translate }} is going. You can see how much
      revenue you've generated from new {{ "KEYWORD.treatment" | translate }}s and memberships quickly, and can generate
      a report for your records!
    </p>
    <mat-checkbox [(ngModel)]="showOnPerformanceFirstVisit">Dont Show Again</mat-checkbox>
  </mat-card>
  <button class="btn-large" (click)="doNotShow()" mat-raised-button color="accent" matDialogClose>Okay!</button>
</div>
