import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';

import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import * as momentTZ from 'moment-timezone';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { LookupService } from '../../../shared/services/lookup.service';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { DentistService } from '../../dentist/shared/dentist.service';
import { MerchantViewComponent } from '../merchant-view/merchant-view.component';
import { MerchantService } from '../shared/merchant.service';

@Component({
  selector: 'app-merchant-trading-hours',
  templateUrl: './merchant-trading-hours.component.html',
  styleUrls: ['./merchant-trading-hours.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.animate', style({ opacity: 0 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.15s ease-in-out',
              keyframes([
                style({
                  opacity: 0,
                  transform: 'translateY(-10%)',
                  offset: 0,
                  height: 0,
                }),
                style({
                  opacity: 1,
                  transform: 'translateY(0)',
                  offset: 1.0,
                  height: '*',
                }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.animate', style({ opacity: 1 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.15s ease-in-out',
              keyframes([
                style({
                  opacity: 1,
                  transform: 'translateY(0)',
                  offset: 0,
                  height: '*',
                }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({
                  opacity: 0,
                  transform: 'translateY(5%)',
                  offset: 1.0,
                  height: 0,
                }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class MerchantTradingHoursComponent implements OnInit {
  @Input() merchantID;
  @Input() dentistID;
  @Input() isSetup = false;

  isPromoterOrAdmin;

  @Output() close = new EventEmitter();

  @Output() getTradingHours = new EventEmitter();

  timezoneList = momentTZ.tz.names();
  originalTImezonelist = momentTZ.tz.names();

  localTime = new Date();
  tradingHours;
  merchants = [];
  isValidTimezone = true;
  sessionType = 'merchant';
  @Input()
  title;
  merchantTimezone;
  merchantTimezoneValue = 'void';
  timeZoneCode;
  timeZoneValue;

  util = new UtilsClass();

  constructor(
    private authenticationService: AuthenticationService,
    private lookupService: LookupService,
    private merchantService: MerchantService,
    private dentistService: DentistService,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((sessionType) => {
      this.sessionType = sessionType;

      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isPromoterOrAdmin = res;

        if (this.isPromoterOrAdmin == true) {
          if (this.dentistID) {
            const p = {
              fields: 'ID,TimeZoneCode,TimeZoneValue,CalculatedName',
            };
            this.dentistService.getContactDetails(this.dentistID, p, this.sessionType).subscribe((m) => {
              if (m) {
                if (m['TimeZoneCode']) {
                  this.merchantTimezone = m['TimeZoneCode'];

                  if (m['TimeZoneValue']) {
                    this.merchantTimezoneValue = m['TimeZoneValue'];
                  }
                }

                this.title = m['CalculatedName'] + ' working hours';

                this.setUp();
              }
            });
          } else if (this.merchantID) {
            const p = {
              fields: 'ID,TimeZoneCode,TimeZoneValue,TradingAs',
            };
            this.merchantService.getMerchantDetails(this.merchantID, p, this.sessionType).subscribe((m) => {
              if (m) {
                if (m['TimeZoneCode']) {
                  this.merchantTimezone = m['TimeZoneCode'];

                  if (m['TimeZoneValue']) {
                    this.merchantTimezoneValue = m['TimeZoneValue'];
                  }
                }

                this.title = m['TradingAs'] + ' opening hours';

                this.setUp();
              }
            });
          } else {
            this.authenticationService.getCurrentPractice().subscribe((m) => {
              if (m) {
                if (m['TimeZoneCode']) {
                  this.merchantTimezone = m['TimeZoneCode'];

                  if (m['TimeZoneValue']) {
                    this.merchantTimezoneValue = m['TimeZoneValue'];
                  }
                }
                this.title = m['TradingAs'] + ' opening hours';
                this.setUp();
              }
            });
          }
        } else {
          if (this.dentistID) {
            const p = {
              fields: 'ID,TimeZoneCode,TimeZoneValue,CalculatedName',
            };
            this.dentistService.getContactDetails(this.dentistID, p, this.sessionType).subscribe((m) => {
              if (m) {
                if (m['TimeZoneCode']) {
                  this.merchantTimezone = m['TimeZoneCode'];

                  if (m['TimeZoneValue']) {
                    this.merchantTimezoneValue = m['TimeZoneValue'];
                  }
                }
                this.title = m['CalculatedName'] + ' working hours';

                this.setUp();
              }
            });
          } else {
            this.authenticationService.getCurrentPractice().subscribe((m) => {
              if (m) {
                if (m['TimeZoneCode']) {
                  this.merchantTimezone = m['TimeZoneCode'];

                  if (m['TimeZoneValue']) {
                    this.merchantTimezoneValue = m['TimeZoneValue'];
                  }
                }
                this.title = m['TradingAs'] + ' opening hours';
                this.setUp();
              }
            });
          }
        }
      });
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.setUp();
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  setUp() {
    if (this.isPromoterOrAdmin == true) {
      const payload = {
        merchantID: this.merchantID,
        dentistID: this.dentistID,
      };
      this.merchantService.getTradingHourList(payload, this.isPromoterOrAdmin).subscribe((res) => {
        if ((res && res.length < 7) || this.isSetup == true) {
          this.initialTimeList();
        } else if (res && res.length == 7 && this.isSetup == false) {
          if (this.timeZoneValue == 'void') {
            const v = this.util.getTimeZoneValueByCode(res[0]['TimeZone']);

            if (v !== null) {
              this.timeZoneCode = res[0]['TimeZone'];
              this.timeZoneValue = v;
              this.updateTimeZone();
            }
          }
          const order = { Monday: 1, Tuesday: 2, Wednesday: 3, Thursday: 4, Friday: 5, Saturday: 6, Sunday: 7 };

          this.tradingHours = res.sort(function (a, b) {
            return order[a['DayOfWeek.Label.Singular']] - order[b['DayOfWeek.Label.Singular']];
          });
        }
      });
    } else {
      this.merchantService.getTradingHourList().subscribe((res) => {
        if (res.length < 7 || this.isSetup == true) {
          this.initialTimeList();
        } else if (res.length == 7 && this.isSetup == false) {
          if (this.timeZoneValue == 'void') {
            const v = this.util.getTimeZoneValueByCode(res[0]['TimeZone']);

            if (v !== null) {
              this.timeZoneCode = res[0]['TimeZone'];
              this.timeZoneValue = v;
              this.updateTimeZone();
            }
          }
          const order = { Monday: 1, Tuesday: 2, Wednesday: 3, Thursday: 4, Friday: 5, Saturday: 6, Sunday: 7 };

          this.tradingHours = res.sort(function (a, b) {
            return order[a['DayOfWeek.Label.Singular']] - order[b['DayOfWeek.Label.Singular']];
          });
        }
      });
    }
  }

  initialTimeList() {
    this.tradingHours = [
      {
        'DayOfWeek.Code': '2',
        'DayOfWeek.Label.Singular': 'Monday',
        NotOpen: true,
        OpeningTime: '',
        ClosingTime: '',
      },
      {
        'DayOfWeek.Code': '3',
        'DayOfWeek.Label.Singular': 'Tuesday',
        NotOpen: true,
        OpeningTime: '',
        ClosingTime: '',
      },
      {
        'DayOfWeek.Code': '4',
        'DayOfWeek.Label.Singular': 'Wednesday',
        NotOpen: true,
        OpeningTime: '',
        ClosingTime: '',
      },
      {
        'DayOfWeek.Code': '5',
        'DayOfWeek.Label.Singular': 'Thursday',
        NotOpen: true,
        OpeningTime: '',
        ClosingTime: '',
      },
      {
        'DayOfWeek.Code': '6',
        'DayOfWeek.Label.Singular': 'Friday',
        NotOpen: true,
        OpeningTime: '',
        ClosingTime: '',
      },
      {
        'DayOfWeek.Code': '7',
        'DayOfWeek.Label.Singular': 'Saturday',
        NotOpen: true,
        OpeningTime: '',
        ClosingTime: '',
      },
      {
        'DayOfWeek.Code': '1',
        'DayOfWeek.Label.Singular': 'Sunday',
        NotOpen: true,
        OpeningTime: '',
        ClosingTime: '',
      },
    ];
  }

  closeEvent() {
    this.close.emit(true);
  }

  onChange(event, index) {
    if (event.checked == true) {
      this.tradingHours[index].NotOpen = false;
    } else {
      this.tradingHours[index].NotOpen = true;
    }
  }

  getOpenTimeEvent(event, index) {
    this.tradingHours[index].OpeningTime = event;
  }

  getCloseTimeEvent(event, index) {
    this.tradingHours[index].ClosingTime = event;
  }

  apply() {
    let count = 0;
    for (let i = 0; i < this.tradingHours.length; i++) {
      let payload = {};

      let isDefault = true;
      if (this.dentistID) {
        isDefault = false;
      }
      if (this.isPromoterOrAdmin == true) {
        payload = {
          isDefault,
          dentistID: this.dentistID,
          merchantID: this.merchantID,
          dayCode: this.tradingHours[i]['DayOfWeek.Code'],
          openingTime: this.tradingHours[i]['OpeningTime'] || '08:30',
          closingTime: this.tradingHours[i]['ClosingTime'] || '17:00',
          timeZone: this.timeZoneCode,
          notOpen: !this.openOrClose(this.tradingHours[i]['NotOpen']),
        };
      } else {
        payload = {
          isDefault,
          dentistID: this.dentistID,
          dayCode: this.tradingHours[i]['DayOfWeek.Code'],
          openingTime: this.tradingHours[i]['OpeningTime'] || '08:30',
          closingTime: this.tradingHours[i]['ClosingTime'] || '17:00',
          timeZone: this.timeZoneCode,
          notOpen: !this.openOrClose(this.tradingHours[i]['NotOpen']),
        };
      }
      this.merchantService.createTradingHour(payload, this.isPromoterOrAdmin).subscribe((res) => {
        if (res) {
          this.getTradingHours.emit(res);
          count += 1;
          if (count == 6) {
            NotifyAppComponent.displayToast(
              'success',
              'Successful operation',
              'You successfully setup your business trading hours'
            );
            this.close.emit(true);
          }
        }
      });
    }
  }

  filterList(e) {
    this.timezoneList = this.originalTImezonelist.filter((x) => x.toLowerCase().includes(e.toLowerCase()));
    if (this.timezoneList.indexOf(e) == -1) {
      this.isValidTimezone = false;
    } else {
      this.isValidTimezone = true;
    }
  }

  openOrClose(e) {
    if (e == '1') {
      return false;
    } else {
      return true;
    }
  }

  viewMerchant() {
    const ref = RootAppComponent.dialog.open(MerchantViewComponent, {
      data: this.merchantID,
      width: '600px',
    });
    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  getResultTimeZone(e) {
    if (e) {
      if (e.code) {
        this.timeZoneCode = e.code;
      }

      if (e.value) {
        this.timeZoneValue = e.value;
      }

      this.updateTimeZone();
    }
  }

  updateTimeZone() {
    if (this.timeZoneCode && this.timeZoneValue) {
      const p = {
        timeZoneCode: this.timeZoneCode,
        timeZoneValue: this.timeZoneValue,
      };

      if (this.dentistID) {
        this.dentistService.modifyContact(this.dentistID, p, this.sessionType).subscribe((r) => {
          if (r) {
            NotifyAppComponent.displayToast('success', 'Successed', 'Timezone is updated');
          }
        });
      } else if (this.merchantID && this.isPromoterOrAdmin == true) {
        this.merchantService.editMerchant(this.merchantID, p).subscribe((r) => {
          if (r) {
            NotifyAppComponent.displayToast('success', 'Successed', 'Timezone is updated');
          }
        });
      } else {
        this.authenticationService.editCurrentPractice(p).subscribe((r) => {
          if (r) {
            NotifyAppComponent.displayToast('success', 'Successed', 'Timezone is updated');
          }
        });
      }
    }
  }
}
