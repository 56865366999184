import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { UtilsService } from '../../services/utils.service';
import { UtilsClass } from '../../types/utils/utils.class';

@Component({
  selector: 'app-lookup-input',
  templateUrl: './lookup-input.component.html',
  styleUrls: ['./lookup-input.component.css'],
})
export class LookupInputComponent implements OnInit {
  lookups = [];

  lookup;

  @Input()
  lookupCode;

  @Input()
  lookupCodeAttribute = 'Code';

  @Input()
  lookupLabelAttribute = 'Label';

  @Input()
  category = 'Gender';

  @Input()
  type = 'codeLookup';

  @Output()
  getLookupCode = new EventEmitter();

  @Output()
  getLookup = new EventEmitter();

  @Output()
  validate = new EventEmitter();

  @Input()
  label = this.category;

  @Input()
  isRequired = true;

  requiredLabel = 'required';

  isValidate = false;
  util = new UtilsClass();

  lookupFilter;

  constructor(private utilService: UtilsService) {}

  ngOnInit() {
    if (this.isRequired == false) {
      this.requiredLabel = null;
    }

    this.setup();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      (changes.lookupCode &&
        changes.lookupCode.previousValue &&
        !changes.lookupCode.currentValue &&
        !changes.lookupCode.firstChange) ||
      (changes.category &&
        !changes.category.previousValue &&
        changes.category.currentValue &&
        !changes.category.firstChange) ||
      (changes.type && !changes.type.previousValue && changes.type.currentValue && !changes.type.firstChange)
    ) {
      this.setup();
    }
  }

  setup() {
    const payload = {
      limit: 400,
    };

    if (this.category && (this.type == 'codeLookup' || this.type == 'CodeLookup')) {
      this.utilService.getCodeLookup(this.category, payload).subscribe((res) => {
        if (res && res.length > 0) {
          this.lookups = res;

          if (this.lookupCode) {
            for (let i = 0; i < this.lookups.length; i++) {
              if (this.lookups[i][this.lookupCodeAttribute] == this.lookupCode) {
                this.lookup = this.lookup[i];
                break;
              }
            }
          }
        }

        this.sendResult();
      });
    } else if (this.category && (this.type == 'simpleLookup' || this.type == 'SimpleLookup')) {
      this.utilService.getSimpleLookup(this.category, payload).subscribe((res) => {
        if (res && res.length > 0) {
          this.lookups = res;

          if (this.lookupCode) {
            for (let i = 0; i < this.lookups.length; i++) {
              if (this.lookups[i][this.lookupCodeAttribute] == this.lookupCode) {
                this.lookup = this.lookup[i];
                break;
              }
            }
          }
        }

        this.sendResult();
      });
    } else if (this.category && (this.type == 'productGroup' || this.type == 'ProductGroup')) {
      this.utilService.getProductGroup().subscribe((res) => {
        if (res && res.length > 0) {
          this.lookups = res;

          if (this.lookupCode) {
            for (let i = 0; i < this.lookups.length; i++) {
              if (this.lookups[i][this.lookupCodeAttribute] == this.lookupCode) {
                this.lookup = this.lookup[i];
                break;
              }
            }
          }
        }

        this.sendResult();
      });
    }
  }

  chooseLookup(e) {
    if (e && typeof e == 'object' && (e[this.lookupCodeAttribute] || e['Label'])) {
      if (!e[this.lookupCodeAttribute]) {
        e[this.lookupCodeAttribute] = e['Label'];
      }
      this.lookup = e;

      this.sendResult();
    }
  }

  sendResult() {
    this.validateField();
    if (this.lookup) {
      this.getLookup.emit(this.lookup);
      this.getLookupCode.emit(this.lookup[this.lookupCodeAttribute]);
    }
  }

  validateField() {
    let ruleValidator;
    if (this.isRequired != false) {
      ruleValidator = {
        lookup: 'string|required',
      };
    } else {
      ruleValidator = {
        lookup: 'string',
      };
    }

    const p = {
      lookup: this.lookup,
    };
    this.isValidate = this.util.validateFields(p, ruleValidator);

    this.validate.emit(this.isValidate);
  }
}
