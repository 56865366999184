/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./help-page-view.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../shared/pipes/safe-html.pipe";
import * as i4 from "@angular/platform-browser";
import * as i5 from "./help-page-view.component";
import * as i6 from "../shared/helper.service";
import * as i7 from "@angular/material/dialog";
var styles_HelpPageViewComponent = [i0.styles];
var RenderType_HelpPageViewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HelpPageViewComponent, data: {} });
export { RenderType_HelpPageViewComponent as RenderType_HelpPageViewComponent };
function View_HelpPageViewComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(1, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 0, 0, _ck(_v, 1, 0, i1.ɵnov(_v.parent.parent, 0), _co.simplePage.content)); _ck(_v, 0, 0, currVal_0); }); }
function View_HelpPageViewComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HelpPageViewComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.simplePage.content; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_HelpPageViewComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i3.SafeHtmlPipe, [i4.DomSanitizer]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HelpPageViewComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.simplePage; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_HelpPageViewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-help-page-view", [], null, null, null, View_HelpPageViewComponent_0, RenderType_HelpPageViewComponent)), i1.ɵdid(1, 114688, null, 0, i5.HelpPageViewComponent, [i6.HelperService, i7.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HelpPageViewComponentNgFactory = i1.ɵccf("app-help-page-view", i5.HelpPageViewComponent, View_HelpPageViewComponent_Host_0, { simplePageCode: "simplePageCode", simplePage: "simplePage" }, {}, []);
export { HelpPageViewComponentNgFactory as HelpPageViewComponentNgFactory };
