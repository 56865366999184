<div class="row clearfix" *ngIf="isPromoterOrAdmin == true">
  <app-operator-filter
    class="dash"
    id="part1"
    [displayMerchant]="isDisplayMerchant"
    [displayDateFrom]="true"
    [setAutoSelectMerchant]="true"
    [displayDateTo]="true"
    [displayDentist]="false"
    [sendNull]="true"
    [selectDateRange]="selectedRange"
    (search)="applySearch($event)"
  ></app-operator-filter>

  <div class="clearfix full-width" *ngIf="searchApplied == true">
    <div class="toggle-card row clearfix full-width mb">
      <mat-button-toggle-group
        class="btn-group row clearfix flex full-width text-center"
        [(ngModel)]="type"
        (ngModelChange)="choose()"
      >
        <mat-button-toggle class="text-center full-width" [value]="null"> All</mat-button-toggle>
        <mat-button-toggle class="text-center full-width" *ngFor="let c of typesGlobal" [value]="c">
          {{ c.value }}
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <div class="full-width" *ngFor="let c of typesGlobal" [hidden]="c.code != templateTypeID">
      <app-message-list-global
        *ngIf="c && c.code && c.code == templateTypeID && merchantID != 'none'"
        [dateFrom]="dateFrom"
        [title]="title"
        [dateTo]="dateTo"
        [merchantID]="merchantID"
        [templateTypeID]="c.code"
      >
      </app-message-list-global>
    </div>

    <div class="full-width" *ngIf="templateTypeID === null && merchantID != 'none'">
      <app-message-list-global
        [dateFrom]="dateFrom"
        [title]="title"
        [dateTo]="dateTo"
        [merchantID]="merchantID"
        [templateTypeID]="null"
      ></app-message-list-global>
    </div>
  </div>
</div>

<div class="row clearfix" *ngIf="isPromoterOrAdmin == false">
  <app-operator-filter
    class="dash"
    [displayMerchant]="false"
    [displayDateFrom]="true"
    [displayDateTo]="true"
    [displayTimePicker]="true"
    [displayDentist]="false"
    [sendNull]="true"
    [selectDateRange]="selectedRange"
    (search)="applySearch($event)"
  ></app-operator-filter>

  <div class="clearfix full-width" *ngIf="searchApplied == true">
    <div class="toggle-card row clearfix full-width mb">
      <mat-button-toggle-group
        class="btn-group row clearfix flex full-width text-center"
        [(ngModel)]="type"
        (ngModelChange)="choose()"
      >
        <mat-button-toggle class="text-center full-width" [value]="null"> All</mat-button-toggle>
        <mat-button-toggle class="text-center full-width" *ngFor="let c of types" [value]="c">
          {{ c.value }}
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <div class="full-width" *ngFor="let c of types" [hidden]="c.code != templateTypeID">
      <app-message-list
        *ngIf="c && c.code && c.code == templateTypeID"
        [dateFrom]="dateFrom"
        [dateTo]="dateTo"
        [title]="title"
        [templateTypeID]="c.code"
      >
      </app-message-list>
    </div>

    <div class="full-width" *ngIf="templateTypeID === null">
      <app-message-list [dateFrom]="dateFrom" [dateTo]="dateTo" [title]="title" [templateTypeID]="null">
      </app-message-list>
    </div>
  </div>
</div>
