<div class="row clearfix">
  <div class="card-header primary-gradient-img clearfix inModal" *ngIf="isModal == true">
    <div class="row clearfix">
      <button class="btn-close btn-clear pull-right mat-button" (click)="closeModalEvent()">
        <mat-icon>clear</mat-icon>
      </button>

      <div class="row titleArea clearfix">
        <mat-icon class="pull-left fas fa-comment-dollar"></mat-icon>
        <h2 class="summary-header rm-m">Create Other Vehicle</h2>
      </div>
    </div>
  </div>

  <div class="row clearfix full-width cont" *ngIf="disabled == false">
    <div class="row clearfix flex">
      <div class="clearfix full-width">
        <div class="col-left">
          <h4 class="rm-mb">Operator modification</h4>
          <hr/>
          <div class="row clearfix full-width">
            <mat-form-field class="full-width" [ngClass]="highlightField('Updating_Reason.Code')" appearance="outline">
              <mat-label>Reason</mat-label>
              <mat-select [(ngModel)]="pcDetail.updatedReason" placeholder="Reason" name="reason" required>
                <mat-option *ngFor="let reason of persCircumUpdReasons" [value]="reason.Code">
                  {{ reason.Label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="clearfix full-width">
        <div class="col-left">
          <h4 class="rm-mb">Comments</h4>
          <hr/>
          <div class="row clearfix full-width">
            <mat-form-field
              class="full-width"
              [ngClass]="highlightField('Updating_Reason.Comment')"
              appearance="outline"
            >
              <mat-label>Leave a comment</mat-label>
              <textarea [(ngModel)]="pcDetail.updatedComment" matInput placeholder="Comment" name="comment"></textarea>
            </mat-form-field>
          </div>
        </div>
      </div>

      <!-- <div class="clearfix full-width">
              <div class="col-left">
                <h4 class="rm-mb">Files</h4>
                <hr>
                <div class="row clearfix full-width">
                  <app-file-uploader class=" text-center clearfix" [uploadDirectly]="true" [isDragable]="true" class="text-center" (onCompleteAll)="onCompleteAll($event)"
                    [limit]="1"></app-file-uploader>


                  <app-file-list class="compact" (remove)="removeFile($event)" [hasExtraInfo]="false" [canEdit]="true" [canAction]="false"
                    [files]="[fileID]"></app-file-list>
                </div>
              </div>
            </div> -->
    </div>
  </div>

  <div class="row clearfix full-width cont">
    <div class="row clearfix flex">
      <div class="clearfix full-width">
        <div class="col-left">
          <h4 class="rm-mb">Share Details</h4>
          <hr/>
          <div class="row clearfix full-width">
            <mat-form-field class="full-width" [ngClass]="highlightField('Asset.Value')" appearance="outline">
              <mat-label>Market Value</mat-label>
              <input
                [(ngModel)]="pcDetail.assetValue"
                [disabled]="disabled"
                matInput
                placeholder="Market Value"
                currencyMask
              />
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="clearfix full-width">
        <div class="col-left">
          <h4 class="rm-mb">Dividends</h4>
          <hr/>
          <div class="row clearfix full-width">
            <mat-form-field class="full-width" [ngClass]="highlightField('Income.Value')" appearance="outline">
              <mat-label>Income Amount</mat-label>
              <input
                [(ngModel)]="pcDetail.incomeValue"
                [disabled]="disabled"
                matInput
                placeholder="Income Amount"
                currencyMask
              />
            </mat-form-field>

            <mat-form-field class="full-width" [ngClass]="highlightField('Income.Frequency')" appearance="outline">
              <mat-label>Income Frequency</mat-label>
              <mat-select
                [(ngModel)]="pcDetail.incomeFrequency"
                [disabled]="disabled"
                placeholder="Income Frequency"
                name="IncomeFrequency"
              >
                <mat-option *ngFor="let fre of frequencies" [value]="fre.Code">
                  {{ fre.Label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="clearfix full-width">
        <div class="col-left">
          <h4 class="rm-mb">Loan Details</h4>
          <hr/>
          <div class="row clearfix full-width">
            <mat-form-field class="full-width" [ngClass]="highlightField('Info.Owned')" appearance="outline">
              <mat-label>Are These Shares Owned Outright?</mat-label>
              <mat-select [(ngModel)]="pcDetail.infoOwned" [disabled]="disabled">
                <mat-option value="0"> No</mat-option>
                <mat-option value="1"> Yes</mat-option>
              </mat-select>
            </mat-form-field>

            <!-- <p>Lender: <span class="value">{{pcDetail['Info.Bank.Name']}}</span></p>
                  <p>Loan Type: <span class="value">{{pcDetail['Info.TypeOfLoan.Label']}}</span></p>
                  <p>Original Loan Amount: <span
                      class="value">{{pcDetail['Info.AmountInitiallyBorrowed'] | customCurrency}}</span></p>
                  <p>Current Debt: <span class="value">{{pcDetail['Liability.Value'] | customCurrency}}</span></p>
                  <p>Repayment Amount: <span class="value">{{pcDetail['Info.RepaymentsTotal.Amount'] | customCurrency}}</span>
                  </p>
                  <p>Repayment Frequency: <span
                      class="value">{{convertFrequency(pcDetail['Info.RepaymentsTotal.Frequency'])}}</span></p> -->

            <section *ngIf="pcDetail.infoOwned == '0'">
              <mat-form-field class="full-width" [ngClass]="highlightField('Info.Bank.Code')" appearance="outline">
                <mat-label>Lender</mat-label>
                <mat-select
                  [(ngModel)]="pcDetail.infoBankCode"
                  [disabled]="disabled"
                  placeholder="Lender"
                  name="Lender"
                >
                  <mat-option *ngFor="let bank of bankList" [value]="bank.ID">
                    {{ bank.Name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field
                class="full-width"
                [ngClass]="highlightField('Info.TypeOfLoan.Code')"
                appearance="outline"
              >
                <mat-label>Loan Type</mat-label>
                <mat-select
                  [(ngModel)]="pcDetail.infoTypeOfLoanCode"
                  [disabled]="disabled"
                  placeholder="Loan Type"
                  name="LoanType"
                >
                  <mat-option *ngFor="let l of loanTypeList" [value]="l.Code">
                    {{ l.Label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field
                class="full-width"
                [ngClass]="highlightField('Info.AmountInitiallyBorrowed')"
                appearance="outline"
              >
                <mat-label>Original Loan Amount</mat-label>
                <input
                  [(ngModel)]="pcDetail.infoAmountBorrowed"
                  [disabled]="disabled"
                  matInput
                  placeholder="Original Loan Amount"
                  currencyMask
                />
              </mat-form-field>

              <mat-form-field class="full-width" [ngClass]="highlightField('Liability.Value')" appearance="outline">
                <mat-label>Current Debt</mat-label>
                <input
                  [(ngModel)]="pcDetail.liabilityValue"
                  [disabled]="disabled"
                  matInput
                  placeholder="Current Debt"
                  currencyMask
                />
              </mat-form-field>

              <mat-form-field
                class="full-width"
                [ngClass]="highlightField('Info.RepaymentsTotal.Amount')"
                appearance="outline"
              >
                <mat-label>Repayment Amount</mat-label>
                <input
                  [(ngModel)]="pcDetail.infoRepaymentValue"
                  [disabled]="disabled"
                  matInput
                  placeholder="Repayment Amount"
                  currencyMask
                />
              </mat-form-field>

              <mat-form-field
                class="full-width"
                [ngClass]="highlightField('Info.RepaymentsTotal.Frequency')"
                appearance="outline"
              >
                <mat-label>Repayment Frequency</mat-label>
                <mat-select
                  [(ngModel)]="pcDetail.infoRepaymentFrequency"
                  [disabled]="disabled"
                  placeholder="Repayment Frequency"
                  name="RepaymentFrequency"
                >
                  <mat-option *ngFor="let fre of frequencies" [value]="fre.Code">
                    {{ fre.Label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </section>
          </div>
        </div>
      </div>
    </div>

    <div class="row clearfix mt" *ngIf="disabled == false">
      <button
        class="pull-right"
        *ngIf="isModal == false"
        [disabled]="!pcDetail.updatedReason"
        (click)="submit()"
        mat-raised-button
        color="accent"
      >
        Submit
      </button>
      <button
        class="pull-right"
        *ngIf="isModal == true"
        [disabled]="!pcDetail.updatedReason"
        (click)="create()"
        mat-raised-button
        color="accent"
      >
        Create
      </button>
    </div>
  </div>
</div>
