import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-getting-started-merchant',
  templateUrl: './getting-started-merchant.component.html',
  styleUrls: ['./getting-started-merchant.component.scss'],
})
export class GettingStartedMerchantComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
