import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../src/environments/environment';
import { DataResponse } from '../types/data-response.type';
import { UtilsService } from './utils.service';

export class IndustrySettings {
  industryType: string;
  industryProfileTable: string;
}

export class RawIndustrySettings {
  Which_Profile_Table: string;
  Type_Of_Industry: string;
}

@Injectable({ providedIn: 'root' })
export class IndustrySettingsService {
  private baseUrl = environment.nodeUrl;

  constructor(private utilsService: UtilsService, private httpClient: HttpClient) {}

  fetchIndustrySettings$(): Observable<IndustrySettings> {
    const params = this.utilsService.getHttpParamsFromPayload({});

    const options = { params };

    const endpoint = 'industry-settings';

    const url = `${this.baseUrl}/${endpoint}`;

    return this.httpClient.get<DataResponse<RawIndustrySettings>>(url, options).pipe(
      map((response) => {
        const data = response['data'];
        const industrySettings: IndustrySettings = {
          industryType: data.Type_Of_Industry,
          industryProfileTable: data.Which_Profile_Table,
        };
        return industrySettings;
      })
    );
  }
}
