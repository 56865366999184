/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./appointment-sync-calendar-card.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i3 from "@angular/material/card";
import * as i4 from "@angular/platform-browser/animations";
import * as i5 from "../../../shared/components/calendar-event-share/calendar-event-share.component.ngfactory";
import * as i6 from "../../../shared/components/calendar-event-share/calendar-event-share.component";
import * as i7 from "../../../shared/services/calendar-share.service";
import * as i8 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i9 from "@angular/material/icon";
import * as i10 from "@angular/common";
import * as i11 from "./appointment-sync-calendar-card.component";
import * as i12 from "../../../core/authentication/shared/authentication.service";
import * as i13 from "../shared/appointment.service";
import * as i14 from "../../../shared/pipes/custom-date.pipe";
var styles_AppointmentSyncCalendarCardComponent = [i0.styles];
var RenderType_AppointmentSyncCalendarCardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppointmentSyncCalendarCardComponent, data: {} });
export { RenderType_AppointmentSyncCalendarCardComponent as RenderType_AppointmentSyncCalendarCardComponent };
function View_AppointmentSyncCalendarCardComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "mat-card", [["class", "card full-width two-third mat-card"]], [[2, "_mat-animation-noopable", null]], null, null, i2.View_MatCard_0, i2.RenderType_MatCard)), i1.ɵdid(1, 49152, null, 0, i3.MatCard, [[2, i4.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 0, "div", [["class", "colour-stripe"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, 0, 6, "div", [["class", "full-width clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-calendar-event-share", [], null, [[null, "success"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("success" === en)) {
        var pd_0 = (_co.calendarSynced($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_CalendarEventShareComponent_0, i5.RenderType_CalendarEventShareComponent)), i1.ɵdid(5, 114688, null, 0, i6.CalendarEventShareComponent, [i7.CalendarShareService, i1.ChangeDetectorRef], { event: [0, "event"], appleIsConnected: [1, "appleIsConnected"], googleIsConnected: [2, "googleIsConnected"], microsoftIsConnected: [3, "microsoftIsConnected"] }, { success: "success" }), (_l()(), i1.ɵeld(6, 0, null, null, 3, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "mat-icon", [["class", "fas fa-star pull-left mr goldStatus mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i8.View_MatIcon_0, i8.RenderType_MatIcon)), i1.ɵdid(8, 9158656, null, 0, i9.MatIcon, [i1.ElementRef, i9.MatIconRegistry, [8, null], [2, i9.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, null, [" You can add this appointment to your favorite calendar, click on button to the right to choose your platform "]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.calEvent; var currVal_2 = !!_co.appointment.Apple_Calendar_Consumer_Key; var currVal_3 = !!_co.appointment.Google_Calendar_Consumer_Key; var currVal_4 = !!_co.appointment.Microsoft_Calendar_Consumer_Key; _ck(_v, 5, 0, currVal_1, currVal_2, currVal_3, currVal_4); _ck(_v, 8, 0); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0); var currVal_5 = i1.ɵnov(_v, 8).inline; var currVal_6 = (((i1.ɵnov(_v, 8).color !== "primary") && (i1.ɵnov(_v, 8).color !== "accent")) && (i1.ɵnov(_v, 8).color !== "warn")); _ck(_v, 7, 0, currVal_5, currVal_6); }); }
export function View_AppointmentSyncCalendarCardComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppointmentSyncCalendarCardComponent_1)), i1.ɵdid(1, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.displaySync(); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AppointmentSyncCalendarCardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-appointment-sync-calendar-card", [], null, null, null, View_AppointmentSyncCalendarCardComponent_0, RenderType_AppointmentSyncCalendarCardComponent)), i1.ɵdid(1, 638976, null, 0, i11.AppointmentSyncCalendarCardComponent, [i12.AuthenticationService, i13.AppointmentService, i14.CustomDatePipe], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppointmentSyncCalendarCardComponentNgFactory = i1.ɵccf("app-appointment-sync-calendar-card", i11.AppointmentSyncCalendarCardComponent, View_AppointmentSyncCalendarCardComponent_Host_0, { appointment: "appointment" }, { success: "success" }, []);
export { AppointmentSyncCalendarCardComponentNgFactory as AppointmentSyncCalendarCardComponentNgFactory };
