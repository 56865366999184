<div class="container">
  <div class="row clearfix">
    <div class="pull-left">
      <h1 class="sm-h1">Settlement Overview</h1>
    </div>
  </div>
  <mat-tab-group dynamicHeight="true">
    <!-- <mat-tab-group [dynamicHeight]="true"> -->
    <mat-tab id="tool1" [label]="('KEYWORD.treatment' | translate | titlecase) + ' Settlements'">
      <div class="row clearfix">
        <mat-card class="chartsCard card">
          <div class="row clearfix flex">
            <div class="thrd-width">
              <div class="padded" id="tool2">
                <!-- <mat-button-toggle-group #group1="matButtonToggleGroup" class="thrd-width pull-right btn-group sml-group text-center" id="group1" [(ngModel)]="chart1type">
                <mat-button-toggle class="btn-small" value="1">
                  {{ "Requests" | translate }}
                </mat-button-toggle>
                <mat-button-toggle class="btn-small" value="2">
                  {{ "Revenue" | translate }}
                </mat-button-toggle>
              </mat-button-toggle-group> -->
                <div class="row clearfix mb">
                  <!-- <app-chart [chart]="reqSetChart" *ngIf="group1.value == 1"> </app-chart>
                <app-chart [chart]="currBillingChart" *ngIf="group1.value == 2"> </app-chart> -->
                  <app-chart-input
                    [showLegend]="false"
                    [data]="revenueDataOne | orderBy: ['name']"
                    [chartType]="'pie'"
                    [title]="'This Settlement Period'"
                    [dataNameAttribute]="'name'"
                    [dataNameAttribute]="'value'"
                  ></app-chart-input>
                  <div class="row clearfix">
                    <p>Number of Requested Settlement: {{ totalRequestedOne }}</p>
                    <p>Total Amount Requested: {{ totalAmountRequestedOne | customCurrency }}</p>
                    <p>Date Settled: {{ totalDaysOne }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="thrd-width">
              <div class="padded" id="tool3">
                <!-- <mat-button-toggle-group #group2="matButtonToggleGroup" id="group2" class="thrd-width pull-right btn-group sml-group text-center" [(ngModel)]="chart2type">
                <mat-button-toggle class="btn-small" value="1">
                  {{ "Requests" | translate }}
                </mat-button-toggle>
                <mat-button-toggle class="btn-small" value="2">
                  {{ "Revenue" | translate }}
                </mat-button-toggle>
              </mat-button-toggle-group> -->
                <div class="row clearfix mb">
                  <!-- <app-chart [chart]="reqLastWkSetChart" *ngIf="group2.value == 1"> </app-chart>
                <app-chart [chart]="lastBillingChart" *ngIf="group2.value == 2"> </app-chart> -->
                  <app-chart-input
                    [showLegend]="false"
                    [data]="revenueDataTwo | orderBy: ['name']"
                    [chartType]="'pie'"
                    [title]="'Previous Settlement Period'"
                    [dataNameAttribute]="'name'"
                    [dataNameAttribute]="'value'"
                  ></app-chart-input>
                  <div class="row clearfix">
                    <p>Number of Requested Settlement: {{ totalRequestedTwo }}</p>
                    <p>Total Amount Requested: {{ totalAmountRequestedTwo | customCurrency }}</p>
                    <p>Date Settled: {{ totalDaysTwo }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="thrd-width" id="tool4">
              <div class="padded">
                <!-- <mat-button-toggle-group #group3="matButtonToggleGroup" class="thrd-width pull-right btn-group sml-group text-center" [(ngModel)]="chart3type">
                <mat-button-toggle class="btn-small" value="1">
                  {{ "Requests" | translate }}
                </mat-button-toggle>
                <mat-button-toggle class="btn-small" value="2">
                  {{ "Revenue" | translate }}
                </mat-button-toggle>
              </mat-button-toggle-group> -->
                <div class="row clearfix mb">
                  <!-- <app-chart [chart]="reqTotalSetChart" *ngIf="group3.value == 1"> </app-chart>
                <app-chart [chart]="yearToDateChart" *ngIf="group3.value == 2"> </app-chart> -->
                  <app-chart-input
                    [showLegend]="false"
                    [data]="revenueDataThr | orderBy: ['name']"
                    [chartType]="'pie'"
                    [title]="'Next Settlement Period'"
                    [dataNameAttribute]="'name'"
                    [dataNameAttribute]="'value'"
                  ></app-chart-input>
                  <div class="row clearfix">
                    <p>Number of Requested Settlement: {{ totalRequestedThr }}</p>
                    <p>Total Amount Requested: {{ totalAmountRequestedThr | customCurrency }}</p>
                    <p>Date Settled: {{ totalDaysThr }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr/>
          <div class="row clearfix">
            <div class="thrd-width">
              <div class="padded">
                <app-chart-input
                  [showLegend]="false"
                  [data]="revenueDataSum | orderBy: ['name']"
                  [chartType]="'pie'"
                  [title]="'Year To Date'"
                  [dataNameAttribute]="'name'"
                  [dataNameAttribute]="'value'"
                ></app-chart-input>
                <div class="row clearfix">
                  <p>Number of Requested Settlement: {{ totalRequestedSum }}</p>
                  <p>Total Amount Requested: {{ totalAmountRequestedSum | customCurrency }}</p>
                  <p>Date Settled: {{ totalDaysSum }}</p>
                </div>
              </div>
            </div>
          </div>
        </mat-card>
      </div>
    </mat-tab>
    <mat-tab id="mat-tab-label-1-1" *ngIf="isModuleMembershipActive == true" label="Membership Settlements">
      <div class="row clearfix">
        <mat-card class="chartsCard card">
          <div class="row clearfix">
            <div class="thrd-width grey-col">
              <div class="padded">
                <mat-button-toggle-group
                  class="thrd-width pull-right btn-group sml-group text-center"
                  #group1="matButtonToggleGroup"
                  [(ngModel)]="chart1type"
                >
                  <mat-button-toggle class="btn-small" value="1">
                    Requests
                  </mat-button-toggle>
                  <mat-button-toggle class="btn-small" value="2">
                    Revenue
                  </mat-button-toggle>
                </mat-button-toggle-group>
                <div class="row clearfix mb">
                  <!-- <app-chart [chart]="reqSetChart" *ngIf="group1.value == 1"> </app-chart>
                <app-chart [chart]="currBillingChart" *ngIf="group1.value == 2"> </app-chart> -->
                </div>
              </div>
            </div>
            <div class="thrd-width">
              <div class="padded">
                <mat-button-toggle-group
                  class="thrd-width pull-right btn-group sml-group text-center"
                  #group2="matButtonToggleGroup"
                  [(ngModel)]="chart2type"
                >
                  <mat-button-toggle class="btn-small" value="1">
                    Requests
                  </mat-button-toggle>
                  <mat-button-toggle class="btn-small" value="2">
                    Revenue
                  </mat-button-toggle>
                </mat-button-toggle-group>
                <div class="row clearfix mb">
                  <!-- <app-chart [chart]="reqLastWkSetChart" *ngIf="group2.value == 1"> </app-chart>
                <app-chart [chart]="lastBillingChart" *ngIf="group2.value == 2"> </app-chart> -->
                </div>
              </div>
            </div>
            <div class="thrd-width">
              <div class="padded">
                <mat-button-toggle-group
                  class="thrd-width pull-right btn-group sml-group text-center"
                  #group3="matButtonToggleGroup"
                  [(ngModel)]="chart3type"
                >
                  <mat-button-toggle class="btn-small" value="1">
                    Requests
                  </mat-button-toggle>
                  <mat-button-toggle class="btn-small" value="2">
                    Revenue
                  </mat-button-toggle>
                </mat-button-toggle-group>
                <div class="row clearfix mb">
                  <!-- <app-chart [chart]="reqTotalSetChart" *ngIf="group3.value == 1"> </app-chart>
               <app-chart [chart]="yearToDateChart" *ngIf="group3.value == 2"> </app-chart>  -->
                </div>
              </div>
            </div>
          </div>
        </mat-card>
      </div>
    </mat-tab>
  </mat-tab-group>
  <div class="row clearfix">
    <mat-card class="statisticsBlock card flex">
      <div class="row clearfix">
        <div class="pull-left">
          <!--  Billing period FROM and TO -->
          <p class="small period">Billing period: <span>00 Month 2017</span> - <span>00 Month 2017</span></p>
        </div>
        <div class="pull-right">
          <button class="btn-small btn-inverse" id="sendReport" mat-button>Send Report Email</button>
        </div>
      </div>
    </mat-card>
  </div>
</div>
