<div id="hidden-liked">
  <mat-icon class="fas fa-thumbs-up animated infinite tada"></mat-icon>
</div>
<div id="hidden-disliked">
  <mat-icon class="fas fa-thumbs-down animated infinite tada"></mat-icon>
</div>
<div id="just-liked" [class.passThrough]="anim">
  <div class="central">
    <like-animation [type]="'like'" [anim]="anim"></like-animation>
    <h3 class="primary-green">{{"KEYWORD.Treatment"}} has been added!</h3>
  </div>
</div>
<div id="just-disliked" [class.passThrough]="anim">
  <div class="central">
    <like-animation [type]="'dislike'" [anim]="anim"></like-animation>
    <h3 class="accent-color">Maybe next time...</h3>
  </div>
</div>

<div *ngIf="posts.length > 0" @ngIfAnimation>
  <!-- <div class="row clearfix"> -->

  <div class="anim" id="viewport">
    <div class="row clearfix rel">
      <ul
        class="stack"
        #postStacks
        [stackConfig]="stackConfig"
        (throwout)="onThrowOut($event)"
        (throwoutleft)="voteUp(false)"
        (throwoutright)="voteUp(true)"
        (swipe)="swipeEvent($event)"
        (dragend)="onDragEnd($event)"
        swing-stack
      >
        <!-- primary stack - top card active and can be swiped -->
        <li
          class="mat-card treatment-item-card"
          #postCard
          *ngFor="let post of posts.slice(posts.length - 1, posts.length); let i = index"
          [id]="post.id"
          [ngClass]="'Post:' + post.id"
          (press)="onPress($event)"
          swing-card
          access-key="post.id"
        >
          <div class="treatmentViewBlock clearfix">
            <div class="card-header primary-gradient-img clearfix">
              <div class="row clearfix">
                <h2 class="summary-header rm-m">
                  {{ post.treatmentProduct }}
                  <span class="subLabel">
                    {{ post.treatmentType }}
                  </span>
                </h2>
              </div>
            </div>
            <div class="card-image rel" [style.background-image]="'url(' + post.treatmentImage + ')'">
              <div class="treatment-value text-right">
                <p class="small rm-m"> {{"KEYWORD.Treatment" | translate}} value:</p>
                <h3 class="rm-m">
                  {{ post.treatmentValue | customCurrency }}
                </h3>
              </div>
            </div>
            <div class="row clearfix full-width totalRow" id="card_toolbar">
              <div class="grey">
                <button class="btn-small" mat-button>
                  <mat-icon>visibility_on</mat-icon>
                  More info
                </button>
              </div>

              <div class="row clearfix flex buttons">
                <button class="btn-clear full-width" (click)="onClickDislike()" mat-button color="accent">
                  <mat-icon class="fas fa-thumbs-down"></mat-icon>
                </button>
                <button class="btn-clear full-width primary-green" (click)="onClickLike()" mat-button color="primary">
                  <mat-icon class="fas fa-thumbs-up"></mat-icon>
                </button>
              </div>
            </div>
          </div>
        </li>
      </ul>

      <!-- 'fake stack' displays next upcoming card below -->
      <div class="stack substack" *ngIf="posts.length > 1">
        <div
          class="post_img_div"
          *ngFor="let post of posts.slice(posts.length - 2, posts.length - 1)"
          [id]="post.id"
          (press)="onPress($event)"
        >
          <mat-card class="treatment-item-card mat-elevation-z5">
            <div class="treatmentViewBlock clearfix">
              <div class="card-header primary-gradient-img clearfix">
                <div class="row clearfix">
                  <h2 class="summary-header rm-m">
                    {{ post.treatmentProduct }}
                    <span class="subLabel">
                      {{ post.treatmentType }}
                    </span>
                  </h2>
                </div>
              </div>
              <div class="card-image rel" [style.background-image]="'url(' + post.treatmentImage + ')'">
                <div class="treatment-value text-right">
                  <p class="small rm-m"> {{"KEYWORD.Treatment" | translate}} value:</p>
                  <h3 class="rm-m">
                    {{ post.treatmentValue | customCurrency }}
                  </h3>
                </div>
              </div>
              <div class="row clearfix full-width totalRow" id="card_toolbar">
                <div class="grey">
                  <button class="btn-small" mat-button>
                    <mat-icon>visibility_on</mat-icon>
                    More info
                  </button>
                </div>

                <div class="row clearfix flex buttons">
                  <button class="btn-clear full-width" mat-button color="accent" disabled>
                    <mat-icon class="fas fa-thumbs-down"></mat-icon>
                  </button>
                  <button class="btn-clear full-width primary-green" mat-button color="primary" disabled>
                    <mat-icon class="fas fa-thumbs-up"></mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
</div>
