import { Component, EventEmitter, Inject, OnInit, Optional, Output } from '@angular/core';
import { Settings } from '../../../shared/types/settings';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { UtilsService } from '../../../shared/services/utils.service';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA } from '@angular/material';
import { CardService } from '../../card/shared/card.service';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { DentistViewComponent } from '../../dentist/dentist-view/dentist-view.component';
import { ContactInputComponent } from '../../contact-us/contact-input/contact-input.component';
import { ContactCreateEditModalComponent } from '../contact-create-edit-modal/contact-create-edit-modal.component';
import { MerchantViewComponent } from '../../merchant/merchant-view/merchant-view.component';
import { PasswordPromptComponent } from '../../operator/password-prompt/password-prompt.component';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { OperatorLoginAccessComponent } from '../../operator/operator-login-access/operator-login-access.component';
@Component({
  selector: 'app-contact-lookup',
  templateUrl: './contact-lookup.component.html',
  styleUrls: ['./contact-lookup.component.css'],
})
export class ContactLookupComponent implements OnInit {
  isModal = true;

  firstName;
  lastName;
  filterData;
  filterEmail;
  filterName;
  filterPhone;
  mobile;
  calculatedName;
  detailedView = new EventEmitter();
  closeModal = new EventEmitter();
  close = new EventEmitter();
  hideFilter = false;
  currentPage;
  itemsPerPage = 10;
  displayMerchantList = true;
  isNewStaff = true;
  showBackButton = true;
  useStrict = false;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  currentMerchant;
  sessionType = 'guest';
  merchantID;
  serviceRef;
  onlyMyAccount;
  isDestoyed = false;
  viewOnClick = false;
  isPromoterOrAdmin = false;
  displayChatButton = true;
  isModulePatientSMSActive = Settings.global['isModulePatientSMSActive'];
  settings = Settings.global;
  contacts = [];
  @Output()
  contactChat = new EventEmitter();

  @Output()
  getResult = new EventEmitter();
  @Output()
  createNew = new EventEmitter();
  @Output()
  getNewContact = new EventEmitter();
  @Output()
  getSelectedContactRaw = new EventEmitter();
  @Output()
  getSelectedContact = new EventEmitter();
  @Output()
  getSelectedContactID = new EventEmitter();

  hasMobile = false;
  hasEmail = false;
  isAdminOrPromoter = false;
  partUserName;
  merchant;
  accountType = null;
  merchants = [];

  isMerchantAdmin = false;
  constructor(
    private utilService: UtilsService,
    private router: Router,
    private AuthenticationService: AuthenticationService,
    private cardService: CardService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.merchantID) {
        this.merchantID = data.merchantID;
      }

      if (data.displayChatButton === true) {
        this.displayChatButton = true;
      } else if (data.displayChatButton === false) {
        this.displayChatButton = false;
      }
      if (data.isNewStaff === true) {
        this.isNewStaff = true;
      } else if (data.isNewStaff === false) {
        this.isNewStaff = false;
      }

      if (data.displayMerchantList === true) {
        this.displayMerchantList = true;
      } else if (data.displayMerchantList === false) {
        this.displayMerchantList = false;
      }

      if (data.hasMobile === true) {
        this.hasMobile = true;
      } else if (data.hasMobile === false) {
        this.hasMobile = false;
      }

      if (data.hasEmail === true) {
        this.hasEmail = true;
      } else if (data.hasEmail === false) {
        this.hasEmail = false;
      }

      if (data.viewOnClick === true) {
        this.viewOnClick = true;
      } else if (data.viewOnClick === false) {
        this.viewOnClick = false;
      }

      if (data.onlyMyAccount === true) {
        this.onlyMyAccount = true;
      } else if (data.onlyMyAccount === false) {
        this.onlyMyAccount = false;
      }
    }
  }

  ngOnInit() {
    this.utilService.getCurrentAccess().subscribe((access) => {
      if (access) {
        this.setAccess(access);
      }
    });
    this.AuthenticationService.getSessionType().subscribe((sessionType) => {
      if (sessionType) {
        this.sessionType = sessionType;
      }

      this.AuthenticationService.isMerchantAdmin().subscribe((isMerchantAdmin) => {
        this.isMerchantAdmin = isMerchantAdmin;

        this.AuthenticationService.isPromoterOrAdmin().subscribe((res) => {
          this.isPromoterOrAdmin = res;

          this.AuthenticationService.getCurrentPractice().subscribe((r) => {
            this.currentMerchant = r;

            this.displayMerchantListEvent();
          });
        });
      });
    });
  }

  getClientObject() {
    if (this.merchantID) {
      const v = this.merchants.findIndex((item) => {
        if (item && item.ID && item.ID == this.merchantID) {
          return true;
        }

        return false;
      });

      if (v !== -1) {
        this.merchant = this.merchants[v];
      }
    }
  }
  displayMerchantListEvent() {
    if (this.isPromoterOrAdmin && this.displayMerchantList == true && this.currentMerchant && this.currentMerchant.ID) {
      if (!this.merchantID) {
        this.merchantID = this.currentMerchant.ID;
        this.merchant = this.currentMerchant;
      }

      this.AuthenticationService.getMerchantList(
        { fields: 'ID,TradingAs,LastModified' },
        this.isPromoterOrAdmin
      ).subscribe((ms) => {
        if (ms) {
          this.merchants = ms;

          if (this.merchantID) {
            const v = this.merchants.findIndex((item) => {
              if (item && item.ID && item.ID == this.merchantID) {
                return true;
              }

              return false;
            });

            if (v !== -1) {
              this.merchant = this.merchants[v];
            }
          }
        }
      });
    }
  }

  viewMerchant(id) {
    const ref = RootAppComponent.dialog.open(MerchantViewComponent, {
      data: id,
      width: '600px',
    });
    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  setAccess(res) {
    if (res) {
      this.isModulePatientSMSActive = res['isModulePatientSMSActive'];
    }
  }
  searchContact() {
    this.currentPage = 1;
    this.AuthenticationService.getSessionType().subscribe((sessionType) => {
      if (sessionType) {
        this.sessionType = sessionType;
      }

      let payload: any = {};

      if (this.isPromoterOrAdmin == true && this.merchantID) {
        payload = {
          partEmail: this.filterEmail,
          partName: this.filterName,
          partFirstName: this.firstName,
          partMobileLastName: this.lastName,
          partCalculatedName: this.calculatedName,
          partUserName: this.partUserName,
          partPhone: this.filterPhone,
          partMobile: this.mobile,
          merchantID: this.merchantID,
          onlyMyAccount: this.onlyMyAccount,
          useStrict: this.useStrict,
          hasMobile: this.hasMobile,
          hasEmail: this.hasEmail,
          selectOperator: true,
          section: 1,
          fields:
            'ID,DateTimeCreated,ContactForCard_key,ContactType.Label,ContactForCardName,ContactForTableName,Name,Salutation,FirstName,MiddleName,TradingAs,CalculatedName,phones.Number,mobiles.Number,faxes.Number,emails.Email,addresses.Calculated,Status',
        };
      } else if (this.isPromoterOrAdmin == true && !this.merchantID) {
        payload = {
          partEmail: this.filterEmail,
          partName: this.filterName,
          partFirstName: this.firstName,
          partMobileLastName: this.lastName,
          partCalculatedName: this.calculatedName,
          partUserName: this.partUserName,
          partPhone: this.filterPhone,
          partMobile: this.mobile,
          merchantID: this.merchantID,
          hasMobile: this.hasMobile,
          hasEmail: this.hasEmail,
          onlyMyAccount: this.onlyMyAccount,
          useStrict: this.useStrict,
          selectOperator: true,
          section: 1,
          fields:
            'ID,DateTimeCreated,ContactForCard_key,ContactType.Label,ContactForCardName,ContactForTableName,Name,Salutation,FirstName,MiddleName,TradingAs,CalculatedName,phones.Number,mobiles.Number,faxes.Number,emails.Email,addresses.Calculated,Status',
        };
      } else {
        payload = {
          partEmail: this.filterEmail,
          partName: this.filterName,
          partFirstName: this.firstName,
          partMobileLastName: this.lastName,
          partCalculatedName: this.calculatedName,
          partUserName: this.partUserName,
          partPhone: this.filterPhone,
          partMobile: this.mobile,
          hasMobile: this.hasMobile,
          hasEmail: this.hasEmail,
          useStrict: this.useStrict,
          onlyMyAccount: this.onlyMyAccount,
          section: 1,
          selectOperator: true,
          fields:
            'ID,DateTimeCreated,ContactForCard_key,ContactType.Label,ContactForCardName,ContactForTableName,Name,Salutation,FirstName,MiddleName,TradingAs,CalculatedName,phones.Number,mobiles.Number,faxes.Number,emails.Email,addresses.Calculated,Status',
        };
      }

      this.serviceRef = this.cardService.getCardList('Contact', payload, this.sessionType).subscribe((res) => {
        if (res && res.length > 0) {
          this.contacts = res;
          this.hideFilter = true;

          const innerFunction = (section) => {
            section = section + 1;
            payload.section = section;

            this.serviceRef = this.cardService.getCardList('Contact', payload, this.sessionType).subscribe((res1) => {
              if (res1.length > 0) {
                this.contacts = this.contacts.concat(res1);

                if (this.isDestoyed != true) {
                  innerFunction(section);
                }
              } else {
                return true;
              }
            });
          };

          if (this.isDestoyed != true) {
            innerFunction(1);
          }
        } else {
          NotifyAppComponent.displayToast('warning', 'Sorry!', 'We did not any match of your Staff in our database.');
        }
      });
    });
  }
  closeEvent() {
    this.closeModal.emit(true);
    this.close.emit(true);
  }

  SelectRecordClickEvent(selectedPatient) {
    if (this.viewOnClick == true) {
      this.viewDentist(selectedPatient['ID']);
    } else {
      if (selectedPatient && selectedPatient.ID) {
        let user: any = {
          ID: null,
          prospectID: null,
          existingPatientID: null,
          firstName: null,
          title: null,
          lastName: null,
          middleName: null,
          gender: null,
          mobile: null,
          homePhone: null,
          workPhone: null,
          email: null,
          birthday: null,
          dateOfBirth: null,
          tableName: 'Contact',
          TableName: 'Contact',
          existingPersonID: selectedPatient.ID,
        };

        if (selectedPatient.ID) {
          user.ID = selectedPatient.ID;
          user.prospectID = selectedPatient.ID;
        }

        if (selectedPatient.ExistingPerson_key) {
          user.existingPatientID = selectedPatient.ExistingPerson_key;
        }

        if (selectedPatient['Email']) {
          user.email = selectedPatient['Email'];
        }

        if (selectedPatient['FirstName']) {
          user.firstName = selectedPatient['FirstName'];
        }

        if (selectedPatient['LastName']) {
          user.lastName = selectedPatient['LastName'];
        }

        if (selectedPatient['Mobile'] && selectedPatient['Mobile'] != 'undefined') {
          user.mobile = selectedPatient['Mobile'];
        }

        if (selectedPatient['emails.Email']) {
          user.email = selectedPatient['emails.Email'];
        }

        if (selectedPatient['FirstName']) {
          user.firstName = selectedPatient['FirstName'];
        }

        if (selectedPatient['LastName']) {
          user.lastName = selectedPatient['LastName'];
        }

        if (selectedPatient['Name']) {
          user.lastName = selectedPatient['Name'];
        }

        if (selectedPatient['MiddleName']) {
          user.middleName = selectedPatient['MiddleName'];
        }

        if (selectedPatient['mobiles.Number'] && selectedPatient['user.mobile'] != 'undefined') {
          user.mobile = selectedPatient['mobiles.Number'];
        }

        if (selectedPatient['phones.Number'] && selectedPatient['phones.Number'] != 'undefined') {
          user.homePhone = selectedPatient['phones.Number'];
        }

        if (selectedPatient['Phone.Work.Number'] && selectedPatient['Phone.Work.Number'] != 'undefined') {
          user.workPhone = selectedPatient['Phone.Work.Number'];
        }

        if (selectedPatient['Salutation']) {
          user.title = selectedPatient['Salutation'];
        }

        if (selectedPatient['Gender.Code']) {
          user.gender = selectedPatient['Gender.Code'];
        }

        if (selectedPatient['DateOfBirth'] && selectedPatient['DateOfBirth'] != '0000-00-00') {
          user.birthday = selectedPatient['DateOfBirth'];
        }
        if (selectedPatient['DateOfBirth'] && selectedPatient['DateOfBirth'] != '0000-00-00') {
          user.dateOfBirth = selectedPatient['DateOfBirth'];
        }
        user.tableName = 'Contact';
        user.TableName = 'Contact';
        selectedPatient.tableName = 'Contact';
        selectedPatient.TableName = 'Contact';
        this.getResult.emit(user);
        this.getSelectedContact.emit(selectedPatient);
        this.getSelectedContactID.emit(user.ID);
      }
    }
  }
  viewDentist(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(DentistViewComponent, {
        data: id,
        width: '550px',
      });
      ref.componentInstance.close.subscribe((res) => {
        if (res == true) {
          ref.close();
        }
      });
    }
  }
  goBack() {
    this.hideFilter = false;
  }

  goForward() {
    this.hideFilter = true;
  }
  changePage(e) {
    if (e) {
      this.currentPage = e;
    }
  }

  displayChat(id) {
    if (id) {
      this.contactChat.emit({
        cardID: id,
        ID: id,
        merchantID: this.merchantID,
        isPromoterOrAdmin: this.isPromoterOrAdmin,
      });
    }
  }

  loginAsUser(item) {
    if (item) {
      if (item && item['ID'] && item['operatorID']) {
        const confirm = new ConfirmDialog(
          'fas fa-info',
          'You need to logout',
          'In order to change the connected user you need to logout',
          'Cancel',
          'Logout and Proceed'
        );

        const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
          data: confirm,
          disableClose: true,
        });
        ref.componentInstance.onConfirm.subscribe((confirmation) => {
          if (confirmation === false) {
            ref.close();
          } else {
            const body = {
              cardID: item['ID'],
            };
            this.AuthenticationService.loginAsUser(body).subscribe((res) => {
              if (res) {
                this.AuthenticationService.getCurrentUserForce().subscribe((getCurrentUserForce) => {
                  NotifyAppComponent.displayToast('success', 'success', 'Successful operation');

                  if (getCurrentUserForce && getCurrentUserForce.data) {
                    if (
                      getCurrentUserForce.data.SessionType == 'admin' ||
                      getCurrentUserForce.data.SessionType == 'merchant' ||
                      getCurrentUserForce.data.SessionType == 'merchant-admin' ||
                      getCurrentUserForce.data.SessionType == 'promoter'
                    ) {
                      UtilsClass.startLoading();
                      window.location.href = Settings.global['merchantFrontendLink'] + '/merchant';
                    } else if (getCurrentUserForce.data.SessionType == 'customer') {
                      UtilsClass.startLoading();
                      window.location.href = Settings.global['consumerFrontendLink'] + '/consumer-app/(page:home)';
                    }
                  }
                });
              }
            });
          }
        });
      }
    }
  }
  contactDentist(id) {
    if (id) {
      var data = {
        targetType: 'contact',
        targetID: id,
        asGuest: false,
        asProfile: true,
        asPractice: true,
      };
      const ref = RootAppComponent.dialog.open(ContactInputComponent, {
        width: '650px',
        data,
      });

      ref.componentInstance.closeModal.subscribe((res) => {
        ref.close();
      });
    }
  }

  newStaffDetails() {
    this.closeEvent();
    this.router.navigate(['/merchant', { outlets: { page: ['dentist-invite-create'] } }]);
  }
  newStaff() {
    const ref2 = RootAppComponent.dialog.open(ContactCreateEditModalComponent, {
      data: {
        merchantID: this.merchantID,
        isEmailRequired: true,
      },
      width: '850px',
    });

    ref2.componentInstance.close.subscribe((res) => {
      ref2.close();
    });
    ref2.componentInstance.getResult.subscribe((res) => {
      if (res && res.ID) {
        ref2.close();
        this.contacts = [res];
        this.hideFilter = true;

        this.getNewContact.emit(res);
      }
    });
  }

  operatorAccess(operatorID) {
    if (operatorID) {
      const ref = RootAppComponent.dialog.open(OperatorLoginAccessComponent, {
        data: {
          operatorID: operatorID,
        },
        width: '480px',
        panelClass: ['noCard'],
      });
      ref.componentInstance.getResult.subscribe((res) => {
        ref.close();
      });
      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    }
  }

  passwordUpdate(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(PasswordPromptComponent, {
        data: {
          operatorID: id,
        },
        width: '650px',
        panelClass: 'noCard',
      });

      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
      ref.componentInstance.getResult.subscribe((res) => {
        ref.close();
      });
    }
  }

  goPageOne() {
    this.currentPage = 1;
  }
}
