<div class="padded" style="overflow: hidden">
  <ipv-data-table
    [table]="report.fullTable"
    [paginate]="false"
    [sectionColumn]="'label'"
    [sections]="[
      { value: 'Borrower Fees', color: '#CE066B', fontColor: 'white' },
      { value: 'Borrower Interest', color: '#CE066B', fontColor: 'white' },
      { value: 'Merchant Fees', color: '#168BDD', fontColor: 'white' },
      { value: 'Total Borrower Interest & Fees', color: '#E7E9EB', fontColor: 'black', customLayout: true },
      { value: 'Total Revenue', color: '#E7E9EB', fontColor: 'black', customLayout: true }
    ]"
    [rename]="{
      accruedNet: 'Net',
      accruedPercentage: 'Accrued %',
      waviersOrRebates: 'Waviers / Rebates'
    }"
    [formatHeaders]="{
      accrued: 'right',
      waviersOrRebates: 'right',
      accruedNet: 'right',
      accruedPercentage: 'right'
    }"
    [customComponents]="{
      label: label,
      accrued: accrued,
      waviersOrRebates: waviersOrRebates,
      accruedNet: net,
      accruedPercentage: accruedPercentage
    }"
  >
  </ipv-data-table>

  <!--  CUSTOM COMPONENTS -->
  <ng-container>
    <ng-template #label let-record>
      <div
        [ngStyle]="{
          'font-weight': isTotalSection(record.label) ? 'bold' : 'normal'
        }"
      >
        {{ record.label }}
      </div>
    </ng-template>

    <ng-template #accrued let-record>
      <div [ngStyle]="{ 'font-weight': isTotalSection(record.label) ? 'bold' : 'normal' }" style="text-align: right">
        {{ record.accrued | customCurrency }}
      </div>
    </ng-template>

    <ng-template #waviersOrRebates let-record>
      <div
        *ngIf="record.waviersOrRebates !== 0"
        [ngStyle]="{ 'font-weight': isTotalSection(record.label) ? 'bold' : 'normal' }"
        style="text-align: right; color: red"
      >
        {{ record.waviersOrRebates | invert | customCurrency }}
      </div>

      <div
        *ngIf="record.waviersOrRebates === 0"
        [ngStyle]="{ 'font-weight': isTotalSection(record.label) ? 'bold' : 'normal' }"
        style="text-align: right; color: red"
      >
        {{ record.waviersOrRebates | customCurrency }}
      </div>
    </ng-template>

    <ng-template #net let-record>
      <div
        [ngStyle]="{ 'font-weight': isTotalSection(record.label) ? 'bold' : 'normal' }"
        style="text-align: right; color: #1b8bdd"
      >
        {{ record.accruedNet | customCurrency }}
      </div>
    </ng-template>

    <ng-template #accruedPercentage let-record>
      <div [ngStyle]="{ 'font-weight': isTotalSection(record.label) ? 'bold' : 'normal' }" style="text-align: right">
        {{ record.accruedPercentage + '%' }}
      </div>
    </ng-template>
  </ng-container>
</div>
