import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { Settings } from '../../../shared/types/settings';

import { animate, group, keyframes, query, stagger, state, style, transition, trigger } from '@angular/animations';
import { BookingModalComponent } from '../../helper/booking-modal/booking-modal.component';

@Component({
  selector: 'app-card-footer',
  templateUrl: './card-footer.component.html',
  styleUrls: ['./card-footer.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.row', style({ opacity: 0 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.row', style({ opacity: 1 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
    trigger('slideInOut', [
      state('in', style({ height: '*', opacity: 0 })),
      transition(':leave', [
        style({ height: '*', opacity: 1 }),

        group([animate(300, style({ height: 0 })), animate('200ms ease-in-out', style({ opacity: '0' }))]),
      ]),
      transition(':enter', [
        style({ height: '0', opacity: 0 }),

        group([animate(300, style({ height: '*' })), animate('400ms ease-in-out', style({ opacity: '1' }))]),
      ]),
    ]),
  ],
})
export class CardFooterComponent implements OnInit {
  @Input()
  firstBackgroundWhite = true;

  @Input()
  featureType = 'all';

  @Input()
  items = ['plans', 'plans-overview', 'plans-price', 'features', 'free', 'demo'];

  _items = [];

  root = '/merchant';

  context = Settings.global['context']
  constructor(private router: Router) {}

  ngOnInit() {
    this.setup();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.setup();
  }

  setup() {
    if (this.featureType === 'consumer') {
      this.root = '/consumer';
    } else {
      this.root = '/merchant';
    }
    if (this.items && this.items.length > 0) {
      const result = [];
      let prefix = false;
      for (let i = 0; i < this.items.length; i++) {
        let item = this.items[i];

        if (prefix === true) {
          item = '-' + item;
        }

        prefix = !prefix;

        result.push(item);
      }

      this._items = JSON.parse(JSON.stringify(result));
    }
  }

  getBackgroundColor(index) {
    if (index % 2 == 0) {
      if (this.firstBackgroundWhite === true) {
        return '#ffffff';
      } else {
        return '#ebebeb66';
      }
    } else {
      if (this.firstBackgroundWhite === true) {
        return '#ebebeb66';
      } else {
        return '#ffffff';
      }
    }
  }

  display(code) {
    const index = this.items.indexOf(code);
    if (index !== -1) {
      return true;
    }

    return false;
  }

  comparePlans() {
    this.router.navigate([
      this.root,
      {
        outlets: {
          page: ['subscription-plan-overview-all', this.featureType],
        },
      },
    ]);
  }

  Features() {
    this.router.navigate([
      this.root,
      {
        outlets: {
          page: ['main-feature-list', this.featureType],
        },
      },
    ]);
  }

  comparePlansPrice() {
    this.router.navigate([
      this.root,
      {
        outlets: {
          page: ['subscription-plan-list-card', this.featureType],
        },
      },
    ]);
  }

  booking() {
    const ref = RootAppComponent.dialog.open(BookingModalComponent, {
      width: '700px',
      panelClass: '',
    });

    ref.componentInstance.closeModal.subscribe((data) => {
      ref.close();
    });
  }

  freeAccount() {
    window.open(Settings['global']['publicSiteFrontendLink'] + '/partners/(page:pricing-free)', '_blank');
  }
}
