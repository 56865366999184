import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from "@angular/core";
import { trigger, style, transition, animate, keyframes, query, stagger, state } from "@angular/animations";
import { Settings } from "../../../shared/types/settings";
import { UtilsService } from "../../../shared/services/utils.service";
import * as moment from "moment";
import { AuthenticationService } from "../../../core/authentication/shared/authentication.service";
import { CustomPhonePipe } from "../../../shared/pipes/custom-phone.pipe";
import { UtilsClass } from "../../../shared/types/utils/utils.class";
import { InvitationTemplateService } from "../shared/invitation-template.service";
import { Router, ActivatedRoute } from "@angular/router";
import * as _ from "lodash";
import { WizardComponent } from "angular-archwizard";
import { ConfirmDialog } from "../../../shared/types/confirm-dialog";
import { ConfirmDialogComponent } from "../../../shared/components/confirm-dialog/confirm-dialog.component";
import {
  VideoTutorialsModalComponent
} from "../../../shared/components/video-tutorials-modal/video-tutorials-modal.component";
import { DentistService } from "../../dentist/shared/dentist.service";
import { DefaultMessageService } from "../../default-message/shared/default-message.service";
import { MerchantService } from "../../merchant/shared/merchant.service";
import { RootAppComponent } from "../../../shared/components/root-component/root-component.component";
import { ContactViewComponent } from "../../contact/contact-view/contact-view.component";
import { DiscountSettingComponent } from "../../merchant/discount-setting/discount-setting.component";
import { DocumentUrlViewComponent } from "../../../shared/components/document-url-view/document-url-view.component";
import { MerchantViewComponent } from "../../merchant/merchant-view/merchant-view.component";
import {
  TreatmentDocumentImportOverviewComponent
} from "../../treatment/treatment-document-import-overview/treatment-document-import-overview.component";
import { DiscountEditComponent } from "../../invitation/discount-edit/discount-edit.component";
import { InvitationTemplateMergerComponent } from "../invitation-template-merger/invitation-template-merger.component";
import { InvitationTemplateLookupComponent } from "../invitation-template-lookup/invitation-template-lookup.component";
import { CustomCurrencyPipe } from "../../../shared/pipes/custom-currency.pipe";
import {
  MessageTemplateCreateEditComponent
} from "../../default-message/message-template-create-edit/message-template-create-edit.component";
import { NotifyAppComponent } from "../../../shared/types/notify-app-component";
import { ProductUtil } from "../../product/shared/productUtil.type";
import { environment } from "environments/environment";

@Component({
  selector: "app-invitation-template-create-edit",
  templateUrl: "./invitation-template-create-edit.component.html",
  styleUrls: ["./invitation-template-create-edit.component.css"],
  animations: [
    trigger("visibilityChanged", [
      state("shown", style({ opacity: 1 })),
      state("hidden", style({ opacity: 0, height: "0" })),
      transition("shown => hidden", animate("600ms")),
      transition("hidden => shown", animate("300ms"))
    ]),
    trigger("ngIfAnimation", [
      transition("void => *", [
        query(".animate", style({ opacity: 0 }), { optional: true }),
        query(
          ".animate",
          stagger("100ms", [
            animate(
              "0.2s ease-out",
              keyframes([
                style({ opacity: 0, offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, offset: 1.0, height: "*" })
              ])
            )
          ]),
          { optional: true }
        )
      ]),
      transition("* => void", [
        query(".animate", style({ opacity: 1 }), { optional: true }),
        query(
          ".animate",
          stagger("100ms", [
            animate(
              "0.2s ease-in",
              keyframes([
                style({ opacity: 1, offset: 0, height: "*" }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, offset: 1.0, height: 0 })
              ])
            )
          ]),
          { optional: true }
        )
      ])
    ])
  ]
})
export class InvitationTemplateCreateEditComponent implements OnInit {
  @ViewChild("wizard", { static: true }) wizard: WizardComponent;
  isDone = false;
  label;
  displayList = false;
  documentTypes = ["Treatment Plan", "Additional Document"];
  documentTypes2 = ["Image", "Scan / X-Ray"];
  isdefaultMessageLoaded = false;
  selectedProductGroup;
  isPromoterOrAdmin = false;
  dentMsg;
  message = {
    label: null,
    viaSMS: null,
    content: null,
    contentRaw: null,
    defaultID: null
  };

  productUtil = new ProductUtil();
  description;
  hideDiscount = false;
  messageOption;
  documents: any = [];
  documentsDetailed: any = [];
  EPstockArtDocuments = [];
  stockArtDocuments = [];
  stockArtDocumentsDetailed = [];
  templates = [];
  cashDiscountAllowOverride = true;
  EPTreatmentDocuments = [];

  @Input()
  merchantID = localStorage.getItem("selectedMerchant");

  selectedMerchants = [];
  defaultMessageLists = [];
  informedConsent = [
    {
      categoryID: null,
      itemCode: null,
      typeID: null,
      brandID: null,
      productID: null,
      preDocuments: [],
      stockArtDocuments: [],
      postDocuments: [],
      marketingDocuments: []
    }
  ];
  resultInformedConsent = [];
  isModuleAdvancedTreatmentActive = Settings.global["isModuleAdvancedTreatmentActive"];
  isModulePrivateDocumentActive = Settings.global["isModulePrivateDocumentActive"];
  isModuleExperimentalFeaturesActive = Settings.global["isModuleExperimentalFeaturesActive"];
  isModuleTreatmentGroupActive = Settings.global["isModuleTreatmentGroupActive"];
  isModuleDiscountsActive = Settings.global["isModuleDiscountsActive"];
  isModuleCustomMessages = Settings.global["isModuleCustomMessagesActive"];
  isModulePatientHistoryActive = Settings.global["isModulePatientHistoryActive"];
  isModuleTreatmentPlanActive = Settings.global["isModuleTreatmentPlanActive"];
  isModuleStockArtActive = Settings.global["isModuleStockArtActive"];
  isModuleGoogleReviewActive = Settings.global["isModuleGoogleReviewActive"];
  isModuleTreatmentTemplateActive = Settings.global["isModuleTreatmentTemplateActive"];
  treatmentAmount = 0;
  cashDiscountDate;
  cashDiscountAmount = 0;
  medicalHistory = false;
  googleReviewPrompt = false;
  attachTags = true;
  cashDiscountOveridden = true;
  cashDiscountOffered = false;
  cashDiscountMinAmount;
  cashDiscountPercent;
  cashDiscountExpiredDays;
  addFile = new EventEmitter();
  addFileStockArt = new EventEmitter();
  utils = new UtilsClass();
  merchant;
  merchants = [];
  isDefault = false;
  template;
  contactID;
  contact;
  sender;
  contacts = [];
  currentUser;
  isDownload = false;
  isRedirection = false;
  isPrint = false;
  templateID;
  defaultMessageID;
  showMyGallery = false;

  rawContent;
  typeFilter;
  sessionType = "merchant";

  defaultSelectedTags = [];

  defaultSelectedTagsGlobal = [];

  selectedTags = [];

  selectedTagsGlobal = [];

  productGroup = "all";

  productGroups = [
    {
      label: "All Product Groups",
      code: "all"
    }
  ];

  promoter;

  constructor(
    private activeRoute: ActivatedRoute,
    private dentistService: DentistService,
    private invitationTemplateService: InvitationTemplateService,
    private merchantService: MerchantService,
    private authenticationService: AuthenticationService,
    private utilService: UtilsService,
    private customePhonePipe: CustomPhonePipe,
    private defaultMessageSerivce: DefaultMessageService,
    private customCurrencyPipe: CustomCurrencyPipe
  ) {
  }

  ngOnInit() {
    this.invitationTemplateService.getProductGroup().subscribe((r) => {
      if (r && r.length > 0) {
        r.map((item) => {
          if (item["Group.Code"] && item["Group.Label"]) {
            let t = {
              code: item["Group.Code"],
              label: item["Group.Label"]
            };

            this.productGroups.push(t);
          }
        });
      }
      this.productGroups = JSON.parse(JSON.stringify(this.productGroups));
    });

    this.activeRoute.params.subscribe((params) => {
      if (params["templateID"]) {
        this.templateID = params["templateID"];
      }

      this.authenticationService.getSessionType().subscribe((sessionType) => {
        if (sessionType) {
          this.sessionType = sessionType;
        }
        this.authenticationService.getPromoter().subscribe((promoter) => {


          if (promoter && promoter.data) {
            this.promoter = promoter.data;
          } else if (promoter) {
            this.promoter = promoter;
          }


        });
        this.authenticationService.isPromoterOrAdmin().subscribe((res1) => {
          this.isPromoterOrAdmin = res1;
          if (this.isPromoterOrAdmin == true) {
            this.isDefault = true;
            this.isDownload = true;
            this.isRedirection = true;
            this.isPrint = true;
          }
          this.authenticationService.getCurrentUser().subscribe((res) => {
            this.currentUser = res;
            this.utilService.getCurrentAccess().subscribe((access) => {
              if (access) {
                this.isModuleAdvancedTreatmentActive = access["isModuleAdvancedTreatmentActive"];
                this.isModulePrivateDocumentActive = access["isModulePrivateDocumentActive"];
                this.isModuleExperimentalFeaturesActive = access["isModuleExperimentalFeaturesActive"];
                this.isModuleTreatmentGroupActive = access["isModuleTreatmentGroupActive"];
                this.isModuleDiscountsActive = access["isModuleDiscountsActive"];
                this.isModuleCustomMessages = access["isModuleCustomMessagesActive"];
                this.isModulePatientHistoryActive = access["isModulePatientHistoryActive"];
                this.isModuleTreatmentPlanActive = access["isModuleTreatmentPlanActive"];
                this.isModuleStockArtActive = access["isModuleStockArtActive"];
                this.isModuleTreatmentTemplateActive = access["isModuleTreatmentTemplateActive"];
              }
              if (this.templateID) {
                let payload = {
                  merchantID: this.merchantID
                };
                this.invitationTemplateService
                  .getInvitationTemplateDetails(this.templateID, payload, this.isPromoterOrAdmin)
                  .subscribe((res) => {
                    if (res) {
                      this.template = res;

                      this.bindData();

                      if (this.isPromoterOrAdmin != true) {
                        this.authenticationService.getCurrentPractice().subscribe((res) => {
                          if (res) {
                            this.merchant = res;
                            this.setup();
                          }
                        });
                      } else if (this.merchantID && this.isPromoterOrAdmin == true) {
                        let payload = {
                          merchantID: this.merchantID,
                          fields: "ID,TradingAs,CalculatedName,Status"
                        };
                        this.selectedMerchants.push(this.merchantID);
                        this.merchantService.getList(payload, this.isPromoterOrAdmin).subscribe((merchants) => {
                          if (merchants) {
                            this.merchants = merchants;
                          }
                        });

                        this.merchantService
                          .getMerchantDetails(this.merchantID, {}, this.sessionType)
                          .subscribe((res) => {
                            if (res) {
                              this.merchant = res;
                              this.setup();
                            }
                          });
                      } else if (!this.merchantID && this.isPromoterOrAdmin == true) {
                        this.authenticationService.getCurrentPractice().subscribe((res) => {
                          if (res) {
                            this.merchant = res;
                            this.merchantID = res["ID"];
                            let payload = {
                              fields: "ID,TradingAs,CalculatedName,Status"
                            };
                            this.merchantService.getList(payload, this.isPromoterOrAdmin).subscribe((merchants) => {
                              if (merchants) {
                                this.merchants = merchants;
                                this.setup();
                              }
                            });
                          }
                        });
                      }
                    }
                  });
              } else {
                if (this.isPromoterOrAdmin != true) {
                  this.authenticationService.getCurrentPractice().subscribe((res) => {
                    if (res) {
                      this.merchant = res;
                      this.setup();
                    }
                  });
                } else if (this.merchantID && this.isPromoterOrAdmin == true) {
                  this.merchantService.getMerchantDetails(this.merchantID, {}, this.sessionType).subscribe((res) => {
                    if (res) {
                      this.merchant = res;
                      this.setup();
                    }
                  });
                } else if (!this.merchantID && this.isPromoterOrAdmin == true) {
                  this.authenticationService.getCurrentPractice().subscribe((res) => {
                    if (res) {
                      this.merchant = res;
                      this.merchantID = res["ID"];
                      let payload = {
                        fields: "ID,TradingAs,CalculatedName,Status"
                      };
                      this.merchantService.getList(payload, this.isPromoterOrAdmin).subscribe((merchants) => {
                        if (merchants) {
                          this.merchants = merchants;
                          this.setup();
                        }
                      });
                    }
                  });
                }
              }
            });
          });
        });
      });
    });
  }

  convertMessageEvent() {
    if (this.rawContent) {
      let payload: any = {
        rawContent: this.rawContent,
        isPatientPlaceHolder: true,
        isDentistPlaceHolder: true,
        isMerchantPlaceHolder: true,
        merchant: this.merchant
      };
      if (this.isDefault != true) {
        payload.dentist = this.sender;
      }

      this.defaultMessageSerivce
        .convertMessage(null, payload, this.customePhonePipe, this.isPromoterOrAdmin)
        .subscribe((res) => {
          if (res) {
            this.dentMsg = res;
          }
        });
    }
  }

  bindData() {
    if (this.template) {
      if (this.template.type == "default" && this.isPromoterOrAdmin == true) {
        this.isDefault = true;
      } else {
        this.isDefault = false;
      }
      if (this.template.label) {
        this.label = this.template.label;
      }

      if (this.template.userID) {
        this.merchantID = this.template.userID;
      }

      if (this.template.description) {
        this.description = this.template.description;
        if (this.description === " ") {
          this.description = "";
        }
      }

      if (this.template && this.template.content) {
        const content = this.template.content;

        if (content.tags && content.tags.length > 0) {
          this.defaultSelectedTags = JSON.parse(JSON.stringify(content.tags));
          this.defaultSelectedTagsGlobal = JSON.parse(JSON.stringify(content.tags));

          this.selectedTags = JSON.parse(JSON.stringify(content.tags));
          this.selectedTagsGlobal = JSON.parse(JSON.stringify(content.tags));
        } else {
          this.defaultSelectedTags = JSON.parse(JSON.stringify([]));
          this.defaultSelectedTagsGlobal = JSON.parse(JSON.stringify([]));
          this.selectedTags = JSON.parse(JSON.stringify([]));
          this.selectedTagsGlobal = JSON.parse(JSON.stringify([]));
        }

        if (content.cashDiscountOffered !== null) {
          this.cashDiscountOffered = content.cashDiscountOffered;
          this.cashDiscountAllowOverride = content.cashDiscountAllowOverride;
          this.cashDiscountPercent = content.cashDiscountPercent;
        }
        if (content.totalTreatmentValue) {
          this.treatmentAmount = content.totalTreatmentValue;
        }
        if (content.cashDiscountOffered === true) {
          this.cashDiscountOffered = true;
        } else {
          this.cashDiscountOffered = false;
        }
        if (content.totalTreatmentValue) {
          this.treatmentAmount = content.totalTreatmentValue;
        }
        if (content.treatmentDocuments && content.treatmentDocuments.length > 0) {
          this.documents = JSON.parse(JSON.stringify(content.treatmentDocuments));
        } else {
          this.documents = JSON.parse(JSON.stringify([]));
        }
        if (content.stockArtDocuments && content.stockArtDocuments.length > 0) {
          this.stockArtDocuments = JSON.parse(JSON.stringify(content.stockArtDocuments));
        } else {
          this.stockArtDocuments = JSON.parse(JSON.stringify([]));
        }
        if (content.EPstockArtDocuments && content.EPstockArtDocuments.length > 0) {
          this.EPstockArtDocuments = JSON.parse(JSON.stringify(content.EPstockArtDocuments));
        } else {
          this.EPstockArtDocuments = JSON.parse(JSON.stringify([]));
        }

        if (content.EPTreatmentDocuments && content.EPTreatmentDocuments.length > 0) {
          this.EPTreatmentDocuments = JSON.parse(JSON.stringify(content.EPTreatmentDocuments));
        } else {
          this.EPTreatmentDocuments = JSON.parse(JSON.stringify([]));
        }

        if (content.treatmentPlan && content.treatmentPlan.length > 0) {
          this.informedConsent = JSON.parse(JSON.stringify(content.treatmentPlan));
          this.resultInformedConsent = JSON.parse(JSON.stringify(content.treatmentPlan));
        } else {
          this.informedConsent = JSON.parse(
            JSON.stringify([
              {
                categoryID: null,
                itemCode: null,
                typeID: null,
                brandID: null,
                productID: null,
                showStockArtDocumentsModal: true,
                stockArtDocuments: [],
                preDocuments: [],
                postDocuments: [],
                marketingDocuments: []
              }
            ])
          );
          this.resultInformedConsent = [];
        }
        if (content && content.message && content.message.defaultID) {
          this.defaultMessageID = content.message.defaultID;
        }
        if (content && content.message && content.message.content) {
          this.dentMsg = content.message.content;
        }

        if (content && content.message && content.message.contentRaw) {
          this.rawContent = content.message.contentRaw;
        }

        if (content && content.templates && content.templates.length > 0) {
          this.templates = content.templates;
        } else {
          this.templates = [];
        }

        if (content && content.message) {
          this.message = content.message;
        }

        if (content && content.medicalHistory) {
          this.medicalHistory = content.medicalHistory;
        }

        if (content && content.googleReviewPrompt) {
          this.googleReviewPrompt = content.googleReviewPrompt;
        }

        this.convertMessageEvent();
        this.calculCashDiscount();
      }
    }
  }

  toggleChange(e) {
    if (this.cashDiscountOffered != e) {
      this.cashDiscountOffered = e;
    }
    if (this.cashDiscountOffered == true) {
      this.calculCashDiscount();
    }
  }

  InvitationTemplateLookup() {
    let selectAll = true;

    if (this.templates && this.templates.length > 0) {
      selectAll = false;
    }
    let ref = RootAppComponent.dialog.open(InvitationTemplateLookupComponent, {
      data: {
        merchantID: this.merchantID,
        defaultTreatmentAmount: this.treatmentAmount || 0,
        isModal: true,
        viewOnClick: true,
        viewOnly: false,
        selectAll: selectAll
      },
      panelClass: "bigger-screen",
      width: "820px"
    });
    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
    ref.componentInstance.getResult.subscribe((result) => {
      if (result && result.templateID) {
        let content = this.buildContent() || {};
        content.templates = this.templates;
        let payload = {
          invitationTemplateID: result.templateID,
          templateID: result.templateID,
          selectDocuments: result.selectDocuments,
          selectImages: result.selectImages,
          selectTreatmentPlan: result.selectTreatmentPlan,
          selectTags: result.selectTags,
          selectTreatmentValue: result.selectTreatmentValue,
          selectMessage: result.selectMessage,
          selectDiscount: result.selectDiscount,
          selectHealthHistory: result.selectHealthHistory,
          selectGoogleReview: result.selectGoogleReview,
          treatmentValue: result.treatmentValue,
          source: content
        };

        this.invitationTemplateService.mergeInvitationTemplate(payload, this.isPromoterOrAdmin).subscribe((res) => {
          if (res) {
            if (!this.template) {
              this.template = {};
            }
            this.template.content = res;

            if (this.isDefault == true && this.isPromoterOrAdmin == true) {
              this.template.type = "default";
            } else {
              this.template.type = "user";
            }
            this.bindData();
          }

          ref.close();
        });
      }
    });
  }

  clearTreatment(template) {
    if (template && template.id) {
      let confirm = new ConfirmDialog(
        "delete",
        "Clear Invitation Template",
        "Are you sure you want to remove this template with all it content?",
        "No",
        "Yes"
      );
      if (template && template.options && template.options.selectTreatmentValue == true && template.treatmentValue) {
        confirm = new ConfirmDialog(
          "delete",
          "Clear Invitation Template",
          "By clearing this Template, an amount of <strong>" +
          this.customCurrencyPipe.transform(template.treatmentValue) +
          "</strong> will be reduced from your treatment value . <br><br>" +
          "Are you sure you want to remove this template with all it content?",
          "No",
          "Yes"
        );
      }

      let ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
        width: "500px"
      });
      ref.componentInstance.onConfirm.subscribe((res) => {
        if (res == true) {
          let content = this.buildContent() || {};
          content.templates = this.templates;

          let payload = {
            invitationTemplateID: template.id,
            templateID: template.id,
            source: content
          };

          this.invitationTemplateService.clearInvitationTemplate(payload, this.isPromoterOrAdmin).subscribe((res) => {
            if (res) {
              if (!this.template) {
                this.template = {};
              }
              this.template.content = res;
              if (this.isDefault == true && this.isPromoterOrAdmin == true) {
                this.template.type = "default";
              } else {
                this.template.type = "user";
              }
              this.bindData();
            }

            ref.close();
          });
        } else {
          ref.close();
        }
      });
    }
  }

  viewSavedTreatment(template) {
    if (template && template.id && template.options) {
      let ref = RootAppComponent.dialog.open(InvitationTemplateMergerComponent, {
        data: {
          templateID: template.id,
          selectDocuments: template.options.selectDocuments,
          selectImages: template.options.selectImages,
          selectTreatmentPlan: template.options.selectTreatmentPlan,
          selectTags: template.options.selectTags,
          selectTreatmentValue: template.options.selectTreatmentValue,
          selectMessage: template.options.selectMessage,
          selectDiscount: template.options.selectDiscount,
          selectHealthHistory: template.options.selectHealthHistory,
          selectGoogleReview: template.options.selectGoogleReview,
          displaySelected: true,
          viewOnly: true
        },
        width: "1200px"
      });
      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    }
  }

  getStockArtDocumentsEvent(docs) {
    if (docs && docs.length > 0) {
      let EPstockArtDocuments = this.EPstockArtDocuments;

      docs = _.uniq(docs);

      if (this.EPstockArtDocuments && this.EPstockArtDocuments.length > 0) {
        this.EPstockArtDocuments = _.uniq(docs.concat(EPstockArtDocuments));
      } else {
        this.EPstockArtDocuments = _.uniq(docs.concat(EPstockArtDocuments));
      }
    }
  }

  getTreatmentDocumentsEvent(docs) {
    if (docs && docs.length > 0) {
      let EPTreatmentDocuments = this.EPTreatmentDocuments;

      docs = _.uniq(docs);

      if (this.EPTreatmentDocuments && this.EPTreatmentDocuments.length > 0) {
        this.EPTreatmentDocuments = _.uniq(docs.concat(EPTreatmentDocuments));
      } else {
        this.EPTreatmentDocuments = _.uniq(docs.concat(EPTreatmentDocuments));
      }
    }
  }

  viewContact() {
    if (this.contactID) {
      let ref = RootAppComponent.dialog.open(ContactViewComponent, {
        data: this.contactID,
        width: "600px"
      });
      ref.componentInstance.close.subscribe((res) => {
        if (res == true) {
          ref.close();
        }
      });
    }
  }

  setup() {
    if (this.isModuleStockArtActive == true) {
      let payload = {
        section: 0,
        limit: 1,
        merchantID: this.merchantID,
        orderBy: "-DateTimeCreated",
        fields: "ID",
        type: "SRVSTC"
      };

      this.utilService.getDocumentList(payload, this.sessionType).subscribe((res) => {
        if (res && res.length && res.length > 0) {
          this.showMyGallery = true;
        } else {
          this.showMyGallery = false;
        }
      });
    }

    if (this.isModuleTreatmentPlanActive == true) {
      if ((this.selectedMerchants && this.selectedMerchants.length == 1) || this.isPromoterOrAdmin != true) {
        let payload;
        if (this.isPromoterOrAdmin == true && this.merchantID) {
          payload = {
            fields: "ID,CalculatedName,ContactForCard_key",
            merchantID: this.merchantID
          };
        } else {
          this.merchantID = this.selectedMerchants[0];
          payload = {
            fields: "ID,CalculatedName,ContactForCard_key"
          };
        }
        this.dentistService.getFiltersContact(payload, this.isPromoterOrAdmin).subscribe((res) => {
          this.contacts = res;
          if (this.template && this.template.type != "default") {
            if (this.template.content && this.template.content.message && this.template.content.message.sender) {
              this.contactID = this.template.content.message.sender;
            }
          }
          if (this.currentUser.data["contactKey"] && !this.contactID) {
            this.contactID = this.currentUser.data["contactKey"];
            this.dentistService.getContactDetails(this.contactID, {}, this.sessionType).subscribe((res) => {
              if (res) {
                this.sender = res;

                this.convertMessageEvent();
              }
            });
          }
          if (this.contactID) {
            for (var i = 0; i < this.contacts.length; i++) {
              if (this.contactID == this.contacts[i]["ID"]) {
                this.contact = this.contacts[i];
              }
            }
          }
        });
      }
    }
    if (this.isModuleCustomMessages == true) {
      this.getDefaultMessageList(this.merchantID);
    }
    if (this.treatmentAmount >= 0) {
      this.changeTreatmentValue();
    }
  }

  getSelectedTags(e) {
    this.selectedTags = e || [];
  }

  getSelectedTagsGlobal(e) {
    this.selectedTagsGlobal = e || [];
  }

  changeMerchant() {
    if (this.selectedMerchants && this.selectedMerchants.length == 1 && Array.isArray(this.selectedMerchants)) {
      this.merchantID = this.selectedMerchants[0];
      this.merchantService.getMerchantDetails(this.merchantID, {}, this.sessionType).subscribe((res) => {
        if (res) {
          this.merchant = res;

          this.setup();
        }
      });
    } else if (this.selectedMerchants && typeof this.selectedMerchants == "string") {
      this.merchantID = this.selectedMerchants;
      this.merchantService.getMerchantDetails(this.merchantID, {}, this.sessionType).subscribe((res) => {
        if (res) {
          this.merchant = res;

          this.setup();
        }
      });
    } else {
      this.merchantID = null;
      this.authenticationService.getCurrentPractice().subscribe((res) => {
        if (res) {
          this.merchant = res;
          this.setup();
        }
      });
    }
  }

  onCompleteAllDetailsSimple(data) {
    if (data && data[0]) {
      data[0].fields =
        "AWS_URL,AWS_Bucket_Name,DocumentType_Code,DateAdded,DateTimeCreated,Description,Extension,ID,Label,URL.Complete,Tags,ContainsSensitiveData,IsGallery";
      data[0].thumbnailFileID = null;
      data[0].fileID = data[0]._id;
      data[0].label = data[0].fileName;
      data[0].description = null;
      data[0].type = "TRP";
      data[0].merchantID = this.merchantID;
      this.addFile.emit(data[0]._id);
      this.documents.push(data[0]._id);
      this.documentsDetailed.push(data[0]);
    }
  }

  getLabelFromProductGroup(p) {
    if (p && p["GroupLabel"]) {
      let t = p["GroupLabel"].split(" - ");
      if (t && t[0]) {
        if (t[0][t.length - 1] == "s") {
          return t[0].substr(0, t[0].length - 1);
        } else if (t[0][t.length - 2] == "s") {
          return t[0].substr(0, t[0].length - 2);
        }
        return t[0];
      }
      return null;
    }
    return null;
  }

  openDiscountSettingDialog() {
    let ref = RootAppComponent.dialog.open(DiscountSettingComponent, {
      data: {
        merchantID: this.merchantID
      },
      width: "550px"
    });
    ref.componentInstance.closeModal.subscribe((res) => {
      ref.close();
    });
    ref.componentInstance.getMerchant.subscribe((res) => {
      if (res) {
        this.merchant = res;
        this.calculCashDiscount();
      }
      ref.close();
    });
  }

  proceed() {
    UtilsClass.scrollUp();
    this.wizard.goToNextStep();
  }

  stepBack() {
    UtilsClass.scrollUp();
    this.wizard.goToPreviousStep();
  }

  stepNext() {
    UtilsClass.scrollUp();
    this.wizard.goToNextStep();
  }

  openDiscountInvitationDialog() {
    let ref = RootAppComponent.dialog.open(DiscountEditComponent, {
      data: {
        merchantID: this.merchantID,
        treatmentAmount: this.treatmentAmount,
        cashDiscountPercent: this.cashDiscountPercent,
        cashDiscountExpiredDays: this.cashDiscountExpiredDays,
        cashDiscountOffered: this.cashDiscountOffered
      },
      width: "600px"
    });
    ref.componentInstance.closeModal.subscribe((res) => {
      ref.close();
    });
    ref.componentInstance.getResult.subscribe((res) => {
      if (res && res.merchant) {
        this.cashDiscountPercent = res.cashDiscountPercent;
        this.cashDiscountExpiredDays = res.cashDiscountExpiredDays;
        this.cashDiscountOffered = res.cashDiscountOffered;

        this.calculCashDiscount();
      }
      ref.close();
    });
  }

  getDefaultMessageList(merchantID = null) {
    if (this.isModuleCustomMessages == true) {
      if (this.isPromoterOrAdmin != true) {
        this.isdefaultMessageLoaded = false;
        let payload = {
          purposeCode: "MerInv",
          orderBy: "Label",
          onlyActive: true,
          selectDefault: true
        };

        this.defaultMessageSerivce.getDefaultMessageList(payload).subscribe((res) => {
          if (res && res.length > 0) {
            for (var i = 0; i < res.length; i++) {
              let r = {
                ID: res[i]["ID"],
                label: res[i]["Label"],
                value: res[i]["Content"],
                IsPromoterTemplate: res[i]["IsPromoterTemplate"]
              };
              this.defaultMessageLists.push(r);

              if (this.defaultMessageID && this.defaultMessageID == res[i]["ID"]) {
                this.messageOption = res[i];
              }
            }
          } else {
            this.defaultMessageLists = [];
          }
        });
      } else if (
        this.isPromoterOrAdmin == true &&
        this.isdefaultMessageLoaded != true &&
        this.isDefault != true &&
        merchantID == null
      ) {
        let payload = {
          purposeCode: "MerInv",
          orderBy: "Label",
          onlyActive: true,
          selectDefault: true
        };
        this.defaultMessageSerivce.getDefaultMessageList(payload).subscribe((res) => {
          if (res && res.length > 0) {
            for (var i = 0; i < res.length; i++) {
              let r = {
                ID: res[i]["ID"],
                label: res[i]["Label"],
                value: res[i]["Content"],
                IsPromoterTemplate: res[i]["IsPromoterTemplate"]
              };
              this.defaultMessageLists.push(r);
              if (this.defaultMessageID && this.defaultMessageID == res[i]["ID"]) {
                this.messageOption = res[i];
              }
            }

            this.isdefaultMessageLoaded = true;
          } else {
            this.isdefaultMessageLoaded = true;
            this.defaultMessageLists = [];
          }
        });
      } else if (
        this.isPromoterOrAdmin == true &&
        this.isdefaultMessageLoaded != true &&
        this.isDefault != true &&
        this.selectedMerchants &&
        this.selectedMerchants.length == 1
      ) {
        this.merchantID = this.selectedMerchants[0];

        let payload = {
          purposeCode: "MerInv",
          orderBy: "Label",
          onlyActive: true,
          selectDefault: true,
          merchantID: this.merchantID
        };
        this.defaultMessageSerivce.getDefaultMessageListGlobal(payload).subscribe((res) => {
          if (res && res.length > 0) {
            for (var i = 0; i < res.length; i++) {
              let r = {
                ID: res[i]["ID"],
                label: res[i]["Label"],
                value: res[i]["Content"],
                IsPromoterTemplate: res[i]["IsPromoterTemplate"]
              };
              this.defaultMessageLists.push(r);
              if (this.defaultMessageID && this.defaultMessageID == res[i]["ID"]) {
                this.messageOption = res[i];
              }
            }

            this.isdefaultMessageLoaded = true;
          } else {
            this.isdefaultMessageLoaded = true;
            this.defaultMessageLists = [];
          }
        });
      } else if (
        this.isPromoterOrAdmin == true &&
        this.isdefaultMessageLoaded != true &&
        (this.isDefault == true || (this.selectedMerchants && this.selectedMerchants.length > 1))
      ) {
        let payload = {
          purposeCode: "MerInv",
          orderBy: "Label",
          selectDefault: true,
          onlyActive: true
        };
        this.defaultMessageSerivce.getDefaultMessageListGlobal(payload).subscribe((res) => {
          if (res && res.length > 0) {
            for (var i = 0; i < res.length; i++) {
              let r = {
                ID: res[i]["ID"],
                label: res[i]["Label"],
                value: res[i]["Content"],
                IsPromoterTemplate: res[i]["IsPromoterTemplate"]
              };
              this.defaultMessageLists.push(r);
              if (this.defaultMessageID && this.defaultMessageID == res[i]["ID"]) {
                this.messageOption = res[i];
              }
            }

            this.isdefaultMessageLoaded = true;
          } else {
            this.isdefaultMessageLoaded = true;
            this.defaultMessageLists = [];
          }
        });
      } else if (this.merchantID) {
        this.isdefaultMessageLoaded = false;
        let payload = {
          onlyActive: true,
          selectDefault: true,
          orderBy: "Label",
          purposeCode: "MerInv",
          merchantID: this.merchantID
        };
        this.defaultMessageSerivce.getDefaultMessageListGlobal(payload).subscribe((res) => {
          if (res && res.length > 0) {
            for (var i = 0; i < res.length; i++) {
              let r = {
                ID: res[i]["ID"],
                label: res[i]["Label"],
                value: res[i]["Content"],
                IsPromoterTemplate: res[i]["IsPromoterTemplate"]
              };
              this.defaultMessageLists.push(r);
              if (this.defaultMessageID && this.defaultMessageID == res[i]["ID"]) {
                this.messageOption = res[i];
              }
            }
          } else {
            this.defaultMessageLists = [];
          }
        });
      }
    }
  }

  cashDiscountOfferedChange() {
    if (this.cashDiscountOffered == true) {
      this.calculCashDiscount();
    }
  }

  calculCashDiscount() {
    if (this.merchant && this.merchant.ID) {
      let payload = {
        cashDiscountOffered: this.cashDiscountOffered,
        cashDiscountPercent: this.cashDiscountPercent,
        cashDiscountExpiredDays: this.cashDiscountExpiredDays,
        treatmentAmount: this.treatmentAmount,
        merchantID: this.merchant.ID
      };
      this.invitationTemplateService.calculateDiscount(payload, this.sessionType).subscribe((res) => {
        if (res) {
          this.hideDiscount = res.hideDiscount;
          this.cashDiscountOffered = res.cashDiscountOffered;
          this.cashDiscountAllowOverride = res.cashDiscountAllowOverride;
          this.cashDiscountPercent = res.cashDiscountPercent;
          this.cashDiscountExpiredDays = res.cashDiscountExpiredDays;
          this.cashDiscountMinAmount = res.cashDiscountMinAmount;
          this.cashDiscountAmount = res.cashDiscountAmount;
        } else {
          this.hideDiscount = true;
          this.cashDiscountOffered = false;
          this.cashDiscountAllowOverride = false;
          this.cashDiscountPercent = null;
          this.cashDiscountExpiredDays = null;
          this.cashDiscountMinAmount = null;
          this.cashDiscountAmount = null;
        }
      });
    }
  }

  removeDocument(id) {
    if (id) {
      let index = this.documents.indexOf(id);
      if (index != -1) {
        this.documents.splice(index, 1);
      }

      let indexDetailed = this.documentsDetailed.findIndex((documentDetailed) => documentDetailed._id === id);
      if (indexDetailed > -1) {
        this.documentsDetailed.splice(indexDetailed, 1);
      }
    }
  }

  calculateAmountFromPercent() {
    if (this.treatmentAmount > 0 && this.cashDiscountPercent && this.cashDiscountPercent <= 100) {
      this.cashDiscountAmount = (this.treatmentAmount * this.cashDiscountPercent) / 100;
    }
  }

  calculateCashDiscountDate() {
    if (this.cashDiscountExpiredDays) {
      this.cashDiscountDate = moment().add(this.cashDiscountExpiredDays, "d");
    }
  }

  clearInformedConsent() {
    if (this.informedConsent && this.informedConsent.length == 1) {
      this.informedConsent = [
        {
          categoryID: null,
          itemCode: null,
          typeID: null,
          brandID: null,
          productID: null,
          preDocuments: [],
          stockArtDocuments: [],
          postDocuments: [],
          marketingDocuments: []
        }
      ];
    } else {
      this.informedConsent = [
        {
          categoryID: null,
          itemCode: null,
          typeID: null,
          brandID: null,
          productID: null,
          preDocuments: [],
          stockArtDocuments: [],
          postDocuments: [],
          marketingDocuments: []
        }
      ];
    }
  }

  clearInformedConsentEvent(isClear, i) {
    if (isClear == true && this.informedConsent && this.informedConsent[i]) {
      let confirmDialog1 = new ConfirmDialog(
        "delete",
        "Are you sure?",
        "<p>Remove informed consent?</p> ",
        "No",
        "Yes, remove"
      );

      let ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirmDialog1
      });
      ref.componentInstance.onConfirm.subscribe((val) => {
        if (val == true) {
          if (this.informedConsent.length == 1) {
            this.informedConsent = [
              {
                categoryID: null,
                itemCode: null,
                typeID: null,
                brandID: null,
                productID: null,
                preDocuments: [],
                stockArtDocuments: [],
                postDocuments: [],
                marketingDocuments: []
              }
            ];
            this.resultInformedConsent = [];
          } else {
            this.informedConsent.splice(i, 1);
            this.resultInformedConsent.splice(i, 1);
          }
          ref.close();
        } else {
          ref.close();
        }
      });
    }
  }

  removeConsent(i) {
    if (this.informedConsent && this.informedConsent[i]) {
      let confirmDialog1 = new ConfirmDialog(
        "delete",
        "Are you sure?",
        "<p>Remove informed consent?</p> ",
        "No",
        "Yes, remove"
      );

      let ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirmDialog1
      });
      ref.componentInstance.onConfirm.subscribe((val) => {
        if (val == true) {
          if (this.informedConsent.length == 1) {
            this.informedConsent = [
              {
                categoryID: null,
                itemCode: null,
                typeID: null,
                brandID: null,
                productID: null,
                preDocuments: [],
                stockArtDocuments: [],
                postDocuments: [],
                marketingDocuments: []
              }
            ];
            this.resultInformedConsent = [];
          } else {
            this.informedConsent.splice(i, 1);
            this.resultInformedConsent.splice(i, 1);
          }
          ref.close();
        } else {
          ref.close();
        }
      });
    }
  }

  addConsent() {
    this.informedConsent.push({
      categoryID: null,
      itemCode: null,
      typeID: null,
      brandID: null,
      productID: null,
      preDocuments: [],
      stockArtDocuments: [],
      postDocuments: [],
      marketingDocuments: []
    });
  }

  getInformedConsent(o, index) {
    if (o && o.brandID && o.categoryID && o.typeID) {
      if (index !== null) {
        o.index = index;
      }
      if (this.resultInformedConsent[index]) {
        this.resultInformedConsent[index] = o;
      } else {
        this.resultInformedConsent.push(o);
      }
    }
  }

  addNewMessage() {
    let ref = RootAppComponent.dialog.open(MessageTemplateCreateEditComponent, {
      data: {
        merchantID: this.merchantID,
        purposeCode: "MerInv",
        displayProductGroup: true,
        displayPurpose: false,
        isDefault: this.isDefault
      },
      width: "900px",
      panelClass: "noCard"
    });
    ref.componentInstance.createdMessage.subscribe((data) => {
      if (data) {
        let r = {
          ID: data["ID"],
          label: data["Label"],
          value: data["Content"]
        };
        this.defaultMessageLists.push(r);
        ref.close();
      }
    });
    const sub = ref.componentInstance.closeModal.subscribe((data) => {
      ref.close();
    });
  }

  viewVideoInfo(videoTitle, docUrl) {
    let ref = RootAppComponent.dialog.open(DocumentUrlViewComponent, {
      data: {
        title: videoTitle,
        description: "",
        documentUrl: docUrl
      },
      width: "800px",
      panelClass: "noCard"
    });
    const sub = ref.componentInstance.closeModal.subscribe((data) => {
      ref.close();
    });
  }

  updateRawContent(content) {
    this.message.contentRaw = content;
  }

  defaultMessageChange(m) {
    if (m && m.value) {
      this.dentMsg = m.value;
      this.message = {
        viaSMS: null,
        label: m.label,
        content: m.value,
        contentRaw: m.value,
        defaultID: m.ID
      };

      let payload: any = {
        rawContent: m.value,
        isPatientPlaceHolder: true,
        isDentistPlaceHolder: true,
        isMerchantPlaceHolder: true,
        merchant: this.merchant
      };
      if (this.isDefault != true) {
        payload.dentist = this.sender;
      }

      this.defaultMessageSerivce
        .convertMessage(null, payload, this.customePhonePipe, this.isPromoterOrAdmin)
        .subscribe((res) => {
          if (res) {
            this.dentMsg = res;
          }
        });
    } else {
      this.message = null;
    }
  }

  changeTreatmentValue() {
    if (this.treatmentAmount >= 0) {
      this.calculCashDiscount();
    }
  }

  convertMessage(message) {
    let result;
    let rule = {};
    rule["PatientSalutation"] = "SALUTATION";
    rule["PatientLastName"] = "LAST_NAME";
    rule["PatientFirstName"] = "FIRST_NAME";
    rule["PatientMiddleName"] = "MIDDLE_NAME";
    rule["PatientMobileNumber"] = "MOBILE";
    rule["PatientWorkPhoneNumber"] = "PHONE_NUMBER";
    rule["PatientEmail"] = "EMAIL";

    if (this.sender) {
      rule["DentistSalutation"] = this.sender["Salutation"];
    } else {
      rule["DentistSalutation"] = "";
    }
    if (this.sender) {
      rule["DentistFirstName"] = this.sender["FirstName"];
    } else {
      rule["DentistFirstName"] = "";
    }
    if (this.sender) {
      rule["DentistMiddleName"] = this.sender["MiddleName"];
    } else {
      rule["DentistMiddleName"] = "";
    }
    if (this.sender) {
      rule["DentistLastName"] = this.sender["Name"];
    } else {
      rule["DentistLastName"] = "";
    }
    if (this.sender) {
      rule["DentistMobileNumber"] = this.customePhonePipe.transform(this.sender["mobiles.Number"], "false");
    } else {
      rule["DentistMobileNumber"] = "";
    }
    if (this.sender) {
      rule["DentistPhoneNumber"] = this.customePhonePipe.transform(this.sender["phones.Number"], "true");
    } else {
      rule["DentistPhoneNumber"] = "";
    }
    if (this.sender) {
      rule["DentistEmail"] = this.sender["emails.Email"];
    } else {
      rule["DentistEmail"] = "";
    }
    if (this.sender) {
      rule["DentistAddress"] = this.sender["addresses.Calculated"];
    } else {
      rule["DentistAddress"] = "";
    }
    if (this.merchant) {
      rule["PracticeLegalName"] = this.merchant["Name"];
    } else {
      rule["PracticeLegalName"] = "";
    }
    if (this.merchant) {
      rule["PracticeAddress"] = this.merchant["addresses.Calculated"];
    } else {
      rule["PracticeAddress"] = "";
    }
    if (this.merchant) {
      rule["PracticeTradingAs"] = this.merchant["TradingAs"];
    } else {
      rule["PracticeTradingAs"] = "";
    }
    if (this.merchant) {
      rule["PracticePhoneNumber"] = this.customePhonePipe.transform(this.merchant["phones.Number"], "true");
    } else {
      rule["PracticePhoneNumber"] = "";
    }
    if (this.merchant && this.merchant["mobiles.Number"]) {
      rule["PracticeMobileNumber"] = this.customePhonePipe.transform(this.merchant["mobiles.Number"], "false");
    } else {
      rule["PracticeMobileNumber"] = "";
    }
    if (this.merchant) {
      rule["PracticeAddress"] = this.merchant["addresses.Calculated"];
    } else {
      rule["PracticeAddress"] = "";
    }
    if (this.merchant) {
      rule["PracticeABN"] = this.merchant["ABN"];
    } else {
      rule["PracticeABN"] = "";
    }
    if (this.merchant) {
      rule["PracticeACN"] = this.merchant["ACN"];
    } else {
      rule["PracticeACN"] = "";
    }
    if (this.merchant) {
      rule["PracticeEmail"] = this.merchant["emails.Email"];
    } else {
      rule["PracticeEmail"] = "";
    }

    if (this.merchant && this.merchant["Facebook"]) {
      rule["PracticeFacebook"] = this.merchant["Facebook"];
    } else {
      rule["PracticeFacebook"] = "";
    }

    if (this.merchant && this.merchant["Instagram"]) {
      rule["PracticeInstagram"] = this.merchant["Instagram"];
    } else {
      rule["PracticeInstagram"] = "";
    }

    if (this.merchant && this.merchant["Twitter"]) {
      rule["PracticeTwitter"] = this.merchant["Twitter"];
    } else {
      rule["PracticeTwitter"] = "";
    }

    if (this.merchant && this.merchant["LinkedIn"]) {
      rule["PracticeLinkedIn"] = this.merchant["LinkedIn"];
    } else {
      rule["PracticeLinkedIn"] = "";
    }

    if (this.merchant && this.merchant["Pinterest"]) {
      rule["PracticePinterest"] = this.merchant["Pinterest"];
    } else {
      rule["PracticePinterest"] = "";
    }

    if (this.merchant && this.merchant["Tumblr"]) {
      rule["PracticeTumblr"] = this.merchant["Tumblr"];
    } else {
      rule["PracticeTumblr"] = "";
    }

    if (this.merchant && this.merchant["Vimeo"]) {
      rule["PracticeVimeo"] = this.merchant["Vimeo"];
    } else {
      rule["PracticeVimeo"] = "";
    }

    if (this.merchant && this.merchant["YouTube"]) {
      rule["PracticeYouTube"] = this.merchant["YouTube"];
    } else {
      rule["PracticeYouTube"] = "";
    }

    if (this.merchant && this.merchant["URL"]) {
      rule["PracticeWebSite"] = this.merchant["URL"];
    } else {
      rule["PracticeWebSite"] = "";
    }
    if (this.merchant && this.merchant["Twilio_Number"]) {
      rule["DedicatedSMSNumber"] = this.customePhonePipe.transform(this.merchant["Twilio_Number"], 'false');
    } else {
      rule["DedicatedSMSNumber"] = "";
    }

    if (this.merchant &&  this.merchant['Terminal_Code']) {
      rule[
        'SelfServicePage'
      ] = `${environment.nodeUrl}/s/${this.merchant['Terminal_Code']}?t=lp`;
    } else {
      rule['SelfServicePage'] = '';
    }
    if (this.merchant && this.merchant["Terminal_Code"]) {
      rule["PracitceAppointment"] = `${Settings.global["publicSiteFrontendLink"]}/a/${this.merchant["Terminal_Code"]}`;
    } else {
      rule["PracitceAppointment"] = "";
    }

    if (this.merchant && this.merchant["Terminal_Code"]) {
      rule["PracticePage"] = `${Settings.global["publicSiteFrontendLink"]}/l/p/s/${this.merchant["Terminal_Code"]}`;
    } else {
      rule["PracticePage"] = "";
    }

    if (this.merchant && this.merchant["Terminal_Code"]) {
      rule["TipsAndOffers"] = `${Settings.global["publicSiteFrontendLink"]}/l/d/s/${this.merchant["Terminal_Code"]}`;
    } else {
      rule["TipsAndOffers"] = "";
    }
    if (this.merchant) {
      rule["PracticeFirstName"] = this.merchant["FirstName"];
    } else {
      rule["PracticeFirstName"] = " ";
    }


    if (this.promoter) {
      if (this.promoter["TradingAs"]) {
        rule["PromoterTradingAs"] = this.promoter["TradingAs"];
      } else {
        rule["PromoterTradingAs"] = " ";
      }

      if (this.promoter["FirstName"]) {
        rule["PromoterFirstName"] = this.promoter["FirstName"];
      } else {
        rule["PromoterFirstName"] = " ";
      }

      if (this.promoter["Name"]) {
        rule["PromoterLegalName"] = this.promoter["Name"];
      } else {
        rule["PromoterLegalName"] = " ";
      }

      if (this.promoter["mobiles.Number"]) {
        rule["PromoterMobileNumber"] = this.customePhonePipe.transform(this.promoter["mobiles.Number"], "false");
      } else {
        rule["PromoterMobileNumber"] = " ";
      }

      if (this.promoter["phones.Number"]) {
        rule["PromoterPhoneNumber"] = this.customePhonePipe.transform(this.promoter["phones.Number"], "true");
      } else {
        rule["PromoterPhoneNumber"] = " ";
      }

      if (this.promoter["emails.Email"]) {
        rule["PromoterEmail"] = this.promoter["emails.Email"];
      } else {
        rule["PromoterEmail"] = " ";
      }

      if (this.promoter["addresses.Calculated"]) {
        rule["PromoterAddress"] = this.promoter["addresses.Calculated"];
      } else {
        rule["PromoterAddress"] = " ";
      }

      if (this.promoter["Facebook"]) {
        rule["PromoterFacebook"] = this.promoter["Facebook"];
      } else {
        rule["PromoterFacebook"] = " ";
      }

      if (this.promoter["Instagram"]) {
        rule["PromoterInstagram"] = this.promoter["Instagram"];
      } else {
        rule["PromoterInstagram"] = " ";
      }
      if (this.promoter["Twitter"]) {
        rule["PromoterTwitter"] = this.promoter["Twitter"];
      } else {
        rule["PromoterTwitter"] = " ";
      }

      if (this.promoter["LinkedIn"]) {
        rule["PromoterLinkedIn"] = this.promoter["LinkedIn"];
      } else {
        rule["PromoterLinkedIn"] = " ";
      }

      if (this.promoter["Pinterest"]) {
        rule["PromoterPinterest"] = this.promoter["Pinterest"];
      } else {
        rule["PromoterPinterest"] = " ";
      }
      if (this.promoter["Tumblr"]) {
        rule["PromoterTumblr"] = this.promoter["Tumblr"];
      } else {
        rule["PromoterTumblr"] = " ";
      }

      if (this.promoter["Vimeo"]) {
        rule["PromoterVimeo"] = this.promoter["Vimeo"];
      } else {
        rule["PromoterVimeo"] = " ";
      }
      if (this.promoter["YouTube"]) {
        rule["PromoterYouTube"] = this.promoter["YouTube"];
      } else {
        rule["PromoterYouTube"] = " ";
      }
      if (this.promoter["URL"]) {
        rule["PromoterWebSite"] = this.promoter["URL"];
      } else {
        rule["PromoterWebSite"] = " ";
      }

      if (this.promoter["ABN"]) {
        rule["PromoterABN"] = this.promoter["ABN"];
      } else {
        rule["PromoterABN"] = " ";
      }
      if (this.promoter["ACN"]) {
        rule["PromoterACN"] = this.promoter["ACN"];
      } else {
        rule["PromoterACN"] = " ";
      }
    }
    result = this.utils.convertMessage(message, rule);
    return result;
  }

  view() {
    if (this.template && this.template["_id"]) {
      let ref = RootAppComponent.dialog.open(InvitationTemplateMergerComponent, {
        data: {
          templateID: this.template["_id"],
          viewOnly: true
        },
        width: "1200px"
      });
      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    }
  }

  viewMerchant() {
    if (this.merchantID) {
      let ref = RootAppComponent.dialog.open(MerchantViewComponent, {
        data: this.merchantID,
        width: "600px"
      });
      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    }
  }

  updateDocument(file) {
    file.fields =
      "AWS_URL,AWS_Bucket_Name,DocumentType_Code,DateAdded,DateTimeCreated,Description,Extension,ID,Label,URL.Complete,Tags,ContainsSensitiveData,IsGallery";
    file.thumbnailFileID = null;
    file.fileID = file.id;
    file.label = file.fileName;
    file.description = null;
    file.type = "TRP";
    file.merchantID = this.merchantID;

    const documentIndex = this.documents.findIndex((document) => document === file.id);

    if (documentIndex > -1) {
      this.documents[documentIndex] = file;
    }

    const documentDetailedIndex = this.documentsDetailed.findIndex(
      (documentDetailed) => documentDetailed._id === file.id
    );

    if (documentDetailedIndex > -1) {
      this.documentsDetailed[documentDetailedIndex] = file;
    }
  }

  updateStockArt(file) {
    file.fields =
      "AWS_URL,AWS_Bucket_Name,DocumentType_Code,DateAdded,DateTimeCreated,Description,Extension,ID,Label,URL.Complete,Tags,ContainsSensitiveData,IsGallery";
    file.thumbnailFileID = null;
    file.fileID = file.id;
    file.label = file.fileName;
    file.description = null;
    file.type = "SRVSTC";
    file.merchantID = this.merchantID;

    const stockArtDocumentsIndex = this.stockArtDocuments.findIndex((stockArtDocument) => stockArtDocument === file.id);

    if (stockArtDocumentsIndex > -1) {
      this.stockArtDocuments[stockArtDocumentsIndex] = file;
    }

    const stockArtDocumentDetailedIndex = this.stockArtDocumentsDetailed.findIndex(
      (stockArtDocumentDetailed) => stockArtDocumentDetailed._id === file.id
    );

    if (stockArtDocumentDetailedIndex > -1) {
      this.stockArtDocumentsDetailed[stockArtDocumentDetailedIndex] = file;
    }
  }

  buildContent() {
    let content: any = {};
    if (this.cashDiscountOffered == true) {
      if (this.cashDiscountExpiredDays) {
        content.cashDiscountExpiredDays = this.cashDiscountExpiredDays;
      }
      if (this.cashDiscountPercent) {
        content.cashDiscountPercent = this.cashDiscountPercent;
      }
      if (this.cashDiscountExpiredDays) {
        content.cashDiscountExpiredDays = this.cashDiscountExpiredDays;
      }
      content.cashDiscountOffered = true;
    } else {
      content.cashDiscountOffered = false;
    }

    if (this.treatmentAmount > 0) {
      content.totalTreatmentValue = this.treatmentAmount;
    }
    if (this.documents && this.documents.length > 0) {
      content.treatmentDocuments = this.documents;
    }

    if (this.resultInformedConsent && this.resultInformedConsent.length > 0) {
      content.treatmentPlan = this.resultInformedConsent;
    } else {
      content.treatmentPlan = [];
    }
    if (this.stockArtDocuments && this.stockArtDocuments.length > 0) {
      content.stockArtDocuments = this.stockArtDocuments;
    } else {
      content.stockArtDocuments = [];
    }
    if (this.EPstockArtDocuments && this.EPstockArtDocuments.length > 0) {
      content.EPstockArtDocuments = this.EPstockArtDocuments;
    } else {
      content.EPstockArtDocuments = [];
    }

    if (this.EPTreatmentDocuments && this.EPTreatmentDocuments.length > 0) {
      content.EPTreatmentDocuments = this.EPTreatmentDocuments;
    } else {
      content.EPTreatmentDocuments = [];
    }
    if (this.message) {
      content.message = this.message;
    }
    if (this.dentMsg) {
      content.message.content = this.dentMsg;
    }
    if (this.sender && this.sender.ID && this.isDefault == true) {
      content.message.sender = this.sender.ID;
      content.message.senderLabel = this.sender.CalculatedName;
    }
    if (this.medicalHistory !== null) {
      content.medicalHistory = this.medicalHistory;
    }
    if (this.googleReviewPrompt !== null) {
      content.googleReviewPrompt = this.googleReviewPrompt;
    }

    if (this.isDefault === true && this.isPromoterOrAdmin == true) {
      content.tags = this.selectedTagsGlobal || [];
    } else {
      content.tags = this.selectedTags || [];
    }

    if (this.isDefault == true) {
      if (content && content.message && content.message.sender) {
        content.message.sender = null;
        content.message.senderLabel = null;
      }
    } else if (this.selectedMerchants && this.selectedMerchants.length > 1) {
      if (content && content.message && content.message.sender) {
        content.message.sender = null;
        content.message.senderLabel = null;
      }
    }

    return content;
  }

  save() {
    UtilsClass.scrollUp();
    let content = this.buildContent() || {};

    if (this.templates && this.templates.length > 0) {
      content.templates = this.templates;
    } else {
      content.templates = [];
    }

    if (this.attachTags !== true) {
      content.tags = [];
    } else if (this.attachTags === true) {
      if (this.isDefault === true && this.isPromoterOrAdmin == true) {
        content.tags = this.selectedTagsGlobal || [];
      } else {
        content.tags = this.selectedTags || [];
      }
    }
    const galleryDocumentPromises = [];
    const galleryDocumentsLength = this.documentsDetailed.length;
    const galleryStockArtLength = this.stockArtDocumentsDetailed.length;

    for (let index = galleryDocumentsLength - 1; index >= 0;) {
      if (this.documentsDetailed[index].isGallery === true) {
        galleryDocumentPromises.push(
          this.utilService.createEpdocument(this.documentsDetailed[index], this.isPromoterOrAdmin).toPromise()
        );

        index--;
      } else {
        index--;
      }
    }

    for (let index = galleryStockArtLength - 1; index >= 0;) {
      if (this.stockArtDocumentsDetailed[index].isGallery === true) {
        galleryDocumentPromises.push(
          this.utilService.createEpdocument(this.stockArtDocumentsDetailed[index], this.isPromoterOrAdmin).toPromise()
        );

        index--;
      } else {
        index--;
      }
    }

    Promise.all(galleryDocumentPromises).then(() => {
      let payload = {
        content: content,
        type: "user",
        description: null,
        merchantID: null,
        merchantIDs: null,
        label: null,
        display: true,
        tags: [],
        productGroup: "all"
      };

      if (this.label) {
        payload.label = this.label;
      }
      if (this.isDefault == true) {
        payload.type = "default";
        if (content && content.message && content.message.sender) {
          content.message.sender = null;
          content.message.senderLabel = null;
        }
      } else if (this.selectedMerchants && this.selectedMerchants.length > 1) {
        payload.type = "user";
        if (content && content.message && content.message.sender) {
          content.message.sender = null;
          content.message.senderLabel = null;
        }
      } else {
        payload.type = "user";
      }

      if (this.isDefault != true) {
        if (this.selectedMerchants && typeof this.selectedMerchants === "string") {
          payload.merchantIDs = this.selectedMerchants;
        } else if (this.selectedMerchants && this.selectedMerchants.length > 1) {
          payload.merchantIDs = this.selectedMerchants.join(",");
        } else if (this.selectedMerchants && this.selectedMerchants.length == 1) {
          this.merchantID = this.selectedMerchants[0];
        } else if (this.merchantID) {
          payload.merchantID = this.merchantID;
        }
      } else {
        payload.merchantID = null;
        payload.merchantIDs = null;
      }

      if (this.description) {
        payload.description = this.description;
      } else if (this.description === "") {
        payload.description = " ";
      }

      if (this.productGroup) {
        payload.productGroup = this.productGroup;
      } else {
        payload.productGroup = "all";
      }

      if (this.templateID) {
        this.invitationTemplateService
          .modifyInvitationTemplate(this.templateID, payload, this.isPromoterOrAdmin)
          .subscribe((res) => {
            if (res) {
              this.template = res;
              this.isDone = true;
              this.wizard.goToStep(3);
              NotifyAppComponent.displayToast("success", "Successful operation", "The invitation template is updated");
            }
          });
      } else {
        this.invitationTemplateService.createInvitationTemplate(payload, this.isPromoterOrAdmin).subscribe((res) => {
          if (res) {
            this.template = res;
            this.isDone = true;
            this.wizard.goToStep(3);
            NotifyAppComponent.displayToast("success", "Successful operation", "The invitation template is created");
          }
        });
      }
    });
  }

  changeContact(s) {
    if (this.contactID != null) {
      this.dentistService.getContactDetails(this.contactID, {}, this.sessionType).subscribe((res) => {
        if (res) {
          this.sender = res;
          if (this.messageOption && this.messageOption["value"]) {
            let payload: any = {
              rawContent: this.messageOption["value"],
              isPatientPlaceHolder: true,
              isDentistPlaceHolder: true,
              isMerchantPlaceHolder: true,
              merchant: this.merchant
            };
            if (this.isDefault != true) {
              payload.dentist = this.sender;
            }

            this.defaultMessageSerivce
              .convertMessage(null, payload, this.customePhonePipe, this.isPromoterOrAdmin)
              .subscribe((res) => {
                if (res) {
                  this.dentMsg = res;
                }
              });
          } else if (this.rawContent) {
            let payload: any = {
              rawContent: this.rawContent,
              isPatientPlaceHolder: true,
              isDentistPlaceHolder: true,
              isMerchantPlaceHolder: true,
              merchant: this.merchant
            };
            if (this.isDefault != true) {
              payload.dentist = this.sender;
            }

            this.defaultMessageSerivce
              .convertMessage(null, payload, this.customePhonePipe, this.isPromoterOrAdmin)
              .subscribe((res) => {
                if (res) {
                  this.dentMsg = res;
                }
              });
          }
        }
      });
    }
  }

  getselectedStockArtDocumentIDs(data) {
    if (Array.isArray(data)) {
      this.EPstockArtDocuments = _.uniq(data);
    }
  }

  getselectedTreatmentDocumentIDs(data) {
    if (Array.isArray(data)) {
      this.EPTreatmentDocuments = _.uniq(data);
    }
  }

  onCompleteAllStockArt(data) {
    if (data && data[0]) {
      this.addFileStockArt.emit(data[0]);
      this.stockArtDocuments.push(data[0]);
      this.stockArtDocuments = this.stockArtDocuments;
    }
  }

  onCompleteAllStockArtDetailsSimple(data) {
    if (data && data[0]) {
      data[0].fields =
        "AWS_URL,AWS_Bucket_Name,DocumentType_Code,DateAdded,DateTimeCreated,Description,Extension,ID,Label,URL.Complete,Tags,ContainsSensitiveData,IsGallery";
      data[0].thumbnailFileID = null;
      data[0].fileID = data[0]._id;
      data[0].label = data[0].fileName;
      data[0].description = null;
      data[0].type = "SRVSTC";
      data[0].merchantID = this.merchantID;
      this.addFileStockArt.emit(data[0]._id);
      this.stockArtDocuments.push(data[0]._id);
      this.stockArtDocumentsDetailed.push(data[0]);
    }
  }

  removeSimpleTreatmentStockArtDocument(id) {
    if (id && Array.isArray(this.stockArtDocuments) && this.stockArtDocuments.indexOf(id) !== -1) {
      let index = this.stockArtDocuments.indexOf(id);
      this.stockArtDocuments.splice(index, 1);

      let indexDetailed = this.stockArtDocumentsDetailed.findIndex(
        (stockArtDocumentsDetailed) => stockArtDocumentsDetailed._id === id
      );
      if (indexDetailed > -1) {
        this.stockArtDocumentsDetailed.splice(indexDetailed, 1);
      }
    }
  }

  checkStockArtDocuments(view = "user") {
    const ref = RootAppComponent.dialog.open(TreatmentDocumentImportOverviewComponent, {
      data: {
        merchantID: this.merchantID,
        readonly: false,
        isGallery: true,
        loadUserDocument: true,
        loadICDocument: true,
        loadPatientDocument: true,
        selectedView: view,
        documents: this.EPstockArtDocuments,
        userDocumentType: ["SRVSTC", "IGVIDEO"],
        ICDocumentType: ["SRVSTC", "IGVIDEO"],
        displayUserDocumentType: false,
        displayICDocumentType: false,
        displayPatientDocumentType: false,
        isICSideMenu: false,
        isUserSideMenu: true,
        displayUserDocuments: true,
        displayICDocuments: true,
        buttonLabel: "Images",
        displayPatientDocuments: true,
        patientDocumentType: ["MEDR_V", "MEDR_X", "MEDR_I"],
        natureICDocument: ["image", "video"],
        natureUserDocument: ["image", "video"],
        naturePatientDocument: ["image", "video"]
      },
      width: "90vw"
    });

    ref.backdropClick().subscribe((data) => {
      ref.close();
    });

    ref.componentInstance.closeModal.subscribe((data) => {
      ref.close();
    });

    ref.componentInstance.getDocuments.subscribe((res) => {
      if (res && res.length > 0) {
        this.EPstockArtDocuments = JSON.parse(JSON.stringify(_.uniq(res)));
      }
      ref.close();
    });
  }

  checkTreatmentDocuments() {
    const ref = RootAppComponent.dialog.open(TreatmentDocumentImportOverviewComponent, {
      data: {
        merchantID: this.merchantID,
        readonly: false,
        isGallery: true,
        title: "Practice, Patient Documents",
        loadUserDocument: true,
        loadICDocument: true,
        loadPatientDocument: true,
        selectedView: "user",
        documents: this.EPTreatmentDocuments,
        userDocumentType: "TRP",
        ICDocumentType: "TRP",
        displayUserDocumentType: false,
        displayICDocumentType: false,
        isICSideMenu: false,
        isUserSideMenu: false,
        displayUserDocuments: true,
        displayICDocuments: false,
        buttonLabel: "Documents",
        displayPatientDocuments: true,
        patientDocumentType: ["MEDR_R", "MEDR_C", "MEDR_T"],
        natureICDocument: ["document"],
        natureUserDocument: ["document"],
        naturePatientDocument: ["document"]
      },
      width: "90vw"
    });

    ref.backdropClick().subscribe((data) => {
      ref.close();
    });

    ref.componentInstance.closeModal.subscribe((data) => {
      ref.close();
    });

    ref.componentInstance.getDocuments.subscribe((res) => {
      if (res && res.length > 0) {
        this.EPTreatmentDocuments = JSON.parse(JSON.stringify(_.uniq(res)));
      }
      ref.close();
    });
  }

  launchAsModal(e, m) {
    // trigger component launch as modal, default to selected video

    let ref2 = RootAppComponent.dialog.open(VideoTutorialsModalComponent, {
      data: {
        isModal: true,
        mainCategory: m,
        subcategory: e,
        defaultSelectedTopic: e
      },
      width: "800px"
    });

    ref2.componentInstance.closeModal.subscribe((res) => {
      if (res == true) {
        ref2.close();
      }
    });
  }
}
