import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'environments/environment';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { AuthenticationService } from '../../authentication/shared/authentication.service';
import { WikiService } from '../shared/wiki.service';
import { WikiGroupEditComponent } from '../wiki-group-edit/wiki-group-edit.component';
import { WikiLeftMenuComponent } from '../wiki-left-menu/wiki-left-menu.component';

@Component({
  selector: 'app-wiki-search',
  templateUrl: './wiki-search.component.html',
  styleUrls: ['./wiki-search.component.css'],
})
export class WikiSearchComponent implements OnInit {
  @Input() tag;
  @Input() search;
  @Input() groupID;
  @Input() orderBy;
  @Input() limit;
  @Input() layout = 'grid';

  @Input() title;

  sessionType = 'guest';
  @Input()
  sessionTypeParams;

  loading = true;

  @Input()
  displayLayout = true;
  @Input()
  displatOrderLayout = true;

  utils = new UtilsClass();
  @Output() closeModal = new EventEmitter();

  @Output() pageFound = new EventEmitter();

  orderByFilter;
  isPromoterOrAdmin = false;

  pages = [];

  constructor(
    public authenticationService: AuthenticationService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private wikiService: WikiService
  ) {}

  ngOnInit() {
    const _res = this.utils.getSessionTypeParams(this.router.url);

    if (_res) {
      this.sessionTypeParams = _res;
    }

    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;
    });

    this.activeRoute.params.subscribe((params) => {
      if (params['search']) {
        this.search = params['search'];
      }

      if (params['tag']) {
        this.tag = params['tag'];
      }

      this.authenticationService.getSessionType().subscribe((res) => {
        if (res && this.sessionTypeParams && (res == 'admin' || res == 'promoter')) {
          this.sessionType = this.sessionTypeParams;
        } else if (res) {
          this.sessionType = res;
          this.sessionTypeParams = null;
        }

        if (this.orderBy) {
          this.orderByFilter = this.orderBy;
        }

        const p = {
          search: this.search,
          tags: this.tag,
          limit: this.limit,
          groupID: this.groupID,
          orderBy: this.orderBy,
          selectGroup: true,
          fields: '_id,title,icon,isMenuDisplayed,summary,roles,views,created_at,priority,isActive,tags,code,fileID',
        };

        this.wikiService.getWikiList(p, this.sessionType).subscribe((res) => {
          if (res && res.length > 0) {
            this.pages = res;

            this.pageFound.emit(true);
          }
        });
      });
    });
  }

  enable(w) {
    if (w) {
      const confirm = new ConfirmDialog(
        'check_circle',
        '',
        'Are you sure you want to enable thisArticle?',
        'No',
        'Yes'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });

      ref.backdropClick().subscribe((res) => {
        this.loading = true;
      });

      ref.componentInstance.closeEvent.subscribe((res) => {
        ref.close();

        this.loading = true;
      });

      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation === false) {
          ref.close();
        } else {
          this.loading = false;
          const p = {
            isActive: true,
            selectGroup: true,
          };

          this.wikiService.updateWiki(w._id, p).subscribe((res) => {
            if (res) {
              ref.close();

              const index = this.pages.findIndex((e) => {
                if (e && e._id && e._id == w._id) {
                  return true;
                }
              });

              if (index != -1) {
                this.pages[index] = res;
              }

              NotifyAppComponent.displayToast('success', 'Successfull operation', 'Wiki Article is Updated ');
              WikiLeftMenuComponent.updateHeader.emit(true);
            }

            this.loading = true;
          });
        }
      });
    }
  }

  disable(w) {
    if (w) {
      const confirm = new ConfirmDialog('cancel', '', 'Are you sure you want to disable thisArticle?', 'No', 'Yes');

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.backdropClick().subscribe((res) => {
        this.loading = true;
      });

      ref.componentInstance.closeEvent.subscribe((res) => {
        ref.close();

        this.loading = true;
      });

      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation === false) {
          ref.close();
        } else {
          this.loading = false;
          const p = {
            isActive: false,
            selectGroup: true,
          };

          this.wikiService.updateWiki(w._id, p).subscribe((res) => {
            if (res) {
              ref.close();

              const index = this.pages.findIndex((e) => {
                if (e && e._id && e._id == w._id) {
                  return true;
                }
              });

              if (index != -1) {
                this.pages[index] = res;
              }

              NotifyAppComponent.displayToast('success', 'Successfull operation', 'Wiki Article is Updated ');
              WikiLeftMenuComponent.updateHeader.emit(true);
            }

            this.loading = true;
          });
        }
      });
    }
  }

  editGoup(w) {
    this.loading = false;

    if (w && w.group && w.group._id) {
      const ref = RootAppComponent.dialog.open(WikiGroupEditComponent, {
        data: {
          groupID: w.group._id,
        },

        width: '800px',
        panelClass: 'noCard',
      });

      ref.componentInstance.result.subscribe((res) => {
        if (res) {
          w.group = res;

          ref.close();

          const index = this.pages.findIndex((e) => {
            if (e && e._id && e._id == w._id) {
              return true;
            }
          });

          if (index != -1) {
            this.pages[index] = res;
          }

          WikiLeftMenuComponent.updateHeader.emit(true);
        }
        ref.close();

        this.loading = true;
      });

      ref.backdropClick().subscribe((res) => {
        this.loading = true;
      });

      ref.componentInstance.closeModal.subscribe((res) => {
        ref.close();

        this.loading = true;
      });
    }
  }

  remove(w) {
    if (w) {
      const confirm = new ConfirmDialog('delete', '', 'Are you sure you want to Remove thisArticle?', 'No', 'Yes');

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });

      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation === false) {
          ref.close();
        } else {
          this.loading = false;
          this.wikiService.removeWiki(w._id).subscribe((res) => {
            if (res) {
              ref.close();

              const index = this.pages.findIndex((e) => {
                if (e && e._id && e._id == w._id) {
                  return true;
                }
              });

              if (index != -1) {
                this.pages.splice(index, 1);
              }

              NotifyAppComponent.displayToast('success', 'Successfull operation', 'Wiki Article is Updated ');
              WikiLeftMenuComponent.updateHeader.emit(true);

              this.loading = true;
            }
          });
        }
      });
    }
  }

  edit(w) {
    if (w && w._id) {
      if (this.sessionTypeParams) {
        this.router.navigate([
          '/wiki-' + this.sessionTypeParams,
          {
            outlets: {
              wikiPageRoute: ['edit', w._id],
            },
          },
        ]);
      } else {
        this.router.navigate([
          '/wiki',
          {
            outlets: {
              wikiPageRoute: ['edit', w._id],
            },
          },
        ]);
      }
    }
  }

  moveToPage(id) {
    if (id) {
      if (this.sessionTypeParams) {
        this.router.navigate([
          '/wiki-' + this.sessionTypeParams,
          {
            outlets: {
              wikiPageRoute: ['view', id],
            },
          },
        ]);
      } else {
        this.router.navigate([
          '/wiki',
          {
            outlets: {
              wikiPageRoute: ['view', id],
            },
          },
        ]);
      }
    }
  }

  moveToGroup(id) {
    if (id) {
      if (this.sessionTypeParams) {
        this.router.navigate([
          '/wiki-' + this.sessionTypeParams,
          {
            outlets: {
              wikiPageRoute: ['group', id],
            },
          },
        ]);
      } else {
        this.router.navigate([
          '/wiki',
          {
            outlets: {
              wikiPageRoute: ['group', id],
            },
          },
        ]);
      }
    }
  }

  isTagInclude(t) {
    if (this.tag && t == this.tag) {
      return true;
    } else if (this.search && this.search.indexOf(t) != -1) {
      return true;
    }

    return false;
  }

  searchEvent(t) {
    if (t) {
      if (this.sessionTypeParams) {
        this.router.navigate([
          '/wiki-' + this.sessionTypeParams,
          {
            outlets: {
              wikiPageRoute: ['tag', t],
            },
          },
        ]);
      } else {
        this.router.navigate([
          '/wiki',
          {
            outlets: {
              wikiPageRoute: ['tag', t],
            },
          },
        ]);
      }
    }
  }

  isNew(date) {
    if (date) {
      const days = this.utils.daysPast(date);

      if (days < 15) {
        return true;
      } else {
        return false;
      }
    }

    return false;
  }

  getSrc(id) {
    if (id) {
      return `${environment.nodeUrl}/files/file-view/${id}`;
    }
  }

  isAwsome(icon) {
    if (icon && icon.indexOf('fa-') != -1) {
      return true;
    }

    return false;
  }

  isPriorityOrder() {
    if (this.orderByFilter && this.orderByFilter.indexOf('priority') != -1) {
      return true;
    }

    return false;
  }

  moveWiki(id, direction) {
    if (id) {
      this.loading = false;
      const p = {
        direction,
        selectGroup: true,
      };
      this.wikiService.switchPriorityWiki(id, p).subscribe((res) => {
        if (res) {
          if (res && res.source && res.source._id) {
            const index = this.pages.findIndex((e) => {
              if (e && e._id && e._id == res.source._id) {
                return true;
              }
            });

            if (index != -1 && this.pages[index]) {
              this.pages[index]['priority'] = res.source['priority'];
            }
          }
          if (res && res.target && res.target._id) {
            const index = this.pages.findIndex((e) => {
              if (e && e._id && e._id == res.target._id) {
                return true;
              }
            });

            if (index != -1 && this.pages[index]) {
              this.pages[index]['priority'] = res.target['priority'];
            }
          }
          WikiLeftMenuComponent.updateHeader.emit(true);
          NotifyAppComponent.displayToast('success', 'Success!', 'Page is updated');

          this.loading = true;
        } else {
          this.loading = true;
        }
      });
    }
  }

  listLayout(value) {
    this.layout = value;
  }

  orderLayout(value2) {
    if (value2) {
      this.orderByFilter = value2;
    }
  }

  firstLetterUpperCase(r) {
    if (r && r[0]) {
      return String(r[0]).toUpperCase();
    }
  }
}
