import { BehaviorSubject, merge as observableMerge, Observable } from 'rxjs';

import { DataSource } from '@angular/cdk/table';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { map } from 'rxjs/operators';
import { LookupClass } from '../../../shared/types/lookupClass';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { InvitationViewModalComponent } from '../invitation-view-modal/invitation-view-modal.component';
import { InvitationService } from '../shared/services/invitation.service';

import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { orderBy, uniq } from 'lodash';
import * as moment from 'moment';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { CustomDatePipe } from '../../../shared/pipes/custom-date.pipe';
import { UtilsService } from '../../../shared/services/utils.service';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { ConfirmOptionDialog } from '../../../shared/types/confirm-option-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { DentistViewComponent } from '../../dentist/dentist-view/dentist-view.component';
import { PatientCardChangesModalComponent } from '../../patient/patient-card-changes-modal/patient-card-changes-modal.component';
import { PatientCardDeferModalComponent } from '../../patient/patient-card-defer-modal/patient-card-defer-modal.component';
import { PatientCardStopModalComponent } from '../../patient/patient-card-stop-modal/patient-card-stop-modal.component';
import { PatientService } from '../../patient/shared/patient.service';
import { InvitationResendComponent } from '../invitation-resend/invitation-resend.component';
import { InvitationUtilClass } from '../shared/types/invitation-util.class';

import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ConfirmDialogSingleButtonComponent } from '../../../shared/components/confirm-dialog-single-button/confirm-dialog-single-button.component';
import { ConfirmDialogSingleButton } from '../../../shared/types/confirm-dialog-single-button';
import { ContractsViewComponent } from '../../contract/contracts-view/contracts-view.component';
import { TreatmentSaveModalComponent } from '../../invitation-template/treatment-save-modal/treatment-save-modal.component';
import { InvitationCloneInfoComponent } from '../invitation-clone-info/invitation-clone-info.component';

import { IframeViewComponent } from '../../../shared/components/iframe-view/iframe-view.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { SideMenuService } from '../../../shared/services/side-menu.service';
import { InstantOfferViewComponent } from '../../instant-finance-offer/instant-offer-view/instant-offer-view.component';
import { InvitationTemplateMergerComponent } from '../../invitation-template/invitation-template-merger/invitation-template-merger.component';
import { MerchantAuthCodeModalComponent } from '../../merchant/merchant-auth-code-modal/merchant-auth-code-modal.component';
import { SmsConversationComponent } from '../../message/sms-conversation/sms-conversation.component';
import { NotesModal, NotesModalConfig } from '../../notes/modals/notes-modal/notes.modal';
import { SubAccountStatisticalReportComponent } from '../../performance/sub-account-statistical-report/sub-account-statistical-report.component';
import { CustomPhonePipe } from '../../../shared/pipes/custom-phone.pipe';

/**
 * @title Feature-rich data table
 */
@Component({
  selector: 'app-invitation-list',
  templateUrl: './invitation-list.component.html',
  styleUrls: ['./invitation-list.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.row', style({ opacity: 0 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.row', style({ opacity: 1 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class InvitationListComponent implements OnInit {
  @Input()
  status;

  invitationUtil = new InvitationUtilClass();

  @Input()
  productID;

  @Input()
  staffID;

  @Input()
  isExpired;

  @Input()
  onClickEvent = false;

  @Output()
  clickEvent = new EventEmitter();

  @Input()
  hasPatientChoice;

  @Input()
  patientID;

  @Input()
  voided = false;

  @Input()
  dateFrom;

  @Input()
  dateTo;

  @Input()
  existingPatientID;

  @Input()
  showInvDetailInList = false;

  @Input()
  isLoadSubAccountStatistics = false;

  @Output()
  viewDetails = new EventEmitter();

  @Input()
  productGroupCode;
  context = Settings.global['context'];

  statusObject = {
    viewed: 'Viewed',
    notViewed: 'Not Viewed',
    intendProceed: 'Intend To Proceed',
    changed: 'Changed',
    notProceeding: 'Not Proceeding',
    proceedingCash: 'Proceeding with Cash/Card',
    expired: 'Expired',
    referred: 'Referred',
    declined: 'Declined',
    approved: 'Approved',
    commenced: 'Commenced',
    completed: 'Completed',
    deferred: 'Deferred',
    cold: 'Cold',
    notFinanced: 'Not Financed',
  };

  invitationID = null;
  public displayInvitationView = false;
  @Input()
  pageSize = Settings.global['listPageSize'] || 20;
  pageSizeOptions = [10, Number(this.pageSize), Number(this.pageSize) * 2, Number(this.pageSize) * 3];

  @Input()
  membership = false;

  utils = new UtilsClass();

  lookup = new LookupClass();
  @Input()
  displayedColumns = [
    'Customer',
    'Date',
    // "Operator",

    // "Email",
    'Amount',
    'Product',
    'ProductGroup',

    'InformedConsent',
    'EmailStatus',
    'InvitationStatus',
    'ApplicationStatus',
    'ContractStatus',
    'timeElapsed',
    'Response',
    'Source',
    'Actions',
    // "fieldColor"
  ];

  searchVals = new FormControl();

  searchValList = [
    'Invitation Status',
    'Source',
    'Dentist',
    'Amount',
    'Product',
    'Product Group',
    'Email Status',
    'Application Status',
    'Contract Status',
  ];

  treatmentValue = 0;

  filters = [];
  selectedValue = '';
  selectedField: any = {
    field: '',
    table: '',
    category: '',
    value: '',
  };
  subjectArray = [];

  stretchList = false;
  filteredSize = null;

  isAdminOrPromoter = false;

  items = [
    'invitation',
    'contract',
    'customer',
    'dentist',
    'merchant',
    'instantOffer',
    'appointment',
    'campaign',
    'landingPage',
    'settlement',
    'note',
  ];

  sourceList = [];
  public invitationsDB: LoadInv | null;
  dataSource: InvitationDataSource | null;
  showFirstFilter = false;
  showSecondFilter = false;
  showDateFilter = false;
  showInput = true;
  dateNow;
  selectedTreatmentValue;
  activeButton = false;

  isModuleNoteFeaturesActive = Settings.global['isModuleNoteFeaturesActive'];
  isModuleTreatmentTemplateActive = Settings.global['isModuleTreatmentTemplateActive'];
  isModuleExperimentalFeaturesActive = Settings.global['isModuleExperimentalFeaturesActive'];
  isModuleAppointmentActive = Settings.global['isModuleAppointmentActive'];

  isModulePatientSMSActive = Settings.global['isModulePatientSMSActive'];
  isModulePatientPortalAccessActive = Settings.global['isModulePatientPortalAccessActive'];
  healthHistoryActive = true;
  emailReputation = 'none';
  emailReputationColor;
  subAccountObj;
  currentPractice;
  isAppointmentDisabled = false;

  innerSubscription = false;
  destroyEvent = new EventEmitter();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('filter', { static: false }) filter: ElementRef;

  constructor(
    private invitationService: InvitationService,
    private router: Router,
    private dialog: MatDialog,
    private customDate: CustomDatePipe,
    private patientService: PatientService,
    private authenticationService: AuthenticationService,
    private utilService: UtilsService,

    private customPhonePipe: CustomPhonePipe
  ) {}

  ngOnInit() {
    AuthenticationService.appoinmentAccess.subscribe((r) => {
      this.isAppointmentDisabled = r;
    });
    AuthenticationService.healthHistoryAccess.subscribe((r) => {
      this.healthHistoryActive = r;
    });

    this.authenticationService.getCurrentPractice().subscribe((currentPractice) => {
      if (currentPractice) {
        this.currentPractice = currentPractice;
        this.checkBookingEngine();
      }
    });
    this.utilService.getCurrentAccess().subscribe((access) => {
      if (access) {
        this.isModuleNoteFeaturesActive = access['isModuleNoteFeaturesActive'];
        this.isModuleTreatmentTemplateActive = access['isModuleTreatmentTemplateActive'];
        this.isModuleExperimentalFeaturesActive = access['isModuleExperimentalFeaturesActive'];
        this.isModuleAppointmentActive = access['isModuleAppointmentActive'];
        this.isModulePatientPortalAccessActive = access['isModulePatientPortalAccessActive'];
        this.isModulePatientSMSActive = access['isModulePatientSMSActive'];
      }

      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isAdminOrPromoter = res;
      });
      SideMenuService.goBack.subscribe((res) => {
        if (res == true) {
          this.cancelInviteViewDetails();
        }
      });

      this.utilService.getServerDate().subscribe((res) => {
        this.dateNow = res;
      });

      if (this.isLoadSubAccountStatistics == true) {
        this.utilService.getSubAccountStatisticalReport().subscribe((res) => {
          if (res && res.length > 0 && res[0].Reputation) {
            this.subAccountObj = res[0];
            const _reputationValue = Number(res[0].Reputation);
            if (_reputationValue >= 90) {
              this.emailReputation = 'Excellent';
              this.emailReputationColor = '4caf50';
            } else if (_reputationValue >= 75 && _reputationValue < 90) {
              this.emailReputation = 'Good';
              this.emailReputationColor = 'cddc39';
            } else if (_reputationValue >= 50 && _reputationValue < 75) {
              this.emailReputation = 'Fair';
              this.emailReputationColor = 'ff9800';
            } else if (_reputationValue > 0 && _reputationValue < 50) {
              this.emailReputation = 'Poor';
              this.emailReputationColor = 'f44336';
            } else if (_reputationValue == 0) {
              this.emailReputation = 'Unknown';
              this.emailReputationColor = 'ababa9';
            }
          } else {
            this.subAccountObj = null;
            this.emailReputation = 'none';
            this.emailReputationColor = 'e3e3e3';
          }
        });
      }

      // const params = {
      //   isExpired: this.isExpired,
      //   patientID: this.patientID || null,
      //   hasPatientChoice: this.hasPatientChoice,
      //   productGroupCode: this.productGroupCode,
      // };
      // this.invitationsDB = new LoadInv(
      //   this.invitationService,
      //   this.destroyEvent,
      //   this.productID,
      //   this.staffID,
      //   this.status,
      //   this.voided,
      //   this.dateFrom,
      //   this.dateTo,
      //   this.membership,
      //   this.existingPatientID,
      //   params
      // );
      // this.dataSource = new InvitationDataSource(this.invitationsDB, this.paginator, this.sort);
      // this.invitationsDB.getSourceList.subscribe((tags) => {
      //   this.sourceList = JSON.parse(JSON.stringify(tags || []));
      // });
      this.changeSelectedColumn();
    });

    // this.sumAmount();
  }

  ngOnChanges(changes: SimpleChanges) {
    const params = {
      isExpired: this.isExpired,
      patientID: this.patientID || null,
      hasPatientChoice: this.hasPatientChoice,
      productGroupCode: this.productGroupCode,
    };

    this.invitationsDB = new LoadInv(
      this.invitationService,
      this.destroyEvent,
      this.productID,
      this.staffID,
      this.status,
      this.voided,
      this.dateFrom,
      this.dateTo,
      this.membership,
      this.existingPatientID,
      params,
      this.customPhonePipe
    );
    this.dataSource = new InvitationDataSource(this.invitationsDB, this.paginator, this.sort);

    if (this.innerSubscription == false && this.invitationsDB) {
      this.invitationsDB.getSourceList.subscribe((tags) => {
        this.sourceList = JSON.parse(JSON.stringify(tags || []));
      });
      this.innerSubscription = true;
    }
  }

  checkBookingEngine() {
    if (this.currentPractice && this.currentPractice['ThirdPartyBooking_URL']) {
      this.isAppointmentDisabled = true;
    } else {
      this.isAppointmentDisabled = false;
    }
  }

  sendSMS(invitationID, id) {
    if (id) {
      const ref2 = RootAppComponent.dialog.open(SmsConversationComponent, {
        data: {
          cardID: id,
          singleChat: true,
          invitationID: invitationID,
        },
        panelClass: 'bigger-screen',
        width: '70%',
        height: '95vh',
      });
      const sub = ref2.componentInstance.close.subscribe((data) => {
        ref2.close();
      });
    }
  }
  sendEmail(id) {
    if (id) {
      const data = {
        targetType: 'customerProspect',
        targetID: id,
        asGuest: false,
        asProfile: true,
        asPractice: true,
      };
      AuthenticationService.contactInputEvent.emit(data);
    }
  }
  openCampaign(id) {
    this.router.navigate(['/merchant', { outlets: { page: ['marketing-campaign-view', id] } }]);
  }

  openDocumentLandingPage(id) {
    if (id) {
      this.authenticationService.getCurrentPractice().subscribe((r) => {
        if (r && r.ID) {
          const link = `${Settings.global['publicSiteFrontendLink']}/doc/${id}?m=${r.ID}`;

          const ref2 = RootAppComponent.dialog.open(IframeViewComponent, {
            data: {
              link,
            },
            width: '80%',
            height: '92vh',
            panelClass: 'noCard',
          });

          ref2.componentInstance.close.subscribe((res) => {
            ref2.close();
          });
        }
      });
    }
  }

  viewSubAccountStatisticalData() {
    if (this.subAccountObj) {
      const ref = RootAppComponent.dialog.open(SubAccountStatisticalReportComponent, {
        data: this.subAccountObj,
        width: '650px',
      });

      ref.componentInstance.close.subscribe((res) => {
        if (res == true) {
          ref.close();
        }
      });
    }
  }

  reactive() {
    this.activeButton = true;
  }

  displayCulomn(index) {
    if (this.displayedColumns.indexOf(index) != -1) {
      return true;
    } else {
      return false;
    }
  }

  resetPatientChoice(id, _void, patientID) {
    const confirm = new ConfirmDialog(
      'fas fa-info',
      'Reset Patient Decision',
      'By doing this will reset the selection previously made by patient and allow the patient to make a new selection.',
      'No',
      'Yes'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirm,
      width: '650px',
    });

    ref.componentInstance.onConfirm.subscribe((res) => {
      if (res == true) {
        ref.close();

        if (_void == '1') {
          // inv expired, asking to clone
          const confirmExpired = new ConfirmDialog(
            'warning',
            'Invitation Expired',
            'Would you like to clone to create a new invitation from the old invitation',
            'No',
            'Yes'
          );

          const refExpired = RootAppComponent.dialog.open(ConfirmDialogComponent, {
            data: confirmExpired,
            width: '650px',
          });

          refExpired.componentInstance.onConfirm.subscribe((resExpired) => {
            if (resExpired == true) {
              refExpired.close();
              this.openCloneInvitationModal(id, patientID);
            } else {
              refExpired.close();
            }
          });
        } else {
          // reset selection
          this.patientService.resetChoiceInivation(id).subscribe((res) => {
            if (res) {
              NotifyAppComponent.displayToast(
                'success',
                'Reset Patient Decision',
                'This inivitaiton is now ready for the patient to make new decision.'
              );
              this.dataSource.replaceItem = res;
            }
          });
        }
      } else {
        ref.close();
      }
    });
  }

  payCashInvitation(id, patientID) {
    const confirmDialog = new ConfirmDialog(
      'fas fa-info',
      'Paying with Cash/Card',
      '<p>Your patient has agreed on their treatment and plans to settle their account by paying with cash or card at the time of their appointment.</p> <p class="noFurther">They will not recieve further notifications from the Smile Right system.</p>',
      'Cancel',
      'Okay'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
      // SET TO PAYING CASH CARD
    });
    ref.componentInstance.onConfirm.subscribe((val) => {
      if (val == true) {
        ref.close();
        ref.afterClosed().subscribe((res) => {
          this.patientService.notFinanceInivation(id, true).subscribe((res) => {
            if (res) {
              this.dataSource.replaceItem = res;

              NotifyAppComponent.displayToast(
                'success',
                'Status Updated',
                'Patient will pay cash or card at the time of their appointment.'
              );
            }
          });
        });
      } else {
        ref.close();
      }
    });
  }

  payCashInvitationWithDiscount(id, patientID) {
    const confirmDialog = new ConfirmDialog(
      'fas fa-info',
      'Paying with Cash/Card',
      '<p>Your patient has agreed on their treatment and plans to settle their account by paying with cash or card at the time of their appointment.</p> <p class="noFurther">They will not recieve further notifications from the Smile Right system.</p>',
      'Cancel',
      'Okay'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
      // SET TO PAYING CASH CARD
    });
    ref.componentInstance.onConfirm.subscribe((val) => {
      if (val == true) {
        ref.close();
        ref.afterClosed().subscribe((res) => {
          this.patientService.notFinanceInivationWithDiscount(id, true).subscribe((res) => {
            if (res) {
              this.dataSource.replaceItem = res;

              NotifyAppComponent.displayToast(
                'success',
                'Status Updated',
                'Patient will pay cash or card at the time of their appointment.'
              );
            }
          });
        });
      } else {
        ref.close();
      }
    });
  }

  deferInvitation(id, patientID) {
    const confirmOptionDialog = new ConfirmOptionDialog(
      'access_alarms',
      'Defer Treatment',
      '<p>Your patient has requested to hold off further notifications about their treatment for a period of time.</p>',
      'Please select time to defer',
      'Cancel',
      'Okay'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmOptionDialog,
    });

    ref.componentInstance.onConfirm.subscribe((res) => {
      if (res == true) {
        ref.close();
        ref.afterClosed().subscribe((res) => {
          const ref1 = RootAppComponent.dialog.open(PatientCardDeferModalComponent, {
            data: {
              invitationID: id,
              force: true,
            },
            width: '500px',
          });
          let deferWeeks;
          ref1.componentInstance.done.subscribe((res3) => {
            deferWeeks = res3;
          });
          ref1.componentInstance.invitation.subscribe((res2) => {
            this.dataSource.replaceItem = res2;
            ref1.close();
          });
        });
      }
    });

    // LAUNCH DEFER TREATMENT MODAL ON 'OK' CLICK
  }

  changesToInvitation(id, patientID) {
    const confirmDialog = new ConfirmDialog(
      'fas fa-info',
      'Changes requested',
      '<p>Your patient requests to modify their treatment plan before proceeding.</p>',
      'Cancel',
      'Okay'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,

      // LAUNCH CHANGES TO INVITATION MODAL ON 'OK' CLICK
    });
    ref.componentInstance.onConfirm.subscribe((res) => {
      if (res == true) {
        ref.close();
        ref.afterClosed().subscribe((res) => {
          const ref1 = RootAppComponent.dialog.open(PatientCardChangesModalComponent, {
            data: {
              invitationID: id,
              force: true,
            },
            width: '500px',
          });

          ref1.componentInstance.invitation.subscribe((res2) => {
            this.dataSource.replaceItem = res2;
            ref1.close();
            ref1.afterClosed().subscribe((res) => {
              if (res2 && res2.newID) {
                const ref2 = RootAppComponent.dialog.open(ConfirmDialogComponent, {
                  data: new ConfirmDialog(
                    'fas fa-info',
                    'Create new invitation?',
                    "<p>By pressing 'Okay' you will start creating a new invitation.</p>",
                    'Cancel',
                    'Okay'
                  ),
                });
                ref2.componentInstance.onConfirm.subscribe((res) => {
                  if (res == true) {
                    ref2.close();
                    ref2.afterClosed().subscribe((res) => {
                      if (this.context == 'retail') {
                        this.router.navigate([
                          '/merchant',
                          { outlets: { page: ['lending-invitation-create', res2.newID] } },
                        ]);
                      } else {
                        this.router.navigate(['/merchant', { outlets: { page: ['invitation-create', res2.newID] } }]);
                      }
                    });
                  } else {
                    ref2.close();
                  }
                });
              }
            });
          });
        });
      }
    });
  }

  notProceedingInvitation(id, patientID) {
    const confirmDialog = new ConfirmDialog(
      'remove_circle',
      'Does not wish to proceed',
      '<p>Your patient has decided not to proceed with their treatment.</p><p class="noFurther">They will not recieve further notifications from the Smile Right system.</p>',
      'Cancel',
      'Okay'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,

      // LAUNCH NOT PROCEEDING REASONS / QUESTIONS MODAL ON 'OKAY' CLICK
    });

    ref.componentInstance.onConfirm.subscribe((res) => {
      if (res == true) {
        ref.close();
        ref.afterClosed().subscribe((res) => {
          const ref1 = RootAppComponent.dialog.open(PatientCardStopModalComponent, {
            data: {
              invitationID: id,
              force: true,
            },
            width: '500px',
            panelClass: 'flex',
            disableClose: true,
          });

          ref1.componentInstance.invitation.subscribe((data) => {
            this.dataSource.replaceItem = data;
            ref1.close();
          });
        });
      }
    });
  }

  openCloneInvitationModal(id, patientID) {
    const ref = this.dialog.open(InvitationCloneInfoComponent, {
      data: {},
      width: '650px',
    });

    if (ref.componentInstance.InvitationCloneInfo == true) {
      ref.close();
      ref.afterClosed().subscribe(() => {
        this.cloneInvitation(id, patientID);
      });
    } else {
      ref.componentInstance.closeModal.subscribe((res) => {
        if (res == true) {
          ref.close();
          ref.afterClosed().subscribe(() => {
            this.cloneInvitation(id, patientID);
          });
        }
      });
    }

    ref.backdropClick().subscribe(() => {
      ref.close();
      ref.afterClosed().subscribe(() => {
        this.cloneInvitation(id, patientID);
      });
    });
  }

  cloneInvitation(id, patientID) {
    const confirm = new ConfirmDialog(
      'fas fa-info',
      'Clone Invitation',
      'Are you sure you want to clone this invitation ?',
      'No',
      'Yes'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirm,
    });
    ref.componentInstance.onConfirm.subscribe((confirmation) => {
      if (confirmation === false) {
        ref.close();
      } else {
        ref.close();

        const confirmArchive = new ConfirmDialog(
          'fas fa-info',
          'Archive old invitation',
          'Are you sure you want to archive old invitation ?',
          'No',
          'Yes'
        );

        const refArchive = RootAppComponent.dialog.open(ConfirmDialogComponent, {
          data: confirmArchive,
        });

        refArchive.componentInstance.onConfirm.subscribe((confirmArchive) => {
          if (confirmArchive == false) {
            this.invitationService.cloneInvitation(id, this.isAdminOrPromoter, false).subscribe((res) => {
              if (res) {
                refArchive.close();

                if (this.context == 'retail') {
                  this.router.navigate(['/merchant', { outlets: { page: ['lending-invitation-create', res] } }]);
                } else {
                  this.router.navigate(['/merchant', { outlets: { page: ['invitation-create', res] } }]);
                }
              } else {
                refArchive.close();
              }
            });
          } else {
            this.invitationService.deleteInvitation(id, this.isAdminOrPromoter, this.membership).subscribe((res) => {
              if (res == true) {
                this.dataSource.removeItem = id;

                NotifyAppComponent.displayToast(
                  'success',
                  'Successful operation',
                  'The invitation has been successfully archived'
                );

                this.invitationService.cloneInvitation(id, this.isAdminOrPromoter, false).subscribe((res) => {
                  if (res) {
                    refArchive.close();

                    if (this.context == 'retail') {
                      this.router.navigate(['/merchant', { outlets: { page: ['lending-invitation-create', res] } }]);
                    } else {
                      this.router.navigate(['/merchant', { outlets: { page: ['invitation-create', res] } }]);
                    }
                  } else {
                    refArchive.close();
                  }
                });
              }
            });
          }
        });
      }
    });
  }

  deleteInvitation(id, patientID) {
    const confirmDialog = new ConfirmDialog(
      'delete',
      'Archive Invitation?',
      '<p><strong>Are you sure?</strong></p><p class="noFurther">They will not recieve further notifications from the Smile Right system.</p>',
      'No',
      'Yes, archive'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
    });
    ref.componentInstance.onConfirm.subscribe((val) => {
      if (val == true) {
        this.invitationService.deleteInvitation(id, this.isAdminOrPromoter, this.membership).subscribe((res) => {
          if (res == true) {
            this.dataSource.removeItem = id;

            NotifyAppComponent.displayToast(
              'success',
              'Successful operation',
              'The invitation has been successfully archived'
            );
            ref.close();
          }
        });
      } else {
        ref.close();
      }
    });
  }

  viewInstantOffer(id) {
    if (id) {
      const ref2 = RootAppComponent.dialog.open(InstantOfferViewComponent, {
        data: {
          instantOfferID: id,
        },
        width: '950px',
      });

      ref2.componentInstance.close.subscribe((res) => {
        ref2.close();
      });
    }
  }

  changeSelectedColumn() {
    if (this.status == 'viewed') {
      if (this.membership == true) {
        this.displayedColumns = [
          'Customer',
          'Date',
          'Operator',
          'Amount',
          'Product',

          'InformedConsent',
          'EmailStatus',
          'InvitationStatus',
          'timeElapsed',
          'Source',
          'Actions',
          // "fieldColor"
        ];
      } else {
        this.displayedColumns = [
          'Customer',
          'Date',
          'Operator',
          'Amount',
          'Product',
          'ProductGroup',

          'InformedConsent',
          'EmailStatus',
          'InvitationStatus',
          'timeElapsed',
          'Source',
          'Actions',
          // "fieldColor"
        ];
      }
    } else if (this.status == 'notViewed') {
      if (this.membership == true) {
        this.displayedColumns = [
          'Customer',
          'Date',
          'Operator',
          'Amount',
          'Product',

          'InformedConsent',
          'EmailStatus',
          'timeElapsed',
          'Source',
          'Actions',
          // "fieldColor"
        ];
      } else {
        this.displayedColumns = [
          'Customer',
          'Date',
          'Operator',
          'Amount',
          'Product',
          'ProductGroup',
          'InformedConsent',
          'EmailStatus',
          'timeElapsed',
          'Source',

          'Actions',
          // "fieldColor"
        ];
      }
    } else if (this.status == 'notProceeding') {
      if (this.membership == true) {
        this.displayedColumns = [
          'Customer',
          'Date',
          'Operator',
          'Amount',
          'Product',

          'InformedConsent',
          'EmailStatus',
          'InvitationStatus',
          'timeElapsed',
          'Response',
          'Source',
          'Actions',
          // "fieldColor"
        ];
      } else {
        this.displayedColumns = [
          'Customer',
          'Date',
          'Operator',
          'Amount',
          'Product',
          'ProductGroup',

          'InformedConsent',
          'EmailStatus',
          'InvitationStatus',
          'timeElapsed',
          'Response',
          'Source',
          'Actions',
          // "fieldColor"
        ];
      }
    } else if (this.status == 'expired') {
      if (this.membership == true) {
        this.displayedColumns = [
          'Customer',
          'Date',
          'Operator',
          'Amount',
          'Product',

          'InformedConsent',
          'EmailStatus',
          'InvitationStatus',
          'timeElapsed',
          'Source',
          'Actions',
          // "fieldColor"
        ];
      } else {
        this.displayedColumns = [
          'Customer',
          'Date',
          'Operator',
          'Amount',
          'Product',
          'ProductGroup',

          'InformedConsent',
          'EmailStatus',
          'InvitationStatus',
          'timeElapsed',
          'Source',
          'Actions',
          // "fieldColor"
        ];
      }
    } else if (this.status == 'proceedingCash') {
      if (this.membership == true) {
        this.displayedColumns = [
          'Customer',
          'Date',
          'Operator',
          'Amount',
          'Product',

          'InformedConsent',
          'EmailStatus',
          'InvitationStatus',
          'timeInvited',
          'timeResponded',
          'Response',
          'Source',
          'Actions',
          // "fieldColor"
        ];
      } else {
        this.displayedColumns = [
          'Customer',
          'Date',
          'Operator',
          'Amount',
          'Product',
          'ProductGroup',

          'InformedConsent',
          'EmailStatus',
          'InvitationStatus',
          'timeInvited',
          'timeResponded',
          'Response',
          'Source',
          'Actions',
          // "fieldColor"
        ];
      }
    } else if (this.status == 'financed') {
      if (this.membership == true) {
        this.displayedColumns = [
          'Customer',
          'Date',
          'Operator',
          'Amount',
          'Product',
          'InformedConsent',
          'ApplicationStatus',
          'ContractStatus',
          'timeElapsed',
          'Source',

          'Actions',
          // "fieldColor"
        ];
      } else {
        this.displayedColumns = [
          'Customer',
          'Date',
          'Operator',
          'Amount',
          'Product',
          'ProductGroup',

          'InformedConsent',
          'ApplicationStatus',
          'ContractStatus',
          'timeElapsed',
          'Source',
          'Actions',
          // "fieldColor"
        ];
      }
    } else {
      if (this.membership == true) {
        this.displayedColumns = [
          'Customer',
          'Date',
          'Operator',
          'Amount',
          'Product',

          'InformedConsent',
          'EmailStatus',
          'InvitationStatus',
          'ApplicationStatus',
          'ContractStatus',
          'timeElapsed',
          'Response',
          'Source',
          'Actions',
          // "fieldColor"
        ];
      } else {
        this.displayedColumns = [
          'Customer',
          'Date',
          'Operator',
          'Amount',
          'Product',
          'ProductGroup',

          'InformedConsent',
          'EmailStatus',
          'InvitationStatus',
          'ApplicationStatus',
          'ContractStatus',
          'timeElapsed',
          'Response',
          'Source',
          'Actions',
          // "fieldColor"
        ];
      }
    }

    if (this.context === 'breeze') {
      let index = this.displayedColumns.indexOf('Source');
      if (index != -1) {
        this.displayedColumns = this.insertBeforeIndex(this.displayedColumns, index, 'marketingDescription');
      }
    }
  }

  insertBeforeIndex(array, index, item) {
    if (index < 0 || index > array.length) {
      return;
    }
    array.splice(index, 0, item);
    return array;
  }

  setFilter(event, field) {
    let filter;

    if (field == 'fullname') {
      let v = event;
      if (v) {
        v = String(v).toLocaleLowerCase();
      }
      filter = {
        field,
        value: v,
      };
    } else if (typeof event == 'object' && event != null) {
      const v = this.customDate.transform(event, Settings.global['dateFormat']);
      filter = {
        field,
        value: v,
      };
    } else if (event) {
      filter = {
        field,
        value: event,
      };
    } else {
      filter = {
        field,
        value: '',
      };
    }

    this.dataSource.filter = filter;
    this.filteredSize = this.dataSource.filteredData.length;
  }

  contact(ID) {
    const data = {
      targetType: 'invitation',
      targetID: ID,
      asGuest: false,
      asProfile: true,
      asPractice: true,
      subjectArray: this.subjectArray,
    };
    AuthenticationService.contactInputEvent.emit(data);
  }

  openQuickViewDialog(ID) {
    const ref = this.dialog.open(ContractsViewComponent, {
      data: {
        customerID: ID,
        isSimple: true,
      },
      width: '900px',
      panelClass: 'noCard',
    });

    ref.componentInstance.closeModal.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  viewPatient(id) {
    // let ref = this.dialog.open(CustomerProspectViewComponent, {
    //   data: id,
    //   width: "600px",
    //   panelClass: "noCard"
    // });
    // ref.componentInstance.closeModal.subscribe(res => {
    //   if (res == true) {
    //     ref.close();
    //   }
    // })
    this.router.navigate([
      '/merchant',
      {
        outlets: {
          page: ['customer-profile', id, 'patient'],
        },
      },
    ]);
  }
  getInvitationUpdate(d) {
    if (d && d.ID) {
      this.dataSource.replaceItem = d;
    }
  }

  getDeletedInvitationID(id) {
    if (id) {
      this.dataSource.removeItem = id;
    }
  }

  openInviteViewDialog(ID) {
    const ref = this.dialog.open(InvitationViewModalComponent, {
      data: {
        invitationID: ID,
        membership: this.membership,
      },
      width: '900px',
    });

    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();

        if (this.showInvDetailInList == true) {
          this.openInviteViewDetails(ID);
        }
      }
    });
  }

  requestAppointment(ID) {
    this.router.navigate([
      '/merchant',
      {
        outlets: {
          page: ['appointment', 'appointment-create-merchant', 'invitation', ID],
        },
      },
    ]);
  }

  fullDetails(invitation) {
    this.router.navigate([
      '/merchant',
      {
        outlets: {
          page: ['customer-profile', invitation['Prospect_key'], 'treatment', invitation['ID']],
        },
      },
    ]);
  }

  resendInvitation(item) {
    if (item && item['Status.Invitation.Short_NE'] != 'Expired') {
      const ref = this.dialog.open(InvitationResendComponent, {
        data: {
          invitationID: item['ID'],
          membership: this.membership,
        },
        width: '600px',
        panelClass: 'noCard',
      });

      ref.componentInstance.close.subscribe((res) => {
        if (res == true) {
          ref.close();
        }
      });

      ref.componentInstance.getInvitation.subscribe((res) => {
        if (res) {
          if (this.voided == true) {
            this.dataSource.removeItem = item['ID'];
          } else {
            this.dataSource.replaceItem = res;
          }
        }
      });
    } else if (item && item['Status.Invitation.Short_NE'] == 'Expired') {
      const confirm = new ConfirmDialog(
        'fas fa-info',
        'Invitation has expired',
        'The invitation you are trying to resend has expired and cannot be resent. <br>You can however clone this invitation and send the new link to the patient. <br><br> Would you like to clone this invitation?',
        'Cancel',
        'Proceed'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
        width: '650px',
      });

      ref.componentInstance.onConfirm.subscribe((res) => {
        if (res == true) {
          ref.close();
          this.openCloneInvitationModal(item['ID'], item['Prospect_key']);
        } else {
          ref.close();
        }
      });
    }
  }

  stretchListChange() {
    this.stretchList = !this.stretchList;
  }

  openInviteViewDetails(ID, patientID = null) {
    if (this.onClickEvent == true) {
      this.clickEvent.emit(ID);
    } else {
      if (this.showInvDetailInList == true) {
        this.viewDetails.emit(true);
        this.invitationID = ID;
        this.displayInvitationView = true;
      } else {
        this.router.navigate([
          '/merchant',
          {
            outlets: {
              page: ['customer-profile', patientID, 'treatment', ID],
            },
          },
        ]);
      }
    }
  }

  createNote(ID, patientID) {
    this.dialog.open<NotesModal, NotesModalConfig>(NotesModal, {
      data: {
        patientID,
        parentRoute: 'merchant',
        noteListMode: 'create',
        relationshipLink: {
          label: 'treatment',
          route: `customer-profile/${patientID}/treatment/${ID}`,
          itemID: ID,
        },
      },
    });
  }

  viewNotes(patientID: string) {
    this.dialog.open<NotesModal, NotesModalConfig>(NotesModal, {
      data: {
        parentRoute: 'merchant',
        patientID,
      },
    });
  }

  cancelInviteViewDetails() {
    this.viewDetails.emit(false);
    this.invitationID = null;
    this.displayInvitationView = false;
  }

  viewOperator(id) {
    const ref = RootAppComponent.dialog.open(DentistViewComponent, {
      data: id,
      width: '600px',
    });
    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  onClickMore() {}

  statusLabel() {
    if (this.voided == true) {
      return 'Archived';
    } else if (this.status) {
      return this.invitationUtil.getStatusLabel(this.status);
    } else {
      return '';
    }
  }

  createTemplate(ID) {
    const _ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: {
        confirmMessage: '<p>Are you sure  You want to create a Treatment Template based on this invitation ? .</p>',
        noMessage: 'Cancel',
        yesMessage: 'Yes',
        title: 'Apply Treatment  Template',
        icon: 'check_circle',
        isInformation: false,
      },
      // SET TO PAYING CASH CARD
    });

    _ref.componentInstance.onConfirm.subscribe((val) => {
      if (val == true) {
        _ref.close();
        _ref.afterClosed().subscribe((res) => {
          const ref = RootAppComponent.dialog.open(TreatmentSaveModalComponent, {
            data: {},

            width: '450px',
          });

          ref.componentInstance.result.subscribe((payload) => {
            if (payload) {
              const _payload = payload;

              this.invitationService.createInvitationTreatmentTemplate(ID, _payload).subscribe((res) => {
                if (res) {
                  NotifyAppComponent.displayToast('success', 'Successful operation', 'Treatment Template is Saved');

                  if (res._id) {
                    ref.close();
                    ref.afterClosed().subscribe((c) => {
                      const ref2 = this.dialog.open(InvitationTemplateMergerComponent, {
                        data: {
                          templateID: res._id,
                          viewOnly: true,
                        },

                        width: '1200px',
                      });

                      ref2.componentInstance.delete.subscribe((res) => {
                        ref2.close();
                      });

                      ref2.componentInstance.close.subscribe((res) => {
                        ref2.close();
                      });
                    });
                  } else {
                    NotifyAppComponent.displayToast(
                      'Error',
                      'Error',
                      'We could not create a treatment template based on this invitation'
                    );
                  }
                }
              });
            }
          });

          ref.componentInstance.closeModal.subscribe((res) => {
            if (res == true) {
              ref.close();
            }
          });
        });
      } else {
        _ref.close();
      }
    });
  }

  convertMobile(val) {
    return '0061' + val.substring(1);
  }

  openPatientResponse(item) {
    if (item['PatientChoice.Code'] == 'DSMO' || item['PatientChoice.Code'] == 'NOT') {
      let message = '';

      if (item['PatientChoice.Code'] == 'NOT') {
        message = item['PatientDeclineReasons.Feedback'] + '<br>' + item['PatientDeclineReasons.Labels'];
      } else if (item['PatientChoice.Code'] == 'DSMO') {
        message = item['PatientFeedback'];
      }

      const confirm = new ConfirmDialogSingleButton('fas fa-info', 'Patient Response', message, 'okay');

      const ref = RootAppComponent.dialog.open(ConfirmDialogSingleButtonComponent, {
        data: confirm,
        width: '650px',
      });

      ref.componentInstance.onConfirm.subscribe((res) => {
        ref.close();
      });
    } else {
      this.openInviteViewDetails(item['ID'], item['Prospect_key']);
    }
  }

  updateProfileInvite(patientID) {
    this.router.navigate([
      '/merchant',
      {
        outlets: {
          page: ['invitation-communication-create', 'profile-update', patientID],
        },
      },
    ]);
  }

  openAuthCodeDialog(id, patientID) {
    const ref = RootAppComponent.dialog.open(MerchantAuthCodeModalComponent, {
      data: {
        invitationID: id,
        type: 'invitation',
        patientID,
      },
      width: '850px',
    });
    const sub = ref.componentInstance.close.subscribe((data) => {
      ref.close();
    });
  }

  ngOnDestroy() {
    this.destroyEvent.emit(true);
  }
}

export class LoadInv implements OnInit {
  public dataChange: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public invitations: any[];
  public count: any;

  get data(): any[] {
    return this.dataChange.value;
  }

  firstLoad = false;

  get firstLoadEvent() {
    return this.firstLoad;
  }

  util = new UtilsClass();

  isDestoyed = false;
  serviceRef;

  sourceList = [];

  public getSourceList = new EventEmitter();

  constructor(
    private invitationService: InvitationService,
    private destroyEvent,
    private productID,
    private staffID,
    private status,
    private voided,
    private dateFrom,
    private dateTo,
    private membership,
    private existingPatientID,
    private _params,

    private customPhonePipe: CustomPhonePipe
  ) {
    this.destroyEvent.subscribe((res) => {
      this.isDestoyed = res;
    });

    const payload = {
      voided: '0',
      dateFrom: this.util.EPdateFormat(this.dateFrom),
      dateTo: this.util.EPdateFormat(this.dateTo),
      status: this.status,
      productID: this.productID,
      staffID: this.staffID,
      existingPatientID: this.existingPatientID,
      section: 0,

      isExpired: this._params.isExpired,
      patientID: this._params.patientID || null,
      hasPatientChoice: this._params.hasPatientChoice,

      productGroupCode: this._params.productGroupCode || null,
      fields:
        'ID,DateTimeCreated,DentistContactName,Instant_Finance_Offer_Key,Document_Link_key,QuotationORContract_Key,Number_Of_Documents,External_Source,Created_From.Code,Created_From.Label,Created_From_Description,MerchantName,ExistingPatientID,Product_Key,Prospect_key,WakandaID,' +
        'DentistContact_key,Email,DateTimeCreated,Product.ThisGroupOnly.Label,Product.ThisGroupOnly.Code,PatientDeclineReasons.Labels,PatientDeclineReasons.Feedback,PatientFeedback,' +
        'LastName,FirstName,Product.Label.Marketing,Status.Email.Short_NE,Voided,' +
        'Status.Invitation.Short_NE,PatientChoice.Label_LongThirdPerson,PatientChoice.Code,Contract_Key,Marketing_Description,' +
        'Status.Contract.Short_NE,Status.Application.Short_NE,Service.Type,treatment.Value,Date.Responded,Date.Commenced,' +
        'Status.Application.Colour,Status.Invitation.Colour,Status.Email.Colour,Status.Contract.Colour,Mobile,SendInvite.ViaSMS,SendInvite.ViaEmail,' +
        'CashDiscount.Offered.YesNo,InformedConsent.Given.When,InformedConsent.Given.Flag,Campaign_Key,Campaign_Label',
    };

    if (this.voided == true) {
      payload.voided = '1';
    }

    if (this.membership) {
      payload.fields =
        'ID,DateTimeCreated,External_Source,Instant_Finance_Offer_Key,Document_Link_key,QuotationORContract_Key,Created_From.Code,Created_From.Label,Created_From_Description,DentistContactName,ExistingPatientID,Product_Key,Prospect_key,' +
        'DentistContact_key,Email,WakandaID,' +
        'LastName,FirstName,Product.Label.Marketing,Status.Email.Short_NE,Voided,DateTimeCreated,PatientDeclineReasons.Labels,PatientDeclineReasons.Feedback,PatientFeedback,' +
        'Status.Invitation.Short_NE,PatientChoice.Label_LongThirdPerson,PatientChoice.Code,Marketing_Description,' +
        'Status.Contract.Short_NE,Status.Application.Short_NE,treatment.Value,Date.Responded,Date.Commenced,Contract_Key,' +
        'Status.Application.Colour,Status.Invitation.Colour,Status.Email.Colour,Status.Contract.Colour,Mobile,SendInvite.ViaSMS,SendInvite.ViaEmail,' +
        'CashDiscount.Offered.YesNo,InformedConsent.Given.When,InformedConsent.Given.Flag';
    }

    this.serviceRef = this.invitationService.getInvitations(payload, false, this.membership).subscribe((res) => {
      // console.log(res);
      if (res.success) {
        if (res && res.data) {
          for (let i = 0; i < res.data.length; i++) {
            res.data[i]['fullName'] = '';

            if (res.data[i]['External_Source'] !== '') {
              res.data[i]['SourceLabel'] = 'Invitation - 3rd Party App';
            } else if (
              res.data[i]['Campaign_Key'] !== '' &&
              res.data[i]['External_Source'] == '' &&
              res.data[i]['Created_From.Label'] == ''
            ) {
              res.data[i]['SourceLabel'] = 'Marketing Campaign';
            } else if (
              res.data[i]['Campaign_Key'] == '' &&
              res.data[i]['External_Source'] == '' &&
              res.data[i]['Created_From.Label'] == ''
            ) {
              res.data[i]['SourceLabel'] = 'Invitation';
            } else if (res.data[i]['Created_From'] !== '') {
              res.data[i]['SourceLabel'] = res.data[i]['Created_From'];
            } else {
              res.data[i]['SourceLabel'] = 'Invitation';
            }

            if (res.data[i]['DentistName']) {
              res.data[i]['fullName'] = String(
                res.data[i]['fullName'] + res.data[i]['DentistName']
              ).toLocaleLowerCase();
            }

            if (res.data[i]['FirstName']) {
              res.data[i]['fullName'] = String(res.data[i]['fullName'] + res.data[i]['FirstName']).toLocaleLowerCase();
            }

            if (res.data[i]['MiddleName']) {
              res.data[i]['fullName'] = String(res.data[i]['fullName'] + res.data[i]['MiddleName']).toLocaleLowerCase();
            }

            if (res.data[i]['LastName']) {
              res.data[i]['fullName'] = String(res.data[i]['fullName'] + res.data[i]['LastName']).toLocaleLowerCase();
            }

            if (res.data[i]['Email']) {
              res.data[i]['fullName'] = String(res.data[i]['fullName'] + res.data[i]['Email']).toLocaleLowerCase();
            }

            if (res.data[i]['Mobile']) {
              res.data[i]['fullName'] = String(res.data[i]['fullName'] + res.data[i]['Mobile']).toLocaleLowerCase();
            }

            if (res.data[i]['Mobile']) {
              res.data[i]['fullName'] =
                res.data[i]['fullName'] +
                ' ' +
                this.customPhonePipe.transform(res.data[i]['Mobile'], 'none').replace(/ /g, '');
            }
            if (res.data[i]['Mobile']) {
              res.data[i]['fullName'] =
                res.data[i]['fullName'] + ' ' + this.customPhonePipe.transform(res.data[i]['Mobile'], 'none');
            }

            if (res.data[i]['Phone.Work']) {
              res.data[i]['fullName'] =
                res.data[i]['fullName'] +
                ' ' +
                this.customPhonePipe.transform(res.data[i]['Phone.Work'], 'landLine').replace(/ /g, '');
            }
            if (res.data[i]['Phone.Work']) {
              res.data[i]['fullName'] =
                res.data[i]['fullName'] + ' ' + this.customPhonePipe.transform(res.data[i]['Phone.Work'], 'landLine');
            }

            if (res.data[i]['Phone.Home']) {
              res.data[i]['fullName'] =
                res.data[i]['fullName'] +
                ' ' +
                this.customPhonePipe.transform(res.data[i]['Phone.Home'], 'landLine').replace(/ /g, '');
            }
            if (res.data[i]['Phone.Home']) {
              res.data[i]['fullName'] =
                res.data[i]['fullName'] +
                ' ' +
                this.customPhonePipe.transform(res.data[i]['Phone.WoHomerk'], 'landLine');
            }

            if (res.data[i]['Phone.Work']) {
              res.data[i]['fullName'] = String(res.data[i]['fullName'] + res.data[i]['Phone.Work']).toLocaleLowerCase();
            }

            if (res.data[i]['Phone.Home']) {
              res.data[i]['fullName'] = String(res.data[i]['fullName'] + res.data[i]['Phone.Home']).toLocaleLowerCase();
            }

            if (res.data[i]['Campaign_Label']) {
              res.data[i]['fullName'] = String(
                res.data[i]['fullName'] + res.data[i]['Campaign_Label']
              ).toLocaleLowerCase();
            }

            if (res.data[i]['Campaign_Description']) {
              res.data[i]['fullName'] = String(
                res.data[i]['fullName'] + res.data[i]['Campaign_Description']
              ).toLocaleLowerCase();
            }

            if (res.data[i]['Treatment.Value']) {
              res.data[i]['fullName'] = String(
                res.data[i]['fullName'] + res.data[i]['Treatment.Value']
              ).toLocaleLowerCase();
            }

            if (res.data[i]['MessageFromDentist']) {
              res.data[i]['fullName'] = String(
                res.data[i]['fullName'] + res.data[i]['MessageFromDentist']
              ).toLocaleLowerCase();
            }

            if (res.data[i]['Product.Label.Internal']) {
              res.data[i]['fullName'] = String(
                res.data[i]['fullName'] + res.data[i]['Product.Label.Internal']
              ).toLocaleLowerCase();
            }
            ('Product.Label.Web');

            if (res.data[i]['Product.Label.Marketing']) {
              res.data[i]['fullName'] = String(
                res.data[i]['fullName'] + res.data[i]['Product.Label.Marketing']
              ).toLocaleLowerCase();
            }

            if (res.data[i]['Product.Label.Web']) {
              res.data[i]['fullName'] = String(
                res.data[i]['fullName'] + res.data[i]['Product.Label.Web']
              ).toLocaleLowerCase();
            }
            if (res.data[i]['Marketing_Description']) {
              res.data[i]['fullName'] = res.data[i]['Marketing_Description'];
            }
            if (res.data[i]['MerchantName']) {
              res.data[i]['fullName'] = String(
                res.data[i]['fullName'] + res.data[i]['MerchantName']
              ).toLocaleLowerCase();
            }
            if (res.data[i]['ID']) {
              res.data[i]['fullName'] = res.data[i]['fullName'] + res.data[i]['ID'];
            }

            if (res.data[i]['WakandaID']) {
              res.data[i]['fullName'] = res.data[i]['fullName'] + res.data[i]['WakandaID'];
            }

            if (res.data[i]['DentistContactName']) {
              res.data[i]['fullName'] = String(
                res.data[i]['fullName'] + res.data[i]['DentistContactName']
              ).toLocaleLowerCase();
            }

            if (res.data[i] && res.data[i]['SourceLabel']) {
              res.data[i]['fullName'] = String(
                res.data[i]['fullName'] + res.data[i]['SourceLabel']
              ).toLocaleLowerCase();
              this.sourceList.push(res.data[i]['SourceLabel']);
            }
          }

          this.sourceList = uniq(this.sourceList);
          this.sourceList = orderBy(this.sourceList);
          this.getSourceList.emit(this.sourceList);
        }
        this.count = res.count;
        this.invitations = res.data;
        this.dataChange.next(this.invitations);
        this.firstLoad = true;
        const innerFunction = (section) => {
          section = section + 1;
          UtilsClass.loadingDataSection(section);
          payload.section = section;

          this.serviceRef = this.invitationService.getInvitations(payload, false, this.membership).subscribe((res) => {
            if (res.success) {
              if (res.data.length > 0) {
                if (res && res.data) {
                  for (let i = 0; i < res.data.length; i++) {
                    res.data[i]['fullName'] = '';

                    if (res.data[i]['External_Source'] !== '') {
                      res.data[i]['SourceLabel'] = 'Invitation - 3rd Party App';
                    } else if (
                      res.data[i]['Campaign_Key'] !== '' &&
                      res.data[i]['External_Source'] == '' &&
                      res.data[i]['Created_From.Label'] == ''
                    ) {
                      res.data[i]['SourceLabel'] = 'Marketing Campaign';
                    } else if (
                      res.data[i]['Campaign_Key'] == '' &&
                      res.data[i]['External_Source'] == '' &&
                      res.data[i]['Created_From.Label'] == ''
                    ) {
                      res.data[i]['SourceLabel'] = 'Invitation';
                    } else if (res.data[i]['Created_From'] !== '') {
                      res.data[i]['SourceLabel'] = res.data[i]['Created_From'];
                    } else {
                      res.data[i]['SourceLabel'] = 'Invitation';
                    }

                    if (res.data[i]['DentistName']) {
                      res.data[i]['fullName'] = String(
                        res.data[i]['fullName'] + res.data[i]['DentistName']
                      ).toLocaleLowerCase();
                    }

                    if (res.data[i]['FirstName']) {
                      res.data[i]['fullName'] = String(
                        res.data[i]['fullName'] + res.data[i]['FirstName']
                      ).toLocaleLowerCase();
                    }

                    if (res.data[i]['MiddleName']) {
                      res.data[i]['fullName'] = String(
                        res.data[i]['fullName'] + res.data[i]['MiddleName']
                      ).toLocaleLowerCase();
                    }

                    if (res.data[i]['LastName']) {
                      res.data[i]['fullName'] = String(
                        res.data[i]['fullName'] + res.data[i]['LastName']
                      ).toLocaleLowerCase();
                    }

                    if (res.data[i]['Email']) {
                      res.data[i]['fullName'] = String(
                        res.data[i]['fullName'] + res.data[i]['Email']
                      ).toLocaleLowerCase();
                    }

                    if (res.data[i]['Mobile']) {
                      res.data[i]['fullName'] = String(
                        res.data[i]['fullName'] + res.data[i]['Mobile']
                      ).toLocaleLowerCase();
                    }

                    if (res.data[i]['Mobile']) {
                      res.data[i]['fullName'] =
                        res.data[i]['fullName'] +
                        ' ' +
                        this.customPhonePipe.transform(res.data[i]['Mobile'], 'none').replace(/ /g, '');
                    }
                    if (res.data[i]['Mobile']) {
                      res.data[i]['fullName'] =
                        res.data[i]['fullName'] + ' ' + this.customPhonePipe.transform(res.data[i]['Mobile'], 'none');
                    }

                    if (res.data[i]['Phone.Work']) {
                      res.data[i]['fullName'] =
                        res.data[i]['fullName'] +
                        ' ' +
                        this.customPhonePipe.transform(res.data[i]['Phone.Work'], 'landLine').replace(/ /g, '');
                    }
                    if (res.data[i]['Phone.Work']) {
                      res.data[i]['fullName'] =
                        res.data[i]['fullName'] +
                        ' ' +
                        this.customPhonePipe.transform(res.data[i]['Phone.Work'], 'landLine');
                    }

                    if (res.data[i]['Phone.Home']) {
                      res.data[i]['fullName'] =
                        res.data[i]['fullName'] +
                        ' ' +
                        this.customPhonePipe.transform(res.data[i]['Phone.Home'], 'landLine').replace(/ /g, '');
                    }
                    if (res.data[i]['Phone.Home']) {
                      res.data[i]['fullName'] =
                        res.data[i]['fullName'] +
                        ' ' +
                        this.customPhonePipe.transform(res.data[i]['Phone.WoHomerk'], 'landLine');
                    }

                    if (res.data[i]['Phone.Work']) {
                      res.data[i]['fullName'] = String(
                        res.data[i]['fullName'] + res.data[i]['Phone.Work']
                      ).toLocaleLowerCase();
                    }
                    if (res.data[i]['Marketing_Description']) {
                      res.data[i]['fullName'] = res.data[i]['Marketing_Description'];
                    }
                    if (res.data[i]['Phone.Home']) {
                      res.data[i]['fullName'] = String(
                        res.data[i]['fullName'] + res.data[i]['Phone.Home']
                      ).toLocaleLowerCase();
                    }
                    if (res.data[i]['ID']) {
                      res.data[i]['fullName'] = res.data[i]['fullName'] + res.data[i]['ID'];
                    }

                    if (res.data[i]['WakandaID']) {
                      res.data[i]['fullName'] = res.data[i]['fullName'] + res.data[i]['WakandaID'];
                    }
                    if (res.data[i]['Campaign_Label']) {
                      res.data[i]['fullName'] = String(
                        res.data[i]['fullName'] + res.data[i]['Campaign_Label']
                      ).toLocaleLowerCase();
                    }

                    if (res.data[i]['Campaign_Description']) {
                      res.data[i]['fullName'] = String(
                        res.data[i]['fullName'] + res.data[i]['Campaign_Description']
                      ).toLocaleLowerCase();
                    }

                    if (res.data[i]['Treatment.Value']) {
                      res.data[i]['fullName'] = String(
                        res.data[i]['fullName'] + res.data[i]['Treatment.Value']
                      ).toLocaleLowerCase();
                    }

                    if (res.data[i]['MessageFromDentist']) {
                      res.data[i]['fullName'] = String(
                        res.data[i]['fullName'] + res.data[i]['MessageFromDentist']
                      ).toLocaleLowerCase();
                    }

                    if (res.data[i]['Product.Label.Internal']) {
                      res.data[i]['fullName'] = String(
                        res.data[i]['fullName'] + res.data[i]['Product.Label.Internal']
                      ).toLocaleLowerCase();
                    }
                    ('Product.Label.Web');

                    if (res.data[i]['Product.Label.Marketing']) {
                      res.data[i]['fullName'] = String(
                        res.data[i]['fullName'] + res.data[i]['Product.Label.Marketing']
                      ).toLocaleLowerCase();
                    }

                    if (res.data[i]['Product.Label.Web']) {
                      res.data[i]['fullName'] = String(
                        res.data[i]['fullName'] + res.data[i]['Product.Label.Web']
                      ).toLocaleLowerCase();
                    }

                    if (res.data[i]['MerchantName']) {
                      res.data[i]['fullName'] = String(
                        res.data[i]['fullName'] + res.data[i]['MerchantName']
                      ).toLocaleLowerCase();
                    }

                    if (res.data[i]['DentistContactName']) {
                      res.data[i]['fullName'] = String(
                        res.data[i]['fullName'] + res.data[i]['DentistContactName']
                      ).toLocaleLowerCase();
                    }

                    if (res.data[i] && res.data[i]['SourceLabel']) {
                      res.data[i]['fullName'] = String(
                        res.data[i]['fullName'] + res.data[i]['SourceLabel']
                      ).toLocaleLowerCase();
                      this.sourceList.push(res.data[i]['SourceLabel']);
                    }
                  }

                  this.sourceList = uniq(this.sourceList);
                  this.sourceList = orderBy(this.sourceList);
                  this.getSourceList.emit(this.sourceList);
                }
                this.count = res.count;
                this.invitations = this.invitations.concat(res.data);

                this.dataChange.next(this.invitations);

                if (this.isDestoyed != true) {
                  innerFunction(section);
                }
              } else {
                return true;
              }
            } else {
              return false;
            }
          });
          UtilsClass.stopLoadingInterceptor();
        };

        if (this.isDestoyed != true) {
          innerFunction(1);
        }
      }
    });
  }

  ngOnInit() {}

  ngOnDestroy() {
    if (this.serviceRef) {
      this.serviceRef.unsubscribe();
    }
  }
}

export class InvitationDataSource extends DataSource<any> {
  _filterChange = new BehaviorSubject('');
  field = '';

  get filter(): any {
    return this._filterChange.value;
  }

  set filter(item: any) {
    this.field = item.field;
    this._filterChange.next(item.value);
  }

  set replaceItem(item) {
    const _index = this._invDatabase.data.findIndex((_obj) => _obj.ID == item.ID);
    this._invDatabase.data[_index] = item;
    this._invDatabase.dataChange.next(this._invDatabase.data);
  }

  set removeItem(id) {
    const data = this._invDatabase.data.filter((row) => row.ID != id);
    this._invDatabase.dataChange.next(data);
  }

  filteredData: any[] = [];

  constructor(private _invDatabase: any, private _paginator: MatPaginator, private _sort: MatSort) {
    super();
    this._filterChange.subscribe(() => (this._paginator.pageIndex = 0));
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<any[]> {
    const displayDataChanges = [
      this._invDatabase.dataChange,
      this._filterChange,
      this._paginator.page,
      this._sort.sortChange,
    ];

    return observableMerge(...displayDataChanges).pipe(
      map(() => {
        // Filter data
        this.filteredData = this._invDatabase.data.slice().filter((item: any) => {
          let f = '';

          f = item[this.field] || '';

          const searchStr = f.toLowerCase();
          return searchStr.indexOf(this.filter.toLowerCase()) != -1;
        });
        const data = this.getSortedData(this.filteredData.slice());
        // Grab the page's slice of data.
        const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
        return data.splice(startIndex, this._paginator.pageSize);
      })
    );
  }

  disconnect() {}

  /** Returns a sorted copy of the database data. */
  getSortedData(data: any[]): any[] {
    // const data = data;
    if (!this._sort.active || this._sort.direction == '') {
      return data;
    }

    return data.sort((a, b) => {
      let propertyA: number | string = '';
      let propertyB: number | string = '';

      switch (this._sort.active) {
        case 'Date':
          [propertyA, propertyB] = [
            moment(a['DateTimeCreated']).toDate().getTime(),
            moment(b['DateTimeCreated']).toDate().getTime(),
          ];
          break;
        case 'Operator':
          [propertyA, propertyB] = [a.DentistContactName, b.DentistContactName];
          break;
        case 'Customer':
          [propertyA, propertyB] = [a['FirstName'], b['LastName']];
          break;
        case 'Email':
          [propertyA, propertyB] = [a['Service.Email'], b['Service.Email']];
          break;
        case 'Amount':
          [propertyA, propertyB] = [a['treatment.Value'], b['treatment.Value']];
          break;
        case 'Product':
          [propertyA, propertyB] = [a['Product.Label.Marketing'], b['Product.Label.Marketing']];
          break;
        case 'ProductGroup':
          [propertyA, propertyB] = [a['Product.ThisGroupOnly.Label'], b['Product.ThisGroupOnly.Label']];
          break;

        case 'InformedConsent':
          [propertyA, propertyB] = [a['InformedConsent.Given.Flag'], b['InformedConsent.Given.Flag']];
          break;

        case 'Source':
          [propertyA, propertyB] = [a['PatientChoice.Code'], b['PatientChoice.Code']];
          break;
        case 'EmailStatus':
          [propertyA, propertyB] = [a['Status.Email.Short'], b['Status.Email.Short']];
          break;
        case 'InvitationStatus':
          [propertyA, propertyB] = [a['Status.Invitation.Short'], b['Status.Invitation.Short']];
          break;
        case 'ApplicationStatus':
          [propertyA, propertyB] = [a['Status.Application.Short'], b['Status.Application.Short']];
          break;
        case 'ContractStatus':
          [propertyA, propertyB] = [a['Status.Contract.Short'], b['Status.Contract.Short']];
          break;
        case 'Response':
          [propertyA, propertyB] = [a['PatientChoice.Label_LongThirdPerson'], b['PatientChoice.Label_LongThirdPerson']];
          break;

        case 'timeElapsed':
          [propertyA, propertyB] = [
            moment(a['DateTimeCreated']).toDate().getTime(),
            moment(b['DateTimeCreated']).toDate().getTime(),
          ];
          break;

        case 'timeInvited':
          [propertyA, propertyB] = [
            moment(a['DateTimeCreated']).toDate().getTime(),
            moment(b['DateTimeCreated']).toDate().getTime(),
          ];
          break;
        case 'timeResponded':
          [propertyA, propertyB] = [
            moment(a['Date.Responded']).toDate().getTime(),
            moment(b['Date.Responded']).toDate().getTime(),
          ];
          break;
      }

      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

      return (valueA < valueB ? -1 : 1) * (this._sort.direction == 'asc' ? 1 : -1);
    });
  }
}
