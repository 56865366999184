<ipv-page
  *ngIf="(viewModel$ | async) as viewModel"
  [isFetchingInitialData]="isFetchingInitialData"
  [dataHeader]="{title: 'Informed Consent', counterValue: informedConsentListLength}"
>
  <div style="margin-top: 10px; margin-bottom: 40px; width: 100%; display: flex; justify-content: flex-end">
    <mat-button-toggle-group
      class="btn-group"
      [value]="informedConsentGivenFilter"
      (change)="setInformedConsentGivenFilter($event.value)"
    >
      <mat-button-toggle [value]="''"> All</mat-button-toggle>
      <mat-button-toggle [value]="'1'"> Consented</mat-button-toggle>
      <mat-button-toggle [value]="'0'"> Unconsented</mat-button-toggle>
    </mat-button-toggle-group>
  </div>

  <ipv-data-table-control-container>
    <ipv-promoter-merchant-selector
      [startWithAllMerchants]="true"
      (merchantSet)="setMerchantIdFilter($event)"
      style="margin-right: 20px"
    >
    </ipv-promoter-merchant-selector>

    <app-date-range-picker [smallerInput]="false" (getRange)="setDateRangeFilter($event)"></app-date-range-picker>

    <!-- Email -->

    <mat-form-field appearance="outline">
      <mat-label>Email</mat-label>
      <div style="display: flex">
        <input name="Email" matInput (input)="setEmailFilter($event.target.value)" [value]="viewModel.emailFilter"/>

        <ipv-clear-search-button *ngIf="viewModel.emailFilter" (click)="setEmailFilter(null)"></ipv-clear-search-button>
      </div>
    </mat-form-field>

    <!-- First Name -->
    <mat-form-field appearance="outline">
      <mat-label>First Name</mat-label>
      <input
        name="FirstName"
        matInput
        (input)="setFirstNameFilter($event.target.value)"
        [value]="viewModel.firstNameFilter"
      />

      <ipv-clear-search-button
        *ngIf="viewModel.firstNameFilter"
        (click)="setEmailFilter(null)"
      ></ipv-clear-search-button>
    </mat-form-field>

    <!-- Last Name -->
    <mat-form-field appearance="outline">
      <mat-label>Last Name</mat-label>
      <input
        name="LastName"
        matInput
        (input)="setLastNameFilter($event.target.value)"
        [value]="viewModel.lastNameFilter"
      />

      <ipv-clear-search-button
        *ngIf="viewModel.lastNameFilter"
        (click)="setEmailFilter(null)"
      ></ipv-clear-search-button>
    </mat-form-field>
  </ipv-data-table-control-container>

  <ipv-informed-consent-treatment-presented-main
    [informedConsentList]="viewModel.informedConsentList"
    [printMerchant]="selectedMerchant"
    [isPromoterOrAdmin]="isPromoterOrAdmin"
  ></ipv-informed-consent-treatment-presented-main>
</ipv-page>
