import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { combineLatest, EMPTY, of } from 'rxjs';
import { debounceTime, expand, map, switchMap, tap } from 'rxjs/operators';
import { AppStateService } from '../../../../../core/app-state/app-state.service';
import { AppState } from '../../../../../core/app-state/app-state.type';
import { STANDARD_KEYBOARD_DEBOUNCE_TIME } from '../../../../../shared/config/STANDARD_KEYBOARD_DEBOUNCE_TIME';
import { UtilsClass } from '../../../../../shared/types/utils/utils.class';
import { ProductCatalogueItem } from '../../../shared/services/product-catalogue.service';
import {
  ProductInvoice,
  ProductInvoiceQuery,
  ProductInvoiceService,
} from '../../../shared/services/product-invoice.service';
import {
  InvoiceFormModalConfig,
  InvoiceProductFormModalComponent,
} from '../../modals/product-invoice-form.modal.ts/product-invoice-form.modal.ts.component';
import {
  ViewInvoiceProductModalComponent,
  ViewInvoiceProductModalConfig,
} from '../../modals/view-product-invoice.modal/view-product-invoice.modal';

@Component({
  selector: 'ipv-retail-product-invoice-list',
  templateUrl: './retail-product-invoice-list.component.html',
  styleUrls: ['./retail-product-invoice-list.component.css'],
})
export class RetailProductInvoiceListComponent {
  isFetchingInitialData = true;

  titleIcon = null;
  title = 'Invoices';

  hiddenColumns = [
    'ID',
    'DateTimeCreated',
    'LastModified',
    'LastModified_Human',
    'Owner_Card_key',
    'Contract_key',
    'Merchant_key',
    'Amount_Borrowed',
    'Notes',
    'Invoice.Type.Code',
    'Invoice.Type.Label',
    'Invoice.Status.Code',
    'Invoice.Status.Label',
    '',
  ];

  renamedColumns = {
    Invoice_Number: 'Invoice',
    Invoice_Date: 'Date',
    Invoice_Total: 'Total',
    CustomerCalculatedName: 'Customer Name',
    CustomerEmail: 'Customer Email',
    ProductLabel: 'Product',
    CustomerNumber: 'Customer Mobile',
  };

  order = [
    'Invoice_Number',
    'Invoice_Date',
    'Invoice_Total',
    'ProductLabel',
    'CustomerCalculatedName',
    'CustomerEmail',
    'CustomerNumber',
  ];

  viewModel$ = this.appStateService.getAppState$().pipe(
    switchMap((appState) =>
      this.getFilters$().pipe(
        switchMap(([customerCalculatedNameFilter, customerEmailFilter, customerProductLabelFilter, Invoice_Number]) =>
          this.fetchData$({
            payload: {
              Invoice_Number,
            },
          }).pipe(
            switchMap(() =>
              this.getData$({ customerCalculatedNameFilter, customerEmailFilter, customerProductLabelFilter })
            ),
            switchMap(([productInvoices]) =>
              this.getViewModel$({
                appState,
                productInvoices,
                Invoice_Number,
                customerCalculatedNameFilter,
                customerEmailFilter,
                customerProductLabelFilter,
              })
            )
          )
        )
      )
    )
  );

  constructor(
    private productInvoicesService: ProductInvoiceService,
    private dialog: MatDialog,
    private appStateService: AppStateService
  ) {}

  getFilters$() {
    return combineLatest([
      this.getCustomerCalculatedNameFilter$(),
      this.getCustomerEmailFilter$(),
      this.getCustomerProductLabelFilter$(),
      this.getInvoiceFilter$(),
    ]);
  }

  getCustomerCalculatedNameFilter$() {
    return this.productInvoicesService
      .getCustomerCalculatedNameFilter$()
      .pipe(debounceTime(STANDARD_KEYBOARD_DEBOUNCE_TIME));
  }

  setCustomerCalculatedNameFilter(customerFirstName: string) {
    this.productInvoicesService.setCustomerCalculatedNameFilter(customerFirstName);
  }

  getCustomerEmailFilter$() {
    return this.productInvoicesService.getCustomerEmailFilter$().pipe(debounceTime(STANDARD_KEYBOARD_DEBOUNCE_TIME));
  }

  setCustomerEmailFilter(customerEmailFilter: string) {
    this.productInvoicesService.setCustomerEmailFilter(customerEmailFilter);
  }

  getCustomerProductLabelFilter$() {
    return this.productInvoicesService
      .getCustomerProductLabelFilter$()
      .pipe(debounceTime(STANDARD_KEYBOARD_DEBOUNCE_TIME));
  }

  setCustomerProductLabelFilter(productLabel: string) {
    this.productInvoicesService.setCustomerProductLabelFilter(productLabel);
  }

  getInvoiceFilter$() {
    return this.productInvoicesService.getInvoiceLabelFilter$().pipe(debounceTime(STANDARD_KEYBOARD_DEBOUNCE_TIME));
  }

  setInvoiceFilter(invoiceFilter: string) {
    this.productInvoicesService.setInvoiceLabelFilter(invoiceFilter);
  }

  fetchData$(parameters: ProductInvoiceQuery) {
    return combineLatest([this.fetchProductInvoiceSections$(parameters)]);
  }

  fetchProductInvoiceSections$(parameters: ProductInvoiceQuery) {
    this.productInvoicesService.setProductInvoices([]);

    const query: ProductInvoiceQuery = {
      payload: Object.assign(
        {
          section: 0,
        },
        parameters.payload
      ),
    };

    return this.productInvoicesService.fetchProductInvoices<Partial<ProductInvoice>>(query.payload).pipe(
      tap(() => {
        UtilsClass.stopLoadingInterceptor();
        query.payload.section++;
        UtilsClass.loadingDataSection(query.payload.section);
      }),
      expand((productInvoiceSection) =>
        productInvoiceSection.length > 0
          ? this.productInvoicesService.fetchProductInvoices<Partial<ProductCatalogueItem>>(query.payload).pipe(
              tap(() => {
                UtilsClass.stopLoadingInterceptor();
              })
            )
          : EMPTY
      ),
      tap((productInvoiceSection) => {
        if (productInvoiceSection.length > 0) {
          query.payload.section++;
          UtilsClass.loadingDataSection(query.payload.section);
          const items = this.productInvoicesService.getProductInvoices();
          const newItems = items.concat(productInvoiceSection);
          this.productInvoicesService.setProductInvoices(newItems);
        }
      })
    );
  }

  getData$(parameters: { customerCalculatedNameFilter; customerEmailFilter; customerProductLabelFilter }) {
    return combineLatest([
      this.getProductInvoices$().pipe(
        map((productInvoices) => {
          return productInvoices
            .filter((productInvoice) => {
              return productInvoice.CustomerCalculatedName.includes(parameters.customerCalculatedNameFilter);
            })
            .filter((productCustomer) => {
              return productCustomer.CustomerEmail.includes(parameters.customerEmailFilter);
            })
            .filter((productCustomer) => {
              return productCustomer.ProductLabel.includes(parameters.customerProductLabelFilter);
            });
        })
      ),
    ]);
  }

  getProductInvoices$() {
    return this.productInvoicesService.getProductInvoices$<Partial<ProductInvoice>>();
  }

  getViewModel$(parameters: {
    appState: AppState;
    productInvoices: Partial<ProductInvoice>[];
    Invoice_Number;
    customerCalculatedNameFilter;
    customerEmailFilter;
    customerProductLabelFilter;
  }) {
    const { appState, Invoice_Number, customerCalculatedNameFilter, customerEmailFilter, customerProductLabelFilter } =
      parameters;

    let { productInvoices } = parameters;

    this.isFetchingInitialData = false;
    const { user, practice, sessionType, isPromoterOrAdmin, dentist } = appState;

    productInvoices = [
      {
        ID: 'sdfdfds',
        DateTimeCreated: '2021-08-26 09:05',
        LastModified: '2021-08-26 09:05',
        LastModified_Human: '2021-08-26 09:05',
        Invoice_Number: 'dsfdsfs',
        Owner_Card_key: 'sfdsfs',
        Contract_key: 'sfdsfds',
        Merchant_key: 'sdfdsfs',
        Amount_Borrowed: 0,
        Notes: '',
        Invoice_Date: '2021-08-26 09:05',
        Invoice_Total: 0,
        'Invoice.Type.Code': 'asdasd',
        'Invoice.Type.Label': 'adsadsa',
        'Invoice.Status.Code': 'asdasdsa',
        'Invoice.Status.Label': 'adsad',
        CustomerCalculatedName: 'asdas',
        CustomerEmail: 'mel@smileright.com.au',
        ProductLabel: 'asdasdsa',
        CustomerNumber: '0061416428991',
      },
    ];

    console.log(productInvoices);

    const result = {
      user,
      practice,
      sessionType,
      isPromoterOrAdmin,
      dentist,
      productInvoices,
      Invoice_Number,
      customerCalculatedNameFilter,
      customerEmailFilter,
      customerProductLabelFilter,
      industryType: appState.publicSettings.industryType,
    };
    return of(result);
  }

  viewInvoice(productInvoice: ProductInvoice, industryType: string) {
    const ref = this.dialog.open<ViewInvoiceProductModalComponent, ViewInvoiceProductModalConfig>(
      ViewInvoiceProductModalComponent,
      {
        data: {
          productInvoice,
          industryType,
        },
        width: '800px',
      }
    );
  }

  updateProduct(productInvoice: ProductInvoice, industryType: string) {
    const ref = this.dialog.open<InvoiceProductFormModalComponent, InvoiceFormModalConfig>(
      InvoiceProductFormModalComponent,
      {
        data: {
          productInvoice,
          industryType,
          formMode: 'update',
        },
        width: '800px',
      }
    );
  }

  add(industryType: string) {
    const ref = this.dialog.open<InvoiceProductFormModalComponent, InvoiceFormModalConfig>(
      InvoiceProductFormModalComponent,
      {
        data: { industryType },
        width: '800px',
      }
    );
  }
}
