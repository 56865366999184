<mat-tab-group class="demo-tab-group">
  <mat-tab label="Capacity Checks">
    <div class="demo-tab-content">
      <div class="row clearfix">
        <h1 class="dataHeader rm-mt rm-mb pull-left">
          Capacity Checks:
          <span class="badge" countUp></span>
        </h1>
      </div>
      <br/>

      <div class="row clearfix">
        <div class="full width">
          <mat-card class="list-card">
            <mat-table class="list-table" #table matSort>
              <!--- Note that these columns can be defined in any order.
                                          The actual rendered columns are set as a property on the row definition" -->
              <!-- ID Column -->

              <!-- Progress Column -->
              <ng-container cdkColumnDef="InitialDate">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header>Initial Date</mat-header-cell>
                <mat-cell *cdkCellDef="let row"></mat-cell>
              </ng-container>
              <ng-container cdkColumnDef="FinalDate">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header>Final Date</mat-header-cell>
                <mat-cell *cdkCellDef="let row"></mat-cell>
              </ng-container>

              <!-- Name Column -->
              <ng-container cdkColumnDef="InitialResult">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header>Initial Result</mat-header-cell>
                <mat-cell *cdkCellDef="let row"></mat-cell>
              </ng-container>
              <!-- Name Column -->

              <!-- Color Column -->
              <ng-container cdkColumnDef="FinalResult">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header>Final Result</mat-header-cell>
                <mat-cell *cdkCellDef="let row"></mat-cell>
              </ng-container>
              <!-- Color Column -->
              <ng-container cdkColumnDef="Product">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header>Product</mat-header-cell>
                <mat-cell *cdkCellDef="let row"></mat-cell>
              </ng-container>
              <ng-container cdkColumnDef="ContractID">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header>Contract ID</mat-header-cell>
                <mat-cell *cdkCellDef="let row"></mat-cell>
              </ng-container>

              <!-- Action Button Column -->
              <ng-container cdkColumnDef="Actions">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header>Actions</mat-header-cell>
                <mat-cell *cdkCellDef="let row">
                  <div style="float: right">
                    <button [matMenuTriggerFor]="menu" mat-icon-button>
                      <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                      <button mat-menu-item>
                        <mat-icon>delete</mat-icon>
                        <span>Delete</span>
                      </button>
                    </mat-menu>
                  </div>
                </mat-cell>
              </ng-container>
              <mat-header-row *cdkHeaderRowDef="displayedColumnsCC"></mat-header-row>
              <mat-row *cdkRowDef="let row; columns: displayedColumnsCC"></mat-row>
            </mat-table>
          </mat-card>
        </div>
      </div>
    </div>
  </mat-tab>
  <mat-tab label="Credit Checks">
    <div class="demo-tab-content">
      <div class="row clearfix">
        <h1 class="dataHeader rm-mt rm-mb pull-left">
          Credit Checks:
          <span class="badge" countUp></span>
        </h1>
        <button class="btn-small pull-right" id="newMessage" mat-raised-button color="accent">
          <mat-icon>add</mat-icon>
          New Credit Check
        </button>
      </div>
      <br/>

      <div class="row clearfix">
        <div class="full width">
          <mat-card class="list-card">
            <mat-table class="list-table" #table matSort>
              <!--- Note that these columns can be defined in any order.
                                          The actual rendered columns are set as a property on the row definition" -->
              <!-- ID Column -->

              <!-- Progress Column -->
              <ng-container cdkColumnDef="InitialDate">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header>Initial Date</mat-header-cell>
                <mat-cell *cdkCellDef="let row"></mat-cell>
              </ng-container>
              <!-- Name Column -->
              <ng-container cdkColumnDef="FinalDate">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header>Final Date</mat-header-cell>
                <mat-cell *cdkCellDef="let row"></mat-cell>
              </ng-container>
              <!-- Name Column -->

              <!-- Color Column -->
              <ng-container cdkColumnDef="InitialResult">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header>Initial Results</mat-header-cell>
                <mat-cell *cdkCellDef="let row"></mat-cell>
              </ng-container>
              <!-- Color Column -->
              <ng-container cdkColumnDef="FinalResult">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header>Final Result</mat-header-cell>
                <mat-cell *cdkCellDef="let row"></mat-cell>
              </ng-container>
              <ng-container cdkColumnDef="Product">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header>Product</mat-header-cell>
                <mat-cell *cdkCellDef="let row"></mat-cell>
              </ng-container>
              <ng-container cdkColumnDef="ContractID">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header>Contract ID</mat-header-cell>
                <mat-cell *cdkCellDef="let row"></mat-cell>
              </ng-container>

              <!-- Action Button Column -->
              <ng-container cdkColumnDef="Actions">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header>Actions</mat-header-cell>
                <mat-cell *cdkCellDef="let row">
                  <div style="float: right">
                    <button [matMenuTriggerFor]="menu" mat-icon-button>
                      <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu"></mat-menu>
                  </div>
                </mat-cell>
              </ng-container>
              <mat-header-row *cdkHeaderRowDef="displayedColumnsCCC"></mat-header-row>
              <mat-row *cdkRowDef="let row; columns: displayedColumnsCCC"></mat-row>
            </mat-table>
          </mat-card>
        </div>
      </div>
    </div>
  </mat-tab>
  <mat-tab label="ID Checks">
    <div class="demo-tab-content">
      <div class="row clearfix">
        <h1 class="dataHeader rm-mt rm-mb pull-left">
          ID Checks:
          <span class="badge" countUp></span>
        </h1>
      </div>
      <br/>
      <div class="row clearfix">
        <div class="full width">
          <mat-card class="list-card">
            <mat-table class="list-table" #table matSort>
              <!--- Note that these columns can be defined in any order.
                                        The actual rendered columns are set as a property on the row definition" -->
              <!-- ID Column -->

              <!-- Progress Column -->
              <ng-container cdkColumnDef="InitialDate">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header>Initial Date</mat-header-cell>
                <mat-cell *cdkCellDef="let row"></mat-cell>
              </ng-container>
              <!-- Name Column -->
              <ng-container cdkColumnDef="FinalDate">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header>Final Date</mat-header-cell>
                <mat-cell *cdkCellDef="let row"></mat-cell>
              </ng-container>
              <!-- Name Column -->

              <!-- Color Column -->
              <ng-container cdkColumnDef="InitialResult">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header>Initial Result</mat-header-cell>
                <mat-cell *cdkCellDef="let row"></mat-cell>
              </ng-container>
              <!-- Color Column -->
              <ng-container cdkColumnDef="FinalResult">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header>Final Result</mat-header-cell>
                <mat-cell *cdkCellDef="let row"></mat-cell>
              </ng-container>
              <ng-container cdkColumnDef="Product">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header>Product</mat-header-cell>
                <mat-cell *cdkCellDef="let row"></mat-cell>
              </ng-container>
              <ng-container cdkColumnDef="ContractID">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header>Contract ID</mat-header-cell>
                <mat-cell *cdkCellDef="let row"></mat-cell>
              </ng-container>

              <!-- Action Button Column -->
              <ng-container cdkColumnDef="Actions">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header>Actions</mat-header-cell>
                <mat-cell *cdkCellDef="let row">
                  <div style="float: right">
                    <button [matMenuTriggerFor]="menu" mat-icon-button>
                      <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                      <button mat-menu-item>
                        <mat-icon>delete</mat-icon>
                        <span>Delete</span>
                      </button>
                    </mat-menu>
                  </div>
                </mat-cell>
              </ng-container>
              <mat-header-row *cdkHeaderRowDef="displayedColumnsIDC"></mat-header-row>
              <mat-row *cdkRowDef="let row; columns: displayedColumnsIDC"></mat-row>
            </mat-table>
          </mat-card>
        </div>
      </div>
    </div>
  </mat-tab>
  <mat-tab label="ID's">
    <div class="demo-tab-content">
      <div class="row clearfix">
        <h1 class="dataHeader rm-mt rm-mb pull-left">
          ID's:
          <span class="badge" countUp></span>
        </h1>
        <button class="btn-small pull-right" id="newMessage" mat-raised-button color="accent">
          <mat-icon>add</mat-icon>
          New ID
        </button>
      </div>
      <br/>

      <div class="row clearfix">
        <div class="full width">
          <mat-card class="list-card">
            <mat-table class="list-table" #tableß matSort>
              <!--- Note that these columns can be defined in any order.
                                            The actual rendered columns are set as a property on the row definition" -->
              <!-- ID Column -->

              <!-- Progress Column -->
              <ng-container cdkColumnDef="IDType">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header>ID Type</mat-header-cell>
                <mat-cell *cdkCellDef="let row"></mat-cell>
              </ng-container>
              <!-- Name Column -->
              <ng-container cdkColumnDef="Current">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header>Current</mat-header-cell>
                <mat-cell *cdkCellDef="let row"></mat-cell>
              </ng-container>
              <!-- Name Column -->

              <!-- Color Column -->
              <ng-container cdkColumnDef="Locked">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header>Locked</mat-header-cell>
                <mat-cell *cdkCellDef="let row"></mat-cell>
              </ng-container>
              <!-- Color Column -->
              <ng-container cdkColumnDef="IDNumber">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header>ID Number</mat-header-cell>
                <mat-cell *cdkCellDef="let row"></mat-cell>
              </ng-container>
              <ng-container cdkColumnDef="Issued">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header>Issued</mat-header-cell>
                <mat-cell *cdkCellDef="let row"></mat-cell>
              </ng-container>
              <ng-container cdkColumnDef="Expiry">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header>Expiry</mat-header-cell>
                <mat-cell *cdkCellDef="let row"></mat-cell>
              </ng-container>
              <ng-container cdkColumnDef="DMS">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header>DMS</mat-header-cell>
                <mat-cell *cdkCellDef="let row"></mat-cell>
              </ng-container>

              <!-- Action Button Column -->
              <ng-container cdkColumnDef="Actions">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header>Actions</mat-header-cell>
                <mat-cell *cdkCellDef="let row">
                  <div style="float: right">
                    <button [matMenuTriggerFor]="menu" mat-icon-button>
                      <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu"></mat-menu>
                  </div>
                </mat-cell>
              </ng-container>
              <mat-header-row *cdkHeaderRowDef="displayedColumnsID"></mat-header-row>
              <mat-row *cdkRowDef="let row; columns: displayedColumnsID"></mat-row>
            </mat-table>
          </mat-card>
        </div>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>
