import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SwipeCardsComponent } from '../../../shared/components/swipe-cards/swipe-cards.component';

@Component({
  selector: 'app-treatment-incusion-modal',
  templateUrl: './treatment-incusion-modal.component.html',
  styleUrls: ['./treatment-incusion-modal.component.css'],
})
export class TreatmentInclusionModalComponent implements OnInit {
  @Input()
  componentTitle = 'Would you like smiles with that?';

  @Output() closeModal = new EventEmitter();

  @ViewChild('swipeCard', { static: false }) swipeCard: SwipeCardsComponent;

  constructor() {}

  ngOnInit() {}

  closeModalEvent() {
    this.closeModal.emit(true);
  }
}
