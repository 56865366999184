import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { HeaderService } from '../../../shared/services/header.service';

@Component({
  selector: 'app-marketing-campaign-message-list-overview',
  templateUrl: './marketing-campaign-message-list-overview.component.html',
  styleUrls: ['./marketing-campaign-message-list-overview.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('*', style({ opacity: 0 }), { optional: true }),
        query(
          '*',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('*', style({ opacity: 1 }), { optional: true }),
        query(
          '*',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class MarketingCampaignMessageListOverviewComponent implements OnInit {
  @Input()
  campaignID;

  @Input()
  status;

  @Input()
  statusNative;

  isPromoterOrAdmin = false;

  merchantID = 'none';
  dateFrom;
  dateTo;

  isDisplayMerchant = true;

  sendNull = true;
  selectedRange = 'days90';

  searchApplied = false;

  constructor(private authenticationService: AuthenticationService, private activeRoute: ActivatedRoute) {}

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      if (params['campaignID']) {
        this.campaignID = params['campaignID'];
      }

      if (params['status']) {
        this.status = params['status'];
      }

      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isPromoterOrAdmin = res;

        if (this.isPromoterOrAdmin == true) {
          const _mID = JSON.parse(localStorage.getItem('selectedMerchant'));
          if (_mID) {
            this.merchantID = _mID;
            // this.isDisplayMerchant = false;
          }

          HeaderService.getPromoterViewAsObject().subscribe((res) => {
            if (res && res['promoterViewAs'] && res['promoterViewAs'] == 'promoter') {
              this.merchantID = 'none';
              this.isDisplayMerchant = true;
            } else if (res && res['promoterViewAs'] && res['promoterViewAs'] == 'merchant') {
              this.merchantID = res['merchantID'];
              this.isDisplayMerchant = false;
            }
          });
        }
      });
    });
  }

  applySearch(result) {
    this.merchantID = result.merchantID;
    this.dateFrom = result.dateFrom;
    this.dateTo = result.dateTo;

    this.searchApplied = true;
  }
}
