/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./simple-page-standalone.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i3 from "@angular/material/card";
import * as i4 from "@angular/platform-browser/animations";
import * as i5 from "@angular/common";
import * as i6 from "../../../shared/pipes/safe-html.pipe";
import * as i7 from "@angular/platform-browser";
import * as i8 from "./simple-page-standalone.component";
import * as i9 from "../shared/helper.service";
import * as i10 from "@angular/router";
var styles_SimplePageStandaloneComponent = [i0.styles];
var RenderType_SimplePageStandaloneComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SimplePageStandaloneComponent, data: {} });
export { RenderType_SimplePageStandaloneComponent as RenderType_SimplePageStandaloneComponent };
function View_SimplePageStandaloneComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "simple-page-conent"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(1, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 0, 0, _ck(_v, 1, 0, i1.ɵnov(_v.parent.parent, 0), _co.simplePage.content)); _ck(_v, 0, 0, currVal_0); }); }
function View_SimplePageStandaloneComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "container simple-page"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "mat-card", [["class", "simple text-center mat-card"]], [[2, "_mat-animation-noopable", null]], null, null, i2.View_MatCard_0, i2.RenderType_MatCard)), i1.ɵdid(2, 49152, null, 0, i3.MatCard, [[2, i4.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_SimplePageStandaloneComponent_2)), i1.ɵdid(4, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.simplePage && _co.simplePage.content); _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 2)._animationMode === "NoopAnimations"); _ck(_v, 1, 0, currVal_0); }); }
export function View_SimplePageStandaloneComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i6.SafeHtmlPipe, [i7.DomSanitizer]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SimplePageStandaloneComponent_1)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.simplePage; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_SimplePageStandaloneComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-simple-page-standalone", [], null, null, null, View_SimplePageStandaloneComponent_0, RenderType_SimplePageStandaloneComponent)), i1.ɵdid(1, 114688, null, 0, i8.SimplePageStandaloneComponent, [i9.HelperService, i10.Router, i10.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SimplePageStandaloneComponentNgFactory = i1.ɵccf("app-simple-page-standalone", i8.SimplePageStandaloneComponent, View_SimplePageStandaloneComponent_Host_0, {}, {}, []);
export { SimplePageStandaloneComponentNgFactory as SimplePageStandaloneComponentNgFactory };
