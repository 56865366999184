<div class="rel" *ngIf="listDB && listDB?.firstLoadEvent == false" @ngIfAnimation>
  <div class="row clearfix padded rel">
    <h1 class="dataHeader rm-mt transparent">Fetching data...</h1>
  </div>
</div>

<div [hidden]="!listDB || (listDB && !listDB.data) || (listDB && listDB.data && listDB.data.length == 0)">
  <div class="row clearfix">
    <h1 class="dataHeader rm-m pull-left">
      {{ title }}
      <span class="badge" [endVal]="dataSource?.filteredData?.length" countUp></span>
    </h1>

    <div class="pull-right selection-items" @ngIfAnimation>
      <button class="animated mr" (click)="selectAll()" mat-stroked-button color="" matTooltip="Select All">
        <mat-icon>border_all</mat-icon>
      </button>
      <button class="animated mr" (click)="selectPage()" mat-stroked-button color="" matTooltip="Select Page">
        <mat-icon>border_outer</mat-icon>
      </button>

      <button class="animated mr" *ngIf="selectedIDs && selectedIDs.length > 0" [matMenuTriggerFor]="ActionBtnmenu"
        mat-stroked-button matTooltip="Options">
        <mat-icon class="fas fa-cog">

        </mat-icon> <span *ngIf="selectedIDs && selectedIDs.length>0"
          class="selected-number-item  accent-background">{{selectedIDs.length}}</span>
      </button>
      <mat-menu #ActionBtnmenu="matMenu">
        <button class="row select-btn" (click)="unselect()" mat-menu-item color="">
          <mat-icon>select_all</mat-icon> Deselect
        </button>

        <hr />

        <button class="row select-btn" *ngIf="selectedIDs && selectedIDs.length > 0" (click)="removeGroup()"
          mat-menu-item color="">
          <mat-icon>delete</mat-icon>
          Remove
        </button>
      </mat-menu>
    </div>
  </div>

  <div class="row clearfix">
    <mat-card class="list-card">
      <div class="row clearfix mb searchArea primary-gradient-img">
        <div class="row clearfix flex">
          <div class="full-width flex animated fadeInDown">
            <mat-icon class="mt mr">search</mat-icon>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Filter by title,Label,Description...etc</mat-label>
              <input class="clearfix" (input)="setFilter($event.target.value, 'fullName')" name="tag" matInput />
            </mat-form-field>

            <mat-form-field class="half-width" appearance="outline">
              <mat-label>Source</mat-label>

              <mat-select class="margin-btm"
                (selectionChange)="setFilter($event.value, 'Source_Instant_Finance_Offer.Code')" name="Exipired"
                placeholder="Exipired">
                <mat-option [value]="null">
                  All
                </mat-option>
                <mat-option [value]="'Direct'"> Direct</mat-option>
                <mat-option [value]="'External'"> External</mat-option>
                <mat-option [value]="'SMS'"> SMS</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="white half-width" *ngIf="merchantTags.length > 0" appearance="outline">
              <mat-label>Tags</mat-label>
              <mat-select class="" (selectionChange)="setFilter($event.value, 'Tags')" name="Tag" placeholder="Tag"
                multiple>
                <mat-option *ngFor="let a of merchantTags | orderBy" [value]="a">
                  {{ a }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="white half-width" *ngIf="offerStatusLookup && offerStatusLookup.length > 0"
              appearance="outline">
              <mat-label>Status</mat-label>
              <mat-select class="" (selectionChange)="setFilter($event.value, 'Status.Code')" name="offerStatusLookup"
                placeholder="Status">
                <mat-option [value]="'Sent'"> Sent</mat-option>

                <mat-option *ngFor="let a of offerStatusLookup" [value]="a.Code">
                  {{ a.Label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>

      <!-- Table -->
      <mat-table class="list-table" #table [dataSource]="dataSource" [class.isStretched]="stretchList" matSort>
        <div>
          <ng-container cdkColumnDef="selectCheckBox">
            <mat-header-cell *cdkHeaderCellDef></mat-header-cell>
            <mat-cell *cdkCellDef="let row">
              <div class="margin-btm pull-left">
                <mat-checkbox [checked]="isSelected(row['ID'])" (change)="select(row['ID'], $event.checked)"
                  color="accent">
                </mat-checkbox>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="Theme">
            <mat-header-cell *cdkHeaderCellDef mat-sort-header> </mat-header-cell>
            <mat-cell *cdkCellDef="let row" (click)="view(row['ID'])">
              <instant-offer-header-image [headerImage]="row['Header_Image']" [merchantID]="row['Merchant_key']"
                [hideZoomButton]="true">
              </instant-offer-header-image>
              <div class="theme-container" *ngIf="row['Theme']"
                [attr.style]="'background:' + themeObject[row['Theme']] + ' !important' | safeStyle"></div>
            </mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="Picture">
            <mat-header-cell *cdkHeaderCellDef mat-sort-header> </mat-header-cell>
            <mat-cell *cdkCellDef="let row" (click)="view(row['ID'])">
              <app-customer-prospect-view-picture [patientID]="row['CustomerProspect_key']">
              </app-customer-prospect-view-picture>
            </mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="Patient" sticky>
            <mat-header-cell *cdkHeaderCellDef mat-sort-header> {{ "KEYWORD.patient" | translate | titlecase }}
            </mat-header-cell>
            <mat-cell *cdkCellDef="let row" (click)="view(row['ID'])"
              matTooltip="{{ row['CustomerProspect_CalculatedName'] }}">
              <h3>{{ row['CustomerProspect_CalculatedName'] }}</h3>
            </mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="treatmentAmount">
            <mat-header-cell *cdkHeaderCellDef mat-sort-header> Amount </mat-header-cell>
            <mat-cell *cdkCellDef="let row" (click)="view(row['ID'])">
              <h3>{{ row['TreatmentAmount'] | customCurrency }}</h3>
            </mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="Dentist">
            <mat-header-cell *cdkHeaderCellDef mat-sort-header>
              {{ "KEYWORD.practitioner" | translate | titlecase }}
            </mat-header-cell>
            <mat-cell *cdkCellDef="let row" (click)="view(row['ID'])">
              <span>{{ row['Contact_CalculatedName'] }}</span>
            </mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="Source">
            <mat-header-cell *cdkHeaderCellDef mat-sort-header> Source </mat-header-cell>
            <mat-cell *cdkCellDef="let row" (click)="view(row['ID'])">
              <span style="width: 65px">{{ row['Source_Instant_Finance_Offer.Label'] || 'SMS' }}</span>

              <mat-icon class="fas fa-mobile-alt product-group-ico-small" *ngIf="row.Send_SMS === '1'"
                [matTooltip]="('KEYWORD.patient' | translate | titlecase) + ' is notified by SMS'"></mat-icon>
              <mat-icon class="fas fa-envelope product-group-ico-small" *ngIf="row.Send_Email === '1'"
                [matTooltip]="('KEYWORD.patient' | translate | titlecase) + ' is notified by Email'"></mat-icon>
            </mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="Practice">
            <mat-header-cell *cdkHeaderCellDef mat-sort-header>{{ "KEYWORD.practice" | translate | titlecase}}
            </mat-header-cell>
            <mat-cell *cdkCellDef="let row" (click)="view(row['ID'])">
              <span>{{ row['Merchant_Tranding_As'] }}</span>
            </mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="offerStatus">
            <mat-header-cell *cdkHeaderCellDef mat-sort-header> Status </mat-header-cell>
            <mat-cell *cdkCellDef="let row" (click)="view(row['ID'])">
              <span class="list-label" *ngIf="
                  row['Status.Code'] != 'Sent' &&
                  row['Status.Code'] != 'Viewed' &&
                  row['Status.Code'] != 'Rejected' &&
                  row['Status.Code'] != 'NotProcd'
                " [style.background]="'rgb(90, 186, 71)'">
                {{ row['Status.Label'] }}
              </span>

              <span class="list-label" *ngIf="row['Status.Code'] == 'Sent'" [style.background]="'rgb(6, 193, 141)'">
                Sent
              </span>

              <span class="list-label" *ngIf="row['Status.Code'] == 'Viewed'" [style.background]="'rgb(251, 169, 25)'">
                {{ row['Status.Label'] }}
              </span>

              <span class="list-label" *ngIf="row['Status.Code'] == 'Rejected' || row['Status.Code'] == 'NotProcd'"
                [style.background]="'rgb(239, 71, 39)'">
                Not Proceeded
              </span>
            </mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="productGroup">
            <mat-header-cell *cdkHeaderCellDef mat-sort-header> Finance Type </mat-header-cell>
            <mat-cell *cdkCellDef="let row" (click)="view(row['ID'])">
              <mat-icon
                class=" fas {{ productUtil.getProductGroupIcon(row['Product_Group.Code']) }} product-group-ico-small mr">
              </mat-icon>
              <span>{{ productUtil.getLabel(row['Product_Group.Code']) }}</span>
            </mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="ExpireAt">
            <mat-header-cell *cdkHeaderCellDef mat-sort-header>Expire At</mat-header-cell>
            <mat-cell *cdkCellDef="let row" (click)="view(row['ID'])">
              <span class="list-label" *ngIf="row['Date.Expire'] == '0000-00-00' || row['Date.Expire'] == '2200-01-01'"
                [style.background]="'rgb(90, 186, 71)'">
                Never
              </span>
              <span class="list-label" *ngIf="row.isExpired == true" [style.background]="'rgb(239, 71, 39)'">
                Expired
              </span>

              <span class="list-label" *ngIf="
                  row.isExpired != true && row['Date.Expire'] != '0000-00-00' && row['Date.Expire'] != '2200-01-01'
                " [style.background]="'#818181'"
                matTooltip="Expires At {{ row['Date.Expire'] | customDate }}">{{ row['Date.Expire'] | timeLeft: null:'years,months,days,hours':true:true }}</span>
            </mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="invitationStatus">
            <mat-header-cell *cdkHeaderCellDef mat-sort-header> Invitation </mat-header-cell>
            <mat-cell *cdkCellDef="let row" (click)="view(row['ID'])" matTooltip="{{ row['Status.Invitation.Short'] }}">
              <span class="list-label" [style.background]="row['Status.Invitation.Colour'] || '#b4bcc1'">
                {{ row['Status.Invitation.Short'] || '...' }}
              </span>
            </mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="applicationStatus">
            <mat-header-cell *cdkHeaderCellDef mat-sort-header> Application</mat-header-cell>
            <mat-cell *cdkCellDef="let row" (click)="view(row['ID'])"
              matTooltip="{{ row['Status.Application.Short'] }}">
              <span class="list-label" [style.background]="row['Status.Application.Colour'] || '#b4bcc1'">
                {{ row['Status.Application.Short'] || '...' }}
              </span>
            </mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="Contract">
            <mat-header-cell *cdkHeaderCellDef mat-sort-header> Contract </mat-header-cell>
            <mat-cell *cdkCellDef="let row" (click)="view(row['ID'])" matTooltip="{{ row['Status.Contract.Short'] }}">
              <span class="list-label" [style.background]="row['Status.Contract.Colour'] || '#b4bcc1'">
                {{ row['Status.Contract.Short'] || '...' }}
              </span>
            </mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="dateViewed">
            <mat-header-cell *cdkHeaderCellDef mat-sort-header>Viewed At</mat-header-cell>
            <mat-cell *cdkCellDef="let row" (click)="view(row['ID'])">
              <span class="list-label" *ngIf="row['Date.Viewed'] != '0000-00-00'" [style.background]="'#5ABA47'"
                matTooltip="{{ row['Date.Viewed'] | customDateTime }}">
                {{ row['Date.Viewed'] | TimeLabelPast }}
                ago
              </span>

              <span class="list-label" *ngIf="row['Date.Viewed'] == '0000-00-00'" [style.background]="'#b4bcc1'">
                Not Yet
              </span>
            </mat-cell>
          </ng-container>
          <ng-container cdkColumnDef="Documents">
            <mat-header-cell *cdkHeaderCellDef mat-sort-header>Documents</mat-header-cell>
            <mat-cell *cdkCellDef="let row" (click)="view(row['ID'])">
              <span class="text-center" *ngIf="row['DocumentFKs']"></span> {{ row['DocumentFKs'].length }}
            </mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="Tags">
            <mat-header-cell *cdkHeaderCellDef mat-sort-header> Tags</mat-header-cell>
            <mat-cell *cdkCellDef="let row" (click)="view(row['ID'])">
              <div class="offer-tags" *ngIf="row && row['Tags']">
                <mat-chip-list>
                  <mat-chip *ngFor="let tag of row['Tags'] | orderBy" [ngClass]="{ 'default-chip': indexOfTag(tag) }">
                    {{ tag }}
                  </mat-chip>
                </mat-chip-list>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="Date">
            <mat-header-cell *cdkHeaderCellDef mat-sort-header> Date Created</mat-header-cell>
            <mat-cell *cdkCellDef="let row" (click)="view(row['ID'])"
              matTooltip="{{ row['DateTimeCreated'] | customDateTime }}">
              <span class="list-label" [style.background]="utils.daysPastColor(row['DateTimeCreated'])">
                {{ row['DateTimeCreated'] | TimeLabelPast }} ago
              </span>
            </mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="active">
            <mat-header-cell *cdkHeaderCellDef mat-sort-header> Active</mat-header-cell>
            <mat-cell *cdkCellDef="let row" (click)="view(row['ID'])">
              <span class="list-label smaller" *ngIf="row['Is_Active'] == '1'" matTooltip="Active"
                style="background-color: rgb(90, 186, 71)">
                <mat-icon>check</mat-icon>
              </span>
              <span class="list-label smaller" *ngIf="row['Is_Active'] != '1'" matTooltip="Inactive"
                style="background-color: rgb(239, 71, 39)">
                <mat-icon>close</mat-icon>
              </span>
            </mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="Actions" stickyEnd>
            <mat-header-cell *cdkHeaderCellDef mat-sort-header> </mat-header-cell>
            <mat-cell *cdkCellDef="let row">
              <div style="float: right">
                <button [matMenuTriggerFor]="menu" mat-icon-button>
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <button (click)="view(row['ID'])" mat-menu-item>
                    <mat-icon>visibility</mat-icon>
                    <span>Preview</span>
                  </button>

                  <hr />

                  <button *ngIf="isPromoterOrAdmin == true" (click)="openLandingPage(row)" mat-menu-item>
                    <mat-icon>description</mat-icon>
                    <span>View Landing Page</span>
                  </button>

                  <button *ngIf="isModulePatientSMSActive == true"
                    (click)="sendSMS(row['CustomerProspect_key'], row['Merchant_key'])" mat-menu-item>
                    <mat-icon class="fas fa-sms"></mat-icon>
                    <span>Send SMS</span>
                  </button>

                  <hr />

                  <button (click)="viewPatient(row['CustomerProspect_key'])" mat-menu-item>
                    <mat-icon class="fas fa-user"></mat-icon>
                    <span>View {{ "KEYWORD.patient" | translate | titlecase }}</span>
                  </button>

                  <button
                    *ngIf="row && row['Status.Code'] !== 'NotProcd' && row.CustomerProspect_key && row.Invitation_key"
                    (click)="viewInvitation(row['CustomerProspect_key'], row['Invitation_key'])" mat-menu-item>
                    <mat-icon class="fas fa-envelope"></mat-icon>
                    <span>View Invitation</span>
                  </button>

                  <button *ngIf="row['Contact_key']" (click)="viewDentist(row['Contact_key'])" mat-menu-item>
                    <mat-icon class="fas fa-user-circle"></mat-icon>
                    <span>View {{ "KEYWORD.practitioner" | translate | titlecase }}</span>
                  </button>

                  <button *ngIf="isPromoterOrAdmin == true" (click)="viewMerchant(row['Merchant_key'])" mat-menu-item>
                    <mat-icon class="fas fa-building"></mat-icon>
                    <span>View Merchant</span>
                  </button>

                  <hr />

                  <button (click)="removed(row['ID'])" mat-menu-item>
                    <mat-icon>remove_circle</mat-icon>
                    <span>Remove</span>
                  </button>
                </mat-menu>
              </div>
            </mat-cell>
          </ng-container>
          <mat-header-row *cdkHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
          <mat-row *cdkRowDef="let row; columns: displayedColumns"></mat-row>
        </div>
      </mat-table>
      <mat-paginator #paginator [length]="dataSource?.filteredData?.length" [pageIndex]="0" [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions">
      </mat-paginator>
    </mat-card>
  </div>
</div>
<div *ngIf="listDB?.data?.length == 0 && listDB?.firstLoadEvent != false">
  <app-empty-list-message [message]="'No record found to display'" [isAction]="false"></app-empty-list-message>
</div>
