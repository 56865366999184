import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { UtilsService } from '../../../shared/services/utils.service';
import { DataResponse } from '../../../shared/types/data-response.type';
import { SessionType } from '../../../shared/types/session-type.type';
import { Dentist } from './dentist.type';

@Injectable()
export class DentistService {
  private baseUrl = environment.nodeUrl;

  private dentist = new BehaviorSubject<Dentist>(null);
  readonly dentist$ = this.dentist.asObservable();

  constructor(private http: HttpClient, private utilsService: UtilsService) {}

  getContactDetails(ID: string, payload = {}, session: string) {
    const sessionType = session as SessionType;

    const params: HttpParams = this.utilsService.getHttpParamsFromPayload(payload);

    const options = { params };

    const isAdminOrPromoter = sessionType === 'admin' || sessionType === 'promoter';
    let endpoint = 'contact';

    if (sessionType === 'admin' || sessionType === 'promoter') {
      endpoint = 'contact/global';
    } else if (sessionType === 'merchant' || sessionType === 'merchant-admin') {
      endpoint = 'contact';
    } else {
      endpoint = 'contact/public';
    }

    const url = `${this.baseUrl}/${endpoint}/${ID}`;

    return this.http.get(url, options).pipe(map((response) => response['data']));
  }

  getOneContact(ID: string, payload = {}, session: string) {
    const sessionType = session as SessionType;

    const params: HttpParams = this.utilsService.getHttpParamsFromPayload(payload);

    const options = { params };
    let endpoint = 'contact';

    if (sessionType === 'admin' || sessionType === 'promoter') {
      endpoint = 'contact/global';
    } else if (sessionType === 'merchant' || sessionType === 'merchant-admin') {
      endpoint = 'contact';
    } else {
      endpoint = 'contact/public';
    }

    const url = `${this.baseUrl}/${endpoint}/${ID}`;

    return this.http.get(url, options).pipe(map((response) => response['data']));
  }
  getTradingHourList(payload = null, isPromoterOrAdmin = false) {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }
    }

    options = {
      params,
    };

    if (isPromoterOrAdmin == true) {
      return this.http
        .get(environment.nodeUrl + '/merchant-working-hours/global', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/merchant-working-hours')
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getFiltersContact(payload = {}, isPromoterOrAdmin = false) {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (isPromoterOrAdmin == false) {
      return this.http.get(environment.nodeUrl + '/contact/', options).pipe(
        map((res: HttpResponse<any>) => {
          return res['data'];
        })
      );
    } else {
      return this.http.get(environment.nodeUrl + '/contact/global', options).pipe(
        map((res: HttpResponse<any>) => {
          return res['data'];
        })
      );
    }
  }

  addCoordinate(ID, body) {
    return this.http
      .put(environment.nodeUrl + '/contact/coordinate/' + ID, body)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getProfilePic(ID, payload = {}) {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }
    return this.http
      .get(`${environment.nodeUrl}/contact/profile-picture/` + ID, options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getProfilePicStreamLink(ID) {
    if (ID) {
      return `${environment.nodeUrl}/contact/profile-picture-stream/` + ID;
    }
    return null;
  }

  createContact(payload, sessionType) {
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http
        .post(environment.nodeUrl + '/contact/global', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
      return this.http
        .post(environment.nodeUrl + '/contact', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'customer') {
      return this.http
        .post(environment.nodeUrl + '/contact/customer', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .post(environment.nodeUrl + '/contact', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  contactDentist(id, payload) {
    return this.http.post(`${environment.nodeUrl}/message/staff/${id}`, payload);
  }

  getList(body, isPromoterOrAdmin = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (let key in body) {
      if (body.hasOwnProperty(key)) {
        params = params.set(key, body[key]);
      }
    }

    options = {
      params,
    };
    if (isPromoterOrAdmin == true) {
      return this.http
        .get(environment.nodeUrl + '/contact/global', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/contact/', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  sendInviteForm(payload) {
    return this.http
      .post(`${environment.nodeUrl}/dentist-invite-create`, payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  modifyContact(ID, payload, session): Observable<Dentist> {
    const sessionType = session as SessionType;

    const endpoint = () => {
      const customerRoute = 'contact/customer';
      const standardRoute = 'contact';
      const globalRoute = 'contact/global';

      switch (sessionType) {
        case 'admin':
          return globalRoute;
        case 'promoter':
          return globalRoute;
        case 'merchant':
          return standardRoute;
        case 'merchant-admin':
          return standardRoute;
        case 'customer':
          return customerRoute;
        default:
          return standardRoute;
      }
    };

    const url = `${this.baseUrl}/${endpoint()}/${ID}`;

    return this.http.put(url, payload).pipe(map((res: HttpResponse<any>) => res['data']));
  }

  uploadProfilePic(ID, payload = {}) {
    return this.http
      .put(`${environment.nodeUrl}/contact/profile-picture/` + ID, payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getContactProfileDentistryTypesList(payload): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    return this.http
      .get(environment.nodeUrl + '/merchant/contact-profile-dentistry-types', options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  createContactProfileDentistryType(createContactDentistryType): Observable<any> {
    return this.http
      .post(environment.nodeUrl + '/merchant/contact-profile-dentistry-types', createContactDentistryType)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  deleteContactProfileDentistryType(id): Observable<any> {
    return this.http
      .delete<any>(environment.nodeUrl + `/merchant/contact-profile-dentistry-types/${id}`)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getDentist$() {
    return this.dentist$;
  }

  setDentist(dentist: Dentist) {
    this.dentist.next(dentist);
  }

  fetchDentist$(ID: string, sessionType: SessionType, payload = {}, existingDentist?: Dentist) {
    if (existingDentist) {
      return of(existingDentist);
    } else {
      const params: HttpParams = this.utilsService.getHttpParamsFromPayload(payload);

      const options = { params };

      const isAdminOrPromoter = sessionType === 'admin' || sessionType === 'promoter';
      const endpoint = isAdminOrPromoter ? 'contact/global' : 'contact';
      const url = `${this.baseUrl}/${endpoint}/${ID}`;

      return this.http.get<DataResponse<Dentist>>(url, options).pipe(map((response) => response['data']));
    }
  }

  updateDentist$(ID, payload, session) {
    const sessionType = session as SessionType;

    const endpoint = (() => {
      const customerRoute = 'contact/customer';
      const standardRoute = 'contact';
      const globalRoute = 'contact/global';

      switch (sessionType) {
        case 'admin':
          return globalRoute;
        case 'promoter':
          return globalRoute;
        case 'merchant':
          return standardRoute;
        case 'merchant-admin':
          return standardRoute;
        case 'customer':
          return customerRoute;
        default:
          return standardRoute;
      }
    })();

    const url = `${this.baseUrl}/${endpoint}/${ID}`;

    return this.http.put(url, payload).pipe(
      map((res: HttpResponse<any>) => res['data']),
      tap((dentist) => {
        this.dentist.next(dentist);
      })
    );
  }
}
