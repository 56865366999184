import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { debounceTime, map, skip, switchMap, tap } from 'rxjs/operators';
import { AppStateService } from '../../../../core/app-state/app-state.service';
import { User } from '../../../../core/authentication/shared/shared/types/authentication.type';
import { ConfirmDialogComponent } from '../../../../shared/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialog } from '../../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../../shared/types/notify-app-component';
import { NotesService } from '../../shared/services/notes.service';
import { NoteType, NoteTypeQuery } from '../../shared/type/notes.type';
import { NoteTypeFormConfig, NoteTypeFormModal } from './modals/note-type-form/note-type-form.modal';

@Component({
  selector: 'ipv-notes-settings',
  templateUrl: './notes-settings.page.html',
  styleUrls: ['./notes-settings.page.css'],
})
export class NotesSettingsPage implements OnInit, OnDestroy {
  viewModel$ = combineLatest([this.appStateService.getAppState$(), this.notesService.getNoteTypes$()]).pipe(
    map(([appState, noteTypes]) => {
      const { practice, sessionType, isPromoterOrAdmin, dentist } = appState;
      const user = appState.user.data as User;
      const result = {
        user,
        practice,
        sessionType,
        isPromoterOrAdmin,
        dentist,
        noteTypes,
      };

      return result;
    })
  );

  noteTypeFilterDebounceSubject = new BehaviorSubject<string>('');

  noteTypeFilter = '';

  constructor(
    private appStateService: AppStateService,
    private notesService: NotesService,
    private matDialog: MatDialog
  ) {}

  ngOnDestroy() {
    /** required for untilDestroyed (Replace with @UntilDestroyed() Decorator in angular 9 and delete ngOnDestroy()) */
  }

  ngOnInit() {
    this.noteTypeFilterDebounceSubject.pipe(untilDestroyed(this), skip(1), debounceTime(1300)).subscribe(() => {
      this.filterNoteTypes();
    });

    this.filterNoteTypes();
  }

  setNoteTypeFilter() {
    this.noteTypeFilterDebounceSubject.next(this.noteTypeFilter);
  }

  filterNoteTypes() {
    const query: NoteTypeQuery = {
      payload: {
        hasDefaultTypes: true,
        noteType: this.noteTypeFilter,
      },
    };

    return this.notesService
      .fetchNoteTypes$<NoteType>(query.payload)
      .pipe(
        untilDestroyed(this),
        tap((noteTypes) => {
          this.notesService.setNoteTypes(noteTypes);
        })
      )
      .subscribe();
  }

  createNoteType() {
    const noteTypeFormModalComponent = this.matDialog.open<NoteTypeFormModal, NoteTypeFormConfig>(NoteTypeFormModal, {
      data: { formMode: 'create' },
    });

    noteTypeFormModalComponent.componentInstance.created
      .pipe(
        untilDestroyed(this),
        switchMap((noteType) => {
          return this.notesService.createNoteType$(noteType);
        })
      )
      .subscribe((noteTypeWithId) => {
        const currentNoteTypes = this.notesService.getNoteTypes();
        const newNoteTypes = [noteTypeWithId, ...currentNoteTypes];
        this.notesService.setNoteTypes(newNoteTypes);

        noteTypeFormModalComponent.close();
        NotifyAppComponent.displayToast(
          'success',
          'Successful operation',
          'You have successfully created a new note type'
        );
      });
  }

  updateNoteType(noteType: NoteType) {
    const noteTypeFormModalComponent = this.matDialog.open<NoteTypeFormModal, NoteTypeFormConfig>(NoteTypeFormModal, {
      data: {
        formMode: 'update',
        noteType: {
          id: noteType.ID,
          noteType: noteType.ActionType,
          subjects: noteType['AllowedValues.Subject'].split(';'),
          results: noteType['AllowedValues.Result'].split(';'),
        },
      },
    });

    noteTypeFormModalComponent.componentInstance.updated
      .pipe(
        untilDestroyed(this),
        switchMap((noteTypeData) => {
          return this.notesService.updateNoteType$(noteType.ID, noteTypeData);
        })
      )
      .subscribe((noteTypeWithId) => {
        const currentNoteTypes = this.notesService.getNoteTypes();

        const itemIndex = currentNoteTypes.findIndex((item) => item.ID === noteTypeWithId.ID);
        currentNoteTypes[itemIndex] = noteTypeWithId;

        this.notesService.setNoteTypes(currentNoteTypes);

        noteTypeFormModalComponent.close();
        NotifyAppComponent.displayToast(
          'success',
          'Successful operation',
          'You have successfully updated the note type'
        );
      });
  }

  deleteNoteType(ID) {
    const confirmDialog = this.matDialog.open<ConfirmDialogComponent, ConfirmDialog>(ConfirmDialogComponent, {
      data: {
        icon: 'delete',
        title: 'Delete Note Type',
        confirmMessage: 'Are you sure to remove this note type?',
        noMessage: 'No',
        yesMessage: 'Yes',
      },
      width: '650px',
    });

    confirmDialog.componentInstance.onConfirm
      .pipe(
        untilDestroyed(this),
        switchMap((confirmed) => {
          if (confirmed) {
            return this.notesService.deleteNoteType$(ID);
          } else {
            confirmDialog.close();
            return of(null);
          }
        })
      )
      .subscribe((noteTypeID) => {
        if (noteTypeID) {
          const currentNoteTypes = this.notesService.getNoteTypes();
          const updatedNoteTypes = currentNoteTypes.filter((item) => ID !== item.ID);

          this.notesService.setNoteTypes(updatedNoteTypes);

          NotifyAppComponent.displayToast(
            'success',
            'Delete Note Type',
            'You have successfully deleted this note type'
          );
          confirmDialog.close();
        } else {
          confirmDialog.close();
        }
      });
  }

  toggleNoteType(noteType: NoteType) {
    const isEnabled = noteType['Disabled'] === '0';
    if (isEnabled) {
      this.disableNoteType(noteType);
    } else {
      this.enableNoteType(noteType);
    }
  }

  disableNoteType(noteType: NoteType) {
    const confirmDialog = this.matDialog.open<ConfirmDialogComponent, ConfirmDialog>(ConfirmDialogComponent, {
      data: {
        icon: 'warning',
        title: 'Disable Note Type',
        confirmMessage: `
          <p style="text-align: center; font-weight: bold; font-size: x-large"> Are you sure? </p>
          <p style="text-align: center">Disabling the <b> "${noteType.ActionType}" </b> note type will result in:</p>
          <ul>
            <li style="text-align: left"> No longer being able to create notes of this type </li>
            <li style="text-align: left"> No longer seeing notes of this type in the default list of notes </li>
            <li style="text-align: left"> Having to toggle a view all notes option to temporarily see notes of this type again </li>
          </ul>

        `,
        noMessage: 'No',
        yesMessage: 'Yes',
      },
      width: '650px',
    });

    confirmDialog.componentInstance.onConfirm
      .pipe(
        untilDestroyed(this),
        switchMap((confirmed) => {
          if (confirmed) {
            return this.notesService.disableNoteType$(noteType.ID);
          } else {
            confirmDialog.close();
            return of(null);
          }
        })
      )
      .subscribe((noteTypeWithId) => {
        if (noteTypeWithId) {
          const currentNoteTypes = this.notesService.getNoteTypes();

          const itemIndex = currentNoteTypes.findIndex((item) => item.ID === noteTypeWithId.ID);
          currentNoteTypes[itemIndex] = noteTypeWithId;

          this.notesService.setNoteTypes(currentNoteTypes);

          confirmDialog.close();
          NotifyAppComponent.displayToast('success', 'Successful operation', 'Note type disabled');
        } else {
          confirmDialog.close();
        }
      });
  }

  enableNoteType(noteType: NoteType) {
    this.notesService.enableNoteType$(noteType.ID).subscribe((noteTypeWithId) => {
      const currentNoteTypes = this.notesService.getNoteTypes();

      const itemIndex = currentNoteTypes.findIndex((item) => item.ID === noteTypeWithId.ID);
      currentNoteTypes[itemIndex] = noteTypeWithId;

      this.notesService.setNoteTypes(currentNoteTypes);

      NotifyAppComponent.displayToast('success', 'Successful operation', 'Note type enabled');
    });
  }

  getStringArray(text: string) {
    return text.split(';');
  }

  clearNoteTypes() {
    this.noteTypeFilter = '';
    this.filterNoteTypes();
  }
}
