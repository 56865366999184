import { Component, EventEmitter, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';

import * as _ from 'lodash';
import { AuthenticationService } from '../../authentication/shared/authentication.service';
import { SubscriptionPlanService } from '../shared/subscription-plan.service';

@Component({
  selector: 'app-subscription-plan-calculator',
  templateUrl: './subscription-plan-calculator.component.html',
  styleUrls: ['./subscription-plan-calculator.component.css'],
})
export class SubscriptionPlanCalculatorComponent implements OnInit {
  data = [
    {
      name: 'Your seeing on averarage',
      value: 260,
    },
    {
      name: 'You present an average',
      value: 87,
    },
    {
      name: 'Your Target',
      value: 43,
    },
  ];

  view: any[] = [200, 200];
  gradient = false;
  showLegend = false;
  showLabels = false;
  isDoughnut = true;
  legendPosition = 'below';

  patientColor = '#FA58B6';
  treatmentColor = '#B2F9FC';
  goalColor = '#8BDB81';
  targetColor = '#CB6CE6';
  colorScheme = {
    domain: [this.goalColor, 'none'],
  };

  isIDexist = false;

  selected;
  dent_num = 1;
  pat_num = 12;
  day_num = 5;
  plan_num = 5;
  pat = 0;
  treat = 0;
  your = 0;
  goal = 1;
  subscriptionPlanID;
  subscriptionPlan;
  subscriptionPlans;

  defaultYour = 0;
  selectedPlan;
  sessionType;
  close = new EventEmitter();

  constructor(
    private subscriptionPlanService: SubscriptionPlanService,
    private authenticationService: AuthenticationService,
    public route: ActivatedRoute,
    public router: Router,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public _data: any
  ) {
    if (_data) {
      if (_data.subscriptionPlanID) {
        this.subscriptionPlanID = _data.subscriptionPlanID;
      }
    }
  }

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((res) => {
      if (res) {
        this.sessionType = res;
      }

      this.setup();
    });
  }

  setup() {
    this.isIDexist = false;
    if (this.subscriptionPlanID) {
      this.subscriptionPlanService
        .getPromoterPlanDetails(
          this.subscriptionPlanID,
          { fields: 'ID,Price,Web_Label,Min_TreatmentPlans_Sent,Color' },
          this.sessionType
        )
        .subscribe((res) => {
          if (res) {
            if (res && res['Color']) {
              res.color = res['Color'].split('|')[0];
              res.colorSecond = res['Color'].split('|')[1];
            }

            this.subscriptionPlan = res;

            this.isIDexist = true;
            this.buildItem();
          }
        });
    } else {
      this.subscriptionPlanService
        .getPromoterPlanList(
          {
            fields: 'ID,Price,Web_Label,Min_TreatmentPlans_Sent,Color',
            orderBy: 'Web_Label',
          },
          this.sessionType
        )
        .subscribe((res) => {
          if (res) {
            for (let i = 0; i < res.length; i++) {
              if (res[i] && res[i]['Color']) {
                res[i].color = res[i]['Color'].split('|')[0];
                res[i].colorSecond = res[i]['Color'].split('|')[1];
              }
            }

            res = _.filter(res, (item) => {
              if (item && Number(item.Price) > 0) {
                return true;
              }

              return false;
            });

            if (res && res.length > 0) {
              this.subscriptionPlans = res;

              this.subscriptionPlan = res[0];
              this.subscriptionPlanID = res[0]['ID'];
              this.buildItem();
            }
          }
        });
    }
  }

  select() {
    if (this.subscriptionPlanID) {
      const p = _.filter(this.subscriptionPlans, (item) => {
        if (item && item.ID === this.subscriptionPlanID) {
          return true;
        }

        return false;
      });

      if (p && p[0] && p[0]['ID']) {
        this.subscriptionPlan = p[0];
      }

      if (this.subscriptionPlan && this.subscriptionPlan.ID) {
        this.your = Number(this.subscriptionPlan['Min_TreatmentPlans_Sent'] || 0);
      }

      this.defaultYour = Number(this.subscriptionPlan['Min_TreatmentPlans_Sent'] || 0);
    }

    this.changeData();
  }

  buildItem() {
    if (this.subscriptionPlan && this.subscriptionPlan.ID) {
      this.your = Number(this.subscriptionPlan['Min_TreatmentPlans_Sent'] || 0);

      this.defaultYour = Number(this.subscriptionPlan['Min_TreatmentPlans_Sent'] || 0);
    }

    this.changeData();
  }

  buildYour() {
    if (this.dent_num > 0) {
      this.your = Math.floor(Number(this.dent_num * this.defaultYour));
    }
  }

  onChangeDent(event: any) {
    this.dent_num = Number(event.target.value);
    this.changeData();
  }

  onInputDent(event: any) {
    this.dent_num = Number(event.value);
    this.changeData();
  }

  onChangeDay(event: any) {
    this.day_num = Number(event.target.value);
    this.changeData();
  }

  onInputDay(event: any) {
    this.day_num = Number(event.value);
    this.changeData();
  }

  onChangePat(event: any) {
    this.pat_num = Number(event.target.value);
    this.changeData();
  }

  onInputPat(event: any) {
    this.pat_num = Number(event.value);
    this.changeData();
  }

  onChangePlan(event: any) {
    this.plan_num = Number(event.target.value);
    this.changeData();
  }

  onInputPlan(event: any) {
    this.plan_num = Number(event.value);
    this.changeData();
  }

  changeData() {
    this.buildYour();
    this.pat = Math.floor((this.dent_num * this.day_num * this.pat_num * 52) / 12);
    this.treat = Math.floor((this.dent_num * this.day_num * this.plan_num * 52) / 12);
    this.goal = Math.floor(this.your - this.treat);
    if (this.goal < 0) {
      this.goal = 0;
    }
    this.data = [
      {
        name: 'You present an average',
        value: this.treat,
      },
      {
        name: 'Your Goal',
        value: this.goal,
      },
    ];
  }

  closeEvent() {
    this.close.emit(true);
  }

  calculPercent(v1, v2) {
    if (v2 === 0) {
      return '0%';
    } else if (v1 > v2) {
      return '100%';
    } else {
      const result = Number((Number(v1) / Number(v2)) * 100).toFixed(0);

      return result + '%';
    }
  }
}
