<div class="row clearfix" *ngIf="pcDetail && category">
  <div class="card-header primary-gradient-img clearfix" *ngIf="isModal == true">
    <div class="row clearfix">
      <!-- Modal dismiss button -->
      <button class="btn-close btn-clear pull-right mat-button" (click)="closeEvent()">
        <mat-icon>clear</mat-icon>
      </button>
      <!-- merchant calculated name -->
      <div class="row titleArea clearfix">
        <!-- <mat-icon class="fas fa-bullhorn pull-left"></mat-icon> -->
        <h2 class="message-title summary-header rm-m">{{ title }} Details</h2>
      </div>
    </div>
  </div>

  <!-- modifications fields -->

  <div class="row clearfix full-width">
    <div class="row clearfix flex">
      <div class="clearfix full-width">
        <div class="col-left">
          <h4 class="rm-mb">Operator modification</h4>
          <hr/>
          <div class="row clearfix full-width">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Reason</mat-label>
              <mat-select [(ngModel)]="pcDetail['Updating_Reason.Code']" placeholder="Reason" name="reason">
                <mat-option *ngFor="let reason of persCircumUpdReasons" [value]="reason.Code">
                  {{ reason.Label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="clearfix full-width">
        <div class="col-left">
          <h4 class="rm-mb">Comments</h4>
          <hr/>
          <div class="row clearfix full-width">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Leave a comment</mat-label>
              <textarea
                [(ngModel)]="pcDetail['Updating_Reason.Comment']"
                matInput
                placeholder="Comment"
                name="comment"
              ></textarea>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="clearfix full-width">
        <div class="col-left">
          <h4 class="rm-mb">Files</h4>
          <hr/>
          <div class="row clearfix full-width">
            <!-- <mat-icon class="fas fa-cloud-upload-alt"></mat-icon> -->

            <app-file-uploader></app-file-uploader>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Details for houseOfResidences -->
  <div class="row clearfix full-width" *ngIf="category == 'houseOfResidences'">
    <div class="row clearfix flex">
      <div class="clearfix full-width">
        <div class="col-left">
          <h4 class="rm-mb">Property Address</h4>
          <hr/>
          <div class="row clearfix">
            <app-address-input
              [displayMap]="false"
              [postCode]="myPostCode"
              [state]="myState"
              [suburb]="mySuburb"
              [streetNumber]="myStreetNumber"
              [streetName]="myStreetName"
              [unitNumber]="myUnitNumber"
              [streetType]="myStreetType"
              (getAdress)="getResidenceAddress($event)"
            ></app-address-input>
          </div>
        </div>
      </div>

      <div class="clearfix full-width">
        <div class="col-left">
          <h4 class="rm-mb">Loan Type</h4>
          <hr/>
          <div class="row clearfix full-width">
            <!-- <p>Moved In: <span class="value">{{pcDetail['Info.StartYear']}}</span> year <span
                class="value">{{convertMonth(pcDetail['Info.StartMonth'])}}</span>
              month</p> -->

            <div class="row clearfix flex">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Moved In - Year</mat-label>
                <input [value]="pcDetail['Info.StartYear']" matInput placeholder="Year"/>
              </mat-form-field>
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Moved In - Month</mat-label>
                <input [value]="convertMonth(pcDetail['Info.StartMonth'])" matInput placeholder="Year"/>
              </mat-form-field>
            </div>

            <!-- <div class="row clearfix flex">


            </div> -->

            <p class="mb">
              Time At This Address:
              <span *ngIf="pcDetail['Info.YearsAtAddress'] == '' && pcDetail['Info.MonthsAtAddress'] == ''">
                <span class="value">Till Now</span>
              </span>
              <span *ngIf="pcDetail['Info.YearsAtAddress'] != '' || pcDetail['Info.MonthsAtAddress'] != ''">
                <span class="value">{{ pcDetail['Info.YearsAtAddress'] }}</span> years
                <span class="value">{{ pcDetail['Info.MonthsAtAddress'] }}</span> months
              </span>
            </p>

            <!-- <p>Housing Status: <span class="value">{{pcDetail['Info.HousingMode.Label']}}</span></p> -->

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Housing Status</mat-label>
              <input [value]="pcDetail['Info.HousingMode.Label']" matInput placeholder="Housing Status"/>
            </mat-form-field>

            <div class="clearfix" *ngIf="pcDetail['Info.HousingMode.Code'] == 'RNT'">
              <!-- <p>Who Pays The Rent: <span class="value"></span></p> -->
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Who Pays The Rent</mat-label>
                <input matInput placeholder="Who Pays The Rent" value=""/>
              </mat-form-field>
            </div>

            <div class="clearfix" *ngIf="pcDetail['Info.HousingMode.Code'] != 'RNT'">
              <!-- <p>Who Owns The Property: <span class="value">{{pcDetail['Info.WhoOwnsIt.Label']}}</span></p>
              <p>Number Of Owners: <span class="value">{{pcDetail['Info.NumberOfOwners']}}</span></p>
              <p>Market Value: <span class="value">{{pcDetail['Info.MarketValue'] | customCurrency}}</span></p>
              <p>Equity In Property: <span class="value">{{pcDetail['Info.EquityAmount'] | customCurrency}}</span>
                <span class="value">{{pcDetail['Info.EquityPercent']}}%</span></p> -->

              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Who Owns The Property</mat-label>
                <input [value]="pcDetail['Info.WhoOwnsIt.Label']" matInput placeholder="Who Owns The Property"/>
              </mat-form-field>

              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Number Of Owners</mat-label>
                <input [value]="pcDetail['Info.NumberOfOwners']" matInput placeholder="Number Of Owners"/>
              </mat-form-field>

              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Market Value</mat-label>
                <input [(ngModel)]="pcDetail['Info.MarketValue']" matInput placeholder="Market Value" currencyMask/>
              </mat-form-field>

              <div class="row clearfix flex">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Equity In Property</mat-label>
                  <input
                    [(ngModel)]="pcDetail['Info.EquityAmount']"
                    matInput
                    placeholder="Equity In Property"
                    currencyMask
                  />
                </mat-form-field>

                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Equity percentage</mat-label>
                  <input [value]="pcDetail['Info.EquityPercent']" matInput placeholder="Equity percentage"/>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row clearfix flex" *ngIf="pcDetail['Info.HousingMode.Code'] != 'RNT'">
      <div class="clearfix full-width">
        <div class="col-left">
          <h4 class="rm-mb">Property Loan</h4>
          <hr/>
          <div class="row clearfix full-width">
            <!-- <p>Lender: <span class="value">{{pcDetail['Info.Bank.Name']}}</span></p>
            <p>Loan Type: <span class="value">{{pcDetail['Info.TypeOfLoan.Label'] || "Not specified"}}</span></p>
            <p>Facility Limit: <span class="value">{{pcDetail['Info.AmountInitiallyBorrowed'] | customCurrency}}</span>
            </p>
            <p>Amount Outstanding: <span class="value">{{pcDetail['Liability.Value'] | customCurrency}}</span></p> -->

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Lender</mat-label>
              <input matInput placeholder="Lender" value="pcDetail['Info.Bank.Name']"/>
            </mat-form-field>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Loan Type</mat-label>
              <input matInput placeholder="Loan Type" value="pcDetail['Info.TypeOfLoan.Label'] || 'Not specified'"/>
            </mat-form-field>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Facility Limit</mat-label>
              <input matInput placeholder="Facility Limit" value="pcDetail['Info.AmountInitiallyBorrowed']"/>
            </mat-form-field>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Amount Outstanding</mat-label>
              <input matInput placeholder="Amount Outstanding" value="pcDetail['Liability.Value']"/>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="clearfix full-width">
        <div class="col-left">
          <h4 class="rm-mb">Applicant's Loan Repayments</h4>
          <hr/>
          <div class="row clearfix full-width">
            <!-- <p>Amount: <span class="value">{{pcDetail['Expense.Value'] | customCurrency}}</span></p>
            <p>Frequency: <span class="value">{{convertFrequency(pcDetail['Expense.Frequency'])}}</span></p> -->

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Amount</mat-label>
              <input matInput placeholder="Amount" value="pcDetail['Expense.Value']"/>
            </mat-form-field>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Frequency</mat-label>
              <input matInput placeholder="Frequency" value="(convertFrequency(pcDetail['Expense.Frequency']))"/>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="clearfix full-width">
        <div class="col-left">
          <h4 class="rm-mb">Total Loan Repayments</h4>
          <hr/>
          <div class="row clearfix full-width">
            <!-- <p>Amount: <span class="value">{{pcDetail['Info.ExpenseTotal.Amount'] | customCurrency}}</span></p>
            <p>Frequency: <span class="value">{{convertFrequency(pcDetail['Info.ExpenseTotal.Frequency'])}}</span></p> -->

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Amount</mat-label>
              <input [(ngModel)]="pcDetail['Info.ExpenseTotal.Amount']" matInput placeholder="Amount" currencyMask/>
            </mat-form-field>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Frequency</mat-label>
              <input
                [value]="convertFrequency(pcDetail['Info.ExpenseTotal.Frequency'])"
                matInput
                placeholder="Frequency"
              />
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>

    <div class="row clearfix flex" *ngIf="pcDetail['Info.HousingMode.Code'] == 'RNT'">
      <div class="clearfix full-width">
        <div class="col-left">
          <h4 class="rm-mb">Rental Details</h4>
          <hr/>
          <div class="row clearfix full-width">
            <!-- <p>Agent/Landlord Name: <span class="value"></span></p>
            <p>Property Manager: <span class="value"></span></p>
            <p>Agent/Landlord Contact: <span class="value"></span></p> -->

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Agent/Landlord Name</mat-label>
              <input [value]="" matInput placeholder="Agent/Landlord Name"/>
            </mat-form-field>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Property Manager</mat-label>
              <input [value]="" matInput placeholder="Property Manager"/>
            </mat-form-field>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Agent/Landlord Contact</mat-label>
              <input [value]="" matInput placeholder="Agent/Landlord Contact"/>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="clearfix full-width">
        <div class="col-left">
          <h4 class="rm-mb">Applicant's Rent Payments</h4>
          <hr/>
          <div class="row clearfix full-width">
            <!-- <p>Amount: <span class="value">{{pcDetail['Expense.Value'] | customCurrency}}</span></p>
            <p>Frequency: <span class="value">{{convertFrequency(pcDetail['Expense.Frequency'])}}</span></p>
 -->

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Amount</mat-label>
              <input [(ngModel)]="pcDetail['Expense.Value']" matInput placeholder="Amount" currencyMask/>
            </mat-form-field>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Frequency</mat-label>
              <input [value]="convertFrequency(pcDetail['Expense.Frequency'])" matInput placeholder="Frequency"/>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="clearfix full-width">
        <div class="col-left">
          <h4 class="rm-mb">Total Rent Payments</h4>
          <hr/>
          <div class="row clearfix full-width">
            <!-- <p>Amount: <span class="value">{{pcDetail['Info.ExpenseTotal.Amount'] | customCurrency}}</span></p>
            <p>Frequency: <span class="value">{{convertFrequency(pcDetail['Info.ExpenseTotal.Frequency'])}}</span></p>
 -->

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Amount</mat-label>
              <input [(ngModel)]="pcDetail['Info.ExpenseTotal.Amount']" matInput placeholder="Amount" currencyMask/>
            </mat-form-field>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Frequency</mat-label>
              <input
                [value]="convertFrequency(pcDetail['Info.ExpenseTotal.Frequency'])"
                matInput
                placeholder="Frequency"
              />
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Details for employmentAndBenefits -->
  <div class="row clearfix full-width" *ngIf="category == 'employmentAndBenefits'">
    <!-- normal employment / job -->

    <div class="row clearfix" *ngIf="pcDetail['TypeCode'] == 'EMP'">
      <div class="row clearfix flex">
        <div class="clearfix full-width">
          <div class="col-left">
            <h4 class="rm-mb">Employment Details</h4>
            <hr/>
            <div class="row clearfix full-width">
              <!-- <p>Employment Status: <span class="value">{{pcDetail['Type']}}</span></p>
              <p>Employment Type: <span class="value">{{pcDetail['Info.Employment.Type.Label']}}</span></p>
              <p>Role / Occupation: <span class="value">{{pcDetail['Info.Employment.Role']}}</span></p>
              <p>Start Date: <span class="value">{{pcDetail['Info.Employment.Start.Year']}}</span>year <span
                  class="value">{{pcDetail['Info.Employment.Start.Month']}}</span>
                month</p>
              <p>Time In This Role: <span class="value">{{pcDetail['Info.Employment.TimeInRole.Years']}}</span>
                years <span class="value">{{pcDetail['Info.Employment.TimeInRole.Months']}}</span> months</p> -->
              <!-- <p>Are You On Probation:
                <span *ngIf="pcDetail['Info.Employment.OnProbation']=='1'" class="value">YES</span>
                <span *ngIf="pcDetail['Info.Employment.OnProbation']=='0'" class="value">NO</span>
              </p> -->

              <div class="row clearfix">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Employment Status</mat-label>
                  <mat-select [(ngModel)]="pcDetail['TypeCode']" name="employmentStatus" disabled>
                    <mat-option *ngFor="let status of employmentStatus" [value]="status.Code">
                      {{ status.Label }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Employment Type</mat-label>
                  <input [(ngModel)]="pcDetail['Info.Employment.Type.Label']" matInput placeholder="Employment Type"/>
                </mat-form-field>

                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Role / Occupation</mat-label>
                  <input [(ngModel)]="pcDetail['Info.Employment.Role']" matInput placeholder="Role / Occupation"/>
                </mat-form-field>
              </div>
              <div class="row clearfix flex">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Start year</mat-label>
                  <input [(ngModel)]="pcDetail['Info.Employment.Start.Year']" matInput placeholder="Start year"/>
                </mat-form-field>
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Start month</mat-label>
                  <input [(ngModel)]="pcDetail['Info.Employment.Start.Month']" matInput placeholder="Start month"/>
                </mat-form-field>
              </div>

              <div class="row clearfix flex">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Years in role</mat-label>
                  <input [(ngModel)]="pcDetail['Info.Employment.TimeInRole.Years']" matInput placeholder="Years"/>
                </mat-form-field>
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Months in role</mat-label>
                  <input [(ngModel)]="pcDetail['Info.Employment.TimeInRole.Months']" matInput placeholder="Month"/>
                </mat-form-field>
              </div>

              <!-- <p>Are You On Probation:
                <span *ngIf="pcDetail['Info.Employment.OnProbation']=='1'" class="value">YES</span>
                <span *ngIf="pcDetail['Info.Employment.OnProbation']=='0'" class="value">NO</span>
              </p> -->

              <mat-form-field appearance="outline">
                <mat-label>Are You On Probation</mat-label>
                <mat-select [(ngModel)]="pcDetail['Info.Employment.OnProbation']">
                  <mat-option value="0"> No</mat-option>
                  <mat-option value="1"> Yes</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="clearfix full-width">
          <div class="col-left">
            <h4 class="rm-mb">Employer Details</h4>
            <hr/>
            <div class="row clearfix full-width">
              <!-- <p>Name: <span class="value">{{pcDetail['Info.Employment.Employer.Name']}}</span></p>
              <p>Contact - Name: <span class="value">{{pcDetail['Info.Employment.Employer.ContactName']}}</span></p>
              <p>Contact - Number: <span class="value">{{pcDetail['Info.Employment.Employer.Number']}}</span></p>
              <p>Contact - Email: <span class="value">{{pcDetail['Info.Employment.Employer.Email']}}</span></p> -->

              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Name</mat-label>
                <input [(ngModel)]="pcDetail['Info.Employment.Employer.Name']" matInput placeholder="Name"/>
              </mat-form-field>
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Contact - Name</mat-label>
                <input
                  [(ngModel)]="pcDetail['Info.Employment.Employer.ContactName']"
                  matInput
                  placeholder="Contact - Name"
                />
              </mat-form-field>
              <!-- <mat-form-field class="full-width" appearance="outline">
                <mat-label>Contact - Number</mat-label>
                <input matInput placeholder="Contact - Number"
                  [(ngModel)]="pcDetail['Info.Employment.Employer.Number']">
              </mat-form-field> -->
              <app-phone-input
                class="full-width"
                [phoneNumber]="pcDetail['Info.Employment.Employer.Number']"
                [isLandLine]="false"
                [label]="'Contact - Number'"
                [required]="false"
                (validate)="validateEmployerPhoneEvent($event)"
                (getPhone)="getEmployerPhone($event)"
              >
              </app-phone-input>
              <!-- <mat-form-field class="full-width" appearance="outline">
                <mat-label>Contact - Email</mat-label>
                <input matInput placeholder="Contact - Email" [(ngModel)]="pcDetail['Info.Employment.Employer.Email']"> -->

              <app-email-input
                class="full-width"
                [email]="pcDetail['Info.Employment.Employer.Email']"
                [label]="'Contact - Email'"
                [required]="false"
                (getEmail)="getEmployerEmail($event)"
                (validate)="validateEmployerEmailEvent($event)"
              >
              </app-email-input>

              <!-- </mat-form-field> -->
            </div>
          </div>
        </div>
      </div>

      <div class="row clearfix flex mt">
        <div class="clearfix full-width">
          <div class="col-left">
            <h4 class="rm-mb">Employer Address</h4>
            <hr/>
            <div class="row clearfix">
              <app-address-input
                [isRequired]="false"
                [displayMap]="false"
                [postCode]="myPostCode"
                [state]="myState"
                [suburb]="mySuburb"
                [streetNumber]="myStreetNumber"
                [streetName]="myStreetName"
                [unitNumber]="myUnitNumber"
                [streetType]="myStreetType"
                (getAdress)="getEmployerAddress($event)"
                (validate)="isEmployerAdressValidEvent($event)"
              ></app-address-input>
            </div>
          </div>
        </div>

        <div class="clearfix full-width">
          <div class="col-left">
            <h4 class="rm-mb">Payment Details</h4>
            <hr/>
            <div class="row clearfix full-width">
              <!-- <p>Payment Amount: <span class="value">{{pcDetail['Income.Value'] | customCurrency}}</span></p>
              <p>Payment Period: <span class="value">{{convertFrequency(pcDetail['Income.Frequency'])}}</span></p>
              <p>Hours Per Week: <span class="value">{{pcDetail['Info.Employment.Hours']}}</span></p>
              <p>Next Payment Date: <span class="value">{{pcDetail['Info.Employment.NextPayDate']}}</span></p> -->

              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Payment Amount</mat-label>
                <input [(ngModel)]="pcDetail['Income.Value']" matInput placeholder="Payment Amount" currencyMask/>
              </mat-form-field>
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Payment Period</mat-label>
                <input [(ngModel)]="pcDetail['Income.Frequency']" matInput placeholder="Payment Period"/>
              </mat-form-field>
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Hours Per Week</mat-label>
                <input [(ngModel)]="pcDetail['Info.Employment.Hours']" matInput placeholder="Hours Per Week"/>
              </mat-form-field>
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Next Payment Date</mat-label>
                <input [(ngModel)]="pcDetail['Info.Employment.NextPayDate']" matInput placeholder="Next Payment Date"/>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row clearfix flex" *ngIf="pcDetail['TypeCode'] != 'EMP'">
      <div class="clearfix full-width">
        <div class="col-left">
          <h4 class="rm-mb">Employment Details</h4>
          <hr/>
          <div class="row clearfix full-width">
            <!-- <p>Employment Status: <span class="value">{{pcDetail['Type']}}</span></p> -->

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Employment Status</mat-label>
              <input [(ngModel)]="pcDetail['Type']" matInput placeholder="Employment Status"/>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="clearfix full-width">
        <div class="col-left">
          <h4 class="rm-mb">Benefit Details</h4>
          <hr/>
          <div class="row clearfix full-width">
            <!-- <p>Benefit Type: <span class="value">{{pcDetail['Info.Employment.BenefitType']}}</span></p>
            <p>Payment Amount: <span class="value">{{pcDetail['Income.Value'] | customCurrency}}</span></p>
            <p>Payment Period: <span class="value">{{convertFrequency(pcDetail['Income.Frequency'])}}</span></p>
            <p>Next Payment Date: <span class="value">{{pcDetail['Info.Employment.NextPayDate']}}</span></p>
 -->

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Benefit Type</mat-label>
              <input [(ngModel)]="pcDetail['Info.Employment.BenefitType']" matInput placeholder="Benefit Type"/>
            </mat-form-field>
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Payment Amount</mat-label>
              <input [(ngModel)]="pcDetail['Income.Value']" matInput placeholder="Payment Amount" currencyMask/>
            </mat-form-field>
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Payment Period</mat-label>
              <input [(ngModel)]="pcDetail['Income.Frequency']" matInput placeholder="Payment Period"/>
            </mat-form-field>
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Next Payment Date</mat-label>
              <input [(ngModel)]="pcDetail['Info.Employment.NextPayDate']" matInput placeholder="Next Payment Date"/>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="clearfix full-width">
        <div class="col-left">
          <h4 class="rm-mb">Times</h4>
          <hr/>
          <div class="row clearfix full-width">
            <!-- <p>Start Date: <span class="value">{{pcDetail['Info.Employment.Start.Year']}}</span> year <span
                class="value">{{convertMonth(pcDetail['Info.Employment.Start.Month'])}}</span> month</p>
            <p>Duration: <span class="value">{{pcDetail['Info.Employment.TimeInRole.Years']}}</span> years
              <span class="value">{{pcDetail['Info.Employment.TimeInRole.Months']}}</span> months</p> -->

            <div class="row clearfix flex">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Start year</mat-label>
                <input [(ngModel)]="pcDetail['Info.Employment.Start.Year']" matInput placeholder="Start year"/>
              </mat-form-field>
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Start month</mat-label>
                <input [(ngModel)]="pcDetail['Info.Employment.Start.Month']" matInput placeholder="Start month"/>
              </mat-form-field>
            </div>

            <div class="row clearfix flex">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Duration - Years</mat-label>
                <input [(ngModel)]="pcDetail['Info.Employment.TimeInRole.Years']" matInput placeholder="Years"/>
              </mat-form-field>
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Duration - Months</mat-label>
                <input [(ngModel)]="pcDetail['Info.Employment.TimeInRole.Months']" matInput placeholder="Month"/>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Details for interestAsIncomes -->
  <div class="row clearfix full-width" *ngIf="category == 'interestAsIncomes'">
    <div class="row clearfix flex">
      <div class="clearfix full-width">
        <div class="col-left">
          <h4 class="rm-mb">Account Details</h4>
          <hr/>
          <div class="row clearfix full-width">
            <!-- <p>Account Name: <span class="value">{{pcDetail['Info.AccountName']}}</span></p>
            <p>Account Type: <span class="value">{{pcDetail['Info.AccountType.Label']}}</span></p>
            <p>Lender: <span class="value">{{pcDetail['Info.Bank.Name']}}</span></p>
            <p>Market Value: <span class="value">{{pcDetail['Asset.Value'] | customCurrency}}</span></p> -->

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Account Name</mat-label>
              <input [value]="pcDetail['Info.AccountName']" matInput placeholder="Account Name"/>
            </mat-form-field>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Account Type</mat-label>
              <input [value]="pcDetail['Info.AccountType.Label']" matInput placeholder="Account Type"/>
            </mat-form-field>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Lender</mat-label>
              <input [value]="pcDetail['Info.Bank.Name']" matInput placeholder="Lender"/>
            </mat-form-field>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Market Value</mat-label>
              <input [(ngModel)]="pcDetail['Asset.Value']" matInput placeholder="Market Value" currencyMask/>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="clearfix full-width">
        <div class="col-left">
          <h4 class="rm-mb">Income</h4>
          <hr/>
          <div class="row clearfix full-width">
            <!-- <p>Amount: <span class="value">{{pcDetail['Income.Value'] | customCurrency}}</span></p>
            <p>Frequency: <span class="value">{{convertFrequency(pcDetail['Income.Frequency'])}}</span></p> -->

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Amount</mat-label>
              <input [(ngModel)]="pcDetail['Income.Value']" matInput placeholder="Amount" currencyMask/>
            </mat-form-field>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Frequency</mat-label>
              <input [value]="convertFrequency(pcDetail['Income.Frequency'])" matInput placeholder="Frequency"/>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Details for furnitureHouseholdGoods -->
  <div class="row clearfix full-width" *ngIf="category == 'furnitureHouseholdGoods'">
    <div class="row clearfix flex">
      <div class="clearfix full-width">
        <div class="col-left">
          <h4 class="rm-mb">Furniture And Household Goods</h4>
          <hr/>
          <div class="row clearfix full-width">
            <!-- <p>Value Of Applicant's Furniture And Household Goods: <span class="value">{{pcDetail['Asset.Value']
                                | customCurrency}}</span></p> -->

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Value Of Applicant's Furniture And Household Goods</mat-label>
              <input [(ngModel)]="pcDetail['Asset.Value']" matInput placeholder="Value" currencyMask/>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="clearfix full-width">
        <div class="col-left">
          <h4 class="rm-mb">&nbsp;</h4>
          <hr/>
          <div class="row clearfix full-width">
            <!-- <p>Description (optional): <span class="value">{{pcDetail['Info.Description']}}</span></p> -->

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Description (optional)</mat-label>
              <input [value]="pcDetail['Info.Description']" matInput placeholder="Description"/>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Details for shares -->
  <div class="row clearfix full-width" *ngIf="category == 'shares'">
    <div class="row clearfix flex">
      <div class="clearfix full-width">
        <div class="col-left">
          <h4 class="rm-mb">Share Details</h4>
          <hr/>
          <div class="row clearfix full-width">
            <!-- <p>Market Value: <span class="value">{{pcDetail['Asset.Value'] | customCurrency}}</span></p> -->

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Market Value</mat-label>
              <input [(ngModel)]="pcDetail['Asset.Value']" matInput placeholder="Market Value" currencyMask/>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="clearfix full-width">
        <div class="col-left">
          <h4 class="rm-mb">Dividends</h4>
          <hr/>
          <div class="row clearfix full-width">
            <!-- <p>Income Amount: <span class="value">{{pcDetail['Income.Value'] | customCurrency}}</span></p>
            <p>Income Frequency: <span class="value">{{convertFrequency(pcDetail['Income.Frequency'])}}</span></p> -->

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Income Amount</mat-label>
              <input [(ngModel)]="pcDetail['Income.Value']" matInput placeholder="Income Amount" currencyMask/>
            </mat-form-field>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Income Frequency</mat-label>
              <input [value]="convertFrequency(pcDetail['Income.Frequency'])" matInput placeholder="Income Frequency"/>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="clearfix full-width">
        <div class="col-left">
          <h4 class="rm-mb">Loan Details</h4>
          <hr/>
          <div class="row clearfix full-width">
            <!-- <p>Are These Shares Owned Outright?
              <span class="value" *ngIf="pcDetail['Info.Owned']=='1'">Yes</span>
              <span class="value" *ngIf="pcDetail['Info.Owned']=='0'">No</span>
            </p> -->

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Are These Shares Owned Outright?</mat-label>
              <mat-select [(ngModel)]="pcDetail['Info.Owned']">
                <mat-option value="0"> No</mat-option>
                <mat-option value="1"> Yes</mat-option>
              </mat-select>
            </mat-form-field>

            <!-- <p>Lender: <span class="value">{{pcDetail['Info.Bank.Name']}}</span></p>
            <p>Loan Type: <span class="value">{{pcDetail['Info.TypeOfLoan.Label']}}</span></p>
            <p>Original Loan Amount: <span
                class="value">{{pcDetail['Info.AmountInitiallyBorrowed'] | customCurrency}}</span></p>
            <p>Current Debt: <span class="value">{{pcDetail['Liability.Value'] | customCurrency}}</span></p>
            <p>Repayment Amount: <span class="value">{{pcDetail['Info.RepaymentsTotal.Amount'] | customCurrency}}</span>
            </p>
            <p>Repayment Frequency: <span
                class="value">{{convertFrequency(pcDetail['Info.RepaymentsTotal.Frequency'])}}</span></p> -->

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Lender</mat-label>
              <input [value]="pcDetail['Info.Bank.Name']" matInput placeholder="Lender"/>
            </mat-form-field>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Loan Type</mat-label>
              <input [value]="pcDetail['Info.TypeOfLoan.Label']" matInput placeholder="Loan Type"/>
            </mat-form-field>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Original Loan Amount</mat-label>
              <input
                [(ngModel)]="pcDetail['Info.AmountInitiallyBorrowed']"
                matInput
                placeholder="Original Loan Amount"
                currencyMask
              />
            </mat-form-field>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Current Debt</mat-label>
              <input [(ngModel)]="pcDetail['Liability.Value']" matInput placeholder="Current Debt" currencyMask/>
            </mat-form-field>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Repayment Amount</mat-label>
              <input
                [(ngModel)]="pcDetail['Info.RepaymentsTotal.Amount']"
                matInput
                placeholder="Repayment Amount"
                currencyMask
              />
            </mat-form-field>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Repayment Frequency</mat-label>
              <input
                [value]="convertFrequency(pcDetail['Info.RepaymentsTotal.Frequency'])"
                matInput
                placeholder="Repayment Frequency"
              />
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Details for livingExpenses -->
  <div class="row clearfix full-width" *ngIf="category == 'livingExpenses'">
    <div class="row clearfix flex">
      <!-- <div class="clearfix full-width">
        <div class="col-left">
          <h4 class="rm-mb">Expenses Details</h4>
          <hr>
          <div class="row clearfix full-width">
            <div *ngFor="let type of livingExpensesType" class="row clearfix well">
              <label>{{type['Label']}}</label>
              <p>Frequency: <span class="value">{{convertFrequency(type['Frequency'])}}</span></p>
              <p>Amount: <span class="value">{{type['Amount'] | customCurrency}}</span></p>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>

  <!-- Details for creditCards -->
  <div class="row clearfix full-width" *ngIf="category == 'creditCards'">
    <div class="row clearfix flex">
      <div class="clearfix full-width">
        <div class="col-left">
          <h4 class="rm-mb">Loan Details</h4>
          <hr/>
          <div class="row clearfix full-width">
            <!-- <p>Card Type: <span class="value">{{pcDetail['Info.CardType']}}</span></p>
            <p>Issuer: <span class="value">{{pcDetail['Info.Bank.Name']}}</span></p>
            <p>Facility Limit: <span class="value">{{pcDetail['Info.FacilityLimit'] | customCurrency}}</span></p>
            <p>Amount Outstanding: <span class="value">{{pcDetail['Liability.Value'] | customCurrency}}</span></p> -->

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Card Type</mat-label>
              <input [value]="pcDetail['Info.CardType']" matInput placeholder="Card Type"/>
            </mat-form-field>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Issuer</mat-label>
              <input [value]="pcDetail['Info.Bank.Name']" matInput placeholder="Issuer"/>
            </mat-form-field>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Facility Limit</mat-label>
              <input [(ngModel)]="pcDetail['Info.FacilityLimit']" matInput placeholder="Facility Limit" currencyMask/>
            </mat-form-field>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Amount Outstanding</mat-label>
              <input [(ngModel)]="pcDetail['Liability.Value']" matInput placeholder="Amount Outstanding" currencyMask/>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="clearfix full-width">
        <div class="col-left">
          <h4 class="rm-mb">Declared Repayments</h4>
          <hr/>
          <div class="row clearfix full-width">
            <!-- <p>Amount: <span class="value">{{pcDetail['Info.DeclaredRepayments.Value'] | customCurrency}}</span></p>
            <p>Frequency: <span class="value">{{convertFrequency(pcDetail['Info.DeclaredRepayments.Frequency'])}}</span>
            </p> -->

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Amount</mat-label>
              <input
                [(ngModel)]="pcDetail['Info.DeclaredRepayments.Value']"
                matInput
                placeholder="Amount"
                currencyMask
              />
            </mat-form-field>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Frequency</mat-label>
              <input
                [value]="convertFrequency(pcDetail['Info.DeclaredRepayments.Frequency'])"
                matInput
                placeholder="Frequency"
              />
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <div class="row clearfix flex">
      <div class="clearfix full-width">
        <div class="col-left">
          <h4 class="rm-mb">Deemed Repayments</h4>
          <hr/>
          <div class="row clearfix full-width">
            <!-- <p>Repayments: <span class="value">Repayments</span></p>
            <p>Frequency: <span class="value">{{convertFrequency(pcDetail['Info.DemedRepayments.Frequency'])}}</span>


            </p> -->

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Repayments</mat-label>
              <input [value]="'MISSING'" matInput placeholder="Repayments"/>
            </mat-form-field>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Frequency</mat-label>
              <input
                [value]="convertFrequency(pcDetail['Info.DemedRepayments.Frequency'])"
                matInput
                placeholder="Frequency"
              />
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="clearfix full-width">
        <div class="col-left">
          <h4 class="rm-mb">Repayments Used For Assesment</h4>
          <hr/>
          <div class="row clearfix full-width">
            <!-- <p>Repayments: <span class="value">MISSING</span></p>
            <p>Based On: <span class="value">{{pcDetail['Info.AssessedOn']}}</span></p> -->

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Repayments</mat-label>
              <input [value]="'MISSING'" matInput placeholder="Repayments"/>
            </mat-form-field>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Based On</mat-label>
              <input [value]="pcDetail['Info.AssessedOn']" matInput placeholder="Based On"/>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Details for cars -->
  <div class="row clearfix full-width" *ngIf="category == 'cars'">
    <div class="row clearfix flex">
      <div class="clearfix full-width">
        <div class="col-left">
          <h4 class="rm-mb">Vehicle Details</h4>
          <hr/>
          <div class="row clearfix full-width">
            <!-- <p>Car Detail: <span class="value">{{pcDetail['Info.Make']}}</span></p>
            <p>Value: <span class="value">{{pcDetail['Asset.Value'] | customCurrency}}</span></p> -->

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Car Detail</mat-label>
              <input [value]="pcDetail['Info.Make']" matInput placeholder="Car Detail"/>
            </mat-form-field>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Value</mat-label>
              <input [(ngModel)]="pcDetail['Asset.Value']" matInput placeholder="Value" currencyMask/>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="clearfix full-width">
        <div class="col-left">
          <h4 class="rm-mb">Expenses</h4>
          <hr/>
          <div class="row clearfix full-width">
            <!-- <p>Amount: <span class="value">{{pcDetail['Expense.Value'] | customCurrency}}</span></p>
            <p>Frequency: <span class="value">{{convertFrequency(pcDetail['Expense.Frequency'])}}</span></p> -->

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Amount</mat-label>
              <input [(ngModel)]="pcDetail['Expense.Value']" matInput placeholder="Amount" currencyMask/>
            </mat-form-field>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Frequency</mat-label>
              <input [value]="convertFrequency(pcDetail['Expense.Frequency'])" matInput placeholder="Frequency"/>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="clearfix full-width">
        <div class="col-left">
          <h4 class="rm-mb">Financing</h4>
          <hr/>
          <div class="row clearfix full-width">
            <!-- <p>Is This Vehicle Owned Outright?
              <span class="value" *ngIf="pcDetail['Info.Own']=='1'">YES</span>
              <span class="value" *ngIf="pcDetail['Info.Own']=='0'">NO</span>
            </p> -->

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Is This Vehicle Owned Outright?</mat-label>
              <mat-select [(ngModel)]="pcDetail['Info.Own']">
                <mat-option value="0"> No</mat-option>
                <mat-option value="1"> Yes</mat-option>
              </mat-select>
            </mat-form-field>

            <!-- <p>Lender: <span class="value">{{pcDetail['Info.Bank.Name']}}</span></p>
            <p>Amount Borrowed: <span class="value">{{pcDetail['Info.AmountInitiallyBorrowed'] | customCurrency}}</span>
            </p>
            <p>Amount Outstanding: <span class="value">{{pcDetail['Liability.Value'] | customCurrency}}</span></p> -->

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Lender</mat-label>
              <input [value]="pcDetail['Info.Bank.Name']" matInput placeholder="Lender"/>
            </mat-form-field>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Amount Borrowed</mat-label>
              <input
                [(ngModel)]="pcDetail['Info.AmountInitiallyBorrowed']"
                matInput
                placeholder="Amount Borrowed"
                currencyMask
              />
            </mat-form-field>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Amount Outstanding</mat-label>
              <input [(ngModel)]="pcDetail['Liability.Value']" matInput placeholder="Amount Outstanding" currencyMask/>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Details for investmentAndLoans -->
  <div class="row clearfix full-width" *ngIf="category == 'investmentAndLoans'"></div>

  <!-- Details for otherAssets -->
  <div class="row clearfix full-width" *ngIf="category == 'otherAssets'"></div>

  <!-- Details for otherIncomes -->
  <div class="row clearfix full-width" *ngIf="category == 'otherIncomes'">
    <div class="row clearfix flex">
      <div class="clearfix full-width">
        <div class="col-left">
          <h4 class="rm-mb">Details</h4>
          <hr/>
          <div class="row clearfix full-width">
            <!-- <p>Income Source: <span class="value">{{pcDetail['Info.Type']}}</span></p> -->

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Income Source</mat-label>
              <input [value]="pcDetail['Info.Type']" matInput placeholder="Income Source"/>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="clearfix full-width">
        <div class="col-left">
          <h4 class="rm-mb">Income</h4>
          <hr/>
          <div class="row clearfix full-width">
            <!-- <p>Amount: <span class="value">{{pcDetail['Income.Value'] | customCurrency}}</span></p>
            <p>Frequency: <span class="value">{{convertFrequency(pcDetail['Income.Frequency'])}}</span></p> -->

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Amount</mat-label>
              <input [(ngModel)]="pcDetail['Income.Value']" matInput placeholder="Amount" currencyMask/>
            </mat-form-field>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Frequency</mat-label>
              <input [value]="convertFrequency(pcDetail['Income.Frequency'])" matInput placeholder="Frequency"/>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Details for investmentPropertys -->
  <div class="row clearfix full-width" *ngIf="category == 'investmentPropertys'">
    <div class="row clearfix flex">
      <div class="clearfix full-width">
        <div class="col-left">
          <h4 class="rm-mb">Property Address</h4>
          <hr/>
          <div class="row clearfix">
            <app-address-input
              [displayMap]="false"
              [postCode]="myPostCode"
              [state]="myState"
              [suburb]="mySuburb"
              [streetNumber]="myStreetNumber"
              [streetName]="myStreetName"
              [unitNumber]="myUnitNumber"
              [streetType]="myStreetType"
              (getAdress)="getinvestmentPropertyAddress($event)"
            ></app-address-input>
          </div>
        </div>
      </div>

      <div class="clearfix full-width">
        <div class="col-left">
          <h4 class="rm-mb">Property Ownership</h4>
          <hr/>
          <div class="row clearfix full-width">
            <!-- <p>Who Owns The Property: <span class="value">{{pcDetail['Info.WhoOwns']}}</span></p>
            <p>Market Value: <span class="value">{{pcDetail['Info.MarketValue'] | customCurrency}}</span></p>
            <p>Equity In Property: <span class="value">{{pcDetail['Info.EquityPercent']}}% {{pcDetail['Asset.Value']
                                | customCurrency}}</span></p> -->

            <div class="row clearfix">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Who Owns The Property</mat-label>
                <input [value]="pcDetail['Info.WhoOwns']" matInput placeholder="Who Owns The Property"/>
              </mat-form-field>

              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Market Value</mat-label>
                <input [(ngModel)]="pcDetail['Info.MarketValue']" matInput placeholder="Market Value" currencyMask/>
              </mat-form-field>
            </div>
            <div class="row clearfix flex">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Equity In Property (%)</mat-label>
                <input [value]="pcDetail['Info.EquityPercent']" matInput placeholder="Equity In Property"/>
              </mat-form-field>

              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Equity In Property ($)</mat-label>
                <input [(ngModel)]="pcDetail['Asset.Value']" matInput placeholder="Equity In Property"/>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row clearfix flex">
      <div class="clearfix full-width">
        <div class="col-left">
          <h4 class="rm-mb">Rental Income</h4>
          <hr/>
          <div class="row clearfix full-width">
            <!-- <p>Applicant: <span class="value">{{pcDetail['Info.Income.Actual'] | customCurrency}}</span></p>
          <p>Deemed: <span class="value">{{pcDetail['Info.Income.Deemed'] | customCurrency}}</span></p>
          <p>Total: <span class="value">{{pcDetail['Info.IncomeTotal.Amount'] | customCurrency}}</span></p>
          <p>Frequency: <span class="value">{{convertFrequency(pcDetail['Info.IncomeTotal.Frequency'])}}</span></p> -->

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Applicant</mat-label>
              <input [(ngModel)]="pcDetail['Info.Income.Actual']" matInput placeholder="Applicant" currencyMark/>
            </mat-form-field>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Deemed</mat-label>
              <input [(ngModel)]="pcDetail['Info.Income.Deemed']" matInput placeholder="Deemed" currencyMark/>
            </mat-form-field>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Total</mat-label>
              <input [(ngModel)]="pcDetail['Info.IncomeTotal.Amount']" matInput placeholder="Total" currencyMark/>
            </mat-form-field>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Frequency</mat-label>
              <input
                [value]="convertFrequency(pcDetail['Info.IncomeTotal.Frequency'])"
                matInput
                placeholder="Frequency"
              />
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="clearfix full-width">
        <div class="col-left">
          <h4 class="rm-mb">Rental Expenses</h4>
          <hr/>
          <div class="row clearfix full-width">
            <!-- <p>Applicant: <span class="value">{{pcDetail['Expense.Value'] | customCurrency}}</span></p>
          <p>Total: <span class="value">{{pcDetail['Expense.Value'] | customCurrency}}</span></p>
          <p>Frequency: <span class="value">{{convertFrequency(pcDetail['Expense.Frequency'])}}</span></p> -->

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Applicant</mat-label>
              <input [(ngModel)]="pcDetail['Expense.Value']" matInput placeholder="Applicant" currencyMark/>
            </mat-form-field>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Total</mat-label>
              <input [(ngModel)]="pcDetail['Expense.Value']" matInput placeholder="Total" currencyMark/>
            </mat-form-field>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Frequency</mat-label>
              <input [value]="convertFrequency(pcDetail['Expense.Frequency'])" matInput placeholder="Frequency"/>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="clearfix full-width">
        <div class="col-left">
          <h4 class="rm-mb">Property Loan</h4>
          <hr/>
          <div class="row clearfix full-width">
            <!-- <p>Is This Property Owned Outright?
            <span class="value" *ngIf="pcDetail['Info.Owned']=='1'">Yes</span>
            <span class="value" *ngIf="pcDetail['Info.Owned']=='0'">No</span></p> -->
            <!-- <p>Lender: <span class="value">{{pcDetail['Info.Bank.Name']}}</span></p>
          <p>Loan Type: <span class="value">{{pcDetail['Info.TypeOfLoan.Label']}}</span></p>
          <p>Original Loan Amount: <span
              class="value">{{pcDetail['Info.AmountInitiallyBorrowed'] | customCurrency}}</span></p>
          <p>Current Debt: <span class="value">{{pcDetail['Liability.Value'] | customCurrency}}</span></p> -->
            <mat-form-field appearance="outline">
              <mat-label>Is This Property Owned Outright?</mat-label>
              <mat-select [(ngModel)]="pcDetail['Info.Owned']">
                <mat-option value="0"> No</mat-option>
                <mat-option value="1"> Yes</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Lender</mat-label>
              <input [(ngModel)]="pcDetail['Info.Bank.Name']" matInput placeholder="Lender"/>
            </mat-form-field>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Loan Type</mat-label>
              <input [(ngModel)]="pcDetail['Info.TypeOfLoan.Label']" matInput placeholder="Loan Type"/>
            </mat-form-field>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Original Loan Amount</mat-label>
              <input
                [(ngModel)]="pcDetail['Info.AmountInitiallyBorrowed']"
                matInput
                placeholder="Original Loan Amount"
                currencyMask
              />
            </mat-form-field>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Current Debt</mat-label>
              <input [value]="pcDetail['Liability.Value']" matInput placeholder="Current Debt" currencyMask/>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Details for otherVehicles -->
  <div class="row clearfix full-width" *ngIf="category == 'otherVehicles'">
    <div class="row clearfix flex">
      <div class="clearfix full-width">
        <div class="col-left">
          <h4 class="rm-mb">Item Details</h4>
          <hr/>
          <div class="row clearfix full-width">
            <!-- <p>Item Detail: <span class="value">{{pcDetail['Info.Make']}}</span></p> -->
            <!-- <p>Value: <span class="value">{{pcDetail['Asset.Value'] | customCurrency}}</span></p> -->

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Item Detail</mat-label>
              <input [(ngModel)]="pcDetail['Info.Make']" matInput placeholder="Item Detail"/>
            </mat-form-field>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Value</mat-label>
              <input [(ngModel)]="pcDetail['Asset.Value']" matInput placeholder="Value" currencyMask/>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="clearfix full-width">
        <div class="col-left">
          <h4 class="rm-mb">Expenses</h4>
          <hr/>
          <div class="row clearfix full-width">
            <!-- <p>Amount: <span class="value">{{pcDetail['Expense.Value'] | customCurrency}}</span></p>
          <p>Frequency: <span class="value">{{convertFrequency(pcDetail['Expense.Frequency'])}}</span></p> -->

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Amount</mat-label>
              <input [(ngModel)]="pcDetail['Expense.Value']" matInput placeholder="Amount" currencyMask/>
            </mat-form-field>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Frequency</mat-label>
              <input [value]="convertFrequency(pcDetail['Expense.Frequency'])" matInput placeholder="Frequency"/>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="clearfix full-width">
        <div class="col-left">
          <h4 class="rm-mb">Financing</h4>
          <hr/>
          <div class="row clearfix full-width">
            <!-- <p>Is This Vehicle Owned Outright?
            <span class="value" *ngIf="pcDetail['Info.Own']=='1'">YES</span>
            <span class="value" *ngIf="pcDetail['Info.Own']=='0'">NO</span>
          </p>
          <p>Lender: <span class="value">{{pcDetail['Info.Bank.Name']}}</span></p>
          <p>Amount Borrowed: <span class="value">{{pcDetail['Info.AmountInitiallyBorrowed'] | customCurrency}}</span>
          </p>
          <p>Amount Outstanding: <span class="value">{{pcDetail['Liability.Value'] | customCurrency}}</span></p> -->

            <mat-form-field appearance="outline">
              <mat-label>Is This Vehicle Owned Outright?</mat-label>
              <mat-select [(ngModel)]="pcDetail['Info.Own']">
                <mat-option value="0"> No</mat-option>
                <mat-option value="1"> Yes</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Lender</mat-label>
              <input [(ngModel)]="pcDetail['Info.Bank.Name']" matInput placeholder="Lender"/>
            </mat-form-field>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Amount Borrowed</mat-label>
              <input
                [(ngModel)]="pcDetail['Info.AmountInitiallyBorrowed']"
                matInput
                placeholder="Amount Borrowed"
                currencyMask
              />
            </mat-form-field>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Amount Outstanding</mat-label>
              <input [(ngModel)]="pcDetail['Liability.Value']" matInput placeholder="Amount Outstanding" currencyMask/>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Details for personalLoans -->
  <div class="row clearfix full-width" *ngIf="category == 'personalLoans'">
    <div class="row clearfix flex">
      <div class="clearfix full-width">
        <div class="col-left">
          <h4 class="rm-mb">Loan Details</h4>
          <hr/>
          <div class="row clearfix full-width">
            <!-- <p>Lender: <span class="value">{{pcDetail['Info.Bank.Name']}}</span></p>
          <p>Loan Type: <span class="value">{{pcDetail['Info.LoanType']}}</span></p>
          <p>Original Loan Amount: <span
              class="value">{{pcDetail['Info.AmountInitiallyBorrowed'] | customCurrency}}</span></p>
          <p>Amount Outstanding: <span class="value">{{pcDetail['Liability.Value'] | customCurrency}}</span></p> -->

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Lender</mat-label>
              <input [(ngModel)]="pcDetail['Info.Bank.Name']" matInput placeholder="Lender"/>
            </mat-form-field>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Loan Type</mat-label>
              <input [(ngModel)]="pcDetail['Info.LoanType']" matInput placeholder="Loan Type"/>
            </mat-form-field>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Original Loan Amount</mat-label>
              <input
                [(ngModel)]="pcDetail['Info.AmountInitiallyBorrowed']"
                matInput
                placeholder="Original Loan Amount"
                currencyMask
              />
            </mat-form-field>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Amount Outstanding</mat-label>
              <input [(ngModel)]="pcDetail['Liability.Value']" matInput placeholder="Amount Outstanding" currencyMask/>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="clearfix full-width">
        <div class="col-left">
          <h4 class="rm-mb">Repayments</h4>
          <hr/>
          <div class="row clearfix full-width">
            <!-- <p>Amount: <span class="value">{{pcDetail['Expense.Value'] | customCurrency}}</span></p>
          <p>Frequency: <span class="value">{{convertFrequency(pcDetail['Expense.Frequency'])}}</span></p> -->

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Amount</mat-label>
              <input [(ngModel)]="pcDetail['Expense.Value']" matInput placeholder="Amount" currencyMask/>
            </mat-form-field>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Frequency</mat-label>
              <input [value]="convertFrequency(pcDetail['Expense.Frequency'])" matInput placeholder="Frequency"/>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Details for taxAndDebts -->
  <div class="row clearfix full-width" *ngIf="category == 'taxAndDebts'">
    <div class="row clearfix flex">
      <div class="clearfix full-width">
        <div class="col-left">
          <h4 class="rm-mb">Loan Details</h4>
          <hr/>
          <div class="row clearfix full-width">
            <!-- <p>Debt Type: <span class="value">{{pcDetail['Info.SubType.Label']}}</span></p>
          <p>Amount Outstanding: <span class="value">{{pcDetail['Liability.Value'] | customCurrency}}</span></p> -->

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Debt Type</mat-label>
              <input [(ngModel)]="pcDetail['Info.SubType.Label']" matInput placeholder="Debt Type"/>
            </mat-form-field>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Amount Outstanding</mat-label>
              <input [(ngModel)]="pcDetail['Liability.Value']" matInput placeholder="Amount Outstanding" currencyMask/>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="clearfix full-width">
        <div class="col-left">
          <h4 class="rm-mb">Declared Repayments</h4>
          <hr/>
          <div class="row clearfix full-width">
            <!-- <p>Amount: <span class="value">{{pcDetail['Expense.Value'] | customCurrency}}</span></p>
          <p>Frequency: <span class="value">{{convertFrequency(pcDetail['Expense.Frequency'])}}</span></p>
 -->

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Amount</mat-label>
              <input [(ngModel)]="pcDetail['Expense.Value']" matInput placeholder="Amount"/>
            </mat-form-field>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Frequency</mat-label>
              <input [value]="convertFrequency(pcDetail['Expense.Frequency'])" matInput placeholder="Frequency"/>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row clearfix mt">
    <button class="float-right" (click)="submit()" mat-raised-button color="accent">Submit</button>
  </div>
</div>
