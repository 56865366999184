<mat-card class="full-width clearfix preview" style="padding-bottom: 0">
  <div class="card-header primary-gradient-img row clearfix inModal">
    <div class="row clearfix">
      <button class="btn-close btn-clear white pull-right mat-button" (click)="closeEvent()" matDialogClose>
        <mat-icon>clear</mat-icon>
      </button>

      <button
        class="btn-close btn-clear mr ml white pull-right mat-button"
        *ngIf="isFullScreenModal != true"
        (click)="fullScreenModal()"
      >
        <mat-icon class="fas fa-expand"></mat-icon>
      </button>

      <button
        class="btn-close btn-clear mr ml white pull-right mat-button"
        *ngIf="isFullScreenModal == true"
        (click)="fullScreenModal()"
      >
        <mat-icon class="fas fa-compress"></mat-icon>
      </button>
      <div class="row titleArea clearfix">
        <h2 class="summary-header rm-m">Preview image</h2>
      </div>
    </div>
  </div>
  <div class="row clearfix">
    <mat-dialog-content class="clearfix preview-image-container">
      <div class="full-width">
        <div
          class="full-width min-height clearfix ep-view-img-simple"
          *ngIf="isError == true && isLoaded == false && _defaultImage"
        >
          <pinch-zoom
            class="full-height pinchZoom pinchZoom-img-simple min-height"
            [limit-zoom]="5"
            [wheel]="true"
            [wheelZoomFactor]="0.2"
            [double-tap]="true"
            [properties]="pinchZoomProperties"
          >
            <img [src]="defaultImage" />
          </pinch-zoom>
        </div>

        <div class="image-loading" *ngIf="isLoaded == false && isError == false">
          <div class="spinner-inner">
            <div class="double-bounce1"></div>
            <div class="double-bounce2"></div>
            <!-- <div class="double-bounce3"></div> -->
          </div>
        </div>

        <div
          class="full-width min-height clearfix ep-view-img-simple"
          *ngIf="image"
          [hidden]="isError == true || isLoaded == false"
        >
          <pinch-zoom
            class="full-height pinchZoom pinchZoom-img-simple min-height"
            [limit-zoom]="5"
            [wheel]="true"
            [wheelZoomFactor]="0.2"
            [double-tap]="true"
            [properties]="pinchZoomProperties"
          >
            <img [src]="image" (error)="onError()" (load)="onLoad()" />
          </pinch-zoom>
        </div>

        <div
          class="full-width min-height clearfix ep-view-img-simple"
          *ngIf="!image || (isError == true && isLoaded == false && !_defaultImage)"
        >
          <pinch-zoom
            class="full-height pinchZoom pinchZoom-img-simple min-height"
            [limit-zoom]="5"
            [wheel]="true"
            [wheelZoomFactor]="0.2"
            [double-tap]="true"
            [properties]="pinchZoomProperties"
          >
            <img
              [src]="
                'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/notFoundImage.png'
              "
            />
          </pinch-zoom>
        </div>
      </div>
    </mat-dialog-content>
  </div>
</mat-card>
