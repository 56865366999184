import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';

import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'environments/environment';
import { ClipboardService } from 'ngx-clipboard';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { AuthenticationService } from '../../authentication/shared/authentication.service';
import { EconnectService } from '../shared/econnect.service';

@Component({
  selector: 'app-econnect-field-view',
  templateUrl: './econnect-field-view.component.html',
  styleUrls: ['./econnect-field-view.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.stacked-card-view', style({ opacity: 0 }), { optional: true }),
        query(
          '.stacked-card-view',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({ opacity: 0, offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.stacked-card-view', style({ opacity: 1 }), { optional: true }),
        query(
          '.stacked-card-view',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({ opacity: 1, offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class EconnectFieldViewComponent implements OnInit {
  @Input()
  fieldID;

  @Output()
  close = new EventEmitter();
  isPromoterOrAdmin = false;
  field;

  constructor(
    private authenticationServer: AuthenticationService,
    private activeRoute: ActivatedRoute,
    private _clipboardService: ClipboardService,
    private econnectService: EconnectService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.fieldID) {
        this.fieldID = data.fieldID;
      }

      if (data.field) {
        this.field = data.field;
      }
    }
  }

  ngOnInit() {
    this.authenticationServer.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;

      this.activeRoute.params.subscribe((params) => {
        if (params['fieldID']) {
          this.fieldID = params['fieldID'];
        }

        this.setup();
      });
    });
  }

  setup() {
    if (this.fieldID) {
      this.econnectService.getFieldDetails(this.fieldID).subscribe((res) => {
        if (res) {
          this.field = res;
        }
      });
    }
  }

  getType(r) {
    if (r && r.Type) {
      const a = r.Type;

      if (r.LookupInfo) {
        return 'Lookup';
      } else if (a == 'B') {
        return 'Boolean';
      } else if (a == 'T') {
        return 'String';
      } else if (a == 'D') {
        return 'Date';
      } else if (a == 'R') {
        return 'Number';
      } else if (a == 'L') {
        return 'String';
      } else if (a == 'JA') {
        return 'Json Array';
      } else {
        return 'String';
      }
    }
  }

  closeModal() {
    this.close.emit(true);
  }

  copyLookup(l) {
    if (l) {
      const p = l.split(':');

      if (p && p.length > 1 && p[1]) {
        this._clipboardService.copy(p[1]);
        NotifyAppComponent.displayToast('success', 'copied', '');
      }
    }
  }

  openLookup(l) {
    if (l) {
      const p = l.split(':');

      if (p && p.length > 1 && p[1]) {
        if (p[0] === 'C') {
          window.open(`${environment.nodeUrl}/codeLookup?category=${p[1]}`, '_blank');
        } else if (p[0] === 'C') {
          window.open(`${environment.nodeUrl}/codeLookup?category=${p[1]}`, '_blank');
        }
      }
    }
  }
}
