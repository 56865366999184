import { animate, state, style, transition, trigger } from '@angular/animations';
import { Location } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnInit, Optional, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { WizardComponent } from 'angular-archwizard';
import { forkJoin, of } from 'rxjs';
import { Observable } from 'rxjs-compat';
import { mergeMap } from 'rxjs/operators';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { FileService } from '../../../shared/services/file.service';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { CustomerProspectService } from '../../customer-prospect/shared/customerProspect.service';
import { InvitationCustomerProspectEditComponent } from '../../invitation/invitation-customer-prospect-edit/invitation-customer-prospect-edit.component';
import { InvitationLookupComponent } from '../../invitation/invitation-lookup/invitation-lookup.component';
import { MerchantViewComponent } from '../../merchant/merchant-view/merchant-view.component';
import { MerchantService } from '../../merchant/shared/merchant.service';
import { SmsConversationComponent } from '../../message/sms-conversation/sms-conversation.component';
import { SmsPromoterComponent } from '../../message/sms-promoter/sms-promoter.component';
import { TagsService } from '../../tags/shared/tags.service';
import { DentalXrService } from '../shared/services/dental-xr.service';
import { DentalXrayScanDetectionItem, DentalXrayScanResult } from '../shared/types/dental-xray.type';

type XrayOptionType = 'bitewings' | 'panoramic' | null;

@Component({
  selector: 'app-ai-scan-create',
  templateUrl: './ai-scan-create.component.html',
  styleUrls: ['./ai-scan-create.component.css'],
  animations: [
    trigger('simpleFadeAnimation', [
      state('in', style({ opacity: 1, height: '*' })),
      transition(':enter', [style({ opacity: 0, height: 0 }), animate(300)]),
      transition(':leave', animate(300, style({ opacity: 0, height: 0 }))),
    ]),
  ],
})
export class AiScanCreateComponent implements OnInit {
  wizard: WizardComponent;
  start = 1;

  util = new UtilsClass();

  sessionType = 'merchant';

  isAdminOrPromoter = false;

  @ViewChild('wizard', { static: false }) set wizardContent(content: WizardComponent) {
    if (content) {
      this.wizard = content;
    }
  }

  @Input() title = 'Submit an xray scan for a patient';

  @Input() patientID;

  @Input() merchantID;

  @Input() displayLookup = true;

  lookupPage = true;
  merchants = [];
  genders = [];
  titles = [];

  isFinDMatch = false;

  clearDate = new EventEmitter();

  merchant;
  selectedMerchant;

  patient = {
    firstName: null,
    tableName: null,
    title: null,
    lastName: null,
    middleName: null,
    genderCode: null,
    mobile: null,
    homePhone: null,
    workPhone: null,
    email: null,
    dateOfBirth: null,
    _dateOfBirth: null,
  };

  clearMobile = new EventEmitter();
  isMobileValid = false;

  clearHomePhone = new EventEmitter();
  isHomePhoneValid = false;

  clearWorkPhone = new EventEmitter();
  isWorkPhoneValid = false;

  clearEmail = new EventEmitter();
  isEmailValid = false;

  isAiScanReportReady = false;

  dentalXrayScanResult: DentalXrayScanResult = {
    id: null,
    uploadType: null,
    calculatedName: null,
    patientID: null,
    merchantID: null,
    operatorID: null,
    imageIDPanoramic: null,
    imageIDLeft: null,
    imageIDRight: null,
    dateCreated: null,
    tags: '',
    apicalDetections: [],
    cariesDetections: [],
    restorationsDetections: [],
  };

  isDisabled = true;

  xrayOptionTypes: XrayOptionType[] = ['bitewings', 'panoramic'];
  selectedXrayOptionType: XrayOptionType = null;

  dentalXrayImageIds: {
    id: string;
    xraySide: 'panoramic' | 'left' | 'right';
  }[] = [];

  currentFileDescriptionPanoramic = 'A panoramic dental xray image';
  currentFileDescriptionLeft = 'A left bitewing dental xray image';
  currentFileDescriptionRight = 'A right bitewing dental xray image';

  currentFileNamePanoramic = '';
  currentFileNameLeft = '';
  currentFileNameRight = '';

  constructor(
    private merchantService: MerchantService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private customerProspectService: CustomerProspectService,
    private authenticationService: AuthenticationService,
    private location: Location,
    private dentalXrService: DentalXrService,
    private tagsService: TagsService,
    private fileService: FileService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {}

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      if (params['patientID']) {
        this.patientID = params['patientID'];

        this.lookupPage = false;
      }

      this.authenticationService.getCurrentPractice().subscribe((currentPractice) => {
        if (currentPractice) {
          this.selectedMerchant = currentPractice;
          if (this.selectedMerchant && this.selectedMerchant.ID) {
            this.merchantID = this.selectedMerchant.ID;
          }
        }

        this.authenticationService.getSessionType().subscribe((sessionType) => {
          if (sessionType) {
            this.sessionType = sessionType;
          }
        });
      });
    });
  }

  buildPatient(patient) {
    if (patient['Gender.Code']) {
      this.patient.genderCode = patient['Gender.Code'];
    }

    if (patient['Salutation']) {
      this.patient.title = patient['Salutation'];
    }

    if (patient['FirstName']) {
      this.patient.firstName = patient['FirstName'];
    }

    if (patient['tableName']) {
      this.patient.tableName = patient['tableName'];
    }

    if (patient['TableName']) {
      this.patient.tableName = patient['TableName'];
    }

    if (patient['dateOfBirth'] && patient['dateOfBirth'] != '0000-00-00') {
      this.patient.dateOfBirth = patient['dateOfBirth'];
      this.patient._dateOfBirth = patient['dateOfBirth'];
    }

    if (patient['DateOfBirth'] && patient['DateOfBirth'] != '0000-00-00') {
      this.patient.dateOfBirth = patient['DateOfBirth'];
      this.patient._dateOfBirth = patient['DateOfBirth'];
    }

    if (patient['MiddleName']) {
      this.patient.middleName = patient['MiddleName'];
    }

    if (patient['Name']) {
      this.patient.lastName = patient['Name'];
    }

    if (patient['mobiles.Number']) {
      this.patient.mobile = patient['mobiles.Number'];
    }

    if (patient['phone.Home.Number']) {
      this.patient.homePhone = patient['phone.Home.Number'];
    }

    if (patient['phone.Work.Number']) {
      this.patient.workPhone = patient['phone.Work.Number'];
    }

    if (patient['emails.Email']) {
      this.patient.email = patient['emails.Email'];
    }

    if (patient['ID']) {
      this.patientID = patient['ID'];
    }
  }

  changeMerchant() {
    if (this.merchant && this.merchant.ID && this.isAdminOrPromoter == true) {
      this.merchantID = this.merchant.ID;

      this.merchantService.getMerchantDetails(this.merchantID, {}, this.sessionType).subscribe((res) => {
        if (res) {
          this.selectedMerchant = res;
        }
      });
    }
  }

  viewMerchant() {
    if (this.merchant && this.merchant['ID']) {
      const ref = RootAppComponent.dialog.open(MerchantViewComponent, {
        data: this.merchant['ID'],
        width: '600px',
      });
      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    }
  }

  getEmail(p) {
    this.patient['email'] = p;
  }

  getMobile(p) {
    this.patient['mobile'] = p;
  }

  getHomePhone(p) {
    this.patient['homePhone'] = p;
  }

  getWorkPhone(p) {
    this.patient['workPhone'] = p;
  }

  validateMobile(v) {
    this.isMobileValid = v;
  }

  validateHomePhone(v) {
    this.isHomePhoneValid = v;
  }

  validateWorkPhone(v) {
    this.isWorkPhoneValid = v;
  }

  validateEmail(v) {
    this.isEmailValid = v;
  }

  openPatientLookUpDialog() {
    const ref = RootAppComponent.dialog.open(InvitationLookupComponent, {
      data: {
        merchantID: this.merchantID,
        onlyMyAccount: true,
        useStrict: true,
      },
      width: '600px',
    });
    const sub = ref.componentInstance.closeModal.subscribe((data) => {
      ref.close();
    });
    ref.componentInstance.detailedView.subscribe((res) => {
      ref.close();
    });
    ref.componentInstance.getSelectedPatient.subscribe((res) => {
      if (res) {
        if (res.ID) {
          this.patientID = res.ID;
          this.patient = res;
          this.buildPatient(res);
          this.isFinDMatch = true;
          ref.close();
        }
      }
    });

    ref.componentInstance.patientChat.subscribe((res) => {
      if (res && res.cardID) {
        if (res.isPromoterOrAdmin == true) {
          const ref2 = RootAppComponent.dialog.open(SmsPromoterComponent, {
            data: {
              merchantID: res.merchantID,
              cardID: res.cardID,
              singleChat: true,
            },
            panelClass: 'noCard',
            width: '500px',
          });
          ref2.componentInstance.close.subscribe((data) => {
            ref2.close();
          });

          ref2.componentInstance.getResult.subscribe((data) => {
            if (data) {
              const ref3 = RootAppComponent.dialog.open(SmsConversationComponent, {
                data,
                panelClass: 'bigger-screen',
                width: '70%',
                height: '95vh',
              });
              const sub = ref3.componentInstance.close.subscribe((data) => {
                ref3.close();
              });
            }

            ref2.close();
          });
        } else {
          const ref2 = RootAppComponent.dialog.open(SmsConversationComponent, {
            data: {
              merchantID: res.merchantID,
              cardID: res.cardID,
              singleChat: true,
            },
            panelClass: 'bigger-screen',
            width: '70%',
            height: '95vh',
          });
          const sub = ref2.componentInstance.close.subscribe((data) => {
            ref2.close();
          });
        }
      }
    });
  }

  getPatientFromLookup(patient) {
    if (patient && patient.ID) {
      this.lookupPage = false;
      this.patientID = patient.ID;

      this.patient = patient;
    }
  }

  backToLookup() {
    this.patientID = null;
    this.patient = null;
    this.lookupPage = true;
  }

  modifyProfile() {
    if (this.patient && this.patientID) {
      const ref = RootAppComponent.dialog.open(InvitationCustomerProspectEditComponent, {
        data: {
          patientID: this.patientID,
        },
        width: '650px',
      });

      ref.componentInstance.getResult.subscribe((res) => {
        if (res && res.ID) {
          this.buildPatient(res);
          ref.close();
        }
      });
    }
  }

  goBack() {
    this.location.back();
  }

  setDentalXrayScanResult(dentalXrayScanResult: DentalXrayScanResult) {
    this.dentalXrayScanResult = dentalXrayScanResult;
  }

  submitXray() {
    switch (this.dentalXrayScanResult.uploadType) {
      case 'panoramic':
        this.submitPanoramicXray();
        break;
      default:
        this.submitBitewingsXray();
        break;
    }
  }

  submitPanoramicXray() {
    this.fileService.getFile(this.dentalXrayScanResult.imageIDPanoramic).subscribe((fileInfo) => {
      this.dentalXrService
        .analyzeAdmin({
          merchantKey: this.patient['merchantKey'],
          fileID: this.dentalXrayScanResult.imageIDPanoramic,
          fileName: `panoramic_${this.dentalXrayScanResult.calculatedName}_${this.dentalXrayScanResult.dateCreated}.${fileInfo.extension}`,
          patientName: this.dentalXrayScanResult.calculatedName,
          imageDate: this.dentalXrayScanResult.dateCreated,
          dateOfBirth: this.patient['DateOfBirth'],
          patientID: this.patient['ID'],
          contentType: fileInfo.contentType,
        })
        .subscribe(
          (res: {
            data: { [key: string]: any };
            apical: DentalXrayScanDetectionItem[];
            caries: DentalXrayScanDetectionItem[];
            restorations: DentalXrayScanDetectionItem[];
          }) => {
            this.dentalXrayScanResult.apicalDetections = res.data.result.apical;
            this.dentalXrayScanResult.cariesDetections = res.data.result.caries;
            this.dentalXrayScanResult.restorationsDetections = res.data.result.restorations;

            NotifyAppComponent.displayToast('success', 'Xray Successfully analyzed', '');

            this.collectTags();
          }
        );
    });
  }

  submitBitewingsXray() {
    this.fileService.getFile(this.dentalXrayScanResult.imageIDLeft).subscribe((fileInfo) => {
      this.dentalXrService
        .analyzeAdmin({
          merchantKey: this.patient['merchantKey'],
          fileID: this.dentalXrayScanResult.imageIDLeft,
          fileName: `left_bitewing_${this.dentalXrayScanResult.calculatedName}_${this.dentalXrayScanResult.dateCreated}.${fileInfo.extension}`,
          patientName: this.dentalXrayScanResult.calculatedName,
          imageDate: this.dentalXrayScanResult.dateCreated,
          dateOfBirth: this.patient['DateOfBirth'],
          patientID: this.patient['ID'],
          contentType: fileInfo.contentType,
        })
        .subscribe(
          (res: {
            data: { [key: string]: any };
            apical: DentalXrayScanDetectionItem[];
            caries: DentalXrayScanDetectionItem[];
            restorations: DentalXrayScanDetectionItem[];
          }) => {
            this.dentalXrayScanResult.apicalDetections = res.data.result.apical;
            this.dentalXrayScanResult.cariesDetections = res.data.result.caries;
            this.dentalXrayScanResult.restorationsDetections = res.data.result.restorations;

            this.submitRightBitewingXray();
          }
        );
    });
  }

  submitRightBitewingXray() {
    this.fileService.getFile(this.dentalXrayScanResult.imageIDRight).subscribe((fileInfo) => {
      this.dentalXrService
        .analyzeAdmin({
          merchantKey: this.patient['merchantKey'],
          fileID: this.dentalXrayScanResult.imageIDRight,
          fileName: `right_bitewing_${this.dentalXrayScanResult.calculatedName}_${this.dentalXrayScanResult.dateCreated}.${fileInfo.extension}`,
          patientName: this.dentalXrayScanResult.calculatedName,
          imageDate: this.dentalXrayScanResult.dateCreated,
          dateOfBirth: this.patient['DateOfBirth'],
          patientID: this.patient['ID'],
          contentType: fileInfo.contentType,
        })
        .subscribe(
          (res: {
            data: { [key: string]: any };
            apical: DentalXrayScanDetectionItem[];
            caries: DentalXrayScanDetectionItem[];
            restorations: DentalXrayScanDetectionItem[];
          }) => {
            this.dentalXrayScanResult.apicalDetections.concat(res.data.result.apical);
            this.dentalXrayScanResult.cariesDetections.concat(res.data.result.caries);
            this.dentalXrayScanResult.restorationsDetections.concat(res.data.result.restorations);

            NotifyAppComponent.displayToast('success', 'Xray Successfully analyzed', '');

            this.collectTags();
          }
        );
    });
  }

  collectTags() {
    let xrayTags: string[] = [];

    const apicalResults = this.dentalXrayScanResult.apicalDetections;
    const cariesResults = this.dentalXrayScanResult.cariesDetections;
    const restorationsResults = this.dentalXrayScanResult.restorationsDetections;

    apicalResults.forEach((result) => xrayTags.push(`apical ${result.subtype} (ai)`));
    cariesResults.forEach((result) => xrayTags.push(`caries ${result.subtype} (ai)`));
    restorationsResults.forEach((result) => xrayTags.push(`restorations ${result.subtype} (ai)`));

    xrayTags = xrayTags.filter((item, position) => xrayTags.indexOf(item) == position);

    this.verifyXrayTagsExist(xrayTags);
  }

  verifyXrayTagsExist(xrayTags: string[]) {
    const xrayTagsLength = xrayTags.length;

    const uniqueXrayTagObservables: Observable<void>[] = [];

    for (let index = 0; index < xrayTagsLength; index++) {
      const xrayTag = xrayTags[index];

      uniqueXrayTagObservables.push(
        this.tagsService.isUniqueTag(xrayTag, this.sessionType).pipe(
          mergeMap((isUniqueTag) => {
            if (isUniqueTag) {
              return this.tagsService.createTag(
                {
                  label: xrayTag,
                  description: '',
                  type: 'Tag',
                  staticAudience: '0',
                },
                this.sessionType
              );
            } else {
              return of(null);
            }
          })
        )
      );
    }

    forkJoin(uniqueXrayTagObservables).subscribe(() => {
      this.attachTagsToUser(xrayTags);
    });
  }

  attachTagsToUser(xrayTags: string[]) {
    const patientTagsRaw = this.patient['Tag.Label'] as string;
    const patientTags = patientTagsRaw.split('|');

    const filteredTags = xrayTags.filter((xrayTag) => patientTags.indexOf(xrayTag) === -1);

    const completeTags = patientTags.concat(filteredTags).join(',');

    const payload = {
      labels: completeTags,
      cardID: this.patientID,
    };

    this.tagsService.createTagForCard(payload, this.sessionType).subscribe(() => {
      this.dentalXrayScanResult.tags = xrayTags.join(',');

      NotifyAppComponent.displayToast('success', 'AI Scan Report Created', '');

      this.wizard.goToNextStep();

      this.isAiScanReportReady = true;
    });
  }

  clearPatientDetails() {
    this.clearEmail.emit(true);
    this.clearMobile.emit(true);
    this.clearHomePhone.emit(true);
    this.clearWorkPhone.emit(true);

    this.patient = {
      firstName: '',
      title: '',
      lastName: '',
      middleName: '',
      genderCode: '',
      mobile: '',
      homePhone: '',
      workPhone: '',
      email: '',
      _dateOfBirth: '',
      dateOfBirth: '',
      tableName: null,
    };

    this.patientID = null;
    this.isFinDMatch = false;
    this.patient.dateOfBirth = null;
    this.patient._dateOfBirth = null;

    this.clearDate.emit(true);
  }

  patientProfile() {
    if (this.patientID) {
      this.router.navigate([
        '/merchant',
        {
          outlets: {
            page: ['customer-profile', this.patientID, 'patient'],
          },
        },
      ]);
    }
  }

  step1() {
    if (!this.patientID) {
      const payload = {
        mobile: this.patient.mobile,
        phone: this.patient.homePhone,
        email: this.patient.email,
        firstName: this.patient.firstName,
        lastName: this.patient.lastName,
        dateOfBirth: this.patient._dateOfBirth,
        merchantID: this.merchantID,
      };
      this.customerProspectService.patientLookup(payload, this.isAdminOrPromoter).subscribe((res) => {
        if (res && res.length > 0) {
          const patientLookup = RootAppComponent.dialog.open(InvitationLookupComponent, {
            data: {
              patients: res,
              hideFilter: true,
              showNextBtn: true,
            },
            width: '600px',
          });

          patientLookup.componentInstance.closeModal.subscribe((res) => {
            patientLookup.close();
          });
          patientLookup.componentInstance.detailedView.subscribe((res) => {
            patientLookup.close();
          });
          patientLookup.componentInstance.getSelectedPatient.subscribe((res) => {
            if (res) {
              if (res.ID) {
                this.patientID = res.ID;
                this.buildPatient(res);
                this.isFinDMatch = true;
                patientLookup.close();

                this.wizard.goToNextStep();
              }
            }
          });

          patientLookup.componentInstance.patientChat.subscribe((res) => {
            if (res && res.cardID) {
              if (res.isPromoterOrAdmin == true) {
                const ref2 = RootAppComponent.dialog.open(SmsPromoterComponent, {
                  data: {
                    merchantID: res.merchantID,
                    cardID: res.cardID,
                    singleChat: true,
                  },
                  panelClass: 'noCard',
                  width: '500px',
                });
                ref2.componentInstance.close.subscribe((data) => {
                  ref2.close();
                });

                ref2.componentInstance.getResult.subscribe((data) => {
                  if (data) {
                    const ref3 = RootAppComponent.dialog.open(SmsConversationComponent, {
                      data,
                      panelClass: 'bigger-screen',
                      width: '70%',
                      height: '95vh',
                    });
                    const sub = ref3.componentInstance.close.subscribe((data) => {
                      ref3.close();
                    });
                  }

                  ref2.close();
                });
              } else {
                const ref2 = RootAppComponent.dialog.open(SmsConversationComponent, {
                  data: {
                    merchantID: res.merchantID,
                    cardID: res.cardID,
                    singleChat: true,
                  },
                  panelClass: 'bigger-screen',
                  width: '70%',
                  height: '95vh',
                });
                const sub = ref2.componentInstance.close.subscribe((data) => {
                  ref2.close();
                });
              }
            }
          });

          patientLookup.componentInstance.createNew.subscribe((res) => {
            patientLookup.close();
            this.wizard.goToNextStep();
          });
        } else {
          this.wizard.goToNextStep();
        }
      });
    } else {
      this.wizard.goToNextStep();
    }
  }

  selectDateToFilter(dateTo) {
    this.patient._dateOfBirth = dateTo;
  }

  setXrayOptionType(xrayOptionType: XrayOptionType) {
    this.isDisabled = false;
    this.dentalXrayImageIds = [];

    this.dentalXrayScanResult = {
      ...this.dentalXrayScanResult,
      dateCreated: null,
      uploadType: null,
      calculatedName: null,
      patientID: null,
      merchantID: null,
      operatorID: null,
      imageIDPanoramic: null,
      imageIDLeft: null,
      imageIDRight: null,
    };

    const imageDate = new Date()
      .toLocaleString()
      .replace(/\//g, '-')
      .replace(/,/g, '_')
      .replace(/\s/g, '')
      .replace(/:/g, '-');

    const patientName = (this.patient['CalculatedName'].replace(/ /g, '_') as string).toLowerCase();

    this.currentFileNamePanoramic = `panoramic_${patientName}_${imageDate}`;
    this.currentFileNameLeft = `left_bitewing_${patientName}_${imageDate}`;
    this.currentFileNameRight = `right_bitewing_${patientName}_${imageDate}`;

    this.setDentalXrayScanResult(this.dentalXrayScanResult);
  }

  getXrayImageId(xrayImageId: string, xraySide: 'panoramic' | 'left' | 'right') {
    this.fileService.getFile(xrayImageId).subscribe((fileInfo) => {
      if (this.patient['CalculatedName'] === undefined) {
        NotifyAppComponent.displayToast('error', 'No patient CalculatedName provided', 'Image could not be added');

        return;
      }

      if (this.patient['DateOfBirth'] === undefined) {
        NotifyAppComponent.displayToast('error', 'No patient date of birth provided', 'Image could not be added.');

        return;
      }

      if (this.patient['ID'] === undefined) {
        NotifyAppComponent.displayToast('error', 'No patient ID provided', 'Image could not be added.');

        return;
      }

      const imageDate = new Date()
        .toLocaleString()
        .replace(/\//g, '-')
        .replace(/,/g, '_')
        .replace(/\s/g, '')
        .replace(/:/g, '-');

      let fileNameXrayType = null;

      if (this.selectedXrayOptionType === 'panoramic') {
        fileNameXrayType = 'panoramic';
      } else {
        fileNameXrayType = `${xraySide}_bitewing`;
      }

      this.dentalXrayImageIds = this.dentalXrayImageIds.filter(
        (dentalXrayImage) => dentalXrayImage.xraySide !== xraySide
      );

      this.dentalXrayImageIds.push({
        id: fileInfo.id,
        xraySide,
      });

      switch (xraySide) {
        case 'left':
          this.dentalXrayScanResult = {
            ...this.dentalXrayScanResult,
            dateCreated: imageDate,
            uploadType: 'bitewings',
            calculatedName: this.patient['CalculatedName'],
            patientID: this.patient['ID'],
            merchantID: this.patient['merchantKey'],
            operatorID: this.patient['operatorID'],
            imageIDLeft: fileInfo.id,
          };

          this.setDentalXrayScanResult(this.dentalXrayScanResult);
          break;

        case 'right':
          this.dentalXrayScanResult = {
            ...this.dentalXrayScanResult,
            dateCreated: imageDate,
            uploadType: 'bitewings',
            calculatedName: this.patient['CalculatedName'],
            patientID: this.patient['ID'],
            merchantID: this.patient['merchantKey'],
            operatorID: this.patient['operatorID'],
            imageIDRight: fileInfo.id,
          };

          this.setDentalXrayScanResult(this.dentalXrayScanResult);
          break;

        default:
          this.dentalXrayScanResult = {
            ...this.dentalXrayScanResult,
            dateCreated: imageDate,
            uploadType: 'panoramic',
            calculatedName: this.patient['CalculatedName'],
            patientID: this.patient['ID'],
            merchantID: this.patient['merchantKey'],
            operatorID: this.patient['operatorID'],
            imageIDPanoramic: fileInfo.id,
          };

          this.setDentalXrayScanResult(this.dentalXrayScanResult);
          break;
      }
    });
  }

  getPanoramicImageIds() {
    return this.dentalXrayImageIds
      .filter((dentalXrayImage) => dentalXrayImage.xraySide === 'panoramic')
      .map((dentalXrayImage) => dentalXrayImage.id);
  }

  removePanoramicXrayImage() {
    this.dentalXrayImageIds = [];

    this.dentalXrayScanResult = {
      ...this.dentalXrayScanResult,
      dateCreated: null,
      uploadType: null,
      calculatedName: null,
      patientID: null,
      merchantID: null,
      operatorID: null,
      imageIDPanoramic: null,
    };

    this.setDentalXrayScanResult(this.dentalXrayScanResult);
  }

  getLeftBitewingImageIds() {
    return this.dentalXrayImageIds
      .filter((dentalXrayImage) => dentalXrayImage.xraySide === 'left')
      .map((dentalXrayImage) => dentalXrayImage.id);
  }

  getRightBitewingImageIds() {
    return this.dentalXrayImageIds
      .filter((dentalXrayImage) => dentalXrayImage.xraySide === 'right')
      .map((dentalXrayImage) => dentalXrayImage.id);
  }

  removeLeftBitewingXrayImage() {
    this.dentalXrayImageIds
      .filter((dentalXrayImage) => dentalXrayImage.xraySide !== 'left')
      .map((dentalXrayImage) => dentalXrayImage.id);

    this.dentalXrayScanResult = {
      ...this.dentalXrayScanResult,
      dateCreated: null,
      uploadType: null,
      calculatedName: null,
      patientID: null,
      merchantID: null,
      operatorID: null,
      imageIDLeft: null,
    };

    this.setDentalXrayScanResult(this.dentalXrayScanResult);
  }

  removeRightBitewingXrayImage() {
    this.dentalXrayImageIds
      .filter((dentalXrayImage) => dentalXrayImage.xraySide !== 'right')
      .map((dentalXrayImage) => dentalXrayImage.id);

    this.dentalXrayScanResult = {
      ...this.dentalXrayScanResult,
      dateCreated: null,
      uploadType: null,
      calculatedName: null,
      patientID: null,
      merchantID: null,
      operatorID: null,
      imageIDRight: null,
    };

    this.setDentalXrayScanResult(this.dentalXrayScanResult);
  }
}

function Output() {
  throw new Error('Function not implemented.');
}
