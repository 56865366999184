import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SettlementIntroModalComponent } from '../../settlement/settlement-intro-modal/settlement-intro-modal.component';
import { HealthfundIntroModalComponent } from '../healthfund-intro-modal/healthfund-intro-modal.component';
import { SettlementCreateIntroModalComponent } from '../settlement-create-intro-modal/settlement-create-intro-modal.component';
import { SettlementCreateComponent } from '../settlement-create/settlement-create.component';
import { SettlementCustomerListGlobalComponent } from '../settlement-customer-list-global/settlement-customer-list-global.component';
import { SettlementCustomerListOverViewComponent } from '../settlement-customer-list-overview/settlement-customer-list-overview.component';
import { SettlementCustomerListComponent } from '../settlement-customer-list/settlement-customer-list.component';
import { SettlementDueSettlementsReportPage } from '../settlement-due-settlements-report/settlement-due-settlements-report.page';
import { SettlementHealthFundModalComponent } from '../settlement-healthfund-modal/settlement-healthfund-modal.component';
import { SettlementListOverViewComponent } from '../settlement-list-overview/settlement-list-overview.component';
import { SettlementModifyComponent } from '../settlement-modify/settlement-modify.component';
import { SettlementPaymentsReportPage } from '../settlement-payments-report/settlement-payments-report.page';
import { SettlementRefundIntroModalComponent } from '../settlement-refund-intro-modal/settlement-refund-intro-modal.component';
import { SettlementRequestComponent } from '../settlement-request/settlement-request.component';
import { SettlementThirdPartyModalComponent } from '../settlement-thirdparty-modal/settlement-thirdparty-modal.component';
import { SettlementViewMiniComponent } from '../settlement-view-mini/settlement-view-mini.component';
import { ThirdpartyIntroModalComponent } from '../thirdparty-intro-modal/thirdparty-intro-modal.component';
import { SettlementNewModalComponent } from '../settlement-new-modal/settlement-new-modal.component';

const routes: Routes = [
  {
    path: 'SettlementNewModalComponent',
    component: SettlementNewModalComponent,
  },
  {
    path: 'settlement-intro-modal',
    component: SettlementIntroModalComponent,
  },
  {
    path: 'settlement-refund-intro-modal',
    component: SettlementRefundIntroModalComponent,
  },
  {
    path: 'SettlementRequestComponent',
    component: SettlementRequestComponent,
  },
  {
    path: 'SettlementViewMiniComponent',
    component: SettlementViewMiniComponent,
  },

  {
    path: 'settlement-payments-report',
    component: SettlementPaymentsReportPage,
  },
  {
    path: 'settlement-due-settlements-report',
    component: SettlementDueSettlementsReportPage,
  },
  {
    path: 'settlement-customer-list',
    component: SettlementCustomerListComponent,
  },
  {
    path: 'settlement-customer-list-overview',
    component: SettlementCustomerListOverViewComponent,
  },
  {
    path: 'settlement-customer-list-global',
    component: SettlementCustomerListGlobalComponent,
  },
  {
    path: 'settlement-edit',
    component: SettlementModifyComponent,
  },
  {
    path: 'settlement-create',
    component: SettlementCreateComponent,
  },
  {
    path: 'settlement-thirdparty-modal',
    component: SettlementThirdPartyModalComponent,
  },
  {
    path: 'settlement-healthfund-modal',
    component: SettlementHealthFundModalComponent,
  },
  {
    path: 'settlement-create-intro-modal',
    component: SettlementCreateIntroModalComponent,
  },
  {
    path: 'Healthfund-intro-modal',
    component: HealthfundIntroModalComponent,
  },
  {
    path: 'Thirdparty-intro-modal',
    component: ThirdpartyIntroModalComponent,
  },
  {
    path: 'settlement-list-overview',
    component: SettlementListOverViewComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SettlementRoutingModule {}
