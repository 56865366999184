import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { DataSource } from '@angular/cdk/table';
import { Component, ElementRef, EventEmitter, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator, MatSort } from '@angular/material';
import { map as _map, uniq } from 'lodash';
import { BehaviorSubject, merge as observableMerge, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { CustomDatePipe } from '../../../shared/pipes/custom-date.pipe';
import { SideMenuService } from '../../../shared/services/side-menu.service';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { DocumentService } from '../shared/document.service';

import { Router } from '@angular/router';
import * as _ from 'lodash';
import * as moment from 'moment';
import { IframeViewComponent } from '../../../shared/components/iframe-view/iframe-view.component';
import { DocumentLinkCreateComponent } from '../document-link-create/document-link-create.component';
import { DocumentLinkShareComponent } from '../document-link-share/document-link-share.component';
import { DocumentLinkViewComponent } from '../document-link-view/document-link-view.component';
import { LandingPageNewModalComponent } from '../landing-page-new-modal/landing-page-new-modal.component';

@Component({
  selector: 'app-document-link-list',
  templateUrl: './document-link-list.component.html',
  styleUrls: ['./document-link-list.component.css'],
  providers: [CustomDatePipe],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.row', style({ opacity: 0 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.5s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.row', style({ opacity: 1 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.5s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class DocumentLinkListComponent implements OnInit {
  isPromoterOrAdmin = false;

  typeFilter = null;
  typeFilter2;

  types = [
    {
      Code: 'FLandPg',
      Label: 'Finance Landing Page',
      Icon: 'fas fa-search-dollar',
    },
    {
      Code: 'PLandPg',
      Label: 'Product Landing Page',
      Icon: 'fas fa-images',
    },
  ];

  @Input()
  title = 'Pages';

  @Input()
  merchantID;

  @Input()
  isDefault;
  @Input()
  isActive;
  @Input()
  isPublic;
  @Input()
  isStored = true;
  @Input()
  addDefault = true;

  isActiveFilter;

  @Input()
  pageSize = Settings.global['listPageSize'] || 20;
  pageSizeOptions = [10, Number(this.pageSize), Number(this.pageSize) * 2, Number(this.pageSize) * 3];

  utils = new UtilsClass();

  displayedColumns = [
    'selectCheckBox',
    'Theme',
    'Label',
    // 'Title',
    'type',
    'Amount',
    'Tags',
    'Visitors',
    'Documents',
    'active',
    'Public',
    // 'Default',
    'ExpireAt',
    'Date',
    'Actions',
  ];
  filters = [];

  selectedIDs = [];

  searchVals = new FormControl();

  searchValList = ['Label'];

  expandedRow: number;
  public listDB: LoadRecords | null;
  dataSource: RecordDataSource | null;

  merchantTags = [];

  filteredSize = null;

  themeObject = {};

  searchLabel;
  sessionType;

  currentPractice;
  dentistPractice;
  renderValue = {};

  dynamicData = [];
  destroyEvent = new EventEmitter();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('filter', { static: false }) filter: ElementRef;

  constructor(
    private router: Router,
    private customDate: CustomDatePipe,
    private authenticationService: AuthenticationService,
    private documentService: DocumentService
  ) {}

  ngOnInit() {
    if ((this.isDefault === true || this.isDefault === 'true') && this.isPromoterOrAdmin != true) {
      this.displayedColumns = [
        'selectCheckBox',
        'Theme',
        'Label',
        // 'Title',
        'type',
        'Amount',
        'Tags',
        'Documents',
        'Date',
        'Actions',
      ];
    } else {
      this.displayedColumns = [
        'selectCheckBox',
        'Theme',
        'Label',
        // 'Title',
        'type',
        'Amount',
        'Tags',
        'Visitors',
        'Documents',
        'active',
        'Public',
        // 'Default',
        'ExpireAt',
        'Date',
        'Actions',
      ];
    }

    const p = {
      isPromoter: true,
      fields: 'Label',
    };
    this.documentService.documentLinkListTheme().subscribe((t) => {
      if (t && t.length > 0) {
        _map(t, (item) => {
          if (item && item.code) {
            this.themeObject[item.code] = item.background;
          }

          return true;
        });
      }
    });

    this.authenticationService.getCurrentDentist().subscribe((dentistPractice) => {
      this.dentistPractice = dentistPractice;
      this.authenticationService.getCurrentPractice().subscribe((currentPractice) => {
        this.currentPractice = currentPractice;

        this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
          this.isPromoterOrAdmin = res;

          this.authenticationService.getSessionType().subscribe((st) => {
            this.sessionType = st;

            const params = {
              merchantID: this.merchantID,
              isPublic: this.isPublic,
              isActive: this.isActive,
              isDefault: this.isDefault,
              isStored: this.isStored,
              addDefault: this.addDefault,
              onlyDefault: this.isDefault,
            };

            if (this.isDefault === false) {
              params.addDefault = false;
            }
            SideMenuService.goBack.subscribe((res) => {
              if (res == true) {
                this.hideViewDetails();
              }
            });

            this.listDB = new LoadRecords(this.documentService, this.destroyEvent, params, this.sessionType);
            this.dataSource = new RecordDataSource(this.listDB, this.paginator, this.sort);
            this.listDB.getDefaultFilter.subscribe((d) => {
              this.typeFilter = d;
              this.setFilter(d, 'IsDefault');
            });
            this.listDB.getMerchantTags.subscribe((tags) => {
              this.merchantTags = JSON.parse(JSON.stringify(tags || []));
            });
          });
        });
      });
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((this.isDefault === true || this.isDefault === 'true') && this.isPromoterOrAdmin != true) {
      this.displayedColumns = [
        'selectCheckBox',
        'Theme',
        'Label',
        // 'Title',
        'type',
        'Amount',
        'Tags',
        'Documents',
        'Date',
        'Actions',
      ];
    } else {
      this.displayedColumns = [
        'selectCheckBox',
        'Theme',
        'Label',
        // 'Title',
        'type',
        'Amount',
        'Tags',
        'Visitors',
        'Documents',
        'active',
        'Public',
        // 'Default',
        'ExpireAt',
        'Date',
        'Actions',
      ];
    }
    const params = {
      merchantID: this.merchantID,
      isPublic: this.isPublic,
      isActive: this.isActive,
      isDefault: this.isDefault,
      isStored: this.isStored,
      addDefault: this.addDefault,
    };

    this.listDB = new LoadRecords(this.documentService, this.destroyEvent, params, this.sessionType);
    this.dataSource = new RecordDataSource(this.listDB, this.paginator, this.sort);
  }

  hideViewDetails() {}

  clone(document) {
    if (document && document.ID) {
      const m = this.merchantID || 'null';

      this.router.navigate([
        '/merchant',
        {
          outlets: {
            page: ['landing-page-clone', document.ID, m, 'true'],
          },
        },
      ]);
    }
  }

  share(t) {
    if (t && t.ID) {
      if (t.IsActive === '1') {
        const ref2 = RootAppComponent.dialog.open(DocumentLinkShareComponent, {
          data: {
            landingPage: t,
          },

          width: '80vw',
          panelClass: 'max-width-panel-1400',
        });

        ref2.componentInstance.close.subscribe((res) => {
          ref2.close();
        });
      } else {
        const confirmDialog = new ConfirmDialog(
          'check_circle',
          'Enable Landing Page',
          '<p>This Landing Page is <strong></strong>, Do you wish to Active it before proceed?</p>',
          'Cancel',
          'Okay'
        );

        const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
          data: confirmDialog,
          // SET TO PAYING CASH CARD
        });
        ref.componentInstance.onConfirm.subscribe((val) => {
          if (val == true) {
            ref.close();
            ref.afterClosed().subscribe((res) => {
              const payload = {
                isActive: true,
              };
              this.documentService.modifyDocumentLink(t.ID, payload, this.sessionType).subscribe((res) => {
                if (res) {
                  this.dataSource.replaceItem = res;

                  NotifyAppComponent.displayToast('success', 'Success!', 'Landing Page is Active.');

                  const ref2 = RootAppComponent.dialog.open(DocumentLinkShareComponent, {
                    data: {
                      landingPage: t,
                    },

                    width: '80vw',
                    panelClass: 'max-width-panel-1400',
                  });

                  ref2.componentInstance.close.subscribe((res) => {
                    ref2.close();
                  });
                }
              });
            });
          } else {
            ref.close();
          }
        });
      }
    }
  }
  setFilter(event, field) {
    let filter;

    if (field == 'ApprovedOrActive') {
      let _event;
      if (event == true) {
        _event = '1';
      } else {
        _event = '0';
      }
      filter = {
        field,
        value: _event,
      };

      this.searchLabel = _event;
    } else if (field == 'Tags' && event && event.length > 0) {
      let e = event;
      if (typeof e === 'string') {
        e = [event];
      }

      filter = {
        field,
        value: e,
      };

      this.searchLabel = event;
    } else if (typeof event == 'object' && event != null) {
      const v = this.customDate.transform(event, Settings.global['dateFormat']);
      filter = {
        field,
        value: v,
      };

      this.searchLabel = v;
    } else if (event) {
      filter = {
        field,
        value: event,
      };

      this.searchLabel = event;
    } else {
      filter = {
        field,
        value: '',
      };

      this.searchLabel = null;
    }

    this.dataSource.filter = filter;
    this.filteredSize = this.dataSource.filteredData.length;
  }

  removed(id) {
    if (id) {
      const confirm = new ConfirmDialog(
        'fas fa-info',
        '',
        'Are you sure you want to remove this Landing Page ?',
        'No',
        'Yes'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation === false) {
          ref.close();
        } else {
          this.documentService.deleteDocumentLink(id, this.sessionType).subscribe((res) => {
            if (res) {
              this.dataSource.removeItem = id;

              NotifyAppComponent.displayToast(
                'success',
                'Successful operation',
                'The Landing Page has been successfully removed'
              );
            }
            ref.close();
          });
        }
      });
    }
  }

  preview(id) {
    if (id) {
      this.authenticationService.getCurrentPractice().subscribe((r) => {
        if (r && r.ID) {
          const link = `${Settings.global['publicSiteFrontendLink']}/landing-page-overview/${id}`;

          window.open(link, '_blank');
        }
      });
    }
  }

  edit(t) {
    if (t) {
      const ref2 = RootAppComponent.dialog.open(DocumentLinkCreateComponent, {
        data: {
          merchantID: this.merchantID,
          addToStore: true,
          linkID: t,
        },
        panelClass: 'noCard',
      });
      ref2.backdropClick().subscribe((res) => {});
      ref2.componentInstance.close.subscribe((res) => {
        ref2.close();
      });
      ref2.componentInstance.getResult.subscribe((res) => {
        if (res) {
          this.dataSource.replaceItem = res;

          ref2.close();
        }
      });
    }
  }

  enableGroup() {
    if (this.selectedIDs && this.selectedIDs.length > 0) {
      const confirmDialog = new ConfirmDialog(
        'cancel',
        'Enable Selected Landing Pages',
        '<p>Once the Landing Page is Active it will not appear as an option for the user.</p>',
        'Cancel',
        'Okay'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirmDialog,
        // SET TO PAYING CASH CARD
      });
      ref.componentInstance.onConfirm.subscribe((val) => {
        if (val == true) {
          ref.close();
          ref.afterClosed().subscribe((res) => {
            const payload = {
              isActive: true,
              ids: this.selectedIDs.join(','),
            };
            this.documentService.modifyDocumentLinkGroup(payload, this.sessionType).subscribe((res) => {
              if (res && res.length > 0) {
                for (let i = 0; i < res.length; i++) {
                  this.dataSource.replaceItem = res[i];
                }

                this.selectedIDs = [];

                NotifyAppComponent.displayToast('success', 'Success!', 'Landing Pages are enabled.');
              }
            });
          });
        } else {
          ref.close();
        }
      });
    }
  }

  disableGroup() {
    if (this.selectedIDs && this.selectedIDs.length > 0) {
      const confirmDialog = new ConfirmDialog(
        'cancel',
        'Disable Selected Landing Pages',
        '<p>Once the Landing Page is not Active it will not appear as an option for the user.</p>',
        'Cancel',
        'Okay'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirmDialog,
        // SET TO PAYING CASH CARD
      });
      ref.componentInstance.onConfirm.subscribe((val) => {
        if (val == true) {
          ref.close();
          ref.afterClosed().subscribe((res) => {
            const payload = {
              isActive: false,
              ids: this.selectedIDs.join(','),
            };
            this.documentService.modifyDocumentLinkGroup(payload, this.sessionType).subscribe((res) => {
              if (res && res.length > 0) {
                for (let i = 0; i < res.length; i++) {
                  this.dataSource.replaceItem = res[i];
                }

                this.selectedIDs = [];

                NotifyAppComponent.displayToast('success', 'Success!', 'Landing Pages are disabled.');
              }
            });
          });
        } else {
          ref.close();
        }
      });
    }
  }

  indexOfTag(e) {
    if (this.searchLabel && Array.isArray(this.searchLabel) && e) {
      if (this.searchLabel.indexOf(e) != -1) {
        return true;
      }
    }

    return false;
  }

  toLowerCase(e) {
    if (e) {
      return String(e).toLocaleLowerCase();
    }

    return e;
  }

  selectPage() {
    const index = this.dataSource['_paginator']['index'] || 0;
    const pageSize = this.dataSource['_paginator']['pageSize'] || 0;

    for (let i = 0; i < (index + 1) * pageSize; i++) {
      const o = this.dataSource.filteredData[index * pageSize + i];

      if (o && o['ID'] && this.selectedIDs.indexOf(o['ID']) == -1) {
        this.selectedIDs.push(o['ID']);
      }
    }
  }

  select(id, isSelected) {
    if (isSelected == true && this.selectedIDs.indexOf(id) == -1) {
      this.selectedIDs.push(id);
    } else {
      const _index = this.selectedIDs.indexOf(id);
      this.selectedIDs.splice(_index, 1);
    }
  }

  unselect() {
    this.selectedIDs = [];
  }

  isSelected(id) {
    if (!this.selectedIDs || this.selectedIDs.length <= 0) {
      return false;
    } else {
      if (this.selectedIDs.indexOf(id) != -1) {
        return true;
      }
    }

    return false;
  }

  selectAll() {
    for (let i = 0; i < this.dataSource.filteredData.length; i++) {
      const o = this.dataSource.filteredData[i];

      if (o && o['ID'] && this.selectedIDs.indexOf(o['ID']) == -1) {
        this.selectedIDs.push(o['ID']);
      }
    }
  }

  removeGroup() {
    if (this.selectedIDs && this.selectedIDs.length > 0) {
      const confirm = new ConfirmDialog(
        'fas fa-info',
        '',
        'Are you sure you want to remove these landing pages  ?',
        'No',
        'Yes'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation === false) {
          ref.close();
        } else {
          this.documentService.deleteDocumentLinkGroup(this.selectedIDs, this.sessionType).subscribe((res) => {
            if (res && res.length > 0) {
              for (let i = 0; i < res.length; i++) {
                this.dataSource.removeItem = res[i];
              }

              this.selectedIDs = [];
            }
            ref.close();

            NotifyAppComponent.displayToast('success', 'Success!', 'Landing Pages are removed.');
          });
        }
      });
    }
  }

  createNew() {
    const refLandingPageNewModalComponent = RootAppComponent.dialog.open(LandingPageNewModalComponent, {
      data: {},
      width: '650px',
    });

    refLandingPageNewModalComponent.componentInstance.getType.subscribe((type) => {
      refLandingPageNewModalComponent.close();

      refLandingPageNewModalComponent.afterClosed().subscribe((r) => {
        const ref2 = RootAppComponent.dialog.open(DocumentLinkCreateComponent, {
          data: {
            merchantID: this.merchantID,
            type,
            addToStore: true,
          },
          panelClass: 'noCard',
        });
        ref2.backdropClick().subscribe((res) => {});
        ref2.componentInstance.close.subscribe((res) => {
          ref2.close();
        });
        ref2.componentInstance.getResult.subscribe((res) => {
          if (res) {
            if (res.Is_Stored == '1') {
              this.dataSource.addItem = res;
            }

            ref2.close();
          }
        });
      });

      refLandingPageNewModalComponent.componentInstance.close.subscribe((res) => {
        refLandingPageNewModalComponent.close();
      });
    });
  }

  openLandingPage(record) {
    if (record && record.ID) {
      let link = `${Settings.global['publicSiteFrontendLink']}/landing-page-overview/${record.ID}`;

      if (record['IsDefault'] === '1') {
        link = `${Settings.global['publicSiteFrontendLink']}/landing-page-overview/${record.ID}?m=${this.currentPractice.ID}&c=${this.dentistPractice.ID}`;
      }

      const ref2 = RootAppComponent.dialog.open(IframeViewComponent, {
        data: {
          link,
          extraDetails: record.IsDefault != '1' || this.isPromoterOrAdmin == true,
        },
        width: '80%',
        height: '92vh',
        panelClass: 'noCard',
      });

      ref2.componentInstance.close.subscribe((res) => {
        ref2.close();
      });
    }
  }

  view(id) {
    if (id) {
      this.router.navigate([
        '/merchant',
        {
          outlets: {
            page: ['landing-page-overview', id],
          },
        },
      ]);
    }
  }
  viewQuick(id) {
    if (id) {
      const ref2 = RootAppComponent.dialog.open(DocumentLinkViewComponent, {
        data: {
          linkID: id,
          displayClone: true,
        },
        width: '950px',
      });

      ref2.componentInstance.clone.subscribe((doc) => {
        if (doc && doc.ID) {
          ref2.close();
          ref2.afterClosed().subscribe((r) => {
            this.clone(doc);
          });
        }
      });

      ref2.componentInstance.close.subscribe((res) => {
        ref2.close();
      });
    }
  }

  enable(ID) {
    const confirmDialog = new ConfirmDialog(
      'check_circle',
      'Enable Landing Page',
      '<p>Once the Landing Page is Active it will  appear as an option for the user.</p>',
      'Cancel',
      'Okay'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
      // SET TO PAYING CASH CARD
    });
    ref.componentInstance.onConfirm.subscribe((val) => {
      if (val == true) {
        ref.close();
        ref.afterClosed().subscribe((res) => {
          const payload = {
            isActive: true,
          };
          this.documentService.modifyDocumentLink(ID, payload, this.sessionType).subscribe((res) => {
            if (res) {
              res.ID = ID;
              this.dataSource.replaceItem = res;

              NotifyAppComponent.displayToast('success', 'Success!', 'Landing Page is Active.');
            }
          });
        });
      } else {
        ref.close();
      }
    });
  }

  disable(ID) {
    const confirmDialog = new ConfirmDialog(
      'cancel',
      'Disable Landing Page',
      '<p>Once the Landing Page is not Active it will not appear as an option for the user.</p>',
      'Cancel',
      'Okay'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
      // SET TO PAYING CASH CARD
    });
    ref.componentInstance.onConfirm.subscribe((val) => {
      if (val == true) {
        ref.close();
        ref.afterClosed().subscribe((res) => {
          const payload = {
            isActive: false,
          };
          this.documentService.modifyDocumentLink(ID, payload, this.sessionType).subscribe((res) => {
            if (res) {
              this.dataSource.replaceItem = res;

              NotifyAppComponent.displayToast('success', 'Success!', 'Landing Page is not Active.');
            }
          });
        });
      } else {
        ref.close();
      }
    });
  }

  ngOnDestroy() {
    this.destroyEvent.emit(true);
  }
}

export class LoadRecords implements OnInit {
  public dataChange: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  items: any;
  count: any;

  get data(): any[] {
    return this.dataChange.value;
  }

  firstLoad = false;

  get firstLoadEvent() {
    return this.firstLoad;
  }

  isDestoyed = false;
  serviceRef;

  utils = new UtilsClass();
  public getDefaultFilter = new EventEmitter();
  public getMerchantTags = new EventEmitter();
  merchantTags = [];
  typeFilter = null;

  constructor(private documentService: DocumentService, private destroyEvent, params, private sessionType) {
    this.destroyEvent.subscribe((res) => {
      this.isDestoyed = res;
    });
    const payload: any = params || {};
    payload.section = 0;
    payload.limit = 200;

    this.serviceRef = this.documentService.getDocumentLinkList(payload, this.sessionType).subscribe((res) => {
      for (let i = 0; i < res.length; i++) {
        res[i]['fullName'] = '';
        if (res[i] && res[i]['Label']) {
          res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['Label'];
        }
        if (res[i] && res[i]['Description']) {
          res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['Description'];
        }
        if (res[i] && res[i]['Title']) {
          res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['Title'];
        }

        if (res[i] && res[i]['ID']) {
          res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['ID'];
        }
        if (res[i] && res[i]['Treatment_Amount']) {
          res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['Treatment_Amount'];
        }
        if (res[i] && res[i]['Message']) {
          res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['Message'];
        }
        if (res[i] && res[i]['Theme']) {
          res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['Theme'];
        }
        if (res[i] && res[i]['Merchant_Trading_As']) {
          res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['Merchant_Trading_As'];
        }
        if (res[i] && res[i]['Type.Label']) {
          res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['Type.Label'];
        }
        if (res[i] && res[i]['Type.Code']) {
          res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['Type.Code'];
        }
        if (res[i] && res[i]['Contact_CalculatedName']) {
          res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['Contact_CalculatedName'];
        }
        if (res[i] && res[i]['ID']) {
          res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['ID'];
        }
        if (res && res[i] && res[i]['DocumentFKs']) {
          res[i]['DocumentFKs'] = res[i]['DocumentFKs'].split('|');
        } else {
          res[i]['DocumentFKs'] = [];
        }

        res[i]['isNew'] = this.isNew(res[i]['DateTimeCreated']);

        if (res && res[i] && res[i]['Tags']) {
          res[i]['Tags'] = res[i]['Tags'].split('|');
          res[i]['Tags'] = _.uniq(res[i]['Tags']);
          this.merchantTags = this.merchantTags.concat(res[i]['Tags']);
        } else {
          res[i]['Tags'] = [];
          this.merchantTags = this.merchantTags.concat(res[i]['Tags']);
        }
      }

      this.merchantTags = _.uniq(this.merchantTags);
      this.merchantTags = _.orderBy(this.merchantTags);
      this.getMerchantTags.emit(this.merchantTags);
      this.count = res.length;
      this.items = res;

      this.dataChange.next(this.items.slice());
      this.firstLoad = true;

      if (this.typeFilter != 'user' && this.items.length > 0) {
        const _userMessagesCount = this.items.filter((template) => template.IsDefault === '0').length;

        if (_userMessagesCount > 0) {
          this.typeFilter = '0';
        } else {
          this.typeFilter = null;
        }
      }

      this.getDefaultFilter.emit(this.typeFilter);

      const innerFunction = (section) => {
        const length = this.items.length;
        section = section + 1;
        UtilsClass.loadingDataSection(section);
        payload.section = section;

        this.serviceRef = this.documentService.getDocumentLinkList(payload, this.sessionType).subscribe((res) => {
          if (res.length > 0) {
            for (let i = 0; i < res.length; i++) {
              res[i]['fullName'] = '';
              if (res[i] && res[i]['Label']) {
                res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['Label'];
              }
              if (res[i] && res[i]['Description']) {
                res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['Description'];
              }
              if (res[i] && res[i]['Title']) {
                res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['Title'];
              }
              if (res[i] && res[i]['Message']) {
                res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['Message'];
              }
              if (res[i] && res[i]['Theme']) {
                res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['Theme'];
              }
              if (res[i] && res[i]['Merchant_Trading_As']) {
                res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['Merchant_Trading_As'];
              }
              if (res[i] && res[i]['Type.Label']) {
                res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['Type.Label'];
              }
              if (res[i] && res[i]['ID']) {
                res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['ID'];
              }
              if (res[i] && res[i]['Treatment_Amount']) {
                res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['Treatment_Amount'];
              }
              if (res[i] && res[i]['Type.Code']) {
                res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['Type.Code'];
              }
              if (res[i] && res[i]['Contact_CalculatedName']) {
                res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['Contact_CalculatedName'];
              }
              if (res[i] && res[i]['ID']) {
                res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['ID'];
              }

              if (res && res[i] && res[i]['DocumentFKs']) {
                res[i]['DocumentFKs'] = res[i]['DocumentFKs'].split('|');
              } else {
                res[i]['DocumentFKs'] = [];
              }

              if (res && res[i] && res[i]['Tags']) {
                res[i]['Tags'] = res[i]['Tags'].split('|');
                res[i]['Tags'] = _.uniq(res[i]['Tags']);
                this.merchantTags = this.merchantTags.concat(res[i]['Tags']);
              } else {
                res[i]['Tags'] = [];
                this.merchantTags = this.merchantTags.concat(res[i]['Tags']);
              }
              res[i]['isNew'] = this.isNew(res[i]['DateTimeCreated']);
            }

            this.merchantTags = _.uniq(this.merchantTags);
            this.merchantTags = _.orderBy(this.merchantTags);
            this.getMerchantTags.emit(this.merchantTags);
            this.count = res.count;
            this.items = this.items.concat(res);

            this.dataChange.next(this.items.slice());
            if (this.isDestoyed != true) {
              innerFunction(section);
            }
          } else {
            return true;
          }
        });
        UtilsClass.stopLoadingInterceptor();
      };

      if (this.isDestoyed != true) {
        innerFunction(1);
      }
    });
  }

  isNew(date) {
    if (date) {
      const days = this.utils.daysPast(date);

      if (days < 15) {
        return true;
      } else {
        return false;
      }
    }

    return false;
  }

  convertMessage(message = null, renderValue = null) {
    if (message && renderValue) {
      let result;
      result = this.utils.convertMessage(message, renderValue);
      return result;
    }

    return message;
  }

  ngOnInit() {}

  ngOnDestroy() {
    if (this.serviceRef) {
      this.serviceRef.unsubscribe();
    }
  }
}

export class RecordDataSource extends DataSource<any> {
  get filter(): any {
    return this._filterChange.value;
  }

  set filter(item: any) {
    this.field = item.field;
    this._filterChange.next(item.value);
  }

  set removeItem(id) {
    const data = this._tableDatabase.data.filter((row) => row.ID != id);
    this._tableDatabase.dataChange.next(data);
  }

  set addItem(res) {
    const _res = res;
    if (_res && _res.ID) {
      _res['fullName'] = '';
      if (_res && _res['Label']) {
        _res['fullName'] = _res['fullName'] + ',' + _res['Label'];
      }
      if (_res && _res['Description']) {
        _res['fullName'] = _res['fullName'] + ',' + _res['Description'];
      }
      if (_res && _res['Title']) {
        _res['fullName'] = _res['fullName'] + ',' + _res['Title'];
      }
      if (_res && _res['Message']) {
        _res['fullName'] = _res['fullName'] + ',' + _res['Message'];
      }
      if (_res && _res['Theme']) {
        _res['fullName'] = _res['fullName'] + ',' + _res['Theme'];
      }
      if (_res && _res['Merchant_Trading_As']) {
        _res['fullName'] = _res['fullName'] + ',' + _res['Merchant_Trading_As'];
      }
      if (_res && _res && _res['DocumentFKs']) {
        _res['DocumentFKs'] = _res['DocumentFKs'].split('|');
      } else {
        _res['DocumentFKs'] = [];
      }

      _res['isNew'] = this.isNew(_res['DateTimeCreated']);

      if (_res && _res['Tags']) {
        _res['Tags'] = _res['Tags'].split('|');
        _res['Tags'] = uniq(_res['Tags']);
      } else {
        _res['Tags'] = [];
      }
    }
    this._tableDatabase.data.unshift(_res);

    this._tableDatabase.dataChange.next(this._tableDatabase.data);
  }

  set replaceItem(item) {
    const _res = item;
    if (_res && _res.ID) {
      _res['fullName'] = '';
      if (_res && _res['Label']) {
        _res['fullName'] = _res['fullName'] + ',' + _res['Label'];
      }
      if (_res && _res['Description']) {
        _res['fullName'] = _res['fullName'] + ',' + _res['Description'];
      }
      if (_res && _res['Title']) {
        _res['fullName'] = _res['fullName'] + ',' + _res['Title'];
      }
      if (_res && _res['Message']) {
        _res['fullName'] = _res['fullName'] + ',' + _res['Message'];
      }
      if (_res && _res['Theme']) {
        _res['fullName'] = _res['fullName'] + ',' + _res['Theme'];
      }
      if (_res && _res['Merchant_Trading_As']) {
        _res['fullName'] = _res['fullName'] + ',' + _res['Merchant_Trading_As'];
      }
      if (_res && _res && _res['DocumentFKs']) {
        _res['DocumentFKs'] = _res['DocumentFKs'].split('|');
      } else {
        _res['DocumentFKs'] = [];
      }
      _res['isNew'] = this.isNew(_res['DateTimeCreated']);

      if (_res && _res && _res['Tags']) {
        _res['Tags'] = _res['Tags'].split('|');
        _res['Tags'] = uniq(_res['Tags']);
      } else {
        _res['Tags'] = [];
      }
    }
    const _index = this._tableDatabase.data.findIndex((_obj) => _obj.ID == _res.ID);
    this._tableDatabase.data[_index] = _res;
    this._tableDatabase.dataChange.next(this._tableDatabase.data);
  }
  _filterChange = new BehaviorSubject('');
  field = '';

  utils = new UtilsClass();

  filteredData: any[] = [];

  isNew(date) {
    if (date) {
      const days = this.utils.daysPast(date);

      if (days < 15) {
        return true;
      } else {
        return false;
      }
    }

    return false;
  }

  constructor(private _tableDatabase: LoadRecords, private _paginator: MatPaginator, private _sort: MatSort) {
    super();
    this._filterChange.subscribe(() => (this._paginator.pageIndex = 0));
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<any[]> {
    const displayDataChanges = [
      this._tableDatabase.dataChange,
      this._filterChange,
      this._paginator.page,
      this._sort.sortChange,
    ];

    return observableMerge(...displayDataChanges).pipe(
      map(() => {
        this.filteredData = this._tableDatabase.data.slice().filter((item: any) => {
          if (this.field == 'Tags') {
            let f = '';
            f = item[this.field] || '';

            if (this.filter && this.filter.length > 0) {
              for (let j = 0; j < this.filter.length; j++) {
                if (f.indexOf(this.filter[j]) == -1) {
                  return false;
                }
              }
              return true;
            } else {
              return f;
            }
          } else {
            let f = '';
            f = item[this.field] || '';

            const searchStr = f.toLowerCase();

            return searchStr.indexOf(this.filter.toLowerCase()) != -1;
          }
        });
        const data = this.getSortedData(this.filteredData.slice());
        // Grab the page's slice of data.
        const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
        return data.splice(startIndex, this._paginator.pageSize);
      })
    );
  }

  disconnect() {}

  /** Returns a sorted copy of the database data. */
  getSortedData(data: any[]): any[] {
    // const data = data;
    if (!this._sort.active || this._sort.direction == '') {
      return data;
    }

    return data.sort((a, b) => {
      let propertyA: number | string = '';
      let propertyB: number | string = '';

      switch (this._sort.active) {
        case 'Tag':
          [propertyA, propertyB] = [a['Tag'], b['Tag']];
          break;

        case 'Label':
          [propertyA, propertyB] = [a['TemplateName'], b['TemplateName']];
          break;

        case 'Purpose':
          [propertyA, propertyB] = [a['Purpose_Label'], b['Purpose_Label']];
          break;

        case 'Date':
          [propertyA, propertyB] = [moment(a['Date']).toDate().getTime(), moment(b['Date']).toDate().getTime()];
          break;
      }

      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

      return (valueA < valueB ? -1 : 1) * (this._sort.direction == 'asc' ? 1 : -1);
    });
  }
}
