import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatSortModule } from '@angular/material';
import { PinchZoomModule } from 'ngx-pinch-zoom';
import { NgxGaugeModule } from 'ngx-gauge';
import { MarketingFilterRoutingModule } from './shared/marketing-filter-routing.module';
import { NgPipesModule } from 'ngx-pipes';
import { MomentModule } from 'ngx-moment';
import { CdkTableModule } from '@angular/cdk/table';
import { ClipboardModule } from 'ngx-clipboard';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ArchwizardModule } from 'angular-archwizard';
import { SharedModule } from '../../shared/shared.module';
import { HelperModule } from '../../core/helper/helper.module';
import { OperatorModule } from '../operator/operator.module';
import { TagsModule } from '../tags/tags.module';
import { MarketingService } from '../marketing/shared/marketing.service';
import { MarketingFilterCreateComponent } from './marketing-filter-create/marketing-filter-create.component';
import { MarketingFilterCreateSectionComponent } from './marketing-filter-create-section/marketing-filter-create-section.component';
import { MarketingFilterCreatePropertyComponent } from './marketing-filter-create-property/marketing-filter-create-property.component';
import { MarketingFilterListOverViewComponent } from './marketing-filter-list-overview/marketing-filter-list-overview.component';
import { MarketingFilterListGlobalComponent } from './marketing-filter-list-global/marketing-filter-list-global.component';
import { MarketingFilterListComponent } from './marketing-filter-list/marketing-filter-list.component';
import { MarketingFilterViewComponent } from './marketing-filter-view/marketing-filter-view.component';
import { CustomerProspectListComponent } from '../customer-prospect/customer-prospect-list/customer-prospect-list.component';

@NgModule({

  imports: [
    CommonModule,
    NgPipesModule,
    MomentModule,
    CdkTableModule,
    ClipboardModule,
    FormsModule,
    ArchwizardModule,
    ReactiveFormsModule,
    MatSortModule,
    PinchZoomModule,
    NgxGaugeModule,
    MarketingFilterRoutingModule,
    SharedModule,
    HelperModule,
    OperatorModule,
    TagsModule,

  ] ,
  declarations: [
    MarketingFilterCreateComponent,
    MarketingFilterCreateSectionComponent,
    MarketingFilterCreatePropertyComponent,
    MarketingFilterListOverViewComponent,
    MarketingFilterListGlobalComponent,
    MarketingFilterListComponent,
    MarketingFilterViewComponent,
    CustomerProspectListComponent

  ],
  exports :[
    MarketingFilterCreateComponent,
    MarketingFilterCreateSectionComponent,
    MarketingFilterCreatePropertyComponent,
    MarketingFilterListOverViewComponent,
    MarketingFilterListGlobalComponent,
    MarketingFilterListComponent,
    MarketingFilterViewComponent,
    CustomerProspectListComponent

  ],
  providers: [
    MarketingService
  ]
})
export class MarketingFilterModule { }
