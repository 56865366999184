import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProductInvoice } from '../../../shared/services/product-invoice.service';

export interface ViewInvoiceProductModalConfig {
  industryType: string;
  productInvoice: ProductInvoice;
}

@Component({
  selector: 'ipv-view-product-invoice',
  templateUrl: './view-product-invoice.modal.html',
  styleUrls: ['./view-product-invoice.modal.css'],
})
export class ViewInvoiceProductModalComponent {
  industryType = 'dental';
  productInvoice = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ViewInvoiceProductModalConfig,
    private dialogRef: MatDialogRef<ViewInvoiceProductModalComponent>
  ) {
    if (data) {
      console.log(data.productInvoice);
      this.industryType = data.industryType;
      this.productInvoice = data.productInvoice;
    }
  }

  close() {
    this.dialogRef.close();
  }
}
