import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';

import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../authentication/shared/authentication.service';
import { EconnectService } from '../shared/econnect.service';

@Component({
  selector: 'app-econnect-table-view',
  templateUrl: './econnect-table-view.component.html',
  styleUrls: ['./econnect-table-view.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.stacked-card-view', style({ opacity: 0 }), { optional: true }),
        query(
          '.stacked-card-view',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({ opacity: 0, offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.stacked-card-view', style({ opacity: 1 }), { optional: true }),
        query(
          '.stacked-card-view',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({ opacity: 1, offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class EconnectTableViewComponent implements OnInit {
  @Input()
  tableID;

  @Output()
  close = new EventEmitter();
  isPromoterOrAdmin = false;
  table;
  constructor(
    private authenticationServer: AuthenticationService,
    private activeRoute: ActivatedRoute,
    private econnectService: EconnectService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.tableID) {
        this.tableID = data.tableID;
      }

      if (data.table) {
        this.table = data.table;
      }
    }
  }

  ngOnInit() {
    this.authenticationServer.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;

      this.activeRoute.params.subscribe((params) => {
        if (params['tableID']) {
          this.tableID = params['tableID'];
        }

        this.setup();
      });
    });
  }

  setup() {
    if (this.tableID) {
      this.econnectService.getTableDetails(this.tableID).subscribe((res) => {
        if (res) {
          this.table = res;
        }
      });
    }
  }

  closeModal() {
    this.close.emit(true);
  }
}
