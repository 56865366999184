<!-- Header -->
<nav id="header">
  <div class="clearfix">
    <div class="pull-left">
      <!-- Page Title -->
      <h1 class="mat-h1 inline">{{ header || 'Administration Dashboard' }}</h1>
    </div>
    <div class="pull-right">
      <!-- Notifications -->
      <session-compact-menu [displayDashboard]="true" [displayAdmin]="false"></session-compact-menu>
    </div>
  </div>
</nav>
