/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./image-view-list.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i4 from "@angular/material/icon";
import * as i5 from "@angular/common";
import * as i6 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i7 from "@angular/material/button";
import * as i8 from "@angular/cdk/a11y";
import * as i9 from "@angular/platform-browser/animations";
import * as i10 from "@angular/material/tooltip";
import * as i11 from "@angular/cdk/overlay";
import * as i12 from "@angular/cdk/scrolling";
import * as i13 from "@angular/cdk/platform";
import * as i14 from "@angular/cdk/bidi";
import * as i15 from "@angular/platform-browser";
import * as i16 from "../../../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i17 from "@angular/material/card";
import * as i18 from "../ep-document-view-image/ep-document-view-image.component.ngfactory";
import * as i19 from "../ep-document-view-image/shared/ep-document-view-image.service";
import * as i20 from "../ep-document-view-image/ep-document-view-image.component";
import * as i21 from "../../../core/authentication/shared/authentication.service";
import * as i22 from "../../services/utils.service";
import * as i23 from "../empty-list-message/empty-list-message.component.ngfactory";
import * as i24 from "../empty-list-message/empty-list-message.component";
import * as i25 from "./image-view-list.component";
var styles_ImageViewListComponent = [i0.styles];
var RenderType_ImageViewListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ImageViewListComponent, data: { "animation": [{ type: 7, name: "simpleFadeAnimation", definitions: [{ type: 0, name: "in", styles: { type: 6, styles: { opacity: 1, height: "*" }, offset: null }, options: undefined }, { type: 1, expr: ":enter", animation: [{ type: 6, styles: { opacity: 0, height: 0 }, offset: null }, { type: 4, styles: null, timings: 300 }], options: null }, { type: 1, expr: ":leave", animation: { type: 4, styles: { type: 6, styles: { opacity: 0, height: 0 }, offset: null }, timings: 300 }, options: null }], options: {} }] } });
export { RenderType_ImageViewListComponent as RenderType_ImageViewListComponent };
function View_ImageViewListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "row clearfix cardheader primary-gradient-img inModal"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "button", [["class", "btn-close btn-clear white pull-right mat-button"], ["mat-dialog-close", ""]], [[1, "aria-label", 0], [1, "type", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).dialogRef.close(i1.ɵnov(_v, 2).dialogResult) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.closeEvent() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i3.View_MatIcon_0, i3.RenderType_MatIcon)), i1.ɵdid(4, 9158656, null, 0, i4.MatIcon, [i1.ElementRef, i4.MatIconRegistry, [8, null], [2, i4.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["clear"])), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "row titleArea clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 3, "h2", [["class", "summary-header rm-m white"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, [" ", " "])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [["class", "subLabel"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""]))], function (_ck, _v) { var currVal_2 = ""; _ck(_v, 2, 0, currVal_2); _ck(_v, 4, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵnov(_v, 2).ariaLabel || null); var currVal_1 = i1.ɵnov(_v, 2).type; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵnov(_v, 4).inline; var currVal_4 = (((i1.ɵnov(_v, 4).color !== "primary") && (i1.ɵnov(_v, 4).color !== "accent")) && (i1.ɵnov(_v, 4).color !== "warn")); _ck(_v, 3, 0, currVal_3, currVal_4); var currVal_5 = _co.title; _ck(_v, 8, 0, currVal_5); var currVal_6 = _co.description; _ck(_v, 10, 0, currVal_6); }); }
function View_ImageViewListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["class", "detail-p"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.description; _ck(_v, 1, 0, currVal_0); }); }
function View_ImageViewListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "row clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "pull-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "label", [["class", "uploaded"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImageViewListComponent_3)), i1.ɵdid(5, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.description; _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 3, 0, currVal_0); }); }
function View_ImageViewListComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 3, "button", [["class", "download-image-btn"], ["color", "accent"], ["mat-raised-button", ""], ["matTooltip", "Download image"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"], [null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } if (("click" === en)) {
        var pd_3 = (_co.downloadImage(_v.parent.context.$implicit) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i6.View_MatButton_0, i6.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i7.MatButton, [i1.ElementRef, i8.FocusMonitor, [2, i9.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), i1.ɵdid(2, 212992, null, 0, i10.MatTooltip, [i11.Overlay, i1.ElementRef, i12.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i13.Platform, i8.AriaDescriber, i8.FocusMonitor, i10.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i14.Directionality], [2, i10.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i15.HAMMER_LOADER]], { message: [0, "message"] }, null), (_l()(), i1.ɵted(-1, 0, [" Download "])), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_2 = "accent"; _ck(_v, 1, 0, currVal_2); var currVal_3 = "Download image"; _ck(_v, 2, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_ImageViewListComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "mat-card", [["class", "text-center mat-card"]], [[24, "@simpleFadeAnimation", 0], [2, "_mat-animation-noopable", null]], null, null, i16.View_MatCard_0, i16.RenderType_MatCard)), i1.ɵdid(2, 49152, null, 0, i17.MatCard, [[2, i9.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(3, 0, null, 0, 4, "div", [["class", "rel mat-card-image"], ["mat-card-image", ""]], null, null, null, null, null)), i1.ɵdid(4, 16384, null, 0, i17.MatCardImage, [], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "app-ep-document-view-image", [["class", "buttonImage"]], null, [[null, "getClick"]], function (_v, en, $event) { var ad = true; if (("getClick" === en)) {
        var pd_0 = ((_v.context.$implicit.checked = !_v.context.$implicit.checked) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i18.View_EpDocumentViewImageComponent_0, i18.RenderType_EpDocumentViewImageComponent)), i1.ɵprd(131584, null, i19.EpDocumentViewImageService, i19.EpDocumentViewImageService, []), i1.ɵdid(7, 770048, null, 0, i20.EpDocumentViewImageComponent, [i21.AuthenticationService, i2.MatDialog, i15.DomSanitizer, [2, i2.MAT_DIALOG_DATA], i1.NgZone, i22.UtilsService, i19.EpDocumentViewImageService], { notChecked: [0, "notChecked"], imageLink: [1, "imageLink"] }, { getClick: "getClick" }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ImageViewListComponent_6)), i1.ɵdid(9, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = !_v.context.$implicit.checked; var currVal_3 = _v.context.$implicit.link; _ck(_v, 7, 0, currVal_2, currVal_3); var currVal_4 = _co.canDownload; _ck(_v, 9, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = undefined; var currVal_1 = (i1.ɵnov(_v, 2)._animationMode === "NoopAnimations"); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_ImageViewListComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "row clearfix thumb-view document-thumbnail-view"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "flex clearfix full-width uploadedContainer"]], [[2, "justify-left", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImageViewListComponent_5)), i1.ɵdid(3, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co._imageLinks; _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.justify == "left"); _ck(_v, 1, 0, currVal_0); }); }
function View_ImageViewListComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-empty-list-message", [], null, null, null, i23.View_EmptyListMessageComponent_0, i23.RenderType_EmptyListMessageComponent)), i1.ɵdid(1, 114688, null, 0, i24.EmptyListMessageComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_ImageViewListComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "mat-dialog-actions", [["class", "mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "clearfix full-width text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "button", [["class", "mr"], ["color", "primary"], ["mat-raised-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeEvent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_MatButton_0, i6.RenderType_MatButton)), i1.ɵdid(4, 180224, null, 0, i7.MatButton, [i1.ElementRef, i8.FocusMonitor, [2, i9.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["Close"]))], function (_ck, _v) { var currVal_2 = "primary"; _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 4).disabled || null); var currVal_1 = (i1.ɵnov(_v, 4)._animationMode === "NoopAnimations"); _ck(_v, 3, 0, currVal_0, currVal_1); }); }
export function View_ImageViewListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "mat-card", [["class", "mat-card"], ["style", "padding-bottom: 0px"]], [[2, "_mat-animation-noopable", null]], null, null, i16.View_MatCard_0, i16.RenderType_MatCard)), i1.ɵdid(1, 49152, null, 0, i17.MatCard, [[2, i9.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ImageViewListComponent_1)), i1.ɵdid(3, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ImageViewListComponent_2)), i1.ɵdid(5, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, 0, 4, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(7, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImageViewListComponent_4)), i1.ɵdid(9, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["emptyRecord", 2]], null, 0, null, View_ImageViewListComponent_7)), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ImageViewListComponent_8)), i1.ɵdid(12, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.isModal; _ck(_v, 3, 0, currVal_1); var currVal_2 = !_co.isModal; _ck(_v, 5, 0, currVal_2); var currVal_3 = (_co.imageLinks && (_co.imageLinks.length > 0)); var currVal_4 = i1.ɵnov(_v, 10); _ck(_v, 9, 0, currVal_3, currVal_4); var currVal_5 = (_co.isModal || _co.canAction); _ck(_v, 12, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0); }); }
export function View_ImageViewListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-image-view-list", [], null, null, null, View_ImageViewListComponent_0, RenderType_ImageViewListComponent)), i1.ɵdid(1, 638976, null, 0, i25.ImageViewListComponent, [i2.MatDialog, i21.AuthenticationService, [2, i2.MAT_DIALOG_DATA]], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ImageViewListComponentNgFactory = i1.ɵccf("app-image-view-list", i25.ImageViewListComponent, View_ImageViewListComponent_Host_0, { imageLinks: "imageLinks", canDownload: "canDownload", canAction: "canAction", canCheck: "canCheck", canMultipleDowload: "canMultipleDowload", canMultipleAction: "canMultipleAction", actionButtonLabel: "actionButtonLabel", multipleActionButtonLabel: "multipleActionButtonLabel", title: "title", description: "description" }, { sendAction: "sendAction", sendMultipleActions: "sendMultipleActions", sendSelectedImages: "sendSelectedImages", closeModal: "closeModal" }, []);
export { ImageViewListComponentNgFactory as ImageViewListComponentNgFactory };
