import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSortModule } from '@angular/material/sort';
import { NgPipesModule } from 'ngx-pipes';
import { SharedModule } from '../../shared/shared.module';
import { OperatorModule } from '../operator/operator.module';
import { TasksRoutingModule } from './shared/tasks-routing.module';
import { TasksService } from './shared/tasks.service';
import { TaskManagerCreateEditComponent } from './task-manager-create-edit/task-manager-create-edit.component';
import { TaskManagerListComponent } from './task-manager-list/task-manager-list.component';
import { TaskManagerViewComponent } from './task-manager-view/task-manager-view.component';
import { TasksCreateComponent } from './tasks-create/tasks-create.component';
import { TasksListOverviewComponent } from './tasks-list-overview/tasks-list-overview.component';
import { TasksListComponent } from './tasks-list/tasks-list.component';
import { TasksSummaryListComponent } from './tasks-summary-list/tasks-summary-list.component';
import { TasksViewComponent } from './tasks-view/tasks-view.component';
import { TastManagerPictureComponent } from './tast-manager-picture/tast-manager-picture.component';

@NgModule({
  imports: [
    CommonModule,
    TasksRoutingModule,
    CdkTableModule,
    SharedModule,
    MatSortModule,
    NgPipesModule,
    OperatorModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  declarations: [
    TasksCreateComponent,
    TasksViewComponent,
    TasksListComponent,
    TasksListOverviewComponent,
    TasksSummaryListComponent,
    TaskManagerCreateEditComponent,
    TaskManagerListComponent,
    TastManagerPictureComponent,
    TaskManagerViewComponent,
  ],
  exports: [
    TasksCreateComponent,
    TasksViewComponent,
    TasksListComponent,
    TasksListOverviewComponent,
    TasksSummaryListComponent,
    TaskManagerCreateEditComponent,
    TaskManagerListComponent,
    TastManagerPictureComponent,
    TaskManagerViewComponent,
  ],
  providers: [TasksService],
})
export class TasksModule {}
