import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output, SimpleChanges } from '@angular/core';

import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TemplateService } from '../shared/template-service';

@Component({
  selector: 'app-template-picture-view',
  templateUrl: './template-picture-view.component.html',
  styleUrls: ['./template-picture-view.component.css'],
})
export class TemplatePictureViewComponent implements OnInit {
  @Input() templateID;
  @Input() isPhysicalTemplate = false;

  @Input() hideZoomButton = false;
  @Output() close = new EventEmitter();
  @Input()
  LastModified = null;

  profileLink;

  constructor(
    private templateService: TemplateService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      this.templateID = data;
    }
  }

  ngOnInit() {
    this.getPicture();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.templateID && changes.templateID.previousValue != changes.templateID.currentValue) {
      if (this.templateID) {
        this.profileLink = null;
        if (this.isPhysicalTemplate == true) {
          this.profileLink = this.templateService.getPhysicalTemplatePictureStreamLink(this.templateID);
        } else {
          this.profileLink = this.templateService.getTemplatePictureStreamLink(this.templateID, this.LastModified);
        }
      } else {
        this.profileLink = null;
      }
    }
  }

  getPicture() {
    this.profileLink = null;

    if (this.templateID) {
      this.profileLink = this.templateService.getTemplatePictureStreamLink(this.templateID, this.LastModified);
    }
  }

  closeEvent() {
    this.close.emit(true);
  }
}
