import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-referral-confirm-modal',
  templateUrl: './referral-confirm-modal.component.html',
  styleUrls: ['./referral-confirm-modal.component.css'],
})
export class ReferralConfirmModalComponent implements OnInit {
  isAgree = false;

  constructor() {}

  ngOnInit() {}
}
