import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CreditCardValidator } from 'angular-cc-library';
import { UtilsService } from '../../services/utils.service';

@Component({
  selector: 'app-credit-card-input',
  templateUrl: './credit-card-input.component.html',
  styleUrls: ['./credit-card-input.component.css'],
})
export class CreditCardInputComponent implements OnInit {
  title = 'Credit card details';
  showCvvInfo = false;

  @Input()
  cardNumber;

  @Input()
  cardName;

  @Input()
  cardExpireDate;

  @Input()
  cardCVS;

  @Input()
  accountType;

  accountTypes = [];

  @Input()
  isRequired = true;
  isValidate = false;

  @Output()
  validate = new EventEmitter();

  @Output()
  getCreditCard = new EventEmitter();

  formBuilder = new FormBuilder();
  paymentForm: FormGroup;

  requiredLabel = 'required';

  constructor(private utilService: UtilsService) {}

  ngOnInit() {
    if (this.isRequired) {
      this.paymentForm = this.formBuilder.group({
        cardNumber: ['', [CreditCardValidator.validateCCNumber, Validators.required]],
        cardExpireDate: ['', [CreditCardValidator.validateExpDate, Validators.required]],
        cardCVS: ['', [Validators.required, Validators.minLength(3) as any, Validators.maxLength(4) as any]],

        cardName: ['', [Validators.required, Validators.compose([Validators.minLength(2)])]],
        accountTypes: ['', [Validators.required]],
      });
      this.utilService.getCodeLookup('BankAccountType').subscribe((res) => {
        if (res && res.length > 0) {
          this.accountTypes = res;
        }

        this.sendResult();
      });
    } else {
      this.requiredLabel = null;

      this.paymentForm = this.formBuilder.group({
        cardNumber: ['', [CreditCardValidator.validateCCNumber]],
        cardExpireDate: ['', [CreditCardValidator.validateExpDate]],
        cardCVS: ['', [Validators.minLength(3) as any, Validators.maxLength(4) as any]],
        cardName: ['', Validators.compose([Validators.minLength(2)])],
        accountTypes: ['', []],
      });

      this.sendResult();
    }
  }

  sendResult() {
    const creditCard = {
      paymentType: 'creditCard',
      cardNumber: this.paymentForm.controls['cardNumber'].value,
      cardName: this.paymentForm.controls['cardName'].value,
      cardExpireDate: this.paymentForm.controls['cardExpireDate'].value,
      cardCVS: this.paymentForm.controls['cardCVS'].value,
      // accountType: this.paymentForm.controls['accountType'].value
      accountType: null,
    };

    this.getCreditCard.emit(creditCard);
    this.validateField();
  }

  validateField() {
    this.isValidate = this.paymentForm.valid;

    this.validate.emit(this.isValidate);
  }

  changeShowInfo() {
    this.showCvvInfo = !this.showCvvInfo;
  }
}
