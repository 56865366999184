import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DrugsCreateEditComponent } from '../drugs-create-edit/drugs-create-edit.component';
import { DrugsImageComponent } from '../drugs-image/drugs-image.component';
import { DrugsThumbnailComponent } from '../drugs-thumbnail/drugs-thumbnail.component';
import { DrugsCreateEditCustomerComponent } from '../drugs-create-edit-customer/drugs-create-edit-customer.component';
import { DrugsViewComponent } from '../drugs-view/drugs-view.component';
import { MedicationCreateCustomerComponent } from '../medication-create-customer/medication-create-customer.component';
import { DrugLookupComponent } from '../drug-lookup/drug-lookup.component';
import { MedicationViewComponent } from '../medication-view/medication-view.component';

const routes: Routes = [
  {
    path: 'DrugsViewComponent',
    component: DrugsViewComponent
  },


  {
    path: 'MedicationViewComponent',
    component: MedicationViewComponent
  },
  {
    path: 'DrugLookupComponent',
    component: DrugLookupComponent
  },
  {
    path: 'MedicationCreateCustomerComponent',
    component: MedicationCreateCustomerComponent
  },

  {
    path: 'DrugsCreateEditCustomerComponent',
    component: DrugsCreateEditCustomerComponent
  },
  {
    path: 'DrugsCreateEditComponent',
    component: DrugsCreateEditComponent
  },
  {
    path: 'DrugsThumbnailComponent',
    component: DrugsThumbnailComponent
  },
  {
    path: 'DrugsImageComponent',
    component: DrugsImageComponent
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DrugsRoutingModule { }
