import { Component, Input, OnInit } from '@angular/core';
import { DentalXrayScanDetectionItem } from '../../../feature/ai-scans/shared/types/dental-xray.type';
import { TeethShape } from '../../../feature/treatment/shared/teeth-shape';
import { RootAppComponent } from '../root-component/root-component.component';
import { ToothDetectionDialogComponent } from './components/tooth-detection-dialog/tooth-detection-dialog.component';

@Component({
  selector: 'sr-tooth-detections',
  templateUrl: './tooth-detections.component.html',
  styleUrls: ['./tooth-detections.component.css'],
})
export class ToothDetectionsComponent implements OnInit {
  @Input() tooth: {
    toothNumber: number;
    hideNumber: boolean;
    apical: DentalXrayScanDetectionItem[];
    caries: DentalXrayScanDetectionItem[];
    restorations: DentalXrayScanDetectionItem[];
  };
  @Input() isAdult: boolean;
  @Input() selected = true;
  toothShape: any;

  constructor() {}

  ngOnInit() {
    this.toothShape = TeethShape.getToothShape(this.tooth.toothNumber, this.isAdult);
  }

  openToothDetectionDialog(tooth: {
    toothNumber: number;
    hideNumber: boolean;
    apical: DentalXrayScanDetectionItem[];
    caries: DentalXrayScanDetectionItem[];
    restorations: DentalXrayScanDetectionItem[];
  }) {
    const toothDetectionsDialogRef = RootAppComponent.dialog.open(ToothDetectionDialogComponent);

    toothDetectionsDialogRef.componentInstance.toothName = tooth.toothNumber;
    toothDetectionsDialogRef.componentInstance.apicalDetections = tooth.apical;
    toothDetectionsDialogRef.componentInstance.cariesDetections = tooth.caries;
    toothDetectionsDialogRef.componentInstance.restorationsDetection = tooth.restorations;
  }
}
