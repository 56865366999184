<mat-sidenav-container class="wiki-container">
  <mat-sidenav class="wiki-sidenav" #sidenav mode="{{ navMode }}" opened="true">
    <wiki-left-menu [WikiTitle]="'Help Centre'"> </wiki-left-menu>
  </mat-sidenav>

  <div class="body-container">
    <wiki-header> </wiki-header>

    <div class="body-container-content">
      <button
        class="btn-clear menu-trigger"
        (click)="sidenav.open()"
        style="width: 100px; position: fixed; top: 20px"
        mat-button
      >
        <mat-icon>menu</mat-icon>
      </button>

      <router-outlet name="wikiPageRoute"></router-outlet>
    </div>

    <wiki-footer> </wiki-footer>
  </div>
</mat-sidenav-container>
