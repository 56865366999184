<div class="rel" [cdkDragDisabled]="!enableDragging" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <div class="card-header primary-gradient-img clearfix inModal" [ngClass]="classNames" style="margin-bottom: 0px">
    <div class="row clearfix">
      <ng-content select="[banner]"></ng-content>
    </div>
  </div>

  <mat-dialog-content>
    <ng-content select="[content]"></ng-content>
  </mat-dialog-content>
</div>
