<div class="thrdPtyModal">
  <div class="card-header primary-gradient-img clearfix inModal">
    <div class="row clearfix">
      <!-- merchant calculated name -->
      <div class="row text-left clearfix">
        <!-- Modal dismiss button -->
        <button class="btn-close btn-clear mat-button white" (click)="close()">
          <mat-icon>clear</mat-icon>
        </button>
        <h2 class="summary-header rm-m">
          Settle to Third Party Supplier
          <span class="subLabel">Have {{ "BRANDING.Brand Name" | translate | titlecase }} dispurse funds to your chosen
            third
            parties</span>
        </h2>
      </div>
    </div>
  </div>
</div>

<mat-dialog-content>
  <!-- <div class=" full-width row clearfix"> -->
  <!-- <p class="larger rm-mt">Get   {{"BRANDING.Brand Name" | translate}} to settle direct to an approved third party supplier.</p> -->
  <!-- <p>Use this dialogue to instruct   {{"BRANDING.Brand Name" | translate}} to settle a payment to a third party supplier of treatment.</p> -->
  <!-- </div> -->
  <div class="full-width row clearfix">
    <!-- <div *ngFor="let supplier of multipleSuppliers; let i = index;">

          <div class="row clearfix">
            <div class="half-width">
              <mat-select name="selectFund" placeholder="{{'Select supplier' | translate }}" class="full-width adjustSelect" required [(ngModel)]="supplier.name">
                <mat-option *ngFor="let thirdparty of thirdpartyList" [value]="thirdparty">
                  {{thirdparty}}
                </mat-option>
              </mat-select>

            </div>
            <div class="half-width">
              <div class="col-right">
                <mat-form-field class="full-width anim" [ngClass]="{'ng-invalid mat-form-field-invalid mat-form-field-invalid' : (toNumber(supplier.amount) > toNumber(maxAmount))}">
                  <span matPrefix>$ &nbsp;</span>
                  <input type="number" matInput name="offsetAmount" placeholder="Maximum amount {{maxAmount | customCurrency}}"
                    required [(ngModel)]="supplier.amount" (input)="valuechange($event)">
                  <mat-hint [hidden]="(toNumber(supplier.amount) <= toNumber(maxAmount))" [ngStyle]="{'color':'#ce066b'}">Amount should be less than {{maxAmount | customCurrency}}</mat-hint>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="row flex clearfix">

              <mat-form-field class="full-width anim">
                <input type="text" matInput name="bsb" placeholder="{{'BSB' | translate}}"
                  required [(ngModel)]="supplier.bsbNumber" readonly class="mat-foucs">
              </mat-form-field>

              <mat-form-field class="full-width anim">
                <input type="text" matInput name="account" placeholder="{{'Account Number' | translate}}"
                  required [(ngModel)]="supplier.accountNumber" readonly>
              </mat-form-field>

          </div>
          <div class="row clearfix text-right">
            <button mat-mini-fab color="warn" class="small" mat-TooltipPosition="left" mdTooltip="Remove payment" (click)="removeSupplier(i)" *ngIf="multipleSuppliers.length > 1"><mat-icon>block</mat-icon></button>
            <button mat-mini-fab color="accent" class="small add-ml" mdTooltipPosition="left" mdTooltip="Add another third party payment" (click)="addSupplier()"><mat-icon>add</mat-icon></button>
          </div> -->

    <mat-form-field class="full-width clearfix" appearance="outline">
      <mat-label>Beneficiary</mat-label>
      <mat-select class="full-width clearfix" [(ngModel)]="beneficiary" (selectionChange)="beneficiaryChange()"
                  name="Beneficiary" required>
        <mat-option *ngFor="let b of beneficiaries" [value]="b">
          {{ b.Label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="full-width mt row clearfix">
    <app-bank-account-list-user class="full-width clearfix" *ngIf="beneficiaryID" [isActive]="true"
                                [userID]="beneficiaryID" [bankAccountID]="bankAccountID" [canEdit]="false"
                                [isActionButton]="false"
                                [isAction]="true" [title]="bankAccountListTitle" [embedded]="true"
                                [description]="bankAccountListDescription"
                                (getAction)="getActionEvent($event)"></app-bank-account-list-user>
  </div>

  <div class="row clearfix" *ngIf="beneficiary && bankAccount">
    <mat-form-field class="full-width clearfix anim" appearance="outline">
      <mat-label>Enter payment amount (maximum: {{ maxAmount | customCurrency }})</mat-label>
      <span class="inputPrefix" matPrefix>$ &nbsp;</span>
      <input [(ngModel)]="amount" type="number" matInput name="offsetAmount"
             placeholder="Enter payment amount (maximum: {{ maxAmount | customCurrency }})" required/>
      <mat-hint [hidden]="toNumber(amount) <= toNumber(maxAmount)" [ngStyle]="{ color: '#ce066b' }">Amount should be
        less than {{ maxAmount | customCurrency }}</mat-hint>
    </mat-form-field>

    <p class="clearfix" mdLine>
      <span class="">Total amount settle to third party supplier(s):</span>
      <span class="pull-right" [ngStyle]="{ color: toNumber(amount) > toNumber(maxAmount) ? '#ce066b' : '#1ca90c' }">{{
        amount | customCurrency
        }}</span>
    </p>
  </div>
  <div class="row full-width clearfix" *ngIf="beneficiary && bankAccount">
    <h4 class="sr-title rm-mb">What happens next?</h4>
    <p class="small rm-m">Once approved, the following will occur:</p>
    <ul>
      <li>
        <p class="small rm-m">
          The amount you can claim from {{ "BRANDING.Brand Name" | translate | titlecase }} will reduce by
          <span *ngIf="!amount">the amount you set</span><span *ngIf="amount" [ngStyle]="{ color: '#1ca90c' }">{{ amount
          | customCurrency }}</span>.
        </p>
      </li>
      <li>
        <p class="small rm-m">
          We will pay direct to <span [ngStyle]="{ color: '#1ca90c' }">{{ beneficiary.Label || '...' }}</span>.
        </p>
      </li>
      <li>
        <p class="small rm-m">
          We will notify <span [ngStyle]="{ color: '#1ca90c' }">{{ beneficiary.Label || '...' }}, </span> of this via
          email on your behalf.
        </p>
      </li>
    </ul>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="justify-right">
  <button (click)="close()" mat-raised-button color="primary">Cancel</button>
  <button [disabled]="
      toNumber(amount) > toNumber(maxAmount) ||
      amount <= 0 ||
      !beneficiary ||
      !beneficiaryID ||
      !bankAccount ||
      !bankAccountID
    " (click)="sendFundAmountEvent()" mat-raised-button color="accent">
    Confirm request
  </button>
</mat-dialog-actions>
