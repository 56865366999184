import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { BeneficiaryViewComponent } from '../beneficiary-view/beneficiary-view.component';
import { BeneficiaryService } from '../shared/beneficiary.service';

@Component({
  selector: 'app-beneficiary-subsidiary-list',
  templateUrl: './beneficiary-subsidiary-list.component.html',
  styleUrls: ['./beneficiary-subsidiary-list.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.card-view', style({ opacity: 0 }), { optional: true }),
        query(
          '.card-view',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({ opacity: 0, offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.card-view', style({ opacity: 1 }), { optional: true }),
        query(
          '.card-view',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({ opacity: 1, offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class BeneficiarySubsidiaryListViewComponent implements OnInit {
  @Input()
  beneficiaryID;

  beneficiary;

  isPromoterOrAdmin = false;
  isModal = false;

  subsidiaryArray;

  @Output()
  getLength = new EventEmitter();

  constructor(
    private beneficiaryService: BeneficiaryService,
    private authenticationService: AuthenticationService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data && typeof data == 'string') {
      this.beneficiaryID = data;
      this.isModal = true;
    }
  }

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      if (params['beneficiaryID']) {
        this.beneficiaryID = params['parentID'];
      }

      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isPromoterOrAdmin = res;

        if (this.beneficiaryID) {
          this.beneficiaryService
            .getBeneficiaryDetails(this.beneficiaryID, null, this.isPromoterOrAdmin)
            .subscribe((res) => {
              if (res) {
                this.beneficiary = res;

                const payload2 = {
                  parentID: this.beneficiary['ID'],
                  orderBy: 'CalculatedName',
                  fields:
                    'ID,addresses.Calculated,addresses.State,addresses.Suburb,emails.Email,Phone.Work.Number,Name,ParentOrganisation.Name,ParentOrganisation_key',
                };

                this.beneficiaryService.getBeneficiaryList(payload2, this.isPromoterOrAdmin).subscribe((res) => {
                  if (res && res.length && res.length > 0) {
                    this.subsidiaryArray = res;

                    this.getLength.emit(this.subsidiaryArray.length);
                  }
                });
              }
            });
        }
      });
    });
  }

  // open view subsidiary modal
  viewSubsid(ID) {
    const ref = RootAppComponent.dialog.open(BeneficiaryViewComponent, {
      data: ID,
      width: '650px',
      panelClass: 'noCard',
    });

    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  createSubsidiaries() {
    this.router.navigate([
      '/merchant',
      { outlets: { page: ['subsidiary-create', this.beneficiary['ID'], this.beneficiary['Type_Code']] } },
    ]);
  }

  editSubsidiary(ID) {
    this.router.navigate(['/merchant', { outlets: { page: ['subsidiary-edit', ID] } }]);
  }

  dissociateSubsidiary(item) {
    const confirmDialog = new ConfirmDialog(
      'warning',
      'Are you sure?',
      'You are about to dissociate ' + item['Name'] + ' from ' + item['ParentOrganisation.Name'],
      'Cancel',
      'Proceed'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
    });

    ref.componentInstance.onConfirm.subscribe((res) => {
      if (res == true) {
        const payload = {
          id: item['ID'],
          parentID: 'none',
        };

        this.beneficiaryService.editBeneficiary(item['ID'], payload).subscribe((res) => {
          if (res) {
            NotifyAppComponent.displayToast(
              'success',
              'Success!',
              item['Name'] + ' has been dissociate from ' + item['ParentOrganisation.Name']
            );

            this.subsidiaryArray.splice(
              this.subsidiaryArray.indexOf(this.subsidiaryArray.findIndex((x) => x.ID == item['ID'])),
              1
            );

            ref.close();
          }
        });
      } else {
        ref.close();
      }
    });
  }
}
