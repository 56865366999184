/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./existing-patient-summary.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../existing-patient-view-details/existing-patient-view-details.component.ngfactory";
import * as i3 from "../existing-patient-view-details/existing-patient-view-details.component";
import * as i4 from "@angular/common";
import * as i5 from "../../../core/authentication/shared/authentication.service";
import * as i6 from "../shared/existing-patient.service";
import * as i7 from "@angular/material/dialog";
import * as i8 from "./existing-patient-summary.component";
var styles_ExistingPatientSummaryComponent = [i0.styles];
var RenderType_ExistingPatientSummaryComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ExistingPatientSummaryComponent, data: {} });
export { RenderType_ExistingPatientSummaryComponent as RenderType_ExistingPatientSummaryComponent };
export function View_ExistingPatientSummaryComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "rel"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-existing-patient-view-details", [], null, null, null, i2.View_ExistingPatientViewDetailsComponent_0, i2.RenderType_ExistingPatientViewDetailsComponent)), i1.ɵdid(2, 114688, null, 0, i3.ExistingPatientViewDetailsComponent, [i4.Location, i5.AuthenticationService, i6.ExistingPatientService, [2, i7.MAT_DIALOG_DATA]], { existingPatientID: [0, "existingPatientID"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.existingPatientID; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ExistingPatientSummaryComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-existing-patient-summary", [], null, null, null, View_ExistingPatientSummaryComponent_0, RenderType_ExistingPatientSummaryComponent)), i1.ɵdid(1, 114688, null, 0, i8.ExistingPatientSummaryComponent, [[2, i7.MAT_DIALOG_DATA]], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ExistingPatientSummaryComponentNgFactory = i1.ɵccf("app-existing-patient-summary", i8.ExistingPatientSummaryComponent, View_ExistingPatientSummaryComponent_Host_0, { existingPatientID: "existingPatientID" }, {}, []);
export { ExistingPatientSummaryComponentNgFactory as ExistingPatientSummaryComponentNgFactory };
