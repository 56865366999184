import { Component, EventEmitter, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { EditorComponentService } from '../shared/editor-component.service';

@Component({
  selector: 'app-editor-component-view',
  templateUrl: './editor-component-view.component.html',
  styleUrls: ['./editor-component-view.component.css'],
})
export class EditorComponentViewComponent implements OnInit {
  title;
  description;

  style;
  content;
  preview;

  editorComponent;
  editorComponentID;
  sessionType;
  close = new EventEmitter();

  isLoaded = false;

  utils = new UtilsClass();

  constructor(
    private authenticationService: AuthenticationService,
    private editorComponentService: EditorComponentService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.editorComponentID != null) {
        this.editorComponentID = data.editorComponentID;
      }
    }
  }

  ngOnInit() {
    if (this.editorComponentID) {
      this.authenticationService.getSessionType().subscribe((r) => {
        this.sessionType = r;
        this.editorComponentService
          .getEditorComponentDetails(
            this.editorComponentID,
            {
              fields:
                'ID,DateTimeCreated,Merchant_key,Merchant_Trading_As,Label,Description,Is_Default,Type.Code,Type.Label,Nature.Code,Nature.Label,Is_Active,Style,Content',
            },
            this.sessionType
          )
          .subscribe((res) => {
            if (res && res.ID) {
              this.editorComponent = res;
              this.buildItem();
            }
          });
      });
    }
  }

  buildItem() {
    if (this.editorComponent && this.editorComponent.ID) {
      if (this.editorComponent.Label) {
        this.title = this.editorComponent.Label;
      }

      if (this.editorComponent.Description) {
        this.description = this.editorComponent.Description;
      }

      if (this.editorComponent.Style) {
        this.style = this.editorComponent.Style;
      }

      if (this.editorComponent.Content) {
        this.content = this.editorComponent.Content;
      }

      if (this.content && this.style) {
        let componentID = null;

        if (this.editorComponent['Nature.Code'] == 'Template') {
          componentID = this.editorComponent.ID;
        }

        this.preview = this.utils.getPreviewHtmlContent(this.content, this.style, componentID);
      } else if (this.content) {
        this.preview = this.content;
      }
    }

    this.isLoaded = true;
  }

  closeEvent() {
    this.close.emit(true);
  }
}
