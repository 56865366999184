import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { LookupService } from '../../../shared/services/lookup.service';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { InvitationLookupComponent } from '../../invitation/invitation-lookup/invitation-lookup.component';
import { SmsConversationComponent } from '../../message/sms-conversation/sms-conversation.component';
import { SmsPromoterComponent } from '../../message/sms-promoter/sms-promoter.component';

@Component({
  selector: 'app-patient-create-personal',
  templateUrl: './patient-create-personal.component.html',
  styleUrls: ['./patient-create-personal.component.css'],
})
export class PatientCreatePersonalComponent implements OnInit {
  @Input()
  invitationID = null;

  @Input()
  merchantID;

  @Input()
  displayLookup = true;

  @Input()
  isAction = false;

  @Input()
  actionLabel;

  @Input()
  actionIcon = 'delete';

  @Output()
  getAction = new EventEmitter();

  @Input()
  isRequired = true;

  @Input()
  isAction2 = false;

  @Input()
  action2Label;

  @Input()
  action2Icon = 'info';

  @Output()
  getAction2 = new EventEmitter();

  @Output()
  getResult = new EventEmitter();

  @Output()
  validate = new EventEmitter();

  isValidate = false;

  isPromoterOrAdmin;

  utilsClass = new UtilsClass();

  closeModal = new EventEmitter();

  @Input()
  user = {
    firstName: null,
    title: null,
    lastName: null,
    middleName: null,
    gender: null,
    mobile: null,
    homePhone: null,
    workPhone: null,
    email: null,
    existingPersonID: null,
    validate: false,
  };

  requiredLabel = 'required';

  validateHomePhone = false;
  validateWorkPhone = false;
  validateEmail = false;
  validateMobile = false;

  genders = [];
  titles = [];

  constructor(
    private lookupService: LookupService,
    private AuthenticationService: AuthenticationService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
    }
  }

  ngOnInit() {
    if (this.isRequired == false) {
      this.requiredLabel = null;
    }

    this.AuthenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;

      this.lookupService.getLookup('CodeLookup', 'Gender').subscribe((data) => {
        this.genders = data;
      });
      this.lookupService.getLookup('SimpleLookup', 'Salutation').subscribe((data) => {
        this.titles = data;
      });
    });
  }

  openPatientLookUpDialog() {
    const data = {};

    if (this.invitationID) {
      data['invitationID'] = this.invitationID;
    }

    if (this.merchantID && this.isPromoterOrAdmin) {
      data['merchantID'] = this.merchantID;
    }

    data['saveUserData'] = false;
    data['useStrict'] = true;

    const ref = RootAppComponent.dialog.open(InvitationLookupComponent, {
      data,
      width: '600px',
    });

    ref.componentInstance.patientChat.subscribe((res) => {
      if (res && res.cardID) {
        if (res.isPromoterOrAdmin == true) {
          const ref2 = RootAppComponent.dialog.open(SmsPromoterComponent, {
            data: {
              merchantID: res.merchantID,
              cardID: res.cardID,
              singleChat: true,
            },
            panelClass: 'noCard',
            width: '500px',
          });
          ref2.componentInstance.close.subscribe((data) => {
            ref2.close();
          });

          ref2.componentInstance.getResult.subscribe((data) => {
            if (data) {
              const ref3 = RootAppComponent.dialog.open(SmsConversationComponent, {
                data,
                panelClass: 'bigger-screen',
                width: '70%',
                height: '95vh',
              });
              const sub = ref3.componentInstance.close.subscribe((data) => {
                ref3.close();
              });
            }

            ref2.close();
          });
        } else {
          const ref2 = RootAppComponent.dialog.open(SmsConversationComponent, {
            data: {
              merchantID: res.merchantID,
              cardID: res.cardID,
              singleChat: true,
            },
            panelClass: 'bigger-screen',
            width: '70%',
            height: '95vh',
          });
          const sub = ref2.componentInstance.close.subscribe((data) => {
            ref2.close();
          });
        }
      }
    });
    ref.componentInstance.close.subscribe((data) => {
      ref.close();
    });
    ref.componentInstance.detailedView.subscribe((res) => {
      ref.close();
    });
    const sub = ref.componentInstance.getResult.subscribe((data) => {
      if (data) {
        this.user = data;

        this.sendResult();
      }

      ref.close();
    });
  }

  validateEvent() {
    this.isValidate = false;

    if (this.validateEmail && this.validateHomePhone && this.validateMobile && this.validateWorkPhone) {
      let ruleValidator;

      this.validate.emit(this.isValidate);

      if (this.isRequired != false) {
        ruleValidator = {
          firstName: 'string|required',
          title: 'string|required',
          lastName: 'string|required',
          middleName: 'string',
          gender: 'string|required',
        };
      } else {
        ruleValidator = {
          firstName: 'string',
          title: 'string',
          lastName: 'string',
          middleName: 'string',
          gender: 'string',
        };
      }

      this.isValidate = this.utilsClass.validateFields(this.user, ruleValidator);
    } else {
      this.isValidate = false;
      this.validate.emit(false);
    }

    this.validate.emit(this.isValidate);
  }

  sendResult() {
    this.validateEvent();

    this.user.validate = this.isValidate;
    this.getResult.emit(this.user);
  }

  actionEvent() {
    this.getAction.emit(true);
  }

  action2Event() {
    this.getAction2.emit(true);
  }

  getMobile(m) {
    this.user.mobile = m;
    this.sendResult();
  }

  getWorkPhone(p) {
    this.user.workPhone = p;

    this.sendResult();
  }

  getHomePhone(p) {
    this.user.homePhone = p;

    this.sendResult();
  }

  getEmail(e) {
    this.user.email = e;

    this.sendResult();
  }

  validateMobileEvent(v) {
    this.validateMobile = v;
  }

  validateWorkPhoneEvent(v) {
    this.validateWorkPhone = v;
  }

  validateHomePhoneEvent(v) {
    this.validateHomePhone = v;
  }

  validateEmailEvent(v) {
    this.validateEmail = v;
  }
}
