<mat-dialog-content class="stacked-card-view">
  <div class="card-header primary-gradient-img clearfix stacked">
    <div class="row clearfix">
      <button class="btn-close btn-clear mat-button white" (click)="closeEvent()">
        <mat-icon>clear</mat-icon>
      </button>
      <div class="row titleArea clearfix">
        <app-session-view-profile-picture class="main-profile" [sessionID]="sessionID" [cnhID]="cnhID">
        </app-session-view-profile-picture>
        <!-- <ng-template #epDoc>
          <app-ep-document-view-image
            class="supplierIcon pull-left"
            [defaultPicture]="'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/default-corporate-image.png)'"
            [hideZoomButton]="false"
            [link]="profileLink"
          >
          </app-ep-document-view-image>
        </ng-template> -->
        <h2 class="summary-header rm-m">
          {{ label }}
        </h2>
      </div>
    </div>
  </div>
  <div class="agent-container">
    <mat-card class="card" *ngFor="let agent of agents">
      <mat-card-header>
        <h3>{{ agent.time | customDateTime }} {{ agent.time | TimeLabelPast }} ago</h3>
      </mat-card-header>

      <div class="row clearfix">
        <div class="half-width">
          <div class="column"><strong>- Device:</strong> {{ agent.device }}</div>
        </div>
        <div class="half-width">
          <div class="column"><strong>- Operating system: </strong> {{ agent.os }}</div>
        </div>
      </div>
      <div class="row clearfix">
        <div class="half-width">
          <div class="column">
            <strong>- Browser: </strong> {{ agent.browser }}
            {{ agent.browser_version }}
          </div>
        </div>
        <div class="half-width">
          <div class="column"><strong>- Version: </strong> {{ agent.os_version }}</div>
        </div>
      </div>
    </mat-card>
  </div>
</mat-dialog-content>
