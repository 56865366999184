import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FundersRevenueReportPage } from './funders-revenue-report/funders-revenue-report.page';

const routes: Routes = [
  {
    path: 'revenue-report',
    component: FundersRevenueReportPage,
    data: {
      title: 'Revenue Report',
    },
    outlet: 'page',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FundersRoutingModule {}
