<div class="row clearfix flex">
  <div class="full-width">
    <div class="col-left">
      <h3 class="sr-title sm-h3 rm-m">Present Situation</h3>
      <hr/>

      <mat-tab-group>
        <mat-tab [label]="'KEYWORD.patient' | translate | titlecase"> Content 1</mat-tab>
        <mat-tab label="Contract"> Content 2 </mat-tab>
        <mat-tab label="Loan Position"> Content 3 </mat-tab>
        <mat-tab label="Previous Arrangements"> Content 3 </mat-tab>
      </mat-tab-group>
    </div>
  </div>
  <div class="full-width">
    <div class="col-right">
      <h3 class="sr-title sm-h3 rm-m">Proposed Arrangement</h3>
      <hr/>
      <mat-tab-group>
        <mat-tab label="Initial Period"> Content 1 </mat-tab>
        <mat-tab label="Catchup Period"> Content 2 </mat-tab>
        <mat-tab label="End of Loan"> Content 3 </mat-tab>
        <mat-tab label="Bank Accounts"> Content 3 </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>

<div class="row clearfix flex">
  <div class="full-width">
    <div class="col-left">
      <h3 class="sr-title sm-h3 rm-m">Present Schedule</h3>
      <hr/>
    </div>
  </div>
  <div class="full-width">
    <div class="col-right">...</div>
  </div>
</div>
