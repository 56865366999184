<ng-container *ngIf="cnHistory">
  <div class="card-header primary-gradient-img clearfix stacked">
    <div class="row clearfix">
      <button class="btn-close btn-clear mat-button white" (click)="closeEvent()">
        <mat-icon>clear</mat-icon>
      </button>
      <div class="row titleArea clearfix">
        <app-session-view-profile-picture class="main-profile" [cnhID]="cnHistory._id">
        </app-session-view-profile-picture>
        <h2 class="summary-header rm-m">
          {{ cnHistory['name'] }}
          <!-- <span class="subLabel" *ngIf="cnHistory['TradingAs']"
          >Trading as: {{ cnHistory["TradingAs"] }}</span
        > -->
        </h2>
        <p class="clearfix small rm-mb">
          <span class="chip-enable ml mr">{{ cnHistory['sessionType'] }}</span>
        </p>
      </div>
    </div>
    <div class="action-button">
      <button class="white" class="actionMenuToggle" [matMenuTriggerFor]="btnPatMenu" mat-mini-fab color="accent">
        <mat-icon class="white">more_vert</mat-icon>
      </button>

      <mat-menu #btnPatMenu="matMenu">
        <button (click)="displayContact()" mat-menu-item>
          <mat-icon>contact_mail</mat-icon>
          <span>Contact User</span>
        </button>

        <button (click)="viewUserLogs()" mat-menu-item>
          <mat-icon>list</mat-icon>
          <span>User Logs</span>
        </button>

        <button (click)="displayAgentDetails()" mat-menu-item>
          <mat-icon>web</mat-icon>
          <span>Browser Details</span>
        </button>
      </mat-menu>
    </div>
  </div>
  <div class="full-width mb clearfix row">
    <div class="row clearfix details flex" *ngIf="cnHistory['phone']">
      <mat-icon class="ico">phone</mat-icon>
      <div class="row-content clearfix">
        <p class="rm-mb rm-mt">
          {{ cnHistory['phone'] | customPhone: 'landLine' }}
        </p>
        <p class="small">Home Phone</p>
      </div>
    </div>

    <div class="row clearfix details flex" *ngIf="cnHistory['email']">
      <mat-icon class="ico">email</mat-icon>
      <div class="row-content">
        <p class="rm-mb rm-mt">{{ cnHistory['email'] }}</p>
        <p class="small">Email</p>
      </div>
    </div>
  </div>
</ng-container>
