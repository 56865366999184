<mat-card class="card treatment-card card1 text-center" id="simpleInvitation">
  <div class="row clearfix tab-header closable" @ngIfAnimation
       [class.primary-gradient-img]="templates && templates.length">
    <h3 class="sm-h3 rm-mt animate" *ngIf="!templates || (templates && templates.length <= 0)">
      Create new {{ "KEYWORD.treatment" | translate }} invitation
    </h3>
    <ng-container *ngIf="templates && templates.length > 0" @ngIfAnimation>
      <div class="animate">
        <div class="row clearfix">
          <h3 class="sm-h3 rm-mt rm-mb">{{ 'Create new invitation from templates' | translate }}</h3>
        </div>
        <div class="full-width treatment-item-parent text-center mt mb clearfix">
          <div class="full-width treatment-item flex" *ngFor="let template of templates">
            <div class="full-width border-box ml mr">
              <div class="row clearfix">
                <p class="white reduce-marg hint">
                  {{ template.label }}
                  <span *ngIf="
                      template &&
                      (template.treatmentValue || template.treatmentValue === 0) &&
                      template.options &&
                      template.options.selectTreatmentValue == true
                    ">:
                  </span>
                  <strong *ngIf="
                      template &&
                      (template.treatmentValue || template.treatmentValue === 0) &&
                      template.options &&
                      template.options.selectTreatmentValue == true
                    ">
                    {{ template.treatmentValue | customCurrency }}
                  </strong>
                </p>
              </div>
              <div class="row clearfix mb">
                <button class="white" (click)="viewSavedTreatment(template)">
                  <mat-icon> visibility </mat-icon> View Template
                </button>
                <button class="white ml" (click)="clearTreatment(template)" mat-stroked-button>
                  <mat-icon> clear </mat-icon> Clear
                </button>
                <div class="full-width text-center mt" *ngIf="template && template.options">
                  <div class="white check-template"
                       [ngClass]="{ 'hide-option': template.options.selectTreatmentValue !== true }"
                       matTooltip="Treatment value {{
                      template.options.selectTreatmentValue == true ? 'is added' : 'is not added'
                    }}">
                    <mat-icon class="fas fa-dollar-sign"></mat-icon>
                  </div>
                  <div class="white check-template"
                       [ngClass]="{ 'hide-option': template.options.selectDocuments !== true }"
                       matTooltip="Treatment Documents {{
                      template.options.selectDocuments == true ? 'are added' : 'are not added'
                    }}">
                    <mat-icon class="fas fa-file-alt"></mat-icon>
                  </div>
                  <div class="white check-template"
                       [ngClass]="{ 'hide-option': template.options.selectImages !== true }"
                       matTooltip="Treatment Images {{
                      template.options.selectImages == true ? 'are added' : 'are not added'
                    }}">
                    <mat-icon class="fas fa-image"></mat-icon>
                  </div>
                  <div class="white check-template"
                       [ngClass]="{ 'hide-option': template.options.selectTreatmentPlan !== true }"
                       matTooltip="Treatment Items {{
                      template.options.selectTreatmentPlan == true ? 'are added' : 'are not added'
                    }}">
                    <mat-icon class="fas fa-notes-medical"></mat-icon>
                  </div>

                  <div class="white check-template" [ngClass]="{ 'hide-option': template.options.selectTags !== true }"
                       matTooltip="Tags {{ template.options.selectTags == true ? 'are added' : 'are not added' }}">
                    <mat-icon class="fas fa-tags"></mat-icon>
                  </div>
                  <div class="white check-template"
                       [ngClass]="{ 'hide-option': template.options.selectMessage !== true }" [matTooltip]="'Personal message to ' + ('KEYWORD.patient' | translate) + (
                      template.options.selectMessage == true ? 'is added' : 'is not added'
                    )">
                    <mat-icon class="fas fa-comment-dots"></mat-icon>
                  </div>
                  <div class="white check-template"
                       [ngClass]="{ 'hide-option': template.options.selectDiscount !== true }"
                       matTooltip="Discount  configuration {{
                      template.options.selectDiscount == true ? 'is added' : 'is not added'
                    }}">
                    <mat-icon class="fas fa-money-bill-wave"></mat-icon>
                  </div>
                  <div class="white check-template"
                       [ngClass]="{ 'hide-option': template.options.selectHealthHistory !== true }"
                       matTooltip=" Health History {{
                      template.options.selectHealthHistory == true ? 'is added' : 'is not added'
                    }}">
                    <mat-icon class="fas fa-heartbeat"></mat-icon>
                  </div>
                  <div class="white check-template"
                       [ngClass]="{ 'hide-option': template.options.selectGoogleReview !== true }"
                       matTooltip="Google Review {{
                      template.options.selectGoogleReview == true ? 'is added' : 'is not added'
                    }}">
                    <mat-icon class="fas fa-star"></mat-icon>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="row clearfix">
    <mat-card class="important-card">
      <div class="colour-stripe"></div>
      <h3 class="sr-title sm-h3 rm-m">1. Enter the {{ "KEYWORD.treatment" | translate }} value</h3>
      <button class="pull-right position-right" (click)="launchAsModal('418752721', 1)" mat-icon-button color="primary"
              matTooltip="What's this?">
        <mat-icon class="fas fa-info-circle mat-primary"></mat-icon>
      </button>
      <div class="clearfix mt centered">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label> {{"KEYWORD.Treatment" | translate}} Value ($)</mat-label>
          <input class="mat-block largeInput" *ngIf="disableTreatmentValue != true || totalTreatmentValue <= 0"
                 [(ngModel)]="totalTreatmentValue" [disabled]="!isSimpleTreatmentCost" (blur)="changeAmount()"
                 name="totalTreatmentValue" matInput placeholder="Please enter a value" currencyMask/>

          <input class="mat-block largeInput" *ngIf="disableTreatmentValue == true && totalTreatmentValue > 0"
                 [(ngModel)]="totalTreatmentValue" [disabled]="true" (blur)="changeAmount()" name="totalTreatmentValue"
                 matInput placeholder="Please enter a value" currencyMask/>
        </mat-form-field>
      </div>
    </mat-card>
  </div>
  <div class="row clearfix" *ngIf="isModuleAdvancedTreatmentActive == false || !isModuleAdvancedTreatmentActive">
    <!-- <div class="full-width"> -->
    <div class="column">
      <div class="row clearfix">
        <h3 class="sr-title sm-h3 rm-m">
          2. Upload or select the {{ 'PHRASE.treatment plan' | translate }} and supporting documents (Optional)

        </h3>
        <p class="small">
          Upload the {{ 'PHRASE.treatment plan' | translate }} you created using your
          {{ "KEYWORD.practice" | translate }} management software, as well as any additional supporting documents which
          may be required as (PDF format only). This step is optional.
        </p>
        <div class="row clearfix">
          <button class="text-center mat-elevation-z1" (click)="checkTreatmentDocuments('system')" mat-stroked-button
                  color="primary">
            <mat-icon class="fas fa-file-import"></mat-icon>&nbsp; Search Document Gallery
          </button>
        </div>
        <ng-container>
          <app-file-uploader class="text-center" [documentType]="'Treatment Plan'"
                             [fileDescription]="treatmentDocumentDescription" [fileName]="treatmentDocumentLabel"
                             [isGallery]="false"
                             [patientDocumentType]="'MEDR_T'" [isDragable]="true" [invitationID]="invitationID"
                             (onCompleteAll)="onCompleteAllSimple($event)">
          </app-file-uploader>
          <app-file-list class="" [defaultLabel]="('KEYWORD.treatment' | translate | titlecase) + ' Document'"
                         [documentTypes]="documentTypes" [files]="simpleTreatmentDocuments"
                         [canPatientDocument]="canPatientDocument"
                         [canGallery]="true" [canPrivateDocument]="true" [invitationID]="invitationID" [canEdit]="true"
                         [canAction]="false" (remove)="removeSimpleTreatmentDocument($event)">
          </app-file-list>
        </ng-container>
        <div class="row clearfix mb" *ngIf="EPTreatmentDocuments && EPTreatmentDocuments.length > 0">
          <div class="clearfix full-width" [class.mt]="EPTreatmentDocuments.length > 0">
            <app-ep-document-list [isDownload]="isDownload" [isPrint]="isPrint" [canRemove]="true" [isFilter]="false"
                                  [defaultCheck]="true" [isRedirection]="isRedirection"
                                  [documents]="EPTreatmentDocuments"
                                  [title]="'Gallery Documents'" [canCheck]="false" [listViewType]="'thumbnails'"
                                  (getselectedDocumentIDs)="getselectedTreatmentDocumentIDs($event)">
            </app-ep-document-list>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-card>
<mat-card class="card treatment-card card1 text-center" id="simpleInvitation2">
  <div class="colour-stripe"></div>
  <div class="row clearfix">
    <div class="column">
      <h3 class="sr-title sm-h3 mt rm-mb" *ngIf="isModuleStockArtActive == true">
        3. Upload or Select Images,Videos (optional)
      </h3>
      <p class="small">
        Upload {{ 'KEYWORD.procedure' | translate }} imagery to send to the {{ "KEYWORD.patient" | translate }} or
        choose some images from our
        gallery.
      </p>
      <div class="row clearfix">
        <button class="text-center mat-elevation-z1" *ngIf="isModuleStockArtActive == true"
                (click)="checkStockArtDocuments('system')" mat-stroked-button color="primary">
          <mat-icon class="fas fa-file-import"></mat-icon>&nbsp; Search image gallery
        </button>
      </div>
      <ng-container *ngIf="isModuleStockArtActive == true">
        <div class="row clearfix">
          <app-file-uploader class="text-center" [documentType]="'Image'" [allowedExtensionTypes]="imageExtensionType"
                             [fileName]="('KEYWORD.procedure' | translate | titlecase) +' Gallery'" [isGallery]="false"
                             [patientDocumentType]="'MEDR_I'" [isDragable]="true" [invitationID]="invitationID"
                             (onCompleteAll)="onCompleteAllStockArt($event)">
          </app-file-uploader>
        </div>
        <div class="row clearfix">
          <app-file-list class="mt" [defaultLabel]="('KEYWORD.procedure' | translate | titlecase) +' Gallery'"
                         [documentTypes]="documentTypes2" [files]="stockArtDocuments" [invitationID]="invitationID"
                         [canEdit]="true"
                         [canAction]="false" [canPatientDocument]="canPatientDocument" [canGallery]="true"
                         (remove)="removeSimpleTreatmentStockArtDocument($event)">
          </app-file-list>
        </div>
      </ng-container>
      <div class="row clearfix mb"
           *ngIf="isModuleStockArtActive == true && EPstockArtDocuments && EPstockArtDocuments.length > 0">
        <div class="clearfix full-width" [class.mt]="EPstockArtDocuments.length > 0">
          <app-ep-document-list [isDownload]="isDownload" [isPrint]="isPrint" [canRemove]="true" [isFilter]="false"
                                [defaultCheck]="true" [isRedirection]="isRedirection" [documents]="EPstockArtDocuments"
                                [title]="'Gallery Images'" [canCheck]="false" [listViewType]="'thumbnails'"
                                (getselectedDocumentIDs)="getselectedStockArtDocumentIDs($event)">
          </app-ep-document-list>
        </div>
      </div>
    </div>
    <!-- </div> -->
  </div>
</mat-card>
<mat-card class="card treatment-card card1 text-center" id="simpleInvitation3">
  <div class="colour-stripe"></div>
  <div class="row clearfix mt" *ngIf="isModuleTreatmentGroupActive == true">
    <!-- <div class="row clearfix"> -->
    <div class="row clearfix">
      <div class="column">
        <div class="row clearfix">
          <h3 class="sr-title sm-h3 rm-mb mt">
            4. Define {{ "KEYWORD.treatment" | translate | titlecase }} to access Informed Consent and Marketing
            Materials
            (Optional)
          </h3>
          <p class="small mb pad-wrap">
            Defining {{ "KEYWORD.treatment" | translate }} allows us to send the
            {{ "KEYWORD.patient" | translate }} various marketing materials and informed consent documentation including
            PDF, videos and images. This step is optional.
          </p>
        </div>
        <div class="infConsTreatCont" *ngFor="let consent of informedConsent; let index = index" @ngIfAnimation>
          <div class="row clearfix infConsTreat animate">
            <div class="row clearfix">
              <h3 class="sr-title rm-m text-left"> {{"KEYWORD.Treatment" | translate}} {{ index + 1 }}</h3>
              <app-treatment-informed-consent-create class="" [displayStockArtDocuments]="false"
                                                     [itemCode]="consent.itemCode" [justSelected]="consent.justSelected"
                                                     [categoryID]="consent.categoryID"
                                                     [isGlobal]="false" [merchantID]="merchantID"
                                                     [brandID]="consent.brandID" [productID]="consent.productID"
                                                     [typeID]="consent.typeID"
                                                     [preSelectedDocuments]="consent.preDocuments"
                [marketingSelectedDocuments]="consent.marketingDocuments"
                                                     [postSelectedDocuments]="consent.postDocuments"
                                                     [stockArtSelectedDocuments]="EPstockArtDocuments"
                                                     [displayPreDocuments]="true" [displayPostDocuments]="true"
                                                     [displayMarketingDocuments]="true"
                                                     [displayStockArtDocuments]="false" [selectPreDocuments]="true"
                                                     [selectPostDocuments]="true"
                                                     [selectMarketingDocuments]="true" [selectStockArtDocuments]="true"
                                                     [showPreDocumentsModal]="false"
                                                     [showPostDocumentsModal]="false"
                                                     [showMarketingDocumentsModal]="false"
                                                     [showStockArtDocumentsModal]="true"
                                                     (getInformedConsent)="getInformedConsent($event, index)"
                (getStockArtDocuments)="getStockArtDocumentsEvent($event)"
                (getStockArtModalOpened)="getStockArtModalOpened($event)"
                                                     (clearInformedConsent)="clearInformedConsentEvent($event, index)">
              </app-treatment-informed-consent-create>
            </div>
            <div class="buttons text-right">
              <button class="mat-stroked-mini-fab mat-elevation-z1" *ngIf="informedConsent.length > 1"
                      (click)="removeConsent(index)" mat-stroked-button color="accent" matTooltip="Remove row">
                <mat-icon class="fas fa-trash"></mat-icon>
              </button>
              <button class="mat-stroked-mini-fab mat-elevation-z1" *ngIf="informedConsent.length <= 1"
                      (click)="clearInformedConsent()" mat-stroked-button color="accent" matTooltip="Clear row">
                <mat-icon class="fas fa-times"></mat-icon>
              </button>
              <button class="mat-stroked-mini-fab mat-elevation-z1" (click)="addConsent()" mat-stroked-button
                      color="primary" matTooltip="Add another row">
                <mat-icon class="fas fa-plus"></mat-icon>
              </button>
            </div>
            <hr class="mb" *ngIf="i >= 0"/>
          </div>
        </div>
        <button *ngIf="!informedConsent" (click)="addConsent()" mat-mini-fab color="primary"
                matTooltip="Add another row">
          <mat-icon class="fas fa-plus"></mat-icon>
        </button>
      </div>
    </div>
  </div>
</mat-card>
