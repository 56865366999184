<div class="error-page rel error">

  <!-- <mat-card class="error text-center"> -->

  <img class="character_thumbsup" *ngIf="!image" />


  <img *ngIf="image" [src]="image" />
  <!-- <div class="circle"></div> -->

  <h2 class="accent-color">{{title}}</h2>

  <div class="errorContent" *ngIf="description">
    <p class="mb" [innerHTML]="description | safeHtml"></p>
  </div>
  <div class="errorContent" *ngIf="!description">
    <p>seems to be experiencing some technical difficulties at this time that are stopping you from proceeding.
      <br>suggest you try again later or if you wish to discuss this issue with our technical team
      <br>feel free to call
      <strong> {{settings.contactPhone}} </strong> during office hours.
      <br>
      <br>
    </p>
  </div>


  <hr>
  <div class="drop-buttons mt">

    <button mat-raised-button color="accent" class="ml mr" (click)="close()">Close </button>

    <button mat-raised-button *ngIf="displayContact==true" color="primary" class="ml mr" (click)="contactUs()">Contact
      Us </button>



  </div>

  <!-- </mat-card> -->

</div>
