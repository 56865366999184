import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { MapViewComponent } from '../../../shared/components/map-view/map-view.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { CustomPhonePipe } from '../../../shared/pipes/custom-phone.pipe';
import { TimeLeftPipe } from '../../../shared/pipes/time-left.pipe';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { OperatorChangePasswordComponent } from '../operator-change-password/operator-change-password.component';
import { OperatorContactComponent } from '../operator-contact/operator-contact.component';
import { OperatorService } from '../shared/operator.service';

@Component({
  selector: 'app-operator-view',
  templateUrl: './operator-view.component.html',
  styleUrls: ['./operator-view.component.css'],
  providers: [CustomPhonePipe, TimeLeftPipe],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.stacked-card-view', style({ opacity: 0 }), { optional: true }),
        query(
          '.stacked-card-view',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({ opacity: 0, offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.stacked-card-view', style({ opacity: 1 }), { optional: true }),
        query(
          '.stacked-card-view',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({ opacity: 1, offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class OperatorViewComponent implements OnInit {
  @Input() operatorID = null;

  @Input()
  invitationID;

  currentUser;
  @Output() close: EventEmitter<any> = new EventEmitter();

  subjectArray = [];
  profileLink;
  operator: any;
  isModal = false;
  sessionType;
  constructor(
    private customPhone: CustomPhonePipe,
    private authenticationService: AuthenticationService,
    private operatorService: OperatorService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private timeLeft: TimeLeftPipe,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      this.operatorID = data;
      this.isModal = true;
    }
  }

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((st) => {
      this.sessionType = st;

      this.activeRoute.params.subscribe((params) => {
        if (params['consumerID']) {
          this.invitationID = params['consumerID'];
        }

        if (!this.operatorID && params && params['operatorID ']) {
          this.operatorID = params['operatorID '];
        }

        this.authenticationService.getCurrentUser().subscribe((res) => {
          if (res) {
            this.currentUser = res.data;
          }
        });

        this.operatorService.getfullOperatorDetails(this.operatorID).subscribe((res) => {
          this.operator = res;
          this.profileLink = null;
          this.profileLink = this.operatorService.getProfilePicStreamLink(this.operatorID);
        });
      });
    });
  }

  verifyUserID() {}

  getMoreInfo() {
    let phone = '';

    if (this.operator['phones.Number']) {
      phone = this.customPhone.transform(String(this.operator['phones.Number']), 'landLine');
    }

    const result =
      "<p><strong>Dentist's name: </strong>" +
      this.operator['CalculatedName'] +
      '</p><p><strong>Phone: </strong>' +
      String(phone) +
      "</p><p><strong>Email: </strong> <a class='emailLink primary-color' href='mailto:" +
      String(this.operator['emails.Email']) +
      "' title='Email Dentist' target='_blank'>" +
      this.operator['emails.Email'] +
      '</a></p>';

    return result;
  }

  displayMap() {
    const location = {
      state: this.operator['addresses.State'],
      postCode: this.operator['addresses.Postcode'],
      zoom: 17,
      suburb: this.operator['addresses.Suburb'],
      country: this.operator['addresses.Country.Code'],
      addressLabel: this.operator['addresses.Calculated'],
      latitude: this.operator['Address.Latitude'],
      longitude: this.operator['Address.Longitude'],
      moreInfo: this.getMoreInfo(),
    };

    const ref = RootAppComponent.dialog.open(MapViewComponent, {
      data: location,
      panelClass: 'modal-map',
      width: '800px',
      height: '600px',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  disableLogin() {
    const confirmDialogParams = {
      data: new ConfirmDialog(
        'fas fa-info',
        'Are you sure?',
        '<p>Disabling the operator, the user will be disconnected</p>',
        'No',
        'Yes'
      ),
    };
    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, confirmDialogParams);
    ref.componentInstance.onConfirm.subscribe((val) => {
      if (val == true) {
        this.operatorService.disableLogin(this.operatorID, this.sessionType).subscribe((res) => {
          if (res) {
            this.operator.enabled = false;
            NotifyAppComponent.displayToast('success', 'Disable Login', 'This operator is not able to login');
          }
        });
      } else {
        ref.close();
      }
    });
  }

  enableLogin() {
    this.operatorService.enableLogin(this.operatorID, this.sessionType).subscribe((res) => {
      if (res) {
        this.operator.enabled = true;
        NotifyAppComponent.displayToast('success', 'Enable Login', 'This operator is able to login');
      }
    });
  }

  contactUser() {
    RootAppComponent.dialog.open(OperatorContactComponent, {
      width: '600px',
      data: this.operatorID,
    });
  }

  changePassword() {
    const ref = RootAppComponent.dialog.open(OperatorChangePasswordComponent, {
      data: this.operatorID,
      width: '650px',
      panelClass: 'noCard',
    });

    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  closeEvent() {
    this.close.emit(true);
  }

  getFacebookLabel() {
    if (this.operator.authenticationMethod && this.operator.authenticationMethod.hasFacebookID) {
      const fbDate = this.timeLeft.transform(this.operator.authenticationMethod.facebookDate);
      return `${this.operator.authenticationMethod.facebookLabel} ${fbDate}`;
    } else {
      return null;
    }
  }

  getGoogleLabel() {
    if (this.operator.authenticationMethod && this.operator.authenticationMethod.hasGoogleID) {
      const gDate = this.timeLeft.transform(this.operator.authenticationMethod.googleDate);
      return `${this.operator.authenticationMethod.googleLabel} ${gDate}`;
    } else {
      return null;
    }
  }

  getTwitterLabel() {
    if (this.operator.authenticationMethod && this.operator.authenticationMethod.hasTwitterID) {
      const tDate = this.timeLeft.transform(this.operator.authenticationMethod.twitterDate);
      return `${this.operator.authenticationMethod.twitterLabel} ${tDate}`;
    } else {
      return null;
    }
  }

  overview() {
    if (this.operatorID) {
      this.router.navigate(['/merchant', { outlets: { page: ['operator-session-overview', this.operatorID] } }]);
      this.closeEvent();
    }
  }

  displayInfo() {
    if (this.operator['UltimateTableName'] == 'MerchantProspect') {
      this.router.navigate([
        '/merchant',
        { outlets: { page: ['merchant-prospect-profile', this.operator['cardKey']] } },
      ]);
      this.closeEvent();
    } else if (this.operator['UltimateTableName'] == 'Promoter') {
      this.authenticationService.getCurrentUser().subscribe((res) => {
        this.router.navigate([
          '/merchant',
          { outlets: { page: ['merchant-profile', res.data['merchantKey'], 'staff', this.operator['contactKey']] } },
        ]);
      });
      this.closeEvent();
    } else if (this.operator['UltimateTableName'] == 'Merchant') {
      this.router.navigate([
        '/merchant',
        { outlets: { page: ['merchant-profile', this.operator['merchantKey'], 'staff', this.operator['contactKey']] } },
      ]);

      this.closeEvent();
    }
  }

  loginAs(cardID) {
    if (cardID) {
      const confirm = new ConfirmDialog(
        'fas fa-info',
        'You need to logout',
        'In order to change the connected user you need to logout',
        'Cancel',
        'Logout and Proceed'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
        disableClose: true,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation === false) {
          ref.close();
        } else {
          const body = {
            cardID,
          };
          this.authenticationService.loginAsUser(body).subscribe((res) => {
            if (res) {
              this.authenticationService.getCurrentUserForce().subscribe((getCurrentUserForce) => {
                NotifyAppComponent.displayToast('success', 'success', 'Successful operation');

                if (getCurrentUserForce && getCurrentUserForce.data) {
                  if (
                    getCurrentUserForce.data.SessionType == 'admin' ||
                    getCurrentUserForce.data.SessionType == 'merchant' ||
                    getCurrentUserForce.data.SessionType == 'merchant-admin' ||
                    getCurrentUserForce.data.SessionType == 'promoter'
                  ) {
                    window.location.href = Settings.global['merchantFrontendLink'] + '/merchant';
                  } else if (getCurrentUserForce.data.SessionType == 'customer') {
                    window.location.href = Settings.global['consumerFrontendLink'] + '/consumer-app/(page:home)';
                  }
                }
              });
            }
          });
        }
      });
    }
  }
}
