<mat-card class="card full-width" *ngIf="tip">
  <div class="colour-stripe"></div>
  <button
    class="btn-close btn-clear white pull-right mat-button"
    *ngIf="isModal == true"
    (click)="closeModalEvent()"
    matDialogClose
  >
    <mat-icon>clear</mat-icon>
  </button>
  <div class="row clearfix">
    <h2 class="sm-h2 sr-title rm-mb rm-mt">{{ tip.title }}</h2>
    <hr class="mb"/>
  </div>
  <div class="row clearfix mb text-center">
    <div [innerHTML]="tip.content | safeHtml"></div>
  </div>

  <div class="row clearfix mt">
    <p class="small rm-m pull-left">
      <span class="pull-right chip chipActive" *ngIf="tip.isActive == true">Active</span>

      <span class="pull-right chip chipNotActive" *ngIf="tip.isActive == false">Not active</span>
    </p>
    <p class="small rm-m pull-right">{{ tip.updated_at | TimeLabelPast }} ago</p>
  </div>
</mat-card>
