import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmDialogComponent } from '../../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../../shared/components/root-component/root-component.component';
import { ConfirmDialog } from '../../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../../shared/types/notify-app-component';
import { Settings } from '../../../../shared/types/settings';
import { AuthenticationService } from '../../../authentication/shared/authentication.service';
import { SettingsService } from '../../../settings/shared/settings.service';

@Component({
  selector: 'admin-footer',
  templateUrl: './admin-footer.component.html',
  styleUrls: ['./admin-footer.component.css'],
})
export class AdminFooterComponent implements OnInit {
  @Output()
  refresh = new EventEmitter();

  siteIsOnline = Settings.global['siteOnline'];

  constructor(
    private router: Router,
    private settingService: SettingsService,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit() {}

  saveAsDefault() {
    let confirm = new ConfirmDialog(
      'fas fa-info',
      '',
      'Are you sure you want to save the current settings as Default ?',
      'No',
      'Yes'
    );

    let ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirm,
    });
    ref.componentInstance.onConfirm.subscribe((confirmation) => {
      if (confirmation === false) {
        ref.close();
      } else {
        this.settingService.saveAsDefault().subscribe((res) => {
          if (res && res.success) {
            this.settingService.getPublicSettings().subscribe((settings) => {
              if (settings && settings[0]) {
                const s = this.settingService.setSettings(settings[0]);
                NotifyAppComponent.displayToast(
                  'success',
                  'Successful operation',
                  'Settings has been stored as default'
                );
              }

              ref.close();
            });
          }
        });
      }
    });
  }

  initialize() {
    let confirm = new ConfirmDialog('fas fa-info', '', 'Are you sure you want to initialize settings ?', 'No', 'Yes');

    let ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirm,
    });
    ref.componentInstance.onConfirm.subscribe((confirmation) => {
      if (confirmation === false) {
        ref.close();
      } else {
        this.settingService.initialize().subscribe((res) => {
          if (res && res.success) {
            Settings.refreshSettings.emit(true);
            this.settingService.getPublicSettings().subscribe((settings) => {
              if (settings && settings[0]) {
                const s = this.settingService.setSettings(res.data);

                Settings.global = settings[0];
                Settings.applySettings();
                if (s) {
                  NotifyAppComponent.displayToast('success', 'Successful operation', 'Settings has been Initialized');
                }
              }
            });
          }

          ref.close();
        });
      }
    });
  }

  changeSiteMode(v) {
    if (v == true) {
      this.setSiteOffline();
    } else {
      this.setSiteOnline();
    }
  }

  setSiteOnline() {
    let confirm = new ConfirmDialog(
      'link',
      '',
      'You are about to set the website back in online mode again, are you done with your changes?',
      'No',
      'Yes, Proceed'
    );

    let ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirm,
    });
    ref.componentInstance.onConfirm.subscribe((confirmation) => {
      if (confirmation === false) {
        ref.close();
      } else {
        this.settingService.setSiteOnline().subscribe((res) => {
          Settings.global['siteOnline'] = true;
          this.siteIsOnline = Settings.global['siteOnline'];

          NotifyAppComponent.displayToast('success', 'Successful operation', 'The application is on Online mode');

          ref.close();
        });
      }
    });
  }

  setSiteOffline() {
    let confirm = new ConfirmDialog(
      'link_off',
      '',
      'You are about to set the website  in offline mode : <br><strong>This</strong>  will destroy all connected sessions.  <br><strong>This</strong>  will prevent all users from using the application except administrator. <br><strong>You</strong>  will have to login again to use the application. <br>  are you sure you want to proceed?',
      'No',
      'Yes, Proceed'
    );

    let ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirm,
    });
    ref.componentInstance.onConfirm.subscribe((confirmation) => {
      if (confirmation === false) {
        ref.close();
      } else {
        this.settingService.setSiteOffline().subscribe((res) => {
          this.authenticationService.logout().subscribe((res1) => {
            Settings.global['siteOnline'] = false;
            this.siteIsOnline = Settings.global['siteOnline'];
            this.router.navigate(['/login-admin']);

            NotifyAppComponent.displayToast('success', 'Successful operation', 'The application is on Offline mode');

            ref.close();
          });
        });
      }
    });
  }
}
