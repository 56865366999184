<div class="rel">
  <div class="card-header primary-gradient-img clearfix confirmModal" *ngIf="isModal == true">
    <button
      class="btn-close btn-clear mat-button smt white custom-close"
      [ngClass]="!title ? 'no-title-close' : ''"
      (click)="onClick()"
    >
      <mat-icon>clear</mat-icon>
    </button>
    <div class="full-width flex main-title-container" [ngClass]="!title ? 'main-icon-container-no-title' : ''">
      <div class="main-icon-container text-center">
        <mat-icon class="main-icon accent-color" *ngIf="isFontAwsome(icon) != true"> {{ icon || 'info' }}</mat-icon>

        <mat-icon
          class="main-icon accent-color {{ icon || 'fas fa-info' }}"
          *ngIf="isFontAwsome(icon) == true"
        ></mat-icon>
      </div>
      <div class="row full-width title-container clearfix">
        <div class="title-container-sub">
          <h2 class="text-left title-header rm-m">
            {{ title }}
            <span class="subLabel" *ngIf="description">{{ description }}</span>
          </h2>
        </div>
      </div>
    </div>
  </div>

  <mat-dialog-content [ngClass]="{ 'no-modal-height': isModal != true }">
    <div class="full-width html-view-only">
      <div class="full-width" [hidden]="!content || isPreview != true || isCode != true">
        <div class="full-width smb">
          <div class="toggle-card row clearfix">
            <mat-button-toggle-group
              class="btn-group row clearfix flex half-width pull-left"
              [(ngModel)]="viewType"
              name="viewType"
            >
              <mat-button-toggle class="text-center full-width rel" *ngIf="preview || content" [value]="'preview'">
                Preview
              </mat-button-toggle>
              <mat-button-toggle class="text-center full-width rel" *ngIf="content" [value]="'code'">
                Code
              </mat-button-toggle>
              <mat-button-toggle class="text-center full-width rel" *ngIf="style" [value]="'style'">
                Style
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="full-width" *ngIf="viewType == 'preview'">
          <app-inner-html-isolated
            class="full-width single-html-more-text clearfix"
            *ngIf="!preview && content"
            [content]="content"
          >
          </app-inner-html-isolated>

          <app-inner-html-isolated
            class="full-width single-html-more-text clearfix"
            *ngIf="preview"
            [content]="preview"
          >
          </app-inner-html-isolated>
        </div>

        <div class="full-width" *ngIf="viewType == 'code'">
          <ngx-monaco-editor class="full-width min-height-html" [(ngModel)]="content" [options]="editorOptions">
          </ngx-monaco-editor>
        </div>

        <div class="full-width" *ngIf="viewType == 'style'">
          <ngx-monaco-editor class="full-width min-height-html" [(ngModel)]="style" [options]="editorOptionsCss">
          </ngx-monaco-editor>
        </div>
      </div>

      <div class="row clearfix mb" *ngIf="content && isPreview != true && isCode == true">
        <ngx-monaco-editor class="full-width min-height-html" [(ngModel)]="content" [options]="editorOptions">
        </ngx-monaco-editor>
      </div>
      <div class="row clearfix mb" *ngIf="(content || preview) && isPreview == true && isCode != true">
        <app-inner-html-isolated
          class="full-width single-html-more-text clearfix"
          *ngIf="!preview && content"
          [content]="content"
        >
        </app-inner-html-isolated>

        <app-inner-html-isolated class="full-width single-html-more-text clearfix" *ngIf="preview" [content]="preview">
        </app-inner-html-isolated>
      </div>

      <div class="row clearfix mb" *ngIf="!preview && !content && !style">
        <app-empty-list-message class="full-width" [title]="'No content'" [message]="'Nothing to display'">
        </app-empty-list-message>
      </div>
    </div>
  </mat-dialog-content>
  <hr/>
  <div class="drop-button text-center mt full-width" *ngIf="isModal == true">
    <button (click)="onClick()" mat-raised-button color="accent">Close</button>
  </div>
</div>
