/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./clear-text-button.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/form-field";
import * as i4 from "@angular/material/icon";
import * as i5 from "@angular/material/tooltip";
import * as i6 from "@angular/cdk/overlay";
import * as i7 from "@angular/cdk/scrolling";
import * as i8 from "@angular/cdk/platform";
import * as i9 from "@angular/cdk/a11y";
import * as i10 from "@angular/cdk/bidi";
import * as i11 from "@angular/platform-browser";
import * as i12 from "./clear-text-button.component";
var styles_ClearTextButtonComponent = [i0.styles];
var RenderType_ClearTextButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ClearTextButtonComponent, data: {} });
export { RenderType_ClearTextButtonComponent as RenderType_ClearTextButtonComponent };
export function View_ClearTextButtonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "inline-clear-icon-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 16777216, null, null, 3, "mat-icon", [["class", "fas fa-times inline-clear-icon mat-icon notranslate"], ["matSuffix", ""], ["matTooltip", "clear"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], [[null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 4)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 4)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(2, 16384, null, 0, i3.MatSuffix, [], null, null), i1.ɵdid(3, 9158656, null, 0, i4.MatIcon, [i1.ElementRef, i4.MatIconRegistry, [8, null], [2, i4.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), i1.ɵdid(4, 212992, null, 0, i5.MatTooltip, [i6.Overlay, i1.ElementRef, i7.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i8.Platform, i9.AriaDescriber, i9.FocusMonitor, i5.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i10.Directionality], [2, i5.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i11.HAMMER_LOADER]], { message: [0, "message"] }, null)], function (_ck, _v) { _ck(_v, 3, 0); var currVal_2 = "clear"; _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).inline; var currVal_1 = (((i1.ɵnov(_v, 3).color !== "primary") && (i1.ɵnov(_v, 3).color !== "accent")) && (i1.ɵnov(_v, 3).color !== "warn")); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_ClearTextButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ipv-clear-text-button", [], null, null, null, View_ClearTextButtonComponent_0, RenderType_ClearTextButtonComponent)), i1.ɵdid(1, 114688, null, 0, i12.ClearTextButtonComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ClearTextButtonComponentNgFactory = i1.ɵccf("ipv-clear-text-button", i12.ClearTextButtonComponent, View_ClearTextButtonComponent_Host_0, {}, {}, []);
export { ClearTextButtonComponentNgFactory as ClearTextButtonComponentNgFactory };
