<div class="row clearfix">
  <div class="well">
    <button *ngIf="beneficiary && !subsidiaryArray" (click)="createSubsidiaries()" mat-raised-button color="primary">
      <mat-icon class="fas fa-home mr"></mat-icon>
      <span>Add new location</span>
    </button>
  </div>
</div>

<mat-card class="rel subsidaries" *ngIf="beneficiary && subsidiaryArray && subsidiaryArray.length > 0" @ngIfAnimation>
  <div class="card-view">
    <div class="card-header primary-gradient-img clearfix inModal">
      <div class="row clearfix">
        <div class="row titleArea clearfix">
          <app-beneficiary-view-picture class="pull-left" [beneficiaryID]="beneficiaryID">
          </app-beneficiary-view-picture>
          <h2 class="summary-header rm-m">Locations</h2>
        </div>
      </div>
      <div class="action-button" *ngIf="isPromoterOrAdmin == true">
        <button class="actionMenuToggle" [matMenuTriggerFor]="btnPatMenu" mat-raised-button>
          Actions <mat-icon>more_vert</mat-icon>
        </button>

        <mat-menu #btnPatMenu="matMenu">
          <button (click)="createSubsidiaries()" mat-menu-item>
            <mat-icon class="fas fa-home"></mat-icon>
            <span>Add new location</span>
          </button>
        </mat-menu>
      </div>
    </div>

    <div class="row clearfix" *ngIf="s" (click)="viewSubsid(s['ID'])">
      <div class="thrd-width">
        <label>City</label>
      </div>
      <div class="thrd-width">
        <label>State</label>
      </div>
      <div class="thrd-width">
        <label>Phone</label>
      </div>
    </div>
    <mat-divider></mat-divider>
    <mat-list class="subsidaries-list" *ngIf="subsidiaryArray && subsidiaryArray.length > 0">
      <mat-list-item *ngFor="let s of subsidiaryArray">
        <div class="row clearfix flex full-width" (click)="viewSubsid(s['ID'])" matTooltip="Click to view details">
          <div class="thrd-width">
            <p class="bold primary-color">{{ s['addresses.Suburb'] }}</p>
          </div>
          <div class="thrd-width">
            <p>{{ s['addresses.State'] }}</p>
          </div>
          <div class="thrd-width">
            <p>{{ s['Phone.Work.Number'] }}</p>
          </div>
        </div>
        <div class="action-button smaller" *ngIf="isPromoterOrAdmin == true">
          <button [matMenuTriggerFor]="listBtnMenu" mat-icon-button>
            <mat-icon>more_vert</mat-icon>
          </button>

          <mat-menu #listBtnMenu="matMenu">
            <button (click)="viewSubsid(s['ID'])" mat-menu-item>
              <mat-icon>description</mat-icon>
              <span>Quick View</span>
            </button>

            <button (click)="editSubsidiary(s['ID'])" mat-menu-item>
              <mat-icon>edit</mat-icon>
              <span>Edit Subsidiary</span>
            </button>

            <button (click)="dissociateSubsidiary(s)" mat-menu-item>
              <mat-icon>link_off</mat-icon>
              <span>Dissociate Subsidiary</span>
            </button>
          </mat-menu>
        </div>
      </mat-list-item>
    </mat-list>
    <div class="subsidaries-list" *ngIf="subsidiaryArray && subsidiaryArray.length == 0">
      <app-empty-list-message [title]="'No subsidiaries Found'" [message]="'There are no subsidiaries in the database'">
      </app-empty-list-message>
    </div>
  </div>
</mat-card>
