<div class="clearfix row">
  <mat-card class="row clearfix selectMerchant card" id="operatorFilter">
    <!--
    <div class="colour-stripe"></div>
    <div class="row clearfix">
      <h4 class="sm-h4 rm-m primary-color">
        <mat-icon class="df">view_list</mat-icon> Data Filter
      </h4>
      <hr>
    </div> -->

    <div class="row clearfix flex mainSearch">
      <div
        class="flex"
        [class.full-width]="filterList.length > 0 || (isAdminOrPromoter == true && displayMerchant == true)"
      >
        <mat-form-field class="" *ngIf="filterList.length > 0" appearance="outline">
          <mat-label>Select Filter</mat-label>
          <mat-select class="searcher" [(ngModel)]="selectedFilter" matSelect placeholder="Select Filter" multiple>
            <mat-option *ngFor="let filter of filterList" [value]="filter.value">{{ filter.name }}</mat-option>
          </mat-select>
        </mat-form-field>

        <div
          class="searcher flex"
          *ngIf="isAdminOrPromoter == true && displayMerchant == true"
          [class.extend]="selectedMerchant && selectedMerchant != 'none'"
        >
          <mat-form-field class="mr" *ngIf="autoCompletedMerchant == false" appearance="outline">
            <mat-label>{{ merchantLabel }}</mat-label>
            <mat-select
              class="merchName"
              [(ngModel)]="selectedMerchant"
              (selectionChange)="changeMerchant()"
              name="MerchantName"
              placeholder=" {{ merchantLabel }}"
            >
              <mat-option *ngIf="currentPracticeID" [value]="currentPracticeID">
                <strong>My {{ "KEYWORD.practice" | translate | titlecase}}</strong>
              </mat-option>

              <mat-option *ngIf="displayAllMerchant == true" [value]="null">
                <strong> All</strong>
              </mat-option>

              <hr/>
              <mat-option *ngFor="let m of merchants | orderBy: ['TradingAs', 'CalculatedName']" [value]="m['ID']">
                <span
                  class="list-label pull-right"
                  *ngIf="m['Status'] && m['Status'] == 'Active'"
                  [style.background]="'#00d07a'"
                >
                  {{ m['Status'] }}
                </span>

                <span
                  class="list-label pull-right"
                  *ngIf="m['Status'] && m['Status'] != 'Active'"
                  [style.background]="'#ff5722'"
                >
                  {{ m['Status'] }}
                </span>

                {{ m['TradingAs'] || m['CalculatedName'] }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="mr" *ngIf="autoCompletedMerchant == true" appearance="outline">
            <mat-label>{{ merchantLabel }}</mat-label>
            <input
              [(ngModel)]="selectedMerchantName"
              [matAutocomplete]="auto"
              (ngModelChange)="filterMerchant($event)"
              name="MerchantName"
              type="text"
              placeholder="{{ merchantLabel }}"
              matInput
            />
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="getSelectedMerchant($event.option.value)">
              <mat-option *ngFor="let m of filterMerchantList" [value]="m">
                {{ m['TradingAs'] || m['CalculatedName'] }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>

          <button
            class="list-button pull-right"
            *ngIf="selectedMerchant && selectedMerchant != 'none'"
            [matTooltip]="('KEYWORD.practice' | translate | titlecase)"
            (click)="viewMerchant(selectedMerchant)"
            mat-stroked-button
          >
            <mat-icon>person</mat-icon>
          </button>
        </div>

        <div class="dateButtonRange mobHide" *ngIf="displayDateRange == true">
          <app-date-range-picker
            [dateLabel]="dateLabel"

            [showCustomRangeLabel]="showCustomRangeLabel"
            [sendNull]="sendNull"
            [showAllTime]="true"
            [selectedRange]="selectDateRange"
            (getRange)="getRangeApplications($event)"
          ></app-date-range-picker>

          <!-- <mat-button-toggle-group>
          <mat-button-toggle *ngFor="let d of  dateRangeArray" [ngClass]="(selectedRange==d.name)?'active':''"
            (click)="changeDateRange(d.name)" class="btn-pill btn-small">{{d.value}}</mat-button-toggle>
        </mat-button-toggle-group> -->
        </div>

        <div class="dateButtonRange mobHide" *ngIf="displayDateSecondRange == true">
          <app-date-range-picker
            [dateLabel]="dateSecondLabel"

            [showCustomRangeLabel]="showCustomRangeLabel"
            [sendNull]="sendNull"
            [showAllTime]="true"
            [selectedRange]="selectDateSecondRange"
            (getRange)="getRangeSecondApplications($event)"
          >
          </app-date-range-picker>

          <!-- <mat-button-toggle-group>
          <mat-button-toggle *ngFor="let d of  dateRangeArray" [ngClass]="(selectedRange==d.name)?'active':''"
            (click)="changeDateRange(d.name)" class="btn-pill btn-small">{{d.value}}</mat-button-toggle>
        </mat-button-toggle-group> -->
        </div>
      </div>

      <div class="pull-right searchButton" *ngIf="displaySearch == true">
        <button
          class="btn btn-small"
          (click)="applySearch()"
          mat-stroked-button
          color="primary"
          matTooltip="{{ searchLabel }}"
        >
          <mat-icon>search</mat-icon>
          <span class="mobHide">{{ searchLabel }}</span>
        </button>
      </div>
    </div>

    <div class="row clearfix flex addFields animated fadeInDown" *ngIf="checkFilter">
      <div
        class="mr flex animated fadeInDown reduce-marg"
        *ngIf="displayDentist == true && checkFilter('staff')"
        [class.extend]="selectedDentist && selectedDentist != 'none'"
      >
        <mat-form-field class="" appearance="outline">
          <mat-select
            class="merchName"
            [(ngModel)]="selectedDentist"
            (selectionChange)="changeDentist()"
            name="DentistName"
            placeholder=" {{ dentistLabel }}"
          >
            <mat-option [value]="null">
              All
            </mat-option>
            <mat-option *ngFor="let m of dentists | orderBy: ['TradingAs', 'CalculatedName']" [value]="m['ID']">
              {{ m['TradingAs'] || m['CalculatedName'] }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <button
          class="btn-close list-button mr pull-right mat-button"
          *ngIf="selectedDentist && selectedDentist != 'none'"
          [matTooltip]="'View ' + ('KEYWORD.practitioner' | translate | titlecase)"
          (click)="viewDentist(selectedDentist)"
        >
          <mat-icon>person_outline</mat-icon>
        </button>
      </div>
      <div
        class="full-width flex searcher animated fadeInDown reduce-marg"
        *ngIf="displayProduct == true && checkFilter('product')"
        [class.extend]="selectedProduct && selectedProduct != 'none'"
      >
        <mat-form-field class="" appearance="outline">
          <mat-select
            class="merchName"
            [(ngModel)]="selectedProduct"
            (selectionChange)="changeProduct()"
            name="ProductName"
            placeholder=" {{ productLabel }}"
          >
            <mat-option [value]="null">
              All
            </mat-option>

            <mat-option *ngFor="let m of products | orderBy: ['MarketingLabel']" [value]="m['ID']">
              {{ m['MarketingLabel'] }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <button
          class="btn-close list-button pull-right mat-button"
          *ngIf="selectedProduct && selectedProduct != 'none' && selectedProduct != '00000000000000000000000000000000'"
          (click)="viewProduct(selectedProduct)"
          matTooltip="View Product"
        >
          <mat-icon>business_center</mat-icon>
        </button>
      </div>
      <div
        class="reduce-marg flex full-width searcher animated fadeInDown"
        *ngIf="displayStatus == true && checkFilter('invitationStatus')"
      >
        <mat-form-field class="" appearance="outline">
          <mat-select
            class="merchName"
            [(ngModel)]="selectedStatus"
            (selectionChange)="changeStatus()"
            name="StatusName"
            placeholder=" {{ statusLabel }}"
          >
            <mat-option *ngFor="let m of invitationUtil.status.all | orderBy" [value]="m">
              {{ invitationUtil.getStatusLabel(m) }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div
        class="reduce-marg animated fadeInDown pull-right searchButton"
        *ngIf="displaySearchFilter == true && selectedFilter.length > 0"
      >
        <button
          class="btn btn-small"
          (click)="applySearch()"
          mat-mini-fab
          color="primary"
          matTooltip="{{ searchLabel }}"
        >
          <mat-icon>search</mat-icon>
        </button>
      </div>
    </div>
  </mat-card>
</div>
