<div class="full-width clearfix">
  <mat-card class="treatment" *ngIf="documents && documents.length > 0">
    <app-ep-document-list-mini
      [title]="'Personal Circumstance Documents'"
      [documents]="documents"
      [isAction]="false"
      [isReplace]="true"
    >
    </app-ep-document-list-mini>
  </mat-card>

  <div *ngIf="documents && documents.length <= 0">
    <app-empty-list-message
      [title]="'No Documents Found'"
      [message]="'There is no documents to show'"
    ></app-empty-list-message>
  </div>
</div>
