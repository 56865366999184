<div class="rel" *ngIf="landingPage && landingPage.ID">
  <form #resetForm="ngForm">
    <div class="card-header primary-gradient-img clearfix confirmModal"
      [attr.style]="'background:' + landingPage.theme.background + ' !important' | safeStyle">
      <button class="btn-close btn-clear mat-button smt white custom-close" (click)="closeEvent()">
        <mat-icon>clear</mat-icon>
      </button>
      <div class="full-width flex main-title-container">
        <div class="row full-width title-container clearfix">
          <div class="title-container-sub">
            <landing-page-header-image class="pull-left landing-page-header-image-view mr"
              [headerImage]="landingPage['Header_Image']" [hideZoomButton]="false">
            </landing-page-header-image>

            <h2 class="text-left title-header rm-m">
              {{ landingPage.Label }}

              <span class="subLabel">
                <span> {{ landingPage.Title }}</span>

                <br />
                <span class="mr">
                  Visitors

                  <span class="badge mr" [endVal]="landingPage.Visitors" countUp></span>
                </span>
                <span class="mr" *ngIf="landingPage && landingPage.documents && landingPage.documents.length">
                  Attached Documents

                  <span class="badge mr" [endVal]="landingPage.documents.length" countUp></span>
                </span>

                <span class="ml chip chipBeta" *ngIf="landingPage['IsActive'] != '1'">Not Active</span>
                <span class="ml chip chipBeta2" *ngIf="landingPage['IsActive'] == '1'"> Active</span>
              </span>
            </h2>
          </div>
        </div>
      </div>
    </div>

    <mat-dialog-content>
      <p class="full-width text-center">
        Choose how you would like to share your landing page. Each option creates a unique tracking code to report on
        visitors and measure success. <br />
        <span *ngIf="landingPage['Type.Code'] == 'FLandPg'">Additionally You can <strong>Customize</strong> the landing
          page before sharing it- this will not affect the
          original record -
        </span>
      </p>
      <p class="text-center rm-m smb"
        *ngIf="landingPage['Type.Code'] == 'FLandPg' && minLoanAmount != null && maxLoanAmount != null" [ngClass]="{
          'error-treatment':
            treatmentAmount && (treatmentAmount < minLoanAmount || treatmentAmount > maxLoanAmount || !treatmentAmount)
        }">
        {{ "KEYWORD.treatment" | translate | titlecase }} Value should be between
        <strong> {{ minLoanAmount | customCurrency }}</strong> and
        <strong> {{ maxLoanAmount | customCurrency }} </strong>
      </p>
      <div class="clearfix text-center smt full-width" *ngIf="landingPage['Type.Code'] == 'FLandPg'">
        <div class="clearfix full-width flex">
          <mat-form-field class="full-width mr" appearance="outline">
            <mat-label>Choose a {{ "KEYWORD.dentist" | translate | titlecase }} </mat-label>
            <mat-select class="full-width" [(ngModel)]="landingPageContactID" (change)="calculDisable()" placeholder=""
              name="detnistID" required>
              <mat-option *ngIf="currentDentist && currentDentist.ID" [value]="currentDentist.ID">
                <strong>My account</strong>
              </mat-option>

              <hr *ngIf="currentDentist && currentDentist.ID" />
              <mat-option *ngFor="let p of dentists" [value]="p['ID']">
                {{ p.CalculatedName }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="full-width mr" appearance="outline">
            <mat-label>Choose product group</mat-label>
            <mat-select class="full-width" [(ngModel)]="productGroup"
              (ngModelChange)="getLoanAmount(productGroup, true)" placeholder="" name="productGroup" required>
              <mat-option *ngFor="let p of financedProductGroup" [value]="p['GroupCode']">
                <mat-icon class="fas {{ productUtil.getProductGroupIcon(p['GroupCode']) }} mr iconproductgroup ">
                </mat-icon>

                {{ productUtil.getLabel(p['GroupCode']) }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="full-width mr" appearance="outline">
            <mat-label>{{"KEYWORD.Treatment" | translate}} Value ($)*</mat-label>
            <input [(ngModel)]="treatmentAmount" (blur)="calculDisable()" name="treatmentAmount" matInput
              placeholder="Please enter a value" currencyMask required />
          </mat-form-field>

          <mat-form-field class="full-width mr" *ngIf="expireDateTypes.length > 0" appearance="outline">
            <mat-label>Expiry Date </mat-label>
            <mat-select class="" [(ngModel)]="expireDateType" (change)="calculDisable()"
              (ngModelChange)="setExpireDate()" name="ExpireDate" placeholder="Expiry Date">
              <mat-option *ngFor="let a of expireDateTypes" [value]="a.code">
                {{ a.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <app-date-input class="full-width" *ngIf="expireDateType && expireDateType != 'never'"
            [defaultDate]="expireDateDefault" [minDate]="'today'" [isTime]="false" [isClear]="false"
            (selectedDate)="selectDateToFilter($event)" label="Expires At">
          </app-date-input>

          <mat-icon class="fas fa-exclamation-circle" *ngIf="showExpired() === true"
            style="color: red; font-size: 1.7rem; margin-top: 14px; margin-left: -5px"></mat-icon>
        </div>

        <div class="full-width text-center mb">
          <button class="btn-large text-center" [disabled]="isDisabled" (click)="saveLandingPageLink()"
            mat-raised-button color="accent">
            Generate new Link
          </button>
        </div>

        <hr class="smb" />
      </div>

      <div class="full-width inner-parent-container">
        <div class="full-width flex inner-parent-row">
          <div class="full-width inner-parent" style="width: 150% !important">
            <h3 class="sr-title text-left mt smb"
              [attr.style]="'color:' + landingPage.theme.color + ' !important' | safeStyle">
              Link with tracking
            </h3>

            <p class="small full-width mb flex inner-text">
              Alternatively, manually copy and add the link to your website, document, social post, email, etc.
              <br />
              <br />
              Each link contains a unique tracker to count the visitor's source.
            </p>

            <div class="full-width inner-footer">
              <button class="btn mb mr pull-left manual-button" (click)="copy('pr')" mat-raised-button>
                <mat-icon class="fas fa-globe smr"
                  [attr.style]="'color:' + landingPage.theme.color + ' !important' | safeStyle"></mat-icon>
                Your Website
              </button>

              <button class="btn mb mr pull-left manual-button" (click)="copy('email')" mat-raised-button>
                <mat-icon class="fas fa-envelope-open smr"
                  [attr.style]="'color:' + landingPage.theme.color + ' !important' | safeStyle"></mat-icon>
                Email
              </button>

              <button class="btn mb mr pull-left manual-button" (click)="copy('sms')" mat-raised-button>
                <mat-icon class="fas fa-sms smr"
                  [attr.style]="'color:' + landingPage.theme.color + ' !important' | safeStyle"></mat-icon>
                SMS
              </button>

              <button class="btn mb mr pull-left manual-button" (click)="copy('facebook')" mat-raised-button>
                <mat-icon class="fab fa-facebook smr"
                  [attr.style]="'color:' + landingPage.theme.color + ' !important' | safeStyle"></mat-icon>
                Facebook
              </button>

              <button class="btn mb mr pull-left manual-button" (click)="copy('twitter')" mat-raised-button>
                <mat-icon class="fab fa-twitter smr"
                  [attr.style]="'color:' + landingPage.theme.color + ' !important' | safeStyle"></mat-icon>
                Twitter
              </button>

              <button class="btn mb mr pull-left manual-button" (click)="copy('linkedin')" mat-raised-button>
                <mat-icon class="fab fa-linkedin smr"
                  [attr.style]="'color:' + landingPage.theme.color + ' !important' | safeStyle"></mat-icon>
                Linkedin
              </button>

              <button class="btn mb mr pull-left manual-button" (click)="copy('pinterest')" mat-raised-button>
                <mat-icon class="fab fa-pinterest smr"
                  [attr.style]="'color:' + landingPage.theme.color + ' !important' | safeStyle"></mat-icon>
                Pinterest
              </button>

              <button class="btn mb mr pull-left manual-button" (click)="copy('i')" mat-raised-button>
                <mat-icon class="fab fa-instagram smr"
                  [attr.style]="'color:' + landingPage.theme.color + ' !important' | safeStyle"></mat-icon>
                Instagram
              </button>
            </div>
          </div>
          <div class="full-width inner-parent" *ngIf="text">
            <h3 class="sr-title text-left mt smb"
              [attr.style]="'color:' + landingPage.theme.color + ' !important' | safeStyle">
              Social Media
            </h3>

            <p class="small full-width mb inner-text">
              Choose the social media channel, and we will create a post automatically.<br />
              <br />

              <strong>TIP</strong> – Press CTRL V to paste copy once the post appears.
            </p>
            <div class="inner-footer">
              <app-social-share-button class="btn-large share-button-doc2 share-button-linkedin smr" *ngIf="
                  landingPage &&
                  _ID &&
                  currentDentist &&
                  currentDentist.ID &&
                  ((landingPage && landingPage.IsDefault != '1') ||
                    (landingPage && landingPage.IsDefault == '1' && isPromoterOrAdmin == true))
                " [label]="'LinkedIn'" [post]="text" [copy]="true" [icon]="'fab fa-linkedin'" [isConfirmModal]="false"
                [url]="env + '/landing-page/redirect/' + _ID + '/l?m=' + merchantID + '&c=' + currentDentist.ID"
                [title]="landingPage.Title" [image]="socialMediaImage" [type]="'linkedin'"
                [tags]="landingPage.Tags || []">
              </app-social-share-button>

              <app-social-share-button class="btn-large share-button-doc2 share-button-twitter smr" *ngIf="
                  landingPage &&
                  _ID &&
                  currentDentist &&
                  currentDentist.ID &&
                  ((landingPage && landingPage.IsDefault != '1') ||
                    (landingPage && landingPage.IsDefault == '1' && isPromoterOrAdmin == true))
                " [label]="'Twitter'" [post]="text" [copy]="true" [icon]="'fab fa-twitter'" [isConfirmModal]="false"
                [url]="env + '/landing-page/redirect/' + _ID + '/t?m=' + merchantID + '&c=' + currentDentist.ID"
                [title]="landingPage.Title" [image]="socialMediaImage" [type]="'twitter'"
                [tags]="landingPage.Tags || []">
              </app-social-share-button>

              <app-social-share-button class="btn-large share-button-doc2 share-button-pinterest smr" *ngIf="
                  landingPage &&
                  _ID &&
                  currentDentist &&
                  currentDentist.ID &&
                  ((landingPage && landingPage.IsDefault != '1') ||
                    (landingPage && landingPage.IsDefault == '1' && isPromoterOrAdmin == true))
                " [label]="'Pinterest'" [post]="text" [copy]="true" [icon]="'fab fa-pinterest'"
                [isConfirmModal]="false"
                [url]="env + '/landing-page/redirect/' + _ID + '/p?m=' + merchantID + '&c=' + currentDentist.ID"
                [title]="landingPage.Title" [image]="socialMediaImage" [type]="'pinterest'"
                [tags]="landingPage.Tags || []">
              </app-social-share-button>

              <app-social-share-button class="btn-large share-button-doc2 share-button-facebook smr" *ngIf="
                  landingPage &&
                  _ID &&
                  currentDentist &&
                  currentDentist.ID &&
                  ((landingPage && landingPage.IsDefault != '1') ||
                    (landingPage && landingPage.IsDefault == '1' && isPromoterOrAdmin == true))
                " [label]="'Facebook'" [post]="text" [copy]="true" [icon]="'fab fa-facebook'" [isConfirmModal]="false"
                [url]="env + '/landing-page/redirect/' + _ID + '/f?m=' + merchantID + '&c=' + currentDentist.ID"
                [title]="landingPage.Title" [image]="socialMediaImage" [type]="'facebook'"
                [tags]="landingPage.Tags || []">
              </app-social-share-button>
            </div>
          </div>
          <div class="full-width inner-parent">
            <h3 class="sr-title text-left mt smb"
              [attr.style]="'color:' + landingPage.theme.color + ' !important' | safeStyle">
              QR code
            </h3>

            <p class="small full-width flex inner-text">
              The code will redirect your {{ "KEYWORD.patient" | translate }}s to dynamic landing pages that are
              personalised to your {{"KEYWORD.practice" | translate}}.
              <br />
              <br />
              Use these in your marketing material to share, such as brochures, newsletters, business cards, email
              footers, etc.
            </p>

            <div class="inner-footer">
              <div class="row clearfix full-width text-center flex">
                <app-generate-qr [downloadLabel]="landingPage.Title" class="full-width app-generate-qr-share ml mr row"
                  *ngIf="urlQR" [source]="urlQR" [size]="160" [download]="downloadQRCodeDental"
                  style="margin-top: 0px !important; margin-bottom: 15px">
                </app-generate-qr>
              </div>

              <div class="row clearfix full-width text-center mt" style="margin-top: 11px !important">
                <button class="btn text-center" *ngIf="landingPage['IsDefault'] !== '1' || isPromoterOrAdmin == true"
                  [attr.style]="'background:' + landingPage.theme.color + ' !important' | safeStyle"
                  (click)="downloadQRCodeDental.emit()" mat-raised-button color="accent">
                  Download QR code
                </button>

                <button class="btn text-center" *ngIf="landingPage['IsDefault'] === '1' && isPromoterOrAdmin != true"
                  [attr.style]="'background:' + landingPage.theme.color + ' !important' | safeStyle"
                  (click)="downloadQRCodeDental.emit()" mat-raised-button color="accent">
                  Download QR code
                </button>
              </div>
            </div>
          </div>

          <div class="full-width inner-parent">
            <h3 class="sr-title text-left mt smb"
              [attr.style]="'color:' + landingPage.theme.color + ' !important' | safeStyle">
              PDF
            </h3>

            <p class="small full-width inner-text">
              Go old school and turn your landing page into a PDF to either share via email or print as a hard copy.

              <br /><br />

            </p>

            <div class="inner-footer">
              <div class="row clearfix full-width text-center flex">
                <img class="pdf-img"
                  src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/pdf.png" />
              </div>



              <div class="row clearfix full-width  text-center  mt " style="
              margin-top: 11px !important;">

                <button mat-raised-button color="accent" class="btn text-center  smr"
                  [attr.style]="('background:' + landingPage.theme.color + ' !important') | safeStyle"
                  *ngIf="landingPage['IsDefault']!=='1' ||  isPromoterOrAdmin==true" (click)="customizePDF()">
                  Customize
                </button>

                <button mat-raised-button color="accent" class="btn text-center "
                  [attr.style]="('background:' + landingPage.theme.color + ' !important') | safeStyle"
                  *ngIf="landingPage['IsDefault']!=='1' ||  isPromoterOrAdmin==true" (click)="ViewPDF()">
                  View
                </button>
              </div>


            </div>
          </div>

          <div class="full-width inner-parent">
            <h3 class="sr-title text-left mt smb"
              [attr.style]="'color:' + landingPage.theme.color + ' !important' | safeStyle">
              Embed
            </h3>

            <p class="small full-width flex inner-text">
              If you want to provide a smoother experience for {{ "KEYWORD.patient" | translate | titlecase }}'s, you
              can set up an
              "Iframe"
              on your webpage. Rather than {{ "KEYWORD.patient" | translate | titlecase }}'s been routed to a new
              webpage.
            </p>

            <div class="inner-footer">
              <div class="row clearfix full-width text-center flex">
                <img class="pdf-img"
                  src="https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/public/img/web-design.png" />
              </div>

              <div class="row clearfix full-width text-center mt" style="margin-top: 11px !important">
                <button class="btn text-center" *ngIf="landingPage['IsDefault'] !== '1' || isPromoterOrAdmin == true"
                  [attr.style]="'background:' + landingPage.theme.color + ' !important' | safeStyle"
                  (click)="viewEmbed()" mat-raised-button color="accent">
                  Setup Iframe
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-dialog-content>

    <hr />
    <div class="drop-button text-center mt text-center full-width">
      <button class="btn-large pull-left" (click)="closeEvent()" mat-raised-button color="primary">close</button>

      <button class="btn-large pull-right" *ngIf="landingPage && landingPage.ID && landingPage.IsActive !== '1'"
        [attr.style]="'background:' + landingPage.theme.color + ' !important' | safeStyle"
        (click)="enable(landingPage.ID)" mat-raised-button color="accent">
        View Landing Page
      </button>

      <button class="btn-large pull-right" *ngIf="landingPage && landingPage.ID"
        [attr.style]="'background:' + landingPage.theme.color + ' !important' | safeStyle" (click)="openIframe()"
        mat-raised-button color="primary">
        View Landing Page
      </button>
    </div>
  </form>
</div>
