<h4 class="sr-title rm-m sm-h4 clearfix mt" *ngIf="canCreate == true || (medications && medications.length > 0)">
  {{ title }}
</h4>

<div class="text-center full-width clearfix" *ngIf="medications && medications.length > 0">
  <div
    class="two-thrd-width flex clearfix"
    class="container-med"
    *ngFor="let m of medications; let index = index"
    [ngClass]="{ pair: index % 2 == 1 }"
  >
    <button class="pull-right" *ngIf="canAction == true" [matMenuTriggerFor]="listBtnMenu" mat-icon-button>
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #listBtnMenu="matMenu">
      <button *ngIf="m && m.ID" (click)="view(m.ID)" mat-menu-item>
        <mat-icon>visibility</mat-icon>
        <span>View Medication </span>
      </button>

      <button *ngIf="canEdit == true" (click)="edit(m, index)" mat-menu-item>
        <mat-icon>edit</mat-icon>
        <span>edit</span>
      </button>

      <button *ngIf="m && m.Drug_key" (click)="viewDrug(m.Drug_key)" mat-menu-item>
        <mat-icon class="fas fa-pills"></mat-icon>
        <span>View drug</span>
      </button>

      <button *ngIf="m && m.drugID" (click)="viewDrug(m.drugID)" mat-menu-item>
        <mat-icon class="fas fa-pills"></mat-icon>
        <span>View drug</span>
      </button>

      <button *ngIf="canEdit == true" (click)="remove(m, index)" mat-menu-item>
        <mat-icon class="remove-button">delete</mat-icon>
        <span>Remove</span>
      </button>
    </mat-menu>

    <button class="pull-right" *ngIf="canEdit == true && canAction != true" (click)="remove(m, index)" mat-icon-button>
      <mat-icon class="remove-button">delete</mat-icon>
    </button>

    <button class="pull-right" *ngIf="canEdit == true && canAction != true" (click)="edit(m, index)" mat-icon-button>
      <mat-icon>edit</mat-icon>
    </button>

    <button
      class="pull-right"
      *ngIf="canView == true && canAction != true && m && m.ID"
      (click)="view(m.ID)"
      mat-icon-button
    >
      <mat-icon>visibility</mat-icon>
    </button>

    <p class="text-left" *ngIf="m && m.ID && canEdit == true" (click)="edit(m, index)">
      {{ m['Drug.Name'] }}
    </p>

    <p class="text-left" *ngIf="m && m.ID && canEdit != true" (click)="view(m.ID)">
      {{ m['Drug.Name'] }}
    </p>

    <p class="text-left" *ngIf="m && !m.ID" (click)="edit(m, index)">
      {{ m.name }}
    </p>
  </div>
</div>

<div
  class="text-center full-width mb mt"
  *ngIf="
    (canCreate == true && canYesNo != true) ||
    (canCreate == true && canYesNo == true && medications && medications.length > 0)
  "
>
  <button class="full-width" (click)="addMedication()" mat-raised-button color="warn">
    <mat-icon>add</mat-icon> Add medication
  </button>
</div>
<div
  class="text-center card-illness full-width mb mt"
  *ngIf="canCreate == true && canYesNo == true && (!medications || (medications && medications.length <= 0))"
>
  <mat-button-toggle-group
    class="divided-toggle animate full-width text-center"
    [(ngModel)]="takeDrug"
    name="stillSuffer"
  >
    <mat-button-toggle class="btn-icon thrd-width no" [value]="false" color="accent" ngDefaultControl>
      <mat-icon class="fas fa-thumbs-down"></mat-icon>
      No
    </mat-button-toggle>
    <mat-button-toggle
      class="btn-icon thrd-width yes"
      [value]="true"
      (click)="addMedication()"
      color="primary"
      ngDefaultControl
    >
      <mat-icon class="fas fa-thumbs-up"></mat-icon>Yes
    </mat-button-toggle>
  </mat-button-toggle-group>
</div>
