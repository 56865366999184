<div class="row clearfix">
  <div class="pull-left">
    <h2 *ngIf="doc.label" mat-dialog-title>{{ doc.label }}</h2>
    <p class="detail-p" *ngIf="doc.description"><strong>description:</strong> {{ doc.description }}</p>
  </div>
  <div class="pull-right">
    <!-- Modal dismiss button -->
    <button class="btn-close btn-clear mat-button" matDialogClose>
      <mat-icon>clear</mat-icon>
    </button>
  </div>
</div>
<mat-dialog-content>
  <div class="row clearfix text-center" *ngIf="doc">
    <button
      class="btn-large"
      *ngIf="doc && doc.url !== ''"
      (click)="openLink()"
      mat-raised-button
      color="accent"
      matTooltip=" Open link"
    >
      Open {{ doc.label }} link
    </button>
    <div class="document">
      <div class="view-container" *ngIf="doc && doc.extension == 'png'">
        <iframe #iframe [attr.src]="fileURL" (load)="onFrameLoad($event)" target="_parent" type="image/png"></iframe>
      </div>
      <div class="view-container" *ngIf="doc && doc.extension == 'jpeg'">
        <iframe #iframe [attr.src]="fileURL" (load)="onFrameLoad($event)" target="_parent" type="image/jpeg"></iframe>
      </div>
      <div class="view-container" *ngIf="doc && doc.extension == 'heic'">
        <iframe #iframe [attr.src]="fileURL" (load)="onFrameLoad($event)" target="_parent" type="image/heic"></iframe>
      </div>
      <div class="view-container" *ngIf="doc && doc.extension == 'pdf'">
        <iframe
          #iframe
          [attr.src]="fileURL"
          (load)="onFrameLoad($event)"
          target="_parent"
          type="application/pdf"
        ></iframe>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <!--  <div *ngIf="extraInfoDisplay">

    <p *ngIf="doc.date" class="detail-p">
      <strong>Added at:</strong> {{doc.date | customDate}}</p>
  </div> -->
  <div class="row clearfix">
    <div class="pull-left">
      <!-- Download link -->
      <button
        class="btn-small"
        *ngIf="doc && (doc.extension == 'pdf' || doc.extension == 'png' || doc.extension == 'jpeg' || doc.extension == 'heic')"
        (click)="downloadFile()"
        mat-mini-fab
        matTooltip="Download {{ doc.label }} Document"
      >
        <mat-icon>file_download</mat-icon>
      </button>
    </div>
    <div class="close pull-right" *ngIf="docs.length > 1">
      <button class="step-back" [disabled]="fileIndex == 1" (click)="getPreviousFile()" mat-mini-fab color="primary">
        <mat-icon>keyboard_arrow_left</mat-icon>
      </button>
      <button
        class="step-back"
        [disabled]="fileIndex == docs.length"
        (click)="getNextFile()"
        mat-mini-fab
        color="primary"
      >
        <mat-icon>keyboard_arrow_right</mat-icon>
      </button>
    </div>
  </div>
</mat-dialog-actions>
