<app-map-multiple-view
  class="full-width clearfix row"
  [streetNameAttribute]="'addresses.Street Name'"
  [streetNumberAttribute]="'addresses.Street Nr'"
  [unitNumberAttribute]="'addresses.Unit'"
  [stateAttribute]="'addresses.State'"
  [suburbAttribute]="'addresses.Suburb'"
  [streetTypeAttribute]="'addresses.Street Type'"
  [countryAttribute]="'addresses.Country.Code'"
  [postCodeAttribute]="'addresses.Postcode'"
  [markerTitleAttribute]="'CalculatedName'"
  [markerDescriptionAttribute]="'addresses.Calculated'"
  [addMarker]="addMarkerEvent"
  [title]="title"
  [resetPositions]="resetPositionsEvent"
  [description]="description"
  (addCoordinate)="addCoordinateEvent($event)"
  (getSelected)="getSelected($event)"
></app-map-multiple-view>

<div class="full-width clearfix row" *ngIf="(!existingPatients || existingPatients.length <= 0) && isLoaded == true">
  <app-empty-list-message></app-empty-list-message>
</div>
