import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AuthenticationModule } from '../../core/authentication/authentication.module';
import { HelperModule } from '../../core/helper/helper.module';
import { SharedModule } from '../../shared/shared.module';
import { BeneficiaryModule } from '../beneficiary/beneficiary.module';
import { CustomerModule } from '../customer/customer.module';
import { DentistModule } from '../dentist/dentist.module';
import { ExistingPatientModule } from '../existing-patient/existing-patient.module';
import { InvitationModule } from '../invitation/invitation.module';
import { MerchantModule } from '../merchant/merchant.module';
import { OperatorModule } from '../operator/operator.module';
import { ContactInputComponent } from './contact-input/contact-input.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { ContactUsRoutingModule } from './shared/contact-us-routing.module';
import { ContactUsService } from './shared/contact-us.service';
import { UnsubscribeComponent } from './unsubscribe/unsubscribe.component';

@NgModule({
  declarations: [ContactInputComponent, ContactUsComponent, UnsubscribeComponent],
  imports: [
    CommonModule,
    ContactUsRoutingModule,
    SharedModule,
    AuthenticationModule,
    InvitationModule,
    BeneficiaryModule,
    DentistModule,
    OperatorModule,
    MerchantModule,
    CustomerModule,
    ExistingPatientModule,
    HelperModule,
  ],
  exports: [ContactInputComponent, ContactUsComponent, UnsubscribeComponent],
  providers: [ContactUsService],
})
export class ContactUsModule {}
