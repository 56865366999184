import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';

import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
/**
 * @title Feature-rich data table
 */
@Component({
  selector: 'app-existing-patient-map-overview',
  templateUrl: './existing-patient-map-overview.component.html',
  styleUrls: ['./existing-patient-map-overview.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('*', style({ opacity: 0 }), { optional: true }),
        query(
          '*',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('*', style({ opacity: 1 }), { optional: true }),
        query(
          '*',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class ExistingPatientMapOverViewComponent implements OnInit {
  isAdminOrPromoter = false;

  merchants = [];
  dateFrom;
  dateTo;
  _dateFrom;
  _dateTo;
  _merchantID = null;
  merchantID = 'none';

  hideExtraComponents = false;

  constructor(private AuthenticationService: AuthenticationService) {}
  ngOnInit() {
    this.AuthenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isAdminOrPromoter = res;
    });
  }

  viewDetails(e) {
    this.hideExtraComponents = e;
  }
  getMerchant(d) {
    this._merchantID = d;
  }

  getDateFrom(d) {
    this._dateFrom = d;
  }

  getDateTo(d) {
    this._dateTo = d;
  }

  applySearch(result) {
    this.merchantID = result.merchantID;
    this.dateFrom = result.dateFrom;
    this.dateTo = result.dateTo;
  }
}
