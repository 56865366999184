import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';

@Pipe({
  name: 'OrderArrayObject',
})
export class OrderArrayObject implements PipeTransform {
  constructor() {}

  transform(expression: any, format: any) {
    try {
      let result = expression;

      if (Array.isArray(expression) && Array.isArray(format) && format.length > 0) {
        const _order = [];
        const _format = [];

        for (let i = 0; i < format.length; i++) {
          let item = format[i];
          if (moment.isDate(item)) {
            item = moment(item).toDate();
          }

          if (item[0] == '-') {
            _order.push('desc');
            _format.push(item.substr(1));
          } else {
            _order.push('asc');
            _format.push(item);
          }
        }

        result = _.orderBy(expression, _format, _order);

        return result;
      } else if (Array.isArray(expression) && typeof format == 'string') {
        const _order = [];
        const _format = [];

        if (moment.isDate(format)) {
          format = moment(format).toDate();
        }

        if (format[0] == '-') {
          _order.push('desc');
          _format.push(format.substr(1));
        } else {
          _order.push('asc');
          _format.push(format);
        }

        result = _.orderBy(expression, _format, _order);

        return result;
      } else {
        return expression;
      }
    } catch (e) {
      return expression;
    }
  }
}
