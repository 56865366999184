import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import { map } from 'rxjs/operators';

@Injectable()
export class ActionLogService {
  constructor(private http: HttpClient) {}

  getInvitationEvents(payload, sessionType: String): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }

    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http
        .get(environment.nodeUrl + '/event-invitation/global', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
      return this.http
        .get(environment.nodeUrl + '/event-invitation', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'customer') {
      return this.http
        .get(environment.nodeUrl + '/event-invitation/customer', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/event-invitation', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getContractEvents(payload, sessionType: String): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }

    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http
        .get(environment.nodeUrl + '/event-contract/global', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
      return this.http
        .get(environment.nodeUrl + '/event-contract', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'customer') {
      return this.http
        .get(environment.nodeUrl + '/event-contract/customer', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/event-contract', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getMessageEvents(payload, sessionType: String): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }

    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http
        .get(environment.nodeUrl + '/event-message/global', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
      return this.http
        .get(environment.nodeUrl + '/event-message', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'customer') {
      return this.http
        .get(environment.nodeUrl + '/event-message/customer', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/event-message', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getEventCode(): Observable<any> {
    return this.http.get(environment.nodeUrl + '/event-code/').pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getOneInvitationEventView(ID, sessionType: String) {
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http
        .get(environment.nodeUrl + '/event-invitation/global/' + ID)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
      return this.http
        .get(environment.nodeUrl + '/event-invitation/' + ID)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'customer') {
      return this.http
        .get(environment.nodeUrl + '/event-invitation/customer/' + ID)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/event-invitation/' + ID)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getOneContractEventView(ID, sessionType: String) {
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http
        .get(environment.nodeUrl + '/event-contract/global/' + ID)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
      return this.http
        .get(environment.nodeUrl + '/event-contract/' + ID)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'customer') {
      return this.http
        .get(environment.nodeUrl + '/event-contract/customer/' + ID)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/event-contract/' + ID)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getOneMessageEventView(ID, sessionType: String) {
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http
        .get(environment.nodeUrl + '/event-message/global/' + ID)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
      return this.http
        .get(environment.nodeUrl + '/event-message/' + ID)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'customer') {
      return this.http
        .get(environment.nodeUrl + '/event-message/customer/' + ID)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/event-message/' + ID)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getActionLogList(payload, sessionType: String) {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }

    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http
        .get(environment.nodeUrl + '/action-log/global', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
      return this.http
        .get(environment.nodeUrl + '/action-log', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'customer') {
      return this.http
        .get(environment.nodeUrl + '/action-log/customer', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/action-log', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }
  getActionLogListHidden(payload, sessionType: String) {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }

    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http
        .get(environment.nodeUrl + '/action-log/hidden/global', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
      return this.http
        .get(environment.nodeUrl + '/action-log/hidden', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'customer') {
      return this.http
        .get(environment.nodeUrl + '/action-log/hidden/customer', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/action-log/hidden', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }
  getOneActionLog(ID, sessionType: String) {
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http
        .get(environment.nodeUrl + '/action-log/global/' + ID)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
      return this.http
        .get(environment.nodeUrl + '/action-log/' + ID)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'customer') {
      return this.http
        .get(environment.nodeUrl + '/action-log/customer/' + ID)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/action-log/' + ID)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getActionCode() {
    return this.http.get(environment.nodeUrl + '/action-log-code/').pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getStatistics(body, isPromoterOrAdmin = false, isHidden = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        params = params.set(key, body[key]);
      }
    }

    options = {
      params,
    };

    if (isHidden == true) {
      if (isPromoterOrAdmin == true) {
        return this.http
          .get(environment.nodeUrl + '/action-log/statistics-hidden/global', options)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else {
        return this.http
          .get(environment.nodeUrl + '/action-log/statistics-hidden', options)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      }
    } else {
      if (isPromoterOrAdmin == true) {
        return this.http
          .get(environment.nodeUrl + '/action-log/statistics/global', options)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else {
        return this.http
          .get(environment.nodeUrl + '/action-log/statistics', options)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      }
    }
  }

  markAsViewed(id): Observable<any> {
    return this.http
      .put(environment.nodeUrl + '/action-log/viewed/' + id, {})
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  markAsNotViewed(id): Observable<any> {
    return this.http
      .put(environment.nodeUrl + '/action-log/notViewed/' + id, {})
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  markAllAsViewed(payload): Observable<any> {
    return this.http
      .put(environment.nodeUrl + '/action-log/viewedAll/', payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  flagged(id): Observable<any> {
    return this.http
      .put(environment.nodeUrl + '/action-log/flagged/' + id, {})
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  removeFlagged(id): Observable<any> {
    return this.http
      .put(environment.nodeUrl + '/action-log/removeFlagged/' + id, {})
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  archived(id): Observable<any> {
    return this.http
      .put(environment.nodeUrl + '/action-log/archived/' + id, {})
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }
}
