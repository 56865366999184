import { Location } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output, SimpleChanges } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { LookupService } from '../../../shared/services/lookup.service';
import { DentistService } from '../../dentist/shared/dentist.service';
import { TreatmentService } from '../../treatment/shared/treatment.service';
import { MarketingFilterCreateComponent } from '../marketing-filter-create/marketing-filter-create.component';
import { MarketingService } from '../../marketing/shared/marketing.service';

@Component({
  selector: 'app-marketing-filter-view',
  templateUrl: 'marketing-filter-view.component.html',
  styleUrls: ['./marketing-filter-view.component.css'],
})
export class MarketingFilterViewComponent implements OnInit {
  @Input()
  isEdit = false;

  @Input()
  filterID;

  @Input()
  viewModal = true;

  close = new EventEmitter();
  @Input()
  filter;

  @Output()
  getEdit = new EventEmitter();

  @Input()
  hideTreatment = false;

  isModal = false;

  dentistList;
  patientChioce;
  contractStatus;
  quotationStatus;

  treatmentList;
  treatmentProductList;
  treatmentBrandList;

  isParams = false;
  isPromoterOrAdmin = false;

  constructor(
    private marketingService: MarketingService,
    private lookupService: LookupService,
    private dentistService: DentistService,
    private treatmentService: TreatmentService,
    private activeRoute: ActivatedRoute,
    private location: Location,
    private authenticationService: AuthenticationService,
    private router: Router,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      this.filterID = data;

      this.isModal = true;
    }
  }

  ngOnInit() {
    this.authenticationService.isPromoterOrAdmin().subscribe((r) => {
      this.isPromoterOrAdmin = r;

      this.activeRoute.params.subscribe((params) => {
        if (params['filterID']) {
          this.filterID = params['filterID'];
          this.isParams = true;
        }

        if (this.filter && this.filter.ID) {
          if (this.hideTreatment !== true) {
            for (let i = 0; i < this.filter['Query'].length; i++) {
              this.setUpTreatmentProperty(this.filter['Query'][i]);
            }
          }
        } else if (this.filterID) {
          this.marketingService.getOneFilter(this.filterID, this.isPromoterOrAdmin).subscribe((res) => {
            if (res) {
              this.filter = res;

              if (this.hideTreatment !== true) {
                for (let i = 0; i < this.filter['Query'].length; i++) {
                  this.setUpTreatmentProperty(this.filter['Query'][i]);
                }
              }
            }
          });
        }
      });
    });
  }

  setup() {
    if (this.filter && this.filter.ID) {
      if (this.hideTreatment !== true) {
        for (let i = 0; i < this.filter['Query'].length; i++) {
          this.setUpTreatmentProperty(this.filter['Query'][i]);
        }
      }
    } else if (this.filterID) {
      this.marketingService.getOneFilter(this.filterID, this.isPromoterOrAdmin).subscribe((res) => {
        if (res) {
          this.filter = res;
          if (this.hideTreatment !== true) {
            for (let i = 0; i < this.filter['Query'].length; i++) {
              this.setUpTreatmentProperty(this.filter['Query'][i]);
            }
          }
        }
      });
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    this.authenticationService.isPromoterOrAdmin().subscribe((r) => {
      this.isPromoterOrAdmin = r;
      this.setup();
    });
  }

  edit() {
    if (!this.filterID && this.filter && this.filter.ID) {
      this.filterID = this.filter.ID;
    }
    if (this.filterID) {
      const ref = RootAppComponent.dialog.open(MarketingFilterCreateComponent, {
        data: {
          filterID: this.filterID,
          standalone: true,
        },
        width: '900px',

        panelClass: 'noCard',
      });
      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });

      ref.componentInstance.getResult.subscribe((res) => {
        if (res) {
          this.filter = res;
          for (let i = 0; i < this.filter['Query'].length; i++) {
            this.setUpTreatmentProperty(this.filter['Query'][i]);
          }

          this.getEdit.emit(res);
          ref.close();
        }
      });
    }
  }

  openFilterView() {
    this.close.emit();
    this.router.navigate(['/merchant', { outlets: { page: ['marketing-filter-view', this.filterID] } }]);
  }

  setUpTreatmentProperty(item) {
    if (item['TypeCode'] == 'INV') {
      const __p = {
        fields: 'ID,CalculatedName',
        merchantID: this.filter['Merchant_key'],
      };

      this.dentistService.getList(__p).subscribe((res) => {
        if (res && res.length > 0) {
          this.dentistList = res;
          this.lookupService.getLookup('CodeLookup', 'InvitationChoice').subscribe((res) => {
            this.patientChioce = res;
          });
        }
      });
    } else if (item['TypeCode'] == 'CNT') {
      this.lookupService.getLookup('CodeLookup', 'ContractStatus').subscribe((res) => {
        this.contractStatus = res;

        this.lookupService.getLookup('CodeLookup', 'QuotationStatus').subscribe((res) => {
          this.quotationStatus = res;
        });
      });
    } else if (
      item['TypeCode'] == 'PRD' ||
      item['TypeCode'] == 'BRN' ||
      item['TypeCode'] == 'TYP' ||
      item['TypeCode'] == 'CAT'
    ) {
      const __p = {
        merchantID: this.filter['Merchant_key'],
        fields:
          'TreatmentProduct_key,Product.Label,TreatmentBrand_key,TreatmentBrand.Label,TreatmentType_key,TreatmentType.Label,TreatmentCategory_key,TreatmentCategory.Label',
      };
      this.treatmentService.getTreatmentEPList(__p).subscribe((res) => {
        if (res && res.length > 0) {
          this.treatmentList = res;

          if (item['TypeCode'] == 'PRD') {
            const __p = {
              merchantID: this.filter['Merchant_key'],
              fields:
                'Manufacturer_Key.Effective,Manufacturer.Name.Effective,Manufacturer_Key.Effective,Manufacturer.Name.Effective',
            };
            this.treatmentService.getTreatmentProductList(__p).subscribe((res) => {
              if (res && res.length > 0) {
                this.treatmentProductList = res;
              }
            });
          } else if (item['TypeCode'] == 'BRD') {
            const __p = {
              merchantID: this.filter['Merchant_key'],
              fields: 'Manufacturer_Key,Manufacturer.Name,Distributor_Key,Distributor.Name',
            };
            this.treatmentService.getTreatmentBrandList(__p).subscribe((res) => {
              if (res && res.length > 0) {
                this.treatmentBrandList = res;
              }
            });
          }
        }
      });
    }
  }

  closeEvent() {
    this.close.emit(true);
  }

  displayContent(section, line) {
    let result = '';
    const property = line['Properties'][0];
    if (section == 'AL') {
      if (line['Type']) {
        result = `${line['Category']['Label']} ${line['Type']['Label']}`;
      } else {
        result = `${line['Category']['Label']}`;
      }
    } else if (section == 'IL') {
      result = `${line['Type']['Label']}`;
    } else if (section == 'MH') {
      result = `${property['Label']}`;
    } else if (section == 'CRD') {
      if (property['Label'] == 'Age' || property['Label'] == 'Postcode') {
        if (property['ValueMin'] && property['ValueMax']) {
          if (Number(property['ValueMin']) == Number(property['ValueMax'])) {
            result = `${property['Label']}: ${property['ValueMin']}`;
          } else {
            result = `${property['Label']}: between ${property['ValueMin']} and ${property['ValueMax']}`;
          }
        } else if (property['ValueMin'] && !property['ValueMax']) {
          result = `${property['Label']}: greater than ${property['ValueMin']}`;
        } else if (!property['ValueMin'] && property['ValueMax']) {
          result = `${property['Label']}: less than ${property['ValueMax']}`;
        }
      } else if (property['Label'] == 'Gender') {
        const _gender = property['Value'] == 'F' ? 'Female' : 'Male';

        result = `Gender: ${_gender}`;
      } else {
        result = `${property['Label']}: ${property['Value']}`;
      }
    } else if (section == 'INV') {
      if (property['Code'] == 'Customer_Choice' && this.patientChioce) {
        const value = this.patientChioce.filter((x) => x['Code'] == property['Value'])[0];

        result = `${property['Label']}: ${value['Label']}`;
      } else if (property['Code'] == 'Merchant_Contact_FK' && this.dentistList) {
        const value = this.dentistList.filter((x) => x['ID'] == property['Value'])[0];

        result = `${property['Label']}: ${value['CalculatedName']}`;
      } else if (property['Code'] == 'Date_Created') {
        result = `${property['Label']}: ${property['Value']}`;
      } else {
        result = `${property['Label']}`;
      }
    } else if (section == 'CNT') {
      if (property['Code'] == 'Contract_Status' && this.contractStatus) {
        const value = this.contractStatus.filter((x) => x['Code'] == property['Value'])[0];

        result = `${property['Label']}: ${value['Label']}`;
      } else if (property['Code'] == 'Quotation_Status' && this.quotationStatus) {
        const value = this.quotationStatus.filter((x) => x['Code'] == property['Value'])[0];

        result = `${property['Label']}: ${value['Label']}`;
      } else {
        result = `${property['Label']}: ${property['Value']}`;
      }
    } else if (section == 'PRD') {
      if (property['Code'] == 'Service_Product_FK' && this.treatmentList) {
        const value = this.treatmentList.filter((x) => x['TreatmentProduct_key'] == property['Value'])[0];

        result = `${property['Label']}: ${value['Product.Label']}`;
      } else if (property['Code'] == 'Manufacturer_NFK' && this.treatmentProductList) {
        const value = this.treatmentProductList.filter((x) => x['Manufacturer_Key.Effective'] == property['Value'])[0];

        result = `${property['Label']}: ${value['Manufacturer.Name.Effective']}`;
      } else if (property['Code'] == 'Distributor_NFK' && this.treatmentProductList) {
        const value = this.treatmentProductList.filter((x) => x['Distributor_Key.Effective'] == property['Value'])[0];

        result = `${property['Label']}: ${value['Distributor.Name.Effective']}`;
      }
    } else if (section == 'BRN') {
      if (property['Code'] == 'Brand_FK' && this.treatmentList) {
        const value = this.treatmentList.filter((x) => x['TreatmentBrand_key'] == property['Value'])[0];

        result = `${property['Label']}: ${value['TreatmentBrand.Label']}`;
      } else if (property['Code'] == 'Manufacturer_NFK' && this.treatmentBrandList) {
        const value = this.treatmentBrandList.filter((x) => x['Manufacturer_Key'] == property['Value'])[0];

        result = `${property['Label']}: ${value['Manufacturer.Name']}`;
      } else if (property['Code'] == 'Distributor_NFK' && this.treatmentBrandList) {
        const value = this.treatmentBrandList.filter((x) => x['Distributor_Key'] == property['Value'])[0];

        result = `${property['Label']}: ${value['Distributor.Name']}`;
      }
    } else if (section == 'TYP') {
      if (this.treatmentList) {
        const value = this.treatmentList.filter((x) => x['TreatmentType_key'] == property['Value'])[0];

        result = `${property['Label']}: ${value['TreatmentType.Label']}`;
      }
    } else if (section == 'CAT') {
      if (this.treatmentList) {
        const value = this.treatmentList.filter((x) => x['TreatmentCategory_key'] == property['Value'])[0];

        result = `${property['Label']}: ${value['TreatmentCategory.Label']}`;
      }
    } else {
      result = '';
    }

    return result;
  }

  goback() {
    this.location.back();
  }
}
