<div class="reduce-padding mb clearfix mat-elevation-z1 full-width white-background">
  <div class="toggle-card row clearfix full-width mb">
    <mat-button-toggle-group class="btn-group row clearfix flex full-width text-center" [(ngModel)]="filter">
      <mat-button-toggle class="text-center full-width" [value]="'Merchant'"> Merchant</mat-button-toggle>
      <mat-button-toggle class="text-center full-width" [value]="'Promoter'"> Promoter</mat-button-toggle>
      <mat-button-toggle class="text-center full-width"
                         [value]="'Patient'"> {{ "KEYWORD.patient" | translate | titlecase }}</mat-button-toggle>
      <mat-button-toggle class="text-center full-width" [value]="'MerchantProspect'">
        Merchant Prospect
      </mat-button-toggle>
      <mat-button-toggle class="text-center full-width" [value]="'Supplier'"> Supplier</mat-button-toggle>
      <mat-button-toggle class="text-center full-width" [value]="'Funder'"> Funder</mat-button-toggle>
      <mat-button-toggle class="text-center full-width" [value]="'All'"> All</mat-button-toggle>
    </mat-button-toggle-group>
  </div>

  <div class="row clearfix full-width tabArea mt white-background" *ngIf="filter == 'Merchant'" @ngIfAnimation>
    <app-operator-list class="row clearfix animate" [utimateTables]="['Merchant']"></app-operator-list>
  </div>

  <div class="row clearfix full-width tabArea mt white-background" *ngIf="filter == 'Promoter'" @ngIfAnimation>
    <app-operator-list class="row clearfix animate" [utimateTables]="['Promoter']"></app-operator-list>
  </div>

  <div class="row clearfix full-width tabArea mt white-background" *ngIf="filter == 'Patient'" @ngIfAnimation>
    <app-operator-list class="row clearfix animate" [utimateTables]="['Prospect', 'Customer', 'CustomersAndProspects']">
    </app-operator-list>
  </div>

  <div class="row clearfix full-width tabArea mt white-background" *ngIf="filter == 'MerchantProspect'" @ngIfAnimation>
    <app-operator-list class="row clearfix animate" [utimateTables]="['MerchantProspect']"></app-operator-list>
  </div>

  <div class="row clearfix full-width tabArea mt white-background" *ngIf="filter == 'Supplier'" @ngIfAnimation>
    <app-operator-list class="row clearfix animate" [utimateTables]="['Benificiary']"></app-operator-list>
  </div>

  <div class="row clearfix full-width tabArea mt white-background" *ngIf="filter == 'Funder'" @ngIfAnimation>
    <app-operator-list class="row clearfix animate" [utimateTables]="['Funder']"></app-operator-list>
  </div>

  <div class="row clearfix full-width tabArea mt white-background" *ngIf="filter == 'All'" @ngIfAnimation>
    <app-operator-list class="row clearfix animate"></app-operator-list>
  </div>
</div>
