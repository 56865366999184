import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../../../common/core/authentication/shared/authentication.service';
import { ConfirmDialogComponent } from '../../../../common/shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../../common/shared/components/root-component/root-component.component';
import { UtilsService } from '../../../../common/shared/services/utils.service';
import { ColorPalette } from '../../../../common/shared/types/color-palette';
import { ConfirmDialog } from '../../../../common/shared/types/confirm-dialog';
import { Settings } from '../../../../common/shared/types/settings';

@Component({
  selector: 'app-integration',
  templateUrl: './integration.component.html',
  styleUrls: ['./integration.component.css'],
})
export class IntegrationComponent implements OnInit {
  color = Settings.global['primaryColor'] || '#1e88e5';
  colorSecond;
  transactionToken;
  redirectURL;

  constructor(
    private utilService: UtilsService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private activeRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    if (this.color && !this.colorSecond) {
      this.onColorPickerClose(this.color);
    }

    const origin = window.location.origin;
    const pathname = window.location.pathname;
    let link = window.location.href;

    if (link && origin) {
      if (pathname) {
        link = link.replace(origin + pathname, '');
      } else {
        link = link.replace(origin, '');
      }
    }

    const urlParams = new URLSearchParams(link);
    this.redirectURL = urlParams.get('redirect_uri');

    this.activeRoute.params.subscribe((params) => {
      if (params['transactionToken']) {
        this.transactionToken = params['transactionToken'];
      }

      if (this.transactionToken) {
        this.authenticationService.isLogin().subscribe((res) => {
          if (res == true) {
            const confirm = new ConfirmDialog(
              'info',
              'You are already Connected!',
              'We detected you have an open session, you need to logout before Proceeding to the Merchant Portal',
              'Cancel',
              'Logout and Proceed'
            );

            const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
              data: confirm,
              disableClose: true,
            });
            ref.componentInstance.onConfirm.subscribe((confirmation) => {
              if (confirmation === false) {
                ref.close();
                if (this.redirectURL) {
                  const __url = this.redirectURL.replace(/\$/g, '/');
                  this.router.navigateByUrl(__url);
                } else {
                  this.router.navigate(['/merchant/']);
                }
              } else {
                this.authenticationService.logout().subscribe((res) => {
                  this.authenticationService
                    .loginWithToken({ transaction_token: this.transactionToken })
                    .subscribe((result) => {
                      if (result) {
                        this.authenticationService.setCurrentUser({ data: result });
                        this.authenticationService.getCurrentUser().subscribe((resCur) => {
                          this.authenticationService.isLoggedIn = true;

                          if (this.redirectURL) {
                            this.utilService.getCurrentAccess().subscribe((res) => {
                              const __url = this.redirectURL.replace(/\$/g, '/');
                              this.router.navigateByUrl(__url);
                            });
                          } else {
                            this.utilService.getCurrentAccess().subscribe((res) => {
                              this.router.navigate(['/merchant/']);
                            });
                          }
                        });
                        ref.close();
                      } else {
                        ref.close();
                        this.router.navigate([
                          '/403',
                          'raw',
                          'Token is not valid',
                          'Transaction token is not valid or removed ',
                        ]);
                      }
                    });
                });
              }
            });
          } else {
            this.authenticationService
              .loginWithToken({ transaction_token: this.transactionToken })
              .subscribe((result) => {
                if (result) {
                  this.authenticationService.setCurrentUser({ data: result });
                  this.authenticationService.getCurrentUser().subscribe((resCur) => {
                    this.authenticationService.isLoggedIn = true;

                    if (this.redirectURL) {
                      this.utilService.getCurrentAccess().subscribe((res) => {
                        const __url = this.redirectURL.replace(/\$/g, '/');
                        this.router.navigateByUrl(__url);
                      });
                    } else {
                      this.utilService.getCurrentAccess().subscribe((res) => {
                        this.router.navigate(['/merchant/']);
                      });
                    }
                  });
                } else {
                  this.router.navigate([
                    '/403',
                    'raw',
                    'Token is not valid',
                    'Transaction token is not valid or removed ',
                  ]);
                }
              });
          }
        });
      } else {
        this.router.navigate(['/404-not-found', 'raw']);
      }
    });
  }

  onColorPickerClose(color: string): void {
    if (color) {
      this.color = color;

      const v = new ColorPalette(color);
      const palette = v.getPalette();

      if (palette && palette.length > 0) {
        const index = palette.findIndex((item) => {
          if (item && item.name == 800) {
            return true;
          } else {
            return false;
          }
        });

        if (palette[index] && palette[index]['hex']) {
          this.colorSecond = palette[index]['hex'];
        }
      }
    }
  }
}
