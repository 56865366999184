<div class="card-header primary-gradient-img clearfix inModal">
  <button class="btn-close btn-clear mat-button" (click)="closeEvent()">
    <mat-icon>clear</mat-icon>
  </button>

  <div class="row titleArea clearfix">
    <mat-icon class="fas fa-arrow-circle-up"></mat-icon>
    <h2 class="summary-header rm-m">
      Update your website
      <span class="subLabel"> Add a {{ "BRANDING.Brand Name" | translate | titlecase }} call-to-action to your website
      </span>
    </h2>
  </div>
</div>

<mat-dialog-content class="uploader">
  <div class="row clearfix">
    <p>Choose one of the options below to download and send to your web developer</p>

    <mat-list class="download-list add-mb">
      <mat-list-item>
        <mat-icon class="left" matListIcon fontSet="fas" fontIcon="fa-file-code"></mat-icon>
        <p matLine>Finance and Membership</p>
        <p class="small" matLine>embedded webpage component</p>

        <button (click)="previewFullLink()" mat-stroked-button>Preview</button>
        <button (click)="mediaFullLink()" mat-raised-button color="primary">Download</button>
      </mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item>
        <mat-icon class="left" matListIcon fontSet="fas" fontIcon="fa-file-code"></mat-icon>
        <p matLine>Finance only</p>
        <p class="small" matLine>embedded webpage component</p>

        <button (click)="previewFinanceLink()" mat-stroked-button>Preview</button>
        <button (click)="mediaFinanceLink()" mat-raised-button color="primary">Download</button>
      </mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item>
        <mat-icon class="left" matListIcon fontSet="fas" fontIcon="fa-file-code"></mat-icon>
        <p matLine>Membership only</p>
        <p class="small" matLine>embedded webpage component</p>

        <button (click)="previewMemberLink()" mat-stroked-button>Preview</button>
        <button (click)="mediaMemberLink()" mat-raised-button color="primary">Download</button>
      </mat-list-item>
    </mat-list>
  </div>
</mat-dialog-content>