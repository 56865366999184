var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ElementRef, EventEmitter, OnInit } from '@angular/core';
import * as moment from 'moment';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { AppointmentViewComponent } from '../appointment-view/appointment-view.component';
import { AppointmentUtilClass } from '../shared/appointmentUtil';
export class ScheduleAppointmentTimeCardComponent {
    constructor(elRef, authenticationService) {
        this.elRef = elRef;
        this.authenticationService = authenticationService;
        this.cancelMockAppointment = new EventEmitter();
        this.appointmentBufferBefore = 0;
        this.appointmentBufferAfter = 0;
        this.appointmentUtil = new AppointmentUtilClass();
        this.runAction = new EventEmitter();
    }
    ngOnInit() {
        return __awaiter(this, void 0, void 0, function* () {
            this.sessionType = yield this.authenticationService.getSessionType().toPromise();
            this.appointmentStart = moment(`${this.appointment.Date_Booked} ${this.appointment.Time}`, 'YYYY-MM-DD h:mma');
            this.appointmentEnd = moment(`${this.appointment.Date_Booked} ${this.appointment.Time_End}`, 'YYYY-MM-DD h:mma');
            this.appointmentDuration = this.appointmentEnd.diff(this.appointmentStart, 'minute');
            this.appointmentBufferBefore = Number(this.appointment.Buffers_Pre);
            this.appointmentBufferAfter = Number(this.appointment.Buffers_Post);
            this.totalBuffer = this.appointmentBufferBefore + this.appointmentBufferAfter;
            // scroll into view for mocked appointment
            if (this.appointment.ID === 'mock') {
                this.elRef.nativeElement.parentNode.scrollTop = this.elRef.nativeElement.offsetTop - 12;
            }
        });
    }
    runActionEvent(item, operation, isGroup = false) {
        if (item) {
            const p = {
                item,
                operation,
                isGroup,
            };
            this.runAction.emit(p);
        }
    }
    getActionResult(r) {
        if (r && r.ID) {
            this.appointment = r;
            // this.updateAppointment.emit(r);
        }
    }
    openQuickViewDialog() {
        const ref = RootAppComponent.dialog.open(AppointmentViewComponent, {
            data: {
                appointmentID: this.appointment.ID,
            },
            width: '900px',
            height: '90%',
            panelClass: 'noCard',
        });
        ref.componentInstance.close.subscribe((res) => {
            if (res == true) {
                ref.close();
            }
        });
    }
}
