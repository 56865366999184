import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import * as moment from 'moment';
import { ClipboardService } from 'ngx-clipboard';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ConfirmDialogSingleButtonComponent } from '../../../shared/components/confirm-dialog-single-button/confirm-dialog-single-button.component';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { CustomPhonePipe } from '../../../shared/pipes/custom-phone.pipe';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { ConfirmDialogSingleButton } from '../../../shared/types/confirm-dialog-single-button';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { MerchantViewComponent } from '../../merchant/merchant-view/merchant-view.component';
import { MerchantService } from '../../merchant/shared/merchant.service';
import { InvitationTemplateThirdPartyComponent } from '../invitation-template-third-party/invitation-template-third-party.component';
import { InvitationTemplateService } from '../shared/invitation-template.service';

@Component({
  selector: 'app-invitation-template-merger',
  templateUrl: './invitation-template-merger.component.html',
  styleUrls: ['./invitation-template-merger.component.css'],
})
export class InvitationTemplateMergerComponent implements OnInit {
  isModuleGoogleReviewActive = Settings.global['isModuleGoogleReviewActive'];
  templateID = '6179fc76c14ac03461cafa57';
  template;
  invitationID;
  @Input()
  merchantID;
  @Output()
  getResult = new EventEmitter();
  isPromoterOrAdmin = true;
  @Output() delete = new EventEmitter();
  @Output()
  close = new EventEmitter();
  @Input()
  title = 'Select your template';
  @Input()
  description = '';
  @Input()
  viewOnly = false;
  treatmentAmount = 0;
  cashDiscountOveridden = true;
  cashDiscountOffered = false;
  cashDiscountMinAmount;
  cashDiscountPercent;
  cashDiscountExpiredDays;
  cashDiscountDate;
  cashDiscountAmount = 0;
  sessionType = 'guest';
  merchant;
  message;
  utils = new UtilsClass();
  isRedirection = true;
  isDownload = true;
  isPrint = false;
  checkboxAddAll = false;
  selectDocuments = true;
  selectImages = true;
  selectTreatmentPlan = true;
  selectTags = true;
  checkboxOverrideAll = false;
  selectTreatmentValue = true;
  selectMessage = false;
  selectDiscount = false;
  selectHealthHistory = false;
  selectGoogleReview = false;
  defaultTreatmentAmount = 7000;
  displaySelectDocuments = true;
  displaySelectImages = true;
  displaySelectTreatmentPlan = true;
  displaySelectTags = true;
  displaySelectTreatmentValue = true;
  displaySelectMessage = true;
  displaySelectDiscount = true;
  displaySelectHealthHistory = true;
  displaySelectGoogleReview = true;
  user;
  selectAll = false;
  displaySelected = false;
  promoter;
  hideTreatmentValue = false;

  defaultSelectTreatmentValue = true;

  constructor(
    private _clipboardService: ClipboardService,
    private authenticationService: AuthenticationService,
    private invitationTemplateService: InvitationTemplateService,
    private merchantService: MerchantService,
    private router: Router,
    private customePhonePipe: CustomPhonePipe,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.merchantID) {
        this.merchantID = data.merchantID;
      }
      if (data.defaultTreatmentAmount !== null && data.defaultTreatmentAmount !== undefined) {
        this.defaultTreatmentAmount = data.defaultTreatmentAmount;
      }

      if (data.hideTreatmentValue !== null && data.hideTreatmentValue !== undefined) {
        this.hideTreatmentValue = data.hideTreatmentValue;
        this.selectTreatmentValue = false;
      }

      if (data.displaySelected != null) {
        this.displaySelected = data.displaySelected;

        this.selectDocuments = false;
        this.selectImages = false;
        this.selectTreatmentPlan = false;
        this.selectTags = false;
        this.selectTreatmentValue = false;
        this.selectMessage = false;
        this.selectDiscount = false;
        this.selectHealthHistory = false;
        this.selectGoogleReview = false;
      }

      if (data.selectDocuments != null) {
        this.selectDocuments = data.selectDocuments;
      }
      if (data.selectImages != null) {
        this.selectImages = data.selectImages;
      }
      if (data.selectTreatmentPlan != null) {
        this.selectTreatmentPlan = data.selectTreatmentPlan;
      }
      if (data.selectTags != null) {
        this.selectTags = data.selectTags;
      }
      if (data.selectTreatmentValue != null) {
        this.selectTreatmentValue = data.selectTreatmentValue;
        this.defaultSelectTreatmentValue = data.selectTreatmentValue;
      }

      if (data.selectMessage != null) {
        this.selectMessage = data.selectMessage;
      }
      if (data.selectDiscount != null) {
        this.selectDiscount = data.selectDiscount;
      }
      if (data.selectGoogleReview != null) {
        this.selectGoogleReview = data.selectGoogleReview;
      }
      if (data.selectHealthHistory != null) {
        this.selectHealthHistory = data.selectHealthHistory;
      }

      if (data.viewOnly != null) {
        this.viewOnly = data.viewOnly;
      }

      if (data.title) {
        this.title = data.title;
      }
      if (data.description) {
        this.description = data.description;
      }
      if (data.templateID) {
        this.templateID = data.templateID;
      }
      if (data.invitationID) {
        this.invitationID = data.invitationID;
      }

      if (data.selectAll) {
        this.selectAll = data.selectAll;
      }
    }
  }

  ngOnInit() {
    this.authenticationService.getCurrentUser().subscribe((user) => {
      if (user && user.data) {
        this.user = user.data;
      }
      this.authenticationService.getSessionType().subscribe((sessionType) => {
        if (sessionType) {
          this.sessionType = sessionType;
        }
        this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
          this.isPromoterOrAdmin = res;

          this.authenticationService.getPromoter().subscribe((promoter) => {
            if (promoter && promoter.data) {
              this.promoter = promoter.data;
            } else if (promoter) {
              this.promoter = promoter;
            }
          });
          if (this.isPromoterOrAdmin == true && this.merchantID) {
            this.merchantService.getMerchantDetails(this.merchantID, {}, this.sessionType).subscribe((r) => {
              if (r) {
                this.merchant = r;
              }
              this.setup();
            });
          } else {
            this.authenticationService.getCurrentUser().subscribe((r) => {
              if (r) {
                this.merchant = r;
              }
              this.setup();
            });
          }
        });
      });
    });
  }

  allAddChecked(checked: boolean) {
    this.selectDocuments = checked && this.displaySelectDocuments;
    this.selectImages = checked && this.displaySelectImages;
    this.selectTreatmentPlan = checked && this.displaySelectTreatmentPlan;
    this.selectTags = checked && this.displaySelectTags;

    if (this.defaultTreatmentAmount === null) {
      this.selectTreatmentValue = checked && this.displaySelectTreatmentValue;
    }
  }

  allOverrideChecked(checked: boolean) {
    this.selectMessage = checked && this.displaySelectMessage;
    this.selectDiscount = checked && this.displaySelectDiscount;
    this.selectHealthHistory = checked && this.displaySelectHealthHistory;
    this.selectGoogleReview = checked && this.displaySelectGoogleReview;
  }

  setup() {
    if (this.selectAll === true) {
      this.selectDocuments = true;
      this.selectImages = true;
      this.selectTreatmentPlan = true;
      this.selectTags = true;
      this.selectTreatmentValue = true;
      this.selectMessage = true;
      this.selectDiscount = true;
      this.selectHealthHistory = true;
      this.selectGoogleReview = true;
    }

    if (this.defaultSelectTreatmentValue === false) {
      this.selectTreatmentValue = false;
    }

    if (this.templateID) {
      const payload = {
        merchantID: this.merchantID,
      };
      this.invitationTemplateService
        .getInvitationTemplateDetails(this.templateID, payload, this.isPromoterOrAdmin)
        .subscribe((res) => {
          if (res && res.content) {
            if (res.displaySelectDocuments === true) {
              this.displaySelectDocuments = true;
            } else {
              this.displaySelectDocuments = false;
              this.selectDocuments = false;
            }

            if (res.displaySelectImages === true) {
              this.displaySelectImages = true;
            } else {
              this.displaySelectImages = false;
              this.selectImages = false;
            }

            if (res.displaySelectTreatmentPlan === true) {
              this.displaySelectTreatmentPlan = true;
            } else {
              this.displaySelectTreatmentPlan = false;
              this.selectTreatmentPlan = false;
            }

            if (res.displaySelectTags === true) {
              this.displaySelectTags = true;
            } else {
              this.displaySelectTags = false;
              this.selectTags = false;
            }

            if (res.displaySelectTreatmentValue === true) {
              this.displaySelectTreatmentValue = true;
            } else {
              this.displaySelectTreatmentValue = false;
              this.selectTreatmentValue = false;
            }

            if (res.displaySelectMessage === true) {
              this.displaySelectMessage = true;
            } else {
              this.displaySelectMessage = false;
              this.selectMessage = false;
            }

            if (res.displaySelectDiscount === true) {
              this.displaySelectDiscount = true;
            } else {
              this.displaySelectDiscount = false;
              this.selectDiscount = false;
            }

            if (res.displaySelectHealthHistory === true) {
              this.displaySelectHealthHistory = true;
            } else {
              this.displaySelectHealthHistory = false;
              this.selectHealthHistory = false;
            }
            if (res.displaySelectGoogleReview === true) {
              this.displaySelectGoogleReview = true;
            } else {
              this.displaySelectGoogleReview = false;
              this.selectGoogleReview = false;
            }

            this.changeAddReplace();

            this.template = res;
            if (this.template && this.template.content && this.template.content.totalTreatmentValue) {
              this.treatmentAmount = this.template.content.totalTreatmentValue;
            }

            if (this.template && this.template.content) {
              this.calculCashDiscount();
            }

            if (
              res &&
              res['userID'] &&
              (this.isPromoterOrAdmin != true || (this.isPromoterOrAdmin == true && !this.merchantID))
            ) {
              this.merchantID = res['userID'];
            }
            if (this.template && this.template.content && this.template.content.message) {
              if (this.template.content.message.contentRaw) {
                this.message = this.convertMessage(this.template.content.message.contentRaw);
              } else if (this.template.content.message.content) {
                this.message = this.template.content.message.content;
              }
            }
          }
        });
    }
  }

  sendResult() {
    const p = {
      selectDocuments: this.selectDocuments,
      selectImages: this.selectImages,
      selectTreatmentPlan: this.selectTreatmentPlan,
      selectTags: this.selectTags,
      selectGoogleReview: this.selectGoogleReview,
      selectHealthHistory: this.selectHealthHistory,
      selectDiscount: this.selectDiscount,
      selectMessage: this.selectMessage,
      selectTreatmentValue: this.selectTreatmentValue,
      templateID: this.templateID,
      template: this.template,
      treatmentValue: null,
    };

    if (this.selectTreatmentValue == true) {
      p.treatmentValue = this.treatmentAmount || 0;
    }

    this.getResult.emit(p);
  }

  isNew(date) {
    if (date) {
      const days = this.utils.daysPast(date);

      if (days < 15) {
        return true;
      } else {
        return false;
      }
    }

    return false;
  }

  showComment() {
    if (this.template && this.template.description) {
      const confirmDialog2 = new ConfirmDialogSingleButton(
        'fas fa-info',
        'Template description',
        this.template.description,
        'OKAY'
      );
      const ref2 = RootAppComponent.dialog.open(ConfirmDialogSingleButtonComponent, {
        data: confirmDialog2,
        width: '500px',
      });
      ref2.componentInstance.onConfirm.subscribe((res) => {
        ref2.close();
      });
    }
  }

  selectDocumentsChange() {
    if (this.viewOnly != true) {
      this.selectDocuments = !this.selectDocuments;
      this.changeAddReplace();
    }
  }

  selectImagesChange() {
    if (this.viewOnly != true) {
      this.selectImages = !this.selectImages;
      this.changeAddReplace();
    }
  }

  selectTreatmentPlanChange() {
    if (this.viewOnly != true) {
      this.selectTreatmentPlan = !this.selectTreatmentPlan;
      this.changeAddReplace();
    }
  }

  selectTagsChange() {
    if (this.viewOnly != true) {
      this.selectTags = !this.selectTags;
      this.changeAddReplace();
    }
  }

  selectGoogleReviewChange() {
    if (this.viewOnly != true) {
      this.selectGoogleReview = !this.selectGoogleReview;
      this.changeAddReplace();
    }
  }

  selectHealthHistoryChange() {
    if (this.viewOnly != true) {
      this.selectHealthHistory = !this.selectHealthHistory;
      this.changeAddReplace();
    }
  }

  resetTreatmentValue() {
    if (this.template && this.template.content && this.template.content.totalTreatmentValue) {
      this.treatmentAmount = this.template.content.totalTreatmentValue;
    }
  }

  selectDiscountChange() {
    if (this.viewOnly != true) {
      this.selectDiscount = !this.selectDiscount;
      this.changeAddReplace();
    }
  }

  selectMessageChange() {
    if (this.viewOnly != true) {
      this.selectMessage = !this.selectMessage;
      this.changeAddReplace();
    }
  }

  selectTreatmentValueChange() {
    if (this.viewOnly != true) {
      this.selectTreatmentValue = !this.selectTreatmentValue;
      this.changeAddReplace();
    }
  }

  closeModalEvent() {
    this.close.emit(true);
  }

  getTypeLabel(type) {
    if (type && type == 'default') {
      return 'Smile Right';
    }
    return 'Practice';
  }

  edit() {
    if (this.templateID) {
      this.router.navigate(['/merchant', { outlets: { page: ['invitation-template-edit', this.templateID] } }]);
      this.closeModalEvent();
    }
  }

  viewMerchant(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(MerchantViewComponent, {
        data: id,
        width: '600px',
      });
      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    }
  }

  remove() {
    if (this.templateID) {
      if (this.template && this.template.canChange === false) {
        const ref2 = RootAppComponent.dialog.open(InvitationTemplateThirdPartyComponent, {
          data: {
            apiKeyClient: this.template.apiKeyClient,
            apiKeyAccess: this.template.apiKeyAccess,
            accessIDs: this.template.accessIDs,
            clientIDs: this.template.clientIDs,
            canChange: this.template.canChange,
            templateID: this.template._id,
          },
        });
        ref2.componentInstance.close.subscribe((confirmation) => {
          ref2.close();
        });
        ref2.componentInstance.closeAll.subscribe((confirmation) => {
          ref2.close();
          ref2.afterClosed().subscribe((res) => {
            this.close.emit('all');
          });
        });
      } else {
        const confirm = new ConfirmDialog(
          'fas fa-info',
          '',
          'Are you sure you want to remove this template  ?',
          'No',
          'Yes'
        );
        const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
          data: confirm,
        });
        ref.componentInstance.onConfirm.subscribe((confirmation) => {
          if (confirmation === false) {
            ref.close();
          } else {
            this.invitationTemplateService
              .deleteInvitationTemplate(this.templateID, this.isPromoterOrAdmin)
              .subscribe((res) => {
                if (res) {
                  ref.close();
                  NotifyAppComponent.displayToast('success', 'Successful operation', 'Treatment Template is removed');
                  this.delete.emit(this.templateID);
                  this.router.navigate(['/merchant', { outlets: { page: ['treatment-saved-template'] } }]);
                }
              });
          }
        });
      }
    }
  }

  disable() {
    if (this.templateID) {
      if (this.template && this.template.canChange === false) {
        const ref2 = RootAppComponent.dialog.open(InvitationTemplateThirdPartyComponent, {
          data: {
            apiKeyClient: this.template.apiKeyClient,
            apiKeyAccess: this.template.apiKeyAccess,
            accessIDs: this.template.accessIDs,
            clientIDs: this.template.clientIDs,
            canChange: this.template.canChange,
            templateID: this.template._id,
          },
        });
        ref2.componentInstance.close.subscribe((confirmation) => {
          ref2.close();
        });
        ref2.componentInstance.closeAll.subscribe((confirmation) => {
          ref2.close();
          ref2.afterClosed().subscribe((res) => {
            this.close.emit('all');
          });
        });
      } else {
        const confirmDialog = new ConfirmDialog(
          'cancel',
          'Disable Template',
          '<p>Are you sure ? </p>',
          'Cancel',
          'Yes'
        );
        const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
          data: confirmDialog,
          // SET TO PAYING CASH CARD
        });
        ref.componentInstance.onConfirm.subscribe((val) => {
          if (val == true) {
            ref.close();
            ref.afterClosed().subscribe((res) => {
              const payload = {
                isActive: false,
              };
              this.invitationTemplateService
                .modifyInvitationTemplate(this.templateID, payload, this.isPromoterOrAdmin)
                .subscribe((res) => {
                  if (res) {
                    this.router.navigate(['/merchant', { outlets: { page: ['treatment-saved-template'] } }]);
                    NotifyAppComponent.displayToast('success', 'Success!', 'Template is disabled.');
                  }
                });
            });
          } else {
            ref.close();
          }
        });
      }
    }
  }

  enable() {
    if (this.templateID) {
      const confirmDialog = new ConfirmDialog(
        'check_circle',
        'Enable Template',
        '<p>Are you sure ? </p>',
        'Cancel',
        'Yes'
      );
      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirmDialog,
        // SET TO PAYING CASH CARD
      });
      ref.componentInstance.onConfirm.subscribe((val) => {
        if (val == true) {
          ref.close();
          ref.afterClosed().subscribe((res) => {
            const payload = {
              isActive: true,
            };
            this.invitationTemplateService
              .modifyInvitationTemplate(this.templateID, payload, this.isPromoterOrAdmin)
              .subscribe((res) => {
                if (res) {
                  this.router.navigate(['/merchant', { outlets: { page: ['treatment-saved-template'] } }]);
                  NotifyAppComponent.displayToast('success', 'Success!', 'Template is enabled.');
                }
              });
          });
        } else {
          ref.close();
        }
      });
    }
  }

  calculCashDiscount() {
    if (this.merchant && this.merchant.ID) {
      const payload = {
        cashDiscountOffered: this.cashDiscountOffered,
        cashDiscountPercent: this.cashDiscountPercent,
        cashDiscountExpiredDays: this.cashDiscountExpiredDays,
        treatmentAmount: this.treatmentAmount,
        merchantID: this.merchant.ID,
        invitationID: this.invitationID,
      };

      this.invitationTemplateService.calculateDiscount(payload, this.sessionType).subscribe((res) => {
        if (res) {
          this.cashDiscountOffered = res.cashDiscountOffered;
          this.cashDiscountPercent = res.cashDiscountPercent;
          this.cashDiscountExpiredDays = res.cashDiscountExpiredDays;
          this.cashDiscountMinAmount = res.cashDiscountMinAmount;
          this.cashDiscountAmount = res.cashDiscountAmount;
          if (
            (this.cashDiscountOffered === true &&
              (this.cashDiscountPercent === null || this.cashDiscountExpiredDays === null)) ||
            this.cashDiscountOffered === null
          ) {
            this.displaySelectDiscount = false;
            this.selectDiscount = false;
          } else {
            this.displaySelectDiscount = true;
          }
        } else {
          this.displaySelectDiscount = false;
          this.selectDiscount = false;
          this.cashDiscountOffered = false;
          this.cashDiscountPercent = null;
          this.cashDiscountExpiredDays = null;
          this.cashDiscountMinAmount = null;
        }
      });
    }
  }

  calculateAmountFromPercent() {
    if (this.treatmentAmount > 0 && this.cashDiscountPercent && this.cashDiscountPercent <= 100) {
      this.cashDiscountAmount = (this.treatmentAmount * this.cashDiscountPercent) / 100;
    }
  }

  calculateCashDiscountDate() {
    if (this.cashDiscountExpiredDays) {
      this.cashDiscountDate = moment().add(this.cashDiscountExpiredDays, 'd');
    }
  }

  closeEvent() {
    this.close.emit(true);
  }

  changeAddReplace() {
    let _selectDocuments = true;
    let _selectImages = true;
    let _selectTreatmentPlan = true;
    let _selectTreatmentValue = true;
    let _selectTags = true;
    let _selectMessage = true;
    let _selectDiscount = true;
    let _selectHealthHistory = true;
    let _selectGoogleReview = true;

    if (this.displaySelectDocuments == true && this.selectDocuments != true) {
      _selectDocuments = false;
    }
    if (this.displaySelectImages == true && this.selectImages != true) {
      _selectImages = false;
    }
    if (this.displaySelectTreatmentPlan == true && this.selectTreatmentPlan != true) {
      _selectTreatmentPlan = false;
    }
    if (this.displaySelectTags == true && this.selectTags != true) {
      _selectTags = false;
    }
    if (this.displaySelectTreatmentValue == true && this.selectTreatmentValue != true) {
      _selectTreatmentValue = false;
    }
    if (this.displaySelectMessage == true && this.selectMessage != true) {
      _selectMessage = false;
    }
    if (this.displaySelectDiscount == true && this.selectDiscount != true) {
      _selectDiscount = false;
    }
    if (this.displaySelectHealthHistory == true && this.selectHealthHistory != true) {
      _selectHealthHistory = false;
    }
    if (this.displaySelectGoogleReview == true && this.selectGoogleReview != true) {
      _selectGoogleReview = false;
    }

    if (this.defaultTreatmentAmount !== null) {
      this.checkboxAddAll = _selectDocuments && _selectImages && _selectTags && _selectTreatmentPlan;
    } else {
      this.checkboxAddAll =
        _selectTreatmentValue && _selectDocuments && _selectImages && _selectTags && _selectTreatmentPlan;
    }

    this.checkboxOverrideAll = _selectMessage && _selectDiscount && _selectHealthHistory && _selectGoogleReview;
  }

  convertMessage(message) {
    let result;
    const rule = {};
    rule['PatientSalutation'] = 'Salutation';
    rule['PatientLastName'] = 'Last name';
    rule['PatientFirstName'] = 'First name';
    rule['PatientMiddleName'] = 'Middle name';
    rule['PatientMobileNumber'] = 'Mobile';
    rule['PatientWorkPhoneNumber'] = 'Phone';
    rule['PatientEmail'] = 'Email';
    ////
    if (this.user) {
      rule['DentistSalutation'] = this.user['Salutation'];
    } else {
      rule['DentistSalutation'] = '';
    }

    if (this.user) {
      rule['DentistFirstName'] = this.user['FirstName'];
    } else {
      rule['DentistFirstName'] = '';
    }

    if (this.user) {
      rule['DentistMiddleName'] = this.user['MiddleName'];
    } else {
      rule['DentistMiddleName'] = '';
    }

    if (this.user) {
      rule['DentistLastName'] = this.user['Name'];
    } else {
      rule['DentistLastName'] = '';
    }

    if (this.user) {
      rule['DentistMobileNumber'] = this.customePhonePipe.transform(this.user['mobiles.Number'], 'false');
    } else {
      rule['DentistMobileNumber'] = '';
    }

    if (this.user) {
      rule['DentistPhoneNumber'] = this.customePhonePipe.transform(this.user['phones.Number'], 'true');
    } else {
      rule['DentistPhoneNumber'] = '';
    }

    if (this.user) {
      rule['DentistEmail'] = this.user['emails.Email'];
    } else {
      rule['DentistEmail'] = '';
    }

    if (this.user) {
      rule['DentistAddress'] = this.user['addresses.Calculated'];
    } else {
      rule['DentistAddress'] = '';
    }

    if (this.merchant) {
      rule['PracticeLegalName'] = this.merchant['Name'];
    } else {
      rule['PracticeLegalName'] = '';
    }

    if (this.merchant) {
      rule['PracticeAddress'] = this.merchant['addresses.Calculated'];
    } else {
      rule['PracticeAddress'] = '';
    }

    if (this.merchant) {
      rule['PracticeTradingAs'] = this.merchant['TradingAs'];
    } else {
      rule['PracticeTradingAs'] = '';
    }
    if (this.merchant) {
      rule['PracticeFirstName'] = this.merchant['FirstName'];
    } else {
      rule['PracticeFirstName'] = ' ';
    }

    if (this.merchant) {
      rule['PracticePhoneNumber'] = this.customePhonePipe.transform(this.merchant['phones.Number'], 'true');
    } else {
      rule['PracticePhoneNumber'] = '';
    }

    if (this.merchant && this.merchant['mobiles.Number']) {
      rule['PracticeMobileNumber'] = this.customePhonePipe.transform(this.merchant['mobiles.Number'], 'false');
    } else {
      rule['PracticeMobileNumber'] = '';
    }
    if (this.merchant) {
      rule['PracticeAddress'] = this.merchant['addresses.Calculated'];
    } else {
      rule['PracticeAddress'] = '';
    }

    if (this.merchant) {
      rule['PracticeABN'] = this.merchant['ABN'];
    } else {
      rule['PracticeABN'] = '';
    }

    if (this.merchant) {
      rule['PracticeACN'] = this.merchant['ACN'];
    } else {
      rule['PracticeACN'] = '';
    }

    if (this.merchant) {
      rule['PracticeEmail'] = this.merchant['emails.Email'];
    } else {
      rule['PracticeEmail'] = '';
    }

    if (this.merchant && this.merchant['Facebook']) {
      rule['PracticeFacebook'] = this.merchant['Facebook'];
    } else {
      rule['PracticeFacebook'] = '';
    }

    if (this.merchant && this.merchant['Instagram']) {
      rule['PracticeInstagram'] = this.merchant['Instagram'];
    } else {
      rule['PracticeInstagram'] = '';
    }

    if (this.merchant && this.merchant['Twitter']) {
      rule['PracticeTwitter'] = this.merchant['Twitter'];
    } else {
      rule['PracticeTwitter'] = '';
    }

    if (this.merchant && this.merchant['LinkedIn']) {
      rule['PracticeLinkedIn'] = this.merchant['LinkedIn'];
    } else {
      rule['PracticeLinkedIn'] = '';
    }

    if (this.merchant && this.merchant['Pinterest']) {
      rule['PracticePinterest'] = this.merchant['Pinterest'];
    } else {
      rule['PracticePinterest'] = '';
    }

    if (this.merchant && this.merchant['Tumblr']) {
      rule['PracticeTumblr'] = this.merchant['Tumblr'];
    } else {
      rule['PracticeTumblr'] = '';
    }

    if (this.merchant && this.merchant['Vimeo']) {
      rule['PracticeVimeo'] = this.merchant['Vimeo'];
    } else {
      rule['PracticeVimeo'] = '';
    }

    if (this.merchant && this.merchant['YouTube']) {
      rule['PracticeYouTube'] = this.merchant['YouTube'];
    } else {
      rule['PracticeYouTube'] = '';
    }

    if (this.merchant && this.merchant['URL']) {
      rule['PracticeWebSite'] = this.merchant['URL'];
    } else {
      rule['PracticeWebSite'] = '';
    }

    if (this.merchant && this.merchant['Twilio_Number']) {
      rule['DedicatedSMSNumber'] = this.customePhonePipe.transform(this.merchant['Twilio_Number'], 'false');
    } else {
      rule['DedicatedSMSNumber'] = '';
    }

    if (this.merchant && this.merchant['Terminal_Code']) {
      rule['SelfServicePage'] = `${environment.nodeUrl}/s/${this.merchant['Terminal_Code']}?t=lp`;
    } else {
      rule['SelfServicePage'] = '';
    }
    if (this.merchant && this.merchant['Terminal_Code']) {
      rule['PracitceAppointment'] = `${Settings.global['publicSiteFrontendLink']}/a/${this.merchant['Terminal_Code']}`;
    } else {
      rule['PracitceAppointment'] = '';
    }

    if (this.merchant && this.merchant['Terminal_Code']) {
      rule['PracticePage'] = `${Settings.global['publicSiteFrontendLink']}/l/p/s/${this.merchant['Terminal_Code']}`;
    } else {
      rule['PracticePage'] = '';
    }

    if (this.merchant && this.merchant['Terminal_Code']) {
      rule['TipsAndOffers'] = `${Settings.global['publicSiteFrontendLink']}/l/d/s/${this.merchant['Terminal_Code']}`;
    } else {
      rule['TipsAndOffers'] = '';
    }

    if (this.promoter) {
      if (this.promoter['TradingAs']) {
        rule['PromoterTradingAs'] = this.promoter['TradingAs'];
      } else {
        rule['PromoterTradingAs'] = ' ';
      }

      if (this.promoter['FirstName']) {
        rule['PromoterFirstName'] = this.promoter['FirstName'];
      } else {
        rule['PromoterFirstName'] = ' ';
      }

      if (this.promoter['Name']) {
        rule['PromoterLegalName'] = this.promoter['Name'];
      } else {
        rule['PromoterLegalName'] = ' ';
      }

      if (this.promoter['mobiles.Number']) {
        rule['PromoterMobileNumber'] = this.customePhonePipe.transform(this.promoter['mobiles.Number'], 'false');
      } else {
        rule['PromoterMobileNumber'] = ' ';
      }

      if (this.promoter['phones.Number']) {
        rule['PromoterPhoneNumber'] = this.customePhonePipe.transform(this.promoter['phones.Number'], 'true');
      } else {
        rule['PromoterPhoneNumber'] = ' ';
      }

      if (this.promoter['emails.Email']) {
        rule['PromoterEmail'] = this.promoter['emails.Email'];
      } else {
        rule['PromoterEmail'] = ' ';
      }

      if (this.promoter['addresses.Calculated']) {
        rule['PromoterAddress'] = this.promoter['addresses.Calculated'];
      } else {
        rule['PromoterAddress'] = ' ';
      }

      if (this.promoter['Facebook']) {
        rule['PromoterFacebook'] = this.promoter['Facebook'];
      } else {
        rule['PromoterFacebook'] = ' ';
      }

      if (this.promoter['Instagram']) {
        rule['PromoterInstagram'] = this.promoter['Instagram'];
      } else {
        rule['PromoterInstagram'] = ' ';
      }
      if (this.promoter['Twitter']) {
        rule['PromoterTwitter'] = this.promoter['Twitter'];
      } else {
        rule['PromoterTwitter'] = ' ';
      }

      if (this.promoter['LinkedIn']) {
        rule['PromoterLinkedIn'] = this.promoter['LinkedIn'];
      } else {
        rule['PromoterLinkedIn'] = ' ';
      }

      if (this.promoter['Pinterest']) {
        rule['PromoterPinterest'] = this.promoter['Pinterest'];
      } else {
        rule['PromoterPinterest'] = ' ';
      }
      if (this.promoter['Tumblr']) {
        rule['PromoterTumblr'] = this.promoter['Tumblr'];
      } else {
        rule['PromoterTumblr'] = ' ';
      }

      if (this.promoter['Vimeo']) {
        rule['PromoterVimeo'] = this.promoter['Vimeo'];
      } else {
        rule['PromoterVimeo'] = ' ';
      }
      if (this.promoter['YouTube']) {
        rule['PromoterYouTube'] = this.promoter['YouTube'];
      } else {
        rule['PromoterYouTube'] = ' ';
      }
      if (this.promoter['URL']) {
        rule['PromoterWebSite'] = this.promoter['URL'];
      } else {
        rule['PromoterWebSite'] = ' ';
      }

      if (this.promoter['ABN']) {
        rule['PromoterABN'] = this.promoter['ABN'];
      } else {
        rule['PromoterABN'] = ' ';
      }
      if (this.promoter['ACN']) {
        rule['PromoterACN'] = this.promoter['ACN'];
      } else {
        rule['PromoterACN'] = ' ';
      }
    }
    result = this.utils.convertMessage(message, rule, { isStrong: true });
    result = result.replace('\n', '<br>');
    return result;
  }

  copy(d) {
    if (d) {
      this._clipboardService.copy(d);
      NotifyAppComponent.displayToast('success', 'Success', 'Copied');
    }
  }

  toNumber(d = 0) {
    return Number(d);
  }

  viewAllThirdParty() {
    const confirmDialog = new ConfirmDialog(
      'check_circle',
      'Redirection',
      '<p>You are about to leave this page, Do you want to continue? </p>',
      'Cancel',
      'Yes'
    );
    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
      // SET TO PAYING CASH CARD
    });
    ref.componentInstance.onConfirm.subscribe((val) => {
      if (val == true) {
        ref.close();
        ref.afterClosed().subscribe((res) => {
          this.router.navigate([
            '/merchant',
            { outlets: { page: ['third-party-access-client-list', this.templateID] } },
          ]);
          this.close.emit('all');
        });
      } else {
        ref.close();
      }
    });
  }
}
