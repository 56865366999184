import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CdkTableModule } from '@angular/cdk/table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ArchwizardModule } from 'angular-archwizard';
import { ColorPickerModule } from 'ngx-color-picker';
import { SharedModule } from '../../shared/shared.module';

import { MatSortModule } from '@angular/material/sort';
import { PasswordStrengthMeterModule } from 'angular-password-strength-meter';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgPipesModule } from 'ngx-pipes';
import { DocumentModule } from '../document/document.module';
import { OperatorModule } from '../operator/operator.module';
import { DentistInviteCreateComponent } from './dentist-invite-create/dentist-invite-create.component';
import { DentistListMapComponent } from './dentist-list-map/dentist-list-map.component';
import { DentistListOverViewComponent } from './dentist-list-overview/dentist-list-overview.component';
import { DentistListComponent } from './dentist-list/dentist-list.component';
import { DentistMapOverViewComponent } from './dentist-map-overview/dentist-map-overview.component';
import { DentistOverviewComponent } from './dentist-overview/dentist-overview.component';
import { DentistSettingsComponent } from './dentist-settings/dentist-settings.component';
import { DentistViewConsumerComponent } from './dentist-view-consumer/dentist-view-consumer.component';
import { DentistViewModalComponent } from './dentist-view-modal/dentist-view-modal.component';
import { DentistViewPictureComponent } from './dentist-view-picture/dentist-view-picture.component';
import { DentistViewComponent } from './dentist-view/dentist-view.component';
import { DentistWorkingHoursOverviewComponent } from './dentist-working-hours-overview/dentist-working-hours-overview.component';
import { DentistRoutingModule } from './shared/dentist-routing.module';
import { DentistService } from './shared/dentist.service';
import { CardModule } from '../card/card.module';

@NgModule({
  imports: [
    NgPipesModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CdkTableModule,
    ArchwizardModule,
    SharedModule,
    ColorPickerModule,
    DentistRoutingModule,
    OperatorModule,
    NgxPaginationModule,
    MatSortModule,
    PasswordStrengthMeterModule,
    DocumentModule,
    CardModule
  ],
  declarations: [
    DentistListMapComponent,
    DentistListComponent,
    DentistMapOverViewComponent,
    DentistListOverViewComponent,
    DentistViewComponent,
    DentistViewConsumerComponent,
    DentistViewModalComponent,
    DentistOverviewComponent,
    DentistSettingsComponent,
    DentistInviteCreateComponent,
    DentistViewPictureComponent,
    DentistWorkingHoursOverviewComponent,
  ],
  exports: [
    DentistListMapComponent,
    DentistListComponent,
    DentistMapOverViewComponent,
    DentistListOverViewComponent,
    DentistViewComponent,
    DentistViewConsumerComponent,
    DentistViewModalComponent,
    DentistOverviewComponent,
    DentistSettingsComponent,
    DentistInviteCreateComponent,
    DentistViewPictureComponent,
    DentistWorkingHoursOverviewComponent,
  ],
  providers: [DentistService],
})
export class DentistModule {}
