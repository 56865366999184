import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ConfirmDialogMessageComponent } from '../../../shared/components/confirm-dialog-message/confirm-dialog-message.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { UtilsService } from '../../../shared/services/utils.service';
import { CustomerProspectService } from '../../customer-prospect/shared/customerProspect.service';

@Component({
  selector: 'app-patient-document-create',
  templateUrl: './patient-document-create.component.html',
  styleUrls: ['./patient-document-create.component.css'],
})
export class PatientDocumentCreateComponent implements OnInit {
  @Input()
  patientID;

  documents = {};

  documentTypes = [];

  addEvents = {};
  removeEvents = {};
  sesssionType = 'guest';

  videoExtensionType = ['video', 'audio'];
  imageExtensionType = ['image'];
  otherExtensionType = ['pdf', 'image', 'document'];

  constructor(
    private utilService: UtilsService,
    private authenticationService: AuthenticationService,
    private customerProspectService: CustomerProspectService
  ) {}

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((res) => {
      this.sesssionType = res;

      const _p = {
        fields: 'ID,Label,TypeCode',
        programAreaCode: 'MEDRD',
      };
      this.utilService.getDocumentTypeList(_p).subscribe((documentTypes) => {
        if (documentTypes && documentTypes.length > 0) {
          for (let i = 0; i < documentTypes.length; i++) {
            if (documentTypes[i] && documentTypes[i]['TypeCode']) {
              this.addEvents[documentTypes[i]['TypeCode']] = new EventEmitter();
              this.removeEvents[documentTypes[i]['TypeCode']] = new EventEmitter();
            }

            if (documentTypes[i]['TypeCode'] == 'MEDR_V') {
              documentTypes[i]['allowedExtensionTypes'] = this.videoExtensionType;
            } else if (documentTypes[i]['TypeCode'] == 'MEDR_I') {
              documentTypes[i]['allowedExtensionTypes'] = this.imageExtensionType;
            } else {
              documentTypes[i]['allowedExtensionTypes'] = this.otherExtensionType;
            }
          }
          this.documentTypes = documentTypes;
        }

        this.setup();
      });
    });
  }

  setup() {
    if (this.patientID) {
      const p = {
        patientID: this.patientID,
        fields: 'ID,DocumentType_Code',
      };
      this.customerProspectService.getPatientDocumentList(p, this.sesssionType).subscribe((res) => {
        if (res && res.length > 0) {
          const documents = {};
          for (let i = 0; i < res.length; i++) {
            if (res && res[i] && res[i]['ID'] && res[i]['DocumentType_Code']) {
              const id = res[i]['ID'];
              const type = res[i]['DocumentType_Code'];

              if (documents[type] && documents[type] && documents[type].length > 0) {
                documents[type].push(id);
              } else {
                documents[type] = [id];
              }
            }
          }

          this.documents = documents;
        } else {
          this.documents = {};
        }
      });
    }
  }

  getLabelByCode(code, label = null) {
    if (code) {
      if (code == 'MEDR_V') {
        return 'Videos';
      } else if (code == 'MEDR_X') {
        return 'Xrays';
      } else if (code == 'MEDR_T') {
        return 'Treatment Plans';
      } else if (code == 'MEDR_I') {
        return 'Images';
      } else if (code == 'MEDR_R') {
        return 'Referrals';
      } else if (code == 'MEDR_C') {
        return 'Correspondence';
      } else if (label) {
        return label;
      } else {
        return 'Documents';
      }
    } else if (label) {
      return label;
    } else {
      return 'Documents';
    }
  }

  getDocumentLabelByCode(code, label = null) {
    if (code) {
      if (code == 'MEDR_V') {
        return 'Video';
      } else if (code == 'MEDR_X') {
        return 'Xray';
      } else if (code == 'MEDR_T') {
        return 'Treatment Plan';
      } else if (code == 'MEDR_I') {
        return 'Image';
      } else if (code == 'MEDR_R') {
        return 'Referral';
      } else if (code == 'MEDR_C') {
        return 'Correspondence';
      } else if (label) {
        return label;
      } else {
        return 'Document';
      }
    } else if (label) {
      return label;
    } else {
      return 'Document';
    }
  }

  onCompleteAll(fileID, code) {
    if (fileID && fileID[0] && code) {
      const ref = RootAppComponent.dialog.open(ConfirmDialogMessageComponent, {
        data: {
          icon: 'info',
          title: 'Title and description',
          description: `Please insert a document Title and description`,
          yesLabel: 'Confirm',
          noLabel: 'Cancel',
          messageLabel: 'Description',
          textLabel: 'Title',
          text: this.getDocumentLabelByCode(code),
          isTextInputRequired: true,
          isTextAreaRequired: false,
          isTextArea: true,
          isTextInput: true,
        },
        width: '650px',
      });
      ref.componentInstance.closeModal.subscribe((res) => {
        ref.close();
      });
      ref.componentInstance.onConfirm.subscribe((res) => {
        if (res && res.confirm == true) {
          let label = this.getDocumentLabelByCode(code);
          if (res.text) {
            label = res.text;
          }

          let description;
          if (res.message) {
            description = res.message;
          }

          const payload = {
            fileID: fileID[0],
            type: code,
            label,
            description,
            patientID: this.patientID,
          };

          this.customerProspectService.createPatientDocument(payload, this.sesssionType).subscribe((res) => {
            if (res && res.ID) {
              if (this.addEvents[code]) {
                this.addEvents[code].emit(res);
              }
              if (this.documents && this.documents[code] && this.documents[code].length > 0) {
                this.documents[code].push(res.ID);
              } else {
                this.documents[code] = [res.ID];
              }
              ref.close();
            }
          });
        } else {
          ref.close();
        }
      });
    }
  }

  removeDocument(id, code) {
    if (id) {
      this.customerProspectService.removePatientDocument(id, this.sesssionType).subscribe((res) => {
        if (res) {
          if (this.removeEvents[code]) {
            this.removeEvents[code].emit(id);
          }
        }
      });
    }
  }
}
