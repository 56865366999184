import { EventEmitter, Injectable } from '@angular/core';

@Injectable()
export class HeaderService {
  static backInvitationButton: EventEmitter<any> = new EventEmitter<any>();
  static headerObject: EventEmitter<any> = new EventEmitter<any>();
  static noteObject: EventEmitter<any> = new EventEmitter<any>();
  static MerchantName: EventEmitter<any> = new EventEmitter<any>();
  static MerchantObject: EventEmitter<any> = new EventEmitter<any>();
  static merchantID: EventEmitter<any> = new EventEmitter<any>();
  static promoterViewAsObject: EventEmitter<any> = new EventEmitter<any>();
  static setupFooter: EventEmitter<any> = new EventEmitter<any>();
  static hideCustomerPortal: EventEmitter<any> = new EventEmitter<any>();
  static noteObjectNew: EventEmitter<any> = new EventEmitter<any>();
  static setheaderObject(value) {
    HeaderService.headerObject.emit(value);
  }

  static getheaderObject() {
    return HeaderService.headerObject;
  }

  static setNoteObject(value) {
    HeaderService.noteObject.emit(value);
  }

  static getNoteObject() {
    return HeaderService.noteObject;
  }

  static setPromoterViewAsObject(promoterViewAs) {
    HeaderService.promoterViewAsObject.emit(promoterViewAs);
  }

  static getPromoterViewAsObject() {
    return HeaderService.promoterViewAsObject;
  }

  constructor() {}
}
