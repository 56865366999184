import { BehaviorSubject, merge as observableMerge, Observable } from 'rxjs';

import { DataSource } from '@angular/cdk/table';
import { Component, ElementRef, EventEmitter, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { Settings } from '../../../shared/types/settings';

import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import * as _ from 'lodash';
import * as moment from 'moment';
import { ConfirmDialogMessageComponent } from '../../../shared/components/confirm-dialog-message/confirm-dialog-message.component';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { CustomDatePipe } from '../../../shared/pipes/custom-date.pipe';
import { CustomPhonePipe } from '../../../shared/pipes/custom-phone.pipe';
import { SideMenuService } from '../../../shared/services/side-menu.service';
import { UtilsService } from '../../../shared/services/utils.service';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { TagsService } from '../../tags/shared/tags.service';
import { SocialMediaService } from '../shared/social-media.service';
import { SocialMediaViewComponent } from '../social-media-view/social-media-view.component';

@Component({
  selector: 'app-social-media-list',
  templateUrl: './social-media-list.component.html',
  styleUrls: ['./social-media-list.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.row', style({ opacity: 0 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.5s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.row', style({ opacity: 1 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.5s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class SocialMediaListComponent implements OnInit {
  @Input()
  isManuallyCreated;

  isPromoterOrAdmin = false;

  currentUser;

  selectedTypeID;

  typeFilter;
  typeFilter2;

  @Input()
  title = 'Social media templates';

  @Input()
  merchantID;

  isActiveFilter;

  @Input()
  pageSize = Settings.global['listPageSize'] || 20;
  pageSizeOptions = [10, Number(this.pageSize), Number(this.pageSize) * 2, Number(this.pageSize) * 3];

  utils = new UtilsClass();

  displayedColumns = ['selectCheckBox', 'Picture', 'Type', 'Label', 'CTA', 'Tags', 'Date', 'active', 'Actions'];
  filters = [];

  selectedIDs = [];

  searchVals = new FormControl();

  searchValList = ['Label'];

  expandedRow: number;
  public listDB: LoadRecords | null;
  dataSource: RecordDataSource | null;

  merchantTags = [];

  filteredSize = null;

  searchLabel;
  sessionType;

  currentPractice;

  renderValue = {};

  dynamicData = [];
  destroyEvent = new EventEmitter();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('filter', { static: false }) filter: ElementRef;

  constructor(
    private customPhonePipe: CustomPhonePipe,
    private router: Router,
    private customDate: CustomDatePipe,
    private authenticationService: AuthenticationService,
    private socialMediaService: SocialMediaService,
    private utilService: UtilsService,
    private tagService: TagsService
  ) {}

  ngOnInit() {
    const p = {
      isPromoter: true,
      fields: 'Label',
    };

    this.authenticationService.getCurrentPractice().subscribe((currentPractice) => {
      this.currentPractice = currentPractice;
      this.addDynamicData();
      this.authenticationService.getCurrentUser().subscribe((u) => {
        if (u && u.data) {
          this.currentUser = u.data;
        }

        this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
          this.isPromoterOrAdmin = res;

          this.authenticationService.getSessionType().subscribe((st) => {
            this.sessionType = st;

            const params = {
              merchantID: this.merchantID,
              isManuallyCreated: this.isManuallyCreated,
              renderValue: this.renderValue,
            };

            SideMenuService.goBack.subscribe((res) => {
              if (res == true) {
                this.hideViewDetails();
              }
            });

            this.listDB = new LoadRecords(this.socialMediaService, this.destroyEvent, params, this.sessionType);
            this.dataSource = new RecordDataSource(this.listDB, this.paginator, this.sort);

            this.listDB.getMerchantTags.subscribe((tags) => {
              this.merchantTags = JSON.parse(JSON.stringify(tags || []));
            });
          });
        });
      });
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.addDynamicData();
    const params = {
      merchantID: this.merchantID,
      isManuallyCreated: this.isManuallyCreated,
      renderValue: this.renderValue,
    };

    this.listDB = new LoadRecords(this.socialMediaService, this.destroyEvent, params, this.sessionType);
    this.dataSource = new RecordDataSource(this.listDB, this.paginator, this.sort);
  }

  addDynamicData() {
    if (this.currentPractice) {
      if (this.currentPractice['TradingAs']) {
        this.renderValue['practiceTradingAs'] = this.currentPractice['TradingAs'];

        this.dynamicData.push({
          label: 'Trading As',
          code: this.currentPractice['TradingAs'],
        });
      } else {
        this.renderValue['practiceTradingAs'] = '';
      }

      if (this.currentPractice['phones.Number']) {
        this.renderValue['practicePhoneNumber'] = this.customPhonePipe.transform(
          this.currentPractice['phones.Number'],
          'landLine'
        );
        this.dynamicData.push({
          label: 'Phone number',
          code: this.customPhonePipe.transform(this.currentPractice['phones.Number'], 'landLine'),
        });
      } else {
        this.renderValue['practicePhoneNumber'] = '';
      }

      if (this.currentPractice['emails.Email']) {
        this.renderValue['practiceEmail'] = this.currentPractice['emails.Email'];
        this.dynamicData.push({
          label: 'Email',
          code: this.currentPractice['emails.Email'],
        });
      } else {
        this.renderValue['practiceEmail'] = '';
      }

      if (this.currentPractice['addresses.Calculated']) {
        this.renderValue['practiceAddress'] = this.currentPractice['addresses.Calculated'];
        this.dynamicData.push({
          label: 'Address',
          code: this.currentPractice['addresses.Calculated'],
        });
      } else {
        this.renderValue['practiceAddress'] = '';
      }

      if (this.currentPractice['URL']) {
        this.renderValue['practiceSite'] = this.currentPractice['URL'];
        this.dynamicData.push({
          label: 'Web Site',
          code: this.currentPractice['URL'],
        });
      } else {
        this.renderValue['practiceSite'] = '';
      }
    }
  }

  convertMessage(message) {
    if (message) {
      let result;
      result = this.utils.convertMessage(message, this.renderValue);
      return result;
    }

    return message;
  }

  getColorFromSocial(type) {
    if (type === 'Pinterest') {
      return '#c8232c';
    } else if (type === 'Facebook') {
      return '#4267B2';
    } else if (type === 'Instagram') {
      return '#E1306C';
    } else if (type === 'LinkedIn') {
      return '#2867B2';
    } else if (type === 'Twitter') {
      return '#1DA1F2';
    }

    return '#4267B2';
  }

  hideViewDetails() {}

  setFilter(event, field) {
    let filter;

    if (field == 'ApprovedOrActive') {
      let _event;
      if (event == true) {
        _event = '1';
      } else {
        _event = '0';
      }
      filter = {
        field,
        value: _event,
      };

      this.searchLabel = _event;
    } else if (field == 'Tags' && event && event.length > 0) {
      let e = event;
      if (typeof e === 'string') {
        e = [event];
      }

      filter = {
        field,
        value: e,
      };

      this.searchLabel = event;
    } else if (typeof event == 'object' && event != null) {
      const v = this.customDate.transform(event, Settings.global['dateFormat']);
      filter = {
        field,
        value: v,
      };

      this.searchLabel = v;
    } else if (event) {
      filter = {
        field,
        value: event,
      };

      this.searchLabel = event;
    } else {
      filter = {
        field,
        value: '',
      };

      this.searchLabel = null;
    }

    this.dataSource.filter = filter;
    this.filteredSize = this.dataSource.filteredData.length;
  }

  removed(id) {
    if (id) {
      const confirm = new ConfirmDialog(
        'fas fa-info',
        '',
        'Are you sure you want to remove this Template ?',
        'No',
        'Yes'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation === false) {
          ref.close();
        } else {
          this.socialMediaService.removeSocialMedia(id).subscribe((res) => {
            if (res) {
              this.dataSource.removeItem = id;

              NotifyAppComponent.displayToast(
                'success',
                'Successful operation',
                'The template has been successfully removed'
              );
            }
            ref.close();
          });
        }
      });
    }
  }

  testCampaign(tag) {
    if (tag) {
      const title = 'Send Test Campaign';
      const description = `<p>To send a test Campaign, we will enable the marketing consent for you. </p>`;

      let subTitle;
      if (this.currentUser['emails.Email']) {
        subTitle = ` The message will be sent to: ${this.currentUser['emails.Email']}`;
      }

      this.utilService.getTemplateTag(tag, { fields: 'MetaData' }).subscribe((template) => {
        if (template) {
          if (template && template['MetaData'] && template['MetaData']['Amount']) {
            let label = 'Total Treatment Price';

            if (template['MetaData']['Amount']['Label']) {
              label = template['MetaData']['Amount']['Label'];
            } else {
              label = 'Treatment value';
            }

            const ref = RootAppComponent.dialog.open(ConfirmDialogMessageComponent, {
              data: {
                icon: 'message',
                title,
                yesLabel: 'Send',
                noLabel: 'Close',
                description,
                subTitle,
                isDateTimeInput: false,
                isDateInput: false,
                isTextArea: false,
                isTextInput: false,
                isPolicy: false,
                isCheckbox: false,
                displayNo: true,
                amount: 5000,
                amountLabel: label,
                isAmountInput: true,
                isAmountInputRequired: true,
              },
              width: '565px',
            });
            ref.componentInstance.closeModal.subscribe((res) => {
              ref.close();
            });
            ref.componentInstance.onConfirm.subscribe((res) => {
              if (res && res.confirm == true) {
                const p = {
                  amount: res.amount,
                };
                this.utilService.testTemplate(tag, p).subscribe((ress) => {
                  if (ress) {
                    ref.close();
                    NotifyAppComponent.displayToast(
                      'success',
                      'Successful Operation',
                      'You have successfully sent a test campaign to yourself, please check your email.'
                    );
                  }
                });
              }
            });
          } else {
            const title = 'Send Test Campaign';
            let description = `<p>To send a test Campaign, we will enable the marketing consent for you.<br><br>`;

            if (this.currentUser['emails.Email']) {
              description =
                description + ` The message will be sent to:  <strong>${this.currentUser['emails.Email']}</strong></p>`;
            } else {
              description = description + `</p>`;
            }
            const confirm = new ConfirmDialog('fas fa-info', title, description, 'No', 'Yes');

            const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
              data: confirm,
              width: '650px',
            });

            ref.componentInstance.onConfirm.subscribe((res) => {
              if (res == false) {
                ref.close();
              } else {
                this.utilService.testTemplate(tag).subscribe((res) => {
                  if (res) {
                    ref.close();
                    NotifyAppComponent.displayToast(
                      'success',
                      'Successful Operation',
                      'You have successfully sent a test campaign to yourself, please check your email.'
                    );
                  }
                });
              }
            });
          }
        }
      });
    }
  }

  EditSocialMedia(t) {
    if (t) {
      this.router.navigate([
        '/merchant',
        {
          outlets: {
            page: ['template-social-media-edit', t],
          },
        },
      ]);
    }
  }

  enableGroup() {
    if (this.selectedIDs && this.selectedIDs.length > 0) {
      const confirmDialog = new ConfirmDialog(
        'cancel',
        'Enable Selected Templates',
        '<p>Once the Template is enabled it will not appear as an option for the user.</p>',
        'Cancel',
        'Okay'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirmDialog,
        // SET TO PAYING CASH CARD
      });
      ref.componentInstance.onConfirm.subscribe((val) => {
        if (val == true) {
          ref.close();
          ref.afterClosed().subscribe((res) => {
            const payload = {
              isActive: true,
              ids: this.selectedIDs.join(','),
            };
            this.socialMediaService.editSocialMediaGroup(payload).subscribe((res) => {
              if (res && res.length > 0) {
                for (let i = 0; i < res.length; i++) {
                  this.dataSource.replaceItem = res[i];
                }

                this.selectedIDs = [];

                NotifyAppComponent.displayToast('success', 'Success!', 'Templates are enabled.');
              }
            });
          });
        } else {
          ref.close();
        }
      });
    }
  }

  disableGroup() {
    if (this.selectedIDs && this.selectedIDs.length > 0) {
      const confirmDialog = new ConfirmDialog(
        'cancel',
        'Disable Selected Templates',
        '<p>Once the template is disabled it will not appear as an option for the user.</p>',
        'Cancel',
        'Okay'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirmDialog,
        // SET TO PAYING CASH CARD
      });
      ref.componentInstance.onConfirm.subscribe((val) => {
        if (val == true) {
          ref.close();
          ref.afterClosed().subscribe((res) => {
            const payload = {
              isActive: false,
              ids: this.selectedIDs.join(','),
            };
            this.socialMediaService.editSocialMediaGroup(payload).subscribe((res) => {
              if (res && res.length > 0) {
                for (let i = 0; i < res.length; i++) {
                  this.dataSource.replaceItem = res[i];
                }

                this.selectedIDs = [];

                NotifyAppComponent.displayToast('success', 'Success!', 'Templates are disabled.');
              }
            });
          });
        } else {
          ref.close();
        }
      });
    }
  }

  indexOfTag(e) {
    if (this.searchLabel && Array.isArray(this.searchLabel) && e) {
      if (this.searchLabel.indexOf(e) != -1) {
        return true;
      }
    }

    return false;
  }

  toLowerCase(e) {
    if (e) {
      return String(e).toLocaleLowerCase();
    }

    return e;
  }

  selectPage() {
    const index = this.dataSource['_paginator']['index'] || 0;
    const pageSize = this.dataSource['_paginator']['pageSize'] || 0;

    for (let i = 0; i < (index + 1) * pageSize; i++) {
      const o = this.dataSource.filteredData[index * pageSize + i];

      if (o && o['ID'] && this.selectedIDs.indexOf(o['ID']) == -1) {
        this.selectedIDs.push(o['ID']);
      }
    }
  }

  select(id, isSelected) {
    if (isSelected == true && this.selectedIDs.indexOf(id) == -1) {
      this.selectedIDs.push(id);
    } else {
      const _index = this.selectedIDs.indexOf(id);
      this.selectedIDs.splice(_index, 1);
    }
  }

  unselect() {
    this.selectedIDs = [];
  }

  isSelected(id) {
    if (!this.selectedIDs || this.selectedIDs.length <= 0) {
      return false;
    } else {
      if (this.selectedIDs.indexOf(id) != -1) {
        return true;
      }
    }

    return false;
  }

  selectAll() {
    for (let i = 0; i < this.dataSource.filteredData.length; i++) {
      const o = this.dataSource.filteredData[i];

      if (o && o['ID'] && this.selectedIDs.indexOf(o['ID']) == -1) {
        this.selectedIDs.push(o['ID']);
      }
    }
  }

  removeGroup() {
    if (this.selectedIDs && this.selectedIDs.length > 0) {
      const confirm = new ConfirmDialog(
        'fas fa-info',
        '',
        'Are you sure you want to remove these Medications  ?',
        'No',
        'Yes'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation === false) {
          ref.close();
        } else {
          this.socialMediaService.removeSocialMediaGroup(this.selectedIDs).subscribe((res) => {
            if (res && res.length > 0) {
              for (let i = 0; i < res.length; i++) {
                this.dataSource.removeItem = res[i];
              }

              this.selectedIDs = [];
            }
            ref.close();

            NotifyAppComponent.displayToast('success', 'Success!', 'Templates are removed.');
          });
        }
      });
    }
  }

  createNew() {
    this.router.navigate(['/merchant', { outlets: { page: ['template-social-media-create'] } }]);
  }

  viewTemplate(id) {
    if (id) {
      const ref3 = RootAppComponent.dialog.open(SocialMediaViewComponent, {
        data: {
          templateID: id,
        },
        width: '900px',
        panelClass: ['noCard', 'full-big-screen'],
      });
      ref3.componentInstance.confirmShare.subscribe((res) => {
        ref3.close();
      });
      ref3.componentInstance.close.subscribe((res) => {
        ref3.close();
      });
    }
  }

  editTemplate(id) {
    this.router.navigate(['/merchant', { outlets: { page: ['template-edit', id] } }]);
  }

  enable(ID) {
    const confirmDialog = new ConfirmDialog(
      'check_circle',
      'Enable Template',
      '<p>Once the Template is enabled it will  appear as an option for the user.</p>',
      'Cancel',
      'Okay'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
      // SET TO PAYING CASH CARD
    });
    ref.componentInstance.onConfirm.subscribe((val) => {
      if (val == true) {
        ref.close();
        ref.afterClosed().subscribe((res) => {
          const payload = {
            isActive: true,
          };
          this.socialMediaService.editSocialMedia(ID, payload, this.sessionType).subscribe((res) => {
            if (res) {
              res.ID = ID;
              this.dataSource.replaceItem = res;

              NotifyAppComponent.displayToast('success', 'Success!', 'Template is enabled.');
            }
          });
        });
      } else {
        ref.close();
      }
    });
  }

  disable(ID) {
    const confirmDialog = new ConfirmDialog(
      'cancel',
      'Disable Template',
      '<p>Once the Template is disabled it will not appear as an option for the user.</p>',
      'Cancel',
      'Okay'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
      // SET TO PAYING CASH CARD
    });
    ref.componentInstance.onConfirm.subscribe((val) => {
      if (val == true) {
        ref.close();
        ref.afterClosed().subscribe((res) => {
          const payload = {
            isActive: false,
          };
          this.socialMediaService.editSocialMedia(ID, payload, this.sessionType).subscribe((res) => {
            if (res) {
              this.dataSource.replaceItem = res;

              NotifyAppComponent.displayToast('success', 'Success!', 'Template is disabled.');
            }
          });
        });
      } else {
        ref.close();
      }
    });
  }

  ngOnDestroy() {
    this.destroyEvent.emit(true);
  }
}

export class LoadRecords implements OnInit {
  public dataChange: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  items: any;
  count: any;

  get data(): any[] {
    return this.dataChange.value;
  }

  firstLoad = false;

  get firstLoadEvent() {
    return this.firstLoad;
  }

  isDestoyed = false;
  serviceRef;

  utils = new UtilsClass();

  public getMerchantTags = new EventEmitter();
  merchantTags = [];

  constructor(private socialMediaService: SocialMediaService, private destroyEvent, params, private sessionType) {
    this.destroyEvent.subscribe((res) => {
      this.isDestoyed = res;
    });

    const payload: any = {
      section: 0,
      fields:
        'ID,LastModified,Active,DateTimeCreated,Label,SocialMediaType.Code,SocialMediaType.Label,' +
        'SupplierKeys,SupplierLabels,Tags,Tag.Label,StaticList.Label,ActionCall.Label,ActionCall.Description',
    };

    this.serviceRef = this.socialMediaService.getSocialMediaList(payload, this.sessionType).subscribe((res) => {
      for (let i = 0; i < res.length; i++) {
        res[i]['Tags'] = [];
        res[i]['fullName'] = '';
        if (res[i] && res[i]['Label']) {
          res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['Label'];
        }
        if (res[i] && res[i]['ActionCall.Description']) {
          res[i]['fullName'] =
            res[i]['fullName'] + ',' + this.convertMessage(res[i]['ActionCall.Description'], params.renderValue);
        }

        if (res[i] && res[i]['ActionCall.Label']) {
          res[i]['fullName'] =
            res[i]['fullName'] + ',' + this.convertMessage(res[i]['ActionCall.Label'], params.renderValue);
        }
        if (res[i] && res[i]['StaticList.Label']) {
          res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['StaticList.Label'];
        }

        if (res[i] && res[i]['SocialMediaType.Label']) {
          res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['SocialMediaType.Label'];
        }

        if (res[i] && res[i]['SupplierLabels']) {
          res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['SupplierLabels'];
        }

        if (res[i] && res[i]['ProductLabels']) {
          res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['ProductLabels'];
        }
        if (res[i] && res[i]['CategoryLabels']) {
          res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['CategoryLabels'];
        }
        if (res[i] && res[i]['BrandLabels']) {
          res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['BrandLabels'];
        }
        if (res[i] && res[i]['TypeLabels']) {
          res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['TypeLabels'];
        }

        if (res[i] && res[i]['Post']) {
          res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['Post'];
        }

        if (res[i]['Tag.Label'] && res[i]['StaticList.Label']) {
          res[i]['StaticList.Label'] = res[i]['Tag.Label'] + '|' + res[i]['StaticList.Label'];
        } else if (res[i]['Tag.Label']) {
          res[i]['StaticList.Label'] = res[i]['Tag.Label'];
        }

        if (res && res[i] && res[i]['StaticList.Label']) {
          res[i]['Tags'] = res[i]['StaticList.Label'].split('|');
          res[i]['Tags'] = _.uniq(res[i]['Tags']);
          this.merchantTags = this.merchantTags.concat(res[i]['Tags']);
        } else {
          res[i]['Tags'] = [];
          this.merchantTags = this.merchantTags.concat(res[i]['Tags']);
        }

        if (res[i]) {
          res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['StaticList.Label'];
        }
      }

      this.merchantTags = _.uniq(this.merchantTags);
      this.merchantTags = _.orderBy(this.merchantTags);
      this.getMerchantTags.emit(this.merchantTags);
      this.count = res.length;
      this.items = res;

      this.dataChange.next(this.items.slice());
      this.firstLoad = true;

      const innerFunction = (section) => {
        const length = this.items.length;
        section = section + 1;
        UtilsClass.loadingDataSection(section);
        payload.section = section;

        this.serviceRef = this.socialMediaService.getSocialMediaList(payload, this.sessionType).subscribe((res) => {
          if (res.length > 0) {
            for (let i = 0; i < res.length; i++) {
              res[i]['Tags'] = [];
              res[i]['fullName'] = '';
              if (res[i] && res[i]['Label']) {
                res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['Label'];
              }
              if (res[i] && res[i]['ActionCall.Description']) {
                res[i]['fullName'] =
                  res[i]['fullName'] + ',' + this.convertMessage(res[i]['ActionCall.Description'], params.renderValue);
              }

              if (res[i] && res[i]['ActionCall.Label']) {
                res[i]['fullName'] =
                  res[i]['fullName'] + ',' + this.convertMessage(res[i]['ActionCall.Label'], params.renderValue);
              }
              if (res[i] && res[i]['StaticList.Label']) {
                res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['StaticList.Label'];
              }

              if (res[i] && res[i]['SocialMediaType.Label']) {
                res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['SocialMediaType.Label'];
              }

              if (res[i] && res[i]['SupplierLabels']) {
                res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['SupplierLabels'];
              }

              if (res[i] && res[i]['ProductLabels']) {
                res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['ProductLabels'];
              }
              if (res[i] && res[i]['CategoryLabels']) {
                res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['CategoryLabels'];
              }
              if (res[i] && res[i]['BrandLabels']) {
                res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['BrandLabels'];
              }
              if (res[i] && res[i]['TypeLabels']) {
                res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['TypeLabels'];
              }

              if (res[i] && res[i]['Post']) {
                res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['Post'];
              }

              if (res[i]['Tag.Label'] && res[i]['StaticList.Label']) {
                res[i]['StaticList.Label'] = res[i]['Tag.Label'] + '|' + res[i]['StaticList.Label'];
              } else if (res[i]['Tag.Label']) {
                res[i]['StaticList.Label'] = res[i]['Tag.Label'];
              }

              if (res && res[i] && res[i]['StaticList.Label']) {
                res[i]['Tags'] = res[i]['StaticList.Label'].split('|');
                res[i]['Tags'] = _.uniq(res[i]['Tags']);
                this.merchantTags = this.merchantTags.concat(res[i]['Tags']);
              } else {
                res[i]['Tags'] = [];
                this.merchantTags = this.merchantTags.concat(res[i]['Tags']);
              }

              if (res[i]) {
                res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['StaticList.Label'];
              }
            }

            this.merchantTags = _.uniq(this.merchantTags);
            this.merchantTags = _.orderBy(this.merchantTags);
            this.getMerchantTags.emit(this.merchantTags);
            this.count = res.count;
            this.items = this.items.concat(res);

            this.dataChange.next(this.items.slice());
            if (this.isDestoyed != true) {
              innerFunction(section);
            }
          } else {
            return true;
          }
        });
        UtilsClass.stopLoadingInterceptor();
      };

      if (this.isDestoyed != true) {
        innerFunction(1);
      }
    });
  }

  convertMessage(message = null, renderValue = null) {
    if (message && renderValue) {
      let result;
      result = this.utils.convertMessage(message, renderValue);
      return result;
    }

    return message;
  }

  ngOnInit() {}

  ngOnDestroy() {
    if (this.serviceRef) {
      this.serviceRef.unsubscribe();
    }
  }
}

export class RecordDataSource extends DataSource<any> {
  _filterChange = new BehaviorSubject('');
  field = '';

  get filter(): any {
    return this._filterChange.value;
  }

  set filter(item: any) {
    this.field = item.field;
    this._filterChange.next(item.value);
  }

  set removeItem(id) {
    const data = this._tableDatabase.data.filter((row) => row.ID != id);
    this._tableDatabase.dataChange.next(data);
  }

  set addItem(res) {
    this._tableDatabase.data.unshift(res);

    this._tableDatabase.dataChange.next(this._tableDatabase.data);
  }

  set replaceItem(item) {
    const _index = this._tableDatabase.data.findIndex((_obj) => _obj.ID == item.ID);
    this._tableDatabase.data[_index] = item;
    this._tableDatabase.dataChange.next(this._tableDatabase.data);
  }

  filteredData: any[] = [];

  constructor(private _tableDatabase: LoadRecords, private _paginator: MatPaginator, private _sort: MatSort) {
    super();
    this._filterChange.subscribe(() => (this._paginator.pageIndex = 0));
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<any[]> {
    const displayDataChanges = [
      this._tableDatabase.dataChange,
      this._filterChange,
      this._paginator.page,
      this._sort.sortChange,
    ];

    return observableMerge(...displayDataChanges).pipe(
      map(() => {
        this.filteredData = this._tableDatabase.data.slice().filter((item: any) => {
          if (this.field == 'Tags') {
            let f = '';
            f = item[this.field] || '';

            if (this.filter && this.filter.length > 0) {
              for (let j = 0; j < this.filter.length; j++) {
                if (f.indexOf(this.filter[j]) == -1) {
                  return false;
                }
              }
              return true;
            } else {
              return f;
            }
          } else {
            let f = '';
            f = item[this.field] || '';

            const searchStr = f.toLowerCase();

            return searchStr.indexOf(this.filter.toLowerCase()) != -1;
          }
        });
        const data = this.getSortedData(this.filteredData.slice());
        // Grab the page's slice of data.
        const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
        return data.splice(startIndex, this._paginator.pageSize);
      })
    );
  }

  disconnect() {}

  /** Returns a sorted copy of the database data. */
  getSortedData(data: any[]): any[] {
    // const data = data;
    if (!this._sort.active || this._sort.direction == '') {
      return data;
    }

    return data.sort((a, b) => {
      let propertyA: number | string = '';
      let propertyB: number | string = '';

      switch (this._sort.active) {
        case 'Tag':
          [propertyA, propertyB] = [a['Tag'], b['Tag']];
          break;

        case 'Label':
          [propertyA, propertyB] = [a['TemplateName'], b['TemplateName']];
          break;

        case 'Purpose':
          [propertyA, propertyB] = [a['Purpose_Label'], b['Purpose_Label']];
          break;

        case 'Date':
          [propertyA, propertyB] = [moment(a['Date']).toDate().getTime(), moment(b['Date']).toDate().getTime()];
          break;
      }

      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

      return (valueA < valueB ? -1 : 1) * (this._sort.direction == 'asc' ? 1 : -1);
    });
  }
}
