 <mat-card class="rel clearfix " *ngIf="landingPage && landingPage.ID">
   <form #resetForm="ngForm">
     <div class="card-header primary-gradient-img clearfix stacked"
       [attr.style]="('background:' + theme.background + ' !important') | safeStyle">

       <button class="btn-close btn-clear mat-button smt white custom-close" (click)="closeEvent()">
         <mat-icon>clear</mat-icon>
       </button>

       <div class="row clearfix">
         <div class="row titleArea clearfix">
           <h2 class="summary-header text-left rm-m">
             Customize Landing Page PDF
           </h2>

         </div>
       </div>
     </div>

     <mat-dialog-content id="instantOffercreate">
       <div class="full-width text-center smb mt " @simpleFadeAnimation>

         <div class="full-width   flex ">


           <div class="full-width mr">
       
             <h3 class="sr-title   text-left full-width text-center smt smb   "
               [attr.style]="('color:' + theme.color + ' !important') | safeStyle">
               Title</h3>

             <mat-form-field class="clearfix full-width  " style="margin-bottom: -10px;" appearance="outline">
               <mat-label>PDF Title</mat-label>
               <input name="Title" matInput [(ngModel)]="title" placeholder="PDF Title" required />
             </mat-form-field>
             <h3 class="sr-title   text-left full-width text-center smt smb   "
               [attr.style]="('color:' + theme.color + ' !important') | safeStyle">
               QR Scan call to action</h3>
             <div class="full-width mb">

               <mat-form-field class="full-width animate" appearance="outline">
                 <mat-label>call to action</mat-label>
                 <textarea matInput name="description" class="min-landing-page-cot" [(ngModel)]="callForAction"
                   placeholder="call to action">   </textarea>

               </mat-form-field>

             </div>






           </div>


           <mat-card class="editor-card-preview2 ml row file animate mat-elevation-z4  full-width "
             [ngClass]="(isHeaderImage!=true)?'no-image':''">

             <div class="full-width landing-page-create-gallery2222-container">

               <h3 class="sr-title   full-width text-center    rm-m smb   " *ngIf="isHeaderImage==true"
                 [attr.style]="('color:' + theme.color + ' !important') | safeStyle">
                 Header Image</h3>

               <h3 class="sr-title   full-width text-center    rm-m smb   " *ngIf="isHeaderImage!=true"
                 [attr.style]="('color:grey  !important') | safeStyle">
                 Header Image</h3>
               <app-ep-document-view-image *ngIf=" isHeaderImage!=true" class=" landing-page-create-gallery2222"
                 [imageLink]="'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/no-image.jpg'"
                 [hideZoomButton]="true">
               </app-ep-document-view-image>


               <app-ep-document-view-image *ngIf="headerImage!='merchant' && isHeaderImage==true"
                 class=" landing-page-create-gallery2222" [ngClass]="(getCircle(headerImage)==true)?'round-circle':''"
                 [imageLink]="headerImage" [hideZoomButton]="false">
               </app-ep-document-view-image>

               <app-ep-document-view-image *ngIf="headerImage=='merchant' && merchantID  && isHeaderImage==true"
                 class=" landing-page-create-gallery2222 round-circle" [imageLink]="getMerchantLink()"
                 [hideZoomButton]="false">
               </app-ep-document-view-image>

             </div>



             <mat-checkbox class=" text-left  no-image-checkbox  " matTooltip="Add Header Image" color="accent"
               name="isHeaderImage" [(ngModel)]="isHeaderImage">

             </mat-checkbox>

             <div class="full-width  text-center card-image-footer">

               <button mat-icon-button class="btn pull-right mr" *ngIf="isHeaderImage!=true" color="accent"
                 [disabled]="true">
                 <mat-icon class="fas fa-upload"></mat-icon>
               </button>
               <button mat-raised-button class="btn pull-left ml" *ngIf="isHeaderImage!=true" [disabled]="true"
                 color="accent">
                 <mat-icon class="fas fa-images smr"></mat-icon> Gallery
               </button>

               <button mat-icon-button class="btn pull-right mr" *ngIf="isHeaderImage==true" color="accent"
                 matTooltip="Upload New Image" (click)="upload()">
                 <mat-icon class="fas fa-upload " [attr.style]="('color:' + theme.color + ' !important') | safeStyle">
                 </mat-icon>
               </button>

               <button mat-raised-button class="btn pull-left ml" *ngIf="isHeaderImage==true"
                 [attr.style]="('background:' + theme.color + ' !important') | safeStyle" color="accent"
                 (click)="galleryHeader()">
                 <mat-icon class="fas fa-images smr"></mat-icon> Gallery
               </button>





             </div>
           </mat-card>



         </div>

         <div class="full-width">

           <div class="full-width">


             <mat-checkbox class=" text-left    smt smb  "
               [ngClass]="(isHideDescription!=true)?'pull-right':'pull-left'" color="accent" name="isHideDescription"
               [(ngModel)]="isHideDescription">

               Hide PDF Description
             </mat-checkbox>


             <h3 class="sr-title   text-left  smt smb   " *ngIf="isHideDescription!=true  "
               [attr.style]="('color:' + theme.color + ' !important') | safeStyle">
               Description </h3>


           </div>

           <message-input
             *ngIf="loadDescription==true && isHideDescription!=true  && loadPromoter==true  && loadContact==true && loadMerchant==true "
             [isTiny]="true" class="full-width  message-input-component2600  message-input-component2" [isModal]="false"
             [messageMode]="messageMode" [label]="'Please insert a description'" [message]="_description"
             [showAllCustomization]="true" [textLength]="0" [displayEmoji]="false" [displayMessageTemplate]="true"
             [contact]="contact" [displayPatient]="false" [displayContact]="true" [displayMerchant]="true"
             [displayPromoter]="true" [displayMarketing]="true" [displayExpend]="false" [displayDentalOffers]="true"
             [displayDocument]="true" [saveAsTemplate]="false" [isSMSPreview]="true" [isDesktopPreview]="true"
             [displaySaveAsTemplate]="true" [encode]="false" [tableName]="tableName"
             (getResult)="getResultDescription($event)">
           </message-input>


         </div>

       </div>
     </mat-dialog-content>
     <div class="drop-button text-center white-background">
       <hr>

       <div class="full-width">


         <button mat-raised-button class=" action-button btn-large   pull-right smt" color="accent" (click)="save()">

           Save & Preview
         </button>

         <button mat-raised-button class=" action-button  mr btn-large  pull-right smt"
           [attr.style]="('background:' + theme.color + ' !important') | safeStyle" color="primary" (click)="preview()">
           Preview
         </button>





         <button mat-raised-button class=" action-button btn-large  pull-left smt"
           [attr.style]="('background:' + theme.color + ' !important') | safeStyle" color="primary"
           (click)="closeEvent()">
           Close
         </button>




       </div>

     </div>

   </form>
 </mat-card>
