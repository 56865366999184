<div class="rel" *ngIf="listDB.firstLoadEvent == false" @ngIfAnimation>
  <div class="row clearfix padded rel">
    <h1 class="dataHeader rm-mt transparent">Fetching data...</h1>
  </div>
</div>

<div [hidden]="listDB.data.length == 0">
  <div class="row clearfix">
    <div class="row clearfix page-title" [class.pull-left]="isPromoterOrAdmin == false">
      <h2 class="dataHeader pull-left rm-mt">
        {{ title }}
        <span class="badge" [endVal]="dataSource.filteredData.length" countUp></span>
      </h2>

      <button class="add-doc pull-right" *ngIf="isPromoterOrAdmin == true" (click)="addDocument()" mat-raised-button
              color="accent">
        <mat-icon>add</mat-icon> Add document
      </button>
    </div>

    <div class="row flex ctl">
      <mat-button-toggle-group class="btn-group text-center with-icons mr" #group="matButtonToggleGroup"
                               [(ngModel)]="typeFilter" (ngModelChange)="setFilter(typeFilter, 'type')">
        <mat-button-toggle class="text-center full-width" [value]="'all'">
          <mat-icon class="fas fa-infinity"></mat-icon>
          All
        </mat-button-toggle>

        <mat-button-toggle class="text-center full-width" [value]="'pdf'">
          <mat-icon class="fas fa-file-alt"></mat-icon>
          Documents
        </mat-button-toggle>
        <mat-button-toggle class="text-center full-width" [value]="'video'">
          <mat-icon class="fas fa-video"></mat-icon>
          Videos
        </mat-button-toggle>
        <mat-button-toggle class="text-center full-width" [value]="'image'">
          <mat-icon class="fas fa-image"></mat-icon>
          Stockart
        </mat-button-toggle>
      </mat-button-toggle-group>

      <mat-button-toggle-group class="btn-group flex text-center with-icons" *ngIf="displayActiveFilter == true"
                               [(ngModel)]="isActiveFilter" (change)="setFilter($event.value, 'Omitted')">
        <mat-button-toggle class="full-width text-center" [value]="'all'">
          <mat-icon class="fas fa-infinity"></mat-icon>
          All
        </mat-button-toggle>
        <mat-button-toggle class="full-width text-center" [value]="'0'">
          <mat-icon class="fas fa-check-circle"></mat-icon>
          Active
        </mat-button-toggle>
        <mat-button-toggle class="full-width text-center" [value]="'1'">
          <mat-icon class="fas fa-times-circle"></mat-icon>
          Not Active
        </mat-button-toggle>
      </mat-button-toggle-group>

      <button class="animated" *ngIf="selectedIDs && selectedIDs.length > 0" [matMenuTriggerFor]="ActionBtnmenu"
              mat-stroked-button matTooltip="Options">
        <mat-icon class="fas fa-cog"></mat-icon>  <span *ngIf="selectedIDs && selectedIDs.length>0"
          class="selected-number-item  accent-background">{{selectedIDs.length}}</span>
      </button>
    </div>
    <mat-menu #ActionBtnmenu="matMenu">
      <button class="row select-btn" (click)="unselect()" mat-menu-item color="">
        <mat-icon>select_all</mat-icon> Deselect
      </button>

      <button class="row select-btn" (click)="disableGroup()" mat-menu-item color="">
        <mat-icon>cancel</mat-icon>
        Disable
      </button>

      <button class="row select-btn" (click)="enableGroup()" mat-menu-item color="">
        <mat-icon>check_circle</mat-icon>
        Enable
      </button>

      <button class="" *ngIf="isPromoterOrAdmin == true" (click)="removeDocumentGroup()" mat-menu-item color="">
        <mat-icon>delete</mat-icon>
        Remove
      </button>
    </mat-menu>
  </div>

  <mat-card class="list-card">
    <div class="row clearfix searchArea primary-gradient-img">
      <div class="row clearfix flex" id="search">
        <div class="row flex animated fadeInDown full-width flexwrap">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Label, Item Code</mat-label>
            <input class="clearfix" (input)="setFilter($event.target.value, 'fullLabel')" name="Labels" matInput/>
          </mat-form-field>

          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Category</mat-label>
            <mat-select class="" (selectionChange)="setFilter($event.value, 'TreatmentCategory.Label')" name="Category"
                        placeholder="Category">
              <mat-option [value]="null">
                All
              </mat-option>
              <mat-option *ngFor="
                  let a of dataSource.filteredData
                    | objectPropertyValue: 'TreatmentCategory.Label'
                    | unique
                    | without: ['', null]
                    | orderBy
                " [value]="a">
                {{ treatmentUtil.getLabel(a) }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Type</mat-label>
            <mat-select class="" (selectionChange)="setFilter($event.value, 'TreatmentType.Label')" name="Type"
                        placeholder="Type">
              <mat-option [value]="null">
                All
              </mat-option>
              <mat-option *ngFor="
                  let a of dataSource.filteredData
                    | objectPropertyValue: 'TreatmentType.Label'
                    | unique
                    | without: ['', null]
                    | orderBy
                " [value]="a">
                {{ treatmentUtil.getLabel(a) }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Brand</mat-label>
            <mat-select class="" (selectionChange)="setFilter($event.value, 'TreatmentBrand.Label')" name="Brand"
                        placeholder="Brand">
              <mat-option [value]="null">
                All
              </mat-option>
              <mat-option *ngFor="
                  let a of dataSource.filteredData
                    | objectPropertyValue: 'TreatmentBrand.Label'
                    | unique
                    | without: ['', null]
                    | orderBy
                " [value]="a">
                {{ treatmentUtil.getLabel(a) }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Product</mat-label>
            <mat-select class="" (selectionChange)="setFilter($event.value, 'TreatmentProduct.Label')" name="Product"
                        placeholder="Product">
              <mat-option [value]="null">
                All
              </mat-option>
              <mat-option *ngFor="
                  let a of dataSource.filteredData
                    | objectPropertyValue: 'TreatmentProduct.Label'
                    | unique
                    | without: ['', null]
                    | orderBy
                " [value]="a">
                {{ treatmentUtil.getLabel(a) }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Manufacturer</mat-label>
            <mat-select class="" (selectionChange)="setFilter($event.value, 'Manufacturer.Name')" name="Manufacturer"
                        placeholder="Manufacturer">
              <mat-option [value]="null">
                All
              </mat-option>
              <mat-option *ngFor="
                  let a of dataSource.filteredData
                    | objectPropertyValue: 'Manufacturer.Name'
                    | unique
                    | without: ['', null]
                    | orderBy
                " [value]="a">
                {{ a }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Distributor</mat-label>
            <mat-select class="" (selectionChange)="setFilter($event.value, 'Distributor.Name')" name="Distributor"
                        placeholder="Distributor">
              <mat-option [value]="null">
                All
              </mat-option>
              <mat-option *ngFor="
                  let a of dataSource.filteredData
                    | objectPropertyValue: 'Distributor.Name'
                    | unique
                    | without: ['', null]
                    | orderBy
                " [value]="a">
                {{ a }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Document Purpose</mat-label>
            <mat-select class="" (selectionChange)="setFilter($event.value, 'Document.Type.Label')" name="purpose"
                        placeholder="Document Purpose">
              <mat-option [value]="null">
                All
              </mat-option>
              <mat-option *ngFor="
                  let a of dataSource.filteredData
                    | objectPropertyValue: 'Document.Type.Label'
                    | unique
                    | without: ['', null]
                    | orderBy
                " [value]="a">
                {{ a }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>

    <mat-table class="list-table vertical-cent" #table [dataSource]="dataSource" matSort>
      <!-- thumbnail of media -->

      <ng-container cdkColumnDef="selectCheckBox" sticky>
        <mat-header-cell *cdkHeaderCellDef> </mat-header-cell>
        <mat-cell *cdkCellDef="let row">
          <div class="margin-btm pull-left">
            <mat-checkbox [checked]="isSelected(row['ID'])" (change)="select(row['ID'], $event.checked)" color="accent">
            </mat-checkbox>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container cdkColumnDef="mediaThumbnail">
        <mat-header-cell *cdkHeaderCellDef mat-sort-header>Thumbnail</mat-header-cell>
        <mat-cell *cdkCellDef="let row" (click)="
            viewDocument(
              row['Document_key'],
              treatmentUtil.getLabel(row['Document.Label']),
              row['Document.Description']
            )
          ">
          <!-- ep-document-image-view or similar component -->
          <div class="document-thumb">
            <app-ep-document-thumbnail-view class="text-center documentThumbView" [documentID]="row['Document_key']"
                                            [hideZoomButton]="true"></app-ep-document-thumbnail-view>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container cdkColumnDef="documentLabel">
        <mat-header-cell *cdkHeaderCellDef mat-sort-header>Label</mat-header-cell>
        <mat-cell *cdkCellDef="let row" (click)="
            viewDocument(
              row['Document_key'],
              treatmentUtil.getLabel(row['Document.Label']),
              row['Document.Description']
            )
          " matTooltip="{{ treatmentUtil.getLabel(row['Document.Label']) }}">
          <strong>{{ treatmentUtil.getLabel(row['Document.Label']) }} </strong>
          <span class="value chip new" *ngIf="isNew(row['DateTimeCreated']) == true">NEW</span>
        </mat-cell>
      </ng-container>

      <ng-container cdkColumnDef="itemCode">
        <mat-header-cell *cdkHeaderCellDef mat-sort-header>Item Code</mat-header-cell>
        <mat-cell *cdkCellDef="let row" (click)="expandProduct(row)">
          <span *ngIf="!row['isMerged']; else merged" (click)="
              viewDocument(
                row['Document_key'],
                treatmentUtil.getLabel(row['Document.Label']),
                row['Document.Description']
              )
            ">{{ row['TreatmentProduct.ItemCode'] || '' }}</span>
          <ng-template #merged>
            <span class="btn-show-all" (click)="expandProduct(row)">
              Various (show all <strong *ngIf="row.mergedLength">{{ row.mergedLength }}</strong>)
            </span>
          </ng-template>
        </mat-cell>
      </ng-container>

      <ng-container cdkColumnDef="Category">
        <mat-header-cell *cdkHeaderCellDef mat-sort-header>Category</mat-header-cell>
        <mat-cell *cdkCellDef="let row" (click)="
            viewDocument(
              row['Document_key'],
              treatmentUtil.getLabel(row['Document.Label']),
              row['Document.Description']
            )
          " matTooltip="{{ treatmentUtil.getLabel(row['TreatmentCategory.Label']) }}">
          {{ treatmentUtil.getLabel(row['TreatmentCategory.Label']) || '...' }}
        </mat-cell>
      </ng-container>

      <ng-container cdkColumnDef="Type">
        <mat-header-cell *cdkHeaderCellDef mat-sort-header>Type</mat-header-cell>
        <mat-cell *cdkCellDef="let row" (click)="
            viewDocument(
              row['Document_key'],
              treatmentUtil.getLabel(row['Document.Label']),
              row['Document.Description']
            )
          " matTooltip="{{ treatmentUtil.getLabel(row['TreatmentType.Label']) }}">
          {{ treatmentUtil.getLabel(row['TreatmentType.Label']) || '...' }}
        </mat-cell>
      </ng-container>

      <ng-container cdkColumnDef="Brand">
        <mat-header-cell *cdkHeaderCellDef mat-sort-header>Brand</mat-header-cell>
        <mat-cell *cdkCellDef="let row" (click)="
            viewDocument(
              row['Document_key'],
              treatmentUtil.getLabel(row['Document.Label']),
              row['Document.Description']
            )
          " matTooltip="{{ treatmentUtil.getLabel(row['TreatmentBrand.Label']) }}">
          {{ treatmentUtil.getLabel(row['TreatmentBrand.Label']) || '...' }}
        </mat-cell>
      </ng-container>

      <ng-container cdkColumnDef="Product">
        <mat-header-cell *cdkHeaderCellDef mat-sort-header>Product</mat-header-cell>
        <mat-cell *cdkCellDef="let row" (click)="
            viewDocument(
              row['Document_key'],
              treatmentUtil.getLabel(row['Document.Label']),
              row['Document.Description']
            )
          " matTooltip="{{ treatmentUtil.getLabel(row['TreatmentProduct.Label']) }}">
          {{ treatmentUtil.getLabel(row['TreatmentProduct.Label']) || '...' }}
        </mat-cell>
      </ng-container>

      <ng-container cdkColumnDef="Manufacturer">
        <mat-header-cell *cdkHeaderCellDef mat-sort-header>Manufacturer</mat-header-cell>
        <mat-cell *cdkCellDef="let row" (click)="
            viewDocument(
              row['Document_key'],
              treatmentUtil.getLabel(row['Document.Label']),
              row['Document.Description']
            )
          " matTooltip="{{ row['Manufacturer.Name'] }}">
          {{ row['Manufacturer.Name'] || '...' }}
        </mat-cell>
      </ng-container>

      <ng-container cdkColumnDef="Distributor">
        <mat-header-cell *cdkHeaderCellDef mat-sort-header>Distributor</mat-header-cell>
        <mat-cell *cdkCellDef="let row" (click)="
            viewDocument(
              row['Document_key'],
              treatmentUtil.getLabel(row['Document.Label']),
              row['Document.Description']
            )
          " matTooltip="{{ row['Distributor.Name'] }}">
          {{ row['Distributor.Name'] || '...' }}
        </mat-cell>
      </ng-container>

      <ng-container cdkColumnDef="mediaMedium">
        <mat-header-cell class="col-thin" *cdkHeaderCellDef mat-sort-header>
          Media Type
        </mat-header-cell>
        <mat-cell class="col-thin" *cdkCellDef="let row" (click)="
            viewDocument(
              row['Document_key'],
              treatmentUtil.getLabel(row['Document.Label']),
              row['Document.Description']
            )
          ">
          {{ getDocumentLabel(row['type']) }}
        </mat-cell>
      </ng-container>

      <ng-container cdkColumnDef="documentType">
        <mat-header-cell class="col-thin" *cdkHeaderCellDef mat-sort-header>
          Document Purpose
        </mat-header-cell>
        <mat-cell class="col-thin" *cdkCellDef="let row" (click)="
            viewDocument(
              row['Document_key'],
              treatmentUtil.getLabel(row['Document.Label']),
              row['Document.Description']
            )
          " matTooltip="{{ row['Document.Type.Label'] }}">
          {{ row['Document.Type.Label'] }}
        </mat-cell>
      </ng-container>

      <ng-container cdkColumnDef="Date">
        <mat-header-cell class="col-thin" *cdkHeaderCellDef mat-sort-header> Created Date</mat-header-cell>
        <mat-cell class="col-thin" *cdkCellDef="let row" (click)="viewDocument(row['Document_key'])"
                  matTooltip="{{ row['DateTimeCreated'] | customDateTime }}">
          {{ row['DateTimeCreated'] | customDate }}
        </mat-cell>
      </ng-container>

      <!-- is it mandatory -->
      <!-- <ng-container cdkColumnDef="MandatoryStatus">
        <mat-header-cell *cdkHeaderCellDef mat-sort-header class="col-thin">{{"Mandatory" | translate}}
        </mat-header-cell>
        <mat-cell *cdkCellDef="let row" class="col-thin"
          (click)="viewDocument(row['Document_key'],treatmentUtil.getLabel(row['Document.Label']),row['Document.Description'])">
          <span *ngIf="row['Mandatory.Effective']=='1'" class="list-label smaller"
            style="background-color:rgb(90, 186, 71)">
            <mat-icon>check</mat-icon>
          </span>
          <span *ngIf="row['Mandatory.Effective']!='1'" class="list-label smaller"
            style="background-color:rgb(239, 71, 39)">
            <mat-icon>close</mat-icon>
          </span>
        </mat-cell>
      </ng-container> -->
      <!-- is it active for merchant -->
      <ng-container cdkColumnDef="ActiveStatus">
        <mat-header-cell class="col-thin" *cdkHeaderCellDef mat-sort-header>Active</mat-header-cell>
        <mat-cell class="col-thin" *cdkCellDef="let row" (click)="
            viewDocument(row['Document_key'], treatmentUtil.getLabel(row['Document.Label']), row['Document.Type.Label'])
          ">
          <span class="list-label smaller" *ngIf="row['Omitted'] != '1'" style="background-color: rgb(90, 186, 71)">
            <mat-icon>check</mat-icon>
          </span>
          <span class="list-label smaller" *ngIf="row['Omitted'] == '1'" style="background-color: rgb(239, 71, 39)">
            <mat-icon>close</mat-icon>
          </span>
        </mat-cell>
      </ng-container>

      <ng-container cdkColumnDef="Actions">
        <mat-header-cell *cdkHeaderCellDef mat-sort-header>Actions</mat-header-cell>
        <mat-cell *cdkCellDef="let row">
          <div style="float: right">
            <button [matMenuTriggerFor]="menu" mat-icon-button>
              <mat-icon>more_vert</mat-icon>
            </button>

            <mat-menu #menu="matMenu">
              <button *ngIf="row['Manufacturer_key']" (click)="viewSupplier(row['Manufacturer_key'])" mat-menu-item>
                <mat-icon class="fas fa-industry mr"></mat-icon>
                <span>View Manufacturer</span>
              </button>

              <button *ngIf="row['Distributor_key']" (click)="viewSupplier(row['Distributor_key'])" mat-menu-item>
                <mat-icon class="fas fa-truck mr"></mat-icon>
                <span>View Distributor</span>
              </button>

              <button *ngIf="row['TreatmentProduct_key']" (click)="viewProduct(row['TreatmentProduct_key'])"
                      mat-menu-item>
                <mat-icon class="fas fa-box-open"></mat-icon>
                <span>View Product</span>
              </button>

              <button *ngIf="row['TreatmentBrand_key']" (click)="viewBrand(row['TreatmentBrand_key'])" mat-menu-item>
                <mat-icon class="fas fa-parachute-box"></mat-icon>
                <span>View Brand</span>
              </button>

              <!--

                <button *ngIf="row['Document_key']" mat-menu-item
                  (click)="viewDocumentTreatmentGroup(row['Document_key'])">
                  <mat-icon class="fas fa-file-import"></mat-icon>
                  <span>{{"View Linked Products" | translate}}</span>
                </button> -->

              <button *ngIf="row['Document_key']" (click)="
                  viewDocument(
                    row['Document_key'],
                    treatmentUtil.getLabel(row['Document.Label']),
                    row['Document.Description']
                  )
                " mat-menu-item>
                <mat-icon>find_in_page</mat-icon>
                <span>View Document</span>
              </button>

              <button *ngIf="row['ID'] && isPromoterOrAdmin == true" (click)="modifyDocument(row['ID'])" mat-menu-item>
                <mat-icon>edit</mat-icon>
                <span>Edit Document</span>
              </button>

              <button *ngIf="row['ID'] && row['Omitted'] == '1' && row['Document.Type.Code'] != 'SRVSPL'"
                      (click)="enableDocument(row['ID'])" mat-menu-item>
                <mat-icon>check</mat-icon>
                <span>Enable Document</span>
              </button>

              <button *ngIf="row['ID'] && row['Omitted'] != '1' && row['Document.Type.Code'] != 'SRVSPL'"
                      (click)="disableDocument(row['ID'])" mat-menu-item>
                <mat-icon>remove_circle</mat-icon>
                <span>Disable Document</span>
              </button>

              <button *ngIf="row['ID'] && row['Mandatory.Effective'] != '1' && row['Document.Type.Code'] != 'SRVSPL'"
                      (click)="requireDocument(row['ID'])" mat-menu-item>
                <mat-icon>check</mat-icon>
                <span>Mandatory</span>
              </button>

              <button *ngIf="row['ID'] && row['Mandatory.Effective'] == '1' && row['Document.Type.Code'] != 'SRVSPL'"
                      (click)="notRequiredDocument(row['ID'])" mat-menu-item>
                <mat-icon>remove_circle</mat-icon>
                <span>Not Mandatory</span>
              </button>

              <button *ngIf="row['ID'] && isPromoterOrAdmin == true" (click)="removeDocument(row['ID'])" color="accent"
                      mat-menu-item>
                <mat-icon>delete</mat-icon>
                <span>Delete Document</span>
              </button>
            </mat-menu>
          </div>
        </mat-cell>
      </ng-container>
      <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *cdkRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
    <mat-paginator #paginator [length]="dataSource.filteredData.length" [pageIndex]="0" [pageSize]="pageSize"
                   [pageSizeOptions]="pageSizeOptions">
    </mat-paginator>
  </mat-card>
</div>

<div *ngIf="listDB.data.length == 0 && listDB.firstLoadEvent != false">
  <div class="row clearfix">
    <div class="pull-left toggle-card"></div>

    <!-- add new document -->
    <button class="pull-right" *ngIf="isPromoterOrAdmin == true" (click)="addDocument()" mat-raised-button
            color="accent">
      Add document
    </button>
  </div>
  <div class="row clearfix">
    <app-empty-list-message [title]="'No documents found'" [message]="'There are no documents in the database'">
    </app-empty-list-message>
  </div>
</div>
