/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./marketing-social-media-redirection.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./marketing-social-media-redirection.component";
import * as i3 from "@angular/router";
var styles_MarketingSocialMediaRedirectionComponent = [i0.styles];
var RenderType_MarketingSocialMediaRedirectionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MarketingSocialMediaRedirectionComponent, data: {} });
export { RenderType_MarketingSocialMediaRedirectionComponent as RenderType_MarketingSocialMediaRedirectionComponent };
export function View_MarketingSocialMediaRedirectionComponent_0(_l) { return i1.ɵvid(0, [], null, null); }
export function View_MarketingSocialMediaRedirectionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-marketing-social-media-redirection", [], null, null, null, View_MarketingSocialMediaRedirectionComponent_0, RenderType_MarketingSocialMediaRedirectionComponent)), i1.ɵdid(1, 114688, null, 0, i2.MarketingSocialMediaRedirectionComponent, [i3.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MarketingSocialMediaRedirectionComponentNgFactory = i1.ɵccf("app-marketing-social-media-redirection", i2.MarketingSocialMediaRedirectionComponent, View_MarketingSocialMediaRedirectionComponent_Host_0, {}, {}, []);
export { MarketingSocialMediaRedirectionComponentNgFactory as MarketingSocialMediaRedirectionComponentNgFactory };
