import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';

export class drawDown {
  invoiceDate: string;
  reference: string;
  description: string;
  amount: number;
  status: string;
  paidTo: string;
}

const DRAW_DOWN_DATA: drawDown[] = [
  {
    reference: '',
    invoiceDate: '10/2/2020',
    description: 'Other',
    amount: 2000,
    status: 'Proposed',
    paidTo: 'Dentist',
  },
  {
    reference: '',
    invoiceDate: '10/3/2020',
    description: 'Other',
    amount: 2500,
    status: 'Proposed',
    paidTo: 'Dentist',
  },
  {
    reference: '',
    invoiceDate: '10/4/2020',
    description: 'Other',
    amount: 2500,
    status: 'Proposed',
    paidTo: 'Dentist',
  },
  {
    reference: '',
    invoiceDate: '10/5/2020',
    description: 'Other',
    amount: 3000,
    status: 'Proposed',
    paidTo: 'Dentist',
  },
];

export class payment {
  due: string;
  status: string;
  amount: number;
  type: string;
  description: string;
  invoice: string;
  account: string;
  id: string;
}

const PAYMENT_DATA: payment[] = [
  {
    due: '10/2/2020',
    status: 'Anticipated',
    amount: 1000,
    type: 'DDB',
    description: 'Drawdown payable to merchant',
    invoice: '',
    account: '<Trading Account>',
    id: '',
  },
  {
    due: '10/3/2020',
    status: 'Anticipated',
    amount: 1500,
    type: 'RMSF',
    description: 'Recourse release MSF',
    invoice: '',
    account: '<Trading Account>',
    id: '',
  },
];

@Component({
  selector: 'app-contract-invoices-settlements',
  templateUrl: './contract-invoices-settlements.component.html',
  styleUrls: ['./contract-invoices-settlements.component.css'],
  animations: [
    trigger('simpleFadeAnimation', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [style({ opacity: 0, height: 0 }), animate(300)]),
      transition(':leave', animate(300, style({ opacity: 0, height: '*' }))),
    ]),
  ],
})
export class ContractInvoicesSettlementsComponent implements OnInit {
  @Input()
  status;

  @Input()
  contractID;

  isAdminOrPromoter = false;

  displayedColumns: string[] = ['reference', 'invoiceDate', 'description', 'amount', 'status', 'paidTo'];
  drawDownSource = DRAW_DOWN_DATA;

  displayedColumns2: string[] = ['due', 'status', 'amount', 'type', 'description', 'invoice', 'account', 'id'];
  paymentSource = PAYMENT_DATA;

  constructor(private AuthenticationService: AuthenticationService, private activeRoute: ActivatedRoute) {}

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      if (params['status']) {
        this.status = params['status'];
      }

      this.AuthenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isAdminOrPromoter = res;
      });
    });
  }

  getTotalCost() {
    return this.drawDownSource.map((t) => t.amount).reduce((acc, value) => acc + value, 0);
  }

  getTotalPayment() {
    return this.paymentSource.map((t) => t.amount).reduce((acc, value) => acc + value, 0);
  }
}
