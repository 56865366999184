import { BehaviorSubject, merge as observableMerge, Observable } from 'rxjs';

import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { DataSource } from '@angular/cdk/table';
import { Component, ElementRef, EventEmitter, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { ActionLogListComponent } from '../../action-log/action-log-list/action-log-list.component';
import { DocumentCreateComponent } from '../../document/document-create/document-create.component';
import { MerchantAccessCreateComponent } from '../merchant-access-create/merchant-access-create.component';
import { MerchantService } from '../shared/merchant.service';

import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { SideMenuService } from '../../../shared/services/side-menu.service';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { MerchantViewComponent } from '../merchant-view/merchant-view.component';
import { CustomPhonePipe } from '../../../shared/pipes/custom-phone.pipe';
import { DentistListComponent } from '../../dentist/dentist-list/dentist-list.component';
import { MerchantSettlementComponent } from '../merchant-settlement/merchant-settlement.component';

@Component({
  selector: 'app-merchant-list',
  templateUrl: './merchant-list.component.html',
  styleUrls: ['./merchant-list.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.row', style({ opacity: 0 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.row', style({ opacity: 1 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class MerchantListComponent implements OnInit {
  @Input()
  status;

  @Input()
  dateFrom;
  selectedIDs = [];
  @Input()
  dateTo;

  invitationID = null;
  isPromoterOrAdmin = false;

  @Input()
  pageSize = Settings.global['listPageSize'] || 20;
  pageSizeOptions = [10, Number(this.pageSize), Number(this.pageSize) * 2, Number(this.pageSize) * 3];

  utils = new UtilsClass();

  displayedColumns = [
    'selectCheckBox',
    'ProfileImage',
    'TradingAS',
    'MerchantPhone',
    'CreatedDate',
    'Address',
    'State',
    'Postcode',
    'Matrialrequest',
    'active',
    'Actions',
  ];
  filters = [];

  searchVals = new FormControl();

  searchValList = ['Trading as', 'Full Name', 'Email', 'Phone', 'Mobile'];

  expandedRow: number;
  public listDB: LoadRecords | null;
  dataSource: RecordDataSource | null;

  filteredSize = null;

  destroyEvent = new EventEmitter();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('filter', { static: false }) filter: ElementRef;

  constructor(
    private MerchantService: MerchantService,
    private dialog: MatDialog,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private customPhonePipe: CustomPhonePipe
  ) {}

  ngOnInit() {
    SideMenuService.goBack.subscribe((res) => {
      if (res == true) {
        this.hideViewDetails();
      }
    });
    this.activeRoute.params.subscribe((params) => {
      if (params['status']) {
        this.status = params['status'];
      }
    });

    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;
      if (this.isPromoterOrAdmin == true) {
        this.listDB = new LoadRecords(
          this.MerchantService,
          this.destroyEvent,
          this.isPromoterOrAdmin,
          this.status,
          this.customPhonePipe
        );
        this.dataSource = new RecordDataSource(this.listDB, this.paginator, this.sort);
      } else {
        this.router.navigate(['/merchant']);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;
      if (this.isPromoterOrAdmin == true) {
        this.listDB = new LoadRecords(
          this.MerchantService,
          this.destroyEvent,
          this.isPromoterOrAdmin,
          this.status,
          this.customPhonePipe
        );
        this.dataSource = new RecordDataSource(this.listDB, this.paginator, this.sort);
      } else {
        this.router.navigate(['/merchant']);
      }
    });
  }

  staffList(item) {
    if (item && item.ID) {
      const ref = this.dialog.open(DentistListComponent, {
        data: {
          merchantID: item.ID,
          title: item.TradingAs + ' Staff Members',
          pageSize: 10,
        },
        panelClass: ['max-width-panel-1800'],
        width: '90vw',
        height: '80vh',
      });
      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    }
  }
  selectPage() {
    const index = this.dataSource['_paginator']['index'] || 0;
    const pageSize = this.dataSource['_paginator']['pageSize'] || 0;

    for (let i = 0; i < (index + 1) * pageSize; i++) {
      const o = this.dataSource.filteredData[index * pageSize + i];

      if (o && o['ID'] && this.selectedIDs.indexOf(o['ID']) == -1) {
        this.selectedIDs.push(o['ID']);
      }
    }
  }

  setFilter(event, field) {
    let filter;

    if (event) {
      filter = {
        field,
        value: event,
      };
    } else {
      filter = {
        field,
        value: '',
      };
    }

    this.dataSource.filter = filter;
    this.filteredSize = this.dataSource.filteredData.length;
  }

  convertPhone(val) {
    if (val) {
      if (val.startsWith('13')) {
        return val;
      } else {
        return '0061' + val.substring(1);
      }
    }
  }

  contact(id) {
    const data = {
      targetType: 'merchant',
      targetID: id,
      asGuest: false,
      asProfile: true,
      asPractice: true,
    };
    AuthenticationService.contactInputEvent.emit(data);
  }

  select(id, isSelected) {
    if (isSelected == true && this.selectedIDs.indexOf(id) == -1) {
      this.selectedIDs.push(id);
    } else {
      const _index = this.selectedIDs.indexOf(id);
      this.selectedIDs.splice(_index, 1);
    }
  }

  unselect() {
    this.selectedIDs = [];
  }

  isSelected(id) {
    if (!this.selectedIDs || this.selectedIDs.length <= 0) {
      return false;
    } else {
      if (this.selectedIDs.indexOf(id) != -1) {
        return true;
      }
    }

    return false;
  }

  selectAll() {
    for (let i = 0; i < this.dataSource.filteredData.length; i++) {
      const o = this.dataSource.filteredData[i];

      if (o && o['ID'] && this.selectedIDs.indexOf(o['ID']) == -1) {
        this.selectedIDs.push(o['ID']);
      }
    }
  }

  openQuickViewDialog(ID) {
    // let ref = this.dialog.open(MerchantViewComponent, {
    //   data: ID,
    //   width: "600px"
    // });
    // ref.componentInstance.close.subscribe(res => {
    //   if (res == true) {
    //     ref.close()
    //   }
    // })

    const url = this.router.url;

    if (url.indexOf('promoter') != -1) {
      this.router.navigate([
        '/promoter',
        {
          outlets: { page: ['merchant-profile', ID] },
        },
      ]);
    } else {
      this.router.navigate([
        '/merchant',
        {
          outlets: { page: ['merchant-profile', ID] },
        },
      ]);
    }
  }

  PatientPortalAssitance(ID) {
    this.router.navigate([
      '/merchant',
      {
        outlets: { page: ['patient-portal-assistance', ID] },
      },
    ]);
  }

  viewMerchant(id) {
    const ref = this.dialog.open(MerchantViewComponent, {
      data: id,
      width: '600px',
    });
    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  merchantAccess(ID) {
    const ref = this.dialog.open(MerchantAccessCreateComponent, {
      data: ID,
      width: '800px',
    });

    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  viewMerchantLogs(ID) {
    const ref = this.dialog.open(ActionLogListComponent, {
      data: {
        merchantID: ID,
        target: 'merchant',
        targetID: ID,
      },
      width: '900px',
    });
    ref.componentInstance.closeModal.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  uploadDoc(id) {
    const ref = RootAppComponent.dialog.open(DocumentCreateComponent, {
      data: {
        cardID: id,
      },
      width: '650px',
    });

    ref.componentInstance.create.subscribe((res) => {
      if (res) {
        ref.close();
      }
    });
    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  matrialRequest(id) {
    if (id) {
      const ref = this.dialog.open(MerchantSettlementComponent, {
        data: {
          merchantID: id,
        },
        width: '500px',
      });
      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
      ref.componentInstance.getResult.subscribe((res) => {
        if (res && res.ID) {
          this.dataSource.replaceItem = res;

          ref.close();
        }
      });
    }
  }

  matrialRequestGroup() {
    if (this.selectedIDs && this.selectedIDs.length > 0) {
      const ref = this.dialog.open(MerchantSettlementComponent, {
        data: {},
        width: '500px',
      });
      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });

      ref.componentInstance.getResult.subscribe((res) => {
        if (res) {
          const payload = {
            ids: this.selectedIDs.join(','),
            ...res,
          };

          this.MerchantService.modifyMerchantGroup(payload).subscribe((res) => {
            if (res && res.length > 0) {
              for (let i = 0; i < res.length; i++) {
                this.dataSource.replaceItem = res[i];
              }

              this.selectedIDs = [];

              NotifyAppComponent.displayToast('success', 'Success!', '');

              ref.close();
            }
          });
        }
      });
    }
  }

  enableGroup() {
    if (this.selectedIDs && this.selectedIDs.length > 0) {
      const confirmDialog = new ConfirmDialog(
        'cancel',
        'Enable Medical History',
        '<p>Are you sure you want to enable Medical History for the selected practices ?</p>',
        'Cancel',
        'Okay'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirmDialog,
        // SET TO PAYING CASH CARD
      });
      ref.componentInstance.onConfirm.subscribe((val) => {
        if (val == true) {
          ref.close();
          ref.afterClosed().subscribe((res) => {
            const payload = {
              healthHistoryActive: true,
              ids: this.selectedIDs.join(','),
            };
            this.MerchantService.modifyMerchantGroup(payload).subscribe((res) => {
              if (res && res.length > 0) {
                for (let i = 0; i < res.length; i++) {
                  this.dataSource.replaceItem = res[i];
                }

                this.selectedIDs = [];

                NotifyAppComponent.displayToast('success', 'Success!', 'Applications are enabled.');
              }
            });
          });
        } else {
          ref.close();
        }
      });
    }
  }

  disableGroup() {
    if (this.selectedIDs && this.selectedIDs.length > 0) {
      const confirmDialog = new ConfirmDialog(
        'cancel',
        'Disable Medical History',
        '<p>Are you sure you want to disable Medical History for the selected practices ?</p>',
        'Cancel',
        'Okay'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirmDialog,
        // SET TO PAYING CASH CARD
      });
      ref.componentInstance.onConfirm.subscribe((val) => {
        if (val == true) {
          ref.close();
          ref.afterClosed().subscribe((res) => {
            const payload = {
              healthHistoryActive: false,
              ids: this.selectedIDs.join(','),
            };
            this.MerchantService.modifyMerchantGroup(payload).subscribe((res) => {
              if (res && res.length > 0) {
                for (let i = 0; i < res.length; i++) {
                  this.dataSource.replaceItem = res[i];
                }

                this.selectedIDs = [];

                NotifyAppComponent.displayToast('success', 'Success!', 'Applications are disabled.');
              }
            });
          });
        } else {
          ref.close();
        }
      });
    }
  }

  toNumber(n) {
    if (n) {
      return Number(n);
    } else {
      return 0;
    }
  }

  hideViewDetails() {}

  ngOnDestroy() {
    this.destroyEvent.emit(true);
  }
}

export class LoadRecords implements OnInit {
  public dataChange: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  items: any;
  count: any;

  get data(): any[] {
    return this.dataChange.value;
  }

  firstLoad = false;

  get firstLoadEvent() {
    return this.firstLoad;
  }

  serviceRef;
  isDestoyed = false;

  constructor(
    private MerchantService: MerchantService,
    private destroyEvent,
    private isPromoterOrAdmin,
    private status,
    private customPhonePipe: CustomPhonePipe
  ) {
    this.destroyEvent.subscribe((res) => {
      this.isDestoyed = res;
    });

    const payload = {
      section: 0,
      status: this.status || null,
      orderBy: 'TradingAs',
      fields:
        'ID,TradingAs,CalculatedName,mobiles.Number,HealthHistoryEnabled.Overridden,emails.Email,DateTimeCreated,phones.Number,Status,Disable_Matrial_Request,Percent_Stlmnt_Mtrls_Request,' +
        'addresses.Unit,addresses.Calculated,addresses.Street Nr,addresses.Street Name,mobiles.Number,addresses.Street Type,addresses.State,addresses.Postcode',
    };

    this.serviceRef = this.MerchantService.getList(payload, this.isPromoterOrAdmin).subscribe((res) => {
      this.count = res.length;
      this.items = res;

      for (let i = 0; i < this.items.length; i++) {
        this.buildRecord(i);
      }

      this.dataChange.next(this.items.slice());
      this.firstLoad = true;
      const innerFunction = (section) => {
        const length = this.items.length;
        section = section + 1;
        UtilsClass.loadingDataSection(section);
        payload.section = section;

        this.serviceRef = this.MerchantService.getList(payload, this.isPromoterOrAdmin).subscribe((res) => {
          if (res.length > 0) {
            this.count = res.count;
            this.items = this.items.concat(res);

            for (let i = length; i < this.items.length; i++) {
              this.buildRecord(i);
            }

            this.dataChange.next(this.items.slice());
            if (this.isDestoyed != true) {
              innerFunction(section);
            }
          } else {
            return true;
          }
        });
        UtilsClass.stopLoadingInterceptor();
      };

      if (this.isDestoyed != true) {
        innerFunction(1);
      }
    });
  }

  buildRecord(i) {
    if (this.items[i]) {
      this.items[i]['fullName'] = '';

      if (this.items[i]['ID']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['ID'];
      }
      if (this.items[i]['CalculatedName']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['CalculatedName'];
      }

      if (this.items[i]['TradingAs']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['TradingAs'];
      }

      if (this.items[i]['emails.Email']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['emails.Email'];
      }

      if (this.items[i]['Status']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['Status'];
      }

      if (this.items[i]['addresses.Calculated']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['addresses.Calculated'];
      }

      if (this.items[i]['mobiles.Number']) {
        this.items[i]['fullName'] =
          this.items[i]['fullName'] +
          ' ' +
          this.customPhonePipe.transform(this.items[i]['mobiles.Number'], 'none').replace(/ /g, '');
      }
      if (this.items[i]['mobiles.Number']) {
        this.items[i]['fullName'] =
          this.items[i]['fullName'] + ' ' + this.customPhonePipe.transform(this.items[i]['mobiles.Number'], 'none');
      }

      if (this.items[i]['phones.Number']) {
        this.items[i]['fullName'] =
          this.items[i]['fullName'] +
          ' ' +
          this.customPhonePipe.transform(this.items[i]['phones.Number'], 'landLine').replace(/ /g, '');
      }
      if (this.items[i]['phones.Number']) {
        this.items[i]['fullName'] =
          this.items[i]['fullName'] + ' ' + this.customPhonePipe.transform(this.items[i]['phones.Number'], 'landLine');
      }
    }

    this.items[i]['profileLink'] = null;

    if (this.items[i]) {
      this.items[i]['profileLink'] = this.MerchantService.getPracticePicStreamLink(this.items[i]['ID']);
    }
  }

  ngOnInit() {}

  ngOnDestroy() {
    if (this.serviceRef) {
      this.serviceRef.unsubscribe();
    }
  }
}

export class RecordDataSource extends DataSource<any> {
  _filterChange = new BehaviorSubject('');
  field = '';

  get filter(): any {
    return this._filterChange.value;
  }

  set filter(item: any) {
    this.field = item.field;
    this._filterChange.next(item.value);
  }

  filteredData: any[] = [];

  constructor(private _tableDatabase: LoadRecords, private _paginator: MatPaginator, private _sort: MatSort) {
    super();
    this._filterChange.subscribe(() => (this._paginator.pageIndex = 0));
  }

  set addItem(res) {
    this._tableDatabase.data.unshift(res);

    this._tableDatabase.dataChange.next(this._tableDatabase.data);
  }

  set replaceItem(item) {
    const _index = this._tableDatabase.data.findIndex((_obj) => _obj.ID == item.ID);
    this._tableDatabase.data[_index] = { ...this._tableDatabase.data[_index], ...item };
    this._tableDatabase.dataChange.next(this._tableDatabase.data);
  }

  set removeItem(id) {
    const data = this._tableDatabase.data.filter((row) => row.ID != id);
    this._tableDatabase.dataChange.next(data);
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<any[]> {
    const displayDataChanges = [
      this._tableDatabase.dataChange,
      this._filterChange,
      this._paginator.page,
      this._sort.sortChange,
    ];

    return observableMerge(...displayDataChanges).pipe(
      map(() => {
        // Filter data
        this.filteredData = this._tableDatabase.data.slice().filter((item: any) => {
          let f = '';

          f = item[this.field] || '';

          const searchStr = f.toLowerCase();
          return searchStr.indexOf(this.filter.toLowerCase()) != -1;
        });
        const data = this.getSortedData(this.filteredData.slice());
        // Grab the page's slice of data.
        const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
        return data.splice(startIndex, this._paginator.pageSize);
      })
    );
  }

  disconnect() {}

  /** Returns a sorted copy of the database data. */
  getSortedData(data: any[]): any[] {
    // const data = data;
    if (!this._sort.active || this._sort.direction == '') {
      return data;
    }

    return data.sort((a, b) => {
      let propertyA: number | string = '';
      let propertyB: number | string = '';

      switch (this._sort.active) {
        case 'CreatedDate':
          [propertyA, propertyB] = [
            moment(a['DateTimeCreated']).toDate().getTime(),
            moment(b['DateTimeCreated']).toDate().getTime(),
          ];
          break;
        case 'TradingAS':
          [propertyA, propertyB] = [a['TradingAs'], b['TradingAs']];
          break;

        case 'MerchantName':
          [propertyA, propertyB] = [a['CalculatedName'], b['CalculatedName']];
          break;
        case 'MerchantMobile':
          [propertyA, propertyB] = [a['mobiles.Number'], b['mobiles.Number']];
          break;
        case 'MerchantEmail':
          [propertyA, propertyB] = [a['emails.Email'], b['emails.Email']];
          break;
        case 'MerchantPhone':
          [propertyA, propertyB] = [a['phones.Number'], b['phones.Number']];
          break;

        case 'Address':
          [propertyA, propertyB] = [a['addresses.Street Name'], b['addresses.Street Name']];
          break;

        case 'State':
          [propertyA, propertyB] = [a['addresses.State'], b['addresses.State']];
          break;

        case 'Postcode':
          [propertyA, propertyB] = [a['addresses.Postcode'], b['addresses.Postcode']];
          break;
      }

      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

      return (valueA < valueB ? -1 : 1) * (this._sort.direction == 'asc' ? 1 : -1);
    });
  }
}
