/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./appointment-iframe-view.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "@angular/common";
import * as i4 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i5 from "@angular/material/button";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/platform-browser/animations";
import * as i8 from "./appointment-iframe-view.component";
import * as i9 from "@angular/platform-browser";
var styles_AppointmentIframeViewComponent = [i0.styles];
var RenderType_AppointmentIframeViewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppointmentIframeViewComponent, data: { "animation": [{ type: 7, name: "ngIfAnimation", definitions: [{ type: 1, expr: "void => *", animation: [{ type: 11, selector: ".row", animation: { type: 6, styles: { opacity: 0 }, offset: null }, options: { optional: true } }, { type: 11, selector: ".row", animation: { type: 12, timings: "100ms", animation: [{ type: 4, styles: { type: 5, steps: [{ type: 6, styles: { opacity: 0, transform: "translateY(-75%)", offset: 0, height: 0 }, offset: null }, { type: 6, styles: { opacity: 1, transform: "translateY(0)", offset: 1, height: "*" }, offset: null }] }, timings: "0.8s ease-out" }] }, options: { optional: true } }], options: null }, { type: 1, expr: "* => void", animation: [{ type: 11, selector: ".row", animation: { type: 6, styles: { opacity: 1 }, offset: null }, options: { optional: true } }, { type: 11, selector: ".row", animation: { type: 12, timings: "100ms", animation: [{ type: 4, styles: { type: 5, steps: [{ type: 6, styles: { opacity: 1, transform: "translateY(0)", offset: 0, height: "*" }, offset: null }, { type: 6, styles: { opacity: 0, transform: "translateY(-75%)", offset: 1, height: 0 }, offset: null }] }, timings: "0.8s ease-in" }] }, options: { optional: true } }], options: null }], options: {} }] } });
export { RenderType_AppointmentIframeViewComponent as RenderType_AppointmentIframeViewComponent };
function View_AppointmentIframeViewComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "rel"]], [[24, "@ngIfAnimation", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "row clearfix padded rel"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h1", [["class", "dataHeader rm-mt transparent"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Loading appointment Iframe..."]))], null, function (_ck, _v) { var currVal_0 = undefined; _ck(_v, 0, 0, currVal_0); }); }
function View_AppointmentIframeViewComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "iframe", [], [[8, "src", 5]], [[null, "load"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("load" === en)) {
        var pd_0 = (_co.onFrameLoad($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.iFrameSrcSecured; _ck(_v, 0, 0, currVal_0); }); }
export function View_AppointmentIframeViewComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppointmentIframeViewComponent_1)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppointmentIframeViewComponent_2)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 5, "mat-dialog-actions", [["align", "center"], ["class", "mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(7, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(8, 0, null, null, 3, "button", [["color", "accent"], ["mat-dialog-close", ""], ["mat-raised-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0], [1, "type", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).dialogRef.close(i1.ɵnov(_v, 10).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatButton_0, i4.RenderType_MatButton)), i1.ɵdid(9, 180224, null, 0, i5.MatButton, [i1.ElementRef, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), i1.ɵdid(10, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(-1, 0, ["Close"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.loadIFrame != true); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.iFrameSrcSecured; _ck(_v, 5, 0, currVal_1); var currVal_6 = "accent"; _ck(_v, 9, 0, currVal_6); var currVal_7 = ""; _ck(_v, 10, 0, currVal_7); }, function (_ck, _v) { var currVal_2 = (i1.ɵnov(_v, 9).disabled || null); var currVal_3 = (i1.ɵnov(_v, 9)._animationMode === "NoopAnimations"); var currVal_4 = (i1.ɵnov(_v, 10).ariaLabel || null); var currVal_5 = i1.ɵnov(_v, 10).type; _ck(_v, 8, 0, currVal_2, currVal_3, currVal_4, currVal_5); }); }
export function View_AppointmentIframeViewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-appointment-iframe-view", [], null, null, null, View_AppointmentIframeViewComponent_0, RenderType_AppointmentIframeViewComponent)), i1.ɵdid(1, 114688, null, 0, i8.AppointmentIframeViewComponent, [i9.DomSanitizer, [2, i2.MAT_DIALOG_DATA]], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppointmentIframeViewComponentNgFactory = i1.ɵccf("app-appointment-iframe-view", i8.AppointmentIframeViewComponent, View_AppointmentIframeViewComponent_Host_0, {}, {}, []);
export { AppointmentIframeViewComponentNgFactory as AppointmentIframeViewComponentNgFactory };
