import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ipv-clear-text-button',
  templateUrl: './clear-text-button.component.html',
  styleUrls: ['./clear-text-button.component.css'],
})
export class ClearTextButtonComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
