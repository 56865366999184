import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { MessageService } from '../shared/message.service';

@Component({
  selector: 'app-message-view',
  templateUrl: './message-view.component.html',
  styleUrls: ['./message-view.component.css'],
})
export class MessageViewComponent implements OnInit {
  @Input()
  messageID;

  @Input()
  messageType;

  @Input()
  resendButton = false;

  message;

  @Output()
  close = new EventEmitter();
  isModal = false;
  sessionType;

  constructor(
    private authenticationService: AuthenticationService,
    private messageService: MessageService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.messageID) {
        this.messageID = data.messageID;
      }

      if (data.messageType) {
        this.messageType = data.messageType;
      }

      if (data.resendButton) {
        this.resendButton = data.resendButton;
      }

      this.isModal = true;
    }
  }

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((sessionType) => {
      this.sessionType = sessionType;

      if (this.messageID) {
        const payload = {
          fields: 'Date.sentOrReceived,Time.sentOrReceived',
        };

        if (this.messageType == 'E') {
          payload.fields = payload.fields + ',BodyBase64';
        } else if (this.messageType == 'S') {
          payload.fields = payload.fields + ',Body';
        }

        this.messageService.getMessageDetails(this.messageID, payload, this.sessionType).subscribe((res) => {
          if (res) {
            this.message = res;
          }
        });
      } else {
        if (this.messageType == 'S') {
          this.message = {
            Body: '',
          };
        } else {
          this.message = {
            BodyBase64: '',
          };
        }
      }
    });
  }

  resendMessageTrigger() {
    // resend message
  }

  closeEvent() {
    this.close.emit(true);
  }
}
