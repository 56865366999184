import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { WishlistManagerModalComponent } from './shared/modals/wishlist-manager-modal/wishlist-manager-modal.component';
import { WishlistService } from './shared/services/wishlist.service';
import { WishlistRoutingModule } from './wishlist-routing.module';
import { WishlistPage } from './wishlist.page';

@NgModule({
  imports: [CommonModule, SharedModule, WishlistRoutingModule],
  exports: [WishlistPage, WishlistManagerModalComponent],
  declarations: [WishlistPage, WishlistManagerModalComponent],
  entryComponents: [WishlistManagerModalComponent],
  providers: [WishlistService],
})
export class WishlistModule {}
