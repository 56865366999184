import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { AuthenticationService } from '../../authentication/shared/authentication.service';
import { SettingsService } from '../../settings/shared/settings.service';

@Component({
  selector: 'app-maintenance-page',
  templateUrl: './maintenance-page.component.html',
  styleUrls: ['./maintenance-page.component.css'],
})
export class MaintenancePageComponent implements OnInit {
  countdown: number;
  offlineMessage;
  stream;
  subscribe;

  redirection = true;

  constructor(private router: Router, private settingsService: SettingsService) {
    this.settingsService.getPublicSettings().subscribe((settings) => {
      if (settings && settings[0]) {
        Settings.global = settings[0];
        Settings.applySettings();

        if (Settings && Settings.global['offlineMessage']) {
          this.offlineMessage = Settings.global['offlineMessage'];
        }
        if (Settings && Settings.global['siteOnline'] == true) {
          this.router.navigate(['/login']);
        }

        this.startCountdownTimer();
      } else {
        NotifyAppComponent.displayToast('error', 'Sorry!', 'The site still offline.');

        this.startCountdownTimer();
      }
    });
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.redirection = false;
    this.subscribe.unsubscribe();
  }

  goHome() {
    this.redirection = false;
    this.subscribe.unsubscribe();
    this.router.navigate(['/login']);
  }

  startCountdownTimer() {
    this.countdown = 0;
    const interval = 1000;
    const duration = 60 * 1000;

    const stream$ = Observable.timer(0, interval)
      .finally(() => {
        if (this.redirection == true) {
          this.goHome();
        }
      })
      .takeUntil(Observable.timer(duration + interval))
      .map((value) => duration - value * interval);
    this.subscribe = stream$.subscribe((value) => (this.countdown = value));
  }

  contactUs() {
    if (this.subscribe) {
      this.redirection = false;
      this.subscribe.unsubscribe();
      this.countdown = null;
    }
    AuthenticationService.contactUSEvent.emit();
  }
}
