<div class="rel" *ngIf="sessionDB.firstLoadEvent == false">
  <div class="row clearfix padded rel">
    <h1 class="dataHeader rm-mt transparent">Fetching data...</h1>
  </div>
</div>

<div [hidden]="sessionDB.data.length == 0">
  <div>
    <div class="row clearfix flex">
      <h1 class="dataHeader rm-m full-width mr ml">
        <!-- Contracts -->
        Operators
        <span class="badge" [endVal]="dataSource.filteredData.length" countUp></span>

        <button
          class="pull-right"
          [routerLink]="['/merchant/', { outlets: { page: ['dentist-invite-create'] } }]"
          [routerLinkActive]="['active']"
          mat-raised-button
          color="accent"
        >
          <mat-icon>person_add</mat-icon> Add a Staff Member
        </button>
      </h1>
    </div>

    <mat-card class="list-card">
      <div class="row clearfix searchArea primary-gradient-img">
        <div class="row justify flex">
          <div class="full-width row clearfix flex">
            <mat-icon class="schIcon mt">search</mat-icon>

            <mat-form-field class="full-width input-adjust" appearance="outline">
              <mat-label>Search </mat-label>
              <input
                class="clearfix"
                (input)="setFilter($event.target.value, 'search')"
                name="Search"
                placeholder=""
                matInput
              />
            </mat-form-field>



            <mat-form-field class="half-width mat-select-adjust" appearance="outline">
              <mat-label>Type</mat-label>
              <mat-select
                class="full-width"
                (selectionChange)="setFilter($event.value, 'type')"
                name="merchantProspectName"
                placeholder=""
              >
                <mat-option [value]="null">
                  All
                </mat-option>
                <mat-option
                  *ngFor="
                    let a of sessionDB.data | objectPropertyValue: 'type' | unique | without: ['', null] | orderBy
                  "
                  [value]="a"
                >
                  {{ a }}
                </mat-option>
              </mat-select>
            </mat-form-field>


          </div>
        </div>
      </div>

      <mat-table class="list-table" #table [dataSource]="dataSource" matSort>
        <!-- ID Column -->

        <ng-container cdkColumnDef="ProfileImage" sticky>
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> </mat-header-cell>
          <mat-cell class="text-center" *cdkCellDef="let row" (click)="openQuickViewDialog(row)">
            <app-ep-document-view-image
              class="headNavView text-center userIconView"
              *ngIf="row"
              [defaultPicture]="
                'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/profile_placeholder.png)'
              "
              [link]="row['profileLink']"
              [hideZoomButton]="true"
            ></app-ep-document-view-image>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="CalculatedName">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header>Name</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="openQuickViewDialog(row)">
            {{ row.CalculatedName }}
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="UserName">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Username</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="openQuickViewDialog(row)">
            {{ row.UserName }}
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="TableName">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Table Name</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="openQuickViewDialog(row)">
            {{ row.TableName }}
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="UltimateTableName">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Type</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="openQuickViewDialog(row)">
            {{ row.type }}
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Role">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Role</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="openQuickViewDialog(row)">
            {{ row.Role }}
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Card_key">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header>Card Key</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="openQuickViewDialog(row)">
            {{ row.Card_key }}
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="IsAdministrator">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header>
            Administrator
          </mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="openQuickViewDialog(row)">
            <span
              class="list-label smaller"
              *ngIf="row['IsAdministrator'] == '1'"
              matTooltip="Administrator"
              style="background-color: rgb(90, 186, 71)"
            >
              <mat-icon>check</mat-icon>
            </span>
            <span
              class="list-label smaller"
              *ngIf="row['IsAdministrator'] != '1'"
              matTooltip="Not Administrator"
              style="background-color: rgb(239, 71, 39)"
            >
              <mat-icon>close</mat-icon>
            </span>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Enabled">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Is Active</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="openCatelogueVIew(row['ID'])">
            <span
              class="list-label smaller"
              *ngIf="row['Enabled'] == '1'"
              matTooltip="Active"
              style="background-color: rgb(90, 186, 71)"
            >
              <mat-icon>check</mat-icon>
            </span>
            <span
              class="list-label smaller"
              *ngIf="row['Enabled'] != '1'"
              matTooltip="Inactive"
              style="background-color: rgb(239, 71, 39)"
            >
              <mat-icon>close</mat-icon>
            </span>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Actions">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header>
            Actions
          </mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <div style="float: right">
              <button [matMenuTriggerFor]="menu" mat-icon-button>
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button *ngIf="row['Card_key']" (click)="openQuickViewDialog(row)" mat-menu-item>
                  <mat-icon>description</mat-icon>
                  <span>Quick View</span>
                </button>
                <button *ngIf="row['Card_key']" (click)="displayContact(row)" mat-menu-item>
                  <mat-icon>contact_mail</mat-icon>
                  <span>Contact {{ row['CalculatedName'] }}</span>
                </button>
                <button (click)="displayInfo(row)" mat-menu-item>
                  <mat-icon>info</mat-icon>
                  <span>View/Modify</span>
                </button>

                <button (click)="changePassword(row.ID)" mat-menu-item>
                  <mat-icon>vpn_key </mat-icon>
                  <span>Change Password</span>
                </button>

                <button (click)="passwordUpdate(row.ID)" mat-menu-item>
                  <mat-icon>lock</mat-icon>
                  <span>Prompt password update</span>
                </button>


                <hr>

                <button *ngIf="row['Enabled'] == '1'" (click)="disableLogin(row.ID)" mat-menu-item>
                  <mat-icon>block</mat-icon>
                  <span>Disable Login</span>
                </button>

                <button *ngIf="row['Enabled'] == '0'" (click)="enableLogin(row.ID)" mat-menu-item>
                  <mat-icon>verified_user</mat-icon>
                  <span>Enable Login</span>
                </button>
                <hr>
                <button
                  *ngIf="row['UltimateTableName'] == 'Merchant' && row['IsAdministrator'] == '1'"
                  (click)="unsetMerchantAdmin(row.ID)"
                  mat-menu-item
                >
                  <mat-icon class="fas fa-user-cog"></mat-icon>
                  <span> Set Role to  {{ 'KEYWORD.practice' | translate }} </span>
                </button>

                <button
                  *ngIf="row['UltimateTableName'] == 'Merchant' && row['IsAdministrator'] == '0'"
                  (click)="setMerchantAdmin(row.ID)"
                  mat-menu-item
                >
                  <mat-icon class="fas fa-user-shield"></mat-icon>
                  <span>Set Role to Merchant-Admin</span>
                </button>
              </mat-menu>
            </div>
          </mat-cell>
        </ng-container>

        <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *cdkRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
      <mat-paginator
        #paginator
        [length]="dataSource.filteredData.length"
        [pageIndex]="0"
        [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions"
      >
      </mat-paginator>
    </mat-card>
  </div>
</div>
