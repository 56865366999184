import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { Observable } from 'rxjs';
import { AuthenticationService } from '../../authentication/shared/authentication.service';

@Component({
  selector: 'app-session-view-practice-picture',
  templateUrl: './session-view-practice-picture.component.html',
  styleUrls: ['./session-view-practice-picture.component.css'],
})
export class SessionViewPracticePictureComponent implements OnInit {
  @Output() close = new EventEmitter();

  practiceLink;
  isNewPracticePicture = false;

  constructor(private authenticationService: AuthenticationService) {}

  ngOnInit() {
    this.getPracticePicture();

    AuthenticationService.updatePracticePicture.subscribe((res) => {
      if (res) {
        this.isNewPracticePicture = !this.isNewPracticePicture;
        this.getPracticePictureTimer();
      }
    });
  }

  getPracticePicture() {
    this.practiceLink = null;
    this.practiceLink = this.authenticationService.getPracticePicStreamLink();
  }

  getPracticePictureTimer() {
    this.practiceLink = null;
    let countdown = 0;
    const interval = 1000;
    const duration = 3 * 1000;
    const stream$ = Observable.timer(0, interval)
      .finally(() => {
        this.practiceLink = this.authenticationService.getPracticePicStreamLink();
      })
      .takeUntil(Observable.timer(duration + interval))
      .map((value) => duration - value * interval);
    stream$.subscribe((value) => (countdown = value));
  }

  closeEvent() {
    this.close.emit(true);
  }
}
