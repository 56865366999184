import { Component, EventEmitter, Inject, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { LogService } from '../shared/log.service';

@Component({
  selector: 'app-log-delete',
  templateUrl: './log-delete.component.html',
  styleUrls: ['./log-delete.component.css'],
})
export class LogDeleteComponent implements OnInit {
  dateToDefault;
  dateTo;
  dateFrom;

  maxDate;

  util = new UtilsClass();

  @Output()
  close = new EventEmitter();

  constructor(
    private logService: LogService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {}

  ngOnInit() {
    this.maxDate = this.util.getPastTime(new Date(), -7, 'day');
    this.dateToDefault = this.util.getPastTime(new Date(), -15, 'day');
  }

  selectDateFrom(d) {
    this.dateFrom = d;
  }

  selectDateTo(d) {
    this.dateTo = d;
  }

  closeEvent() {
    this.close.emit(true);
  }

  deleteLogs() {
    const pay = {};

    if (this.dateFrom) {
      pay['dateFrom'] = this.dateFrom;
    }

    if (this.dateTo) {
      pay['dateTo'] = this.dateTo;
    }

    this.logService.deleteLogs(pay).subscribe((res) => {
      if (res) {
        NotifyAppComponent.displayToast('success', 'Successful operation', 'Logs cleared');

        this.close.emit(true);
      }
    });
  }
}
