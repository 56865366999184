<div class="rel" *ngIf="listDB.firstLoadEvent == false" @ngIfAnimation>
  <div class="row clearfix padded rel">
    <h1 class="dataHeader rm-mt transparent">Fetching data...</h1>
  </div>
</div>

<div [hidden]="listDB.data.length == 0">
  <div>
    <div class="row clearfix">
      <h1 class="dataHeader pull-left rm-mt">
        {{ title }}
        <span class="badge" [endVal]="dataSource.filteredData.length" countUp></span>
      </h1>

      <div class="pull-right toggle-card">
        <mat-button-toggle-group
          class="btn-group mb text-center full-width with-icons"
          #group="matButtonToggleGroup"
          [(ngModel)]="typeFilter"
          (ngModelChange)="setFilter(typeFilter, 'type')"
        >
          <mat-button-toggle class="text-center full-width" [value]="null">
            <mat-icon class="fas fa-infinity"></mat-icon>
            All
          </mat-button-toggle>

          <mat-button-toggle class="text-center full-width" [value]="'document'">
            <mat-icon class="fas fa-file-alt"></mat-icon>
            Documents
          </mat-button-toggle>
          <mat-button-toggle class="text-center full-width" [value]="'video'">
            <mat-icon class="fas fa-video"></mat-icon>
            Videos
          </mat-button-toggle>
          <mat-button-toggle class="text-center full-width" [value]="'image'">
            <mat-icon class="fas fa-image"></mat-icon>
            Stockart
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>

      <div class="pull-right selection-items adjusted" *ngIf="selectedIDs && selectedIDs.length > 0" @ngIfAnimation>
        <button class="row select-btn" (click)="unselect()" mat-raised-button color="">
          <mat-icon>select_all</mat-icon>
          De select
        </button>

        <button
          class="row select-btn"
          *ngIf="isPromoterOrAdmin == true"
          (click)="removeDocumentGroup()"
          mat-raised-button
          color=""
        >
          <mat-icon>delete</mat-icon>
          Remove
        </button>
      </div>
    </div>
    <mat-card class="list-card">
      <div class="row clearfix searchArea primary-gradient-img">
        <div class="row clearfix flex">
          <div class="thr-qtr-width flex" id="search">
            <div class="row flex animated fadeInDown full-width">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Label</mat-label>
                <input
                  class="clearfix"
                  (input)="setFilter($event.target.value, 'Document.Label')"
                  name="Labels"
                  placeholder=""
                  matInput
                />
              </mat-form-field>
            </div>
          </div>
          <div class="qtr-width">
            <!-- add new document -->
            <button
              class="pull-right"
              *ngIf="isPromoterOrAdmin == true && false"
              (click)="addDocument()"
              mat-raised-button
              color="accent"
            >
              Add document
            </button>
          </div>
        </div>
      </div>

      <mat-table class="list-table vertical-cent" #table [dataSource]="dataSource" matSort>
        <!-- thumbnail of media -->

        <ng-container cdkColumnDef="selectCheckBox" sticky>
          <mat-header-cell *cdkHeaderCellDef></mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <div class="margin-btm pull-left">
              <mat-checkbox
                [checked]="isSelected(row['ID'])"
                (change)="select(row['ID'], $event.checked)"
                color="accent"
              >
              </mat-checkbox>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="mediaThumbnail">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header>Thumbnail</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="viewDocument(row['ID'], row['Label'], row['Description'])">
            <!-- ep-document-image-view or similar component -->
            <div class="document-thumb">
              <app-ep-document-thumbnail-view
                class="text-center documentThumbView"
                [documentID]="row['ID']"
                [hideZoomButton]="true"
              ></app-ep-document-thumbnail-view>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="documentLabel">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header>Label</mat-header-cell>
          <mat-cell
            *cdkCellDef="let row"
            (click)="viewDocument(row['ID'], row['Label'], row['Description'])"
            matTooltip="{{ row['Label'] }}"
          >
            <strong>{{ row['Label'] }} </strong>
            <span class="value chip new" *ngIf="isNew(row['DateTimeCreated']) == true">NEW</span>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="mediaMedium">
          <mat-header-cell class="col-thin" *cdkHeaderCellDef mat-sort-header
          >
            Media Type
          </mat-header-cell>
          <mat-cell
            class="col-thin"
            *cdkCellDef="let row"
            (click)="viewDocument(row['ID'], row['Label'], row['Description'])"
          >
            {{ getDocumentLabel(row['type']) }}
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Date">
          <mat-header-cell class="col-thin" *cdkHeaderCellDef mat-sort-header> Created Date</mat-header-cell>
          <mat-cell
            class="col-thin"
            *cdkCellDef="let row"
            (click)="viewDocument(row['ID'], row['Label'], row['Description'])"
            matTooltip="{{ row['DateTimeCreated'] | customDateTime }}"
          >
            {{ row['DateTimeCreated'] | customDate }}
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Actions">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header>Actions</mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <div style="float: right">
              <button [matMenuTriggerFor]="menu" mat-icon-button>
                <mat-icon>more_vert</mat-icon>
              </button>

              <mat-menu #menu="matMenu">
                <button
                  *ngIf="row['ID']"
                  (click)="viewDocument(row['ID'], row['Label'], row['Description'])"
                  mat-menu-item
                >
                  <mat-icon>find_in_page</mat-icon>
                  <span>View Document</span>
                </button>

                <!-- <button *ngIf="row['ID'] && isPromoterOrAdmin==true" mat-menu-item (click)="modifyDocument(row['ID'])">
                  <mat-icon>edit</mat-icon>
                  <span>{{"Edit Document " | translate}}</span>

                </button> -->

                <button
                  *ngIf="row['ID'] && isPromoterOrAdmin == true"
                  (click)="removeDocument(row['ID'])"
                  color="accent"
                  mat-menu-item
                >
                  <mat-icon>delete</mat-icon>
                  <span>
                    Delete Document
                  </span>
                </button>
              </mat-menu>
            </div>
          </mat-cell>
        </ng-container>
        <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *cdkRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
      <mat-paginator
        #paginator
        [length]="dataSource.filteredData.length"
        [pageIndex]="0"
        [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions"
      >
      </mat-paginator>
    </mat-card>
  </div>
</div>

<div *ngIf="listDB.data.length == 0 && listDB.firstLoadEvent != false">
  <div class="row clearfix">
    <!-- add new document -->
    <button
      class="pull-right"
      *ngIf="isPromoterOrAdmin == true && false"
      (click)="addDocument()"
      mat-raised-button
      color="accent"
    >
      Add document
    </button>
  </div>
  <div class="row clearfix">
    <app-empty-list-message [title]="'No documents found'" [message]="'There are no documents in the database'">
    </app-empty-list-message>
  </div>
</div>
