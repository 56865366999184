<div class="card-header primary-gradient-img clearfix inModal">
  <div class="row clearfix">
    <!-- merchant calculated name -->
    <div class="row text-left clearfix">
      <!-- Modal dismiss button -->
      <button class="btn-close btn-clear mat-button white" (click)="close()">
        <mat-icon>clear</mat-icon>
      </button>
      <h2 class="summary-header rm-m">
        {{ title }} decision
        <span class="subLabel">Manually set a decision on this check</span>
      </h2>
    </div>
  </div>
</div>

<mat-dialog-content>
  <div class="column">
    <div class="row clearfix mb" *ngIf="checkType != 'document'">
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>Reason for decision</mat-label>
        <textarea [(ngModel)]="reason" matInput placeholder="Please enter your reason" required></textarea>
      </mat-form-field>
    </div>
    <div class="row clearfix mb">
      <mat-button-toggle-group class="full-width" style="box-shadow:none !important;" [(ngModel)]="decision" appearance="legacy" name="decision">
        <div class="full-width flex">
          <mat-button-toggle class="decline mr full-width" value="Decline">
            <mat-icon class="fas fa-thumbs-down"></mat-icon> Decline
          </mat-button-toggle>
          <mat-button-toggle class="approve ml full-width" value="Accept">
            <mat-icon class="fas fa-thumbs-up"></mat-icon> Approve
          </mat-button-toggle>
        </div>

      </mat-button-toggle-group>
    </div>

    <div class="row clearfix mb" *ngIf="checkType == 'document' && decision == 'Decline'">
      <div class="row clearfix">
        <h4 class="sr-title">Issues with supporting document</h4>
        <div class="clearfix row" *ngFor="let a of documentIssues">
          <div class="thr-qtr-width">
            <p class="rm-mt mobSm">{{ a }}</p>
          </div>
          <div class="qtr-width text-center">
            <mat-checkbox (change)="selectReason(a, $event)"></mat-checkbox>
          </div>
        </div>
        <div class="clearfix row">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Reason for decision</mat-label>
            <textarea [(ngModel)]="reason" matInput placeholder="Please enter your reason" required></textarea>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
  <hr />
  <div class="row clearfix mt text-center">
    <button class="btn-large pull-left  ml mr" (click)="close()" mat-raised-button color="primary">Cancel</button>
    <button class=" btn-large   pull-right mr" *ngIf="checkType != 'document'" [disabled]="!reason || !decision" (click)="makeDecision()"
      mat-raised-button color="accent">
      Confirm
      <mat-icon>chevron_right</mat-icon>
    </button>

    <button class=" btn-large pull-right mr" *ngIf="checkType == 'document'" [disabled]="!decision" (click)="makeDecision()" mat-raised-button
      color="accent">
      <span *ngIf="decision == 'Decline'">Request re-upload</span>
      <span *ngIf="decision != 'Decline'">Confirm</span>
      <mat-icon>chevron_right</mat-icon>
    </button>
  </div>
</mat-dialog-content>
