import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-patient-identification-view',
  templateUrl: './patient-identification-view.component.html',
  styleUrls: ['./patient-identification-view.component.css'],
})
export class PatientIdentificationViewComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
