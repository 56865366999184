<div class="rel discount">
  <div class="card-header primary-gradient-img clearfix inModal">
    <div class="ribbon">
      <span>SAVE!</span>
    </div>
    <div class="row clearfix">
      <button class="btn-close btn-clear mat-button white" (click)="close()">
        <mat-icon>clear</mat-icon>
      </button>

      <div class="row titleArea clearfix full-width">
        <h2 class="summary-header rm-m white">
          Discount available!
          <span class="subLabel">Pay in full before the given timeframe and save!</span>
        </h2>
      </div>
    </div>
  </div>

  <img
    class=""
    src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/character/golden-ticket.png"
    mat-card-image
  />

  <mat-dialog-content>
    <p>To reward you for your ongoing loyalty we are pleased to provide you with a discount for prompt payment.</p>
    <p>
      If you book and pay <strong>{{ treatmentAmount - discountAmount | customCurrency }}</strong> on your first visit,
      you will save <strong>{{ discountAmount | customCurrency }}</strong
    >.
    </p>

    <p class="">
      <span *ngIf="customInvDate && customInvDate != '0000-00-00'">
        Act now - this offer is limited and will expire in
        <span *ngIf="cashDiscountDateCounter && cashDiscountDateCounter.d > 0">
          {{ cashDiscountDateCounter.d | number: '2.0-0' }} day(s)</span
        >

        <span *ngIf="cashDiscountDateCounter"
        >{{ cashDiscountDateCounter.h | number: '2.0-0' }} Hour(s)
          {{ cashDiscountDateCounter.m | number: '2.0-0' }} Min(s)
        </span>
      </span>
    </p>

    <hr/>
    <div class="buttons-row row clearfix">
      <button class="pull-left" (click)="close()" mat-raised-button>Close</button>

      <button class="pull-right" (click)="closeProceed()" mat-raised-button color="accent">
        Proceed with discount
        <mat-icon>chevron_right</mat-icon>
      </button>
    </div>
  </mat-dialog-content>
</div>
