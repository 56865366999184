import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';

@Component({
  selector: 'app-tasks-list-overview',
  templateUrl: './tasks-list-overview.component.html',
  styleUrls: ['./tasks-list-overview.component.css'],
})
export class TasksListOverviewComponent implements OnInit {
  isPromoterOrAdmin = false;

  merchantID = 'none';

  constructor(private authenticationService: AuthenticationService) {}

  ngOnInit() {
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;
    });
  }

  applySearch(result) {
    this.merchantID = result.merchantID;
  }

  getMerchant(e) {
    this.merchantID = e;
  }
}
