import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { AppStateService } from '../../../../core/app-state/app-state.service';
import { User } from '../../../../core/authentication/shared/shared/types/authentication.type';
import { RootAppComponent } from '../../../../shared/components/root-component/root-component.component';
import { NotifyAppComponent } from '../../../../shared/types/notify-app-component';
import { SessionType } from '../../../../shared/types/session-type.type';
import { RelationshipLink } from '../../../../shared/types/utils/utils.type';
import { CustomerProspectCreateComponent } from '../../../customer-prospect/customer-prospect-create/customer-prospect-create.component';
import { CustomerProspectService } from '../../../customer-prospect/shared/customerProspect.service';
import { InvitationLookupComponent } from '../../../invitation/invitation-lookup/invitation-lookup.component';
import { CustomerAndProspect } from '../../../invitation/shared/types/invitation.type';
import { Merchant } from '../../../merchant/shared/types/merchant.type';
import { NotesService } from '../../shared/services/notes.service';
import { Note, NoteFilterType, NoteQuery } from '../../shared/type/notes.type';
import { NoteListToggles } from './shared/components/note-list/note-list.component';

export class NotesModalConfig {
  patientID?: string;
  noteListMode?: NoteModalMode;
  relationshipLink?: RelationshipLink;
  /**
   * Used for relationships if url should have parent route e.g. if parent route is "merchant" then result will be https://url/merchant
   * */
  parentRoute: string | null;
  prefillNoteAction?: string;
}

export type NoteModalMode = 'view' | 'create' | 'update' | 'list';

@Component({
  selector: 'app-notes-modal',
  templateUrl: './notes.modal.html',
  styleUrls: ['./notes.modal.css'],
})
export class NotesModal implements OnInit, OnDestroy {
  merchantKey: string;

  isPromoterOrAdmin = false;

  sessionType: SessionType = 'guest';

  viewModel$ = combineLatest([
    this.appStateService.getAppState$(),
    this.notesService.getNotes$<Note>(),
    this.notesService.getSelectedNotes$<Note>(),
    this.notesService.getSelectedNotesDates$<Note>(),
    this.notesService.getEnabledNoteTypes$(),
  ]).pipe(
    map(([appState, notes, selectedNotes, selectedNoteDates, enabledNoteTypes]) => {
      const { user, practice, sessionType, isPromoterOrAdmin, dentist } = appState;
      this.user = user.data;

      this.isPromoterOrAdmin = isPromoterOrAdmin;

      this.practice = practice;

      const result = {
        user,
        practice,
        sessionType,
        isPromoterOrAdmin,
        dentist,
        notes,
        selectedNotes,
        selectedNoteDates,
        enabledNoteTypes,
      };

      return result;
    })
  );

  noteListMode: NoteModalMode = 'list';
  selectedPatient: Partial<CustomerAndProspect>;

  notesSectionFilter = 1;
  patientNameFilter = '';
  areMoreNotesAvailable = true;
  notesFilterBy: NoteFilterType = 'latest';
  areNotesPrivate = false;
  showArchivedNotes = false;

  selectedNotesSectionFilter = 1;
  areMoreSelectedNotesAvailable = true;
  selectedNotesFilterBy: NoteFilterType = 'latest';

  availableNoteTypes = '';

  selectedNote: Note = null;

  patientID: string;
  practice: Partial<Merchant>;

  user: User;
  relationshipLink: RelationshipLink;
  parentRoute: string | null;
  prefillNoteAction: string;

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: NotesModalConfig,
    private notesService: NotesService,
    private appStateService: AppStateService,
    private matDialogRef: MatDialogRef<NotesModal>,
    private customerProspectService: CustomerProspectService
  ) {
    this.notesService.setSelectedNotes([]);

    if (data) {
      this.patientID = data.patientID || undefined;
      this.noteListMode = data.noteListMode || 'list';
      this.relationshipLink = data.relationshipLink || undefined;
      this.parentRoute = data.parentRoute || null;
      this.prefillNoteAction = data.prefillNoteAction || undefined;
    } else {
      console.warn(`Please pass a parent route e.g. 'merchant', 'consumer' or null for the note modals data`);
    }
  }

  ngOnInit() {
    this.matDialogRef.addPanelClass(['borderless-modal', 'notes-modal-class']);
    this.matDialogRef
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.notesService.setSelectedNotes([]);
        this.notesService.setNotes([]);
      });

    this.notesService
      .fetchEnabledNoteTypes$()
      .pipe(untilDestroyed(this))
      .subscribe((enabledNoteTypes) => {
        this.availableNoteTypes = enabledNoteTypes.map((noteType) => noteType.ActionType).join('|');
        this.notesService.setEnabledNoteTypes(enabledNoteTypes);
        this.filterNotes();
      });

    if (this.patientID) {
      this.selectUserFromID();
    }

    this.appStateService.getAppState$().subscribe((appState) => {
      if (this.prefillNoteAction) {
        this.selectPatient(appState.practice, true);
      }
    });
  }

  ngOnDestroy() {
    /** required for untilDestroyed (Replace with @UntilDestroyed() Decorator in angular 9 and delete ngOnDestroy()) */
  }

  close() {
    this.matDialogRef.close();
  }

  selectUserFromID() {
    this.customerProspectService
      .fetchCustomerAndProspect$(this.data.patientID, {}, this.sessionType)
      .subscribe((customerProspect) => {
        this.selectNote(customerProspect);
      });
  }

  setNoteListMode(mode: NoteModalMode) {
    this.prefillNoteAction = undefined;
    if (mode === 'create' || mode === 'list') {
      this.selectedNote = null;
      /** refresh to update follow-up counts */
      if (mode === 'list') {
        this.filterNotes();
      }
    }
    this.noteListMode = mode;
  }

  setNameFilter(name: string) {
    this.notesSectionFilter = 1;
    this.areMoreNotesAvailable = true;
    this.patientNameFilter = name;
    this.filterNotes();
  }

  setAreNotesPrivate(areNotesPrivate: boolean) {
    this.areNotesPrivate = areNotesPrivate;
    this.filterNotes();

    if (this.selectedPatient) {
      this.filterSelectedNotes();
    }
  }

  setShowArchivedNotes(filter: NoteListToggles) {
    this.showArchivedNotes = filter.archived;
    this.selectedNotesFilterBy = filter.systemNotes ? 'latest' : 'all';
    this.filterSelectedNotes();
  }

  filterNotes() {
    const query: NoteQuery = {
      payload: {
        limit: 200,
        section: this.notesSectionFilter,
        'Who.Name': this.patientNameFilter,
        filterBy: this.notesFilterBy,
        Merchant_key: this.merchantKey,
        areNotesPrivate: this.areNotesPrivate,
        showArchivedNotes: this.showArchivedNotes,
        availableNoteTypes: this.availableNoteTypes,
      },
    };

    return this.notesService
      .fetchNotes$<Note>(query.payload)
      .pipe(
        untilDestroyed(this),
        tap((notes) => {
          this.notesService.setNotes(notes);
        })
      )
      .subscribe();
  }

  loadMoreNotes() {
    this.notesSectionFilter++;
    const query: NoteQuery = {
      payload: {
        limit: 200,
        section: this.notesSectionFilter,
        'Who.Name': this.patientNameFilter,
        filterBy: this.notesFilterBy,
        Merchant_key: this.merchantKey,
        areNotesPrivate: this.areNotesPrivate,
        availableNoteTypes: this.availableNoteTypes,
        showArchivedNotes: this.showArchivedNotes,
      },
    };

    this.notesService.loadMoreNotes(query.payload).subscribe((notes) => {
      if (notes.length) {
        this.areMoreNotesAvailable = true;
      } else {
        this.areMoreNotesAvailable = false;
        NotifyAppComponent.displayToast('info', 'All patients Loaded', '');
      }
    });
  }

  selectPatient(practice: Partial<Merchant>, prefill: boolean = false) {
    const invitationLookup = RootAppComponent.dialog.open(InvitationLookupComponent, {
      data: {
        merchantID: practice.ID,
        onlyMyAccount: true,
        useStrict: true,
      },
      width: '600px',
    });

    invitationLookup.componentInstance.closeModal.pipe(untilDestroyed(this)).subscribe(() => {
      invitationLookup.close();
    });

    invitationLookup.componentInstance.getSelectedPatient.pipe(untilDestroyed(this)).subscribe((selectedPatient) => {
      invitationLookup.close();
      this.selectedPatient = selectedPatient;

      if (prefill) {
        this.noteListMode = 'create';
      }
    });
  }

  newPatient(practice: Partial<Merchant>) {
    const customerProspectCreate = RootAppComponent.dialog.open(CustomerProspectCreateComponent, {
      data: {
        merchantID: practice.ID,
        isEmailRequired: false,
      },
      width: '850px',
    });

    customerProspectCreate.componentInstance.close.pipe(untilDestroyed(this)).subscribe(() => {
      customerProspectCreate.close();
    });

    customerProspectCreate.componentInstance.getResult.pipe(untilDestroyed(this)).subscribe((newPatient) => {
      customerProspectCreate.close();
      this.selectedPatient = newPatient;
    });
  }

  setNotesFilterBy(filterBy: 'all' | 'latest' | 'follow ups') {
    this.notesFilterBy = filterBy;
    this.filterNotes();
  }

  selectNote(selectedPatient: CustomerAndProspect) {
    this.selectedPatient = selectedPatient;

    this.selectedNotesSectionFilter = 1;

    this.filterSelectedNotes();
  }

  filterSelectedNotes() {
    const query: NoteQuery = {
      payload: {
        limit: 30,
        section: this.selectedNotesSectionFilter,
        ID: this.selectedPatient.ID,
        'Who.Name': '',
        filterBy: this.selectedNotesFilterBy,
        Merchant_key: this.merchantKey,
        areNotesPrivate: this.areNotesPrivate,
        showArchivedNotes: this.showArchivedNotes,
        availableNoteTypes: this.availableNoteTypes,
      },
    };

    return this.notesService
      .fetchNotes$<Note>(query.payload)
      .pipe(
        untilDestroyed(this),
        tap((notes) => {
          this.notesService.setSelectedNotes(notes);
        })
      )
      .subscribe();
  }

  loadMoreSelectedNotes() {
    this.selectedNotesSectionFilter++;
    const query: NoteQuery = {
      payload: {
        limit: 30,
        section: this.selectedNotesSectionFilter,
        ID: this.selectedPatient.ID,
        'Who.Name': '',
        areNotesPrivate: this.areNotesPrivate,
        showArchivedNotes: this.showArchivedNotes,
        availableNoteTypes: this.availableNoteTypes,
        filterBy: this.selectedNotesFilterBy,
        Merchant_key: this.merchantKey,
      },
    };

    this.notesService.loadMoreSelectedNotes(query.payload).subscribe((notes) => {
      if (notes.length) {
        this.areMoreSelectedNotesAvailable = true;
      } else {
        this.areMoreSelectedNotesAvailable = false;
        NotifyAppComponent.displayToast('info', 'All notes loaded', '');
      }
    });
  }

  deleteNote(note: Note) {
    this.notesService
      .deleteNote$(note)
      .pipe(
        switchMap((id) => {
          return this.notesService.removeSelectedNote$(note);
        })
      )
      .subscribe((id) => {
        this.filterNotes();
        NotifyAppComponent.displayToast('success', 'Successful operation', 'Successfully removed note');
      });
  }

  archiveNote(note: Note) {
    const payload: Partial<Note> = {
      View_By_Funder: note.View_By_Funder,
      View_By_Merchant: note.View_By_Merchant,
      View_By_Patient: note.View_By_Patient,
      View_By_Supplier: note.View_By_Supplier,
      View_By_Private: note.View_By_Private,
      View_By_EP: note.View_By_EP,
      View_By_Promoter: note.View_By_Promoter,
      Description: note.Description,
      Is_Archived: '1',
    };

    this.notesService
      .updateNote$(note, payload)
      .pipe(
        switchMap((id) => {
          return this.notesService.removeSelectedNote$(note);
        })
      )
      .subscribe((id) => {
        this.filterSelectedNotes();

        NotifyAppComponent.displayToast('success', 'Successful operation', 'Successfully archived note');
      });
  }

  unarchiveNote(note: Note) {
    const payload: Partial<Note> = {
      View_By_Funder: note.View_By_Funder,
      View_By_Merchant: note.View_By_Merchant,
      View_By_Patient: note.View_By_Patient,
      View_By_Supplier: note.View_By_Supplier,
      View_By_Private: note.View_By_Private,
      View_By_EP: note.View_By_EP,
      View_By_Promoter: note.View_By_Promoter,
      Description: note.Description,
      Is_Archived: '0',
    };

    this.notesService
      .updateNote$(note, payload)
      .pipe(
        switchMap((id) => {
          return this.notesService.removeSelectedNote$(note);
        })
      )
      .subscribe((id) => {
        this.filterSelectedNotes();

        NotifyAppComponent.displayToast('success', 'Successful operation', 'Successfully unarchived note');
      });
  }

  setSelectedNotesFilterBy(filter: NoteListToggles) {
    this.selectedNotesFilterBy = filter.systemNotes ? 'latest' : 'all';
    this.showArchivedNotes = filter.archived;
    this.filterSelectedNotes();
  }

  setMerchant(merchant: Partial<Merchant>) {
    this.merchantKey = merchant.ID;
    this.filterNotes();
  }

  setSelectedNote(note) {
    this.selectedNote = note;
  }
}
