<div class="clearfix" *ngIf="isAdminOrPromoter == true">
  <div [hidden]="hideExtraComponents">
    <app-operator-filter
      id="part1"
      [displayMerchant]="true"
      [displayStatus]="false"
      [displayDateFrom]="true"
      [displayDateTo]="true"
      [displayProduct]="false"
      [displayDentist]="false"
      [displayAllMerchant]="false"
      (getMerchant)="getMerchant($event)"
      (getDateTo)="getDateTo($event)"
      (getDateFrom)="getDateFrom($event)"
      (search)="applySearch($event)"
    ></app-operator-filter>
  </div>

  <div class="row clearfix">
    <app-existing-patient-list-map
      *ngIf="merchantID != 'none'"
      [dateFrom]="dateFrom"
      [dateTo]="dateTo"
      [merchantID]="merchantID"
    ></app-existing-patient-list-map>
  </div>
  <div class="row clearfix" [hidden]="hideExtraComponents">
    <div class="pleaseChoose" *ngIf="merchantID == 'none'" @ngIfAnimation>
      <p>
        <strong>No merchant selected</strong>
      </p>
      <img class="character_warning" />
      <p>
        Choose your desired merchant from the drop-down above to view their
        {{ "KEYWORD.patient" | translate }}s
      </p>
    </div>
  </div>
</div>

<div class="clearfix" *ngIf="isAdminOrPromoter == false">
  <div [hidden]="hideExtraComponents">
    <app-operator-filter
      id="part1"
      [displayMerchant]="false"
      [displayStatus]="false"
      [displayDateFrom]="true"
      [displayDateTo]="true"
      [displayProduct]="false"
      [displayDentist]="false"
      (getMerchant)="getMerchant($event)"
      (getDateTo)="getDateTo($event)"
      (getDateFrom)="getDateFrom($event)"
      (search)="applySearch($event)"
    ></app-operator-filter>
  </div>
  <app-existing-patient-list-map id="part1" [dateFrom]="dateFrom" [dateTo]="dateTo"></app-existing-patient-list-map>
</div>
