import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output, SimpleChanges } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { MessageService } from '../shared/message.service';

import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import * as $ from 'jquery';
import { forEach, uniq } from 'lodash';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { CustomPhonePipe } from '../../../shared/pipes/custom-phone.pipe';
import { UtilsService } from '../../../shared/services/utils.service';
import { MarketingCampaginLookupComponent } from '../../marketing/marketing-campagin-lookup/marketing-campagin-lookup.component';
import { MarketingCampaignTipsOffersLookupComponent } from '../../marketing/marketing-campaign-tips-offers-lookup/marketing-campaign-tips-offers-lookup.component';
import { SmsPreviewComponent } from '../sms-preview/sms-preview.component';

import { environment } from 'environments/environment';
import { FileInsertionComponent } from '../../../shared/components/file-insertion/file-insertion.component';
import { CustomCurrencyPipe } from '../../../shared/pipes/custom-currency.pipe';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { UtilSchema } from '../../../shared/types/utils/utils.type';
import { DefaultMessageLookupComponent } from '../../default-message/default-message-lookup/default-message-lookup.component';
import { MessageTemplateCreateEditComponent } from '../../default-message/message-template-create-edit/message-template-create-edit.component';
import { DocumentLinkCreateComponent } from '../../document/document-link-create/document-link-create.component';
import { DocumentLinkLookupComponent } from '../../document/document-link-lookup/document-link-lookup.component';
import { LandingPageNewModalComponent } from '../../document/landing-page-new-modal/landing-page-new-modal.component';
import { InstantOfferCreateComponent } from '../../instant-finance-offer/instant-offer-create/instant-offer-create.component';
import { MerchantLinkLookupComponent } from '../../merchant-link/merchant-link-lookup/merchant-link-lookup.component';
import { TreatmentDocumentImportOverviewComponent } from '../../treatment/treatment-document-import-overview/treatment-document-import-overview.component';
import { DesktopPreviewComponent } from '../desktop-preview/desktop-preview.component';
import { DocumentListThumbnailComponent } from '../../document/document-list-thumbnail/document-list-thumbnail.component';

@Component({
  selector: 'message-input',
  templateUrl: './message-input.component.html',
  styleUrls: ['./message-input.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.stacked-card-view', style({ opacity: 0 }), { optional: true }),
        query(
          '.stacked-card-view',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({ opacity: 0, offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.stacked-card-view', style({ opacity: 1 }), { optional: true }),
        query(
          '.stacked-card-view',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({ opacity: 1, offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class MessageInputComponent implements OnInit {
  @Input()
  displayEmoji = true;
  @Input()
  animated = false;
  @Input()
  isCollapsed = true;

  @Input()
  displayMore = false;
  @Input()
  maxLength = 150;
  @Input()
  displayMessageTemplate = true;
  @Input()
  isView = false;
  @Input()
  showAllCustomization = false;
  payload;

  @Input()
  displayPatient = true;
  @Input()
  isExpend = false;

  @Input()
  displayContact = true;
  @Input()
  label = 'Please insert a message';

  @Input()
  displayMerchant = true;
  @Input()
  displayInstantOffer = false;

  @Input()
  displayPromoter = true;

  @Input()
  displayMarketing = true;

  @Input()
  displayDentalOffers = true;

  @Input()
  displayDocument = true;

  @Input()
  isModal = true;

  @Input()
  saveAsTemplate = false;

  @Input()
  isSMSPreview = true;

  @Input()
  isDesktopPreview = false;

  @Input()
  displaySaveAsTemplate = true;

  convertedMessage;
  createdRecord;
  @Input()
  tableName = 'CustomersAndProspects';
  @Input()
  cardID;

  @Input()
  merchantID;

  @Input()
  clear = new EventEmitter();

  settings = Settings.global

  @Input()
  contactID;

  @Input()
  messageTemplateID;
  @Input()
  patientID;

  @Input()
  contact;
  @Input()
  merchant;
  @Input()
  card;

  @Input()
  textLength = 160;
  @Input()
  apiTinyKey = Settings.global['TinyAPIKey'];

  @Input()
  displayExpend = true;
  @Input()
  isAction = false;
  @Input()
  message;
  close = new EventEmitter();
  @Input()
  encode = true;
  @Input()
  isMessageConverted = false;
  @Input()
  purposeCodes = ['PromSMS', 'MerSMS'];

  random = btoa(Math.random().toString()).substring(2, 20);
  @Input()
  messageMode = 'sms';
  util = new UtilsClass();
  @Output()
  getMessage = new EventEmitter();
  @Output()
  getResult = new EventEmitter();
  @Output()
  getConvertedMessage = new EventEmitter();
  @Output()
  getAction = new EventEmitter();
  practiceRules = [];
  promoterRules = [];
  contactRules = [];
  cardRules = [];
  campaigns = [];
  EPTreatmentDocuments = [];
  displayEmojis = Settings.global['displayEmojis'];
  isModuleAppointmentActive = Settings.global['isModuleAppointmentActive'];
  isModulePatientSMSActive = Settings.global['isModulePatientSMSActive'];
  isModulePracticeLandingPageActive = Settings.global['isModulePracticeLandingPageActive'];
  isModuleDocumentLinkActive = Settings.global['isModuleDocumentLinkActive'];
  isModuleCustomMessagesActive = Settings.global['isModuleCustomMessagesActive'];
  isModuleSMSOfferActive = Settings.global['isModuleSMSOfferActive'];

  instantOfferID;
  @Input()
  isTiny = false;

  @Input()
  isMenuBar = true;
  @Input()
  isCard = false
  @Input()
  isCustomTreatmentAmount = false

  @Input()
  displayPreview = false


  @Input()
  displayUseDefault = false

  @Input()
  useDefault = false

  @Input()
  useDefaultMessage = "Use as Default message"

  @Input()
  cardTitle

  @Input()
  cardDescription

  @Input()
  inputTitle

  @Input()
  previewTitle

  @Input()
  theme = {
    code: 'blue',
    background: 'linear-gradient(135deg, #0092eb 0%, #0dbad3 100%)',
    color: '#1b8bdd'
  }


  TinyMCEOptions = {
    menubar: 'file edit view insert format tools table tc help',
    plugins:
      ' casechange lists checklist  table  advcode codesample     media mediaembed export formatpainter  pageembed permanentpen powerpaste  lists advlist anchor autolink  autosave  charmap    emoticons   hr image image imagetools    insertdatetime  link lists media nonbreaking pagebreak  preview print save searchreplace    textpattern toc  ',
    toolbar1:
      'undo redo | code  | styles  | bold underline italic   |   formatselect fontselect fontfamily  fontsize  fontsizeselect  | forecolor backcolor casechange permanentpen formatpainter removeformat ',
    toolbar2:
      ' alignleft aligncenter alignright alignjustify autoresize |  bullist numlist checklist  |    hr  | outdent indent    | image  media charmap | print  export | preview   ',

    default_link_target: '_blank',
    browser_spellcheck: true,

    extended_valid_elements:
      'a[class|name|href|target|title|hreflang|ping|referrerpolicy|charset|onclick|rel|id|download|media|type|width|height],button[class|style|autofocus|autocomplete|name|onclick|disable|id|type|value|width|formtarget|formnovalidate|formmethod|formenctype|formaction|form|height]',
  };

  practiceSchema: UtilSchema[] =  this.util.getPracticeSchema()

  promoterSchema: UtilSchema[] =  this.util.getPromoterSchema()

  contactSchema: UtilSchema[] = this.util.getContactSchema()

  cardSchema: UtilSchema[] = this.util.getCardSchema()

  groups = ['1', '2', '3', '4', '5'];

  isPromoterOrAdmin = false;
  @Input()
  promoter;
  promoterID;
  sessionType;

  loadCard = false;
  loadPromoter = false;
  loadMerchant = false;
  loadContact = false;

  linkParamsID;
  linkCardID;
  linkMerchantID;
  linkContactID;
  linkTablename;

  @Input()
  visitorsType = 'pr'
  isLinkParamsCreated = false;

  isLinkParamsCreating = false;

  @Input()
  isContactStaff = false ;

  keyWord='KEYWORD.patient'
  constructor(
    private utilService: UtilsService,
    private authenticationService: AuthenticationService,
    private messageService: MessageService,
    private customPhonePipe: CustomPhonePipe,
    private currencyPipe: CustomCurrencyPipe,
    @Optional()
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data) {
      if (data && data.cardID) {
        this.cardID = data.cardID;
        this.isModal = true;
      }

      if (data.purposeCodes && typeof data.purposeCodes === 'string') {
        this.purposeCodes = data.purposeCodes.split(',');
        this.isModal = true;
      } else if (data.purposeCodes && data.purposeCodes.length > 0 && Array.isArray(data.purposeCodes)) {
        this.purposeCodes = data.purposeCodes;
        this.isModal = true;
      }

      if (data && data.messageTemplateID) {
        this.messageTemplateID = data.messageTemplateID;
        this.isModal = true;
      }
      if (data && data.merchantID) {
        this.merchantID = data.merchantID;
        this.isModal = true;
      }
      if (data && data.isView != null) {
        this.isView = data.isView;
        this.isModal = true;
      }
      if (data && data.promoter) {
        this.promoter = data.promoter;
        this.isModal = true;
      }

      if (data && data.visitorsType) {
        this.visitorsType = data.visitorsType;
        this.isModal = true;
      }


      if (data && data.theme) {
        this.theme = data.theme;
        this.isModal = true;
      }
      if (data && data.contactID) {
        this.contactID = data.contactID;
        this.isModal = true;
      }
      if (data && data.contact) {
        this.contact = data.contact;
        this.isModal = true;
      }
      if (data && data.merchant) {
        this.merchant = data.merchant;
        this.isModal = true;
      }
      if (data && data.card) {
        this.card = data.card;
        this.isModal = true;
      }
      if (data && data.messageMode) {
        this.messageMode = data.messageMode;
        this.isModal = true;
      }

      if (data && data.tableName) {
        this.tableName = data.tableName;
        this.isModal = true;
      }

      if (data && data.message) {
        this.message = data.message;
        this.isModal = true;
      }
      if (data && data.isModal != null) {
        this.isModal = data.isModal;
      }

      if (data && data.isAction != null) {
        this.isAction = data.isAction;
      }




      if (data && data.showAllCustomization != null) {
        this.showAllCustomization = data.showAllCustomization;
      }
      if (data && data.isTiny != null) {
        this.isTiny = data.isTiny;
      }
      if (data && data.isCard != null) {
        this.isCard = data.isCard;
      }
      if (data && data.isCustomTreatmentAmount != null) {
        this.isCustomTreatmentAmount = data.isCustomTreatmentAmount;
      }




      if (data && data.displayUseDefault != null) {
        this.displayUseDefault = data.displayUseDefault;
      }

      if (data && data.useDefault != null) {
        this.useDefault = data.useDefault;
      }

      if (data && data.useDefaultMessage) {
        this.useDefaultMessage = data.useDefaultMessage;
        this.isModal = true;
      }

      if (data && data.cardTitle) {
        this.cardTitle = data.cardTitle;
        this.isModal = true;
      }
      if (data && data.inputTitle) {
        this.inputTitle = data.inputTitle;
        this.isModal = true;
      }

      if (data && data.previewTitle) {
        this.previewTitle = data.previewTitle;
        this.isModal = true;
      }



      if (data && data.cardDescription) {
        this.cardDescription = data.cardDescription;
        this.isModal = true;
      }
      if (data && data.isSMSPreview != null) {
        this.isSMSPreview = data.isSMSPreview;
      }
      if (data && data.displayPreview != null) {
        this.displayPreview = data.displayPreview;
      }

      if (data && data.isDesktopPreview != null) {
        this.isDesktopPreview = data.isDesktopPreview;
      }

      if (data && data.saveAsTemplate != null) {
        this.saveAsTemplate = data.saveAsTemplate;
      }

      if (data && data.displaySaveAsTemplate != null) {
        this.displaySaveAsTemplate = data.displaySaveAsTemplate;
      }
      if (data && data.displayEmoji != null) {
        this.displayEmoji = data.displayEmoji;
      }

      if (data && data.displayMessageTemplate != null) {
        this.displayMessageTemplate = data.displayMessageTemplate;
      }
      if (data && data.displayPatient != null) {
        this.displayPatient = data.displayPatient;
      }
      if (data && data.displayContact != null) {
        this.displayContact = data.displayContact;
      }
      if (data && data.displayMerchant != null) {
        this.displayMerchant = data.displayMerchant;
      }

      if (data && data.displayInstantOffer != null) {
        this.displayInstantOffer = data.displayInstantOffer;
      }

      if (data && data.displayPromoter != null) {
        this.displayPromoter = data.displayPromoter;
      }
      if (data && data.displayMarketing != null) {
        this.displayMarketing = data.displayMarketing;
      }
      if (data && data.displayDentalOffers != null) {
        this.displayDentalOffers = data.displayDentalOffers;
      }
      if (data && data.displayDocument != null) {
        this.displayDocument = data.displayDocument;
      }
      if (data && data.isExpend != null) {
        this.isExpend = data.isExpend;
      }

      if (data.patientID) {
        this.patientID = data.patientID;
      }

      if (data && data.encode != null) {
        this.encode = data.encode;
      }
    }
  }

  ngOnInit() {


    if(this.isContactStaff)
    {
      this.cardSchema=this.util.getReceiverSchema()

      this.keyWord='Staff Member'
    }

    if (this.clear) {
      this.clear.subscribe(r => {
        if (r == true) {
          this.messageTemplateID = null;
        }
      })
    }
    if (Settings.global['context'] === 'p4p') {
      this.theme = {
        code: 'ee350b',
        background: ' linear-gradient(-135deg, #1caaa0 -10.31%, #7462ef  97.05%)',
        color: '#7462ef',
      };
    }

    RootAppComponent.getExpendMessageInputResult.subscribe((res) => {
      if (res && res.random == this.random) {
        this.message = res.message;
        this.useDefault = res.useDefault;
        this.saveAsTemplate = res.saveAsTemplate;

        if (res.messageTemplateID) {
          this.messageTemplateID = res.messageTemplateID;
        }

        this.sendResult();
      }
    });
    this.authenticationService.isPromoterOrAdmin().subscribe((isPromoterOrAdmin) => {
      this.isPromoterOrAdmin = isPromoterOrAdmin;
      this.authenticationService.getSessionType().subscribe((sessionType) => {
        this.sessionType = sessionType;
        this.utilService.getCurrentAccess().subscribe((access) => {
          if (access) {
            this.setAccess(access);
          }

          if (!this.promoter) {
            this.authenticationService.getPromoter().subscribe((r) => {
              if (r && r.data) {
                this.promoter = r.data;
                this.promoterID = r.data.ID;
              } else if (r) {
                this.promoter = r;
                this.promoterID = r.ID;
              }

              this.buildPromoter();
              this.setup();
            });
          } else {
            this.buildPromoter();
            this.setup();
          }
        });
      });
    });
  }

  ngOnChanges(changes: SimpleChanges) {

    if (this.isMenuBar == false) {
      this.TinyMCEOptions.menubar = 'false';
    }

    this.authenticationService.isPromoterOrAdmin().subscribe((isPromoterOrAdmin) => {
      this.isPromoterOrAdmin = isPromoterOrAdmin;

      if (this.card && this.card.ID != this.cardID) {
        this.buildCard(false);
      }
      if (this.contact && this.contact.ID != this.contactID) {
        this.buildContact(false);
      }

      if (this.merchant && this.merchant.ID != this.merchantID) {
        this.buildMerchant(false);
      } else if (
        (changes.merchantID && changes.merchantID.previousValue != changes.merchantID.currentValue) ||
        (changes.cardID && changes.cardID.previousValue != changes.cardID.currentValue) ||
        (changes.contactID && changes.contactID.previousValue != changes.contactID.currentValue) ||
        (changes.patientID && changes.patientID.previousValue != changes.patientID.currentValue)
      ) {
        if (changes.merchantID && changes.merchantID.previousValue != changes.merchantID.currentValue) {
          this.merchant = null;
        }
        if (changes.patientID && changes.patientID.previousValue != changes.patientID.currentValue) {
          this.card = null;
          this.cardID = this.patientID;
          this.tableName = 'CustomersAndProspects';
        } else if (changes.cardID && changes.cardID.previousValue != changes.cardID.currentValue) {
          this.card = null;
        }

        if (changes.contactID && changes.contactID.previousValue != changes.contactID.currentValue) {
          this.contact = null;
        }

        this.setup(false);
      }
      if (changes.message && changes.message.previousValue != changes.message.currentValue) {
        this.buildMessage();
      }

      this.sendResult();
    });
  }

  buildMessage() {
    if (this.message) {
      $('#main-sms-container-' + this.random).focus();
      $('#main-sms-container-' + this.random).val(this.message);
      $('#main-sms-container-' + this.random).prop('selectionEnd', this.message.length);
    }
  }

  clearMessage() {
    this.message = null;
    this.sendResult();
  }

  setup(sendResult = true) {
    if (this.patientID) {
      this.cardID = this.patientID;
      this.tableName = 'CustomersAndProspects';
    }
    if (this.isModuleAppointmentActive == true) {
      const index = this.practiceSchema.findIndex((item) => {
        if (item && item.code === 'PracitceAppointment') {
          return true;
        }

        return false;
      });

      if (index === -1) {
        this.practiceSchema.push({
          code: 'PracitceAppointment',
          label: 'Appointment Page',
          field: 'Terminal_Code',
          group: '3',
        });
      }
    }

    if (this.isModulePracticeLandingPageActive == true) {
      const index = this.practiceSchema.findIndex((item) => {
        if (item && item.code === 'PracticePage') {
          return true;
        }

        return false;
      });

      if (index === -1) {
        this.practiceSchema.push({
          code: 'PracticePage',
          label: 'Go,Green Page',
          field: 'Terminal_Code',
          group: '3',
        });
      }

      const index2 = this.practiceSchema.findIndex((item) => {
        if (item && item.code === 'TipsAndOffers') {
          return true;
        }

        return false;
      });

      if (index2 === -1) {
        this.practiceSchema.push({
          code: 'TipsAndOffers',
          label: 'Tips & Offers',
          field: 'Terminal_Code',
          group: '3',
        });
      }


      const index3 = this.practiceSchema.findIndex((item) => {
        if (item && item.code === 'SelfServicePage') {
          return true;
        }

        return false;
      });

      if (index3 === -1) {
        this.practiceSchema.push({
          code: 'SelfServicePage',
          label: 'Self Service Page',
          field: 'Terminal_Code',
          group: '3',
        });
      }

    }

    if (this.merchant && this.merchant.ID) {
      this.buildMerchant(sendResult);
    } else if (this.merchantID && this.isPromoterOrAdmin == true) {
      this.messageService.getCardDetails(this.merchantID, 'Merchant', {}, this.sessionType).subscribe((r) => {
        if (r) {
          this.merchant = r;
          this.buildMerchant(sendResult);
        }
      });
    } else {
      this.authenticationService.getCurrentPractice().subscribe((r) => {
        if (r) {
          this.merchant = r;
          this.buildMerchant(sendResult);
        }
      });
    }

    if (this.contact && this.contact.ID) {
      this.buildContact(sendResult);
    } else if (this.contactID && this.isPromoterOrAdmin == true) {
      this.messageService.getCardDetails(this.contactID, 'Contact', {}, this.sessionType).subscribe((r) => {
        if (r) {
          this.contact = r;
          this.buildContact(sendResult);
        }
      });
    } else {
      this.authenticationService.getCurrentDentist().subscribe((r) => {
        if (r) {
          this.contact = r;
          this.buildContact(sendResult);
        }
      });
    }

    if (this.card && this.card.ID) {
      this.buildCard(sendResult);
    } else if (this.cardID && this.cardID !== '00000000000000000000') {
      this.messageService.getCardDetails(this.cardID, this.tableName, {}, this.sessionType).subscribe((r) => {
        if (r) {
          this.card = r;
          console.log(this.card);
          this.buildCard(sendResult);
        }
      });
    } else {
      this.buildCard(sendResult);
    }
  }

  setAccess(_access) {
    if (_access) {
      this.isModulePracticeLandingPageActive = _access['isModulePracticeLandingPageActive'];

      this.isModuleAppointmentActive = _access['isModuleAppointmentActive'];

      this.isModulePatientSMSActive = _access['isModulePatientSMSActive'];
      this.isModuleDocumentLinkActive = _access['isModuleDocumentLinkActive'];
      this.isModuleCustomMessagesActive = _access['isModuleCustomMessagesActive'];
      this.isModuleSMSOfferActive = _access['isModuleSMSOfferActive'];
    }
  }

  buildMerchant(sendResult = true) {
    if (this.merchant && this.merchant.ID) {
      this.merchantID = this.merchant.ID;
      if (this.practiceSchema && this.practiceSchema.length > 0) {
        this.practiceRules = [];
        forEach(this.practiceSchema, (item) => {
          if (item) {
            const _item = item;
            if (this.showAllCustomization === true) {
              _item.value = this.merchant[item['field']] || '';
              this.practiceRules.push(_item);
            } else if (this.merchant[item['field']] && this.merchant[item['field']] != '') {
              _item.value = this.merchant[item['field']] || '';
              this.practiceRules.push(_item);
            }
          }
        });

        this.practiceRules = JSON.parse(JSON.stringify(this.practiceRules));
      }
    }

    this.loadMerchant = true;

    if (sendResult == true) {
      this.sendAfterBuild();
    }
  }

  buildPromoter(sendResult = true) {
    if (this.promoter && this.promoter.ID) {
      this.promoterID = this.promoter.ID;
      if (this.promoterSchema && this.promoterSchema.length > 0) {
        this.promoterRules = [];
        forEach(this.promoterSchema, (item) => {
          if (item) {
            const _item = item;
            if (this.promoter[item['field']] && this.promoter[item['field']] != '') {
              _item.value = this.promoter[item['field']];
              this.promoterRules.push(_item);
            }
          }
        });

        this.promoterRules = JSON.parse(JSON.stringify(this.promoterRules));
      }
    }

    this.loadPromoter = true;
    if (sendResult == true) {
      this.sendAfterBuild();
    }
  }

  buildContact(sendResult = true) {
    if (this.contact && this.contact.ID) {
      this.contactID = this.contact.ID;
      if (this.contactSchema && this.contactSchema.length > 0) {
        this.contactRules = [];
        forEach(this.contactSchema, (item) => {
          if (item) {
            const _item = item;
            if (this.showAllCustomization === true) {
              _item.value = this.contact[item['field']] || '';
              this.contactRules.push(_item);
            } else if (this.contact[item['field']] && this.contact[item['field']] != '') {
              _item.value = this.contact[item['field']] || '';
              this.contactRules.push(_item);
            }
          }
        });

        this.contactRules = JSON.parse(JSON.stringify(this.contactRules));
      }
    }
    this.loadContact = true;
    if (sendResult == true) {
      this.sendAfterBuild();
    }
  }

  buildCard(sendResult = true) {
    if (this.card && this.card.Card_TableName) {
      this.tableName = this.card.Card_TableName;
    }

    if (this.card && this.card.ID) {
      this.cardID = this.card.ID;
      if (this.cardSchema && this.cardSchema.length > 0) {
        this.cardRules = [];
        forEach(this.cardSchema, (item) => {
          if (item) {
            const _item = item;

            if (this.showAllCustomization === true) {
              _item.value = this.card[item['field']] || '';
              this.cardRules.push(_item);
            } else if (this.card[item['field']] && this.card[item['field']] != '') {
              _item.value = this.card[item['field']] || '';
              this.cardRules.push(_item);
            }
          }
        });

        this.cardRules = JSON.parse(JSON.stringify(this.cardRules));
      }
    }

    this.loadCard = true;
    if (sendResult == true) {
      this.sendAfterBuild();
    }
  }

  sendAfterBuild() {
    if (this.loadCard == true && this.loadContact == true && this.loadMerchant == true && this.loadPromoter == true) {
      this.sendResult();
    }
  }

  closeEvent() {
    this.close.emit(true);
  }

  selectEmoji(emoji) {
    if (emoji && emoji.emoji && emoji.emoji.native) {
      this.insertTextAtCursor(emoji.emoji.native + ' ');
    }
  }

  insertTextAtCursorCode(text) {
    this.insertTextAtCursor(' {{' + text + '}} ');
  }

  insertTextAtCursorValue(text) {
    this.insertTextAtCursor(text);
  }

  insertTextAtCursor(text) {
    if (text) {
      if (
        this.isTiny == true &&
        window &&
        window['tinymce'] &&
        window['tinymce'].editors &&
        window['tinymce'].editors['main-sms-tiny-' + this.random]
      ) {
        window['tinymce'].editors['main-sms-tiny-' + this.random].execCommand('mceInsertContent', true, text);
      } else {
        const cursorStart = $('#main-sms-container-' + this.random).prop('selectionStart');
        const cursorEnd = $('#main-sms-container-' + this.random).prop('selectionEnd');

        if (this.message) {
          const v = $('#main-sms-container-' + this.random).val() as string;
          if (v) {
            $('#main-sms-container-' + this.random).focus();
            const textBefore = v.substring(0, cursorStart);
            const textAfter = v.substring(cursorEnd);
            $('#main-sms-container-' + this.random).val(textBefore + text + textAfter);
            this.message = textBefore + text + textAfter;

            $('#main-sms-container-' + this.random).prop('selectionEnd', (textBefore + text).length);
          }
        } else {
          $('#main-sms-container-' + this.random).focus();
          $('#main-sms-container-' + this.random).val(text);
          this.message = text;
          $('#main-sms-container-' + this.random).prop('selectionEnd', this.message.length);
        }
      }
    }

    this.sendResult();
  }

  calculMathFloor(text) {
    if (text && text.length) {
      return Math.floor(text.length / this.textLength + 1);
    }

    return 0;
  }

  getExpendEvent() {
    RootAppComponent.expendMessageInput.emit(this.buildWholeObject());
  }

  convertMessage(isBoldText = false) {
    let result = this.message || null;

    const rule = {};

    if (this.practiceRules && this.practiceRules.length > 0) {
      for (let i = 0; i < this.practiceRules.length; i++) {
        const item = this.practiceRules[i];

        if (item && item.code === 'PracticePage') {
          rule[
            'PracticePage'
          ] = `${Settings.global['publicSiteFrontendLink']}/l/p/s/${this.merchant['Terminal_Code']}?t=g`;
        } else if (item && item.code === 'TipsAndOffers') {
          rule[
            'TipsAndOffers'
          ] = `${Settings.global['publicSiteFrontendLink']}/l/d/s/${this.merchant['Terminal_Code']}?t=t`;
        } else if (item && item.code === 'SelfServicePage') {
          rule[
            'SelfServicePage'
          ] = `${environment.nodeUrl}/s/${this.merchant['Terminal_Code']}?t=${this.visitorsType}`;
        } else if (item && item.code === 'PracitceAppointment') {
          rule[
            'PracitceAppointment'
          ] = `${Settings.global['publicSiteFrontendLink']}/a/${this.merchant['Terminal_Code']}?t=a`;
        } else if (item && item.code && item.field) {
          if (this.merchant[item.field]) {
            if (item.isPhone == true) {

              let format = 'mobile'
              if (item.isLandLine == true) {
                format = 'landLine'
              }



              rule[item.code] = this.customPhonePipe.transform(this.merchant[item.field], format);
            } else {
              rule[item.code] = this.merchant[item.field];
            }
          } else {
            rule[item.code] = ' ';
          }
        }
      }
    }

    if (this.cardRules && this.cardRules.length > 0) {
      for (let i = 0; i < this.cardRules.length; i++) {
        const item = this.cardRules[i];
        if (item && item.code && item.field) {
          if (this.card[item.field]) {
            if (item.isPhone == true) {

              let format = 'mobile'
              if (item.isLandLine == true) {
                format = 'landLine'
              }

              rule[item.code] = this.customPhonePipe.transform(this.card[item.field], format);
            } else {
              rule[item.code] = this.card[item.field];
            }
          } else {
            rule[item.code] = ' ';
          }
        }
      }
    }

    if (this.contactRules && this.contactRules.length > 0) {
      for (let i = 0; i < this.contactRules.length; i++) {
        const item = this.contactRules[i];
        if (item && item.code && item.field) {
          if (this.contact[item.field]) {
            if (item.isPhone == true) {

              let format = 'mobile'
              if (item.isLandLine == true) {
                format = 'landLine'
              }

              rule[item.code] = this.customPhonePipe.transform(this.contact[item.field], format);
            } else {
              rule[item.code] = this.contact[item.field];
            }
          } else {
            rule[item.code] = ' ';
          }
        }
      }
    }

    if (this.promoterRules && this.promoterRules.length > 0) {
      for (let i = 0; i < this.promoterRules.length; i++) {
        const item = this.promoterRules[i];
        if (item && item.code && item.field) {
          if (this.promoter[item.field]) {
            if (item.isPhone == true) {

              let format = 'mobile'
              if (item.isLandLine == true) {
                format = 'landLine'
              }



              rule[item.code] = this.customPhonePipe.transform(this.promoter[item.field], format);
            } else {
              rule[item.code] = this.promoter[item.field];
            }
          } else {
            rule[item.code] = ' ';
          }
        }
      }
    }


    if (this.card && (this.card['AmountToFinance'] || Number(this.card['AmountToFinance']) === 0) && this.currencyPipe) {
      rule['AmountToFinance'] = this.currencyPipe.transform(this.card['AmountToFinance']);
    } else {

      if (Settings.global === 'breeze' ) {
        rule['AmountToFinance'] = '[Amount To Finance]';
      } else {
        rule['AmountToFinance'] = '[Treatment Amount]';
      }

    }

    if (this.isTiny == true) {

      if (result)
        result = result.replace(/(\r\n|\r|\n)/g, '');
    }
    result = this.util.convertMessage(result, rule, { isStrong: isBoldText, isToolTip: isBoldText });

    if (this.linkParamsID && result) {
      const reg = /(api\/l\/p-.*?-p\/)/g;
      const reg2 = /(\/l\/)/g;
      const reg3 = /(api\/m\/p-.*?-p\/)/g;

      result = result.replace(reg, 'api/l/p-' + this.linkParamsID + '-p');
      result = result.replace(reg3, 'api/m/p-' + this.linkParamsID + '-p');

      result = result.replace(reg2, '/m/p-' + this.linkParamsID + '-p/');
    }

    return result;
  }

  campaignLook() {
    if (this.campaigns && this.campaigns.length > 0) {
      this.lookupModal();
    } else {
      const payload: any = {
        statusCodes: 'ACTV,CMPL',
        isExpired: false,
        merchantID: this.merchantID,
        limit: 200,
        fields:
          'ID,DateTimeCreated,LastModified,LastModified,Label,Description,Social_Media_Only,Template.Label,Template.FacebookPost_Key,Template.LinkedInPost_Key,Template.TwitterPost_Key,Template.InstagramPost_Key,Template.Tag,Template.LandingPageURL',
      };
      this.messageService.getCamaignList(payload, this.isPromoterOrAdmin).subscribe((res) => {
        if (res && res.length > 0) {
          this.campaigns = res;

          this.lookupModal();
        }
      });
    }
  }

  lookupModal() {
    const ref = RootAppComponent.dialog.open(MarketingCampaginLookupComponent, {
      data: {
        campaigns: this.campaigns,
        isCreate: false,
      },
      width: '700px',
    });

    ref.componentInstance.closeModal.subscribe((res) => {
      ref.close();
    });
    ref.componentInstance.getCampaign.subscribe((c) => {
      if (c && c.ID) {
        let link = `${Settings.global['publicSiteFrontendLink']}/mc/${c.ID}`;
        if (
          this.cardID &&
          this.tableName &&
          (this.tableName === 'Prospect' || this.tableName === 'CustomersAndProspects' || this.tableName === 'Customer')
        ) {
          link = `${Settings.global['publicSiteFrontendLink']}/mc/${c.ID}/${this.card['ID']}`;
        }
        this.utilService.createShortcut(link).subscribe((r) => {
          if (r && r.shortLink) {
            if (this.isTiny == true) {
              this.insertTextAtCursor(' ' + this.util.URLify(r.shortLink + '?t=m ') + ' ');
            } else {
              this.insertTextAtCursor(' ' + r.shortLink + '?t=m ');
            }
            ref.close();
          }
        });
      }
    });
  }

  insertFile() {
    const ref = RootAppComponent.dialog.open(FileInsertionComponent, {
      data: {},
      width: '600px',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
    ref.componentInstance.getResult.subscribe((link) => {
      if (link && link.shortLink && link.htmlLink) {
        if (this.isTiny == true) {
          this.insertTextAtCursor(' ' + link.htmlLink + ' ');
        } else {
          this.insertTextAtCursor(' ' + link.shortLink + '?t=sd ');
        }

        ref.close();
      }
    });
  }

  insertLink() {

    const ref2 = RootAppComponent.dialog.open(MerchantLinkLookupComponent, {
      data: {
        merchantID: this.merchantID,
        viewOnly: false,
        isCreate: true,
      },
      width: '600px',
      height: '80vh',
    });

    ref2.componentInstance.close.subscribe((res) => {
      ref2.close();
    });

    ref2.componentInstance.getResult.subscribe((link) => {
      if (link && link.shortLink && link.htmlLink) {
        if (this.isTiny == true) {
          this.insertTextAtCursor(' ' + link.htmlLink + ' ');
        } else {
          this.insertTextAtCursor(' ' + link.shortLink + '?t=ct ');
        }

        ref2.close();

      }
    });


  }

  tipsAndOffers() {
    const ref = RootAppComponent.dialog.open(MarketingCampaignTipsOffersLookupComponent, {
      data: {
        merchantID: this.merchantID,
        viewOnly: false,
      },
      width: '1000px',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
    ref.componentInstance.getResult.subscribe((link) => {
      if (link) {
        this.utilService.createShortcut(link).subscribe((r) => {
          if (r && r.shortLink) {
            if (this.isTiny == true) {
              this.insertTextAtCursor(' ' + this.util.URLify(r.shortLink + '?t=t ') + ' ');
            } else {
              this.insertTextAtCursor(' ' + r.shortLink + '?t=t ');
            }

            ref.close();
          }
        });
      }
    });
  }

  insertDocument() {
    const payload = {
      merchantID: this.merchantID,
      readonly: false,
      isGallery: true,
      loadUserDocument: true,
      loadICDocument: true,
      loadPatientDocument: true,
      patientID: this.cardID,
      cardID: this.cardID,
      selectedView: 'system',
      documents: this.EPTreatmentDocuments,
      userDocumentType: ['SRVSTC', 'TRP', 'IGVIDEO'],
      ICDocumentType: ['SRVSTC', 'TRP', 'IGVIDEO'],
      displayUserDocumentType: true,
      displayICDocumentType: true,
      displayPatientDocumentType: true,
      isICSideMenu: true,
      isUserSideMenu: true,
      isPatientSideMenu: true,
      buttonLabel: 'Documents',
      displayPatientDocuments: true,
      nature: ['document', 'image', 'video'],
      natureICDocument: ['document', 'image', 'video'],
      natureUserDocument: ['document', 'image', 'video'],
      naturePatientDocument: ['document', 'image', 'video'],
      addTitle: 'Insert Documents',
      selectAnotherPatient: true,
    };
    if (!(this.tableName === 'Prospect' || this.tableName == 'CustomersAndProspects' || this.tableName == 'Customer')) {
      payload.patientID = null;
      payload.loadPatientDocument = false;
      payload.displayPatientDocumentType = false;
      payload.isPatientSideMenu = false;
      payload.naturePatientDocument = [];
    }

    const ref = RootAppComponent.dialog.open(TreatmentDocumentImportOverviewComponent, {
      data: payload,
      width: '90vw'
    });
    ref.backdropClick().subscribe((data) => {
      ref.close();
    });
    ref.componentInstance.closeModal.subscribe((data) => {
      ref.close();
    });
    ref.componentInstance.getDocuments.subscribe((res) => {
      if (res && res.length > 0) {
        ref.close();
        ref.afterClosed().subscribe((r) => {
          this.EPTreatmentDocuments = JSON.parse(JSON.stringify(uniq(res)));

          const ref2 = RootAppComponent.dialog.open(DocumentLinkCreateComponent, {
            data: {
              documentIDs: this.EPTreatmentDocuments,
              merchantID: this.merchantID,
              addNewDocument: false,
              addGalleryDocument: false,
              merchant: this.merchant,
              contact: this.contact,
              contactID: this.contactID,
              promoter: this.promoter,
              visitorsType: this.visitorsType,
              message: "<p>Please see attached some information we thought you might find interesting.</p>",
              title: "Some further information ",
              description: " Your Dentist wants to shared documents with you.",
              type: 'PLandPg',
              addToStore: false,
              displayType: false
            },
            panelClass: 'noCard',
          });
          ref2.backdropClick().subscribe((res) => {

          });
          ref2.componentInstance.close.subscribe((res) => {
            ref2.close();
          });
          ref2.componentInstance.getLink.subscribe((link) => {
            if (link) {
              this.utilService.createShortcut(link).subscribe((r) => {
                if (r && r.shortLink) {
                  if (link.Is_Finance === '1' || link['Type.Code'] === 'FLandPg') {
                    if (this.isTiny == true) {
                      this.insertTextAtCursor(' ' + this.util.URLify(r.shortLink + '?t=df ') + ' ');
                    } else {
                      this.insertTextAtCursor(' ' + r.shortLink + '?t=df ');
                    }
                  } else {
                    if (this.isTiny == true) {
                      this.insertTextAtCursor(' ' + this.util.URLify(r.shortLink + '?t=d ') + ' ');
                    } else {
                      this.insertTextAtCursor(' ' + r.shortLink + '?t=d ');
                    }
                  }

                  ref2.close();
                }
              });
            }
          });
        });
      } else {
        ref.close();
      }
    });
  }

  addDocument() {


    const refLandingPageNewModalComponent = RootAppComponent.dialog.open(LandingPageNewModalComponent, {
      data: {
      },
      width: '650px',
    });

    refLandingPageNewModalComponent.componentInstance.getType.subscribe((type) => {
      refLandingPageNewModalComponent.close();

      refLandingPageNewModalComponent.afterClosed().subscribe(r => {

        const ref2 = RootAppComponent.dialog.open(DocumentLinkCreateComponent, {
          data: {
            merchantID: this.merchantID,
            tableName: this.tableName,
            cardID: this.cardID,
            card: this.card,
            merchant: this.merchant,
            contact: this.contact,
            contactID: this.contactID,
            promoter: this.promoter,
            visitorsType: this.visitorsType,
            addToStore: false,
            type: type,
            displayType: false
          },
          panelClass: 'noCard',
        });
        ref2.backdropClick().subscribe((res) => {

        });
        ref2.componentInstance.close.subscribe((res) => {
          ref2.close();
        });
        ref2.componentInstance.getLink.subscribe((link) => {
          if (link) {
            this.utilService.createShortcut(link).subscribe((r) => {
              if (r && r.shortLink) {
                if (link.Is_Finance === '1') {
                  if (this.isTiny == true) {
                    this.insertTextAtCursor(' ' + this.util.URLify(r.shortLink + '?t=df ') + ' ');
                  } else {
                    this.insertTextAtCursor(' ' + r.shortLink + '?t=df ');
                  }
                } else {
                  if (this.isTiny == true) {
                    this.insertTextAtCursor(' ' + this.util.URLify(r.shortLink + '?t=d ') + ' ');
                  } else {
                    this.insertTextAtCursor(' ' + r.shortLink + '?t=d ');
                  }
                }
                ref2.close();
              }
            });
          }
        });
      });

      refLandingPageNewModalComponent.componentInstance.close.subscribe((res) => {
        refLandingPageNewModalComponent.close();
      });
    });
  }

  messageTemplateLookup() {
    let purposeCodes = this.purposeCodes;
    if (this.messageMode != 'sms') {
      purposeCodes = null;
    }
    const ref2 = RootAppComponent.dialog.open(DefaultMessageLookupComponent, {
      data: {
        merchantID: this.merchantID,
        viewOnClick: false,
        createNew: true,
        messageMode: this.messageMode,
        purposeCodes,
        card: this.card,
        merchant: this.merchant,
        promoter: this.promoter,
        contact: this.contact,
      },
      panelClass: 'max-width-panel-1200',
      width: '90vw',
    });

    ref2.componentInstance.close.subscribe((res) => {
      ref2.close();
    });

    ref2.componentInstance.getResult.subscribe((res) => {
      if (res && res.Content) {
        if (res.ID) {
          this.messageTemplateID = res.ID;
        }
        if (this.isTiny == true) {
          this.message = this.util.URLify(res.Content);
        } else {
          this.message = res.Content;
        }
      }

      this.sendResult();
      ref2.close();
    });
  }

  documentLinkLookup() {
    const ref2 = RootAppComponent.dialog.open(DocumentListThumbnailComponent, {
      data: {
        isModal: true,
        viewOnly: false,
        isClonedStored: false,
        isClonedDefault: false,
        isClonedPublic: false,
        cloneDefault: true,
      },
      width: '85vw',

      panelClass: 'noCard',
    });

    ref2.componentInstance.close.subscribe((res) => {
      ref2.close();
    });
    ref2.componentInstance.getLandingPageURL.subscribe((link) => {
      if (link) {
        this.utilService.createShortcut(link).subscribe((r) => {
          if (r && r.shortLink) {
            if (link.Is_Finance === '1') {
              if (this.isTiny == true) {
                this.insertTextAtCursor(' ' + this.util.URLify(r.shortLink + '?t=df ') + ' ');
              } else {
                this.insertTextAtCursor(' ' + r.shortLink + '?t=df ');
              }
            } else {
              if (this.isTiny == true) {
                this.insertTextAtCursor(' ' + this.util.URLify(r.shortLink + '?t=d ') + ' ');
              } else {
                this.insertTextAtCursor(' ' + r.shortLink + '?t=d ');
              }
            }
            ref2.close();
          }
        });
      }
    });
  }

  previewSMS() {
    const ref = RootAppComponent.dialog.open(SmsPreviewComponent, {
      data: {
        card: this.card,
        cardID: this.cardID,
        tableName: this.tableName,
        message: this.convertMessage(true),
        createdRecord: this.createdRecord,
      },
      panelClass: ['noCard', 'bigger-screen'],
      width: '375px'
    });
    ref.componentInstance.close.subscribe((data) => {
      ref.close();
    });
  }

  previewWeb() {
    const ref = RootAppComponent.dialog.open(DesktopPreviewComponent, {
      data: {
        message: this.convertMessage(true) || '...',
        merchantID: this.merchantID,
        card: this.card,
        cardID: this.cardID,
      },
      panelClass: ['noCard', 'bigger-screen'],
      width: '65vw',
      height: '80vh',
    });
    ref.componentInstance.close.subscribe((res) => {
      if (res) {
        ref.close();
      }
    });
  }

  displayInstantOfferEvent() {
    const ref2 = RootAppComponent.dialog.open(InstantOfferCreateComponent, {
      data: {
        card: this.card,
        merchant: this.merchant,
        contact: this.contact,
        cardID: this.cardID,
        merchantID: this.merchantID,
        contactID: this.contactID,
        isActive: false,
      },
      panelClass: 'noCard',
    });

    ref2.componentInstance.close.subscribe((res) => {
      ref2.close();
    });

    ref2.componentInstance.getResult.subscribe((r) => {
      if (r && r.link && r.ID) {

        this.instantOfferID = r.ID;
        if (this.isTiny == true) {

          if (r.link.indexOf('?t=if') === -1) {

            this.insertTextAtCursor(' ' + this.util.URLify(r.link + '?t=if ') + ' ');
          }
          else {

            this.insertTextAtCursor(' ' + this.util.URLify(r.link + ' ') + ' ');
          }
        } else {
          if (r.link.indexOf('?t=if') === -1) {
            this.insertTextAtCursor(' ' + r.link + '?t=if ');
          }
          else {
            this.insertTextAtCursor(' ' + r.link + ' ');
          }

        }

        this.displaySaveAsTemplate = false;
        ref2.close();
      }
    });
  }

  buildResult() {
    let cardName;
    if (this.card) {
      cardName = this.card.CalculatedName;
    }

    if (this.card && this.tableName === 'Merchant') {
      cardName = this.card.TradingAs;
    }

    if (this.createdRecord && (this.createdRecord.Card_Name === '' || this.createdRecord.Card_Name === '')) {
      this.createdRecord.Card_Name = cardName;
    }
    const payload = {
      cardID: this.cardID,
      patientID: null,
      dentistID: null,
      senderCardID: this.contactID,
      contactID: this.contactID,
      body:this.convertedMessage,
      merchantID: this.merchantID,
      encode: this.encode,
      message: this.message,
      convertedMessage: this.convertedMessage,
      saveAsTemplate: this.saveAsTemplate,
      instantOfferID: this.instantOfferID,
      messageTemplateID: this.messageTemplateID
    };

    if (this.tableName === 'Contact') {
      payload.dentistID = this.cardID;
    } else if (
      this.tableName === 'Prospect' ||
      this.tableName === 'CustomersAndProspects' ||
      this.tableName === 'Customer'
    ) {
      payload.patientID = this.cardID;
    }

    this.payload = payload;
  }

  sendResult() {

    if (this.isLinkParamsCreating === false) {
      if (this.isLinkParamsCreated != true || !this.linkParamsID) {

        this.isLinkParamsCreating = true;
        const payload = {
          tableName: this.tableName,
          cardID: this.cardID,
          merchantID: this.merchantID,
          contactID: this.contactID,

          useDefault: this.useDefault,
        };

        this.utilService.createLinkParamsCached(payload).subscribe((r) => {
          if (r) {
            if (r.code) {
              this.linkParamsID = r.code;
            }

            if (r.cardID) {
              this.linkCardID = r.cardID;
            }
            if (r.tableName) {
              this.linkTablename = r.tableName;
            }
            if (r.merchantID) {
              this.linkMerchantID = r.merchantID;
            }
            if (r.contactID) {
              this.linkContactID = r.contactID;
            }
            this.isLinkParamsCreated = true;
            this.sendAllResult();
          }


          this.isLinkParamsCreating = false;
        });
      } else if (
        this.cardID != this.linkCardID ||
        this.contactID != this.linkContactID ||
        this.merchantID != this.linkMerchantID
      ) {
        this.isLinkParamsCreating = true;
        const payload = {
          tableName: this.tableName,
          cardID: this.cardID,
          merchantID: this.merchantID,
          contactID: this.contactID,

          useDefault: this.useDefault,
        };

        this.utilService.createLinkParamsCached(payload).subscribe((r) => {
          if (r) {
            if (r.code) {
              this.linkParamsID = r.code;
            }

            if (r.cardID) {
              this.linkCardID = r.cardID;
            }
            if (r.tableName) {
              this.linkTablename = r.tableName;
            }
            if (r.merchantID) {
              this.linkMerchantID = r.merchantID;
            }
            if (r.contactID) {
              this.linkContactID = r.contactID;
            }

            this.isLinkParamsCreated = true;
            this.sendAllResult();
          }

          this.isLinkParamsCreating = false;
        });
      } else {
        this.isLinkParamsCreating = true;
        this.sendAllResult();
        this.isLinkParamsCreating = false;
      }
    } else {
      this.sendAllResult();
    }
  }

  sendAllResult() {
    this.convertedMessage = this.convertMessage();
    this.buildResult();
    this.getMessage.emit(this.message || null);
    this.getConvertedMessage.emit(this.convertedMessage || null);
    this.getResult.emit(this.payload);
  }

  saveAsTemplateEvent(id = null) {
    const smsEditorComponent = RootAppComponent.dialog.open(MessageTemplateCreateEditComponent, {
      data: {
        messageMode: this.messageMode,
        message: this.message,
        messageTemplateID: id,
        card: this.card,
        merchant: this.merchant,
        contact: this.contact,
        promoter: this.promoter,
        tableName: this.tableName,
        saveAndApply: true,
      },
      width: "900px",

      panelClass: 'noCard',
    });

    smsEditorComponent.componentInstance.close.subscribe((r) => {
      smsEditorComponent.close();
    });

    smsEditorComponent.componentInstance.createdMessage.subscribe((r) => {
      if (r && r.ID) {
        this.messageTemplateID = r.ID;

        if (r.apply === true && r.Content) {
          this.message = r.Content;

          this.sendResult();
        }
        smsEditorComponent.close();
      }
    });
  }

  buildWholeObject() {
    this.buildResult();

    const payload = {
      cardID: this.cardID,
      patientID: this.patientID,
      dentistID: this.contactID,
      senderCardID: this.contactID,
      contactID: this.contactID,
      body: this.convertedMessage,
      merchantID: this.merchantID,
      encode: this.encode,
      message: this.message,
      convertedMessage: this.convertedMessage,
      saveAsTemplate: this.saveAsTemplate,
      random: this.random,

      messageTemplateID: this.messageTemplateID,
      messageMode: this.messageMode,
      contact: this.contact,
      merchant: this.merchant,
      card: this.card,
      tableName: this.tableName,
      isTiny: this.isTiny,
      isSMSPreview: this.isSMSPreview,
      isDesktopPreview: this.isDesktopPreview,
      displaySaveAsTemplate: this.displaySaveAsTemplate,
      displayEmoji: this.displayEmoji,
      displayMessageTemplate: this.displayMessageTemplate,
      displayPatient: this.displayPatient,
      displayContact: this.displayContact,
      displayMerchant: this.displayMerchant,
      displayInstantOffer: this.displayInstantOffer,
      displayPromoter: this.displayPromoter,
      displayMarketing: this.displayMarketing,
      displayDentalOffers: this.displayDentalOffers,
      displayDocument: this.displayDocument,
      isCustomTreatmentAmount: this.isCustomTreatmentAmount,
      isCard: this.isCard,

      displayUseDefault: this.displayUseDefault,
      useDefault: this.useDefault,
      useDefaultMessage: this.useDefaultMessage,

      cardTitle: this.cardTitle,
      cardDescription: this.cardDescription,
      displayPreview: this.displayPreview,
      inputTitle: this.inputTitle,
      previewTitle: this.previewTitle,
      theme: this.theme,

    };

    if (this.tableName === 'Contact') {
      payload.dentistID = this.cardID;
    } else if (
      this.tableName === 'Prospect' ||
      this.tableName === 'CustomersAndProspects' ||
      this.tableName === 'Customer'
    ) {
      payload.patientID = this.cardID;
    }

    return payload;
  }

  sendAction() {
    this.getAction.emit(this.buildWholeObject());
  }
}
