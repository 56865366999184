import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-theme-view',
  templateUrl: './theme-view.component.html',
  styleUrls: ['./theme-view.component.css'],
})
export class ThemeViewComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
