import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class WikiService {
  constructor(private http: HttpClient) {}

  removeWikiGroupAsGroup(ids): Observable<any> {
    return this.http
      .delete(`${environment.nodeUrl}/wiki-group/group/${ids}`)
      .map((res: HttpResponse<any>) => res['data']);
  }

  removeWikiAsGroup(ids): Observable<any> {
    return this.http.delete(`${environment.nodeUrl}/wiki/group/${ids}`).map((res: HttpResponse<any>) => res['data']);
  }

  updateWikiGroupAsGroup(payload: any): Observable<any> {
    return this.http
      .put(`${environment.nodeUrl}/wiki-group/group`, payload)
      .map((res: HttpResponse<any>) => res['data']);
  }

  updateWikiAsGroup(payload: any): Observable<any> {
    return this.http.put(`${environment.nodeUrl}/wiki/group`, payload).map((res: HttpResponse<any>) => res['data']);
  }

  resetOrder(): Observable<any> {
    return this.http.post(`${environment.nodeUrl}/wiki/order`, {}).map((res: HttpResponse<any>) => res['data']);
  }

  removeWikiGroup(id): Observable<any> {
    return this.http.delete(`${environment.nodeUrl}/wiki-group/${id}`).map((res: HttpResponse<any>) => res['data']);
  }

  createWikiGroup(payload: any): Observable<any> {
    return this.http.post(`${environment.nodeUrl}/wiki-group/`, payload).map((res: HttpResponse<any>) => res['data']);
  }

  updateWikiViews(id, code): Observable<any> {
    if (id) {
      return this.http
        .put(`${environment.nodeUrl}/wiki/views-hidden/${id}`, {})
        .map((res: HttpResponse<any>) => res['data']);
    } else if (code) {
      return this.http
        .put(`${environment.nodeUrl}/wiki/views-hidden/code/${code}`, {})
        .map((res: HttpResponse<any>) => res['data']);
    }
  }

  updateWikiGroup(id, payload: any): Observable<any> {
    return this.http
      .put(`${environment.nodeUrl}/wiki-group/${id}`, payload)
      .map((res: HttpResponse<any>) => res['data']);
  }

  switchPriorityWikiGroup(id, payload: any): Observable<any> {
    return this.http
      .put(`${environment.nodeUrl}/wiki-group/switch-periority/${id}`, payload)
      .map((res: HttpResponse<any>) => res['data']);
  }

  switchPriorityWiki(id, payload: any): Observable<any> {
    return this.http
      .put(`${environment.nodeUrl}/wiki/switch-periority/${id}`, payload)
      .map((res: HttpResponse<any>) => res['data']);
  }

  removeWiki(id): Observable<any> {
    return this.http.delete(`${environment.nodeUrl}/wiki/${id}`).map((res: HttpResponse<any>) => res['data']);
  }

  createWiki(payload: any): Observable<any> {
    return this.http.post(`${environment.nodeUrl}/wiki/`, payload).map((res: HttpResponse<any>) => res['data']);
  }

  updateWiki(id, payload: any): Observable<any> {
    return this.http.put(`${environment.nodeUrl}/wiki/${id}`, payload).map((res: HttpResponse<any>) => res['data']);
  }

  getWikiDetails(id, code = null, __params = {}, role = 'guest'): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (__params) {
      for (const key in __params) {
        if (__params.hasOwnProperty(key)) {
          params = params.set(key, __params[key]);
        }
      }

      options = {
        params,
      };
    }

    if (id) {
      if (role == 'merchant') {
        return this.http.get(`${environment.nodeUrl}/wiki/${id}`, options).map((res: HttpResponse<any>) => res['data']);
      } else if (role == 'admin') {
        return this.http
          .get(`${environment.nodeUrl}/wiki/global/${id}`, options)
          .map((res: HttpResponse<any>) => res['data']);
      } else if (role == 'customer') {
        return this.http
          .get(`${environment.nodeUrl}/wiki/customer/${id}`, options)
          .map((res: HttpResponse<any>) => res['data']);
      } else if (role == 'promoter') {
        return this.http
          .get(`${environment.nodeUrl}/wiki/promoter/${id}`, options)
          .map((res: HttpResponse<any>) => res['data']);
      } else if (role == 'funder') {
        return this.http
          .get(`${environment.nodeUrl}/wiki/funder/${id}`, options)
          .map((res: HttpResponse<any>) => res['data']);
      } else if (role == 'supplier') {
        return this.http
          .get(`${environment.nodeUrl}/wiki/supplier/${id}`, options)
          .map((res: HttpResponse<any>) => res['data']);
      } else {
        return this.http
          .get(`${environment.nodeUrl}/wiki/guest/${id}`, options)
          .map((res: HttpResponse<any>) => res['data']);
      }
    } else if (code) {
      if (role == 'merchant') {
        return this.http
          .get(`${environment.nodeUrl}/wiki/code/${code}`, options)
          .map((res: HttpResponse<any>) => res['data']);
      } else if (role == 'admin') {
        return this.http
          .get(`${environment.nodeUrl}/wiki/code/global/${code}`, options)
          .map((res: HttpResponse<any>) => res['data']);
      } else if (role == 'customer') {
        return this.http
          .get(`${environment.nodeUrl}/wiki/code/customer/${code}`, options)
          .map((res: HttpResponse<any>) => res['data']);
      } else if (role == 'supplier') {
        return this.http
          .get(`${environment.nodeUrl}/wiki/code/supplier/${code}`, options)
          .map((res: HttpResponse<any>) => res['data']);
      } else if (role == 'promoter') {
        return this.http
          .get(`${environment.nodeUrl}/wiki/code/promoter/${code}`, options)
          .map((res: HttpResponse<any>) => res['data']);
      } else if (role == 'funder') {
        return this.http
          .get(`${environment.nodeUrl}/wiki/code/funder/${code}`, options)
          .map((res: HttpResponse<any>) => res['data']);
      } else {
        return this.http
          .get(`${environment.nodeUrl}/wiki/code/guest/${code}`, options)
          .map((res: HttpResponse<any>) => res['data']);
      }
    }
  }

  getWikiList(__params = {}, role = 'guest'): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (__params) {
      for (const key in __params) {
        if (__params.hasOwnProperty(key)) {
          params = params.set(key, __params[key]);
        }
      }

      options = {
        params,
      };
    }

    if (role == 'merchant') {
      return this.http.get(`${environment.nodeUrl}/wiki`, options).map((res: HttpResponse<any>) => res['data']);
    } else if (role == 'admin') {
      return this.http.get(`${environment.nodeUrl}/wiki/global`, options).map((res: HttpResponse<any>) => res['data']);
    } else if (role == 'customer') {
      return this.http
        .get(`${environment.nodeUrl}/wiki/customer`, options)
        .map((res: HttpResponse<any>) => res['data']);
    } else if (role == 'promoter') {
      return this.http
        .get(`${environment.nodeUrl}/wiki/promoter`, options)
        .map((res: HttpResponse<any>) => res['data']);
    } else if (role == 'supplier') {
      return this.http
        .get(`${environment.nodeUrl}/wiki/supplier`, options)
        .map((res: HttpResponse<any>) => res['data']);
    } else if (role == 'funder') {
      return this.http.get(`${environment.nodeUrl}/wiki/funder`, options).map((res: HttpResponse<any>) => res['data']);
    } else {
      return this.http.get(`${environment.nodeUrl}/wiki/guest`, options).map((res: HttpResponse<any>) => res['data']);
    }
  }

  getWikiMenu(__params = {}, role = 'guest'): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (__params) {
      for (const key in __params) {
        if (__params.hasOwnProperty(key)) {
          params = params.set(key, __params[key]);
        }
      }

      options = {
        params,
      };
    }

    if (role == 'merchant') {
      return this.http
        .get(`${environment.nodeUrl}/wiki/menu-hidden`, options)
        .map((res: HttpResponse<any>) => res['data']);
    } else if (role == 'admin') {
      return this.http
        .get(`${environment.nodeUrl}/wiki/menu-hidden/global`, options)
        .map((res: HttpResponse<any>) => res['data']);
    } else if (role == 'customer') {
      return this.http
        .get(`${environment.nodeUrl}/wiki/menu-hidden/customer`, options)
        .map((res: HttpResponse<any>) => res['data']);
    } else if (role == 'promoter') {
      return this.http
        .get(`${environment.nodeUrl}/wiki/menu-hidden/promoter`, options)
        .map((res: HttpResponse<any>) => res['data']);
    } else if (role == 'supplier') {
      return this.http
        .get(`${environment.nodeUrl}/wiki/menu-hidden/supplier`, options)
        .map((res: HttpResponse<any>) => res['data']);
    } else if (role == 'funder') {
      return this.http
        .get(`${environment.nodeUrl}/wiki/menu-hidden/funder`, options)
        .map((res: HttpResponse<any>) => res['data']);
    } else {
      return this.http
        .get(`${environment.nodeUrl}/wiki/menu-hidden/guest`, options)
        .map((res: HttpResponse<any>) => res['data']);
    }
  }

  getWikiGroupList(__params = {}, role = 'guest'): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (__params) {
      for (const key in __params) {
        if (__params.hasOwnProperty(key)) {
          params = params.set(key, __params[key]);
        }
      }

      options = {
        params,
      };
    }

    if (role == 'merchant') {
      return this.http.get(`${environment.nodeUrl}/wiki-group`, options).map((res: HttpResponse<any>) => res['data']);
    } else if (role == 'admin') {
      return this.http
        .get(`${environment.nodeUrl}/wiki-group/global`, options)
        .map((res: HttpResponse<any>) => res['data']);
    } else if (role == 'customer') {
      return this.http
        .get(`${environment.nodeUrl}/wiki-group/customer`, options)
        .map((res: HttpResponse<any>) => res['data']);
    } else if (role == 'promoter') {
      return this.http
        .get(`${environment.nodeUrl}/wiki-group/promoter`, options)
        .map((res: HttpResponse<any>) => res['data']);
    } else if (role == 'funder') {
      return this.http
        .get(`${environment.nodeUrl}/wiki-group/funder`, options)
        .map((res: HttpResponse<any>) => res['data']);
    } else if (role == 'supplier') {
      return this.http
        .get(`${environment.nodeUrl}/wiki-group/supplier`, options)
        .map((res: HttpResponse<any>) => res['data']);
    } else {
      return this.http
        .get(`${environment.nodeUrl}/wiki-group/guest`, options)
        .map((res: HttpResponse<any>) => res['data']);
    }
  }

  getWikiTagList(__params = {}): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (__params) {
      for (const key in __params) {
        if (__params.hasOwnProperty(key)) {
          params = params.set(key, __params[key]);
        }
      }

      options = {
        params,
      };
    }

    return this.http.get(`${environment.nodeUrl}/wiki-tag`, options).map((res: HttpResponse<any>) => res['data']);
  }

  getWikiGroupDetails(id, code, __params = {}, role = 'guest'): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (__params) {
      for (const key in __params) {
        if (__params.hasOwnProperty(key)) {
          params = params.set(key, __params[key]);
        }
      }

      options = {
        params,
      };
    }

    if (id) {
      if (role == 'merchant') {
        return this.http
          .get(`${environment.nodeUrl}/wiki-group/${id}`, options)
          .map((res: HttpResponse<any>) => res['data']);
      } else if (role == 'admin') {
        return this.http
          .get(`${environment.nodeUrl}/wiki-group/global/${id}`, options)
          .map((res: HttpResponse<any>) => res['data']);
      } else if (role == 'customer') {
        return this.http
          .get(`${environment.nodeUrl}/wiki-group/customer/${id}`, options)
          .map((res: HttpResponse<any>) => res['data']);
      } else if (role == 'supplier') {
        return this.http
          .get(`${environment.nodeUrl}/wiki-group/supplier/${id}`, options)
          .map((res: HttpResponse<any>) => res['data']);
      } else if (role == 'funder') {
        return this.http
          .get(`${environment.nodeUrl}/wiki-group/funder/${id}`, options)
          .map((res: HttpResponse<any>) => res['data']);
      } else if (role == 'promoter') {
        return this.http
          .get(`${environment.nodeUrl}/wiki-group/promoter/${id}`, options)
          .map((res: HttpResponse<any>) => res['data']);
      } else {
        return this.http
          .get(`${environment.nodeUrl}/wiki-group/guest/${id}`, options)
          .map((res: HttpResponse<any>) => res['data']);
      }
    } else if (code) {
      if (role == 'merchant') {
        return this.http
          .get(`${environment.nodeUrl}/wiki-group/code/${code}`, options)
          .map((res: HttpResponse<any>) => res['data']);
      } else if (role == 'admin') {
        return this.http
          .get(`${environment.nodeUrl}/wiki-group/code/global/${code}`, options)
          .map((res: HttpResponse<any>) => res['data']);
      } else if (role == 'customer') {
        return this.http
          .get(`${environment.nodeUrl}/wiki-group/code/customer/${code}`, options)
          .map((res: HttpResponse<any>) => res['data']);
      } else if (role == 'promoter') {
        return this.http
          .get(`${environment.nodeUrl}/wiki-group/code/promoter/${code}`, options)
          .map((res: HttpResponse<any>) => res['data']);
      } else if (role == 'funder') {
        return this.http
          .get(`${environment.nodeUrl}/wiki-group/code/funder/${code}`, options)
          .map((res: HttpResponse<any>) => res['data']);
      } else if (role == 'supplier') {
        return this.http
          .get(`${environment.nodeUrl}/wiki-group/code/supplier/${code}`, options)
          .map((res: HttpResponse<any>) => res['data']);
      } else {
        return this.http
          .get(`${environment.nodeUrl}/wiki-group/code/guest/${code}`, options)
          .map((res: HttpResponse<any>) => res['data']);
      }
    }
  }

  getTags(role = 'guest'): Observable<any> {
    const options = {};

    if (role == 'merchant') {
      return this.http.get(`${environment.nodeUrl}/wiki/tag`, options).map((res: HttpResponse<any>) => res['data']);
    } else if (role == 'admin') {
      return this.http
        .get(`${environment.nodeUrl}/wiki/tag/global`, options)
        .map((res: HttpResponse<any>) => res['data']);
    } else if (role == 'customer') {
      return this.http
        .get(`${environment.nodeUrl}/wiki/tag/customer`, options)
        .map((res: HttpResponse<any>) => res['data']);
    } else if (role == 'funder') {
      return this.http
        .get(`${environment.nodeUrl}/wiki/tag/funder`, options)
        .map((res: HttpResponse<any>) => res['data']);
    } else if (role == 'supplier') {
      return this.http
        .get(`${environment.nodeUrl}/wiki/tag/supplier`, options)
        .map((res: HttpResponse<any>) => res['data']);
    } else if (role == 'promoter') {
      return this.http
        .get(`${environment.nodeUrl}/wiki/tag/promoter`, options)
        .map((res: HttpResponse<any>) => res['data']);
    } else {
      return this.http
        .get(`${environment.nodeUrl}/wiki/tag/guest`, options)
        .map((res: HttpResponse<any>) => res['data']);
    }
  }
}
