import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ConfirmDialogMessageComponent } from '../../../shared/components/confirm-dialog-message/confirm-dialog-message.component';
import { ConfirmDialogMultipleComponent } from '../../../shared/components/confirm-dialog-multiple/confirm-dialog-multiple.component';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { EpDocumentViewComponent } from '../../../shared/components/ep-document-view/ep-document-view.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { EventLogListComponent } from '../../action-log/event-log-list/event-log-list.component';
import { BankAccountViewComponent } from '../../bank-account/bank-account-view/bank-account-view.component';
import { BeneficiaryViewComponent } from '../../beneficiary/beneficiary-view/beneficiary-view.component';
import { ContractViewComponent } from '../../contract/contract-view/contract-view.component';
import { MerchantViewComponent } from '../../merchant/merchant-view/merchant-view.component';
import { SettlementModifyComponent } from '../settlement-modify/settlement-modify.component';
import { SettlementRequestComponent } from '../settlement-request/settlement-request.component';
import { SettlementViewGroupComponent } from '../settlement-view-group/settlement-view-group.component';
import { SettlementViewComponent } from '../settlement-view/settlement-view.component';
import { SettlementService } from '../shared/settlement.service';
import { SettlementUtilClass } from '../shared/settlementUtils';

@Component({
  selector: 'settlement-view-mini',
  templateUrl: './settlement-view-mini.component.html',
  styleUrls: ['./settlement-view-mini.component.css'],
})
export class SettlementViewMiniComponent implements OnInit {
  @Input()
  settlementID = null;

  @Output() close = new EventEmitter();

  @Input()
  showGroup = true;

  @Input()
  settlement = null;

  @Output() changedSettlements = new EventEmitter();

  isModal = false;

  settleUtils = new SettlementUtilClass();

  isPromoterOrAdmin = false;
  @Input()
  isExpanded=false
  constructor(
    private SettlementService: SettlementService,
    private authenticationService: AuthenticationService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data && typeof data == 'string') {
      this.settlementID = data;
      this.isModal = true;
    }
  }

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      if (params['settlementID']) {
        this.settlementID = params['settlementID'];
      }

      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isPromoterOrAdmin = res;

        this.setup();
      });
    });
  }

  splitStatus(s) {
    try {
      const result = s.split('/');

      return result;
    } catch (e) {
      return [];
    }
  }

  setup() {
    if (this.settlementID) {
      const payload = {
        selectDocument: true,
      };
      this.SettlementService.getSettlementDetails(this.settlementID, payload).subscribe((res) => {
        if (res) {
          this.settlement = res;
        }
      });
    }
  }

  closeModalEvent() {
    this.close.emit(true);
  }

  viewDocument() {
    this.SettlementService.getDocuments(
      this.settlement['ID'],
      ['ID', 'Label', 'Description', 'Extension', 'DateAdded'],
      this.isPromoterOrAdmin
    ).subscribe((res) => {
      if (res && res.length > 0) {
        const ref = this.dialog.open(EpDocumentViewComponent, {
          data: {
            document: res,
          },
          width: '800px',
          panelClass: 'noCard',
        });

        ref.componentInstance.closeModal.subscribe((res) => {
          ref.close();
        });

        ref.componentInstance.noFileFound.subscribe((res) => {
          NotifyAppComponent.displayToast('warning', 'Sorry!', 'There is no document attached to this settlement');
        });
      } else {
        NotifyAppComponent.displayToast('warning', 'Sorry!', 'There is no document attached to this settlement');
      }
    });
  }

  editSettlement() {
    const ref = this.dialog.open(SettlementModifyComponent, {
      data: this.settlement['ID'],
      width: '600px',
      panelClass: 'noCard',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });

    ref.componentInstance.modifiedSettlement.subscribe((res) => {
      if (res) {
        this.settlement = res;

        if (this.settlement && this.settlement.groupArray && this.settlement.groupArray.length > 0) {
          this.changedSettlements.emit(this.settlement.groupArray);
        }
      }
    });
  }

  openMerchantDialog() {
    const ref = this.dialog.open(MerchantViewComponent, {
      data: this.settlement['Merchant_key'],
      width: '650px',
    });
    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  openContractDialog() {
    const ref = this.dialog.open(ContractViewComponent, {
      data: {
        contractID: this.settlement['Contract_key'],
        isSimple: true,
      },
      width: '900px',
    });

    ref.componentInstance.closeModal.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  withdrawSettlement() {
    if (
      (this.settlement['ID'] && this.settlement['DrawDownGroupID'] == '') ||
      (this.settlement['ID'] && this.showGroup == false)
    ) {
      const confirm = new ConfirmDialog('block', '', 'Are you sure you want to withdraw this settlement?', 'No', 'Yes');

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation == true) {
          this.SettlementService.cancel(this.settlement['ID'], this.isPromoterOrAdmin).subscribe((res) => {
            if (res) {
              this.settlement = res;

              if (this.settlement && this.settlement.groupArray && this.settlement.groupArray.length > 0) {
                this.changedSettlements.emit(this.settlement.groupArray);
              }

              NotifyAppComponent.displayToast('success', 'Success!', 'Your Settlement has been withdrawn');

              ref.close();
            }
          });
        } else {
          ref.close();
        }
      });
    } else if (this.settlement['ID'] && this.settlement['DrawDownGroupID'] != '') {
      const confirm = new ConfirmDialog('block', '', 'Are you sure you want to withdraw this settlement?', 'No', 'Yes');

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation == true) {
          this.SettlementService.cancel(this.settlement['ID'], this.isPromoterOrAdmin, true).subscribe((res) => {
            if (res) {
              this.settlement = res;

              if (this.settlement && this.settlement.groupArray && this.settlement.groupArray.length > 0) {
                this.changedSettlements.emit(this.settlement.groupArray);
              }

              NotifyAppComponent.displayToast('success', 'Success!', 'Your Settlement has been withdrawn');
              ref.close();
            }
          });
        } else {
          ref.close();
        }
      });
    }

    // else if (this.settlement["ID"] && this.settlement["DrawDownGroupID"] != "" && this.showGroup == true) {

    //   var ref2 = RootAppComponent.dialog.open(ConfirmDialogMultipleComponent, {

    //     data:
    //     {
    //       icon: "block",
    //       description: "This settlement is part of a settlemennt group , you can either widthraw the selected one or the whole group .",
    //       yesLabel: "Yes, only this.",
    //       noLabel: "Cancel",
    //       yesSecondLabel: "Yes, for group.",
    //       informationLabel: "View settlement group",
    //       title: "Please choose one of the options below "

    //     },
    //     width: "500px"

    //   });

    //   ref2.componentInstance.confirmation.subscribe(confirmation => {
    //     if (confirmation == true) {
    //       this.settlementService.cancel(this.settlement["ID"], this.isPromoterOrAdmin, false).subscribe(res => {
    //         if (res) {

    //           this.settlement = res;

    //           if (this.settlement && this.settlement.groupArray && this.settlement.groupArray.length > 0) {
    //             this.changedSettlements.emit(this.settlement.groupArray);
    //           }

    //           NotifyAppComponent.displayToast(
    //             'success',
    //             'Success!',
    //             'Your Settlement has been withdrawn'
    //           );

    //           ref2.close();
    //         }
    //       })
    //     }

    //   });

    //   ref2.componentInstance.confirmationSecond.subscribe(confirmation => {
    //     if (confirmation == true) {
    //       this.settlementService.cancel(this.settlement["ID"], this.isPromoterOrAdmin, true).subscribe(res => {
    //         if (res) {

    //           this.settlement = res;

    //           if (this.settlement && this.settlement.groupArray && this.settlement.groupArray.length > 0) {
    //             this.changedSettlements.emit(this.settlement.groupArray);
    //           }

    //           NotifyAppComponent.displayToast(
    //             'success',
    //             'Success!',
    //             'Your Settlement has been withdrawn'
    //           );

    //           ref2.close();
    //         }
    //       })
    //     }

    //   });

    //   ref2.componentInstance.information.subscribe(information => {
    //     if (information == true) {
    //       this.viewSettlementGroup();
    //     }

    //   });

    // }
  }

  approveSettlement() {
    if (this.settlement['ID'] && this.settlement['DrawDownGroupID'] == '') {
      const confirm = new ConfirmDialog(
        'thumb_up_alt',
        '',
        'Are you sure you want to approve this settlement?',
        'No',
        'Yes'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation == true) {
          this.SettlementService.approve(this.settlement['ID'], false).subscribe((res) => {
            if (res) {
              this.settlement = res;

              if (this.settlement && this.settlement.groupArray && this.settlement.groupArray.length > 0) {
                this.changedSettlements.emit(this.settlement.groupArray);
              }

              NotifyAppComponent.displayToast('success', 'Success!', 'Your Settlement has been approved');

              ref.close();
            }
          });
        } else {
          ref.close();
        }
      });
    } else if (this.settlement['ID'] && this.settlement['DrawDownGroupID'] != '') {
      const ref2 = RootAppComponent.dialog.open(ConfirmDialogMultipleComponent, {
        data: {
          icon: 'thumb_down_alt',
          description: 'This settlement is part of a settlemennt group.',
          yesLabel: 'Yes, proceed.',
          noLabel: 'Cancel',
          isSecondConfirmation: false,
          informationLabel: 'View settlement group',
          title: 'Are you sure you want to approve this settlement group ?',
        },
        width: '500px',
      });

      ref2.componentInstance.confirmation.subscribe((confirmation) => {
        if (confirmation == true) {
          this.SettlementService.approve(this.settlement['ID'], true).subscribe((res) => {
            if (res) {
              this.settlement = res;

              if (this.settlement && this.settlement.groupArray && this.settlement.groupArray.length > 0) {
                this.changedSettlements.emit(this.settlement.groupArray);
              }

              NotifyAppComponent.displayToast('success', 'Success!', 'Your Settlement has been approved');

              ref2.close();
            }
          });
        }
      });
      ref2.componentInstance.information.subscribe((information) => {
        if (information == true) {
          this.viewSettlementGroup();
        }
      });
    }
  }

  declineSettlement() {
    if (this.settlement['ID'] && this.settlement['DrawDownGroupID'] == '') {
      const ref = RootAppComponent.dialog.open(ConfirmDialogMessageComponent, {
        data: {
          icon: 'thumb_down_alt',
          title: 'Are you sure you want to decline this settlement?',
          description: '',
          yesLabel: 'Yes,Proceed',
          noLabel: 'No',
          messageLabel: 'Please enter the reason',
        },
      });
      ref.componentInstance.closeModal.subscribe((res) => {
        ref.close();
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation && confirmation.confirm == true) {
          this.SettlementService.decline(this.settlement['ID'], confirmation.message, false).subscribe((res) => {
            if (res) {
              this.settlement = res;

              if (this.settlement && this.settlement.groupArray && this.settlement.groupArray.length > 0) {
                this.changedSettlements.emit(this.settlement.groupArray);
              }

              NotifyAppComponent.displayToast('success', 'Success!', 'Your Settlement has been declined');

              ref.close();
            }
          });
        } else {
          ref.close();
        }
      });
    } else if (this.settlement['ID'] && this.settlement['DrawDownGroupID'] != '') {
      const ref = RootAppComponent.dialog.open(ConfirmDialogMessageComponent, {
        data: {
          icon: 'thumb_down_alt',
          title: 'Are you sure you want to decline this settlement?',
          description: '',
          yesLabel: 'Yes,Proceed',
          noLabel: 'No',
          messageLabel: 'Please enter the reason',
        },
      });
      ref.componentInstance.closeModal.subscribe((res) => {
        ref.close();
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation && confirmation.confirm == true) {
          const ref2 = RootAppComponent.dialog.open(ConfirmDialogMultipleComponent, {
            data: {
              icon: 'thumb_down_alt',
              description: 'This settlement is part of a settlemennt group.',
              yesLabel: 'Yes, proceed.',
              noLabel: 'Cancel',
              isSecondConfirmation: false,
              informationLabel: 'View settlement group',
              title: 'Are you sure you want to decline this settlement group ?',
            },
            width: '500px',
          });

          ref2.componentInstance.confirmation.subscribe((confirmation) => {
            if (confirmation == true) {
              this.SettlementService.decline(this.settlement['ID'], confirmation.message, true).subscribe((res) => {
                if (res) {
                  this.settlement = res;

                  if (this.settlement && this.settlement.groupArray && this.settlement.groupArray.length > 0) {
                    this.changedSettlements.emit(this.settlement.groupArray);
                  }

                  NotifyAppComponent.displayToast('success', 'Success!', 'Your Settlement has been declined');

                  ref2.close();
                }
              });
            }
          });

          ref2.componentInstance.information.subscribe((information) => {
            if (information == true) {
              this.viewSettlementGroup();
            }
          });
        } else {
          ref.close();
        }
      });
    }
  }

  requestSettlement() {
    if (this.settlement['ID']) {
      const ref = RootAppComponent.dialog.open(SettlementRequestComponent, {
        data: this.settlement['ID'],
        width: '800px',
        panelClass: 'noCard',
      });

      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });

      ref.componentInstance.result.subscribe((res) => {
        if (res) {
          this.settlement = res;

          if (this.settlement && this.settlement.groupArray && this.settlement.groupArray.length > 0) {
            this.changedSettlements.emit(this.settlement.groupArray);
          }

          ref.close();
        }
      });
    }
  }

  voidSettlement() {
    if (this.settlement['ID']) {
      const confirm = new ConfirmDialog('delete', '', 'Are you sure you want to remove this settlement?', 'No', 'Yes');

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation == true) {
          this.SettlementService.void(this.settlement['ID'], this.isPromoterOrAdmin).subscribe((res) => {
            if (res) {
              this.settlement = res;

              if (this.settlement && this.settlement.groupArray && this.settlement.groupArray.length > 0) {
                this.changedSettlements.emit(this.settlement.groupArray);
              }

              NotifyAppComponent.displayToast('success', 'Success!', 'Your Settlement has been removed');

              ref.close();
            }
          });
        } else {
          ref.close();
        }
      });
    }
  }

  viewSettlementLogs() {
    const ref = this.dialog.open(EventLogListComponent, {
      data: {
        itemID: this.settlement['ID'],
      },
      width: '900px',
    });

    ref.componentInstance.closeModal.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  viewSettlementGroup() {
    if (this.settlement['DrawDownGroupID']) {
      const ref = this.dialog.open(SettlementViewGroupComponent, {
        data: {
          settlementGroupID: this.settlement['DrawDownGroupID'],
          title: 'Settlement Group',
          description: '',
        },
        width: '900px',
      });

      ref.componentInstance.close.subscribe((res) => {
        if (res == true) {
          ref.close();
        }
      });

      ref.componentInstance.changedSettlements.subscribe((res) => {
        if (res) {
          this.changedSettlements.emit(res);
        }
      });
    }
  }

  contractDetails() {
    this.close.emit(true);
    this.router.navigate(['/merchant', { outlets: { page: ['contract-view', this.settlement['Contract_key']] } }]);
  }

  viewSettlement() {
    const ref = RootAppComponent.dialog.open(SettlementViewComponent, {
      data: this.settlement['ID'],
      width: '850px',
      panelClass: 'noCard',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  clickHeader() {
    // if (this.settlement['Status.Code'] == 'PEND') {
    //   this.requestSettlement();
    // } else {
    //   this.viewSettlement();
    // }
  }

  viewBeneficiary() {
    if (this.settlement['SettleTo.Card_key']) {
      const ref = RootAppComponent.dialog.open(BeneficiaryViewComponent, {
        data: this.settlement['SettleTo.Card_key'],
        width: '850px',
      });

      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    }
  }

  viewBankAccount() {
    if (this.settlement['SettleTo.Account_key']) {
      const ref = RootAppComponent.dialog.open(BankAccountViewComponent, {
        data: this.settlement['SettleTo.Account_key'],
        width: '850px',
      });

      ref.componentInstance.closeModal.subscribe((res) => {
        ref.close();
      });
    }
  }
}
