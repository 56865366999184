import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';
import { AuthenticationService } from '../../../../common/core/authentication/shared/authentication.service';
import { FAQModalMerchantComponent } from '../../../../common/core/helper/FAQ-modal-merchant/faq-modal-merchant.component';
import { SimplePageViewComponent } from '../../../../common/core/helper/simple-page-view/simple-page-view.component';
import { TestimonyCreateEditComponent } from '../../../../common/feature/testimony/testimony-create-edit/testimony-create-edit.component';
import { RootAppComponent } from '../../../../common/shared/components/root-component/root-component.component';
import { HeaderService } from '../../../../common/shared/services/header.service';
import { Settings } from '../../../../common/shared/types/settings';

@Component({
  selector: 'app-merchant-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export class FooterMerchantComponent implements OnInit {
  languages = [];

  settings = Settings.global;
  selectedLanguage = 'en';
  isAdminOrPromoter = false;

  promoterViewAs = 'promoter';

  constructor(private authenticationService: AuthenticationService, private translate: TranslateService) {}

  ngOnInit() {
    this.languages = this.settings['availableLanguages'];
    this.selectedLanguage = this.settings['language'];

    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isAdminOrPromoter = res;

      if (this.isAdminOrPromoter == true) {
        HeaderService.getPromoterViewAsObject().subscribe((res) => {
          if (res && res.promoterViewAs) {
            this.promoterViewAs = res.promoterViewAs;
          }
        });
      }
    });
  }

  changeLang() {
    this.translate.use(this.selectedLanguage);
  }

  contactUs() {
    const data = {
      targetType: 'us',
      showBooking: true,
    };
    AuthenticationService.contactInputEvent.emit(data);
  }

  FAQ() {
    RootAppComponent.dialog.open(FAQModalMerchantComponent, {
      data: 'Frequent Asked Question',
      width: '800px',
    });
  }

  aboutUs() {
    RootAppComponent.dialog.open(SimplePageViewComponent, {
      data: 'aboutUs',
      width: '650px',
    });
  }

  credits() {
    RootAppComponent.dialog.open(SimplePageViewComponent, {
      data: 'credits',
      width: '650px',
    });
  }

  rateUs() {
    const ref = RootAppComponent.dialog.open(TestimonyCreateEditComponent, {
      width: '650px',
      panelClass: 'rm-bp',
    });
    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  openPublic() {
    window.open(this.settings['publicSiteFrontendLink'], '_blank');
  }

  openMailTo() {
    window.location.href = 'mailto:' + this.settings['contactEmail'];
    // window.open(this.settings['contactEmail'], "_blank");
  }

  goToADA() {
    const newWindow = window.open('https://www.ada.org.au', '_blank', 'noopener');
  }

  goToADIA() {
    const newWindow = window.open('https://www.adia.org.au', '_blank', 'noopener');
  }

  getFileLink(fileID) {
    return environment.nodeUrl + '/files/file-view/' + fileID;
  }
}
