import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Settings } from '../../../shared/types/settings';

@Component({
  selector: 'app-marketing-template-gallery',
  templateUrl: './marketing-template-gallery.component.html',
  styleUrls: ['./marketing-template-gallery.component.css'],
})
export class MarketingTemplateGalleryComponent implements OnInit {
  @Input()
  isBusiness = false;

  @Input()
  isBusinessOrDefault = false;

  @Input()
  isMerchantTemplate = null;

  @Input()
  isManualCreatedOrDefault = true;

  @Input()
  isManualCreated = false;

  @Input()
  isCampaign = true;

  @Input()
  isDefault = true;

  @Input()
  isActive = true;

  @Input()
  isForMerchant = false;

  @Input()
  actionLabel = 'Create New Campaign';

  @Input()
  title = 'Email and SMS Campaigns';

  @Input()
  description = 'This is the Campaign Template List';

  @Output()
  selectTemplate = new EventEmitter();

  @Output()
  proceed = new EventEmitter();

  @Input()
  isRedirect = true;

  emailLabel = 'Preview Email';

  smsLabel = '';

  @Input()
  merchantID;

  @Input()
  templateID;

  @Input()
  isPrint = false;

  @Input()
  isProceed = true;

  @Input()
  isDownload = false;

  @Input()
  isMerchantCampaign = false;

  @Input()
  isPatientCampaign = false;

  templateLoaded = false;

  constructor(private router: Router) {}

  ngOnInit() {
    this.isManualCreatedOrDefault = Settings.global['MarketingTemplateMasterAndManual'];

    this.isManualCreated = Settings.global['MarketingManual'];

    this.isCampaign = Settings.global['MarketingIsCampaign'];

    this.isDefault = Settings.global['MarketingTemplateDefault'];

    this.templateLoaded = true;
  }

  actionProceedEvent(e) {
    if (this.isRedirect == true) {
      if (e && e['ID']) {
        this.router.navigate(['/merchant', { outlets: { page: ['marketing-campaign-create', e['ID']] } }]);
      }
    } else {
      if (e && e['MetaData'] && e['MetaData']['Meta_Data)']) {
        e['MetaData'] = e['MetaData']['Meta_Data)'];
      }
      this.proceed.emit(e);
    }
  }

  selectTemplateEvent(e) {
    if (e) {
      if (e && e['MetaData'] && e['MetaData']['Meta_Data)']) {
        e['MetaData'] = e['MetaData']['Meta_Data)'];
      }
      this.selectTemplate.emit(e);
    }
  }
}
