import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { AppointmentCreateCustomerComponent } from '../../../feature/appointment/appointment-create-customer/appointment-create-customer.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { HeaderService } from '../../../shared/services/header.service';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';

@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.css'],
})
export class ThankYouComponent implements OnInit {
  @Input()
  typeOfCard = 'thankyou';

  settings = Settings.global;

  appointment = false;

  @Input()
  invitationID;

  @Input()
  subjectRequestInvitation = 'Request new invitation';

  @Input()
  firstName = null;

  @Input()
  title = 'Error! The page you are looking for can not be found';

  @Input()
  merchantPhone = this.settings['contactPhone'];

  @Input()
  merchantEmail = this.settings['contactEmail'];

  @Input()
  merchantName = null;

  @Input()
  dentistPhone = this.settings['contactPhone'];

  @Input()
  dentistEmail = this.settings['contactEmail'];

  @Input()
  dentistName = null;

  @Input()
  dentistAppointmentUrl;

  @Input()
  hasBookedAppointment;

  @Input()
  weeks = 'One Week';

  @Input()
  merchantID;

  @Input()
  dentistID;

  @Input()
  displayContact = false;

  @Input()
  displayViewTreatment = true;

  @Input()
  productName;

  @Input()
  isProceed = false;

  @Input()
  statusDate;

  @Input()
  canLogin = true;
  @Input()
  isCountDown = true;

  loginLabel = 'Login';

  sessionType = 'guest';
  isLogin = false;

  @Output()
  proceedToWakanda = new EventEmitter();

  @Output()
  viewTreatment = new EventEmitter();
  countdown: number;
  subscribe;
  redirection = false;
  hideCustomerPortal = false;

  constructor(private authenticationService: AuthenticationService) {}

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((res) => {
      this.sessionType = res;
    });
    AuthenticationService.loginLabel.subscribe((res) => {
      if (res) {
        this.loginLabel = res;
      }
    });

    AuthenticationService.stopWakandaCountDown.subscribe((res) => {
      this.stopCountDown();
    });

    HeaderService.hideCustomerPortal.subscribe((res) => {
      this.hideCustomerPortal = res;
    });

    if (this.typeOfCard == 'commenced') {
      this.isProceed = true;
    } else if (this.typeOfCard == 'approved') {
      this.isProceed = true;
    } else if (this.typeOfCard == 'palningToCommence') {
      this.isProceed = true;
    }

    if (this.isProceed == true && this.isCountDown) {
      this.startCountdownTimer();
    }
  }

  contactUs() {
    this.stopCountDown();
    AuthenticationService.contactUSEvent.emit();
  }

  makeAppointment() {
    this.stopCountDown();
    if (this.dentistAppointmentUrl) {
      window.open(this.dentistAppointmentUrl, '_blank');
    } else if (!this.hasBookedAppointment) {
      const refAppointment = RootAppComponent.dialog.open(AppointmentCreateCustomerComponent, {
        data: {
          invitationID: this.invitationID,
          cancelLabel: 'Close',
          submitLabel: 'Submit',
          displayReasonForVisiting: false,
          isApplicationProceed: true,
        },
        width: '800px',
        panelClass: 'noCard',
      });

      refAppointment.componentInstance.close.subscribe((res) => {
        refAppointment.close();
      });
      refAppointment.componentInstance.result.subscribe((res) => {
        if (res && res.ID) {
          this.hasBookedAppointment = true;
          refAppointment.close();

          NotifyAppComponent.displayToast(
            'success',
            'Successful operation',
            'Appointment is now booked for this treatment'
          );
        }
      });
    }
  }

  viewDentist() {
    this.stopCountDown();
    AuthenticationService.viewDentist.emit(this.dentistID);
  }

  viewMerchant() {
    this.stopCountDown();
    AuthenticationService.viewMerchant.emit(this.merchantID);
  }

  contactMerchant(subject = null) {
    this.stopCountDown();
    const data = {
      invitationID: this.invitationID,
      targetType: 'merchant',
      targetID: this.merchantID,
      asGuest: true,
      asProfile: false,
      asPractice: false,
      subject: subject || null,
    };
    AuthenticationService.contactInputEvent.emit(data);
  }

  contactDentist() {
    this.stopCountDown();

    const data = {
      invitationID: this.invitationID,
      targetType: 'contact',
      targetID: this.dentistID,
      asGuest: true,
      asProfile: false,
      asPractice: false,
    };
    AuthenticationService.contactInputEvent.emit(data);
  }

  dial(phone) {
    this.stopCountDown();
    window.location.href = 'tel:' + phone;
  }

  email(email) {
    this.stopCountDown();
    window.location.href = 'mailto:' + email;
  }

  viewTreatmentEvent() {
    this.viewTreatment.emit(true);
    this.stopCountDown();
  }

  goWakanda() {
    this.proceedToWakanda.emit(true);
    this.stopCountDown();
  }

  loginEvent() {
    AuthenticationService.customerLogin.emit(true);

    this.stopCountDown();
  }

  startCountdownTimer() {
    this.countdown = 0;
    const interval = 1000;
    const duration = 60 * 1000;
    const stream$ = Observable.timer(0, interval)
      .finally(() => {
        if (this.redirection == true) {
          this.goWakanda();
        }
      })
      .takeUntil(Observable.timer(duration + interval))
      .map((value) => duration - value * interval);
    this.subscribe = stream$.subscribe((value) => (this.countdown = value));
  }

  ngOnDestroy() {
    this.stopCountDown();
  }

  stopCountDown() {
    this.countdown = 0;
    this.redirection = false;
    if (this.subscribe) {
      this.subscribe.unsubscribe();
    }
  }
}
