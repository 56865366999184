<div class="container">
  <div class="row clearfix">
    <div class="pull-left">
      <h1 class="sm-h1">Settlement Overview</h1>
    </div>
    <div class="pull-right">
      <button class="mt" id="beginNewSettlement" (click)="openPatientLookUpDialog()" mat-raised-button color="primary">
        New Settlement
      </button>
    </div>
  </div>
  <mat-tab-group dynamicHeight="true">
    <!-- <mat-tab-group [dynamicHeight]="true"> -->
    <mat-tab id="tool1" [label]="('KEYWORD.dental' | translate | titlecase) + ' Settlements'">
      <div class="row clearfix">
        <mat-card class="chartsCard card">
          <div class="row clearfix flex">
            <div class="thrd-width grey-col">
              <div class="padded" id="tool2">
                <mat-button-toggle-group
                  class="thrd-width pull-right btn-group sml-group text-center"
                  id="group1"
                  #group1="matButtonToggleGroup"
                  [(ngModel)]="chart1type"
                >
                  <mat-button-toggle class="btn-small" value="1"> Requests</mat-button-toggle>
                  <mat-button-toggle class="btn-small" value="2"> Revenue</mat-button-toggle>
                </mat-button-toggle-group>
                <div class="row clearfix mb">
                  <!-- <app-chart [chart]="reqSetChart" *ngIf="group1.value == 1"> </app-chart>
                <app-chart [chart]="currBillingChart" *ngIf="group1.value == 2"> </app-chart> -->
                </div>
              </div>
            </div>
            <div class="thrd-width">
              <div class="padded" id="tool3">
                <mat-button-toggle-group
                  class="thrd-width pull-right btn-group sml-group text-center"
                  id="group2"
                  #group2="matButtonToggleGroup"
                  [(ngModel)]="chart2type"
                >
                  <mat-button-toggle class="btn-small" value="1"> Requests</mat-button-toggle>
                  <mat-button-toggle class="btn-small" value="2"> Revenue</mat-button-toggle>
                </mat-button-toggle-group>
                <div class="row clearfix mb">
                  <!-- <app-chart [chart]="reqLastWkSetChart" *ngIf="group2.value == 1"> </app-chart>
                <app-chart [chart]="lastBillingChart" *ngIf="group2.value == 2"> </app-chart> -->
                </div>
              </div>
            </div>
            <div class="thrd-width" id="tool4">
              <div class="padded">
                <mat-button-toggle-group
                  class="thrd-width pull-right btn-group sml-group text-center"
                  #group3="matButtonToggleGroup"
                  [(ngModel)]="chart3type"
                >
                  <mat-button-toggle class="btn-small" value="1"> Requests</mat-button-toggle>
                  <mat-button-toggle class="btn-small" value="2"> Revenue</mat-button-toggle>
                </mat-button-toggle-group>
                <div class="row clearfix mb">
                  <!-- <app-chart [chart]="reqTotalSetChart" *ngIf="group3.value == 1"> </app-chart>
                <app-chart [chart]="yearToDateChart" *ngIf="group3.value == 2"> </app-chart> -->
                </div>
              </div>
            </div>
          </div>
        </mat-card>
      </div>
    </mat-tab>
    <mat-tab id="mat-tab-label-1-1" label="Membership Settlements">
      <div class="row clearfix">
        <mat-card class="chartsCard card">
          <div class="row clearfix">
            <div class="thrd-width grey-col">
              <div class="padded">
                <mat-button-toggle-group
                  class="thrd-width pull-right btn-group sml-group text-center"
                  #group1="matButtonToggleGroup"
                  [(ngModel)]="chart1type"
                >
                  <mat-button-toggle class="btn-small" value="1"> Requests</mat-button-toggle>
                  <mat-button-toggle class="btn-small" value="2"> Revenue</mat-button-toggle>
                </mat-button-toggle-group>
                <div class="row clearfix mb">
                  <!-- <app-chart [chart]="reqSetChart" *ngIf="group1.value == 1"> </app-chart>
                <app-chart [chart]="currBillingChart" *ngIf="group1.value == 2"> </app-chart> -->
                </div>
              </div>
            </div>
            <div class="thrd-width">
              <div class="padded">
                <mat-button-toggle-group
                  class="thrd-width pull-right btn-group sml-group text-center"
                  #group2="matButtonToggleGroup"
                  [(ngModel)]="chart2type"
                >
                  <mat-button-toggle class="btn-small" value="1"> Requests</mat-button-toggle>
                  <mat-button-toggle class="btn-small" value="2"> Revenue</mat-button-toggle>
                </mat-button-toggle-group>
                <div class="row clearfix mb">
                  <!-- <app-chart [chart]="reqLastWkSetChart" *ngIf="group2.value == 1"> </app-chart>
                <app-chart [chart]="lastBillingChart" *ngIf="group2.value == 2"> </app-chart> -->
                </div>
              </div>
            </div>
            <div class="thrd-width">
              <div class="padded">
                <mat-button-toggle-group
                  class="thrd-width pull-right btn-group sml-group text-center"
                  #group3="matButtonToggleGroup"
                  [(ngModel)]="chart3type"
                >
                  <mat-button-toggle class="btn-small" value="1"> Requests</mat-button-toggle>
                  <mat-button-toggle class="btn-small" value="2"> Revenue</mat-button-toggle>
                </mat-button-toggle-group>
                <div class="row clearfix mb">
                  <!-- <app-chart [chart]="reqTotalSetChart" *ngIf="group3.value == 1"> </app-chart>
                <app-chart [chart]="yearToDateChart" *ngIf="group3.value == 2"> </app-chart> -->
                </div>
              </div>
            </div>
          </div>
        </mat-card>
      </div>
    </mat-tab>
  </mat-tab-group>
  <div class="row clearfix">
    <mat-card class="statisticsBlock card flex">
      <div class="row clearfix">
        <div class="pull-left">
          <!--  Billing period FROM and TO -->
          <p class="small period">Billing period: <span>00 Month 2017</span> - <span>00 Month 2017</span></p>
        </div>
        <div class="pull-right">
          <button class="btn-small btn-inverse" id="sendReport" mat-button>Send Report Email</button>
        </div>
      </div>
    </mat-card>
  </div>
</div>
