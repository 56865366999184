<mat-card
  class="rel treatmentSaved clearfix mb"
  *ngIf="treatment"
  @ngIfAnimation
  [class.isModal]="isModal == true"
  [class.mt]="isModal == false"
>
  <mat-dialog-content [ngClass]="{ 'no-modal-height': isModal != true }">
    <div class="card-header primary-gradient-img clearfix inModal rel">
      <div class="row clearfix">
        <button class="btn-close btn-clear mat-button white" *ngIf="isModal == true" (click)="closeModalEvent()">
          <mat-icon>clear</mat-icon>
        </button>
        <div class="row titleArea clearfix">
          <mat-icon class="pull-left fas {{'fa-tooth' | translate}}"></mat-icon>
          <h2 class="summary-header rm-m">
            {{ treatment['label'] }}

            <span class="subLabel" *ngIf="treatment['description']"> {{ treatment['description'] }}</span>
          </h2>
        </div>
      </div>

      <div class="date-chip-area">
        <p class="small rm-m mr" *ngIf="treatment.updated_at">
          <mat-icon class="fas fa-clock"></mat-icon>
          {{ treatment.updated_at | TimeLabelPast }} ago
        </p>

        <p
          class="rm-m mr"
          *ngIf="treatment.applyCount >= 0"
          matTooltip="This Template has been applied {{ treatment.applyCount }} Times"
        >
          <mat-icon class="fas fa-chart-line"></mat-icon>
          <span class="badge mat-elevation-z1" [endVal]="treatment.applyCount || 0" countUp></span>
        </p>

        <p class="clearfix small rm-m" *ngIf="treatment['productGroupLabel']">
          <span class="chip-enable mat-elevation-z1">{{ treatment['productGroupLabel'] }}</span>
        </p>

        <p class="clearfix small rm-m" *ngIf="treatment['isActive'] == '1'">
          <span class="chip-enable mat-elevation-z1">Active</span>
        </p>
        <p class="clearfix small rm-m" *ngIf="treatment['isActive'] == '0'">
          <span class="chip-disable mat-elevation-z1">Not active</span>
        </p>

        <p class="clearfix small rm-m" *ngIf="treatment['type']">
          <span class="chip-practice mat-elevation-z1" [class.blue]="getTypeLabel(treatment['type']) == 'Smile Right'">
            <mat-icon class="fas {{'fa-tooth' | translate}}" *ngIf="getTypeLabel(treatment['type']) == 'Smile Right'"></mat-icon>
            <mat-icon class="fas fa-user" *ngIf="getTypeLabel(treatment['type']) != 'Smile Right'"></mat-icon>

            {{ getTypeLabel(treatment['type']) | ucfirst }}
          </span>
        </p>
      </div>
      <div class="action-button pull-right">
        <button
          class="pull-right"
          *ngIf="
            treatment && (treatment.type != 'default' || (treatment.type == 'default' && isPromoterOrAdmin == true))
          "
          [matMenuTriggerFor]="menu"
          mat-raised-button
        >
          Actions <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button
            *ngIf="
              treatment && (treatment.type != 'default' || (treatment.type == 'default' && isPromoterOrAdmin == true))
            "
            (click)="edit()"
            mat-menu-item
          >
            <mat-icon>edit</mat-icon>
            <span>Edit Template</span>
          </button>

          <button
            *ngIf="
              treatment['isActive'] == true &&
              (treatment.type != 'default' || (treatment.type == 'default' && isPromoterOrAdmin == true))
            "
            (click)="disable()"
            mat-menu-item
          >
            <mat-icon>cancel</mat-icon>
            <span>Disable</span>
          </button>

          <button
            *ngIf="
              treatment['isActive'] != true &&
              (treatment.type != 'default' || (treatment.type == 'default' && isPromoterOrAdmin == true))
            "
            (click)="enable()"
            mat-menu-item
          >
            <mat-icon>check_circle</mat-icon>
            <span>Enable</span>
          </button>

          <button
            *ngIf="treatment.userID && isPromoterOrAdmin == true"
            (click)="viewMerchant(treatment.userID)"
            mat-menu-item
          >
            <mat-icon>person</mat-icon>
            <span>View Merchant</span>
          </button>

          <button
            *ngIf="treatment.type != 'default' || (treatment.type == 'default' && isPromoterOrAdmin == true)"
            (click)="remove()"
            mat-menu-item
          >
            <mat-icon>delete</mat-icon>
            <span>Remove</span>
          </button>
        </mat-menu>
      </div>
    </div>

    <div *ngIf="treatment && treatment.content">
      <div class="row clearfix">
        <div class="column clearfix flex">
          <div class="icons-col">
            <mat-icon class="fas fa-money-bill-wave" *ngIf="treatmentAmount" aria-hidden="true"></mat-icon>
          </div>
          <div class="row-content-col">
            <div class="half-width" *ngIf="treatmentAmount">
              <h3 class="sr-title rm-mb">{{ "KEYWORD.treatment" | translate | titlecase }} Value</h3>
              <p class="rm-mt larger op09">{{ treatmentAmount | customCurrency }}</p>
            </div>

            <div class="half-width" *ngIf="treatment.content.cashDiscountOffered == true">
              <h3 class="sr-title rm-mb" *ngIf="cashDiscountOffered">
                Cash discount if paid in full
              </h3>
              <p class="rm-mt larger op09" *ngIf="cashDiscountOffered">{{ cashDiscountAmount | customCurrency }}</p>

              <!-- <p class="small rm-mt" *ngIf="cashDiscountDate"><strong>Expiry:</strong> {{cashDiscountDate | customDate}}
              </p> -->
            </div>
          </div>
        </div>

        <hr/>
      </div>

      <div class="row clearfix" *ngIf="treatment.content" style="margin: 0px auto; width: 100%; max-width: 630px">
        <div style="display: flex; flex-wrap: wrap">
          <div style="display: flex">
            <div style="width: 50px; margin-top: 23px; margin-left: 20px; color: #9a9a9a">
              <mat-icon
                class="fas fa-money-bill-wave mat-primary"
                *ngIf="treatment.content.cashDiscountOffered"
                aria-hidden="true"
              ></mat-icon>
              <mat-icon
                class="fas fa-money-bill-wave"
                *ngIf="!treatment.content.cashDiscountOffered"
                aria-hidden="true"
              ></mat-icon>
            </div>

            <div style="margin-top: 23px; margin-right: 5px">
              <mat-checkbox
                class="mat-primary"
                *ngIf="treatment.content.cashDiscountOffered"
                [checked]="treatment.content.cashDiscountOffered"
                [class.mat-checkbox-disabled]="false"
                color="primary"
                disabled
              >
              </mat-checkbox>

              <mat-checkbox
                *ngIf="!treatment.content.cashDiscountOffered"
                [checked]="treatment.content.cashDiscountOffered"
                color="primary"
                disabled
              >
              </mat-checkbox>
            </div>

            <div>
              <h3 class="sr-title sm-h3 rm-mt mt rm-mb mb pull-left" *ngIf="treatment.content.cashDiscountOffered">
                Cash Discount
              </h3>
              <h3
                class="sr-title sm-h3 rm-mt mt rm-mb mb pull-left"
                *ngIf="!treatment.content.cashDiscountOffered"
                style="color: #9a9a9a"
              >
                Cash Discount
              </h3>
            </div>
          </div>

          <div style="display: flex">
            <div style="width: 45px; margin-top: 20px; color: #9a9a9a; margin-left: 25px">
              <mat-icon
                class="fas fas fa-notes-medical mat-primary"
                *ngIf="treatment.content.medicalHistory"
                aria-hidden="true"
              ></mat-icon>
              <mat-icon class="fas fas fa-notes-medical" *ngIf="!treatment.content.medicalHistory" aria-hidden="true">
              </mat-icon>
            </div>

            <div style="margin-top: 23px; margin-right: 5px">
              <mat-checkbox
                class="mat-primary"
                *ngIf="treatment.content.medicalHistory"
                [checked]="treatment.content.medicalHistory"
                [class.mat-checkbox-disabled]="false"
                color="primary"
                disabled
              >
              </mat-checkbox>

              <mat-checkbox
                *ngIf="!treatment.content.medicalHistory"
                [checked]="treatment.content.medicalHistory"
                color="primary"
                disabled
              >
              </mat-checkbox>
            </div>

            <div>
              <h3 class="sr-title sm-h3 rm-mt mt rm-mb mb pull-left" *ngIf="treatment.content.medicalHistory">
                Health History
              </h3>
              <h3
                class="sr-title sm-h3 rm-mt mt rm-mb mb pull-left"
                *ngIf="!treatment.content.medicalHistory"
                style="color: #9a9a9a"
              >
                Health History
              </h3>
            </div>
          </div>

          <div style="display: flex">
            <div style="width: 50px; margin-top: 20px; color: #9a9a9a; margin-left: 20px">
              <mat-icon
                class="fas fas fa-star mat-primary"
                *ngIf="treatment.content.googleReviewPrompt"
                aria-hidden="true"
              ></mat-icon>
              <mat-icon class="fas fas fa-star" *ngIf="!treatment.content.googleReviewPrompt" aria-hidden="true">
              </mat-icon>
            </div>

            <div style="margin-top: 23px; margin-right: 5px">
              <mat-checkbox
                class="mat-primary"
                *ngIf="treatment.content.googleReviewPrompt"
                [checked]="treatment.content.googleReviewPrompt"
                [class.mat-checkbox-disabled]="false"
                color="primary"
                disabled
              >
              </mat-checkbox>

              <mat-checkbox
                *ngIf="!treatment.content.googleReviewPrompt"
                [checked]="treatment.content.googleReviewPrompt"
                color="primary"
                disabled
              >
              </mat-checkbox>
            </div>

            <div>
              <h3 class="sr-title sm-h3 rm-mt mt rm-mb mb pull-left" *ngIf="treatment.content.googleReviewPrompt">
                Google Review
              </h3>
              <h3
                class="sr-title sm-h3 rm-mt mt rm-mb mb pull-left"
                *ngIf="!treatment.content.googleReviewPrompt"
                style="color: #9a9a9a"
              >
                Google Review
              </h3>
            </div>
          </div>
        </div>
      </div>

      <hr/>

      <div
        class="row clearfix"
        *ngIf="treatment.content && treatment.content.message && treatment.content.message.contentRaw"
      >
        <div class="column clearfix flex" *ngIf="message">
          <div class="icons-col">
            <mat-icon class="fas fa-comments" aria-hidden="true"></mat-icon>
          </div>
          <div class="row-content-col">
            <div class="row clearfix">
              <h3 class="sr-title rm-mb">Message to {{"KEYWORD.patient" | translate}}</h3>
              <!-- <div class="reduce-marg" [innerHTML]="message | safeHtml"></div> -->
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Message</mat-label>
                <textarea
                  class="dentMsg increase-input-field"
                  [(ngModel)]="message"
                  name="dentMsg"
                  matInput
                  cdkTextareaAutosize
                  placeholder="Enter your message here..."
                  readonly
                ></textarea>
              </mat-form-field>
            </div>

            <div class="row clearfix flex full-width wrapped mt">
              <div class="block half-width" *ngIf="treatment.content.message.senderLabel">
                <p class="small rm-m">Sender</p>
                <h5 class="sr-title reduce-marg">{{ treatment.content.message.senderLabel }}</h5>
              </div>

              <div class="block half-width" *ngIf="treatment.content.message.viaSMS != null">
                <p class="small rm-m">Send SMS</p>
                <h5 class="sr-title reduce-marg" *ngIf="treatment.content.message.viaSMS == true">Yes</h5>

                <h5 class="sr-title reduce-marg" *ngIf="treatment.content.message.viaSMS != true">No</h5>
              </div>
            </div>
          </div>
        </div>

        <hr *ngIf="message"/>
      </div>

      <div
        class="clearfix row"
        *ngIf="treatment.content.treatmentDocuments && treatment.content.treatmentDocuments.length > 0"
      >
        <div class="column clearfix flex">
          <div class="icons-col">
            <mat-icon class="fas fa-file-alt" aria-hidden="true"></mat-icon>
          </div>
          <div class="row-content-col">
            <h3 class="sr-title rm-mb"> {{"KEYWORD.Treatment" | translate}} Documents</h3>

            <app-file-list
              class="clearfix full-width"
              [files]="treatment.content.treatmentDocuments"
              [canEdit]="false"
              [justify]="'left'"
              [canAction]="false"
              [title]="'Attached Documents'"
            >
            </app-file-list>

            <app-ep-document-list
              [documents]="treatment.content.EPTreatmentDocuments"
              [canEdit]="false"
              [justify]="'left'"
              [canAction]="false"
              [title]="'Gallery Documents'"
              [canCheck]="false"
              [listViewType]="'thumbnails'"
              [isDownload]="isDownload"
              [isPrint]="isPrint"
              [defaultCheck]="true"
              [isRedirection]="isRedirection"
            >
            </app-ep-document-list>
          </div>
        </div>
        <hr/>
      </div>

      <div
        class="clearfix row"
        *ngIf="
          (treatment.content.stockArtDocuments && treatment.content.stockArtDocuments.length > 0) ||
          (treatment.content.EPstockArtDocuments && treatment.content.EPstockArtDocuments.length > 0)
        "
      >
        <div class="column clearfix flex">
          <div class="icons-col">
            <mat-icon class="fas fa-images" aria-hidden="true"></mat-icon>
          </div>
          <div class="row-content-col">
            <h3 class="sr-title rm-mb"> {{"KEYWORD.Treatment" | translate}} Images</h3>

            <app-file-list
              class="clearfix full-width"
              [files]="treatment.content.stockArtDocuments"
              [canEdit]="false"
              [justify]="'left'"
              [canAction]="false"
              [title]="'Attached Images'"
            >
            </app-file-list>

            <app-ep-document-list
              [documents]="treatment.content.EPstockArtDocuments"
              [canEdit]="false"
              [justify]="'left'"
              [canAction]="false"
              [title]="'Gallery Images'"
              [canCheck]="false"
              [listViewType]="'thumbnails'"
              [isDownload]="isDownload"
              [isPrint]="isPrint"
              [defaultCheck]="true"
              [isRedirection]="isRedirection"
            >
            </app-ep-document-list>
          </div>
        </div>
        <hr/>
      </div>

      <div
        class="clearfix row mt"
        *ngIf="treatment.content.treatmentPlan && treatment.content.treatmentPlan.length > 0"
      >
        <app-treatment-informed-consent-view
          *ngFor="let t of treatment.content.treatmentPlan"
          [justSelected]="true"
          [productID]="t.productID"
          [merchantID]="merchantID"
          [preSelectedDocuments]="t.preDocuments"
          [isMiniList]="true"
          [displayStockArtDocuments]="false"
          [postSelectedDocuments]="t.postDocuments"
          [marketingSelectedDocuments]="t.marketingDocuments"
        >
        </app-treatment-informed-consent-view>
      </div>
    </div>
  </mat-dialog-content>

  <div class="drop-button" *ngIf="isModal == true">
    <button class="step-next" (click)="closeModalEvent()" mat-raised-button color="primary">Close</button>
  </div>
</mat-card>

<div class="container navi text-left mt" *ngIf="isModal != true">
  <button class="" (click)="cancel()" mat-mini-fab color="primary">
    <mat-icon>chevron_left</mat-icon>
  </button>
</div>
