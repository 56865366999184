import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';

import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { MatPaginator } from '@angular/material/paginator';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';

import { MatTableDataSource } from '@angular/material';
import { MerchantService } from '../../merchant/shared/merchant.service';
import { DentistryTypeCreateComponent } from '../dentistry-type-create/dentistry-type-create.component';
import { DentistryTypeEditComponent } from '../dentistry-type-edit/dentistry-type-edit.component';

@Component({
  selector: 'app-dentistry-types-list',
  templateUrl: './dentistry-types-list.component.html',
  styleUrls: ['./dentistry-types-list.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.row', style({ opacity: 0 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.row', style({ opacity: 1 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class DentistryTypesListComponent implements OnInit {
  @Input()
  pageSize = Settings.global['listPageSize'] || 20;
  pageSizeOptions = [10, Number(this.pageSize), Number(this.pageSize) * 2, Number(this.pageSize) * 3];

  displayedColumns = ['Label', 'Description', 'Actions'];

  dentistryTypes = [];

  datasource: MatTableDataSource<any>;

  firstLoadEvent = false;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('filter', { static: false }) filter: ElementRef;

  constructor(private merchantService: MerchantService) {}

  ngOnInit() {
    this.merchantService.getDentistryTypesList({}).subscribe((data) => {
      this.firstLoadEvent = true;
      this.dentistryTypes = data.sort((a, b) => (a.Label > b.Label ? 1 : -1));
      this.datasource = new MatTableDataSource(this.dentistryTypes);
      this.datasource.paginator = this.paginator;
    });
  }

  openCreateDentistryTypeDialog() {
    const ref = RootAppComponent.dialog.open(DentistryTypeCreateComponent, { width: '600px' });

    ref.componentInstance.create.subscribe((dentistryType) => {
      this.merchantService.createDentistryType(dentistryType).subscribe((result) => {
        if (result) {
          this.dentistryTypes.push(...result);

          const dentistryTypesSorted = this.dentistryTypes.sort((a, b) => (a.Label > b.Label ? 1 : -1));

          this.datasource.data = [...dentistryTypesSorted];

          NotifyAppComponent.displayToast(
            'success',
            'Successful operation',
            'The dentistry type has been successfully created'
          );

          ref.close();
        }
      });
    });
  }

  openEditDentistryTypeDialog(dentistryType) {
    const ref = RootAppComponent.dialog.open(DentistryTypeEditComponent, {
      data: {
        ID: dentistryType.ID,
        Label: dentistryType.Label,
        Description: dentistryType.Description,
      },
      width: '600px',
    });

    ref.componentInstance.update.subscribe((dentistryTypeResult) => {
      this.merchantService.updateDentistryType(dentistryTypeResult).subscribe((result) => {
        if (result) {
          const updatedIndex = this.dentistryTypes.findIndex(
            (oldDentistryType) => oldDentistryType.ID === dentistryTypeResult.ID
          );

          if (updatedIndex > -1) {
            this.dentistryTypes[updatedIndex] = dentistryTypeResult;

            const dentistryTypeSorted = this.dentistryTypes.sort((a, b) => (a.Label > b.Label ? 1 : -1));

            this.datasource.data = [...dentistryTypeSorted];
          }

          NotifyAppComponent.displayToast(
            'success',
            'Successful operation',
            'The dentistry type has been successfully edited'
          );

          ref.close();
        }
      });
    });
  }

  openDeleteDentistryTypeDialog(id) {
    const confirm = new ConfirmDialog(
      'delete',
      '',
      'Are you sure you want to delete this dentistry type?',
      'No',
      'Yes'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, { data: confirm });

    ref.componentInstance.onConfirm.subscribe((val) => {
      if (val == true) {
        this.merchantService.deleteDentistryType(id).subscribe((result) => {
          if (result) {
            const updatedIndex = this.dentistryTypes.findIndex((oldDentistryType) => oldDentistryType.ID === id);

            if (updatedIndex > -1) {
              this.dentistryTypes.splice(updatedIndex, 1);

              this.datasource.data = [...this.dentistryTypes];
            }

            NotifyAppComponent.displayToast(
              'success',
              'Successful operation',
              'The dentistry type has been successfully deleted'
            );

            ref.close();
          }
        });
      } else {
        ref.close();
      }
    });
  }
}
