<div class="rel">
  <div class="card-header primary-gradient-img clearfix confirmModal">
    <button class="btn-close btn-clear mat-button smt white custom-close" (click)="closeEvent()">
      <mat-icon>clear</mat-icon>
    </button>
    <div class="full-width flex main-title-container">
      <app-ep-document-thumbnail-view class="gallery-card-support2 pull-left mr" [hasIcon]="false"
        [documentID]="documentID" [hideZoomButton]="false">
      </app-ep-document-thumbnail-view>

      <div class="row full-width title-container clearfix">
        <div class="title-container-sub">
          <h2 class="text-left title-header rm-m">
            {{ title }}
            <span class="subLabel" *ngIf="description">{{ description }}</span>
            <span class="subLabel" *ngIf="extension">.{{ extension }}</span>
          </h2>
        </div>
      </div>
    </div>
  </div>

  <mat-dialog-content class="preview-editor-document">
    <div class="full-width all-container">
      <app-ep-document-view class="full-width gallery-content-container-modal" [isModal]="false" [contentOnly]="true"
        [documentID]="documentID">
      </app-ep-document-view>
    </div>
  </mat-dialog-content>
  <hr style="margin-top: 0px" />
  <div class="drop-button text-center mt full-width">



    <button class="pull-right btn-large" *ngIf="isEmbedOption(document) == false && isHeader(document) == false"
      (click)="insertCopyContent(document, false)" mat-raised-button color="accent">
      Insert-Copy Content
    </button>

    <button class="pull-right btn-large" *ngIf="isEmbedOption(document) == false && isHeader(document) == true"
      (click)="insertContent(document, false)" mat-raised-button color="accent">
      Insert Content
    </button>

    <button class="pull-right btn-large mr" *ngIf="isEmbedOption(document) == false && isHeader(document) == true"
      (click)="copyContent(document, false)" mat-raised-button color="primary">
      Copy Content
    </button>

    <button class="pull-right btn-large" *ngIf="isEmbedOption(document) == true"
      (click)="insertCopyContent(document, true)" mat-raised-button color="accent">
      Insert-Copy As Embedded
    </button>

    <button class="pull-right mr btn-large" *ngIf="isEmbedOption(document) == true"
      (click)="insertCopyContent(document, false)" mat-raised-button color="accent">
      Insert-Copy As External Link
    </button>

    <button class="pull-right btn-large mr" (click)="copyLink(document)" mat-raised-button color="primary">
      Copy  Link
    </button>
    <button class="pull-left btn-large" (click)="preview(document)" mat-raised-button color="primary">Preview</button>
  </div>
</div>
