import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class PerformanceService {
  constructor(private http: HttpClient) {}

  getWeeklyPerformance(body, isHidden = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        params = params.set(key, body[key]);
      }
    }

    options = {
      params,
    };
    if (isHidden == true) {
      return this.http
        .get(environment.nodeUrl + '/weekly-report-promoter/hidden', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/weekly-report-promoter', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }
}
