<div class="row clearfix" *ngIf="tag">
  <div class="well tag">
    <label>Tag Label:</label>

    <h3 class="sr-title rm-mb">{{ tag.Label }}</h3>

    <div *ngIf="tag.Description">
      <label class="mt">Tag Description:</label>
      <p class="">{{ tag.Description }}</p>
    </div>
  </div>

  <!-- 
  <div class="full-width list-view" *ngIf="tag.Cards_key">
    <app-customer-prospect-list [setPatients]="tag.Cards_key" [viewControl]="false"></app-customer-prospect-list>
  </div> -->

  <div class="full-width mt">
    <button class="pull-left" (click)="goback()" mat-raised-button color="primary">Back</button>
  </div>
</div>
