import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-main-redirection',
  template: ``,
  styles: [``],
})
export class MainRedirectionComponent implements OnInit {
  root = 'merchant';

  constructor(private router: Router, private activeRoute: ActivatedRoute) {}

  ngOnInit() {
    let targetURL;
    const url = this.router.url;

    if (url) {
      if (url == '/main' && url != '/' + this.root) {
        targetURL = '/' + this.root;
      } else if (url == '/promoter' && url != '/' + this.root) {
        targetURL = '/' + this.root;
      } else if (url == '/consumer' && url != '/' + this.root) {
        targetURL = '/' + this.root;
      } else if (url == '/consumer-app' && url != '/' + this.root) {
        targetURL = '/' + this.root;
      } else if (url == '/funder' && url != '/' + this.root) {
        targetURL = '/' + this.root;
      } else if (url == '/tmp' && url != '/' + this.root) {
        targetURL = '/' + this.root;
      } else if (url == '/supplier' && url != '/' + this.root) {
        targetURL = '/' + this.root;
      } else if (url == '/merchant' && url != '/' + this.root) {
        targetURL = '/' + this.root;
      } else if (url == '/customer' && url != '/' + this.root) {
        targetURL = '/' + this.root;
      } else if (url.indexOf('/main/') != -1) {
        targetURL = url.replace('/main/', '/' + this.root + '/');
      } else if (url.indexOf('/promoter/') != -1) {
        targetURL = url.replace('/promoter/', '/' + this.root + '/');
      } else if (url.indexOf('/consumer/') != -1) {
        targetURL = url.replace('/consumer/', '/' + this.root + '/');
      } else if (url.indexOf('/consumer-app/') != -1) {
        targetURL = url.replace('/consumer-app/', '/' + this.root + '/');
      } else if (url.indexOf('/funder/') != -1) {
        targetURL = url.replace('/funder/', '/' + this.root + '/');
      } else if (url.indexOf('/tmp/') != -1) {
        targetURL = url.replace('/tmp/', '/' + this.root + '/');
      } else if (url.indexOf('/supplier/') != -1) {
        targetURL = url.replace('/supplier/', '/' + this.root + '/');
      } else if (url.indexOf('/merchant/') != -1) {
        targetURL = url.replace('/merchant/', '/' + this.root + '/');
      } else if (url.indexOf('/customer/') != -1) {
        targetURL = url.replace('/customer/', '/' + this.root + '/');
      }

      if (targetURL) {
        this.router.navigateByUrl(targetURL, { replaceUrl: true });
      } else {
        this.router.navigate([], { replaceUrl: true });
      }
    } else {
      this.router.navigateByUrl(url, { replaceUrl: true });
    }
  }
}
