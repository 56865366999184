<div class="rel dash intro">
  <img
    class="animated zoomIn config-img dashboard"
    src="https://s3.ap-southeast-2.amazonaws.com/images.smileright.com.au/hands-up.png"
  />

  <mat-dialog-content>
    <div class="row clearfix add-mb" [innerHTML]="message | safeHtml" style="margin-top: 50px; font-weight: bold"></div>

    <hr/>
    <button class="pull-right" (click)="close()" mat-raised-button color="accent">Close</button>
  </mat-dialog-content>
</div>
