import { TitleCasePipe } from '@angular/common';
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';
import * as _ from 'lodash';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { UtilsService } from '../../../shared/services/utils.service';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { MerchantService } from '../../merchant/shared/merchant.service';
import { CONTEXTS } from '../shared/CONTEXT';
import { TranslationService } from '../shared/services/translation.service';
import { TranslationManagementExportComponent } from '../translation-management-export/translation-management-export.component';

@Component({
  selector: 'ipv-translation',
  templateUrl: './translation-management-create.component.html',
  styleUrls: ['./translation-management-create.component.css'],
  providers: [TitleCasePipe],
})
export class TranslationPage {
  contexts = CONTEXTS;
  tabs: string[] = [];

  translationData = [];

  context = {
    name: 'Default',
    value: 'default',
    icon: 'settings',
  };
  settings = Settings.global;

  translation;

  filter;

  setDefault = false;

  lastUpdateDate = null;
  constructor(
    private utilsService: UtilsService,
    private translationService: TranslationService,
    private helperService: MerchantService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    if (this.contexts && this.contexts.length > 0) {
      const index = this.contexts.findIndex((e) => {
        if (e && e.value && Settings.global['context'] == e.value) {
          return true;
        }

        return false;
      });

      if (index != -1) {
        this.context = this.contexts[index];
      }
    }

    this.setup();
  }

  setup() {
    if (this.context && this.context.value) {
      this.translationService.getTranslation(this.context.value, {}).subscribe((r) => {
        if (r && r.content) {
          this.translationData = JSON.parse(JSON.stringify(r.content));
        }

        if (r && r.updated_at) {
          this.lastUpdateDate = r.updated_at;
        } else {
          this.lastUpdateDate = null;
        }
      });
    }
  }
  changeContext(v) {
    if (v && v.name && v.value) {
      this.context = v;
      this.setDefault = false;
      this.setup();
    }
  }

  addTranslationItems() {
    if (this.translationData) {
      this.translationData.push({
        key: null,
        value: null,
      });
    }
  }
  isODD(i) {
    if (i % 2 == 1) {
      return true;
    }

    return false;
  }

  removeTranslationItems(v) {
    if (v && v.value && v.key) {
      const confirmDialogParams = {
        data: new ConfirmDialog(
          'fas fa-info',
          'Are you sure?',
          '<p>Remove this Translation Key/Value </p>',
          'No',
          'Yes'
        ),
      };

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, confirmDialogParams);
      ref.componentInstance.onConfirm.subscribe((val) => {
        if (val == true) {
          ref.close();
          ref.afterClosed().subscribe((res) => {
            if (this.translationData && this.translationData.length > 0) {
              const index = this.translationData.findIndex((item) => {
                if (item && item.key == v.key && v.value == item.value) {
                  return true;
                }

                return false;
              });

              if (index != -1) {
                this.translationData.splice(index, 1);
              }

              this.translationData = JSON.parse(JSON.stringify(this.translationData));
            }
          });
        } else {
          ref.close();
        }
      });
    } else {
      if (this.translationData && this.translationData.length > 0) {
        const index = this.translationData.findIndex((item) => {
          if (item && item.key == v.key && v.value == item.value) {
            return true;
          }

          return false;
        });

        if (index != -1) {
          this.translationData.splice(index, 1);
        }

        this.translationData = JSON.parse(JSON.stringify(this.translationData));
      }
    }
  }

  export() {
    window.open(environment.nodeUrl + '/translation-admin/export', '_blank');
  }

  addTab(tabName: string) {
    if (this.tabs.filter((tab) => tab === tabName).length === 0) {
      this.tabs.unshift(tabName);
    }
  }

  // deleteTranslation(code: string) {
  //  this.translationService.deleteTranslation(code).subscribe(() => {
  //      NotifyAppComponent.displayToast('success', 'Successful operation', 'Translation Deleted');
  //  })
  // }

  isFontAwesomeIcon(icon: string) {
    return this.utilsService.isFontAwesomeIcon(icon);
  }

  import() {
    const ref = RootAppComponent.dialog.open(TranslationManagementExportComponent, {
      data: {
        isModal: true,
        viewOnly: false,
        isClonedStored: false,
        isClonedDefault: false,
        isClonedPublic: false,
        cloneDefault: true,
      },
      width: '800px',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });

    ref.componentInstance.getResult.subscribe((res) => {
      this.setup();
      ref.close();
    });
  }

  saveTranslationServerOverview() {
    if (this.setDefault === true && this.context && this.context.value) {
      const payload = { context: this.context.value };

      if (this.settings && this.settings['_id']) {
        this.helperService.updateSettings(this.settings['_id'], payload).subscribe((r) => {
          if (r) {
            NotifyAppComponent.displayToast('success', 'Success!', 'Settings are updated');

            Settings.global['context'] = r['context'];
          }
        });
      }
    } else {
      this.saveTranslationServer();
    }
  }
  saveTranslationServer() {
    if (this.context && this.context.value) {
      this.translationData = _.filter(this.translationData, (item) => {
        if (item && item.key && item.value) {
          return true;
        }

        return false;
      });

      const payload = {
        apply: true,
        updateDefault: false,
        content: this.translationData,
        code: this.context.value,
      };
      this.translationService.createTranslation(payload).subscribe((r) => {
        if (r && r.updated_at) {
          this.lastUpdateDate = r.updated_at;
        } else {
          this.lastUpdateDate = null;
        }

        if (r && r.code) {
          this.applyTranslate();
          this.setDefault = false;
          this.setup();
        }
      });
    }
  }

  applyTranslate() {
    const payload = { translateHash: btoa(Math.random().toString()).substring(2, 20) };

    if (this.settings && this.settings['_id']) {
      this.helperService.updateSettings(this.settings['_id'], payload).subscribe((r) => {
        if (r) {
          NotifyAppComponent.displayToast('success', 'Success!', 'Settings are updated');

          Settings.global['translateHash'] = r['translateHash'];

          const context = Settings.global['context'] || 'default';
          const translateHash = Settings.global['translateHash'] || '297749565899';
          if (context && translateHash) {
            this.translate.use(context + '/' + translateHash);
          } else if (context) {
            this.translate.use(context + '/297749565899');
          } else {
            this.translate.use('default');
          }
        }
      });
    }
  }
}
