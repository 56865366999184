<div class="padded" style="overflow: hidden">
  <ng-container>
    <ipv-data-table
      [table]="reportTable.table"
      [rename]="{
        treatmentValue: 'Treatment Value',
        settlementAmount: 'Settlement Amount'
      }"
      [customComponents]="{
        treatmentValue: treatmentValue,
        settlementAmount: settlementAmount
      }"
      [formatHeaders]="{
        treatmentValue: 'right',
        settlementAmount: 'right'
      }"
    >
    </ipv-data-table>

    <ng-template #treatmentValue let-record>
      <div *ngIf="record.treatmentValue > 0" style="text-align: right">
        {{ record.treatmentValue | invertNumber | customCurrency }}
      </div>
    </ng-template>

    <ng-template #settlementAmount let-record>
      <div *ngIf="record.settlementAmount !== 0" style="text-align: right">
        {{ record.settlementAmount | invertNumber | customCurrency }}
      </div>
    </ng-template>
  </ng-container>
</div>
