<div class="row clearfix">
  <div class="card-header primary-gradient-img clearfix inModal noteType" *ngIf="isModal == true">
    <div class="row clearfix">
      <button class="btn-close btn-clear pull-right mat-button" (click)="closeModal()">
        <mat-icon>clear</mat-icon>
      </button>
      <div class="row titleArea clearfix">
        <h2 class="message-title summary-header rm-m">
          <span *ngIf="isEdit == false"> New template purpose </span>
          <span *ngIf="isEdit == true"> Edit template purpose </span>
        </h2>
      </div>
    </div>
  </div>

  <div class="row clearfix">
    <mat-dialog-content>
      <div class="container">
        <div class="row clearfix">
          <h3 class="sr-title rm-m">Purpose Label</h3>
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Label</mat-label>
            <input [(ngModel)]="templatePurpose.label" matInput name="label" placeholder="Label" required/>
          </mat-form-field>
        </div>

        <div class="row clearfix">
          <h3 class="sr-title rm-m">Purpose description</h3>
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Description</mat-label>
            <textarea
              [(ngModel)]="templatePurpose.description"
              matInput
              name="description"
              cols="30"
              rows="10"
            ></textarea>
          </mat-form-field>
        </div>
      </div>
    </mat-dialog-content>

    <button
      *ngIf="isEdit == false"
      [disabled]="templatePurpose.label == null"
      (click)="create()"
      mat-raised-button
      color="accent"
    >
      Create
    </button>

    <button
      *ngIf="isEdit == true"
      [disabled]="templatePurpose.label == null"
      (click)="edit()"
      mat-raised-button
      color="accent"
    >
      Edit
    </button>
  </div>
</div>
