<div class="rel clearfix lookup">
  <div class="card-header primary-gradient-img clearfix inModal">
    <div class="row clearfix">
      <button class="btn-close btn-clear mat-button white" *ngIf="isModal == true" (click)="closeEvent()">
        <mat-icon>clear</mat-icon>
      </button>
      <div class="row titleArea clearfix">
        <h2 class="summary-header rm-m">
          {{ title }}
          <span class="subLabel" [innerHTML]="description | safeHtml"></span>
        </h2>
      </div>
    </div>
  </div>

  <div class="clearfix flex flex-center">
    <mat-form-field class="full-width mr" appearance="outline">
      <mat-label>{{ 'Search by Label, Description...' | translate }}</mat-label>
      <input [(ngModel)]="filterData" (ngModelChange)="backToOne()" matInput name="search"
             placeholder="Search by Title, Description,{{ 'KEYWORD.Treatment' | translate }} Value And Item Number"
             tabIndex="1"/>
    </mat-form-field>

    <mat-button-toggle-group class="btn-group rm-m selectType full-width flex tab" [(ngModel)]="defaultType"
                             (ngModelChange)="changeTab()" value="null">
      <mat-button-toggle class="full-width" [value]="null">
        <span class="badge master" *ngIf="components" [endVal]="
            (components
              | FilterArrayMultipleOrContain: 'search':filterData
              | FilterArrayMultipleValue: ['Is_Default']:null
              | lengthOfArray) || 0
          " countUp></span>
        All
      </mat-button-toggle>

      <mat-button-toggle class="full-width" [value]="'0'">
        <span class="badge master" *ngIf="components" [endVal]="
            (components
              | FilterArrayMultipleOrContain: 'search':filterData
              | FilterArrayMultipleValue: ['Is_Default']:'0'
              | lengthOfArray) || 0
          " countUp></span>
        {{ "KEYWORD.practice" | translate | titlecase}}
        <mat-icon class="fas fa-user-alt"></mat-icon>
      </mat-button-toggle>

      <mat-button-toggle class="full-width" [value]="'1'">
        <span class="badge master" *ngIf="components" [endVal]="
            (components
              | FilterArrayMultipleOrContain: 'search':filterData
              | FilterArrayMultipleValue: ['Is_Default']:'1'
              | lengthOfArray) || 0
          " countUp></span>
        {{ "BRANDING.Brand Name" | translate | titlecase }}
        <mat-icon class="fas {{'fa-tooth' | translate}}"></mat-icon>
      </mat-button-toggle>
    </mat-button-toggle-group>

    <button class="actionMenuToggle" [matMenuTriggerFor]="menu" mat-icon-button>
      <mat-icon class="fas fa-sort"></mat-icon>
    </button>

    <mat-menu #menu="matMenu">
      <button [ngClass]="{ 'accent-color': orderByFilter === 'Label' || orderByFilter === '-label' }"
              (click)="orderBy('Label')" mat-menu-item>
        <span>Name</span>
        <span *ngIf="orderByFilter === 'Label' || orderByFilter === '-label'">
          <mat-icon class="accent-color" *ngIf="orderByFilter === '-label'">arrow_drop_up</mat-icon>
          <mat-icon class="accent-color" *ngIf="orderByFilter !== '-label'">arrow_drop_down</mat-icon>
        </span>
      </button>

      <button
        [ngClass]="{ 'accent-color': orderByFilter === 'DateTimeCreated' || orderByFilter === '-DateTimeCreated' }"
        (click)="orderBy('-DateTimeCreated')" mat-menu-item>
        <span>Date</span>
        <span *ngIf="orderByFilter === 'DateTimeCreated' || orderByFilter === '-DateTimeCreated'">
          <mat-icon class="accent-color" *ngIf="orderByFilter === '-DateTimeCreated'">arrow_drop_down</mat-icon>
          <mat-icon class="accent-color" *ngIf="orderByFilter !== '-DateTimeCreated'"> arrow_drop_up</mat-icon>
        </span>
      </button>
    </mat-menu>
  </div>

  <mat-dialog-content class="lookup-component" [ngClass]="{ 'no-modal-height': isModal != true }">
    <div class="patientContainer mt">
      <div class="patientRecord row clearfix" *ngFor="
          let component of components
            | FilterArrayMultipleOrContain: 'search':filterData
            | FilterArrayMultipleValue: ['Is_Default']:defaultType
            | OrderArrayObject: [orderByFilter]
            | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage }
        ">
        <div class="full-width clearfix expend-container">
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <div class="profile-result full-width flex">
                    <!-- avatar image -->

                    <span class="chipPromoter pull-left primary-bg mr" *ngIf="component && component.Is_Default == '1'"
                          matTooltip="  {{ 'BRANDING.Brand Name' | translate }} Component">
                      <mat-icon class="fas {{'fa-tooth' | translate}}"></mat-icon>
                    </span>

                    <span class="chipUser pull-left mr" *ngIf="component && component.Is_Default != '1'"
                          [matTooltip]="('KEYWORD.practice' | translate | titlecase) + ' Component'">
                      <mat-icon class="fas fa-user-alt"></mat-icon>
                    </span>

                    <div class="pull-left full-width truncate-normal">
                      <div class="full-width">
                        <h4 class="sr-title rm-m mr pull-left"
                            [ngClass]="{ 'title-practice': component && component.Is_Default !== '1' }">
                          <strong> {{ component['Label'] }}</strong>
                        </h4>
                      </div>
                    </div>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>

              <div class="flex">
                <div class="profile-result full-width flex">
                  <div class="pull-left full-width truncate-normal patientRecord-inner"
                       (click)="selectComponent(component)">
                    <p class="reduce-marg small-margin-bottom" matLine>
                      {{ component['Description'] }}
                    </p>

                    <div class="full-width clearfix">
                      <p class="small rm-m date_label grey pull-right" *ngIf="component.DateTimeCreated">
                        <mat-icon class="fas fa-clock"></mat-icon>
                        {{ component.DateTimeCreated | TimeLabelPast }} ago

                        <button class="component-view-button sml"
                          *ngIf="component && component['ID'] && viewOnClick != true"
                                (click)="$event.stopPropagation(); viewComponent(component['ID'])" color="primary"
                                mat-icon-button matTooltip="View Component Details">
                          <mat-icon class="view-icon">visibility</mat-icon>
                        </button>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>

        <div class="flex full-width clearfix mt mb normal-container">
          <div class="profile-result full-width flex">
            <!-- avatar image -->

            <span class="chipPromoter pull-left primary-bg mr" *ngIf="component && component.Is_Default == '1'"
                  (click)="selectComponent(component)" matTooltip="  {{ 'BRANDING.Brand Name' | translate }} Component">
              <mat-icon class="fas {{'fa-tooth' | translate}}"></mat-icon>
            </span>

            <span class="chipUser pull-left mr" *ngIf="component && component.Is_Default !== '1'"
              (click)="selectComponent(component)"
                  [matTooltip]="('KEYWORD.practice' | translate | titlecase) + ' Component'">
              <mat-icon class="fas fa-user-alt"></mat-icon>
            </span>

            <!-- profile information -->
            <div class="pull-left full-width truncate-normal" (click)="selectComponent(component)">
              <div class="full-width">
                <h3 class="sr-title rm-m pull-left"
                    [ngClass]="{ 'title-practice': component && component.Is_Default !== '1' }">
                  <strong> {{ component['Label'] }}</strong>
                </h3>
                <p class="small rm-m mr date_label grey pull-right" *ngIf="component.DateTimeCreated">
                  <mat-icon class="fas fa-clock"></mat-icon>
                  {{ component.DateTimeCreated | TimeLabelPast }} ago

                  <button class="component-view-button sml" *ngIf="component && component['ID'] && viewOnClick != true"
                          (click)="$event.stopPropagation(); viewComponent(component['ID'])" color="primary"
                          mat-icon-button
                          matTooltip="View Component Details">
                    <mat-icon class="view-icon">visibility</mat-icon>
                  </button>
                </p>
              </div>

              <p class="reduce-marg small-margin-bottom" matLine>
                {{ component['Description'] }}
              </p>
              <div class="full-width clearfix">
                <h3 class="sr-title rm-m pull-right mr"
                    [ngClass]="{ 'title-practice': template && template.Is_Defaul !== '1' }">
                  <span *ngIf="component['Nature.Code'] == 'Style'" matTooltip="Style">Style</span>
                  <span *ngIf="component['Nature.Code'] == 'PageTem'" matTooltip="Page Template"> Page Template</span>
                  <span *ngIf="component['Nature.Code'] == 'Template'" matTooltip="Component"> Component</span>
                </h3>
              </div>
            </div>
          </div>
        </div>

        <mat-divider></mat-divider>
      </div>

      <div class="full-width" *ngIf="
          (components
            | FilterArrayMultipleOrContain: 'search':filterData
            | FilterArrayMultipleValue: ['Is_Default']:defaultType
            | OrderArrayObject: [orderByFilter]
            | lengthOfArray) <= 0 && isLoaded == true
        ">
        <app-empty-list-message [message]="'No Component found'"></app-empty-list-message>
      </div>
    </div>

    <div class="full-width text-center page-container mt flex mb" *ngIf="
        (components
          | FilterArrayMultipleOrContain: 'search':filterData
          | FilterArrayMultipleValue: ['Is_Default']:defaultType
          | OrderArrayObject: [orderByFilter]
          | lengthOfArray) > 20
      ">
      <div class="ml qtr-width flex pull-left">
        <mat-label class="line-h">Page size</mat-label>
        <mat-select class="page-size-in line-h pull-right" [(ngModel)]="itemsPerPage" placeholder="Page size">
          <mat-option [value]="20"> 20</mat-option>
          <mat-option [value]="30"> 30</mat-option>
          <mat-option [value]="40"> 40</mat-option>
          <mat-option [value]="50"> 50</mat-option>
        </mat-select>
      </div>
      <pagination-controls class="full-width clearfix" (pageChange)="changePage($event)" responsive="true">
      </pagination-controls>
    </div>
    <br/>
    <br/>
  </mat-dialog-content>
  <hr/>
  <div class="drop-button full-width pull-right clearfix" *ngIf="isModal == true">
    <button class="inline btn-new-invite pull-left selectExistingPatient" (click)="closeEvent()" mat-raised-button
            color="primary">
      Close
    </button>
    <button class="inline btn-new-invite pull-right selectExistingPatient" (click)="gotoNewComponent()"
            mat-raised-button color="">
      <mat-icon class="primary-color">add</mat-icon> <span class="primary-color">Create your own</span>
    </button>
  </div>
</div>
