import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PriceServiceEditComponent } from '../price-service-edit/price-service-edit.component';
import { PriceServiceListComponent } from '../price-service-list/price-service-list.component';
import { PriceServiceViewComponent } from '../price-service-view/price-service-view.component';

const routes: Routes = [
  {
    path: 'price-service-list',
    component: PriceServiceListComponent,
  },
  {
    path: 'price-service-view',
    component: PriceServiceViewComponent,
  },
  {
    path: 'price-service-edit',
    component: PriceServiceEditComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PriceServiceRoutingModule {}
