import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-credit-card-view',
  templateUrl: './credit-card-view.component.html',
  styleUrls: ['./credit-card-view.component.css'],
})
export class CreditCardViewComponent implements OnInit {
  @Input()
  flippable = false;

  @Input()
  name = '';

  @Input()
  number = '';

  @Input()
  reference = '';

  @Input()
  expiry = '';

  @Input()
  cardType = 'cc';

  @Input()
  cardBrand = '';

  @Input()
  cardLabel = '';

  imgURL;

  constructor() {}

  ngOnInit() {
    if (this.cardBrand) {
      this.imgURL = `https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/brandLogos/_${this.cardBrand}.png`;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.cardBrand) {
      this.imgURL = `https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/brandLogos/_${this.cardBrand}.png`;
    }
  }
}
