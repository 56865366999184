import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'invertNumber',
})
export class InvertNumberPipe implements PipeTransform {
  transform(value: number): number {
    return value * -1;
  }
}
