<div class="rel" [cdkDragDisabled]="!enableDragging" cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane">
  <div class="card-header primary-gradient-img clearfix confirmModal" [ngClass]="classNames">
    <div class="row clearfix">
      <button class="btn-close btn-clear mat-button white" (click)="close()" matDialogClose>
        <mat-icon>clear</mat-icon>
      </button>
      <div class="row titleArea clearfix" style="width: 100%">
        <div class="title-section">
          <div class="main-icon-container text-center">
            <mat-icon class="white pull-left main-icon accent-color" *ngIf="!classIcon" style="margin-right: 5px">{{
              icon
              }}</mat-icon>
            <mat-icon
              *ngIf="classIcon"
              [ngClass]="'white pull-left main-icon accent-color ' + classIcon"
              style="margin-right: 5px"
            ></mat-icon>
          </div>

          <h2 class="summary-header rm-m">{{ title }}</h2>
        </div>
      </div>
    </div>
  </div>

  <mat-dialog-content class="text-center">
    <div class="row clearfix mb">
      <div [innerHTML]="message | safeHtml"></div>
    </div>

    <div *ngIf="!hideAllDefaultButtons">
      <div style="display: flex; width: 100%; justify-content: flex-end">
        <button
          *ngIf="!hideDefaultRejectButton"
          (click)="rejectButtonClick()"
          mat-raised-button
          style="margin-left: 10px"
        >
          {{ rejectButtonText }}
        </button>

        <button
          *ngIf="!hideDefaultAcceptButton"
          (click)="acceptButtonClick()"
          color="accent"
          mat-raised-button
          style="margin-left: 10px"
        >
          {{ acceptButtonText }}
        </button>
      </div>
    </div>
  </mat-dialog-content>
</div>
