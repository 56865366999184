<div class="clearfix toggle-card">
  <mat-button-toggle-group
    class="btn-group text-center full-width with-icons"
    #group="matButtonToggleGroup"
    [(ngModel)]="groupFilter"
  >
    <mat-button-toggle class="text-center full-width" [value]="'patient'">
      <mat-icon class="fas fa-user"></mat-icon>
      {{ "KEYWORD.patient" | translate | titlecase }} Documents
    </mat-button-toggle>
    <mat-button-toggle class="text-center full-width" [value]="'dentist'">
      <mat-icon class="fas {{'fa-tooth' | translate}}"></mat-icon>
      {{ "KEYWORD.practitioner" | translate | titlecase }} Documents
    </mat-button-toggle>
  </mat-button-toggle-group>
</div>

<div class="full-width row clearfix" *ngIf="groupFilter == 'patient'">
  <app-treatment-document-gallery-overview class="clearfix full-width" id="part1">
  </app-treatment-document-gallery-overview>
</div>

<div class="full-width row clearfix" *ngIf="groupFilter == 'dentist'">
  <app-document-list [title]="('KEYWORD.practitioner' | translate | titlecase) + 's' + ' Documents'" [type]="'SRVSPL'">
  </app-document-list>
</div>
