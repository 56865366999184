<div class="row clearfix" *ngIf="isPromoterOrAdmin == true">
  <app-operator-filter
    class="filter"
    id="part1"
    [selectDefaultAccount]="true"
    [displayMerchant]="true"
    [displayDateRange]="false"
    [displayDateFrom]="false"
    [displayTimePicker]="false"
    [displayDateTo]="false"
    [displayDentist]="false"
    (search)="applySearch($event)"
    (getMerchant)="getMerchant($event)"
  >
  </app-operator-filter>

  <div class="row clearfix">
    <app-tasks-list *ngIf="merchantID != 'none'" [merchantID]="merchantID"></app-tasks-list>
  </div>
  <div class="row clearfix">
    <div class="pleaseChoose" *ngIf="merchantID == 'none'">
      <p>
        <strong>No merchant selected</strong>
      </p>
      <img class="character_warning" />
      <p>Choose your desired merchant from the drop-down above to view their messages</p>
    </div>
  </div>
</div>
