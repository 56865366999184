import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { map, switchMap, tap } from 'rxjs/operators';
import { User } from '../../../../../../../core/authentication/shared/shared/types/authentication.type';
import { NotifyAppComponent } from '../../../../../../../shared/types/notify-app-component';
import { UtilsClass } from '../../../../../../../shared/types/utils/utils.class';
import { Dentist } from '../../../../../../dentist/shared/dentist.type';
import { CustomerAndProspect } from '../../../../../../invitation/shared/types/invitation.type';
import { Merchant } from '../../../../../../merchant/shared/types/merchant.type';
import { NotesService } from '../../../../../shared/services/notes.service';
import { Note } from '../../../../../shared/type/notes.type';
import {
  NoteTemplateFormModalComponent,
  NoteTemplateFormModalConfig,
} from '../note-form/shared/components/note-input/shared/modals/note-template-form-modal.component';

@Component({
  selector: 'ipv-note-templates',
  templateUrl: './note-templates.component.html',
  styleUrls: ['./note-templates.component.css'],
})
export class NoteTemplatesComponent implements OnInit, OnDestroy {
  utils = new UtilsClass();

  noteTemplates$ = this.notesService.getNoteTemplates$<Note>().pipe(
    map((notes) => {
      const filteredNotes = notes.filter(
        (note) => note.Template_Label.includes(this.searchFilter) || note.Description.includes(this.searchFilter)
      );

      return filteredNotes;
    })
  );

  @Output() templateSelected = new EventEmitter<Note>();

  @Input() title = 'Note Template';
  @Input() subtitle = `Below is a list of Note Templates available. Click to view the template, or if you are feeling
    creative press the 'create your own' button.`;
  @Input() displayMore = true;

  @Input() practice: Partial<Merchant>;
  @Input() patient: Partial<CustomerAndProspect> | User;
  @Input() dentist: Dentist;

  searchFilter = '';
  isCollapsed = true;

  orderByDirection = 'Template_Label';

  constructor(private notesService: NotesService, private dialog: MatDialog) {}

  ngOnInit() {
    this.filterSelectedNotes();
  }

  ngOnDestroy() {
    /** required for untilDestroyed (Replace with @UntilDestroyed() Decorator in angular 9 and delete ngOnDestroy()) */
  }

  changeSearchFilter() {
    const noteTemplates = [...this.notesService.getNoteTemplates()];
    this.notesService.setNoteTemplates(noteTemplates);
  }

  orderBy(orderDirection) {
    if (orderDirection) {
      let isUpOrder = true;

      if (orderDirection && orderDirection[0] === '-') {
        isUpOrder = false;
      }

      if (this.orderByDirection === orderDirection) {
        if (isUpOrder === true) {
          this.orderByDirection = '-' + orderDirection;
        } else {
          this.orderByDirection = orderDirection.replace('-', '');
        }
      } else {
        this.orderByDirection = orderDirection;
      }
    }
    this.filterSelectedNotes();
  }

  collapse(e) {
    this.isCollapsed = e;
  }

  isNew(date) {
    if (date) {
      const days = this.utils.daysPast(date);

      if (days < 15) {
        return true;
      } else {
        return false;
      }
    }

    return false;
  }

  selectNoteTemplate(note: Note) {
    this.templateSelected.emit(note);
  }

  openNoteTemplateFormModal() {
    this.dialog.open<NoteTemplateFormModalComponent, NoteTemplateFormModalConfig>(NoteTemplateFormModalComponent, {
      data: {
        disableApply: true,
        practice: this.practice,
        patient: this.patient,
        dentist: this.dentist,
      },
    });
  }

  openNoteTemplateFormModalUpdateMode(note: Note) {
    this.dialog.open<NoteTemplateFormModalComponent, NoteTemplateFormModalConfig>(NoteTemplateFormModalComponent, {
      data: {
        formMode: 'update',
        disableApply: true,
        noteID: note.ID,
        note,
        noteText: note.Description,
        label: note.Template_Label,
        practice: this.practice,
        patient: this.patient,
        dentist: this.dentist,
      },
    });
  }

  deleteNote(note: Note) {
    this.notesService
      .deleteNote$(note)
      .pipe(
        switchMap(() => {
          return this.notesService.removeNoteTemplate$(note);
        })
      )
      .subscribe((id) => {
        NotifyAppComponent.displayToast('success', 'Successful operation', 'Successfully removed template');
      });
  }

  filterSelectedNotes() {
    return this.notesService
      .fetchNoteTemplates$<Note>({ orderBy: this.orderByDirection })
      .pipe(
        untilDestroyed(this),
        tap((notes) => {
          this.notesService.setNoteTemplates(notes);
        })
      )
      .subscribe();
  }
}
