import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { HelperService } from '../shared/helper.service';

@Component({
  selector: 'app-simple-page-create',
  templateUrl: './simple-page-create.component.html',
  styleUrls: ['./simple-page-create.component.css'],
})
export class SimplePageCreateComponent implements OnInit {
  simplePage = {
    title: '',
    type: '',
    content: '',
  };
  @Output() added = new EventEmitter();

  constructor(private helperService: HelperService) {}

  ngOnInit() {}

  addSimplePage() {
    this.simplePage.content =
      'Please Insert the content from the Admin Page ,<br><strong> Title:</strong>' +
      this.simplePage.title +
      '<br><strong>  Code:</strong>' +
      this.simplePage.type;
    this.helperService.addSimplePage(this.simplePage).subscribe((res) => {
      if (res.success) {
        NotifyAppComponent.displayToast('success', 'Successfull operation', 'Simple page has been successfully added');
        this.simplePage = {
          title: '',
          type: '',
          content: '',
        };
        this.added.emit({ added: true, page: res.data });
      }
    });
  }
}
