import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';

@Component({
  selector: 'app-merchant-product-assitant-overview',
  templateUrl: './merchant-product-assitant-overview.component.html',
  styleUrls: ['./merchant-product-assitant-overview.component.css'],
})
export class MerchantProductAssitantOverviewComponent implements OnInit {
  merchantID = 'none';
  isPromoterOrAdmin = false;

  constructor(private authenticationService: AuthenticationService) {}

  ngOnInit() {
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;

      this.authenticationService.getCurrentPractice().subscribe((m) => {
        if (m && m.ID) {
          this.merchantID = m.ID;
        }
      });
    });
  }

  applySearch(result) {
    this.merchantID = result.merchantID;
  }
}
