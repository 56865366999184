<div class="full-width clearfix">
  <div class="main-banner blue-banner" *ngIf="isEmbed != true && featureType != 'consumer'"></div>

  <div class="row clearfix container mt ml mr">
    <div class="clearfix container-inner-parent" *ngIf="isEmbed != true" data-aos="fade-right" data-aos-delay="500"
      data-aos-easing="ease-in-sine" data-aos-once="true">
      <div class="clearfix container-inner mt">
        <div class="guid guid-right flex clearfix full-width">
          <div class="container guid-main">
            <div class="half-width txt-box">
              <div class="txt">
                <h1 class="text-left header-title-sub-list">{{ title }}</h1>
                <p class="text-left header-description-sub-list">
                  {{ description }}
                </p>
              </div>
            </div>
            <div class="half-width img-box-left">
              <div class="guid-imgbox flex guid-imgbox-left">
                <img *ngIf="context!=='p4p'" class="full-width"
                  src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/public/img/group-shot.png" />
                <img *ngIf="context=='p4p'" class="full-width"
                  src="https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/pets-groups0.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="full-width flex clearfix">
      <div class="full-width clearfix ml mr">
        <button class="btn smr mt pull-right" *ngIf="windowControlMain == true && featureType == 'consumer'"
          (click)="canHideDisplayEvent(true)" mat-icon-button matTooltip="Open all">
          <mat-icon class="fas fa-window-maximize" [attr.style]="'color: #fff !important' | safeStyle"></mat-icon>
        </button>

        <button class="btn smr mt pull-right" *ngIf="windowControlMain == true && featureType == 'consumer'"
          (click)="canHideDisplayEvent(false)" mat-icon-button matTooltip="Close all">
          <mat-icon class="fas fa-window-minimize" [attr.style]="'color: #fff !important' | safeStyle"></mat-icon>
        </button>

        <button class="btn smr mt pull-right" *ngIf="windowControlMain == true && featureType != 'consumer'"
          (click)="canHideDisplayEvent(true)" mat-icon-button matTooltip="Open all">
          <mat-icon class="fas fa-window-maximize" color="primary"></mat-icon>
        </button>

        <button class="btn smr mt pull-right" *ngIf="windowControlMain == true && featureType != 'consumer'"
          (click)="canHideDisplayEvent(false)" mat-icon-button matTooltip="Close all">
          <mat-icon class="fas fa-window-minimize" color="primary"></mat-icon>
        </button>

        <button class="btn mr ml mt pull-right" *ngIf="isAdminOrPromoter == true && actionButton == true"
          (click)="createNew()" mat-raised-button color="accent">
          <mat-icon class="fas fa-plus-circle"></mat-icon>
          <span class="sml">Create New</span>
        </button>

        <button class="btn pull-right mr mt" *ngIf="
            canDragAndDrop == true &&
            readOnly != true &&
            mainFeatures &&
            mainFeatures.length > 1 &&
            displaySaveButton == true &&
            displaySaveButtonOnBottom != true
          " (click)="saveOrderEvent()" mat-raised-button color="accent" matTooltip="Save new order">
          <mat-icon>save</mat-icon>
        </button>

        <button class="btn smr mt pull-right"
          *ngIf="isAdminOrPromoter == true && readOnly == true && actionButton == true" (click)="modifyReadOnly(false)"
          mat-raised-button color="accent">
          <mat-icon class="fas fa-edit"></mat-icon>
          <span class="sml">Edit Mode</span>
        </button>

        <button class="btn smr mt pull-right"
          *ngIf="isAdminOrPromoter == true && readOnly != true && actionButton == true" (click)="modifyReadOnly(true)"
          mat-raised-button color="accent">
          <mat-icon class="fas fa-glasses"></mat-icon>
          <span class="sml">View Mode</span>
        </button>
      </div>
    </div>

    <div class="full-width flex clearfix" *ngIf="mainFeatures && mainFeatures.length > 0">
      <div class="mt full-width mb" [cdkDropListDisabled]="canDragAndDrop != true || readOnly == true"
        (cdkDropListDropped)="drop($event)" cdkDropList>
        <div class="full-width mb container-main-feature"
          *ngFor="let m of mainFeatures | OrderArrayObject: 'Display_Order'; let index = index"
          [class.pointerCard]="isOrderCheck == true" data-aos="fade-right" data-aos-delay="700"
          data-aos-easing="ease-in-sine" data-aos-once="true" cdkDrag>
          <div class="item-placeholder full-width" *cdkDragPlaceholder></div>
          <app-feature-sub-label-list class="app-feature-sub-label-list-container" [featureType]="featureType"
            [openFirst]="index == 0 ? true : false" [windowControl]="windowControl" [displayFilter]="displayFilter"
            [displayCover]="displayCover" [ngClass]="{ 'pointer-cursor-card-main': canDragAndDrop == true }"
            [saveOrder]="saveOrderInner" [displaySaveButton]="false" [colapse]="colapse" [readOnly]="readOnly"
            [canSelect]="canSelect" [canSelectMain]="canSelectMain" [allView]="allView"
            [defaultSelectedIDs]="defaultSelectedIDs" [defaultSelectedLimitedIDs]="defaultSelectedLimitedIDs"
            [mainFeatureLabel]="m" [subFeatureLabels]="m.subFeatures" [mainFeatureLabelID]="null"
            [displayOwn]="displayOwn" [maxText]="maxText" [canDragAndDrop]="canDragAndDrop"
            [hideDisplayCheck]="index == 0 ? true : hideDisplayCheck" (getSaveOrder)="getSaveOrderInner(m.ID, $event)"
            (getRemove)="getRemoveMain($event)" (getSelectedIDs)="getSelectedIDsInner(m['ID'], $event)"
            (getSelectedLimitedIDs)="getSelectedLimitedIDsInner(m['ID'], $event)"
            (getIsSelectionChange)="getIsSelectionChangeEvent($event)">
          </app-feature-sub-label-list>
        </div>
      </div>
    </div>

    <div class="full-width text-center mt clearfix" *ngIf="
        canDragAndDrop == true &&
        readOnly != true &&
        mainFeatures &&
        mainFeatures.length > 1 &&
        displaySaveButton == true &&
        displaySaveButtonOnBottom == true
      ">
      <button class="btn-large" (click)="saveOrderEvent()" mat-raised-button color="accent">
        Save
        <mat-icon>save</mat-icon>
      </button>
    </div>
  </div>
</div>

<app-card-footer *ngIf="isEmbed != true && featureType != 'consumer'" [firstBackgroundWhite]="false"
  [items]="['plans-price', 'demo']" [featureType]="featureType">
</app-card-footer>
