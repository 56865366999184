import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { MerchantViewComponent } from '../../merchant/merchant-view/merchant-view.component';
import { ContractService } from '../shared/contract.service';

@Component({
  selector: 'app-contract-detail-shared-section',
  templateUrl: './contract-detail-shared-section.component.html',
  styleUrls: ['./contract-detail-shared-section.component.css'],
})
export class ContractDetailSharedSectionComponent implements OnInit {
  @Input()
  contractID;

  @Input()
  contractObject;

  @Output()
  hideContractDetail = new EventEmitter();

  contract;
  isPromoterOrAdmin = false;
  capacityCheck = false;
  creditCheck = false;
  identityCheck = false;
  isContractView = false;

  constructor(
    private activeRouter: ActivatedRoute,
    private contractSerivce: ContractService,
    private authenticationService: AuthenticationService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.activeRouter.params.subscribe((res) => {
      if (res['contractID']) {
        this.contractID = res['contractID'];
      }
      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isPromoterOrAdmin = res;

        if (this.contractObject && typeof this.contractObject == 'object') {
          this.contract = this.contractObject;
          this.setup();
        } else if (this.contractID) {
          this.contractSerivce.fetchContractDetails$(this.contractID, null, this.isPromoterOrAdmin).subscribe((res) => {
            if (res) {
              this.contract = res;
              this.setup();
            }
          });
        }
      });
    });
  }

  setup() {
    if (this.contract['CapacityCheck.Status'] && this.contract['CapacityCheck.Status'] == 'Accept') {
      this.capacityCheck = true;
    }

    if (this.contract['CreditCheck.Status'] && this.contract['CreditCheck.Status'] == 'Accept') {
      this.creditCheck = true;
    }

    if (this.contract['IdentityCheckStatus'] && this.contract['IdentityCheckStatus'] == 'Accept') {
      this.identityCheck = true;
    }

    const status = this.contract['Status.Code'];
    if (status == 'ACTIVE' || status == 'COMPLETE' || status == 'APPROVED') {
      this.isContractView = true;
    } else {
      this.isContractView = false;
    }
  }

  openCreditCheck() {
    this.hideContractDetail.emit('CreditCheck');
  }

  openCapacityCheck() {
    this.hideContractDetail.emit('CapacityCheck');
  }

  openIdentityCheck() {
    this.hideContractDetail.emit('IdentityCheck');
  }

  viewMerchant() {
    const ref = this.dialog.open(MerchantViewComponent, {
      data: this.contract['Merchant_key'],
      width: '650px',
    });
    ref.componentInstance.close.subscribe((res) => ref.close());
  }
}
