<div [hidden]="emptyList == true">
  <div class="rel" *ngIf="firstLoad == false" @ngIfAnimation>
    <div class="row clearfix padded rel">
      <h1 class="dataHeader rm-mt transparent">Fetching data...</h1>
    </div>
  </div>
  <div class="row clearfix" *ngIf="payments && firstLoad == true">
    <!-- <mat-dialog-content *ngIf="" class=""> -->
    <app-payment-view *ngFor="let p of payments | OrderArrayObject: 'Dates.Due'" [paymentObject]="p"></app-payment-view>
    <!-- </mat-dialog-content> -->
  </div>
</div>

<div *ngIf="emptyList == true">
  <app-empty-list-message
    [title]="'No payment found'"
    [message]="'There is no payment to show'"
  ></app-empty-list-message>
</div>
