import { BehaviorSubject, merge as observableMerge, Observable } from 'rxjs';

import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { DataSource } from '@angular/cdk/table';
import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Optional,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { map } from 'rxjs/operators';
import { EpDocumentViewComponent } from '../../../shared/components/ep-document-view/ep-document-view.component';
import { CustomDatePipe } from '../../../shared/pipes/custom-date.pipe';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';

import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { TreatmentDocumentEditComponent } from '../../treatment/treatment-document-edit/treatment-document-edit.component';

import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { EPDocumentCreateEditComponent } from '../../../shared/components/ep-document-create-edit/ep-document-create-edit.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { UtilsService } from '../../../shared/services/utils.service';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { DocumentService } from '../shared/document.service';

@Component({
  selector: 'app-document-list',
  templateUrl: './document-list.component.html',
  styleUrls: ['./document-list.component.css'],
  providers: [CustomDatePipe],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.row', style({ opacity: 0 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.5s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 0, height: '0' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.row', style({ opacity: 1 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.5s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 1.0, height: '0' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class DocumentListComponent implements OnInit {
  @Input()
  cardID;

  @Input()
  merchantID;
  @Input()
  contractID;

  @Input()
  invitationID;

  @Input()
  drawDownID;

  @Input()
  paymentID;

  @Input()
  types;

  @Input()
  programAreaCodes;

  @Input()
  nature;

  @Input()
  natures;

  @Input()
  type;

  @Input()
  isGallery;

  @Input()
  programAreaCode;

  @Input()
  dateFrom;

  @Input()
  dateTo;

  @Input()
  title = 'Documents';

  @Output()
  displaySlideToggle = new EventEmitter();

  @Output()
  getLength = new EventEmitter();

  @Output()
  viewSupplierEvent = new EventEmitter();

  isModal = false;
  typeFilter = null;
  @Output()
  closeModal = new EventEmitter();

  @Input()
  pageSize = Settings.global['listPageSize'] || 20;
  pageSizeOptions = [10, Number(this.pageSize), Number(this.pageSize) * 2, Number(this.pageSize) * 3];

  displayedColumns = ['mediaThumbnail', 'documentLabel', 'mediaMedium', 'Actions'];

  isActiveFilter;
  filters = [];
  selectedIDs = [];

  searchVals = new FormControl();

  searchValList = ['Document Label'];

  public listDB: LoadRecords | null;
  dataSource: RecordDataSource | null;

  filteredSize = null;

  isPromoterOrAdmin = false;
  utils = new UtilsClass();

  destroyEvent = new EventEmitter();

  @Input()
  isDownload = true;

  @Input()
  isRedirection = true;

  @Input()
  isPrint = true;

  sessionType = 'guest';

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('filter', { static: false }) filter: ElementRef;

  constructor(
    private documentService: DocumentService,
    private customDate: CustomDatePipe,
    private utilService: UtilsService,
    private authenticationService: AuthenticationService,
    private dialog: MatDialog,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.cardID) {
        this.pageSize = 8;

        this.isModal = true;
      }

      if (data.type) {
        this.type = data.type;
      }

      if (data.programAreaCode) {
        this.programAreaCode = data.programAreaCode;
      }
    }
  }

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((sessionType) => {
      this.sessionType = sessionType;

      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isPromoterOrAdmin = res;

        if (this.isPromoterOrAdmin == true) {
          this.displayedColumns.unshift('selectCheckBox');
        }
        if (this.isPromoterOrAdmin == false) {
          this.isDownload = false;
          this.isRedirection = false;
          this.isPrint = false;
        }

        const params = {
          cardID: this.cardID,
          contractID: this.contractID,
          invitationID: this.invitationID,
          drawDownID: this.drawDownID,
          paymentID: this.paymentID,
          type: this.type,
          programAreaCode: this.programAreaCode,
          isPromoterOrAdmin: this.isPromoterOrAdmin,
          dateFrom: this.dateFrom,
          dateTo: this.dateTo,
          sessionType: this.sessionType,
          isGallery: this.isGallery,
          types: this.types,
          programAreaCodes: this.programAreaCodes,
          nature: this.nature,
          natures: this.natures,
          merchantID: this.merchantID,
        };
        this.listDB = new LoadRecords(
          this.documentService,
          this.destroyEvent,
          this.getLength,
          this.utilService,
          params
        );
        this.dataSource = new RecordDataSource(this.listDB, this.paginator, this.sort);
        this.displaySlideToggle.emit(true);
      });
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    const params = {
      cardID: this.cardID,
      contractID: this.contractID,
      invitationID: this.invitationID,
      drawDownID: this.drawDownID,
      paymentID: this.paymentID,
      type: this.type,
      programAreaCode: this.programAreaCode,
      isPromoterOrAdmin: this.isPromoterOrAdmin,
      dateFrom: this.dateFrom,
      dateTo: this.dateTo,
      sessionType: this.sessionType,
      isGallery: this.isGallery,
      types: this.types,
      programAreaCodes: this.programAreaCodes,
      nature: this.nature,
      natures: this.natures,
      merchantID: this.merchantID,
    };
    this.listDB = new LoadRecords(this.documentService, this.destroyEvent, this.getLength, this.utilService, params);
    this.dataSource = new RecordDataSource(this.listDB, this.paginator, this.sort);
    this.displaySlideToggle.emit(true);
  }

  closeEvent() {
    this.closeModal.emit(true);
  }

  setFilter(event, field) {
    let filter;

    if (typeof event == 'object' && event != null) {
      const v = this.customDate.transform(event, Settings.global['dateFormat']);
      filter = {
        field,
        value: v,
      };
    } else if (event) {
      filter = {
        field,
        value: event,
      };
    } else {
      filter = {
        field,
        value: '',
      };
    }

    this.dataSource.filter = filter;
    this.filteredSize = this.dataSource.filteredData.length;
  }

  select(id, isSelected) {
    if (isSelected == true && this.selectedIDs.indexOf(id) == -1) {
      this.selectedIDs.push(id);
    } else {
      const _index = this.selectedIDs.indexOf(id);
      this.selectedIDs.splice(_index, 1);
    }
  }

  unselect() {
    this.selectedIDs = [];
  }

  isSelected(id) {
    if (!this.selectedIDs || this.selectedIDs.length <= 0) {
      return false;
    } else {
      if (this.selectedIDs.indexOf(id) != -1) {
        return true;
      }
    }

    return false;
  }

  removeDocumentGroup() {
    if (this.selectedIDs && this.selectedIDs.length > 0) {
      const confirm = new ConfirmDialog(
        'fas fa-info',
        '',
        'Are you sure you want to remove these documents  ?',
        'No',
        'Yes'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation === false) {
          ref.close();
        } else {
          this.documentService.deleteDocumentGroup(this.selectedIDs).subscribe((res) => {
            if (res && res.length > 0) {
              for (let i = 0; i < res.length; i++) {
                this.dataSource.removeItem = res[i];
              }

              this.selectedIDs = [];
            }
            ref.close();
          });
        }
      });
    }
  }

  viewDocument(id, title, description) {
    if (id) {
      const ref = this.dialog.open(EpDocumentViewComponent, {
        data: {
          isDownload: this.isDownload,
          isPrint: this.isPrint,
          isRedirection: this.isRedirection,
          documentID: id,
          title,
          description,
        },
        width: '800px',
        panelClass: 'noCard',
      });

      ref.componentInstance.closeModal.subscribe((res) => {
        ref.close();
      });

      ref.componentInstance.noFileFound.subscribe((res) => {
        NotifyAppComponent.displayToast('warning', 'Sorry!', 'We could not get the requested file(s)');
      });
    }
  }

  isNew(date) {
    if (date) {
      const days = this.utils.daysPast(date);

      if (days < 15) {
        return true;
      } else {
        return false;
      }
    }

    return false;
  }

  getDocumentLabel(t) {
    let label = 'Document';

    if (t == 'video') {
      label = 'Video';
    } else if (t == 'link') {
      label = 'External link';
    }

    // else if (t == "image") {
    //   label = "Image"
    // }
    return label;
  }

  addDocument() {
    const ref = this.dialog.open(EPDocumentCreateEditComponent, {
      width: '750px',
      data: {
        type: this.type,
        cardID: this.cardID,
        merchantID: this.merchantID,
        isGallery: this.isGallery,
        programAreaCode: this.programAreaCode,
      },
    });

    ref.componentInstance.closeModal.subscribe((res) => {
      ref.close();
    });

    ref.componentInstance.result.subscribe((res) => {
      if (res && res.ID) {
        this.dataSource.addItem = res;
        ref.close();
      }
    });
  }

  viewSupplier(id) {
    if (id) {
      this.viewSupplierEvent.emit(id);
    }
  }

  removeDocument(id) {
    if (id) {
      const confirm = new ConfirmDialog(
        'fas fa-info',
        '',
        'Are you sure you want to remove this document  ?',
        'No',
        'Yes'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation === false) {
          ref.close();
        } else {
          this.documentService.deleteDocument(id).subscribe((res) => {
            if (res) {
              this.dataSource.removeItem = res;
            }
            ref.close();
          });
        }
      });
    }
  }

  modifyDocument(id) {
    if (id) {
      const ref = this.dialog.open(TreatmentDocumentEditComponent, {
        data: {
          treatmentDocumentID: id,
        },
        width: '800px',
      });

      ref.componentInstance.result.subscribe((res) => {
        if (res) {
          res.type = this.utils.getDocumentType(res);

          this.dataSource.replaceItemWithNewID = res;
          ref.close();
        }
      });

      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    }
  }

  ngOnDestroy() {
    this.destroyEvent.emit(true);
  }
}

export class LoadRecords implements OnInit {
  public dataChange: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  items: any;
  count: any;

  get data(): any[] {
    return this.dataChange.value;
  }

  firstLoad = false;

  get firstLoadEvent() {
    return this.firstLoad;
  }

  isDestoyed = false;

  util = new UtilsClass();
  serviceRef;

  constructor(
    private documentService: DocumentService,
    private destroyEvent,
    private getLength,
    private utilService: UtilsService,
    params
  ) {
    this.destroyEvent.subscribe((res) => {
      this.isDestoyed = res;
    });

    const payload = {
      section: 0,
      includeOmmitted: true,

      orderBy: '-DateTimeCreated',
      fields:
        'ID,Label,DateTimeCreated,AWS_URL,AWS_Bucket_Name,DocumentType_Code,DocumentType_Code,CardTable,Card_key,' +
        'ProgramArea.Code,ProgramArea.Label,Extension,URL.Base,URL.Complete,URL.Relative',
      cardID: params.cardID,
      type: params.type,
      types: params.types,
      programAreaCodes: params.programAreaCodes,
      nature: params.nature,
      natures: params.natures,
      merchantID: params.merchantID,
      contractID: params.contractID,
      invitationID: params.invitationID,
      drawDownID: params.drawDownID,
      paymentID: params.paymentID,
      programAreaCode: params.programAreaCode,
      dateFrom: params.dateFrom,
      dateTo: params.dateTo,
    };

    this.serviceRef = this.documentService.getDocumentList(payload, params.sessionType).subscribe((res) => {
      this.count = res.length;

      this.items = res;

      for (let i = 0; i < this.items.length; i++) {
        this.setDocumentType(i);
      }

      this.dataChange.next(this.items.slice());
      this.firstLoad = true;

      if (this.items && this.items.length) {
        this.getLength.emit(this.items.length);
      }

      const innerFunction = (section) => {
        section = section + 1;
        UtilsClass.loadingDataSection(section);
        payload.section = section;

        this.serviceRef = this.documentService.getDocumentList(payload, params.sessionType).subscribe((res) => {
          if (res.length > 0) {
            this.count = res.count;

            const length = this.items.length;

            this.items = this.items.concat(res);

            if (this.items && this.items.length) {
              this.getLength.emit(this.items.length);
            }

            for (let i = length; i < this.items.length; i++) {
              this.setDocumentType(i);
            }

            this.dataChange.next(this.items.slice());

            if (this.isDestoyed != true) {
              innerFunction(section);
            }
          } else {
            return true;
          }
        });
        UtilsClass.stopLoadingInterceptor();
      };

      if (this.isDestoyed != true) {
        innerFunction(1);
      }
    });
  }

  setThumbnail(i) {
    this.setDocumentType(i);
    this.items[i]['loading'] = true;

    if (this.items[i] && this.items[i]['Document_key']) {
      this.utilService.getEpdocumentThumbnail(this.items[i]['Document_key']).subscribe((res) => {
        if (res) {
          this.items[i]['pic'] = res;
        }

        this.items[i]['loading'] = false;
      });
    }
  }

  setDocumentType(i) {
    let type = this.util.getDocumentType(this.items[i]);

    if (type === 'pdf') {
      type = 'document';
    }

    this.items[i]['type'] = type;
  }

  ngOnInit() {}

  ngOnDestroy() {
    if (this.serviceRef) {
      this.serviceRef.unsubscribe();
    }
  }
}

export class RecordDataSource extends DataSource<any> {
  _filterChange = new BehaviorSubject('');
  field = '';

  get filter(): any {
    return this._filterChange.value;
  }

  set filter(item: any) {
    this.field = item.field;
    this._filterChange.next(item.value);
  }

  set replaceItemWithNewID(item) {
    let _index = this._tableDatabase.data.findIndex((_obj) => _obj.ID == item.oldID);

    if (_index < 0) {
      _index = this._tableDatabase.data.findIndex((_obj) => _obj.ID == item.ID);
    }

    this._tableDatabase.data[_index] = item;

    this._tableDatabase.dataChange.next(this._tableDatabase.data);
  }

  set replaceItem(item) {
    const _index = this._tableDatabase.data.findIndex((_obj) => _obj.ID == item.ID);
    this._tableDatabase.data[_index] = item;

    this._tableDatabase.dataChange.next(this._tableDatabase.data);
  }

  set addItem(res) {
    this._tableDatabase.data.unshift(res);

    this._tableDatabase.dataChange.next(this._tableDatabase.data);
  }

  set removeItem(id) {
    const data = this._tableDatabase.data.filter((row) => row.ID != id);
    this._tableDatabase.dataChange.next(data);
  }

  filteredData: any[] = [];

  constructor(private _tableDatabase: LoadRecords, private _paginator: MatPaginator, private _sort: MatSort) {
    super();
    this._filterChange.subscribe(() => (this._paginator.pageIndex = 0));
  }

  connect(): Observable<any[]> {
    const displayDataChanges = [
      this._tableDatabase.dataChange,
      this._filterChange,
      this._paginator.page,
      this._sort.sortChange,
    ];

    return observableMerge(...displayDataChanges).pipe(
      map(() => {
        this.filteredData = this._tableDatabase.data.slice().filter((item: any) => {
          let f = '';
          f = item[this.field] || '';

          const searchStr = f.toLowerCase();
          return searchStr.indexOf(this.filter.toLowerCase()) != -1;
        });
        const data = this.getSortedData(this.filteredData.slice());

        const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
        return data.splice(startIndex, this._paginator.pageSize);
      })
    );
  }

  disconnect() {}

  getSortedData(data: any[]): any[] {
    if (!this._sort.active || this._sort.direction == '') {
      return data;
    }

    return data.sort((a, b) => {
      let propertyA: number | string = '';
      let propertyB: number | string = '';

      switch (this._sort.active) {
        case 'documentLabel':
          [propertyA, propertyB] = [a['Document.Label'], b['Document.Label']];
          break;

        case 'Date':
          [propertyA, propertyB] = [new Date(a['DateTimeCreated']).getTime(), new Date(b['DateTimeCreated']).getTime()];
          break;
      }

      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

      return (valueA < valueB ? -1 : 1) * (this._sort.direction == 'asc' ? 1 : -1);
    });
  }
}
