import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ThirdPartyAccessCreateComponent } from '../third-party-access-create/third-party-access-create.component';
import { ThirdPartyAccessViewComponent } from '../third-party-access-view/third-party-access-view.component';
import { ThirdPartyClientCreateEditComponent } from '../third-party-client-create-edit/third-party-client-create-edit.component';
import { ThirdPartyClientQuickEditComponent } from '../third-party-client-quick-edit/third-party-client-quick-edit.component';
import { ThirdPartyClientViewComponent } from '../third-party-client-view/third-party-client-view.component';

const routes: Routes = [
  {
    path: 'ThirdPartyAccessCreateComponent',
    component: ThirdPartyAccessCreateComponent,
  },


  {
    path: 'ThirdPartyAccessViewComponent',
    component: ThirdPartyAccessViewComponent,
  },
  {
    path: 'ThirdPartyClientCreateEditComponent',
    component: ThirdPartyClientCreateEditComponent,
  },

  {
    path: 'ThirdPartyClientViewComponent',
    component: ThirdPartyClientViewComponent,
  },

  {
    path: 'ThirdPartyClientQuickEditComponent',
    component: ThirdPartyClientQuickEditComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ThirdPartyRoutingModule {}
