import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InstantOfferCreateComponent } from '../instant-offer-create/instant-offer-create.component';
import { InstantOfferExternalLinkViewComponent } from '../instant-offer-external-link-view/instant-offer-external-link-view.component';
import { InstantOfferExternalLinkComponent } from '../instant-offer-external-link/instant-offer-external-link.component';
import { InstantOfferSettingsComponent } from '../instant-offer-settings/instant-offer-settings.component';
import { InstantOfferViewComponent } from '../instant-offer-view/instant-offer-view.component';

const routes: Routes = [
  {
    path: 'InstantOfferExternalLinkViewComponent',
    component: InstantOfferExternalLinkViewComponent,
  },
  {
    path: 'InstantOfferSettingsComponent',
    component: InstantOfferSettingsComponent,
  },
  {
    path: 'InstantOfferCreateComponent',
    component: InstantOfferCreateComponent,
  },
  {
    path: 'InstantOfferViewComponent',
    component: InstantOfferViewComponent,
  },
  {
    path: 'InstantOfferExternalLinkComponent',
    component: InstantOfferExternalLinkComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class InstantFinanceOfferRoutingModule {}
