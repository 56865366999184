import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import 'rxjs/Rx';
import { UtilsService } from '../../services/utils.service';
import { FileContentComponent } from '../file-content/file-content.component';

@Component({
  selector: 'app-file-view',
  templateUrl: './file-view.component.html',
  styleUrls: ['./file-view.component.css'],
})
export class FileViewComponent implements OnInit {
  @Input() files: any[] = [];
  @Input() title = 'Documents';
  @Input() canEdit = false;
  @Input() invitationID = '';
  @Output() remove = new EventEmitter();
  file: any;

  constructor(private dialog: MatDialog, private utilsService: UtilsService) {}

  ngOnInit() {
    if (this.files[0] && typeof this.files[0] === 'object') {
      this.file = this.files[0];
    } else {
      this.utilsService.getFileDetails(this.files[0]).subscribe((res) => {
        this.file = res;
      });
    }
  }

  openDialog() {
    const dialogRef = this.dialog.open(FileContentComponent, {
      data: {
        files: this.files,
        canEdit: this.canEdit,
        invitationID: this.invitationID,
      },
      width: '650',
    });

    dialogRef.componentInstance.close.subscribe((res) => {
      if (res == true) {
        dialogRef.close();
        this.file = null;
      }
    });
    dialogRef.componentInstance.remove.subscribe((res) => {
      this.files.splice(res.id, 1);

      this.remove.emit(res);
    });
  }
}
