import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayFirst',
})
export class arrayFirst implements PipeTransform {
  constructor() {}

  transform(expression: any) {
    try {
      const result = [];

      if (Array.isArray(expression) && expression[0]) {
        result.push(expression[0]);

        return result;
      } else {
        return expression;
      }
    } catch (e) {
      return expression;
    }
  }
}
