import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';

import { animate, state, style, transition, trigger } from '@angular/animations';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { MerchantViewComponent } from '../merchant-view/merchant-view.component';
import { MerchantService } from '../shared/merchant.service';

@Component({
  selector: 'app-merchant-select-list',
  templateUrl: './merchant-select-list.component.html',
  styleUrls: ['./merchant-select-list.component.css'],
  animations: [
    trigger('simpleFadeAnimation', [
      state('in', style({ opacity: 1, height: '*' })),
      transition(':enter', [style({ opacity: 0, height: 0 }), animate(300)]),
      transition(':leave', animate(300, style({ opacity: 0, height: 0 }))),
    ]),
  ],
})
export class MerchantSelectListComponent implements OnInit {
  @Input() merchantID;

  @Input() title = 'Please select a merchant ';

  @Output() close = new EventEmitter();

  @Output() getMerchant = new EventEmitter();

  merchants = [];

  isAdminOrPromoter = false;

  merchant;

  constructor(
    private merchantService: MerchantService,
    private authenticationService: AuthenticationService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.merchantID) {
        this.merchantID = data.merchantID;
      }

      if (data.title) {
        this.title = data.title;
      }
    }
  }

  ngOnInit() {
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isAdminOrPromoter = res;

      const p = {
        fields: 'ID,Name,FirstName,TradingAs,CalculatedName,Status',
      };

      this.merchantService.getList(p, this.isAdminOrPromoter).subscribe((res) => {
        if (res) {
          this.merchants = res;

          if (this.merchantID) {
            for (let i = 0; i < this.merchants.length; i++) {
              if (this.merchants[i] && this.merchants[i]['ID'] && this.merchants[i]['ID'] == this.merchantID) {
                this.merchant = this.merchants[i];
              }
            }
          }
        }
      });
    });
  }

  selectMerchant() {
    if (this.merchant) {
      this.getMerchant.emit(this.merchant);
    } else {
      this.getMerchant.emit(null);
    }
  }

  viewMerchant() {
    if (this.merchant && this.merchant['ID']) {
      const ref = RootAppComponent.dialog.open(MerchantViewComponent, {
        data: this.merchant['ID'],
        width: '600px',
      });
      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    }
  }

  closeEvent() {
    this.close.emit(true);
  }
}
