import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export class ConfirmModalConfig {
  icon?: string;
  classIcon?: string;
  title?: string;
  message?: string;
  customClass?: string;
  acceptButtonText?: string;
  rejectButtonText?: string;
  hideAllDefaultButtons?: boolean;
  hideDefaultAcceptButton?: boolean;
  hideDefaultRejectButton?: boolean;
}

@Component({
  selector: 'ipv-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.css'],
})
export class ConfirmModalComponent {
  @Output() closed: EventEmitter<void> = new EventEmitter();

  @Output() acceptButtonClicked: EventEmitter<void> = new EventEmitter();
  @Output() rejectButtonClicked: EventEmitter<void> = new EventEmitter();

  @Input() icon = '';
  @Input() classIcon = 'fa fa-trash';
  @Input() title = 'Are you sure?';

  @Input() hideCloseButton = false;

  @Input() customClass: null | string = null;
  classNames = {};

  @Input() message: null | string = `<p>Message content</p>`;
  @Input() hideAllDefaultButtons = false;
  @Input() enableDragging = false;

  @Input() hideDefaultAcceptButton = false;
  @Input() hideDefaultRejectButton = false;

  @Input() acceptButtonText = 'Yes, delete';
  @Input() rejectButtonText = 'No';

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: ConfirmModalConfig,
    private dialogRef: MatDialogRef<ConfirmModalComponent>
  ) {
    if (this.data) {
      this.icon = this.data.icon ? this.data.icon : this.icon;
      this.classIcon = this.data.classIcon ? this.data.classIcon : this.classIcon;
      this.title = this.data.title ? this.data.title : this.title;
      this.message = this.data.message ? this.data.message : this.message;
      this.customClass = this.data.customClass ? this.data.customClass : this.customClass;
      this.hideAllDefaultButtons = this.data.hideAllDefaultButtons
        ? this.data.hideAllDefaultButtons
        : this.hideAllDefaultButtons;
      this.hideDefaultAcceptButton = this.data.hideDefaultAcceptButton
        ? this.data.hideDefaultAcceptButton
        : this.hideDefaultAcceptButton;
      this.hideDefaultRejectButton = this.data.hideDefaultRejectButton
        ? this.data.hideDefaultRejectButton
        : this.hideDefaultRejectButton;
      this.acceptButtonText = this.data.acceptButtonText ? this.data.acceptButtonText : this.acceptButtonText;
      this.rejectButtonText = this.data.rejectButtonText ? this.data.rejectButtonText : this.rejectButtonText;
    }

    if (this.customClass === null) {
      this.customClass = this.icon;
    }

    const classNamesString = `{"${this.customClass}":true}`;

    this.classNames = JSON.parse(classNamesString);
  }

  close() {
    this.closed.emit();
  }

  acceptButtonClick() {
    this.acceptButtonClicked.emit();
  }

  rejectButtonClick() {
    this.dialogRef.close();
  }
}
