import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { environment } from 'environments/environment';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { FileInsertionComponent } from '../../../shared/components/file-insertion/file-insertion.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { DentistService } from '../../dentist/shared/dentist.service';
import { LandingPageHeaderLookupComponent } from '../../document/landing-page-header-lookup/landing-page-header-lookup.component';
import { MerchantService } from '../../merchant/shared/merchant.service';
import { ProductService } from '../../product/shared/product.service';
import { ProductUtil } from '../../product/shared/productUtil.type';
import { InstantOfferService } from '../shared/instant-finance-offer.service';

@Component({
  selector: 'instant-offer-settings',
  templateUrl: './instant-offer-settings.component.html',
  styleUrls: ['./instant-offer-settings.component.css'],
})
export class InstantOfferSettingsComponent implements OnInit {
  merchantID;

  settings: any = Settings.global;

  title = 'Simple & Painless Financing for Dental Procedures';

  _description = `<p>At {{PracticeTradingAs}} , we pride ourselves on providing our patients with straightforward, open, and transparent solutions.  <br><br>
So with ${Settings.global['brandName']} {{PatientFirstName}} , we are giving you 3 easy to understand options for a range of dental procedures.</p>`;
  _message = `<p>Hi {{PatientFirstName}},<br>
As discussed, we provide payment options for your next procedure. Your new Smile is just three steps away.
<ul>
<li> <strong>Step 1:</strong> Choose the product right for you, and our simple and guided application process takes as little as 5 minutes to complete (depending on the selected product).</li>
<li> <strong>Step 2:</strong> ${Settings.global['brandName']} will notify us once approved, and you are free to commence treatment.
${Settings.global['brandName']} settles your bill with us as the procedure is completed - Hassle-free.</li>
<li> <strong>Step 3:</strong> Smile and enjoy - {{PatientFirstName}} now you can confidently continue your treatment, knowing ${Settings.global['brandName']} has removed the financial burden. <br>
If you have any further queries on which plan suits your needs, feel free to call the team at ${Settings.global['brandName']} on {{PromoterPhoneNumber}}. </li>
</ul>
<br>
Kind Regards<br>
{{DentistFirstName}} <br>
{{PracticeTradingAs}} <br>
{{PracticePhoneNumber}}</p>`;

  description;
  descriptionRaw;
  messageRaw;
  message;

  isLandingPagePublic = false;
  maxLandingPageAmount = null;

  theme = {
    code: 'blue',
    background: 'linear-gradient(135deg, #0092eb 0%, #0dbad3 100%)',
    color: '#1b8bdd',
  };
  themes;

  loadMessage = false;
  loadContact = false;
  loadMerchant = false;
  loadPromoter = false;
  loadCard = true;

  contactID;
  merchant;
  promoter;
  card: any = {
    ID: '00000000000000000000',
    Salutation: 'Mr',
    FirstName: 'John',
    MiddleName: 'Al',
    Name: 'Doe',
    'mobiles.Number': '0061101010101',
    'phones.Number': '0062202020202',
    'emails.Email': 'john.doe@smileright.com.au',
    'addresses.Calculated': 'PO Box 7795 Cloisters Square 6850 Western Australia',
  };

  _disclaimerMessage;
  disclaimerMessage;
  disclaimerMessageRaw;
  displayDisclaimerMessage = false;
  contact;

  displayPatient = true;
  messageMode = 'all';
  tableName;
  sessionType;
  isPromoterOrAdmin = false;
  financedProductGroup = [];
  dentists;
  productGroup;
  maxLoanAmount;
  minLoanAmount;

  headerImage =
    'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/character/dentist_hero_pair.png';
  productUtil = new ProductUtil();

  displayAppointmentPage = true;
  displayTipsAndOffersPage = true;
  displayGoingGreenPage = true;
  isHeaderImage = true;

  @Input()
  submitLabel = 'Submit';
  @Output()
  getTheme = new EventEmitter();

  @Output()
  getResult = new EventEmitter();

  saveOnInit = false;
  constructor(
    private instantOfferService: InstantOfferService,
    private merchantService: MerchantService,
    private productService: ProductService,
    private dentistService: DentistService,
    private authenticationService: AuthenticationService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    this.description = this._description;
    this.descriptionRaw = this._description;

    this.message = this._message;
    this.messageRaw = this._message;

    if (data) {
      if (data && data.card) {
        this.card = data.card;
      }
      if (data && data.merchant) {
        this.merchant = data.merchant;
      }
      if (data && data.messageMode) {
        this.messageMode = data.messageMode;
      }

      if (data && data.contactID) {
        this.contactID = data.contactID;
      }

      if (data && data.merchantID) {
        this.merchantID = data.merchantID;
      }

      if (data && data.promoter) {
        this.promoter = data.promoter;
      }
      if (data && data.contact) {
        this.contact = data.contact;
      }

      if (data && data.tableName) {
        this.tableName = data.tableName;
      }

      if (data && data.title) {
        this.title = data.title;
      }
      if (data && data.description) {
        this.description = data.description;
      }
    }
  }
  ngOnInit() {
    if (Settings.global['context'] === 'p4p') {
      this.theme = {
        code: 'ee350b',
        background: ' linear-gradient(-135deg, #1caaa0 -10.31%, #7462ef  97.05%)',
        color: '#7462ef',
      };

      this.headerImage =
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/duke-contact+-+Copy.png?type=b';

      this.title = 'Simple & Painless Financing';
      this._description = `<p>At {{PracticeTradingAs}} , we pride ourselves on providing our customers with straightforward, open, and transparent solutions.
      <br /><br />
      So with ${Settings.global['brandName']} {{PatientFirstName}} , we are giving you 3 easy to understand options for a range of consumer purchases.</p>`;

      this._message = `<p>Hi {{PatientFirstName}},<br>
      As discussed, we provide payment options for your next procedure.
      <ul>
      <li> <strong>Step 1:</strong> Choose the product right for you, and our simple and guided application process takes as little as 5 minutes to complete (depending on the selected product).</li>
      <li> <strong>Step 2:</strong> ${Settings.global['brandName']} will notify us once approved, and you are free to commence treatment.
      ${Settings.global['brandName']} settles your bill with us as the procedure is completed - Hassle-free.</li>
      <li> <strong>Step 3:</strong> Smile and enjoy - {{PatientFirstName}} now you can confidently continue your treatment, knowing ${Settings.global['brandName']} has removed the financial burden. <br>
      If you have any further queries on which plan suits your needs, feel free to call the team at ${Settings.global['brandName']} on {{PromoterPhoneNumber}}. </li>
      </ul>
      <br>
      Kind Regards<br>
      {{DentistFirstName}} <br>
      {{PracticeTradingAs}} <br>
      {{PracticePhoneNumber}}</p>`;

      this.description = this._description;
      this.descriptionRaw = this._description;

      this.message = this._message;
      this.messageRaw = this._message;
    } else if (Settings.global['context'] === 'breeze') {
      this.title = 'Simple & Painless Financing';
      this._description = `<p>At {{PracticeTradingAs}} , we pride ourselves on providing our customers with straightforward, open, and transparent solutions.
      <br /><br />
      So with ${Settings.global['brandName']} {{PatientFirstName}} , we are giving you 3 easy to understand options for a range of consumer purchases.</p>`;
      this._message ==
        `<p>Hi {{PatientFirstName}},</p><p><br />
      As we talked about, we provide payment options for your next purchase. Your new Kirby is just three steps away.</p>
      <ul>
      <li><strong>Step 1:</strong> Choose the product right for you, and our simple and guided application process takes as little as 5 minutes to complete (depending on the selected product). </li>
      <br /> <li><strong>Step 2:</strong> Breeze will let us know once approved. Breeze settles your bill with us, and we delivery your goods - Hassle-free.</li>

      <li>
      <strong>Step 3:</strong> Enjoy - {{PatientFirstName}} now you can confidently continue to enjoy your new Kirby, knowing Breeze has removed the financial burden. <br />
      If you have any further queries on which plan suits your needs, please call the team at Breeze on {{PromoterPhoneNumber}}.</li>

      </ul><p>
      &nbsp;<br /><br />
      Kind Regards<br />{{DentistFirstName}} <br />
      {{PracticeTradingAs}} <br />
      {{PracticePhoneNumber}}</p>`;
      this.headerImage = 'https://breezelivingwebsite.s3.ap-southeast-2.amazonaws.com/Images/MSSS-3-600x600.jpg?type=c';

      this.description = this._description;
      this.descriptionRaw = this._description;

      this.message = this._message;
      this.messageRaw = this._message;
    } else {
      this.description = this._description;
      this.descriptionRaw = this._description;

      this.message = this._message;
      this.messageRaw = this._message;
    }

    // let ref5 = RootAppComponent.dialog.open(InstantOfferExternalLinkViewComponent, {
    //   data: {
    //   },
    //   width: "900px",
    //   panelClass: 'noCard',
    // });

    // ref5.componentInstance.close.subscribe((res) => {
    //   ref5.close();
    // });

    // let ref2 = RootAppComponent.dialog.open(InstantOfferCreateComponent, {
    //   data: {
    //     merchantID: this.merchantID,
    //     isInstantFinanceLink: true,
    //   },
    //   width: '70vw',
    //   height: '95vh',
    //   panelClass: 'noCard',
    // });

    this.instantOfferService.instantOfferListTheme().subscribe((t) => {
      if (t && t.length > 0) {
        this.themes = t;
      }

      this.authenticationService.getCurrentDentist().subscribe((r) => {
        this.authenticationService.getSessionType().subscribe((sessionType) => {
          this.sessionType = sessionType;
          this.authenticationService.isPromoterOrAdmin().subscribe((r) => {
            this.isPromoterOrAdmin = r;

            if (!this.merchantID && !this.merchant) {
              this.authenticationService.getCurrentPractice().subscribe((r) => {
                if (r && r.ID) {
                  this.merchantID = r.ID;
                  this.merchant = r;

                  this.dentistService
                    .getList({ merchantID: this.merchantID, fields: 'ID,CalculatedName' }, this.isPromoterOrAdmin)
                    .subscribe((res) => {
                      if (res && res.length > 0) {
                        this.dentists = res;
                      }
                    });

                  this.init();
                }
              });
            } else if ((this.merchant && this.merchant.ID) || this.merchantID) {
              if (this.merchant && this.merchant.ID) {
                if (!this.merchantID) {
                  this.merchantID = this.merchant.ID;
                }
                this.dentistService
                  .getList({ merchantID: this.merchantID, fields: 'ID,CalculatedName' }, this.isPromoterOrAdmin)
                  .subscribe((res) => {
                    if (res && res.length > 0) {
                      this.dentists = res;
                    }
                  });

                this.init();
              } else {
                this.authenticationService.getMerchantDetails(this.merchantID, {}, this.sessionType).subscribe((r) => {
                  if (r && r.ID) {
                    this.merchant = r;

                    this.dentistService
                      .getList({ merchantID: this.merchantID, fields: 'ID,CalculatedName' }, this.isPromoterOrAdmin)
                      .subscribe((res) => {
                        if (res && res.length > 0) {
                          this.dentists = res;
                        }
                      });

                    this.init();
                  }
                });
              }
            }
          });
        });
      });
    });
  }

  init() {
    if (this.merchant && this.merchant.ID) {
      this.buildMerchant(this.merchant);
    }

    const p1 = {
      merchantID: this.merchantID,
      isFinance: true,
    };

    this.productService.getMerchantProductGroup(p1, this.isPromoterOrAdmin).subscribe((res) => {
      if (res && res.length > 0) {
        const index = res.findIndex((item) => {
          if (item && item['GroupCode'] === 'GPF') {
            return true;
          }
          return false;
        });
        if (index != -1 && !this.productGroup) {
          this.financedProductGroup = res;
          this.productGroup = 'GPF';
        } else if (!this.productGroup) {
          this.financedProductGroup = res;
          if (res && res[0] && res[0]['GroupCode']) {
            this.productGroup = res[0]['GroupCode'];
          }
        } else {
          this.financedProductGroup = res;
        }
      }

      this.loadMessage = true;
      if (this.productGroup) {
        this.getLoanAmount(this.productGroup);
      }

      this.setup();
    });
  }

  galleryHeader() {
    const ref2 = RootAppComponent.dialog.open(LandingPageHeaderLookupComponent, {
      data: {
        merchantID: this.merchantID,
      },
      width: '900px',
    });

    ref2.componentInstance.close.subscribe((res) => {
      ref2.close();
    });

    ref2.componentInstance.getResult.subscribe((res) => {
      if (res) {
        this.headerImage = res;
        ref2.close();
      }
    });
  }
  upload() {
    const ref = RootAppComponent.dialog.open(FileInsertionComponent, {
      data: {
        documentType: 'LP_HI',
        displayGallery: false,
        isGallery: true,
        isFixedAspectRatio: true,
        roundCropper: true,
        aspectRatioType: 'full',
        documentNature: 'image',
        keepOriginal: false,
        maxFileSize: 1,
      },
      width: '600px',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
    ref.componentInstance.getResult.subscribe((document) => {
      if (document && document.AWS_Bucket_Name && document.AWS_URL) {
        this.headerImage = `${Settings.global['AWSS3Prefix']}/${document.AWS_Bucket_Name}${document.AWS_URL}?type=c`;

        ref.close();
      }
    });
  }
  getMerchantLink() {
    return environment.nodeUrl + '/merchant/practice-picture-stream/' + this.merchantID;
  }

  getCircle(headerImage) {
    if (headerImage && headerImage.indexOf('?type=c') != -1) {
      return true;
    }

    return false;
  }

  buildMerchant(merchant) {
    if (merchant) {
      if (merchant['Hide_Landing_Page_HdThumbnail'] === '1') {
        this.isHeaderImage = false;
      } else {
        this.isHeaderImage = true;
      }

      if (merchant['Hide_Landing_Page_Appointment'] === '1') {
        this.displayAppointmentPage = false;
      } else {
        this.displayAppointmentPage = true;
      }

      if (merchant['Hide_Landing_Page_Going_Green'] === '1') {
        this.displayGoingGreenPage = false;
      } else {
        this.displayGoingGreenPage = true;
      }

      if (merchant['Hide_Landing_Page_Tips'] === '1') {
        this.displayTipsAndOffersPage = false;
      } else {
        this.displayTipsAndOffersPage = true;
      }

      if (merchant['Is_Landing_Page_Public'] === '1') {
        this.isLandingPagePublic = true;
      } else {
        this.isLandingPagePublic = false;
      }

      if (merchant['Landing_Page_Default_Label']) {
        this.title = merchant['Landing_Page_Default_Label'];
      }

      if (merchant['Landing_Page_Default_Descrpt']) {
        this.description = merchant['Landing_Page_Default_Descrpt'];
        this._description = merchant['Landing_Page_Default_Descrpt'];
        this.descriptionRaw = merchant['Landing_Page_Default_Descrpt'];
      } else {
        this.saveOnInit = true;
      }

      if (merchant['Landing_Page_Default_Message']) {
        this.message = merchant['Landing_Page_Default_Message'];
        this._message = merchant['Landing_Page_Default_Message'];
        this.messageRaw = merchant['Landing_Page_Default_Message'];
      } else {
        this.saveOnInit = true;
      }

      if (merchant['Landing_Page_Default_Message']) {
        this.message = merchant['Landing_Page_Default_Message'];
        this._message = merchant['Landing_Page_Default_Message'];
        this.messageRaw = merchant['Landing_Page_Default_Message'];
      } else {
        this.saveOnInit = true;
      }

      if (this.merchant['Hide_Disclaimer_Message'] === '1') {
        this.displayDisclaimerMessage = false;
      } else if (this.merchant['Hide_Disclaimer_Message'] === '0') {
        this.displayDisclaimerMessage = true;
      }

      if (this.merchant['Disclaimer_Message']) {
        this.disclaimerMessage = this.merchant['Disclaimer_Message'];
        this._disclaimerMessage = this.merchant['Disclaimer_Message'];
        this.disclaimerMessageRaw = this.merchant['Disclaimer_Message'];
      }

      if (merchant['Landing_Page_Default_Sender_key']) {
        this.contactID = merchant['Landing_Page_Default_Sender_key'];
      }

      if (merchant['Landing_Page_Default_Thumbnail']) {
        this.headerImage = merchant['Landing_Page_Default_Thumbnail'];
      } else {
        this.saveOnInit = true;
      }
      if (merchant['Landing_Page_Default_Product_Group']) {
        this.productGroup = merchant['Landing_Page_Default_Product_Group'];
      }

      if (
        merchant['Max_Landing_Page_Amount'] &&
        merchant['Max_Landing_Page_Amount'] !== '' &&
        merchant['Max_Landing_Page_Amount'] !== '0'
      ) {
        this.maxLandingPageAmount = merchant['Max_Landing_Page_Amount'];
      } else {
        this.maxLandingPageAmount = null;
      }

      if (merchant['Landing_Page_Default_Theme'] && this.themes && this.themes.length > 0) {
        const index = this.themes.findIndex((e) => {
          if (e && e.code && e.code === merchant['Landing_Page_Default_Theme']) {
            return true;
          }

          return false;
        });

        if (index !== -1) {
          this.theme = this.themes[index];
          this.getTheme.emit(this.theme);
        }
      }
      if (this.saveOnInit === true) {
        this.submit();
      }
    }
  }

  setup() {
    if (!this.promoter) {
      this.authenticationService.getPromoter().subscribe((r) => {
        if (r) {
          this.promoter = r;
        }
        this.loadPromoter = true;
      });
    } else {
      this.loadPromoter = true;
    }

    if (!this.contactID && !this.contact) {
      this.authenticationService.getCurrentDentist().subscribe((r) => {
        if (r) {
          this.contact = r;
          this.contactID = r.ID;
        }
        this.loadContact = true;
      });
    } else if (this.contact && this.contact.ID) {
      this.contactID = this.contact.ID;
      this.loadContact = true;
    } else if (this.contactID) {
      this.authenticationService.getCardDetails(this.contactID, 'Contact', {}, this.sessionType).subscribe((r) => {
        if (r && r.ID) {
          this.contact = r;
          this.contactID = r.ID;
        }
        this.loadContact = true;
      });
    }

    if (!this.merchantID && !this.merchant) {
      this.authenticationService.getCurrentPractice().subscribe((r) => {
        if (r) {
          this.merchant = r;
        }
        this.loadMerchant = true;
      });
    } else if (this.merchant && this.merchant.ID) {
      this.merchantID = this.merchant.ID;
      this.loadMerchant = true;
    } else if (this.merchantID) {
      this.authenticationService.getCardDetails(this.merchantID, 'Merchant', {}, this.sessionType).subscribe((r) => {
        if (r && r.ID) {
          this.merchant = r;
          this.merchantID = r.ID;
        }
        this.loadMerchant = true;
      });
    }
  }

  changeDentist() {
    if (this.contactID) {
      this.loadContact = false;
      this.authenticationService.getCardDetails(this.contactID, 'Contact', {}, this.sessionType).subscribe((r) => {
        if (r && r.ID) {
          this._description = this.descriptionRaw;
          this._message = this.messageRaw;
          this.contact = r;
          this.contactID = r.ID;
        }
        this.loadContact = true;
      });
    }
  }

  getLoanAmount(productCode) {
    if (productCode) {
      const payload = {
        groupCode: productCode,
        merchantID: this.merchantID,
        fields: 'Parameter.MaxLoanValue,Parameter.MinLoanValue',
      };
      this.productService.getList(payload, this.isPromoterOrAdmin).subscribe((res) => {
        if (res && res.length > 0) {
          const temp = res.map((x) => Number(x['Parameter.MaxLoanValue']));
          this.maxLoanAmount = Math.max(...temp) || 0;

          const temp2 = res.map((x) => Number(x['Parameter.MinLoanValue']));
          this.minLoanAmount = Math.min(...temp2) || 0;
        }
      });
    }
  }
  getResultDescription(e) {
    if (e) {
      this.description = e.convertedMessage;
      this.descriptionRaw = e.message;
    } else {
      this.description = null;
      this.descriptionRaw = null;
    }
  }
  getResultMessage(e) {
    if (e) {
      this.message = e.convertedMessage;

      this.messageRaw = e.message;
    } else {
      this.message = null;
      this.messageRaw = null;
    }
  }

  getResultMessageDisclaimer(e) {
    if (e) {
      this.disclaimerMessage = e.convertedMessage;

      this.disclaimerMessageRaw = e.message;
    } else {
      this.disclaimerMessage = null;
      this.disclaimerMessageRaw = null;
    }
  }

  submit() {
    const payload = {
      landingPageHideAppointmentPage: !this.displayAppointmentPage,
      landingPageHideTipsAndOffersPage: !this.displayTipsAndOffersPage,
      landingPageHideGoingGreenPage: !this.displayGoingGreenPage,
      landingPageHideHeaderImage: !this.isHeaderImage,

      isLandingPagePublic: this.isLandingPagePublic,

      landingPageTheme: this.theme.code,
      landingPageMaxAmount: this.maxLandingPageAmount,
      landingPageLabel: this.title,
      landingPageDescription: this.descriptionRaw,
      landingPageMessage: this.messageRaw,
      landingPageContactID: this.contactID,
      landingPageThumbnail: this.headerImage,
      ladingPageProductGroup: this.productGroup,
      disclaimerMessage: this.disclaimerMessageRaw,
      displayDisclaimerMessage: this.displayDisclaimerMessage,

      merchantID: this.merchantID,
    };

    if (!this.maxLandingPageAmount || this.maxLandingPageAmount == 0 || this.maxLandingPageAmount == '0') {
      payload.landingPageMaxAmount = 'none';
    }

    if (this.isPromoterOrAdmin == true) {
      this.merchantService.editMerchant(this.merchantID, payload).subscribe((r) => {
        if (r && r.ID) {
          NotifyAppComponent.displayToast('success', 'Successfull', `Settings are updated.`);

          this.getResult.emit(r);
        }
      });
    } else {
      this.authenticationService.editCurrentPractice(payload).subscribe((r) => {
        if (r && r.ID) {
          NotifyAppComponent.displayToast('success', 'Successfull', `Settings are updated.`);

          this.getResult.emit(r);
        }
      });
    }
  }

  selectTheme(d) {
    if (d && d.code) {
      this.theme = d;

      this.getTheme.emit(this.theme);
    }
  }
}
