import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UtilsService } from '../../services/utils.service';
import { NotifyAppComponent } from '../../types/notify-app-component';
import { UtilsClass } from '../../types/utils/utils.class';
import { BsbLookupComponent } from '../bsb-lookup/bsb-lookup.component';

@Component({
  selector: 'app-bank-account-input',
  templateUrl: './bank-account-input.component.html',
  styleUrls: ['./bank-account-input.component.css'],
})
export class BankAccountInputComponent implements OnInit {
  @Input()
  title = 'Bank account details';

  @Input()
  bankBSB;

  @Input()
  bankName;

  @Input()
  bankBranch;

  @Input()
  accountName;

  @Input()
  accountNumber;

  @Input()
  accountType;

  accountTypes = [];

  @Output()
  validate = new EventEmitter();

  @Output()
  getBankAccount = new EventEmitter();

  requiredLabel = 'required';

  bsbValid = true;
  isValidate = false;
  bank;
  @Input()
  isRequired = true;

  util = new UtilsClass();

  iconAddres = 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/bank-icon-map.png';

  constructor(private dialog: MatDialog, private utilService: UtilsService) {}

  ngOnInit() {
    if (this.isRequired == false) {
      this.requiredLabel = null;
    }

    this.utilService.getCodeLookup('BankAccountType').subscribe((res) => {
      if (res && res.length > 0) {
        this.accountTypes = res;
      }

      this.sendResult();
    });
  }

  validateBsb() {
    this.utilService.searchByBsb(this.bankBSB).subscribe((res) => {
      if (res.length) {
        this.bsbValid = true;
        // debugger
        this.bank = res[0];

        if (this.bank && this.bank['Bank.Code'] && this.bank['Branch.Name']) {
          this.bankName = this.bank['Bank.Code'];
          this.bankBranch = this.bank['Branch.Name'];
        } else {
          this.bankName = null;
          this.bankBranch = null;
        }
      } else {
        this.bsbValid = false;
        this.bsbLookup();

        NotifyAppComponent.displayToast('warning', 'Invalid BSB', 'Please use "BSB lookup"  to find your bsb Number');
      }
    });
  }

  bsbLookup() {
    const ref = this.dialog.open(BsbLookupComponent, {
      width: '750px',
      height: '660px',
    });
    const sub = ref.componentInstance.close.subscribe((data) => {
      ref.close();
    });

    ref.componentInstance.getBSB.subscribe((res) => {
      if (res) {
        this.bsbValid = true;
        this.bank = res;

        if (this.bank && this.bank['Bank.Code'] && this.bank['Branch.Name']) {
          this.bankName = this.bank['Bank.Code'];
          this.bankBranch = this.bank['Branch.Name'];
        } else {
          this.bankName = null;
          this.bankBranch = null;
        }

        this.bankBSB = res.BSB;

        this.sendResult();
      }

      ref.close();
    });
  }

  sendResult() {
    this.validateField();
    const bankObject = {
      paymentType: 'bank',
      bankBSB: this.bankBSB,
      bankName: this.bankName,
      bankBranch: this.bankBranch,
      accountName: this.accountName,
      accountNumber: this.accountNumber,
      accountType: this.accountType,
    };

    this.getBankAccount.emit(bankObject);
  }

  validateField() {
    let ruleValidator;

    if (this.isRequired != false) {
      ruleValidator = {
        bankBSB: 'string|required',
        bankName: 'string|required',
        bankBranch: 'string|required',
        accountName: 'string|required',
        accountNumber: 'string|required',
        accountType: 'string',
      };
    } else {
      ruleValidator = {
        bankBSB: 'string',
        bankName: 'string',
        bankBranch: 'string',
        accountName: 'string',
        accountNumber: 'string',
        accountType: 'string',
      };
    }

    const bankObject = {
      bankBSB: this.bankBSB,
      bankName: this.bankName,
      bankBranch: this.bankBranch,
      accountName: this.accountName,
      accountNumber: this.accountNumber,
      accountType: this.accountType,
    };

    this.isValidate = this.util.validateFields(bankObject, ruleValidator);

    this.validate.emit(this.isValidate);
  }
}
