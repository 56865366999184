import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { WizardComponent } from 'angular-archwizard';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { TemplateReviewComponent } from '../../../core/helper/template-review/template-review.component';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { LookupService } from '../../../shared/services/lookup.service';
import { UserDataService } from '../../../shared/services/user-data.service';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { DentistService } from '../../dentist/shared/dentist.service';
import { ExistingPatientService } from '../../existing-patient/shared/existing-patient.service';
import { MerchantViewComponent } from '../../merchant/merchant-view/merchant-view.component';
import { MerchantService } from '../../merchant/shared/merchant.service';
import { SmsConversationComponent } from '../../message/sms-conversation/sms-conversation.component';
import { SmsPromoterComponent } from '../../message/sms-promoter/sms-promoter.component';
import { ProductService } from '../../product/shared/product.service';
import { TreatmentService } from '../../treatment/shared/treatment.service';
import { BeforeStartingLendingComponent } from '../before-starting-lending/before-starting-lending.component';
import { InvitationLookupComponent } from '../invitation-lookup/invitation-lookup.component';
import { InvitationViewSmsComponent } from '../invitation-view-sms/invitation-view-sms.component';
import { InvitationService } from '../shared/services/invitation.service';

@Component({
  selector: 'app-lending-invitation-create',
  templateUrl: './lending-invitation-create.component.html',
  styleUrls: ['./lending-invitation-create.component.css'],
})
export class LendingInvitationCreateComponent implements OnInit {
  @ViewChild(WizardComponent, { static: true }) public wizard: WizardComponent;
  defaultAmount = 5000;
  sendSMS = false;
  minAmount = 5000;
  maxAmount = 50000;
  isSelectedSender = false;
  merchants = [];
  serviceType;
  serviceTypes = [];
  merchantID;
  invitationDocuments = [];

  consumerCanChange = true;
  isStep2 = false;
  context = Settings.global['context'];
  isPromoterOrAdmin = false;
  isMobileValid = false;
  isHomePhoneValid = false;

  isWorkPhoneValid = false;
  isEmailValid = false;
  dentMsg;
  maxDuration = 60;
  minDuration = 1;

  titles = [];

  genders = [];

  security;

  invitationID = '';
  user = {
    firstname: '',
    title: '',
    lastname: '',
    middlename: '',
    gender: '',
    mobile: '',
    home_phone: '',
    work_phone: '',
    email: '',
  };

  products = [];

  assets = [
    { value: '0', viewValue: 'House' },
    { value: '1', viewValue: 'Investment Property' },
    { value: '2', viewValue: 'Shares' },
    { value: '2', viewValue: 'Car' },
    { value: '2', viewValue: 'Bike/Caravan/Boat' },
    { value: '2', viewValue: 'Interest' },
    { value: '2', viewValue: 'Other' },
  ];

  documentTypes = ['Product Description', 'Additional Document', 'Image', 'Technical Specs'];

  dentMsgOptions = [
    {
      key: 'DefaultMessage1',
      value:
        'Attached is the lending product we discussed earlier. Please take a look and let us know how you would like to proceed!',
    },
    {
      key: 'DefaultMessage2',
      value: "Thanks for getting in touch. Here's the details of your finance as discussed.  ",
    },
    {
      key: 'DefaultMessage3',
      value:
        'Glad we could help! Attached is all the details of your loan, please take a look at let me know if anything needs changing before you proceed.',
    },
    { key: 'DefaultMessage4', value: 'Please enter your own message here!' },
  ];

  messageOption;

  duration;
  amount = this.defaultAmount;
  productID;
  patientContribution;
  patientContributionInput;

  durationInput;
  amountInput = this.defaultAmount;

  frequency = 4;
  frequencyInput = 4;

  productIDInput;

  removable = true;
  groupCode = 'GPF';
  selectedSender;
  senders = [];
  secAssets = [{ name: 'House' }, { name: 'Shares' }, { name: 'Car' }];

  sessionType;

  guid: any;

  constructor(
    private treatmentService: TreatmentService,
    private merchantService: MerchantService,
    private dialog: MatDialog,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private productService: ProductService,
    private lookupService: LookupService,
    private dentistService: DentistService,
    private invitationService: InvitationService,
    private userDataService: UserDataService,
    private existingPatientService: ExistingPatientService,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit() {
    if (this.context != 'retail') {
      this.router.navigate(['/merchant', { outlets: { page: ['new-invitation'] } }]);
    }

    const pp = {
      isFinance: true,
    };
    this.authenticationService.isPromoterOrAdmin().subscribe((sessionType) => {
      this.sessionType = sessionType;

      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isPromoterOrAdmin = res;

        if (this.isPromoterOrAdmin == true) {
          const payload = {
            fields: 'ID,TradingAs,CalculatedName,Status',
          };
          this.merchantService.getList(payload, this.isPromoterOrAdmin).subscribe((res) => {
            this.merchants = res;
          });
        }
      });
    });
    this.activeRoute.params.subscribe((params) => {
      if (!params['invitationID']) {
        this.router.navigate(['/merchant/']);
      } else {
        this.invitationID = params['invitationID'];

        UtilsClass.startLoadingForce();
        this.invitationService.getProgress(this.invitationID).subscribe((progress) => {
          UtilsClass.stopLoadingForce();

          this.progress(progress);

          const ref = this.dialog.open(BeforeStartingLendingComponent, { width: '550px', panelClass: 'noCard' });
          if (ref.componentInstance.showBeforeStartingInvitation == true) {
            ref.close();
          } else {
            const sub = ref.componentInstance.closeModal.subscribe((data) => {
              ref.close();
            });
          }
          this.lookupService.getLookup('CodeLookup', 'Gender').subscribe((data) => {
            this.genders = data;
          });
          this.lookupService.getLookup('SimpleLookup', 'Salutation').subscribe((data) => {
            this.titles = data;
          });
        });
      }
    });
  }

  getSimpleTreatment() {
    this.treatmentService.getSimpleTreatment(this.invitationID).subscribe((invitationDocument) => {
      this.invitationDocuments = invitationDocument.treatmentDocuments;
    });
  }

  progress(p) {
    let step = 0;
    if (!p) {
      step = 0;
    } else if (p && p.page == 'create') {
      step = p.step;
    } else {
      step = 3;
    }

    switch (step) {
      case 0: {
        this.wizard.goToStep(0);
        break;
      }
      case 1: {
        this.step2();
        break;
      }
      case 2: {
        this.step3();
        break;
      }
      default: {
        this.wizard.goToStep(3);
      }
    }
  }

  changeProductID() {
    this.amountInput = this.amount;
    this.productIDInput = this.productID;
  }

  step1() {
    this.userDataService
      .getUserDataByField(this.invitationID, 'ExistingPatientID', this.sessionType)
      .subscribe((userData) => {
        if (!userData.ExistingPatientID) {
          let payload = {};
          if (this.isPromoterOrAdmin == true) {
            payload = {
              merchant: this.merchantID,
            };
          }

          this.existingPatientService
            .searchByInvitationId(this.invitationID, payload, this.isPromoterOrAdmin)
            .subscribe((result) => {
              if (result.data && result.data != null && result.data.length != 0) {
                const confirm = new ConfirmDialog(
                  'account_circle',
                  '',
                  'We found a match of your patient in our Database , do you want to check it?',
                  'No',
                  'Yes, show me'
                );

                const ref = this.dialog.open(ConfirmDialogComponent, {
                  data: confirm,
                });
                ref.componentInstance.onConfirm.subscribe((confirmation) => {
                  if (confirmation === false) {
                    ref.close();
                    this.step2();
                  } else {
                    ref.close();

                    const patientLookup = this.dialog.open(InvitationLookupComponent, {
                      data: {
                        invitationID: this.invitationID,
                        patients: result.data,
                        hideFilter: true,
                        useStrict: true,
                      },
                      width: '600px',
                    });
                    patientLookup.componentInstance.detailedView.subscribe((res) => {
                      patientLookup.close();
                    });
                    patientLookup.componentInstance.close.subscribe((data) => {
                      patientLookup.close();
                    });

                    patientLookup.componentInstance.patientChat.subscribe((res) => {
                      if (res && res.cardID) {
                        if (res.isPromoterOrAdmin == true) {
                          const ref2 = RootAppComponent.dialog.open(SmsPromoterComponent, {
                            data: {
                              merchantID: res.merchantID,
                              cardID: res.cardID,
                              singleChat: true,
                            },
                            panelClass: 'noCard',
                            width: '500px',
                          });
                          ref2.componentInstance.close.subscribe((data) => {
                            ref2.close();
                          });

                          ref2.componentInstance.getResult.subscribe((data) => {
                            if (data) {
                              const ref3 = RootAppComponent.dialog.open(SmsConversationComponent, {
                                data,
                                panelClass: 'bigger-screen',
                                width: '70%',
                                height: '95vh',
                              });
                              const sub = ref3.componentInstance.close.subscribe((data) => {
                                ref3.close();
                              });
                            }

                            ref2.close();
                          });
                        } else {
                          const ref2 = RootAppComponent.dialog.open(SmsConversationComponent, {
                            data: {
                              merchantID: res.merchantID,
                              cardID: res.cardID,
                              singleChat: true,
                            },
                            panelClass: 'bigger-screen',
                            width: '70%',
                            height: '95vh',
                          });
                          const sub = ref2.componentInstance.close.subscribe((data) => {
                            ref2.close();
                          });
                        }
                      }
                    });
                  }
                });
              } else {
                this.step2();
              }
            });
        } else {
          this.step2();
        }
      });
  }

  step2() {
    const payLoad = [
      'totalTreatmentValue',
      'patientContribution',
      'frequency',
      'duration',
      'productID',
      'productGroupCode',
      'userCanChange',
    ];

    this.lookupService.getLookup('simpleLookup', 'LoanPurpose').subscribe((res1) => {
      this.serviceTypes = res1;
    });

    UtilsClass.startLoadingForce();
    this.userDataService.getUserDataByFields(this.invitationID, payLoad, this.sessionType).subscribe((res) => {
      UtilsClass.stopLoadingForce();

      if (res['userCanChange'] != null) {
        this.consumerCanChange = res['userCanChange'];
      } else {
        this.consumerCanChange = false;
      }
      if (res['totalTreatmentValue']) {
        this.amount = res['totalTreatmentValue'];
        this.amountInput = this.amount;
      } else {
        this.amount = this.defaultAmount;
        this.amountInput = this.amount;
      }

      if (res['productGroupCode']) {
        this.groupCode = res['productGroupCode'];
      }

      if (res['patientContribution']) {
        this.patientContribution = res['patientContribution'];
        this.patientContributionInput = this.patientContribution;
      } else {
        this.patientContribution = 0;
        this.patientContributionInput = this.patientContribution;
      }

      if (res['frequency']) {
        this.frequency = res['frequency'];
        this.frequencyInput = this.frequency;
      } else {
        this.frequency = 4;
        this.frequencyInput = this.frequency;
      }

      if (res['duration']) {
        this.duration = res['duration'];
        this.durationInput = this.duration;
      }

      if (res['productID']) {
        this.productID = res['productID'];
        this.productIDInput = this.productID;
      }

      if (this.productID && this.amount && this.duration && this.consumerCanChange == false) {
        this.isStep2 = true;
      } else if (this.consumerCanChange == true) {
        this.consumerCanChangeEvent();
        this.isStep2 = true;
      }
      this.getSimpleTreatment();

      this.wizard.goToStep(1);
      this.onStepChange();
      this.loadProducts();
    });
  }

  remove(secAsset: any): void {
    const index = this.secAssets.indexOf(secAsset);

    if (index >= 0) {
      this.secAssets.splice(index, 1);
    }
  }

  openInviteViewDetails() {
    UtilsClass.startLoadingForce();
    this.invitationService.getUserData(this.invitationID, 'EPinvitationID', this.sessionType).subscribe((res) => {
      UtilsClass.stopLoadingForce();

      this.router.navigate(['/merchant', { outlets: { page: ['invitation-view', res['EPinvitationID']] } }]);
    });
  }

  consumerCanChangeEvent() {
    UtilsClass.startLoadingForce();
    const _consumerCanChange = {
      attribute: 'userCanChange',
      value: this.consumerCanChange,
    };
    this.userDataService.setUserData(this.invitationID, _consumerCanChange, this.sessionType).subscribe((res) => {
      UtilsClass.stopLoadingForce();
    });
  }

  invitationPage() {
    if (Settings.global['isWakandaIntegrated'] && Settings.global['isWakandaIntegrated'] === true) {
      let invitationCard = Settings.global['wakandaInvitationCardLink'];
      invitationCard = invitationCard.replace('_id_', this.invitationID);
      window.open(invitationCard, '_blank').focus();
    } else {
      this.router.navigate(['/consumer', { outlets: { page: ['invitation-card', this.invitationID] } }]);
    }
  }

  viewMerchant() {
    const ref = RootAppComponent.dialog.open(MerchantViewComponent, {
      data: this.merchantID,
      width: '600px',
    });
    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  validateMobile(v) {
    this.isMobileValid = v;
  }

  validateHomePhone(v) {
    this.isHomePhoneValid = v;
  }

  validateWorkPhone(v) {
    this.isWorkPhoneValid = v;
  }

  validateEmail(v) {
    this.isEmailValid = v;
  }

  onStepChange() {
    const step = this.wizard.currentStepIndex;
    this.invitationService.setProgress(this.invitationID, step).subscribe(() => {});
  }

  openPatientLookUpDialog() {
    const ref = this.dialog.open(InvitationLookupComponent, {
      data: {
        invitationID: this.invitationID,
        merchantID: this.merchantID,
        useStrict: true,
      },
      width: '600px',
    });
    const sub = ref.componentInstance.close.subscribe((data) => {
      ref.close();
    });

    ref.componentInstance.patientChat.subscribe((res) => {
      if (res && res.cardID) {
        if (res.isPromoterOrAdmin == true) {
          const ref2 = RootAppComponent.dialog.open(SmsPromoterComponent, {
            data: {
              merchantID: res.merchantID,
              cardID: res.cardID,
              singleChat: true,
            },
            panelClass: 'noCard',
            width: '500px',
          });
          ref2.componentInstance.close.subscribe((data) => {
            ref2.close();
          });

          ref2.componentInstance.getResult.subscribe((data) => {
            if (data) {
              const ref3 = RootAppComponent.dialog.open(SmsConversationComponent, {
                data,
                panelClass: 'bigger-screen',
                width: '70%',
                height: '95vh',
              });
              const sub = ref3.componentInstance.close.subscribe((data) => {
                ref3.close();
              });
            }

            ref2.close();
          });
        } else {
          const ref2 = RootAppComponent.dialog.open(SmsConversationComponent, {
            data: {
              merchantID: res.merchantID,
              cardID: res.cardID,
              singleChat: true,
            },
            panelClass: 'bigger-screen',
            width: '70%',
            height: '95vh',
          });
          const sub = ref2.componentInstance.close.subscribe((data) => {
            ref2.close();
          });
        }
      }
    });
    ref.componentInstance.detailedView.subscribe((res) => {
      ref.close();
    });
  }

  openSmsPreviewDialog() {
    const ref = this.dialog.open(InvitationViewSmsComponent, {
      data: {
        invitationID: this.invitationID,
      },
      width: '500px',
    });
    const sub = ref.componentInstance.closeModal.subscribe((data) => {
      ref.close();
    });
  }

  openEmailPreviewDialog() {
    const ref = RootAppComponent.dialog.open(TemplateReviewComponent, {
      data: {
        templateTag: 'consumer-invitation',
        isPrint: false,
        isProceed: false,
        isDownload: false,
        proceedLabel: null,
      },
      width: '700px',
    });

    ref.componentInstance.closeModal.subscribe((res) => {
      ref.close();
    });
  }

  finishFunction() {
    this.router.navigate(['/merchant']);
  }

  saveInvitation() {
    let body = {};
    if (this.isPromoterOrAdmin == true) {
      body = {
        merchantID: this.merchantID,
      };
    }

    this.invitationService.createByUserDataID(this.invitationID, body, this.isPromoterOrAdmin).subscribe((res) => {
      this.wizard.goToStep(3);
    });
  }

  defaultMessage() {
    let content;

    for (let i = 0; i < this.dentMsgOptions.length; i++) {
      if (this.messageOption == this.dentMsgOptions[i].key) {
        content = this.dentMsgOptions[i].value;
      }
    }

    if (content) {
      const contentfull = 'Hi ' + this.user.firstname + ', ' + content;

      UtilsClass.savingData();
      const message = {
        attribute: 'message.content',
        value: contentfull,
      };
      this.userDataService.setUserData(this.invitationID, message, this.sessionType).subscribe((res) => {
        this.dentMsg = contentfull;
      });
    }
  }

  moreInfo() {
    NotifyAppComponent.displayToast(
      'fas fa-info',
      'Treatment Instructions',
      'Please make sure you  enter the treatment value.'
    );
  }

  newPatientInv() {
    this.router.navigate(['/merchant', { outlets: { page: ['new-invitation'] } }]);
  }

  cancel() {
    this.router.navigate(['/merchant', { outlets: { page: ['home'] } }]);
  }

  loadProducts() {
    if (this.amount) {
      UtilsClass.startLoadingForce();
      const _amount = {
        attribute: 'totalTreatmentValue',
        value: this.amount,
      };
      this.userDataService.setUserData(this.invitationID, _amount, this.sessionType).subscribe((res) => {
        UtilsClass.stopLoadingForce();

        const payload = {};
        if (this.isPromoterOrAdmin == true) {
          payload['merchantKey'] = this.merchantID;
          payload['amount'] = this.amount;

          payload['groupCode'] = this.groupCode || null;
        } else {
          payload['amount'] = this.amount;
          payload['groupCode'] = this.groupCode || null;
        }

        if (this.amount) {
          const _productID = this.productIDInput;
          let foundMatch = false;
          this.productService.getSuitableProduct(payload).subscribe((res) => {
            this.products = res;
            this.amountInput = this.amount;

            for (var i = 0; i < res.length; i++) {
              if (_productID == res[i]['ID']) {
                this.productID = _productID;
                this.productIDInput = this.productID;
                foundMatch = true;

                break;
              }
            }

            if (foundMatch == true) {
              if (this.frequency == 1) {
                if (
                  this.duration < Number(res[i]['Result.Weeks.Min']) &&
                  this.duration > Number(res[i]['Result.Weeks.Max'])
                ) {
                  this.duration = null;
                  this.durationInput = null;
                }
              } else if (this.frequency == 2) {
                this.duration = null;
                this.durationInput = null;
              } else if (this.frequency == 4) {
                if (
                  this.duration < Number(res[i]['Result.Months.Min']) &&
                  this.duration > Number(res[i]['Result.Months.Max'])
                ) {
                  this.duration = null;
                  this.durationInput = null;
                }
              }
            } else {
              this.productID = null;
              this.productIDInput = null;
              this.duration = null;
              this.durationInput = null;
              this.frequency = null;
              this.frequencyInput = null;
            }
          });
        }
      });
    }
  }

  selectAmount(a) {
    if (a && this.amount != a) {
      this.amount = a;

      this.productID = null;
      this.duration = null;

      this.loadProducts();
    }
  }

  selectDuration(d) {
    if (d) {
      this.duration = d;
    }
  }

  step3() {
    UtilsClass.startLoadingForce();

    let patload;
    if (this.isPromoterOrAdmin == true) {
      patload = {
        fields: 'ID,CalculatedName,ContactForCard_key',
        merchantID: this.merchantID,
      };
    } else {
      patload = {
        fields: 'ID,CalculatedName,ContactForCard_key',
      };
    }

    if (this.merchantID && this.merchantID != 'none') {
      this.dentistService.getFiltersContact(patload, this.isPromoterOrAdmin).subscribe((res) => {
        this.authenticationService.getCurrentUser().subscribe((currentUser) => {
          this.wizard.goToStep(2);
          this.onStepChange();
          this.senders = res;

          for (let i = 0; i < this.senders.length; i++) {
            if (currentUser.data['contactKey'] == this.senders[i]['ID'] && this.selectedSender == null) {
              const s = this.senders[i]['ID'];
              UtilsClass.savingData();
              const sender = {
                attribute: 'message.sender',
                value: s,
              };
              this.userDataService.setUserData(this.invitationID, sender, this.sessionType).subscribe((res) => {
                UtilsClass.stopLoadingForce();
                this.selectedSender = s;
                this.isSelectedSender = true;
              });
            }
          }

          UtilsClass.stopLoadingForce();
        });
      });
    } else {
      this.dentistService.getFiltersContact(patload).subscribe((res) => {
        this.authenticationService.getCurrentUser().subscribe((currentUser) => {
          this.wizard.goToStep(2);
          this.onStepChange();
          this.senders = res;

          for (let i = 0; i < this.senders.length; i++) {
            if (currentUser.data['contactKey'] == this.senders[i]['ID'] && this.selectedSender == null) {
              const s = this.senders[i]['ID'];
              UtilsClass.savingData();
              const sender = {
                attribute: 'message.sender',
                value: s,
              };
              this.userDataService.setUserData(this.invitationID, sender, this.sessionType).subscribe((res) => {
                UtilsClass.stopLoadingForce();
                this.selectedSender = s;
                this.isSelectedSender = true;
              });
            }
          }

          UtilsClass.stopLoadingForce();
        });
      });
    }
  }

  senderChanged() {
    if (this.selectedSender != null) {
      this.isSelectedSender = true;
    }
  }

  calculator(res) {
    if (res) {
      const payload = {};

      if (res['duration']) {
        this.duration = res['duration'];
        payload['duration'] = Number(this.duration);
      } else if (this.duration) {
        payload['duration'] = Number(this.duration);
      }

      if (res['amount']) {
        this.amount = res['amount'];
        payload['totalTreatmentValue'] = Number(this.amount);
      } else if (this.amount) {
        payload['totalTreatmentValue'] = Number(this.amount);
      }

      if (res['ProductID']) {
        this.productID = res['ProductID'];

        payload['productID'] = this.productID;
      } else if (this.productID) {
        payload['productID'] = this.productID;
      }

      if (res['patientContribution']) {
        this.patientContribution = res['patientContribution'];

        payload['patientContribution'] = Number(this.patientContribution);
      } else if (this.patientContribution) {
        payload['patientContribution'] = Number(this.patientContribution);
      }

      if (res['frequency']) {
        this.frequency = res['frequency'];
        payload['frequency'] = Number(this.frequency);
      } else if (this.frequency) {
        payload['frequency'] = Number(this.frequency);
      }

      payload['userCanChange'] = this.consumerCanChange;

      if (payload) {
        UtilsClass.startLoadingForce();
        this.isStep2 = false;

        this.userDataService.setUserDataArrayForce(this.invitationID, payload, this.sessionType).subscribe((res) => {
          UtilsClass.stopLoadingForce();
          if (res && res.data && res.data.content) {
            this.duration = res.data.content['duration'];
            this.amount = res.data.content['totalTreatmentValue'];
            this.frequency = res['frequency'];
          }
          if (this.productID && this.amount && this.duration) {
            this.isStep2 = true;
          }
        });
      } else {
        this.duration = res['duration'];
        this.amount = res['amount'];
        this.frequency = res['frequency'];

        if (this.productID && this.amount && this.duration) {
          this.isStep2 = true;
        }
      }
    } else if (this.productID && this.amount && this.duration) {
      this.isStep2 = true;
    }
  }

  removeInvitationDocument(id) {
    const data = {
      invitationID: this.invitationID,
      type: 'treatmentSimple',
      option: null,
    };

    this.treatmentService.removeTreatmentDocument(id, data).subscribe((result) => {
      this.getSimpleTreatment();
    });
  }

  onCompleteAllInvitationDocument(data) {
    this.createInvitationDocument(data);
  }

  createInvitationDocument(documents = null) {
    let payload = {};

    let treatmentDocuments = null;
    if (documents) {
      treatmentDocuments = documents.join(',');
    }

    payload = {
      id: this.invitationID,
      treatmentDocuments,
    };

    this.treatmentService.createSimpleTreatment(payload).subscribe((res1) => {
      this.invitationDocuments = [];
      this.invitationDocuments = res1.treatmentDocuments;
    });
  }
}
