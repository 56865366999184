import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';

@Component({
  selector: 'app-marketing-settings',
  templateUrl: './marketing-settings.component.html',
  styleUrls: ['./marketing-settings.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.tab-content-inner', style({ opacity: 0, height: 0 }), { optional: true }),
        query(
          '.tab-content-inner',
          stagger('250ms', [
            animate(
              '0.2s ease-in',
              keyframes([
                style({ opacity: 0, height: 0, offset: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, height: '*', offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.tab-content-inner', style({ opacity: 1, height: '*' }), { optional: true }),
        query(
          '.tab-content-inner',
          stagger('250ms', [
            animate(
              '0.2s ease-in',
              keyframes([
                style({ opacity: 1, height: '*', offset: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, height: 0, offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class MarketingSettingsComponent implements OnInit {
  @Input()
  merchantID;

  @Input()
  hideFilter = false;

  isAdminOrPromoter = false;

  msgList = [];

  settings = 'filter';

  constructor(private authenticationService: AuthenticationService, private activeRoute: ActivatedRoute) {}

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isAdminOrPromoter = res;
      });
    });
  }
}
