import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { Settings } from '../../../shared/types/settings';
import { MerchantViewComponent } from '../../merchant/merchant-view/merchant-view.component';

import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { HeaderService } from '../../../shared/services/header.service';
import { InvTreatBeforeStartingComponent } from '../before-starting/before-starting.component';

@Component({
  selector: 'app-invitation-template-list-overview',
  templateUrl: './invitation-template-list-overview.component.html',
  styleUrls: ['./invitation-template-list-overview.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('*', style({ opacity: 0 }), { optional: true }),
        query(
          '*',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('*', style({ opacity: 1 }), { optional: true }),
        query(
          '*',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class InvitationTemplateListOverviewComponent implements OnInit {
  @Input()
  inputMerchantID;

  @Input()
  hideFilter = false;

  @Input()
  pageSize = Settings.global['listPageSize'] || 10;

  isAdminOrPromoter = false;

  merchants = [];

  merchantID = 'none';

  isDisplaySlider = false;

  hideMerchantSelect = false;

  constructor(private authenticationService: AuthenticationService, private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit() {
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isAdminOrPromoter = res;

      if (this.isAdminOrPromoter == true) {
        if (this.inputMerchantID) {
          this.merchantID = this.inputMerchantID;
        } else {
          const _mID = JSON.parse(localStorage.getItem('selectedMerchant'));

          if (_mID) {
            // this.hideFilter = true;
            this.merchantID = _mID;
          } else {
            this.authenticationService.getCurrentPractice().subscribe((res) => {
              if (res && res['ID']) {
                this.merchantID = res['ID'];
              }
            });
          }

          HeaderService.getPromoterViewAsObject().subscribe((res) => {
            if (res && res['promoterViewAs'] && res['promoterViewAs'] == 'promoter') {
              this.merchantID = 'none';
              this.hideFilter = false;
            } else if (res && res['promoterViewAs'] && res['promoterViewAs'] == 'merchant') {
              this.merchantID = res['merchantID'];
              this.hideFilter = true;
            }
          });

          const ref = RootAppComponent.dialog.open(InvTreatBeforeStartingComponent, { width: '650px' });
          if (ref && ref.componentInstance) {
            if (ref.componentInstance.showBeforeStartingInvitationTemplate == true) {
              ref.close();
            } else {
              if (
                ref.componentInstance &&
                ref.componentInstance.closeModal &&
                ref.componentInstance.closeModal.subscribe
              ) {
                const sub = ref.componentInstance.closeModal.subscribe((data) => {
                  ref.close();
                });
              }
            }
          }
        }
      }

      // if (this.isAdminOrPromoter == true && this.inputMerchantID) {
      //   this.merchantID = this.inputMerchantID;
      // } else {
      //   this.authenticationService.getCurrentPractice().subscribe(res => {
      //     if (res && res['ID']) {
      //       this.merchantID = res['ID']
      //     }
      //   })
      // }
    });
  }

  viewMerchant() {
    const ref = RootAppComponent.dialog.open(MerchantViewComponent, {
      data: this.merchantID,
      width: '600px',
    });
    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  displaySlideToggle(e) {
    this.isDisplaySlider = e;
  }

  applySearch(result) {
    this.merchantID = result.merchantID;
    this.changeDetectorRef.detectChanges();
  }

  getMerchant(d) {
    this.merchantID = d;
    this.changeDetectorRef.detectChanges();
  }
}
