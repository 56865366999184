import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ConfirmDialogMessageComponent } from '../../../shared/components/confirm-dialog-message/confirm-dialog-message.component';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { EpDocumentViewComponent } from '../../../shared/components/ep-document-view/ep-document-view.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { EventLogListComponent } from '../../action-log/event-log-list/event-log-list.component';
import { BankAccountViewComponent } from '../../bank-account/bank-account-view/bank-account-view.component';
import { BeneficiaryViewComponent } from '../../beneficiary/beneficiary-view/beneficiary-view.component';
import { SettlementRequestComponent } from '../settlement-request/settlement-request.component';
import { SettlementViewComponent } from '../settlement-view/settlement-view.component';
import { SettlementService } from '../shared/settlement.service';
import { SettlementUtilClass } from '../shared/settlementUtils';

@Component({
  selector: 'settlement-view-group',
  templateUrl: './settlement-view-group.component.html',
  styleUrls: ['./settlement-view-group.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.actionMenuToggle', style({ opacity: 0 }), { optional: true }),
        query(
          '.actionMenuToggle',
          stagger('100ms', [
            animate(
              '0.3s ease-out',
              keyframes([
                style({ opacity: 0, offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.actionMenuToggle', style({ opacity: 1 }), { optional: true }),
        query(
          '.actionMenuToggle',
          stagger('100ms', [
            animate(
              '0.3s ease-in',
              keyframes([
                style({ opacity: 1, offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class SettlementViewGroupComponent implements OnInit {
  @Input()
  settlementGroupID = null;

  @Output() changedSettlements = new EventEmitter();

  @Input()
  title = 'Settlement group';

  @Input()
  description = '';

  isExpanded = false;

  @Output() close = new EventEmitter();

  settlements: any = [];
  settlement: any = {};

  settleUtils = new SettlementUtilClass();

  isModal = false;

  isPromoterOrAdmin = false;

  constructor(
    private SettlementService: SettlementService,
    private authenticationService: AuthenticationService,
    private activeRoute: ActivatedRoute,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.settlementGroupID) {
        this.settlementGroupID = data.settlementGroupID;
        this.isModal = true;
      }

      if (data.title) {
        this.title = data.title;
        this.isModal = true;
      }

      if (data.description) {
        this.description = data.description;
        this.isModal = true;
      }
    }
  }

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      if (params['settlementGroupID']) {
        this.settlementGroupID = params['settlementGroupID'];
      }

      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isPromoterOrAdmin = res;

        this.setup();
      });
    });
  }

  changedSettlementsEvent(e) {
    if (e && e.length > 0) {
      this.settlements = e;

      this.settlement = this.settlements[0];
    }
  }

  setup() {
    if (this.settlementGroupID) {
      const payload = {
        groupID: this.settlementGroupID,
      };
      this.SettlementService.getSettlementList(payload, this.isPromoterOrAdmin).subscribe((res) => {
        if (res) {
          this.settlements = res;

          this.settlement = this.settlements[0];
        }
      });
    }
  }

  closeModalEvent() {
    this.close.emit(true);
  }

  withdrawSettlementGroup() {
    if (this.settlement['ID']) {
      const confirm = new ConfirmDialog(
        'block',
        '',
        'Are you sure you want to withdraw this settlement group?',
        'No',
        'Yes'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation == true) {
          this.SettlementService.cancel(this.settlement['ID'], this.isPromoterOrAdmin, true).subscribe((res) => {
            if (res) {
              this.settlement = res;

              if (this.settlement && this.settlement.groupArray && this.settlement.groupArray.length > 0) {
                this.changedSettlements.emit(this.settlement.groupArray);
                this.settlements = this.settlement.groupArray;

                this.settlement = this.settlements[0];
              }

              NotifyAppComponent.displayToast('success', 'Success!', 'Your Settlement has been withdrawn');

              ref.close();
            }
          });
        } else {
          ref.close();
        }
      });
    }
  }

  approveSettlementGroup() {
    if (this.settlement['ID']) {
      const confirm = new ConfirmDialog(
        'thumb_up_alt',
        '',
        'Are you sure you want to approve this settlement group?',
        'No',
        'Yes'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation == true) {
          this.SettlementService.approve(this.settlement['ID'], true).subscribe((res) => {
            if (res) {
              this.settlement = res;

              if (this.settlement && this.settlement.groupArray && this.settlement.groupArray.length > 0) {
                this.changedSettlements.emit(this.settlement.groupArray);
                this.settlements = this.settlement.groupArray;

                this.settlement = this.settlements[0];
              }

              NotifyAppComponent.displayToast('success', 'Success!', 'Your Settlement has been approved');

              ref.close();
            }
          });
        } else {
          ref.close();
        }
      });
    }
  }

  declineSettlementGroup() {
    if (this.settlement['ID']) {
      const ref = RootAppComponent.dialog.open(ConfirmDialogMessageComponent, {
        data: {
          icon: 'thumb_down_alt',
          title: 'Are you sure you want to decline this settlement group?',
          description: '',
          yesLabel: 'Yes,Proceed',
          noLabel: 'No',
          messageLabel: 'Please enter the reason',
        },
      });
      ref.componentInstance.closeModal.subscribe((res) => {
        ref.close();
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation && confirmation.confirm == true) {
          this.SettlementService.decline(this.settlement['ID'], confirmation.message, true).subscribe((res) => {
            if (res) {
              this.settlement = res;

              if (this.settlement && this.settlement.groupArray && this.settlement.groupArray.length > 0) {
                this.changedSettlements.emit(this.settlement.groupArray);
                this.settlements = this.settlement.groupArray;

                this.settlement = this.settlements[0];
              }

              NotifyAppComponent.displayToast('success', 'Success!', 'Your Settlement has been declined');

              ref.close();
            }
          });
        } else {
          ref.close();
        }
      });
    }
  }

  requestSettlementGroup() {
    if (this.settlement['ID']) {
      const ref = RootAppComponent.dialog.open(SettlementRequestComponent, {
        data: this.settlement['ID'],
      });

      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });

      ref.componentInstance.result.subscribe((res) => {
        if (res) {
          this.settlement = res;

          if (this.settlement && this.settlement.groupArray && this.settlement.groupArray.length > 0) {
            this.changedSettlements.emit(this.settlement.groupArray);
            this.settlements = this.settlement.groupArray;

            this.settlement = this.settlements[0];
          }

          ref.close();
        }
      });
    }
  }

  viewSettlementLogs(s) {
    const ref = RootAppComponent.dialog.open(EventLogListComponent, {
      data: {
        itemID: s['ID'],
      },
      width: '900px',
    });

    ref.componentInstance.closeModal.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  viewSettlement(s) {
    const ref = RootAppComponent.dialog.open(SettlementViewComponent, {
      data: s['ID'],
      width: '850px',
      panelClass: 'noCard',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  viewDocument(s) {
    this.SettlementService.getDocuments(
      s['ID'],
      ['ID', 'Label', 'Description', 'Extension', 'DateAdded'],
      this.isPromoterOrAdmin
    ).subscribe((res) => {
      if (res && res.length > 0) {
        const ref = RootAppComponent.dialog.open(EpDocumentViewComponent, {
          data: {
            document: res,
          },
          width: '800px',
          panelClass: 'noCard',
        });

        ref.componentInstance.closeModal.subscribe((res) => {
          ref.close();
        });

        ref.componentInstance.noFileFound.subscribe((res) => {
          NotifyAppComponent.displayToast('warning', 'Sorry!', 'There is no document attached to this settlement');
        });
      } else {
        NotifyAppComponent.displayToast('warning', 'Sorry!', 'There is no document attached to this settlement');
      }
    });
  }

  voidSettlement(s) {
    if (s['ID']) {
      const confirm = new ConfirmDialog('delete', '', 'Are you sure you want to remove this settlement?', 'No', 'Yes');

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation == true) {
          this.SettlementService.void(s['ID'], this.isPromoterOrAdmin).subscribe((res) => {
            if (res) {
              this.settlement = res;

              if (this.settlement && this.settlement.groupArray && this.settlement.groupArray.length > 0) {
                this.changedSettlements.emit(this.settlement.groupArray);

                this.settlements = this.settlement.groupArray;
              }

              NotifyAppComponent.displayToast('success', 'Success!', 'Your Settlement has been removed');

              ref.close();
            }
          });
        } else {
          ref.close();
        }
      });
    }
  }

  requestSettlement(s) {
    if (s['ID']) {
      const ref = RootAppComponent.dialog.open(SettlementRequestComponent, {
        data: s['ID'],
      });

      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });

      ref.componentInstance.result.subscribe((res) => {
        if (res) {
          this.settlement = res;

          if (this.settlement && this.settlement.groupArray && this.settlement.groupArray.length > 0) {
            this.changedSettlements.emit(this.settlement.groupArray);
            this.settlements = this.settlement.groupArray;
          }

          ref.close();
        }
      });
    }
  }

  splitStatus(s) {
    try {
      const result = s.split('/');

      return result;
    } catch (e) {
      return [];
    }
  }

  viewBeneficiary(s) {
    if (s['SettleTo.Card_key']) {
      const ref = RootAppComponent.dialog.open(BeneficiaryViewComponent, {
        data: s['SettleTo.Card_key'],
        width: '850px',
        panelClass: 'noCard',
      });

      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    }
  }

  viewBankAccount(s) {
    if (s['SettleTo.Account_key']) {
      const ref = RootAppComponent.dialog.open(BankAccountViewComponent, {
        data: s['SettleTo.Account_key'],
        width: '850px',
      });

      ref.componentInstance.closeModal.subscribe((res) => {
        ref.close();
      });
    }
  }
}
