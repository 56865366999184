import { animate, keyframes, query, stagger, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { OperatorService } from '../shared/operator.service';

@Component({
  selector: 'app-operator-change-password',
  templateUrl: './operator-change-password.component.html',
  styleUrls: ['./operator-change-password.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.stacked-card-view', style({ opacity: 0 }), { optional: true }),
        query(
          '.stacked-card-view',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({ opacity: 0, offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.stacked-card-view', style({ opacity: 1 }), { optional: true }),
        query(
          '.stacked-card-view',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({ opacity: 1, offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
    trigger('simpleFadeAnimation', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [style({ opacity: 0, height: 0 }), animate(300)]),
      transition(':leave', animate(300, style({ opacity: 0, height: '*' }))),
    ]),
  ],
})
export class OperatorChangePasswordComponent implements OnInit {
  @Input() operatorID;
  @Output() close = new EventEmitter();
  operator;
  isPromoterOrAdmin;
  newPassword1;
  newPassword2;

  constructor(
    private operatorService: OperatorService,
    private AuthenticationService: AuthenticationService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      this.operatorID = data;
    }

    this.AuthenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;
    });
  }

  ngOnInit() {
    this.operatorService.getfullOperatorDetails(this.operatorID).subscribe((res) => {
      this.operator = res;
    });
  }

  closeEvent() {
    this.close.emit(true);
  }

  updatePassword() {
    if (this.isPromoterOrAdmin) {
      const confirm = new ConfirmDialog(
        'fas fa-info',
        'Do You want to proceed ?',
        'The user will get disconnected after changing password',
        'No',
        'Yes'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation === false) {
          ref.close();
        } else {
          const payLoad = {
            password: this.newPassword1,
          };

          this.operatorService.changePassword(this.operatorID, payLoad).subscribe(
            (res) => {
              if (res) {
                NotifyAppComponent.displayToast(
                  'success',
                  'Successful operation',
                  'The password has been successfully updated'
                );
                this.closeEvent();
              }
              ref.close();
            },
            () => {
              ref.close();
            }
          );
        }
      });
    }
  }
}
