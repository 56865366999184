import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';

import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-security-code-info',
  templateUrl: './security-code-info.component.html',
  styleUrls: ['./security-code-info.component.css'],
})
export class SecurityCodeInfoComponent implements OnInit {
  @Output()
  onConfirm = new EventEmitter();

  @Input()
  mobileNumber;

  @Input()
  name;

  @Input()
  description;

  canClose = true;

  @Output()
  closeModal = new EventEmitter();

  @Input()
  isModal = false;

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.mobile) {
        this.mobileNumber = data.mobile;

        this.isModal = true;
      }

      if (data.mobileNumber) {
        this.mobileNumber = data.mobileNumber;

        this.isModal = true;
      }
      if (data.description) {
        this.description = data.description;

        this.isModal = true;
      }

      if (data.canClose != null) {
        this.canClose = data.canClose;

        this.isModal = true;
      }

      if (data.name) {
        this.name = data.name;

        this.isModal = true;
      }
    }
  }

  ngOnInit() {}

  onYes() {
    this.onConfirm.emit(true);
  }

  cutIt() {
    if (this.mobileNumber) {
      let result = '';

      for (let i = 0; i < this.mobileNumber.length; i++) {
        if (i < this.mobileNumber.length - 3) {
          result = result + '*';
        } else {
          result = result + this.mobileNumber[i];
        }
      }
      return result;
    }
  }

  closeEvent() {
    this.closeModal.emit(true);
  }
}
