<div class="rel text-center">
  <mat-icon class="xlarge-modal-icon primary-color">help</mat-icon>
  <h2 class="sr-title sm-h2 rm-mb mt" mat-dialog-title>Advanced {{ "KEYWORD.treatment" | translate | titlecase }}
    Invitation</h2>
  <mat-dialog-content>
    <div class="row clearfix">
      <p>You're about to change to an Advanced {{ "KEYWORD.treatment" | translate | titlecase }} Invitation</p>
      <p>
        Advanced {{ "KEYWORD.treatment" | translate | titlecase }} Invitations allow you to select your individual
        {{ "KEYWORD.treatment" | translate }} items and build a detailed {{ 'PHRASE.treatment plan' | translate }} to
        send to your {{ "KEYWORD.patient" | translate }}.
      </p>
      <p>
        By doing this, your changes in your Simple {{ "KEYWORD.treatment" | translate | titlecase }} Invitation will be
        lost.
        <strong>Do you wish to continue?</strong>
      </p>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="button-row text-center add-mt">
    <div class="row clearfix text-center">
      <mat-checkbox [(ngModel)]="dontShowAgainType">Dont Show Again</mat-checkbox>
    </div>
    <hr class="mb"/>
    <button (click)="backToSimple(); doNotShow()" mat-raised-button color="accent" matDialogClose>
      No, Stay on Simple
    </button>
    <button (click)="doNotShow()" mat-raised-button color="primary" matDialogClose>Yes</button>
  </mat-dialog-actions>
</div>
