<mat-card class="mat-elevation-z3 mb" [ngClass]="{ 'standard-size': !hasFullWidth }">
  <div style="padding: 10px">
    <div style="display: flex; justify-content: space-between">
      <h3 class="sr-title rm-m" style="line-height: 35px">Google Reviews</h3>

      <button [matMenuTriggerFor]="googleReviewMenu" mat-icon-button>
        <mat-icon>more_vert</mat-icon>
      </button>
    </div>

    <mat-menu #googleReviewMenu="matMenu">
      <button (click)="disconnectAccount()" mat-menu-item color="primary">Disconnect</button>
    </mat-menu>

    <hr/>
    <img src="assets/images/google_my_business.jpeg" style="display: block; margin: 5px auto; width: 100%"/>

    <p style="font-size: small">
      With a Google My Business Account, you get more than a business listing. Your free Business Profile lets you
      easily connect with customers across Google Search and Maps. Start promoting
      your {{"KEYWORD.practice" | translate}} now by clicking the
      Manage button below.
    </p>

    <div style="display: flex; width: 100%; justify-content: flex-end; margin-bottom: 10px">
      <button (click)="openManageGoogleReviewsDialog()" mat-raised-button color="primary">Manage</button>
    </div>

    <hr />

    <ng-container *ngIf="isServiceReady; else preparingServiceLoader">
      <div class="business-info" *ngIf="Google_Reviews_Account_ID">
        <ng-container *ngIf="businessInfo$ | async as businessInfo">
          <ng-container *ngIf="businessInfo !== null">
            <p style="font-size: large">
              <b> {{ businessInfo.Google_Reviews_Business_Name }} </b>
            </p>
            <p style="font-size: small">{{ businessInfo.Google_Reviews_Business_Address }}</p>

            <div style="display: flex; flex-direction: row">
              <div style="font-size: small; padding-top: 15px">{{ businessInfo.Google_Reviews_Business_Rating }}</div>
              <app-rating
                [rating]="businessInfo.Google_Reviews_Business_Rating"
                style="width: 150px; padding-left: 10px"
              ></app-rating>
              <div style="font-size: small; padding-top: 15px; padding-left: 30px">
                {{ businessInfo.Google_Reviews_Business_Review_Count }}
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>

      <p *ngIf="!Google_Reviews_Account_ID" style="text-align: center; color: red; margin-top: 30px">NOT CONNECTED</p>
    </ng-container>

    <p *ngIf="isCheckingAccountStatus && isServiceReady" style="text-align: center; margin-top: 30px">
      Checking Google Review Account Status...
    </p>

    <ng-template #preparingServiceLoader>
      <p style="text-align: center; margin-top: 30px">Checking Google Review Account Status...</p>
    </ng-template>
  </div>
</mat-card>
