import { animate, animateChild, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'app-referral-create-select-merchant',
  templateUrl: './referral-create-select-merchant.component.html',
  styleUrls: ['./referral-create-select-merchant.component.css'],
  animations: [
    trigger('fade', [transition(':enter', [style({ opacity: 0 }), animate('.6s ease')])]),
    trigger('stagger', [transition(':enter', [query(':enter', stagger('.3s', [animateChild()]), { optional: true })])]),
  ],
})
export class ReferralCreateSelectMerchantComponent implements OnInit {
  chooseSelMerchant = new EventEmitter();

  chooseNewMerchant = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  selectMerch() {
    this.chooseSelMerchant.emit(this.chooseSelMerchant);
  }

  newMerch() {
    this.chooseNewMerchant.emit(this.chooseNewMerchant);
  }
}
