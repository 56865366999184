import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit } from '@angular/core';
import { ClipboardService } from 'ngx-clipboard';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { HtmlSimpleLinkInputComponent } from '../../../shared/components/html-simple-link-input/html-simple-link-input.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { EditorComponentDocumentViewComponent } from '../editor-component-document-view/editor-component-document-view.component';
import { EditorDocumentCreateComponent } from '../editor-document-create/editor-document-create.component';
import { EditorComponentService } from '../shared/editor-component.service';

@Component({
  selector: 'app-editor-document-gallery',
  templateUrl: './editor-document-gallery.component.html',
  styleUrls: ['./editor-document-gallery.component.css'],
})
export class EditorDocumentGalleryComponent implements OnInit {
  close = new EventEmitter();

  getResult = new EventEmitter();

  isPromoterOrAdmin = false;

  sessionType;

  searchApplied = false;

  limit = 32;
  types = [
    {
      icon: 'fas fa-image',
      code: 'EDTRBKG',
      label: 'Background',
    },
    {
      icon: 'fas fa-portrait',
      code: 'EDTRCHR',
      label: 'Character',
    },
    {
      icon: 'fas fa-file-alt',
      code: 'EDTRDOC',
      label: 'Document',
    },

    {
      icon: 'fas fa-file-image',
      code: 'EDTRIMG',
      label: 'Image',
    },
    // {
    //   code: 'EDTRJVS',
    //   label: 'Javascript'
    // },
    {
      icon: 'fas fa-file-code',
      code: 'EDTRSTL',
      label: 'Style',
    },
  ];
  galleryType = 'default';

  orderBy = '-DateTimeCreated';
  orderBySearch = '-DateTimeCreated';

  type = 'EDTRIMG';
  typeSearch = 'EDTRIMG';

  partLabel;
  partLabelSearch;

  utils = new UtilsClass();

  isDefaultLoaded = false;
  isPracticeLoaded = false;

  defaultSection = 1;
  practiceSection = 1;

  defaultDocuments = [];
  practiceDocuments = [];

  displayMoreDefault = false;
  displayMorePractice = false;

  constructor(
    private http: HttpClient,
    private _clipboardService: ClipboardService,
    private editorComponentService: EditorComponentService,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((r) => {
      this.sessionType = r;

      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isPromoterOrAdmin = res;

        this.setup();
      });
    });
  }

  setup(loadMore = false) {
    if (this.searchApplied == true) {
      if (this.galleryType === 'default') {
        const payload = {
          fields:
            'ID,DateTimeCreated,Label,IsGallery,AWS_Bucket_Name,AWS_Folder_Name,ExtensionOrMimeType,AWS_URL,DocumentType_Code',
          partLabel: this.partLabel,
          type: this.type,
          isGallery: false,
          orderBy: this.orderBy,
          limit: this.limit,
          section: this.defaultSection,
        };

        this.editorComponentService.getEditorComponentDocumentList(payload, 'admin').subscribe((res) => {
          if (res) {
            if (loadMore === false) {
              this.defaultDocuments = res;
            } else {
              this.defaultDocuments = this.defaultDocuments.concat(res);
            }
          }

          if (res && res.length >= this.limit) {
            this.displayMoreDefault = true;
          } else {
            this.displayMoreDefault = false;
          }
          this.isDefaultLoaded = true;
        });
      } else if (this.galleryType === 'practice') {
        const payload = {
          fields:
            'ID,DateTimeCreated,Label,IsGallery,AWS_Bucket_Name,AWS_Folder_Name,ExtensionOrMimeType,AWS_URL,DocumentType_Code',
          partLabel: this.partLabel,
          type: this.type,
          isGallery: true,
          orderBy: this.orderBy,
          limit: this.limit,
          section: this.practiceSection,
        };

        this.editorComponentService.getEditorComponentDocumentList(payload, 'merchant').subscribe((res) => {
          if (res) {
            if (loadMore === false) {
              this.practiceDocuments = res;
            } else {
              this.practiceDocuments = this.practiceDocuments.concat(res);
            }
          }
          if (res && res.length >= this.limit) {
            this.displayMorePractice = true;
          } else {
            this.displayMorePractice = false;
          }
          this.isPracticeLoaded = true;
        });
      }
    }
  }

  search() {
    this.type = this.typeSearch;
    this.orderBy = this.orderBySearch;
    this.partLabel = this.partLabelSearch;
    this.practiceSection = 1;
    this.defaultSection = 1;

    this.isDefaultLoaded = false;
    this.isPracticeLoaded = false;
    this.displayMorePractice = false;
    this.displayMoreDefault = false;
    this.defaultDocuments = [];
    this.practiceDocuments = [];
    this.searchApplied = true;
    this.setup();
  }

  loadMore() {
    if (this.galleryType === 'default') {
      this.defaultSection = this.defaultSection + 1;

      this.setup(true);
    } else if (this.galleryType === 'practice') {
      this.practiceSection = this.practiceSection + 1;

      this.setup(true);
    }
  }

  closeEvent() {
    this.close.emit(true);
  }

  changeGalleryChange() {
    if (this.galleryType === 'default' && this.isDefaultLoaded === false) {
      this.setup(false);
    } else if (this.galleryType === 'practice' && this.isPracticeLoaded === false) {
      this.setup(false);
    }
  }

  view(d) {
    if (d && d.ID) {
      const ref = RootAppComponent.dialog.open(EditorComponentDocumentViewComponent, {
        data: {
          documentID: d.ID,
          document: d,
          extension: d.ExtensionOrMimeType,
          title: d.Label,
          description: this.getTypeLabel(d.DocumentType_Code),
        },
        width: '1000px',
      });
      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });

      ref.componentInstance.getResult.subscribe((res) => {
        ref.close();
        ref.afterClosed().subscribe((c) => {
          this.getResult.emit(res);
        });
      });
    }
  }

  getTypeLabel(d) {
    if (d) {
      if (d === 'EDTRBKG') {
        return 'Background';
      } else if (d === 'EDTRCHR') {
        return 'Character';
      } else if (d === 'EDTRDOC') {
        return 'Document';
      } else if (d === 'EDTRIMG') {
        return 'Image';
      } else if (d === 'EDTRJVS') {
        return 'Javascript';
      } else if (d === 'EDTRSTL') {
        return 'Style';
      }
    }

    return null;
  }

  create() {
    const ref = RootAppComponent.dialog.open(EditorDocumentCreateComponent, {
      data: {},
      width: '800px',
    });
    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });

    ref.componentInstance.getResult.subscribe((res) => {
      if (res && res.ID) {
        if (res.isPromoter === true) {
          if (this.defaultDocuments && this.defaultDocuments.length > 0) {
            this.defaultDocuments.unshift(res);
          } else {
            this.defaultDocuments = [res];
          }
        } else {
          if (this.practiceDocuments && this.practiceDocuments.length > 0) {
            this.practiceDocuments.unshift(res);
          } else {
            this.practiceDocuments = [res];
          }
        }
      }
      ref.close();

      ref.afterClosed().subscribe((c) => {
        if (res && res.ID) {
          const ref2 = RootAppComponent.dialog.open(EditorComponentDocumentViewComponent, {
            data: {
              documentID: res.ID,
              document: res,
              extension: res.ExtensionOrMimeType,
              title: res.Label,
              description: this.getTypeLabel(res.DocumentType_Code),
            },
            width: '900px',
          });
          ref2.componentInstance.close.subscribe((a) => {
            ref2.close();
          });

          ref2.componentInstance.getResult.subscribe((res) => {
            ref2.close();
            ref2.afterClosed().subscribe((c) => {
              this.getResult.emit(res);
            });
          });
        }
      });
    });
  }

  preview(d) {
    const link = this.getDocumentLink(d);
    if (link) {
      window.open(link, '_blank');
    }
  }

  copyLink(d) {
    const link = this.getDocumentLink(d);
    if (link) {
      this._clipboardService.copy(link);

      NotifyAppComponent.displayToast('success', 'success', 'Copied');
    }
  }

  getDocumentLink(d) {
    if (d) {
      return `${Settings.global['AWSS3Prefix']}/${d.AWS_Bucket_Name}${d.AWS_URL}`;
    }

    return null;
  }

  delete(id) {
    if (id) {
      const confirm = new ConfirmDialog('delete', '', 'Are you sure you want to delete this document ?', 'No', 'Yes');
      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation === false) {
          ref.close();
        } else {
          this.editorComponentService.deleteEditorComponentDocument(id, this.sessionType).subscribe((res) => {
            if (res) {
              if (this.galleryType == 'default') {
                const index = this.defaultDocuments.findIndex((item) => {
                  if (item && item.ID && id && item.ID == id) {
                    return true;
                  }
                  return false;
                });
                if (index != -1) {
                  this.defaultDocuments.splice(index, 1);
                }
              } else {
                const index = this.practiceDocuments.findIndex((item) => {
                  if (item && item.ID && id && item.ID == id) {
                    return true;
                  }
                  return false;
                });
                if (index != -1) {
                  this.practiceDocuments.splice(index, 1);
                }
              }

              NotifyAppComponent.displayToast('success', 'Success!', 'Document is removed ');
              ref.close();
            }
          });
        }
      });
    }
  }

  build(document, isEmbeded = false, isCopy = false) {
    let content = null;
    let style = null;
    let headCSS = null;
    const headJS = null;
    let _isCopy = false;
    let script = null;

    if (document && document.ExtensionOrMimeType) {
      const link = this.getDocumentLink(document);
      const title = `${document.Label} ${this.getTypeLabel(document.DocumentType_Code)}`;

      if (link) {
        const nature = this.utils.getDocumentTypeFromExtension(document.ExtensionOrMimeType);

        if (nature === 'image') {
          const ref2 = RootAppComponent.dialog.open(HtmlSimpleLinkInputComponent, {
            data: {
              title,
              isMerchant: false,
              link,
              linkTitle: title,
              type: 'image',
            },
            width: '600px',
          });
          ref2.componentInstance.getResult.subscribe((c) => {
            if (c) {
              content = c.content || null;
              style = c.style || null;
              _isCopy = c.isCopy || false;

              ref2.close();

              ref2.afterClosed().subscribe((d) => {
                const result = {
                  content,
                  style,
                  headCSS,
                  headJS,
                  isCopy: _isCopy,
                  isReplace: false,
                };

                this.sendResultEvent(result);
              });
            } else {
              ref2.close();
            }
          });

          ref2.componentInstance.close.subscribe((s) => {
            ref2.close();
          });
          ref2.backdropClick().subscribe((s) => {
            ref2.close();
          });
        } else if (String(document.ExtensionOrMimeType).toLocaleLowerCase() === 'css' && isEmbeded != true) {
          headCSS = `<!--START: ${title}-->\n  <link rel="stylesheet" href="${link}">\n<!--END: ${title}-->`;

          const result = {
            content,
            style,
            headCSS,
            headJS,
            isCopy,
            isReplace: false,
          };

          this.sendResultEvent(result);
        } else if (String(document.ExtensionOrMimeType).toLocaleLowerCase() === 'css' && isEmbeded == true) {
          this.http.get(link, { responseType: 'text' as 'json' }).subscribe((res) => {
            style = `/* START: ${title} */\n ${res} \n/* END: ${title} */`;
            const result = {
              content,
              style,
              headCSS,
              headJS,
              isCopy,
              isReplace: false,
            };
            this.sendResultEvent(result);
          });
        } else if (String(document.ExtensionOrMimeType).toLocaleLowerCase() === 'js') {
          this.http.get(link, { responseType: 'text' as 'json' }).subscribe((res) => {
            script = `<!--START: ${title}-->\n<script>\n${res}\n</script>\n<!--END: ${title}-->`;
            const result = {
              content,
              style,
              headCSS,
              headJS,
              isCopy,
              script,
              isReplace: false,
            };
            this.sendResultEvent(result);
          });
        } else if (String(document.ExtensionOrMimeType).toLocaleLowerCase() === 'html' && isEmbeded == true) {
          const ref2 = RootAppComponent.dialog.open(HtmlSimpleLinkInputComponent, {
            data: {
              title,
              extensionType: 'html',
              isMerchant: false,
              link,
              linkTitle: title,
              type: 'iframe',
            },
            width: '600px',
          });
          ref2.componentInstance.getResult.subscribe((c) => {
            if (c) {
              content = c.content || null;
              style = c.style || null;
              _isCopy = c.isCopy || false;

              ref2.close();

              ref2.afterClosed().subscribe((d) => {
                const result = {
                  content,
                  style,
                  headCSS,
                  headJS,
                  isCopy: _isCopy,
                  isReplace: false,
                };

                this.sendResultEvent(result);
              });
            } else {
              ref2.close();
            }
          });

          ref2.componentInstance.close.subscribe((s) => {
            ref2.close();
          });
          ref2.backdropClick().subscribe((s) => {
            ref2.close();
          });
        } else if (String(document.ExtensionOrMimeType).toLocaleLowerCase() === 'pdf' && isEmbeded == true) {
          const ref2 = RootAppComponent.dialog.open(HtmlSimpleLinkInputComponent, {
            data: {
              title,
              extensionType: 'pdf',
              isMerchant: false,
              link,
              linkTitle: title,
              type: 'iframe',
            },
            width: '600px',
          });
          ref2.componentInstance.getResult.subscribe((c) => {
            if (c) {
              content = c.content || null;
              style = c.style || null;

              _isCopy = c.isCopy || false;

              ref2.close();

              ref2.afterClosed().subscribe((d) => {
                const result = {
                  content,
                  style,
                  headCSS,
                  headJS,
                  isCopy: _isCopy,
                  isReplace: false,
                };

                this.sendResultEvent(result);
              });
            } else {
              ref2.close();
            }
          });

          ref2.componentInstance.close.subscribe((s) => {
            ref2.close();
          });
          ref2.backdropClick().subscribe((s) => {
            ref2.close();
          });
        } else {
          const ref2 = RootAppComponent.dialog.open(HtmlSimpleLinkInputComponent, {
            data: {
              title,
              isMerchant: false,
              link,
              linkTitle: title,
              type: 'link',
            },
            width: '600px',
          });
          ref2.componentInstance.getResult.subscribe((c) => {
            if (c) {
              content = c.content || null;
              style = c.style || null;

              _isCopy = c.isCopy || false;

              ref2.close();

              ref2.afterClosed().subscribe((d) => {
                const result = {
                  content,
                  style,
                  headCSS,
                  headJS,
                  isCopy,
                  isReplace: false,
                };

                this.sendResultEvent(result);
              });
            } else {
              ref2.close();
            }
          });

          ref2.componentInstance.close.subscribe((s) => {
            ref2.close();
          });
          ref2.backdropClick().subscribe((s) => {
            ref2.close();
          });
        }
      }
    }
  }

  copyContent(document, isEmbeded = false) {
    this.build(document, isEmbeded, true);
  }

  insertContent(document, isEmbeded = false) {
    this.build(document, isEmbeded, false);
  }

  insertCopyContent(document, isEmbeded = false) {
    this.build(document, isEmbeded);
  }

  sendResultEvent(payload) {
    if (payload) {
      if (payload.isCopy === true) {
        if (payload.headCSS) {
          this.copy(payload.headCSS);
        } else if (payload.headJS) {
          this.copy(payload.headJS);
        } else if (payload.content) {
          this.copy(payload.content);
        }

        this.getResult.emit(payload);
      } else {
        this.getResult.emit(payload);
      }
    }
  }

  isEmbedOption(d) {
    if (
      d &&
      d &&
      (String(d.ExtensionOrMimeType).toLocaleLowerCase() === 'css' ||
        String(d.ExtensionOrMimeType).toLocaleLowerCase() === 'html' ||
        String(d.ExtensionOrMimeType).toLocaleLowerCase() === 'pdf')
    ) {
      return true;
    }

    return false;
  }

  isHeader(d) {
    if (
      d &&
      d &&
      (String(d.ExtensionOrMimeType).toLocaleLowerCase() === 'js' ||
        String(d.ExtensionOrMimeType).toLocaleLowerCase() === 'css')
    ) {
      return true;
    }

    return false;
  }

  copy(d) {
    if (d) {
      this._clipboardService.copy(d);

      NotifyAppComponent.displayToast('success', 'success', 'Copied');
    }
  }
}
