import { Icon } from './icon';

export const ICONS: Icon[] = [
  { id: 1, icon1: '', icon2: '', icon3: '', icon4: '', icon5: '', icon6: '' },
  { id: 2, icon1: 'email', icon2: 'lock', icon3: 'sms', icon4: 'lock', icon5: 'email', icon6: 'sms' },
  { id: 3, icon1: 'email', icon2: 'lock', icon3: 'sms', icon4: 'lock', icon5: 'email', icon6: 'sms' },
  { id: 4, icon1: '', icon2: '', icon3: '', icon4: '', icon5: '', icon6: '' },
  { id: 5, icon1: 'email', icon2: 'lock', icon3: 'sms', icon4: 'lock', icon5: 'email', icon6: 'sms' },
  { id: 6, icon1: 'email', icon2: 'lock', icon3: 'sms', icon4: 'lock', icon5: 'email', icon6: 'sms' },
  { id: 7, icon1: '', icon2: '', icon3: '', icon4: '', icon5: '', icon6: '' },
  { id: 8, icon1: 'email', icon2: 'lock', icon3: 'sms', icon4: 'lock', icon5: 'email', icon6: 'sms' },
  { id: 9, icon1: 'email', icon2: 'lock', icon3: 'sms', icon4: 'lock', icon5: 'email', icon6: 'sms' },
  { id: 10, icon1: 'email', icon2: 'lock', icon3: 'sms', icon4: 'lock', icon5: 'email', icon6: 'sms' },
  { id: 11, icon1: '', icon2: '', icon3: '', icon4: '', icon5: '', icon6: '' },
  { id: 12, icon1: 'email', icon2: 'lock', icon3: 'sms', icon4: 'lock', icon5: 'email', icon6: 'sms' },
  { id: 13, icon1: 'email', icon2: 'lock', icon3: 'sms', icon4: 'lock', icon5: 'email', icon6: 'sms' },
];
