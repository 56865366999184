import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { AppointmentLookupService } from '../shared/appointmentLookup.service';
import { AppointmentUtilClass } from '../shared/appointmentUtil';

export class AppointmentLookupEditComponentDialogData {
  appointmentLookupID?: string;
}

@Component({
  selector: 'app-appointment-lookup-edit',
  templateUrl: './appointment-lookup-edit.component.html',
  styleUrls: ['./appointment-lookup-edit.component.css'],
})
export class AppointmentLookupEditComponent implements OnInit {
  @Input() isModal = false;
  @Input() appointmentLookupID: string;

  @Output() sendResult = new EventEmitter();
  @Output() closeModal = new EventEmitter();
  appointmentUtil = new AppointmentUtilClass();
  durations = this.appointmentUtil.getDurationArray();
  buffers = this.appointmentUtil.getBufferArray();
  sessionType;
  appointmentLookup: any = {};
  utils = new UtilsClass();

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: AppointmentLookupEditComponentDialogData,
    private appointmentLookupService: AppointmentLookupService,
    private authenticationService: AuthenticationService
  ) {
    if (data) {
      this.isModal = true;

      if (data.appointmentLookupID) {
        this.appointmentLookupID = data.appointmentLookupID;
      }
    }
  }

  ngOnInit(): void {
    this.authenticationService.getSessionType().subscribe((res) => {
      if (res) {
        this.sessionType = res;
        this.fetchAppointmentLookupDetails(this.appointmentLookupID, this.sessionType);
      }
    });
  }

  closeEvent(): void {
    this.closeModal.emit(true);
  }

  onSave(): void {
    if (this.appointmentLookupID && this.sessionType) {
      const payload = {
        label: this.appointmentLookup.Label,
        description: this.utils.cleanText(this.appointmentLookup.Description),
        buffersPre: this.appointmentLookup.Buffers_Pre,
        buffersPost: this.appointmentLookup.Buffers_Post,
        defaultDuration: this.appointmentLookup.DefaultDuration,
        isActive: this.appointmentLookup.Is_Active,
      };

      this.appointmentLookupService.edit(this.appointmentLookupID, payload, this.sessionType).subscribe((res) => {
        if (res) {
          this.sendResult.emit(res);
        }
      });
    }
  }

  private fetchAppointmentLookupDetails(id: string, sessionType: any): void {
    if (id && sessionType) {
      this.appointmentLookupService.getDetails(id, sessionType).subscribe((res) => {
        if (res) {
          this.appointmentLookup = res;
          this.appointmentLookup.Buffers_Pre = this.appointmentLookup.Buffers_Pre || '0';
          this.appointmentLookup.Buffers_Post = this.appointmentLookup.Buffers_Post || '0';
          this.appointmentLookup.Is_Active = this.appointmentLookup.Is_Active === '1';
        }
      });
    }
  }

  replaceAllString(e) {
    if (e && typeof e == 'string') {
      return String(e).replace(/�/g, "'");
    }
  }
}
