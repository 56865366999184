import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { environment } from 'environments/environment';
import { AppMerchantModule } from './app/app.module';
import { enableProdMode } from '@angular/core';

if (environment.production) {
  enableProdMode();
}
platformBrowserDynamic()
  .bootstrapModule(AppMerchantModule)
  .catch((err) => console.error(err));
