import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { DataSource } from '@angular/cdk/table';
import { Component, ElementRef, EventEmitter, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator, MatSort } from '@angular/material';
import { BehaviorSubject, merge as observableMerge, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { TasksService } from '../shared/tasks.service';
import { TaskManagerCreateEditComponent } from '../task-manager-create-edit/task-manager-create-edit.component';

@Component({
  selector: 'app-task-manager-list',
  templateUrl: './task-manager-list.component.html',
  styleUrls: ['./task-manager-list.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('*', style({ opacity: 0 }), { optional: true }),
        query(
          '*',
          stagger('10ms', [
            animate(
              '0.2s ease-in',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-10%)', offset: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('*', style({ opacity: 1 }), { optional: true }),
        query(
          '*',
          stagger('10ms', [
            animate(
              '0.2s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-10%)', offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class TaskManagerListComponent implements OnInit {
  @Input()
  title = 'Tasks';

  @Input()
  pageSize = 10;
  pageSizeOptions = [10, Number(this.pageSize), Number(this.pageSize) * 2, Number(this.pageSize) * 3];

  util = new UtilsClass();

  displayedColumns = ['Picture', 'Label', 'Priority', 'Type', 'Action', 'Date', 'Actions'];
  filters = [];

  searchVals = new FormControl();

  public listDB: LoadRecords | null;
  dataSource: RecordDataSource | null;

  destroyEvent = new EventEmitter();

  filteredSize = null;
  sessionType;
  isPromoterOrAdmin = false;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('filter', { static: false }) filter: ElementRef;

  constructor(private TasksService: TasksService, private authenticationService: AuthenticationService) {}

  ngOnInit() {
    this.authenticationService.isPromoterOrAdmin().subscribe((isPromoterOrAdmin) => {
      this.isPromoterOrAdmin = isPromoterOrAdmin;
    });

    this.authenticationService.getSessionType().subscribe((res) => {
      this.sessionType = res;
      const p = {};
      this.listDB = new LoadRecords(this.TasksService, this.sessionType, this.destroyEvent, p);

      this.dataSource = new RecordDataSource(this.listDB, this.paginator, this.sort);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    const p = {};

    this.listDB = new LoadRecords(this.TasksService, this.sessionType, this.destroyEvent, p);
    this.dataSource = new RecordDataSource(this.listDB, this.paginator, this.sort);
  }

  setFilter(event, field) {
    let filter;
    if (event) {
      filter = {
        field,
        value: event,
      };
    } else {
      filter = {
        field,
        value: '',
      };
    }

    this.dataSource.filter = filter;
    this.filteredSize = this.dataSource.filteredData.length;
  }

  create() {
    const ref = RootAppComponent.dialog.open(TaskManagerCreateEditComponent, {
      data: {},
      width: '1000px',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });

    ref.componentInstance.getResult.subscribe((res) => {
      if (res && res.ID) {
        this.dataSource.addItem = res;

        ref.close();
      }
    });
  }

  Edit(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(TaskManagerCreateEditComponent, {
        data: {
          taskID: id,
        },
        width: '1000px',
      });

      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });

      ref.componentInstance.getResult.subscribe((res) => {
        if (res && res.ID) {
          this.dataSource.replaceItem = res;

          ref.close();
        }
      });
    }
  }

  view(id) {
    if (id) {
      //   let ref = RootAppComponent.dialog.open(ThirdPartyAccessViewComponent, {
      //     data: {
      //       thirdPartyAccessID: id,
      //       isViewTemplate: true
      //     },
      //     width: "600px",
      //     panelClass: 'noCard',
      //     height: "1000px"
      //   });
      //   ref.componentInstance.close.subscribe(res => {
      //     ref.close();
      //   });
      //   ref.componentInstance.getViewTemplate.subscribe(res => {
      //     if (res) {
      //       let ref2 = RootAppComponent.dialog.open(InvitationTemplateMergerComponent, {
      //         data: {
      //           templateID: res,
      //           viewOnly: true,
      //           hideTreatmentValue: true,
      //         },
      //         width: "900px"
      //       });
      //       ref2.componentInstance.close.subscribe(res => {
      //         ref2.close();
      //       })
      //     }
      //   });
    }
  }

  delete(id) {
    if (id) {
      const confirmDialog = new ConfirmDialog(
        'cancel',
        'Are you sure you want to continue?',
        '<p>By removing this, all API keys created for this client will be removed too</p>',

        'Cancel',
        'Okay'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirmDialog,
        // SET TO PAYING CASH CARD
      });
      ref.componentInstance.onConfirm.subscribe((val) => {
        if (val == true) {
          ref.close();
          ref.afterClosed().subscribe((res) => {
            // this.TasksService.deleteTasks(id, this.sessionType).subscribe(res => {
            //   if (res) {
            //     this.dataSource.removeItem = id;
            //     NotifyAppComponent.displayToast(
            //       'success',
            //       'Success!',
            //       'Client Access is removed.'
            //     );
            //   }
            // });
          });
        } else {
          ref.close();
        }
      });
    }
  }

  ngOnDestroy() {
    this.destroyEvent.emit(true);
  }
}

export class LoadRecords implements OnInit {
  public dataChange: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  items: any;
  count: any;

  get data(): any[] {
    return this.dataChange.value;
  }

  firstLoad = false;

  get firstLoadEvent() {
    return this.firstLoad;
  }

  isDestoyed = false;

  serviceRef;

  constructor(private TasksService: TasksService, private sessionType, private destroyEvent, private params) {
    this.destroyEvent.subscribe((res) => {
      this.isDestoyed = res;
    });

    const payload = JSON.parse(JSON.stringify(params));

    payload.section = 0;

    this.serviceRef = this.TasksService.getPracticeTaskList(payload).subscribe((res) => {
      if (res) {
        for (let i = 0; i < res.length; i++) {
          res[i]['fullName'] = '';
          if (res[i] && res[i]['Name']) {
            res[i]['fullName'] = String(res[i]['fullName'] + res[i]['Name']).toLocaleLowerCase();
          }
          if (res[i] && res[i]['Description']) {
            res[i]['fullName'] = String(res[i]['fullName'] + res[i]['Description']).toLocaleLowerCase();
          }
          if (res[i] && res[i]['Type']) {
            res[i]['fullName'] = String(res[i]['fullName'] + res[i]['Type']).toLocaleLowerCase();
          }
          if (res[i] && res[i]['Action']) {
            res[i]['fullName'] = String(res[i]['fullName'] + res[i]['Action']).toLocaleLowerCase();
          }
          if (res[i] && res[i]['Marketing_Description']) {
            res[i]['fullName'] = String(res[i]['fullName'] + res[i]['Marketing_Description']).toLocaleLowerCase();
          }
        }
      }

      this.count = res.length;
      this.items = res;
      this.dataChange.next(this.items.slice());
      this.firstLoad = true;
      const innerFunction = (section) => {
        section = section + 1;
        UtilsClass.loadingDataSection(section);
        payload.section = section;

        this.serviceRef = this.TasksService.getPracticeTaskList(payload).subscribe((res) => {
          if (res.length > 0) {
            if (res) {
              for (let i = 0; i < res.length; i++) {
                res[i]['fullName'] = '';
                if (res[i] && res[i]['Name']) {
                  res[i]['fullName'] = String(res[i]['fullName'] + res[i]['Name']).toLocaleLowerCase();
                }
                if (res[i] && res[i]['Description']) {
                  res[i]['fullName'] = String(res[i]['fullName'] + res[i]['Description']).toLocaleLowerCase();
                }
                if (res[i] && res[i]['Type']) {
                  res[i]['fullName'] = String(res[i]['fullName'] + res[i]['Type']).toLocaleLowerCase();
                }
                if (res[i] && res[i]['Action']) {
                  res[i]['fullName'] = String(res[i]['fullName'] + res[i]['Action']).toLocaleLowerCase();
                }
                if (res[i] && res[i]['Marketing_Description']) {
                  res[i]['fullName'] = String(res[i]['fullName'] + res[i]['Marketing_Description']).toLocaleLowerCase();
                }
              }
            }

            this.count = res.count;
            this.items = this.items.concat(res);
            this.dataChange.next(this.items.slice());
            if (this.isDestoyed != true) {
              innerFunction(section);
            }
          } else {
            return true;
          }
        });
        UtilsClass.stopLoadingInterceptor();
      };

      if (this.isDestoyed != true) {
        innerFunction(1);
      }
    });
  }

  ngOnInit() {}

  ngOnDestroy() {
    if (this.serviceRef) {
      this.serviceRef.unsubscribe();
    }
  }
}

export class RecordDataSource extends DataSource<any> {
  _filterChange = new BehaviorSubject('');
  field = '';

  get filter(): any {
    return this._filterChange.value;
  }

  set filter(item: any) {
    this.field = item.field;
    this._filterChange.next(item.value);
  }

  filteredData: any[] = [];

  constructor(private _tableDatabase: LoadRecords, private _paginator: MatPaginator, private _sort: MatSort) {
    super();
    this._filterChange.subscribe(() => (this._paginator.pageIndex = 0));
  }

  set addItem(res) {
    this._tableDatabase.data.unshift(res);

    this._tableDatabase.dataChange.next(this._tableDatabase.data);
  }

  set replaceItem(item) {
    const _index = this._tableDatabase.data.findIndex((_obj) => _obj.ID == item.ID);
    this._tableDatabase.data[_index] = item;
    this._tableDatabase.dataChange.next(this._tableDatabase.data);
  }

  set removeItem(id) {
    const data = this._tableDatabase.data.filter((row) => row.ID != id);
    this._tableDatabase.dataChange.next(data);
  }

  set removeItemByTaskID(taskID) {
    const data = this._tableDatabase.data.filter((row) => row['Task_key'] != taskID);
    this._tableDatabase.dataChange.next(data);
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<any[]> {
    const displayDataChanges = [
      this._tableDatabase.dataChange,
      this._filterChange,
      this._paginator.page,
      this._sort.sortChange,
    ];

    return observableMerge(...displayDataChanges).pipe(
      map(() => {
        // Filter data
        this.filteredData = this._tableDatabase.data.slice().filter((item: any) => {
          if (this.field == 'Templates') {
            if (!this.filter || (this.filter && this.filter.length <= 0)) {
              return true;
            } else if (
              item &&
              item['Template_Key'] &&
              this.filter &&
              this.filter.length > 0 &&
              this.filter.indexOf(item['Template_Key']) !== -1
            ) {
              return true;
            }

            return false;
          } else if (this.field == 'Applications') {
            if (!this.filter || (this.filter && this.filter.length <= 0)) {
              return true;
            } else if (
              item &&
              item['Third_Party_Client_Key'] &&
              this.filter &&
              this.filter.length > 0 &&
              this.filter.indexOf(item['Third_Party_Client_Key']) !== -1
            ) {
              return true;
            }

            return false;
          } else {
            let f = '';
            f = item[this.field] || '';

            const searchStr = f.toLowerCase();

            return searchStr.indexOf(this.filter.toLowerCase()) != -1;
          }
        });
        const data = this.getSortedData(this.filteredData.slice());
        // Grab the page's slice of data.
        const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
        return data.splice(startIndex, this._paginator.pageSize);
      })
    );
  }

  disconnect() {}

  /** Returns a sorted copy of the database data. */
  getSortedData(data: any[]): any[] {
    // const data = data;
    if (!this._sort.active || this._sort.direction == '') {
      return data;
    }

    return data.sort((a, b) => {
      let propertyA: number | string = '';
      let propertyB: number | string = '';

      switch (this._sort.active) {
        case 'Label':
          [propertyA, propertyB] = [a['Label'], b['Label']];
          break;

        case 'Description':
          [propertyA, propertyB] = [a['Task.Description'], b['Task.Description']];
          break;
      }

      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

      return (valueA < valueB ? -1 : 1) * (this._sort.direction == 'asc' ? 1 : -1);
    });
  }
}
