import { animate, animateChild, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { FilterArrayMultipleContainValuePipe } from '../../../shared/pipes/filter-array-object-contain.pipe';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { ActionLogViewComponent } from '../action-log-view/action-log-view.component';
import { ActionLogService } from '../shared/action-log.service';
import { ActionLogUtilClass } from '../shared/actionLogUtil';

@Component({
  selector: 'app-activity-list-menu',
  templateUrl: './activity-list-menu.component.html',
  styleUrls: ['./activity-list-menu.component.css'],
  animations: [
    trigger('fade', [transition(':enter', [style({ opacity: 0 }), animate('.6s ease')])]),
    trigger('stagger', [transition(':enter', [query(':enter', stagger('.3s', [animateChild()]), { optional: true })])]),
  ],
  providers: [FilterArrayMultipleContainValuePipe],
})
export class ActivityListMenuComponent implements OnInit {
  @Input()
  targetID = '';

  @Input()
  itemID = '';

  @Input()
  merchantID;

  dateFrom = new Date('2020-03-10');

  dateTo;

  @Input()
  limit = 10;

  @Input()
  numOfTasks;

  @Input()
  isInvitationActions = true;

  @Input()
  isChangeRoute = new EventEmitter();

  @Output()
  getTotalActivities = new EventEmitter();

  isModal = false;
  isPromoterOrAdmin = false;
  settings = Settings.global;

  actionLogs = [];
  originalActionLogs;

  emptyList = false;
  util = new UtilsClass();
  actionLogUtil = new ActionLogUtilClass();

  currentUserID;

  sectionCount = 0;
  isShowAllMessage = false;

  _dateFrom;
  sessionType;

  constructor(
    private authenticationService: AuthenticationService,
    private actionlogService: ActionLogService,
    private router: Router,
    private filterArrayMultipleContainValue: FilterArrayMultipleContainValuePipe,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.targetID) {
        this.targetID = data.targetID;
      }

      if (data.itemID) {
        this.itemID = data.itemID;
      }

      this.isModal = true;
    }
  }

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((sessionType) => {
      this.sessionType = sessionType;

      setTimeout(() => {
        this.loadData();
      }, 2000);

      this.isChangeRoute.subscribe((res) => {
        if (res == true) {
          this.sectionCount = 0;
          this.isShowAllMessage = false;
        }
      });
    });
  }

  // ngOnChanges(changes: SimpleChanges) {
  //   this.loadData();
  // }

  loadData() {
    this.authenticationService.getCurrentUser().subscribe((res) => {
      if (res && res['data'] && res['data']['DateTimeCreated']) {
        this.currentUserID = res['data']['operatorID'];

        const __dateFrom = moment('2020-03-10', 'YYYY-MM-DD HH:mm:ss');
        const _operatorCreateDate = moment(res.data['DateTimeCreated'], 'YYYY-MM-DD HH:mm:ss');
        this._dateFrom = __dateFrom.diff(_operatorCreateDate) > 0 ? __dateFrom : _operatorCreateDate;

        if (this.dateFrom instanceof Date) {
          this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
            this.isPromoterOrAdmin = res;

            const payload = {
              isInvitationActions: true,
              limit: 20,
              section: 0,
              dateFrom: this.util.EPdateFormat(this._dateFrom.toISOString()),
              dateTo: this.util.EPdateFormat(this.dateTo),
            };

            if (this.isPromoterOrAdmin == true) {
              payload['merchantID'] = this.merchantID;
            }

            this.actionlogService.getActionLogListHidden(payload, this.sessionType).subscribe((res) => {
              if (res.length > 0) {
                this.actionLogs = res;

                this.filterItems(this.isShowAllMessage);
              }
            });
          });
        }
      }
    });
  }

  showAllMessages() {
    this.isShowAllMessage = true;
    this.loadData();
  }

  showUnreadMessage() {
    this.isShowAllMessage = false;
    this.loadData();
  }

  clickLoadMore() {
    this.sectionCount++;
    this.loadMore(this.sectionCount);
  }

  loadMore = (section) => {
    const payload = {
      isInvitationActions: true,
      limit: 20,
      section: 0,
      dateFrom: this.util.EPdateFormat(this._dateFrom.toISOString()),
      dateTo: this.util.EPdateFormat(this.dateTo),
    };

    section = section + 1;
    payload.section = section;

    this.actionlogService.getActionLogList(payload, this.sessionType).subscribe((res) => {
      if (res.length > 0) {
        this.actionLogs = this.actionLogs.concat(res);
        this.filterItems(this.isShowAllMessage);
        if (res.length >= 20) {
          this.loadMore(section);
        }
      } else {
        NotifyAppComponent.displayToast(
          'fas fa-info',
          'No more messages',
          'There are no more messages in our database'
        );
        return true;
      }
    });
  };

  filterItems(isAllMessage = false) {
    if (this.actionLogs) {
      for (let i = 0; i < this.actionLogs.length; i++) {
        const viewedIDs = this.actionLogs[i]['Who_Viewed'].split('|');

        const flaggedIDs = this.actionLogs[i]['Who_Flagged'].split('|');

        const archivedIDs = this.actionLogs[i]['Who_Archived'].split('|');

        if (viewedIDs.indexOf(this.currentUserID) != -1) {
          this.actionLogs[i]['isViewed'] = 'true';
        } else {
          this.actionLogs[i]['isViewed'] = 'false';
        }

        if (flaggedIDs.indexOf(this.currentUserID) != -1) {
          this.actionLogs[i]['isFlagged'] = true;
        } else {
          this.actionLogs[i]['isFlagged'] = false;
        }

        if (archivedIDs.indexOf(this.currentUserID) != -1) {
          this.actionLogs[i]['isArchived'] = 'true';
        } else {
          this.actionLogs[i]['isArchived'] = 'false';
        }
      }

      if (isAllMessage == false) {
        this.actionLogs = this.filterArrayMultipleContainValue.transform(this.actionLogs, ['isArchived'], 'false');
        this.actionLogs = this.filterArrayMultipleContainValue.transform(this.actionLogs, ['isViewed'], 'false');
      }
    }
  }

  viewLog(id) {
    const ref = RootAppComponent.dialog.open(ActionLogViewComponent, {
      data: id,
      width: '650px',
    });

    ref.componentInstance.closeModal.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  dateFilter(date) {
    return moment(date).format('dddd Do MMM');
  }

  timeFilter(date) {
    return moment(date).format('h:mm a');
  }

  showInvitationDetails(item) {
    if (item && item['ID']) {
      // mark as viewed if you view it
      this.markOneViewed(item['ID']);

      this.router.navigate([
        '/merchant',
        { outlets: { page: ['customer-profile', item['Who.key'], 'treatment', item['What.key']] } },
      ]);
    }
  }

  markOneViewed(ID) {
    this.actionlogService.markAsViewed(ID).subscribe((res) => {
      if (res) {
        NotifyAppComponent.displayToast('success', 'Mark as read', 'You have marked this notification as read');

        const ids = this.actionLogs.map((x) => x.ID);

        this.actionLogs.splice(ids.indexOf(res.ID), 1);

        this.numOfTasks--;

        this.filterItems();
      }
    });
  }

  markAsNotViewed(ID) {
    this.actionlogService.markAsNotViewed(ID).subscribe((res) => {
      if (res) {
        NotifyAppComponent.displayToast('success', 'Mark as unread', 'You have marked this notification as unread');

        const ids = this.actionLogs.map((x) => x.ID);

        this.actionLogs.splice(ids.indexOf(res.ID), 1, res);

        this.filterItems();
      }
    });
  }

  markAllViewed() {
    const payload1 = {
      dateFrom: this.util.EPdateFormat(this._dateFrom.toISOString()),
      isInvitationActions: true,
      onlyUnread: true,
      viewerID: this.currentUserID,
    };
    this.actionlogService.getActionLogList(payload1, this.sessionType).subscribe((res) => {
      if (res) {
        this.actionLogs = res;

        this.filterItems();

        const ids = this.actionLogs.filter((item) => item['isViewed'] == 'false').map((x) => x.ID);

        if (ids && ids.length > 0) {
          const payload = {
            ids: ids.join(','),
          };

          this.actionlogService.markAllAsViewed(payload).subscribe((res) => {
            if (res) {
              NotifyAppComponent.displayToast(
                'success',
                'Mark all as read',
                'You have marked all your latest notifications as read'
              );
              // need to update the list
              this.actionLogs = [];
              this.numOfTasks = 0;
            }
          });
        }
      }
    });

    if (this.actionLogs) {
    }
  }

  addFlag(ID) {
    this.actionlogService.flagged(ID).subscribe((res) => {
      if (res) {
        NotifyAppComponent.displayToast('success', 'Flagged message', 'You have flagged this message');

        const ids = this.actionLogs.map((x) => x.ID);

        this.actionLogs.splice(ids.indexOf(res.ID), 1, res);

        this.filterItems();
      }
    });
  }

  removeFlag(ID) {
    this.actionlogService.removeFlagged(ID).subscribe((res) => {
      if (res) {
        NotifyAppComponent.displayToast('success', 'Remove Flagged', 'You have removed the flag of this message');

        const ids = this.actionLogs.map((x) => x.ID);

        this.actionLogs.splice(ids.indexOf(res.ID), 1, res);

        this.filterItems();
      }
    });
  }

  archived(ID) {
    this.actionlogService.archived(ID).subscribe((res) => {
      if (res) {
        NotifyAppComponent.displayToast('success', 'Archived message', 'You have archived this message');

        const ids = this.actionLogs.map((x) => x.ID);

        this.actionLogs.splice(ids.indexOf(res.ID), 1);

        this.numOfTasks--;
        // this.filterItems();
      }
    });
  }
}
