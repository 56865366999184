<div class="" *ngIf="simplePage">
  <div
    class="card-header primary-gradient-img clearfix inModal isSimplePage"
    *ngIf="isModal == true && hideHeader != true && simplePage.title && displayHeader == true && !color && !colorSecond"
  >
    <div class="row clearfix">
      <!-- Modal dismiss button -->
      <button class="btn-close btn-clear mat-button white" matDialogClose>
        <mat-icon>clear</mat-icon>
      </button>
      <!-- merchant calculated name -->
      <div class="row titleArea clearfix">
        <h2 class="summary-header rm-m" *ngIf="!title">
          {{ simplePage.title }}
        </h2>

        <h2 class="summary-header rm-m" *ngIf="title">
          {{ title }}
        </h2>
      </div>
    </div>
  </div>

  <div
    class="card-header primary-gradient-img clearfix inModal isSimplePage"
    *ngIf="isModal == true && hideHeader != true && simplePage.title && displayHeader == true && color && colorSecond"
    [attr.style]="
      'background:linear-gradient(135deg, ' + colorSecond + ' 0%, ' + color + ' 100%) !important' | safeStyle
    "
    [attr.style]="
      'background:-moz-linear-gradient(135deg, ' + colorSecond + ' 0%, ' + color + ' 100%) !important' | safeStyle
    "
    [attr.style]="
      'background:-webkit-linear-gradient(135deg, ' + colorSecond + ' 0%, ' + color + ' 100%) !important' | safeStyle
    "
  >
    <div class="row clearfix">
      <!-- Modal dismiss button -->
      <button class="btn-close btn-clear mat-button white" matDialogClose>
        <mat-icon>clear</mat-icon>
      </button>
      <!-- merchant calculated name -->
      <div class="row titleArea clearfix">
        <h2 class="summary-header rm-m" *ngIf="!title">
          {{ simplePage.title }}
        </h2>

        <h2 class="summary-header rm-m" *ngIf="title">
          {{ title }}
        </h2>
      </div>
    </div>
  </div>

  <mat-dialog-content>
    <button
      class="btn-close btn-clear btn-clear-hide-header mat-button white"
      *ngIf="hideHeader == true"
      matDialogClose
    >
      <mat-icon>clear</mat-icon>
    </button>

    <div *ngIf="simplePage.content" [innerHTML]="simplePage.content | safeHtml"></div>
  </mat-dialog-content>

  <div class="drop-button text-center full-width mt" *ngIf="canAccept == true">
    <hr />
    <mat-slide-toggle class="pull-left mt" [(ngModel)]="accepted" name="accepted">
      I agree, please proceed
    </mat-slide-toggle>

    <button
      class="pull-right btn-large smt"
      *ngIf="accepted == true"
      [attr.style]="'background-color:' + color + ' !important' | safeStyle"
      (click)="proceedEvent()"
      mat-raised-button
      color="accent"
    >
      Proceed <mat-icon class="fas fa-angle-right sml"></mat-icon>
    </button>

    <button
      class="pull-right btn-large smt"
      *ngIf="accepted != true"
      [disabled]="true"
      (click)="proceedEvent()"
      mat-raised-button
      color="accent"
    >
      Proceed <mat-icon class="fas fa-angle-right sml"></mat-icon>
    </button>
  </div>
</div>
