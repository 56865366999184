<div class="container text-center">
  <h2 class="sr-title">Add new Document</h2>
  <form #addForm="ngForm">
    <div class="row clearfix">
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>Page Category</mat-label>
        <input [(ngModel)]="helpPage.category" name="category" matInput placeholder="Page Category" required/>
      </mat-form-field>
    </div>
    <div class="row clearfix">
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>Page Topic</mat-label>
        <input [(ngModel)]="helpPage.topic" name="topic" matInput placeholder="Page Topic" required/>
      </mat-form-field>
    </div>

    <div class="row clearfix">
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>Page Title</mat-label>
        <input [(ngModel)]="helpPage.pageTitle" name="pageTitle" matInput placeholder="Page Title" required/>
      </mat-form-field>
    </div>

    <div class="clearfix full-width text-center">
      <button
        class="btn-large"
        [disabled]="!addForm.form.valid"
        (click)="addSimplePage()"
        mat-raised-button
        color="accent"
      >
        <mat-icon>add</mat-icon>
        Add
      </button>
    </div>
  </form>
</div>
