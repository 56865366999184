import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-getting-started-step2-merchant',
  templateUrl: './step2.component.html',
  styleUrls: ['./step2.component.scss'],
})
export class GettingStartedMerchantStep2Component implements OnInit {
  constructor() {}

  ngOnInit() {}
}
