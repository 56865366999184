import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
// import { SignaturePad } from 'angular2-signaturepad/signature-pad';

// email regex
const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

@Component({
  selector: 'app-merchant-create',
  templateUrl: './merchant-create.component.html',
  styleUrls: ['./merchant-create.component.css'],
})
export class MerchantCreateComponent implements OnInit {
  // email validation

  emailFormControl = new FormControl('', [Validators.required, Validators.pattern(EMAIL_REGEX)]);

  emailFormControl2 = new FormControl('', [Validators.required, Validators.pattern(EMAIL_REGEX)]);

  pracRoles = [
    { value: '0', viewValue: 'Practice Owner' },
    { value: '1', viewValue: 'Practice Manager' },
    { value: '2', viewValue: 'Practice Administration' },
    { value: '3', viewValue: 'Dentist' },
    { value: '4', viewValue: 'Hygenist' },
  ];

  payMethods = [
    { value: '0', viewValue: 'Credit Card' },
    { value: '1', viewValue: 'Bank Account (Direct Debit)' },
  ];

  // drawStart() {
  // will be notified of szimek/signature_pad's onBegin event

  // }

  // public clear() {
  //   this.signaturePad.clear();
  // }

  // checkbox

  checked = true;
  disabled = true;

  // signature pad

  // @ViewChild(SignaturePad) signaturePad: SignaturePad;
  // @ViewChild('sigContainer') sigContainer;

  // signaturePadOptions: Object = { // passed through to szimek/signature_pad constructor
  //   'canvasWidth': 500,
  //   'canvasHeight': 300,
  //   'penColor': 'rgb(0, 0, 0)'
  // };

  ngAfterViewInit() {
    // this.signaturePad is now available
    // this.signaturePad.set('minWidth', 0.5); // set szimek/signature_pad options at runtime
    // this.signaturePad.set('maxWidth', 3); // set szimek/signature_pad options at runtime
    // this.signaturePad.clear(); // invoke functions from szimek/signature_pad API
    // this.beResponsive();
    // commented out responsive at the moment
  }

  // set the dimensions of the signature pad canvas
  // public beResponsive() {
  //   console.log('Resizing signature pad canvas to suit container size');
  //   this.size(this.sigContainer, this.signaturePad);
  // }

  // public size(container: sigContainer, signaturePad: SignaturePad){
  //   this.signaturePad.set('canvasWidth', sigContainer.nativeElement.clientWidth);
  //   this.signaturePad.set('canvasHeight', sigContainer.nativeElement.clientHeight);
  // }

  // drawComplete() {
  // will be notified of szimek/signature_pad's onEnd event

  // }

  finishFunction() {}

  constructor(private location: Location) {}

  ngOnInit() {}

  cancel() {
    this.location.back(); // <-- go back to previous location on cancel
  }
}
