import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ContractService } from '../shared/contract.service';

@Component({
  selector: 'app-contract-document',
  templateUrl: './contract-document.component.html',
  styleUrls: ['./contract-document.component.css'],
  animations: [
    trigger('onChangeAnimation', [
      transition('void => *', [
        query('.animate', style({ opacity: 0 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.15s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(0)', offset: 0, height: 0, margin: 0 }),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0, height: '*', margin: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.animate', style({ opacity: 1 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.15s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0, height: '*', margin: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(0)', offset: 1.0, height: 0, margin: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class ContractDocumentComponent implements OnInit {
  @Input()
  contractID;

  isModal = false;

  documents;

  constructor(
    private contractService: ContractService,
    private activeRoute: ActivatedRoute,

    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.contractID) {
        this.contractID = data.contractID;
        this.isModal = true;
      }
    }
  }

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      if (params['contractID']) {
        this.contractID = params['contractID'];
      }

      if (this.contractID) {
        const payload = {
          fields: 'DateAdded,DateTimeCreated,Description,Extension,ID,Label,URL.Complete,URL.Relative,URL.Base',
        };

        this.contractService.getConractDocument(this.contractID, payload).subscribe((res) => {
          if (res) {
            this.documents = res;
          }
        });
      }
    });
  }
}
