<div class="rel" *ngIf="listDB.firstLoadEvent == false" @ngIfAnimation>
  <div class="row clearfix padded rel">
    <h1 class="dataHeader rm-mt transparent">Fetching data...</h1>
  </div>
</div>

<div [hidden]="listDB.data.length == 0">
  <div [hidden]="displayContractView == true || displayInvitationView == true || displaySettlmentList == true">
    <h1 class="dataHeader">
      Financed applications
      <span class="badge">{{ dataSource.filteredData.length }}</span>
    </h1>
    <p class="help-label dataHeader" *ngIf="approvedOrActive != null">List of Approved and Active Contracts</p>
    <mat-card class="list-card">
      <div class="row clearfix searchArea primary-gradient-img">
        <div class="row flex justify">
          <div class="full-width flex" id="search">
            <mat-icon class="schIcon mt mr">search</mat-icon>
            <!-- <mat-select class="schSel full-width" matInput placeholder="Add Filters" [formControl]="searchVals"
              multiple>

              <mat-select-trigger>
                {{searchVals.value ? searchVals.value[0] : ''}}
                <span *ngIf="searchVals.value?.length > 1" class="additional-selection">
                  (+{{searchVals.value.length - 1}} others)
                </span>
              </mat-select-trigger>
              <mat-option *ngFor="let searchVal of searchValList" [value]="searchVal">{{searchVal}}</mat-option>
            </mat-select> -->

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>{{ "KEYWORD.patient" | translate | titlecase }} Name</mat-label>
              <input
                class="clearfix"
                (input)="setFilter($event.target.value, 'Customer.FullName')"
                name="patientName"
                placeholder=""
                matInput
              />
            </mat-form-field>

            <mat-form-field class="full-width marginSearch" appearance="outline">
              <mat-label>Product</mat-label>

              <mat-select
                class="margin-btm"
                (selectionChange)="setFilter($event.value, 'ProductName')"
                name="product"
                placeholder="Product"
              >
                <mat-option [value]="null">
                  All
                </mat-option>
                <mat-option
                  *ngFor="
                    let p of dataSource.filteredData
                      | objectPropertyValue: 'ProductName'
                      | unique
                      | without: ['', null]
                      | orderBy
                  "
                  [value]="p"
                >
                  {{ p }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="full-width clearfix flex animated fadeInDown" *ngIf="searchVals.value?.length > 0">
            <app-date-input
              class="full-width"
              *ngIf="searchVals.value?.indexOf('Application Date') !== -1"
              [isClear]="true"
              (selectedDate)="setFilter($event.target.value, 'ApplicationDate')"
              name="ApplicationDate"
            >
            </app-date-input>
            <mat-select
              class="full-width"
              *ngIf="searchVals.value?.indexOf('Status') !== -1"
              (selectionChange)="setFilter($event.value, 'Status')"
              name="Status"
              placeholder="Status"
            >
              <mat-option [value]="null">
                All
              </mat-option>
              <mat-option
                *ngFor="let a of listDB.data | objectPropertyValue: 'Status' | unique | without: ['', null] | orderBy"
                [value]="a"
              >
                {{ a }}
              </mat-option>
            </mat-select>
            <mat-form-field class="full-width" *ngIf="searchVals.value?.indexOf('Customer FullName') !== -1">
              <mat-label>Customer FullName</mat-label>
              <input
                class="clearfix"
                (input)="setFilter($event.target.value, 'Customer.FullName')"
                name="CustomerFullName"
                placeholder="Customer FullName"
                matInput
              />
            </mat-form-field>
            <mat-form-field class="full-width" *ngIf="searchVals.value?.indexOf('Mobile') !== -1">
              <mat-label>Mobile</mat-label>
              <input
                class="clearfix"
                (input)="setFilter($event.target.value, 'Customer.Mobile')"
                name="Mobile"
                placeholder="Mobile"
                matInput
              />
            </mat-form-field>
            <mat-form-field class="full-width" *ngIf="searchVals.value?.indexOf('Email') !== -1">
              <mat-label>Email</mat-label>
              <input
                class="clearfix"
                (input)="setFilter($event.target.value, 'Customer.Email')"
                name="Email"
                placeholder="Email"
                matInput
              />
            </mat-form-field>
            <mat-select
              class="full-width"
              *ngIf="searchVals.value?.indexOf('Product Name') !== -1"
              (selectionChange)="setFilter($event.value, 'ProductName')"
              name="ProductName"
              placeholder="Product Name"
            >
              <mat-option [value]="null">
                All
              </mat-option>
              <mat-option
                *ngFor="
                  let a of listDB.data | objectPropertyValue: 'ProductName' | unique | without: ['', null] | orderBy
                "
                [value]="a"
              >
                {{ a }}
              </mat-option>
            </mat-select>
            <mat-select
              class="full-width"
              *ngIf="searchVals.value?.indexOf('Loan Purpose') !== -1"
              (selectionChange)="setFilter($event.value, 'LoanPurpose')"
              name="LoanPurpose"
              placeholder="Loan Purpose"
            >
              <mat-option [value]="null">
                All
              </mat-option>
              <mat-option
                *ngFor="
                  let a of listDB.data | objectPropertyValue: 'LoanPurpose' | unique | without: ['', null] | orderBy
                "
                [value]="a"
              >
                {{ a }}
              </mat-option>
            </mat-select>
          </div>

          <!-- date output -->
          <div class="ml timeOutput clearfix" *ngIf="dateFrom != null || dateTo != null">
            <mat-icon class="pull-left mt mr">schedule</mat-icon>
            <div class="pull-right">
              <p class="small rm-mb">
                <span *ngIf="dateFrom"
                >From:
                  <strong>{{ dateFrom | customDateTime }} </strong>
                </span>
              </p>
              <p class="small rm-mt">
                <span *ngIf="dateTo">
                  To:
                  <strong>{{ dateTo | customDateTime }}</strong>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <mat-table class="list-table" #table [dataSource]="dataSource" matSort>
        <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->
        <!-- ID Column -->
        <ng-container cdkColumnDef="ApplicationDate">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Application Date</mat-header-cell>
          <mat-cell
            *cdkCellDef="let row"
            (click)="openSettlementList(row['ID'])"
            matTooltip="{{ row['ApplicationDate'] | customDate }}"
          >
            {{ row['ApplicationDate'] | customDate }}
          </mat-cell>
        </ng-container>
        <ng-container cdkColumnDef="Status">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Status</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="openSettlementList(row['ID'])" matTooltip="{{ row['Status'] }}">
            {{ row['Status'] }}
          </mat-cell>
        </ng-container>
        <ng-container cdkColumnDef="CustomerName">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Name</mat-header-cell>
          <mat-cell
            *cdkCellDef="let row"
            (click)="openSettlementList(row['ID'])"
            matTooltip="{{ row['Customer.FullName'] }}"
          >
            {{ row['Customer.FullName'] }}
          </mat-cell>
        </ng-container>
        <ng-container cdkColumnDef="CustomerMobile">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Phone/Mobile</mat-header-cell>
          <mat-cell
            *cdkCellDef="let row"
            (click)="openSettlementList(row['ID'])"
            matTooltip="{{ row['Customer.Mobile'] | customPhone }}"
          >
            {{ row['Customer.Mobile'] | customPhone }}
          </mat-cell>
        </ng-container>
        <ng-container cdkColumnDef="CustomerEmail">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Email</mat-header-cell>
          <mat-cell
            *cdkCellDef="let row"
            (click)="openSettlementList(row['ID'])"
            matTooltip="{{ row['Customer.Email'] }}"
          >
            {{ row['Customer.Email'] }}
          </mat-cell>
        </ng-container>
        <ng-container cdkColumnDef="ProductName">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Product</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="openSettlementList(row['ID'])" matTooltip="{{ row['ProductName'] }}">
            {{ row['ProductName'] }}
          </mat-cell>
        </ng-container>
        <ng-container cdkColumnDef="LoanPurpose">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Loan Purpose</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="openSettlementList(row['ID'])" matTooltip="{{ row['LoanPurpose'] }}">
            {{ row['LoanPurpose'] }}
          </mat-cell>
        </ng-container>
        <ng-container cdkColumnDef="AmountInvoice">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Invoice</mat-header-cell>
          <mat-cell
            *cdkCellDef="let row"
            (click)="openSettlementList(row['ID'])"
            matTooltip="{{ row['Amount.Invoice'] | customCurrency }}"
          >
            {{ row['Amount.Invoice'] | customCurrency }}
          </mat-cell>
        </ng-container>
        <ng-container cdkColumnDef="AmountFinanced">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Financed</mat-header-cell>
          <mat-cell
            *cdkCellDef="let row"
            (click)="openSettlementList(row['ID'])"
            matTooltip="{{ row['Amount.Financed'] | customCurrency }}"
          >
            {{ row['Amount.Financed'] | customCurrency }}
          </mat-cell>
        </ng-container>
        <ng-container cdkColumnDef="Actions">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Actions</mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <div style="float: right">


              <app-invitation-actions *ngIf="row" [customerID]="row['Customer_key']"
                [invitationID]="row['Invitation_key']" [items]="items" [contractID]="row['ID']"
                [merchantID]="row['Merchant_key']" [dentistID]="row['DentistContact_key']"
                [campaignID]="row['Campaign_Key']" [contract]="row" [productID]="row['Product_key']">
              </app-invitation-actions>

              <!-- <button [matMenuTriggerFor]="menu" mat-icon-button>
                <mat-icon>more_vert</mat-icon>
              </button> -->
              <mat-menu #menu="matMenu">
                <!-- <button *ngIf="row['ID']" mat-menu-item (click)="openQuickViewDialog(row['ID'])">
                  <mat-icon>description</mat-icon>
                  <span>{{"QuickViewContract" | translate}}</span>
                </button> -->

                <!-- <button mat-menu-item *ngIf="row['ID']" (click)="openDetailContractDialog(row['ID'])">
                  <mat-icon>portrait</mat-icon>
                  <span>{{"ContractDetails" | translate}}</span>
                </button> -->
                <!-- <button mat-menu-item *ngIf="row['Invitation_key']" (click)="openInvitationView(row['Invitation_key'])">
                  <mat-icon>description</mat-icon>
                  <span> {{"QuickViewInvitation" | translate}} </span>
                </button> -->
                <!-- <button mat-menu-item *ngIf="row['Invitation_key']" (click)="openInviteViewDetails(row['Invitation_key'])">
                  <mat-icon>description</mat-icon>
                  <span> {{"InvitationDetailsView" | translate}} </span>
                </button> -->
                <!-- <button mat-menu-item *ngIf="row['Product_key']" (click)="openProductView(row['Product_key'])">
                  <mat-icon>business_center</mat-icon>
                  <span>{{"ProductView" | translate}}</span>
                </button> -->

                <button *ngIf="row['Customer_key']" (click)="openCustomerView(row['Customer_key'])" mat-menu-item>
                  <mat-icon>person</mat-icon>
                  <span>{{ "KEYWORD.patient" | translate | titlecase }} Profile</span>
                </button>

                <button (click)="createNote(row['Customer_key'], row['ID'])" mat-menu-item>
                  <mat-icon>note_add</mat-icon>
                  <span>Create Note</span>
                </button>

                <button (click)="viewNotes(row['Customer_key'])" mat-menu-item>
                  <mat-icon class="fa fa-list-alt"></mat-icon>
                  <span>View Notes</span>
                </button>

                <button
                  *ngIf="row['Status.Code'] == 'ACTIVE' && toNumber(row['Settlements.NotYetDrawn']) > 0"
                  (click)="createSettlement(row['ID'])"
                  mat-menu-item
                >
                  <mat-icon class="fas fa-file-contract" aria-hidden="true"></mat-icon>
                  <span>New Settlement</span>
                </button>
              </mat-menu>
            </div>
          </mat-cell>
        </ng-container>
        <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *cdkRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
      <mat-paginator
        #paginator
        [length]="dataSource.filteredData.length"
        [pageIndex]="0"
        [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions"
      >
      </mat-paginator>
    </mat-card>
  </div>
  <div *ngIf="contractID != null && displayContractView == true">
    <app-contract-view [contractID]="contractID"></app-contract-view>
    <div class="full-width text-center">
      <button class="btn-large" (click)="cancelContractViewDetails()" mat-raised-button color="accent">
        Go Back
      </button>
    </div>
  </div>
  <div *ngIf="invitationID != null && displayInvitationView == true">
    <!-- <app-invitation-view [invitationID]="invitationID"></app-invitation-view> -->
    <div class="full-width text-center">
      <button class="btn-large" (click)="cancelInviteViewDetails()" mat-raised-button color="accent">
        Go Back
      </button>
    </div>
  </div>

  <div *ngIf="contractID != null && displaySettlmentList == true">
    <app-settlement-list [_contractID]="contractID" [dateFrom]="dateFrom" [dateTo]="dateTo"></app-settlement-list>
    <div class="full-width mt">
      <button class="" (click)="cancelSettlementList()" mat-raised-button color="accent">
        <mat-icon>chevron_left</mat-icon>
        Go Back
      </button>
    </div>
  </div>
</div>

<div *ngIf="listDB.data.length == 0 && listDB.firstLoadEvent != false">
  <app-empty-list-message></app-empty-list-message>
</div>
