<div class="rel dash intro">
  <mat-dialog-content class="beta-dash">
    <div class="full-width beta-container" [attr.style]="'background:' + buildBackground() + ' !important' | safeStyle">
      <div class="full-width text-center mt">
        <div class="zoom-frame">
          <p class="image-icon">
            <mat-icon class="fas {{'fa-tooth' | translate}}"></mat-icon>
          </p>
        </div>
      </div>

      <div class="full-width text-center icon-scroll-container">
        <div class="icon-scroll">
          <div class="icon-wheel-button"></div>
          <div class="icon-wheel">
            <div class="icon-wheel-step step1"></div>

            <div class="icon-wheel-step step2"></div>

            <div class="icon-wheel-step step3"></div>
            <div class="icon-wheel-step step4"></div>
          </div>
          <div class="first-div"></div>
          <div class="second-div"></div>
          <div class="third-div"></div>
        </div>
      </div>

      <div class="full-width inner text-center desktop-title">
        <h3>Use your mouse wheel to <strong>Zoom In</strong> and <strong>Zoom Out</strong>.</h3>
      </div>

      <div class="full-width inner text-center mb mobile-title">
        <h4>Zoom in for closer image details.</h4>
      </div>
      <div class="full-width footer">
        <div class="full-width desktop-action">
          <mat-checkbox
            class="pull-left mt smb sml"
            [(ngModel)]="zoomInImage"
            matTooltip="Please don't show this message again."
          >
            <span>Please don't show this message again.</span>
          </mat-checkbox>

          <button class="pull-right smt smb mr btn" (click)="doNotShow()" mat-raised-button color="accent">
            Got it!
          </button>
        </div>

        <div class="full-width mobile-action text-center">
          <div class="full-wdith text-center mt">
            <mat-checkbox class="smb" [(ngModel)]="zoomInImage" matTooltip="Please don't show this message again.">
              <span>Please don't show this message again.</span>
            </mat-checkbox>
          </div>
          <div class="full-wdith text-center">
            <button class="smt smb btn" (click)="doNotShow()" mat-raised-button color="accent">Got it!</button>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
</div>
