<div class="allerg card">
  <div class="card-header primary-gradient-img clearfix inModal">
    <div class="row clearfix">
      <!-- Modal dismiss button -->
      <button class="btn-close btn-clear mat-button white" *ngIf="isModal == true" (click)="close()">
        <mat-icon>clear</mat-icon>
      </button>
      <div class="row titleArea clearfix">
        <h2 class="summary-header rm-m">
          <span *ngIf="isEdit == false">New Allergy</span>
          <span *ngIf="isEdit == true">Edit Allergy</span>
        </h2>
      </div>
    </div>
  </div>

  <form #allergyForm="ngForm">
    <mat-dialog-content>
      <div class="row clearfix flex mt" ngIfAnimation>
        <!-- select label -->
        <mat-form-field class="half-width mb" appearance="outline">
          <mat-label>Allergy</mat-label>
          <mat-select
            class=""
            [(ngModel)]="allergyObject.categoryCode"
            (selectionChange)="getAllergies(allergyObject.categoryCode)"
            name="category"
            placeholder="Please select Allergy"
            required
          >
            <mat-option *ngFor="let c of categories" [value]="c.Category">{{ c.DisplayLabel }}</mat-option>
          </mat-select>
        </mat-form-field>

        <!-- select type -->
        <mat-form-field class="half-width mb" *ngIf="allergies.length > 0" appearance="outline">
          <mat-label>Type</mat-label>
          <mat-select class="" [(ngModel)]="allergyObject.typeCode" name="type" placeholder="Type" required>
            <mat-option *ngFor="let c of allergies" [value]="c.Code">{{ c.Label }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- still an issue button toggle -->
      <div class="row clearfix">
        <h3 class="sr-title rm-mt">Still suffering?</h3>
        <mat-button-toggle-group
          class="divided-toggle animate full-width"
          [(ngModel)]="allergyObject.isOngoing"
          name="stillSuffer"
        >
          <mat-button-toggle class="btn-icon no" [value]="false" color="accent" ngDefaultControl>
            <mat-icon class="fas fa-thumbs-down"></mat-icon>
            No
          </mat-button-toggle>
          <mat-button-toggle class="btn-icon yes" [value]="true" color="primary" ngDefaultControl>
            <mat-icon class="fas fa-thumbs-up"></mat-icon>Yes
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>

      <!-- initial date picker -->
      <div class="full-width flex clearfix">
        <div class="row full-width clearfix" *ngIf="allergyObject.isOngoing != null" @ngIfAnimation>
          <app-date-month-input
            class="full-width"
            [defaultDays]="1"
            [defaultDate]="startDate"
            [startView]="'multi-year'"
            [isTime]="false"
            [maxDate]="'toDay'"
            [isClear]="true"
            (selectedDate)="selectDateToFilter($event, 'firstOccurDate')"
            label="When did it first start?"
          >
          </app-date-month-input>
        </div>
        <div class="row ml full-width clearfix" *ngIf="allergyObject.isOngoing == false" @ngIfAnimation>
          <app-date-month-input
            class="full-width"
            [defaultDays]="'last'"
            [defaultDate]="stopDate"
            [startView]="'multi-year'"
            [isTime]="false"
            [minDate]="allergyObject?.firstOccurDate"
            [maxDate]="'toDay'"
            [isClear]="true"
            (selectedDate)="selectDateToFilter($event, 'ceaseDate')"
            label="When did it finish?"
          >
          </app-date-month-input>
        </div>
      </div>

      <div class="full-width flex" *ngIf="allergyObject?.firstOccurDate">
        <h3 class="sr-title rm-mt animate full-width text-left">
          Occuring duration:

          <span *ngIf="allergyObject.isOngoing === false" [style.color]="'black'">
            {{ allergyObject?.firstOccurDate | timeLeft: allergyObject?.ceaseDate:'years,months':false }}
          </span>

          <span *ngIf="allergyObject.isOngoing !== false" [style.color]="'black'">
            {{ allergyObject?.firstOccurDate | timeLeft: null:'years,months':false }}
          </span>
        </h3>
      </div>

      <div class="row clearfix" *ngIf="allergyObject.isOngoing != null" @ngIfAnimation>
        <h3 class="sr-title rm-mt animate">More details (optional)</h3>
        <mat-form-field class="full-width animate" appearance="outline">
          <mat-label>Would you like to tell us anything else?</mat-label>
          <textarea
            class="mat-block"
            [(ngModel)]="allergyObject.description"
            matInput
            placeholder="Would you like to tell us anything else?"
            name="description"
          ></textarea>
        </mat-form-field>
      </div>

      <div class="full-width clearfix" [hidden]="medicationExist != true && allergyObject?.isOngoing != true">
        <app-medication-list
          *ngIf="isLoaded == true"
          [medications]="_medications"
          [title]="'Are you taking any medication for it?'"
          [canCreate]="true"
          [canEdit]="true"
          [allergyID]="allergyID"
          [allergyCode]="allergyObject?.categoryCode"
          [startDate]="allergyObject?.firstOccurDate"
          [stopDate]="allergyObject?.ceaseDate"
          [showStopDate]="!allergyObject?.isOngoing"
          [canAction]="false"
          [canYesNo]="true"
          (getNewMedications)="getNewMedications($event)"
          (getMedicationExist)="getMedicationExist($event)"
        ></app-medication-list>
      </div>
    </mat-dialog-content>
  </form>
  <!-- proceed bar -->
  <div class="row clearfix">
    <hr/>
    <button class="" (click)="close()" mat-raised-button color="primary">
      <mat-icon>block</mat-icon>
      Cancel
    </button>
    <button
      class="pull-right"
      *ngIf="isEdit == false"
      [disabled]="!allergyForm.form.valid"
      (click)="createAllergy()"
      mat-raised-button
      color="accent"
    >
      Save
      <mat-icon>check</mat-icon>
    </button>
    <button
      class="pull-right"
      *ngIf="isEdit == true"
      [disabled]="!allergyForm.form.valid"
      (click)="modifyAllergy()"
      mat-raised-button
      color="accent"
    >
      Update
      <mat-icon>check</mat-icon>
    </button>
  </div>
</div>
