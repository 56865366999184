<span *ngIf="isQuickInvitation != true">
  <span *ngIf="isSideMenu == false">
    <!-- normal invitations -->

    <button class="inline btn-new-invite gradient red" id="newTreatmentInvite"
      *ngIf="(isModuleEmergercyActive == true || isModuleTreatmentPlanActive == true) && isInviteButton == true  && context!='breeze' && context!='p4p'"
      [matMenuTriggerFor]="extendedInviteMenu" [class.btn-large]="btnLarge == true" (click)="displayMenu()"
      mat-raised-button color="accent">
      <mat-icon class="new-inv-ico" [class.sh]="!title">{{ icon }}</mat-icon>
      <mat-icon class="small" *ngIf="!title" [class.sh]="!title">play_arrow</mat-icon>
      <span *ngIf="title">{{ title }}</span>
    </button>



    <button class="inline btn-new-invite gradient red" id="newTreatmentInvite"
      *ngIf=" isInviteButton == true && (context=='p4p' ||  context=='breeze' ) && isModuleSMSOfferActive == true"
      [class.btn-large]="btnLarge == true" (click)="patientLookup()" mat-raised-button color="accent" appCustomTooltip
      [customToolTipContent]="getToolTip('instantFinance')" [customShowDelay]="0" [customHideDelay]="0"
      [customToolTipClass]="'additional-tooltip-class-header'" [customReplacement]="'bottom'"
      [customDisplay]="displayInfoToolTip && displayToolTip('instantFinance')" [customIndicatorPosition]="'left'">
      <mat-icon class="new-inv-ico fas  {{'fa-tooth' | translate}}" [class.sh]="!title"></mat-icon>

      <span *ngIf="title">{{ title }}</span>
    </button>


    <button class="inline btn-new-invite gradient yellow" id="newQuotationInvite"
      *ngIf="isModuleQuotebuilderActive == true && isQuoteButton == true" [matMenuTriggerFor]="extendedInviteQuoteMenu"
      [class.btn-large]="btnLarge == true" mat-raised-button color="accent"
      [matTooltip]="('PHRASE.treatment plan' | translate | titlecase) + ' Plan Builder'">
      <mat-icon class="fas fa-file-invoice-dollar" [class.sh]="!title"></mat-icon>
      <mat-icon class="small" *ngIf="!title" [class.sh]="!title">play_arrow</mat-icon>
      <span *ngIf="title">{{ title }}</span>
    </button>

    <!-- member invitations -->
    <button class="inline btn-new-invite gradient purple" id="newMemberInvite"
      *ngIf="isModuleMembershipActive == true && isMemberButton == true" [matMenuTriggerFor]="extendedMemberInviteMenu"
      [class.btn-large]="btnLarge == true" mat-raised-button color="accent">
      <mat-icon [class.sh]="!title">card_membership</mat-icon>
      <mat-icon class="small" *ngIf="!title" [class.sh]="!title">play_arrow</mat-icon>
      <span *ngIf="title">{{ title }}</span>
    </button>
  </span>

  <span *ngIf="isSideMenu == true">
    <!-- side menu invite button -->
    <button class="sideMenuButton"
      *ngIf="(isModuleEmergercyActive == true || isModuleTreatmentPlanActive == true) && isInviteButton == true"
      [matMenuTriggerFor]="extendedInviteMenu" (click)="displayMenu()" mat-button>
      <mat-icon>add</mat-icon> <strong> Invitation</strong>
    </button>

    <mat-divider
      *ngIf="(isModuleEmergercyActive == true || isModuleTreatmentPlanActive == true) && isInviteButton == true">
    </mat-divider>
    <!-- side menu quote button -->
    <button class="sideMenuButton" *ngIf="isModuleQuotebuilderActive == true && isQuoteButton == true"
      [matMenuTriggerFor]="extendedInviteQuoteMenu" mat-button>
      <mat-icon>add</mat-icon> Build {{ "PHRASE.treatment plan" | translate | titlecase}}
    </button>
    <mat-divider *ngIf="isModuleQuotebuilderActive == true && isQuoteButton == true"></mat-divider>
    <!-- side menu member button -->
    <button class="sideMenuButton" *ngIf="isModuleMembershipActive == true && isMemberButton == true"
      [matMenuTriggerFor]="extendedMemberInviteMenu" mat-button>
      <mat-icon>add</mat-icon> <strong> Invitation</strong>
    </button>
    <mat-divider *ngIf="isModuleMembershipActive == true && isMemberButton == true"></mat-divider>
  </span>

  <!-- normal invitation menu -->
  <mat-menu class="wideMenu navHeadMenu" #extendedInviteMenu="matMenu">
    <div *ngIf="isModuleTreatmentPlanActive == true || isModuleSMSOfferActive == true">
      <div *ngIf="isModuleSMSOfferActive == true">
        <button (click)="patientLookup()" appCustomTooltip [customToolTipContent]="getToolTip('instantFinance')"
          [customShowDelay]="0" [customHideDelay]="0" [customToolTipClass]="'additional-tooltip-class'"
          [customReplacement]="'bottom'" [customDisplay]="displayInfoToolTip && displayToolTip('instantFinance')"
          [customIndicatorPosition]="'left'" mat-menu-item>
          <strong>Instant Finance Offer</strong>
          <mat-icon class="fas fa-dollar-sign"></mat-icon>
        </button>
      </div>
      <mat-divider *ngIf="isModuleSMSOfferActive == true"></mat-divider>

      <div *ngFor="let p of financedProductGroup">
        <button (click)="newPatientInv(p)" appCustomTooltip [customToolTipContent]="p.ToolTip" [customShowDelay]="0"
          [customHideDelay]="0" [customToolTipClass]="'additional-tooltip-class'" [customReplacement]="'bottom'"
          [customDisplay]="displayInfoToolTip && p.HasToolTip" [customIndicatorPosition]="'left'" mat-menu-item>
          Treatment - <strong>{{ p['Group.Label'] }}</strong>
          <mat-icon class="position fas {{ p.Icon }} "></mat-icon>
        </button>
      </div>
      <mat-divider *ngIf="financedProductGroup && financedProductGroup.length>0"></mat-divider>
    </div>

    <mat-menu class="wideMenu navHeadMenu" #extendedMemberInviteMenu="matMenu">
      <div *ngIf="isModuleMembershipActive == true">
        <div *ngFor="let p of membershipProductGroup">
          <button (click)="newMembership(p)" mat-menu-item>
            <strong>{{ p['Group.Label'] }}</strong> Membership
            <mat-icon class="position fas {{ p.Icon }} "></mat-icon>
          </button>

        </div>
      </div>
    </mat-menu>

    <mat-divider *ngIf="membershipProductGroup && membershipProductGroup.length>0"></mat-divider>

    <button class="green" id="emergencyTreatmentInvite" appCustomTooltip
      [customToolTipContent]="getToolTip('healthHistory')" [customShowDelay]="0" [customHideDelay]="0"
      [customToolTipClass]="'additional-tooltip-class'" [customReplacement]="'bottom'"
      [customDisplay]="displayInfoToolTip && displayToolTip('healthHistory')" [customIndicatorPosition]="'left'"
      *ngIf="isModulePatientHistoryActive == true && healthHistoryActive == true" [routerLink]="[
        '/merchant/',
        { outlets: { page: ['medical-history', 'medical-history-invitation-redirection'] } }
      ]" mat-menu-item>
      <strong>Health History</strong>
      Invitation
      <mat-icon class="position fas fa-heartbeat"></mat-icon>
    </button>

    <mat-divider *ngIf="isModuleExperimentalFeaturesActive == true"></mat-divider>

    <button class="green" id="ProfileInvite" *ngIf="isModuleExperimentalFeaturesActive == true"
      [routerLink]="['/merchant/', { outlets: { page: ['invitation-communication-create', 'profile-update'] } }]"
      mat-menu-item>
      <strong>{{ "KEYWORD.patient" | translate | titlecase }}</strong> Invitation
      <mat-icon class="position">face</mat-icon>
    </button>
    <mat-divider *ngIf="isModuleAppointmentActive == true && isAppointmentDisabled != true"></mat-divider>
    <button class="green" id="ProfileInvites" *ngIf="isModuleAppointmentActive == true && isAppointmentDisabled != true"
      appCustomTooltip [customToolTipContent]="getToolTip('appointment')" [customShowDelay]="0" [customHideDelay]="0"
      [customToolTipClass]="'additional-tooltip-class'" [customReplacement]="'bottom'"
      [customDisplay]="displayInfoToolTip && displayToolTip('appointment')" [customIndicatorPosition]="'left'"
      [routerLink]="['/merchant/', { outlets: { page: ['appointment', 'appointment-create-merchant'] } }]"
      mat-menu-item>
      <strong>Appointment</strong> Invitation
      <mat-icon class="position">alarm_add</mat-icon>
    </button>

    <mat-divider *ngIf="currentPractice && currentPractice['Google_Review_Place_ID']"> </mat-divider>

    <button class="green" id="Google_Review_Place_ID" appCustomTooltip
      [customToolTipContent]="getToolTip('googleReview')" [customShowDelay]="0" [customHideDelay]="0"
      [customToolTipClass]="'additional-tooltip-class'" [customReplacement]="'bottom'"
      [customDisplay]="displayInfoToolTip && displayToolTip('googleReview')" [customIndicatorPosition]="'left'"
      *ngIf="currentPractice && currentPractice['Google_Review_Place_ID']"
      [routerLink]="['/merchant/', { outlets: { page: ['google-review-invitation-create'] } }]" mat-menu-item>
      <strong> Google Review </strong> Invitation
      <mat-icon class="position fas fa-star"></mat-icon>
    </button>
    <mat-divider></mat-divider>
  </mat-menu>

  <!--  Member invitation menu ONLY -->


  <!--  Member quote builder menu ONLY -->
  <mat-menu class="wideMenu navHeadMenu" #extendedInviteQuoteMenu="matMenu">
    <div *ngIf="isModuleQuotebuilderActive == true">
      <!-- <div *ngFor="let p of membershipProductGroup"> -->
      <button [routerLink]="['/merchant/', { outlets: { page: ['quotation-create'] } }]" mat-menu-item>
        <strong>General {{ "KEYWORD.practitioner" | translate | titlecase}}
          s</strong> {{ "PHRASE.treatment plan" | translate | titlecase}}
        <mat-icon class="position fas fa-hand-holding-usd"></mat-icon>
      </button>
      <mat-divider></mat-divider>
      <!-- </div> -->
    </div>
  </mat-menu>

  <!--  new nested menu for different merchant types -->
  <mat-menu class="wideMenu navHeadMenu hasSub" #rootMenu="matMenu">
    <button [matMenuTriggerFor]="subMenuGeneral" mat-menu-item>
      General &amp; Specialist {{ "KEYWORD.dentistry" | translate }}
      <mat-icon class="fas {{'fa-tooth' | translate}} first"></mat-icon>
    </button>
    <mat-divider></mat-divider>
    <button [matMenuTriggerFor]="subMenuOrtho" mat-menu-item>
      Orthodontics <mat-icon class="fas fa-teeth first"> </mat-icon>
    </button>
    <mat-divider></mat-divider>
    <button [matMenuTriggerFor]="subMenuPediat" mat-menu-item>
      Pediatrics <mat-icon class="fas fa-child first"> </mat-icon>
    </button>
  </mat-menu>

  <mat-menu class="wideMenu navHeadMenu" #subMenuGeneral="matMenu">
    <button *ngIf="isModuleAppointmentActive == true && isAppointmentDisabled != true" mat-menu-item>
      Appointment <mat-icon class="fas fa-calendar-plus"></mat-icon>
    </button>
    <mat-divider *ngIf="isModuleAppointmentActive == true && isAppointmentDisabled != true"></mat-divider>
    <button mat-menu-item> {{"KEYWORD.Treatment" | translate}} invitation <mat-icon class="fas fa-paper-plane">
      </mat-icon></button>
    <mat-divider></mat-divider>
    <button mat-menu-item>Welcome pack <mat-icon class="fas fa-award"></mat-icon></button>
  </mat-menu>

  <mat-menu class="wideMenu navHeadMenu" #subMenuOrtho="matMenu">
    <button *ngIf="isModuleAppointmentActive == true && isAppointmentDisabled != true" mat-menu-item>
      Appointment <mat-icon class="fas fa-calendar-plus"></mat-icon>
    </button>
    <mat-divider *ngIf="isModuleAppointmentActive == true && isAppointmentDisabled != true"></mat-divider>
    <button mat-menu-item> {{"KEYWORD.Treatment" | translate}} invitation <mat-icon class="fas fa-paper-plane">
      </mat-icon></button>
    <mat-divider></mat-divider>
    <button mat-menu-item>Welcome pack <mat-icon class="fas fa-award"></mat-icon></button>
  </mat-menu>

  <mat-menu class="wideMenu navHeadMenu" #subMenuPediat="matMenu">
    <button *ngIf="isModuleAppointmentActive == true && isAppointmentDisabled != true" mat-menu-item>
      Appointment <mat-icon class="fas fa-calendar-plus"></mat-icon>
    </button>
    <mat-divider *ngIf="isModuleAppointmentActive == true && isAppointmentDisabled != true"></mat-divider>
    <button mat-menu-item> {{"KEYWORD.Treatment" | translate}} invitation <mat-icon class="fas fa-paper-plane">
      </mat-icon></button>
    <mat-divider></mat-divider>
    <button mat-menu-item>Welcome pack <mat-icon class="fas fa-award"></mat-icon></button>
  </mat-menu>
</span>
<span *ngIf="isQuickInvitation == true">
  <span *ngIf="isSideMenu == false">
    <!-- normal invitations -->

    <button class="inline btn-new-invite gradient green" id="newTreatmentInviteApplication"
      [matMenuTriggerFor]="extendedInviteMenu2" [class.btn-large]="btnLarge == true" mat-raised-button color="accent">
      <mat-icon class="new-inv-ico sh smr fas fa-hands-helping"></mat-icon>
      <mat-icon class="small">play_arrow</mat-icon>
    </button>
  </span>
  <span *ngIf="isSideMenu == true">
    <!-- side menu invite button -->
    <button class="sideMenuButton"
      *ngIf="(isModuleEmergercyActive == true || isModuleTreatmentPlanActive == true) && isInviteButton == true"
      [matMenuTriggerFor]="extendedInviteMenu2" mat-button>
      <mat-icon>add</mat-icon> Apply Now
    </button>
  </span>

  <mat-menu class="wideMenu navHeadMenu" #extendedInviteMenu2="matMenu">
    <button class="green" id="ProfileInvite" (click)="applyFinance()" appCustomTooltip
      [customToolTipContent]="getToolTip('GPF')" [customShowDelay]="0" [customHideDelay]="0"
      [customToolTipClass]="'additional-tooltip-class-application'" [customReplacement]="'bottom'"
      [customDisplay]="displayInfoToolTip && displayToolTip('GPF')" [customIndicatorPosition]="'left'" mat-menu-item>
      Apply For <strong>Finance</strong>
      <mat-icon class="sml fas fa-money-check-alt"></mat-icon>
    </button>
    <mat-divider *ngIf="isInnerHouse == true"></mat-divider>
    <button class="green" id="ProfileInvites" *ngIf="isInnerHouse == true" (click)="applyInnerHouse()" mat-menu-item>
      Apply For <strong>In-House</strong>
      <mat-icon class="sml fas fa-search-dollar"></mat-icon>
    </button>

    <mat-divider *ngIf="isSmileClub == true  && context!='breeze' && context!='p4p' "></mat-divider>
    <button class="green" id="ProfileInvites" *ngIf="isSmileClub == true  && context!='breeze' && context!='p4p'" (click)="applyFinanceSmileClub()"
      appCustomTooltip [customToolTipContent]="getToolTip('MEM')" [customShowDelay]="0" [customHideDelay]="0"
      [customToolTipClass]="'additional-tooltip-class-application'" [customReplacement]="'bottom'"
      [customDisplay]="displayInfoToolTip && displayToolTip('MEM')" [customIndicatorPosition]="'left'" mat-menu-item>
      Apply For <strong>{{'Smile Club' | translate}}</strong>
      <mat-icon class="sml fas fa-hospital-user"></mat-icon>
    </button>


    <mat-divider *ngIf="isSmileClub == true  && context=='breeze' "></mat-divider>
    <button class="green" id="ProfileInvites" *ngIf="isSmileClub == true  &&  context=='breeze'" (click)="applyFinanceSmileClub()"
      appCustomTooltip [customToolTipContent]="getToolTip('MEM')" [customShowDelay]="0" [customHideDelay]="0"
      [customToolTipClass]="'additional-tooltip-class-application'" [customReplacement]="'bottom'"
      [customDisplay]="displayInfoToolTip && displayToolTip('MEM')" [customIndicatorPosition]="'left'" mat-menu-item>
      Apply For <strong>Breeze Living Club</strong>
      <mat-icon class="sml fas fa-hospital-user"></mat-icon>
    </button>


    <mat-divider *ngIf="isSmileClub == true  && context=='p4p' "></mat-divider>
    <button class="green" id="ProfileInvites" *ngIf="isSmileClub == true  &&  context=='p4p'" (click)="applyFinanceSmileClub()"
      appCustomTooltip [customToolTipContent]="getToolTip('MEM')" [customShowDelay]="0" [customHideDelay]="0"
      [customToolTipClass]="'additional-tooltip-class-application'" [customReplacement]="'bottom'"
      [customDisplay]="displayInfoToolTip && displayToolTip('MEM')" [customIndicatorPosition]="'left'" mat-menu-item>
      Apply For <strong>Pay For Paws Club</strong>
      <mat-icon class="sml fas fa-hospital-user"></mat-icon>
    </button>
  </mat-menu>
</span>
