import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output, SimpleChanges } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import * as _ from 'lodash';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { TagsService } from '../shared/tags.service';
import { TagCreateEditComponent } from '../tag-create-edit/tag-create-edit.component';

@Component({
  selector: 'app-tag-select',
  templateUrl: './tag-select.component.html',
  styleUrls: ['./tag-select.component.css'],
})
export class TagSelectComponent implements OnInit {
  nature = 'Tag';

  @Input()
  openPosition = 'above';
  @Input()
  canRemove = true;

  @Input()
  canCreate = true;

  @Input()
  canSelect = false;

  @Input()
  hasFilter = true;

  @Input()
  directRemove = true;

  @Input()
  directRemoveDefault = true;

  @Input()
  removeTag = new EventEmitter();

  @Input()
  tags = [];

  @Input()
  onlyGreyTags = true;

  @Input()
  merchantID;

  @Input()
  staticAudience = false;

  @Input()
  tagFormatIsObject = false;

  @Input()
  selectedTags;

  @Input()
  defaultSelectedTags;

  addTag = new EventEmitter();

  @Output()
  getSelectedTags = new EventEmitter();
  @Output()
  getResult = new EventEmitter();

  @Input()
  selectTitle = 'Attach tags to this template';

  @Input()
  title;

  @Input()
  description;

  loadTag = false;
  @Input()
  isModal = true;

  close = new EventEmitter();

  sessionType;
  isPromoterOrAdmin = false;

  @Output()
  getSelectedTag = new EventEmitter();
  @Output()
  getRemovedTag = new EventEmitter();
  @Output()
  getRemovedAddedTag = new EventEmitter();

  @Output()
  getCreate = new EventEmitter();

  constructor(
    private tagsService: TagsService,
    private authenticationService: AuthenticationService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.canRemove != null) {
        this.canRemove = data.canRemove;
        this.isModal = true;
      }
      if (data.description != null) {
        this.description = data.description;
        this.isModal = true;
      }
      if (data.title != null) {
        this.title = data.title;
        this.isModal = true;
      }
      if (data.selectTitle != null) {
        this.selectTitle = data.selectTitle;
        this.isModal = true;
      }

      if (data.canCreate != null) {
        this.canCreate = data.canCreate;
        this.isModal = true;
      }
      if (data.merchantID != null) {
        this.merchantID = data.merchantID;
        this.isModal = true;
      }

      if (data.canSelect != null) {
        this.canSelect = data.canSelect;
        this.isModal = true;
      }
      if (data.directRemove != null) {
        this.directRemove = data.directRemove;
        this.isModal = true;
      }
      if (data.directRemoveDefault != null) {
        this.directRemoveDefault = data.directRemoveDefault;
        this.isModal = true;
      }

      if (data.onlyGreyTags != null) {
        this.onlyGreyTags = data.onlyGreyTags;
        this.isModal = true;
      }
      if (data.staticAudience != null) {
        this.staticAudience = data.staticAudience;
        this.isModal = true;
      }
      if (data.tagFormatIsObject != null) {
        this.tagFormatIsObject = data.tagFormatIsObject;
        this.isModal = true;
      }
      if (data.defaultSelectedTags != null) {
        this.defaultSelectedTags = data.defaultSelectedTags;
        this.isModal = true;
      }
      if (data.selectedTags != null) {
        this.selectedTags = data.selectedTags;
        this.isModal = true;
      }

      if (data.isModal != null) {
        this.isModal = data.isModal;
      }
    }
  }

  ngOnInit() {
    this.authenticationService.isPromoterOrAdmin().subscribe((isPromoterOrAdmin) => {
      this.isPromoterOrAdmin = isPromoterOrAdmin;
      this.authenticationService.getSessionType().subscribe((sessionType) => {
        this.sessionType = sessionType;

        this.setup();
      });
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      (changes.merchantID &&
        changes.merchantID !== undefined &&
        changes.merchantID.previousValue !== changes.merchantID.currentValue) ||
      (changes.staticAudience !== null &&
        changes.staticAudience !== undefined &&
        changes.staticAudience.previousValue !== changes.staticAudience.currentValue)
    ) {
      this.authenticationService.getSessionType().subscribe((sessionType) => {
        this.sessionType = sessionType;

        this.setup();
      });
    }
  }

  setup() {
    this.loadTag = false;
    if (this.staticAudience === true) {
      this.nature = 'Static Audience';
    } else {
      this.nature = 'Tag';
    }

    const payload = {
      merchantID: this.merchantID,
      staticAudience: this.staticAudience,
      fields: 'Label',
      limit: 1000,
    };

    this.tagsService.getTagsList(payload, this.sessionType).subscribe((res) => {
      if (res) {
        this.tags = _.map(res, (item) => {
          return item.Label;
        });

        this.loadTag = true;
      }
    });
  }

  closeEvent() {
    this.close.emit(true);
  }

  createNewTag() {
    const ref = RootAppComponent.dialog.open(TagCreateEditComponent, {
      data: {
        staticAudience: this.staticAudience,
        merchantID: this.merchantID,
      },

      width: '600px',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });

    ref.componentInstance.getResult.subscribe((res) => {
      if (res && res.Label) {
        if (
          (!this.tags || (this.tags && this.tags.length <= 0)) &&
          (!this.defaultSelectedTags || (this.defaultSelectedTags && this.defaultSelectedTags.length <= 0))
        ) {
          this.defaultSelectedTags = JSON.parse(JSON.stringify([res.Label]));
          this.tags = JSON.parse(JSON.stringify([res.Label]));

          if (!this.selectedTags || (this.selectedTags && this.selectedTags.length <= 0)) {
            this.selectedTags = [res.Label];
          } else {
            this.selectedTags.push(res.Label);
          }

          this.sendResult();
        } else {
          if (!this.tags || (this.tags && this.tags.length <= 0)) {
            this.tags.push(res);
          }
          this.addTag.emit(res);
        }
        ref.close();
      }
    });
  }

  getSelectedTagsEvent(e) {
    this.defaultSelectedTags = e || [];
    this.selectedTags = e || [];
    this.sendResult();
  }

  sendResult() {
    this.getSelectedTags.emit(this.selectedTags || []);
  }

  sendResultEvent() {
    this.getResult.emit(this.selectedTags || []);
  }

  getSelectedTagEvent(e) {
    this.getSelectedTag.emit(e);
  }

  getRemovedTagEvent(e) {
    this.getRemovedTag.emit(e);
  }

  getRemovedAddedTagEvent(e) {
    this.getRemovedAddedTag.emit(e);
  }

  getCreateEvent(e) {
    this.getCreate.emit(e);
  }
}
