<div class="container" *ngIf="operator">
  <div class="card-header primary-gradient-img clearfix stacked">
    <div class="row clearfix">
      <button class="btn-close btn-clear mat-button white" (click)="closeEvent()">
        <mat-icon>clear</mat-icon>
      </button>
      <div class="row titleArea clearfix">
        <app-operator-view-picture class="main-profile" [operatorID]="operatorID"></app-operator-view-picture>
        <h2 class="summary-header rm-m">Contact {{ operator['CalculatedName'] }}</h2>
      </div>
    </div>
  </div>

  <form class="row clearfix" #contactForm="ngForm">
    <div class="row clearfix">
      <div class="full-width">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Email</mat-label>
          <input class="mat-block" [(ngModel)]="operator['emails.Email']" [disabled]="true" name="email" matInput/>
        </mat-form-field>

        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Name</mat-label>
          <input
            class="mat-block"
            [(ngModel)]="operator.CalculatedName"
            [disabled]="true"
            name="name"
            matInput
            placeholder="Name"
          />
        </mat-form-field>
      </div>
      <div class="full-width">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>What is your enquiry about?</mat-label>
          <input
            class="mat-block"
            [(ngModel)]="contact.subject"
            name="subject"
            matInput
            placeholder="What is your enquiry about?"
            required
          />
        </mat-form-field>

        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Your Message</mat-label>
          <textarea
            class="messageArea"
            [(ngModel)]="contact.body"
            name="message"
            matInput
            placeholder="Your Message"
            required
          ></textarea>
        </mat-form-field>
      </div>
    </div>

    <div class="full-width text-center step-button-row">
      <button
        class="btn-large contact-us"
        [disabled]="!contactForm.form.valid"
        (click)="contactOperator()"
        mat-raised-button
        type="submit"
        color="accent"
      >
        <mat-icon>email</mat-icon>Send message
      </button>
    </div>
  </form>
</div>
