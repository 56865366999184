import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'like-animation',
  templateUrl: './like-animation.component.html',
  styleUrls: ['./like-animation.component.css'],
})
export class LikeAnimationComponent implements OnInit {
  @Input()
  type = '';

  @Input()
  anim = false;

  constructor() {}

  ngOnInit() {}
}
