import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Merchant } from '../../../../merchant/shared/types/merchant.type';
import { ProductCustomer } from '../../../shared/services/product-customer.service';

export interface CustomerProductFormModalConfig {
  industryType: string;
  formMode?: 'create' | 'update';
  productCustomer?: ProductCustomer;
  practice: Partial<Merchant>;
}

@Component({
  selector: 'ipv-customer-product-form.modal',
  templateUrl: './customer-product-form.modal.ts.component.html',
  styleUrls: ['./customer-product-form.modal.ts.component.css'],
})
export class CustomerProductFormModalComponent {
  title = 'New Serialised Product';
  formMode: 'create' | 'update' = 'create';
  industryType = 'dental';
  productCustomer: Partial<ProductCustomer> | ProductCustomer = null;
  practice: Partial<Merchant> = null;

  constructor(@Inject(MAT_DIALOG_DATA) public data: CustomerProductFormModalConfig) {
    if (data) {
      this.formMode = data.formMode || 'create';
      this.title = data.formMode === 'update' ? 'Update Serialised Product' : this.title;
      this.industryType = data.industryType;
      this.practice = data.practice;
      this.productCustomer = data.productCustomer || null;

      if (this.formMode === 'update') {
        this.title = 'Update Serialised Product';
      }
    }
  }
}
