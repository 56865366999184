<div class="container text-center">
  <form #createForm="ngForm">
    <h2 class="sr-title">Add Language</h2>

    <div class="row clearfix">
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>Label </mat-label>
        <input [(ngModel)]="label" name="label" matInput placeholder="Label" required/>
      </mat-form-field>
    </div>

    <div class="row clearfix">
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>code (no space) </mat-label>
        <input [(ngModel)]="code" name="code" matInput placeholder="code (no space)" required/>
      </mat-form-field>
    </div>

    <div class="clearfix full-width text-center">
      <button class="btn-large" [disabled]="!createForm.form.valid" (click)="create()" mat-raised-button color="accent">
        <mat-icon>add</mat-icon>Add
      </button>
    </div>
  </form>
</div>
