import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-two-factor-input',
  templateUrl: './two-factor-input.component.html',
  styleUrls: ['./two-factor-input.component.css'],
})
export class TwoFactorInputComponent implements OnInit {
  counterFinished;

  constructor() {}

  ngOnInit() {}
}
