import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { combineLatest, EMPTY, of } from 'rxjs';
import { debounceTime, expand, map, switchMap, tap } from 'rxjs/operators';
import { AppStateService } from '../../../../../core/app-state/app-state.service';
import { AppState } from '../../../../../core/app-state/app-state.type';
import { STANDARD_KEYBOARD_DEBOUNCE_TIME } from '../../../../../shared/config/STANDARD_KEYBOARD_DEBOUNCE_TIME';
import { UtilsClass } from '../../../../../shared/types/utils/utils.class';
import { Merchant } from '../../../../merchant/shared/types/merchant.type';
import {
  ProductCustomer,
  ProductCustomerQuery,
  ProductCustomerService,
} from '../../../shared/services/product-customer.service';
import {
  CustomerProductFormModalComponent,
  CustomerProductFormModalConfig,
} from '../../modals/customer-product-form.modal/customer-product-form.modal.ts.component';
import {
  ViewCustomerProductModalComponent,
  ViewCustomerProductModalConfig,
} from '../../modals/view-customer-product.modal/view-customer-product.modal';

@Component({
  selector: 'ipv-retail-product-customer-list',
  templateUrl: './retail-product-customer-list.component.html',
  styleUrls: ['./retail-product-customer-list.component.css'],
})
export class RetailProductCustomerListComponent {
  isFetchingInitialData = true;

  hiddenColumns = [
    'ID',
    'Invoice_key',
    'DateTimeCreated',
    'LastModified',
    'LastModified_Human',
    'Owner_Card_key',
    'Master_Appliance_Profile_key',
    'CostPrice',
    'TaxRate',
    'SellPrice',
    'Quantity',
    'Discount_Amount',
    'Notes',
    'Merchant_key',
  ];

  renamedColumns = {
    CustomerCalculatedName: 'Name',
    CustomerMobile: 'Mobile',
    CustomerEmail: 'Email',
    ProductLabel: 'Product',
    PurchaseDate: 'Purchase Date',
    SerialNumber: 'Serial Number',
  };

  order = [
    'CustomerCalculatedName',
    'CustomerMobile',
    'CustomerEmail',
    'ProductLabel',
    'SerialNumber',
    'SellPrice',
    'PurchaseDate',
  ];

  viewModel$ = this.appStateService.getAppState$().pipe(
    switchMap((appState) =>
      this.getFilters$().pipe(
        switchMap(([calculatedNameFilter, emailFilter, productLabelFilter]) =>
          this.fetchData$({
            payload: {
              Merchant_key: appState.user.data.practiceKey,
              calculatedNameFilter,
              emailFilter,
              productLabelFilter,
            },
          }).pipe(
            switchMap(() => this.getData$({ calculatedNameFilter, productLabelFilter, emailFilter })),
            switchMap(([productCustomers]) =>
              this.getViewModel$({
                appState,
                productCustomers,
                calculatedNameFilter,
                emailFilter,
                productLabelFilter,
              })
            )
          )
        )
      )
    )
  );

  constructor(
    private dialog: MatDialog,
    private appStateService: AppStateService,
    private productCustomerService: ProductCustomerService
  ) {}

  getFilters$() {
    return combineLatest([
      this.getCustomerCalculatedNameFilter$(),
      this.getCustomerEmailFilter$(),
      this.getCustomerProductLabelFilter$(),
    ]);
  }

  fetchData$(parameters: ProductCustomerQuery) {
    return combineLatest([this.fetchProductCustomerSections$(parameters)]);
  }

  getCustomerCalculatedNameFilter$() {
    return this.productCustomerService
      .getCustomerCalculatedNameFilter$()
      .pipe(debounceTime(STANDARD_KEYBOARD_DEBOUNCE_TIME));
  }

  setCustomerCalculatedNameFilter(customerFirstName: string) {
    this.productCustomerService.setCustomerCalculatedNameFilter(customerFirstName);
  }

  getCustomerEmailFilter$() {
    return this.productCustomerService.getCustomerEmailFilter$().pipe(debounceTime(STANDARD_KEYBOARD_DEBOUNCE_TIME));
  }

  setCustomerEmailFilter(customerEmailFilter: string) {
    this.productCustomerService.setCustomerEmailFilter(customerEmailFilter);
  }

  getCustomerProductLabelFilter$() {
    return this.productCustomerService
      .getCustomerProductLabelFilter$()
      .pipe(debounceTime(STANDARD_KEYBOARD_DEBOUNCE_TIME));
  }

  setCustomerProductLabelFilter(productLabel: string) {
    this.productCustomerService.setCustomerProductLabelFilter(productLabel);
  }

  fetchProductCustomerSections$(parameters: ProductCustomerQuery) {
    this.productCustomerService.setProductCustomers([]);

    const query: ProductCustomerQuery = {
      payload: Object.assign(
        {
          section: 0,
        },
        parameters.payload
      ),
    };

    return this.productCustomerService.fetchProductCustomers$<Partial<ProductCustomer>>(query.payload).pipe(
      tap(() => {
        UtilsClass.stopLoadingInterceptor();
        query.payload.section++;
        UtilsClass.loadingDataSection(query.payload.section);
      }),
      expand((productCustomerSection) =>
        productCustomerSection.length > 0
          ? this.productCustomerService.fetchProductCustomers$<Partial<ProductCustomer>>(query.payload).pipe(
              tap(() => {
                UtilsClass.stopLoadingInterceptor();
              })
            )
          : EMPTY
      ),
      tap((productCustomerSection) => {
        if (productCustomerSection.length > 0) {
          query.payload.section++;
          UtilsClass.loadingDataSection(query.payload.section);
          const items = this.productCustomerService.getProductCustomers();
          const newItems = items.concat(productCustomerSection);
          this.productCustomerService.setProductCustomers(newItems);
        }
      })
    );
  }

  getData$(parameters: { calculatedNameFilter; emailFilter; productLabelFilter }) {
    return combineLatest([
      this.productCustomerService.getProductCustomers$().pipe(
        map((productCustomers) => {
          return productCustomers
            .filter((productCustomer) => {
              return productCustomer.CustomerCalculatedName.includes(parameters.calculatedNameFilter);
            })
            .filter((productCustomer) => {
              return productCustomer.CustomerEmail.includes(parameters.emailFilter);
            })
            .filter((productCustomer) => {
              return productCustomer.ProductLabel.includes(parameters.productLabelFilter);
            });
        })
      ),
    ]);
  }

  getViewModel$(parameters: {
    appState: AppState;
    productCustomers: Partial<ProductCustomer>[];
    calculatedNameFilter;
    emailFilter;
    productLabelFilter;
  }) {
    const { appState, calculatedNameFilter, emailFilter, productLabelFilter } = parameters;
    let { productCustomers } = parameters;
    this.isFetchingInitialData = false;
    const { user, practice, sessionType, isPromoterOrAdmin, dentist } = appState;

    productCustomers = [
      {
        ID: '',
        DateTimeCreated: '',
        LastModified: '',
        LastModified_Human: '',
        Owner_Card_key: 'ECEB868868766742AE0BD4B988C04CFA',
        Master_Appliance_Profile_key: '',
        SellPrice: 0,
        CostPrice: 0,
        TaxRate: 0,
        Quantity: '1',
        Discount_Amount: 0,
        Notes: '',
        Invoice_key: '',
        Merchant_key: '',
        PurchaseDate: '2021-10-07 13:48:14',
        SerialNumber: '',
        CustomerCalculatedName: 'Sms Hadil',
        CustomerEmail: 'hadil@ipventures.com.au',
        CustomerMobile: '0061410389285',
        ProductLabel: 'Product Label',
      },
    ];

    console.log(productCustomers);

    const result = {
      user,
      practice,
      sessionType,
      isPromoterOrAdmin,
      dentist,
      productCustomers,
      calculatedNameFilter,
      emailFilter,
      productLabelFilter,
      industryType: appState.publicSettings.industryType,
    };
    return of(result);
  }

  viewProduct(productCustomer: ProductCustomer, industryType: string, practice: Merchant) {
    const ref = this.dialog.open<ViewCustomerProductModalComponent, ViewCustomerProductModalConfig>(
      ViewCustomerProductModalComponent,
      {
        data: {
          productCustomer,
          industryType,
          practice,
        },
        width: '800px',
      }
    );
  }

  updateProduct(productCustomer: ProductCustomer, industryType: string, practice: Partial<Merchant>) {
    const ref = this.dialog.open<CustomerProductFormModalComponent, CustomerProductFormModalConfig>(
      CustomerProductFormModalComponent,
      {
        data: {
          productCustomer,
          industryType,
          practice,
          formMode: 'update',
        },
        width: '800px',
      }
    );
  }

  add(industryType: string, practice: Partial<Merchant>) {
    const ref = this.dialog.open<CustomerProductFormModalComponent, CustomerProductFormModalConfig>(
      CustomerProductFormModalComponent,
      {
        data: { industryType, practice },
        width: '800px',
      }
    );
  }
}
