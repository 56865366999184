/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./customer-summary.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i4 from "@angular/material/icon";
import * as i5 from "../customer-view/customer-view.component.ngfactory";
import * as i6 from "../customer-view/customer-view.component";
import * as i7 from "@angular/common";
import * as i8 from "../shared/customer.service";
import * as i9 from "@angular/router";
import * as i10 from "../../../core/authentication/shared/authentication.service";
import * as i11 from "../../customer-prospect/shared/customerProspect.service";
import * as i12 from "../../../shared/services/utils.service";
import * as i13 from "../../product/shared/product.service";
import * as i14 from "./customer-summary.component";
var styles_CustomerSummaryComponent = [i0.styles];
var RenderType_CustomerSummaryComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CustomerSummaryComponent, data: {} });
export { RenderType_CustomerSummaryComponent as RenderType_CustomerSummaryComponent };
export function View_CustomerSummaryComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "rel"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "float-close"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "button", [["class", "btn-close btn-clear mat-button"], ["matDialogClose", ""]], [[1, "aria-label", 0], [1, "type", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).dialogRef.close(i1.ɵnov(_v, 3).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { _matDialogClose: [0, "_matDialogClose"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i3.View_MatIcon_0, i3.RenderType_MatIcon)), i1.ɵdid(5, 9158656, null, 0, i4.MatIcon, [i1.ElementRef, i4.MatIconRegistry, [8, null], [2, i4.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["clear"])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "app-customer-view", [], null, null, null, i5.View_CustomerViewComponent_0, i5.RenderType_CustomerViewComponent)), i1.ɵdid(8, 638976, null, 0, i6.CustomerViewComponent, [i7.Location, i8.CustomerService, i9.ActivatedRoute, i10.AuthenticationService, i11.CustomerProspectService, i9.Router, i12.UtilsService, i13.ProductService, i2.MatDialog, [2, i2.MAT_DIALOG_DATA]], { customerID: [0, "customerID"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = ""; _ck(_v, 3, 0, currVal_2); _ck(_v, 5, 0); var currVal_5 = _co.customerID; _ck(_v, 8, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 3).ariaLabel || null); var currVal_1 = i1.ɵnov(_v, 3).type; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵnov(_v, 5).inline; var currVal_4 = (((i1.ɵnov(_v, 5).color !== "primary") && (i1.ɵnov(_v, 5).color !== "accent")) && (i1.ɵnov(_v, 5).color !== "warn")); _ck(_v, 4, 0, currVal_3, currVal_4); }); }
export function View_CustomerSummaryComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-customer-summary", [], null, null, null, View_CustomerSummaryComponent_0, RenderType_CustomerSummaryComponent)), i1.ɵdid(1, 114688, null, 0, i14.CustomerSummaryComponent, [[2, i2.MAT_DIALOG_DATA]], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomerSummaryComponentNgFactory = i1.ɵccf("app-customer-summary", i14.CustomerSummaryComponent, View_CustomerSummaryComponent_Host_0, { customerID: "customerID" }, {}, []);
export { CustomerSummaryComponentNgFactory as CustomerSummaryComponentNgFactory };
