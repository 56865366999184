<div class="row clearfix defineMessage" *ngIf="patient">
  <!-- blue heading -->
  <div class="card-header primary-gradient-img clearfix" *ngIf="isModal == true">
    <div class="row clearfix">
      <!-- Modal dismiss button -->
      <button class="btn-close btn-clear pull-right mat-button" (click)="closeEvent()">
        <mat-icon>clear</mat-icon>
      </button>
      <!-- merchant calculated name -->

      <div class="row titleArea clearfix">
        <h2 class="summary-header rm-m">
          <span>Add new Document for : {{ patient.FirstName }} {{ patient.Name }}</span>
        </h2>
      </div>
    </div>
  </div>
  <!-- end blue heading -->

  <div class="create-well">
    <form #msgForm="ngForm">
      <!--  mat dialog scrolling area -->
      <mat-dialog-content>
        <div class="full-width clearfix mt">
          <mat-form-field
            class="full-width clearfix"
            *ngIf="documentTypes && documentTypes.length > 0 && displayDocumentType == true"
            appearance="outline"
          >
            <mat-label>Document Type</mat-label>
            <mat-select
              class=" "
              [(ngModel)]="documentType"
              (ngModelChange)="changeDocumentType()"
              name="DocumentType"
              placeholder="Document Type"
              required
            >
              <mat-option *ngFor="let type of documentTypes | orderBy: ['label']" [value]="type['TypeCode']">
                {{ getLabelByCode(type.TypeCode) | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="full-width clearfix" appearance="outline">
            <mat-label>Label</mat-label>
            <input
              class="mat-block"
              [(ngModel)]="label"
              matInput
              name="label"
              placeholder="Label"
              required
            />
          </mat-form-field>

          <mat-form-field class="full-width clearfix" appearance="outline">
            <mat-label>Description</mat-label>
            <textarea
              class="dentMsg increase-input-field"
              [(ngModel)]="description"
              name="groupDescription"
              matInput
              placeholder="Description"
            ></textarea>
          </mat-form-field>

          <div class="clearfix">
            <!--  Image Upload -->
            <div class="full-width">
              <div class="row clearfix">
                <app-file-uploader
                  class="text-center clearfix full-width"
                  [allowedExtensionTypes]="allowedExtensionTypes"
                  [documentType]="documenType"
                  [fileDescription]="documentDescription"
                  [fileName]="label"
                  [isFixedAspectRatio]="false"
                  [isImageCropping]="true"
                  [canSkip]="false"
                  [limit]="1"
                  [uploadDirectly]="true"
                  [isDragable]="true"
                  [title]="'Drag and Drop or Click to upload file'"
                  (onCompleteAll)="onCompleteAll($event)"
                >
                </app-file-uploader>
              </div>
              <div class="row clearfix" *ngIf="fileID">
                <app-file-list
                  class=""
                  [showInfo]="false"
                  [files]="[fileID]"
                  [canPrivateDocument]="false"
                  [documentTypes]="[]"
                  [canGallery]="false"
                  [hasExtraInfo]="false"
                  [invitationID]="''"
                  [canEdit]="false"
                  [canAction]="false"
                >
                </app-file-list>
              </div>
            </div>
          </div>
        </div>
      </mat-dialog-content>
      <mat-dialog-actions>
        <div class="row clearfix">
          <button
            class="pull-right mr ml"
            [disabled]="!msgForm.form.valid || !fileID"
            (click)="save()"
            mat-raised-button
            color="accent"
          >
            Save
            <mat-icon>check</mat-icon>
          </button>

          <button class="pull-right" (click)="closeEvent()" mat-raised-button color="primary">Close</button>
        </div>
      </mat-dialog-actions>
    </form>
  </div>
</div>
