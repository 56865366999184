<div class="rel dash intro" *ngIf="subscriptionPlan && subscriptionPlan.ID">
  <div class="full-width text-center">
    <!--
    <div class="sub-plan-recommend-container " *ngIf="subscriptionPlan['Recommended_Plan'] == 'Yes'">
      <h3 class="summary-header rm-m">
        Recommended
      </h3>
    </div> -->
    <div class="full-width cover-picture-sub-plan first">
      <div [hidden]="true">
        <img [src]="postCover" (error)="onError()" (load)="onLoad()"/>
      </div>

      <div
        class="sub-plan-view-header"
        [ngClass]="{ 'default-background-image': isImageError != false }"
        [style.background-image]="postCoverURL"
      ></div>
    </div>

    <div class="image-thumbnail-sub-plan-modal">
      <app-ep-document-view-image
        class="square-img-sub-plan-modal image-sub-plan-modal"
        [hideZoomButton]="false"
        [defaultPicture]="
          'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/light_icon2.png)'
        "
        [link]="postPictureThumbnail"
      >
      </app-ep-document-view-image>
    </div>
  </div>

  <mat-dialog-content id="sub-plan-dialog-content">
    <div class="full-width flex">
      <div class="full-width ml main-container">
        <div class="full-width">
          <h1 class="main-title text-left" [attr.style]="'color:' + color + ' !important' | safeStyle">
            {{ subscriptionPlan.Web_Label }}

            <mat-icon class="fas fa-medal recommended-icon" *ngIf="isRecommended == true" matTooltip="Recommended">
            </mat-icon>
          </h1>
          <div class="full-width title-header-div">
            <p class="text-left" *ngIf="webDescription">
              {{ webDescription }}
            </p>
          </div>
        </div>
        <div class="full-width">
          <div
            class="pointer-shape pull-right"
            [attr.style]="
              'background:linear-gradient(135deg, ' + colorSecond + ' 0%, ' + color + ' 100%) !important' | safeStyle
            "
            [attr.style]="
              'background:-moz-linear-gradient(135deg, ' + colorSecond + ' 0%, ' + color + ' 100%) !important'
                | safeStyle
            "
            [attr.style]="
              'background:-webkit-linear-gradient(135deg, ' + colorSecond + ' 0%, ' + color + ' 100%) !important'
                | safeStyle
            "
          >
            <div class="full-width">
              <p class="text-center rm-m price-card-modal price-card pull-right">
                {{ price || 0 | customCurrency: '1.0-3' }}
              </p>
            </div>
            <div class="full-width">
              <p class="text-center rm-m price-card-modal price-card-sub pull-right">per month*</p>
            </div>
          </div>
        </div>

        <h3 class="rm-mt text-left">What do you get?</h3>

        <div class="full-width more-details-sub-plan flex">
          <div class="full-width mb">
            <!-- <p class="rm-m mt text-left" *ngIf="minTreatmentPlanMerchant==null">
              <mat-icon class="fas {{'fa-tooth' | translate}} enable-icon-module " [style.color]='color'></mat-icon>
              Min {{ "KEYWORD.treatment" | translate }}s: <strong>{{minTreatmentPlan}}/{{ "KEYWORD.practitioner" | translate | titlecase }}</strong>
              <mat-icon class="fas fa-info  ml info-icon-module " matTooltip="What is this?" [style.color]='color'
                (click)="moreMinTreatmentDetails()"></mat-icon>
            </p>

            <p class="rm-m mt text-left" *ngIf="minTreatmentPlanMerchant!=null">
              <mat-icon class="fas {{'fa-tooth' | translate}} enable-icon-module " [style.color]='color'></mat-icon>
              Min {{ "KEYWORD.treatment" | translate }}s: <strong>{{minTreatmentPlanMerchant}}</strong>
              <mat-icon class="fas fa-info  ml info-icon-module " matTooltip="What is this?" [style.color]='color'
                (click)="moreMinTreatmentDetails()"></mat-icon>
            </p>



            <p class="rm-m text-left">
              <mat-icon class="fas fa-hand-sparkles enable-icon-module " [style.color]='color'></mat-icon>
              Waived period: <strong>{{waivedPeriod}} Day(s)</strong>

              <mat-icon class="fas fa-info  ml info-icon-module " matTooltip="What is this?" [style.color]='color'
                (click)="moreWaivedDetails()"></mat-icon>
            </p>

            <p class="rm-mb smt  text-left" *ngIf="isFreeSMS==true  ">
              <mat-icon class="fas fa-mobile-alt enable-icon-module " [style.color]='color'></mat-icon>
              <strong> Free SMS</strong>
            </p>
            <p class="rm-mb smt  text-left" *ngIf="isFreeSMS!=true  ">
              <mat-icon class="fas fa-mobile-alt enable-icon-module " [style.color]='color'></mat-icon>
              Messages: for <strong>{{(smsPrice || 0)| customCurrency }}/SMS</strong>
            </p> -->

            <div *ngIf="marketingDescriptions && marketingDescriptions.length > 0">
              <p class="rm-mb smt text-left" *ngFor="let s of marketingDescriptions">
                <mat-icon class="fas fa-check-circle enable-icon-module" [style.color]="color"></mat-icon>
                {{ s }}
              </p>
            </div>
          </div>

          <div class="full-width cover-picture-sub-plan second">
            <div [hidden]="true">
              <img [src]="postCover" (error)="onError()" (load)="onLoad()"/>
            </div>

            <div
              class="sub-plan-view-header"
              [ngClass]="{ 'default-background-image': isImageError != false }"
              [style.background-image]="postCoverURL"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <div class="drop-button text-center full-width desktop-view mt">
    <button
      class="pull-left smt action-card-button"
      [attr.style]="'background:' + color + ' !important' | safeStyle"
      (click)="closeEvent()"
      mat-raised-button
      color="primary"
    >
      Close
    </button>

    <!-- <button mat-raised-button color="accent" [attr.style]="('background:' +color + ' !important') | safeStyle"
      class=" pull-left action-card-button  smt ml " (click)="view()">
      <mat-icon class="smr fas fa-lightbulb"></mat-icon> More Details...
    </button> -->

    <button mat-raised-button color="accent" class=" pull-right btn-large order-now-button action-card-button downgrade"
      *ngIf="displaySubscribeButton==true && isPlanOwn!=true && price==0 && isPromoterOrAdmin!=true "
      (click)="downgrade()">
      DOWNGRADE <mat-icon class="fas fa-angle-double-down smr sml"></mat-icon>
    </button>

    <button mat-raised-button color="accent" class=" pull-right btn-large order-now-button action-card-button downgrade"
      *ngIf="displaySubscribeButton==true && isPlanOwn!=true && price==0 && isPromoterOrAdmin==true "
      (click)="getPlanEvent()">
      DOWNGRADE <mat-icon class="fas fa-angle-double-down smr sml"></mat-icon>
    </button>

    <button mat-raised-button color="accent" class=" pull-right btn-large order-now-button action-card-button "
      *ngIf="displaySubscribeButton==true && isPlanOwn!=true && price>0" (click)="getPlanEvent()">
      GET IT NOW! <mat-icon class="fas fa-star smr sml"></mat-icon>
    </button>

    <div class="own-item text-center pull-right" *ngIf="isPlanOwn == true">
      <span>YOUR CURRENT PLAN</span>
      <mat-icon class="fas fa-crown smr ml"></mat-icon>
    </div>

    <button
      class="pull-right smt action-card-button"
      *ngIf="displaySubscribeButton != true && isPlanOwn != true"
      [attr.style]="'background:' + color + ' !important' | safeStyle"
      (click)="closeEvent()"
      mat-raised-button
      color="primary"
    >
      Close
    </button>
  </div>

  <div class="drop-button text-center full-width mt mobile-view">
    <button
      class="pull-left smt action-card-button"
      *ngIf="displaySubscribeButton == true"
      [attr.style]="'background:' + color + ' !important' | safeStyle"
      (click)="closeEvent()"
      mat-raised-button
      color="primary"
    >
      Close
    </button>



    <button mat-raised-button color="accent" class=" pull-right btn order-now-button action-card-button downgrade"
      *ngIf="displaySubscribeButton==true && isPlanOwn!=true && price==0 && isPromoterOrAdmin!=true"
      (click)="downgrade()">
      DOWNGRADE <mat-icon class="fas fa-angle-double-down smr sml"></mat-icon>
    </button>


    <button mat-raised-button color="accent" class=" pull-right btn order-now-button action-card-button downgrade"
      *ngIf="displaySubscribeButton==true && isPlanOwn!=true && price==0 && isPromoterOrAdmin==true"
      (click)="getPlanEvent()">
      DOWNGRADE <mat-icon class="fas fa-angle-double-down smr sml"></mat-icon>
    </button>


    <button mat-raised-button color="accent" class=" pull-right btn order-now-button action-card-button "
      *ngIf="displaySubscribeButton==true && isPlanOwn!=true && price>0" (click)="getPlanEvent()">
      GET IT NOW! <mat-icon class="fas fa-star smr sml"></mat-icon>
    </button>

    <div class="own-item text-center pull-right" *ngIf="isPlanOwn == true">
      <span>YOUR CURRENT PLAN</span>
      <mat-icon class="fas fa-crown smr ml"></mat-icon>
    </div>
  </div>
</div>
