import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { WizardComponent } from 'angular-archwizard';
import { LookupService } from '../../../shared/services/lookup.service';
import { CustomerService } from '../shared/customer.service';

@Component({
  selector: 'app-customer-create',
  templateUrl: './customer-create.component.html',
  styleUrls: ['./customer-create.component.css'],
})
export class CustomerCreateComponent implements OnInit {
  customer: any = {
    firstName: '',
    title: '',
    lastName: '',
    midleName: '',
    gender: '',
    mobile: '',
    homePhone: '',
    workPhone: '',
    email: '',
  };
  isReffered = true;
  genders = [];
  titles = [];
  @ViewChild(WizardComponent, { static: true }) public wizard: WizardComponent;
  constructor(
    private location: Location,
    private lookupService: LookupService,
    private customerService: CustomerService
  ) {} // inject Location into class constructor

  ngOnInit() {
    this.lookupService.getLookup('CodeLookup', 'Gender').subscribe((data) => {
      this.genders = data;
    });
    this.lookupService.getLookup('SimpleLookup', 'Salutation').subscribe((data) => {
      this.titles = data;
    });
  }

  cancel() {
    this.location.back(); // <-- go back to previous location on cancel
  }

  createCustomer() {
    this.customerService.addCustomer(this.customer).subscribe((res) => {
      if (res.success) {
        this.wizard.goToNextStep();
        this.resetData();
      }
    });
  }

  resetData() {
    this.customer = {
      firstName: '',
      title: '',
      lastName: '',
      midleName: '',
      gender: '',
      mobile: '',
      homePhone: '',
      workPhone: '',
      email: '',
    };
  }
}
