import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { environment } from 'environments/environment';
import { setCurrentPublicSettingsAction } from '../../core/app-state/app-state.actions';
import { ModuleOptions, PublicSettings, Settings } from '../types/settings';
import { IndustrySettingsService } from './industry-settings.service';

@Injectable({
  providedIn: 'root',
})
export class ServiceFactoryService {
  constructor(private store: Store, private industrySettingsService: IndustrySettingsService) {}

  extractIsModulePropertiesFromObject(object: PublicSettings) {
    if (object) {
      return Object.keys(object)
        .filter((key) => key.includes('isModule'))
        .reduce((current, key) => Object.assign(current, { [key]: object[key] }), {}) as ModuleOptions;
    } else {
      return {} as ModuleOptions;
    }
  }

  removeIsModulePropertiesFromObject(object: PublicSettings) {
    if (object) {
      return Object.keys(object)
        .filter((key) => !key.includes('isModule'))
        .reduce((current, key) => Object.assign(current, { [key]: object[key] }), {}) as PublicSettings;
    } else {
      return {} as PublicSettings;
    }
  }

  getSettings = () => {
    return new Promise((resolve) => {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', environment.nodeUrl + '/settings/public-hidden');
      xhr.onload = () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          let data;
          let result = null;
          if (xhr && xhr.response) {
            data = JSON.parse(xhr.response);
          }

          if (data && data['data'] && data['data'][0]) {
            Settings.global = data['data'][0];
            result = data['data'][0];
          }

          resolve(result);
        } else {
          resolve(null);
        }
      };
      xhr.onerror = () => {
        resolve(null);
      };
      xhr.send();
    }).then((result: PublicSettings | null) => {
      this.industrySettingsService
        .fetchIndustrySettings$()
        .toPromise()
        .then((industrySettings) => {
          industrySettings.industryType = 'retail';
          industrySettings = {
            industryProfileTable: industrySettings.industryProfileTable
              ? industrySettings.industryProfileTable
              : 'none',
            industryType: industrySettings.industryType ? industrySettings.industryType : 'none',
          };
          const publicSettings = Object.assign(this.removeIsModulePropertiesFromObject(result), industrySettings);
          this.store.dispatch(setCurrentPublicSettingsAction({ publicSettings }));
        });
    });
  };
}
