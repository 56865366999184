import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { DataSource } from '@angular/cdk/table';
import { Component, ElementRef, EventEmitter, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { orderBy, uniq } from 'lodash';
import { BehaviorSubject, merge as observableMerge, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { CustomDatePipe } from '../../../shared/pipes/custom-date.pipe';
import { SideMenuService } from '../../../shared/services/side-menu.service';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { TagSelectComponent } from '../../tags/tag-select/tag-select.component';
import { InvitationTemplateCloneComponent } from '../invitation-template-clone/invitation-template-clone.component';
import { InvitationTemplateMergerComponent } from '../invitation-template-merger/invitation-template-merger.component';
import { InvitationTemplateThirdPartyComponent } from '../invitation-template-third-party/invitation-template-third-party.component';
import { InvitationTemplateService } from '../shared/invitation-template.service';

@Component({
  selector: 'invitation-template-list',
  templateUrl: './invitation-template-list.component.html',
  styleUrls: ['./invitation-template-list.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.row', style({ opacity: 0 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.5s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.row', style({ opacity: 1 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.5s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class InvitationTemplateListComponent implements OnInit {
  isPromoterOrAdmin = false;

  selectedTypeID;

  typeFilter = 'user';
  searchInput = '';

  @Input()
  title = 'Saved invitation templates';

  @Input()
  merchantID;

  isActiveFilter;

  @Input()
  pageSize = Settings.global['listPageSize'] || 20;
  pageSizeOptions = [10, Number(this.pageSize), Number(this.pageSize) * 2, Number(this.pageSize) * 3];

  utils = new UtilsClass();

  displayedColumns = [
    'selectCheckBox',
    'Label',
    'Value',
    'type',
    'Tags',
    'productGroupLabel',
    'applyCount',
    'Date',
    'IsActive',
    'Actions',
  ];
  filters = [];

  selectedIDs = [];

  searchLabel;

  searchVals = new FormControl();

  searchValList = ['Label'];

  expandedRow: number;
  public listDB: LoadRecords | null;
  dataSource: RecordDataSource | null;

  productGroups = [];
  merchantTags = [];
  filteredSize = null;

  destroyEvent = new EventEmitter();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('filter', { static: false }) filter: ElementRef;

  constructor(
    private invitationTemplateService: InvitationTemplateService,
    private dialog: MatDialog,
    private router: Router,
    private customDate: CustomDatePipe,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit() {
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;
    });

    const params = {
      merchantID: this.merchantID,
    };

    SideMenuService.goBack.subscribe((res) => {
      if (res == true) {
        this.hideViewDetails();
      }
    });

    this.listDB = new LoadRecords(this.invitationTemplateService, this.destroyEvent, params, this.isPromoterOrAdmin);
    this.dataSource = new RecordDataSource(this.listDB, this.paginator, this.sort);

    this.listDB.dataChangeInit.subscribe((filterType) => {
      if (filterType !== null) {
        this.typeFilter = filterType;
      }
    });

    this.listDB.getMerchantTags.subscribe((tags) => {
      this.merchantTags = JSON.parse(JSON.stringify(tags || []));
    });

    this.listDB.getProductGroups.subscribe((productGroups) => {
      this.productGroups = JSON.parse(JSON.stringify(productGroups || []));
    });

    this.setFilter({ value: '', field: 'label' }, { value: this.typeFilter, field: 'type' });
  }

  ngOnChanges(changes: SimpleChanges) {
    const params = {
      merchantID: this.merchantID,
    };

    this.listDB = new LoadRecords(this.invitationTemplateService, this.destroyEvent, params, this.isPromoterOrAdmin);
    this.dataSource = new RecordDataSource(this.listDB, this.paginator, this.sort);
  }

  getTypeLabel(type) {
    if (type && type == 'default') {
      return 'Smile Right';
    }

    return 'Practice';
  }

  setFilter(firstColumn: { value: any; field: string }, secondColumn?: { value: any; field: string }) {
    this.searchLabel = null;
    let filter;

    if (firstColumn.field == 'ApprovedOrActive') {
      let _event;
      if (firstColumn.value == true) {
        _event = '1';
      } else {
        _event = '0';
      }
      filter = {
        field: firstColumn.field,
        value: _event,
      };

      this.searchLabel = firstColumn.value;
    } else if (Array(firstColumn.value) && firstColumn.field == 'Tags') {
      let e = firstColumn.value;

      if (typeof e === 'string') {
        e = [e];
      }

      filter = {
        field: firstColumn.field,
        value: e,
      };

      this.searchLabel = firstColumn.value;
    } else if (Array(firstColumn.value) && firstColumn.field == 'productGroups') {
      let e = firstColumn.value;

      if (typeof e === 'string') {
        e = [e];
      }

      filter = {
        field: firstColumn.field,
        value: e,
      };

      this.searchLabel = firstColumn.value;
    } else if (typeof firstColumn.value == 'object' && firstColumn.value != null) {
      const v = this.customDate.transform(firstColumn.value, Settings.global['dateFormat']);
      filter = {
        field: firstColumn.field,
        value: v,
      };

      this.searchLabel = firstColumn.value;
    } else if (firstColumn.value && (firstColumn.field === 'fullName' || firstColumn.field === 'fullName_tags')) {
      filter = {
        field: firstColumn.field,
        value: String(firstColumn.value).toLocaleLowerCase(),
      };
      this.searchLabel = firstColumn.value;
    } else if (firstColumn.value) {
      filter = {
        field: firstColumn.field,
        value: firstColumn.value,
      };
      this.searchLabel = firstColumn.value;
    } else {
      this.searchLabel = null;
      filter = {
        field: firstColumn.field,
        value: '',
      };
    }

    if (secondColumn) {
      filter = { ...filter, secondField: secondColumn.field, secondValue: secondColumn.value };
    }

    this.dataSource.filter = filter;
    this.filteredSize = this.dataSource.filteredData.length;
  }

  indexOfTag(e) {
    if (this.searchLabel && Array.isArray(this.searchLabel) && e) {
      if (this.searchLabel.indexOf(e) != -1) {
        return true;
      }
    }

    return false;
  }

  selectAll() {
    for (let i = 0; i < this.dataSource.filteredData.length; i++) {
      const o = this.dataSource.filteredData[i];
      if (
        (o && o['_id'] && this.selectedIDs.indexOf(o['_id']) == -1 && o['type'] != 'default') ||
        (o['type'] == 'default' && this.isPromoterOrAdmin == true)
      ) {
        this.selectedIDs.push(o['_id']);
      }
    }
  }

  createNew() {
    this.router.navigate(['/merchant', { outlets: { page: ['invitation-template-create'] } }]);
  }

  editTreatment(id) {
    if (id) {
      this.router.navigate(['/merchant', { outlets: { page: ['invitation-template-edit', id] } }]);
    }
  }

  selectPage() {
    const index = this.dataSource['_paginator']['index'] || 0;
    const pageSize = this.dataSource['_paginator']['pageSize'] || 0;

    for (let i = 0; i < (index + 1) * pageSize; i++) {
      const o = this.dataSource.filteredData[index * pageSize + i];

      if (
        (o && o['_id'] && this.selectedIDs.indexOf(o['_id']) == -1 && o && o['type'] != 'default') ||
        (o && o['type'] == 'default' && this.isPromoterOrAdmin == true)
      ) {
        this.selectedIDs.push(o['_id']);
      }
    }
  }

  select(id, isSelected) {
    if (isSelected == true && this.selectedIDs.indexOf(id) == -1) {
      this.selectedIDs.push(id);
    } else {
      const _index = this.selectedIDs.indexOf(id);
      this.selectedIDs.splice(_index, 1);
    }
  }

  unselect() {
    this.selectedIDs = [];
  }

  isSelected(id) {
    if (!this.selectedIDs || this.selectedIDs.length <= 0) {
      return false;
    } else {
      if (this.selectedIDs.indexOf(id) != -1) {
        return true;
      }
    }

    return false;
  }

  enableGroup() {
    if (this.selectedIDs && this.selectedIDs.length > 0) {
      const confirmDialog = new ConfirmDialog(
        'check_circle',
        'Enable Selected Templates',
        '<p>Are you sure You want to enable these Templates ? .</p>',
        'Cancel',
        'Yes'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirmDialog,
        // SET TO PAYING CASH CARD
      });
      ref.componentInstance.onConfirm.subscribe((val) => {
        if (val == true) {
          ref.close();
          ref.afterClosed().subscribe((res) => {
            const payload = {
              isActive: true,
              ids: this.selectedIDs.join(','),
            };
            this.invitationTemplateService
              .modifyInvitationTemplateGroup(payload, this.isPromoterOrAdmin)
              .subscribe((res) => {
                if (res && res.length > 0) {
                  for (let i = 0; i < res.length; i++) {
                    this.dataSource.replaceItem = res[i];
                  }

                  this.selectedIDs = [];

                  NotifyAppComponent.displayToast('success', 'Success!', 'Templates are enabled.');
                }
              });
          });
        } else {
          ref.close();
        }
      });
    }
  }

  toLowerCase(e) {
    if (e) {
      return String(e).toLocaleLowerCase();
    }

    return e;
  }

  disableGroup() {
    if (this.selectedIDs && this.selectedIDs.length > 0) {
      const confirmDialog = new ConfirmDialog(
        'cancel',
        'Disable Selected Templates',
        '<p>Are you sure You want to Disable these Templates ? .</p>',
        'Cancel',
        'Yes'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirmDialog,
        // SET TO PAYING CASH CARD
      });
      ref.componentInstance.onConfirm.subscribe((val) => {
        if (val == true) {
          ref.close();
          ref.afterClosed().subscribe((res) => {
            const payload = {
              isActive: false,
              ids: this.selectedIDs.join(','),
            };
            this.invitationTemplateService
              .modifyInvitationTemplateGroup(payload, this.isPromoterOrAdmin)
              .subscribe((res) => {
                if (res && res.length > 0) {
                  for (let i = 0; i < res.length; i++) {
                    this.dataSource.replaceItem = res[i];
                  }

                  this.selectedIDs = [];

                  NotifyAppComponent.displayToast('success', 'Success!', 'Templates are disabled.');
                }
              });
          });
        } else {
          ref.close();
        }
      });
    }
  }

  enable(ID) {
    const confirmDialog = new ConfirmDialog(
      'check_circle',
      'Enable Template',
      '<p>Are you sure ? </p>',
      'Cancel',
      'Yes'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
      // SET TO PAYING CASH CARD
    });
    ref.componentInstance.onConfirm.subscribe((val) => {
      if (val == true) {
        ref.close();
        ref.afterClosed().subscribe((res) => {
          const payload = {
            isActive: true,
          };
          this.invitationTemplateService
            .modifyInvitationTemplate(ID, payload, this.isPromoterOrAdmin)
            .subscribe((res) => {
              if (res) {
                this.dataSource.replaceItem = res;

                NotifyAppComponent.displayToast('success', 'Success!', 'Template is enabled.');
              }
            });
        });
      } else {
        ref.close();
      }
    });
  }

  addTagsGroup() {
    if (this.selectedIDs && this.selectedIDs.length > 0) {
      const ref = RootAppComponent.dialog.open(TagSelectComponent, {
        data: {
          merchantID: this.merchantID,
          isModal: true,
          description: 'Choose a Tag or multiple ones to be added to the selected templates',
        },
        width: '600px',
      });
      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
      ref.componentInstance.getResult.subscribe((res) => {
        if (res && Array.isArray(res) && res.length > 0) {
          const payload = {
            addTags: res,
            ids: this.selectedIDs.join(','),
          };
          this.invitationTemplateService
            .modifyInvitationTemplateGroup(payload, this.isPromoterOrAdmin)
            .subscribe((res) => {
              if (res && res.length > 0) {
                for (let i = 0; i < res.length; i++) {
                  this.dataSource.replaceItem = res[i];
                }

                this.selectedIDs = [];

                NotifyAppComponent.displayToast('success', 'Success!', 'Tags are added to the selected templates.');
              }
            });
          ref.close();
        }
      });
    }
  }

  removeTagsGroup() {
    if (this.selectedIDs && this.selectedIDs.length > 0) {
      const ref = RootAppComponent.dialog.open(TagSelectComponent, {
        data: {
          merchantID: this.merchantID,
          isModal: true,
          description: 'Choose a Tag or multiple ones to be removed from the selected templates',
        },
        width: '600px',
      });
      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
      ref.componentInstance.getResult.subscribe((res) => {
        if (res && Array.isArray(res) && res.length > 0) {
          const payload = {
            removeTags: res,
            ids: this.selectedIDs.join(','),
          };
          this.invitationTemplateService
            .modifyInvitationTemplateGroup(payload, this.isPromoterOrAdmin)
            .subscribe((res) => {
              if (res && res.length > 0) {
                for (let i = 0; i < res.length; i++) {
                  this.dataSource.replaceItem = res[i];
                }

                this.selectedIDs = [];

                NotifyAppComponent.displayToast(
                  'success',
                  'Success!',
                  'Tags are removed from  the selected templates.'
                );
              }
            });
          ref.close();
        }
      });
    }
  }

  markDefaultGroup() {
    if (this.selectedIDs && this.selectedIDs.length > 0) {
      const confirmDialog = new ConfirmDialog(
        'check_circle',
        'Set Selected Templates As Smile right Template , Once you agree they will appear for all users ',
        '<p>Are you sure You ? .</p>',
        'Cancel',
        'Yes'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirmDialog,
        // SET TO PAYING CASH CARD
      });
      ref.componentInstance.onConfirm.subscribe((val) => {
        if (val == true) {
          ref.close();
          ref.afterClosed().subscribe((res) => {
            const payload = {
              type: 'default',
              ids: this.selectedIDs.join(','),
            };
            this.invitationTemplateService
              .modifyInvitationTemplateGroup(payload, this.isPromoterOrAdmin)
              .subscribe((res) => {
                if (res && res.length > 0) {
                  for (let i = 0; i < res.length; i++) {
                    this.dataSource.replaceItem = res[i];
                  }

                  this.selectedIDs = [];

                  NotifyAppComponent.displayToast('success', 'Success!', 'Templates are Set as Smile Right Template.');
                }
              });
          });
        } else {
          ref.close();
        }
      });
    }
  }

  removeDefaultGroup() {
    if (this.selectedIDs && this.selectedIDs.length > 0) {
      const confirmDialog = new ConfirmDialog(
        'check_circle',
        'Set Selected Templates As User Template , Once you agree they will appear for all users ',
        '<p>Are you sure You ? .</p>',
        'Cancel',
        'Yes'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirmDialog,
        // SET TO PAYING CASH CARD
      });
      ref.componentInstance.onConfirm.subscribe((val) => {
        if (val == true) {
          ref.close();
          ref.afterClosed().subscribe((res) => {
            const payload = {
              type: 'user',
              ids: this.selectedIDs.join(','),
            };
            this.invitationTemplateService
              .modifyInvitationTemplateGroup(payload, this.isPromoterOrAdmin)
              .subscribe((res) => {
                if (res && res.length > 0) {
                  for (let i = 0; i < res.length; i++) {
                    this.dataSource.replaceItem = res[i];
                  }

                  this.selectedIDs = [];

                  NotifyAppComponent.displayToast('success', 'Success!', 'Templates are Set as User Templates.');
                }
              });
          });
        } else {
          ref.close();
        }
      });
    }
  }

  markDefault(ID) {
    if (ID) {
      const confirmDialog = new ConfirmDialog(
        'cancel',
        'Set Template as Smile Right Template , Once you agree it will appear for all users',
        '<p>Are you sure ? </p>',
        'Cancel',
        'Yes'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirmDialog,
        // SET TO PAYING CASH CARD
      });
      ref.componentInstance.onConfirm.subscribe((val) => {
        if (val == true) {
          ref.close();
          ref.afterClosed().subscribe((res) => {
            const payload = {
              type: 'default',
            };
            this.invitationTemplateService
              .modifyInvitationTemplate(ID, payload, this.isPromoterOrAdmin)
              .subscribe((res) => {
                if (res) {
                  this.dataSource.replaceItem = res;

                  NotifyAppComponent.displayToast('success', 'Success!', 'Template is Set as Smile Right Template.');
                }
              });
          });
        } else {
          ref.close();
        }
      });
    }
  }

  removeDefault(ID) {
    if (ID) {
      const confirmDialog = new ConfirmDialog(
        'cancel',
        'Set Template as  User Template , Once you agree it will appear for all users',
        '<p>Are you sure ? </p>',
        'Cancel',
        'Yes'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirmDialog,
        // SET TO PAYING CASH CARD
      });
      ref.componentInstance.onConfirm.subscribe((val) => {
        if (val == true) {
          ref.close();
          ref.afterClosed().subscribe((res) => {
            const payload = {
              type: 'user',
            };
            this.invitationTemplateService
              .modifyInvitationTemplate(ID, payload, this.isPromoterOrAdmin)
              .subscribe((res) => {
                if (res) {
                  this.dataSource.replaceItem = res;

                  NotifyAppComponent.displayToast('success', 'Success!', 'Template is Set as User Template.');
                }
              });
          });
        } else {
          ref.close();
        }
      });
    }
  }

  disable(ID) {
    if (ID) {
      this.invitationTemplateService.getThirdPartyDetails(ID).subscribe((template) => {
        if (template && template.canChange === false) {
          const ref2 = RootAppComponent.dialog.open(InvitationTemplateThirdPartyComponent, {
            data: template,
          });
          ref2.componentInstance.close.subscribe((confirmation) => {
            ref2.close();
          });
          ref2.componentInstance.closeAll.subscribe((confirmation) => {
            ref2.close();
          });
        } else {
          const confirmDialog = new ConfirmDialog(
            'cancel',
            'Disable Template',
            '<p>Are you sure ? </p>',
            'Cancel',
            'Yes'
          );

          const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
            data: confirmDialog,
            // SET TO PAYING CASH CARD
          });
          ref.componentInstance.onConfirm.subscribe((val) => {
            if (val == true) {
              ref.close();
              ref.afterClosed().subscribe((res) => {
                const payload = {
                  isActive: false,
                };
                this.invitationTemplateService
                  .modifyInvitationTemplate(ID, payload, this.isPromoterOrAdmin)
                  .subscribe((res) => {
                    if (res) {
                      this.dataSource.replaceItem = res;

                      NotifyAppComponent.displayToast('success', 'Success!', 'Template is disabled.');
                    }
                  });
              });
            } else {
              ref.close();
            }
          });
        }
      });
    }
  }

  removeGroup() {
    if (this.selectedIDs && this.selectedIDs.length > 0) {
      const confirm = new ConfirmDialog(
        'fas fa-info',
        '',
        'Are you sure you want to remove these templates  ?',
        'No',
        'Yes'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation === false) {
          ref.close();
        } else {
          this.invitationTemplateService
            .deleteInvitationTemplateGroup(this.selectedIDs, this.isPromoterOrAdmin)
            .subscribe((res) => {
              if (res && res.length > 0) {
                for (let i = 0; i < res.length; i++) {
                  this.dataSource.removeItem = res[i];
                }

                this.selectedIDs = [];
              }
              ref.close();
            });
        }
      });
    }
  }

  remove(id) {
    if (id) {
      this.invitationTemplateService.getThirdPartyDetails(id).subscribe((template) => {
        if (template && template.canChange === false) {
          const ref2 = RootAppComponent.dialog.open(InvitationTemplateThirdPartyComponent, {
            data: template,
          });
          ref2.componentInstance.close.subscribe((confirmation) => {
            ref2.close();
          });
          ref2.componentInstance.closeAll.subscribe((confirmation) => {
            ref2.close();
          });
        } else {
          const confirm = new ConfirmDialog(
            'fas fa-info',
            '',
            'Are you sure you want to remove this template  ?',
            'No',
            'Yes'
          );

          const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
            data: confirm,
          });
          ref.componentInstance.onConfirm.subscribe((confirmation) => {
            if (confirmation === false) {
              ref.close();
            } else {
              this.invitationTemplateService.deleteInvitationTemplate(id, this.isPromoterOrAdmin).subscribe((res) => {
                if (res) {
                  this.dataSource.removeItem = id;
                }
                ref.close();
              });
            }
          });
        }
      });
    }
  }

  clone(t) {
    if (t && t._id) {
      let isDefault = false;

      if (this.isPromoterOrAdmin == true && t.type == 'default') {
        isDefault = true;
      }

      const ref = this.dialog.open(InvitationTemplateCloneComponent, {
        data: {
          merchantID: this.merchantID,
          invitationTemplateID: t._id,
          isDefault,
        },
        width: '600px',
      });
      ref.componentInstance.closeAndEdit.subscribe((res) => {
        ref.close();
      });

      ref.componentInstance.closeModal.subscribe((res) => {
        ref.close();
      });
      ref.componentInstance.getResult.subscribe((res) => {
        if (res && res._id) {
          this.dataSource.addItem = res;
        }
        ref.close();
      });
    }
  }

  viewAllThirdParty(id) {
    if (id) {
      const confirmDialog = new ConfirmDialog(
        'check_circle',
        'Redirection',
        '<p>You are about to leave this page, Do you want to continue? </p>',
        'Cancel',
        'Yes'
      );
      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirmDialog,
        // SET TO PAYING CASH CARD
      });
      ref.componentInstance.onConfirm.subscribe((val) => {
        if (val == true) {
          ref.close();
          ref.afterClosed().subscribe((res) => {
            this.router.navigate(['/merchant', { outlets: { page: ['third-party-access-client-list', id] } }]);
          });
        } else {
          ref.close();
        }
      });
    }
  }

  toNumber(n) {
    if (n) {
      return Number(n);
    } else {
      return 0;
    }
  }

  isNew(date) {
    if (date) {
      const days = this.utils.daysPast(date);

      if (days < 15) {
        return true;
      } else {
        return false;
      }
    }

    return false;
  }

  joinBySpace(tags) {
    if (tags && tags.length > 0) {
      return tags.join(', ');
    }
  }

  viewTreatment(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(InvitationTemplateMergerComponent, {
        data: {
          templateID: id,
          viewOnly: true,
        },
        width: '1200px',
      });
      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    }
  }

  hideViewDetails() {}

  ngOnDestroy() {
    this.destroyEvent.emit(true);
  }
}

export class LoadRecords implements OnInit {
  public dataChange: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public dataChangeInit: BehaviorSubject<any> = new BehaviorSubject<any[]>(null);

  items: any;
  count: any;

  get data(): any[] {
    return this.dataChange.value;
  }

  firstLoad = false;

  get firstLoadEvent() {
    return this.firstLoad;
  }

  isDestoyed = false;
  serviceRef;
  public getProductGroups = new EventEmitter();
  public getMerchantTags = new EventEmitter();
  merchantTags = [];
  productGroups = [];

  constructor(
    private invitationTemplateService: InvitationTemplateService,
    private destroyEvent,
    params,
    private isPromoterOrAdmin
  ) {
    this.destroyEvent.subscribe((res) => {
      this.isDestoyed = res;
    });

    const payload = {
      section: 0,
      orderBy: 'label',
      fields: '_id,label,isActive,type,applyCount,created_at,content,description,productGroup',
    };

    if (params && params.merchantID) {
      payload['merchantID'] = params.merchantID;
    }
    if (!this.merchantTags) {
      this.merchantTags = [];
    }

    if (!this.productGroups) {
      this.productGroups = [];
    }
    this.serviceRef = this.invitationTemplateService
      .getInvitationTemplateList(payload, this.isPromoterOrAdmin)
      .subscribe((res) => {
        if (res) {
          for (let i = 0; i < res.length; i++) {
            res[i]['fullName'] = '';
            res[i]['Tags'] = [];
            if (res[i] && res[i]['content'] && res[i]['content']['tags']) {
              res[i]['fullName'] =
                String(res[i]['fullName'] + res[i]['content']['tags'].join(',')).toLocaleLowerCase() + ',';
              res[i]['Tags'] = res[i]['Tags'].concat(res[i]['content']['tags']);

              this.merchantTags = this.merchantTags.concat(res[i]['content']['tags']);
            }

            if (res[i] && res[i]['label']) {
              res[i]['fullName'] = String(res[i]['fullName'] + res[i]['label']).toLocaleLowerCase();
            }

            if (res[i] && res[i]['description']) {
              res[i]['fullName'] = String(res[i]['fullName'] + res[i]['description']).toLocaleLowerCase();
            }

            if (res[i] && res[i]['type']) {
              res[i]['fullName'] = String(res[i]['fullName'] + res[i]['type']).toLocaleLowerCase();
            }

            if (res[i] && res[i]['productGroup']) {
              res[i]['fullName'] = String(res[i]['fullName'] + res[i]['productGroup']).toLocaleLowerCase();
            }

            if (res[i] && res[i]['productGroupLabel']) {
              res[i]['fullName'] = String(res[i]['fullName'] + res[i]['productGroupLabel']).toLocaleLowerCase();
              this.productGroups.push(res[i]['productGroupLabel']);
            }
          }

          this.merchantTags = uniq(this.merchantTags);
          this.merchantTags = orderBy(this.merchantTags);
          this.getMerchantTags.emit(this.merchantTags);

          this.productGroups = uniq(this.productGroups);
          this.productGroups = orderBy(this.productGroups);
          this.getProductGroups.emit(this.productGroups);
        }
        this.count = res.length;
        this.items = res;

        if (this.firstLoad === false) {
          const userTemplatesCount = res.filter((template) => template.type === 'user').length;

          if (userTemplatesCount > 0) {
            this.dataChangeInit.next('user');
          } else {
            this.dataChangeInit.next('default');
          }
        }

        this.dataChange.next(this.items.slice());
        this.firstLoad = true;

        const innerFunction = (section) => {
          const length = this.items.length;
          section = section + 1;
          UtilsClass.loadingDataSection(section);
          payload.section = section;

          this.serviceRef = this.invitationTemplateService
            .getInvitationTemplateList(payload, this.isPromoterOrAdmin)
            .subscribe((res) => {
              if (res.length > 0) {
                for (let i = 0; i < res.length; i++) {
                  res[i]['fullName'] = '';
                  res[i]['Tags'] = [];
                  if (res[i] && res[i]['content'] && res[i]['content']['tags']) {
                    res[i]['fullName'] =
                      String(res[i]['fullName'] + res[i]['content']['tags'].join(',')).toLocaleLowerCase() + ',';
                    res[i]['Tags'] = res[i]['Tags'].concat(res[i]['content']['tags']);

                    this.merchantTags = this.merchantTags.concat(res[i]['content']['tags']);
                  }

                  if (res[i] && res[i]['label']) {
                    res[i]['fullName'] = String(res[i]['fullName'] + res[i]['label']).toLocaleLowerCase();
                  }

                  if (res[i] && res[i]['description']) {
                    res[i]['fullName'] = String(res[i]['fullName'] + res[i]['description']).toLocaleLowerCase();
                  }

                  if (res[i] && res[i]['type']) {
                    res[i]['fullName'] = String(res[i]['fullName'] + res[i]['type']).toLocaleLowerCase();
                  }
                  if (res[i] && res[i]['productGroup']) {
                    res[i]['fullName'] = String(res[i]['fullName'] + res[i]['productGroup']).toLocaleLowerCase();
                  }

                  if (res[i] && res[i]['productGroupLabel']) {
                    res[i]['fullName'] = String(res[i]['fullName'] + res[i]['productGroupLabel']).toLocaleLowerCase();
                    this.productGroups.push(res[i]['productGroupLabel']);
                  }
                }

                this.productGroups = uniq(this.productGroups);
                this.productGroups = orderBy(this.productGroups);
                this.getProductGroups.emit(this.productGroups);

                this.merchantTags = uniq(this.merchantTags);
                this.merchantTags = orderBy(this.merchantTags);
                this.getMerchantTags.emit(this.merchantTags);
                this.count = res.count;
                this.items = this.items.concat(res);

                this.dataChange.next(this.items.slice());
                if (this.isDestoyed != true) {
                  innerFunction(section);
                }
              } else {
                return true;
              }
            });
          UtilsClass.stopLoadingInterceptor();
        };

        if (this.isDestoyed != true) {
          innerFunction(1);
        }
      });
  }

  ngOnInit() {}

  ngOnDestroy() {
    if (this.serviceRef) {
      this.serviceRef.unsubscribe();
    }
  }
}

export class RecordDataSource extends DataSource<any> {
  _filterChange = new BehaviorSubject('');
  field = '';
  secondField;
  secondValue;

  get filter(): any {
    return this._filterChange.value;
  }

  set filter(item: any) {
    this.field = item.field;
    this.secondField = item.secondField;
    this.secondValue = item.secondValue;
    this._filterChange.next(item.value);
  }

  set removeItem(id) {
    const data = this._tableDatabase.data.filter((row) => row._id != id);
    this._tableDatabase.dataChange.next(data);
  }

  set addItem(res) {
    this._tableDatabase.data.unshift(res);

    this._tableDatabase.dataChange.next(this._tableDatabase.data);
  }

  set replaceItem(item) {
    const _index = this._tableDatabase.data.findIndex((_obj) => _obj._id == item._id);
    this._tableDatabase.data[_index] = item;
    this._tableDatabase.dataChange.next(this._tableDatabase.data);
  }

  filteredData: any[] = [];

  constructor(private _tableDatabase: LoadRecords, private _paginator: MatPaginator, private _sort: MatSort) {
    super();
    this._filterChange.subscribe(() => (this._paginator.pageIndex = 0));
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<any[]> {
    const displayDataChanges = [
      this._tableDatabase.dataChange,
      this._filterChange,
      this._paginator.page,
      this._sort.sortChange,
    ];

    return observableMerge(...displayDataChanges).pipe(
      map(() => {
        // Filter data
        this.filteredData = this._tableDatabase.data.slice().filter((item: any) => {
          if (this.secondField === undefined) {
            if (this.field == 'Tags') {
              let f = '';
              f = item[this.field] || '';

              if (this.filter && this.filter.length > 0) {
                for (let j = 0; j < this.filter.length; j++) {
                  if (f.indexOf(this.filter[j]) == -1) {
                    return false;
                  }
                }

                return true;
              } else {
                return f;
              }
            } else if (this.field == 'productGroups') {
              let f = '';
              f = item['productGroupLabel'] || '';

              if (this.filter && this.filter.length > 0) {
                if (this.filter.indexOf(f) === -1) {
                  return false;
                }

                return true;
              } else {
                return f;
              }
            } else {
              let f = '';
              f = item[this.field] || '';

              const searchStr = f.toLowerCase();

              return searchStr.indexOf(this.filter.toLowerCase()) != -1;
            }
          } else {
            let f = '';
            let f2 = null;

            f = item[this.field] || '';
            f2 = item[this.secondField] || null;

            const searchStr = f.toLowerCase();
            const searchStr2 = f2.toLowerCase();

            if (this.secondValue === null) {
              return searchStr.indexOf(this.filter.toLowerCase()) != -1;
            } else {
              return searchStr.indexOf(this.filter.toLowerCase()) != -1 && searchStr2 === this.secondValue;
            }
          }
        });
        const data = this.getSortedData(this.filteredData.slice());
        // Grab the page's slice of data.
        const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
        return data.splice(startIndex, this._paginator.pageSize);
      })
    );
  }

  disconnect() {}

  /** Returns a sorted copy of the database data. */
  getSortedData(data: any[]): any[] {
    // const data = data;
    if (!this._sort.active || this._sort.direction == '') {
      return data;
    }

    return data.sort((a, b) => {
      let propertyA: number | string = '';
      let propertyB: number | string = '';

      switch (this._sort.active) {
        case 'type':
          [propertyA, propertyB] = [a['type'], b['type']];
          break;

        case 'Label':
          [propertyA, propertyB] = [a['Label'], b['Label']];
          break;

        case 'Date':
          [propertyA, propertyB] = [new Date(a['created_at']).getTime(), new Date(b['created_at']).getTime()];
          break;

        case 'IsActive':
          [propertyA, propertyB] = [a['Active'], b['Active']];
          break;

        case 'applyCount':
          [propertyA, propertyB] = [a['applyCount'], b['applyCount']];
          break;
      }

      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

      return (valueA < valueB ? -1 : 1) * (this._sort.direction == 'asc' ? 1 : -1);
    });
  }
}
