<div class="rel bank-details-card clearfix" *ngIf="bankAccount && isPromoterOrAdmin == true">
  <!-- header if standalone -->
  <div class="card-header primary-gradient-img clearfix inModal">
    <div class="row clearfix">
      <!-- Modal dismiss button -->
      <button class="btn-close btn-clear mat-button white" *ngIf="isModal == true" (click)="closeEvent()"
        matDialogClose>
        <mat-icon>clear</mat-icon>
      </button>

      <div class="row titleArea clearfix">
        <app-ep-document-view-image class="pull-left display-img-bank mr"
          *ngIf="bankAccount && bankAccount['AccountType.Code'] == 'CC' &&  getCardType(bankAccount['Card.Type.Code'])==='MC'"
          [defaultPicture]="
            'url(https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/public/img/card_11041055.png)'
          "></app-ep-document-view-image>

        <app-ep-document-view-image class="pull-left display-img-bank mr"
          *ngIf="bankAccount && bankAccount['AccountType.Code'] == 'CC'  &&  getCardType(bankAccount['Card.Type.Code'])==='VS'"
          [defaultPicture]="
        'url(https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/public/img/card_11041038.png)'
      "></app-ep-document-view-image>

        <app-ep-document-view-image class="pull-left display-img-bank mr"
          *ngIf="bankAccount && bankAccount['AccountType.Code'] == 'CC'  &&  getCardType(bankAccount['Card.Type.Code'])==='AX'"
          [defaultPicture]="
        'url(https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/public/img/american-express_349228.png)'
      "></app-ep-document-view-image>

        <app-ep-document-view-image class="pull-left display-img-bank mr"
          *ngIf="bankAccount && bankAccount['AccountType.Code'] == 'CC'  &&  getCardType(bankAccount['Card.Type.Code'])==='CC'"
          [defaultPicture]="
        'url(https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/credit-card_147258.png)'
      "></app-ep-document-view-image>



        <app-ep-document-view-image class="pull-left display-img-bank mr "
          *ngIf="bankAccount && bankAccount['AccountType.Code'] != 'CC'" [defaultPicture]="
            'url(https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/bank_2830289.png)'
          "></app-ep-document-view-image>

        <h2 class="summary-header pull-left  rm-m" *ngIf="bankAccount['AccountType.Code'] == 'CC'">
          Credit Card
          <span class="subLabel" *ngIf="bankAccount['Card.Type.Label']">{{ bankAccount['Card.Type.Label'] }}</span>
        </h2>

        <h2 class="summary-header pull-left  rm-m" *ngIf="bankAccount['AccountType.Code'] != 'CC'">
          Bank Account
          <span class="subLabel" *ngIf="bankAccount['BankName']">{{ bankAccount['BankName'] }}</span>
        </h2>
      </div>
    </div>
    <div class="action-button">
      <button class="actionMenuToggle" [matMenuTriggerFor]="btnPatMenu" mat-raised-button>
        <mat-icon>more_vert</mat-icon>
      </button>

      <mat-menu #btnPatMenu="matMenu">
        <button *ngIf="bankAccount && bankAccount['IsActive'] == '1' && isPromoterOrAdmin==true " (click)="disable()"
          mat-menu-item>
          <mat-icon>cancel</mat-icon>
          <span>Disable</span>
        </button>

        <button *ngIf="bankAccount && bankAccount['IsActive'] == '0'  && isPromoterOrAdmin==true " (click)="enable()"
          mat-menu-item>
          <mat-icon>check_circle</mat-icon>
          <span>Enable</span>
        </button>
      </mat-menu>
    </div>
  </div>
  <mat-dialog-content>
    <div class="full-width mb clearfix row bankAcc" *ngIf="bankAccount['AccountType.Code'] != 'CC'">
      <div class="row clearfix details flex" *ngIf="bankAccount['BankName']">
        <mat-icon class="pull-left mr fas fa-piggy-bank"></mat-icon>
        <div class="row-content">
          <!--  -->
          <p class="rm-mb rm-mt">{{ bankAccount['BankName'] }}</p>
          <p class="small">Bank Name</p>
        </div>
      </div>

      <hr *ngIf="bankAccount['AccountType.Label']" />
      <div class="row clearfix details flex" *ngIf="bankAccount['AccountType.Label']">
        <mat-icon class="pull-left mr fas fa-money-check-alt"></mat-icon>
        <div class="row-content">
          <!--  -->
          <p class="rm-mb rm-mt">{{ bankAccount['AccountType.Label'] }}</p>
          <p class="small">Account</p>
        </div>
      </div>

      <hr *ngIf="bankAccount['AccountSavingCheqType.Label']" />
      <div class="row clearfix details flex" *ngIf="bankAccount['AccountSavingCheqType.Label']">
        <mat-icon class="pull-left mr">search</mat-icon>
        <div class="row-content">
          <!--  -->
          <p class="rm-mb rm-mt">{{ bankAccount['AccountSavingCheqType.Label'] }}</p>
          <p class="small">Account Type</p>
        </div>
      </div>

      <hr *ngIf="bankAccount['AccountNumber.Full']" />
      <div class="row clearfix details flex" *ngIf="bankAccount['AccountNumber.Full']">
        <mat-icon class="pull-left mr fas fa-hashtag"></mat-icon>
        <div class="row-content">
          <!--  -->
          <p class="rm-mb rm-mt">{{ bankAccount['AccountNumber.Full'] }}</p>
          <p class="small">Account Number</p>
        </div>
      </div>

      <hr *ngIf="bankAccount['BankCode'] || bankAccount['BranchCode']" />
      <div class="row clearfix details flex" *ngIf="bankAccount['BankCode'] || bankAccount['BranchCode']">
        <mat-icon class="pull-left mr fas fa-university"></mat-icon>
        <div class="row-content mr">
          <!--  -->
          <p class="rm-mb rm-mt">{{ bankAccount['BankCode'] }}</p>
          <p class="small">Bank Code</p>
        </div>

        <div class="row-content ml">
          <!--  -->
          <p class="rm-mb rm-mt">{{ bankAccount['BranchCode'] }}</p>
          <p class="small">Bank Branch Code</p>
        </div>
      </div>

      <!-- probably dont need request status -->
      <!-- <div *ngIf="bankAccount['Request.Status']" class="row clearfix details flex">
        <mat-icon class="pull-left mr">help</mat-icon>
        <div class="row-content">
          <p class="rm-mb rm-mt">{{bankAccount['Request.Status'] }}</p>
          <p class="small">{{ "Request Status" | translate }}</p>
        </div>
      </div> -->
    </div>

    <div class="full-width mb clearfix row creditCard" *ngIf="bankAccount['AccountType.Code'] == 'CC'">
      <div class="row clearfix details flex" *ngIf="bankAccount['AccountType.Label']">
        <mat-icon class="pull-left mr fas fa-university"></mat-icon>
        <div class="row-content">
          <!--  -->
          <p class="rm-mb rm-mt">{{ bankAccount['AccountType.Label'] }}</p>
          <p class="small">Account</p>
        </div>
      </div>

      <hr *ngIf="bankAccount['AccountSavingCheqType.Label']" />
      <div class="row clearfix details flex" *ngIf="bankAccount['AccountSavingCheqType.Label']">
        <mat-icon class="pull-left mr fas fa-money-check-alt"></mat-icon>
        <div class="row-content">
          <!--  -->
          <p class="rm-mb rm-mt">{{ bankAccount['AccountSavingCheqType.Label'] }}</p>
          <p class="small">Account Type</p>
        </div>
      </div>

      <hr *ngIf="bankAccount['Card.CardHolder']" />
      <div class="row clearfix details flex" *ngIf="bankAccount['Card.CardHolder']">
        <mat-icon class="pull-left mr fas fa-user"></mat-icon>
        <div class="row-content">
          <!--  -->
          <p class="rm-mb rm-mt">{{ bankAccount['Card.CardHolder'] }}</p>
          <p class="small">Card Holder</p>
        </div>
      </div>
      <hr *ngIf="bankAccount['Card.Number.Full']" />
      <div class="row clearfix details flex" *ngIf="bankAccount['Card.Number.Full']">
        <mat-icon class="pull-left mr fas fa-hashtag"></mat-icon>
        <div class="row-content">
          <!--  -->
          <p class="rm-mb rm-mt">{{ bankAccount['Card.Number.Full'] }}</p>
          <p class="small">Card Number</p>
        </div>
      </div>

      <hr *ngIf="bankAccount['Card.ExpiryDate']" />
      <div class="row clearfix details flex" *ngIf="bankAccount['Card.ExpiryDate']">
        <mat-icon class="pull-left mr fas fa-calendar-day"></mat-icon>
        <div class="row-content">
          <!--  -->
          <p class="rm-mb rm-mt">{{ bankAccount['Card.ExpiryDate'] }}</p>
          <p class="small">Expiry Date</p>
        </div>
      </div>

      <hr *ngIf="bankAccount['Card.Type.Label']" />
      <div class="row clearfix details flex" *ngIf="bankAccount['Card.Type.Label']">
        <mat-icon class="pull-left mr fas fa-credit-card"></mat-icon>
        <div class="row-content">
          <!--  -->
          <p class="rm-mb rm-mt">{{ bankAccount['Card.Type.Label'] }}</p>
          <p class="small">Card Type</p>
        </div>
      </div>

      <!-- probably don't need request status -->
      <!-- <div *ngIf="bankAccount['Request.Status']" class="row clearfix details flex">
      <div class="row-content">
        <p class="rm-mb rm-mt">{{bankAccount['Request.Status'] }}</p>
        <p class="small">{{ "Request Status" | translate }}</p>
      </div>
    </div> -->
    </div>

    <hr />
    <p class="clearfix small pull-right" *ngIf="bankAccount['IsActive'] == '1' && isPromoterOrAdmin == true">
      <span class="chip chip-enable">Activated</span>
    </p>

    <p class="clearfix small pull-right" *ngIf="bankAccount['IsActive'] == '0' && isPromoterOrAdmin == true">
      <span class="chip chip-disable">Not Activated</span>
    </p>

    <p class="clearfix small pull-right" *ngIf="bankAccount['HasBeenVerified'] == '1' && isPromoterOrAdmin == true">
      <span class="chip chip-enable">Verified</span>
    </p>

    <p class="clearfix small pull-right" *ngIf="bankAccount['HasBeenVerified'] == '0' && isPromoterOrAdmin == true">
      <span class="chip chip-disable">Not Verified</span>
    </p>
  </mat-dialog-content>
</div>
