import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-customer-import',
  templateUrl: './customer-import.component.html',
  styleUrls: ['./customer-import.component.css'],
})
export class CustomerImportComponent implements OnInit {
  constructor(private location: Location) {} // inject Location into class constructor

  ngOnInit() {}

  cancel() {
    this.location.back(); // <-- go back to previous location on cancel
  }
}
