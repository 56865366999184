import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { tap } from 'rxjs/operators';
import { CustomerProspectService } from '../../../feature/customer-prospect/shared/customerProspect.service';
import { PatientLookupComponent } from '../patient-lookup/patient-lookup.component';
import { RootAppComponent } from '../root-component/root-component.component';

@Component({
  selector: 'sr-patient-selector',
  templateUrl: './patient-selector.component.html',
  styleUrls: ['./patient-selector.component.css'],
})
export class PatientSelectorComponent implements OnInit, OnChanges {
  @Output() getPatient: EventEmitter<any> = new EventEmitter();

  @Input() patient;
  @Input() merchantID;

  @Input() displayOnly = false;

  private readonly defaultPicture =
    'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/profile_placeholder.png';
  patientImage =
    'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/profile_placeholder.png';

  constructor(private customerProspectService: CustomerProspectService) {}

  ngOnInit(): void {
    if (this.patient) {
      this.getPatientPicture().subscribe();
    }
  }

  openPatientLookupDialog() {
    const ref = RootAppComponent.dialog.open(PatientLookupComponent, {
      data: {
        merchantID: this.merchantID,

        useStrict: true,
      },
      width: '550px',
    });

    ref.componentInstance.getResult.subscribe((res) => {
      if (res) {
        this.patient = res;
        this.getPatient.emit(this.patient);
        this.getPatientPicture().subscribe(() => {
          ref.close();
        });
      }
    });

    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  getPatientPicture() {
    return this.customerProspectService.getProfilePic(this.patient['ID'], {}).pipe(
      tap((picture) => {
        if (picture) {
          this.patientImage = this.customerProspectService.getProfilePicStreamLink(picture['ID']);
        } else {
          this.patientImage = this.defaultPicture;
        }
      })
    );
  }

  ngOnChanges() {
    if (this.patient) {
      this.getPatientPicture().subscribe();
    }
  }
}
