/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./customer-prospect-edit.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../customer/customer-edit/customer-edit.component.ngfactory";
import * as i3 from "../../customer/customer-edit/customer-edit.component";
import * as i4 from "../../../core/authentication/shared/authentication.service";
import * as i5 from "@angular/common";
import * as i6 from "../../customer/shared/customer.service";
import * as i7 from "../../../shared/services/lookup.service";
import * as i8 from "@angular/material/dialog";
import * as i9 from "../../existing-patient/prospect-edit/prospect-edit.component.ngfactory";
import * as i10 from "../../existing-patient/prospect-edit/prospect-edit.component";
import * as i11 from "../../existing-patient/shared/existing-patient.service";
import * as i12 from "./customer-prospect-edit.component";
import * as i13 from "../shared/customerProspect.service";
var styles_CustomerProspectEditComponent = [i0.styles];
var RenderType_CustomerProspectEditComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CustomerProspectEditComponent, data: {} });
export { RenderType_CustomerProspectEditComponent as RenderType_CustomerProspectEditComponent };
function View_CustomerProspectEditComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-customer-edit", [["class", ""]], null, [[null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("close" === en)) {
        var pd_0 = (_co.closeEvent($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_CustomerEditComponent_0, i2.RenderType_CustomerEditComponent)), i1.ɵdid(1, 114688, null, 0, i3.CustomerEditComponent, [i4.AuthenticationService, i5.Location, i6.CustomerService, i7.LookupService, [2, i8.MAT_DIALOG_DATA]], { customerID: [0, "customerID"], isConcumer: [1, "isConcumer"] }, { close: "close" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.customerID; var currVal_1 = _co.isConcumer; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_CustomerProspectEditComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-prospect-edit", [["class", ""]], null, [[null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("close" === en)) {
        var pd_0 = (_co.closeEvent($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_ProspectEditComponent_0, i9.RenderType_ProspectEditComponent)), i1.ɵdid(1, 4308992, null, 0, i10.ProspectEditComponent, [i5.Location, i4.AuthenticationService, i11.ExistingPatientService, i7.LookupService, i1.ChangeDetectorRef, [2, i8.MAT_DIALOG_DATA]], { prospectID: [0, "prospectID"], isConcumer: [1, "isConcumer"] }, { close: "close" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.prospectID; var currVal_1 = _co.isConcumer; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_CustomerProspectEditComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomerProspectEditComponent_1)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomerProspectEditComponent_2)), i1.ɵdid(3, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.customerID; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.prospectID; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_CustomerProspectEditComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-customer-prospect-edit", [], null, null, null, View_CustomerProspectEditComponent_0, RenderType_CustomerProspectEditComponent)), i1.ɵdid(1, 638976, null, 0, i12.CustomerProspectEditComponent, [i4.AuthenticationService, i13.CustomerProspectService, [2, i8.MAT_DIALOG_DATA]], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomerProspectEditComponentNgFactory = i1.ɵccf("app-customer-prospect-edit", i12.CustomerProspectEditComponent, View_CustomerProspectEditComponent_Host_0, { patientID: "patientID", isConcumer: "isConcumer" }, {}, []);
export { CustomerProspectEditComponentNgFactory as CustomerProspectEditComponentNgFactory };
