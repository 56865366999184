<div class="container merchant-calculator">
  <mat-card class="calculator" [ngClass]="{ flipped: showOption == true }">
    <div class="front">
      <mat-card-header class="text-center primary-gradient-img rel">
        <!-- Modal dismiss button -->
        <button class="calc btn-close btn-clear mat-button white" (click)="closeModal()">
          <mat-icon>clear</mat-icon>
        </button>

        <h1 class="sr-title sm-h1 rm-m">Financial Calculator</h1>
        <label class="mb">Calculate {{ "KEYWORD.patient" | translate }} repayments for financing
          {{ "KEYWORD.treatment" | translate }}</label>
      </mat-card-header>
      <div class="card-content">
        <form #createForm="ngForm" name="form">
          <div class="selector-row row clearfix mb productGroup">
            <!-- <div class="full-width">
              <mat-select placeholder="Choose product type" name="productType" [(ngModel)]="productType">
                <mat-option [value]="'Finance'">Finance</mat-option>
                <mat-option [value]="'Membership'">Membership</mat-option>
              </mat-select>
            </div> -->
            <div class="clearfix" *ngIf="isPromoterOrAdmin == true">
              <div class="row clearfix selectMerchant">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Please Select a Merchant</mat-label>
                  <mat-select class="" [(ngModel)]="merchantID" (ngModelChange)="getProductGroup(merchantID)"
                              name="MerchantName" placeholder="">
                    <mat-option [value]="null"> My Account</mat-option>
                    <mat-option *ngFor="let m of merchants | orderBy: ['TradingAs', 'CalculatedName']"
                                [value]="m['ID']">
                      <span class="list-label pull-right" *ngIf="m['Status'] && m['Status'] == 'Active'"
                            [style.background]="'#00d07a'">
                        {{ m['Status'] }}
                      </span>

                      <span class="list-label pull-right" *ngIf="m['Status'] && m['Status'] != 'Active'"
                            [style.background]="'#ff5722'">
                        {{ m['Status'] }}
                      </span>
                      {{ m['TradingAs'] || m['CalculatedName'] }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <button class="pull-right merchBtn" *ngIf="merchantID && merchantID != 'none'" (click)="viewMerchant()"
                        mat-raised-button color="primary">
                  <mat-icon>person</mat-icon>
                  <span class="mobHide">View Merchant</span>
                </button>
              </div>
            </div>

            <div class="row clearfix" *ngIf="hasProductGroup == true">
              <!-- Finance product group -->
              <div class="selector-row row clearfix">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Choose product group</mat-label>
                  <mat-select class="full-width" [(ngModel)]="selectedGroup"
                              (selectionChange)="getMaxLoanAmount(selectedGroup)" placeholder="" name="productGroup"
                              required>
                    <mat-option *ngFor="let p of financedProductGroup" [value]="p['GroupCode']">
                     {{p['GroupLabel']}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <!-- Membership product group -->
              <!-- <div class="selector-row row clearfix mb" *ngIf="isModuleMembershipActive==true && productType=='Membership'">
                <mat-select placeholder="Choose product group" name="productGroup" [(ngModel)]="selectedGroup">
                  <mat-option *ngFor="let p of membershipProductGroup" [value]="p['Group.Code']">
                    {{p['Group.Label']}}
                  </mat-option>
                </mat-select>
              </div> -->
            </div>
          </div>

          <div class="selector-row row clearfix mb">
            <app-amount-slider-input [maxAmount]="maxLoanAmount" (selectAmount)="selectAmount($event)">
            </app-amount-slider-input>
          </div>
          <hr class="mb"/>
          <div class="clearfix row">
            <mat-checkbox [(ngModel)]="depositInc" name="keepingFee">Customer Contribution
            </mat-checkbox>
            <!-- show if deposit required -->
            <mat-form-field class="full-width animated fadeInDown mt" *ngIf="depositInc == true" appearance="outline">
              <mat-label>Enter Contribution</mat-label>

              <input class="mat-block" [(ngModel)]="patientContribution" name="contribution" matInput value=""
                     placeholder="Enter Contribution"/>
            </mat-form-field>
          </div>
          <div class="clearfix row text-center mt">
            <!-- <button class="full-back btn-large" mat-raised-button color="primary" (click)="cancel()">
            <mat-icon>keyboard_arrow_left</mat-icon> Go Back</button> -->
            <!-- <button mat-raised-button class="btn-large" color="accent" [disabled]="!createForm.form.valid || amount == 0 || patientContribution== 0 || patientContribution > (amount/2)" (click)="financeCalculate() ">Show Options</button> -->
            <button class="btn-large" [disabled]="!createForm.form.valid || amount == 0 || selectedGroup == null"
                    (click)="getSuibaleProduct()" mat-raised-button color="accent">
              Show Options
            </button>
          </div>
        </form>
      </div>
    </div>
    <div class="back">
      <mat-card-header class="text-center primary-gradient-img">
        <div class="abs-right">
          <button class="btn-clear pull-right" (click)="flipClose()" mat-button>
            <mat-icon>reply</mat-icon>
          </button>
        </div>
        <h1 class="sr-title sm-h1 rm-m">
          {{ "KEYWORD.treatment" | translate | titlecase }} Value <strong>{{ amount | customCurrency }}</strong>
        </h1>
        <label class="mb">Select a product to view</label>
      </mat-card-header>
      <mat-dialog-content class="respect-min" *ngIf="showOption == true">
        <div class="card-content">
          <!-- SHOW SCROLLING CHAMBER OF PRODUCTS -->
          <div [hidden]="hideProductList">
            <app-suitable-product-compact-list class="card-content-product-compact-list" [frequency]="'1'"
                                               [displaySimulator]="true" [amount]="suitableProductAmount"
                                               [isModal]="false" [groupCode]="selectedGroup"
                                               [merchantKey]="merchantID" (select)="selectProduct($event)"
                                               (closeFinanceCalculator)="closeFinanceCalculator($event)"></app-suitable-product-compact-list>
          </div>
          <!-- <hr *ngIf="selectedProduct"   class="mb"> -->

          <div [hidden]="!hideProductList">
            <app-product-calculator class="merchant-calculator" *ngIf="selectedProduct" [@fadeInOut] [moreInfo]="true"
                                    [dentalInfo]="true" [retailInfo]="false" [ProductID]="selectedProduct"
                                    [fixedAmount]="suitableProductAmount" [patientContribution]="patientContribution"
                                    [isTop]="true"
                                    [displayTerms]="false">
            </app-product-calculator>
          </div>
        </div>
      </mat-dialog-content>
      <hr *ngIf="displayEstimation"/>
      <div class="button-row clearfix text-center mt" [hidden]="!hideProductList">
        <button [@fadeInOut] (click)="showProductsAgain()" mat-raised-button color="warn">
          <mat-icon>view_carousel</mat-icon> Select Product
        </button>
        <button class="" *ngIf="false" mat-raised-button color="accent">
          <mat-icon>email</mat-icon>
          Send to {{ "KEYWORD.patient" | translate }}!
        </button>
        <button class="" (click)="flipClose()" mat-raised-button color="primary" matDialogClose>
          Close
        </button>
      </div>
      <div class="button-row clearfix text-center mt" [hidden]="hideProductList">
        <button class="" *ngIf="false" mat-raised-button color="accent">
          <mat-icon>email</mat-icon>
          Send to {{ "KEYWORD.patient" | translate }}!
        </button>
        <button class="" (click)="flipClose()" mat-raised-button color="primary" matDialogClose>
          Close
        </button>
      </div>
    </div>
  </mat-card>
</div>
