import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { DrugLookupComponent } from '../drug-lookup/drug-lookup.component';
import { DrugsViewComponent } from '../drugs-view/drugs-view.component';
import { MedicationCreateCustomerComponent } from '../medication-create-customer/medication-create-customer.component';
import { MedicationViewComponent } from '../medication-view/medication-view.component';
import { DrugsService } from '../shared/drugs.service';

@Component({
  selector: 'app-medication-list',
  templateUrl: './medication-list.component.html',
  styleUrls: ['./medication-list.component.css'],
})
export class MedicationListComponent implements OnInit {
  @Input()
  title = 'Are you taking any medication for it?';

  @Input()
  illnessID;

  @Input()
  allergyID;

  @Input()
  canEdit = true;

  @Input()
  canCreate = true;

  takeDrug = null;
  @Input()
  showStopDate = true;

  @Input()
  showStartDate = true;
  @Input()
  canAction = false;

  @Input()
  illnessCode;

  @Input()
  allergyCode;

  @Input('startDate')
  _startDate;

  startDate = null;

  @Input('stopDate')
  _stopDate;
  stopDate = null;

  @Input()
  canYesNo = true;
  @Input()
  canView = false;
  @Output()
  getMedications = new EventEmitter();

  @Output()
  getMedicationExist = new EventEmitter();

  @Output()
  getNewMedications = new EventEmitter();

  @Input('medications')
  _medications = [];
  medications = [];
  notInList = false;

  sessionType;

  constructor(private authenticationService: AuthenticationService, private drugsService: DrugsService) {}

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((res) => {
      this.sessionType = res;

      if (this._startDate && this._startDate != null) {
        this.startDate = this._startDate;
      } else {
        this.startDate = null;
      }

      if (this._stopDate && this._stopDate != null) {
        this.stopDate = this._stopDate;
      } else {
        this.stopDate = null;
      }

      this.setup();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this._startDate && this._startDate != null) {
      this.startDate = this._startDate;
    } else {
      this.startDate = null;
    }

    if (this._stopDate && this._stopDate != null) {
      this.stopDate = this._stopDate;
    } else {
      this.stopDate = null;
    }

    if (
      (changes.illnessID && changes.illnessID.previousValue && !changes.illnessID.currentValue) ||
      (changes.allergyID && changes.allergyID.previousValue && !changes.allergyID.currentValue)
    ) {
      this.setup();
    }
  }

  setup() {
    if (this._medications && this._medications.length > 0) {
      this.medications = this._medications;
    } else if ((this.illnessID && this.illnessID != 'undefined') || (this.allergyID && this.allergyID != 'undefined')) {
      const p = {
        illnessID: this.illnessID,
        allergyID: this.allergyID,
        fields: 'Drug.Name,Drug_key,ID',
      };
      this.drugsService.getMedicationList(p, this.sessionType).subscribe((res) => {
        if (res && res.length > 0) {
          this.medications = res;
        }

        this.sendResult();
      });
    }
  }

  viewDrug(id) {
    const ref = RootAppComponent.dialog.open(DrugsViewComponent, {
      data: {
        drugID: id,
      },

      width: '800px',
    });

    ref.componentInstance.closeModal.subscribe((res) => {
      ref.close();
    });
  }

  view(id) {
    const ref = RootAppComponent.dialog.open(MedicationViewComponent, {
      data: {
        medicationID: id,
      },

      width: '800px',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  addMedicationModal(drugID = null) {
    let isCreate = false;
    if (this.illnessID) {
      isCreate = true;
    } else if (this.allergyID) {
      isCreate = true;
    }

    const ref = RootAppComponent.dialog.open(MedicationCreateCustomerComponent, {
      data: {
        isCreate,
        drugID,
        allergyID: this.allergyID,
        illnessID: this.illnessID,
        startDate: this.startDate,
        stopDate: this.stopDate,
        showStartDate: this.showStartDate,
        showStopDate: this.showStopDate,
      },
      panelClass: 'noCard',
      width: '800px',
    });

    ref.componentInstance.getMedication.subscribe((res) => {
      if (res) {
        this.medications.push(res);
        this.sendResult();
        ref.close();
      }
    });

    ref.componentInstance.getLocalMedication.subscribe((res) => {
      if (res) {
        this.medications.push(res);
        this.sendResult();
        ref.close();
      }
    });
    ref.componentInstance.close.subscribe((res) => {
      ref.close();
      this.takeDrug = false;
    });
    ref.backdropClick().subscribe((res) => {
      ref.close();
      this.takeDrug = false;
    });
  }

  addMedication() {
    if (this.notInList != true && (this.illnessCode || this.allergyCode)) {
      const payload = {
        illnessCode: this.illnessCode,
        isActive: true,
        allergyCode: this.allergyCode,
        fields:
          'Name,Description,DoseUnit.Code,DoseUnit.Label,DrugType.Code,DrugType.Label,DoseType.Code,DoseType.Label,GeneralInformation,ID',
      };
      this.drugsService.getDrugList(payload, this.sessionType).subscribe((res) => {
        if (res) {
          if (res && res.length > 0) {
            const ref = RootAppComponent.dialog.open(DrugLookupComponent, {
              data: {
                drugs: res,
                noLabel: 'Not in the list',
                noButton: true,
              },
              width: '800px',
            });

            ref.componentInstance.getDrug.subscribe((drug) => {
              if (drug && drug.ID) {
                ref.close();
                ref.afterClosed().subscribe((r) => {
                  this.addMedicationModal(drug.ID);
                });
              } else {
                this.addMedicationModal();
              }
            });

            ref.componentInstance.getNo.subscribe((res) => {
              ref.close();
              ref.afterClosed().subscribe((r) => {
                this.addMedicationModal();
              });
            });

            ref.componentInstance.close.subscribe((res) => {
              ref.close();
              this.takeDrug = false;
            });
            ref.backdropClick().subscribe((res) => {
              ref.close();
              this.takeDrug = false;
            });
          } else {
            this.notInList = true;
            this.addMedicationModal();
          }
        }
      });
    } else {
      this.addMedicationModal();
    }
  }

  remove(m, index) {
    const confirmDialog = new ConfirmDialog(
      'delete',
      'Are you sure ?',
      'Do you want to remove this medication',
      'Cancel',
      'Yes'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
      // SET TO PAYING CASH CARD
    });
    ref.componentInstance.onConfirm.subscribe((val) => {
      if (val == true) {
        if (m && m.ID) {
          this.drugsService.deleteMedicationDetails(m.ID, this.sessionType).subscribe((r) => {
            if (r) {
              this.medications.splice(index, 1);
              this.sendResult();

              ref.close();
            }
          });
        } else if (m) {
          this.medications.splice(index, 1);
          this.sendResult();

          ref.close();
        }
      } else {
        ref.close();
      }
    });
  }

  sendResult() {
    this.getMedications.emit(this.medications);
    let newMedication = [];

    if (this.medications && this.medications.length > 0) {
      newMedication = _.filter(this.medications, (item) => {
        if (item && item['name'] && !item['ID']) {
          return true;
        }

        return false;
      });
    }

    if (newMedication && newMedication.length > 0) {
      for (let i = 0; i < newMedication.length; i++) {
        newMedication[i] = this.buildPayload(newMedication[i]);
      }
    }

    this.getNewMedications.emit(newMedication);

    if (this.medications && this.medications.length > 0) {
      this.getMedicationExist.emit(true);
    } else {
      this.getMedicationExist.emit(false);
    }
  }

  edit(m, index) {
    if (m && m.ID) {
      const ref = RootAppComponent.dialog.open(MedicationCreateCustomerComponent, {
        data: {
          isCreated: true,
          medicationID: m.ID,

          showStartDate: this.showStartDate,
          showStopDate: this.showStopDate,
        },
        panelClass: 'noCard',
        width: '800px',
      });

      ref.componentInstance.getMedication.subscribe((res) => {
        if (res) {
          this.medications[index] = res;
          this.sendResult();
          ref.close();
        }
      });
      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    } else if (m) {
      const ref = RootAppComponent.dialog.open(MedicationCreateCustomerComponent, {
        data: {
          isCreated: false,
          drugLocal: m,

          showStartDate: this.showStartDate,
          showStopDate: this.showStopDate,
        },
        panelClass: 'noCard',
        width: '800px',
      });

      ref.componentInstance.getLocalMedication.subscribe((res) => {
        if (res) {
          this.medications[index] = res;
          this.sendResult();
          ref.close();
        }
      });
      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    }
  }

  buildPayload(drug) {
    const medication: any = {};
    if (drug) {
      if (drug.drugID) {
        medication.drugID = drug.drugID;
      }

      if (drug.name) {
        medication.name = drug.name;
      }
      if (drug.note) {
        medication.note = drug.note;
      }

      if (drug.doseAmount) {
        medication.doseAmount = drug.doseAmount;
      } else {
        medication.doseAmount = 'void';
      }

      if (drug.startDate) {
        medication.startDate = drug.startDate;
      } else {
        medication.startDate = 'void';
      }

      if (drug.stopDate) {
        medication.stopDate = drug.stopDate;
      } else {
        medication.stopDate = 'void';
      }

      if (drug.drugType) {
        if (drug.drugType == 'other' && drug.drugTypeOther) {
          medication.drugTypeOther = drug.drugTypeOther;
          medication.drugType = 'void';
        } else if (drug.drugType == 'other') {
          medication.drugType = 'void';
        } else {
          medication.drugType = drug.drugType;
        }
      }

      if (drug.doseType) {
        if (drug.doseType == 'other' && drug.doseTypeOther) {
          medication.doseTypeOther = drug.doseTypeOther;
          medication.doseType = 'void';
        } else if (drug.doseType == 'other') {
          medication.doseType = 'void';
        } else {
          medication.doseType = drug.doseType;
        }
      }

      if (drug.doseUnit) {
        if (drug.doseUnit == 'other' && drug.doseUnitOther) {
          medication.doseUnitOther = drug.doseUnitOther;
          medication.doseUnit = 'void';
        } else if (drug.doseUnit == 'other') {
          medication.doseUnit = 'void';
        } else {
          medication.doseUnit = drug.doseUnit;
        }
      }

      if (drug.drugFrequency) {
        if (drug.drugFrequency == 'other' && drug.drugFrequencyOther) {
          medication.drugFrequencyOther = drug.drugFrequencyOther;
          medication.drugFrequency = 'void';
        } else if (drug.drugFrequency == 'other') {
          medication.drugFrequency = 'void';
        } else {
          medication.drugFrequency = drug.drugFrequency;
        }
      }

      if (this.allergyID) {
        medication.allergyID = this.allergyID;
      } else if (this.illnessID) {
        medication.illnessID = this.illnessID;
      }
    }

    return medication;
  }
}
