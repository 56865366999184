import { Component, EventEmitter, Inject, Input, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-code-html-edit',
  templateUrl: './html-edit.component.html',
  styleUrls: ['./html-edit.component.css'],
})
export class CodeHTMLEditComponent implements OnInit {
  @Input()
  isCSS = false;

  editorOptions = {
    fontSize: 12,
    formatOnType: true,
    formatOnPaste: true,
    language: 'html',
    autoIndent: true,
    autoClosingBrackets: 'always',
    dragAndDrop: true,
    fixedOverflowWidgets: true,
    theme: 'vs-dark',
  };
  editorOptionsCss = {
    fontSize: 12,
    formatOnType: true,
    formatOnPaste: true,
    language: 'css',
    autoIndent: true,
    autoClosingBrackets: 'always',
    dragAndDrop: true,
    fixedOverflowWidgets: true,
    theme: 'vs-dark',
  };

  @Input()
  icon = 'fas fa-code';
  @Input()
  title = 'Insert or Edit  Source Code';
  @Input()
  description;

  @Input()
  content = '';

  getResult = new EventEmitter();

  close = new EventEmitter();

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.icon) {
        this.icon = data.icon;
      }
      if (data.content) {
        this.content = data.content;
      }

      if (data.title) {
        this.title = data.title;
      }
      if (data.description) {
        this.description = data.description;
      }
      if (data.isCSS != null) {
        this.isCSS = data.isCSS;
      }
    }
  }

  ngOnInit() {}

  closeEvent() {
    this.close.emit(true);
  }

  sendResult() {
    this.getResult.emit(this.content);
  }

  isFontAwsome(icon) {
    if (icon) {
      if (icon.indexOf('fas ') !== -1 || icon.indexOf('fab-') !== -1) {
        return true;
      }

      return false;
    }

    return false;
  }
}
