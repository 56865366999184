import { Store } from '@ngrx/store';
import { environment } from 'environments/environment';
import { setCurrentPublicSettingsAction } from '../../core/app-state/app-state.actions';
import { Settings } from '../types/settings';
import { IndustrySettingsService } from './industry-settings.service';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "./industry-settings.service";
export class ServiceFactoryService {
    constructor(store, industrySettingsService) {
        this.store = store;
        this.industrySettingsService = industrySettingsService;
        this.getSettings = () => {
            return new Promise((resolve) => {
                const xhr = new XMLHttpRequest();
                xhr.open('GET', environment.nodeUrl + '/settings/public-hidden');
                xhr.onload = () => {
                    if (xhr.status >= 200 && xhr.status < 300) {
                        let data;
                        let result = null;
                        if (xhr && xhr.response) {
                            data = JSON.parse(xhr.response);
                        }
                        if (data && data['data'] && data['data'][0]) {
                            Settings.global = data['data'][0];
                            result = data['data'][0];
                        }
                        resolve(result);
                    }
                    else {
                        resolve(null);
                    }
                };
                xhr.onerror = () => {
                    resolve(null);
                };
                xhr.send();
            }).then((result) => {
                this.industrySettingsService
                    .fetchIndustrySettings$()
                    .toPromise()
                    .then((industrySettings) => {
                    industrySettings.industryType = 'retail';
                    industrySettings = {
                        industryProfileTable: industrySettings.industryProfileTable
                            ? industrySettings.industryProfileTable
                            : 'none',
                        industryType: industrySettings.industryType ? industrySettings.industryType : 'none',
                    };
                    const publicSettings = Object.assign(this.removeIsModulePropertiesFromObject(result), industrySettings);
                    this.store.dispatch(setCurrentPublicSettingsAction({ publicSettings }));
                });
            });
        };
    }
    extractIsModulePropertiesFromObject(object) {
        if (object) {
            return Object.keys(object)
                .filter((key) => key.includes('isModule'))
                .reduce((current, key) => Object.assign(current, { [key]: object[key] }), {});
        }
        else {
            return {};
        }
    }
    removeIsModulePropertiesFromObject(object) {
        if (object) {
            return Object.keys(object)
                .filter((key) => !key.includes('isModule'))
                .reduce((current, key) => Object.assign(current, { [key]: object[key] }), {});
        }
        else {
            return {};
        }
    }
}
ServiceFactoryService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ServiceFactoryService_Factory() { return new ServiceFactoryService(i0.ɵɵinject(i1.Store), i0.ɵɵinject(i2.IndustrySettingsService)); }, token: ServiceFactoryService, providedIn: "root" });
