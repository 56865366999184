<div class="row clearfix actList indOvf" *ngIf="actionLogs">
  <perfect-scrollbar>
    <!-- IF NO MODAL -->
    <div class="activ-list" *ngIf="emptyList != true && isModal == false" [@stagger]="actionLogs.length">
      <div
        class="activ-item mat-elevation-z1"
        *ngFor="let c of actionLogs; let i = index"
        [@fade]
        [ngClass]="c['Subject']"
        [ngStyle]="{ 'border-color': actionLogUtil.getSubjectColor(c['Action'], c['Subject']) }"
      >
        <div class="activ-content">
          <div class="row clearfix titlebar">
            <mat-icon
              class="pull-left fa"
              [ngStyle]="{ color: actionLogUtil.getSubjectColor(c['Action'], c['Subject']) }"
              [ngClass]="actionLogUtil.getSubjectIcon(c['Action'], c['Subject'])"
            ></mat-icon>
            <h4 class="rm-m pull-left" [ngStyle]="{ color: actionLogUtil.getSubjectColor(c['Action'], c['Subject']) }">
              {{ c['Who.Name'] }} - {{ c['Subject'] }}

              <span class="dateTime" [ngStyle]="{ color: actionLogUtil.getSubjectColor(c['Action'], c['Subject']) }">
                {{ dateFilter(c['DateTimeCreated']) }}, {{ timeFilter(c['DateTimeCreated']) }}
              </span>
            </h4>
          </div>

          <div class="row clearfix controls">
            <button class="full-width" (click)="viewLog(c['ID'])" mat-button>
              <mat-icon>visibility_on</mat-icon>
              View details
            </button>
            <button
              class="full-width"
              *ngIf="c['What.key']"
              (click)="showInvitationDetails(c['Who.key'], c['What.key'], c['What.Table'])"
              mat-button
            >
              Go to event
              <mat-icon>chevron_right</mat-icon>
            </button>
          </div>
        </div>
        <span
          class="background"
          [ngStyle]="{ 'background-color': actionLogUtil.getSubjectColor(c['Action'], c['Subject']) }"
        ></span>
      </div>
    </div>

    <app-empty-list-message
      class="full-width row clearfix"
      *ngIf="emptyList == true"
      [title]="''"
      [message]="'No recent activity-log'"
    ></app-empty-list-message>
  </perfect-scrollbar>
</div>
