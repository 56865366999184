import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { InvitationIntroModalComponent } from '../../invitation/invitation-intro-modal/invitation-intro-modal.component';
import { InvitationLookupComponent } from '../../invitation/invitation-lookup/invitation-lookup.component';
import { InvitationService } from '../../invitation/shared/services/invitation.service';
import { SmsConversationComponent } from '../../message/sms-conversation/sms-conversation.component';
import { SmsPromoterComponent } from '../../message/sms-promoter/sms-promoter.component';

@Component({
  selector: 'app-contract-overview',
  templateUrl: './contract-overview.component.html',
  styleUrls: ['./contract-overview.component.css'],
})
export class ContractOverviewComponent implements OnInit {
  chart1type = 2;
  chart2type = 2;
  chart3type = 2;

  statistics: any;

  pracInvitationChart = {
    title: 'Invitation Value',
    subtitle: 'Value generated this contract period',
    totalsub: 'Total Fees Generated',
    lastUpdate: new Date(),
    labels: [],
    data: [],
    cutoutPercentage: 70,
    isCurrency: 0,
  };

  currBillingChart = {
    title: 'This contract period',
    subtitle: 'Contracts from this week',
    totalsub: 'Total Fees Generated',
    lastUpdate: new Date(),
    labels: [],
    data: [],
    cutoutPercentage: 70,
    isCurrency: 1,
  };
  lastBillingChart = {
    title: 'Last contract period',
    subtitle: 'Contracts from the previous week',
    totalsub: 'Total Fees Generated',
    lastUpdate: new Date(),
    labels: [],
    data: [],
    cutoutPercentage: 70,
    isCurrency: 1,
  };

  yearToDateChart = {
    title: 'Year to date',
    subtitle: 'Total Contracts for the year',
    totalsub: 'Fees Generated This Year',
    lastUpdate: new Date(),
    labels: ['Net Sebbled', 'Charges Abbociated', 'Chabebacks'],
    data: [8500, 1350, 4000],
    cutoutPercentage: 70,
    isCurrency: 1,
  };

  reqSetChart = {
    title: 'Current Requests',
    subtitle: 'Number of Requests',
    totalsub: 'Requests this cycle',
    lastUpdate: new Date(),
    labels: ['Draft', 'Requested', 'Approved', 'Declined', 'Canceled'],
    data: [24, 8, 25, 4, 5],
    cutoutPercentage: 70,
    isCurrency: 0,
  };

  reqLastWkSetChart = {
    title: 'Previous Requests',
    subtitle: 'Number of Requests',
    totalsub: 'Requests last cycle',
    lastUpdate: new Date(),
    labels: ['Draft', 'Requested', 'Approved', 'Declined', 'Canceled'],
    data: [31, 7, 22, 8, 9],
    cutoutPercentage: 70,
    isCurrency: 0,
  };

  reqTotalSetChart = {
    title: 'Requests YTD',
    subtitle: 'Number of Requests',
    totalsub: 'Requests this year',
    lastUpdate: new Date(),
    labels: ['Draft', 'Requested', 'Approved', 'Declined', 'Canceled'],
    data: [884, 98, 125, 89, 120],
    cutoutPercentage: 70,
    isCurrency: 0,
  };

  constructor(private invitationService: InvitationService, private dialog: MatDialog) {}

  openPatientLookUpDialog() {
    const ref = this.dialog.open(InvitationLookupComponent, {
      data: {
        useStrict: true,
      },
      width: '600px',
    });
    const sub = ref.componentInstance.close.subscribe((data) => {
      ref.close();
    });
    ref.componentInstance.detailedView.subscribe((res) => {
      ref.close();
    });

    ref.componentInstance.patientChat.subscribe((res) => {
      if (res && res.cardID) {
        if (res.isPromoterOrAdmin == true) {
          const ref2 = RootAppComponent.dialog.open(SmsPromoterComponent, {
            data: {
              merchantID: res.merchantID,
              cardID: res.cardID,
              singleChat: true,
            },
            panelClass: 'noCard',
            width: '500px',
          });
          ref2.componentInstance.close.subscribe((data) => {
            ref2.close();
          });

          ref2.componentInstance.getResult.subscribe((data) => {
            if (data) {
              const ref3 = RootAppComponent.dialog.open(SmsConversationComponent, {
                data,
                panelClass: 'bigger-screen',
                width: '70%',
                height: '95vh',
              });
              const sub = ref3.componentInstance.close.subscribe((data) => {
                ref3.close();
              });
            }

            ref2.close();
          });
        } else {
          const ref2 = RootAppComponent.dialog.open(SmsConversationComponent, {
            data: {
              merchantID: res.merchantID,
              cardID: res.cardID,
              singleChat: true,
            },
            panelClass: 'bigger-screen',
            width: '70%',
            height: '95vh',
          });
          const sub = ref2.componentInstance.close.subscribe((data) => {
            ref2.close();
          });
        }
      }
    });
  }

  ngOnInit() {
    const ref = this.dialog.open(InvitationIntroModalComponent, {
      width: '800px',
      panelClass: 'noCard',
    });
    if (ref.componentInstance.showOnInvitationFirstVisit == true) {
      ref.close();
    } else {
      const sub = ref.componentInstance.closeModal.subscribe((data) => {
        ref.close();
      });
      // const tour = ref.componentInstance.startTour.subscribe(data => {
      //   // startTour();

      //   ref.close();
      //   this.startTour();
      // });
    }

    this.invitationService.getInvStatistics().subscribe((res) => {
      this.statistics = res.data.statistics;
      // init the statistic card
      // this.activePlansCard.value = this.statistics.active;
      // this.newlyActiveCard.value = this.statistics.new;
      // this.pendingInvCard.value = this.statistics.pending;
      // this.financingCardTitle.value = this.statistics.financing;
      // this.invSentCardTitle.value = this.statistics.sent;
      // this.suspendedTreatCard.value = this.statistics.suspended;
      // Initialize the chart

      // Need to show Invites sent/voided for prac invitation chart
      this.pracInvitationChart.labels = res.data.billing.current.labels;
      this.pracInvitationChart.data = res.data.billing.current.data;
      this.pracInvitationChart.lastUpdate = res.data.billing.current.lastUpdated;

      this.currBillingChart.labels = res.data.billing.current.labels;
      this.currBillingChart.data = res.data.billing.current.data;
      this.currBillingChart.lastUpdate = res.data.billing.current.lastUpdated;

      this.lastBillingChart.labels = res.data.billing.last.labels;
      this.lastBillingChart.data = res.data.billing.last.data;
      this.lastBillingChart.lastUpdate = res.data.billing.last.lastUpdated;

      // this.nextBillingChart.labels = res.data.billing.next.labels;
      // this.nextBillingChart.data = res.data.billing.next.data;
      // this.nextBillingChart.lastUpdate = res.data.billing.next.lastUpdated;

      // this.yearToDateChart.labels = res.data.billing.yearly.labels;
      // this.yearToDateChart.data = res.data.billing.yearly.data;
      this.yearToDateChart.lastUpdate = res.data.billing.yearly.lastUpdated;
    });
  }
}
