var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { EventEmitter, OnChanges, OnInit } from '@angular/core';
import * as moment from 'moment';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { CustomDatePipe } from '../../../shared/pipes/custom-date.pipe';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { AppointmentService } from '../shared/appointment.service';
import { AppointmentUtilClass } from '../shared/appointmentUtil';
/**
 * This Component is for consumer or guest only
 */
export class AppointmentSyncCalendarCardComponent {
    constructor(authenticationService, appointmentService, customDate) {
        this.authenticationService = authenticationService;
        this.appointmentService = appointmentService;
        this.customDate = customDate;
        this.sessionType = 'guest';
        this.appointmentUtil = new AppointmentUtilClass();
        this.success = new EventEmitter();
    }
    ngOnInit() {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.authenticationService.getSessionType().toPromise();
            if (res) {
                this.sessionType = res;
            }
            this.calEvent = this.appointmentUtil.buildCalendarEvent(this.appointment);
        });
    }
    ngOnChanges() {
        this.calEvent = this.appointmentUtil.buildCalendarEvent(this.appointment);
    }
    displaySync() {
        return (this.appointment &&
            moment(this.customDate.transform(this.appointment.Date, 'YYYY-MM-DD'), 'YYYY-MM-DD').toDate() > new Date() &&
            (this.appointment['Status.Code'] === 'WAITINGCONFIRMATION' ||
                this.appointment['Status.Code'] === 'BOOKED' ||
                this.appointment['Status.Code'] === 'REQUESTRESCHEDULE' ||
                this.appointment['Status.Code'] === 'REQUESTDATECHANGE'));
    }
    calendarSynced(data) {
        if (data.id) {
            const payload = {};
            const key = `${data.calendar}CalendarConsumerKey`;
            payload[key] = data.id;
            this.appointmentService.editAppointment(this.appointment.ID, payload, this.sessionType).subscribe((res) => {
                this.success.emit(res);
                let msg = 'Your appointment is now synced to your calendar of choosing';
                if (data.calendar === "apple" /* apple */) {
                    msg = 'Please execute the downloaded ics file to your apple device';
                }
                NotifyAppComponent.displayToast('success', 'Calendar Sync', msg);
            });
        }
        else {
            NotifyAppComponent.displayToast('error', 'Calendar Sync', 'Your appointment is not synced to your calendar of choosing');
        }
    }
}
