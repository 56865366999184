import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { SessionType } from '../../../shared/types/session-type.type';
import { DentistService } from '../shared/dentist.service';

@Component({
  selector: 'app-dentist-view-modal',
  templateUrl: './dentist-view-modal.component.html',
  styleUrls: ['./dentist-view-modal.component.css'],
  animations: [
    trigger('onChangeAnimation', [
      transition('void => *', [
        query('.animate', style({ opacity: 0 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.15s ease-in-out',
              keyframes([
                style({ opacity: 0, offset: 0, height: 0, margin: 0 }),
                style({ opacity: 1, offset: 1.0, height: '*', margin: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.animate', style({ opacity: 1 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.15s ease-in-out',
              keyframes([
                style({ opacity: 1, offset: 0, height: '*', margin: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, offset: 1.0, height: 0, margin: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class DentistViewModalComponent implements OnInit {
  profileLink;

  displayMapFlag = true;

  @Output() close = new EventEmitter();

  @Input() dentist;

  sessionType: SessionType = 'guest';

  constructor(
    private dentistService: DentistService,
    private authenticationService: AuthenticationService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      this.dentist = data;
    }
  }

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((sessionType) => {
      if (sessionType) {
        this.sessionType = sessionType;
      }
      if (this.dentist) {
        this.dentistService.getContactDetails(this.dentist, {}, this.sessionType).subscribe((res) => {
          this.dentist = res;
        });

        this.getProfilePicture();
      }
    });
  }

  notFoundEvent(e) {
    if (e == null) {
      this.displayMapFlag = false;
    } else {
      this.displayMapFlag = !e;
    }
  }

  openSocialLink(link) {
    // check if link has http or https and add it if not
    let url = '';
    if (!/^http[s]?:\/\//.test(link)) {
      url += 'https://';
    }
    url += link;
    window.open(url, '_blank', 'noopener');
  }

  getProfilePicture() {
    this.profileLink = null;

    this.profileLink = this.dentistService.getProfilePicStreamLink(this.dentist);
  }

  closeEvent() {
    this.close.emit(true);
  }
}
