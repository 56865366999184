import { Component, EventEmitter, Inject, Input, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { Settings } from '../../../shared/types/settings';
import { MerchantService } from '../shared/merchant.service';

@Component({
  selector: 'app-merchant-settlement',
  templateUrl: './merchant-settlement.component.html',
  styleUrls: ['./merchant-settlement.component.css'],
})
export class MerchantSettlementComponent implements OnInit {
  @Input()
  merchantID;

  close = new EventEmitter();

  getResult = new EventEmitter();

  sessionType;

  merchant;

  settings = Settings.global;

  materialRequestActive = Settings.global['materialRequestActive'];
  materialRequestPercent = Settings.global['materialRequestPercent'];

  materialRequestActiveDefault = Settings.global['materialRequestActive'];
  materialRequestPercentDefault = Settings.global['materialRequestPercent'];

  constructor(
    private authenticationService: AuthenticationService,
    private merchantService: MerchantService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.merchantID) {
        this.merchantID = data.merchantID;
      }
    }
  }

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((res) => {
      this.sessionType = res;

      this.setup();
    });
  }

  setup() {
    if (this.merchantID) {
      this.authenticationService
        .getMerchantDetails(
          this.merchantID,
          { fields: 'ID,Disable_Matrial_Request,Percent_Stlmnt_Mtrls_Request' },
          this.sessionType
        )
        .subscribe((res) => {
          if (res) {
            this.merchant = res;

            if (this.merchant['Disable_Matrial_Request'] === '1') {
              this.materialRequestActive = false;
            } else {
              this.materialRequestActive = true;
            }

            if (this.merchant['Percent_Stlmnt_Mtrls_Request']) {
              this.materialRequestPercent = Number(this.merchant['Percent_Stlmnt_Mtrls_Request'] || 0);
            }
          }
        });
    }
  }

  save() {
    let p = {
      materialRequestActive: this.materialRequestActive,
      materialRequestPercent: this.materialRequestPercent,
    };

    if (this.merchant && this.merchant.ID) {
      this.merchantService.editMerchant(this.merchant.ID, p).subscribe((r) => {
        if (r && r.ID) {
          this.getResult.emit(r);
        }
      });
    } else {
      this.getResult.emit(p);
    }
  }
  closeEvent() {
    this.close.emit(true);
  }
}
