import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-help-topics-sub-contents',
  templateUrl: './help-topics-sub-contents.component.html',
  styleUrls: ['./help-topics-sub-contents.component.css'],
})
export class HelpTopicsSubContentsComponent implements OnInit {
  @Input()
  label;

  @Output()
  showPageContent = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  help() {
    this.showPageContent.emit(true);
  }
}
