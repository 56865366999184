import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { SessionsService } from '../../../core/session/shared/sessions.service';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { MerchantService } from '../shared/merchant.service';

@Component({
  selector: 'app-merchant-welcome-card',
  templateUrl: './merchant-welcome-card.component.html',
  styleUrls: ['./merchant-welcome-card.component.css'],
})
export class MerchantWelcomeCardComponent implements OnInit {
  @Input()
  isSession = true;

  @Input()
  merchantID;

  settings: any = {};
  merchant: any = {};
  util = new UtilsClass();
  tradingAs;
  profileLink;

  sessionType = 'guest';

  constructor(
    private SessionsService: SessionsService,
    private authenticationService: AuthenticationService,
    private merchantService: MerchantService
  ) {}

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((sessionType) => {
      if (sessionType) {
        this.sessionType = sessionType;
      }

      this.getWelcomeMsg();
    });
  }

  getWelcomeMsg() {
    if (this.isSession == true) {
      this.authenticationService.getCurrentUser().subscribe(
        (res: any) => {
          this.merchant.calculatedName = res.data.CalculatedName;
          this.merchant.lastLogin = res.data.last_login;
          this.merchant.lastCheck = res.data.last_check;
          this.tradingAs = res.data['TradingAs'];

          this.profileLink = null;
          this.profileLink = this.authenticationService.getProfilePicStreamLink();
        },
        (err: any) => {}
      );
      // this.merchantService.getSettings().subscribe(
      //   (res: any) => {
      //     this.settings = res.data;
      //   },
      //   (err: any) => {

      //   });
    } else {
      if (this.merchantID) {
        this.merchantService.getMerchantDetails(this.merchantID, {}, this.sessionType).subscribe((res) => {
          if (res) {
            this.tradingAs = res['TradingAs'];
          }
        });
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.getWelcomeMsg();
  }
}
