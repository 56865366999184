<div class="row clearfix marketingFilter" [class.filterCard]="standalone == true">
  <div class="card-header primary-gradient-img clearfix inModal" *ngIf="standalone == true">
    <div class="row clearfix">
      <button class="btn-close btn-clear mat-button white" *ngIf="isModal == true" (click)="closeEvent()">
        <mat-icon>clear</mat-icon>
      </button>

      <div class="row titleArea clearfix">
        <h2 class="summary-header rm-m" *ngIf="isEdit == false">New Audience</h2>
        <h2 class="summary-header rm-m" *ngIf="isEdit == true">Edit Audience</h2>
      </div>
    </div>
  </div>

  <div class="row clearfix mb" *ngIf="isModal == false">
    <div class="row clearfix">
      <h2 class="sr-title rm-m mt pull-left" *ngIf="standalone == false">New Audience</h2>

      <!-- <h2 class="summary-header rm-m">Marketing Filter</h2> -->

      <!-- <div class="clearfix row mt"> -->

      <!-- <p class="help-label" > To send an invitation on behalf of a merchant select a merchant below, otherwise, the invitation will be sent by the promoter. </p> -->

      <div class="pull-right selectMerchant" *ngIf="isPromoterOrAdmin == true">
        <mat-form-field class="full-width centered" appearance="outline">
          <mat-label>Please Select a Merchant</mat-label>
          <mat-select class=" " [(ngModel)]="merchantID" name="MerchantName" placeholder="Please Select a Merchant">
            <mat-option [value]="null"> My Account</mat-option>
            <mat-option *ngFor="let m of merchants | orderBy: ['TradingAs', 'CalculatedName']" [value]="m['ID']">
              <span
                class="list-label pull-right"
                *ngIf="m['Status'] && m['Status'] == 'Active'"
                [style.background]="'#00d07a'"
              >
                {{ m['Status'] }}
              </span>

              <span
                class="list-label pull-right"
                *ngIf="m['Status'] && m['Status'] != 'Active'"
                [style.background]="'#ff5722'"
              >
                {{ m['Status'] }}
              </span>
              {{ m['TradingAs'] || m['CalculatedName'] }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <button
          class="pull-right merchBtn"
          *ngIf="merchantID && merchantID != 'none'"
          (click)="viewMerchant()"
          mat-raised-button
          color="primary"
        >
          <mat-icon>person</mat-icon>
          <span class="mobHide">View Merchant</span>
        </button>
      </div>
    </div>
  </div>

  <mat-dialog-content>
    <div class="grey-section">
      <p>Please enter a label for this audience and a short description for your reference</p>

      <div class="row clearfix flex mt">
        <mat-form-field class="thrd-width" appearance="outline">
          <mat-label>Label</mat-label>
          <input
            *ngIf="isEdit == false"
            [(ngModel)]="filterName"
            [readonly]="isEdit"
            matInput
            name="filterName"
            placeholder="Label"
            required
          />
          <input
            *ngIf="isEdit == true"
            [(ngModel)]="filterName"
            [readonly]="isEdit"
            matInput
            name="filterName"
            placeholder="Label is not editable"
            required
          />
        </mat-form-field>

        <mat-form-field class="two-thrd-width" appearance="outline">
          <mat-label>Description</mat-label>
          <input [(ngModel)]="filterDescription" matInput name="filterDescription" placeholder="Description"/>
        </mat-form-field>
      </div>

      <!-- <hr> -->
      <div class="row  full-width clearfix">


        <h3 class="sr-title pull-left  mr ">Global Audience setup</h3>
        <button
          class="pull-right add-filter-btn"
          (click)="addSection()"
          mat-stroked-button
          color="primary"
          matTooltip="Add another section"
        >
          Add Audience <mat-icon class=" sml fas fa-plus mat-primary"></mat-icon>
        </button>

        <div class="row clearfix text-center pull-right">
          <mat-form-field class="full-width filterconj" appearance="outline">
            <mat-label
            >Apply
              <strong *ngIf="sectionConjunction == 'and'">All</strong>
              <strong *ngIf="sectionConjunction == 'or'">Any</strong>
              globally
            </mat-label>
            <mat-select
              class="full-width"
              [(ngModel)]="sectionConjunction"
              (selectionChange)="getSectionConjunction($event.value)"
              name="sectionConjunction"
            >
              <mat-option [value]="'and'">All</mat-option>
              <mat-option [value]="'or'">Any</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
    <!-- <div class="row clearfix"> -->

    <div class="row clearfix">
      <mat-card class="card clearfix filter-card" *ngFor="let f of filterArray; let index = index">
        <div class="andOr" *ngIf="index != 0">
          <ng-container *ngIf="index != 0 && sectionConjunction == 'and'">
            <span class="and">And</span>
          </ng-container>
          <ng-container *ngIf="index != 0 && sectionConjunction == 'or'">
            <span class="or">Or</span>
          </ng-container>
        </div>

        <div class="row flex controls">
          <h3 class="sr-title rm-m text-left pull-left">Audience {{ index + 1 }}</h3>
        </div>
        <div class="row clearfix">
          <app-marketing-filter-create-section
            [isEdit]="isEdit"
            [filter]="f"
            [merchantID]="merchantID"
            (getFilter)="getFilter($event, index)"
            (getPropertyConjunction)="getPropertyConjunctionEvent($event, index)"
          >
          </app-marketing-filter-create-section>
        </div>

        <button class="btn-small rem" (click)="removeSection(index)" mat-stroked-button>
          Remove Audience section

          <mat-icon class="fas fa-trash mat-accent"></mat-icon>
        </button>
      </mat-card>
    </div>
  </mat-dialog-content>

  <div class="row clearfix text-center">
    <button
      class="pull-left"
      *ngIf="isModal == false && standalone == true"
      (click)="goback()"
      mat-raised-button
      color="primary"
    >
      Back
    </button>

    <button class="" [disabled]="!filterName" (click)="createFilter()" mat-raised-button color="primary">Save</button>
  </div>
</div>
