export class treatmentUtil {
  constructor() {}

  getLabel(label) {
    let _label = label;
    _label = _label.replace(/(\?)/g, '-');
    return _label;
  }

  getLabelTitle(label) {
    let _label = label;
    const index = _label.indexOf('?');
    if (index != -1) {
      _label = _label.substring(0, index);
    }
    return _label;
  }

  getLabelSubTitle(label) {
    let _label = label;
    const index = _label.indexOf('?');
    if (index != -1) {
      _label = _label.substring(_label.indexOf('?') + 1).replace(/(\?)/g, '-');
    } else {
      _label = null;
    }
    return _label;
  }

  getItemCode(label) {
    // Bridge pontic - indirect - per pontic (643)
    const split = label.split('(');
    const itemCode = split[split.length - 1].replace(')', '');
    return itemCode;
  }
}
