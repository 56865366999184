<div class="row clearfix" *ngIf="type == 'invitation'">
  <div class="row clearfix pull-right ml mb">
    <button
      class="pull-right"
      (click)="viewVideoInfo('Default messages', 'vimeo.com/358964874/19331ac58f')"
      mat-raised-button
      color="primary"
    >
      What's this?
      <mat-icon class="fas fa-info-circle"></mat-icon>
    </button>
  </div>
  <h3 class="sm-h3 sr-title rm-mb rm-pt">Default Invitation Messages</h3>
  <p>
    Speed up your {{ "KEYWORD.treatment" | translate }} invitations by creating customised messages to send with
    your {{ "KEYWORD.treatment" | translate }} invitations. You can
    use the tools below to create as many different messages as you wish, and personalised tags (such as a
    {{ "KEYWORD.patient" | translate }}'s name) can be added using the 'insert personalisation' button below.
  </p>
  <hr/>
</div>

<div class="clearfix" *ngIf="isPromoterOrAdmin == true && isGlobal == true">
  <app-operator-filter
    class="filter"
    id="part1"
    *ngIf="hideFilter == false"
    [displayMerchant]="true"
    [displayDateRange]="false"
    [displayDateFrom]="false"
    [displayTimePicker]="false"
    [displayDateTo]="false"
    [displayDentist]="false"
    (search)="applySearch($event)"
  >
  </app-operator-filter>

  <hr/>
  <div class="row clearfix">
    <app-default-message-list-global
      *ngIf="merchantID !== 'none' && isLoaded == true && displayResult==true"
      [purposeCode]="purposeCode"
      [productGroupCode]="productGroupCode"
      [purposeCodes]="purposeCodes"
      [notPurposeCodes]="notPurposeCodes"
      [productGroupCodes]="productGroupCodes"
      [pageSize]="pageSize"
      [merchantID]="merchantID"
      [messageMode]="messageMode"
    >
    </app-default-message-list-global>
  </div>

  <div class="row clearfix">
    <div class="pleaseChoose" *ngIf="merchantID == 'none'">
      <p>
        <strong>No merchant selected</strong>
      </p>
      <img class="character_warning" />
      <p>"Choose your desired merchant from the drop-down above to view their messages</p>
    </div>
  </div>
</div>

<div class="clearfix" *ngIf="isPromoterOrAdmin == false || isGlobal != true">
  <app-default-message-list
    *ngIf="isLoaded == true && displayResult==true"
    [purposeCode]="purposeCode"
    [productGroupCode]="productGroupCode"
    [purposeCodes]="purposeCodes"
    [productGroupCodes]="productGroupCodes"
    [notPurposeCodes]="notPurposeCodes"
    [pageSize]="pageSize"
    [messageMode]="messageMode"
  >
  </app-default-message-list>
</div>
