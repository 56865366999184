import { Component, EventEmitter, Inject, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { ColorPalette } from '../../../shared/types/color-palette';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { Settings } from '../../../shared/types/settings';
import { AuthenticationService } from '../../authentication/shared/authentication.service';
import { SubscriptionPlanService } from '../shared/subscription-plan.service';

@Component({
  selector: 'app-subscription-plan-congratulation',
  templateUrl: './subscription-plan-congratulation.component.html',
  styleUrls: ['./subscription-plan-congratulation.component.css'],
})
export class SubscriptionPlanCongratulationComponent implements OnInit {
  label;
  color = Settings.global['primaryColor'] || '#1e88e5';
  colorSecond;
  root = '/merchant';
  subscriptionPlanID;
  sessionType;
  isExternal = false;
  @Output()
  close = new EventEmitter();
  loaded = false;

  settings  = Settings.global

  constructor(
    private authenticationService: AuthenticationService,
    private subscriptionPlanService: SubscriptionPlanService,
    private router: Router,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.subscriptionPlanID) {
        this.subscriptionPlanID = data.subscriptionPlanID;
      } else {
        if (data.color && data.color.split('|')[0]) {
          this.color = data.color.split('|')[0];
        } else if (data.color) {
          this.color = data.color;
        }
        if (data.color && data.color.split('|')[1]) {
          this.colorSecond = data.color.split('|')[1];
        } else if (data.colorSecond) {
          this.colorSecond = data.colorSecond;
        }
        if (data.label) {
          this.label = data.label;
        }
      }

      if (data.isExternal != null) {
        this.isExternal = data.isExternal;
      }
    }
  }

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((sessionType) => {
      this.sessionType = sessionType;

      if (this.subscriptionPlanID) {
        this.subscriptionPlanService
          .getPromoterPlanDetails(this.subscriptionPlanID, { fields: 'Color,Web_Label' }, this.sessionType)
          .subscribe((data) => {
            if (data) {
              if (data.Color && data.Color.split('|')[0]) {
                this.color = data.Color.split('|')[0];
              } else if (data.Color) {
                this.color = data.Color;
              }
              if (data.Color && data.Color.split('|')[1]) {
                this.colorSecond = data.Color.split('|')[1];
              } else if (data.colorSecond) {
                this.colorSecond = data.colorSecond;
              }

              if (data.Web_Label) {
                this.label = data.Web_Label;
              }
            }

            this.loaded = true;
          });
      } else {
        if (this.color && !this.colorSecond) {
          this.onColorPickerClose(this.color);
        }
        this.loaded = true;
      }
    });
  }

  onColorPickerClose(color: string): void {
    if (color) {
      this.color = color;

      const v = new ColorPalette(color);
      const palette = v.getPalette();

      if (palette && palette.length > 0) {
        const index = palette.findIndex((item) => {
          if (item && item.name == 800) {
            return true;
          } else {
            return false;
          }
        });

        if (palette[index] && palette[index]['hex']) {
          this.colorSecond = palette[index]['hex'];
        }
      }
    }
  }

  closeEvent() {
    this.close.emit(true);
  }

  activate() {
    const confirm = new ConfirmDialog(
      'info',
      'Logout',
      'Do you wish to logout to Activate the  subscription plan features?',
      'Cancel',
      'Logout and Proceed'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirm,
      disableClose: true,
    });

    ref.componentInstance.onConfirm.subscribe((confirmation) => {
      if (confirmation === false) {
        ref.close();
      } else {
        this.authenticationService.logout().subscribe((res) => {
          ref.close();
          this.closeEvent();
          this.router.navigate(['/login']);
        });
      }
    });
  }

  checkPlan() {
    this.closeEvent();
    if (this.subscriptionPlanID) {
      this.router.navigate([
        this.root,
        {
          outlets: {
            page: ['subscription-plan-view', this.subscriptionPlanID],
          },
        },
      ]);
    } else {
      this.router.navigate([
        this.root,
        {
          outlets: {
            page: ['subscription-plan-view-current', 'current'],
          },
        },
      ]);
    }
  }
}
