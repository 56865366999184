import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { SettlementService } from '../shared/settlement.service';

@Component({
  selector: 'app-settlement-modify',
  templateUrl: './settlement-modify.component.html',
  styleUrls: ['./settlement-modify.component.css'],
})
export class SettlementModifyComponent implements OnInit {
  @Input()
  settlementtKey;

  @Input()
  settlement = null;

  @Output()
  close = new EventEmitter();

  @Output()
  modifiedSettlement = new EventEmitter();

  constructor(
    private settlementService: SettlementService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      this.settlementtKey = data;
    }
  }

  ngOnInit() {
    this.settlementService.getSettlementDetails(this.settlementtKey, null).subscribe((res) => {
      if (res) {
        if (res['Status.Code'] != 'PEND') {
          NotifyAppComponent.displayToast('error', 'Error', 'You only edit pending  Settlements ');
          this.close.emit(true);
        } else {
          this.settlement = {};

          this.settlement['settlementAmount'] = res['Settlement.Amount'];
          this.settlement['invoiceAmount'] = res['Invoice.Amount'];
          this.settlement['invoiceDate'] = res['Invoice.Date'];
          this.settlement['invoiceReference'] = res['Invoice.Reference'];
          this.settlement['invoiceDescription'] = res['Invoice.Description'];
          this.settlement['isFinalized'] = false;
        }
      }
    });
  }

  selectInvoiceDate(d) {
    if (d) {
      this.settlement['invoiceDate'] = d;
    }
  }

  edit() {
    const modified = {};

    modified['settlementAmount'] = this.settlement['Settlement.Amount'];
    modified['invoiceAmount'] = this.settlement['Invoice.Amount'];
    modified['invoiceDate'] = this.settlement['Invoice.Date'];
    modified['isFinalized'] = this.settlement['isFinalized'];

    this.settlementService.editSettlement(this.settlementtKey, this.settlement).subscribe((res) => {
      if (res) {
        this.modifiedSettlement.emit(res);
        NotifyAppComponent.displayToast('success', 'Success!', 'The Settlement is successfully modified');

        this.close.emit(true);
      }
    });
  }

  closeDialog() {
    this.close.emit(true);
  }
}
