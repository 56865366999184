import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InvTreatBeforeStartingComponent } from '../before-starting/before-starting.component';
import { InvitationTemplateCloneComponent } from '../invitation-template-clone/invitation-template-clone.component';
import { InvitationTemplateCreateEditComponent } from '../invitation-template-create-edit/invitation-template-create-edit.component';
import { InvitationTemplateListOverviewComponent } from '../invitation-template-list-overview/invitation-template-list-overview.component';
import { InvitationTemplateListComponent } from '../invitation-template-list/invitation-template-list.component';
import { InvitationTemplateLookupComponent } from '../invitation-template-lookup/invitation-template-lookup.component';
import { InvitationTemplateMergerComponent } from '../invitation-template-merger/invitation-template-merger.component';
import { InvitationTemplateThirdPartyComponent } from '../invitation-template-third-party/invitation-template-third-party.component';
import { InvitationTemplateViewComponent } from '../invitation-template-view/invitation-template-view.component';
import { TreatmentSaveModalComponent } from '../treatment-save-modal/treatment-save-modal.component';

const routes: Routes = [
  {
    path: 'InvitationTemplateThirdPartyComponent',
    component: InvitationTemplateThirdPartyComponent,
  },
  {
    path: 'InvitationTemplateCreateEditComponent',
    component: InvitationTemplateCreateEditComponent,
  },

  {
    path: 'InvitationTemplateCloneComponent',
    component: InvitationTemplateCloneComponent,
  },

  {
    path: 'InvTreatBeforeStartingComponent',
    component: InvTreatBeforeStartingComponent,
  },

  {
    path: 'InvitationTemplateListComponent',
    component: InvitationTemplateListComponent,
  },
  {
    path: 'InvitationTemplateLookupComponent',
    component: InvitationTemplateLookupComponent,
  },

  {
    path: 'InvitationTemplateMergerComponent',
    component: InvitationTemplateMergerComponent,
  },

  {
    path: 'InvitationTemplateListOverviewComponent',
    component: InvitationTemplateListOverviewComponent,
  },
  {
    path: 'InvitationTemplateViewComponent',
    component: InvitationTemplateViewComponent,
  },
  {
    path: 'TreatmentSaveModalComponent',
    component: TreatmentSaveModalComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class InvitationTemplateRoutingModule {}
