
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {ComponentStore} from '@ngrx/component-store';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {AppStateService} from '../../../core/app-state/app-state.service';
import {UtilsService} from '../../../shared/services/utils.service';
import {DataResponse} from '../../../shared/types/data-response.type';
import {QueryParameters} from '../../../shared/types/query-parameters.type';

class BeneficiaryState {
  beneficiaries: Partial<Beneficiary>[] | Beneficiary[];
  beneficiary: Partial<Beneficiary> | Beneficiary;
  tradingAsFilter: string;
  typeCodeFilter: string;
}


export class Beneficiary {
  readonly 'ID'?: string;
  readonly 'DateTimeCreated'?: string;
  readonly 'LastModified'?: string;
  readonly 'LastModified_Human'?: string;
  'Label': string;
  'Active': '0' | '1';
  'Name': string;
  'Salutation': string;
  'MaternalName': string;
  'FirstName': string;
  'MiddleName': string;
  'PreferredName': string;
  'TradingAs': string;
  'CalculatedName': string;
  'Occupation': string;
  'CardType': string;
  'ABN': string;
  'ACN': string;
  'CompanyType': string;
  'phones.Labels': string;
  'phones.Primary': string;
  'phones.Number': string;
  'Phone.Work.Number': string;
  'emails.Email': string;
  'Phone.Home.Number': string;
  'mobiles.Labels': string;
  'mobiles.Primary': string;
  'mobiles.Number': string;
  'emails.Labels': string;
  'emails.Primary': string;
  'addresses.Calculated': string;
  'addresses.Suburb': string;
  'addresses.State': string;
  'addresses.Postcode': string;
  'addresses.Street Type': string;
  'addresses.Unit': string;
  'addresses.Street Nr': string;
  'addresses.Street Name': string;
  'addresses.Property': string;
  'PreferredMessageType.Code': string;
  'PreferredMessageType.Label': string;
  'Status': string;
  'Address.Longitude': string;
  'Address.Latitude': string;
  'URL': string;
  'Twitter': string;
  'LinkedIn': string;
  'Instagram': string;
  'Facebook': string;
  'TrusteeFor': string;
  'Type_Code': string;
  'ParentOrganisation_key': string;
  'ParentOrganisation.Name': string;
  'PossibleContactTypes': string;
  isChecked?: boolean
}

export class BeneficiaryQuery {
  payload: Partial<Beneficiary> &
    QueryParameters & {
    partTradingAs?: string;
    typeCode?: string;
  };
}

@Injectable()
export class BeneficiaryService extends ComponentStore<BeneficiaryState> {
  private baseUrl = environment.nodeUrl;

  constructor(
    private http: HttpClient,
    private appStateService: AppStateService,
    private utilsService: UtilsService,
    private httpClient: HttpClient
  ) {
    super({
      beneficiaries: [],
      beneficiary: null,
      tradingAsFilter: null,
      typeCodeFilter: null
    })
  }

  getBeneficiaries<T extends Beneficiary | Partial<Beneficiary>>(): T[] {
    return this.get().beneficiaries as T[];
  }

  getBeneficiaries$<T extends Beneficiary | Partial<Beneficiary>>(): Observable<T[]> {
    return this.select((state) => state.beneficiaries) as Observable<T[]>;
  }

  setBeneficiaries(beneficiaries: Beneficiary[] | Partial<Beneficiary>[]): void {
    this.patchState({ beneficiaries });
  }

  getTradingAsFilter$() {
    return this.select((state) => state.tradingAsFilter);
  }

  setTradingAsFilter(typeCodeFilter: string) {
    this.patchState({typeCodeFilter})
  }

  getTypeCodeFilter$() {
    return this.select((state) => state.typeCodeFilter);
  }

  setTypeCodeFilter(typeCodeFilter: string) {
    this.patchState({typeCodeFilter})
  }

  fetchBeneficiaries$<T extends Beneficiary | Partial<Beneficiary>>(payload?: BeneficiaryQuery['payload']): Observable<T[]> {
    return this.appStateService.getAppState$().pipe(
      switchMap((appState) => {
        const params = this.utilsService.getHttpParamsFromPayload(payload);

        const options = { params };

        const endpoint = appState.isPromoterOrAdmin ? 'beneficiary/global' : 'beneficiary';

        const url = `${this.baseUrl}/${endpoint}`;

        return this.httpClient.get<DataResponse<T[]>>(url, options).pipe(map((response) => response['data']));
      })
    );
  }

  getBeneficiary$<T extends Beneficiary | Partial<Beneficiary>>(): Observable<T> {
    return this.select((state) => state.beneficiary) as Observable<T>;
  }

  setBeneficiary(beneficiary: Beneficiary | Partial<Beneficiary>): void {
    this.patchState({ beneficiary });
  }

  fetchBeneficiary$<T extends Beneficiary | Partial<Beneficiary>>(payload: {supplierID: string}): Observable<T> {
    const {supplierID} = payload;

    return this.appStateService.getAppState$().pipe(
        switchMap((appState) => {
          const params = this.utilsService.getHttpParamsFromPayload(payload);

          const options = { params };

          const endpoint = appState.isPromoterOrAdmin ? 'beneficiary/global' : 'beneficiary';

          const url = `${this.baseUrl}/${endpoint}/${supplierID}`;

          return this.httpClient.get<DataResponse<T>>(url, options).pipe(map((response) => response['data']));
        })
    );
  }

  getBeneficiaryDetails(ID, payload = null, isPromoterOrAdmin = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params
      };
    }

    if (isPromoterOrAdmin == true) {
      return this.http
        .get(environment.nodeUrl + '/beneficiary/global/' + ID, options).pipe(
          map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/beneficiary/' + ID, options).pipe(
          map((res: HttpResponse<any>) => res['data']));
    }
  }

  getBeneficiaryList(payload = {}, isPromoterOrAdmin = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params
      };
    }

    if (isPromoterOrAdmin == true) {
      return this.http
        .get(environment.nodeUrl + '/beneficiary/global/', options).pipe(
          map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/beneficiary/', options).pipe(
          map((res: HttpResponse<any>) => res['data']));
    }
  }

  createBeneficiary(payload): Observable<any> {

    return this.http
      .post(environment.nodeUrl + '/beneficiary/', payload).pipe(
        map((res: HttpResponse<any>) => res['data']));

  }



  checkBeneficiaryLabel(label): Observable<any> {

    return this.http
      .post(environment.nodeUrl + '/beneficiary/label/' + label, {}).pipe(
        map((res: HttpResponse<any>) => res['data']));

  }

  editBeneficiary(id, payload): Observable<any> {

    return this.http
      .put(environment.nodeUrl + '/beneficiary/' + id, payload).pipe(
        map((res: HttpResponse<any>) => res['data']));

  }

  editBeneficiarytGroup(payload): Observable<any> {
    return this.http
      .put(`${environment.nodeUrl}/beneficiary/group`, payload).pipe(
        map((res: Response) => res['data']));
  }

  addDocumentBeneficiaryGroup(payload): Observable<any> {
    return this.http
      .post(`${environment.nodeUrl}/beneficiary/document/group`, payload).pipe(
        map((res: Response) => res['data']));
  }

  getList(payload, isPromoterOrAdmin = true): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params
    };
    if (isPromoterOrAdmin == true) {

      return this.http
        .get(environment.nodeUrl + '/beneficiary/global/', options).pipe(
          map((res: HttpResponse<any>) => res['data']));

    } else {
      return this.http
        .get(environment.nodeUrl + '/beneficiary/', options).pipe(
          map((res: HttpResponse<any>) => res['data']));
    }

  }


  uploadProfilePic(ID, payload = {}) {




    return this.http
      .put(`${environment.nodeUrl}/beneficiary/profile-picture/` + ID, payload).pipe(
        map((res: HttpResponse<any>) => res['data']));
  }


  getProfilePic(ID, payload = {}) {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params
      };
    }

    return this.http
      .get(`${environment.nodeUrl}/beneficiary/profile-picture/` + ID, options).pipe(
        map((res: HttpResponse<any>) => res['data']));
  }



  getProfilePicStreamLink(ID, LastModified = null) {
    if (
      LastModified &&
      LastModified != '' &&
      LastModified != undefined &&
      LastModified != 'null' &&
      LastModified != 'undefined '
    ) {
      return `${environment.nodeUrl}/beneficiary/profile-picture-stream-cached/${LastModified}/` + ID;
    } else if (ID) {
      return `${environment.nodeUrl}/beneficiary/profile-picture-stream/` + ID;
    }
    return null;
  }


  addCoordinate(ID, body) {
    return this.http
      .put(environment.nodeUrl + '/beneficiary/coordinate/' + ID, body).pipe(
        map((res: HttpResponse<any>) => res['data']));
  }

  getBeneficiaryTypes(payload = null): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params
      };
    }
    return this.http
      .get(environment.nodeUrl + '/beneficiary-type', options).pipe(
        map((res: HttpResponse<any>) => res['data']));
  }


}




