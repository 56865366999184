import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'clearText',
})
export class ClearTextPipe implements PipeTransform {
  constructor() {}
  transform(value) {
    let d = value;
    if (d) {
      d = d.replaceAll('<strong><br></strong>', '');
      d = d.replaceAll('<h1><br></h1>', '');
      d = d.replaceAll('<h2><br></h2>', '');
      d = d.replaceAll('<h3><br></h3>', '');
      d = d.replaceAll('<h4><br></h4>', '');
      d = d.replaceAll('<h5><br></h5>', '');
      d = d.replaceAll('<p><br></p>', '');
    }

    return d;
  }
}
