import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSortModule } from '@angular/material/sort';
import { Ng2CompleterModule } from 'ng2-completer';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgPipesModule } from 'ngx-pipes';
import { SharedModule } from '../../shared/shared.module';
import { BeneficiaryViewPictureComponent } from '../beneficiary/beneficiary-view-picture/beneficiary-view-picture.component';
import { DocumentModule } from '../document/document.module';
import { OperatorModule } from '../operator/operator.module';
import { ProductModule } from '../product/product.module';
import { DentalArchCreateComponent } from './dental-arch-create/dental-arch-create.component';
import { DentalArchModalComponent } from './dental-arch-modal/dental-arch-modal.component';
import { DentalArchViewComponent } from './dental-arch-view/dental-arch-view.component';
import { DocumentListConsumerSimpleComponent } from './document-list-consumer-simple/document-list-consumer-simple.component';
import { SelectedTeethViewComponent } from './selected-teeth-view/selected-teeth-view.component';
import { TreatmentRoutingModule } from './shared/treatment-routing.module';
import { TreatmentService } from './shared/treatment.service';
import { ToothDeleteComponent } from './tooth-delete/tooth-delete.component';
import { ToothEditComponent } from './tooth-edit/tooth-edit.component';
import { TreatmentBrandCreateEditComponent } from './treatment-brand-create-edit/treatment-brand-create-edit.component';
import { TreatmentBrandListGlobalComponent } from './treatment-brand-list-global/treatment-brand-list-global.component';
import { TreatmentBrandListOverviewComponent } from './treatment-brand-list-overview/treatment-brand-list-overview.component';
import { TreatmentBrandListComponent } from './treatment-brand-list/treatment-brand-list.component';
import { TreatmentBrandViewLogoComponent } from './treatment-brand-view-logo/treatment-brand-view-logo.component';
import { TreatmentBrandViewComponent } from './treatment-brand-view/treatment-brand-view.component';
import { TreatmentCategoryCreateEditComponent } from './treatment-category-create-edit/treatment-category-create-edit.component';
import { TreatmentCategoryListComponent } from './treatment-category-list/treatment-category-list.component';
import { TreatmentCategoryViewLogoComponent } from './treatment-category-view-logo/treatment-category-view-logo.component';
import { TreatmentCategoryViewComponent } from './treatment-category-view/treatment-category-view.component';
import { TreatmentCreateComponent } from './treatment-create/treatment-create.component';
import { TreatmentDocumentCreateComponent } from './treatment-document-create/treatment-document-create.component';
import { TreatmentDocumentEditComponent } from './treatment-document-edit/treatment-document-edit.component';
import { TreatmentDocumentGalleryContainerComponent } from './treatment-document-gallery-container/treatment-document-gallery-container.component';
import { TreatmentDocumentGalleryOverviewComponent } from './treatment-document-gallery-overview/treatment-document-gallery-overview.component';
import { TreatmentDocumentGalleryComponent } from './treatment-document-gallery/treatment-document-gallery.component';
import { TreatmentDocumentImportOverviewComponent } from './treatment-document-import-overview/treatment-document-import-overview.component';
import { treatmentEditModalComponent } from './treatment-edit-modal/treatment-edit-modal.component';
import { TreatmentGroupDocumentList } from './treatment-group-document-list/treatment-group-document-list.component';
import { TreatmentGroupNewButtonComponent } from './treatment-group-new-button/treatment-group-new-button.component';
import { TreatmentInclusionModalComponent } from './treatment-incusion-modal/treatment-incusion-modal.component';
import { TreatmentInformedConsentCreateComponent } from './treatment-informed-consent-create/treatment-informed-consent-create.component';
import { TreatmentInformedConsentViewComponent } from './treatment-informed-consent-view/treatment-informed-consent-view.component';
import { TreatmentIntroModalComponent } from './treatment-intro-modal/treatment-intro-modal.component';
import { TreatmentItemViewComponent } from './treatment-item-view/treatment-item-view.component';
import { TreatmentListSimpleComponent } from './treatment-list-simple/treatment-list-simple.component';
import { TreatmentPracticeOverheadEditComponent } from './treatment-practice-overhead-edit/treatment-practice-overhead-edit.component';
import { TreatmentProductCreateEditComponent } from './treatment-product-create-edit/treatment-product-create-edit.component';
import { TreatmentProductEditMerchantComponent } from './treatment-product-edit-merchant/treatment-product-edit-merchant.component';
import { TreatmentProductListGlobalComponent } from './treatment-product-list-global/treatment-product-list-global.component';
import { TreatmentProductListOverviewComponent } from './treatment-product-list-overview/treatment-product-list-overview.component';
import { TreatmentProductListComponent } from './treatment-product-list/treatment-product-list.component';
import { TreatmentProductLookupComponent } from './treatment-product-lookup/treatment-product-lookup.component';
import { TreatmentProductViewCatalogueComponent } from './treatment-product-view-catalogue/treatment-product-view-catalogue.component';
import { TreatmentProductViewLogoComponent } from './treatment-product-view-logo/treatment-product-view-logo.component';
import { TreatmentProductViewComponent } from './treatment-product-view/treatment-product-view.component';
import { TreatmentSavedListGlobalComponent } from './treatment-saved-list-global/treatment-saved-list-global.component';
import { TreatmentSavedListComponent } from './treatment-saved-list/treatment-saved-list.component';
import { TreatmentSavedOverviewComponent } from './treatment-saved-overview/treatment-saved-overview.component';
import { TreatmentTypeCreateEditComponent } from './treatment-type-create-edit/treatment-type-create-edit.component';
import { TreatmentTypeListComponent } from './treatment-type-list/treatment-type-list.component';
import { TreatmentTypeViewLogoComponent } from './treatment-type-view-logo/treatment-type-view-logo.component';
import { TreatmentTypeViewComponent } from './treatment-type-view/treatment-type-view.component';

@NgModule({
  imports: [
    NgPipesModule,
    CommonModule,
    FormsModule,
    CdkTableModule,
    Ng2CompleterModule,
    CommonModule,
    ReactiveFormsModule,
    TreatmentRoutingModule,
    ProductModule,
    SharedModule,
    OperatorModule,
    ColorPickerModule,
    MatSortModule,
    DocumentModule,
  ],
  declarations: [
    TreatmentDocumentEditComponent,
    TreatmentInformedConsentCreateComponent,
    TreatmentInformedConsentViewComponent,
    TreatmentCreateComponent,
    DentalArchModalComponent,
    DentalArchViewComponent,
    ToothEditComponent,
    DentalArchCreateComponent,
    SelectedTeethViewComponent,
    ToothDeleteComponent,
    TreatmentListSimpleComponent,
    DocumentListConsumerSimpleComponent,
    TreatmentDocumentCreateComponent,
    TreatmentProductCreateEditComponent,
    TreatmentPracticeOverheadEditComponent,
    TreatmentDocumentGalleryComponent,
    TreatmentItemViewComponent,
    TreatmentInclusionModalComponent,
    TreatmentCategoryListComponent,
    TreatmentBrandListComponent,
    TreatmentTypeListComponent,
    TreatmentGroupNewButtonComponent,
    TreatmentBrandCreateEditComponent,
    TreatmentCategoryCreateEditComponent,
    TreatmentTypeCreateEditComponent,
    TreatmentBrandViewLogoComponent,
    TreatmentProductListComponent,
    TreatmentProductEditMerchantComponent,
    TreatmentProductListGlobalComponent,
    TreatmentProductListOverviewComponent,
    TreatmentBrandViewComponent,
    TreatmentBrandListGlobalComponent,
    TreatmentBrandListOverviewComponent,
    TreatmentProductViewComponent,
    TreatmentProductViewLogoComponent,
    TreatmentProductViewCatalogueComponent,
    BeneficiaryViewPictureComponent,
    TreatmentDocumentGalleryOverviewComponent,
    TreatmentSavedOverviewComponent,
    TreatmentSavedListComponent,
    TreatmentSavedListGlobalComponent,
    TreatmentGroupDocumentList,
    TreatmentProductLookupComponent,
    TreatmentTypeViewComponent,
    TreatmentDocumentGalleryContainerComponent,
    TreatmentCategoryViewComponent,
    TreatmentTypeViewLogoComponent,
    TreatmentCategoryViewLogoComponent,
    TreatmentIntroModalComponent,
    treatmentEditModalComponent,
    TreatmentDocumentImportOverviewComponent,
  ],
  exports: [
    TreatmentDocumentEditComponent,
    TreatmentInformedConsentCreateComponent,
    TreatmentInformedConsentViewComponent,
    TreatmentCreateComponent,
    DentalArchModalComponent,
    DentalArchViewComponent,
    TreatmentProductCreateEditComponent,
    ToothEditComponent,
    DentalArchCreateComponent,
    SelectedTeethViewComponent,
    ToothDeleteComponent,
    TreatmentListSimpleComponent,
    DocumentListConsumerSimpleComponent,
    TreatmentDocumentCreateComponent,
    TreatmentPracticeOverheadEditComponent,
    TreatmentDocumentGalleryComponent,
    TreatmentItemViewComponent,
    TreatmentInclusionModalComponent,
    TreatmentCategoryListComponent,
    TreatmentBrandListComponent,
    TreatmentTypeListComponent,
    TreatmentGroupNewButtonComponent,
    TreatmentBrandCreateEditComponent,
    TreatmentCategoryCreateEditComponent,
    TreatmentTypeCreateEditComponent,
    TreatmentBrandViewLogoComponent,
    TreatmentProductListComponent,
    TreatmentProductEditMerchantComponent,
    TreatmentProductListGlobalComponent,
    TreatmentProductListOverviewComponent,
    TreatmentBrandViewComponent,
    TreatmentBrandListGlobalComponent,
    TreatmentBrandListOverviewComponent,
    TreatmentProductViewComponent,
    TreatmentProductViewLogoComponent,
    TreatmentProductViewCatalogueComponent,
    BeneficiaryViewPictureComponent,
    TreatmentDocumentGalleryOverviewComponent,
    TreatmentSavedOverviewComponent,
    TreatmentSavedListComponent,
    TreatmentSavedListGlobalComponent,
    TreatmentGroupDocumentList,
    TreatmentProductLookupComponent,
    TreatmentTypeViewComponent,
    TreatmentDocumentGalleryContainerComponent,
    TreatmentCategoryViewComponent,
    TreatmentTypeViewLogoComponent,
    TreatmentCategoryViewLogoComponent,
    TreatmentIntroModalComponent,
    treatmentEditModalComponent,
    TreatmentDocumentImportOverviewComponent,
  ],
  entryComponents: [TreatmentIntroModalComponent],
  providers: [TreatmentService],
})
export class TreatmentModule {}
