import { BehaviorSubject, merge as observableMerge, Observable } from 'rxjs';

import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { DataSource } from '@angular/cdk/table';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { CustomDatePipe } from '../../../shared/pipes/custom-date.pipe';
import { SideMenuService } from '../../../shared/services/side-menu.service';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { MerchantViewComponent } from '../../merchant/merchant-view/merchant-view.component';
import { MedicalHistoryService } from '../shared/medical-history.service';

@Component({
  selector: 'app-medical-history-list-global',
  templateUrl: './medical-history-list-global.component.html',
  styleUrls: ['./medical-history-list-global.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.row', style({ opacity: 0 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.row', style({ opacity: 1 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class MedicalHistoryListGlobalComponent implements OnInit {
  static closeMerchantSelectEvent = new EventEmitter();
  @Input()
  merchantID;

  @Input()
  cardID;

  @Input()
  showDetailsInList = false;

  @Output()
  hideMerchantSelection = new EventEmitter();
  isPromoterOrAdmin = false;

  @Input()
  pageSize = Settings.global['listPageSize'] || 20;
  pageSizeOptions = [10, Number(this.pageSize), Number(this.pageSize) * 2, Number(this.pageSize) * 3];

  utils = new UtilsClass();

  displayedColumns = [
    'profilePicture',
    'PatientName',
    'Date',
    'MerchantName',
    'Status',
    'Progress',
    'updated',
    'Actions',
  ];
  filters = [];

  searchVals = new FormControl();

  searchValList = ['Patient Name', 'Merchant Name'];
  expandedRow: number;
  public listDB: LoadRecords | null;
  dataSource: RecordDataSource | null;

  filteredSize = null;

  medicalHistoryID;

  displayMedicalHistoryDetail = false;

  destroyEvent = new EventEmitter();
  serviceType = 'merchant';

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('filter', { static: false }) filter: ElementRef;

  constructor(
    private medicalHistoryService: MedicalHistoryService,
    private customDate: CustomDatePipe,
    private authenticationService: AuthenticationService,
    private router: Router
  ) {
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;
    });

    this.authenticationService.getSessionType().subscribe((res) => {
      this.serviceType = res;
    });
  }

  ngOnInit() {
    SideMenuService.goBack.subscribe((res) => {
      if (res == true) {
        this.cancelViewMedicalHistory();
      }
    });

    const p = {
      merchantID: this.merchantID,
      cardID: this.cardID,
    };

    this.listDB = new LoadRecords(this.medicalHistoryService, p, this.serviceType, this.destroyEvent);
    this.dataSource = new RecordDataSource(this.listDB, this.paginator, this.sort);
  }

  ngOnChanges(changes: SimpleChanges) {
    const p = {
      merchantID: this.merchantID,
      cardID: this.cardID,
    };

    this.listDB = new LoadRecords(this.medicalHistoryService, p, this.serviceType, this.destroyEvent);
    this.dataSource = new RecordDataSource(this.listDB, this.paginator, this.sort);
  }

  getProgress(item) {
    if (item && item.Status == 'Complete') {
      return 100;
    } else if (item && item.Step) {
      const step = Number(item.Step);

      return Number(Number((step / 36) * 100).toFixed(0));
    } else {
      return 0;
    }
  }

  getProgressColor(a) {
    if (a) {
      if (a >= 0 && a < 20) {
        return '#ff0800';
      } else if (a >= 20 && a < 40) {
        return '#f6631e';
      } else if (a >= 40 && a < 60) {
        return '#ec8422';
      } else if (a >= 60 && a < 80) {
        return '#84ec22';
      } else if (a >= 80) {
        return '#00d07a';
      }
    }
    return '#ff0800';
  }

  getStatusColor(status) {
    if (status == 'Complete') {
      return '#00d07a';
    }
    return '#ff5722';
  }

  expendItems(item) {
    this.dataSource.showAllItem = item;
  }

  setFilter(event, field) {
    let filter;
    if (typeof event == 'object' && event != null) {
      const v = this.customDate.transform(event, Settings.global['dateFormat']);
      filter = {
        field,
        value: v,
      };
    } else if (event) {
      filter = {
        field,
        value: event,
      };
    } else {
      filter = {
        field,
        value: '',
      };
    }
    this.dataSource.filter = filter;
    this.filteredSize = this.dataSource.filteredData.length;
  }

  viewMedicalHistory(ID, patientID) {
    if (this.showDetailsInList == true) {
      this.medicalHistoryID = ID;
      this.displayMedicalHistoryDetail = true;
      this.hideMerchantSelection.emit(true);
    } else {
      this.router.navigate([
        '/merchant',
        {
          outlets: {
            page: ['customer-profile', patientID, 'health', ID],
          },
        },
      ]);
    }
  }

  cancelViewMedicalHistory() {
    this.medicalHistoryID = null;
    this.displayMedicalHistoryDetail = false;
    this.hideMerchantSelection.emit(false);
  }

  viewPatient(patientID) {
    this.router.navigate([
      '/merchant',
      {
        outlets: {
          page: ['customer-profile', patientID, 'patient'],
        },
      },
    ]);
  }

  viewMerchant(ID) {
    const ref = RootAppComponent.dialog.open(MerchantViewComponent, {
      data: ID,
      width: '650px',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  inviteMedicalHistory() {
    if (this.cardID) {
      this.router.navigate(['/merchant', { outlets: { page: ['medical-history-invitation-create', this.cardID] } }]);
    }
  }

  ngOnDestroy() {
    this.destroyEvent.emit(true);
  }
}

export class LoadRecords implements OnInit {
  public dataChange: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  items: any;
  count: any;

  get data(): any[] {
    return this.dataChange.value;
  }

  firstLoad = false;

  get firstLoadEvent() {
    return this.firstLoad;
  }

  isDestoyed = false;

  serviceRef;

  constructor(
    private medicalHistoryService: MedicalHistoryService,
    private params = {},
    private serviceType,
    private destroyEvent
  ) {
    this.destroyEvent.subscribe((res) => {
      this.isDestoyed = res;
    });

    const payload = {
      section: 0,
      fields:
        'ID,DateTimeCreated,Date.Completed,Date.Activated,Card_key,Card.Name,Merchant.Name,Merchant_key,Status,Step,LastModified_Human',
      orderBy: '-Date.Activated',
    };

    if (this.params['merchantID']) {
      payload['merchantID'] = this.params['merchantID'];
    }

    if (this.params['cardID']) {
      payload['cardID'] = this.params['cardID'];
    }

    this.serviceRef = this.medicalHistoryService.getMedicalHistoryList(payload, this.serviceType).subscribe((res) => {
      this.count = res.length;
      this.items = res;
      this.dataChange.next(this.items.slice());
      this.firstLoad = true;
      const innerFunction = (section) => {
        section = section + 1;
        UtilsClass.loadingDataSection(section);
        payload.section = section;

        this.serviceRef = this.medicalHistoryService
          .getMedicalHistoryList(payload, this.serviceType)
          .subscribe((res) => {
            if (res.length > 0) {
              this.count = res.count;
              this.items = this.items.concat(res);
              this.dataChange.next(this.items.slice());
              innerFunction(section);
            } else {
              return true;
            }
          });
        UtilsClass.stopLoadingInterceptor();
      };
      innerFunction(1);
    });
  }

  ngOnInit() {}

  ngOnDestroy() {
    if (this.serviceRef) {
      this.serviceRef.unsubscribe();
    }
  }
}

export class RecordDataSource extends DataSource<any> {
  _filterChange = new BehaviorSubject('');
  field = '';
  expandingItems = [];

  get filter(): any {
    return this._filterChange.value;
  }

  set filter(item: any) {
    this.field = item.field;
    this._filterChange.next(item.value);
  }

  set showAllItem(item) {
    this.expandingItems.push(item.Card_key);
    this._tableDatabase.dataChange.next(this._tableDatabase.data);
  }

  filteredData: any[] = [];

  constructor(private _tableDatabase: LoadRecords, private _paginator: MatPaginator, private _sort: MatSort) {
    super();
    this._filterChange.subscribe(() => (this._paginator.pageIndex = 0));
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<any[]> {
    const displayDataChanges = [
      this._tableDatabase.dataChange,
      this._filterChange,
      this._paginator.page,
      this._sort.sortChange,
    ];

    return observableMerge(...displayDataChanges).pipe(
      map(() => {
        // Filter data
        let data = this.getMergedData(this._tableDatabase.data.slice());
        data = data.slice().filter((item: any) => {
          let f = '';
          f = item[this.field] || '';

          const searchStr = f.toLowerCase();
          return searchStr.indexOf(this.filter.toLowerCase()) != -1;
        });
        data = this.getSortedData(data.slice());
        this.filteredData = data.slice();
        // Grab the page's slice of data.
        const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
        return data.splice(startIndex, this._paginator.pageSize);
      })
    );
  }

  disconnect() {}

  getMergedData(data: any[]) {
    const key = 'Card_key';

    const uniqueData = [...new Map(data.map((item) => [item[key], item])).values()];

    const finalData = [];
    uniqueData.forEach((it) => {
      if (it[key]) {
        const list = data.filter((o) => o[key] === it[key]) || [];
        const l = list.length;
        const isMerged = !this.expandingItems.includes(it[key]) && l > 1;
        if (isMerged && list[0][key]) {
          list[0].isMerged = true;
          list[0].mergedLength = l;

          finalData.push(list[0]);
        } else {
          it.isMerged = false;
          it.mergedLength = null;

          if (list && list.length > 0) {
            list.map((item) => {
              item.isMerged = false;
              item.mergedLength = null;

              return item;
            });
          }
          finalData.push(...list);
        }
      } else {
        it.isMerged = false;
        it.mergedLength = null;
        finalData.push(...data.filter((o) => o[key] === it[key]));
      }
    });

    return finalData;
  }

  /** Returns a sorted copy of the database data. */
  getSortedData(data: any[]): any[] {
    // const data = data;
    if (!this._sort.active || this._sort.direction == '') {
      return data;
    }

    return data.sort((a, b) => {
      let propertyA: number | string = '';
      let propertyB: number | string = '';

      switch (this._sort.active) {
        case 'Date':
          [propertyA, propertyB] = [moment(a['Date']).toDate().getTime(), moment(b['Date']).toDate().getTime()];
          break;
      }

      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

      return (valueA < valueB ? -1 : 1) * (this._sort.direction == 'asc' ? 1 : -1);
    });
  }
}
