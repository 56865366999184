<!--  Notifications -->
<mat-card class="card">
  <!-- If no notifications -->
  <div class="empty-notification" *ngIf="notificationsEmpty">
    <p>There are no new notifications to display</p>
    <button class="btn-small" mat-raised-button>Notification Settings</button>
  </div>

  <!--  If notifications -->
  <div class="some-notifications">
    <h3 class="sm-h3 rm-mt">Notifications</h3>
    <mat-list>
      <!--  Notification item -->
      <mat-list-item class="notification clearfix">
        <div class="icons-col">
          <!-- notification icon -->
          <mat-icon class="notify-green">monetization_on</mat-icon>
        </div>
        <div class="row-content-col rel">
          <!-- Notification text -->
          <p>A new {{ "KEYWORD.patient" | translate }} has started their finance application</p>
          <button class="btn-small btn-clear btn-action" mat-button>View</button>
        </div>
      </mat-list-item>
      <mat-divider></mat-divider>
      <!-- / notification item -->

      <!--  Notification item -->
      <mat-list-item class="notification clearfix">
        <div class="icons-col">
          <!-- notification icon -->
          <mat-icon class="notify-warn">report_problem</mat-icon>
        </div>
        <div class="row-content-col rel">
          <!-- Notification text -->
          <p>
            A {{ "KEYWORD.patient" | translate }} has reported a problem with
            their {{ 'PHRASE.treatment plan' | translate }}. Please review
            <em>FirstName LastName</em>.
          </p>
          <button class="btn-small btn-clear btn-action" mat-button>View</button>
        </div>
      </mat-list-item>
      <mat-divider></mat-divider>
      <!-- / notification item -->

      <!--  Notification item -->
      <mat-list-item class="notification clearfix">
        <div class="icons-col">
          <!-- notification icon -->
          <mat-icon class="notify-blue">person</mat-icon>
        </div>
        <div class="row-content-col rel">
          <!-- Notification text -->
          <p>A new {{ "KEYWORD.patient" | translate }} <em>FirstName LastName</em> has registered!</p>
          <button class="btn-small btn-clear btn-action" mat-button>View</button>
        </div>
      </mat-list-item>
      <mat-divider></mat-divider>
      <!-- / notification item -->
    </mat-list>
  </div>
</mat-card>

<div class="row clearfix">
  <div class="full-width step-button-row text-center">
    <button class="step-back" (click)="cancel()" mat-mini-fab color="primary">
      <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
  </div>
</div>
