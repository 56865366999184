<mat-card class="rel" *ngIf="appointment" @ngIfAnimation style="margin-bottom: 10px">
  <div class="card-header primary-gradient-img clearfix stacked">
    <div class="row clearfix">
      <button class="btn-close btn-clear mat-button white" (click)="closeEvent()">
        <mat-icon>clear</mat-icon>
      </button>
      <div class="clearfix">
        <h2 class="sm-h2 pull-left rm-mb">Appointment Requested</h2>
      </div>
    </div>
  </div>

  <mat-dialog-content class="stacked-card-view">
    <div class="full-width flex">
      <h4 class="primary-color subtitle rm-mb rm-mt">Status</h4>
    </div>

    <div class="row clearfix flex funnel full-width">
      <div class="step step-1">
        <div class="row clearfix text-center">
          <mat-icon class="fas fa-calendar-alt"></mat-icon>
          <h5 class="sr-title rm-m">Request</h5>

          <hr />
        </div>
        <mat-list class="list-container">
          <mat-list-item *ngIf="appointment['DateTimeCreated'] != '0000-00-00'">
            <p matLine>
              <span class="title"
              >Requested:

                <span
                  class="fas fa-envelope comment"
                  *ngIf="appointment['Comment']"
                  (click)="showComment('Appointment request activity-log from the patient', appointment['Comment'])"
                  [matTooltip]="'Appointment request note from the ' + ('KEYWORD.patient' | translate)"
                >
                </span>
              </span>
              <span class="value">
                <p>{{ appointment['DateTimeCreated'] | customDate }}</p>
                <p>
                  {{ appointment['DateTimeCreated'] | customDate: 'h:mm a' }}
                </p>
              </span>
            </p>
          </mat-list-item>

          <mat-list-item *ngIf="appointment['Date_Request_Date_Change'] != '0000-00-00'">
            <p matLine>
              <span class="title"
              >Request date change:

                <span
                  class="fas fa-envelope comment"
                  *ngIf="appointment['Request_Date_Change_Comment']"
                  (click)="
                    showComment(
                      'Appointment date change activity-log from the patient',
                      appointment['Request_Date_Change_Comment']
                    )
                  "
                  [matTooltip]="'Appointment date change note from the ' + ('KEYWORD.patient' | translate)"
                >
                </span>
              </span>
              <span class="value"> {{ appointment['Date_Request_Date_Change'] | customDate }}</span>
            </p>
          </mat-list-item>

          <mat-list-item class="date-view-bottom" *ngIf="appointment['DateTimeCreated']">
            <p
              class="small"
              [style.border-color]="util.daysPastColor(appointment['DateTimeCreated'])"
              [style.color]="util.daysPastColor(appointment['DateTimeCreated'])"
              matLine
            >
              Requested
              {{ appointment['DateTimeCreated'] | TimeLabelPast }} ago
              <span
                class="backgroundColor"
                [style.background]="util.daysPastColor(appointment['DateTimeCreated'])"
              ></span>
            </p>
          </mat-list-item>
        </mat-list>
      </div>

      <!-- information about patient card interaction -->
      <div
        class="step step-2"
        [class.disabled]="!appointment['Date_Booked'] || appointment['Date_Booked'] == '0000-00-00'"
      >
        <div class="row clearfix text-center">
          <mat-icon class="fas fa-clock"></mat-icon>
          <h5 class="sr-title rm-m">Booking</h5>
          <hr />
        </div>

        <p
          class="nothingToDisplay text-center"
          *ngIf="!appointment['Date_Booked'] || appointment['Date_Booked'] == '0000-00-00'"
        >
          No activity to display
        </p>

        <mat-list
          class="list-container"
          [hidden]="!appointment['Date_Booked'] || appointment['Date_Booked'] == '0000-00-00'"
        >
          <mat-list-item *ngIf="appointment['Date_Booked'] != '0000-00-00'">
            <p matLine>
              <span class="title"
              >Propose Booking:

                <span
                  class="fas fa-envelope comment"
                  *ngIf="appointment['Merchant_Comment']"
                  (click)="showComment('Booking activity-log from the practice', appointment['Merchant_Comment'])"
                  [matTooltip]="'Booking note from the ' + ('KEYWORD.practice' | translate)"
                >
                </span>
              </span>
              <span class="value"> {{ appointment['Date_Booked'] | customDate }}</span>
            </p>
          </mat-list-item>

          <mat-list-item *ngIf="appointment['Date_Confirmation'] != '0000-00-00'">
            <p matLine>
              <span class="title">Confirmed:</span>
              <span class="value">{{ appointment['Date_Confirmation'] | customDate }}</span>
            </p>
          </mat-list-item>

          <mat-list-item *ngIf="appointment['Date_Request_Reschedule'] != '0000-00-00'">
            <p matLine>
              <span class="title"
              >Request rechedule:

                <span
                  class="fas fa-envelope comment"
                  *ngIf="appointment['Request_Reschedule_Comment']"
                  (click)="
                    showComment(
                      'Requesting rescheduling activity-log from the patient',
                      appointment['Request_Reschedule_Comment']
                    )
                  "
                  [matTooltip]="'Requesting rescheduling note from the ' + ('KEYWORD.patient' | translate)"
                >
                </span>
              </span>
              <span class="value"> {{ appointment['Date_Request_Reschedule'] | customDate }}</span>
            </p>
          </mat-list-item>
          <mat-list-item *ngIf="appointment['Date_Rescheduled'] != '0000-00-00'">
            <p matLine>
              <span class="title"
              >Rechedule:

                <span
                  class="fas fa-envelope comment"
                  *ngIf="appointment['Rescheduled_Comment']"
                  (click)="
                    showComment('Rescheduling activity-log from the practice', appointment['Rescheduled_Comment'])
                  "
                  [matTooltip]="'Rescheduling note from the ' + ('KEYWORD.practice' | translate)"
                >
                </span>
              </span>
              <span class="value"> {{ appointment['Date_Rescheduled'] | customDate }}</span>
            </p>
          </mat-list-item>

          <mat-list-item class="date-view-bottom" *ngIf="appointment['Date_Confirmation'] !== '0000-00-00'">
            <p
              class="small"
              [style.border-color]="util.daysPastColor(appointment['Date_Confirmation'])"
              [style.color]="util.daysPastColor(appointment['Date_Confirmation'])"
              matLine
            >
              Booked
              {{ appointment['Date_Confirmation'] | TimeLabelPast }}
              ago
              <span
                class="backgroundColor"
                [style.background]="util.daysPastColor(appointment['Date_Confirmation'])"
              ></span>
            </p>
          </mat-list-item>

          <mat-list-item
            class="date-view-bottom"
            *ngIf="appointment['Date_Booked'] !== '0000-00-00' && appointment['Date_Confirmation'] == '0000-00-00'"
          >
            <p
              class="small"
              [style.border-color]="util.daysPastColor(appointment['Date_Booked'])"
              [style.color]="util.daysPastColor(appointment['Date_Booked'])"
              matLine
            >
              Suggested
              {{ appointment['Date_Booked'] | TimeLabelPast }} ago
              <span class="backgroundColor" [style.background]="util.daysPastColor(appointment['Date_Booked'])"></span>
            </p>
          </mat-list-item>
        </mat-list>
      </div>

      <!-- Information about {{ "KEYWORD.patient" | translate }}'s selection -->
      <div
        class="step step-3"
        [class.disabled]="
          (!appointment['Date_Missed'] || appointment['Date_Missed'] == '0000-00-00') &&
          (!appointment['Date_Attended'] || appointment['Date_Attended'] == '0000-00-00') &&
          (!appointment['Date_Canceled'] || appointment['Date_Canceled'] == '0000-00-00')
        "
      >
        <div class="row clearfix text-center">
          <mat-icon class="fas fa-user-clock"></mat-icon>
          <h5 class="sr-title rm-m">Attendance</h5>
          <hr />
        </div>

        <p
          class="nothingToDisplay text-center"
          *ngIf="
            (!appointment['Date_Missed'] || appointment['Date_Missed'] == '0000-00-00') &&
            (!appointment['Date_Attended'] || appointment['Date_Attended'] == '0000-00-00') &&
            (!appointment['Date_Canceled'] || appointment['Date_Canceled'] == '0000-00-00')
          "
        >
          No activity to display
        </p>

        <mat-list
          class="list-container"
          [hidden]="
            (!appointment['Date_Missed'] || appointment['Date_Missed'] == '0000-00-00') &&
            (!appointment['Date_Attended'] || appointment['Date_Attended'] == '0000-00-00') &&
            (!appointment['Date_Canceled'] || appointment['Date_Canceled'] == '0000-00-00')
          "
        >
          <mat-list-item *ngIf="appointment['Date_Attended'] != '0000-00-00'">
            <p matLine>
              <span class="title">Attended:</span>
              <span class="value">{{ appointment['Date_Attended'] | customDate }}</span>
            </p>
          </mat-list-item>
          <mat-list-item *ngIf="appointment['Date_Missed'] != '0000-00-00'">
            <p matLine>
              <span class="title"
              >Missed:
                <span
                  class="fas fa-envelope comment"
                  *ngIf="appointment['Missed_Comment']"
                  (click)="
                    showComment('Missing appointment activity-log from the practice', appointment['Missed_Comment'])
                  "
                  [matTooltip]="'Missing appointment note from the ' + ('KEYWORD.practice' | translate)"
                >
                </span>
              </span>
              <span class="value"> {{ appointment['Date_Missed'] | customDate }}</span>
            </p>
          </mat-list-item>

          <mat-list-item *ngIf="appointment['Date_Canceled'] != '0000-00-00'">
            <p matLine>
              <span class="title"
              >Canceled:

                <span
                  class="fas fa-envelope comment"
                  *ngIf="appointment['Canceled_Comment']"
                  (click)="showComment('Cancelling appointment activity-log', appointment['Canceled_Comment'])"
                  matTooltip="Cancelling appointment note "
                >
                </span>
              </span>
              <span class="value"> {{ appointment['Date_Canceled'] | customDate }}</span>
            </p>
          </mat-list-item>

          <mat-list-item class="date-view-bottom">
            <p
              class="small"
              [style.border-color]="util.daysPastColor(appointment['LastModified_Human'])"
              [style.color]="util.daysPastColor(appointment['LastModified_Human'])"
              matLine
            >
              Updated
              {{ appointment['LastModified_Human'] | TimeLabelPast }}
              ago
              <span
                class="backgroundColor"
                [style.background]="util.daysPastColor(appointment['LastModified_Human'])"
              ></span>
            </p>
          </mat-list-item>
        </mat-list>
      </div>
    </div>

    <div class="full-width flex">
      <h4 class="primary-color subtitle rm-mb rm-mt">{{"KEYWORD.patient" | translate | titlecase}} Details</h4>
      <button class="ml-auto" (click)="gotoCustomerPage()" mat-raised-button color="accent">
        <mat-icon>person</mat-icon>
        <span>View Profile</span>
      </button>
    </div>

    <div class="full-width flex row">
      <div class="clearfix customer-picture text-center">
        <app-customer-prospect-view-picture
          class="supplierIconAppointmentRequest pull-left mr"
          [hideZoomButton]="false"
          [patientID]="appointment['CustomerOrProspect_Key']"
        >
        </app-customer-prospect-view-picture>
      </div>
      <div class="clearfix">
        <div class="full-width clearfix flex" *ngIf="appointment.CustomerOrProspect_CalculatedName">
          <div class="clearfix details-stacked full-width">
            <mat-icon class="ico">person</mat-icon>
            <div class="row-content clearfix">
              <p class="rm-mb rm-mt">
                {{ appointment.CustomerOrProspect_CalculatedName }}
              </p>
            </div>
          </div>
        </div>

        <div class="full-width clearfix flex" *ngIf="appointment.CustomerOrProspect_Mobile">
          <div class="clearfix details-stacked full-width">
            <mat-icon class="ico">phone</mat-icon>
            <div class="row-content clearfix">
              <p class="rm-mb rm-mt">
                {{ appointment.CustomerOrProspect_Mobile | customPhone }}
              </p>
            </div>
          </div>
        </div>
        <div class="full-width clearfix flex" *ngIf="appointment.CustomerOrProspect_Email">
          <div class="clearfix details-stacked full-width">
            <mat-icon class="ico">email</mat-icon>
            <div class="row-content clearfix">
              <p class="rm-mb rm-mt">
                {{ appointment.CustomerOrProspect_Email }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="full-width flex">
      <h4 class="primary-color subtitle rm-mb rm-mt">Appointment Details</h4>
      <button class="ml-auto" mat-raised-button color="accent">
        <mat-icon>find_in_page</mat-icon>
        <span>View Source</span>
      </button>
    </div>

    <div class="full-width flex" *ngIf="appointment['Source.Label']">
      <div class="clearfix details-stacked full-width">
        <mat-icon class="ico">find_in_page</mat-icon>
        <mat-label class="detail-label">Source of booking:</mat-label>
        <p class="rm-mb rm-mt">
          {{ appointment['Source.Label'] }}
        </p>
      </div>
    </div>
    <div class="full-width flex" *ngIf="appointment['Type.Label']">
      <div class="clearfix details-stacked full-width">
        <mat-icon class="ico">favorite</mat-icon>
        <mat-label class="detail-label">Reason for visit:</mat-label>
        <p class="rm-mb rm-mt">
          {{ appointment['Type.Label'] }}
        </p>
      </div>
    </div>

    <div class="full-width flex" *ngIf="appointment['SubType.Label']">
      <div class="clearfix details-stacked full-width">
        <mat-icon class="ico">favorite</mat-icon>
        <mat-label class="detail-label">More Details:</mat-label>
        <p class="rm-mb rm-mt">
          {{ appointment['SubType.Label'] }}
        </p>
      </div>
    </div>

    <div class="full-width flex" *ngIf="appointment.Comment">
      <div class="clearfix details-stacked full-width">
        <mat-icon class="ico">description</mat-icon>
        <mat-label class="detail-label">Additional Comments:</mat-label>
        <pre class="additionalComment">{{ appointment.Comment }}</pre>
      </div>
    </div>

    <div class="full-width flex">
      <h4 class="primary-color subtitle rm-mb rm-mt">Next Steps</h4>
    </div>

    <p>Respond to your {{ "KEYWORD.patient" | translate }}s with one of the following options</p>
  </mat-dialog-content>

  <div class="drop-buttons">
    <ng-container *ngFor="let a of appointmentUtil.getOperations(appointment, sessionType)">
      <button
        class="ml mr"
        *ngIf="a.code !== 'proposeBooking'"
        [style.background]="a['color']"
        (click)="runActionEvent(appointment, a.code, false)"
        mat-raised-button
        color="accent"
      >
        <mat-icon>{{ a.icon }}</mat-icon>
        <span>{{ a.label }}</span>
      </button>
    </ng-container>

    <button class="ml mr" (click)="scheduleAppointment()" mat-raised-button color="primary">
      <mat-icon>alarm_add</mat-icon>
      <span>Schedule Appointment</span>
    </button>
  </div>
</mat-card>

<app-appointment-action-hidden [runAction]="runAction" (result)="getActionResult($event)">
</app-appointment-action-hidden>
