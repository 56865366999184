import { Component, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'app-contract-arrangement-modal',
  templateUrl: './contract-arrangement-modal.component.html',
  styleUrls: ['./contract-arrangement-modal.component.css'],
})
export class ContractArrangementModalComponent implements OnInit {
  closeModal = new EventEmitter();

  constructor() {}

  ngOnInit() {}
}
