<ipv-container-modal [enableDragging]="true">
  <ng-container banner>
    <div style="display: flex; justify-content: space-between">
      <div style="display: flex">
        <mat-icon> emoji_emotions</mat-icon>

        <div style="display: block">
          <h2 class="summary-header rm-m">Emoji Keyboard</h2>
        </div>
      </div>

      <button class="btn-close btn-clear mat-button white" (click)="close()" style="height: 24px" matDialogClose>
        <mat-icon>clear</mat-icon>
      </button>
    </div>
  </ng-container>

  <ng-container content>
    <emoji-mart [showPreview]="false" (emojiClick)="selectEmoji($event)"></emoji-mart>
  </ng-container>
</ipv-container-modal>
