<div class="rel">
  <div class="card-header primary-gradient-img clearfix confirmModal">
    <button
      class="btn-close btn-clear mat-button smt white custom-close"
      [ngClass]="!title ? 'no-title-close' : ''"
      (click)="closeEvent()"
    >
      <mat-icon>clear</mat-icon>
    </button>
    <div class="full-width flex main-title-container" [ngClass]="{ 'main-icon-container-no-title': !title }">
      <div class="main-icon-container text-center">
        <mat-icon class="main-icon accent-color" *ngIf="isFontAwsome(icon) != true"> {{ icon || 'info' }}</mat-icon>

        <mat-icon
          class="main-icon accent-color {{ icon || 'fas fa-info' }}"
          *ngIf="isFontAwsome(icon) == true"
        ></mat-icon>
      </div>
      <div class="row full-width title-container clearfix">
        <div class="title-container-sub">
          <h2 class="text-left title-header rm-m">
            {{ title }}
          </h2>
        </div>
      </div>
    </div>
  </div>

  <mat-dialog-content>
    <div class="full-width">
      <ngx-monaco-editor class="min-height-html" *ngIf="isCSS != true" [(ngModel)]="content" [options]="editorOptions">
      </ngx-monaco-editor>

      <ngx-monaco-editor
        class="min-height-html"
        *ngIf="isCSS == true"
        [(ngModel)]="content"
        [options]="editorOptionsCss"
      >
      </ngx-monaco-editor>
    </div>
  </mat-dialog-content>
  <hr/>
  <div class="drop-button text-center mt full-width">
    <button class="pull-left" (click)="closeEvent()" mat-raised-button color="primary">Close</button>

    <button class="pull-right" (click)="sendResult()" mat-raised-button color="accent">Save</button>
    <!-- Can optionally provide a result for the closing dialog. -->
  </div>
</div>
