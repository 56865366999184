<div class="rel" *ngIf="listDB.firstLoadEvent == false">
  <div class="row clearfix padded rel">
    <h1 class="dataHeader rm-mt transparent">Fetching data...</h1>
  </div>
</div>
<div [hidden]="listDB.data.length == 0">
  <div>
    <div class="head-content clearfix">
      <button class="pull-right mr" (click)="create()" mat-raised-button color="accent">Create New</button>

      <h2 class="dataHeader rm-m pull-left">
        {{ title }}
        <span class="badge" [endVal]="dataSource?.filteredData?.length" countUp></span>
      </h2>
    </div>
    <mat-card class="list-card no-box-card">
      <!-- Search -->
      <div class="row clearfix mb searchArea primary-gradient-img">
        <div class="row clearfix flex">
          <div class="full-width flex animated fadeInDown">
            <mat-form-field class="white full-width" appearance="outline">
              <mat-label>Label,Description...</mat-label>
              <input
                class="clearfix"
                (input)="setFilter($event.target.value, 'fullName')"
                name="Name"
                placeholder="Label,Description,Email,Address,Phone..."
                matInput
              />
            </mat-form-field>
          </div>
        </div>
      </div>
      <!-- Table -->
      <mat-table class="list-table" #table [dataSource]="dataSource" matSort>
        <ng-container cdkColumnDef="Picture" sticky>
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> </mat-header-cell>
          <mat-cell class="text-center" *cdkCellDef="let row" (click)="view(row['ID'])">
            <app-tast-manager-picture
              class="supplierIcon2-thumbnail prod text-center"
              *ngIf="row"
              [hideZoomButton]="true"
              [taskID]="row['ID']"
            >
            </app-tast-manager-picture>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Label">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Label</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="view(row['ID'])" matTooltip="{{ row['Name'] }}">
            <h3>{{ row['Name'] }}</h3>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Type">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Type</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="view(row['ID'])" matTooltip="{{ row['Type'] }}">
            {{ row['Type'] }}
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Action">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header>Action </mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="view(row['ID'])" matTooltip="{{ row['Action'] }}">
            {{ row['Action'] }}
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Date">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Created At</mat-header-cell>
          <mat-cell
            *cdkCellDef="let row"
            (click)="view(row['ID'])"
            matTooltip="{{ row['DateTimeCreated'] | customDateTime }}"
          >
            {{ row.DateTimeCreated | TimeLabelPast }} ago
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Priority">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Priority</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="view(row['ID'])">
            <span
              class="chip-module smr pull-left mat-elevation-z1"
              *ngIf="row['Priority_Code'] === 'High'"
              style="background: rgb(228, 28, 28)"
            >
              {{ row['Priority_Label'] }}</span
            >

            <span
              class="chip-module smr pull-left mat-elevation-z1"
              *ngIf="row['Priority_Code'] === 'Medium'"
              style="background: rgb(221, 100, 2)"
            >
              {{ row['Priority_Label'] }}</span
            >

            <span
              class="chip-module smr pull-left mat-elevation-z1"
              *ngIf="row['Priority_Code'] === 'Low'"
              style="background: rgb(32, 194, 0)"
            >
              {{ row['Priority_Label'] }}</span
            >

            <span
              class="chip-module smr pull-left mat-elevation-z1"
              *ngIf="row['Priority_Code'] === 'Optional'"
              style="background: #0584d8"
            >
              {{ row['Priority_Label'] }}</span
            >

            <span class="chip-module smr pull-left mat-elevation-z1" *ngIf="row['Priority_Code'] === ''">
              Not Defined</span
            >
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Actions">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Actions</mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <div style="float: right">
              <button [matMenuTriggerFor]="menu" mat-icon-button>
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button *ngIf="row['ID']" (click)="view(row['ID'])" mat-menu-item>
                  <mat-icon>visibility</mat-icon>
                  <span>View</span>
                </button>

                <button *ngIf="row['ID']" (click)="Edit(row['ID'])" mat-menu-item>
                  <mat-icon>edit</mat-icon>
                  <span>Edit</span>
                </button>

                <hr/>

                <button *ngIf="row['ID']" (click)="delete(row['ID'])" mat-menu-item>
                  <mat-icon>delete</mat-icon>
                  <span>delete</span>
                </button>
              </mat-menu>
            </div>
          </mat-cell>
        </ng-container>
        <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *cdkRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
      <mat-paginator
        #paginator
        [length]="dataSource.filteredData.length"
        [pageIndex]="0"
        [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions"
      >
      </mat-paginator>
    </mat-card>
  </div>
</div>
<div class="clearfix full-width" *ngIf="listDB.data.length == 0 && listDB.firstLoadEvent != false">
  <app-empty-list-message
    [message]="'There are no Task to display.'"
    [actionIcon]="'add'"
    [isAction]="true"
    [actionLabel]="'New Task'"
    (actionEvent)="create()"
  >
  </app-empty-list-message>
</div>
