import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSortModule } from '@angular/material/sort';
import { MomentModule } from 'ngx-moment';
import { NgPipesModule } from 'ngx-pipes';
import { SharedModule } from '../../shared/shared.module';
import { MessageModule } from '../message/message.module';
import { OperatorModule } from '../operator/operator.module';
import { DefaultMessageListGlobalComponent } from './default-message-list-global/default-message-list-global.component';
import { DefaultMessageListOverviewComponent } from './default-message-list-overview/default-message-list-overview.component';
import { DefaultMessageListComponent } from './default-message-list/default-message-list.component';
import { DefaultMessageLookupComponent } from './default-message-lookup/default-message-lookup.component';
import { DefaultMessageViewComponent } from './default-message-view/default-message-view.component';
import { DefaultSmsSettingsComponent } from './default-sms-settings/default-sms-settings.component';
import { MessageTemplateCreateEditComponent } from './message-template-create-edit/message-template-create-edit.component';
import { DefaultMessageRoutingModule } from './shared/default-message-routing.module';
import { DefaultMessageService } from './shared/default-message.service';

@NgModule({
  imports: [
    NgPipesModule,
    CommonModule,
    MomentModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    CdkTableModule,
    DefaultMessageRoutingModule,
    MatSortModule,
    OperatorModule,
    MessageModule,
  ],
  exports: [
    DefaultMessageListComponent,
    DefaultMessageViewComponent,
    DefaultMessageListGlobalComponent,
    DefaultMessageListOverviewComponent,
    DefaultMessageLookupComponent,
    MessageTemplateCreateEditComponent,
    DefaultSmsSettingsComponent,
  ],
  declarations: [
    DefaultMessageListComponent,
    DefaultMessageViewComponent,
    DefaultMessageListGlobalComponent,
    DefaultMessageListOverviewComponent,
    DefaultMessageLookupComponent,
    MessageTemplateCreateEditComponent,
    DefaultSmsSettingsComponent,
  ],
  providers: [DefaultMessageService],
})
export class DefaultMessageModule {}
