import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ConfirmDialogMessageComponent } from '../../../shared/components/confirm-dialog-message/confirm-dialog-message.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { CustomDatePipe } from '../../../shared/pipes/custom-date.pipe';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { MerchantTradingHoursViewComponent } from '../../merchant/merchant-trading-hours-view/merchant-trading-hours-view.component';
import { MerchantService } from '../../merchant/shared/merchant.service';
import { AppointmentService } from '../shared/appointment.service';
import { AppointmentUtilClass } from '../shared/appointmentUtil';

import { HeaderService } from '../../../shared/services/header.service';
import { UtilsService } from '../../../shared/services/utils.service';

@Component({
  selector: 'app-appointment-public-operation',
  templateUrl: './appointment-public-operation.component.html',
  styleUrls: ['./appointment-public-operation.component.css'],
})
export class AppointmentPublicOperationComponent implements OnInit {
  @Input()
  appointmentID;
  merchantProfile;
  merchantCover;
  defaultCover = 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/hhd-blu.png';
  defaultProfile =
    'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/profile-placeholder.png';

  @Input()
  appointment;
  sessionType = 'guest';

  message;
  loadFirst = false;
  settings = Settings.global;
  title = 'Practice Note';
  runAction = new EventEmitter();
  appointmentUtil = new AppointmentUtilClass();
  @Input()
  isFullDetails = true;
  @Output()
  requestNewAppointment = new EventEmitter();
  util = new UtilsClass();
  merchant;
  isCreated = false;
  displayFooter = true;
  isNotFound = false;
  isModuleConsumerPortalActive = Settings.global['isModuleConsumerPortalActive'];

  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private merchantService: MerchantService,
    private appointmentService: AppointmentService,
    private authenticationService: AuthenticationService,
    private customDate: CustomDatePipe,
    private utilService: UtilsService
  ) {}

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      if (this.isFullDetails != true && this.appointment && this.appointment.ID) {
        this.build();
      } else {
        if (params['footer'] && params['footer'] == 'none') {
          this.displayFooter = false;
        }
        if (!params['appointmentID'] && this.isFullDetails == true) {
          this.router.navigate(['/404']);
        } else {
          this.appointmentID = params['appointmentID'];

          this.authenticationService.getSessionType().subscribe((res) => {
            if (res) {
              this.sessionType = res;
            }

            this.setup();
          });
        }
      }
    });
  }

  setup() {
    if (this.appointmentID) {
      if (this.appointmentID) {
        this.utilService.getAccessAppointmentList(this.appointmentID).subscribe((access) => {
          if (access) {
            this.isModuleConsumerPortalActive = access['isModuleConsumerPortalActive'];
          }
        });
      }

      this.appointmentService.getAppointmentDetails(this.appointmentID, 'guest').subscribe((res) => {
        if (res && res['Merchant_key']) {
          this.merchantService.getMerchantDetails(res['Merchant_key'], {}, this.sessionType).subscribe((m) => {
            this.merchant = m;
            if (this.merchant['ThirdPartyBooking_URL']) {
              this.isNotFound = true;
            }
            this.appointment = res;
            this.build();
          });
        } else {
          this.router.navigate(['/404']);
        }
      });
    } else if (this.appointment) {
      if (this.appointment['ID']) {
        this.utilService.getAccessAppointmentList(this.appointment['ID']).subscribe((access) => {
          if (access) {
            this.isModuleConsumerPortalActive = access['isModuleConsumerPortalActive'];
          }
        });
      }
      if (this.appointment && this.appointment['Merchant_key']) {
        this.merchantService
          .getMerchantDetails(this.appointment['Merchant_key'], {}, this.sessionType)
          .subscribe((m) => {
            this.merchant = m;

            if (this.merchant['ThirdPartyBooking_URL']) {
              this.isNotFound = true;
            }
            this.build();
          });
      } else {
        this.router.navigate(['/404']);
      }
    }
  }

  build() {
    if (this.appointment) {
      if (this.sessionType == 'customer' && this.isModuleConsumerPortalActive == false) {
        HeaderService.hideCustomerPortal.emit(false);
      } else if (this.isModuleConsumerPortalActive == false) {
        HeaderService.hideCustomerPortal.emit(true);
      } else {
        HeaderService.hideCustomerPortal.emit(false);
      }

      const signupObject = {
        cardID: null,
        appointmentID: null,
      };
      if (this.appointment && this.appointment.ID) {
        signupObject.appointmentID = this.appointment.ID;
      }

      if (this.appointment && this.appointment.CustomerOrProspect_Key) {
        signupObject.cardID = this.appointment.CustomerOrProspect_Key;
      }

      AuthenticationService.signupObject.emit(signupObject);

      if (this.merchant) {
        HeaderService.MerchantObject.emit(this.merchant);
        HeaderService.MerchantName.emit(this.merchant['TradingAs']);
      }

      this.merchantCover = this.merchantService.getPracticeAppointmentCoverStreamLink(this.appointment['Merchant_key']);
      this.merchantProfile = this.merchantService.getPracticePicStreamLink(this.appointment['Merchant_key']);

      if (document && document.getElementById('profile-background')) {
        document.getElementById('profile-background').style.backgroundImage =
          'url(' + this.merchantProfile + '),url(' + this.defaultProfile + ')';
      }

      if (document && document.getElementById('cover-background')) {
        document.getElementById('cover-background').style.backgroundImage =
          'url(' + this.merchantCover + '),url(' + this.defaultCover + ')';
      }
    }
  }

  runActionEvent(item, operation, isGroup = false) {
    if (item) {
      const p = {
        item,
        operation,
        isGroup,
      };
      this.runAction.emit(p);
    }
  }

  getActionResult(r) {
    if (r && r.ID) {
      if (this.isFullDetails == true) {
        this.isCreated = true;
      } else {
        this.appointment = r;
        this.build();
      }
    }
  }

  requestDateChangeAppointment(item) {
    let _date;
    let _time;

    if (item['Date']) {
      _date = this.customDate.transform(item['Date']);
    }
    if (item['Time']) {
      _time = this.customDate.transform(item['Date'] + ' ' + item['Time'], 'HH:ss A');
    }

    const ref = RootAppComponent.dialog.open(ConfirmDialogMessageComponent, {
      data: {
        icon: 'alarm_off',
        title: `Request changing your appointment date `,
        yesLabel: 'Submit',
        noLabel: 'Cancel',
        textLabel: '',
        description:
          `<p>Are you sure  you want to request changing the appointment date  suggested by your practice ` +
          ` on <strong>${_date}</strong> at <strong>${_time}</strong>  </p>`,
        messageLabel: 'You can add a comment to be sent to the practice',
        dateTimeLabel: 'Booking date and time',
        isDateRequired: false,
        isDateTimeRequired: false,
        isDateTimeInput: false,
        isDateInput: false,
        isTextInputRequired: false,
        isTextAreaRequired: false,
        isTextArea: true,
        isTextInput: false,

        isPolicy: false,
        isPolicyRequired: false,
        policyLabel: 'I agree to ',
        policyName: item.Merchant_CalculatedName + ' Cancelling policy',

        isCheckbox: false,
        checkboxLabel: 'Notify the patient',
      },
      width: '650px',
    });
    ref.componentInstance.closeModal.subscribe((res) => {
      ref.close();
    });

    ref.componentInstance.onConfirm.subscribe((res) => {
      if (res && res.confirm == true) {
        const data = {
          id: item.ID,
          operation: 'rejectBooking',
          comment: res.text,
        };
        this.appointmentService.editAppointment(item.ID, data, 'guest').subscribe((res) => {
          if (res) {
            this.isCreated = true;
            NotifyAppComponent.displayToast('success', 'Reject appointment', 'Your request is sent');
            ref.close();
          }
        });
      } else {
        ref.close();
      }
    });
  }

  openLink(l) {
    if (l.indexOf('http') === -1) {
      l = 'https://' + l;
    }
    window.open(l, '_blank');
  }

  replaceAllString(e) {
    if (e && typeof e == 'string') {
      return String(e).replace(/�/g, "'");
    }
  }

  contactMerchant() {
    const data = {
      targetType: 'merchant',
      targetID: this.appointment.Merchant_key,
      asGuest: true,
      asProfile: false,
      asPractice: false,
      subject: 'About my appointment' || null,
    };

    AuthenticationService.contactInputEvent.emit(data);
  }

  requestAppointment(item) {
    if (this.merchant && this.merchant['ThirdPartyBooking_URL']) {
      window.location.href = this.merchant['ThirdPartyBooking_URL'];
    } else if (item && item['Merchant_key'] && this.settings['publicSiteFrontendLink'] && this.isFullDetails == true) {
      window.location.href = this.settings['publicSiteFrontendLink'] + '/appointment-practice/' + item['Merchant_key'];
    } else if (this.isFullDetails != true) {
      this.requestNewAppointment.emit(true);
    }
  }

  tradingHours() {
    const ref = RootAppComponent.dialog.open(MerchantTradingHoursViewComponent, {
      data: this.appointment['Merchant_key'],
      width: '450px',
    });
    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  calendarSynced(appointment) {
    this.appointment = appointment;
  }

  cleanURL(url) {
    if (url) {
      if (url.indexOf('https://') != -1) {
        return url.replace('https://', '');
      } else if (url.indexOf('http://') != -1) {
        return url.replace('http://', '');
      }
    }

    return url;
  }
}
