/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./main-redirection.component";
import * as i2 from "@angular/router";
var styles_MainRedirectionComponent = [""];
var RenderType_MainRedirectionComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_MainRedirectionComponent, data: {} });
export { RenderType_MainRedirectionComponent as RenderType_MainRedirectionComponent };
export function View_MainRedirectionComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_MainRedirectionComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-main-redirection", [], null, null, null, View_MainRedirectionComponent_0, RenderType_MainRedirectionComponent)), i0.ɵdid(1, 114688, null, 0, i1.MainRedirectionComponent, [i2.Router, i2.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MainRedirectionComponentNgFactory = i0.ɵccf("app-main-redirection", i1.MainRedirectionComponent, View_MainRedirectionComponent_Host_0, {}, {}, []);
export { MainRedirectionComponentNgFactory as MainRedirectionComponentNgFactory };
