import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output, SimpleChanges } from '@angular/core';

import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MarketingService } from '../shared/marketing.service';

@Component({
  selector: 'app-marketing-view-logo',
  templateUrl: './marketing-view-logo.component.html',
  styleUrls: ['./marketing-view-logo.component.css'],
})
export class MarketingViewLogoComponent implements OnInit {
  @Input() marketingID;

  @Input() LastModified = null;
  @Input() hideZoomButton = false;
  @Output() close = new EventEmitter();

  profileLink;

  constructor(
    private marketingService: MarketingService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      this.marketingID = data;
    }
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.marketingID && changes.marketingID.previousValue != changes.marketingID.currentValue) {
      if (this.marketingID) {
        this.getPicture();
      } else {
        this.profileLink = null;
      }
    }
  }

  getPicture() {
    this.profileLink = null;

    if (this.marketingID) {
      this.profileLink = this.marketingService.getMarketingLogoStreamLink(this.marketingID, this.LastModified);
    }
  }

  closeEvent() {
    this.close.emit(true);
  }
}
