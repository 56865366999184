/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./third-party-access-client-overview.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../third-party-client-list/third-party-client-list.component.ngfactory";
import * as i3 from "../third-party-client-list/third-party-client-list.component";
import * as i4 from "../shared/third-party.service";
import * as i5 from "@angular/router";
import * as i6 from "../../authentication/shared/authentication.service";
import * as i7 from "../third-party-access-list/third-party-access-list.component.ngfactory";
import * as i8 from "../third-party-access-list/third-party-access-list.component";
import * as i9 from "../../../shared/components/empty-list-message/empty-list-message.component.ngfactory";
import * as i10 from "../../../shared/components/empty-list-message/empty-list-message.component";
import * as i11 from "@angular/common";
import * as i12 from "./third-party-access-client-overview.component";
var styles_ThirdPartyAccessClientOverviewComponent = [i0.styles];
var RenderType_ThirdPartyAccessClientOverviewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ThirdPartyAccessClientOverviewComponent, data: {} });
export { RenderType_ThirdPartyAccessClientOverviewComponent as RenderType_ThirdPartyAccessClientOverviewComponent };
function View_ThirdPartyAccessClientOverviewComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-third-party-client-list", [["class", "full-width mb"]], null, [[null, "updateAllTemplate"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("updateAllTemplate" === en)) {
        var pd_0 = (_co.updateAllTemplate($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ThirdPartyClientListComponent_0, i2.RenderType_ThirdPartyClientListComponent)), i1.ɵdid(1, 770048, null, 0, i3.ThirdPartyClientListComponent, [i4.ThirdPartyService, i5.Router, i6.AuthenticationService], { canCreate: [0, "canCreate"], title: [1, "title"], templateID: [2, "templateID"], pageSize: [3, "pageSize"] }, { updateAllTemplate: "updateAllTemplate" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = false; var currVal_1 = _co.titleApplication; var currVal_2 = _co.templateID; var currVal_3 = _co.pageSizeApp; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_ThirdPartyAccessClientOverviewComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-third-party-access-list", [["class", "full-width mt mb"]], null, null, null, i7.View_ThirdPartyAccessListComponent_0, i7.RenderType_ThirdPartyAccessListComponent)), i1.ɵdid(1, 770048, null, 0, i8.ThirdPartyAccessListComponent, [i4.ThirdPartyService, i6.AuthenticationService], { title: [0, "title"], templateID: [1, "templateID"], canCreate: [2, "canCreate"], pageSize: [3, "pageSize"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.titleAccess; var currVal_1 = _co.templateID; var currVal_2 = false; var currVal_3 = _co.pageSizeAccess; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_ThirdPartyAccessClientOverviewComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-empty-list-message", [], null, [[null, "actionEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("actionEvent" === en)) {
        var pd_0 = (_co.view() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_EmptyListMessageComponent_0, i9.RenderType_EmptyListMessageComponent)), i1.ɵdid(1, 114688, null, 0, i10.EmptyListMessageComponent, [], { message: [0, "message"], isAction: [1, "isAction"], actionIcon: [2, "actionIcon"], actionLabel: [3, "actionLabel"] }, { actionEvent: "actionEvent" })], function (_ck, _v) { var currVal_0 = "There are no API Keys linked to this template."; var currVal_1 = true; var currVal_2 = "visibility"; var currVal_3 = "View Template"; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_ThirdPartyAccessClientOverviewComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-empty-list-message", [], null, [[null, "actionEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("actionEvent" === en)) {
        var pd_0 = (_co.view() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_EmptyListMessageComponent_0, i9.RenderType_EmptyListMessageComponent)), i1.ɵdid(1, 114688, null, 0, i10.EmptyListMessageComponent, [], { message: [0, "message"], isAction: [1, "isAction"], actionIcon: [2, "actionIcon"], actionLabel: [3, "actionLabel"] }, { actionEvent: "actionEvent" })], function (_ck, _v) { var currVal_0 = "There are no Third-Party Application or  API Keys linked to this template."; var currVal_1 = true; var currVal_2 = "visibility"; var currVal_3 = "View Template"; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_ThirdPartyAccessClientOverviewComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ThirdPartyAccessClientOverviewComponent_1)), i1.ɵdid(1, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ThirdPartyAccessClientOverviewComponent_2)), i1.ɵdid(3, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ThirdPartyAccessClientOverviewComponent_3)), i1.ɵdid(5, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ThirdPartyAccessClientOverviewComponent_4)), i1.ɵdid(7, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.displayApplication == true) && (_co.isPromoterOrAdmin == true)); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.displayAccess == true); _ck(_v, 3, 0, currVal_1); var currVal_2 = (((_co.displayAccess != true) && (_co.isPromoterOrAdmin != true)) && (_co.loaded == true)); _ck(_v, 5, 0, currVal_2); var currVal_3 = ((((_co.displayAccess != true) && (_co.displayApplication != true)) && (_co.isPromoterOrAdmin == true)) && (_co.loaded == true)); _ck(_v, 7, 0, currVal_3); }, null); }
export function View_ThirdPartyAccessClientOverviewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-third-party-access-client-overview", [], null, null, null, View_ThirdPartyAccessClientOverviewComponent_0, RenderType_ThirdPartyAccessClientOverviewComponent)), i1.ɵdid(1, 114688, null, 0, i12.ThirdPartyAccessClientOverviewComponent, [i4.ThirdPartyService, i6.AuthenticationService, i5.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ThirdPartyAccessClientOverviewComponentNgFactory = i1.ɵccf("app-third-party-access-client-overview", i12.ThirdPartyAccessClientOverviewComponent, View_ThirdPartyAccessClientOverviewComponent_Host_0, {}, {}, []);
export { ThirdPartyAccessClientOverviewComponentNgFactory as ThirdPartyAccessClientOverviewComponentNgFactory };
