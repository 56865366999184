import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { IframeViewComponent } from '../../../shared/components/iframe-view/iframe-view.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { CustomerProspectViewModelComponent } from '../../customer-prospect/customer-prospect-view-model/customer-prospect-view-model.component';
import { DentistViewComponent } from '../../dentist/dentist-view/dentist-view.component';
import { MerchantViewComponent } from '../../merchant/merchant-view/merchant-view.component';
import { ProductUtil } from '../../product/shared/productUtil.type';
import { InstantOfferService } from '../shared/instant-finance-offer.service';
@Component({
  selector: 'instant-offer-view',
  templateUrl: './instant-offer-view.component.html',
  styleUrls: ['./instant-offer-view.component.css'],
})
export class InstantOfferViewComponent implements OnInit {
  @Output()
  close = new EventEmitter();

  maxLength = 220;
  description;
  message;

  settings = Settings.global;
  @Input()
  instantOfferID;
  utils = new UtilsClass();
  isSubmit = false;
  isCollapsed = true;
  messageMode = 'all';
  instantOffer;
  getLink = new EventEmitter();
  sessionType;
  productUtil = new ProductUtil();
  isPromoterOrAdmin = false;
  theme;

  getInstantOffer = new EventEmitter();
  getInstantOfferID = new EventEmitter();
  getInstantOfferURL = new EventEmitter();
  @Output()
  getResult = new EventEmitter();

  isModal = false;

  constructor(
    private activeRoute: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private instantOfferService: InstantOfferService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.isSubmit != null) {
        this.isSubmit = data.isSubmit;
        this.isModal = true;
      }

      if (data && data.instantOfferID) {
        this.instantOfferID = data.instantOfferID;

        this.isModal = true;
      }
    }
  }

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      if (params['instantOfferID']) {
        this.instantOfferID = params['instantOfferID'];
        this.isModal = false;
      }

      this.authenticationService.getSessionType().subscribe((SessionType) => {
        this.sessionType = SessionType;
        this.authenticationService.isPromoterOrAdmin().subscribe((r) => {
          this.isPromoterOrAdmin = r;

          this.init();
        });
      });
    });
  }

  init() {
    if (this.instantOfferID) {
      this.instantOfferService
        .getInstantOffer(this.instantOfferID, { selectDocument: true }, this.sessionType)
        .subscribe((r) => {
          if (r && r.ID) {
            this.instantOffer = r;

            this.setup();
          }
        });
    }
  }
  setup() {
    if (this.instantOffer && this.instantOffer.ID) {
      this.theme = this.instantOffer.theme;

      if (this.instantOffer.Comment) {
        this.description = this.instantOffer.Comment.replace(/(\r\n|\r|\n)/g, '<br>');
        this.description = this.description.replace(/(<p><\/p>)/g, '<br>');
        this.description = this.description.replace(/(<p>&nbsp;<\/p>)/g, '<br>');

        this.description = this.description.replace(/(<br><br>)/g, '<br>');
      }

      if (this.instantOffer.Message) {
        this.message = this.instantOffer.Message.replace(/(\r\n|\r|\n)/g, '<br>');
        this.message = this.message.replace(/(<p><\/p>)/g, '<br>');
        this.message = this.message.replace(/(<p>&nbsp;<\/p>)/g, '<br>');
        this.message = this.message.replace(/(<br><br>)/g, '<br>');
      }
    }
  }

  closeEvent() {
    this.close.emit(true);
  }
  sendResult() {
    if (this.instantOffer && this.instantOffer.ID) {
      const instantOffer = `${Settings.global['publicSiteFrontendLink']}/offer/${this.instantOffer.ID}`;
      this.getResult.emit(this.instantOffer);
      this.getInstantOffer.emit(this.instantOffer);
      this.getInstantOfferID.emit(this.instantOffer.ID);

      this.getInstantOfferURL.emit(instantOffer);
    } else {
      this.getResult.emit(null);
    }
  }

  openIframe() {
    if (this.instantOffer && this.instantOffer.ID) {
      let instantOffer = `${Settings.global['publicSiteFrontendLink']}/offer/${this.instantOffer.ID}`;
      if (
        this.instantOffer['Source_Instant_Finance_Offer.Code'] === 'External' ||
        this.instantOffer['Source_Instant_Finance_Offer.Code'] === 'Direct'
      ) {
        instantOffer = `${Settings.global['publicSiteFrontendLink']}/offer-apply/${this.instantOffer.ID}`;
      }

      const ref2 = RootAppComponent.dialog.open(IframeViewComponent, {
        data: {
          link: instantOffer,
          isSubmit: this.isSubmit,
        },
        width: '80%',
        height: '92vh',
        panelClass: 'noCard',
      });

      ref2.componentInstance.close.subscribe((res) => {
        ref2.close();
      });

      ref2.componentInstance.getSubmit.subscribe((res) => {
        if (res) {
          ref2.close();
          ref2.afterClosed().subscribe((r) => {
            this.sendResult();
          });
        }
      });
    }
  }

  viewMerchant(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(MerchantViewComponent, {
        data: id,
        width: '600px',
      });
      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    }
  }
  viewDentist(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(DentistViewComponent, {
        data: id,
        width: '600px',
      });
      ref.componentInstance.close.subscribe((res) => {
        if (res == true) {
          ref.close();
        }
      });
    }
  }
  viewPatient(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(CustomerProspectViewModelComponent, {
        data: {
          patientID: id,
          isFullDetails: true,
        },
        width: '700px',
      });
      ref.componentInstance.close.subscribe((res) => {
        if (res == true) {
          ref.close();
        }
      });

      ref.componentInstance.detailedView.subscribe((res) => {
        if (res == true) {
          ref.close();
          ref.afterClosed().subscribe((r) => {
            this.closeEvent();
          });
        }
      });
    }
  }

  list() {
    this.router.navigate(['/merchant', { outlets: { page: ['instant-offer-list-overview'] } }]);
  }
  viewInvitation() {
    if (
      this.instantOffer &&
      this.instantOffer['Status.Code'] !== 'NotProcd' &&
      this.instantOffer['CustomerProspect_key'] &&
      this.instantOffer['Invitation_key']
    ) {
      this.router.navigate([
        '/merchant',
        {
          outlets: {
            page: [
              'customer-profile',
              this.instantOffer['CustomerProspect_key'],
              'treatment',
              this.instantOffer['Invitation_key'],
            ],
          },
        },
      ]);

      this.closeEvent();
    }
  }
}
