<ng-container *ngIf="headerSize === 'standard'">
  <h1 class="dataHeader rm-mt" style="display: flex; flex-direction: row; align-items: center">
    <img *ngIf="imageSrc" [src]="imageSrc | safe"/>
    {{ title }}
    <span *ngIf="counterValue"> : </span>
    <span class="badge" *ngIf="counterValue" [endVal]="counterValue" countUp></span>
  </h1>
</ng-container>

<ng-container *ngIf="headerSize === 'smaller'">
  <h2 class="dataHeader rm-mt" style="display: flex; flex-direction: row; align-items: center">
    <img *ngIf="imageSrc" [src]="imageSrc | safe"/>
    {{ title }}
    <span *ngIf="counterValue"> : </span>
    <span class="badge" *ngIf="counterValue" [endVal]="counterValue" countUp></span>
  </h2>
</ng-container>
