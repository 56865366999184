import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';

@Component({
  selector: 'document-link-detailed-view-redirection',
  templateUrl: './document-link-detailed-view-redirection.component.html',
  styleUrls: ['./document-link-detailed-view-redirection.component.css'],
})
export class DocumentLinkDetailedViewRedirectionComponent implements OnInit {
  landingPageID;
  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      if (params['landingPageID']) {
        this.landingPageID = params['landingPageID'];
      }

      if (this.landingPageID) {
        this.router.navigate(['/merchant', { outlets: { page: ['landing-page-overview', this.landingPageID] } }], {
          replaceUrl: true,
        });
      } else {
        this.router.navigate(['/merchant', { outlets: { page: ['landing-page-overview'] } }], { replaceUrl: true });
      }
    });
  }
}
