import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { TemplateReviewComponent } from '../../../core/helper/template-review/template-review.component';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { UtilsService } from '../../../shared/services/utils.service';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { ContactInputComponent } from '../../contact-us/contact-input/contact-input.component';
import { CustomerProspectBoostModalComponent } from '../../customer-prospect/customer-prospect-boost-modal/customer-prospect-boost-modal.component';
import { CustomerProspectService } from '../../customer-prospect/shared/customerProspect.service';
import { DentistViewComponent } from '../../dentist/dentist-view/dentist-view.component';
import { MerchantViewComponent } from '../../merchant/merchant-view/merchant-view.component';
import { SubAccountStatisticalReportComponent } from '../../performance/sub-account-statistical-report/sub-account-statistical-report.component';
import { ProductService } from '../../product/shared/product.service';
import { SocialMediaGalleryListComponent } from '../../social-media/social-media-gallery-list/social-media-gallery-list.component';
import { TagsService } from '../../tags/shared/tags.service';
import { TagsViewModalComponent } from '../../tags/tags-view-modal/tags-view-modal.component';
import { MarketingCampaginEditModalComponent } from '../marketing-campagin-edit-modal/marketing-campagin-edit-modal.component';
import { MarketingResendModalComponent } from '../marketing-resend-modal/marketing-resend-modal.component';
import { MarketingService } from '../shared/marketing.service';
import { MarketingUtilClass } from '../shared/marketingUtil';
import { MarketingFilterViewComponent } from '../../marketing-filter/marketing-filter-view/marketing-filter-view.component';

@Component({
  selector: 'app-marketing-campaign-view',
  templateUrl: './marketing-campaign-view.component.html',
  styleUrls: ['./marketing-campaign-view.component.css'],
  animations: [
    trigger('simpleFadeAnimation', [
      state('in', style({ opacity: 1, height: '*' })),
      transition(':enter', [style({ opacity: 0, height: 0 }), animate(300)]),
      transition(':leave', animate(300, style({ opacity: 0, height: 0 }))),
    ]),
  ],
})
export class MarketingCampaignViewComponent implements OnInit {
  @Input()
  campaignID;

  campaign;

  subjectArray = [];

  utilMarketing = new MarketingUtilClass();

  Math: any;

  @Output()
  close = new EventEmitter();

  selectedTemplateID;
  selectedTemplateTag;
  metaData = [];

  userData;

  @Input()
  showBack = true;

  @Input()
  patientFilter = true;

  @Input()
  merchantID;

  productGroup = [];
  productGroupLabel = '';
  isPromoterOrAdmin = false;
  selectedTemplate;
  emailContent = null;
  smsContent = null;
  emailSubject = null;
  displayTemplate = false;
  chartPatientData = [];
  chartVisitorData = [];

  successedEmail = 0;
  successedSMS = 0;

  failedEmail = 0;
  failedSMS = 0;

  allEmail = 0;
  allSMS = 0;

  allTarget = 0;
  successedTarget = 0;
  failedTarget = 0;

  allSent = 0;

  duplicatesEmail = 0;
  duplicatesSMS = 0;
  allDuplicates = 0;

  rejectedEmail = 0;
  rejectedSMS = 0;
  allRejected = 0;

  blockedEmail = 0;
  blockedSMS = 0;
  allBlocked = 0;

  emailReputation = 'none';
  emailReputationColor;
  subAccountObj;
  reputationValue;

  visitorChart = false;

  patientDataChart = false;
  boostDataChart = false;
  campaignDataChart = false;
  reputationDataChart = false;

  displayNonReachedList = false;

  chartCampaignDate = [];

  moreIsWorse = {
    '5': { color: '#8bc34a' },
    '10': { color: '#ffeb3b' },
    '15': { color: '#ffc107' },
    '20': { color: '#ff9800' },
    '25': { color: '#ff5722' },
  };

  moreIsBetter = {
    '5': { color: '#db291b' },
    '10': { color: '#ff5722' },
    '15': { color: '#ff9800' },
    '20': { color: '#ffc107' },
    '25': { color: '#ffeb3b' },
    '30': { color: '#8bc34a' },
  };

  moreIsBetterPerc = {
    '10': { color: '#db291b' },
    '25': { color: '#ff5722' },
    '30': { color: '#ff9800' },
    '45': { color: '#ffc107' },
    '70': { color: '#ffeb3b' },
    '85': { color: '#8bc34a' },
  };

  displayCampaignMessage = false;
  selectedMessageStatus;
  selectedMessageStatusNative;
  selectedNotReachedReason;

  totalExcluded = 0;
  tagId;

  @Output()
  hideGobackBtn = new EventEmitter();

  isModuleBoostReachActive = Settings.global['isModuleBoostReachActive'];

  isModuleExperimentalFeaturesActive = Settings.global['isModuleExperimentalFeaturesActive'];
  isModuleMarketingSocialMediaActive = Settings.global['isModuleMarketingSocialMediaActive'];
  isModal = false;
  SessionType = 'merchant';
  totalPatient;
  totalVisitor = 0;
  missDataPatient;
  noMarketConsentPatient;
  successDelivered;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private activeRoute: ActivatedRoute,
    private marketingService: MarketingService,
    private utilService: UtilsService,
    private productService: ProductService,
    private customerProspectService: CustomerProspectService,
    private tagsService: TagsService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.campaignID) {
        this.campaignID = data.campaignID;
        this.isModal = true;
      }

      if (data.templateID) {
        this.selectedTemplateID = data.templateID;
        this.isModal = true;
      }

      if (data.templateTag) {
        this.selectedTemplateTag = data.templateTag;
        this.isModal = true;
      }
    }
  }

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      if (params['campaignID']) {
        this.campaignID = params['campaignID'];
      }
      if (params['templateID']) {
        this.selectedTemplateID = params['templateID'];
      }

      if (params['templateTag']) {
        this.selectedTemplateTag = params['templateTag'];
      }

      this.utilService.getCurrentAccess().subscribe((access) => {
        if (access) {
          this.isModuleBoostReachActive = access['isModuleBoostReachActive'];
          this.isModuleExperimentalFeaturesActive = access['isModuleExperimentalFeaturesActive'];
          this.isModuleMarketingSocialMediaActive = access['isModuleMarketingSocialMediaActive'];
        }
      });

      this.authenticationService.getSessionType().subscribe((SessionType) => {
        this.SessionType = SessionType;

        this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
          this.isPromoterOrAdmin = res;

          this.marketingService.getMarketingDetails(this.campaignID, {}, this.SessionType).subscribe((res) => {
            if (res) {
              this.campaign = res;

              if (this.campaign['Template.Tag']) {
                this.selectedTemplateTag = this.campaign['Template.Tag'];
              }

              const payload = {};

              if (this.isPromoterOrAdmin == true) {
                payload['merchantID'] = this.campaign['Merchant_key'];
              }

              this.customerProspectService.getStatistics(payload, this.isPromoterOrAdmin).subscribe((res) => {
                if (res && res['global']) {
                  this.totalPatient = res['global']['Count(*)'];
                }

                if (this.campaign) {
                  const paylaod2 = {
                    campaignID: this.campaign['ID'],
                  };

                  this.tagsService.getTagsList({}, this.SessionType).subscribe((tags) => {
                    if (this.campaign['Tag.Label']) {
                      const tag = tags.find((tag) => tag['Label'] === this.campaign['Tag.Label']);
                      if (tag && tag.ID) {
                        this.tagId = tag['ID'];
                      }
                    }

                    if (this.campaign['Reached.Distinct']) {
                      this.successedTarget = Number(this.campaign['Reached.Distinct']);
                    }

                    if (this.campaign['Targets.Distinct)'] && this.campaign['Reached.Distinct']) {
                      this.failedTarget =
                        Number(this.campaign['Targets.Distinct']) - Number(this.campaign['Reached.Distinct']);
                    }

                    if (this.campaign['Reached.Email']) {
                      this.allEmail = Number(this.campaign['Reached.Email']);
                    }

                    if (this.campaign['Sent.Email']) {
                      this.successedEmail = Number(this.campaign['Sent.Email']);
                    }

                    if (this.campaign['Facebook_Visitors']) {
                      this.totalVisitor = this.totalVisitor + Number(this.campaign['Facebook_Visitors']);
                    }
                    if (this.campaign['Linkedin_Visitors']) {
                      this.totalVisitor = this.totalVisitor + Number(this.campaign['Linkedin_Visitors']);
                    }
                    if (this.campaign['Instagram_Visitors']) {
                      this.totalVisitor = this.totalVisitor + Number(this.campaign['Instagram_Visitors']);
                    }

                    if (this.campaign['Pinterest_Visitors']) {
                      this.totalVisitor = this.totalVisitor + Number(this.campaign['Pinterest_Visitors']);
                    }
                    if (this.campaign['Twitter_Visitors']) {
                      this.totalVisitor = this.totalVisitor + Number(this.campaign['Twitter_Visitors']);
                    }
                    if (this.campaign['Email_Visitors']) {
                      this.totalVisitor = this.totalVisitor + Number(this.campaign['Email_Visitors']);
                    }

                    if (this.campaign['Practice_Visitors']) {
                      this.totalVisitor = this.totalVisitor + Number(this.campaign['Practice_Visitors']);
                    }

                    if (this.campaign['Dental_Visitors']) {
                      this.totalVisitor = this.totalVisitor + Number(this.campaign['Dental_Visitors']);
                    }

                    if (this.campaign['SMS_Visitors']) {
                      this.totalVisitor = this.totalVisitor + Number(this.campaign['SMS_Visitors']);
                    }

                    if (this.campaign['Other_Visitors']) {
                      this.totalVisitor = this.totalVisitor + Number(this.campaign['Other_Visitors']);
                    }

                    if (this.totalVisitor > 0) {
                      if (this.campaign['Social_Media_Only'] != '1') {
                        this.chartVisitorData.push({
                          name: `Email ${Math.trunc(
                            (Number(this.campaign['Email_Visitors']) / Number(this.totalVisitor)) * 100
                          )}%`,
                          value: Number(this.campaign['Email_Visitors']),
                        });
                      }
                      this.chartVisitorData = [
                        {
                          name: `SMS ${Math.trunc(
                            (Number(this.campaign['SMS_Visitors']) / Number(this.totalVisitor)) * 100
                          )}%`,
                          value: Number(this.campaign['SMS_Visitors']),
                        },

                        {
                          name: `Practice ${Math.trunc(
                            (Number(this.campaign['Practice_Visitors']) / Number(this.totalVisitor)) * 100
                          )}%`,
                          value: Number(this.campaign['Practice_Visitors']),
                        },

                        {
                          name: `Dental ${Math.trunc(
                            (Number(this.campaign['Dental_Visitors']) / Number(this.totalVisitor)) * 100
                          )}%`,
                          value: Number(this.campaign['Dental_Visitors']),
                        },

                        {
                          name: `Facebook ${Math.trunc(
                            (Number(this.campaign['Facebook_Visitors']) / Number(this.totalVisitor)) * 100
                          )}%`,
                          value: Number(this.campaign['Facebook_Visitors']),
                        },
                        {
                          name: `Linkedin ${Math.trunc(
                            (Number(this.campaign['Linkedin_Visitors']) / Number(this.totalVisitor)) * 100
                          )}%`,
                          value: Number(this.campaign['Linkedin_Visitors']),
                        },
                        {
                          name: `Twitter ${Math.trunc(
                            (Number(this.campaign['Twitter_Visitors']) / Number(this.totalVisitor)) * 100
                          )}%`,
                          value: Number(this.campaign['Twitter_Visitors']),
                        },
                        {
                          name: `Instagram ${Math.trunc(
                            (Number(this.campaign['Instagram_Visitors']) / Number(this.totalVisitor)) * 100
                          )}%`,
                          value: Number(this.campaign['Instagram_Visitors']),
                        },

                        {
                          name: `Pinterest ${Math.trunc(
                            (Number(this.campaign['Pinterest_Visitors']) / Number(this.totalVisitor)) * 100
                          )}%`,
                          value: Number(this.campaign['Pinterest_Visitors']),
                        },
                        {
                          name: `Other ${Math.trunc(
                            (Number(this.campaign['Other_Visitors']) / Number(this.totalVisitor)) * 100
                          )}%`,
                          value: Number(this.campaign['Other_Visitors']),
                        },
                      ];
                    }
                    this.failedEmail = this.allEmail - this.successedEmail;

                    if (this.campaign['Reached.SMS']) {
                      this.allSMS = Number(this.campaign['Reached.SMS']);
                    }

                    if (this.campaign['Sent.SMS']) {
                      this.successedSMS = Number(this.campaign['Sent.SMS']);
                    }

                    this.failedSMS = this.allSMS - this.successedSMS;

                    this.allSent = this.successedEmail + this.successedSMS;

                    if (this.campaign['Duplicates.Email']) {
                      this.duplicatesEmail = Number(this.campaign['Duplicates.Email']);
                    }

                    if (this.campaign['Duplicates.SMS']) {
                      this.duplicatesSMS = Number(this.campaign['Duplicates.SMS']);
                    }

                    this.allDuplicates = this.duplicatesEmail + this.duplicatesSMS;

                    if (this.campaign['Rejected.Email']) {
                      this.rejectedEmail = Number(this.campaign['Rejected.Email']);
                    }

                    if (this.campaign['Rejected.SMS']) {
                      this.rejectedSMS = Number(this.campaign['Rejected.SMS']);
                    }

                    this.allRejected = this.rejectedEmail + this.rejectedSMS;

                    if (this.campaign['Blacklisted.Email']) {
                      this.blockedEmail = Number(this.campaign['Blacklisted.Email']);
                    }

                    if (this.campaign['Blacklisted.SMS']) {
                      this.blockedSMS = Number(this.campaign['Blacklisted.SMS']);
                    }

                    this.allBlocked = this.blockedEmail + this.blockedSMS;

                    this.missDataPatient = Number(this.campaign['No.Email']) + Number(this.campaign['No.Mobile']);

                    this.noMarketConsentPatient = Number(this.campaign['No.MarketConsent']);

                    this.totalExcluded =
                      this.totalPatient -
                      (this.allBlocked +
                        this.missDataPatient +
                        this.noMarketConsentPatient +
                        this.allDuplicates +
                        this.successedTarget);

                    this.chartPatientData = [
                      {
                        name: `Missing data ${Math.trunc(
                          (Number(this.missDataPatient) / Number(this.totalPatient)) * 100
                        )}%`,
                        value: this.missDataPatient,
                      },
                      {
                        name: `Duplicated ${Math.trunc(
                          (Number(this.allDuplicates) / Number(this.totalPatient)) * 100
                        )}%`,
                        value: this.allDuplicates,
                      },
                      {
                        name: `Blacklisted ${Math.trunc((Number(this.allBlocked) / Number(this.totalPatient)) * 100)}%`,
                        value: this.allBlocked,
                      },
                      {
                        name: `No Marketing consent given ${Math.trunc(
                          (Number(this.noMarketConsentPatient) / Number(this.totalPatient)) * 100
                        )}%`,
                        value: this.noMarketConsentPatient,
                      },
                      {
                        name: `Available patient ${Math.trunc(
                          (Number(this.successedTarget) / Number(this.totalPatient)) * 100
                        )}%`,
                        value: this.successedTarget,
                      },
                    ];

                    if (this.campaign['Targets.NamedFilter_FK']) {
                      this.chartPatientData.push({
                        name: `Don't match filter applied ${Math.trunc(
                          (Number(this.campaign['Filtered_Patient']) / Number(this.totalPatient)) * 100
                        )}%`,
                        value: this.campaign['Filtered_Patient'],
                      });
                    }

                    this.allTarget =
                      Number(this.allSent) +
                      Number(this.allDuplicates) +
                      Number(this.allRejected) +
                      Number(this.allBlocked);

                    this.successDelivered =
                      Number(this.successedTarget) -
                      Number(this.campaign['SBounced.Email']) -
                      Number(this.campaign['HBounced.Email']) -
                      Number(this.campaign['Unsubscribed']);

                    this.chartCampaignDate = [
                      {
                        name: `Soft bounces ${Math.trunc(
                          (Number(this.campaign['SBounced.Email']) / Number(this.successedTarget)) * 100
                        )}%`,
                        value: this.campaign['SBounced.Email'],
                      },
                      {
                        name: `Hard bounces ${Math.trunc(
                          (Number(this.campaign['HBounced.Email']) / Number(this.successedTarget)) * 100
                        )}%`,
                        value: this.campaign['HBounced.Email'],
                      },
                      {
                        name: `Unsubscribed ${Math.trunc(
                          (Number(this.campaign['Unsubscribed']) / Number(this.successedTarget)) * 100
                        )}%`,
                        value: this.campaign['Unsubscribed'],
                      },
                      {
                        name: `Successfully delivered ${Math.trunc(
                          (Number(this.successDelivered) / Number(this.successedTarget)) * 100
                        )}%`,
                        value: this.successDelivered,
                      },
                    ];
                  });
                }
              });

              if (
                this.campaign &&
                this.campaign['Template.MetaData'] &&
                this.campaign['UserData'] &&
                this.campaign['UserData'][0]
              ) {
                for (const key in this.campaign['Template.MetaData']) {
                  if (this.campaign['Template.MetaData'].hasOwnProperty(key)) {
                    const o = this.campaign['Template.MetaData'][key];
                    o.ID = key;

                    this.metaData.push(o);
                  }
                }

                this.userData = this.campaign['UserData'][0];

                this.productService.getMerchantProductGroup({}, this.isPromoterOrAdmin).subscribe((res) => {
                  if (res) {
                    this.productGroup = res;

                    if (this.userData['ProductGroup']) {
                      this.productGroupLabel = this.userData['ProductGroup'];
                      for (let i = 0; i < this.productGroup.length; i++) {
                        if (this.productGroup[i]['GroupCode'] == this.userData['ProductGroup']) {
                          this.productGroupLabel = this.productGroup[i]['GroupLabel'];
                          break;
                        }
                      }
                    }
                  }
                });
              }
              this.selectedTemplateID = this.campaign['templateID'];
              // this.utilService.getTemplateTag(this.selectedTemplate).subscribe(res => {
              //   if (res) {
              //     this.selectedTemplateID = res['ID'];
              //   }
              // })
            }
          });
        });
      });
    });
    this.utilService.getSubAccountStatisticalReport().subscribe((res) => {
      if (res && res.length > 0 && res[0].Reputation) {
        this.subAccountObj = res[0];
        const _reputationValue = Number(res[0].Reputation);
        if (_reputationValue >= 90) {
          this.emailReputation = 'Excellent';
          this.emailReputationColor = '4caf50';
          this.reputationValue = _reputationValue;
        } else if (_reputationValue >= 75 && _reputationValue < 90) {
          this.emailReputation = 'Good';
          this.emailReputationColor = 'cddc39';
          this.reputationValue = _reputationValue;
        } else if (_reputationValue >= 50 && _reputationValue < 75) {
          this.emailReputation = 'Fair';
          this.emailReputationColor = 'ff9800';
          this.reputationValue = _reputationValue;
        } else {
          this.emailReputation = 'Poor';
          this.emailReputationColor = 'f44336';
          this.reputationValue = _reputationValue;
        }
      } else {
        this.subAccountObj = null;
        this.emailReputation = 'none';
        this.emailReputationColor = 'e3e3e3';
      }
    });
  }

  openFilterView(ID) {
    const ref = RootAppComponent.dialog.open(MarketingFilterViewComponent, {
      data: ID,
      width: '650px',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  viewTag(ID) {
    const ref = RootAppComponent.dialog.open(TagsViewModalComponent, {
      data: ID,
      width: '650px',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  closeModal() {
    this.close.emit(true);
  }

  editCampaignQuick(campaignID) {
    if (campaignID) {
      const ref = RootAppComponent.dialog.open(MarketingCampaginEditModalComponent, {
        data: {
          campaignID,
        },
        width: '900px',
      });

      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });

      ref.componentInstance.getResult.subscribe((res) => {
        if (res && res.ID) {
          if (res['Label']) {
            this.campaign['Label'] = res['Label'];
          }

          if (res['ActionCall.Label']) {
            this.campaign['ActionCall.Label'] = res['ActionCall.Label'];
          }

          if (res['ActionCall.Description']) {
            this.campaign['ActionCall.Description'] = res['ActionCall.Description'];
          }

          if (res['Template.ActionCall_Labe']) {
            this.campaign['Template.ActionCall_Labe'] = res['Template.ActionCall_Labe'];
          }

          if (res['Template.ActionCall_Description']) {
            this.campaign['Template.ActionCall_Description'] = res['Template.ActionCall_Description'];
          }

          if (res['Is_Public']) {
            this.campaign['Is_Public'] = res['Is_Public'];
          }

          if (res['WhenToExpire']) {
            this.campaign['WhenToExpire'] = res['WhenToExpire'];
          }

          if (res['Status.Code']) {
            this.campaign['Status.Code'] = res['Status.Code'];
          }

          if (res['Status.Label']) {
            this.campaign['Status.Label'] = res['Status.Label'];
          }
        }
        ref.close();
      });
    }
  }

  editCampaign(id) {
    if (id) {
      this.router.navigate(['/merchant', { outlets: { page: ['marketing-campaign-edit', id] } }]);
    }
  }

  viewSocialMedia(campaignID) {
    if (campaignID) {
      const ref = RootAppComponent.dialog.open(SocialMediaGalleryListComponent, {
        data: {
          campaignID,
        },
        width: '1200px',
        panelClass: ['noCard', 'top-modal'],
      });

      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });

      ref.componentInstance.confirmShare.subscribe((res) => {
        if (res && res.ID && res.ID == this.campaignID) {
          if (res['Facebook_Set_Date']) {
            this.campaign['Facebook_Set_Date'] = res['Facebook_Set_Date'];
          }

          if (res['LinkedIn_Set_Date']) {
            this.campaign['LinkedIn_Set_Date'] = res['LinkedIn_Set_Date'];
          }

          if (res['Twitter_Set_Date']) {
            this.campaign['Twitter_Set_Date'] = res['Twitter_Set_Date'];
          }
          if (res['Instagram_Set_Date']) {
            this.campaign['Instagram_Set_Date'] = res['Instagram_Set_Date'];
          }

          if (res['Pinterest_Set_Date']) {
            this.campaign['Pinterest_Set_Date'] = res['Pinterest_Set_Date'];
          }
        }
        ref.close();
      });
    }
  }

  getLabel(e) {
    if (e == 'Quoted amount' || e == 'Total Treatment Price' || e === 'Treatment Price') {
      return 'Treatment Price';
    } else if (e == 'Product group') {
      return 'Finance Offered';
    } else {
      return e;
    }
  }

  openDentistDialog() {
    const ref = RootAppComponent.dialog.open(DentistViewComponent, {
      data: this.campaign['Contact_key'],
      width: '600px',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  openMerchantDialog() {
    const ref = RootAppComponent.dialog.open(MerchantViewComponent, {
      data: this.campaign['Merchant_key'],
      width: '600px',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  contactMerchantDialog() {
    const ref = RootAppComponent.dialog.open(ContactInputComponent, {
      width: '650px',
      data: {
        targetType: 'merchant',
        targetID: this.campaign['Merchant_key'],
        asGuest: false,
        asProfile: true,
        asPractice: true,
        subjectArray: this.subjectArray,
      },
    });

    ref.componentInstance.closeModal.subscribe((res) => {
      ref.close();
    });
  }

  resendCampaign() {
    const ref = RootAppComponent.dialog.open(MarketingResendModalComponent, {
      width: '650px',
      data: {
        campaignID: this.campaign['ID'],
        openView: false,
      },
    });

    ref.componentInstance.updatedMarketing.subscribe((res) => {
      this.campaign = res;
      ref.close();
    });
    ref.componentInstance.closeModal.subscribe((res) => {
      ref.close();
    });
  }

  activeCampaign() {
    const confirm = new ConfirmDialog(
      'done_outline',
      '',
      'Are you sure you want to activate this compaign?',
      'No',
      'Yes'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirm,
    });
    ref.componentInstance.onConfirm.subscribe((confirmation) => {
      if (confirmation === false) {
        ref.close();
      } else {
        this.marketingService.activateCompaign(this.campaign['ID'], this.isPromoterOrAdmin).subscribe((res) => {
          if (res) {
            if (this.campaign['ID'] == res['ID']) {
              this.campaign = res;
            } else if (this.campaign['ID'] != res['ID']) {
              this.router.navigate(['/merchant', { outlets: { page: ['marketing-campaign-view', res['ID']] } }]);
            }

            NotifyAppComponent.displayToast(
              'success',
              'Successful operation',
              'Campaign has been successfully activated'
            );
          }
          ref.close();
        });
      }
    });
  }

  addToPracticePage(id, isPublic) {
    if (id) {
      if (isPublic == true) {
        const confirm = new ConfirmDialog(
          'visibility',
          '',
          'Are you sure you want to add this campaign to your Offers Page ?',
          'No',
          'Yes'
        );

        const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
          data: confirm,
        });
        ref.componentInstance.onConfirm.subscribe((confirmation) => {
          if (confirmation === false) {
            ref.close();
          } else {
            const p = {
              isPublicCampaign: true,
              date: false,
            };

            this.marketingService.addCampaignToPracticeLandingPage(id, p, this.isPromoterOrAdmin).subscribe((res) => {
              if (res) {
                this.campaign['Is_Public'] = res.Is_Public;

                NotifyAppComponent.displayToast(
                  'success',
                  'Successful operation',
                  'The campaign has been added to your Offers Page'
                );
              }
              ref.close();
            });
          }
        });
      } else {
        const confirm = new ConfirmDialog(
          'visibility_off',
          '',
          'Are you sure you want to remove this campaign from your Offers Page ?',
          'No',
          'Yes'
        );

        const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
          data: confirm,
        });
        ref.componentInstance.onConfirm.subscribe((confirmation) => {
          if (confirmation === false) {
            ref.close();
          } else {
            const p = {
              isPublicCampaign: false,
              date: false,
            };

            this.marketingService.addCampaignToPracticeLandingPage(id, p, this.isPromoterOrAdmin).subscribe((res) => {
              if (res) {
                this.campaign['Is_Public'] = res.Is_Public;

                NotifyAppComponent.displayToast(
                  'success',
                  'Successful operation',
                  'The campaign has been removed from your Offers Page'
                );
              }
              ref.close();
            });
          }
        });
      }
    }
  }

  cancelCampaign() {
    const confirm = new ConfirmDialog(
      'cancel',
      'Cancel/End campaign',
      'If you cancel a <strong>complete</strong> Campaign it will be marked as <strong>Exipred</strong>, you can <strong>Quick Edit</strong> it latter if you want to activate it agait ' +
        '<br>Are you sure you want to proceed?',
      'No',
      'Yes'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirm,
    });
    ref.componentInstance.onConfirm.subscribe((confirmation) => {
      if (confirmation === false) {
        ref.close();
      } else {
        this.marketingService.cancelCompaign(this.campaign['ID'], this.isPromoterOrAdmin).subscribe((res) => {
          if (res) {
            this.campaign = res;

            NotifyAppComponent.displayToast(
              'success',
              'Successful operation',
              'Campaign has been successfully canceled'
            );
          }
          ref.close();
        });
      }
    });
  }

  socialMedia() {
    if (this.campaignID) {
      this.close.emit(true);
      this.router.navigate(['/merchant', { outlets: { page: ['marketing-social-create', this.campaignID] } }]);
    }
  }

  toNumber(n) {
    if (n) {
      return Number(n);
    } else {
      return 0;
    }
  }

  viewTemplate() {
    if (this.campaign['Template.Tag']) {
      const ref = RootAppComponent.dialog.open(TemplateReviewComponent, {
        data: {
          templateTag: this.campaign['Template.Tag'],
        },
        width: '600px',
        panelClass: 'noCard',
      });

      ref.componentInstance.closeModal.subscribe((res) => {
        ref.close();
      });
    }
  }

  viewNonReachedList(reason) {
    this.selectedNotReachedReason = reason;
    this.displayNonReachedList = true;
    this.hideGobackBtn.emit(true);
  }

  cancelNonReachedList() {
    this.selectedNotReachedReason = null;
    this.displayNonReachedList = false;
    this.hideGobackBtn.emit(false);
  }

  viewCampaignMessage(status, statusNative) {
    // this.router.navigate([
    //   '/merchant',
    //   {outlets: {page: ['campaign-message-list', this.campaign['ID'], status]}}
    // ])
    this.selectedMessageStatus = status;
    this.selectedMessageStatusNative = statusNative;
    this.displayCampaignMessage = true;
    this.hideGobackBtn.emit(true);
  }

  cancelCampaignMessageView() {
    this.selectedMessageStatus = null;
    this.selectedMessageStatusNative = null;
    this.displayCampaignMessage = false;
    this.hideGobackBtn.emit(false);
  }

  viewSubAccountStatisticalData() {
    if (this.subAccountObj) {
      const ref = RootAppComponent.dialog.open(SubAccountStatisticalReportComponent, {
        data: this.subAccountObj,
        width: '950px',
      });

      ref.componentInstance.close.subscribe((res) => {
        if (res == true) {
          ref.close();
        }
      });
    }
  }

  openWikiLink(link) {
    let url = '';
    if (!/^http[s]?:\/\//.test(link)) {
      url += 'https://';
    }
    url += link;
    window.open(url, '_blank', 'noopener');
  }

  openBoostDialog() {
    const ref = RootAppComponent.dialog.open(CustomerProspectBoostModalComponent, {
      data: {
        merchantID: this.merchantID,
      },
      width: '1250px',
      panelClass: 'noCard',
    });

    ref.componentInstance.closeModal.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }
}
