<div class="rel" *ngIf="listDB.firstLoadEvent == false">
  <div class="row clearfix padded rel">
    <h1 class="dataHeader rm-mt transparent">Fetching data...</h1>
  </div>
</div>
<div [hidden]="listDB.data.length == 0">
  <div>
    <h1 class="dataHeader">
      <!-- Contracts -->
      Testimonials
      <span class="badge" [endVal]="dataSource.filteredData.length" countUp></span>
    </h1>
    <mat-card class="list-card">
      <!-- Search -->
      <div class="row clearfix mb">
        <div class="row clearfix">
          <div class="half-width" id="search">
            <mat-icon class="schIcon">search</mat-icon>
            <mat-form-field class="schSel" appearance="outline">
              <mat-label>Add Filters</mat-label>
              <mat-select class="schSel" [formControl]="searchVals" matInput placeholder="Add Filters" multiple>
                <mat-select-trigger>
                  {{ searchVals.value ? searchVals.value[0] : '' }}
                  <span class="additional-selection" *ngIf="searchVals.value?.length > 1">
                    (+{{ searchVals.value.length - 1 }} others)
                  </span>
                </mat-select-trigger>
                <mat-option *ngFor="let searchVal of searchValList" [value]="searchVal">{{ searchVal }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row clearfix flex well miniWell animated fadeInDown" *ngIf="searchVals.value?.length > 0">
          <mat-form-field
            class="margin-btm"
            *ngIf="searchVals.value?.indexOf('First Name') !== -1"
            appearance="outline"
          >
            <mat-label>First Name</mat-label>
            <input
              class="clearfix"
              (input)="setFilter($event.target.value, 'firstName')"
              name="FirstName"
              placeholder="First Name"
              matInput
            />
          </mat-form-field>
          <mat-form-field class="margin-btm" *ngIf="searchVals.value?.indexOf('Last Name') !== -1" appearance="outline">
            <mat-label>Last Name</mat-label>
            <input
              class="clearfix"
              (input)="setFilter($event.target.value, 'lastName')"
              name="LastName"
              placeholder="Last Name"
              matInput
            />
          </mat-form-field>
          <mat-form-field class="margin-btm" *ngIf="searchVals.value?.indexOf('Email') !== -1" appearance="outline">
            <mat-label>Email</mat-label>
            <input
              class="clearfix"
              (input)="setFilter($event.target.value, 'email')"
              name="CustomerEmail"
              placeholder="Email"
              matInput
            />
          </mat-form-field>
          <mat-form-field class="margin-btm" *ngIf="searchVals.value?.indexOf('Rating') !== -1" appearance="outline">
            <mat-label>Rating</mat-label>
            <input
              class="clearfix"
              (input)="setFilter($event.target.value, 'starts')"
              name="Rating"
              placeholder="Rating"
              matInput
            />
          </mat-form-field>
          <mat-form-field
            class="margin-btm"
            *ngIf="searchVals.value?.indexOf('Active Status') !== -1"
            appearance="outline"
          >
            <mat-label>Active Status</mat-label>
            <input
              class="clearfix"
              (input)="setFilter($event.target.value, 'isActive')"
              name="ActiveStatus"
              placeholder="Active Status"
              matInput
            />
          </mat-form-field>
        </div>
      </div>
      <!-- Table -->
      <mat-table class="list-table" #table [dataSource]="dataSource" matSort>
        <!--- Note that these columns can be defined in any order.
                    The actual rendered columns are set as a property on the row definition" -->
        <ng-container cdkColumnDef="Avatar">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Avatar</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="viewTestimony(row['_id'])">
            <span *ngIf="row['fileID'] != null"
            ><img class="selectAvatar" src="{{ replaceImage(row['fileID']) }}"
            /></span>
            <!-- <span *ngIf="row['fileID']==null"><img class="selectAvatar" src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/profile-placeholder.png"></span> -->
            <app-contact-view-picture *ngIf="row['fileID'] == '' || !row['fileID']" [contactID]="row['userContactID']">
            </app-contact-view-picture>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Name">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Name</mat-header-cell>
          <mat-cell
            *cdkCellDef="let row"
            (click)="viewTestimony(row['_id'])"
            matTooltip="{{ row['firstName'] }} {{ row['lastName'] }}"
          >
            {{ row['firstName'] }}
            {{ row['lastName'] }}
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="AccountType">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Account Type</mat-header-cell>
          <mat-cell
            *cdkCellDef="let row"
            (click)="viewTestimony(row['_id'])"
            matTooltip="{{ row['type'] }} {{ row['role'] || '' }}"
          >
            {{ row['type'] }}
            {{ row['role'] || '' }}
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Rating">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Rating</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="viewTestimony(row['_id'])" matTooltip="{{ row['starts'] }}">
            {{ row['starts'] }} Stars
          </mat-cell>
        </ng-container>
        <ng-container cdkColumnDef="Email">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Email</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="viewTestimony(row['_id'])" matTooltip="{{ row['email'] }}">
            {{ row['email'] }}
          </mat-cell>
        </ng-container>
        <ng-container cdkColumnDef="Type">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> User Type</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="viewTestimony(row['_id'])" matTooltip="{{ row['type'] }}">
            {{ row['type'] }}
          </mat-cell>
        </ng-container>
        <ng-container cdkColumnDef="Active Status">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Active Status</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="viewTestimony(row['_id'])">
            <span class="chip good" *ngIf="row['isActive'] == true">Active</span>
            <span class="chip bad" *ngIf="row['isActive'] != true">Inactive</span>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="CreatedDate">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Create Date</mat-header-cell>
          <mat-cell
            *cdkCellDef="let row"
            (click)="viewTestimony(row['_id'])"
            matTooltip="{{ row['created_at'] | customDateTime }}"
          >
            <span>{{ row['created_at'] | TimeLabelPast }} ago</span>
          </mat-cell>
        </ng-container>
        <ng-container cdkColumnDef="Actions">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Actions</mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <div style="float: right">
              <button [matMenuTriggerFor]="menu" mat-icon-button>
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button *ngIf="row['_id']" (click)="viewTestimony(row['_id'])" mat-menu-item>
                  <mat-icon>description</mat-icon>
                  <span>View Testimony</span>
                </button>

                <button
                  *ngIf="row['_id'] && row['type'] == 'guest' && row['userID']"
                  (click)="viewProspect(row['userID'])"
                  mat-menu-item
                >
                  <mat-icon>description</mat-icon>
                  <span>View Prospect</span>
                </button>

                <button
                  *ngIf="row['_id'] && row['type'] == 'user' && row['role'] == 'merchant' && row['userID']"
                  (click)="viewMerchant(row['userID'])"
                  mat-menu-item
                >
                  <mat-icon>description</mat-icon>
                  <span>View Merchant</span>
                </button>

                <button *ngIf="row['_id']" (click)="deleteTestimony(row['_id'])" mat-menu-item>
                  <mat-icon>delete</mat-icon>
                  <span>Delete Testimony</span>
                </button>
                <button
                  *ngIf="row['_id'] && row['isActive'] != true"
                  (click)="activateTestimony(row['_id'])"
                  mat-menu-item
                >
                  <mat-icon>thumb_up</mat-icon>
                  <span>Activate Testimony</span>
                </button>
                <button
                  *ngIf="row['_id'] && row['isActive'] == true"
                  (click)="deactivateTestimony(row['_id'])"
                  mat-menu-item
                >
                  <mat-icon>thumb_down</mat-icon>
                  <span>Deactivate Testimony</span>
                </button>
              </mat-menu>
            </div>
          </mat-cell>
        </ng-container>
        <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *cdkRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
      <mat-paginator
        #paginator
        [length]="dataSource.filteredData.length"
        [pageIndex]="0"
        [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions"
      >
      </mat-paginator>
    </mat-card>
  </div>
</div>
<div class="clearfix full-width" *ngIf="listDB.data.length == 0 && listDB.firstLoadEvent != false">
  <app-empty-list-message></app-empty-list-message>
</div>
