/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./note-text-modal.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../../../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "../../note-input.component.ngfactory";
import * as i5 from "../../note-input.component";
import * as i6 from "../../../../../../../../../../../../core/app-state/app-state.service";
import * as i7 from "../../../../../../../../../../../../shared/services/utils.service";
import * as i8 from "@angular/material/dialog";
import * as i9 from "../../../../../../../../../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i10 from "@angular/material/button";
import * as i11 from "@angular/cdk/a11y";
import * as i12 from "@angular/platform-browser/animations";
import * as i13 from "./note-text-modal.component";
var styles_NoteTextModalComponent = [i0.styles];
var RenderType_NoteTextModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NoteTextModalComponent, data: {} });
export { RenderType_NoteTextModalComponent as RenderType_NoteTextModalComponent };
export function View_NoteTextModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "full-width modalClass"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "button", [["class", "btn-close btn-clear mat-button pull-right"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(3, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["clear"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "ipv-note-input", [], null, [[null, "textUpdated"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("textUpdated" === en)) {
        var pd_0 = (_co.setNoteValue($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_NoteInputComponent_0, i4.RenderType_NoteInputComponent)), i1.ɵdid(6, 245760, null, 0, i5.NoteInputComponent, [i6.AppStateService, i7.UtilsService, i8.MatDialog], { practice: [0, "practice"], patient: [1, "patient"], dentist: [2, "dentist"], size: [3, "size"], text: [4, "text"], hideExpandButton: [5, "hideExpandButton"] }, { textUpdated: "textUpdated" }), (_l()(), i1.ɵeld(7, 0, null, null, 8, "div", [["class", "drop-button text-center white-background"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "button", [["class", "pull-left action-button btn-large mr"], ["color", "primary"], ["mat-raised-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_MatButton_0, i9.RenderType_MatButton)), i1.ɵdid(10, 180224, null, 0, i10.MatButton, [i1.ElementRef, i11.FocusMonitor, [2, i12.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, [" Close "])), (_l()(), i1.ɵeld(12, 0, null, null, 3, "button", [["class", "pull-right action-button btn-large"], ["color", "accent"], ["mat-raised-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.done() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_MatButton_0, i9.RenderType_MatButton)), i1.ɵdid(13, 180224, null, 0, i10.MatButton, [i1.ElementRef, i11.FocusMonitor, [2, i12.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵeld(14, 0, null, 0, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Save"]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 3, 0); var currVal_2 = _co.practice; var currVal_3 = _co.patient; var currVal_4 = _co.dentist; var currVal_5 = "large"; var currVal_6 = _co.noteText; var currVal_7 = true; _ck(_v, 6, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_10 = "primary"; _ck(_v, 10, 0, currVal_10); var currVal_13 = "accent"; _ck(_v, 13, 0, currVal_13); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).inline; var currVal_1 = (((i1.ɵnov(_v, 3).color !== "primary") && (i1.ɵnov(_v, 3).color !== "accent")) && (i1.ɵnov(_v, 3).color !== "warn")); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_8 = (i1.ɵnov(_v, 10).disabled || null); var currVal_9 = (i1.ɵnov(_v, 10)._animationMode === "NoopAnimations"); _ck(_v, 9, 0, currVal_8, currVal_9); var currVal_11 = (i1.ɵnov(_v, 13).disabled || null); var currVal_12 = (i1.ɵnov(_v, 13)._animationMode === "NoopAnimations"); _ck(_v, 12, 0, currVal_11, currVal_12); }); }
export function View_NoteTextModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ipv-note-text-modal", [], null, null, null, View_NoteTextModalComponent_0, RenderType_NoteTextModalComponent)), i1.ɵdid(1, 114688, null, 0, i13.NoteTextModalComponent, [i8.MAT_DIALOG_DATA, i8.MatDialogRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NoteTextModalComponentNgFactory = i1.ɵccf("ipv-note-text-modal", i13.NoteTextModalComponent, View_NoteTextModalComponent_Host_0, { label: "label", noteText: "noteText", patient: "patient", practice: "practice", dentist: "dentist" }, { noteTextUpdated: "noteTextUpdated" }, []);
export { NoteTextModalComponentNgFactory as NoteTextModalComponentNgFactory };
