import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, UrlSegment } from '@angular/router';
import { intersection, uniq } from 'lodash';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { EpDocumentListComponent } from '../../../shared/components/ep-document-list/ep-document-list.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { UtilsService } from '../../../shared/services/utils.service';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { BeneficiaryService } from '../../beneficiary/shared/beneficiary.service';
import { TreatmentService } from '../shared/treatment.service';
import { treatmentUtil } from '../shared/treatmentUtil';
import { TreatmentBrandViewComponent } from '../treatment-brand-view/treatment-brand-view.component';
import { TreatmentCategoryViewComponent } from '../treatment-category-view/treatment-category-view.component';
import { TreatmentProductLookupComponent } from '../treatment-product-lookup/treatment-product-lookup.component';
import { TreatmentProductViewComponent } from '../treatment-product-view/treatment-product-view.component';
import { TreatmentTypeViewComponent } from '../treatment-type-view/treatment-type-view.component';

@Component({
  selector: 'app-treatment-informed-consent-create',
  templateUrl: './treatment-informed-consent-create.component.html',
  styleUrls: ['./treatment-informed-consent-create.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.animate', style({ opacity: 0 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.2s ease-out',
              keyframes([
                style({ opacity: 0, offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.animate', style({ opacity: 1 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.2s ease-in',
              keyframes([
                style({ opacity: 1, offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class TreatmentInformedConsentCreateComponent implements OnInit {
  @Input() justSelected = false;
  @Input() categoryID;
  @Input() brandID;
  @Input() typeID;
  @Input() productID;
  @Input() itemCode;

  @Input() merchantID;

  @Input() preSelectedDocuments;
  @Input() postSelectedDocuments;

  @Input() marketingSelectedDocuments;
  @Input() stockArtSelectedDocuments;

  @Input()
  DocumentlistViewType = 'thumbnails';

  @Output() getInformedConsent = new EventEmitter();

  @Output() clearInformedConsent = new EventEmitter();

  @Output() getCategoryID = new EventEmitter();

  @Output() getTypeID = new EventEmitter();

  @Output() getBrandID = new EventEmitter();

  @Output() getProductID = new EventEmitter();

  @Output() getPreDocuments = new EventEmitter();
  @Output() getStockArtDocuments = new EventEmitter();
  @Output() getPostDocuments = new EventEmitter();
  @Output() getMarketingDocuments = new EventEmitter();

  @Output() getCheckedPostDocument = new EventEmitter();
  @Output() getUncheckedPostDocument = new EventEmitter();

  @Output() getCheckedPreDocument = new EventEmitter();
  @Output() getUncheckedPreDocument = new EventEmitter();

  @Output() getCheckedMarketingDocument = new EventEmitter();
  @Output() getUncheckedMarketingDocument = new EventEmitter();

  @Output() getCheckedStockArtDocument = new EventEmitter();
  @Output() getUncheckedStockArtDocument = new EventEmitter();

  @Output() getStockArtModalOpened = new EventEmitter();
  @Output() getPreModalOpened = new EventEmitter();
  @Output() getPostModalOpened = new EventEmitter();
  @Output() getMarketingModalOpened = new EventEmitter();

  @Input() isGlobal = false;

  @Input() isDownload = false;
  @Input() isRedirection = false;
  @Input() isPrint = false;

  @Output()
  close = new EventEmitter();

  treatmentUtil = new treatmentUtil();

  simpleCategories: any = [];
  simpleSuppliers: any = [];
  simpleTypes: any = [];
  simpleBrands: any = [];
  simpleProducts: any = [];
  allSimpleProducts: any = [];
  supplierID;

  preAllDocuments = [];
  postAllDocuments = [];
  marketingAllDocuments = [];
  stockArtAllDocuments = [];

  preRequiredDocuments = [];
  postRequiredDocuments = [];
  marketingRequiredDocuments = [];
  stockArtRequiredDocuments = [];

  preDocuments = [];
  postDocuments = [];
  marketingDocuments = [];
  stockArtDocuments = [];

  simpleCategory;
  simpleType;
  simpleBrand;
  simpleProduct;

  isAdminOrPromoter = false;
  @Input()
  defaultCheck = false;

  isModal = false;

  @Input()
  displayPreDocuments = true;

  @Input()
  displayPostDocuments = true;

  @Input()
  displayMarketingDocuments = true;

  @Input()
  displayStockArtDocuments = true;

  @Input()
  selectPreDocuments = true;

  @Input()
  selectPostDocuments = true;

  @Input()
  selectMarketingDocuments = true;

  @Input()
  selectStockArtDocuments = true;

  @Input()
  showPreDocumentsModal = false;

  @Input()
  showPostDocumentsModal = false;

  @Input()
  showMarketingDocumentsModal = false;

  @Input()
  showStockArtDocumentsModal = false;

  itemCodeChanged = false;

  step = 1; // 1-3
  step1Options = [
    {
      label: 'ADA Item Code',
      value: 'adaItemCode',
    },
    {
      label: 'ADA Service Category',
      value: 'adaServiceCategory',
    },
    {
      label: 'Supplier',
      value: 'supplier',
    },
    {
      label: 'Product/Brand',
      value: 'productOrBrand',
    },
  ];
  step1Selected = 'adaItemCode';
  viewCodesLink = `${window.location.protocol}//${window.location.host}/merchant/(page:treatment-type-list)`;
  viewSuppliersLink = `${window.location.protocol}//${window.location.host}/merchant/(page:beneficiary-list-overview)`;
  viewProductsOrBrandsLink = `${window.location.protocol}//${window.location.host}/merchant/(page:treatment-product-list)`;
  viewServiceCategoryLink = `${window.location.protocol}//${window.location.host}/merchant/(page:treatment-category-list)`;

  filteredCategories: any[] = [];
  filteredSuppliers: any[] = [];

  productSearchLabel;
  productSearchPartLabel;

  constructor(
    private authenticationService: AuthenticationService,
    private utilService: UtilsService,
    private treatmentService: TreatmentService,
    private beneficiaryService: BeneficiaryService,
    public sanitizer: DomSanitizer,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute
  ) {
    if (data) {
      if (data.isModal) {
        this.isModal = data.isModal;
      }
      if (data.categoryID) {
        this.categoryID = data.categoryID;
      }

      if (data.brandID) {
        this.brandID = data.brandID;
      }

      if (data.typeID) {
        this.typeID = data.typeID;
      }

      if (data.productID) {
        this.productID = data.productID;
      }

      if (data.itemCode) {
        this.itemCode = data.itemCode;
      }

      if (data.merchantID) {
        this.merchantID = data.merchantID;
      }

      if (data.preSelectedDocuments && data.preSelectedDocuments.length > 0) {
        this.preSelectedDocuments = data.preSelectedDocuments;
      }

      if (data.postSelectedDocuments && data.postSelectedDocuments.length > 0) {
        this.postSelectedDocuments = data.postSelectedDocuments;
      }

      if (data.marketingSelectedDocuments && data.marketingSelectedDocuments.length > 0) {
        this.marketingSelectedDocuments = data.marketingSelectedDocuments;
      }

      if (data.stockArtSelectedDocuments && data.stockArtSelectedDocuments.length > 0) {
        this.stockArtSelectedDocuments = data.stockArtSelectedDocuments;
      }

      if (data.isDownload != null) {
        this.isDownload = data.isDownload;
      }
      if (data.justSelected != null) {
        this.justSelected = data.justSelected;
      }

      if (data.isRedirection != null) {
        this.isRedirection = data.isRedirection;
      }

      if (data.isPrint != null) {
        this.isPrint = data.isPrint;
      }

      if (data.displayPreDocuments != null) {
        this.displayPreDocuments = data.displayPreDocuments;
      }

      if (data.displayPostDocuments != null) {
        this.displayPostDocuments = data.displayPostDocuments;
      }

      if (data.displayMarketingDocuments != null) {
        this.displayMarketingDocuments = data.displayMarketingDocuments;
      }

      if (data.displayStockArtDocuments != null) {
        this.displayStockArtDocuments = data.displayStockArtDocuments;
      }

      if (data.selectPreDocuments != null) {
        this.selectPreDocuments = data.selectPreDocuments;
      }

      if (data.selectPostDocuments != null) {
        this.selectPostDocuments = data.selectPostDocuments;
      }

      if (data.defaultCheck != null) {
        this.defaultCheck = data.defaultCheck;
      }

      if (data.selectMarketingDocuments != null) {
        this.selectMarketingDocuments = data.selectMarketingDocuments;
      }

      if (data.selectStockArtDocuments != null) {
        this.selectStockArtDocuments = data.selectStockArtDocuments;
      }

      if (data.showPreDocumentsModal != null) {
        this.showPreDocumentsModal = data.showPreDocumentsModal;
      }

      if (data.showPostDocumentsModal != null) {
        this.showPostDocumentsModal = data.showPostDocumentsModal;
      }

      if (data.showMarketingDocumentsModal != null) {
        this.showMarketingDocumentsModal = data.showMarketingDocumentsModal;
      }

      if (data.showStockArtDocumentsModal != null) {
        this.showStockArtDocumentsModal = data.showStockArtDocumentsModal;
      }

      if (data.isGlobal != null) {
        this.isGlobal = data.isGlobal;
      }
    }
  }

  ngOnInit() {
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isAdminOrPromoter = res;

      if (this.isAdminOrPromoter == true) {
        this.isDownload = true;
        this.isRedirection = true;
        this.isPrint = true;
      }

      this.setup();
    });
  }

  viewProduct(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(TreatmentProductViewComponent, {
        data: {
          productID: id,
          isGlobal: this.isGlobal,
          merchantID: this.merchantID,
        },

        width: '800px',
        panelClass: 'noCard',
      });

      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    }
  }

  getRoutes() {
    const segments: UrlSegment[] = this.activatedRoute.snapshot.url;
    return segments;
  }

  viewType(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(TreatmentTypeViewComponent, {
        data: {
          typeID: id,
          isGlobal: this.isGlobal,
        },

        width: '700px',
        panelClass: 'noCard',
      });

      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    }
  }

  viewCategory(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(TreatmentCategoryViewComponent, {
        data: {
          categoryID: id,

          isGlobal: this.isGlobal,
        },

        width: '700px',
        panelClass: 'noCard',
      });

      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    }
  }

  viewBrand(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(TreatmentBrandViewComponent, {
        data: {
          brandID: id,
          isGlobal: this.isGlobal,
          merchantID: this.merchantID,
        },

        width: '700px',
        panelClass: 'noCard',
      });

      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    }
  }

  setup() {
    let payload: any = {
      fields: 'ID,Label,Description,LastModified',
      isActive: true,
      merchantID: this.merchantID,
    };
    this.treatmentService.getTreatmentCategoryList(payload, this.isAdminOrPromoter).subscribe((res) => {
      this.simpleCategories = res;
      this.filteredCategories = res;

      if (this.categoryID) {
        this.onSelectSimpleCategory(this.categoryID, true, false, false, this.itemCode);
      }
    });

    payload = {
      orderBy: 'CalculatedName',
      fields: 'ID,Label',
      merchantID: this.merchantID,
    };

    this.beneficiaryService.getList(payload, this.isAdminOrPromoter).subscribe((res) => {
      this.simpleSuppliers = res;
      this.filteredSuppliers = res;
    });
  }

  onSelectSimpleCategory(simpleCategory, setup = false, allowNull = false, defaultCheck = true, itemCode = false) {
    if (simpleCategory) {
      const payload = {
        fields: 'ID,Label,LastModified',
        isActive: true,
        categoryID: simpleCategory,
        merchantID: this.merchantID,
        isNullType: true,
      };

      this.treatmentService.getTreatmentTypeList(payload).subscribe((res) => {
        if (res && res.length > 0) {
          if (allowNull != true) {
            if (res.length > 1) {
              const index = res.findIndex((item) => {
                return item.Label === 'N/A';
              });

              res.splice(index, 1);
            }
          }

          this.simpleTypes = res;

          if (res.length === 1) {
            this.typeID = res[0]['ID'];

            this.onSelectSimpleType(this.typeID, setup, allowNull, defaultCheck, itemCode);
          } else if (setup == false) {
            this.itemCode = null;
            this.typeID = null;
            this.brandID = null;
            this.productID = null;

            this.simpleType = null;
            this.simpleBrand = null;
            this.simpleProduct = null;

            this.simpleBrands = [];
            this.simpleProducts = [];

            this.postAllDocuments = [];
            this.postRequiredDocuments = [];
            this.postDocuments = [];

            this.preAllDocuments = [];
            this.preRequiredDocuments = [];
            this.preDocuments = [];

            this.marketingAllDocuments = [];
            this.marketingRequiredDocuments = [];
            this.marketingDocuments = [];

            this.stockArtAllDocuments = [];
            this.stockArtRequiredDocuments = [];
            this.stockArtDocuments = [];
          }
          if (setup == true && this.typeID) {
            this.onSelectSimpleType(this.typeID, true, allowNull, defaultCheck, itemCode);
          }
        }
      });
    }
  }

  onSelectSimpleType(simpleType, setup = false, allowNull = false, defaultCheck = true, itemCode = false) {
    if (simpleType) {
      let payload: any = {
        isActive: true,
        fields: 'ID,Label,LastModified',
        typeID: simpleType,
        merchantID: this.merchantID,
        isNullBrand: true,
      };

      if (this.isAdminOrPromoter == true) {
        payload = {
          isActive: true,
          typeID: simpleType,
          fields: 'ID,Label,LastModified',
          merchantID: this.merchantID,
          isNullBrand: true,
        };
      }

      this.treatmentService.getTreatmentBrandList(payload, this.isAdminOrPromoter).subscribe((res) => {
        if (res && res.length > 0) {
          if (allowNull != true) {
            if (res.length > 1) {
              const index = res.findIndex((item) => {
                return item.Label === 'N/A';
              });

              res.splice(index, 1);
            }
          }

          this.simpleBrands = res;

          if (res.length === 1) {
            this.brandID = res[0]['ID'];

            this.onSelectSimpleBrand(this.brandID, setup, allowNull, defaultCheck, itemCode);
          } else if (setup == false) {
            this.itemCode = null;
            this.brandID = null;
            this.productID = null;

            this.simpleBrand = null;
            this.simpleProduct = null;

            this.simpleProducts = [];

            this.postAllDocuments = [];
            this.postRequiredDocuments = [];
            this.postDocuments = [];

            this.preAllDocuments = [];
            this.preRequiredDocuments = [];
            this.preDocuments = [];

            this.marketingAllDocuments = [];
            this.marketingRequiredDocuments = [];
            this.marketingDocuments = [];

            this.stockArtAllDocuments = [];
            this.stockArtRequiredDocuments = [];
            this.stockArtDocuments = [];
          } else if (setup == true && this.brandID) {
            this.onSelectSimpleBrand(this.brandID, true, allowNull, defaultCheck, itemCode);
          }
        }
      });
    }
  }

  onSelectSimpleBrand(simpleBrand, setup = false, allowNull = false, defaultCheck = true, itemCode = false) {
    if (simpleBrand) {
      let payload: any = {
        itemCode: this.itemCode,
        fields:
          'Category_key,LastModified,Type_key,Brand_key,ID,Brand.Label,Type.Label,Category.Label,Category.Colour,Label,Item_Code.Effective',
        merchantID: this.merchantID,
        isNullProduct: true,
        isNullBrand: true,
        isNullType: true,
      };

      if (this.isAdminOrPromoter == true) {
        payload = {
          itemCode: this.itemCode,
          fields:
            'Category_key,LastModified,Type_key,Brand_key,ID,Brand.Label,Type.Label,Category.Label,Category.Colour,Label,Item_Code.Effective',
          merchantID: this.merchantID,
          isNullProduct: true,
          isNullBrand: true,
          isNullType: true,
        };
      }

      this.treatmentService.getTreatmentProductList(payload, this.isAdminOrPromoter).subscribe((res) => {
        if (res && res.length > 0) {
          if (allowNull != true) {
            if (res.length > 1) {
              const index = res.findIndex((item) => {
                return item.Label === 'N/A';
              });

              if (index > -1) {
                res.splice(index, 1);
              }
            }
          }
          this.simpleProducts = res;

          if (res.length === 1) {
            this.productID = res[0]['ID'];

            this.onSelectSimpleProduct(this.productID, setup, defaultCheck, itemCode);
          } else if (setup == false) {
            this.itemCode = null;
            this.productID = null;

            this.simpleProduct = null;

            this.postAllDocuments = [];
            this.postRequiredDocuments = [];
            this.postDocuments = [];

            this.preAllDocuments = [];
            this.preRequiredDocuments = [];
            this.preDocuments = [];

            this.marketingAllDocuments = [];
            this.marketingRequiredDocuments = [];
            this.marketingDocuments = [];

            this.stockArtAllDocuments = [];
            this.stockArtRequiredDocuments = [];
            this.stockArtDocuments = [];
          } else if (setup == true && this.productID) {
            this.onSelectSimpleProduct(this.productID, setup, defaultCheck, itemCode);

            // this.step = 3;
          }
        }
      });
    }
  }

  async changeItemCode() {
    if (
      this.itemCode ||
      this.supplierID ||
      this.simpleCategory ||
      this.productSearchLabel ||
      this.productSearchPartLabel
    ) {
      this.itemCodeChanged = true;
      const payload: any = {
        merchantID: this.merchantID,
        fields:
          'Category_key,LastModified,Type_key,Brand_key,ID,Brand.Label,Type.Label,Category.Label,Category.Colour,Label,Item_Code.Effective',
        isNullProduct: true,
      };

      if (this.itemCode) {
        payload.itemCode = this.itemCode;
      } else if (this.supplierID) {
        payload.supplierID = this.supplierID;
      } else if (this.simpleCategory) {
        const split = this.simpleCategory.Label.split(/[-\s]/g);
        const itemCodeMin = split[split.length - 2];
        const itemCodeMax = split[split.length - 1];
        payload.itemCodeMin = itemCodeMin;
        payload.itemCodeMax = itemCodeMax;
      } else if (this.productSearchLabel) {
        payload.label = this.productSearchLabel;
      } else if (this.productSearchPartLabel) {
        payload.partLabel = this.productSearchPartLabel;
      }

      this.treatmentService.getTreatmentProductList(payload, this.isAdminOrPromoter).subscribe((res) => {
        if (res && res.length == 1) {
          if (res[0]) {
            const _ids = res[0]['ID'].split(':');

            let _merchantID;

            if (_ids && _ids.length > 1) {
              _merchantID = _ids[1];
            }
            this.categoryID = res[0]['Category_key'];
            this.typeID = res[0]['Type_key'];

            if (_merchantID) {
              this.brandID = res[0]['Brand_key'] + ':' + _merchantID;
            } else {
              this.brandID = res[0]['Brand_key'];
            }

            this.productID = res[0]['ID'];

            if (this.isModal == true) {
              this.getProductID.emit(this.productID);
            }

            this.onSelectSimpleCategory(this.categoryID, true, false, true, true);
            if (this.isModal) {
              this.closeModal();
            } else {
              this.step++;
            }
          }
        } else if (res.length > 1) {
          const ref = RootAppComponent.dialog.open(TreatmentProductLookupComponent, {
            data: {
              products: res,
              itemCode: this.itemCode,
              mergeProducts: !this.productSearchLabel,
            },

            width: '800px',
          });

          ref.componentInstance.getProduct.subscribe((product) => {
            if (product) {
              const _ids = product['ID'].split(':');

              let _merchantID;

              if (_ids && _ids.length > 1) {
                _merchantID = _ids[1];
              }
              this.categoryID = product['Category_key'];
              this.typeID = product['Type_key'];
              this.itemCode = product['Item_Code.Effective'];

              if (_merchantID) {
                this.brandID = product['Brand_key'] + ':' + _merchantID;
              } else {
                this.brandID = product['Brand_key'];
              }

              this.productID = product['ID'];

              if (this.isModal == true) {
                this.getProductID.emit(this.productID);
              }

              if (
                product &&
                product['Type.Label'] != 'N/A' &&
                product['Brand.Label'] != 'N/A' &&
                product['Category.Label'] != 'N/A'
              ) {
                this.onSelectSimpleCategory(this.categoryID, true, false, true, this.itemCode);
              } else {
                this.onSelectSimpleCategory(this.categoryID, true, true, true, this.itemCode);
              }

              if (this.isModal) {
                this.closeModal();
              } else {
                this.step++;
                ref.close();
              }
            }
          });

          ref.componentInstance.close.subscribe((res) => {
            ref.close();
          });
        } else if (!res || res.length <= 0) {
          NotifyAppComponent.displayToast('warning', 'Not Found', 'Product Code Not found');

          const emit = !this.supplierID;
          this.clearFields(emit);
        }
      });
    }
  }

  clearFields(emit = false) {
    this.itemCode = null;
    this.typeID = null;
    this.brandID = null;
    this.categoryID = null;
    this.productID = null;
    this.supplierID = null;

    this.simpleType = null;
    this.simpleBrand = null;
    this.simpleProduct = null;
    this.simpleCategory = null;
    this.productSearchPartLabel = null;
    this.productSearchLabel = null;

    this.simpleBrands = [];
    this.simpleProducts = [];

    this.postAllDocuments = [];
    this.postRequiredDocuments = [];
    this.postDocuments = [];

    this.preAllDocuments = [];
    this.preRequiredDocuments = [];
    this.preDocuments = [];

    this.marketingAllDocuments = [];
    this.marketingRequiredDocuments = [];
    this.marketingDocuments = [];

    this.stockArtAllDocuments = [];
    this.stockArtRequiredDocuments = [];
    this.stockArtDocuments = [];

    if (emit) {
      this.clearInformedConsent.emit(true);
    }
  }

  onSelectSimpleProduct(simpleProduct, setup = false, defaultCheck = true, itemCode = false) {
    if (simpleProduct) {
      if (this.simpleProducts && this.simpleProducts.length > 0) {
        const temp = this.simpleProducts.find((x) => x.ID == simpleProduct);
        if (temp) {
          this.itemCode = temp['Item_Code.Effective'];
          this.step = 3;
        } else if (this.getRoutes()[0].path === 'invitation-template-edit') {
          this.step = 3;
        } else if (this.getRoutes()[0].path === 'invitation-create') {
          this.step = 3;
        }
      }

      // this.itemCode = this.simpleProducts.find(x => x.ID == simpleProduct)['ItemCode'];
      if (this.isModal == false) {
        this.getDocuments(simpleProduct, setup, defaultCheck, itemCode);
      }
    }
  }

  getDocuments(productID, setup = false, defaultCheck = true, itemCode = false) {
    this.defaultCheck = defaultCheck;
    if (setup == false) {
      this.justSelected = defaultCheck;
    }

    this.marketingAllDocuments = [];
    this.postAllDocuments = [];
    this.preAllDocuments = [];
    this.stockArtAllDocuments = [];

    const types = [];

    const payload: any = {
      section: 0,
      fields: 'Document.Type.Code,LastModified,Mandatory.Effective,Document_key',
      includeOmmitted: false,
      merchantID: this.merchantID,
      productID,
      includeInherited: true,
    };

    if (this.selectPreDocuments == true) {
      types.push('SRVPRE');
    }

    if (this.selectPostDocuments == true) {
      types.push('SRVPST');
    }

    if (this.selectMarketingDocuments == true) {
      types.push('SRVMKT');
    }

    if (this.selectStockArtDocuments == true) {
      types.push('SRVSTC');
    }

    if (types && types.length > 0) {
      payload.documentTypes = types.join(',');
    }

    if (types && types.length > 0) {
      this.treatmentService.getTreatmentDocumentList(payload, this.isAdminOrPromoter).subscribe((_res) => {
        const res = _res['data'];
        if (res && res.length > 0) {
          const _preDoc = [];
          const _postDoc = [];
          const _marketingDoc = [];
          const _stockArtDoc = [];

          for (let i = 0; i < res.length; i++) {
            if (
              res[i] &&
              res[i] &&
              res[i]['Document_key'] &&
              res[i]['Document.Type.Code'] &&
              res[i]['Document.Type.Code'] == 'SRVPST'
            ) {
              _postDoc.push(res[i]['Document_key']);

              if (res[i]['Mandatory.Effective'] === '1') {
                this.postRequiredDocuments.push(res[i]['Document_key']);
              }
            } else if (
              res[i] &&
              res[i]['Document_key'] &&
              res[i]['Document.Type.Code'] &&
              res[i]['Document.Type.Code'] == 'SRVPRE'
            ) {
              _preDoc.push(res[i]['Document_key']);

              if (res[i]['Mandatory.Effective'] === '1') {
                this.preRequiredDocuments.push(res[i]['Document_key']);
              }
            } else if (
              res[i] &&
              res[i]['Document_key'] &&
              res[i]['Document.Type.Code'] &&
              res[i]['Document.Type.Code'] == 'SRVMKT'
            ) {
              _marketingDoc.push(res[i]['Document_key']);

              if (res[i]['Mandatory.Effective'] === '1') {
                this.marketingRequiredDocuments.push(res[i]['Document_key']);
              }
            } else if (
              res[i] &&
              res[i]['Document_key'] &&
              res[i]['Document.Type.Code'] &&
              res[i]['Document.Type.Code'] == 'SRVSTC'
            ) {
              _stockArtDoc.push(res[i]['Document_key']);

              if (res[i]['Mandatory.Effective'] === '1') {
                this.stockArtRequiredDocuments.push(res[i]['Document_key']);
              }
            }
          }

          if (_preDoc && _preDoc.length > 0) {
            if (this.justSelected == true) {
              if (this.preSelectedDocuments && this.preSelectedDocuments.length > 0) {
                this.preAllDocuments = intersection(_preDoc, this.preSelectedDocuments);
              } else {
                this.preAllDocuments = [];
              }
            } else {
              this.preAllDocuments = _preDoc;
            }
          } else {
            this.preAllDocuments = [];
          }

          if (_postDoc && _postDoc.length > 0) {
            if (this.justSelected == true) {
              if (this.postSelectedDocuments && this.postSelectedDocuments.length > 0) {
                this.postAllDocuments = intersection(_postDoc, this.postSelectedDocuments);
              } else {
                this.postAllDocuments = [];
              }
            } else {
              this.postAllDocuments = _postDoc;
            }
          } else {
            this.postAllDocuments = [];
          }

          if (_marketingDoc && _marketingDoc.length > 0) {
            if (this.justSelected == true) {
              if (this.marketingSelectedDocuments && this.marketingSelectedDocuments.length > 0) {
                this.marketingAllDocuments = intersection(_marketingDoc, this.marketingSelectedDocuments);
              } else {
                this.marketingAllDocuments = [];
              }
            } else {
              this.marketingAllDocuments = _marketingDoc;
            }
          } else {
            this.marketingAllDocuments = [];
          }

          if (_stockArtDoc && _stockArtDoc.length > 0) {
            if (this.justSelected == true) {
              if (this.stockArtSelectedDocuments && this.stockArtSelectedDocuments.length > 0) {
                this.stockArtAllDocuments = intersection(_stockArtDoc, this.stockArtSelectedDocuments);
              } else {
                this.stockArtAllDocuments = [];
              }
            } else {
              this.stockArtAllDocuments = _stockArtDoc;
            }
          } else {
            this.stockArtAllDocuments = [];
          }

          this.preAllDocuments = JSON.parse(JSON.stringify(this.preAllDocuments));
          this.stockArtAllDocuments = JSON.parse(JSON.stringify(this.stockArtAllDocuments));
          this.marketingAllDocuments = JSON.parse(JSON.stringify(this.marketingAllDocuments));
          this.postAllDocuments = JSON.parse(JSON.stringify(this.postAllDocuments));

          if (this.itemCodeChanged == true || setup != true) {
            if (this.showStockArtDocumentsModal == true) {
              this.modalStockArt();
            }

            if (this.showPreDocumentsModal == true) {
              this.modalPreDocument();
            }

            if (this.showPostDocumentsModal == true) {
              this.modalPostDocument();
            }

            if (this.showMarketingDocumentsModal == true) {
              this.modalmarketingDocument();
            }
          }
          if (
            this.preAllDocuments.length == 0 &&
            this.preAllDocuments.length == 0 &&
            this.marketingAllDocuments.length == 0 &&
            (setup != true || itemCode == true)
          ) {
            this.sendResult();
          }
        } else {
          this.marketingAllDocuments = [];
          this.postAllDocuments = [];
          this.preAllDocuments = [];
          this.stockArtAllDocuments = [];

          this.sendResult();
        }
      });
    }
  }

  modalStockArt() {
    if (
      this.stockArtAllDocuments &&
      this.stockArtAllDocuments.length > 0 &&
      this.selectStockArtDocuments &&
      this.displayStockArtDocuments != true
    ) {
      let __documents = [];
      const ref = RootAppComponent.dialog.open(EpDocumentListComponent, {
        data: {
          title: 'Image list',
          description:
            'We have found images available for this treatment, select what images you want to present to patient ',
          actionLabelGlobal: 'Select',
          canActionGlobal: true,
          canCheck: true,
          isDownload: this.isDownload,
          isRedirection: this.isRedirection,
          isPrint: this.isPrint,
          isFilter: true,
          defaultCheck: false,
          listViewType: 'thumbnails',
          selectedIDs: this.stockArtSelectedDocuments,
          documents: uniq(this.stockArtAllDocuments),
        },

        width: '800px',
      });

      this.getStockArtModalOpened.emit(true);

      ref.componentInstance.closeModal.subscribe((res) => {
        this.getStockArtModalOpened.emit(false);
        ref.close();
      });

      ref.backdropClick().subscribe((res) => {
        this.getStockArtModalOpened.emit(false);
        ref.close();
      });

      ref.componentInstance.sendActionGlobal.subscribe((res) => {
        if (res) {
          this.stockArtDocuments = uniq(__documents);
          __documents = [];

          if (this.selectStockArtDocuments == true) {
            this.getStockArtDocuments.emit(this.stockArtDocuments);
          }

          this.stockArtDocuments = JSON.parse(JSON.stringify(this.stockArtDocuments));

          ref.close();
        }
      });

      ref.componentInstance.getCheckedDocument.subscribe((res) => {
        if (res && res.ID) {
          __documents.push(res.ID);

          this.getCheckedStockArtDocument.emit(res);
        }
      });

      ref.componentInstance.getUncheckedDocument.subscribe((res) => {
        if (res && res.ID) {
          const index = __documents.indexOf(res.ID);
          if (index != -1) {
            __documents.splice(index, 1);
          }

          this.getUncheckedStockArtDocument.emit(res);
        }
      });
    }
  }

  modalPreDocument() {
    if (this.preAllDocuments && this.preAllDocuments.length > 0 && this.displayPreDocuments != true) {
      let __documents = [];
      const ref = RootAppComponent.dialog.open(EpDocumentListComponent, {
        data: {
          title: 'Pre documents',
          description:
            'We have Pre documents available for this treatment, select what documents you want to present to patient ',
          actionLabelGlobal: 'Select',
          canActionGlobal: true,
          canCheck: true,
          isDownload: this.isDownload,
          isRedirection: this.isRedirection,
          isPrint: this.isPrint,
          isFilter: true,
          defaultCheck: false,
          listViewType: 'thumbnails',
          selectedIDs: this.preSelectedDocuments,
          documents: uniq(this.preAllDocuments),
        },

        width: '800px',
      });

      this.getPreModalOpened.emit(true);

      ref.componentInstance.closeModal.subscribe((res) => {
        this.getPreModalOpened.emit(false);
        ref.close();
      });

      ref.backdropClick().subscribe((res) => {
        this.getPreModalOpened.emit(false);
        ref.close();
      });

      ref.componentInstance.sendActionGlobal.subscribe((res) => {
        if (res) {
          this.preDocuments = uniq(__documents);
          __documents = [];

          if (this.selectPreDocuments == true) {
            this.getPreDocuments.emit(this.preDocuments);
          }
          this.preDocuments = JSON.parse(JSON.stringify(this.preDocuments));
          ref.close();
        }
      });

      ref.componentInstance.getCheckedDocument.subscribe((res) => {
        if (res && res.ID) {
          __documents.push(res.ID);

          this.getCheckedPreDocument.emit(res);
        }
      });

      ref.componentInstance.getUncheckedDocument.subscribe((res) => {
        if (res && res.ID) {
          const index = __documents.indexOf(res.ID);
          if (index != -1) {
            __documents.splice(index, 1);
          }

          this.getCheckedPreDocument.emit(res);
        }
      });
    }
  }

  modalPostDocument() {
    if (this.postAllDocuments && this.postAllDocuments.length > 0 && this.displayPostDocuments != true) {
      let __documents = [];
      const ref = RootAppComponent.dialog.open(EpDocumentListComponent, {
        data: {
          title: 'Post documents',
          description:
            'We have Post documents available for this treatment, select what documents you want to  present to patient ',
          actionLabelGlobal: 'Select',
          canActionGlobal: true,
          canCheck: true,
          isDownload: this.isDownload,
          isRedirection: this.isRedirection,
          isPrint: this.isPrint,
          isFilter: true,
          defaultCheck: false,
          listViewType: 'thumbnails',
          selectedIDs: this.postSelectedDocuments,
          documents: uniq(this.postAllDocuments),
        },

        width: '800px',
      });

      this.getPreModalOpened.emit(true);

      ref.componentInstance.closeModal.subscribe((res) => {
        this.getPreModalOpened.emit(false);
        ref.close();
      });

      ref.backdropClick().subscribe((res) => {
        this.getPreModalOpened.emit(false);
        ref.close();
      });

      ref.componentInstance.sendActionGlobal.subscribe((res) => {
        if (res) {
          this.postDocuments = uniq(__documents);
          __documents = [];

          if (this.selectPostDocuments == true) {
            this.getPostDocuments.emit(this.postDocuments);
          }
          this.postDocuments = JSON.parse(JSON.stringify(this.postDocuments));
          ref.close();
        }
      });

      ref.componentInstance.getCheckedDocument.subscribe((res) => {
        if (res && res.ID) {
          __documents.push(res.ID);

          this.getCheckedPostDocument.emit(res);
        }
      });

      ref.componentInstance.getUncheckedDocument.subscribe((res) => {
        if (res && res.ID) {
          const index = __documents.indexOf(res.ID);
          if (index != -1) {
            __documents.splice(index, 1);
          }

          this.getCheckedPostDocument.emit(res);
        }
      });
    }
  }

  modalmarketingDocument() {
    if (this.marketingAllDocuments && this.marketingAllDocuments.length > 0 && this.displayMarketingDocuments != true) {
      let __documents = [];
      const ref = RootAppComponent.dialog.open(EpDocumentListComponent, {
        data: {
          title: 'Marketing documents',
          description:
            'We have Marketing documents available for this treatment, select what documents you want to present to patient ',
          actionLabelGlobal: 'Select',
          canActionGlobal: true,
          canCheck: true,
          isDownload: this.isDownload,
          isRedirection: this.isRedirection,
          isPrint: this.isPrint,
          isFilter: true,
          defaultCheck: false,
          listViewType: 'thumbnails',
          selectedIDs: this.marketingSelectedDocuments,
          documents: uniq(this.marketingAllDocuments),
        },

        width: '800px',
      });

      this.getMarketingModalOpened.emit(true);

      ref.componentInstance.closeModal.subscribe((res) => {
        this.getMarketingModalOpened.emit(false);
        ref.close();
      });

      ref.backdropClick().subscribe((res) => {
        this.getMarketingModalOpened.emit(false);
        ref.close();
      });

      ref.componentInstance.sendActionGlobal.subscribe((res) => {
        if (res) {
          this.marketingDocuments = uniq(__documents);
          __documents = [];

          if (this.selectMarketingDocuments == true) {
            this.getMarketingDocuments.emit(this.marketingDocuments);
          }
          this.marketingDocuments = JSON.parse(JSON.stringify(this.marketingDocuments));
          ref.close();
        }
      });

      ref.componentInstance.getCheckedDocument.subscribe((res) => {
        if (res && res.ID) {
          __documents.push(res.ID);

          this.getCheckedMarketingDocument.emit(res);
        }
      });

      ref.componentInstance.getUncheckedDocument.subscribe((res) => {
        if (res && res.ID) {
          const index = __documents.indexOf(res.ID);
          if (index != -1) {
            __documents.splice(index, 1);
          }

          this.getCheckedMarketingDocument.emit(res);
        }
      });
    }
  }

  getUncheckedMarketingDocumentEvent(d) {
    if (d && d.ID) {
      this.getUncheckedMarketingDocument.emit(d);
    }
  }

  getCheckedMarketingDocumentEvent(d) {
    if (d && d.ID) {
      this.getCheckedMarketingDocument.emit(d);
    }
  }

  getUncheckedStockArtDocumentEvent(d) {
    if (d && d.ID) {
      this.getUncheckedStockArtDocument.emit(d);
    }
  }

  getCheckedStockArtDocumentEvent(d) {
    if (d && d.ID) {
      this.getCheckedStockArtDocument.emit(d);
    }
  }

  getUncheckedPostDocumentEvent(d) {
    if (d && d.ID) {
      this.getUncheckedPostDocument.emit(d);
    }
  }

  getCheckedPostDocumentEvent(d) {
    if (d && d.ID) {
      this.getCheckedPostDocument.emit(d);
    }
  }

  getUncheckedPreDocumentEvent(d) {
    if (d && d.ID) {
      this.getUncheckedPreDocument.emit(d);
    }
  }

  getCheckedPreDocumentEvent(d) {
    if (d && d.ID) {
      this.getCheckedMarketingDocument.emit(d);
    }
  }

  getPreCheckedDocument(docs) {
    this.preDocuments = docs;
    this.sendResult();
  }

  getPostCheckedDocument(docs) {
    this.postDocuments = docs;
    this.sendResult();
  }

  getMarketingCheckedDocument(docs) {
    this.marketingDocuments = docs;
    this.sendResult();
  }

  getStockArtCheckedDocument(docs) {
    this.stockArtDocuments = docs;
    this.sendResult();
  }

  sendResult() {
    const informedConsent = {
      itemCode: null,
      categoryID: null,
      typeID: null,
      brandID: null,
      productID: null,
      justSelected: false,
      preDocuments: [],
      stockArtDocuments: [],
      postDocuments: [],
      marketingDocuments: [],
    };

    if (this.itemCode) {
      informedConsent.itemCode = this.itemCode;
    }

    if (this.categoryID) {
      informedConsent.categoryID = this.categoryID;
    }

    if (this.brandID) {
      informedConsent.brandID = this.brandID;
    }
    if (this.itemCode) {
      informedConsent.itemCode = this.itemCode;
    }
    if (this.productID) {
      informedConsent.productID = this.productID;
    }

    if (this.typeID) {
      informedConsent.typeID = this.typeID;
    }

    if (this.preDocuments && this.preDocuments.length > 0) {
      informedConsent.preDocuments = this.preDocuments;
    }

    if (this.postDocuments && this.postDocuments.length > 0) {
      informedConsent.postDocuments = this.postDocuments;
    }

    if (this.marketingDocuments && this.marketingDocuments.length > 0) {
      informedConsent.marketingDocuments = this.marketingDocuments;
    }

    if (this.stockArtDocuments && this.stockArtDocuments.length > 0) {
      informedConsent.stockArtDocuments = this.stockArtDocuments;
    }

    if (this.justSelected == true) {
      informedConsent.justSelected = true;
    }

    this.getInformedConsent.emit(informedConsent);
    this.getCategoryID.emit(this.categoryID);
    this.getTypeID.emit(this.typeID);
    this.getBrandID.emit(this.brandID);
    this.getProductID.emit(this.productID);

    if (this.selectPreDocuments == true && this.showPreDocumentsModal != true) {
      this.getPreDocuments.emit(this.preDocuments);
    }

    if (this.selectPostDocuments == true && this.showPostDocumentsModal != true) {
      this.getPostDocuments.emit(this.postDocuments);
    }

    if (this.selectMarketingDocuments == true && this.showMarketingDocumentsModal != true) {
      this.getMarketingDocuments.emit(this.marketingDocuments);
    }

    if (this.selectStockArtDocuments == true && this.showStockArtDocumentsModal != true) {
      this.getStockArtDocuments.emit(this.stockArtDocuments);
    }
  }

  closeModal() {
    this.close.emit(true);
  }

  search() {
    switch (this.step) {
      case 1:
        this.step++;
        break;
      case 2:
        this.changeItemCode();
    }
  }

  filterCategory(value) {
    if (value && value.srcElement) {
      this.categoryID = null;
      this.simpleCategory = null;
      const text = value.srcElement.value;
      if (text) {
        this.filteredCategories = this.simpleCategories.filter((it) =>
          it.Label.toLowerCase().includes(text.toLowerCase())
        );
      } else {
        this.filteredCategories = this.simpleCategories;
      }
    }
  }

  categorySelected(value) {
    if (this.categoryID !== value.ID) {
      this.simpleCategory = value;
      this.categoryID = value.ID;
      this.filteredCategories = this.simpleCategories;
    }
    this.search();
  }

  filterSupplier(value) {
    if (value && value.srcElement) {
      this.supplierID = null;
      const text = value.srcElement.value;
      if (text) {
        this.filteredSuppliers = this.simpleSuppliers.filter((it) =>
          it.Label.toLowerCase().includes(text.toLowerCase())
        );
      } else {
        this.filteredSuppliers = this.simpleSuppliers;
      }
    }
  }

  supplierSelected(value) {
    this.supplierID = value.ID;
    this.filteredSuppliers = this.simpleSuppliers;
    this.search();
  }

  getOptionText(option) {
    return option.Label;
  }

  goBack() {
    this.itemCode = null;
    this.supplierID = null;
    this.categoryID = null;
    this.productSearchLabel = null;
    this.productSearchPartLabel = null;
    this.step1Selected = null;
    this.step = 1;
  }

  proceed() {
    this.step = this.step + 1;
  }
}
