import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { InvitationService } from '../shared/services/invitation.service';

import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { FilterArrayMultipleValue } from '../../../shared/pipes/filter-array-object.pipe';
import { WithoutArrayMultipleValue } from '../../../shared/pipes/without-array-object.pipe';
import { SideMenuService } from '../../../shared/services/side-menu.service';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { DentistViewComponent } from '../../dentist/dentist-view/dentist-view.component';
import { MerchantViewComponent } from '../../merchant/merchant-view/merchant-view.component';
import { ProductViewModalComponent } from '../../product/product-view-modal/product-view-modal.component';
import { InvitationUtilClass } from '../shared/types/invitation-util.class';

@Component({
  selector: 'app-invitation-performance',
  templateUrl: './invitation-performance.component.html',
  styleUrls: ['./invitation-performance.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.animate', style({ opacity: 0 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.4s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-50px)', offset: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.animate', style({ opacity: 1 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.4s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-50px)', offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
    trigger('labelAnimation', [
      transition('void => *', [
        query('p', style({ opacity: 0 }), { optional: true }),
        query(
          'p',
          stagger('50ms', [
            animate(
              '0.1s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateX(-30px)', offset: 0 }),
                style({ opacity: 1, transform: 'translateX(0)', offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
    trigger('ngIfLoadingAnimation', [
      transition('void => *', [
        query('.animate', style({ opacity: 0 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.4s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-50px)', offset: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class InvitationPerformanceComponent implements OnInit {
  util = new UtilsClass();
  objectKeys = Object.keys;
  timeLineStatus = 'unit';
  displayChartView = 'unit';
  displayProdChartView = 'unit';
  displayTimeLineChartView = 'unit';
  displayMerchChartView = 'unit';
  displayStaffChartView = 'unit';
  displayGlobalPieChart = 'invitation';
  simpleData: any = [
    {
      name: 'Won',
      value: 358,
    },
    {
      name: 'Maybe',
      value: 176,
    },
    {
      name: 'Lost',
      value: 727,
    },
  ];
  displayChartTime = 'months';
  isDisplayed = false;
  chartStatusColor = ['#FF0000', '#4BB543', '#ffae42'];

  pageSize = 10;

  @Input()
  membership = false;

  statusPercent = [];
  statusPercentGlobal = [];
  isAdminOrPromoter = false;
  dateFrom;
  dateTo;
  merchantID = null;
  dentistID = null;
  productID = null;
  status = null;
  _dateFrom;
  _dateTo;
  _merchantID = null;
  _dentistID = null;
  _productID = null;
  _status = null;
  stats = {
    global: null,
    status: null,
    products: null,
    merchants: null,
    dentists: null,
    days: null,
    months: null,
    years: null,
    dateRange: null,
  };
  chartColors = Settings.global['chartColors'];
  selectProduct = false;
  selectYears = false;
  selectDays = false;
  selectMonths = false;
  selectDentist = false;
  selectMerchant = false;
  selectStatus = true;
  staffFiler;
  productFilter;
  merchantFilter;
  statusFilter;
  merchantIDInvitation = null;
  dentistIDInvitation = null;
  productIDInvitation = null;
  statusInvitation = null;
  dateFromInvitation = null;
  dateToInvitation = null;
  displayTimeLineList = false;
  displayTimeLineRangeList = false;
  displayInvitationList = false;
  selectedIndex = 0;
  productListFiltered = [];
  isEmptyResult = true;
  emptyResultFilter = [0];
  orderByFiler = {
    status: ['name'],
    product: ['MarketingLabel'],
    merchant: ['TradingAs'],
    staff: ['CalculatedName'],
    daily: ['ID'],
    monthly: ['ID'],
    annualy: ['ID'],
    dateRange: ['ID'],
  };

  selectDateRange = false;

  statusFilterType = 'invitation';
  invitationUtil = new InvitationUtilClass();
  statusFilterTypeArray = this.invitationUtil.status.allInvitation;
  InvitationsTotal;
  globalLoaded = false;
  dateRangeLoaded = false;
  productLoaded = false;
  merchantLoaded = false;
  staffLoaded = false;
  statusLoaded = false;
  indexLabel = 'status';

  sendNull = true;
  selectedRange = 'days90';

  constructor(
    private AuthenticationService: AuthenticationService,
    private WithoutArrayMultipleValue: WithoutArrayMultipleValue,
    private FilterArrayMultipleValue: FilterArrayMultipleValue,
    private invitationService: InvitationService
  ) {}

  ngOnInit() {
    SideMenuService.goBack.subscribe((res) => {
      if (res == true) {
        this.goBack();
      }
    });

    this.AuthenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isAdminOrPromoter = res;
      this.selectYears = true;
      this.selectDays = true;
      this.selectMonths = true;
    });
  }

  chaneDisplayTimeLineList(e) {
    this.displayTimeLineList = e.checked;
  }

  chaneDisplayTimeLineRangeList(e) {
    this.displayTimeLineRangeList = e.checked;
  }

  changeStatusFilter() {
    if (this.stats && this.stats.status && this.stats.status.length > 0) {
      if (this.statusFilterType == 'all') {
        this.statusFilterTypeArray = this.invitationUtil.status.all;
      } else if (this.statusFilterType == 'invitation') {
        this.statusFilterTypeArray = this.invitationUtil.status.allInvitation;
      } else if (this.statusFilterType == 'proceeded') {
        this.statusFilterTypeArray = this.invitationUtil.status.won;
      } else if (this.statusFilterType == 'notProceed') {
        this.statusFilterTypeArray = this.invitationUtil.status.lost;
      } else if (this.statusFilterType == 'maybe') {
        this.statusFilterTypeArray = this.invitationUtil.status.maybe;
      } else if (this.statusFilterType == 'contract') {
        this.statusFilterTypeArray = this.invitationUtil.status.contract;
      } else {
        this.statusFilterTypeArray = this.invitationUtil.status.all;
      }

      let _filtered = this.FilterArrayMultipleValue.transform(this.stats.status, 'ID', this.statusFilterTypeArray);
      _filtered = this.WithoutArrayMultipleValue.transform(_filtered, "'Count(*)", this.emptyResultFilter);
    }
  }

  emptyResultChange(e) {
    if (e && e.checked && e.checked == true) {
      this.emptyResultFilter = [0];
    } else {
      this.emptyResultFilter = [];
    }
  }

  getProduct(d) {
    this._productID = d;
  }

  getDentist(d) {
    this._dentistID = d;
  }

  getMerchant(d) {
    this._merchantID = d;
  }

  getDateFrom(d) {
    this._dateFrom = d;
  }

  getDateTo(d) {
    this._dateTo = d;
  }

  getStatus(d) {
    this._status = d;
  }

  filterByOrderBy(context, filter) {
    const _filter = filter.split(',');
    if (this.orderByFiler[context]) {
      this.orderByFiler[context] = _filter;
    }
  }

  calculStatus() {
    const source = this.stats.status;
    if (source && source.length > 0) {
      const won = {
        ID: 'won',
        name: 'Won',
        'Count(*)': 0,
        'Sum(treatment.Value)': 0,
      };

      const loss = {
        ID: 'lost',
        name: 'Lost',
        'Count(*)': 0,
        'Sum(treatment.Value)': 0,
      };

      const change = {
        ID: 'maybe',
        name: 'Maybe',
        'Count(*)': 0,
        'Sum(treatment.Value)': 0,
      };

      const contract = {
        ID: 'contract',
        name: 'Contract',
        'Count(*)': 0,
        'Sum(treatment.Value)': 0,
      };

      this.statusPercentGlobal = [];
      this.statusPercent = [];
      for (let i = 0; i < source.length; i++) {
        if (source[i] && source[i]['ID']) {
          if (this.invitationUtil.status.won.indexOf(source[i]['ID']) != -1) {
            won['Sum(treatment.Value)'] = won['Sum(treatment.Value)'] + source[i]['Sum(treatment.Value)'];
            won['Count(*)'] = won['Count(*)'] + source[i]['Count(*)'];
          } else if (this.invitationUtil.status.maybe.indexOf(source[i]['ID']) != -1) {
            change['Sum(treatment.Value)'] = change['Sum(treatment.Value)'] + source[i]['Sum(treatment.Value)'];
            change['Count(*)'] = change['Count(*)'] + source[i]['Count(*)'];
          } else if (this.invitationUtil.status.lost.indexOf(source[i]['ID']) != -1) {
            loss['Sum(treatment.Value)'] = loss['Sum(treatment.Value)'] + source[i]['Sum(treatment.Value)'];
            loss['Count(*)'] = loss['Count(*)'] + source[i]['Count(*)'];
          } else if (this.invitationUtil.status.contract.indexOf(source[i]['ID']) != -1) {
            contract['Sum(treatment.Value)'] = contract['Sum(treatment.Value)'] + source[i]['Sum(treatment.Value)'];
            contract['Count(*)'] = contract['Count(*)'] + source[i]['Count(*)'];
          }
        }
      }

      this.statusPercent.push(loss);
      this.statusPercent.push(won);
      this.statusPercent.push(change);
      this.statusPercent.push(contract);
      this.statusPercentGlobal.push(loss);
      this.statusPercentGlobal.push(won);
      this.statusPercentGlobal.push(change);
    }
  }

  getResult(result) {
    this.merchantID = result.merchantID;
    this.dateFrom = result.dateFrom;
    this.dateTo = result.dateTo;
    this.dentistID = result.dentistID;
    this.productID = result.productID;
    this.status = result.status;

    if (this.dateTo || this.dateFrom) {
      this.selectDateRange = true;
    } else {
      this.selectDateRange = false;
    }

    this.displayTimeLineList = false;
    this.displayTimeLineRangeList = false;

    this.resetLoading();
    this.applySearch(true);
  }

  applySearch(updateSelection = false) {
    this.getTabLabel();
    const loadStats = this.reloadStatus();

    if (loadStats == true || updateSelection == true) {
      this.buildPayload(updateSelection);
      const payload = {
        selectProduct: this.selectProduct,
        selectYears: this.selectYears,
        selectDays: this.selectDays,
        selectMonths: this.selectMonths,
        selectDentist: this.selectDentist,
        selectMerchant: this.selectMerchant,
        selectStatus: this.selectStatus,
        merchantID: this.merchantID,
        dateFrom: this.util.EPdateFormat(this.dateFrom),
        dateTo: this.util.EPdateFormat(this.dateTo),
        status: this.status,
        productID: this.productID,
        dentistID: this.dentistID,
        fields: 'Sum(treatment.Value),Count(*),Min(treatment.Value),Avg(treatment.Value),Max(treatment.Value)',
      };

      this.invitationService.getStatistics(payload, this.isAdminOrPromoter, this.membership).subscribe((res1) => {
        console.log(res1);
        if (this.indexLabel == 'product') {
          this.stats.products = res1.products;

          if (this.stats.products && this.stats.products.length > 0) {
            this.productLoaded = true;
          }

          if (this.isAdminOrPromoter == true) {
            this.filterProduct();
          }
        }

        if (this.indexLabel == 'merchant') {
          this.stats.merchants = res1.merchants;

          if (this.stats.merchants && this.stats.merchants.length > 0) {
            this.merchantLoaded = true;
          }
        }

        if (this.indexLabel == 'staff') {
          this.stats.dentists = res1.dentists;

          if (this.stats.dentists && this.stats.dentists.length > 0) {
            this.staffLoaded = true;
          }
        }

        if (updateSelection == true) {
          this.stats.days = res1.days;
          this.stats.months = res1.months;
          this.stats.years = res1.years;
          this.stats.status = res1.status;
          this.stats.dateRange = res1.dateRange;
          this.stats.global = res1.global;
        }

        this.calculStatus();
      });
    }
  }

  updateStats() {}

  filterProduct() {
    let tmpProductFiltered;
    const tmpProducList = [];
    // tmpProductFiltered = this.groupBy.transform(this.stats.products, 'MarketingLabel');
    tmpProductFiltered = this.stats.products.reduce((acc, obj) => {
      const key = obj['MarketingLabel'];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
    for (const key in tmpProductFiltered) {
      if (tmpProductFiltered.hasOwnProperty(key)) {
        const _obj = {};
        _obj['MarketingLabel'] = key;
        _obj['Sum(treatment.Value)'] = 0;
        _obj['Count(*)'] = 0;
        _obj['Min(treatment.Value)'] = 0;
        _obj['Avg(treatment.Value)'] = 0;
        _obj['Max(treatment.Value)'] = 0;
        for (let i = 0; i < tmpProductFiltered[key].length; i++) {
          _obj['Sum(treatment.Value)'] += Number(tmpProductFiltered[key][i]['Sum(treatment.Value)']);
          _obj['Count(*)'] += Number(tmpProductFiltered[key][i]['Count(*)']);
          _obj['Min(treatment.Value)'] += Number(tmpProductFiltered[key][i]['Min(treatment.Value)']);
          _obj['Avg(treatment.Value)'] += Number(tmpProductFiltered[key][i]['Avg(treatment.Value)']);
          _obj['Max(treatment.Value)'] += Number(tmpProductFiltered[key][i]['Max(treatment.Value)']);
        }

        tmpProducList.push(_obj);
      }
    }

    this.productListFiltered = tmpProducList;
  }

  getTabLabel() {
    if (this.isAdminOrPromoter == true) {
      if (this.selectedIndex == 0) {
        this.indexLabel = 'status';
      } else if (this.selectedIndex == 1) {
        this.indexLabel = 'product';
      } else if (this.selectedIndex == 2) {
        this.indexLabel = 'merchant';
      } else if (this.selectedIndex == 3) {
        this.indexLabel = 'staff';
      }
    } else {
      if (this.selectedIndex == 0) {
        this.indexLabel = 'status';
      } else if (this.selectedIndex == 1) {
        this.indexLabel = 'product';
      } else if (this.selectedIndex == 2) {
        this.indexLabel = 'staff';
      }
    }

    return this.indexLabel;
  }

  buildPayload(updateTimeline = false) {
    if (updateTimeline == true) {
      this.selectYears = true;
      this.selectDays = true;
      this.selectMonths = true;
      this.selectStatus = true;
    } else {
      this.selectYears = false;
      this.selectDays = false;
      this.selectMonths = false;
      this.selectStatus = false;
    }

    if (this.isAdminOrPromoter == true) {
      if (this.selectedIndex == 1) {
        this.selectProduct = true;
        this.selectDentist = false;
        this.selectMerchant = false;
      } else if (this.selectedIndex == 2) {
        this.selectProduct = false;
        this.selectDentist = false;
        this.selectMerchant = true;
      } else if (this.selectedIndex == 3) {
        this.selectProduct = false;
        this.selectDentist = true;
        this.selectMerchant = false;
      }
    } else {
      if (this.selectedIndex == 1) {
        this.selectProduct = true;
        this.selectDentist = false;
        this.selectMerchant = false;
      } else if (this.selectedIndex == 2) {
        this.selectProduct = false;
        this.selectDentist = true;
        this.selectMerchant = false;
      }
    }
  }

  loadStats(s) {
    this.selectedIndex = s;
    if (this.isAdminOrPromoter == true) {
      if (
        this.selectedIndex != 4 ||
        (this.selectedIndex == 4 && (!this.stats || !this.stats.dateRange || this.stats.dateRange.length <= 0))
      ) {
        {
          this.applySearch(false);
        }
      }
    } else {
      if (
        this.selectedIndex != 3 ||
        (this.selectedIndex == 3 && (!this.stats || !this.stats.dateRange || this.stats.dateRange.length <= 0))
      ) {
        {
          this.applySearch(false);
        }
      }
    }
  }

  goBack() {
    this.displayInvitationList = false;
  }

  displayList(dateFrom, dateTo, merchantIDInvitation, dentistIDInvitation, productIDInvitation, statusInvitation) {
    this.dateFromInvitation = dateFrom;
    this.dateToInvitation = dateTo;
    this.merchantIDInvitation = merchantIDInvitation;
    this.dentistIDInvitation = dentistIDInvitation;
    this.productIDInvitation = productIDInvitation;
    this.statusInvitation = statusInvitation;
    this.displayInvitationList = true;
  }

  reloadStatus() {
    if (this.indexLabel == 'product') {
      if (this.productLoaded == false) {
        return true;
      } else {
        return false;
      }
    }

    if (this.indexLabel == 'merchant') {
      if (this.merchantLoaded == false) {
        return true;
      } else {
        return false;
      }
    }

    if (this.indexLabel == 'staff') {
      if (this.staffLoaded == false) {
        return true;
      } else {
        return false;
      }
    }
  }

  resetLoading() {
    this.globalLoaded = false;
    this.productLoaded = false;
    this.merchantLoaded = false;
    this.staffLoaded = false;
    this.statusLoaded = false;
    this.dateRangeLoaded = false;
  }

  arrowDirection(array, index, attribute) {
    const previousIndex = index - 1;
    if (!array || array[index] == null || array[index][attribute] == null || Number(array[index][attribute]) == 0) {
      return false;
    } else if (index == 0) {
      return true;
    } else if (
      array[index] != null &&
      array[index][attribute] != null &&
      array[previousIndex] != null &&
      array[previousIndex][attribute] != null &&
      Number(array[index][attribute]) >= Number(array[previousIndex][attribute])
    ) {
      return true;
    } else {
      return false;
    }
  }

  viewProduct(id) {
    const ref = RootAppComponent.dialog.open(ProductViewModalComponent, {
      data: id,
      width: '750px',
      panelClass: 'noCard',
    });
    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  viewMerchant(id) {
    const ref = RootAppComponent.dialog.open(MerchantViewComponent, {
      data: id,
      width: '600px',
    });
    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  viewStaff(id) {
    const ref = RootAppComponent.dialog.open(DentistViewComponent, {
      data: id,
      width: '600px',
    });
  }
}
