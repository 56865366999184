import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { WizardComponent } from 'angular-archwizard';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { HelperService } from '../../../core/helper/shared/helper.service';
import { TemplateReviewComponent } from '../../../core/helper/template-review/template-review.component';
import {
  ConfirmDialogMultipleComponent
} from '../../../shared/components/confirm-dialog-multiple/confirm-dialog-multiple.component';
import {
  ConfirmDialogSingleButtonComponent
} from '../../../shared/components/confirm-dialog-single-button/confirm-dialog-single-button.component';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { DocumentUrlViewComponent } from '../../../shared/components/document-url-view/document-url-view.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import {
  VideoTutorialsModalComponent
} from '../../../shared/components/video-tutorials-modal/video-tutorials-modal.component';
import { CustomPhonePipe } from '../../../shared/pipes/custom-phone.pipe';
import { HeaderService } from '../../../shared/services/header.service';
import { LookupService } from '../../../shared/services/lookup.service';
import { UserDataService } from '../../../shared/services/user-data.service';
import { UtilsService } from '../../../shared/services/utils.service';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { ConfirmDialogSingleButton } from '../../../shared/types/confirm-dialog-single-button';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { CustomerProspectService } from '../../customer-prospect/shared/customerProspect.service';
import {
  MessageTemplateCreateEditComponent
} from '../../default-message/message-template-create-edit/message-template-create-edit.component';
import { DefaultMessageService } from '../../default-message/shared/default-message.service';
import { DentistService } from '../../dentist/shared/dentist.service';
import {
  InvitationTemplateLookupComponent
} from '../../invitation-template/invitation-template-lookup/invitation-template-lookup.component';
import {
  InvitationTemplateMergerComponent
} from '../../invitation-template/invitation-template-merger/invitation-template-merger.component';
import {
  TreatmentSaveModalComponent
} from '../../invitation-template/treatment-save-modal/treatment-save-modal.component';
import { DiscountSettingComponent } from '../../merchant/discount-setting/discount-setting.component';
import {
  MerchantAuthCodeModalComponent
} from '../../merchant/merchant-auth-code-modal/merchant-auth-code-modal.component';
import { MerchantViewComponent } from '../../merchant/merchant-view/merchant-view.component';
import { MerchantService } from '../../merchant/shared/merchant.service';
import { SmsConversationComponent } from '../../message/sms-conversation/sms-conversation.component';
import { SmsPromoterComponent } from '../../message/sms-promoter/sms-promoter.component';
import { ProductService } from '../../product/shared/product.service';
import { ProductUtil } from '../../product/shared/productUtil.type';
import { TreatmentChoiceComponent } from '../../treatment-plan/treatment-choice/treatment-choice.component';
import { BeforeStartingComponent } from '../before-starting/before-starting.component';
import { DiscountEditComponent } from '../discount-edit/discount-edit.component';
import {
  InvitationCustomerProspectEditComponent
} from '../invitation-customer-prospect-edit/invitation-customer-prospect-edit.component';
import { InvitationLookupComponent } from '../invitation-lookup/invitation-lookup.component';
import { InvitationViewSmsComponent } from '../invitation-view-sms/invitation-view-sms.component';
import { InvitationService } from '../shared/services/invitation.service';

@Component({
  selector: 'app-invitation-create',
  templateUrl: './invitation-create.component.html',
  styleUrls: ['./invitation-create.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.anim', style({ opacity: 0 }), { optional: true }),
        query(
          '.anim',
          stagger('100ms', [
            animate(
              '0.3s ease-out',
              keyframes([
                style({ opacity: 0, offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, offset: 1.0, height: '*' })
              ])
            )
          ]),
          { optional: true }
        )
      ]),
      transition('* => void', [
        query('.anim', style({ opacity: 1 }), { optional: true }),
        query(
          '.anim',
          stagger('100ms', [
            animate(
              '0.3s ease-in',
              keyframes([
                style({ opacity: 1, offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, offset: 1.0, height: 0 })
              ])
            )
          ]),
          { optional: true }
        )
      ])
    ])
  ]
})
export class InvitationCreateComponent implements OnInit {
  @ViewChild('wizard', { static: true }) wizard: WizardComponent;
  validateCreatedTreatment;
  isAdvanced = false;
  medicalHistory = false;
  isPatientReferredToggle = false;
  selectedTab = 0;
  disableNexBtn = true;
  isInvitationCreated = true;
  invitationStep = 2;
  treat_plan_cost = 0;
  senders = [];
  selectedSender = null;
  isSelectedSender = true;
  sendSMS = false;
  sendDate = new Date();
  sendTime = new Date();
  dentMsg;
  choice;
  merchant;
  contact;
  totalTreatmentValue = 0;
  hideAll = false;
  skipLookup = false;
  messageOption;
  isAdminOrPromoter = false;
  dontShowAgainSimpleType: boolean = JSON.parse(localStorage.getItem('dontShowAgainSimpleType'));
  selectAllTemplate = false;
  selectTreatmentValue = true;
  hideTreatmentValue = false;
  disableTreatmentValue = false;
  isPromoterOrAdmin = false;
  isMobileValid = false;
  isHomePhoneValid = false;
  isWorkPhoneValid = false;
  isEmailValid = false;
  refreshAmount = new EventEmitter();
  refreshTreatment = new EventEmitter();
  genders = [];
  merchants = [];
  merchantID = localStorage.getItem('selectedMerchant');
  stopFlying = false;
  flyAway = false;
  titles = [];
  tags = [];
  invitationType: any = [];
  invitationID = '';
  user = {
    firstname: '',
    title: '',
    lastname: '',
    middlename: '',
    gender: '',
    mobile: '',
    home_phone: '',
    work_phone: '',
    email: '',
    isReferred: false,
    dateOfBirth: null
  };
  align;
  treatmentPlan = {
    isTreatmentPlan: false,
    isMembershipPackage: false
  };
  attachTags = true;
  isModuleCustomMessages = Settings.global['isModuleCustomMessagesActive'];
  isModuleDiscountsActive = Settings.global['isModuleDiscountsActive'];
  isModulePatientHistoryActive = Settings.global['isModulePatientHistoryActive'];
  isModuleAdvancedTreatmentActive = Settings.global['isModuleAdvancedTreatmentActive'];
  isModuleExperimentalFeaturesActive = Settings.global['isModuleExperimentalFeaturesActive'];
  isModuleDemoFeaturesActive = Settings.global['isModuleDemoFeaturesActive'];
  isModuleTreatmentTemplateActive = Settings.global['isModuleTreatmentTemplateActive'];
  isModuleStockArtActive = Settings.global['isModuleStockArtActive'];
  isModulePatientPortalAccessActive = Settings.global['isModulePatientPortalAccessActive'];
  isModuleGoogleReviewActive = Settings.global['isModuleGoogleReviewActive'];
  invitationLinkOnStep = Settings.global['invitationLinkOnStep'];
  canHasDiscount = true;
  toggleDiscount = true;
  context = Settings.global['context'];
  defaultSendInvitationSMS = Settings.global['defaultSendInvitationSMS'];
  productGroups;
  selectedProductGroup;
  defaultMessageLists = [];
  cashDiscountDate;
  cashDiscountAmount = 0;
  cashDiscountAllowOverride = true;
  cashDiscountOffered = false;
  googleReviewPrompt = false;
  cashDiscountMinAmount;
  cashDiscountPercent;
  cashDiscountExpiredDays;
  hideDiscount = false;
  utils = new UtilsClass();
  disableAir = false;
  productUtils = new ProductUtil();
  clearEmail = new EventEmitter();
  clearMobile = new EventEmitter();
  clearHomePhone = new EventEmitter();
  clearWorkPhone = new EventEmitter();
  clearDate = new EventEmitter();
  // patent/guardian toggle
  isAllowedGuardian = false;
  isAllowedAppointment = false;
  isGuardian = false;
  isGuardianContactSame = true;
  isFinDMatch = false;
  selectedPatientID;
  selectedExistingPatientID;
  hideMerchantSelection = false;
  invitationTemplateMsg;
  healthHistoryActive = true;
  typeFilter;
  sessionType = 'merchant';
  @Output()
  getInvitationID = new EventEmitter();
  @Input()
  isIntegrated = false;
  @Input()
  redirectURL;
  @Input()
  redirectParam = null;
  EPInvitationID;
  stockArtDocuments = [];
  EPstockArtDocuments = [];
  simpleTreatmentDocuments = [];
  EPTreatmentDocuments = [];
  informedConsent = [
    {
      categoryID: null,
      itemCode: null,
      typeID: null,
      brandID: null,
      productID: null,
      showStockArtDocumentsModal: true,
      preDocuments: [],
      stockArtDocuments: [],
      postDocuments: [],
      marketingDocuments: []
    }
  ];

  defaultSelectedTags = [];
  selectedTags = [];
  isCloseWebhookButton = false;
  webhookUrl;
  transactionToken;
  transactionWebhookURL;
  messageDate = new Date();
  promoter;

  guid: any;

  constructor(
    private utilService: UtilsService,
    private merchantService: MerchantService,
    private dialog: MatDialog,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private lookupService: LookupService,
    private simplePageService: HelperService,
    private dentistService: DentistService,
    private invitationService: InvitationService,
    private userDataService: UserDataService,
    private customerProspectService: CustomerProspectService,
    private authenticationService: AuthenticationService,
    private defaultMessageSerivce: DefaultMessageService,
    private productService: ProductService,
    private customePhonePipe: CustomPhonePipe
  ) {
  }

  ngOnInit() {
    this.setupIntegrationURL();
    this.authenticationService.getPromoter().subscribe((promoter) => {

      if (promoter && promoter.data) {
        this.promoter = promoter.data;
      } else if (promoter) {
        this.promoter = promoter;
      }

    });

    AuthenticationService.healthHistoryAccess.subscribe((r) => {
      this.healthHistoryActive = r;
    });
    this.authenticationService.getSessionType().subscribe((sessionType) => {
      if (sessionType) {
        this.sessionType = sessionType;
      }
      this.authenticationService.getCurrentPractice().subscribe((currentPractice) => {
        if (currentPractice) {
          if (currentPractice && currentPractice['HealthHistoryEnabled.Overridden'] == 'Y') {
            this.healthHistoryActive = true;
          } else {
            this.healthHistoryActive = false;
          }
        }
      });
      this.sendSMS = this.defaultSendInvitationSMS;
      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isAdminOrPromoter = res;


        this.utilService.getCurrentAccess().subscribe((access) => {
          if (access) {
            this.isModuleCustomMessages = access['isModuleCustomMessagesActive'];
            this.isModuleDiscountsActive = access['isModuleDiscountsActive'];
            this.isModulePatientHistoryActive = access['isModulePatientHistoryActive'];
            this.isModuleAdvancedTreatmentActive = access['isModuleAdvancedTreatmentActive'];
            this.isModuleExperimentalFeaturesActive = access['isModuleExperimentalFeaturesActive'];
            this.isModuleTreatmentTemplateActive = access['isModuleTreatmentTemplateActive'];
            this.isModuleDemoFeaturesActive = access['isModuleDemoFeaturesActive'];
            this.isModuleStockArtActive = access['isModuleStockArtActive'];
            this.isModulePatientPortalAccessActive = access['isModulePatientPortalAccessActive'];
            this.isModuleGoogleReviewActive = access['isModuleGoogleReviewActive'];
          }
          if (this.context == 'retail') {
            this.router.navigate(['/merchant', { outlets: { page: ['new-invitation'] } }]);
          }
          this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
            this.isPromoterOrAdmin = res;
            if (this.isPromoterOrAdmin == true) {
              const payload = {
                fields: 'ID,TradingAs,CalculatedName,Status,HealthHistoryEnabled.Overridden'
              };
              this.merchantService.getList(payload, this.isPromoterOrAdmin).subscribe((res) => {
                this.merchants = res;
              });
              const _mID = JSON.parse(localStorage.getItem('selectedMerchant'));
              if (_mID) {
                this.merchantID = _mID;
                // this.hideMerchantSelection = true;
              }
              HeaderService.getPromoterViewAsObject().subscribe((res) => {
                if (res && res['promoterViewAs'] && res['promoterViewAs'] == 'promoter') {
                  this.merchantID = null;
                  this.hideMerchantSelection = false;
                } else if (res && res['promoterViewAs'] && res['promoterViewAs'] == 'merchant') {
                  this.merchantID = res['merchantID'];
                  this.hideMerchantSelection = true;
                }
              });
            }
            this.activeRoute.params.subscribe((params) => {
              if (!params['invitationID']) {
                this.router.navigate(['/merchant/']);
              } else {

                this.invitationID = params['invitationID'];


                UtilsClass.startLoadingForce();
                this.invitationService.getProgress(this.invitationID).subscribe((progress) => {
                  UtilsClass.stopLoadingForce();
                  this.progress(progress);
                  this.invitationService.getUserData(this.invitationID, null, this.sessionType).subscribe((res) => {
                    if (res) {
                      this.buildItem(res);
                    }
                  });
                  this.lookupService.getLookup('CodeLookup', 'Gender').subscribe((data) => {
                    this.genders = data;
                  });
                  this.lookupService.getLookup('SimpleLookup', 'Salutation').subscribe((data) => {
                    this.titles = data;
                  });
                  const ref = this.dialog.open(BeforeStartingComponent, { width: '650px' });
                  if (ref && ref.componentInstance) {
                    if (ref.componentInstance.showBeforeStartingInvitation == true) {
                      ref.close();
                    } else {
                      if (
                        ref.componentInstance &&
                        ref.componentInstance.closeModal &&
                        ref.componentInstance.closeModal.subscribe
                      ) {
                        const sub = ref.componentInstance.closeModal.subscribe((data) => {
                          ref.close();
                        });
                      }
                    }
                  }
                });
              }
            });
          });
        });
      });
    });
  }

  setupUserData() {
    if (this.invitationID) {
      const fields = [
        'merchantID',
        'totalTreatmentValue',
        'patient',
        'message',
        'medicalHistory',
        'googleReviewPrompt',
        'cashDiscountOffered',
        'attachTags',
        'tags',
        'productGroupCode',
        'skipLookup'
      ];
      this.invitationService.getUserData(this.invitationID, fields.join(','), this.sessionType).subscribe((res) => {
        if (res) {
          this.buildItem(res);
        }
      });
    }
  }

  buildItem(res) {
    if (res) {


      if (res['merchantID']) {
        this.merchantID = res['merchantID'];
      }

      if (res['totalTreatmentValue']) {
        const totalTreatmentValue = Number(res['totalTreatmentValue'] || 0);

        if (!totalTreatmentValue || totalTreatmentValue <= 0) {
          this.disableTreatmentValue = false;
          this.hideTreatmentValue = false;
        }
      } else {
        this.disableTreatmentValue = false;
        this.hideTreatmentValue = false;
      }

      if (res['medicalHistory'] != null) {
        this.medicalHistory = res['medicalHistory'];
      }
      if (res['googleReviewPrompt'] != null) {
        this.googleReviewPrompt = res['googleReviewPrompt'];
      }

      if (res['attachTags'] != null) {
        this.attachTags = res['attachTags'];
      }

      if (res['cashDiscountOffered'] != null) {
        this.cashDiscountOffered = res['cashDiscountOffered'];
      }

      if (res && res.tags && res.tags.length > 0) {
        this.defaultSelectedTags = JSON.parse(JSON.stringify(res.tags));
        this.selectedTags = res['tags'];
      } else {
        this.defaultSelectedTags = JSON.parse(JSON.stringify([]));
      }

      if (res['productGroupCode']) {
        this.selectedProductGroup = res['productGroupCode'];
      }

      if (res['skipLookup'] != null) {
        this.skipLookup = res['skipLookup'] || false;
      }

      if (res.message) {
        if (res.message.sendDate) {
          this.messageDate = res.message.sendDate;
        }

        if (res.message.content) {
          this.dentMsg = res.message.content;
        }
        if (res.message.sender) {
          this.selectedSender = res.message.sender;
        }
        if (res.message.viaSMS != null) {
          this.sendSMS = res.message.viaSMS;
        }
      }

      if (res.patient) {
        this.buildPatientInvitationData(res.patient);
      }
    }
  }

  buildPatientInvitationData(patient) {
    if (patient) {
      if (patient.prospectID) {
        this.selectedPatientID = patient.prospectID;
        this.isFinDMatch = true;
      }
      if (patient.existingPatientID) {
        this.selectedExistingPatientID = patient.existingPatientID;
      }

      if (patient.gender) {
        this.user.gender = patient.gender;
      }
      if (patient.title) {
        this.user.title = patient.title;
      }
      if (patient.firstName) {
        this.user.firstname = patient.firstName;
      }
      if (patient.middleName) {
        this.user.middlename = patient.middleName;
      }
      if (patient.lastName) {
        this.user.lastname = patient.lastName;
      }
      if (patient.isReferred) {
        this.user.isReferred = patient.isReferred;
      }

      if (patient.mobile) {
        this.user.mobile = patient.mobile;
      }

      if (patient.homePhone) {
        this.user.home_phone = patient.homePhone;
      }
      if (patient.workPhone) {
        this.user.work_phone = patient.workPhone;
      }
      if (patient.email) {
        this.user.email = patient.email;
      }
      if (patient.dateOfBirth) {
        this.user.dateOfBirth = patient.dateOfBirth;
      }
    }
  }

  setupIntegrationURL() {
    const origin = window.location.origin;
    const pathname = window.location.pathname;
    let link = window.location.href;

    if (link && origin) {
      if (pathname) {
        link = link.replace(origin + pathname, '');
      } else {
        link = link.replace(origin, '');
      }
    }

    const urlParams = new URLSearchParams(link);
    this.transactionWebhookURL = urlParams.get('transactionWebhookURL');
    this.transactionToken = urlParams.get('transactionToken');
    if (this.transactionWebhookURL) {
      this.transactionWebhookURL = this.transactionWebhookURL.replace(/\$/g, '/');
    }

    if (urlParams.get('isIntegrated') == 'true') {
      this.isIntegrated = true;
    } else {
      this.isIntegrated = false;
    }

    if (urlParams.get('isCloseWebhookButton') == 'true') {
      this.isCloseWebhookButton = true;
      this.isIntegrated = true;
    } else {
      this.isCloseWebhookButton = false;
    }
    if (urlParams.get('redirectParam') != null && urlParams.get('redirectParam') != 'null') {
      this.redirectParam = urlParams.get('redirectParam');
    } else {
      this.redirectParam = null;
    }
    if (urlParams.get('redirectURL') == null || urlParams.get('redirectURL') == 'null') {
      this.redirectURL = null;
    } else {
      this.redirectURL = urlParams.get('redirectURL');
    }

    if (this.isIntegrated == true) {
      this.selectTreatmentValue = false;
      this.hideTreatmentValue = true;
      this.disableTreatmentValue = true;
    }
  }

  checkMerchantProductGroup(merchantID = null) {
    const payload = {
      merchantID
    };
    this.productService.getMerchantProductGroup(payload, this.isPromoterOrAdmin).subscribe((res) => {
      if (res) {
        if (res.length <= 0) {
          const confirmDialog = new ConfirmDialogSingleButton(
            'warning',
            'No Product License Found',
            'Please contact Smile Right for more infomation',
            'Okay'
          );
          const ref = this.dialog.open(ConfirmDialogSingleButtonComponent, {
            data: confirmDialog,
            width: '650px'
          });
          ref.componentInstance.onConfirm.subscribe((res) => {
            ref.close();
            this.merchantID = null;
          });
          ref.afterClosed().subscribe((res) => {
            this.merchantID = null;
          });
        } else {
          const check = res.some((val) => {
            if (val['GroupCode'] === this.selectedProductGroup) {
              return true;
            } else {
              return false;
            }
          });
          if (check == false) {
            const confirmDialog = new ConfirmDialogSingleButton(
              'warning',
              this.productUtils.getLabel(this.selectedProductGroup) + ' hasn\'t been set up for your practice.',
              'Please contact Smile Right for more infomation',
              'Okay'
            );
            const ref = this.dialog.open(ConfirmDialogSingleButtonComponent, {
              data: confirmDialog,
              width: '650px'
            });
            ref.componentInstance.onConfirm.subscribe((res) => {
              ref.close();
              this.merchantID = null;
            });
            ref.afterClosed().subscribe((res) => {
              this.merchantID = null;
            });
          }
        }
      }
    });
  }

  getDiscount() {
    if (this.isModuleDiscountsActive == true) {
      UtilsClass.startLoadingForce();
      this.invitationService
        .getUserData(
          this.invitationID,
          'cashDiscountOffered,cashDiscountExpiredDays,cashDiscountPercent',
          this.sessionType
        )
        .subscribe((res) => {
          if (res) {
            this.cashDiscountOffered = res['cashDiscountOffered'];
            this.cashDiscountExpiredDays = res['cashDiscountExpiredDays'];
            this.cashDiscountPercent = res['cashDiscountPercent'];
            this.fillMerchantDiscount();
            UtilsClass.stopLoadingForce();
          } else {
            UtilsClass.stopLoadingForce();
          }
        });
    }
  }

  setUserDataEvent(attribute, value) {
    const payload = {
      attribute,
      value
    };

    UtilsClass.startLoadingData('Saving Your Data...');

    this.userDataService.setUserDataHidden(this.invitationID, payload, this.sessionType).subscribe((data) => {
      UtilsClass.stopLoadingData();
    });
  }

  saveDefinedTreatment(invitationTemplateID) {
    const ref = RootAppComponent.dialog.open(TreatmentSaveModalComponent, {
      data: {
        invitationTemplateID
      },
      width: '550px'
    });
    ref.componentInstance.result.subscribe((payload) => {
      if (payload) {
        const _payload = payload;
        if (this.merchantID) {
          _payload.merchantID = this.merchantID;
        }
        this.invitationService
          .createInvitationTreatmentTemplate(this.invitationID, _payload, this.isPromoterOrAdmin)
          .subscribe((res) => {
            if (res) {
              if (res._id) {
                ref.close();
                ref.afterClosed().subscribe((c) => {
                  const ref2 = this.dialog.open(InvitationTemplateMergerComponent, {
                    data: {
                      templateID: res._id,
                      viewOnly: true
                    },
                    width: '1200px'
                  });
                  ref2.componentInstance.delete.subscribe((res) => {
                    ref2.close();
                  });
                  ref2.componentInstance.close.subscribe((res) => {
                    ref2.close();
                  });
                });
              }
              NotifyAppComponent.displayToast('success', 'Successful operation', 'Treatment Template is Saved');
            }
            ref.close();
          });
      }
    });
    ref.componentInstance.closeModal.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  setInformedConsent(informedConsent) {
    this.informedConsent = informedConsent;
  }

  InvitationTemplateLookup() {
    const ref = this.dialog.open(InvitationTemplateLookupComponent, {
      data: {
        merchantID: this.merchantID,
        isModal: true,
        viewOnClick: true,
        viewOnly: false,
        selectAll: this.selectAllTemplate,

        productGroup: this.selectedProductGroup || 'all',
        hideTreatmentValue: this.hideTreatmentValue,
        selectTreatmentValue: this.selectTreatmentValue,
        defaultTreatmentAmount: this.totalTreatmentValue || 0
      },
      panelClass: 'bigger-screen',
      width: '820px'
    });
    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
    ref.componentInstance.getResult.subscribe((result) => {
      if (result && result.templateID) {
        const payload = {
          invitationTemplateID: result.templateID,
          templateID: result.templateID,
          selectDocuments: result.selectDocuments,
          selectImages: result.selectImages,
          selectTreatmentPlan: result.selectTreatmentPlan,
          selectTags: result.selectTags,
          selectMessage: result.selectMessage,
          selectDiscount: result.selectDiscount,
          selectHealthHistory: result.selectHealthHistory,
          selectGoogleReview: result.selectGoogleReview,
          treatmentValue: result.treatmentValue,
          selectTreatmentValue: result.selectTreatmentValue,
          isExtra: true
        };

        this.invitationService
          .updateInvitationTreatmentTemplate(this.invitationID, payload, this.isPromoterOrAdmin)
          .subscribe((res) => {
            if (res) {
              if (res.totalTreatmentValue) {
                this.treat_plan_cost = res.totalTreatmentValue;
                this.totalTreatmentValue = res.totalTreatmentValue;
              }

              this.bindData(res, true);
              NotifyAppComponent.displayToast('success', 'Success!', 'Treatment Template is  applied.');
              this.hideAll = false;
              ref.close();
              this.refreshTreatment.emit(res);
            } else {
              ref.close();
              this.hideAll = false;
            }
          });
      }
    });
  }

  refreshTotalCost(value) {
    this.treat_plan_cost = value || 0;
    this.totalTreatmentValue = value || 0;
  }

  setSimpleTreatmentDocuments(documents) {
    this.simpleTreatmentDocuments = documents;
  }

  setEpTreatmentDocuments(epDocuments) {
    this.EPTreatmentDocuments = epDocuments;
  }

  setStockArtDocuments(stockArts) {
    this.stockArtDocuments = stockArts;
  }

  setEPStockartDocuments(EPStockArts) {
    this.EPstockArtDocuments = EPStockArts;
  }

  bindData(template, clear = false) {
    if (template) {
      if (template.message) {
        if (template.message.sender) {
          this.selectedSender = template.message.sender;
        }
        if (template.message.viaSMS != null) {
          this.sendSMS = template.message.viaSMS;
        }
        if (template.message.contentRaw && template.message.defaultID) {
          if (this.defaultMessageLists && this.defaultMessageLists.length > 0) {
            for (let i = 0; i < this.defaultMessageLists.length; i++) {
              if (
                template.message.defaultID &&
                this.defaultMessageLists &&
                this.defaultMessageLists[i]['ID'] == template.message.defaultID
              ) {
                this.messageOption = this.defaultMessageLists[i];
              }
            }
          } else {
            this.messageOption = {
              ID: template.message.defaultID,
              value: template.message.contentRaw,
              label: template.message.label
            };
          }
        }
      } else if (clear == true) {
        this.messageOption = null;
        this.sendSMS = this.defaultSendInvitationSMS;
        this.selectedSender = null;
      }
      if (this.selectedSender) {
        this.dentistService.getContactDetails(this.selectedSender, {}, this.sessionType).subscribe((contact) => {
          if (contact) {
            this.contact = contact;

            if (template && template.message && template.message.contentRaw) {
              const p = {
                value: template.message.contentRaw,
                defaultID: template.message.defaultID
              };
              this.defaultMessageChange(p);
            }
          }
        });
      } else {
        this.authenticationService.getCurrentDentist().subscribe((contact) => {
          if (contact) {
            this.contact = contact;
            if (template && template.message && template.message.contentRaw) {
              const p = {
                value: template.message.contentRaw,
                defaultID: template.message.defaultID
              };
              this.defaultMessageChange(p);
            }
          } else {
            if (template && template.message && template.message.contentRaw) {
              const p = {
                value: template.message.contentRaw,
                defaultID: template.message.defaultID
              };
              this.defaultMessageChange(p);
            }
          }
        });
      }
      if (template.cashDiscountOffered != null) {
        this.cashDiscountOffered = template.cashDiscountOffered;
      }

      if (template && template.tags && template.tags.length > 0) {
        this.defaultSelectedTags = JSON.parse(JSON.stringify(template.tags));
      } else {
        this.defaultSelectedTags = JSON.parse(JSON.stringify([]));
      }

      if (template.medicalHistory != null) {
        this.medicalHistory = template.medicalHistory;
      }
      if (template.googleReviewPrompt != null) {
        this.googleReviewPrompt = template.googleReviewPrompt;
      } else if (clear == true) {
        this.cashDiscountPercent = null;
      }
      if (template.cashDiscountExpiredDays != null) {
        this.cashDiscountExpiredDays = template.cashDiscountExpiredDays;
      } else if (clear == true) {
        this.cashDiscountExpiredDays = null;
      }
      if (template.cashDiscountPercent != null) {
        this.cashDiscountPercent = template.cashDiscountPercent;
      } else if (clear == true) {
        this.cashDiscountPercent = null;
      }
      this.getDiscount();
    }
  }

  clearTemplate(e) {
    if (e && this.invitationID) {
      this.hideAll = true;
      const p = {
        templateID: e,
        invitationTemplateID: e
      };
      this.invitationService
        .clearInvitationTreatmentTemplate(this.invitationID, p, this.isPromoterOrAdmin)
        .subscribe((res) => {
          if (res) {
            this.bindData(res, true);
            NotifyAppComponent.displayToast('success', 'Success!', 'Treatment Template is Removed.');
            this.hideAll = false;
            this.refreshTreatment.emit(res);
          } else {
            this.hideAll = false;
          }
        });
    }
  }

  changeMerchant(merchantID = null) {
    if (merchantID) {
      this.merchantService.getMerchantDetails(merchantID, {}, this.sessionType).subscribe((res) => {
        if (res) {
          this.merchant = res;
          this.calculCashDiscount(merchantID);
          this.getDefaultMessageList(merchantID);
          this.checkHH();
        }
      });
    } else {
      this.authenticationService.getCurrentPractice().subscribe((res) => {
        if (res) {
          this.merchant = res;
          this.calculCashDiscount(merchantID);
          this.getDefaultMessageList();
          this.checkHH();
        }
      });
    }
  }

  checkHH() {
    if (this.merchant && this.merchant['HealthHistoryEnabled.Overridden'] == 'Y') {
      this.healthHistoryActive = true;
    } else {
      this.healthHistoryActive = false;
    }
  }

  templateSelectAll(e) {
    this.selectAllTemplate = e || false;
  }

  changeDiscountOffer(e) {
    const payload = {
      attribute: 'cashDiscountOffered',
      value: e
    };
    this.userDataService.setUserData(this.invitationID, payload, this.sessionType).subscribe((data) => {
      this.toggleChange(e);
    });
  }

  toggleChange(e) {
    if (this.cashDiscountOffered != e) {
      this.cashDiscountOffered = e;
    }
    if (this.cashDiscountOffered == true) {
      if (this.merchant && this.merchant.ID) {
        this.calculCashDiscount(this.merchant.ID);
      } else if (this.merchantID && this.isPromoterOrAdmin == true) {
        this.calculCashDiscount(this.merchant.ID);
      } else {
        this.calculCashDiscount(null);
      }
    }
  }

  fillMerchantDiscount(merchantID = null) {
    this.checkMerchantProductGroup(merchantID);
    this.calculCashDiscount(merchantID);
  }

  merchantChange() {
    const payload = {
      attribute: 'merchantID',
      value: this.merchantID
    };
    this.userDataService.setUserData(this.invitationID, payload, this.sessionType).subscribe((data) => {
      this.checkMerchantProductGroup(this.merchantID);
      this.calculCashDiscount(this.merchantID);
    });
  }

  calculCashDiscount(merchantID) {
    const payload = {
      cashDiscountOffered: this.cashDiscountOffered,
      cashDiscountPercent: this.cashDiscountPercent,
      cashDiscountExpiredDays: this.cashDiscountExpiredDays,
      treatmentAmount: this.totalTreatmentValue,
      merchantID,
      invitationID: this.invitationID
    };
    this.invitationService.calculateDiscount(payload, this.sessionType).subscribe((res) => {
      if (res) {
        this.hideDiscount = res.hideDiscount;
        this.cashDiscountOffered = res.cashDiscountOffered;
        this.cashDiscountAllowOverride = res.cashDiscountAllowOverride;
        this.cashDiscountPercent = res.cashDiscountPercent;
        this.cashDiscountExpiredDays = res.cashDiscountExpiredDays;
        this.cashDiscountMinAmount = res.cashDiscountMinAmount;
        this.cashDiscountAmount = res.cashDiscountAmount;
      } else {
        this.hideDiscount = true;
        this.cashDiscountOffered = false;
        this.cashDiscountAllowOverride = false;
        this.cashDiscountPercent = null;
        this.cashDiscountExpiredDays = null;
        this.cashDiscountMinAmount = null;
        this.cashDiscountAmount = null;
      }
    });
  }

  getEmail(p) {
    this.user.email = p;
  }

  viewMerchant() {
    const ref = this.dialog.open(MerchantViewComponent, {
      data: this.merchantID,
      width: '600px'
    });
    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  getMobile(p) {
    this.user.mobile = p;
  }

  getHomePhone(p) {
    this.user.home_phone = p;
  }

  getWorkPhone(p) {
    this.user.work_phone = p;
  }

  validateMobile(v) {
    this.isMobileValid = v;
  }

  validateHomePhone(v) {
    this.isHomePhoneValid = v;
  }

  validateWorkPhone(v) {
    this.isWorkPhoneValid = v;
  }

  validateEmail(v) {
    this.isEmailValid = v;
  }

  onStepChange() {
    UtilsClass.scrollUp();
    const step = this.wizard.currentStepIndex;
    this.invitationService.setProgress(this.invitationID, step).subscribe(() => {
    });
  }

  progress(p) {
    let step = 0;
    if (!p) {
      step = 0;
    } else if (p && p.page == 'create') {
      step = p.step;
    } else {
      step = 3;
    }
    switch (step) {
      case 0: {
        this.wizard.goToStep(0);
        break;
      }
      case 1: {
        this.step2();
        break;
      }
      case 2: {
        this.step3();
        break;
      }
      default: {
        this.wizard.goToStep(3);
      }
    }
  }

  /**
   * @Description
   *  Open patien lookup modal
   */
  openDiscountSettingDialog() {
    const ref = RootAppComponent.dialog.open(DiscountSettingComponent, {
      data: {
        merchantID: this.merchantID
      },
      width: '550px'
    });
    ref.componentInstance.closeModal.subscribe((res) => {
      ref.close();
    });
    ref.componentInstance.getMerchant.subscribe((res) => {
      if (res) {
        if (res.merchant && this.merchant.ID) {
          this.calculCashDiscount(this.merchant.ID);
        }
      }
      ref.close();
    });
  }

  openDiscountInvitationDialog() {
    const ref = RootAppComponent.dialog.open(DiscountEditComponent, {
      data: {
        merchantID: this.merchantID,
        treatmentAmount: this.treat_plan_cost,
        cashDiscountPercent: this.cashDiscountPercent,
        cashDiscountExpiredDays: this.cashDiscountExpiredDays,
        cashDiscountOffered: this.cashDiscountOffered
      },
      width: '600px'
    });
    ref.componentInstance.closeModal.subscribe((res) => {
      ref.close();
    });
    ref.componentInstance.getResult.subscribe((res) => {
      if (res && res.merchant && res.merchant.ID) {
        this.cashDiscountPercent = res.cashDiscountPercent;
        this.cashDiscountExpiredDays = res.cashDiscountExpiredDays;
        this.cashDiscountOffered = res.cashDiscountOffered;
        this.calculCashDiscount(res.merchant.ID);
      }
      ref.close();
    });
  }

  openPatientLookUpDialog() {
    const ref = this.dialog.open(InvitationLookupComponent, {
      data: {
        invitationID: this.invitationID,
        merchantID: this.merchantID,
        onlyMyAccount: true,
        saveUserData: true,
        useStrict: true
      },
      width: '600px',
    });
    const sub = ref.componentInstance.closeModal.subscribe((data) => {
      ref.close();
    });

    ref.componentInstance.patientChat.subscribe((res) => {
      if (res && res.cardID) {


        if (res.isPromoterOrAdmin == true) {
          const ref2 = RootAppComponent.dialog.open(SmsPromoterComponent, {
            data: {
              merchantID: res.merchantID,
              cardID: res.cardID,
              singleChat: true
            },
            panelClass: 'noCard',
            width: '500px'
          });
          ref2.componentInstance.close.subscribe((data) => {
            ref2.close();
          });

          ref2.componentInstance.getResult.subscribe((data) => {
            if (data) {
              const ref3 = RootAppComponent.dialog.open(SmsConversationComponent, {
                data,
                panelClass: 'bigger-screen',
                width: '70%',
                height: '95vh'
              });
              const sub = ref3.componentInstance.close.subscribe((data) => {
                ref3.close();
              });
            }

            ref2.close();
          });
        } else {
          const ref2 = RootAppComponent.dialog.open(SmsConversationComponent, {
            data: {
              merchantID: res.merchantID,
              cardID: res.cardID,
              singleChat: true
            },
            panelClass: 'bigger-screen',
            width: '70%',
            height: '95vh'
          });
          const sub = ref2.componentInstance.close.subscribe((data) => {
            ref2.close();
          });

        }

      }
    });
    ref.componentInstance.detailedView.subscribe((res) => {
      ref.close();
    });
    ref.componentInstance.getResult.subscribe((res) => {
      if (res && res.ID) {
        const sender = {
          attribute: 'patient',
          value: res
        };
        this.userDataService.setUserData(this.invitationID, sender, this.sessionType).subscribe((r) => {
          if (r && r.data && r.data.content && r.data.content.patient) {
            this.buildPatientInvitationData(r.data.content.patient);
          }

          ref.close();
        });
      }
    });
  }

  modifyProfile() {
    // this.isFinDMatch = false;
    if (this.selectedPatientID && this.invitationID) {
      const ref = this.dialog.open(InvitationCustomerProspectEditComponent, {
        data: {
          patientID: this.selectedPatientID,
          invitationID: this.invitationID
        },
        width: '650px'
      });
      ref.componentInstance.closeModal.subscribe((res) => {
        ref.close();
      });
      ref.componentInstance.getResult.subscribe((res) => {
        if (res && res.ID) {
          this.invitationService.linkToInv(res.ID, this.invitationID).subscribe((res) => {
            const fields = ['patient'];
            this.invitationService
              .getUserData(this.invitationID, fields.join(','), this.sessionType)
              .subscribe((res) => {
                if (res && res.patient) {
                  this.buildPatientInvitationData(res.patient);
                }

                ref.close();
              });
          });
        }
      });
    }
  }

  openSmsPreviewDialog() {
    const ref = this.dialog.open(InvitationViewSmsComponent, {
      data: {
        invitationID: this.invitationID
      },
      width: '500px'
    });
    const sub = ref.componentInstance.closeModal.subscribe((data) => {
      ref.close();
    });
  }

  openEmailPreviewDialog() {
    const ref = RootAppComponent.dialog.open(TemplateReviewComponent, {
      data: {
        templateTag: 'consumer-invitation',
        isPrint: false,
        isProceed: false,
        isDownload: false,
        proceedLabel: null
      },
      width: '700px'
    });
    ref.componentInstance.closeModal.subscribe((res) => {
      ref.close();
    });
  }

  addNewMessage() {
    const ref = this.dialog.open(MessageTemplateCreateEditComponent, {
      data: {
        merchantID: this.merchantID,
        productGroupCode: this.selectedProductGroup,
        purposeCode: 'MerInv',
        displayProductGroup: false,
        displayPurpose: false
      },
      width: "900px",
      panelClass: 'noCard'
    });
    ref.componentInstance.createdMessage.subscribe((data) => {
      if (data) {
        const r = {
          ID: data['ID'],
          label: data['Label'],
          value: data['Content']
        };
        this.defaultMessageLists.push(r);
        ref.close();
      }
    });
    const sub = ref.componentInstance.closeModal.subscribe((data) => {
      ref.close();
    });
  }

  onValidateTreatmentCreation(data) {
    this.validateCreatedTreatment = data;
  }

  openInviteViewDetails() {
    this.flyAway = true;
    setTimeout(() => {
      UtilsClass.startLoadingForce();
      this.invitationService
        .getUserData(this.invitationID, 'EPinvitationID,patient.prospectID', this.sessionType)
        .subscribe((res) => {
          UtilsClass.stopLoadingForce();
          if (res && res['EPinvitationID'] && res['patient.prospectID']) {
            this.router.navigate([
              '/merchant',
              {
                outlets: { page: ['customer-profile', res['patient.prospectID'], 'treatment', res['EPinvitationID']] }
              }
            ]);
          } else if (res && res['EPinvitationID']) {
            this.router.navigate(['/merchant', { outlets: { page: ['invitation-view', res['EPinvitationID']] } }]);
          }
        });
    }, 1800);
  }

  invitationPage() {
    this.flyAway = true;

    const url = Settings.global['consumerFrontendLink'] + '/consumer/(page:invitation-card/' + this.invitationID + ')';
    window.open(url, '_blank');
    this.flyAway = false;
    // }
  }

  exitToDash() {
    this.flyAway = true;
    setTimeout(() => {
      this.router.navigate(['/merchant']);
    }, 1800);
  }

  finishFunction() {
    this.router.navigate(['/merchant']);
  }

  saveInvitation() {
    UtilsClass.scrollUp();
    let body = {};
    if (this.isPromoterOrAdmin == true) {
      body = {
        merchantID: this.merchantID
      };
    }

    this.invitationService.createByUserDataID(this.invitationID, body, this.isPromoterOrAdmin).subscribe((res) => {
      if (res) {
        this.EPInvitationID = res;

        if (this.isIntegrated == true) {
          this.invitationService
            .getInvitationDetails(this.EPInvitationID, { fields: 'Webhook_URL' }, this.sessionType)
            .subscribe((res) => {
              if (res && res['Webhook_URL']) {
                this.webhookUrl = res['Webhook_URL'];

                this.wizard.goToStep(3);
                this.stopPlaneFlying();
                this.sendResult();
              } else if (this.transactionWebhookURL) {
                this.wizard.goToStep(3);
                this.stopPlaneFlying();
                this.sendResult();
              } else if (this.redirectURL) {
                this.wizard.goToStep(3);
                this.stopPlaneFlying();
                this.sendResult();
              }
            });
        } else {
          this.wizard.goToStep(3);
          this.stopPlaneFlying();
        }
      }
    });
  }

  stopPlaneFlying() {
    setTimeout(() => {
      this.stopFlying = true;
    }, 2500);
  }

  step1() {
    UtilsClass.scrollUp();

    this.invitationService
      .getUserData(this.invitationID, 'ExistingPatientID,skipLookup', this.sessionType)
      .subscribe((userData) => {
        if (!userData.ExistingPatientID && userData.skipLookup != true) {
          let payload = {};
          if (this.isPromoterOrAdmin == true) {
            payload = {
              merchantID: this.merchantID
            };
          }
          this.customerProspectService
            .searchByInvitationId(this.invitationID, payload, this.isPromoterOrAdmin)
            .subscribe((result) => {
              if (result.data && result.data != null && result.data.length != 0) {
                const patientLookup = this.dialog.open(InvitationLookupComponent, {
                  data: {
                    invitationID: this.invitationID,
                    patients: result.data,
                    hideFilter: true,
                    showNextBtn: true
                  },
                  width: '600px',
                });

                patientLookup.componentInstance.patientChat.subscribe((res) => {
                  if (res && res.cardID) {


                    if (res.isPromoterOrAdmin == true) {
                      const ref2 = RootAppComponent.dialog.open(SmsPromoterComponent, {
                        data: {
                          merchantID: res.merchantID,
                          cardID: res.cardID,
                          singleChat: true
                        },
                        panelClass: 'noCard',
                        width: '500px'
                      });
                      ref2.componentInstance.close.subscribe((data) => {
                        ref2.close();
                      });

                      ref2.componentInstance.getResult.subscribe((data) => {
                        if (data) {
                          const ref3 = RootAppComponent.dialog.open(SmsConversationComponent, {
                            data,
                            panelClass: 'bigger-screen',
                            width: '70%',
                            height: '95vh'
                          });
                          const sub = ref3.componentInstance.close.subscribe((data) => {
                            ref3.close();
                          });
                        }

                        ref2.close();
                      });
                    } else {
                      const ref2 = RootAppComponent.dialog.open(SmsConversationComponent, {
                        data: {
                          merchantID: res.merchantID,
                          cardID: res.cardID,
                          singleChat: true
                        },
                        panelClass: 'bigger-screen',
                        width: '70%',
                        height: '95vh'
                      });
                      const sub = ref2.componentInstance.close.subscribe((data) => {
                        ref2.close();
                      });

                    }

                  }
                });
                patientLookup.componentInstance.detailedView.subscribe((res) => {
                  patientLookup.close();
                });
                patientLookup.componentInstance.getResult.subscribe((res) => {
                  if (res) {
                    const sender = {
                      attribute: 'patient',
                      value: res
                    };
                    this.userDataService.setUserData(this.invitationID, sender, this.sessionType).subscribe((data) => {
                      this.isFinDMatch = true;
                      if (res.ID) {
                        this.selectedPatientID = res.ID;
                      }
                      if (res.ExistingPerson_key) {
                        this.selectedExistingPatientID = res.ExistingPerson_key;
                      }
                      patientLookup.close();
                    });
                  }
                });
                patientLookup.componentInstance.createNew.subscribe((data) => {
                  UtilsClass.savingData();
                  const sender = {
                    attribute: 'skipLookup',
                    value: true
                  };
                  this.userDataService.setUserData(this.invitationID, sender, this.sessionType).subscribe((res) => {
                    UtilsClass.stopLoadingForce();
                    this.skipLookup = true;
                    patientLookup.close();
                    this.step2();
                  });
                });
                patientLookup.componentInstance.closeModal.subscribe((data) => {
                  if (data == true) {
                    patientLookup.close();
                  } else {
                    patientLookup.close();
                  }
                });
                // }
                // }
                // });
              } else {
                this.step2();
              }
            });
        } else {
          this.step2();
        }
      });
  }

  defaultMessageChange(m) {
    if (m && m.value) {
      const payload = {};
      payload['message.content'] = this.convertMessage(m.value);
      payload['message.contentRaw'] = m.value;
      payload['message.defaultID'] = m.ID;
      UtilsClass.savingData();
      this.userDataService.setUserDataArrayForce(this.invitationID, payload, this.sessionType).subscribe((res) => {
        this.dentMsg = this.convertMessage(m.value);
        UtilsClass.stopLoadingForce();
      });
    }
  }

  getDefaultMessageList(merchantID = null) {
    if (this.isModuleCustomMessages == true) {
      if (this.isPromoterOrAdmin == false || merchantID == null) {
        const payload = {
          selectDefault: true,
          orderBy: 'Label',
          purposeCode: 'MerInv',
          productGroupCode: this.selectedProductGroup,
          onlyActive: true
        };
        this.defaultMessageSerivce.getDefaultMessageList(payload).subscribe((res) => {
          if (res && res.length > 0) {
            for (let i = 0; i < res.length; i++) {
              const r = {
                ID: res[i]['ID'],
                label: res[i]['Label'],
                value: res[i]['Content'],
                IsPromoterTemplate: res[i]['IsPromoterTemplate']
              };
              this.defaultMessageLists.push(r);
            }
            if (this.defaultMessageLists && this.defaultMessageLists.length > 0) {
              this.invitationService
                .getUserData(this.invitationID, 'message.defaultID', this.sessionType)
                .subscribe((res) => {
                  if (res && res['message.defaultID']) {
                    for (let i = 0; i < this.defaultMessageLists.length; i++) {
                      if (
                        this.defaultMessageLists[i] &&
                        this.defaultMessageLists[i]['ID'] == res['message.defaultID']
                      ) {
                        this.messageOption = this.defaultMessageLists[i];
                        break;
                      }
                    }
                  }
                });
            }
          } else {
            this.defaultMessageLists = [];
          }
        });
      } else {
        const payload = {
          onlyActive: true,
          purposeCode: 'MerInv',
          orderBy: 'Label',
          selectDefault: true,
          merchantID: this.merchantID
        };
        this.defaultMessageSerivce.getDefaultMessageListGlobal(payload).subscribe((res) => {
          if (res && res.length > 0) {
            for (let i = 0; i < res.length; i++) {
              const r = {
                ID: res[i]['ID'],
                label: res[i]['Label'],
                value: res[i]['Content']
              };
              this.defaultMessageLists.push(r);
            }
            if (this.defaultMessageLists && this.defaultMessageLists.length > 0) {
              this.invitationService
                .getUserData(this.invitationID, 'message.defaultID', this.sessionType)
                .subscribe((res) => {
                  if (res && res['message.defaultID']) {
                    for (let i = 0; i < this.defaultMessageLists.length; i++) {
                      if (
                        this.defaultMessageLists[i] &&
                        this.defaultMessageLists[i]['ID'] == res['message.defaultID']
                      ) {
                        this.messageOption = this.defaultMessageLists[i];
                        break;
                      }
                    }
                  }
                });
            }
          } else {
            this.defaultMessageLists = [];
          }
        });
      }
    }
  }

  convertMessage(message) {
    let result;
    const rule = {};
    if (this.user) {
      rule['PatientSalutation'] = this.user.title;
    } else {
      rule['PatientSalutation'] = ' ';
    }
    if (this.user) {
      rule['PatientLastName'] = this.user.lastname;
    } else {
      rule['PatientLastName'] = ' ';
    }
    if (this.user) {
      rule['PatientAddress'] = this.user['address'];
    } else {
      rule['PatientAddress'] = ' ';
    }


    if (this.user) {
      rule['PatientFirstName'] = this.user.firstname;
    } else {
      rule['PatientFirstName'] = ' ';
    }
    if (this.user) {
      rule['PatientMiddleName'] = this.user.middlename;
    } else {
      rule['PatientMiddleName'] = ' ';
    }
    if (this.user) {
      rule['PatientMobileNumber'] = this.customePhonePipe.transform(this.user.mobile, 'false');
    } else {
      rule['PatientMobileNumber'] = ' ';
    }
    if (this.user) {
      rule['PatientWorkPhoneNumber'] = this.customePhonePipe.transform(this.user.work_phone, 'true');
    } else {
      rule['PatientWorkPhoneNumber'] = ' ';
    }
    if (this.user) {
      rule['PatientEmail'] = this.user.email;
    } else {
      rule['PatientEmail'] = ' ';
    }
    if (this.contact) {
      rule['DentistSalutation'] = this.contact['Salutation'];
    } else {
      rule['DentistSalutation'] = ' ';
    }
    if (this.contact) {
      rule['DentistFirstName'] = this.contact['FirstName'];
    } else {
      rule['DentistFirstName'] = ' ';
    }
    if (this.contact) {
      rule['DentistMiddleName'] = this.contact['MiddleName'];
    } else {
      rule['DentistMiddleName'] = ' ';
    }
    if (this.contact) {
      rule['DentistLastName'] = this.contact['Name'];
    } else {
      rule['DentistLastName'] = ' ';
    }
    if (this.contact) {
      rule['DentistMobileNumber'] = this.customePhonePipe.transform(this.contact['mobiles.Number'], 'false');
    } else {
      rule['DentistMobileNumber'] = ' ';
    }
    if (this.contact) {
      rule['DentistPhoneNumber'] = this.customePhonePipe.transform(this.contact['phones.Number'], 'true');
    } else {
      rule['DentistPhoneNumber'] = ' ';
    }
    if (this.contact) {
      rule['DentistEmail'] = this.contact['emails.Email'];
    } else {
      rule['DentistEmail'] = ' ';
    }
    if (this.contact) {
      rule['DentistAddress'] = this.contact['addresses.Calculated'];
    } else {
      rule['DentistAddress'] = ' ';
    }
    if (this.merchant) {
      rule['PracticeLegalName'] = this.merchant['Name'];
    } else {
      rule['PracticeLegalName'] = ' ';
    }
    if (this.merchant) {
      rule['PracticeLegalName'] = this.merchant['Name'];
    } else {
      rule['PracticeLegalName'] = ' ';
    }
    if (this.merchant) {
      rule['PracticeAddress'] = this.merchant['addresses.Calculated'];
    } else {
      rule['PracticeAddress'] = ' ';
    }
    if (this.merchant) {
      rule['PracticeTradingAs'] = this.merchant['TradingAs'];
    } else {
      rule['PracticeTradingAs'] = ' ';
    }
    if (this.merchant) {
      rule['PracticePhoneNumber'] = this.customePhonePipe.transform(this.merchant['phones.Number'], 'true');
    } else {
      rule['PracticePhoneNumber'] = ' ';
    }
    if (this.merchant && this.merchant['mobiles.Number']) {
      rule['PracticeMobileNumber'] = this.customePhonePipe.transform(this.merchant['mobiles.Number'], 'false');
    } else {
      rule['PracticeMobileNumber'] = ' ';
    }
    if (this.merchant) {
      rule['PracticeAddress'] = this.merchant['addresses.Calculated'];
    } else {
      rule['PracticeAddress'] = ' ';
    }
    if (this.merchant) {
      rule['PracticeFirstName'] = this.merchant['FirstName'];
    } else {
      rule['PracticeFirstName'] = ' ';
    }


    if (this.merchant) {
      rule['PracticeABN'] = this.merchant['ABN'];
    } else {
      rule['PracticeABN'] = ' ';
    }
    if (this.merchant) {
      rule['PracticeACN'] = this.merchant['ACN'];
    } else {
      rule['PracticeACN'] = ' ';
    }
    if (this.merchant) {
      rule['PracticeEmail'] = this.merchant['emails.Email'];
    } else {
      rule['PracticeEmail'] = ' ';
    }

    if (this.merchant && this.merchant['Facebook']) {
      rule['PracticeFacebook'] = this.merchant['Facebook'];
    } else {
      rule['PracticeFacebook'] = ' ';
    }

    if (this.merchant && this.merchant['Instagram']) {
      rule['PracticeInstagram'] = this.merchant['Instagram'];
    } else {
      rule['PracticeInstagram'] = ' ';
    }

    if (this.merchant && this.merchant['Twitter']) {
      rule['PracticeTwitter'] = this.merchant['Twitter'];
    } else {
      rule['PracticeTwitter'] = ' ';
    }

    if (this.merchant && this.merchant['LinkedIn']) {
      rule['PracticeLinkedIn'] = this.merchant['LinkedIn'];
    } else {
      rule['PracticeLinkedIn'] = ' ';
    }

    if (this.merchant && this.merchant['Pinterest']) {
      rule['PracticePinterest'] = this.merchant['Pinterest'];
    } else {
      rule['PracticePinterest'] = ' ';
    }

    if (this.merchant && this.merchant['Tumblr']) {
      rule['PracticeTumblr'] = this.merchant['Tumblr'];
    } else {
      rule['PracticeTumblr'] = ' ';
    }

    if (this.merchant && this.merchant['Vimeo']) {
      rule['PracticeVimeo'] = this.merchant['Vimeo'];
    } else {
      rule['PracticeVimeo'] = ' ';
    }

    if (this.merchant && this.merchant['YouTube']) {
      rule['PracticeYouTube'] = this.merchant['YouTube'];
    } else {
      rule['PracticeYouTube'] = ' ';
    }

    if (this.merchant && this.merchant['URL']) {
      rule['PracticeWebSite'] = this.merchant['URL'];
    } else {
      rule['PracticeWebSite'] = ' ';
    }

    if (this.merchant && this.merchant['Terminal_Code']) {
      rule['PracitceAppointment'] = `${Settings.global['publicSiteFrontendLink']}/a/${this.merchant['Terminal_Code']}`;
    } else {
      rule['PracitceAppointment'] = ' ';
    }

    if (this.merchant && this.merchant['Terminal_Code']) {
      rule['PracticePage'] = `${Settings.global['publicSiteFrontendLink']}/l/p/s/${this.merchant['Terminal_Code']}`;
    } else {
      rule['PracticePage'] = ' ';
    }

    if (this.merchant && this.merchant['Terminal_Code']) {
      rule['TipsAndOffers'] = `${Settings.global['publicSiteFrontendLink']}/l/d/s/${this.merchant['Terminal_Code']}`;
    } else {
      rule['TipsAndOffers'] = ' ';
    }


    if (this.promoter) {
      if (this.promoter['TradingAs']) {
        rule['PromoterTradingAs'] = this.promoter['TradingAs'];
      } else {
        rule['PromoterTradingAs'] = ' ';
      }

      if (this.promoter['FirstName']) {
        rule['PromoterFirstName'] = this.promoter['FirstName'];
      } else {
        rule['PromoterFirstName'] = ' ';
      }

      if (this.promoter['Name']) {
        rule['PromoterLegalName'] = this.promoter['Name'];
      } else {
        rule['PromoterLegalName'] = ' ';
      }

      if (this.promoter['mobiles.Number']) {
        rule['PromoterMobileNumber'] = this.customePhonePipe.transform(this.promoter['mobiles.Number'], 'false');
      } else {
        rule['PromoterMobileNumber'] = ' ';
      }

      if (this.promoter['phones.Number']) {
        rule['PromoterPhoneNumber'] = this.customePhonePipe.transform(this.promoter['phones.Number'], 'true');
      } else {
        rule['PromoterPhoneNumber'] = ' ';
      }

      if (this.promoter['emails.Email']) {
        rule['PromoterEmail'] = this.promoter['emails.Email'];
      } else {
        rule['PromoterEmail'] = ' ';
      }

      if (this.promoter['addresses.Calculated']) {
        rule['PromoterAddress'] = this.promoter['addresses.Calculated'];
      } else {
        rule['PromoterAddress'] = ' ';
      }

      if (this.promoter['Facebook']) {
        rule['PromoterFacebook'] = this.promoter['Facebook'];
      } else {
        rule['PromoterFacebook'] = ' ';
      }

      if (this.promoter['Instagram']) {
        rule['PromoterInstagram'] = this.promoter['Instagram'];
      } else {
        rule['PromoterInstagram'] = ' ';
      }
      if (this.promoter['Twitter']) {
        rule['PromoterTwitter'] = this.promoter['Twitter'];
      } else {
        rule['PromoterTwitter'] = ' ';
      }

      if (this.promoter['LinkedIn']) {
        rule['PromoterLinkedIn'] = this.promoter['LinkedIn'];
      } else {
        rule['PromoterLinkedIn'] = ' ';
      }

      if (this.promoter['Pinterest']) {
        rule['PromoterPinterest'] = this.promoter['Pinterest'];
      } else {
        rule['PromoterPinterest'] = ' ';
      }
      if (this.promoter['Tumblr']) {
        rule['PromoterTumblr'] = this.promoter['Tumblr'];
      } else {
        rule['PromoterTumblr'] = ' ';
      }

      if (this.promoter['Vimeo']) {
        rule['PromoterVimeo'] = this.promoter['Vimeo'];
      } else {
        rule['PromoterVimeo'] = ' ';
      }
      if (this.promoter['YouTube']) {
        rule['PromoterYouTube'] = this.promoter['YouTube'];
      } else {
        rule['PromoterYouTube'] = ' ';
      }
      if (this.promoter['URL']) {
        rule['PromoterWebSite'] = this.promoter['URL'];
      } else {
        rule['PromoterWebSite'] = ' ';
      }

      if (this.promoter['ABN']) {
        rule['PromoterABN'] = this.promoter['ABN'];
      } else {
        rule['PromoterABN'] = ' ';
      }
      if (this.promoter['ACN']) {
        rule['PromoterACN'] = this.promoter['ACN'];
      } else {
        rule['PromoterACN'] = ' ';
      }
    }


    result = this.utils.convertMessage(message, rule);
    return result;
  }

  moreInfo() {
    NotifyAppComponent.displayToast(
      'fas fa-info',
      'Finance Instructions',
      'Please make sure you  enter the finance value.'
    );
  }

  proceedToStep3() {
    const payload = {
      isActive: true,
      orderBy: 'label',
      merchantID: this.merchantID,
      fields: 'label,_id,type'
    };
    if (!this.isModuleAdvancedTreatmentActive || this.isModuleAdvancedTreatmentActive == false) {
      this.selectedTab = 0;

      this.wizard.goToStep(1);
      this.onStepChange();
      this.moreInfo();
    } else {
      if (this.dontShowAgainSimpleType == false) {
        this.wizard.goToStep(1);
        this.onStepChange();
        this.moreInfo();
      } else {
        const ref = this.dialog.open(TreatmentChoiceComponent, { width: '700px' });
        // let tour = ref.componentInstance.startTreatmentTour.subscribe(data => {
        //   ref.close();
        //   this.startTreatmentTour();
        // });
        ref.componentInstance.dontShowAgain.subscribe((res) => {
          this.dontShowAgainSimpleType = res;
        });
        ref.componentInstance.closeModal.subscribe((res) => {
          ref.close();
        });
        const sub = ref.componentInstance.isSimple.subscribe((data) => {
          ref.close();
          if (data == false) {
            this.selectedTab = 1;

            this.wizard.goToStep(1);
            this.onStepChange();
            this.moreInfo();
          } else {
            this.selectedTab = 0;

            this.wizard.goToStep(1);
            this.onStepChange();
            this.moreInfo();
          }
        });
      }
    }
  }

  step2() {
    if (this.user && this.user.email && this.user.mobile) {
      this.simplePageService.isContactBlackList(this.user).subscribe((blackListResult) => {
        if (blackListResult && blackListResult.isBlackListed == false) {
          this.proceedToStep3();
        } else {
          let blackListedItem = 'Email address';
          if (blackListResult.isMobile == true) {
            blackListedItem = 'Mobile Number';
          }
          const data = {
            icon: 'error',
            description:
              'The ' +
              blackListedItem +
              ' you are trying to send to is currently on our Blacklist and cannot be used. <br>  <br>' +
              'How did it get on a Blacklist? This ' +
              blackListedItem +
              ' has previously been reported to a spam filter otherwise bounced or rejected previous emails we have sent.<br>  <br>' +
              ' You have two options, either request another ' +
              blackListedItem +
              ' address to use from the patient or if the patient is claiming the address is still valid you can request we move this address to a Whitelist and try again. If you Whitelist there is no guarantee the email will reach its destination. <br>  <br>' +
              'Choose one of the options below.',
            yesLabel: 'Whitelist and send again',
            yesSecondLabel: 'Enter new ' + blackListedItem + '',
            isSecondConfirmation: true,
            isNo: false,
            isInformation: false,
            title: 'Well, this is not good.'
          };
          const ref2 = RootAppComponent.dialog.open(ConfirmDialogMultipleComponent, {
            data,
            width: '800px'
          });
          ref2.componentInstance.confirmation.subscribe((confirmation) => {
            if (confirmation == true) {
              const confirmDialog1 = new ConfirmDialog(
                'fas fa-info',
                'Are you sure?',
                '<p>Add this patient to the White List</p> ',
                'No',
                'Yes, Add'
              );
              const refInner = RootAppComponent.dialog.open(ConfirmDialogComponent, {
                data: confirmDialog1
              });
              refInner.componentInstance.onConfirm.subscribe((val) => {
                if (val == true) {
                  const o = {
                    email: this.user.email,
                    mobile: this.user.mobile,
                    merchantID: this.merchantID
                  };
                  this.simplePageService.whileListContact(o, this.isPromoterOrAdmin).subscribe((res) => {
                    if (res) {
                      if (res.Result && res.Result == 'Succeed') {
                        NotifyAppComponent.displayToast(
                          'success',
                          'Successful operation',
                          'The pattient has been added to white list'
                        );
                        ref2.close();
                        ref2.afterClosed().subscribe((res) => {
                          refInner.close();
                        });
                        this.proceedToStep3();
                      } else {
                        ref2.close();
                        ref2.afterClosed().subscribe((res) => {
                          refInner.close();
                        });
                        const confirmDialog2 = new ConfirmDialogSingleButton(
                          'warning',
                          'Well, this is not good.',
                          'Sorry, we could not add the patient to the white list , please change the  ' +
                          blackListedItem +
                          ' Or contact us for more details ',
                          'Okay'
                        );
                        const ref4 = this.dialog.open(ConfirmDialogSingleButtonComponent, {
                          data: confirmDialog2,
                          width: '650px'
                        });
                        ref4.componentInstance.onConfirm.subscribe((res) => {
                          ref4.close();
                        });
                      }
                    } else {
                      ref2.close();
                      ref2.afterClosed().subscribe((res) => {
                        refInner.close();
                      });
                    }
                  });
                } else {
                  refInner.close();
                }
              });
            }
          });
          ref2.componentInstance.confirmationSecond.subscribe((information) => {
            if (information == true) {
              ref2.close();
            }
          });
        }
      });
    } else {
      this.wizard.goToStep(0);
    }
  }

  step3() {
    UtilsClass.scrollUp();

    UtilsClass.startLoadingForce();
    this.invitationService.getTreatmentPlanCost(this.invitationID).subscribe((res1) => {
      let patload;
      if (this.isPromoterOrAdmin == true) {
        patload = {
          fields: 'ID,CalculatedName,ContactForCard_key',
          merchantID: this.merchantID
        };
      } else {
        patload = {
          fields: 'ID,CalculatedName,ContactForCard_key'
        };
      }
      if (this.merchantID && this.merchantID != 'none') {
        this.merchantService.getMerchantDetails(this.merchantID, {}, this.sessionType).subscribe((m) => {
          if (m) {
            this.merchant = m;
            this.dentistService.getFiltersContact(patload, this.isPromoterOrAdmin).subscribe((res) => {
              this.authenticationService.getCurrentUser().subscribe((currentUser) => {
                this.treat_plan_cost = res1 || 0;
                this.wizard.goToStep(2);
                this.getDefaultMessageList(this.merchantID);
                this.getDiscount();
                this.onStepChange();
                this.senders = res;
                for (let i = 0; i < this.senders.length; i++) {
                  if (currentUser.data['contactKey'] == this.senders[i]['ID'] && this.selectedSender == null) {
                    const s = this.senders[i]['ID'];
                    this.contact = this.senders[i];
                    UtilsClass.savingData();
                    const sender = {
                      attribute: 'message.sender',
                      value: s
                    };
                    this.userDataService.setUserData(this.invitationID, sender, this.sessionType).subscribe((res) => {
                      UtilsClass.stopLoadingForce();
                      this.selectedSender = s;
                      this.isSelectedSender = true;
                      if (this.messageOption && this.messageOption.contentRaw) {
                        const p = {
                          value: this.messageOption.contentRaw,
                          defaultID: this.messageOption.ID
                        };
                        this.defaultMessageChange(p);
                      }
                    });
                  }
                }
                UtilsClass.stopLoadingForce();
              });
            });
          }
        });
      } else {
        UtilsClass.savingData();
        const sender = {
          attribute: 'message.viaSMS',
          value: this.sendSMS
        };
        this.userDataService.setUserData(this.invitationID, sender, this.sessionType).subscribe((res) => {
        });
        this.authenticationService.getCurrentPractice().subscribe((currentPractice) => {
          this.dentistService.getFiltersContact(patload).subscribe((res) => {
            this.authenticationService.getCurrentUser().subscribe((currentUser) => {
              this.treat_plan_cost = res1 || 0;
              this.merchant = currentPractice;
              this.getDefaultMessageList();
              this.getDiscount();
              this.wizard.goToStep(2);
              this.onStepChange();
              this.senders = res;
              for (let i = 0; i < this.senders.length; i++) {
                if (currentUser.data['contactKey'] == this.senders[i]['ID'] && this.selectedSender == null) {
                  const s = this.senders[i]['ID'];
                  this.contact = this.senders[i];
                  UtilsClass.savingData();
                  const sender = {
                    attribute: 'message.sender',
                    value: s
                  };
                  this.userDataService.setUserData(this.invitationID, sender, this.sessionType).subscribe((res) => {
                    UtilsClass.stopLoadingForce();
                    this.selectedSender = s;
                    this.isSelectedSender = true;
                  });
                }
              }
              UtilsClass.stopLoadingForce();
            });
          });
        });
      }
    });
  }

  newPatientInv() {
    this.router.navigate(['/merchant', { outlets: { page: ['new-invitation'] } }]);
  }

  senderChanged() {
    const payload = {
      attribute: 'message.sender',
      value: this.selectedSender
    };
    this.userDataService.setUserData(this.invitationID, payload, this.sessionType).subscribe((data) => {
    });
    if (this.selectedSender != null) {
      this.dentistService.getContactDetails(this.selectedSender, {}, this.sessionType).subscribe((res) => {
        if (res) {
          this.contact = res;
          if (this.messageOption && this.messageOption['value']) {
            this.dentMsg = this.convertMessage(this.messageOption['value']);
            UtilsClass.savingData();
            const message = {
              attribute: 'message.content',
              value: this.dentMsg
            };
            this.userDataService.setUserData(this.invitationID, message, this.sessionType).subscribe((res) => {
              this.isSelectedSender = true;
            });
          }
        }
      });
    }
  }

  saveDraft() {
    const confirm = new ConfirmDialog(
      'fas fa-info',
      'Save as Draft',
      'The invitation will be saved in drafts and can be retrieved later, You will be Redirected to The Dashbord, Do you to Proceed?',
      'No',
      'Yes, Proceed'
    );
    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirm
    });
    ref.componentInstance.onConfirm.subscribe((confirmation) => {
      if (confirmation === false) {
        ref.close();
      } else {
        ref.close();
        NotifyAppComponent.displayToast('success', 'Successful operation', 'Invitation is Saved');
        this.router.navigate(['/merchant']);
      }
    });
  }

  viewSavedTreatment(id) {
    const ref = this.dialog.open(InvitationTemplateMergerComponent, {
      data: {
        templateID: id,
        viewOnly: true
      },

      width: '1200px'
    });
    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  cancel() {
    this.router.navigate(['/merchant', { outlets: { page: ['home'] } }]);
  }

  setContact(s) {
    if (s) {
      this.contact = s;
    }
  }

  viewVideoInfo(videoTitle, docUrl) {
    const ref = RootAppComponent.dialog.open(DocumentUrlViewComponent, {
      data: {
        title: videoTitle,
        description: '',
        documentUrl: docUrl
      },
      width: '800px',
      panelClass: 'noCard'
    });
    const sub = ref.componentInstance.closeModal.subscribe((data) => {
      ref.close();
    });
  }

  launchAsModal(e, m) {
    // trigger component launch as modal, default to selected video
    const ref2 = RootAppComponent.dialog.open(VideoTutorialsModalComponent, {
      data: {
        isModal: true,
        mainCategory: m,
        subcategory: e,
        defaultSelectedTopic: e
      },
      width: '800px'
    });
    ref2.componentInstance.closeModal.subscribe((res) => {
      if (res == true) {
        ref2.close();
      }
    });
  }

  clearPatientDetails() {
    const confirm = new ConfirmDialog(
      'clear',
      'Clear patient details',
      'You are about to remove the patient details from  this invitation, you want to proceed ?',
      'No',
      'Yes, Proceed'
    );
    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirm
    });
    ref.componentInstance.onConfirm.subscribe((confirmation) => {
      if (confirmation === false) {
        ref.close();
      } else {
        this.invitationService.clearPatientDetails(this.invitationID, this.isPromoterOrAdmin).subscribe((res) => {
          if (res) {
            this.clearEmail.emit(true);
            this.clearMobile.emit(true);
            this.clearHomePhone.emit(true);
            this.clearWorkPhone.emit(true);
            this.user = {
              firstname: '',
              title: '',
              lastname: '',
              middlename: '',
              gender: '',
              mobile: '',
              home_phone: '',
              work_phone: '',
              email: '',
              isReferred: false,
              dateOfBirth: null
            };
            this.selectedExistingPatientID = null;
            this.selectedPatientID = null;
            this.isFinDMatch = false;
            this.clearDate.emit(true);
            ref.close();
          }
        });
      }
    });
  }

  openAuthCodeDialog(openInNewTable = false) {
    UtilsClass.startLoadingForce();
    this.invitationService
      .getUserData(this.invitationID, 'EPinvitationID,patient.prospectID', this.sessionType)
      .subscribe((res) => {
        UtilsClass.stopLoadingForce();
        if (res && res['EPinvitationID']) {
          const ref = RootAppComponent.dialog.open(MerchantAuthCodeModalComponent, {
            data: {
              merchantID: this.merchantID,
              openInNewTable,
              type: 'invitation',
              invitationID: res['EPinvitationID'],
              patientID: res['patient.prospectID']
            },
            width: '850px'
          });
          const sub = ref.componentInstance.close.subscribe((data) => {
            ref.close();
          });
        }
      });
  }

  sendResult() {
    if (this.isIntegrated == true) {
      if (this.transactionWebhookURL && this.EPInvitationID) {
        this.invitationService
          .triggerWebhook(this.EPInvitationID, 'Close', this.transactionToken, this.transactionWebhookURL)
          .subscribe((res) => {
            this.authenticationService.logout().subscribe((res) => {
            });
          });
      } else if (this.redirectURL && this.EPInvitationID) {
        this.invitationService
          .getInvitationDetails(this.EPInvitationID, { fields: 'ID,Prospect_key' }, this.sessionType, false)
          .subscribe((res) => {
            if (res) {
              let prospectID;
              if (res['Prospect_key']) {
                prospectID = res['Prospect_key'];
              }

              this.authenticationService.logout().subscribe((res) => {
                let link = this.redirectURL + '?redirect_param=' + this.redirectParam;
                if (prospectID) {
                  link = link + '&patient_id=' + this.EPInvitationID;
                }

                if (this.EPInvitationID) {
                  link = link + '&invitation_id=' + this.EPInvitationID;
                }
                window.location.href = link;
              });
            }
          });
      } else if (this.webhookUrl && this.EPInvitationID) {
        this.invitationService.triggerWebhook(this.EPInvitationID, 'Close', this.transactionToken).subscribe((res) => {
          this.authenticationService.logout().subscribe((res) => {
          });
        });
      } else {
        this.authenticationService.logout().subscribe((res) => {
          if (this.redirectURL) {
            if (this.EPInvitationID) {
              window.location.href =
                this.redirectURL + '?invitation_id=' + this.EPInvitationID + '&redirect_param=' + this.redirectParam;
            } else {
              window.location.href = this.redirectURL + '&redirect_param=' + this.redirectParam;
            }
          } else if (this.EPInvitationID) {
            this.getInvitationID.emit(this.EPInvitationID);
          }
        });
      }
    }
  }
}
