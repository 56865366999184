import { Component, Input, OnInit } from '@angular/core';
import { switchMap } from 'rxjs/operators';
import { AppStateService } from '../../../../../../../core/app-state/app-state.service';
import { CustomerProspectService } from '../../../../../../customer-prospect/shared/customerProspect.service';
import { CustomerAndProspect } from '../../../../../../invitation/shared/types/invitation.type';
import {
  ProductCatalogueItem,
  ProductCatalogueService,
} from '../../../../../shared/services/product-catalogue.service';
import { ProductCustomer } from '../../../../../shared/services/product-customer.service';

@Component({
  selector: 'ipv-retail-view-customer-product',
  templateUrl: './retail-view-customer-product.component.html',
  styleUrls: ['./retail-view-customer-product.component.css'],
})
export class RetailViewCustomerProductComponent implements OnInit {
  @Input() productCustomer: ProductCustomer;

  product: ProductCatalogueItem = null;
  customer: CustomerAndProspect = null;
  existingThumbnailSrc: string = null;
  additionalImages: string[] = [];

  constructor(
    private productCatalogueService: ProductCatalogueService,
    private customerProspectService: CustomerProspectService,
    private appStateService: AppStateService
  ) {}

  ngOnInit() {
    // this.productCatalogueService.fetchProductCatalogueItem$<ProductCatalogueItem>({id: this.productCustomer.Master_Appliance_Profile_key}).subscribe((product) => {
    //   this.product = product;
    //
    //   if (this.product) {
    //     if (this.product.AdditionalImages_Doc_key) {
    //       this.additionalImages = this.product.AdditionalImages_Doc_key.length > 0 ? this.product.AdditionalImages_Doc_key.split('|') : [];
    //
    //       this.additionalImageIDs.forEach((imageID) => {
    //         this.additionalImages = [...this.additionalImages, `${environment.nodeUrl}/files/file-view/${imageID}`]
    //       });
    //     }
    //   }
    // });

    this.appStateService
      .getAppState$()
      .pipe(
        switchMap((appState) => {
          return this.customerProspectService.fetchCustomerAndProspect$(
            this.productCustomer.Owner_Card_key,
            {},
            appState.sessionType
          );
        })
      )
      .subscribe((customer) => {
        this.customer = customer;
      });
  }
}
