<div class="action-button">
  <ng-container *ngIf="appointment.ID !== 'mock'; else closeBtn">
    <button class="white" class="actionMenuToggle" [matMenuTriggerFor]="btnPatMenu" mat-mini-fab color="accent">
      <mat-icon class="white">more_vert</mat-icon>
    </button>

    <mat-menu #btnPatMenu="matMenu">
      <button (click)="openQuickViewDialog()" mat-menu-item>
        <mat-icon>description</mat-icon>
        <span>Quick View</span>
      </button>

      <button
        *ngFor="let a of appointmentUtil.getOperations(appointment, sessionType)"
        (click)="runActionEvent(appointment, a.code, false)"
        mat-menu-item
      >
        <mat-icon>{{ a.icon }}</mat-icon>
        <span>{{ a.label }}</span>
      </button>
    </mat-menu>
  </ng-container>
  <ng-template #closeBtn>
    <button class="white" class="actionMenuToggle" (click)="cancelMockAppointment.emit()" mat-mini-fab color="accent">
      <mat-icon class="white">close</mat-icon>
    </button>
  </ng-template>
</div>

<div class="infoLabel float-left">
  <mat-icon>info</mat-icon>
  <p class="small">
    {{ appointment['Status.Label'] }}
  </p>
</div>

<div class="infoLabel">
  <mat-icon>schedule</mat-icon>
  <p class="small">{{ appointmentDuration }} min</p>
</div>

<div class="infoLabel">
  <mat-icon>date_range</mat-icon>
  <p class="small">
    {{ appointmentStart | customDate: 'h:mma':false }} -
    {{ appointmentEnd | customDate: 'h:mma':false }}
  </p>
</div>
<div class="infoLabel">
  <mat-icon>person</mat-icon>
  <p class="small">
    {{ appointment['CustomerOrProspect_CalculatedName'] }}
  </p>
</div>

<div class="infoLabel">
  <mat-icon>favorite</mat-icon>
  <p class="small">
    {{ appointment['Type.Label'] || '-' }}
    <span *ngIf="appointment['SubType.Label']">- {{ appointment['SubType.Label'] }}</span>
  </p>
</div>

<div class="infoLabel" *ngIf="totalBuffer">
  <mat-icon>hourglass_full</mat-icon>
  <p class="small">
    {{ totalBuffer }} min buffer:
    {{ appointmentBufferBefore ? appointmentBufferBefore + ' min before' : '' }}
    {{ appointmentBufferAfter && appointmentBufferAfter ? ', ' : '' }}
    {{ appointmentBufferAfter ? appointmentBufferAfter + ' min after' : '' }}
    ({{ appointmentDuration + totalBuffer }}
    min)
  </p>
</div>
<!--
<div *ngIf="appointment.ID === 'mock'" class="drop-buttons">
  <button
    mat-raised-button
    class="ml"
    color="accent"
    (click)="cancelMockAppointment.emit()"
  >
    <span>Cancel</span>
  </button>
</div> -->

<app-appointment-action-hidden [runAction]="runAction" (result)="getActionResult($event)">
</app-appointment-action-hidden>
