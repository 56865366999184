import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatSortModule } from '@angular/material/sort';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgPipesModule } from 'ngx-pipes';
import { LogModule } from '../../core/log/log.module';
import { SessionModule } from '../../core/session/session.module';
import { SharedModule } from '../../shared/shared.module';
import { OperatorBeneficiaryViewPictureComponent } from './operator-beneficiary-view-picture/operator-beneficiary-view-picture.component';
import { OperatorContactComponent } from './operator-contact/operator-contact.component';
import { OperatorFilterToggleComponent } from './operator-filter-toggle/operator-filter-toggle.component';
import { OperatorFilterComponent } from './operator-filter/operator-filter.component';
import { OperatorListOverviewComponent } from './operator-list-overview/operator-list-overview.component';
import { OperatorListComponent } from './operator-list/operator-list.component';
import { OperatorLookupComponent } from './operator-lookup/operator-lookup.component';
import { OperatorMerchantViewPictureComponent } from './operator-merchant-view-picture/operator-merchant-view-picture.component';
import { OperatorSessionOverviewComponent } from './operator-session-overview/operator-session-overview.component';
import { OperatorRoutingModule } from './shared/operator-routing.module';
import { PasswordPromptComponent } from './password-prompt/password-prompt.component';
import { OperatorLoginAccessComponent } from './operator-login-access/operator-login-access.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    OperatorRoutingModule,
    NgPipesModule,
    CdkTableModule,
    MatSortModule,
    SessionModule,
    LogModule,
    NgxPaginationModule,
  ],
  declarations: [
    OperatorFilterComponent,
    OperatorFilterToggleComponent,
    OperatorContactComponent,
    OperatorListComponent,
    OperatorLookupComponent,
    OperatorSessionOverviewComponent,
    OperatorListOverviewComponent,
    OperatorMerchantViewPictureComponent,
    OperatorBeneficiaryViewPictureComponent,
    PasswordPromptComponent,
    OperatorLoginAccessComponent,
  ],
  exports: [
    OperatorFilterComponent,
    OperatorFilterToggleComponent,
    OperatorContactComponent,
    OperatorListComponent,
    OperatorLookupComponent,
    OperatorListOverviewComponent,
    OperatorMerchantViewPictureComponent,
    OperatorBeneficiaryViewPictureComponent,
    PasswordPromptComponent,
    OperatorLoginAccessComponent,
  ],
  providers: [],
})
export class OperatorModule {}
