import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { DataSource } from '@angular/cdk/table';
import { Component, ElementRef, EventEmitter, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { BehaviorSubject, merge as observableMerge, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { CustomDatePipe } from '../../../shared/pipes/custom-date.pipe';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { MarketingService } from '../../marketing/shared/marketing.service';

@Component({
  selector: 'app-marketing-filter-list',
  templateUrl: './marketing-filter-list.component.html',
  styleUrls: ['./marketing-filter-list.component.css'],
  providers: [CustomDatePipe],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('*', style({ opacity: 0 }), { optional: true }),
        query(
          '*',
          stagger('10ms', [
            animate(
              '0.2s ease-in',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-10%)', offset: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('*', style({ opacity: 1 }), { optional: true }),
        query(
          '*',
          stagger('10ms', [
            animate(
              '0.2s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-10%)', offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class MarketingFilterListComponent implements OnInit {
  @Input()
  dateFrom;

  @Input()
  dateTo;

  @Input()
  pageSize = Settings.global['listPageSize'] || 20;
  pageSizeOptions = [10, Number(this.pageSize), Number(this.pageSize) * 2, Number(this.pageSize) * 3];

  utils = new UtilsClass();

  displayedColumns = ['selectCheckBox', 'Label', 'Description', 'Date', 'IsActive', 'Actions'];

  isPromoterOrAdmin = false;

  selectedIDs = [];
  filters = [];

  searchVals = new FormControl();

  searchValList = ['Merchant', 'Name', 'CreatedDate', 'ScheduledSend', 'Target', 'Template', 'Status'];

  public listDB: LoadRecords | null;
  dataSource: RecordDataSource | null;

  filteredSize = null;

  sessionType;

  destroyEvent = new EventEmitter();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('filter', { static: false }) filter: ElementRef;

  constructor(
    private marketingService: MarketingService,
    private authenticationService: AuthenticationService,
    private customDate: CustomDatePipe,
    private router: Router
  ) {
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;
      this.authenticationService.getSessionType().subscribe((st) => {
        this.sessionType = st;
      });
    });
  }

  ngOnInit() {
    this.listDB = new LoadRecords(
      this.marketingService,
      this.destroyEvent,
      this.isPromoterOrAdmin,
      this.dateFrom,
      this.dateTo
    );
    this.dataSource = new RecordDataSource(this.listDB, this.paginator, this.sort);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.listDB = new LoadRecords(
      this.marketingService,
      this.destroyEvent,
      this.isPromoterOrAdmin,
      this.dateFrom,
      this.dateTo
    );
    this.dataSource = new RecordDataSource(this.listDB, this.paginator, this.sort);
  }

  setFilter(event, field) {
    let filter;
    if (typeof event == 'object' && event != null) {
      const v = this.customDate.transform(event, Settings.global['dateFormat']);
      filter = {
        field,
        value: v,
      };
    } else if (event) {
      filter = {
        field,
        value: event,
      };
    } else {
      filter = {
        field,
        value: '',
      };
    }

    this.dataSource.filter = filter;
    this.filteredSize = this.dataSource.filteredData.length;
  }

  openFilterView(ID) {
    // let ref = RootAppComponent.dialog.open(MarketingFilterViewComponent, {
    //   data: ID,
    //   width: '650px'
    // });

    // ref.componentInstance.close.subscribe(res => {
    //   ref.close();
    // })

    this.router.navigate(['/merchant', { outlets: { page: ['marketing-filter-view', ID] } }]);
  }

  enableFilter(ID) {
    const confirmDialog = new ConfirmDialog(
      'check_circle',
      'Enable filter',
      '<p>Are you sure you want to proceed ? </p>',
      'Cancel',
      'Proceed'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
      width: '650px',
    });

    ref.componentInstance.onConfirm.subscribe((res) => {
      if (res == true) {
        ref.close();
        ref.afterClosed().subscribe((res) => {
          const payload = {
            active: true,
          };

          this.marketingService.activeFilter(ID, payload).subscribe((res) => {
            if (res) {
              this.dataSource.replaceItem = res;

              NotifyAppComponent.displayToast('success', 'Success!', 'Filter is enabled.');
            }
          });
        });
      } else {
        ref.close();
      }
    });
  }

  selectPage() {
    const index = this.dataSource['_paginator']['index'] || 0;
    const pageSize = this.dataSource['_paginator']['pageSize'] || 0;

    for (let i = 0; i < (index + 1) * pageSize; i++) {
      const o = this.dataSource.filteredData[index * pageSize + i];

      if (o && o['ID'] && this.selectedIDs.indexOf(o['ID']) == -1) {
        this.selectedIDs.push(o['ID']);
      }
    }
  }

  select(id, isSelected) {
    if (isSelected == true && this.selectedIDs.indexOf(id) == -1) {
      this.selectedIDs.push(id);
    } else {
      const _index = this.selectedIDs.indexOf(id);
      this.selectedIDs.splice(_index, 1);
    }
  }

  unselect() {
    this.selectedIDs = [];
  }

  isSelected(id) {
    if (!this.selectedIDs || this.selectedIDs.length <= 0) {
      return false;
    } else {
      if (this.selectedIDs.indexOf(id) != -1) {
        return true;
      }
    }

    return false;
  }

  selectAll() {
    for (let i = 0; i < this.dataSource.filteredData.length; i++) {
      const o = this.dataSource.filteredData[i];

      if (o && o['ID'] && this.selectedIDs.indexOf(o['ID']) == -1) {
        this.selectedIDs.push(o['ID']);
      }
    }
  }

  enableGroup() {
    if (this.selectedIDs && this.selectedIDs.length > 0) {
      const confirmDialog = new ConfirmDialog(
        'cancel',
        'Enable Selected Filters',
        '<p>Once the Filter is enabled it will not appear as an option for the user.</p>',
        'Cancel',
        'Okay'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirmDialog,
        // SET TO PAYING CASH CARD
      });
      ref.componentInstance.onConfirm.subscribe((val) => {
        if (val == true) {
          ref.close();
          ref.afterClosed().subscribe((res) => {
            const payload = {
              isActive: true,
              ids: this.selectedIDs.join(','),
            };
            this.marketingService.editFilterGroup(payload, this.sessionType).subscribe((res) => {
              if (res && res.length > 0) {
                for (let i = 0; i < res.length; i++) {
                  this.dataSource.replaceItem = res[i];
                }

                this.selectedIDs = [];

                NotifyAppComponent.displayToast('success', 'Success!', 'Filters are enabled.');
              }
            });
          });
        } else {
          ref.close();
        }
      });
    }
  }

  disableGroup() {
    if (this.selectedIDs && this.selectedIDs.length > 0) {
      const confirmDialog = new ConfirmDialog(
        'cancel',
        'Disable Selected Filters',
        '<p>Once the Filter is disabled it will not appear as an option for the user.</p>',
        'Cancel',
        'Okay'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirmDialog,
        // SET TO PAYING CASH CARD
      });
      ref.componentInstance.onConfirm.subscribe((val) => {
        if (val == true) {
          ref.close();
          ref.afterClosed().subscribe((res) => {
            const payload = {
              isActive: false,
              ids: this.selectedIDs.join(','),
            };
            this.marketingService.editFilterGroup(payload, this.sessionType).subscribe((res) => {
              if (res && res.length > 0) {
                for (let i = 0; i < res.length; i++) {
                  this.dataSource.replaceItem = res[i];
                }

                this.selectedIDs = [];

                NotifyAppComponent.displayToast('success', 'Success!', 'Filters are disabled.');
              }
            });
          });
        } else {
          ref.close();
        }
      });
    }
  }

  removeFilter(ID) {
    const confirmDialog = new ConfirmDialog(
      'delete',
      'Removing filter',
      '<p>Are you sure you want to remove this filter? </p>',
      'Cancel',
      'Remove'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
      width: '650px',
    });

    ref.componentInstance.onConfirm.subscribe((res) => {
      if (res == true) {
        ref.close();
        ref.afterClosed().subscribe((res) => {
          this.marketingService.removeFilter(ID, this.isPromoterOrAdmin).subscribe((res) => {
            if (res) {
              this.dataSource.removeItem = res;

              NotifyAppComponent.displayToast('success', 'Success!', 'Filter is disabled.');
            }
          });
        });
      } else {
        ref.close();
      }
    });
  }

  disableFilter(ID) {
    const confirmDialog = new ConfirmDialog(
      'cancel',
      'Disable filter',
      '<p>Are you sure you want to proceed ? </p>',
      'Cancel',
      'Proceed'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
      width: '650px',
    });

    ref.componentInstance.onConfirm.subscribe((res) => {
      if (res == true) {
        ref.close();
        ref.afterClosed().subscribe((res) => {
          const payload = {
            active: false,
          };

          this.marketingService.activeFilter(ID, payload).subscribe((res) => {
            if (res) {
              this.dataSource.replaceItem = res;

              NotifyAppComponent.displayToast('success', 'Success!', 'Filter is disabled.');
            }
          });
        });
      } else {
        ref.close();
      }
    });
  }

  editFilter(ID) {
    this.router.navigate(['/merchant', { outlets: { page: ['edit-filter', ID] } }]);
  }

  newFilter() {
    this.router.navigate(['/merchant', { outlets: { page: ['new-filter'] } }]);
  }

  ngOnDestroy() {
    this.destroyEvent.emit(true);
  }
}

export class LoadRecords implements OnInit {
  public dataChange: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  items: any;
  count: any;

  get data(): any[] {
    return this.dataChange.value;
  }

  firstLoad = false;

  get firstLoadEvent() {
    return this.firstLoad;
  }

  serviceRef;
  isDestoyed = false;

  util = new UtilsClass();

  constructor(
    private marketingService: MarketingService,
    private destroyEvent,
    private isPromoterOrAdmin,
    private dateFrom,
    private dateTo
  ) {
    this.destroyEvent.subscribe((res) => {
      this.isDestoyed = res;
    });

    const payload = {
      section: 0,
      dateFrom: this.util.EPdateFormat(this.dateFrom),
      dateTo: this.util.EPdateFormat(this.dateTo),
    };

    this.serviceRef = this.marketingService.getFilterList(payload, this.isPromoterOrAdmin).subscribe((res) => {
      this.count = res.length;
      this.items = res;

      this.dataChange.next(this.items.slice());
      this.firstLoad = true;

      const innerFunction = (section) => {
        section = section + 1;
        UtilsClass.loadingDataSection(section);
        payload.section = section;

        this.serviceRef = this.marketingService.getFilterList(payload, this.isPromoterOrAdmin).subscribe((res) => {
          if (res.length > 0) {
            this.count = res.count;
            this.items = this.items.concat(res);
            this.dataChange.next(this.items.slice());
            if (this.isDestoyed != true) {
              innerFunction(section);
            }
          } else {
            return true;
          }
        });
        UtilsClass.stopLoadingInterceptor();
      };

      if (this.isDestoyed != true) {
        innerFunction(1);
      }
    });
  }

  ngOnInit() {}

  ngOnDestroy() {
    if (this.serviceRef) {
      this.serviceRef.unsubscribe();
    }
  }
}

export class RecordDataSource extends DataSource<any> {
  _filterChange = new BehaviorSubject('');
  field = '';

  get filter(): any {
    return this._filterChange.value;
  }

  set filter(item: any) {
    this.field = item.field;
    this._filterChange.next(item.value);
  }

  set addItem(res) {
    this._custDatabase.data.unshift(res);

    this._custDatabase.dataChange.next(this._custDatabase.data);
  }

  set removeItem(id) {
    const data = this._custDatabase.data.filter((row) => row.ID != id);
    this._custDatabase.dataChange.next(data);
  }

  set replaceItem(item) {
    const _index = this._custDatabase.data.findIndex((_obj) => _obj.ID == item.ID);
    this._custDatabase.data[_index] = item;
    this._custDatabase.dataChange.next(this._custDatabase.data);
  }

  filteredData: any[] = [];

  constructor(private _custDatabase: LoadRecords, private _paginator: MatPaginator, private _sort: MatSort) {
    super();
    this._filterChange.subscribe(() => (this._paginator.pageIndex = 0));
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<any[]> {
    const displayDataChanges = [
      this._custDatabase.dataChange,
      this._filterChange,
      this._paginator.page,
      this._sort.sortChange,
    ];

    return observableMerge(...displayDataChanges).pipe(
      map(() => {
        // Filter data
        this.filteredData = this._custDatabase.data.slice().filter((item: any) => {
          let f = '';

          f = item[this.field] || '';

          const searchStr = f.toLowerCase();
          return searchStr.indexOf(this.filter.toLowerCase()) != -1;
        });
        const data = this.getSortedData(this.filteredData.slice());
        // Grab the page's slice of data.
        const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
        return data.splice(startIndex, this._paginator.pageSize);
      })
    );
  }

  disconnect() {}

  /** Returns a sorted copy of the database data. */
  getSortedData(data: any[]): any[] {
    // const data = data;
    if (!this._sort.active || this._sort.direction == '') {
      return data;
    }

    return data.sort((a, b) => {
      let propertyA: number | string = '';
      let propertyB: number | string = '';

      switch (this._sort.active) {
        case 'Date':
          [propertyA, propertyB] = [
            moment(a['DateTimeCreated']).toDate().getTime(),
            moment(b['DateTimeCreated']).toDate().getTime(),
          ];
          break;
        case 'Label':
          [propertyA, propertyB] = [a['Label'], b['Label']];
          break;
        case 'Description':
          [propertyA, propertyB] = [a['Description'], b['Description']];
          break;
        case 'IsActive':
          [propertyA, propertyB] = [a['Active'], b['Active']];
          break;
      }

      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

      return (valueA < valueB ? -1 : 1) * (this._sort.direction == 'asc' ? 1 : -1);
    });
  }
}
