import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';

import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { BeneficiaryViewComponent } from '../../beneficiary/beneficiary-view/beneficiary-view.component';
import { TreatmentService } from '../shared/treatment.service';
import { treatmentUtil } from '../shared/treatmentUtil';
import { TreatmentBrandViewComponent } from '../treatment-brand-view/treatment-brand-view.component';
import { TreatmentCategoryViewComponent } from '../treatment-category-view/treatment-category-view.component';
import { TreatmentTypeViewComponent } from '../treatment-type-view/treatment-type-view.component';

@Component({
  selector: 'app-product-view',
  templateUrl: './treatment-product-view.component.html',
  styleUrls: ['./treatment-product-view.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.stacked-card-view', style({ opacity: 0 }), { optional: true }),
        query(
          '.stacked-card-view',
          stagger('100ms', [
            animate(
              '0.3s ease-out',
              keyframes([
                style({ opacity: 0, offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.stacked-card-view', style({ opacity: 1 }), { optional: true }),
        query(
          '.stacked-card-view',
          stagger('100ms', [
            animate(
              '0.3s ease-in',
              keyframes([
                style({ opacity: 1, offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class TreatmentProductViewComponent implements OnInit {
  @Input()
  productID;

  @Input()
  isGlobal = false;

  @Input()
  merchantID;

  @Output()
  close = new EventEmitter();

  @Output()
  saveObject = new EventEmitter();

  isModal = false;

  treatment;
  isActive = false;

  isEdit = false;

  treatmentUtil = new treatmentUtil();

  isPromoterOrAdmin = false;

  sellPrice = null;
  sellTotalPrice = null;
  sellPercentPrice = null;
  costPrice = null;
  costTotalPrice = null;
  costPercentPrice = null;
  profitPrice = null;
  costOverheadPrice = null;
  costOverheadPercent = null;

  preDocuments = [];
  postDocuments = [];
  marketingDocuments = [];
  supplierDocuments = [];
  stockArtDocuments = [];
  groupFilter = 'patient';

  isDownload = false;
  isRedirection = false;
  isPrint = false;

  constructor(
    private authenticationServer: AuthenticationService,
    private TreatmentService: TreatmentService,
    private activeRoute: ActivatedRoute,
    private dialog: MatDialog,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.productID) {
        this.productID = data.productID;
      }

      if (data.merchantID) {
        this.merchantID = data.merchantID;
      }

      if (data.isGlobal != null) {
        this.isGlobal = data.isGlobal;
      }

      this.isModal = true;
    }
  }

  ngOnInit() {
    this.authenticationServer.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;

      if (this.isPromoterOrAdmin == true) {
        this.isDownload = true;
        this.isRedirection = true;
        this.isPrint = true;
      }

      this.activeRoute.params.subscribe((params) => {
        if (params['merchantID']) {
          this.merchantID = params['merchantID'];
        }

        if (params['productID']) {
          this.productID = params['productID'];
        }

        if (this.isPromoterOrAdmin == false) {
          this.isGlobal = false;
        }

        this.setup();
      });
    });
  }

  viewSupplier(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(BeneficiaryViewComponent, {
        data: id,
        width: '600px',
        panelClass: 'noCard',
      });

      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    }
  }

  viewType(id) {
    const ref = RootAppComponent.dialog.open(TreatmentTypeViewComponent, {
      data: {
        typeID: id,
        isGlobal: true,
      },

      width: '700px',
      panelClass: 'noCard',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  viewCategory(id) {
    const ref = RootAppComponent.dialog.open(TreatmentCategoryViewComponent, {
      data: {
        categoryID: id,
      },

      width: '700px',
      panelClass: 'noCard',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  viewBrand(id) {
    const ref = RootAppComponent.dialog.open(TreatmentBrandViewComponent, {
      data: {
        brandID: id,
        isGlobal: this.isGlobal,
        merchantID: this.merchantID,
      },

      width: '700px',
      panelClass: 'noCard',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  fillData() {
    if (this.treatment) {
      if (this.treatment['Active'] == '1') {
        this.isActive = true;
      }

      if (this.treatment['Active'] == '0') {
        this.isActive = false;
      }

      if (this.isGlobal == true) {
        if (this.treatment['DefaultPrice.Sell']) {
          this.sellPrice = Number(this.treatment['DefaultPrice.Sell']);
        } else {
          this.sellPrice = 0;
        }

        if (this.treatment['DefaultPrice.Cost.Item']) {
          this.costPrice = Number(this.treatment['DefaultPrice.Cost.Item']);
        } else {
          this.costPrice = 0;
        }

        if (this.treatment['DefaultPrice.Cost.Total']) {
          this.costTotalPrice = Number(this.treatment['DefaultPrice.Cost.Total']);
        } else {
          this.costTotalPrice = 0;
        }

        if (this.treatment['DefaultPrice.Profit']) {
          this.profitPrice = Number(this.treatment['DefaultPrice.Profit']);
        } else {
          this.profitPrice = 0;
        }

        if (this.treatment['DefaultPrice.Cost.Overheads']) {
          this.costOverheadPrice = Number(this.treatment['DefaultPrice.Cost.Overheads']);
        } else {
          this.costOverheadPrice = 0;
        }

        if (this.sellPrice > 0) {
          this.costOverheadPercent = Number((Number(this.costOverheadPrice) / Number(this.sellPrice)) * 100).toFixed(0);
        } else {
          this.costOverheadPercent = 0;
        }
      } else {
        if (this.treatment['Price.Sell.Effective']) {
          this.sellPrice = Number(this.treatment['Price.Sell.Effective']);
        } else {
          this.sellPrice = 0;
        }

        if (this.treatment['Price.Cost.Item.Effective']) {
          this.costPrice = Number(this.treatment['Price.Cost.Item.Effective']);
        } else {
          this.costPrice = 0;
        }

        if (this.treatment['Price.Cost.Total.Effective']) {
          this.costTotalPrice = Number(this.treatment['Price.Cost.Total.Effective']);
        } else {
          this.costTotalPrice = 0;
        }

        if (this.treatment['Price.Profit.Effective']) {
          this.profitPrice = Number(this.treatment['Price.Profit.Effective']);
        } else {
          this.profitPrice = 0;
        }

        if (this.treatment['Price.Cost.Overheads.Effective']) {
          this.costOverheadPrice = Number(this.treatment['Price.Cost.Overheads.Effective']);
        } else {
          this.costOverheadPrice = 0;
        }
        if (this.sellPrice > 0) {
          this.costOverheadPercent = Number((Number(this.costOverheadPrice) / Number(this.sellPrice)) * 100).toFixed(0);
        } else {
          this.costOverheadPercent = 0;
        }
      }
    }
  }

  isEditMode() {
    this.isEdit = true;
  }

  setup() {
    const p = {
      merchantID: this.merchantID,
    };

    if (this.isGlobal == true && this.isPromoterOrAdmin == true) {
      p['fields'] =
        'ID,Label,DateTimeCreated,Active,Category.Colour,Description,DefaultNotes.Content.Treatment.Effective,' +
        'DefaultNotes.Content.Diagnostic.Effective,Item_Code.Effective,Category.Label,Category_key,Type.Label,Type_key,Brand.Label,Brand_key,' +
        'Manufacturer_Key.Effective,Distributor_Key.Effective,Manufacturer.Name.Effective,Distributor.Name.Effective,' +
        'DefaultNotes.Label.Effective,DefaultNotes.Category.Effective,DefaultPrice.Cost.Item,DefaultPrice.Cost.Total,' +
        'DefaultPrice.Profit,DefaultPrice.Cost.Overheads';

      this.TreatmentService.getTreatmentProductDetailsGlobal(this.productID, p).subscribe((res) => {
        if (res) {
          this.treatment = res;

          this.fillData();

          this.setupDocument();
        }
      });
    } else {
      p['fields'] =
        'ID,Label,DateTimeCreated,Active,IsOmitted,Category.Colour,Description,DefaultNotes.Content.Treatment.Effective,' +
        'DefaultNotes.Content.Diagnostic.Effective,Item_Code.Effective,Category.Label,Category_key,Type.Label,Type_key,Brand.Label,Brand_key,' +
        'Manufacturer_Key.Effective,Distributor_Key.Effective,Manufacturer.Name.Effective,Distributor.Name.Effective,' +
        'DefaultNotes.Label.Effective,DefaultNotes.Category.Effective,' +
        'Price.Sell.Effective,Price.Cost.Item.Effective,Price.Cost.Total.Effective,Price.Profit.Effective,' +
        'Price.Cost.Overheads.Effective';
      this.TreatmentService.getTreatmentProductDetails(this.productID, p, this.isPromoterOrAdmin).subscribe((res) => {
        if (res) {
          this.treatment = res;

          this.fillData();
          this.setupDocument();
        }
      });
    }
  }

  closeModal() {
    this.close.emit(true);
  }

  toNumber(value) {
    return Number(value);
  }

  setupDocument() {
    if (this.treatment) {
      const payload = {
        section: 1,
        includeOmmitted: true,

        orderBy: '-DateTimeCreated',
        fields: 'Document_key,Document.Type.Code',
        documentTypes: 'SRVPRE,SRVPST,SRVMKT,SRVSPL,SRVSTC',
        productID: this.productID,
        includeInherited: true,
        merchantID: this.merchantID,
      };

      this.TreatmentService.getTreatmentDocumentList(payload, this.isPromoterOrAdmin).subscribe((_res) => {
        const res = _res['data'];
        if (res && res.length > 0) {
          const _preDoc = [];
          const _postDoc = [];
          const _marketingDoc = [];
          const _supplierDoc = [];
          const _stockArtDoc = [];

          for (let i = 0; i < res.length; i++) {
            if (
              res[i] &&
              res[i] &&
              res[i]['Document_key'] &&
              res[i]['Document.Type.Code'] &&
              res[i]['Document.Type.Code'] == 'SRVPST'
            ) {
              _postDoc.push(res[i]['Document_key']);
            } else if (
              res[i] &&
              res[i]['Document_key'] &&
              res[i]['Document.Type.Code'] &&
              res[i]['Document.Type.Code'] == 'SRVPRE'
            ) {
              _preDoc.push(res[i]['Document_key']);
            } else if (
              res[i] &&
              res[i]['Document_key'] &&
              res[i]['Document.Type.Code'] &&
              res[i]['Document.Type.Code'] == 'SRVMKT'
            ) {
              _marketingDoc.push(res[i]['Document_key']);
            } else if (
              res[i] &&
              res[i]['Document_key'] &&
              res[i]['Document.Type.Code'] &&
              res[i]['Document.Type.Code'] == 'SRVSPL'
            ) {
              _supplierDoc.push(res[i]['Document_key']);
            } else if (
              res[i] &&
              res[i]['Document_key'] &&
              res[i]['Document.Type.Code'] &&
              res[i]['Document.Type.Code'] == 'SRVSTC'
            ) {
              _stockArtDoc.push(res[i]['Document_key']);
            }
          }

          if (_preDoc && _preDoc.length > 0) {
            this.preDocuments = _preDoc;
          } else {
            this.preDocuments = [];
          }

          if (_postDoc && _postDoc.length > 0) {
            this.postDocuments = _postDoc;
          } else {
            this.postDocuments = [];
          }

          if (_marketingDoc && _marketingDoc.length > 0) {
            this.marketingDocuments = _marketingDoc;
          } else {
            this.marketingDocuments = [];
          }

          if (_stockArtDoc && _stockArtDoc.length > 0) {
            this.stockArtDocuments = _stockArtDoc;
          } else {
            this.stockArtDocuments = [];
          }

          if (_supplierDoc && _supplierDoc.length > 0) {
            this.supplierDocuments = _supplierDoc;
          } else {
            this.supplierDocuments = [];
          }
        }
      });
    }
  }
}
