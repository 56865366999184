import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { LookupService } from '../../../shared/services/lookup.service';
import { UtilsService } from '../../../shared/services/utils.service';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { MedicalHistoryService } from '../shared/medical-history.service';

@Component({
  selector: 'app-allergy-create-edit',
  templateUrl: './allergy-create-edit.component.html',
  styleUrls: ['./allergy-create-edit.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.animate', style({ opacity: 0 }), { optional: true }),
        query(
          '.animate',
          stagger('200ms', [
            animate(
              '0.4s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-20%)', offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.animate', style({ opacity: 1 }), { optional: true }),
        query(
          '.animate',
          stagger('200ms', [
            animate(
              '0.4s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-20%)', offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class AllergyCreateEditComponent implements OnInit {
  // @Input()
  // medicalHistoryID = "262DC7B73DEEFD45A7A03CE37DF2D9D1";

  // @Input()
  // allergyID = "39D670C881906841B9612B24BA5A22AB";

  @Input()
  medicalHistoryID;

  @Input()
  allergyID;
  @Output()
  closeEvent = new EventEmitter();
  @Output()
  getCreated = new EventEmitter();

  @Output()
  getEdited = new EventEmitter();

  categories = [];
  allergies = [];

  allergyObject = {
    medicalHistoryID: null,
    categoryCode: null,
    typeCode: null,
    description: null,
    firstOccurDate: null,
    isOngoing: null,
    ceaseDate: null,
    medicationTaken: null,
    medicationFrequencyCode: null,
    medications: [],
  };

  isModal = false;
  isEdit = false;

  config = {
    format: 'MMMM, YYYY',
    monthFormat: 'MMMM, YYYY',
  };

  frequencies;
  isModuleMedicationActive = Settings.global['isModuleMedicationActive'];
  sessionType = 'merchant';

  medicationExist = false;
  medications = [];
  _medications = [];
  startDate;
  isLoaded = false;
  stopDate;

  constructor(
    private medicalHistoryService: MedicalHistoryService,
    private lookupService: LookupService,
    private utilService: UtilsService,
    private authenticationService: AuthenticationService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.medicalHistoryID) {
        this.medicalHistoryID = data.medicalHistoryID;
      }

      if (data.allergyID) {
        this.allergyID = data.allergyID;
      }

      this.isModal = true;
    }
  }

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((sessionType) => {
      this.sessionType = sessionType;

      this.utilService.getCurrentAccess().subscribe((access) => {
        if (access) {
          this.isModuleMedicationActive = access['isModuleMedicationActive'];
        }
      });

      const p = {
        orderBy: 'DisplayLabel',
      };
      this.medicalHistoryService.getAllergyCategoryList(p).subscribe((res) => {
        if (res) {
          this.categories = res;
        }
      });

      if (this.allergyID) {
        this.medicalHistoryService
          .getAllergyObject(this.allergyID, { selectMedication: true }, this.sessionType)
          .subscribe((res) => {
            if (res) {
              this.isEdit = true;

              if (res && res.medications) {
                this._medications = res.medications;
              }
              if (res['Category.Code']) {
                this.allergyObject.categoryCode = res['Category.Code'];

                this.lookupService.getLookup('CodeLookup', this.allergyObject.categoryCode).subscribe((category) => {
                  this.allergies = category;

                  if (res['Type.Code']) {
                    this.allergyObject.typeCode = res['Type.Code'];
                  }
                });
              } else {
                if (res['Type.Code']) {
                  this.allergyObject.typeCode = res['Type.Code'];
                }
              }

              if (res['Description']) {
                this.allergyObject.description = res['Description'];
              }

              if (res['Description']) {
                this.allergyObject.description = res['Description'];
              }

              if (res['FirstOccurDate']) {
                this.allergyObject.firstOccurDate = moment(res['FirstOccurDate']).format('MMMM, YYYY');
                this.startDate = res['FirstOccurDate'];
              }

              if (res['CeaseDate'] && res['CeaseDate'] != '0000-00-00') {
                this.allergyObject.ceaseDate = moment(res['CeaseDate']).format('MMMM, YYYY');
                this.stopDate = res['CeaseDate'];
              }

              if (res['MedicationFrequency.Code']) {
                this.allergyObject.medicationFrequencyCode = res['MedicationFrequency.Code'];
              }

              if (res['MedicationTaken']) {
                this.allergyObject.medicationTaken = res['MedicationTaken'];
              }

              this.allergyObject.isOngoing = res['IsOngoing'] == '1' ? true : false;
            }
            this.isLoaded = true;
          });
      } else {
        this.isLoaded = true;
      }
    });
  }

  getAllergies(value) {
    if (value) {
      this.lookupService.getLookup('CodeLookup', value).subscribe((res) => {
        this.allergies = res;
      });
    }
  }

  createAllergy() {
    this.allergyObject.medicalHistoryID = this.medicalHistoryID;

    if (this.allergyObject.isOngoing == true) {
      this.allergyObject.ceaseDate = 'void';
    }

    if (this.allergyObject.isOngoing == true) {
      this.allergyObject.medications = this.medications;
    }

    this.medicalHistoryService.createAllergy(this.allergyObject, this.sessionType).subscribe((res) => {
      if (res) {
        NotifyAppComponent.displayToast(
          'success',
          'Successful Operation',
          'You have successfully create your allergy history!'
        );

        this.getCreated.emit(res);
      }
    });
  }

  modifyAllergy() {
    if (this.allergyObject.isOngoing == true) {
      this.allergyObject.ceaseDate = 'void';
    }

    if (this.allergyObject.isOngoing == true) {
      this.allergyObject.medications = this.medications;
    }
    this.medicalHistoryService.modifyAllergy(this.allergyID, this.allergyObject, this.sessionType).subscribe((res) => {
      if (res) {
        NotifyAppComponent.displayToast(
          'success',
          'Successful Operation',
          'You have successfully create your allergy history!'
        );

        this.getEdited.emit(res);
      }
    });
  }

  close() {
    this.closeEvent.emit(true);
  }

  getNewMedications(d = []) {
    this.medications = d;
  }

  getMedicationExist(e) {
    this.medicationExist = e;
  }

  selectDateToFilter(e, key) {
    this.allergyObject[key] = e;
  }
}
