<div class="container audit" *ngIf="existingPatient">
  <div class="row clearfix">
    <div class="column">
      <mat-card class="card">
        <div class="colour-stripe"></div>
        <div class="row clearfix">
          <button class="btn-close btn-clear mat-button white pull-right" *ngIf="isModal == true" (click)="close()">
            <mat-icon>clear</mat-icon>
          </button>

          <div class="pull-left inv-head">
            <h3 class="sm-h3 sr-title rm-mb rm-mt">
              {{ existingPatient.firstName | lowercase | ucfirst }} {{ existingPatient.lastName | lowercase | ucfirst }}
            </h3>
            <label>{{ "KEYWORD.patient" | translate | titlecase }} Invitations:
              <span class="chip" *ngIf="invitations && invitations.length && invitations.length > 0">{{
                invitations.length
                }}</span>
            </label>
          </div>
          <div class="pull-right">
            <button class="mr" (click)="openCustomerViewModal()" mat-raised-button color="accent">
              <mat-icon>person</mat-icon>
              View {{"KEYWORD.patient" | translate}}
            </button>

            <!-- <div id="toggles" class="toggles widen pull-right">
                <mat-button-toggle-group #group1="matButtonToggleGroup">
                    <mat-button-toggle class="pos" matTooltip="Invitations - All">
                       All
                      </mat-button-toggle>
                  <mat-button-toggle class="pos" matTooltip="Invitations - Proceeding">
                    <mat-icon>mood</mat-icon>
                  </mat-button-toggle>
                  <mat-button-toggle class="maybe" matTooltip="Invitations - Maybe proceeding">
                    <mat-icon>sentiment_satisfied</mat-icon>
                  </mat-button-toggle>
                  <mat-button-toggle class="neg" matTooltip="Invitations - Not proceeding">
                    <mat-icon>mood_bad</mat-icon>
                  </mat-button-toggle>
                </mat-button-toggle-group>
              </div> -->
          </div>
        </div>
        <hr class="mb"/>
        <mat-dialog-content @smoothHeightAnim>
          <!-- <div class="row clearfix"> -->
          <!-- <div class="column"> -->
          <!-- <div class="icons-col">
              <mat-icon>person</mat-icon>
            </div> -->

          <!-- <div class="row-content-col summary">
              <div class="row clearfix">
                <div class="half-width">
                  <div class="col-left">

                    <label class="small">{{"PhoneMobile2" | translate}}</label>
                    <p class="rm-mt">{{ existingPatient["mobile"] | customPhone}} </p>

                    <label class="small">{{"PhoneWork2" | translate}}</label>
                    <p class="rm-mt" *ngIf='existingPatient["workPhone"] && existingPatient["workPhone"]!="undefined" '>
                      {{existingPatient["workPhone"] | customPhone:'landLine'}} </p>

                  </div>
                </div>
                <div class="half-width">
                  <div class="col-right">



                    <label class="small">Email:</label>
                    <p class="rm-mt">{{existingPatient["email"]}} </p>

                    <label class="small">{{"Address2" | translate}}</label>
                    <p class="rm-mt">{{existingPatient["adress"] || '...'}} </p>



                  </div>
                </div>

              </div>

            </div> -->
          <!-- </div> -->
          <!-- </div> -->

          <!-- <div class="row clearfix well" *ngIf="invitations && invitations.length && invitations.length>1">

            <mat-select name="MerchantName" class=" margin-btm half-width " placeholder="Please Select a Merchant"
              [(ngModel)]="merchantID">

              <mat-option [value]="null">
                {{"All" | translate}}
              </mat-option>
              <mat-option *ngFor="let m of  merchants   | orderBy : ['TradingAs', 'CalculatedName'] " [value]="m['ID']">
                {{m['TradingAs'] || m['CalculatedName'] }}
              </mat-option>
            </mat-select>

          </div> -->

          <div class="InvitationContainer row clearfix"
               *ngIf="invitations && invitations.length && invitations.length > 0" @smoothHeightAnim>
            <div class="InvitationRecord full-width" id="InvitationRecord" @smoothHeightAnim>
              <div class="row clearfix">
                <!-- repeater block -->
                <div class="clearfix settleWell"
                  *ngFor="let invitation of invitations | filterBy: ['MerchantName']:merchantName"
                     [style.borderColor]="invitation['Status.Invitation.Colour']">
                  <div class="row clearfix">
                    <div class="pull-left">
                      <h4 class="sm-h4 rm-m">
                        <mat-icon class="fas fa-money-bill-wave"
                                  [style.color]="invitation['Status.Application.Colour']"></mat-icon>
                        Invitation created
                        {{ invitation['DateTimeCreated'] | customDate }}
                      </h4>
                      <label class="small" *ngIf="role && (role == 'merchant' || merchantName)">{{
                        'KEYWORD.practitioner' | translate | titlecase }}
                        : {{ invitation['DentistContactName'] }}</label>
                    </div>
                    <button class="viewBtn" class="pull-right" *ngIf="invitation['ID']"
                            (click)="openInviteViewDialog(invitation['ID'])" mat-button color="primary"
                            matTooltip="View Invitation">
                      <mat-icon>search</mat-icon>
                    </button>
                  </div>
                  <div class="status-row row clearfix">
                    <p class="small rm-mt thrd-width">
                      Invitation status:
                      <span class="chip" *ngIf="invitation['Status.Invitation.Short_NE']"
                            [style.backgroundColor]="invitation['Status.Invitation.Colour']">{{
                        invitation['Status.Invitation.Short_NE'] }}
                      </span>
                      <span class="chip" *ngIf="!invitation['Status.Invitation.Short_NE']"
                            [style.backgroundColor]="'grey'">...</span>
                    </p>
                    <p class="small rm-mt thrd-width">
                      Application status :
                      <span class="chip" *ngIf="invitation['Status.Application.Short_NE']"
                            [style.backgroundColor]="invitation['Status.Application.Colour']">{{
                        invitation['Status.Application.Short_NE'] }}
                      </span>
                      <span class="chip" *ngIf="!invitation['Status.Application.Short_NE']"
                            [style.backgroundColor]="'grey'">...</span>
                    </p>
                  </div>
                  <div class="row clearfix grey">
                    <div class="qtr-width">
                      <label class="small">Amount</label>
                      <p class="rm-mt">
                        <span>{{ invitation['treatment.Value'] | customCurrency }} </span>
                      </p>
                    </div>
                    <div class="qtr-width" *ngIf="role && (role == 'promoter' || role == 'admin')">
                      <label class="small">{{ "KEYWORD.practitioner" | translate | titlecase }}:</label>
                      <p class="rm-mt">
                        <span>{{ invitation['DentistContactName'] }} </span>
                      </p>
                    </div>
                    <div class="qtr-width">
                      <label class="small">Product :</label>
                      <p class="rm-mt">
                        <span>{{ invitation['Product.Label.Marketing'] }} </span>
                      </p>
                    </div>
                    <div class="qtr-width" *ngIf="role && (role == 'promoter' || role == 'admin')">
                      <label class="small">Merchant:</label>
                      <p class="rm-mt">
                        <span>{{ invitation['MerchantName'] }} </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <!-- <mat-card class="card " *ngFor="let invitation of invitations | filterBy: ['MerchantName']: merchantName  "> -->
              <!-- <div class="colour-stripe "></div> -->
              <!-- <div class="row clearfix">




          <h3 class="sm-h3 sr-title rm-mb rm-mt">{{invitation['DateTimeCreated'] | customDate}}</h3>
          <hr class="mb">

        </div> -->
              <!-- <div class="row clearfix mb"> -->
              <!-- <div class="column"> -->

              <!-- <div class="row-content-col summary"> -->

              <!-- <div class="row clearfix">
                <div class="half-width">
                  <div class="col-left">
                    <div class="block">

                    </div>
                    <div class="block">
                      <label>{{"Amount" | translate}}</label>
                      <p class="rm-mt">
                        <span>{{invitation['treatment.Value'] | customCurrency}} </span>
                      </p>
                    </div>

                    <div class="block" *ngIf="role && (role=='promoter' || role=='admin')   ">
                      <label>{{ "KEYWORD.practitioner" | translate | titlecase }}:</label>
                      <p class="rm-mt">
                        <span>{{invitation['DentistContactName'] }} </span>
                      </p>
                    </div>

                  </div>
                </div>
                <div class="half-width">
                  <div class="col-right">
                    <div class="block">

                    </div>
                    <div class="block">
                      <label>{{"Product" | translate}} :</label>
                      <p class="rm-mt">
                        <span>{{invitation['Product.Label.Marketing'] }} </span>
                      </p>
                    </div>
                    <div class="block" *ngIf="role && (role=='promoter' || role=='admin')  ">
                      <label>{{"Merchant" | translate}}:</label>
                      <p class="rm-mt">
                        <span>{{invitation['MerchantName'] }} </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row clearfix full-width" *ngIf="role &&  (role=='merchant' || merchantName)">
                <label>{{"KEYWORD.practitioner" | translate | titlecase}}:</label>
                <p class="rm-mt">
                  <span>{{invitation['DentistContactName'] }} </span>
                </p>
              </div>
            </div> -->
              <!-- </div> -->
              <!-- </div> -->
              <!-- </mat-card> -->
            </div>
          </div>

          <div *ngIf="invitations && invitations.length == 0" @ngIfAnimation>
            <div class="pleaseChoose">
              <p>
                <strong>No Invitation To Show</strong>
              </p>
              <img class="character_warning" />
              <p>This {{ "KEYWORD.patient" | translate | titlecase }} has no invitation linked to your account.</p>
            </div>
          </div>
        </mat-dialog-content>
        <div class="clearfix row text-center mt">
          <button (click)="close()" mat-raised-button color="accent">Close</button>
        </div>
      </mat-card>
    </div>
  </div>
</div>
