import { InjectionToken, NgModule } from '@angular/core';
import { CountriesData } from 'countries-data';

export { Countries } from 'countries-data';

export const COUNTRIES_DATA = new InjectionToken('CountriesData');

@NgModule({
  imports: [],
  providers: [{ provide: COUNTRIES_DATA, useValue: CountriesData }],
})
export class ProviderModule {}
