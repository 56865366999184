<div class="row clearfix" @onChangeAnimation>
  <mat-card class="card clearfix">
    <div class="colour-stripe"></div>

    <div class="row full-width clearfix">
      <div class="clearfix rel">
        <div class="row white-background full-width clearfix" @onChangeAnimation>
          <div class="animate">
            <div class="mt mb clearfix">
              <ipv-data-header [title]="'Settlements Due'" [counterValue]="summaryRecordCount"></ipv-data-header>

              <div class="padded" style="overflow: hidden">
                <ng-container>
                  <div
                    style="margin-top: 10px; margin-bottom: 40px; width: 100%; display: flex; justify-content: flex-end"
                  >
                    <button
                      (click)="openTablePrint()"
                      color="accent"
                      mat-raised-button
                      style="margin-left: 30px; height: 36px"
                    >
                      <mat-icon class="fas fa-print"></mat-icon>
                      Print Report
                    </button>
                  </div>

                  <ipv-data-table-control-container>
                    <app-date-input
                      [defaultDate]="initialStartDate"
                      (selectedDate)="setStartDate($event)"
                      [label]="'Start Date'"
                    ></app-date-input>

                    <app-date-input
                      *ngIf="showEndDate"
                      [defaultDate]="initialEndDate"
                      (selectedDate)="setEndDate($event)"
                      [label]="'End Date'"
                    ></app-date-input>

                    <div style="margin-left: auto; margin-top: 2px; color: white; font-weight: bold">
                      <h2 *ngIf="summaryTotal !== 0">Total Due: {{ summaryTotal | invertNumber | customCurrency }}</h2>
                    </div>
                  </ipv-data-table-control-container>
                </ng-container>

                <ng-container *ngIf="(dueSettlementsReport$ | async) as dueSettlementsReport">
                  <ipv-settlement-due-settlements-report-main
                    [dueSettlementsReport]="dueSettlementsReport"
                    [startDate]="startDate"
                    [endDate]="endDate"
                  ></ipv-settlement-due-settlements-report-main>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-card>
</div>
