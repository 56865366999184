import { MapsAPILoader } from '@agm/core';
import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { OverlayContainer, ToastrService } from 'ngx-toastr';
import { Observable, timer } from 'rxjs';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { SessionCountdownModalComponent } from '../../../core/session/session-countdown-modal/session-countdown-modal.component';
import { SettingsService } from '../../../core/settings/shared/settings.service';
import { FacebookPixelService } from '../../services/facebook-pixel/facebook-pixel.service';
import { ClientDetails } from '../../types/client-details';
import { DeviceDetector } from '../../types/device-detector';
import { NotifyAppComponent } from '../../types/notify-app-component';
import { Settings } from '../../types/settings';
import { DetectBrowserComponent } from '../detect-browser/detect-browser.component';

import { CookieService } from 'ng2-cookies';

declare var google: any;

@Component({
  selector: 'app-root-component',
  templateUrl: './root-component.component.html',
  styleUrls: ['./root-component.component.css'],
})
export class RootAppComponent implements OnInit {
  static closeModal = new EventEmitter();
  static dialog: MatDialog;
  static geocoder;
  static setCurrentAccess = new EventEmitter();
  static updateSideMenuAllModule = new EventEmitter();
  static newSubscriptionPlan = new EventEmitter();
  static hideSideMenu = new EventEmitter();
  static stopSubPlanTimer = false;
  static expendMessageInput = new EventEmitter();
  static getExpendMessageInputResult = new EventEmitter();
  driftsubscribe;
  driftsubscribeUpdate;
  dontShowBrowserWarning = JSON.parse(localStorage.getItem('dontShowBrowserWarning')) || false;

  isLoading = true;
  isSettingApplied = false;
  public authChange$: Observable<any>;
  settings = Settings.global;
  driftID;
  @Input()
  drift = true;

  constructor(
    public dialogLocal: MatDialog,
    public toastr: ToastrService,
    private deviceDetectorService: DeviceDetectorService,
    public overlayContainer: OverlayContainer,
    private translate: TranslateService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private settingsService: SettingsService,
    private authenticationService: AuthenticationService,
    private mapsApiLoader: MapsAPILoader,
    private cookieService: CookieService,
    private facebookPixelService: FacebookPixelService
  ) {
    RootAppComponent.dialog = dialogLocal;
    window.addEventListener('dragend', function (e) {
      e.preventDefault();
    });

    window.addEventListener('dragover', function (e) {
      e.preventDefault();
    });

    window.addEventListener('drop', function (e) {
      e.preventDefault();
    });

    this.mapsApiLoader.load().then(() => {
      RootAppComponent.geocoder = new google.maps.Geocoder();
    });

    ClientDetails.getTimeZoneUTC();

    const deviceInfo = this.deviceDetectorService.getDeviceInfo();
    DeviceDetector.setDeviceInfo(deviceInfo);

    const browser = deviceInfo.browser;
    const browserVersion = deviceInfo.browser_version;
    const os = deviceInfo.os;
    const osVersion = deviceInfo.os_version;
    const device = deviceInfo.device;

    if (browser) {
      ClientDetails.browser = browser + ' ' + browserVersion;
    }

    if (os) {
      ClientDetails.os = os + ' ' + osVersion;
    }

    if (device) {
      ClientDetails.device = device + '-' + os;
    }

    if (browser === 'ie' && this.dontShowBrowserWarning == false) {
      const ref = this.dialogLocal.open(DetectBrowserComponent, {
        width: '800px',
        panelClass: 'noCard',
      });

      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    }

    Settings.global = this.settingsService.getPublicSettingsSync();
    ClientDetails.getIPAddress();

    Settings.applySettings();

    const context = Settings.global['context'] || 'default';
    const translateHash = Settings.global['translateHash'] || '297749565899';
    if (context && translateHash) {
      this.translate.use(context + '/' + translateHash);
    } else if (context) {
      this.translate.use(context + '/297749565899');
    } else {
      this.translate.use('default');
    }

    this.authenticationService.sendReviveSession.subscribe(() => {
      this.authenticationService.getSessionType().subscribe((res) => {
        if (res && res != 'guest') {
          this.authenticationService.refreshAliveHidden().subscribe((res) => {
            console.info('you are online');
          });
        }
      });
    });

    this.authenticationService.syncPlan.subscribe(() => {
      if (RootAppComponent.stopSubPlanTimer != true) {
        this.authenticationService.getSessionType().subscribe((res) => {
          if (res && res != 'guest') {
            this.authenticationService.getCurrentPlanForce(true, false).subscribe((planID) => {
              if (planID) {
                this.authenticationService.getCurrentPlan().subscribe((currentPlanID) => {
                  if (currentPlanID != planID) {
                    this.authenticationService.setCurrentPlan(planID);

                    RootAppComponent.newSubscriptionPlan.emit(planID);

                    this.authenticationService.getCurrentUserForce(true).subscribe((res) => {
                      this.authenticationService.getCurrentForceAccess(true).subscribe((access) => {
                        if (access) {
                          RootAppComponent.setCurrentAccess.emit(access);
                        }
                        console.info('New subscription');
                      });
                    });
                  } else {
                    this.authenticationService
                      .getCurrentPlanTransactionForce(true, false)
                      .subscribe((planTransactionID) => {
                        if (planTransactionID) {
                          this.authenticationService
                            .getCurrentPlanTransactionID()
                            .subscribe((currentPlanTransactionID) => {
                              if (currentPlanTransactionID != planTransactionID) {
                                this.authenticationService.setCurrentPlanTransactionID(planTransactionID);
                                this.authenticationService.getCurrentUserForce(true).subscribe((res) => {
                                  this.authenticationService.getCurrentForceAccess(true).subscribe((access) => {
                                    if (access) {
                                      RootAppComponent.setCurrentAccess.emit(access);
                                    }
                                    console.info('New subscription Update');
                                  });
                                });
                              } else {
                                console.info('Your plan is Sync');
                              }
                            });
                        }
                      });
                  }
                });
              }
            });
          }
        });
      }
    });

    this.authenticationService.syncModule.subscribe(() => {
      this.authenticationService.getSessionType().subscribe((sessionTye) => {
        this.authenticationService.getModuleListHidden({}, sessionTye).subscribe((res) => {
          if (res) {
            console.info('New List');
            RootAppComponent.updateSideMenuAllModule.emit(res);
          }
        });
      });
    });

    this.authenticationService.sendCountDown.subscribe(() => {
      this.authenticationService.isLogin().subscribe((res) => {
        if (res == true) {
          const ref = this.dialogLocal.open(SessionCountdownModalComponent);

          ref.componentInstance.close.subscribe((res) => {
            if (res) {
              ref.close();
            }
          });
        }
      });
    });



    AuthenticationService.clearCookie.subscribe((res) => {
      if (res) {
        this.authenticationService.initCurrUser();
        this.cookieService.delete('sessionId');
        this.cookieService.delete('sessionid');
        sessionStorage.clear();
      }
    });

    if (this.drift === true) {
      AuthenticationService.updateDrift.subscribe((app = 'merchant') => {
        this.authenticationService.getSessionType().subscribe((sessionType) => {
          if (app == 'none') {
            this.driftID = 'none';
          } else if (sessionType == 'merchant') {
            this.driftID = Settings.global['merchantDrift'];
          } else if (sessionType == 'admin') {
            this.driftID = Settings.global['merchantDrift'];
          } else if (sessionType == 'customer' || sessionType == 'consumer') {
            this.driftID = Settings.global['consumerDrift'];
          } else if (sessionType == 'supplier') {
            this.driftID = Settings.global['supplierDrift'];
          } else if (sessionType == 'funder') {
            this.driftID = Settings.global['funderDrift'];
          } else {
            if (app == 'merchant') {
              this.driftID = Settings.global['merchantLoginDrift'];
            } else if (app == 'customer' || app == 'consumer') {
              this.driftID = Settings.global['consumerLoginDrift'];
            } else if (app == 'supplier') {
              this.driftID = Settings.global['supplierLoginDrift'];
            } else if (app == 'funder') {
              this.driftID = Settings.global['funderLoginDrift'];
            } else if (app == 'promoter') {
              this.driftID = Settings.global['merchantLoginDrift'];
            } else if (app == 'patientCard') {
              this.driftID = Settings.global['patientCardDrift'];
            } else {
              this.driftID = Settings.global['merchantLoginDrift'];
            }
          }

          if (window && window['drift'] && window['drift']['api']) {
            if (this.driftID) {
              if (this.driftID == '0' || this.driftID == 0 || !this.driftID) {
                window['drift'].reset();
              } else if (this.driftID) {
                window['drift'].reset();

                this.setDrift(false);
              } else {
                window['drift'].reset();
              }
            } else {
              window['drift'].reset();
            }
          } else {
            this.setDrift(false);
          }
        });
      });

      AuthenticationService.setDrift.subscribe((drift, openPanel = false) => {
        if (window && window['drift'] && window['drift']['api']) {
          if (drift) {
            this.driftID = this.settings[drift];

            if (this.driftID == '0' || this.driftID == 0 || !this.driftID) {
              window['drift'].reset();
            } else if (this.driftID) {
              window['drift'].reset();
              this.setDrift(false);
            } else {
              window['drift'].reset();
            }
          } else {
            window['drift'].reset();
          }
        } else {
          this.driftID = this.settings[drift];
          this.setDrift(false);
        }
      });
    }
    this.authenticationService.sendTimeOut.subscribe(() => {
      this.authenticationService.isLogin().subscribe((res) => {
        if (res == true) {
          this.logout();
        }
      });
    });

    // this.toastr.setRootViewContainerRef(vcr);

    ClientDetails.getGeoLocation();
  }

  setDrift(openPanel = false) {
    if (this.driftsubscribeUpdate) {
      this.driftsubscribeUpdate.unsubscribe();
    }

    if (this.driftID && this.driftID != 0 && this.driftID != '0') {
      let i = 0;
      const stream$ = timer(0, 1000);
      this.driftsubscribeUpdate = stream$.subscribe((x) => {
        if (window && window['drift'] && window['drift']['api'] && this.driftID && this.driftID != 'none') {
          window['drift'].api.startInteraction({ interactionId: Number(this.driftID), goToConversation: openPanel });
          if (this.driftsubscribeUpdate) {
            this.driftsubscribeUpdate.unsubscribe();
          }
        } else if (window && window['drift'] && window['drift']['api'] && this.driftID && this.driftID == 'none') {
          window['drift'].hide();
          if (this.driftsubscribeUpdate) {
            this.driftsubscribeUpdate.unsubscribe();
          }
        }
        i++;
        if (i > 120) {
          this.driftsubscribeUpdate.unsubscribe();
        }
      });
    }
  }

  logout() {
    let sessionType;
    let currentUser;
    this.authenticationService.getCurrentUser().subscribe((currentUser2) => {
      if (currentUser2) {
        currentUser = currentUser2;
      }

      this.authenticationService.getSessionType().subscribe((res) => {
        if (res) {
          sessionType = res;
        }
        this.authenticationService.logout().subscribe(
          (res) => {
            this.activeRoute.params.subscribe((params) => {
              this.authenticationService.initCurrUser();

              let navigation = 'medical';

              if (currentUser && currentUser.data && currentUser.data.navigation) {
                navigation = currentUser.data.navigation;
              }

              if (
                sessionType == 'customer' &&
                currentUser.data &&
                currentUser.data.loginSource &&
                currentUser.data.loginSource === 'practice-auth'
              ) {
                if (currentUser.data.merchantID) {
                  this.router.navigate(['/practice-auth/', currentUser.data.merchantID]);
                } else {
                  this.router.navigate(['/practice-auth/']);
                }
              } else if (
                sessionType == 'customer' &&
                currentUser.data.loginSource &&
                currentUser &&
                currentUser.data.ID &&
                !currentUser.data.merchantID &&
                !currentUser.data.invitationID &&
                !currentUser.data.medicalInvitationID
              ) {
                this.router.navigate(['/customer-login/' + navigation + '/patient/', currentUser.data.ID]);
              } else if (
                sessionType == 'customer' &&
                currentUser.data.loginSource &&
                currentUser &&
                currentUser.data.ID &&
                currentUser.data.merchantID &&
                !currentUser.data.invitationID &&
                !currentUser.data.medicalInvitationID
              ) {
                this.router.navigate([
                  '/customer-login/' + navigation + '/',
                  currentUser.data.ID,
                  currentUser.data.merchantID,
                ]);
              } else if (
                sessionType == 'customer' &&
                currentUser.data.loginSource &&
                currentUser &&
                currentUser.data.ID &&
                !currentUser.data.merchantID &&
                currentUser.data.invitationID &&
                !currentUser.data.medicalInvitationID
              ) {
                this.router.navigate(['/customer-login/' + navigation + '/invitation/', currentUser.data.invitationID]);
              } else if (
                sessionType == 'customer' &&
                currentUser.data.loginSource &&
                currentUser &&
                currentUser.data.ID &&
                !currentUser.data.merchantID &&
                !currentUser.data.invitationID &&
                currentUser.data.medicalInvitationID
              ) {
                this.router.navigate([
                  '/customer-login/' + navigation + '/medical-invitation/',
                  currentUser.data.medicalInvitationID,
                ]);
              } else {
                this.router.navigate(['/login']);
              }
            });
          },
          (err: any) => {
            console.log(err);
          }
        );
      });
    });
  }

  ngAfterContentChecked() {
    Settings.applyImages();
  }

  ngOnInit() {
    this.facebookPixelService.startTracking('1297402354315871');

    NotifyAppComponent.navigation.subscribe((link) => {
      this.router.navigate([link]);
    });

    NotifyAppComponent.errorEvent.subscribe((error) => {
      const optionError = {
        closeButton: true,
        enableHTML: true,
        timeOut: 15000,
      };

      const optionSuccess = {
        closeButton: true,
        enableHTML: true,
        timeOut: 5000,
      };

      const optionWarning = {
        closeButton: true,
        enableHTML: true,
        timeOut: 10000,
      };

      const optionInfo = {
        closeButton: true,
        enableHTML: true,
        timeOut: 12000,
      };

      const userDataLoader = {
        positionClass: 'toast-bottom-left',
        animate: 'flyLeft',
        enableHTML: true,
        timeOut: 150000,
        maxShown: 1,
      };

      if (error) {
        if (error.type === 'error') {
          this.toastr.error(error.title, error.content, optionError);
        } else if (error.type === 'success') {
          this.toastr.success(error.title, error.content, optionSuccess);
        } else if (error.type === 'warning') {
          this.toastr.warning(error.title, error.content, optionWarning);
        } else if (error.type === 'info') {
          this.toastr.info(error.title, error.content, optionInfo);
        } else if (error.type === 'userDataLoader') {
          this.toastr.info(
            '<span style="color: #199BFF;"> <img src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/loader.gif" /> </div> Saving Your Data.</span>',
            null,
            userDataLoader
          );
        }
      }
    });
  }
}
