/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./activity-log-list-overview.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../activity-log-list-global/activity-log-list-global.component.ngfactory";
import * as i3 from "../activity-log-list-global/activity-log-list-global.component";
import * as i4 from "../shared/activity-log.service";
import * as i5 from "../../../core/authentication/shared/authentication.service";
import * as i6 from "../../../shared/pipes/custom-date.pipe";
import * as i7 from "@angular/router";
import * as i8 from "../../operator/operator-filter/operator-filter.component.ngfactory";
import * as i9 from "../../operator/operator-filter/operator-filter.component";
import * as i10 from "../../merchant/shared/merchant.service";
import * as i11 from "../../product/shared/product.service";
import * as i12 from "../../dentist/shared/dentist.service";
import * as i13 from "@angular/common";
import * as i14 from "../activity-log-list/activity-log-list.component.ngfactory";
import * as i15 from "../activity-log-list/activity-log-list.component";
import * as i16 from "./activity-log-list-overview.component";
var styles_ActivityLogListOverviewComponent = [i0.styles];
var RenderType_ActivityLogListOverviewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ActivityLogListOverviewComponent, data: { "animation": [{ type: 7, name: "ngIfAnimation", definitions: [{ type: 1, expr: "void => *", animation: [{ type: 11, selector: "*", animation: { type: 6, styles: { opacity: 0 }, offset: null }, options: { optional: true } }, { type: 11, selector: "*", animation: { type: 12, timings: "100ms", animation: [{ type: 4, styles: { type: 5, steps: [{ type: 6, styles: { opacity: 0, transform: "translateY(-75%)", offset: 0, height: 0 }, offset: null }, { type: 6, styles: { opacity: 1, transform: "translateY(0)", offset: 1, height: "*" }, offset: null }] }, timings: "0.8s ease-out" }] }, options: { optional: true } }], options: null }, { type: 1, expr: "* => void", animation: [{ type: 11, selector: "*", animation: { type: 6, styles: { opacity: 1 }, offset: null }, options: { optional: true } }, { type: 11, selector: "*", animation: { type: 12, timings: "100ms", animation: [{ type: 4, styles: { type: 5, steps: [{ type: 6, styles: { opacity: 1, transform: "translateY(0)", offset: 0, height: "*" }, offset: null }, { type: 6, styles: { opacity: 0, transform: "translateY(-75%)", offset: 1, height: 0 }, offset: null }] }, timings: "0.8s ease-in" }] }, options: { optional: true } }], options: null }], options: {} }] } });
export { RenderType_ActivityLogListOverviewComponent as RenderType_ActivityLogListOverviewComponent };
function View_ActivityLogListOverviewComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-activity-log-list-global", [], null, [[null, "groupFilterEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("groupFilterEvent" === en)) {
        var pd_0 = (_co.getGroupFilter($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ActivityLogListGlobalComponent_0, i2.RenderType_ActivityLogListGlobalComponent)), i1.ɵdid(1, 770048, null, 0, i3.ActivityLogListGlobalComponent, [i4.ActivityLogService, i5.AuthenticationService, i6.CustomDatePipe, i7.Router], { merchantID: [0, "merchantID"], dateFrom: [1, "dateFrom"], dateTo: [2, "dateTo"], groupFilter: [3, "groupFilter"], displayToggleSwitch: [4, "displayToggleSwitch"] }, { groupFilterEvent: "groupFilterEvent" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.merchantID; var currVal_1 = _co.dateFrom; var currVal_2 = _co.dateTo; var currVal_3 = _co.groupFilter; var currVal_4 = true; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
function View_ActivityLogListOverviewComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "pleaseChoose"]], [[24, "@ngIfAnimation", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Merchant No Selected"])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["class", "character_warning"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Merchant Choose Desired"]))], null, function (_ck, _v) { var currVal_0 = undefined; _ck(_v, 0, 0, currVal_0); }); }
function View_ActivityLogListOverviewComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-operator-filter", [["class", "dash"], ["id", "part1"]], null, [[null, "getMerchant"], [null, "getDateTo"], [null, "getDateFrom"], [null, "search"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("getMerchant" === en)) {
        var pd_0 = (_co.getMerchant($event) !== false);
        ad = (pd_0 && ad);
    } if (("getDateTo" === en)) {
        var pd_1 = (_co.getDateTo($event) !== false);
        ad = (pd_1 && ad);
    } if (("getDateFrom" === en)) {
        var pd_2 = (_co.getDateFrom($event) !== false);
        ad = (pd_2 && ad);
    } if (("search" === en)) {
        var pd_3 = (_co.applySearch($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i8.View_OperatorFilterComponent_0, i8.RenderType_OperatorFilterComponent)), i1.ɵdid(3, 114688, null, 0, i9.OperatorFilterComponent, [i5.AuthenticationService, i10.MerchantService, i11.ProductService, i12.DentistService], { displayMerchant: [0, "displayMerchant"], displayDateFrom: [1, "displayDateFrom"], displayDateTo: [2, "displayDateTo"], displayDentist: [3, "displayDentist"], selectDateRange: [4, "selectDateRange"], sendNull: [5, "sendNull"], setAutoSelectMerchant: [6, "setAutoSelectMerchant"] }, { getMerchant: "getMerchant", getDateTo: "getDateTo", getDateFrom: "getDateFrom", search: "search" }), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "row clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ActivityLogListOverviewComponent_2)), i1.ɵdid(6, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "row clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ActivityLogListOverviewComponent_3)), i1.ɵdid(9, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.isDisplayMerchant; var currVal_2 = false; var currVal_3 = false; var currVal_4 = false; var currVal_5 = _co.selectedRange; var currVal_6 = true; var currVal_7 = true; _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_8 = ((_co.merchantID != "none") && (_co.searchApplied == true)); _ck(_v, 6, 0, currVal_8); var currVal_9 = (_co.merchantID == "none"); _ck(_v, 9, 0, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hideExtraComponents; _ck(_v, 1, 0, currVal_0); }); }
function View_ActivityLogListOverviewComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-activity-log-list", [], null, null, null, i14.View_ActivityLogListComponent_0, i14.RenderType_ActivityLogListComponent)), i1.ɵdid(1, 770048, null, 0, i15.ActivityLogListComponent, [i4.ActivityLogService, i5.AuthenticationService, i6.CustomDatePipe, i7.Router], { dateFrom: [0, "dateFrom"], dateTo: [1, "dateTo"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dateFrom; var currVal_1 = _co.dateTo; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_ActivityLogListOverviewComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "row full-width clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-operator-filter", [["class", "dash"], ["id", "part1"]], null, [[null, "getMerchant"], [null, "getDateTo"], [null, "getDateFrom"], [null, "search"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("getMerchant" === en)) {
        var pd_0 = (_co.getMerchant($event) !== false);
        ad = (pd_0 && ad);
    } if (("getDateTo" === en)) {
        var pd_1 = (_co.getDateTo($event) !== false);
        ad = (pd_1 && ad);
    } if (("getDateFrom" === en)) {
        var pd_2 = (_co.getDateFrom($event) !== false);
        ad = (pd_2 && ad);
    } if (("search" === en)) {
        var pd_3 = (_co.applySearch($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i8.View_OperatorFilterComponent_0, i8.RenderType_OperatorFilterComponent)), i1.ɵdid(3, 114688, null, 0, i9.OperatorFilterComponent, [i5.AuthenticationService, i10.MerchantService, i11.ProductService, i12.DentistService], { displayMerchant: [0, "displayMerchant"], displayDateFrom: [1, "displayDateFrom"], displayDateTo: [2, "displayDateTo"], displayProduct: [3, "displayProduct"], displayDentist: [4, "displayDentist"], selectDateRange: [5, "selectDateRange"], sendNull: [6, "sendNull"] }, { getMerchant: "getMerchant", getDateTo: "getDateTo", getDateFrom: "getDateFrom", search: "search" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ActivityLogListOverviewComponent_5)), i1.ɵdid(5, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = false; var currVal_2 = false; var currVal_3 = false; var currVal_4 = false; var currVal_5 = false; var currVal_6 = _co.selectedRange; var currVal_7 = true; _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_8 = (_co.searchApplied == true); _ck(_v, 5, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hideExtraComponents; _ck(_v, 1, 0, currVal_0); }); }
export function View_ActivityLogListOverviewComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ActivityLogListOverviewComponent_1)), i1.ɵdid(1, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ActivityLogListOverviewComponent_4)), i1.ɵdid(3, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isPromoterOrAdmin == true); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.isPromoterOrAdmin == false); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_ActivityLogListOverviewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-activity-log-list-overview", [], null, null, null, View_ActivityLogListOverviewComponent_0, RenderType_ActivityLogListOverviewComponent)), i1.ɵdid(1, 114688, null, 0, i16.ActivityLogListOverviewComponent, [i5.AuthenticationService, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ActivityLogListOverviewComponentNgFactory = i1.ɵccf("app-activity-log-list-overview", i16.ActivityLogListOverviewComponent, View_ActivityLogListOverviewComponent_Host_0, {}, {}, []);
export { ActivityLogListOverviewComponentNgFactory as ActivityLogListOverviewComponentNgFactory };
