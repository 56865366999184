<mat-sidenav-container class="profile-container" *ngIf="patient" [class.thin]="!isExpanded">
  <mat-sidenav
    class="profile-sidebar"
    #sidenavProfile
    [mode]="navMode"
    [opened]="openFlag"
    [position]="'end'"
    [class.stuck]="stickyStuck"
  >
    <div class="sticky" #sticky>
      <div class="profile">
        <section class="profile-header primary-gradient-img rel" [class.expando]="isExpanded">
          <button class="coll white" *ngIf="isExpanded" (click)="collapseSidePanel()" mat-icon-button>
            <mat-icon [class.rotate]="!isExpanded">chevron_right</mat-icon>
          </button>

          <app-customer-prospect-view-picture
            class="profile-lead-image"
            *ngIf="isExpanded"
            @simpleFadeAnimation
            [patientID]="patient['ID']"
            [LastModified]="patient['LastModified']"
          >
          </app-customer-prospect-view-picture>
          <app-customer-prospect-view-picture
            class="profile-shrink-image pointer"
            *ngIf="!isExpanded"
            @simpleFadeAnimation
            [patientID]="patient['ID']"
            [LastModified]="patient['LastModified']"
            [hideZoomButton]="true"
            (click)="collapseSidePanel()"
            matTooltip="Click to expand"
          >
          </app-customer-prospect-view-picture>
          <div class="profile-header-details" *ngIf="isExpanded" @simpleFadeAnimation>
            <h2>{{ patient['FirstName'] }} {{ patient['MiddleName'] }} {{ patient['Name'] }}</h2>


            <p class="small" *ngIf="birthday && birthday != '0000-00-00'">
              Age: <strong>{{ birthday | age }}</strong>
            </p>

            <p class="small" *ngIf="patient['addresses.Calculated']">{{ patient['addresses.Calculated'] }}</p>
            <p class="small" *ngIf="patient['DateTimeCreated']">
              Created Date: {{ patient['DateTimeCreated'] | customDateTime }}
            </p>
            <p class="small" *ngIf="patient['LastModified_Human']">
              Last modifed Date: {{ patient['LastModified_Human'] | customDateTime }}
            </p>
          </div>

          <div class="ctrlbtn">
            <button
              class="invite"
              [matMenuTriggerFor]="patientInvLink"
              mat-mini-fab
              color="warn"
              matTooltip="Create new invitation"
              matTooltip-position="left"
            >
              <mat-icon>send</mat-icon>
            </button>

            <button
              class="inline btn-new-invite gradient green"
              *ngIf="isModulePatientHistoryActive == true"
              (click)="inviteMedicalHistory()"
              mat-mini-fab
              color="warn"
              matTooltip="Create new Medical invitation"
              matTooltip-position="left"
            >
              <mat-icon class="fas fa-heartbeat"></mat-icon>
            </button>

            <mat-menu #patientInvLink="matMenu">
              <div *ngIf="isModuleTreatmentPlanActive == true && financedProductGroup">
                <div *ngFor="let p of financedProductGroup">
                  <button
                    (click)="linkToInvitation(p)"
                    mat-menu-item
                    matTooltip="New {{ productUtil.getLabel(p['GroupCode']) }} Invitation"
                  >
                    New <strong>{{ productUtil.getLabel(p['GroupCode']) }}</strong> Invitation
                    <mat-icon class="position fas {{ productUtil.getProductGroupIcon(p['GroupCode']) }} "></mat-icon>
                  </button>
                  <mat-divider></mat-divider>
                </div>
              </div>

              <button (click)="createNote(patient['ID'])" mat-menu-item>
                <mat-icon>note_add</mat-icon>
                <span>Create Note</span>
              </button>

              <button (click)="viewNotes(patient['ID'])" mat-menu-item>
                <mat-icon class="fa fa-list-alt"></mat-icon>
                <span>View notes</span>
              </button>
            </mat-menu>
          </div>
        </section>

        <div class="profile-sidebar-container">
          <div class="row clearfix" *ngIf="isExpanded" @simpleFadeAnimation>
            <div class="row clearfix flex icon-container">
              <!-- <div class="icon-list" *ngIf="isSmoke==true || isDrink==true || hasImmediateProblem==true"> -->
              <div class="ic smoke" *ngIf="isSmoke == true">
                <i class="fas fa-smoking" matTooltip="Smoker"></i>
              </div>
              <div class="ic drink" *ngIf="isDrink == true">
                <i class="fas fa-wine-bottle" matTooltip="Drinker"></i>
              </div>
              <div class="ic immed" *ngIf="hasImmediateProblem == true">
                <i class="fas fa-exclamation-triangle" matTooltip="Immediate problem"></i>
              </div>
              <!-- </div> -->
              <div class="icon-list illness" *ngIf="illnessIconList && illnessIconList.length > 0">
                <div class="ic" *ngFor="let ic of illnessIconList" matTooltip="{{ ic.Label }}">
                  <p>{{ ic.Icon }}</p>
                </div>
              </div>
              <div class="icon-list allergy" *ngIf="allergyIconList && allergyIconList.length > 0">
                <div class="ic" *ngFor="let ic of allergyIconList" matTooltip="{{ ic.Category }} {{ ic.Label }}">
                  <p>{{ ic.Icon }}</p>
                </div>
              </div>
            </div>
            <hr class="mt"/>
          </div>

          <!-- Phone numbers -->
          <div class="row clearfix" *ngIf="patient['mobiles.Number'] || patient['workPhone']">
            <div class="icons">
              <mat-icon
                class="pointer"
                *ngIf="patient['mobiles.Number']"
                (click)="dial(patient['mobiles.Number'])"
                matTooltip="Call {{ patient['FirstName'] }}"
              >phone
              </mat-icon>
              <mat-icon
                class="pointer"
                *ngIf="patient['workPhone'] && patient['workPhone'] != 'undefined'"
                (click)="dial(patient['workPhone'])"
                matTooltip="Call {{ patient['FirstName'] }}"
              >phone
              </mat-icon>
            </div>
            <div class="row-content" *ngIf="isExpanded" @simpleFadeAnimation>
              <p class="rm-m" *ngIf="patient['mobiles.Number']">{{ patient['mobiles.Number'] | customPhone }}</p>
              <p class="small" *ngIf="patient['mobiles.Number']">Phone/Mobile</p>
              <p class="rm-m" *ngIf="patient['workPhone'] && patient['workPhone'] != 'undefined'">
                {{ patient['workPhone'] | customPhone: 'landLine' }}
              </p>
              <p class="small" *ngIf="patient['workPhone'] && patient['workPhone'] != 'undefined'">
                Work
              </p>
            </div>
          </div>
          <mat-divider *ngIf="patient['mobiles.Number'] || patient['workPhone']"></mat-divider>
          <!-- Email Addresses -->

          <div class="row clearfix" *ngIf="patient['emails.Email']">
            <div class="icons">
              <mat-icon
                class="pointer"
                (click)="email(patient['emails.Email'])"
                matTooltip="Email {{ patient['FirstName'] }}"
              >email
              </mat-icon>
            </div>
            <div class="row-content" *ngIf="isExpanded" @simpleFadeAnimation>
              <p class="rm-m">{{ patient['emails.Email'] }}</p>
              <p class="small">Primary Email</p>
            </div>
          </div>

          <mat-divider *ngIf="patient['emails.Email']"></mat-divider>

          <div class="consent" *ngIf="isExpanded" @simpleFadeAnimation>
            <!-- elec consent basic -->
            <span class="label green" *ngIf="patient['ElectronicCommsConsent.Given'] == '1'">
              <mat-icon>check</mat-icon> Electronic Consent
            </span>
            <span class="label red" *ngIf="patient['ElectronicCommsConsent.Given'] == '0'">
              <mat-icon>clear</mat-icon> Electronic Consent
            </span>
            <!-- marketing consent basic -->
            <span class="label green" *ngIf="patient['MarketingConsent.Given'] == '1'">
              <mat-icon>check</mat-icon> Marketing Consent
            </span>
            <span class="label red" *ngIf="patient['MarketingConsent.Given'] == '0'">
              <mat-icon>clear</mat-icon> Marketing Consent
            </span>
            <!-- data storage basic -->
            <span class="label green" *ngIf="patient['ElectronicCommsConsent.Given'] == '1'">
              <mat-icon>check</mat-icon> Data Storage
            </span>
            <span class="label red" *ngIf="patient['ElectronicCommsConsent.Given'] == '0'">
              <mat-icon>clear</mat-icon> Data Storage
            </span>
            <!-- privacy basic -->
            <span class="label green" *ngIf="patient['ElectronicCommsConsent.Given'] == '1'">
              <mat-icon>check</mat-icon> Privacy Consent
            </span>
            <span class="label red" *ngIf="patient['ElectronicCommsConsent.Given'] == '0'">
              <mat-icon>clear</mat-icon> Privacy Consent
            </span>
          </div>

          <mat-divider *ngIf="patient['ReferredCustomer'] == '1'"></mat-divider>

          <div class="consent">
            <!-- Referred Patient -->
            <span class="label green" *ngIf="patient['ReferredCustomer'] == '1'">
              <mat-icon>check</mat-icon> Referred {{ "KEYWORD.patient" | translate | titlecase }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </mat-sidenav>

  <!-- Side Menu -->

  <!-- flex container -->
  <div class="profile-content">
    <app-customer-prospect-profile-tabs
      [selectedMerchant]="merchantID"
      [patientID]="patient['ID']"
      [class.add-btn-pad]="isParameter == true"
      [tabs]="tabs"
      [itemID]="itemID"
      (merchantID)="getMerchantID($event)"
      (birthday)="getBirthday($event)"
    >
    </app-customer-prospect-profile-tabs>
  </div>

  <div class="row clearfix profile-column">
    <button
      class="btn-pos"
      [routerLink]="['/merchant/', { outlets: { page: ['customer-prospect-list-overview'] } }]"
      [routerLinkActive]="['active']"
      (click)="goBack()"
      mat-raised-button
      color="primary"
    >
      <mat-icon>chevron_left</mat-icon>
      <span>Go Back</span>
    </button>
  </div>
</mat-sidenav-container>
