import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSortModule } from '@angular/material/sort';
import { NgPipesModule } from 'ngx-pipes';
import { SharedModule } from '../../shared/shared.module';
import { OperatorModule } from '../operator/operator.module';
import { TagsRoutingModule } from './shared/tags-routing.module';
import { TagsService } from './shared/tags.service';
import { TagCreateEditCardComponent } from './tag-create-edit-card/tag-create-edit-card.component';
import { TagCreateEditComponent } from './tag-create-edit/tag-create-edit.component';
import { TagPatientManagerComponent } from './tag-patient-manager/tag-patient-manager.component';
import { TagSelectComponent } from './tag-select/tag-select.component';
import { TagsListGlobalComponent } from './tags-list-global/tags-list-global.component';
import { TagsListOverviewComponent } from './tags-list-overview/tags-list-overview.component';
import { TagsListComponent } from './tags-list/tags-list.component';
import { TagsViewModalComponent } from './tags-view-modal/tags-view-modal.component';
import { TagsViewComponent } from './tags-view/tags-view.component';

@NgModule({
  imports: [
    CommonModule,
    TagsRoutingModule,
    CdkTableModule,
    SharedModule,
    MatSortModule,
    NgPipesModule,
    OperatorModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  declarations: [
    TagsListOverviewComponent,
    TagsListGlobalComponent,
    TagsListComponent,
    TagCreateEditComponent,
    TagsViewComponent,
    TagCreateEditCardComponent,
    TagPatientManagerComponent,
    TagsViewModalComponent,
    TagSelectComponent,
  ],
  exports: [
    TagsListOverviewComponent,
    TagsListGlobalComponent,
    TagsListComponent,
    TagCreateEditComponent,
    TagsViewComponent,
    TagsViewModalComponent,
    TagCreateEditCardComponent,
    TagPatientManagerComponent,
    TagSelectComponent,
  ],
  entryComponents: [TagsViewModalComponent],
  providers: [TagsService],
})
export class TagsModule {}
