import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { HelperService } from '../shared/helper.service';

@Component({
  selector: 'app-help-page-create',
  templateUrl: './help-page-create.component.html',
  styleUrls: ['./help-page-create.component.css'],
})
export class HelpPageCreateComponent implements OnInit {
  helpPage = {
    category: '',
    topic: '',
    pageTitle: '',
    content: '',
  };
  @Output() added = new EventEmitter();

  constructor(private helperService: HelperService) {}

  ngOnInit() {}

  addSimplePage() {
    this.helpPage.content =
      'Please Insert the content from the Admin Page ,<br><strong> Category:</strong>' +
      this.helpPage.category +
      '<br><strong>  Topic:</strong>' +
      this.helpPage.topic +
      '<br><strong>  Page Title:</strong>' +
      this.helpPage.pageTitle;
  }
}
