import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ColorPalette } from '../../types/color-palette';

@Component({
  selector: 'app-color-palette',
  templateUrl: './color-palette.component.html',
  styleUrls: ['./color-palette.component.css'],
})
export class ColorPaletteComponent implements OnInit {
  @Output()
  getSCSSPalette = new EventEmitter();

  @Input()
  displayPalette = false;

  @Input()
  displayCoreColor = false;

  @Input()
  isPalette = true;

  @Input()
  canEditPalette = true;

  @Output()
  getCoreColor = new EventEmitter();

  @Input() position = 'top';

  @Output()
  getPalette = new EventEmitter();

  @Input()
  coreColor = '#000';
  palette = [];

  constructor() {}

  ngOnInit() {
    if (this.coreColor) {
      this.changeColor();
    }
  }

  getContrast(b) {
    if (b == false) {
      return '#fff';
    } else {
      return '#000';
    }
  }

  changeColor() {
    const v = new ColorPalette(this.coreColor);
    this.palette = v.getPalette();

    if (this.isPalette == true) {
      this.getSCSSPalette.emit(this.buildPaletteString(this.palette));
    } else {
      this.getSCSSPalette.emit(this.coreColor);
    }

    this.getCoreColor.emit(this.coreColor);
    this.getPalette.emit(this.palette);
  }

  logcolor(e) {}

  sendPalette() {
    this.getPalette.emit(this.palette);

    if (this.isPalette == true) {
      this.getSCSSPalette.emit(this.buildPaletteString(this.palette));
    } else {
      this.getSCSSPalette.emit(this.coreColor);
    }
  }

  buildPaletteString(p) {
    let body = '';
    let constrast = '';
    let result;

    for (let i = 0; i < p.length; i++) {
      body = body + p[i]['name'] + ':' + p[i]['hex'] + ',';
      constrast = constrast + p[i]['name'] + ':' + this.getContrast(p[i]['darkContrast']) + ',';
    }

    result = '(' + body + 'contrast:(' + constrast + '));';

    return result;
  }
}
