<div class="row clearfix">
  <div class="card-header primary-gradient-img clearfix inModal">
    <div class="row clearfix">
      <button class="btn-close btn-clear mat-button white" *ngIf="isModal == true" (click)="closeModal()">
        <mat-icon>clear</mat-icon>
      </button>
      <div class="row titleArea clearfix">
        <mat-icon class="pull-left">lock</mat-icon>
        <h2 class="summary-header rm-m">
          <span *ngIf="isEdit == false">Create new email</span>
          <span *ngIf="isEdit == true">Update email</span>
          <span class="subLabel"></span>
        </h2>
      </div>
    </div>
  </div>
  <div class="row clearfix" *ngIf="isPrimary == true">
    <p class="small notice">
      <mat-icon>help</mat-icon>
      If you wish to set a new primary email, please create a new email address and select that as 'primary'.
    </p>
  </div>

  <div class="row clearfix flex">
    <mat-icon class="fas fa-tag inline"></mat-icon>

    <mat-form-field class="full-width" [hidden]="isEdit == false" appearance="outline">
      <mat-label>Type</mat-label>
      <mat-select [(ngModel)]="label" (selectionChange)="getEmailByType(label)" placeholder="Type" name="emailType">
        <mat-option *ngFor="let type of emailTypes" [value]="type">
          <span>{{ type | titlecase }}</span>
        </mat-option>
        <hr/>
        <mat-option [value]="null"> Create new label</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="full-width" [hidden]="isEdit == true" appearance="outline">
      <mat-label>Label</mat-label>
      <input [(ngModel)]="label" [disabled]="isEdit == true" matInput name="label" placeholder="Label" required/>
    </mat-form-field>

    <mat-checkbox class="full-width labelCheckbox" [(ngModel)]="isPrimary" [disabled]="isPrimary" name="isPrimary "
    >Set to primary
    </mat-checkbox>
  </div>

  <div class="row clearfix flex">
    <mat-icon class="fas fa-envelope inline"></mat-icon>
    <app-email-input
      class="full-width"
      [clear]="clearEmail"
      [label]="'Email'"
      [required]="isRequired"
      [email]="myEmail"
      (getEmail)="getEmail($event)"
      (validate)="validateEmailEvent($event)"
    >
    </app-email-input>
  </div>

  <button class="" *ngIf="isPrimary == false" (click)="removeEmailByLabel()" mat-raised-button color="primary">
    Delete
  </button>

  <button
    class="pull-right"
    *ngIf="isEdit == false"
    [disabled]="!label || validateEmail == false"
    (click)="create()"
    mat-raised-button
    color="accent"
  >
    Create
  </button>
  <button class="pull-right" *ngIf="isEdit == true" (click)="modify()" mat-raised-button color="accent">Update</button>
</div>
