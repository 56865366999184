import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { environment } from 'environments/environment';
import { CurrencyMaskInputMode } from 'ngx-currency';
import { NgxMonacoEditorConfig } from 'ngx-monaco-editor';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { httpFactory } from './types/http.factory';
export const customCurrencyMaskConfig = {
    align: 'right',
    allowNegative: false,
    allowZero: true,
    decimal: '.',
    precision: 2,
    prefix: '$',
    suffix: '',
    thousands: ',',
    nullable: true,
    min: null,
    max: null,
    inputMode: CurrencyMaskInputMode.NATURAL,
};
// AoT requires an exported function for factories
export function HttpLoaderFactory(http) {
    return new TranslateHttpLoader(http, environment.nodeUrl + '/translate/cached/', '.json');
}
const DEFAULT_SWIPER_CONFIG = {
    direction: 'horizontal',
    slidesPerView: 'auto',
};
const monacoConfig = {
    baseUrl: '/assets',
    defaultOptions: { scrollBeyondLastLine: false },
};
const ɵ0 = adapterFactory, ɵ1 = DEFAULT_SWIPER_CONFIG, ɵ2 = { useUtc: true }, ɵ3 = httpFactory;
export class SharedModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3 };
