import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { SessionsService } from '../../session/shared/sessions.service';
import { AuthenticationService } from '../shared/authentication.service';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-password-change',
  templateUrl: './password-change.component.html',
  styleUrls: ['./password-change.component.css'],
  animations: [
    trigger('simpleFadeAnimation', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [style({ opacity: 0, height: 0 }), animate(300)]),
      transition(':leave', animate(300, style({ opacity: 0, height: '*' }))),
    ]),
  ],
})
export class PasswordChangeComponent implements OnInit {
  @Input()
  title = 'Update Password';

  @Input()
  buttonText = 'Change';

  @Input()
  isExistingPassword = true;
  isLogout= false;
  oldPassword;
  newPassword1;
  newPassword2;
  isModal=false
  canClose=false

  existingTitle = "Existing Password"


  disableButton=false;
  showPassword1=false;
  showPassword2=false;
  showPassword3=false;

  @Output()
  close = new EventEmitter()

  @Input()
  description =
    'Update your password using the below fields. Please select a password that is at least 8 characters long, and has at least one number and one upper case letter.';
  isPromoterOrAdmin = false;

  constructor(
    private SessionsService: SessionsService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private AuthenticationService: AuthenticationService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.title) {
        this.title = data.title;
      }
      if (data.existingTitle) {
        this.existingTitle = data.existingTitle;
      }


      if (data.buttonText) {
        this.buttonText = data.buttonText;
      }
      if (data.description) {
        this.description = data.description;
      }

      if (data.isModal===true) {
        this.isModal = data.isModal;
      } else  if (data.isModal===false) {
        this.isModal = data.isModal;
      }

      if (data.isLogout===true) {
        this.isLogout = data.isLogout;
      } else  if (data.isLogout===false) {
        this.isLogout = data.isLogout;
      }


      if (data.canClose===true) {
        this.canClose = data.canClose;
      }  if (data.canClose===false) {
        this.canClose = data.canClose;
      }

    }
    this.AuthenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;
    });
  }

  ngOnInit() {}

  updatePassword() {
    if (this.isPromoterOrAdmin == true) {
      this.AuthenticationService.isEPmaster().subscribe((res) => {
        if (res == true) {
          const confirm = new ConfirmDialog(
            'fas fa-info',
            'Do You want to proceed ?',
            'Your account is used as EP Master, we will have to log you out after changing your password',
            'No',
            'Yes'
          );

          const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
            data: confirm,
          });
          ref.componentInstance.onConfirm.subscribe((confirmation) => {
            if (confirmation === false) {
              ref.close();
            } else {
              const payLoad = {
                currentPassword: this.oldPassword,
                newPassword: this.newPassword1,
                newPasswordConfirmed: this.newPassword2,
              };

              this.SessionsService.changePassword(payLoad).subscribe((res) => {
                if (res) {
                  this.AuthenticationService.logout().subscribe(
                    (res) => {
                      ref.close();

                      this.closeEvent()

                      this.router.navigate(['/login']);
                    },
                    (err: any) => {
                      console.log(err);
                    }
                  );
                } else {
                  ref.close();
                }
              });
            }
          });
        } else {
          this.change();
        }
      });
    } else {
      this.change();
    }
  }

  closeEvent()
  {
    this.close.emit(true)
  }
  change() {
    if (this.oldPassword && this.newPassword1 && this.newPassword2) {
      const payLoad = {
        currentPassword: this.oldPassword,
        newPassword: this.newPassword1,
        newPasswordConfirmed: this.newPassword2,
      };

      this.disableButton=true;
      this.SessionsService.changePassword(payLoad).subscribe((res) => {


        NotifyAppComponent.displayToast(
          'success',
          'Successful operation',
          'The password has been successfully updated, we will redirect you to login page'
        );


        this.AuthenticationService.logout().subscribe(
          (res) => {

            this.disableButton=false;

            this.closeEvent()

            this.router.navigate(['/login']);
          },
          (err: any) => {
            this.disableButton=false;

            console.log(err);
          }
        );
      },
      (err1: any) => {

        this.disableButton=false;
      });
    }
  }

  changeHide1()
  {
    this.showPassword1=!this.showPassword1;
  }
  changeHide2()
  {
    this.showPassword2=!this.showPassword2;
  }
  changeHide3()
  {
    this.showPassword3=!this.showPassword3;
  }

}
