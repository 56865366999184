import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SessionsService } from '../../../core/session/shared/sessions.service';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { TestimonyService } from '../shared/testimony.service';

@Component({
  selector: 'app-testimony-create-edit',
  templateUrl: './testimony-create-edit.component.html',
  styleUrls: ['./testimony-create-edit.component.css'],
})
export class TestimonyCreateEditComponent implements OnInit {
  @Input()
  testimonyID = null;

  title = 'Testimonial';
  rating = 5;
  content;
  userID;

  avatarImages = [
    {
      id: 1,
      name: 'Robert',
      src: 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/avatars/001.png',
    },
    {
      id: 2,
      name: 'Mary',
      src: 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/avatars/002.png',
    },
    {
      id: 3,
      name: 'Terry',
      src: 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/avatars/003.png',
    },
    {
      id: 4,
      name: 'Karen',
      src: 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/avatars/004.png',
    },
    {
      id: 5,
      name: 'Jimbo',
      src: 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/avatars/005.png',
    },
    {
      id: 6,
      name: 'Reginald',
      src: 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/avatars/006.png',
    },
    {
      id: 7,
      name: 'Mal',
      src: 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/avatars/007.png',
    },
    {
      id: 8,
      name: 'Bingo',
      src: 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/avatars/008.png',
    },
    {
      id: 9,
      name: 'Johnathan',
      src: 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/avatars/009.png',
    },
    {
      id: 10,
      name: 'Kim',
      src: 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/avatars/010.png',
    },
    {
      id: 11,
      name: 'Doug',
      src: 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/avatars/011.png',
    },
    {
      id: 12,
      name: 'Jake',
      src: 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/avatars/012.png',
    },
    {
      id: 13,
      name: 'Christy',
      src: 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/avatars/013.png',
    },
    {
      id: 14,
      name: 'Billy',
      src: 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/avatars/014.png',
    },
    {
      id: 15,
      name: 'Frank',
      src: 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/avatars/015.png',
    },
    {
      id: 16,
      name: 'Belinda',
      src: 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/avatars/016.png',
    },
    {
      id: 17,
      name: 'Nate',
      src: 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/avatars/017.png',
    },
    {
      id: 18,
      name: 'Sarah',
      src: 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/avatars/018.png',
    },
    {
      id: 19,
      name: 'Richard',
      src: 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/avatars/019.png',
    },
    {
      id: 20,
      name: 'Chloe',
      src: 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/avatars/020.png',
    },
    {
      id: 21,
      name: 'Natalie',
      src: 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/avatars/021.png',
    },
    {
      id: 22,
      name: 'Bobo',
      src: 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/avatars/022.png',
    },
    {
      id: 23,
      name: 'Alex',
      src: 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/avatars/023.png',
    },
    {
      id: 24,
      name: 'Laura',
      src: 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/avatars/024.png',
    },
    {
      id: 25,
      name: 'Anne',
      src: 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/avatars/025.png',
    },
    {
      id: 26,
      name: 'Sore Sally',
      src: 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/avatars/026.png',
    },
    {
      id: 27,
      name: 'Chris',
      src: 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/avatars/027.png',
    },
    {
      id: 28,
      name: 'Wendy',
      src: 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/avatars/028.png',
    },
    {
      id: 29,
      name: 'Bob',
      src: 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/avatars/029.png',
    },
    {
      id: 30,
      name: 'Watson',
      src: 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/avatars/030.png',
    },
    {
      id: 31,
      name: 'Festive Frank',
      src: 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/avatars/031.png',
    },
    {
      id: 32,
      name: 'Hannah',
      src: 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/avatars/032.png',
    },
    {
      id: 33,
      name: 'Lucy',
      src: 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/avatars/033.png',
    },
    {
      id: 34,
      name: 'Emma',
      src: 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/avatars/034.png',
    },
    {
      id: 35,
      name: 'Phil',
      src: 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/avatars/035.png',
    },
    {
      id: 36,
      name: 'Sandra',
      src: 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/avatars/036.png',
    },
    {
      id: 37,
      name: 'Bill',
      src: 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/avatars/037.png',
    },
    {
      id: 38,
      name: 'Louise',
      src: 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/avatars/038.png',
    },
    {
      id: 39,
      name: 'Anna',
      src: 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/avatars/039.png',
    },
    {
      id: 40,
      name: 'Hughbert',
      src: 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/avatars/040.png',
    },
    {
      id: 41,
      name: 'Trish',
      src: 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/avatars/041.png',
    },
    {
      id: 42,
      name: 'Felicity',
      src: 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/avatars/042.png',
    },
    {
      id: 43,
      name: 'Caroline',
      src: 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/avatars/043.png',
    },
    {
      id: 44,
      name: 'Leonardo',
      src: 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/avatars/044.png',
    },
    {
      id: 45,
      name: 'Denise',
      src: 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/avatars/045.png',
    },
    {
      id: 46,
      name: 'Amy',
      src: 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/avatars/046.png',
    },
    {
      id: 47,
      name: 'Charlie',
      src: 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/avatars/047.png',
    },
    {
      id: 48,
      name: 'Joel',
      src: 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/avatars/048.png',
    },
    {
      id: 49,
      name: 'Cindy',
      src: 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/avatars/049.png',
    },
    {
      id: 50,
      name: 'Ninja Nigel',
      src: 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/avatars/050.png',
    },
  ];

  selectedAvatar = 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/avatars/001.png';

  profilePic;

  @Output() close = new EventEmitter();

  constructor(
    private sessionService: SessionsService,
    private testimonyService: TestimonyService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      this.testimonyID = data;
    }
  }

  ngOnInit() {
    if (this.testimonyID) {
      this.testimonyService.getOneTestimony(this.testimonyID).subscribe((res) => {
        this.title = res['title'];
        this.rating = res['starts'];
        this.content = res['content'];
      });
    }
    // this.sessionService.getProfilePic().subscribe((res) => {
    //   if (res) {
    //     this.profilePic = res;
    //   }
    // });
  }

  createTestimony() {
    const payload = {
      title: this.title,
      content: this.content,
      starts: this.rating,
      fileID: this.selectedAvatar,
    };
    if (this.profilePic) {
      payload.fileID = '';
    }
    this.testimonyService.createTestimony(payload).subscribe((res) => {
      if (res) {
        this.close.emit(true);
        NotifyAppComponent.displayToast(
          'success',
          'Successful operation',
          'You have successfully create new testimony'
        );
      }
    });
  }

  closeModalEvent() {
    this.close.emit(true);
  }

  getRating(e) {
    this.rating = e;
  }
}
