import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../../common/core/authentication/shared/authentication.service';
import { BetaInfoComponent } from '../../../../common/core/helper/beta-info/beta-info.component';
import { ModuleViewComponent } from '../../../../common/core/module/module-view/module-view.component';
import { ModuleService } from '../../../../common/core/module/shared/module.service';
import { ContractService } from '../../../../common/feature/contract/shared/contract.service';
import { CustomerProspectCreateComponent } from '../../../../common/feature/customer-prospect/customer-prospect-create/customer-prospect-create.component';
import { InvitationService } from '../../../../common/feature/invitation/shared/services/invitation.service';
import { MarketingNewCampaignModalComponent } from '../../../../common/feature/marketing/marketing-new-campaign-modal/marketing-new-campaign-modal.component';
import { MerchantLinkLookupComponent } from '../../../../common/feature/merchant-link/merchant-link-lookup/merchant-link-lookup.component';
import { MerchantLookupComponent } from '../../../../common/feature/merchant/merchant-lookup/merchant-lookup.component';
import { verticalScaleFadeAnimation } from '../../../../common/shared/animations/vertical-scale-fade.animation';
import { CalendlyComponent } from '../../../../common/shared/components/calendly/calendly.component';
import { ConfirmDialogComponent } from '../../../../common/shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../../common/shared/components/root-component/root-component.component';
import { HeaderService } from '../../../../common/shared/services/header.service';
import { SideMenuService } from '../../../../common/shared/services/side-menu.service';
import { UtilsService } from '../../../../common/shared/services/utils.service';
import { ConfirmDialog } from '../../../../common/shared/types/confirm-dialog';
import { ModuleOption, Settings } from '../../../../common/shared/types/settings';
import { UtilsClass } from '../../../../common/shared/types/utils/utils.class';
import { AppStateService } from '../../../../common/core/app-state/app-state.service';
import { LandingPageNewModalComponent } from '../../../../common/feature/document/landing-page-new-modal/landing-page-new-modal.component';
import { VersionUpdateComponent } from '../../../../common/feature/merchant/version-update/version-update.component';
import { ContactLookupComponent } from '../../../../common/feature/contact/contact-lookup/contact-lookup.component';
import { SmsConversationComponent } from '../../../../common/feature/message/sms-conversation/sms-conversation.component';
import { ContactCreateEditModalComponent } from '../../../../common/feature/contact/contact-create-edit-modal/contact-create-edit-modal.component';
import { SmsPromoterComponent } from '../../../../common/feature/message/sms-promoter/sms-promoter.component';
import { DentistInviteCreateComponent } from '../../../../common/feature/dentist/dentist-invite-create/dentist-invite-create.component';
// import { TranslationManagementCreateComponent } from '../../../../common/feature/translation-management/translation-management-create/translation-management-create.component';
// import { DocumentListThumbnailComponent } from '../../../../common/feature/document/document-list-thumbnail/document-list-thumbnail.component';

@Component({
  selector: 'app-merchant-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.css'],
  animations: [verticalScaleFadeAnimation],
})
export class SideMerchantMenuComponent implements OnInit {
  guid: any;
  role = null;

  showPromoterMenu = true;
  newInvitationButton = false;
  context = Settings.global['context'];
  mainModuleLoaded = false;
  somethingNew: boolean = JSON.parse(localStorage.getItem('somethingNew')) || true;

  isModuleStaffActive = Settings.global['isModuleStaffActive'];
  isModulePatientSMSActive = Settings.global['isModulePatientSMSActive'];
  isModuleFeeFundingActive = Settings.global['isModuleFeeFundingActive'];
  isModuleChangeLogActive = Settings.global['isModuleChangeLogActive'];
  isModuleTreatmentPlanActive = Settings.global['isModuleTreatmentPlanActive'];
  isModuleFacilityActive = Settings.global['isModuleFacilityActive'];
  isModuleSettlementsActive = Settings.global['isModuleSettlementsActive'];
  isModuleMembershipActive = Settings.global['isModuleMembershipActive'];
  isModulePatientPortalAccessActive = Settings.global['isModulePatientPortalAccessActive'];
  isModuleMerchantInformedConsentActive = Settings.global['isModuleMerchantInformedConsentActive'];
  isModuleInformedConsentActive = Settings.global['isModuleInformedConsentActive'];
  isModulePatientsActive = Settings.global['isModulePatientsActive'];
  isModulePerformanceActive = Settings.global['isModulePerformanceActive'];
  isModuleAppointmentOldActive = Settings.global['isModuleAppointmentOldActive'];
  isModuleReferralActive = Settings.global['isModuleReferralActive'];
  isModuleCustomerActive = Settings.global['isModuleCustomerActive'];
  isModuleContractActive = Settings.global['isModuleContractActive'];
  isModuleThirdPartyActive = Settings.global['isModuleThirdPartyActive'];
  isModuleAdvancedTreatmentActive = Settings.global['isModuleAdvancedTreatmentActive'];
  isModuleOverviewActive = Settings.global['isModuleOverviewActive'];
  isModuleAppointmentActive = Settings.global['isModuleAppointmentActive'];
  isModuleAiScansActive = Settings.global['isModuleAiScansActive'];
  isModuleSMSOffer = Settings.global['isModuleSMSOfferActive'];
  isModuleDocumentLinkActive = Settings.global['isModuleDocumentLinkActive'];
  isModuleMedicationActive = Settings.global['isModuleMedicationActive'];
  isModuleMarketingActive = Settings.global['isModuleMarketingActive'];
  isModuleBulkSMSActive = Settings.global['isModuleBulkSMSActive'];

  isModuleExtendedProductsActive = Settings.global['isModuleExtendedProductsActive'];
  isModuleEmergercyActive = Settings.global['isModuleEmergercyActive'];
  isModuleCustomMessages = Settings.global['isModuleCustomMessagesActive'];
  isModuleBeneficiaryActive = Settings.global['isModuleBeneficiaryActive'];
  isModuleTreatmentGroupActive = Settings.global['isModuleTreatmentGroupActive'];
  isModulePatientHistoryActive = Settings.global['isModulePatientHistoryActive'];
  isModuleNoteFeaturesActive = Settings.global['isModuleNoteFeaturesActive'];
  isModuleSMSOfferActive = Settings.global['isModuleSMSOfferActive'];
  isModuleExperimentalFeaturesActive = Settings.global['isModuleExperimentalFeaturesActive'];
  isModuleMakeOverActive = Settings.global['isModuleMakeOverActive'];
  isModuleTreatmentTemplateActive = Settings.global['isModuleTreatmentTemplateActive'];
  isModuleNewPatientInvitationActive = Settings.global['isModuleNewPatientInvitationActive'];
  isModuleTipsActive = Settings.global['isModuleTipsActive'];
  isModuleMarketingSocialMediaActive = Settings.global['isModuleMarketingSocialMediaActive'];
  isModuleWikiActive = Settings.global['isModuleWikiActive'];
  isModuleGoogleReviewActive = Settings.global['isModuleGoogleReviewActive'];
  isModulePracticeLandingPageActive = Settings.global['isModulePracticeLandingPageActive'];

  isModuleSelfServicePageActive = Settings.global['isModuleSelfServicePageActive'];

  brandName = Settings.global['brandName'];

  canHasDiscount = true;

  settings = Settings.global;

  promoterViewAs = 'promoter';
  promoterViewAsRaw = 'promoter';
  selectedMerchantID;
  selectedTradingAs;

  invitationStatus = [];
  contractStatus = [];

  modules: any = {};
  sessionType;
  currentPractice;
  isAppointmentDisabled = false;
  healthHistoryActive = true;

  currentPlanID;

  // ADMIN
  isAdmin = true;

  isAdminOrPromoter = false;

  step = 0;
  isMerchantAdmin = false;
  appState$ = this.appStateService.getAppState$();

  subPlanLabel;

  constructor(
    private authenticationService: AuthenticationService,
    private utilService: UtilsService,
    private invitationService: InvitationService,
    private contractService: ContractService,
    private moduleService: ModuleService,
    private router: Router,
    private appStateService: AppStateService
  ) {}

  ngOnInit() {
    setTimeout(() => {
      this.newInvitationButton = true;
    }, 1000);

    this.authenticationService.getCurrentPlan().subscribe((res) => {
      if (res) {
        this.currentPlanID = res;
      }
    });
    this.authenticationService.isMerchantAdmin().subscribe((isMerchantAdmin) => {
      this.isMerchantAdmin = isMerchantAdmin;
    });
    this.authenticationService.getSessionType().subscribe((st) => {
      this.sessionType = st;
      UtilsClass.updateSideMenu.subscribe((res) => {
        this.settings = Settings.global;

        this.setup();
      });

      UtilsClass.updateSideMenuOneModule.subscribe((res) => {
        this.updateOneModulEvent(res);
      });

      UtilsClass.updateSideMenuAllModule.subscribe((res) => {
        this.updateAllModulEvent(res);
      });

      RootAppComponent.updateSideMenuAllModule.subscribe((res) => {
        this.updateAllModulEvent(res);
      });

      RootAppComponent.setCurrentAccess.subscribe((res) => {
        if (res) {
          this.setAccess(res);
        }
      });

      this.setup();
    });
  }

  versionReport() {
    const ref = RootAppComponent.dialog.open(VersionUpdateComponent, {
      data: {},
      width: '80vw',

      panelClass: 'noCard',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  updateAllModulEvent(res) {
    if (res && res.length > 0) {
      for (let i = 0; i < res.length; i++) {
        const item = res[i];
        this.updateOneModulEvent(item);
      }
    }
  }

  updateOneModulEvent(item) {
    if (item && item.Code) {
      this.modules['isModule' + item.Code + 'Active'] = {
        isActive: false,
        isBeta: false,
        icon: null,
        label: null,
      };

      if (item['Status.Code'] === 'EnableForAll' || item['Status.Code'] === 'Beta') {
        this.modules['isModule' + item.Code + 'Active'].isActive = true;

        if (item['Status.Code'] === 'Beta') {
          this.modules['isModule' + item.Code + 'Active'].isBeta = true;
        }
      }

      if (item['Label']) {
        this.modules['isModule' + item.Code + 'Active'].label = item['Label'];
      }

      if (item['Icon']) {
        this.modules['isModule' + item.Code + 'Active'].icon = item['Icon'];
      }
    }
  }

  setup() {
    AuthenticationService.healthHistoryAccess.subscribe((r) => {
      this.healthHistoryActive = r;
    });

    AuthenticationService.appoinmentAccess.subscribe((r) => {
      this.isAppointmentDisabled = r;
    });
    setTimeout(() => {
      this.moduleService
        .getModuleListHidden({ fields: 'Code,Status.Code,Icon,Label' }, this.sessionType)
        .subscribe((res) => {
          if (res && res.length > 0) {
            this.updateAllModulEvent(res);
          }

          this.mainModuleLoaded = true;
        });
    }, 1000);

    this.authenticationService.getCurrentPractice().subscribe((currentPractice) => {
      if (currentPractice) {
        this.currentPractice = currentPractice;
        this.checkBookingEngine();
        this.checkHH();
      }
    });

    this.authenticationService.getCurrentUser().subscribe((data) => {
      this.utilService.getCurrentAccess().subscribe((access) => {
        if (access) {
          this.setAccess(access);
        }

        if (data && data.data && data.data.subscriptionPlanLabel) {
          this.subPlanLabel = data.data.subscriptionPlanLabel;
        }
        if (data && data.success && data.data['Role'] && data.data['Role']) {
          this.role = data.data['Role'];
        }

        this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
          this.isAdminOrPromoter = res;

          if (this.isAdminOrPromoter == true) {
            const _mID = JSON.parse(localStorage.getItem('selectedMerchant'));

            const payloadInvitation = {
              selectProduct: false,
              selectYears: false,
              selectDays: false,
              selectMonths: false,
              selectDentist: false,
              selectMerchant: false,
              selectStatus: true,
            };

            this.invitationService
              .getStatistics(payloadInvitation, this.isAdminOrPromoter, false, true)
              .subscribe((res) => {
                if (res) {
                  this.invitationStatus = res.status;
                }
              });

            const payloadContract = {
              selectStatus: true,
              selectGlobal: false,
            };

            this.contractService.statistics(payloadContract).subscribe((res) => {
              if (res) {
                this.contractStatus = res.status;
              }
            });
          }
        });
      });
    });
  }

  getBadgeNumber(status: string, type) {
    let result = 0;

    let typeList = [];

    if (type == 'invitation' && this.invitationStatus.length > 0) {
      typeList = this.invitationStatus;
    } else if (type == 'contract' && this.contractStatus.length > 0) {
      typeList = this.contractStatus;
    }

    if (typeList.length > 0) {
      const resultObj = typeList.find((x) => x.ID == status);

      if (resultObj) {
        result = resultObj['Count(*)'];
      }
    }

    return result;
  }

  setAccess(_access) {
    if (_access) {
      this.isModuleStaffActive = _access['isModuleStaffActive'];
      this.isModulePatientSMSActive = _access['isModulePatientSMSActive'];
      this.isModuleFeeFundingActive = _access['isModuleFeeFundingActive'];
      this.isModuleChangeLogActive = _access['isModuleChangeLogActive'];
      this.isModuleTreatmentPlanActive = _access['isModuleTreatmentPlanActive'];
      this.isModuleFacilityActive = _access['isModuleFacilityActive'];
      this.isModuleSettlementsActive = _access['isModuleSettlementsActive'];
      this.isModuleMembershipActive = _access['isModuleMembershipActive'];
      this.isModulePatientPortalAccessActive = _access['isModulePatientPortalAccessActive'];
      this.isModuleMerchantInformedConsentActive = _access['isModuleMerchantInformedConsentActive'];
      this.isModuleInformedConsentActive = _access['isModuleInformedConsentActive'];
      this.isModulePatientsActive = _access['isModulePatientsActive'];
      this.isModulePerformanceActive = _access['isModulePerformanceActive'];
      this.isModuleAppointmentOldActive = _access['isModuleAppointmentOldActive'];
      this.isModuleReferralActive = _access['isModuleReferralActive'];
      this.isModuleCustomerActive = _access['isModuleCustomerActive'];
      this.isModuleContractActive = _access['isModuleContractActive'];
      this.isModuleThirdPartyActive = _access['isModuleThirdPartyActive'];
      this.isModuleAdvancedTreatmentActive = _access['isModuleAdvancedTreatmentActive'];
      this.isModuleOverviewActive = _access['isModuleOverviewActive'];
      this.isModuleAppointmentActive = _access['isModuleAppointmentActive'];
      this.isModuleAiScansActive = _access['isModuleAiScansActive'];
      this.isModuleSMSOffer = _access['isModuleSMSOfferActive'];
      this.isModuleDocumentLinkActive = _access['isModuleDocumentLinkActive'];
      this.isModuleMedicationActive = _access['isModuleMedicationActive'];
      this.isModuleMarketingActive = _access['isModuleMarketingActive'];
      this.isModuleBulkSMSActive = _access['isModuleBulkSMSActive'];

      this.isModuleExtendedProductsActive = _access['isModuleExtendedProductsActive'];
      this.isModuleEmergercyActive = _access['isModuleEmergercyActive'];
      this.isModuleCustomMessages = _access['isModuleCustomMessagesActive'];
      this.isModuleBeneficiaryActive = _access['isModuleBeneficiaryActive'];
      this.isModuleTreatmentGroupActive = _access['isModuleTreatmentGroupActive'];
      this.isModulePatientHistoryActive = _access['isModulePatientHistoryActive'];
      this.isModuleNoteFeaturesActive = _access['isModuleNoteFeaturesActive'];
      this.isModuleSMSOfferActive = _access['isModuleSMSOfferActive'];
      this.isModuleExperimentalFeaturesActive = _access['isModuleExperimentalFeaturesActive'];
      this.isModuleMakeOverActive = _access['isModuleMakeOverActive'];
      this.isModuleTreatmentTemplateActive = _access['isModuleTreatmentTemplateActive'];
      this.isModuleNewPatientInvitationActive = _access['isModuleNewPatientInvitationActive'];
      this.isModuleTipsActive = _access['isModuleTipsActive'];
      this.isModuleMarketingSocialMediaActive = _access['isModuleMarketingSocialMediaActive'];
      this.isModuleWikiActive = _access['isModuleWikiActive'];
      this.isModuleGoogleReviewActive = _access['isModuleGoogleReviewActive'];
      this.isModulePracticeLandingPageActive = _access['isModulePracticeLandingPageActive'];
      this.isModuleSelfServicePageActive = _access['isModuleSelfServicePageActive'];
    }
  }

  setStep(index: number) {
    this.step = index;
  }

  newChangeLogEvent() {
    this.somethingNew = false;

    localStorage.setItem('somethingNew', JSON.stringify(this.somethingNew));
  }

  newPatientInv() {
    this.invitationService.getGUID().subscribe((res) => {
      this.guid = res;
      this.router.navigate(['/merchant', { outlets: { page: ['new-invitation'] } }]);
    });
  }

  bulkSMS() {
    this.router.navigate(['/merchant', { outlets: { page: ['message-group-create'] } }]);
  }
  newMarketingCampaign() {
    const ref = RootAppComponent.dialog.open(MarketingNewCampaignModalComponent, {
      data: {
        isCommunication: true,
      },
      width: '750px',
    });
    ref.componentInstance.closeModal.subscribe((res) => {
      ref.close();
    });
  }

  goBackEvent() {
    SideMenuService.goBack.emit(true);
  }

  viewAsMerchant() {
    const _mID = JSON.parse(localStorage.getItem('selectedMerchant'));

    const ref = RootAppComponent.dialog.open(MerchantLookupComponent, {
      data: {
        saveLocalStorage: true,
        merchantID: _mID,
      },
      width: '650px',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();

      this.viewAsPromoter();
    });

    ref.backdropClick().subscribe((res) => {
      ref.close();

      this.viewAsPromoter();
    });

    ref.componentInstance.result.subscribe((res) => {
      if (res) {
        ref.close();
        this.promoterViewAs = 'merchant';
        this.promoterViewAsRaw = 'merchant';
        this.router.navigate(['/merchant']);
        this.utilService.getGlobalAccess(res).subscribe((access) => {
          if (access) {
            this.setAccess(access);
            access['merchantID'] = res.merchantID;
            this.selectedMerchantID = res.merchantID;
            this.selectedTradingAs = res.tradingAs;
            access['promoterViewAs'] = this.promoterViewAs;
            HeaderService.setPromoterViewAsObject(access);
          }
        });
      }
    });
  }

  viewAsPromoter() {
    this.promoterViewAsRaw = 'promoter';
    this.promoterViewAs = 'promoter';

    // this.router.navigate(['/merchant'])

    this.utilService.getCurrentAccess().subscribe((access) => {
      if (access) {
        this.setAccess(access);

        localStorage.setItem('selectedMerchant', null);
        localStorage.removeItem('selectedMerchant');
        HeaderService.setPromoterViewAsObject({
          promoterViewAs: this.promoterViewAs,
        });
      }
    });
  }

  changePromoterViewAs() {
    if (this.promoterViewAsRaw == 'merchant') {
      this.viewAsMerchant();
    } else {
      this.viewAsPromoter();
    }
  }

  booking() {
    const ref = RootAppComponent.dialog.open(CalendlyComponent, {
      width: '650px',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  checkBookingEngine() {
    if (this.currentPractice && this.currentPractice['ThirdPartyBooking_URL']) {
      this.isAppointmentDisabled = true;
    } else {
      this.isAppointmentDisabled = false;
    }
  }

  staffLook() {
    const ref = RootAppComponent.dialog.open(ContactLookupComponent, {
      data: {
        onlyMyAccount: true,
        displayChatButton: true,
        useStrict: true,
      },
      width: '650px',
    });
    ref.componentInstance.closeModal.subscribe((data) => {
      ref.close();
    });
    ref.componentInstance.contactChat.subscribe((data) => {
      if (data && data.cardID) {
        const ref2 = RootAppComponent.dialog.open(SmsConversationComponent, {
          data: {
            cardID: data.cardID,

            isContactStaff: true,
            singleChat: true,
          },
          panelClass: 'bigger-screen',
          width: '70%',
          height: '95vh',
        });

        ref2.backdropClick().subscribe((r) => {});
        const sub = ref2.componentInstance.close.subscribe((data) => {
          ref2.close();
        });
      }
    });
  }

  checkHH() {
    if (this.currentPractice && this.currentPractice['HealthHistoryEnabled.Overridden'] == 'Y') {
      this.healthHistoryActive = true;
    } else {
      this.healthHistoryActive = false;
    }
  }

  openModule(parameters: {
    code: ModuleOption;
    isActive?: boolean;
    isBeta?: boolean;
    showModule?: boolean;
    goBack?: boolean;
  }) {
    if (parameters.isBeta && parameters.isActive) {
      this.ShowBeta(parameters.code);
      if (parameters.goBack) {
        this.goBackEvent();
      }
    } else if (!parameters.isBeta && parameters.isActive) {
      if (parameters.goBack) {
        this.goBackEvent();
      }
    } else if (parameters.showModule) {
      this.viewModule(parameters.code);
    }
  }

  viewModule(code) {
    if (code) {
      const ref = RootAppComponent.dialog.open(ModuleViewComponent, {
        data: {
          moduleCode: code,
        },
        width: '1000px',
      });
      ref.componentInstance.close.subscribe((res) => {
        if (res) {
          ref.close();
        }
      });
    }
  }

  hideTooltip(isActive: boolean) {
    return isActive ? '' : 'You do not have access to this Module';
  }

  getModuleActive(code) {
    if (this.modules && this.modules[code] && this.modules[code].isActive == true && this.mainModuleLoaded == true) {
      return true;
    }

    return false;
  }

  getModuleIcon(code, icon) {
    if (this.modules && this.modules[code] && this.modules[code].icon) {
      return this.modules[code].icon;
    }

    return icon;
  }

  getModuleLabel(code, label) {
    if (this.modules && this.modules[code] && this.modules[code].label) {
      return this.modules[code].label;
    }

    return label;
  }

  isBeta(code) {
    if (this.modules && this.modules[code] && this.modules[code].isBeta == true) {
      return true;
    }

    return false;
  }

  ShowBeta(b) {
    if (b) {
      const d = this.isBeta(b);
      if (d === true) {
        let show = false;

        if (localStorage.getItem('showBetaModule' + b) === 'true') {
          show = true;
        }

        if (show !== true) {
          const ref = RootAppComponent.dialog.open(BetaInfoComponent, {
            data: {
              code: b,
            },
            width: '650px',
            height: '650px',
            panelClass: 'noCard',
          });
          ref.componentInstance.close.subscribe((res) => {
            ref.close();
          });

          ref.componentInstance.getModule.subscribe((res) => {
            ref.close();
            this.viewModule(b);
          });
        }
      }
    }
  }

  createNewPatient() {
    const ref2 = RootAppComponent.dialog.open(CustomerProspectCreateComponent, {
      width: '850px',
    });

    ref2.componentInstance.close.subscribe((res) => {
      ref2.close();
    });
    ref2.componentInstance.getResult.subscribe((res) => {
      if (res && res.ID) {
        ref2.close();
        const confirm = new ConfirmDialog(
          'visibility',
          'View the patient?',
          'Do you want to view the created patient.',
          'No',
          'Yes'
        );
        const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
          data: confirm,
        });
        ref.componentInstance.onConfirm.subscribe((confirmation) => {
          if (confirmation === false) {
            ref.close();
          } else {
            ref.close();
            this.router.navigate([
              '/merchant',
              {
                outlets: {
                  page: ['customer-profile', res.ID, 'patient'],
                },
              },
            ]);
          }
        });
      }
    });
  }

  openCreateGlobalSMSContact() {
    if (this.sessionType === 'admin' || this.sessionType === 'promoter') {
      const ref2 = RootAppComponent.dialog.open(SmsPromoterComponent, {
        data: {
          isContactStaff: true,
        },

        panelClass: 'noCard',
        width: '500px',
      });
      ref2.componentInstance.close.subscribe((data) => {
        ref2.close();
      });

      ref2.componentInstance.getResult.subscribe((data) => {
        if (data) {
          this.router.navigate([
            '/merchant',
            {
              outlets: { page: ['sms-conversation-promoter-type', 'staff', encodeURIComponent(JSON.stringify(data))] },
            },
          ]);
        }

        ref2.close();
      });
    } else {
      this.router.navigate(['/merchant', { outlets: { page: ['sms-conversation-type', 'staff'] } }]);
    }
  }

  createNewContact() {
    const ref2 = RootAppComponent.dialog.open(ContactCreateEditModalComponent, {
      width: '850px',
    });

    ref2.componentInstance.close.subscribe((res) => {
      ref2.close();
    });
    ref2.componentInstance.getResult.subscribe((res) => {
      if (res && res.ID) {
        ref2.close();
        ref2.afterClosed().subscribe((r) => {
          const confirm = new ConfirmDialog(
            'visibility',
            'View the Staff?',
            'Do you want to view the created Staff member.',
            'No',
            'Yes'
          );
          const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
            data: confirm,
          });
          ref.componentInstance.onConfirm.subscribe((confirmation) => {
            if (confirmation === false) {
              ref.close();
            } else {
              ref.close();
              this.router.navigate([
                '/merchant',
                {
                  outlets: {
                    page: ['dentist-profile', res.ID],
                  },
                },
              ]);
            }
          });
        });
      }
    });
  }

  createNewContactDetails() {
    const ref2 = RootAppComponent.dialog.open(DentistInviteCreateComponent, {
      data: {
        isModal: true,
      },
      panelClass: ['max-width-panel-1200', 'noCard','card-relative'],
      width: '90vw',
    });

    ref2.componentInstance.close.subscribe((res) => {
      ref2.close();
    });
    ref2.componentInstance.getResult.subscribe((res) => {
      if (res && res.ID) {
        ref2.close();
        ref2.afterClosed().subscribe((r) => {
          const confirm = new ConfirmDialog(
            'visibility',
            'View the Staff?',
            'Do you want to view the created Staff member.',
            'No',
            'Yes'
          );
          const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
            data: confirm,
          });
          ref.componentInstance.onConfirm.subscribe((confirmation) => {
            if (confirmation === false) {
              ref.close();
            } else {
              ref.close();
              this.router.navigate([
                '/merchant',
                {
                  outlets: {
                    page: ['dentist-profile', res.ID],
                  },
                },
              ]);
            }
          });
        });
      }
    });
  }

  linksDirectory() {
    const ref2 = RootAppComponent.dialog.open(MerchantLinkLookupComponent, {
      data: {
        viewOnly: true,
      },
      width: '600px',
      height: '80vh',
    });

    ref2.componentInstance.close.subscribe((res) => {
      ref2.close();
    });
  }

  isNavigationEnabled(enabled: boolean, route: any) {
    return enabled ? route : null;
  }
 createSettlement() {
    this.goBackEvent();
    AuthenticationService.newSettlement.emit({});
  }

  newLandingPage() {
    const refLandingPageNewModalComponent = RootAppComponent.dialog.open(LandingPageNewModalComponent, {
      data: {},
      width: '650px',
    });

    refLandingPageNewModalComponent.componentInstance.getType.subscribe((type) => {
      refLandingPageNewModalComponent.close();

      refLandingPageNewModalComponent.afterClosed().subscribe((r) => {
        this.router.navigate([
          '/merchant',
          {
            outlets: {
              page: ['landing-page-create', type, 'null', 'true'],
            },
          },
        ]);
      });
    });

    refLandingPageNewModalComponent.componentInstance.close.subscribe((res) => {
      refLandingPageNewModalComponent.close();
    });
  }
}
