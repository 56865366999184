import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { WizardComponent } from 'angular-archwizard';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { BlobViewComponent } from '../../../shared/components/blob-view/blob-view.component';
import { ConfirmDialogSingleButtonComponent } from '../../../shared/components/confirm-dialog-single-button/confirm-dialog-single-button.component';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { FilterArrayMultipleValue } from '../../../shared/pipes/filter-array-object.pipe';
import { UtilsService } from '../../../shared/services/utils.service';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { ConfirmDialogSingleButton } from '../../../shared/types/confirm-dialog-single-button';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { BeneficiaryService } from '../../beneficiary/shared/beneficiary.service';
import { ContractViewSimpleComponent } from '../../contract/contract-view-simple/contract-view-simple.component';
import { ContractService } from '../../contract/shared/contract.service';
import { CustomerViewComponent } from '../../customer/customer-view/customer-view.component';
import { CustomerService } from '../../customer/shared/customer.service';
import { MerchantViewComponent } from '../../merchant/merchant-view/merchant-view.component';
import { MerchantService } from '../../merchant/shared/merchant.service';
import { TreatmentService } from '../../treatment/shared/treatment.service';
import { HealthfundIntroModalComponent } from '../healthfund-intro-modal/healthfund-intro-modal.component';
import { SettlementContractListComponent } from '../settlement-contract-list/settlement-contract-list.component';
import { SettlementCreateIntroModalComponent } from '../settlement-create-intro-modal/settlement-create-intro-modal.component';
import { SettlementHealthFundModalComponent } from '../settlement-healthfund-modal/settlement-healthfund-modal.component';
import { SettlementThirdPartyModalComponent } from '../settlement-thirdparty-modal/settlement-thirdparty-modal.component';
import { SettlementViewGroupComponent } from '../settlement-view-group/settlement-view-group.component';
import { SettlementViewComponent } from '../settlement-view/settlement-view.component';
import { SettlementService } from '../shared/settlement.service';
import { ThirdpartyIntroModalComponent } from '../thirdparty-intro-modal/thirdparty-intro-modal.component';

import * as _ from 'lodash';

import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { HeaderService } from '../../../shared/services/header.service';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { BeneficiaryViewComponent } from '../../beneficiary/beneficiary-view/beneficiary-view.component';
import { before } from 'lodash';
import { SettlementListOverViewComponent } from '../settlement-list-overview/settlement-list-overview.component';
import { ConfirmDialogMultipleComponent } from '../../../shared/components/confirm-dialog-multiple/confirm-dialog-multiple.component';

@Component({
  selector: 'app-settlement-create',
  templateUrl: './settlement-create.component.html',
  styleUrls: ['./settlement-create.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.anim', style({ opacity: 0 }), { optional: true }),
        query(
          '.anim',
          stagger('100ms', [
            animate(
              '0.2s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-20%)', offset: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
    trigger('ngIfGrow', [
      transition('void => *', [
        query('.animG', style({ opacity: 0 }), { optional: true }),
        query(
          '.animG',
          stagger('100ms', [
            animate(
              '0.3s ease-out',
              keyframes([
                style({ opacity: 0, offset: 0, height: 0, margin: 0 }),
                style({ opacity: 1, offset: 1.0, height: '*', margin: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
    trigger('onChangeAnimation', [
      transition('void => *', [
        query('.animate', style({ opacity: 0 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.3s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-10%)', offset: 0, height: 0, margin: 0 }),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0, height: '*', margin: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.animate', style({ opacity: 1 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.3s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0, height: '*', margin: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(5%)', offset: 1.0, height: 0, margin: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class SettlementCreateComponent implements OnInit {
  isModuleSettlementsAdvancedActive = Settings.global['isModuleSettlementsAdvancedActive'];

  thirdPartyIsRequired = false;
  healthFundIsRequired = false;

  healthFundIsSet = false;

  healthFundAmount = 0;
  healthFundAccepted = false;
  healthFundName;

  healthFundReference;

  externalID;
  externalSource;
  transactionID;

  thirdPartyName;
  thirdPartySuppliers;
  @Input()
  settlementTransactionToken;

  settlementData;
  @Input() contractID = null;
  @Input() customerID = null;
  @Input()
  isIntegrated = false;
  @Input()
  redirectURL;
  @Input()
  redirectParam = null;
  @Output()
  createdSettlement = new EventEmitter();

  settlementsNotYetDrawn;

  @Output()
  getSettlementID = new EventEmitter();
  isCloseWebhookButton = false;
  counter = 0;

  @Output()
  close = new EventEmitter();

  @Output() // For the purpose of not display this componenet from the settlement list if there is no contract has balance to drawn.
  noContract = new EventEmitter();

  settelmentDocuments;

  isSkipLookup;
  isUploaded = false;
  uploadAllForm = new EventEmitter();
  settlementfileID;
  documentTypesForm = ['Treatment Plan', 'Additional Document', 'Image', 'Scan / X-Ray'];
  isPromoterOrAdmin;
  customers: any = [];

  selectedCustomer = null;
  contracts: any = [];

  displayContractList = false;
  displayCustomerList = false;

  requestedPendingAmount = 0;
  paidApprovedAmount = 0;
  selectedContract = null;

  filterEmail = '';
  filterFirstName = '';

  filterMiddleName = '';
  filterName = '';
  filterPhone = '';

  amountInvoice;
  balance;

  // selectedFundApproved = false;
  isFundApproved = false;
  isBeneficiaryApproved = false;

  serviceType;
  serviceTypes = [];

  settlementChart;
  charColors = ['#0000BF', '#1ca90c'];
  merchantID;
  merchants = [];
  role;
  checkForm: FormGroup;
  smsForm: FormGroup;

  authSms = true;

  securityCodeID;
  securityCode;
  smsSent = false;
  emailSent = false;
  filterCustomer;
  option = 'securityCode';
  settlementAmount;
  settlementID;
  settlementDate = new Date();

  authorisationFormContent;

  settings = Settings.global;

  authorisationFormTitle = 'Authorisation form unsigned';
  authorisationFormDescription = 'To proceed you must either download or print this form for the patient to sign';
  invoiceDate = new Date();
  invoiceReference;
  invoiceDescription;
  invoiceFileID;

  settlementObject;

  timeLeft: number;
  interval;

  minDate = new Date();

  beneficiaries = [];

  hideLookup = false;
  webhookUrl;
  step = 0;

  contractOnHoldSettlements = [];

  items = [
    'contract',
    'settlement',
    'invitation',
    'customer',
    'dentist',
    'merchant',
    'instantOffer',
    'appointment',
    'campaign',
    'landingPage',
    'note',
  ];
  // beneficiary;
  // bankAccount;

  // bankAccountID;
  // beneficiaryID;
  // beneficiaryAmount = 0;

  isPreTreatment = false;
  settlementAmountInput;
  invoiceDetails;
  transactionWebhookURL;
  hideMerchantSelect = false;
  transactionToken;
  @ViewChild(WizardComponent, { static: false })
  public wizard: WizardComponent;

  settlementPendingStatus = ['PEND', 'REQU'];

  isMaterialRequest;

  documents;
  sessionType = 'guest';
  displayList = false;

  materialRequestPercent = Settings.global['materialRequestPercent'];

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  constructor(
    private utilService: UtilsService,
    private beneficiaryService: BeneficiaryService,
    private customerService: CustomerService,
    private contractService: ContractService,
    private merchantService: MerchantService,
    private dialog: MatDialog,
    private router: Router,
    private settlementService: SettlementService,
    private authenticationService: AuthenticationService,
    private FilterArrayMultipleValue: FilterArrayMultipleValue,
    private activeRoute: ActivatedRoute,
    private treatmentService: TreatmentService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.contractID) {
        this.contractID = data.contractID;
      }

      if (data.customerID) {
        this.customerID = data.customerID;
      }
    }

    this.settlementAmountInput = new FormControl('');
  }

  ngOnInit() {
    this.setupIntgratuionURL();

    this.utilService.getCurrentAccess().subscribe((access) => {
      if (access) {
        this.isModuleSettlementsAdvancedActive = access['isModuleSettlementsAdvancedActive'];
      }

      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isPromoterOrAdmin = res;

        this.activeRoute.params.subscribe((params) => {
          if (params['customerKey']) {
            this.customerID = params['customerKey'];
          }
          if (params['materialRequest'] == 'material') {
            this.isMaterialRequest = true;
          } else {
            this.isMaterialRequest = false;
          }

          if (params['settlementTransactionToken']) {
            this.settlementTransactionToken = params['settlementTransactionToken'];
          }

          if (params['contractKey']) {
            this.contractID = params['contractKey'];
          }
          this.authenticationService.getSessionType().subscribe((sessionType) => {
            if (sessionType) {
              this.sessionType = sessionType;
            }

            const payload = {
              fields: 'ID,Label',
              isActive: true,
            };
            this.treatmentService.getCategoryList(payload, this.isPromoterOrAdmin).subscribe((res) => {
              this.serviceTypes = res;
            });

            if (this.isPromoterOrAdmin == true && !this.customerID && !this.contractID) {
              HeaderService.getPromoterViewAsObject().subscribe((obj) => {
                if (obj && obj['promoterViewAs'] && obj['promoterViewAs'] == 'merchant') {
                  this.merchantID = obj.merchantID;
                  this.hideMerchantSelect = true;
                } else if (obj && obj['promoterViewAs'] && obj['promoterViewAs'] == 'promoter') {
                  this.merchantID = null;
                  this.hideMerchantSelect = false;
                }
              });
              const temp = localStorage.getItem('selectedMerchant');

              const payload = {
                fields: 'ID,TradingAs,CalculatedName,Status',
              };
              this.merchantService.getList(payload).subscribe((res) => {
                this.merchants = res;
              });

              if (temp) {
                this.merchantID = temp;
                this.hideMerchantSelect = true;
              }
            }

            this.setupMaterialRequest();

            if (this.customerID && !this.contractID) {
              this.isSkipLookup = false;
              this.customerID = this.customerID;
              this.customerService.getCustomerDetails(this.customerID, {}, this.sessionType).subscribe((res) => {
                if (res) {
                  this.selectedCustomer = res;

                  this.contractLookup();
                }
              });
            } else if (this.contractID) {
              this.isSkipLookup = true;
              const payload = {
                selectStats: true,
              };
              this.contractService
                .fetchContractDetails$(this.contractID, payload, this.isPromoterOrAdmin)
                .subscribe((res1) => {
                  this.counter = -1;
                  this.customerID = res1['Customer_key'];
                  this.selectContract(res1);

                  this.customerService.getCustomerDetails(this.customerID, {}, this.sessionType).subscribe((res) => {
                    if (res) {
                      this.selectedCustomer = res;

                      this.isSkipLookup = true;

                      this.contractID = this.selectedContract['ID'];

                      this.wizard.goToStep(0);

                      if (Number(this.selectedContract['Settlements.NotYetDrawn']) > 0) {
                        this.settlementsNotYetDrawn = Number(this.selectedContract['Settlements.NotYetDrawn']);

                        this.balance = this.calculBalance(
                          this.selectedContract['Amount.Invoice'],
                          this.selectedContract['Settlements.NotYetDrawn']
                        );

                        this.settlementAmountInput = new FormControl('', [
                          Validators.max(this.balance),
                          Validators.min(0),
                        ]);

                        const requestedPendingArray = this.FilterArrayMultipleValue.transform(
                          this.selectedContract['stats'],
                          'ID',
                          this.settlementPendingStatus
                        );

                        this.requestedPendingAmount = 0;

                        for (let i = 0; i < requestedPendingArray.length; i++) {
                          this.requestedPendingAmount =
                            this.requestedPendingAmount + Number(requestedPendingArray['Sum(Settlement.Amount)']);
                        }

                        if (this.isMaterialRequest == true) {
                          this.charColors = ['#0000BF', '#808080', '#1ca90c'];
                          this.settlementChart = [
                            {
                              name: ' Paid/Approved for payment',
                              value:
                                Number(this.selectedContract['Amount.Invoice']) -
                                Number(this.balance) -
                                Number(this.selectedContract['Settlements.NotYetDrawn']),
                            },
                            {
                              name: 'Available For Settlement',
                              value: Number(this.selectedContract['Amount.Invoice']) - Number(this.balance),
                            },
                            {
                              name: 'Available For Material Advance',
                              value: this.balance,
                            },
                          ];
                        } else {
                          this.settlementChart = [
                            {
                              name: ' Paid/Approved for payment',
                              value: Number(this.selectedContract['Amount.Invoice']) - Number(this.balance),
                            },
                            {
                              name: 'Available Balance',
                              value: this.balance,
                            },
                          ];
                        }

                        this.buildTransaction();
                      } else {
                        NotifyAppComponent.displayToast(
                          'warning',
                          'Sorry!',
                          'No Balance left to drawdown for this contract'
                        );
                      }
                    }
                  });
                });
            } else {
              this.isSkipLookup = false;
            }
          });
        });
      });
    });
  }

  changeMerchant() {
    this.setupMaterialRequest();
  }
  setupMaterialRequest() {
    if (this.isPromoterOrAdmin === true && this.merchantID) {
      this.authenticationService
        .getMerchantDetails(
          this.merchantID,
          { fields: 'ID,Disable_Matrial_Request,Percent_Stlmnt_Mtrls_Request' },
          this.sessionType
        )
        .subscribe((merchant) => {
          if (merchant && merchant.ID) {
            this.materialRequestPercent = Number(merchant['Percent_Stlmnt_Mtrls_Request']);
          }
        });
    } else {
      this.authenticationService.getCurrentPractice().subscribe((merchant) => {
        if (merchant && merchant.ID) {
          this.materialRequestPercent = Number(merchant['Percent_Stlmnt_Mtrls_Request']);
        }
      });
    }
  }

  calculBalance(amount, NotYetDrawn) {
    if (this.isMaterialRequest === true) {
      if (this.materialRequestPercent) {
        let p = Math.floor(Number((Number(amount) * Number(this.materialRequestPercent)) / 100));

        if (p > Number(NotYetDrawn)) {
          return NotYetDrawn;
        }
        return p;
      }
      return 0;
    } else {
      return amount;
    }
  }

  setupIntgratuionURL() {
    const origin = window.location.origin;
    const pathname = window.location.pathname;
    let link = window.location.href;

    if (link && origin) {
      if (pathname) {
        link = link.replace(origin + pathname, '');
      } else {
        link = link.replace(origin, '');
      }
    }

    const urlParams = new URLSearchParams(link);

    if (urlParams.get('isIntegrated') == 'true') {
      this.isIntegrated = true;
    } else {
      this.isIntegrated = false;
    }
    this.transactionToken = urlParams.get('transactionToken');
    this.transactionWebhookURL = urlParams.get('transactionWebhookURL');

    if (this.transactionWebhookURL) {
      this.transactionWebhookURL = this.transactionWebhookURL.replace(/\$/g, '/');
    }

    if (urlParams.get('isCloseWebhookButton') == 'true') {
      this.isCloseWebhookButton = true;
      this.isIntegrated = true;
    } else {
      this.isCloseWebhookButton = false;
    }
    if (urlParams.get('redirectParam') != null && urlParams.get('redirectParam') != 'null') {
      this.redirectParam = urlParams.get('redirectParam');
    } else {
      this.redirectParam = null;
    }
    if (urlParams.get('redirectURL') == null || urlParams.get('redirectURL') == 'null') {
      this.redirectURL = null;
    } else {
      this.redirectURL = urlParams.get('redirectURL');
    }
  }

  buildTransaction() {
    if (this.settlementTransactionToken && !this.settlementData) {
      this.settlementService.getSettlementDataByToken(this.settlementTransactionToken).subscribe((res) => {
        if (res) {
          this.settlementData = res;

          if (this.settlementData['settlement_amount']) {
            this.settlementAmount = this.settlementData['settlement_amount'];
          }

          if (this.settlementData['insurance_reference']) {
            this.healthFundReference = this.settlementData['insurance_reference'];
          }

          if (this.settlementData['insurance_contribution']) {
            this.healthFundAmount = this.settlementData['insurance_contribution'];
          }

          if (this.settlementData['insurance_provider']) {
            this.healthFundName = this.settlementData['insurance_provider'];
          }

          if (this.settlementData['insurance_provider']) {
            this.healthFundName = this.settlementData['insurance_provider'];
          }

          if (this.settlementData['settlement_external_id']) {
            this.externalID = this.settlementData['settlement_external_id'];
          }

          if (this.settlementData['settlement_source_id']) {
            this.externalSource = this.settlementData['settlement_source_id'];
          }

          if (this.settlementData['settlement_transaction_id']) {
            this.transactionID = this.settlementData['settlement_transaction_id'];
          }

          if (this.healthFundReference && this.healthFundAmount && this.healthFundName) {
            this.healthFundIsSet = true;
          }

          if (this.settlementData['settlement_webhook_url']) {
            this.webhookUrl = this.settlementData['settlement_webhook_url'];
          }

          if (
            this.settlementData &&
            this.settlementData.beneficiaries &&
            this.settlementData.beneficiaries.length > 0
          ) {
            for (let i = 0; i < this.settlementData.beneficiaries.length; i++) {
              const o = {
                bankAccount: this.settlementData.beneficiaries['bank_account_id'],
                beneficiary: this.settlementData.beneficiaries[i]['beneficiary_id'],
                beneficiaryAmount: this.settlementData.beneficiaries['beneficiary_amount'],
              };
              this.beneficiaries.push(o);
            }
          }
        }
      });
    }
  }

  getBeneficiaryPicture(i) {
    this.beneficiaries[i].beneficiary['profileLink'] = null;

    this.beneficiaries[i].beneficiary['profileLink'] = this.beneficiaryService.getProfilePicStreamLink(
      this.beneficiaries[i].beneficiary['ID']
    );
  }

  settlementListOverview(contractID, status = null, statusCodes = null) {
    if (contractID) {
      const ref = RootAppComponent.dialog.open(SettlementListOverViewComponent, {
        data: {
          contractID,
          status: status,
          statusCodes: statusCodes,
        },
        width: '90vw',
        panelClass: ['max-width-panel-90vw'],
      });

      ref.componentInstance.close.subscribe((res) => {
        if (res == true) {
          ref.close();
        }
      });
    }
  }

  settlementList(contractID, status = null) {
    if (contractID) {
      const ref = RootAppComponent.dialog.open(SettlementContractListComponent, {
        data: {
          contractID,
          statusID: status,
        },
        width: '900px',
      });

      ref.componentInstance.closeModal.subscribe((res) => {
        if (res == true) {
          ref.close();
        }
      });
    }
  }

  viewBeneficiary(b) {
    if (b && b.beneficiary && b.beneficiary['ID']) {
      const ref = RootAppComponent.dialog.open(BeneficiaryViewComponent, {
        data: b.beneficiary['ID'],
        width: '600px',
      });

      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    }
  }

  removeAuthorisationForm(e) {
    if (e && e == this.settlementfileID) {
      this.settlementfileID = null;
    }
  }

  removeInvoiceFile(e) {
    if (e && e == this.invoiceFileID) {
      this.invoiceFileID = null;
    }
  }

  changeAuthMethodSms() {
    this.authSms = true;
  }

  changeAuthMethodDoc() {
    this.authSms = false;
  }

  proceedDemoRequest() {
    if ((this.authSms = true)) {
      NotifyAppComponent.displayToast(
        'success',
        'SMS SENT',
        'Your patient has been sent an authorisation code to their mobile'
      );
    } else {
      NotifyAppComponent.displayToast(
        'fas fa-info',
        'Please print form',
        'Please print and complete the authorisation form to proceed with settlement'
      );
    }
  }

  authenticated() {
    NotifyAppComponent.displayToast('success', 'Authorisation', 'Your patient has authorised the settlement request');

    this.wizard.goToNextStep();
  }

  previewMessage() {
    const payload = {
      settlementAmount: this.settlementAmount,
    };

    if (this.healthFundName) {
      payload['healthFundName'] = this.healthFundName;
    }

    if (this.healthFundAmount) {
      payload['healthFundAmount'] = this.healthFundAmount;
    }

    if (this.healthFundReference) {
      payload['healthFundReference'] = this.healthFundReference;
    }

    this.settlementService.getAuthorisationForm(this.contractID, payload).subscribe((res) => {
      if (res) {
        this.authorisationFormContent = res;
        const ref = RootAppComponent.dialog.open(BlobViewComponent, {
          data: {
            content: this.authorisationFormContent,
            title: this.authorisationFormTitle,
            label: 'Authorisation form for ' + this.selectedContract['Customer.FullName'],
          },
          width: '800px',
          panelClass: 'noCard',
        });

        ref.componentInstance.closeModal.subscribe((res) => {
          if (res == true) {
            ref.close();
          }
        });
      }
    });
  }

  viewSettlementGroup() {
    if (this.settlementObject['DrawDownGroupID']) {
      const ref = this.dialog.open(SettlementViewGroupComponent, {
        data: {
          settlementGroupID: this.settlementObject['DrawDownGroupID'],
          title: 'Settlement Group',
          description: '',
        },
        width: '900px',
      });

      ref.componentInstance.close.subscribe((res) => {
        if (res == true) {
          ref.close();
        }
      });
    }
  }

  viewSettlement() {
    if (this.settlementObject && this.settlementObject['ID'] && this.settlementObject['DrawDownGroupID'] == '') {
      const ref = RootAppComponent.dialog.open(SettlementViewComponent, {
        data: this.settlementObject['ID'],
        width: '850px',
        panelClass: 'noCard',
      });

      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    } else if (this.settlementObject && this.settlementObject['ID'] && this.settlementObject['DrawDownGroupID'] != '') {
      this.viewSettlementGroup();
    }
  }

  onCompleteAllInvoice(e) {
    if (e && e[0]) {
      this.invoiceFileID = e[0];
    }
  }

  goBackCreateSettlement() {
    this.option = null;
    this.wizard.goToPreviousStep();

    if (this.settlementObject) {
      this.settlementService.cancel(this.settlementObject['ID'], this.isPromoterOrAdmin).subscribe((res) => {
        if (res) {
          // NotifyAppComponent.displayToast(
          //   'success',
          //   'Success!',
          //   'Your Settlement has been withdrawn'
          // );
        }
      });
    }
  }

  onCompleteAllForm(e) {
    if (e && e[0]) {
      this.settlementfileID = e[0];
    }
  }

  createAuthorisationForm() {
    this.uploadAllForm.emit();
  }

  isUploadedMyFile(e) {
    this.isUploaded = e;
  }

  getCustomer() {
    let payload = {};
    if (this.isPromoterOrAdmin == true) {
      payload = {
        email: this.filterEmail,
        partLastName: this.filterName,
        partFirstName: this.filterFirstName,
        partMiddleName: this.filterMiddleName,

        fields: 'ID,FirstName,Name,mobiles.Number,emails.Email,MiddleName',
        partMobile: this.filterPhone,
        hasActiveOrSuspendedOrCompleteContract: true,
      };
      if (this.merchantID && this.merchantID !== 'none') {
        payload['merchantID'] = this.merchantID;
      }
    } else {
      payload = {
        email: this.filterEmail,
        fields: 'ID,FirstName,Name,mobiles.Number,emails.Email,MiddleName',
        partLastName: this.filterName,
        partFirstName: this.filterFirstName,
        partMiddleName: this.filterMiddleName,
        partMobile: this.filterPhone,
        hasActiveOrSuspendedOrCompleteContract: true,
      };
    }
    this.customerService.customerLookup(payload, this.isPromoterOrAdmin).subscribe((res) => {
      if (res && res.length > 0) {
        this.customers = res;
        this.displayCustomerList = true;
        this.displayContractList = false;

        // if (this.customers && this.customers.length == 1) {
        //   this.selectCustomer(this.customers[0])
        // }
      } else {
        NotifyAppComponent.displayToast(
          'warning',
          'Sorry!',
          'We did not find any match of customer with active contract in our database.'
        );
      }
    });
  }

  selectCustomer(c) {
    this.selectedCustomer = c;

    // this.wizard.goToNextStep();
    this.contractLookup();
  }

  contractLookup() {
    if (this.selectedCustomer) {
      const payload: any = {
        onlyActive: true,
        canDrawDown: true,
        isMaterialRequest: null,
        customerID: this.selectedCustomer['ID'],
        fields:
          'ID,ProductName,Settlements.NotYetDrawn,ApplicationDate,Amount.Invoice,' +
          'Settlements.Drawn,Customer.FullName,NextDrawDown.Before',
      };

      if (this.isMaterialRequest == true) {
        payload.isMaterialRequest = false;
      }
      this.contractService.getContractList(payload, this.isPromoterOrAdmin).subscribe((res) => {
        const tempList = [];
        res.map((x) => {
          if (Number(x['Settlements.NotYetDrawn']) > 0) {
            tempList.push(x);
          }
        });
        if (tempList.length <= 0) {
          NotifyAppComponent.displayToast(
            'warning',
            'Sorry!',
            'We did not find any contract with active contract in our database.'
          );
          this.contracts = tempList;
          this.goBackCustomerList();
          this.noContract.emit(false);
        }
        // else if (tempList.length == 1) {
        //   this.customerID = this.selectedCustomer['ID'];
        //   this.contracts = tempList;
        //   this.selectContract(this.contracts[0]);
        //   this.requestSettlement();
        // }
        else {
          this.customerID = this.selectedCustomer['ID'];
          this.contracts = tempList;
          this.displayContractList = true;
          this.displayCustomerList = false;
        }

        // if (this.contracts && this.contracts.length == 1) {
        //   this.selectContract(this.contracts[0])
        // }
      });
    }
  }

  canDrawDown(c) {
    return true;
  }

  goBackCustomerLlookup() {
    this.displayContractList = false;
    this.displayCustomerList = false;
    this.customerID = null;
    this.contractID = null;
  }

  goBackCustomerList() {
    this.displayContractList = false;
    this.displayCustomerList = true;
    this.customerID = null;
  }

  goBackContractList() {
    this.hideLookup = false;
    this.resetDeductions();
    this.displayContractList = true;
    this.displayCustomerList = false;
    this.contractID = null;
    this.settlementfileID = null;
    this.invoiceFileID = null;
    this.settlementAmount = null;
    this.wizard.goToPreviousStep();
  }

  sendCodeAgain() {
    const payload = {
      customerID: this.selectedCustomer['ID'],
      settlementID: this.settlementObject['ID'],
      contractID: this.contractID,
    };
    this.settlementService.requestSettlementCode(payload).subscribe((res) => {
      if (res) {
        this.countDown();
        this.smsSent = true;

        this.emailSent = false;
        if (res && res['Send.Email'] == '1') {
          this.emailSent = true;
        }
        this.securityCodeID = res.ID;
        NotifyAppComponent.displayToast('success', 'Successful operation', 'The new code has been sent to you');
      }
    });
  }

  proceedWithCode() {
    this.option = 'securityCode';
    this.createSettlement();
  }

  validateSettlementCode() {
    if (this.settlementObject && this.securityCodeID && this.securityCode) {
      const payload = {
        settlementID: this.settlementObject['ID'],
        securityCodeID: this.securityCodeID,
        securityCode: this.securityCode,
        cardID: this.selectedCustomer['ID'],
      };

      this.settlementService.validateSettlementCode(payload).subscribe((res) => {
        if (res && res == true) {
          NotifyAppComponent.displayToast(
            'success',
            'Successful operation',
            'The settlement has been authorised by security code'
          );
          this.wizard.goToNextStep();
        }
      });
    }
  }

  countDown() {
    this.timeLeft = 60;
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        clearInterval(this.interval);
      }
    }, 1000);
  }

  proceedWithForm() {
    const payload = {
      settlementAmount: this.settlementAmount,
    };

    if (this.healthFundName) {
      payload['healthFundName'] = this.healthFundName;
    }

    if (this.healthFundAmount) {
      payload['healthFundAmount'] = this.healthFundAmount;
    }

    if (this.healthFundReference) {
      payload['healthFundReference'] = this.healthFundReference;
    }

    this.settlementService.getAuthorisationForm(this.contractID, payload).subscribe((res) => {
      if (res) {
        this.option = 'document';

        this.authorisationFormContent = res;
        const ref = RootAppComponent.dialog.open(BlobViewComponent, {
          data: {
            labelProceed: 'Proceed',
            labelPrint: 'Print',
            labelDownload: 'Download',
            content: this.authorisationFormContent,
            title: this.authorisationFormTitle,
            description: this.authorisationFormDescription,
            isDownload: true,
            isPrint: true,
            isProceed: true,
            label: 'Authorisation form for ' + this.selectedContract['Customer.FullName'],
          },
          width: '800px',
          panelClass: 'ScrollNoCard',
          disableClose: true,
        });

        ref.componentInstance.closeModal.subscribe((res) => {
          ref.close();
        });

        ref.componentInstance.proceed.subscribe((res) => {
          if (res == true) {
            ref.close();
            this.wizard.goToNextStep();
          }
        });
      }
    });
  }

  goBack() {
    this.customers = [];
    this.displayCustomerList = false;
    this.customerID = null;
    this.contracts = [];
    this.displayContractList = false;
    this.contractID = null;
    this.selectedContract = null;
  }

  openCustomerDetailsDialog(id) {
    const ref = RootAppComponent.dialog.open(CustomerViewComponent, {
      data: id,
      width: '600px',
      panelClass: 'noCard',
    });

    ref.componentInstance.closeModal.subscribe((res) => {
      ref.close();
    });
  }

  openContractDetailsDialog(id) {
    const ref = RootAppComponent.dialog.open(ContractViewSimpleComponent, {
      data: id,
      width: '800px',
    });

    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  printForm() {
    // This is a simple
    window.open('https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/files/test.pdf');
    this.proceedWithForm();
  }

  viewMerchant() {
    const ref = RootAppComponent.dialog.open(MerchantViewComponent, {
      data: this.merchantID,
      width: '600px',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  buildContractView(moveNext = true) {
    this.hideLookup = true;

    this.contractID = this.selectedContract['ID'];
    const payload = {
      selectStats: true,
    };
    this.contractService.fetchContractDetails$(this.contractID, payload, this.isPromoterOrAdmin).subscribe((res1) => {
      if (res1) {
        this.selectedContract = res1;

        if (Number(this.selectedContract['Settlements.NotYetDrawn']) > 0) {
          this.balance = this.calculBalance(
            this.selectedContract['Amount.Invoice'],
            this.selectedContract['Settlements.NotYetDrawn']
          );

          this.settlementsNotYetDrawn = Number(this.selectedContract['Settlements.NotYetDrawn']);

          this.settlementAmountInput = new FormControl('', [Validators.max(this.balance), Validators.min(0)]);

          const requestedPendingArray = this.FilterArrayMultipleValue.transform(
            this.selectedContract['stats'],
            'ID',
            this.settlementPendingStatus
          );

          this.requestedPendingAmount = 0;

          for (let i = 0; i < requestedPendingArray.length; i++) {
            this.requestedPendingAmount =
              this.requestedPendingAmount + Number(requestedPendingArray['Sum(Settlement.Amount)']);
          }

          if (this.isMaterialRequest == true) {
            this.charColors = ['#0000BF', '#808080', '#1ca90c'];
            this.settlementChart = [
              {
                name: ' Paid/Approved for payment',
                value:
                  Number(this.selectedContract['Amount.Invoice']) -
                  Number(this.balance) -
                  Number(this.selectedContract['Settlements.NotYetDrawn']),
              },
              {
                name: 'Available For Settlement',
                value: Number(this.selectedContract['Amount.Invoice']) - Number(this.balance),
              },
              {
                name: 'Available For Material Advance',
                value: this.balance,
              },
            ];
          } else {
            this.settlementChart = [
              {
                name: ' Paid/Approved for payment',
                value: Number(this.selectedContract['Amount.Invoice']) - Number(this.balance),
              },
              {
                name: 'Available Balance',
                value: this.balance,
              },
            ];
          }
        } else {
          NotifyAppComponent.displayToast('warning', 'Sorry!', 'No Balance left to drawdown for this contract');
        }

        if (moveNext == true) {
          this.wizard.goToNextStep();
        }
      }
    });
  }

  selectContract(c) {
    if (c && c.ID) {
      this.settlementService
        .getSettlementList(
          {
            contractID: c.ID,
            fields: 'ID,Status.Code,Status.Label,DateTimeCreated',
            statusCodes: 'REQU,PEND',
            limit: 1000,
            section: 1,
          },
          this.isPromoterOrAdmin
        )
        .subscribe((r) => {
          if (r && r.length > 0) {
            this.contractOnHoldSettlements = r;
          } else {
            this.contractOnHoldSettlements = [];
          }
        });

      this.selectedContract = c;
    }
  }

  requestSettlementEvent() {
    if (this.selectedContract && this.selectedContract.ID) {
      if (this.isMaterialRequest === true) {
        let p = {
          merchantID: this.merchantID,
          settlementID: this.settlementID,
          contractID: this.selectedContract.ID,
          customerID: this.customerID,
        };

        this.settlementService.verifyMaterialRequest(p, this.sessionType).subscribe((r) => {
          if (r) {
            if (r.valid == true) {
              this.requestSettlement();
            } else if (r.error && r.proceedType === 'cancel') {
              const ref2 = RootAppComponent.dialog.open(ConfirmDialogMultipleComponent, {
                data: {
                  icon: 'fas fa-exclamation-triangle',
                  description: `<p>${r.errorHTML}</p> <p>In order to proceed, you will need to withdraw other requests.</p>`,
                  yesLabel: 'Yes, proceed.',
                  noLabel: 'Cancel',
                  isSecondConfirmation: false,
                  informationLabel: 'View Pending/Request ',
                  title: 'Do you wish to continue?',
                },
                width: '500px',
              });

              ref2.componentInstance.confirmation.subscribe((confirmation) => {
                if (confirmation == true) {
                  if (r.settlementList && r.settlementList.length > 0) {
                    let ids = _.map(r.settlementList, (item) => {
                      return item.ID;
                    });

                    this.settlementService.cancelSettlementGroup({ ids: ids }, this.sessionType).subscribe((res) => {
                      if (res) {
                        NotifyAppComponent.displayToast('success', 'Success!', '');

                        ref2.close();
                        ref2.afterClosed().subscribe((r) => {
                          this.requestSettlement();
                        });
                      }
                    });
                  } else {
                    NotifyAppComponent.displayToast('success', 'Success!', '');

                    ref2.close();
                    ref2.afterClosed().subscribe((r) => {
                      this.requestSettlement();
                    });
                  }
                }
              });
              ref2.componentInstance.information.subscribe((information) => {
                if (information == true) {
                  let data = {
                    contractID: this.selectedContract.ID,
                    statusCodes: ['REQU', 'PEND'],
                    pageSize: 100,
                  };
                  AuthenticationService.settlementList.emit(data);
                }
              });
            } else if (r.errorHTML && r.proceedType === 'approve') {
              const confirm = new ConfirmDialog(
                'fas fa-exclamation-triangle',
                'You can not request a material advance.',
                `<p>${r.errorHTML}</p>`,
                'Close',
                'View Application Settlements'
              );
              const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
                data: confirm,
                width: '600px',
              });
              ref.componentInstance.onConfirm.subscribe((confirmation) => {
                if (confirmation === false) {
                  ref.close();
                } else {
                  let data = {
                    contractID: this.selectedContract.ID,
                    pageSize: 100,
                  };
                  AuthenticationService.settlementList.emit(data);
                }
              });
            } else {
              const confirmDialog2 = new ConfirmDialogSingleButton(
                'fas fa-exclamation',
                'Material Advance is not available',
                `<p  style='text-align:center;width:100%';>${r.errorHTML}</p>`,
                'OKAY',
                false,
                'rgb(199, 0, 0)'
              );
              const ref2 = RootAppComponent.dialog.open(ConfirmDialogSingleButtonComponent, {
                data: confirmDialog2,
              });
              ref2.componentInstance.close.subscribe((res) => {
                ref2.close();
              });
            }
          }
        });
      } else {
        this.requestSettlement();
      }
    }
  }

  requestSettlement() {
    const ref = RootAppComponent.dialog.open(SettlementCreateIntroModalComponent, {
      width: '750px',
      panelClass: 'noCard',
    });
    if (ref.componentInstance.showOnSettlementCreate == true) {
      ref.close();
      ref.afterClosed().subscribe((res) => {
        this.requestSettlementSubStep();
      });
    } else {
      ref.componentInstance.closeModal.subscribe((data) => {
        ref.close();
        ref.afterClosed().subscribe((res) => {
          this.requestSettlementSubStep();
        });
      });
    }

    ref.backdropClick().subscribe(() => {
      ref.close();
      ref.afterClosed().subscribe((res) => {
        this.requestSettlementSubStep();
      });
    });
  }

  requestSettlementSubStep() {
    const canDrowDown = this.canDrawDown(this.selectedContract);

    if (Number(this.selectedContract['Settlements.NotYetDrawn']) > 0 && canDrowDown == true) {
      this.buildContractView(true);
    } else {
      const confirmMsg = new ConfirmDialogSingleButton(
        'block',
        'Cannot proceed further',
        '<p>There is no balance left to create a new settlement</p>',
        'Go Back'
      );
      const ref = this.dialog.open(ConfirmDialogSingleButtonComponent, {
        data: confirmMsg,
      });
      ref.componentInstance.onConfirm.subscribe((res) => {
        ref.close();
      });
    }
  }

  selectSettlementDate(e) {
    if (e) {
      this.settlementDate = e;
    }
  }

  selectInvoiceDate(e) {
    if (e) {
      this.invoiceDate = e;
    }
  }

  updateLater() {
    if (this.settlementObject && this.settlementObject['ID']) {
      const confirm = new ConfirmDialog(
        'warning',
        'Are you sure ?',
        'The settlement will be saved as a draft until you enter the invoice details.<br><br>No Invoice information will be saved if choose <strong>Save as Draft</strong>',
        'Continue settlement request',
        'Save as draft'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation == true) {
          ref.close();
          this.wizard.goToNextStep();
          this.sendResult();
        } else {
          ref.close();
        }
      });
    }
  }

  createInvoice() {
    if (this.settlementObject && this.settlementObject['ID']) {
      const payload = {
        invoiceDate: this.invoiceDate,
        invoiceReference: this.invoiceReference,
        invoiceDescription: this.serviceType,
        fileID: this.invoiceFileID,
        isPreTreatment: this.isPreTreatment,
        invoiceDetails: this.invoiceDetails,
        isMaterialRequest: this.isMaterialRequest,
      };

      this.settlementService.createInvoiceSettlement(this.settlementObject['ID'], payload).subscribe((res) => {
        if (res) {
          this.settlementObject = res;

          NotifyAppComponent.displayToast('success', 'Success!', 'Your Settlement has been created');

          this.wizard.goToNextStep();
          this.sendResult();
        }
      });
    }
  }

  toNumber(n) {
    if (n) {
      return Number(n);
    } else {
      return 0;
    }
  }

  createSettlement() {
    let payload;

    if (this.option == 'document') {
      payload = {
        contractID: this.selectedContract['ID'],
        settlementAmount: this.settlementAmount,
        fileID: this.settlementfileID,
        isMaterialRequest: this.isMaterialRequest,
      };

      if (this.healthFundIsRequired == true) {
        payload['insuranceReference'] = this.healthFundReference;
        payload['insuranceContribution'] = this.healthFundAmount;
        payload['insuranceProvider'] = this.healthFundName;
      }

      if (this.thirdPartyIsRequired == true) {
        payload['beneficiaries'] = this.buildPayload() || [];
      }
    } else {
      payload = {
        contractID: this.selectedContract['ID'],
        settlementAmount: this.settlementAmount,
        // securityCode: this.securityCode,
        // securityCodeID: this.securityCodeID,
      };

      if (this.healthFundIsRequired == true) {
        payload['insuranceReference'] = this.healthFundReference;
        payload['insuranceContribution'] = this.healthFundAmount;
        payload['insuranceProvider'] = this.healthFundName;
      }

      if (this.thirdPartyIsRequired == true) {
        payload['beneficiaries'] = this.buildPayload() || [];
      }
    }

    if (this.isPromoterOrAdmin == true && this.merchantID != 'none') {
      payload['merchantID'] = this.merchantID;
    }

    if (this.webhookUrl) {
      payload['webhookUrl'] = this.webhookUrl;
    }

    if (this.externalID) {
      payload['externalID'] = this.externalID;
    }

    if (this.externalSource) {
      payload['externalSource'] = this.externalSource;
    }

    if (this.transactionID) {
      payload['transactionID'] = this.transactionID;
    }

    payload['isMaterialRequest'] = this.isMaterialRequest;

    this.settlementService.createSettlement(payload, this.isPromoterOrAdmin).subscribe((res) => {
      if (res) {
        this.settlementObject = res;
        NotifyAppComponent.displayToast(
          'success',
          'Success!',
          'Your Settlement has been created on pending status waiting for you to upload the invoice details'
        );

        this.wizard.goToNextStep();

        if (this.option == 'securityCode') {
          const payload = {
            customerID: this.selectedCustomer['ID'],
            settlementID: this.settlementObject['ID'],
            contractID: this.contractID,
          };

          this.settlementService.requestSettlementCode(payload).subscribe((res) => {
            if (res) {
              this.countDown();
              this.smsSent = true;

              this.emailSent = false;
              if (res && res['Send.Email'] == '1') {
                this.emailSent = true;
              }

              this.securityCodeID = res.ID;
            }
          });
        }
      }
    });
  }

  saveDraft() {
    const confirm = new ConfirmDialog(
      'warning',
      'Are you sure ?',
      'If you choose to authorise later, the settlement will not be processed until you return and complete the authorisation. Are you sure you want to authorise later?',
      'Authorise now',
      'Authorise later'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirm,
      width: '650px',
    });

    ref.componentInstance.onConfirm.subscribe((res) => {
      if (res == true) {
        ref.close();
        this.option = null;
        if (!this.settlementObject) {
          this.createSettlement();
        }
        if (this.isSkipLookup == true) {
          this.wizard.goToStep(3);
        } else {
          this.wizard.goToStep(4);
        }
      } else {
        ref.close();
      }
    });
  }

  contractDetails() {
    this.close.emit(true);
    this.router.navigate([
      '/merchant',
      { outlets: { page: ['contract-view', this.settlementObject['Contract_key']] } },
    ]);
  }

  editThirdPartyReq(i) {
    const ref = RootAppComponent.dialog.open(SettlementThirdPartyModalComponent, {
      width: '700px',
      data: {
        bankAccountID: this.beneficiaries[i].bankAccountID,
        beneficiaryID: this.beneficiaries[i].beneficiaryID,
        amount: this.beneficiaries[i].beneficiaryAmount,
        maxAmount: this.settlementAmount - (this.healthFundAmount || 0) - this.getTotalBeneficiaryAmount(),
      },
      disableClose: true,
    });

    ref.componentInstance.getResult.subscribe((res) => {
      if (
        res &&
        res.bankAccount &&
        res.bankAccount.ID &&
        res.beneficiary &&
        res.beneficiary.ID &&
        res.amount > 0 &&
        res.amount <= this.settlementAmount - (this.healthFundAmount || 0)
      ) {
        this.beneficiaries[i].beneficiaryID = res.beneficiary.ID;
        this.beneficiaries[i].bankAccountID = res.bankAccount.ID;
        this.beneficiaries[i].beneficiaryAmount = res.amount || 0;
        this.beneficiaries[i].beneficiary = res.beneficiary;
        this.beneficiaries[i].bankAccount = res.bankAccount;
        this.beneficiaries[i].active = true;
        this.getBeneficiaryPicture(i);

        ref.close();
      } else {
        this.thirdPartyIsRequired = false;
        ref.close();
      }
    });

    ref.componentInstance.closeModal.subscribe((res) => {
      if (res) {
        ref.close();
      }
    });
  }

  openHealthFundReq() {
    this.healthFundIsRequired = true;

    if (this.healthFundIsRequired == true) {
      const ref = RootAppComponent.dialog.open(HealthfundIntroModalComponent, {
        width: '750px',
        panelClass: 'noCard',
      });

      if (ref.componentInstance.showOnHealthfund == true) {
        ref.close();
        ref.afterClosed().subscribe((res) => {
          this.openHealthFundReqSubStep();
        });
      } else {
        ref.componentInstance.closeModal.subscribe((res) => {
          if (res == true) {
            ref.close();
            ref.afterClosed().subscribe((res) => {
              this.openHealthFundReqSubStep();
            });
          }
        });
      }

      ref.backdropClick().subscribe(() => {
        ref.close();
        ref.afterClosed().subscribe((res) => {
          this.openHealthFundReqSubStep();
        });
      });
    } else {
      this.healthFundIsSet = false;
      this.healthFundAmount = null;
    }
  }

  openHealthFundReqSubStep() {
    if (this.healthFundIsRequired == true) {
      const ref = RootAppComponent.dialog.open(SettlementHealthFundModalComponent, {
        width: '650px',
        disableClose: true,
        data: {
          amount: this.healthFundAmount || 0,
          maxAmount: this.settlementAmount - (this.healthFundAmount || 0) - this.getTotalBeneficiaryAmount(),
        },
      });

      ref.componentInstance.sendAmount.subscribe((res) => {
        if (res) {
          ref.close();
          this.healthFundAmount = res.amount;
          this.healthFundName = res.name;
          this.healthFundReference = res.reference;
          this.healthFundIsSet = true;
          this.healthFundAccepted = res.isAccepted;
        }
      });

      ref.componentInstance.closeModal.subscribe((res) => {
        if (res) {
          ref.close();
          this.healthFundIsRequired = false;
        }
      });
    } else {
      this.healthFundIsSet = false;
      this.healthFundAmount = 0;
      this.healthFundName = null;
      this.healthFundReference = null;
    }
  }

  editHealthFundReqSubStep() {
    const ref = RootAppComponent.dialog.open(SettlementHealthFundModalComponent, {
      width: '650px',
      disableClose: true,
      data: {
        multipleSuppliers: this.thirdPartySuppliers,
        amount: this.healthFundAmount,
        name: this.healthFundName,
        reference: this.healthFundReference,
        maxAmount: this.settlementAmount - (this.healthFundAmount || 0) - this.getTotalBeneficiaryAmount(),
      },
    });

    ref.componentInstance.sendAmount.subscribe((res) => {
      if (res) {
        ref.close();
        this.healthFundAmount = res.amount;
        this.healthFundName = res.name;
        this.healthFundReference = res.reference;
        this.healthFundIsSet = true;
        this.healthFundAccepted = res.isAccepted;
      }
    });

    ref.componentInstance.closeModal.subscribe((res) => {
      if (res) {
        ref.close();
        this.healthFundIsRequired = false;
      }
    });
  }

  openThirdPartyReq() {
    this.thirdPartyIsRequired = true;

    if (this.thirdPartyIsRequired == true) {
      const ref = RootAppComponent.dialog.open(ThirdpartyIntroModalComponent, {
        width: '750px',
        panelClass: 'noCard',
      });

      if (ref.componentInstance.showOnThirdparty == true) {
        ref.close();
        ref.afterClosed().subscribe((res) => {
          this.openThirdPartyReqSubStep();
        });
      } else {
        ref.componentInstance.closeModal.subscribe((res) => {
          if (res == true) {
            ref.close();
            ref.afterClosed().subscribe((res) => {
              this.openThirdPartyReqSubStep();
            });
          }
        });
      }

      ref.backdropClick().subscribe(() => {
        ref.close();
        ref.afterClosed().subscribe((res) => {
          this.openThirdPartyReqSubStep();
        });
      });
    }
  }

  removeThirdPartyReq(i) {
    const confirm = new ConfirmDialog(
      'cancel',
      'Are you sure you want to delete this payment?',
      '',
      'No',
      'Yes, proceed'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirm,
    });
    ref.componentInstance.onConfirm.subscribe((confirmation) => {
      if (confirmation == true) {
        this.beneficiaries[i].active = false;
        ref.close();
      } else {
        ref.close();
      }
    });
  }

  openThirdPartyReqSubStep() {
    if (this.thirdPartyIsRequired == true) {
      const ref = RootAppComponent.dialog.open(SettlementThirdPartyModalComponent, {
        width: '700px',
        disableClose: true,
        data: {
          bankAccountID: null,
          beneficiaryID: null,
          amount: 0,
          maxAmount: this.settlementAmount - (this.healthFundAmount || 0) - this.getTotalBeneficiaryAmount(),
        },
      });

      ref.componentInstance.getResult.subscribe((res) => {
        if (
          res &&
          res.bankAccount &&
          res.bankAccount.ID &&
          res.beneficiary &&
          res.beneficiary.ID &&
          res.amount > 0 &&
          res.amount <= this.settlementAmount - (this.healthFundAmount || 0)
        ) {
          const b: any = {
            beneficiaryID: res.beneficiary.ID,
            bankAccountID: res.bankAccount.ID,
            beneficiaryAmount: res.amount || 0,
            beneficiary: res.beneficiary,
            bankAccount: res.bankAccount,
            active: true,
          };
          this.beneficiaries.push(b);

          this.getBeneficiaryPicture(this.beneficiaries.length - 1);

          ref.close();
        } else {
          this.thirdPartyIsRequired = false;
          ref.close();
        }
      });

      ref.componentInstance.closeModal.subscribe((res) => {
        if (res) {
          ref.close();
          this.thirdPartyIsRequired = false;
        }
      });
    }
  }

  resetDeductions() {
    this.healthFundIsSet = false;
    this.healthFundIsRequired = false;
    this.thirdPartyIsRequired = false;
    this.healthFundName = null;
    this.healthFundReference = null;
    this.healthFundAmount = null;
    this.thirdPartyName = null;
    this.beneficiaries = [];
  }

  getTotalBeneficiaryAmount() {
    let total = 0;

    for (let i = 0; i < this.beneficiaries.length; i++) {
      if (
        this.beneficiaries[i] &&
        this.beneficiaries[i]['beneficiaryAmount'] &&
        this.beneficiaries[i]['active'] == true
      ) {
        total = total + Number(this.beneficiaries[i]['beneficiaryAmount']);
      }
    }

    return total;
  }

  buildPayload() {
    const b = [];
    for (let i = 0; i < this.beneficiaries.length; i++) {
      const p: any = {};

      if (
        this.beneficiaries[i] &&
        this.beneficiaries[i]['active'] == true &&
        this.beneficiaries[i]['beneficiaryID'] &&
        this.beneficiaries[i]['bankAccountID'] &&
        this.beneficiaries[i]['beneficiaryAmount']
      ) {
        p['beneficiaryID'] = this.beneficiaries[i]['beneficiaryID'];

        p['bankAccountID'] = this.beneficiaries[i]['bankAccountID'];
        p['beneficiaryAmount'] = this.beneficiaries[i]['beneficiaryAmount'];

        b.push(p);
      }
    }

    return b;
  }

  isPreTreatmentToggle(e) {
    this.isPreTreatment = e;
    if (e == true) {
      const confirm = new ConfirmDialog(
        'fas fa-info',
        'DECLARATION',
        'By selecting this option you are confirming this settlement will be to pay for materials or ' +
          'services prior to treatment taking place and an invoice being produced for the patient. <br><br> If you ' +
          'have an invoice for the settlement select <strong>Cancel</strong>, otherwise select <strong>Proceed</strong> to continue.',
        'Cancel',
        'Proceed'
      );

      const ref = this.dialog.open(ConfirmDialogComponent, {
        data: confirm,
        width: '650px',
      });

      ref.componentInstance.onConfirm.subscribe((res) => {
        if (res == true) {
          this.invoiceReference = 'Purchase of Materials';
          this.invoiceFileID = null;

          ref.close();
        } else {
          ref.close();
          this.isPreTreatment = false;
        }
      });

      ref.componentInstance.closeEvent.subscribe((res) => {
        ref.close();
        this.isPreTreatment = false;
      });

      ref.backdropClick().subscribe((res) => {
        ref.close();
        this.isPreTreatment = false;
      });
    } else {
      this.invoiceReference = null;
    }
  }

  sendResult() {
    if (this.isIntegrated == true) {
      if (this.transactionWebhookURL && this.settlementObject['ID']) {
        UtilsClass.startLoadingForce();
        this.settlementService
          .triggerWebhook(this.settlementObject['ID'], 'Close', this.transactionToken, this.transactionWebhookURL)
          .subscribe((res) => {
            this.authenticationService.logout().subscribe((res) => {});
          });
      } else if (this.redirectURL && this.settlementObject && this.settlementObject['ID']) {
        this.authenticationService.logout().subscribe((res) => {
          let link = this.redirectURL + '?redirect_param=' + this.redirectParam;
          if (this.settlementObject['ID']) {
            link = link + '&settlement_id=' + this.settlementObject['ID'];
          }

          if (this.settlementObject['Contract_key']) {
            link = link + '&scontract_id=' + this.settlementObject['Contract_key'];
          }

          window.location.href = link;
        });
      } else if (this.webhookUrl && this.settlementObject['ID']) {
        UtilsClass.startLoadingForce();
        this.settlementService
          .triggerWebhook(this.settlementObject['ID'], 'Close', this.transactionToken)
          .subscribe((res) => {
            this.authenticationService.logout().subscribe((res) => {});
          });
      } else {
        this.authenticationService.logout().subscribe((res) => {
          if (this.redirectURL) {
            if (this.settlementObject['ID']) {
              window.location.href =
                this.redirectURL +
                '?settlement_id=' +
                this.settlementObject['ID'] +
                '&redirect_param=' +
                this.redirectParam;
            } else {
              window.location.href = this.redirectURL + '&redirect_param=' + this.redirectParam;
            }
          } else if (this.settlementObject['ID']) {
            this.getSettlementID.emit(this.settlementObject['ID']);
          }
        });
      }
    }
  }

  protected readonly before = before;
}
