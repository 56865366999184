<div class="row clearfix">
  <div class="row clearfix mb searchArea primary-gradient-img">
    <div class="row clearfix full-width flex" id="search">
      <div class="full-width">
        <!-- <h3 class="pull-left white rm-m">Staff List<span class="badge" countUp [endVal]="merchants.length"></span></h3> -->
        <mat-form-field class="half-width clearfix" appearance="outline">
          <mat-label>Filter</mat-label>
          <input class="clearfix" [(ngModel)]="filter" name="filter" placeholder="Filter" matInput/>

          <button
            class="white position"
            *ngIf="filter"
            (click)="filter = undefined; selectedItem = ''; $event.stopPropagation()"
            mat-button
            matSuffix
            mat-icon-button

          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>

        <!-- circle radius adjust -->

        <button class="pull-left white radius-toggle" (click)="showRadius = !showRadius" mat-stroked-button>
          <ng-container *ngIf="!showRadius">
            <mat-icon>my_location</mat-icon>
            Show range circle
          </ng-container>
          <ng-container *ngIf="showRadius">
            <mat-icon>my_location</mat-icon>
            Hide range circle
          </ng-container>
        </button>

        <div class="thrd-width radius-slider" *ngIf="showRadius" @simpleFadeAnimation>
          <mat-slider
            class="full-width"
            [(ngModel)]="radius"
            [displayWith]="formatLabel"
            thumbLabel
            tickInterval="1000"
            step="1000"
            min="1000"
            max="20000"
          ></mat-slider>

          <span class="left-label"> 1km </span>
          <span class="center-label"> Displaying {{ radius / 1000 }} km </span>
          <span class="right-label"> 20km </span>
        </div>
      </div>

      <div class="full-width">
        <!-- map interface -->

        <button class="pull-right white map-toggle" (click)="resetMap()" mat-stroked-button>
          <mat-icon class="fas fa-history"></mat-icon> Reset
        </button>

        <button class="pull-right white map-toggle" (click)="myLocation()" mat-stroked-button>
          <mat-icon class="fas fa-map-marker-alt"></mat-icon> My Location
        </button>

        <button class="pull-right white map-toggle" (click)="practiceLocation()" mat-stroked-button>
          <mat-icon class="fas {{'fa-tooth' | translate}}"></mat-icon>
          {{ "KEYWORD.practice" | translate | titlecase}} Location
        </button>

        <button class="pull-right white map-toggle" (click)="hasMapSearch = !hasMapSearch" mat-stroked-button>
          <mat-icon class="fas fa-map-marker"></mat-icon> Search Address
        </button>
      </div>
    </div>
  </div>
</div>

<div class="row clearfix flex min-height white-bg">
  <div class="thrd-width flex flex-col flex-1" *ngIf="filter">
    <div class="row clearfix full-height flex flex-col flex-1 rel" *ngIf="selectedItem" @simpleFadeAnimation>
      <button class="close-arrow" (click)="closeSelectedItem()" mat-button matTooltip="Close">
        <mat-icon>keyboard_backspace</mat-icon> Back to list
      </button>
      <app-merchant-view class="animate" [merchantID]="selectedItem['ID']"></app-merchant-view>
    </div>
    <div
      class="row clearfix full-height flex flex-col flex-1"
      *ngIf="merchants && merchants.length > 0 && !selectedItem"
      @simpleFadeAnimation
    >
      <mat-card class="card listCardView listCardSide flex flex-col flex-1" [class.push]="!selectedItem">
        <div class="row clearfix flex flex-col flex-1">
          <mat-list class="staffList">
            <mat-list-item
              *ngFor="
                let d of merchants
                  | FilterArrayMultipleContainValue: ['CalculatedName']:filter
                  | paginate: { itemsPerPage: 10, currentPage: p };
                let i = index
              "
              [class.highlightRow]="selectedItem && selectedItem.ID && selectedItem.ID == d['ID']"
              (click)="selectItem(d, i, p)"
            >
              <app-merchant-view-picture class="staffIcon text-center" *ngIf="d" [merchantID]="d['ID']">
              </app-merchant-view-picture>
              <p>{{ d.CalculatedName }}</p>
            </mat-list-item>
          </mat-list>
        </div>
        <div class="text-center">
          <mat-divider></mat-divider>
          <pagination-controls (pageChange)="pageChange($event)"></pagination-controls>
        </div>
      </mat-card>
    </div>
  </div>

  <div class="full-width flex">
    <app-map-multiple-view
      class="full-width flex flex-col"
      [displayHeaderBar]="false"
      [streetNameAttribute]="'addresses.Street Name'"
      [streetNumberAttribute]="'addresses.Street Nr'"
      [unitNumberAttribute]="'addresses.Unit'"
      [stateAttribute]="'addresses.State'"
      [suburbAttribute]="'addresses.Suburb'"
      [streetTypeAttribute]="'addresses.Street Type'"
      [countryAttribute]="'addresses.Country.Code'"
      [postCodeAttribute]="'addresses.Postcode'"
      [markerTitleAttribute]="'CalculatedName'"
      [markerDescriptionAttribute]="'addresses.Calculated'"
      [latitudeAttribute]="'Address.Latitude'"
      [longitudeAttribute]="'Address.Longitude'"
      [addMarker]="addMarkerEvent"
      [title]="title"
      [description]="description"
      [selectedItem]="selectedItemEvent"
      [isAction]="true"
      [actionIcon]="'format_list_bulleted'"
      [actionLabel]="('KEYWORD.practice' | translate | titlecase) + 's' + ' List'"
      [isLightMode]="true"
      [showCustomAction]="false"
      [allowRadius]="true"
      [showRadius]="showRadius"
      [radiusDraggable]="true"
      [radius]="radius"
      [displayActions]="false"
      [hasMapSearch]="hasMapSearch"
      (getSelected)="getSelected($event)"
      (addCoordinate)="addCoordinateEvent($event)"
      (getAction)="getActionEvent($event)"
    ></app-map-multiple-view>
  </div>
</div>

<div class="full-width clearfix row" *ngIf="(!merchants || merchants.length <= 0) && isLoaded == true">
  <app-empty-list-message></app-empty-list-message>
</div>
