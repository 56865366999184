<!-- header -->
<div class="clearfix full-width" *ngIf="isLogin == true" @simpleFadeAnimation>
  <form #resetForm="ngForm">
    <div class="card-header primary-gradient-img clearfix inModal">
      <!-- close modal -->
      <div class="row clearfix">
        <button class="btn-close btn-clear mat-button" *ngIf="isModal == true" (click)="closeEvent()">
          <mat-icon>clear</mat-icon>
        </button>
        <div class="row titleArea clearfix">
          <h2 class="summary-header rm-m">
            Confirm Your password
            <span class="subLabel">For security Reasons we need to confirm your credentials</span>
          </h2>
        </div>
      </div>
    </div>
    <!-- content area -->
    <mat-dialog-content>
      <p class="text-center">Please enter your password in the fields below.</p>

      <br/>

      <div class="full-width clearfix" *ngIf="isError == true">
        <p class="small mt warning">Please Make sure you inserted the right password</p>
      </div>

      <mat-form-field class="full-width text-center" appearance="outline">
        <mat-label>Password</mat-label>
        <!-- <input matInput placeholder="Password" value="" type="password" autocomplete="off"> -->
        <input
          [(ngModel)]="password"
          [type]="hide ? 'password' : 'text'"
          [min]="8"
          name="rePassword2"
          matInput
          placeholder="Password"
          value=""
          pattern="^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,}$"
          required
        />
        <mat-icon (click)="hide = !hide" matSuffix>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
      </mat-form-field>
    </mat-dialog-content>

    <div class="drop-button text-center full-width">
      <button class="ml mr" *ngIf="isModal == true" (click)="closeEvent()" mat-raised-button color="primary">
        Cancel
      </button>

      <button
        class="ml mr"
        [disabled]="!resetForm.form.valid"
        (click)="checkPassword()"
        mat-raised-button
        color="accent"
      >
        Submit
      </button>
    </div>
  </form>
</div>
