<div class="row clearfix gridView" @fade>
  <div class="file-container">
    <mat-card class="card clearfix main-card-template">
      <div class="card-header primary-gradient-img clearfix inModal camps" *ngIf="displayHeader == true">
        <div class="row clearfix full-width content">
          <button class="btn-close btn-clear mat-button white" *ngIf="isModal == true" (click)="closeModal()">
            <mat-icon>clear</mat-icon>
          </button>

          <button class="btn pull-right mr" (click)="newLandingPage()" mat-raised-button color="accent">
            <mat-icon class="smr fas fa-folder-plus"></mat-icon>
            New Landing Page
          </button>

          <div class="row titleArea clearfix">
            <h2 class="summary-header rm-m" *ngIf="isDefault != true">Landing Pages Gallery</h2>

            <h2 class="summary-header rm-m" *ngIf="isDefault == true">Landing Pages Templates</h2>
          </div>
        </div>
      </div>

      <mat-dialog-content class="full-width" [ngClass]="{ 'no-modal-height': isModal != true }">
        <div class="row clearfix flex" *ngIf="landingPages && landingPages.length > 3">
          <mat-form-field class="full-width mr" *ngIf="landingPages.length >= 4" appearance="outline">
            <mat-label>Search...</mat-label>
            <input
              [(ngModel)]="filter"
              matInput
              name="name"
              placeholder="Filter by label, post, supplier, tags...etc"
              tabindex="1"
            />
          </mat-form-field>

          <div class="ml filter-control pull-right" *ngIf="isDefault !== true && isDefault !== false">
            <mat-button-toggle-group class="btn-group group-list-filter" [(ngModel)]="defaultFilter" name="typess">
              <mat-button-toggle class="text-center" [value]="null">
                All

                <span class="badge" *ngIf="landingPages">{{
                  landingPages
                    | FilterArrayMultipleContainValue: ['fullName']:filter
                    | FilterArrayMultipleContainValue: ['Type.Code']:selectedType
                    | FilterArrayMultipleContainValue: ['IsActive']:selectedStatus
                    | FilterArrayMultipleContainValue: ['Tags']:selectedTagList
                    | lengthOfArray
                  }}</span>
              </mat-button-toggle>

              <mat-button-toggle class="text-center" [value]="'0'">
                {{ "KEYWORD.practice" | translate | titlecase}}

                <span class="badge" *ngIf="landingPages">{{
                  landingPages
                    | FilterArrayMultipleContainValue: ['fullName']:filter
                    | FilterArrayMultipleContainValue: ['Type.Code']:selectedType
                    | FilterArrayMultipleContainValue: ['IsActive']:selectedStatus
                    | FilterArrayMultipleContainValue: ['Tags']:selectedTagList
                    | FilterArrayMultipleContainValue: ['IsDefault']:'0'
                    | lengthOfArray
                  }}</span>
              </mat-button-toggle>

              <mat-button-toggle class="text-center" [value]="'1'">
                {{ settings['brandName'] }}

                <span class="badge" *ngIf="landingPages">{{
                  landingPages
                    | FilterArrayMultipleContainValue: ['fullName']:filter
                    | FilterArrayMultipleContainValue: ['Type.Code']:selectedType
                    | FilterArrayMultipleContainValue: ['IsActive']:selectedStatus
                    | FilterArrayMultipleContainValue: ['Tags']:selectedTagList
                    | FilterArrayMultipleContainValue: ['IsDefault']:'1'
                    | lengthOfArray
                  }}</span>
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="row clearfix flex" *ngIf="landingPages && landingPages.length > 3">
          <mat-form-field class="half-width" appearance="outline">
            <mat-label>Tags</mat-label>

            <mat-select class="margin-btm" [(ngModel)]="selectedTagList" name="Tags" multiple placeholder="Tags">
              <mat-option *ngFor="let t of merchantTags | orderBy" [value]="t">
                {{ t }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="half-width" appearance="outline">
            <mat-label>Type</mat-label>

            <mat-select class="margin-btm" [(ngModel)]="selectedType" name="ITypesDefault" placeholder="Type">
              <mat-option [value]="null">
                All
              </mat-option>
              <mat-option *ngFor="let type1 of types" [value]="type1.Code">
                <mat-icon class="{{ type1.Icon }} smr"></mat-icon>
                {{ type1.Label }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="half-width" appearance="outline">
            <mat-label>Status</mat-label>

            <mat-select class="margin-btm" [(ngModel)]="selectedStatus" name="ITypesDefault" placeholder="Type">
              <mat-option [value]="null">
                All
              </mat-option>
              <mat-option [value]="'1'"> Active</mat-option>
              <mat-option [value]="'0'"> Not Active</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="half-width pull-right" appearance="outline">
            <mat-label>Sort By</mat-label>

            <mat-select class="margin-btm" [(ngModel)]="orderByFilter" name="orderByFilter" placeholder="Sort By">
              <mat-option [value]="'-DateTimeCreated'">
                <mat-icon class="fa fa-history"></mat-icon> Recently added
              </mat-option>
              <mat-option [value]="'Label'">
                <mat-icon class="fa fa-sort-alpha-down"></mat-icon>
                Sort by name
              </mat-option>
              <mat-option [value]="'-Visitors'">
                <mat-icon class="fa fa-eye"></mat-icon>
                Sort by popularity
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <hr class="mb" *ngIf="landingPages && landingPages.length > 3"/>

        <div
          class="row clearfix text-center templateContainer flex flexwrap mb mt"
          *ngIf="landingPages && landingPages.length > 0"
          [@stagger]="landingPages.length"
          matchItemHeight="matchThis"
        >
          <mat-card
            class="template-preview file animate mat-elevation-z4 row"
            *ngFor="
              let t of landingPages
                | FilterArrayMultipleContainValue: ['fullName']:filter
                | FilterArrayMultipleContainValue: ['Type.Code']:selectedType
                | FilterArrayMultipleContainValue: ['IsActive']:selectedStatus
                | FilterArrayMultipleContainValue: ['Tags']:selectedTagList
                | FilterArrayMultipleContainValue: ['IsDefault']:defaultFilter
                | OrderArrayObject: [orderByFilter]
            "
            [@fade]
            [class.selectedTemplate]="selectedTemplate && t['ID'] == selectedTemplate['ID']"
            (click)="$event.stopPropagation();view(t); "
            matRipple
          >
            <div class="full-width card-header-container flex">
              <div
                class="card-header primary-gradient-img clearfix stacked header-landing-page full-width"
                [attr.style]="'background:' + t.theme.background + ' !important' | safeStyle"
              >
                <h4 class="summary-header text-left rm-m" *ngIf="t['Label']">
                  {{ t['Label'] }}
                  <span class="value ml chip-new" *ngIf="t['isNew'] == true" matTooltip="New  Landing Page">NEW</span>
                </h4>
              </div>
            </div>

            <div class="button-container">
              <button
                class="preview-btn"
                [attr.style]="'background:' + t.theme.color + ' !important' | safeStyle"
                (click)="$event.stopPropagation();openLandingPage(t); "
                mat-flat-button
                color="accent"
                matTooltip="View "
              >
                <mat-icon class="fas fa-desktop"></mat-icon>
              </button>

              <button
                class="preview-btn"
                [attr.style]="
                  'top:90px  !important;background:#fff !important; border: 1px solid' +
                    t.theme.color +
                    ' !important; color;' +
                    t.theme.color +
                    ' !important;' | safeStyle
                "
                (click)="$event.stopPropagation();quickView(t.ID); "
                mat-flat-button
                color="primary"
                matTooltip="Quick View"
              >
                <mat-icon class="fas fa-search" [attr.style]="'color:' + t.theme.color + ' !important' | safeStyle">
                </mat-icon>
              </button>

              <button
                class="preview-btn"
                *ngIf="t.IsDefault !== '1' || isAdminOrPromoter == true"
                [attr.style]="
                  'top:130px !important;background:#fff !important; border: 1px solid' +
                    t.theme.color +
                    ' !important; color;' +
                    t.theme.color +
                    ' !important;' | safeStyle
                "
                [matMenuTriggerFor]="previewMenu"
                (click)="$event.stopPropagation()"
                mat-flat-button
                color="primary"
              >
                <mat-icon [attr.style]="'color:' + t.theme.color + ' !important' | safeStyle">more_vert</mat-icon>
              </button>

              <mat-menu #previewMenu="matMenu">
                <button (click)="edit(t['ID'])" mat-menu-item>
                  <mat-icon>edit</mat-icon>
                  <span>Edit</span>
                </button>

                <hr *ngIf="t.IsDefault !== '1' || isAdminOrPromoter == true"/>

                <button
                  *ngIf="t['IsActive'] == '1' && (t.IsDefault !== '1' || isAdminOrPromoter == true)"
                  (click)="disable(t['ID'])"
                  mat-menu-item
                >
                  <mat-icon>cancel</mat-icon>
                  <span>Disable</span>
                </button>

                <button
                  *ngIf="t['IsActive'] != '1' && (t.IsDefault !== '1' || isAdminOrPromoter == true)"
                  (click)="enable(t['ID'])"
                  mat-menu-item
                >
                  <mat-icon>check_circle</mat-icon>
                  <span>Enable</span>
                </button>

                <hr/>
                <button (click)="clone(t)" mat-menu-item>
                  <mat-icon>content_copy</mat-icon>
                  <span>Clone</span>
                </button>

                <hr *ngIf="t.IsDefault !== '1' || isAdminOrPromoter == true"/>

                <button
                  *ngIf="t.IsDefault !== '1' || isAdminOrPromoter == true"
                  (click)="remove(t['ID'])"
                  mat-menu-item
                >
                  <mat-icon>remove_circle</mat-icon>
                  <span>Remove</span>
                </button>
              </mat-menu>
            </div>

            <!--
            <div class="flex clearfix title-container">



              <div class="full-width title-text">

                <div class="template-circle smr"
                  [attr.style]="('background:' + t.theme.background + ' !important') | safeStyle"></div>

                <p class="rm-m  accent-color">



                  <span class="mr" *ngIf="t['Treatment_Amount'] && t['Treatment_Amount']!=='0'"
                    [attr.style]="('color:' + t.theme.color + ' !important') | safeStyle">
                    {{t['Treatment_Amount'] | customCurrency}}</span>


                  <mat-icon
                    [attr.style]="('color:' + t.theme.color + ' !important;font-size:1rem  !important;') | safeStyle"
                    class="fas fa-file smr" *ngIf="t['documentIDs'] && t['documentIDs'].length>0"></mat-icon>
                  <span *ngIf="t['documentIDs'] && t['documentIDs'].length>0"
                    [attr.style]="('color:' + t.theme.color + ' !important') | safeStyle"
                    class="mr">{{ t['documentIDs'].length}}</span>

                  <mat-icon *ngIf="t.IsDefault!=='1' || isAdminOrPromoter==true"
                    [attr.style]="('color:' + t.theme.color + ' !important;font-size:1rem  !important;') | safeStyle"
                    class="fas fa-eye smr"></mat-icon>
                  <span *ngIf="t.IsDefault!=='1' || isAdminOrPromoter==true"
                    [attr.style]="('color:' + t.theme.color + ' !important') | safeStyle"
                    class="mr">{{t['Visitors']}}</span>
                </p>
              </div>
            </div> -->

            <div class="full-width cleafix image-container">
              <span class="pull-left chip chipBeta" *ngIf="t['IsActive'] != '1'">Not Active</span>

              <!-- <div class="template-header-tags full-width row clearfix matchThis"
                *ngIf="t['Tags']  && t['Tags'] .length>0">

                <p class="small inner-tags text-left rm-mt rm-mb " *ngIf="t['Tags'] && t['Tags'].length>0">
                  <span *ngFor="let d of t['Tags']    | orderBy">
                    #{{d}}
                  </span>
                </p>
              </div> -->

              <landing-page-header-image
                class="landing-page-header-image-view-list clearfix"
                [headerImage]="t['Header_Image']"
                [hideZoomButton]="true"
              >
              </landing-page-header-image>
            </div>

            <div class="template-header full-width row text-center clearfix matchThis">
              <div class="full-width">
                <p class="text-left rm-mt smb" *ngIf="t['Label'] && t['Title']">{{ t['Title'] }}</p>

                <mat-chip-list
                  class="full-width clearfix tags-patient"
                  #chipList
                  *ngIf="t['Tags'] && t['Tags'].length > 0"
                  name="tags-patient"
                >
                  <mat-chip *ngFor="let d of t['Tags'] | orderBy" [selectable]="false" [removable]="false">
                    {{ d }}
                  </mat-chip>
                </mat-chip-list>

                <p class="full-width absolute-position-spacer"></p>

                <!-- <p class="landing-page-date" matTooltip='{{t["DateTimeCreated"] | customDateTime }}'>
                  {{ t['DateTimeCreated'] | TimeLabelPast     }} ago </p> -->

                <div class="full-width title-text">
                  <mat-icon
                    class="fas fa-coins smr pull-left"
                    *ngIf="t['Treatment_Amount'] && t['Treatment_Amount'] !== '0'"
                    [attr.style]="'color:' + t.theme.color + ' !important;font-size:1rem  !important;' | safeStyle"
                  >
                  </mat-icon>

                  <span
                    class="mr pull-left"
                    *ngIf="t['Treatment_Amount'] && t['Treatment_Amount'] !== '0'"
                    [attr.style]="'color:' + t.theme.color + ' !important;  font-weight: 700 !important;' | safeStyle"
                  >
                    {{ t['Treatment_Amount'] | customCurrency }}</span
                  >

                  <mat-icon
                    class="fas fa-eye smr pull-left"
                    *ngIf="t.IsDefault !== '1' || isAdminOrPromoter == true"
                    [attr.style]="'color:' + t.theme.color + ' !important;font-size:1rem  !important;' | safeStyle"
                  ></mat-icon>
                  <span
                    class="mr pull-left"
                    *ngIf="t.IsDefault !== '1' || isAdminOrPromoter == true"
                    [attr.style]="'color:' + t.theme.color + ' !important' | safeStyle"
                  >{{ t['Visitors'] }}</span
                  >

                  <mat-icon
                    class="fas fa-image smr pull-left"
                    *ngIf="t['documentIDs'] && t['documentIDs'].length > 0"
                    [attr.style]="'color:' + t.theme.color + ' !important;font-size:1rem  !important;' | safeStyle"
                  ></mat-icon>
                  <span
                    class="mr pull-left"
                    *ngIf="t['documentIDs'] && t['documentIDs'].length > 0"
                    [attr.style]="'color:' + t.theme.color + ' !important' | safeStyle"
                  >{{ t['documentIDs'].length }}</span
                  >
                </div>
                <button
                  class="text-center pull-right clone-button"
                  *ngIf="t.IsDefault !== '1' || isAdminOrPromoter == true"
                  (click)="$event.stopPropagation();share(t); "
                  mat-icon-button
                  color="primary"
                  matTooltip="Share this Landing Page "
                >
                  <span [attr.style]="'color:' + t.theme.color + ' !important;' | safeStyle">Share</span>
                  <mat-icon class="sml" [attr.style]="'color:' + t.theme.color + ' !important;' | safeStyle"
                  >share
                  </mat-icon>
                </button>

                <button
                  class="text-center btn clone-button"
                  *ngIf="t.IsDefault == '1' && isAdminOrPromoter != true"
                  (click)=" $event.stopPropagation();clone(t);"
                  mat-raised-button
                  matTooltip="Clone this Template "
                >
                  <span [attr.style]="'color:' + t.theme.color + ' !important;' | safeStyle">Clone</span>
                  <mat-icon class="sml" [attr.style]="'color:' + t.theme.color + ' !important;' | safeStyle">
                    content_copy
                  </mat-icon>
                </button>
              </div>
            </div>
          </mat-card>
        </div>

        <div class="text-center full-width clearfix">
          <button
            class="text-center btn-large ml mr"
            *ngIf="displayLoadMore == true"
            (click)="loadMore()"
            mat-raised-button
            color="accent"
          >
            Load More Posts <mat-icon>more_horiz</mat-icon>
          </button>
        </div>

        <div
          class="margin-div text-center mt mb"
          *ngIf="
            (landingPages
              | FilterArrayMultipleContainValue: ['fullName']:filter
              | FilterArrayMultipleContainValue: ['Type.Code']:selectedType
              | FilterArrayMultipleContainValue: ['IsActive']:selectedStatus
              | FilterArrayMultipleContainValue: ['Tags']:selectedTagList
              | FilterArrayMultipleContainValue: ['IsDefault']:defaultFilter
              | lengthOfArray) <= 0 && finishLoading == true
          "
        >
          <app-empty-list-message [title]="''" [message]="'No Landing Page found'"></app-empty-list-message>
        </div>
      </mat-dialog-content>

      <div class="drop-button mt">
        <button
          class="inline selectExistingPatient pull-left"
          *ngIf="isModal == true"
          (click)="closeModal()"
          mat-raised-button
          color="primary"
        >
          Close
        </button>
      </div>
    </mat-card>
  </div>
</div>
