import { Observable, of as observableOf } from 'rxjs';

import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import { catchError, map } from 'rxjs/operators';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';

@Injectable()
export class SettingsService {
  private settingsUrl: string = environment.nodeUrl + '/settings';
  private settings: any;

  constructor(private http: HttpClient) {}

  getPublicSettings(): Observable<any> {
    return this.http.get(this.settingsUrl + '/public').pipe(
      map((res: HttpResponse<any>) => {
        const result = res['data'] || null;
        return result;
      }),
      catchError((error: any): Observable<any> => {
        return null;
      })
    );
  }

  getPublicSettingsSync() {
    if (Settings.global && Settings.global['_id']) {
      return Settings.global;
    } else if (this.settings) {
      return Settings.global;
    } else {
      let result;
      let request;

      try {
        request = new XMLHttpRequest();
        request.open('GET', this.settingsUrl + '/public', false);
        request.setRequestHeader('Content-Type', 'application/json-rpc; charset=utf-8');
        request.setRequestHeader('dataType', 'application/json');
        request.send();
        if (request.status === 200) {
          result = JSON.parse(request.responseText);

          if (result && result['data'] && result['data'][0]) {
            result = result['data'][0];
          } else {
            result = null;
          }
        } else {
          result = null;
        }
        return result;
      } catch (e) {
        NotifyAppComponent.navigation.emit('/500-error');

        // window.location.href = '500/' + 500 + '/Server is down for update';
      }
    }
  }

  getSettings(): Observable<any> {
    if (this.settings) {
      return observableOf(this.settings);
    } else {
      return this.http.get(this.settingsUrl).pipe(
        map((res: HttpResponse<any>) => {
          if (res['data'] && res['data'][0]) {
            this.settings = res['data'][0];
            return res['data'][0];
          } else {
            return null;
          }
        }),
        catchError((error: any): Observable<any> => {
          return Observable.throwError(error);
        })
      );
    }
  }

  getSettingsPublicHidden(): Observable<any> {
    if (this.settings) {
      return observableOf(this.settings);
    } else {
      return this.http.get(this.settingsUrl + '/public-hidden').pipe(
        map((res: HttpResponse<any>) => {
          if (res['data'] && res['data'][0]) {
            this.settings = res['data'][0];

            Settings.global = res['data'][0];
            return res['data'][0];
          } else {
            return null;
          }
        }),
        catchError((error: any): Observable<any> => {
          return Observable.throwError(error);
        })
      );
    }
  }

  updateSettings(id, body): Observable<any> {
    return this.http
      .put(`${this.settingsUrl}/${id}`, body)
      .pipe(catchError((error: any) => Observable.throwError(error)));
  }

  addLanguage(idSetting, body): Observable<any> {
    return this.http
      .post(`${this.settingsUrl}/${idSetting}/languages`, body)
      .pipe(catchError((error: any) => Observable.throwError(error)));
  }

  setSettings(settings): Boolean {
    this.settings = settings;
    return true;
  }

  saveAsDefault(): Observable<any> {
    return this.http
      .post(`${this.settingsUrl}/save/default`, {})
      .pipe(catchError((error: any) => Observable.throwError(error)));
  }

  setSiteOnline(): Observable<any> {
    return this.http.post(`${this.settingsUrl}/online`, {}).pipe(
      map((res: HttpResponse<any>) => res['data']),
      catchError((error: any) => Observable.throwError(error))
    );
  }

  setSiteOffline(): Observable<any> {
    return this.http.post(`${this.settingsUrl}/offline`, {}).pipe(
      map((res: HttpResponse<any>) => res['data']),
      catchError((error: any) => Observable.throwError(error))
    );
  }

  initialize(): Observable<any> {
    return this.http
      .post(`${this.settingsUrl}/init/default`, {})
      .pipe(catchError((error: any) => Observable.throwError(error)));
  }

  changeMaster(id, body): Observable<any> {
    return this.http
      .put(`${environment.nodeUrl + '/settings/ep-master'}/${id}`, body)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  saveModule(payload): Observable<any> {
    return this.http
      .post(`${environment.nodeUrl}/module-access`, payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  existingModuleCodes(): Observable<any> {
    return this.http
      .get(`${environment.nodeUrl}/module-access/code`, {})
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }
}
