<!-- header -->
<div class="clearfix full-width" *ngIf="drug && drug.ID" @simpleFadeAnimation>
  <div class="card-header primary-gradient-img clearfix inModal">
    <!-- close modal -->
    <div class="row clearfix">
      <button class="btn-close btn-clear mat-button" *ngIf="isModal == true" (click)="closeEvent()">
        <mat-icon>clear</mat-icon>
      </button>
      <div class="row titleArea clearfix">
        <app-drugs-thumbnail class="avatar" [hideZoomButton]="false" [drugID]="drug.ID"></app-drugs-thumbnail>
        <h2 class="summary-header rm-m">
          {{ drug['Name'] }}
          <span class="subLabel">{{ drug['Description'] }}</span>
        </h2>
      </div>
    </div>
    <div class="date-chip-area">
      <p class="small rm-m mr" *ngIf="drug.DateTimeCreated">
        <mat-icon class="fas fa-clock"></mat-icon>
        {{ drug.DateTimeCreated | TimeLabelPast }} ago
      </p>

      <p class="clearfix small rm-m mr ml" *ngIf="drug['IsActive'] == '1'">
        <span class="chip-enable mat-elevation-z1">Active</span>
      </p>
      <p class="clearfix small rm-m mr ml" *ngIf="drug['IsActive'] == '0'">
        <span class="chip-disable mat-elevation-z1">Not active</span>
      </p>

      <p class="clearfix small rm-m" *ngIf="drug['DrugType.Label']">
        <span class="chip-enable mat-elevation-z1">{{ drug['DrugType.Label'] | ucfirst }}</span>
      </p>
    </div>

    <div class="action-button pull-right" *ngIf="isAdminOrPromoter == true">
      <button class="pull-right" [matMenuTriggerFor]="menu" mat-raised-button>
        Actions <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button *ngIf="drug" (click)="edit()" mat-menu-item>
          <mat-icon>edit</mat-icon>
          <span>Edit Medication</span>
        </button>

        <button *ngIf="drug['IsActive'] == '1'" (click)="disable()" mat-menu-item>
          <mat-icon>cancel</mat-icon>
          <span>Disable</span>
        </button>

        <button *ngIf="drug['IsActive'] == '0'" (click)="enable()" mat-menu-item>
          <mat-icon>check_circle</mat-icon>
          <span>Enable</span>
        </button>

        <button *ngIf="drug" (click)="remove()" mat-menu-item>
          <mat-icon>delete</mat-icon>
          <span>Delete Medication</span>
        </button>
      </mat-menu>
    </div>
  </div>
  <!-- content area -->
  <mat-dialog-content>
    <div class="row clearfix flex">
      <div class="half-width">
        <app-drugs-image class="product-image mt clearfix full-width" [drugID]="drug.ID" [hideZoomButton]="false">
        </app-drugs-image>
      </div>
      <div class="full-width">
        <mat-accordion class="drug-accordion">
          <mat-expansion-panel
            class="mat-elevation-z0"
            *ngIf="drug['GeneralInformation'] || drug['DoseType.Label'] || drug['DoseUnit.Label']"
          >
            <mat-expansion-panel-header>
              <mat-panel-title>
                <h4 class="sr-title rm-mb">
                  <mat-icon class="fas fa-question-circle"></mat-icon>
                  General Information
                  <span *ngIf="drug['DoseType.Label'] || drug['DoseUnit.Label']"> & Doses</span>
                </h4>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p class="" *ngIf="drug['GeneralInformation']">{{ drug['GeneralInformation'] }}</p>
            <mat-label *ngIf="drug['DoseType.Label'] || drug['DoseUnit.Label']">Doses</mat-label>
            <p class="" *ngIf="drug['DoseType.Label'] || drug['DoseUnit.Label']">
              <span *ngIf="drug['DosageOptions']"> {{ drug['DosageOptions'] }}</span>

              <span *ngIf="drug['DoseType.Label']"> {{ drug['DoseType.Label'] }}</span>
              <span *ngIf="drug['DoseType.Label'] && drug['DoseUnit.Label']"> /</span>
              <span *ngIf="drug['DoseUnit.Label']"> {{ drug['DoseUnit.Label'] }}</span>
            </p>
          </mat-expansion-panel>
          <mat-expansion-panel
            class="mat-elevation-z0"
            *ngIf="(allergies && allergies.length > 0) || (illnesses && illnesses.length > 0)"
          >
            <mat-expansion-panel-header>
              <mat-panel-title>
                <h4 class="sr-title rm-mb">
                  <mat-icon class="fas fa-notes-medical"></mat-icon>
                  Illnesses & Allergies
                </h4>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="full-width flex">
              <div class="full-width">
                <p *ngIf="illnesses && illnesses.length > 0"><strong>Illnesses</strong></p>
                <ul class="check" *ngIf="illnesses && illnesses.length > 0">
                  <li *ngFor="let a of illnesses | orderBy">{{ a }}</li>
                </ul>
              </div>
              <div class="full-width">
                <p *ngIf="allergies && allergies.length > 0"><strong>Allergies</strong></p>
                <ul class="check" *ngIf="allergies && allergies.length > 0">
                  <li *ngFor="let a of allergies | orderBy">{{ a }}</li>
                </ul>
              </div>
            </div>
          </mat-expansion-panel>

          <mat-expansion-panel class="mat-elevation-z0" *ngIf="sideEffects && sideEffects.length > 0">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <h4 class="sr-title rm-mb">
                  <mat-icon class="fas fa-exclamation"></mat-icon>
                  Side Effects
                </h4>
              </mat-panel-title>
            </mat-expansion-panel-header>

            <ul class="exclaim" *ngIf="sideEffects && sideEffects.length > 0">
              <li *ngFor="let a of sideEffects | orderBy">{{ a }}</li>
            </ul>
          </mat-expansion-panel>

          <mat-expansion-panel class="mat-elevation-z0" *ngIf="drug['Warnings']">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <h4 class="sr-title rm-mb">
                  <mat-icon class="fas fa-exclamation-triangle"></mat-icon>
                  Warnings
                </h4>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row clearfix">
              <p [innerHTML]="drug['Warnings'] | safeHtml"></p>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel class="mat-elevation-z0" *ngIf="drug['Directions']">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <h4 class="sr-title rm-mb">
                  <mat-icon class="fas fa-directions"></mat-icon>
                  Directions
                </h4>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row clearfix">
              <p [innerHTML]="drug['Directions'] | safeHtml"></p>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel class="mat-elevation-z0" *ngIf="drug['Ingredients']">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <h4 class="sr-title rm-mb">
                  <mat-icon class="fas fa-mortar-pestle"></mat-icon>
                  Ingredients
                </h4>
              </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="row clearfix">
              <p [innerHTML]="drug['Ingredients'] | safeHtml"></p>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel class="mat-elevation-z0" *ngIf="drug['Manufacturer_Key'] || drug['Distributor_Key']">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <h4 class="sr-title rm-mb">
                  <mat-icon class="fas fa-truck"></mat-icon>
                  Suppliers
                </h4>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="full-width flex">
              <div class="full-width">
                <p
                  class="cusor-pointer"
                  (click)="viewSupplier(drug['Manufacturer_Key'])"
                  matTooltip="View Manufacturer"
                >
                  <strong>Manufacturer</strong>
                </p>
                <p
                  class="cusor-pointer"
                  *ngIf="drug['Manufacturer.Name']"
                  (click)="viewSupplier(drug['Manufacturer_Key'])"
                  matTooltip="View Manufacturer"
                >
                  {{ drug['Manufacturer.Name'] }}
                </p>
              </div>
              <div class="full-width">
                <p class="cusor-pointer" (click)="viewSupplier(drug['Distributor_Key'])" matTooltip="View Distributor">
                  <strong>Distributor</strong>
                </p>
                <p
                  class="cusor-pointer"
                  *ngIf="drug['Distributor.Name']"
                  (click)="viewSupplier(drug['Distributor_Key'])"
                  matTooltip="View Distributor"
                >
                  {{ drug['Distributor.Name'] }}
                </p>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
  </mat-dialog-content>
  <div class="full-width clearfix text-center">
    <div class="drop-button" *ngIf="isModal == true">
      <button class="step-next" (click)="closeEvent()" mat-raised-button color="primary">Close</button>
    </div>
  </div>
</div>
