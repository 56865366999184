import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output, SimpleChanges } from '@angular/core';

import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CardService } from '../shared/card.service';

@Component({
  selector: 'app-card-picture',
  templateUrl: './card-picture.component.html',
  styleUrls: ['./card-picture.component.css'],
})
export class CardPictureComponent implements OnInit {
  @Input() cardID;
  @Input() tableName;
  @Input() LastModified = null;
  @Input() hideZoomButton;

  @Output() close = new EventEmitter();
  profileLink;

  constructor(
    private messageService: CardService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data && typeof data == 'string') {
      this.cardID = data;
    }
  }

  ngOnInit() {
    if (this.cardID) {
      this.getProfilePicture();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.cardID) {
      this.getProfilePicture();
    } else {
      this.profileLink = null;
    }
  }

  getProfilePicture() {
    this.profileLink = null;

    if (this.cardID !== '00000000000000000000') {
      this.profileLink = this.messageService.getProfilePicStreamLink(this.cardID, this.tableName, null);
    }
  }
  closeEvent() {
    this.close.emit(true);
  }
}
