<mat-card class="rel clearfix step-1-normal">
  <div class="card-header primary-gradient-img clearfix stacked" style=" margin-bottom: 0;">

    <div class="row clearfix">
      <div class="row titleArea clearfix">
        <h2 class="summary-header text-left rm-m">Bulk {{messageLabel}} Overview

          <span class="subLabel text-left">
            Create and send large volumes of {{messageLabel}} Messages simultaneously to a targeted audience. This
            feature is ideal
            for marketing campaigns, new product launches, patient notifications, alerts and much more.
          </span>
        </h2>

      </div>
    </div>
  </div>

  <div class="full-widh mt " *ngIf="isPromoterOrAdmin==true">

    <mat-form-field class="white half-width" *ngIf="merchants && merchants.length > 0" appearance="outline">
      <mat-label>{{ "KEYWORD.practice" | translate | titlecase }}s</mat-label>
      <mat-select class="" [placeholder]="('KEYWORD.practice' | translate | titlecase) + 's'" name="merchants"
        [(ngModel)]="_merchantID" (ngModelChange)="changeMerchant()">

        <mat-option [value]="'all'">
          <strong>All</strong>
        </mat-option>
        <mat-option *ngIf="currentPractice && currentPractice.ID" [value]="currentPractice.ID">
          <strong>My Account</strong>
        </mat-option>

        <hr>
        <mat-option *ngFor="let a of merchants | orderBy" [value]="a.ID">
          <app-card-picture class="side-card-picture3 mr" [tableName]="'Merchant'" [cardID]="a['ID']">
          </app-card-picture>


          {{ a.TradingAs }}
        </mat-option>
      </mat-select>
    </mat-form-field>


  </div>

  <div class="full-widh " *ngIf="isStateLoad === true  && !statistics">

    <app-empty-list-message [message]="'No Bulk '+messageLabel+' Found'" [isAction]="true" [actionIcon]="'add'"
      [actionLabel]="'New Bulk '+messageLabel" (actionEvent)="add()"></app-empty-list-message>


  </div>

  <div class="full-widh " *ngIf="isStateLoad === true  && statistics">

    <h3 class="sr-title text-left full-width   rm-m mt ">
      Summary of All Campaigns
    </h3>



    <div class="full-width  flex  ">
      <mat-card class="full-width mat-elevation-z3 mt ">
        <div class="full-width flex">
          <div class="full-width global-state">

            <h3 class="sr-title ml text-left full-width  rm-mt smb ">
              Messages
            </h3>


            <div class="full-widh ml">

              <p>
                <mat-icon class="{{messageUtil.getStatusIcon('Paused')}} mr"
                  [ngStyle]="{ 'color': messageUtil.getStatusColor('Paused', true) }"></mat-icon><strong
                  [ngStyle]="{ 'color': messageUtil.getStatusColor('Paused', true) }">{{globalStatistics.Queued}}</strong>
                SMSs are queued and ready to be sent.
              </p>


              <p>
                <mat-icon class="{{messageUtil.getStatusIcon('Scheduled')}} mr"
                  [ngStyle]="{ 'color': messageUtil.getStatusColor('Scheduled', false) }"></mat-icon><strong
                  [ngStyle]="{ 'color': messageUtil.getStatusColor('Scheduled', false) }">{{globalStatistics.ScheduledMessages}}</strong>
                SMSs are scheduled to be sent.
              </p>

              <p>
                <mat-icon class="fas fa-envelope-open mr" [ngStyle]="{ 'color':'gold' }"></mat-icon><strong
                  [ngStyle]="{ 'color':'gold' }">{{globalStatistics.Delivered}}</strong>
                SMSs have been delivered
              </p>

              <p>
                <mat-icon class="fas fa-users  primary-color mr">
                </mat-icon><strong class="primary-color">{{globalStatistics.Submitted}}</strong>
                All potential SMSs to send.
              </p>


            </div>
            <h3 class="sr-title ml text-left full-width   mt smb ">
              Campaigns
            </h3>
            <div class="full-widh ml">


              <p>
                <mat-icon class="{{messageUtil.getStatusIcon('Complete')}} mr"
                  [ngStyle]="{ 'color': messageUtil.getStatusColor('Complete', true) }"></mat-icon><strong
                  [ngStyle]="{ 'color': messageUtil.getStatusColor('Complete', true) }">{{globalStatistics.complete}}</strong>
                Campaigns have been completed.
              </p>

              <p>
                <mat-icon class="{{messageUtil.getStatusIcon('Canceled')}} mr"
                  [ngStyle]="{ 'color': messageUtil.getStatusColor('Canceled', true) }"></mat-icon><strong
                  [ngStyle]="{ 'color': messageUtil.getStatusColor('Canceled', true) }">{{globalStatistics.canceled}}</strong>
                Campaigns have been cancelled.
              </p>

              <p>
                <mat-icon class="fas fa-stopwatch mr" *ngIf="globalStatistics.progress<100"
                  [ngStyle]="{ 'color': messageUtil.getStatusColor('Draft', true) }"></mat-icon>
                <strong *ngIf="globalStatistics.progress<100"
                  [ngStyle]="{ 'color': messageUtil.getStatusColor('Draft', true) }">{{globalStatistics.progress}}%</strong>


                <mat-icon class="fas fa-paper-plane mr" *ngIf="globalStatistics.progress>=100"
                  [ngStyle]="{ 'color': messageUtil.getStatusColor('Submitted', true) }"></mat-icon>
                <strong *ngIf="globalStatistics.progress>=100"
                  [ngStyle]="{ 'color': messageUtil.getStatusColor('Submitted', true) }">{{globalStatistics.progress}}%</strong>

                Of current campaign messages have been sent.
              </p>



            </div>
            <div class="full-widh text-center mt">



              <button class=" btn ml mt pull-right mr" (click)="refreshMessageGroup()" mat-raised-button color="primary">
                <mat-icon class="fas fa-sync-alt smr"></mat-icon> Refresh Statistics
              </button>


              <button class=" btn ml mt  pull-right " (click)="hardRefresh()" mat-raised-button color="accent">
                <mat-icon class="fas fa-redo-alt smr"></mat-icon> Hard Refresh
              </button>

            </div>

          </div>

          <div class="half-width add-new-message-group-container">

            <div class="full-width add-new-message-group">

            </div>
            <div class="full-width flex">
              <div class="full-width" style="padding: 16px;">


                <div class="full-width">

                  <h3 class="sr-title  text-left full-width rm-ml rm-mb  ">
                    Boost your reach with Bulk SMSs!
                  </h3>

                  <p style=" line-height: 1.8rem;       ">
                    Send thousands of messages instantly. <br>
                    Engage {{'patient' | translate}}s, promote offers, and drive results.
                    Easy, fast, and cost-effective. <br>

                    Try it now! 🚀
                  </p>


                </div>

                <div class="full-widh text-center smt">


                  <button class=" btn ml pull-right " (click)="add()" mat-raised-button color="accent">
                    <mat-icon class="fas fa-plus smr"></mat-icon> New Bulk {{messageLabel}}
                  </button>


                </div>

              </div>
            </div>
          </div>

        </div>


      </mat-card>


    </div>

    <div class="full-width mt  ">


      <h3 class="sr-title text-left smb   full-width">
        Delivery Status
      </h3>

      <p>
        Monitoring the performance and success of your delivery of messages below.
      </p>



      <div class="full-width flex   mt mb"
        *ngIf="(chartVisitorDataLocal && chartVisitorDataLocal.length>0) || (chartVisitorData && chartVisitorData.length>0)">
        <div class="full-width flex smr">
          <app-empty-list-message class="mr full-width report-empty"
            *ngIf="!chartVisitorDataLocal || (chartVisitorDataLocal && chartVisitorDataLocal.length<=0)"
            style=" height: 297px; " [message]="'No Queue Report found'" [message]="'No messages found in the Queue'">
          </app-empty-list-message>
          <mat-card class="full-width mat-elevation-z3 mr "
            *ngIf="chartVisitorDataLocal && chartVisitorDataLocal.length>0" style=" height: 297px; ">
            <div class="colour-stripe"></div>
            <div class="full-width flex">
              <div class="chart-view full-width">
                <div class="row clearfix">
                  <h3 class="sr-title text-center ">
                    Queue Status
                  </h3>

                </div>
                <div class="base-chart-margin base-chart-margin-container base-chart-margin-container-msg-group rel mr">
                  <div class="total anim">
                    <span class="totLab">Total</span>
                    <span class="balance" [endVal]="totalMessagesLocal" countUp></span>
                    <!-- <span class="totSub">Total</span> -->
                  </div>
                  <app-chart-input class="fullHeight visitor-charts mb clearfix" [titleIsTop]="false"
                    [showLegend]="false" [colorScheme]="colorSchemeLocal" [legendPosition]="'right'" [showLabel]="false"
                    [chartType]="'pie'" [title]="" [data]="chartVisitorDataLocal" [dataNameAttribute]="'name'"
                    [dataNameAttribute]="'value'">
                  </app-chart-input>
                </div>
              </div>
              <div class=" full-width  ml    preview-card-container-legend  ">
                <div class="full-width flex smb legend-holder" *ngFor="let s of chartVisitorDataLocal"
                  (click)="viewMessageList(s.code,null)" matTooltip="Click to check  messages list ">
                  <div class="full-width">
                    <p class="small ml pull-left " style="  padding-top: 5px; ">
                      <mat-icon class="{{messageUtil.getStatusIcon(s.code)}} smr"
                        style="font-size: 0.9rem;opacity: 0.8;"
                        [ngStyle]="{ 'color':  messageUtil.getStatusColor(s.code,true)  }"></mat-icon>

                      <strong> {{s.name}}</strong>
                    </p>
                  </div>
                  <p class="small  full-width text-right ">
                    <span class="pull-right mr status-container "
                      [ngStyle]="{ 'background-color':  messageUtil.getStatusColor(s.code,true)  }">
                      {{s.value}}
                    </span>
                  </p>
                </div>
                <!-- <p class="chart-total-text pull-right"> Total: <span [endVal]="totalMessages" countUp></span></p> -->
              </div>
            </div>
          </mat-card>
        </div>
        <div class="full-width flex sml">
          <app-empty-list-message class=" full-width report-empty"
            *ngIf="!chartVisitorData || (chartVisitorData && chartVisitorData.length<=0)" style=" height: 297px; "
            [message]="'No Delivery Report found'">
          </app-empty-list-message>
          <mat-card class="full-width mat-elevation-z3  " *ngIf="chartVisitorData && chartVisitorData.length>0"
            style=" height: 297px; ">
            <div class="colour-stripe"></div>
            <div class="full-width flex">
              <div class="chart-view full-width">
                <div class="row clearfix">
                  <h3 class="sr-title text-center ">
                    Messages Delivery Status
                  </h3>

                </div>
                <div class="base-chart-margin base-chart-margin-container base-chart-margin-container-msg-group rel mr">
                  <div class="total anim">
                    <span class="totLab">Total</span>
                    <span class="balance" [endVal]="totalMessages" countUp></span>
                    <!-- <span class="totSub">Total</span> -->
                  </div>
                  <app-chart-input class="fullHeight visitor-charts mb clearfix" [titleIsTop]="false"
                    [showLegend]="false" [colorScheme]="colorScheme" [legendPosition]="'right'" [showLabel]="false"
                    [chartType]="'pie'" [title]="" [data]="chartVisitorData" [dataNameAttribute]="'name'"
                    [dataNameAttribute]="'value'">
                  </app-chart-input>
                </div>
              </div>
              <div class=" full-width  ml    preview-card-container-legend  ">
                <div class="full-width flex smb legend-holder" *ngFor="let s of chartVisitorData"
                  (click)="viewMessageList(null,s.code)" matTooltip="Click to check  messages list ">
                  <div class="full-width">
                    <p class="small ml pull-left " style="  padding-top: 5px; ">
                      <mat-icon class="{{messageUtil.getMessageStatusIcon(s.code)}} smr"
                        style="font-size: 0.9rem;opacity: 0.8;"
                        [ngStyle]="{ 'color':  messageUtil.getStatusColor(s.code,false)  }"></mat-icon>
                      <strong> {{s.name}}</strong>
                    </p>
                  </div>
                  <p class="small  full-width text-right ">
                    <span class="pull-right mr status-container "
                      [ngStyle]="{ 'background-color':  messageUtil.getStatusColor (s.code,false)  }">
                      {{s.value}}
                    </span>
                  </p>
                </div>
                <!-- <p class="chart-total-text pull-right"> Total: <span [endVal]="totalMessages" countUp></span></p> -->
              </div>
            </div>
          </mat-card>
        </div>
      </div>


    </div>

    <div class=" full-width flex">
      <mat-card class="full-width flex">
        <div class=" full-width">
          <div class="clearfix  smb flex  full-width flex-center">
            <mat-button-toggle-group class="btn-group rm-m selectType full-width  flex tab"
              style="margin-right: 0px !important;" [(ngModel)]="status">



              <mat-button-toggle [value]="null" class="full-width">
                All
              </mat-button-toggle>



              <mat-button-toggle *ngFor="let s of activeStatusArray" [value]="s.status" class="full-width">


                <mat-icon class=" sml fas {{s.icon}}" style="
          font-size: 0.9rem !important;  ">
                </mat-icon>

                {{s.status}}


              </mat-button-toggle>


            </mat-button-toggle-group>
          </div>


          <div class="clearfix">
            <div class="row full-width flex clearfix">
              <app-message-group-list class="full-width  message-list-overview-items " [pageSize]="pageSize"
                [status]="status" [merchantID]="merchantID">
              </app-message-group-list>
            </div>
          </div>
        </div>
      </mat-card>
    </div>

    <mat-expansion-panel class="full-width mb mt advanced-search" [expanded]="true">
      <mat-expansion-panel-header style="height: 80px !important">
        <mat-panel-title>
          <p class="full-width text-left sml mb   smt">
            <strong class="smr">SMS Statuses: </strong> see the list below for a better understanding of the different
            types of
            statuses. Click on each icon for a detailed explanation.
          </p>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="full-width flex mt full-width full-width status-holder">
        <div class="full-width flex">
          <div class="qtr-width" style="    background: #f9f9f9; padding-right: 10px; padding-left: 10px ;"
            *ngIf="isSMS==true">
            <h3 class="sr-title text-left full-width  ">
              Campaign Status
            </h3>
            <div class="full-width mr">
              <p class="full-width smb rm-mt " *ngFor="let s of splitCampaginStatusArray(1)">
                <span [ngStyle]="{ 'background-color':  messageUtil.getStatusColor (s,true)  }"
                  (click)="displayStatusGroupDescription(s)" class="status-container">
                  <mat-icon class="{{messageUtil.getStatusIcon(s)}} smr "
                    style="font-size:  0.9em;width: 0.9rem;height: 0.9rem;">
                  </mat-icon> {{s}}
                </span>
              </p>
            </div>
          </div>
          <div class="qtr-width"
            style="    background: #f9f9f9; padding-right: 10px; padding-left: 10px ;     padding-top: 53px;"
            *ngIf="isSMS==true">

            <div class="full-width mr">
              <p class="full-width smb rm-mt " *ngFor="let s of splitCampaginStatusArray(2)">
                <span [ngStyle]="{ 'background-color':  messageUtil.getStatusColor (s,true)  }"
                  (click)="displayStatusGroupDescription(s)" class="status-container">
                  <mat-icon class="{{messageUtil.getStatusIcon(s)}} smr "
                    style="font-size:  0.9em;width: 0.9rem;height: 0.9rem;">
                  </mat-icon> {{s}}
                </span>
              </p>
            </div>
          </div>
          <div class="qtr-width" style="    background: #f9f9f9; padding-right: 10px; padding-left: 10px ;"
            *ngIf="isSMS!=true">
            <h3 class="sr-title text-left full-width  ">
              Campaign Status
            </h3>
            <div class="full-width mr">
              <p class="full-width smb rm-mt " *ngFor="let s of messageGroupStatus">
                <span [ngStyle]="{ 'background-color':  messageUtil.getStatusColor (s,true)  }"
                  (click)="displayStatusGroupDescription(s)" class="status-container">
                  <mat-icon class="{{messageUtil.getStatusIcon(s)}} smr "
                    style="font-size:  0.9em;width: 0.9rem;height: 0.9rem;">
                  </mat-icon> {{s}}
                </span>
              </p>
            </div>
          </div>
          <div class="full-width ">
            <h3 class="sr-title ml text-left full-width  ">
              Message Status
            </h3>
            <div class="full-width  flex">
              <div class="full-width  " style="border-left: 1px solid #909090;">
                <p class="full-width smb rm-mt ml " *ngFor="let s of statusMessageArray1">
                  <span [ngStyle]="{ 'background-color':  messageUtil.getStatusColor (s,false)  }"
                    (click)="displayStatusDescription(s)" class="status-container">
                    <mat-icon class="{{messageUtil.getMessageStatusIcon(s)}} smr "
                      style="font-size:  0.9em;width: 0.9rem;height: 0.9rem;">
                    </mat-icon> {{s}}
                  </span>
                </p>
              </div>
              <div class="full-width ">
                <p class="full-width smb rm-mt  " *ngFor="let s of statusMessageArray2">
                  <span [ngStyle]="{ 'background-color':  messageUtil.getStatusColor (s,false)  }"
                    (click)="displayStatusDescription(s)" class="status-container">
                    <mat-icon class="{{messageUtil.getMessageStatusIcon(s)}} smr "
                      style="font-size:  0.9em;width: 0.9rem;height: 0.9rem;">
                    </mat-icon> {{s}}
                  </span>
                </p>
              </div>
              <div class="full-width " style="border-right: 1px solid #909090;">
                <p class="full-width smb rm-mt mr " *ngFor="let s of statusMessageArray3">
                  <span [ngStyle]="{ 'background-color':  messageUtil.getStatusColor (s,false)  }"
                    (click)="displayStatusDescription(s)" class="status-container">
                    <mat-icon class="{{messageUtil.getMessageStatusIcon(s)}} smr "
                      style="font-size:  0.9em;width: 0.9rem;height: 0.9rem;">
                    </mat-icon> {{s}}
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div class="qtr-width" style="    background: #f9f9f9; padding-right: 20px; padding-left: 20px ; ">
            <h3 class="sr-title text-left full-width  ">
              Queue Status
            </h3>
            <div class="full-width ">
              <p class="full-width smb rm-mt " *ngFor="let s of statusArray">
                <span [ngStyle]="{ 'background-color':  messageUtil.getStatusColor (s,true)  }"
                  class="status-container">
                  <mat-icon class="{{messageUtil.getStatusIcon(s)}} smr "
                    style="font-size:  0.9em;width: 0.9rem;height: 0.9rem;">
                  </mat-icon> {{s}}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </mat-expansion-panel>

  </div>

</mat-card>
