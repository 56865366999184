import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class PriceServiceService {
  constructor(private http: HttpClient) {}

  /**
   * Description
   * Get Customer details
   */
  getPriceServiceDetails(ID): Observable<any> {
    return this.http
      .get(environment.nodeUrl + '/priceService/' + ID)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  /**
   * Description
   * Get Customers List
   */
  getPriceServiceList(payload): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };
    return this.http
      .get(`${environment.nodeUrl}/priceService`, options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  /**
   * Description
   * Get Customers List
   */
  modifyPriceService(ID, payload): Observable<any> {
    return this.http
      .put(`${environment.nodeUrl}/priceService/${ID}`, payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }
}
