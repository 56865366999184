import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import * as JSZip from 'jszip';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { PracticeAppointmentCancellationPolicyComponent } from '../../../core/session/practice-appointment-cancellation-policy/practice-appointment-cancellation-policy.component';
import { ConfirmDialogSingleButtonComponent } from '../../../shared/components/confirm-dialog-single-button/confirm-dialog-single-button.component';
import { ImageViewListComponent } from '../../../shared/components/image-view-list/image-view-list.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { CustomCurrencyPipe } from '../../../shared/pipes/custom-currency.pipe';
import { CustomPhonePipe } from '../../../shared/pipes/custom-phone.pipe';
import { ConfirmDialogSingleButton } from '../../../shared/types/confirm-dialog-single-button';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import {
  AppointmentAvailabilityComponent,
  AppointmentAvailabilityComponentDialogData,
} from '../appointment-availability/appointment-availability.component';
import {
  AppointmentIframeViewComponent,
  AppointmentIframeViewComponentDialogData,
} from '../appointment-iframe-view/appointment-iframe-view.component';
import {
  AppointmentPageCustomizeComponent,
  AppointmentPageCustomizeComponentDialogData,
} from '../appointment-page-customize/appointment-page-customize.component';

import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { MerchantService } from '../../merchant/shared/merchant.service';

@Component({
  selector: 'app-appointment-landing-create',
  templateUrl: './appointment-landing-create.component.html',
  styleUrls: ['./appointment-landing-create.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.row', style({ opacity: 0 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({
                  opacity: 0,
                  transform: 'translateY(-75%)',
                  offset: 0,
                  height: 0,
                }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({
                  opacity: 1,
                  transform: 'translateY(0)',
                  offset: 1.0,
                  height: '*',
                }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.row', style({ opacity: 1 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({
                  opacity: 1,
                  transform: 'translateY(0)',
                  offset: 0,
                  height: '*',
                }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({
                  opacity: 0,
                  transform: 'translateY(-75%)',
                  offset: 1.0,
                  height: 0,
                }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class AppointmentLandingCreateComponent implements OnInit, OnDestroy {
  settings = Settings.global;

  title = 'Request an Appointment';
  description =
    'Let your dentist know what time might suit you best for an appointment. The  practice will then contact you to arrange a suitable appointment date.';

  practiceLandingPageCode;
  backgroundCode = 'default';
  downloadQRCode = new EventEmitter();
  backgroundFileID;
  backgroundImage;
  displayStaffAppointment = false;
  displayAppointmentLookup = false;
  displayAppointmentLookupSorting = false;
  showContact = true;
  showHeader = true;
  backgroundColor = '#1b8bdd';
  backgroundStyle;

  width = 650;
  height = 800;

  iframeSrc;
  merchantID;
  queryParams;
  iframeCode;
  contactColor = '#ffffff';
  contactBackgroundColor = '#1b8bdd';
  displayAdmin = false;
  currentPractice;
  useThirdPartyBookingURL = false;
  thirdPartyBookingURL = '';

  loadIFrame = false;
  utils = new UtilsClass();

  isAppointmentDisabled = true;

  isFirstLoad = false;
  shortLink;
  appointmentLandingPage;

  constructor(
    private authenticationService: AuthenticationService,
    private _sanitizer: DomSanitizer,
    private dialog: MatDialog,
    private customCurrencyPipe: CustomCurrencyPipe,
    private customPhonePipe: CustomPhonePipe,
    private merchantService: MerchantService,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router
  ) {}

  ngOnInit() {
    this.authenticationService.getCurrentUser().subscribe((res) => {
      if (res && res.data && res.data.merchantKey) {
        AuthenticationService.updateDrift.emit('none');

        this.merchantID = res.data.merchantKey;
      }
    });

    this.authenticationService.getCurrentPractice().subscribe((res) => {
      if (res) {
        this.updateCurrentPractice(res);
      }
    });
  }

  previewAdvanceIntegration(): void {
    const dialogData: AppointmentIframeViewComponentDialogData = {
      iFrameSrc: this.getPreviewLinkFromCurrentPractice(this.currentPractice),
    };
    const ref = RootAppComponent.dialog.open(AppointmentIframeViewComponent, {
      panelClass: 'noCard',
      data: dialogData,
      width: this.width + 'px',
      height: this.height + 'px',
    });
  }

  getAppointmentCancellingPolicy(currentPractice: any): string {
    if (currentPractice && currentPractice['Appointment_Cancelling_Policy']) {
      let appointmentCancellingPolicy: string = currentPractice['Appointment_Cancelling_Policy'];
      // {{PracticeName}}
      appointmentCancellingPolicy = appointmentCancellingPolicy
        .split('{{PracticeName}}')
        .join(currentPractice['TradingAs'] || '');

      // {{Fee}}
      const fee = currentPractice['Appointment_Cancelling_Fee'] || 55;
      appointmentCancellingPolicy = appointmentCancellingPolicy
        .split('{{Fee}}')
        .join(this.customCurrencyPipe.transform(fee));

      // {{Hours}}
      appointmentCancellingPolicy = appointmentCancellingPolicy
        .split('{{Hours}}')
        .join(currentPractice['Appointment_Cancelling_Hours'] || 24);

      // {{Description}}
      appointmentCancellingPolicy = appointmentCancellingPolicy
        .split('{{Description}}')
        .join(currentPractice['Description'] || '');

      // {{PracticeEmail}}
      appointmentCancellingPolicy = appointmentCancellingPolicy
        .split('{{PracticeEmail}}')
        .join(currentPractice['emails.Email'] || '');

      // {{PracticePhone}}
      const phone = this.customPhonePipe.transform(currentPractice['phones.Number']);
      appointmentCancellingPolicy = appointmentCancellingPolicy.split('{{PracticePhone}}').join(phone || '');

      return appointmentCancellingPolicy;
    }
    return '';
  }

  onCompleteAll(fileID) {
    if (fileID && fileID[0]) {
      this.backgroundFileID = fileID[0];
      this.backgroundImage = `${environment.nodeUrl}/files/file-view/${this.backgroundFileID}`;
    }
  }

  clickCopy() {
    NotifyAppComponent.displayToast('success', 'Code copied', '');
  }

  linkCopy() {
    NotifyAppComponent.displayToast('success', 'Link copied', '');
  }

  goToLink() {
    this.getLink();

    if (this.appointmentLandingPage) {
      window.open(this.appointmentLandingPage, '_blank');
    }
  }

  previewCancellationPolicy(): void {
    const confirm = new ConfirmDialogSingleButton(
      'fas fa-info',
      'Appointment Cancelling Policy',
      this.getAppointmentCancellingPolicy(this.currentPractice),
      'okay'
    );
    // Confirm Dialog Single Button
    const ref = this.dialog.open(ConfirmDialogSingleButtonComponent, {
      data: confirm,
      width: '650px',
    });

    ref.componentInstance.onConfirm.subscribe((res) => {
      ref.close();
    });
  }

  personaliseCancellationPolicy(): void {
    const ref = RootAppComponent.dialog.open(PracticeAppointmentCancellationPolicyComponent, {
      data: {},
      width: '750px',
    });

    ref.componentInstance.editedAppointmentCancellingPolicy.subscribe((res) => {
      if (res) {
        this.updateCurrentPractice(res);
      }
    });

    ref.componentInstance.closeModal.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  onSaveThirdPartyBookingURL(url: string): void {
    const val = this.useThirdPartyBookingURL ? url : 'void';

    const payload = {
      thirdPartyBooking_URL: val,
      useThirdPartyBookingURL: this.useThirdPartyBookingURL,
    };
    if (val && val != 'void') {
      const confirmDialog = new ConfirmDialog(
        'warning',
        'Are you sure you want to continue ?',
        'By using your own booking engine, you and your patients will no longer be able to use our Appointment feature',

        'Cancel',
        'Okay'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirmDialog,
        // SET TO PAYING CASH CARD
      });
      ref.componentInstance.onConfirm.subscribe((val) => {
        if (val == true) {
          ref.close();
          ref.afterClosed().subscribe((res) => {
            this.authenticationService.editCurrentPractice(payload).subscribe((res) => {
              this.authenticationService.getCurrentUserForce().subscribe((res1) => {
                // update currentPractice property
                this.updateCurrentPractice(res);
                NotifyAppComponent.displayToast('success', 'Successed', 'Your profile has been updated successfully');
              });
            });
          });
        } else {
          ref.close();
        }
      });
    } else {
      this.authenticationService.editCurrentPractice(payload).subscribe((res) => {
        this.authenticationService.getCurrentUserForce().subscribe((res1) => {
          // update currentPractice property
          this.updateCurrentPractice(res);
          NotifyAppComponent.displayToast('success', 'Successed', 'Your profile has been updated successfully');
        });
      });
    }
  }

  personaliseLookAndFeel(): void {
    const dialogData: AppointmentPageCustomizeComponentDialogData = {
      currentPractice: this.currentPractice,
    };

    const ref = RootAppComponent.dialog.open(AppointmentPageCustomizeComponent, {
      data: dialogData,
      width: '900px',
    });

    ref.componentInstance.editedCurrentPractice.subscribe((currentPractice) => {
      if (currentPractice) {
        this.updateCurrentPractice(currentPractice);
      }
    });

    ref.componentInstance.closeModal.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  ngOnDestroy() {
    AuthenticationService.updateDrift.emit('merchant');
  }

  updatePreview(changeLink = true): void {
    const link = this.getPreviewLinkFromCurrentPractice(this.currentPractice);
    this.queryParams = link;
    if (changeLink) {
      this.iframeSrc = this._sanitizer.bypassSecurityTrustResourceUrl(link);

      // this.loadIFrame = false;
    }

    if (this.iframeSrc) {
      if (this.appointmentLandingPage) {
        this.iframeCode = `<iframe \n style="border:none !important;"  \n width="${this.width}px" height="${this.height}px"     \n src="${this.appointmentLandingPage}"> \n </iframe>`;
      }
    }
  }

  previewToNewTab() {
    const link = this.getPreviewLinkFromCurrentPractice(this.currentPractice);
    window.open(link, '_blank');
  }

  personaliseAppointmentAvailability(): void {
    const dialogData: AppointmentAvailabilityComponentDialogData = {
      merchantID: this.currentPractice['ID'],
    };

    const ref = RootAppComponent.dialog.open(AppointmentAvailabilityComponent, {
      data: dialogData,
      width: '500px',
    });

    ref.componentInstance.editedMerchant.subscribe((res) => {
      if (res) {
        this.updateCurrentPractice(res);
      }
    });

    ref.componentInstance.closeModal.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  staffAvailability() {
    this.displayStaffAppointment = true;
    this.displayAppointmentLookup = false;
    this.displayAppointmentLookupSorting = false;
  }

  appointmentLookup() {
    this.displayStaffAppointment = false;
    this.displayAppointmentLookup = true;
    this.displayAppointmentLookupSorting = false;
  }

  appointmentLookupSorting() {
    this.displayStaffAppointment = false;
    this.displayAppointmentLookup = false;
    this.displayAppointmentLookupSorting = true;
  }

  backToMain() {
    this.displayStaffAppointment = false;
    this.displayAppointmentLookup = false;
    this.displayAppointmentLookupSorting = false;
  }

  onTestBookingUrl() {
    if (this.thirdPartyBookingURL) {
      window.open(this.thirdPartyBookingURL, '_blank');
    }
  }

  updateCurrentPractice(currentPractice) {
    if (currentPractice) {
      this.currentPractice = currentPractice;
      this.thirdPartyBookingURL = currentPractice['ThirdPartyBooking_URL'] || '';
      this.useThirdPartyBookingURL = this.thirdPartyBookingURL !== '';

      const payload = { fields: 'Code' };
      if (!this.practiceLandingPageCode) {
        this.merchantService.practiceLandingPageOneByMerchant(currentPractice.ID, payload).subscribe((res) => {
          if (res && res.Code) {
            this.practiceLandingPageCode = res.Code;
          }

          this.getLink();
          this.updatePreview();
          this.checkBookingEngine();
        });
      } else {
        this.checkBookingEngine();
        this.updatePreview();
      }
    }
  }

  private getPreviewLinkFromCurrentPractice(currentPractice): string {
    let link: string = this.settings['publicSiteFrontendLink'];

    if (currentPractice && currentPractice['AppointmentPageURL']) {
      link = link + '/' + currentPractice['AppointmentPageURL'];
    } else {
      link = link + '/appointment/' + currentPractice['ID']; // same as `merchantID`
    }
    return link;
  }

  onFrameLoad(e) {
    if (!this.loadIFrame) {
      this.loadIFrame = true;
      this.changeDetectorRef.detectChanges();
    } else {
      this.loadIFrame = true;
    }
  }

  downloadButtons() {
    const imageLinks = [];

    for (let idx = 1; idx <= 46; idx++) {
      imageLinks.push(`/assets/images/appointment/buttons/${idx}.png`);
    }

    const ref = RootAppComponent.dialog.open(ImageViewListComponent, {
      data: {
        imageLinks,
        canDownload: true,
        canCheck: true,
        canMultipleDowload: true,
        multipleActionButtonLabel: 'Download',
      },
      width: '910px',
      panelClass: 'noCard',
    });

    ref.componentInstance.closeModal.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });

    ref.componentInstance.sendSelectedImages.subscribe(async (res: string[]) => {
      if (res.length > 0) {
        let imgData: any = await Promise.all(res.map((it) => fetch(it)));
        imgData = await Promise.all(imgData.map((it) => it.blob()));
        const zip = new JSZip();

        imgData.forEach(async (it, idx) => {
          const fileImg = new File([it], `${idx + 1}.png`);
          zip.file(`${idx + 1}.png`, fileImg);
        });

        zip.generateAsync({ type: 'blob' }).then(
          function (blob) {
            saveAs(blob, 'download.zip');
          },
          (err) => {
            console.log(err);
          }
        );
      }
    });
  }

  updateCurrentPracticeCalendar(calResponse) {
    this.currentPractice.calendar = calResponse;
  }

  checkBookingEngine() {
    if (this.currentPractice && this.currentPractice['ThirdPartyBooking_URL']) {
      this.isAppointmentDisabled = true;
    } else {
      this.isAppointmentDisabled = false;
    }
    this.isFirstLoad = true;

    AuthenticationService.appoinmentAccess.emit(this.isAppointmentDisabled);
  }

  getLink() {
    let link;
    if (this.currentPractice && this.practiceLandingPageCode) {
      link = `${this.settings['publicSiteFrontendLink']}/appointment-request/${this.practiceLandingPageCode}`;
    } else if (this.currentPractice && this.currentPractice.ID) {
      link = `${this.settings['publicSiteFrontendLink']}/appointment-practice/${this.currentPractice.ID}`;
    }

    this.appointmentLandingPage = link;
  }
}
