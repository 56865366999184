<mat-card class="card rm-mb">
  <div class="card-header">
    <h3 class="rm-mt rm-mb sr-title sm-h3">Add new 'Tip of the day'</h3>
    <p class="rm-mt">
      Tips are displayed on the  {{ 'KEYWORD.practice' | translate }} Dashboard, and provide helpful input for using the
      system.
    </p>
  </div>
  <mat-form-field class="full-width" appearance="outline">
    <mat-label>Enter a new title</mat-label>
    <input
      class="text-center"
      id="cc-name"
      [(ngModel)]="tip.title"
      type="text"
      placeholder="Enter a new title"
      matInput
    />
  </mat-form-field>

  <app-text-editor-input
    class="clearfix"
    [title]="null"
    [isAction]="false"
    [isPreview]="false"
    (getContent)="getContent($event)"
    name="editor"
  >
  </app-text-editor-input>

  <button class="btn btn-primary mt mb" (click)="addUpdateTip()" mat-raised-button type="button" color="primary">
    Add tip
  </button>
</mat-card>
