import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { HeaderService } from '../../../shared/services/header.service';
import { MerchantViewComponent } from '../../merchant/merchant-view/merchant-view.component';

@Component({
  selector: 'app-customer-prospect-list-overview',
  templateUrl: './customer-prospect-list-overview.component.html',
  styleUrls: ['./customer-prospect-list-overview.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('*', style({ opacity: 0 }), { optional: true }),
        query(
          '*',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('*', style({ opacity: 1 }), { optional: true }),
        query(
          '*',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class CustomerProspectListOverViewComponent implements OnInit {
  allList = true;
  customerList = false;
  prospectList = false;
  invitationList = false;
  hideMerchantSelect = false;

  merchantCustomer = 'none';
  merchantProspect = 'none';
  merchantAll = 'none';

  isPromoterOrAdmin = false;
  viewAsPromoter = true;

  displayResult = true ;

  setPatients;

  constructor(private AuthenticationService: AuthenticationService) {}

  ngOnInit() {
    this.AuthenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;

      if (this.isPromoterOrAdmin == true) {
        HeaderService.getPromoterViewAsObject().subscribe((res) => {
          if (res && res['promoterViewAs'] && res['promoterViewAs'] == 'promoter') {
            this.merchantAll = 'none';
            this.merchantCustomer = 'none';
            this.merchantProspect = 'none';
            this.viewAsPromoter = true;
          } else if (res && res['promoterViewAs'] && res['promoterViewAs'] == 'merchant') {
            this.merchantAll = res['merchantID'];
            this.merchantCustomer = res['merchantID'];
            this.merchantProspect = res['merchantID'];
            this.viewAsPromoter = false;
          }
        });
        const _mID = JSON.parse(localStorage.getItem('selectedMerchant'));

        if (_mID) {
          this.merchantAll = _mID;
          // this.viewAsPromoter = false;
        }
      }
    });
  }

  viewMerchant(index) {
    let id = this.merchantAll;

    if (index == 2) {
      id = this.merchantCustomer;
    } else if (index == 3) {
      id = this.merchantProspect;
    }

    const ref = RootAppComponent.dialog.open(MerchantViewComponent, {
      data: id,
      width: '600px',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  loadList(i) {
    if (i == 1) {
      this.customerList = true;
    }
    if (i == 2) {
      this.prospectList = true;
    }
  }

  detailView(e) {
    this.hideMerchantSelect = e;
  }

  getMerchantAll(e) {
    this.merchantAll = e;


    this.displayResult=false;
    setTimeout(() => {
      this.displayResult=true;
    }, 500);

  }

  getMerchantCustomer(e) {
    this.merchantCustomer = e;

    this.displayResult=false;
    setTimeout(() => {
      this.displayResult=true;
    }, 500);
  }

  getMerchantProspect(e) {
    this.merchantProspect = e;

    this.displayResult=false;
    setTimeout(() => {
      this.displayResult=true;
    }, 500);

  }

  getSetPatientsEvent(e) {
    this.setPatients = e;
  }
}
