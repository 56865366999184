<div *ngIf="isPromoterOrAdmin == true">
  <div class="row clearfix">
    <app-operator-filter
      class="dash"
      id="part1"
      [displayDateRange]="false"
      [selectDefaultAccount]="false"
      [selectDateRange]="selectedRange"
      [setAutoSelectMerchant]="true"
      [sendNull]="false"
      [selectDateRange]="false"
      [selectDateSecondRange]="false"
      [displayMerchant]="true"
      [displayDateFrom]="false"
      [displayDateTo]="false"
      [displayDentist]="false"
      [dateLabel]="'Requesting Date'"
      (search)="applySearch($event)"
    ></app-operator-filter>
  </div>

  <div class="row clearfix" *ngIf="isPromoterOrAdmin && merchantID && merchantID != 'none'">
    <app-merchant-product-assistant [merchantID]="merchantID"></app-merchant-product-assistant>
  </div>

  <div class="row clearfix padded" *ngIf="!merchantID || merchantID == 'none'">
    <div class="pleaseChoose" @ngIfAnimation>
      <p>
        <strong>No Merchant Selected</strong>
      </p>
      <img class="character_warning" />
      <p>
        Choose your desired merchant from the drop-down above to view their Appointments
      </p>
    </div>
  </div>
</div>
