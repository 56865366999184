import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AppStateService } from '../../../core/app-state/app-state.service';
import { UtilsService } from '../../../shared/services/utils.service';
import { DataResponse } from '../../../shared/types/data-response.type';
import { RevenueReportContract } from '../../funders/funders-revenue-report/shared/types/revenue-report.type';
import { Contract, ContractInvitationMembership, ContractQuery } from './types/contract.type';

class ContractState {
  contracts: Partial<Contract>[] | Contract[];
  contract: Partial<Contract> | Contract;
}

@Injectable()
export class ContractService extends ComponentStore<ContractState> {
  private baseUrl = environment.nodeUrl;

  constructor(private http: HttpClient, private utilsService: UtilsService, private appStateService: AppStateService) {
    super({
      contracts: [],
      contract: null,
    });
  }

  getContract$<T extends Contract | Partial<Contract>>(): Observable<T> {
    return this.select((state) => state.contract) as Observable<T>;
  }

  setContract(contract: Partial<Contract> | Contract) {
    this.patchState({ contract });
  }

  fetchContract$<T extends Contract | Partial<Contract>>(
    ID: string,
    payload: ContractQuery['payload'],
    existingContract?: Partial<Contract> | Contract
  ): Observable<T> {
    if (existingContract) {
      return of(existingContract) as Observable<T>;
    } else {
      return this.appStateService.getAppState$().pipe(
        switchMap((appState) => {
          const params: HttpParams = this.utilsService.getHttpParamsFromPayload(payload);
          const options = { params };

          const endpoint = appState.isPromoterOrAdmin ? 'contract' : 'contract/simple';
          const url = `${this.baseUrl}/${endpoint}/${ID}`;

          return this.http.get<DataResponse<T>>(url, options).pipe(map((response) => response.data));
        })
      );
    }
  }

  getContracts$<T extends Contract | Partial<Contract>>(): Observable<T[]> {
    return this.select((state) => state.contracts) as Observable<T[]>;
  }

  setContracts(contracts: Partial<Contract>[] | Contract[]) {
    this.patchState({ contracts });
  }

  fetchContracts$<T extends Contract | Partial<Contract> | RevenueReportContract>(
    payload: ContractQuery['payload']
  ): Observable<T[]> {
    return this.appStateService.getAppState$().pipe(
      switchMap((appState) => {
        const params: HttpParams = this.utilsService.getHttpParamsFromPayload(payload);
        const options = { params };

        const endpoint = appState.isPromoterOrAdmin ? 'contract/global' : 'contract/simple';
        const url = `${this.baseUrl}/${endpoint}`;

        return this.http.get<DataResponse<T[]>>(url, options).pipe(map((response) => response['data']));
      })
    );
  }

  statistics(payload): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }
      options = {
        params,
      };
    }

    return this.http
      .get(environment.nodeUrl + '/contract/statistics/global', options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  statisticsHidden(payload): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }
      options = {
        params,
      };
    }

    return this.http
      .get(environment.nodeUrl + '/contract/statistics-hidden/global', options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  onInvitation(ID, payload = {}): Observable<ContractInvitationMembership> {
    const params = this.utilsService.getHttpParamsFromPayload(payload);
    const options = { params };

    const endpoint = 'contract/invitation';
    const url = `${this.baseUrl}/${endpoint}/${ID}`;

    return this.http.get(url, options).pipe(map((res: HttpResponse<any>) => res['data']));
  }

  oneWebhookInfo(payload = {}, sesstionType = null): Observable<any> {
    if (sesstionType == 'admin' || sesstionType == 'promoter') {
      return this.http
        .post(environment.nodeUrl + '/contract/webhook-info/global', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .post(environment.nodeUrl + '/contract/webhook-info', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  complianceTask(contractKey, Key) {
    const payload = {
      Key,
    };
    const urlPatient = environment.nodeUrl + '/existingPerson/' + contractKey;
    return this.http.post(urlPatient, payload).pipe(map((res: HttpResponse<any>) => res['data']));
  }

  canDrawDown(contractID): Observable<any> {
    return this.http
      .get(environment.nodeUrl + '/contract/can-draw-down/' + contractID)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  editContract(ID, payload, isAdminOrPromoter = false): Observable<any> {
    if (isAdminOrPromoter == true) {
      return this.http
        .put(environment.nodeUrl + '/contract/' + ID, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  fetchContractDetails$(ID: string, payload = {}, isPromoterOrAdmin = false): Observable<Contract> {
    const params: HttpParams = this.utilsService.getHttpParamsFromPayload(payload);
    const options = { params };

    const endpoint = isPromoterOrAdmin ? 'contract' : 'contract/simple';
    const url = `${this.baseUrl}/${endpoint}/${ID}`;

    return this.http.get(url, options).pipe(map((res: HttpResponse<any>) => res['data']));
  }
  changeContractStatus(ID, payload): Observable<any> {
    return this.http
      .put(environment.nodeUrl + '/contract/changeStatus/' + ID, payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getContractDetails(ID: string, payload = {}, isPromoterOrAdmin = false): Observable<Contract> {
    const params: HttpParams = this.utilsService.getHttpParamsFromPayload(payload);
    const options = { params };

    const endpoint = isPromoterOrAdmin ? 'contract' : 'contract/simple';
    const url = `${this.baseUrl}/${endpoint}/${ID}`;

    return this.http.get(url, options).pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getConractDocument(ID, payload = {}): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    return this.http
      .get(environment.nodeUrl + '/contract/document/' + ID, options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getContractSimple(ID): Observable<any> {
    return this.http
      .get(environment.nodeUrl + '/contract/simple/' + ID)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getContractList(payload, isPromoterOrAdmin = false): Observable<Contract[] & { count: any }> {
    const params: HttpParams = this.utilsService.getHttpParamsFromPayload(payload);
    const options = { params };

    const endpoint = isPromoterOrAdmin ? 'contract/global' : 'contract';
    const url = `${this.baseUrl}/${endpoint}`;

    return this.http
      .get<DataResponse<Contract[] & { count: any }>>(url, options)
      .pipe(map((response) => response['data']));
  }

  getContractListGlobal(body): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        params = params.set(key, body[key]);
      }
    }

    options = {
      params,
    };

    return this.http
      .get(environment.nodeUrl + '/contract/global', options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getContractListGlobalPOST(body): Observable<any> {


    return this.http
      .post(environment.nodeUrl + '/contract/global', body)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getQuotationListGlobal(body): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        params = params.set(key, body[key]);
      }
    }

    options = {
      params,
    };

    return this.http
      .get(environment.nodeUrl + '/contract/global', options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getContractLookup(payload): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };
    return this.http
      .get(`${environment.nodeUrl}/contract/simple`, options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getPersonCircumstances(payload, isPromoterOrAdmin = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (isPromoterOrAdmin == true) {
      return this.http
        .get(environment.nodeUrl + '/personal-circumstances/global', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/personal-circumstances', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getPersonCircumstanceDetails(ID): Observable<any> {
    return this.http
      .get(environment.nodeUrl + '/personal-circumstances/' + ID)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getCapacityCheckDetails(ID): Observable<any> {
    return this.http
      .get(environment.nodeUrl + '/capacityCheckContract/' + ID)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getCreditCheckDetails(ID): Observable<any> {
    return this.http
      .get(environment.nodeUrl + '/creditCheckContract/' + ID)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getIDCheckDetails(ID): Observable<any> {
    return this.http.get(environment.nodeUrl + '/id-check/' + ID).pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getPCICars(payload, isPromoterOrAdmin = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (isPromoterOrAdmin == true) {
      return this.http
        .get(environment.nodeUrl + '/pci-car/global', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/pci-car/', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getPCICarDetails(ID): Observable<any> {
    return this.http.get(environment.nodeUrl + '/pci-car/' + ID).pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getPCICreditCard(payload, isPromoterOrAdmin = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (isPromoterOrAdmin == true) {
      return this.http
        .get(environment.nodeUrl + '/pci-creditCard/global', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/pci-creditCard/', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getPCICreditCardDetails(ID): Observable<any> {
    return this.http
      .get(environment.nodeUrl + '/pci-creditCard/' + ID)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getPCIEmploymentAndBenefit(payload, isPromoterOrAdmin = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (isPromoterOrAdmin == true) {
      return this.http
        .get(environment.nodeUrl + '/pci-employment-and-benefit/global', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/pci-employment-and-benefit/', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getPCIEmploymentAndBenefitDetails(ID): Observable<any> {
    return this.http
      .get(environment.nodeUrl + '/pci-employment-and-benefit/' + ID)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getPCIFurnitureHouseholdGoods(payload, isPromoterOrAdmin = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (isPromoterOrAdmin == true) {
      return this.http
        .get(environment.nodeUrl + '/pci-furniture-household-goods/global', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/pci-furniture-household-goods/', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getPCIFurnitureHouseholdGoodsDetails(ID): Observable<any> {
    return this.http
      .get(environment.nodeUrl + '/pci-furniture-household-goods/' + ID)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getPCIHouseOfResidence(payload, isPromoterOrAdmin = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (isPromoterOrAdmin == true) {
      return this.http
        .get(environment.nodeUrl + '/pci-house-of-residence/global', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/pci-house-of-residence/', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getPCIHouseOfResidenceDetails(ID): Observable<any> {
    return this.http
      .get(environment.nodeUrl + '/pci-house-of-residence/' + ID)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getPCIInterestAsIncome(payload, isPromoterOrAdmin = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (isPromoterOrAdmin == true) {
      return this.http
        .get(environment.nodeUrl + '/pci-interest-as-income/global', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/pci-interest-as-income/', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getPCIInterestAsIncomeDetails(ID): Observable<any> {
    return this.http
      .get(environment.nodeUrl + '/pci-interest-as-income/' + ID)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getPCIInvestmentAndLoans(payload, isPromoterOrAdmin = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (isPromoterOrAdmin == true) {
      return this.http
        .get(environment.nodeUrl + '/pci-investment-and-loans/global', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/pci-investment-and-loans/', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getPCIInvestmentAndLoansDetails(ID): Observable<any> {
    return this.http
      .get(environment.nodeUrl + '/pci-investment-and-loans/' + ID)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getPCIInvestmentProperty(payload, isPromoterOrAdmin = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (isPromoterOrAdmin == true) {
      return this.http
        .get(environment.nodeUrl + '/pci-investment-property/global', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/pci-investment-property/', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getPCIInvestmentPropertyDetails(ID): Observable<any> {
    return this.http
      .get(environment.nodeUrl + '/pci-investment-property/' + ID)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getPCILivingExpenses(payload, isPromoterOrAdmin = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (isPromoterOrAdmin == true) {
      return this.http
        .get(environment.nodeUrl + '/pci-living-expenses/global', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/pci-living-expenses/', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getPCILivingExpensesDetails(ID): Observable<any> {
    return this.http
      .get(environment.nodeUrl + '/pci-living-expenses/' + ID)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getPCIOtherAssets(payload, isPromoterOrAdmin = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (isPromoterOrAdmin == true) {
      return this.http
        .get(environment.nodeUrl + '/pci-other-assets/global', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/pci-other-assets/', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getPCIOtherAssetsDetails(ID): Observable<any> {
    return this.http
      .get(environment.nodeUrl + '/pci-other-assets/' + ID)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getPCIOtherIncome(payload, isPromoterOrAdmin = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (isPromoterOrAdmin == true) {
      return this.http
        .get(environment.nodeUrl + '/pci-other-income/global', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/pci-other-income/', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getPCIOtherIncomeDetails(ID): Observable<any> {
    return this.http
      .get(environment.nodeUrl + '/pci-other-income/' + ID)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getPCIOtherVehicle(payload, isPromoterOrAdmin = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (isPromoterOrAdmin == true) {
      return this.http
        .get(environment.nodeUrl + '/pci-other-vehicle/global', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/pci-other-vehicle/', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getPCIOtherVehicleDetails(ID): Observable<any> {
    return this.http
      .get(environment.nodeUrl + '/pci-other-vehicle/' + ID)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getPCIPersonalLoan(payload, isPromoterOrAdmin = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (isPromoterOrAdmin == true) {
      return this.http
        .get(environment.nodeUrl + '/pci-personal-loan/global', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/pci-personal-loan/', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getPCIPersonalLoanDetails(ID): Observable<any> {
    return this.http
      .get(environment.nodeUrl + '/pci-personal-loan/' + ID)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getPCIShares(payload, isPromoterOrAdmin = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (isPromoterOrAdmin == true) {
      return this.http
        .get(environment.nodeUrl + '/pci-shares/global', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/pci-shares/', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getPCISharesDetails(ID): Observable<any> {
    return this.http.get(environment.nodeUrl + '/pci-shares/' + ID).pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getPCITaxAndDebt(payload, isPromoterOrAdmin = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (isPromoterOrAdmin == true) {
      return this.http
        .get(environment.nodeUrl + '/pci-tax-and-debt/global', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/pci-tax-and-debt/', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getPCITaxAndDebtDetails(ID): Observable<any> {
    return this.http
      .get(environment.nodeUrl + '/pci-tax-and-debt/' + ID)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getComplianceTask(payload, isAdminOrPromoter = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (isAdminOrPromoter == true) {
      return this.http
        .get(environment.nodeUrl + '/compliance-task/global', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/compliance-task', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getContractKPI(ID): Observable<any> {
    return this.http
      .get(environment.nodeUrl + '/contract/kpi/' + ID)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getQuotationPayment(ID): Observable<any> {
    return this.http
      .get(environment.nodeUrl + '/contract/quotationPayment/' + ID)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  editPCI(ID, payload): Observable<any> {
    return this.http
      .put(environment.nodeUrl + '/pci-employment-and-benefit/' + ID, payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getIDDocumentGlobal(payload): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    return this.http
      .get(environment.nodeUrl + '/id-document/global', options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  uploadContractDocument(ID, payload) {
    return this.http
      .put(`${environment.nodeUrl}/contract/document/` + ID, payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  promoterDecision(ID, payload) {
    return this.http
      .put(`${environment.nodeUrl}/EPDocument/promoterDecision/` + ID, payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  sendConsumerReuploadDocMessage(id, data) {
    return this.http.post(`${environment.nodeUrl}/contract/re-upload-document/${id}`, data);
  }

  triggerWebhook(id, code, transactionToken = null, transactionWebhookURL = null): Observable<any> {
    const payload = {
      code,
      transactionToken,
      transactionWebhookURL,
    };
    return this.http.post(`${environment.nodeUrl}/contract/web-hook/${id}`, payload);
  }

  getInvitationDetails(ID, payload = {}, sessionType, isMembership = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }

    if (isMembership == true) {
      if (sessionType == 'admin' || sessionType == 'promoter') {
        return this.http
          .get(environment.nodeUrl + '/invitation-membership/global/' + ID, options)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
        return this.http
          .get(environment.nodeUrl + '/invitation-membership/' + ID, options)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else {
        return this.http
          .get(environment.nodeUrl + '/invitation-membership/public/' + ID, options)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      }
    } else {
      if (sessionType == 'admin' || sessionType == 'promoter') {
        return this.http
          .get(environment.nodeUrl + '/invitation/global/' + ID, options)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
        return this.http
          .get(environment.nodeUrl + '/invitation/' + ID, options)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else {
        return this.http
          .get(environment.nodeUrl + '/invitation/public/' + ID, options)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      }
    }
  }

  getVedaHTML(customerID, payload = {}): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }

    return this.http
      .get(environment.nodeUrl + '/credit-check-report/veda-html/' + customerID, options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getVedaPDF(customerID, payload = {}): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }

    return this.http
      .get(environment.nodeUrl + '/credit-check-report/veda-pdf/' + customerID, options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  decideCheck(ID, payload, checkType): Observable<any> {
    if (checkType == 'Capacity') {
      return this.http
        .put(environment.nodeUrl + '/capacityCheckContract/' + ID, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (checkType == 'Credit') {
      return this.http
        .put(environment.nodeUrl + '/creditCheckContract/' + ID, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (checkType == 'Identity') {
      return this.http
        .put(environment.nodeUrl + '/id-check/' + ID, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  performCheck(contractID, payload, checkType): Observable<any> {
    if (checkType == 'Capacity') {
      return this.http
        .post(environment.nodeUrl + '/capacityCheckContract/perform/' + contractID, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (checkType == 'Credit') {
      return this.http
        .post(environment.nodeUrl + '/creditCheckContract/perform/' + contractID, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (checkType == 'Identity') {
      return this.http
        .post(environment.nodeUrl + '/id-check/perform/' + contractID, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }


  getStatusColorIcon(isQuotation=false): Observable<any> {
    if(isQuotation==true)
    {
      return this.http
      .get(environment.nodeUrl + '/contract/color-icon/true')
      .pipe(map((res: HttpResponse<any>) => res['data']));


    }
    else {
      return this.http
      .get(environment.nodeUrl + '/contract/color-icon')
      .pipe(map((res: HttpResponse<any>) => res['data']));
    }

  }

}
