/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./instant-offer-header-image.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/components/ep-document-view-image/ep-document-view-image.component.ngfactory";
import * as i3 from "@angular/common";
import * as i4 from "../../../shared/components/ep-document-view-image/shared/ep-document-view-image.service";
import * as i5 from "../../../shared/components/ep-document-view-image/ep-document-view-image.component";
import * as i6 from "../../../core/authentication/shared/authentication.service";
import * as i7 from "@angular/material/dialog";
import * as i8 from "@angular/platform-browser";
import * as i9 from "../../../shared/services/utils.service";
import * as i10 from "./instant-offer-header-image.component";
var styles_InstantOfferHeaderImageComponent = [i0.styles];
var RenderType_InstantOfferHeaderImageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InstantOfferHeaderImageComponent, data: {} });
export { RenderType_InstantOfferHeaderImageComponent as RenderType_InstantOfferHeaderImageComponent };
function View_InstantOfferHeaderImageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "app-ep-document-view-image", [["class", "full-width offer-page-header-pic"]], null, null, null, i2.View_EpDocumentViewImageComponent_0, i2.RenderType_EpDocumentViewImageComponent)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵprd(131584, null, i4.EpDocumentViewImageService, i4.EpDocumentViewImageService, []), i1.ɵdid(4, 770048, null, 0, i5.EpDocumentViewImageComponent, [i6.AuthenticationService, i7.MatDialog, i8.DomSanitizer, [2, i7.MAT_DIALOG_DATA], i1.NgZone, i9.UtilsService, i4.EpDocumentViewImageService], { imageLink: [0, "imageLink"], hideZoomButton: [1, "hideZoomButton"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "full-width offer-page-header-pic"; var currVal_1 = ((_co.getCircle(_co.headerImage) == true) ? "round-circle" : ""); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.headerImage; var currVal_3 = _co.hideZoomButton; _ck(_v, 4, 0, currVal_2, currVal_3); }, null); }
function View_InstantOfferHeaderImageComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-ep-document-view-image", [["class", "full-width offer-page-header-pic"]], null, null, null, i2.View_EpDocumentViewImageComponent_0, i2.RenderType_EpDocumentViewImageComponent)), i1.ɵprd(131584, null, i4.EpDocumentViewImageService, i4.EpDocumentViewImageService, []), i1.ɵdid(2, 770048, null, 0, i5.EpDocumentViewImageComponent, [i6.AuthenticationService, i7.MatDialog, i8.DomSanitizer, [2, i7.MAT_DIALOG_DATA], i1.NgZone, i9.UtilsService, i4.EpDocumentViewImageService], { imageLink: [0, "imageLink"], hideZoomButton: [1, "hideZoomButton"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/no-image.jpg"; var currVal_1 = _co.hideZoomButton; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_InstantOfferHeaderImageComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-ep-document-view-image", [["class", "full-width offer-page-header-pic"]], null, null, null, i2.View_EpDocumentViewImageComponent_0, i2.RenderType_EpDocumentViewImageComponent)), i1.ɵprd(131584, null, i4.EpDocumentViewImageService, i4.EpDocumentViewImageService, []), i1.ɵdid(2, 770048, null, 0, i5.EpDocumentViewImageComponent, [i6.AuthenticationService, i7.MatDialog, i8.DomSanitizer, [2, i7.MAT_DIALOG_DATA], i1.NgZone, i9.UtilsService, i4.EpDocumentViewImageService], { imageLink: [0, "imageLink"], hideZoomButton: [1, "hideZoomButton"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/character/dentist_hero_pair.png"; var currVal_1 = _co.hideZoomButton; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_InstantOfferHeaderImageComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-ep-document-view-image", [["class", "full-width offer-page-header-pic round-circle"]], null, null, null, i2.View_EpDocumentViewImageComponent_0, i2.RenderType_EpDocumentViewImageComponent)), i1.ɵprd(131584, null, i4.EpDocumentViewImageService, i4.EpDocumentViewImageService, []), i1.ɵdid(2, 770048, null, 0, i5.EpDocumentViewImageComponent, [i6.AuthenticationService, i7.MatDialog, i8.DomSanitizer, [2, i7.MAT_DIALOG_DATA], i1.NgZone, i9.UtilsService, i4.EpDocumentViewImageService], { imageLink: [0, "imageLink"], hideZoomButton: [1, "hideZoomButton"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.nodeServer + "/merchant/practice-picture-stream/") + _co.merchantID); var currVal_1 = _co.hideZoomButton; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_InstantOfferHeaderImageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_InstantOfferHeaderImageComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InstantOfferHeaderImageComponent_2)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InstantOfferHeaderImageComponent_3)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InstantOfferHeaderImageComponent_4)), i1.ɵdid(7, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.headerImage && (_co.headerImage != "none")) && (_co.headerImage != "merchant")); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.headerImage && (_co.headerImage == "none")); _ck(_v, 3, 0, currVal_1); var currVal_2 = !_co.headerImage; _ck(_v, 5, 0, currVal_2); var currVal_3 = (((_co.headerImage && _co.nodeServer) && (_co.headerImage == "merchant")) && _co.merchantID); _ck(_v, 7, 0, currVal_3); }, null); }
export function View_InstantOfferHeaderImageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "instant-offer-header-image", [], null, null, null, View_InstantOfferHeaderImageComponent_0, RenderType_InstantOfferHeaderImageComponent)), i1.ɵdid(1, 114688, null, 0, i10.InstantOfferHeaderImageComponent, [i6.AuthenticationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InstantOfferHeaderImageComponentNgFactory = i1.ɵccf("instant-offer-header-image", i10.InstantOfferHeaderImageComponent, View_InstantOfferHeaderImageComponent_Host_0, { headerImage: "headerImage", hideZoomButton: "hideZoomButton", merchantID: "merchantID" }, {}, []);
export { InstantOfferHeaderImageComponentNgFactory as InstantOfferHeaderImageComponentNgFactory };
