/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./wizard-step-card.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../pipes/safe-html.pipe";
import * as i3 from "@angular/platform-browser";
import * as i4 from "../../../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i5 from "@angular/material/card";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "@angular/common";
import * as i8 from "./wizard-step-card.component";
var styles_WizardStepCardComponent = [i0.styles];
var RenderType_WizardStepCardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WizardStepCardComponent, data: {} });
export { RenderType_WizardStepCardComponent as RenderType_WizardStepCardComponent };
function View_WizardStepCardComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", ". "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.stepNumber; _ck(_v, 1, 0, currVal_0); }); }
function View_WizardStepCardComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(1, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 0, 0, _ck(_v, 1, 0, i1.ɵnov(_v.parent, 0), _co.instructions)); _ck(_v, 0, 0, currVal_0); }); }
export function View_WizardStepCardComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.SafeHtmlPipe, [i3.DomSanitizer]), (_l()(), i1.ɵeld(1, 0, null, null, 12, "mat-card", [["class", "card treatment-card card1 text-center mat-card"]], [[2, "_mat-animation-noopable", null]], null, null, i4.View_MatCard_0, i4.RenderType_MatCard)), i1.ɵdid(2, 49152, null, 0, i5.MatCard, [[2, i6.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(3, 0, null, 0, 0, "div", [["class", "colour-stripe"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, 0, 9, "div", [["class", "row clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 7, "div", [["class", "column"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "h3", [["class", "sr-title sm-h3 mt rm-mb"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 4, "p", [["class", "small"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WizardStepCardComponent_1)), i1.ɵdid(10, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WizardStepCardComponent_2)), i1.ɵdid(12, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.stepNumber; _ck(_v, 10, 0, currVal_2); var currVal_3 = _co.instructions; _ck(_v, 12, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵnov(_v, 2)._animationMode === "NoopAnimations"); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.title; _ck(_v, 7, 0, currVal_1); }); }
export function View_WizardStepCardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ipv-wizard-step-card", [], null, null, null, View_WizardStepCardComponent_0, RenderType_WizardStepCardComponent)), i1.ɵdid(1, 114688, null, 0, i8.WizardStepCardComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var WizardStepCardComponentNgFactory = i1.ɵccf("ipv-wizard-step-card", i8.WizardStepCardComponent, View_WizardStepCardComponent_Host_0, { title: "title", stepNumber: "stepNumber", instructions: "instructions" }, {}, ["*"]);
export { WizardStepCardComponentNgFactory as WizardStepCardComponentNgFactory };
