import { Component, EventEmitter, Inject, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-merchant-service-group-edit',
  templateUrl: './merchant-service-group-edit.component.html',
  styleUrls: ['./merchant-service-group-edit.component.css'],
})
export class MerchantServiceGroupEditComponent implements OnInit {
  merchantServicesGroup = {
    ID: '',
    Label: '',
    Description: '',
  };

  @Output() update = new EventEmitter();
  @Output() close = new EventEmitter();

  isModal = true;

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      this.merchantServicesGroup = data;
    }
  }

  ngOnInit() {}

  updateMerchantServiceGroup() {
    if (this.merchantServicesGroup.ID) {
      this.update.emit(this.merchantServicesGroup);
    }
  }

  closeModalEvent() {
    this.close.emit(true);
  }

  getContent(c) {
    this.merchantServicesGroup.Description = c;
  }
}
