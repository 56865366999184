import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
@Injectable()
export class ThirdPartyService {
  constructor(private http: HttpClient) {}

  getByInvitationTemplate(id): Observable<any> {
    return this.http
      .get(environment.nodeUrl + '/invitation-template/third-party/' + id, {})
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getThirdPartyAccessList(payload = null, sessionType) {
    let url = 'third-party-access';
    if (sessionType == 'admin' || sessionType == 'promoter') {
      url = 'third-party-access/global';
    }
    let params: HttpParams = new HttpParams();
    let options = {};
    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }
      options = {
        params,
      };
    }
    return this.http.get(`${environment.nodeUrl}/${url}`, options).pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      })
    );
  }

  getThirdPartyAccessDetails(id, payload = null, sessionType) {
    let url = 'third-party-access';
    if (sessionType == 'admin' || sessionType == 'promoter') {
      url = 'third-party-access/global';
    }
    let params: HttpParams = new HttpParams();
    let options = {};
    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }
      options = {
        params,
      };
    }
    return this.http.get(`${environment.nodeUrl}/${url}/${id}`, options).pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      })
    );
  }
  getThirdPartyAccessExist(id, sessionType) {
    let url = 'third-party-access/exist';
    if (sessionType == 'admin' || sessionType == 'promoter') {
      url = 'third-party-access/exist/global';
    }

    return this.http.get(`${environment.nodeUrl}/${url}/${id}`, {}).pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      })
    );
  }
  modifyThirdPartyAccess(id, payload = null, sessionType) {
    let url = 'third-party-access';
    if (sessionType == 'admin' || sessionType == 'promoter') {
      url = 'third-party-access/global';
    }
    return this.http.put(`${environment.nodeUrl}/${url}/${id}`, payload).pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      })
    );
  }

  createThirdPartyAccess(payload = {}, sessionType) {
    let url = 'third-party-access';
    if (sessionType == 'admin' || sessionType == 'promoter') {
      url = 'third-party-access/global';
    }
    return this.http.post(`${environment.nodeUrl}/${url}`, payload).pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      })
    );
  }

  deleteThirdPartyAccess(id, sessionType) {
    let url = 'third-party-access';
    if (sessionType == 'admin' || sessionType == 'promoter') {
      url = 'third-party-access/global';
    }
    return this.http.delete(`${environment.nodeUrl}/${url}/${id}`, {}).pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      })
    );
  }

  modifyThirdPartyAccessGroup(payload, sessionType): Observable<any> {
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http
        .put(`${environment.nodeUrl}/third-party-access/group/global`, payload)
        .pipe(map((res: Response) => res['data']));
    } else {
      return this.http
        .put(`${environment.nodeUrl}/third-party-access/group`, payload)
        .pipe(map((res: Response) => res['data']));
    }
  }

  deleteThirdPartyAccessGroup(ids, sessionType): Observable<any> {
    let _ids;
    if (Array.isArray(ids) == true) {
      _ids = ids.join(',');
    } else if (typeof ids == 'string') {
      _ids = ids;
    }
    if (_ids) {
      if (sessionType == 'admin' || sessionType == 'promoter') {
        return this.http
          .delete(environment.nodeUrl + '/third-party-access/group/global/' + _ids)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else {
        return this.http
          .delete(environment.nodeUrl + '/third-party-access/group/' + _ids)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      }
    }
  }

  getThirdPartyClientList(payload = null, sessionType) {
    let url = 'third-party-client';
    if (sessionType == 'admin' || sessionType == 'promoter') {
      url = 'third-party-client/global';
    }
    let params: HttpParams = new HttpParams();
    let options = {};
    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }
      options = {
        params,
      };
    }
    return this.http.get(`${environment.nodeUrl}/${url}`, options).pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      })
    );
  }

  deleteThirdPartyClientGroup(ids): Observable<any> {
    let _ids;
    if (Array.isArray(ids) == true) {
      _ids = ids.join(',');
    } else if (typeof ids == 'string') {
      _ids = ids;
    }
    if (_ids) {
      return this.http
        .delete(environment.nodeUrl + '/third-party-client/group/' + _ids)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }
  modifyThirdPartyClientGroup(payload): Observable<any> {
    return this.http
      .put(`${environment.nodeUrl}/third-party-client/group`, payload)
      .pipe(map((res: Response) => res['data']));
  }

  deleteThirdPartyClient(id) {
    const url = 'third-party-client';

    return this.http.delete(`${environment.nodeUrl}/${url}/${id}`, {}).pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      })
    );
  }

  getThirdPartyClientDetails(id, payload = null, sessionType) {
    let url = 'third-party-client';
    if (sessionType == 'admin' || sessionType == 'promoter') {
      url = 'third-party-client/global';
    }
    let params: HttpParams = new HttpParams();
    let options = {};
    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }
      options = {
        params,
      };
    }
    return this.http.get(`${environment.nodeUrl}/${url}/${id}`, options).pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      })
    );
  }

  modifyThirdPartyClient(id, payload = null) {
    const url = 'third-party-client';

    return this.http.put(`${environment.nodeUrl}/${url}/${id}`, payload).pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      })
    );
  }

  createThirdPartyClient(payload = {}) {
    const url = 'third-party-client';

    return this.http.post(`${environment.nodeUrl}/${url}`, payload).pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      })
    );
  }

  getThirdPartyClientImageStreamLink(ID) {
    if (ID) {
      return `${environment.nodeUrl}/third-party-client/picture-stream/` + ID;
    }
    return null;
  }

  getThirdPartyClientImage(ID) {
    return this.http
      .get(`${environment.nodeUrl}/third-party-client/picture/` + ID, {})
      .pipe(map((res: Response) => res['data']));
  }
}
