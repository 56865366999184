import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-marketing-audience-filter',
  templateUrl: './marketing-audience-filter.component.html',
  styleUrls: ['./marketing-audience-filter.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.animate', style({ opacity: 0 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.4s ease-out',
              keyframes([
                style({ opacity: 0, offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.animate', style({ opacity: 1 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.4s ease-in',
              keyframes([
                style({ opacity: 1, offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class MarketingAudienceFilterComponent implements OnInit {
  // show remove button if cloned row
  removable = false;

  // define filtering by ANY or ALL
  Comparitor = '0';

  // demonstratable filter group array

  masterFilter = [
    { value: 'f-0', viewValue: 'Patient data' },
    { value: 'f-1', viewValue: 'Treatment' },
    { value: 'f-2', viewValue: 'Lending data' },
    { value: 'f-3', viewValue: 'Dental history' },
    { value: 'f-4', viewValue: 'Medical history' },
  ];

  filterGroups = [
    {
      name: 'Patient data',
      filter: [
        { value: 'pd-0', viewValue: 'Gender' },
        { value: 'pd-1', viewValue: 'Age range' },
        { value: 'pd-2', viewValue: 'Postcode range' },
        { value: 'pd-3', viewValue: 'Added' },
        { value: 'pd-4', viewValue: 'Treated' },
      ],
    },
    {
      name: 'Treatment',
      filter: [
        { value: 'tr-0', viewValue: 'Treatment Product' },
        { value: 'tr-1', viewValue: 'Treatment Invitation' },
        { value: 'tr-2', viewValue: 'Treatment Completed' },
      ],
    },
    {
      name: 'Lending data',
      disabled: true,
      filter: [
        { value: 'ld-0', viewValue: 'Finance status' },
        { value: 'ld-1', viewValue: 'Repayment status' },
        { value: 'ld-2', viewValue: 'Approval status' },
      ],
    },
    {
      name: 'Dental history',
      filter: [
        { value: 'dh-0', viewValue: 'Immediate problems' },
        { value: 'dh-1', viewValue: 'Wanting to improve' },
        { value: 'dh-2', viewValue: 'Sensitivity' },
        { value: 'dh-3', viewValue: 'Pain/Soreness' },
        { value: 'dh-4', viewValue: 'Bite problems' },
        { value: 'dh-5', viewValue: 'Apprehension' },
        { value: 'dh-6', viewValue: 'Personal info' },
      ],
    },
    {
      name: 'Medical history',
      filter: [
        { value: 'mh-0', viewValue: 'Private health' },
        { value: 'mh-1', viewValue: 'Smoking' },
        { value: 'mh-2', viewValue: 'Drinking' },
        { value: 'mh-3', viewValue: 'Pregnancy' },
        { value: 'mh-4', viewValue: 'Stress' },
      ],
    },
    {
      name: 'Illnesses',
      filter: [
        { value: 'il-0', viewValue: 'Illness type' },
        { value: 'il-1', viewValue: 'Ongoing illnesses' },
      ],
    },
    {
      name: 'Allergies',
      filter: [
        { value: 'al-0', viewValue: 'Allergy type' },
        { value: 'al-1', viewValue: 'Ongoing allergies' },
      ],
    },
  ];

  // demo illness
  illnessList = [
    'Anaemia',
    'Arthiritis',
    'Artificial Heart Valve',
    'Artificial Joints - Hip, Knee etc.',
    'Asthma',
    'Blood Disorders',
    'Cancer',
    'Diabetes',
    'Dizziness/Fainting',
    'Epilepsy',
    'Excessive Bleeding',
    'Glaucoma',
    'Heart Disease',
    'Heart Murmur',
    'Hepatitis A',
    'Hepatitis B',
    'Hepatitis C',
    'High/Low Blood Pressure',
    'HIV/AIDS',
    'Kidney Disease',
    'Liver Disease',
    'Osteoporosis',
    'Pacemaker',
    'Psychological Disorders',
    'Radiation Therapy',
    'Respiratory Problems',
    'Rheumatic Fever',
    'Sinus Problems',
    'Stroke',
    'Transplanted Organ',
    'Tuberculosis',
    'Tumours',
    'Others',
  ];

  // demo allergy
  allergyList = [
    'Food allergy',
    'Skin allergy',
    'Dust allergy',
    'Insect sting allergy',
    'Pet allergy',
    'Eye allergy',
    'Drug allergy',
    'Mold allergy',
    'Sinus infection allergy',
    'Cockroach allergy',
    'Rhinitis allergy',
  ];

  // are filters selected
  hasFilters = false;

  // the selected filter

  masterfilterListSelection;

  filterListSelection;

  // the array of filters that have been chosen
  filterPanels = [];

  constructor() {}

  ngOnInit() {}

  // fake adding and removing treatment row
  newVisitRow() {
    this.filterPanels.push(this.filterListSelection);
    // console.log('set ' + this.filterListSelection + ' and added to array');
    // console.log('result is ' + this.filterPanels);
    this.checkVisits();
    this.filterListSelection = '';
  }

  removeFilter(i) {
    const index: number = this.filterPanels.indexOf(i);
    if (index !== -1) {
      this.filterPanels.splice(index, 1);
      this.checkVisits();
    }
    // console.log(this.filterPanels.length)
  }

  checkVisits() {
    if (this.filterPanels.length >= 2) {
      this.hasFilters = true;
    } else if (this.filterPanels.length == 1) {
      this.hasFilters = false;
    }
  }
}
