<div class="row clearfix padded">
  <mat-button-toggle-group
    class="btn-group flex full-width text-center"
    #group="matButtonToggleGroup"
    value="profilePage"
  >
    <mat-button-toggle
      class="text-center full-width"
      (click)="page = 'profilePage'; openSnackBar()"
      value="profilePage"
    >
      Profile
    </mat-button-toggle>
    <mat-button-toggle
      class="text-center full-width"
      (click)="page = 'contactPage'; openSnackBar()"
      value="contactPage"
    >
      Contacts
    </mat-button-toggle>
    <mat-button-toggle
      class="text-center full-width"
      (click)="page = 'invitationsPage'; openSnackBar()"
      value="invitationsPage"
    >
      Invitations
    </mat-button-toggle>
    <mat-button-toggle
      class="text-center full-width"
      *ngIf="isPromoterOrAdmin == true"
      (click)="page = 'lendingPage'; openSnackBar()"
      value="lendingPage"
    >
      Lending
    </mat-button-toggle>
    <mat-button-toggle
      class="text-center full-width"
      (click)="page = 'settlementPage'; openSnackBar()"
      value="settlementPage"
    >
      Settlements
    </mat-button-toggle>
    <mat-button-toggle
      class="text-center full-width"
      (click)="page = 'marketingPage'; openSnackBar()"
      value="marketingPage"
    >
      Marketing
    </mat-button-toggle>
    <mat-button-toggle
      class="text-center full-width"
      *ngIf="isPromoterOrAdmin == true"
      (click)="page = 'messagingPage'; openSnackBar()"
      value="messagingPage"
    >
      Messaging
    </mat-button-toggle>
    <mat-button-toggle
      class="text-center full-width"
      (click)="page = 'medicalHistoryPage'; openSnackBar()"
      value="medicalHistoryPage"
    >
      Health History
    </mat-button-toggle>
    <mat-button-toggle
      class="text-center full-width"
      (click)="page = 'actionLogPage'; openSnackBar()"
      value="actionLogPage"
    >
      Action Log
    </mat-button-toggle>
    <mat-button-toggle
      class="text-center full-width"
      *ngIf="isPromoterOrAdmin == true"
      (click)="page = 'verificationPage'; openSnackBar()"
      value="verificationPage"
    >
      Verification
    </mat-button-toggle>
    <mat-button-toggle
      class="text-center full-width"
      *ngIf="isPromoterOrAdmin == true"
      (click)="page = 'documentsPage'; openSnackBar()"
      value="documentsPage"
    >
      Documents
    </mat-button-toggle>
    <mat-button-toggle
      class="text-center full-width"
      *ngIf="isPromoterOrAdmin == true"
      (click)="page = 'logonsPage'; openSnackBar()"
      value="logonsPage"
    >
      Logons
    </mat-button-toggle>
  </mat-button-toggle-group>
</div>

<div class="row clearfix" *ngIf="selectedCustomer">
  <!------------------------------ CHILD COMPONENT  -------------------------------->

  <!-- <div class="masonry" *ngIf="page == 'profilePage'" @ngIfAnimation>
        <div class="item" *ngIf="mainInformation == true" @ngIfAnimation>
          <mat-card class="clearfix">
            <div class="colour-stripe "></div>
            <mat-card-header>
              <mat-card-title>
                <h3 class="sr-title sm-h3 rm-m">
                  <mat-icon class="fas fa-user"></mat-icon>Profile
                </h3>
              </mat-card-title>
            </mat-card-header>
            <mat-card-content>

              <div class="row clearfix">
                <app-details-card-profile></app-details-card-profile>
              </div>

            </mat-card-content>
          </mat-card>

        </div>  -->

  <!-----------------------------  CHILD COMPONENT END ------------------------------->

  <!-------------------------------------------------------- PROFILE TAB ------------------------------------------------------------------------>

  <div class="masonry" *ngIf="page == 'profilePage'" @ngIfAnimation>
    <div class="item thrd-width" *ngIf="mainInformation == true" @ngIfAnimation>
      <mat-card class="clearfix">
        <div class="colour-stripe"></div>
        <mat-card-header>
          <mat-card-title>
            <h3 class="sr-title sm-h3 rm-m">
              <mat-icon class="fas fa-user-circle"></mat-icon>
              Main Information
            </h3>
          </mat-card-title>
          <div class="pull-right" style="float: right">
            <button [matMenuTriggerFor]="menu" mat-icon-button>
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button *ngIf="editMode1 == true" (click)="editMode1 = false; openSnackBar()" mat-menu-item>
                <mat-icon class="fas fa-edit"></mat-icon>
                <span>Edit Mode</span>
              </button>
              <button *ngIf="editMode1 == false" (click)="editMode1 = true" mat-menu-item>
                <mat-icon class="fas fa-glasses"></mat-icon>
                <span>View Mode</span>
              </button>
            </mat-menu>
          </div>
        </mat-card-header>
        <form #form="ngForm">
          <mat-card-content>
            <div class="row clearfix">
              <mat-select
                class="full-width"
                [(ngModel)]="selectedCustomer['CardType']"
                [placeholder]="('KEYWORD.patient' | translate | titlecase) + ' Type'"
                [disabled]="editMode1"
                name="entityType"
                ngDefaultControl
              >
                <mat-option *ngFor="let entityType of entityTypes" [value]="selectedCustomer['CardType']">
                  {{ entityType.viewValue }}
                </mat-option>
              </mat-select>

              <div>
                <mat-select
                  class="full-width"
                  [(ngModel)]="selectedCustomer['Salutation']"
                  [disabled]="editMode1"
                  name="title"
                  placeholder="Salutation"
                  ngDefaultControl
                  required
                >
                  <mat-option *ngFor="let title of titles" [value]="title.Label">
                    {{ title.Label }}
                  </mat-option>
                </mat-select>
                <mat-form-field class="full-width" appearance="outline">
                  <input
                    class="mat-block"
                    [(ngModel)]="selectedCustomer.FirstName"
                    [disabled]="editMode1"
                    matInput
                    placeholder="{{ 'PLACEHOLDER.First Name' | translate }}"
                    name="firstName"
                    style="text-transform: capitalize"
                    required
                  />
                </mat-form-field>
                <mat-form-field class="full-width" appearance="outline">
                  <input
                    class="mat-block"
                    [(ngModel)]="selectedCustomer['MiddleName']"
                    [disabled]="editMode1"
                    matInput
                    placeholder="{{ 'PLACEHOLDER.Middle Name' | translate }}"
                    name="middleName"
                    style="text-transform: capitalize"
                  />
                </mat-form-field>
                <mat-form-field class="full-width" appearance="outline">
                  <input
                    class="mat-block"
                    [(ngModel)]="selectedCustomer['Name']"
                    [disabled]="editMode1"
                    matInput
                    placeholder="Surname"
                    name="surname"
                    style="text-transform: capitalize"
                    required
                  />
                </mat-form-field>
                <mat-form-field class="full-width" appearance="outline">
                  <input
                    class="mat-block"
                    [(ngModel)]="selectedCustomer['PreferredName']"
                    [disabled]="editMode1"
                    matInput
                    placeholder="Preferred name"
                    name="preferredName"
                    style="text-transform: capitalize"
                  />
                </mat-form-field>
              </div>
            </div>
          </mat-card-content>

          <button
            class="mb mt pull-right"
            [disabled]="editMode1"
            (click)="editMainInfo()"
            mat-raised-button
            color="accent"
          >
            <mat-icon>save</mat-icon>Save
          </button>
        </form>
      </mat-card>
    </div>
    <div class="item thrd-width" *ngIf="mainInformation == true" @ngIfAnimation>
      <mat-card class="clearfix">
        <div class="colour-stripe"></div>
        <mat-card-header>
          <mat-card-title>
            <h3 class="sr-title sm-h3 rm-m">
              <mat-icon class="fas fa-info-circle"></mat-icon>
              Additional Information
            </h3>
          </mat-card-title>
          <div class="pull-right" style="float: right">
            <button [matMenuTriggerFor]="menu" mat-icon-button>
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button *ngIf="editMode2 == true" (click)="editMode2 = false; openSnackBar()" mat-menu-item>
                <mat-icon class="fas fa-edit"></mat-icon>
                <span>Edit Mode</span>
              </button>
              <button *ngIf="editMode2 == false" (click)="editMode2 = true" mat-menu-item>
                <mat-icon class="fas fa-glasses"></mat-icon>
                <span>View Mode</span>
              </button>
            </mat-menu>
          </div>
        </mat-card-header>
        <form #addForm="ngForm">
          <mat-card-content>
            <div class="col-left">
              <mat-select
                class="full-width"
                [(ngModel)]="selectedCustomer['Status']"
                [disabled]="editMode2"
                name="status"
                placeholder="Status"
                ngDefaultControl
              >
                <mat-option *ngFor="let c of cardStatus" [value]="selectedCustomer['Status']">
                  {{ c.Label }}
                </mat-option>
              </mat-select>
              <mat-select
                class="full-width"
                *ngIf="isPromoterOrAdmin == true"
                [(ngModel)]="selectedCustomer['AccountManager']"
                [disabled]="editMode2"
                name="accountManager"
                placeholder="Account Manager"
                ngDefaultControl
              >
                <mat-option [value]="selectedCustomer['AccountManager']"></mat-option>
              </mat-select>
              <mat-select
                class="full-width"
                [(ngModel)]="selectedCustomer['Gender.Label']"
                [disabled]="editMode2"
                name="gender"
                placeholder="Gender"
                ngDefaultControl
              >
                <mat-option *ngFor="let gender of genders" [value]="selectedCustomer['Gender.Label']">
                  {{ gender.Label }}
                </mat-option>
              </mat-select>

              <div class="full-width">
                <mat-form-field class="full-width" appearance="outline">
                  <input
                    class="mat-block"
                    [value]="selectedCustomer['DateOfBirth'] | customDate"
                    [disabled]="editMode2"
                    matInput
                    name="birthday"
                    placeholder="Date of Birth"
                  />
                </mat-form-field>
              </div>
              <mat-select
                class="full-width"
                [(ngModel)]="selectedCustomer['RelationshipStatus']"
                [disabled]="editMode2"
                name="relationshipStatus"
                placeholder="Relationship Status"
              >
                <mat-option *ngFor="let r of relationshipStatus | objectPropertyValue: 'Label'" [value]="r">
                  {{ r }}
                </mat-option>
              </mat-select>

              <app-occupation-input
                class="full-width"
                [isRequired]="true"
                [occupationLabel]="selectedCustomer['Occupation']"
                (validate)="validateOccupationEvent($event)"
                (getOccupation)="getOccupation($event)"
              >
              </app-occupation-input>

              <mat-checkbox [(ngModel)]="checkedReferral" [disabled]="editMode2" name="referredCustomer"
              >Referred Customer?
              </mat-checkbox>
            </div>
          </mat-card-content>
          <button
            class="mb mt pull-right"
            [disabled]="editMode2"
            (click)="editAddedInfo()"
            mat-raised-button
            color="accent"
          >
            <mat-icon>save</mat-icon>Save
          </button>
        </form>
      </mat-card>
    </div>

    <div class="item thrd-width" *ngIf="mainInformation == true" @ngIfAnimation>
      <mat-card class="clearfix">
        <div class="colour-stripe"></div>
        <mat-card-header>
          <mat-card-title>
            <h3 class="sr-title sm-h3 rm-m">
              <mat-icon class="fas fa-address-book"></mat-icon>
              Emergency Contacts
            </h3>
          </mat-card-title>
          <div class="pull-right" style="float: right">
            <button [matMenuTriggerFor]="menu" mat-icon-button>
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button *ngIf="editMode3 == true" (click)="editMode3 = false; openSnackBar()" mat-menu-item>
                <mat-icon class="fas fa-edit"></mat-icon>
                <span>Edit Mode</span>
              </button>
              <button *ngIf="editMode3 == false" (click)="editMode3 = true" mat-menu-item>
                <mat-icon class="fas fa-glasses"></mat-icon>
                <span>View Mode</span>
              </button>
            </mat-menu>
          </div>
        </mat-card-header>
        <form #emergencyForm="ngForm">
          <mat-card-content>
            <div class="half-width">
              <div class="col-left">
                <mat-select
                  class="full-width"
                  [(ngModel)]="selectedCustomer['Guardian.FullName']"
                  [disabled]="editMode3"
                  name="guardian"
                  placeholder="Guardian"
                  ngDefaultControl
                >
                  <mat-option [value]="selectedCustomer['Guardian.FullName']">
                    {{ selectedCustomer['Guardian.FullName'] }}
                  </mat-option>
                </mat-select>
                <mat-select
                  class="full-width"
                  [(ngModel)]="selectedCustomer['Carer.FullName']"
                  [disabled]="editMode3"
                  name="carer"
                  placeholder="Carer"
                  ngDefaultControl
                >
                  <mat-option [value]="selectedCustomer['Carer.FullName']">
                    {{ selectedCustomer['Carer.FullName'] }}
                  </mat-option>
                </mat-select>
                <mat-select
                  class="full-width"
                  [(ngModel)]="selectedCustomer['Emergency.FullName']"
                  [disabled]="editMode3"
                  name="emergency"
                  placeholder="Emergency Contact"
                  ngDefaultControl
                >
                  <mat-option [value]="selectedCustomer['Emergency.FullName']">
                    {{ selectedCustomer['Emergency.FullName'] }}
                  </mat-option>
                </mat-select>
                <mat-select
                  class="full-width"
                  [(ngModel)]="selectedCustomer['GP.FullName']"
                  [disabled]="editMode3"
                  name="doctor"
                  placeholder="GP/Doctor"
                  ngDefaultControl
                >
                  <mat-option [value]="selectedCustomer['GP.FullName']">
                    {{ selectedCustomer['GP.FullName'] }}
                  </mat-option>
                </mat-select>
                <mat-select
                  class="full-width"
                  [(ngModel)]="selectedCustomer['GeneralDentist.FullName']"
                  [placeholder]="'General ' + ('KEYWORD.practitioner' | translate | titlecase)"
                  [disabled]="editMode3"
                  name="dentist"
                  ngDefaultControl
                >
                  <mat-option [value]="selectedCustomer['GeneralDentist.FullName']">
                    {{ selectedCustomer['GeneralDentist.FullName'] }}
                  </mat-option>
                </mat-select>
              </div>
            </div>
            <div class="col-right">
              <mat-form-field appearance="outline">
                <input
                  class="mat-block"
                  [disabled]="editMode3"
                  matInput
                  placeholder="Contact Number"
                  name="guardianContact"
                />
              </mat-form-field>
              <mat-form-field appearance="outline">
                <input
                  class="mat-block"
                  [disabled]="editMode3"
                  matInput
                  placeholder="Contact Number"
                  name="carerContact"
                />
              </mat-form-field>
              <mat-form-field appearance="outline">
                <input
                  class="mat-block"
                  [disabled]="editMode3"
                  matInput
                  placeholder="Contact Number"
                  name="emergencyContact"
                />
              </mat-form-field>
              <mat-form-field appearance="outline">
                <input
                  class="mat-block"
                  [disabled]="editMode3"
                  matInput
                  placeholder="Contact Number"
                  name="doctorContact"
                />
              </mat-form-field>
              <mat-form-field appearance="outline">
                <input
                  class="mat-block"
                  [disabled]="editMode3"
                  matInput
                  placeholder="Contact Number"
                  name="dentistContact"
                />
              </mat-form-field>
            </div>
          </mat-card-content>
          <button
            class="mb mt pull-right"
            [disabled]="editMode3"
            (click)="editEmergencyContacts()"
            mat-raised-button
            color="accent"
          >
            <mat-icon>save</mat-icon>Save
          </button>
        </form>
      </mat-card>
    </div>

    <div class="item wide" *ngIf="addressDetails == true" @ngIfAnimation>
      <mat-card class="clearfix">
        <div class="colour-stripe"></div>
        <mat-card-header>
          <mat-card-title>
            <h3 class="sr-title sm-h3 rm-m">
              <mat-icon class="fas fa-map-marked-alt"></mat-icon>
              Address Details
            </h3>
          </mat-card-title>
          <div class="pull-right" style="float: right">
            <button [matMenuTriggerFor]="menu" mat-icon-button>
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button *ngIf="editMode4 == true" (click)="editMode4 = false; openSnackBar()" mat-menu-item>
                <mat-icon class="fas fa-edit"></mat-icon>
                <span>Edit Mode</span>
              </button>
              <button *ngIf="editMode4 == false" (click)="editMode4 = true" mat-menu-item>
                <mat-icon class="fas fa-glasses"></mat-icon>
                <span>View Mode</span>
              </button>
              <button mat-menu-item>
                <mat-icon class="fas fa-retweet"></mat-icon>
                <span>Change Primary Address</span>
              </button>
              <button (click)="addAddress()" mat-menu-item>
                <mat-icon>add</mat-icon>
                <span>Add New Address</span>
              </button>
            </mat-menu>
          </div>
        </mat-card-header>
        <form #addressForm="ngForm">
          <mat-card-content>
            <div class="row clearfix">
              <!-- <mat-select name="addressType" class="full-width" placeholder="Select Primary Address" [(ngModel)]="selectedCustomer['addresses.Calculated']"
              [disabled]="editMode4" ngDefaultControl style="text-transform: capitalize">
              <mat-option [value]="selectedCustomer['addresses.Calculated']">
                {{selectedCustomer['addresses.Calculated']}}
              </mat-option>
            </mat-select> -->
              <mat-form-field class="full-width" appearance="outline">
                <input
                  class="mat-block"
                  [(ngModel)]="selectedCustomer['addresses.Calculated']"
                  [disabled]="editMode4"
                  [value]="selectedCustomer['addresses.Calculated']"
                  matInput
                  name="primaryAddress"
                  style="text-transform: capitalize"
                  placeholder="Primary Address"
                />
              </mat-form-field>

              <hr class="mb"/>

              <!-- <mat-select name="country" class="full-width" placeholder="Country" [(ngModel)]="selectedCustomer['addresses.Country.Label']"
                  ngDefaultControl disabled>
                  <mat-option [value]="selectedCustomer['addresses.Country.Label']">
                    {{selectedCustomer['addresses.Country.Label']}}
                  </mat-option>
                </mat-select> -->
              <app-address-input
                [displayMap]="true"
                [postCode]="myPostCode"
                [state]="myState"
                [suburb]="mySuburb"
                [streetNumber]="myStreetNumber"
                [streetName]="myStreetName"
                [unitNumber]="myUnitNumber"
                [streetType]="myStreetType"
                [latitude]="myLatitude"
                [longitude]="myLongitude"
                (getAdress)="getAdress($event)"
              ></app-address-input>
            </div>
            <div class="row clearfix">
              <div class="well row clearfix flex">
                <div class="row clearfix">
                  <mat-icon class="fas fa-home"></mat-icon>
                  <span>&nbsp;<strong> Address Book</strong></span>
                  <hr class="mb"/>
                  <mat-list *ngFor="let subItem of getAddressLabels(selectedCustomer)">
                    <mat-list-item
                    ><strong>{{ subItem }}: </strong> &nbsp; <i>{{ getDiffAddress(subItem) }}</i>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                  </mat-list>
                </div>
              </div>
            </div>
          </mat-card-content>
          <button
            class="mb mt pull-right"
            [disabled]="editMode4"
            (click)="editAddressDetails()"
            mat-raised-button
            color="accent"
          >
            <mat-icon>save</mat-icon>Save
          </button>
        </form>
      </mat-card>
    </div>
    <div class="item half-width" *ngIf="contactDetails == true" @ngIfAnimation>
      <mat-card class="clearfix">
        <div class="colour-stripe"></div>
        <mat-card-header>
          <mat-card-title>
            <h3 class="sr-title sm-h3 rm-m">
              <mat-icon class="fas fa-phone"></mat-icon>
              Contact Details
            </h3>
          </mat-card-title>
          <div class="pull-right" style="float: right">
            <button [matMenuTriggerFor]="menu" mat-icon-button>
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button *ngIf="editMode5 == true" (click)="editMode5 = false; openSnackBar()" mat-menu-item>
                <mat-icon class="fas fa-edit"></mat-icon>
                <span>Edit Mode</span>
              </button>
              <button *ngIf="editMode5 == false" (click)="editMode5 = true" mat-menu-item>
                <mat-icon class="fas fa-glasses"></mat-icon>
                <span>View Mode</span>
              </button>
              <button mat-menu-item>
                <mat-icon class="fas fa-retweet"></mat-icon>
                <span>Change Primary Contacts</span>
              </button>
              <button (click)="addContactDetails()" mat-menu-item>
                <mat-icon>add</mat-icon>
                <span>Add New Contact</span>
              </button>
            </mat-menu>
          </div>
        </mat-card-header>
        <form #contactForm="ngForm">
          <mat-card-content>
            <div class="row clearfix">
              <mat-form-field class="half-width" appearance="outline">
                <input
                  class="mat-lbock"
                  [(ngModel)]="selectedCustomer['phones.Number']"
                  [disabled]="editMode5"
                  [value]="selectedCustomer['phones.Number']"
                  matInput
                  name="primaryPhoneNumber"
                  placeholder="Primary Contact Number"
                />
              </mat-form-field>
              <mat-form-field class="half-width" appearance="outline">
                <input
                  class="mat-block"
                  [(ngModel)]="selectedCustomer['emails.Email']"
                  [disabled]="editMode5"
                  [value]="selectedCustomer['emails.Email']"
                  matInput
                  name="primaryEmail"
                  placeholder="Primary Email Address"
                />
              </mat-form-field>
              <hr class="mb"/>
            </div>
            <!-- <div class="row clearfix">
                <mat-form-field appearance="outline">
                    <input matInput name="primaryMobileNumber" placeholder="Primary Mobile Number" class="full-width" [(ngModel)]="selectedCustomer['mobiles.Number']" [disabled]="editMode5" [value]="selectedCustomer['mobiles.Number']">
                  </mat-form-field>
              </div> -->
            <div class="row clearfix">
              <!-- <button mat-icon-button class="pull-right" [disabled]="editMode5" matTooltip="Add New Email Address">
                  <mat-icon>add</mat-icon>
                </button> -->
            </div>
            <div class="well row clearfix flex">
              <div class="half-width">
                <div class="col-left">
                  <mat-icon class="fas fa-phone-square"></mat-icon>
                  <span><strong>Phone Numbers</strong></span>
                  <hr class="mb"/>
                  <mat-list *ngFor="let subItem of getPhoneLabels(selectedCustomer)">
                    <mat-list-item
                    ><strong>{{ subItem }}: </strong> &nbsp; <i>{{ getNumber(subItem) }}</i></mat-list-item
                    >
                    <mat-divider></mat-divider>
                  </mat-list>
                </div>
              </div>
              <div class="col-right">
                <mat-icon class="fas fa-envelope-square"></mat-icon>
                <span><strong>Email Addresses</strong></span>
                <hr class="mb"/>
                <mat-list *ngFor="let subItem of getEmailLabels(selectedCustomer)">
                  <mat-list-item
                  ><strong>{{ subItem }}: </strong> &nbsp; <i>{{ getEmailAddress(subItem) }}</i>
                  </mat-list-item>
                  <mat-divider></mat-divider>
                </mat-list>
              </div>
            </div>

            <hr class="mb"/>
            <br/>

            <!-- <div class="row clearfix">
            <button mat-icon-button class="pull-right" [disabled]="editMode5" matTooltip="Add New Home Number">
              <mat-icon>add</mat-icon>
            </button>
            <mat-select name="phoneNumber" class="full-width" placeholder="Select Home Phone Number" [(ngModel)]="selectedCustomer['phones.Number']"
              [disabled]="editMode5" ngDefaultControl>
              <mat-option [value]="selectedCustomer['phones.Number']">
                {{selectedCustomer['phones.Number']}}
              </mat-option>
            </mat-select>
          </div> -->
            <!-- <div class="row clearfix">
            <button mat-icon-button class="pull-right" [disabled]="editMode5" matTooltip="Add New Work Number">
              <mat-icon>add</mat-icon>
            </button>
            <mat-select name="workNumber" class="full-width" placeholder="Select Work Number" [disabled]="editMode5"
              ngDefaultControl>
              <mat-option>
              </mat-option>
            </mat-select>
          </div> -->

            <div class="row clearfix">
              <mat-form-field class="full-width" appearance="outline">
                <input
                  class="mat-block"
                  [(ngModel)]="selectedCustomer['URL']"
                  [disabled]="editMode5"
                  matInput
                  placeholder="Website"
                  name="website"
                />
              </mat-form-field>
            </div>
          </mat-card-content>
          <button
            class="mb mt pull-right"
            [disabled]="editMode5"
            (click)="editContactDetails()"
            mat-raised-button
            color="accent"
          >
            <mat-icon>save</mat-icon>Save
          </button>
        </form>
      </mat-card>
    </div>

    <div class="item thrd-width" *ngIf="preferencesDetails == true" @ngIfAnimation>
      <mat-card class="clearfix">
        <div class="colour-stripe"></div>
        <mat-card-header>
          <mat-card-title>
            <h3 class="sr-title sm-h3 rm-m">
              <mat-icon class="fas fa-bullhorn"></mat-icon>
              Preferences
            </h3>
          </mat-card-title>
          <div class="pull-right" style="float: right">
            <button [matMenuTriggerFor]="menu" mat-icon-button>
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button *ngIf="editMode6 == true" (click)="editMode6 = false; openSnackBar()" mat-menu-item>
                <mat-icon class="fas fa-edit"></mat-icon>
                <span>Edit Mode</span>
              </button>
              <button *ngIf="editMode6 == false" (click)="editMode6 = true" mat-menu-item>
                <mat-icon class="fas fa-glasses"></mat-icon>
                <span>View Mode</span>
              </button>
            </mat-menu>
          </div>
        </mat-card-header>
        <form #preferenceForm="ngForm">
          <mat-card-content>
            <div class="row clearfix">
              <mat-select
                class="full-width"
                [(ngModel)]="selectedCustomer['PreferredContactMethod.Label']"
                [disabled]="editMode6"
                name="contactMethod"
                placeholder="Contact Method"
                ngDefaultControl
              >
                <mat-option
                  *ngFor="let contactMethod of contactMethods | objectPropertyValue: 'Label'"
                  [value]="contactMethod"
                >
                  {{ contactMethod }}
                </mat-option>
              </mat-select>
              <mat-select
                class="full-width"
                [(ngModel)]="selectedCustomer['PreferredContactTime.Label']"
                [disabled]="editMode6"
                name="contactTime"
                placeholder="Contact Time"
              >
                <mat-option
                  *ngFor="let contactTime of contactTimes | objectPropertyValue: 'Label'"
                  [value]="contactTime"
                >
                  {{ contactTime }}
                </mat-option>
              </mat-select>
              <mat-select
                class="full-width"
                [(ngModel)]="selectedCustomer['PreferredMessageType.Label']"
                [disabled]="editMode6"
                name="messageType"
                placeholder="Message Type"
                ngDefaultControl
              >
                <mat-option
                  *ngFor="let messageType of messageTypes | objectPropertyValue: 'Label'"
                  [value]="messageType"
                >
                  {{ messageType }}
                </mat-option>
              </mat-select>

              <mat-select
                class="full-width"
                [(ngModel)]="selectedCustomer['ReferralSource.Category']"
                [disabled]="editMode6"
                name="referralSource"
                placeholder="Referral Source"
              >
                <mat-option
                  *ngFor="let referralSource of referralSources"
                  [value]="selectedCustomer['ReferralSource.Category']"
                >
                  {{ referralSource.viewValue }}
                </mat-option>
              </mat-select>

              <mat-select
                class="full-width"
                [(ngModel)]="selectedCustomer['ReferralSource.Specific']"
                [disabled]="editMode6"
                name="referralSpecifics"
                placeholder="Referral Specifics"
              >
                <mat-option [value]="selectedCustomer['ReferralSource.Specific']">
                  {{ selectedCustomer['ReferralSource.Specific'] }}
                </mat-option>
              </mat-select>

              <div class="consent">
                <hr/>
                <p class="rm-mb">
                  Electronic Consent Accepted:
                  <span
                    class="label red"
                    *ngIf="selectedCustomer['ElectronicCommsConsent.Given'] == 0"
                    [(ngModel)]="selectedCustomer['ElectronicCommsConsent.Given']"
                    matTooltip="Not Agreed"
                    name="consentElec"
                    ngDefaultControl
                  >
                    <mat-icon class="fas fa-times"></mat-icon>
                  </span>
                  <span
                    class="label green"
                    *ngIf="selectedCustomer['ElectronicCommsConsent.Given'] == 1"
                    [(ngModel)]="selectedCustomer['ElectronicCommsConsent.Given']"
                    matTooltip="Consent Agreed"
                    name="consentElec"
                    ngDefaultControl
                  >
                    <mat-icon class="fas fa-check"></mat-icon> - {{ selectedCustomer['ElectronicCommsConsent.When'] }}
                  </span>
                </p>
                <p class="rm-mb">
                  Marketing Consent:
                  <span
                    class="label red"
                    *ngIf="selectedCustomer['MarketingConsent.Given'] == 0"
                    [(ngModel)]="selectedCustomer['MarketingConsent.Given']"
                    matTooltip="Not Agreed"
                    name="consentMarketing"
                    ngDefaultControl
                  >
                    <mat-icon class="fas fa-times"></mat-icon>
                  </span>
                  <span
                    class="label green"
                    *ngIf="selectedCustomer['MarketingConsent.Given'] == 1"
                    [(ngModel)]="selectedCustomer['MarketingConsent.Given']"
                    matTooltip="Consent Agreed"
                    name="consentMarketing"
                    ngDefaultControl
                  >
                    <mat-icon class="fas fa-check"></mat-icon> - {{ selectedCustomer['MarketingConsent.When'] }}
                  </span>
                </p>
                <p class="rm-mb">
                  Data Storage:
                  <span
                    class="label red"
                    *ngIf="selectedCustomer['DataConsent.Given'] == 0"
                    [(ngModel)]="selectedCustomer['DataConsent.Given']"
                    matTooltip="Not Agreed"
                    name="consentData"
                    ngDefaultControl
                  >
                    <mat-icon class="fas fa-times"></mat-icon>
                  </span>
                  <span
                    class="label green"
                    *ngIf="selectedCustomer['DataConsent.Given'] == 1"
                    [(ngModel)]="selectedCustomer['DataConsent.Given']"
                    matTooltip="Consent Agreed"
                    name="consentData"
                    ngDefaultControl
                  >
                    <mat-icon class="fas fa-check"></mat-icon> - {{ selectedCustomer['DataConsent.When'] }}
                  </span>
                </p>
                <p class="rm-mb">
                  Privacy Consent:
                  <span
                    class="label red"
                    *ngIf="selectedCustomer['PrivacyConsent.Given'] == 0"
                    [(ngModel)]="selectedCustomer['PrivacyConsent.Given']"
                    matTooltip="Not Agreed"
                    name="consentPrivacy"
                    ngDefaultControl
                  >
                    <mat-icon class="fas fa-times"></mat-icon>
                  </span>
                  <span
                    class="label green"
                    *ngIf="selectedCustomer['PrivacyConsent.Given'] == 1"
                    [(ngModel)]="selectedCustomer['PrivacyConsent.Given']"
                    matTooltip="Consent Agreed"
                    name="consentPrivacy"
                    ngDefaultControl
                  >
                    <mat-icon class="fas fa-check"></mat-icon> - {{ selectedCustomer['PrivacyConsent.When'] }}
                  </span>
                </p>
              </div>
            </div>
          </mat-card-content>
          <button
            class="mb mt pull-right"
            [disabled]="editMode6"
            (click)="editPreferences()"
            mat-raised-button
            color="accent"
          >
            <mat-icon>save</mat-icon>Save
          </button>
        </form>
      </mat-card>
    </div>
    <div class="item thrd-width" *ngIf="profileImage == true" @ngIfAnimation>
      <mat-card class="clearfix">
        <div class="colour-stripe"></div>
        <mat-card-header>
          <mat-card-title>
            <h3 class="sr-title sm-h3 rm-m">
              <mat-icon class="fas fa-camera"></mat-icon>
              Profile Image
            </h3>
          </mat-card-title>
          <div class="pull-right" style="float: right">
            <button [matMenuTriggerFor]="menu" mat-icon-button>
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button *ngIf="editMode7 == true" (click)="editMode7 = false; openSnackBar()" mat-menu-item>
                <mat-icon class="fas fa-edit"></mat-icon>
                <span>Edit Mode</span>
              </button>
              <button *ngIf="editMode7 == false" (click)="editMode7 = true" mat-menu-item>
                <mat-icon class="fas fa-glasses"></mat-icon>
                <span>View Mode</span>
              </button>
            </mat-menu>
          </div>
        </mat-card-header>
        <mat-card-content>
          <div class="row clearfix">
            <div class="row clearfix image">
              <app-ep-document-view-image
                class="clearfix full-width display-image"
                [defaultPicture]="
                  'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/profile_placeholder.png'
                "
              ></app-ep-document-view-image>
            </div>
            <div class="row clearfix">
              <h4 class="sr-title sm-h4 rm-mb">Upload Avatar</h4>
              <p class="small rm-m">To upload a new avatar image, please drag your file into the grey area below.</p>
              <app-file-uploader
                class="text-center clearfix full-width"
                [allowedExtensionTypes]="['image']"
                [isFixedAspectRatio]="true"
                [isImageCropping]="true"
                [canSkip]="false"
                [limit]="1"
                [uploadDirectly]="true"
                [isDragable]="true"
              >
              </app-file-uploader>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="item thrd-width" *ngIf="mainInformation == true" @ngIfAnimation>
      <mat-card class="clearfix">
        <div class="colour-stripe"></div>
        <mat-card-header>
          <mat-card-title>
            <h3 class="sr-title sm-h3 rm-m">
              <mat-icon class="fas fa-id-card"></mat-icon>
              Identification
            </h3>
          </mat-card-title>
          <div class="pull-right" style="float: right">
            <button [matMenuTriggerFor]="menu" mat-icon-button>
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button *ngIf="editMode8 == true" (click)="editMode8 = false; openSnackBar()" mat-menu-item>
                <mat-icon class="fas fa-edit"></mat-icon>
                <span>Edit Mode</span>
              </button>
              <button *ngIf="editMode8 == false" (click)="editMode8 = true" mat-menu-item>
                <mat-icon class="fas fa-glasses"></mat-icon>
                <span>View Mode</span>
              </button>
            </mat-menu>
          </div>
        </mat-card-header>
        <form #idForm="ngForm">
          <mat-card-content>
            <div class="row clearfix half-width">
              <div class="col-left">
                <mat-select
                  class="full-width"
                  [disabled]="editMode8"
                  name="idType"
                  placeholder="ID Type"
                  ngDefaultControl
                >
                  <mat-option *ngFor="let idType of idTypes | objectPropertyValue: 'Label'" [value]="idType">
                    {{ idType }}
                  </mat-option>
                </mat-select>
                <mat-select
                  class="full-width"
                  [disabled]="editMode8"
                  name="idType"
                  placeholder="ID Type"
                  ngDefaultControl
                >
                  <mat-option *ngFor="let idType of idTypes | objectPropertyValue: 'Label'" [value]="idType">
                    {{ idType }}
                  </mat-option>
                </mat-select>
                <mat-select
                  class="full-width"
                  [disabled]="editMode8"
                  name="idType"
                  placeholder="ID Type"
                  ngDefaultControl
                >
                  <mat-option *ngFor="let idType of idTypes | objectPropertyValue: 'Label'" [value]="idType">
                    {{ idType }}
                  </mat-option>
                </mat-select>
                <mat-select
                  class="full-width"
                  [disabled]="editMode8"
                  name="idType"
                  placeholder="ID Type"
                  ngDefaultControl
                >
                  <mat-option *ngFor="let idType of idTypes | objectPropertyValue: 'Label'" [value]="idType">
                    {{ idType }}
                  </mat-option>
                </mat-select>
                <mat-select
                  class="full-width"
                  [disabled]="editMode8"
                  name="idType"
                  placeholder="ID Type"
                  ngDefaultControl
                >
                  <mat-option *ngFor="let idType of idTypes | objectPropertyValue: 'Label'" [value]="idType">
                    {{ idType }}
                  </mat-option>
                </mat-select>
              </div>
            </div>
            <div class="col-right">
              <mat-form-field appearance="outline">
                <input
                  class="mat-block full-width"
                  [disabled]="editMode8"
                  matInput
                  placeholder="Drivers License"
                  name="driversLicense"
                />
              </mat-form-field>
              <mat-form-field appearance="outline">
                <input
                  class="mat-block full-width"
                  [disabled]="editMode8"
                  matInput
                  placeholder="Passport"
                  name="passport"
                />
              </mat-form-field>
              <mat-form-field appearance="outline">
                <input
                  class="mat-block full-width"
                  [disabled]="editMode8"
                  matInput
                  placeholder="ID Card"
                  name="idCard"
                />
              </mat-form-field>
              <mat-form-field appearance="outline">
                <input
                  class="mat-block full-width"
                  [disabled]="editMode8"
                  matInput
                  placeholder="Medicard"
                  name="medicareCard"
                />
              </mat-form-field>
              <mat-form-field appearance="outline">
                <input
                  class="mat-block full-width"
                  [disabled]="editMode8"
                  matInput
                  placeholder="Health Fund"
                  name="healthFund"
                />
              </mat-form-field>
            </div>
          </mat-card-content>
          <button
            class="mb mt pull-right"
            [disabled]="editMode8"
            (click)="editIDDetails()"
            mat-raised-button
            color="accent"
          >
            <mat-icon>save</mat-icon>Save
          </button>
        </form>
      </mat-card>
    </div>

    <!------------------------------------------------------------------ PROFILE TAB END --------------------------------------------------------------------->

    <div class="full-width text-center">
      <!-- <button mat-raised-button color="primary" class="mb mt pull-left" (click)="backClicked()">
        <mat-icon>keyboard_arrow_left</mat-icon> Back to {{ "KEYWORD.patient" | translate | titlecase }} List
      </button> -->
    </div>
  </div>

  <!-- -----  contact list ----------- -->

  <div class="masonry" *ngIf="page == 'contactPage'" @ngIfAnimation>
    <div class="item" *ngIf="contactDetails == true" @ngIfAnimation>
      <mat-card class="clearfix">
        <div class="colour-stripe"></div>
        <mat-card-header>
          <mat-card-title>
            <h3 class="sr-title sm-h3 rm-m">
              <mat-icon class="fas fa-users"></mat-icon>
              Contacts List
            </h3>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="row clearfix">
            <app-details-card-contacts-list></app-details-card-contacts-list>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="full-width text-center">
      <!-- <button mat-raised-button color="primary" class="mb mt pull-left" (click)="backClicked()">
        <mat-icon>keyboard_arrow_left</mat-icon> Back to {{ "KEYWORD.patient" | translate | titlecase }} List
      </button> -->
      <button class="mb mt pull-right" (click)="editDetails()" mat-raised-button color="accent">
        <mat-icon>save</mat-icon>Save
      </button>
    </div>
  </div>

  <!--  Invitations List -->

  <div class="masonry" *ngIf="page == 'invitationsPage'" @ngIfAnimation>
    <div class="item" *ngIf="invitationDetails == true" @ngIfAnimation>
      <mat-card class="clearfix">
        <div class="colour-stripe"></div>
        <mat-card-header>
          <mat-card-title>
            <h3 class="sr-title sm-h3 rm-m">
              <mat-icon class="fas fa-envelope"></mat-icon>
              Invitation List
            </h3>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="row clearfix">
            <app-patient-invitation-list></app-patient-invitation-list>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="full-width text-center">
      <!-- <button mat-raised-button color="primary" class="mb mt pull-left" (click)="backClicked()">
        <mat-icon>keyboard_arrow_left</mat-icon> Back to {{ "KEYWORD.patient" | translate | titlecase }} List
      </button> -->
      <button class="mb mt pull-right" (click)="editDetails()" mat-raised-button color="accent">
        <mat-icon>save</mat-icon>Save
      </button>
    </div>
  </div>

  <!--  Lending -->

  <div class="masonry" *ngIf="page == 'lendingPage'" @ngIfAnimation>
    <div class="item" *ngIf="lendingDetails == true" @ngIfAnimation>
      <mat-card class="clearfix">
        <div class="colour-stripe"></div>
        <mat-card-header>
          <mat-card-title>
            <h3 class="sr-title sm-h3 rm-m">
              <mat-icon class="fas fa-dollar-sign"></mat-icon>
              Lending
            </h3>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="row clearfix">
            <app-details-card-lending></app-details-card-lending>
            >
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="full-width text-center">
      <!-- <button mat-raised-button color="primary" class="mb mt pull-left" (click)="backClicked()">
        <mat-icon>keyboard_arrow_left</mat-icon> Back to {{ "KEYWORD.patient" | translate | titlecase }} List
      </button> -->
      <button class="mb mt pull-right" (click)="editDetails()" mat-raised-button color="accent">
        <mat-icon>save</mat-icon>Save
      </button>
    </div>
  </div>

  <!-- marketing -->

  <div class="masonry" *ngIf="page == 'marketingPage'" @ngIfAnimation>
    <!-- <div class="item" *ngIf="marketingInformation == true" @ngIfAnimation>
    <mat-card class="clearfix">
      <div class="colour-stripe "></div>
      <mat-card-header>
        <mat-card-title>
          <h3 class="sr-title sm-h3 rm-m">
            <mat-icon class="fas fa-info-circle"></mat-icon>General
          </h3>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="row clearfix">
          <div class="half-width">
            <div class="col-left">
              <mat-select name="industryType" class="full-width" placeholder="Industry Type" [(ngModel)]="selectedCustomer['IndustryType']">
                <mat-option *ngFor="let industryType of industryTypes">
                  {{industryType.viewValue}}
                </mat-option>
              </mat-select> -->

    <!-- <mat-select name="industryType" class="full-width" placeholder="Industry Type" [(ngModel)]="selectedCustomer['IndustryType']">
                  <mat-option *ngFor="let industryType of industryTypes | objectPropertyValue : 'Label'" [value]="industryType">
                    {{industryType}}
                  </mat-option>
                </mat-select> -->
    <!---<mat-select name="customerGroup" class="full-width" [placeholder]="('KEYWORD.patient' | translate | titlecase) + ' Group'">
                  <mat-option *ngFor="let customerGroup of customerGroups | objectPropertyValue : 'Label'" [value]="customerGroup">
                      {{customerGroup}}
                  </mat-option>
                </mat-select>-->

    <!-- </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div> -->
    <div class="item" *ngIf="marketingInformation == true" @ngIfAnimation>
      <mat-card class="clearfix">
        <div class="colour-stripe"></div>
        <mat-card-header>
          <mat-card-title>
            <h3 class="sr-title sm-h3 rm-m">
              <mat-icon class="fas fa-align-justify"></mat-icon>
              Marketing Campaigns
            </h3>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="row clearfix">
            <div class="half-width">
              <div class="col-left">
                <mat-form-field class="full-width" appearance="outline">
                  <input class="mat-block" matInput placeholder="Input"/>
                </mat-form-field>
                <mat-form-field class="full-width" appearance="outline">
                  <input class="mat-block" matInput placeholder="Input"/>
                </mat-form-field>
                <mat-form-field class="full-width" appearance="outline">
                  <input class="mat-block" matInput placeholder="Input"/>
                </mat-form-field>
                <mat-form-field class="full-width" appearance="outline">
                  <input class="mat-block" matInput placeholder="Input"/>
                </mat-form-field>
                <mat-form-field class="full-width" appearance="outline">
                  <input class="mat-block" matInput placeholder="Input"/>
                </mat-form-field>
              </div>
            </div>
            <div class="half-width">
              <div class="col-right">
                <mat-form-field class="full-width" appearance="outline">
                  <input class="mat-block" matInput placeholder="Input"/>
                </mat-form-field>
                <mat-form-field class="full-width" appearance="outline">
                  <input class="mat-block" matInput placeholder="Input"/>
                </mat-form-field>
                <mat-form-field class="full-width" appearance="outline">
                  <input class="mat-block" matInput placeholder="Input"/>
                </mat-form-field>
                <mat-form-field class="full-width" appearance="outline">
                  <input class="mat-block" matInput placeholder="Input"/>
                </mat-form-field>
                <mat-form-field class="full-width" appearance="outline">
                  <input class="mat-block" matInput placeholder="Input"/>
                </mat-form-field>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="full-width text-center">
      <!-- <button mat-raised-button color="primary" class="mb mt pull-left" (click)="backClicked()">
        <mat-icon>keyboard_arrow_left</mat-icon> Back to {{ "KEYWORD.patient" | translate | titlecase }} List
      </button> -->
      <button class="mb mt pull-right" (click)="editDetails()" mat-raised-button color="accent">
        <mat-icon>save</mat-icon>Save
      </button>
    </div>
  </div>

  <!-- messaging -->

  <div class="masonry" *ngIf="page == 'messagingPage'" @ngIfAnimation>
    <div class="item" *ngIf="messageDetails == true">
      <mat-card class="clearfix">
        <div class="colour-stripe"></div>
        <mat-card-header>
          <mat-card-title>
            <h3 class="sr-title sm-h3 rm-m">
              <mat-icon class="fas fa-envelope"></mat-icon>
              Messages
            </h3>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="row clearfix">
            <p class="pull-right" *ngIf="dateFrom != null || dateTo != null">
              <span *ngIf="dateFrom"
              >From:
                <strong>{{ dateFrom | customDateTime }} </strong>
              </span>

              <span *ngIf="dateTo">
                To:
                <strong>{{ dateTo | customDateTime }}</strong>
              </span>
            </p>

            <h1 class="dataHeader rm-mt rm-mb pull-left">
              Messages:
              <span class="badge" countUp></span>
            </h1>

            <button class="btn-small pull-right" id="newMessage" mat-raised-button color="accent">
              <mat-icon>add</mat-icon> New Message
            </button>
          </div>
          <br/>

          <div class="row clearfix">
            <div class="full width">
              <mat-card class="list-card">
                <mat-table class="list-table" #table [dataSource]="dataSource" matSort>
                  <!--- Note that these columns can be defined in any order.
                                The actual rendered columns are set as a property on the row definition" -->
                  <!-- ID Column -->

                  <!-- Progress Column -->
                  <ng-container cdkColumnDef="Description">
                    <mat-header-cell *cdkHeaderCellDef mat-sort-header>Description</mat-header-cell>
                    <mat-cell *cdkCellDef="let row"></mat-cell>
                  </ng-container>
                  <ng-container cdkColumnDef="Type">
                    <mat-header-cell *cdkHeaderCellDef mat-sort-header>Type</mat-header-cell>
                    <mat-cell *cdkCellDef="let row"></mat-cell>
                  </ng-container>

                  <!-- Name Column -->
                  <ng-container cdkColumnDef="Status">
                    <mat-header-cell *cdkHeaderCellDef mat-sort-header>Status</mat-header-cell>
                    <mat-cell *cdkCellDef="let row"></mat-cell>
                  </ng-container>
                  <!-- Name Column -->

                  <!-- Color Column -->
                  <ng-container cdkColumnDef="Written">
                    <mat-header-cell *cdkHeaderCellDef mat-sort-header>Written</mat-header-cell>
                    <mat-cell *cdkCellDef="let row"></mat-cell>
                  </ng-container>
                  <!-- Color Column -->
                  <ng-container cdkColumnDef="Submitted">
                    <mat-header-cell *cdkHeaderCellDef mat-sort-header>Submitted</mat-header-cell>
                    <mat-cell *cdkCellDef="let row"></mat-cell>
                  </ng-container>

                  <ng-container cdkColumnDef="Sent">
                    <mat-header-cell *cdkHeaderCellDef mat-sort-header>Sent</mat-header-cell>
                    <mat-cell *cdkCellDef="let row"></mat-cell>
                  </ng-container>
                  <ng-container cdkColumnDef="Time">
                    <mat-header-cell *cdkHeaderCellDef mat-sort-header>Time</mat-header-cell>
                    <mat-cell *cdkCellDef="let row"></mat-cell>
                  </ng-container>

                  <!-- Action Button Column -->
                  <ng-container cdkColumnDef="Actions">
                    <mat-header-cell *cdkHeaderCellDef mat-sort-header>Actions</mat-header-cell>
                    <mat-cell *cdkCellDef="let row">
                      <div style="float: right">
                        <button [matMenuTriggerFor]="menu" mat-icon-button>
                          <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                          <button mat-menu-item>
                            <mat-icon>delete</mat-icon>
                            <span>Delete Contact</span>
                          </button>
                        </mat-menu>
                      </div>
                    </mat-cell>
                  </ng-container>
                  <mat-header-row *cdkHeaderRowDef="displayedColumnsM"></mat-header-row>
                  <mat-row *cdkRowDef="let row; columns: displayedColumnsM"></mat-row>
                </mat-table>
              </mat-card>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="full-width text-center">
      <!-- <button mat-raised-button color="primary" class="mb mt pull-left" (click)="backClicked()">
        <mat-icon>keyboard_arrow_left</mat-icon> Back to Patient List
      </button> -->
      <button class="mb mt pull-right" (click)="editDetails()" mat-raised-button color="accent">
        <mat-icon>save</mat-icon>Save
      </button>
    </div>
  </div>

  <!-- action log -->

  <div class="masonry" *ngIf="page == 'actionLogPage'" @ngIfAnimation>
    <div class="item" *ngIf="actionlogDetails == true" @ngIfAnimation>
      <mat-card class="clearfix">
        <div class="colour-stripe"></div>
        <mat-card-header>
          <mat-card-title>
            <h3 class="sr-title sm-h3 rm-m">
              <mat-icon class="fas fa-asterisk"></mat-icon>
              Action Log
            </h3>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="row clearfix greyArea">
            <app-action-log-list [targetID]="customerID"></app-action-log-list>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="full-width text-center">
      <!-- <button mat-raised-button color="primary" class="mb mt pull-left" (click)="backClicked()">
        <mat-icon>keyboard_arrow_left</mat-icon> Back to Patient List
      </button> -->
      <button class="mb mt pull-right" (click)="editDetails()" mat-raised-button color="accent">
        <mat-icon>save</mat-icon>Save
      </button>
    </div>
  </div>

  <!-- verification -->

  <div class="masonry" *ngIf="page == 'verificationPage'" @ngIfAnimation>
    <div class="item" *ngIf="verificationDetails == true" @ngIfAnimation>
      <mat-card class="clearfix">
        <div class="colour-stripe"></div>
        <mat-card-header>
          <mat-card-title>
            <h3 class="sr-title sm-h3 rm-m">
              <mat-icon class="fas fa-check-circle"></mat-icon>
              Verification
            </h3>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="row clearfix">
            <app-details-card-verification></app-details-card-verification>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="full-width text-center">
      <!-- <button mat-raised-button color="primary" class="mb mt pull-left" (click)="backClicked()">
        <mat-icon>keyboard_arrow_left</mat-icon> Back to Patient List
      </button> -->
      <button class="mb mt pull-right" (click)="editDetails()" mat-raised-button color="accent">
        <mat-icon>save</mat-icon>Save
      </button>
    </div>
  </div>

  <!-- documents -->

  <div class="masonry" *ngIf="page == 'documentsPage'" @ngIfAnimation>
    <div class="item" *ngIf="documentsInformation == true" @ngIfAnimation>
      <mat-card class="clearfix">
        <div class="colour-stripe"></div>
        <mat-card-header>
          <mat-card-title>
            <h3 class="sr-title sm-h3 rm-m">
              <mat-icon class="fas fa-file-alt"></mat-icon>
              Documents
            </h3>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="row clearfix">
            <h1 class="dataHeader rm-mt rm-mb pull-left">
              Documents:
              <span class="badge" countUp></span>
            </h1>
            <button class="btn-small pull-right" id="newMessage" mat-raised-button color="accent">
              <mat-icon>add</mat-icon> New Document
            </button>
          </div>
          <br/>

          <div class="row clearfix">
            <div class="full width">
              <mat-card class="list-card">
                <mat-table class="list-table" #table [dataSource]="dataSource" matSort>
                  <!--- Note that these columns can be defined in any order.
                                The actual rendered columns are set as a property on the row definition" -->
                  <!-- ID Column -->

                  <!-- Progress Column -->
                  <ng-container cdkColumnDef="Label" sticky>
                    <mat-header-cell *cdkHeaderCellDef mat-sort-header>Label</mat-header-cell>
                    <mat-cell *cdkCellDef="let row"></mat-cell>
                  </ng-container>
                  <ng-container cdkColumnDef="Description">
                    <mat-header-cell *cdkHeaderCellDef mat-sort-header>Description</mat-header-cell>
                    <mat-cell *cdkCellDef="let row"></mat-cell>
                  </ng-container>

                  <!-- Name Column -->
                  <ng-container cdkColumnDef="Extension">
                    <mat-header-cell *cdkHeaderCellDef mat-sort-header>Extension</mat-header-cell>
                    <mat-cell *cdkCellDef="let row"></mat-cell>
                  </ng-container>
                  <!-- Name Column -->

                  <!-- Color Column -->
                  <ng-container cdkColumnDef="Version">
                    <mat-header-cell *cdkHeaderCellDef mat-sort-header>Version</mat-header-cell>
                    <mat-cell *cdkCellDef="let row"></mat-cell>
                  </ng-container>
                  <!-- Color Column -->
                  <ng-container cdkColumnDef="LastModified">
                    <mat-header-cell *cdkHeaderCellDef mat-sort-header>Last Modified</mat-header-cell>
                    <mat-cell *cdkCellDef="let row"></mat-cell>
                  </ng-container>

                  <ng-container cdkColumnDef="Type">
                    <mat-header-cell *cdkHeaderCellDef mat-sort-header>Type</mat-header-cell>
                    <mat-cell *cdkCellDef="let row"></mat-cell>
                  </ng-container>

                  <ng-container cdkColumnDef="Area">
                    <mat-header-cell *cdkHeaderCellDef mat-sort-header>Area</mat-header-cell>
                    <mat-cell *cdkCellDef="let row"></mat-cell>
                  </ng-container>

                  <!-- Action Button Column -->
                  <ng-container cdkColumnDef="Actions">
                    <mat-header-cell *cdkHeaderCellDef mat-sort-header>Actions</mat-header-cell>
                    <mat-cell *cdkCellDef="let row">
                      <div style="float: right">
                        <button [matMenuTriggerFor]="menu" mat-icon-button>
                          <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                          <button mat-menu-item>
                            <mat-icon>delete</mat-icon>
                            <span>Delete Document</span>
                          </button>
                        </mat-menu>
                      </div>
                    </mat-cell>
                  </ng-container>
                  <mat-header-row *cdkHeaderRowDef="displayedColumnsD"></mat-header-row>
                  <mat-row *cdkRowDef="let row; columns: displayedColumnsD"></mat-row>
                </mat-table>
              </mat-card>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="full-width text-center">
      <!-- <button mat-raised-button color="primary" class="mb mt pull-left" (click)="backClicked()">
        <mat-icon>keyboard_arrow_left</mat-icon> Back to Patient List
      </button> -->
      <button class="mb mt pull-right" (click)="editDetails()" mat-raised-button color="accent">
        <mat-icon>save</mat-icon>Save
      </button>
    </div>
  </div>

  <!-- logons -->

  <div class="masonry" *ngIf="page == 'logonsPage'" @ngIfAnimation>
    <div class="item" *ngIf="logonsDetails == true" @ngIfAnimation>
      <mat-card class="clearfix">
        <div class="colour-stripe"></div>
        <mat-card-header>
          <mat-card-title>eConnect</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="row clearfix">
            <div class="half-width">
              <div class="col-left">
                <mat-form-field class="full-width" appearance="outline">
                  <input class="mat-block" matInput placeholder="Username"/>
                </mat-form-field>
                <mat-form-field class="full-width" appearance="outline">
                  <input class="mat-block" matInput placeholder="Password"/>
                </mat-form-field>
                <mat-form-field class="full-width" appearance="outline">
                  <input class="mat-block" matInput placeholder="Re-enter Password"/>
                </mat-form-field>

                <mat-select class="full-width" name="mobileSitePreference" placeholder="Mobile Site Preference">
                  <mat-option></mat-option>
                </mat-select>
              </div>
            </div>
            <div class="half-width">
              <div class="col-right">
                <button class="pull-right" mat-raised-button>Show Groups</button>
                <mat-checkbox>Logon Enabled?</mat-checkbox>
                <br/>
                <mat-checkbox>Is Administrator?</mat-checkbox>
                <br/>
                <mat-checkbox>Is Wakanda Master User?</mat-checkbox>
                <br/>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>

    <!-- <div class="item">
      <mat-card class="clearfix">
        <div class="colour-stripe "></div>
        <mat-card-header>
          <mat-card-title>ePremium</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="row clearfix">
            <div class="half-width">
              <div class="col-left">
                <mat-form-field class="full-width" appearance="outline">
                  <input matInput class="mat-block" placeholder="Username">
                </mat-form-field>
                <mat-form-field class="full-width" appearance="outline">
                  <input matInput class="mat-block" placeholder="Password">
                </mat-form-field>
                <mat-form-field class="full-width" appearance="outline">
                  <input matInput class="mat-block" placeholder="Re-enter password">
                </mat-form-field>
                <mat-checkbox>Logon Enabled?</mat-checkbox><br>
                <mat-checkbox>Is Administrator?</mat-checkbox><br>
                <mat-select name="managementLevel" class="full-width" placeholder="Management Level">
                  <mat-option>
                  </mat-option>
                </mat-select>
              </div>
            </div>

            <div class="half-width">
              <div class="col-right">
                <button mat-raised-button class="pull-right">Show Groups</button>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>  -->
    <div class="full-width text-center">
      <!-- <button mat-raised-button color="primary" class="mb mt pull-left" (click)="backClicked()">
        <mat-icon>keyboard_arrow_left</mat-icon> Back to Patient List
      </button> -->
      <button class="mb mt pull-right" (click)="editDetails()" mat-raised-button color="accent">
        <mat-icon>save</mat-icon>Save
      </button>
    </div>
  </div>

  <!--  Settlements -->

  <div class="masonry" *ngIf="page == 'settlementPage'" @ngIfAnimation>
    <div class="item" *ngIf="actionlogDetails == true" @ngIfAnimation>
      <mat-card class="clearfix">
        <div class="colour-stripe"></div>
        <mat-card-header>
          <mat-card-title>
            <h3 class="sr-title sm-h3 rm-m">
              <mat-icon class="fas fa-dollar-sign"></mat-icon>
              Settlements
            </h3>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="row clearfix">
            <app-contracts-view [customerID]="selectedCustomer['ID']" [isShowGoBackBtn]="false"></app-contracts-view>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="full-width text-center">
      <!-- <button mat-raised-button color="primary" class="mb mt pull-left" (click)="backClicked()">
        <mat-icon>keyboard_arrow_left</mat-icon> Back to Patient List
      </button> -->
      <button class="mb mt pull-right" (click)="editDetails()" mat-raised-button color="accent">
        <mat-icon>save</mat-icon>Save
      </button>
    </div>
  </div>
</div>
