<form #editForm="ngForm">
  <div class="row clearfix">
    <div class="pull-right">
      <button (click)="preview()" mat-mini-fab color="primary" matTooltip="Preview document screen">
        <mat-icon>remove_red_eye</mat-icon>
      </button>
      <button (click)="openConfirmDialog()" mat-mini-fab color="warn" matTooltip="Delete document">
        <mat-icon>delete</mat-icon>
      </button>
    </div>

    <div class="pull-left">
      <h3 class="sr-title sm-h3 rm-mt rm-mb">Page Title</h3>
      <mat-form-field class="clearfix simplePageTitle full-width">
        <mat-label>Title</mat-label>
        <input [(ngModel)]="simplePage.title" name="title" matInput required/>
      </mat-form-field>
    </div>
  </div>
  <h3 class="sr-title sm-h3 rm-mt rm-mb mb">Page Content</h3>

  <app-text-editor-input
    class="clearfix"
    [content]="content"
    [title]="null"
    [isPreview]="false"
    [isAction]="false"
    (getContent)="getContent($event)"
    name="editor"
  >
  </app-text-editor-input>

  <div class="full-width text-center step-button-row mb">
    <div class="full-width text-center step-button-row">
      <button
        class="btn btn-primary"
        [disabled]="!editForm.form.valid || !simplePage.content"
        (click)="update()"
        mat-raised-button
        type="button"
        color="accent"
      >
        Update
      </button>
    </div>

    <div class="row clearfix">
      <hr class="mt"/>
      <span class="history"> <mat-icon>history</mat-icon>Last update: {{ simplePage.lastUpdate | date }} </span>
      <span class="history">By: {{ simplePage.userName }}</span>
      <div class="pull-right">
        <label>Page Code: {{ simplePage.type }} </label>
      </div>
    </div>
  </div>
</form>
