<div class="rel lookup">
  <div *ngIf="!hideFilter">
    <div class="clearfix">

      <div class="card-header primary-gradient-img clearfix inModal">
        <div class="row clearfix">
          <button class="btn-close btn-clear mat-button white" *ngIf="isModal == true" (click)="closeEvent()"
            tabIndex="-1">
            <mat-icon>clear</mat-icon>
          </button>
          <div class="row titleArea clearfix">
            <mat-icon class="fas fa-user pull-left patient-main-icon"></mat-icon>
            <h2 class="summary-header rm-m">
              <!-- {{ beneficiary.CalculatedName}} -->
              Staff Lookup

              <span class="subLabel" *ngIf="hideFilter != true">

                You can search for them using any (or all) of the field below:

              </span>
              <span class="subLabel" *ngIf="hideFilter == true">

                You detected a possible matching Staff records in our database. Please confirm if
                any of the matches bellow are are the same records as the one you are entering.

              </span>
            </h2>
          </div>

          <button class="btn mr new-patient-button pull-right"
            *ngIf="isNewStaff == true && (isPromoterOrAdmin==true  || isMerchantAdmin==true)"
            [matMenuTriggerFor]="menuListsasaS" mat-raised-button color="accent">
            <mat-icon class="fas fa-user-plus smr" style="font-size: 1rem; margin-top: 10px"></mat-icon>
            New
            Staff Member
          </button>


          <mat-menu #menuListsasaS="matMenu">
            <button (click)="newStaff()" mat-menu-item>
              <strong>Without</strong> Sign-in Account
            </button>
            <hr />
            <button (click)="newStaffDetails()" mat-menu-item>
              <strong>With</strong> Sign-in Account
            </button>

          </mat-menu>


          <!-- <button class="btn-close btn-clear mat-button white" matDialogClose>
            <mat-icon>clear</mat-icon>
          </button> -->
        </div>
      </div>

      <div class="clearfix full-width previous-search" *ngIf="contacts && contacts.length > 0">
        <button class="clearfix pull-right" (click)="goForward()" mat-raised-button color="primary">
          {{ contacts.length }} member(s)
          <mat-icon>keyboard_arrow_right</mat-icon>
        </button>
        <p class="rm-m mr line-height">View the result of your previous search</p>
      </div>

      <hr class="mb" *ngIf="contacts && contacts.length > 0" />

      <form class="lookup-form" #lookupForm="ngForm">
        <div class="full-width clearfix mt">
          <mat-form-field class="full-width" *ngIf="
              merchants &&
              merchants.length > 0 &&
              currentMerchant &&
              currentMerchant.ID &&
              displayMerchantList == true &&
              isPromoterOrAdmin == true
            " appearance="outline">
            <mat-label>Merchant</mat-label>

            <mat-select [(ngModel)]="merchantID" (ngModelChange)="getClientObject()" name="merchantID"
              placeholder="Merchant">
              <mat-select-trigger>
                <div class="full-width flex flex-cust" *ngIf="merchant && merchant.ID">
                  <app-card-picture class="sms-merchant-contact-pic prod text-center pull-left mr"
                    [hideZoomButton]="true" [cardID]="merchant.ID" [hideZoomButton]="false" [tableName]="'Merchant'"
                    [LastModified]="merchant['LastModified']">
                  </app-card-picture>
                  <p class="rm-m third-party-p full-width pull-left">
                    {{ merchant.TradingAs }}
                  </p>
                  <button class="mr" (click)="$event.stopPropagation(); viewMerchant(merchantID)" mat-button
                    mat-icon-button>
                    <mat-icon>visibility</mat-icon>
                  </button>
                </div>
              </mat-select-trigger>
              <mat-option [value]="currentMerchant.ID">
                <div class="full-width flex flex-cust">
                  <app-card-picture class="sms-merchant-contact-pic prod text-center pull-left mr"
                    [hideZoomButton]="true" [cardID]="currentMerchant.ID" [hideZoomButton]="false"
                    [tableName]="'Merchant'" [LastModified]="currentMerchant['LastModified']">
                  </app-card-picture>
                  <p class="rm-m third-party-p full-width pull-left">
                    <strong>Promoter</strong>
                  </p>

                  <button class="mr" (click)="$event.stopPropagation(); viewMerchant(currentMerchant['ID'])" mat-button
                    mat-icon-button>
                    <mat-icon style="width: 40px">visibility</mat-icon>
                  </button>
                </div>
              </mat-option>

              <mat-option *ngFor="let s of merchants | OrderArrayObject: ['TradingAs']" [value]="s.ID">
                <div class="full-width flex flex-cust">
                  <app-card-picture class="sms-merchant-contact-pic prod text-center pull-left mr"
                    [hideZoomButton]="true" [cardID]="s.ID" [hideZoomButton]="false" [tableName]="'Merchant'"
                    [LastModified]="s['LastModified']">
                  </app-card-picture>
                  <p class="rm-m third-party-p full-width pull-left">
                    {{ s.TradingAs }}
                  </p>

                  <button class="mr" (click)="$event.stopPropagation(); viewMerchant(s['ID'])" mat-button
                    mat-icon-button>
                    <mat-icon style="width: 40px">visibility</mat-icon>
                  </button>
                </div>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>



        <div class="full-width clearfix flex">

          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Username</mat-label>
            <input [(ngModel)]="partUserName" matInput name="Username" placeholder="Username" tabIndex="1" />
          </mat-form-field>

        </div>
        <div class="full-width clearfix flex">


          <mat-form-field class="full-width" *ngIf="useStrict != true" appearance="outline">
            <mat-label>Name</mat-label>
            <input [(ngModel)]="filterName" matInput name="name" placeholder="Name" tabIndex="1" />
          </mat-form-field>

          <mat-form-field class="full-width" *ngIf="useStrict == true" appearance="outline">
            <mat-label>First name, Surname, Middle name, Full name...</mat-label>
            <input [(ngModel)]="calculatedName" matInput name="calculatedName"
              placeholder="First name, Surname, Full name" tabIndex="1" />
          </mat-form-field>

          <!-- <mat-form-field class="full-width ml" *ngIf="useStrict==true" appearance="outline">
            <mat-label>Surname</mat-label>
            <input matInput name="lastName" placeholder="Surname" tabIndex="2" [(ngModel)]="lastName">
          </mat-form-field> -->
        </div>
        <div class="full-width clearfix flex">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Mobile</mat-label>
            <input [(ngModel)]="mobile" matInput name="mobile" placeholder="Mobile" tabIndex="3" />
          </mat-form-field>

          <mat-form-field class="full-width ml" appearance="outline">
            <mat-label>Phone</mat-label>
            <input [(ngModel)]="filterPhone" matInput name="phone" placeholder="Phone" tabIndex="3" />
          </mat-form-field>
        </div>

        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Email</mat-label>
          <input [(ngModel)]="filterEmail" matInput name="email" type="email" tabIndex="4" placeholder="Email" />
        </mat-form-field>


        <div class="drop-buttons">
          <button class="inline btn-new-invite btn-large" (click)="searchContact()" mat-raised-button color="accent"
            tabIndex="4">
            Search
          </button>
        </div>
      </form>
    </div>
  </div>

  <div *ngIf="hideFilter">
    <div>
      <div class="card-header primary-gradient-img clearfix inModal">
        <div class="row clearfix">
          <button class="btn-close btn-clear mat-button white" *ngIf="isModal == true" (click)="closeEvent()">
            <mat-icon>clear</mat-icon>
          </button>
          <div class="row titleArea clearfix">
            <mat-icon class="pull-left patient-main-icon">account_circle</mat-icon>
            <h2 class="summary-header rm-m">
              <!-- {{ beneficiary.CalculatedName}} -->
              Possible Match
              <span class="subLabel">
                You detected a possible matching Staff records in our database. <br />
                Please confirm if any of the matches below are are the same records as the one you are entering.
              </span>
            </h2>
          </div>
        </div>
      </div>
      <h1 class="sr-title sm-h1 rm-mt">
        Staff

        <span class="badge master" *ngIf="contacts" [endVal]="
            (contacts
              | FilterArrayMultipleOrContain
                : ['Name','operatorID','ID','operatorUserName', 'FirstName', 'emails.Email', 'mobiles.Number', 'operatorUserName', 'operatorID','ID']
                : filterData
              |     FilterArrayMultipleOrContain
              : ['hasOperatorAccount']
                : accountType   |   lengthOfArray) || 0
          " countUp></span>
      </h1>
      <div class="clearfix flex flex-center">
        <mat-form-field class="full-width mr" appearance="outline">
          <mat-label>Search results by Name, email or phone number</mat-label>
          <input [(ngModel)]="filterData" matInput name="search" tabIndex="1" />
        </mat-form-field>

        <mat-form-field style="
        width: 180px !important;
    " *ngIf="(isPromoterOrAdmin==true || isMerchantAdmin==true ) " appearance="outline">
          <mat-label>Account Status</mat-label>
          <mat-select class="full-width " [(ngModel)]="accountType" (change)="goPageOne()" name="accountType"
            placeholder="Account Status">
            <mat-option [value]="null">
              All
            </mat-option>
            <mat-option [value]="'0'">
              <mat-icon class="fas fa-user smr"></mat-icon> Staff Without Account
            </mat-option>
            <mat-option [value]="'1'">
              <mat-icon class="fas fa-sign-in-alt smr"></mat-icon> Staff With Account
            </mat-option>

          </mat-select>
        </mat-form-field>

      </div>
    </div>

    <div class="patientContainer">
      <div class="patientRecord row clearfix" *ngFor="
          let patient of contacts
            | FilterArrayMultipleOrContain: ['Name', 'operatorID','ID','operatorUserName','FirstName', 'emails.Email', 'mobiles.Number', 'operatorUserName', 'operatorID','ID']:filterData |   FilterArrayMultipleOrContain
                : ['hasOperatorAccount']
                : accountType
            | OrderArrayObject: [orderByFilter]
            | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage }
        " [ngClass]="{ activeExistingPatient: selectedPatient == patient }">
        <div class="pull-right upper-side">
          <button class="" *ngIf="patient && patient['ID'] " [matMenuTriggerFor]="menu" color="accent" mat-icon-button
            matTooltip="View  Details">
            <mat-icon>more_vert</mat-icon>
          </button>

          <mat-menu #menu="matMenu">
            <button (click)="viewDentist(patient['ID'])" mat-menu-item>
              <mat-icon class="smr">visibility</mat-icon>
              Quick View
            </button>

            <button *ngIf=" patient['emails.Email']" (click)="contactDentist(patient['ID'])" mat-menu-item>
              <mat-icon class="smr fas fa-at"></mat-icon>
              Contact Staff
            </button>


            <button *ngIf="displayChatButton == true && isModulePatientSMSActive == true && patient['mobiles.Number']"
              (click)="displayChat(patient['ID'])" mat-menu-item>
              <mat-icon class="smr">textsms</mat-icon>
              SMS Chat
            </button>



            <hr
              *ngIf="(isPromoterOrAdmin==true || isMerchantAdmin==true ) && patient['operatorID'] && patient['emails.Email']" />
            <button
              *ngIf="(isPromoterOrAdmin==true || isMerchantAdmin==true ) && patient['operatorID'] && patient['emails.Email']"
              (click)="passwordUpdate(patient.operatorID)" mat-menu-item>
              <mat-icon class="smr">lock</mat-icon>
              <span>Prompt password update</span>
            </button>

            <hr *ngIf="(isPromoterOrAdmin==true || isMerchantAdmin==true ) && patient['operatorID']" />
            <button *ngIf="(isPromoterOrAdmin==true || isMerchantAdmin==true ) && patient['operatorID'] "
              (click)="operatorAccess(patient.operatorID)" mat-menu-item>
              <mat-icon class="fas fa-key smr "></mat-icon>
              <span>Portals Access</span>
            </button>



            <hr *ngIf="isPromoterOrAdmin==true  && patient['operatorID'] && patient['ID']" />
            <button color="accent" class="btn"
              *ngIf="isPromoterOrAdmin==true  && patient['operatorID'] && patient['ID']" (click)="loginAsUser(patient)"
              mat-menu-item>
              <mat-icon class="  fas fa-user-lock accent-color mr"></mat-icon>
              <span class=" accent-color">Login as user</span>
            </button>



          </mat-menu>
        </div>

        <div class="flex truncate" (click)="SelectRecordClickEvent(patient)"
          matTooltip="Click to choose {{ patient['FirstName'] }} {{ patient['Name'] }}">
          <div class="profile-result">

            <mat-icon *ngIf="(isPromoterOrAdmin==true || isMerchantAdmin==true ) && patient['operatorID']"
              class="fas fa-sign-in-alt patient-login-icon accent-color"></mat-icon>
            <!-- avatar image -->
            <app-card-picture class="pull-left contactIconLabel invitationContactLookup mr" [cardID]="patient['ID']"
              [LastModified]="patient['LastModified']" [tableName]="'Contact'">
            </app-card-picture>
            <!-- profile information -->
            <div class="pull-left">
              <h3 class="sr-title rm-m">{{ patient['FirstName'] }} {{ patient['Name'] }}

                <span *ngIf="(isPromoterOrAdmin==true || isMerchantAdmin==true ) && patient['operatorIsAdmin']=='1'"
                  class="chip chip-enable sml">
                  Merchant Admin
                </span>

              </h3>


              <p class="small rm-m" *ngIf="patient['ContactType.Label']" matLine>
                <strong> {{ patient['ContactType.Label'] }}</strong>
              </p>

              <p class="small rm-m"
                *ngIf="(isPromoterOrAdmin==true || isMerchantAdmin==true ) && patient['operatorUserName']" matLine>
                <strong>Username:</strong> {{ patient['operatorUserName'] }}
              </p>


              <p class="small rm-m" *ngIf="patient['mobiles.Number']" matLine>
                {{ patient['mobiles.Number'] | customPhone }}
              </p>
              <p class="small rm-m" *ngIf="patient['emails.Email']" matLine>
                {{ patient['emails.Email'] }}
              </p>


            </div>
          </div>
        </div>

        <mat-divider class="mt"></mat-divider>
      </div>

      <div class="full-width" *ngIf="
          (contacts
            | FilterArrayMultipleOrContain
              : ['Name','operatorID','ID','operatorUserName', 'FirstName', 'emails.Email', 'mobiles.Number', 'operatorUserName', 'operatorID','ID']
              : filterData
            |   FilterArrayMultipleOrContain
                : ['hasOperatorAccount']
                : accountType  |  lengthOfArray) <= 0
        ">
        <app-empty-list-message [message]="'No Record found'"></app-empty-list-message>
      </div>
    </div>

    <div class="full-width text-center page-container mt flex mb" *ngIf="
        (contacts
          | FilterArrayMultipleOrContain
            : ['Name','operatorID','ID','operatorUserName', 'FirstName', 'emails.Email', 'mobiles.Number', 'operatorUserName', 'operatorID','ID']
            : filterData
          |    FilterArrayMultipleOrContain
          : ['hasOperatorAccount']
                : accountType  | lengthOfArray) > 10
      ">
      <pagination-controls class="full-width clearfix" (pageChange)="changePage($event)" responsive="true">
      </pagination-controls>
    </div>

    <div class="drop-button text-center clearfix mt full-width" *ngIf="isModal == true">
      <ng-container *ngIf="showBackButton == true">
        <button class="inline btn-new-invite selectExistingPatient pull-left" (click)="goBack()" mat-raised-button
          color="accent">
          <mat-icon>keyboard_arrow_left</mat-icon> Back
        </button>
      </ng-container>

      <button class="inline btn-new-invite selectExistingPatient"
        [ngClass]="{ 'pull-right': showNextBtn != true, 'pull-left': showBackButton != true }" (click)="closeEvent()"
        mat-raised-button color="primary">
        Close
      </button>

      <ng-container *ngIf="showNextBtn == true">
        <button class="inline btn-new-invite selectExistingPatient pull-right" (click)="noMatch()" mat-raised-button
          color="accent">
          Add New {{ "Dentist" | translate | titlecase }}
          <mat-icon>add</mat-icon>
        </button>
      </ng-container>
    </div>
  </div>
</div>
