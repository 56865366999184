import { animate, animation, keyframes, query, stagger, style } from '@angular/animations';

export const ngIfRowInAnimation = animation(
  [
    query('.row', style({ opacity: 1 }), { optional: true }),
    query(
      '.row',
      stagger('100ms', [
        animate(
          '{{ timings }} ease-in',
          keyframes([
            style({
              opacity: 1,
              transform: 'translateY(0)',
              offset: 0,
              height: '*',
            }),
            style({
              opacity: 0,
              transform: 'translateY(-75%)',
              offset: 1.0,
              height: 0,
            }),
          ])
        ),
      ]),
      { optional: true }
    ),
  ],
  { params: { timings: '0.5s' } }
);

export const ngIfRowOutAnimation = animation(
  [
    query('.row', style({ opacity: 0 }), { optional: true }),
    query(
      '.row',
      stagger('100ms', [
        animate(
          '{{ timings }} ease-out',
          keyframes([
            style({
              opacity: 0,
              transform: 'translateY(-75%)',
              offset: 0,
              height: 0,
            }),
            style({
              opacity: 1,
              transform: 'translateY(0)',
              offset: 1.0,
              height: '*',
            }),
          ])
        ),
      ]),
      { optional: true }
    ),
  ],
  { params: { timings: '0.5s' } }
);
