import { TitleCasePipe } from '@angular/common';
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { combineLatest } from 'rxjs';
import { mergeMap, switchMap, tap } from 'rxjs/operators';
import { AuthenticationService } from '../../core/authentication/shared/authentication.service';
import {
  ConfirmModalComponent,
  ConfirmModalConfig,
} from '../../shared/components/confirm-modal/confirm-modal.component';
import { NotifyAppComponent } from '../../shared/types/notify-app-component';
import { MerchantService } from '../merchant/shared/merchant.service';
import { SmileMakeover } from '../merchant/shared/types/smile-makeover.type';
import { WishlistService } from '../wishlist/shared/services/wishlist.service';
import {
  SmileMakeoverModalViewComponent,
  SmileMakeoverModalViewConfig,
} from './smile-makeover-modal-view/smile-makeover-modal-view.component';

@Component({
  selector: 'ipv-ventures-smile-makeover-manager',
  templateUrl: './smile-makeover-manager.component.html',
  styleUrls: ['./smile-makeover-manager.component.css'],
  providers: [TitleCasePipe],
})
export class SmileMakeoverManagerComponent {
  smileMakeovers$ = combineLatest([
    this.authenticationService.getCurrentUser(),
    this.authenticationService.isPromoterOrAdmin(),
  ]).pipe(
    mergeMap(([currentUserResponse, isPromoterOrAdmin]) => {
      const user = currentUserResponse.data;
      this.isPromoterOrAdmin = isPromoterOrAdmin;
      this.merchantKey = isPromoterOrAdmin ? this.merchantKey : user.merchantKey;

      return this.merchantService.fetchSmileMakeoverList({ Merchant_key: user.merchantKey }).pipe(
        tap((smileMakeovers) => {
          this.merchantService.setSmileMakeovers(smileMakeovers);
        })
      );
    }),
    switchMap(() => {
      return this.merchantService.getSmileMakeovers$();
    })
  );
  merchantKey;
  isPromoterOrAdmin = false;

  constructor(
    private merchantService: MerchantService,
    private authenticationService: AuthenticationService,
    private titleCasePipe: TitleCasePipe,
    private wishlistService: WishlistService,
    private dialog: MatDialog
  ) {}

  openSmileMakeoverViewModal(item: unknown) {
    const smileMakeover = item as SmileMakeover;

    this.wishlistService
      .fetchWishlistIDs({ WishListItems_IDs: smileMakeover.WishListItems_IDs })
      .subscribe((wishlist) => {
        const smileMakeoverViewModal = this.dialog.open<SmileMakeoverModalViewComponent, SmileMakeoverModalViewConfig>(
          SmileMakeoverModalViewComponent,
          {
            data: {
              smileMakeover,
              wishlist,
            },
          }
        );
      });
  }

  changeMerchant(merchantKey) {
    this.merchantService.fetchSmileMakeoverList(merchantKey).subscribe((smileMakeovers) => {
      this.merchantService.setSmileMakeovers(smileMakeovers);
      this.merchantKey = merchantKey;
    });
  }

  openSmileMakeoverDeleteModal(smileMakeover: SmileMakeover) {
    const smileMakeoverManagerDeleteModal = this.dialog.open<ConfirmModalComponent, ConfirmModalConfig>(
      ConfirmModalComponent,
      {
        data: {
          message: `Are you sure you want to delete smile makeover for ${this.titleCasePipe.transform(
            smileMakeover['Card.CalculatedName']
          )}?`,
          acceptButtonText: 'Yes',
        },
      }
    );

    smileMakeoverManagerDeleteModal.componentInstance.acceptButtonClicked.subscribe(() => {
      this.merchantService
        .deleteSmileMakeover(smileMakeover)
        .pipe(
          switchMap(() => {
            return this.merchantService.removeSmileMakeover$(smileMakeover);
          })
        )
        .subscribe(() => {
          NotifyAppComponent.displayToast(
            'success',
            'Successful Operation',
            `Smile makeover for ${this.titleCasePipe.transform(smileMakeover['Card.CalculatedName'])}  deleted.`
          );
          smileMakeoverManagerDeleteModal.close();
        });
    });
  }
}
