<div class="row clearfix docu-container" *ngIf="document">
  <!-- header area -->
  <div class="row clearfix cardheader primary-gradient-img inModal" *ngIf="contentOnly != true">
    <!-- close button -->
    <button
      class="btn-close btn-clear white pull-right mat-button"
      *ngIf="isModal == true"
      (click)="closeModalEvent()"
      matDialogClose
    >
      <mat-icon>clear</mat-icon>
    </button>

    <button
      class="btn-close btn-clear mr ml white pull-right mat-button"
      *ngIf="isFullScreenModal != true"
      (click)="fullScreenModal()"
    >
      <mat-icon class="fas fa-expand"></mat-icon>
    </button>

    <button
      class="btn-close btn-clear mr ml white pull-right mat-button"
      *ngIf="isFullScreenModal == true"
      (click)="fullScreenModal()"
    >
      <mat-icon class="fas fa-compress"></mat-icon>
    </button>
    <div class="action-button pull-right" *ngIf="isModal != true">
      <button
        class="actionMenuToggle"
        *ngIf="isPrint == true || isDownload == true || isRedirection == true"
        [matMenuTriggerFor]="menu"
        mat-icon-button
      >
        <mat-icon class="whiteColor">more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button *ngIf="isPrint == true" (click)="printEvent()" mat-menu-item>
          <mat-icon class="fas fa-print"></mat-icon>
          <span>Print</span>
        </button>
        <button *ngIf="isDownload == true" (click)="downloadFile()" mat-menu-item>
          <mat-icon class="fas fa-download"></mat-icon>
          <span>Download</span>
        </button>
      </mat-menu>
    </div>

    <div class="row titleArea clearfix">
      <app-ep-document-view-image
        class="file-thumbnail-content2 pull-right"
        *ngIf="defaultImage"
        [hideZoomButton]="true"
        [defaultPicture]="defaultImage"
      >
      </app-ep-document-view-image>

      <h2 class="summary-header rm-m white">
        {{ document.Label }}
        <span class="subLabel" *ngIf="document.Description && document.Description != document.Label">
          {{ document.Description }}
        </span>

        <span class="subLabel" *ngIf="description && !document.Description && description != document.Label">
          {{ document.Description }}
        </span>

        <span class="subLabel" *ngIf="document && document['Card.Name']">
          {{ document['Card.Name'] }}
        </span>

        <div class="date-chip-area2" *ngIf="document.DateTimeCreated && document.DateTimeCreated != '0000-00-00'">
          <p class="small rm-m mr">
            <mat-icon class="fas fa-clock"></mat-icon>
            {{ document.DateTimeCreated | TimeLabelPast }} ago
          </p>
        </div>
      </h2>
    </div>
  </div>
  <!-- end header -->

  <!-- start scrollable content pane -->
  <mat-dialog-content
    class="dark-bg"
    *ngIf="displayReplaceSection == false"
    [ngClass]="{ 'no-modal-height': isModal != true }"
  >
    <div class="full-width row clearfix ep-document-view-modal">
      <div
        class="row clearfix text-center noOVerflow"
        *ngIf="document && document['URL.Complete'] && document['URL.Complete'] !== ''"
      >
        <iframe
          class="document-view-iframe"
          *ngIf="isVideo == true"
          [src]="safeVideoURL"
          width="560"
          height="315"
          frameborder="0"
          webkitallowfullscreen
          mozallowfullscreen
          allowfullscreen
        ></iframe>

        <button class="clearfix mt text-center" *ngIf="!isVideo" (click)="openLink()" mat-raised-button color="accent">
          <mat-icon>link</mat-icon> Open the link
        </button>
      </div>

      <div
        class="row clearfix text-center noOVerflow full-height"
        *ngIf="
          document && document['Extension'] && safeLink && (document['URL.Complete'] == '' || !document['URL.Complete'])
        "
      >
        <app-blob-view
          [title]="null"
          [label]="document.Label"
          [labelToPrint]="document.Label"
          [description]="null"
          [link]="safeLink"
          [extension]="document.Extension"
          [isPrint]="false"
          [isDownload]="false"
          [isProceed]="false"
          [applyDownload]="applyDownload"
          [applyPrint]="applyPrint"
        >
        </app-blob-view>
      </div>

      <div
        class="row clearfix text-center noOVerflow"
        *ngIf="document && !safeLink && document['URL.Complete'] == '' && document['ContainsSensitiveData'] == '1'"
      >
        <div class="ep-view-img-locked no-file-fnd" [style.background-image]="defaultLockedDocumentPicture"></div>

        <div class="clearfix div-inner text-center">
          <div class="full-width text-center clearfix" *ngIf="accountType == 'guest'">
            <div class="icons-col icon-locked">
              <mat-icon>lock</mat-icon>
            </div>
            <div class="row-content-col">
              <p class="small rm-mt text-center">This document contains <strong>Private</strong> data .</p>

              <button
                class="btn-clear set-btn-width"
                (click)="requestDocument(document)"
                mat-raised-button
                color="primary"
              >
                <mat-icon>vpn_key</mat-icon> Unlock the Document
              </button>
            </div>
          </div>

          <div class="full-width text-center clearfix" *ngIf="accountType != 'guest'">
            <div class="icons-col icon-locked">
              <mat-icon>lock</mat-icon>
            </div>
            <div class="row-content-col">
              <p class="small rm-mt text-center">Sorry!, this document is Locked</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <!-- end scrolling pane -->

  <!-- Replace document -->
  <mat-dialog-content class="" *ngIf="displayReplaceSection == true">
    <div class="full-width row clearfix">
      <app-file-uploader
        class="text-center"
        [isDragable]="true"
        [limit]="1"
        (onCompleteAll)="onCompleteAllFile($event)"
      ></app-file-uploader>

      <app-file-list
        [hasExtraInfo]="false"
        [files]="replaceFileID"
        [canEdit]="true"
        [canAction]="false"
        (remove)="removeNewTreatmentDocument($event)"
      >
      </app-file-list>
    </div>
  </mat-dialog-content>
  <!-- End replace document -->

  <!-- begin actions footer -->
  <mat-dialog-actions
    *ngIf="
      (description ||
        document.Description ||
        isDownload == true ||
        isPrint == true ||
        isRedirection == true ||
        displayReplaceSection == false) &&
      contentOnly != true
    "
  >
    <!-- button controls -->
    <div class="row clearfix" *ngIf="document && displayReplaceSection == false">
      <div class="row clearfix text-center buttonrow">
        <button
          class="btn-small btn-dl"
          *ngIf="
            isDownload == true &&
            document &&
            (document.Extension == 'pdf' ||
              document.Extension == 'heic' ||
              document.Extension == 'png' ||
              document.Extension == 'jpg' ||
              document.Extension == 'html' ||
              document.Extension == 'jpeg') &&
            (!document['URL.Complete'] || document['URL.Complete'] == '')
          "
          (click)="downloadFile()"
          mat-raised-button
          matTooltip="Download {{ document.Label }} Document"
          color="accent"
        >
          Download <mat-icon>file_download</mat-icon>
        </button>

        <!-- print file -->
        <print-btn
          class="mobHide"
          *ngIf="
            isPrint == true &&
            (document.Extension == 'pdf' ||
              document.Extension == 'png' ||
              document.Extension == 'heic' ||
              document.Extension == 'jpeg' ||
              document.Extension == 'jpg' ||
              document.Extension == 'html') &&
            safeLink &&
            (!document['URL.Complete'] || document['URL.Complete'] == '')
          "
          [ngClass]="{ 'pull-left': isVerify == true }"
          [buttonType]="'small'"
          [label]="'Print'"
          (click)="printEvent()"
        ></print-btn>
        <!-- paginate forwards -->

        <button
          class="btn-small btn-dl pull-right green"
          *ngIf="
            isVerify == true &&
            document &&
            (document.Extension == 'pdf' ||
              document.Extension == 'heic' ||
              document.Extension == 'png' ||
              document.Extension == 'jpg' ||
              document.Extension == 'jpg' ||
              document.Extension == 'jpeg') &&
            safeLink &&
            (!document['URL.Complete'] || document['URL.Complete'] == '')
          "
          (click)="verifyDocument()"
          mat-raised-button
        >
          Verify <mat-icon>verified_user</mat-icon>
        </button>
        <button
          class="btn-small btn-dl pull-right"
          *ngIf="
            isVerify == true &&
            document &&
            (document.Extension == 'pdf' ||
              document.Extension == 'heic' ||
              document.Extension == 'png' ||
              document.Extension == 'jpg' ||
              document.Extension == 'jpeg') &&
            safeLink &&
            (!document['URL.Complete'] || document['URL.Complete'] == '')
          "
          (click)="rejectDocument()"
          mat-raised-button
          color="warn"
        >
          Reject <mat-icon>block</mat-icon>
        </button>

        <button
          class="btn-small btn-dl pull-right"
          *ngIf="
            isReplace == true &&
            document &&
            (document.Extension == 'pdf' ||
              document.Extension == 'heic' ||
              document.Extension == 'png' ||
              document.Extension == 'jpg' ||
              document.Extension == 'jpeg') &&
            safeLink &&
            (!document['URL.Complete'] || document['URL.Complete'] == '')
          "
          (click)="replaceDocument()"
          mat-raised-button
          color="warn"
        >
          Replace <mat-icon>loop</mat-icon>
        </button>
      </div>
    </div>

    <div class="row clearfix" *ngIf="document && displayReplaceSection == true">
      <div class="row clearfix text-center buttonrow">
        <button
          class="btn-small btn-dl"
          [disabled]="!replaceFileID"
          (click)="replaceDoc()"
          mat-raised-button
          color="primary"
        >
          Save
        </button>
      </div>
    </div>
  </mat-dialog-actions>
  <!-- end actions footer -->
</div>
