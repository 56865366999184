import { Component, EventEmitter, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { DrugsViewComponent } from '../drugs-view/drugs-view.component';
import { DrugsService } from '../shared/drugs.service';

@Component({
  selector: 'app-drug-lookup',
  templateUrl: './drug-lookup.component.html',
  styleUrls: ['./drug-lookup.component.css'],
})
export class DrugLookupComponent implements OnInit {
  drugs = [];
  sessionType;

  isClickImage = false;
  close = new EventEmitter();

  getDrug = new EventEmitter();
  filterData = '';
  selectedDrug: any;

  noButton = false;
  noLabel = 'Not in the list';
  getNo = new EventEmitter();

  constructor(
    private authenticationService: AuthenticationService,
    private drugsService: DrugsService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data && data.drugs && data.drugs.length > 0) {
        this.drugs = data.drugs;
      }
      if (data.noButton != null) {
        this.noButton = data.noButton;
      }
      if (data.noLabel) {
        this.noLabel = data.noLabel;
      }
    }
  }

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((sessionType) => {
      this.sessionType = sessionType;
    });
  }

  viewDrug(id) {
    const ref = RootAppComponent.dialog.open(DrugsViewComponent, {
      data: {
        drugID: id,
      },

      width: '800px',
    });

    ref.componentInstance.closeModal.subscribe((res) => {
      ref.close();
    });
  }

  selectDrug(p) {
    if (p && this.isClickImage != true) {
      this.getDrug.emit(p);
    }
  }

  clickImageEvent(e) {
    this.isClickImage = e;
  }

  closeEvent() {
    this.close.emit(true);
  }

  noButtonEvent() {
    this.getNo.emit(true);
  }
}
