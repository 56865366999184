import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger,
  MatChipInputEvent,
} from '@angular/material';
import * as _ from 'lodash';

import * as $ from 'jquery';
@Component({
  selector: 'app-tag-list',
  templateUrl: './tag-list.component.html',
  styleUrls: ['./tag-list.component.css'],
})
export class TagListComponent implements OnInit {
  @ViewChild('tagInput', { static: false }) tagInput: any;
  @ViewChild('auto', { static: false }) matAutocomplete: MatAutocomplete;
  @Input()
  title = 'Search by tags';
  @Input()
  tagSplit = '|';

  @Input('tags')
  defaulTags: any;

  @Input('selectedTags')
  defaulSelectedTags: any = [];

  @Input()
  openPosition = 'above';

  @Input()
  selectedTagFormatIsObject: any = false;
  @Input()
  tagFormatIsObject: any = false;

  @Input()
  tagFormatID: any = 'Label';

  @Input()
  tagFormatAttribute: any = 'Label';
  tags = [];

  selectedTags = [];

  @Input()
  tag;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  @Input()
  isFlex = false;

  @Input()
  canSelect = true;

  @Input()
  disabled = false;

  scrollPosition = 0;

  @Input()
  directRemoveDefault = false;

  @Input()
  directRemove = false;

  @Input()
  remove = new EventEmitter();
  @Input()
  add = new EventEmitter();
  @Input()
  canRemove = true;

  @Input()
  canCreate = true;

  @Input()
  hasFilter = true;

  @Output()
  getSelectedTags = new EventEmitter();
  @Output()
  getSelectedTag = new EventEmitter();
  @Output()
  getRemovedTag = new EventEmitter();
  @Output()
  getRemovedAddedTag = new EventEmitter();

  @Output()
  getCreate = new EventEmitter();

  @ViewChild('autoTrigger', { read: MatAutocompleteTrigger, static: false })
  autoTrigger: MatAutocompleteTrigger;

  constructor() {}

  ngOnInit() {
    this.setup();

    this.add.subscribe((r) => {
      if (r) {
        let target = r;
        if (typeof r === 'object' && r[this.tagFormatAttribute]) {
          target = r[this.tagFormatAttribute];
        }

        if (this.tagFormatIsObject == true) {
          if (typeof r === 'object') {
            this.tags.push(r);
          } else if (typeof r === 'string') {
            const p: any = {};

            p[this.tagFormatAttribute] = r;
            this.tags.push(p);
          }

          this.tags = _.uniqBy(this.tags, this.tagFormatAttribute);
        } else {
          if (typeof r === 'object' && r[this.tagFormatAttribute]) {
            this.tags.push(r[this.tagFormatAttribute]);
          } else if (typeof r === 'string') {
            this.tags.push(r);
          }

          this.tags = _.uniq(this.tags);
        }

        this.tags = JSON.parse(JSON.stringify(this.tags));

        this.tagChange(target);
      }
    });

    this.remove.subscribe((r) => {
      if (r) {
        const index = this.selectedTags.indexOf(r);
        if (index != -1) {
          this.selectedTags.splice(index, 1);
        }
        this.selectedTags = _.uniq(this.selectedTags);

        this.sendResult();
      }
    });
  }
  ngOnChanges(changes: SimpleChanges) {
    this.setup();
  }

  setup() {
    if (this.defaulTags && Array.isArray(this.defaulTags) && this.defaulTags.length > 0) {
      let _defaulTags = this.defaulTags;

      if (this.tagFormatIsObject == true) {
        _defaulTags = _.uniqBy(_defaulTags, this.tagFormatID);
      } else {
        _defaulTags = _.uniq(_defaulTags);
      }

      this.tags = JSON.parse(JSON.stringify(_defaulTags));
    } else if (this.defaulTags && typeof this.defaulTags === 'string') {
      let _defaulTags = this.defaulTags.split(this.tagSplit);

      if (this.tagFormatIsObject == true) {
        _defaulTags = _.uniqBy(_defaulTags, this.tagFormatID);
      } else {
        _defaulTags = _.uniq(_defaulTags);
      }

      this.tags = JSON.parse(JSON.stringify(_defaulTags));
    }

    if (this.defaulSelectedTags && Array.isArray(this.defaulSelectedTags) && this.defaulSelectedTags.length > 0) {
      this.selectedTags = JSON.parse(JSON.stringify(this.defaulSelectedTags));
    } else if (this.defaulSelectedTags && typeof this.defaulSelectedTags === 'string') {
      this.selectedTags = JSON.parse(JSON.stringify(this.defaulSelectedTags.split(this.tagSplit)));
    }
  }
  selectTag(t) {
    if (t) {
      this.getSelectedTag.emit(t);
    }
  }

  isDefaultTag(t) {
    if (t && this.defaulSelectedTags && this.defaulSelectedTags.length > 0) {
      let index = -1;
      if (this.selectedTagFormatIsObject === true) {
        index = this.defaulSelectedTags.findIndex((o) => {
          if (o && o[this.tagFormatAttribute] && o[this.tagFormatAttribute] == t) {
            return true;
          }
          return false;
        });
      } else {
        index = this.defaulSelectedTags.indexOf(t);
      }

      if (index !== -1) {
        return true;
      }
    }
    return false;
  }
  removeTag(t: string): void {
    if (this.selectedTagFormatIsObject === true && this.defaulSelectedTags) {
      const index = this.defaulSelectedTags.findIndex((o) => {
        if (o && o[this.tagFormatAttribute] && o[this.tagFormatAttribute] == t) {
          return true;
        }
        return false;
      });
      if (index !== -1) {
        this.getRemovedTag.emit(t);

        if (this.directRemove == true) {
          const index = this.selectedTags.indexOf(t);

          if (index >= 0) {
            this.selectedTags.splice(index, 1);
          }

          this.sendResult();
        }
      } else {
        this.getRemovedAddedTag.emit(t);

        if (this.directRemoveDefault == true) {
          const index = this.selectedTags.indexOf(t);

          if (index >= 0) {
            this.selectedTags.splice(index, 1);
          }

          this.sendResult();
        }
      }
    } else if (this.selectedTagFormatIsObject !== true && this.defaulSelectedTags) {
      const index = this.defaulSelectedTags.indexOf(t);

      if (index !== -1) {
        this.getRemovedTag.emit(t);

        if (this.directRemove == true) {
          const index = this.selectedTags.indexOf(t);

          if (index >= 0) {
            this.selectedTags.splice(index, 1);
          }

          this.sendResult();
        }
      } else {
        this.getRemovedAddedTag.emit(t);

        if (this.directRemoveDefault == true) {
          const index = this.selectedTags.indexOf(t);

          if (index >= 0) {
            this.selectedTags.splice(index, 1);
          }

          this.sendResult();
        }
      }
    } else {
      this.getRemovedAddedTag.emit(t);

      if (this.directRemoveDefault == true) {
        const index = this.selectedTags.indexOf(t);

        if (index >= 0) {
          this.selectedTags.splice(index, 1);
        }

        this.sendResult();
      }
    }
  }

  openPanelEvent(event, trigger) {
    if (event && trigger) {
      event.stopPropagation();
      trigger.openPanel();

      setTimeout(() => {
        $('.mat-autocomplete-panel').scrollTop(this.scrollPosition);
      }, 0);
    }
  }
  tagChange(t) {
    if (t) {
      const index = this.selectedTags.indexOf(t);

      if (index == -1 && typeof t == 'string') {
        this.selectedTags.push(t);
      }

      this.sendResult();
      this.tag = null;
    }
  }

  addTag(event: MatChipInputEvent): void {
    const input = event.input;
    let value = event.value;

    if (value) {
      value = value.trim();
      const index = this.selectedTags.indexOf(value);

      if (index == -1 && typeof value == 'string') {
        this.selectedTags.push(value);
      }
    }

    this.sendResult();
    // Reset the input value
    if (input) {
      input.value = '';
    }
    this.tag = null;
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.scrollPosition = $('.mat-autocomplete-panel').scrollTop();

    if (event && event.option && event.option.viewValue) {
      const v = event.option.viewValue;
      const index = this.selectedTags.indexOf(v);

      if (index == -1 && typeof v == 'string') {
        this.selectedTags.push(v);
      }

      this.sendResult();
      this.tagInput.nativeElement.value = '';
      this.tag = null;
    }
  }

  clone(a) {
    return JSON.parse(JSON.stringify(a));
  }
  createEvent() {
    this.getCreate.emit(true);
  }

  sendResult() {
    this.selectedTags = _.uniq(this.selectedTags);
    this.getSelectedTags.emit(this.selectedTags || []);
  }

  isTagSelected(t) {
    if (t && this.selectedTags.indexOf(t) != -1) {
      return true;
    }
    return false;
  }
}
