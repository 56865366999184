/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./color-palette.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "ngx-color-picker";
import * as i3 from "../../../../../node_modules/@angular/material/checkbox/typings/index.ngfactory";
import * as i4 from "@angular/material/checkbox";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "@angular/forms";
import * as i8 from "@angular/common";
import * as i9 from "./color-palette.component";
var styles_ColorPaletteComponent = [i0.styles];
var RenderType_ColorPaletteComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ColorPaletteComponent, data: {} });
export { RenderType_ColorPaletteComponent as RenderType_ColorPaletteComponent };
function View_ColorPaletteComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, "input", [["class", "color-input-core mr full-width pull-left"], ["placeholder", "#ffffff"]], [[8, "disabled", 0], [8, "value", 0], [4, "background", null]], [[null, "colorPickerChange"], [null, "cpSliderDragEnd"], [null, "cpInputChange"], [null, "change"], [null, "click"], [null, "focus"], [null, "input"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).handleClick() !== false);
        ad = (pd_0 && ad);
    } if (("focus" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).handleFocus() !== false);
        ad = (pd_1 && ad);
    } if (("input" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1).handleInput($event) !== false);
        ad = (pd_2 && ad);
    } if (("colorPickerChange" === en)) {
        var pd_3 = ((_co.coreColor = $event) !== false);
        ad = (pd_3 && ad);
    } if (("cpSliderDragEnd" === en)) {
        var pd_4 = (_co.changeColor() !== false);
        ad = (pd_4 && ad);
    } if (("cpInputChange" === en)) {
        var pd_5 = (_co.changeColor() !== false);
        ad = (pd_5 && ad);
    } if (("change" === en)) {
        var pd_6 = (_co.changeColor() !== false);
        ad = (pd_6 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i2.ColorPickerDirective, [i1.Injector, i1.ComponentFactoryResolver, i1.ApplicationRef, i1.ViewContainerRef, i1.ElementRef, i2.ColorPickerService], { colorPicker: [0, "colorPicker"], cpOutputFormat: [1, "cpOutputFormat"], cpPosition: [2, "cpPosition"] }, { cpInputChange: "cpInputChange", cpSliderDragEnd: "cpSliderDragEnd", colorPickerChange: "colorPickerChange" }), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.coreColor; var currVal_4 = _co.hex; var currVal_5 = _co.position; _ck(_v, 1, 0, currVal_3, currVal_4, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.displayCoreColor; var currVal_1 = _co.coreColor; var currVal_2 = _co.coreColor; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_ColorPaletteComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, "input", [["class", "color-input-core mr two-thrd-width pull-left"], ["placeholder", "#ffffff"]], [[8, "disabled", 0], [8, "value", 0], [4, "background", null]], [[null, "colorPickerChange"], [null, "change"], [null, "click"], [null, "focus"], [null, "input"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).handleClick() !== false);
        ad = (pd_0 && ad);
    } if (("focus" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).handleFocus() !== false);
        ad = (pd_1 && ad);
    } if (("input" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1).handleInput($event) !== false);
        ad = (pd_2 && ad);
    } if (("colorPickerChange" === en)) {
        var pd_3 = ((_co.coreColor = $event) !== false);
        ad = (pd_3 && ad);
    } if (("colorPickerChange" === en)) {
        var pd_4 = (_co.changeColor() !== false);
        ad = (pd_4 && ad);
    } if (("change" === en)) {
        var pd_5 = (_co.changeColor() !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i2.ColorPickerDirective, [i1.Injector, i1.ComponentFactoryResolver, i1.ApplicationRef, i1.ViewContainerRef, i1.ElementRef, i2.ColorPickerService], { colorPicker: [0, "colorPicker"], cpOutputFormat: [1, "cpOutputFormat"], cpPosition: [2, "cpPosition"] }, { colorPickerChange: "colorPickerChange" }), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.coreColor; var currVal_4 = _co.hex; var currVal_5 = _co.position; _ck(_v, 1, 0, currVal_3, currVal_4, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.displayCoreColor; var currVal_1 = _co.coreColor; var currVal_2 = _co.coreColor; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_ColorPaletteComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "pull-left palette-check-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "mat-checkbox", [["class", "mat-checkbox"], ["color", "primary"], ["name", "displayPalette"]], [[8, "id", 0], [1, "tabindex", 0], [2, "mat-checkbox-indeterminate", null], [2, "mat-checkbox-checked", null], [2, "mat-checkbox-disabled", null], [2, "mat-checkbox-label-before", null], [2, "_mat-animation-noopable", null], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ngModelChange" === en)) {
        var pd_0 = ((_co.displayPalette = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatCheckbox_0, i3.RenderType_MatCheckbox)), i1.ɵdid(2, 8568832, null, 0, i4.MatCheckbox, [i1.ElementRef, i1.ChangeDetectorRef, i5.FocusMonitor, i1.NgZone, [8, null], [2, i4.MAT_CHECKBOX_CLICK_ACTION], [2, i6.ANIMATION_MODULE_TYPE]], { color: [0, "color"], name: [1, "name"] }, null), i1.ɵprd(1024, null, i7.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.MatCheckbox]), i1.ɵdid(4, 671744, null, 0, i7.NgModel, [[8, null], [8, null], [8, null], [6, i7.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i7.NgControl, null, [i7.NgModel]), i1.ɵdid(6, 16384, null, 0, i7.NgControlStatus, [[4, i7.NgControl]], null, null), (_l()(), i1.ɵted(-1, 0, [" Show Palette"]))], function (_ck, _v) { var _co = _v.component; var currVal_14 = "primary"; var currVal_15 = "displayPalette"; _ck(_v, 2, 0, currVal_14, currVal_15); var currVal_16 = "displayPalette"; var currVal_17 = _co.displayPalette; _ck(_v, 4, 0, currVal_16, currVal_17); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).id; var currVal_1 = null; var currVal_2 = i1.ɵnov(_v, 2).indeterminate; var currVal_3 = i1.ɵnov(_v, 2).checked; var currVal_4 = i1.ɵnov(_v, 2).disabled; var currVal_5 = (i1.ɵnov(_v, 2).labelPosition == "before"); var currVal_6 = (i1.ɵnov(_v, 2)._animationMode === "NoopAnimations"); var currVal_7 = i1.ɵnov(_v, 6).ngClassUntouched; var currVal_8 = i1.ɵnov(_v, 6).ngClassTouched; var currVal_9 = i1.ɵnov(_v, 6).ngClassPristine; var currVal_10 = i1.ɵnov(_v, 6).ngClassDirty; var currVal_11 = i1.ɵnov(_v, 6).ngClassValid; var currVal_12 = i1.ɵnov(_v, 6).ngClassInvalid; var currVal_13 = i1.ɵnov(_v, 6).ngClassPending; _ck(_v, 1, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13]); }); }
function View_ColorPaletteComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "row full-width clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 16777216, null, null, 1, "input", [["class", "color-input two-thrd-width"]], [[8, "value", 0], [8, "disabled", 0], [4, "background", null]], [[null, "colorPickerChange"], [null, "click"], [null, "focus"], [null, "input"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).handleClick() !== false);
        ad = (pd_0 && ad);
    } if (("focus" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).handleFocus() !== false);
        ad = (pd_1 && ad);
    } if (("input" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2).handleInput($event) !== false);
        ad = (pd_2 && ad);
    } if (("colorPickerChange" === en)) {
        var pd_3 = ((_v.context.$implicit["hex"] = $event) !== false);
        ad = (pd_3 && ad);
    } if (("colorPickerChange" === en)) {
        var pd_4 = (_co.sendPalette() !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 671744, null, 0, i2.ColorPickerDirective, [i1.Injector, i1.ComponentFactoryResolver, i1.ApplicationRef, i1.ViewContainerRef, i1.ElementRef, i2.ColorPickerService], { colorPicker: [0, "colorPicker"], cpOutputFormat: [1, "cpOutputFormat"], cpPosition: [2, "cpPosition"] }, { colorPickerChange: "colorPickerChange" }), (_l()(), i1.ɵeld(3, 16777216, null, null, 1, "input", [["class", "contrastInput pull-left"]], [[8, "disabled", 0], [4, "background", null]], [[null, "colorPickerChange"], [null, "click"], [null, "focus"], [null, "input"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).handleClick() !== false);
        ad = (pd_0 && ad);
    } if (("focus" === en)) {
        var pd_1 = (i1.ɵnov(_v, 4).handleFocus() !== false);
        ad = (pd_1 && ad);
    } if (("input" === en)) {
        var pd_2 = (i1.ɵnov(_v, 4).handleInput($event) !== false);
        ad = (pd_2 && ad);
    } if (("colorPickerChange" === en)) {
        var pd_3 = ((_v.context.$implicit["hex"] = $event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 671744, null, 0, i2.ColorPickerDirective, [i1.Injector, i1.ComponentFactoryResolver, i1.ApplicationRef, i1.ViewContainerRef, i1.ElementRef, i2.ColorPickerService], { colorPicker: [0, "colorPicker"], cpOutputFormat: [1, "cpOutputFormat"], cpPosition: [2, "cpPosition"] }, { colorPickerChange: "colorPickerChange" }), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "colorName pull-left"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_3 = _v.context.$implicit["hex"]; var currVal_4 = _co.hex; var currVal_5 = _co.position; _ck(_v, 2, 0, currVal_3, currVal_4, currVal_5); var currVal_8 = _v.context.$implicit["hex"]; var currVal_9 = _co.hex; var currVal_10 = _co.position; _ck(_v, 4, 0, currVal_8, currVal_9, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit["hex"]; var currVal_1 = !_co.canEditPalette; var currVal_2 = _v.context.$implicit["hex"]; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_6 = "true"; var currVal_7 = _co.getContrast(_v.context.$implicit["darkContrast"]); _ck(_v, 3, 0, currVal_6, currVal_7); var currVal_11 = _v.context.$implicit["name"]; _ck(_v, 6, 0, currVal_11); }); }
function View_ColorPaletteComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "margin-btm"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ColorPaletteComponent_5)), i1.ɵdid(2, 278528, null, 0, i8.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.palette; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ColorPaletteComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["id", "color-palette"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "row flex clearfix full-width"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ColorPaletteComponent_1)), i1.ɵdid(3, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ColorPaletteComponent_2)), i1.ɵdid(5, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ColorPaletteComponent_3)), i1.ɵdid(7, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ColorPaletteComponent_4)), i1.ɵdid(9, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isPalette == false); _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.isPalette == true); _ck(_v, 5, 0, currVal_1); var currVal_2 = (_co.coreColor && (_co.isPalette == true)); _ck(_v, 7, 0, currVal_2); var currVal_3 = ((_co.coreColor && (_co.displayPalette == true)) && (_co.isPalette == true)); _ck(_v, 9, 0, currVal_3); }, null); }
export function View_ColorPaletteComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-color-palette", [], null, null, null, View_ColorPaletteComponent_0, RenderType_ColorPaletteComponent)), i1.ɵdid(1, 114688, null, 0, i9.ColorPaletteComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ColorPaletteComponentNgFactory = i1.ɵccf("app-color-palette", i9.ColorPaletteComponent, View_ColorPaletteComponent_Host_0, { displayPalette: "displayPalette", displayCoreColor: "displayCoreColor", isPalette: "isPalette", canEditPalette: "canEditPalette", position: "position", coreColor: "coreColor" }, { getSCSSPalette: "getSCSSPalette", getCoreColor: "getCoreColor", getPalette: "getPalette" }, []);
export { ColorPaletteComponentNgFactory as ColorPaletteComponentNgFactory };
