import { Location } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { LookupService } from '../../../shared/services/lookup.service';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { CustomerService } from '../shared/customer.service';

@Component({
  selector: 'app-customer-edit',
  templateUrl: './customer-edit.component.html',
  styleUrls: ['./customer-edit.component.css'],
})
export class CustomerEditComponent implements OnInit {
  @Input()
  customerID;

  @Input()
  canEdit;

  @Input()
  isConcumer = false;

  customer = {};

  @Output()
  close = new EventEmitter();

  isAdressValid = false;
  mySuburb;
  myState;
  myPostCode;
  myStreetNumber;
  myStreetName;
  myUnitNumber;
  myStreetType;
  myLatitude;
  myLongitude;
  myBirthdayDefault;
  myBirthday;
  respectMarketing = true;

  profileLink;
  isFile = false;

  validateMobile = false;
  validatePhone = false;
  validateEmail = false;
  phone;
  mobile;
  email;

  titles = [];
  CustomerService: any;
  isModal = false;
  sessionType = 'guest';

  constructor(
    private authenticationService: AuthenticationService,
    private location: Location,
    private customerService: CustomerService,
    private lookupService: LookupService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data && typeof data == 'string') {
      this.customerID = data;

      this.isModal = true;
    }
  }

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((sessionType) => {
      if (sessionType) {
        this.sessionType = sessionType;
      }

      this.lookupService.getLookup('SimpleLookup', 'Salutation').subscribe((res) => {
        this.titles = res;
      });

      this.customerService.getCustomerDetails(this.customerID, {}, this.sessionType).subscribe((res) => {
        if (res) {
          this.customer = res;
          this.getPicture();

          this.mySuburb = this.customer['addresses.Suburb'];
          this.myState = this.customer['addresses.State'];
          this.myPostCode = this.customer['addresses.Postcode'];
          this.myStreetNumber = this.customer['addresses.Street Nr'];
          this.myStreetName = this.customer['addresses.Street Name'];
          this.myUnitNumber = this.customer['addresses.Unit'];
          this.myStreetType = this.customer['addresses.Street Type'];
          this.myLatitude = this.customer['Address.Latitude'];
          this.myLongitude = this.customer['Address.Longitude'];
          this.myBirthdayDefault = moment(this.customer['DateOfBirth']).toDate();
        }
      });
    });
  }

  getPicture() {
    if (this.customerID) {
      this.getProfilePicture();
    }
  }

  onCompleteAll(fileID) {
    if (fileID && fileID[0] && this.customerID) {
      const payload = {
        fileID: fileID[0],
      };

      this.customerService.uploadProfilePic(this.customerID, payload).subscribe((res) => {
        if (res) {
          this.isFile = true;
          this.getProfilePicture();
        } else {
          this.isFile = false;
        }
      });
    }
  }

  cancel() {
    this.location.back(); // <-- go back to previous location on cancel
  }

  editDetail() {
    const payload = {
      firstName: this.customer['FirstName'],
      midleName: this.customer['MiddleName'],
      lastName: this.customer['Name'],
      email: this.email,
      mobile: this.mobile,
      phone: this.phone,
      title: this.customer['Salutation'],
      abn: this.customer['ABN'],
      acn: this.customer['ACN'],
      companyType: this.customer['CompanyType'],
      tradingAs: this.customer['TradingAs'],
      website: this.customer['URL'],
      birthday: this.myBirthday,
      streetName: this.myStreetName,
      streetNumber: this.myStreetNumber,
      streetType: this.myStreetType,
      unitNumber: this.myUnitNumber,
      latitude: this.myLatitude,
      longitude: this.myLongitude,
      suburb: this.mySuburb,
      state: this.myState,
      postCode: this.myPostCode,
      isReffered: this.respectMarketing,
    };
    this.customerService.editCustomer(this.customer['ID'], payload).subscribe((res) => {
      if (res && typeof res == 'object') {
        this.customer = res;

        NotifyAppComponent.displayToast('success', 'Success!', 'Details have been updated');
      }
    });
  }

  getAdress(adr) {
    if (adr) {
      this.mySuburb = adr.suburb;
      this.myState = adr.state;
      this.myPostCode = adr.postCode;
      this.myStreetNumber = adr.streetNumber;
      this.myStreetName = adr.streetName;
      this.myUnitNumber = adr.unitNumber;
      this.myStreetType = adr.streetType;
      this.myLatitude = adr.latitude;
      this.myLongitude = adr.longitude;
    }
  }

  isAdressValidEvent(v) {
    this.isAdressValid = v || false;
  }

  getMobile(m) {
    this.mobile = m;
  }

  getPhone(p) {
    this.phone = p;
  }

  getEmail(e) {
    this.email = e;
  }

  validateMobileEvent(v) {
    this.validateMobile = v;
  }

  validatePhoneEvent(v) {
    this.validatePhone = v;
  }

  validateEmailEvent(v) {
    this.validateEmail = v;
  }

  getDate(e) {
    this.myBirthday = e;
  }

  closeModal() {
    this.close.emit(true);
  }

  getProfilePicture() {
    this.profileLink = null;

    this.profileLink = this.customerService.getProfilePicStreamLink(this.customerID);
  }
}
