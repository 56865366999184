// Import necessary Angular modules and services
import { Directive, Input, ElementRef, Renderer2, HostListener, TemplateRef, ViewContainerRef, OnChanges } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

interface CustomToolTipOptions {
  customToolTipContent?: string | TemplateRef<any>;
  customShowDelay?: number;
  customHideDelay?: number;
  customReplacement?: string;
  customDisplay?: boolean;
  customIndicatorPosition?: string;
  customToolTipClass?: string;
}

@Directive({
  selector: '[appCustomTooltip]',
})
export class CustomTooltipDirective implements OnChanges {
  @Input('customToolTipContent') customToolTipContent: string | TemplateRef<any>;
  @Input('customShowDelay') customShowDelay: number = 0;
  @Input('customHideDelay') customHideDelay: number = 0;
  @Input('customReplacement') customReplacement: string = 'auto';
  @Input('customDisplay') customDisplay: boolean = true;
  @Input('customIndicatorPosition') customIndicatorPosition: string = 'auto';
  @Input('customToolTipClass') customToolTipClass: string;

  @Input('customToolTipOptions') customToolTipOptions: CustomToolTipOptions = {};

  private tooltip: HTMLElement;

  private indicator: HTMLElement;

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private sanitizer: DomSanitizer,
    private viewContainerRef: ViewContainerRef
  ) {}


  ngOnChanges(): void {

  }

  @HostListener('mouseenter') onMouseEnter(): void {
    this.showTooltip();
  }

  @HostListener('mouseleave') onMouseLeave(): void {
    this.hideTooltip();
  }
  private showTooltip(): void {
    if (this.customDisplay !== false && this.customToolTipOptions.customDisplay !== false) {
      if (this.customShowDelay === 0 || !this.customShowDelay) {
        this.createTooltip();
        this.updateTooltipContent();
        this.positionTooltip();
        this.renderer.setStyle(this.tooltip, 'display', 'block');
      } else {
        setTimeout(() => {
          this.createTooltip();
          this.updateTooltipContent();
          this.positionTooltip();
          this.renderer.setStyle(this.tooltip, 'display', 'block');
        }, this.customShowDelay);
      }
    }
  }

  private hideTooltip(): void {
    if (this.tooltip) {
      if (this.customHideDelay === 0 || !this.customHideDelay) {

        this.renderer.setStyle(this.tooltip, 'display', 'none');
      }
      else {

        setTimeout(() => {
          this.renderer.setStyle(this.tooltip, 'display', 'none');
        }, this.customHideDelay);
      }
    }
  }

  private createTooltip(): void {
    if (!this.tooltip) {
      this.tooltip = this.renderer.createElement('div');
      this.renderer.addClass(this.tooltip, 'customized-tooltip-class');

      if (this.customToolTipClass || this.customToolTipOptions.customToolTipClass) {
        const tooltipClass = this.customToolTipClass || this.customToolTipOptions.customToolTipClass;
        this.renderer.addClass(this.tooltip, tooltipClass);
      }

      this.indicator = this.renderer.createElement('div');
      this.renderer.addClass(this.indicator, 'tooltip-indicator');

      this.renderer.appendChild(this.tooltip, this.indicator);
      this.renderer.appendChild(document.body, this.tooltip);

      this.renderer.setStyle(this.tooltip, 'position', 'absolute');
      this.renderer.setStyle(this.tooltip, 'display', 'none');
    }
  }

  private updateTooltipContent(): void {
    if (this.tooltip) {
      this.tooltip.innerHTML = '';
      let _content: any;

      if (this.customToolTipOptions && this.customToolTipOptions.customToolTipContent) {
        _content = this.customToolTipOptions.customToolTipContent;
      }

      if (this.customToolTipContent) {
        _content = this.customToolTipContent;
      }

      if (this.customToolTipContent instanceof TemplateRef) {
        const view = this.viewContainerRef.createEmbeddedView(_content);
        view.detectChanges();
        const content = view.rootNodes.map((node) => this.getOuterHTML(node)).join('');
        this.tooltip.innerHTML = content;
      } else {
        this.tooltip.innerHTML = _content;
      }
    }
  }

  private getOuterHTML(node: Node): string {
    const container = document.createElement('div');
    container.appendChild(node.cloneNode(true));
    return container.innerHTML;
  }
  private positionTooltip(): void {
    const hostRect = this.elementRef.nativeElement.getBoundingClientRect();
    const tooltipRect = this.tooltip.getBoundingClientRect();

    let top, left;

    switch (this.customReplacement) {
      case 'top':
        top = hostRect.top - tooltipRect.height;
        left = hostRect.left + (hostRect.width - tooltipRect.width) / 2;
        break;
      case 'bottom':
        top = hostRect.bottom;
        left = hostRect.left + (hostRect.width - tooltipRect.width) / 2;
        break;
      case 'left':
        top = hostRect.top + (hostRect.height - tooltipRect.height) / 2;
        left = hostRect.left - tooltipRect.width;
        break;
      case 'right':
        top = hostRect.top + (hostRect.height - tooltipRect.height) / 2;
        left = hostRect.right;
        break;
      default:
        // Auto positioning (prefer bottom)
        if (hostRect.bottom + tooltipRect.height <= window.innerHeight) {
          // Display below
          top = hostRect.bottom;
          left = hostRect.left + (hostRect.width - tooltipRect.width) / 2;
        } else {
          // Display above
          top = hostRect.top - tooltipRect.height;
          left = hostRect.left + (hostRect.width - tooltipRect.width) / 2;
        }
        break;
    }

    this.renderer.setStyle(this.tooltip, 'top', `${top + window.scrollY}px`);
    this.renderer.setStyle(this.tooltip, 'left', `${left + window.scrollX}px`);
    this.positionIndicator(hostRect, tooltipRect);

    // Indicator position (centered vertically or horizontally)
    const customReplacement = this.customReplacement || this.customToolTipOptions.customReplacement;
    if (customReplacement === 'auto') {
      if (['top', 'bottom'].includes(this.customReplacement || this.customToolTipOptions.customReplacement)) {
        this.renderer.setStyle(this.tooltip, 'transform', 'translateX(-50%)');
      } else {
        this.renderer.setStyle(this.tooltip, 'transform', 'translateY(-50%)');
      }
    } else {
      this.renderer.addClass(this.tooltip, `indicator-${customReplacement}`);
    }
  }

  private positionIndicator(hostRect: ClientRect, tooltipRect: ClientRect): void {
    const customReplacement = this.customReplacement || this.customToolTipOptions.customReplacement;
    const indicatorHeight = 10; // Adjust this value based on your design
    const indicatorWidth = 20; // Adjust this value based on your design

    const addIndicatorClass = (className: string) => {
      this.renderer.addClass(this.indicator, className);
    };

    this.renderer.addClass(this.indicator, 'custom-tooltip-indicator');

    switch (customReplacement) {
      case 'right':
        addIndicatorClass('indicator-left');
        this.renderer.setStyle(this.indicator, 'top', `50%`);
        this.renderer.setStyle(this.indicator, 'left', `-${indicatorWidth}px`);
        break;
      case 'left':
        addIndicatorClass('indicator-right');
        this.renderer.setStyle(this.indicator, 'top', `50%`);
        this.renderer.setStyle(this.indicator, 'right', `-${indicatorWidth}px`);
        break;
      case 'top':
        addIndicatorClass('indicator-bottom');
        this.renderer.setStyle(this.indicator, 'bottom', `-${indicatorHeight}px`);
        this.renderer.setStyle(this.indicator, 'left', `50%`);
        break;
      case 'bottom':
        addIndicatorClass('indicator-top');
        this.renderer.setStyle(this.indicator, 'top', `-${indicatorHeight}px`);
        this.renderer.setStyle(this.indicator, 'left', `50%`);
        break;

      default:
        // Add more cases based on your needs
        break;
    }
  }
}
