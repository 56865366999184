import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Inject, Input, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { ClipboardService } from 'ngx-clipboard';
import { HtmlSimpleLinkInputComponent } from '../../../shared/components/html-simple-link-input/html-simple-link-input.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';

@Component({
  selector: 'app-editor-component-document-view',
  templateUrl: './editor-component-document-view.component.html',
  styleUrls: ['./editor-component-document-view.component.css'],
})
export class EditorComponentDocumentViewComponent implements OnInit {
  @Input()
  documentID;

  @Input()
  title;

  @Input()
  extension;
  @Input()
  description;
  close = new EventEmitter();
  document;
  getResult = new EventEmitter();

  utils = new UtilsClass();

  constructor(
    private http: HttpClient,
    private _clipboardService: ClipboardService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.extension) {
        this.extension = data.extension;
      }
      if (data.documentID) {
        this.documentID = data.documentID;
      }
      if (data.document) {
        this.document = data.document;
      }

      if (data.title) {
        this.title = data.title;
      }

      if (data.description) {
        this.description = data.description;
      }
    }
  }

  ngOnInit() {}

  closeEvent() {
    this.close.emit(true);
  }

  preview(d) {
    const link = this.getDocumentLink(d);
    if (link) {
      window.open(link, '_blank');
    }
  }

  copyLink(d) {
    const link = this.getDocumentLink(d);
    if (link) {
      this._clipboardService.copy(link);

      NotifyAppComponent.displayToast('success', 'success', 'Copied');
    }
  }

  copy(d) {
    if (d) {
      this._clipboardService.copy(d);

      NotifyAppComponent.displayToast('success', 'success', 'Copied');
    }
  }

  getDocumentLink(d) {
    if (d) {
      return `${Settings.global['AWSS3Prefix']}/${d.AWS_Bucket_Name}${d.AWS_URL}`;
    }

    return null;
  }

  getTypeLabel(d) {
    if (d) {
      if (d === 'EDTRBKG') {
        return 'Background';
      } else if (d === 'EDTRCHR') {
        return 'Character';
      } else if (d === 'EDTRDOC') {
        return 'Document';
      } else if (d === 'EDTRIMG') {
        return 'Image';
      } else if (d === 'EDTRJVS') {
        return 'Javascript';
      } else if (d === 'EDTRSTL') {
        return 'Style';
      }
    }

    return null;
  }




  build(document, isEmbeded = false, isCopy = false) {
    let content = null;
    let style = null;
    let headCSS = null;
    const headJS = null;
    let _isCopy = false;
    let script = null;

    if (document && document.ExtensionOrMimeType) {
      const link = this.getDocumentLink(document);
      const title = `${document.Label} ${this.getTypeLabel(document.DocumentType_Code)}`;

      if (link) {
        const nature = this.utils.getDocumentTypeFromExtension(document.ExtensionOrMimeType);

        if (nature === 'image') {
          const ref2 = RootAppComponent.dialog.open(HtmlSimpleLinkInputComponent, {
            data: {
              title,
              isMerchant: false,
              link,
              linkTitle: title,
              type: 'image',
            },
            width: '600px',
          });
          ref2.componentInstance.getResult.subscribe((c) => {
            if (c) {
              content = c.content || null;
              style = c.style || null;
              _isCopy = c.isCopy || false;

              ref2.close();

              ref2.afterClosed().subscribe((d) => {
                const result = {
                  content,
                  style,
                  headCSS,
                  headJS,
                  isCopy: _isCopy,
                  isReplace: false,
                };

                this.sendResultEvent(result);
              });
            } else {
              ref2.close();
            }
          });

          ref2.componentInstance.close.subscribe((s) => {
            ref2.close();
          });
          ref2.backdropClick().subscribe((s) => {
            ref2.close();
          });
        } else if (String(document.ExtensionOrMimeType).toLocaleLowerCase() === 'css' && isEmbeded != true) {
          headCSS = `<!--START: ${title}-->\n  <link rel="stylesheet" href="${link}">\n<!--END: ${title}-->`;

          const result = {
            content,
            style,
            headCSS,
            headJS,
            isCopy,
            isReplace: false,
          };

          this.sendResultEvent(result);
        } else if (String(document.ExtensionOrMimeType).toLocaleLowerCase() === 'css' && isEmbeded == true) {
          this.http.get(link, { responseType: 'text' as 'json' }).subscribe((res) => {
            style = `/* START: ${title} */\n ${res} \n/* END: ${title} */`;
            const result = {
              content,
              style,
              headCSS,
              headJS,
              isCopy,
              isReplace: false,
            };
            this.sendResultEvent(result);
          });
        } else if (String(document.ExtensionOrMimeType).toLocaleLowerCase() === 'js') {
          this.http.get(link, { responseType: 'text' as 'json' }).subscribe((res) => {
            script = `<!--START: ${title}-->\n<script>\n${res}\n</script>\n<!--END: ${title}-->`;
            const result = {
              content,
              style,
              headCSS,
              headJS,
              isCopy,
              script,
              isReplace: false,
            };
            this.sendResultEvent(result);
          });
        } else if (String(document.ExtensionOrMimeType).toLocaleLowerCase() === 'html' && isEmbeded == true) {
          const ref2 = RootAppComponent.dialog.open(HtmlSimpleLinkInputComponent, {
            data: {
              title,
              extensionType: 'html',
              isMerchant: false,
              link,
              linkTitle: title,
              type: 'iframe',
            },
            width: '600px',
          });
          ref2.componentInstance.getResult.subscribe((c) => {
            if (c) {
              content = c.content || null;
              style = c.style || null;
              _isCopy = c.isCopy || false;

              ref2.close();

              ref2.afterClosed().subscribe((d) => {
                const result = {
                  content,
                  style,
                  headCSS,
                  headJS,
                  isCopy: _isCopy,
                  isReplace: false,
                };

                this.sendResultEvent(result);
              });
            } else {
              ref2.close();
            }
          });

          ref2.componentInstance.close.subscribe((s) => {
            ref2.close();
          });
          ref2.backdropClick().subscribe((s) => {
            ref2.close();
          });
        } else if (String(document.ExtensionOrMimeType).toLocaleLowerCase() === 'pdf' && isEmbeded == true) {
          const ref2 = RootAppComponent.dialog.open(HtmlSimpleLinkInputComponent, {
            data: {
              title,
              extensionType: 'pdf',
              isMerchant: false,
              link,
              linkTitle: title,
              type: 'iframe',
            },
            width: '600px',
          });
          ref2.componentInstance.getResult.subscribe((c) => {
            if (c) {
              content = c.content || null;
              style = c.style || null;

              _isCopy = c.isCopy || false;

              ref2.close();

              ref2.afterClosed().subscribe((d) => {
                const result = {
                  content,
                  style,
                  headCSS,
                  headJS,
                  isCopy: _isCopy,
                  isReplace: false,
                };

                this.sendResultEvent(result);
              });
            } else {
              ref2.close();
            }
          });

          ref2.componentInstance.close.subscribe((s) => {
            ref2.close();
          });
          ref2.backdropClick().subscribe((s) => {
            ref2.close();
          });
        } else {
          const ref2 = RootAppComponent.dialog.open(HtmlSimpleLinkInputComponent, {
            data: {
              title,
              isMerchant: false,
              link,
              linkTitle: title,
              type: 'link',
            },
            width: '600px',
          });
          ref2.componentInstance.getResult.subscribe((c) => {
            if (c) {
              content = c.content || null;
              style = c.style || null;

              _isCopy = c.isCopy || false;

              ref2.close();

              ref2.afterClosed().subscribe((d) => {
                const result = {
                  content,
                  style,
                  headCSS,
                  headJS,
                  isCopy,
                  isReplace: false,
                };

                this.sendResultEvent(result);
              });
            } else {
              ref2.close();
            }
          });

          ref2.componentInstance.close.subscribe((s) => {
            ref2.close();
          });
          ref2.backdropClick().subscribe((s) => {
            ref2.close();
          });
        }
      }
    }
  }

  copyContent(document, isEmbeded = false) {
    this.build(document, isEmbeded, true);
  }

  insertContent(document, isEmbeded = false) {
    this.build(document, isEmbeded, false);
  }

  insertCopyContent(document, isEmbeded = false) {
    this.build(document, isEmbeded);
  }

  sendResultEvent(payload) {
    if (payload) {
      if (payload.isCopy === true) {
        if (payload.headCSS) {
          this.copy(payload.headCSS);
        } else if (payload.headJS) {
          this.copy(payload.headJS);
        } else if (payload.content) {
          this.copy(payload.content);
        }

        this.getResult.emit(payload);
      } else {
        this.getResult.emit(payload);
      }
    }
  }

  isEmbedOption(d) {
    if (
      d &&
      d &&
      (String(d.ExtensionOrMimeType).toLocaleLowerCase() === 'css' ||
        String(d.ExtensionOrMimeType).toLocaleLowerCase() === 'html' ||
        String(d.ExtensionOrMimeType).toLocaleLowerCase() === 'pdf')
    ) {
      return true;
    }

    return false;
  }

  isHeader(d) {
    if (
      d &&
      d &&
      (String(d.ExtensionOrMimeType).toLocaleLowerCase() === 'js' ||
        String(d.ExtensionOrMimeType).toLocaleLowerCase() === 'css')
    ) {
      return true;
    }

    return false;
  }
}
