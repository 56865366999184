import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../../../../../../../src/environments/environment';
import { Beneficiary, BeneficiaryService } from '../../../../../../beneficiary/shared/beneficiary.service';
import { ProductCatalogueItem } from '../../../../../shared/services/product-catalogue.service';

@Component({
  selector: 'ipv-retail-view-product',
  templateUrl: './retail-view-product.component.html',
  styleUrls: ['./retail-view-product.component.css'],
})
export class RetailViewProductComponent implements OnInit {
  @Input() product: ProductCatalogueItem;

  existingThumbnailSrc = '';
  additionalImageIDs: string[] = [];
  additionalImages: string[] = [];

  beneficiary: Beneficiary;
  productType = '';
  productCategory = '';

  constructor(private beneficiaryService: BeneficiaryService) {}

  ngOnInit() {
    this.existingThumbnailSrc = `${environment.nodeUrl}/files/file-view/${this.product.Thumbnail_Doc_key}`;
    this.additionalImages =
      this.product.AdditionalImages_Doc_key.length > 0 ? this.product.AdditionalImages_Doc_key.split('|') : [];

    this.additionalImageIDs.forEach((imageID) => {
      this.additionalImages = [...this.additionalImages, `${environment.nodeUrl}/files/file-view/${imageID}`];
    });

    this.beneficiaryService
      .fetchBeneficiary$<Beneficiary>({ supplierID: this.product.Supplier_key })
      .subscribe((beneficiary) => {
        this.beneficiary = beneficiary;
      });
  }
}
