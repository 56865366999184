import { Location } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params } from '@angular/router';
import { combineLatest, forkJoin } from 'rxjs';
import { mergeMap, switchMap, tap } from 'rxjs/operators';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { LookupService } from '../../../shared/services/lookup.service';
import { DataResponse } from '../../../shared/types/data-response.type';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { SessionType } from '../../../shared/types/session-type.type';
import { User } from '../../../shared/types/user';
import { DentistService } from '../../dentist/shared/dentist.service';
import { Dentist } from '../../dentist/shared/dentist.type';
import { AddContactDentistryTypesProfileDialogComponent } from '../../merchant/dialogs/add-contact-dentistry-types-profile-dialog/add-contact-dentistry-types-profile-dialog.component';
import { Settings } from '../../../shared/types/settings';

@Component({
  selector: 'app-dentist-profile',
  templateUrl: './dentist-profile.component.html',
  styleUrls: ['./dentist-profile.component.css'],
})
export class DentistProfileComponent {
  @Input()
  dentistID;

  @Input()
  hideSide = false;

  navMode: 'over' | 'side' | 'push' = 'side';
  settings = 'profile';

  isPromoterOrAdmin = false;
  isSideMenuOpen = true;
  disableEditPractice = true;

  context = Settings.global['context'];
  params: Params;
  currentUserResponse: DataResponse<User>;
  sessionType: SessionType;

  dentist$ = combineLatest([
    this.activatedRouter.params,
    this.authenticationService.getSessionType(),
    this.authenticationService.isPromoterOrAdmin(),
    this.authenticationService.getCurrentUser(),
  ]).pipe(
    mergeMap(([params, sessionType, isPromoterOrAdmin, currentUserResponse]) => {
      this.sessionType = sessionType;
      this.isPromoterOrAdmin = isPromoterOrAdmin;
      this.currentUserResponse = currentUserResponse;
      this.dentistID = params['dentistID'] || this.dentistID;

      if (this.currentUserResponse && this.currentUserResponse.success) {
        const role = this.currentUserResponse.data.Role;
        this.disableEditPractice = !(role === 'admin' || role === 'merchant-admin');

        this.currentUser = this.currentUserResponse.data;
      }

      return combineLatest([this.initializeDentistryTypesList$(), this.initializeDentist$()]);
    }),
    switchMap(() => this.dentistService.getDentist$())
  );

  currentUser: User;
  profileLink;
  profileDescription;
  birthday;
  marketingConsent;

  titles;
  genders;
  preferredContactTimeOptions;
  preferredContactMethodOptions;
  preferredMessageTypeOptions;

  contactTypeCodes = [];

  contactProfileDentistryTypes = [];

  constructor(
    private activatedRouter: ActivatedRoute,
    private dentistService: DentistService,
    private authenticationService: AuthenticationService,
    public location: Location,
    private lookupService: LookupService,
    private dialog: MatDialog
  ) {}

  initializeDentistryTypesList$() {
    return this.dentistService
      .getContactProfileDentistryTypesList({
        Contact_key: this.dentistID,
      })
      .pipe(
        tap((data) => {
          this.contactProfileDentistryTypes = data.sort((a, b) => (a.Label > b.Label ? 1 : -1));
        })
      );
  }

  initializeDentist$() {
    return this.dentistService.fetchDentist$(this.dentistID, this.sessionType, {}).pipe(
      tap((dentist) => {
        if (dentist) {
          this.dentistService.setDentist(dentist);
          this.profileDescription = dentist.Description;
          this.marketingConsent = dentist['MarketingConsent.Given'] === '1';

          this.setPicture(dentist);
          this.setupLookup();
        }
      })
    );
  }

  setBirthday(date) {
    this.birthday = date;
  }

  setPicture(dentist: Dentist) {
    this.profileLink = null;

    if (dentist.ID) {
      this.profileLink = this.dentistService.getProfilePicStreamLink(dentist.ID);
    }
  }

  setProfileContent(description) {
    this.profileDescription = description;
  }

  onCompleteAllPractice(fileID, dentist: Dentist) {
    if (fileID && fileID[0]) {
      const payload = { fileID: fileID[0] };
      this.profileLink = null;

      this.dentistService.uploadProfilePic(dentist['ID'], payload).subscribe(() => {
        this.setPicture(dentist);
      });
    }
  }

  setupLookup() {
    combineLatest([
      this.lookupService.getLookup('SimpleLookup', 'Salutation'),
      this.lookupService.getLookup('CodeLookup', 'Gender'),
      this.lookupService.getLookup('CodeLookup', 'ContactTime'),
      this.lookupService.getLookup('CodeLookup', 'ContactMethod'),
      this.lookupService.getLookup('CodeLookup', 'MessageType'),
      this.lookupService.getLookup('CodeLookup', 'ContactType'),
    ]).subscribe(
      ([
        titles,
        genders,
        preferredContactTimeOptions,
        preferredContactMethodOptions,
        preferredMessageTypeOptions,
        contactTypeCodes,
      ]) => {
        this.titles = titles;
        this.genders = genders;
        this.preferredContactTimeOptions = preferredContactTimeOptions;
        this.preferredContactMethodOptions = preferredContactMethodOptions;
        this.preferredMessageTypeOptions = preferredMessageTypeOptions;
        this.contactTypeCodes = contactTypeCodes;
      }
    );
  }

  editPractice(dentist: Dentist) {
    const payload = {
      firstName: dentist['FirstName'],
      midleName: dentist['MiddleName'] || 'void',
      lastName: dentist['Name'],
      gender: dentist['Gender.Code'],
      title: dentist['Salutation'],
      birthday: this.birthday || 'void',
      contactTypeCode: dentist['ContactType.Code'],
      marketingConsent: this.marketingConsent,
      preferredContactMethod: dentist['PreferredContactMethod.Code'],
      preferredContactTime: dentist['PreferredContactTime.Code'],
      preferredMessageType: dentist['PreferredMessageType.Code'],
      facebook: dentist['Facebook'] || 'void',
      instagram: dentist['Instagram'] || 'void',
      twitter: dentist['Twitter'] || 'void',
      linkedin: dentist['LinkedIn'] || 'void',

      pinterest: dentist['Pinterest'] || 'void',
      tumblr: dentist['Tumblr'] || 'void',
      vimeo: dentist['Vimeo'] || 'void',
      url: dentist['URL'] || 'void',

      description: this.profileDescription || 'void',
    };

    this.dentistService.updateDentist$(dentist.ID, payload, this.sessionType).subscribe(() => {
      NotifyAppComponent.displayToast('success', 'Update Practice Profile', `You have successfully updated profile.`);
    });
  }

  openAddContactDentistryTypesProperty(dentist: Dentist) {
    const addContactDentistryTypesProfileDialogRef = RootAppComponent.dialog.open(
      AddContactDentistryTypesProfileDialogComponent,
      {
        data: { dentistID: dentist.ID },
        width: '500px',
      }
    );

    addContactDentistryTypesProfileDialogRef.componentInstance.addContactDentistryTypesProfile.subscribe((changes) => {
      const dentistryTypesObservables = [];

      changes.newDentistryTypes.forEach((newDentistryType) => {
        dentistryTypesObservables.push(this.dentistService.createContactProfileDentistryType(newDentistryType));
      });

      changes.removedDentistryTypes.forEach((removedDentistryType) => {
        dentistryTypesObservables.push(this.dentistService.deleteContactProfileDentistryType(removedDentistryType));
      });

      forkJoin(dentistryTypesObservables).subscribe(() => {
        NotifyAppComponent.displayToast('success', 'Successful operation', 'Dentistry Type successfully updated.');

        addContactDentistryTypesProfileDialogRef.close();

        this.dentistService
          .getContactProfileDentistryTypesList({
            Contact_key: dentist.ID,
          })
          .subscribe((data) => {
            this.contactProfileDentistryTypes = data.sort((a, b) => (a.Label > b.Label ? 1 : -1));
          });
      });
    });
  }
}
