import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { WizardComponent } from 'angular-archwizard';

import { Settings } from '../../../shared/types/settings';

import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Location } from '@angular/common';
import { FormControl } from '@angular/forms';
import { LookupService } from '../../../shared/services/lookup.service';

@Component({
  selector: 'app-patient-record-details',
  templateUrl: './patient-record-details.component.html',
  styleUrls: ['./patient-record-details.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('wizard-step', style({ opacity: 0 }), { optional: true }),
        query(
          'wizard-step',
          stagger('100ms', [
            animate(
              '0.4s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-20%)', offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('wizard-step', style({ opacity: 1 }), { optional: true }),
        query(
          'wizard-step',
          stagger('100ms', [
            animate(
              '0.4s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-20%)', offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class PatientRecordDetailsComponent implements OnInit {
  @ViewChild(WizardComponent, { static: true }) public wizard: WizardComponent;

  @Output()
  agreement: EventEmitter<any> = new EventEmitter();
  isAgree = false;
  isAgreed = false;

  selectedTab = 0;
  choice;
  step = 0;

  symptom = new FormControl();
  allergy = new FormControl();
  dental = new FormControl();

  symptomList = [
    'Anaemia',
    'Arthiritis',
    'Artificial Heart Valve',
    'Artificial Joints (Hip, Knee etc.)',
    'Asthma',
    'Blood Disorders',
    'Cancer',
    'Diabetes',
    'Dizziness/Fainting',
    'Epilepsy',
    'Excessive Bleeding',
    'Glaucoma',
    'Heart Disease',
    'Heart Murmur',
    'Hepatitis A, B, C',
    'High/Low Blood Pressure',
    'HIV/AIDS',
    'Kidney Disease',
    'Liver Disease',
    'Osteoporosis',
    'Pacemaker',
    'Psychological Disorders',
    'Radiation Therapy',
    'Respiratory Problems',
    'Rheumatic Fever',
    'Sinus Problems',
    'Stroke',
    'Transplanted Organ',
    'Tuberculosis',
    'Tumours',
  ];

  allergyList = [
    'Animals',
    'Antibiotics',
    'Aspirin',
    'Barbiturates',
    'Codeine',
    'Food',
    'Hay Fever',
    'Iodine',
    'Latex (Rubber)',
    'Local Anaesthetic',
    'Metals',
    'Penicillin',
    'Sedatives',
    'Sleeping Pills',
    'Sulfa-Drugs',
    'Other (Please activity-log.)',
  ];

  dentalList = [
    'Bad Breath',
    'Bleeding Gums',
    'Braces',
    'Dentures (Complete/Partial)',
    'Dry Mouth',
    'Earaches',
    'Food or Floss Catching Between Teeth',
    'Injury (Head/Mouth)',
    'Jaw Clicking/Popping',
    'Mouth Sores',
    'Mouth Ulcers',
    'Neck Pain',
    'Orthodontic Treatment',
    'Pain and Discomfort',
    'Periodontal Disease',
    'Poor Previous Dental Treatment',
    'Teeth Grinding',
    'Tooth Sensitivity',
    'Other (Please activity-log.)',
  ];

  healthInsurance;
  medicare;
  gP;
  sameContact;
  sameAddressGuardian;
  dependant;
  samePostalAddress;
  isPromoterOrAdmin = false;
  isMobileValid = false;
  isHomePhoneValid = false;
  isWorkPhoneValid = false;
  isEmailValid = false;
  refreshAmount = new EventEmitter();
  genders = [];
  titles = [];
  align;

  validateMobile;
  validateWorkPhone;
  consolePhone;
  validateEmail;
  validateHomePhone;
  prefContactMethods;
  contactTimes;
  occupations;
  relationStatuss;

  context = Settings.global['context'];
  insurers: string[] = [
    'ahm Health Insurance',
    'Allianz',
    'AIG',
    'Australian Unity',
    'Budget Direct',
    'Bupa Health Insurance',
    'CBHS Health Fund',
    'CUA Healthai',
    'Doctors Health Fund',
    'Frank Health Insurance',
    'GMF',
    'GMHBA',
    'HBF',
    'HCF',
    'HIF',
    'nib',
    'Peoplecare',
    'Qantas',
  ];
  durations: String[] = ['Less than 1 month', '1-2 months', '3-6 months', '6-12 months', '1 year+'];
  cardTypes: String[] = ['Australian Resident Card (Green Card)', 'RHCA (Yellow Card)', 'Interim (Blue Card)'];

  patient = {
    firstname: '',
    title: '',
    lastname: '',
    middlename: '',
    gender: '',
    dob: '',
    mobile: '',
    home_phone: '',
    work_phone: '',
    email: '',
    contactMethod: '',
    contactTime: '',
    unitNumber: '',
    streetNumber: '',
    streetName: '',
    streetType: '',
    suburb: '',
    state: '',
    postcode: '',
    postalUnitNumber: '',
    postalStreetNumber: '',
    postalStreetName: '',
    postalStreetType: '',
    postalSuburb: '',
    postalState: '',
    postalPostcode: '',
    occupation: '',
  };
  emergency = {
    firstname: '',
    lastname: '',
    mobile: '',
    home_phone: '',
    relationship: '',
  };

  guardian = {
    firstname: '',
    title: '',
    lastname: '',
    middlename: '',
    gender: '',
    dob: '',
    mobile: '',
    home_phone: '',
    email: '',
    unitNumber: '',
    streetNumber: '',
    streetName: '',
    streetType: '',
    suburb: '',
    state: '',
    postcode: '',
    relationship: '',
  };

  gp = {
    firstname: '',
    lastname: '',
    phone: '',
    email: '',
    unitNumber: '',
    streetNumber: '',
    streetName: '',
    streetType: '',
    suburb: '',
    state: '',
    postcode: '',
  };

  questions = {
    injuries: '',
    allergies: '',
    comment1: '',
    dentalInjuries: '',
    dentalLast: '',
    dentalFreq: '',
    comment2: '',
  };

  health = {
    insuranceName: '',
    insuranceNumber: '',
    medicardType: '',
    medicardNumber: '',
  };

  // Tab control for document presentation
  selectedIndex = 0;

  constructor(private router: Router, private lookupService: LookupService, private location: Location) {}

  ngOnInit() {
    this.lookupService.getLookup('CodeLookup', 'Gender').subscribe((data) => {
      this.genders = data;
    });
    this.lookupService.getLookup('SimpleLookup', 'Salutation').subscribe((data) => {
      this.titles = data;
    });
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  onStepChange() {
    const step = this.wizard.currentStepIndex;
    // this.invitationService.setProgress(this.invitationID, step).subscribe(() => { });
  }

  cancel() {
    this.location.back(); // <-- go back to previous location on cancel
  }

  scrollTop() {
    window.scrollTo(0, 0);
  }

  selectedIndexChange(val: number) {
    window.scrollTo(0, 0);
    this.selectedIndex = val;
  }

  displayNavBar() {
    const myProgressBar = document.querySelector('.large-filled-symbols');
    myProgressBar.classList.add('commenced');
  }

  hideNavBar() {
    const myProgressBar = document.querySelector('.large-filled-symbols');
    myProgressBar.classList.remove('commenced');
  }

  finishFunction() {
    this.router.navigate(['/merchant']);
  }

  changeAgreement() {
    this.agreement.emit(this.isAgree);
  }

  changeAgreements() {
    this.agreement.emit(this.isAgreed);
  }
}
