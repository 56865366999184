<mat-card class="rel create-container supplier-card brandView clearfix mb" @ngIfAnimation>
  <div class="card-header primary-gradient-img clearfix inModal rel">
    <div class="row clearfix content">
      <div class="mr pull-left flex social-media-icon">
        <div
          class="full-width icon-social-media text-center"
          *ngIf="socialMediaType == 'Facebook'"
          matTooltip="Post for Facebook"
        >
          <mat-icon class="fab fa-facebook-f big-icon" [style.color]="'#4267B2'"></mat-icon>
        </div>
        <div
          class="full-width icon-social-media text-center"
          *ngIf="socialMediaType == 'Instagram'"
          matTooltip="Post for Instagram"
        >
          <mat-icon class="fab fa-instagram big-icon" [style.color]="'#E1306C'"></mat-icon>
        </div>
        <div
          class="full-width icon-social-media text-center"
          *ngIf="socialMediaType == 'Twitter'"
          matTooltip="Post for Twitter"
        >
          <mat-icon class="fab fa-twitter big-icon" [style.color]="'#1DA1F2'"></mat-icon>
        </div>
        <div
          class="full-width icon-social-media text-center"
          *ngIf="socialMediaType == 'LinkedIn'"
          matTooltip="Post for Linkedin"
        >
          <mat-icon class="fab fa-linkedin-in big-icon" [style.color]="'#2867B2'"></mat-icon>
        </div>

        <div
          class="full-width icon-social-media text-center"
          *ngIf="socialMediaType == 'Pinterest'"
          matTooltip="Post for Pinterest"
        >
          <mat-icon class="fab fa-pinterest big-icon" [style.color]="'#c8232c'"></mat-icon>
        </div>
      </div>

      <button class="btn-close btn-clear mat-button white" *ngIf="isModal == true" (click)="closeModal()">
        <mat-icon>clear</mat-icon>
      </button>
      <div class="row titleArea clearfix">
        <h2 class="summary-header rm-m">
          {{ 'Social media template' }}
          <span class="subLabel"
          >Insert social media title and post, you can attach tags to the template, use panel in the right to upload a
            thumbnail.<br/>
            Insert a meaningful CTA to catch the user attention, upload Social media main Image to be added to the post.
          </span>
        </h2>
      </div>
    </div>
  </div>
  <form #resetForm="ngForm">
    <mat-dialog-content class="full-width" [ngClass]="{ 'no-modal-height': isModal != true }">
      <div @ngIfAnimation>
        <div class="full-width clearfix" *ngIf="step == 1">
          <div class="full-width clearfix flex row mb">
            <mat-card class="full-width row mr animate mat-elevation-z4">
              <div class="full-width row mr">
                <h3 class="sr-title">Social media details</h3>

                <div class="full-width flex mb">
                  <mat-form-field
                    class="thrd-width mr"
                    *ngIf="socialMediaID == null || !socialMediaID"
                    appearance="outline"
                  >
                    <mat-label>Social media type</mat-label>
                    <mat-select
                      class="full-width"
                      [(ngModel)]="socialMediaType"
                      (ngModelChange)="changeSocialMediType()"
                      name="Buffers_Pre"
                      placeholder="Social media type"
                      required
                    >
                      <mat-option [style.color]="'#4267B2'" [value]="'Facebook'">
                        <mat-icon class="fab fa-facebook"></mat-icon>
                        <span>Facebook</span>
                      </mat-option>
                      <mat-option [style.color]="'#2867B2'" [value]="'LinkedIn'">
                        <mat-icon class="fab fa-linkedin"></mat-icon>
                        <span>LinkedIn</span>
                      </mat-option>
                      <mat-option [style.color]="'#1DA1F2'" [value]="'Twitter'">
                        <mat-icon class="fab fa-twitter"></mat-icon>
                        <span>Twitter</span>
                      </mat-option>
                      <mat-option [style.color]="'#E1306C'" [value]="'Instagram'">
                        <mat-icon class="fab fa-instagram"></mat-icon>
                        <span>Instagram</span>
                      </mat-option>
                      <mat-option [style.color]="'#c8232c'" [value]="'Pinterest'">
                        <mat-icon class="fab fa-pinterest"></mat-icon>
                        <span>Pinterest</span>
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Template Title</mat-label>
                    <input
                      [(ngModel)]="postLabel"
                      (change)="changeTitle()"
                      name="audienceName"
                      matInput
                      placeholder="Template Title"
                      required
                    />

                    <mat-hint *ngIf="(postLabel?.length || 0) <= 65" align="end"
                    >Title length: {{ postLabel?.length || 0 }}/
                      {{ 65 || 0 }}
                    </mat-hint>

                    <mat-hint class="error-color" *ngIf="(postLabel?.length || 0) > 65" align="end"
                    >Title length: <strong> {{ postText?.length || 0 }} </strong>/
                      {{ 65 || 0 }}
                    </mat-hint>
                  </mat-form-field>
                </div>

                <div class="full-width clearfix">
                  <mat-form-field
                    class="full-width animate"
                    [ngClass]="(postText?.length || 0) > maxLength ? 'mat-form-field-invalid' : ''"
                    appearance="outline"
                  >
                    <mat-label>Post Text</mat-label>
                    <textarea
                      class="mat-block main-textarea"
                      [(ngModel)]="postText"
                      matInput
                      placeholder="Post Text"
                      name="postText"
                      required
                    >
                    </textarea>

                    <mat-hint *ngIf="(postText?.length || 0) <= maxLength" align="end"
                    >Post length: {{ postText?.length || 0 }}/
                      {{ maxLength || 0 }}
                    </mat-hint>

                    <mat-hint class="error-color" *ngIf="(postText?.length || 0) > maxLength" align="end"
                    >Post length: <strong> {{ postText?.length || 0 }} </strong>/
                      {{ maxLength || 0 }}
                    </mat-hint>
                  </mat-form-field>

                  <div class="full-width clearfix">
                    <button class="pull-left personalisation-button" [matMenuTriggerFor]="persMenu1" mat-button>
                      <mat-icon class="fas fa-plus"></mat-icon>Insert personalisation
                    </button>
                    <mat-menu #persMenu1="matMenu">
                      <div *ngFor="let p of dynamicData">
                        <button (click)="addPersonalisationText(p.code)" mat-menu-item>
                          {{ p.label }}
                        </button>
                      </div>
                    </mat-menu>
                  </div>
                </div>

                <div class="clearfix row full-width mb">
                  <h3 class="sr-title mt">Call to Action (CTA)</h3>

                  <div class="full-width clearfix">
                    <div class="full-width clearfix">
                      <mat-form-field class="full-width" *ngIf="socialMediaType != 'Instagram'" appearance="outline">
                        <mat-label>CTA Title</mat-label>

                        <input
                          [(ngModel)]="actionCallLabel"
                          name="actionCallLabel2"
                          matInput
                          [placeholder]="'The title is used to capture attention and seen by ' + ('KEYWORD.patient' | translate) + 's'"
                          required
                        />

                        <mat-hint
                          [ngClass]="
                            actionCallLabel &&
                            (actionCallLabel.length < minLengthCTALabel || actionCallLabel.length > maxLengthCTALabel)
                              ? 'hint-warning'
                              : ''
                          "
                          align="end"
                        >
                          Recommended text length is between <i>{{ minLengthCTALabel }}</i> and
                          <i>{{ maxLengthCTALabel }}</i> :
                          <strong> {{ actionCallLabel?.length || 0 }}/{{ maxLengthCTALabel }} </strong>
                        </mat-hint>
                      </mat-form-field>
                      <mat-form-field class="full-width" *ngIf="socialMediaType == 'Instagram'" appearance="outline">
                        <mat-label>CTA Title</mat-label>

                        <input
                          [(ngModel)]="actionCallLabel"
                          name="actionCallLabel2"
                          matInput
                          [placeholder]="'The title is used to capture attention and seen by ' + ('KEYWORD.patient' | translate) + 's'"
                        />

                        <mat-hint
                          [ngClass]="
                            actionCallLabel &&
                            (actionCallLabel.length < minLengthCTALabel || actionCallLabel.length > maxLengthCTALabel)
                              ? 'hint-warning'
                              : ''
                          "
                          align="end"
                        >
                          Recommended text length is between <i>{{ minLengthCTALabel }}</i> and
                          <i>{{ maxLengthCTALabel }}</i> :
                          <strong> {{ actionCallLabel?.length || 0 }}/{{ maxLengthCTALabel }} </strong>
                        </mat-hint>
                      </mat-form-field>

                      <div class="full-width clearfix">
                        <button class="pull-left personalisation-button" [matMenuTriggerFor]="persMenu2" mat-button>
                          <mat-icon class="fas fa-plus"></mat-icon>Insert personalisation
                        </button>
                        <mat-menu #persMenu2="matMenu">
                          <div *ngFor="let p of dynamicData">
                            <button (click)="addPersonalisationCTALabel(p.code)" mat-menu-item>
                              {{ p.label }}
                            </button>
                          </div>
                        </mat-menu>
                      </div>
                    </div>

                    <div
                      class="full-width clearfix"
                      *ngIf="
                        socialMediaType != 'Instagram' &&
                        socialMediaType != 'LinkedIn' &&
                        socialMediaType != 'Pinterest'
                      "
                    >
                      <mat-form-field class="full-width mt" appearance="outline">
                        <mat-label>CTA Description/Preview</mat-label>

                        <textarea
                          [(ngModel)]="actionCallDescription"
                          name="actionCallDescription"
                          matInput
                          placeholder="The description provides a preview of what the post offer contains and is seen by the patient."
                          required
                        >
                        </textarea>

                        <mat-hint
                          [ngClass]="
                            actionCallDescription &&
                            (actionCallDescription.length < minLengthCTADescription ||
                              actionCallDescription.length > maxLengthCTADescription)
                              ? 'hint-warning'
                              : ''
                          "
                          align="end"
                        >
                          Recommended text length is between <i>{{ minLengthCTADescription }}</i> and
                          <i>{{ maxLengthCTADescription }}</i> :
                          <strong> {{ actionCallDescription?.length || 0 }}/{{ maxLengthCTADescription }} </strong>
                        </mat-hint>
                      </mat-form-field>

                      <div class="full-width clearfix">
                        <button class="pull-left personalisation-button" [matMenuTriggerFor]="persMenu3" mat-button>
                          <mat-icon class="fas fa-plus"></mat-icon>Insert personalisation
                        </button>
                        <mat-menu #persMenu3="matMenu">
                          <div *ngFor="let p of dynamicData">
                            <button (click)="addPersonalisationCTADescription(p.code)" mat-menu-item>
                              {{ p.label }}
                            </button>
                          </div>
                        </mat-menu>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="clearfix row full-width mb">
                  <h3 class="sr-title mt">Tags and Suppliers</h3>

                  <div class="full-width row clearfix mb">
                    <div class="full-width text-left mb" *ngIf="!tags || (tags && tags.length <= 0)">
                      <button class="pull-right merchBtn mt" (click)="createNewTag()" mat-raised-button color="primary">
                        <mat-icon>add</mat-icon> New Tag
                      </button>
                      <p>
                        No tags are found - Tags are applied to {{ "KEYWORD.patient" | translate }}s and used in
                        marketing to create audiences and
                        query {{ "KEYWORD.patient" | translate }}s.
                      </p>
                    </div>

                    <app-tag-list
                      class="full-width"
                      [tags]="tags"
                      [selectedTags]="defaultSelectedTags"
                      [add]="addTag"
                      [tagFormatIsObject]="true"
                      [title]="'Attach tags to this template'"
                      [canRemove]="true"
                      [canCreate]="true"
                      [directRemoveDefault]="true"
                      [hasFilter]="true"
                      [canSelect]="false"
                      (getCreate)="createNewTag()"
                      (getSelectedTags)="getSelectedTags($event)"
                    >
                    </app-tag-list>
                  </div>

                  <div class="full-width flex row clearfix mb" *ngIf="suppliers && suppliers.length > 0">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Suppliers</mat-label>
                      <mat-select
                        class="margin-btm"
                        [(ngModel)]="selectedSuppliers"
                        name="suplipers"
                        multiple
                        placeholder="Suppliers"
                      >
                        <mat-option *ngFor="let t of suppliers | orderBy: ['Label']" [value]="t.ID">
                          {{ t.Label }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </mat-card>
            <div class="qtr-width row blob-for-social-media-container-thumbnail ml mr">
              <div class="full-width">
                <app-ep-document-view-image
                  class="squareImg squareImg-social"
                  *ngIf="!postPictureLinkThumbnail && postPictureThumbnail"
                  [hideZoomButton]="false"
                  [defaultPicture]="
                    'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/gettingstarted-img4.png)'
                  "
                  [link]="postPictureThumbnail"
                >
                </app-ep-document-view-image>

                <app-ep-document-view-image
                  class="squareImg squareImg-social"
                  *ngIf="postPictureLinkThumbnail && !postPictureThumbnail"
                  [hideZoomButton]="false"
                  [defaultPicture]="
                    'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/gettingstarted-img4.png)'
                  "
                  [link]="postPictureLinkThumbnail"
                >
                </app-ep-document-view-image>

                <app-ep-document-view-image
                  class="squareImg squareImg-social"
                  *ngIf="!postPictureLinkThumbnail && !postPictureThumbnail"
                  [hideZoomButton]="false"
                  [defaultPicture]="
                    'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/gettingstarted-img4.png)'
                  "
                >
                </app-ep-document-view-image>
              </div>

              <div class="clearfix full-width">
                <hr/>
                <app-file-uploader
                  class="text-center clearfix full-width"
                  [allowedExtensions]="allowExtensions"
                  [maxFileSize]="2"
                  [isImageCropping]="true"
                  [canSkip]="true"
                  [limit]="1"
                  [uploadDirectly]="true"
                  [isDragable]="true"
                  [title]="'CLICK HERE to upload Thumbnail (Max size: ' + 2 + 'MB)'"
                  (onCompleteAll)="onCompleteThumbnail($event)"
                >
                </app-file-uploader>
              </div>
            </div>
          </div>

          <div class="clearfix full-width mb">
            <h3 class="sr-title mt">Post Image</h3>

            <p *ngIf="socialMediaType == 'Facebook'">
              Recommended dimension is <strong>1200x630</strong> (only images are allowed). Max image size is
              {{ maxFileSize }} MB
            </p>
            <p *ngIf="socialMediaType == 'Instagram'">
              Recommended dimension is <strong>1080x566</strong> (only images are allowed). Max image size is
              {{ maxFileSize }} MB
            </p>
            <p *ngIf="socialMediaType == 'LinkedIn'">
              Recommended dimension is <strong>1024x512</strong> (only images are allowed). Max image size is
              {{ maxFileSize }} MB
            </p>
            <p *ngIf="socialMediaType == 'Twitter'">
              Recommended dimension is <strong>1024x512</strong> (only images are allowed). Max image size is
              {{ maxFileSize }} MB
            </p>
            <p *ngIf="socialMediaType == 'Pinterest'">
              Recommended dimension is <strong>1000x1500</strong> (only images are allowed). Max image size is
              {{ maxFileSize }} MB
            </p>
            <div class="full-width">
              <div class="full-width mb blob-for-social-media-container">
                <app-blob-view
                  class="row clearfix flex flexCol blob-for-social-media full-width full-height"
                  *ngIf="(!postPictureLink && postPicture && loadingPost != true) || loadingPost == true"
                  [label]="'Campaign'"
                  [applyDownload]="applyDownload"
                  [loading]="loadingPost"
                  [isDownload]="false"
                  [isProceed]="false"
                  [isPrint]="false"
                  [canProceed]="false"
                  [content]="postPicture"
                  [extension]="'png'"
                  [label]=""
                >
                </app-blob-view>

                <div
                  class="view-container full-height full-width"
                  *ngIf="postPictureLink && !postPicture && loadingPost != true"
                >
                  <div class="row clearfix">
                    <pinch-zoom
                      class="full-height clearfix full-width pinchZoom"
                      [limit-zoom]="25"
                      [wheel]="true"
                      [wheelZoomFactor]="0.5"
                      [double-tap]="true"
                      [properties]="pinchZoomProperties"
                    >
                      <img [src]="postPictureLink" />
                    </pinch-zoom>
                  </div>
                </div>

                <div
                  class="view-container full-height full-width"
                  *ngIf="
                    (!postPicture || postPicture == null) &&
                    (!postPictureLink || postPictureLink == null) &&
                    loadingPost != true
                  "
                >
                  <div class="row clearfix">
                    <pinch-zoom
                      class="full-height clearfix full-width pinchZoom"
                      [limit-zoom]="25"
                      [wheel]="true"
                      [wheelZoomFactor]="0.5"
                      [double-tap]="true"
                      [properties]="pinchZoomProperties"
                    >
                      <img
                        [src]="
                          'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/public/img/popularity.png'
                        "
                      />
                    </pinch-zoom>
                  </div>
                </div>
              </div>
              <div class="clearfix full-width">
                <app-file-uploader
                  class="text-center clearfix full-width"
                  [allowedExtensions]="allowExtensions"
                  [maxFileSize]="maxFileSize"
                  [isFixedAspectRatio]="false"
                  [isImageCropping]="true"
                  [canSkip]="false"
                  [limit]="1"
                  [uploadDirectly]="true"
                  [isDragable]="true"
                  [title]="'CLICK HERE to upload  Image (Max size: ' + maxFileSize + 'MB)'"
                  (onCompleteAll)="onComplete($event)"
                >
                </app-file-uploader>
              </div>
            </div>
          </div>
          <div class="full-width clearfix mt mb" *ngIf="socialMediaID">
            <mat-slide-toggle [(ngModel)]="isActive" name="isActive">
              Display this social media template for users?
            </mat-slide-toggle>
          </div>

          <hr class="mb"/>
        </div>

        <div class="container" id="step-4" *ngIf="step == 2" @ngIfAnimation>
          <div class="animate clearfix">
            <div class="row clearfix">
              <mat-card class="card successful">
                <div class="row clearfix">
                  <div class="full-width text-center">
                    <h1 class="sm-h1 sr-title">Well Done!</h1>
                    <h3 class="sm-h3 rm-m">Social media Campaign is Created</h3>
                    <div class="row clearfix">
                      <div class="svg-box">
                        <svg class="circular green-stroke">
                          <circle
                            class="path"
                            cx="75"
                            cy="75"
                            r="50"
                            fill="none"
                            stroke-width="5"
                            stroke-miterlimit="10"
                          />
                        </svg>
                        <svg class="checkmark green-stroke">
                          <g transform="matrix(0.79961,8.65821e-32,8.39584e-32,0.79961,-489.57,-205.679)">
                            <path
                              class="checkmark__check"
                              fill="none"
                              d="M616.306,283.025L634.087,300.805L673.361,261.53"
                            />
                          </g>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row clearfix text-center button-row flex">
                  <button class="step-next" (click)="templateList()" mat-raised-button color="primary">
                    <mat-icon>list</mat-icon> Templates list
                  </button>

                  <button class="step-next" (click)="newTemplate()" mat-raised-button color="accent">
                    <mat-icon>add</mat-icon> New template
                  </button>

                  <button
                    class="step-next"
                    (click)="viewSocialMedia(createdTemplate.ID)"
                    mat-raised-button
                    color="accent"
                  >
                    <mat-icon>visibility</mat-icon>
                    <span>View Template</span>
                  </button>
                </div>
              </mat-card>
            </div>
          </div>
        </div>
      </div>
    </mat-dialog-content>
    <div class="drop-button full-width mt" *ngIf="step == 1">
      <button
        class="inline selectExistingPatient pull-left"
        *ngIf="isModal == true"
        (click)="closeModal()"
        mat-raised-button
        color="primary"
      >
        Close
      </button>

      <button
        class="inline selectExistingPatient pull-left"
        *ngIf="isModal != true"
        (click)="templateList()"
        mat-raised-button
        color="primary"
      >
        <mat-icon>chevron_left</mat-icon> Templates list
      </button>

      <button
        class="inline btn-new-invite selectExistingPatient pull-right"
        *ngIf="!socialMediaID"
        [disabled]="!resetForm.form.valid || (postText?.length || 0) > maxLength || postLabel?.length > 65"
        (click)="createEdit()"
        mat-raised-button
        color="accent"
      >
        Create Template
        <mat-icon>chevron_right</mat-icon>
      </button>

      <button
        class="inline btn-new-invite selectExistingPatient pull-right"
        *ngIf="socialMediaID"
        [disabled]="!resetForm.form.valid || (postText?.length || 0) > maxLength || postLabel?.length > 65"
        (click)="createEdit()"
        mat-raised-button
        color="accent"
      >
        Edit Template
        <mat-icon>chevron_right</mat-icon>
      </button>
    </div>
  </form>
</mat-card>
