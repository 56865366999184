<ipv-title-modal [title]="'Note Template'" [hideAllDefaultButtons]="true" [icon]="null">
  <div content>
    <div class="row clearfix smt">
      <mat-form-field class="full-width" appearance="outline">
        <input
          [(ngModel)]="label"
          (ngModelChange)="checkFormValidity()"
          matInput
          placeholder="Label"
          name="label"
          required
        />
      </mat-form-field>
    </div>

    <div class="row clearfix">
      <ipv-note-input
        [text]="noteText"
        [patient]="patient"
        [practice]="practice"
        [dentist]="dentist"
        [hideTemplateSaveButton]="true"
        [hideTemplateSelectButton]="true"
        (textUpdated)="setNoteValue($event)"
      >
      </ipv-note-input>
    </div>

    <hr/>
    <div class="row clearfix full-width text-center">
      <button class="pull-left btn-large smb" (click)="close()" mat-raised-button color="primary">Close</button>

      <button
        class="pull-right btn-large smb"
        *ngIf="!disableApply"
        [disabled]="validateForm"
        (click)="saveAndApply()"
        mat-raised-button
        color="accent"
      >
        Apply & Submit
      </button>

      <button
        class="pull-right btn-large mr smb"
        *ngIf="formMode === 'create'"
        [disabled]="!validateForm"
        (click)="save()"
        mat-raised-button
        color="accent"
      >
        Submit
      </button>

      <button
        class="pull-right btn-large mr smb"
        *ngIf="formMode === 'update'"
        [disabled]="!validateForm"
        (click)="update()"
        mat-raised-button
        color="accent"
      >
        Update
      </button>
    </div>
  </div>
</ipv-title-modal>
