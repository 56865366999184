<div class="card-header primary-gradient-img clearfix inModal">
  <div class="row clearfix">
    <button class="btn-close btn-clear mat-button white" *ngIf="isModal == true" (click)="close()">
      <mat-icon>clear</mat-icon>
    </button>
    <div class="row titleArea clearfix hasIcon">
      <mat-icon class="white pull-left fas fa-image patient-main-icon"></mat-icon>
      <h2 class="summary-header rm-m">
        {{ title }}
        <span class="subLabel">
          {{ description }}
        </span>
      </h2>
    </div>
  </div>
</div>

<div class="row full-width clearfix">
  <mat-button-toggle-group class="btn-group selectType rm-m pull-left full-width text-center smb"
                           #view="matButtonToggleGroup" [(ngModel)]="selectedView" (change)="checkTitle()"
                           name="galleryView">
    <!-- show all docs from practice -->
    <mat-button-toggle *ngIf="displayUserDocuments == true" [value]="'user'">{{"KEYWORD.Practice" | translate}}

      <span class="chip sml mat-elevation-z1">{{ userDocumentsLength }}<span
        *ngIf="loadMoreUserDocument === true && userDocumentsLength > 0">...</span>
      </span>
    </mat-button-toggle>

    <!-- promoter level documents -->
    <mat-button-toggle *ngIf="displayICDocuments == true" [value]="'system'">
      {{ "BRANDING.Brand Name" | translate | titlecase }}

      <span class="chip sml mat-elevation-z1">{{ ICDocumentsLength }}<span
        *ngIf="loadMoreICDocument === true && ICDocumentsLength > 0">...</span></span>
    </mat-button-toggle>

    <!-- show only this patients documents -->
    <mat-button-toggle *ngIf="displayPatientDocuments == true && isModulePatientDocumentActive == true"
                       [value]="'patient'">
      <span *ngIf="patient && patient.FirstName && buttonLabel">
        {{ patient?.FirstName }}'s -
        {{ buttonLabel }}
      </span>
      <span *ngIf="!patient || (patient && !patient.FirstName)"> {{ "KEYWORD.patient" | translate | titlecase }}
        Documents </span>

      <span class="chip sml mat-elevation-z1">{{ patientDocumentsLength
        }}<span *ngIf="loadMorePatientDocument === true && patientDocumentsLength > 0">...</span></span>
    </mat-button-toggle>

    <mat-button-toggle class="btn-primary" *ngIf="displaySelecteDocuments == true && documents && documents.length > 0"
                       [value]="'selected'" color="accent">Selected
      <span class="chip sml mat-elevation-z1" [endVal]="documents.length" countUp></span>
    </mat-button-toggle>
  </mat-button-toggle-group>
</div>

<mat-dialog-content [ngClass]="{ 'no-modal-height': isModal != true }">
  <div class="row clearfix flex gallery-display-area">
    <div class="row clearfix flex full-width" [hidden]="selectedView != 'patient'">
      <div class="mat-elevation-z3 search-area" (keyup.enter)="checkPatientDocumentsClickSearch()">
        <div class="row titleArea clearfix text-center mb" *ngIf="selectedPatientSearch">
          <app-ep-document-view-image class="profile-lead-image" [hideZoomButton]="false" [defaultPicture]="
              'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/profile_placeholder.png'
            " [link]="profileLink" tabindex="0" alt="Your default profile image">
          </app-ep-document-view-image>
          <h3 class="sr-title text-center">
            {{ selectedPatientSearch['firstName'] }} {{ selectedPatientSearch['lastName'] }}
          </h3>
        </div>

        <div class="full-wdith text-center smt" *ngIf="selectAnotherPatient == true">
          <button class="clearfix btn mb" *ngIf="selectPatient == true" (click)="patientLookup()" mat-raised-button
                  color="accent">
            <mat-icon>search</mat-icon>

            <span *ngIf="!selectedPatientSearch || (selectedPatientSearch && !selectedPatientSearch.ID)">
              Select {{ "KEYWORD.patient" | translate | titlecase }}
            </span>
            <span *ngIf="selectedPatientSearch && selectedPatientSearch.ID"> Select Another {{ "KEYWORD.patient" |
              translate | titlecase }} </span>
          </button>
        </div>

        <hr/>

        <div class="search-fields flex-grow mt">
          <mat-form-field class="full-width clearfix mb" appearance="outline">
            <mat-label>Document Label</mat-label>
            <input class="mat-block" [(ngModel)]="patientDocumentLabelSearch" matInput name="patientDocumentLabel"
                   placeholder="Document Label"/>
          </mat-form-field>

          <mat-form-field class="full-width clearfix" *ngIf="displayNature == true" appearance="outline">
            <mat-label>Document Nature</mat-label>
            <mat-select class=" " [(ngModel)]="_naturePatientDocumentSearch" name="DocumentSort"
                        placeholder="Document Nature" multiple>
              <mat-option *ngFor="let type of natures | orderBy: ['label']" [value]="type['code']">
                {{ type.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <mat-form-field class="full-width clearfix"
          *ngIf="displayPatientDocumentType == true && patientDocumentTypes && patientDocumentTypes.length > 0"
                        appearance="outline">
          <mat-label>Document Type</mat-label>
          <mat-select class=" " [(ngModel)]="_patientDocumentTypeSearch" (ngModelChange)="changeDocumentType($event)"
                      name="DocumentType" placeholder="Document Type" multiple>
            <mat-option *ngFor="let type of patientDocumentTypes | orderBy: ['label']" [value]="type['code']">
              {{ type.label | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="full-width clearfix" *ngIf="displayOrderBy == true" appearance="outline">
          <mat-label>Order By</mat-label>
          <mat-select class=" " [(ngModel)]="patientOrderBySearch" name="patientOrderBy" placeholder="Order By">
            <mat-option [value]="'-DateTimeCreated'"> Latest Added</mat-option>
            <mat-option [value]="'LastModified'"> Latest Modified</mat-option>

            <mat-option [value]="'DateTimeCreated'"> First Added</mat-option>

            <mat-option [value]="'Document.Label'"> By Label (Ascending)</mat-option>

            <mat-option [value]="'-Document.Label'"> By Label (Descending)</mat-option>
            <mat-option [value]="'Document.Extension'"> By Extension</mat-option>
          </mat-select>
        </mat-form-field>

        <div class="search-action clearfix full-width">
          <app-date-range-picker [showAllTime]="true" [dateLabel]="'Upload Date'" [selectedRange]="'all'"
                                 (getRange)="getDateRange($event)"></app-date-range-picker>
        </div>
      </div>
      <div class="full-width results-area"
           *ngIf="patientDocumentLoading != true && patientDocuments && patientDocuments.length > 0">
        <app-ep-document-list class="gallery-ep-list" [deleteFile]="deletePatientDocumentEvent" [miniEdit]="true"
                              [onlyView]="onlyView"
                              [removeLabel]="'Remove '+ ('KEYWORD.patient' | translate | titlecase) + ' Document'"
                              [directRemove]="false" [actionLabelGlobal]="'Select'" [canEdit]="true"
                              [canActionGlobal]="false"
                              [canCheck]="canCheck" [canRemove]="canRemove" [isDownload]="isDownload"
                              [isRedirection]="isRedirection"
                              [isPrint]="isPrint" [isFilter]="false" [defaultCheck]="false"
                              [listViewType]="'thumbnails'"
                              [selectedIDs]="documents" [documents]="patientDocumentsDefault" [isModal]="false"
                              [showLabels]="false"
                              [fullsizeClick]="isFullsizeClick" [addFiles]="addPatientFiles"
                              [filter]="patientDocumentFilter"
                              (getCheckedDocument)="getCheckedDocumentEvent($event)"
                              (removeID)="removePatientDoc($event)"
          (getUncheckedDocument)="getUncheckedDocumentEvent($event)"
                              (getDocumentLength)="getPatientDocumentsLength($event)">
        </app-ep-document-list>

        <div class="text-center full-width mt clearfix place-holder-result" *ngIf="loadMorePatientDocument == true">
          <!--
          <button class="btn-large text-center ml  mb  mr" *ngIf="loadMorePatientDocument==true " mat-raised-button
            color="primary" (click)="loadMorePatientDocumentEvent()">
            Load More Documents <mat-icon>more_horiz</mat-icon>
          </button> -->
        </div>
      </div>

      <div class="full-width results-area" *ngIf="
          patientDocumentFirstLoad == true &&
          patientDocumentLoading != true &&
          patientDocuments &&
          patientDocuments.length <= 0 &&
          patientID
        ">
        <app-empty-list-message [title]="'No Document found'"
                                [message]="'We could not find any document in your  gallery'">
        </app-empty-list-message>
      </div>

      <div class="full-width results-area" *ngIf="!patientID">
        <app-empty-list-message [title]="'Select a ' + ('KEYWORD.patient' | translate | titlecase) + ''"
                                [message]="'Please Select a '+ ('KEYWORD.patient' | translate | titlecase) + ' from the list in the right panel'">
        </app-empty-list-message>
      </div>
    </div>

    <!-- my gallery -->
    <div class="row clearfix flex full-width" [hidden]="selectedView != 'user'">
      <!-- <div class=" mat-elevation-z3 search-area" *ngIf="loadUserDocument!=true"> -->
      <div class="mat-elevation-z3 search-area" (keyup.enter)="checkUserDocumentsSearch()">
        <mat-form-field class="full-width clearfix" *ngIf="isDisplayMerchant == true && isAdminOrPromoter"
                        appearance="outline">
          <mat-label>Merchant</mat-label>
          <mat-select [(ngModel)]="merchantIDSearch" name="MerchantName" placeholder="Merchant">
            <mat-option [value]="null"> My Account</mat-option>
            <mat-option *ngFor="let m of merchants | orderBy: ['TradingAs', 'CalculatedName']" [value]="m['ID']">
              <span class="list-label pull-right" *ngIf="m['Status'] && m['Status'] == 'Active'"
                    [style.background]="'#00d07a'">
                {{ m['Status'] }}
              </span>

              <span class="list-label pull-right" *ngIf="m['Status'] && m['Status'] != 'Active'"
                    [style.background]="'#ff5722'">
                {{ m['Status'] }}
              </span>
              {{ m['TradingAs'] || m['CalculatedName'] }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <div class="search-fields flex-grow">
          <mat-form-field class="full-width clearfix mb" appearance="outline">
            <mat-label>Document Label</mat-label>
            <input class="mat-block" [(ngModel)]="userDocumentLabelSearch" matInput name="userDocumentLabel"
                   placeholder="Document Label"/>
          </mat-form-field>

          <mat-form-field class="full-width clearfix" *ngIf="displayNature == true" appearance="outline">
            <mat-label>Document Nature</mat-label>
            <mat-select class=" " [(ngModel)]="_natureUserDocumentSearch" name="DocumentSort"
                        placeholder="Document Nature" multiple>
              <mat-option *ngFor="let type of natures | orderBy: ['label']" [value]="type['code']">
                {{ type.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <mat-form-field class="full-width clearfix"
          *ngIf="displayUserDocumentType == true && userDocumentTypes && userDocumentTypes.length > 0"
                        appearance="outline">
          <mat-label>Document Type</mat-label>
          <mat-select class=" " [(ngModel)]="_userDocumentTypeSearch" (ngModelChange)="changeDocumentType($event)"
                      name="DocumentType" placeholder="Document Type" multiple>
            <mat-option *ngFor="let type of userDocumentTypes | orderBy: ['label']" [value]="type['code']">
              {{ type.label  | translate}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="full-width clearfix" *ngIf="displayOrderBy == true" appearance="outline">
          <mat-label>Order By</mat-label>
          <mat-select class=" " [(ngModel)]="userOrderBySearch" name="userOrderBy" placeholder="Order By">
            <mat-option [value]="'-DateTimeCreated'"> Latest Added</mat-option>
            <mat-option [value]="'LastModified'"> Latest Modified</mat-option>
            <mat-option [value]="'DateTimeCreated'"> First Added</mat-option>

            <mat-option [value]="'Document.Label'"> By Label (Ascending)</mat-option>

            <mat-option [value]="'-Document.Label'"> By Label (Descending)</mat-option>
            <mat-option [value]="'Document.Extension'"> By Extension</mat-option>
          </mat-select>
        </mat-form-field>

        <div class="search-action clearfix full-width">
          <app-date-range-picker [showAllTime]="true" [dateLabel]="'Upload Date'" [selectedRange]="'all'"
                                 (getRange)="getDateRange($event)"></app-date-range-picker>
        </div>
      </div>
      <div class="full-width results-area"
           *ngIf="userDocumentLoading != true && userDocuments && userDocuments.length > 0">
        <app-ep-document-list class="gallery-ep-list" [onlyView]="onlyView" [deleteFile]="deleteUserDocumentEvent"
                              [directRemove]="false" [removeLabel]="'Remove From Gallery'"
                              [actionLabelGlobal]="'Select'" [canEdit]="true"
                              [canActionGlobal]="false" [canCheck]="canCheck" [canRemove]="canRemove"
                              [isDownload]="isDownload"
                              [isRedirection]="isRedirection" [isPrint]="isPrint" [isFilter]="false"
                              [defaultCheck]="false"
                              [listViewType]="'thumbnails'" [selectedIDs]="documents" [documents]="userDocumentsDefault"
                              [isModal]="false"
                              [showLabels]="false" [fullsizeClick]="isFullsizeClick" [addFiles]="addUserFiles"
                              [filter]="userDocumentFilter"
                              (getCheckedDocument)="getCheckedDocumentEvent($event)"
                              (removeID)="removeFromGallery($event)"
          (getUncheckedDocument)="getUncheckedDocumentEvent($event)"
                              (getDocumentLength)="getUserDocumentsLength($event)">
        </app-ep-document-list>

        <div class="text-center full-width mt clearfix place-holder-result" *ngIf="loadMoreUserDocument == true">
          <!-- <button class="btn-large  text-center ml mb  mr" *ngIf="loadMoreUserDocument==true " mat-raised-button
            color="primary" (click)="loadMoreUserDocumentEvent()">
            Load More Documents <mat-icon>more_horiz</mat-icon>
          </button> -->
        </div>
      </div>

      <div class="full-width results-area" *ngIf="
          userDocumentFirstLoad == true && userDocumentLoading != true && userDocuments && userDocuments.length <= 0
        ">
        <app-empty-list-message [title]="'No Document found'"
                                [message]="'We could not find any document in your  gallery'">
        </app-empty-list-message>
      </div>
    </div>

    <!--   {{"BRANDING.Brand Name" | translate}} stock gallery -->
    <div class="row clearfix flex full-width" [hidden]="selectedView != 'system'">
      <div class="mat-elevation-z3 search-area" (keyup.enter)="checkICDocumentsSearch()">
        <div class="search-fields smb clearfix">
          <mat-form-field class="full-width clearfix" *ngIf="isDisplayMerchant == true && isAdminOrPromoter"
                          appearance="outline">
            <mat-label>Merchant</mat-label>
            <mat-select [(ngModel)]="merchantIDSearch" name="MerchantName" placeholder="Merchant">
              <mat-option [value]="null"> My Account</mat-option>
              <mat-option *ngFor="let m of merchants | orderBy: ['TradingAs', 'CalculatedName']" [value]="m['ID']">
                <span class="list-label pull-right" *ngIf="m['Status'] && m['Status'] == 'Active'"
                      [style.background]="'#00d07a'">
                  {{ m['Status'] }}
                </span>

                <span class="list-label pull-right" *ngIf="m['Status'] && m['Status'] != 'Active'"
                      [style.background]="'#ff5722'">
                  {{ m['Status'] }}
                </span>
                {{ m['TradingAs'] || m['CalculatedName'] }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="full-width clearfix mb" appearance="outline">
            <mat-label>Document Label</mat-label>
            <input class="mat-block" [(ngModel)]="ICLabelSearch" matInput name="DocumentLabel"
                   placeholder="Document Label"/>
          </mat-form-field>
          <mat-form-field class="full-width clearfix" *ngIf="displayNature == true" appearance="outline">
            <mat-label>Document Nature</mat-label>
            <mat-select class=" " [(ngModel)]="_natureICDocumentSearch" name="DocumentSort"
                        placeholder="Document Nature" multiple>
              <mat-option *ngFor="let type of natures | orderBy: ['label']" [value]="type['code']">
                {{ type.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="full-width clearfix" *ngIf="displayOrderBy == true" appearance="outline">
            <mat-label>Order By</mat-label>
            <mat-select class=" " [(ngModel)]="ICOrderBySearch" name="ICOrderBy" placeholder="Order By">
              <mat-option [value]="'-DateTimeCreated'"> Latest Added</mat-option>
              <mat-option [value]="'LastModified'"> Latest Modified</mat-option>
              <mat-option [value]="'DateTimeCreated'"> First Added</mat-option>

              <mat-option [value]="'Document.Label'"> By Label (Ascending)</mat-option>

              <mat-option [value]="'-Document.Label'"> By Label (Descending)</mat-option>
              <mat-option [value]="'Document.Extension'"> By Extension</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="full-width clearfix" appearance="outline">
            <mat-label>Item Code</mat-label>
            <input class="mat-block" [(ngModel)]="itemCodeSearch" matInput name="itemCode" placeholder="Item code"
                   tabIndex="1"/>
          </mat-form-field>

          <mat-form-field class="full-width clearfix"
                          *ngIf="displayICDocumentType == true && ICDocumentTypes && ICDocumentTypes.length > 0"
                          appearance="outline">
            <mat-label>Document Type</mat-label>
            <mat-select class=" " [(ngModel)]="_ICDocumentTypeSearch" name="DocumentType" placeholder="Document Type"
                        multiple>
              <mat-option *ngFor="let type of ICDocumentTypes | orderBy: ['label']" [value]="type['code']">
                {{ type.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="full-width clearfix" appearance="outline">
            <mat-label>Category</mat-label>
            <mat-select class=" " [(ngModel)]="categoryIDSearch"
                        (selectionChange)="onSelectSimpleCategory(categoryIDSearch)" name="category"
                        placeholder="Category"
                        panelClass="maxSelectWidth2">
              <mat-option *ngFor="let category of categories" [value]="category['ID']">
                <button class="position view-button-select"
                        (click)="$event.stopPropagation(); viewCategory(category['ID'])" mat-button mat-icon-button
                        matPrefix
                        matTooltip="View     {{ treatmentUtil.getLabel(category['Label']) }}">
                  <mat-icon>visibility</mat-icon>
                </button>

                {{ treatmentUtil.getLabel(category['Label']) }} <strong>({{ category['NumberOfDocuments'] }})</strong>

                <hr style="margin-bottom: -8px"/>
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="full-width clearfix" *ngIf="categoryIDSearch" appearance="outline">
            <mat-label>Type</mat-label>
            <mat-select class=" " [(ngModel)]="typeIDSearch" (selectionChange)="onSelectSimpleType(typeIDSearch)"
                        name="type" placeholder="Type" panelClass="maxSelectWidth">
              <mat-option *ngFor="let type of types" [value]="type['ID']">
                <button class="position view-button-select" (click)="$event.stopPropagation(); viewType(type['ID'])"
                        mat-button mat-icon-button matPrefix
                        matTooltip="View {{ treatmentUtil.getLabel(type['Label']) }}">
                  <mat-icon>visibility</mat-icon>
                </button>

                {{ treatmentUtil.getLabel(type['Label']) }} <strong>({{ type['NumberOfDocuments'] }})</strong>

                <hr style="margin-bottom: -8px"/>
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="full-width clearfix" *ngIf="typeIDSearch" appearance="outline">
            <mat-label>Brand</mat-label>

            <mat-select class=" " [(ngModel)]="brandIDSearch" (selectionChange)="onSelectSimpleBrand(brandIDSearch)"
                        name="brand" placeholder="Brand" panelClass="maxSelectWidth">
              <mat-option *ngFor="let brand of brands" [value]="brand['ID']">
                <button class="position view-button-select" (click)="$event.stopPropagation(); viewBrand(brand['ID'])"
                        mat-button mat-icon-button matPrefix
                        matTooltip="View   {{ treatmentUtil.getLabel(brand['Label']) }}">
                  <mat-icon>visibility</mat-icon>
                </button>

                {{ treatmentUtil.getLabel(brand['Label']) }} <strong>({{ brand['NumberOfDocuments'] }})</strong>

                <hr style="margin-bottom: -8px"/>
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="full-width clearfix" *ngIf="brandIDSearch" appearance="outline">
            <mat-label>Product</mat-label>

            <mat-select class=" " [(ngModel)]="productIDSearch" name="product" placeholder="Product"
                        panelClass="maxSelectWidth">
              <mat-option *ngFor="let product of products" [value]="product['ID']">
                <button class="position view-button-select"
                        (click)="$event.stopPropagation(); viewProduct(product['ID'])" mat-button mat-icon-button
                        matPrefix
                        matTooltip="View    {{ treatmentUtil.getLabel(product['Label']) }}">
                  <mat-icon>visibility</mat-icon>
                </button>

                {{ treatmentUtil.getLabel(product['Label']) }} <strong>({{ product['NumberOfDocuments'] }})</strong>

                <hr style="margin-bottom: -8px"/>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="full-width results-area">
        <div class="row clearfix mb" *ngIf="ICDocuments && ICDocuments.length > 0 && ICDocumentLoading != true">
          <div class="clearfix full-width">
            <app-ep-document-list class="gallery-ep-list" [canActionGlobal]="false" [directRemove]="false"
                                  [deleteFile]="deleteICDocumentEvent" [canCheck]="canCheck" [isDownload]="isDownload"
                                  [canEdit]="isAdminOrPromoter" [isRedirection]="isRedirection" [isPrint]="isPrint"
                                  [isFilter]="false"
                                  [defaultCheck]="false" [listViewType]="'thumbnails'" [selectedIDs]="documents"
                                  [documents]="ICDocumentsDefault" [isModal]="false" [showLabels]="false"
                                  [fullsizeClick]="isFullsizeClick"
                                  [addFiles]="addICFiles" [filter]="ICDocumentFilter"
                                  (getCheckedDocument)="getCheckedDocumentEvent($event)"
              (getUncheckedDocument)="getUncheckedDocumentEvent($event)"
                                  (getDocumentLength)="getICDocumentsLength($event)">
            </app-ep-document-list>

            <div class="text-center full-width mt clearfix place-holder-result" *ngIf="loadMoreICDocument == true">
              <!--
              <button class="btn-large text-center ml mb  mr" *ngIf="loadMoreICDocument==true " mat-raised-button
                color="primary" (click)="loadMoreICDocumentEvent()">
                Load More Documents <mat-icon>more_horiz</mat-icon>
              </button> -->
            </div>
          </div>
        </div>

        <div class="full-width results-area" *ngIf="
            (ICDocumentLoading != true && ICDocumentFirstLoad == true && ICDocuments && ICDocuments.length <= 0) ||
            ICDocuments.length <= 0
          ">
          <app-empty-list-message [title]="'No Documents found'" [message]="'Please search for a document'">
          </app-empty-list-message>
        </div>
      </div>
    </div>

    <!-- selected documents list -->
    <div class="row clearfix flex full-width" *ngIf="selectedView == 'selected'">
      <div class="full-width results-area" *ngIf="documents && documents.length > 0">
        <app-ep-document-list class="gallery-ep-list" [actionLabelGlobal]="'Select'" [canActionGlobal]="false"
                              [canCheck]="canCheck" [isDownload]="isDownload" [isRedirection]="isRedirection"
                              [isPrint]="isPrint"
                              [isFilter]="false" [defaultCheck]="false" [listViewType]="'thumbnails'"
                              [selectedIDs]="documents"
                              [documents]="documents" [isModal]="false" [showLabels]="false"
                              [fullsizeClick]="isFullsizeClick"
                              [filter]="ICDocumentFilter" (getCheckedDocument)="getCheckedDocumentEvent($event)"
                              (getUncheckedDocument)="getUncheckedDocumentEvent($event)"></app-ep-document-list>
      </div>

      <div class="full-width results-area"
           *ngIf="userDocumentLoading != true && userDocuments && userDocuments.length <= 0">
        <app-empty-list-message [title]="'No Documents Selected'"
                                [message]="'You have not selected any Documents yet '">
        </app-empty-list-message>
      </div>
    </div>
  </div>
</mat-dialog-content>

<div class="rel">
  <button class="btn-large text-center ml mb mr load-more-button"
          *ngIf="loadMorePatientDocument == true && selectedView == 'patient'" (click)="loadMorePatientDocumentEvent()"
          mat-raised-button color="primary">
    Load More Documents <mat-icon>more_horiz</mat-icon>
  </button>

  <button class="btn-large text-center ml mb mr load-more-button"
          *ngIf="loadMoreUserDocument == true && selectedView == 'user'" (click)="loadMoreUserDocumentEvent()"
          mat-raised-button color="primary">
    Load More Documents <mat-icon>more_horiz</mat-icon>
  </button>

  <button class="btn-large text-center ml mb mr load-more-button"
          *ngIf="loadMoreICDocument == true && selectedView == 'system'" (click)="loadMoreICDocumentEvent()"
          mat-raised-button
          color="primary">
    Load More Documents <mat-icon>more_horiz</mat-icon>
  </button>
</div>

<div class="selected-actions full-width row clearfix flex">
  <div class="search-action search-action-button clearfix pull-left" [hidden]="selectedView != 'user'">
    <button class="text-center mr" (click)="clearUserGroup()" mat-raised-button>
      <mat-icon class="primary-color">remove_circle_outline</mat-icon> <span class="primary-color">Clear</span>
    </button>

    <button class="text-center" (click)="checkUserDocumentsSearch()" mat-raised-button color="accent">
      {{ actionLabel }}
    </button>
  </div>

  <div class="search-action search-action-button clearfix pull-left" [hidden]="selectedView != 'system'">
    <button class="text-center mr" (click)="clearGroup()" mat-raised-button>
      <mat-icon class="primary-color">remove_circle_outline</mat-icon> <span class="primary-color">Clear</span>
    </button>

    <button class="text-center" (click)="checkICDocumentsSearch()" mat-raised-button color="accent">
      {{ actionLabel }}
    </button>
  </div>

  <div class="search-action search-action-button clearfix pull-left" [hidden]="selectedView != 'patient'">
    <button class="text-center mr" (click)="clearPatientGroup()" mat-raised-button>
      <mat-icon class="primary-color">remove_circle_outline</mat-icon> <span class="primary-color">Clear</span>
    </button>

    <button class="text-center" (click)="checkPatientDocumentsClickSearch()" mat-raised-button color="accent">
      {{ actionLabel }}
    </button>
  </div>

  <div class="full-width">
    <button class="ml btn-large pull-right" *ngIf="readonly != true" [disabled]="!documents || documents.length <= 0"
            (click)="SendResult()" mat-raised-button color="accent">
      {{ addTitle }}
    </button>

    <button class="text-center btn-large ml pull-right" *ngIf="
        selectedView != 'selected' &&
        readonly != true &&
        displaySelecteDocuments == true &&
        documents &&
        documents.length > 0
      " (click)="checkSelectedDocument()" mat-raised-button color="primary">
      <mat-icon>visibility</mat-icon> Selected Documents
      <ng-container>({{ documents.length }})</ng-container>
    </button>

    <button class="text-center btn-large ml" *ngIf="selectedView == 'user'"
            [ngClass]="readonly != true ? 'pull-left' : 'pull-right'" (click)="addUserDocument()" mat-raised-button
            color="primary">
      <mat-icon class="smr fas fa-folder-plus"></mat-icon>
      New Document
    </button>
    <button class="text-center btn-large ml" *ngIf="selectedView == 'system' && isAdminOrPromoter == true"
            [ngClass]="readonly != true ? 'pull-left' : 'pull-right'" (click)="addICDocument()" mat-raised-button
            color="primary">
      <mat-icon class="smr fas fa-folder-plus"></mat-icon>
      New Document
    </button>
    <button class="text-center btn-large ml" *ngIf="selectedView == 'patient' && selectedPatient && selectedPatient.ID"
            [ngClass]="readonly != true ? 'pull-left' : 'pull-right'" (click)="addPatientDocument()" mat-raised-button
            color="primary">
      <mat-icon class="smr fas fa-folder-plus"></mat-icon>
      New Document For
      <strong> {{ selectedPatient['firstName'] }}</strong>
    </button>
  </div>
</div>
