<div class="edit-treat-merch mat-card" *ngIf="treatment" @ngIfAnimation>
  <div class="card-header primary-gradient-img clearfix inModal">
    <div class="row clearfix">
      <!-- Modal dismiss button -->
      <button class="btn-close btn-clear mat-button white" *ngIf="isModal == true" (click)="closeModal()" mat-button>
        <mat-icon>clear</mat-icon>
      </button>
      <!-- merchant calculated name -->
      <div class="row titleArea clearfix">
        <app-product-view-logo class="pull-left" [productID]="treatment['ID']"
                               [LastModified]="treatment['LastModified']">
        </app-product-view-logo>

        <h2 class="summary-header rm-m">
          {{ treatment['Type.Label'] }}
          <span class="subLabel">Edit {{ "KEYWORD.treatment" | translate | titlecase }} Item</span>
        </h2>
      </div>
    </div>

    <div class="action-button" *ngIf="false">
      <button class="actionMenuToggle" [matMenuTriggerFor]="btnPatMenu" mat-raised-button>
        Actions <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #btnPatMenu="matMenu">
        <!-- reset fields to EP defaults -->
        <button mat-menu-item color="accent">
          <mat-icon>settings_backup_restore</mat-icon>
          Revert to defaults
        </button>
      </mat-menu>
    </div>
  </div>

  <mat-dialog-content class="includes-buttons" [ngClass]="{ 'no-modal-height': isModal != true }">
    <form #priceServiceForm="ngForm">
      <div class="product-item-view">
        <div class="row clearfix flex">
          <div class="half-width flex" *ngIf="treatment['ID']">
            <!-- product catalogue image -->
            <app-product-view-catalogue class="supplierIcon pull-left" [productID]="treatment['ID']"
                                        [LastModified]="treatment['LastModified']">
            </app-product-view-catalogue>
          </div>

          <div class="half-width">
            <div class="row clearfix">
              <p class="clearfix" [innerHTML]="treatment['Description'] | safeHtml">
                <!-- treatment product desctiption text -->
              </p>
              <hr/>
            </div>

            <div class="row clearfix flex wrapped">
              <div class="block full-width" *ngIf="treatment['Item_Code.Effective']">
                <p class="small rm-m">Item Code</p>
                <h5 class="sr-title reduce-marg" [ngStyle]="{ color: treatment['Category.Colour'] }">
                  {{ treatment['Item_Code.Effective'] }}
                </h5>
              </div>

              <div class="block full-width" *ngIf="treatment['Category.Label']">
                <p class="small rm-m">Category</p>
                <h5 class="sr-title reduce-marg" [ngStyle]="{ color: treatment['Category.Colour'] }">
                  {{ treatment['Category.Label'] }}
                </h5>
              </div>

              <div class="block full-width" *ngIf="treatment['Type.Label']">
                <p class="small rm-m">Type</p>
                <h5 class="sr-title reduce-marg" [ngStyle]="{ color: treatment['Category.Colour'] }">
                  {{ treatment['Type.Label'] }}
                </h5>
              </div>

              <div class="block full-width">
                <div class="row-content clicktext" *ngIf="treatment['Label']" matTooltip="Click to view Brand details">
                  <p class="small rm-m">Brand</p>
                  <h5 class="sr-title reduce-marg" [ngStyle]="{ color: treatment['Category.Colour'] }">
                    {{ treatment['Label'] }}
                  </h5>
                </div>
              </div>
            </div>
            <hr/>
            <div class="row clearfix">
              <mat-slide-toggle class="pull-right" [(ngModel)]="product.isActive" name="treatmentActiveStatus">
                <span *ngIf="product.isActive == true">Product is available</span>
                <span *ngIf="product.isActive == false">Product is not available</span>
              </mat-slide-toggle>
            </div>
          </div>
        </div>
      </div>

      <div class="grey-row clearfix">
        <div class="row clearfix">
          <div class="icons-col">
            <mat-icon class="fas fa-dollar-sign"></mat-icon>
          </div>
          <div class="row-content-col row flex clearfix">
            <!-- display item price -->
            <mat-form-field class="full-width large-input green-text">
              <mat-label> {{"KEYWORD.Treatment" | translate}} Sell Price</mat-label>
              <input class="mat-block" [(ngModel)]="product.sellPrice" (ngModelChange)="calculatePrice()" matInput
                     name="treatmentSellPrice"
                     [placeholder]="('KEYWORD.treatment' | translate | titlecase) + ' Sell Price'"
                     currencyMask required/>
            </mat-form-field>

            <mat-slide-toggle class="pull-right add-mt" [(ngModel)]="useDefault"
                              (ngModelChange)="useDefaultEvent($event)" name="useDefault">
              Use default prices?
            </mat-slide-toggle>
          </div>
        </div>
      </div>

      <mat-accordion class="noBoxShadow mt">
        <mat-expansion-panel class="costs" [expanded]="step === 0" (opened)="setStep(0)">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3 class="sr-title">
                <mat-icon class="fas fa-hand-holding-usd"></mat-icon>
                Show costs
              </h3>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row clearfix">
            <div class="row clearfix">
              <div class="icons-col">
                <mat-icon class="fas fa-donate"></mat-icon>
              </div>
              <div class="row-content-col row clearfix">
                <div class="row clearfix flex">
                  <!-- Set practice cost price -->
                  <mat-form-field class="full-width large-input">
                    <mat-label>Purchase price</mat-label>
                    <input class="mat-block" [(ngModel)]="product.costPrice" (ngModelChange)="calculatePrice()"
                           name="treatmentCostPrice" placeholder="Purchase price" matInput currencyMask required/>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="rel">
              <mat-icon class="iocon fas fa-plus"></mat-icon>
              <mat-divider></mat-divider>
            </div>
            <div class="row clearfix">
              <div class="icons-col">
                <mat-icon class="fas fa-store"></mat-icon>
              </div>
              <div class="row-content-col row clearfix">
                <div class="row clearfix flex">
                  <!-- set overhead percentage - if modified this shows a modal to confirm it will impact other product prices -->
                  <div class="block full-width">
                    <p class="rm-mb">{{"KEYWORD.Practice" | translate}} overhead ($)</p>
                    <h2 class="accent-color rm-mt bold">
                      {{ product.costOverheadPrice | customCurrency }}
                    </h2>
                  </div>
                  <div class="block full-width">
                    <p class="rm-mb">{{"KEYWORD.Practice" | translate}} overhead (%)</p>
                    <h2 class="accent-color rm-mt bold" *ngIf="product.sellPrice > 0">
                      {{ toNumber(product.costOverheadPrice) / toNumber(product.sellPrice) | percent: '2.1-1' }}
                    </h2>
                    <h2 class="accent-color rm-mt bold" *ngIf="product.sellPrice <= 0">
                      {{ 0 | percent: '2.1-1' }}
                    </h2>
                  </div>
                  <div class="block full-width">
                    <button class="pull-right mt" (click)="editOverheadModal()" mat-raised-button color="accent"
                            [matTooltip]="'Update master ' + ('KEYWORD.practice' | translate) + ' overhead'">
                      <mat-icon>settings</mat-icon> Overheads
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="rel">
              <mat-icon class="iocon fas fa-equals"></mat-icon>
              <mat-divider></mat-divider>
            </div>
            <div class="row clearfix">
              <div class="icons-col">
                <mat-icon class="fas fa-wallet"></mat-icon>
              </div>
              <div class="row-content-col row clearfix">
                <!-- display calculated total cost -->
                <p class="rm-mb">Total cost</p>
                <h2 class="accent-color rm-mt bold">{{ product.costTotalPrice | customCurrency }}</h2>
              </div>
            </div>
            <mat-divider></mat-divider>
            <div class="row clearfix">
              <div class="icons-col">
                <mat-icon class="fas fa-coins"></mat-icon>
              </div>
              <div class="row-content-col row clearfix">
                <div class="row clearfix flex">
                  <!-- set overhead percentage - if modified this shows a modal to confirm it will impact other product prices -->
                  <div class="block full-width">
                    <!-- display calculated net profit -->
                    <p class="rm-mb">Gross profit ($)</p>
                    <h2 class="green-text rm-mt bold">{{ product.profitPrice | customCurrency }}</h2>
                  </div>
                  <div class="block full-width">
                    <!-- display calculated net profit -->
                    <p class="rm-mb">Gross profit (%)</p>
                    <h2 class="green-text rm-mt bold" *ngIf="product.sellPrice > 0">
                      {{ toNumber(product.profitPrice) / toNumber(product.sellPrice) | percent: '2.1-1' }}
                    </h2>
                    <h2 class="green-text rm-mt bold" *ngIf="product.sellPrice <= 0">
                      {{ 0 | percent: '2.1-1' }}
                    </h2>
                  </div>
                  <div class="block full-width">&nbsp;</div>
                </div>
              </div>
            </div>
          </div>
        </mat-expansion-panel>

        <!-- <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3 class="sr-title">
                <mat-icon class="fas fa-comments"></mat-icon>{{ "KEYWORD.treatment" | translate | titlecase }} notes
              </h3>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row clearfix">
            <div class="icons-col">
              <mat-icon class="fas fa-info-circle"></mat-icon>
            </div>
            <div class="row-content-col row clearfix diag">

              <div class="row clearfix flex info">
                <label class="full-width">{{ "Category" | translate }}:<br>
                  <strong>
                    {{treatment['Category.Label']}}
                  </strong>
                </label>

                <label class="full-width">{{ "Type" | translate }}:<br>
                  <strong>
                    {{treatment['Type.Label']}}
                  </strong>
                </label>

                <label class="full-width">{{ "Brand" | translate }}:<br>
                  <strong>
                    {{treatment['Label']}}
                  </strong>
                </label>
              </div>
            </div>
          </div>
          <hr class="mt">
          <div class="row clearfix">
            <div class="half-width">
              <div class="col-left">
                <div class="row clearfix">
                  <div class="icons-col">
                    <mat-icon class="fas fa-notes-medical"></mat-icon>
                  </div>
                  <div class="row-content-col row clearfix diag">

                    <mat-form-field class="full-width" appearance="outline">
                      <textarea matInput class="desc-txt" name="diagnosisDescription" placeholder="Category Note"
                        [(ngModel)]="product.categoryNote"></textarea>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
            <div class="half-width">
              <div class="col-right">
                <div class="row clearfix">
                  <div class="icons-col">
                    <mat-icon class="fas {{'fa-tooth' | translate}}"></mat-icon>
                  </div>
                  <div class="row-content-col row clearfix diag">

                    <mat-form-field class="full-width" appearance="outline">
                      <textarea matInput class="desc-txt" name="treatmentDescription" placeholder="Label Note"
                        [(ngModel)]="product.labelNote"></textarea>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </mat-expansion-panel> -->

        <mat-expansion-panel [expanded]="step === 2" (opened)="setStep(2)">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3 class="sr-title">
                <mat-icon class="fas fa-file-medical"></mat-icon>
                Show Included Documents
                <span class="num">{{
                  preDocuments.length + postDocuments.length + marketingDocuments.length + stockArtDocuments.length
                  }}</span>
              </h3>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row clearfix" *ngIf="
              (stockArtDocuments && stockArtDocuments.length > 0) ||
              (preDocuments && preDocuments.length > 0) ||
              (marketingDocuments && marketingDocuments.length > 0) ||
              (postDocuments && postDocuments.length > 0)
            ">
            <div class="clearfix full-width" *ngIf="preDocuments && preDocuments.length > 0">
              <app-ep-document-list [isDownload]="isDownload" [isRedirection]="isRedirection" [isPrint]="isPrint"
                                    [documents]="preDocuments" [title]="'Pre documents'" [canCheck]="true"
                                    [defaultCheck]="false"
                                    [selectedIDs]="preDocumentsEnabled" (getCheckedDocument)="enableDocument($event)"
                                    (getUncheckedDocument)="disableDocument($event)">
              </app-ep-document-list>
            </div>

            <div class="clearfix full-width" *ngIf="postDocuments && postDocuments.length > 0">
              <app-ep-document-list [isDownload]="isDownload" [isRedirection]="isRedirection" [isPrint]="isPrint"
                                    [documents]="postDocuments" [title]="'Post documents'" [canCheck]="true"
                                    [defaultCheck]="false"
                                    [selectedIDs]="postDocumentsEnabled" (getCheckedDocument)="enableDocument($event)"
                                    (getUncheckedDocument)="disableDocument($event)">
              </app-ep-document-list>
            </div>

            <div class="clearfix full-width" *ngIf="marketingDocuments && marketingDocuments.length > 0">
              <app-ep-document-list [isDownload]="isDownload" [isRedirection]="isRedirection" [isPrint]="isPrint"
                                    [documents]="marketingDocuments" [title]="'Marketing documents'" [canCheck]="true"
                                    [defaultCheck]="false" [selectedIDs]="marketingDocumentsEnabled"
                                    (getCheckedDocument)="enableDocument($event)"
                                    (getUncheckedDocument)="disableDocument($event)">
              </app-ep-document-list>
            </div>

            <div class="clearfix full-width" *ngIf="stockArtDocuments && stockArtDocuments.length > 0">
              <app-ep-document-list [isDownload]="isDownload" [isRedirection]="isRedirection" [isPrint]="isPrint"
                                    [documents]="stockArtDocuments"
                                    [title]="('KEYWORD.procedure' | translate | titlecase) +' Gallery'"
                                    [canCheck]="true" [defaultCheck]="false" [selectedIDs]="stockArtDocumentsEnabled"
                                    (getCheckedDocument)="enableDocument($event)"
                                    (getUncheckedDocument)="disableDocument($event)">
              </app-ep-document-list>
            </div>
          </div>
          <div class="row clearfix mt">
            <button class="pull-right" *ngIf="isPromoterOrAdmin == true" (click)="editDocument()" mat-raised-button
                    color="accent">
              Add document
            </button>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </form>

    <mat-divider></mat-divider>

    <div class="row clearfix top-pad saveBtns">
      <!-- save changes -->
      <button class="pull-right" [disabled]="!priceServiceForm.form.valid" (click)="saveClose()" mat-raised-button
              color="primary">
        {{ 'SaveAndClose' | translate }}
      </button>
      <button class="pull-right" [disabled]="!priceServiceForm.form.valid" (click)="save()" mat-raised-button
              color="accent">
        Save
      </button>
    </div>
  </mat-dialog-content>
</div>
