import { Routes } from '@angular/router';
import { TranslationPage } from '../../translation-management-create/translation-management-create.component';
const ɵ0 = { title: 'Translation Management' };
const routes = [
    {
        path: 'translation-management',
        component: TranslationPage,
        data: ɵ0,
    },
];
export class TranslationManagementRoutingModule {
}
export { ɵ0 };
