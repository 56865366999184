import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSortModule } from '@angular/material/sort';
import { QuillModule } from 'ngx-quill';
import { SharedModule } from '../../shared/shared.module';
import { DefaultMessageModule } from '../default-message/default-message.module';
import { MerchantProspectModule } from '../merchant-prospect/merchant-prospect.module';
import { DiscountSettingComponent } from '../merchant/discount-setting/discount-setting.component';
import { OperatorModule } from '../operator/operator.module';
import { TreatmentModule } from '../treatment/treatment.module';
import { InvitationTemplateCreateEditComponent } from './invitation-template-create-edit/invitation-template-create-edit.component';
import { InvitationTemplateListOverviewComponent } from './invitation-template-list-overview/invitation-template-list-overview.component';
import { InvitationTemplateListComponent } from './invitation-template-list/invitation-template-list.component';
import { InvitationTemplateViewComponent } from './invitation-template-view/invitation-template-view.component';
import { InvitationTemplateRoutingModule } from './shared/invitation-template-routing.module';
import { InvitationTemplateService } from './shared/invitation-template.service';
import { TreatmentSaveModalComponent } from './treatment-save-modal/treatment-save-modal.component';

import { ArchwizardModule } from 'angular-archwizard';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgPipesModule } from 'ngx-pipes';
import { DentistModule } from '../dentist/dentist.module';
import { TagsModule } from '../tags/tags.module';
import { InvTreatBeforeStartingComponent } from './before-starting/before-starting.component';
import { InvitationTemplateCloneComponent } from './invitation-template-clone/invitation-template-clone.component';
import { InvitationTemplateLookupComponent } from './invitation-template-lookup/invitation-template-lookup.component';
import { InvitationTemplateMergerComponent } from './invitation-template-merger/invitation-template-merger.component';
import { InvitationTemplateThirdPartyComponent } from './invitation-template-third-party/invitation-template-third-party.component';

@NgModule({
  imports: [
    NgPipesModule,
    DentistModule,
    CommonModule,
    InvitationTemplateRoutingModule,
    CdkTableModule,
    QuillModule,
    SharedModule,
    TreatmentModule,
    NgxPaginationModule,
    ArchwizardModule,

    OperatorModule,
    MatSortModule,
    MerchantProspectModule,
    DefaultMessageModule,
    TagsModule,
  ],
  declarations: [
    InvitationTemplateCreateEditComponent,
    InvitationTemplateListComponent,
    InvitationTemplateLookupComponent,
    InvitationTemplateMergerComponent,
    InvitationTemplateListOverviewComponent,
    InvitationTemplateViewComponent,
    DiscountSettingComponent,
    InvitationTemplateCloneComponent,
    TreatmentSaveModalComponent,
    InvTreatBeforeStartingComponent,
    InvitationTemplateThirdPartyComponent,
  ],
  exports: [
    InvitationTemplateCreateEditComponent,
    InvitationTemplateListComponent,
    InvitationTemplateMergerComponent,
    InvitationTemplateLookupComponent,
    DiscountSettingComponent,
    InvitationTemplateListOverviewComponent,
    InvitationTemplateViewComponent,
    InvitationTemplateCloneComponent,
    TreatmentSaveModalComponent,
    InvTreatBeforeStartingComponent,
    InvitationTemplateThirdPartyComponent,
  ],
  providers: [InvitationTemplateService],
})
export class InvitationTemplateModule {}
