import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { MerchantService } from '../../merchant/shared/merchant.service';

@Component({
  selector: 'app-treatment-practice-overhead-edit',
  templateUrl: './treatment-practice-overhead-edit.component.html',
  styleUrls: ['./treatment-practice-overhead-edit.component.css'],
})
export class TreatmentPracticeOverheadEditComponent implements OnInit {
  @Output()
  close = new EventEmitter();

  @Output()
  getOverheads = new EventEmitter();

  @Output()
  getPractice = new EventEmitter();

  @Input()
  merchantID;

  isPromoterOrAdmin = false;

  useDefault = false;

  practice;

  overheads = 0;

  profileLink;
  sessionType;

  constructor(
    private authenticationService: AuthenticationService,
    private merchantService: MerchantService,
    private dialog: MatDialog,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.merchantID) {
        this.merchantID = data.merchantID;
      }
    }
  }

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((sessionType) => {
      this.sessionType = sessionType;

      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isPromoterOrAdmin = res;

        if (this.isPromoterOrAdmin == true && this.merchantID) {
          const p = {
            fields: 'ID,CalculatedName,TradingAs,OverheadPercentage.Overridden,OverheadPercentage.Effective',
          };

          this.merchantService.getMerchantDetails(this.merchantID, p, this.sessionType).subscribe((res) => {
            if (res) {
              this.practice = res;

              this.setup();
            }
          });
        } else {
          this.authenticationService.getCurrentPractice().subscribe((res) => {
            if (res) {
              this.practice = res;
              this.setup();
            }
          });
        }
      });
    });
  }

  setup() {
    if (this.practice) {
      if (this.practice && this.practice['OverheadPercentage.Effective']) {
        this.overheads = Number(this.practice['OverheadPercentage.Effective']);
      }

      if (
        this.practice &&
        this.practice['OverheadPercentage.Overridden'] &&
        Number(this.practice['OverheadPercentage.Overridden']) < 0
      ) {
        this.useDefault = true;
      }

      this.getPicture();
    }
  }

  save() {
    const confirm = new ConfirmDialog(
      'fas fa-info',
      'Changing the Overheads value will affect all your prices ',
      'Are you sure you want to Proceed ?',
      'No',
      'Yes'
    );

    const ref = this.dialog.open(ConfirmDialogComponent, {
      data: confirm,
    });
    ref.componentInstance.onConfirm.subscribe((confirmation) => {
      if (confirmation === false) {
        ref.close();
      } else {
        ref.close();

        const p = {
          overheads: this.overheads,
          userDefaultOverheads: this.useDefault,
        };
        if (this.isPromoterOrAdmin == true && this.merchantID) {
          this.merchantService.editMerchant(this.merchantID, p).subscribe((res) => {
            if (res) {
              NotifyAppComponent.displayToast('success', 'Successful operation', 'The Practice Overheads is updated');

              this.practice = res;

              this.getOverheads.emit(Number(this.practice['OverheadPercentage.Effective']));
              this.getPractice.emit(this.practice);
            }
          });
        } else {
          this.authenticationService.editCurrentPractice(p).subscribe((res) => {
            if (res) {
              NotifyAppComponent.displayToast('success', 'Successful operation', 'The Practice Overheads is updated');

              this.practice = res;

              this.getOverheads.emit(Number(this.practice['OverheadPercentage.Effective']));
              this.getPractice.emit(this.practice);
            }
          });
        }
      }
    });
  }

  getPicture() {
    this.profileLink = null;

    if (this.practice) {
      this.profileLink = this.merchantService.getPracticePicStreamLink(this.practice['ID']);
    }
  }
}
