// docs: https://github.com/MurhafSousli/ngx-sharebuttons/blob/v7.1.3/README_V5.md

import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { ClipboardService } from 'ngx-clipboard';
import { ConfirmDialog } from '../../types/confirm-dialog';
import { NotifyAppComponent } from '../../types/notify-app-component';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../root-component/root-component.component';
import { SocialShareButtonBrand } from './types/social-share-button-brand.type';

@Component({
  selector: 'app-social-share-button',
  templateUrl: './social-share-button.component.html',
  styleUrls: ['./social-share-button.component.css'],
})
export class SocialShareButtonComponent {
  @Input() type: SocialShareButtonBrand = 'telegram';
  @Input() label = 'Facebook';

  @Input() url: string;
  @Input() title: string;

  @Input() image: string;
  @Input() post: string;

  @Input() tags: string[] = [];

  @Input() copy = true;
  @Input() autoSetMeta = true;

  @Input() isConfirmModal = true;

  @Output() success = new EventEmitter();

  @Output() opened = new EventEmitter();
  @Output() closed = new EventEmitter();

  @Input() icon = 'default';
  @Input() isDisabled = false;
  _image;
  _post;
  _url;
  _title;

  _tags = [];

  constructor(private _clipboardService: ClipboardService) {}

  ngOnInit() {
    this.setup();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.setup();
  }

  setup() {
    if (this.icon == 'default') {
      if (this.type === 'facebook') {
        this.icon = 'fab fa-facebook';
      } else if (this.type === 'linkedin') {
        this.icon = 'fab fa-linkedin';
      } else if (this.type === 'telegram') {
        this.icon = 'fab fa-telegram';
      } else if (this.type === 'twitter') {
        this.icon = 'fab fa-twitter';
      } else if (this.type === 'pinterest') {
        this.icon = 'fab fa-pinterest';
      }
    }

    this.buildPost();
  }

  buildPost() {
    this._post = '';
    this._tags = [];

    if (this.type === 'pinterest') {
      if (this.post) {
        this._post = this.post;

        if (this.url) {
          this._post = this._post + '\n' + this.url;
        }
      } else if (this.url) {
        this._post = this.url;
      }
    } else {
      if (this.post) {
        this._post = this.post;
      } else if (this.url) {
        this._post = this.url;
      }
    }
    if (this.url) {
      this._url = this.url;
    }

    if (this.image) {
      this._image = this.image;
    }

    if (this._title) {
      this._title = this.title;
    }

    if (this.tags && this.tags.length > 0) {
      for (let i = 0; i < this.tags.length; i++) {
        if (this.tags[i]) {
          this._tags.push(this.tags[i].replace(/ /g, '_'));
        }
      }
    }
  }

  shareWindowClosed() {
    if (this.isConfirmModal) {
      const confirmDialogParams = {
        data: new ConfirmDialog(
          'fas fa-info',
          'Confirm Share',
          '<p>Did you successfully share the Post  ?</p>',
          'No',
          'Yes'
        ),
      };
      const confirmDialogRef = RootAppComponent.dialog.open(ConfirmDialogComponent, confirmDialogParams);
      confirmDialogRef.componentInstance.onConfirm.subscribe((result) => {
        if (result === true) {
          this.success.emit();
        }
        confirmDialogRef.close();
      });
    } else {
      this.success.emit();
    }

    this.closed.emit();
  }

  clickCopy() {
    if (this.copy == true) {
      let post = '';

      if (this.post) {
        post = this.post;
      } else if (this.url) {
        post = this.url;
      }

      this._clipboardService.copy(post);
      NotifyAppComponent.displayToast('success', 'Text copied', 'The post text has been copied to your clipboard');
    }
  }

  openEvent() {
    this.opened.emit(true);
    this.clickCopy();
  }
}
