<div>
  <div class="main-banner blue-banner"></div>
  <ng-container *ngIf="page == 1">
    <section class="step1" id="step1">
      <div class="top flex container">
        <div class="top-leftbox">
          <div class="top-txt" @ngIfAnimation data-aos="fade-right" data-aos-delay="400" data-aos-once="true">
            <h1 class="getting-started-page">Everything you need to go digital</h1>
            <p class="getting-started-page">
              Welcome to the first step on your journey to growing your {{ "KEYWORD.practice" | translate }}! This page
              will help you and your team with everything they need to start communicating digitally.
            </p>
          </div>
        </div>
        <div class="top-rightbox" @ngIfAnimation data-aos="fade-left" data-aos-delay="400" data-aos-once="true">
          <div class="imgbox">
            <img class="img"
                 src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/getting-started/Asset_6.png"/>
          </div>
        </div>
      </div>
      <div class="main container">
        <div class="title" @ngIfAnimation data-aos="fade-up" data-aos-delay="400" data-aos-once="true">
          <h2 class="getting-started-page">Getting Started</h2>
          <p class="getting-started-page">
            A new platform can be overwhelming - there are so many new features to explore and you aren't sure where to
            start. Taking those first few steps can be daunting, but the hard part is over! Decisions like these are
            just one of many towards creating a successful {{ "KEYWORD.practice" | translate }}. Choose a step to get
            started.
          </p>
        </div>
        <div class="intro flex">
          <div class="intro-fi">
            <div class="intro-con" @ngIfAnimation data-aos="fade-right" data-aos-delay="400" data-aos-once="true">
              <div class="intro-numbox" (click)="pageS(2)">
                <div class="intro-num">
                  <h2 class="getting-started-page">1</h2>
                </div>
              </div>
              <div class="intro-txt">
                <h4 class="getting-started-page">Review Your Profile</h4>
              </div>
            </div>
          </div>
          <div class="intro-se">
            <div class="intro-con" @ngIfAnimation data-aos="fade-right" data-aos-delay="400" data-aos-once="true">
              <div class="intro-numbox" (click)="pageS(3)">
                <div class="intro-num">
                  <h2 class="getting-started-page">2</h2>
                </div>
              </div>
              <div class="intro-txt">
                <h4 class="getting-started-page">Sending {{"PHRASE.treatment plan" | translate | titlecase}}s</h4>
              </div>
            </div>
          </div>
          <div class="intro-th">
            <div class="intro-con" @ngIfAnimation data-aos="fade-right" data-aos-delay="400" data-aos-once="true">
              <div class="intro-numbox" (click)="pageS(4)">
                <div class="intro-num">
                  <h2 class="getting-started-page">3</h2>
                </div>
              </div>
              <div class="intro-txt">
                <h4 class="getting-started-page">Getting Paid</h4>
              </div>
            </div>
          </div>
        </div>
        <div class="intro-bot" @ngIfAnimation data-aos="fade-up" data-aos-delay="400" data-aos-once="true">
          <p class="getting-started-page">
            <span class="strong">Important</span> - The {{ "KEYWORD.practice" | translate }} manager should have your
            portal set up and ready to go with all the
            correct {{"PARAGRAPH.Core Getting Started Settings and More" | translate}}. If not, be sure
            to mention it to your {{ "KEYWORD.practice" | translate }} manager or principal
            {{ "KEYWORD.practitioner" | translate }} to confirm this is done. Alternatively, click on the button below
            to follow the setup instructions.
          </p>
          <button class="btn" (click)="pageS(2)" mat-stroked-button>
            Portal Manager Setup <mat-icon>chevron_right</mat-icon>
          </button>
        </div>
      </div>
      <div class="guid flex">
        <div class="container guid-main" @ngIfAnimation data-aos="fade-right" data-aos-delay="400" data-aos-once="true">
          <div class="half-width img-box-left">
            <div class="guid-imgbox">
              <img class="img"
                   src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/getting-started/Asset_7.png"/>
            </div>
          </div>
          <div class="half-width txt-box">
            <div class="txt" @ngIfAnimation data-aos="fade-left" data-aos-delay="400" data-aos-once="true">
              <h2 class="getting-started-page">Read Me!</h2>
              <p class="getting-started-page">
                We've created a detailed User Guide to help take you through each feature of your
                {{ "KEYWORD.practice" | translate }} portal. With insights and helpful links to articles, you will be a
                portal pro in no time!
              </p>
              <button class="btn" (click)="openNewTab()" mat-stroked-button>
                Download User Guide <mat-icon>chevron_right</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="visit flex white-background">
        <div class="container visit-main">
          <div class="visit-imgbox" @ngIfAnimation data-aos="fade-right" data-aos-delay="400" data-aos-once="true">
            <img class="img"
                 src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/getting-started/Asset_8.png"/>
          </div>
          <div class="visit-text" @ngIfAnimation data-aos="fade-left" data-aos-delay="400" data-aos-once="true">
            <p class="getting-started-page">
              Need some more detailed help? Visit the help centre located in your portal for access to easy to follow
              how-to articles
            </p>
          </div>
          <button class="btn" @ngIfAnimation (click)="openNewTab1()" mat-stroked-button data-aos="fade-up"
                  data-aos-delay="400" data-aos-once="true">
            View Help Centre <mat-icon>chevron_right</mat-icon>
          </button>
        </div>
      </div>
      <div class="book flex">
        <div class="container book-main">
          <div class="book-title">
            <h2 class="getting-started-page">Still need help? Get in touch!</h2>
          </div>
          <div class="book-contact">
            <p class="getting-started-page">Email: {{ practiceAddress }}</p>
            <p class="getting-started-page">Phone: {{ practiceNumber }}</p>
            <p class="getting-started-page">{{"BRANDING.Operating Hours AEST" | translate}}</p>
          </div>
          <div class="book-bot">
            <p class="getting-started-page">
              Alternatively, you can book in some training with our experts. Training is
              <span class="strong">FREE</span> with unlimited access to courses that will help you and your team become
              portal pros!
            </p>
          </div>
          <button class="btn" (click)="openNewTab2()" mat-stroked-button>
            Book Training <mat-icon>chevron_right</mat-icon>
          </button>
        </div>
      </div>
    </section>
  </ng-container>
  <ng-container *ngIf="page == 2">
    <section class="step2" id="step2">
      <div class="top flex container">
        <div class="top-leftbox">
          <div class="top-txt" @ngIfAnimation data-aos="fade-right" data-aos-delay="400" data-aos-once="true">
            <h1 class="getting-started-page">Review your profile</h1>
            <p class="getting-started-page">
              Personalise your profile so {{ "KEYWORD.patient" | translate }}s and team members see you in the best
              possible light
            </p>
          </div>
        </div>
        <div class="top-rightbox" @ngIfAnimation data-aos="fade-left" data-aos-delay="400" data-aos-once="true">
          <div class="imgbox">
            <img class="img"
                 src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/getting-started/Asset_2.png"/>
          </div>
        </div>
      </div>
      <div class="main container">
        <div class="title" @ngIfAnimation data-aos="fade-up" data-aos-delay="400" data-aos-once="true">
          <h2 class="getting-started-page">Settings</h2>
          <p class="getting-started-page">
            Just logged into your portal for the first time? Your screen should like something like this.
          </p>
        </div>
        <div class="step2-imgbox">
          <img class="img"
               src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/getting-started/Asset_9.png"/>
        </div>
        <div class="step2-txt">
          <p class="getting-started-page">
            Like we mentioned preiviously, your {{ "KEYWORD.practice" | translate }} manager or principal
            {{ "KEYWORD.practitioner" | translate }} should have your portal settings filled out. All that is left for
            you to do is fill out your personal details. Let’s start!
          </p>
          <p class="getting-started-page">
            Click on your name in the top right-hand corner and go to 'my profile'. Here is a helpful checklist along
            with how-to articles with detailed instructions to get you on the right track:
          </p>
        </div>
        <div class="artic">
          <div class="art-con">
            <div class="art-icon">
              <mat-icon class="icon-done"><span class="material-icons-outlined">done</span></mat-icon>
            </div>
            <div class="art-txt">
              <p class="getting-started-page">
                <span class="strong">Bookmark Portal</span> - You should bookmark the portal landing page for easy
                access when logging in.
              </p>
            </div>
            <div class="art-art">
              <a target="_blank"
                 href="https://app.smileright.com.au/wiki/(wikiPageRoute:view-code/setting-up-your-profile">
                <mat-icon class="icon-art"><span class="material-icons-outlined">list_alt</span></mat-icon>
              </a>
            </div>
          </div>
          <div class="art-con">
            <div class="art-icon">
              <mat-icon class="icon-done"><span class="material-icons-outlined">done</span></mat-icon>
            </div>
            <div class="art-txt">
              <p class="getting-started-page">
                <span class="strong">Contact Details</span> - Add/confirm your best contact details, your name, and your
                position in the {{ "KEYWORD.practice" | translate }}.
              </p>
            </div>
            <div class="art-art">
              <a target="_blank"
                 href="https://app.smileright.com.au/wiki/(wikiPageRoute:view-code/setting-up-your-profile">
                <mat-icon class="icon-art"><span class="material-icons-outlined">list_alt</span></mat-icon>
              </a>
            </div>
          </div>
          <div class="art-con">
            <div class="art-icon">
              <mat-icon class="icon-done"><span class="material-icons-outlined">done</span></mat-icon>
            </div>
            <div class="art-txt">
              <p class="getting-started-page">
                <span class="strong">Personal Description</span> - Your personal profile helps your team members and
                {{ "KEYWORD.patient" | translate }}s identify you within your {{ "KEYWORD.practice" | translate }}. It's
                also a way for people to get to know you - tell a fun story about yourself or about your dreams.
              </p>
            </div>
            <div class="art-art">
              <a target="_blank"
                 href="https://app.smileright.com.au/wiki/(wikiPageRoute:view-code/setting-up-your-profile">
                <mat-icon class="icon-art"><span class="material-icons-outlined">list_alt</span></mat-icon>
              </a>
            </div>
          </div>
          <div class="art-con">
            <div class="art-icon">
              <mat-icon class="icon-done"><span class="material-icons-outlined">done</span></mat-icon>
            </div>
            <div class="art-txt">
              <p class="getting-started-page">
                <span class="strong">Profile Photo</span> - Upload a clear picture of yourself. Remember you are
                representing the {{ "KEYWORD.practice" | translate }} and {{ "KEYWORD.patient" | translate }}s will be
                able to see this, so try to stick =on the profiessional side.
              </p>
            </div>
            <div class="art-art">
              <a target="_blank"
                 href="https://app.smileright.com.au/wiki/(wikiPageRoute:view-code/setting-up-your-profile">
                <mat-icon class="icon-art"><span class="material-icons-outlined">list_alt</span></mat-icon>
              </a>
            </div>
          </div>
          <div class="art-con">
            <div class="art-icon">
              <mat-icon class="icon-done"><span class="material-icons-outlined">done</span></mat-icon>
            </div>
            <div class="art-txt">
              <p class="getting-started-page">
                <span class="strong">Social Media Links</span> - If you have any socials you want to share, maybe a
                LinkedIn page, connect it with an easy link.
              </p>
            </div>
            <div class="art-art">
              <a target="_blank"
                 href="https://app.smileright.com.au/wiki/(wikiPageRoute:view-code/setting-up-your-profile">
                <mat-icon class="icon-art"><span class="material-icons-outlined">list_alt</span></mat-icon>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="step2-bot">
        <div class="left-btn">
          <button class="btn" (click)="pageS(1)" mat-stroked-button>
            <mat-icon>chevron_left</mat-icon>
            Start
          </button>
        </div>
        <div class="right-btn">
          <button class="btn" (click)="pageS(3)" mat-stroked-button>
            Step Two
            <mat-icon>chevron_right</mat-icon>
          </button>
        </div>
      </div>
    </section>
  </ng-container>
  <ng-container *ngIf="page == 3">
    <section class="step3">
      <div class="top flex container">
        <div class="s3-top-leftbox top-leftbox">
          <div class="s3-top-txt top-txt" @ngIfAnimation data-aos="fade-right" data-aos-delay="400"
               data-aos-once="true">
            <h1 class="getting-started-page">Marketing Your {{ "KEYWORD.practice" | translate | titlecase}}</h1>
            <p class="getting-started-page">
              Capture {{ "KEYWORD.patient" | translate }}s attention with professionally designed marketing emails and
              social media posts, all made to promote your {{ "KEYWORD.practice" | translate }}!
            </p>
          </div>
        </div>
        <div class="s3-top-rightbox top-rightbox" @ngIfAnimation data-aos="fade-left" data-aos-delay="400"
             data-aos-once="true">
          <div class="s3-top-img imgbox">
            <img class="img"
                 src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/getting-started/Asset_17.png"/>
          </div>
        </div>
      </div>
      <div class="main container">
        <div class="title" @ngIfAnimation data-aos="fade-up" data-aos-delay="400" data-aos-once="true">
          <h2 class="getting-started-page">What do I need to do for my {{ "KEYWORD.patient" | translate }}s?</h2>
          <p class="getting-started-page">
            Marketing informs your {{ "KEYWORD.patient" | translate }}s about your
            {{ "KEYWORD.practice" | translate }} and the products and services you offer. It engages your
            {{ "KEYWORD.patient" | translate }}s and promotes communication; social media is a great example of a
            marketing tool that helps you to connect with your {{ "KEYWORD.patient" | translate }}s.
          </p>
        </div>
        <div class="step2-txt">
          <h3 class="getting-started-page">Email and Social Media</h3>
          <p class="getting-started-page">
            The marketing tab is where you can see a complete overview of your marketing in one place. Visit the
            template gallery for curated and branded content on all different
            suppliers, {{ "KEYWORD.procedure" | translate }}s and products. Choose
            between email and social media templated content to create personalised communication to your
            {{ "KEYWORD.patient" | translate }}s in minutes.
          </p>
          <p class="getting-started-page">
            Whether it’s general information you want to communicate, or promoting a
            new product/{{ 'KEYWORD.procedure' | translate }}, we are
            constantly adding more templates for you to use.
          </p>
        </div>
        <div class="artic">
          <h2 class="getting-started-page">Email Campaigns</h2>
          <div class="art-con">
            <div class="art-icon">
              <mat-icon class="icon-done"><span class="material-icons-outlined">done</span></mat-icon>
            </div>
            <div class="art-txt">
              <p class="getting-started-page">
                <span class="strong">Choose Template</span> - Go to the marketing tab > email campaigns > then choose a
                template from the gallery available to your {{ "KEYWORD.practice" | translate }}. You can preview emails
                and their landing pages by pressing the red ‘preview’ button in the top right hand corner of each
                template.
              </p>
            </div>
            <div class="art-art">
              <a target="_blank"
                 href="https://app.smileright.com.au/wiki/(wikiPageRoute:view-code/setting-up-your-profile">
                <mat-icon class="icon-art"><span class="material-icons-outlined">list_alt</span></mat-icon>
              </a>
            </div>
          </div>
          <div class="art-con">
            <div class="art-icon">
              <mat-icon class="icon-done"><span class="material-icons-outlined">done</span></mat-icon>
            </div>
            <div class="art-txt">
              <p class="getting-started-page">
                <span class="strong">Customise Audience</span> - Who do you want to receive this email? You can choose
                to send to all {{ "KEYWORD.patient" | translate }}s, or define them depending on what criteria they fit.
                Maybe they are an ideal case for clear aligners or a bleach.
              </p>
            </div>
            <div class="art-art">
              <a target="_blank"
                 href="https://app.smileright.com.au/wiki/(wikiPageRoute:view-code/setting-up-your-profile">
                <mat-icon class="icon-art"><span class="material-icons-outlined">list_alt</span></mat-icon>
              </a>
            </div>
          </div>
          <div class="art-con">
            <div class="art-icon">
              <mat-icon class="icon-done"><span class="material-icons-outlined">done</span></mat-icon>
            </div>
            <div class="art-txt">
              <p class="getting-started-page">
                <span class="strong">Campaign Details</span> - Define the campaign
                details; {{ "KEYWORD.treatment" | translate }} price, product
                group, and more. Be sure to double check everything before you press send.
              </p>
            </div>
            <div class="art-art">
              <a target="_blank"
                 href="https://app.smileright.com.au/wiki/(wikiPageRoute:view-code/setting-up-your-profile">
                <mat-icon class="icon-art"><span class="material-icons-outlined">list_alt</span></mat-icon>
              </a>
            </div>
          </div>
          <div class="art-con">
            <div class="art-icon">
              <mat-icon class="icon-done"><span class="material-icons-outlined">done</span></mat-icon>
            </div>
            <div class="art-txt">
              <p class="getting-started-page">
                <span class="strong">Tracking Performance</span> - Check the performance of your campaigns in the
                marketing overview tab. Here you can see information such as open rate, bounce rate, and reach out to
                people who have asked for more information.
              </p>
            </div>
            <div class="art-art">
              <a target="_blank"
                 href="https://app.smileright.com.au/wiki/(wikiPageRoute:view-code/setting-up-your-profile">
                <mat-icon class="icon-art"><span class="material-icons-outlined">list_alt</span></mat-icon>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="step-mid flex">
        <div class="container mid-main">
          <div class="step3-imgbox">
            <img class="img"
                 src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/getting-started/Asset_16.png"/>
          </div>
          <div class="artic">
            <h2 class="getting-started-page">Social Media Campaigns</h2>
            <div class="art-con">
              <div class="art-icon">
                <mat-icon class="icon-done"><span class="material-icons-outlined">done</span></mat-icon>
              </div>
              <div class="art-txt">
                <p class="getting-started-page">
                  <span class="strong">Choose Template</span> - Go to the marketing tab > social media campaigns > then
                  choose a template from the gallery available to your {{ "KEYWORD.practice" | translate }}. You can
                  preview posts and their matching emails by pressing the red ‘preview’ button in the top right hand
                  corner of each template.
                </p>
              </div>
              <div class="art-art">
                <a target="_blank"
                   href="https://app.smileright.com.au/wiki/(wikiPageRoute:view-code/setting-up-your-profile">
                  <mat-icon class="icon-art"><span class="material-icons-outlined">list_alt</span></mat-icon>
                </a>
              </div>
            </div>
            <div class="art-con">
              <div class="art-icon">
                <mat-icon class="icon-done"><span class="material-icons-outlined">done</span></mat-icon>
              </div>
              <div class="art-txt">
                <p class="getting-started-page">
                  <span class="strong">Download Content</span> - Choose your social media platform and then download the
                  pre-made content for you to post. Don’t see a brand you offer? Let us know!
                </p>
              </div>
              <div class="art-art">
                <a target="_blank"
                   href="https://app.smileright.com.au/wiki/(wikiPageRoute:view-code/setting-up-your-profile">
                  <mat-icon class="icon-art"><span class="material-icons-outlined">list_alt</span></mat-icon>
                </a>
              </div>
            </div>
            <div class="art-con">
              <div class="art-icon">
                <mat-icon class="icon-done"><span class="material-icons-outlined">done</span></mat-icon>
              </div>
              <div class="art-txt">
                <p class="getting-started-page">
                  <span class="strong">Post</span> - Upload to your chosen platform, write your text and post!
                </p>
              </div>
              <div class="art-art">
                <a target="_blank"
                   href="https://app.smileright.com.au/wiki/(wikiPageRoute:view-code/setting-up-your-profile">
                  <mat-icon class="icon-art"><span class="material-icons-outlined">list_alt</span></mat-icon>
                </a>
              </div>
            </div>
            <div class="art-con">
              <div class="art-icon">
                <mat-icon class="icon-done"><span class="material-icons-outlined">done</span></mat-icon>
              </div>
              <div class="art-txt">
                <p class="getting-started-page">
                  <span class="strong">Add {{ "BRANDING.Brand Name" | translate | titlecase }} To Your Website</span> -
                  Either use
                  our snippet of code to add to
                  website or use the pre-build website and QR code.
                </p>
              </div>
              <div class="art-art">
                <a target="_blank"
                   href="https://app.smileright.com.au/wiki/(wikiPageRoute:view-code/setting-up-your-profile">
                  <mat-icon class="icon-art"><span class="material-icons-outlined">list_alt</span></mat-icon>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bot">
        <div class="step2-bot">
          <div class="left-btn">
            <button class="btn" (click)="pageS(2)" mat-stroked-button>
              <mat-icon>chevron_left</mat-icon> Step One
            </button>
          </div>
          <div class="right-btn">
            <button class="btn" (click)="pageS(4)" mat-stroked-button>
              Step Three <mat-icon>chevron_right</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </section>
  </ng-container>
  <ng-container *ngIf="page == 4">
    <section class="step4">
      <div class="top flex container">
        <div class="s3-top-leftbox top-leftbox">
          <div class="s3-top-txt top-txt" @ngIfAnimation data-aos="fade-right" data-aos-delay="400"
               data-aos-once="true">
            <h1 class="getting-started-page">Tracking Performance</h1>
            <p class="getting-started-page">
              You’ve done the hard work, now see how well it went by tracking marketing and
              {{ "KEYWORD.practice" | translate }} performance.
            </p>
          </div>
        </div>
        <div class="s3-top-rightbox top-rightbox" @ngIfAnimation data-aos="fade-left" data-aos-delay="400"
             data-aos-once="true">
          <div class="s4-imgbox imgbox">
            <img class="img"
                 src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/getting-started/Asset_15.png"/>
          </div>
        </div>
      </div>
      <div class="main container">
        <div class="step2-txt s4-step2">
          <p class="getting-started-page">
            It’s important to keep track of what is and isn’t working for your {{ "KEYWORD.practice" | translate }}. We
            have made it easy to understand your performance data and learn from the positives and negatives with chart
            breakdowns and colour coded graphics
          </p>
          <p class="getting-started-page">Here is an easy to follow checklist to get you started:</p>
        </div>
        <div class="artic">
          <div class="art-con">
            <div class="art-icon">
              <mat-icon class="icon-done"><span class="material-icons-outlined">done</span></mat-icon>
            </div>
            <div class="art-txt">
              <p class="getting-started-page">
                <span class="strong">Choose Template</span> - Go to the marketing tab > social media campaigns > then
                choose a template from the gallery available to your {{ "KEYWORD.practice" | translate }}. You can
                preview posts and their matching emails by pressing the red ‘preview’ button in the top right hand
                corner of each template.
              </p>
            </div>
            <div class="art-art">
              <a target="_blank"
                 href="https://app.smileright.com.au/wiki/(wikiPageRoute:view-code/setting-up-your-profile">
                <mat-icon class="icon-art"><span class="material-icons-outlined">list_alt</span></mat-icon>
              </a>
            </div>
          </div>
          <div class="art-con">
            <div class="art-icon">
              <mat-icon class="icon-done"><span class="material-icons-outlined">done</span></mat-icon>
            </div>
            <div class="art-txt">
              <p class="getting-started-page">
                <span class="strong">Download Content</span> - Choose your social media platform and then download the
                pre-made content for you to post. Don’t see a brand you offer? Let us know!
              </p>
            </div>
            <div class="art-art">
              <a target="_blank"
                 href="https://app.smileright.com.au/wiki/(wikiPageRoute:view-code/setting-up-your-profile">
                <mat-icon class="icon-art"><span class="material-icons-outlined">list_alt</span></mat-icon>
              </a>
            </div>
          </div>
          <div class="art-con">
            <div class="art-icon">
              <mat-icon class="icon-done"><span class="material-icons-outlined">done</span></mat-icon>
            </div>
            <div class="art-txt">
              <p class="getting-started-page">
                <span class="strong">Post</span> - Upload to your chosen platform, write your text and post!
              </p>
            </div>
            <div class="art-art">
              <a target="_blank"
                 href="https://app.smileright.com.au/wiki/(wikiPageRoute:view-code/setting-up-your-profile">
                <mat-icon class="icon-art"><span class="material-icons-outlined">list_alt</span></mat-icon>
              </a>
            </div>
          </div>
          <div class="art-con">
            <div class="art-icon">
              <mat-icon class="icon-done"><span class="material-icons-outlined">done</span></mat-icon>
            </div>
            <div class="art-txt">
              <p class="getting-started-page">
                <span class="strong">Add {{ "BRANDING.Brand Name" | translate | titlecase }} To Your Website</span> -
                Either use our
                snippet of code to add to
                website or use the pre-build website and QR code.
              </p>
            </div>
            <div class="art-art">
              <a target="_blank"
                 href="https://app.smileright.com.au/wiki/(wikiPageRoute:view-code/setting-up-your-profile">
                <mat-icon class="icon-art"><span class="material-icons-outlined">list_alt</span></mat-icon>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="bot">
        <div class="step2-bot">
          <div class="left-btn">
            <button class="btn" (click)="pageS(3)" mat-stroked-button>
              <mat-icon>chevron_left</mat-icon> Step Two
            </button>
          </div>
          <div class="right-btn">
            <button class="btn" [routerLink]="['/merchant/', { outlets: { page: ['getting-started-home'] } }]"
                    mat-stroked-button>
              Back to Start <mat-icon>chevron_right</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </section>
  </ng-container>
</div>
