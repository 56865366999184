import { UpperCasePipe } from '@angular/common';
import { Component, EventEmitter, Inject, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Observable, of, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthenticationService } from '../../../../core/authentication/shared/authentication.service';
import { NotifyAppComponent } from '../../../../shared/types/notify-app-component';
import { GoogleReviewsService } from '../../shared/google-reviews.service';
import { GmbAccount, GmbLocation } from '../../shared/types/gmb.type';

@Component({
  selector: 'sr-google-reviews-business-settings-dialog',
  templateUrl: './google-reviews-business-settings-dialog.component.html',
  styleUrls: ['./google-reviews-business-settings-dialog.component.css'],
  providers: [UpperCasePipe],
})
export class GoogleReviewsBusinessSettingsDialogComponent implements OnInit, OnDestroy {
  @Output() authenticationExpired: EventEmitter<any> = new EventEmitter();

  private subscriptions = new Subscription();

  isServiceReady = false;

  merchantID: string;
  merchant;

  selectAccountLabel = 'Select Account';

  displayedBusinessAccountColumns: string[] = ['accountName', 'type'];
  businessAccounts$: Observable<GmbAccount[]> = of([]);
  selectedBusinessAccountId: string;
  isStep1Enabled = true;

  displayedBusinessLocationColumns: string[] = ['business'];
  businessLocations$: Observable<GmbLocation[]> = of([]);
  selectedBusinessLocationId: string;

  isPlaceIdSaving = false;
  selectBusinessLabel = 'Select Business';
  savingPlaceId = false;
  isStep2Enabled = false;

  selectedBusinessPlaceId = null;

  sessionType = 'guest';

  constructor(
    private dialogRef: MatDialogRef<GoogleReviewsBusinessSettingsDialogComponent>,
    private googleReviewsService: GoogleReviewsService,
    private authenticationService: AuthenticationService,
    private upperCasePipe: UpperCasePipe,
    private ngZone: NgZone,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (this.data) {
      this.sessionType = this.data.sessionType !== undefined ? this.data.sessionType : this.sessionType;
    }
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.authenticationService.getCurrentPractice().subscribe((merchant) => {
        this.merchantID = merchant['ID'];
        this.merchant = merchant;

        this.googleReviewsService.onLoad().subscribe(() => {
          this.isServiceReady = true;
          this.businessAccounts$ = this.googleReviewsService.getAccounts(this.merchantID, this.sessionType).pipe(
            tap((result) => {

              // Check for empty object in case authentication expired.
              /*                 if (
                                  result
                                  && Object.keys(result).length === 0
                                  && Object.getPrototypeOf(result) === Object.prototype) {
                                    this.authenticationExpired.emit();
                                  } */
            })
          );
        });
      })
    );
  }

  setSelectedBusinessAccountId(businessAccountId: string, businessAccountName: string) {
    this.selectAccountLabel = 'Selected Account: ' + this.upperCasePipe.transform(businessAccountName);
    this.selectedBusinessAccountId = businessAccountId;
    this.isStep1Enabled = false;
    this.isStep2Enabled = true;
    this.businessLocations$ = this.googleReviewsService.getLocations(
      this.merchantID,
      this.sessionType,
      businessAccountId
    );
  }

  setSelectedPlaceId(placeId: string, businessName: string, businessLocationId: string) {
    this.selectBusinessLabel = 'Selected Business: ' + this.upperCasePipe.transform(businessName);
    this.isStep2Enabled = false;
    this.selectedBusinessPlaceId = placeId;
    this.selectedBusinessLocationId = businessLocationId;
    this.saveBusinessDetails();
  }

  saveBusinessDetails() {
    this.isPlaceIdSaving = true;

    this.googleReviewsService
      .updateGoogleReviewBusinessSettings(
        this.merchantID,
        this.sessionType,
        this.selectedBusinessAccountId,
        this.selectedBusinessLocationId,
        this.selectedBusinessPlaceId
      )
      .subscribe(
        () => {
          this.merchant['Google_Reviews_Account_ID'] = this.selectedBusinessAccountId;
          this.merchant['Google_Reviews_Location_ID'] = this.selectedBusinessLocationId;
          this.merchant['Google_Review_Place_ID'] = this.selectedBusinessPlaceId;

          this.authenticationService.setCurrentPractice(this.merchant);

          NotifyAppComponent.displayToast('success', 'Success', 'Google Review Setup Complete.');

          this.ngZone.run(() => {
            this.dialogRef.close({
              Google_Reviews_Account_ID: this.selectedBusinessAccountId,
              Google_Reviews_Location_ID: this.selectedBusinessLocationId,
              Google_Review_Place_ID: this.selectedBusinessPlaceId,
            });
          });
        },
        (error) => {
          this.isPlaceIdSaving = false;
          NotifyAppComponent.displayToast('error', 'Error', 'Something went wrong when trying to setup Google reviews');
        }
      );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
