<div class="full-width white-background" *ngIf="mainFeature && mainFeature.ID">
  <mat-card>
    <form #userContactForm="ngForm">
      <div
        class="card-header primary-gradient-img clearfix stacked"
        [attr.style]="
          'background:linear-gradient(135deg, ' + colorSecond + ' 0%, ' + color + ' 100%) !important' | safeStyle
        "
        [attr.style]="
          'background:-moz-linear-gradient(135deg, ' + colorSecond + ' 0%, ' + color + ' 100%) !important' | safeStyle
        "
        [attr.style]="
          'background:-webkit-linear-gradient(135deg, ' + colorSecond + ' 0%, ' + color + ' 100%) !important'
            | safeStyle
        "
      >
        <div class="row clearfix">
          <button class="btn-close btn-clear mat-button white" (click)="closeEvent()">
            <mat-icon>clear</mat-icon>
          </button>
          <div class="clearfix">
            <h2 class="sm-h2 pull-left rm-mt rm-mb">
              Edit <strong>{{ label }}</strong
            >.
            </h2>
          </div>
        </div>
      </div>

      <mat-dialog-content class="stacked-card-view">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Label</mat-label>
          <input [(ngModel)]="label" (change)="changeLabel()" matInput placeholder="Label" name="label" required/>
        </mat-form-field>
        <div class="full-width clearfix">
          <mat-form-field class="full-width animate" appearance="outline">
            <mat-label>Short Description</mat-label>
            <textarea
              class="mat-block main-textarea"
              [(ngModel)]="description"
              matInput
              name="description"
              style="height: 150px"
              placeholder="Description"
            >
            </textarea>
          </mat-form-field>

          <div class="full-width">
            <span class="pull-right" [ngClass]="{ 'red-error': description?.length > maxLength }"
            >Text length: <strong> {{ description?.length || 0 }} </strong>/ {{ maxLength || 0 }}</span
            >
          </div>
        </div>
        <div class="full-width">
          <mat-form-field
            class="full-width"
            [ngClass]="isUnique != true ? 'error-input-manual' : ''"
            appearance="outline"
          >
            <mat-label>Static Code</mat-label>
            <input
              [(ngModel)]="code"
              (change)="isUniqueEvent()"
              name="code"
              matInput
              placeholder="Static  Code"
              required
            />
          </mat-form-field>
          <mat-error class="full-width text-right" *ngIf="isUnique != true">
            <mat-icon class="fas fa-exclamation-circle smr"></mat-icon>Static Code must be unique
          </mat-error>
        </div>

        <div class="full-width clearfix">
          <h3 class="sr-title mt" [attr.style]="'color:' + color + ' !important' | safeStyle">Accessibility</h3>
          <div class="full-width mb">
            <mat-checkbox class="slider-width pull-left slider-input-select" [(ngModel)]="isPublic" name="isPublic">
              is Public?
            </mat-checkbox>

            <mat-checkbox class="slider-width pull-left slider-input-select" [(ngModel)]="isActive" name="isActive">
              Is Active?
            </mat-checkbox>
          </div>
          <div class="full-width mt mb">
            <mat-checkbox
              class="slider-width pull-left slider-input-select"
              [(ngModel)]="isSubPlanFeature"
              name="isSubPlanFeature"
            >
              is Sub-Plan Feature?
            </mat-checkbox>

            <mat-checkbox class="slider-width pull-left slider-input-select" [(ngModel)]="isMerchant" name="isMerchant">
              is  {{ 'KEYWORD.practice' | translate }} Feature?
            </mat-checkbox>

            <mat-checkbox class="slider-width pull-left slider-input-select" [(ngModel)]="isConsumer" name="isConsumer">
              is Consumer Feature?
            </mat-checkbox>
          </div>

          <h3 class="sr-title" [attr.style]="'color:' + color + ' !important' | safeStyle">Side Menu</h3>

          <div class="full-width flex">
            <mat-checkbox class="slider-width pull-left slider-input-select" [(ngModel)]="isSideMenu" name="isSideMenu">
              Add to side menu?
            </mat-checkbox>

            <mat-form-field class="full-width mr" *ngIf="isSideMenu == true" appearance="outline">
              <mat-label>Side Menu Label</mat-label>
              <input [(ngModel)]="sideMenuLabel" name="sideMenuLabel" matInput placeholder="Side Menu Label"/>
            </mat-form-field>

            <mat-form-field class="full-width ml" *ngIf="isSideMenu == true" appearance="outline">
              <mat-label>Side Menu Icon</mat-label>
              <input [(ngModel)]="icon" name="icon" matInput placeholder="Side Menu Icon" required/>

              <span matSuffix>
                <mat-icon class="{{ icon | translate}}" [attr.style]="'color:#808080 !important' | safeStyle"> </mat-icon>
              </span>
            </mat-form-field>
          </div>
          <p class="small" *ngIf="isSideMenu == true">
            * You can get the icons from the <a href="https://fontawesome.com/v5/search" target="_blank">Link</a>, e.g.
            <strong>fas fa-user</strong>
          </p>

          <div class="full-width row flex">
            <div class="clearfix full-width mr mb">
              <h3 class="sr-title mt" [attr.style]="'color:' + color + ' !important' | safeStyle">
                Cover Image
              </h3>
              <p>
                Recommended dimension is <strong>1000x250 px</strong> (only images are allowed). Max image size is
                {{ maxFileSize }} MB
              </p>
              <div class="full-width mb blob-for-main-feature-container-modal">
                <app-blob-view
                  class="row clearfix flex flexCol blob-for-social-media full-width full-height"
                  *ngIf="(!postPictureLink && postPicture && loadingPost != true) || loadingPost == true"
                  [label]="'Cover Image'"
                  [applyDownload]="applyDownload"
                  [loading]="loadingPost"
                  [isDownload]="false"
                  [isProceed]="false"
                  [isPrint]="false"
                  [canProceed]="false"
                  [content]="postPicture"
                  [extension]="'png'"
                  [label]=""
                >
                </app-blob-view>
                <div
                  class="view-container full-height full-width"
                  *ngIf="postPictureLink && !postPicture && loadingPost != true"
                >
                  <div class="row clearfix">
                    <pinch-zoom
                      class="full-height clearfix full-width pinchZoom"
                      [limit-zoom]="25"
                      [wheel]="true"
                      [wheelZoomFactor]="0.5"
                      [double-tap]="true"
                      [properties]="pinchZoomProperties"
                    >
                      <img [src]="postPictureLink" />
                    </pinch-zoom>
                  </div>
                </div>
                <div
                  class="view-container full-height full-width"
                  *ngIf="
                    (!postPicture || postPicture == null) &&
                    (!postPictureLink || postPictureLink == null) &&
                    loadingPost != true
                  "
                >
                  <div class="row clearfix">
                    <pinch-zoom
                      class="full-height clearfix full-width pinchZoom"
                      [limit-zoom]="25"
                      [wheel]="true"
                      [wheelZoomFactor]="0.5"
                      [double-tap]="true"
                      [properties]="pinchZoomProperties"
                    >
                      <img
                        [src]="
                          'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/cover/pic8.png'
                        "
                      />
                    </pinch-zoom>
                  </div>
                </div>
              </div>
              <div class="clearfix full-width">
                <app-file-uploader
                  class="text-center clearfix full-width"
                  [allowedExtensions]="allowExtensions"
                  [aspectRatioType]="'qtr'"
                  [maxFileSize]="maxFileSize"
                  [isFixedAspectRatio]="false"
                  [isImageCropping]="true"
                  [canSkip]="false"
                  [limit]="1"
                  [uploadDirectly]="true"
                  [isDragable]="true"
                  [title]="'CLICK HERE to upload  Cover (Max size: ' + maxFileSize + 'MB)'"
                  (onCompleteAll)="onComplete($event)"
                >
                </app-file-uploader>
              </div>
            </div>
          </div>
        </div>
      </mat-dialog-content>

      <div class="drop-button text-center white-background">
        <hr/>

        <button
          class="ml btn-large mr"
          [disabled]="userContactForm.invalid || isUnique != true"
          (click)="save()"
          mat-raised-button
          color="accent"
        >
          <span>Save</span>
        </button>
      </div>
    </form>
  </mat-card>
</div>
