<mat-form-field class="full-width" [ngClass]="isValidate == false ? 'errorInput' : ''" appearance="outline">
  <mat-label>{{ label }}</mat-label>
  <input
    class="mat-block"
    [(ngModel)]="email"
    [placeholder]="label"
    [required]="required"
    [disabled]="isDisabled"
    (change)="sendResult()"
    matInput
    type="email"
    name="{{ random2 }}"
    pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
  />

  <mat-error *ngIf="!email">Email is required</mat-error>
  <mat-hint *ngIf="isValidate == false && email && changeOnce == true" [style.color]="'#f44336'"
  >Invalid email format
  </mat-hint>
</mat-form-field>
