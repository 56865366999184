/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./theme-view.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./theme-view.component";
var styles_ThemeViewComponent = [i0.styles];
var RenderType_ThemeViewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ThemeViewComponent, data: {} });
export { RenderType_ThemeViewComponent as RenderType_ThemeViewComponent };
export function View_ThemeViewComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["template-view works!"]))], null, null); }
export function View_ThemeViewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-theme-view", [], null, null, null, View_ThemeViewComponent_0, RenderType_ThemeViewComponent)), i1.ɵdid(1, 114688, null, 0, i2.ThemeViewComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ThemeViewComponentNgFactory = i1.ɵccf("app-theme-view", i2.ThemeViewComponent, View_ThemeViewComponent_Host_0, {}, {}, []);
export { ThemeViewComponentNgFactory as ThemeViewComponentNgFactory };
