<div class="full-width text-center flex sub-plan-card-container" *ngIf="subscriptionPlan && subscriptionPlan.ID">
  <mat-card class="full-width plan-card text-center" [attr.style]="'box-shadow:' + buildShadowBox(color) | safeStyle">
    <div
      class="card-header primary-gradient-img sub-plan-recommend-inner primary-gradient-img-customized clearfix inModal rel"
      [attr.style]="
        'background:linear-gradient(135deg, ' + colorSecond + ' 0%, ' + color + ' 100%) !important' | safeStyle
      "
      [attr.style]="
        'background:-moz-linear-gradient(135deg, ' + colorSecond + ' 0%, ' + color + ' 100%) !important' | safeStyle
      "
      [attr.style]="
        'background:-webkit-linear-gradient(135deg, ' + colorSecond + ' 0%, ' + color + ' 100%) !important' | safeStyle
      "
    >
      <mat-checkbox
        class="checkbox-module"
        *ngIf="canCheck == true"
        [(ngModel)]="isChecked"
        (ngModelChange)="check(subscriptionPlan['ID'])"
      >
      </mat-checkbox>

      <button
        class="pull-right action-button"
        *ngIf="subscriptionPlan && subscriptionPlan.ID && isPromoterOrAdmin == true"
        [matMenuTriggerFor]="ActionBtnmenu"
        mat-icon-button
        color="primary"
      >
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #ActionBtnmenu="matMenu">
        <button (click)="viewSubPlanModal(subscriptionPlan.ID)" mat-menu-item color="">
          <mat-icon>visibility</mat-icon> Quick View
        </button>

        <button (click)="view(subscriptionPlan.ID)" mat-menu-item color="">
          <mat-icon>description</mat-icon> View
        </button>

        <hr/>
        <button (click)="edit(subscriptionPlan.ID)" mat-menu-item color="">
          <mat-icon>edit</mat-icon>
          Edit
        </button>

        <button (click)="promote(subscriptionPlan.ID)" mat-menu-item color="">
          <mat-icon class="fas fa-bullhorn"></mat-icon> Promote
        </button>
        <hr/>
        <button (click)="remove(subscriptionPlan.ID)" mat-menu-item color="">
          <mat-icon>delete</mat-icon>
          Remove
        </button>
      </mat-menu>

      <div class="image-thumbnail">
        <app-ep-document-view-image
          class="square-img-sub module-sub-plan-feature"
          [hideZoomButton]="false"
          [defaultPicture]="
            'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/light_icon2.png)'
          "
          [link]="postPictureThumbnail"
        >
        </app-ep-document-view-image>
      </div>
    </div>

    <mat-icon class="fas fa-medal recommended-icon" *ngIf="isRecommended == true" matTooltip="Recommended"></mat-icon>

    <div class="own-item text-center smt ml mr mb" *ngIf="isPlanOwn == true">
      <span class="">Your Current plan</span>
      <mat-icon class="fas fa-crown"></mat-icon>
    </div>

    <div class="full-width container-main-parent">
      <div class="full-width container-main-info">
        <h1 class="summary-header rm-m subscription-plan-header" [style.color]="color">
          {{ label }}
        </h1>

        <p class="text-center subscription-plan-description" *ngIf="customizationOnly != true || isPlanOwn != true">
          {{ description }}
        </p>

        <div class="full-width" *ngIf="customizationOnly == true && isPlanOwn == true && subscriptionDate">
          <p class="text-left rm-m smb"><strong>Date Subscribed: </strong>{{ subscriptionDate | customDate }}</p>
        </div>
        <div class="full-width" *ngIf="customizationOnly == true && isPlanOwn == true">
          <p class="text-left rm-m smb">
            <strong>Initial Subscription Wavier Period: </strong><br/>{{ waivedPeriod || 0 }} Days
          </p>
        </div>

        <div class="full-width" *ngIf="customizationOnly == true && isPlanOwn == true">
          <p class="text-left rm-m smb">
            <strong>Your 30-day
              Target: </strong>{{ minTreatmentPlan || 0 }}{{ "KEYWORD.treatment" | translate | titlecase }}
            Invitations
          </p>
        </div>

        <div class="full-width" *ngIf="customizationOnly == true && isPlanOwn == true">
          <p class="text-left rm-m smb"><strong>SMS's Fee: </strong>{{ smsPrice || 0 | customCurrency }} per SMS</p>
        </div>

        <div class="text-center smt" *ngIf="isPromoterOrAdmin == true">
          <span class="chip-module chip-disabled mat-elevation-z1" *ngIf="isActive != true">Not active</span>
          <span class="chip-module chip-beta mat-elevation-z1" *ngIf="isPublic != true">Customized</span>
          <span
            class="chip-module chip-enable mat-elevation-z1"
            *ngIf="isDefault == true && isActive == true && isPublic == true"
          >Default</span
          >
        </div>

        <div class="full-width container-main-info-price">
          <p class="text-center rm-m mt price-card">{{ price || 0 | customCurrency: '1.0-3' }}</p>
          <p class="text-center rm-m smb price-card-sub">per month*</p>
        </div>
      </div>

      <div class="full-width container-main-extra" *ngIf="customizationOnly != true || isPlanOwn != true">
        <div class="full-width" *ngIf="isClosed == false || canClose == true">
          <div class="full-width" [ngClass]="{ 'pointer-cursor-card': canClose == true }" (click)="changeCloseEvent()">
            <p class="rm-mb subscription-plan-description-more">What do you get?</p>

            <mat-icon class="fas fa-chevron-down collapse-icon pull-right" *ngIf="isClosed == true && canClose == true">
            </mat-icon>
            <mat-icon class="fas fa-chevron-up collapse-icon pull-right" *ngIf="isClosed != true && canClose == true">
            </mat-icon>
          </div>
          <div class="full-width" *ngIf="isClosed != true" [@slideInOut]>
            <hr class="sub-plan-div" [attr.style]="'background:' + color + ' !important' | safeStyle"/>

            <div *ngIf="marketingDescriptions && marketingDescriptions.length > 0">
              <p class="rm-m text-left" *ngFor="let s of marketingDescriptions">
                <mat-icon class="fas fa-check-circle enable-icon-module" [style.color]="color"></mat-icon>
                {{ s }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        class="full-width text-center action-container mt smb"
        *ngIf="isSelectButton == true || isSignupButton == true || (isSubscriptionButton == true && isPlanOwn != true)"
      >
        <div class="full-width flex">
          <h5
            class="view-link full-width text-left"
            *ngIf="isViewButton == true && price > 0"
            [attr.style]="'color:' + color + ' !important' | safeStyle"
            (click)="freeSub(subscriptionPlan.ID)"
          >
            Want it for free?
          </h5>

          <h5
            class="view-link full-width text-right"
            *ngIf="isViewButton == true"
            [attr.style]="'color:' + color + ' !important' | safeStyle"
            (click)="view(subscriptionPlan.ID)"
          >
            More details
            <mat-icon class="fas fa-chevron-right smr"></mat-icon>
          </h5>
        </div>

        <hr class="smt sub-plan-div" [attr.style]="'background:' + color + ' !important' | safeStyle"/>

        <button
          class="btn-large clearfix order-now-button"
          *ngIf="isSubscriptionButton == true && isPlanOwn != true"
          (click)="subscribe(subscriptionPlan.ID)"
          mat-raised-button
          color="primary"
        >
          GET IT NOW!
          <mat-icon class="fas fa-star smr sml"></mat-icon>
        </button>

        <button
          class="btn-large clearfix"
          *ngIf="isSignupButton == true && isPlanOwn != true"
          [attr.style]="
            'background:linear-gradient(135deg, ' + colorSecond + ' 0%, ' + color + ' 100%) !important' | safeStyle
          "
          [attr.style]="
            'background:-moz-linear-gradient(135deg, ' + colorSecond + ' 0%, ' + color + ' 100%) !important' | safeStyle
          "
          [attr.style]="
            'background:-webkit-linear-gradient(135deg, ' + colorSecond + ' 0%, ' + color + ' 100%) !important'
              | safeStyle
          "
          (click)="subscribe(subscriptionPlan.ID)"
          mat-raised-button
          color="primary"
        >
          Sign up
        </button>

        <button
          class="btn-large clearfix"
          *ngIf="isSignupButton == true && isPlanOwn == true"
          [attr.style]="
            'background:linear-gradient(135deg, ' + colorSecond + ' 0%, ' + color + ' 100%) !important' | safeStyle
          "
          [attr.style]="
            'background:-moz-linear-gradient(135deg, ' + colorSecond + ' 0%, ' + color + ' 100%) !important' | safeStyle
          "
          [attr.style]="
            'background:-webkit-linear-gradient(135deg, ' + colorSecond + ' 0%, ' + color + ' 100%) !important'
              | safeStyle
          "
          (click)="subscribe(subscriptionPlan.ID)"
          mat-raised-button
          color="primary"
        >
          View
        </button>
        <button
          class="btn-large clearfix"
          *ngIf="isSelectButton == true"
          [attr.style]="
            'background:linear-gradient(135deg, ' + colorSecond + ' 0%, ' + color + ' 100%) !important' | safeStyle
          "
          [attr.style]="
            'background:-moz-linear-gradient(135deg, ' + colorSecond + ' 0%, ' + color + ' 100%) !important' | safeStyle
          "
          [attr.style]="
            'background:-webkit-linear-gradient(135deg, ' + colorSecond + ' 0%, ' + color + ' 100%) !important'
              | safeStyle
          "
          (click)="select(subscriptionPlan.ID)"
          mat-raised-button
          color="primary"
        >
          {{ selectTitle }}
        </button>
      </div>
    </div>
  </mat-card>
</div>
