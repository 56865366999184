import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSortModule } from '@angular/material/sort';
import { QuillModule } from 'ngx-quill';
import { SharedModule } from '../../shared/shared.module';
import { TipRoutingModule } from './shared/tip-routing.module';
import { TipService } from './shared/tip.service';
import { TipCreateComponent } from './tip-create/tip-create.component';
import { TipEditComponent } from './tip-edit/tip-edit.component';
import { TipListComponent } from './tip-list/tip-list.component';
import { TipOverviewComponent } from './tip-overview/tip-overview.component';
import { TipViewComponent } from './tip-view/tip-view.component';

@NgModule({
  imports: [CommonModule, TipRoutingModule, CdkTableModule, QuillModule, SharedModule, MatSortModule],
  declarations: [TipCreateComponent, TipListComponent, TipOverviewComponent, TipViewComponent, TipEditComponent],
  exports: [TipCreateComponent, TipListComponent, TipOverviewComponent, TipViewComponent, TipEditComponent],
  providers: [TipService],
})
export class TipModule {}
