import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CdkTableModule } from '@angular/cdk/table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSortModule } from '@angular/material';
import { ArchwizardModule } from 'angular-archwizard';
import { NgPipesModule } from 'ngx-pipes';
import { MerchantModule } from '../../feature/merchant/merchant.module';
import { OperatorModule } from '../../feature/operator/operator.module';
import { SharedModule } from '../../shared/shared.module';
import { AuthenticationModule } from '../authentication/authentication.module';
import { ThirdPartyRoutingModule } from './shared/third-party-routing.module';
import { ThirdPartyService } from './shared/third-party.service';
import { ThirdPartyAccessClientOverviewComponent } from './third-party-access-client-overview/third-party-access-client-overview.component';
import { ThirdPartyAccessCreateComponent } from './third-party-access-create/third-party-access-create.component';
import { ThirdPartyAccessListOverviewComponent } from './third-party-access-list-overview/third-party-access-list-overview.component';
import { ThirdPartyAccessListComponent } from './third-party-access-list/third-party-access-list.component';
import { ThirdPartyAccessViewComponent } from './third-party-access-view/third-party-access-view.component';
import { ThirdPartyClientCreateEditComponent } from './third-party-client-create-edit/third-party-client-create-edit.component';
import { ThirdPartyClientListComponent } from './third-party-client-list/third-party-client-list.component';
import { ThirdPartyClientPictureComponent } from './third-party-client-picture/third-party-client-picture.component';
import { ThirdPartyClientQuickEditComponent } from './third-party-client-quick-edit/third-party-client-quick-edit.component';
import { ThirdPartyClientViewComponent } from './third-party-client-view/third-party-client-view.component';

@NgModule({
  imports: [
    CommonModule,
    ThirdPartyRoutingModule,
    ArchwizardModule,
    ReactiveFormsModule,
    CdkTableModule,
    NgPipesModule,
    FormsModule,
    SharedModule,
    AuthenticationModule,
    MerchantModule,
    MatSortModule,
    OperatorModule,
  ],
  declarations: [
    ThirdPartyClientCreateEditComponent,
    ThirdPartyClientListComponent,
    ThirdPartyClientViewComponent,
    ThirdPartyAccessCreateComponent,
    ThirdPartyAccessViewComponent,
    ThirdPartyAccessListComponent,
    ThirdPartyAccessListOverviewComponent,
    ThirdPartyClientPictureComponent,
    ThirdPartyAccessClientOverviewComponent,
    ThirdPartyClientQuickEditComponent,
  ],
  exports: [
    ThirdPartyClientCreateEditComponent,
    ThirdPartyClientListComponent,
    ThirdPartyClientViewComponent,
    ThirdPartyAccessCreateComponent,
    ThirdPartyAccessViewComponent,
    ThirdPartyAccessListComponent,
    ThirdPartyAccessListOverviewComponent,
    ThirdPartyClientPictureComponent,
    ThirdPartyAccessClientOverviewComponent,
    ThirdPartyClientQuickEditComponent,
  ],
  providers: [ThirdPartyService],
})
export class ThirdPartyModule {}
