<div>
  <div class="main-banner blue-banner"></div>
  <ng-container *ngIf="page == 1">
    <section class="step1">
      <div class="top container">
        <div class="top-leftbox">
          <div class="top-txt" @ngIfAnimation data-aos="fade-right" data-aos-delay="400" data-aos-once="true">
            <h1 class="getting-started-page">Everything you need to go digital</h1>
            <p class="getting-started-page">
              Welcome to the first step on your journey to growing your {{ "KEYWORD.practice" | translate }}! This page
              will help you and your team with everything they need to start communicating digitally.
            </p>
          </div>
        </div>
        <div class="top-rightbox" @ngIfAnimation data-aos="fade-left" data-aos-delay="400" data-aos-once="true">
          <div class="imgbox">
            <img class="img"
                 src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/getting-started/Asset_6.png"/>
          </div>
        </div>
      </div>
      <div class="main container white-background">
        <div class="title" @ngIfAnimation data-aos="fade-up" data-aos-delay="400" data-aos-once="true">
          <h2 class="getting-started-page">Getting Started</h2>
          <p class="getting-started-page">
            A new platform can be overwhelming - there are so many new features to explore and you aren't sure where to
            start. Taking those first few steps can be daunting, but the hard part is over! Decisions like these are
            just one of many towards creating a successful {{ "KEYWORD.practice" | translate }}. Choose a step to get
            started.
          </p>
        </div>
        <div class="intro flex">
          <div class="intro-fi">
            <div class="intro-con" @ngIfAnimation data-aos="fade-right" data-aos-delay="400" data-aos-once="true">
              <div class="intro-numbox" (click)="pageS(2)">
                <div class="intro-num">
                  <h2 class="getting-started-page">1</h2>
                </div>
              </div>
              <div class="intro-txt">
                <h4 class="getting-started-page">Review Your Profile</h4>
              </div>
            </div>
          </div>
          <div class="intro-se">
            <div class="intro-con" @ngIfAnimation data-aos="fade-right" data-aos-delay="400" data-aos-once="true">
              <div class="intro-numbox" (click)="pageS(3)">
                <div class="intro-num">
                  <h2 class="getting-started-page">2</h2>
                </div>
              </div>
              <div class="intro-txt">
                <h4 class="getting-started-page">Sending {{"PHRASE.treatment plan" | translate | titlecase}}s </h4>
              </div>
            </div>
          </div>
          <div class="intro-th">
            <div class="intro-con" @ngIfAnimation data-aos="fade-right" data-aos-delay="400" data-aos-once="true">
              <div class="intro-numbox" (click)="pageS(4)">
                <div class="intro-num">
                  <h2 class="getting-started-page">3</h2>
                </div>
              </div>
              <div class="intro-txt">
                <h4 class="getting-started-page">Getting Paid</h4>
              </div>
            </div>
          </div>
        </div>
        <div class="intro-bot" @ngIfAnimation data-aos="fade-up" data-aos-delay="400" data-aos-once="true">
          <p class="getting-started-page">
            <span class="strong">Important</span> - The {{ "KEYWORD.practice" | translate }} manager should have your
            portal set up and ready to go with all the
            correct {{"PARAGRAPH.Core Getting Started Settings and More" | translate}}. If not, be sure
            to mention it to your {{ "KEYWORD.practice" | translate }} manager or principal
            {{ "KEYWORD.practitioner" | translate }} to confirm this is done. Alternatively, click on the button below
            to follow the setup instructions.
          </p>
          <button class="btn" (click)="pageS(2)" mat-stroked-button>
            Portal Manager Setup <mat-icon>chevron_right</mat-icon>
          </button>
        </div>
      </div>
      <div class="guid flex">
        <div class="container guid-main" @ngIfAnimation data-aos="fade-right" data-aos-delay="400" data-aos-once="true">
          <div class="half-width img-box-left">
            <div class="guid-imgbox">
              <img class="img"
                   src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/getting-started/Asset_7.png"/>
            </div>
          </div>
          <div class="half-width txt-box">
            <div class="txt" @ngIfAnimation data-aos="fade-left" data-aos-delay="400" data-aos-once="true">
              <h2 class="getting-started-page">Read Me!</h2>
              <p class="getting-started-page">
                We've created a detailed User Guide to help take you through each feature of your
                {{ "KEYWORD.practice" | translate }} portal. With insights and helpful links to articles, you will be a
                portal pro in no time!
              </p>
              <button class="btn" (click)="openNewTab()" mat-stroked-button>
                Download User Guide <mat-icon>chevron_right</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="visit flex white-background">
        <div class="container visit-main">
          <div class="visit-imgbox" @ngIfAnimation data-aos="fade-right" data-aos-delay="400" data-aos-once="true">
            <img class="img"
                 src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/getting-started/Asset_8.png"/>
          </div>
          <div class="visit-text" @ngIfAnimation data-aos="fade-left" data-aos-delay="400" data-aos-once="true">
            <p class="getting-started-page">
              Need some more detailed help? Visit the help centre located in your portal for access to easy to follow
              how-to articles
            </p>
          </div>
          <button class="btn" @ngIfAnimation (click)="openNewTab1()" mat-stroked-button data-aos="fade-up"
                  data-aos-delay="400" data-aos-once="true">
            View Help Centre <mat-icon>chevron_right</mat-icon>
          </button>
        </div>
      </div>
      <div class="book flex">
        <div class="container book-main">
          <div class="book-title">
            <h2 class="getting-started-page">Still need help? Get in touch!</h2>
          </div>
          <div class="book-contact">
            <p class="getting-started-page">Email: {{ practiceAddress }}</p>
            <p class="getting-started-page">Phone: {{ practiceNumber }}</p>
            <p class="getting-started-page">{{"BRANDING.Operating Hours AEST" | translate}}</p>
          </div>
          <div class="book-bot">
            <p class="getting-started-page">
              Alternatively, you can book in some training with our experts. Training is
              <span class="strong">FREE</span> with unlimited access to courses that will help you and your team become
              portal pros!
            </p>
          </div>
          <button class="btn" (click)="openNewTab2()" mat-stroked-button>
            Book Training <mat-icon>chevron_right</mat-icon>
          </button>
        </div>
      </div>
    </section>
  </ng-container>
  <ng-container *ngIf="page == 2">
    <section class="step2">
      <div class="top flex container">
        <div class="top-leftbox">
          <div class="top-txt" @ngIfAnimation data-aos="fade-right" data-aos-delay="400" data-aos-once="true">
            <h1 class="getting-started-page">Review your profile</h1>
            <p class="getting-started-page">
              Personalise your profile so {{ "KEYWORD.patient" | translate }}s and team members see you in the best
              possible light
            </p>
          </div>
        </div>
        <div class="top-rightbox" @ngIfAnimation data-aos="fade-left" data-aos-delay="400" data-aos-once="true">
          <div class="imgbox">
            <img class="img"
                 src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/getting-started/Asset_2.png"/>
          </div>
        </div>
      </div>
      <div class="main container">
        <div class="title" @ngIfAnimation data-aos="fade-up" data-aos-delay="400" data-aos-once="true">
          <h2 class="getting-started-page">Settings</h2>
          <p class="getting-started-page">
            Just logged into your portal for the first time? Your screen should like something like this.
          </p>
        </div>
        <div class="step2-imgbox">
          <img class="img"
               src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/getting-started/Asset_9.png"/>
        </div>
        <div class="step2-txt">
          <p class="getting-started-page">
            Like we mentioned previously, your {{ "KEYWORD.practice" | translate }} manager or principal
            {{ "KEYWORD.practitioner" | translate }} should have your portal settings filled out. All that is left for
            you to do is fill out your personal details. Let’s start!
          </p>
          <p class="getting-started-page">
            Click on your name in the top right-hand corner and go to 'my profile'. Here is a helpful checklist along
            with how-to articles with detailed instructions to get you on the right track:
          </p>
        </div>
        <div class="artic">
          <div class="art-con">
            <div class="art-icon">
              <mat-icon class="icon-done"><span class="material-icons-outlined">done</span></mat-icon>
            </div>
            <div class="art-txt">
              <p class="getting-started-page">
                <span class="strong">Bookmark Portal</span> - You should bookmark the portal landing page for easy
                access when logging in.
              </p>
            </div>
            <div class="art-art">
              <a target="_blank"
                 href="https://app.smileright.com.au/wiki/(wikiPageRoute:view-code/setting-up-your-profile">
                <mat-icon class="icon-art"><span class="material-icons-outlined">list_alt</span></mat-icon>
              </a>
            </div>
          </div>
          <div class="art-con">
            <div class="art-icon">
              <mat-icon class="icon-done"><span class="material-icons-outlined">done</span></mat-icon>
            </div>
            <div class="art-txt">
              <p class="getting-started-page">
                <span class="strong">Contact Details</span> - Add/confirm your best contact details, your name, and your
                position in the {{ "KEYWORD.practice" | translate }}.
              </p>
            </div>
            <div class="art-art">
              <a target="_blank"
                 href="https://app.smileright.com.au/wiki/(wikiPageRoute:view-code/setting-up-your-profile">
                <mat-icon class="icon-art"><span class="material-icons-outlined">list_alt</span></mat-icon>
              </a>
            </div>
          </div>
          <div class="art-con">
            <div class="art-icon">
              <mat-icon class="icon-done"><span class="material-icons-outlined">done</span></mat-icon>
            </div>
            <div class="art-txt">
              <p class="getting-started-page">
                <span class="strong">Personal Description</span> - Your personal profile helps your team members and
                {{ "KEYWORD.patient" | translate }}s identify you within your {{ "KEYWORD.practice" | translate }}. It's
                also a way for people to get to know you - tell a fun story about yourself or about your dreams.
              </p>
            </div>
            <div class="art-art">
              <a target="_blank"
                 href="https://app.smileright.com.au/wiki/(wikiPageRoute:view-code/setting-up-your-profile">
                <mat-icon class="icon-art"><span class="material-icons-outlined">list_alt</span></mat-icon>
              </a>
            </div>
          </div>
          <div class="art-con">
            <div class="art-icon">
              <mat-icon class="icon-done"><span class="material-icons-outlined">done</span></mat-icon>
            </div>
            <div class="art-txt">
              <p class="getting-started-page">
                <span class="strong">Profile Photo</span> - Upload a clear picture of yourself. Remember you are
                representing the {{ "KEYWORD.practice" | translate }} and {{ "KEYWORD.patient" | translate }}s will be
                able to see this, so try to stick on the professional side.
              </p>
            </div>
            <div class="art-art">
              <a target="_blank"
                 href="https://app.smileright.com.au/wiki/(wikiPageRoute:view-code/setting-up-your-profile">
                <mat-icon class="icon-art"><span class="material-icons-outlined">list_alt</span></mat-icon>
              </a>
            </div>
          </div>
          <div class="art-con">
            <div class="art-icon">
              <mat-icon class="icon-done"><span class="material-icons-outlined">done</span></mat-icon>
            </div>
            <div class="art-txt">
              <p class="getting-started-page">
                <span class="strong">Social Media Links</span> - If you have any socials you want to share, maybe a
                LinkedIn page, connect it with an easy link.
              </p>
            </div>
            <div class="art-art">
              <a target="_blank"
                 href="https://app.smileright.com.au/wiki/(wikiPageRoute:view-code/setting-up-your-profile">
                <mat-icon class="icon-art"><span class="material-icons-outlined">list_alt</span></mat-icon>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="step2-bot">
        <div class="left-btn">
          <button class="btn" (click)="pageS(1)" mat-stroked-button>
            <mat-icon>chevron_left</mat-icon>
            Start
          </button>
        </div>
        <div class="right-btn">
          <button class="btn" (click)="pageS(3)" mat-stroked-button>
            Step Two
            <mat-icon>chevron_right</mat-icon>
          </button>
        </div>
      </div>
    </section>
  </ng-container>
  <ng-container *ngIf="page == 3">
    <section class="step3">
      <div class="top flex container">
        <div class="s3-top-leftbox top-leftbox">
          <div class="s3-top-txt top-txt" @ngIfAnimation data-aos="fade-right" data-aos-delay="400"
               data-aos-once="true">
            <h1 class="getting-started-page">Sending {{ 'PHRASE.treatment plan' | translate | titlecase}}</h1>
            <p class="getting-started-page">
              Paper is a thing of the past, it’s time to go digital! We have made thing easy with just a few clicks you
              will be communicating digitally in no time.
            </p>
          </div>
        </div>
        <div class="s3-top-rightbox top-rightbox" @ngIfAnimation data-aos="fade-left" data-aos-delay="400"
             data-aos-once="true">
          <div class="s3-top-txt imgbox">
            <img class="img"
                 src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/getting-started/Asset_10.png"/>
          </div>
        </div>
      </div>
      <div class="main container">
        <div class="title" @ngIfAnimation data-aos="fade-up" data-aos-delay="400" data-aos-once="true">
          <h2 class="getting-started-page">What do I need to do for my {{ "KEYWORD.patient" | translate }}s?</h2>
        </div>
        <div class="step2-txt">
          <p class="getting-started-page">
            {{"PARAGRAPH.Core Getting Started Dental Digital Treatment" | translate}}
          </p>
          <p class="getting-started-page">
            Remember, {{ "BRANDING.Brand Name" | translate | titlecase }} is about {{ "KEYWORD.patient" | translate }}
            communication
            and education, not just
            finance. We've put together a checklist to help
            you {{"PARAGRAPH.Core Getting Started Dental Digital Treatment Communication" | translate}}:
          </p>
        </div>
        <div class="artic">
          <div class="art-con">
            <div class="art-icon">
              <mat-icon class="icon-done"><span class="material-icons-outlined">done</span></mat-icon>
            </div>
            <div class="art-txt">
              <p class="getting-started-page">
                <span class="strong">Prepare {{ "KEYWORD.patient" | translate | titlecase }} in Portal</span> - Your
                {{ "KEYWORD.patient" | translate }}s should be already imported into your portal, if not visit the
                previous step to see how. Is your {{ "KEYWORD.patient" | translate }} new? No problem! All you need is a
                name, mobile and email to communicate.
              </p>
            </div>
            <div class="art-art">
              <div class="step3-art">
                <a target="_blank"
                   href="https://app.smileright.com.au/wiki/(wikiPageRoute:viewcode/sending-a-treatment-invitation)">
                  <mat-icon class="icon-art"><span class="material-icons-outlined">list_alt</span></mat-icon>
                </a>
                <a
                  (click)="viewVideoInfo('Prepare ' + this.PatientKeyword +' in Portal', 'vimeo.com/389155530/f8d2ff4355')">
                  <mat-icon class="icon-art art-video"><span class="material-icons-outlined">play_circle_filled</span>
                  </mat-icon>
                </a>
              </div>
            </div>
          </div>
          <div class="art-con">
            <div class="art-icon">
              <mat-icon class="icon-done"><span class="material-icons-outlined">done</span></mat-icon>
            </div>
            <div class="art-txt">
              <p class="getting-started-page">
                <span class="strong">After {{"KEYWORD.consultation" | translate | titlecase}}</span> - Once
                your {{ "KEYWORD.patient" | translate }} has had
                their {{"KEYWORD.consultation" | translate}}, complete the invitation process and press send, and the
                job is done.
              </p>
            </div>
            <div class="art-art">
              <div class="step3-art">
                <a target="_blank"
                   href="https://app.smileright.com.au/wiki/(wikiPageRoute:viewcode/sending-a-treatment-invitation)">
                  <mat-icon class="icon-art"><span class="material-icons-outlined">list_alt</span></mat-icon>
                </a>
                <a (click)="viewVideoInfo('After ' + this.ConsultationKeyword, 'vimeo.com/389155530/f8d2ff4355')">
                  <mat-icon class="icon-art art-video"><span class="material-icons-outlined">play_circle_filled</span>
                  </mat-icon>
                </a>
              </div>
            </div>
          </div>
          <div class="art-con">
            <div class="art-icon">
              <mat-icon class="icon-done"><span class="material-icons-outlined">done</span></mat-icon>
            </div>
            <div class="art-txt">
              <p class="getting-started-page">
                <span class="strong">Send {{ "KEYWORD.treatment" | translate | titlecase }} in 30 Seconds</span> - Save
                more time by
                using {{ "KEYWORD.treatment" | translate }} templates.
                These pre-populate an offer for {{ "KEYWORD.treatment" | translate | titlecase }} including price,
                images, videos,
                informed consent material and
                more. Use one provided by {{ "BRANDING.Brand Name" | translate | titlecase }} or clone and modify to
                suit your
                {{ "KEYWORD.practice" | translate }}.
              </p>
            </div>
            <div class="art-art">
              <div class="step3-art">
                <a target="_blank"
                   href="https://app.smileright.com.au/wiki/(wikiPageRoute:viewcode/sending-a-treatment-invitation)">
                  <mat-icon class="icon-art"><span class="material-icons-outlined">list_alt</span></mat-icon>
                </a>
                <a
                  (click)="viewVideoInfo('Send ' + this.TreatmentKeyword +' in 30 Seconds', 'vimeo.com/389155530/f8d2ff4355')">
                  <mat-icon class="icon-art art-video"><span class="material-icons-outlined">play_circle_filled</span>
                  </mat-icon>
                </a>
              </div>
            </div>
          </div>
          <div class="art-con">
            <div class="art-icon">
              <mat-icon class="icon-done"><span class="material-icons-outlined">done</span></mat-icon>
            </div>
            <div class="art-txt">
              <p class="getting-started-page">
                <span class="strong">Keep Track of Invitations</span> - Sit back and get ready
                - {{"PARAGRAPH.Core Getting Started Electronic Communication" | translate}}
              </p>
            </div>
            <div class="art-art">
              <div class="step3-art">
                <a target="_blank"
                   href="https://app.smileright.com.au/wiki/(wikiPageRoute:viewcode/sending-a-treatment-invitation)">
                  <mat-icon class="icon-art"><span class="material-icons-outlined">list_alt</span></mat-icon>
                </a>
                <a (click)="viewVideoInfo('Keep Track of Invitations', 'vimeo.com/389155530/f8d2ff4355')">
                  <mat-icon class="icon-art art-video"><span class="material-icons-outlined">play_circle_filled</span>
                  </mat-icon>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="step-mid flex">
        <div class="container mid-main">
          <div class="title">
            <h2 class="getting-started-page">Discussing {{ "KEYWORD.treatment" | translate | titlecase }}</h2>
            <p class="getting-started-page">
              Once you’ve sent an invitation to your {{ "KEYWORD.patient" | translate }}, you can pull up their
              {{ "KEYWORD.patient" | translate }} card to discuss {{ "KEYWORD.treatment" | translate }}s and show
              helpful documentation and videos.
              Help your {{ "KEYWORD.patient" | translate }} understand their
              recommended {{ 'PHRASE.treatment plan' | translate }} through helpful
              visual tools, and even help {{ "KEYWORD.patient" | translate }}s apply for
              a {{ "BRANDING.Brand Name" | translate | titlecase }} payment plan, all
              before they leave the chair!
            </p>
          </div>
          <div class="artict">
            <div class="artict-cont">
              <h4 class="getting-started-page">Read the ‘How-To’ article ></h4>
              <a target="_blank"
                 href="https://app.smileright.com.au/wiki/(wikiPageRoute:viewcode/sending-a-treatment-invitation)">
                <mat-icon class="icon-art"><span class="material-icons-outlined">list_alt</span></mat-icon>
              </a>
            </div>
          </div>
          <div class="step3-imgbox">
            <img class="img"
                 src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/getting-started/Asset_11.png"/>
          </div>
        </div>
      </div>
      <div class="bot">
        <div class="step2-bot">
          <div class="left-btn">
            <button class="btn" (click)="pageS(2)" mat-stroked-button>
              <mat-icon>chevron_left</mat-icon> Step One
            </button>
          </div>
          <div class="right-btn">
            <button class="btn" (click)="pageS(4)" mat-stroked-button>
              Step Three <mat-icon>chevron_right</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </section>
  </ng-container>
  <ng-container *ngIf="page == 4">
    <section class="step4">
      <div class="top flex container">
        <div class="s3-top-leftbox top-leftbox">
          <div class="s3-top-txt top-txt" @ngIfAnimation data-aos="fade-right" data-aos-delay="400"
               data-aos-once="true">
            <h1 class="getting-started-page">Getting Paid</h1>
            <p class="getting-started-page">
              Provided {{ "KEYWORD.treatment" | translate }}? Now it’s time to get paid by putting in a settlement
              request.
            </p>
          </div>
        </div>
        <div class="s3-top-rightbox top-rightbox" @ngIfAnimation data-aos="fade-left" data-aos-delay="400"
             data-aos-once="true">
          <div class="s4-imgbox imgbox">
            <img class="img"
                 src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/getting-started/Asset_12.png"/>
          </div>
        </div>
      </div>
      <div class="main container">
        <div class="title" @ngIfAnimation data-aos="fade-up" data-aos-delay="400" data-aos-once="true">
          <h2 class="getting-started-page">Settlement Request</h2>
        </div>
        <div class="step2-txt">
          <p class="getting-started-page">
            Once {{ "KEYWORD.treatment" | translate }} has been provided, you can request a settlement for
            the {{ 'KEYWORD.procedure' | translate }} amount and what you have
            invoiced your {{ "KEYWORD.patient" | translate }}. There is no limit to the number of requests you can make.
          </p>
          <p class="getting-started-page">
            Your {{ "KEYWORD.practice" | translate }} manager or the {{ "KEYWORD.treatment" | translate }}
            coordinator/{{ "KEYWORD.receptionist" | translate }} will complete
            this process as it requires a few things from the {{ "KEYWORD.patient" | translate }} before they leave.
          </p>
        </div>
        <div class="step4-con">
          <div class="s4-txtbox">
            <div class="s4-txt">
              <h3 class="getting-started-page">Sound like a job for you instead?</h3>
              <p class="getting-started-page">
                Click the button below for settlement request instructions or alternatively visit the help centre.
              </p>
              <button class="btn" [routerLink]="['/merchant/', { outlets: { page: ['getting-started-home'] } }]"
                      mat-stroked-button>
                How-To <mat-icon>chevron_right</mat-icon>
              </button>
            </div>
          </div>
          <div class="s4-imgbox">
            <div class="s4-img">
              <img class="img"
                   src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/getting-started/Asset_13.png"/>
            </div>
          </div>
        </div>
      </div>
      <div class="step-mid flex">
        <div class="container mid-main">
          <div class="title">
            <h2 class="getting-started-page">Helpful Tools</h2>
          </div>
          <div class="step-itembox">
            <div>
              <div class="item-con">
                <div class="item-icon">
                  <mat-icon class="icon-left"><span class="step4-icon material-icons">zoom_in</span></mat-icon>
                </div>
                <div class="item-txt">
                  <p class="getting-started-page">
                    <span class="strong">Product Lookup:</span> Used to search for all the
                    supplier products available
                    on the platform and will display any information relating to the product
                    selected, including
                    supporting documents, images and videos. Search by Item Code, brand or product. Can also be used for
                    a variety of things, including research, staff training,
                    {{ "KEYWORD.patient" | translate }} presentation, invitation
                    templates, {{"PHRASE.treatment planning" | translate}} and more!
                  </p>
                </div>
              </div>
              <div class="item-con">
                <div class="item-txt">
                  <p class="getting-started-page">
                    <span class="strong">Financial Calculator:</span> This is a helpful feature! If a
                    {{ "KEYWORD.patient" | translate }} is enquiring about payment options, costs or repayments, use
                    this feature to show/tell your {{ "KEYWORD.patient" | translate }} and answer any query they might
                    have on {{ "BRANDING.Brand Name" | translate | titlecase }} payment options. Input the {{
                    "KEYWORD.treatment" |
                    translate }} value, and the
                    calculator will show all
                    options available.
                  </p>
                </div>
                <div class="item-icon">
                  <mat-icon class="icon-left"><span class="step4-icon material-icons">attach_money</span></mat-icon>
                </div>
              </div>
              <div class="item-con">
                <div class="item-icon">
                  <mat-icon class="icon-left"><span class="step4-icon material-icons">notifications</span></mat-icon>
                </div>
                <div class="item-txt">
                  <p class="getting-started-page">
                    <span class="strong">Activity Bell:</span> Every action or interaction either by a team member or
                    {{ "KEYWORD.patient" | translate }} that happens on the platform will give you a notification. It
                    would help if you viewed these notifications regularly to stay up to date with what is happening.
                    You can mark notifications as read, or "star" them if they are essential and return to them later.
                    To view a full audit trail, click the communications tab in the blue bar and see activities and
                    communication to and from the platform.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bot">
        <div class="step2-bot">
          <div class="left-btn">
            <button class="btn" (click)="pageS(3)" mat-stroked-button>
              <mat-icon>chevron_left</mat-icon> Step Two
            </button>
          </div>
          <div class="right-btn">
            <button class="btn" [routerLink]="['/merchant/', { outlets: { page: ['getting-started-home'] } }]"
                    mat-stroked-button>
              Back to Start <mat-icon>chevron_right</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </section>
  </ng-container>
</div>
