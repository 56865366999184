<div class="rel" *ngIf="listDB.firstLoadEvent == false" @ngIfAnimation>
  <div class="row clearfix padded rel">
    <h1 class="dataHeader rm-mt transparent">Fetching data...</h1>
  </div>
</div>

<div [hidden]="listDB.data.length == 0 || ShowPagesList == true">
  <div class="row clearfix">
    <div class="row clearfix">
      <h2 class="dataHeader rm-mt">
        Wiki Categories
        <span class="badge" [endVal]="dataSource.filteredData.length" countUp></span>
      </h2>

      <p class="lead rm-mt">The categories are sorted by display order</p>
    </div>

    <div class="row clearfix flex ctl mb" @ngIfAnimation>
      <mat-button-toggle-group
        class="btn-group rm-m half-width"
        *ngIf="isPromoterOrAdmin == true"
        [(ngModel)]="isActiveFilter"
        (change)="setFilter($event.value, 'active')"
      >
        <mat-button-toggle class="full-width text-center" [value]="null">
          All
        </mat-button-toggle>
        <mat-button-toggle class="full-width text-center" [value]="'1'">
          Active
        </mat-button-toggle>
        <mat-button-toggle class="full-width text-center" [value]="'0'">
          Not Active
        </mat-button-toggle>
      </mat-button-toggle-group>

      <button
        class="row select-btn"
        *ngIf="isPromoterOrAdmin == true"
        (click)="selectAll()"
        mat-stroked-button
        color=""
        matTooltip="Select All"
      >
        <mat-icon>border_all</mat-icon>
      </button>

      <button
        class="row select-btn"
        *ngIf="isPromoterOrAdmin == true"
        (click)="selectPage()"
        mat-stroked-button
        color=""
        matTooltip="Select Article"
      >
        <mat-icon>border_outer</mat-icon>
      </button>

      <button
        class="animated"
        *ngIf="isPromoterOrAdmin == true && selectedIDs && selectedIDs.length > 0"
        [matMenuTriggerFor]="ActionBtnmenu"
        mat-stroked-button
        matTooltip="Options"
      >
        <mat-icon class="fas fa-cog"></mat-icon>  <span *ngIf="selectedIDs && selectedIDs.length>0"
          class="selected-number-item  accent-background">{{selectedIDs.length}}</span>
      </button>
      <mat-menu #ActionBtnmenu="matMenu">
        <button class="row select-btn" (click)="unselect()" mat-menu-item color="">
          <mat-icon>select_all</mat-icon> Deselect
        </button>

        <button class="row select-btn" (click)="disableGroup()" mat-menu-item color="">
          <mat-icon>cancel</mat-icon>
          Disable
        </button>

        <button class="row select-btn" (click)="enableGroup()" mat-menu-item color="">
          <mat-icon>check_circle</mat-icon>
          Enable
        </button>

        <button class="" *ngIf="isPromoterOrAdmin == true" (click)="removeGroup()" mat-menu-item color="">
          <mat-icon>delete</mat-icon>
          Remove
        </button>
      </mat-menu>
    </div>
  </div>

  <div class="row clearfix">
    <mat-card class="list-card">
      <div class="row clearfix mb searchArea primary-gradient-img">
        <div class="row clearfix flex">
          <div class="full-width flex animated fadeInDown">
            <mat-icon class="mt">search</mat-icon>

            <mat-form-field class="full-width ml" appearance="outline">
              <mat-label>Filter by: Title , Audience</mat-label>
              <input
                class="clearfix"
                (input)="setFilter($event.target.value, 'fullLabel')"
                name="Labels"
                placeholder="Filter by: Title , Audience  "
                matInput
              />
            </mat-form-field>
          </div>
        </div>
      </div>

      <!-- Table -->
      <mat-table class="list-table" #table [dataSource]="dataSource" matSort>
        <ng-container cdkColumnDef="selectCheckBox" sticky>
          <mat-header-cell *cdkHeaderCellDef></mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <div class="margin-btm pull-left">
              <mat-checkbox
                [checked]="isSelected(row['_id'])"
                (change)="select(row['_id'], $event.checked)"
                color="accent"
              >
              </mat-checkbox>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="switchers">
          <mat-header-cell *cdkHeaderCellDef>
            <mat-icon>sort</mat-icon>
          </mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <div class="time-change-hours-class text-center flexcenter pull-left">
              <mat-icon
                class="fas fa-chevron-up clearfix change-time pull-left"
                (click)="moveWiki(row['_id'], 'up')"
                matTooltip="Move Category Up"
                color="primary"
              ></mat-icon>

              <mat-icon
                class="fas fa-chevron-down change-time clearfix pull-left"
                (click)="moveWiki(row['_id'], 'down')"
                matTooltip="Move Category Down"
                color="primary"
              ></mat-icon>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="title">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Title</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="moveToGroup(row['_id'])" matTooltip="{{ row['title'] }}">
            <strong>{{ row['title'] }}</strong>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="roles">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Audience</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="moveToGroup(row['_id'])">
            <div class="full-width mt" *ngIf="row && row.roles && row.roles.length > 0">
              <mat-chip-list class="pull-left mr" *ngFor="let r of row.roles">
                <mat-chip> {{ r }}</mat-chip>
              </mat-chip-list>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="isMenuDisplayed">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Side Menu</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="moveToGroup(row['_id'])">
            <span
              class="list-label smaller"
              *ngIf="row['menu'] == 'all'"
              matTooltip="Show all Articles on side menu"
              style="background-color: rgb(90, 186, 71)"
            >
              <mat-icon>check</mat-icon>
            </span>
            <span
              class="list-label smaller"
              *ngIf="row['menu'] == 'only'"
              matTooltip="Only Category name"
              style="background-color: rgb(207, 111, 0)"
            >
              <mat-icon>warning</mat-icon>
            </span>

            <span
              class="list-label smaller"
              *ngIf="row['menu'] == 'none'"
              matTooltip="Category hidden"
              style="background-color: rgb(255, 0, 0)"
            >
              <mat-icon>close</mat-icon>
            </span>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="IsActive">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Active</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="moveToGroup(row['_id'])">
            <span
              class="list-label smaller"
              *ngIf="row['isActive'] == true"
              matTooltip="Active"
              style="background-color: rgb(90, 186, 71)"
            >
              <mat-icon>check</mat-icon>
            </span>
            <span
              class="list-label smaller"
              *ngIf="row['isActive'] != true"
              matTooltip="Inactive"
              style="background-color: rgb(239, 71, 39)"
            >
              <mat-icon>close</mat-icon>
            </span>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Actions">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Actions</mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <div style="float: right">
              <button [matMenuTriggerFor]="menu" mat-icon-button>
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button *ngIf="row['_id'] && isPromoterOrAdmin == true" (click)="moveToGroup(row['_id'])" mat-menu-item>
                  <mat-icon>visibility</mat-icon>
                  <span>View Category</span>
                </button>

                <button *ngIf="row['_id'] && isPromoterOrAdmin == true" (click)="edit(row['_id'])" mat-menu-item>
                  <mat-icon>edit</mat-icon>
                  <span>Edit Category</span>
                </button>

                <button *ngIf="isPromoterOrAdmin == true" (click)="viewPages(row['_id'], row['title'])" mat-menu-item>
                  <mat-icon>book</mat-icon>
                  <span>View Articles</span>
                </button>

                <button
                  *ngIf="row['isActive'] == true && isPromoterOrAdmin == true"
                  (click)="disable(row['_id'])"
                  mat-menu-item
                >
                  <mat-icon>cancel</mat-icon>
                  <span>Disable</span>
                </button>

                <button
                  *ngIf="row['isActive'] != true && isPromoterOrAdmin == true"
                  (click)="enable(row['_id'])"
                  mat-menu-item
                >
                  <mat-icon>check_circle</mat-icon>
                  <span>Enable</span>
                </button>

                <button *ngIf="isPromoterOrAdmin == true" (click)="remove(row['_id'])" mat-menu-item>
                  <mat-icon>delete</mat-icon>
                  <span>Remove</span>
                </button>
              </mat-menu>
            </div>
          </mat-cell>
        </ng-container>
        <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *cdkRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
      <mat-paginator
        #paginator
        [length]="dataSource.filteredData.length"
        [pageIndex]="0"
        [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions"
      >
      </mat-paginator>
    </mat-card>
  </div>
</div>

<div class="showDocumentList" *ngIf="ShowPagesList == true">
  <div class="row clearfix">
    <app-wiki-list class="clearfix" [groupID]="selectGroupID" [title]="pageTitle"></app-wiki-list>
  </div>
  <div class="row clearfix text-center mt">
    <button (click)="hideViewDetails()" mat-raised-button color="accent">
      <mat-icon>chevron_left</mat-icon> Wiki Categories
    </button>
  </div>
</div>

<div *ngIf="listDB.data.length == 0 && listDB.firstLoadEvent != false">
  <app-empty-list-message></app-empty-list-message>
</div>
