import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Location } from '@angular/common';
import * as moment from 'moment';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { LookupService } from '../../../shared/services/lookup.service';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { ExistingPatientService } from '../shared/existing-patient.service';

@Component({
  selector: 'app-prospect-edit',
  templateUrl: './prospect-edit.component.html',
  styleUrls: ['./prospect-edit.component.css'],
})
export class ProspectEditComponent implements OnInit {
  @Input()
  prospectID;

  @Input()
  canEdit;

  @Input()
  isConcumer = false;

  prospect = {};

  @Output()
  close = new EventEmitter();

  isAdressValid = false;
  mySuburb;
  myState;
  myPostCode;
  myStreetNumber;
  myStreetName;
  myUnitNumber;
  myStreetType;
  myBirthday;
  myBirthdayDefault;
  myLatitude;
  myLongitude;

  validateMobile = false;
  validatePhone = false;
  validateEmail = false;
  phone;
  mobile;
  email;

  isFile = false;
  profileLink;

  titles = [];

  isModal = false;
  sessionType = 'guest';

  constructor(
    private location: Location,
    private authenticationService: AuthenticationService,
    private existingPatientService: ExistingPatientService,
    private lookupService: LookupService,
    private _changeDetectionRef: ChangeDetectorRef,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data && typeof data == 'string') {
      this.prospectID = data;

      this.isModal = true;
    }
  }

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((sessionType) => {
      if (sessionType) {
        this.sessionType = sessionType;
      }
      this.lookupService.getLookup('SimpleLookup', 'Salutation').subscribe((res) => {
        this.titles = res;
      });

      this.existingPatientService.getProspectDetails(this.prospectID, {}, this.sessionType).subscribe((res) => {
        this.prospect = res;
        this.getPicture();

        this.mySuburb = this.prospect['addresses.Suburb'];
        this.myState = this.prospect['addresses.State'];
        this.myPostCode = this.prospect['addresses.Postcode'];
        this.myStreetNumber = this.prospect['addresses.Street Nr'];
        this.myStreetName = this.prospect['addresses.Street Name'];
        this.myUnitNumber = this.prospect['addresses.Unit'];
        this.myStreetType = this.prospect['addresses.Street Type'];

        this.myLatitude = this.prospect['Address.Latitude'];
        this.myLongitude = this.prospect['Address.Longitude'];
        this.myBirthdayDefault = moment(this.prospect['DateOfBirth']).toDate();
      });
    });
  }

  ngAfterViewInit(): void {
    // Force another change detection in order to fix an occuring ExpressionChangedAfterItHasBeenCheckedError
    this._changeDetectionRef.detectChanges();
  }

  cancel() {
    this.location.back(); // <-- go back to previous location on cancel
  }

  getPicture() {
    this.profileLink = null;
    if (this.prospectID) {
      this.profileLink = this.existingPatientService.getProfilePicStreamLink(this.prospectID);
    }
  }

  onCompleteAll(fileID) {
    if (fileID && fileID[0] && this.prospectID) {
      const payload = {
        fileID: fileID[0],
      };

      this.profileLink = null;
      this.existingPatientService.uploadProfilePic(this.prospectID, payload).subscribe((res) => {
        this.getPicture();
      });
    }
  }

  editDetail() {
    const payload = {
      firstName: this.prospect['FirstName'],
      midleName: this.prospect['MiddleName'],
      lastName: this.prospect['Name'],
      email: this.email,
      mobile: this.mobile,
      phone: this.phone,
      title: this.prospect['Salutation'],
      abn: this.prospect['ABN'],
      acn: this.prospect['ACN'],
      companyType: this.prospect['CompanyType'],
      // tradingAs: this.prospect['TradingAs'],
      website: this.prospect['URL'],
      birthday: this.myBirthday,
      streetName: this.myStreetName,
      streetNumber: this.myStreetNumber,
      streetType: this.myStreetType,
      unitNumber: this.myUnitNumber,
      latitude: this.myLatitude,
      longitude: this.myLongitude,
      suburb: this.mySuburb,
      state: this.myState,
      postCode: this.myPostCode,
    };
    this.existingPatientService.editProspect(this.prospect['ID'], payload).subscribe((res) => {
      if (res && typeof res == 'object') {
        this.prospect = res;

        NotifyAppComponent.displayToast('success', 'Successful operation', 'Details have been successfully updated');
      }
    });
  }

  getAdress(adr) {
    if (adr) {
      this.mySuburb = adr.suburb;
      this.myState = adr.state;
      this.myPostCode = adr.postCode;
      this.myStreetNumber = adr.streetNumber;
      this.myStreetName = adr.streetName;
      this.myUnitNumber = adr.unitNumber;
      this.myStreetType = adr.streetType;
      this.myLatitude = adr.latitude;
      this.myLongitude = adr.longitude;
    }
  }

  isAdressValidEvent(v) {
    this.isAdressValid = v || false;
  }

  getMobile(m) {
    this.mobile = m;
  }

  getPhone(p) {
    this.phone = p;
  }

  getEmail(e) {
    this.email = e;
  }

  validateMobileEvent(v) {
    this.validateMobile = v;
  }

  validatePhoneEvent(v) {
    this.validatePhone = v;
  }

  validateEmailEvent(v) {
    this.validateEmail = v;
  }

  getDate(e) {
    this.myBirthday = e;
  }

  closeModal() {
    this.close.emit(true);
  }
}
