import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'session-change-avatar',
  templateUrl: './session-change-avatar.component.html',
  styleUrls: ['./session-change-avatar.component.css'],
})
export class SessionChangeAvatar implements OnInit {
  constructor() {}

  ngOnInit() {}
}
