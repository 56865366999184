import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'illness-allergy-icon',
  templateUrl: './illness-allergy-icon.component.html',
  styleUrls: ['./illness-allergy-icon.component.css'],
})
export class IllnessAllergyIconComponent implements OnInit {
  @Input()
  iconContent = '';

  iconLetters = '';

  @Input()
  illClass = '';

  @Input()
  mdIcon = false;

  @Input()
  numOfSteps;

  @Input()
  step;

  colour;

  constructor() {}

  ngOnInit() {
    this.rainbow(this.numOfSteps, this.step);
    if (this.iconContent) {
      this.iconLetters = this.iconContent.slice(0, 2);
    }
  }

  rainbow(numOfSteps, step) {
    // This function generates vibrant, "evenly spaced" colours (i.e. no clustering). This is ideal for creating easily distinguishable vibrant markers in Google Maps and other apps.
    // Adam Cole, 2011-Sept-14
    // HSV to RBG adapted from: http://mjijackson.com/2008/02/rgb-to-hsl-and-rgb-to-hsv-color-model-conversion-algorithms-in-javascript
    let r, g, b;
    let h = step / numOfSteps;
    let i = ~~(h * 6);
    let f = h * 6 - i;
    let q = 1 - f;
    switch (i % 6) {
      case 0:
        r = 1;
        g = f;
        b = 0;
        break;
      case 1:
        r = q;
        g = 1;
        b = 0;
        break;
      case 2:
        r = 0;
        g = 1;
        b = f;
        break;
      case 3:
        r = 0;
        g = q;
        b = 1;
        break;
      case 4:
        r = f;
        g = 0;
        b = 1;
        break;
      case 5:
        r = 1;
        g = 0;
        b = q;
        break;
    }
    this.colour =
      '#' +
      ('00' + (~~(r * 255)).toString(16)).slice(-2) +
      ('00' + (~~(g * 255)).toString(16)).slice(-2) +
      ('00' + (~~(b * 255)).toString(16)).slice(-2);
    return this.colour;
  }
}
