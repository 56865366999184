import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AuthenticationService } from '../shared/authentication.service';

@Component({
  selector: 'app-check-password',
  templateUrl: './check-password.component.html',
  styleUrls: ['./check-password.component.css'],
  animations: [
    trigger('simpleFadeAnimation', [
      state('in', style({ opacity: 1, height: '*' })),
      transition(':enter', [style({ opacity: 0, height: 0 }), animate(300)]),
      transition(':leave', animate(300, style({ opacity: 0, height: 0 }))),
    ]),
  ],
})
export class CheckPasswordComponent implements OnInit {
  password;

  @Output()
  isValid = new EventEmitter();

  @Output()
  getResult = new EventEmitter();

  isModal = true;
  @Output()
  close = new EventEmitter();

  isError = false;
  isLogin = false;
  hide = true;

  constructor(private authenticationService: AuthenticationService) {}

  ngOnInit() {
    this.authenticationService.isLogin().subscribe((res) => {
      this.isLogin = res;
    });
  }

  checkPassword() {
    this.authenticationService.checkPassword(this.password).subscribe((res) => {
      this.isValid.emit(res);

      if (res != true) {
        this.isError = true;
      } else if (res == true) {
        const p = {
          password: this.password,
        };
        this.getResult.emit(p);
      }
    });
  }

  closeEvent() {
    this.close.emit(true);
  }
}
