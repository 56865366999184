/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./error-modal.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i5 from "@angular/material/button";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/platform-browser/animations";
import * as i8 from "./error-modal.component";
var styles_ErrorModalAuthenticationComponent = [i0.styles];
var RenderType_ErrorModalAuthenticationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ErrorModalAuthenticationComponent, data: {} });
export { RenderType_ErrorModalAuthenticationComponent as RenderType_ErrorModalAuthenticationComponent };
export function View_ErrorModalAuthenticationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [["class", "rel error text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "mat-icon", [["class", "large-modal-icon mat-icon notranslate"], ["color", "accent"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(2, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["error"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h3", [["class", "sr-title sm-h3 no-pad"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Oops! There seems to be a problem."])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "p", [["class", ""]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["It appears that your account is currently inactive."])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Please contact Us."])), (_l()(), i1.ɵeld(10, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 6, "div", [["class", "drop-buttons"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 2, "button", [["class", "btn-large"], ["color", "primary"], ["mat-raised-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.contactUS() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatButton_0, i4.RenderType_MatButton)), i1.ɵdid(13, 180224, null, 0, i5.MatButton, [i1.ElementRef, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["Contact Us"])), (_l()(), i1.ɵeld(15, 0, null, null, 2, "button", [["class", "btn-large"], ["color", "accent"], ["mat-raised-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModalEvent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatButton_0, i4.RenderType_MatButton)), i1.ɵdid(16, 180224, null, 0, i5.MatButton, [i1.ElementRef, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["Close"]))], function (_ck, _v) { var currVal_2 = "accent"; _ck(_v, 2, 0, currVal_2); var currVal_5 = "primary"; _ck(_v, 13, 0, currVal_5); var currVal_8 = "accent"; _ck(_v, 16, 0, currVal_8); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).inline; var currVal_1 = (((i1.ɵnov(_v, 2).color !== "primary") && (i1.ɵnov(_v, 2).color !== "accent")) && (i1.ɵnov(_v, 2).color !== "warn")); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_3 = (i1.ɵnov(_v, 13).disabled || null); var currVal_4 = (i1.ɵnov(_v, 13)._animationMode === "NoopAnimations"); _ck(_v, 12, 0, currVal_3, currVal_4); var currVal_6 = (i1.ɵnov(_v, 16).disabled || null); var currVal_7 = (i1.ɵnov(_v, 16)._animationMode === "NoopAnimations"); _ck(_v, 15, 0, currVal_6, currVal_7); }); }
export function View_ErrorModalAuthenticationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-error-modal", [], null, null, null, View_ErrorModalAuthenticationComponent_0, RenderType_ErrorModalAuthenticationComponent)), i1.ɵdid(1, 114688, null, 0, i8.ErrorModalAuthenticationComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ErrorModalAuthenticationComponentNgFactory = i1.ɵccf("app-error-modal", i8.ErrorModalAuthenticationComponent, View_ErrorModalAuthenticationComponent_Host_0, {}, { closeModal: "closeModal" }, []);
export { ErrorModalAuthenticationComponentNgFactory as ErrorModalAuthenticationComponentNgFactory };
