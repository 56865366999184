import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Inject, Input, OnInit, Optional, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material';
import { WizardComponent } from 'angular-archwizard';
import { CreditCardValidator } from 'angular-cc-library';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';

import { MatDialogRef } from '@angular/material';
import { CustomerProspectService } from '../shared/customerProspect.service';

@Component({
  selector: 'app-customer-prospect-boost-modal',
  templateUrl: './customer-prospect-boost-modal.component.html',
  styleUrls: ['./customer-prospect-boost-modal.component.css'],
  animations: [
    trigger('simpleFadeAnimation', [
      state('in', style({ opacity: 1, height: '*' })),
      transition(':enter', [style({ opacity: 0, height: 0 }), animate(300)]),
      transition(':leave', animate(300, style({ opacity: 0, height: 0 }))),
    ]),
  ],
})
export class CustomerProspectBoostModalComponent implements OnInit {
  @Input()
  merchantID;

  @Input()
  campaignID;

  @Input()
  limitSelection = false;

  @ViewChild(WizardComponent, { static: false })
  public wizard: WizardComponent;

  closeModal = new EventEmitter();

  isAskPatient = true;
  isWashExistingList = false;
  isAcessSRDB = false;

  showCvvInfo = false;

  paymentForm: FormGroup;

  step = 1;

  modalTitle = 'Increase your audience - Boost your reach';

  isPromoterOrAdmin = false;

  priceComparisonSlide = 0;

  // costs will come from EP

  updateDetailsCost = 10.0;
  cleanListCost = 0.1;
  accessDataCost = 0.2;

  flagfall1 = 0.1;
  flagfall2 = 0.2;
  flagfall3 = 0.3;

  // patient numbers will come from EP

  currentPatients = 1123;
  projectedPatients = 10123;

  // option picker

  selectedOption;

  constructor(
    private fb: FormBuilder,
    private CustomerProspectService: CustomerProspectService,
    private authenticationService: AuthenticationService,
    public dialogRef: MatDialogRef<any>,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.merchantID != null) {
        this.merchantID = data.merchantID;
      }

      if (data.campaignID != null) {
        this.campaignID = data.campaignID;
      }

      if (data.limitSelection != null) {
        this.limitSelection = data.limitSelection;
      }
    }

    this.paymentForm = this.fb.group({
      cardNumber: ['', [CreditCardValidator.validateCCNumber, Validators.required]],
      cardExpireDate: ['', [CreditCardValidator.validateExpDate, Validators.required]],
      cardCVS: ['', [Validators.required, Validators.minLength(3) as any, Validators.maxLength(4) as any]],
      cardName: ['', [Validators.required, Validators.compose([Validators.minLength(2)])]],
    });
  }

  ngOnInit() {
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;
    });
  }

  // change modal title based on step
  setStep(e) {
    this.step = e;

    // set titles for main wizard steps
    if (this.step == 1) {
      this.modalTitle = 'Boost your reach to more patients';
    } else if (this.step == 2) {
      this.modalTitle = 'Review and confirm your campaign';
    } else if (this.step == 3) {
      this.modalTitle = 'Enter your payment information';
    } else if (this.step == 4) {
      this.modalTitle = 'Success!';
    }
  }

  // update modal size on selection change
  setProductOption(e) {
    this.priceComparisonSlide = e;

    if (this.priceComparisonSlide > 0) {
      this.dialogRef.updateSize('900px', '800px');
    } else {
      // return to original size
      if (this.limitSelection == true) {
        this.dialogRef.updateSize('750px', '800px');
      } else if (this.limitSelection == false) {
        this.dialogRef.updateSize('1250px', '800px');
      }
    }

    // set titles for option steps
    if (this.step == 1 && this.priceComparisonSlide == 1) {
      this.modalTitle = 'SMS campaign';
    } else if (this.step == 1 && this.priceComparisonSlide == 2) {
      this.modalTitle = 'Wash your data';
    } else if (this.step == 1 && this.priceComparisonSlide == 3) {
      this.modalTitle = 'Access new patients';
    } else if (this.step == 1 && this.priceComparisonSlide == 4) {
      this.modalTitle = 'Re-engage with Patients';
    } else if (this.step == 1 && this.priceComparisonSlide == 5) {
      this.modalTitle = 'Social Media posts';
    } else if (this.step == 1 && this.priceComparisonSlide == 0) {
      this.modalTitle = 'Boost your reach to more patients';
    }
  }

  close() {
    this.closeModal.emit(true);
  }

  // select options
  chooseAskPatient() {
    this.isWashExistingList = false;
    this.isAcessSRDB = false;
  }

  chooseWashExistingList() {
    this.isAskPatient = false;
    this.isAcessSRDB = false;
  }

  chooseAccessSRDB() {
    this.isAskPatient = false;
    this.isWashExistingList = false;
  }

  proceed() {
    const payment = {
      accountHolder: this.paymentForm.controls['cardName'].value,
      accountNumber: this.paymentForm.controls['cardNumber'].value,
      expiredDate: this.paymentForm.controls['cardExpireDate'].value,
      securityCode: this.paymentForm.controls['cardCVS'].value,
    };

    const payload = {
      label: 'Update contact details',
      template: 'campaign-M-CustomersAndProspects-Comm00-Update-your-details',
      sendSms: false,
      sendEmail: true,
      status: 'ACTV',
      accountHolder: payment.accountHolder,
      accountNumber: payment.accountNumber,
      expiredDate: payment.expiredDate,
      securityCode: payment.securityCode,
    };

    if (this.isPromoterOrAdmin == true) {
      payload['merchantID'] = this.merchantID;
    }

    this.CustomerProspectService.createMarketing(payload, this.isPromoterOrAdmin).subscribe((res) => {
      if (res) {
        this.closeModal.emit(true);
      }
    });
  }

  changeSelection() {
    this.isWashExistingList = false;
    this.isAskPatient = false;
    this.isAcessSRDB = false;

    if (this.selectedOption == 'op1') {
      this.isWashExistingList = true;
    } else if (this.selectedOption == 'op2') {
      this.isAskPatient = true;
    } else if (this.selectedOption == 'op3') {
      this.isAcessSRDB = true;
    }
  }

  contactUs() {
    const data = {
      targetType: 'us',
      showBooking: true,
    };
    AuthenticationService.contactInputEvent.emit(data);
  }
}
