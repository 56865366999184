<mat-card class="rel create-container supplier-card brandView clearfix mb" @ngIfAnimation>
  <div class="card-header primary-gradient-img clearfix inModal rel" *ngIf="module && module.ID">
    <div class="row clearfix content">
      <div class="mr pull-left text-center social-media-icon">
        <mat-icon class="primary-color big-icon" *ngIf="isIndexOf(icon, 'fa-') == false"> {{ icon }}</mat-icon>
        <mat-icon class="fas {{ icon }}  primary-color big-icon" *ngIf="isIndexOf(icon, 'fa-') != false"></mat-icon>
      </div>
      <button class="btn-close btn-clear mat-button white" *ngIf="isModal == true" (click)="closeModal()">
        <mat-icon>clear</mat-icon>
      </button>
      <div class="row titleArea clearfix">
        <h2 class="summary-header rm-m">
          Edit Module
          <span class="subLabel">Insert and customize Module details </span>
        </h2>
      </div>
    </div>
  </div>
  <div class="full-width" @ngIfAnimation>
    <aw-wizard class="template-module-create" #wizard [disableNavigationBar]="true" navBarLayout="large-filled-symbols">
      <aw-wizard-step [navigationSymbol]="{ symbol: '1' }" stepTitle="Define template" awOptionalStep>
        <div class="full-width clearfix mt" *ngIf="module && module.ID">
          <form #resetform="ngForm">
            <div class="full-width clearfix flex row mb">
              <mat-card class="full-width row mr animate mat-elevation-z4">
                <h3 class="sr-title">Module details</h3>
                <div class="full-width flex mb">
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Label</mat-label>
                    <input [(ngModel)]="label" name="label" matInput placeholder="Label" required/>
                  </mat-form-field>
                </div>
                <div class="full-width clearfix">
                  <mat-form-field class="full-width animate" appearance="outline">
                    <mat-label>Description</mat-label>
                    <textarea
                      class="mat-block main-textarea"
                      [(ngModel)]="description"
                      matInput
                      name="description"
                      placeholder="Description"
                      required
                    >
                    </textarea>
                  </mat-form-field>
                </div>
                <p class="ml mt">
                  Insert an Icon of the module, you can get the list from
                  <a href="https://fontawesome.com/v5.15/icons?d=gallery&p=2" target="_blank">Here</a>
                </p>
                <div class="full-width flex">
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Icon for example: fas fa-info</mat-label>
                    <input [(ngModel)]="icon" name="icon" matInput placeholder="Icon" required/>

                    <span matSuffix>
                      <mat-icon class="{{ icon }}" [attr.style]="'color:#808080 !important' | safeStyle"> </mat-icon>
                    </span>
                  </mat-form-field>
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Status</mat-label>
                    <mat-select [(ngModel)]="status" name="Status" placeholder="status" required>
                      <mat-option *ngFor="let s of statusArray" [value]="s.code">
                        {{ s.value }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="full-width flex mb">
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Wiki Link</mat-label>
                    <input [(ngModel)]="wikiUrl" name="wikiLink" matInput placeholder="Wiki Link"/>
                  </mat-form-field>
                  <button
                    class="pull-right smt sml smr"
                    *ngIf="wikiUrl"
                    (click)="openLink(wikiUrl)"
                    mat-icon-button
                    color="primary"
                    matTooltip="Click to open Wiki link"
                  >
                    <mat-icon>open_in_browser</mat-icon>
                  </button>
                </div>
                <h3 class="sr-title">Marketing descriptions</h3>
                <p class="ml">Insert one or multiple Marketing descriptions to be displayed for the user</p>
                <div class="full-width">
                  <div class="list full-width mr">
                    <div class="full-width" *ngFor="let item of marketingDescriptions; let idx = index">
                      <div class="full-width flex">
                        <mat-form-field class="full-width" appearance="outline">
                          <mat-label>{{ idx + 1 }}- Insert a description</mat-label>
                          <textarea
                            class="mat-block sub-textarea"
                            [(ngModel)]="item"
                            (change)="changeMarketing(idx, $event.target.value)"
                            matInput
                            placeholder="{{ idx + 1 }}- Insert a description"
                            name="Insertadescription {{ idx }}"
                          >
                          </textarea>
                        </mat-form-field>
                        <button
                          class="pull-right smr marketing-delete"
                          *ngIf="marketingDescriptions && marketingDescriptions.length > 1"
                          (click)="removeMarketingDescription(idx)"
                          mat-icon-button
                          color="primary"
                        >
                          <mat-icon>delete</mat-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                  <button class="btn mt pull-right" (click)="addMarketingDescription()" mat-mini-fab color="primary">
                    <mat-icon>add</mat-icon>
                  </button>
                </div>
              </mat-card>
              <div class="qtr-width row blob-for-module-container-thumbnail ml mr">
                <div class="full-width">
                  <app-ep-document-view-image
                    class="squareImg squareImg-social"
                    *ngIf="!postPictureLinkThumbnail && postPictureThumbnail"
                    [hideZoomButton]="false"
                    [defaultPicture]="
                      'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/bell_icon.png)'
                    "
                    [link]="postPictureThumbnail"
                  >
                  </app-ep-document-view-image>
                  <app-ep-document-view-image
                    class="squareImg squareImg-social"
                    *ngIf="postPictureLinkThumbnail && !postPictureThumbnail"
                    [hideZoomButton]="false"
                    [defaultPicture]="
                      'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/bell_icon.png)'
                    "
                    [link]="postPictureLinkThumbnail"
                  >
                  </app-ep-document-view-image>
                  <app-ep-document-view-image
                    class="squareImg squareImg-social"
                    *ngIf="!postPictureLinkThumbnail && !postPictureThumbnail"
                    [hideZoomButton]="false"
                    [defaultPicture]="
                      'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/bell_icon.png)'
                    "
                  >
                  </app-ep-document-view-image>
                </div>
                <div class="clearfix full-width">
                  <hr/>
                  <app-file-uploader
                    class="text-center clearfix full-width"
                    [allowedExtensions]="allowExtensions"
                    [aspectRatioType]="'full'"
                    [maxFileSize]="2"
                    [isImageCropping]="true"
                    [canSkip]="true"
                    [limit]="1"
                    [uploadDirectly]="true"
                    [isDragable]="true"
                    [title]="'CLICK HERE to upload (Max size: ' + 2 + 'MB)'"
                    (onCompleteAll)="onCompleteThumbnail($event)"
                  >
                  </app-file-uploader>
                </div>
              </div>
            </div>
            <div class="full-width row mr">
              <div class="clearfix full-width mb">
                <h3 class="sr-title mt">Cover Image</h3>
                <p>
                  Recommended dimension is <strong>1000x250 px</strong> (only images are allowed). Max image size is
                  {{ maxFileSize }} MB
                </p>
                <div class="full-width mb blob-for-module-container">
                  <app-blob-view
                    class="row clearfix flex flexCol blob-for-social-media full-width full-height"
                    *ngIf="(!postPictureLink && postPicture && loadingPost != true) || loadingPost == true"
                    [label]="'Campaign'"
                    [applyDownload]="applyDownload"
                    [loading]="loadingPost"
                    [isDownload]="false"
                    [isProceed]="false"
                    [isPrint]="false"
                    [canProceed]="false"
                    [content]="postPicture"
                    [extension]="'png'"
                    [label]=""
                  >
                  </app-blob-view>
                  <div
                    class="view-container full-height full-width"
                    *ngIf="postPictureLink && !postPicture && loadingPost != true"
                  >
                    <div class="row clearfix">
                      <pinch-zoom
                        class="full-height clearfix full-width pinchZoom"
                        [limit-zoom]="25"
                        [wheel]="true"
                        [wheelZoomFactor]="0.5"
                        [double-tap]="true"
                        [properties]="pinchZoomProperties"
                      >
                        <img [src]="postPictureLink" />
                      </pinch-zoom>
                    </div>
                  </div>
                  <div
                    class="view-container full-height full-width"
                    *ngIf="
                      (!postPicture || postPicture == null) &&
                      (!postPictureLink || postPictureLink == null) &&
                      loadingPost != true
                    "
                  >
                    <div class="row clearfix">
                      <pinch-zoom
                        class="full-height clearfix full-width pinchZoom"
                        [limit-zoom]="25"
                        [wheel]="true"
                        [wheelZoomFactor]="0.5"
                        [double-tap]="true"
                        [properties]="pinchZoomProperties"
                      >
                        <img
                          [src]="
                            'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/cover/pic4.png'
                          "
                        />
                      </pinch-zoom>
                    </div>
                  </div>
                </div>
                <div class="clearfix full-width">
                  <app-file-uploader
                    class="text-center clearfix full-width"
                    [allowedExtensions]="allowExtensions"
                    [aspectRatioType]="'third'"
                    [maxFileSize]="maxFileSize"
                    [isFixedAspectRatio]="false"
                    [isImageCropping]="true"
                    [canSkip]="false"
                    [limit]="1"
                    [uploadDirectly]="true"
                    [isDragable]="true"
                    [title]="'CLICK HERE to upload  Cover (Max size: ' + maxFileSize + 'MB)'"
                    (onCompleteAll)="onComplete($event)"
                  >
                  </app-file-uploader>
                </div>
              </div>
            </div>
            <hr class="mb"/>
            <div class="drop-button full-width mt">
              <button class="btn-large pull-left" (click)="moduleList()" mat-raised-button color="primary">
                <mat-icon>chevron_left</mat-icon> Module list
              </button>
              <button
                class="btn-large pull-right"
                [disabled]="!resetform.form.valid"
                (click)="proceed()"
                mat-raised-button
                color="accent"
              >
                Next
                <mat-icon>chevron_right</mat-icon>
              </button>
            </div>
          </form>
        </div>
      </aw-wizard-step>
      <aw-wizard-step [navigationSymbol]="{ symbol: '2' }" stepTitle="Marketing Content" awOptionalStep>
        <div class="container mt" id="step-4">
          <app-html-input
            class="mb"
            [label]="label"
            [EPDocumentID]="fileIDHtmlEP"
            [inputType]="inputType"
            [content]="htmlContent"
            [structure]="structure"
            [result]="htmlResult"
            [title]="titleHtml"
            [description]="descriptionHtml"
            [descriptionDocument]="descriptionDocumentHtml"
            [descriptionRaw]="descriptionRawHtml"
            [descriptionEditor]="descriptionEditorHtml"
            (getResult)="edit($event)"
          ></app-html-input>

          <div class="drop-button full-width mt text-center">
            <button class="btn-large pull-left" (click)="stepBack()" mat-raised-button color="primary">
              <mat-icon>chevron_left</mat-icon> Previous
            </button>
            <button class="btn-large pull-right" (click)="sendResult()" mat-raised-button color="accent">
              Modify
              <mat-icon>chevron_right</mat-icon>
            </button>
          </div>
        </div>
      </aw-wizard-step>
      <aw-wizard-step [navigationSymbol]="{ symbol: '3' }" stepTitle="Done!" awOptionalStep>
        <div class="container mt" id="step-2" *ngIf="createdRecord && createdRecord.ID" @ngIfAnimation>
          <div class="animate clearfix">
            <div class="row clearfix">
              <mat-card class="card successful">
                <div class="row clearfix">
                  <div class="full-width text-center">
                    <h1 class="sm-h1 sr-title">Well Done!</h1>
                    <h3 class="sm-h3 rm-m">{{ 'Module is Modified' | translate }}</h3>
                    <div class="row clearfix">
                      <div class="svg-box">
                        <svg class="circular green-stroke">
                          <circle
                            class="path"
                            cx="75"
                            cy="75"
                            r="50"
                            fill="none"
                            stroke-width="5"
                            stroke-miterlimit="10"
                          />
                        </svg>
                        <svg class="checkmark green-stroke">
                          <g transform="matrix(0.79961,8.65821e-32,8.39584e-32,0.79961,-489.57,-205.679)">
                            <path
                              class="checkmark__check"
                              fill="none"
                              d="M616.306,283.025L634.087,300.805L673.361,261.53"
                            />
                          </g>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row clearfix text-center">
                  <button class="mr" (click)="moduleList()" mat-raised-button color="primary">
                    <mat-icon>list</mat-icon> Module list
                  </button>
                  <button class="ml" (click)="viewModule(createdRecord.ID)" mat-raised-button color="accent">
                    <mat-icon>visibility</mat-icon>
                    <span>View Module</span>
                  </button>
                </div>
              </mat-card>
            </div>
          </div>
        </div>
      </aw-wizard-step>
    </aw-wizard>
  </div>
</mat-card>
