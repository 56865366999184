<div class="clearfix rel">
  <button class="btn-close btn-clear pull-right mat-button" *ngIf="isModal == true" (click)="closeClicked()">
    <mat-icon>clear</mat-icon>
  </button>

  <ng-container *ngIf="settlements$ | async as settlements">
    <div
      class="row white-background full-width clearfix"
      *ngIf="contractID && settlements && settlements.length > 0"
      @onChangeAnimation
    >
      <div class="animate">
        <div class="title-area mb">
          <ng-container *ngIf="status !== 'payment-schedule' && status !== 'statement'">
            <h3 class="sr-title rm-m clearfix prodTitle" *ngIf="status && status.id == 'all'" @labelAnimation>
              <mat-icon class="fas {{ status['icon'] }}  h2-icon"></mat-icon>
              Settlement requests
              <span class="badge" [endVal]="settlements.length" countUp></span>
            </h3>

            <h3 class="sr-title rm-m clearfix prodTitle" *ngIf="status && status.id != 'all'" @labelAnimation>
              <mat-icon class="fas {{ status['icon'] }}  h2-icon"></mat-icon>
              {{ status['label'] }} Settlement(s)
              <span
                class="badge"
                [endVal]="
                  settlements
                    | FilterArrayMultipleValue: 'Status.Code':status['settlementStatus']
                    | FilterArrayMultipleContainValue: 'PaymentStatus.Label':status['paymentStatus']
                    | lengthOfArray
                "
                countUp
              ></span>
              <span class="span-total-color pull-right">
                {{
                settlements
                  | FilterArrayMultipleValue: 'Status.Code':status['settlementStatus']
                  | FilterArrayMultipleContainValue: 'PaymentStatus.Label':status['paymentStatus']
                  | objectPropertyValue: 'Settlement.Amount'
                  | toNumberArray
                  | sum
                  | customCurrency
                }}
              </span>
            </h3>
          </ng-container>

          <ng-container *ngIf="status === 'payment-schedule'">
            <h3 class="sr-title rm-m clearfix prodTitle" @labelAnimation>
              <mat-icon class="fas fa-calendar-alt h2-icon"></mat-icon>
              Payment Schedule
            </h3>
          </ng-container>

          <ng-container *ngIf="status === 'statement'">
            <h3 class="sr-title rm-m clearfix prodTitle" @labelAnimation>
              <mat-icon class="fas fa-money-check-alt h2-icon" style="margin-right: 5px"></mat-icon>
              Statement
            </h3>
          </ng-container>
        </div>

        <div class="mt mb clearfix" *ngIf="updated == true">
          <div class="toggle-card row clearfix">
            <mat-button-toggle-group class="btn-group row clearfix flex full-width text-center" [(ngModel)]="status">
              <mat-button-toggle
                class="text-center full-width"
                *ngFor="let s of statusArray"
                [hidden]="
                  !(
                    settlements
                    | FilterArrayMultipleValue: 'Status.Code':s['settlementStatus']
                    | FilterArrayMultipleContainValue: 'PaymentStatus.Label':s['paymentStatus']
                    | FilterArrayMultipleContainValue: 'SettleTo.TableName':s['tableName']
                    | validateArrayLength
                  )
                "
                [ngClass]="status['id'] == s['id'] ? 'selectStatus' : ''"
                [value]="s"
              >
                <mat-icon class="fas {{ s['icon'] }} "></mat-icon>
                <span class="badge-settlement"
                  matBadge="{{
                    settlements
                      | FilterArrayMultipleValue: 'Status.Code':s['settlementStatus']
                      | FilterArrayMultipleContainValue: 'PaymentStatus.Label':s['paymentStatus']
                      | FilterArrayMultipleContainValue: 'SettleTo.TableName':s['tableName']
                      | lengthOfArray
                  }}"
                  matBadgeColor="accent"
                  matBadgeOverlap="false"
                >{{ s['label'] }}</span
                >
              </mat-button-toggle>

              <mat-button-toggle *ngIf="isContractApproved | binaryBool" style="    border-left: solid 1px rgba(0, 0, 0, 0.12);     border-right: solid 1px rgba(0, 0, 0, 0.12);" value="payment-schedule">
                <mat-icon class="fas fa-calendar-alt"></mat-icon>
                <span> Payment Schedule </span>
              </mat-button-toggle>

              <mat-button-toggle *ngIf="isContractApproved | binaryBool" style="    border-left: solid 1px rgba(0, 0, 0, 0.12);     border-right: solid 1px rgba(0, 0, 0, 0.12);"  value="statement">
                <mat-icon class="fas fa-money-check-alt"></mat-icon>
                <span> Statement </span>
              </mat-button-toggle>
            </mat-button-toggle-group>
            <hr class="mb"/>
          </div>

          <ng-container *ngIf="status !== 'payment-schedule' && status !== 'statement'">
            <div *ngIf="updated == true" [class.overFlowContainer]="isModal == true">
              <div
                *ngFor="
                  let s of settlements
                    | FilterArrayMultipleValue: 'Status.Code':status['settlementStatus']
                    | FilterArrayMultipleContainValue: 'PaymentStatus.Label':status['paymentStatus']
                    | FilterArrayMultipleContainValue: 'SettleTo.TableName':status['tableName']
                    | OrderArrayObject: ['-DateTimeCreated'];
                  let i = index
                "
              >
                <settlement-view-group
                  class="clearfix"
                  *ngIf="s['DrawDownGroupID'] != ''"
                  [title]="'Settlement Group'"
                  [settlementGroupID]="s['DrawDownGroupID']"
                  (changedSettlements)="settlementsChanged($event, settlements)"
                ></settlement-view-group>

                <settlement-view-mini
                  class="clearfix"
                  *ngIf="s['DrawDownGroupID'] == ''"
                  [settlement]="s"
                  [isExpanded]="isMiniSettlementExpanded"
                  (changedSettlements)="settlementsChanged($event, settlements)"
                ></settlement-view-mini>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="status === 'payment-schedule'">
            <div class="padded">
              <ng-container *ngIf="scheduleOfPayments$ | async as scheduleOfPayments">
                <div
                  style="margin-top: 10px; margin-bottom: 40px; width: 100%; display: flex; justify-content: flex-end"
                >
                  <button
                    (click)="
                      openTablePrint(
                        scheduleOfPayments,
                        'Payment Schedule for ' + this.contractName,
                        {
                          date_due: date_due,
                          amount: amount,
                          description: description,
                          status: statusPrintable
                        },
                        { amount: 'right' },
                        { date_due: 'Date Due' }
                      )
                    "
                    color="accent"
                    mat-raised-button
                    style="margin-left: 30px; height: 36px"
                  >
                    <mat-icon class="fas fa-print"></mat-icon>
                    Print Schedule
                  </button>

                  <button
                    (click)="sendScheduleEmail()"
                    color="accent"
                    mat-raised-button
                    style="margin-left: 30px; height: 36px"
                  >
                    <mat-icon class="fas fa-envelope"></mat-icon>
                    Email Schedule
                  </button>
                </div>

                <ipv-data-table
                  [table]="scheduleOfPayments"
                  [customComponents]="{ date_due: date_due, amount: amount, description: description, status: status }"
                  [formatHeaders]="{ amount: 'right' }"
                  [rename]="{ date_due: 'Date Due' }"
                  [paginate]="false"
                >
                </ipv-data-table>

                <ng-template #date_due let-record>
                  <span *ngIf="record.date_due !== 'total'; else dateTotalLabel">
                    {{ record.date_due | customDate }}
                  </span>
                  <ng-template #dateTotalLabel> Total</ng-template>
                </ng-template>

                <ng-template #description let-record>
                  <div style="text-align: left">{{ record.description | customCurrency }}</div>
                </ng-template>

                <ng-template #amount let-record>
                  <div style="text-align: right">
                    <span
                      *ngIf="!isScheduleAmountTotal(scheduleOfPayments, record.amount); else totalAmount"
                      [ngStyle]="{ color: record.amount > 0 ? 'red' : 'black' }"
                    >
                      {{ record.amount | invertNumber | customCurrency }}
                    </span>

                    <ng-template #totalAmount>
                      <span [ngStyle]="{ color: record.amount > -1 ? 'black' : 'red' }">
                        {{ record.amount | customCurrency }}
                      </span>
                    </ng-template>
                  </div>
                </ng-template>

                <ng-template #status let-record>
                  <ng-container *ngIf="record.status !== null">
                    <ipv-list-label
                      *ngIf="record.status === '0' || record.status === '5'"
                      [color]="'rgb(251, 169, 25)'"
                    >
                      Pending
                    </ipv-list-label>

                    <ipv-list-label *ngIf="record.status === '1'"> Paid</ipv-list-label>
                  </ng-container>
                </ng-template>

                <ng-template #statusPrintable let-record>
                  <ng-container *ngIf="record.status !== null">
                    <ipv-list-label-print-compatible
                      *ngIf="record.status === '0' || record.status === '5'"
                      [color]="'rgb(251, 169, 25)'"
                    >
                      Pending
                    </ipv-list-label-print-compatible>

                    <ipv-list-label-print-compatible *ngIf="record.status === '1'">
                      Paid
                    </ipv-list-label-print-compatible>
                  </ng-container>
                </ng-template>
              </ng-container>
            </div>
          </ng-container>

          <ng-container *ngIf="status === 'statement'">
            <div class="padded">
              <ng-container *ngIf="contractStatement$ | async as contractStatement">
                <div
                  style="margin-top: 10px; margin-bottom: 40px; width: 100%; display: flex; justify-content: flex-end"
                >
                  <button
                    (click)="
                      openTablePrint(
                        contractStatement,
                        'Statement for ' + this.contractName,
                        {
                          credits: credits,
                          debits: debits,
                          balance: balance,
                          date: date
                        },
                        { credits: 'right', debits: 'right', balance: 'right' }
                      )
                    "
                    color="accent"
                    mat-raised-button
                    style="margin-left: 30px; height: 36px"
                  >
                    <mat-icon class="fas fa-print"></mat-icon>
                    Print Statement
                  </button>

                  <button
                    (click)="sendStatementEmail()"
                    color="accent"
                    mat-raised-button
                    style="margin-left: 30px; height: 36px"
                  >
                    <mat-icon class="fas fa-envelope"></mat-icon>
                    Email Statement
                  </button>
                </div>

                <ipv-data-table
                  [table]="contractStatement"
                  [customComponents]="{ credits: credits, debits: debits, balance: balance, date: date }"
                  [formatHeaders]="{ credits: 'right', debits: 'right', balance: 'right' }"
                  [paginate]="false"
                >
                </ipv-data-table>

                <ng-template #date let-record>
                  <span *ngIf="record.date !== 'Total'; else statementTotal"> {{ record.date | customDate }} </span>
                  <ng-template #statementTotal> {{ record.date }} </ng-template>
                </ng-template>

                <ng-template #credits let-record>
                  <div *ngIf="record.credits > 0" style="text-align: right; color: red">
                    {{ record.credits | invertNumber | customCurrency }}
                  </div>
                </ng-template>

                <ng-template #debits let-record>
                  <div *ngIf="record.debits > 0" style="text-align: right">{{ record.debits | customCurrency }}</div>
                </ng-template>

                <ng-template #balance let-record>
                  <div [ngStyle]="{ color: record.balance > -1 ? 'black' : 'red' }" style="text-align: right">
                    {{ record.balance | customCurrency }}
                  </div>
                </ng-template>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>
</div>
