import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';

@Component({
  selector: 'instant-offer-settings-overview',
  templateUrl: './instant-offer-settings-overview.component.html',
  styleUrls: ['./instant-offer-settings-overview.component.css'],
})
export class InstantOfferSettingsOverviewComponent implements OnInit {
  settings = Settings.global;
  loadIFrame = true;
  hideIframe = false;
  iFrameSrcSecured;

  treatmentAmountPreview = 5000;
  theme = {
    code: 'blue',
    background: 'linear-gradient(135deg, #0092eb 0%, #0dbad3 100%)',
    color: '#1b8bdd',
  };

  merchantID;

  constructor(
    private _sanitizer: DomSanitizer,
    private changeDetectorRef: ChangeDetectorRef,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit() {
    if (Settings.global['context'] === 'p4p') {
      this.theme = {
        code: 'ee350b',
        background: ' linear-gradient(-135deg, #1caaa0 -10.31%, #7462ef  97.05%)',
        color: '#7462ef',
      };
    }

    this.authenticationService.getCurrentPractice().subscribe((r) => {
      if (r && r.ID) {
        this.merchantID = r.ID;
      }

      this.iFrameSrcSecured = this._sanitizer.bypassSecurityTrustResourceUrl(
        this.settings['publicSiteFrontendLink'] + '/offer-settings-amount/' + this.treatmentAmountPreview
      );
    });
  }

  onFrameLoad(e) {
    if (!this.loadIFrame) {
      this.loadIFrame = true;
      this.changeDetectorRef.detectChanges();
    } else {
      this.loadIFrame = true;
    }
  }

  getTheme(r) {
    if (r) {
      this.theme = r;
    }
  }
  getResult(r) {
    if (r && r.ID) {
      UtilsClass.scrollUp();
      this.hideIframe = true;
      this.loadIFrame = false;

      this.iFrameSrcSecured = this._sanitizer.bypassSecurityTrustResourceUrl(
        this.settings['publicSiteFrontendLink'] +
          '/offer-settings/' +
          this.merchantID +
          '/' +
          this.treatmentAmountPreview
      );

      setTimeout(() => {
        this.loadIFrame = true;
        this.hideIframe = false;
      }, 500);
    }
  }
  refresh() {
    UtilsClass.scrollUp();
    this.hideIframe = true;
    this.loadIFrame = false;

    this.iFrameSrcSecured = this._sanitizer.bypassSecurityTrustResourceUrl(
      this.settings['publicSiteFrontendLink'] + '/offer-settings/' + this.merchantID + '/' + this.treatmentAmountPreview
    );

    setTimeout(() => {
      this.loadIFrame = true;
      this.hideIframe = false;
    }, 500);
  }
  openNewTab() {
    window.open(
      this.settings['publicSiteFrontendLink'] +
        '/offer-settings/' +
        this.merchantID +
        '/' +
        this.treatmentAmountPreview,
      '_blank'
    );
  }
}
