import { Component, ElementRef, EventEmitter, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator, MatSort } from '@angular/material';
import { map as _map, orderBy, uniq } from 'lodash';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { IframeViewComponent } from '../../../shared/components/iframe-view/iframe-view.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { SideMenuService } from '../../../shared/services/side-menu.service';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { ProductUtil } from '../../product/shared/productUtil.type';
import { InstantOfferService } from '../shared/instant-finance-offer.service';

import { map } from 'rxjs/operators';

import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { DataSource } from '@angular/cdk/table';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { BehaviorSubject, merge as observableMerge, Observable } from 'rxjs';
import { CustomDatePipe } from '../../../shared/pipes/custom-date.pipe';
import { LookupService } from '../../../shared/services/lookup.service';
import { UtilsService } from '../../../shared/services/utils.service';
import { CustomerProspectViewModelComponent } from '../../customer-prospect/customer-prospect-view-model/customer-prospect-view-model.component';
import { DentistViewComponent } from '../../dentist/dentist-view/dentist-view.component';
import { MerchantViewComponent } from '../../merchant/merchant-view/merchant-view.component';
import { SmsConversationComponent } from '../../message/sms-conversation/sms-conversation.component';
import { SmsPromoterComponent } from '../../message/sms-promoter/sms-promoter.component';
import { InstantOfferViewComponent } from '../instant-offer-view/instant-offer-view.component';

@Component({
  selector: 'instant-offer-list',
  templateUrl: './instant-offer-list.component.html',
  styleUrls: ['./instant-offer-list.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.row', style({ opacity: 0 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.5s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.row', style({ opacity: 1 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.5s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class InstantOfferListComponent implements OnInit {
  productUtil = new ProductUtil();
  isPromoterOrAdmin = false;

  typeFilter = null;
  typeFilter2;
  stretchList = false;
  @Input()
  title = 'Finance Offers';

  @Input()
  merchantID;
  @Input()
  patientID;
  @Input()
  isActive;

  isActiveFilter;

  @Input()
  pageSize = Settings.global['listPageSize'] || 20;
  pageSizeOptions = [10, Number(this.pageSize), Number(this.pageSize) * 2, Number(this.pageSize) * 3];

  utils = new UtilsClass();

  displayedColumns = [
    'selectCheckBox',
    'Picture',
    'Patient',
    'treatmentAmount',
    'Source',
    'Practice',

    // 'Dentist',
    'productGroup',
    'offerStatus',
    'invitationStatus',
    'applicationStatus',
    'Contract',
    'Tags',
    'dateViewed',
    'Date',
    'Actions',
  ];
  filters = [];

  selectedIDs = [];

  searchVals = new FormControl();

  searchValList = ['Label'];

  expandedRow: number;
  public listDB: LoadRecords | null;
  dataSource: RecordDataSource | null;

  merchantTags = [];

  filteredSize = null;

  themeObject = {};

  searchLabel;
  sessionType;

  currentPractice;

  isModulePatientSMSActive = Settings.global['isModulePatientSMSActive'];
  renderValue = {};

  offerStatusLookup = [];
  dynamicData = [];
  destroyEvent = new EventEmitter();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('filter', { static: false }) filter: ElementRef;


  innerSubscription = false;


  constructor(
    private utilService: UtilsService,
    private router: Router,
    private customDate: CustomDatePipe,
    private authenticationService: AuthenticationService,
    private instantOfferService: InstantOfferService,
    private lookupService: LookupService
  ) {}

  ngOnInit() {
    const p = {
      isPromoter: true,
      fields: 'Label',
    };

    this.lookupService.getLookup('CodeLookup', 'Instant_Finance_Offer_Status').subscribe((res) => {
      if (res && res.length > 0) {
        this.offerStatusLookup = res;
      }
    });

    this.instantOfferService.instantOfferListTheme().subscribe((t) => {
      if (t && t.length > 0) {
        _map(t, (item) => {
          if (item && item.code) {
            this.themeObject[item.code] = item.background;
          }

          return true;
        });
      }
    });
    this.utilService.getCurrentAccess().subscribe((access) => {
      if (access) {
        this.isModulePatientSMSActive = access['isModulePatientSMSActive'];
      }
    });
    this.authenticationService.getCurrentPractice().subscribe((currentPractice) => {
      this.currentPractice = currentPractice;

      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isPromoterOrAdmin = res;

        this.displayCollumnFilter();

        this.authenticationService.getSessionType().subscribe((st) => {
          this.sessionType = st;


          SideMenuService.goBack.subscribe((res) => {
            if (res == true) {
              this.hideViewDetails();
            }
          });



        });
      });
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    const params = {
      merchantID: this.merchantID,
      patientID: this.patientID,
      isActive: this.isActive,
    };
    this.listDB = new LoadRecords(this.instantOfferService, this.destroyEvent, params, this.sessionType);
    this.dataSource = new RecordDataSource(this.listDB, this.paginator, this.sort);

    if (this.innerSubscription == false && this.listDB) {

      this.listDB.getDefaultFilter.subscribe((d) => {
        this.typeFilter = d;
        this.setFilter(d, 'IsDefault');
      });
      this.listDB.getMerchantTags.subscribe((tags) => {
        this.merchantTags = JSON.parse(JSON.stringify(tags || []));
      });

    }
  }

  displayCollumnFilter() {
    if (this.isPromoterOrAdmin != true || this.merchantID) {
      const index = this.displayedColumns.findIndex((item) => {
        if (item === 'Practice') {
          return true;
        }

        return false;
      });

      if (index != -1) {
        this.displayedColumns.splice(index, 1);
      }
    }
  }

  hideViewDetails() {}

  setFilter(event, field) {
    let filter;

    if (field == 'ApprovedOrActive') {
      let _event;
      if (event == true) {
        _event = '1';
      } else {
        _event = '0';
      }
      filter = {
        field,
        value: _event,
      };

      this.searchLabel = _event;
    } else if (field == 'Tags' && event && event.length > 0) {
      let e = event;
      if (typeof e === 'string') {
        e = [event];
      }

      filter = {
        field,
        value: e,
      };

      this.searchLabel = event;
    } else if (typeof event == 'object' && event != null) {
      const v = this.customDate.transform(event, Settings.global['dateFormat']);
      filter = {
        field,
        value: v,
      };

      this.searchLabel = v;
    } else if (event) {
      filter = {
        field,
        value: event,
      };

      this.searchLabel = event;
    } else {
      filter = {
        field,
        value: '',
      };

      this.searchLabel = null;
    }

    this.dataSource.filter = filter;
    this.filteredSize = this.dataSource.filteredData.length;
  }

  removed(id) {
    if (id) {
      const confirm = new ConfirmDialog('fas fa-info', '', 'Are you sure you want to remove this Offer ?', 'No', 'Yes');

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation === false) {
          ref.close();
        } else {
          this.instantOfferService.deleteInstantOffer(id, this.sessionType).subscribe((res) => {
            if (res) {
              this.dataSource.removeItem = id;

              NotifyAppComponent.displayToast(
                'success',
                'Successful operation',
                'The Offer has been successfully removed'
              );
            }
            ref.close();
          });
        }
      });
    }
  }

  viewMerchant(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(MerchantViewComponent, {
        data: id,
        width: '600px',
      });
      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    }
  }

  viewPatient(id) {
    if (id) {
      if (id) {
        const ref = RootAppComponent.dialog.open(CustomerProspectViewModelComponent, {
          data: {
            patientID: id,
            isFullDetails: true,
          },
          width: '700px',
        });
        ref.componentInstance.close.subscribe((res) => {
          if (res == true) {
            ref.close();
          }
        });

        ref.componentInstance.detailedView.subscribe((res) => {
          if (res == true) {
            ref.close();
          }
        });
      }
    }
  }

  viewDentist(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(DentistViewComponent, {
        data: id,
        width: '600px',
      });
      ref.componentInstance.close.subscribe((res) => {
        if (res == true) {
          ref.close();
        }
      });
    }
  }

  viewInvitation(patientID, invitationID) {
    if (patientID && invitationID) {
      const confirm = new ConfirmDialog(
        'fas fa-info',

        'Do you want to continue?',
        'You are about to leave this page.',
        'Cancel',
        'Okay'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
        width: '500px',
      });

      ref.componentInstance.onConfirm.subscribe((res) => {
        if (res == true) {
          ref.close();
          this.router.navigate([
            '/merchant',
            {
              outlets: {
                page: ['customer-profile', patientID, 'treatment', invitationID],
              },
            },
          ]);
        } else {
          ref.close();
        }
      });
    }
  }

  indexOfTag(e) {
    if (this.searchLabel && Array.isArray(this.searchLabel) && e) {
      if (this.searchLabel.indexOf(e) != -1) {
        return true;
      }
    }

    return false;
  }

  toLowerCase(e) {
    if (e) {
      return String(e).toLocaleLowerCase();
    }

    return e;
  }

  selectPage() {
    const index = this.dataSource['_paginator']['index'] || 0;
    const pageSize = this.dataSource['_paginator']['pageSize'] || 0;

    for (let i = 0; i < (index + 1) * pageSize; i++) {
      const o = this.dataSource.filteredData[index * pageSize + i];

      if (o && o['ID'] && this.selectedIDs.indexOf(o['ID']) == -1) {
        this.selectedIDs.push(o['ID']);
      }
    }
  }

  select(id, isSelected) {
    if (isSelected == true && this.selectedIDs.indexOf(id) == -1) {
      this.selectedIDs.push(id);
    } else {
      const _index = this.selectedIDs.indexOf(id);
      this.selectedIDs.splice(_index, 1);
    }
  }

  unselect() {
    this.selectedIDs = [];
  }

  isSelected(id) {
    if (!this.selectedIDs || this.selectedIDs.length <= 0) {
      return false;
    } else {
      if (this.selectedIDs.indexOf(id) != -1) {
        return true;
      }
    }

    return false;
  }

  selectAll() {
    for (let i = 0; i < this.dataSource.filteredData.length; i++) {
      const o = this.dataSource.filteredData[i];

      if (o && o['ID'] && this.selectedIDs.indexOf(o['ID']) == -1) {
        this.selectedIDs.push(o['ID']);
      }
    }
  }

  removeGroup() {
    if (this.selectedIDs && this.selectedIDs.length > 0) {
      const confirm = new ConfirmDialog(
        'fas fa-info',
        '',
        'Are you sure you want to remove these Medications  ?',
        'No',
        'Yes'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation === false) {
          ref.close();
        } else {
          this.instantOfferService.deleteInstantOfferGroup(this.selectedIDs, this.sessionType).subscribe((res) => {
            if (res && res.length > 0) {
              for (let i = 0; i < res.length; i++) {
                this.dataSource.removeItem = res[i];
              }

              this.selectedIDs = [];
            }
            ref.close();

            NotifyAppComponent.displayToast('success', 'Success!', 'Offers are removed.');
          });
        }
      });
    }
  }

  openLandingPage(instantOffer) {
    if (instantOffer && instantOffer.ID) {
      this.authenticationService.getCurrentPractice().subscribe((r) => {
        if (r && r.ID) {
          let link = `${Settings.global['publicSiteFrontendLink']}/offer/${instantOffer.ID}`;
          if (
            instantOffer['Source_Instant_Finance_Offer.Code'] === 'External' ||
            instantOffer['Source_Instant_Finance_Offer.Code'] === 'Direct'
          ) {
            link = `${Settings.global['publicSiteFrontendLink']}/offer-apply/${instantOffer.ID}`;
          }

          const ref2 = RootAppComponent.dialog.open(IframeViewComponent, {
            data: {
              link,
            },
            width: '80%',
            height: '92vh',
            panelClass: 'noCard',
          });

          ref2.componentInstance.close.subscribe((res) => {
            ref2.close();
          });
        }
      });
    }
  }

  view(id) {
    if (id) {
      const ref2 = RootAppComponent.dialog.open(InstantOfferViewComponent, {
        data: {
          instantOfferID: id,
        },
        width: '950px',
      });

      ref2.componentInstance.close.subscribe((res) => {
        ref2.close();
      });
    }
  }

  ngOnDestroy() {
    this.destroyEvent.emit(true);
  }
}

export class LoadRecords implements OnInit {
  public dataChange: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  items: any;
  count: any;

  get data(): any[] {
    return this.dataChange.value;
  }

  firstLoad = false;

  get firstLoadEvent() {
    return this.firstLoad;
  }

  isDestoyed = false;
  serviceRef;

  productUtil = new ProductUtil();
  utils = new UtilsClass();
  public getDefaultFilter = new EventEmitter();
  public getMerchantTags = new EventEmitter();
  merchantTags = [];
  typeFilter = null;

  constructor(private instantOfferService: InstantOfferService, private destroyEvent, params, private sessionType) {
    this.destroyEvent.subscribe((res) => {
      this.isDestoyed = res;
    });
    const payload: any = params || {};
    payload.section = 0;
    payload.limit = 200;

    this.serviceRef = this.instantOfferService.getInstantOfferList(payload, this.sessionType).subscribe((res) => {
      for (let i = 0; i < res.length; i++) {
        res[i]['fullName'] = '';
        if (res[i] && res[i]['CustomerProspect_CalculatedName']) {
          res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['CustomerProspect_CalculatedName'];
        }
        if (res[i] && res[i]['Contact_CalculatedName']) {
          res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['Contact_CalculatedName'];
        }
        if (res[i] && res[i]['Merchant_Tranding_As']) {
          res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['Merchant_Tranding_As'];
        }
        if (res[i] && res[i]['TreatmentAmount']) {
          res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['TreatmentAmount'];
        }

        if (res[i] && res[i]['Product_Group.Code']) {
          res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['Product_Group.Code'];
        }

        if (res[i] && res[i]['Product_Group.Code']) {
          res[i]['fullName'] = res[i]['fullName'] + ',' + this.productUtil.getLabel(res[i]['Product_Group.Code']);
        }

        if (res[i] && res[i]['Product_Group.Label']) {
          res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['Product_Group.Label'];
        }
        if (res[i] && res[i]['Status.Invitation.Short']) {
          res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['Status.Invitation.Short'];
        }
        if (res[i] && res[i]['Status.Application.Short']) {
          res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['Status.Application.Short'];
        }
        if (res[i] && res[i]['Status.Contract.Short']) {
          res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['Status.Contract.Short'];
        }

        if (res[i] && res[i]['Status.Code'] === '') {
          res[i]['Status.Code'] = 'Sent';
          res[i]['Status.Label'] = 'Sent';
        }
        if (res[i] && res[i]['Status.Label']) {
          res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['Status.Label'];
        }
        if (res[i] && res[i]['Status.Code']) {
          res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['Status.Code'];
        }
        if (res[i] && res[i]['Theme']) {
          res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['Theme'];
        }

        if (res && res[i] && res[i]['DocumentFKs']) {
          res[i]['DocumentFKs'] = res[i]['DocumentFKs'].split('|');
        } else {
          res[i]['DocumentFKs'] = [];
        }

        if (res[i]['Source_Instant_Finance_Offer.Code'] === '') {
          res[i]['Source_Instant_Finance_Offer.Code'] = 'SMS';
        }

        res[i]['isNew'] = this.isNew(res[i]['DateTimeCreated']);

        if (res && res[i] && res[i]['Tags']) {
          res[i]['Tags'] = res[i]['Tags'].split('|');
          res[i]['Tags'] = uniq(res[i]['Tags']);
          this.merchantTags = this.merchantTags.concat(res[i]['Tags']);
        } else {
          res[i]['Tags'] = [];
          this.merchantTags = this.merchantTags.concat(res[i]['Tags']);
        }
      }

      this.merchantTags = uniq(this.merchantTags);
      this.merchantTags = orderBy(this.merchantTags);
      this.getMerchantTags.emit(this.merchantTags);
      this.count = res.length;
      this.items = res;

      this.dataChange.next(this.items.slice());
      this.firstLoad = true;

      if (this.typeFilter != 'user' && this.items.length > 0) {
        const _userMessagesCount = this.items.filter((template) => template.IsDefault === '0').length;

        if (_userMessagesCount > 0) {
          this.typeFilter = '0';
        } else {
          this.typeFilter = null;
        }
      }

      this.getDefaultFilter.emit(this.typeFilter);

      const innerFunction = (section) => {
        const length = this.items.length;
        section = section + 1;
        UtilsClass.loadingDataSection(section);
        payload.section = section;

        this.serviceRef = this.instantOfferService.getInstantOfferList(payload, this.sessionType).subscribe((res) => {
          if (res.length > 0) {
            for (let i = 0; i < res.length; i++) {
              res[i]['fullName'] = '';
              if (res[i] && res[i]['CustomerProspect_CalculatedName']) {
                res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['CustomerProspect_CalculatedName'];
              }
              if (res[i] && res[i]['Contact_CalculatedName']) {
                res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['Contact_CalculatedName'];
              }
              if (res[i] && res[i]['Merchant_Tranding_As']) {
                res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['Merchant_Tranding_As'];
              }
              if (res[i] && res[i]['TreatmentAmount']) {
                res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['TreatmentAmount'];
              }
              if (res[i] && res[i]['Product_Group.Code']) {
                res[i]['fullName'] = res[i]['fullName'] + ',' + this.productUtil.getLabel(res[i]['Product_Group.Code']);
              }
              if (res[i] && res[i]['Product_Group.Code']) {
                res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['Product_Group.Code'];
              }
              if (res[i] && res[i]['Product_Group.Label']) {
                res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['Product_Group.Label'];
              }
              if (res[i] && res[i]['Status.Invitation.Short']) {
                res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['Status.Invitation.Short'];
              }
              if (res[i] && res[i]['Status.Application.Short']) {
                res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['Status.Application.Short'];
              }
              if (res[i] && res[i]['Status.Contract.Short']) {
                res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['Status.Contract.Short'];
              }
              if (res[i] && res[i]['Status.Code'] === '') {
                res[i]['Status.Code'] = 'Sent';
                res[i]['Status.Label'] = 'Sent';
              }
              if (res[i] && res[i]['Status.Label']) {
                res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['Status.Label'];
              }
              if (res[i] && res[i]['Status.Code']) {
                res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['Status.Code'];
              }
              if (res[i] && res[i]['Theme']) {
                res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['Theme'];
              }

              if (res && res[i] && res[i]['DocumentFKs']) {
                res[i]['DocumentFKs'] = res[i]['DocumentFKs'].split('|');
              } else {
                res[i]['DocumentFKs'] = [];
              }
              if (res[i]['Source_Instant_Finance_Offer.Code'] === '') {
                res[i]['Source_Instant_Finance_Offer.Code'] = 'SMS';
              }

              if (res && res[i] && res[i]['Tags']) {
                res[i]['Tags'] = res[i]['Tags'].split('|');
                res[i]['Tags'] = uniq(res[i]['Tags']);
                this.merchantTags = this.merchantTags.concat(res[i]['Tags']);
              } else {
                res[i]['Tags'] = [];
                this.merchantTags = this.merchantTags.concat(res[i]['Tags']);
              }
              res[i]['isNew'] = this.isNew(res[i]['DateTimeCreated']);
            }

            this.merchantTags = uniq(this.merchantTags);
            this.merchantTags = orderBy(this.merchantTags);
            this.getMerchantTags.emit(this.merchantTags);
            this.count = res.count;
            this.items = this.items.concat(res);

            this.dataChange.next(this.items.slice());
            if (this.isDestoyed != true) {
              innerFunction(section);
            }
          } else {
            return true;
          }
        });
        UtilsClass.stopLoadingInterceptor();
      };

      if (this.isDestoyed != true) {
        innerFunction(1);
      }
    });
  }

  isNew(date) {
    if (date) {
      const days = this.utils.daysPast(date);

      if (days < 15) {
        return true;
      } else {
        return false;
      }
    }

    return false;
  }

  sendSMS(id, merchantID) {
    if (id) {
      if (this.sessionType == 'admin' || this.sessionType == 'promoter') {
        const ref2 = RootAppComponent.dialog.open(SmsPromoterComponent, {
          data: {
            merchantID,
            cardID: id,
            singleChat: true,
            instantOfferID:id,
          },
          panelClass: 'noCard',
          width: '500px',
        });
        ref2.componentInstance.close.subscribe((data) => {
          ref2.close();
        });

        ref2.componentInstance.getResult.subscribe((data) => {
          if (data) {
            const ref = RootAppComponent.dialog.open(SmsConversationComponent, {
              data,
              panelClass: 'bigger-screen',
              width: '70%',
              height: '95vh',
            });
            const sub = ref.componentInstance.close.subscribe((data) => {
              ref.close();
            });
          }

          ref2.close();
        });
      } else {
        const ref2 = RootAppComponent.dialog.open(SmsConversationComponent, {
          data: {
            cardID: id,
            singleChat: true,
            instantOfferID:id,
          },
          panelClass: 'bigger-screen',
          width: '70%',
          height: '95vh',
        });
        const sub = ref2.componentInstance.close.subscribe((data) => {
          ref2.close();
        });
      }
    }
  }

  convertMessage(message = null, renderValue = null) {
    if (message && renderValue) {
      let result;
      result = this.utils.convertMessage(message, renderValue);
      return result;
    }

    return message;
  }

  ngOnInit() {}

  ngOnDestroy() {
    if (this.serviceRef) {
      this.serviceRef.unsubscribe();
    }
  }
}

export class RecordDataSource extends DataSource<any> {
  get filter(): any {
    return this._filterChange.value;
  }

  set filter(item: any) {
    this.field = item.field;
    this._filterChange.next(item.value);
  }

  set removeItem(id) {
    const data = this._tableDatabase.data.filter((row) => row.ID != id);
    this._tableDatabase.dataChange.next(data);
  }

  set addItem(res) {
    const _res = res;
    if (_res && _res.ID) {
      _res['fullName'] = '';
      if (_res && _res['Label']) {
        _res['fullName'] = _res['fullName'] + ',' + _res['Label'];
      }
      if (_res && _res['Description']) {
        _res['fullName'] = _res['fullName'] + ',' + _res['Description'];
      }
      if (_res && _res['Title']) {
        _res['fullName'] = _res['fullName'] + ',' + _res['Title'];
      }
      if (_res && _res['Message']) {
        _res['fullName'] = _res['fullName'] + ',' + _res['Message'];
      }
      if (_res && _res['Theme']) {
        _res['fullName'] = _res['fullName'] + ',' + _res['Theme'];
      }
      if (_res && _res['Merchant_Trading_As']) {
        _res['fullName'] = _res['fullName'] + ',' + _res['Merchant_Trading_As'];
      }
      if (_res && _res && _res['DocumentFKs']) {
        _res['DocumentFKs'] = _res['DocumentFKs'].split('|');
      } else {
        _res['DocumentFKs'] = [];
      }

      _res['isNew'] = this.isNew(_res['DateTimeCreated']);

      if (_res && _res['Tags']) {
        _res['Tags'] = _res['Tags'].split('|');
        _res['Tags'] = uniq(_res['Tags']);
      } else {
        _res['Tags'] = [];
      }
    }
    this._tableDatabase.data.unshift(_res);

    this._tableDatabase.dataChange.next(this._tableDatabase.data);
  }

  set replaceItem(item) {
    const _res = item;
    if (_res && _res.ID) {
      _res['fullName'] = '';
      if (_res && _res['Label']) {
        _res['fullName'] = _res['fullName'] + ',' + _res['Label'];
      }
      if (_res && _res['Description']) {
        _res['fullName'] = _res['fullName'] + ',' + _res['Description'];
      }
      if (_res && _res['Title']) {
        _res['fullName'] = _res['fullName'] + ',' + _res['Title'];
      }
      if (_res && _res['Message']) {
        _res['fullName'] = _res['fullName'] + ',' + _res['Message'];
      }
      if (_res && _res['Theme']) {
        _res['fullName'] = _res['fullName'] + ',' + _res['Theme'];
      }
      if (_res && _res['Merchant_Trading_As']) {
        _res['fullName'] = _res['fullName'] + ',' + _res['Merchant_Trading_As'];
      }
      if (_res && _res && _res['DocumentFKs']) {
        _res['DocumentFKs'] = _res['DocumentFKs'].split('|');
      } else {
        _res['DocumentFKs'] = [];
      }
      _res['isNew'] = this.isNew(_res['DateTimeCreated']);

      if (_res && _res && _res['Tags']) {
        _res['Tags'] = _res['Tags'].split('|');
        _res['Tags'] = uniq(_res['Tags']);
      } else {
        _res['Tags'] = [];
      }
    }
    const _index = this._tableDatabase.data.findIndex((_obj) => _obj.ID == _res.ID);
    this._tableDatabase.data[_index] = _res;
    this._tableDatabase.dataChange.next(this._tableDatabase.data);
  }

  _filterChange = new BehaviorSubject('');
  field = '';

  utils = new UtilsClass();

  filteredData: any[] = [];

  isNew(date) {
    if (date) {
      const days = this.utils.daysPast(date);

      if (days < 15) {
        return true;
      } else {
        return false;
      }
    }

    return false;
  }

  constructor(private _tableDatabase: LoadRecords, private _paginator: MatPaginator, private _sort: MatSort) {
    super();
    this._filterChange.subscribe(() => (this._paginator.pageIndex = 0));
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<any[]> {
    const displayDataChanges = [
      this._tableDatabase.dataChange,
      this._filterChange,
      this._paginator.page,
      this._sort.sortChange,
    ];

    return observableMerge(...displayDataChanges).pipe(
      map(() => {
        this.filteredData = this._tableDatabase.data.slice().filter((item: any) => {
          if (this.field == 'Tags') {
            let f = '';
            f = item[this.field] || '';

            if (this.filter && this.filter.length > 0) {
              for (let j = 0; j < this.filter.length; j++) {
                if (f.indexOf(this.filter[j]) == -1) {
                  return false;
                }
              }
              return true;
            } else {
              return f;
            }
          } else {
            let f = '';
            f = item[this.field] || '';

            const searchStr = f.toLowerCase();

            return searchStr.indexOf(this.filter.toLowerCase()) != -1;
          }
        });
        const data = this.getSortedData(this.filteredData.slice());
        // Grab the page's slice of data.
        const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
        return data.splice(startIndex, this._paginator.pageSize);
      })
    );
  }

  disconnect() {}

  /** Returns a sorted copy of the database data. */
  getSortedData(data: any[]): any[] {
    // const data = data;
    if (!this._sort.active || this._sort.direction == '') {
      return data;
    }

    return data.sort((a, b) => {
      let propertyA: number | string = '';
      let propertyB: number | string = '';

      switch (this._sort.active) {
        case 'Tag':
          [propertyA, propertyB] = [a['Tag'], b['Tag']];
          break;

        case 'Label':
          [propertyA, propertyB] = [a['OfferName'], b['OfferName']];
          break;

        case 'Purpose':
          [propertyA, propertyB] = [a['Purpose_Label'], b['Purpose_Label']];
          break;

        case 'Date':
          [propertyA, propertyB] = [moment(a['Date']).toDate().getTime(), moment(b['Date']).toDate().getTime()];
          break;
      }

      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

      return (valueA < valueB ? -1 : 1) * (this._sort.direction == 'asc' ? 1 : -1);
    });
  }
}
