<div class="card-header primary-gradient-img clearfix inModal videocard" *ngIf="isModal == true">
  <div class="row clearfix">
    <button class="btn-close btn-clear mat-button white" (click)="close()">
      <mat-icon>clear</mat-icon>
    </button>
    <div class="row titleArea clearfix">
      <mat-icon class="pull-left fas fa-video"></mat-icon>
      <h2 class="summary-header rm-m">
        Video tutorials
        <span class="subLabel" *ngIf="subcategory">{{ subcategory.title }}</span>
      </h2>
    </div>
  </div>
</div>

<mat-dialog-content *ngIf="isModal == true">
  <div class="row clearfix mat-card-image adjust-margin" *ngIf="safeUrl">
    <div style="padding: 56.25% 0 0 0; position: relative">
      <iframe [src]="safeUrl" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%" frameborder="0"
              allow="autoplay; fullscreen" allowfullscreen></iframe>
    </div>
    <!-- <script src="https://player.vimeo.com/api/player.js"></script> -->
  </div>
  <div class="row flex-just-between flex flex-1">
    <mat-form-field class="full-width" appearance="outline">
      <mat-label>Video Category</mat-label>
      <mat-select [(ngModel)]="mainCategory">
        <mat-option *ngFor="let category of mainGroups" [value]="category.id">
          {{ category.title }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="full-width" appearance="outline">
      <mat-label>Select a topic</mat-label>
      <mat-select [(ngModel)]="subcategory" (selectionChange)="onChangeTopic($event)">
        <mat-option *ngFor="let sub of filterSubById(mainCategory)" [value]="sub.value">
          {{ sub.title }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <p class="small">
    Learn more about using the {{ "BRANDING.Brand Name" | translate | titlecase }} platform with these handy video
    tutorials. Start
    by selecting the category you wish to view, then select the topic.
  </p>
</mat-dialog-content>

<ng-container *ngIf="isModal == false">
  <div class="row clearfix mat-card-image adjust-margin" *ngIf="safeUrl">
    <div style="padding: 56.25% 0 0 0; position: relative">
      <iframe [src]="safeUrl" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%" frameborder="0"
              allow="autoplay; fullscreen" allowfullscreen></iframe>
    </div>
    <!-- <script src="https://player.vimeo.com/api/player.js"></script> -->
  </div>

  <div class="text-center">
    <button (click)="toggleSelect = !toggleSelect" mat-stroked-button>See more video tutorials</button>
  </div>

  <ng-container *ngIf="toggleSelect == true" @simpleFadeAnimation>
    <div class="row flex-just-between flex flex-1">
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>Video Category</mat-label>
        <mat-select [(ngModel)]="mainCategory">
          <mat-option *ngFor="let category of mainGroups" [value]="category.id">
            {{ category.title }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="full-width" appearance="outline">
        <mat-label>Select a topic</mat-label>
        <mat-select [(ngModel)]="subcategory" (selectionChange)="onChangeTopic($event)">
          <mat-option *ngFor="let sub of filterSubById(mainCategory)" [value]="sub.value">
            {{ sub.title }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </ng-container>
</ng-container>
