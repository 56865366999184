import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'environments/environment';
import { uniqBy } from 'lodash';
import { FileService } from '../../services/file.service';
import { UtilsService } from '../../services/utils.service';
import { NotifyAppComponent } from '../../types/notify-app-component';
import { Settings } from '../../types/settings';
import { UtilsClass } from '../../types/utils/utils.class';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { FileContentComponent } from '../file-content/file-content.component';
import { FileInsertionComponent } from '../file-insertion/file-insertion.component';
import { RootAppComponent } from '../root-component/root-component.component';

@Component({
  selector: 'app-file-list',
  templateUrl: './file-list.component.html',
  styleUrls: ['./file-list.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.animate', style({ opacity: 0 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({
                  opacity: 0,
                  transform: 'translateY(-75%)',
                  offset: 0,
                  height: 0,
                }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({
                  opacity: 1,
                  transform: 'translateY(0)',
                  offset: 1.0,
                  height: '*',
                }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.animate', style({ opacity: 1 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({
                  opacity: 1,
                  transform: 'translateY(0)',
                  offset: 0,
                  height: '*',
                }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({
                  opacity: 0,
                  transform: 'translateY(-75%)',
                  offset: 1.0,
                  height: 0,
                }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class FileListComponent implements OnInit {
  isModulePrivateDocumentActive = Settings.global['isModulePrivateDocumentActive'];

  @Input() documentTypes = Settings.global['defaultMessages'] || [
    'Treatment Plan',
    'Additional Document',
    'Image',
    'Scan / X-Ray',
  ];

  util = new UtilsClass();

  imgTypes = ['image/png', 'image/jpeg'];

  @Input() isModal = false;

  @Input() title = 'Attached Files';

  @Output() getFile = new EventEmitter();
  @Output() remove = new EventEmitter();
  // tslint:disable-next-line: no-input-rename
  @Input('files') fs: any[];
  @Input() addFile = new EventEmitter();
  @Input() canEdit = true;
  @Input() canAction = true;
  @Input() actionLabel = 'Select';
  @Input() justify = 'center';
  @Input() hasExtraInfo = true;
  @Input() canPrivateDocument = false;
  @Input() canGallery = false;
  @Input() canPatientDocument = false;
  @Input() showInfo = true;
  @Input() defaultLabel;
  @Input() invitationID = '';
  @Input() displayThumbnail = true;
  files = [];
  fileLink = `${environment.nodeUrl}/files/id/view`;
  selectedFile: any;

  @Input()
  patientDocumentTypes = [
    { label: 'Videos', code: 'MEDR_V' },

    { label: 'Xrays', code: 'MEDR_X' },

    { label: 'Treatment Plans', code: 'MEDR_T' },

    { label: 'Images', code: 'MEDR_I' },

    { label: 'Referrals', code: 'MEDR_R' },

    { label: 'Correspondences', code: 'MEDR_C' },
  ];

  @Input() hasResponsiveWidth = false;

  constructor(private dialog: MatDialog, private utilService: UtilsService, private fileService: FileService) {}

  ngOnInit() {
    this.utilService.getCurrentAccess().subscribe((access) => {
      if (access) {
        this.isModulePrivateDocumentActive = access['isModulePrivateDocumentActive'];

        this.configureDefaultFileProperties();

        this.addFile.subscribe((file) => {
          if (file && typeof file === 'string') {
            this.fileService.getFile(file).subscribe((fileId) => {
              if (fileId) {
                this.files.push(this.buildFile(fileId));
              }
            });
          } else if (file && typeof file === 'object') {
            this.files.push(this.buildFile(file));
          }
        });
      }
    });
  }

  configureDefaultFileProperties() {
    const fileIds = this.convertToFileIdArray(this.fs);

    if (fileIds.length > 0) {
      this.fileService.getArrayFile(fileIds).subscribe((response) => {
        if (response && response.length > 0) {
          this.files = uniqBy(response, '_id');

          for (let index = 0; index < this.files.length; index++) {
            if (this.files && this.files[index] && this.files[index]['contentType'] === 'text/javascript') {
              this.files[index]['extension'] = 'js';
            }

            if (
              this.defaultLabel &&
              this.files[index] &&
              (!this.files[index]['fileName'] || this.files[index]['fileName'] === '')
            ) {
              this.files[index]['fileName'] = this.defaultLabel;
            }
            this.files[index]['documentTypeFromExtension'] = this.util.getDocumentTypeFromExtension(
              this.files[index]['extension']
            );

            this.files[index]['defaultImage'] = this.util.getDefaultPic(
              this.files[index]['documentTypeFromExtension'],
              this.files[index]['extension']
            );
          }
        }
      });
    } else {
      this.files = [];
    }
  }

  buildFile(f) {
    const file = f;
    if (file) {
      if (file && file['contentType'] === 'text/javascript') {
        file['extension'] = 'js';
      }
      if (this.defaultLabel && file && (!file['fileName'] || file['fileName'] === '')) {
        file['fileName'] = this.defaultLabel;
      }

      file['documentTypeFromExtension'] = this.util.getDocumentTypeFromExtension(file['extension']);

      file['defaultImage'] = this.util.getDefaultPic(file['documentTypeFromExtension'], file['extension']);
    }
    return file;
  }

  convertToFileIdArray(files) {
    const fileIds = [];

    if (typeof files === 'string') {
      files = files.split(',');
    } else if (!Array.isArray(files)) {
      files = [];
    }

    for (let index = 0; index < files.length; index++) {
      if (typeof files[index] === 'object' && files[index] !== null && files[index] !== '') {
        fileIds.push(files[index]['_id']);
      } else if (typeof files[index] === 'string' && files[index] != null && files[index] !== '') {
        fileIds.push(files[index]);
      }
    }

    return fileIds;
  }

  hasFilesChanged(previousArray) {
    if (!this.fs) {
      return false;
    }

    if (this.fs.length !== previousArray.length) {
      return false;
    }

    for (let index = 0; index < previousArray.length; index++) {
      if (previousArray[index] !== this.fs[index]) {
        return false;
      }
    }

    return true;
  }

  ngOnChanges(changes: SimpleChanges) {
    /*  When change detection is triggered
     Checks are run to prevent expensive operations from running when
     changes that don't affect the respective operation are not made.
  */

    this.refreshFileDefaultProperties(changes);
  }

  refreshFileDefaultProperties(changes: SimpleChanges) {
    if (changes.fs && changes.fs.previousValue) {
      const previousArray = changes.fs.previousValue || [];

      const hasFilesChanged = this.hasFilesChanged(previousArray);

      if (hasFilesChanged === false) {
        this.configureDefaultFileProperties();
      }
    } else if (changes.fs && !changes.fs.previousValue) {
      this.configureDefaultFileProperties();
    }
  }

  openFileContentDialog(selectedFile) {
    const fileContentDialogRef = this.dialog.open(FileContentComponent, {
      data: {
        file: selectedFile,
        showInfo: true,
        canEdit: this.canEdit,
      },
      width: '800px',
      panelClass: ['autoHeight'],
    });

    fileContentDialogRef.componentInstance.remove.subscribe((removeFile) => {
      if (removeFile && removeFile._id) {
        this.files = this.files.filter((file) => {
          if (file._id === removeFile._id) {
            return false;
          }
          return true;
        });

        this.remove.emit(removeFile);

        fileContentDialogRef.close();
      }
    });

    fileContentDialogRef.componentInstance.close.subscribe((close) => {
      if (close === true) {
        fileContentDialogRef.close();
      }
    });

    fileContentDialogRef.componentInstance.getFile.subscribe((file) => {
      this.getFile.emit(file);
    });
  }

  download(file) {
    const fileLink = environment.nodeUrl + '/files/' + file._id + '/download/';
    window.location.href = fileLink;
  }

  onClickDeleteButton(selectedFile) {
    const confirmDialogRef = this.dialog.open(ConfirmDialogComponent);
    confirmDialogRef.componentInstance.onConfirm.subscribe((isConfirmed) => {
      if (isConfirmed === true) {
        this.fileService.deleteFile(selectedFile._id).subscribe((response) => {
          if (response['success']) {
            this.files = this.files.filter((file) => {
              if (file._id === selectedFile._id) {
                return false;
              }
              return true;
            });
            this.remove.emit(selectedFile._id);
            NotifyAppComponent.displayToast(
              'success',
              'Successful operation',
              'The file has been successfully removed'
            );
            confirmDialogRef.close();
          }
        });

        confirmDialogRef.close();
      }
    });
  }

  sendFile(file) {
    this.getFile.emit(file);
  }

  updateMeta(file) {
    let fileName;
    let documentType;
    let isPrivate;
    let isGallery = false;
    let isPatientDocument = false;
    let patientDocumentType;

    if (file && file.fileName) {
      fileName = file.fileName;
    }

    if (file && file.documentType) {
      documentType = file.documentType;
    }

    if (file && file.patientDocumentType) {
      patientDocumentType = file.patientDocumentType;
    }

    if (file && file.isPrivate != null) {
      isPrivate = file.isPrivate;
    }

    if (file && file.isPatientDocument != null) {
      isPatientDocument = file.isPatientDocument;
    }

    if (file && file.isGallery != null) {
      isGallery = file.isGallery;
    }

    if (fileName || documentType || isGallery != null || isPrivate != null) {
      this.fileService
        .updateMetaDate(file._id, {
          fileName,
          isPrivate,
          isGallery,
          isPatientDocument,
          patientDocumentType,
          documentType,
        })
        .subscribe(() => {});
    }
  }

  getFileName(file) {
    return file.fileName ? file.fileName : file;
  }

  bytesToSize(bytes) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) {
      return '0 Byte';
    }
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i];
  }

  onImageError(file) {
    if (file) {
      file.isError = true;
    }
  }

  editThumbnail(file) {
    if (file && file.id) {
      const ref = RootAppComponent.dialog.open(FileInsertionComponent, {
        data: {
          documentType: 'LP_HI',
          displayGallery: false,
          isGallery: false,
          isFixedAspectRatio: true,
          roundCropper: false,
          aspectRatioType: 'full',
          documentNature: 'image',
          keepOriginal: false,
          maxFileSize: 1,
          uploadOnSubmit: false,
          headerTitle: 'Update Thumbnail',
          displayThumbnail: false,
        },
        width: '600px',
      });

      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
      ref.componentInstance.getFileID.subscribe((fileID) => {
        if (fileID) {
          this.fileService
            .updateMetaDate(file.id, {
              thumbnailFileID: fileID,
            })
            .subscribe((r) => {
              if (r && r.id) {
                const index = this.files.findIndex((item) => {
                  if (item && item.id && item.id == r.id) {
                    return true;
                  }
                  return false;
                });

                if (index != -1) {
                  this.files[index] = r;
                }
              }

              ref.close();
            });
        }
      });
    }
    console.log(file);
  }
}
