<div class="rel dentist-card merchantView clearfix" *ngIf="merchant" @onChangeAnimation>
  <div class="animate">
    <div class="card-header primary-gradient-img clearfix">
      <div class="row clearfix">
        <!-- Modal dismiss button -->
        <button class="btn-close btn-clear mat-button white" matDialogClose>
          <mat-icon>clear</mat-icon>
        </button>
        <!-- merchant calculated name -->
        <div class="row titleArea clearfix">
          <app-merchant-view-picture class="headNavView userIconView mr pull-left" [merchantID]="merchant['ID']">
          </app-merchant-view-picture>

          <h2 class="summary-header rm-m ml">
            {{ merchant.CalculatedName }}
            <span class="subLabel">
              Control the system modules which are currently active across merchant dashboards</span
            >
          </h2>
        </div>
      </div>
      <div class="action-button">
        <button class="actionMenuToggle" [matMenuTriggerFor]="menu" mat-raised-button>
          Actions <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button
            *ngIf="merchant['addresses.Calculated']"
            (click)="displayMap()"
            mat-menu-item
            color="accent"
            matTooltip="View on map"
          >
            <mat-icon>place</mat-icon>
            <span>View on map</span>
          </button>
          <button
            *ngIf="isPromoterOrAdmin == true && merchant['ID']"
            (click)="viewMerchantLogs(merchant['ID'])"
            mat-menu-item
            matTooltip="View merchant logs"
          >
            <mat-icon class="fa fa-list-alt"></mat-icon>
            <span>View merchant logs</span>
          </button>
        </mat-menu>
      </div>
    </div>

    <mat-dialog-content class="full-width mb clearfix row">
      <app-user-access-create [hideHeader]="true" [cardID]="merchant['ID']" (updateAccess)="updateAccess($event)">
      </app-user-access-create>
    </mat-dialog-content>
  </div>
</div>
