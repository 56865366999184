import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ActionLogService } from '../shared/action-log.service';
import { ActionLogUtilClass } from '../shared/actionLogUtil';

@Component({
  selector: 'app-action-log-view',
  templateUrl: './action-log-view.component.html',
  styleUrls: ['./action-log-view.component.css'],
})
export class ActionLogViewComponent implements OnInit {
  @Input()
  actionID;

  @Output()
  closeModal = new EventEmitter();

  @Input()
  largeHeading;

  actionLog;
  isModal = false;
  isPromoterOrAdmin = false;

  actionLogUtil = new ActionLogUtilClass();
  sessionType;

  constructor(
    private actionlogService: ActionLogService,
    private authenticationService: AuthenticationService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      this.actionID = data;

      this.isModal = true;
    }
  }

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((sessionType) => {
      this.sessionType = sessionType;

      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isPromoterOrAdmin = res;

        this.actionlogService.getOneActionLog(this.actionID, this.sessionType).subscribe((res) => {
          this.actionLog = res;
        });
      });
    });
  }

  closeEvent() {
    this.closeModal.emit(true);
  }

  calculateDuration(val) {
    const mins_num = parseFloat(val);
    let hours = String(Math.floor(mins_num / 60));
    let minutes = String(Math.floor(mins_num - (Number(hours) * 3600) / 60));
    let seconds = String(Math.floor(mins_num * 60 - Number(hours) * 3600 - Number(minutes) * 60));

    // Appends 0 when unit is less than 10
    if (Number(hours) < 10) {
      hours = '0' + hours;
    }
    if (Number(minutes) < 10) {
      minutes = '0' + minutes;
    }
    if (Number(seconds) < 10) {
      seconds = '0' + seconds;
    }
    return hours + ':' + minutes + ':' + seconds;
  }
}
