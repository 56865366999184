var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ConfirmDialogSingleButtonComponent } from '../../../shared/components/confirm-dialog-single-button/confirm-dialog-single-button.component';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { ConfirmDialogSingleButton } from '../../../shared/types/confirm-dialog-single-button';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { DentistViewModalComponent } from '../../dentist/dentist-view-modal/dentist-view-modal.component';
import { MerchantViewModalComponent } from '../../merchant/merchant-view-modal/merchant-view-modal.component';
import { AppointmentService } from '../shared/appointment.service';
import { AppointmentUtilClass } from '../shared/appointmentUtil';
import { CustomDatePipe } from '../../../shared/pipes/custom-date.pipe';
import { TranslatePipe } from '@ngx-translate/core';
export class AppointmentViewComponent {
    constructor(appointmentService, authenticationService, activeRoute, customDate, router, translatePipe, data) {
        this.appointmentService = appointmentService;
        this.authenticationService = authenticationService;
        this.activeRoute = activeRoute;
        this.customDate = customDate;
        this.router = router;
        this.translatePipe = translatePipe;
        this.data = data;
        this.closeOnProposeBooking = false;
        this.close = new EventEmitter();
        this.proposeBooking = new EventEmitter();
        this.updateAppointment = new EventEmitter();
        this.deleteAppointment = new EventEmitter();
        this.dateNow = new Date();
        this.runAction = new EventEmitter();
        this.util = new UtilsClass();
        this.settings = Settings.global;
        // calEvent: CalendarEvent;
        this.appointmentUtil = new AppointmentUtilClass();
        this.PatientKeyword = 'KEYWORD.Patient';
        this.patientKeyword = 'KEYWORD.patient';
        this.PracticeKeyword = 'Keyword.Practice';
        this.practiceKeyword = 'KEYWORD.practice';
        if (data) {
            if (data.appointmentID) {
                this.appointmentID = data.appointmentID;
            }
            if (data.closeOnProposeBooking != null) {
                this.closeOnProposeBooking = data.closeOnProposeBooking;
            }
            // this.isModal = true;
        }
    }
    closeEvent() {
        this.close.emit(true);
    }
    ngOnInit() {
        this.PatientKeyword = this.translatePipe.transform(this.PatientKeyword);
        this.PatientKeyword = this.translatePipe.transform(this.PatientKeyword);
        this.PracticeKeyword = this.translatePipe.transform(this.PracticeKeyword);
        this.practiceKeyword = this.translatePipe.transform(this.practiceKeyword);
        this.authenticationService.isPromoterOrAdmin().subscribe((res) => __awaiter(this, void 0, void 0, function* () {
            this.isPromoterOrAdmin = res;
            this.authenticationService.getSessionType().subscribe((sessionType) => {
                if (sessionType) {
                    this.sessionType = sessionType;
                    if (this.appointmentID) {
                        this.appointmentService.getAppointmentDetails(this.appointmentID, sessionType).subscribe((res) => {
                            this.appointment = res;
                        });
                    }
                }
            });
        }));
    }
    openMerchantViewDialog() {
        const ref = RootAppComponent.dialog.open(MerchantViewModalComponent, {
            data: this.appointment['Merchant_key'],
            width: '550px',
        });
        ref.componentInstance.close.subscribe((res) => {
            if (res == true) {
                ref.close();
            }
        });
    }
    openDentistViewDialog() {
        const ref = RootAppComponent.dialog.open(DentistViewModalComponent, {
            data: this.appointment['MerchantContact_key'],
            width: '550px',
        });
        ref.componentInstance.close.subscribe((res) => {
            if (res == true) {
                ref.close();
            }
        });
    }
    gotoCustomerPage() {
        window.open(`merchant/(page:customer-profile/${this.appointment['CustomerOrProspect_Key']}/patient)`, '_blank');
    }
    runActionEvent(item, operation, isGroup = false) {
        if (item) {
            const p = {
                item,
                operation,
                isGroup,
            };
            if (this.closeOnProposeBooking && operation == 'proposeBooking') {
                this.proposeBooking.emit(p);
            }
            else if (this.closeOnProposeBooking && operation == 'reschedule') {
                this.proposeBooking.emit(p);
            }
            else {
                this.runAction.emit(p);
            }
        }
    }
    getActionResult(r) {
        if (r && r.ID) {
            this.appointment = r;
            this.updateAppointment.emit(r);
        }
    }
    publicPage(key) {
        if (key && this.settings && this.settings['consumerFrontendLink']) {
            window.open(this.settings['consumerFrontendLink'] + '/consumer/(page:appointment-card/' + key + ')', '_blank');
        }
    }
    showComment(title, comment) {
        if (title && comment) {
            const confirmDialog2 = new ConfirmDialogSingleButton('comment', title, comment, 'OKAY');
            const ref2 = RootAppComponent.dialog.open(ConfirmDialogSingleButtonComponent, {
                data: confirmDialog2,
            });
            ref2.componentInstance.onConfirm.subscribe((res) => {
                ref2.close();
            });
        }
    }
    removeAppointment(item) {
        const confirmDialogParams = {
            data: new ConfirmDialog('fas fa-info', 'Are you sure?', '<p>You are about to remove this appointment</p>', 'No', 'Yes'),
        };
        const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, confirmDialogParams);
        ref.componentInstance.onConfirm.subscribe((val) => {
            if (val == true) {
                this.appointmentService.removeAppointment(item.ID, this.sessionType).subscribe((res) => {
                    if (res) {
                        this.deleteAppointment.emit(item.ID);
                        this.closeEvent();
                        NotifyAppComponent.displayToast('success', 'Remove appointment', 'Appointment is now removed');
                    }
                    ref.close();
                });
            }
            else {
                ref.close();
            }
        });
    }
    openInvitationViewDialog() {
        window.open(`merchant/(page:customer-profile/${this.appointment['CustomerOrProspect_Key']}/treatment/${this.appointment['Invitation_key']}))`, '_blank');
    }
    gotoCampaignPage() {
        const confirmDialogParams = {
            data: new ConfirmDialog('fas fa-info', 'Are you sure you want to continue?', '<p>You will be redirected to the campaign page</p>', 'No', 'Yes'),
        };
        const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, confirmDialogParams);
        ref.componentInstance.onConfirm.subscribe((val) => {
            if (val == true) {
                this.router.navigate([
                    '/merchant',
                    {
                        outlets: {
                            page: ['marketing-campaign-view', this.appointment['Campaign_Key']],
                        },
                    },
                ]);
                ref.close();
                ref.afterClosed().subscribe((res) => {
                    this.closeEvent();
                });
            }
            else {
                ref.close();
            }
        });
    }
}
