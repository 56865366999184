import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import { map } from 'rxjs/operators';

@Injectable()
export class ThemeService {
  private settingsUrl: string = environment.nodeUrl + '/settings';
  constructor(private http: HttpClient) {
    console.log('called');
  }
  addTheme(theme): Observable<any> {
    return this.http.post(environment.nodeUrl + '/theme', theme).pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getThemeList(payload = {}) {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }

    return this.http.get(`${environment.nodeUrl}/theme`, options).pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getThemeDetails(id) {
    return this.http.get(`${environment.nodeUrl}/theme/${id}`).pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getThemeSourceList(payload = {}) {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }

    return this.http
      .get(`${environment.nodeUrl}/theme/source`, options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  deleteTheme(id) {
    return this.http.delete(`${environment.nodeUrl}/theme/${id}`);
  }

  updateSettings(id, body): Observable<any> {
    return this.http.put(`${this.settingsUrl}/${id}`, body).pipe(map((res: HttpResponse<any>) => res['data']));
  }
}
