import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ConsumerRedirectionComponent } from './consumer-redirection/consumer-redirection.component';
import { HelperRoutingModule } from './shared/helper-routing.module';

@NgModule({
  declarations: [ConsumerRedirectionComponent],
  imports: [CommonModule, HelperRoutingModule],
})
export class HelperModule {}
