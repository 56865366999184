import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
@Component({
  selector: 'app-patient-identification-create',
  templateUrl: './patient-identification-create.component.html',
  styleUrls: ['./patient-identification-create.component.css'],
})
export class PatientIdentificationCreateComponent implements OnInit {
  @Input() invitationID = '';
  @Output() validate = new EventEmitter();
  hasDriversLicense = null;
  licenceDriverID = '';
  identificationID = '';
  typeOfIds = [
    {
      code: 'Passport',
      label: 'Passport',
    },
    {
      code: 'IDcard',
      label: 'Identity Card',
    },
  ];

  constructor() {}

  onValidate() {
    let isValid = false;
    if (this.hasDriversLicense === '0') {
      if (this.identificationID.length > 4) {
        isValid = true;
      } else {
        isValid = false;
      }
    } else if (this.hasDriversLicense === '1') {
      if (this.licenceDriverID.length > 5) {
        isValid = true;
      } else {
        isValid = false;
      }
    }
    this.validate.emit(isValid);
  }

  resetData() {
    this.licenceDriverID = '';
    this.identificationID = '';
  }

  public ngOnInit(): void {}
}
