import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'admin-users',
  templateUrl: './admin-users.component.html',
  styleUrls: ['./admin-users.component.css'],
})
export class AdminUsersComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
