import { BehaviorSubject, merge as observableMerge, Observable } from 'rxjs';

import { DataSource } from '@angular/cdk/table';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';

import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { orderBy, uniq } from 'lodash';
import * as moment from 'moment';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { CustomDatePipe } from '../../../shared/pipes/custom-date.pipe';
import { UtilsService } from '../../../shared/services/utils.service';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { ActionLogListComponent } from '../../action-log/action-log-list/action-log-list.component';
import { CustomerService } from '../../customer/shared/customer.service';
import { ExistingPatientService } from '../../existing-patient/shared/existing-patient.service';
import { ProductService } from '../../product/shared/product.service';
import { ProductUtil } from '../../product/shared/productUtil.type';
import { CustomerProspectViewComponent } from '../customer-prospect-view/customer-prospect-view.component';
import { GroupInviteModalComponent } from '../group-invite-modal/group-invite-modal.component';
import { CustomerProspectService } from '../shared/customerProspect.service';
import { CustomerProspectUtilClass } from '../shared/customerProspectUtil';

import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { SideMenuService } from '../../../shared/services/side-menu.service';
import { MarketingService } from '../../marketing/shared/marketing.service';
import { SmsConversationComponent } from '../../message/sms-conversation/sms-conversation.component';
import { SmsPromoterComponent } from '../../message/sms-promoter/sms-promoter.component';
import { NotesModal, NotesModalConfig } from '../../notes/modals/notes-modal/notes.modal';
import { TagCreateEditCardComponent } from '../../tags/tag-create-edit-card/tag-create-edit-card.component';
import { TagPatientManagerComponent } from '../../tags/tag-patient-manager/tag-patient-manager.component';
import { CustomerProspectCreateComponent } from '../customer-prospect-create/customer-prospect-create.component';
import { CustomerProspectViewModelComponent } from '../customer-prospect-view-model/customer-prospect-view-model.component';
import { CustomPhonePipe } from '../../../shared/pipes/custom-phone.pipe';

@Component({
  selector: 'app-customer-prospect-list-global',
  templateUrl: './customer-prospect-list-global.component.html',
  styleUrls: ['./customer-prospect-list-global.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.row', style({ opacity: 0 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.row', style({ opacity: 1 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class CustomerProspectListGlobalComponent implements OnInit {
  @Input()
  voided = false;
  @Input()
  title = 'Patients';

  @Output()
  getCards = new EventEmitter();

  @Input()
  manualSelection = false;

  @Input()
  hasEmail = null;

  @Input()
  hasMobile = null;

  @Input()
  getUnselectManual = new EventEmitter();

  @Output()
  getDetailedView = new EventEmitter();

  @Input()
  dateFrom;

  @Input()
  IDs;

  @Input()
  dateTo;

  @Input()
  merchantID;

  @Input()
  tableName;

  @Input()
  setPatients;

  @Input()
  filterID;

  @Input()
  filterIDs;

  @Output()
  getSetPatients = new EventEmitter();

  innerSubscription = false;

  @Output()
  detailView = new EventEmitter();

  displayDetailCard = false;
  patientID;
  @Input()
  pageSize = Settings.global['listPageSize'] || 20;
  pageSizeOptions = [20, 50, 100, 200];
  searchLabel;
  displayedColumns = [
    'checkbox',
    'profilePic',
    'FirstName',
    'LastName',
    'mobile',
    'email',
    'address',
    // 'tags',
    'marketing',
    'referred',
    'Actions',
  ];

  role;
  isPromoterOrAdmin = false;
  filters = [];

  searchVals = new FormControl();

  searchValList = ['First Name', 'Last Name', 'Email', 'Mobile'];

  public existingPatientsDB: LoadExistingPatients | null;
  dataSource: ExistingPatientDataSource | null;

  typeLabel;

  filteredSize = null;
  subjectArray = [];

  customerProspectUtil = new CustomerProspectUtilClass();
  existingPatientID;
  displayInvitations = false;

  isModulePatientSMSActive = Settings.global['isModulePatientSMSActive'];
  isModuleNoteFeaturesActive = Settings.global['isModuleNoteFeaturesActive'];
  isModuleTreatmentPlanActive = Settings.global['isModuleTreatmentPlanActive'];
  isModulePatientHistoryActive = Settings.global['isModulePatientHistoryActive'];
  isModuleAppointmentActive = Settings.global['isModuleAppointmentActive'];

  financedProductGroup;
  productUtil = new ProductUtil();
  healthHistoryActive = true;
  selectedProspectIDs = [];
  selectedCustomerIDs = [];
  merchantTags = [];
  marketingFilters = [];
  currentPractice;
  isAppointmentDisabled = false;

  destroyEvent = new EventEmitter();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('filter', { static: false }) filter: ElementRef;
  manualSelectionsCards = [];

  @Output()
  getSelectedCards = new EventEmitter();
  @Input()
  firstName;
  @Input()
  partFirstName;
  @Input()
  IDFromMerchant;
  @Input()
  partIDFromMerchant;

  @Input()
  state;
  @Input()
  id;
  @Input()
  lastName;
  @Input()
  partLastName;
  @Input()
  middleName;
  @Input()
  partMiddleName;
  @Input()
  email;
  @Input()
  partEmail;
  @Input()
  mobile;
  @Input()
  partMobile;

  @Input()
  partPhone;
  @Input()
  calculatedName;
  @Input()
  partCalculatedName;
  @Input()
  birthDay;
  @Input()
  tags;
  @Input()
  maxRecords;

  @Input()
  suburb;
  @Input()
  postCodeFrom;
  @Input()
  postCodeTo;
  @Input()
  postCode;

  @Input()
  marketingConsent;


  @Input()
  marketingConsentEmail;

  constructor(
    private utilService: UtilsService,
    private authenticationService: AuthenticationService,
    private dialog: MatDialog,
    private router: Router,
    private customDate: CustomDatePipe,
    private customerProspectService: CustomerProspectService,
    private customerService: CustomerService,
    private existingPatientService: ExistingPatientService,
    private marketingService: MarketingService,
    private productService: ProductService,
    private customPipePhone:CustomPhonePipe
  ) {
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;
    });
  }

  ngOnInit() {
    AuthenticationService.appoinmentAccess.subscribe((r) => {
      this.isAppointmentDisabled = r;
    });

    AuthenticationService.healthHistoryAccess.subscribe((r) => {
      this.healthHistoryActive = r;
    });

    if (this.getUnselectManual) {
      this.getUnselectManual.subscribe((id) => {
        if (id && this.manualSelectionsCards && this.manualSelectionsCards.length > 0) {
          let index = this.manualSelectionsCards.findIndex((_obj) => _obj.ID == id);

          if (index != -1) {
            this.manualSelectionsCards.slice(index, 1);
          }
        }
      });
    }

    this.authenticationService.getCurrentPractice().subscribe((currentPractice) => {
      if (currentPractice) {
        this.currentPractice = currentPractice;
        this.checkBookingEngine();
        this.checkHH();
      }
    });

    if (this.manualSelection === true) {
      this.displayedColumns = [
        'checkboxManual',
        'profilePic',
        'FirstName',
        'LastName',
        'mobile',
        'email',
        'address',
        // 'tags',
        'marketing',
        'referred',
      ];

      this.pageSize = 20;
    }

    this.utilService.getCurrentAccess().subscribe((access) => {
      if (access) {
        this.isModuleNoteFeaturesActive = access['isModuleNoteFeaturesActive'];
        this.isModuleTreatmentPlanActive = access['isModuleTreatmentPlanActive'];
        this.isModulePatientHistoryActive = access['isModulePatientHistoryActive'];
        this.isModulePatientSMSActive = access['isModulePatientSMSActive'];

        this.isModuleAppointmentActive = access['isModuleAppointmentActive'];
      }

      SideMenuService.goBack.subscribe((res) => {
        if (res == true) {
          this.hideViewDetails();
        }
      });

      if (this.tableName == 'customer') {
        this.typeLabel = 'Customers';
      } else if (this.tableName == 'prospect') {
        this.typeLabel = 'Prospects';
      } else {
        this.typeLabel = 'Patients';
      }
      this.authenticationService.getCurrentUser().subscribe((res) => {
        if (res && res.success && res.data['Role'] && res.data['Role']) {
          this.role = res.data['Role'];

          // const params = {
          //   IDs: this.IDs,
          //   filterID: this.filterID,
          //   filterIDs: this.filterIDs,

          //   firstName: this.firstName || null,
          //   partFirstName: this.partFirstName || null,
          //   lastName: this.lastName || null,
          //   partLastName: this.partLastName || null,
          //   middleName: this.middleName || null,
          //   partMiddleName: this.partMiddleName || null,
          //   email: this.email || null || null,
          //   partEmail: this.partEmail || null,
          //   mobile: this.mobile || null,
          //   partMobile: this.partMobile || null,
          //   partPhone: this.partPhone || null,

          //   calculatedName: this.calculatedName || null,
          //   partCalculatedName: this.partCalculatedName || null,
          //   birthDay: this.birthDay || null,
          //   tags: this.tags || null,
          //   maxRecords: this.maxRecords || null,

          //   suburb: this.suburb || null,
          //   postCodeFrom: this.postCodeFrom || null,
          //   postCodeTo: this.postCodeTo || null,
          //   postCode: this.postCode || null,

          //   marketingConsent: this.marketingConsent,

          //   IDFromMerchant: this.IDFromMerchant,
          //   partIDFromMerchant: this.partIDFromMerchant,
          //   state: this.state,

          //   id: this.id,
          // };
          // this.existingPatientsDB = new LoadExistingPatients(
          //   this.customerProspectService,
          //   this.isPromoterOrAdmin,
          //   this.destroyEvent,
          //   this.merchantID,
          //   this.dateFrom,
          //   this.dateTo,
          //   this.tableName,
          //   this.voided,
          //   this.setPatients,
          //   params
          // );
          // this.dataSource = new ExistingPatientDataSource(this.existingPatientsDB, this.paginator, this.sort);
        }
      });

      // this.existingPatientsDB.getMerchantTags.subscribe((tags) => {
      //   this.merchantTags = JSON.parse(JSON.stringify(tags || []));
      // });

      this.getProductGroup();

      this.getMarketingFitler();
    });
  }

  checkHH() {
    if (this.currentPractice && this.currentPractice['HealthHistoryEnabled.Overridden'] == 'Y') {
      this.healthHistoryActive = true;
    } else {
      this.healthHistoryActive = false;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.tableName == 'customer') {
      this.typeLabel = 'Customers';
    } else if (this.tableName == 'prospect') {
      this.typeLabel = 'Prospects';
    } else {
      this.typeLabel = 'Patients';
    }

    const params = {
      IDs: this.IDs,
      filterID: this.filterID,
      filterIDs: this.filterIDs,

      firstName: this.firstName || null,
      partFirstName: this.partFirstName || null,
      lastName: this.lastName || null,
      partLastName: this.partLastName || null,
      middleName: this.middleName || null,
      partMiddleName: this.partMiddleName || null,
      email: this.email || null || null,
      partEmail: this.partEmail || null,
      mobile: this.mobile || null,
      partMobile: this.partMobile || null,
      partPhone: this.partPhone || null,

      calculatedName: this.calculatedName || null,
      partCalculatedName: this.partCalculatedName || null,
      birthDay: this.birthDay || null,
      tags: this.tags || null,
      maxRecords: this.maxRecords || null,

      suburb: this.suburb || null,
      postCodeFrom: this.postCodeFrom || null,
      postCodeTo: this.postCodeTo || null,
      postCode: this.postCode || null,

      marketingConsent: this.marketingConsent,
      marketingConsentEmail: this.marketingConsentEmail,

      IDFromMerchant: this.IDFromMerchant,
      partIDFromMerchant: this.partIDFromMerchant,
      state: this.state,

      hasEmail: this.hasEmail,
      hasMobile: this.hasMobile,
      id: this.id,
    };

    this.existingPatientsDB = new LoadExistingPatients(
      this.customerProspectService,
      this.isPromoterOrAdmin,
      this.destroyEvent,
      this.merchantID,
      this.dateFrom,
      this.dateTo,
      this.tableName,
      this.voided,
      this.setPatients,
      params,
      this.customPipePhone
    );
    this.dataSource = new ExistingPatientDataSource(this.existingPatientsDB, this.paginator, this.sort);

    if (this.innerSubscription == false && this.existingPatientsDB) {
      this.existingPatientsDB.getMerchantTags.subscribe((tags) => {
        this.merchantTags = JSON.parse(JSON.stringify(tags || []));
      });

      this.innerSubscription = true;
    }
  }

  getMarketingFitler() {
    const p = {
      merchantID: this.merchantID,
    };
    this.marketingService.getFilterList(p, this.isPromoterOrAdmin).subscribe((res) => {
      if (res && res.length) {
        this.marketingFilters = res;
      }
    });
  }

  createNote(patientID: string) {
    this.dialog.open<NotesModal, NotesModalConfig>(NotesModal, {
      data: {
        patientID,
        parentRoute: 'merchant',
        noteListMode: 'create',
        relationshipLink: {
          label: 'patient',
          route: `customer-profile/${patientID}/patient`,
          itemID: patientID,
        },
      },
    });
  }

  quickEdit(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(CustomerProspectCreateComponent, {
        data: {
          customerID: id,
          isFullDetails: false,
        },
        width: '800px',
      });
      ref.componentInstance.close.subscribe((res) => {
        if (res == true) {
          ref.close();
        }
      });
      ref.componentInstance.getResult.subscribe((_res) => {
        if (_res) {
          let res = _res;

          res['fullName'] = '';
          if (res && res['addresses.Calculated']) {
            res['fullName'] = res['fullName'] + ',' + res['addresses.Calculated'];
          }
          if (res && res['phones.Number']) {
            res['fullName'] = res['fullName'] + ',' + res['phones.Number'];
          }
          if (res && res['mobiles.Number']) {
            res['fullName'] = res['fullName'] + ',' + res['mobiles.Number'];
          }
          if (res && res['emails.Email']) {
            res['fullName'] = res['fullName'] + ',' + res['emails.Email'];
          }
          if (res && res['CalculatedName']) {
            res['fullName'] = res['fullName'] + ',' + res['CalculatedName'];
          }
          if (res && res['DateOfBirth']) {
            res['fullName'] = res['fullName'] + ',' + res['DateOfBirth'];
          }
          if (res && res['ID']) {
            res['fullName'] = res['fullName'] + ',' + res['ID'];
          }
          if (res.tags && Array.isArray(res.tags) && res.tags.length > 0) {
            const item = {
              ID: res.ID,
              tags: res.tags,
            };
            this.dataSource.setTagItem = item;
          } else {
            const item = {
              ID: res.ID,
              tags: [],
            };
            this.dataSource.setTagItem = item;
          }

          this.dataSource.replaceItem = res;
          ref.close();
        }
      });
    }
  }

  viewNotes(patientID: string) {
    this.dialog.open<NotesModal, NotesModalConfig>(NotesModal, {
      data: {
        parentRoute: 'merchant',
        patientID,
      },
    });
  }

  quickView(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(CustomerProspectViewModelComponent, {
        data: {
          patientID: id,
          isFullDetails: true,
        },
        width: '700px',
      });
      ref.componentInstance.detailedView.subscribe((res) => {
        ref.close();
        ref.afterClosed().subscribe((r) => {
          this.router.navigate([
            '/merchant',
            {
              outlets: {
                page: ['customer-profile', id],
              },
            },
          ]);

          this.getDetailedView.emit(true);
        });
      });

      ref.componentInstance.close.subscribe((res) => {
        if (res == true) {
          ref.close();
        }
      });
    }
  }

  checkBookingEngine() {
    if (this.currentPractice && this.currentPractice['ThirdPartyBooking_URL']) {
      this.isAppointmentDisabled = true;
    } else {
      this.isAppointmentDisabled = false;
    }
  }

  changeFilter(e) {
    if (e && e.ID) {
      this.marketingService.getOneFilter(e.ID, true).subscribe((res) => {
        if (res && res.PatientIDs) {
          this.getSetPatients.emit(res.PatientIDs);
        }
      });
    } else {
      this.getSetPatients.emit(null);
    }
  }

  requestAppointment(ID) {
    this.router.navigate([
      '/merchant',
      {
        outlets: {
          page: ['appointment', 'appointment-create-merchant', 'customer', ID],
        },
      },
    ]);

    this.getDetailedView.emit(true);
  }

  addNewFilter() {
    this.router.navigate(['/merchant', { outlets: { page: ['new-filter'] } }]);

    this.getDetailedView.emit(true);
  }

  getProductGroup() {
    const p1 = {
      isFinance: true,
    };
    if (this.isPromoterOrAdmin == true) {
      p1['merchantID'] = this.merchantID;
    }
    this.productService.getMerchantProductGroup(p1).subscribe((res) => {
      this.financedProductGroup = res;
    });
  }

  setFilter(event, field) {
    let filter;
    if (field == 'Tags') {
      let e = event;
      if (typeof e === 'string') {
        e = [event];
      }

      filter = {
        field,
        value: e,
      };

      this.searchLabel = event;
    } else if (field == 'NoTags') {
      let e = event;
      if (typeof e === 'string') {
        e = [event];
      }

      filter = {
        field,
        value: e,
      };

      this.searchLabel = event;
    } else if (typeof event == 'object' && event != null) {
      const v = this.customDate.transform(event, Settings.global['dateFormat']);
      filter = {
        field,
        value: v,
      };

      this.searchLabel = v;
    } else if (event) {
      filter = {
        field,
        value: event,
      };

      this.searchLabel = event;
    } else {
      filter = {
        field,
        value: '',
      };

      this.searchLabel = null;
    }

    this.dataSource.filter = filter;
    this.filteredSize = this.dataSource.filteredData.length;
  }

  indexOfTag(e) {
    if (this.searchLabel && Array.isArray(this.searchLabel) && e) {
      if (this.searchLabel.indexOf(e) != -1) {
        return true;
      }
    }

    return false;
  }

  selectAll() {
    for (let i = 0; i < this.dataSource.filteredData.length; i++) {
      const o = this.dataSource.filteredData[i];

      if (o && o['ID'] && o['TableName'] == 'Prospect' && this.selectedProspectIDs.indexOf(o['ID']) == -1) {
        this.selectedProspectIDs.push(o['ID']);
      } else if (o && o['ID'] && o['TableName'] == 'Customer' && this.selectedCustomerIDs.indexOf(o['ID']) == -1) {
        this.selectedCustomerIDs.push(o['ID']);
      }
    }
  }

  selectPage() {
    const index = this.dataSource['_paginator']['index'] || 0;
    const pageSize = this.dataSource['_paginator']['pageSize'] || 0;

    for (let i = 0; i < (index + 1) * pageSize; i++) {
      const o = this.dataSource.filteredData[index * pageSize + i];

      if (o && o['ID'] && o['TableName'] == 'Prospect' && this.selectedProspectIDs.indexOf(o['ID']) == -1) {
        this.selectedProspectIDs.push(o['ID']);
      } else if (o && o['ID'] && o['TableName'] == 'Customer' && this.selectedCustomerIDs.indexOf(o['ID']) == -1) {
        this.selectedCustomerIDs.push(o['ID']);
      }
    }
  }

  select(id, tablename, isSelected) {
    if (isSelected == true) {
      if (tablename == 'Prospect' && this.selectedProspectIDs.indexOf(id) == -1) {
        this.selectedProspectIDs.push(id);
      } else if (tablename == 'Customer' && this.selectedCustomerIDs.indexOf(id) == -1) {
        this.selectedCustomerIDs.push(id);
      }
    } else {
      if (tablename == 'Prospect') {
        this.selectedProspectIDs.splice(this.selectedProspectIDs.indexOf(id), 1);
      } else if (tablename == 'Customer') {
        this.selectedCustomerIDs.splice(this.selectedCustomerIDs.indexOf(id), 1);
      }
    }
  }

  unselect() {
    this.selectedCustomerIDs = [];
    this.selectedProspectIDs = [];
  }

  isSelected(id, tablename) {
    if (tablename == 'Prospect') {
      if (!this.selectedProspectIDs || this.selectedProspectIDs.length <= 0) {
        return false;
      } else {
        if (this.selectedProspectIDs.indexOf(id) != -1) {
          return true;
        }
      }
    } else if (tablename == 'Customer') {
      if (!this.selectedCustomerIDs || this.selectedCustomerIDs.length <= 0) {
        return false;
      } else {
        if (this.selectedCustomerIDs.indexOf(id) != -1) {
          return true;
        }
      }
    }

    return false;
  }

  selectAllManual() {
    for (let i = 0; i < this.dataSource.filteredData.length; i++) {
      const o = this.dataSource.filteredData[i];

      let index = this.manualSelectionsCards.findIndex((_obj) => _obj.ID == o.ID);

      if (index == -1) {
        this.manualSelectionsCards.push(o);
      }
    }

    this.getSelectedCards.emit(this.manualSelectionsCards);
  }

  selectPageManual() {
    const index = this.dataSource['_paginator']['index'] || 0;
    const pageSize = this.dataSource['_paginator']['pageSize'] || 0;

    for (let i = 0; i < (index + 1) * pageSize; i++) {
      const o = this.dataSource.filteredData[index * pageSize + i];

      let index2 = this.manualSelectionsCards.findIndex((_obj) => _obj.ID == o.ID);

      if (index2 == -1) {
        this.manualSelectionsCards.push(o);
      }
    }

    this.getSelectedCards.emit(this.manualSelectionsCards);
  }

  selectManual(card, isSelected) {
    if (card && card.ID) {
      let index = this.manualSelectionsCards.findIndex((_obj) => _obj.ID == card.ID);

      if (isSelected == true) {
        if (index == -1) {
          this.manualSelectionsCards.push(card);
        }
      } else {
        if (index != -1) {
          this.manualSelectionsCards.splice(index, 1);
        }
      }
    }

    this.getSelectedCards.emit(this.manualSelectionsCards);
  }

  unselectManual() {
    this.manualSelectionsCards = [];
    this.getSelectedCards.emit(this.manualSelectionsCards);
  }

  isSelectedManual(card) {
    if (card && card.ID) {
      if (!this.manualSelectionsCards || this.manualSelectionsCards.length <= 0) {
        return false;
      } else {
        let index = this.manualSelectionsCards.findIndex((_obj) => _obj.ID == card.ID);

        if (index != -1) {
          return true;
        }
      }
    }
    return false;
  }
  createTag(row) {
    if (row && row.ID) {
      const ref = RootAppComponent.dialog.open(TagPatientManagerComponent, {
        data: {
          patientID: row.ID,
          patientTags: row.Tags || [],
        },
        width: '700px',
      });

      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });

      ref.componentInstance.getResult.subscribe((res) => {
        if (res && Array.isArray(res) && res.length > 0) {
          const item = {
            ID: row.ID,
            tags: res,
          };
          this.dataSource.setTagItem = item;
        } else {
          const item = {
            ID: row.ID,
            tags: [],
          };
          this.dataSource.setTagItem = item;
        }
      });
    }
  }

  enableGroup() {
    const confirmDialog = new ConfirmDialog(
      'check_circle',
      'Enable Marketing Consent',
      '<p>Enable patients marketing consent to receive SMS communication.</p>',
      'Cancel',
      'Proceed'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
    });

    if (this.selectedProspectIDs && this.selectedProspectIDs.length > 0) {
      ref.componentInstance.onConfirm.subscribe((val) => {
        if (val == true) {
          ref.close();
          ref.afterClosed().subscribe((res) => {
            const payload = {
              ids: this.selectedProspectIDs.join(','),
              marketingConsent: true,
            };

            this.customerProspectService.editProspectMarketingConsentGroup(payload).subscribe((res) => {
              if (res && res.length > 0) {
                for (let i = 0; i < res.length; i++) {
                  res[i]['TableName'] = 'Prospect';
                  this.dataSource.replaceItem = res[i];
                }

                this.selectedProspectIDs = [];

                NotifyAppComponent.displayToast('success', 'Success!', 'Marketing consents are enabled.');
              }
            });
          });
        } else {
          ref.close();
        }
      });
    }

    if (this.selectedCustomerIDs && this.selectedCustomerIDs.length > 0) {
      ref.componentInstance.onConfirm.subscribe((val) => {
        if (val == true) {
          ref.close();
          ref.afterClosed().subscribe((res) => {
            const payload = {
              ids: this.selectedCustomerIDs.join(','),
              marketingConsent: true,
            };
            this.customerProspectService.editCustomerMarketingConsentGroup(payload).subscribe((res) => {
              if (res && res.length > 0) {
                for (let i = 0; i < res.length; i++) {
                  res[i]['TableName'] = 'Customer';
                  this.dataSource.replaceItem = res[i];
                }

                this.selectedCustomerIDs = [];

                NotifyAppComponent.displayToast('success', 'Success!', 'Marketing consents are enabled.');
              }
            });
          });
        } else {
          ref.close();
        }
      });
    }
  }

  disableGroup() {
    const confirmDialog = new ConfirmDialog(
      'cancel',
      'Disable marketing consent',
      '<p>Disable patients marketing consent to SMS communication</p>',
      'Cancel',
      'Proceed'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
    });

    if (this.selectedProspectIDs && this.selectedProspectIDs.length > 0) {
      ref.componentInstance.onConfirm.subscribe((val) => {
        if (val == true) {
          ref.close();
          ref.afterClosed().subscribe((res) => {
            const payload = {
              ids: this.selectedProspectIDs.join(','),
              marketingConsent: false,
            };

            this.customerProspectService.editProspectMarketingConsentGroup(payload).subscribe((res) => {
              if (res && res.length > 0) {
                for (let i = 0; i < res.length; i++) {
                  res[i]['TableName'] = 'Prospect';
                  this.dataSource.replaceItem = res[i];
                }

                this.selectedProspectIDs = [];

                NotifyAppComponent.displayToast('success', 'Success!', 'Marketing consents are disabled.');
              }
            });
          });
        } else {
          ref.close();
        }
      });
    }

    if (this.selectedCustomerIDs && this.selectedCustomerIDs.length > 0) {
      ref.componentInstance.onConfirm.subscribe((val) => {
        if (val == true) {
          ref.close();
          ref.afterClosed().subscribe((res) => {
            const payload = {
              ids: this.selectedCustomerIDs.join(','),
              marketingConsent: false,
            };
            this.customerProspectService.editCustomerMarketingConsentGroup(payload).subscribe((res) => {
              if (res && res.length > 0) {
                for (let i = 0; i < res.length; i++) {
                  res[i]['TableName'] = 'Customer';
                  this.dataSource.replaceItem = res[i];
                }

                this.selectedCustomerIDs = [];

                NotifyAppComponent.displayToast('success', 'Success!', 'Marketing consents are disabled.');
              }
            });
          });
        } else {
          ref.close();
        }
      });
    }
  }




  enableGroupEmail() {
    const confirmDialog = new ConfirmDialog(
      'check_circle',
      'Enable Marketing Consent',
      '<p>Enable patients marketing consent to receive Email communication.</p>',
      'Cancel',
      'Proceed'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
    });

    if (this.selectedProspectIDs && this.selectedProspectIDs.length > 0) {
      ref.componentInstance.onConfirm.subscribe((val) => {
        if (val == true) {
          ref.close();
          ref.afterClosed().subscribe((res) => {
            const payload = {
              ids: this.selectedProspectIDs.join(','),
              marketingConsentEmail: true,
            };

            this.customerProspectService.editProspectMarketingConsentGroup(payload).subscribe((res) => {
              if (res && res.length > 0) {
                for (let i = 0; i < res.length; i++) {
                  res[i]['TableName'] = 'Prospect';
                  this.dataSource.replaceItem = res[i];
                }

                this.selectedProspectIDs = [];

                NotifyAppComponent.displayToast('success', 'Success!', 'Marketing consents are enabled.');
              }
            });
          });
        } else {
          ref.close();
        }
      });
    }

    if (this.selectedCustomerIDs && this.selectedCustomerIDs.length > 0) {
      ref.componentInstance.onConfirm.subscribe((val) => {
        if (val == true) {
          ref.close();
          ref.afterClosed().subscribe((res) => {
            const payload = {
              ids: this.selectedCustomerIDs.join(','),
              marketingConsentEmail: true,
            };
            this.customerProspectService.editCustomerMarketingConsentGroup(payload).subscribe((res) => {
              if (res && res.length > 0) {
                for (let i = 0; i < res.length; i++) {
                  res[i]['TableName'] = 'Customer';
                  this.dataSource.replaceItem = res[i];
                }

                this.selectedCustomerIDs = [];

                NotifyAppComponent.displayToast('success', 'Success!', 'Marketing consents are enabled.');
              }
            });
          });
        } else {
          ref.close();
        }
      });
    }
  }

  disableGroupEmail() {
    const confirmDialog = new ConfirmDialog(
      'cancel',
      'Disable marketing consent',
      '<p>Disable patients marketing consent to Email communication</p>',
      'Cancel',
      'Proceed'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
    });

    if (this.selectedProspectIDs && this.selectedProspectIDs.length > 0) {
      ref.componentInstance.onConfirm.subscribe((val) => {
        if (val == true) {
          ref.close();
          ref.afterClosed().subscribe((res) => {
            const payload = {
              ids: this.selectedProspectIDs.join(','),
              marketingConsentEmail: false,
            };

            this.customerProspectService.editProspectMarketingConsentGroup(payload).subscribe((res) => {
              if (res && res.length > 0) {
                for (let i = 0; i < res.length; i++) {
                  res[i]['TableName'] = 'Prospect';
                  this.dataSource.replaceItem = res[i];
                }

                this.selectedProspectIDs = [];

                NotifyAppComponent.displayToast('success', 'Success!', 'Marketing consents are disabled.');
              }
            });
          });
        } else {
          ref.close();
        }
      });
    }

    if (this.selectedCustomerIDs && this.selectedCustomerIDs.length > 0) {
      ref.componentInstance.onConfirm.subscribe((val) => {
        if (val == true) {
          ref.close();
          ref.afterClosed().subscribe((res) => {
            const payload = {
              ids: this.selectedCustomerIDs.join(','),
              marketingConsentEmail: false,
            };
            this.customerProspectService.editCustomerMarketingConsentGroup(payload).subscribe((res) => {
              if (res && res.length > 0) {
                for (let i = 0; i < res.length; i++) {
                  res[i]['TableName'] = 'Customer';
                  this.dataSource.replaceItem = res[i];
                }

                this.selectedCustomerIDs = [];

                NotifyAppComponent.displayToast('success', 'Success!', 'Marketing consents are disabled.');
              }
            });
          });
        } else {
          ref.close();
        }
      });
    }
  }

  createStaticFilterTag() {
    const ids = this.selectedProspectIDs.concat(this.selectedCustomerIDs);

    if (ids.length > 0) {
      const ref = RootAppComponent.dialog.open(TagCreateEditCardComponent, {
        data: {
          cardIDs: ids,
          type: 'StaticFilter',

          merchantID: this.merchantID,
        },
        width: '650px',
      });
      ref.componentInstance.newTag.subscribe((res) => {
        if (res && res.Label) {
          this.merchantTags.push(res.Label);

          this.merchantTags = uniq(this.merchantTags);
          this.merchantTags = JSON.parse(JSON.stringify(this.merchantTags));
        }
      });
      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });

      ref.componentInstance.getResult.subscribe((res) => {
        if (res) {
          ref.close();

          if (Array.isArray(res) && res.length > 0) {
            for (let i = 0; i < res.length; i++) {
              const patientIDs = res[i].Cards_key.split('|');

              if (patientIDs && patientIDs.length > 0) {
                for (let j = 0; j < patientIDs.length; j++) {
                  const item = {
                    ID: patientIDs[j],
                    tag: res[i].Label,
                  };

                  this.dataSource.addTagItem = item;
                }
              }
            }
          } else {
            const patientIDs = res.Cards_key.split('|');

            if (patientIDs && patientIDs.length > 0) {
              for (let i = 0; i < patientIDs.length; i++) {
                const item = {
                  ID: patientIDs[i],
                  tag: res.Label,
                };

                this.dataSource.addTagItem = item;
              }
            }
          }
        }
      });
    }
  }

  removeStaticFilterTag() {
    const ids = this.selectedProspectIDs.concat(this.selectedCustomerIDs);

    if (ids.length > 0) {
      const ref = RootAppComponent.dialog.open(TagCreateEditCardComponent, {
        data: {
          cardIDs: ids,
          isRemoved: true,
          type: 'StaticFilter',
        },
        width: '650px',
      });
      ref.componentInstance.newTag.subscribe((res) => {
        if (res && res.Label) {
          this.merchantTags.push(res.Label);

          this.merchantTags = uniq(this.merchantTags);
          this.merchantTags = JSON.parse(JSON.stringify(this.merchantTags));
        }
      });
      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });

      ref.componentInstance.getResult.subscribe((res) => {
        if (res) {
          ref.close();

          if (Array.isArray(res) && res.length > 0) {
            for (let i = 0; i < res.length; i++) {
              const patientIDs = ids;

              if (patientIDs && patientIDs.length > 0) {
                for (let j = 0; j < patientIDs.length; j++) {
                  const item = {
                    ID: patientIDs[j],
                    tag: res[i].Label,
                  };

                  this.dataSource.removeTagItem = item;
                }
              }
            }
          } else {
            const patientIDs = ids;

            if (patientIDs && patientIDs.length > 0) {
              for (let i = 0; i < patientIDs.length; i++) {
                const item = {
                  ID: patientIDs[i],
                  tag: res.Label,
                };

                this.dataSource.removeTagItem = item;
              }
            }
          }
        }
      });
    }
  }

  sendProfileUpdateInvGroup() {
    const ids = this.selectedProspectIDs.concat(this.selectedCustomerIDs);

    if (ids.length > 0) {
      const ref = RootAppComponent.dialog.open(GroupInviteModalComponent, {
        data: {
          patientsID: ids,
          needMedicalHistory: false,
          modalType: 'profile',
        },
        width: '750px',
      });

      ref.componentInstance.getResult.subscribe((res) => {});
    }
  }

  sendMedicalHistoryInvGroup() {
    const ids = this.selectedProspectIDs.concat(this.selectedCustomerIDs);

    if (ids.length > 0) {
      const ref = RootAppComponent.dialog.open(GroupInviteModalComponent, {
        data: {
          patientsID: ids,
          needMedicalHistory: true,
          modalType: 'health',
        },
        width: '750px',
      });

      ref.componentInstance.getResult.subscribe((res) => {});
    }
  }

  openQuickViewDialog(ID) {
    const ref = this.dialog.open(CustomerProspectViewComponent, {
      data: ID,
      width: '650px',
      panelClass: 'noCard',
    });

    ref.componentInstance.closeModal.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  populateDetailCard(ID) {
    if (ID) {
      this.router.navigate([
        '/merchant',
        {
          outlets: {
            page: ['customer-profile', ID, 'patient'],
          },
        },
      ]);

      this.getDetailedView.emit(true);
    }
  }

  newPatientQuick() {
    const ref2 = RootAppComponent.dialog.open(CustomerProspectCreateComponent, {
      data: {
        merchantID: this.merchantID,
        isEmailRequired: true,
      },
      width: '850px',
    });

    ref2.componentInstance.close.subscribe((res) => {
      ref2.close();
    });
    ref2.componentInstance.getResult.subscribe((res) => {
      if (res && res.ID) {
        ref2.close();

        this.router.navigate([
          '/merchant',
          {
            outlets: {
              page: ['customer-profile', res.ID, 'patient'],
            },
          },
        ]);

        this.getDetailedView.emit(true);
      }
    });
  }
  hideViewDetails() {
    this.displayDetailCard = false;
    this.patientID = null;
    this.detailView.emit(false);
  }

  patientInvitation(id) {
    // let ref = this.dialog.open(InvitationExistingPatientListComponent, {
    //   data: id,
    //   width: "80%",
    //   panelClass: "noCard"
    // });
    // ref.componentInstance.closeModal.subscribe(res => {
    //   ref.close();
    // })
    this.existingPatientID = id;
    this.displayInvitations = true;
    this.detailView.emit(true);
  }

  cancelInvitationView() {
    this.existingPatientID = null;
    this.displayInvitations = false;
    this.detailView.emit(false);
  }

  contact(patient) {
    if (patient['TableName'] == 'Customer') {
      const data = {
        targetType: 'customer',
        targetID: patient['ID'],
        asGuest: false,
        asProfile: true,
        asPractice: true,
        subjectArray: this.subjectArray,
      };

      AuthenticationService.contactInputEvent.emit(data);
    } else if (patient['TableName'] == 'Prospect') {
      const data = {
        targetType: 'prospect',
        targetID: patient['ID'],
        asGuest: false,
        asProfile: true,
        asPractice: true,
        subjectArray: this.subjectArray,
      };

      AuthenticationService.contactInputEvent.emit(data);
    }
  }

  patientLog(ID) {
    const ref = this.dialog.open(ActionLogListComponent, {
      data: {
        targetID: ID,
        merchantID: this.merchantID,
      },
      width: '900px',
    });

    ref.componentInstance.closeModal.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  linkToInvitation(patient, p) {
    const payload = {
      productGroupCode: p['GroupCode'],
    };
    if (patient['TableName'] == 'Customer') {
      this.customerProspectService.linkCustomerToInv(patient['ID'], payload).subscribe((res) => {
        if (res.success) {
          this.router.navigate(['/merchant', { outlets: { page: ['invitation-create', res.data] } }]);

          this.getDetailedView.emit(true);
        }
      });
    } else if (patient['TableName'] == 'Prospect') {
      this.customerProspectService.linkProspectToInv(patient['ID'], payload).subscribe((res) => {
        if (res.success) {
          this.router.navigate(['/merchant', { outlets: { page: ['invitation-create', res.data] } }]);

          this.getDetailedView.emit(true);
        }
      });
    }
  }

  sendSMS(id) {
    if (id) {
      const ref2 = RootAppComponent.dialog.open(SmsPromoterComponent, {
        data: {
          merchantID: this.merchantID,
          cardID: id,
          singleChat: true,
        },
        panelClass: 'noCard',
        width: '500px',
      });
      ref2.componentInstance.close.subscribe((data) => {
        ref2.close();
      });

      ref2.componentInstance.getResult.subscribe((data) => {
        if (data) {
          const ref = RootAppComponent.dialog.open(SmsConversationComponent, {
            data,
            panelClass: 'bigger-screen',
            width: '70%',
            height: '95vh',
          });
          const sub = ref.componentInstance.close.subscribe((data) => {
            ref.close();
          });
        }

        ref2.close();
      });
    }
  }

  inviteMedicalHistory(id) {
    if (id) {
      this.router.navigate([
        '/merchant',
        { outlets: { page: ['medical-history', 'medical-history-invitation-create', id] } },
      ]);

      this.getDetailedView.emit(true);

      // let ref = RootAppComponent.dialog.open(MedicalHistoryInvitationCreate, {
      //   data: {
      //     patientID: id
      //   },
      //   width: "1200px"
      // });

      // ref.componentInstance.close.subscribe(res => {

      //   ref.close();
      // })

      // ref.componentInstance.getResult.subscribe(res => {

      //   if (res) {
      //     ref.close();
      //   }

      // })
    }
  }

  archivePatient(patient) {
    const confirmDialog = new ConfirmDialog(
      'delete',
      'Archive Patient?',
      '<p><strong>Are you sure?</strong></p><p class="noFurther">He/She will not recieve any further notifications.</p>',
      'No',
      'Yes, archive'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
    });

    ref.componentInstance.onConfirm.subscribe((res) => {
      if (res == true) {
        const payload = {
          firstName: patient.FirstName,
          lastName: patient.Name,
          status: 'Archived',
        };
        if (patient['TableName'] == 'Customer') {
          this.customerService.editCustomer(patient['ID'], payload).subscribe((res) => {
            if (res) {
              this.dataSource.removeItem = patient['ID'];

              NotifyAppComponent.displayToast(
                'success',
                'Successful operation',
                'The patient has been successfully archived'
              );

              ref.close();
            }
          });
        } else if (patient['TableName'] == 'Prospect') {
          this.existingPatientService.editProspect(patient['ID'], payload).subscribe((res) => {
            if (res) {
              this.dataSource.removeItem = patient['ID'];

              NotifyAppComponent.displayToast(
                'success',
                'Successful operation',
                'The patient has been successfully archived'
              );

              ref.close();
            }
          });
        }
      } else {
        ref.close();
      }
    });
  }

  unarchivePatient(patient) {
    const confirmDialog = new ConfirmDialog(
      'fas fa-trash-restore',
      'Unarchive Record?',
      '<p><strong>Are you sure?</strong></p><p class="noFurther">He/She will start recieving notifications.</p>',
      'No',
      'Yes, unarchive'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
    });

    ref.componentInstance.onConfirm.subscribe((res) => {
      if (res == true) {
        const payload = {
          firstName: patient.FirstName,
          lastName: patient.Name,
          status: 'Active',
        };
        if (patient['TableName'] == 'Customer') {
          this.customerService.editCustomer(patient['ID'], payload).subscribe((res) => {
            if (res) {
              this.dataSource.removeItem = patient['ID'];

              NotifyAppComponent.displayToast(
                'success',
                'Successful operation',
                'The patient has been successfully unarchived'
              );

              ref.close();
            }
          });
        } else if (patient['TableName'] == 'Prospect') {
          this.existingPatientService.editProspect(patient['ID'], payload).subscribe((res) => {
            if (res) {
              this.dataSource.removeItem = patient['ID'];

              NotifyAppComponent.displayToast(
                'success',
                'Successful operation',
                'The patient has been successfully unarchived'
              );

              ref.close();
            }
          });
        }
      } else {
        ref.close();
      }
    });
  }

  ngOnDestroy() {
    this.destroyEvent.emit(true);
  }
}

export class LoadExistingPatients implements OnInit {
  public dataChange: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  existingPatients: any;
  count: any;

  get data(): any[] {
    return this.dataChange.value;
  }

  firstLoad = false;

  get firstLoadEvent() {
    return this.firstLoad;
  }

  isDestoyed = false;

  public getMerchantTags = new EventEmitter();
  merchantTags = [];
  util = new UtilsClass();
  serviceRef;

  maxRecords = 0;
  constructor(
    private customerProspectService: CustomerProspectService,
    private isPromoterOrAdmin,
    private destroyEvent,
    private merchantID,
    private dateFrom,
    private dateTo,
    private tableName,
    private voided,
    private setPatients,
    private params,
    private customPipePhone:CustomPhonePipe
  ) {
    this.destroyEvent.subscribe((res) => {
      this.isDestoyed = res;
    });

    let limit = Number(Settings.global['listItemNumber']);

    if (params.maxRecords) {
      limit = Number(params.maxRecords);
    }

    const payload = {
      dateFrom: this.util.EPdateFormat(this.dateFrom),
      dateTo: this.util.EPdateFormat(this.dateTo),
      section: 0,
      limit,
      voided: this.voided,
      merchantID: this.merchantID,
      tableName: this.tableName,
      IDs: null,
      filterID: null,
      filterIDs: null,
      fields:
        'ID,DateTimeCreated,IDFromMerchant,TableName,emails.Email,mobiles.Number,LastModified,FirstName,Name,CalculatedName,addresses.Calculated,ExistingPerson_key,Status,ReferredCustomer,MarketingConsent_Email.Given,MarketingConsent.Given' +
        // 'Tag.Label,Tag.Description,StaticList.Label,StaticList.Description',
        '',

      firstName: params.firstName || null,
      partFirstName: params.partFirstName || null,
      lastName: params.lastName || null,
      partLastName: params.partLastName || null,
      middleName: params.middleName || null,
      partMiddleName: params.partMiddleName || null,
      email: params.email || null,
      partEmail: params.partEmail || null,
      mobile: params.mobile || null,
      partMobile: params.partMobile || null,
      hasEmail: params.hasEmail || null,
      hasMobile: params.hasMobile || null,
      partPhone: params.partPhone || null,
      calculatedName: params.calculatedName || null,
      partCalculatedName: params.partCalculatedName || null,
      birthDay: params.birthDay || null,
      tags: params.tags || null,
      IDFromMerchant: params.IDFromMerchant,
      partIDFromMerchant: params.partIDFromMerchant,

      state: params.state,
      id: params.id,

      suburb: params.suburb || null,
      postCodeFrom: params.postCodeFrom || null,
      postCodeTo: params.postCodeTo || null,
      postCode: params.postCode || null,
      marketingConsent: params.marketingConsent,
      marketingConsentEmail: params.marketingConsentEmail,
    };

    if (this.params) {
      if (this.params.IDs && Array.isArray(this.params.IDs) && this.params.IDs.length > 0) {
        payload.IDs = this.params.IDs.join(',');
      } else if (this.params.IDs && typeof this.params.IDs === 'string' && this.params.IDs.indexOf('|') != -1) {
        const ids = this.params.IDs.split('|');
        payload.IDs = ids.join(',');
      } else if (this.params.IDs && typeof this.params.IDs === 'string' && this.params.IDs.indexOf(',') != -1) {
        payload.IDs = this.params.IDs;
      } else if (this.params.IDs && typeof this.params.IDs === 'string') {
        payload.IDs = [this.params.IDs];
      }
    }

    if (this.params.filterID) {
      payload.filterID = this.params.filterID;
    } else if (this.params.filterIDs) {
      if (this.params.filterIDs && Array.isArray(this.params.filterIDs) && this.params.filterIDs.length > 0) {
        payload.filterIDs = this.params.filterIDs.join(',');
      } else if (
        this.params.filterIDs &&
        typeof this.params.filterIDs === 'string' &&
        this.params.filterIDs.indexOf('|') != -1
      ) {
        const filterIDs = this.params.filterIDs.split('|');
        payload.filterIDs = filterIDs.join(',');
      } else if (
        this.params.filterIDs &&
        typeof this.params.filterIDs === 'string' &&
        this.params.filterIDs.indexOf(',') != -1
      ) {
        payload.filterIDs = this.params.filterIDs;
      } else if (this.params.filterIDs && typeof this.params.filterIDs === 'string') {
        payload.filterIDs = [this.params.filterIDs];
      }
    }

    if (this.setPatients) {
      payload['setPatients'] = this.setPatients;
    }

    this.serviceRef = this.customerProspectService
      .getCustomerProspectListGlobal(payload, this.isPromoterOrAdmin)
      .subscribe((res) => {
        this.count = res.length;

        if (res.length > 0) {
          for (let i = 0; i < res.length; i++) {
            res[i]['fullName'] = '';
            if (res[i] && res[i]['addresses.Calculated']) {
              res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['addresses.Calculated'];
            }
            if (res[i] && res[i]['phones.Number']) {
              res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['phones.Number'];
              res[i]['fullName'] = res[i]['fullName'] + ',' + this.customPipePhone.transform(res[i]['phones.Number'],'true');
              res[i]['fullName'] = res[i]['fullName'] + ',' + this.customPipePhone.transform(res[i]['phones.Number'],'true').trim();
            }
            if (res[i] && res[i]['mobiles.Number']) {
              res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['mobiles.Number'];
              res[i]['fullName'] = res[i]['fullName'] + ',' + this.customPipePhone.transform(res[i]['mobiles.Number']);
              res[i]['fullName'] = res[i]['fullName'] + ',' + this.customPipePhone.transform(res[i]['mobiles.Number']).trim();
            }
            if (res[i] && res[i]['emails.Email']) {
              res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['emails.Email'];
            }
            if (res[i] && res[i]['CalculatedName']) {
              res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['CalculatedName'];
            }
            if (res[i] && res[i]['DateOfBirth']) {
              res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['DateOfBirth'];
            }
            if (res[i] && res[i]['ID']) {
              res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['ID'];
            }
          }
        }

        this.existingPatients = res;

        this.maxRecords = this.maxRecords + (res.length || 0);

        for (let i = 0; i < this.existingPatients.length; i++) {
          this.getTags(i);
        }

        this.merchantTags = uniq(this.merchantTags);
        this.merchantTags = orderBy(this.merchantTags);
        this.getMerchantTags.emit(this.merchantTags);
        this.dataChange.next(this.existingPatients.slice());
        this.firstLoad = true;

        const innerFunction = (section) => {
          section = section + 1;
          UtilsClass.loadingDataSection(section);
          payload.section = section;

          this.serviceRef = this.customerProspectService
            .getCustomerProspectListGlobal(payload, this.isPromoterOrAdmin)
            .subscribe((res) => {
              if (res.length > 0) {
                this.count = res.count;
                this.maxRecords = this.maxRecords + (res.length || 0);

                if (res.length > 0) {
                  for (let i = 0; i < res.length; i++) {
                    res[i]['fullName'] = '';
                    if (res[i] && res[i]['addresses.Calculated']) {
                      res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['addresses.Calculated'];
                    }
                    if (res[i] && res[i]['phones.Number']) {
                      res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['phones.Number'];
                      res[i]['fullName'] = res[i]['fullName'] + ',' + this.customPipePhone.transform(res[i]['phones.Number'],'true');
                      res[i]['fullName'] = res[i]['fullName'] + ',' + this.customPipePhone.transform(res[i]['phones.Number'],'true').trim();
                    }
                    if (res[i] && res[i]['mobiles.Number']) {
                      res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['mobiles.Number'];
                      res[i]['fullName'] = res[i]['fullName'] + ',' + this.customPipePhone.transform(res[i]['mobiles.Number']);
                      res[i]['fullName'] = res[i]['fullName'] + ',' + this.customPipePhone.transform(res[i]['mobiles.Number']).trim();
                    }
                    if (res[i] && res[i]['emails.Email']) {
                      res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['emails.Email'];
                    }
                    if (res[i] && res[i]['CalculatedName']) {
                      res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['CalculatedName'];
                    }
                    if (res[i] && res[i]['DateOfBirth']) {
                      res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['DateOfBirth'];
                    }
                    if (res[i] && res[i]['ID']) {
                      res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['ID'];
                    }
                  }
                }

                this.existingPatients = this.existingPatients.concat(res);

                for (let i = 0; i < this.existingPatients.length; i++) {
                  this.getTags(i);
                }

                this.merchantTags = uniq(this.merchantTags);
                this.merchantTags = orderBy(this.merchantTags);
                this.getMerchantTags.emit(this.merchantTags);
                this.dataChange.next(this.existingPatients);
                if (
                  (this.isDestoyed != true && !params.maxRecords) ||
                  (this.isDestoyed != true && params.maxRecords && Number(params.maxRecords) > Number(this.maxRecords))
                ) {
                  innerFunction(section);
                }
              } else {
                return true;
              }
            });
          UtilsClass.stopLoadingInterceptor();
        };

        if (
          (this.isDestoyed != true && !params.maxRecords) ||
          (this.isDestoyed != true && params.maxRecords && Number(params.maxRecords) > Number(this.maxRecords))
        ) {
          innerFunction(1);
        }
      });
  }

  getTags(index) {
    if (this.existingPatients[index]) {
      let tags = [];
      let staticList = [];
      if (this.existingPatients[index]['Tag.Label']) {
        tags = this.existingPatients[index]['Tag.Label'].split('|');
      }
      if (this.existingPatients[index]['StaticList.Label']) {
        staticList = this.existingPatients[index]['StaticList.Label'].split('|');
      }

      this.existingPatients[index]['Tags'] = tags.concat(staticList);

      this.merchantTags = this.merchantTags.concat(this.existingPatients[index]['Tags']);
    }
  }

  ngOnInit() {}

  ngOnDestroy() {
    if (this.serviceRef) {
      this.serviceRef.unsubscribe();
    }
  }
}

export class ExistingPatientDataSource extends DataSource<any> {
  _filterChange = new BehaviorSubject('');
  field = '';

  get filter(): any {
    return this._filterChange.value;
  }

  set filter(item: any) {
    this.field = item.field;
    this._filterChange.next(item.value);
  }

  set removeItem(id) {
    const data = this._custDatabase.data.filter((row) => row.ID != id);
    this._custDatabase.dataChange.next(data);
  }

  set replaceItem(item) {
    const _index = this._custDatabase.data.findIndex((_obj) => _obj.ID == item.ID);
    this._custDatabase.data[_index] = item;
    this._custDatabase.dataChange.next(this._custDatabase.data);
  }

  set setTagItem(item) {
    const _index = this._custDatabase.data.findIndex((_obj) => _obj.ID == item.ID);
    if (item && item.tags && Array.isArray(item.tags)) {
      this._custDatabase.data[_index]['Tags'] = item.tags;
    }
    this._custDatabase.data[_index]['Tags'] = JSON.parse(JSON.stringify(uniq(this._custDatabase.data[_index]['Tags'])));
    this._custDatabase.dataChange.next(this._custDatabase.data);
  }

  set addTagItem(item) {
    const _index = this._custDatabase.data.findIndex((_obj) => _obj.ID == item.ID);

    if (_index != -1) {
      this._custDatabase.data[_index]['Tags'].push(item.tag);

      this._custDatabase.data[_index]['Tags'] = JSON.parse(
        JSON.stringify(uniq(this._custDatabase.data[_index]['Tags']))
      );
      this._custDatabase.dataChange.next(this._custDatabase.data);
    }
  }

  set removeTagItem(item) {
    const _index = this._custDatabase.data.findIndex((_obj) => _obj.ID == item.ID);

    if (_index != -1) {
      const indexOfTag = this._custDatabase.data[_index]['Tags'].indexOf(item.tag);
      if (indexOfTag != -1) {
        this._custDatabase.data[_index]['Tags'].splice(indexOfTag, 1);
      }

      this._custDatabase.data[_index]['Tags'] = JSON.parse(
        JSON.stringify(uniq(this._custDatabase.data[_index]['Tags']))
      );
      this._custDatabase.dataChange.next(this._custDatabase.data);
    }
  }

  filteredData: any[] = [];

  constructor(private _custDatabase: LoadExistingPatients, private _paginator: MatPaginator, private _sort: MatSort) {
    super();
    this._filterChange.subscribe(() => (this._paginator.pageIndex = 0));
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<any[]> {
    const displayDataChanges = [
      this._custDatabase.dataChange,
      this._filterChange,
      this._paginator.page,
      this._sort.sortChange,
    ];

    return observableMerge(...displayDataChanges).pipe(
      map(() => {
        // Filter data
        this.filteredData = this._custDatabase.data.slice().filter((item: any) => {
          if (this.field == 'Tags') {
            let f = '';
            f = item[this.field] || '';

            if (this.filter && this.filter.length > 0) {
              for (let j = 0; j < this.filter.length; j++) {
                if (f.indexOf(this.filter[j]) == -1) {
                  return false;
                }
              }
              return true;
            } else {
              return f;
            }
          } else if (this.field == 'NoTags') {
            let f = '';
            f = item['Tags'] || '';

            if (this.filter && this.filter.length > 0) {
              for (let j = 0; j < this.filter.length; j++) {
                if (f.indexOf(this.filter[j]) != -1) {
                  return false;
                }
              }
              return true;
            } else {
              return f;
            }
          } else {
            let f = '';
            f = item[this.field] || '';

            const searchStr = f.toLowerCase();

            return searchStr.indexOf(this.filter.toLowerCase()) != -1;
          }
        });
        const data = this.getSortedData(this.filteredData.slice());
        // Grab the page's slice of data.
        const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
        return data.splice(startIndex, this._paginator.pageSize);
      })
    );
  }

  disconnect() {}

  /** Returns a sorted copy of the database data. */
  getSortedData(data: any[]): any[] {
    // const data = data;
    if (!this._sort.active || this._sort.direction == '') {
      return data;
    }

    return data.sort((a, b) => {
      let propertyA: number | string = '';
      let propertyB: number | string = '';

      switch (this._sort.active) {
        case 'LastName':
          [propertyA, propertyB] = [a.CalculatedName, b.CalculatedName];
          break;

        case 'FirstName':
          [propertyA, propertyB] = [a.CalculatedName, b.CalculatedName];
          break;

        case 'DateOfBirth':
          [propertyA, propertyB] = [
            moment(a['DateOfBirth']).toDate().getTime(),
            moment(b['DateOfBirth']).toDate().getTime(),
          ];
          break;

        case 'mobile':
          [propertyA, propertyB] = [a['mobiles.Number'], b['mobiles.Number']];
          break;
        case 'email':
          [propertyA, propertyB] = [a['emails.Email'], b['emails.Email']];
          break;
      }

      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

      return (valueA < valueB ? -1 : 1) * (this._sort.direction == 'asc' ? 1 : -1);
    });
  }
}
