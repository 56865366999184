import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Settings } from '../../../shared/types/settings';
import { AuthenticationService } from '../../authentication/shared/authentication.service';

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.css'],
})
export class ErrorModalComponent implements OnInit {
  closeModal = new EventEmitter();
  promoter: any;
  error: any = {
    type: '',
    link: '',
  };

  isModal = false;

  settings = Settings.global;

  displayContact = false;

  constructor(private authenticationService: AuthenticationService, @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data) {
      if (data.error) {
        this.isModal = true;
        this.error.content = data.error;
      }
      if (data.displayContact != null) {
        this.isModal = true;
        this.displayContact = data.displayContact;
      }

      if (data.code) {
        this.isModal = true;
        this.error.type = data.code;
      }

      if (data.type) {
        this.isModal = true;
        this.error.type = data.type;
      }
    }
  }

  ngOnInit() {
    this.promoter = JSON.parse(localStorage.getItem('promoter'));

    if (!this.promoter) {
      this.authenticationService.getPromoter().subscribe((res) => {
        if (res && res.data) {
          this.promoter = res.data;
        } else if (res) {
          this.promoter = res;
        }
      });
    }
    this.error.title = this.data.title || 'Error';
    this.error.link = this.data.link || null;
    this.error.content = this.data.content || null;
  }
  close() {
    this.closeModal.emit(true);
  }
  openLink() {
    if (this.error.link === 'reload') {
      window.location.reload();
    } else {
      window.open(this.error.link, '_blank');
    }
  }

  contactUs() {
    AuthenticationService.contactUSEvent.emit();
  }
}
