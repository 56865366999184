/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./clear-search-button.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/form-field";
import * as i4 from "@angular/material/icon";
import * as i5 from "@angular/common";
import * as i6 from "./clear-search-button.component";
var styles_ClearSearchButtonComponent = [i0.styles];
var RenderType_ClearSearchButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ClearSearchButtonComponent, data: {} });
export { RenderType_ClearSearchButtonComponent as RenderType_ClearSearchButtonComponent };
function View_ClearSearchButtonComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["style", "width: 50px; cursor: pointer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "mat-icon", [["class", "fas fa-times search-clear-icon mat-icon notranslate"], ["matSuffix", ""], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(2, 16384, null, 0, i3.MatSuffix, [], null, null), i1.ɵdid(3, 9158656, null, 0, i4.MatIcon, [i1.ElementRef, i4.MatIconRegistry, [8, null], [2, i4.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).inline; var currVal_1 = (((i1.ɵnov(_v, 3).color !== "primary") && (i1.ɵnov(_v, 3).color !== "accent")) && (i1.ɵnov(_v, 3).color !== "warn")); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_ClearSearchButtonComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["style", "width: 50px; cursor: pointer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "mat-icon", [["class", "fas fa-times search-clear-icon-dark mat-icon notranslate"], ["matSuffix", ""], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(2, 16384, null, 0, i3.MatSuffix, [], null, null), i1.ɵdid(3, 9158656, null, 0, i4.MatIcon, [i1.ElementRef, i4.MatIconRegistry, [8, null], [2, i4.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).inline; var currVal_1 = (((i1.ɵnov(_v, 3).color !== "primary") && (i1.ɵnov(_v, 3).color !== "accent")) && (i1.ɵnov(_v, 3).color !== "warn")); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_ClearSearchButtonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ClearSearchButtonComponent_1)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ClearSearchButtonComponent_2)), i1.ɵdid(3, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.iconType === "light"); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.iconType === "dark"); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_ClearSearchButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ipv-clear-search-button", [], null, null, null, View_ClearSearchButtonComponent_0, RenderType_ClearSearchButtonComponent)), i1.ɵdid(1, 49152, null, 0, i6.ClearSearchButtonComponent, [], null, null)], null, null); }
var ClearSearchButtonComponentNgFactory = i1.ɵccf("ipv-clear-search-button", i6.ClearSearchButtonComponent, View_ClearSearchButtonComponent_Host_0, { iconType: "iconType" }, {}, []);
export { ClearSearchButtonComponentNgFactory as ClearSearchButtonComponentNgFactory };
