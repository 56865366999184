<div class="treatment-block" *ngIf="options.length > 0">
  <div class="clearfix">
    <mat-card>
      <button class="info pull-right" (click)="openProductDetails()" mat-mini-fab color="primary"
              matTooltip="Click for more infomation about the product">
        <mat-icon>search</mat-icon>
      </button>

      <button class="info pull-right" (click)="simulateProduct()" mat-mini-fab color="accent"
              matTooltip="Simulate the product">
        <mat-icon>queue_play_next</mat-icon>
      </button>

      <!-- repeat start -->
      <div>
        <h3 class="sr-title rm-mt rm-mb">{{ option['Label.Marketing'] }}</h3>
        <h3 class="sr-subtitle">{{ option.SubType }}</h3>

        <mat-tab-group dynamicHeight="true">
          <mat-tab>
            <ng-template mat-tab-label>
              Minimum
            </ng-template>

            <ul>
              <li>
                <strong> Duration:</strong>
                <span class="val totalTreatmentPlanAmount">{{ option['Result.Instalments.Min'] }} {{
                  option['Result.Instalments.Label.Singular'] }}(s)</span>
              </li>

              <li>
                <strong> Total {{ 'PHRASE.treatment plan' | translate }} amount: </strong>
                <span class="val totalTreatmentPlanAmount">{{ amount | customCurrency }}</span>
              </li>

              <li>
                <strong>Lending Deposit:</strong>
                <span class="val lendingDeposit">{{
                  option['BasedOnMinDuration.SubTotals.Deposit'] | customCurrency
                  }}</span>
              </li>
              <li>
                <strong>Fees:</strong>
                <span class="val theAmountFinanced">{{
                  option['BasedOnMinDuration.SubTotals.Fees'] | customCurrency
                  }}</span>
              </li>

              <li>
                <strong>Interest Rate:</strong>
                <span class="val interestRate">{{ option['BasedOnMinDuration.InterestRate'] }}%</span>
              </li>
              <li>
                <strong> Monthly:</strong>
                <span class="val RepaymentsApproxMonthly">{{
                  option['BasedOnMinDuration.Repayments.Monthly'] | customCurrency
                  }}</span>
              </li>
              <li>
                <strong> Fortnightly:</strong>
                <span class="val RepaymentsApproxFortnightly">{{
                  option['BasedOnMinDuration.Repayments.Fortnightly'] | customCurrency
                  }}</span>
              </li>
              <li>
                <strong> Weekly:</strong>
                <span class="val RepaymentsApproxWeekly">{{
                  option['BasedOnMinDuration.Repayments.Weekly'] | customCurrency
                  }}</span>
              </li>
              <li>
                <strong> Daily:</strong>
                <span class="val RepaymentsApproxDaily">{{
                  option['BasedOnMinDuration.Repayments.Weekly'] / 7 | customCurrency
                  }}</span>
              </li>

              <li>
                <strong>Total payable by customer:</strong>
                <span class="val RepaymentsTotal">{{
                  option['BasedOnMinDuration.TotalPayable'] | customCurrency
                  }}</span>
              </li>
            </ul>
          </mat-tab>

          <mat-tab>
            <ng-template mat-tab-label>
              Maximum
            </ng-template>
            <ul>
              <li>
                <strong> Duration:</strong>
                <span class="val totalTreatmentPlanAmount">{{ option['Result.Instalments.Max'] }} {{
                  option['Result.Instalments.Label.Singular'] }}(s)</span>
              </li>
              <li>
                <strong> Total {{ 'PHRASE.treatment plan' | translate }} amount: </strong>
                <span class="val totalTreatmentPlanAmount">{{ amount | customCurrency }}</span>
              </li>

              <li>
                <strong> Lending Deposit:</strong>
                <span class="val lendingDeposit">{{
                  option['BasedOnMaxDuration.SubTotals.Deposit'] | customCurrency
                  }}</span>
              </li>
              <li>
                <strong>Fees:</strong>
                <span class="val theAmountFinanced">{{
                  option['BasedOnMaxDuration.SubTotals.Fees'] | customCurrency
                  }}</span>
              </li>

              <li>
                <strong>Interest Rate Maximum:</strong>
                <span class="val interestRate">{{ option['BasedOnMaxDuration.InterestRate'] }}%</span>
              </li>
              <li>
                <strong> Monthly:</strong>
                <span class="val RepaymentsApproxMonthly">{{
                  option['BasedOnMaxDuration.Repayments.Monthly'] | customCurrency
                  }}</span>
              </li>
              <li>
                <strong> Fortnightly:</strong>
                <span class="val RepaymentsApproxFortnightly">{{
                  option['BasedOnMaxDuration.Repayments.Fortnightly'] | customCurrency
                  }}</span>
              </li>
              <li>
                <strong> Weekly:</strong>
                <span class="val RepaymentsApproxWeekly">{{
                  option['BasedOnMaxDuration.Repayments.Weekly'] | customCurrency
                  }}</span>
              </li>
              <li>
                <strong> Daily:</strong>
                <span class="val RepaymentsApproxDaily">{{
                  option['BasedOnMaxDuration.Repayments.Weekly'] / 7 | customCurrency
                  }}</span>
              </li>

              <li>
                <strong>Total payable by customer:</strong>
                <span class="val RepaymentsTotal">{{
                  option['BasedOnMaxDuration.TotalPayable'] | customCurrency
                  }}</span>
              </li>
            </ul>
          </mat-tab>
        </mat-tab-group>
      </div>
      <!-- repeat end -->

      <hr/>

      <button class="info pull-left" [disabled]="index == 0" (click)="getPreviousOption()" mat-mini-fab color="primary"
              matTooltip="Previous Option">
        <mat-icon>chevron_left</mat-icon>
      </button>

      <button class="info pull-right" [disabled]="index == options.length - 1" (click)="getNextOption()" mat-mini-fab
              color="primary" matTooltip="Next  Option">
        <mat-icon>chevron_right</mat-icon>
      </button>
    </mat-card>
  </div>
</div>
