import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-editor-component-redirection',
  templateUrl: './editor-component-redirection.component.html',
  styleUrls: ['./editor-component-redirection.component.css'],
})
export class EditorComponentRedirectionComponent implements OnInit {
  root = '/merchant';

  constructor(private router: Router, private activeRoute: ActivatedRoute) {}

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      this.router.navigate([this.root, { outlets: { page: ['editor-component-create'] } }]);
    });
  }
}
