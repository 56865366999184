<mat-card class="rel supplier-card treatmentSaved clearfix mb" *ngIf="defaultMessage">
  <div class="card-header primary-gradient-img clearfix inModal rel">
    <div class="row clearfix">
      <button class="btn-close btn-clear mat-button white" *ngIf="isModal == true" (click)="closeClicked()">
        <mat-icon>clear</mat-icon>
      </button>
      <div class="row titleArea clearfix">
        <h2 class="summary-header rm-m">
          {{ defaultMessage['Label'] }}

          <span class="subLabel" *ngIf="defaultMessage['Purpose.Label']"> {{ defaultMessage['Purpose.Label'] }}</span>
        </h2>

        <div class="date-chip-area">
          <p class="small rm-m mr" *ngIf="defaultMessage.DateTimeCreated">
            <mat-icon class="fas fa-clock"></mat-icon>
            {{ defaultMessage.DateTimeCreated | TimeLabelPast }} ago
          </p>

          <p class="clearfix small rm-m">

            <span *ngIf="defaultMessage['IsPromoterTemplate']=='1' && defaultMessage['Purpose.Code']!=='PromSMS'"
              class="chip">
              {{"BRANDING.Brand Name" | translate}} Template
            </span>
            <span *ngIf="defaultMessage['IsPromoterTemplate']==='0'  && defaultMessage['Purpose.Code']!=='PromSMS'"
              class="chip chip-enable">
              {{ 'KEYWORD.practice' | translate }} Template
            </span>
            <span *ngIf="defaultMessage['Purpose.Code']==='PromSMS'" class="chip">
              Promoter Template
            </span>


          </p>

          <p class="clearfix small rm-m">
            <span class="chip chip-enabled" *ngIf="defaultMessage['Favourite'] == '1'"> Enabled </span>
            <span class="chip chip-disabled" *ngIf="defaultMessage['Favourite'] != '1'"> Disabled </span>
          </p>
        </div>
      </div>
    </div>
  </div>

  <mat-dialog-content>
    <div class="row clearfix full-width" *ngIf="isLoaded == true">
      <div *ngIf="
          defaultMessage['Purpose.Label'] != 'Action Log' &&
          defaultMessage['Purpose.Label'] != 'Contact' &&
          defaultMessage['Group.Code']
        ">
        <h3 class="sr-title text-left rm-mb full-width">Product Group Label</h3>

        <p class="rm-mt">{{      defaultMessage['Group.Code']  }}</p>
      </div>

      <div class="row clearfix">
        <h3 class="sr-title   text-left     full-width ">Content</h3>


        <message-input class="full-width  " [isModal]="false" [message]="defaultMessage['Content'] "
          [showAllCustomization]="true" [textLength]="0" [displayEmoji]="false" [displayMessageTemplate]="false"
          [contact]="contact" [displayPatient]="false" [displayContact]="false" [displayMerchant]="false" [card]="card"
          [merchant]="merchant" [displayPromoter]="false" [isMessageConverted]="false" [displayMarketing]="false"
          [displayExpend]="false" [displayDentalOffers]="false" [displayDocument]="false" [saveAsTemplate]="false"
          [isSMSPreview]="true" [isDesktopPreview]="true" [displaySaveAsTemplate]="false" [encode]="false"
          [isView]="true">
        </message-input>
      </div>

      <hr *ngIf="defaultMessage.tags && defaultMessage.tags.length>0 ">

      <div *ngIf="defaultMessage.tags && defaultMessage.tags.length>0 "
        class="row mt prospect-tags mb clearfix details ">


        <mat-chip-list>
          <mat-chip *ngFor="let tag of defaultMessage['tags']    | orderBy">
            {{tag}}
          </mat-chip>
        </mat-chip-list>


      </div>



    </div>
  </mat-dialog-content>

  <div class="full-width text-center mt" *ngIf="isModal == true">
    <hr />
    <button class="" (click)="closeClicked()" mat-raised-button color="accent">Close</button>
  </div>
</mat-card>
