import { Injectable } from '@angular/core';

import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class QuotationService {
  private settingsUrl: string = environment.nodeUrl + '/version';

  constructor(private http: HttpClient) {}

  decideCheck(ID, payload, checkType): Observable<any> {
    if (checkType == 'Capacity') {
      return this.http
        .put(environment.nodeUrl + '/capacityCheckContract/' + ID, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (checkType == 'Credit') {
      return this.http
        .put(environment.nodeUrl + '/creditCheckContract/' + ID, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (checkType == 'Identity') {
      return this.http
        .put(environment.nodeUrl + '/id-check/' + ID, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  performCheck(ID, checkType): Observable<any> {
    if (checkType == 'Capacity') {
      return this.http
        .post(environment.nodeUrl + '/capacityCheckContract/apply/' + ID, {})
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (checkType == 'Credit') {
      return this.http
        .post(environment.nodeUrl + '/creditCheckContract/apply/' + ID, {})
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (checkType == 'Identity') {
      return this.http
        .post(environment.nodeUrl + '/id-check/apply/' + ID, {})
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  changeContractStatus(ID, payload): Observable<any> {
    return this.http
      .put(environment.nodeUrl + '/contract/changeStatus/' + ID, payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getContractPossibleStatus(payload): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload != null) {
      for (let key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }

    return this.http
      .get(environment.nodeUrl + '/contract-status-change-possible', options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getConractDocument(ID, payload = {}): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (let key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    return this.http
      .get(environment.nodeUrl + '/contract/document/' + ID, options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }
}
