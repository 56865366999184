import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ActiveSessionViewComponent } from '../active-session-view/active-session-view.component';
import { ActiveSessionsComponent } from '../active-sessions/active-sessions.component';
import { AgentDetailsComponent } from '../agent-details/agent-details.component';
import { ConnectionHistoryViewComponent } from '../connection-history-view/connection-history-view.component';
import { ConnectionHistoryComponent } from '../connection-history/connection-history.component';
import { SessionChangeAvatar } from '../session-change-avatar/session-change-avatar.component';
import { SessionCompactMenuComponent } from '../session-compact-menu/session-compact-menu.component';
import { SessionCountdownModalComponent } from '../session-countdown-modal/session-countdown-modal.component';
import { SessionEditProfileComponent } from '../session-edit-profile/session-edit-profile.component';
import { SessionOverViewComponent } from '../session-over-view/session-over-view.component';
import { SessionViewPracticePictureComponent } from '../session-view-practice-picture/session-view-practice-picture.component';
import { SessionViewProfilePictureComponent } from '../session-view-profile-picture/session-view-profile-picture.component';

const routes: Routes = [
  {
    path: 'sessions-view',
    component: ActiveSessionsComponent,
  },
  {
    path: 'session-view-profile-picture',
    component: SessionViewProfilePictureComponent,
  },
  {
    path: 'session-view-practice-picture',
    component: SessionViewPracticePictureComponent,
  },

  {
    path: 'SessionOverViewComponent',
    component: SessionOverViewComponent,
  },
  {
    path: 'connection-history',
    component: ConnectionHistoryComponent,
  },
  {
    path: 'agent-details',
    component: AgentDetailsComponent,
  },
  {
    path: 'change-avatar',
    component: SessionChangeAvatar,
  },

  {
    path: 'session-compact-menu',
    component: SessionCompactMenuComponent,
  },

  {
    path: 'session-edit-profil',
    component: SessionEditProfileComponent,
  },

  {
    path: 'session-countdown-modal',
    component: SessionCountdownModalComponent,
  },
  {
    path: 'ActiveSessionViewComponent',
    component: ActiveSessionViewComponent,
  },
  {
    path: 'ConnectionHistoryViewComponent',
    component: ConnectionHistoryViewComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SessionRoutingModule {}
