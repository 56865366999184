<div class="row clearfix">
  <div class="colour-stripe"></div>
  <mat-card>
    <h2 class="sm-h2 white">{{ label }}</h2>
    <div class="pdf-window">
      <app-ep-document-view-short [title]="title" [docs]="docs" (donwload)="downloadFile($event)">
      </app-ep-document-view-short>

      <mat-form-field class="full-width" appearance="outline">
        <mat-label>Are you agreed?</mat-label>
        <input
          id="cc-name"
          [(ngModel)]="isAgreed"
          (ngModelChange)="isAgreedAgreement()"
          type="text"
          placeholder="Are you agreed?"
          matInput
        />
      </mat-form-field>
    </div>
  </mat-card>
</div>
<div class="row clearfix">
  <div class="pull-left">
    <h4 class="pdf-desc">
      <em
      >Review your <span>{{ title }}</span> by scrolling up and down</em
      >
    </h4>
  </div>
  <div class="pull-right">
    <button class="btn-small" mat-raised-button matTooltip="Email document">
      <mat-icon>email</mat-icon>
    </button>
  </div>
</div>
<hr/>
