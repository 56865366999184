import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-invitation-modification',
  templateUrl: './invitation-modification.component.html',
  styleUrls: ['./invitation-modification.component.css'],
})
export class InvitationModificationComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
