import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import * as moment from 'moment';

export class NoteStopwatch {
  duration: string;
  startTime: string;
  endTime: string;
}

@Component({
  selector: 'ipv-note-timer',
  templateUrl: './note-timer.component.html',
  styleUrls: ['./note-timer.component.css'],
})
export class NoteTimerComponent implements OnDestroy, OnChanges, OnInit {
  @Output() getTimerValue = new EventEmitter<NoteStopwatch>();
  @Input() showTimerButtons = true;
  @Input() disabled = false;
  @Input() startTime = '00:00:00';
  @Input() endTime = '00:00:00';
  @Input() duration = '00:00:00';
  @Input() startImmediately = false;
  @Input() forceEndTimeNow = false;

  timerRef;
  counter: number;

  minutes = '00';
  seconds = '00';
  milliseconds = '00';

  isTimerRunning = false;
  isTimerStopped = false;
  isTimerReset = true;
  isTimerStartedAtLeastOnce = false;

  constructor() {}

  ngOnInit() {
    if (this.startImmediately) {
      this.startTimer();
    }
  }

  ngOnChanges() {
    if (!this.endTime && this.disabled) {
      this.endTime = '-';
      this.startTime = '-';
      this.duration = '-';
    } else {
      if (!this.isTimerStopped && !this.disabled) {
        this.endTime = '00:00:00';
        if (!this.forceEndTimeNow) {
          this.startTime = '00:00:00';
        }
        this.duration = '00:00:00';
      } else {
        if (!this.forceEndTimeNow) {
          this.calculateDuration();
        }
      }
    }
  }

  startTimer() {
    this.isTimerStartedAtLeastOnce = true;
    this.isTimerRunning = true;
    this.isTimerReset = false;
    this.startTime = moment().format('HH:mm:ss');
    this.setTimerRef();
  }

  resumeTimer() {
    this.isTimerRunning = true;
    this.isTimerStopped = false;
    this.endTime = '00:00:00';
    this.setTimerRef();
  }

  stopTimer() {
    this.isTimerRunning = false;
    this.isTimerStopped = true;
    if (this.isTimerStartedAtLeastOnce) {
      this.endTime = moment().format('HH:mm:ss');
    }
    this.getTimerValue.emit({ duration: this.duration, startTime: this.startTime, endTime: this.endTime });
    clearInterval(this.timerRef);
  }

  resetTimer() {
    this.isTimerRunning = false;
    this.isTimerStopped = false;
    this.isTimerReset = true;
    this.counter = undefined;
    this.milliseconds = '00';
    this.seconds = '00';
    this.minutes = '00';
    this.startTime = '00:00:00';
    this.endTime = '00:00:00';
    this.duration = '00:00:00';
    clearInterval(this.timerRef);
  }

  setTimerRef() {
    const startTime = Date.now() - (this.counter || 0);
    this.timerRef = setInterval(() => {
      this.counter = Date.now() - startTime;
      this.milliseconds = Math.floor(Math.floor(this.counter % 1000) / 10).toFixed(0);
      this.minutes = Math.floor(this.counter / 60000).toFixed(0);
      this.seconds = Math.floor(Math.floor(this.counter % 60000) / 1000).toFixed(0);
      if (Number(this.minutes) < 10) {
        this.minutes = '0' + this.minutes;
      } else {
        this.minutes = '' + this.minutes;
      }
      if (Number(this.milliseconds) < 10) {
        this.milliseconds = '0' + this.milliseconds;
      } else {
        this.milliseconds = '' + this.milliseconds;
      }
      if (Number(this.seconds) < 10) {
        this.seconds = '0' + this.seconds;
      } else {
        this.seconds = '' + this.seconds;
      }
      if (this.forceEndTimeNow) {
        this.stopTimer();
      } else {
        this.duration = `${this.minutes}:${this.seconds}:${this.milliseconds}`;
      }
    });
  }

  calculateDuration() {
    const [formattedStartTimeHours, formattedStartTimeMinutes, formattedStartTimeSeconds] = this.startTime.split(':');
    const [formattedEndTimeHours, formattedEndTimeMinutes, formattedEndTimeSeconds] = this.endTime.split(':');

    const startTimeHours =
      formattedStartTimeHours[0] === '0'
        ? parseInt(formattedStartTimeHours.substring(1), 10)
        : parseInt(formattedStartTimeHours, 10);
    const startTimeMinutes =
      formattedStartTimeMinutes[0] === '0'
        ? parseInt(formattedStartTimeMinutes.substring(1), 10)
        : parseInt(formattedStartTimeMinutes, 10);
    const startTimeSeconds =
      formattedStartTimeSeconds[0] === '0'
        ? parseInt(formattedStartTimeSeconds.substring(1), 10)
        : parseInt(formattedStartTimeSeconds, 10);

    const endTimeHours =
      formattedEndTimeHours[0] === '0'
        ? parseInt(formattedEndTimeHours.substring(1), 10)
        : parseInt(formattedEndTimeHours, 10);
    const endTimeMinutes =
      formattedEndTimeMinutes[0] === '0'
        ? parseInt(formattedEndTimeMinutes.substring(1), 10)
        : parseInt(formattedEndTimeMinutes, 10);
    const endTimeSeconds =
      formattedEndTimeSeconds[0] === '0'
        ? parseInt(formattedEndTimeSeconds.substring(1), 10)
        : parseInt(formattedEndTimeSeconds, 10);

    const hours = endTimeHours - startTimeHours;
    const minutes = endTimeMinutes - startTimeMinutes;
    const seconds = endTimeSeconds - startTimeSeconds;

    const positiveHours = hours > -1 ? hours : hours * -1;
    const positiveMinutes = minutes > -1 ? minutes : hours * -1;
    const positiveSeconds = seconds > -1 ? seconds : seconds * -1;

    const finalHours = positiveHours > 10 ? positiveHours : '0' + positiveHours;
    const finalMinutes = positiveMinutes > 10 ? positiveMinutes : '0' + positiveMinutes;
    const finalSeconds = positiveSeconds > 10 ? positiveSeconds : '0' + positiveSeconds;

    this.duration = `${finalHours}:${finalMinutes}:${finalSeconds}`;
  }

  ngOnDestroy() {
    clearInterval(this.timerRef);
  }
}
