<div class="row clearfix">
  <div class="row flex flex-center justify-between" *ngIf="title">
    <h2 class="sr-title pull-left">{{ title }}</h2>
  </div>

  <div class="row clearfix">
    <mat-card class="card clearfix">
      <div class="card-header primary-gradient-img clearfix inModal">
        <div class="row clearfix">
          <div class="row titleArea clearfix">
            <app-ep-document-view-image
              class="full-width healthhistory-v"
              [defaultPicture]="
                'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/profile_placeholder.png)'
              "
              [link]="profileLink"
            >
            </app-ep-document-view-image>

            <h2 class="summary-header rm-m">
              {{ dentalXrayScanResult.calculatedName }}

              <span class="subLabel"
              >Created At:
                <strong> {{ dentalXrayScanResult.dateCreated }} </strong>
              </span>

              <span class="subLabel"
              >Upload Type:
                <strong> {{ dentalXrayScanResult.uploadType }} </strong>
              </span>
            </h2>
          </div>
        </div>
      </div>

      <div class="scan-image">
        <ng-container *ngIf="dentalXrayScanResult.uploadType === 'panoramic'; else bitewingImages">
          <img class="panoramic-image" [src]="fileLink.replace('id', dentalXrayScanResult.imageIDPanoramic)"/>
        </ng-container>

        <ng-template #bitewingImages>
          <div class="bitewing-images">
            <div class="left-image-container">
              <p style="text-align: center"><b> LEFT </b></p>

              <div class="left-image-border">
                <div
                  class="left-image-content"
                  [ngStyle]="{
                    'background-image': 'url(' + fileLink.replace('id', dentalXrayScanResult.imageIDLeft) + ')'
                  }"
                ></div>
              </div>
            </div>

            <div class="right-image-container">
              <p style="text-align: center"><b> RIGHT </b></p>
              <div class="right-image-border">
                <div
                  class="right-image-content"
                  [ngStyle]="{
                    'background-image': 'url(' + fileLink.replace('id', dentalXrayScanResult.imageIDRight) + ')'
                  }"
                ></div>
              </div>
            </div>
          </div>
        </ng-template>
      </div>

      <ng-container class="dental-arch-detections">
        <sr-dental-arch-detections [reportResults]="reportResults"></sr-dental-arch-detections>
      </ng-container>
    </mat-card>
  </div>

  <div class="row clearfix mt full-width">
    <button
      class="full-back btn-large pull-right"
      mat-raised-button
      color="primary"
      printTitle="AI Scan - {{ patient['CalculatedName'] }} - {{ dentalXrayScanResult.dateCreated }}"
      printSectionId="aiScanReport"
      styleSheetFile="/assets/styles/print-history.css, /assets/styles/application.css, /assets/styles/css/fontawesome.min.css"
      ngxPrint
    >
      <span style="display: flex; justify-content: center; align-items: center"
      ><mat-icon>print</mat-icon> &nbsp;Print page</span>
    </button>

    <!-- print section below -->

    <div
      class="health-hist-accord"
      id="aiScanReport"
      #aiScanReport
      *ngIf="
        (dentalXrayScanResult.imageIDPanoramic ||
          (dentalXrayScanResult.imageIDLeft && dentalXrayScanResult.imageIDRight)) &&
        patient
      "
    >
      <div class="section row clearfix mt">
        <div class="pull-left">
          <h1 class="summary-header rm-m sm-mb">{{ "KEYWORD.patient" | translate | titlecase }} Xray Scan Report</h1>
        </div>

        <div class="pull-right">
          <p class="bold rm-mt" *ngIf="merchant">{{ merchant['TradingAs'] }}</p>
          <p class="small rm-m" *ngIf="merchant">Phone: {{ merchant['phones.Number'] | customPhone }}</p>
          <p class="small rm-m" *ngIf="merchant">Address: {{ merchant['addresses.Calculated'] }}</p>
        </div>

        <div class="pull-right">
          <!--          <app-merchant-view-picture *ngIf="merchant && merchant['ID']" class="merchantView" [merchantID]="merchant['ID']" >-->
          <!--          </app-merchant-view-picture>-->
        </div>
      </div>

      <div class="row clearfix header-block">
        <div class="pull-left">
          <app-ep-document-view-image
            class="full-width patientIcon"
            [defaultPicture]="
              'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/profile_placeholder.png)'
            "
            [link]="profileLink"
          >
          </app-ep-document-view-image>
        </div>
        <div class="row clearfix pull-left">
          <h2 class="summary-header rm-m sm-mb">
            {{ this.dentalXrayScanResult.calculatedName }}
          </h2>

          <p class="small rm-m" *ngIf="patient['email']">
            Email: <strong>{{ patient['email'] }}</strong>
          </p>
          <p class="small rm-m" *ngIf="patient['mobile'] && patient['email'] != 'undefined'">
            mobile:
            <strong>{{ patient['mobile'] | customPhone }}</strong>
          </p>
          <p class="small rm-m" *ngIf="patient['phones.Number'] && patient['phones.Number'] != 'undefined'">
            Phone: <strong>{{ patient['phones.Number'] | customPhone }}</strong>
          </p>

          <p class="small rm-m" *ngIf="patient.dateOfBirth">
            Date of Birth: <strong>{{ patient.dateOfBirth | customDate }}</strong>
          </p>
        </div>
      </div>

      <div class="scan-image">
        <ng-container *ngIf="dentalXrayScanResult.uploadType === 'panoramic'; else bitewingImages">
          <img
            class="panoramic-image"
            [src]="fileLink.replace('id', dentalXrayScanResult.imageIDPanoramic)"
            style="width: 100%; max-width: 680px; padding: 0px; display: block; margin: 100px auto"
          />
        </ng-container>

        <ng-template #bitewingImages>
          <div
            class="bitewing-images"
            style="
              width: 100%;
              max-width: 620px;
              padding: 0px;
              display: flex;
              margin: 10px auto;
              justify-content: space-between;
              margin: 100px auto;
            "
          >
            <div class="left-image-container" style="width: 295px; height: 350px">
              <p style="text-align: center"><b> LEFT </b></p>

              <div
                class="left-image-border"
                style="width: 298px; height: 253px; margin-top: 30px; padding: 2px; border: 1px dotted black"
              >
                <div
                  class="left-image-content"
                  [ngStyle]="{
                    'background-image': 'url(' + fileLink.replace('id', dentalXrayScanResult.imageIDLeft) + ')'
                  }"
                  style="width: 295px; height: 250px; margin: 0px auto; margin-top: 1px; background-size: cover"
                ></div>
              </div>
            </div>

            <div class="right-image-container" style="width: 295px; height: 350px">
              <p style="text-align: center"><b> RIGHT </b></p>
              <div
                class="right-image-border"
                style="width: 298px; height: 253px; margin-top: 30px; padding: 2px; border: 1px dotted black"
              >
                <div
                  class="right-image-content"
                  [ngStyle]="{
                    'background-image': 'url(' + fileLink.replace('id', dentalXrayScanResult.imageIDRight) + ')'
                  }"
                  style="width: 295px; height: 250px; margin: 0px auto; margin-top: 1px; background-size: cover"
                ></div>
              </div>
            </div>
          </div>
        </ng-template>
      </div>

      <div class="section row clearfix">
        <div class="row clearfix">
          <div
            class="block half-width"
            *ngIf="
              reportResults &&
              reportResults.apical &&
              reportResults.apical.length > 0 &&
              reportResults.caries &&
              reportResults.caries.length > 0
            "
          >
            <ng-container *ngIf="reportResults && reportResults.apical && reportResults.apical.length > 0">
              <h4>Apical</h4>
              <div style="display: flex; flex-wrap: wrap">
                <div
                  class="print-tooth-item"
                  *ngFor="let apical of getUniqueSubTypes(reportResults.apical)"
                  style="display: flex; flex: 1 0 46%; margin-top: 10px; margin-bottom: 10px"
                >
                  <div style="width: 50px">
                    <!--                      <app-tooth-edit [tooth]="{hideNumber: false, toothNumber: apical.toothName, selected: false}"></app-tooth-edit >-->
                  </div>

                  <div>
                    <ul>
                      <li
                        *ngFor="let detections of apical.detections"
                        style="
                          font-weight: bold;
                          color: red;
                          font-family: Arial, Helvetica, sans-serif;
                          font-size: small;
                        "
                      >
                        {{ detections | uppercase }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="reportResults && reportResults.caries && reportResults.caries.length > 0">
              <h4>Caries</h4>
              <div style="display: flex; flex-wrap: wrap">
                <div
                  class="print-tooth-item"
                  *ngFor="let caries of getUniqueSubTypes(reportResults.caries)"
                  style="display: flex; flex: 1 0 46%; margin-top: 10px; margin-bottom: 10px"
                >
                  <div style="width: 50px">
                    <!--                      <app-tooth-edit [tooth]="{hideNumber: false, toothNumber: caries.toothName, selected: false}"></app-tooth-edit >-->
                  </div>

                  <div>
                    <ul>
                      <li
                        *ngFor="let detections of caries.detections"
                        style="
                          font-weight: bold;
                          color: red;
                          font-family: Arial, Helvetica, sans-serif;
                          font-size: small;
                        "
                      >
                        {{ detections | uppercase }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>

          <div class="block half-width">
            <ng-container *ngIf="reportResults && reportResults.restorations && reportResults.restorations.length > 0">
              <h4>Restorations</h4>

              <div style="display: flex; flex-wrap: wrap">
                <div
                  class="print-tooth-item"
                  *ngFor="let restorations of getUniqueSubTypes(reportResults.restorations)"
                  style="display: flex; flex: 1 0 46%; margin-top: 10px; margin-bottom: 10px"
                >
                  <div style="width: 50px">
                    <!--                      <app-tooth-edit [tooth]="{hideNumber: false, toothNumber: restorations.toothName, selected: false}"></app-tooth-edit> -->
                  </div>

                  <div>
                    <ul>
                      <li
                        *ngFor="let detections of restorations.detections"
                        style="
                          font-weight: bold;
                          color: red;
                          font-family: Arial, Helvetica, sans-serif;
                          font-size: small;
                        "
                      >
                        {{ detections | uppercase }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>

          <div class="pull-right power-by">
            <img
              [src]="'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/power_by_SR.png'"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
