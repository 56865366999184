import { Component, Input } from '@angular/core';
import { Merchant } from '../../../feature/merchant/shared/types/merchant.type';

@Component({
  selector: 'ipv-print-modal',
  templateUrl: './print-modal.component.html',
  styleUrls: ['./print-modal.component.css'],
})
export class PrintModalComponent {
  @Input() title = 'Print Preview';
  @Input() printTitle = '';
  @Input() printTitleAlignment: 'left' | 'right' | 'center' = 'center';
  @Input() merchant: Partial<Merchant>;
  @Input() loading = false;
}
