import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxGaugeModule } from 'ngx-gauge';
import { NgPipesModule } from 'ngx-pipes';
import { SharedModule } from '../../shared/shared.module';
import { OperatorModule } from '../operator/operator.module';
import { PerformanceDashboardPromoterToolsComponent } from './performance-dashboard-promoter-tools/performance-dashboard-promoter-tools.component';
import { PerformanceIntroModalComponent } from './performance-intro-modal/performance-intro-modal.component';
import { PerformanceMerchantComponent } from './performance-merchant/performance-merchant.component';
import { PerformancePromoterComponent } from './performance-promoter/performance-promoter.component';
import { PerformanceRoutingModule } from './shared/performance-routing.module';
import { PerformanceService } from './shared/performance.service';
import { SubAccountStatisticalReportComponent } from './sub-account-statistical-report/sub-account-statistical-report.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    PerformanceRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    CdkTableModule,
    OperatorModule,
    NgPipesModule,
    NgxGaugeModule,
  ],
  declarations: [
    PerformancePromoterComponent,
    PerformanceIntroModalComponent,
    PerformanceDashboardPromoterToolsComponent,
    PerformanceMerchantComponent,
    SubAccountStatisticalReportComponent,
  ],
  exports: [
    PerformancePromoterComponent,
    PerformanceIntroModalComponent,
    PerformanceDashboardPromoterToolsComponent,
    PerformanceMerchantComponent,
    SubAccountStatisticalReportComponent,
  ],
  providers: [PerformanceService],
})
export class PerformanceModule {}
