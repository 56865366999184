import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import moment from 'moment-timezone';

@Component({
  selector: 'app-time-zone-list',
  templateUrl: './time-zone-list.component.html',
  styleUrls: ['./time-zone-list.component.css'],
})
export class TimeZoneListComponent implements OnInit {
  @Input() timeZoneCode;
  @Input() viewOnly = false;
  @Input() disabled = false;

  @Input() label = 'Select your timezone';

  @Output() getResult = new EventEmitter();

  timeZones: string[] = [];
  filteredTimeZones: string[] = [];

  constructor() {
    this.timeZones = moment.tz.names();
    this.filteredTimeZones = this.timeZones;
  }

  ngOnInit() {
    if (!this.timeZoneCode) {
      this.timeZoneCode = moment.tz.guess();
    }
  }

  filterTimezone(value) {
    if (value && value.srcElement) {
      const text = value.srcElement.value;
      if (text) {
        this.filteredTimeZones = this.timeZones.filter((it) => it.toLowerCase().includes(text.toLowerCase()));
      } else {
        this.filteredTimeZones = this.timeZones;
      }
    }
  }

  getSelectedTimezone(value) {
    this.timeZoneCode = value;
    const diff = moment().tz(value).utcOffset();
    this.getResult.emit({
      code: this.timeZoneCode,
      value: diff / 60,
    });
  }

  getTZLabel(tz: string) {
    const diff = moment().tz(tz).utcOffset();
    const isNeg = diff < 0;
    const num = Math.abs(diff);
    let hr: any = Math.floor(num / 60);
    hr = ('0' + hr).slice(-2);
    let min: any = num % 60;
    min = ('0' + min).slice(-2);

    const utc = this.viewOnly ? '' : ` (UTC ${isNeg ? '-' : '+'}${hr}:${min})`;

    return `${tz}${utc}`;
  }
}
