import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ipv-wizard-step-card',
  templateUrl: './wizard-step-card.component.html',
  styleUrls: ['./wizard-step-card.component.css'],
})
export class WizardStepCardComponent implements OnInit {
  /**
   * Documentation example:
   * <example-url>http://localhost:4200/wizard-step-card?noBack=true</example-url>
   */

  @Input() title = 'Wizard Step';
  @Input() stepNumber: number | null = 0;
  @Input() instructions: string | null = 'Instructions go here';

  constructor() {}

  ngOnInit(): void {}
}
