import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { CustomerService } from '../../customer/shared/customer.service';
import { ProductService } from '../../product/shared/product.service';
import { ComplianceTaskComponent } from '../compliance-task/compliance-task.component';
import { ContractService } from '../shared/contract.service';
import { Settings } from '../../../shared/types/settings';

@Component({
  selector: 'app-contract-detail-overview',
  templateUrl: './contract-detail-overview.component.html',
  styleUrls: ['./contract-detail-overview.component.css'],
})
export class ContractDetailOverviewComponent implements OnInit {
  @Input()
  contractID;

  @Input()
  displayFunder = true;

  @Input()
  contractObject;

  displayedColumns = ['What', 'Task', 'Done', 'Date', 'Time', 'Operator'];
  simpleData = [
    {
      What: 'Invoice Details',
      Task: 'Performed',
      Done: true,
      Date: '03/02/2017',
      Time: '06:03:03',
      Operator: 'Master',
    },
    {
      What: 'Information Booklet',
      Task: 'Performed',
      Done: true,
      Date: '03/02/2017',
      Time: '06:03:11',
      Operator: 'Master',
    },
    {
      What: 'Privacy Notice & Credit Check',
      Task: 'Performed',
      Done: true,
      Date: '03/02/2017',
      Time: '06:03:04',
      Operator: 'Master',
    },
    {
      What: 'Personal Needs Requirement',
      Task: 'Performed',
      Done: true,
      Date: '03/02/2017',
      Time: '06:03:04',
      Operator: 'Master',
    },
    {
      What: 'Electronic Delivery',
      Task: 'Performed',
      Done: true,
      Date: '03/02/2017',
      Time: '06:03:04',
      Operator: 'Master',
    },
    {
      What: 'Capacity Check',
      Task: 'Performed',
      Done: true,
      Date: '03/02/2017',
      Time: '06:03:04',
      Operator: 'Master',
    },
  ];

  contract;
  selectedFrequency;
  selectedAccountManager;

  frequency = ['Monthly', 'Fortnightly', 'Weekly'];
  patientAddress;
  isContractView = false;
  isPromoterOrAdmin = false;
  estimatedObject;
  frequencyCode;
  sessionType = 'guest';

  context = Settings.global['context']

  constructor(
    private activeRouter: ActivatedRoute,
    private customerService: CustomerService,
    private contractService: ContractService,
    private authenticationService: AuthenticationService,
    private productService: ProductService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.activeRouter.params.subscribe((params) => {
      if (params['contractID']) {
        this.contractID = params['contractID'];
      }

      this.authenticationService.getSessionType().subscribe((sessionType) => {
        if (sessionType) {
          this.sessionType = sessionType;
        }

        this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
          this.isPromoterOrAdmin = res;

          if (this.contractObject && typeof this.contractObject == 'object') {
            this.contract = this.contractObject;
            this.setup();
          } else if (this.contractID) {
            this.contractService
              .fetchContractDetails$(this.contractID, null, this.isPromoterOrAdmin)
              .subscribe((res) => {
                if (res) {
                  this.contract = res;
                  this.setup();
                }
              });
          }

          this.customerService
            .getCustomerDetails(this.contract['Customer_key'], {fields:'addresses.Calculated'}, this.sessionType)
            .subscribe((res) => {
              if (res) {
                this.patientAddress = res['addresses.Calculated'];
              }
            });
        });
      });
    });
  }

  setup() {
    this.selectedFrequency = this.contract['RepaymentFrequency.Adverb'];
    this.selectedAccountManager = this.contract['AccountManagerName'];
    const status = this.contract['Status.Code'];

    if (status == 'ACTIVE' || status == 'COMPLETE' || status == 'APPROVED' || status == 'SUBMITTED') {
      this.isContractView = true;
    } else {
      this.isContractView = false;
      this.getCalculator(this.contract['RepaymentFrequency.Code'], this.contract['LoanInstalments']);
    }
  }

  getTotalFee() {
    let result = 0;

    if (this.contract) {
      if (this.contract['Recourse_Release_MSF'] && Number(this.contract['Recourse_Release_MSF']) > 0) {
        result = result + Number(this.contract['Recourse_Release_MSF']);
      }
      if (this.contract['Merchant_Service_Fee'] && Number(this.contract['Merchant_Service_Fee']) > 0) {
        result = result + Number(this.contract['Merchant_Service_Fee']);
      }
      if (this.contract['Merchant_Membership_Fee'] && Number(this.contract['Merchant_Membership_Fee']) > 0) {
        result = result + Number(this.contract['Merchant_Membership_Fee']);
      }
      if (this.contract['Merchant_Risk_Fee'] && Number(this.contract['Merchant_Risk_Fee']) > 0) {
        result = result + Number(this.contract['Merchant_Risk_Fee']);
      }
      if (this.contract['Credit_Charges_Merchant'] && Number(this.contract['Credit_Charges_Merchant']) > 0) {
        result = result + Number(this.contract['Credit_Charges_Merchant']);
      }
      if (this.contract['CustomerFeesAbsorbed'] && Number(this.contract['RecourseCustomerFeesAbsorbed_Release_MSF']) > 0) {
        result = result + Number(this.contract['CustomerFeesAbsorbed']);
      }
      if (this.contract['Insurance_Contributions'] && Number(this.contract['Insurance_Contributions']) > 0) {
        result = result + Number(this.contract['Insurance_Contributions']);
      }
      if (this.contract['RefundsForUndrawn'] && Number(this.contract['RefundsForUndrawn']) > 0) {
        result = result + Number(this.contract['RefundsForUndrawn']);
      }
      if (this.contract['Borrower_Risk_Fee'] && Number(this.contract['Borrower_Risk_Fee']) > 0) {
        result = result + Number(this.contract['Borrower_Risk_Fee']);
      }
      if (this.contract['Merchant_HoldBack_Fee'] && Number(this.contract['Merchant_HoldBack_Fee']) > 0) {
        result = result + Number(this.contract['Merchant_HoldBack_Fee']);
      }
    }

    return result;
  }
  toNumber(value) {
    return Number(value);
  }

  changeFrequency(e) {
    if (e == 'Monthly') {
      this.frequencyCode = '4';
      this.getCalculator(this.frequencyCode);
    } else if (e == 'Fortnightly') {
      this.frequencyCode = '2';
      this.getCalculator(this.frequencyCode);
    } else if (e == 'Weekly') {
      this.frequencyCode = '1';
      this.getCalculator(this.frequencyCode);
    }
  }

  getCalculator(code, duration = null) {
    const payload = {
      amount: this.contract['Amount.FinancedBeforeDeposit'],
      duration,
      frequency: code,
      promoCode: this.contract['PromoCode'],
      interestRate: this.contract['InterestRate'],
      patientContribution: this.contract['Amount.Contribution'] || null,
    };

    this.productService.getCalculatorCustomer(this.contract['Product_key'], payload).subscribe((res) => {
      this.estimatedObject = res['estimate'];
    });
  }

  openComplianceTask() {
    const ref = this.dialog.open(ComplianceTaskComponent, {
      data: this.contract['ID'],
      width: '1050px',
    });

    ref.componentInstance.closeEvent.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }
}
