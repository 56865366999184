<div class="row clearfix defineMessage">
  <!-- blue heading -->
  <div class="card-header primary-gradient-img clearfix">
    <div class="row clearfix">
      <!-- Modal dismiss button -->
      <button class="btn-close btn-clear pull-right mat-button" (click)="closeEvent()">
        <mat-icon>clear</mat-icon>
      </button>
      <!-- merchant calculated name -->
      <div class="row titleArea clearfix">
        <mat-icon class="fas fa-tag pull-left"></mat-icon>
        <h2 class="message-title summary-header rm-m">
          <span *ngIf="!tagID">Create {{ nature }}</span>
          <span *ngIf="tagID">Edit {{ nature }}</span>
          <span class="subLabel">Mark your record by attach {{ nature }} s to it</span>
        </h2>
      </div>
    </div>
  </div>
  <!-- end blue heading -->

  <div class="create-well">
    <form #msgForm="ngForm">
      <!--  mat dialog scrolling area -->
      <mat-dialog-content>
        <p>Insert a label and a description for your {{ nature }}, the label must be unique</p>
        <div class="row clearfix mt">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>{{ nature }} Label</mat-label>
            <input
              [(ngModel)]="tag.label"
              (change)="verifyUnique()"
              matInput
              placeholder="{{ nature }} Label"
              name="label"
              required
            />

            <mat-icon
              class="error-icon-signup"
              *ngIf="isUnique == false && tag && tag.label && checkIsDone == true"
              matSuffix
              matTooltip="the  Tag Label must be unique"
            >error
            </mat-icon>

            <mat-icon
              class="check-icon-signup"
              *ngIf="isUnique != false && tag && tag.label && checkIsDone == true"
              matSuffix
              matTooltip="this Tag Label  is Valid"
            >check
            </mat-icon>
          </mat-form-field>
        </div>

        <div class="row clearfix mt">
          <mat-form-field class="full-width messageContent" appearance="outline">
            <mat-label>Enter a description of your {{ nature }} s</mat-label>
            <textarea
              class="dentMsg increase-input-field"
              [(ngModel)]="tag.description"
              name="dentMsg"
              matInput
              placeholder="Enter a description of your {{ nature }} s"
              cols="30"
              rows="50"
            ></textarea>
          </mat-form-field>
        </div>
      </mat-dialog-content>
      <mat-dialog-actions>
        <div class="row clearfix text-center">
          <button
            [disabled]="!msgForm.form.valid || isUnique != true || checkIsDone != true"
            (click)="createTag()"
            mat-raised-button
            color="accent"
          >
            Save
            <mat-icon>check</mat-icon>
          </button>
        </div>
      </mat-dialog-actions>
    </form>
  </div>
</div>
