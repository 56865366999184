import { Component, Input, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { UtilsService } from '../../../shared/services/utils.service';

@Component({
  selector: 'app-document-list-mini',
  templateUrl: './document-list-mini.component.html',
  styleUrls: ['./document-list-mini.component.css'],
})
export class DocumentListMiniComponent implements OnInit {
  @Input()
  cardID;

  @Input()
  title = 'Documents';

  isModal = false;

  documents;

  isPromoterOrAdmin = false;
  sessionType = 'guest';

  constructor(private utilService: UtilsService, private authenticationService: AuthenticationService) {}

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((sessionType) => {
      this.sessionType = sessionType;

      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isPromoterOrAdmin = res;

        if (this.cardID) {
          const payload = {
            cardID: this.cardID,
            withoutProfileImage: true,
            withoutTreatmentDocument: true,
            fields: 'DateAdded,DateTimeCreated,Description,Extension,ID,Label,URL.Complete,URL.Relative,URL.Base',
          };

          this.utilService.getDocumentList(payload, this.sessionType).subscribe((res) => {
            if (res) {
              this.documents = res;
            }
          });
        }
      });
    });
  }
}
