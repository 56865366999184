<div *ngIf="settlements && settlements.length > 0">
  <div class="full-width clearfix row">
    <div
      class="clearfix settleWell"
      *ngIf="settlement"
      [class.cancelled]="settlement['Status.Code'] == 'WDRN'"
      [class.declined]="settlement['Status.Code'] == 'DECL'"
      [class.requested]="settlement['Status.Code'] == 'REQU'"
      [class.approved]="settlement['Status.Code'] == 'APPR'"
      [class.pending]="settlement['Status.Code'] == 'PEND'"
      [class.awaiting]="settlement['Status.Code'] == 'AWTR'"
    >
      <mat-accordion>
        <mat-expansion-panel class="mat-elevation-z0" (opened)="isExpanded = true" (closed)="isExpanded = false">
          <mat-expansion-panel-header>
            <div class="row full-width flex mr">
              <div class="full-width flex-center">
                <div class="status mr">
                  <mat-icon
                    class="fas fa-money-bill-wave"
                    *ngIf="settlement['Status.Code'] != 'APPR' && settlement['Status.Code'] != 'DECL'"
                  ></mat-icon>
                  <mat-icon class="fas fa-check" *ngIf="settlement['Status.Code'] == 'APPR'"></mat-icon>
                  <mat-icon class="fas fa-times" *ngIf="settlement['Status.Code'] == 'DECL'"></mat-icon>
                </div>
                <h4 class="sm-h4">
                  <strong>{{
                    settlements | objectPropertyValue: 'Settlement.Amount' | toNumberArray | sum | customCurrency
                    }}</strong>
                  requested on {{ settlement['Settlement.Date'] | customDate }}
                </h4>
              </div>

              <span class="chip">{{ settlements.length }}</span>
            </div>
          </mat-expansion-panel-header>
          <mat-dialog-content>
            <div
              class="row clearfix control-action-btn"
              *ngIf="
                settlement &&
                settlement['Status.Code'] != 'APPR' &&
                settlement['Status.Code'] != 'DECL' &&
                settlement['Status.Code'] != 'WDRN'
              "
              @ngIfAnimation
            >
              <div *ngIf="isExpanded == true">
                <button
                  class="actionMenuToggle group"
                  @ngIfAnimation
                  [matMenuTriggerFor]="menu"
                  mat-mini-fab
                  color="accent"
                  matTooltip="Group actions"
                >
                  <mat-icon class="fas fa-cog"></mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <button
                    *ngIf="settlement && settlement['Status.Code'] == 'PEND'"
                    (click)="requestSettlementGroup()"
                    mat-menu-item
                    color="warn"
                  >
                    <mat-icon>description</mat-icon>
                    <span>Request Settlement</span>
                  </button>
                  <button
                    *ngIf="(settlement && settlement['Status.Code'] == 'PEND') || settlement['Status.Code'] == 'REQU'"
                    (click)="withdrawSettlementGroup()"
                    mat-menu-item
                    color="warn"
                  >
                    <mat-icon>block</mat-icon>
                    <span>Withdraw settlement group </span>
                  </button>
                  <!-- <button mat-menu-item color="primary"
                  *ngIf="settlement &&   isPromoterOrAdmin == true  && settlement['Status.Code']=='REQU'"
                  (click)="approveSettlementGroup()">
                  <mat-icon>thumb_up_alt</mat-icon>
                  <span>Approve settlement group </span>
                </button>
                <button mat-menu-item color="accent"
                  *ngIf="settlement && isPromoterOrAdmin == true  && settlement['Status.Code']=='REQU'"
                  (click)="declineSettlement()">
                  <mat-icon>thumb_down_alt</mat-icon>
                  <span>Decline settlement group </span>
                </button> -->
                  <button
                    *ngIf="
                      false &&
                      settlement &&
                      (settlement['Status.Code'] == 'REQU' || settlement['Status.Code'] == 'PEND')
                    "
                    (click)="voidSettlementGroup()"
                    mat-menu-item
                  >
                    <mat-icon>delete</mat-icon>
                    <span>Archive settlement group </span>
                  </button>
                  <!-- <button mat-menu-item *ngIf=" settlement['Status.Code']!='PEND'  " (click)="viewDocument()">
                  <mat-icon>pageview</mat-icon>
                  <span>View document</span>
                </button> -->
                </mat-menu>
              </div>
            </div>

            <div class="clearfix full-width row" *ngFor="let s of settlements">
              <div class="status-row row clearfix grey pointer">
                <div class="row clearfix flex">
                  <div class="full-width">
                    <div class="row clearfix flex" (click)="viewSettlement(s)">
                      <p class="small rm-mt full-width" *ngIf="s['SettleTo.Card_key'] && s['SettleTo.Account_key']">
                        Third party payment: <strong> {{ s['Settlement.Amount'] | customCurrency }}</strong>
                      </p>

                      <p
                        class="small rm-mt full-width"
                        *ngIf="s['SettleTo.Card_key'] == '' || s['SettleTo.Account_key'] == ''"
                      >
                        Invoice Reference:
                        <strong>{{ settlement['Invoice.Reference'] || '...' }}</strong>
                      </p>

                      <p class="small rm-mt full-width">
                        Request status
                        <span class="chip"> {{ settlement['Status.Label'] || '...' }}</span>
                      </p>
                      <p class="small rm-mt full-width">
                        Payment status
                        <span
                          class="chip"
                          *ngFor="let pp of splitStatus(s['PaymentStatus.Label'])"
                          [class.chipPaymentPaid]="pp == 'Paid'"
                          [class.chipPaymentDeclined]="pp == 'Blocked'"
                          [class.chipPaymentPending]="pp == 'Pending'"
                          [class.chipPaymentRequested]="pp == 'Suspended'"
                        >{{ pp || '...' }}</span
                        >
                      </p>
                    </div>

                    <div class="row clearfix flex" *ngIf="s['SettleTo.Card_key']" (click)="viewSettlement(s)">
                      <p class="small rm-mt full-width" *ngIf="s['SettleTo.Name']">
                        Settled To:
                        <strong>{{ s['SettleTo.Name'] || '...' }}</strong>
                      </p>
                      <p class="small rm-mt full-width" *ngIf="s['SettleTo.Account.AccountNumber']">Bank Account</p>
                      <p class="small rm-mt full-width" *ngIf="s['SettleTo.Account.CardNumber']">Credit Card</p>
                      <p class="small rm-mt full-width" *ngIf="s['SettleTo.Account.AccountNumber']">
                        Account Number:
                        <strong>{{ s['SettleTo.Account.AccountNumber'] || '...' }}</strong>
                      </p>
                      <p class="small rm-mt full-width" *ngIf="s['SettleTo.Account.CardNumber']">
                        Card Number:
                        <strong>{{ s['SettleTo.Account.CardNumber'] || '...' }}</strong>
                      </p>
                    </div>
                    <div
                      class="row clearfix flex"
                      *ngIf="s['Insurance.Contribution'] && s['Insurance.Contribution'] !== '0'"
                      (click)="viewSettlement(s)"
                    >
                      <p class="small rm-mt full-width" *ngIf="s['Insurance.Contribution']">
                        Insurance Contribution:
                        <strong>{{ s['Insurance.Contribution'] | customCurrency }}</strong>
                      </p>
                      <p class="small rm-mt full-width" *ngIf="s['Insurance.Provider']">
                        Insurance Provider:
                        <strong>{{ s['Insurance.Provider'] || '...' }}</strong>
                      </p>
                      <p class="small rm-mt full-width" *ngIf="s['Insurance.Reference']">
                        Insurance Reference:
                        <strong>{{ s['Insurance.Reference'] || '...' }}</strong>
                      </p>
                    </div>

                    <div
                      class="row clearfix flex"
                      *ngIf="(s['Requested.By'] || s['Approved.By_Key']) && isPromoterOrAdmin == true"
                    >
                      <p class="small rm-mt full-width" *ngIf="s['Requested.By']">
                        Requested By:
                        <strong>{{ settleUtils.getName(s['Requested.By_Name']) }}</strong>
                      </p>
                      <p class="small rm-mt full-width" *ngIf="s['Approved.By_Key']">
                        Approved By:
                        <strong>{{ settleUtils.getName(s['Approved.By_Name']) }}</strong>
                      </p>
                    </div>
                  </div>

                  <button [matMenuTriggerFor]="menu" mat-icon-button>
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                    <!-- <button mat-menu-item *ngIf="s['SettleTo.Card_key'] && s['SettleTo.TableName']=='Supplier'"
                      (click)="viewBeneficiary(s)">
                      <mat-icon>search</mat-icon>
                      <span>View Third Party </span>
                    </button>
                    <button mat-menu-item *ngIf="isPromoterOrAdmin == true  && s['SettleTo.Account_key']" (click)="viewBankAccount(s)">
                      <mat-icon>search</mat-icon>
                      <span>View Third Party Bank Account</span>
                    </button> -->

                    <button
                      *ngIf="s['Status.Code'] != 'PEND' && s['DrawDownGroupID'] != ''"
                      (click)="viewDocument(s)"
                      mat-menu-item
                    >
                      <mat-icon>pageview</mat-icon>
                      <span>View document</span>
                    </button>
                    <button *ngIf="isPromoterOrAdmin == true && s['ID']" (click)="viewSettlementLogs(s)" mat-menu-item>
                      <mat-icon class="fa fa-list-alt"></mat-icon>
                      <span>View Settlement logs</span>
                    </button>
                  </mat-menu>
                </div>
              </div>
            </div>
          </mat-dialog-content>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</div>
