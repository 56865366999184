<div *ngIf="isAdminOrPromoter == true">
  <div class="row clearfix" *ngIf="hideMerchantSelect == false">
    <mat-form-field class="margin-btm half-width" appearance="outline">
      <mat-label>Please Select a Merchant</mat-label>
      <mat-select class="  " [(ngModel)]="merchantID" name="MerchantName4" placeholder="Please Select a Merchant">
        <mat-option [value]="'none'">
          Please Select Merchant
        </mat-option>
        <mat-option [value]="null">
          All
        </mat-option>
        <mat-option *ngFor="let m of merchants | orderBy: ['TradingAs', 'CalculatedName']" [value]="m['ID']">
          <span
            class="list-label pull-right"
            *ngIf="m['Status'] && m['Status'] == 'Active'"
            [style.background]="'#00d07a'"
          >
            {{ m['Status'] }}
          </span>

          <span
            class="list-label pull-right"
            *ngIf="m['Status'] && m['Status'] != 'Active'"
            [style.background]="'#ff5722'"
          >
            {{ m['Status'] }}
          </span>
          {{ m['TradingAs'] || m['CalculatedName'] }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <button
      class="pull-right merchBtn"
      *ngIf="merchantID && merchantID != 'none'"
      (click)="viewMerchant()"
      mat-raised-button
      color="primary"
    >
      <mat-icon>person</mat-icon>
      <span class="mobhide">View Merchant</span>
    </button>
  </div>
  <div class="row clearfix">
    <app-medical-history-list-global
      *ngIf="merchantID != 'none'"
      [merchantID]="merchantID"
      (hideMerchantSelection)="hideDetails($event)"
    ></app-medical-history-list-global>
  </div>
  <div class="row clearfix">
    <div class="pleaseChoose" *ngIf="merchantID == 'none'" @ngIfAnimation>
      <p>
        <strong>No Merchant Selected</strong>
      </p>
      <img class="character_warning" />
      <p>Choose Desired Merchant</p>
    </div>
  </div>
</div>

<div class="row full-width clearfix" *ngIf="isAdminOrPromoter == false">
  <app-medical-history-list></app-medical-history-list>
</div>
