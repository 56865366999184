<div class="clearfix full-width row">
  <form #formAddress="ngForm" class="address-form">
    <div class="row clearfix flex full-width " *ngIf="isMiniView!=true">

      <div class="flex flexwrap row full-width ">

        <div class="flex full-width">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Unit Number</mat-label>
            <input
              [(ngModel)]="unitNumber"
              [disabled]="isDisabled"
              (change)="changeUnitNumber($event.currentTarget.value)"
              matInput
              type="text"
              placeholder="Unit Number"
              name="unitNumber"
            />
          </mat-form-field>
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Street Number</mat-label>
            <input
              [(ngModel)]="streetNumber"
              [required]="requiredLabel"
              [disabled]="isDisabled"
              (change)="changeStreetNumber($event.currentTarget.value)"
              matInput
              type="text"
              placeholder="Street Number"
              name="streetNumber"
            />

            <mat-error *ngIf="!streetNumber">Street number is required</mat-error>
          </mat-form-field>
        </div>
        <mat-form-field class="full-width streetName" appearance="outline">
          <mat-label>Street Name</mat-label>
          <input
            [(ngModel)]="streetName"
            [required]="requiredLabel"
            [disabled]="isDisabled"
            (change)="changeStreetName($event.currentTarget.value)"
            matInput
            type="text"
            placeholder="Street Name"
            name="streetName"
          />

          <mat-error *ngIf="!streetName">Street name is required</mat-error>
        </mat-form-field>
        <mat-form-field
          class="full-width animate"
          [ngClass]="{ 'mat-form-field-invalid ng-invalid mat-error': invalidStreetType == true }"
          appearance="outline"
        >
          <mat-label>Street Type</mat-label>
          <input
            [(ngModel)]="streetTypeObjectLabel"
            [matAutocomplete]="auto"
            [required]="requiredLabel"
            [disabled]="isDisabled"
            (ngModelChange)="filterStreetType($event)"
            matIput
            name="streetType"
            type="text"
            placeholder="Street Type"
            matInput
          />
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="getSelectedStreetType($event.option.value)">
            <mat-option
              *ngFor="let streetType of filteredStreetTypes"
              [value]="streetType"
              [hidden]="hideStreetTypeOption == true"
            >
              {{ streetType }}
            </mat-option>
          </mat-autocomplete>

          <mat-hint *ngIf="invalidStreetType == true && streetTypeObjectLabel" [style.color]="'#f44336'"
          >Invalid street type
          </mat-hint>

          <mat-error *ngIf="!streetTypeObjectLabel">Street type is required</mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="row clearfix flex full-width">

      <div class="flex flexwrap row full-width " [ngClass]="isMiniView!=true ? '' : ''">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>City/Suburb</mat-label>
          <input
            class="full-width"
            [(ngModel)]="suburb"
            [matAutocomplete]="autoSuburb"
            [required]="requiredLabel"
            [disabled]="isDisabled"
            (ngModelChange)="suburbChange($event)"
            (blur)="runCheck()"
            matInput
            name="suburb"
            type="text"
            placeholder="City/Suburb"
            matInput
          />
          <mat-autocomplete
            #autoSuburb="matAutocomplete"
            [displayWith]="displaySuburbFn"
            (optionSelected)="chooseSuburb($event.option.value)"
          >
            <mat-option *ngFor="let s of suburbs | orderBy: ['Locality', 'State', 'PostCode']" [value]="s">
              {{ s.Locality }}, {{ s.State }}, {{ s.Postcode }}
            </mat-option>
          </mat-autocomplete>

          <mat-error *ngIf="!suburb">Suburb is required</mat-error>
        </mat-form-field>
        <mat-form-field
          class="full-width"
          [ngClass]="{ 'mat-form-field-invalid': suburb != null && isSelectedSuburb == true }"
          appearance="outline"
        >
          <mat-label>State</mat-label>
          <input
            [(ngModel)]="state"
            [disabled]="true"
            [required]="requiredLabel"
            matInput
            type="text"
            placeholder="State"
            name="State"
          />
        </mat-form-field>
        <mat-form-field
          class="full-width"
          [class.mat-form-field-invalid]="suburb != null && isSelectedSuburb == true"
          appearance="outline"
        >
          <mat-label>Postcode</mat-label>
          <input
            [(ngModel)]="postCode"
            [disabled]="true"
            [required]="requiredLabel"
            matInput
            type="number"
            placeholder="Postcode"
            name="Postcode"
          />
        </mat-form-field>
      </div>
    </div>
  </form>
</div>

<div class="clearfix full-width row" *ngIf="isMiniView != true">
  <p class="errorMsg" *ngIf="suburb != null && isSelectedSuburb == true">
    You need to enter and select a suburb from the list to populate state and postcode
  </p>
</div>
<div class="row clearfix full-width mt" *ngIf="postCode && displayMap == true && isMiniView != true">
  <app-map-view
    class="mt mb"
    [icon]="iconAddres"
    [forceGettingAddress]="true"
    [canClose]="canClose"
    [unitNumber]="unitNumber"
    [canClick]="false"
    [streetType]="streetType"
    [streetName]="streetName"
    [latitude]="latitude"
    [longitude]="longitude"
    [streetNumber]="streetNumber"
    [zoom]="17"
    [postCode]="postCode"
    [state]="state"
    [suburb]="suburb"
    [moreInfo]=""
    (getSelected)="getSelectedEvent($event)"
    (notFoundEvent)="notFoundEvent($event)"
  ></app-map-view>
</div>
