import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
@Component({
  selector: 'app-confirm-dialog-message',
  templateUrl: './confirm-dialog-message.component.html',
  styleUrls: ['./confirm-dialog-message.component.css'],
})
export class ConfirmDialogMessageComponent implements OnInit {
  @Input()
  icon = 'info';

  @Input()
  title = 'Are you sure?';

  @Input()
  headerBGColor;

  @Input()
  description = '<p>You are about to remove this item.</p> ';

  @Input()
  text;
  @Input()
  textLabel = 'Title';

  @Input()
  message;

  @Input()
  checkboxTitle = '';

  @Input()
  checkboxValue = true;

  @Input()
  checkboxValueSecond = true;

  @Input()
  date = new Date();

  @Input()
  dateTime = new Date();

  @Input()
  dateSecond = new Date();

  @Input()
  dateTimeSecond = new Date();

  @Input()
  yesLabel = 'Yes';

  @Input()
  noLabel = 'No';

  @Input()
  displayNo = true;

  @Input()
  messageLabel = 'Please enter your  message';

  @Input()
  messageTitle = '';

  @Input()
  dateLabelSecond = 'Date Label';

  @Input()
  dateTimeLabelSecond = 'Date Time Label';

  @Input()
  dateLabel = 'Date Label';

  @Input()
  dateTimeLabel = 'Date Time Label';

  @Input()
  policyName = 'Policy';

  @Input()
  policyLabel = 'I agree to ';

  @Input()
  isPolicy = false;

  @Input()
  policyValue = false;
  @Input()
  isPolicyRequired = false;

  @Input()
  isCheckboxRequired = false;

  @Input()
  isCheckboxSecondRequired = false;

  @Input()
  checkboxLabel = 'Please enter your  check value';

  @Input()
  checkboxLabelSecond = 'Please enter your  check value';

  @Input()
  minDate = 'today';

  @Input()
  minDateSecond = 'today';

  @Input()
  isCheckbox = false;

  @Input()
  isCheckboxSecond = false;

  @Input()
  isTextArea = true;

  @Input()
  isTextInput = false;

  @Input()
  isDateInput = false;

  @Input()
  isDateRequired = false;

  @Input()
  isDateTimeInput = false;

  @Input()
  isDateTimeRequired = false;

  @Input()
  isDateSecondInput = false;

  @Input()
  isDateSecondRequired = false;

  @Input()
  isDateTimeSecondInput = false;

  @Input()
  isDateTimeSecondRequired = false;

  @Input()
  isTextAreaRequired = true;

  @Input()
  isTextInputRequired = false;
  @Input()
  optionList;

  @Input()
  optionLabel;

  @Input()
  isOption = false;

  @Input()
  isOptionRequired = false;

  @Input()
  optionCode;

  @Input()
  optionViewValue;

  @Input()
  optionValue;

  @Input()
  dateDefault;

  @Input()
  dateDefaultSecond;

  @Input()
  isDiffrentDuration = false;

  @Input()
  diffrentDurationMinutes = 60;

  @Input()
  dateTimeDefault;

  @Input()
  dateTimeDefaultSecond;

  isModal = false;

  @Input()
  defaultEmail;

  @Input()
  email;

  @Input()
  emailLabel = 'Your email';

  @Input()
  isEmailInput = false;

  @Input()
  isEmailInputRequired = true;

  isEmailValidate = true;
  @Input()
  subTitle;

  @Input()
  amount;

  @Input()
  amountLabel = 'Treatment amount';

  @Input()
  isAmountInput = false;

  @Input()
  isAmountInputRequired = true;

  @Output()
  clickPolicy = new EventEmitter();

  @Output()
  closeModal = new EventEmitter();

  defaultDateDate;
  defaultDateTime;
  defaultDateTimeDate;
  defaultDateTimeTime;
  defaultDateDateSecond;
  defaultDateTimeSecond;
  defaultDateTimeDateSecond;
  defaultDateTimeTimeSecond;

  onConfirm = new EventEmitter();
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    if (data) {
      if (data.headerBGColor) {
        this.headerBGColor = data.headerBGColor;
      }

      if (data.optionList && data.optionList.length > 0) {
        this.optionList = data.optionList;
      }

      if (data.optionViewValue) {
        this.optionViewValue = data.optionViewValue;
      }
      if (data.optionCode) {
        this.optionCode = data.optionCode;
      }
      if (data.subTitle) {
        this.subTitle = data.subTitle;
      }

      if (data.optionValue) {
        this.optionValue = data.optionValue;
      }

      if (moment.isDate(data.dateDefault)) {
        this.dateDefault = data.dateDefault;
      }

      if (moment.isDate(data.dateDefaultSecond)) {
        this.dateDefaultSecond = data.dateDefaultSecond;
      }

      if (moment.isDate(data.dateTimeDefault)) {
        this.dateTimeDefault = data.dateTimeDefault;
      }

      if (moment.isDate(data.dateTimeDefaultSecond)) {
        this.dateTimeDefaultSecond = data.dateTimeDefaultSecond;
      }

      if (data.optionLabel) {
        this.optionLabel = data.optionLabel;
      }

      if (data.option) {
        this.optionValue = data.option;
      }

      if (data.isOption != null) {
        this.isOption = data.isOption;
      }

      if (data.isDiffrentDuration != null) {
        this.isDiffrentDuration = data.isDiffrentDuration;
      }

      if (data.diffrentDurationMinutes) {
        this.diffrentDurationMinutes = data.diffrentDurationMinutes;
      }

      if (data.isOptionRequired != null) {
        this.isOptionRequired = data.isOptionRequired;
      }

      this.icon = data.icon;

      if (data.title) {
        this.title = data.title;
      }

      if (data.dateLabel) {
        this.dateLabel = data.dateLabel;
      }

      if (data.dateTimeLabel) {
        this.dateTimeLabel = data.dateTimeLabel;
      }

      if (data.minDate) {
        this.minDate = data.minDate;
      }

      if (data.dateLabelSecond) {
        this.dateLabelSecond = data.dateLabelSecond;
      }

      if (data.dateTimeLabelSecond) {
        this.dateTimeLabelSecond = data.dateTimeLabelSecond;
      }

      if (data.minDateSecond) {
        this.minDateSecond = data.minDateSecond;
      }

      if (data.checkboxTitle) {
        this.checkboxTitle = data.checkboxTitle;
      }

      if (data.checkboxLabel) {
        this.checkboxLabel = data.checkboxLabel;
      }

      if (data.checkboxLabelSecond) {
        this.checkboxLabelSecond = data.checkboxLabelSecond;
      }

      if (data.defaultEmail) {
        this.email = data.defaultEmail;
      }
      if (data.emailLabel) {
        this.emailLabel = data.emailLabel;
      }

      if (data.isEmailInput !== null) {
        this.isEmailInput = data.isEmailInput;
      }
      if (data.isEmailInputRequired !== null) {
        this.isEmailInputRequired = data.isEmailInputRequired;
      }

      if (data.amount) {
        this.amount = data.amount;
      }
      if (data.amountLabel) {
        this.amountLabel = data.amountLabel;
      }

      if (data.isAmountInput !== null) {
        this.isAmountInput = data.isAmountInput;
      }
      if (data.isAmountInputRequired !== null) {
        this.isAmountInputRequired = data.isAmountInputRequired;
      }

      if (data.description) {
        this.description = data.description;
      }
      if (data.yesLabel) {
        this.yesLabel = data.yesLabel;
      }
      if (data.noLabel) {
        this.noLabel = data.noLabel;
      }
      if (data.message) {
        this.message = data.message;
      }
      if (data.date) {
        this.date = data.date;
      }

      if (data.dateSecond) {
        this.dateSecond = data.dateSecond;
      }

      if (data.dateTime) {
        this.dateTime = data.dateTime;
      }

      if (data.dateTimeSecond) {
        this.dateTimeSecond = data.dateTimeSecond;
      }

      if (data.messageLabel) {
        this.messageLabel = data.messageLabel;
      } else if (data.messageLabel === '') {
        this.messageLabel = '';
      }
      if (data.policyName) {
        this.policyName = data.policyName;
      }

      if (data.policyLabel) {
        this.policyLabel = data.policyLabel;
      }

      if (data.isPolicyRequired != null) {
        this.isPolicyRequired = data.isPolicyRequired;
      }

      if (data.isCheckboxRequired != null) {
        this.isCheckboxRequired = data.isCheckboxRequired;
      }

      if (data.isCheckboxSecondRequired != null) {
        this.isCheckboxSecondRequired = data.isCheckboxSecondRequired;
      }

      if (data.isPolicy != null) {
        this.isPolicy = data.isPolicy;
      }
      if (data.policyValue != null) {
        this.policyValue = data.policyValue;
      }

      if (data.isCheckbox != null) {
        this.isCheckbox = data.isCheckbox;
      }
      if (data.checkboxValue != null) {
        this.checkboxValue = data.checkboxValue;
      }

      if (data.isCheckboxSecond != null) {
        this.isCheckboxSecond = data.isCheckboxSecond;
      }
      if (data.checkboxValueSecond != null) {
        this.checkboxValueSecond = data.checkboxValueSecond;
      }

      if (data.isTextAreaRequired != null) {
        this.isTextAreaRequired = data.isTextAreaRequired;
      }
      if (data.isDateInput != null) {
        this.isDateInput = data.isDateInput;
      }

      if (data.isDateRequired != null) {
        this.isDateRequired = data.isDateRequired;
      }

      if (data.isDateTimeInput != null) {
        this.isDateTimeInput = data.isDateTimeInput;
      }

      if (data.isDateTimeRequired != null) {
        this.isDateTimeRequired = data.isDateTimeRequired;
      }

      if (data.isDateSecondInput != null) {
        this.isDateSecondInput = data.isDateSecondInput;
      }

      if (data.isDateSecondRequired != null) {
        this.isDateSecondRequired = data.isDateSecondRequired;
      }

      if (data.isDateTimeSecondInput != null) {
        this.isDateTimeSecondInput = data.isDateTimeSecondInput;
      }

      if (data.isDateTimeSecondRequired != null) {
        this.isDateTimeSecondRequired = data.isDateTimeSecondRequired;
      }

      if (data.isTextInputRequired != null) {
        this.isTextInputRequired = data.isTextInputRequired;
      }
      if (data.isTextArea != null) {
        this.isTextArea = data.isTextArea;
      }

      if (data.messageTitle) {
        this.messageTitle = data.messageTitle;
      } else if (data.messageTitle === '') {
        this.messageTitle = '';
      }

      if (data.isTextInput != null) {
        this.isTextInput = data.isTextInput;
      }

      if (data.text) {
        this.text = data.text;
      }
      if (data.textLabel) {
        this.textLabel = data.textLabel;
      }

      if (data.displayNo !== null) {
        this.displayNo = data.displayNo;
      }

      this.isModal = true;
    }
  }

  ngOnInit() {
    if (moment.isDate(this.dateDefault)) {
      this.defaultDateDate = moment(this.dateDefault).toDate();
      this.defaultDateTime = moment(this.dateDefault).format('HH:mm');
    } else {
      this.defaultDateDate = moment().toDate();
      this.defaultDateTime = moment().add(2, 'minutes').format('HH:mm');
    }

    if (moment.isDate(this.dateTimeDefault)) {
      this.defaultDateTimeDate = moment(this.dateTimeDefault).toDate();
      this.defaultDateTimeTime = moment(this.dateTimeDefault).format('HH:mm');
    } else {
      this.defaultDateTimeDate = moment().toDate();
      this.defaultDateTimeTime = moment().add(2, 'minutes').format('HH:mm');
    }

    if (moment.isDate(this.dateDefaultSecond)) {
      this.defaultDateDateSecond = moment(this.dateDefaultSecond).toDate();
      this.defaultDateTimeSecond = moment(this.dateDefaultSecond).format('HH:mm');
    } else {
      this.defaultDateDateSecond = moment().toDate();
      this.defaultDateTimeSecond = moment().add(2, 'minutes').format('HH:mm');
    }

    if (moment.isDate(this.dateTimeDefaultSecond)) {
      this.defaultDateTimeDateSecond = moment(this.dateTimeDefaultSecond).toDate();
      this.defaultDateTimeTimeSecond = moment(this.dateTimeDefaultSecond).format('HH:mm');
    } else {
      this.defaultDateTimeDateSecond = moment().toDate();
      this.defaultDateTimeTimeSecond = moment().add(2, 'minutes').format('HH:mm');
    }
  }

  closeEvent() {
    this.closeModal.emit(true);
  }
  onYes() {
    const p = {
      message: this.message,
      text: this.text,
      date: this.date,
      dateTime: this.dateTime,
      dateSecond: this.dateSecond,
      dateTimeSecond: this.dateTimeSecond,
      confirm: true,
      checkboxValue: this.checkboxValue,
      checkboxValueSecond: this.checkboxValueSecond,
      optionValue: this.optionValue,
      amount: this.amount,
      email: this.email,
    };
    this.onConfirm.emit(p);
  }

  onNo() {
    const p = {
      message: this.message,
      text: this.text,
      date: this.date,
      dateTime: this.dateTime,
      dateSecond: this.dateSecond,
      dateTimeSecond: this.dateTimeSecond,
      confirm: false,
      checkboxValue: this.checkboxValue,
      checkboxValueSecond: this.checkboxValueSecond,
      optionValue: this.optionValue,
      amount: this.amount,
      email: this.email,
    };
    this.onConfirm.emit(p);

    this.closeModal.emit(true);
  }

  selectDateTime(d) {
    if (d) {
      this.dateTime = d;

      if (this.isDiffrentDuration == true && this.diffrentDurationMinutes && this.isDateTimeSecondInput == true) {
        this.dateTimeSecond = moment(this.dateTime).add(this.diffrentDurationMinutes, 'minutes').toDate();

        this.defaultDateTimeDateSecond = moment(this.dateTimeSecond).toDate();
        this.defaultDateTimeTimeSecond = moment(this.dateTimeSecond).format('HH:mm');
      }
    }
  }
  selectDate(d) {
    if (d) {
      this.date = d;
    }
  }

  selectDateTimeSecond(d) {
    if (d) {
      this.dateTimeSecond = d;
    }
  }
  selectDateSecond(d) {
    if (d) {
      this.dateSecond = d;
    }
  }

  clickPolicyEvent() {
    this.clickPolicy.emit(true);
  }

  validateEmail(e) {
    this.isEmailValidate = e;
  }

  getEmail(e) {
    if (e) {
      this.email = e;
    }
  }
  isFontAwsome(icon) {
    if (icon) {
      if (icon.indexOf('fas ') !== -1 || icon.indexOf('fab-') !== -1) {
        return true;
      }

      return false;
    }

    return false;
  }
}
