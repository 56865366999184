<div class="row clearfix">
  <div class="row clearfix flex">
    <mat-form-field class="full-width" appearance="outline">
      <mat-label>Filter Type</mat-label>
      <mat-select
        class="full-width"
        [(ngModel)]="selectedSection"
        (selectionChange)="getSection($event.value)"
        placeholder="Filter Type"
        name="section"
      >
        <mat-option *ngFor="let s of sections" [value]="s">{{ s['Label'] }} </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="thrd-width" appearance="outline">
      <mat-label
      >Apply
        <strong *ngIf="propertyConjunction == 'and'">All</strong>
        <strong *ngIf="propertyConjunction == 'or'">Any</strong>
        for each property
      </mat-label>
      <mat-select
        class="full-width"
        [(ngModel)]="propertyConjunction"
        (selectionChange)="getConjunction($event.value)"
        name="propertyConjunction"
      >
        <mat-option [value]="'and'">All</mat-option>
        <mat-option [value]="'or'">Any</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="row clearfix prop-row" *ngFor="let f of filterProperties; let index = index">
    <div class="row flex prop">
      <h4 class="rm-m text-left">
        <span *ngIf="index != 0 && propertyConjunction == 'and'">AND</span>
        <span *ngIf="index != 0 && propertyConjunction == 'or'">OR</span>
        Property {{ index + 1 }}
      </h4>

      <div class="row clearfix pull-right flex control-bar">
        <!-- <p class="tiny rm-m pull-left">Property {{index+1}} controls</p> -->

        <button
          class="btn-small"
          (click)="removeProperty(index)"
          mat-stroked-button
          color="accent"
          matTooltip="Remove row"
        >
          Remove property
          <mat-icon class="fas fa-trash mat-accent"></mat-icon>
        </button>

        <!-- <button mat-icon-button class="small" color="accent" matTooltip="Clear row" (click)="clearProperty()">
          <mat-icon class="fas fa-times mat-accent"></mat-icon>
        </button> -->

        <button
          class="btn-small"
          (click)="addProperty()"
          mat-stroked-button
          color="primary"
          matTooltip="Add another section"
        >
          Add property
          <mat-icon class="fas fa-plus mat-primary"></mat-icon>
        </button>
      </div>
    </div>
    <div class="row clearfix">
      <app-marketing-filter-create-property
        [isEdit]="isEdit"
        [merchantID]="merchantID"
        [section]="selectedSection"
        [inputProperty]="f"
        (getFilter)="getPropertyEvent($event, index)"
      ></app-marketing-filter-create-property>
    </div>
  </div>
</div>
