import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { switchMap, take } from 'rxjs/operators';
import { environment } from '../../../../../../../../../src/environments/environment';
import { AppStateService } from '../../../../../../../core/app-state/app-state.service';
import { RootAppComponent } from '../../../../../../../shared/components/root-component/root-component.component';
import { BinaryBoolPipe } from '../../../../../../../shared/pipes/binary-bool/binary-bool.pipe';
import { UtilsService } from '../../../../../../../shared/services/utils.service';
import { NotifyAppComponent } from '../../../../../../../shared/types/notify-app-component';
import { UtilsClass } from '../../../../../../../shared/types/utils/utils.class';
import { CustomerProspectCreateComponent } from '../../../../../../customer-prospect/customer-prospect-create/customer-prospect-create.component';
import { CustomerProspectViewModelComponent } from '../../../../../../customer-prospect/customer-prospect-view-model/customer-prospect-view-model.component';
import { InvitationLookupComponent } from '../../../../../../invitation/invitation-lookup/invitation-lookup.component';
import { CustomerAndProspect } from '../../../../../../invitation/shared/types/invitation.type';
import { Merchant } from '../../../../../../merchant/shared/types/merchant.type';
import {
  DynamicProductListComponent,
  DynamicProductListComponentConfig,
} from '../../../../../dynamic-product-list/dynamic-product-list.component';
import {
  ViewProductModalComponent,
  ViewProductModalConfig,
} from '../../../../../dynamic-product-list/modals/view-product.modal/view-product.modal';
import { ProductCatalogueItem } from '../../../../../shared/services/product-catalogue.service';
import { ProductCustomer, ProductCustomerService } from '../../../../../shared/services/product-customer.service';

@Component({
  selector: 'ipv-retail-customer-product-form',
  templateUrl: './retail-customer-product-form.component.html',
  styleUrls: ['./retail-customer-product-form.component.css'],
  providers: [BinaryBoolPipe],
})
export class RetailCustomerProductFormComponent implements OnInit, OnDestroy {
  utils = new UtilsClass();

  @Input() existingProductCustomer = null;
  @Input() practice: Partial<Merchant>;
  @Input() formMode: 'create' | 'update' = 'create';

  fileName = '';

  selectedCustomer: CustomerAndProspect = null;
  selectedProduct: ProductCatalogueItem = null;

  existingAdditionalImages: string[] = [];

  productCustomer: ProductCustomer = {
    ID: '',
    DateTimeCreated: '',
    LastModified: '',
    LastModified_Human: '',
    Owner_Card_key: '',
    Master_Appliance_Profile_key: '',
    SellPrice: 0,
    CostPrice: 0,
    TaxRate: 0,
    Quantity: '1',
    Discount_Amount: 0,
    Notes: '',
    Invoice_key: '',
    Merchant_key: '',
    PurchaseDate: '',
    SerialNumber: '',
  };

  appState$ = this.appStateService.getAppState$();

  constructor(
    private dialogRef: MatDialogRef<RetailCustomerProductFormComponent>,
    private productCustomerService: ProductCustomerService,
    private appStateService: AppStateService,
    private utilsService: UtilsService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    if (this.existingProductCustomer) {
      this.productCustomer = this.existingProductCustomer;
    }
  }

  close() {
    this.dialogRef.close();
  }

  ngOnDestroy() {}

  create(merchantKey: string) {
    this.productCustomer.Owner_Card_key = this.selectedCustomer.ID;
    this.productCustomer.Master_Appliance_Profile_key = this.selectedCustomer.ID;
    this.productCustomer.PurchaseDate = this.utils.getStringDateFromEP(new Date());

    this.productCustomerService
      .createProductCustomer$<ProductCatalogueItem>(this.productCustomer)
      .pipe(
        switchMap((itemWithID) => {
          return this.productCustomerService.addProductCustomer$(itemWithID);
        })
      )
      .subscribe(() => {
        NotifyAppComponent.displayToast('success', 'Successful operation', 'Successfully created serialised product');
        this.dialogRef.close();
      });
  }

  selectPatient(practice: Partial<Merchant>) {
    const invitationLookup = RootAppComponent.dialog.open(InvitationLookupComponent, {
      data: {
        merchantID: practice.ID,
        onlyMyAccount: true,
        useStrict: true,
      },
      width: '600px',
    });

    invitationLookup.componentInstance.closeModal.pipe(untilDestroyed(this)).subscribe(() => {
      invitationLookup.close();
    });

    invitationLookup.componentInstance.getSelectedPatient.pipe(untilDestroyed(this)).subscribe((selectedPatient) => {
      invitationLookup.close();
      this.selectedCustomer = selectedPatient;
      this.productCustomer.Owner_Card_key = selectedPatient.ID;
    });
  }

  newPatient(practice: Partial<Merchant>) {
    const customerProspectCreate = RootAppComponent.dialog.open(CustomerProspectCreateComponent, {
      data: {
        merchantID: practice.ID,
        isEmailRequired: false,
      },
      width: '850px',
    });

    customerProspectCreate.componentInstance.close.pipe(untilDestroyed(this)).subscribe(() => {
      customerProspectCreate.close();
    });

    customerProspectCreate.componentInstance.getResult.pipe(untilDestroyed(this)).subscribe((newPatient) => {
      customerProspectCreate.close();
      this.selectedCustomer = newPatient;
      this.productCustomer.Owner_Card_key = newPatient.ID;
    });
  }

  update() {
    this.productCustomerService
      .updateProductCustomer$(this.productCustomer, this.productCustomer)
      .pipe(
        switchMap((productCustomer) => {
          return this.productCustomerService.editProductCustomer$(productCustomer);
        })
      )
      .subscribe(() => {
        NotifyAppComponent.displayToast('success', 'Successful operation', 'Successfully updated serialised product');

        this.dialogRef.close();
      });
  }

  openPatientProfile() {
    const ID = this.selectedCustomer.ID;
    if (ID) {
      const customerProspectViewModelComponent = RootAppComponent.dialog.open(CustomerProspectViewModelComponent, {
        data: {
          patientID: ID,
          isFullDetails: true,
        },
        width: '700px',
      });

      customerProspectViewModelComponent.componentInstance.close.pipe(untilDestroyed(this)).subscribe((isClosed) => {
        if (isClosed === true) {
          customerProspectViewModelComponent.close();
        }
      });

      customerProspectViewModelComponent.componentInstance.detailedView
        .pipe(untilDestroyed(this))
        .subscribe((showDetailedView) => {
          if (showDetailedView === true) {
            customerProspectViewModelComponent.close();
            customerProspectViewModelComponent
              .afterClosed()
              .pipe(untilDestroyed(this))
              .subscribe(() => {
                this.close();
              });
          }
        });
    }
  }

  getThumbnailImage() {
    return `${environment.nodeUrl}/files/file-view/${this.selectedProduct.Thumbnail_Doc_key}`;
  }

  viewProduct(industryType: string) {
    const ref = this.dialog.open<ViewProductModalComponent, ViewProductModalConfig>(ViewProductModalComponent, {
      data: {
        product: this.selectedProduct,
        industryType,
      },
      width: '800px',
    });
  }

  setFileName() {
    const imageDate = new Date()
      .toLocaleString()
      .replace(/\//g, '-')
      .replace(/,/g, '_')
      .replace(/\s/g, '')
      .replace(/:/g, '-');

    this.fileName = `${this.productCustomer.SerialNumber}_${imageDate}`;
  }

  removeFile(fileID: string) {
    this.existingAdditionalImages = this.existingAdditionalImages.filter((imageID) => imageID !== fileID);
    // this.product.AdditionalImages_Doc_key = this.existingAdditionalImages.join('|');
  }

  addAdditionalImage(fileID: string[]) {
    if (fileID && fileID.length > 0) {
      this.existingAdditionalImages = [...this.existingAdditionalImages, fileID[0]];
      // this.product.AdditionalImages_Doc_key = this.existingAdditionalImages.join('|');
    }
  }

  selectSerialisedProducts() {
    const dynamicProductListModal = this.dialog.open<DynamicProductListComponent, DynamicProductListComponentConfig>(
      DynamicProductListComponent,
      {
        panelClass: 'noCard',
        data: { modalTitle: 'Select Product' },
      }
    );

    dynamicProductListModal.componentInstance.productSelected.pipe(take(1)).subscribe((item) => {
      console.log(item);
      this.selectedProduct = item;
      dynamicProductListModal.close();
    });
  }
}
