import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { InvitationTemplateMergerComponent } from '../../../feature/invitation-template/invitation-template-merger/invitation-template-merger.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { AuthenticationService } from '../../authentication/shared/authentication.service';
import { ThirdPartyService } from '../shared/third-party.service';

@Component({
  selector: 'app-third-party-access-client-overview',
  templateUrl: './third-party-access-client-overview.component.html',
  styleUrls: ['./third-party-access-client-overview.component.css'],
})
export class ThirdPartyAccessClientOverviewComponent implements OnInit {
  templateID;
  isPromoterOrAdmin = false;

  loaded = false;

  pageSizeApp = 10;
  pageSizeAccess = 20;

  type = 'all';

  displayAccess = false;
  displayApplication = false;

  titleAccess = 'API Keys';
  titleApplication = 'Applications';

  constructor(
    private thirdPartyService: ThirdPartyService,
    private authenticationService: AuthenticationService,
    private activeRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      if (params['templateID']) {
        this.templateID = params['templateID'];
      }

      if (params['type']) {
        this.type = params['type'];
      }

      this.authenticationService.isPromoterOrAdmin().subscribe((r) => {
        this.isPromoterOrAdmin = r;

        if (this.isPromoterOrAdmin === false) {
          this.pageSizeAccess = null;
          this.pageSizeApp = null;
        }

        this.thirdPartyService.getByInvitationTemplate(this.templateID).subscribe((res) => {
          if (res) {
            if (res.templateLabel) {
              this.titleAccess = 'API Keys linked to: ' + res.templateLabel;
              this.titleApplication = '3rd Party Apps linked to: ' + res.templateLabel;
            }
            if ((res.accessIDs && res.accessIDs.length > 0) || (res.clientIDs && res.clientIDs.length > 0)) {
              if (res.accessIDs && res.accessIDs.length > 0 && (this.type == 'all' || this.type == 'access')) {
                this.displayAccess = true;
              }
              if (res.clientIDs && res.clientIDs.length > 0 && (this.type == 'all' || this.type == 'application')) {
                this.displayApplication = true;
              }

              if (this.type !== 'all') {
                this.pageSizeAccess = null;
                this.pageSizeApp = null;
              }
            }
          }

          this.loaded = true;
        });
      });
    });
  }

  updateAllTemplate(res) {
    if (res && res.templateIDOld && this.templateID && res.templateIDOld === this.templateID) {
      this.displayAccess = false;

      setTimeout(() => {
        this.displayAccess = true;
      }, 1500);
    }
  }

  view() {
    if (this.templateID) {
      const ref = RootAppComponent.dialog.open(InvitationTemplateMergerComponent, {
        data: {
          templateID: this.templateID,
          viewOnly: true,
          hideTreatmentValue: true,
        },
        width: '1200px',
      });
      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    }
  }
}
