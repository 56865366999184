import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Settings } from '../../../shared/types/settings';

import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import * as moment from 'moment';
import { HelperService } from '../shared/helper.service';

@Component({
  selector: 'app-version-view',
  templateUrl: './version-view.component.html',
  styleUrls: ['./version-view.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.stacked-card-view', style({ opacity: 0 }), { optional: true }),
        query(
          '.stacked-card-view',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({ opacity: 0, offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.stacked-card-view', style({ opacity: 1 }), { optional: true }),
        query(
          '.stacked-card-view',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({ opacity: 1, offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class VersionViewComponent implements OnInit {
  @Output() close = new EventEmitter();
  isLoaded=false
  settings = Settings.global;

  versionDescriptions = [];
  constructor(private helperService: HelperService) {}

  ngOnInit() {
    this.helperService.getGlobalSettings().subscribe((res) => {
      if (res && res[0]) {
        this.settings = res[0];


        if(this.settings && this.settings['versionDescriptions'] && this.settings['versionDescriptions'].length>0 )
        {
          this.versionDescriptions =this.settings['versionDescriptions']
        }
      }
      this.isLoaded=true
    });
  }

  formatDate(d) {
    if (d) {
      return moment(d, 'YYYY-MM-DD HH:mm:ss').toDate();
    }

    return null;
  }

  closeEvent() {
    this.close.emit();
  }
}
