/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./page.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../modal-title/modal-title.component.ngfactory";
import * as i3 from "../modal-title/modal-title.component";
import * as i4 from "@angular/material/dialog";
import * as i5 from "@angular/common";
import * as i6 from "../data-header/data-header.component.ngfactory";
import * as i7 from "../data-header/data-header.component";
import * as i8 from "../background-header/background-header.component.ngfactory";
import * as i9 from "../background-header/background-header.component";
import * as i10 from "../../../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i11 from "@angular/material/card";
import * as i12 from "@angular/platform-browser/animations";
import * as i13 from "./page.component";
var styles_PageComponent = [i0.styles];
var RenderType_PageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PageComponent, data: { "animation": [{ type: 7, name: "ngIfRowAnimation", definitions: [{ type: 1, expr: "void => *", animation: [{ type: 10, animation: { type: 8, animation: [{ type: 11, selector: ".row", animation: { type: 6, styles: { opacity: 1 }, offset: null }, options: { optional: true } }, { type: 11, selector: ".row", animation: { type: 12, timings: "100ms", animation: [{ type: 4, styles: { type: 5, steps: [{ type: 6, styles: { opacity: 1, transform: "translateY(0)", offset: 0, height: "*" }, offset: null }, { type: 6, styles: { opacity: 0, transform: "translateY(-75%)", offset: 1, height: 0 }, offset: null }] }, timings: "{{ timings }} ease-in" }] }, options: { optional: true } }], options: { params: { timings: "0.5s" } } }, options: null }], options: null }, { type: 1, expr: "* => void", animation: [{ type: 10, animation: { type: 8, animation: [{ type: 11, selector: ".row", animation: { type: 6, styles: { opacity: 0 }, offset: null }, options: { optional: true } }, { type: 11, selector: ".row", animation: { type: 12, timings: "100ms", animation: [{ type: 4, styles: { type: 5, steps: [{ type: 6, styles: { opacity: 0, transform: "translateY(-75%)", offset: 0, height: 0 }, offset: null }, { type: 6, styles: { opacity: 1, transform: "translateY(0)", offset: 1, height: "*" }, offset: null }] }, timings: "{{ timings }} ease-out" }] }, options: { optional: true } }], options: { params: { timings: "0.5s" } } }, options: null }], options: null }], options: {} }] } });
export { RenderType_PageComponent as RenderType_PageComponent };
function View_PageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "rel"]], [[24, "@ngIfRowAnimation", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "row clearfix padded rel"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h1", [["class", "dataHeader rm-mt transparent"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Fetching data..."]))], null, function (_ck, _v) { var currVal_0 = undefined; _ck(_v, 0, 0, currVal_0); }); }
function View_PageComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "ipv-modal-title", [], null, null, null, i2.View_ModalTitleComponent_0, i2.RenderType_ModalTitleComponent)), i1.ɵdid(2, 49152, null, 0, i3.ModalTitleComponent, [i4.MAT_DIALOG_DATA], { icon: [0, "icon"], title: [1, "title"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = null; var currVal_1 = _co.modalTitle; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_PageComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "colour-stripe"]], null, null, null, null, null))], null, null); }
function View_PageComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PageComponent_5)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showDataHeader; _ck(_v, 2, 0, currVal_0); }, null); }
function View_PageComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ipv-data-header", [], null, null, null, i6.View_DataHeaderComponent_0, i6.RenderType_DataHeaderComponent)), i1.ɵdid(1, 49152, null, 0, i7.DataHeaderComponent, [], { title: [0, "title"], imageSrc: [1, "imageSrc"], counterValue: [2, "counterValue"], headerSize: [3, "headerSize"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dataHeader.title; var currVal_1 = _co.dataHeader.imageSrc; var currVal_2 = _co.dataHeader.counterValue; var currVal_3 = _co.headerSize; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_PageComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ipv-background-header", [], null, null, null, i8.View_BackgroundHeaderComponent_0, i8.RenderType_BackgroundHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i9.BackgroundHeaderComponent, [], { title: [0, "title"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.backgroundHeader.title; _ck(_v, 1, 0, currVal_0); }, null); }
function View_PageComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "row clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "mat-card", [["class", "card clearfix mat-card"], ["style", "display: flex; flex-direction: column"]], [[2, "_mat-animation-noopable", null]], null, null, i10.View_MatCard_0, i10.RenderType_MatCard)), i1.ɵdid(2, 49152, null, 0, i11.MatCard, [[2, i12.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_PageComponent_3)), i1.ɵdid(4, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_PageComponent_4)), i1.ɵdid(6, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_PageComponent_6)), i1.ɵdid(8, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_PageComponent_7)), i1.ɵdid(10, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵncd(0, 0)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.pageMode === "modal"); _ck(_v, 4, 0, currVal_1); var currVal_2 = (_co.pageMode !== "modal"); _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.showDataHeader; _ck(_v, 8, 0, currVal_3); var currVal_4 = _co.showBackgroundHeader; _ck(_v, 10, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 2)._animationMode === "NoopAnimations"); _ck(_v, 1, 0, currVal_0); }); }
export function View_PageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_PageComponent_1)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PageComponent_2)), i1.ɵdid(3, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isFetchingInitialData; _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.isFetchingInitialData; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_PageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ipv-page", [], null, null, null, View_PageComponent_0, RenderType_PageComponent)), i1.ɵdid(1, 49152, null, 0, i13.PageComponent, [], null, null)], null, null); }
var PageComponentNgFactory = i1.ɵccf("ipv-page", i13.PageComponent, View_PageComponent_Host_0, { isFetchingInitialData: "isFetchingInitialData", showDataHeader: "showDataHeader", dataHeader: "dataHeader", showBackgroundHeader: "showBackgroundHeader", backgroundHeader: "backgroundHeader", headerSize: "headerSize", pageMode: "pageMode", modalTitle: "modalTitle" }, {}, ["*"]);
export { PageComponentNgFactory as PageComponentNgFactory };
