export class Tip {
  id: String;
  content: String;
  title: String;
  isActive: boolean;
  priority: number;
  constructor() {
    this.priority = 1;
    this.isActive = true;
  }
}
