<mat-tab-group dynamicHeight="true">
  <mat-tab *ngIf="isFeature('general')" label="App Settings">
    <mat-card class="card">
      <mat-card-header>
        <p class="lead rm-mt">Application general Settings</p>
      </mat-card-header>
      <form #siteInfoForm="ngForm">
        <div class="row clearfix extra-mt">
          <div class="half-width">
            <div class="column">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Page Title</mat-label>
                <input [(ngModel)]="settings.pageTitle" name="title" matInput placeholder="Page Title" required />
              </mat-form-field>
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Version</mat-label>
                <input [(ngModel)]="settings.EPversion" name="version" matInput placeholder="Version" />
              </mat-form-field>
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Release</mat-label>
                <input [(ngModel)]="settings.EPrelease" name="release" matInput placeholder="Release" />
              </mat-form-field>
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Brand Name</mat-label>
                <input [(ngModel)]="settings.brandName" name="brand" matInput placeholder="Brand Name" />
              </mat-form-field>
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Strap Line</mat-label>
                <input [(ngModel)]="settings.snapLine" name="snap" matInput placeholder="Strap Line" />
              </mat-form-field>
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Copyright Line</mat-label>
                <input [(ngModel)]="settings.copyRight" name="copyright" matInput placeholder="Copyright Line"
                  required />
              </mat-form-field>
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Address</mat-label>
                <input [(ngModel)]="settings.address" name="address" matInput placeholder="Address" />
              </mat-form-field>
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Public Web Site</mat-label>
                <input [(ngModel)]="settings.publicWebSite" name="publicWebSite" matInput
                  placeholder="Public Web Site" />
              </mat-form-field>
            </div>
          </div>
          <div class="half-width">
            <div class="column">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>ABN</mat-label>
                <input [(ngModel)]="settings.abn" name="abn" matInput placeholder="ABN" />
              </mat-form-field>
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Help Name</mat-label>
                <input [(ngModel)]="settings.contactName" name="helname" matInput placeholder="Help Name" required />
              </mat-form-field>
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Help Line</mat-label>
                <input [(ngModel)]="settings.contactPhone" name="helpline" matInput placeholder="Help Line" required />
              </mat-form-field>
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Help Email</mat-label>
                <input [(ngModel)]="settings.contactEmail" name="email" matInput placeholder="Help Email" required />
              </mat-form-field>
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Twitter</mat-label>
                <input [(ngModel)]="settings.twitter" name="twitter" matInput placeholder="Twitter" required />
              </mat-form-field>
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Facebook</mat-label>
                <input [(ngModel)]="settings.facebook" name="facebook" matInput placeholder="Facebook" />
              </mat-form-field>
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Youtube</mat-label>
                <input [(ngModel)]="settings.youtube" name="Youtube" matInput placeholder="Youtube" />
              </mat-form-field>
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Instagram</mat-label>
                <input [(ngModel)]="settings.instagram" name="instagram" matInput placeholder="Instagram" />
              </mat-form-field>
            </div>
          </div>
          <hr class="mb" />
          <div class="clearfix full-width text-center">
            <button class="btn-large" [disabled]="!siteInfoForm.form.valid" (click)="updateSiteInformation()"
              mat-raised-button color="accent">
              Save changes
            </button>
          </div>
        </div>
      </form>
    </mat-card>
  </mat-tab>

  <mat-tab *ngIf="isFeature('session')" label="Sessions Life Time">
    <mat-card class="card">
      <mat-card-header>
        <p class="lead rm-mt">Sessions Life details</p>
      </mat-card-header>
      <form #lifeTimeForm="ngForm">
        <div class="row clearfix extra-mt">
          <div class="half-width">
            <div class="column">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Session LifeTime (hours)</mat-label>
                <input [(ngModel)]="settings.sessionLifeTime" [minlength]="1" name="lifetisme" type="number" matInput
                  placeholder="Session LifeTime (hours)" required />
              </mat-form-field>
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Session Remebered LifeTime (days)</mat-label>
                <input [(ngModel)]="settings.sessionRemeberedLifeTime" name="rembered" type="number" matInput
                  placeholder="Session Remebered LifeTime (days)" required minlength="1" />
              </mat-form-field>
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Security code LifeTime (mins)</mat-label>
                <input [(ngModel)]="settings.securityCodeLifeTime" name="securityCodeLifeTime" type="number" matInput
                  placeholder="Security code LifeTime (mins)" required minlength="1" />
              </mat-form-field>

              <mat-form-field class="full-width" appearance="outline">
                <mat-label>{{ "KEYWORD.practice" | translate | titlecase}} Code LifeTime (days)</mat-label>
                <input [(ngModel)]="settings.practiceCodeLifeTime"
                  [placeholder]="('KEYWORD.practice' | translate | titlecase) + ' Code LifeTime (days)'"
                  name="practiceCodeLifeTime" type="number" matInput required minlength="1" />
              </mat-form-field>

              <mat-form-field class="full-width" appearance="outline">
                <mat-label>{{ "KEYWORD.practice" | translate | titlecase}} Code Number uf uses</mat-label>
                <input [(ngModel)]="settings.practiceCodeNumberOfUse"
                  [placeholder]="('KEYWORD.practice' | translate | titlecase) + ' Code Number uf uses'"
                  name="practiceCodeNumberOfUse" type="number" matInput required minlength="1" />
              </mat-form-field>

              <div class="row clearfix">
                <label style="margin-bottom: 10px">Validator</label>
                <mat-radio-group class="alternate-theme" [(ngModel)]="settings.securityCodeMethod"
                  name="securityCodeMethod">
                  <mat-radio-button *ngFor="let p of securityCodeMethods" [value]="p">{{ p }} </mat-radio-button>
                </mat-radio-group>
              </div>
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Session Refresh Duration (hours)</mat-label>
                <input [(ngModel)]="settings.sessionRefreshDuration" [minlength]="1" name="sessionRefreshDuration"
                  type="number" matInput placeholder="Session Refresh Duration (hours)" required />
              </mat-form-field>
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Browser Cache Duration (hours)</mat-label>
                <input [(ngModel)]="settings.browserCacheLifeTime" name="browserCacheLifeTime" type="number" matInput
                  placeholder="Browser Cache Duration (hours)" required minlength="1" />
              </mat-form-field>
            </div>
          </div>
          <div class="half-width">
            <div class="column">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Stored GeoLocation Number</mat-label>
                <input [(ngModel)]="settings.storedGeoLocationNumber" name="geolocationNumber" matInput
                  placeholder="Stored GeoLocation Number" minlength="1" />
              </mat-form-field>
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Stored Angent details Number</mat-label>
                <input [(ngModel)]="settings.storedClientInfoNumber" name="angentDetails" matInput
                  placeholder="Stored Angent details Number" required minlength="1" />
              </mat-form-field>

              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Consumer Session LifeTime (hours)</mat-label>
                <input [(ngModel)]="settings.sessionConsumerLifeTime" [minlength]="1" name="lifetime" type="number"
                  matInput placeholder=" ConsumerSession LifeTime (hours)" required />
              </mat-form-field>
              <mat-form-field class="full-width" appearance="outline">
                <mat-label> Consumer Session Remebered LifeTime (days)</mat-label>
                <input [(ngModel)]="settings.sessionRemeberedConsumerLifeTime" name="rembered" type="number" matInput
                  placeholder=" ConsumerSession Remebered LifeTime (days)" required minlength="1" />
              </mat-form-field>

              <div class="margin-btm">
                <mat-checkbox [(ngModel)]="settings.isConsumerLogin" name="isConsumerLogin" color="primary">
                  Consumer needs to login?
                </mat-checkbox>
              </div>
              <mat-form-field class="full-width" *ngIf="settings.isConsumerLogin == true">
                <mat-label>Login Code Life Time (minutes)</mat-label>
                <input [(ngModel)]="settings.loginCodeLifeTime" name="loginCodeLifeTime" type="number" matInput
                  placeholder="Login Code Life Time (minutes)" required minlength="1" />
              </mat-form-field>
              <mat-form-field class="full-width" *ngIf="settings.isConsumerLogin == true">
                <mat-label>Number of times the Consumer can login without authentication</mat-label>
                <input [(ngModel)]="settings.consumerNoLoginTimes" name="consumerNoLoginTimes" type="number" matInput
                  placeholder="Number of times the Consumer can login without authentication" required minlength="1" />
              </mat-form-field>
              <mat-form-field class="full-width" *ngIf="settings.isConsumerLogin == true">
                <mat-label>Consumer Session Life Time (hours)</mat-label>
                <input [(ngModel)]="settings.sessionConsumerLifeTime" name="consumerLifetime" type="number" matInput
                  placeholder=" Consumer Session  Life Time (hours)" required minlength="1" />
              </mat-form-field>
              <mat-form-field class="full-width" *ngIf="settings.isConsumerLogin == true">
                <mat-label>Consumer Authentication Duration (minutes)</mat-label>
                <input [(ngModel)]="settings.consumerAuthenticationDuration" name="consumerDuration" type="number"
                  matInput placeholder="Consumer Authentication Duration (minutes)" required minlength="1" />
              </mat-form-field>

              <div class="margin-btm">
                <h3 class=" sr-title">Oauth2 lifetime</h3>
              </div>

              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Authorization Code (mins)</mat-label>
                <input [(ngModel)]="settings.authorizationCodeLifeTime" [minlength]="1" name="authorizationCodeLifeTime"
                  type="number" matInput placeholder="Authorization Code (mins)" required />
              </mat-form-field>

              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Refresh Token (days)</mat-label>
                <input [(ngModel)]="settings.refreshTokenlifeTime" [minlength]="1" name="refreshTokenlifeTime"
                  type="number" matInput placeholder="Refresh Token (days)" required />
              </mat-form-field>

              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Access Token (hours)</mat-label>
                <input [(ngModel)]="settings.accessTokenLifeTime" [minlength]="1" name="accessTokenLifeTime"
                  type="number" matInput placeholder="Access Token (hours)" required />
              </mat-form-field>
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Transaction Access Token (mins)</mat-label>
                <input [(ngModel)]="settings.transactionTokenLifeTime" [minlength]="1" name="transactionTokenLifeTime"
                  type="number" matInput placeholder="Transaction Access Token (mins)" required />
              </mat-form-field>
            </div>
          </div>
          <hr class="mb" />
          <div class="clearfix full-width text-center">
            <button class="btn-large" [disabled]="!lifeTimeForm.form.valid" (click)="updateLifeTimeDetails()"
              mat-raised-button color="accent">
              Save changes
            </button>
          </div>
        </div>
      </form>
    </mat-card>
  </mat-tab>
  <!--
  <mat-tab label="Modules" *ngIf="isFeature('modules')">
    <mat-card class="card">
      <mat-card-header>
        <p class="lead rm-mt">Modules</p>
      </mat-card-header>
      <form #moduleForm="ngForm">
        <div class="row clearfix extra-mt">
          <div class="full-width">
            <div class="flex module-card-flex">




              <div class="full-width  clearfix flex ">

                <mat-card class="card module-card  full-width well context-card margin-btm flex  mr "
                  id="module-card-context">
                  <div class="row  full-width">
                    <mat-icon class="core-icon pull-left">account_balance</mat-icon>
                    <h2 class=" sm-h2 rm-mt rm-mb core-icon-para">Lending Context </h2>
                    <label class="">This will affect
                      <strong> Invitation </strong> and
                      <strong>Consumer Card </strong> Pages
                    </label>
                  </div>
                  <mat-card-actions class="full-width">
                    <mat-select placeholder="Selected Context" [(ngModel)]="settings.context"
                      (ngModelChange)="updateContext()" name="contextapp" class="full-width alternate-theme">

                      <mat-option *ngFor="let c of contexts" [value]="c.value">
                        <mat-icon class="core-icon-choice"> {{c.icon}}</mat-icon>
                        {{c.name}}
                      </mat-option>
                    </mat-select>
                  </mat-card-actions>
                </mat-card>

                <mat-card class="card module-card full-width well label-lang-card margin-btm flex ml  "
                  id="module-card-context">
                  <div class="row clearfix full-width">
                    <mat-icon class="label-lang-icon pull-left">translate</mat-icon>
                    <h2 class=" sm-h2 rm-mt rm-mb label-lang-icon-para">Application labels </h2>
                    <label class="">It will change the application labels such as
                      <strong>{{ "KEYWORD.patient" | translate | titlecase }} </strong> by
                      <strong>Customer</strong>
                    </label>
                  </div>
                  <mat-card-actions class="text-center full-width">
                    <mat-select placeholder="Selected Labels" [(ngModel)]="settings.labelSource"
                      (ngModelChange)="updateContext()" name="labelapp" class="full-width alternate-theme">
                      <mat-option *ngFor="let c of labelSources" [value]="c.value">
                        {{c.name}}
                      </mat-option>
                    </mat-select>
                  </mat-card-actions>
                </mat-card>

              </div>
              <hr class="mb">


              <mat-form-field appearance="outline" class="full-width clearfix"
                *ngIf="moduleUtils && moduleUtils.modules  && moduleUtils.modules.length>0 ">
                <mat-label>Filter by module name , description or Code</mat-label>

                <input name="linkLabel" matInput [(ngModel)]="moduleFilter"
                  placeholder="Filter by module name , description or Code" class="full-width ">
              </mat-form-field>



              <mat-card class="card module-card "
                *ngFor="  let m of moduleUtils.modules   |  FilterArrayMultipleOrContain:  ['code','description','title']: moduleFilter | OrderArrayObject: 'title' ;"
                [class.enabledModule]="settings[m.code] == true  "
                [class.disabledModule]="settings[m.code] == false &&  !settings[m.code+'Beta'] "
                [class.betaModule]="settings[m.code] == false &&  settings[m.code+'Beta'] == true  "
                id="module-card-tips">
                <div class="row clearfix full-width">
                  <mat-icon *ngIf="isIndexOf(m.icon,'fa-')==false" class="primary-color">{{m.icon}}</mat-icon>

                  <mat-icon *ngIf="isIndexOf(m.icon,'fa-')!=false" class="fas {{m.icon}}  primary-color"></mat-icon>

                  <h3 class="sr-title sm-h3 rm-mt rm-mb"> {{m.title}}</h3>
                  <label class="">{{m.description}}
                    <i>({{m.code}})</i>
                  </label>
                </div>
                <div class="clearfix text-right">

                  <div class="clearfix   text-right">


                    <button mat-button matTooltip="Synchronize Module With EP" class="pull-right  mr  btn-small"
                      *ngIf="!isModuleSynchronized(m.code) && existingCodesLoaded==true" (click)="syncModuleEP(m)">
                      <mat-icon>autorenew</mat-icon>
                    </button>

                    <button mat-button matTooltip="View users " class="pull-right  btn-small mr"
                      (click)="moduleUser(m)">
                      <mat-icon>group</mat-icon>
                    </button>


                  </div>



                  <mat-slide-toggle class="clearfix" [checked]="settings[m.code]"
                    (change)="sliderChangeModule($event, m.code)">{{
                    settings[m.code] === true ? 'Module active' : 'Module inactive' }}
                  </mat-slide-toggle>

                  <mat-slide-toggle name="{{m.code}}+'Beta'" class="mt clearfix" [(ngModel)]=" settings[m.code+'Beta'] "
                    (change)="sliderChangeModuleBeta($event,m)" color="primary" *ngIf="settings[m.code]==false">{{
                  settings[m.code+'Beta'] === true ? 'Beta On' : 'Beta Off' }}</mat-slide-toggle>

                </div>
              </mat-card>

            </div>
          </div>

        </div>
      </form>
    </mat-card>
  </mat-tab> -->

  <mat-tab *ngIf="isFeature('integration')" label="Integrations">
    <mat-card class="card">
      <mat-card-header>
        <p class="lead rm-mt">Integrations</p>
      </mat-card-header>
      <form #ingetrationForm="ngForm">
        <div class="row clearfix extra-mt">
          <div class="full-width">
            <div class="column">
              <div class="margin-btm">
                <h3 class=" sr-title">Drift links</h3>
              </div>

              <p class="small">
                Copy the ID from playbook driftLink , if the link is
                <i>drift.api.startInteraction( interactionId: 233932 );</i> Insert <strong>233932</strong> .
              </p>
              <p class="small">Insert <strong>0</strong> for null Value</p>

              <div class="full-width flex mb mt">
                <mat-form-field class="full-width mr" appearance="outline">
                  <mat-label>Consumer (Public Site)</mat-label>
                  <input [(ngModel)]="settings.consumerPublicDrift" name="consumerPublicDrift" matInput
                    placeholder="Consumer (Public Site)" required />
                </mat-form-field>

                <mat-form-field class="full-width mr" appearance="outline">
                  <mat-label>{{ "KEYWORD.practitioner" | translate | titlecase }} (Public Site)</mat-label>
                  <input [(ngModel)]="settings.dentistPublicDrift" name="dentistPublicDrift" matInput
                    placeholder="Consumer (Public Site)" required />
                </mat-form-field>

                <mat-form-field class="full-width mr" appearance="outline">
                  <mat-label>Connected {{ "KEYWORD.practice" | translate }} Prospect (Public Site)</mat-label>
                  <input [(ngModel)]="settings.merchantProspectPublicSiteDrift" name="merchantProspectPublicSiteDrift"
                    matInput placeholder="Connected      {{ 'KEYWORD.practice' | translate }}  Prospect (Public Site)"
                    required />
                </mat-form-field>

                <mat-form-field class="full-width mr" appearance="outline">
                  <mat-label>Connected Merchant (Public Site)</mat-label>
                  <input [(ngModel)]="settings.merchantPublicSiteDrift" name="merchantPublicSiteDrift" matInput
                    placeholder="Connected    {{ 'KEYWORD.practice' | translate }} (Public Site)" required />
                </mat-form-field>

                <mat-form-field class="full-width mr" appearance="outline">
                  <mat-label>Specialist (Public Site)</mat-label>
                  <input [(ngModel)]="settings.specialistPublicDrift" name="specialistPublicDrift" matInput
                    placeholder="Specialist (Public Site)" required />
                </mat-form-field>

                <mat-form-field class="full-width mr" appearance="outline">
                  <mat-label>Investor (Public Site)</mat-label>
                  <input [(ngModel)]="settings.investorPublicDrift" name="investorPublicDrift" matInput
                    placeholder="Investor (Public Site)" required />
                </mat-form-field>
              </div>

              <div class="full-width flex mb">
                <mat-form-field class="full-width mr" appearance="outline">
                  <mat-label>Consumer (Application)</mat-label>
                  <input [(ngModel)]="settings.consumerDrift" name="consumerDrift" matInput
                    placeholder="Consumer (Application)" required />
                </mat-form-field>

                <mat-form-field class="full-width mr" appearance="outline">
                  <mat-label>Merchant (Application)</mat-label>
                  <input [(ngModel)]="settings.merchantDrift" name="merchantDrift" matInput
                    placeholder="Merchant (Application)" required />
                </mat-form-field>

                <mat-form-field class="full-width mr" appearance="outline">
                  <mat-label>Funder (Application)</mat-label>
                  <input [(ngModel)]="settings.funderDrift" name="funderDrift" matInput
                    placeholder="Funder (Application)" required />
                </mat-form-field>

                <mat-form-field class="full-width mr" appearance="outline">
                  <mat-label>Supplier (Application)</mat-label>
                  <input [(ngModel)]="settings.supplierDrift" name="supplierDrift" matInput
                    placeholder="Supplier (Application)" required />
                </mat-form-field>

                <mat-form-field class="full-width mr" appearance="outline">
                  <mat-label>Error (Application)</mat-label>
                  <input [(ngModel)]="settings.errorDrift" name="errorDrift" matInput placeholder="Error (Application)"
                    required />
                </mat-form-field>

                <mat-form-field class="full-width mr" appearance="outline">
                  <mat-label>Unsubscribe (Application)</mat-label>
                  <input [(ngModel)]="settings.unsubscribeDrift" name="unsubscribeDrift" matInput
                    placeholder="Unsubscribe (Application)" required />
                </mat-form-field>
              </div>

              <div class="full-width flex mb">
                <mat-form-field class="full-width mr" appearance="outline">
                  <mat-label>Consumer Login (Application)</mat-label>
                  <input [(ngModel)]="settings.consumerLoginDrift" name="consumerLoginDrift" matInput
                    placeholder="Consumer Login  (Application)" required />
                </mat-form-field>

                <mat-form-field class="full-width mr" appearance="outline">
                  <mat-label>Merchant Login (Application)</mat-label>
                  <input [(ngModel)]="settings.merchantLoginDrift" name="merchantLoginDrift" matInput
                    placeholder="Merchant Login (Application)" required />
                </mat-form-field>

                <mat-form-field class="full-width mr" appearance="outline">
                  <mat-label>Funder Login (Application)</mat-label>
                  <input [(ngModel)]="settings.funderLoginDrift" name="funderLoginDrift" matInput
                    placeholder="Funder Login (Application)" required />
                </mat-form-field>

                <mat-form-field class="full-width mr" appearance="outline">
                  <mat-label>Supplier Login (Application)</mat-label>
                  <input [(ngModel)]="settings.supplierLoginDrift" name="supplierLoginDrift" matInput
                    placeholder="Supplier Login (Application)" required />
                </mat-form-field>

                <mat-form-field class="full-width mr" appearance="outline">
                  <mat-label>{{ "KEYWORD.patient" | translate | titlecase }} Card (Application)</mat-label>
                  <input [(ngModel)]="settings.patientCardDrift"
                    [placeholder]="('KEYWORD.patient' | translate | titlecase) + 'Card (Application)'"
                    name="patientCardDrift" matInput required />
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
        <div class="clearfix full-width">
          <div class="column">
            <div class="margin-btm">
              <h3 class=" sr-title">Booking Calendar public site links</h3>
            </div>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Guest </mat-label>
              <input [(ngModel)]="settings.calendarGuestBooking" name="calendarGuestBooking" matInput
                placeholder="Guest  " required />
            </mat-form-field>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Partner</mat-label>
              <input [(ngModel)]="settings.calendarPartnerBooking" name="calendarPartnerBooking" matInput
                placeholder="Partner" required />
            </mat-form-field>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Investor</mat-label>
              <input [(ngModel)]="settings.calendarInvestorBooking" name="calendarInvestorBooking" matInput
                placeholder="Investor" required />
            </mat-form-field>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Specialist</mat-label>
              <input [(ngModel)]="settings.calendarSpecialistBooking" name="calendarSpecialistBooking" matInput
                placeholder="Specialist" required />
            </mat-form-field>

            <div class="margin-btm">
              <h3 class=" sr-title">Booking Calendar main application links</h3>
            </div>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Merchant</mat-label>
              <input [(ngModel)]="settings.calendarMerchantBooking" name="calendarMerchantBooking" matInput
                placeholder="Merchant" required />
            </mat-form-field>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Consumer</mat-label>
              <input [(ngModel)]="settings.calendarConsumerBooking" name="calendarConsumerBooking" matInput
                placeholder="Consumer" required />
            </mat-form-field>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Supplier</mat-label>
              <input [(ngModel)]="settings.calendarSupplierBooking" name="calendarSupplierBooking" matInput
                placeholder="Supplier" required />
            </mat-form-field>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Funder</mat-label>
              <input [(ngModel)]="settings.calendarFunderBooking" name="calendarFunderBooking" matInput
                placeholder="Funder" required />
            </mat-form-field>
          </div>
        </div>
        <hr class="mb" />
        <div class="clearfix full-width text-center">
          <button class="btn-large" [disabled]="!ingetrationForm.form.valid" (click)="updateIntegration()"
            mat-raised-button color="accent">
            Save changes
          </button>
        </div>
      </form>
    </mat-card>
  </mat-tab>

  <mat-tab *ngIf="isFeature('userInputSetting')" label="User Input">
    <mat-card class="card">
      <mat-card-header>
        <p class="lead rm-mt">User Input</p>
      </mat-card-header>
      <form #userInputFrom="ngForm">
        <div class="row clearfix extra-mt">
          <div class="half-width">
            <div class="column">
              <div class="margin-btm">
                <h3 class=" sr-title">Currency</h3>
              </div>
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Currency</mat-label>
                <mat-select class="full-width" [(ngModel)]="settings.currencyCode" name="currency"
                  placeholder="Currency" required>
                  <mat-option *ngFor="let currency of currencyList" [value]="currency.code">
                    {{ currency.code }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Currency Digits</mat-label>
                <input [(ngModel)]="settings.currencyDigits" name="currencyDigit" matInput placeholder="Currency Digits"
                  required />
              </mat-form-field>
              <div class="margin-btm">
                <mat-checkbox [(ngModel)]="settings.currencyDisplaySymbol" name="currencySymbol" color="primary">
                  Currency Display Symbol
                </mat-checkbox>
              </div>
              <div class="margin-btm">
                <h3 class=" sr-title">Phone</h3>
              </div>
              <div class="row clearfix">
                <label style="margin-bottom: 10px">Validator</label>
                <mat-radio-group class="alternate-theme" [(ngModel)]="settings.phoneValidator" name="phoneValidator">
                  <mat-radio-button *ngFor="let p of phoneValidators" [value]="p.value">{{ p.name }} </mat-radio-button>
                </mat-radio-group>
                <div class="margin-btm" *ngIf="settings.phoneValidator == 'kleber'">
                  <mat-checkbox [(ngModel)]="settings.isKleberPhoneStrictMode" name="displayPrefiex" color="primary">
                    Use Kleber in Strict Mode?
                  </mat-checkbox>
                </div>
              </div>
              <div class="margin-btm">
                <mat-checkbox [(ngModel)]="settings.dynamicPhonePrefix" name="dynamicPrefix" color="primary">
                  Dynamic Prefix?
                </mat-checkbox>
              </div>
              <label style="margin-bottom: 10px">Phone Prefixes</label>
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Default Mobile Prefix</mat-label>
                <span matPrefix>+</span>
                <input [(ngModel)]="settings.defaultMobilePhonePrefix" [minlength]="1" name="MobilephonePrefix"
                  type="number" matInput placeholder="'Default Mobile Prefix'" required />
              </mat-form-field>
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Default LandLine Prefix</mat-label>
                <span matPrefix>+</span>
                <input [(ngModel)]="settings.defaultLandPhonePrefix" [minlength]="1" name="LandphonePrefix"
                  type="number" matInput placeholder="'Default LandLine Prefix'" required />
              </mat-form-field>
              <mat-form-field class="row clearfix full-width">
                <mat-label>
                  Mobile Format: exp= '(+p) 01-23-456-789' where 'p' for Prefix , '0' for Zero , '1' for First
                  digits..etc
                </mat-label>
                <input [(ngModel)]="settings.phoneFormat" name="phoneFormat" type="text" matInput
                  placeholder=" Mobile Format: exp= '(+p) 01-23-456-789' where  'p' for Prefix , '0' for Zero , '1' for First digits..etc "
                  required />
              </mat-form-field>
              <mat-form-field class="row clearfix full-width">
                <mat-label>Landline Format: exp= '(+p) 01-23-456-789' where 'p' for Prefix , '0' for Zero , '1' for
                  First
                  digits..etc
                </mat-label>
                <input [(ngModel)]="settings.landPhoneFormat" name="landPhoneFormat" type="text" matInput
                  placeholder="Landline Format: exp= '(+p) 01-23-456-789' where  'p' for Prefix , '0' for Zero , '1' for First digits..etc "
                  required />
              </mat-form-field>

              <div class="margin-btm">
                <mat-checkbox [(ngModel)]="settings.displayEmojis" name="displayEmojis" color="primary">
                  Use Emojis
                </mat-checkbox>
              </div>
            </div>
          </div>
          <div class="half-width">
            <div class="column">
              <div class="margin-btm">
                <h3 class=" sr-title">Email</h3>
              </div>
              <div class="row clearfix">
                <label style="margin-bottom: 10px">Validator</label>
                <mat-radio-group class="alternate-theme" [(ngModel)]="settings.emailValidator" name="emailValidator">
                  <mat-radio-button *ngFor="let e of emailValidators" [value]="e.value">{{ e.name }} </mat-radio-button>
                </mat-radio-group>
                <div class="margin-btm" *ngIf="settings.emailValidator == 'kleber'">
                  <mat-checkbox [(ngModel)]="settings.isKleberEmailStrictMode" name="displayPrefiex" color="primary">
                    Use Kleber in Strict Mode?
                  </mat-checkbox>
                </div>
              </div>
              <div class="margin-btm mt">
                <h3 class=" sr-title">Date</h3>
              </div>
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Date Format</mat-label>
                <input [(ngModel)]="settings.dateFormat" name="dateFormat" matInput placeholder="Date Format"
                  required />
              </mat-form-field>
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>DateTime Format</mat-label>
                <input [(ngModel)]="settings.dateTimeFormat" name="dateTimeFormat" matInput
                  placeholder="DateTime Format" required />
              </mat-form-field>
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Time Format</mat-label>
                <input [(ngModel)]="settings.timeFormat" name="timeFormat" matInput placeholder="Time Format"
                  required />
              </mat-form-field>

              <!-- <mat-form-field class="full-width" appearance="outline">
                <mat-label>Time Zone</mat-label>
                <input type="text" placeholder="Time Zone"  matInput name="timeZone"
                  [matAutocomplete]="auto" [(ngModel)]="settings.serverTimeZone">
                <mat-autocomplete #auto="matAutocomplete">
                  <mat-option
                    *ngFor="let option of timeZones | FilterArrayMultipleContainValue: '*' : settings.serverTimeZone"
                    [value]="option">
                    {{option}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field> -->

              <mat-select class="full-width margin-btm" [(ngModel)]="settings.serverTimeZoneUTC" name="timeZoneUTC"
                placeholder="Time Zone" required>
                <mat-label>Time Zone</mat-label>
                <mat-option *ngFor="let option of timeZoneUTCs" [value]="option">
                  UTC <span *ngIf="option >= 0">+{{ option }}</span>
                  <span *ngIf="option < 0">{{ option }}</span>
                </mat-option>
              </mat-select>

              <div class="margin-btm">
                <h3 class=" sr-title">Dynamic Date conversion to the connected user Time Zone</h3>

                <mat-slide-toggle class="clearfix" [(ngModel)]="settings['dynamicUserTimeZone']"
                  name="dynamicUserTimeZone">
                  {{ settings['dynamicUserTimeZone'] === true ? 'Using session time zone' : 'Using local time zone' }}
                </mat-slide-toggle>
              </div>

              <div class="margin-btm">
                <h3 class=" sr-title">Map</h3>
              </div>
              <div class="row clearfix">
                <label style="margin-bottom: 10px">Validator</label>
                <mat-radio-group class="alternate-theme" [(ngModel)]="settings.mapValidator" name="mapValidator">
                  <mat-radio-button *ngFor="let m of mapValidators" [value]="m.value">{{ m.name }} </mat-radio-button>
                </mat-radio-group>
              </div>
              <mat-form-field class="full-width" *ngIf="settings.mapValidator == 'kleber'">
                <mat-label>Kleber result number</mat-label>
                <input [(ngModel)]="settings.mapKleberResult" [minlength]="1" name="kleberResult" type="number" matInput
                  placeholder="Kleber result number" required />
              </mat-form-field>
              <mat-form-field class="full-width" *ngIf="settings.mapValidator == 'kleber'">
                <mat-label>Kleber Number of Letters to auto-complete</mat-label>
                <input [(ngModel)]="settings.mapKleberAutoComplet" [minlength]="1" name="kleberAutoComplet"
                  type="number" matInput placeholder="Kleber Number of Letters to auto-complete" required />
              </mat-form-field>
              <div class="margin-btm">
                <h3 class=" sr-title">File</h3>
              </div>
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>file size limit (Mb)</mat-label>
                <input [(ngModel)]="settings.fileMaxSize" [attr.minlength]="5" name="limit" type="number" matInput
                  placeholder="file size limit (Mb)" required />
              </mat-form-field>

              <div class="margin-btm">
                <mat-checkbox [(ngModel)]="settings.defaultSendInvitationSMS" name="defaultSMSSENT" color="primary">
                  Check 'Send SMS' By default on invitation steps ?
                </mat-checkbox>
              </div>
            </div>
          </div>
          <hr class="mb" />
          <div class="clearfix full-width text-center">
            <button class="btn-large" [disabled]="!userInputFrom.form.valid || settings.fileMaxSize < 5"
              (click)="userInputSetting()" mat-raised-button color="accent">
              Save changes
            </button>
          </div>
        </div>
      </form>
    </mat-card>
  </mat-tab>
  <mat-tab *ngIf="isFeature('communication')" label="Communication">
    <mat-card class="card">
      <mat-card-header>
        <p class="lead rm-mt">Communication settings</p>
      </mat-card-header>

      <form #communicationSettForm="ngForm">
        <div class="row clearfix extra-mt">
          <div class="half-width">
            <div class="column">
              <div class="margin-btm">
                <h3 class=" sr-title">NodeJS</h3>
              </div>
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>NodeJS entry point</mat-label>
                <input [(ngModel)]="settings.nodeJSServer" type="text" name="nodejsServer" matInput
                  placeholder="NodeJS entry point"
                  pattern="^(http|https|ftp)\://([a-zA-Z0-9\.\-]+(\:[a-zA-Z0-9\.&amp;%\$\-]+)*@)*((25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9])\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[0-9])|localhost|([a-zA-Z0-9\-]+\.)*[a-zA-Z0-9\-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(\:[0-9]+)*(/($|[a-zA-Z0-9\.\,\?\'\\\+&amp;%\$#\=~_\-]+))*$"
                  required />
              </mat-form-field>
              <div class="margin-btm">
                <h3 class=" sr-title">Frontend Settings</h3>
              </div>

              <mat-slide-toggle class="clearfix mt mb" [(ngModel)]="settings['acticeSmsAuthentication']"
                name="acticeSmsAuthentication">
                {{
                settings['acticeSmsAuthentication'] === true
                  ? 'SMS Authentication Active'
                  : 'SMS Authentication inactive'
                }}
              </mat-slide-toggle>

              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Public Site</mat-label>
                <input [(ngModel)]="settings.publicSiteFrontendLink" name="publicSiteFrontendLink" matInput
                  placeholder="Public Site" required />
              </mat-form-field>

              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Merchant Application</mat-label>
                <input [(ngModel)]="settings.merchantFrontendLink" name="merchantFrontendLink" matInput
                  placeholder="Merchant Application" required />
              </mat-form-field>

              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Consumer Application</mat-label>
                <input [(ngModel)]="settings.consumerFrontendLink" name="consumerFrontendLink" matInput
                  placeholder="Consumer Application" required />
              </mat-form-field>

              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Funder Application</mat-label>
                <input [(ngModel)]="settings.funderFrontendLink" name="funderFrontendLink" matInput
                  placeholder="Funder Application" required />
              </mat-form-field>

              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Promoter Application</mat-label>
                <input [(ngModel)]="settings.promoterFrontendLink" name="promoterFrontendLink" matInput
                  placeholder="Promoter Application" required />
              </mat-form-field>

              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Supplier Application</mat-label>
                <input [(ngModel)]="settings.supplierFrontendLink" name="supplierFrontendLink" matInput
                  placeholder="Supplier Application" required />
              </mat-form-field>

              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Tmp Application</mat-label>
                <input [(ngModel)]="settings.tmpFrontendLink" name="tmpFrontendLink" matInput
                  placeholder="Tmp Application" required />
              </mat-form-field>

              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Integration Application</mat-label>
                <input [(ngModel)]="settings.integrationFrontendLink" name="integrationFrontendLink" matInput
                  placeholder="Integration Application" required />
              </mat-form-field>

              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Wakanda Application List</mat-label>
                <input [(ngModel)]="settings.wakandaLink" name="wakandaApplicationList" type="text" matInput
                  placeholder="Wakanda Application List" required />
              </mat-form-field>

              <h3 class=" sr-title">Graphic Editors</h3>

              <div class="full-width clearfix mb">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>TinyMCE Editor API key</mat-label>
                  <input [(ngModel)]="settings.TinyAPIKey" name="TinyAPIKey" type="text" matInput
                    placeholder="TinyMCE  Editor API key" />
                </mat-form-field>

                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Froala Editor API key</mat-label>
                  <input [(ngModel)]="settings.FroalaAPIKey" name="FroalaAPIKey" type="text" matInput
                    placeholder="Froala  Editor API key" />
                </mat-form-field>

                <div class="margin-btm full-width flex">
                  <mat-slide-toggle class="full-width mr" [(ngModel)]="settings['isFroalaEditorActive']"
                    name="isFroalaEditorActive">
                    {{
                    settings['isFroalaEditorActive'] === true
                      ? 'Froala Editor is  Active'
                      : 'Froala Editor is   Inactive'
                    }}
                  </mat-slide-toggle>

                  <mat-slide-toggle class="full-width" [(ngModel)]="settings['isTinyEditorActive']"
                    name="isTinyEditorActive">{{
                    settings['isTinyEditorActive'] === true
                      ? 'TinyMCE Editor is  Active'
                      : 'TinyMCE Editor is   Inactive'
                    }}
                  </mat-slide-toggle>
                </div>

                <div class="margin-btm full-width">
                  <mat-slide-toggle class="clearfix" [(ngModel)]="settings['isUnlayerActive']" name="isUnlayerActive">{{
                    settings['isUnlayerActive'] === true
                      ? 'Unlayer Editor is  Active'
                      : 'Unlayer Editor is   Inactive'
                    }}
                  </mat-slide-toggle>
                </div>
              </div>

              <div class="margin-btm">
                <h3 class=" sr-title">S3 Service Configuration</h3>

                <mat-slide-toggle class="clearfix" [(ngModel)]="settings['activeAWSS3']" name="activeAWSS3">
                  {{ settings['activeAWSS3'] === true ? 'S3 upload active' : 'S3 upload inactive' }}
                </mat-slide-toggle>
              </div>

              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Bucket Default Bucket</mat-label>
                <input [(ngModel)]="settings.defaultBucketName" name="defaultBucketName" type="text" matInput
                  placeholder="Bucket Default Bucket" />
              </mat-form-field>

              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Bucket Default Region</mat-label>
                <input [(ngModel)]="settings.S3DefaultRegion" name="S3DefaultRegion" type="text" matInput
                  placeholder="Bucket Default Region" />
              </mat-form-field>

              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Access Key ID</mat-label>
                <input [(ngModel)]="settings.S3AccessKeyID" name="S3AccessKeyID" type="text" matInput
                  placeholder="Access Key ID" />
              </mat-form-field>

              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Secrect Access Key</mat-label>
                <input [(ngModel)]="settings.secrectS3AccessKey" name="secrectS3AccessKey" type="text" matInput
                  placeholder="Secrect Access Key" />
              </mat-form-field>

              <mat-form-field class="full-width" appearance="outline">
                <mat-label>S3 Prefix</mat-label>
                <input [(ngModel)]="settings.AWSS3Prefix" name="AWSS3Prefix" type="text" matInput
                  placeholder="S3 Prefix" />
              </mat-form-field>

              <mat-form-field class="full-width" appearance="outline">
                <mat-label>S3 Public Folder</mat-label>
                <input [(ngModel)]="settings.AWSS3PublicFolder" name="AWSS3PublicFolder" type="text" matInput
                  placeholder="S3 Public Folder" />
              </mat-form-field>




              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Thumbnail Bucket</mat-label>
                <input [(ngModel)]="settings.thumbnailS3Bucket" name="thumbnailS3Bucket" type="text" matInput
                  placeholder="Thumbnail Bucket" />
              </mat-form-field>

              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Thumbnail Folder</mat-label>
                <input [(ngModel)]="settings.thumbnailS3Folder" name="thumbnailS3Folder" type="text" matInput
                  placeholder="Thumbnail Folder" />
              </mat-form-field>

              <div class="margin-btm">
                <mat-checkbox>Inform Operator when {{ "KEYWORD.patient" | translate }} completes consent</mat-checkbox>
              </div>
              <div class="margin-btm">
                <h3 class=" sr-title">Wakanda Integration</h3>
              </div>
              <div class="margin-btm">
                <mat-checkbox [(ngModel)]="settings.isWakandaIntegrated" name="isWakandaIntegrated" color="primary">
                  Is Wakanda Integrated?
                </mat-checkbox>
              </div>
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Wakanda Invitation Card : _id_ will be replaced by invitation id</mat-label>
                <input [(ngModel)]="settings.wakandaInvitationCardLink" name="wakandaInvitationCardLink" type="text"
                  matInput placeholder="Wakanda Invitation Card : _id_ will be replaced by invitation id "
                  pattern="^(http|https|ftp)\://([a-zA-Z0-9\.\-]+(\:[a-zA-Z0-9\.&amp;%\$\-]+)*@)*((25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9])\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[0-9])|localhost|([a-zA-Z0-9\-]+\.)*[a-zA-Z0-9\-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(\:[0-9]+)*(/($|[a-zA-Z0-9\.\,\?\'\\\+&amp;%\$#\=~_\-]+))*$"
                  required />
              </mat-form-field>
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Wakanda Loan Application Link : _id_ will be replaced by invitation id </mat-label>
                <input [(ngModel)]="settings.wakandaLALink" name="wakandaLaLink" type="text" matInput
                  placeholder="Wakanda Loan Application Link : _id_ will be replaced by invitation id "
                  pattern="^(http|https|ftp)\://([a-zA-Z0-9\.\-]+(\:[a-zA-Z0-9\.&amp;%\$\-]+)*@)*((25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9])\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[0-9])|localhost|([a-zA-Z0-9\-]+\.)*[a-zA-Z0-9\-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(\:[0-9]+)*(/($|[a-zA-Z0-9\.\,\?\'\\\+&amp;%\$#\=~_\-]+))*$"
                  required />
              </mat-form-field>
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Wakanda Payment Plan Link: _id_ will be replaced by invitation id</mat-label>
                <input [(ngModel)]="settings.wakandaPPLink" name="wakandaPPLink" type="text" matInput
                  placeholder="Wakanda Payment Plan Link: _id_ will be replaced by invitation id "
                  pattern="^(http|https|ftp)\://([a-zA-Z0-9\.\-]+(\:[a-zA-Z0-9\.&amp;%\$\-]+)*@)*((25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9])\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[0-9])|localhost|([a-zA-Z0-9\-]+\.)*[a-zA-Z0-9\-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(\:[0-9]+)*(/($|[a-zA-Z0-9\.\,\?\'\\\+&amp;%\$#\=~_\-]+))*$"
                  required />
              </mat-form-field>





              <div class="margin-btm">
                <h3 class=" sr-title">Query Parameters</h3>
              </div>

              <div class="full-width clearfix flex">
                <mat-form-field class="full-width mr" appearance="outline">
                  <mat-label>List Item Number</mat-label>
                  <input [(ngModel)]="settings.listItemNumber" name="listItemNum" type="number" matInput
                    placeholder="List Item Number" minlength="1" required />
                </mat-form-field>
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>List Default Page Size</mat-label>
                  <input [(ngModel)]="settings.listPageSize" name="pageSize" type="number" matInput
                    placeholder="List Default Page Size" minlength="1" required />
                </mat-form-field>
              </div>

              <div class="full-width clearfix flex">
                <mat-form-field class="full-width mr" appearance="outline">
                  <mat-label>Logs LifeTime (days)</mat-label>
                  <input [(ngModel)]="settings.logsLifeTime" name="logsLifetime" type="number" matInput
                    placeholder="Logs LifeTime (days)" minlength="1" required />
                </mat-form-field>
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Records LifeTime (days)</mat-label>
                  <input [(ngModel)]="settings.recordsLifeTime" name="RecordLifeTime" type="number" matInput
                    placeholder="Records LifeTime (days)" minlength="1" minlength="1" required />
                </mat-form-field>
              </div>



            </div>
          </div>
          <div class="half-width">
            <div class="column">
              <div class="margin-btm">
                <h3 class=" sr-title">Application Context</h3>
              </div>

              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Context</mat-label>
                <mat-select class="full-width alternate-theme" [(ngModel)]="settings.context" placeholder="Context"
                  name="contextapp">
                  <!-- <mat-select-trigger>
                    {{contexts.name}}
                  </mat-select-trigger> -->
                  <!-- Needs to display NAME selected -->
                  <mat-option *ngFor="let c of contexts" [value]="c.value">
                    <mat-icon class="core-icon-choice" *ngIf="isFontAwsome(c.icon) != true"> {{ c.icon }}</mat-icon>
                    <mat-icon class="{{ c.icon }} core-icon-choice" *ngIf="isFontAwsome(c.icon) == true"></mat-icon>
                    {{ c.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <div class="margin-btm">
                <h3 class=" sr-title">HubSpot</h3>
              </div>
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>HubSpot Key</mat-label>
                <input [(ngModel)]="settings.hubSpotKey" name="hubSpotKey" type="text" matInput
                  placeholder="HubSpot Key" required />
              </mat-form-field>
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>HubSpot User ID</mat-label>
                <input [(ngModel)]="settings.hubSpotUserId" name="hubSpotUserId" type="text" matInput
                  placeholder="HubSpot User ID" required />
              </mat-form-field>

              <div class="margin-btm">
                <h3 class=" sr-title">EPremium</h3>
              </div>
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>EP server</mat-label>
                <input [(ngModel)]="EPServerlink" name="epServer" matInput placeholder="EP server"
                  pattern="^(http|https|ftp)\://([a-zA-Z0-9\.\-]+(\:[a-zA-Z0-9\.&amp;%\$\-]+)*@)*((25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9])\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[0-9])|localhost|([a-zA-Z0-9\-]+\.)*[a-zA-Z0-9\-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(\:[0-9]+)*(/($|[a-zA-Z0-9\.\,\?\'\\\+&amp;%\$#\=~_\-]+))*$"
                  required />
              </mat-form-field>
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>EC version</mat-label>
                <input [(ngModel)]="settings.ECversion" name="ecVersion" type="number" matInput placeholder="EC version"
                  minlength="1" required />
              </mat-form-field>

              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Message Host</mat-label>
                <input [(ngModel)]="settings.messageHost" name="messageHost" matInput placeholder="Message Host"
                  pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" required />
              </mat-form-field>
              <div class="margin-btm">
                <h3 class=" sr-title">Kleber</h3>
              </div>
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Kleber Host</mat-label>
                <input [(ngModel)]="settings.kleberHost" name="kleberHost" type="text" matInput
                  placeholder="Kleber Host" required />
              </mat-form-field>
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Kleber Web Service URL</mat-label>
                <input [(ngModel)]="settings.kleberWebServiceUrl" name="kleberWebService" type="text" matInput
                  placeholder="Kleber Web Service URL" required />
              </mat-form-field>
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Kleber Request Key</mat-label>
                <input [(ngModel)]="settings.kleverRequestKey" name="KleberRequestKey" type="text" matInput
                  placeholder="Kleber Request Key" required />
              </mat-form-field>

              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Vimeo Api Token</mat-label>
                <input [(ngModel)]="settings.vimeoApiToken" name="vimeoApiToken" matInput
                  placeholder="Vimeo Api Token" />
              </mat-form-field>

              <div class="margin-btm">
                <h3 class=" sr-title">
                  <mat-icon class="fab fa-facebook-f mr"></mat-icon>
                  Facebook API
                </h3>
              </div>

              <mat-form-field class="full-width" appearance="outline">
                <mat-label> Api ID</mat-label>
                <input [(ngModel)]="settings.facebookID" name="facebookID" matInput placeholder=" Api ID" />
              </mat-form-field>

              <mat-form-field class="full-width" appearance="outline">
                <mat-label> Client ID</mat-label>
                <input [(ngModel)]="settings.fbClientID" name="fbClientID" matInput placeholder=" Client ID" />
              </mat-form-field>

              <mat-form-field class="full-width mt" appearance="outline">
                <mat-label> Client Secrect</mat-label>
                <input [(ngModel)]="settings.fbClientSecrect" name="fbClientSecrect" matInput
                  placeholder=" Client Secrect" />
              </mat-form-field>

              <div class="margin-btm">
                <h3 class=" sr-title">
                  <mat-icon class="fab fa-google mr"></mat-icon>
                  Google API
                </h3>
              </div>

              <mat-form-field class="full-width" appearance="outline">
                <mat-label> Api ID</mat-label>
                <input [(ngModel)]="settings.googleID" name="googleID" matInput placeholder=" Api ID" />
              </mat-form-field>

              <mat-form-field class="full-width" appearance="outline">
                <mat-label> Client ID</mat-label>
                <input [(ngModel)]="settings.googleClientID" name="googleClientID" matInput placeholder=" Client ID" />
              </mat-form-field>

              <mat-form-field class="full-width" appearance="outline">
                <mat-label> Client Secret</mat-label>
                <input [(ngModel)]="settings.googleClientSecrect" name="googleClientSecrect" matInput
                  placeholder=" Client Secrect" />
              </mat-form-field>

              <div class="margin-btm">
                <h3 class=" sr-title">
                  <mat-icon class="fab fa-apple mr"></mat-icon>
                  Apple API
                </h3>
              </div>

              <mat-form-field class="full-width" appearance="outline">
                <mat-label> Api ID</mat-label>
                <input [(ngModel)]="settings.appleID" name="AppleID" matInput placeholder=" Api ID" />
              </mat-form-field>

              <mat-form-field class="full-width" appearance="outline">
                <mat-label> Client ID</mat-label>
                <input [(ngModel)]="settings.appleClientID" name="appleClientID" matInput placeholder=" Client ID" />
              </mat-form-field>

              <mat-form-field class="full-width" appearance="outline">
                <mat-label> Client Secrect</mat-label>
                <input [(ngModel)]="settings.appleClientSecrect" name="appleClientSecrect" matInput
                  placeholder=" Client Secret" />
              </mat-form-field>

              <div class="margin-btm">
                <h3 class=" sr-title">
                  <mat-icon class="fab fa-microsoft mr"></mat-icon>
                  Microsoft API
                </h3>
              </div>

              <mat-form-field class="full-width" appearance="outline">
                <mat-label> Api ID</mat-label>
                <input [(ngModel)]="settings.msID" name="msID" matInput placeholder=" Api ID" />
              </mat-form-field>

              <mat-form-field class="full-width" appearance="outline">
                <mat-label> Client ID</mat-label>
                <input [(ngModel)]="settings.msClientID" name="msClientID" matInput placeholder=" Client ID" />
              </mat-form-field>

              <mat-form-field class="full-width" appearance="outline">
                <mat-label> Client Secret</mat-label>
                <input [(ngModel)]="settings.msClientSecrect" name="msClientSecrect" matInput
                  placeholder=" Client Secret" />
              </mat-form-field>

              <div class="margin-btm">
                <h3 class=" sr-title">Twilio Account</h3>
              </div>

              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Twilio ID</mat-label>
                <input [(ngModel)]="settings.twilioID" name="twilioID" matInput placeholder="Twilio ID" />
              </mat-form-field>




              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Twilio Token</mat-label>
                <input [(ngModel)]="settings.twilioToken" name="twilioToken" matInput placeholder="Twilio Token" />
              </mat-form-field>


              <div class="margin-btm">
                <h3 class=" sr-title">Marketing Templates</h3>
              </div>

              <div class="full-width clearfix flex">
                <mat-form-field class="full-width mr" appearance="outline">
                  <mat-label>Template Types</mat-label>

                  <mat-select class="full-width margin-btm" [(ngModel)]="templateType" (ngModelChange)="changeType()"
                    name="templateType" placeholder="Template Types">
                    <mat-option [value]="'masterAndManual'"> Master Templates and Manual created</mat-option>

                    <mat-option [value]="'master'"> Master Templates only</mat-option>

                    <mat-option [value]="'manual'"> Manual created only</mat-option>
                  </mat-select>
                </mat-form-field>

                <div class="margin-btm full-width ml mt">
                  <mat-checkbox [(ngModel)]="settings.MarketingIsCampaign" name="currencySymbol" color="primary">
                    Is campaign
                  </mat-checkbox>
                </div>
              </div>


              <div class="margin-btm">
                <h3 class=" sr-title">Save Application logs</h3>

                <mat-slide-toggle class="clearfix " [(ngModel)]="settings['logActive']" name="logActive">
                  {{ settings['logActive'] === true ? 'Active' : 'Inactive' }}
                </mat-slide-toggle>




              </div>



            </div>
          </div>

          <hr class="mb" />
          <div class="clearfix full-width">
            <div class="margin-btm">
              <h3 class=" sr-title">Offline Message</h3>
            </div>

            <app-text-editor-input class="clearfix" [content]="settings.offlineMessage" [title]="''" [isPreview]="false"
              [isAction]="false" (getContent)="getContent($event)" name="editor">
            </app-text-editor-input>
          </div>
          <hr class="mb" />
          <div class="clearfix full-width text-center">
            <button class="btn-large" [disabled]="!communicationSettForm.form.valid"
              (click)="updateCommunicationSetting()" mat-raised-button color="accent">
              Save changes
            </button>
          </div>
        </div>
      </form>
    </mat-card>
  </mat-tab>



  <mat-tab *ngIf="isFeature('industry')" label="industry">
    <mat-card class="card">
      <mat-card-header>
        <p class="lead rm-mt">Industry settings</p>
      </mat-card-header>

      <form #featureSettForm="ngForm">
        <div class="row clearfix extra-mt">
          <div class="half-width">
            <div class="column">


              <div class="margin-btm">

                <div class="full-width   ">
                  <h3 class=" sr-title rm-mt">Bulk SMS </h3>
                  <div class="full-width flex ">

                    <div class="full-width mr">


                      <mat-slide-toggle class="clearfix smt" [(ngModel)]="settings['demoBulkSMS']" name="demoBulkSMS">
                        {{ settings['demoBulkSMS'] === true ? 'Demo mode Active' : 'Demo mode Inactive' }}
                      </mat-slide-toggle>
                    </div>
                    <div class="full-width mr">
                      <mat-form-field class="full-width" appearance="outline">
                        <mat-label>Delay (seconds)</mat-label>
                        <input type="number" [(ngModel)]="settings.messageGroupDelay" name="Delayseconds" matInput
                          placeholder="Delay (seconds)" />
                      </mat-form-field>

                    </div>

                    <div class="full-width">


                      <mat-form-field class="full-width" appearance="outline">
                        <mat-label>Chunk size</mat-label>
                        <input type="number" [(ngModel)]="settings.messageGroupChunkLength"
                          name="messageGroupChunkLength" matInput placeholder="Chunk size" />
                      </mat-form-field>


                    </div>


                  </div>

                </div>

              </div>

              <div class=" full-width margin-btm">


                <h3 class=" sr-title">Bulk Message Schedule Error Time</h3>

                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Minutes</mat-label>
                  <input type="number" [(ngModel)]="settings.scheduleErrorPeriod" name="scheduleErrorPeriod" matInput
                    placeholder="Minutes" />
                </mat-form-field>



              </div>


            </div>
          </div>
          <div class="half-width">
            <div class="column">



              <div class=" full-widthmargin-btm">
                <h3 class=" sr-title">Invitation Link on Final Step</h3>

                <mat-slide-toggle class="clearfix" [(ngModel)]="settings['invitationLinkOnStep']"
                  name="invitationLinkOnStep">{{ settings['invitationLinkOnStep'] === true ? 'Active' : 'Inactive' }}
                </mat-slide-toggle>
              </div>


              <div class=" full-width margin-btm mt">

                <h3 class=" sr-title">Settlement Material Advance </h3>

                <div class="full-width flex ">

                  <div class="full-width mr">
                    <mat-slide-toggle class="clearfix smt" [(ngModel)]="settings['materialRequestActive']"
                      name="materialRequestActive">
                      {{ settings['materialRequestActive'] === true ? 'Active' : 'Inactive' }}
                    </mat-slide-toggle>


                  </div>

                  <div class="full-width ">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label> Material Advance Percentage</mat-label>
                      <input type="number" [(ngModel)]="settings.materialRequestPercent" name="materialRequestPercent"
                        [max]="100" [min]="1" matInput placeholder=" Material Advance Percentage" />
                      <span matSuffix>%</span>
                    </mat-form-field>

                  </div>
                </div>








              </div>

            </div>
          </div>

          <hr class="mb" />
          <div class="clearfix full-width text-center">
            <button class="btn-large" [disabled]="!featureSettForm.form.valid" (click)="updateFeatures()"
              mat-raised-button color="accent">
              Save changes
            </button>
          </div>
        </div>
      </form>
    </mat-card>
  </mat-tab>

  <mat-tab *ngIf="isFeature('branding')" label="Branding">
    <mat-card class="card">
      <mat-card-header>
        <p class="lead rm-mt">Branding</p>
      </mat-card-header>
      <div class="row clearfix extra-mt">
        <div class="full-width">
          <div class="column">
            <!-- Logo upload -->
            <div class="row clearfix">
              <div class="half-width two-thrd-width">
                <label>Site Logo (selector class="site_logo")</label>
                <p *ngIf="settings.BrandingLogoImage">
                  <img class="image" [src]="getFileLink(settings.BrandingLogoImage)" />
                </p>
                <p>This is your company's logo, in PNG format.</p>
              </div>
              <div class="half-width">
                <app-file-uploader class="clearfix" class="text-center" [allowedExtensionTypes]="allowedExtensionTypes"
                  [uploadDirectly]="true" [isDragable]="true" [limit]="1"
                  (onCompleteAll)="onCompletebranding($event, 'BrandingLogoImage')"></app-file-uploader>
              </div>
            </div>
            <hr />
            <!-- Merchant Site Background -->
            <div class="row clearfix">
              <div class="pull-left two-thrd-width">
                <label>Merchant Site Background (selector class="site_background")</label>
                <p *ngIf="settings.BrandingBackgroundImageMerchant">
                  <img class="image" [src]="getFileLink(settings.BrandingBackgroundImageMerchant)" />
                </p>
                <p>
                  This is your website's background image. It needs to be a JPG file no more than 4mb in size, and
                  minimum 1920x1080px.
                </p>
              </div>
              <div class="pull-right">
                <app-file-uploader class="clearfix" class="text-center" [allowedExtensionTypes]="allowedExtensionTypes"
                  [uploadDirectly]="true" [isDragable]="true" [limit]="1"
                  (onCompleteAll)="onCompletebranding($event, 'BrandingBackgroundImageMerchant')">
                </app-file-uploader>
              </div>
            </div>
            <hr />
            <!-- Consumer Site Background -->
            <div class="row clearfix">
              <div class="pull-left two-thrd-width">
                <label>Consumer Site Background </label>
                <p *ngIf="settings.BrandingBackgroundImageConsumer">
                  <img class="image" [src]="getFileLink(settings.BrandingBackgroundImageConsumer)" />
                </p>
                <p>
                  This is your website's background image. It needs to be a JPG file no more than 4mb in size, and
                  minimum 1920x1080px.
                </p>
              </div>
              <div class="pull-right">
                <app-file-uploader class="clearfix" class="text-center" [allowedExtensionTypes]="allowedExtensionTypes"
                  [uploadDirectly]="true" [isDragable]="true" [limit]="1"
                  (onCompleteAll)="onCompletebranding($event, 'BrandingBackgroundImageConsumer')">
                </app-file-uploader>
              </div>
            </div>
            <hr />
            <!-- Site Favicon -->
            <div class="row clearfix">
              <div class="pull-left two-thrd-width">
                <label>Site Favicon</label>
                <p *ngIf="settings.BrandingIcon">
                  <img class="ico" [src]="getFileLink(settings.BrandingIcon)" />
                </p>
                <p>This is the small icon that appears next to your website's URL in the browser window.</p>
              </div>
              <div class="pull-right">
                <app-file-uploader class="clearfix" class="text-center" [allowedExtensionTypes]="allowedExtensionTypes"
                  [uploadDirectly]="true" [isDragable]="true" [limit]="1"
                  (onCompleteAll)="onCompletebranding($event, 'BrandingIcon')"></app-file-uploader>
              </div>
            </div>
          </div>
        </div>
        <hr class="mb" />
        <div class="clearfix full-width text-center">
          <button class="btn-large" (click)="updateBranding()" mat-raised-button color="accent">Save changes</button>
        </div>
        <div class="pull-left clearfix">
          <span class="history">
            <mat-icon>history</mat-icon>Data updated {{ settings.lastUpdated }} ago, By {{ settings.updatedByName }}
          </span>
        </div>
      </div>
    </mat-card>
  </mat-tab>
  <mat-tab *ngIf="isFeature('masterAccount')" label="Master Account">
    <mat-card class="card">
      <mat-card-header>
        <p class="lead rm-mt">Update EP Master Account</p>
      </mat-card-header>

      <form #communicationSettForm2="ngForm">
        <div class="row clearfix">
          <p class="small">
            Please Insert an Admin/Promoter account, this will also change the password of the choosen account
          </p>
          <p class="small">
            Please select a password that is at least 8 characters long, and has at least one number and one upper case
            letter.
          </p>

          <div class="row clearfix">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Master Username </mat-label>
              <input [(ngModel)]="masterUsername" matInput name="p11" placeholder="Master Username" required
                autocomplete="off" />
            </mat-form-field>
          </div>

          <div class="row clearfix">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>New Password</mat-label>
              <input [(ngModel)]="masterPassword" [min]="8" matInput name="p2" placeholder="New Password"
                type="password" pattern="^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,}$" required autocomplete="off" />
            </mat-form-field>
          </div>
          <div class="row clearfix">
            <p class="small rm-mt">Password strength</p>
            <password-strength-meter [password]="masterPassword"></password-strength-meter>
          </div>
          <div class="row clearfix">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Confirm New Password</mat-label>
              <input [(ngModel)]="masterPasswordConfirmed" [min]="8" matInput name="p3"
                placeholder="Confirm New Password" type="password" pattern="^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,}$"
                required autocomplete="off" />
            </mat-form-field>
          </div>

          <hr class="mb" />
          <div class="clearfix full-width text-center">
            <button class="" [disabled]="!communicationSettForm2.form.valid" (click)="updatePassword()"
              mat-raised-button color="accent">
              Change
            </button>
          </div>
        </div>
      </form>
    </mat-card>
  </mat-tab>
  <mat-tab *ngIf="isFeature('languages')" label="Language">
    <mat-card class="card">
      <mat-card-header>
        <p class="lead rm-mt">Languages</p>
      </mat-card-header>
      <form #languageForm="ngForm">
        <div class="row clearfix extra-mt">
          <div class="half-width">
            <div class="column">
              <mat-select class="full-width margin-btm" [(ngModel)]="settings.language" (ngModelChange)="selectLang()"
                name="language" placeholder="Current Language" required>
                <mat-label>Current Language</mat-label>
                <mat-option *ngFor="let lang of settings.availableLanguages" [value]="lang._id">
                  {{ lang.label }}
                </mat-option>
              </mat-select>
            </div>
          </div>
          <div class="half-width">
            <div class="column">
              <button class="addDocument pull-right" (click)="addLanguagePage()" mat-mini-fab color="primary">
                <mat-icon>add</mat-icon>
              </button>
            </div>
          </div>
          <hr class="mb" />
          <div class="clearfix full-width text-center">
            <button class="btn-large" [disabled]="!languageForm.form.valid" (click)="updateLanguages()"
              mat-raised-button color="accent">
              Save changes
            </button>
          </div>
        </div>
      </form>
    </mat-card>
  </mat-tab>
  <mat-tab *ngIf="isFeature('analytics')" label="Analytics">
    <mat-card class="card">
      <mat-card-header>
        <p class="lead rm-mt">Analytics</p>
      </mat-card-header>
      <form #languageForm="ngForm">
        <div class="row clearfix extra-mt">
          <div class="half-width">
            <div class="column">
              <h4 class=" sr-title">Google Tag Manager</h4>
              <p>
                Google Tag Manager allows you to maintain your analytics through a convenient central location. To
                utilise this service, please make sure you have:
              </p>
              <ul>
                <li>An active Google account</li>
                <li>
                  A
                  <a href="https://tagmanager.google.com/?hl=en#/" title="Google Tag Manager sign up"
                    target="_blank">Google Tag Manager</a>
                  account
                </li>
                <li>An active Google Tag Manager ID (eg. GTM-XXXXXXX)</li>
              </ul>
              <p>To proceed, enter your Google Tag Manager ID below.</p>
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Enter ID. Eg: GTM-XXXXXXX</mat-label>
                <input [(ngModel)]="analyticsID" name="code" matInput placeholder="Enter ID. Eg: GTM-XXXXXXX"
                  required />
              </mat-form-field>
            </div>
          </div>
          <hr class="mb" />
          <div class="clearfix full-width text-center">
            <button class="btn-large" (click)="updateAnalyticsID()" mat-raised-button color="accent">
              Save changes
            </button>
          </div>
        </div>
      </form>
    </mat-card>
  </mat-tab>

  <mat-tab *ngIf="isFeature('charts')" label="Charts Settings">
    <mat-card class="card clearfix">
      <mat-card-header>
        <p class="lead rm-mt">Charts Settings</p>
      </mat-card-header>
      <form #ChartsForm="ngForm">
        <div class="row full-width clearfix extra-mt">
          <div class="row full-width clearfix">
            <div class="qtr-width">
              <div class="column">
                <div class="margin-btm">
                  <mat-checkbox [(ngModel)]="settings.chartShowXAxis" name="chartShowXAxis" color="primary">
                    Show XAxis
                  </mat-checkbox>
                </div>
                <div class="margin-btm">
                  <mat-checkbox [(ngModel)]="settings.chartShowYAxis" name="chartShowYAxis" color="primary">
                    Show YAxis
                  </mat-checkbox>
                </div>
                <div class="margin-btm">
                  <mat-checkbox [(ngModel)]="settings.chartShowLegend" name="chartShowLegend" color="primary">
                    Show Legend
                  </mat-checkbox>
                </div>
                <div class="margin-btm">
                  <mat-checkbox [(ngModel)]="settings.chartGradient" name="chartGradient" color="primary">
                    Gradient
                  </mat-checkbox>
                </div>
              </div>
            </div>
            <div class="qtr-width">
              <div class="column">
                <div class="margin-btm">
                  <mat-checkbox [(ngModel)]="settings.chartShowXAxisLabel" name="chartShowXAxisLabel" color="primary">
                    Show XAxis label
                  </mat-checkbox>
                </div>
                <div class="margin-btm">
                  <mat-checkbox [(ngModel)]="settings.chartShowYAxisLabel" name="chartShowYAxisLabel" color="primary">
                    Show YAxis label
                  </mat-checkbox>
                </div>
                <div class="margin-btm">
                  <mat-checkbox [(ngModel)]="settings.chartShowGridLines" name="chartShowGridLines" color="primary">
                    Show Grid lines
                  </mat-checkbox>
                </div>
              </div>
            </div>
            <div class="qtr-width">
              <div class="column">
                <div class="margin-btm">
                  <mat-checkbox [(ngModel)]="settings.chartAutoScale" name="chartAutoScale" color="primary">
                    Auto scale
                  </mat-checkbox>
                </div>
                <div class="margin-btm">
                  <mat-checkbox [(ngModel)]="settings.chartShowDoughnut" name="chartShowDoughnut" color="primary">
                    Doughnub chart
                  </mat-checkbox>
                </div>
                <div class="margin-btm">
                  <mat-checkbox [(ngModel)]="settings.chartShowTrimLabels" name="chartShowTrimLabels" color="primary">
                    Show Trim labels
                  </mat-checkbox>
                </div>
              </div>
            </div>
            <div class="qtr-width">
              <div class="column">
                <div class="margin-btm">
                  <mat-checkbox [(ngModel)]="settings.chartShowTimeline" name="chartShowTimeline" color="primary">
                    Show Time line
                  </mat-checkbox>
                </div>
                <div class="margin-btm">
                  <mat-checkbox [(ngModel)]="settings.chartShowLabel" name="chartShowLabel" color="primary">
                    Show Pie Label
                  </mat-checkbox>
                </div>
                <div class="margin-btm">
                  <mat-checkbox [(ngModel)]="settings.chartShowRefLabels" name="chartShowRefLabels" color="primary">
                    Show Ref labels
                  </mat-checkbox>
                </div>
              </div>
            </div>
          </div>
          <div class="row clearfix full-width">
            <div class="half-width">
              <div class="column">
                <div class="well">
                  <app-chart-input class="row" [showLabel]="settings.chartShowLabel" [gradient]="settings.chartGradient"
                    [showXAxis]="settings['chartShowXAxis']" [showYAxis]="settings['chartShowYAxis']"
                    [showLegend]="settings['chartShowLegend']" [showXAxisLabel]="settings['chartShowXAxisLabel']"
                    [showYAxisLabel]="settings['chartShowYAxisLabel']" [roundDomains]="settings['chartRoundDomains']"
                    [showGridLines]="settings['chartShowGridLines']" [barPadding]="settings['chartBarPadding']"
                    [showDoughnut]="settings['chartShowDoughnut']" [showTrimLabels]="settings['chartShowTrimLabels']"
                    [showTimeline]="settings['chartShowTimeline']" [showRefLines]="settings['chartShowRefLines']"
                    [showRefLabels]="settings['chartShowRefLabels']" [autoScale]="settings['chartAutoScale']"
                    [data]="simpleData" [colorScheme]="colorChartsVariables" [chartType]="'barHorizontal'"
                    [title]="'Horizontal bar chart'"></app-chart-input>
                </div>
              </div>
            </div>
            <div class="half-width">
              <div class="column">
                <div class="well">
                  <app-chart-input class="row" [showLabel]="settings.chartShowLabel" [gradient]="settings.chartGradient"
                    [showXAxis]="settings['chartShowXAxis']" [showYAxis]="settings['chartShowYAxis']"
                    [showLegend]="settings['chartShowLegend']" [showXAxisLabel]="settings['chartShowXAxisLabel']"
                    [showYAxisLabel]="settings['chartShowYAxisLabel']" [roundDomains]="settings['chartRoundDomains']"
                    [showGridLines]="settings['chartShowGridLines']" [barPadding]="settings['chartBarPadding']"
                    [showDoughnut]="settings['chartShowDoughnut']" [showTrimLabels]="settings['chartShowTrimLabels']"
                    [showTimeline]="settings['chartShowTimeline']" [showRefLines]="settings['chartShowRefLines']"
                    [showRefLabels]="settings['chartShowRefLabels']" [autoScale]="settings['chartAutoScale']"
                    [data]="simpleData" [colorScheme]="colorChartsVariables" [chartType]="'pie'" [title]="'Pie chart'">
                  </app-chart-input>
                </div>
              </div>
            </div>
          </div>
          <div class="row clearfix full-width">
            <div class="half-width">
              <div class="column">
                <div class="well">
                  <app-chart-input class="row" [showLabel]="settings.chartShowLabel" [gradient]="settings.chartGradient"
                    [showXAxis]="settings['chartShowXAxis']" [showYAxis]="settings['chartShowYAxis']"
                    [showLegend]="settings['chartShowLegend']" [showXAxisLabel]="settings['chartShowXAxisLabel']"
                    [showYAxisLabel]="settings['chartShowYAxisLabel']" [roundDomains]="settings['chartRoundDomains']"
                    [showGridLines]="settings['chartShowGridLines']" [barPadding]="settings['chartBarPadding']"
                    [showDoughnut]="settings['chartShowDoughnut']" [showTrimLabels]="settings['chartShowTrimLabels']"
                    [showTimeline]="settings['chartShowTimeline']" [showRefLines]="settings['chartShowRefLines']"
                    [showRefLabels]="settings['chartShowRefLabels']" [autoScale]="settings['chartAutoScale']"
                    [data]="AdvancedData" [colorScheme]="colorChartsVariables" [chartType]="'barVerticalNormalized'"
                    [title]="'Vertical normalized bar chart'"></app-chart-input>
                </div>
              </div>
            </div>
            <div class="half-width">
              <div class="column">
                <div class="well">
                  <app-chart-input class="row" [showLabel]="settings.chartShowLabel" [gradient]="settings.chartGradient"
                    [showXAxis]="settings['chartShowXAxis']" [showYAxis]="settings['chartShowYAxis']"
                    [showLegend]="settings['chartShowLegend']" [showXAxisLabel]="settings['chartShowXAxisLabel']"
                    [showYAxisLabel]="settings['chartShowYAxisLabel']" [roundDomains]="settings['chartRoundDomains']"
                    [showGridLines]="settings['chartShowGridLines']" [barPadding]="settings['chartBarPadding']"
                    [showDoughnut]="settings['chartShowDoughnut']" [showTrimLabels]="settings['chartShowTrimLabels']"
                    [showTimeline]="settings['chartShowTimeline']" [showRefLines]="settings['chartShowRefLines']"
                    [showRefLabels]="settings['chartShowRefLabels']" [autoScale]="settings['chartAutoScale']"
                    [data]="lineData" [colorScheme]="colorChartsVariables" [chartType]="'area'" [title]="'Line chart'">
                  </app-chart-input>
                </div>
              </div>
            </div>
          </div>
          <div class="row clearfix full-width">
            <div class="half-width">
              <div class="column">
                <div class="well">
                  <app-chart-input class="row" [showLabel]="settings.chartShowLabel" [gradient]="settings.chartGradient"
                    [showXAxis]="settings['chartShowXAxis']" [showYAxis]="settings['chartShowYAxis']"
                    [showLegend]="settings['chartShowLegend']" [showXAxisLabel]="settings['chartShowXAxisLabel']"
                    [showYAxisLabel]="settings['chartShowYAxisLabel']" [roundDomains]="settings['chartRoundDomains']"
                    [showGridLines]="settings['chartShowGridLines']" [barPadding]="settings['chartBarPadding']"
                    [showDoughnut]="settings['chartShowDoughnut']" [showTrimLabels]="settings['chartShowTrimLabels']"
                    [showTimeline]="settings['chartShowTimeline']" [showRefLines]="settings['chartShowRefLines']"
                    [showRefLabels]="settings['chartShowRefLabels']" [autoScale]="settings['chartAutoScale']"
                    [data]="simpleData2" [colorScheme]="colorChartsVariables" [chartType]="'pieGrid'"
                    [title]="'Grid Pie chart'"></app-chart-input>
                </div>
              </div>
            </div>
            <div class="half-width">
              <div class="column">
                <div class="well">
                  <app-chart-input class="row" [showLabel]="settings.chartShowLabel" [gradient]="settings.chartGradient"
                    [showXAxis]="settings['chartShowXAxis']" [showYAxis]="settings['chartShowYAxis']"
                    [showLegend]="settings['chartShowLegend']" [showXAxisLabel]="settings['chartShowXAxisLabel']"
                    [showYAxisLabel]="settings['chartShowYAxisLabel']" [roundDomains]="settings['chartRoundDomains']"
                    [showGridLines]="settings['chartShowGridLines']" [barPadding]="settings['chartBarPadding']"
                    [showDoughnut]="settings['chartShowDoughnut']" [showTrimLabels]="settings['chartShowTrimLabels']"
                    [showTimeline]="settings['chartShowTimeline']" [showRefLines]="settings['chartShowRefLines']"
                    [showRefLabels]="settings['chartShowRefLabels']" [autoScale]="settings['chartAutoScale']"
                    [data]="simpleData" [colorScheme]="colorChartsVariables" [chartType]="'pieAdvanced'"
                    [title]="'Advanced Pie chart'"></app-chart-input>
                </div>
              </div>
            </div>
          </div>
          <hr class="mb" />
          <div class="clearfix full-width text-center">
            <button class="btn-large" [disabled]="!ChartsForm.form.valid" (click)="updateCharts()" mat-raised-button
              color="accent">
              Save changes
            </button>
          </div>
        </div>
      </form>
    </mat-card>
  </mat-tab>
</mat-tab-group>
