import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Optional, Output, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatAutocomplete, MatAutocompleteSelectedEvent, MatChipInputEvent } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { WikiService } from '../shared/wiki.service';
import { WikiLeftMenuComponent } from '../wiki-left-menu/wiki-left-menu.component';

import { ENTER } from '@angular/cdk/keycodes';
import { environment } from 'environments/environment';
import * as _ from 'lodash';
import { DocumentUrlViewComponent } from '../../../shared/components/document-url-view/document-url-view.component';
import { EpDocumentViewComponent } from '../../../shared/components/ep-document-view/ep-document-view.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { AuthenticationService } from '../../authentication/shared/authentication.service';

@Component({
  selector: 'app-wiki-create-edit',
  templateUrl: './wiki-create-edit.component.html',
  styleUrls: ['./wiki-create-edit.component.css'],
})
export class WikiCreateEditComponent implements OnInit {
  @Input()
  wiki;

  @Input()
  wikiID;

  @Input()
  wikiTags = [];

  @Input()
  title;

  @Input()
  tags = [];

  @Input()
  code;

  @Input()
  references = [];

  @Input()
  roles = ['merchant'];

  @Input()
  groupRoles = ['merchant'];

  @Input()
  groupID;

  @Input()
  isActive = true;

  isModal = false;

  @Input()
  groups = [];
  allowedExtensionTypes = ['image'];
  isDone = false;
  groupTitle;
  groupIcon;
  groupFileID;
  groupMenu = 'all';
  groupOrderBy = 'priority';
  groupCode;
  fileID;
  groupDescription;
  summary;
  content;
  _content;

  groupFileSrc;
  pageFileSrc;
  sessionType = 'guest';

  isEditMode = false;

  createNewGroup = true;

  isMenuDisplayed = true;
  referenceLabel;
  referenceLink;

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER];
  tagCtrl;

  views;
  createdDate;
  sessionTypeParams;

  utils = new UtilsClass();

  @ViewChild('tagInput', { static: false }) tagInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', { static: false }) matAutocomplete: MatAutocomplete;

  @Output() create = new EventEmitter();
  @Output() closeModal = new EventEmitter();

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private router: Router,
    public authenticationService: AuthenticationService,
    private activeRoute: ActivatedRoute,
    private wikiService: WikiService
  ) {
    if (data) {
      if (data.wikiTags && data.wikiTags.length > 0) {
        this.wikiTags = _.without(data.wikiTags, '[object Object]');

        this.isModal = true;
      }

      if (data.code) {
        this.code = data.code;
        this.isModal = true;
      }

      if (data.wikiID) {
        this.wikiID = data.wikiID;

        this.isModal = true;
      }
    }
  }

  ngOnInit() {
    const _res = this.utils.getSessionTypeParams(this.router.url);

    if (_res) {
      this.sessionTypeParams = _res;
    }

    this.activeRoute.params.subscribe((params) => {
      if (params['wikiID']) {
        this.wikiID = params['wikiID'];
      }

      if (params['code']) {
        this.code = params['code'];
      }

      this.authenticationService.getSessionType().subscribe((res) => {
        if (res) {
          this.sessionType = res;
        }

        if (this.wikiID || this.code) {
          this.isEditMode = true;
        }

        this.setup();
      });
    });
  }

  setup() {
    this.wikiService.getWikiTagList().subscribe((res) => {
      if (res) {
        this.wikiTags = _.without(res, '[object Object]');
      }

      this.wikiService.getWikiGroupList({}, this.sessionType).subscribe((res2) => {
        if (res2 && res2.length > 0) {
          this.groups = res2;

          this.createNewGroup = false;

          if (this.isEditMode == true && (this.wikiID || this.code)) {
            const p = {
              selectNext: false,
              selectPrevious: false,
            };
            this.wikiService.getWikiDetails(this.wikiID, this.code, p, this.sessionType).subscribe((res) => {
              if (res) {
                if (res.roles && res.roles.length > 0) {
                  this.roles = res.roles;
                }

                if (res.references && res.references.length > 0) {
                  this.references = res.references;
                }

                if (res.tags && res.tags.length > 0) {
                  this.tags = res.tags;
                }

                if (res.code) {
                  this.code = res.code;
                }

                if (res.title) {
                  this.title = res.title;
                }

                if (res.isActive != null) {
                  this.isActive = res.isActive;
                } else {
                  this.isActive = false;
                }

                if (res.summary) {
                  this.summary = res.summary;
                }

                if (res.content) {
                  this._content = res.content;
                }

                if (res.group && res.group._id) {
                  this.groupID = res.group._id;
                }

                if (res.views) {
                  this.views = res.views;
                }

                if (res.createdDate) {
                  this.createdDate = res.createdDate;
                }

                if (res.fileID) {
                  this.fileID = res.fileID;

                  this.pageFileSrc = `${environment.nodeUrl}/files/file-view/${this.fileID}`;
                }

                if (res.isMenuDisplayed != null) {
                  this.isMenuDisplayed = res.isMenuDisplayed;
                } else {
                  this.isMenuDisplayed = false;
                }
              }
            });
          }
        }
      });
    });
  }

  buildCode() {
    if (this.title) {
      this.code = this.title.replace(/\s+/g, '-').toLowerCase();
    }
  }

  buildCodeGroup() {
    if (this.groupTitle) {
      this.groupCode = this.groupTitle.replace(/\s+/g, '-').toLowerCase();
    }
  }

  closeEvent() {
    this.closeModal.emit(true);
  }

  isRoleActive(role) {
    if (role && this.roles && this.roles.length > 0 && this.roles.indexOf(role) != -1) {
      return true;
    }

    return false;
  }

  isGroupRoleActive(role) {
    if (role && this.groupRoles && this.groupRoles.length > 0 && this.groupRoles.indexOf(role) != -1) {
      return true;
    }

    return false;
  }

  sliderChangeGroupRole(value, target) {
    if (value && value.checked == true && this.groupRoles && this.groupRoles.indexOf(target) == -1) {
      this.groupRoles.push(target);
    } else if (value && value.checked == false) {
      const index = this.groupRoles.indexOf(target);

      if (index != -1) {
        this.groupRoles.splice(index, 1);
      }
    }
  }

  sliderChangeRole(value, target) {
    if (value && value.checked == true && this.roles && this.roles.indexOf(target) == -1) {
      this.roles.push(target);

      if (this.groupRoles && this.groupRoles.indexOf(target) == -1) {
        this.groupRoles.push(target);
      }
    } else if (value && value.checked == false) {
      const index = this.roles.indexOf(target);

      if (index != -1) {
        this.roles.splice(index, 1);
      }

      const indexGroup = this.groupRoles.indexOf(target);

      if (indexGroup != -1) {
        this.groupRoles.splice(indexGroup, 1);
      }
    }
  }

  addReference() {
    if (this.referenceLabel && this.referenceLink) {
      const p = {
        label: this.referenceLabel,
        link: this.referenceLink,
      };
      this.references.push(p);

      this.referenceLabel = null;
      this.referenceLink = null;
    }
  }

  removeReference(index) {
    if (this.references && this.references.length > 0 && this.references[index]) {
      this.references.splice(index, 1);
    }
  }

  getContent(c) {
    if (c) {
      this.content = c;
    }
  }

  saveWiki() {
    const payload = {
      title: this.title,
      summary: this.summary,
    };

    if (this.roles && this.roles.length) {
      payload['roles'] = this.roles.join(',');
    }

    if (this.tags && this.tags.length) {
      const tags = [];

      for (let i = 0; i < this.tags.length; i++) {
        if (typeof this.tags[i] === 'string') {
          tags.push(this.tags[i]);
        } else if (this.tags[i] && this.tags[i]['value']) {
          tags.push(this.tags[i]['value']);
        }
      }

      payload['tags'] = tags.join(',');
    }

    if (this.references && this.references.length) {
      payload['references'] = this.references;
    }

    if (this.createNewGroup == true) {
      payload['groupTitle'] = this.groupTitle;
      payload['groupIcon'] = this.groupIcon;
      payload['groupFileID'] = this.groupFileID;
      payload['groupMenu'] = this.groupMenu;
      payload['groupOrderBy'] = this.groupOrderBy;
      payload['groupCode'] = this.groupCode;

      payload['groupDescription'] = this.groupDescription;
      if (this.groupRoles && this.groupRoles.length) {
        payload['groupRoles'] = this.roles.join(',');
      }
    }
    if (this.createNewGroup != true && this.groupID) {
      payload['group'] = this.groupID;
    }

    if (this.isActive != null) {
      payload['isActive'] = this.isActive;
    } else {
      payload['isActive'] = false;
    }
    if (this.content) {
      payload['content'] = this.content;
    }

    if (this.code) {
      payload['code'] = this.code;
    }

    if (this.fileID) {
      payload['fileID'] = this.fileID;
    }

    if (this.isMenuDisplayed) {
      payload['isMenuDisplayed'] = this.isMenuDisplayed;
    }

    if (this.isEditMode == true && this.wikiID) {
      this.wikiService.updateWiki(this.wikiID, payload).subscribe((res) => {
        if (res) {
          WikiLeftMenuComponent.updateHeader.emit(true);
          this.isDone = true;
          this.wiki = res;

          NotifyAppComponent.displayToast('success', 'Successfull operation', 'Wiki Article is Updated ');
        }
      });
    } else {
      this.wikiService.createWiki(payload).subscribe((res) => {
        if (res) {
          WikiLeftMenuComponent.updateHeader.emit(true);

          this.isDone = true;
          this.wiki = res;
          NotifyAppComponent.displayToast('success', 'Successfull operation', 'Wiki Article is created ');
        }
      });
    }
  }

  goCategory() {
    if (this.wiki && this.wiki.group && typeof this.wiki.group == 'string') {
      if (this.sessionTypeParams) {
        this.router.navigate([
          '/wiki-' + this.sessionTypeParams,
          { outlets: { wikiPageRoute: ['group', this.wiki.group] } },
        ]);
      } else {
        this.router.navigate(['/wiki', { outlets: { wikiPageRoute: ['group', this.wiki.group] } }]);
      }
    } else if (this.wiki && this.wiki.group && typeof this.wiki.group == 'object') {
      if (this.wiki.group.code) {
        if (this.sessionTypeParams) {
          this.router.navigate([
            '/wiki-' + this.sessionTypeParams,
            { outlets: { wikiPageRoute: ['group-code', this.wiki.group.code] } },
          ]);
        } else {
          this.router.navigate(['/wiki', { outlets: { wikiPageRoute: ['group-code', this.wiki.group.code] } }]);
        }
      } else if (this.wiki.group._id) {
        if (this.sessionTypeParams) {
          this.router.navigate([
            '/wiki-' + this.sessionTypeParams,
            { outlets: { wikiPageRoute: ['group', this.wiki.group._id] } },
          ]);
        } else {
          this.router.navigate(['/wiki', { outlets: { wikiPageRoute: ['group', this.wiki.group._id] } }]);
        }
      }
    }
  }

  createPage() {
    if (this.sessionTypeParams) {
      this.router.navigate([
        '/wiki-' + this.sessionTypeParams,
        {
          outlets: {
            wikiPageRoute: ['redirection', 'create'],
          },
        },
      ]);
    } else {
      this.router.navigate([
        '/wiki',
        {
          outlets: {
            wikiPageRoute: ['redirection', 'create'],
          },
        },
      ]);
    }
  }

  viewPage() {
    if (this.wiki && this.wiki._id) {
      if (this.sessionTypeParams) {
        this.router.navigate([
          '/wiki-' + this.sessionTypeParams,
          { outlets: { wikiPageRoute: ['view', this.wiki._id] } },
        ]);
      } else {
        this.router.navigate(['/wiki', { outlets: { wikiPageRoute: ['view', this.wiki._id] } }]);
      }
    }
  }

  goHome() {
    if (this.wiki && this.wiki._id) {
      if (this.sessionTypeParams) {
        this.router.navigate(['/wiki-' + this.sessionTypeParams]);
      } else {
        this.router.navigate([
          '/wiki',
          {
            outlets: {
              wikiPageRoute: ['home'],
            },
          },
        ]);
      }
    }
  }

  redirectLink(label, link) {
    if (link) {
      const extension = link.split('.').pop();

      if (link && link.indexOf('@EP:') != -1) {
        const id = link.replace('@EP:', '');

        const ref = RootAppComponent.dialog.open(EpDocumentViewComponent, {
          data: {
            isDownload: false,
            isPrint: false,
            isRedirection: false,
            documentID: id,
            title: label,
            description: 'Wiki Document: ' + this.title,
          },
          width: '800px',
          panelClass: 'noCard',
        });

        ref.componentInstance.closeModal.subscribe((res) => {
          ref.close();
        });

        ref.componentInstance.noFileFound.subscribe((res) => {
          NotifyAppComponent.displayToast('warning', 'Sorry!', 'We could not get the requested file(s)');
        });
      } else if (
        link.indexOf('vimeo.com') != -1 ||
        link.indexOf('youtube.com') != -1 ||
        String(extension).toLocaleLowerCase() == 'heic' ||
        String(extension).toLocaleLowerCase() == 'png' ||
        String(extension).toLocaleLowerCase() == 'gif' ||
        String(extension).toLocaleLowerCase() == 'jpg' ||
        String(extension).toLocaleLowerCase() == 'jpeg' ||
        String(extension).toLocaleLowerCase() == 'pdf'
      ) {
        const ref = RootAppComponent.dialog.open(DocumentUrlViewComponent, {
          data: {
            title: 'Wiki Document: ' + this.title,
            description: '',
            documentUrl: link,
          },
          width: '800px',
          panelClass: 'noCard',
        });
        const sub = ref.componentInstance.closeModal.subscribe((data) => {
          ref.close();
        });
      } else {
        window.open(link, '_blank');
      }
    }
  }

  addTag(event: MatChipInputEvent): void {
    const input = event.input;
    let value = event.value;

    if (value) {
      value = value.trim();
      const index = this.tags.indexOf(value);

      if (index == -1 && typeof value == 'string') {
        this.tags.push(value);
      }
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
    this.tagCtrl = null;
  }

  removeTag(fruit: string): void {
    const index = this.tags.indexOf(fruit);

    if (index >= 0) {
      this.tags.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    if (event && event.option && event.option.viewValue) {
      const v = event.option.viewValue;
      const index = this.tags.indexOf(v);

      if (index == -1 && typeof v == 'string') {
        this.tags.push(v);
      }
      this.tagInput.nativeElement.value = '';
      this.tagCtrl = null;
    }
  }

  tagChange(t) {
    if (t) {
      const index = this.tags.indexOf(t);

      if (index == -1 && typeof t == 'string') {
        this.tags.push(t);
      }

      this.tagCtrl = null;
    }
  }

  onCompletePageAll(fileID) {
    if (fileID && fileID[0]) {
      this.fileID = fileID[0];
      this.pageFileSrc = `${environment.nodeUrl}/files/file-view/${this.fileID}`;
    }
  }

  onCompleteCategoryAll(fileID) {
    if (fileID && fileID[0]) {
      this.groupFileID = fileID[0];
      this.groupFileSrc = `${environment.nodeUrl}/files/file-view/${this.groupFileID}`;
    }
  }
}
