<mat-card style="padding-bottom: 0px">
  <div class="row clearfix cardheader primary-gradient-img inModal" *ngIf="isModal">
    <!-- close button -->
    <button class="btn-close btn-clear white pull-right mat-button" (click)="closeEvent()" mat-dialog-close>
      <mat-icon>clear</mat-icon>
    </button>
    <div class="row titleArea clearfix">
      <h2 class="summary-header rm-m white">
        {{ title }}
        <span class="subLabel">{{ description }}</span>
      </h2>
    </div>
  </div>

  <div class="row clearfix" *ngIf="!isModal">
    <div class="pull-left">
      <label class="uploaded">{{ title }}</label>
      <p class="detail-p" *ngIf="description">
        {{ description }}
      </p>
    </div>
  </div>

  <mat-dialog-content>
    <div
      class="row clearfix thumb-view document-thumbnail-view"
      *ngIf="imageLinks && imageLinks.length > 0; else emptyRecord"
    >
      <div class="flex clearfix full-width uploadedContainer" [class.justify-left]="justify == 'left'">
        <ng-container *ngFor="let image of _imageLinks">
          <mat-card class="text-center" @simpleFadeAnimation>
            <div class="rel" mat-card-image>
              <!-- <mat-checkbox
                *ngIf="canCheck"
                class="checkbox-image"
                [(ngModel)]="image.checked"
              >
              </mat-checkbox> -->
              <app-ep-document-view-image
                class="buttonImage"
                [imageLink]="image.link"
                [notChecked]="!image.checked"
                (getClick)="image.checked = !image.checked"
              >
              </app-ep-document-view-image>
            </div>

            <!-- <button
              mat-mini-fab
              class="fullsize-thumb-click"
              color="accent"
              (click)="viewImage(image)"
              matTooltip="Preview image"
            >
              <mat-icon>search</mat-icon>
            </button> -->

            <button
              class="download-image-btn"
              *ngIf="canDownload"
              (click)="downloadImage(image)"
              mat-raised-button
              color="accent"
              matTooltip="Download image"
            >
              Download
            </button>
          </mat-card>
        </ng-container>
      </div>
    </div>
    <ng-template #emptyRecord>
      <app-empty-list-message></app-empty-list-message>
    </ng-template>
  </mat-dialog-content>
  <mat-dialog-actions *ngIf="isModal || canAction">
    <div class="clearfix full-width text-center">
      <button class="mr" (click)="closeEvent()" mat-raised-button color="primary">Close</button>

      <!-- <button
        mat-raised-button
        *ngIf="canMultipleDowload"
        class=""
        color="accent"
        (click)="multipleActionEvent()"
      >
        {{ multipleActionButtonLabel }}
      </button> -->
    </div>
  </mat-dialog-actions>
</mat-card>
