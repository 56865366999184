import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../../src/environments/environment';
import { UtilsService } from './utils.service';
import * as i0 from "@angular/core";
import * as i1 from "./utils.service";
import * as i2 from "@angular/common/http";
export class IndustrySettings {
}
export class RawIndustrySettings {
}
export class IndustrySettingsService {
    constructor(utilsService, httpClient) {
        this.utilsService = utilsService;
        this.httpClient = httpClient;
        this.baseUrl = environment.nodeUrl;
    }
    fetchIndustrySettings$() {
        const params = this.utilsService.getHttpParamsFromPayload({});
        const options = { params };
        const endpoint = 'industry-settings';
        const url = `${this.baseUrl}/${endpoint}`;
        return this.httpClient.get(url, options).pipe(map((response) => {
            const data = response['data'];
            const industrySettings = {
                industryType: data.Type_Of_Industry,
                industryProfileTable: data.Which_Profile_Table,
            };
            return industrySettings;
        }));
    }
}
IndustrySettingsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function IndustrySettingsService_Factory() { return new IndustrySettingsService(i0.ɵɵinject(i1.UtilsService), i0.ɵɵinject(i2.HttpClient)); }, token: IndustrySettingsService, providedIn: "root" });
