<div class="rel text-center cdia">
  <div class="card-header primary-gradient-img clearfix confirmModal" >
    <button class="btn-close btn-clear mat-button smt white custom-close" matDialogClose>
      <mat-icon>clear</mat-icon>
    </button>
    <div class="full-width flex main-title-container">
      <div class="main-icon-container text-center">
        <mat-icon class="main-icon accent-color fas fa-mobile">
        </mat-icon>
      </div>
      <div class="row full-width title-container clearfix">
        <div class="title-container-sub">
          <h2 class="text-left title-header rm-m">
            {{'Patient' | translate}} SMS
          </h2>
        </div>
      </div>
    </div>
  </div>

  <mat-dialog-content>
    <div class="row clearfix mb">

      <p class="text-left">

        <strong> ALL MESSAGES</strong> - Display team and system-generated messages (invites, reminders etc.).
      </p>


      <p class="text-left">

        <strong> TEAM MESSAGES </strong>- Display only messages exchanged between team members and
        {{'Patient' | translate}}s.

      </p>
    </div>

  </mat-dialog-content>

  <div class="drop-button text-center white-background">
    <hr />
    <button mat-raised-button matDialogClose class="pull-left">Close</button>
    <button (click)=" onYes()" mat-raised-button class="pull-right" color="accent">
      Team messages
    </button>
    <button (click)="onYesSecond()" mat-raised-button class="pull-right mr" color="primary">All messages</button>


  </div>


</div>
