<aw-wizard #wizard navBarLayout="large-filled-symbols">
  <aw-wizard-step [navigationSymbol]="{ symbol: '1' }" stepTitle="Contact Details">
    <div class="container">
      <div class="row clearfix">
        <div class="full-width rel">
          <div class="text-center">
            <h2 class="sr-title">
              <span *ngIf="isEdit == false">New Contact for {{ title }}</span>
              <span *ngIf="isEdit == true">Edit Contact for {{ title }}</span>
            </h2>
          </div>
        </div>
      </div>

      <form #createForm="ngForm">
        <div class="row clearfix flex">
          <div class="qtr-width">
            <div class="col-left">
              <mat-card class="mat-card uploadBlock card">
                <!--  Picture Upload -->
                <div class="row clearfix image">
                  <div class="notUploaded" *ngIf="isFile == false && isEdit == false">
                    <app-ep-document-view-image
                      class="clearfix full-width notYetUploaded"
                      [defaultPicture]="
                        'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/cloud-upload-alt-solid.png)'
                      "
                    >
                    </app-ep-document-view-image>
                  </div>

                  <div class="displayUploadImg text-center" *ngIf="isFile == false && isEdit == true">
                    <app-ep-document-view-image
                      class="clearfix full-width profile-pic-container"
                      [defaultPicture]="
                        'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/cloud-upload-alt-solid.png)'
                      "
                      [link]="profileLink"
                      [hideZoomButton]="false"
                    >
                    </app-ep-document-view-image>
                  </div>

                  <div class="displayUploadImg" *ngIf="isFile == true">
                    <img class="display-image-uploaded" [src]="src"/>
                  </div>
                </div>
                <div class="row clearfix">
                  <h4 class="sr-title sm-h4 rm-mb">Upload contact profile picture</h4>
                  <p class="small rm-m">
                    To upload a new contact profile picture, please drag your <strong>jpg or png file </strong> into the
                    grey area below.
                  </p>
                  <app-file-uploader
                    class="text-center clearfix full-width"
                    [allowedExtensionTypes]="['image']"
                    [isFixedAspectRatio]="true"
                    [isImageCropping]="true"
                    [canSkip]="false"
                    [limit]="1"
                    [uploadDirectly]="true"
                    [isDragable]="true"
                    (onCompleteAll)="onCompleteAll($event)"
                  >
                  </app-file-uploader>
                </div>
              </mat-card>
            </div>
          </div>

          <mat-card class="thr-qtr-width card">
            <div class="row clearfix">
              <h4></h4>

              <div class="row clearfix flex full-width">
                <div class="icons-col">
                  <mat-icon>person</mat-icon>
                </div>
                <div class="row-content-col">
                  <div class="row clearfix flex">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Contact Type</mat-label>
                      <mat-select
                        class="full-width"
                        [(ngModel)]="contact.contactTypeCode"
                        placeholder="Contact Type"
                        name="ContactType"
                        required
                      >
                        <mat-option *ngFor="let c of types" [value]="c.Code">{{ c.Label }} </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <mat-form-field
                      class="full-width"
                      *ngIf="contact.contactTypeCode == 'NOK' || contact.contactTypeCode == 'REL'"
                      appearance="outline"
                    >
                      <mat-label>Relative Type</mat-label>
                      <mat-select
                        class="full-width"
                        [(ngModel)]="contact.relativeTypeCode"
                        placeholder="Relative Type"
                        name="RelativeType"
                        required
                      >
                        <mat-option *ngFor="let r of relativeTypes" [value]="r.Code">{{ r.Label }} </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div class="row clearfix flex mt">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Gender</mat-label>
                      <mat-select
                        class="full-width"
                        [(ngModel)]="contact.gender"
                        placeholder="Gender"
                        name="Gender"
                        required
                      >
                        <mat-option *ngFor="let g of genders" [value]="g.Code">{{ g.Label }} </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Title</mat-label>
                      <mat-select
                        class="full-width"
                        [(ngModel)]="contact.title"
                        placeholder="Title"
                        name="Title"
                        required
                      >
                        <mat-option *ngFor="let title of titles" [value]="title.Label">{{ title.Label }} </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div class="row clearfix flex mt">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>First Name</mat-label>
                      <input
                        class="mat-block"
                        [(ngModel)]="contact.firstName"
                        matInput
                        [placeholder]="('PLACEHOLDER.First Name' | translate)"
                        name="Firstname"
                        required
                      />
                    </mat-form-field>
                    <!-- Middle Name -->
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label> Middle Name</mat-label>
                      <input
                        class="mat-block"
                        [(ngModel)]="contact.midleName"
                        matInput
                        [placeholder]="('PLACEHOLDER.Middle Name' | translate)"
                        name="Middlename"
                      />
                    </mat-form-field>
                    <!-- Surname -->
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label> Surname</mat-label>
                      <input
                        class="mat-block"
                        [(ngModel)]="contact.lastName"
                        matInput
                        [placeholder]="('PLACEHOLDER.Surname' | translate)"
                        name="Lastname"
                        required
                      />
                    </mat-form-field>
                  </div>
                </div>
              </div>

              <div class="row clearfix flex full-width">
                <div class="icons-col">
                  <mat-icon>phone</mat-icon>
                </div>
                <div class="row-content-col">
                  <div class="row clearfix flex">
                    <app-phone-input
                      class="full-width"
                      [phoneNumber]="contact.mobile"
                      [label]="'Mobile Number'"
                      [required]="false"
                      (validate)="validateMobileEvent($event)"
                      (getPhone)="getMobile($event)"
                    ></app-phone-input>

                    <app-phone-input
                      class="full-width"
                      [phoneNumber]="contact.phone"
                      [label]="'Phone Number'"
                      [required]="false"
                      [isLandLine]="true"
                      (validate)="validatePhoneEvent($event)"
                      (getPhone)="getPhone($event)"
                    ></app-phone-input>
                  </div>
                </div>
              </div>

              <div class="row clearfix flex full-width">
                <div class="icons-col">
                  <mat-icon>email</mat-icon>
                </div>
                <div class="row-content-col">
                  <app-email-input
                    class="full-width"
                    [email]="contact.email"
                    [label]="'Email Address'"
                    [required]="false"
                    (validate)="validateEmailEvent($event)"
                    (getEmail)="getEmail($event)"
                  ></app-email-input>
                </div>
              </div>

              <div class="row clearfix flex full-width">
                <app-address-input
                  class="full-width"
                  [displayMap]="true"
                  [canClose]="false"
                  [postCode]="contact.postCode"
                  [state]="contact.state"
                  [suburb]="contact.suburb"
                  [streetNumber]="contact.streetNumber"
                  [streetName]="contact.streetName"
                  [unitNumber]="contact.unitNumber"
                  [streetType]="contact.streetType"
                  [latitude]="contact.latitude"
                  [longitude]="contact.longitude"
                  [isRequired]="false"
                  (getAdress)="getAddress($event)"
                  (validate)="isAddressValidEvent($event)"
                >
                </app-address-input>
              </div>
            </div>
          </mat-card>
        </div>

        <div class="row clearfix">
          <button *ngIf="isParameter == true" (click)="goBack()" mat-raised-button color="primary">
            <mat-icon>chevron_left</mat-icon>Back
          </button>

          <button
            class="pull-right"
            *ngIf="isEdit == false"
            [disabled]="
              !createForm.form.valid || validateMobile == false || validateEmail == false || validateAddress == false
            "
            (click)="createContact()"
            mat-raised-button
            color="accent"
          >
            Create
            <mat-icon>chevron_right</mat-icon>
          </button>

          <button
            class="pull-right"
            *ngIf="isEdit == true"
            [disabled]="
              !createForm.form.valid || validateMobile == false || validateEmail == false || validateAddress == false
            "
            (click)="editContact()"
            mat-raised-button
            color="accent"
          >
            Edit
            <mat-icon>chevron_right</mat-icon>
          </button>
        </div>
      </form>
    </div>
  </aw-wizard-step>

  <aw-wizard-completion-step [navigationSymbol]="{ symbol: '2' }" title="Complete!">
    <div class="container animated fadeInDown" id="step-2" *ngIf="contactCreated">
      <div class="row clearfix text-center">
        <h2 class="sr-title inline-block rm-mt">
          <span *ngIf="isEdit == false">Contact created!</span>
          <span *ngIf="isEdit == true">Contact details updated!</span>
        </h2>
      </div>
      <div class="row clearfix">
        <div class="full-width text-center">
          <mat-card class="add-mb">
            <hr class="mt"/>
            <div class="svg-box">
              <svg class="circular green-stroke">
                <circle class="path" cx="75" cy="75" r="50" fill="none" stroke-width="5" stroke-miterlimit="10" />
              </svg>
              <svg class="checkmark green-stroke">
                <g transform="matrix(0.79961,8.65821e-32,8.39584e-32,0.79961,-489.57,-205.679)">
                  <path class="checkmark__check" fill="none" d="M616.306,283.025L634.087,300.805L673.361,261.53" />
                </g>
              </svg>
            </div>

            <div class="row clearfix">
              <div class="full-width text-center step-button-row">
                <button
                  class="step-next"
                  *ngIf="contactCreated && contactCreated['ID']"
                  (click)="viewContact()"
                  mat-raised-button
                  color="primary"
                >
                  <mat-icon>visibility</mat-icon>
                  View Contact
                </button>
              </div>
            </div>
          </mat-card>
        </div>
      </div>
    </div>
  </aw-wizard-completion-step>
</aw-wizard>
