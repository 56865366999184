import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TagCreateEditCardComponent } from '../tag-create-edit-card/tag-create-edit-card.component';
import { TagCreateEditComponent } from '../tag-create-edit/tag-create-edit.component';
import { TagPatientManagerComponent } from '../tag-patient-manager/tag-patient-manager.component';
import { TagSelectComponent } from '../tag-select/tag-select.component';

const routes: Routes = [
  {
    path: 'tag-create-edit',
    component: TagCreateEditComponent,
  },
  {
    path: 'TagCreateEditCardComponent',
    component: TagCreateEditCardComponent,
  },
  {
    path: 'TagPatientManagerComponent',
    component: TagPatientManagerComponent,
  },

  {
    path: 'TagSelectComponent',
    component: TagSelectComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TagsRoutingModule {}
