import { Component, EventEmitter, OnInit } from '@angular/core';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { AuthenticationService } from '../shared/authentication.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css'],
})
export class ForgotPasswordComponent implements OnInit {
  closeModal = new EventEmitter();

  username;

  constructor(private authenticationService: AuthenticationService) {}

  ngOnInit() {}

  submit() {
    const payload = {
      username: this.username,
    };

    this.authenticationService.checkUserEmail(payload).subscribe((res) => {
      if (res) {
        const confirm = new ConfirmDialog(
          'fas fa-info',
          'Check your email address',
          `Is this your email <strong>${res}</strong>? <br> This email will receive the link to reset the password of your account. <br>If not, please contact Smile Right for more information.`,
          'Contact Smile Right',
          'Yes, proceed'
        );

        const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
          data: confirm,
          width: '650px',
        });

        ref.componentInstance.onConfirm.subscribe((onConfirm) => {
          if (onConfirm == false) {
            const data = {
              targetType: 'us',
            };
            AuthenticationService.contactInputEvent.emit(data);
          } else {
            const payload = {
              username: this.username,
            };
            this.authenticationService.forgotPassword(payload).subscribe((res) => {
              if (res == true) {
                NotifyAppComponent.displayToast(
                  'success',
                  'Forgot Password',
                  'Please check your email to reset your password'
                );
                this.closeModal.emit(true);
                ref.close();
              }
            });
          }
        });
      } else {
        NotifyAppComponent.displayToast('warning', 'Sorry!', 'Email not valid, please contact us for more details');
      }
    });
  }
}
