import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { TipService } from '../shared/tip.service';
import { Tip } from '../shared/tip.type';

@Component({
  selector: 'app-tip-create',
  templateUrl: './tip-create.component.html',
  styleUrls: ['./tip-create.component.css'],
})
export class TipCreateComponent implements OnInit {
  @Input() tip = new Tip();

  @Output() add = new EventEmitter();

  constructor(private tipService: TipService) {}

  ngOnInit() {}

  addUpdateTip() {
    this.tipService.addTip(this.tip).subscribe((res) => {
      if (res && res['success']) {
        this.add.emit(res['data']);
        NotifyAppComponent.displayToast('success', 'Success!', 'Your new tip has been added');
      }
    });
  }

  getContent(c) {
    this.tip.content = c;
  }
}
