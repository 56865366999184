<div class="full-width row clearfix">
  <div class="row clearfix cardheader primary-gradient-img inModal" *ngIf="isModal == true">
    <!-- close button -->
    <button class="btn-close btn-clear white pull-right mat-button" (click)="closeEvent()" mat-dialog-close>
      <mat-icon>clear</mat-icon>
    </button>
    <div class="row titleArea clearfix">
      <h2 class="summary-header rm-m white">
        {{ title }}
        <span class="subLabel">{{ description }}</span>
      </h2>
    </div>
  </div>

  <div class="clearfix full-width">
    <div class="row clearfix" *ngIf="isModal != true">
      <div class="row titleArea clearfix" *ngIf="title">
        <h2 class="sr-title rm-m">
          {{ title }}
          <span class="subLabel">{{ description }}</span>
        </h2>
      </div>
    </div>

    <div class="full-width clearfix mb">
      <button
        class="btn btn-primary mt pull-right"
        *ngIf="isMultiView == true"
        (click)="inserHTML()"
        mat-raised-button
        color="warning"
      >
        HTML<mat-icon>add</mat-icon>
      </button>

      <div class="thrd-width toggle-card flex" *ngIf="isMultiView == true">
        <mat-button-toggle-group
          class="btn-group text-center full-width with-icons"
          #groupB="matButtonToggleGroup"
          [(ngModel)]="view"
          name="view"
        >
          <mat-button-toggle class="text-center half-width" [value]="'editor'" matTooltip="Use WYIWYG editor ">
            <mat-icon class="fas fa-text-width"></mat-icon> Editor
          </mat-button-toggle>
          <mat-button-toggle class="text-center half-width" [value]="'raw'" matTooltip="Intert HTML Content ">
            <mat-icon class="fas fa-file-code"></mat-icon> Html
          </mat-button-toggle>
        </mat-button-toggle-group>

        <button
          class="pull-right mr mt ml"
          class=""
          *ngIf="isPreview == true && content"
          (click)="preview()"
          matTooltip="Preview"
          color="primary"
          mat-stroked-button
        >
          <mat-icon>remove_red_eye</mat-icon> Preview
        </button>
      </div>
    </div>

    <div class="full-width clearfix" *ngIf="view == 'editor'">
      <quill-editor
        class="min-height"
        [(ngModel)]="content"
        (ngModelChange)="sendResult($event)"
        (onEditorChanged)="onEditorChanged($event)"
        format="html"
        theme="snow"
        name="editor"
      ></quill-editor>
    </div>

    <div class="full-width clearfix" *ngIf="view == 'raw'">
      <p class="small clearfix" *ngIf="view == 'raw'">
        You can insert the Html content and view the live result on the left panel , Click on
        <strong>ALT+Shift+F</strong> for auto format
      </p>

      <div class="full-width clearfix flex">
        <div class="full-width clearfix">
          <ngx-monaco-editor
            class="min-height"
            [(ngModel)]="content"
            [options]="editorOptions"
            (change)="sendResult($event)"
          ></ngx-monaco-editor>
        </div>

        <div class="full-width min-height">
          <div class="full-width clearfix" class="small" *ngIf="content" [innerHTML]="content | safeHtml"></div>
        </div>
      </div>
    </div>
  </div>

  <div class="clearfix full-width text-center mt" *ngIf="isAction == true">
    <button
      class="btn btn-primary"
      [disabled]="isRequired == true && !content"
      (click)="actionEvent()"
      mat-raised-button
      color="accent"
    >
      {{ actionLabel }}
    </button>
  </div>
</div>
