export class DueSettlementsRecord {
  date: string;
  merchant: string;
  patient: string;
  product: string;
  treatmentValue: number;
  dueAmount: number;
  typeCode: string;
}

export class DueSettlementsReportRequestOptionals {
  endDate?: string;
}

export class DueSettlementsReportRequest {
  StartDate: string;
  EndDate?: string;

  constructor(startDate: string, optional?: DueSettlementsReportRequestOptionals) {
    this.StartDate = startDate;

    if (optional) {
      if (optional.endDate) {
        this.EndDate = optional.endDate;
      }
    }
  }
}

export class DueSettlementsReport {
  StartDate: string;
  EndDate?: string;
  Date_Due: string[];
  Merchant_Name: string[];
  Patient_Name: string[];
  Product_Label: string[];
  Treatment_Value: number[];
  Due_Amount: number[];
  Contract_ID: string[];
  Type_Code: string[];
  Total_Due_Amount: number;
}
