<div class="row clearfix flex">
  <div class="icons-col">
    <mat-icon>email</mat-icon>
  </div>
  <div class="row-content-col">
    <div class="row clearfix flex">
      <mat-form-field class="half-width" appearance="outline">
        <mat-label>Type</mat-label>
        <mat-select
          [(ngModel)]="emailType"
          [disabled]="isDisabled"
          [ngClass]="{ removeArrow: isDisabled == true }"
          (selectionChange)="getEmailByType(emailType)"
          placeholder="Type"
          name="emailType"
        >
          <mat-option *ngFor="let type of emailTypes" [value]="type">
            <span *ngIf="convertToLowerCase(type) != convertToLowerCase(targetEmailType)">{{ type | titlecase }}</span>
            <span *ngIf="convertToLowerCase(type) == convertToLowerCase(targetEmailType)"
            >{{ type | titlecase }} - Primary</span
            >
          </mat-option>
          <!-- <hr>
          <mat-option (click)="createEmailLabel()">
            Create new label
          </mat-option> -->
        </mat-select>
      </mat-form-field>
      <app-email-input class="full-width" [label]="'Email'" [required]="true" [email]="myEmail" [isDisabled]="true">
      </app-email-input>
      <div class="clearfix" *ngIf="isDisabled == false">
        <!-- <button mat-icon-button matTooltip="Delete Email" (click)="removeEmailByLabel(emailType)" *ngIf="emailType != targetEmailType">
          <mat-icon>delete</mat-icon>
        </button> -->

        <button (click)="editEmailByLabel(emailType)" mat-icon-button matTooltip="Update Email">
          <mat-icon>edit</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
