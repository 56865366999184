import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class GlobalService {
  loader = new BehaviorSubject(false);

  constructor() {}

  showLoader = function () {
    this.loader.next(true);
  };

  hideLoader = function () {
    this.loader.next(false);
  };
}
