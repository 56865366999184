<div class="container text-center">
  <h2 class="sr-title">Add new Document</h2>
  <form #addForm="ngForm">
    <div class="row clearfix">
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>Page Title</mat-label>
        <input [(ngModel)]="simplePage.title" name="title" matInput placeholder="Page Title" required/>
      </mat-form-field>
    </div>

    <div class="row clearfix">
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>Page Type (no space)</mat-label>
        <input [(ngModel)]="simplePage.type" name="type" matInput placeholder="Page Type (no space)" required/>
      </mat-form-field>
    </div>

    <div class="clearfix full-width text-center">
      <button
        class="btn-large"
        [disabled]="!addForm.form.valid"
        (click)="addSimplePage()"
        mat-raised-button
        color="accent"
      >
        <mat-icon>add</mat-icon>Add
      </button>
    </div>
  </form>
</div>
