<mat-card class="rel create-container supplier-card brandView clearfix mb" id="subscription-plan" @ngIfAnimation>
  <div class="card-header primary-gradient-img clearfix inModal rel" @ngIfAnimation>
    <div class="row clearfix content">
      <div class="row titleArea clearfix">
        <h2 class="message-title summary-header rm-m">
          <span *ngIf="!thirdPartyClientID">Create Third-Party Application</span>
          <span *ngIf="thirdPartyClientID">Edit Third-Party Application </span>
          <span class="subLabel">Insert a label and a description for Third-Party Application </span>
        </h2>
      </div>
    </div>
  </div>

  <div class="full-width" @ngIfAnimation>
    <aw-wizard
      class="template-subscription-plan-create"
      #wizard
      [disableNavigationBar]="true"
      navBarLayout="large-filled-symbols"
    >
      <aw-wizard-step [navigationSymbol]="{ symbol: '1' }" stepTitle="Define" awOptionalStep>
        <div class="full-width clearfix mt">
          <form #resetform="ngForm">
            <div class="full-width clearfix flex row mb">
              <mat-card class="full-width row mr animate mat-elevation-z4">
                <h3 class="sr-title mt">Details</h3>
                <div class="row clearfix full-width flex mt">
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Label</mat-label>
                    <input [(ngModel)]="label" matInput placeholder="Label" name="label" required/>
                  </mat-form-field>
                </div>

                <div class="full-width clearfix">
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Description</mat-label>
                    <textarea
                      class="min-height"
                      [(ngModel)]="description"
                      name="description"
                      matInput
                      placeholder="Description"
                    ></textarea>
                  </mat-form-field>
                </div>

                <div class="full-width flex">
                  <app-phone-input
                    class="full-width mr"
                    [phoneNumber]="_phone"
                    [isLandLine]="false"
                    [label]="'Phone'"
                    [required]="false"
                    (validate)="isPhoneValidEvent($event)"
                    (getPhone)="getPhone($event)"
                  >
                  </app-phone-input>
                  <app-email-input
                    class="full-width"
                    [email]="_email"
                    [label]="'Email'"
                    [required]="false"
                    (getEmail)="getEmail($event)"
                    (validate)="isEmailValidEvent($event)"
                  >
                  </app-email-input>
                </div>
                <h3 class="sr-title mt">Address</h3>

                <app-address-input
                  class="full-width"
                  *ngIf="loadAddress == true"
                  [canClose]="false"
                  [isRequired]="false"
                  [postCode]="_postCode"
                  [state]="_state"
                  [suburb]="_suburb"
                  [streetNumber]="_streetNumber"
                  [streetName]="_streetName"
                  [unitNumber]="_unitNumber"
                  [streetType]="_streetType"
                  (getAdress)="getAddress($event)"
                  (validate)="isAdressValidEvent($event)"
                >
                </app-address-input>

                <h3 class="sr-title mt">Secret ID/Key</h3>

                <div class="full-width flex mb" *ngIf="displayManual == true">
                  <mat-checkbox class="full-width slider-input-select ml mr" [(ngModel)]="isManual" name="isManual">
                    Manually add Secrect Key and ID.
                  </mat-checkbox>
                </div>

                <div class="full-width flex mb" *ngIf="displayManual != true">
                  <mat-checkbox
                    class="full-width slider-input-select ml mr"
                    [(ngModel)]="generateNew"
                    name="generateNew"
                  >
                    Automatically Generate New Secret Key/ID
                  </mat-checkbox>
                </div>

                <div class="full-width flex mb" *ngIf="isManual == true || displayManual != true">
                  <mat-form-field class="full-width mr" *ngIf="generateNew != true" appearance="outline">
                    <mat-label>Secret ID</mat-label>
                    <input [(ngModel)]="secretID" matInput placeholder="Secret ID" name="secretID" required/>
                  </mat-form-field>

                  <mat-form-field class="full-width mr" *ngIf="generateNew == true" appearance="outline">
                    <mat-label>Secret ID</mat-label>
                    <input [(ngModel)]="secretID" [disabled]="true" matInput placeholder="Secret ID" name="secretID"/>
                  </mat-form-field>

                  <mat-form-field class="full-width" *ngIf="generateNew != true" appearance="outline">
                    <mat-label>Secret Key</mat-label>
                    <input [(ngModel)]="secretKey" matInput placeholder="Secret Key" name="secretKey" required/>
                  </mat-form-field>

                  <mat-form-field class="full-width" *ngIf="generateNew == true" appearance="outline">
                    <mat-label>Secret Key</mat-label>
                    <input
                      [(ngModel)]="secretKey"
                      [disabled]="true"
                      matInput
                      placeholder="Secret Key"
                      name="secretKey"
                    />
                  </mat-form-field>
                </div>

                <h3 class="sr-title rm-mb">Default Template</h3>

                <div class="full-width flex flex-cust mb">
                  <p class="full-width small pull-left">
                    Choose the template that will be applied to invitations sent.
                  </p>
                  <button
                    class="ml btn mr pull-right"
                    (click)="openTemplateLookup()"
                    mat-raised-button
                    style="height: 40px"
                    color="primary"
                  >
                    <span>Select Template</span><span class="ml mr"></span>
                  </button>
                </div>

                <div
                  class="full-width flex smb"
                  *ngIf="templateID && templateLabel && templateID != templateIDOld && templateIDOld"
                >
                  <mat-checkbox class="full-width slider-input-select ml mr" [(ngModel)]="updateAll" name="updateAll">
                    Update all API Keys with the previous template .
                  </mat-checkbox>
                </div>

                <div class="full-width flex mb" *ngIf="templateID && templateLabel">
                  <div class="full-width text-center secret-container">
                    <div class="full-width text-left">
                      <h3 class="full-width rm-m text-left">
                        {{ templateLabel }}

                        <button
                          class="ml smr pull-right btn"
                          (click)="viewTemplate(templateID)"
                          mat-button
                          mat-icon-button

                        >
                          <mat-icon>visibility</mat-icon>
                        </button>
                      </h3>
                    </div>
                  </div>
                </div>

                <h3 class="sr-title mt">Social Media</h3>

                <div class="full-width flex">
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Website</mat-label>
                    <input [(ngModel)]="website" matInput placeholder="Website" name="website"/>
                  </mat-form-field>
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Vimeo</mat-label>
                    <input [(ngModel)]="vimeo" matInput placeholder="Vimeo" name="vimeo"/>
                  </mat-form-field>

                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Youtube</mat-label>
                    <input [(ngModel)]="youtube" matInput placeholder="Youtube" name="youtube"/>
                  </mat-form-field>
                </div>

                <div class="full-width flex">
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Facebook</mat-label>
                    <input [(ngModel)]="facebook" matInput placeholder="Facebook" name="facebook"/>
                  </mat-form-field>

                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Twitter</mat-label>
                    <input [(ngModel)]="twitter" matInput placeholder="Twitter" name="twitter"/>
                  </mat-form-field>

                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>LinkedIn</mat-label>
                    <input [(ngModel)]="linkedIn" matInput placeholder="LinkedIn" name="linkedIn"/>
                  </mat-form-field>
                </div>

                <div class="full-width flex">
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Pinterest</mat-label>
                    <input [(ngModel)]="pinterest" matInput placeholder="Pinterest" name="pinterest"/>
                  </mat-form-field>

                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Instagram</mat-label>
                    <input [(ngModel)]="instagram" matInput placeholder="Instagram" name="instagram"/>
                  </mat-form-field>

                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Tumblr</mat-label>
                    <input [(ngModel)]="tumblr" matInput placeholder="Label" name="tumblr"/>
                  </mat-form-field>
                </div>
              </mat-card>
              <div class="qtr-width row blob-for-main-feature-container-thumbnail ml mr">
                <div class="full-width">
                  <app-ep-document-view-image
                    class="squareImg squareImg-social"
                    *ngIf="!postPictureLink && postPicture"
                    [hideZoomButton]="false"
                    [defaultPicture]="
                      'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/3385472.png)'
                    "
                    [link]="postPicture"
                  >
                  </app-ep-document-view-image>
                  <app-ep-document-view-image
                    class="squareImg squareImg-social"
                    *ngIf="postPictureLink && !postPicture"
                    [hideZoomButton]="false"
                    [defaultPicture]="
                      'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/3385472.png)'
                    "
                    [link]="postPictureLink"
                  >
                  </app-ep-document-view-image>
                  <app-ep-document-view-image
                    class="squareImg squareImg-social"
                    *ngIf="!postPictureLink && !postPicture"
                    [hideZoomButton]="false"
                    [defaultPicture]="
                      'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/3385472.png)'
                    "
                  >
                  </app-ep-document-view-image>
                </div>
                <div class="clearfix full-width">
                  <hr/>
                  <app-file-uploader
                    class="text-center clearfix full-width"
                    [aspectRatioType]="'full'"
                    [allowedExtensions]="allowExtensions"
                    [isTwoThirdWidth]="false"
                    [maxFileSize]="2"
                    [isImageCropping]="true"
                    [canSkip]="true"
                    [limit]="1"
                    [uploadDirectly]="true"
                    [isDragable]="true"
                    [title]="'CLICK HERE to upload (Max size: ' + 2 + 'MB)'"
                    (onCompleteAll)="onComplete($event)"
                  >
                  </app-file-uploader>
                </div>
              </div>
            </div>

            <hr class="mb"/>
            <div class="drop-button full-width mt">
              <button class="btn-large pull-left" (click)="clientList()" mat-raised-button color="primary">
                <mat-icon>chevron_left</mat-icon> Application List
              </button>
              <button
                class="btn-large pull-right"
                [disabled]="!resetform.form.valid || !isAdressValid || !isPhoneValid || !isEmailValid || !templateID"
                (click)="save()"
                mat-raised-button
                color="accent"
              >
                Next
                <mat-icon>chevron_right</mat-icon>
              </button>
            </div>
          </form>
        </div>
      </aw-wizard-step>

      <aw-wizard-step [navigationSymbol]="{ symbol: '2' }" stepTitle="Done!" awOptionalStep>
        <div class="container mt" id="step-2" *ngIf="createdRecord && createdRecord.ID" @ngIfAnimation>
          <div class="animate clearfix">
            <div class="row clearfix">
              <mat-card class="card successful">
                <div class="row clearfix">
                  <div class="full-width text-center">
                    <h1 class="sm-h1 sr-title">
                      Well Done!
                    </h1>
                    <h3 class="sm-h3 rm-m" *ngIf="!thirdPartyClientID">
                      Third-Party Application has been Created
                    </h3>

                    <h3 class="sm-h3 rm-m" *ngIf="thirdPartyClientID">
                      Third-Party Application has been Modified
                    </h3>
                    <div class="row clearfix">
                      <div class="svg-box">
                        <svg class="circular green-stroke">
                          <circle
                            class="path"
                            cx="75"
                            cy="75"
                            r="50"
                            fill="none"
                            stroke-width="5"
                            stroke-miterlimit="10"
                          />
                        </svg>
                        <svg class="checkmark green-stroke">
                          <g transform="matrix(0.79961,8.65821e-32,8.39584e-32,0.79961,-489.57,-205.679)">
                            <path
                              class="checkmark__check"
                              fill="none"
                              d="M616.306,283.025L634.087,300.805L673.361,261.53"
                            />
                          </g>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row clearfix text-center">
                  <button
                    class="smr"
                    *ngIf="createdRecord && createdRecord.ID"
                    (click)="clientList()"
                    mat-raised-button
                    color="primary"
                  >
                    <mat-icon class="smr">list</mat-icon> Application list
                  </button>
                  <button
                    class="smr"
                    *ngIf="createdRecord && createdRecord.ID"
                    (click)="view(createdRecord.ID)"
                    mat-raised-button
                    color="accent"
                  >
                    <mat-icon class="smr">visibility</mat-icon> View
                  </button>
                </div>
              </mat-card>
            </div>
          </div>
        </div>
      </aw-wizard-step>
    </aw-wizard>
  </div>
</mat-card>
