import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSortModule } from '@angular/material/sort';
import { ArchwizardModule } from 'angular-archwizard';
import { NgPipesModule } from 'ngx-pipes';
import { SharedModule } from '../../shared/shared.module';
import { DentistModule } from '../dentist/dentist.module';
import { PatientModule } from '../patient/patient.module';
import { TreatmentPlanModule } from '../treatment-plan/treatment-plan.module';
import { TreatmentModule } from '../treatment/treatment.module';
import { ReferralCardConsumerComponent } from './referral-card-consumer/referral-card-consumer.component';
import { ReferralCardViewComponent } from './referral-card-view/referral-card-view.component';
import { ReferralConfirmModalComponent } from './referral-confirm-modal/referral-confirm-modal.component';
import { ReferralCreateSelectMerchantComponent } from './referral-create-select-merchant/referral-create-select-merchant.component';
import { ReferralCreateComponent } from './referral-create/referral-create.component';
import { ReferralListComponent } from './referral-list/referral-list.component';
import { ReferralViewComponent } from './referral-view/referral-view.component';
import { ReferralRoutingModule } from './shared/referral-routing.module';

@NgModule({
  imports: [
    NgPipesModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    ReferralRoutingModule,
    CdkTableModule,
    ArchwizardModule,
    TreatmentPlanModule,
    TreatmentModule,
    DentistModule,
    MatSortModule,
    PatientModule,
  ],
  declarations: [
    ReferralListComponent,
    ReferralViewComponent,
    ReferralCardViewComponent,
    ReferralCreateComponent,
    ReferralCreateSelectMerchantComponent,
    ReferralCardConsumerComponent,
    ReferralConfirmModalComponent,
  ],
  exports: [
    ReferralListComponent,
    ReferralViewComponent,
    ReferralCardViewComponent,
    ReferralCreateComponent,
    ReferralCreateSelectMerchantComponent,
    ReferralCardConsumerComponent,
    ReferralConfirmModalComponent,
  ],
  providers: [],
})
export class ReferralModule {}
