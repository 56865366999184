import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { AuthenticationService } from '../../authentication/shared/authentication.service';

@Component({
  selector: 'wiki-left-menu',
  templateUrl: './wiki-left-menu.component.html',
  styleUrls: ['./wiki-left-menu.component.css'],
})
export class WikiLeftMenuComponent implements OnInit {
  static updateHeader = new EventEmitter();
  @Input()
  WikiTitle = 'Wiki';

  sessionType = 'guest';

  isDisabled = 'true';

  utils = new UtilsClass();
  isPromoterOrAdmin = false;
  // static updateHeaderWithSessionType = new EventEmitter();

  settings = Settings.global;

  constructor(private router: Router, private authenticationService: AuthenticationService) {}

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((res) => {
      if (res) {
        this.sessionType = res;
      }
    });
  }

  login() {
    const activeURL = window.location.pathname;
    if (activeURL) {
      const __url = activeURL.replace(/\//g, '$');

      this.router.navigate(['/login/' + __url]);
    } else {
      this.router.navigate(['/login/']);
    }
  }
}
