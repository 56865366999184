import { Component, OnInit, Input } from '@angular/core';
import { Settings } from '../../../shared/types/settings';

import { trigger, style, transition, animate, keyframes, query, stagger } from '@angular/animations';
import { UtilsService } from '../../../shared/services/utils.service';

@Component({
  selector: 'app-change-log',
  templateUrl: './change-log.component.html',
  styleUrls: ['./change-log.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('mat-card', style({ opacity: 0 }), { optional: true }),
        query(
          'mat-card',
          stagger('100ms', [
            animate(
              '0.4s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-20%)', offset: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('mat-card', style({ opacity: 1 }), { optional: true }),
        query(
          'mat-card',
          stagger('100ms', [
            animate(
              '0.4s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-20%)', offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class ChangeLogComponent implements OnInit {
  settings = Settings.global;

  newItem: boolean = true;

  isModuleSettlementsActive = Settings.global['isModuleSettlementsActive'];

  constructor(private utilService: UtilsService) {}

  ngOnInit() {
    this.utilService.getCurrentAccess().subscribe((access) => {
      if (access) {
        this.isModuleSettlementsActive = access['isModuleSettlementsActive'];
      }
    });
  }
}
