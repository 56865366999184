import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ReferralConfirmModalComponent } from '../referral-confirm-modal/referral-confirm-modal.component';
import { ReferralCreateSelectMerchantComponent } from '../referral-create-select-merchant/referral-create-select-merchant.component';
import { ReferralCreateComponent } from '../referral-create/referral-create.component';
import { ReferralViewComponent } from '../referral-view/referral-view.component';

const routes: Routes = [
  {
    path: 'referral-view',
    component: ReferralViewComponent,
  },
  {
    path: 'referral-create',
    component: ReferralCreateComponent,
  },
  {
    path: 'select-merchant-modal',
    component: ReferralCreateSelectMerchantComponent,
  },
  {
    path: 'referral-confirm-modal',
    component: ReferralConfirmModalComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ReferralRoutingModule {}
