<div
  class="row clearfix flex"
  *ngIf="dentist || merchant"
  [class.noMessage]="!messageFromDentist"
  [class.smallMessage]="messageFromDentist && messageFromDentist.length < 200"
>
  <mat-card
    class="practice-card"
    *ngIf="messageFromPractice"
    @simpleFadeAnimation
    [class.full-width]="!merchant && !dentist && messageFromPractice"
    [class.large-profile]="largeProfile"
  >
    <!-- NO IMAGE -->
    <div class="practice-card-header primary-gradient-img">
      <!-- dentist avatar -->
      <app-ep-document-view-image
        class="dentistIcon"
        *ngIf="displayProfilePicture == true"
        [defaultPicture]="
          'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/patient-card-header.png)'
        "
        [hideZoomButton]="false"
      >
      </app-ep-document-view-image>

      <app-ep-document-view-image
        class="dentistIcon"
        *ngIf="displayProfilePicture != true"
        [defaultPicture]="
          'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/patient-card-header.png)'
        "
        [hideZoomButton]="true"
      >
      </app-ep-document-view-image>
    </div>

    <div class="row clearfix">
      <h2 class="rm-m">
        <ng-container *ngIf="messageFromPractice && !messageFromPracticeTitle">Message from </ng-container>
        {{ merchant.TradingAs }}
        <ng-container *ngIf="messageFromPractice && messageFromPracticeTitle"
        >{{ messageFromPracticeTitle }}
        </ng-container>
      </h2>
    </div>

    <div class="dentistMessage" *ngIf="messageFromPractice">
      <read-more [text]="messageFromPractice" [maxLength]="400"></read-more>
    </div>

    <div class="row clearfix flex flex-grow"></div>
  </mat-card>

  <!-- dentist -->
  <mat-card
    class="dentist-card"
    *ngIf="displayDentistCard && dentist"
    @simpleFadeAnimation
    [class.full-width]="displayFullWidth == true"
    [class.large-profile]="largeProfile"
  >
    <!-- HAS IMAGE -->
    <div
      class="dentist-card-header primary-gradient-img"
      *ngIf="practiceCoverLinkURL"
      [ngStyle]="{
        'background-image': practiceCoverLinkURL + ',' + defaultPicture
      }"
    >
      <!-- view profile button -->
      <button class="" *ngIf="dentistID" (click)="viewDentistModal()" mat-button>
        <mat-icon>search</mat-icon> <span class="mobHide">View profile</span>
      </button>
      <!-- dentist avatar -->
      <app-ep-document-view-image
        class="dentistIcon"
        *ngIf="displayProfilePicture == true"
        [defaultPicture]="
          'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/profile_placeholder.png)'
        "
        [hideZoomButton]="true"
        [link]="profileLink"
      >
      </app-ep-document-view-image>

      <app-ep-document-view-image
        class="dentistIcon"
        *ngIf="displayProfilePicture != true"
        [defaultPicture]="
          'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/patient-card-header.png)'
        "
        [hideZoomButton]="true"
      >
      </app-ep-document-view-image>
    </div>

    <!-- NO IMAGE -->
    <div class="dentist-card-header primary-gradient-img" *ngIf="!practiceCoverLinkURL">
      <!-- view profile button -->
      <button
        class=""
        *ngIf="messageFromDentist && dentist['Description'] && !displayIntroText"
        (click)="viewDentistModal()"
        mat-button
      >
        <mat-icon>search</mat-icon> <span class="mobHide">View profile</span>
      </button>
      <!-- dentist avatar -->
      <app-ep-document-view-image
        class="dentistIcon"
        *ngIf="displayProfilePicture == true"
        [defaultPicture]="
          'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/profile_placeholder.png)'
        "
        [hideZoomButton]="true"
        [link]="profileLink"
      >
      </app-ep-document-view-image>

      <app-ep-document-view-image
        class="dentistIcon"
        *ngIf="displayProfilePicture != true"
        [defaultPicture]="
          'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/patient-card-header.png)'
        "
        [hideZoomButton]="true"
      >
      </app-ep-document-view-image>
    </div>

    <div class="row clearfix">
      <h2 class="rm-m">
        <ng-container *ngIf="messageFromDentist && dentist">Message from </ng-container>{{ dentist?.Salutation }}
        {{ dentist?.FirstName }} {{ dentist?.Name }}
      </h2>
    </div>

    <div class="row clearfix bio mt" *ngIf="dentist['Description'] && largeProfile">
      <read-more [text]="dentist['Description']" [maxLength]="300"></read-more>
    </div>

    <!-- if no message -->
    <!-- view profile button -->
    <div
      class="row clearfix flex buttons flex-wrap"
      *ngIf="!messageFromDentist && dentist['Description'] && !displayIntroText"
    >
      <button class="full-width" (click)="viewDentistModal()" mat-button>
        <mat-icon>search</mat-icon> View profile
      </button>
    </div>


    <div class="dentistMessage" *ngIf="messageFromDentist">
      <read-more [text]="messageFromDentist" [maxLength]="400"></read-more>
    </div>

    <div class="row clearfix flex flex-grow"></div>

    <ng-container *ngIf="displayFullWidth == true && merchant">
      <button
        class="contact-btn primary-bg"
        *ngIf="merchant['phones.Number']"
        (click)="dialPractice(merchant['phones.Number'])"
        mat-button
      >
        <mat-icon>phone</mat-icon>
        {{ merchant['phones.Number'] | customPhone: 'landLine' }}
      </button>

      <button
        class="contact-btn primary-bg"
        *ngIf="!merchant['phones.Number'] && merchant['mobiles.Number']"
        (click)="dialPractice(merchant['mobiles.Number'])"
        mat-button
      >
        <mat-icon>phone</mat-icon>
        {{ merchant['mobiles.Number'] | customPhone }}
      </button>
    </ng-container>
  </mat-card>

  <!-- practice -->
  <mat-card
    class="practice-card"
    *ngIf="displayPracticeCard == true && merchant"
    @simpleFadeAnimation
    [class.full-width]="displayDentistCard == false && !dentist && !messageFromPractice"
  >
    <!-- HAS IMAGE -->
    <div
      class="practice-card-header primary-gradient-img"
      *ngIf="practiceCoverLinkURL"
      [ngStyle]="{
        'background-image': practiceCoverLinkURL + ',' + defaultPicture
      }"
    >
      <!-- view profile button with open indicator -->
      <button class="" *ngIf="!displayIntroText" (click)="viewMerchantModal()" mat-button>
        <mat-icon>search</mat-icon> <span class="mobHide">View profile</span>
      </button>
      <div class="open-indicator">
        <span class="open" *ngIf="isOpen == true"></span>
        <span class="closed" *ngIf="isOpen == false"></span>
      </div>
      <!-- practice avatar -->
      <app-ep-document-view-image
        class="dentistIcon"
        *ngIf="displayProfilePicture == true"
        [defaultPicture]="
          'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/profile_placeholder.png)'
        "
        [hideZoomButton]="true"
        [link]="practiceLink"
      >
      </app-ep-document-view-image>

      <app-ep-document-view-image
        class="dentistIcon"
        *ngIf="displayProfilePicture != true"
        [defaultPicture]="
          'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/patient-card-header.png)'
        "
        [hideZoomButton]="true"
      >
      </app-ep-document-view-image>
    </div>

    <!-- NO IMAGE -->
    <div class="practice-card-header primary-gradient-img" *ngIf="!practiceCoverLinkURL">
      <!-- view profile button with open indicator -->
      <button class="" *ngIf="!displayIntroText" (click)="viewMerchantModal()" mat-button>
        <mat-icon>search</mat-icon> <span class="mobHide">View profile</span>
      </button>
      <div class="open-indicator">
        <span class="open" *ngIf="isOpen == true"></span>
        <span class="closed" *ngIf="isOpen == false"></span>
      </div>
      <!-- practice avatar -->
      <app-ep-document-view-image
        class="dentistIcon"
        *ngIf="displayProfilePicture == true"
        [defaultPicture]="
          'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/profile_placeholder.png)'
        "
        [hideZoomButton]="true"
        [link]="practiceLink"
      >
      </app-ep-document-view-image>

      <app-ep-document-view-image
        class="dentistIcon"
        *ngIf="displayProfilePicture != true"
        [defaultPicture]="
          'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/patient-card-header.png)'
        "
        [hideZoomButton]="true"
      >
      </app-ep-document-view-image>
    </div>

    <!-- open hrs -->
    <div class="row clearfix" *ngIf="merchant">
      <h2 class="rm-m">{{ merchant.TradingAs }}</h2>
      <p class="subLabel rm-mt">
        <ng-container *ngIf="isOpen == true"
        >{{ "KEYWORD.practice" | translate | titlecase}} is <strong>open</strong> until {{ closeTime }}</ng-container
        >
        <ng-container *ngIf="isOpen == false"
        >{{ "KEYWORD.practice" | translate | titlecase}} is currently <strong>closed</strong></ng-container
        >
      </p>

      <ng-container *ngIf="displayIntroText == true">
        <hr />
        <div class="row clearfix bio mt" *ngIf="merchant['Description']">
          <read-more [text]="merchant['Description']" [maxLength]="450"></read-more>
        </div>

        <div
          class="row clearfix flex flex-wrap mb"
          *ngIf="
            merchant['URL'] ||
            merchant['Facebook'] ||
            merchant['Twitter'] ||
            merchant['LinkedIn'] ||
            merchant['Instagram'] ||
            merchant['Pinterest'] ||
            merchant['Tumblr'] ||
            merchant['Vimeo']
          "
        >
          <div class="row clearfix socials">
            <!-- open social links in new tab -->
            <button
              *ngIf="merchant['URL']"
              (click)="openSocialLink(merchant['URL'])"
              mat-stroked-button
              matTooltip="Visit website"
            >
              Website <mat-icon class="fas fa-globe"></mat-icon>
            </button>
            <button
              *ngIf="merchant['Facebook']"
              (click)="openSocialLink(merchant['Facebook'])"
              mat-stroked-button
              matTooltip="Visit Facebook page"
            >
              Facebook <mat-icon class="fab fa-facebook"></mat-icon>
            </button>
            <button
              *ngIf="merchant['Twitter']"
              (click)="openSocialLink(merchant['Twitter'])"
              mat-stroked-button
              matTooltip="Visit Twitter page"
            >
              Twitter <mat-icon class="fab fa-twitter"></mat-icon>
            </button>
            <button
              *ngIf="merchant['LinkedIn']"
              (click)="openSocialLink(merchant['LinkedIn'])"
              mat-stroked-button
              matTooltip="Visit LinkedIn page"
            >
              LinkedIn <mat-icon class="fab fa-linkedin"></mat-icon>
            </button>
            <button
              *ngIf="merchant['Instagram']"
              (click)="openSocialLink(merchant['Instagram'])"
              mat-stroked-button
              matTooltip="Visit Instagram page"
            >
              Instagram <mat-icon class="fab fa-instagram"></mat-icon>
            </button>
            <button
              *ngIf="merchant['Pinterest']"
              (click)="openSocialLink(merchant['Pinterest'])"
              mat-stroked-button
              matTooltip="Visit Pinterest page"
            >
              Pinterest <mat-icon class="fab fa-pinterest"></mat-icon>
            </button>
            <button
              *ngIf="merchant['Tumblr']"
              (click)="openSocialLink(merchant['Tumblr'])"
              mat-stroked-button
              matTooltip="Visit Tumblr page"
            >
              Tumblr <mat-icon class="fab fa-tumblr"></mat-icon>
            </button>
            <button
              *ngIf="merchant['Vimeo']"
              (click)="openSocialLink(merchant['Vimeo'])"
              mat-stroked-button
              matTooltip="Visit Vimeo page"
            >
              Vimeo <mat-icon class="fab fa-vimeo"></mat-icon>
            </button>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="row clearfix flex buttons flex-wrap">
      <button class="full-width" (click)="viewMerchantMapModal()" mat-button>
        <mat-icon>room</mat-icon> View on map
      </button>
      <button class="full-width" *ngIf="merchant" (click)="emailPractice(merchant['emails.Email'])" mat-button>
        <mat-icon>email</mat-icon> Send e-mail
      </button>
      <button
        class="full-width profile"
        *ngIf="dentist && dentist['URL']"
        (click)="practiceWebsite(dentist['URL'])"
        mat-button
      >
        <mat-icon class="fas fa-globe"></mat-icon> View website
      </button>
      <button class="full-width" (click)="viewTradingHrs()" mat-button>
        <mat-icon> schedule</mat-icon> Trading Hours
      </button>
    </div>

    <div class="flex-grow"></div>

    <button
      class="contact-btn primary-bg"
      *ngIf="merchant && merchant['phones.Number']"
      (click)="dialPractice(merchant['phones.Number'])"
      mat-button
    >
      <mat-icon>phone</mat-icon>
      {{ merchant['phones.Number'] | customPhone: 'landLine' }}
    </button>

    <button
      class="contact-btn primary-bg"
      *ngIf="merchant && !merchant['phones.Number'] && merchant['mobiles.Number']"
      (click)="dialPractice(merchant['mobiles.Number'])"
      mat-button
    >
      <mat-icon>phone</mat-icon>
      {{ merchant['mobiles.Number'] | customPhone }}
    </button>
  </mat-card>
</div>
