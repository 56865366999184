<div class="rel" *ngIf="listDB.firstLoadEvent == false" @ngIfAnimation>
  <div class="row clearfix padded rel">
    <h1 class="dataHeader rm-mt transparent">Fetching data...</h1>
  </div>
</div>

<div [hidden]="listDB.data.length == 0">
  <button class="pull-right" [routerLink]="['/merchant/', { outlets: { page: ['merchant-list-map'] } }]"
    [routerLinkActive]="['active']" mat-raised-button color="accent">
    <mat-icon>public</mat-icon>
    {{ "KEYWORD.practice" | translate | titlecase}} map
  </button>

  <div class="pull-right selection-items" @ngIfAnimation>
    <button class="animated mr" (click)="selectAll()" mat-stroked-button color="" matTooltip="Select All">
      <mat-icon>border_all</mat-icon>
    </button>
    <button class="animated mr" (click)="selectPage()" mat-stroked-button color="" matTooltip="Select Page">
      <mat-icon>border_outer</mat-icon>
    </button>

    <button class="animated mr" *ngIf="selectedIDs && selectedIDs.length > 0" [matMenuTriggerFor]="ActionBtnmenu"
      mat-stroked-button matTooltip="Options">
      <mat-icon class="fas fa-cog"></mat-icon> <span *ngIf="selectedIDs && selectedIDs.length>0"
        class="selected-number-item  accent-background">{{selectedIDs.length}}</span>
    </button>
    <mat-menu #ActionBtnmenu="matMenu">
      <button class="row select-btn" (click)="unselect()" mat-menu-item color="">
        <mat-icon>select_all</mat-icon>
        Deselect
      </button>


      <hr />


      <button (click)="matrialRequestGroup()" mat-menu-item>
        <mat-icon class="fas fa-search-dollar smr"></mat-icon>
        <span>Material request Settings</span>
      </button>


      <hr />

      <button class="row select-btn" *ngIf="selectedIDs && selectedIDs.length > 0" (click)="enableGroup()" mat-menu-item
        color="">
        <mat-icon>check_circle</mat-icon>
        Enable Medical History
      </button>

      <button class="row select-btn" *ngIf="selectedIDs && selectedIDs.length > 0" (click)="disableGroup()"
        mat-menu-item color="">
        <mat-icon>cancel</mat-icon>
        Disable Medical History
      </button>
      <hr />
    </mat-menu>
  </div>

  <p class="pull-right" *ngIf="dateFrom != null || dateTo != null">
    <span *ngIf="dateFrom">From:
      <strong>{{ dateFrom | customDateTime }} </strong>
    </span>

    <span *ngIf="dateTo">
      To:
      <strong>{{ dateTo | customDateTime }}</strong>
    </span>
  </p>
  <div>
    <h1 class="dataHeader rm-m">
      <!-- Contracts -->
      {{'Merchant' | translate}}s
      <span class="badge" [endVal]="dataSource.filteredData.length" countUp></span>
    </h1>
    <mat-card class="list-card">
      <!-- Search -->
      <div class="row clearfix searchArea primary-gradient-img">

        <div class="full-width flex">

          <mat-icon class="mr">search</mat-icon>

          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Search...</mat-label>
            <input class="clearfix" (input)="setFilter($event.target.value, 'fullName')" name="fullName" placeholder=""
              matInput />
          </mat-form-field>

        </div>
      </div>

      <!-- Table -->
      <mat-table class="list-table" #table [dataSource]="dataSource" matSort>
        <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->
        <!-- ID Column -->

        <ng-container cdkColumnDef="selectCheckBox" sticky>
          <mat-header-cell *cdkHeaderCellDef></mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <div class="margin-btm pull-left">
              <mat-checkbox [checked]="isSelected(row['ID'])" (change)="select(row['ID'], $event.checked)"
                color="accent">
              </mat-checkbox>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="ProfileImage" sticky>
          <mat-header-cell *cdkHeaderCellDef mat-sort-header></mat-header-cell>
          <mat-cell class="text-center" *cdkCellDef="let row" (click)="viewMerchant(row['ID'])">
            <app-ep-document-view-image class="headNavView text-center userIconView" *ngIf="row" [defaultPicture]="
                'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/profile_placeholder.png)'
              " [link]="row['profileLink']" [hideZoomButton]="true"></app-ep-document-view-image>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="TradingAS">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Trading as</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="viewMerchant(row['ID'])" matTooltip="{{ row['TradingAs'] }}">
            {{ row['TradingAs'] }}
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="MerchantName">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Name</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="viewMerchant(row['ID'])" matTooltip="{{ row['CalculatedName'] }}">
            {{ row['CalculatedName'] }}
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="MerchantPhone">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Phone</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="viewMerchant(row['ID'])"
            matTooltip="{{ row['phones.Number'] | customPhone: 'landLine' }}">
            {{ row['phones.Number'] | customPhone: 'landLine' }}
          </mat-cell>
        </ng-container>
        <ng-container cdkColumnDef="MerchantMobile">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Mobile</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="viewMerchant(row['ID'])"
            matTooltip="{{ row['mobiles.Number'] | customPhone }}">
            {{ row['mobiles.Number'] | customPhone }}
          </mat-cell>
        </ng-container>
        <ng-container cdkColumnDef="MerchantEmail">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Email</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="viewMerchant(row['ID'])" matTooltip="{{ row['emails.Email'] }}">
            {{ row['emails.Email'] }}
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Address">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> {{ 'Address2' | translate }} </mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="viewMerchant(row['ID'])"
            matTooltip="{{ row['addresses.Calculated'] }}">
            {{ row['addresses.Unit'] }} {{ row['addresses.Street Nr'] }} {{ row['addresses.Street Name'] }}
            {{ row['addresses.Street Type'] }}
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="State">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> State</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="viewMerchant(row['ID'])" matTooltip="{{ row['addresses.State'] }}">
            {{ row['addresses.State'] }}
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Postcode">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Postcode</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="viewMerchant(row['ID'])"
            matTooltip="{{ row['addresses.Postcode'] }}">
            {{ row['addresses.Postcode'] }}
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="active">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> HH</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="viewMerchant(row['ID'])">
            <span class="list-label smaller" *ngIf="row['HealthHistoryEnabled.Overridden'] == 'Y'" matTooltip="Active"
              style="background-color: rgb(90, 186, 71)">
              <mat-icon>check</mat-icon>
            </span>
            <span class="list-label smaller" *ngIf="row['HealthHistoryEnabled.Overridden'] != 'Y'" matTooltip="Inactive"
              style="background-color: rgb(239, 71, 39)">
              <mat-icon>close</mat-icon>
            </span>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Matrialrequest">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Material Advance</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="viewMerchant(row['ID'])">
            <span class="list-label smaller" *ngIf="row['Disable_Matrial_Request'] == '0'" matTooltip="Active"
              style="background-color: rgb(90, 186, 71)">
              <mat-icon>check</mat-icon>
            </span>

            <strong style="color: rgb(90, 186, 71)" class="ml"
              *ngIf="row['Disable_Matrial_Request'] == '0'">{{row['Percent_Stlmnt_Mtrls_Request']}}%</strong>
            <span class="list-label smaller" *ngIf="row['Disable_Matrial_Request'] != '0'" matTooltip="Inactive"
              style="background-color: rgb(239, 71, 39)">
              <mat-icon>close</mat-icon>
            </span>


            <strong style="color: rgb(239, 71, 39)" class="ml"
              *ngIf="row['Disable_Matrial_Request'] != '0'">{{row['Percent_Stlmnt_Mtrls_Request']}}%</strong>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="CreatedDate">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Join Date</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="viewMerchant(row['ID'])"
            matTooltip="{{ row['DateTimeCreated'] | customDate }}">
            {{ row['DateTimeCreated'] | customDate }}
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Actions">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Actions</mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <div style="float: right">
              <button [matMenuTriggerFor]="menu" mat-icon-button>
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button *ngIf="row['ID']" (click)="viewMerchant(row['ID'])" mat-menu-item>
                  <mat-icon class="smr">search</mat-icon>
                  <span>Quick view</span>
                </button>

                <button *ngIf="row['ID']" (click)="openQuickViewDialog(row['ID'])" mat-menu-item>
                  <mat-icon class="smr">description</mat-icon>
                  <span>Detailed view</span>
                </button>


                <button *ngIf="row['ID']" (click)="staffList(row)" mat-menu-item>
                  <mat-icon class="fas fa-users smr"></mat-icon>
                  <span>Staff List</span>
                </button>



                <mat-divider></mat-divider>


                <button *ngIf="row['ID']" (click)="matrialRequest(row['ID'])" mat-menu-item>
                  <mat-icon class="fas fa-search-dollar smr"></mat-icon>
                  <span>Material request Settings</span>
                </button>


                <button *ngIf="row['ID']" (click)="contact(row['ID'])" mat-menu-item>
                  <mat-icon class="smr">message</mat-icon>
                  <span>Contact merchant</span>
                </button>


                <button *ngIf="isPromoterOrAdmin == true && row['ID']" (click)="merchantAccess(row['ID'])"
                  mat-menu-item>
                  <mat-icon class="fa fa-key smr"></mat-icon>
                  <span>{{ 'Merchant Access' | translate }}</span>
                </button>

                <button *ngIf="isPromoterOrAdmin == true && row['ID']" (click)="PatientPortalAssitance(row['ID'])"
                  mat-menu-item>
                  <mat-icon class="fas fa-hands-helping smr"></mat-icon>
                  <span>{{ "KEYWORD.patient" | translate | titlecase }} Portal Assitance</span>
                </button>
                <button *ngIf="isPromoterOrAdmin == true && row['ID']" (click)="uploadDoc(row['ID'])" mat-menu-item>
                  <mat-icon class="fa fa-file-upload smr"></mat-icon>
                  <span>Upload merchant</span>
                </button>
                <mat-divider *ngIf="isPromoterOrAdmin == true && row['ID']"></mat-divider>
                <button *ngIf="isPromoterOrAdmin == true && row['ID']" (click)="viewMerchantLogs(row['ID'])"
                  mat-menu-item>
                  <mat-icon class="fa fa-list-alt smr"></mat-icon>
                  <span>{{ 'View merchant logs' | translate }}</span>
                </button>

              </mat-menu>




            </div>
          </mat-cell>
        </ng-container>
        <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *cdkRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
      <mat-paginator #paginator [length]="dataSource.filteredData.length" [pageIndex]="0" [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions">
      </mat-paginator>
    </mat-card>
  </div>
</div>
<div *ngIf="listDB.data.length == 0 && listDB.firstLoadEvent != false">
  <app-empty-list-message></app-empty-list-message>
</div>
