<div class="rel dash intro">
  <div class="card-header primary-gradient-img inModal">
    <div class="row titleArea clearfix full-width">
      <img class="animated zoomIn feature-img dashboard character_thumbsup"/>
      <h2 class="summary-header rm-m white">Welcome to the Dashboard!</h2>
    </div>

    <button class="btn-close btn-clear mat-button" (click)="doNotShow()">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <mat-dialog-content>
    <div class="row clearfix add-mb">
      <p class="lead">
        This is your {{ "KEYWORD.practice" | translate }}'s new command centre for communicating with your
        {{ "KEYWORD.patient" | translate }}s!
      </p>
      <p>
        Using the dashboard, you can send and manage {{ "KEYWORD.treatment" | translate }} invitations with the click of
        a button. To get started, press 'New Invitation'.
      </p>
    </div>

    <hr/>
    <mat-checkbox class="pull-left" [(ngModel)]="showOnDashLogin"> Please don't show this message again</mat-checkbox>
    <button class="pull-right" (click)="doNotShow()" mat-raised-button color="accent">Close</button>
  </mat-dialog-content>
</div>
