import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Optional, Output, ViewChild } from '@angular/core';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatChipInputEvent } from '@angular/material';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { UtilsService } from '../../../shared/services/utils.service';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { CustomerProspectCreateComponent } from '../../customer-prospect/customer-prospect-create/customer-prospect-create.component';
import { CustomerProspectViewModelComponent } from '../../customer-prospect/customer-prospect-view-model/customer-prospect-view-model.component';
import { InstantOfferCreateComponent } from '../../instant-finance-offer/instant-offer-create/instant-offer-create.component';
import { MerchantViewComponent } from '../../merchant/merchant-view/merchant-view.component';
import { TagsService } from '../../tags/shared/tags.service';
import { InvitationService } from '../shared/services/invitation.service';

@Component({
  selector: 'app-invitation-lookup',
  templateUrl: './invitation-lookup.component.html',
  styleUrls: ['./invitation-lookup.component.css'],
})
export class InvitationLookupComponent implements OnInit {
  @Input()
  merchantID;
  displayMerchantList = false;
  itemsPerPage = 10;
  headerIcon = null;
  currentPage;
  dateOfBirth;
  isPromoterOrAdmin;
  firstName;
  lastName;
  filterData;
  filterEmail;
  filterName;
  filterPhone;
  mobile;
  calculatedName;
  hideFilter = false;
  showNextBtn = false;
  isModal = false;
  displayFinanceOffer = false;
  @Input()
  useStrict = true;
  @Input()
  displayCreateButton = false;
  patients: any = [];
  selectedPatient: any;
  utilsClass = new UtilsClass();
  @Input()
  isNewPatient = false;



  tableName
  @Input()
  headerTitle = 'Existing patient lookup';

  showBackButton = true;
  closeModal = new EventEmitter();
  close = new EventEmitter();
  @Output()
  getResult = new EventEmitter();
  @Output()
  createNew = new EventEmitter();
  @Output()
  getNewPatient = new EventEmitter();
  @Output()
  getSelectedPatientRaw = new EventEmitter();
  @Output()
  getSelectedPatient = new EventEmitter();
  @Output()
  getSelectedPatientID = new EventEmitter();

  @Output()
  detailedView = new EventEmitter();
  isModuleSMSOfferActive = Settings.global['isModuleSMSOfferActive'];
  isModulePatientSMSActive = Settings.global['isModulePatientSMSActive'];
  @Output()
  patientChat = new EventEmitter();

  profilePic;
  loading = false;
  onlyMyAccount;
  serviceRef;
  isDestoyed = false;
  merchant;
  patientLabel = 'Patient';
  merchants = [];
  title =
    'If you are creating an invitation for an existing patient, you can search for them using any (or all) of the field below:';
  title2 =
    'You detected a possible matching patient records in our database. Please confirm if any of the matches bellow are are the same records as the one you are entering.';

  orderByFilter = '-DateTimeCreated';
  @ViewChild('tagInput', { static: false }) tagInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', { static: false }) matAutocomplete: MatAutocomplete;
  tags = [];
  selectedTags = [];

  tag;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  currentMerchant;
  sessionType = 'guest';

  viewOnClick = false;

  hasMobile = false;
  hasEmail = false;

  displayChatButton = true;

  settings = Settings.global;


  constructor(
    private utilService: UtilsService,
    private router: Router,
    private AuthenticationService: AuthenticationService,
    private invitationService: InvitationService,
    private TagsService: TagsService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data) {
      if (data.invitationID) {
        this.isModal = true;
      }

      if (data.headerIcon) {
        this.headerIcon = data.headerIcon;
        this.isModal = true;
      }

      if (data.tableName) {
        this.tableName = data.tableName;
        this.isModal = true;
      }


      if (data.hasMobile === true) {
        this.hasMobile = true;
      } else if (data.hasMobile === false) {
        this.hasMobile = false;
      }

      if (data.hasEmail === true) {
        this.hasEmail = true;
      } else if (data.hasEmail === false) {
        this.hasEmail = false;
      }
      if (data.headerTitle) {
        this.headerTitle = data.headerTitle;
        this.isModal = true;
      }
      if (data.title) {
        this.title = data.title;
        this.isModal = true;
      }
      if (data.title2) {
        this.title2 = data.title2;
        this.isModal = true;
      }

      if (data.useStrict != null) {
        this.useStrict = data.useStrict;
        this.isModal = true;
      }
      if (data.isNewPatient != null) {
        this.isNewPatient = data.isNewPatient;
        this.isModal = true;
      }

      if (data.displayChatButton != null) {
        this.displayChatButton = data.displayChatButton;
        this.isModal = true;
      }

      if (data.displayFinanceOffer != null) {
        this.displayFinanceOffer = data.displayFinanceOffer;
        this.isModal = true;
      }

      if (data.viewOnClick != null) {
        this.viewOnClick = data.viewOnClick;
        this.isModal = true;
      }
      if (data.displayMerchantList != null) {
        this.displayMerchantList = data.displayMerchantList;
        this.isModal = true;
      }
      if (data.useStrict != null) {
        this.useStrict = data.useStrict;
        this.isModal = true;
      }

      if (data.patients) {
        this.patients = data.patients || [];
        this.isModal = true;
      }

      if (data.merchantID) {
        this.merchantID = data.merchantID || [];
        this.isModal = true;
      }

      if (data.hideFilter != null) {
        this.hideFilter = data.hideFilter || false;
        this.isModal = true;

        if (this.hideFilter === true) {
          this.showBackButton = false;
        }
      }

      if (data.onlyMyAccount != null) {
        this.onlyMyAccount = data.onlyMyAccount || true;
        this.isModal = true;
      }

      if (data.showNextBtn != null) {
        this.showNextBtn = data.showNextBtn || false;
        this.isModal = true;
      }

      if (data.displayCreateButton != null) {
        this.displayCreateButton = data.displayCreateButton || false;
        this.isModal = true;
      }

      if (data.isModal != null) {
        this.isModal = data.isModal;
      }
    }
  }

  ngOnInit() {
    if (this.settings && this.settings['context'] == 'breeze') {
      this.patientLabel = 'Customer';

      this.headerTitle = 'Existing ' + this.patientLabel + ' lookup';

      this.title =
        'If you are creating an offer for an existing  ' +
        this.patientLabel +
        ' , you can search for them using any (or all) of the field below:';
      this.title2 =
        'You detected a possible matching ' +
        this.patientLabel +
        ' records in our database. Please confirm if any of the matches bellow are are the same records as the one you are entering.';
    } else if (this.settings && this.settings['context'] == 'p4p') {
      this.patientLabel = 'Client';

      this.headerTitle = 'Existing ' + this.patientLabel + ' lookup';

      this.title =
        'If you are creating an offer for an existing  ' +
        this.patientLabel +
        ' , you can search for them using any (or all) of the field below:';
      this.title2 =
        'You detected a possible matching ' +
        this.patientLabel +
        ' records in our database. Please confirm if any of the matches bellow are are the same records as the one you are entering.';
    }

    this.utilService.getCurrentAccess().subscribe((access) => {
      if (access) {
        this.setAccess(access);
      }
    });
    this.AuthenticationService.getSessionType().subscribe((sessionType) => {
      if (sessionType) {
        this.sessionType = sessionType;
      }
      this.AuthenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isPromoterOrAdmin = res;

        this.AuthenticationService.getCurrentPractice().subscribe((r) => {
          this.currentMerchant = r;

          this.displayMerchantListEvent();
          this.TagsService.getTagsList(
            {
              fields: 'Label',
              staticAudience: false,
              merchantID: this.merchantID,
              orderBy: 'Label',
            },
            this.sessionType
          ).subscribe((res) => {
            if (res) {
              this.tags = res;
            }
          });
        });
      });
    });
  }

  getPatients() {
    this.currentPage = 1;
    this.AuthenticationService.getSessionType().subscribe((sessionType) => {
      if (sessionType) {
        this.sessionType = sessionType;
      }

      let payload: any = {};

      if (this.isPromoterOrAdmin == true && this.merchantID) {
        payload = {
          email: this.filterEmail,
          name: this.filterName,
          firstName: this.firstName,
          lastName: this.lastName,
          calculatedName: this.calculatedName,
          phone: this.filterPhone,
          mobile: this.mobile,
          merchantID: this.merchantID,
          dateOfBirth: this.dateOfBirth,
          onlyMyAccount: this.onlyMyAccount,
          useStrict: this.useStrict,
          hasMobile:this.hasMobile,
          hasEmail:this.hasEmail,
          tableName:this.tableName,
          section: 1,
          fields:
            'ID,TableName,ExistingPerson_key,LastModified,CalculatedName,phones.Number,DateOfBirth,DateTimeCreated,Name,Salutation,FirstName,Name,MiddleName,Gender.Code,mobiles.Number,emails.Email,Tag.Label,Tag.Description',
        };
      } else if (this.isPromoterOrAdmin == true && !this.merchantID) {
        payload = {
          email: this.filterEmail,
          name: this.filterName,
          firstName: this.firstName,
          lastName: this.lastName,
          calculatedName: this.calculatedName,
          dateOfBirth: this.dateOfBirth,
          phone: this.filterPhone,
          mobile: this.mobile,
          merchantID: this.merchantID,
          onlyMyAccount: this.onlyMyAccount,
          useStrict: this.useStrict,
          hasMobile:this.hasMobile,
          hasEmail:this.hasEmail,
          tableName:this.tableName,
          section: 1,
          fields:
            'ID,TableName,ExistingPerson_key,LastModified,CalculatedName,phones.Number,DateOfBirth,DateTimeCreated,Name,Salutation,FirstName,Name,MiddleName,Gender.Code,mobiles.Number,emails.Email,Tag.Label',
        };
      } else {
        payload = {
          email: this.filterEmail,
          name: this.filterName,
          firstName: this.firstName,
          lastName: this.lastName,
          calculatedName: this.calculatedName,
          dateOfBirth: this.dateOfBirth,
          phone: this.filterPhone,
          mobile: this.mobile,
          useStrict: this.useStrict,
          onlyMyAccount: this.onlyMyAccount,
          hasMobile:this.hasMobile,
          hasEmail:this.hasEmail,
          tableName:this.tableName,
          section: 1,
          fields:
            'ID,TableName,ExistingPerson_key,CalculatedName,LastModified,phones.Number,DateOfBirth,DateTimeCreated,Name,Salutation,FirstName,Name,MiddleName,Gender.Code,mobiles.Number,emails.Email,Tag.Label',
        };
      }

      if (this.selectedTags && this.selectedTags.length > 0 && Array.isArray(this.selectedTags)) {
        payload.tags = this.selectedTags.join(',');
      }
      this.serviceRef = this.invitationService.invitationLookup(payload, this.isPromoterOrAdmin).subscribe((res) => {
        if (res && res.length > 0) {
          this.patients = res;
          this.hideFilter = true;

          const innerFunction = (section) => {
            section = section + 1;
            payload.section = section;

            this.serviceRef = this.invitationService
              .invitationLookup(payload, this.isPromoterOrAdmin)
              .subscribe((res1) => {
                if (res1.length > 0) {
                  this.patients = this.patients.concat(res1);

                  if (this.isDestoyed != true) {
                    innerFunction(section);
                  }
                } else {
                  return true;
                }
              });
          };

          if (this.isDestoyed != true) {
            innerFunction(1);
          }
        } else {
          NotifyAppComponent.displayToast(
            'warning',
            'Sorry!',
            'We did not any match of your patients in our database.'
          );
        }
      });
    });
  }

  ngOnDestroy() {
    this.isDestoyed = true;
    if (this.serviceRef) {
      this.serviceRef.unsubscribe();
    }
  }

  setAccess(res) {
    if (res) {
      this.isModulePatientSMSActive = res['isModulePatientSMSActive'];
      this.isModuleSMSOfferActive = res['isModuleSMSOfferActive'];
    }
  }

  displayInstantOfferEvent(id) {
    if (id) {
      const ref2 = RootAppComponent.dialog.open(InstantOfferCreateComponent, {
        data: {
          cardID: id,
          merchantID: this.merchantID,
          isActive: true,
          sendSMS: true,
          sendEmail: true,
        },
        panelClass: 'noCard',
      });

      ref2.componentInstance.close.subscribe((res) => {
        ref2.close();
      });

      ref2.componentInstance.getResult.subscribe((r) => {
        if (r && r.ID) {
          NotifyAppComponent.displayToast(
            'success',
            'Successfull',
            'Instant Finance Offer is created and sent to the patient.'
          );

          ref2.close();
        }
      });
    }
  }

  displayMerchantListEvent() {
    if (this.isPromoterOrAdmin && this.displayMerchantList == true && this.currentMerchant && this.currentMerchant.ID) {
      if (!this.merchantID) {
        this.merchantID = this.currentMerchant.ID;
        this.merchant = this.currentMerchant;
      }

      this.AuthenticationService.getMerchantList(
        { fields: 'ID,TradingAs,LastModified' },
        this.isPromoterOrAdmin
      ).subscribe((ms) => {
        if (ms) {
          this.merchants = ms;

          if (this.merchantID) {
            const v = this.merchants.findIndex((item) => {
              if (item && item.ID && item.ID == this.merchantID) {
                return true;
              }

              return false;
            });

            if (v !== -1) {
              this.merchant = this.merchants[v];
            }
          }
        }
      });
    }
  }

  viewMerchant(id) {
    const ref = RootAppComponent.dialog.open(MerchantViewComponent, {
      data: id,
      width: '600px',
    });
    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  SelectRecordClickEvent(selectedPatient) {
    if (this.viewOnClick == true) {
      this.openPatientViewDialog(selectedPatient['ID']);
    } else {
      if (selectedPatient && selectedPatient.ID) {
        const user = {
          ID: null,
          prospectID: null,
          existingPatientID: null,
          firstName: null,
          title: null,
          lastName: null,
          middleName: null,
          gender: null,
          mobile: null,
          homePhone: null,
          workPhone: null,
          email: null,
          birthday: null,
          dateOfBirth: null,
          tableName: null,
          existingPersonID: selectedPatient.ID,
        };

        if (selectedPatient.ID) {
          user.ID = selectedPatient.ID;
          user.prospectID = selectedPatient.ID;
        }

        if (selectedPatient.ExistingPerson_key) {
          user.existingPatientID = selectedPatient.ExistingPerson_key;
        }

        if (selectedPatient['TableName']) {
          user.tableName = selectedPatient['TableName'];
        }

        if (selectedPatient['Email']) {
          user.email = selectedPatient['Email'];
        }

        if (selectedPatient['FirstName']) {
          user.firstName = selectedPatient['FirstName'];
        }

        if (selectedPatient['LastName']) {
          user.lastName = selectedPatient['LastName'];
        }

        if (selectedPatient['Mobile'] && selectedPatient['Mobile'] != 'undefined') {
          user.mobile = selectedPatient['Mobile'];
        }

        if (selectedPatient['emails.Email']) {
          user.email = selectedPatient['emails.Email'];
        }

        if (selectedPatient['FirstName']) {
          user.firstName = selectedPatient['FirstName'];
        }

        if (selectedPatient['LastName']) {
          user.lastName = selectedPatient['LastName'];
        }

        if (selectedPatient['Name']) {
          user.lastName = selectedPatient['Name'];
        }

        if (selectedPatient['MiddleName']) {
          user.middleName = selectedPatient['MiddleName'];
        }

        if (selectedPatient['mobiles.Number'] && selectedPatient['user.mobile'] != 'undefined') {
          user.mobile = selectedPatient['mobiles.Number'];
        }

        if (selectedPatient['phones.Number'] && selectedPatient['phones.Number'] != 'undefined') {
          user.homePhone = selectedPatient['phones.Number'];
        }

        if (selectedPatient['Phone.Work.Number'] && selectedPatient['Phone.Work.Number'] != 'undefined') {
          user.workPhone = selectedPatient['Phone.Work.Number'];
        }

        if (selectedPatient['Salutation']) {
          user.title = selectedPatient['Salutation'];
        }

        if (selectedPatient['Gender.Code']) {
          user.gender = selectedPatient['Gender.Code'];
        }

        if (selectedPatient['DateOfBirth'] && selectedPatient['DateOfBirth'] != '0000-00-00') {
          user.birthday = selectedPatient['DateOfBirth'];
        }
        if (selectedPatient['DateOfBirth'] && selectedPatient['DateOfBirth'] != '0000-00-00') {
          user.dateOfBirth = selectedPatient['DateOfBirth'];
        }

        this.getResult.emit(user);
        this.getSelectedPatient.emit(selectedPatient);
        this.getSelectedPatientID.emit(user.ID);
      }
    }
  }

  closeEvent() {
    this.closeModal.emit(true);
    this.close.emit(true);
  }

  noMatch() {
    this.createNew.emit(true);
  }

  selectDateToFilter(d) {
    this.dateOfBirth = d;
  }

  getSelectedTags(e) {
    this.selectedTags = e || [];
  }

  getClientObject() {
    if (this.merchantID) {
      const v = this.merchants.findIndex((item) => {
        if (item && item.ID && item.ID == this.merchantID) {
          return true;
        }

        return false;
      });

      if (v !== -1) {
        this.merchant = this.merchants[v];
      }
    }
  }

  openPatientViewDialog(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(CustomerProspectViewModelComponent, {
        data: {
          patientID: id,
          isFullDetails: true,
        },
        width: '700px',
      });
      ref.componentInstance.detailedView.subscribe((res) => {
        ref.close();
        ref.afterClosed().subscribe((r) => {
          this.detailedView.emit(true);
        });
      });

      ref.componentInstance.close.subscribe((res) => {
        if (res == true) {
          ref.close();
        }
      });
    }
  }

  displayChat(id) {
    if (id) {
      this.patientChat.emit({
        cardID: id,
        merchantID: this.merchantID,
        isPromoterOrAdmin: this.isPromoterOrAdmin,
      });
    }
  }

  viewPatient(id) {
    if (id) {
      const confirm = new ConfirmDialog(
        'fas fa-info',

        'Do you want to continue?',
        'You are about to leave this page.',
        'Cancel',
        'Okay'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
        width: '500px',
      });

      ref.componentInstance.onConfirm.subscribe((res) => {
        if (res == true) {
          ref.close();

          ref.afterClosed().subscribe((r) => {
            this.router.navigate([
              '/merchant',
              {
                outlets: {
                  page: ['customer-profile', id],
                },
              },
            ]);
            this.closeEvent();
          });
        } else {
          ref.close();
        }
      });
    }
  }

  orderBy(d) {
    if (d) {
      if (this.orderByFilter === d) {
        this.orderByFilter = '-' + d;
      } else {
        this.orderByFilter = d;
      }
    }
  }

  removeTag(t: string): void {
    const index = this.selectedTags.indexOf(t);

    if (index >= 0) {
      this.selectedTags.splice(index, 1);
    }
  }

  tagChange(t) {
    if (t) {
      const index = this.selectedTags.indexOf(t);

      if (index == -1 && typeof t == 'string') {
        this.selectedTags.push(t);
      }

      this.tag = null;
    }
  }

  goBack() {
    this.hideFilter = false;
  }

  goForward() {
    this.hideFilter = true;
  }

  addTag(event: MatChipInputEvent): void {
    const input = event.input;
    let value = event.value;

    if (value) {
      value = value.trim();
      const index = this.selectedTags.indexOf(value);

      if (index == -1 && typeof value == 'string') {
        this.selectedTags.push(value);
      }
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
    this.tag = null;
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    if (event && event.option && event.option.viewValue) {
      const v = event.option.viewValue;
      const index = this.selectedTags.indexOf(v);

      if (index == -1 && typeof v == 'string') {
        this.selectedTags.push(v);
      }
      this.tagInput.nativeElement.value = '';
      this.tag = null;
    }
  }

  changePage(e) {
    if (e) {
      this.currentPage = e;
    }
  }

  newPatient() {
    const ref2 = RootAppComponent.dialog.open(CustomerProspectCreateComponent, {
      data: {
        merchantID: this.merchantID,
        isEmailRequired: true,
      },
      width: '850px',
    });

    ref2.componentInstance.close.subscribe((res) => {
      ref2.close();
    });
    ref2.componentInstance.getResult.subscribe((res) => {
      if (res && res.ID) {
        ref2.close();
        this.patients = [res];
        this.hideFilter = true;

        this.getNewPatient.emit(res);
      }
    });
  }
}
