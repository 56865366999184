import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fromObjectToArray',
})
export class FromObjectToArrayPipe implements PipeTransform {
  transform(expression: any): any {
    let result = [];

    if (expression && typeof expression === 'object') {
      result = Object.values(expression);
    } else if (Array.isArray(expression) && expression.length > 0) {
      result = expression;
    }

    return result;
  }
}
