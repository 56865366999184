import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { VerticalTimelineModule } from 'angular-vertical-timeline';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { NgPipesModule } from 'ngx-pipes';
import { SharedModule } from '../../shared/shared.module';
import { CustomerProspectModule } from '../customer-prospect/customerProspect.module';
import { ActionLogInterfaceComponent } from './action-log-interface/action-log-interface.component';
import { ActionLogListComponent } from './action-log-list/action-log-list.component';
import { ActionLogViewComponent } from './action-log-view/action-log-view.component';
import { ActivityListMenuComponent } from './activity-list-menu/activity-list-menu.component';
import { ActivityListComponent } from './activity-list/activity-list.component';
import { EventLogListComponent } from './event-log-list/event-log-list.component';
import { EventLogViewComponent } from './event-log-view/event-log-view.component';
import { ActionLogRoutingModule } from './shared/action-log-routing.module';
import { ActionLogService } from './shared/action-log.service';

@NgModule({
  imports: [
    NgPipesModule,
    CommonModule,
    ActionLogRoutingModule,
    SharedModule,
    VerticalTimelineModule,
    FormsModule,
    ReactiveFormsModule,
    CustomerProspectModule,
    PerfectScrollbarModule,
  ],
  declarations: [
    ActionLogViewComponent,
    ActionLogListComponent,
    ActionLogInterfaceComponent,
    EventLogListComponent,
    EventLogViewComponent,
    ActivityListComponent,
    ActivityListMenuComponent,
  ],
  exports: [
    ActionLogViewComponent,
    ActionLogListComponent,
    ActionLogInterfaceComponent,
    EventLogListComponent,
    EventLogViewComponent,
    ActivityListComponent,
    ActivityListMenuComponent,
  ],
  providers: [ActionLogService],
})
export class ActionLogModule {}
