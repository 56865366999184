import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ClipboardService } from 'ngx-clipboard';
import { MAT_DIALOG_DATA } from '@angular/material';
import { DocumentService } from '../shared/document.service';
import { environment } from 'environments/environment';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { UtilsService } from '../../../shared/services/utils.service';

import * as moment from 'moment';
import { animate, state, style, transition, trigger } from "@angular/animations";
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { IframeViewComponent } from '../../../shared/components/iframe-view/iframe-view.component';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { CustomPhonePipe } from '../../../shared/pipes/custom-phone.pipe';
import { DentistService } from '../../dentist/shared/dentist.service';
import { ProductService } from '../../product/shared/product.service';
import { ProductUtil } from '../../product/shared/productUtil.type';
import { BlobViewComponent } from '../../../shared/components/blob-view/blob-view.component';
import { CurrencyPipe } from '@angular/common';
import { CustomCurrencyPipe } from '../../../shared/pipes/custom-currency.pipe';
import { LandingPageEmbedComponent } from '../../merchant/landing-page-embed/landing-page-embed.component';
import { DocumentLinkPdfCreateComponent } from '../document-link-pdf-create/document-link-pdf-create.component';

@Component({
  selector: 'document-link-share',
  templateUrl: './document-link-share.component.html',
  styleUrls: ['./document-link-share.component.css'],
  animations: [
    trigger("simpleFadeAnimation", [
      state("in", style({ opacity: 1, height: "*" })),
      transition(":enter", [style({ opacity: 0, height: 0 }), animate(300)]),
      transition(":leave", animate(300, style({ opacity: 0, height: 0 })))
    ])
  ]
})
export class DocumentLinkShareComponent implements OnInit {
  @Output()
  close = new EventEmitter();
  sessionType
  isPromoterOrAdmin = false;
  merchantID;
  contactID;
  currentPractice
  currentDentist;
  landingPageID;
  landingPage;

  displayMessage = false;
  isDisabled = false;


  landingPageContactID

  _ID
  text


  utils = new UtilsClass();

  socialMediaDescription
  Contact_key
  promoter;
  card: any = {
    ID: '00000000000000000000',
    Salutation: 'Mr',
    FirstName: 'John',
    MiddleName: 'Al',
    Name: 'Doe',
    'mobiles.Number': '0061101010101',
    'phones.Number': '0062202020202',
    'emails.Email': 'john.doe@smileright.com.au',
    'addresses.Calculated': 'PO Box 7795 Cloisters Square 6850 Western Australia',
  };


  socialMediaImage

  iframeLink;

  downloadQRCodeDental = new EventEmitter();

  env = environment.nodeUrl
  urlQR;
  dentists = [];
  financedProductGroup = [];

  productGroup;


  landingPageLinkID;
  treatmentAmount;
  maxLoanAmount
  minLoanAmount

  isExpired = false;

  expireDate;
  expireDateType = 'never'
  expireDateTypes = [
    {
      code: '1days',
      label: '1 Day',
      days: 1
    },

    {
      code: '3days',
      label: '3 Days',
      days: 3
    },
    {
      code: 'week',
      label: '1 Week',
      days: 7
    },
    {
      code: '2weeks',
      label: '2 Weeks',
      days: 15
    },
    {
      code: 'month',
      label: '1 Month',
      days: 30
    },
    {
      code: 'month',
      label: '3 Months',
      days: 90
    },
    {
      code: 'year',
      label: '1 Year',
      days: 365
    },
    {
      code: 'setDate',
      label: 'Set Date',
      days: 10
    },
    {
      code: 'never',
      label: 'Never'
    },

  ]
  expireDateDefault
  productUtil = new ProductUtil();

  constructor(
    private dentistService: DentistService,
    private productService: ProductService,
    private utilService: UtilsService,
    private _clipboardService: ClipboardService,
    private authenticationService: AuthenticationService,
    private documentService: DocumentService,
    private customPhonePipe: CustomPhonePipe,
    private currencyPipe: CustomCurrencyPipe,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    if (data) {
      if (data && data.landingPage && data.landingPage.ID) {
        this.landingPageID = data.landingPage.ID
        this.landingPage = data.landingPage
      }
      else if (data && data.ID) {
        this.landingPageID = data.ID
      }
      else if (data && data.id) {
        this.landingPageID = data.id
      }
      else if (data && data.landingPageID) {
        this.landingPageID = data.landingPageID
      }
      if (data && data.dentists && data.dentists.length > 0) {
        this.dentists = data.dentists
      }

      if (data && data.financedProductGroup && data.financedProductGroup.length > 0) {
        this.financedProductGroup = data.financedProductGroup
      }

    }
  }

  ngOnInit() {
    this.authenticationService.getPromoter().subscribe(promoter => {
      if (promoter && promoter.data) {
        this.promoter = promoter.data
      } else if (promoter) {
        this.promoter = promoter;
      }


      this.authenticationService.getSessionType().subscribe(sessionType => {

        this.sessionType = sessionType;

        this.authenticationService.isPromoterOrAdmin().subscribe(isPromoterOrAdmin => {

          this.isPromoterOrAdmin = isPromoterOrAdmin;

          this.authenticationService.getCurrentPractice().subscribe(r => {
            if (r && r.ID) {
              this.merchantID = r.ID;
              this.currentPractice = r;
            }


            this.authenticationService.getCurrentDentist().subscribe(d => {
              if (d && d.ID) {
                this.contactID = d.ID;
                this.currentDentist = d;
              }

              if (this.landingPage && this.landingPage.ID) {
                this.setup()
              }
              else if (this.landingPageID) {
                this.documentService.getDocumentLink(this.landingPageID, {}, this.sessionType).subscribe(l => {
                  if (l && l.ID) {
                    this.landingPage = l;
                  }
                  this.setup()

                })

              }

            })
          })
        })
      })
    })
  }
  setExpireDate() {
    if (this.expireDateType && this.expireDateType !== 'never') {

      let index = this.expireDateTypes.findIndex(item => {
        if (item && item.code && item.code === this.expireDateType) {
          return true
        }
        return false;
      })

      if (index != -1) {
        let item = this.expireDateTypes[index];

        if (item && item.days) {
          this.expireDateDefault = moment().add(item.days, 'd').toDate()
        }
      }
    }

    this.isExpired = false;
  }

  setupFinance() {

    if (this.landingPage && this.landingPage['Type.Code'] === 'FLandPg') {

      this.treatmentAmount = this.landingPage['Treatment_Amount']

      this.landingPageContactID = this.landingPage['Contact_key']



      if (this.dentists.length === 0) {
        this.dentistService.getList({ merchantID: this.merchantID, fields: 'ID,CalculatedName' }, this.isPromoterOrAdmin).subscribe(res => {
          if (res && res.length > 0) {
            this.dentists = res;
          }
        })
      }


      if (this.financedProductGroup.length === 0) {

        let p1 = {
          merchantID: this.merchantID,
          isFinance: true,
        };

        this.productService.getMerchantProductGroup(p1, this.isPromoterOrAdmin).subscribe((res) => {
          if (res && res.length > 0) {
            this.financedProductGroup = res;
            let index = this.financedProductGroup.findIndex(item => {
              if (item && item['GroupCode'] === this.landingPage['Product_Group.Code']) {
                return true
              }
              return false;
            })
            if (index != -1) {
              this.productGroup = this.landingPage['Product_Group.Code']
            }




            this.getLoanAmount(this.productGroup)
          }

        })

      }
      else if (this.financedProductGroup && this.financedProductGroup.length > 0) {
        let index = this.financedProductGroup.findIndex(item => {
          if (item && item['GroupCode'] === this.landingPage['Product_Group.Code']) {
            return true
          }
          return false;
        })
        if (index != -1) {
          this.productGroup = this.landingPage['Product_Group.Code']
        }




        this.getLoanAmount(this.productGroup)
      }
    }

  }


  buildContentDescription(text) {


    if (
      this.currentDentist &&
      this.currentPractice &&
      this.promoter &&
      this.card &&
      text
    ) {
      return this.utils.fillMessage(
        this.promoter,
        this.currentPractice,
        this.currentDentist,
        this.card,
        text,
        false,
        this.customPhonePipe,
        this.currencyPipe
      );
    }

    return text;
  }


  setup() {

    if (this.landingPage && this.landingPage.ID) {
      this._ID = this.landingPage.ID;
    }



    this.buildURL()
    this.setupFinance()

    if (this.landingPage && this.landingPage.ID) {

      if (this.landingPage['Header_Image'] === 'merchant') {

        this.socialMediaImage = environment.nodeUrl + '/merchant/practice-picture-stream/' + this.currentPractice.ID;
      }
      else if (this.landingPage['Header_Image']) {

        this.socialMediaImage = this.landingPage['Header_Image'];
      }
      else {
        this.socialMediaImage = environment.nodeUrl + '/merchant/practice-picture-stream/' + this.currentPractice.ID;
      }


      if (this.landingPage['Message']) {


        this.documentService.removeHTML(this.buildContentDescription(this.landingPage['Message'])).subscribe(r => {
          if (r) {
            this.text = r;
          }
        })


      }

    }
  }

  buildURL() {
    let urlQR = `${Settings.global["publicSiteFrontendLink"]}/landing-page-overview/${this._ID}/qr`;

    if (this.landingPage['IsDefault'] === '1') {
      urlQR = `${Settings.global["publicSiteFrontendLink"]}/landing-page-overview/${this._ID}/qr?m=${this.currentPractice.ID}&c=${this.currentDentist.ID}`;
    }
    this.utilService.createShortcut(urlQR).subscribe((r) => {
      if (r && r.shortLink) {

        this.urlQR = r.shortLink

      }
    })
  }
  closeEvent() {
    this.close.emit(true)
  }
  copy(type = 'others') {
    if (type) {
      let url = this.documentService.getLinkByVisitorType(this._ID, type)
      this._clipboardService.copyFromContent(url);
      NotifyAppComponent.displayToast("success", "Copied!", "");
    }
  }

  textWithoutHtml(description) {

    if (description) {

      let message = description;

      message = message.replace(/(<br>)/g, '\n');

      message = message.replace(/(&nbsp;)/g, ' ');
      message = message.replace(/(&ndash;)/g, ' ');
      message = message.replace(/(&mdash;)/g, ' ');


      message = message.replace(/(&nbsp)/g, ' ');
      message = message.replace(/(&ndash)/g, ' ');
      message = message.replace(/(&mdash;)/g, ' ');

      message = message.replace(/ +/g, ' ')
      return message.replace(/<(?:.|\n)*?>/gm, ' ');
    }

    return ''



  }
  openIframe() {
    if (this._ID) {
      let link = `${Settings.global['publicSiteFrontendLink']}/landing-page-overview/${this._ID}`;

      if (this.landingPage['IsDefault'] === '1' && this.isPromoterOrAdmin != true) {
        link = `${Settings.global['publicSiteFrontendLink']}/landing-page-overview/${this._ID}?m=${this.merchantID}&c=${this.contactID}`;
      }
      let ref2 = RootAppComponent.dialog.open(IframeViewComponent, {
        data: {
          link: link,
          isSubmit: false,
          extraDetails: this.landingPage.IsDefault != '1' || this.isPromoterOrAdmin == true
        },
        width: '80%',
        height: '92vh',
        panelClass: 'noCard',
      });

      ref2.componentInstance.close.subscribe((res) => {
        ref2.close();
      });

      ref2.componentInstance.getSubmit.subscribe((res) => {
        if (res) {
          ref2.close();

        }
      });
    }
  }
  viewEmbed() {
    if (this._ID) {

      let ref2 = RootAppComponent.dialog.open(LandingPageEmbedComponent, {
        data: {
          landingPageID: this._ID,
        },
        width: '600px',
        panelClass: 'noCard',
      });

      ref2.componentInstance.close.subscribe((res) => {
        ref2.close();
      });

    }
  }

  enable(ID) {
    const confirmDialog = new ConfirmDialog(
      'check_circle',
      'Enable Landing Page',
      '<p>Once the Landing Page is Active it will  appear as an option for the user.</p>',
      'Cancel',
      'Okay'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
      // SET TO PAYING CASH CARD
    });
    ref.componentInstance.onConfirm.subscribe((val) => {
      if (val == true) {
        ref.close();
        ref.afterClosed().subscribe((res) => {
          const payload = {
            isActive: true,
          };
          this.documentService.modifyDocumentLink(ID, payload, this.sessionType).subscribe((res) => {
            if (res) {

              this.landingPage = res;


              NotifyAppComponent.displayToast('success', 'Success!', 'Landing Page is Active.');
            }
          });
        });
      } else {
        ref.close();
      }
    });
  }

  ViewPDF() {
    if (this.landingPage && this.landingPage.ID) {

      this.documentService.getLandingPagePDFBlob(this.landingPage.ID, { hideMessage: !this.displayMessage, lastModified: this.landingPage['LastModified'] }, this.sessionType).subscribe(content => {

        let ref = RootAppComponent.dialog.open(BlobViewComponent, {
          data: {
            content: content,
            title: null,
            label: this.landingPage.Label,
            description: null,
            isProceed: false,
            isPrint: false,
            downloadPullRight: true,
            noTitleMode: true,
          },
          width: "800px",
        });

        ref.componentInstance.closeModal.subscribe((res) => {
          if (res == true) {
            ref.close();
          }
        });
      })


    }
  }

  customizePDF() {
    if (this.landingPage && this.landingPage.ID) {
      const ref = RootAppComponent.dialog.open(DocumentLinkPdfCreateComponent, {
        data: {
          landingPageID: this.landingPage.ID
        },
        panelClass: ['noCard', 'max-width-panel-1200'],
        width: '90vw',
      });
      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });

      ref.componentInstance.getResultWithContent.subscribe((res) => {
        ref.close();
        ref.afterClosed().subscribe(r => {



          this.landingPage['LastModified'] = res.LastModified

          let ref3 = RootAppComponent.dialog.open(BlobViewComponent, {
            data: {
              content: res.content,
              title: null,
              label: this.landingPage.Label,
              description: null,
              isProceed: false,
              isPrint: false,
              downloadPullRight: true,
              noTitleMode: true,
            },
            width: "800px",
          });

          ref3.componentInstance.closeModal.subscribe((res) => {
            if (res == true) {
              ref3.close();
            }
          });

        });
      });

      

    }
  }

  getLoanAmount(productCode, generateLink = true) {
    if (productCode) {
      let payload = {
        groupCode: productCode,
        merchantID: this.merchantID,
        fields: 'Parameter.MaxLoanValue,Parameter.MinLoanValue',
      };
      this.productService.getList(payload, this.isPromoterOrAdmin).subscribe((res) => {
        if (res && res.length > 0) {
          let temp = res.map((x) => Number(x['Parameter.MaxLoanValue']));
          this.maxLoanAmount = Math.max(...temp) || 0;


          let temp2 = res.map((x) => Number(x['Parameter.MinLoanValue']));
          this.minLoanAmount = Math.min(...temp2) || 0;


        }

        if (generateLink == true) {
          this.calculDisable()
        }
      });
    }
  }
  selectDateToFilter(d) {
    this.expireDate = d;

    this.calculDisable()

  }

  saveLandingPageLink() {


    this.calculDisable()


    if (this.landingPage &&
      this.treatmentAmount &&
      this.isDisabled == false &&
      this.productGroup &&
      this.landingPage['Type.Code'] === 'FLandPg' &&
      (
        String(this.landingPage['Treatment_Amount']) != String(this.treatmentAmount) ||
        this.landingPage['Contact_key'] != this.landingPageContactID ||
        this.landingPage['Product_Group.Code'] != this.productGroup
      )
    ) {


      let p = {
        productGroup: this.productGroup,
        senderID: this.landingPageContactID,
        treatmentAmount: this.treatmentAmount,
        landingPageID: this.landingPage.ID,
        expireDate: null,

        canExpire: false,
      }
      if (this.expireDateType != 'never') {
        p.canExpire = true;
        p.expireDate = this.expireDate
      }
      if (this.landingPageLinkID) {
        this.documentService.modifyLandingPageLink(this.landingPageLinkID, p, this.sessionType).subscribe(r => {

          if (r && r.ID) {
            this._ID = r.ID;

            NotifyAppComponent.displayToast('success', 'Successful', 'The new customized link is generated');

            this.buildURL()

          }
        })
      }
      else {
        this.documentService.createLandingPageLink(p, this.sessionType).subscribe(r => {

          if (r && r.ID) {
            this._ID = r.ID;
            this.landingPageLinkID = r.ID;
            NotifyAppComponent.displayToast('success', 'Successful', 'The new customized link is generated');
            this.buildURL()
          }
        })
      }

    }

  }
  showExpired() {
    if (moment(this.expireDate).isBefore(moment()) && moment(this.expireDate).format('YYYY-MM-DD') !== moment().format("YYYY-MM-DD") && this.expireDateType !== 'never') {
      return true
    }

    return false
  }

  calculDisable() {



    if (this.landingPage['Type.Code'] === 'FLandPg') {

      if (this.treatmentAmount && this.productGroup && this.landingPageContactID && this.minLoanAmount && this.maxLoanAmount &&
        Number(this.treatmentAmount) >= Number(this.minLoanAmount) && Number(this.treatmentAmount) <= Number(this.maxLoanAmount)
      ) {


        this.isDisabled = false

      }
      else {
        this.isDisabled = true;
      }

    }
    else {
      this.isDisabled = false
    }



  }
}
