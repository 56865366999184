import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'environments/environment';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { UtilsService } from '../../services/utils.service';
import { UtilsClass } from '../../types/utils/utils.class';
import { EpDocumentViewImageService } from '../ep-document-view-image/shared/ep-document-view-image.service';
import { ViewImageSimpleComponent } from '../view-image-simple/view-image-simple.component';

@Component({
  selector: 'app-ep-document-view-image',
  templateUrl: './ep-document-view-image.component.html',
  styleUrls: ['./ep-document-view-image.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.row', style({ opacity: 0 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.row', style({ opacity: 1 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({
                  opacity: 0,
                  transform: 'translateY(-75%)',
                  offset: 1.0,
                }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
  providers: [EpDocumentViewImageService],
})
export class EpDocumentViewImageComponent implements OnInit, OnDestroy {
  @Input()
  height;

  @Input()
  width;
  @Input()
  notChecked = false;

  @Input()
  document;

  @Input()
  defaultLockedDocumentPicture =
    'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/document-prototype.png)';

  @Input()
  defaultPicture: any =
    'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/default-image-thumbnail.png)';

  @Input()
  imageLink;

  imageLinkURL;

  @Input()
  linkURL;

  @Input()
  link;

  @Input()
  loadingImg = 'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/ep-loader.gif)';

  @Input()
  loading = true;

  @Input()
  isFullScreen = true;

  @Input()
  contentAttribute = 'PhysicalFileInBLOB';

  @Input()
  content;

  @Input()
  hideZoomButton = false;

  @Input() trueZoom = false;

  @Input()
  fullScreen = false;

  doc;

  isLoaded = true;
  displayFullSizeButton = false;

  @Output()
  getClick = new EventEmitter();

  @Output()
  showFullSize = new EventEmitter();

  fileURL: any;

  fileURLRaw: any;

  scroll$: any;

  accountType = 'guest';

  pinchZoomProperties = [
    {
      wheel: true,
      autoHeight: true,
    },
  ];

  utils = new UtilsClass();

  isImageError = false;
  isImageLoaded = false;

  activeBackground;

  @ViewChild('iframe', { static: false }) iframe: ElementRef;

  constructor(
    private authenticationService: AuthenticationService,
    public dialogLocal: MatDialog,
    private domSanitizer: DomSanitizer,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    @Inject(NgZone) private zone: NgZone,
    private utilsService: UtilsService,
    private epDocumentViewImageService: EpDocumentViewImageService
  ) {
    if (data) {
      this.doc = data;
    }
  }

  ngOnInit() {
    this.setup();
  }

  ngOnDestroy() {
    /** required for untilDestroyed (Replace with @UntilDestroyed() Decorator in angular 9 and delete ngOnDestroy()) */
  }

  ngOnChanges(changes: SimpleChanges) {
    this.authenticationService.getSessionType().subscribe((res1) => {
      this.accountType = res1;

      if (
        (changes &&
          changes.link &&
          changes.link.previousValue &&
          changes.link.currentValue &&
          changes.link.currentValue != changes.link.previousValue) ||
        (changes.document && !changes.document.previousValue && changes.document.currentValue) ||
        (changes.document &&
          changes.document.previousValue &&
          changes.document.currentValue &&
          typeof changes.document.currentValue != typeof changes.document.previousValue) ||
        (changes.document &&
          changes.document.previousValue &&
          changes.document.currentValue &&
          typeof changes.document.currentValue == 'string' &&
          typeof changes.document.previousValue == 'string' &&
          changes.document.currentValue != changes.document.previousValue) ||
        (changes.content &&
          changes.content.previousValue &&
          changes.content.currentValue &&
          changes.content.currentValue != changes.content.previousValue) ||
        (changes.imageLink &&
          changes.imageLink.previousValue &&
          changes.imageLink.currentValue &&
          changes.imageLink.currentValue != changes.imageLink.previousValue) ||
        (changes.document &&
          changes.document.previousValue &&
          changes.document.currentValue &&
          typeof changes.document.currentValue == 'object' &&
          typeof changes.document.previousValue == 'object' &&
          changes.document.currentValue.ID != changes.document.previousValue.ID) ||
        (changes && changes.link && !changes.link.previousValue && changes.link.currentValue)
      ) {
        this.setup();
      }
    });
  }

  setup() {
    this.isImageError = false;
    this.isImageError = false;
    if (this.defaultPicture && typeof this.defaultPicture == 'string' && this.defaultPicture.indexOf('url') == -1) {
      this.defaultPicture = 'url(' + this.defaultPicture + ')';
    }

    if (!this.link) {
      this.linkURL = null;
    }

    if (this.link && typeof this.link == 'string') {
      if (this.link.indexOf(environment.nodeUrl) == -1) {
        if (this.link[0] == '/') {
          this.link = environment.nodeUrl + this.link;
        } else {
          this.link = environment.nodeUrl + '/' + this.link;
        }
      }

      if (this.link.indexOf('url') == -1) {
        this.linkURL = 'url(' + this.link + ')';
      } else {
        this.linkURL = this.link;
      }
    } else if (this.imageLink) {
      if (typeof this.imageLink == 'string' && this.imageLink.indexOf('url') == -1) {
        this.imageLinkURL = 'url(' + this.imageLink + ')';
      } else if (typeof this.imageLink == 'string' && this.imageLink.indexOf('url') != -1) {
        this.imageLink = this.imageLink.replace('url(', '');
        this.imageLink = this.imageLink.replace(')', '');

        this.imageLinkURL = 'url(' + this.imageLink + ')';
      }
    } else if (!this.document && this.content) {
      this.doc = {};

      this.doc[this.contentAttribute] = this.content;
      this.displayDoc();
    } else if (this.document && typeof this.document == 'object') {
      this.doc = this.document;
      this.displayDoc();
    } else if (this.document && typeof this.document == 'string') {
      this.utilsService.getEpdocumentMiniDetails(this.document).subscribe((res) => {
        if (res) {
          this.document = res;
          this.doc = this.document;
          this.displayDoc();
        }
      });
    } else {
      this.doc = null;
    }
  }

  getWithoutURL(_d) {
    let d = _d;
    d = _d.replace('url(', '');
    d = _d.replace(')', '');

    return d;
  }

  displayDoc() {
    this.fileURL = null;
    this.fileURLRaw = null;
    if (
      this.doc &&
      this.doc['ID'] &&
      this.document['Extension'] &&
      this.utils.isImageExtension(this.document['Extension'])
    ) {
      this.link = this.utilsService.getDocumentStreamLink(this.doc['ID']);
      if (this.link.indexOf('url') == -1) {
        this.linkURL = 'url(' + this.link + ')';
      } else {
        this.linkURL = this.link;
      }
    } else if (this.doc && this.doc[this.contentAttribute]) {
      if (this.doc && this.doc.Extension == 'png') {
        const file = this.base64toBlob(this.doc[this.contentAttribute], 'image/png');
        this.fileURL = this.domSanitizer.bypassSecurityTrustStyle('url(' + window.URL.createObjectURL(file) + ')');

        this.fileURLRaw = window.URL.createObjectURL(file);
      } else if (this.doc && (this.doc.Extension == 'jpeg' || this.doc.Extension == 'jpg')) {
        const file = this.base64toBlob(this.doc[this.contentAttribute], 'image/jpeg');
        this.fileURL = this.domSanitizer.bypassSecurityTrustStyle('url(' + window.URL.createObjectURL(file) + ')');

        this.fileURLRaw = window.URL.createObjectURL(file);
      }else if (this.doc && (this.doc.Extension == 'heic' || this.doc.Extension == 'heic')) {
        const file = this.base64toBlob(this.doc[this.contentAttribute], 'mage/heic');
        this.fileURL = this.domSanitizer.bypassSecurityTrustStyle('url(' + window.URL.createObjectURL(file) + ')');

        this.fileURLRaw = window.URL.createObjectURL(file);
      } else {
        this.doc.Extension = 'png';
        const file = this.base64toBlob(this.doc[this.contentAttribute], 'image/png');
        this.fileURL = this.domSanitizer.bypassSecurityTrustStyle('url(' + window.URL.createObjectURL(file) + ')');

        this.fileURLRaw = window.URL.createObjectURL(file);
      }
    }
  }

  base64toBlob(base64Data, contentType) {
    contentType = contentType || '';
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  emitClick() {
    this.getClick.emit(true);
  }

  displayFullSizeButtonEvent(e) {
    this.displayFullSizeButton = e;
  }

  fullscreen(link) {
    if (link) {
      const _link = this.domSanitizer.bypassSecurityTrustResourceUrl(link);

      const ref = this.dialogLocal.open(ViewImageSimpleComponent, {
        data: {
          link: _link,
          defaultImage: this.defaultPicture,
        },

        width: '800px',
        panelClass: 'noCard',
      });
      this.showFullSize.emit(true);
      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
      ref.afterClosed().subscribe((res) => {
        this.showFullSize.emit(false);
      });
    }
  }

  fullscreenImageLink(link) {
    if (link) {
      const _link = this.domSanitizer.bypassSecurityTrustResourceUrl(link);

      const ref = this.dialogLocal.open(ViewImageSimpleComponent, {
        data: {
          link: _link,
          defaultImage: this.defaultPicture,
        },
        width: '800px',
        panelClass: 'noCard',
      });
      this.showFullSize.emit(true);
      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
      ref.afterClosed().subscribe((res) => {
        this.showFullSize.emit(false);
      });
    }
  }

  onLoad(activeImage) {
    this.isImageLoaded = true;
    this.isImageError = false;
    this.loading = false;

    this.activeBackground = activeImage;
  }

  onError(defaultImage) {
    this.isImageLoaded = false;
    this.isImageError = true;

    this.loading = false;

    this.activeBackground = defaultImage;
  }
}
