import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-video-tutorials-modal',
  templateUrl: './video-tutorials-modal.component.html',
  styleUrls: ['./video-tutorials-modal.component.css'],
  animations: [
    trigger('simpleFadeAnimation', [
      state('in', style({ opacity: 1, height: '*' })),
      transition(':enter', [style({ opacity: 0, height: 0 }), animate(300)]),
      transition(':leave', animate(300, style({ opacity: 0, height: 0 }))),
    ]),
  ],
})
export class VideoTutorialsModalComponent implements OnInit {
  @Input()
  isModal = false;

  @Output()
  closeModal = new EventEmitter();

  @Input()
  title;

  @Input()
  mainCategory;

  @Input()
  subcategory;

  @Input()
  autoplay = true;

  toggleSelect = false;

  mainGroups = [
    { id: 1, title: 'Treatment Invitations' },

    { id: 2, title: 'Patients' },

    { id: 3, title: 'Practice Development' },

    { id: 4, title: 'Settings' },
  ];

  subCategories = [
    // invitations
    { value: '389155530', title: 'Treatment templates - Overview', parentId: 1 },
    { value: '418331841', title: 'Treatment Templates - How to use', parentId: 1 },
    { value: '428497337', title: 'Treatment templates - How to create', parentId: 1 },
    { value: '418752721', title: 'Treatment plans - Personalising treatment', parentId: 1 },
    { value: '356129743', title: 'Treatment plans - Cloning/Copying', parentId: 1 },

    // patients
    { value: '362512520', title: 'How patients view treatment - Overview', parentId: 2 },

    // practice development
    { value: '387893111', title: 'How much time should you invest in your patients? (Part 1/3)', parentId: 3 },
    { value: '395975821', title: 'What are my patients really thinking? (Part 2/3)', parentId: 3 },
    { value: '397210711', title: 'Secrets to increasing treatment plan acceptance (Part 3/3)', parentId: 3 },
    { value: '397362136', title: 'How to build a great practice (Complete video)', parentId: 3 },

    // settings
    { value: '358964874', title: 'Default Messages - How to set up', parentId: 4 },
    { value: '355935655', title: 'Cash Discounts - How to set up', parentId: 4 },
  ];

  @Input()
  defaultSelectedCategory = '';

  @Input()
  defaultSelectedTopic = '418752721';

  @Input()
  videoID;

  safeUrl: any;

  constructor(
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.isModal != null) {
        this.isModal = data.isModal;
      }

      if (data.defaultSelectedTopic != null) {
        this.defaultSelectedTopic = data.defaultSelectedTopic;
      }

      if (data.subcategory != null) {
        this.subcategory = data.subcategory;
      }

      if (data.mainCategory != null) {
        this.mainCategory = data.mainCategory;
      }

      if (data.videoID != null) {
        this.videoID = data.videoID;
      }

      if (data.autoplay != null) {
        this.autoplay = data.autoplay;
      }
    }
  }

  ngOnInit() {
    if (this.autoplay == true) {
      this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
        'https://player.vimeo.com/video/' + this.defaultSelectedTopic + '?autoplay=1?byline=0&portrait=0'
      );
    } else {
      this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
        'https://player.vimeo.com/video/' + this.defaultSelectedTopic + '?byline=0&portrait=0'
      );
    }

    if (this.videoID) {
      this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
        'https://player.vimeo.com/video/' + this.videoID + '?autoplay=1?byline=0&portrait=0'
      );

      this.subcategory = this.videoID;

      this.mainCategory = this.subCategories.filter((item) => item.value == this.videoID)[0]['parentId'];
    }
  }

  // change video topi
  onChangeTopic(eventValue) {
    this.safeUrl = '';

    // reset iframe
    setTimeout(() => {
      this.defaultSelectedTopic = eventValue.value;

      // sanitize url to be safe
      if (this.autoplay == true) {
        this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
          'https://player.vimeo.com/video/' + eventValue.value + '?autoplay=1?byline=0&portrait=0'
        );
      } else {
        this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
          'https://player.vimeo.com/video/' + eventValue.value + '?byline=0&portrait=0'
        );
      }
    }, 10);
  }

  filterSubById(id) {
    return this.subCategories.filter((item) => item.parentId === id);
  }

  close() {
    this.closeModal.emit(true);
  }
}
