import { animate, state, style, transition, trigger } from '@angular/animations';
import { Location } from '@angular/common';
import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Optional, Output, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { WizardComponent } from 'angular-archwizard';
import * as moment from 'moment';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { LookupService } from '../../../shared/services/lookup.service';
import { UtilsService } from '../../../shared/services/utils.service';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { CustomerProspectService } from '../../customer-prospect/shared/customerProspect.service';
import { DentistViewComponent } from '../../dentist/dentist-view/dentist-view.component';
import { DentistService } from '../../dentist/shared/dentist.service';
import { InvitationCustomerProspectEditComponent } from '../../invitation/invitation-customer-prospect-edit/invitation-customer-prospect-edit.component';
import { InvitationLookupComponent } from '../../invitation/invitation-lookup/invitation-lookup.component';
import { InvitationService } from '../../invitation/shared/services/invitation.service';
import { MerchantViewComponent } from '../../merchant/merchant-view/merchant-view.component';
import { MerchantService } from '../../merchant/shared/merchant.service';
import { SmsConversationComponent } from '../../message/sms-conversation/sms-conversation.component';
import { SmsPromoterComponent } from '../../message/sms-promoter/sms-promoter.component';
import { AppointmentViewComponent } from '../appointment-view/appointment-view.component';
import { ScheduleAppointmentViewComponent } from '../schedule-appointment-view/schedule-appointment-view.component';
import { AppointmentService } from '../shared/appointment.service';

@Component({
  selector: 'app-appointment-create-merchant',
  templateUrl: './appointment-create-merchant.component.html',
  styleUrls: ['./appointment-create-merchant.component.css'],
  animations: [
    trigger('simpleFadeAnimation', [
      state('in', style({ opacity: 1, height: '*' })),
      transition(':enter', [style({ opacity: 0, height: 0 }), animate(300)]),
      transition(':leave', animate(300, style({ opacity: 0, height: 0 }))),
    ]),
  ],
})
export class AppointmentCreateMerchantComponent implements OnInit {
  step2Container: ElementRef<HTMLDivElement>;
  step3Container: ElementRef<HTMLDivElement>;
  wizard: WizardComponent;
  scheduler: ScheduleAppointmentViewComponent;
  googleReviewPrompt = false;

  @ViewChild('wizard', { static: false }) set wizardContent(content: WizardComponent) {
    if (content) {
      this.wizard = content;
    }
  }

  @ViewChild('step2Container', { static: false }) set step2Content(content: ElementRef<HTMLDivElement>) {
    if (content) {
      this.step2Container = content;
    }
  }

  @ViewChild('step3Container', { static: false }) set step3Content(content: ElementRef<HTMLDivElement>) {
    if (content) {
      this.step3Container = content;
    }
  }

  @ViewChild(ScheduleAppointmentViewComponent, { static: false })
  set schedulerContent(content: ScheduleAppointmentViewComponent) {
    if (content) {
      this.scheduler = content;
    }
  }

  @Input() merchantID = localStorage.getItem('selectedMerchant');
  @Input() displayLookup = true;
  @Input() title = 'An invitation will be sent to patient  ';
  @Input() patientID;

  @Output() close = new EventEmitter();
  @Output() getResult = new EventEmitter();

  lookupPage = true;
  util = new UtilsClass();
  isLoadingInit;

  merchants = [];
  contacts = [];
  genders = [];
  titles = [];
  defaultMessageLists = [];
  defaultStepIndex;

  isAdminOrPromoter = false;
  hidePatientDetails;

  message;
  merchant;
  contact;
  isModal = false;
  selectedExistingPatientID;
  patient;
  isMobileValid = false;
  isHomePhoneValid = false;
  isWorkPhoneValid = false;
  isEmailValid = false;
  isModuleCustomMessages = Settings.global['isModuleCustomMessagesActive'];
  complete = false;
  stopFlying = false;
  flyAway = false;
  selectedMerchant;
  selectedSender;
  rawMessage;
  hideMerchantSelection = false;
  isFinDMatch = false;

  types = [];
  type;
  subTypes = [];
  subType;
  comment;
  bookDate = new Date();
  bookDateEnd;
  minDate = new Date();
  goStep = new EventEmitter();

  user = {
    firstname: null,
    title: null,
    lastname: null,
    middlename: null,
    gender: null,
    mobile: null,
    home_phone: null,
    work_phone: null,
    email: null,
  };

  clearEmail = new EventEmitter();
  clearMobile = new EventEmitter();
  clearHomePhone = new EventEmitter();
  clearWorkPhone = new EventEmitter();

  skipLookup = false;
  sessionType = 'merchant';
  appointmentID;

  dentist;
  appointment: any = {};
  dentists;
  // bestContactTimes = [];
  // bestContactTime;
  isPromoterOrAdmin = false;

  duration = 60;

  defaultBookEndDate;
  defaultBookEndTime;

  smsConfirmAppointment = false;
  smsAppointmentReminder = false;
  dateOfBirth;
  _dateOfBirth;
  isScheduleStep1Valid;
  isScheduleStep2Valid;

  constructor(
    private utilService: UtilsService,
    private merchantService: MerchantService,
    private appointmentService: AppointmentService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private dentistService: DentistService,
    private customerProspectService: CustomerProspectService,
    private invitationService: InvitationService,
    private authenticationService: AuthenticationService,
    private lookupService: LookupService,
    private location: Location,
    private dialog: MatDialog,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.merchantID) {
        this.merchantID = data.merchantID;

        this.isModal = true;
      }
      if (data.patientID) {
        this.patientID = data.patientID;
        this.displayLookup = false;

        this.lookupPage = false;
        this.isModal = true;
      }

      if (data.title) {
        this.title = data.title;
        this.isModal = true;
      }
    }
  }

  async ngOnInit() {
    this.isLoadingInit = true;
    // this.types = await this.utilService.getCodeLookup("AppointmentTypes").toPromise();
    // this.isPromoterOrAdmin = await this.authenticationService.isPromoterOrAdmin().toPromise()
    // this.dentists = await this.dentistService.getList({ fields: 'ID,CalculatedName', merchantID: this.merchantID }, this.isPromoterOrAdmin).toPromise()
    // this.bestContactTimes = await this.utilService.getCodeLookup("BestAppointmentTime").toPromise();

    this.activeRoute.params.subscribe(async (params) => {
      if (params['patientID']) {
        this.patientID = params['patientID'];
        this.displayLookup = false;
        this.lookupPage = false;
      }

      if (params['invitationID']) {
        const payload = {
          fields: 'ID,FirstName,LastName,Merchant_Key,MerchantName,Prospect_key',
        };
        const res = await this.invitationService
          .getInvitationDetails(params['invitationID'], payload, this.sessionType)
          .toPromise();
        this.patientID = res.Prospect_key;
        this.hidePatientDetails = true;
      } else if (params['customerID']) {
        this.patientID = params['customerID'];
        this.hidePatientDetails = true;
      }

      const res = await Promise.all([
        this.authenticationService.getSessionType().toPromise(),
        this.authenticationService.isPromoterOrAdmin().toPromise(),
        this.utilService.getCurrentAccess().toPromise(),
        this.lookupService.getLookup('CodeLookup', 'Gender').toPromise(),
        this.lookupService.getLookup('SimpleLookup', 'Salutation').toPromise(),
      ]);

      this.sessionType = res[0];
      this.isAdminOrPromoter = res[1];
      const access = res[2];
      this.genders = res[3];
      this.titles = res[4];

      if (access) {
        this.isModuleCustomMessages = access['isModuleCustomMessagesActive'];

        if (this.isAdminOrPromoter == true) {
          const p = {
            fields: 'ID,Name,FirstName,TradingAs,CalculatedName',
          };

          const list = await this.merchantService.getList(p, this.isAdminOrPromoter).toPromise();

          if (list) {
            this.merchants = list;

            if (this.merchantID) {
              for (let i = 0; i < this.merchants.length; i++) {
                if (this.merchants[i] && this.merchants[i]['ID'] && this.merchants[i]['ID'] == this.merchantID) {
                  this.merchant = this.merchants[i];
                }
              }
            }
          }
        }

        const __p = {
          fields: 'ID,Name,FirstName,TradingAs,CalculatedName',
        };

        const res = await this.dentistService.getList(__p, this.isAdminOrPromoter).toPromise();
        if (res && res.length > 0) {
          this.contacts = res;

          const curDentist = await this.authenticationService.getCurrentDentist().toPromise();
          if (curDentist) {
            this.selectedSender = curDentist;

            for (let i = 0; i < this.contacts.length; i++) {
              if (
                this.contacts[i] &&
                this.contacts[i]['ID'] &&
                this.selectedSender &&
                this.selectedSender['ID'] &&
                this.contacts[i]['ID'] == this.selectedSender['ID']
              ) {
                this.contact = this.contacts[i];
              }
            }
          }
        }

        if (this.patientID) {
          const p = {
            fields:
              'ID,Name,FirstName,MiddleName,DateOfBirth,Name,CalculatedName,Salutation,Gender.Code,Gender.Label,phones.Number,mobiles.Number,emails.Email',
          };

          const patient = await this.customerProspectService
            .getCustomerProspectDetails(this.patientID, p, this.sessionType)
            .toPromise();

          this.patient = patient;
          this.buildPatient(this.patient);
        }
      }

      const curPractice = await this.authenticationService.getCurrentPractice().toPromise();
      if (curPractice) {
        this.selectedMerchant = curPractice;
        this.merchantID = this.selectedMerchant.ID;

        if (this.selectedMerchant['SMS_ConfirmAppointment'] == '1') {
          this.smsConfirmAppointment = true;
        }
        if (this.selectedMerchant['SMS_AppointmentReminder'] == '1') {
          this.smsAppointmentReminder = true;
        }
      }

      this.isLoadingInit = false;
    });
  }

  replaceAllString(e) {
    if (e && typeof e == 'string') {
      return String(e).replace(/�/g, "'");
    }
  }

  toggleGoogleReviewPrompt() {
    this.googleReviewPrompt = !this.googleReviewPrompt;
  }

  addSubType() {
    if (this.type && this.type.Label3) {
      this.duration = Number(this.type.Label3);
    } else {
      this.duration = 60;
    }

    if (this.duration) {
      this.bookDateEnd = moment(this.bookDate).add(this.duration, 'minutes').toDate();

      this.defaultBookEndDate = moment(this.bookDateEnd).toDate();
      this.defaultBookEndTime = moment(this.bookDateEnd).format('HH:mm');
    }

    if (this.type && this.type.Code) {
      this.utilService.getCodeLookup(this.type.Code).subscribe((res) => {
        if (res) {
          this.subTypes = res;
        }
      });
    }
  }

  subTypeSelect() {
    if (this.subType && this.subType.Label3) {
      this.duration = Number(this.subType.Label3);
    } else if (this.type && this.type.Label3) {
      this.duration = Number(this.type.Label3);
    } else {
      this.duration = 60;
    }
  }

  selectDateTime(d) {
    if (d) {
      this.bookDate = d;

      if (this.duration) {
        this.bookDateEnd = moment(this.bookDate).add(this.duration, 'minutes').toDate();

        this.defaultBookEndDate = moment(this.bookDateEnd).toDate();
        this.defaultBookEndTime = moment(this.bookDateEnd).format('HH:mm');
      }
    }
  }

  selectDateTimeSecond(d) {
    if (d) {
      this.bookDateEnd = d;
    }
  }

  viewMerchant() {
    const ref = this.dialog.open(MerchantViewComponent, {
      data: this.merchantID,
      width: '600px',
    });
    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  selectMerchant(ID) {
    this.merchantID = ID;

    const __p = {
      merchantID: this.merchantID,
      fields: 'ID,Name,FirstName,TradingAs,CalculatedName',
      canAcceptAppointment: true,
    };

    this.dentistService.getList(__p, this.isAdminOrPromoter).subscribe((res) => {
      if (res && res.length > 0) {
        this.contacts = res;
        this.contact = this.contacts[0];
      }
    });
  }

  viewDentist() {
    if (this.contact && this.contact.ID) {
      const ref = this.dialog.open(DentistViewComponent, {
        data: this.contact.ID,
        width: '600px',
      });

      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    }
  }

  closeEvent() {
    this.close.emit(true);
  }

  getEmail(p) {
    this.user.email = p;
  }

  getMobile(p) {
    this.user.mobile = p;
  }

  getHomePhone(p) {
    this.user.home_phone = p;
  }

  getWorkPhone(p) {
    this.user.work_phone = p;
  }

  validateMobile(v) {
    this.isMobileValid = v;
  }

  validateHomePhone(v) {
    this.isHomePhoneValid = v;
  }

  validateWorkPhone(v) {
    this.isWorkPhoneValid = v;
  }

  validateEmail(v) {
    this.isEmailValid = v;
  }

  sendResult(data) {
    const p: any = {
      ...data,
      merchantID: this.merchantID,
      patientID: this.patientID,
      customerID: this.patientID,
      firstName: this.user.firstname,
      lastName: this.user.lastname,
      mobile: this.user.mobile,
      email: this.user.email,
      gender: this.user.gender,
      salutation: this.user.title,
      workPhone: this.user.work_phone,
      homePhone: this.user.home_phone,
      middleName: this.user.middlename,
      bestAppointmentCode: 'Afternoon',
      dateOfBirth: this.dateOfBirth,
    };

    delete p.operation;

    this.appointmentService.createForMerchantWithPatient(p, this.sessionType).subscribe((res: any) => {
      if (res) {
        this.appointmentID = res.ID;
        this.getResult.emit(res);

        this.appointment = res;

        NotifyAppComponent.displayToast(
          'success',
          'Successful operation',
          'The patient has been successfully invited '
        );

        this.wizard.goToNextStep();
        this.complete = true;
      }
    });
  }

  openPatientLookUpDialog() {
    const ref = this.dialog.open(InvitationLookupComponent, {
      data: {
        merchantID: this.merchantID,
        onlyMyAccount: true,
        useStrict: true,
      },
      width: '600px',
    });
    ref.componentInstance.closeModal.subscribe((data) => {
      ref.close();
    });
    ref.componentInstance.detailedView.subscribe((res) => {
      ref.close();
    });

    ref.componentInstance.patientChat.subscribe((res) => {
      if (res && res.cardID) {
        if (res.isPromoterOrAdmin == true) {
          const ref2 = RootAppComponent.dialog.open(SmsPromoterComponent, {
            data: {
              merchantID: res.merchantID,
              cardID: res.cardID,
              singleChat: true,
            },
            panelClass: 'noCard',
            width: '500px',
          });
          ref2.componentInstance.close.subscribe((data) => {
            ref2.close();
          });

          ref2.componentInstance.getResult.subscribe((data) => {
            if (data) {
              const ref3 = RootAppComponent.dialog.open(SmsConversationComponent, {
                data,
                panelClass: 'bigger-screen',
                width: '70%',
                height: '95vh',
              });
              const sub = ref3.componentInstance.close.subscribe((data) => {
                ref3.close();
              });
            }

            ref2.close();
          });
        } else {
          const ref2 = RootAppComponent.dialog.open(SmsConversationComponent, {
            data: {
              merchantID: res.merchantID,
              cardID: res.cardID,
              singleChat: true,
            },
            panelClass: 'bigger-screen',
            width: '70%',
            height: '95vh',
          });
          const sub = ref2.componentInstance.close.subscribe((data) => {
            ref2.close();
          });
        }
      }
    });

    ref.componentInstance.getSelectedPatient.subscribe((res) => {
      if (res) {
        if (res.ID) {
          ref.close();
          this.patientID = res.ID;
          this.isFinDMatch = true;
          this.skipLookup = true;

          this.buildPatient(res);
        }
      }
    });
  }

  buildPatient(patient) {
    if (patient['Gender.Code']) {
      this.user.gender = patient['Gender.Code'];
    }

    if (patient['Salutation']) {
      this.user.title = patient['Salutation'];
    }

    if (patient['FirstName']) {
      this.user.firstname = patient['FirstName'];
    }

    if (patient['MiddleName']) {
      this.user.middlename = patient['MiddleName'];
    }

    if (patient['Name']) {
      this.user.lastname = patient['Name'];
    }

    if (patient['mobiles.Number']) {
      this.user.mobile = patient['mobiles.Number'];
    }

    if (patient['phone.Home.Number']) {
      this.user.home_phone = patient['phone.Home.Number'];
    }

    if (patient['phone.Work.Number']) {
      this.user.work_phone = patient['phone.Work.Number'];
    }

    if (patient['emails.Email']) {
      this.user.email = patient['emails.Email'];
    }

    if (patient['dateOfBirth'] && patient['dateOfBirth'] != '0000-00-00') {
      this.dateOfBirth = patient['dateOfBirth'];
      this._dateOfBirth = patient['dateOfBirth'];
    }

    if (patient['DateOfBirth'] && patient['DateOfBirth'] != '0000-00-00') {
      this.dateOfBirth = patient['DateOfBirth'];
      this._dateOfBirth = patient['DateOfBirth'];
    }

    this.appointment.CustomerOrProspect_Key = patient.ID;
    this.appointment.CustomerOrProspect_CalculatedName = patient.CalculatedName;
    this.appointment.CustomerOrProspect_Mobile = patient['mobiles.Number'];
    this.appointment.CustomerOrProspect_Email = patient['emails.Email'];
  }

  backToLookup() {
    this.patientID = null;
    this.patient = null;
    this.lookupPage = true;
  }

  step1() {
    if (!this.patientID) {
      const payload = {
        mobile: this.user.mobile,
        phone: this.user.home_phone,
        email: this.user.email,
        firstName: this.patient.firstName,
        lastName: this.patient.lastName,
        merchantID: this.merchantID,
      };
      this.customerProspectService.patientLookup(payload, this.isAdminOrPromoter).subscribe((res) => {
        if (res && res.length > 0) {
          const patientLookup = this.dialog.open(InvitationLookupComponent, {
            data: {
              patients: res,
              hideFilter: true,
              showNextBtn: true,
            },
            width: '600px',
          });

          patientLookup.componentInstance.patientChat.subscribe((res) => {
            if (res && res.cardID) {
              if (res.isPromoterOrAdmin == true) {
                const ref2 = RootAppComponent.dialog.open(SmsPromoterComponent, {
                  data: {
                    merchantID: res.merchantID,
                    cardID: res.cardID,
                    singleChat: true,
                  },
                  panelClass: 'noCard',
                  width: '500px',
                });
                ref2.componentInstance.close.subscribe((data) => {
                  ref2.close();
                });

                ref2.componentInstance.getResult.subscribe((data) => {
                  if (data) {
                    const ref3 = RootAppComponent.dialog.open(SmsConversationComponent, {
                      data,
                      panelClass: 'bigger-screen',
                      width: '70%',
                      height: '95vh',
                    });
                    const sub = ref3.componentInstance.close.subscribe((data) => {
                      ref3.close();
                    });
                  }

                  ref2.close();
                });
              } else {
                const ref2 = RootAppComponent.dialog.open(SmsConversationComponent, {
                  data: {
                    merchantID: res.merchantID,
                    cardID: res.cardID,
                    singleChat: true,
                  },
                  panelClass: 'bigger-screen',
                  width: '70%',
                  height: '95vh',
                });
                const sub = ref2.componentInstance.close.subscribe((data) => {
                  ref2.close();
                });
              }
            }
          });
          patientLookup.componentInstance.closeModal.subscribe((res) => {
            patientLookup.close();
          });
          patientLookup.componentInstance.detailedView.subscribe((res) => {
            patientLookup.close();
          });
          patientLookup.componentInstance.getSelectedPatient.subscribe((res) => {
            if (res) {
              if (res.ID) {
                this.patientID = res.ID;
                this.isFinDMatch = true;
                patientLookup.close();
                this.wizard.goToNextStep();
                this.buildPatient(res);

                setTimeout(() => {
                  this.scheduler.scrollFirstEnabledTimeOption();
                });
              }
            }
          });

          patientLookup.componentInstance.createNew.subscribe((res) => {
            this.skipLookup = true;
            patientLookup.close();
            this.wizard.goToNextStep();

            setTimeout(() => {
              this.scheduler.scrollFirstEnabledTimeOption();
            });
          });
        }
      });
    } else {
      this.wizard.goToNextStep();
      setTimeout(() => {
        this.scheduler.scrollFirstEnabledTimeOption();
      });
    }
  }

  step2() {
    this.wizard.goToNextStep();
  }

  clearPatientDetails() {
    this.clearEmail.emit(true);
    this.clearMobile.emit(true);
    this.clearHomePhone.emit(true);
    this.clearWorkPhone.emit(true);

    this.user = {
      firstname: '',
      title: '',
      lastname: '',
      middlename: '',
      gender: '',
      mobile: '',
      home_phone: '',
      work_phone: '',
      email: '',
    };

    this.patientID = null;
    this.isFinDMatch = false;
    this.skipLookup = false;
    this.dateOfBirth = null;
    this._dateOfBirth = null;
  }

  modifyProfile() {
    if (this.patientID) {
      const ref = this.dialog.open(InvitationCustomerProspectEditComponent, {
        data: {
          patientID: this.patientID,
        },
        width: '650px',
      });

      ref.componentInstance.getResult.subscribe((res) => {
        if (res && res.ID) {
          ref.close();

          this.buildPatient(res);
        }
      });
    }
  }

  goBack() {
    this.location.back();
  }

  exitToList() {
    this.flyAway = true;
    setTimeout(() => {
      this.router.navigate([
        '/merchant',
        {
          outlets: {
            page: ['customer-profile', this.appointment['CustomerOrProspect_Key'], 'appointments'],
          },
        },
      ]);
    }, 1800);
  }

  openAppointmentView() {
    const ref = RootAppComponent.dialog.open(AppointmentViewComponent, {
      data: {
        appointmentID: this.appointmentID,
      },
      width: '900px',
      height: '90%',
      panelClass: 'noCard',
    });

    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  moveScheduleAppointmentView(step) {
    if (
      step === 2 &&
      this.step2Container &&
      (this.step2Container.nativeElement.children as HTMLCollection).length === 0 &&
      this.step3Container.nativeElement.children[0]
    ) {
      this.step2Container.nativeElement.appendChild(this.step3Container.nativeElement.children[0]);
      this.goStep.emit(1);
    } else if (
      step === 3 &&
      this.step3Container &&
      (this.step3Container.nativeElement.children as HTMLCollection).length === 0 &&
      this.step2Container.nativeElement.children[0]
    ) {
      this.step3Container.nativeElement.appendChild(this.step2Container.nativeElement.children[0]);
      this.goStep.emit(2);
    }
  }

  scheduleStep1Validate(res) {
    this.isScheduleStep1Valid = res;
  }

  scheduleStep2Validate(res) {
    this.isScheduleStep2Valid = res;
  }

  selectDateToFilter(dateTo) {
    this._dateOfBirth = dateTo;
  }
}
