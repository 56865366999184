import { transition, trigger, useAnimation } from '@angular/animations';
import { Component, Input } from '@angular/core';
import { ngIfRowInAnimation, ngIfRowOutAnimation } from '../../animations/ngIf/ngIfRow.animation';

interface PageTemplate {
  title: string;
  counterValue: number;
  imageSrc: string;
}

@Component({
  selector: 'ipv-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.css'],
  animations: [
    trigger('ngIfRowAnimation', [
      transition('void => *', [useAnimation(ngIfRowInAnimation)]),
      transition('* => void', [useAnimation(ngIfRowOutAnimation)]),
    ]),
  ],
})
export class PageComponent {
  @Input() isFetchingInitialData = false;
  @Input() showDataHeader = true;
  @Input() dataHeader: PageTemplate = {
    title: 'title here',
    counterValue: 0,
    imageSrc: null,
  };
  @Input() showBackgroundHeader = false;
  @Input() backgroundHeader = { title: 'title here' };
  @Input() headerSize: 'standard' | 'smaller' = 'standard';
  @Input() pageMode: 'page' | 'modal' = 'page';
  @Input() modalTitle = 'Modal Title';
}
