<div>
  <app-operator-filter *ngIf="isAdminOrPromoter == true" [sendNull]="true" [selectDateRange]="selectedRange"
                       [displayMerchant]="true" [displayDateFrom]="true" [displayDateTo]="true" [displayProduct]="true"
                       [displayDentist]="true" [displayStatus]="true" (getProduct)="getProduct($event)"
                       (getDentist)="getDentist($event)"
                       (getMerchant)="getMerchant($event)" (getDateTo)="getDateTo($event)"
                       (getDateFrom)="getDateFrom($event)"
                       (search)="getResult($event)">
  </app-operator-filter>
  <app-operator-filter *ngIf="isAdminOrPromoter == false" [sendNull]="true" [selectDateRange]="selectedRange"
                       [displayMerchant]="false" [displayDateFrom]="true" [displayDateTo]="true" [displayProduct]="true"
                       [displayDentist]="true" [displayStatus]="true" (getProduct)="getProduct($event)"
                       (getDentist)="getDentist($event)"
                       (getMerchant)="getMerchant($event)" (getDateTo)="getDateTo($event)"
                       (getDateFrom)="getDateFrom($event)"
                       (search)="getResult($event)">
  </app-operator-filter>

  <!-- skeltal -->
  <div class="performanceOverview" *ngIf="!stats.global" @ngIfLoadingAnimation>
    <div class="row clearfix mb">
      <h2 class="lg-h2 rm-m sr-title">Invitation &amp; Finance Performance</h2>
      <label class="subtitle">View metrics surrounding invitation delivery and {{"KEYWORD.practice" | translate}}
        engagement</label>
    </div>
    <div class="row clearfix flex mb">
      <div class="two-thrd-width flex">
        <div class="subStatcontainer full-width mock">
          <div class="row mb clearfix flex rel">
            <!-- css loader -->
            <div class="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <!-- /css loader -->
          </div>
        </div>
      </div>
      <div class="thrd-width flex">
        <div class="col-right full-width flex">
          <mat-card class="full-width mock rel animate">
            <div class="colour-stripe"></div>
            <div class="row clearfix rel">
              <h3 class="sm-h3 rm-m animate">Loading...</h3>
              <!-- css loader -->
              <div class="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              <!-- /css loader -->
              <hr/>
            </div>
          </mat-card>
        </div>
      </div>
    </div>
    <div class="row clearfix full-width">
      <mat-card class="card dataCard mock clearfix">
        <div class="colour-stripe"></div>
        <div class="row clearfix">
          <h2 class="sr-title lg-h2 pull-left dataHead animate">Loading...</h2>
          <hr/>
        </div>
        <div class="quickLook mock row clearfix text-center mt add-mb">
          <div class="qtr-width">
            <div class="column">
              <mat-card class="mat-elevation-z7 clearfix animate">
                <!-- css loader -->
                <div class="lds-ellipsis">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <!-- /css loader -->
              </mat-card>
            </div>
          </div>
          <div class="qtr-width">
            <div class="column">
              <mat-card class="mat-elevation-z7 clearfix animate">
                <!-- css loader -->
                <div class="lds-ellipsis">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <!-- /css loader -->
              </mat-card>
            </div>
          </div>
          <div class="qtr-width">
            <div class="column">
              <mat-card class="mat-elevation-z7 clearfix animate">
                <!-- css loader -->
                <div class="lds-ellipsis">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <!-- /css loader -->
              </mat-card>
            </div>
          </div>
          <div class="qtr-width">
            <div class="column">
              <mat-card class="mat-elevation-z7 clearfix animate">
                <!-- css loader -->
                <div class="lds-ellipsis">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <!-- /css loader -->
              </mat-card>
            </div>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
  <!-- /skeltal -->

  <div class="performanceOverview" *ngIf="stats && stats.global" @ngIfAnimation>
    <div class="row clearfix mb">
      <h2 class="lg-h2 rm-m sr-title">
        Invitation &amp; Finance Performance

        <span *ngIf="
            (dateFrom != null || dateTo != null) && stats && stats.global && displayInvitationList == false && dateFrom
          ">From:
          <strong>{{ dateFrom | customDateTime }} </strong>
        </span>
        <span *ngIf="
            (dateFrom != null || dateTo != null) && stats && stats.global && displayInvitationList == false && dateTo
          ">
          To:
          <strong>{{ dateTo | customDateTime }}</strong>
        </span>
      </h2>
      <label class="subtitle">View metrics surrounding invitation delivery and {{"KEYWORD.practice" | translate}}
        engagement</label>
    </div>
    <div class="row clearfix flex mb">
      <div class="two-thrd-width flex">
        <div class="subStatcontainer full-width">
          <div class="row mb clearfix flex">
            <div class="two-thrd-width clearfix flex rel animate" *ngIf="selectDateRange != true">
              <div class="clearfix full-width" *ngIf="timeLineStatus == 'unit'">
                <div class="row clearfix flex full-width rel" *ngIf="displayChartTime == 'days'">
                  <app-chart-input class="row full-width" *ngIf="stats && stats.days" [xAxisLabel]="'Days'"
                                   [yAxisLabel]="'Invitation(s)'"
                                   [data]="stats.days | OrderArrayObject: orderByFiler.daily"
                                   [dataValueAttribute]="'Count(*)'" [buildAdvanced]="true" [dataNameAttribute]="'ID'"
                                   [chartType]="'line'" [title]="'Invitations by day'" [showLegend]="false"
                                   [view]="undefined">
                  </app-chart-input>
                </div>
                <div class="row clearfix flex full-width rel" *ngIf="displayChartTime == 'months'">
                  <app-chart-input class="row full-width" *ngIf="stats && stats.months" [gradient]="false"
                                   [xAxisLabel]="'Months'" [yAxisLabel]="'Invitation(s)'"
                                   [data]="stats.months | OrderArrayObject: orderByFiler.monthly"
                                   [dataValueAttribute]="'Count(*)'"
                                   [buildAdvanced]="true" [dataNameAttribute]="'ID'" [chartType]="'line'"
                                   [title]="'Invitations by month'" [showLegend]="false"
                                   [view]="undefined"></app-chart-input>
                </div>
                <div class="row clearfix flex full-width rel" *ngIf="displayChartTime == 'years'">
                  <app-chart-input class="row full-width" *ngIf="stats && stats.years" [xAxisLabel]="'Years'"
                                   [yAxisLabel]="'Invitation(s)'" [data]="
                      stats.years
                        | WithoutArrayMultipleValue: 'Count(*)':emptyResultFilter
                        | OrderArrayObject: orderByFiler.annualy
                    " [dataValueAttribute]="'Count(*)'" [chartType]="'barVertical'" [dataNameAttribute]="'ID'"
                                   [title]="'Invitations by year'" [showLegend]="false"
                                   [view]="undefined"></app-chart-input>
                </div>
              </div>
              <div class="clearfix full-width" *ngIf="timeLineStatus == 'amount'">
                <div class="row clearfix flex full-width rel" *ngIf="displayChartTime == 'days'">
                  <app-chart-input class="row full-width" *ngIf="stats && stats.days" [xAxisLabel]="'Days'"
                                   [yAxisLabel]="('KEYWORD.treatment' | translate | titlecase) + ' Amount ($)'"
                                   [data]="stats.days | OrderArrayObject: orderByFiler.daily"
                                   [dataValueAttribute]="'Sum(treatment.Value)'" [buildAdvanced]="true"
                                   [dataNameAttribute]="'ID'"
                                   [chartType]="'line'"
                                   [title]="'Days vs ' + ('KEYWORD.treatment' | translate | titlecase) + ' Amount ($)'"
                                   [showLegend]="false" [view]="undefined"></app-chart-input>
                </div>
                <div class="row clearfix flex full-width rel" *ngIf="displayChartTime == 'months'">
                  <app-chart-input class="row full-width" *ngIf="stats && stats.months" [gradient]="false"
                                   [xAxisLabel]="'Months'"
                                   [yAxisLabel]="('KEYWORD.treatment' | translate | titlecase) + ' Amount ($)'"
                    [data]="stats.months | OrderArrayObject: orderByFiler.monthly"
                                   [dataValueAttribute]="'Sum(treatment.Value)'" [buildAdvanced]="true"
                                   [dataNameAttribute]="'ID'"
                                   [chartType]="'line'"
                                   [title]="'Months vs ' + ('KEYWORD.treatment' | translate | titlecase) + ' Amount ($)'"
                                   [showLegend]="false" [view]="undefined"></app-chart-input>
                </div>
                <div class="row clearfix flex full-width rel" *ngIf="displayChartTime == 'years'">
                  <app-chart-input class="row full-width" *ngIf="stats && stats.years" [xAxisLabel]="'Years'"
                                   [yAxisLabel]="('KEYWORD.treatment' | translate | titlecase) + ' Amount ($)'" [data]="
                      stats.years
                        | WithoutArrayMultipleValue: 'Count(*)':emptyResultFilter
                        | OrderArrayObject: orderByFiler.annualy
                    " [dataValueAttribute]="'Sum(treatment.Value)'" [chartType]="'barVertical'"
                                   [dataNameAttribute]="'ID'"
                                   [title]="'Years vs ' + ('KEYWORD.treatment' | translate | titlecase) + ' Amount ($)'"
                                   [showLegend]="false" [view]="undefined"></app-chart-input>
                </div>
              </div>
            </div>
            <div class="two-thrd-width clearfix flex rel" *ngIf="selectDateRange == true">
              <div class="row clearfix flex full-width rel" *ngIf="displayTimeLineChartView == 'unit'">
                <app-chart-input class="row full-width" *ngIf="stats && stats.dateRange" [xAxisLabel]="''"
                                 [yAxisLabel]="'Invitation(s)'"
                                 [data]="stats.dateRange | OrderArrayObject: orderByFiler.dateRange"
                                 [dataValueAttribute]="'Count(*)'" [buildAdvanced]="true" [dataNameAttribute]="'ID'"
                                 [chartType]="'line'" [title]="'Time vs Invitations'"
                                 [view]="undefined"></app-chart-input>
              </div>

              <div class="row clearfix flex full-width rel" *ngIf="displayTimeLineChartView == 'amount'">
                <app-chart-input class="row full-width" *ngIf="stats && stats.dateRange" [xAxisLabel]="''"
                                 [yAxisLabel]="('KEYWORD.treatment' | translate | titlecase) + ' Amount ($)'"
                  [data]="stats.dateRange | OrderArrayObject: orderByFiler.dateRange"
                                 [dataValueAttribute]="'Sum(treatment.Value)'" [buildAdvanced]="true"
                                 [chartType]="'line'"
                                 [dataNameAttribute]="'ID'" [view]="undefined"
                                 [title]="'Time vs ' + ('KEYWORD.treatment' | translate | titlecase) + ' Amount ($)'">
                </app-chart-input>
              </div>
            </div>

            <div class="thrd-width">
              <div class="row clearfix" id="tool2" *ngIf="stats && stats.global && displayInvitationList == false">
                <mat-list class="quickStats">
                  <mat-list-item class="animate quickStat">
                    <mat-icon class="fas fa-paper-plane" matListIcon></mat-icon>
                    <p mdLine>
                      <span class="valueOf"> Total Invitations </span>
                    </p>
                    <h3 mdLine>{{ stats.global['Count(*)'] }}</h3>
                    <mat-divider></mat-divider>
                  </mat-list-item>
                  <mat-list-item class="animate quickStat">
                    <mat-icon class="fas fa-calculator" matListIcon></mat-icon>
                    <p mdLine>
                      <span class="valueOf"> Total {{ "KEYWORD.treatment" | translate | titlecase }} Amount </span>
                    </p>
                    <h3 mdLine>{{ stats.global['Sum(treatment.Value)'] | customCurrency }}</h3>
                    <mat-divider></mat-divider>
                  </mat-list-item>
                  <mat-list-item class="animate quickStat">
                    <mat-icon class="fas fa-chart-line" matListIcon></mat-icon>
                    <p mdLine>
                      <span class="valueOf">Average Invitation Value</span>
                    </p>
                    <h3 mdLine>{{ stats.global['Avg(treatment.Value)'] | customCurrency }}</h3>
                    <mat-divider></mat-divider>
                  </mat-list-item>
                  <mat-list-item class="animate quickStat">
                    <mat-icon class="yellow fas fa-handshake" matListIcon></mat-icon>
                    <p mdLine>
                      <span class="valueOf">Financed {{ "KEYWORD.patient" | translate }}s</span>
                    </p>
                    <h3 mdLine>
                      {{
                      stats.status
                        | FilterArrayMultipleValue: 'ID':['active', 'proceedingCash']
                        | WithoutArrayMultipleValue: 'Count(*)':emptyResultFilter
                        | objectPropertyValue: 'Count(*)'
                        | sum
                      }}
                    </h3>
                    <mat-divider></mat-divider>
                  </mat-list-item>
                  <mat-list-item class="animate quickStat">
                    <mat-icon class="green fas fa-star" matListIcon></mat-icon>
                    <p mdLine>
                      <span class="valueOf">Finance conversion rate</span>
                    </p>
                    <h3 mdLine>
                      {{
                      (stats.status
                        | FilterArrayMultipleValue: 'ID':['active', 'proceedingCash']
                        | WithoutArrayMultipleValue: 'Count(*)':emptyResultFilter
                        | objectPropertyValue: 'Count(*)'
                        | sum) /
                      (stats.global['Count(*)'] -
                        (stats.status
                          | FilterArrayMultipleValue: 'ID':['notViewed']
                          | WithoutArrayMultipleValue: 'Count(*)':emptyResultFilter
                          | objectPropertyValue: 'Count(*)'
                          | sum)) | percent: '2.1-1'
                      }}
                    </h3>
                    <mat-divider></mat-divider>
                  </mat-list-item>
                </mat-list>
              </div>
            </div>
          </div>
          <div class="row clearfix rel" *ngIf="selectDateRange != true">
            <div class="buttonsControl flex">
              <mat-button-toggle-group class="btn-group text-center" [(ngModel)]="displayChartTime"
                                       matTooltip="Change viewing period">
                <mat-button-toggle class="text-center" [value]="'days'"> Daily</mat-button-toggle>
                <mat-button-toggle class="text-center" [value]="'months'"> Monthly</mat-button-toggle>
                <mat-button-toggle class="text-center" [value]="'years'"> Annually</mat-button-toggle>
              </mat-button-toggle-group>
              <mat-button-toggle-group class="btn-group full-width text-center" [(ngModel)]="timeLineStatus"
                                       matTooltip="View by number of invitations, or value">
                <mat-button-toggle class="text-center" [value]="'unit'"> Invitations</mat-button-toggle>
                <mat-button-toggle class="text-center" [value]="'amount'"> Amount($)</mat-button-toggle>
              </mat-button-toggle-group>
              <mat-slide-toggle class="text-center margin-left-input" [checked]="displayTimeLineList"
                                (change)="chaneDisplayTimeLineList($event)">
                {{ displayTimeLineList === true ? 'Hide List' : 'View List' }}</mat-slide-toggle>
            </div>
          </div>
          <div class="row clearfix rel" *ngIf="selectDateRange == true">
            <div class="buttonsControl flex">
              <mat-button-toggle-group class="btn-group clearfix full-width text-center"
                                       [(ngModel)]="displayTimeLineChartView">
                <mat-button-toggle class="text-center half-width" [value]="'unit'"> Invitations</mat-button-toggle>
                <mat-button-toggle class="text-center half-width" [value]="'amount'"> Amount($)</mat-button-toggle>
              </mat-button-toggle-group>
              <mat-slide-toggle class="text-center margin-left-input" [checked]="displayTimeLineRangeList"
                                (change)="chaneDisplayTimeLineRangeList($event)">
                {{ displayTimeLineRangeList === true ? 'Hide List' : 'View List' }}</mat-slide-toggle>
            </div>
          </div>
        </div>
      </div>
      <div class="thrd-width flex">
        <div class="col-right full-width flex">
          <mat-card class="full-width">
            <div class="colour-stripe"></div>
            <div class="row clearfix">
              <h3 class="sm-h3 rm-m" *ngIf="displayGlobalPieChart == 'contract'">Overall contract conversions</h3>
              <h3 class="sm-h3 rm-m" *ngIf="displayGlobalPieChart == 'invitation'">Overall invitation conversions</h3>
              <hr/>
            </div>
            <div class="row clearfix" *ngIf="displayGlobalPieChart == 'invitation' && stats">
              <div class="rel overall">
                <div class="total anim">
                  <span class="totLab">You've sent</span>
                  <span [endVal]="
                      statusPercentGlobal
                        | WithoutArrayMultipleValue: 'Count(*)':emptyResultFilter
                        | objectPropertyValue: 'Count(*)'
                        | sum
                    " countUp></span>
                  <span class="totSub">invitations</span>
                </div>
                <app-chart-input class="row" *ngIf="stats && stats.status" [colorScheme]="chartStatusColor"
                                 [titleIsTop]="false" [xAxisLabel]="'Invitation(s)'" [yAxisLabel]="'Status'"
                                 [data]="statusPercentGlobal" [dataNameAttribute]="'name'"
                                 [dataValueAttribute]="'Count(*)'"
                                 [chartType]="'pie'" [title]="'Status vs Invitations'" [showLegend]="false"
                                 [showLabel]="false"
                                 [view]="undefined">
                </app-chart-input>
              </div>
              <div class="row clearfix legend mt">
                <label class="rm-mt num" *ngFor="let p of statusPercentGlobal; let i = index">
                  <span [style.color]="chartStatusColor[i]">{{ p.name }}</span>
                  <strong [style.background]="chartStatusColor[i]">{{ p['Count(*)'] }}</strong>
                </label>
              </div>
            </div>

            <div class="row clearfix" *ngIf="displayGlobalPieChart == 'contract' && stats">
              <div class="rel overall">
                <div class="total anim">
                  <span class="totLab">Financed</span>
                  <span [endVal]="
                      stats.status
                        | FilterArrayMultipleValue: 'ID':invitationUtil.status.contract
                        | WithoutArrayMultipleValue: 'Count(*)':emptyResultFilter
                        | objectPropertyValue: 'Count(*)'
                        | sum
                    " countUp></span>
                  <span class="totSub">Contracts</span>
                </div>
                <app-chart-input class="row" *ngIf="stats && stats.status" [titleIsTop]="false"
                                 [xAxisLabel]="'Contract(s)'" [yAxisLabel]="'Status'" [data]="
                    stats.status
                      | FilterArrayMultipleValue: 'ID':invitationUtil.status.contract
                      | WithoutArrayMultipleValue: 'Count(*)':emptyResultFilter
                  " [dataNameAttribute]="'name'" [dataValueAttribute]="'Count(*)'" [chartType]="'pie'"
                                 [title]="'Status vs Invitations'" [showLegend]="false" [showLabel]="false"
                                 [view]="undefined">
                </app-chart-input>
              </div>
              <div class="row clearfix legend mt">
                <label class="rm-mt num" *ngFor="
                    let p of stats.status
                      | FilterArrayMultipleValue: 'ID':invitationUtil.status.contract
                      | WithoutArrayMultipleValue: 'Count(*)':emptyResultFilter;
                    let i = index
                  ">
                  <span [style.color]="chartColors[i]">{{ p.name }}</span>
                  <strong [style.background]="chartColors[i]">{{ p['Count(*)'] }}</strong>
                </label>
              </div>
            </div>

            <mat-button-toggle-group class="btn-group clearfix full-width text-center"
                                     [(ngModel)]="displayGlobalPieChart" matTooltip="Change viewing statistics">
              <mat-button-toggle class="text-center half-width" [value]="'invitation'"> Invitations</mat-button-toggle>
              <mat-button-toggle class="text-center half-width" [value]="'contract'"> Contracts</mat-button-toggle>
            </mat-button-toggle-group>
          </mat-card>
        </div>
      </div>
    </div>

    <div class="clearfix full-width animate" *ngIf="displayTimeLineRangeList == true">
      <div class="clearfix subStatcontainer" *ngIf="stats && stats.dateRange && stats.dateRange.length > 0">
        <div class="row clearfix full-width">
          <mat-card class="card dataCard clearfix">
            <div class="colour-stripe"></div>

            <div class="row clearfix">
              <h2 class="sr-title lg-h2 pull-left dataHead">
                <span *ngIf="dateFrom">From:
                  {{ dateFrom | customDateTime }}
                </span>
                <span *ngIf="dateTo">
                  To:
                  {{ dateTo | customDateTime }}
                </span>
              </h2>
              <mat-slide-toggle class="pull-right" [checked]="isEmptyResult" (change)="emptyResultChange($event)">Hide
                Empty Result
              </mat-slide-toggle>
              <hr/>
            </div>

            <div class="row clearfix full-width mb" *ngIf="stats && stats.dateRange && stats.dateRange.length > 1">
              <div class="row clearfix full-width">
                <div class="qtr-width">
                  <p class="small pointer-cursor" *ngIf="orderByFiler.dateRange?.indexOf('-ID') == -1"
                     (click)="filterByOrderBy('dateRange', '-ID')">
                    Date
                    <mat-icon *ngIf="orderByFiler.dateRange?.indexOf('ID') != -1">arrow_drop_up</mat-icon>
                  </p>
                  <p class="small pointer-cursor" *ngIf="orderByFiler.dateRange?.indexOf('-ID') != -1"
                     (click)="filterByOrderBy('dateRange', 'ID')">
                    Date
                    <mat-icon>arrow_drop_down</mat-icon>
                  </p>
                </div>
                <div class="half-width">
                  <div class="qtr-width">
                    <p class="small pointer-cursor" *ngIf="orderByFiler.dateRange?.indexOf('-Count(*)') == -1"
                       (click)="filterByOrderBy('dateRange', '-Count(*)')">
                      Units
                      <mat-icon *ngIf="orderByFiler.dateRange?.indexOf('Count(*)') != -1"> arrow_drop_up</mat-icon>
                    </p>
                    <p class="small pointer-cursor" *ngIf="orderByFiler.dateRange?.indexOf('-Count(*)') != -1"
                       (click)="filterByOrderBy('dateRange', 'Count(*)')">
                      Units
                      <mat-icon>arrow_drop_down</mat-icon>
                    </p>
                  </div>
                  <div class="qtr-width">
                    <p class="small pointer-cursor"
                      *ngIf="orderByFiler.dateRange?.indexOf('-Min(treatment.Value)') == -1"
                       (click)="filterByOrderBy('dateRange', '-Min(treatment.Value)')">
                      Min
                      <mat-icon *ngIf="orderByFiler.dateRange?.indexOf('Min(treatment.Value)') != -1">arrow_drop_up
                      </mat-icon>
                    </p>
                    <p class="small pointer-cursor"
                      *ngIf="orderByFiler.dateRange?.indexOf('-Min(treatment.Value)') != -1"
                       (click)="filterByOrderBy('dateRange', 'Min(treatment.Value)')">
                      Min
                      <mat-icon>arrow_drop_down</mat-icon>
                    </p>
                  </div>
                  <div class="qtr-width">
                    <p class="small pointer-cursor"
                      *ngIf="orderByFiler.dateRange?.indexOf('-Max(treatment.Value)') == -1"
                       (click)="filterByOrderBy('dateRange', '-Max(treatment.Value)')">
                      Max
                      <mat-icon *ngIf="orderByFiler.dateRange?.indexOf('Max(treatment.Value)') != -1">arrow_drop_up
                      </mat-icon>
                    </p>
                    <p class="small pointer-cursor"
                      *ngIf="orderByFiler.dateRange?.indexOf('-Max(treatment.Value)') != -1"
                       (click)="filterByOrderBy('dateRange', 'Max(treatment.Value)')">
                      Max
                      <mat-icon>arrow_drop_down</mat-icon>
                    </p>
                  </div>
                  <div class="qtr-width">
                    <p class="small pointer-cursor"
                      *ngIf="orderByFiler.dateRange?.indexOf('-Avg(treatment.Value)') == -1"
                       (click)="filterByOrderBy('dateRange', '-Avg(treatment.Value)')">
                      Avg
                      <mat-icon *ngIf="orderByFiler.dateRange?.indexOf('Avg(treatment.Value)') != -1">arrow_drop_up
                      </mat-icon>
                    </p>
                    <p class="small pointer-cursor"
                      *ngIf="orderByFiler.dateRange?.indexOf('-Avg(treatment.Value)') != -1"
                       (click)="filterByOrderBy('dateRange', 'Avg(treatment.Value)')">
                      Avg
                      <mat-icon>arrow_drop_down</mat-icon>
                    </p>
                  </div>
                </div>
                <div class="qtr-width">
                  <p class="small pointer-cursor" *ngIf="orderByFiler.dateRange?.indexOf('-Sum(treatment.Value)') == -1"
                     (click)="filterByOrderBy('dateRange', '-Sum(treatment.Value)')">
                    &nbsp; Sum
                    <mat-icon *ngIf="orderByFiler.dateRange?.indexOf('Sum(treatment.Value)') != -1">arrow_drop_up
                    </mat-icon>
                  </p>
                  <p class="small pointer-cursor" *ngIf="orderByFiler.dateRange?.indexOf('-Sum(treatment.Value)') != -1"
                     (click)="filterByOrderBy('dateRange', 'Sum(treatment.Value)')">
                    &nbsp; Sum
                    <mat-icon>arrow_drop_down</mat-icon>
                  </p>
                </div>
              </div>
              <div *ngFor="
                  let p of stats.dateRange
                    | WithoutArrayMultipleValue: 'Count(*)':emptyResultFilter
                    | OrderArrayObject: orderByFiler.dateRange;
                  let i = index
                " @labelAnimation>
                <div class="row clearfix">
                  <div class="qtr-width">
                    <p>{{ p['ID'] }}</p>
                  </div>
                  <div class="half-width">
                    <div class="qtr-width">
                      <p class="value">{{ p['Count(*)'] }}</p>
                    </div>
                    <div class="qtr-width">
                      <p class="value">{{ p['Min(treatment.Value)'] | customCurrency }}</p>
                    </div>
                    <div class="qtr-width">
                      <p class="value">{{ p['Max(treatment.Value)'] | customCurrency }}</p>
                    </div>
                    <div class="qtr-width">
                      <p class="value">{{ p['Avg(treatment.Value)'] | customCurrency }}</p>
                    </div>
                  </div>
                  <div class="qtr-width">
                    <button class="btn-small list-button pull-right mat-button" *ngIf="p['Count(*)'] > 0"
                      (click)="displayList(p['dateFromRaw'], p['dateToRaw'], merchantID, dentistID, productID, status)"
                            matTooltip="View InvitationList: {{ p['ID'] }}">
                      <mat-icon>list_alt</mat-icon>
                    </button>
                    <p class="value">
                      <mat-icon class="posIcon" *ngIf="
                          arrowDirection(
                            stats.dateRange
                              | WithoutArrayMultipleValue: 'Count(*)':emptyResultFilter
                              | OrderArrayObject: orderByFiler.dateRange,
                            i,
                            'Sum(treatment.Value)'
                          ) == true
                        ">arrow_drop_up
                      </mat-icon>
                      <mat-icon class="negIcon" *ngIf="
                          arrowDirection(
                            stats.dateRange
                              | WithoutArrayMultipleValue: 'Count(*)':emptyResultFilter
                              | OrderArrayObject: orderByFiler.dateRange,
                            i,
                            'Sum(treatment.Value)'
                          ) == false
                        ">arrow_drop_down
                      </mat-icon>
                      {{ p['Sum(treatment.Value)'] | customCurrency }}
                    </p>
                  </div>
                </div>
                <mat-divider></mat-divider>
              </div>
            </div>
          </mat-card>
        </div>
      </div>
    </div>

    <div class="clearfix full-width animate" *ngIf="displayTimeLineList == true">
      <div class="clearfix subStatcontainer"
           *ngIf="stats && stats.days && stats.days.length > 0 && displayChartTime == 'days'">
        <div class="row clearfix full-width">
          <mat-card class="card dataCard clearfix">
            <div class="colour-stripe"></div>
            <div class="row clearfix">
              <h1 class="dataHeader pull-left">
                Statistics By Days
                <!-- <span class="badge">
                              {{stats.status.length}}</span> -->
              </h1>
              <mat-slide-toggle class="pull-right" [checked]="isEmptyResult" (change)="emptyResultChange($event)">Hide
                Empty Result
              </mat-slide-toggle>
            </div>
            <div class="row clearfix full-width">
              <div class="qtr-width">
                <p class="small pointer-cursor" *ngIf="orderByFiler.daily?.indexOf('-ID') == -1"
                   (click)="filterByOrderBy('daily', '-ID')">
                  Date
                  <mat-icon *ngIf="orderByFiler.daily?.indexOf('ID') != -1">arrow_drop_up</mat-icon>
                </p>
                <p class="small pointer-cursor" *ngIf="orderByFiler.daily?.indexOf('-ID') != -1"
                   (click)="filterByOrderBy('daily', 'ID')">
                  Date
                  <mat-icon>arrow_drop_down</mat-icon>
                </p>
              </div>
              <div class="qtr-width">
                <p class="small pointer-cursor" *ngIf="orderByFiler.daily?.indexOf('-Count(*)') == -1"
                   (click)="filterByOrderBy('daily', '-Count(*)')">
                  Units
                  <mat-icon *ngIf="orderByFiler.daily?.indexOf('Count(*)') != -1">arrow_drop_up</mat-icon>
                </p>
                <p class="small pointer-cursor" *ngIf="orderByFiler.daily?.indexOf('-Count(*)') != -1"
                   (click)="filterByOrderBy('daily', 'Count(*)')">
                  Units
                  <mat-icon>arrow_drop_down</mat-icon>
                </p>
              </div>
              <div class="qtr-width">
                <p class="small pointer-cursor" *ngIf="orderByFiler.daily?.indexOf('-Avg(treatment.Value)') == -1"
                   (click)="filterByOrderBy('daily', '-Avg(treatment.Value)')">
                  Avg
                  <mat-icon *ngIf="orderByFiler.daily?.indexOf('Avg(treatment.Value)') != -1"> arrow_drop_up</mat-icon>
                </p>
                <p class="small pointer-cursor" *ngIf="orderByFiler.daily?.indexOf('-Avg(treatment.Value)') != -1"
                   (click)="filterByOrderBy('daily', 'Avg(treatment.Value)')">
                  Avg
                  <mat-icon>arrow_drop_down</mat-icon>
                </p>
              </div>
              <div class="qtr-width">
                <p class="small pointer-cursor" *ngIf="orderByFiler.daily?.indexOf('-Sum(treatment.Value)') == -1"
                   (click)="filterByOrderBy('daily', '-Sum(treatment.Value)')">
                  &nbsp; Sum
                  <mat-icon *ngIf="orderByFiler.daily?.indexOf('Sum(treatment.Value)') != -1"> arrow_drop_up</mat-icon>
                </p>
                <p class="small pointer-cursor" *ngIf="orderByFiler.daily?.indexOf('-Sum(treatment.Value)') != -1"
                   (click)="filterByOrderBy('daily', 'Sum(treatment.Value)')">
                  &nbsp; Sum
                  <mat-icon>arrow_drop_down</mat-icon>
                </p>
              </div>
            </div>
            <div *ngFor="
                let p of stats.days
                  | WithoutArrayMultipleValue: 'Count(*)':emptyResultFilter
                  | OrderArrayObject: orderByFiler.daily;
                let i = index
              " @labelAnimation>
              <div class="row clearfix">
                <div class="qtr-width">
                  <p>{{ p['ID'] }}</p>
                </div>
                <div class="qtr-width">
                  <p class="value">{{ p['Count(*)'] }}</p>
                </div>
                <div class="qtr-width">
                  <p class="value">{{ p['Avg(treatment.Value)'] | customCurrency }}</p>
                </div>
                <div class="qtr-width">
                  <button class="btn-small list-button pull-right mat-button" *ngIf="p['Count(*)'] > 0"
                    (click)="displayList(p['dateFromRaw'], p['dateToRaw'], merchantID, dentistID, productID, status)"
                          matTooltip="View InvitationList: {{ p['ID'] }}">
                    <mat-icon>list_alt</mat-icon>
                  </button>
                  <p class="value">
                    <mat-icon class="posIcon" *ngIf="
                        arrowDirection(
                          stats.days
                            | WithoutArrayMultipleValue: 'Count(*)':emptyResultFilter
                            | OrderArrayObject: orderByFiler.daily,
                          i,
                          'Sum(treatment.Value)'
                        ) == true
                      ">arrow_drop_up
                    </mat-icon>
                    <mat-icon class="negIcon" *ngIf="
                        arrowDirection(
                          stats.days
                            | WithoutArrayMultipleValue: 'Count(*)':emptyResultFilter
                            | OrderArrayObject: orderByFiler.daily,
                          i,
                          'Sum(treatment.Value)'
                        ) == false
                      ">arrow_drop_down
                    </mat-icon>
                    {{ p['Sum(treatment.Value)'] | customCurrency }}
                  </p>
                </div>
              </div>
              <mat-divider></mat-divider>
            </div>
          </mat-card>
        </div>
      </div>
      <div class="clearfix subStatcontainer"
           *ngIf="stats && stats.months && stats.months.length > 0 && displayChartTime == 'months'">
        <div class="row clearfix full-width">
          <mat-card class="card dataCard clearfix">
            <div class="colour-stripe"></div>
            <div class="row clearfix">
              <h1 class="dataHeader pull-left">
                Statistics By Months
                <!-- <span class="badge">
                            {{stats.status.length}}</span> -->
              </h1>
              <mat-slide-toggle class="pull-right" [checked]="isEmptyResult" (change)="emptyResultChange($event)">Hide
                Empty Result
              </mat-slide-toggle>
            </div>
            <div class="row clearfix full-width">
              <div class="qtr-width">
                <p class="small pointer-cursor" *ngIf="orderByFiler.monthly?.indexOf('-ID') == -1"
                   (click)="filterByOrderBy('monthly', '-ID')">
                  Month
                  <mat-icon *ngIf="orderByFiler.monthly?.indexOf('ID') != -1">arrow_drop_up</mat-icon>
                </p>
                <p class="small pointer-cursor" *ngIf="orderByFiler.monthly?.indexOf('-ID') != -1"
                   (click)="filterByOrderBy('monthly', 'ID')">
                  Month
                  <mat-icon>arrow_drop_down</mat-icon>
                </p>
              </div>
              <div class="qtr-width">
                <p class="small pointer-cursor" *ngIf="orderByFiler.monthly?.indexOf('-Count(*)') == -1"
                   (click)="filterByOrderBy('monthly', '-Count(*)')">
                  Units
                  <mat-icon *ngIf="orderByFiler.monthly?.indexOf('Count(*)') != -1">arrow_drop_up</mat-icon>
                </p>
                <p class="small pointer-cursor" *ngIf="orderByFiler.monthly?.indexOf('-Count(*)') != -1"
                   (click)="filterByOrderBy('monthly', 'Count(*)')">
                  Units
                  <mat-icon>arrow_drop_down</mat-icon>
                </p>
              </div>
              <div class="qtr-width">
                <p class="small pointer-cursor" *ngIf="orderByFiler.monthly?.indexOf('-Avg(treatment.Value)') == -1"
                   (click)="filterByOrderBy('monthly', '-Avg(treatment.Value)')">
                  Avg
                  <mat-icon *ngIf="orderByFiler.monthly?.indexOf('Avg(treatment.Value)') != -1">
                    arrow_drop_up
                  </mat-icon>
                </p>
                <p class="small pointer-cursor" *ngIf="orderByFiler.monthly?.indexOf('-Avg(treatment.Value)') != -1"
                   (click)="filterByOrderBy('monthly', 'Avg(treatment.Value)')">
                  Avg
                  <mat-icon>arrow_drop_down</mat-icon>
                </p>
              </div>
              <div class="qtr-width">
                <p class="small pointer-cursor" *ngIf="orderByFiler.monthly?.indexOf('-Sum(treatment.Value)') == -1"
                   (click)="filterByOrderBy('monthly', '-Sum(treatment.Value)')">
                  &nbsp; Sum
                  <mat-icon *ngIf="orderByFiler.monthly?.indexOf('Sum(treatment.Value)') != -1">arrow_drop_up</mat-icon>
                </p>
                <p class="small pointer-cursor" *ngIf="orderByFiler.monthly?.indexOf('-Sum(treatment.Value)') != -1"
                   (click)="filterByOrderBy('monthly', 'Sum(treatment.Value)')">
                  &nbsp; Sum
                  <mat-icon>arrow_drop_down</mat-icon>
                </p>
              </div>
            </div>
            <div *ngFor="
                let p of stats.months
                  | WithoutArrayMultipleValue: 'Count(*)':emptyResultFilter
                  | OrderArrayObject: orderByFiler.monthly;
                let i = index
              " @labelAnimation>
              <div class="row clearfix">
                <div class="qtr-width">
                  <p>{{ p['ID'] }}</p>
                </div>
                <div class="qtr-width">
                  <p class="value">{{ p['Count(*)'] }}</p>
                </div>
                <div class="qtr-width">
                  <p class="value">{{ p['Avg(treatment.Value)'] | customCurrency }}</p>
                </div>
                <div class="qtr-width">
                  <button class="btn-small list-button pull-right mat-button" *ngIf="p['Count(*)'] > 0"
                    (click)="displayList(p['dateFromRaw'], p['dateToRaw'], merchantID, dentistID, productID, status)"
                          matTooltip="View InvitationList: {{ p['ID'] }}">
                    <mat-icon>list_alt</mat-icon>
                  </button>
                  <p class="value">
                    <mat-icon class="posIcon" *ngIf="
                        arrowDirection(
                          stats.months
                            | WithoutArrayMultipleValue: 'Count(*)':emptyResultFilter
                            | OrderArrayObject: orderByFiler.monthly,
                          i,
                          'Sum(treatment.Value)'
                        ) == true
                      ">arrow_drop_up
                    </mat-icon>
                    <mat-icon class="negIcon" *ngIf="
                        arrowDirection(
                          stats.months
                            | WithoutArrayMultipleValue: 'Count(*)':emptyResultFilter
                            | OrderArrayObject: orderByFiler.monthly,
                          i,
                          'Sum(treatment.Value)'
                        ) == false
                      ">arrow_drop_down
                    </mat-icon>
                    {{ p['Sum(treatment.Value)'] | customCurrency }}
                  </p>
                </div>
              </div>
              <mat-divider></mat-divider>
            </div>
          </mat-card>
        </div>
      </div>
      <div class="clearfix subStatcontainer"
           *ngIf="stats && stats.years && stats.years.length > 0 && displayChartTime == 'years'">
        <div class="row clearfix full-width">
          <mat-card class="card dataCard clearfix">
            <div class="colour-stripe"></div>
            <div class="row clearfix">
              <h1 class="dataHeader pull-left">
                Statistics By Years
                <!-- <span class="badge">
                          {{stats.status.length}}</span> -->
              </h1>
              <mat-slide-toggle class="pull-right" [checked]="isEmptyResult" (change)="emptyResultChange($event)">Hide
                Empty Result
              </mat-slide-toggle>
            </div>
            <div class="row clearfix full-width">
              <div class="qtr-width">
                <p class="small pointer-cursor" *ngIf="orderByFiler.annualy?.indexOf('-ID') == -1"
                   (click)="filterByOrderBy('annualy', '-ID')">
                  Year
                  <mat-icon *ngIf="orderByFiler.annualy?.indexOf('ID') != -1">arrow_drop_up</mat-icon>
                </p>
                <p class="small pointer-cursor" *ngIf="orderByFiler.annualy?.indexOf('-ID') != -1"
                   (click)="filterByOrderBy('annualy', 'ID')">
                  Year
                  <mat-icon>arrow_drop_down</mat-icon>
                </p>
              </div>
              <div class="qtr-width">
                <p class="small pointer-cursor" *ngIf="orderByFiler.annualy?.indexOf('-Count(*)') == -1"
                   (click)="filterByOrderBy('annualy', '-Count(*)')">
                  Units
                  <mat-icon *ngIf="orderByFiler.annualy?.indexOf('Count(*)') != -1">arrow_drop_up</mat-icon>
                </p>
                <p class="small pointer-cursor" *ngIf="orderByFiler.annualy?.indexOf('-Count(*)') != -1"
                   (click)="filterByOrderBy('annualy', 'Count(*)')">
                  Units
                  <mat-icon>arrow_drop_down</mat-icon>
                </p>
              </div>
              <div class="qtr-width">
                <p class="small pointer-cursor" *ngIf="orderByFiler.annualy?.indexOf('-Avg(treatment.Value)') == -1"
                   (click)="filterByOrderBy('annualy', '-Avg(treatment.Value)')">
                  Avg
                  <mat-icon *ngIf="orderByFiler.annualy?.indexOf('Avg(treatment.Value)') != -1">
                    arrow_drop_up
                  </mat-icon>
                </p>
                <p class="small pointer-cursor" *ngIf="orderByFiler.annualy?.indexOf('-Avg(treatment.Value)') != -1"
                   (click)="filterByOrderBy('annualy', 'Avg(treatment.Value)')">
                  Avg
                  <mat-icon>arrow_drop_down</mat-icon>
                </p>
              </div>
              <div class="qtr-width">
                <p class="small pointer-cursor" *ngIf="orderByFiler.annualy?.indexOf('-Sum(treatment.Value)') == -1"
                   (click)="filterByOrderBy('annualy', '-Sum(treatment.Value)')">
                  &nbsp; Sum
                  <mat-icon *ngIf="orderByFiler.annualy?.indexOf('Sum(treatment.Value)') != -1">arrow_drop_up</mat-icon>
                </p>
                <p class="small pointer-cursor" *ngIf="orderByFiler.annualy?.indexOf('-Sum(treatment.Value)') != -1"
                   (click)="filterByOrderBy('annualy', 'Sum(treatment.Value)')">
                  &nbsp; Sum
                  <mat-icon>arrow_drop_down</mat-icon>
                </p>
              </div>
            </div>
            <div *ngFor="
                let p of stats.years
                  | WithoutArrayMultipleValue: 'Count(*)':emptyResultFilter
                  | OrderArrayObject: orderByFiler.annualy;
                let i = index
              " @labelAnimation>
              <div class="row clearfix">
                <div class="qtr-width">
                  <p>{{ p['ID'] }}</p>
                </div>
                <div class="qtr-width">
                  <p class="value">{{ p['Count(*)'] }}</p>
                </div>
                <div class="qtr-width">
                  <p class="value">{{ p['Avg(treatment.Value)'] | customCurrency }}</p>
                </div>
                <div class="qtr-width">
                  <button class="btn-small list-button pull-right mat-button" *ngIf="p['Count(*)'] > 0"
                    (click)="displayList(p['dateFromRaw'], p['dateToRaw'], merchantID, dentistID, productID, status)"
                          matTooltip="View InvitationList: {{ p['ID'] }}">
                    <mat-icon>list_alt</mat-icon>
                  </button>
                  <p class="value">
                    <mat-icon class="posIcon" *ngIf="
                        arrowDirection(
                          stats.years
                            | WithoutArrayMultipleValue: 'Count(*)':emptyResultFilter
                            | OrderArrayObject: orderByFiler.annualy,
                          i,
                          'Sum(treatment.Value)'
                        ) == true
                      ">arrow_drop_up
                    </mat-icon>
                    <mat-icon class="negIcon" *ngIf="
                        arrowDirection(
                          stats.years
                            | WithoutArrayMultipleValue: 'Count(*)':emptyResultFilter
                            | OrderArrayObject: orderByFiler.annualy,
                          i,
                          'Sum(treatment.Value)'
                        ) == false
                      ">arrow_drop_down
                    </mat-icon>
                    {{ p['Sum(treatment.Value)'] | customCurrency }}
                  </p>
                </div>
              </div>
              <mat-divider></mat-divider>
            </div>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
  <div class="row clearfix full-width tabArea">
    <mat-tab-group class="perfTabs" *ngIf="stats && stats.global && displayInvitationList == false"
                   [selectedIndex]="selectedIndex" (selectedIndexChange)="loadStats($event)" dynamicHeight="true">
      <mat-tab id="tool6" label="Status">
        <div *ngIf="stats && stats.status && stats.status.length > 0 && indexLabel == 'status'" @ngIfAnimation>
          <div class="row clearfix full-width">
            <mat-card class="card dataCard clearfix">
              <div class="colour-stripe"></div>
              <div class="row clearfix">
                <h2 class="sr-title lg-h2 pull-left dataHead">Invitations Status Statistics</h2>

                <div class="row pull-right clearfix">
                  <div class="flex">
                    <mat-button-toggle-group class="btn-group text-center" [(ngModel)]="statusFilterType"
                                             (change)="changeStatusFilter()" name="statusFilters">
                      <mat-button-toggle class="text-center pos" [value]="'all'" matTooltip="All Status">
                        All Status
                      </mat-button-toggle>

                      <mat-button-toggle class="text-center pos" [value]="'invitation'"
                                         matTooltip="All Invitations Status">
                        Invitations Status
                      </mat-button-toggle>

                      <mat-button-toggle class="text-center pos" [value]="'contract'"
                                         matTooltip="All  Contracts Status">
                        Contracts Status
                      </mat-button-toggle>

                      <mat-button-toggle class="text-center pos" [value]="'proceeded'"
                                         matTooltip="Proceeded Invitations">
                        <mat-icon>mood</mat-icon>
                      </mat-button-toggle>
                      <mat-button-toggle class="text-center maybe" [value]="'maybe'" matTooltip="Maybe">
                        <mat-icon>sentiment_satisfied</mat-icon>
                      </mat-button-toggle>
                      <mat-button-toggle class="text-center neg" [value]="'notProceed'"
                                         matTooltip="Not Proceeded  Invitations">
                        <mat-icon>mood_bad</mat-icon>
                      </mat-button-toggle>
                    </mat-button-toggle-group>

                    <mat-slide-toggle class="margin-left-input" [checked]="isEmptyResult"
                                      (change)="emptyResultChange($event)">Hide Empty Results
                    </mat-slide-toggle>
                  </div>
                </div>

                <hr/>
              </div>
              <div class="quickLook row clearfix text-center mt add-mb">
                <div class="qtr-width">
                  <div class="column">
                    <mat-card class="mat-elevation-z7">
                      <h3 class="sr-title sm-h3 rm-bp">Out of</h3>
                      <span class="bigNum" [endVal]="
                          stats.global['Count(*)'] -
                          (stats.status
                            | FilterArrayMultipleValue: 'ID':['notViewed']
                            | objectPropertyValue: 'Count(*)'
                            | toNumberArray
                            | sum)
                        " countUp></span>
                      <h3 class="sr-title sm-h3 rm-m">Opened invitations</h3>
                      <p class="small">
                        ({{
                        (stats.global['Count(*)'] -
                          (stats.status
                            | FilterArrayMultipleValue: 'ID':['notViewed']
                            | objectPropertyValue: 'Count(*)'
                            | toNumberArray
                            | sum)) /
                        stats.global['Count(*)'] | percent: '2.1-1'
                        }}

                        of sent invitations)
                      </p>
                    </mat-card>
                  </div>
                </div>
                <div class="qtr-width">
                  <div class="column">
                    <mat-card class="mat-elevation-z7">
                      <h3 class="sr-title sm-h3 rm-bp">You had</h3>
                      <span class="bigNum" [endVal]="
                          stats.status
                            | FilterArrayMultipleValue: 'ID':['proceedingCash']
                            | objectPropertyValue: 'Count(*)'
                            | toNumberArray
                            | sum
                        " countUp></span>
                      <h3 class="sr-title sm-h3 rm-m">{{ "KEYWORD.patient" | translate | titlecase}}s proceed with
                        cash/card</h3>
                      <p class="small">
                        (
                        {{
                        (stats.status
                          | FilterArrayMultipleValue: 'ID':['proceedingCash']
                          | objectPropertyValue: 'Count(*)'
                          | toNumberArray
                          | sum) /
                        (stats.global['Count(*)'] -
                          (stats.status
                            | FilterArrayMultipleValue: 'ID':['notViewed']
                            | objectPropertyValue: 'Count(*)'
                            | toNumberArray
                            | sum)) | percent: '2.1-1'
                        }}

                        of opened invitations)
                      </p>
                    </mat-card>
                  </div>
                </div>
                <div class="qtr-width">
                  <div class="column">
                    <mat-card class="mat-elevation-z7">
                      <h3 class="sr-title sm-h3 rm-bp">As well as</h3>
                      <span class="bigNum" [endVal]="
                          stats.status
                            | FilterArrayMultipleValue: 'ID':['approved']
                            | objectPropertyValue: 'Count(*)'
                            | toNumberArray
                            | sum
                        " countUp></span>
                      <h3 class="sr-title sm-h3 rm-m">{{ "KEYWORD.patient" | translate | titlecase}}s approved for
                        finance</h3>
                      <p class="small">
                        (
                        {{
                        (stats.status
                          | FilterArrayMultipleValue: 'ID':['approved']
                          | objectPropertyValue: 'Count(*)'
                          | toNumberArray
                          | sum) /
                        (stats.global['Count(*)'] -
                          (stats.status
                            | FilterArrayMultipleValue: 'ID':['notViewed']
                            | objectPropertyValue: 'Count(*)'
                            | toNumberArray
                            | sum)) | percent: '2.1-1'
                        }}
                        of opened invitations)
                      </p>
                    </mat-card>
                  </div>
                </div>
                <div class="qtr-width">
                  <div class="column">
                    <mat-card class="mat-elevation-z7">
                      <h3 class="sr-title sm-h3 rm-bp">Resulting in a</h3>
                      <span class="bigNum"><span [endVal]="
                            ((stats.status
                              | FilterArrayMultipleValue: 'ID':['active']
                              | objectPropertyValue: 'Count(*)'
                              | toNumberArray
                              | sum) /
                              ((stats.status
                                | FilterArrayMultipleValue: 'ID':['approved']
                                | objectPropertyValue: 'Count(*)'
                                | toNumberArray
                                | sum) +
                                (stats.status
                                  | FilterArrayMultipleValue: 'ID':['completed']
                                  | objectPropertyValue: 'Count(*)'
                                  | toNumberArray
                                  | sum) +
                                (stats.status
                                  | FilterArrayMultipleValue: 'ID':['referred']
                                  | objectPropertyValue: 'Count(*)'
                                  | toNumberArray
                                  | sum) +
                                (stats.status
                                  | FilterArrayMultipleValue: 'ID':['declined']
                                  | objectPropertyValue: 'Count(*)'
                                  | toNumberArray
                                  | sum) +
                                (stats.status
                                  | FilterArrayMultipleValue: 'ID':['active']
                                  | objectPropertyValue: 'Count(*)'
                                  | toNumberArray
                                  | sum))) *
                            100
                          " countUp></span>%</span>
                      <h3 class="sr-title sm-h3 rm-m">Accepted {{ "KEYWORD.treatment" | translate | titlecase }}
                        Rate</h3>
                      <p class="small">
                        (
                        {{
                        (stats.status
                          | FilterArrayMultipleValue: 'ID':['active', 'proceedingCash']
                          | WithoutArrayMultipleValue: 'Count(*)':emptyResultFilter
                          | objectPropertyValue: 'Count(*)'
                          | sum) /
                        (stats.global['Count(*)'] -
                          (stats.status
                            | FilterArrayMultipleValue: 'ID':['notViewed']
                            | WithoutArrayMultipleValue: 'Count(*)':emptyResultFilter
                            | objectPropertyValue: 'Count(*)'
                            | sum)) | percent: '2.1-1'
                        }}
                        finance conversion rate)
                      </p>
                    </mat-card>
                  </div>
                </div>
              </div>
              <div class="row clearfix mt full-width mb" *ngIf="stats && stats.status && stats.status.length > 1">
                <div class="half-width">
                  <div class="col-left rel">
                    <div class="rel mb row clearfix">
                      <app-chart-input class="row" *ngIf="stats && stats.status && displayChartView == 'amount'"
                                       [xAxisLabel]="'Status'"
                                       [yAxisLabel]="('KEYWORD.treatment' | translate | titlecase) + ' Amount ($)'"
                                       [data]="
                          stats.status
                            | FilterArrayMultipleValue: 'ID':statusFilterTypeArray
                            | WithoutArrayMultipleValue: 'Count(*)':emptyResultFilter
                            | OrderArrayObject: orderByFiler.status
                        " [dataNameAttribute]="'name'" [dataValueAttribute]="'Sum(treatment.Value)'"
                        [chartType]="'barHorizontal'"
                                       [title]="'Status vs ' + ('KEYWORD.treatment' | translate | titlecase) + ' Amount'"
                                       [showLegend]="false"
                                       [view]="undefined"></app-chart-input>
                    </div>
                    <div class="rel mb row clearfix">
                      <app-chart-input class="row" *ngIf="stats && stats.status && displayChartView == 'unit'"
                                       [xAxisLabel]="'Invitation(s)'" [yAxisLabel]="'Status'" [data]="
                          stats.status
                            | FilterArrayMultipleValue: 'ID':statusFilterTypeArray
                            | WithoutArrayMultipleValue: 'Count(*)':emptyResultFilter
                            | OrderArrayObject: orderByFiler.status
                        " [dataNameAttribute]="'name'" [dataValueAttribute]="'Count(*)'" [showLegend]="false"
                                       [chartType]="'barHorizontal'" [title]="'Status vs Invitations'"
                                       [view]="undefined">
                      </app-chart-input>
                    </div>
                    <hr/>
                    <mat-button-toggle-group class="btn-group clearfix full-width text-center"
                                             [(ngModel)]="displayChartView">
                      <mat-button-toggle class="text-center half-width" [value]="'unit'">
                        Invitations
                      </mat-button-toggle>
                      <mat-button-toggle class="text-center half-width" [value]="'amount'">
                        Amount($)
                      </mat-button-toggle>
                    </mat-button-toggle-group>
                  </div>
                </div>
                <div class="half-width">
                  <div class="col-right">
                    <div class="row clearfix full-width">
                      <div class="qtr-width">
                        <p class="small pointer-cursor" *ngIf="orderByFiler.status?.indexOf('-name') == -1"
                           (click)="filterByOrderBy('status', '-name')">
                          Status
                          <mat-icon *ngIf="orderByFiler.status?.indexOf('name') != -1"> arrow_drop_up</mat-icon>
                        </p>
                        <p class="small pointer-cursor" *ngIf="orderByFiler.status?.indexOf('-name') != -1"
                           (click)="filterByOrderBy('status', 'name')">
                          Status
                          <mat-icon>arrow_drop_down</mat-icon>
                        </p>
                      </div>
                      <div class="half-width">
                        <div class="half-width">
                          <p class="small pointer-cursor" *ngIf="orderByFiler.status?.indexOf('-Count(*)') == -1"
                             (click)="filterByOrderBy('status', '-Count(*)')">
                            Units
                            <mat-icon *ngIf="orderByFiler.status?.indexOf('Count(*)') != -1"> arrow_drop_up</mat-icon>
                          </p>
                          <p class="small pointer-cursor" *ngIf="orderByFiler.status?.indexOf('-Count(*)') != -1"
                             (click)="filterByOrderBy('status', 'Count(*)')">
                            Units
                            <mat-icon>arrow_drop_down</mat-icon>
                          </p>
                        </div>
                        <div class="half-width">
                          <p class="small pointer-cursor"
                            *ngIf="orderByFiler.status?.indexOf('-Avg(treatment.Value)') == -1"
                             (click)="filterByOrderBy('status', '-Avg(treatment.Value)')">
                            Avg
                            <mat-icon *ngIf="orderByFiler.status?.indexOf('Avg(treatment.Value)') != -1">arrow_drop_up
                            </mat-icon>
                          </p>
                          <p class="small pointer-cursor"
                            *ngIf="orderByFiler.status?.indexOf('-Avg(treatment.Value)') != -1"
                             (click)="filterByOrderBy('status', 'Avg(treatment.Value)')">
                            Avg
                            <mat-icon>arrow_drop_down</mat-icon>
                          </p>
                        </div>
                      </div>
                      <div class="qtr-width">
                        <p class="small pointer-cursor"
                          *ngIf="orderByFiler.status?.indexOf('-Sum(treatment.Value)') == -1"
                           (click)="filterByOrderBy('status', '-Sum(treatment.Value)')">
                          &nbsp; Sum
                          <mat-icon *ngIf="orderByFiler.status?.indexOf('Sum(treatment.Value)') != -1">arrow_drop_up
                          </mat-icon>
                        </p>
                        <p class="small pointer-cursor"
                          *ngIf="orderByFiler.status?.indexOf('-Sum(treatment.Value)') != -1"
                           (click)="filterByOrderBy('status', 'Sum(treatment.Value)')">
                          &nbsp; Sum
                          <mat-icon>arrow_drop_down</mat-icon>
                        </p>
                      </div>
                    </div>
                    <div *ngFor="
                        let p of stats.status
                          | FilterArrayMultipleValue: 'ID':statusFilterTypeArray
                          | WithoutArrayMultipleValue: 'Count(*)':emptyResultFilter
                          | filterBy: ['name']:statusFiler
                          | OrderArrayObject: orderByFiler.status;
                        let i = index
                      " @labelAnimation>
                      <div class="row clearfix">
                        <div class="qtr-width">
                          <p>{{ p['name'] }}</p>
                        </div>
                        <div class="half-width">
                          <div class="half-width">
                            <p class="value">{{ p['Count(*)'] }}</p>
                          </div>
                          <div class="half-width">
                            <p class="value">{{ p['Avg(treatment.Value)'] | customCurrency }}</p>
                          </div>
                        </div>
                        <div class="qtr-width">
                          <button class="btn-small list-button pull-right mat-button" *ngIf="p['Count(*)'] > 0"
                            (click)="displayList(dateFrom, dateTo, merchantID, dentistID, productID, p['ID'])"
                                  matTooltip="View InvitationList: {{ p['name'] }}">
                            <mat-icon>list_alt</mat-icon>
                          </button>
                          <p class="value">
                            <mat-icon class="posIcon" *ngIf="
                                arrowDirection(
                                  stats.status
                                    | FilterArrayMultipleValue: 'ID':statusFilterTypeArray
                                    | WithoutArrayMultipleValue: 'Count(*)':emptyResultFilter
                                    | filterBy: ['name']:statusFiler
                                    | OrderArrayObject: orderByFiler.status,
                                  i,
                                  'Sum(treatment.Value)'
                                ) == true
                              ">arrow_drop_up
                            </mat-icon>
                            <mat-icon class="negIcon" *ngIf="
                                arrowDirection(
                                  stats.status
                                    | FilterArrayMultipleValue: 'ID':statusFilterTypeArray
                                    | WithoutArrayMultipleValue: 'Count(*)':emptyResultFilter
                                    | filterBy: ['name']:statusFiler
                                    | OrderArrayObject: orderByFiler.status,
                                  i,
                                  'Sum(treatment.Value)'
                                ) == false
                              ">arrow_drop_down
                            </mat-icon>
                            {{ p['Sum(treatment.Value)'] | customCurrency }}
                          </p>
                        </div>
                      </div>
                      <mat-divider></mat-divider>
                    </div>
                  </div>
                </div>
              </div>
            </mat-card>
          </div>
        </div>
      </mat-tab>
      <mat-tab class="" id="tool1" label="Products">
        <div *ngIf="
            stats &&
            stats.products &&
            indexLabel == 'product' &&
            stats.products.length > 0 &&
            (isAdminOrPromoter != true || (isAdminOrPromoter == true && merchantID != 'none' && merchantID))
          " @ngIfAnimation>
          <div class="row clearfix full-width">
            <mat-card class="card dataCard clearfix">
              <div class="colour-stripe"></div>
              <div class="row clearfix">
                <h2 class="sr-title lg-h2 pull-left dataHead">Products Statistics</h2>
                <mat-slide-toggle class="pull-right" [checked]="isEmptyResult" (change)="emptyResultChange($event)">Hide
                  Empty Result
                </mat-slide-toggle>
                <hr/>
              </div>
              <div class="quickLook row clearfix text-center mt add-mb">
                <div class="qtr-width">
                  <div class="column">
                    <mat-card class="mat-elevation-z7">
                      <h3 class="sr-title sm-h3 rm-bp">Out of</h3>
                      <span class="bigNum" [endVal]="
                          stats.status
                            | FilterArrayMultipleValue: 'ID':['active', 'proceedingCash']
                            | objectPropertyValue: 'Count(*)'
                            | toNumberArray
                            | sum
                        " countUp></span>
                      <h3 class="sr-title sm-h3 rm-m">Financed {{ "KEYWORD.patient" | translate }}s</h3>
                      <p class="small">
                        ({{
                        (stats.status
                          | FilterArrayMultipleValue: 'ID':['active', 'proceedingCash']
                          | objectPropertyValue: 'Count(*)'
                          | toNumberArray
                          | sum) /
                        ((stats.status
                            | FilterArrayMultipleValue: 'ID':['approved']
                            | objectPropertyValue: 'Count(*)'
                            | toNumberArray
                            | sum) +
                          (stats.status
                            | FilterArrayMultipleValue: 'ID':['completed']
                            | objectPropertyValue: 'Count(*)'
                            | toNumberArray
                            | sum) +
                          (stats.status
                            | FilterArrayMultipleValue: 'ID':['referred']
                            | objectPropertyValue: 'Count(*)'
                            | toNumberArray
                            | sum) +
                          (stats.status
                            | FilterArrayMultipleValue: 'ID':['declined']
                            | objectPropertyValue: 'Count(*)'
                            | toNumberArray
                            | sum) +
                          (stats.status
                            | FilterArrayMultipleValue: 'ID':['proceedingCash']
                            | objectPropertyValue: 'Count(*)'
                            | toNumberArray
                            | sum) +
                          (stats.status
                            | FilterArrayMultipleValue: 'ID':['active']
                            | objectPropertyValue: 'Count(*)'
                            | toNumberArray
                            | sum)) | percent: '2.1-1'
                        }} of accepted {{ "KEYWORD.treatment" | translate }}s)
                      </p>
                    </mat-card>
                  </div>
                </div>
                <div class="qtr-width">
                  <div class="column">
                    <mat-card class="mat-elevation-z7">
                      <h3 class="sr-title sm-h3 rm-bp">You converted</h3>
                      <span class="bigNum" [endVal]="
                          stats.products
                            | FilterArrayMultipleValue: 'isPaymentPlan':[true]
                            | objectPropertyValue: 'Count(*)'
                            | toNumberArray
                            | sum
                        " countUp></span>
                      <h3 class="sr-title sm-h3 rm-m">Payment plans</h3>
                      <p class="small">
                        (
                        {{
                        (stats.products
                          | FilterArrayMultipleValue: 'isPaymentPlan':[true]
                          | objectPropertyValue: 'Count(*)'
                          | toNumberArray
                          | sum) / (stats.products | objectPropertyValue: 'Count(*)' | toNumberArray | sum)
                          | percent: '2.1-1'
                        }}
                        of financed {{ "KEYWORD.treatment" | translate }}s)
                      </p>
                    </mat-card>
                  </div>
                </div>
                <div class="qtr-width">
                  <div class="column">
                    <mat-card class="mat-elevation-z7">
                      <h3 class="sr-title sm-h3 rm-bp">As well as</h3>
                      <span class="bigNum" [endVal]="
                          stats.products
                            | FilterArrayMultipleValue: 'isPaymentPlan':[false]
                            | objectPropertyValue: 'Count(*)'
                            | toNumberArray
                            | sum
                        " countUp></span>
                      <h3 class="sr-title sm-h3 rm-m">Loan products</h3>
                      <p class="small">
                        ({{
                        (stats.products
                          | FilterArrayMultipleValue: 'isPaymentPlan':[false]
                          | objectPropertyValue: 'Count(*)'
                          | toNumberArray
                          | sum) / (stats.products | objectPropertyValue: 'Count(*)' | toNumberArray | sum)
                          | percent: '2.1-1'
                        }}
                        of financed {{ "KEYWORD.treatment" | translate }}s)
                      </p>
                    </mat-card>
                  </div>
                </div>
                <div class="qtr-width">
                  <div class="column">
                    <mat-card class="mat-elevation-z7">
                      <h3 class="sr-title sm-h3 rm-bp">And there are</h3>
                      <span class="bigNum" [endVal]="
                          stats.status
                            | FilterArrayMultipleValue: 'ID':['viewed']
                            | objectPropertyValue: 'Count(*)'
                            | toNumberArray
                            | sum
                        " countUp></span>
                      <h3 class="sr-title sm-h3 rm-m">still waiting to make a decision</h3>
                      <p class="small">
                        ({{
                        (stats.status
                          | FilterArrayMultipleValue: 'ID':['viewed']
                          | objectPropertyValue: 'Count(*)'
                          | sum) / stats.global['Count(*)'] | percent: '2.1-1'
                        }}
                        of sent invitations)
                      </p>
                    </mat-card>
                  </div>
                </div>
              </div>
              <div class="row clearfix full-width mb" *ngIf="stats && stats.products">
                <div class="half-width rel">
                  <div class="col-left">
                    <div class="rel mb row clearfix" *ngIf="displayProdChartView == 'unit'">
                      <div class="total anim">
                        <span [endVal]="
                            stats.products
                              | WithoutArrayMultipleValue: 'Count(*)':emptyResultFilter
                              | objectPropertyValue: 'Count(*)'
                              | sum
                          " countUp></span>
                        <span class="totSub">Total</span>
                      </div>
                      <app-chart-input class="row full-width pie" *ngIf="stats && stats.products"
                                       [xAxisLabel]="'Products'" [yAxisLabel]="'Invitation(s)'" [data]="
                          stats.products
                            | WithoutArrayMultipleValue: 'Count(*)':emptyResultFilter
                            | OrderArrayObject: orderByFiler.product
                        " [dataNameAttribute]="'MarketingLabel,SubType'" [dataValueAttribute]="'Sum(treatment.Value)'"
                                       [separator]="'-'" [chartType]="'pie'" [title]="'Product vs Invitations'"
                                       [view]="undefined"
                                       [showLegend]="false"></app-chart-input>
                    </div>

                    <div class="rel mb row clearfix" *ngIf="displayProdChartView == 'amount'">
                      <div class="total anim">
                        <span [endVal]="
                            stats.products
                              | WithoutArrayMultipleValue: 'Count(*)':emptyResultFilter
                              | objectPropertyValue: 'Sum(treatment.Value)'
                              | sum
                          " countUp></span>
                        <span class="totSub">Total</span>
                      </div>

                      <app-chart-input class="row full-width pie" *ngIf="stats && stats.products"
                                       [yAxisLabel]="'Products'" [xAxisLabel]="'Treatment Amount ($)'" [data]="
                          stats.products
                            | WithoutArrayMultipleValue: 'Count(*)':emptyResultFilter
                            | OrderArrayObject: orderByFiler.product
                        " [dataNameAttribute]="'MarketingLabel,SubType'" [dataValueAttribute]="'Count(*)'"
                                       [separator]="'-'" [chartType]="'pie'"
                                       [title]="'Products vs ' + ('KEYWORD.treatment' | translate | titlecase) + ' Amount ($)'"
                                       [showLegend]="false" [view]="undefined"></app-chart-input>
                    </div>

                    <hr/>
                    <mat-button-toggle-group class="btn-group clearfix full-width text-center"
                                             [(ngModel)]="displayProdChartView">
                      <mat-button-toggle class="text-center half-width" [value]="'unit'">
                        Invitations
                      </mat-button-toggle>
                      <mat-button-toggle class="text-center half-width" [value]="'amount'">
                        Amount($)
                      </mat-button-toggle>
                    </mat-button-toggle-group>
                  </div>
                </div>
                <div class="half-width">
                  <div class="column">
                    <div class="row clearfix half-width">
                      <mat-form-field class="full-width" appearance="outline">
                        <mat-label>Product filter</mat-label>
                        <input class="small" [(ngModel)]="productFilter" name="productFilter" matInput
                               placeholder="Product filter"/>
                      </mat-form-field>
                    </div>
                    <div class="row clearfix full-width">
                      <div class="qtr-width">
                        <p class="small pointer-cursor" *ngIf="orderByFiler.product?.indexOf('-MarketingLabel') == -1"
                           (click)="filterByOrderBy('product', '-MarketingLabel')">
                          Product
                          <mat-icon *ngIf="orderByFiler.product?.indexOf('MarketingLabel') != -1">arrow_drop_up
                          </mat-icon>
                        </p>
                        <p class="small pointer-cursor" *ngIf="orderByFiler.product?.indexOf('-MarketingLabel') != -1"
                           (click)="filterByOrderBy('product', 'MarketingLabel')">
                          Product
                          <mat-icon>arrow_drop_down</mat-icon>
                        </p>
                      </div>
                      <div class="half-width">
                        <div class="qtr-width">
                          <p class="small pointer-cursor" *ngIf="orderByFiler.product?.indexOf('-Count(*)') == -1"
                             (click)="filterByOrderBy('product', '-Count(*)')">
                            Units
                            <mat-icon *ngIf="orderByFiler.product?.indexOf('Count(*)') != -1"> arrow_drop_up</mat-icon>
                          </p>
                          <p class="small pointer-cursor" *ngIf="orderByFiler.product?.indexOf('-Count(*)') != -1"
                             (click)="filterByOrderBy('product', 'Count(*)')">
                            Units
                            <mat-icon>arrow_drop_down</mat-icon>
                          </p>
                        </div>
                        <div class="qtr-width">
                          <p class="small pointer-cursor"
                            *ngIf="orderByFiler.product?.indexOf('-Min(treatment.Value)') == -1"
                             (click)="filterByOrderBy('product', '-Min(treatment.Value)')">
                            Min
                            <mat-icon *ngIf="orderByFiler.product?.indexOf('Min(treatment.Value)') != -1">arrow_drop_up
                            </mat-icon>
                          </p>
                          <p class="small pointer-cursor"
                            *ngIf="orderByFiler.product?.indexOf('-Min(treatment.Value)') != -1"
                             (click)="filterByOrderBy('product', 'Min(treatment.Value)')">
                            Min
                            <mat-icon>arrow_drop_down</mat-icon>
                          </p>
                        </div>
                        <div class="qtr-width">
                          <p class="small pointer-cursor"
                            *ngIf="orderByFiler.product?.indexOf('-Max(treatment.Value)') == -1"
                             (click)="filterByOrderBy('product', '-Max(treatment.Value)')">
                            Max
                            <mat-icon *ngIf="orderByFiler.product?.indexOf('Max(treatment.Value)') != -1">arrow_drop_up
                            </mat-icon>
                          </p>
                          <p class="small pointer-cursor"
                            *ngIf="orderByFiler.product?.indexOf('-Max(treatment.Value)') != -1"
                             (click)="filterByOrderBy('product', 'Max(treatment.Value)')">
                            Max
                            <mat-icon>arrow_drop_down</mat-icon>
                          </p>
                        </div>
                        <div class="qtr-width">
                          <p class="small pointer-cursor"
                            *ngIf="orderByFiler.product?.indexOf('-Avg(treatment.Value)') == -1"
                             (click)="filterByOrderBy('product', '-Avg(treatment.Value)')">
                            Avg
                            <mat-icon *ngIf="orderByFiler.product?.indexOf('Avg(treatment.Value)') != -1">arrow_drop_up
                            </mat-icon>
                          </p>
                          <p class="small pointer-cursor"
                            *ngIf="orderByFiler.product?.indexOf('-Avg(treatment.Value)') != -1"
                             (click)="filterByOrderBy('product', 'Avg(treatment.Value)')">
                            Avg
                            <mat-icon>arrow_drop_down</mat-icon>
                          </p>
                        </div>
                      </div>
                      <div class="qtr-width">
                        <p class="small pointer-cursor"
                          *ngIf="orderByFiler.product?.indexOf('-Sum(treatment.Value)') == -1"
                           (click)="filterByOrderBy('product', '-Sum(treatment.Value)')">
                          &nbsp; Sum
                          <mat-icon *ngIf="orderByFiler.product?.indexOf('Sum(treatment.Value)') != -1">arrow_drop_up
                          </mat-icon>
                        </p>
                        <p class="small pointer-cursor"
                          *ngIf="orderByFiler.product?.indexOf('-Sum(treatment.Value)') != -1"
                           (click)="filterByOrderBy('product', 'Sum(treatment.Value)')">
                          &nbsp; Sum
                          <mat-icon>arrow_drop_down</mat-icon>
                        </p>
                      </div>
                    </div>
                    <div *ngFor="
                        let p of stats.products
                          | WithoutArrayMultipleValue: 'Count(*)':emptyResultFilter
                          | filterBy: ['MarketingLabel']:productFilter
                          | OrderArrayObject: orderByFiler.product;
                        let i = index
                      " @labelAnimation>
                      <div class="row clearfix">
                        <div class="qtr-width">
                          <p>{{ p['MarketingLabel'] }}</p>
                        </div>
                        <div class="half-width">
                          <div class="qtr-width">
                            <p class="value">{{ p['Count(*)'] }}</p>
                          </div>
                          <div class="qtr-width">
                            <p class="value">{{ p['Min(treatment.Value)'] | customCurrency }}</p>
                          </div>
                          <div class="qtr-width">
                            <p class="value">{{ p['Max(treatment.Value)'] | customCurrency }}</p>
                          </div>
                          <div class="qtr-width">
                            <p class="value">{{ p['Avg(treatment.Value)'] | customCurrency }}</p>
                          </div>
                        </div>
                        <div class="qtr-width">
                          <button class="btn-small list-button pull-right mat-button"
                                  [disabled]="p['ID'] == '00000000000000000000000000000000'"
                                  (click)="viewProduct(p['ID'])"
                                  matTooltip="View Product: {{ p['MarketingLabel'] }}">
                            <mat-icon>visibility</mat-icon>
                          </button>
                          <button class="btn-small list-button pull-right mat-button" *ngIf="p['Count(*)'] > 0"
                            (click)="displayList(dateFrom, dateTo, merchantID, dentistID, p['ID'], status)"
                                  matTooltip="View InvitationList: {{ p['MarketingLabel'] }}">
                            <mat-icon>list_alt</mat-icon>
                          </button>
                          <p class="value">
                            <mat-icon class="posIcon" *ngIf="
                                arrowDirection(
                                  stats.products
                                    | WithoutArrayMultipleValue: 'Count(*)':emptyResultFilter
                                    | filterBy: ['MarketingLabel']:productFilter
                                    | OrderArrayObject: orderByFiler.product,
                                  i,
                                  'Sum(treatment.Value)'
                                ) == true
                              ">arrow_drop_up
                            </mat-icon>
                            <mat-icon class="negIcon" *ngIf="
                                arrowDirection(
                                  stats.products
                                    | WithoutArrayMultipleValue: 'Count(*)':emptyResultFilter
                                    | filterBy: ['MarketingLabel']:productFilter
                                    | OrderArrayObject: orderByFiler.product,
                                  i,
                                  'Sum(treatment.Value)'
                                ) == false
                              ">arrow_drop_down
                            </mat-icon>
                            {{ p['Sum(treatment.Value)'] | customCurrency }}
                          </p>
                        </div>
                      </div>
                      <mat-divider></mat-divider>
                    </div>
                  </div>
                </div>
              </div>
            </mat-card>
          </div>
        </div>
        <div *ngIf="
            stats &&
            productListFiltered &&
            indexLabel == 'product' &&
            productListFiltered.length > 0 &&
            isAdminOrPromoter == true &&
            (merchantID == 'none' || !merchantID)
          ">
          <div class="row clearfix full-width">
            <mat-card class="card dataCard clearfix">
              <div class="colour-stripe"></div>
              <div class="row clearfix">
                <h2 class="sr-title lg-h2 pull-left dataHead">
                  Products Statistics
                  <!-- <span class="badge">
                    {{stats.status.length}}</span> -->
                </h2>
                <mat-slide-toggle class="pull-right" [checked]="isEmptyResult" (change)="emptyResultChange($event)">Hide
                  Empty Result
                </mat-slide-toggle>
                <hr/>
              </div>
              <div class="quickLook row clearfix text-center mt add-mb">
                <div class="qtr-width">
                  <div class="column">
                    <mat-card class="mat-elevation-z7">
                      <h3 class="sr-title sm-h3 rm-bp">Out of</h3>
                      <span class="bigNum" [endVal]="
                          stats.status
                            | FilterArrayMultipleValue: 'ID':['active', 'proceedingCash']
                            | objectPropertyValue: 'Count(*)'
                            | toNumberArray
                            | sum
                        " countUp></span>
                      <h3 class="sr-title sm-h3 rm-m">Financed {{ "KEYWORD.patient" | translate }}s</h3>
                      <p class="small">
                        ({{
                        (stats.status
                          | FilterArrayMultipleValue: 'ID':['active', 'proceedingCash']
                          | objectPropertyValue: 'Count(*)'
                          | toNumberArray
                          | sum) /
                        ((stats.status
                            | FilterArrayMultipleValue: 'ID':['approved']
                            | objectPropertyValue: 'Count(*)'
                            | toNumberArray
                            | sum) +
                          (stats.status
                            | FilterArrayMultipleValue: 'ID':['completed']
                            | objectPropertyValue: 'Count(*)'
                            | toNumberArray
                            | sum) +
                          (stats.status
                            | FilterArrayMultipleValue: 'ID':['referred']
                            | objectPropertyValue: 'Count(*)'
                            | toNumberArray
                            | sum) +
                          (stats.status
                            | FilterArrayMultipleValue: 'ID':['declined']
                            | objectPropertyValue: 'Count(*)'
                            | toNumberArray
                            | sum) +
                          (stats.status
                            | FilterArrayMultipleValue: 'ID':['proceedingCash']
                            | objectPropertyValue: 'Count(*)'
                            | toNumberArray
                            | sum) +
                          (stats.status
                            | FilterArrayMultipleValue: 'ID':['active']
                            | objectPropertyValue: 'Count(*)'
                            | toNumberArray
                            | sum)) | percent: '2.1-1'
                        }} of accepted {{ "KEYWORD.treatment" | translate }}s)
                      </p>
                    </mat-card>
                  </div>
                </div>
                <div class="qtr-width">
                  <div class="column">
                    <mat-card class="mat-elevation-z7">
                      <h3 class="sr-title sm-h3 rm-bp">You converted</h3>
                      <span class="bigNum" [endVal]="
                          stats.products
                            | FilterArrayMultipleValue: 'isPaymentPlan':[true]
                            | objectPropertyValue: 'Count(*)'
                            | toNumberArray
                            | sum
                        " countUp></span>
                      <h3 class="sr-title sm-h3 rm-m">Payment plans</h3>
                      <p class="small">
                        (
                        {{
                        (stats.products
                          | FilterArrayMultipleValue: 'isPaymentPlan':[true]
                          | objectPropertyValue: 'Count(*)'
                          | toNumberArray
                          | sum) / (stats.products | objectPropertyValue: 'Count(*)' | toNumberArray | sum)
                          | percent: '2.1-1'
                        }}
                        of financed {{ "KEYWORD.treatment" | translate }}s)
                      </p>
                    </mat-card>
                  </div>
                </div>
                <div class="qtr-width">
                  <div class="column">
                    <mat-card class="mat-elevation-z7">
                      <h3 class="sr-title sm-h3 rm-bp">As well as</h3>
                      <span class="bigNum" [endVal]="
                          stats.products
                            | FilterArrayMultipleValue: 'isPaymentPlan':[false]
                            | objectPropertyValue: 'Count(*)'
                            | toNumberArray
                            | sum
                        " countUp></span>
                      <h3 class="sr-title sm-h3 rm-m">Loan products</h3>
                      <p class="small">
                        (
                        {{
                        (stats.products
                          | FilterArrayMultipleValue: 'isPaymentPlan':[false]
                          | objectPropertyValue: 'Count(*)'
                          | toNumberArray
                          | sum) / (stats.products | objectPropertyValue: 'Count(*)' | toNumberArray | sum)
                          | percent: '2.1-1'
                        }}
                        of financed {{ "KEYWORD.treatment" | translate }}s)
                      </p>
                    </mat-card>
                  </div>
                </div>
                <div class="qtr-width">
                  <div class="column">
                    <mat-card class="mat-elevation-z7">
                      <h3 class="sr-title sm-h3 rm-bp">And there are</h3>
                      <span class="bigNum" [endVal]="
                          stats.status
                            | FilterArrayMultipleValue: 'ID':['viewed']
                            | objectPropertyValue: 'Count(*)'
                            | toNumberArray
                            | sum
                        " countUp></span>
                      <h3 class="sr-title sm-h3 rm-m">still waiting to make a decision</h3>
                      <p class="small">
                        ({{
                        (stats.status
                          | FilterArrayMultipleValue: 'ID':['viewed']
                          | objectPropertyValue: 'Count(*)'
                          | sum) / stats.global['Count(*)'] | percent: '2.1-1'
                        }}
                        of sent invitations)
                      </p>
                    </mat-card>
                  </div>
                </div>
              </div>
              <div class="row clearfix flex full-width mb">
                <div class="full-width rel" *ngIf="
                    stats && productListFiltered && productListFiltered.length > 1 && productListFiltered.length < 30
                  ">
                  <div class="col-left">
                    <div class="rel mb row clearfix" *ngIf="displayProdChartView == 'unit'">
                      <div class="total anim">
                        <span [endVal]="
                            productListFiltered
                              | WithoutArrayMultipleValue: 'Count(*)':emptyResultFilter
                              | objectPropertyValue: 'Count(*)'
                              | sum
                          " countUp></span>
                        <span class="totSub">Total</span>
                      </div>
                      <app-chart-input class="row full-width pie" *ngIf="stats && productListFiltered"
                                       [xAxisLabel]="'Products'" [yAxisLabel]="'Invitation(s)'" [data]="
                          productListFiltered
                            | WithoutArrayMultipleValue: 'Count(*)':emptyResultFilter
                            | OrderArrayObject: orderByFiler.product
                        " [dataNameAttribute]="'MarketingLabel,SubType'" [dataValueAttribute]="'Count(*)'"
                                       [separator]="'-'" [chartType]="'pie'" [title]="'Product vs Invitations'"
                                       [showLegend]="false"
                                       [view]="undefined"></app-chart-input>
                    </div>

                    <div class="rel mb row clearfix" *ngIf="displayProdChartView == 'amount'">
                      <div class="total anim">
                        <span [endVal]="
                            productListFiltered
                              | WithoutArrayMultipleValue: 'Count(*)':emptyResultFilter
                              | objectPropertyValue: 'Sum(treatment.Value)'
                              | sum
                          " countUp></span>
                        <span class="totSub">Total</span>
                      </div>

                      <app-chart-input class="row full-width pie" *ngIf="stats && productListFiltered"
                                       [yAxisLabel]="'Products'" [xAxisLabel]="'Treatment Amount ($)'" [data]="
                          productListFiltered
                            | WithoutArrayMultipleValue: 'Count(*)':emptyResultFilter
                            | OrderArrayObject: orderByFiler.product
                        " [dataNameAttribute]="'MarketingLabel,SubType'" [dataValueAttribute]="'Sum(treatment.Value)'"
                                       [separator]="'-'" [chartType]="'pie'"
                                       [title]="'Products vs ' + ('KEYWORD.treatment' | translate | titlecase) + ' Amount ($)'"
                                       [showLegend]="false" [view]="undefined"></app-chart-input>
                    </div>
                    <hr/>
                    <div class="row clearfix">
                      <mat-button-toggle-group class="btn-group clearfix full-width text-center"
                                               [(ngModel)]="displayProdChartView">
                        <mat-button-toggle class="text-center half-width" [value]="'unit'">
                          Invitations
                        </mat-button-toggle>
                        <mat-button-toggle class="text-center half-width" [value]="'amount'">
                          Amount($)
                        </mat-button-toggle>
                      </mat-button-toggle-group>
                    </div>
                  </div>
                </div>
                <div class="full-width">
                  <div class="col-right">
                    <div class="row clearfix half-width">
                      <mat-form-field class="full-width" appearance="outline">
                        <mat-label>Product filter</mat-label>
                        <input class="small" [(ngModel)]="productFilter" name="productFilter" matInput
                               placeholder="Product Filter..."/>
                      </mat-form-field>
                    </div>
                    <div class="row clearfix full-width">
                      <div class="qtr-width">
                        <p class="small pointer-cursor" *ngIf="orderByFiler.product?.indexOf('-MarketingLabel') == -1"
                           (click)="filterByOrderBy('product', '-MarketingLabel')">
                          Product
                          <mat-icon *ngIf="orderByFiler.product?.indexOf('MarketingLabel') != -1">arrow_drop_up
                          </mat-icon>
                        </p>
                        <p class="small pointer-cursor" *ngIf="orderByFiler.product?.indexOf('-MarketingLabel') != -1"
                           (click)="filterByOrderBy('product', 'MarketingLabel')">
                          Product
                          <mat-icon>arrow_drop_down</mat-icon>
                        </p>
                      </div>
                      <div class="half-width">
                        <div class="qtr-width">
                          <p class="small pointer-cursor" *ngIf="orderByFiler.product?.indexOf('-Count(*)') == -1"
                             (click)="filterByOrderBy('product', '-Count(*)')">
                            Units
                            <mat-icon *ngIf="orderByFiler.product?.indexOf('Count(*)') != -1"> arrow_drop_up</mat-icon>
                          </p>
                          <p class="small pointer-cursor" *ngIf="orderByFiler.product?.indexOf('-Count(*)') != -1"
                             (click)="filterByOrderBy('product', 'Count(*)')">
                            Units
                            <mat-icon>arrow_drop_down</mat-icon>
                          </p>
                        </div>
                        <div class="qtr-width">
                          <p class="small pointer-cursor"
                            *ngIf="orderByFiler.product?.indexOf('-Min(treatment.Value)') == -1"
                             (click)="filterByOrderBy('product', '-Min(treatment.Value)')">
                            Min
                            <mat-icon *ngIf="orderByFiler.product?.indexOf('Min(treatment.Value)') != -1">arrow_drop_up
                            </mat-icon>
                          </p>
                          <p class="small pointer-cursor"
                            *ngIf="orderByFiler.product?.indexOf('-Min(treatment.Value)') != -1"
                             (click)="filterByOrderBy('product', 'Min(treatment.Value)')">
                            Min
                            <mat-icon>arrow_drop_down</mat-icon>
                          </p>
                        </div>
                        <div class="qtr-width">
                          <p class="small pointer-cursor"
                            *ngIf="orderByFiler.product?.indexOf('-Max(treatment.Value)') == -1"
                             (click)="filterByOrderBy('product', '-Max(treatment.Value)')">
                            Max
                            <mat-icon *ngIf="orderByFiler.product?.indexOf('Max(treatment.Value)') != -1">arrow_drop_up
                            </mat-icon>
                          </p>
                          <p class="small pointer-cursor"
                            *ngIf="orderByFiler.product?.indexOf('-Max(treatment.Value)') != -1"
                             (click)="filterByOrderBy('product', 'Max(treatment.Value)')">
                            Max
                            <mat-icon>arrow_drop_down</mat-icon>
                          </p>
                        </div>
                        <div class="qtr-width">
                          <p class="small pointer-cursor"
                            *ngIf="orderByFiler.product?.indexOf('-Avg(treatment.Value)') == -1"
                             (click)="filterByOrderBy('product', '-Avg(treatment.Value)')">
                            Avg
                            <mat-icon *ngIf="orderByFiler.product?.indexOf('Avg(treatment.Value)') != -1">arrow_drop_up
                            </mat-icon>
                          </p>
                          <p class="small pointer-cursor"
                            *ngIf="orderByFiler.product?.indexOf('-Avg(treatment.Value)') != -1"
                             (click)="filterByOrderBy('product', 'Avg(treatment.Value)')">
                            Avg
                            <mat-icon>arrow_drop_down</mat-icon>
                          </p>
                        </div>
                      </div>
                      <div class="qtr-width">
                        <p class="small pointer-cursor"
                          *ngIf="orderByFiler.product?.indexOf('-Sum(treatment.Value)') == -1"
                           (click)="filterByOrderBy('product', '-Sum(treatment.Value)')">
                          &nbsp; Sum
                          <mat-icon *ngIf="orderByFiler.product?.indexOf('Sum(treatment.Value)') != -1">arrow_drop_up
                          </mat-icon>
                        </p>
                        <p class="small pointer-cursor"
                          *ngIf="orderByFiler.product?.indexOf('-Sum(treatment.Value)') != -1"
                           (click)="filterByOrderBy('product', 'Sum(treatment.Value)')">
                          &nbsp; Sum
                          <mat-icon>arrow_drop_down</mat-icon>
                        </p>
                      </div>
                    </div>
                    <div *ngFor="
                        let p of productListFiltered
                          | WithoutArrayMultipleValue: 'Count(*)':emptyResultFilter
                          | filterBy: ['MarketingLabel']:productFilter
                          | OrderArrayObject: orderByFiler.product;
                        let i = index
                      " @labelAnimation>
                      <div class="row clearfix">
                        <div class="qtr-width">
                          <p>{{ p['MarketingLabel'] }}</p>
                        </div>
                        <div class="half-width">
                          <div class="qtr-width">
                            <p class="value">{{ p['Count(*)'] }}</p>
                          </div>
                          <div class="qtr-width">
                            <p class="value">{{ p['Min(treatment.Value)'] | customCurrency }}</p>
                          </div>
                          <div class="qtr-width">
                            <p class="value">{{ p['Max(treatment.Value)'] | customCurrency }}</p>
                          </div>
                          <div class="qtr-width">
                            <p class="value">{{ p['Avg(treatment.Value)'] | customCurrency }}</p>
                          </div>
                        </div>
                        <div class="qtr-width">
                          <p class="value">
                            <mat-icon class="posIcon" *ngIf="
                                arrowDirection(
                                  productListFiltered
                                    | WithoutArrayMultipleValue: 'Count(*)':emptyResultFilter
                                    | filterBy: ['MarketingLabel']:productFilter
                                    | OrderArrayObject: orderByFiler.product,
                                  i,
                                  'Sum(treatment.Value)'
                                ) == true
                              ">arrow_drop_up
                            </mat-icon>
                            <mat-icon class="negIcon" *ngIf="
                                arrowDirection(
                                  productListFiltered
                                    | WithoutArrayMultipleValue: 'Count(*)':emptyResultFilter
                                    | filterBy: ['MarketingLabel']:productFilter
                                    | OrderArrayObject: orderByFiler.product,
                                  i,
                                  'Sum(treatment.Value)'
                                ) == false
                              ">arrow_drop_down
                            </mat-icon>
                            {{ p['Sum(treatment.Value)'] | customCurrency }}
                          </p>
                        </div>
                      </div>
                      <mat-divider></mat-divider>
                    </div>
                  </div>
                </div>
              </div>
            </mat-card>
          </div>
        </div>
      </mat-tab>
      <mat-tab id="tool5" *ngIf="isAdminOrPromoter == true" label="Merchants">
        <div *ngIf="stats && stats.merchants && indexLabel == 'merchant' && stats.merchants.length > 0" @ngIfAnimation>
          <div class="row clearfix full-width">
            <div class="thr-qtr-width">
              <div class="col-left">
                <mat-card class="card dataCard clearfix">
                  <div class="colour-stripe"></div>
                  <div class="row clearfix">
                    <h2 class="sr-title lg-h2 pull-left dataHead">
                      Merchants Statistics
                      <!-- <span class="badge">
                    {{stats.status.length}}</span> -->
                    </h2>
                    <mat-slide-toggle class="pull-right" [checked]="isEmptyResult" (change)="emptyResultChange($event)">
                      Hide Empty Result
                    </mat-slide-toggle>
                    <hr/>
                  </div>
                  <div class="row clearfix full-width mb">
                    <div class="half-width" *ngIf="
                        stats &&
                        stats.merchants &&
                        (stats.merchants | WithoutArrayMultipleValue: 'Count(*)':emptyResultFilter | lengthOfArray) >
                          1 &&
                        (stats.merchants | WithoutArrayMultipleValue: 'Count(*)':emptyResultFilter | lengthOfArray) < 30
                      ">
                      <div class="col-left rel">
                        <div class="row clearfix mb rel" *ngIf="
                            displayMerchChartView == 'unit' &&
                            (stats.merchants
                              | WithoutArrayMultipleValue: 'Count(*)':emptyResultFilter
                              | lengthOfArray) > 1 &&
                            (stats.merchants
                              | WithoutArrayMultipleValue: 'Count(*)':emptyResultFilter
                              | lengthOfArray) < 6
                          ">
                          <div class="total anim">
                            <span [endVal]="
                                stats.merchants
                                  | WithoutArrayMultipleValue: 'Count(*)':emptyResultFilter
                                  | objectPropertyValue: 'Count(*)'
                                  | sum
                              " countUp></span>
                            <span class="totSub">Total</span>
                          </div>
                          <app-chart-input class="row full-width chart-min-height pie" [xAxisLabel]="'Merchants'"
                                           [yAxisLabel]="'Invitation(s)'" [chartHeight]="350" [data]="
                              stats.merchants
                                | WithoutArrayMultipleValue: 'Count(*)':emptyResultFilter
                                | OrderArrayObject: orderByFiler.merchant
                            " [dataNameAttribute]="'TradingAs,CalculatedName'" [dataValueAttribute]="'Count(*)'"
                                           [separator]="'-'" [chartType]="'pie'" [title]="'Merchants vs Invitations'"
                                           [titleIsTop]="false" [showLegend]="false"
                                           [view]="undefined"></app-chart-input>
                        </div>

                        <div class="row clearfix mb rel" *ngIf="
                            displayMerchChartView == 'unit' &&
                            (stats.merchants
                              | WithoutArrayMultipleValue: 'Count(*)':emptyResultFilter
                              | lengthOfArray) > 1 &&
                            (stats.merchants
                              | WithoutArrayMultipleValue: 'Count(*)':emptyResultFilter
                              | lengthOfArray) >= 6
                          ">
                          <app-chart-input class="row full-width chart-min-heigh piet" [xAxisLabel]="'Merchants'"
                                           [yAxisLabel]="'Invitation(s)'" [chartHeight]="350" [data]="
                              stats.merchants
                                | WithoutArrayMultipleValue: 'Count(*)':emptyResultFilter
                                | OrderArrayObject: orderByFiler.merchant
                            " [dataNameAttribute]="'TradingAs,CalculatedName'" [dataValueAttribute]="'Count(*)'"
                                           [separator]="'-'" [chartType]="'barHorizontal'"
                                           [title]="'Merchants vs Invitations'"
                                           [titleIsTop]="false" [showLegend]="false"
                                           [view]="undefined"></app-chart-input>
                        </div>

                        <div class="row mb clearfix rel" *ngIf="
                            displayMerchChartView == 'amount' &&
                            (stats.merchants
                              | WithoutArrayMultipleValue: 'Count(*)':emptyResultFilter
                              | lengthOfArray) > 1 &&
                            (stats.merchants
                              | WithoutArrayMultipleValue: 'Count(*)':emptyResultFilter
                              | lengthOfArray) < 6
                          ">
                          <div class="total anim">
                            <span [endVal]="
                                stats.merchants
                                  | WithoutArrayMultipleValue: 'Count(*)':emptyResultFilter
                                  | objectPropertyValue: 'Sum(treatment.Value)'
                                  | sum
                              " countUp></span>
                            <span class="totSub">Total</span>
                          </div>
                          <app-chart-input class="row full-width chart-min-height pie" [xAxisLabel]="'Merchants'"
                                           [yAxisLabel]="('KEYWORD.treatment' | translate | titlecase) + ' Amount ($)'"
                                           [chartHeight]="350" [data]="
                              stats.merchants
                                | WithoutArrayMultipleValue: 'Count(*)':emptyResultFilter
                                | OrderArrayObject: orderByFiler.merchant
                            " [dataNameAttribute]="'TradingAs,CalculatedName'"
                                           [dataValueAttribute]="'Sum(treatment.Value)'" [separator]="'-'"
                                           [chartType]="'pie'"
                                           [title]="'Merchants vs ' + ('KEYWORD.treatment' | translate | titlecase) + ' Amount ($)'"
                                           [showLegend]="false" [titleIsTop]="false"
                                           [view]="undefined"></app-chart-input>
                        </div>
                        <div class="row mb clearfix rel" *ngIf="
                            displayMerchChartView == 'amount' &&
                            (stats.merchants
                              | WithoutArrayMultipleValue: 'Count(*)':emptyResultFilter
                              | lengthOfArray) > 1 &&
                            (stats.merchants
                              | WithoutArrayMultipleValue: 'Count(*)':emptyResultFilter
                              | lengthOfArray) >= 6
                          ">
                          <app-chart-input class="row full-width chart-min-height pie" [xAxisLabel]="'Merchants'"
                                           [yAxisLabel]="('KEYWORD.treatment' | translate | titlecase) + ' Amount ($)'"
                                           [chartHeight]="350" [data]="
                              stats.merchants
                                | WithoutArrayMultipleValue: 'Count(*)':emptyResultFilter
                                | OrderArrayObject: orderByFiler.merchant
                            " [dataNameAttribute]="'TradingAs,CalculatedName'"
                                           [dataValueAttribute]="'Sum(treatment.Value)'" [separator]="'-'"
                            [chartType]="'barHorizontal'"
                                           [title]="'Merchants vs ' + ('KEYWORD.treatment' | translate | titlecase) + ' Amount ($)'"
                                           [showLegend]="false" [titleIsTop]="false"
                                           [view]="undefined"></app-chart-input>
                        </div>

                        <hr/>
                        <div class="row clearfix">
                          <mat-button-toggle-group class="btn-group clearfix full-width text-center"
                                                   [(ngModel)]="displayMerchChartView">
                            <mat-button-toggle class="text-center half-width" [value]="'unit'">
                              Invitations
                            </mat-button-toggle>
                            <mat-button-toggle class="text-center half-width" [value]="'amount'">
                              Amount($)
                            </mat-button-toggle>
                          </mat-button-toggle-group>
                        </div>
                      </div>
                    </div>
                    <div class="half-width">
                      <div class="col-right">
                        <div class="row clearfix half-width">
                          <mat-form-field class="full-width" appearance="outline">
                            <mat-label>Merchant Name Filter...</mat-label>
                            <input class="small" [(ngModel)]="merchantFilter" name="merchantName" matInput
                                   placeholder="Merchant Name Filter..."/>
                          </mat-form-field>
                        </div>
                        <div class="row clearfix full-width">
                          <div class="thrd-width">
                            <p class="small pointer-cursor"
                              *ngIf="orderByFiler.merchant?.indexOf('-MarketingLabel') == -1"
                               (click)="filterByOrderBy('merchant', '-MarketingLabel')">
                              Name
                              <mat-icon *ngIf="orderByFiler.merchant?.indexOf('MarketingLabel') != -1">arrow_drop_up
                              </mat-icon>
                            </p>
                            <p class="small pointer-cursor"
                              *ngIf="orderByFiler.merchant?.indexOf('-MarketingLabel') != -1"
                               (click)="filterByOrderBy('merchant', 'MarketingLabel')">
                              Name
                              <mat-icon>arrow_drop_down</mat-icon>
                            </p>
                          </div>
                          <div class="thrd-width">
                            <div class="half-width">
                              <p class="small pointer-cursor" *ngIf="orderByFiler.merchant?.indexOf('-Count(*)') == -1"
                                 (click)="filterByOrderBy('merchant', '-Count(*)')">
                                Units
                                <mat-icon *ngIf="orderByFiler.merchant?.indexOf('Count(*)') != -1">
                                  arrow_drop_up
                                </mat-icon>
                              </p>
                              <p class="small pointer-cursor" *ngIf="orderByFiler.merchant?.indexOf('-Count(*)') != -1"
                                 (click)="filterByOrderBy('merchant', 'Count(*)')">
                                Units
                                <mat-icon>arrow_drop_down</mat-icon>
                              </p>
                            </div>
                            <div class="half-width">
                              <p class="small pointer-cursor"
                                *ngIf="orderByFiler.merchant?.indexOf('-Avg(treatment.Value)') == -1"
                                 (click)="filterByOrderBy('merchant', '-Avg(treatment.Value)')">
                                Avg
                                <mat-icon
                                  *ngIf="orderByFiler.merchant?.indexOf('Avg(treatment.Value)') != -1">arrow_drop_up
                                </mat-icon>
                              </p>
                              <p class="small pointer-cursor"
                                *ngIf="orderByFiler.merchant?.indexOf('-Avg(treatment.Value)') != -1"
                                 (click)="filterByOrderBy('merchant', 'Avg(treatment.Value)')">
                                Avg
                                <mat-icon>arrow_drop_down</mat-icon>
                              </p>
                            </div>
                          </div>
                          <div class="thrd-width">
                            <p class="small pointer-cursor"
                              *ngIf="orderByFiler.merchant?.indexOf('-Sum(treatment.Value)') == -1"
                               (click)="filterByOrderBy('merchant', '-Sum(treatment.Value)')">
                              &nbsp; Sum
                              <mat-icon
                                *ngIf="orderByFiler.merchant?.indexOf('Sum(treatment.Value)') != -1">arrow_drop_up
                              </mat-icon>
                            </p>
                            <p class="small pointer-cursor"
                              *ngIf="orderByFiler.merchant?.indexOf('-Sum(treatment.Value)') != -1"
                               (click)="filterByOrderBy('merchant', 'Sum(treatment.Value)')">
                              &nbsp; Sum
                              <mat-icon>arrow_drop_down</mat-icon>
                            </p>
                          </div>
                        </div>
                        <div *ngFor="
                            let p of stats.merchants
                              | WithoutArrayMultipleValue: 'Count(*)':emptyResultFilter
                              | filterBy: ['TradingAs', 'CalculatedName']:merchantFilter
                              | OrderArrayObject: orderByFiler.merchant;
                            let i = index
                          " @labelAnimation>
                          <div class="row clearfix">
                            <div class="thrd-width">
                              <p>{{ p['TradingAs'] || p['CalculatedName'] }}</p>
                            </div>
                            <div class="thrd-width">
                              <div class="half-width">
                                <p class="value">{{ p['Count(*)'] }}</p>
                              </div>
                              <div class="half-width">
                                <p class="value">{{ p['Avg(treatment.Value)'] | customCurrency }}</p>
                              </div>
                            </div>
                            <div class="thrd-width">
                              <button class="btn-close list-button pull-right mat-button"
                                      (click)="viewMerchant(p['ID'])"
                                      matTooltip="View Profile: {{ p['CalculatedName'] }}">
                                <mat-icon>visibility</mat-icon>
                              </button>
                              <button class="btn-small list-button pull-right mat-button" *ngIf="p['Count(*)'] > 0"
                                (click)="displayList(dateFrom, dateTo, p['ID'], dentistID, productID, status)"
                                      matTooltip="View InvitationList: {{ p['CalculatedName'] }}">
                                <mat-icon>list_alt</mat-icon>
                              </button>
                              <p class="value">
                                <mat-icon class="posIcon" *ngIf="
                                    arrowDirection(
                                      stats.merchants
                                        | WithoutArrayMultipleValue: 'Count(*)':emptyResultFilter
                                        | filterBy: ['TradingAs', 'CalculatedName']:merchantFilter
                                        | OrderArrayObject: orderByFiler.merchant,
                                      i,
                                      'Sum(treatment.Value)'
                                    ) == true
                                  ">arrow_drop_up
                                </mat-icon>
                                <mat-icon class="negIcon" *ngIf="
                                    arrowDirection(
                                      stats.merchants
                                        | WithoutArrayMultipleValue: 'Count(*)':emptyResultFilter
                                        | filterBy: ['TradingAs', 'CalculatedName']:merchantFilter
                                        | OrderArrayObject: orderByFiler.merchant,
                                      i,
                                      'Sum(treatment.Value)'
                                    ) == false
                                  ">arrow_drop_down
                                </mat-icon>
                                {{ p['Sum(treatment.Value)'] | customCurrency }}
                              </p>
                            </div>
                          </div>
                          <mat-divider></mat-divider>
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-card>
              </div>
            </div>
            <div class="qtr-width leaderboard">
              <mat-card class="card dataCard clearfix" *ngFor="
                  let p of stats.merchants
                    | WithoutArrayMultipleValue: 'Count(*)':emptyResultFilter
                    | OrderArrayObject: ['Count(*)', 'Sum(treatment.Value)']
                    | arrayLast
                ">
                <div class="colour-stripe"></div>
                <div class="row clearfix">
                  <h2 class="sr-title lg-h2 pull-left dataHead">Top performing</h2>
                  <div class="pull-right text-center">
                    <mat-icon class="fas fa-trophy"></mat-icon>
                  </div>
                  <hr/>
                </div>
                <div class="row clearfix text-center">
                  <p class="larger mb">The current <strong>best performing</strong> Merchant is</p>
                  <div class="full-width text-center">
                    <app-merchant-view-picture class="headNavPicture userPicture" [merchantID]="p['ID']">
                    </app-merchant-view-picture>
                  </div>
                  <p class="lead">
                    <strong>{{ p['CalculatedName'] }}</strong>

                    <button class="btn-close list-button mat-button" (click)="viewMerchant(p['ID'])"
                            matTooltip="View Profile: {{ p['CalculatedName'] }}">
                      <mat-icon>visibility</mat-icon>
                    </button>
                  </p>
                  <p>
                    With a total of <strong>{{ p['Count(*)'] }}</strong> invitations sent, totalling a possible
                    <strong>{{ p['Sum(treatment.Value)'] | customCurrency }}</strong>
                    worth of {{"KEYWORD.treatment" | translate}}.
                  </p>
                </div>
              </mat-card>
              <mat-card class="card dataCard clearfix" *ngFor="
                  let p of stats.merchants
                    | WithoutArrayMultipleValue: 'Count(*)':emptyResultFilter
                    | OrderArrayObject: ['Count(*)', 'Sum(treatment.Value)']
                    | arrayFirst
                ">
                <div class="colour-stripe"></div>
                <div class="row clearfix">
                  <h2 class="sr-title lg-h2 pull-left dataHead">Lowest performing</h2>
                  <div class="pull-right text-center">
                    <mat-icon class="fas fa-arrow-circle-down"></mat-icon>
                  </div>
                  <hr/>
                </div>
                <div class="row clearfix text-center">
                  <p class="larger mb">The <strong>least performing</strong> Merchant is</p>
                  <div class="full-width text-center">
                    <app-merchant-view-picture class="headNavPicture userPicture" [merchantID]="p['ID']">
                    </app-merchant-view-picture>
                  </div>
                  <p class="lead">
                    <strong>{{ p['CalculatedName'] }}</strong>
                    <button class="btn-close list-button mat-button" (click)="viewMerchant(p['ID'])"
                            matTooltip="View Profile: {{ p['CalculatedName'] }}">
                      <mat-icon>visibility</mat-icon>
                    </button>
                  </p>
                  <p>
                    With a total of <strong>{{ p['Count(*)'] }}</strong> invitations sent, totalling a possible
                    <strong>{{ p['Sum(treatment.Value)'] | customCurrency }}</strong>
                    worth of {{"KEYWORD.treatment" | translate}}.
                  </p>
                </div>
              </mat-card>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab id="tool7" label="Staff Members">
        <div *ngIf="stats && stats.dentists && stats.dentists.length > 0 && indexLabel == 'staff'" @ngIfAnimation>
          <div class="row clearfix full-width">
            <div class="thr-qtr-width">
              <div class="col-left">
                <mat-card class="card dataCard clearfix">
                  <div class="colour-stripe"></div>
                  <div class="row clearfix">
                    <h2 class="sr-title lg-h2 pull-left dataHead">Staff Members Statistics</h2>
                    <mat-slide-toggle class="pull-right" [checked]="isEmptyResult" (change)="emptyResultChange($event)">
                      Hide Empty Result
                    </mat-slide-toggle>
                    <hr/>
                  </div>
                  <div class="row clearfix full-width mb">
                    <div class="half-width" *ngIf="
                        stats &&
                        stats.dentists &&
                        (stats.dentists | WithoutArrayMultipleValue: 'Count(*)':emptyResultFilter | lengthOfArray) >
                          1 &&
                        (stats.dentists | WithoutArrayMultipleValue: 'Count(*)':emptyResultFilter | lengthOfArray) < 30
                      ">
                      <div class="col-left rel">
                        <div class="row clearfix mb rel" *ngIf="
                            displayStaffChartView == 'unit' &&
                            (stats.dentists | WithoutArrayMultipleValue: 'Count(*)':emptyResultFilter | lengthOfArray) >
                              1 &&
                            (stats.dentists | WithoutArrayMultipleValue: 'Count(*)':emptyResultFilter | lengthOfArray) <
                              6
                          ">
                          <div class="total anim">
                            <span [endVal]="
                                stats.dentists
                                  | WithoutArrayMultipleValue: 'Count(*)':emptyResultFilter
                                  | objectPropertyValue: 'Count(*)'
                                  | sum
                              " countUp></span>
                            <span class="totSub">Total</span>
                          </div>
                          <app-chart-input class="row full-width chart-min-height pie" [xAxisLabel]="'Invitation(s)'"
                                           [yAxisLabel]="'Staff Memebers'" [data]="
                              stats.dentists
                                | WithoutArrayMultipleValue: 'Count(*)':emptyResultFilter
                                | OrderArrayObject: orderByFiler.staff
                            " [dataNameAttribute]="'CalculatedName'" [dataValueAttribute]="'Count(*)'"
                                           [title]="'Staff vs Invitations'" [chartType]="'pie'" [showLegend]="false"
                                           [view]="undefined"
                                           [titleIsTop]="false"></app-chart-input>
                        </div>

                        <div class="row clearfix mb rel" *ngIf="
                            displayStaffChartView == 'unit' &&
                            (stats.dentists | WithoutArrayMultipleValue: 'Count(*)':emptyResultFilter | lengthOfArray) >
                              1 &&
                            (stats.dentists
                              | WithoutArrayMultipleValue: 'Count(*)':emptyResultFilter
                              | lengthOfArray) >= 6
                          ">
                          <app-chart-input class="row full-width chart-min-height pie" [xAxisLabel]="'Invitation(s)'"
                                           [yAxisLabel]="'Staff Memebers'" [data]="
                              stats.dentists
                                | WithoutArrayMultipleValue: 'Count(*)':emptyResultFilter
                                | OrderArrayObject: orderByFiler.staff
                            " [dataNameAttribute]="'CalculatedName'" [dataValueAttribute]="'Count(*)'"
                                           [title]="'Staff vs Invitations'" [chartType]="'barHorizontal'"
                                           [showLegend]="false"
                                           [view]="undefined" [titleIsTop]="true"></app-chart-input>
                        </div>

                        <div class="row clearfix mb rel" *ngIf="
                            displayStaffChartView == 'amount' &&
                            (stats.dentists | WithoutArrayMultipleValue: 'Count(*)':emptyResultFilter | lengthOfArray) >
                              1 &&
                            (stats.dentists | WithoutArrayMultipleValue: 'Count(*)':emptyResultFilter | lengthOfArray) <
                              6
                          ">
                          <div class="total anim">
                            <span [endVal]="
                                stats.dentists
                                  | WithoutArrayMultipleValue: 'Count(*)':emptyResultFilter
                                  | objectPropertyValue: 'Sum(treatment.Value)'
                                  | sum
                              " countUp></span>
                            <span class="totSub">Total</span>
                          </div>
                          <app-chart-input class="row full-width chart-min-heigh piet"
                                           [xAxisLabel]="'Treatment Amount ($)'" [yAxisLabel]="'Staff Memebers'"
                                           [data]="
                              stats.dentists
                                | WithoutArrayMultipleValue: 'Count(*)':emptyResultFilter
                                | OrderArrayObject: orderByFiler.staff
                            " [dataNameAttribute]="'CalculatedName'" [chartType]="'pie'"
                            [dataValueAttribute]="'Sum(treatment.Value)'"
                                           [title]="'Staff vs ' + ('KEYWORD.treatment' | translate | titlecase) + ' Amount ($)'"
                                           [showLegend]="false" [titleIsTop]="false"
                                           [view]="undefined"></app-chart-input>
                        </div>

                        <div class="row clearfix mb rel" *ngIf="
                            displayStaffChartView == 'amount' &&
                            (stats.dentists | WithoutArrayMultipleValue: 'Count(*)':emptyResultFilter | lengthOfArray) >
                              1 &&
                            (stats.dentists
                              | WithoutArrayMultipleValue: 'Count(*)':emptyResultFilter
                              | lengthOfArray) >= 6
                          ">
                          <app-chart-input class="row full-width chart-min-height pie"
                                           [xAxisLabel]="'Treatment Amount ($)'" [yAxisLabel]="'Staff Memebers'"
                                           [data]="
                              stats.dentists
                                | WithoutArrayMultipleValue: 'Count(*)':emptyResultFilter
                                | OrderArrayObject: orderByFiler.staff
                            " [dataNameAttribute]="'CalculatedName'" [chartType]="'barHorizontal'"
                            [dataValueAttribute]="'Sum(treatment.Value)'"
                                           [title]="'Staff vs ' + ('KEYWORD.treatment' | translate | titlecase) + ' Amount ($)'"
                                           [showLegend]="false" [titleIsTop]="true"
                                           [view]="undefined"></app-chart-input>
                        </div>
                        <hr/>
                        <mat-button-toggle-group class="btn-group clearfix full-width text-center"
                                                 [(ngModel)]="displayStaffChartView">
                          <mat-button-toggle class="text-center half-width" [value]="'unit'">
                            Invitations
                          </mat-button-toggle>
                          <mat-button-toggle class="text-center half-width" [value]="'amount'">
                            Amount($)
                          </mat-button-toggle>
                        </mat-button-toggle-group>
                      </div>
                    </div>
                    <div class="half-width">
                      <div class="col-right">
                        <div class="row clearfix qtr-width">
                          <mat-form-field class="full-width" appearance="outline">
                            <mat-label>Staff Name Filter...</mat-label>
                            <input class="small" [(ngModel)]="staffFilter" name="staffFilter" matInput
                                   placeholder="Staff Name Filter..."/>
                          </mat-form-field>
                        </div>
                        <div class="row clearfix full-width">
                          <div class="thrd-width">
                            <p class="small pointer-cursor" *ngIf="orderByFiler.staff?.indexOf('-MarketingLabel') == -1"
                               (click)="filterByOrderBy('staff', '-MarketingLabel')">
                              Name
                              <mat-icon *ngIf="orderByFiler.staff?.indexOf('MarketingLabel') != -1">
                                arrow_drop_up
                              </mat-icon>
                            </p>
                            <p class="small pointer-cursor" *ngIf="orderByFiler.staff?.indexOf('-MarketingLabel') != -1"
                               (click)="filterByOrderBy('staff', 'MarketingLabel')">
                              Name
                              <mat-icon>arrow_drop_down</mat-icon>
                            </p>
                          </div>

                          <div class="thrd-width">
                            <div class="half-width">
                              <p class="small pointer-cursor" *ngIf="orderByFiler.staff?.indexOf('-Count(*)') == -1"
                                 (click)="filterByOrderBy('staff', '-Count(*)')">
                                Units
                                <mat-icon *ngIf="orderByFiler.staff?.indexOf('Count(*)') != -1">
                                  arrow_drop_up
                                </mat-icon>
                              </p>
                              <p class="small pointer-cursor" *ngIf="orderByFiler.staff?.indexOf('-Count(*)') != -1"
                                 (click)="filterByOrderBy('staff', 'Count(*)')">
                                Units
                                <mat-icon>arrow_drop_down</mat-icon>
                              </p>
                            </div>
                            <div class="half-width">
                              <p class="small pointer-cursor"
                                *ngIf="orderByFiler.staff?.indexOf('-Avg(treatment.Value)') == -1"
                                 (click)="filterByOrderBy('staff', '-Avg(treatment.Value)')">
                                Avg
                                <mat-icon
                                  *ngIf="orderByFiler.staff?.indexOf('Avg(treatment.Value)') != -1">arrow_drop_up
                                </mat-icon>
                              </p>
                              <p class="small pointer-cursor"
                                *ngIf="orderByFiler.staff?.indexOf('-Avg(treatment.Value)') != -1"
                                 (click)="filterByOrderBy('staff', 'Avg(treatment.Value)')">
                                Avg
                                <mat-icon>arrow_drop_down</mat-icon>
                              </p>
                            </div>
                          </div>
                          <div class="thrd-width">
                            <p class="small pointer-cursor"
                              *ngIf="orderByFiler.staff?.indexOf('-Sum(treatment.Value)') == -1"
                               (click)="filterByOrderBy('staff', '-Sum(treatment.Value)')">
                              &nbsp; Sum
                              <mat-icon *ngIf="orderByFiler.staff?.indexOf('Sum(treatment.Value)') != -1">arrow_drop_up
                              </mat-icon>
                            </p>
                            <p class="small pointer-cursor"
                              *ngIf="orderByFiler.staff?.indexOf('-Sum(treatment.Value)') != -1"
                               (click)="filterByOrderBy('staff', 'Sum(treatment.Value)')">
                              &nbsp; Sum
                              <mat-icon>arrow_drop_down</mat-icon>
                            </p>
                          </div>
                        </div>
                        <div *ngFor="
                            let p of stats.dentists
                              | WithoutArrayMultipleValue: 'Count(*)':emptyResultFilter
                              | filterBy: ['CalculatedName']:staffFilter
                              | OrderArrayObject: orderByFiler.staff;
                            let i = index
                          " @labelAnimation>
                          <div class="row clearfix">
                            <div class="thrd-width">
                              <p>{{ p['CalculatedName'] }}</p>
                            </div>
                            <div class="thrd-width">
                              <div class="half-width">
                                <p class="value">{{ p['Count(*)'] }}</p>
                              </div>
                              <div class="half-width">
                                <p class="value">{{ p['Avg(treatment.Value)'] | customCurrency }}</p>
                              </div>
                            </div>
                            <div class="thrd-width">
                              <button class="btn-close list-button pull-right mat-button" (click)="viewStaff(p['ID'])"
                                      matTooltip="View Profile: {{ p['CalculatedName'] }}">
                                <mat-icon>visibility</mat-icon>
                              </button>
                              <button class="btn-small list-button pull-right mat-button" *ngIf="p['Count(*)'] > 0"
                                (click)="displayList(dateFrom, dateTo, merchantID, p['ID'], productID, status)"
                                      matTooltip="View InvitationList: {{ p['CalculatedName'] }}">
                                <mat-icon>list_alt</mat-icon>
                              </button>
                              <p class="value">
                                <mat-icon class="posIcon" *ngIf="
                                    arrowDirection(
                                      stats.dentists
                                        | WithoutArrayMultipleValue: 'Count(*)':emptyResultFilter
                                        | filterBy: ['CalculatedName']:staffFilterr
                                        | OrderArrayObject: orderByFiler.staff,
                                      i,
                                      'Sum(treatment.Value)'
                                    ) == true
                                  ">arrow_drop_up
                                </mat-icon>
                                <mat-icon class="negIcon" *ngIf="
                                    arrowDirection(
                                      stats.dentists
                                        | WithoutArrayMultipleValue: 'Count(*)':emptyResultFilter
                                        | filterBy: ['CalculatedName']:staffFilter
                                        | OrderArrayObject: orderByFiler.staff,
                                      i,
                                      'Sum(treatment.Value)'
                                    ) == false
                                  ">arrow_drop_down
                                </mat-icon>
                                {{ p['Sum(treatment.Value)'] | customCurrency }}
                              </p>
                            </div>
                          </div>
                          <mat-divider></mat-divider>
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-card>
              </div>
            </div>
            <div class="qtr-width leaderboard">
              <mat-card class="card dataCard clearfix" *ngFor="
                  let p of stats.dentists
                    | WithoutArrayMultipleValue: 'Count(*)':emptyResultFilter
                    | OrderArrayObject: ['Count(*)', 'Sum(treatment.Value)']
                    | arrayLast
                ">
                <div class="colour-stripe"></div>
                <div class="row clearfix">
                  <h2 class="sr-title lg-h2 pull-left dataHead">Top performing</h2>
                  <div class="pull-right">
                    <mat-icon class="fas fa-trophy"></mat-icon>
                  </div>
                  <hr/>
                </div>
                <div class="row clearfix text-center">
                  <p class="larger mb">The current <strong>best performing</strong> Staff member is</p>
                  <div class="full-width text-center">
                    <app-dentist-view-picture class="headNavPicture userPicture" [dentistID]="p['ID']">
                    </app-dentist-view-picture>
                  </div>
                  <p class="lead">
                    <strong>{{ p['CalculatedName'] }}</strong>
                    <button class="btn-close list-button mat-button" (click)="viewStaff(p['ID'])"
                            matTooltip="View Profile: {{ p['CalculatedName'] }}">
                      <mat-icon>visibility</mat-icon>
                    </button>
                  </p>
                  <p>
                    With a total of <strong>{{ p['Count(*)'] }}</strong> invitations sent, totalling a possible
                    <strong>{{ p['Sum(treatment.Value)'] | customCurrency }}</strong>
                    worth of {{"KEYWORD.treatment" | translate}}.
                  </p>
                </div>
              </mat-card>
              <mat-card class="card dataCard clearfix" *ngFor="
                  let p of stats.dentists
                    | WithoutArrayMultipleValue: 'Count(*)':emptyResultFilter
                    | OrderArrayObject: ['Count(*)', 'Sum(treatment.Value)']
                    | arrayFirst
                ">
                <div class="colour-stripe"></div>
                <div class="row clearfix">
                  <h2 class="sr-title lg-h2 pull-left dataHead">Lowest performing</h2>
                  <div class="pull-right">
                    <mat-icon class="fas fa-arrow-circle-down"></mat-icon>
                  </div>
                  <hr/>
                </div>
                <div class="row clearfix text-center">
                  <p class="larger mb">The <strong>least performing</strong> Staff Member is</p>
                  <div class="full-width text-center">
                    <app-dentist-view-picture class="headNavPicture userPicture" [dentistID]="p['ID']">
                    </app-dentist-view-picture>
                  </div>
                  <p class="lead">
                    <strong>{{ p['CalculatedName'] }}</strong>

                    <button class="btn-close list-button mat-button" (click)="viewStaff(p['ID'])"
                            matTooltip="View Profile: {{ p['CalculatedName'] }}">
                      <mat-icon>visibility</mat-icon>
                    </button>
                  </p>
                  <p>
                    With a total of <strong>{{ p['Count(*)'] }}</strong> invitations sent, totalling a possible
                    <strong>{{ p['Sum(treatment.Value)'] | customCurrency }}</strong>
                    worth of {{"KEYWORD.treatment" | translate}}.
                  </p>
                </div>
              </mat-card>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
  <div class="row clearfix full-width" *ngIf="displayInvitationList == true">
    <app-invitation-list-global *ngIf="merchantID != 'none' && isAdminOrPromoter == true" [pageSize]="pageSize"
                                [dateFrom]="dateFromInvitation" [dateTo]="dateToInvitation"
                                [merchantID]="merchantIDInvitation"
                                [productID]="productIDInvitation" [staffID]="dentistIDInvitation"
                                [status]="statusInvitation">
    </app-invitation-list-global>
    <app-invitation-list *ngIf="isAdminOrPromoter == false" [pageSize]="pageSize" [dateFrom]="dateFromInvitation"
                         [dateTo]="dateToInvitation" [productID]="productIDInvitation" [staffID]="dentistIDInvitation"
                         [status]="statusInvitation">
    </app-invitation-list>
    <div class="full-width text-center mt">
      <button class="" (click)="goBack()" mat-raised-button color="primary">
        &larr; Performance
      </button>
    </div>
  </div>
</div>
