import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
// import "rxjs/add/operator/map";
// import "rxjs/add/operator/catch";
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class TipService {
  private tipUrl: string = environment.nodeUrl + '/tip';
  private tipUrlhidden: string = environment.nodeUrl + '/tip/hidden';

  constructor(private http: HttpClient) {}

  getTipsGlobal(): Observable<any> {
    return this.http.get(environment.nodeUrl + '/tip/global').pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      }),
      catchError((error: any) => {
        return Observable.throwError(error);
      })
    );
  }

  getTipDetails(id): Observable<any> {
    return this.http.get(`${environment.nodeUrl}/tip/${id}`).pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      }),
      catchError((error: any) => {
        return Observable.throwError(error);
      })
    );
  }

  getTipsHidden(): Observable<any> {
    return this.http.get(this.tipUrlhidden).pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      }),
      catchError((error: any) => {
        return Observable.throwError(error);
      })
    );
  }

  getTips(): Observable<any> {
    return this.http.get(this.tipUrl).pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      }),
      catchError((error: any) => {
        return Observable.throwError(error);
      })
    );
  }
  /**
   * @description
   * Create Tip
   * @param tip
   */
  addTip(tip) {
    return this.http.post(`${environment.nodeUrl}/tip`, tip);
  }

  /**
   * @description
   * Remove Tip
   * @param id
   */
  removeTip(id) {
    return this.http.delete(`${environment.nodeUrl}/tip/${id}`);
  }

  /**
   * @description
   * Update Tip
   * @param tip
   */
  updateTip(id, tip) {
    return this.http.put(`${environment.nodeUrl}/tip/${id}`, tip);
  }
}
