import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { selectAppState } from './app-state.selectors';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class AppStateService {
    constructor(store) {
        this.store = store;
    }
    getAppState$() {
        return this.store.select(selectAppState).pipe(take(1));
    }
}
AppStateService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppStateService_Factory() { return new AppStateService(i0.ɵɵinject(i1.Store)); }, token: AppStateService, providedIn: "root" });
