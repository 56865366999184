<div class="row clearfix">
  <div class="card-header primary-gradient-img clearfix inModal">
    <div class="row clearfix">
      <button class="btn-close btn-clear mat-button white" *ngIf="isModal == true" (click)="closeEvent()">
        <mat-icon>clear</mat-icon>
      </button>
      <div class="row titleArea clearfix full-width">
        <h2 class="summary-header rm-m white">Update {{ title }} details</h2>
      </div>
    </div>
  </div>

  <div class="row clearfix">
    <form #createForm="ngForm">
      <div class="row clearfix">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Card Numbe</mat-label>
          <input [(ngModel)]="cardObject.cardNumber" matInput name="number" placeholder="Card Number" required/>
        </mat-form-field>
      </div>

      <div class="row clearfix" *ngIf="type == 'AU_PHF'">
        <div class="row clearfix">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Private health fund name</mat-label>
            <mat-select
              class="full-width"
              [(ngModel)]="cardObject.issuerCode"
              name="fundName"
              placeholder="Private health fund name"
              required
            >
              <mat-option *ngFor="let healthfund of healthfunds" [value]="healthfund.Code">
                {{ healthfund.Label }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <!-- <div class="row clearfix"  *ngIf="cardObject.issuerCode=='Other'">
          <p class="small">If your {{ "KEYWORD.patient" | translate }}'s private health fund is not in the list above, please enter the fund name in
            the field below.</p>

          <mat-form-field class="full-width" appearance="outline">
            <input matInput name="otherFundName" placeholder="Private health (Optional)" [(ngModel)]="cardObject.issuerCode">
          </mat-form-field>
        </div> -->
      </div>

      <div class="row clearfix">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Reference Number</mat-label>
          <input
            [(ngModel)]="cardObject.referenceNumber"
            matInput
            name="referenceNumber"
            placeholder="Reference Number"
            required
          />
        </mat-form-field>
      </div>

      <div class="row clearfix" *ngIf="type == 'AU_IDM'">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Expiry Date</mat-label>
          <input
            [(ngModel)]="cardObject.expireDate"
            [mode]="'month'"
            [dpDayPicker]="config"
            matInput
            name="expireDate"
            placeholder="Expiry Date"
            required
          />
        </mat-form-field>
      </div>
    </form>
    <hr class="mt"/>
    <button [disabled]="!createForm.form.valid" (click)="create()" mat-raised-button color="accent">Save</button>
  </div>
</div>
