import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DashboardModule } from './dashboard/dashboard.module';
import { HelperModule } from './helper/helper.module';
import { PagesMerchantRoutingModule } from './shared/pages-routing.module';

@NgModule({
  imports: [CommonModule, PagesMerchantRoutingModule, DashboardModule, HelperModule],
})
export class PagesMerchantModule {}
