import { Component, EventEmitter, OnInit } from '@angular/core';
import { UtilsService } from '../../../shared/services/utils.service';
import { Settings } from '../../../shared/types/settings';

@Component({
  selector: 'app-settlement-intro-modal',
  templateUrl: './settlement-intro-modal.component.html',
  styleUrls: ['./settlement-intro-modal.component.css'],
})
export class SettlementIntroModalComponent implements OnInit {
  showOnSettlementFirstVisit = JSON.parse(localStorage.getItem('showOnPatientListFirstVisit'));

  closeModal = new EventEmitter();

  isModuleTutorialsActive = Settings.global['isModuleTutorialsActive'];

  constructor(private utilService: UtilsService) {}

  ngOnInit() {
    this.utilService.getCurrentAccess().subscribe((access) => {
      if (access) {
        this.isModuleTutorialsActive = access['isModuleTutorialsActive'];
      }
    });
  }

  doNotShow() {
    localStorage.setItem('showOnPatientListFirstVisit', JSON.stringify(this.showOnSettlementFirstVisit));

    this.closeModal.emit(this.showOnSettlementFirstVisit);
  }
}
