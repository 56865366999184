import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';


import * as _ from 'lodash';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { MessageService } from '../../message/shared/message.service';
import { ProductUtil } from '../../product/shared/productUtil.type';
import { DefaultMessageViewComponent } from '../default-message-view/default-message-view.component';
import { MessageTemplateCreateEditComponent } from '../message-template-create-edit/message-template-create-edit.component';
import { DefaultMessageService } from '../shared/default-message.service';


@Component({
  selector: 'app-default-message-lookup',
  templateUrl: './default-message-lookup.component.html',
  styleUrls: ['./default-message-lookup.component.css']
})
export class DefaultMessageLookupComponent implements OnInit {

  @Input()
  displayMore = true;
  @Input()
  isCollapsed = false;
  typeFilter;
  @Input()
  merchantID;

  maxLength = 150

  @Input()
  viewOnClick = false;

  createNew = true
  @Input()
  type;

  @Output()
  getResult = new EventEmitter();

  @Output()
  getMessageTemplateID = new EventEmitter();

  @Output()
  getMessageTemplate = new EventEmitter();

  showNextBtn = false

  filterData = '';
  selectedPatient: any;
  utilsClass = new UtilsClass();

  isPromoterOrAdmin = true;

  @Output()
  close = new EventEmitter();

  themeObject = {};
  messageTemplates = [];
  isModal = false;

  orderByFilter = 'label'
  selectAll
  messageTemplate
  @Input()
  title = 'Select your Message Template';

  isInvitationContext = true;

  @Input()
  description = 'Below is a list of  Message Templates available. Select the one you wish to apply, or if you are feeling creative press the \'create your own\' button.';
  serviceRef
  isDestoyed = false
  isLoaded = false;
  itemsPerPage = 20;

  utils = new UtilsClass();
  currentPage;
  currentPagePromoter = 1;
  currentPageDefault = 1;
  currentPageUser = 1;
  currentPageSM = 1;
  viewOnly = false
  sessionType
  messageMode = 'all'
  purposeCodes;
  purposeCode;
  loadCard = false;
  loadMerchant = false;
  purposeCodeCreate
  loadPromoter = false;
  loadContact = false;
  @Input()
  tableName;

  @Input()
  cardID;

  @Input()
  contactID;
  tags = []
  @Input()
  contact;
  @Input()
  merchant;
  @Input()
  card: any = {
    'ID': '00000000000000000000',
    'Salutation': 'Mr',
    'FirstName': 'John',
    'MiddleName': 'Al',
    'Name': 'Doe',
    'mobiles.Number': '0061101010101',
    'phones.Number': '0062202020202',
    'emails.Email': 'john.doe@smileright.com.au',
    'addresses.Calculated': 'PO Box 7795 Cloisters Square 6850 Western Australia'

  };

  selectedTags = []
  selectedTagsTemp = []

  productUtil = new ProductUtil();
  isHeader = false
  promoter

  purposeCodesFilter = [];
  constructor(
    private authenticationService: AuthenticationService,
    private messageService: DefaultMessageService,
    private messageTemplateService: MessageService,
    private router: Router,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {


      if (data.tableName) {
        this.tableName = data.tableName
        this.isModal = true;
      }

      if (data.cardID) {
        this.cardID = data.cardID
        this.isModal = true;
      }

      if (data.contactID) {
        this.contactID = data.contactID
        this.isModal = true;
      }

      if (data.contact) {
        this.contact = data.contact
        this.isModal = true;
      }

      if (data.merchant) {
        this.merchant = data.merchant
        this.isModal = true;
      }

      if (data.card) {
        this.card = data.card
        this.isModal = true;
      }

      if (data.merchantID) {
        this.merchantID = data.merchantID
        this.isModal = true;
      }
      if (data.messageMode) {
        this.messageMode = data.messageMode
        this.isModal = true;
      }

      if (data.purposeCodeCreate) {
        this.purposeCodeCreate = data.purposeCodeCreate
        this.isModal = true;
      }




      if (data.purposeCodes && typeof data.purposeCodes === 'string') {
        this.purposeCodes = data.purposeCodes.split(',')
        this.isModal = true;
      } else if (data.purposeCodes && data.purposeCodes.length > 0 && Array.isArray(data.purposeCodes)) {
        this.purposeCodes = data.purposeCodes
        this.isModal = true;
      }


      if (data.createNew != null) {
        this.createNew = data.createNew
        this.isModal = true;
      }


      if (data.viewOnly != null) {
        this.viewOnly = data.viewOnly
        this.isModal = true;
      }

      if (data.viewOnClick != null) {
        this.viewOnClick = data.viewOnClick
        this.isModal = true;
      }
      if (data.selectAll != null) {
        this.selectAll = data.selectAll
        this.isModal = true;
      }
      if (data.isHeader != null) {
        this.isHeader = data.isHeader
        this.isModal = true;
      }


      if (data.type) {
        this.type = data.type
        this.isModal = true;
      }

      if (data.isModal != null) {
        this.isModal = data.isModal;
      }

    }
  }

  ngOnInit() {




    if (this.createNew != true) {
      this.description = 'Below is a list of  Landing Pages available. Select the one you wish to apply.';
    }




    this.authenticationService.getSessionType().subscribe(sessionType => {
      this.sessionType = sessionType;
      this.authenticationService.isPromoterOrAdmin().subscribe(res => {
        this.isPromoterOrAdmin = res;


        let pTag = {
          isPromoter: false,
          merchantID: this.merchantID,
          fields: 'Label'
        }




        if (this.isPromoterOrAdmin === true && !this.merchantID) {

          this.authenticationService.getCurrentPractice().subscribe(m => {
            if (m && m.ID) {
              this.merchantID = m.ID;

              if (this.card && this.card.ID === '00000000000000000000') {
                this.authenticationService.getCurrentDentist().subscribe(r => {


                  if (r && r['Gender.Code'] && r['Gender.Code'] === 'F') {
                    this.card.FirstName = 'Jane';
                    this.card['emails.Email'] = 'jane.doe@smileright.com.au';
                    if (r && r['Salutation']) {
                      this.card['Salutation'] = r['Salutation']
                    } else {
                      this.card['Salutation'] = 'Miss'
                    }
                  } else if (r && r['Salutation']) {
                    this.card['Salutation'] = r['Salutation']
                  }

                  this.setup()
                })
              } else {
                this.setup()

              }


            }
          })
        } else {

          if (this.card && this.card.ID === '00000000000000000000') {
            this.authenticationService.getCurrentDentist().subscribe(r => {


              if (r && r['Gender.Code'] && r['Gender.Code'] === 'F') {
                this.card.FirstName = 'Jane';
                this.card['emails.Email'] = 'jane.doe@smileright.com.au';
                if (r && r['Salutation']) {
                  this.card['Salutation'] = r['Salutation']
                } else {
                  this.card['Salutation'] = 'Miss'
                }
              } else if (r && r['Salutation']) {
                this.card['Salutation'] = r['Salutation']
              }
              this.setup()
            })
          } else {
            this.setup()

          }

        }

      })
    })



  }


  isNew(date) {
    if (date) {
      const days = this.utils.daysPast(date);

      if (days < 15) {
        return true;
      } else {
        return false;
      }
    }

    return false;
  }

  setup() {


    if (this.card && this.card.ID) {
      this.loadCard = true;
    } else if (this.cardID) {
      this.messageTemplateService.getCardDetails(this.cardID, this.tableName, {}, this.sessionType).subscribe((r) => {
        if (r) {
          this.card = r;
          this.loadCard = true;
        }
      });
    } else {
      this.loadCard = true;
    }


    if (this.contact && this.contact.ID) {
      this.loadContact = true;
    } else if (this.contactID) {
      this.messageTemplateService.getCardDetails(this.contactID, 'Contact', {}, this.sessionType).subscribe((r) => {
        if (r) {
          this.contact = r;
          this.loadContact = true;
        }
      });
    } else {
      this.authenticationService.getCurrentDentist().subscribe((r) => {
        if (r) {
          this.contact = r;
          this.loadContact = true;
        }
      });
    }
    if (this.merchant && this.merchant.ID) {
      this.loadMerchant = true;
    } else if (this.merchantID && this.isPromoterOrAdmin == true) {
      this.messageTemplateService.getCardDetails(this.merchantID, 'Merchant', {}, this.sessionType).subscribe((r) => {
        if (r) {
          this.merchant = r;
          this.loadMerchant = true;
        }
      });
    } else {
      this.authenticationService.getCurrentPractice().subscribe((r) => {
        if (r) {
          this.merchant = r;


          this.loadMerchant = true;
        }
      });
    }

    this.authenticationService.getPromoter().subscribe((r) => {
      if (r && r.data) {
        this.promoter = r.data;
        this.loadPromoter = true;
      } else if (r) {
        this.promoter = r;
        this.loadPromoter = true;
      }



    });


    this.typeFilter = null;
    this.purposeCode = null;
    let purposeCodesFilter;
    const payload = {
      orderBy: 'Label',
      merchantID: this.merchantID,
      limit: 200,
      section: 1,
      selectDefault: true,
      isActive: true,
      purposeCodes: this.purposeCodes
    }
    if (this.purposeCodes && this.purposeCodes.length > 0 && Array.isArray(this.purposeCodes)) {
      payload.purposeCodes = this.purposeCodes.join(',')
    }


    this.serviceRef = this.messageService.defaultMessageList(payload, this.sessionType).subscribe(res => {

      this.isLoaded = true;
      if (res && res.length > 0) {

        res = _.map(res, (item => {
          if (item) {
            item.search = '';
            if (item.Label) {
              item.search = item.search + ' ' + item.Label
            }
            if (item.Content) {
              item.search = item.search + ' ' + item.Content
            }

            if (item['Purpose.Code']) {
              item.search = item.search + ' ' + item['Purpose.Code']
            }
            if (item['ProductGroup.Code']) {
              item.search = item.search + ' ' + item['ProductGroup.Code']
            }




            if (item['Purpose.Code'] === 'PromSMS') {
              item.type = 'promoter'
            } else if (item.IsPromoterTemplate == '1') {
              item.type = 'default'
            } else if (item.IsPromoterTemplate == '0') {
              item.type = 'user'
            }
            if (item.tags && item.tags.length>0) {
              this.tags = this.tags.concat(item.tags)
           }

          }
          return item;
        }))


        this.tags=_.uniq(this.tags)

        this.messageTemplates = res;

        const userMessageTemplatesCount = this.messageTemplates.filter((template) => template.type === 'user').length;

        if (userMessageTemplatesCount > 0) {
          this.typeFilter = 'user';
        } else {
          this.typeFilter = 'default';
        }

        purposeCodesFilter = _.uniqBy(this.messageTemplates.concat(this.purposeCodesFilter), 'Purpose.Code');
        this.purposeCodesFilter = JSON.parse(JSON.stringify(purposeCodesFilter))

        const innerFunction = (section) => {



          section = section + 1;
          payload.section = section;

          this.serviceRef = this.messageService.defaultMessageListHidden(payload, this.sessionType).subscribe(res => {

            if (res.length > 0) {

              res = _.map(res, (item => {
                if (item) {
                  item.search = '';
                  if (item.Label) {
                    item.search = item.search + ' ' + item.Label
                  }
                  if (item.Content) {
                    item.search = item.search + ' ' + item.Content
                  }
                  if (item.tags && item.tags.length>0) {
                     this.tags = this.tags.concat(item.tags)
                  }
                  if (item['Purpose.Code']) {
                    item.search = item.search + ' ' + item['Purpose.Code']
                  }
                  if (item['ProductGroup.Code']) {
                    item.search = item.search + ' ' + item['ProductGroup.Code']
                  }

                  if (item['Purpose.Code'] === 'PromSMS') {
                    item.type = 'promoter'
                  } else if (item.IsPromoterTemplate == '1') {
                    item.type = 'default'
                  } else if (item.IsPromoterTemplate == '0') {
                    item.type = 'user'
                  }


                }
                return item;
              }))

              this.tags=_.uniq(this.tags)
              this.messageTemplates = this.messageTemplates.concat(res);

              if (this.typeFilter != 'user') {
                const _userMessageTemplatesCount = this.messageTemplates.filter((template) => template.type === 'user').length;

                if (_userMessageTemplatesCount > 0) {
                  this.typeFilter = 'user';
                } else {
                  this.typeFilter = 'default';
                }
              }


              if (this.isDestoyed != true) {
                innerFunction(section)
              }
            } else {


              purposeCodesFilter = _.uniqBy(this.messageTemplates.concat(this.purposeCodesFilter), 'Purpose.Code');
              this.purposeCodesFilter = JSON.parse(JSON.stringify(purposeCodesFilter))
              return true;
            }


          });
        }


        if (this.isDestoyed != true) {
          innerFunction(1);
        }


      }
    })

  }

  selectMessageTemplate(messageTemplate) {

    if (messageTemplate && messageTemplate.ID) {

      if (this.viewOnClick == true) {


        this.view(messageTemplate.ID)


      } else {
        this.messageTemplate = messageTemplate;

        this.sendResult();
      }



    }

  }

  ngOnDestroy() {
    this.isDestoyed = true;
    if (this.serviceRef) { this.serviceRef.unsubscribe(); }
  }
  sendResult() {

    if (this.messageTemplate && this.messageTemplate.ID) {

      this.getResult.emit(this.messageTemplate)
      this.getMessageTemplate.emit(this.messageTemplate);
      this.getMessageTemplateID.emit(this.messageTemplate.ID)

    } else {
      this.getResult.emit(null)
    }
  }

  backToOne() {
    this.currentPage = 1;
    this.currentPageDefault = 1;
    this.currentPagePromoter = 1
    this.currentPageSM = 1;
    this.currentPageUser = 1;
  }
  changePage(e) {
    if (e) {
      if (this.typeFilter == 'user') {

        this.currentPageUser = e;
        this.currentPage = e;

      } else if (this.typeFilter == 'promoter') {
        this.currentPagePromoter = e;
        this.currentPage = e;
      } else if (this.typeFilter == 'default') {
        this.currentPageDefault = e;
        this.currentPage = e;
      } else {
        this.currentPageSM = e;
        this.currentPage = e;
      }
    }
  }

  changeTab() {
    if (this.typeFilter == 'user') {
      this.currentPage = this.currentPageUser;
    } else if (this.typeFilter == 'default') {
      this.currentPage = this.currentPageDefault;
    } else if (this.typeFilter == 'promoter') {
      this.currentPage = this.currentPagePromoter;
    } else {
      this.currentPage = this.currentPageSM;
    }

  }
  view(id) {

    if (id) {
      const defaultMessageViewComponent = RootAppComponent.dialog.open(DefaultMessageViewComponent, {
        data: {
          messageID: id,
          messageMode: this.messageMode,
          card: this.card,
          merchant: this.merchant,
          promoter: this.promoter,
          contact: this.contact
        },
        width: '750px',
        panelClass: 'noCard',
      });
      defaultMessageViewComponent.componentInstance.closeModal.subscribe(r => {
        defaultMessageViewComponent.close()
      })

    }

  }


  orderBy(d) {
    if (d) {

      let isUpOrder = true;

      if (d && d[0] == '-') {
        isUpOrder = false;
      }

      if (this.orderByFilter === d) {

        if (isUpOrder == true) {
          this.orderByFilter = '-' + d
        } else {
          this.orderByFilter = d.replace('-', '')
        }

      } else {
        this.orderByFilter = d;
      }


    }
  }

  collapse(e) {
    this.isCollapsed = e;
  }

  closeEvent() {
    this.close.emit(true);
  }

  newTemplate() {
    this.newTemplateSMS()
  }

  newTemplateSMS() {
    const ref2 = RootAppComponent.dialog.open(MessageTemplateCreateEditComponent, {
      data: {
        merchantID: this.merchantID,
        messageMode: this.messageMode,
        purposeCode: this.purposeCodeCreate,
        addToStore: true,
      },
      width: "900px",
      panelClass: 'noCard'
    });


    ref2.componentInstance.close.subscribe(res => {
      ref2.close();
    })
    ref2.componentInstance.createdMessage.subscribe(item => {
      if (item) {

        if (item) {
          item.search = '';
          if (item.Label) {
            item.search = item.search + ' ' + item.Label
          }
          if (item.Content) {
            item.search = item.search + ' ' + item.Content
          }

          if (item['Purpose.Code']) {
            item.search = item.search + ' ' + item['Purpose.Code']
          }
          if (item['ProductGroup.Code']) {
            item.search = item.search + ' ' + item['ProductGroup.Code']
          }

          if (item['Purpose.Code'] === 'PromSMS') {
            item.type = 'promoter'
          } else if (item.IsPromoterTemplate == '1') {
            item.type = 'default'
          } else if (item.IsPromoterTemplate == '0') {
            item.type = 'user'
          }

        }

        this.messageTemplate = item;

        this.messageTemplates.push(item);

        this.messageTemplates = JSON.parse(JSON.stringify(this.messageTemplates))

        this.backToOne()

        this.filterData = item.Label;
        this.typeFilter = null;
        this.purposeCode = null;
        ref2.close();
        ref2.afterClosed().subscribe(r => {
          this.sendResult();
        })

      }

    })

  }


  editMessageTemplate(id) {
    if (id) {
      const ref2 = RootAppComponent.dialog.open(MessageTemplateCreateEditComponent, {
        data: {
          merchantID: this.merchantID,
          messageTemplateID: id,
          messageMode: this.messageMode,
          addToStore: true,
        },
        width: "900px",
        panelClass: 'noCard'
      });


      ref2.componentInstance.close.subscribe(res => {
        ref2.close();
      })
      ref2.componentInstance.createdMessage.subscribe(item => {
        if (item && item.ID) {

          if (item) {
            item.search = '';
            if (item.Label) {
              item.search = item.search + ' ' + item.Label
            }
            if (item.Content) {
              item.search = item.search + ' ' + item.Content
            }
            if (item['Purpose.Code']) {
              item.search = item.search + ' ' + item['Purpose.Code']
            }
            if (item['ProductGroup.Code']) {
              item.search = item.search + ' ' + item['ProductGroup.Code']
            }


            if (item['Purpose.Code'] === 'PromSMS') {
              item.type = 'promoter'
            } else if (item.IsPromoterTemplate == '1') {
              item.type = 'default'
            } else if (item.IsPromoterTemplate == '0') {
              item.type = 'user'
            }

          }

          this.messageTemplate = item;

          const index = this.messageTemplates.findIndex(e => {
            if (e && e.ID && e.ID == item.ID) {
              return true
            }

            return false
          })

          if (index !== -1) {
            this.messageTemplates[index] = item;



            this.messageTemplates = JSON.parse(JSON.stringify(this.messageTemplates))

            this.backToOne()

            this.filterData = item.Label;
            this.typeFilter = null;
            this.purposeCode = null;
            ref2.close();
            ref2.afterClosed().subscribe(r => {
              this.sendResult();
            })
          }
        }

      })
    }
  }

  delete(id) {
    if (id) {
      const confirm = new ConfirmDialog(
        'delete',
        'Are you sure  ?',
        'Remove a message Template',
        'No',
        'Yes'
      );
      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation === false) {
          ref.close();
        } else {

          this.messageService.deleteDefaultMessage(id, this.isPromoterOrAdmin).subscribe(r => {
            if (r) {
              const index = this.messageTemplates.findIndex(e => {
                if (e && e.ID && e.ID == id) {
                  return true
                }

                return false
              })

              if (index !== -1) {
                this.messageTemplates.splice(index, 1);



                this.messageTemplates = JSON.parse(JSON.stringify(this.messageTemplates))



              }

              NotifyAppComponent.displayToast('success', 'Successfull', 'Message Template is removed');
              ref.close();
            }
          })

        }
      });
    }
  }


  list() {
    this.closeEvent();

    this.router.navigate(['/merchant', { outlets: { page: ['template-default-message', 'all'] } }]);


  }



  clone(template) {
    const ref2 = RootAppComponent.dialog.open(MessageTemplateCreateEditComponent, {
      data: {
        merchantID: this.merchantID,
        messageMode: this.messageMode,
        message: template.Content,
        label: template.Label + ' (cloned)',
        productGroup: template['ProductGroup.Code'] || null,
        purposeCode: template['Purpose.Code'] || null,
        addToStore: true,
      },
      width: "900px",
      panelClass: 'noCard'
    });


    ref2.componentInstance.close.subscribe(res => {
      ref2.close();
    })
    ref2.componentInstance.createdMessage.subscribe(item => {
      if (item) {

        if (item) {
          item.search = '';
          if (item.Label) {
            item.search = item.search + ' ' + item.Label
          }
          if (item.Content) {
            item.search = item.search + ' ' + item.Content
          }

          if (item['Purpose.Code']) {
            item.search = item.search + ' ' + item['Purpose.Code']
          }
          if (item['ProductGroup.Code']) {
            item.search = item.search + ' ' + item['ProductGroup.Code']
          }

          if (item['Purpose.Code'] === 'PromSMS') {
            item.type = 'promoter'
          } else if (item.IsPromoterTemplate == '1') {
            item.type = 'default'
          } else if (item.IsPromoterTemplate == '0') {
            item.type = 'user'
          }

        }

        this.messageTemplate = item;

        this.messageTemplates.push(item);

        this.messageTemplates = JSON.parse(JSON.stringify(this.messageTemplates))

        this.backToOne()

        this.filterData = item.Label;
        this.typeFilter = null;
        this.purposeCode = null;
        ref2.close();
        ref2.afterClosed().subscribe(r => {
          this.sendResult();
        })

      }

    })

  }
}
