<aw-wizard #wizard navBarLayout="large-filled-symbols" [ngClass]="{ 'modal-dentist': isModal==true}">
  <aw-wizard-step [navigationSymbol]="{ symbol: '1' }" stepTitle="Personal Details">
    <!-- Step 1  -->

    <div class="container rel" id="step-1">

      <mat-dialog-content [ngClass]="{ 'no-modal-height': isModal != true }">

        <div class="row clearfix flex" [class.mt]="isModal == false  ">

          <div class="full-width">
            <form #personalDetails="ngForm">
              <mat-card class="row clearfix detail">
                <div class="card-header primary-gradient-img clearfix stacked">
                  <div class="row titleArea clearfix">
                    <h2 *ngIf="dentistID" class="summary-header rm-m">Edit Staff Member Details</h2>
                    <h2 *ngIf="!dentistID" class="summary-header rm-m">New Staff Member Invitation</h2>
                  </div>
                </div>


                <div class="column clearfix row mt" *ngIf="isPromoterOrAdmin == true && !dentistID">
                  <div class="row clearfix flex full-width">
                    <div class="icons-col">
                      <mat-icon class="fas fa-building"></mat-icon>
                    </div>
                    <div class="row row-content-col">

                      <div class="row clearfix  flex selectMerchant">
                        <mat-form-field class="margin-btm full-width centered" appearance="outline">
                          <mat-label>Please Select a Merchant</mat-label>
                          <mat-select class=" " [(ngModel)]="selectedMerchant" name="MerchantName"
                            placeholder="Please Select a Merchant">
                            <mat-option [value]="null"> My Account</mat-option>
                            <mat-option *ngFor="let m of merchants | orderBy: ['TradingAs', 'CalculatedName']"
                              [value]="m['ID']">
                              <span class="list-label pull-right" *ngIf="m['Status'] && m['Status'] == 'Active'"
                                [style.background]="'#00d07a'">
                                {{ m['Status'] }}
                              </span>

                              <span class="list-label pull-right" *ngIf="m['Status'] && m['Status'] != 'Active'"
                                [style.background]="'#ff5722'">
                                {{ m['Status'] }}
                              </span>
                              {{ m['TradingAs'] || m['CalculatedName'] }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>

                        <button class="pull-right merchBtn" *ngIf="merchantID && merchantID != 'none'"
                          (click)="viewMerchant()" mat-raised-button color="primary">
                          <mat-icon>person</mat-icon>
                          <span class="mobHide">View Merchant</span>
                        </button>
                      </div>
                    </div>
                  </div>

                </div>
                <div class="column">
                  <div class="row clearfix flex full-width">
                    <div class="icons-col">
                      <mat-icon>person</mat-icon>
                    </div>
                    <div class="row row-content-col">
                      <div class="row clearfix flex">
                        <!-- Gender Input -->
                        <mat-form-field class="full-width" appearance="outline">
                          <mat-label>Gender</mat-label>
                          <mat-select class="full-width" [(ngModel)]="staff.gender" placeholder="Gender" name="gender"
                            >
                            <mat-option *ngFor="let gender of genders" [value]="gender.Code">
                              {{ gender.Label }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                        <!-- Title Input -->
                        <mat-form-field class="full-width" appearance="outline">
                          <mat-label>Title</mat-label>
                          <mat-select class="full-width" [(ngModel)]="staff.title" placeholder="Title" name="title">
                            <mat-option *ngFor="let title of titles" [value]="title.Label">
                              {{ title.Label }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>

                      <div class="row clearfix flex">
                        <!-- First Name Input -->
                        <mat-form-field class="full-width" appearance="outline">
                          <mat-label>First Name</mat-label>
                          <input [(ngModel)]="staff.firstName" name="firstName" matInput
                            [placeholder]="'PLACEHOLDER.First Name' | translate" required />
                        </mat-form-field>
                        <!-- Middle Name Input -->
                        <mat-form-field class="full-width" appearance="outline">
                          <mat-label>Middle Name</mat-label>
                          <input [(ngModel)]="staff.midleName" matInput
                            [placeholder]="'PLACEHOLDER.Middle Name' | translate" name="midleName" />
                        </mat-form-field>
                        <!-- Last Name Input -->
                        <mat-form-field class="full-width" appearance="outline">
                          <mat-label>Surname</mat-label>
                          <input class="mat-block" [(ngModel)]="staff.lastName" matInput
                            [placeholder]="'PLACEHOLDER.Surname' | translate" name="lastName" required />
                        </mat-form-field>
                      </div>
                    </div>
                  </div>

                  <div class="row clearfix  mt flex full-width">
                    <div class="icons-col">
                      <mat-icon>settings_phone</mat-icon>
                    </div>
                    <div class="row-content-col flex">
                      <!-- General Phone Input -->
                      <app-phone-input class="full-width" [phoneNumber]="staff.mobile" [isLandLine]="false"
                        [label]="'Mobile'" [required]="false" (validate)="validateMobileEvent($event)"
                        (getPhone)="getMobile($event)"></app-phone-input>

                      <app-phone-input class="full-width" [phoneNumber]="staff.phone" [isLandLine]="true"
                        [label]="'Work Phone'" [required]="false" (validate)="validatePhoneEvent($event)"
                        (getPhone)="getPhone($event)"></app-phone-input>
                      <!-- Mobile Input -->
                      <!-- Other Phone Input -->
                      <app-phone-input class="full-width" [phoneNumber]="staff.homePhone" [isLandLine]="true"
                        [label]="'Home Phone'" [required]="false" (validate)="validateHomePhoneEvent($event)"
                        (getPhone)="getHomePhone($event)"></app-phone-input>
                    </div>
                  </div>

                  <div class="row clearfix flex full-width">
                    <div class="icons-col">
                      <mat-icon>mail</mat-icon>
                    </div>
                    <div class="row-content-col flex">
                      <app-email-input class="full-width" [email]="staff.email" [label]="'Email'" [required]="true"
                        (getEmail)="getEmail($event)" (validate)="validateEmailEvent($event)">
                      </app-email-input>
                    </div>
                  </div>

                  <div class="row clearfix flex mt full-width">
                    <div class="icons-col">
                      <mat-icon>store_mall_directory</mat-icon>
                    </div>
                    <div class="row-content-col">
                      <div class="full-width">
                        <!-- <div class="col-left"> -->
                        <!-- Occupation Role Input -->
                        <mat-form-field class="full-width" appearance="outline">
                          <mat-label>Employment Role</mat-label>
                          <mat-select class="full-width" [(ngModel)]="staff.contactTypeCode"
                            placeholder="Employment Role" name="Role">
                            <mat-option *ngFor="let contactTypeCode of contactTypeCodes" [value]="contactTypeCode.Code">
                              {{ contactTypeCode.Label }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>

                        <!-- </div> -->
                      </div>

                      <!-- <div class="half-width">
                      <div class="col-right">
                        <mat-form-field class="full-width" appearance="outline">
                          <mat-label>Trading as</mat-label>
                          <input matInput placeholder="Trading as" type="text" name="tradinAs"
                            [(ngModel)]="staff.tradingAs">
                        </mat-form-field>
                      </div>
                    </div> -->
                    </div>
                  </div>

                  <div class="row clearfix flex  mt full-width">
                    <!-- Address Input -->
                    <app-address-input class="full-width" [displayMap]="true" [isRequired]="false" [canClose]="false"
                      [postCode]="staff.postCode" [state]="staff.state" [suburb]="staff.suburb"
                      [streetNumber]="staff.streetNumber" [streetName]="staff.streetName"
                      [unitNumber]="staff.unitNumber" [streetType]="staff.streetType" [latitude]="staff.latitude"
                      [longitude]="staff.longitude" (getAdress)="getAdress($event)"
                      (validate)="isAdressValidEvent($event)"></app-address-input>
                  </div>
                </div>
              </mat-card>

              <mat-card class="row clearfix detail mt" *ngIf="displayEditOperator==true">
                <div class="card-header primary-gradient-img clearfix stacked">
                  <div class="row titleArea clearfix">
                    <h2 class="summary-header rm-m">Create login</h2>
                  </div>
                </div>

                <div class="row clearfix  full-width">
                  <mat-checkbox class="push full-width " [(ngModel)]="staff.isOperator" name="isLogin" color="accent">
                    Enable login?
                  </mat-checkbox>
                  <p class="mt full-width  ">
                    Creating a login will allow your staff to use the application as merchant. You can disable this
                    option
                    if your staff member does not need a login.
                  </p>
                </div>

                <div class="row clearfix full-width" *ngIf="staff.isOperator == true" @simpleFadeAnimation>
                  <hr />


                  <p class=" mt clearfix">
                    Please enter a username, your staff member will receive an email with a temporary password and will
                    be
                    prompted to change it after the next login.
                  </p>


                  <div class="row clearfix full-width flex">
                    <div class="full-width">
                      <div class="col-left">
                        <mat-form-field class="full-width" appearance="outline">
                          <mat-label>User Name</mat-label>
                          <input [(ngModel)]="staff.userName" matInput placeholder="User Name" type="text"
                            name="userName" required />
                        </mat-form-field>
                      </div>
                    </div>

                  </div>
                </div>
              </mat-card>
            </form>
          </div>
          <div class="qtr-width ml">
            <div class="col-left">
              <div class="pictureBlock mat-card">
                <div class="card-header primary-gradient-img clearfix stacked">
                  <div class="row titleArea clearfix">
                    <h2 class="summary-header rm-m">Upload Avatar</h2>
                  </div>
                </div>

                <div class="row clearfix text-center mt" *ngIf="isEdit == true">
                  <div class="upload-container">

                    <app-ep-document-view-image class="profile-lead-image" [defaultPicture]="
                      'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/profile_placeholder.png)'
                    " [link]="profileLink">
                    </app-ep-document-view-image>

                    <app-file-uploader class="text-center clearfix full-width" [allowedExtensionTypes]="['image']"
                      [limit]="1" [uploadDirectly]="true" [isDragable]="true" [keepOriginal]="false"  [title]="null"
                      [isFixedAspectRatio]="true" [resizeToHeight]="400" [resizeToWidth]="400"
                      (onCompleteAll)="onCompleteAllEditMode($event)">
                    </app-file-uploader>

                  </div>
                  <!-- <p class="small">
                    To upload a profile picture, please drag a <strong>.jpg or .png file</strong> into the grey area.
                  </p> -->
                </div>

                <!--  Picture Upload -->
                <div class="row clearfix image" *ngIf="isEdit == false">
                  <div *ngIf="isFile == false">
                    <app-ep-document-view-image class="clearfix display-image" [defaultPicture]="
                      'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/profile_placeholder.png)'
                    " [link]="profileLink">
                    </app-ep-document-view-image>
                  </div>
                  <div *ngIf="isFile == true">
                    <img class="clearfix full-width display-image" [src]="src" />
                  </div>
                </div>
                <div class="row clearfix" *ngIf="isEdit == false">
                  <!-- <h4 class="sr-title sm-h4 rm-mb">Upload Avatar</h4>
                  <p class="small rm-mb">To upload a new avatar image, please drag your file into the grey area below.
                  </p> -->
                  <app-file-uploader class="text-center clearfix full-width" [allowedExtensionTypes]="['image']"
                    [isFixedAspectRatio]="true" [isImageCropping]="true" [canSkip]="false" [limit]="1" [title]="null"
                    [uploadDirectly]="true" [isDragable]="true" (onCompleteAll)="onCompleteAll($event)">
                  </app-file-uploader>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-dialog-content>
      <div class="row clearfix">
        <div class="full-width text-center step-button-row">
          <button class="step-back  pull-left" *ngIf="isModal!=true" (click)="cancel()" mat-mini-fab color="primary">
            <mat-icon>keyboard_arrow_left</mat-icon>
          </button>


          <button class="btn-large pull-left" *ngIf="isModal == true" (click)="closeEvent()" mat-raised-button
            color="primary">
            Close
          </button>

          <!-- <button mat-raised-button class="btn-large" color="accent" (click)="test()">test</button> -->
          <button class="btn-large pull-right" *ngIf="isEdit == false" [disabled]="
              isAdressValid != true ||
              validateHomePhone != true ||
              validatePhone != true ||
              validateMobile != true ||
              validateEmail != true ||
              !personalDetails.form.valid
            " (click)="sendApplication()" mat-raised-button color="accent">
            Submit
          </button>

          <button class="btn-large pull-right" *ngIf="isEdit == true" [disabled]="!personalDetails.form.valid"
            (click)="sendApplication()" mat-raised-button color="accent">
            Submit
          </button>
        </div>
      </div>
    </div>
    <!--  / Step 1  -->
  </aw-wizard-step>

  <!-- The completion step -->
  <aw-wizard-completion-step *ngIf="isModal!=true" [navigationSymbol]="{ symbol: '2' }" title="Send!">
    <!--  Step 2 - finished -->
    <div class="container" id="step-2">
      <mat-card class="card successful">
        <div class="row clearfix">
          <div class="full-width">
            <h1 class="sm-h1 sr-title">Well done!</h1>
            <h3 class="sm-h3 rm-mt">Your invitation is complete</h3>
            <p>
              A profile for your staff member has been created, and an email confirming their details has been sent.
            </p>
            <div class="row clearfix">
              <div class="svg-box">
                <svg class="circular green-stroke">
                  <circle class="path" cx="75" cy="75" r="50" fill="none" stroke-width="5" stroke-miterlimit="10" />
                </svg>
                <svg class="checkmark green-stroke">
                  <g transform="matrix(0.79961,8.65821e-32,8.39584e-32,0.79961,-489.57,-205.679)">
                    <path class="checkmark__check" fill="none" d="M616.306,283.025L634.087,300.805L673.361,261.53" />
                  </g>
                </svg>
              </div>
            </div>

            <div class="full-width text-center">


              <button *ngIf="createdContact && createdContact.ID" class="ml mr btn-large  " (click)="contact()"
                matTooltip="Send an email to {{ staff.firstName }}" mat-raised-button color="primary">
                <mat-icon>email</mat-icon>
                Contact {{ staff.firstName }}!
              </button>


              <button *ngIf="createdContact && createdContact.ID" class="ml mr  btn-large"
                [routerLink]="['/merchant/', { outlets: { page: ['dentist-profile', createdContact.ID] } }]"
                [routerLinkActive]="['active']" mat-raised-button color="accent">
                View Profile
              </button>
            </div>
          </div>
        </div>
      </mat-card>

      <div class="row clearfix">
        <div class="full-width text-center step-button-row">
          <button class="full-back" (click)="cancel()" mat-raised-button color="primary">
            <mat-icon>keyboard_arrow_left</mat-icon> Staff List
          </button>
        </div>
      </div>
    </div>
    <!--  / Step 2  -->
  </aw-wizard-completion-step>
</aw-wizard>
