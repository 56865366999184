import { Component, OnInit, Input, Optional, Inject, Output, EventEmitter, SimpleChanges } from '@angular/core';

import { DentistService } from '../shared/dentist.service';
import { MapViewComponent } from '../../../shared/components/map-view/map-view.component';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomPhonePipe } from '../../../shared/pipes/custom-phone.pipe';
import { SessionsService } from '../../../core/session/shared/sessions.service';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ActivatedRoute } from '@angular/router';
import { trigger, style, transition, animate, keyframes, query, stagger } from '@angular/animations';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
@Component({
  selector: 'app-dentist-view',
  templateUrl: './dentist-view.component.html',
  styleUrls: ['./dentist-view.component.css'],
  animations: [
    trigger('onChangeAnimation', [
      transition('void => *', [
        query('.animate', style({ opacity: 0 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.15s ease-in-out',
              keyframes([
                style({ opacity: 0, offset: 0, height: 0, margin: 0 }),
                style({ opacity: 1, offset: 1.0, height: '*', margin: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.animate', style({ opacity: 1 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.15s ease-in-out',
              keyframes([
                style({ opacity: 1, offset: 0, height: '*', margin: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, offset: 1.0, height: 0, margin: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class DentistViewComponent implements OnInit {
  @Input() dentistID;

  subjectArray = [];

  @Input()
  invitationID;

  isMerchantAdmin = false;
  @Output() close = new EventEmitter();
  dentist;
  loading = false;
  currentUser;
  operators;
  sessionType = 'merchant';

  constructor(
    private dentistService: DentistService,
    private customPhone: CustomPhonePipe,
    private seesionService: SessionsService,
    private authenticationService: AuthenticationService,
    private activeRoute: ActivatedRoute,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data && typeof data == 'string') {
      this.dentistID = data;
      this.isModal = true;
    }
  }

  isModal = false;

  @Input()
  actionView = true;
  isPromoterOrAdmin = false;
  @Input()
  embedded = false;
  @Input()
  displayAdress = true;
  @Input()
  displayPhone = true;
  @Input()
  displayEmail = true;
  @Input()
  displayActions = true;

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      if (params['consumerID']) {
        this.invitationID = params['consumerID'];
      }
      this.authenticationService.getSessionType().subscribe((sessionType) => {
        if (sessionType) {
          this.sessionType = sessionType;
        }

        this.authenticationService.isMerchantAdmin().subscribe((isMerchantAdmin) => {
          this.isMerchantAdmin = isMerchantAdmin;

          this.authenticationService.isPromoterOrAdmin().subscribe((isPromoterOrAdmin) => {
            this.isPromoterOrAdmin = isPromoterOrAdmin;
            this.authenticationService.getCurrentUser().subscribe((res) => {
              if (res) {
                this.currentUser = res.data;
              }

              if (this.dentistID) {
                this.dentistService
                  .getOneContact(this.dentistID, { selectOperator: true }, this.sessionType)
                  .subscribe((res) => {
                    this.dentist = res;
                  });
              }
            });
          });
        });
      });
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.dentistID.previousValue != changes.dentistID.currentValue) {
      this.authenticationService.getSessionType().subscribe((sessionType) => {
        if (sessionType) {
          this.sessionType = sessionType;
        }

        if (this.dentistID) {
          this.dentistService
            .getOneContact(this.dentistID, { selectOperator: true }, this.sessionType)
            .subscribe((res) => {
              this.dentist = res;
            });
        }
      });
    }
  }

  getMoreInfo() {
    var phone = '';

    if (this.dentist['phones.Number']) {
      phone = this.customPhone.transform(String(this.dentist['phones.Number']), 'landLine');
    }

    var result =
      "<p><strong>Dentist's name: </strong>" +
      this.dentist['CalculatedName'] +
      '</p><p><strong>Phone: </strong>' +
      String(phone) +
      "</p><p><strong>Email: </strong> <a class='emailLink primary-color' href='mailto:" +
      String(this.dentist['emails.Email']) +
      "' title='Email Dentist' target='_blank'>" +
      this.dentist['emails.Email'] +
      '</a></p>';

    return result;
  }

  closeModal() {
    this.close.emit(true);
  }

  displayMap() {
    var location = {
      zoom: 17,
      postCode: this.dentist['addresses.Postcode'],
      state: this.dentist['addresses.State'],
      streetType: this.dentist['addresses.Street Type'],
      streetName: this.dentist['addresses.Street Name'],
      streetNumber: this.dentist['addresses.Street Nr'],
      suburb: this.dentist['addresses.Suburb'],
      country: this.dentist['addresses.Country.Code'],
      addressLabel: this.dentist['addresses.Calculated'],
      latitude: this.dentist['Address.Latitude'],
      longitude: this.dentist['Address.Longitude'],
      moreInfo: this.getMoreInfo(),
    };

    let ref = RootAppComponent.dialog.open(MapViewComponent, {
      data: location,
      panelClass: 'modal-map',
      width: '800px',
      height: '600px',
    });

    ref.componentInstance.addCoordinate.subscribe((res) => {
      if (res && res.latitude && res.longitude) {
        let payload = {
          latitude: res.latitude,
          longitude: res.longitude,
        };
        this.dentistService.addCoordinate(this.dentistID, payload).subscribe((res) => {});
      }
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  contact(id) {
    var data = {
      invitationID: this.invitationID,
      targetType: 'contact',
      targetID: id,
      asGuest: false,
      asProfile: true,
      asPractice: true,
      subjectArray: this.subjectArray,
    };
    AuthenticationService.contactInputEvent.emit(data);
  }

  contactSMS(id) {
    if (id) {
      AuthenticationService.SMSConversation.emit({
        cardID: id,
        isContactStaff: true,
        singleChat: true,
      });
    }
  }

  closeEvent() {
    this.close.emit(true);
  }
}
