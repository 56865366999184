<div class="rel">
  <form #resetForm="ngForm">
    <div
      class="card-header primary-gradient-img clearfix confirmModal"
      [attr.style]="'background:' + theme.background + ' !important' | safeStyle"
    >
      <button class="btn-close btn-clear mat-button smt white custom-close" (click)="closeEvent()">
        <mat-icon>clear</mat-icon>
      </button>
      <div class="full-width flex main-title-container">
        <div class="row full-width title-container clearfix">
          <div class="title-container-sub">
            <h2 class="text-left title-header rm-m smt">
              {{ titlePage }}

              <span class="subLabel">
                {{ descriptionPage }}
              </span>
            </h2>
          </div>
        </div>
      </div>
    </div>

    <mat-dialog-content>
      <div class="clearfix text-center full-width">
        <div class="clearfix text-center full-width">
          <div class="full-width text-center flex mb">
            <div
              class="theme-container"
              *ngFor="let t of themes"
              [attr.style]="'background:' + t.background + ' !important' | safeStyle"
              (click)="selectTheme(t)"
            >
              <mat-icon class="fas fa-check-circle" *ngIf="t && t.code && theme && theme.code && t.code == theme.code">
              </mat-icon>
            </div>
          </div>

          <div class="full-width flex mt">
            <mat-form-field class="full-width mr" appearance="outline">
              <mat-label>Sender</mat-label>
              <mat-select
                class="full-width"
                [(ngModel)]="contactID"
                (ngModelChange)="changeDentist()"
                placeholder=""
                name="detnistID"
                required
              >
                <mat-option *ngFor="let p of dentists" [value]="p['ID']">
                  {{ p.CalculatedName }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="full-width mr" appearance="outline">
              <mat-label>Default product group*</mat-label>
              <mat-select
                class="full-width"
                [(ngModel)]="productGroup"
                (ngModelChange)="getLoanAmount(productGroup)"
                placeholder=""
                name="productGroup"
              >
                <mat-option *ngFor="let p of financedProductGroup" [value]="p['GroupCode']">
                  <mat-icon class="fas {{ productUtil.getProductGroupIcon(p['GroupCode']) }} mr iconproductgroup ">
                  </mat-icon>

                  {{ productUtil.getLabel(p['GroupCode']) }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>{{"KEYWORD.Treatment" | translate}} Value ($)</mat-label>
              <input
                [(ngModel)]="treatmentAmount"
                name="treatmentAmount"
                matInput
                placeholder="Please enter a value "
                currencyMask
                required
              />
            </mat-form-field>
          </div>
        </div>

        <div class="clearfix text-center full-width flex">
          <div class="clearfix text-center full-width">
            <h3
              class="sr-title text-left full-width text-center rm-m mb"
              [attr.style]="'color:' + theme.color + ' !important' | safeStyle"
            >
              Product Details
            </h3>

            <message-input
              class="full-width message-input-component251"
              *ngIf="loadPromoter == true && loadContact == true && loadMerchant == true"
              [isTiny]="true"
              [isModal]="false"
              [messageMode]="messageMode"
              [label]="'Please insert a  message'"
              [message]="_message"
              [showAllCustomization]="true"
              [card]="card"
              [textLength]="0"
              [displayEmoji]="false"
              [displayMessageTemplate]="true"
              [contact]="contact"
              [displayPatient]="false"
              [displayContact]="true"
              [displayMerchant]="true"
              [merchant]="merchant"
              [contact]="contact"
              [promoter]="promtoer"
              [displayPromoter]="true"
              [displayMarketing]="true"
              [displayExpend]="false"
              [displayDentalOffers]="true"
              [displayDocument]="true"
              [saveAsTemplate]="false"
              [isSMSPreview]="true"
              [isDesktopPreview]="true"
              [displaySaveAsTemplate]="true"
              [encode]="false"
              [tableName]="tableName"
              (getResult)="getResultMessage($event)"
            >
            </message-input>
          </div>

          <mat-card
            class="editor-card-preview row file animate mat-elevation-z4 full-width"
            [ngClass]="isHeaderImage != true ? 'no-image' : ''"
          >
            <div class="full-width landing-page-create-gallery-container">
              <h3
                class="sr-title full-width text-center rm-m smb"
                *ngIf="isHeaderImage == true"
                [attr.style]="'color:' + theme.color + ' !important' | safeStyle"
              >
                Product Image
              </h3>

              <h3
                class="sr-title full-width text-center rm-m smb"
                *ngIf="isHeaderImage != true"
                [attr.style]="'color:' + theme.color + ' !important' | safeStyle"
              >
                Product Image
              </h3>
              <app-ep-document-view-image
                class="landing-page-create-gallery2"
                *ngIf="isHeaderImage != true"
                [imageLink]="
                  'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/no-image.jpg'
                "
                [hideZoomButton]="true"
                style="margin-top: 60px !important"
              >
              </app-ep-document-view-image>

              <app-ep-document-view-image
                class="landing-page-create-gallery2"
                *ngIf="headerImage != 'merchant' && isHeaderImage == true"
                [ngClass]="getCircle(headerImage) == true ? 'round-circle' : ''"
                [imageLink]="headerImage"
                [hideZoomButton]="false"
                style="margin-top: 60px !important"
              >
              </app-ep-document-view-image>

              <app-ep-document-view-image
                class="landing-page-create-gallery2 round-circle"
                *ngIf="headerImage == 'merchant' && merchantID && isHeaderImage == true"
                [imageLink]="getMerchantLink()"
                [hideZoomButton]="false"
                style="margin-top: 60px !important"
              >
              </app-ep-document-view-image>
            </div>

            <mat-checkbox
              class="text-left no-image-checkbox"
              [(ngModel)]="isHeaderImage"
              matTooltip="Add Product  Image"
              color="accent"
              name="isHeaderImage"
            >
            </mat-checkbox>

            <div class="full-width text-center card-image-footer">
              <button
                class="btn pull-right mr"
                *ngIf="isHeaderImage != true"
                [disabled]="true"
                mat-icon-button
                color="accent"
              >
                <mat-icon class="fas fa-upload"></mat-icon>
              </button>
              <button
                class="btn pull-left ml"
                *ngIf="isHeaderImage != true"
                [disabled]="true"
                [attr.style]="'background:' + theme.color + ' !important' | safeStyle"
                mat-raised-button
                color="accent"
              >
                <mat-icon class="fas fa-images smr"></mat-icon> Gallery
              </button>

              <button
                class="btn pull-right mr"
                *ngIf="isHeaderImage == true"
                (click)="upload()"
                mat-icon-button
                color="accent"
                matTooltip="Upload New Image"
              >
                <mat-icon class="fas fa-upload"></mat-icon>
              </button>

              <button
                class="btn pull-left ml"
                *ngIf="isHeaderImage == true"
                [attr.style]="'background:' + theme.color + ' !important' | safeStyle"
                (click)="galleryHeader()"
                mat-raised-button
                color="accent"
              >
                <mat-icon class="fas fa-images smr"></mat-icon> Gallery
              </button>
            </div>
          </mat-card>
        </div>
        <div class="clearfix text-center full-width">
          <h3
            class="sr-title text-left full-width text-center mt mb"
            [attr.style]="'color:' + theme.color + ' !important' | safeStyle"
          >
            Title & Description
          </h3>
          <mat-form-field class="clearfix full-width" appearance="outline">
            <mat-label>Landing Page Title</mat-label>
            <input [(ngModel)]="title" name="Title" matInput placeholder="Landing Page Title" required/>
          </mat-form-field>

          <message-input
            class="full-width message-input-component250"
            *ngIf="loadPromoter == true && loadContact == true && loadMerchant == true"
            [isTiny]="true"
            [isModal]="false"
            [messageMode]="messageMode"
            [label]="'Please insert a description'"
            [message]="_description"
            [showAllCustomization]="true"
            [card]="card"
            [textLength]="0"
            [displayEmoji]="false"
            [displayMessageTemplate]="true"
            [contact]="contact"
            [displayPatient]="false"
            [displayContact]="true"
            [displayMerchant]="true"
            [merchant]="merchant"
            [contact]="contact"
            [promoter]="promtoer"
            [displayPromoter]="true"
            [displayMarketing]="true"
            [displayExpend]="false"
            [displayDentalOffers]="true"
            [displayDocument]="true"
            [saveAsTemplate]="false"
            [isSMSPreview]="true"
            [isDesktopPreview]="true"
            [displaySaveAsTemplate]="true"
            [encode]="false"
            [tableName]="tableName"
            (getResult)="getResultDescription($event)"
          >
          </message-input>

          <h3
            class="sr-title text-left full-width text-center mt smb"
            [attr.style]="'color:' + theme.color + ' !important' | safeStyle"
          >
            Additional Document <span *ngIf="fileIDs && fileIDs.length > 0"> ({{ fileIDs.length }})</span>
          </h3>
          <div class="full-width mb">
            <app-file-uploader
              class="text-center"
              [documentType]="'Additional Document'"
              [fileName]="'Shared Document'"
              [isGallery]="false"
              [isDragable]="true"
              (onCompleteAll)="onCompleFile($event)"
            >
            </app-file-uploader>
          </div>
          <div class="full-width" *ngIf="fileIDs && fileIDs.length > 0">
            <app-file-list
              class=""
              [defaultLabel]="'Shared Document'"
              [documentTypes]="documentTypes"
              [files]="fileIDs"
              [addFile]="addFile"
              [canPatientDocument]="false"
              [canGallery]="false"
              [canPrivateDocument]="false"
              [canEdit]="true"
              [canAction]="false"
              (remove)="removeNewFile($event)"
            >
            </app-file-list>
          </div>

          <h3
            class="sr-title text-left full-width text-center mt smb"
            [attr.style]="'color:' + theme.color + ' !important' | safeStyle"
          >
            Extra Links
          </h3>

          <div class="full-width flex smb">
            <mat-checkbox
              class="full-width text-left smt mr"
              [(ngModel)]="displayAppointmentPage"
              color="accent"
              name="displayAppointmentPage"
            >
              Appointment Request Link
            </mat-checkbox>

            <mat-checkbox
              class="full-width text-left smt mr"
              [(ngModel)]="displayTipsAndOffersPage"
              color="accent"
              name="displayTipsAndOffersPage"
            >
              Tips and Offers Link
            </mat-checkbox>

            <mat-checkbox
              class="full-width text-left smt mr"
              [(ngModel)]="displayGoingGreenPage"
              color="accent"
              name="displayGoingGreenPage"
            >
              Going Green Link
            </mat-checkbox>
          </div>
          <h3
            class="sr-title smt text-left full-width text-center smb"
            [attr.style]="'color:' + theme.color + ' !important' | safeStyle"
          >
            Tags
          </h3>
          <div class="full-width">
            <app-tag-list
              class="full-width"
              [tags]="tags"
              [selectedTags]="defaultSelectedTags"
              [add]="addTag"
              [tagFormatIsObject]="false"
              [title]="'Attach tags to this landing page'"
              [canRemove]="true"
              [canCreate]="true"
              [directRemove]="true"
              [directRemoveDefault]="true"
              [hasFilter]="true"
              [canSelect]="false"
              (getCreate)="createNewTag()"
              (getSelectedTags)="getSelectedTags($event)"
            >
            </app-tag-list>
          </div>
        </div>
      </div>
    </mat-dialog-content>

    <hr/>

    <div class="drop-button text-center mt text-center full-width">
      <button class="btn-large ml mr pull-right" (click)="submit()" mat-raised-button color="accent">Submit</button>

      <button
        class="btn-large pull-left"
        [attr.style]="'background:' + theme.color + ' !important' | safeStyle"
        (click)="closeEvent()"
        mat-raised-button
        color="primary"
      >
        close
      </button>
    </div>
  </form>
</div>
