<div class="table-container">
  <table *ngIf="datasource.data.length > 0; else empty" [dataSource]="datasource" mat-table matSort>
    <ng-container *ngFor="let header of headers; let index = index">
      <ng-container [matColumnDef]="header.key" [sticky]="index === 0 && isFixedFirstRow">
        <!-- Header -->
        <ng-container *ngIf="!isSortable(header.key); else sortableHeader">
          <th
            *matHeaderCellDef
            [ngStyle]="{
              'text-align': getHeaderFormatting(header.key),
              'padding-right': index === 0 && isFixedFirstRow ? '20px' : '16px'
            }"
            mat-header-cell
            style="padding-left: 0px"
          >
            <span *ngIf="titleCaseEnabled === true"> {{ header.title | titlecase }} </span>
            <span *ngIf="titleCaseEnabled === false"> {{ header.title }} </span>
          </th>
        </ng-container>

        <!-- Sortable Header -->
        <ng-template #sortableHeader>
          <th
            *matHeaderCellDef
            [ngStyle]="{
              'text-align': getHeaderFormatting(header.key),
              'padding-right': index === 0 && isFixedFirstRow ? '20px' : '16px'
            }"
            mat-header-cell
            mat-sort-header
            style="padding-left: 0px"
          >
            <span *ngIf="titleCaseEnabled === true"> {{ header.title | titlecase }} </span>
            <span *ngIf="titleCaseEnabled === false"> {{ header.title }} </span>
          </th>
        </ng-template>

        <!-- Data -->
        <ng-container>
          <td
            *matCellDef="let item"
            [ngStyle]="{ 'background-color': getSectionColor(item) }"
            (click)="emitItem(item, header.key)"
            mat-cell
          >
            <div
              *ngIf="
                (!detectedCustomComponent(header.key) || getSectionColor(item) !== 'white') && !isCustomSection(item);
                else customComponent
              "
              [ngStyle]="{
                color: getSectionFontColor(item),
                'padding-left': getSectionColor(item) !== 'white' ? '2px' : '0px',
                'font-weight': getSectionColor(item) !== 'white' ? 'bold' : 'normal',
                'font-size': getSectionColor(item) !== 'white' ? 'large' : 'normal'
              }"
            >
              {{ item[header.key] }}
            </div>

            <ng-template #customComponent>
              <div
                [ngStyle]="{
                  color: getSectionFontColor(item),
                  'padding-left': getSectionColor(item) !== 'white' ? '2px' : '0px',
                  'font-weight': getSectionColor(item) !== 'white' ? 'bold' : 'normal',
                  'font-size': getSectionColor(item) !== 'white' ? 'large' : 'normal'
                }"
              >
                <ng-container
                  [ngTemplateOutletContext]="{ $implicit: item }"
                  [ngTemplateOutlet]="getAssociatedTemplate(header.key)"
                >
                </ng-container>
              </div>
            </ng-template>
          </td>
        </ng-container>

        <!-- Footer Cell -->
        <ng-container *ngIf="getFooterRowLength() > 0">
          <td
            *matFooterCellDef
            [ngStyle]="{
              'text-align': getHeaderFormatting(header.key),
              'background-color': footerSectionBackgroundColor
            }"
            mat-footer-cell
          >
            <ng-container *ngIf="!detectedFooterCustomComponent(header.key); else footerCustomComponent">
              {{ footerRow[header.key] }}
            </ng-container>

            <ng-template #footerCustomComponent>
              <ng-container
                [ngTemplateOutletContext]="{ $implicit: footerRow[header.key] }"
                [ngTemplateOutlet]="getAssociatedFooterTemplate(header.key)"
              >
              </ng-container>
            </ng-template>
          </td>
        </ng-container>
      </ng-container>
    </ng-container>

    <!-- Action Menu -->
    <ng-container *ngIf="menuTemplate" matColumnDef="actions" stickyEnd>
      <!-- Header -->
      <th
        *matHeaderCellDef
        [ngStyle]="{ 'text-align': getHeaderFormatting('actions') }"
        mat-header-cell
        style="padding-right: 20px"
      >
        Actions
      </th>

      <!-- Data -->
      <td *matCellDef="let item" mat-cell>
        <button [matMenuTriggerFor]="actionMenu" mat-icon-button>
          <mat-icon>more_vert</mat-icon>
        </button>

        <mat-menu #actionMenu>
          <ng-container [ngTemplateOutletContext]="{ $implicit: item }" [ngTemplateOutlet]="menuTemplate">
          </ng-container>
        </mat-menu>
      </td>

      <!-- Footer -->
      <td *matFooterCellDef mat-footer-cell></td>
    </ng-container>

    <tr *matHeaderRowDef="headerKeys; sticky: true" mat-header-row></tr>
    <tr *matRowDef="let row; columns: headerKeys" mat-row></tr>

    <ng-container *ngIf="getFooterRowLength() > 0">
      <tr *matFooterRowDef="headerKeys" mat-footer-row></tr>
    </ng-container>
  </table>

  <ng-template #isChecked let-record>
    <mat-checkbox [checked]="record.isChecked" (change)="setIsChecked($event.checked, record)"> </mat-checkbox>
  </ng-template>

  <ng-template #empty>
    <app-empty-list-message></app-empty-list-message>
  </ng-template>
</div>

<mat-paginator
  #paginator
  [hidden]="!paginate"
  [length]="table.length"
  [pageIndex]="pageIndex"
  [pageSizeOptions]="pageSizeOptions"
  [pageSize]="pageSize"
  (page)="getPageEvent($event)"
>
</mat-paginator>
