import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import * as $ from 'jquery';
import { FileUploader } from 'ng2-file-upload';

import { MatDialog } from '@angular/material/dialog';
import { environment } from 'environments/environment';
import * as mime from 'mime-types';
import { NotifyAppComponent } from '../../types/notify-app-component';
import { Settings } from '../../types/settings';
import { UtilsClass } from '../../types/utils/utils.class';
import { FileImageCropperComponent } from '../file-image-cropper/file-image-cropper.component';

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.css'],
})
export class FileUploaderComponent implements AfterViewInit {
  @Input() invitationID = '';
  @Input() label = 'document';
  @Input() limit = 10;
  @Input() uploadDirectly = true;
  @Input() isDragable = false;
  @Input() editMode7 = true;
  @Input() uploadAll = new EventEmitter();
  @Output() onCompleteAll = new EventEmitter();
  @Output() onCompleteAllDetails = new EventEmitter();
  @Output() isUploaded = new EventEmitter();
  @Output() fileQueued = new EventEmitter();

  @Input() isTwoThirdWidth = true;
  @Input() isImageCropping = true;

  @Input()
  roundCropper = false;
  @Input()
  canSkip = true;
  @Input()
  hasCustomTitleText = false;
  @Input()
  isDisabled;
  @Input()
  isFixedAspectRatio = false;

  @Input()
  aspectRatio = '1/1';
  @Input()
  aspectRatioType = null;

  @Input()
  isCover = false;

  @Input()
  isTemplateThumbnail = false;

  @ViewChild('fileCropperInput', { static: false })
  fileCropperInputVariable: ElementRef;

  nextFile = new EventEmitter();

  @Input()
  title = 'Drag and Drop or Click to upload files';

  defaultFilesExist;

  divID = String(Math.random()).substring(2, 18);

  @Input()
  uploaderID = 'file-uploader';

  @Input()
  resizeToHeight = 0;

  @Input()
  resizeToWidth = 0;

  @Input()
  keepOriginal = true;

  @Input()
  displayToaster = true;

  @Input()
  documentType;

  @Input()
  fileName;

  @Input()
  fileDescription;

  @Input()
  isPrivate = false;

  @Input()
  isGallery = false;

  @Input()
  isPatientDocument = false;

  @Input()
  patientDocumentType;

  @Input()
  backgroundColor = '#fff';

  uploadDocumentArray = [];
  uploadDocumentDetailsArray = [];
  type = '';
  filesAdded = false;
  is_uploaded = false;

  @Input()
  allowedExtensions = [];

  @Input()
  allowedExtensionTypes;
  settings = Settings.global;
  @Input()
  maxFileSize = this.settings['fileMaxSize'];

  allowSize = Number(this.settings['fileMaxSize']) * 1024 * 1024;

  @ViewChild('nav-trigger', { static: false }) dropzone: ElementRef;
  // create the uploader
  uploader: FileUploader = new FileUploader({
    url: environment.nodeUrl + '/files',
    autoUpload: false,
    removeAfterUpload: true,
  });

  isError = false;

  utils = new UtilsClass();

  @Input() backgroundImageUrl = 'none';

  @Input() hasResponsiveHeight = false;

  constructor(private dialog: MatDialog, private elementRef: ElementRef) {}

  @HostListener('window:drop', ['$event'])
  preventDefault(e) {
    e.preventDefault();
  }

  ngOnInit() {
    if (this.maxFileSize) {
      this.allowSize = Number(this.maxFileSize) * 1024 * 1024;
    } else {
      this.allowSize = Number(this.settings['fileMaxSize']) * 1024 * 1024;
    }

    if (this.allowedExtensionTypes) {
      if (Array.isArray(this.allowedExtensionTypes)) {
        let allowedExtensions = [];
        for (let i = 0; i < this.allowedExtensionTypes.length; i++) {
          let r = [];
          if (this.allowedExtensionTypes[i]) {
            r = this.utils.getDocumentExtensionsFromType(this.allowedExtensionTypes[i]);
          }

          if (r && r.length > 0) {
            allowedExtensions = allowedExtensions.concat(r);
          }
        }

        if (this.allowedExtensions && this.allowedExtensions.length > 0) {
          this.allowedExtensions = this.allowedExtensions.concat(allowedExtensions);
        } else {
          this.allowedExtensions = allowedExtensions;
        }
      } else if (this.allowedExtensionTypes) {
        const allowedExtensions = this.utils.getDocumentExtensionsFromType(this.allowedExtensionTypes);

        if (allowedExtensions && allowedExtensions.length > 0) {
          if (this.allowedExtensions && this.allowedExtensions.length > 0) {
            this.allowedExtensions = this.allowedExtensions.concat(allowedExtensions);
          } else {
            this.allowedExtensions = allowedExtensions;
          }
        }
      }
    }
  }

  dropFile(e) {
    const _images = [];
    if (e && e.dataTransfer && e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      for (let i = 0; i < e.dataTransfer.files.length; i++) {
        const _file = e.dataTransfer.files[i];

        if (_file && _file.size && _file.size <= this.allowSize) {
          const verifyExtension = this.verifyExtension(_file.type);
          if (verifyExtension == true) {
            if (_file && _file.type && this.isImageCropping == true && this.isImageByType(_file.type) == true) {
              _images.push(_file);
            } else if (_file) {
              this.uploader.addToQueue([_file]);
            }
          } else {
            NotifyAppComponent.displayToast(
              'error',
              'The Extension' + String(mime.extension(_file.type)).toUpperCase() + ' is not allowed!',
              'Allowed extensions are  ' + this.allowedExtensions.join(', ') + '.'
            );
          }
        } else {
          NotifyAppComponent.displayToast(
            'error',
            'The file is too big!',
            'Maximun allowed file size is ' + this.maxFileSize + 'MB'
          );
        }
      }
    }

    if (_images && _images.length > 0) {
      this.arrayImageCropper(_images);
    }

    this.hideDropZone();
  }

  onCancelItem(item) {
    item.remove();
    this.isUploaded.emit(false);
  }

  hideDropZone() {
    const divID = this.divID;

    const item = document.querySelector('#dropzone' + divID) as HTMLElement;
    item.style.opacity = '0';
    // give css animation enough time to finish before setting visibility hidden
    setTimeout(function () {
      item.style.visibility = 'hidden';
      item.style.pointerEvents = 'none';
    }, 300);
  }

  ngAfterViewInit() {
    const divID = this.divID;
    const that = this;
    const dropcontainer = this.elementRef.nativeElement.querySelector('.drop-container' + divID);

    let lastTarget = null;

    dropcontainer.addEventListener('dragenter', function (e) {
      if (that.isDragable && !that.isDisabled) {
        lastTarget = e.target; // cache the last target here
        // unhide our dropzone overlay
        const item = document.querySelector('#dropzone' + divID) as HTMLElement;

        item.style.opacity = '1';
        item.style.pointerEvents = 'auto';
        item.style.visibility = '';
      }
    });
    dropcontainer.addEventListener('dragleave', function (e) {
      // this is the magic part. when leaving the window,
      // e.target happens to be exactly what we want: what we cached
      // at the start, the dropzone we dragged into.
      // so..if dragleave target matches our cache, we hide the dropzone.
      if (e.target === lastTarget || e.target === document) {
        const item = document.querySelector('#dropzone' + divID) as HTMLElement;
        item.style.opacity = '0';
        // give css animation enough time to finish before setting visibility hidden
        setTimeout(function () {
          item.style.visibility = 'hidden';
          item.style.pointerEvents = 'none';
        }, 300);
      }
    });
    dropcontainer.addEventListener('dragover', function (e) {
      e.preventDefault();
    });
    dropcontainer.addEventListener('dragend', function (e) {
      e.preventDefault();
    });
    dropcontainer.addEventListener('drop', function (e) {
      e.preventDefault();
      if (that.isDragable && !that.isDisabled) {
        that.dropFile(e);
      }
    });

    // this.fileQueued.emit(this.uploader.queue.length);

    this.uploader.onAfterAddingFile = (item) => {
      this.filesAdded = true;
      this.isUploaded.emit(true);
      if (this.uploadDirectly == true) {
        this.uploader.uploadAll();
      }
    };

    this.uploader.onCancelItem = (item) => {
      if (this.uploader.queue.length == 0) {
        this.filesAdded = false;
      }
    };

    this.uploader.onBuildItemForm = (item, form) => {
      this.uploader.options.additionalParameter = {
        label: this.label,
        invitation_id: this.invitationID,
        type: item.file.type,
        documentType: this.documentType,
        fileDescription: this.fileDescription,
        fileName: this.fileName,
        isPrivate: this.isPrivate,
        isGallery: this.isGallery,
        isPatientDocument: this.isPatientDocument,
        patientDocumentType: this.patientDocumentType,
      };
    };

    this.uploader.onBeforeUploadItem = () => {
      $('#loader').show();
    };

    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      $('#loader').hide();
      try {
        this.isError = false;

        const result = JSON.parse(response);
        if (status === 400) {
          if (result.success === false) {
            UtilsClass.displayErrorModal(result.error.message, result.error.code, null);
          }
        } else if (status === 200) {
          this.is_uploaded = true;
          const res = JSON.parse(response).data[0];
          this.uploadDocumentArray.push(res._id);
          this.uploadDocumentDetailsArray.push(res);

          if (this.uploadDirectly == true) {
            if (this.uploader.queue.length != 0) {
            } else {
              this.uploader.uploadAll();
            }
          }
        } else {
          UtilsClass.displayError(result.error.message, status, null);
        }
      } catch (e) {
        this.isError = true;
        NotifyAppComponent.displayToast(
          'error',
          'Failed to upload your document!',
          'Please make sure your file does not exceed: <strong>' + this.settings['fileMaxSize'] + 'MB</strong>'
        );
      }
    };

    this.uploader.onCompleteAll = () => {
      if (this.isError == false) {
        this.filesAdded = false;
        if (this.displayToaster == true) {
          NotifyAppComponent.displayToast('success', 'Success!', 'Your documents are successfully uploaded!');
        }

        this.onCompleteAll.emit(this.uploadDocumentArray);
        this.uploadDocumentArray = [];

        this.onCompleteAllDetails.emit(this.uploadDocumentDetailsArray);
        this.uploadDocumentDetailsArray = [];
      }
    };

    this.uploadAll.subscribe(() => {
      if (this.uploader.queue.length === 0 && this.isError == false) {
        this.onCompleteAll.emit(this.uploadDocumentArray);
        this.onCompleteAllDetails.emit(this.uploadDocumentDetailsArray);
      } else {
        this.uploader.uploadAll();
      }
    });
  }

  fileChange(e) {
    const _images = [];
    if (e && e.srcElement && e.srcElement.files && e.srcElement.files.length > 0) {
      for (let i = 0; i < e.srcElement.files.length; i++) {
        const _file = e.srcElement.files[i];
        if (_file && _file.size && _file.size <= this.allowSize) {
          const verifyExtension = this.verifyExtension(_file.type);
          if (verifyExtension == true) {
            if (_file && _file.type && this.isImageCropping == true && this.isImageByType(_file.type) == true) {
              _images.push(_file);
            } else if (_file) {
              this.uploader.addToQueue([_file]);
            }
          } else {
            NotifyAppComponent.displayToast(
              'error',
              'The Extension ' + String(mime.extension(_file.type)).toUpperCase() + ' is not allowed!',
              'Allowed extensions are  ' + this.allowedExtensions.join(', ') + '.'
            );
          }
        } else {
          NotifyAppComponent.displayToast(
            'error',
            'The file is too big!',
            'Maximun allowed file size is ' + this.maxFileSize + 'MB'
          );
        }
      }
    }

    if (_images && _images.length > 0) {
      this.arrayImageCropper(_images);
    }
  }

  arrayImageCropper(images) {
    if (images && images.length) {
      const innerFunction = (index) => {
        index++;

        if (index < images.length) {
          let file = images[index];

          if (file) {
            const ref = this.dialog.open(FileImageCropperComponent, {
              data: {
                imageFile: file,
                isFixedAspectRatio: this.isFixedAspectRatio,
                isCover: this.isCover,
                isTemplateThumbnail: this.isTemplateThumbnail,
                aspectRatio: this.aspectRatio,
                aspectRatioType: this.aspectRatioType,
                canSkip: this.canSkip,
                resizeToHeight: this.resizeToHeight,
                resizeToWidth: this.resizeToWidth,
                keepOriginal: this.keepOriginal,
                backgroundColor: this.backgroundColor,
                roundCropper: this.roundCropper,
              },

              width: '850px',
              panelClass: 'noCard',
            });

            ref.componentInstance.getFile.subscribe((res) => {
              if (res) {
                this.uploader.addToQueue([res]);
                file = null;
                ref.close();
                ref.afterClosed().subscribe((res) => {
                  innerFunction(index);
                });
              }
            });

            ref.componentInstance.close.subscribe((res) => {
              if (res == true) {
                file = null;
                ref.close();
                ref.afterClosed().subscribe((res) => {
                  innerFunction(index);
                });
              }
            });
          }
        }
      };

      innerFunction(-1);
    }
  }

  isImageByType(t) {
    if (t) {
      const _type = String(t).toLowerCase();

      if (_type.indexOf('png') != -1) {
        return true;
      }


      if (_type.indexOf('heic') != -1) {
        return true;
      }

      if (_type.indexOf('jpg') != -1) {
        return true;
      }

      if (_type.indexOf('jpeg') != -1) {
        return true;
      }

      if (_type.indexOf('jpeg') != -1) {
        return true;
      }

      if (_type.indexOf('jpeg') != -1) {
        return true;
      }

      if (_type.indexOf('gif') != -1) {
        return true;
      }

      if (_type.indexOf('tiff') != -1) {
        return true;
      }

      if (_type.indexOf('tif') != -1) {
        return true;
      }
    }

    return false;
  }

  verifyExtension(t) {
    if (t && this.allowedExtensions && this.allowedExtensions.length > 0) {
      let extension = mime.extension(t);

      if (t === 'text/javascript') {
        extension = 'js';
      }
      if (extension) {
        const _type = String(extension).toLowerCase();

        if (this.allowedExtensions.indexOf(_type) != -1) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }

    return true;
  }
}
