<div *ngIf="isPromoterOrAdmin == true">
  <div class="row clearfix">
    <app-operator-filter
      class="dash"
      id="part1"
      [selectDefaultAccount]="true"
      [selectDateRange]="selectedRange"
      [setAutoSelectMerchant]="true"
      [sendNull]="true"
      [selectDateRange]="true"
      [selectDateSecondRange]="false"
      [displayMerchant]="true"
      [displayDateFrom]="false"
      [displayDateTo]="false"
      [displayDentist]="false"
      [dateLabel]="'Requesting Date'"
      (search)="applySearch($event)"
    ></app-operator-filter>
  </div>

  <div class="row clearfix" *ngIf="isPromoterOrAdmin && merchantID && merchantID != 'none'">
    <app-appointment-list-calendar
      [hasBookingDate]="true"
      [merchantID]="merchantID"
      [dateFrom]="dateFrom"
      [dateTo]="dateTo"
    >
    </app-appointment-list-calendar>
  </div>

  <div class="row clearfix padded" *ngIf="!merchantID || merchantID == 'none'">
    <div class="pleaseChoose" @ngIfAnimation>
      <p>
        <strong>No Merchant Selected</strong>
      </p>
      <img class="character_warning" />
      <p>
        Choose your desired merchant from the drop-down above to view their Appointments
      </p>
    </div>
  </div>
</div>

<div class="row full-width clearfix" *ngIf="isPromoterOrAdmin == false">
  <div class="row clearfix">
    <app-operator-filter
      class="dash"
      id="part2"
      [selectDateRange]="selectedRange"
      [setAutoSelectMerchant]="true"
      [sendNull]="true"
      [selectDateRange]="true"
      [selectDateSecondRange]="false"
      [displayMerchant]="false"
      [displayDateFrom]="false"
      [displayDateTo]="false"
      [displayDentist]="false"
      [dateLabel]="'Requesting Date'"
      (search)="applySearch($event)"
    ></app-operator-filter>
  </div>

  <div class="row clearfix">
    <app-appointment-list-calendar [hasBookingDate]="true" [dateFrom]="dateFrom" [dateTo]="dateTo">
    </app-appointment-list-calendar>
  </div>
</div>
