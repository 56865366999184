<mat-card class="rel supplier-card brandView clearfix mb" *ngIf="table && isPromoterOrAdmin == true" @ngIfAnimation>
  <div class="card-header primary-gradient-img clearfix inModal rel">
    <div class="row clearfix content">
      <button class="btn-close btn-clear mat-button white" (click)="closeModal()">
        <mat-icon>clear</mat-icon>
      </button>
      <div class="row titleArea clearfix">
        <h2 class="summary-header rm-m">
          {{ table.ID }}
          <span class="subLabel" *ngIf="table['DepreciatedTableName']">{{ table['DepreciatedTableName'] }}</span>
          <span class="subLabel" *ngIf="table['TableRetrievalCost']"
          >Retrieval Cost: {{ table['TableRetrievalCost'] }}</span
          >
        </h2>
      </div>
    </div>
  </div>
  <mat-dialog-content class="stacked-card-view indOvf">
    <div class="row clearfix">
      <h3 class="sr-title rm-m sm-h3" *ngIf="table.Description">Description</h3>
      <p class="rm-m mb" *ngIf="table.Description" [innerHTML]="table.Description | safeHtml"></p>

      <h3 class="sr-title rm-m sm-h3" *ngIf="table.Comments">Comment</h3>
      <p class="rm-m mb" *ngIf="table.Comments" [innerHTML]="table.Comments | safeHtml"></p>

      <h3 class="sr-title rm-m sm-h3" *ngIf="table.Tags">Tags</h3>
      <p class="rm-m mb" *ngIf="table.Tags" [innerHTML]="table.Tags | safeHtml"></p>
    </div>

    <hr class="mt"/>
    <div class="flex clearfix full-width mt">
      <p class="clearfix small rm-m" *ngIf="table['CanCreate'] == '1'">
        <span class="chip-enable">Can Create</span>
      </p>
      <p class="clearfix small rm-m" *ngIf="table['CanCreate'] == '0'">
        <span class="chip-disable">Can Create</span>
      </p>

      <p class="clearfix small rm-m" *ngIf="table['CanModify'] == '1'">
        <span class="chip-enable">Can Modify</span>
      </p>
      <p class="clearfix small rm-m" *ngIf="table['CanModify'] == '0'">
        <span class="chip-disable">Can Modify</span>
      </p>

      <p class="clearfix small rm-m" *ngIf="table['CanQuery'] == '1'">
        <span class="chip-enable">Can Query</span>
      </p>
      <p class="clearfix small rm-m" *ngIf="table['CanQuery'] == '0'">
        <span class="chip-disable">Can Query</span>
      </p>

      <p class="clearfix small rm-m" *ngIf="table['CanDelete'] == '1'">
        <span class="chip-enable">Can Delete</span>
      </p>
      <p class="clearfix small rm-m" *ngIf="table['CanDelete'] == '0'">
        <span class="chip-disable">Can Delete</span>
      </p>

      <p class="clearfix small rm-m" *ngIf="table['CanSort'] == '1'">
        <span class="chip-enable">Can Sort</span>
      </p>
      <p class="clearfix small rm-m" *ngIf="table['CanSort'] == '0'">
        <span class="chip-disable">Can Sort</span>
      </p>

      <p class="clearfix small rm-m" *ngIf="table['IsFunction'] == '1'">
        <span class="chip-enable">Function</span>
      </p>
      <p class="clearfix small rm-m" *ngIf="table['IsFunction'] == '0'">
        <span class="chip-disable">Function</span>
      </p>
    </div>
  </mat-dialog-content>
</mat-card>
