<div class="card-header primary-gradient-img clearfix inModal">
  <button class="btn-close btn-clear mat-button" (click)="closeEvent()">
    <mat-icon>clear</mat-icon>
  </button>

  <div class="row titleArea clearfix">
    <mat-icon>cloud_upload</mat-icon>
    <h2 class="summary-header rm-m">
      Securely send your data
      <span class="subLabel">
        Transfer your existing {{ "KEYWORD.patient" | translate }} data securely for uploading
      </span>
    </h2>
  </div>
</div>

<mat-dialog-content class="uploader">
  <div class="row clearfix">
    <ng-container *ngIf="type == 'standard'">
      <ol class="add-mb">
        <li>
          <p class="bold rm-mb sr-title">Export your data</p>
          <p class="rm-mt">
            Export your {{ "KEYWORD.patient" | translate }} data into a .CSV or excel file from
            your {{"KEYWORD.practice" | translate}} management software.
          </p>
        </li>
        <li>
          <p class="bold rm-mb sr-title">Upload</p>
          <p class="rm-mt">
            Attach your {{ "KEYWORD.patient" | translate }} data and press 'Upload' to securely send your data to
            {{ "BRANDING.Brand Name" | translate | titlecase }}.
          </p>
        </li>
        <li>
          <p>
            Your data will be imported by one of our team members, and once done we will notify you via email that your
            data is available to use.
          </p>
        </li>
      </ol>
    </ng-container>

    <ng-container *ngIf="type == 'update'">
      <p>
        To update your existing {{ "KEYWORD.patient" | translate }} data, use the upload button below to import
        your {{ "KEYWORD.patient" | translate }} database in .csv or
        excel formats.
      </p>
      <p>
        Once received, {{ "BRANDING.Brand Name" | translate | titlecase }} will compare your updated records to your
        existing data,
        adding new {{ "KEYWORD.patient" | translate }}s to your account and updating new details on any existing
        {{ "KEYWORD.patient" | translate }}s. We'll then let you know via email when the process is complete.
      </p>
      <p class="small info">
        <strong>Please note:</strong> All care will be taken to ensure no existing {{ "KEYWORD.patient" | translate }}
        data is lost or damaged.
        However if you feel there is a problem after your upload, please get in touch with
        {{ "BRANDING.Brand Name" | translate | titlecase }} and a staff member will be happy to help.
      </p>
    </ng-container>
  </div>
  <div class="well">
    <div class="row clearfix">
      <app-file-uploader class="text-center clearfix" class="text-center" [uploadDirectly]="true" [isDragable]="true"
                         [limit]="1" [displayToaster]="false" (onCompleteAll)="onComplete($event)"></app-file-uploader>
    </div>
    <div class="row clearfix">
      <app-file-list class="compact" [hasExtraInfo]="false" [canEdit]="true" [canAction]="false" [files]="[fileID]"
                     (remove)="removeFile($event)"></app-file-list>
    </div>
  </div>

  <div class="row clearfix">
    <button class="pull left" [matMenuTriggerFor]="menu" mat-raised-button color="primary">Download guide</button>
    <button class="pull-right" (click)="save()" mat-raised-button color="accent">
      Upload
      <mat-icon>cloud_upload</mat-icon>
    </button>
  </div>

  <mat-menu #menu="matMenu">
    <button (click)="downloadGuide(1)" mat-menu-item>Export Guide - Generic</button>
    <button (click)="downloadGuide(2)" mat-menu-item>Export Guide - D4W</button>
    <button (click)="downloadGuide(3)" mat-menu-item>Export Guide - {{ "KEYWORD.practice" | translate | titlecase}}
      Works
    </button>
  </mat-menu>
</mat-dialog-content>
