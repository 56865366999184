import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { HelperService } from '../shared/helper.service';
import { SimplePageViewComponent } from '../simple-page-view/simple-page-view.component';

@Component({
  selector: 'app-simple-page-edit',
  templateUrl: './simple-page-edit.component.html',
  styleUrls: ['./simple-page-edit.component.css'],
})
export class SimplePageEditComponent implements OnInit {
  @Input() simplePage;
  @Output() deleted = new EventEmitter();
  data: any = {};

  content;

  options: any = {
    height: '400px',
  };

  constructor(private helperService: HelperService) {}

  ngOnInit() {
    if (this.simplePage && this.simplePage.content) {
      this.content = this.simplePage.content;
    }
  }

  update() {
    const payload = {
      type: this.simplePage.type,
      title: this.simplePage.title,
      content: this.simplePage.content,
    };
    this.helperService.editSimplePage(payload).subscribe((res) => {
      NotifyAppComponent.displayToast('success', 'The page has been successfully updated', 'Successfull update');
    });
  }

  preview() {
    RootAppComponent.dialog.open(SimplePageViewComponent, {
      data: this.simplePage.type,
      width: '70%',
      height: '70%',
    });
  }

  getContent(content) {
    this.simplePage.content = content;
  }

  openConfirmDialog() {
    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent);
    ref.componentInstance.onConfirm.subscribe((isYes) => {
      if (isYes) {
        this.helperService.removeSimplePage(this.simplePage.type).subscribe((res) => {
          if (res.success) {
            NotifyAppComponent.displayToast(
              'success',
              'Successfull operation',
              'Simple page has been successfully removed'
            );
            ref.close();
            this.deleted.emit({ success: true, page: this.simplePage });
          }
        });
      }
    });
  }
}
