import { NgModule } from '@angular/core';
import { HelperModule } from '../../core/helper/helper.module';
import { SharedModule } from '../../shared/shared.module';
import { ContactModule } from '../contact/contact.module';
import { CustomerProspectModule } from '../customer-prospect/customerProspect.module';
import { DefaultMessageModule } from '../default-message/default-message.module';
import { DentistModule } from '../dentist/dentist.module';
import { DrugsModule } from '../drugs/drugs.module';
import { InvitationModule } from '../invitation/invitation.module';
import { MerchantModule } from '../merchant/merchant.module';
import { AllergyCreateEditComponent } from './allergy-create-edit/allergy-create-edit.component';
import { AllergyListComponent } from './allergy-list/allergy-list.component';
import { AllergyViewComponent } from './allergy-view/allergy-view.component';
import { IllnessAllergyIconComponent } from './illness-allergy-icon/illness-allergy-icon.component';
import { IllnessCreateEditComponent } from './illness-create-edit/illness-create-edit.component';
import { IllnessListComponent } from './illness-list/illness-list.component';
import { IllnessViewComponent } from './illness-view/illness-view.component';
import { MedicalHistoryAcknowledgementComponent } from './medical-history-acknowledgement/medical-history-acknowledgement.component';
import { MedicalHistoryCardComponent } from './medical-history-card/medical-history-card.component';
import { MedicalHistoryCreateEditComponent } from './medical-history-create-edit/medical-history-create-edit.component';
import { MedicalHistoryInvitationCreate } from './medical-history-invitation-create/medical-history-invitation-create.component';
import { MedicalHistoryInvitationRedirectionComponent } from './medical-history-invitation-redirection/medical-history-invitation-redirection.component';
import { MedicalHistoryInvitationView } from './medical-history-invitation-view/medical-history-invitation-view.component';
import { MedicalHistoryLanguageModalComponent } from './medical-history-language-modal/medical-history-language-modal.component';
import { MedicalHistoryListCustomerComponent } from './medical-history-list-customer/medical-history-list-customer.component';
import { MedicalHistoryListGlobalComponent } from './medical-history-list-global/medical-history-list-global.component';
import { MedicalHistoryListOverViewComponent } from './medical-history-list-overview/medical-history-list-overview.component';
import { MedicalHistoryListComponent } from './medical-history-list/medical-history-list.component';
import { MedicalHistoryPrivacyModalComponent } from './medical-history-privacy-modal/medical-history-privacy-modal.component';
import { MedicalHistoryProgressModalComponent } from './medical-history-progress-modal/medical-history-progress-modal.component';
import { MedicalHistorySettingComponent } from './medical-history-setting/medical-history-setting.component';
import { MedicalHistoryViewComponent } from './medical-history-view/medical-history-view.component';
import { MedicalHistoryRoutingModule } from './shared/medical-history-routing.module';
import { MedicalHistoryService } from './shared/medical-history.service';

@NgModule({
  imports: [
    SharedModule,
    ContactModule,
    DentistModule,
    MerchantModule,
    InvitationModule,
    DefaultMessageModule,
    CustomerProspectModule,
    DrugsModule,
    HelperModule,
    MedicalHistoryRoutingModule,
  ],
  declarations: [
    MedicalHistoryCreateEditComponent,
    IllnessCreateEditComponent,
    IllnessListComponent,
    AllergyCreateEditComponent,
    AllergyListComponent,
    MedicalHistoryAcknowledgementComponent,
    MedicalHistoryCardComponent,
    MedicalHistoryProgressModalComponent,
    MedicalHistoryLanguageModalComponent,
    MedicalHistoryListComponent,
    MedicalHistoryListOverViewComponent,
    MedicalHistoryListGlobalComponent,
    MedicalHistoryViewComponent,
    IllnessAllergyIconComponent,
    MedicalHistoryInvitationCreate,
    MedicalHistoryInvitationView,
    MedicalHistoryInvitationRedirectionComponent,
    MedicalHistoryPrivacyModalComponent,
    MedicalHistorySettingComponent,
    AllergyViewComponent,
    IllnessViewComponent,
    MedicalHistoryListCustomerComponent,
  ],
  exports: [
    MedicalHistoryCreateEditComponent,
    IllnessCreateEditComponent,
    IllnessListComponent,
    AllergyCreateEditComponent,
    AllergyListComponent,
    MedicalHistoryAcknowledgementComponent,
    MedicalHistoryCardComponent,
    MedicalHistoryProgressModalComponent,
    MedicalHistoryLanguageModalComponent,
    MedicalHistoryListComponent,
    MedicalHistoryListOverViewComponent,
    MedicalHistoryListGlobalComponent,
    MedicalHistoryViewComponent,
    IllnessAllergyIconComponent,
    MedicalHistoryInvitationCreate,
    MedicalHistoryInvitationView,
    MedicalHistoryInvitationRedirectionComponent,
    MedicalHistoryPrivacyModalComponent,
    MedicalHistorySettingComponent,
    AllergyViewComponent,
    IllnessViewComponent,
    MedicalHistoryListCustomerComponent,
  ],
  providers: [MedicalHistoryService],
})
export class MedicalHistoryModule {}
