<div *ngIf="isPromoterOrAdmin == true">
  <div [hidden]="hideExtraComponents">
    <app-operator-filter
      class="dash"
      id="part1"
      [setAutoSelectMerchant]="true"
      [sendNull]="true"
      [selectDateRange]="selectedRange"
      [displayMerchant]="isDisplayMerchant"
      [displayDateFrom]="false"
      [displayDateTo]="false"
      [displayDentist]="false"
      (getMerchant)="getMerchant($event)"
      (getDateTo)="getDateTo($event)"
      (getDateFrom)="getDateFrom($event)"
      (search)="applySearch($event)"
    ></app-operator-filter>
  </div>

  <div class="row clearfix">
    <app-activity-log-list-global
      *ngIf="merchantID != 'none' && searchApplied == true"
      [merchantID]="merchantID"
      [dateFrom]="dateFrom"
      [dateTo]="dateTo"
      [groupFilter]="groupFilter"
      [displayToggleSwitch]="true"
      (groupFilterEvent)="getGroupFilter($event)"
    ></app-activity-log-list-global>
  </div>
  <div class="row clearfix">
    <div class="pleaseChoose" *ngIf="merchantID == 'none'" @ngIfAnimation>
      <p>
        <strong>Merchant No Selected</strong>
      </p>
      <img class="character_warning" />
      <p>Merchant Choose Desired</p>
    </div>
  </div>
</div>

<div class="row full-width clearfix" *ngIf="isPromoterOrAdmin == false">
  <div [hidden]="hideExtraComponents">
    <app-operator-filter
      class="dash"
      id="part1"
      [sendNull]="true"
      [selectDateRange]="selectedRange"
      [displayMerchant]="false"
      [displayDateFrom]="false"
      [displayDateTo]="false"
      [displayProduct]="false"
      [displayDentist]="false"
      (getMerchant)="getMerchant($event)"
      (getDateTo)="getDateTo($event)"
      (getDateFrom)="getDateFrom($event)"
      (search)="applySearch($event)"
    ></app-operator-filter>
  </div>

  <app-activity-log-list *ngIf="searchApplied == true" [dateFrom]="dateFrom" [dateTo]="dateTo"></app-activity-log-list>
</div>
