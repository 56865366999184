import { Component, EventEmitter, Inject, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { LookupService } from '../../../shared/services/lookup.service';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { MessageService } from '../../message/shared/message.service';
import { ProductService } from '../../product/shared/product.service';
import { ProductUtil } from '../../product/shared/productUtil.type';
import { DefaultMessageService } from '../shared/default-message.service';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { TagCreateEditComponent } from '../../tags/tag-create-edit/tag-create-edit.component';
import * as _ from 'lodash';
@Component({
  selector: 'message-template-create-edit',
  templateUrl: './message-template-create-edit.component.html',
  styleUrls: ['./message-template-create-edit.component.css'],
})
export class MessageTemplateCreateEditComponent implements OnInit {
  @Output()
  createdMessage = new EventEmitter();

  sessionType;
  close = new EventEmitter();
  closeModal = new EventEmitter();
  isPromoterOrAdmin = false;

  label;
  message;

  productUtil = new ProductUtil();
  cardID;
  card: any = {
    ID: '00000000000000000000',
    Salutation: 'Mr',
    FirstName: 'John',
    MiddleName: 'Al',
    Name: 'Doe',
    'mobiles.Number': '0061101010101',
    'phones.Number': '0062202020202',
    'emails.Email': 'john.doe@smileright.com.au',
    'addresses.Calculated': 'PO Box 7795 Cloisters Square 6850 Western Australia',
  };
  merchantID;
  merchant;
  contact;
  contactID;
  purposeCode = 'MerSMS';
  loadCard = false;
  loadMerchant = false;
  loadContact = false;
  loadPromoter = false;
  tableName = 'CustomersAndProspects';
  messageMode = 'sms';
  displayProductGroup = true;
  displayPurpose = true;
  messageTemplateID;
  promoter;
  productGroup = '';
  productGroupCodes: string[] = [];
  isDefault = false;
  isActive = true;
  content;
  saveAndApply = false;
  purposeCodes = [
    {
      Code: 'MerSMS',
      Label: 'SMS sent by Merchant',
    },
    {
      Code: 'PromSMS',
      Label: 'SMS sent by Promoter',
    },
  ];
  loadTag = false;
  tags = [];
  selectedTags = [];
  defaultSelectedTags = [];
  addTag = new EventEmitter();

  templateNature = 'practice';
  constructor(
    private authenticationService: AuthenticationService,
    private messageService: MessageService,
    private defaultMessageService: DefaultMessageService,
    private lookupService: LookupService,
    private productService: ProductService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.label) {
        this.label = data.label;
      }

      if (data.messageMode) {
        this.messageMode = data.messageMode;
      }
      if (data.message) {
        this.message = data.message;
      }
      if (data.purposeCode) {
        this.purposeCode = data.purposeCode;
      }

      if (data.productGroupCode) {
        this.productGroup = data.productGroupCode;
      }
      if (data.productGroup) {
        this.productGroup = data.productGroup;
      }
      if (data.tags && data.tags.length > 0 && Array(data.tags)) {
        this.defaultSelectedTags = JSON.parse(JSON.stringify(data['tags']));
        this.selectedTags = JSON.parse(JSON.stringify(data['tags']));
      }

      if (data.cardID) {
        this.cardID = data.cardID;
      }

      if (data.card) {
        this.card = data.card;
      }

      if (data.merchantID) {
        this.merchantID = data.merchantID;
      }

      if (data.merchant) {
        this.merchant = data.merchant;
      }

      if (data.contactID) {
        this.contactID = data.contactID;
      }
      if (data.contact) {
        this.contact = data.contact;
      }

      if (data.messageTemplateID) {
        this.messageTemplateID = data.messageTemplateID;
      }

      if (data.displayProductGroup != null) {
        this.displayProductGroup = data.displayProductGroup;
      }
      if (data.displayPurpose != null) {
        this.displayPurpose = data.displayPurpose;
      }

      if (data.saveAndApply != null) {
        this.saveAndApply = data.saveAndApply;
      }
    }
  }

  ngOnInit() {
    if (this.card && this.card.ID === '00000000000000000000') {
      this.authenticationService.getCurrentDentist().subscribe((r) => {
        if (r && r['Gender.Code'] && r['Gender.Code'] === 'F') {
          this.card.FirstName = 'Jane';
          this.card['emails.Email'] = 'jane.doe@smileright.com.au';
          if (r && r['Salutation']) {
            this.card['Salutation'] = r['Salutation'];
          } else {
            this.card['Salutation'] = 'Miss';
          }
        } else if (r && r['Salutation']) {
          this.card['Salutation'] = r['Salutation'];
        }

        this.init();
      });
    } else {
      this.init();
    }
  }

  init() {
    this.authenticationService.isPromoterOrAdmin().subscribe((isPromoterOrAdmin) => {
      this.isPromoterOrAdmin = isPromoterOrAdmin;
      this.authenticationService.getSessionType().subscribe((r) => {
        this.sessionType = r;

        if (this.displayProductGroup == true) {
          this.productService.getMerchantProductGroup({}, this.isPromoterOrAdmin).subscribe((productGroupCodes) => {
            if (productGroupCodes && productGroupCodes.length > 0) {
              this.productGroupCodes = productGroupCodes;
            }
          });
        }
        if (this.messageTemplateID) {
          this.defaultMessageService.getOneDefaultMessage(this.messageTemplateID, this.sessionType).subscribe((r) => {
            if (r) {
              if (r.Content) {
                this.message = r.Content;
              }

              if (r.Card_key) {
                this.merchantID = r.Card_key;
              }
              if (r.Label) {
                this.label = r.Label;
              }
              if (r['Purpose.Code']) {
                this.purposeCode = r['Purpose.Code'];
              }
              if (r['ProductGroup.Code']) {
                this.productGroup = r['ProductGroup.Code'];
              }

              if (r && r['tags'] && r['tags'].length > 0) {
                this.defaultSelectedTags = JSON.parse(JSON.stringify(r['tags']));
                this.selectedTags = JSON.parse(JSON.stringify(r['tags']));
              }

              if (r['Purpose.Code'] === 'PromSMS') {
                this.templateNature = 'promoter';
              } else {
                if (r.IsPromoterTemplate === '1') {
                  this.templateNature = 'brand';
                } else {
                  this.templateNature = 'practice';
                }
              }

              if (r.IsPromoterTemplate === '1') {
                this.isDefault = true;
              } else {
                this.isDefault = false;
              }
              if (r.Favourite === '1') {
                this.isActive = true;
              } else {
                this.isActive = false;
              }

              this.getTags();
              this.build();
            }
          });
        } else {
          this.getTags();

          this.build();
        }
      });
    });
  }

  build() {
    if (this.messageMode == 'sms') {
      if (this.isPromoterOrAdmin == true) {
        this.purposeCodes = [
          {
            Code: 'MerSMS',
            Label: 'SMS sent by Merchant',
          },
          {
            Code: 'PromSMS',
            Label: 'SMS sent by Promoter',
          },
        ];
      } else {
        this.purposeCodes = [
          {
            Code: 'MerSMS',
            Label: 'SMS sent by Merchant',
          },
        ];
      }

      this.setup();
    } else {
      this.lookupService.getLookup('codeLookup', 'PrsMsg_Purpose').subscribe((res) => {
        if (res) {
          if (this.isPromoterOrAdmin != true) {
            let index = res.findIndex((item) => {
              if (item && item.Code && item.Code === 'PromSMS') {
                return true;
              }

              return false;
            });

            if (index != -1) {
              res.splice(index, 1);
            }
          }

          if (this.messageMode == 'default') {
            let index = res.findIndex((item) => {
              if (item && item.Code && item.Code === 'MerSMS') {
                return true;
              }

              return false;
            });

            if (index != -1) {
              res.splice(index, 1);
            }
          }
          this.purposeCodes = res;
          this.setup();
        }
      });
    }
  }

  setup() {
    if (this.card && this.card.ID) {
      this.loadCard = true;
    } else if (this.cardID) {
      this.messageService.getCardDetails(this.cardID, this.tableName, {}, this.sessionType).subscribe((r) => {
        if (r) {
          this.card = r;
          this.loadCard = true;
        }
      });
    } else {
      this.loadCard = true;
    }

    if (this.contact && this.contact.ID) {
      this.loadContact = true;
    } else if (this.contactID) {
      this.messageService.getCardDetails(this.contactID, 'Contact', {}, this.sessionType).subscribe((r) => {
        if (r) {
          this.contact = r;
          this.loadContact = true;
        }
      });
    } else {
      this.authenticationService.getCurrentDentist().subscribe((r) => {
        if (r) {
          this.contact = r;
          this.loadContact = true;
        }
      });
    }
    if (this.merchant && this.merchant.ID) {
      this.loadMerchant = true;
    } else if (this.merchantID && this.isPromoterOrAdmin == true) {
      this.messageService.getCardDetails(this.merchantID, 'Merchant', {}, this.sessionType).subscribe((r) => {
        if (r) {
          this.merchant = r;
          this.loadMerchant = true;
        }
      });
    } else {
      this.authenticationService.getCurrentPractice().subscribe((r) => {
        if (r) {
          this.merchant = r;
          this.loadMerchant = true;
        }
      });
    }

    this.authenticationService.getPromoter().subscribe((r) => {
      if (r && r.data) {
        this.promoter = r.data;
        this.loadPromoter = true;
      } else if (r) {
        this.promoter = r;
        this.loadPromoter = true;
      }
    });
  }

  getResultEvent(e) {
    if (e && e.message) {
      this.content = e.message;
    }
  }

  save(apply = false) {
    let payload: any = {
      label: this.label,
      merchantID: this.merchantID,
      content: this.content,
      isDefault: this.isDefault,
      tags: this.selectedTags,
    };

    if (this.messageTemplateID && this.selectedTags.length <= 0) {
      payload.tags = 'none';
    }

    if (this.messageMode === 'sms') {
      if (this.templateNature === 'promoter') {
        payload.purposeCode = 'PromSMS';
        payload.isDefault = true;
      } else if (this.templateNature === 'brand') {
        payload.purposeCode = 'MerSMS';
        payload.isDefault = true;
      } else {
        payload.purposeCode = 'MerSMS';
        payload.isDefault = false;
      }
    }

    if (this.messageTemplateID) {
      payload.active = this.isActive;
      this.messageService.editMessageTemplate(this.messageTemplateID, payload, this.sessionType).subscribe((r) => {
        if (r) {
          NotifyAppComponent.displayToast('success', 'Successfull', 'Message Template is edited');

          r.apply = apply;

          if (r['Purpose.Code'] === 'PromSMS') {
            r['IsPromoterTemplate'] = '2';
          }
          this.createdMessage.emit(r);
        }
      });
    } else {
      payload.purposeCode = this.purposeCode;
      if (this.purposeCode === 'MerInv' && this.productGroup && this.productGroup != '') {
        payload.productGroupCode = this.productGroup;
      }
      this.messageService.createMessageTemplate(payload, this.sessionType).subscribe((r) => {
        if (r) {
          NotifyAppComponent.displayToast('success', 'Successfull', 'Message Template is created');

          r.apply = apply;

          if (r['Purpose.Code'] === 'PromSMS') {
            r['IsPromoterTemplate'] = '2';
          }
          this.createdMessage.emit(r);
        }
      });
    }
  }

  closeEvent() {
    this.close.emit(true);
    this.closeModal.emit(true);
  }

  createNewTag() {
    let ref = RootAppComponent.dialog.open(TagCreateEditComponent, {
      data: {
        merchantID: this.merchantID,
      },

      width: '600px',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });

    ref.componentInstance.getResult.subscribe((res) => {
      if (res && res.Label) {
        if (
          (!this.tags || (this.tags && this.tags.length <= 0)) &&
          (!this.defaultSelectedTags || (this.defaultSelectedTags && this.defaultSelectedTags.length <= 0))
        ) {
          this.defaultSelectedTags = JSON.parse(JSON.stringify([res.Label]));
          this.tags = JSON.parse(JSON.stringify([res.Label]));
        } else {
          if (!this.tags || (this.tags && this.tags.length <= 0)) {
            this.tags.push(res.Label);
          }
          this.addTag.emit(res.Label);
        }
        ref.close();
      }
    });
  }

  getSelectedTags(e) {
    this.selectedTags = e || [];
  }

  getTags() {
    this.loadTag = false;
    let pTag = {
      isPromoter: false,
      merchantID: this.merchantID,
      fields: 'Label',
    };

    this.defaultMessageService.getTagsList(pTag, this.sessionType).subscribe((res) => {
      if (res) {
        this.tags = _.map(res, (item) => {
          if (item && item.Label) return item.Label;

          return null;
        });
      }

      this.loadTag = true;
    });
  }
}
