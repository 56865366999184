import { animate, group, keyframes, query, stagger, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ColorPalette } from '../../../shared/types/color-palette';
import { AuthenticationService } from '../../authentication/shared/authentication.service';
import { ModuleService } from '../shared/module.service';

import * as _ from 'lodash';

@Component({
  selector: 'app-module-view',
  templateUrl: './module-view.component.html',
  styleUrls: ['./module-view.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.animate', style({ opacity: 0 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.4s ease-out',
              keyframes([
                style({ opacity: 0, offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.animate', style({ opacity: 1 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.4s ease-in',
              keyframes([
                style({ opacity: 1, offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),

    trigger('slideInOut', [
      state('in', style({ height: '*', opacity: 0 })),
      transition(':leave', [
        style({ height: '*', opacity: 1 }),

        group([animate(300, style({ height: 0 })), animate('200ms ease-in-out', style({ opacity: '0' }))]),
      ]),
      transition(':enter', [
        style({ height: '0', opacity: 0 }),

        group([animate(300, style({ height: '*' })), animate('400ms ease-in-out', style({ opacity: '1' }))]),
      ]),
    ]),
  ],
})
export class ModuleViewComponent implements OnInit {
  @Input()
  moduleID;
  @Input()
  moduleCode;

  @Output()
  close = new EventEmitter();
  module;
  loadingPost;
  postPicture;
  pinchZoomProperties = [
    {
      wheel: true,
      autoHeight: true,
    },
  ];
  postPictureThumbnail;
  postCover;
  sessionType;
  postCoverURL;
  isImageError = false;

  color;
  colorIcon;

  colorSecond;
  @Input()
  viewType = 'details';

  EPDocumentID;
  htmlContent;

  marketingButton = false;
  displaySubscribeButton = true;
  subscriptionPlans = [];

  isModuleOwned;

  constructor(
    private moduleService: ModuleService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private domSanitizer: DomSanitizer,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.moduleID) {
        this.moduleID = data.moduleID;
      }
      if (data.moduleCode) {
        this.moduleCode = data.moduleCode;
      }
      if (data.displaySubscribeButton != null) {
        this.displaySubscribeButton = data.displaySubscribeButton;
      }
      if (data.colorSecond) {
        this.colorSecond = data.colorSecond;
      }
      if (data.color) {
        this.color = data.color;
        this.colorIcon = data.color;
      }

      if (data.viewType) {
        this.viewType = data.viewType;
      }
    }
  }

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((sessionType) => {
      this.sessionType = sessionType;

      if (this.moduleID) {
        this.moduleService.getModuleDetails(this.moduleID, {}, this.sessionType).subscribe((res) => {
          if (res && res.ID) {
            this.module = res;

            if (this.module && this.module.ID) {
              this.isModuleOwned = false;
              this.authenticationService.isModuleOwn(this.module.Code).subscribe((res) => {
                this.isModuleOwned = res;

                if (this.isModuleOwned != true && this.displaySubscribeButton == true) {
                  this.moduleService
                    .getPromoterPlanList(
                      {
                        moduleCode: this.module.Code,
                        isActive: true,
                        isPublic: true,
                        orderBy: '-Is_Public,Price',
                      },
                      this.sessionType
                    )
                    .subscribe((res) => {
                      if (res && res.length > 0) {
                        this.subscriptionPlans = res;
                      }
                    });
                }
              });

              this.setup();
            }
          }
        });
      } else if (this.moduleCode) {
        this.moduleService.getModuleDetailsByCode(this.moduleCode, {}, this.sessionType).subscribe((res) => {
          if (res && res.ID) {
            this.module = res;
            if (this.module && this.module.ID) {
              this.isModuleOwned = false;
              this.authenticationService.isModuleOwn(this.module.Code).subscribe((res) => {
                this.isModuleOwned = res;

                if (this.isModuleOwned != true && this.displaySubscribeButton == true) {
                  this.moduleService
                    .getPromoterPlanList(
                      {
                        moduleCode: this.module.Code,
                        isActive: true,
                        isPublic: true,
                        orderBy: '-Is_Public,Price',
                      },
                      this.sessionType
                    )
                    .subscribe((res) => {
                      if (res && res.length > 0) {
                        this.subscriptionPlans = res;
                      }
                    });
                }
              });
              this.setup();
            }
          }
        });
      }
    });
  }

  onColorPickerClose(color: string): void {
    if (color) {
      this.color = color;

      const v = new ColorPalette(color);
      const palette = v.getPalette();

      if (palette && palette.length > 0) {
        const index = palette.findIndex((item) => {
          if (item && item.name == 800) {
            return true;
          } else {
            return false;
          }
        });

        if (palette[index] && palette[index]['hex']) {
          this.colorSecond = palette[index]['hex'];
        }
      }
    }
  }

  onColorPickerCloseDefault(color: string): void {
    if (color) {
      this.color = color;

      const v = new ColorPalette(color);
      const palette = v.getPalette();

      if (palette && palette.length > 0) {
        const index = palette.findIndex((item) => {
          if (item && item.name == 800) {
            return true;
          } else {
            return false;
          }
        });

        if (palette[index] && palette[index]['hex']) {
          return palette[index]['hex'];
        }
      }
    }
  }

  setup() {
    if (this.module && this.module.ID) {
      this.isModuleOwned = false;
      this.authenticationService.isModuleOwn(this.module.Code).subscribe((res) => {
        this.isModuleOwned = res;
      });

      this.moduleID = this.module.ID;
      if (this.module && this.module.Marketing_Description) {
        this.module.marketingDescriptions = this.module.Marketing_Description.split('||');
      }
      if (this.module['Marketing_Html_Document_Key'] && this.module['Marketing_Conent_Type.Code'] === 'Document') {
        this.EPDocumentID = this.module['Marketing_Html_Document_Key'];
        this.marketingButton = true;
        this.viewType = 'details';
      } else if (
        this.module['Marketing_Conent_Type.Code'] === 'Editor' ||
        this.module['Marketing_Conent_Type.Code'] === 'Raw'
      ) {
        this.moduleService.getMarketingHTMLContent(this.module.ID).subscribe((_res) => {
          if (_res && _res.ID) {
            if (_res['Marketing_Html_Content']) {
              this.htmlContent = _res['Marketing_Html_Content'];
            }
            this.marketingButton = true;
            this.viewType = 'details';
          }
        });
      }

      if (!this.color) {
        if (this.module['Status.Code'] === 'EnableForAll') {
          this.color = '#409993';
          this.colorIcon = '#409993';
          this.colorSecond = '#f35900';
        } else if (this.module['Status.Code'] === 'DisableForAll') {
          this.color = '#646464';
          this.colorIcon = '#646464';

          this.colorSecond = '#f35900';
        } else if (this.module['Status.Code'] === 'Beta') {
          this.color = '#3A66A7';
          this.colorIcon = '#3A66A7';

          this.colorSecond = '#f35900';
        }
      }
      if (this.color && !this.colorSecond) {
        this.onColorPickerClose(this.color);
      }

      this.postPictureThumbnail = this.moduleService.getThumbnailImageStreamLink(
        this.moduleID,
        this.module.LastModified
      );
      this.postCover = this.moduleService.getCoverImageStreamLink(this.moduleID, this.module.LastModified);

      this.postCoverURL = this.domSanitizer.bypassSecurityTrustStyle('url(' + this.postCover + ')');
    }
  }

  openWiki(url) {
    if (url) {
      window.open(url, '_blank');
    }
  }

  closeEvent() {
    this.close.emit(true);
  }

  isIndexOf(s, attribute) {
    if (s && attribute && s.indexOf(attribute) != -1) {
      return true;
    }

    return false;
  }

  onLoad() {
    this.isImageError = false;
  }

  onError() {
    this.isImageError = true;
  }

  viewTypeEvent(e) {
    this.viewType = e;
  }

  viewModuleCard() {
    this.viewType = 'cards';
  }

  getSelect(id) {
    if (id) {
      this.closeEvent();
      this.router.navigate([
        '/merchant',
        {
          outlets: {
            page: ['subscription-plan-view', id],
          },
        },
      ]);
    }
  }

  compare() {
    if (this.subscriptionPlans && this.subscriptionPlans.length > 0) {
      const IDs = _.map(this.subscriptionPlans, (item) => {
        return item.ID;
      });

      this.closeEvent();

      this.router.navigate([
        '/merchant',
        {
          outlets: {
            page: ['subscription-plan-overview', IDs.join(',')],
          },
        },
      ]);
    }
  }
}
