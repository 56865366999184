import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-product-document-library-create',
  templateUrl: './product-document-library-create.component.html',
  styleUrls: ['./product-document-library-create.component.css'],
})
export class ProductDocumentLibraryCreateComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
