export class CustomerProspectUtilClass {
  status = {
    all: [
      'viewed',
      'notViewed',
      'intendProceed',
      'changed',
      'notProceeding',
      'proceedingCash',
      'expired',
      'active',
      'approved',
      // 'completed',
      'submitted',
      'commenced',
      'deferred',
      'notFinanced',
      'cold',
      'appliedFinance',
      'referred',
      'declined',
    ],
    allInvitation: [
      'viewed',
      'notViewed',
      'intendProceed',
      'changed',
      'notProceeding',
      'proceedingCash',
      'expired',
      'deferred',
      'notFinanced',
      'appliedFinance',
    ],
    won: ['proceedingCash', 'intendProceed', 'appliedFinance'],
    lost: ['notProceeding', 'expired'],
    maybe: ['viewed', 'changed', 'notViewed', 'deferred', 'notFinanced'],
    contract: [
      'active',
      'approved',
      // 'completed',
      'submitted',
      'referred',
      'declined',
    ],
    contractWon: [
      'active',
      'approved',
      'submitted',
      // 'completed',
    ],
    contractLost: ['declined'],
    contractMaybe: ['referred'],
  };

  constructor() {}

  ngOnInit() {}

  getLabel(code) {
    if (!code) {
      return 'undefined';
    }

    const labelObject = {
      prospect: 'Patients',
      customer: 'Financed',
    };

    if (labelObject[code]) {
      return labelObject[code];
    } else {
      return code;
    }
  }

  getTitle(code) {
    if (!code) {
      return 'undefined';
    }

    const labelObject = {
      prospect: 'Yet to receive an invitation',
      customer: 'Financing with Smile Right',
    };

    if (labelObject[code]) {
      return labelObject[code];
    } else {
      return code;
    }
  }
}
