<div class="container">
  <div class="promoter-bar clearfix" *ngIf="dentistSignUp" @simpleFadeAnimation>
    <!-- <button mat-stroked-button class="pull-right"
    [routerLink]="['/merchant/', {outlets: {'page': ['performance-merchant']}}]">View Merchant</button>
 -->

    <h3 class="sr-title padded">{{ "KEYWORD.practitioner" | translate | titlecase }} Sign-Up</h3>
  </div>

  <div class="row flex flex-wrap">
    <div class="quick-actions">
      <mat-card class="quick-actions-card card" *ngIf="dentistSignUp" @simpleFadeAnimation>
        <div class="row clearfix">
          <h3 class="sm-h3 sr-title rm-mb rm-mt"><i class="fas fa-money" aria-hidden="true"> </i> Sign-Ups</h3>
        </div>
        <div class="row clearfix flex-grow rel">
          <div class="row clearfix chart">
            <div class="full-width text-center" *ngIf="loadSignup != true">
              <div class="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>

            <app-chart-input
              *ngIf="loadSignup == true"
              [showLegend]="false"
              [autoScale]="true"
              [showGridLines]="true"
              [showLabel]="false"
              [data]="dentistSignUp"
              [chartType]="'line'"
              [showXAxis]="true"
              [showYAxis]="true"
              [dataNameAttribute]="'name'"
              [showXAxisLabel]="false"
              [showYAxisLabel]="true"
              [yAxisLabel]="('KEYWORD.practice' | translate | titlecase) + 's'"
              [title]="''"
            >
            </app-chart-input>
          </div>
        </div>
        <p class="small">
          Number of <span [style.color]="chartColors[0]">{{ "KEYWORD.practitioner" | translate | titlecase}}
          s Signed Up</span>
        </p>
        <app-date-range-picker
          [sendOnInit]="false"
          [showAllTime]="false"
          [selectedRange]="'days90'"
          (getRange)="getRangeDentistSignedUp($event)"
        ></app-date-range-picker>
      </mat-card>

      <mat-card class="quick-actions-card card" *ngIf="practicesOnPlatform" @simpleFadeAnimation>
        <div class="row clearfix">
          <h3 class="sm-h3 sr-title rm-mb rm-mt">
            <i class="fas fa-money" aria-hidden="true"> </i> {{ "KEYWORD.practice" | translate | titlecase }}s on
            Platform
          </h3>
        </div>
        <div class="row clearfix flex-grow rel">
          <div class="row clearfix chart">
            <div class="full-width text-center" *ngIf="loadPractice != true">
              <div class="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>

            <app-chart-input
              *ngIf="loadPractice == true"
              [showLegend]="false"
              [autoScale]="true"
              [showGridLines]="true"
              [showLabel]="false"
              [data]="practicesOnPlatform"
              [chartType]="'line'"
              [showXAxis]="true"
              [showYAxis]="true"
              [dataNameAttribute]="'name'"
              [showXAxisLabel]="false"
              [showYAxisLabel]="true"
              [yAxisLabel]="('KEYWORD.practice' | translate | titlecase) + 's'"
              [title]="''"
            >
            </app-chart-input>
          </div>
        </div>
        <p class="small">
          Number of
          <span [style.color]="chartColors[0]">{{ "KEYWORD.practice" | translate | titlecase }}
            s actively on Platform</span>
        </p>
        <app-date-range-picker
          [sendOnInit]="false"
          [showAllTime]="false"
          [selectedRange]="'days90'"
          (getRange)="getRangePracticesOnPlatform($event)"
        ></app-date-range-picker>
      </mat-card>

      <mat-card class="quick-actions-card card" *ngIf="engagementPercentage">
        <div class="row clearfix">
          <h3 class="sm-h3 sr-title rm-mb rm-mt"><i class="fas fa-money" aria-hidden="true"> </i> Engagement</h3>
        </div>
        <div class="row clearfix flex-grow rel">
          <div class="row clearfix chart">
            <div class="full-width text-center" *ngIf="loadEngagement != true">
              <div class="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>

            <app-chart-input
              *ngIf="loadEngagement == true"
              [showLegend]="false"
              [legendPosition]="'right'"
              [autoScale]="true"
              [showGridLines]="true"
              [showLabel]="false"
              [data]="engagementPercentage"
              [chartType]="'line'"
              [showXAxis]="true"
              [showYAxis]="true"
              [dataNameAttribute]="'name'"
              [showXAxisLabel]="false"
              [showYAxisLabel]="true"
              [yAxisLabel]="'Invitations'"
              [title]="''"
            >
            </app-chart-input>
          </div>
        </div>
        <p class="small">Number of <span [style.color]="chartColors[0]">Webinars</span></p>
        <app-date-range-picker
          [sendOnInit]="false"
          [showAllTime]="false"
          [selectedRange]="'days90'"
          (getRange)="getRangeEngagementPercentage($event)"
        ></app-date-range-picker>
      </mat-card>
    </div>
  </div>

  <h3 class="sr-title padded" *ngIf="invitesSentOpened" @simpleFadeAnimation>Finance Products</h3>

  <!-- Label primary / accent / warn colours should inherit from chart colours -->

  <div class="row flex flex-wrap">
    <div class="quick-actions">
      <!-- invitations send opened -->
      <mat-card class="quick-actions-card card" *ngIf="invitesSentOpened" @simpleFadeAnimation>
        <div class="row clearfix">
          <h3 class="sm-h3 sr-title rm-mb rm-mt">
            <i class="fas fa-money" aria-hidden="true"> </i> Invitations sent vs Opens
          </h3>
        </div>
        <div class="row clearfix flex-grow rel">
          <div class="row clearfix chart">
            <div class="full-width text-center" *ngIf="loadSentOpen != true">
              <div class="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>

            <app-chart-input
              *ngIf="loadSentOpen == true"
              [showLegend]="false"
              [autoScale]="true"
              [showGridLines]="true"
              [showLabel]="false"
              [data]="invitesSentOpened"
              [chartType]="'line'"
              [showXAxis]="true"
              [showYAxis]="true"
              [dataNameAttribute]="'name'"
              [showXAxisLabel]="false"
              [showYAxisLabel]="true"
              [yAxisLabel]="'Invitations'"
              [title]="''"
            >
            </app-chart-input>
          </div>
        </div>
        <p class="small">
          Number of <span [style.color]="chartColors[0]">Invites Sent</span> vs
          <span [style.color]="chartColors[1]">Invited Opened </span>
        </p>
        <app-date-range-picker
          [sendOnInit]="false"
          [showAllTime]="false"
          [selectedRange]="'days90'"
          (getRange)="getRangeInvitesSentOpened($event)"
        ></app-date-range-picker>
      </mat-card>

      <!-- applications commenced -->
      <mat-card class="quick-actions-card card" *ngIf="applications" @simpleFadeAnimation>
        <div class="row clearfix">
          <h3 class="sm-h3 sr-title rm-mb rm-mt"><i class="fas fa-money" aria-hidden="true"> </i> Applications</h3>
        </div>
        <div class="row clearfix flex-grow rel">
          <div class="row clearfix chart">
            <div class="full-width text-center" *ngIf="loadApplication != true">
              <div class="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>

            <app-chart-input
              *ngIf="loadApplication == true"
              [showLegend]="false"
              [legendPosition]="'right'"
              [autoScale]="true"
              [showGridLines]="true"
              [showLabel]="false"
              [data]="applications"
              [chartType]="'line'"
              [showXAxis]="true"
              [showYAxis]="true"
              [dataNameAttribute]="'name'"
              [showXAxisLabel]="false"
              [showYAxisLabel]="true"
              [yAxisLabel]="'Applications'"
              [title]="''"
            >
            </app-chart-input>
          </div>
        </div>
        <p class="small">
          Number of <span [style.color]="chartColors[0]">Applications Commenced</span>,
          <span [style.color]="chartColors[1]">Approved</span> and <span [style.color]="chartColors[2]">Activated</span>
        </p>
        <app-date-range-picker
          [sendOnInit]="false"
          [showAllTime]="false"
          [selectedRange]="'days90'"
          (getRange)="getRangeApplications($event)"
        ></app-date-range-picker>
      </mat-card>

      <!-- loans vs drawdown -->
      <mat-card class="quick-actions-card card" *ngIf="loansVsDrawdowns" @simpleFadeAnimation>
        <div class="row clearfix">
          <h3 class="sm-h3 sr-title rm-mb rm-mt">
            <i class="fas fa-money" aria-hidden="true"> </i> Loans vs Drawdowns
          </h3>
        </div>
        <div class="row clearfix flex-grow rel">
          <div class="row clearfix chart">
            <div class="full-width text-center" *ngIf="loadSettlement != true">
              <div class="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>

            <app-chart-input
              *ngIf="loadSettlement == true"
              [showLegend]="false"
              [legendPosition]="'right'"
              [autoScale]="true"
              [showGridLines]="true"
              [showLabel]="false"
              [data]="loansVsDrawdowns"
              [chartType]="'line'"
              [showXAxis]="true"
              [showYAxis]="true"
              [dataNameAttribute]="'name'"
              [showXAxisLabel]="false"
              [showYAxisLabel]="true"
              [yAxisLabel]="'Value ($)'"
              [title]="''"
            >
            </app-chart-input>
          </div>
        </div>
        <p class="small">
          <span [style.color]="chartColors[0]">Value of Loans Activated</span> and
          <span [style.color]="chartColors[1]">Draw Downs Disbursed</span>
        </p>
        <app-date-range-picker
          [sendOnInit]="false"
          [showAllTime]="false"
          [selectedRange]="'days90'"
          (getRange)="getRangeLoansVsDrawdowns($event)"
        ></app-date-range-picker>
      </mat-card>
    </div>
  </div>

  <h3 class="sr-title padded" *ngIf="avgCreditScore" @simpleFadeAnimation>Portfolio</h3>

  <div class="row flex flex-wrap">
    <div class="quick-actions">
      <mat-card class="quick-actions-card card" *ngIf="avgCreditScore" @simpleFadeAnimation>
        <div class="row clearfix">
          <h3 class="sm-h3 sr-title rm-mb rm-mt">
            <i class="fas fa-money" aria-hidden="true"> </i> Average Credit Scores
          </h3>
        </div>
        <div class="row clearfix flex-grow rel">
          <div class="row clearfix chart">
            <div class="full-width text-center" *ngIf="loadCredit != true">
              <div class="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>

            <app-chart-input
              *ngIf="loadCredit == true"
              [showLegend]="false"
              [legendPosition]="'right'"
              [autoScale]="true"
              [showGridLines]="true"
              [showLabel]="false"
              [data]="avgCreditScore"
              [chartType]="'line'"
              [showXAxis]="true"
              [showYAxis]="true"
              [dataNameAttribute]="'name'"
              [showXAxisLabel]="false"
              [showYAxisLabel]="true"
              [yAxisLabel]="'Score'"
              [showRefLabels]="false"
              [title]="''"
            >
            </app-chart-input>
          </div>
        </div>
        <p class="small">
          Average <span [style.color]="chartColors[0]">credit score</span> of applicants and
          <span [style.color]="chartColors[1]">Trend</span>
        </p>
        <app-date-range-picker
          [sendOnInit]="false"
          [showAllTime]="false"
          [selectedRange]="'days90'"
          (getRange)="getRangeAvgCreditScore($event)"
        ></app-date-range-picker>
      </mat-card>

      <mat-card class="quick-actions-card card" *ngIf="avgLoanValue" @simpleFadeAnimation>
        <div class="row clearfix">
          <h3 class="sm-h3 sr-title rm-mb rm-mt">
            <i class="fas fa-money" aria-hidden="true"> </i> Average Loan Value
          </h3>
        </div>
        <div class="row clearfix flex-grow rel">
          <div class="row clearfix chart">
            <div class="full-width text-center" *ngIf="loadLoan != true">
              <div class="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>

            <app-chart-input
              *ngIf="loadLoan == true"
              [showLegend]="false"
              [legendPosition]="'right'"
              [autoScale]="true"
              [showGridLines]="true"
              [showLabel]="false"
              [data]="avgLoanValue"
              [chartType]="'line'"
              [showXAxis]="true"
              [showYAxis]="true"
              [dataNameAttribute]="'name'"
              [showXAxisLabel]="false"
              [showYAxisLabel]="true"
              [yAxisLabel]="'Value ($)'"
              [trimXAxisTicks]="0"
              [title]="''"
            >
            </app-chart-input>
          </div>
        </div>
        <p class="small">
          Average <span [style.color]="chartColors[0]">value of loan applications</span> and
          <span [style.color]="chartColors[1]">Trend</span>
        </p>
        <app-date-range-picker
          [sendOnInit]="false"
          [showAllTime]="false"
          [selectedRange]="'days90'"
          (getRange)="getRangeAvgLoanValue($event)"
        ></app-date-range-picker>
      </mat-card>

      <mat-card class="quick-actions-card card" *ngIf="portfolioVsAccounts" @simpleFadeAnimation>
        <div class="row clearfix">
          <h3 class="sm-h3 sr-title rm-mb rm-mt">
            <i class="fas fa-money" aria-hidden="true"> </i> Portfolio vs Accounts
          </h3>
        </div>
        <div class="row clearfix flex-grow rel">
          <div class="row clearfix chart">
            <div class="full-width text-center" *ngIf="loadAccount != true">
              <div class="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>

            <app-double-axis-chart
              *ngIf="loadAccount == true"
              [view]="view"
              [scheme]="chartColorScheme"
              [results]="portfolioVsAccounts"
              [gradient]="false"
              [xAxis]="true"
              [yAxis]="true"
              [legend]="false"
              [showXAxisLabel]="false"
              [yMainAxisShowLabel]="true"
              [ySecondaryAxisShowLabel]="true"
              [xAxisLabel]="xAxisLabel"
              [yMainAxisLabel]="multiyAxisLabel"
              [ySecondaryAxisLabel]="multiyAxisLabel2"
              [autoScale]="autoScale"
              [timeline]="false"
              [tooltipDisabled]="tooltipDisabled"
              [animations]="animations"
            >
            </app-double-axis-chart>
          </div>
        </div>
        <p class="small">
          Current <span [style.color]="chartColors[0]">Portfolio Balance</span> and Value of
          <span [style.color]="chartColors[1]">Accounts in Arrears</span>
        </p>
        <app-date-range-picker
          [sendOnInit]="false"
          [showAllTime]="false"
          [selectedRange]="'days90'"
          (getRange)="getRangePortfolioVsAccounts($event)"
        ></app-date-range-picker>
      </mat-card>
    </div>
  </div>

  <!-- <h3 class="sr-title padded">Marketing</h3>

  <div class="row  flex flex-wrap">

    <div class="quick-actions ">


      <mat-card class="quick-actions-card card" *ngIf="lineData" @simpleFadeAnimation>
        <div class="row clearfix">
          <h3 class="sm-h3 sr-title rm-mb rm-mt"><i class="fas fa-money" aria-hidden="true"> </i> Marketing Emails
          </h3>
        </div>
        <div class="row clearfix flex-grow rel">
          <div class="row clearfix chart">
            <app-chart-input [showLegend]="false" [legendPosition]="'right'" [autoScale]="true" [showGridLines]="true" [showLabel]="false"
              [data]="invitesSentOpened" [chartType]="'line'" [showXAxis]="true" [showYAxis]="true" [dataNameAttribute]="'name'"
              [showXAxisLabel]="false" [showYAxisLabel]="true" [yAxisLabel]="'Emails'" [showRefLabels]="false" [title]="''">
            </app-chart-input>
          </div>
        </div>
        <p class="small">Number of emails <span [style.color]="chartColors[0]">Sent</span> and <span [style.color]="chartColors[1]">Opened</span></p>
      </mat-card>




    </div>

  </div> -->
</div>

<!-- Label primary / accent / warn colours should inherit from chart colours -->

<!-- <div class="quick-actions vertical"> -->

<!-- applications commenced -->

<!-- <app-chart-input [showLegend]="false" [legendPosition]="'right'" [autoScale]="true" [showGridLines]="true"
        [showLabel]="false" [data]="applications" [chartType]="'barVerticalStacked'" [showXAxis]="true" [showYAxis]="true"
        [dataNameAttribute]="'name'" [showXAxisLabel]="false" [showYAxisLabel]="true"
        [yAxisLabel]="applications" [title]="''">
      </app-chart-input> -->

<!--
    <mat-card class="quick-actions-card card" @simpleFadeAnimation *ngIf="portfolioVsAccounts">
      <div class="row clearfix">
        <h3 class="sm-h3 sr-title rm-mb rm-mt"><i class="fas fa-money" aria-hidden="true"> </i> Portfolio vs Accounts
        </h3>
      </div>
      <div class="row clearfix flex-grow rel">
        <div class="row clearfix chart">
          <app-double-axis-chart [view]="view" [scheme]="chartColorScheme" [results]="portfolioVsAccounts"
            [gradient]="gradient" [xAxis]="false" [yAxis]="true" [legend]="false" [showXAxisLabel]="false"
            [yMainAxisShowLabel]="false" [ySecondaryAxisShowLabel]="false" [xAxisLabel]="xAxisLabel"
            [yMainAxisLabel]="multiyAxisLabel" [ySecondaryAxisLabel]="multiyAxisLabel2" [autoScale]="autoScale"
            [timeline]="false" [tooltipDisabled]="tooltipDisabled" [animations]="animations">
          </app-double-axis-chart>
        </div>
      </div>
      <p class="small">Current <span class="primary-color">Portfolio Balance</span> and Value of <span
          class="accent-color">Accounts
          in Arrears</span></p>
      <app-date-range-picker [sendOnInit]="false" [showAllTime]="false" [selectedRange]="'days90'" (getRange)="getRangePortfolioVsAccounts($event)"></app-date-range-picker>

    </mat-card>
  </div> -->
