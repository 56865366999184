import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AppStateService } from '../../../../core/app-state/app-state.service';
import { UtilsService } from '../../../../shared/services/utils.service';
import { DataResponse } from '../../../../shared/types/data-response.type';
import { Translation, TranslationItem, TranslationQuery } from '../type/translation.type';

class TranslationState {
  translation: TranslationItem[];
  searchTextFilter: string;
  contextFilter: string;
}

@Injectable({
  providedIn: 'root',
})
export class TranslationService extends ComponentStore<TranslationState> {
  private baseUrl = environment.nodeUrl;

  constructor(
    private httpClient: HttpClient,
    private utilsService: UtilsService,
    private appStateService: AppStateService
  ) {
    super({
      contextFilter: 'default',
      translation: [],
      searchTextFilter: '',
    });
  }

  getContextFilter$(): Observable<string> {
    return this.select((state) => state.contextFilter);
  }

  setContextFilter(contextFilter: string) {
    this.patchState({ contextFilter });
  }

  getSearchTextFilter$() {
    return this.select((state) => state.searchTextFilter);
  }

  setSearchTextFilter(searchTextFilter: string) {
    this.patchState({ searchTextFilter });
  }

  createTranslation$(payload?: {
    translation: TranslationItem[];
    context: string;
    apply: boolean;
    updateDefault: boolean;
  }) {
    const finalPayload = {
      code: payload.context,
      content: payload.translation,
      apply: payload.apply,
      updateDefault: payload.updateDefault,
    };

    const endpoint = 'translation-admin';

    const url = `${this.baseUrl}/${endpoint}`;

    return this.httpClient.post(url, finalPayload);
  }

  fetchContextTranslations$(payload: TranslationQuery['payload']): Observable<TranslationItem[]> {
    return this.appStateService.getAppState$().pipe(
      switchMap((appState) => {
        const params = this.utilsService.getHttpParamsFromPayload(payload);

        const options = { params };

        const endpoint = `translation-admin/${payload.context}`;
        const url = `${this.baseUrl}/${endpoint}`;

        return this.httpClient.get<DataResponse<Translation[]>>(url, options).pipe(
          map((response) => {
            return response['data']['content'];
          })
        );
      })
    );
  }

  deleteTranslation(code: string) {
    const endpoint = `translation-admin/${code}`;
    const url = `${this.baseUrl}/${endpoint}`;

    return this.httpClient.delete(url).pipe(
      map((response) => {
        return response['data'];
      })
    );
  }
  exportTranslation() {
    const endpoint = `translation-admin/export`;
    const url = `${this.baseUrl}/${endpoint}`;

    return this.httpClient.get(url).pipe(
      map((response) => {
        return response['data'];
      })
    );
  }

  importTranslation(payload) {
    const endpoint = `translation-admin/import`;
    const url = `${this.baseUrl}/${endpoint}`;

    return this.httpClient.post(url, payload).pipe(
      map((response) => {
        return response['data'];
      })
    );
  }

  createTranslation(payload) {
    const endpoint = `translation-admin`;
    const url = `${this.baseUrl}/${endpoint}`;

    return this.httpClient.post(url, payload).pipe(
      map((response) => {
        return response['data'];
      })
    );
  }
  getTranslation(id, body) {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        params = params.set(key, body[key]);
      }
    }

    options = {
      params,
    };

    const endpoint = `translation-admin/${id}`;
    const url = `${this.baseUrl}/${endpoint}`;
    return this.httpClient.get(url, options).pipe(
      map((response) => {
        return response['data'];
      })
    );
  }
}
