import { Component, EventEmitter, Input, OnInit, SimpleChanges } from '@angular/core';

import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { DentistService } from '../shared/dentist.service';

@Component({
  selector: 'app-dentist-list-map',
  templateUrl: './dentist-list-map.component.html',
  styleUrls: ['./dentist-list-map.component.css'],
  animations: [
    trigger('onChangeAnimation', [
      transition('void => *', [
        query('.animate', style({ opacity: 0 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.15s ease-in-out',
              keyframes([
                style({ opacity: 0, offset: 0, height: 0, margin: 0 }),
                style({ opacity: 1, offset: 1.0, height: '*', margin: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.animate', style({ opacity: 1 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.15s ease-in-out',
              keyframes([
                style({ opacity: 1, offset: 0, height: '*', margin: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, offset: 1.0, height: 0, margin: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class DentistListMapComponent implements OnInit {
  @Input() merchantID;

  @Input() dateFrom;
  @Input() dateTo;
  @Input() isMapAction = true;

  util = new UtilsClass();

  addMarkerEvent = new EventEmitter();

  selectedItemEvent = new EventEmitter();

  selectedItemIndex;

  selectedItem;

  filter;

  p = 1;
  itemPageIndex = 0;

  resetPositionsEvent = new EventEmitter();

  isLoaded = false;
  dentists = [];
  serviceRef;
  description = 'Click on marker to view staff friends details ';
  title = 'Staff members';

  isAdminOrPromoter = false;
  dataArray;

  icon = 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/blue-dot.png';

  displayMap = false;

  constructor(
    private dentistService: DentistService,
    private router: Router,
    private authenticationService: AuthenticationService,
    private location: Location
  ) {}

  ngOnInit() {
    this.setup();
  }

  ngOnDestroy() {
    if (this.serviceRef) {
      this.serviceRef.unsubscribe();
    }
  }

  setup() {
    if (this.serviceRef) {
      this.serviceRef.unsubscribe();
    }

    this.resetPositionsEvent.emit(true);

    this.displayMap = false;
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isAdminOrPromoter = res;

      const payload = {
        section: 0,
        limit: 300,
        merchantID: this.merchantID,
        dateTo: this.util.EPdateFormat(this.dateTo),
        dateFrom: this.util.EPdateFormat(this.dateFrom),
        hasAdress: true,
        fields:
          'ID,addresses.Calculated,addresses.Suburb,addresses.State,addresses.Postcode,addresses.Country.Code,' +
          'addresses.Unit,addresses.Street Nr,addresses.Street Name,addresses.Street Type,Address.Latitude,Address.Longitude,' +
          'CalculatedName',
      };

      this.serviceRef = this.dentistService.getList(payload, this.isAdminOrPromoter).subscribe((res) => {
        this.displayMap = true;
        if (res && res.length > 0) {
          this.isLoaded = true;
          this.dentists = res;
          for (let i = 0; i < this.dentists.length; i++) {
            if (this.dentists[i]) {
              this.dentists[i].icon = this.icon;
              this.addMarkerEvent.emit(this.dentists[i]);
            }
          }

          this.dataArray = this.dentists;

          const innerFunction = (section) => {
            section = section + 1;
            UtilsClass.loadingDataSection(section);
            payload.section = section;

            this.serviceRef = this.dentistService.getList(payload, this.isAdminOrPromoter).subscribe((res) => {
              if (res.length > 0) {
                this.dentists = this.dentists.concat(res);

                for (let i = res.length; i < this.dentists.length; i++) {
                  const o = this.dentists[i];

                  o.icon = this.icon;

                  this.addMarkerEvent.emit(o);
                }

                innerFunction(section);
              } else {
                return true;
              }
            });
            UtilsClass.stopLoadingInterceptor();
          };

          innerFunction(1);
        } else {
          this.isLoaded = true;
        }
      });
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      (changes.merchantID && changes.merchantID.previousValue != changes.merchantID.currentValue) ||
      (changes.dateTo && changes.dateTo.previousValue != changes.dateTo.currentValue) ||
      (changes.dateFrom && changes.dateFrom.previousValue != changes.dateFrom.currentValue)
    ) {
      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isAdminOrPromoter = res;
        this.setup();
      });
    }
  }

  getActionEvent(e) {
    this.router.navigate([
      '/merchant',
      {
        outlets: {
          page: ['staff-list-overview'],
        },
      },
    ]);
  }

  addCoordinateEvent(item) {
    if (item && item['ID'] && item['latitude'] && item['longitude']) {
      const payload = {
        latitude: item['latitude'],
        longitude: item['longitude'],
      };

      this.dentistService.addCoordinate(item['ID'], payload).subscribe((res) => {});
    }
  }

  getSelected(item) {
    if (item && item['ID']) {
      this.selectedItemIndex;

      this.dentists.map((x, index) => {
        if (x && x['ID']) {
          if (x['ID'] == item['ID']) {
            this.selectedItemIndex = index;

            return index;
          }
        }
      });
      this.selectedItem = item;
      this.itemPageIndex = Math.trunc(this.selectedItemIndex / 10) + 1;
      this.pageChange(this.itemPageIndex);
    } else {
      this.pageChange(1);
      this.selectedItem = null;
      this.selectedItemIndex = null;
      this.itemPageIndex = null;
    }
  }

  cancel() {
    this.location.back();
  }

  selectItem(item, index, pageIndex) {
    if (item && item['ID']) {
      this.selectedItemIndex = index;
      this.selectedItemEvent.emit(item);
      this.selectedItem = item;
      this.itemPageIndex = pageIndex;
      this.pageChange(pageIndex);
    }
  }

  pageChange(pageIndex) {
    this.p = pageIndex;
  }

  closeSelectedItem() {
    this.pageChange(1);
    this.selectedItem = null;
    this.selectedItemIndex = null;
    this.itemPageIndex = null;
  }
}
