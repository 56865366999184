import { NativeDateAdapter } from '@angular/material';
import * as moment from 'moment';
import { Settings } from './settings';

export class AppDateAdapter extends NativeDateAdapter {
  parse(value: any): Date | null {
    try {
      const format = Settings.global['dateFormat'];

      const _date = moment(value, String(format).toUpperCase());

      return _date.toDate();
    } catch (e) {
      return new Date();
    }
  }
  format(date: Date, displayFormat: string): string {
    try {
      const _date = moment(date);

      const format = Settings.global['dateFormat'];

      const result = _date.format(String(format).toUpperCase());
      return result;
    } catch (e) {
      return null;
    }

    // let result = this.datePipe
    // if (displayFormat == "input") {
    //   let day = date.getDate();
    //   let month = date.getMonth() + 1;
    //   let year = date.getFullYear();
    //   return this._to2digit(day) + '/' + this._to2digit(month) + '/' + year;
    // } else if (displayFormat == "inputMonth") {
    //   let month = date.getMonth() + 1;
    //   let year = date.getFullYear();
    //   return this._to2digit(month) + '/' + year;
    // } else {
    //   return date.toDateString();
    // }
  }

  private _to2digit(n: number) {
    return ('00' + n).slice(-2);
  }
}

export const APP_DATE_FORMATS = {
  parse: {
    dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
  },
  display: {
    // dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
    dateInput: 'input',
    // monthYearLabel: { month: 'short', year: 'numeric', day: 'numeric' },
    monthYearLabel: 'inputMonth',
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
  },
};

export class AppDateAdapterMonth extends NativeDateAdapter {
  parse(value: any): Date | null {
    try {
      const format = 'mm-YYYY';

      const _date = moment(value, String(format).toUpperCase());

      return _date.toDate();
    } catch (e) {
      return new Date();
    }
  }
  format(date: Date, displayFormat: string): string {
    try {
      const _date = moment(date);

      const format = 'MMMM-YYYY';

      const result = _date.format(String(format).toUpperCase());
      return result;
    } catch (e) {
      return null;
    }

    // let result = this.datePipe
    // if (displayFormat == "input") {
    //   let day = date.getDate();
    //   let month = date.getMonth() + 1;
    //   let year = date.getFullYear();
    //   return this._to2digit(day) + '/' + this._to2digit(month) + '/' + year;
    // } else if (displayFormat == "inputMonth") {
    //   let month = date.getMonth() + 1;
    //   let year = date.getFullYear();
    //   return this._to2digit(month) + '/' + year;
    // } else {
    //   return date.toDateString();
    // }
  }

  private _to2digit(n: number) {
    return ('00' + n).slice(-2);
  }
}

export class AppDateAdapterYear extends NativeDateAdapter {
  parse(value: any): Date | null {
    try {
      const format = 'YYYY';

      const _date = moment(value, String(format).toUpperCase());

      return _date.toDate();
    } catch (e) {
      return new Date();
    }
  }
  format(date: Date, displayFormat: string): string {
    try {
      const _date = moment(date);
      const format = 'YYYY';

      const result = _date.format(String(format).toUpperCase());
      return result;
    } catch (e) {
      return null;
    }

    // let result = this.datePipe
    // if (displayFormat == "input") {
    //   let day = date.getDate();
    //   let month = date.getMonth() + 1;
    //   let year = date.getFullYear();
    //   return this._to2digit(day) + '/' + this._to2digit(month) + '/' + year;
    // } else if (displayFormat == "inputMonth") {
    //   let month = date.getMonth() + 1;
    //   let year = date.getFullYear();
    //   return this._to2digit(month) + '/' + year;
    // } else {
    //   return date.toDateString();
    // }
  }

  private _to2digit(n: number) {
    return ('00' + n).slice(-2);
  }
}
