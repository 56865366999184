import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { PatientService } from '../shared/patient.service';

@Component({
  selector: 'app-patient-card-stop-modal',
  templateUrl: './patient-card-stop-modal.component.html',
  styleUrls: ['./patient-card-stop-modal.component.css'],
})
export class PatientCardStopModalComponent implements OnInit {
  reasons = [];
  feedback = '';
  options = [];
  invID;
  selectedRadio = false;
  @Output()
  done: EventEmitter<any> = new EventEmitter();

  @Output()
  invitation: EventEmitter<any> = new EventEmitter();

  @Input()
  force = false;

  constructor(private patientService: PatientService, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.invID = data.invitationID;
    if (data.force != null) {
      this.force = data.force;
    }
  }

  ngOnInit() {
    this.patientService.getDeclineReasons().subscribe((res) => {
      this.options = res;
    });
  }

  selectReason(reason, response) {
    const index = this.reasons.indexOf(reason);

    if ((!this.reasons.length || index == -1) && response == 'yes') {
      this.reasons.push(reason['Code']);
    } else {
      if (response == 'no' && index != -1) {
        this.reasons.splice(reason['Code'], 1);
      }
    }
    this.selectedRadio = true;
  }

  submit() {
    this.patientService
      .stopProceedInivation(
        this.invID,
        {
          reasons: this.reasons,
          feedback: this.feedback,
        },
        this.force
      )
      .subscribe((res) => {
        if (res) {
          NotifyAppComponent.displayToast(
            'success',
            'Successfull operation',
            "We've recieved your decline reasons, thank you for your help"
          );
        }

        this.done.emit(true);

        this.invitation.emit(res);
      });
  }
}
