import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-patient-personal-details-view',
  templateUrl: './patient-personal-details-view.component.html',
  styleUrls: ['./patient-personal-details-view.component.css'],
})
export class PatientPersonalDetailsViewComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
