<div class="rel">
  <div class="card-header primary-gradient-img clearfix confirmModal info">
    <div class="row clearfix">
      <!-- Modal dismiss button -->
      <button class="btn-close btn-clear mat-button white" (click)="closeModal()" matDialogClose>
        <mat-icon>clear</mat-icon>
      </button>
      <div class="row titleArea clearfix">
        <mat-icon class="white pull-left">info</mat-icon>
        <h2 class="summary-header rm-m">Tooth {{ toothName }} Scan</h2>
      </div>
    </div>
  </div>

  <mat-dialog-content class="text-center">
    <div style="display: block; width: 100%">
      <app-tooth-view
        [tooth]="{ hideNumber: false, toothNumber: toothName }"
        style="display: block; margin: 20px auto; width: 40px"
      ></app-tooth-view>
    </div>

    <ng-container *ngIf="apicalDetections && apicalDetections.length > 0">
      <h4 style="text-align: left">APICAL</h4>

      <ul>
        <li
          *ngFor="let detections of apicalDetections"
          style="
            font-weight: bold;
            color: red;
            font-family: Arial, Helvetica, sans-serif;
            font-size: small;
            text-align: left;
          "
        >
          {{ detections | uppercase }}
        </li>
      </ul>
    </ng-container>

    <ng-container *ngIf="cariesDetections && cariesDetections.length > 0">
      <h4 style="text-align: left">CARIES</h4>

      <ul>
        <li
          *ngFor="let detections of cariesDetections"
          style="
            font-weight: bold;
            color: red;
            font-family: Arial, Helvetica, sans-serif;
            font-size: small;
            text-align: left;
          "
        >
          {{ detections | uppercase }}
        </li>
      </ul>
    </ng-container>

    <ng-container *ngIf="restorationsDetection && restorationsDetection.length > 0">
      <h4 style="text-align: left">RESTORATIONS</h4>

      <ul>
        <li
          *ngFor="let detections of restorationsDetection"
          style="
            font-weight: bold;
            color: red;
            font-family: Arial, Helvetica, sans-serif;
            font-size: small;
            text-align: left;
          "
        >
          {{ detections | uppercase }}
        </li>
      </ul>
    </ng-container>
  </mat-dialog-content>
  <mat-dialog-actions class="button-row" style="justify-content: flex-end">
    <button (click)="closeModal()" color="primary" mat-raised-button matDialogClose>OK</button>
  </mat-dialog-actions>
</div>
