<mat-card class="card treatment-card card1 text-center">
  <div class="colour-stripe"></div>
  <div class="row clearfix">
    <div class="column">
      <h3 class="sr-title sm-h3 mt rm-mb">{{ title }}</h3>
      <p class="small">
        <span *ngIf="stepNumber"> {{ stepNumber }}. </span>
        <span *ngIf="instructions" [innerHTML]="instructions | safeHtml"> </span>
      </p>
    </div>

    <ng-content></ng-content>
  </div>
</mat-card>
