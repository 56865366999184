import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService, FacebookLoginProvider, GoogleLoginProvider } from 'angularx-social-login';
import { CalendarShareService } from '../../../shared/services/calendar-share.service';
import { CalendarService } from '../../../shared/services/calendar.service';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { AuthenticationService } from '../shared/authentication.service';

@Component({
  selector: 'app-social-media-input',
  templateUrl: './social-media-input.component.html',
  styleUrls: ['./social-media-input.component.css'],
  animations: [
    trigger('simpleFadeAnimation', [
      state('in', style({ opacity: 1, width: '*' })),
      transition(':enter', [style({ opacity: 0, width: 0 }), animate(300)]),
      transition(':leave', animate(300, style({ opacity: 0, width: 0 }))),
    ]),
  ],
})
export class SocialMediaInputComponent implements OnInit {
  @Input()
  logout = true;

  @Input()
  buttonStyle = 'buttons';

  // Status

  @Input()
  facebookStatus = null;

  @Input()
  googleStatus = null;

  @Input()
  iCloudCalendarStatus = null;

  @Input()
  googleCalendarStatus = null;

  @Input()
  msExchangeStatus = null;

  // Access Tokens

  @Input()
  iCloudCalendarID = null;

  @Input()
  googleCalendarID = null;

  @Input()
  msExchangeID = null;

  // @Input()
  // office365Status = null;

  // Status Label

  @Input()
  facebookStatusLabel = '';

  @Input()
  googleStatusLabel = '';

  @Input()
  iCloudCalendarStatusLabel = '';

  @Input()
  googleCalendarStatusLabel = '';

  @Input()
  msExchangeStatusLabel = '';

  // @Input()
  // office365StatusLabel = "";

  // Label

  @Input()
  facebookLabel;

  @Input()
  googleLabel;

  @Input()
  iCloudCalendarLabel;

  @Input()
  googleCalendarLabel;

  @Input()
  msExchangeLabel;

  // @Input()
  // office365Label;

  // Button Label

  @Input()
  facebookButtonLabel = 'Connect Facebook';

  @Input()
  googleButtonLabel = 'Connect Google';

  @Input()
  iCloudCalendarButtonLabel = 'Connect iCloud Calendar';

  @Input()
  googleCalendarButtonLabel = 'Connect Google Calendar';

  @Input()
  msExchangeButtonLabel = 'Connect MS Exchange';

  // @Input()
  // office365ButtonLabel = "Connect Office 365";

  // Date

  @Input()
  facebookDate = null;

  @Input()
  googleDate = null;

  @Input()
  iCloudCalendarDate = null;

  @Input()
  googleCalendarDate = null;

  @Input()
  msExchangeDate = null;

  // @Input()
  // office365Date = null;

  // Display

  @Input()
  displayFacebook = true;

  @Input()
  displayGoogle = true;

  @Input()
  displayiCloudCalendar = false;

  @Input()
  displayGoogleCalendar = false;

  @Input()
  displayMSExchange = false;

  //

  // @Input()
  // displayOffice365 = false;

  @Input()
  directLogin = true;

  @Input()
  loginEvent = new EventEmitter();

  @Output()
  getResult = new EventEmitter();

  @Output()
  getClick = new EventEmitter();

  @Output()
  getRemove = new EventEmitter();

  @Input()
  canRemove = false;

  @Input()
  removeLabel = 'Remove Integration';

  type = 'facebook';

  clicked = false;
  sessionType: any;

  constructor(
    private authSocialService: AuthService,
    private authenticationService: AuthenticationService,
    private calendarService: CalendarService,
    private calendarShare: CalendarShareService
  ) {}

  async ngOnInit() {
    // this.calendarShare.initGoogleClient();
    const res = await this.authenticationService.getSessionType().toPromise();
    if (res) {
      this.sessionType = res;
    }

    this.authSocialService.authState.subscribe((user) => {
      if (user && this.clicked != false) {
        const p = JSON.parse(JSON.stringify(user)) || {};

        if (p && p.provider == 'FACEBOOK') {
          p.facebookID = p.id;
          p.facebookLabel = p.name;
        } else {
          p.facebookID = null;
          p.facebookLabel = null;
        }

        if (p && p.provider == 'GOOGLE') {
          p.googleID = p.id;
          p.googleLabel = p.name;
        } else {
          p.googleID = null;
          p.googleLabel = null;
        }

        if (p && p.provider == 'TWITTER') {
          p.twitterID = p.id;
          p.twitterLabel = p.twitterLabel;
        } else {
          p.twitterID = null;
          p.twitterLabel = null;
        }

        if (p && p.photoUrl) {
          p.profilePicture = p.photoUrl.replace('normal', 'large');
          p.profilePicture = p.photoUrl.replace('s96-c', 's400-c');
        }

        this.getResult.emit(p);
        this.authSocialService.signOut();
      }
    });

    this.loginEvent.subscribe((res) => {
      if (this.type == 'facebook') {
        this.authSocialService.signIn(GoogleLoginProvider.PROVIDER_ID);
      } else if (this.type == 'google') {
        this.authSocialService.signIn(GoogleLoginProvider.PROVIDER_ID);
      } else if (this.type == 'twitter') {
      }
    });

    // this.checkSyncedCalendarsStillValid();
  }

  syncGoogle() {
    this.clicked = true;
    this.type = 'google';
    if (this.directLogin == true) {
      this.authSocialService.signIn(GoogleLoginProvider.PROVIDER_ID);
    } else {
      this.getClick.emit(this.type);
    }
  }

  syncFaceBook() {
    this.clicked = true;
    this.type = 'facebook';
    if (this.directLogin == true) {
      this.authSocialService.signIn(FacebookLoginProvider.PROVIDER_ID);
    } else {
      this.getClick.emit(this.type);
    }
  }

  toggleiCloudCalendar() {}

  async toggleGoogleCalendar() {
    let payload: any;
    const isConnected = this.googleCalendarStatus === 'valid';
    if (isConnected) {
      payload = {
        googleID: 'void',
        googleETag: 'void',
        googleLabel: 'void',
        googleExpirationDate: 'void',
        googleAddedDate: 'void',
      };
      this.calendarShare.googleSignOut();
    } else {
      const res = await this.calendarShare.googleSignIn();
      // const refreshAccessCode = await this.calendarShare
      //   .googleRefreshTokenRequest(res.code)
      //   .toPromise();
      const user = this.calendarShare.googleGetCurrentUserDetails();
      const idToken = user.id_token;
      // const accessToken = user.access_token;
      // const refreshToken = refreshAccessCode.refresh_token;
      const expiresAt = user.expires_at;
      const fullname = user.fullname;
      const email = user.email;

      payload = {
        googleID: idToken,
        // googleETag: accessToken,
        googleETag: res.code,
        googleLabel: `${fullname} - ${email}`,
        googleExpirationDate: expiresAt,
        googleAddedDate: new Date(),
      };
    }

    this.calendarService.syncCalendar(payload, this.sessionType).subscribe((res) => {
      if (isConnected) {
        if (!res['Google.ID']) {
          NotifyAppComponent.displayToast('success', 'Calendar Disconnect', 'Google calendar is now disconnected');
          this.getResult.emit(res);
        } else {
          NotifyAppComponent.displayToast('error', 'Calendar Disconnect', 'Something went wrong when disconnecting');
        }
      } else {
        if (res['Google.ID']) {
          NotifyAppComponent.displayToast('success', 'Calendar Sync', 'Google calendar signed in');
          this.getResult.emit(res);
        } else {
          NotifyAppComponent.displayToast('error', 'Calendar Sync', 'Something went wrong when signing in');
        }
      }
    });
  }

  async toggleMSExchange() {
    let payload: any;
    const isConnected = this.msExchangeStatus === 'valid';
    if (isConnected) {
      payload = {
        microsoftID: 'void',
        microsoftETag: 'void',
        microsoftLabel: 'void',
        microsoftExpirationDate: 'void',
        microsoftAddedDate: 'void',
      };
      this.calendarShare.microsoftSignOut();
    } else {
      const res = await this.calendarShare.msSignIn();
      if (res) {
        const user = await this.calendarShare.microsoftGetCurrentUserDetails();
        const idToken = user.id_token;
        const accessToken = user.access_token;
        const expiresAt = user.expires_at;
        const fullname = user.fullname;
        const email = user.email;

        payload = {
          microsoftID: idToken,
          microsoftETag: accessToken,
          microsoftLabel: `${fullname} - ${email}`,
          microsoftExpirationDate: expiresAt,
          microsoftAddedDate: new Date(),
        };
      } else {
        return;
      }
    }

    this.calendarService.syncCalendar(payload, this.sessionType).subscribe((res) => {
      if (isConnected) {
        if (!res['Microsoft.ID']) {
          NotifyAppComponent.displayToast('success', 'Calendar Disconnect', 'Microsoft calendar is now disconnected');
          this.getResult.emit(res);
        } else {
          NotifyAppComponent.displayToast('error', 'Calendar Disconnect', 'Something went wrong when disconnecting');
        }
      } else {
        if (res['Microsoft.ID']) {
          NotifyAppComponent.displayToast('success', 'Calendar Sync', 'Microsoft calendar signed in');
          this.getResult.emit(res);
        } else {
          NotifyAppComponent.displayToast('error', 'Calendar Sync', 'Something went wrong when signing in');
        }
      }
    });
  }

  removeEvent(type) {
    if (type) {
      this.getRemove.emit(type);
    }
  }

  // checkSyncedCalendarsStillValid() {
  //   const disconnect = (type: string) => {
  //     let payload: any = {};
  //     payload[`${type}ID`] = "void";
  //     payload[`${type}AddedDate`] = "void";
  //     this.calendarService
  //       .syncCalendar(payload, this.sessionType)
  //       .subscribe((res) => {
  //         if (res) {
  //           NotifyAppComponent.displayToast(
  //             "warning",
  //             "Calendar Disconnect",
  //             `${type} calendar sign in has expired`
  //           );
  //           this.getResult.emit(res);
  //         }
  //       });
  //   };

  //   // check google calendar
  //   if (this.googleCalendarID) {
  //     this.calendarShare
  //       .initGoogleClient(this.googleCalendarID)
  //       .then((res) => {
  //         if (!res) {
  //           disconnect(CalendarType.google);
  //         }
  //       })
  //       .catch(() => disconnect(CalendarType.google));
  //   }
  // }
}
