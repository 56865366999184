<div class="rel">
  <div class="card-header primary-gradient-img clearfix confirmModal">
    <button
      class="btn-close btn-clear mat-button smt white custom-close"
      [ngClass]="!confirmDialog.title ? 'no-title-close' : ''"
      (click)="closeModal()"
      matDialogClose
    >
      <mat-icon>clear</mat-icon>
    </button>
    <div class="full-width flex main-title-container">
      <div
        class="main-icon-container text-center"
        [ngClass]="{ 'main-icon-container-no-title': confirmDialog && !confirmDialog.title }"
      >
        <mat-icon class="main-icon accent-color" *ngIf="isFontAwsome(confirmDialog.icon) != true">
          {{ confirmDialog.icon || 'info' }}</mat-icon
        >

        <mat-icon
          class="main-icon accent-color {{ confirmDialog.icon || 'fas fa-info' }}"
          *ngIf="isFontAwsome(confirmDialog.icon) == true"
        ></mat-icon>
      </div>
      <div class="row full-width title-container clearfix">
        <div class="title-container-sub">
          <h2 class="text-left title-header rm-m">
            {{ confirmDialog.title  | translate}}
          </h2>
        </div>
      </div>
    </div>
  </div>

  <!--
  <mat-icon class="xlarge-modal-icon accent-color">{{confirmDialog.icon}}</mat-icon>
  <h2 mat-dialog-title class="sr-title rm-mb mt">{{confirmDialog.title}}</h2> -->
  <mat-dialog-content class="text-center">
    <div class="clearFix text-center full-width mt mb" *ngIf="confirmDialog && confirmDialog.imageLink">
      <div class="dialogImage text-center" [style.background-image]="'url(' + confirmDialog.imageLink + ')'"></div>
    </div>

    <div class="row clearfix mb">
      <div [innerHTML]="confirmDialog.confirmMessage | translate | safeHtml"></div>
    </div>

    <!-- <hr> -->
  </mat-dialog-content>
  <mat-dialog-actions class="button-row text-center">
    <button
      class="pull-left"
      *ngIf="confirmDialog.isInformation == true"
      (click)="onInformationEvent()"
      mat-raised-button
      color="primary"
    >
      {{ confirmDialog.informationLabel }}
    </button>

    <button (click)="onNo()" mat-raised-button matDialogClose>{{ confirmDialog.noMessage }}</button>

    <!-- Can optionally provide a result for the closing dialog. -->
    <button (click)="onYes()" mat-raised-button color="accent">{{ confirmDialog.yesMessage }}</button>
  </mat-dialog-actions>
</div>
