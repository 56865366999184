import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { BankAccountService } from '../../bank-account/shared/bank-account.service';
import { ContractService } from '../shared/contract.service';

@Component({
  selector: 'app-contract-detail-add-payment',
  templateUrl: './contract-detail-add-payment.component.html',
  styleUrls: ['./contract-detail-add-payment.component.css'],
  animations: [
    trigger('simpleFadeAnimation', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [style({ opacity: 0, height: 0 }), animate(300)]),
      transition(':leave', animate(300, style({ opacity: 0, height: '*' }))),
    ]),
  ],
})
export class ContractDetailAddPaymentComponent implements OnInit {
  @Input()
  contractID;

  @Input()
  contractObject;

  @Input()
  setContractTab = 'repayment';

  closeModal = new EventEmitter();

  contract;
  bankDetails;
  isConsolidated = false;
  isOutstandingOnly = false;
  isPending = true;
  totalObject;
  isPromoterOrAdmin = false;

  constructor(
    private activeRouter: ActivatedRoute,
    private contractService: ContractService,
    private bankAccountService: BankAccountService,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit() {
    this.activeRouter.params.subscribe((params) => {
      if (params['contractID']) {
        this.contractID = params['contractID'];
      }
      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isPromoterOrAdmin = res;

        if (this.contractObject && typeof this.contractObject == 'object') {
          this.contract = this.contractObject;
          this.setup();
        } else if (this.contractID) {
          this.contractService.fetchContractDetails$(this.contractID, null, this.isPromoterOrAdmin).subscribe((res) => {
            if (res) {
              this.contract = res;
              this.setup();
            }
          });
        }
      });
    });
  }

  setup() {
    this.bankAccountService
      .getBankAccountDetails(this.contract['DefaultPaymentMethod_key'], {}, this.isPromoterOrAdmin)
      .subscribe((res) => {
        this.bankDetails = res;
      });
  }

  close() {
    this.closeModal.emit(true);
  }
}
