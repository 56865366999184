/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./treatment-incusion-modal.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i4 from "@angular/material/icon";
import * as i5 from "../../../shared/components/swipe-cards/swipe-cards.component.ngfactory";
import * as i6 from "../../../shared/components/swipe-cards/swipe-cards.component";
import * as i7 from "./treatment-incusion-modal.component";
var styles_TreatmentInclusionModalComponent = [i0.styles];
var RenderType_TreatmentInclusionModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TreatmentInclusionModalComponent, data: {} });
export { RenderType_TreatmentInclusionModalComponent as RenderType_TreatmentInclusionModalComponent };
export function View_TreatmentInclusionModalComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { swipeCard: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 13, "div", [["class", "card-header primary-gradient-img clearfix inInclusion inModal"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 12, "div", [["class", "row clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "button", [["class", "btn-close btn-clear mat-button white"], ["matDialogClose", ""]], [[1, "aria-label", 0], [1, "type", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).dialogRef.close(i1.ɵnov(_v, 4).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { _matDialogClose: [0, "_matDialogClose"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i3.View_MatIcon_0, i3.RenderType_MatIcon)), i1.ɵdid(6, 9158656, null, 0, i4.MatIcon, [i1.ElementRef, i4.MatIconRegistry, [8, null], [2, i4.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["clear"])), (_l()(), i1.ɵeld(8, 0, null, null, 6, "div", [["class", "row titleArea clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "mat-icon", [["class", "fas fa-cart-plus pull-left mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i3.View_MatIcon_0, i3.RenderType_MatIcon)), i1.ɵdid(10, 9158656, null, 0, i4.MatIcon, [i1.ElementRef, i4.MatIconRegistry, [8, null], [2, i4.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵeld(11, 0, null, null, 3, "h2", [["class", "summary-header rm-m"]], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, [" ", " "])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "span", [["class", "subLabel"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Add extra inclusions"])), (_l()(), i1.ɵeld(15, 0, null, null, 2, "div", [["class", "row clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 1, "app-swipe-cards", [], null, [[null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("close" === en)) {
        var pd_0 = (_co.closeModalEvent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_SwipeCardsComponent_0, i5.RenderType_SwipeCardsComponent)), i1.ɵdid(17, 114688, null, 0, i6.SwipeCardsComponent, [], null, { close: "close" })], function (_ck, _v) { var currVal_2 = ""; _ck(_v, 4, 0, currVal_2); _ck(_v, 6, 0); _ck(_v, 10, 0); _ck(_v, 17, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵnov(_v, 4).ariaLabel || null); var currVal_1 = i1.ɵnov(_v, 4).type; _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵnov(_v, 6).inline; var currVal_4 = (((i1.ɵnov(_v, 6).color !== "primary") && (i1.ɵnov(_v, 6).color !== "accent")) && (i1.ɵnov(_v, 6).color !== "warn")); _ck(_v, 5, 0, currVal_3, currVal_4); var currVal_5 = i1.ɵnov(_v, 10).inline; var currVal_6 = (((i1.ɵnov(_v, 10).color !== "primary") && (i1.ɵnov(_v, 10).color !== "accent")) && (i1.ɵnov(_v, 10).color !== "warn")); _ck(_v, 9, 0, currVal_5, currVal_6); var currVal_7 = _co.componentTitle; _ck(_v, 12, 0, currVal_7); }); }
export function View_TreatmentInclusionModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-treatment-incusion-modal", [], null, null, null, View_TreatmentInclusionModalComponent_0, RenderType_TreatmentInclusionModalComponent)), i1.ɵdid(1, 114688, null, 0, i7.TreatmentInclusionModalComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TreatmentInclusionModalComponentNgFactory = i1.ɵccf("app-treatment-incusion-modal", i7.TreatmentInclusionModalComponent, View_TreatmentInclusionModalComponent_Host_0, { componentTitle: "componentTitle" }, { closeModal: "closeModal" }, []);
export { TreatmentInclusionModalComponentNgFactory as TreatmentInclusionModalComponentNgFactory };
