import { Component, Input } from '@angular/core';

@Component({
  selector: 'ipv-data-header',
  templateUrl: './data-header.component.html',
  styleUrls: ['./data-header.component.css'],
})
export class DataHeaderComponent {
  @Input() title = 'header';
  @Input() imageSrc: string = null;
  @Input() counterValue: null | number = null;
  @Input() headerSize: 'standard' | 'smaller' = 'standard';
}
