<div class="full-width white-background" *ngIf="thirdPartyClient && thirdPartyClient.ID">
  <mat-card>
    <form #userContactForm="ngForm">
      <div class="card-header primary-gradient-img clearfix stacked">
        <div class="row clearfix">
          <button class="btn-close btn-clear mat-button white" (click)="closeEvent()">
            <mat-icon>clear</mat-icon>
          </button>
          <div class="clearfix">
            <h2 class="sm-h2 pull-left rm-mt rm-mb">Edit Third-Party Application.</h2>
          </div>
        </div>
      </div>

      <mat-dialog-content class="stacked-card-view">
        <div class="full-width">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Label</mat-label>
            <input [(ngModel)]="label" matInput placeholder="Label" name="label" required/>
          </mat-form-field>

          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Description</mat-label>
            <textarea
              class="min-height"
              [(ngModel)]="description"
              name="description"
              matInput
              placeholder="Description"
            ></textarea>
          </mat-form-field>

          <app-phone-input
            class="full-width"
            [phoneNumber]="_phone"
            [isLandLine]="false"
            [label]="'Phone'"
            [required]="false"
            (validate)="isPhoneValidEvent($event)"
            (getPhone)="getPhone($event)"
          >
          </app-phone-input>

          <app-email-input
            class="full-width"
            [email]="_email"
            [label]="'Email'"
            [required]="false"
            (getEmail)="getEmail($event)"
            (validate)="isEmailValidEvent($event)"
          >
          </app-email-input>

          <mat-checkbox class="full-width clearfix smb" [(ngModel)]="isActive" name="isActive"> Active?</mat-checkbox>

          <h3 class="sr-title">Keys Management</h3>

          <div class="full-width flex smb">
            <mat-checkbox class="full-width" [(ngModel)]="generateNew" name="generateNew">
              Automatically Generate New Secret Key/ID
            </mat-checkbox>
          </div>

          <div class="full-width mb">
            <mat-form-field class="full-width" *ngIf="generateNew != true" appearance="outline">
              <mat-label>Secret ID</mat-label>
              <input [(ngModel)]="secretID" matInput placeholder="Secret ID" name="secretID" required/>
            </mat-form-field>

            <mat-form-field class="full-width" *ngIf="generateNew == true" appearance="outline">
              <mat-label>Secret ID</mat-label>
              <input [(ngModel)]="secretID" [disabled]="true" matInput placeholder="Secret ID" name="secretID"/>
            </mat-form-field>

            <mat-form-field class="full-width" *ngIf="generateNew != true" appearance="outline">
              <mat-label>Secret Key</mat-label>
              <input [(ngModel)]="secretKey" matInput placeholder="Secret Key" name="secretKey" required/>
            </mat-form-field>

            <mat-form-field class="full-width" *ngIf="generateNew == true" appearance="outline">
              <mat-label>Secret Key</mat-label>
              <input [(ngModel)]="secretKey" [disabled]="true" matInput placeholder="Secret Key" name="secretKey"/>
            </mat-form-field>
          </div>

          <h3 class="sr-title rm-mb">Default Template</h3>

          <div class="full-width flex flex-cust mb">
            <p class="full-width small pull-left">Choose the template that will be applied to invitations sent.</p>
            <button
              class="ml btn mr pull-right"
              (click)="openTemplateLookup()"
              mat-raised-button
              style="height: 40px"
              color="primary"
            >
              <span>Select Template</span><span class="ml mr"></span>
            </button>
          </div>

          <div
            class="full-width flex smb"
            *ngIf="templateID && templateLabel && templateID != templateIDOld && templateIDOld"
          >
            <mat-checkbox class="full-width slider-input-select ml mr" [(ngModel)]="updateAll" name="updateAll">
              Update all API Keys with the old template .
            </mat-checkbox>
          </div>

          <div class="full-width flex mb" *ngIf="templateID && templateLabel">
            <div class="full-width text-center secret-container">
              <div class="full-width text-left">
                <h3 class="full-width rm-m text-left">
                  {{ templateLabel }}

                  <button
                    class="ml smr pull-right btn"
                    (click)="viewTemplate(templateID)"
                    mat-button
                    mat-icon-button

                  >
                    <mat-icon>visibility</mat-icon>
                  </button>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </mat-dialog-content>

      <div class="drop-button text-center white-background">
        <hr/>

        <button
          class="ml btn-large mr"
          [disabled]="userContactForm.invalid || !templateID || !templateLabel || !isPhoneValid || !isEmailValid"
          (click)="save()"
          mat-raised-button
          color="accent"
        >
          <span>Save</span>
        </button>
      </div>
    </form>
  </mat-card>
</div>
