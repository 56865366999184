import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { environment } from '../../../../../../src/environments/environment';
import { AppStateService } from '../../../../core/app-state/app-state.service';
import { UtilsService } from '../../../../shared/services/utils.service';
import { DataResponse } from '../../../../shared/types/data-response.type';
import { QueryParameters } from '../../../../shared/types/query-parameters.type';

class ProductCatalogueState {
  productCatalogue: Partial<ProductCatalogueItem>[] | ProductCatalogueItem[];
  isAvailableFilter: '0' | '1';
  productLabelFilter?: string;
  skuFilter?: string;
  upcFilter?: string;
  modelFilter?: string;
}

export class ProductCatalogueItem {
  readonly 'ID'?: string;
  readonly 'DateTimeCreated'?: string;
  readonly 'LastModified'?: string;
  readonly 'LastModified_Human'?: string;
  'Description': string;
  'Stock_Keeping_Unit': string;
  'Supplier_key': string;
  'Universal_Product_Code': string;
  'Product_Type_key': string;
  'Product_Category_key'?: string;
  'Size': string;
  'Colour': string;
  'Weight': string;
  'Width': string;
  'Height': string;
  'Length': string;
  'SellPrice': number;
  'CostPrice': number;
  'TaxRate': number;
  'Product': string;
  'Model': string;
  'Is_Available': '0' | '1';
  'Is_SerialNum_Mandatory': '0' | '1';
  'Thumbnail_Doc_key': string;
  'ProfileImage_Doc_key': string;
  'AdditionalImages_Doc_key': string;
  'Manufactured_Year': string;
  isChecked?: boolean;
}

export class ProductCatalogueQuery {
  payload: Partial<ProductCatalogueItem> &
    QueryParameters & {
      Merchant_key: string;
    };
}

@Injectable()
export class ProductCatalogueService extends ComponentStore<ProductCatalogueState> {
  private baseUrl = environment.nodeUrl;

  constructor(
    private utilsService: UtilsService,
    private httpClient: HttpClient,
    private appStateService: AppStateService
  ) {
    super({
      productCatalogue: [],
      isAvailableFilter: '1',
      modelFilter: '',
      productLabelFilter: '',
      skuFilter: '',
      upcFilter: '',
    });
  }

  getProductCatalogue<T extends ProductCatalogueItem | Partial<ProductCatalogueItem>>(): T[] {
    return this.get().productCatalogue as T[];
  }

  getProductCatalogue$<T extends ProductCatalogueItem | Partial<ProductCatalogueItem>>(): Observable<T[]> {
    return this.select((state) => state.productCatalogue) as Observable<T[]>;
  }

  setProductCatalogue(productCatalogue: ProductCatalogueItem[] | Partial<ProductCatalogueItem>[]): void {
    this.patchState({ productCatalogue });
  }

  fetchProductCatalogue$<T extends ProductCatalogueItem | Partial<ProductCatalogueItem>>(
    payload?: ProductCatalogueQuery['payload']
  ): Observable<T[]> {
    return this.appStateService.getAppState$().pipe(
      switchMap((appState) => {
        const params = this.utilsService.getHttpParamsFromPayload(payload);

        const options = { params };

        const endpoint = appState.isPromoterOrAdmin ? 'product-catalogue/global' : 'product-catalogue';

        const url = `${this.baseUrl}/${endpoint}`;

        console.log(payload);

        return this.httpClient.get<DataResponse<T[]>>(url, options).pipe(map((response) => response['data']));
      })
    );
  }

  getIsAvailableFilter$() {
    return this.select((state) => state.isAvailableFilter);
  }

  setIsAvailableFilter(isAvailableFilter: '0' | '1') {
    this.patchState({ isAvailableFilter });
  }

  getProductLabelFilter$() {
    return this.select((state) => state.productLabelFilter);
  }

  setProductLabelFilter(productLabelFilter: string) {
    this.patchState({ productLabelFilter });
  }

  getSkuFilter$() {
    return this.select((state) => state.skuFilter);
  }

  setSkuFilter(skuFilter: string) {
    this.patchState({ skuFilter });
  }

  getUpcFilter$() {
    return this.select((state) => state.upcFilter);
  }

  setUpcFilter(upcFilter: string) {
    this.patchState({ upcFilter });
  }

  getModelFilter$() {
    return this.select((state) => state.modelFilter);
  }

  setModelFilter(modelFilter: string) {
    this.patchState({ modelFilter });
  }

  fetchProductCatalogueItem$<T extends ProductCatalogueItem | Partial<ProductCatalogueItem>>(payload?: {
    id: string;
  }): Observable<T> {
    return this.appStateService.getAppState$().pipe(
      switchMap((appState) => {
        const params = this.utilsService.getHttpParamsFromPayload(payload);

        const options = { params };

        const endpoint = appState.isPromoterOrAdmin ? 'product-catalogue/global' : 'product-catalogue';

        const url = `${this.baseUrl}/${endpoint}/${payload.id}`;

        return this.httpClient.get<DataResponse<T[]>>(url, options).pipe(map((response) => response['data']));
      })
    );
  }

  createProductCatalogueItem$<T extends ProductCatalogueItem | Partial<ProductCatalogueItem>>(
    payload?: Partial<ProductCatalogueItem>
  ): Observable<T> {
    return this.appStateService.getAppState$().pipe(
      switchMap((appState) => {
        const endpoint = 'product-catalogue';

        const url = `${this.baseUrl}/${endpoint}`;

        return this.httpClient.post<DataResponse<T>>(url, payload).pipe(map((response) => response.data));
      })
    );
  }

  updateProductCatalogueItem$<T extends ProductCatalogueItem | Partial<ProductCatalogueItem>>(
    item: ProductCatalogueItem | Partial<ProductCatalogueItem>,
    payload?: Partial<ProductCatalogueItem>
  ): Observable<T> {
    return this.appStateService.getAppState$().pipe(
      switchMap((appState) => {
        const endpoint = appState.isPromoterOrAdmin
          ? `product-catalogue/global/${item.ID}`
          : `product-catalogue/${item.ID}`;

        const url = `${this.baseUrl}/${endpoint}`;

        return this.httpClient.put<DataResponse<T>>(url, payload).pipe(map((response) => response.data));
      })
    );
  }

  deleteProductCatalogueItem$(appliance: ProductCatalogueItem | Partial<ProductCatalogueItem>): Observable<string> {
    return this.appStateService.getAppState$().pipe(
      take(1),
      switchMap((appState) => {
        const endpoint = appState.isPromoterOrAdmin
          ? `product-catalogue/global/${appliance.ID}`
          : `product-catalogue/${appliance.ID}`;

        const url = `${this.baseUrl}/${endpoint}`;

        return this.httpClient.delete<DataResponse<Partial<string>>>(url).pipe(map((response) => response.data));
      })
    );
  }

  addProductCatalogueItem$(itemWithID: ProductCatalogueItem | Partial<ProductCatalogueItem>) {
    const currentItems = this.get().productCatalogue;
    const items = [...currentItems, itemWithID];
    this.patchState({ productCatalogue: items });
    return of(itemWithID);
  }

  editProductCatalogueItem$(itemName: ProductCatalogueItem | Partial<ProductCatalogueItem>) {
    const items = this.get().productCatalogue;
    const targetIndex = items.findIndex((item) => itemName.ID === item.ID);
    items[targetIndex] = itemName;
    this.patchState({ productCatalogue: items });
    return of(itemName);
  }

  removeProductCatalogueItem$(selectedItem: ProductCatalogueItem | Partial<ProductCatalogueItem>): Observable<null> {
    const currentItems = this.get().productCatalogue;
    const items = currentItems.filter((item) => item.ID !== selectedItem.ID);

    this.patchState({ productCatalogue: items });

    return of(null);
  }
}
