import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { UtilsService } from '../../../shared/services/utils.service';
import { TreatmentDocumentCreateComponent } from '../treatment-document-create/treatment-document-create.component';
import { icon } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'app-treatment-group-new-button',
  templateUrl: './treatment-group-new-button.component.html',
  styleUrls: ['./treatment-group-new-button.component.css'],
})
export class TreatmentGroupNewButtonComponent implements OnInit {
  @Input()
  title = 'New Group';

  @Input()
  btnLarge = true;

  @Input()
  isSideMenu = true;

  constructor(private utilService: UtilsService, private router: Router) {}

  ngOnInit() {}

  newCategory() {
    this.router.navigate(['/merchant', { outlets: { page: ['treatment-category-create'] } }]);
  }

  newType() {
    this.router.navigate(['/merchant', { outlets: { page: ['treatment-type-create'] } }]);
  }

  newBrand() {
    this.router.navigate(['/merchant', { outlets: { page: ['treatment-brand-create'] } }]);
  }

  newProduct() {
    this.router.navigate(['/merchant', { outlets: { page: ['treatment-product-create'] } }]);
  }

  newMedication() {
    this.router.navigate(['/merchant', { outlets: { page: ['drugs', 'drug-create'] } }]);
  }

  newDocument() {
    const ref = RootAppComponent.dialog.open(TreatmentDocumentCreateComponent, {
      width: '750px',
      data: {},
    });

    ref.componentInstance.create.subscribe((res) => {
      if (res) {
        ref.close();
      }
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  protected readonly icon = icon;
}
