<div class="container" *ngIf="group && loading == true">
  <div class="row clearfix flex">
    <div class="row clearfix full-width">
      <mat-card class="intro-card">
        <app-ep-document-view-image
          class="header-img"
          *ngIf="group && group.fileID"
          [hideZoomButton]="true"
          [imageLink]="getSrc(group.fileID)"
        >
        </app-ep-document-view-image>

        <div class="content-area">
          <div class="pull-right" *ngIf="isPromoterOrAdmin == true">
            <button class="actionMenuToggle" [matMenuTriggerFor]="menu" mat-icon-button>
              <mat-icon class="white">more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button (click)="edit()" mat-menu-item>
                <mat-icon class="fas fa-edit"></mat-icon>
                <span>Edit</span>
              </button>

              <button *ngIf="group.isActive != true" (click)="enable()" mat-menu-item>
                <mat-icon class="fas fa-check-circle"></mat-icon>
                <span>Enable</span>
              </button>

              <button *ngIf="group.isActive == true" (click)="disable()" mat-menu-item>
                <mat-icon class="fas fa-ban"></mat-icon>
                <span>Disable</span>
              </button>

              <button (click)="remove()" mat-menu-item>
                <mat-icon class="fas fa-trash-alt"></mat-icon>
                <span>Delete</span>
              </button>
            </mat-menu>
          </div>

          <h2 class="summary-header white rm-m">
            <mat-icon class="{{ group.icon }} " *ngIf="group && group.icon && isAwsome(group.icon) == true"></mat-icon>
            <mat-icon *ngIf="group && group.icon && isAwsome(group.icon) != true">{{ group.icon }} </mat-icon>

            {{ group.title }}

            <span class="value chip new" *ngIf="isNew(group['created_at']) == true">NEW</span>
          </h2>

          <p class="lead white rm-mt" *ngIf="group.description">{{ group.description }}</p>

          <div class="row clearfix" *ngIf="isPromoterOrAdmin == true">
            <i class="static-link pull-left"
            >Static Category link: <strong>/wiki/(wikiPageRoute:group-code/{{ group.code }}) </strong></i
            >

            <p class="small ml white pull-right" *ngIf="group.created_at">
              <mat-icon class="fas fa-clock extra-header-icon"></mat-icon>
              {{ group.created_at | TimeLabelPast }} ago
            </p>

            <p class="small mr pull-right" *ngIf="group['isActive'] == true && isPromoterOrAdmin == true">
              <span class="chip-enable">Active</span>
            </p>
            <p class="small mr pull-right" *ngIf="group['isActive'] != true && isPromoterOrAdmin == true">
              <span class="chip-disable">Not active</span>
            </p>

            <p
              class="small pull-right"
              *ngIf="group && group.roles && group.roles.length > 0 && isPromoterOrAdmin == true"
            >
              <span class="chip-only" *ngFor="let r of group.roles | orderBy" matTooltip="{{ r }}">{{
                firstLetterUpperCase(r)
                }}</span>
            </p>
          </div>
        </div>
      </mat-card>

      <app-wiki-search
        class="row clearfix"
        [groupID]="group._id"
        [displatOrderLayout]="true"
        [displayLayout]="true"
        [orderBy]="orderBy"
      >
      </app-wiki-search>
    </div>

    <app-wiki-summary class="thrd-width ml"></app-wiki-summary>
  </div>
</div>

<div class="clearfix full-width mt" *ngIf="!group && loading == true">
  <app-empty-list-message
    class="full-width"
    *ngIf="sessionType != 'guest'"
    [title]="'Page Not found'"
    [message]="'This article does not exist anymore'"
  >
  </app-empty-list-message>

  <app-empty-list-message
    class="clearfix full-width"
    *ngIf="sessionType == 'guest'"
    [title]="'Page Not found'"
    [message]="'This article does not exist anymore, or you dont have access to it'"
    [isAction]="true"
    [actionIcon]="'lock_open'"
    [actionLabel]="'Login'"
    (actionEvent)="login()"
  >
  </app-empty-list-message>
</div>
