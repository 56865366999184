<div *ngIf="revenueReport; else emptyListMessage" style="display: flex; justify-content: center">
  <div
    class="details"
    style="width: 400px; background-color: #f8f8ff; padding: 10px; border-radius: 5px; margin-right: 10px"
  >
    <div class="row clearfix">
      <div class="pull-left">
        <h3 class="sm-h3 sr-title rm-mb rm-mt">Details</h3>
      </div>
      <hr/>
    </div>

    <div class="row clearfix" @labelAnimation>
      <label class="rm-mt num anim" style="display: block">
        <span class="wide" style="line-height: 25px; color: #168bdd">
          <strong> Number of Contracts: </strong>
        </span>

        <strong class="pill" style="width: fit-content; background-color: #168bdd">
          {{ revenueReport.NoOfCntrctsInActualCustRecords }}
        </strong>
      </label>

      <hr/>

      <label class="rm-mt num anim" style="display: block">
        <span class="wide" style="line-height: 25px; color: #168bdd">
          <strong> Number of Active & Suspended Contracts: </strong>
        </span>

        <strong class="pill" style="width: fit-content; background-color: #168bdd">
          {{ revenueReport.NoOfCntrctsActvOrSus }}
        </strong>
      </label>

      <hr/>

      <label class="rm-mt num anim" style="display: block">
        <span class="wide" style="line-height: 25px; color: #ce066b">
          <strong> Total (Before Deposit): </strong>
        </span>

        <strong class="pill" style="width: fit-content; background-color: #ce066b">
          {{ revenueReport.TotalExecludingDep | customCurrency }}
        </strong>
      </label>

      <hr/>

      <label class="rm-mt num anim" style="display: block">
        <span class="wide" style="line-height: 25px; color: #ce066b">
          <strong> Total (Financed): </strong>
        </span>

        <strong class="pill" style="width: fit-content; background-color: #ce066b">
          {{ revenueReport.FinancedTotal | customCurrency }}
        </strong>
      </label>

      <hr/>

      <label class="rm-mt num anim" style="display: block">
        <span class="wide" style="line-height: 25px">
          <strong> Balance Outstanding: </strong>
        </span>

        <strong
          class="pill"
          *ngIf="revenueReport.TotalDueTillToday; else totalDueTillTodayNull"
          style="width: fit-content"
        >
          {{ revenueReport.TotalDueTillToday | customCurrency }}
        </strong>

        <ng-template #totalDueTillTodayNull>
          <strong class="pill" style="width: fit-content">
            {{ 0 | customCurrency }}
          </strong>
        </ng-template>
      </label>
    </div>
  </div>

  <div
    class="actual-chart"
    *ngIf="revenueReport"
    style="width: 400px; background-color: #f8f8ff; padding: 10px; border-radius: 5px; margin-right: 10px"
  >
    <div class="row clearfix">
      <div class="pull-left">
        <h3 class="sm-h3 sr-title rm-mb rm-mt">Actual</h3>
      </div>
      <hr/>
    </div>

    <div class="row clearfix rel">
      <div class="total anim" style="display: flex; justify-content: center">
        {{ chartCurrencyCode }}
        <div [endVal]="revenueReport.TotalRevenue_Actual[0].Actual_Net" countUp></div>
      </div>

      <app-chart-input
        [showLegend]="false"
        [showLabel]="false"
        [view]="undefined"
        [data]="[
          { name: borrowersFeesLabel, value: revenueReport.BorrowerFeesPercentage[0].Actual },
          { name: borrowersInterestLabel, value: revenueReport.BorrowerInterestPercentage[0].Actual },
          { name: merchantFeesLabel, value: revenueReport.MerchantFeesPercentage[0].Actual }
        ]"
        [chartType]="'pie'"
        [dataNameAttribute]="'name'"
        [title]="''"
        [colorScheme]="chartColorScheme"
      >
      </app-chart-input>
    </div>

    <div class="row clearfix" @labelAnimation>
      <label class="rm-mt num anim" style="display: block">
        <span class="wide">
          <strong style="color: #ce066b"> {{ borrowersFeesLabel }} </strong>
        </span>

        <strong class="pill" style="background-color: #ce066b">
          {{ revenueReport.BorrowerFeesPercentage[0].Actual }}%
        </strong>
      </label>

      <hr/>

      <label class="rm-mt num anim" style="display: block">
        <span class="wide">
          <strong style="color: #774692"> {{ borrowersInterestLabel }} </strong>
        </span>

        <strong class="pill" style="background-color: #774692">
          {{ revenueReport.BorrowerInterestPercentage[0].Actual }}%
        </strong>
      </label>

      <hr/>

      <label class="rm-mt num anim" style="display: block">
        <span class="wide">
          <strong style="color: #168bdd"> {{ merchantFeesLabel }} </strong>
        </span>

        <strong class="pill" style="background-color: #168bdd">
          {{ revenueReport.MerchantFeesPercentage[0].Actual }}%
        </strong>
      </label>
    </div>
  </div>

  <div
    class="accrued-chart"
    *ngIf="revenueReport"
    style="width: 400px; background-color: #f8f8ff; padding: 10px; border-radius: 5px"
  >
    <div class="row clearfix">
      <div class="pull-left">
        <h3 class="sm-h3 sr-title rm-mb rm-mt">Accrued</h3>
      </div>
      <hr/>
    </div>

    <div class="row clearfix rel">
      <div class="total anim" style="display: flex; justify-content: center">
        {{ chartCurrencyCode }}
        <div [endVal]="revenueReport.TotalRevenue_Accrued[0].Accrued_Net" countUp></div>
      </div>

      <app-chart-input
        [showLegend]="false"
        [showLabel]="false"
        [view]="undefined"
        [data]="[
          { name: borrowersFeesLabel, value: revenueReport.BorrowerFeesPercentage[0].Accrued },
          { name: borrowersInterestLabel, value: revenueReport.BorrowerInterestPercentage[0].Accrued },
          { name: merchantFeesLabel, value: revenueReport.MerchantFeesPercentage[0].Accrued }
        ]"
        [chartType]="'pie'"
        [dataNameAttribute]="'name'"
        [title]="''"
        [colorScheme]="chartColorScheme"
      >
      </app-chart-input>
    </div>

    <div class="row clearfix" @labelAnimation>
      <label class="rm-mt num anim" style="display: block">
        <span class="wide">
          <strong style="color: #ce066b"> {{ borrowersFeesLabel }} </strong>
        </span>

        <strong class="pill" style="background-color: #ce066b">
          {{ revenueReport.BorrowerFeesPercentage[0].Accrued }}%
        </strong>
      </label>

      <hr/>

      <label class="rm-mt num anim" style="display: block">
        <span class="wide">
          <strong style="color: #774692"> {{ borrowersInterestLabel }} </strong>
        </span>

        <strong class="pill" style="background-color: #774692">
          {{ revenueReport.BorrowerInterestPercentage[0].Accrued }}%
        </strong>
      </label>

      <hr/>

      <label class="rm-mt num anim" style="display: block">
        <span class="wide">
          <strong style="color: #168bdd"> {{ merchantFeesLabel }} </strong>
        </span>

        <strong class="pill" style="background-color: #168bdd">
          {{ revenueReport.MerchantFeesPercentage[0].Accrued }}%
        </strong>
      </label>
    </div>
  </div>
</div>

<ng-template #emptyListMessage>
  <app-empty-list-message></app-empty-list-message>
</ng-template>
