import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
  selector: 'app-wiki-main',
  templateUrl: './wiki-main.component.html',
  styleUrls: ['./wiki-main.component.css'],
})
export class WikiMainComponent implements OnInit {
  @ViewChild('sidenav', { static: true }) sidenav: MatSidenav;
  navMode = 'side';

  constructor() {}

  ngOnInit() {
    if (window.innerWidth < 1200) {
      this.navMode = 'over';
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (event.target.innerWidth < 1200) {
      this.navMode = 'over';
      this.sidenav.close();
    }
    if (event.target.innerWidth > 1200) {
      this.navMode = 'side';
      this.sidenav.open();
    }
  }
}
