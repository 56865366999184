<div class="row clearfix" *ngIf="medicalHistory">
  <div class="row flex flex-center justify-between" *ngIf="title">
    <h2 class="sr-title pull-left">{{ title }}</h2>
  </div>

  <div class="row clearfix">
    <mat-card class="card clearfix">
      <div class="card-header primary-gradient-img clearfix inModal">
        <div class="row clearfix">
          <!-- merchant calculated name -->
          <div class="row titleArea clearfix">
            <app-ep-document-view-image
              class="full-width healthhistory-v"
              [defaultPicture]="
                'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/profile_placeholder.png)'
              "
              [link]="profileLink"
            >
            </app-ep-document-view-image>

            <h2 class="summary-header rm-m" *ngIf="isConsumer != true">
              {{ medicalHistory['Card.Name'] }}
              <span class="subLabel"
              >Created At:
                <strong>{{ medicalHistory['DateTimeCreated'] | customDateTime }}</strong>
                <span
                  class="chip ml noCap mat-elevation-z2"
                  *ngIf="medicalHistory['DateTimeCreated']"
                  [style.background]="util.daysPastColor(medicalHistory['DateTimeCreated'])"
                >
                  {{ medicalHistory['DateTimeCreated'] | TimeLabelPast }} ago</span
                >
                <span
                  class="ml chip mat-elevation-z2"
                  *ngIf="medicalHistory['Status']"
                  [ngStyle]="{
                    'background-color': medicalHistory['Status'] === 'Complete' ? 'rgb(90, 186, 71)' : 'red'
                  }"
                >
                  {{ medicalHistory['Status'] }}
                  <span *ngIf="medicalHistory['Date.Completed'] != '0000-00-00'">
                    ({{ medicalHistory['Date.Completed'] | TimeLabelPast }} ago)
                  </span>
                </span>
                <span
                  class="ml chip mat-elevation-z2"
                  [style.background]="getProgressColor(getProgress(medicalHistory))"
                  matTooltip="Progress: {{ getProgress(medicalHistory) }}%"
                >
                  {{ getProgress(medicalHistory) }}%
                </span>
              </span>
            </h2>

            <h2 class="summary-header rm-m" *ngIf="isConsumer == true">
              {{ medicalHistory['Merchant.Name'] }}
              <span class="subLabel"
              >Created At:
                <strong>{{ medicalHistory['DateTimeCreated'] | customDateTime }}</strong>
                <span
                  class="chip ml noCap mat-elevation-z2"
                  *ngIf="medicalHistory['DateTimeCreated']"
                  [style.background]="util.daysPastColor(medicalHistory['DateTimeCreated'])"
                >
                  {{ medicalHistory['DateTimeCreated'] | TimeLabelPast }} ago</span
                >
                <span
                  class="ml chip mat-elevation-z2"
                  *ngIf="medicalHistory['Status']"
                  [ngStyle]="{
                    'background-color': medicalHistory['Status'] === 'Complete' ? 'rgb(90, 186, 71)' : 'red'
                  }"
                >
                  {{ medicalHistory['Status'] }}
                  <span *ngIf="medicalHistory['Date.Completed'] != '0000-00-00'">
                    ({{ medicalHistory['Date.Completed'] | TimeLabelPast }} ago)
                  </span>
                </span>
                <span
                  class="ml chip mat-elevation-z2"
                  [style.background]="getProgressColor(getProgress(medicalHistory))"
                  matTooltip="Progress: {{ getProgress(medicalHistory) }}%"
                >
                  {{ getProgress(medicalHistory) }}%
                </span>
              </span>
            </h2>
          </div>
          <div class="pull-right drug" *ngIf="drugAlert == true && noWarning == false">
            <p class="drug-alert mat-elevation-z8">DRUG ALERT</p>
          </div>
        </div>
      </div>

      <mat-accordion class="health-hist-accord">
        <mat-expansion-panel [expanded]="step == 0" (opened)="setStep(0)">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3 class="sr-title rm-m">
                <mat-icon class="fas {{'fa-tooth' | translate}}"></mat-icon>
                {{ "KEYWORD.dental" | translate | titlecase }}
                <mat-icon
                  class="fas fa-exclamation-triangle"
                  *ngIf="
                    noWarning == false &&
                    (medicalHistory['HasDentalProblem'] === '1' ||
                      medicalHistory['CleansHowOften'] === '0' ||
                      medicalHistory['TerrifiedLevel'] >= '4')
                  "
                >
                </mat-icon>
              </h3>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <h4>General information</h4>
          <div class="row clearfix health-items">
            <div class="block" [class.yes]="medicalHistory['HasDentalProblem'] == 1">
              <mat-icon class="fas fa-grimace"></mat-icon>
              <p class="rm-mb" *ngIf="isConsumer != true">
                {{"KEYWORD.patient" | translate | titlecase}} has declared an
                immediate {{ 'KEYWORD.dental' | translate }} problem
              </p>
              <p class="rm-mb" *ngIf="isConsumer == true">
                You declared an immediate {{ 'KEYWORD.dental' | translate }} problem
              </p>

              <h3 class="primary-color" *ngIf="noWarning == false && medicalHistory['HasDentalProblem'] == 1">
                Yes
                <mat-icon class="fas fa-exclamation-triangle"></mat-icon>
              </h3>
              <h3 class="accent-color" *ngIf="medicalHistory['HasDentalProblem'] === '0'">No</h3>
              <div class="row clearfix" *ngIf="medicalHistory['HasDentalProblem'] === '1'">
                <hr/>
                <p class="rm-mb">Description of problem</p>
                <h3>{{ medicalHistory['DentalProblemDescription'] }}</h3>
              </div>
            </div>

            <div class="block" [class.yes]="medicalHistory['TerrifiedLevel'] >= '2'">
              <mat-icon class="fas fa-flushed"></mat-icon>
              <p class="rm-mb" *ngIf="isConsumer != true">{{"KEYWORD.patient" | translate | titlecase}} fear
                of {{ 'KEYWORD.dental' | translate }} work</p>
              <p class="rm-mb" *ngIf="isConsumer == true">Your fear of {{ 'KEYWORD.dental' | translate }} work</p>
              <ngx-gauge
                class="full-width"
                [size]="130"
                [type]="gaugeType"
                [value]="medicalHistory['TerrifiedLevel']"
                [label]="'Level of fear'"
                [max]="5"
                [thresholds]="lessIsMore"
                [thick]="10"
                [cap]="'round'"
              >
              </ngx-gauge>
              <mat-icon
                class="fas fa-exclamation-triangle"
                *ngIf="noWarning == false && medicalHistory['TerrifiedLevel'] >= 4"
              ></mat-icon>
            </div>
          </div>
          <h4>Sensitivity</h4>
          <div class="row clearfix health-items">
            <div class="block" [class.yes]="medicalHistory['SensitiveTeethWithHot'] > '0'">
              <mat-icon class="fas {{'fa-tooth' | translate}}"></mat-icon>
              <p class="rm-mb">
                <span *ngIf="isConsumer != true">Sensitive to heat:</span>
                <span *ngIf="isConsumer == true">Are you sensitive to heat?</span>
              </p>
              <h3 class="primary-color" *ngIf="medicalHistory['SensitiveTeethWithHot'] > '0'">Yes</h3>
              <h3 class="accent-color" *ngIf="medicalHistory['SensitiveTeethWithHot'] === '0'">No</h3>

              <div class="row clearfix">
                <hr/>
                <ngx-gauge
                  class="full-width"
                  [size]="130"
                  [type]="gaugeType"
                  [value]="medicalHistory['SensitiveTeethWithHot']"
                  [label]="'Level of sensitivity - Heat (1 - 5)'"
                  [max]="5"
                  [thresholds]="lessIsMore"
                  [thick]="12"
                  [cap]="'round'"
                >
                </ngx-gauge>
              </div>
            </div>

            <div class="block" [class.yes]="medicalHistory['SensitiveTeethWithCold'] > '0'">
              <mat-icon class="fas {{'fa-tooth' | translate}}"></mat-icon>
              <p class="rm-mb">
                <span *ngIf="isConsumer != true">Sensitive to cold:</span>
                <span *ngIf="isConsumer == true">Are you sensitive to cold?</span>
              </p>
              <h3 class="primary-color" *ngIf="medicalHistory['SensitiveTeethWithCold'] > '0'">Yes</h3>
              <h3 class="accent-color" *ngIf="medicalHistory['SensitiveTeethWithCold'] === '0'">No</h3>

              <div class="row clearfix">
                <hr/>
                <ngx-gauge
                  class="full-width"
                  [size]="130"
                  [type]="gaugeType"
                  [value]="medicalHistory['SensitiveTeethWithCold']"
                  [label]="'Level of sensitivity - Cold (1 - 5)'"
                  [max]="5"
                  [thresholds]="lessIsMore"
                  [thick]="12"
                  [cap]="'round'"
                >
                </ngx-gauge>
              </div>
            </div>

            <div class="block" [class.yes]="medicalHistory['SensitiveTeethWithSweet'] > '0'">
              <mat-icon class="fas {{'fa-tooth' | translate}}"></mat-icon>
              <p class="rm-mb">
                <span *ngIf="isConsumer != true">Sensitive to sweet:</span>
                <span *ngIf="isConsumer == true">Are you sensitive to sweet?</span>
              </p>
              <h3 class="primary-color" *ngIf="medicalHistory['SensitiveTeethWithSweet'] > '0'">Yes</h3>
              <h3 class="accent-color" *ngIf="medicalHistory['SensitiveTeethWithSweet'] === '0'">No</h3>

              <div class="row clearfix">
                <hr/>
                <ngx-gauge
                  class="full-width"
                  [size]="130"
                  [type]="gaugeType"
                  [value]="medicalHistory['SensitiveTeethWithSweet']"
                  [label]="'Level of sensitivity - Sweet (1 - 5)'"
                  [max]="5"
                  [thresholds]="lessIsMore"
                  [thick]="12"
                  [cap]="'round'"
                >
                </ngx-gauge>
              </div>
            </div>

            <div class="block" [class.yes]="medicalHistory['SensitiveTeethWithChew'] > '0'">
              <mat-icon class="fas {{'fa-tooth' | translate}}"></mat-icon>
              <p class="rm-mb">
                <span *ngIf="isConsumer != true">Sensitive to chewing:</span>
                <span *ngIf="isConsumer == true">Are you sensitive to chewing?</span>
              </p>
              <h3 class="primary-color" *ngIf="medicalHistory['SensitiveTeethWithChew'] > '0'">Yes</h3>
              <h3 class="accent-color" *ngIf="medicalHistory['SensitiveTeethWithChew'] === '0'">No</h3>

              <div class="row clearfix">
                <hr/>
                <ngx-gauge
                  class="full-width"
                  [size]="130"
                  [type]="gaugeType"
                  [value]="medicalHistory['SensitiveTeethWithChew']"
                  [label]="'Level of sensitivity - Chewing (1 - 5)'"
                  [max]="5"
                  [thresholds]="lessIsMore"
                  [thick]="12"
                  [cap]="'round'"
                >
                </ngx-gauge>
              </div>
            </div>
          </div>
          <h4>Pain / Soreness</h4>
          <div class="row clearfix health-items">
            <div class="block" [class.yes]="medicalHistory['GumsBleed'] === '1'">
              <mat-icon class="fas fa-tint"></mat-icon>
              <p class="rm-mb">
                <span *ngIf="isConsumer != true"> Gums bleed from brushing:</span>
                <span *ngIf="isConsumer == true">Do your gums bleed from brushing?</span>
              </p>
              <h3 class="primary-color" *ngIf="medicalHistory['GumsBleed'] === '1'">Yes</h3>
              <h3 class="accent-color" *ngIf="medicalHistory['GumsBleed'] === '0'">No</h3>
            </div>
            <div class="block" [class.yes]="medicalHistory['HasSoreMuscles'] === '1'">
              <mat-icon class="fas fa-dumbbell"></mat-icon>
              <p class="rm-mb">
                <span *ngIf="isConsumer != true"> Suffers sore jaw muscles: </span>
                <span *ngIf="isConsumer == true">Do your suffer sore jaw muscles?</span>
              </p>
              <h3 class="primary-color" *ngIf="medicalHistory['HasSoreMuscles'] === '1'">Yes</h3>
              <h3 class="accent-color" *ngIf="medicalHistory['HasSoreMuscles'] === '0'">No</h3>
            </div>
            <div class="block" [class.yes]="medicalHistory['HurtsWhenBiteHard'] === '1'">
              <mat-icon class="fas fa-frown-open"></mat-icon>
              <p class="rm-mb">
                <span *ngIf="isConsumer != true"> Feels pain when biting hard:</span>
                <span *ngIf="isConsumer == true">Do you feel pain when biting hard?</span>
              </p>
              <h3 class="primary-color" *ngIf="medicalHistory['HurtsWhenBiteHard'] === '1'">Yes</h3>
              <h3 class="accent-color" *ngIf="medicalHistory['HurtsWhenBiteHard'] === '0'">No</h3>
            </div>
            <div class="block" [class.yes]="medicalHistory['HasHeadacheOrJawOrFacialPain'] === '1'">
              <mat-icon class="fas fa-frown"></mat-icon>
              <p class="rm-mb">
                <span *ngIf="isConsumer != true"> Experiences headaches/Jaw pain:</span>
                <span *ngIf="isConsumer == true">Do you experience headaches/Jaw pain?</span>
              </p>
              <h3 class="primary-color" *ngIf="medicalHistory['HasHeadacheOrJawOrFacialPain'] === '1'">Yes</h3>
              <h3 class="accent-color" *ngIf="medicalHistory['HasHeadacheOrJawOrFacialPain'] === '0'">No</h3>
            </div>
          </div>
          <h4>Bite information</h4>
          <div class="row clearfix health-items">
            <div class="block" [class.yes]="medicalHistory['HasBadBreath'] > '0'">
              <mat-icon class="fas fa-dizzy"></mat-icon>
              <p class="rm-mb" *ngIf="isConsumer != true">Suffers bad breath</p>
              <p class="rm-mb" *ngIf="isConsumer == true">Do you suffer bad breath?</p>
              <h3 class="primary-color" *ngIf="medicalHistory['HasBadBreath'] > '0'">Yes</h3>
              <h3 class="accent-color" *ngIf="medicalHistory['HasBadBreath'] === '0'">No</h3>
              <div class="row clearfix" *ngIf="medicalHistory['HasBadBreath'] > '0'">
                <hr/>
                <ngx-gauge
                  class="full-width"
                  *ngIf="isConsumer != true"
                  [size]="100"
                  [type]="gaugeType"
                  [value]="medicalHistory['HasBadBreath']"
                  [label]="('KEYWORD.patient' | translate | titlecase) + ' rating (1-5)'"
                  [max]="5"
                  [thresholds]="lessIsMore"
                  [thick]="10"
                  [cap]="'round'"
                >
                </ngx-gauge>

                <ngx-gauge
                  class="full-width"
                  *ngIf="isConsumer == true"
                  [size]="100"
                  [type]="gaugeType"
                  [value]="medicalHistory['HasBadBreath']"
                  [label]="'Your rating (1-5)'"
                  [max]="5"
                  [thresholds]="lessIsMore"
                  [thick]="10"
                  [cap]="'round'"
                >
                </ngx-gauge>
              </div>
            </div>
            <div class="block" [class.yes]="medicalHistory['HasClickingLockingJaw'] === '1'">
              <mat-icon class="fas fa-teeth-open"></mat-icon>
              <p class="rm-mb" *ngIf="isConsumer != true">Suffers clicking/locking jaw</p>
              <p class="rm-mb" *ngIf="isConsumer == true">Do you suffer clicking/locking jaw?</p>
              <h3 class="primary-color" *ngIf="medicalHistory['HasClickingLockingJaw'] === '1'">Yes</h3>
              <h3 class="accent-color" *ngIf="medicalHistory['HasClickingLockingJaw'] === '0'">No</h3>
            </div>

            <div class="block" [class.yes]="medicalHistory['WearsNightPlate'] == 1">
              <mat-icon class="fas fa-teeth"></mat-icon>
              <p class="rm-mb" *ngIf="isConsumer != true">Wears a splint/bite plate</p>
              <p class="rm-mb" *ngIf="isConsumer == true">Do you wear a splint/bite plate?</p>
              <h3 class="primary-color" *ngIf="medicalHistory['WearsNightPlate'] === '1'">Yes</h3>
              <h3 class="accent-color" *ngIf="medicalHistory['WearsNightPlate'] === '0'">No</h3>
            </div>
            <div class="block" [class.yes]="medicalHistory['DoesClenchOrGrind'] === '1'">
              <mat-icon class="fas fa-grimace"></mat-icon>
              <p class="rm-mb" *ngIf="isConsumer != true">Suffers clenching/grinding of teeth</p>
              <p class="rm-mb" *ngIf="isConsumer == true">Do you suffer clenching/grinding of teeth?</p>
              <h3 class="primary-color" *ngIf="medicalHistory['DoesClenchOrGrind'] === '1'">Yes</h3>
              <h3 class="accent-color" *ngIf="medicalHistory['DoesClenchOrGrind'] === '0'">No</h3>
            </div>

            <div class="block" [class.yes]="medicalHistory['HasPeridontalTreatment'] == 1">
              <mat-icon class="fas {{'fa-tooth' | translate}}"></mat-icon>
              <p class="rm-mb">
                <span *ngIf="isConsumer != true"> Previous periodontal work:</span>
                <span *ngIf="isConsumer == true">Did you have previous periodontal work?</span>
              </p>
              <h3 class="primary-color" *ngIf="medicalHistory['HasPeridontalTreatment'] === '1'">Yes</h3>
              <h3 class="accent-color" *ngIf="medicalHistory['HasPeridontalTreatment'] === '0'">No</h3>
            </div>

            <div class="block" [class.yes]="medicalHistory['HasBiteAdjusted'] == 1">
              <mat-icon class="fas fa-laugh-beam"></mat-icon>
              <p class="rm-mb" *ngIf="isConsumer != true">Has had previous bite adjustment</p>
              <p class="rm-mb" *ngIf="isConsumer == true">Did you have previous bite adjustment?</p>
              <h3 class="primary-color" *ngIf="medicalHistory['HasBiteAdjusted'] === '1'">Yes</h3>
              <h3 class="accent-color" *ngIf="medicalHistory['HasBiteAdjusted'] === '0'">No</h3>
            </div>

            <div class="block" [class.yes]="medicalHistory['DoesBiteCheeks'] == 1">
              <mat-icon class="fas fa-tired"></mat-icon>
              <p class="rm-mb">
                <span *ngIf="isConsumer != true">Bites cheeks:</span>
                <span *ngIf="isConsumer == true">Do you bite your cheeks?</span>
              </p>
              <h3 class="primary-color" *ngIf="medicalHistory['DoesBiteCheeks'] === '1'">Yes</h3>
              <h3 class="accent-color" *ngIf="medicalHistory['DoesBiteCheeks'] === '0'">No</h3>
            </div>
            <div class="block" [class.yes]="medicalHistory['FoodCaughtInTeeth'] === '1'">
              <mat-icon class="fas fa-utensils"></mat-icon>
              <p class="rm-mb" *ngIf="isConsumer != true">Gets food caught in teeth</p>
              <p class="rm-mb" *ngIf="isConsumer == true">Do you get food caught in teeth?</p>
              <h3 class="primary-color" *ngIf="medicalHistory['FoodCaughtInTeeth'] === '1'">Yes</h3>
              <h3 class="accent-color" *ngIf="medicalHistory['FoodCaughtInTeeth'] === '0'">No</h3>
            </div>
          </div>
          <h4>Smile information</h4>
          <div class="row clearfix health-items">
            <div class="block" [class.yes]="printableMedicalHistory['cleansHowOften']">
              <mat-icon class="fas {{'fa-tooth' | translate}}"></mat-icon>
              <ngx-gauge
                class="full-width"
                [size]="130"
                [type]="gaugeType"
                [value]="medicalHistory['CleansHowOften']"
                [label]="'Cleans teeth how often'"
                [max]="3"
                [thresholds]="moreIsLess"
                [thick]="10"
                [cap]="'round'"
                [append]="'/day'"
              >
              </ngx-gauge>

              <mat-icon
                class="fas fa-exclamation-triangle"
                *ngIf="noWarning == false && medicalHistory['CleansHowOften'] === '0'"
              ></mat-icon>
            </div>

            <div class="block" [class.yes]="medicalHistory['ConsciousOfColourAppearance'] > '0'">
              <mat-icon class="fas fa-palette"></mat-icon>
              <ngx-gauge
                class="full-width"
                [size]="130"
                [type]="gaugeType"
                [value]="medicalHistory['ConsciousOfColourAppearance']"
                [label]="'Conscious of teeth colour'"
                [max]="5"
                [thresholds]="lessIsMore"
                [thick]="10"
                [cap]="'round'"
              >
              </ngx-gauge>
              <mat-icon
                class="fas fa-exclamation-triangle"
                *ngIf="noWarning == false && medicalHistory['ConsciousOfColourAppearance'] >= 4"
              >
              </mat-icon>
            </div>
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel [expanded]="step == 1" (opened)="setStep(1)">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3 class="sr-title rm-m">
                <mat-icon class="fas fa-pills"></mat-icon>
                Illness
                <span class="pill" *ngIf="illness && illness.length > 0">{{ illness.length }}</span>
              </h3>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div class="row clearfix" *ngIf="illness && illness.length > 0">
            <app-illness-list [illnessList]="illness" [isCreate]="false" [disableModify]="true"></app-illness-list>
          </div>

          <div class="row clearfix" *ngIf="!illness || (illness && illness.length <= 0)">
            <app-empty-list-message
              *ngIf="isConsumer != true"
              [title]="'No Illness'"
              [message]="'Your ' + ('KEYWORD.patient' | translate | titlecase) + ' has no  history Illnesses'"
            >
            </app-empty-list-message>

            <app-empty-list-message
              *ngIf="isConsumer == true"
              [title]="'No Illness'"
              [message]="'You have no  history Illnesses'"
            >
            </app-empty-list-message>
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel [expanded]="step == 2" (opened)="setStep(2)">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3 class="sr-title rm-m">
                <mat-icon class="fas fa-allergies"></mat-icon>
                Allergy
                <span class="pill" *ngIf="allergy && allergy.length > 0">{{ allergy.length }}</span>
              </h3>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div class="row clearfix" *ngIf="allergy && allergy.length > 0">
            <app-allergy-list [allergyList]="allergy" [disableModify]="true" [isConsumer]="false"></app-allergy-list>
          </div>
          <div class="row clearfix" *ngIf="!allergy || (allergy && allergy.length <= 0)">
            <app-empty-list-message
              *ngIf="isConsumer != true"
              [title]="'No Allergies'"
              [message]="'Your ' + ('KEYWORD.patient' | translate | titlecase) + ' has no Allergies'"
            >
            </app-empty-list-message>

            <app-empty-list-message
              *ngIf="isConsumer == true"
              [title]="'No Allergies'"
              [message]="'You have no Allergies'"
            >
            </app-empty-list-message>
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel [expanded]="step == 3" (opened)="setStep(3)">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3 class="sr-title rm-m">
                <mat-icon class="fas fa-heartbeat"></mat-icon> General &amp; Lifestyle
                <mat-icon
                  class="fas fa-exclamation-triangle"
                  *ngIf="
                    noWarning == false &&
                    (medicalHistory['HasSeriousIllness'] == 1 ||
                      medicalHistory['SmokingTimes'] == 5 ||
                      medicalHistory['DrinkingTimes'] == 5)
                  "
                >
                </mat-icon>
              </h3>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div class="row clearfix">
            <h4 *ngIf="gender && gender != 'M'">Pregnancy information</h4>
            <div class="row clearfix health-items" *ngIf="gender && gender != 'M'">
              <div class="block" [class.yes]="medicalHistory['IsPregnant'] === '1'">
                <mat-icon>pregnant_woman</mat-icon>
                <p class="rm-mb" *ngIf="isConsumer != true">Is pregnant</p>
                <p class="rm-mb" *ngIf="isConsumer == true">Are you pregnant?</p>
                <h3 class="primary-color" *ngIf="medicalHistory['IsPregnant'] === '1'">Yes</h3>
                <h3 class="accent-color" *ngIf="medicalHistory['IsPregnant'] === '0'">No</h3>

                <div class="block" *ngIf="medicalHistory['IsPregnant'] === '1'">
                  <hr/>
                  <mat-icon>pregnant_woman</mat-icon>
                  <p class="rm-mb">Pregnant for</p>
                  <h3 class="primary-color">{{ medicalHistory['PregnancyMonths'] }} months</h3>
                </div>
              </div>

              <div class="block" [class.yes]="medicalHistory['IsBreastFeeding'] === '1'">
                <mat-icon>child_care</mat-icon>
                <p class="rm-mb">Breastfeeding</p>
                <h3 class="primary-color" *ngIf="medicalHistory['IsBreastFeeding'] === '1'">Yes</h3>
                <h3 class="accent-color" *ngIf="medicalHistory['IsBreastFeeding'] === '0'">No</h3>
              </div>
            </div>

            <h4>Lifestyle information</h4>
            <div class="row clearfix health-items">
              <div class="block" [class.yes]="medicalHistory['IsSmoking'] == 1">
                <mat-icon class="fas fa-smoking"></mat-icon>
                <p class="rm-mb" *ngIf="isConsumer != true">Smoker</p>
                <p class="rm-mb" *ngIf="isConsumer == true">Are you a smoker?</p>
                <h3 class="primary-color" *ngIf="medicalHistory['IsSmoking'] === '1'">Yes</h3>
                <h3 class="accent-color" *ngIf="medicalHistory['IsSmoking'] === '0'">No</h3>

                <div class="row clearfix" *ngIf="medicalHistory['IsSmoking'] === '1'">
                  <hr/>
                  <p class="rm-mb">Amount of cigarettes per day</p>
                  <h3 class="primary-color" *ngIf="medicalHistory['SmokingTimes'] === '1'">A couple</h3>
                  <h3 class="primary-color" *ngIf="medicalHistory['SmokingTimes'] === '2'">5 - 10</h3>
                  <h3 class="primary-color" *ngIf="medicalHistory['SmokingTimes'] === '3'">10 - 20</h3>
                  <h3 class="primary-color" *ngIf="medicalHistory['SmokingTimes'] === '4'">20 - 30</h3>
                  <h3 class="primary-color" *ngIf="noWarning == false && medicalHistory['SmokingTimes'] === '5'">
                    Pack a day or more
                    <mat-icon class="fas fa-exclamation-triangle"></mat-icon>
                  </h3>
                </div>
              </div>

              <div class="block" [class.yes]="medicalHistory['DrinkWine'] === '1'">
                <mat-icon class="fas fa-wine-bottle"></mat-icon>
                <p class="rm-mb" *ngIf="isConsumer != true">Drinks alcohol</p>
                <p class="rm-mb" *ngIf="isConsumer == true">Do you drink alcohol?</p>
                <h3 class="primary-color" *ngIf="medicalHistory['DrinkWine'] === '1'">Yes</h3>
                <h3 class="accent-color" *ngIf="medicalHistory['DrinkWine'] === '0'">No</h3>

                <div class="row clearfix" *ngIf="medicalHistory['DrinkWine'] === '1'">
                  <hr/>
                  <p class="rm-mb">Amount of standard drinks per day</p>
                  <h3 class="primary-color" *ngIf="medicalHistory['DrinkingTimes'] === '1'">A couple</h3>
                  <h3 class="primary-color" *ngIf="medicalHistory['DrinkingTimes'] === '2'">5 - 10</h3>
                  <h3 class="primary-color" *ngIf="medicalHistory['DrinkingTimes'] === '3'">10 - 20</h3>
                  <h3 class="primary-color" *ngIf="medicalHistory['DrinkingTimes'] === '4'">20 - 30</h3>
                  <h3 class="primary-color" *ngIf="noWarning == false && medicalHistory['DrinkingTimes'] === '5'">
                    Lots
                    <mat-icon class="fas fa-exclamation-triangle"></mat-icon>
                  </h3>
                </div>
              </div>

              <div class="block" [class.yes]="medicalHistory['HasFamilyStress'] === '1'">
                <mat-icon class="fas fa-poo-storm"></mat-icon>
                <p class="rm-mb" *ngIf="isConsumer != true">Has family stress</p>
                <p class="rm-mb" *ngIf="isConsumer == true">Do you have family stress?</p>
                <h3 class="primary-color" *ngIf="medicalHistory['HasFamilyStress'] === '1'">Yes</h3>
                <h3 class="accent-color" *ngIf="medicalHistory['HasFamilyStress'] === '0'">No</h3>
                <div class="row clearfix" *ngIf="medicalHistory['HasFamilyStress'] === '1'">
                  <hr/>
                  <p class="rm-mb">Description of stress</p>
                  <h3>{{ medicalHistory['FamilyStressDescription'] }}</h3>
                </div>
              </div>
            </div>

            <h4>General information</h4>
            <div class="row clearfix health-items">
              <div class="block" [class.yes]="medicalHistory['ReadsWritesEnglish'] === '1'">
                <!-- <mat-icon class="fas fa-pen-alt"></mat-icon> -->
                <p class="rm-mb" *ngIf="isConsumer != true">{{"KEYWORD.patient" | translate | titlecase}} can read and
                  write in
                  English</p>
                <p class="rm-mb" *ngIf="isConsumer == true">Can you read and write in English?</p>
                <h3 class="primary-color" *ngIf="medicalHistory['ReadsWritesEnglish'] === '1'">Yes</h3>
                <h3 class="accent-color" *ngIf="medicalHistory['ReadsWritesEnglish'] === '0'">No</h3>
              </div>

              <div class="block" [class.yes]="medicalHistory['NeedsHelpWithForm'] === '1'">
                <!-- <mat-icon class="fas fa-info-circle"></mat-icon> -->
                <p class="rm-mb" *ngIf="isConsumer != true">Needs help completing health history</p>
                <p class="rm-mb" *ngIf="isConsumer == true">Do need help completing health history?</p>
                <h3 class="primary-color" *ngIf="medicalHistory['NeedsHelpWithForm'] === '1'">Yes</h3>
                <h3 class="accent-color" *ngIf="medicalHistory['NeedsHelpWithForm'] === '0'">No</h3>
              </div>

              <div class="block" [class.yes]="medicalHistory['SpeakInPrivateToStaff'] === '1'">
                <!-- <mat-icon class="fas fa-comment-dots"></mat-icon> -->
                <p class="rm-mb" *ngIf="isConsumer != true">Wants to speak to someone in private</p>
                <p class="rm-mb" *ngIf="isConsumer == true">Do you want to speak to someone in private?</p>
                <h3 class="primary-color" *ngIf="medicalHistory['SpeakInPrivateToStaff'] === '1'">Yes</h3>
                <h3 class="accent-color" *ngIf="medicalHistory['SpeakInPrivateToStaff'] === '0'">No</h3>
              </div>

              <div class="block" [class.yes]="hasHealthFund == true">
                <p class="rm-mb" *ngIf="isConsumer != true">Has Private Health Insurance</p>
                <p class="rm-mb" *ngIf="isConsumer == true">Do you have Private Health Insurance?</p>
                <h3 class="primary-color" *ngIf="hasHealthFund == true">Yes</h3>
                <hr *ngIf="hasHealthFund == true; healthFund"/>
                <div class="row clearfix flex" *ngIf="hasHealthFund == true && healthFund">
                  <div class="full-width">
                    <p class="rm-mb">Health fund:</p>
                    <h3>{{ healthFund.name }}</h3>
                  </div>
                  <div class="full-width">
                    <p class="rm-mb">Member number:</p>
                    <h3>{{ healthFund.number }}</h3>
                  </div>
                </div>

                <h3 class="accent-color" *ngIf="hasHealthFund == false">No</h3>
              </div>

              <div class="block" [class.yes]="hasGp == true">
                <p class="rm-mb" *ngIf="isConsumer != true">Has a
                  General {{ "KEYWORD.practitioner" | translate | titlecase }}</p>
                <p class="rm-mb" *ngIf="isConsumer == true">
                  Do you have a General {{ "KEYWORD.practitioner" | translate | titlecase }}?
                </p>
                <h3 class="primary-color" *ngIf="hasGp == true">Yes</h3>
                <button
                  class="btn-small"
                  *ngIf="hasGp == true"
                  (click)="viewContact(gpDoctorID)"
                  mat-button
                  color="primary"
                >
                  <mat-icon>search</mat-icon>
                  View details
                </button>
                <h3 class="accent-color" *ngIf="hasGp == false">No</h3>
              </div>

              <div class="block" [class.yes]="medicalHistory['FormFilledOnBehalf'] === '1'">
                <!-- <mat-icon class="fas fa-user-edit"></mat-icon> -->
                <p class="rm-mb" *ngIf="isConsumer != true">Has a parent / guardian</p>
                <p class="rm-mb" *ngIf="isConsumer == true">Do you have a parent / guardian?</p>
                <h3 class="primary-color" *ngIf="medicalHistory['FormFilledOnBehalf'] === '1'">Yes</h3>
                <button
                  class="btn-small"
                  *ngIf="medicalHistory['FormFilledOnBehalf'] === '1'"
                  (click)="viewContact(guardianID)"
                  mat-button
                  color="primary"
                >
                  <mat-icon>search</mat-icon>
                  View details
                </button>
                <h3 class="accent-color" *ngIf="medicalHistory['FormFilledOnBehalf'] === '0'">No</h3>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </mat-card>
  </div>

  <div class="row clearfix mt full-width">
    <button
      class="full-back btn-large pull-right"
      [printTitle]="
        ('KEYWORD.dental' | translate | titlecase) +
        'History - ' +
        medicalHistory['Card.Name'] +
        '-' +
        (medicalHistory['Date.Activated'] | customDateTime)
      "
      mat-raised-button
      color="primary"
      printSectionId="medicalHistory"
      styleSheetFile="/assets/styles/print-history.css, /assets/styles/application.css, /assets/styles/css/fontawesome.min.css"
      ngxPrint
    >
      <span style="display: flex; justify-content: center; align-items: center"
      ><mat-icon>print</mat-icon> &nbsp;Print page</span
      >
    </button>

    <mat-card
      class="treatment secondary-gradient rel animate first botton clearfix mt full-width mb mr"
      *ngIf="medicalHistory && updateMedicationHistory == true && medicalHistory['Status'] !== 'Complete'"
      [matRippleColor]="'rgba(255,255,255,0.14)'"
      (click)="proceedEvent()"
      matRipple
    >
      <div class="row clearfix flex valueLabels">
        <div class="col-left full-width clearfix text-center">
          <h2 class="summary-header sr-title rm-m mt text-center">You have not finished yet!</h2>
          <p class="rm-mt text-center">
            <span>Click Continue to finish from where you left last time</span>
          </p>
        </div>
        <div class="col-right full-width clearfix valueLabels text-right">
          <button class="full-width full-height btn-outline pop" mat-button>
            <span class="mobHide"> Continue! <mat-icon>chevron_right</mat-icon> </span>
          </button>
        </div>
      </div>
      <mat-icon class="background fas {{'fa-tooth' | translate}}"></mat-icon>
    </mat-card>
  </div>

  <!-- print section below -->

  <div class="health-hist-accord" id="medicalHistory" #medicalHistory *ngIf="medicalHistory">
    <div class="section row clearfix mt">
      <div class="pull-left">
        <h1 class="summary-header rm-m sm-mb">{{"KEYWORD.patient" | translate | titlecase}} Health History</h1>
      </div>
      <div class="pull-right">
        <p class="bold rm-mt" *ngIf="merchant">{{ merchant['TradingAs'] }}</p>
        <p class="small rm-m" *ngIf="merchant">Phone: {{ merchant['phones.Number'] | customPhone }}</p>
        <p class="small rm-m" *ngIf="merchant">Address: {{ merchant['addresses.Calculated'] }}</p>
      </div>
      <div class="pull-right">
        <app-merchant-view-picture
          class="merchantView"
          *ngIf="merchant && merchant['ID']"
          [merchantID]="merchant['ID']"
        >
        </app-merchant-view-picture>
      </div>
    </div>

    <div class="section row clearfix">
      <div class="row clearfix header-block">
        <div class="pull-left">
          <app-ep-document-view-image
            class="full-width patientIcon"
            [defaultPicture]="
              'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/profile_placeholder.png)'
            "
            [link]="profileLink"
          >
          </app-ep-document-view-image>
        </div>
        <div class="row clearfix pull-left">
          <h2 class="summary-header rm-m sm-mb">
            {{ this.printableMedicalHistory['name'] }}
          </h2>

          <p class="small rm-m" *ngIf="email">
            Email: <strong>{{ email }}</strong>
          </p>
          <p class="small rm-m" *ngIf="mobile && mobile != 'undefined'">
            mobile:
            <strong>{{ mobile | customPhone }}</strong>
          </p>
          <p class="small rm-m" *ngIf="phoneNumber && phoneNumber != 'undefined'">
            Phone: <strong>{{ phoneNumber | customPhone }}</strong>
          </p>

          <p class="small rm-m" *ngIf="dateOfBirth">
            Date of Birth: <strong>{{ dateOfBirth | customDate }}</strong>
          </p>

          <p class="small rm-m" *ngIf="addressCalculated">
            Address: <strong>{{ addressCalculated }}</strong>
          </p>
        </div>
        <div class="pull-right" *ngIf="noWarning == false">
          <p class="drug-alert rm-m" *ngIf="drugAlert == true">DRUG ALERT</p>
        </div>
      </div>

      <div class="row clearfix full-width">
        <div class="full-width clearfix">
          <span
            class="chip ml pull-right"
            *ngIf="medicalHistory['Status']"
            [ngStyle]="{
              'background-color': printableMedicalHistory['Status'] === 'Complete' ? 'rgb(90, 186, 71)' : 'red'
            }"
          >
            {{ printableMedicalHistory['Status'] }}
          </span>
          <h4>{{ "KEYWORD.dental" | translate | titlecase }} History</h4>
        </div>
        <div class="row clearfix">
          <div class="row clearfix">
            <p class="rm-mt">
              Created at:
              <strong>{{ printableMedicalHistory['dateTimeCreated'] | customDateTime }}</strong>
            </p>
            <p class="rm-mt">
              Status:
              <strong>{{ printableMedicalHistory['status'] | translate }}</strong>
            </p>
            <p class="rm-mt" *ngIf="medicalHistory['dateCompleted'] && medicalHistory['dateCompleted'] != '0000-00-00'">
              Completed and Signed On:
              <strong>{{ medicalHistory['dateCompleted'] | customDate }}</strong>
            </p>
          </div>
          <div class="row clearfix" [class.yes]="printableMedicalHistory['hasDentalProblem']">
            <p class="rm-m main">
              {{"KEYWORD.patient" | translate | titlecase}} has declared an immediate dental problem:
              <strong class="primary-color" *ngIf="noWarning == false && printableMedicalHistory['hasDentalProblem']"
              >Yes
                <mat-icon class="fas fa-exclamation-triangle"></mat-icon>
              </strong>
              <strong class="accent-color" *ngIf="!printableMedicalHistory['hasDentalProblem']">No</strong>
            </p>

            <div class="row clearfix" *ngIf="printableMedicalHistory['dentalProblemDescription']">
              <p class="rm-mb">Description of problem:</p>
              <p class="rm-mt">
                <strong>{{ printableMedicalHistory['dentalProblemDescription'] }}</strong>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="row clearfix">
        <!--      <div class="row clearfix flex">-->
        <div class="block half-width">
          <h4>Sensitivity</h4>
          <div class="row clearfix" [class.yes]="printableMedicalHistory['sensitiveTeethWithHot']">
            <p class="rm-m main">
              <span *ngIf="isConsumer != true">Sensitive to heat: </span>
              <span *ngIf="isConsumer == true">Are you sensitive to heat? </span>
              <strong class="primary-color" *ngIf="printableMedicalHistory['sensitiveTeethWithHot']">Yes</strong>
              <strong class="accent-color" *ngIf="!printableMedicalHistory['sensitiveTeethWithHot']">No</strong>
            </p>
            <p class="small" *ngIf="printableMedicalHistory['sensitiveTeethWithHot']">
              Level of sensitivity: {{ printableMedicalHistory['sensitiveTeethWithHotLevel'] }}/5
            </p>
          </div>
          <div class="row clearfix" [class.yes]="printableMedicalHistory['sensitiveTeethWithCold'] > 0">
            <p class="rm-mb main">
              <span *ngIf="isConsumer != true">Sensitive to cold: </span>
              <span *ngIf="isConsumer == true">Are you sensitive to cold? </span>
              <strong class="primary-color" *ngIf="printableMedicalHistory['sensitiveTeethWithCold']">Yes</strong>
              <strong class="accent-color" *ngIf="!printableMedicalHistory['sensitiveTeethWithCold']">No</strong>
            </p>
            <p class="small" *ngIf="printableMedicalHistory['sensitiveTeethWithCold']">
              Level of sensitivity: {{ printableMedicalHistory['sensitiveTeethWithColdLevel'] }}/5
            </p>
          </div>
          <div class="row clearfix" [class.yes]="printableMedicalHistory['sensitiveTeethWithSweet']">
            <p class="rm-mb main">
              <span *ngIf="isConsumer != true">Sensitive to sweet: </span>
              <span *ngIf="isConsumer == true">Are you sensitive to sweet? </span>
              <strong class="primary-color" *ngIf="printableMedicalHistory['sensitiveTeethWithSweet']">Yes</strong>
              <strong class="accent-color" *ngIf="!printableMedicalHistory['sensitiveTeethWithSweet']">No</strong>
            </p>
            <p class="small" *ngIf="printableMedicalHistory['sensitiveTeethWithSweet']">
              Level of sensitivity: {{ printableMedicalHistory['sensitiveTeethWithSweetLevel'] }}/5
            </p>
          </div>
          <div class="row clearfix" [class.yes]="printableMedicalHistory['sensitiveTeethWithChew']">
            <p class="rm-mb main">
              <span *ngIf="isConsumer != true">Sensitive to chewing: </span>
              <span *ngIf="isConsumer == true">Are you sensitive to chewing? </span>
              <strong class="primary-color" *ngIf="printableMedicalHistory['sensitiveTeethWithChew']">Yes</strong>
              <strong class="accent-color" *ngIf="!printableMedicalHistory['sensitiveTeethWithChew']">No</strong>
            </p>
            <p class="small" *ngIf="printableMedicalHistory['sensitiveTeethWithChew']">
              Level of sensitivity: {{ printableMedicalHistory['sensitiveTeethWithChewLevel'] }}/5
            </p>
          </div>
        </div>
        <div class="block half-width">
          <h4>Pain / Soreness</h4>
          <div class="row clearfix" [class.yes]="printableMedicalHistory['gumsBleed']">
            <p class="rm-m main">
              <span *ngIf="isConsumer != true"> Gums bleed from brushing: </span>
              <span *ngIf="isConsumer == true">Do your gums bleed from brushing? </span>
              <strong class="primary-color" *ngIf="printableMedicalHistory['gumsBleed']">Yes</strong>
              <strong class="accent-color" *ngIf="!printableMedicalHistory['gumsBleed']">No</strong>
            </p>
          </div>
          <div class="row clearfix" [class.yes]="printableMedicalHistory['hasSoreMuscles']">
            <p class="rm-mb main">
              <span *ngIf="isConsumer != true"> Suffers sore jaw muscles: </span>
              <span *ngIf="isConsumer == true">Do your suffer sore jaw muscles? </span>
              <strong class="primary-color" *ngIf="printableMedicalHistory['hasSoreMuscles']">Yes</strong>
              <strong class="accent-color" *ngIf="!printableMedicalHistory['hasSoreMuscles']">No</strong>
            </p>
          </div>
          <div class="row clearfix" [class.yes]="printableMedicalHistory['hurtsWhenBiteHard']">
            <p class="rm-mb main">
              <span *ngIf="isConsumer != true"> Feels pain when biting hard: </span>
              <span *ngIf="isConsumer == true">do you feel pain when biting hard? </span>
              <strong class="primary-color" *ngIf="printableMedicalHistory['hurtsWhenBiteHard']">Yes</strong>
              <strong class="accent-color" *ngIf="!printableMedicalHistory['hurtsWhenBiteHard']">No</strong>
            </p>
          </div>
          <div class="row clearfix" [class.yes]="printableMedicalHistory['HasHeadacheOrJawOrFacialPain']">
            <p class="rm-mb main">
              <span *ngIf="isConsumer != true"> Experiences headaches/Jaw pain: </span>
              <span *ngIf="isConsumer == true">Do you experience headaches/Jaw pain? </span>
              <strong class="primary-color" *ngIf="printableMedicalHistory['HasHeadacheOrJawOrFacialPain']">Yes</strong>
              <strong class="accent-color" *ngIf="!printableMedicalHistory['HasHeadacheOrJawOrFacialPain']">No</strong>
            </p>
          </div>
        </div>
        <!--      </div>-->
      </div>

      <div class="row clearfix">
        <h4>Bite information</h4>

        <div class="row clearfix flex">
          <div class="block half-width">
            <div class="row clearfix" [class.yes]="printableMedicalHistory['hasBadBreath']">
              <p class="rm-m main">
                <span *ngIf="isConsumer != true"> Suffers bad breath: </span>
                <span *ngIf="isConsumer == true">do you suffer bad breath? </span>
                <strong class="primary-color" *ngIf="printableMedicalHistory['hasBadBreath']">Yes</strong>
                <strong class="accent-color" *ngIf="!printableMedicalHistory['hasBadBreath']">No</strong>
              </p>
              <p *ngIf="printableMedicalHistory['hasBadBreath']">
                {{"KEYWORD.patient" | translate | titlecase}} rating:
                <strong>{{ printableMedicalHistory['hasBadBreathLevel'] }}
                /5</strong>
              </p>
            </div>
            <div class="row clearfix" [class.yes]="printableMedicalHistory['hasClickingLockingJaw']">
              <p class="rm-mb main">
                <span *ngIf="isConsumer != true"> Suffers clicking/locking jaw: </span>
                <span *ngIf="isConsumer == true">do you suffer clicking/locking jaw? </span>
                <strong class="primary-color" *ngIf="printableMedicalHistory['hasClickingLockingJaw']">Yes</strong>
                <strong class="accent-color" *ngIf="!printableMedicalHistory['hasClickingLockingJaw']">No</strong>
              </p>
            </div>

            <div class="row clearfix" [class.yes]="printableMedicalHistory['wearsNightPlate']">
              <p class="rm-mb">
                <span *ngIf="isConsumer != true"> Wears a splint/bite plate: </span>
                <span *ngIf="isConsumer == true">do you wear a splint/bite plate? </span>

                <strong class="primary-color" *ngIf="printableMedicalHistory['wearsNightPlate']">Yes</strong>
                <strong class="accent-color" *ngIf="!printableMedicalHistory['wearsNightPlate']">No</strong>
              </p>
            </div>
            <div class="row clearfix" [class.yes]="printableMedicalHistory['doesClenchOrGrind']">
              <p class="rm-mb main">
                <span *ngIf="isConsumer != true"> Suffers clenching/grinding of teeth: </span>
                <span *ngIf="isConsumer == true">do you suffer clenching/grinding of teeth? </span>

                <strong class="primary-color" *ngIf="printableMedicalHistory['doesClenchOrGrind']">Yes</strong>
                <strong class="accent-color" *ngIf="!printableMedicalHistory['doesClenchOrGrind']">No</strong>
              </p>
            </div>
          </div>

          <div class="block half-width">
            <div class="row clearfix" [class.yes]="printableMedicalHistory['hasPeridontalTreatment']">
              <p class="rm-m main">
                <span *ngIf="isConsumer != true"> Previous periodontal work: </span>
                <span *ngIf="isConsumer == true">do you have previous periodontal work? </span>

                <strong class="primary-color" *ngIf="printableMedicalHistory['hasPeridontalTreatment']">Yes</strong>
                <strong class="accent-color" *ngIf="!printableMedicalHistory['hasPeridontalTreatment']">No</strong>
              </p>
            </div>

            <div class="row clearfix" [class.yes]="printableMedicalHistory['hasBiteAdjusted']">
              <p class="rm-mb main">
                <span *ngIf="isConsumer != true"> Has had previous bite adjustment: </span>
                <span *ngIf="isConsumer == true">did you have previous bite adjustment? </span>

                <strong class="primary-color" *ngIf="printableMedicalHistory['hasBiteAdjusted']">Yes</strong>
                <strong class="accent-color" *ngIf="!printableMedicalHistory['hasBiteAdjusted']">No</strong>
              </p>
            </div>

            <div class="row clearfix" [class.yes]="printableMedicalHistory['doesBiteCheeks']">
              <p class="rm-mb main">
                <span *ngIf="isConsumer != true">Bites cheeks: </span>
                <span *ngIf="isConsumer == true">Do you bite cheeks? </span>
                <strong class="primary-color" *ngIf="printableMedicalHistory['doesBiteCheeks']">Yes</strong>
                <strong class="accent-color" *ngIf="!printableMedicalHistory['doesBiteCheeks']">No</strong>
              </p>
            </div>
            <div class="row clearfix" [class.yes]="printableMedicalHistory['hasBadBreath']">
              <p class="rm-mb main">
                <span *ngIf="isConsumer != true"> Gets food caught in teeth: </span>
                <span *ngIf="isConsumer == true">Do you get food caught in teeth? </span>

                <strong class="primary-color" *ngIf="printableMedicalHistory['hasBadBreath']">Yes</strong>
                <strong class="accent-color" *ngIf="!printableMedicalHistory['hasBadBreath']">No</strong>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="row clearfix flex">
        <div class="block half-width">
          <h4>Smile information</h4>
          <div class="row clearfix">
            <div class="row clearfix" [class.yes]="printableMedicalHistory['cleansHowOften']">
              <p class="main rm-m">
                Cleans teeth <strong>{{ printableMedicalHistory['cleansHowOftenTimes'] }} times/day</strong>
              </p>
            </div>

            <div class="row clearfix" [class.yes]="printableMedicalHistory['consciousOfColourAppearance']">
              <p class="main">
                Conscious of teeth colour:
                <strong>{{ printableMedicalHistory['consciousOfColourAppearanceLevel'] }}/5</strong>
              </p>
            </div>
          </div>
        </div>

        <div class="block half-width">
          <h4>
            Lifestyle information
            <mat-icon
              class="fas fa-exclamation-triangle"
              *ngIf="
                noWarning == false &&
                (printableMedicalHistory['hasSeriousIllness'] ||
                  printableMedicalHistory['smokingTimes'] === '5' ||
                  printableMedicalHistory['smokingTimes'] === '5')
              "
            >
            </mat-icon>
          </h4>

          <div class="row clearfix" [class.yes]="printableMedicalHistory['isSmoking']">
            <p class="rm-m main">
              Smoker:
              <strong class="primary-color" *ngIf="printableMedicalHistory['isSmoking']">Yes</strong>
              <strong class="accent-color" *ngIf="!printableMedicalHistory['isSmoking']">No</strong>
            </p>

            <div class="row clearfix" *ngIf="printableMedicalHistory['isSmoking']">
              <p class="rm-mb">
                Amount of cigarettes per day:
                <strong class="primary-color" *ngIf="printableMedicalHistory['smokingTimes'] === '1'">A couple</strong>
                <strong class="primary-color" *ngIf="printableMedicalHistory['smokingTimes'] === '2'">5 - 10</strong>
                <strong class="primary-color" *ngIf="printableMedicalHistory['smokingTimes'] === '3'">10 - 20</strong>
                <strong class="primary-color" *ngIf="printableMedicalHistory['smokingTimes'] === '4'">20 - 30</strong>
                <strong
                  class="primary-color"
                  *ngIf="noWarning == false && printableMedicalHistory['smokingTimes'] === '5'"
                >Pack a day or more
                  <mat-icon class="fas fa-exclamation-triangle"></mat-icon>
                </strong>
              </p>
            </div>
          </div>

          <div class="row clearfix" [class.yes]="printableMedicalHistory['drinkWine']">
            <p class="rm-mb main">
              Drinks alcohol:
              <strong class="primary-color" *ngIf="printableMedicalHistory['drinkWine']">Yes</strong>
              <strong class="accent-color" *ngIf="!printableMedicalHistory['drinkWine']">No</strong>
            </p>

            <div class="row clearfix" *ngIf="printableMedicalHistory['drinkWine']">
              <p class="rm-mb">
                Amount of standard drinks per day:
                <strong class="primary-color" *ngIf="printableMedicalHistory['drinkingTimes'] === '1'">A couple</strong>
                <strong class="primary-color" *ngIf="printableMedicalHistory['drinkingTimes'] === '2'">5 - 10</strong>
                <strong class="primary-color" *ngIf="printableMedicalHistory['drinkingTimes'] === '3'">10 - 20</strong>
                <strong class="primary-color" *ngIf="printableMedicalHistory['drinkingTimes'] === '4'">20 - 30</strong>
                <strong
                  class="primary-color"
                  *ngIf="noWarning == false && printableMedicalHistory['drinkingTimes'] === '5'"
                >Lots
                  <mat-icon class="fas fa-exclamation-triangle"></mat-icon>
                </strong>
              </p>
            </div>
          </div>

          <div class="row clearfix" [class.yes]="printableMedicalHistory['hasFamilyStress']">
            <p class="rm-mb main">
              Has family stress:
              <strong class="primary-color" *ngIf="printableMedicalHistory['hasFamilyStress']">Yes</strong>
              <strong class="accent-color" *ngIf="!printableMedicalHistory['hasFamilyStress']">No</strong>
            </p>
            <div class="row clearfix" *ngIf="printableMedicalHistory['hasFamilyStress']">
              <p class="rm-mb">
                Description of stress: <strong>{{ printableMedicalHistory['familyStressDescription'] }}</strong>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        class="row clearfix"
        *ngIf="printableMedicalHistory['isPregnant'] || printableMedicalHistory['isBreastFeeding']"
      >
        <h4 *ngIf="gender && gender != 'M'">Pregnancy information</h4>
        <div class="row clearfix" *ngIf="gender && gender != 'M'">
          <div class="row clearfix" [class.yes]="printableMedicalHistory['isPregnant']">
            <p class="rm-m main">
              Is pregnant:
              <strong class="primary-color" *ngIf="printableMedicalHistory['isPregnant']">Yes</strong>
              <strong class="accent-color" *ngIf="!printableMedicalHistory['isPregnant']">No</strong>
            </p>

            <div class="row clearfix" *ngIf="printableMedicalHistory['isPregnant']">
              <p class="rm-mb main">
                Pregnant for
                <strong class="primary-color">{{ printableMedicalHistory['pregnancyMonths'] }} months</strong>
              </p>
            </div>
          </div>

          <div class="row clearfix" [class.yes]="printableMedicalHistory['isBreastFeeding']">
            <p class="rm-mb main">
              Breastfeeding:
              <strong class="primary-color" *ngIf="printableMedicalHistory['isBreastFeeding']">Yes</strong>
              <strong class="accent-color" *ngIf="!printableMedicalHistory['isBreastFeeding']">No</strong>
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="row clearfix all-ill" *ngIf="(illness && illness.length > 0) || (allergy && allergy.length > 0)">
      <h4 class="sr-title mb" *ngIf="illness && illness.length > 0">Illnesses</h4>

      <div class="row clearfix" *ngIf="illness && illness.length > 0">
        <app-illness-list [illnessList]="illness" [disableModify]="true" [isCreate]="false" [printMode]="true">
        </app-illness-list>
      </div>

      <h4 class="sr-title mb" *ngIf="allergy && allergy.length > 0">Allergies</h4>

      <div class="row clearfix" *ngIf="allergy && allergy.length > 0">
        <app-allergy-list [allergyList]="allergy" [disableModify]="true" [isConsumer]="false" [printMode]="true">
        </app-allergy-list>
      </div>
    </div>

    <div class="section">
      <div class="row clearfix">
        <h4>General information</h4>
        <div class="row clearfix">
          <div class="block half-width">
            <div class="row clearfix" [class.yes]="printableMedicalHistory['pregnancyMonths']">
              <p class="rm-mb main">
                {{"KEYWORD.patient" | translate | titlecase}} can read and write in English:
                <strong class="primary-color" *ngIf="printableMedicalHistory['pregnancyMonths']">Yes</strong>
                <strong class="accent-color" *ngIf="!printableMedicalHistory['pregnancyMonths']">No</strong>
              </p>
            </div>

            <div class="row clearfix" [class.yes]="printableMedicalHistory['needsHelpWithForm']">
              <p class="rm-mb main">
                Needs help completing health history:
                <strong class="primary-color" *ngIf="printableMedicalHistory['needsHelpWithForm']">Yes</strong>
                <strong class="accent-color" *ngIf="!printableMedicalHistory['needsHelpWithForm']">No</strong>
              </p>
            </div>

            <div class="row clearfix" [class.yes]="printableMedicalHistory['speakInPrivateToStaff']">
              <p class="rm-mb main">
                Wants to speak to someone in private:
                <strong class="primary-color" *ngIf="printableMedicalHistory['speakInPrivateToStaff']">Yes</strong>
                <strong class="accent-color" *ngIf="!printableMedicalHistory['speakInPrivateToStaff']">No</strong>
              </p>
            </div>
          </div>

          <div class="block half-width">
            <div class="row clearfix" [class.yes]="printableMedicalHistory['terrified']">
              <p class="rm-mb main">
                {{"KEYWORD.patient" | translate | titlecase}} fear of dental work:
                <strong>{{ printableMedicalHistory['terrifiedLevel'] }}/5</strong>
              </p>
            </div>

            <div class="row clearfix" [class.yes]="hasGp == true">
              <p class="rm-mb main">
                Has a General {{ "KEYWORD.practitioner" | translate | titlecase }}:
                <strong class="primary-color" *ngIf="hasGp == true">Yes</strong>
                <strong class="accent-color" *ngIf="hasGp == false">No</strong>
              </p>
            </div>

            <div
              class="row clearfix"
              *ngIf="printableMedicalHistory['formFilledOnBehalf']"
              [class.yes]="printableMedicalHistory['formFilledOnBehalf']"
            >
              <!-- <mat-icon class="fas fa-user-edit"></mat-icon> -->
              <p class="rm-mb main">
                Has a parent / guardian:
                <strong class="primary-color" *ngIf="printableMedicalHistory['formFilledOnBehalf']">Yes</strong>
                <strong class="accent-color" *ngIf="!printableMedicalHistory['formFilledOnBehalf']">No</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section">
      <div class="row clearfix flex">
        <!-- GUARDIAN -->
        <div class="block" *ngIf="medicalHistory['FormFilledOnBehalf'] == 1" [class.half-width]="guardian">
          <h4>Guardian</h4>
          <div class="row clearfix" *ngIf="guardian">
            <p class="rm-mb" *ngIf="guardian['CalculatedName']">
              Name: <strong>{{ guardian['CalculatedName'] }}</strong>
            </p>
            <p class="rm-mb" *ngIf="guardian['ContactType.Label']">
              Relationship: <strong>{{ guardian['ContactType.Label'] }}</strong>
            </p>
            <p class="rm-mb" *ngIf="guardian['DateOfBirth']">
              Date of Birth: <strong>{{ guardian['DateOfBirth'] }}</strong>
            </p>
            <p class="rm-mb" *ngIf="guardian['phones.Number']">
              Phone: <strong>{{ guardian['phones.Number'] }}</strong>
            </p>
            <p class="rm-mb" *ngIf="guardian['emails.Email']">
              Email: <strong>{{ guardian['emails.Email'] }}</strong>
            </p>
            <p class="rm-mb" *ngIf="guardian['addresses.Calculated']">
              Address: <strong>{{ guardian['addresses.Calculated'] }}</strong>
            </p>
          </div>
        </div>

        <!-- GP -->
        <div class="block" *ngIf="hasGp == true" [class.half-width]="gpDoctor">
          <h4>General {{ "KEYWORD.practitioner" | translate | titlecase }}</h4>

          <div class="row clearfix" *ngIf="gpDoctor">
            <p class="rm-mb" *ngIf="gpDoctor['CalculatedName']">
              Name: <strong>{{ gpDoctor['CalculatedName'] }}</strong>
            </p>
            <p class="rm-mb" *ngIf="gpDoctor['TradingAs']">
              {{ "KEYWORD.practice" | translate | titlecase}}: <strong>{{ gpDoctor['TradingAs'] }}</strong>
            </p>
            <p class="rm-mb" *ngIf="gpDoctor['ContactType.Label']">
              Type: <strong>{{ gpDoctor['ContactType.Label'] }}</strong>
            </p>
            <p class="rm-mb" *ngIf="gpDoctor['phones.Number']">
              Phone: <strong>{{ gpDoctor['phones.Number'] }}</strong>
            </p>
            <p class="rm-mb" *ngIf="gpDoctor['emails.Email']">
              Email: <strong>{{ gpDoctor['emails.Email'] }}</strong>
            </p>
            <p class="rm-mb" *ngIf="gpDoctor['addresses.Calculated']">
              Address: <strong>{{ gpDoctor['addresses.Calculated'] }}</strong>
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="section">
      <div class="row clearfix flex">
        <!-- MEDICARE -->
        <div class="block half-width" *ngIf="mediCare" [class.half-width]="mediCare && hasHealthFund == true">
          <h4>Medicare</h4>

          <div class="row clearfix">
            <p class="rm-mb" *ngIf="mediCare && mediCare.number">
              Card number: <strong>{{ mediCare.number }}</strong>
            </p>
            <p class="rm-mb" *ngIf="mediCare && mediCare.reference">
              Reference #: <strong>{{ mediCare.reference }}</strong>
            </p>
            <p class="rm-mb" *ngIf="mediCare && mediCare.expiry">
              Card expiry: <strong>{{ mediCare.expiry }}</strong>
            </p>
          </div>
        </div>

        <!-- PRIVATE HEALTH -->
        <div class="block" *ngIf="hasHealthFund == true" [class.half-width]="mediCare && hasHealthFund == true">
          <h4>Private Health</h4>

          <div class="row clearfix" [class.yes]="hasHealthFund == true">
            <p class="rm-mb main">
              Has Private Health Insurance:
              <strong class="primary-color" *ngIf="hasHealthFund == true">Yes</strong>
              <strong class="accent-color" *ngIf="hasHealthFund == false">No</strong>
            </p>
            <div class="row clearfix" *ngIf="hasHealthFund == true && healthFund">
              <p class="rm-mb" *ngIf="healthFund.name">
                Health fund: <strong>{{ healthFund.name }}</strong>
              </p>
              <p class="rm-mb" *ngIf="healthFund.number">
                Member number: <strong>{{ healthFund.number }}</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="pull-right power-by">
        <img
          [src]="'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/power_by_SR.png'"
        />
      </div>
    </div>
  </div>
</div>
