import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'environments/environment';
import { ClipboardService } from 'ngx-clipboard';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { UtilsService } from '../../../shared/services/utils.service';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { DentistViewComponent } from '../../dentist/dentist-view/dentist-view.component';
import { MerchantService } from '../../merchant/shared/merchant.service';
import { ProductUtil } from '../../product/shared/productUtil.type';
import { DocumentLinkShareComponent } from '../document-link-share/document-link-share.component';
import { DocumentService } from '../shared/document.service';

@Component({
  selector: 'document-link-detailed-view',
  templateUrl: './document-link-detailed-view.component.html',
  styleUrls: ['./document-link-detailed-view.component.css'],
  animations: [
    trigger('simpleFadeAnimation', [
      state('in', style({ opacity: 1, height: '*' })),
      transition(':enter', [style({ opacity: 0, height: 0 }), animate(300)]),
      transition(':leave', animate(300, style({ opacity: 0, height: 0 }))),
    ]),
  ],
})
export class DocumentLinkDetailedViewComponent implements OnInit {
  @Input()
  landingPageID = '290D0CFA29E69C45A597F3D6FA322F03';
  isPromoterOrAdmin = false;
  sessionType;
  currentDentist;
  currentPractice;
  link;

  urlQR;
  urlQRShort;

  env = environment.nodeUrl;

  landingPage;

  settings = Settings.global;
  totalVisitor = 0;
  iFrameSrc;
  iFrameSrcSecured;
  loadIFrame = false;

  chartVisitorData = [];
  merchantID;
  productUtil = new ProductUtil();
  label;
  title;
  description;
  downloadQRCodeDental = new EventEmitter();
  constructor(
    private utilService: UtilsService,
    private _clipboardService: ClipboardService,
    private _sanitizer: DomSanitizer,
    private merchantService: MerchantService,
    private authenticationService: AuthenticationService,
    private activeRoute: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef,
    private landingPageService: DocumentService,
    private router: Router
  ) {}

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      if (params['landingPageID']) {
        this.landingPageID = params['landingPageID'];
      }

      this.authenticationService.isPromoterOrAdmin().subscribe((isPromoterOrAdmin) => {
        this.isPromoterOrAdmin = isPromoterOrAdmin;
        this.authenticationService.getSessionType().subscribe((res) => {
          this.sessionType = res;
          this.authenticationService.getCurrentDentist().subscribe((dentist) => {
            if (dentist && dentist.ID) {
              this.currentDentist = dentist;
              this.authenticationService.getCurrentPractice().subscribe((m) => {
                if (m && m.ID) {
                  this.currentPractice = m;

                  this.merchantID = m.ID;

                  this.setup();
                }
              });
            }
          });
        });
      });
    });
  }

  setup() {
    if (this.landingPageID) {
      this.landingPageService
        .getDocumentLink(this.landingPageID, { selectDocument: true }, this.sessionType)
        .subscribe((res) => {
          if (res && res.ID) {
            this.landingPage = res;

            this.addLinks();
            this.addChart();
          }
        });
    }
  }

  addLinks(updateIframe = true) {
    if (this.landingPage) {
      this.label = 'Practice Page';

      let urlQR = `${Settings.global['publicSiteFrontendLink']}/landing-page-overview/${this.landingPageID}/qr`;

      if (this.landingPage['IsDefault'] === '1') {
        this.link = `${Settings.global['publicSiteFrontendLink']}/landing-page-overview/${this.landingPageID}?m=${this.currentPractice.ID}&c=${this.currentDentist.ID}`;
        urlQR = `${Settings.global['publicSiteFrontendLink']}/landing-page-overview/${this.landingPageID}/qr?m=${this.currentPractice.ID}&c=${this.currentDentist.ID}`;
      } else {
        this.link = `${Settings.global['publicSiteFrontendLink']}/landing-page-overview/${this.landingPageID}`;
      }

      this.utilService.createShortcut(urlQR).subscribe((r) => {
        if (r && r.shortLink) {
          this.urlQR = r.shortLink;
        }
      });

      if (updateIframe == true) {
        this.updateIFrameSrc(this.link);
      }
    }
  }

  addChart() {
    if (this.landingPage) {
      this.totalVisitor = 0;
      this.chartVisitorData = [];

      if (this.landingPage['Facebook_Visitors']) {
        this.totalVisitor = this.totalVisitor + Number(this.landingPage['Facebook_Visitors']);
      }

      if (this.landingPage['Offer_Page_Visitors']) {
        this.totalVisitor = this.totalVisitor + Number(this.landingPage['Offer_Page_Visitors']);
      }

      if (this.landingPage['Practice_Site_Visitors']) {
        this.totalVisitor = this.totalVisitor + Number(this.landingPage['Practice_Site_Visitors']);
      }

      if (this.landingPage['Linkedin_Visitors']) {
        this.totalVisitor = this.totalVisitor + Number(this.landingPage['Linkedin_Visitors']);
      }
      if (this.landingPage['Instagram_Visitors']) {
        this.totalVisitor = this.totalVisitor + Number(this.landingPage['Instagram_Visitors']);
      }

      if (this.landingPage['Pinterest_Visitors']) {
        this.totalVisitor = this.totalVisitor + Number(this.landingPage['Pinterest_Visitors']);
      }
      if (this.landingPage['Twitter_Visitors']) {
        this.totalVisitor = this.totalVisitor + Number(this.landingPage['Twitter_Visitors']);
      }
      if (this.landingPage['Email_Visitors']) {
        this.totalVisitor = this.totalVisitor + Number(this.landingPage['Email_Visitors']);
      }

      if (this.landingPage['QR_Scan_Visitors']) {
        this.totalVisitor = this.totalVisitor + Number(this.landingPage['QR_Scan_Visitors']);
      }
      if (this.landingPage['SMS_Visitors']) {
        this.totalVisitor = this.totalVisitor + Number(this.landingPage['SMS_Visitors']);
      }

      if (this.landingPage['Other_Visitors']) {
        this.totalVisitor = this.totalVisitor + Number(this.landingPage['Other_Visitors']);
      }

      if (this.landingPage['Campaign_Visitors']) {
        this.totalVisitor = this.totalVisitor + Number(this.landingPage['Campaign_Visitors']);
      }
      this.chartVisitorData.push({
        name: `Email ${Math.trunc((Number(this.landingPage['Email_Visitors']) / Number(this.totalVisitor)) * 100)}%`,
        value: Number(this.landingPage['Email_Visitors']),
      });

      this.chartVisitorData.push({
        name: `SMS ${Math.trunc((Number(this.landingPage['SMS_Visitors']) / Number(this.totalVisitor)) * 100)}%`,
        value: Number(this.landingPage['SMS_Visitors']),
      });

      this.chartVisitorData.push({
        name: `Practice Website ${Math.trunc(
          (Number(this.landingPage['Practice_Site_Visitors']) / Number(this.totalVisitor)) * 100
        )}%`,
        value: Number(this.landingPage['Practice_Site_Visitors']),
      });

      this.chartVisitorData.push({
        name: `QR Scan ${Math.trunc(
          (Number(this.landingPage['QR_Scan_Visitors']) / Number(this.totalVisitor)) * 100
        )}%`,
        value: Number(this.landingPage['QR_Scan_Visitors']),
      });

      this.chartVisitorData.push({
        name: `Campaign landing page ${Math.trunc(
          (Number(this.landingPage['Campaign_Visitors']) / Number(this.totalVisitor)) * 100
        )}%`,
        value: Number(this.landingPage['Campaign_Visitors']),
      });

      this.chartVisitorData.push({
        name: `Facebook ${Math.trunc(
          (Number(this.landingPage['Facebook_Visitors']) / Number(this.totalVisitor)) * 100
        )}%`,
        value: Number(this.landingPage['Facebook_Visitors']),
      });
      this.chartVisitorData.push({
        name: `Linkedin ${Math.trunc(
          (Number(this.landingPage['Linkedin_Visitors']) / Number(this.totalVisitor)) * 100
        )}%`,
        value: Number(this.landingPage['Linkedin_Visitors']),
      });
      this.chartVisitorData.push({
        name: `Twitter ${Math.trunc(
          (Number(this.landingPage['Twitter_Visitors']) / Number(this.totalVisitor)) * 100
        )}%`,
        value: Number(this.landingPage['Twitter_Visitors']),
      });
      this.chartVisitorData.push({
        name: `Instagram ${Math.trunc(
          (Number(this.landingPage['Instagram_Visitors']) / Number(this.totalVisitor)) * 100
        )}%`,
        value: Number(this.landingPage['Instagram_Visitors']),
      });
      this.chartVisitorData.push({
        name: `Tips and Offers page ${Math.trunc(
          (Number(this.landingPage['Offer_Page_Visitors']) / Number(this.totalVisitor)) * 100
        )}%`,
        value: Number(this.landingPage['Offer_Page_Visitors']),
      });

      this.chartVisitorData.push({
        name: `Pinterest ${Math.trunc(
          (Number(this.landingPage['Pinterest_Visitors']) / Number(this.totalVisitor)) * 100
        )}%`,
        value: Number(this.landingPage['Pinterest_Visitors']),
      });
      this.chartVisitorData.push({
        name: `Other ${Math.trunc((Number(this.landingPage['Other_Visitors']) / Number(this.totalVisitor)) * 100)}%`,
        value: Number(this.landingPage['Other_Visitors']),
      });
    }
  }

  updateIFrameSrc(src: string): void {
    this.iFrameSrcSecured = this._sanitizer.bypassSecurityTrustResourceUrl(src);
  }
  previewPage(url) {
    window.open(url, '_blank');
  }

  onFrameLoad(e) {
    if (!this.loadIFrame) {
      this.loadIFrame = true;
      this.changeDetectorRef.detectChanges();
    } else {
      this.loadIFrame = true;
    }
  }

  linkCopy(url) {
    if (url) {
      this._clipboardService.copyFromContent(url);
      NotifyAppComponent.displayToast('success', 'Copied!', '');
    }
  }
  viewDentist(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(DentistViewComponent, {
        data: id,
        width: '600px',
      });
      ref.componentInstance.close.subscribe((res) => {
        if (res == true) {
          ref.close();
        }
      });
    }
  }

  shareSocialMedia() {}

  goToList(isTemplate = false) {
    this.router.navigate([
      '/merchant',
      {
        outlets: {
          page: ['document-link-list'],
        },
      },
    ]);
  }

  edit() {
    if (this.landingPage && this.landingPage.ID) {
      const m = this.merchantID || 'null';

      this.router.navigate([
        '/merchant',
        {
          outlets: {
            page: ['landing-page-edit', this.landingPage.ID, m, 'true'],
          },
        },
      ]);
    }
  }

  clone() {
    if (this.landingPage && this.landingPage.ID) {
      const m = this.merchantID || 'null';

      this.router.navigate([
        '/merchant',
        {
          outlets: {
            page: ['landing-page-clone', this.landingPage.ID, m, 'true'],
          },
        },
      ]);
    }
  }

  remove(id) {
    if (id) {
      const confirm = new ConfirmDialog(
        'fas fa-info',
        '',
        'Are you sure you want to remove this landing page ?',
        'No',
        'Yes'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation === false) {
          ref.close();
        } else {
          this.landingPageService.deleteDocumentLink(id, this.sessionType).subscribe((res) => {
            if (res) {
              NotifyAppComponent.displayToast(
                'success',
                'Successful operation',
                'The Landing Page has been successfully removed'
              );

              ref.close();
              this.router.navigate([
                '/merchant',
                {
                  outlets: {
                    page: ['document-link-list'],
                  },
                },
              ]);
            }
          });
        }
      });
    }
  }

  enable(ID) {
    const confirmDialog = new ConfirmDialog(
      'check_circle',
      'Enable Landing Page',
      '<p>Once the Landing Page is Active it will  appear as an option for the user.</p>',
      'Cancel',
      'Okay'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
      // SET TO PAYING CASH CARD
    });
    ref.componentInstance.onConfirm.subscribe((val) => {
      if (val == true) {
        ref.close();
        ref.afterClosed().subscribe((res) => {
          const payload = {
            isActive: true,
          };
          this.landingPageService.modifyDocumentLink(ID, payload, this.sessionType).subscribe((res) => {
            if (res && res.ID) {
              this.landingPage = res;
              this.addLinks();
              this.addChart();

              NotifyAppComponent.displayToast('success', 'Success!', 'Landing Page is Active.');
            }
          });
        });
      } else {
        ref.close();
      }
    });
  }

  disable(ID) {
    const confirmDialog = new ConfirmDialog(
      'cancel',
      'Disable Landing Page',
      '<p>Once the Landing Page is not Active it will not appear as an option for the user.</p>',
      'Cancel',
      'Okay'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
      // SET TO PAYING CASH CARD
    });
    ref.componentInstance.onConfirm.subscribe((val) => {
      if (val == true) {
        ref.close();
        ref.afterClosed().subscribe((res) => {
          const payload = {
            isActive: false,
          };
          this.landingPageService.modifyDocumentLink(ID, payload, this.sessionType).subscribe((res) => {
            if (res && res.ID) {
              this.landingPage = res;
              this.addLinks();
              this.addChart();

              NotifyAppComponent.displayToast('success', 'Success!', 'Landing Page is not Active.');
            }
          });
        });
      } else {
        ref.close();
      }
    });
  }

  share() {
    if (this.landingPage && this.landingPage.ID) {
      if (this.landingPage.IsActive === '1') {
        const ref2 = RootAppComponent.dialog.open(DocumentLinkShareComponent, {
          data: {
            landingPage: this.landingPage,
          },
          width: '80vw',
          panelClass: 'max-width-panel-1400',
        });

        ref2.componentInstance.close.subscribe((res) => {
          ref2.close();
        });
      } else {
        const confirmDialog = new ConfirmDialog(
          'check_circle',
          'Enable Landing Page',
          '<p>This Landing Page is <strong></strong>, Do you wish to Active it before proceed?</p>',
          'Cancel',
          'Okay'
        );

        const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
          data: confirmDialog,
          // SET TO PAYING CASH CARD
        });
        ref.componentInstance.onConfirm.subscribe((val) => {
          if (val == true) {
            ref.close();
            ref.afterClosed().subscribe((res) => {
              const payload = {
                isActive: true,
              };
              this.landingPageService
                .modifyDocumentLink(this.landingPage.ID, payload, this.sessionType)
                .subscribe((res) => {
                  if (res) {
                    NotifyAppComponent.displayToast('success', 'Success!', 'Landing Page is Active.');

                    const ref2 = RootAppComponent.dialog.open(DocumentLinkShareComponent, {
                      data: {
                        landingPage: this.landingPage,
                      },
                      width: '80vw',
                      panelClass: 'max-width-panel-1400',
                    });

                    ref2.componentInstance.close.subscribe((res) => {
                      ref2.close();
                    });
                  }
                });
            });
          } else {
            ref.close();
          }
        });
      }
    }
  }
}
