import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { AuthenticationService } from '../../authentication/shared/authentication.service';
import { SubscriptionPlanService } from '../shared/subscription-plan.service';
import { Settings } from '../../../shared/types/settings';

@Component({
  selector: 'app-feature-main-label-list',
  templateUrl: './feature-main-label-list.component.html',
  styleUrls: ['./feature-main-label-list.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.row', style({ opacity: 0 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.row', style({ opacity: 1 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class FeatureMainLabelListComponent implements OnInit {
  mainFeatures = [];

  sessionType;
  @Input()
  displayOwn = false;
  @Input()
  isMarketingOnly;

  @Input()
  isEmbed = false;
  @Input()
  allView = true;
  @Input()
  maxText = 500;
  @Input()
  windowControlMain = true;
  @Input()
  windowControl = true;
  @Input()
  displayFilter = true;
  @Input()
  hideDisplayCheck = false;
  root = '/merchant';
  @Input()
  displaySaveButtonOnBottom = true;

  @Input()
  displayCover = true;
  selectedLimitedIDs = [];
  selectedIDs = [];

  @Input()
  readOnly = false;

  @Input()
  canSelect = false;

  @Input()
  canSelectMain = false;

  @Input()
  canDragAndDrop = true;

  @Input()
  displaySaveButton = true;

  @Input()
  actionButton = true;

  @Input()
  saveOrder = new EventEmitter();

  saveOrderInner = new EventEmitter();

  @Output()
  getSelectedLimitedIDs = new EventEmitter();
  @Output()
  getSelectedIDs = new EventEmitter();
  @Output()
  getSaveOrder = new EventEmitter();
  @Input()
  defaultSelectedLimitedIDs = [];

  @Input()
  defaultSelectedIDs = [];

  selectLimit: any = {};
  select: any = {};
  resultOrder = {
    main: false,
  };
  @Input()
  hideEmptyFeatures = false;

  isConsumer = null;
  isMerchant = null;

  @Input()
  featureType = 'all';
  @Input()
  title = 'We’re built for growing your practice';
  @Input()
  description =
    'We provide world-class technology and support to help your practice grow. See how we can help with the many features our platform offers below.';

  context = Settings.global['context'];
  disableSaveButton = true;

  colapse = new EventEmitter();

  isAdminOrPromoter = false;

  @Output()
  getIsSelectionChange = new EventEmitter();

  isChanged = false;

  constructor(
    private subscriptionPlanService: SubscriptionPlanService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      if (params['featureType']) {
        this.featureType = params['featureType'];
      }

      if (this.featureType === 'consumer') {
        this.isConsumer = true;
        this.isMerchant = null;
      } else if (this.featureType === 'merchant') {
        this.isConsumer = null;
        this.isMerchant = true;
      } else {
        this.isConsumer = null;
        this.isMerchant = null;
      }

      this.authenticationService.isPromoterOrAdmin().subscribe((isAdminOrPromoter) => {
        this.isAdminOrPromoter = isAdminOrPromoter;
      });

      this.authenticationService.getSessionType().subscribe((st) => {
        this.sessionType = st;
        if (this.sessionType != 'admin' && this.sessionType != 'promoter') {
          this.readOnly = true;
        }

        this.setup();
      });
    });

    this.saveOrder.subscribe((res) => {
      if (this.displaySaveButton != true) {
        this.saveOrderEvent();
      } else {
        this.getSaveOrder.emit(true);
      }
    });
  }

  getIsSelectionChangeEvent(e) {
    if (e === true) {
      this.isChanged = true;
      this.getIsSelectionChange.emit(this.isChanged);
    }
  }

  setup() {
    if (this.featureType === 'consumer') {
      this.root = '/consumer';
    } else {
      this.root = '/merchant';
    }
    const p = {
      subFeatureFields:
        'ID,FE_Code,Is_Marketing_Only,Is_Side_Menu,Display_Order,Color,Icon,DateTimeCreated,Side_Menu_Label,Is_Private,Is_Merchant_Feature,Is_Consumer_Feature,Is_Main_Side_Menu,Feature_MainLabel_key,MainLabel,MainLabel_Is_Deactivated,MainLabel_DisplayOrder,SubLabel,Description,Is_Deactivated,Is_NewFeature',
      hideEmptyFeatures: this.hideEmptyFeatures,
      isConsumer: this.isConsumer,
      isMerchant: this.isMerchant,
      isMarketingOnly: this.isMarketingOnly,
    };
    this.subscriptionPlanService.getFeatureMainLabelListFull(p, this.sessionType).subscribe((res) => {
      if (res) {
        this.mainFeatures = res;
      }
    });
  }

  canHideDisplayEvent(d) {
    this.colapse.emit(d);
  }

  converTable(d) {
    return JSON.parse(JSON.stringify(d || []));
  }

  modifyReadOnly(d) {
    this.readOnly = d;
  }

  drop(event) {
    if (this.mainFeatures && this.mainFeatures.length > 0) {
      const currentList = _.orderBy(this.mainFeatures, ['Display_Order']);
      const currentIndex = event.currentIndex;
      const previousIndex = event.previousIndex;
      const item = currentList[previousIndex];
      currentList.splice(previousIndex, 1);
      currentList.splice(currentIndex, 0, item);
      for (let i = 0; i < currentList.length; i++) {
        currentList[i]['Display_Order'] = i + 1;
      }
      this.hideDisplayCheck = false;
      this.mainFeatures = JSON.parse(JSON.stringify(currentList));
      this.disableSaveButton = false;
    }
  }

  closeAll() {
    this.colapse.emit(true);
  }

  saveOrderEvent() {
    this.saveOrderInner.emit(true);
    if (this.disableSaveButton == true) {
      this.resultOrder['main'] = true;
    } else {
      this.resultOrder['main'] = false;
    }

    if (this.mainFeatures && this.mainFeatures.length > 0 && this.disableSaveButton != true) {
      const p = _.map(this.mainFeatures, (item) => {
        if (item && item.ID) {
          return {
            id: item.ID,
            displayOrder: item.Display_Order,
          };
        }
      });
      this.subscriptionPlanService.editFeatureMainLabelGroup(p).subscribe((res) => {
        if (res && res.length > 0) {
          this.disableSaveButton = true;
          if (this.displaySaveButton == true) {
            NotifyAppComponent.displayToast('success', 'Success!', 'Sort order is modified ');
          }
        }
        this.resultOrder['main'] = true;
        this.buildSaveOrderResult();
      });
    }
  }

  createNew() {
    this.router.navigate([this.root, { outlets: { page: ['main-feature-edit-create'] } }]);
  }

  getRemoveMain(id) {
    const index = this.mainFeatures.findIndex((item) => {
      if (item && item.ID && id && item.ID == id) {
        return true;
      }
      return false;
    });
    if (index != -1) {
      this.mainFeatures.splice(index, 1);
      this.mainFeatures = JSON.parse(JSON.stringify(this.mainFeatures));
    }
  }

  getSaveOrderInner(id, result) {
    this.resultOrder[id] = result;
    this.buildSaveOrderResult();
  }

  buildSaveOrderResult() {
    let sendResult = true;

    for (const key in this.resultOrder) {
      if (this.resultOrder.hasOwnProperty(key)) {
        if (this.resultOrder[key] != true) {
          sendResult = false;
        }
      }
    }

    if (sendResult == true) {
      this.getSaveOrder.emit(true);
    }
  }

  getSelectedIDsInner(id, d) {
    this.selectedIDs = [];
    this.select[id] = d || [];

    for (const key in this.select) {
      if (this.select.hasOwnProperty(key)) {
        this.selectedIDs = this.selectedIDs.concat(this.select[key] || []);
      }
    }

    this.sendResult();
  }

  getSelectedLimitedIDsInner(id, d) {
    this.selectedLimitedIDs = [];
    this.selectLimit[id] = d || [];

    for (const key in this.selectLimit) {
      if (this.selectLimit.hasOwnProperty(key)) {
        this.selectedLimitedIDs = this.selectedLimitedIDs.concat(this.selectLimit[key] || []);
      }
    }

    this.sendResult();
  }

  sendResult() {
    this.selectedIDs = _.uniq(this.selectedIDs);
    this.selectedLimitedIDs = _.uniq(this.selectedLimitedIDs);

    this.getSelectedIDs.emit(this.selectedIDs || []);
    this.getSelectedLimitedIDs.emit(this.selectedLimitedIDs || []);
  }
}
