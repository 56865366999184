<div class="rel" *ngIf="editorComponent && editorComponent.ID">
  <form #userContactForm="ngForm">
    <div class="card-header primary-gradient-img clearfix confirmModal">
      <button class="btn-close btn-clear mat-button smt white custom-close" (click)="closeEvent()">
        <mat-icon>clear</mat-icon>
      </button>
      <div class="full-width flex main-title-container">
        <div class="row full-width title-container clearfix">
          <div class="title-container-sub">
            <h2 class="text-left title-header rm-m">
              Clone: {{ title }}

              <span class="subLabel" *ngIf="description">{{ description }}</span>
            </h2>
            <p class="clearfix small rm-mb">
              <span
                [ngClass]="{
                  'chip-enable': editorComponent.Is_Active === '1',
                  'chip-disable': !editorComponent.Is_Active === '0'
                }"
              >{{ editorComponent.Is_Active == '0' ? 'Not Active' : 'Active' }}</span
              >

              <span class="chip-enable" *ngIf="editorComponent.Is_Default === '1'">Default</span>

              <span class="chip-custom" *ngIf="editorComponent['Nature.Code'] == 'Style'" matTooltip="Style"
              >Style</span
              >
              <span class="chip-custom" *ngIf="editorComponent['Nature.Code'] == 'PageTem'" matTooltip="Page Template"
              >Page Template</span
              >
              <span class="chip-custom" *ngIf="editorComponent['Nature.Code'] == 'Template'" matTooltip="Component"
              >Component</span
              >
              <span
                class="chip-custom"
                *ngIf="editorComponent['Type.Label']"
                matTooltip="{{ editorComponent['Type.Label'] }}"
              >{{ editorComponent['Type.Label'] }}</span
              >
            </p>
          </div>
        </div>
      </div>
    </div>

    <mat-dialog-content>
      <div class="full-width mt">
        <mat-form-field
          class="full-width"
          *ngIf="merchants && merchants.length > 0 && isPromoterOrAdmin == true && isDefault != true"
          appearance="outline"
        >
          <mat-label>{{ "KEYWORD.practice" | translate | titlecase}}</mat-label>
          <mat-select class="" name="merchants" [placeholder]="('KEYWORD.practice' | translate | titlecase)">
            <mat-option *ngFor="let a of merchants" [value]="a.ID">
              {{ a.TradingAs }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Label</mat-label>
          <input [(ngModel)]="label" matInput placeholder="Label" name="label" required/>
        </mat-form-field>

        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Description</mat-label>
          <textarea
            class="min-height"
            [(ngModel)]="description"
            name="description"
            matInput
            placeholder="Description"
            required
          ></textarea>
        </mat-form-field>

        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Type</mat-label>

          <mat-select [(ngModel)]="typeCode" name="typeCode" placeholder="Type">
            <mat-option *ngFor="let m of types" [value]="m.Code">
              {{ m.Label }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <div class="full-width clearfix mt smb flex">
          <mat-slide-toggle class="full-width ml mr" [(ngModel)]="isActive" name="isActive">
            is Active Component?
          </mat-slide-toggle>

          <mat-slide-toggle
            class="full-width ml mr"
            *ngIf="isPromoterOrAdmin == true"
            [(ngModel)]="isDefault"
            name="isDefault"
            appearance="outline"
          >
            Is Default Component?
          </mat-slide-toggle>
        </div>
      </div>
    </mat-dialog-content>

    <hr/>
    <div class="drop-button text-center full-width">
      <button class="pull-left btn" (click)="closeEvent()" mat-raised-button color="primary">Close</button>

      <button
        class="pull-right btn ml"
        [disabled]="userContactForm.invalid"
        (click)="sendResult(true)"
        mat-raised-button
        color="accent"
      >
        Clone & Edit
      </button>

      <button
        class="pull-right btn ml"
        [disabled]="userContactForm.invalid"
        (click)="sendResult(false)"
        mat-raised-button
        color="accent"
      >
        Clone & Close
      </button>
    </div>
  </form>
</div>
