import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Output,
  ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatCalendar, MatCalendarCellCssClasses } from '@angular/material';
import * as $ from 'jquery';
import moment from 'moment-timezone';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { ClientDetails } from '../../../shared/types/client-details';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { DentistViewModalComponent } from '../../dentist/dentist-view-modal/dentist-view-modal.component';
import { DentistService } from '../../dentist/shared/dentist.service';
import { MerchantService } from '../../merchant/shared/merchant.service';
import { AppointmentService } from '../shared/appointment.service';
import { AppointmentLookupService } from '../shared/appointmentLookup.service';
import { AppointmentUtilClass } from '../shared/appointmentUtil';

class DateTimeOptionType {
  value: any;
  enabled?: boolean;
  confirm?: boolean;
}

@Component({
  selector: 'app-schedule-appointment-view',
  templateUrl: './schedule-appointment-view.component.html',
  styleUrls: ['./schedule-appointment-view.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.stacked-card-view', style({ opacity: 0 }), { optional: true }),
        query(
          '.stacked-card-view',
          stagger('100ms', [
            animate(
              '0.3s ease-out',
              keyframes([
                style({ opacity: 0, offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.stacked-card-view', style({ opacity: 1 }), { optional: true }),
        query(
          '.stacked-card-view',
          stagger('100ms', [
            animate(
              '0.3s ease-in',
              keyframes([
                style({ opacity: 1, offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class ScheduleAppointmentViewComponent implements OnInit, OnChanges {
  @Input()
  appointmentID;

  @Input()
  appointment;

  @Input()
  merchantID;
  merchant;
  merchantWorkingHours: any[];
  merchantTimezone;
  merchantBeforeBuffer = 0;
  merchantAfterBuffer = 0;
  tzTimeStr;

  @Input()
  isEmbedded = false;

  @Input()
  goStep = new EventEmitter();

  @Input()
  step = 1;

  @Output()
  close = new EventEmitter();

  @Output()
  confirm = new EventEmitter();

  @Output()
  isStep1Validate = new EventEmitter();

  @Output()
  isStep2Validate = new EventEmitter();

  mockAppointment: any;
  mockAppointmentIdx;

  isPromoterOrAdmin;
  sessionType;
  dateNow = new Date();
  selectedDate;
  selectedDateTime;
  runAction = new EventEmitter();
  timeStep = 30;
  maxScheduleDate;
  isLoadingDateTimeOptions = false;
  isLoadingInit = true;

  isModal;
  isFirstScreen = true;
  dentistOptions = [];
  selectedDentist;
  curMonthDisplay;
  appointmentsOfMonth: any[] = [];

  duration = 60;
  types = [];
  type;
  subTypes = [];
  subType;
  comment;
  smsConfirmAppointment;
  smsReminderAppointment;
  dateTimeOptions: DateTimeOptionType[] = [];
  bufferBefore = 0;
  bufferAfter = 0;

  appointmentUtil = new AppointmentUtilClass();

  addToGoogleCalendar = false;
  addToAppleCalendar = false;
  addToMicrosoftCalendar = false;

  title = 'Schedule Appointment';
  buttonLabel = 'Schedule Appointment';
  durations: any = this.appointmentUtil.getDurationArray();
  buffers: any = this.appointmentUtil.getBufferArray();
  @ViewChild('applyForm', { static: true }) applyForm: NgForm;
  matCalendar: MatCalendar<any>;
  googleReviewPrompt = false;

  @ViewChild(MatCalendar, { static: false }) set content(content: MatCalendar<any>) {
    if (content && !this.matCalendar) {
      this.matCalendar = content;
      $(this.el.nativeElement).on('click', '.mat-calendar-controls', (ev) => {
        if (
          (ev.target.classList as DOMTokenList).contains('mat-calendar-next-button') ||
          (ev.target.classList as DOMTokenList).contains('mat-calendar-previous-button')
        ) {
          this.getAppointmentsOfMonth();
        }
      });
    }
  }

  constructor(
    private appointmentLookupService: AppointmentLookupService,
    private appointmentService: AppointmentService,
    private authenticationService: AuthenticationService,
    private dentistService: DentistService,
    private merchantService: MerchantService,
    public el: ElementRef,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data) {
      this.appointmentID = data.appointmentID;

      if (data.title) {
        this.title = data.title;
      }

      if (data.buttonLabel) {
        this.buttonLabel = data.buttonLabel;
      }
      this.isModal = true;
    }
  }

  closeEvent() {
    this.close.emit(true);
  }

  async ngOnInit() {
    this.durations = this.durations.map((it) => {
      return {
        value: Number(it.code),
        label: it.label,
      };
    });

    this.buffers = this.buffers.map((it) => {
      return {
        value: Number(it.code),
        label: it.label,
      };
    });

    if (this.goStep) {
      this.goStep.subscribe((res) => {
        switch (res) {
          case 1:
            this.isFirstScreen = true;
            break;
          case 2:
            this.isFirstScreen = false;
            break;
          case 3:
            this.submit();
            break;
        }
      });
    }

    if (this.applyForm) {
      this.applyForm.valueChanges.subscribe(() => {
        this.checkStepsValid();
      });
    }

    // get permissions
    const res = await Promise.all([
      this.authenticationService.isPromoterOrAdmin().toPromise(),
      this.authenticationService.getSessionType().toPromise(),
    ]);

    this.isPromoterOrAdmin = res[0];
    this.sessionType = res[1];

    await this.initData();
    this.isLoadingInit = false;
  }

  async ngOnChanges() {
    this.initData();
  }

  async initData() {
    let res;

    this.isFirstScreen = this.step === 1 || this.step === null;

    // get appointment
    if (this.appointmentID) {
      res = await this.appointmentService.getAppointmentDetails(this.appointmentID, this.sessionType).toPromise();
      this.appointment = res;
    }
    if (!this.merchantID) {
      this.merchantID = res['Merchant_key'];
    }
    let payload: any = {
      merchantID: this.merchantID,
      isActive: true,
      lookupType: 'AppointmentTypes',
      category: 'AppointmentTypes',
    };
    // set selected type
    this.types = await this.appointmentLookupService.list(payload, this.sessionType).toPromise();
    this.type = this.types.find((it) => res && res['Type.Code'] === it.Code);

    if (this.type && this.type.Code) {
      if (this.type.DefaultDuration) {
        this.duration = Number(this.type.DefaultDuration);
      }
      payload = {
        merchantID: this.merchantID,
        isActive: true,
        lookupType: 'AppointmentTypes',
        category: this.type.Code,
      };
      // set selected subtype
      this.subTypes = await this.appointmentLookupService.list(payload, this.sessionType).toPromise();
      if (this.appointment['SubType.Code']) {
        this.subType = this.subTypes.find((it) => it.Code === this.appointment['SubType.Code']);
        if (this.subType.DefaultDuration) {
          this.duration = Number(this.subType.DefaultDuration);
        }
      }
    }

    payload = {
      merchantID: this.merchantID,
      canAcceptAppointment: true,
      orderBy: 'CalculatedName',
      fields: 'ID,CalculatedName',
    };

    // set selected dentist
    this.dentistOptions = await this.dentistService.getList(payload, this.isPromoterOrAdmin).toPromise();
    const found = this.dentistOptions.find(
      (it) => this.appointment && it.ID === this.appointment['MerchantContact_key']
    );
    this.selectedDentist = found || this.dentistOptions[0];

    // set merchant
    const merchantPromise = this.isPromoterOrAdmin
      ? this.merchantService.getMerchantDetails(this.merchantID, {}, this.sessionType).toPromise()
      : this.authenticationService.getCurrentPractice().toPromise();
    this.merchant = await merchantPromise;
    if (this.merchant['Appointment_Time_Step']) {
      this.timeStep = Number(this.merchant['Appointment_Time_Step']);
    }

    // set sms defaults
    this.smsConfirmAppointment = this.merchant.SMS_ConfirmAppointment === '1';
    this.smsReminderAppointment = this.merchant.SMS_AppointmentReminder === '1';

    if (this.merchant['Appointment_Limit_To_Days'] && this.merchant['Appointment_Limit_To_Days'] !== '-1') {
      this.maxScheduleDate = moment().add(Number(this.merchant['Appointment_Limit_To_Days']), 'day').toDate();
    }

    this.merchantBeforeBuffer = Number(this.merchant.Default_Buffers_Pre);
    this.merchantAfterBuffer = Number(this.merchant.Default_Buffers_Post);

    await Promise.all([this.fillDateTimeOptions(true), this.getAppointmentsOfMonth()]);
  }

  async fillDateTimeOptions(reset = false) {
    if (!this.selectedDentist) {
      return;
    }

    const dateStr = moment(this.selectedDate).format('DD-MM-YYYY');

    if (reset) {
      this.isLoadingDateTimeOptions = true;
      this.dateTimeOptions = [];

      const merchantPayload = {
        dentistID: this.selectedDentist.ID,
      };

      const appointmentPayload = {
        dentistID: this.selectedDentist.ID,
        dateBooking: ClientDetails.getMoment(this.selectedDate)
          .set('h', 0)
          .set('m', 0)
          .set('s', 0)
          .format(ClientDetails.formatUTC),
        statusCodes: 'WAITINGCONFIRMATION,BOOKED',
      };

      const res = await Promise.all([
        this.merchantService.getTradingHourList(merchantPayload, this.isPromoterOrAdmin).toPromise(),
        this.appointmentService.getAppointmentList(appointmentPayload, this.sessionType).toPromise(),
      ]);

      this.merchantWorkingHours = res[0];
      this.merchantTimezone = this.merchantWorkingHours[0]['TimeZone'];
      this.tzTimeStr = moment().tz(this.merchantTimezone).format('h:mma');
      this.dateNow = moment().tz(this.merchantTimezone).toDate();

      // select correct date in calendar on first load
      if (!this.selectedDate) {
        const travBusinessDay = moment(this.dateNow).set('h', 0).set('m', 0).set('s', 0);
        const idx = this.merchantWorkingHours.findIndex(
          (item) => item['DayOfWeek.Label.Singular'] === moment(this.dateNow).format('dddd')
        );
        // find the nearest business day
        for (
          let trav = idx;
          !this.selectedDate;
          trav = (trav + 1) % this.merchantWorkingHours.length, travBusinessDay.add(1, 'day')
        ) {
          if (this.merchantWorkingHours[trav].NotOpen === '0') {
            if (trav === idx) {
              const timeSplit = this.merchantWorkingHours[trav].ClosingTime.split(':');
              const currentTime = this.dateNow.getTime();
              const closingTime = moment(this.dateNow)
                .set('h', timeSplit[0])
                .set('m', timeSplit[1])
                .set('s', timeSplit[2])
                .toDate()
                .getTime();
              if (currentTime >= closingTime) {
                continue;
              }
            }
            this.selectedDate = travBusinessDay.toDate();
          }
        }
      }

      let practitionerAppointments: any[] = res[1];

      const workDay = this.merchantWorkingHours.find(
        (item) => item['DayOfWeek.Label.Singular'] === moment(this.selectedDate).format('dddd') && item.NotOpen === '0'
      );

      if (workDay) {
        const startTime = moment(`${dateStr} ${workDay.OpeningTime}`, 'DD-MM-YYYY HH:mm:ss');
        const endTime = moment(`${dateStr} ${workDay.ClosingTime}`, 'DD-MM-YYYY HH:mm:ss');

        for (let trav = startTime; trav.toDate() <= endTime.toDate(); ) {
          const addDateTime = trav.toDate();

          const foundAppointment = practitionerAppointments.find((it) => {
            const exp = moment(`${dateStr} ${it.Time}`, 'DD-MM-YYYY HH:mm');
            if (it.Buffers_Pre) {
              exp.subtract(Number(it.Buffers_Pre), 'minute');
            }
            const dateTimeStart = ClientDetails.convertTimeZone(exp, true);
            return addDateTime >= dateTimeStart;
          });

          if (foundAppointment) {
            practitionerAppointments = practitionerAppointments.filter((it) => it !== foundAppointment);
            let exp = moment(`${dateStr} ${foundAppointment.Time}`, 'DD-MM-YYYY HH:mm');
            const dateTimeStart = ClientDetails.convertTimeZone(exp, true);
            exp = moment(`${dateStr} ${foundAppointment.Time_End}`, 'DD-MM-YYYY HH:mm');
            const dateTimeEnd = ClientDetails.convertTimeZone(exp, true);
            foundAppointment.Time = moment(dateTimeStart).format('h:mma');
            foundAppointment.Time_End = moment(dateTimeEnd).format('h:mma');

            // foundAppointment._dateTimeStart = moment(dateTimeStart)
            //   .subtract(Number(foundAppointment.Buffers_Pre), 'minute')
            //   .toDate();
            // foundAppointment._dateTimeEnd = moment(dateTimeEnd)
            //   .add(Number(foundAppointment.Buffers_Post), 'minute')
            //   .toDate();
            // } else {
            //   enabled = addDateTime.getTime() > this.dateNow.getTime();
          }

          this.dateTimeOptions.push({
            value: foundAppointment || addDateTime,
            enabled: true,
          });

          if (foundAppointment) {
            trav = moment(`${dateStr} ${foundAppointment.Time_End}`, 'DD-MM-YYYY h:mma');
            if (foundAppointment.Buffers_Post) {
              trav.add(Number(foundAppointment.Buffers_Post), 'minute');
            }
          } else {
            trav.add(this.timeStep, 'minute');
          }
        }

        // make sure last option is the work day end time of merchant
        const lastOption = this.dateTimeOptions[this.dateTimeOptions.length - 1];
        if (lastOption && !lastOption.value.ID && lastOption.value.getTime() != endTime.toDate().getTime()) {
          this.dateTimeOptions.push({
            value: endTime.toDate(),
            enabled: true,
          });
        }
      }
      this.isLoadingDateTimeOptions = false;

      // scroll to view the first enabled datetime option
      setTimeout(() => {
        this.scrollFirstEnabledTimeOption();
      });
    }

    const totalDuration = this.duration + this.bufferBefore + this.bufferAfter;

    // traverse thru all dateTimeOptions with appointments including last option
    this.dateTimeOptions
      .filter((it, idx) => (!!it.value.ID && it.value.ID !== 'mock') || idx === this.dateTimeOptions.length - 1)
      .forEach((opt) => {
        let travIdx = this.dateTimeOptions.findIndex((it) => opt === it);
        if (travIdx !== this.dateTimeOptions.length - 1) {
          --travIdx;
        }
        const momValue = opt.value.Time
          ? moment(`${dateStr} ${opt.value.Time}`, 'DD-MM-YYYY h:mma')
          : moment(opt.value);
        const minUnavailableTime = momValue.subtract(totalDuration, 'minute');
        if (opt.value.Buffers_Pre) {
          minUnavailableTime.subtract(Number(opt.value.Buffers_Pre), 'minute');
        }

        // traverse backwards thru dateTimeOptions before the appointment time card
        do {
          // make sure it is within bounds and it is a date value
          if (this.dateTimeOptions[travIdx] && !this.dateTimeOptions[travIdx].value.ID) {
            const enabled =
              this.dateTimeOptions[travIdx].value <= minUnavailableTime.toDate() &&
              this.dateTimeOptions[travIdx].value.getTime() > this.dateNow.getTime();
            this.dateTimeOptions[travIdx].enabled = enabled;
            if (!enabled && this.dateTimeOptions[travIdx].confirm) {
              this.dateTimeOptions[travIdx].confirm = false;
            }
            travIdx--;
          } else {
            break;
          }
        } while (true);
      });

    // check if selectedDateTime is still valid
    if (this.selectedDateTime) {
      const isInvalid = !!this.dateTimeOptions.find((it) => {
        // check time cards
        if (!it.value.ID && it.value.getTime() === this.selectedDateTime.getTime() && !it.enabled) {
          return true;
        }
        // check appointment time cards
        // if (it.value.ID && it.value.ID !== 'mock' &&
        //   it.value._dateTimeStart.getTime() <= this.selectedDateTime.getTime() &&
        //   it.value._dateTimeEnd.getTime() > this.selectedDateTime.getTime()) {
        //   return true;
        // }
      });
      if (isInvalid) {
        this.removeMockAppointment();
      }
    }
  }

  scrollFirstEnabledTimeOption() {
    const el = $('.timeOptionChip:not(.appointmentTimeCard):not(.disabled):first()') as any;
    if (el && el[0]) {
      el[0].parentNode.scrollTop = el[0].offsetTop;
    }
  }

  back() {
    this.isFirstScreen = true;
  }

  next() {
    if (this.isFirstScreen) {
      this.isFirstScreen = false;
    }
  }

  async selectedDateChanged(data) {
    this.selectedDate = data;
    await this.fillDateTimeOptions(true);
    if (moment(this.selectedDate).format('YYYY-MM-DD') === moment(this.selectedDateTime).format('YYYY-MM-DD')) {
      this.renderMockAppointmentTimeCard();
    }
  }

  async selectedDentistChange(data) {
    // this.isFirstLoadCalendarDates = true;
    await this.getAppointmentsOfMonth(true);
    this.removeMockAppointment();
    this.fillDateTimeOptions(true);
  }

  selectedDurationChange(data) {
    this.fillDateTimeOptions();
    this.renderMockAppointmentTimeCard();
  }

  selectedBufferChange(data) {
    this.fillDateTimeOptions();
    this.renderMockAppointmentTimeCard();
  }

  confirmTime(selectedTime: DateTimeOptionType) {
    if (selectedTime.enabled && selectedTime.value !== this.selectedDateTime) {
      this.dateTimeOptions.map((time) => (time.confirm = time.confirm ? false : selectedTime === time));
    }
  }

  selectTime(selectedTime, idx) {
    this.dateTimeOptions.map((time) => (time.confirm = false));
    this.removeMockAppointment();
    this.selectedDateTime = selectedTime.value;
    this.checkStepsValid();
    this.mockAppointmentIdx = idx;
    this.renderMockAppointmentTimeCard();
  }

  renderMockAppointmentTimeCard() {
    if (!this.selectedDateTime) {
      return;
    }
    let spliceIdx = 1;
    const totalDuration = this.duration + this.bufferBefore + this.bufferAfter;
    if (!this.mockAppointment) {
      this.mockAppointment = {
        ID: 'mock',
        'Status.Label': 'Pending',
        CustomerOrProspect_CalculatedName: this.appointment.CustomerOrProspect_CalculatedName,
      };
      spliceIdx = 0;
    }

    this.mockAppointment['Type.Label'] = this.type ? this.type.Label : '';
    this.mockAppointment['SubType.Label'] = this.subType ? this.subType.Label : '';

    const momDateTime = ClientDetails.getMoment(this.selectedDateTime);

    this.mockAppointment.Date_Booked = momDateTime.format('YYYY-MM-DD');

    momDateTime.add(this.bufferBefore, 'minute');
    this.mockAppointment.Buffers_Pre = this.bufferBefore.toString();
    this.mockAppointment.Buffers_Post = this.bufferAfter.toString();

    this.mockAppointment.Time = momDateTime.format('h:mma');
    this.mockAppointment.Time_End = momDateTime.add(this.duration, 'minute').format('h:mma');
    this.mockAppointment._unixStart = ClientDetails.getMoment(this.selectedDateTime).toDate().getTime();
    this.mockAppointment._unixEnd = ClientDetails.getMoment(this.selectedDateTime)
      .add(totalDuration, 'minute')
      .toDate()
      .getTime();

    // insert the mock appointment to dateTimeOptions
    this.dateTimeOptions.splice(this.mockAppointmentIdx, spliceIdx, {
      value: this.mockAppointment,
    });
  }

  removeMockAppointment(isConfirm = false) {
    const proceed = () => {
      this.selectedDateTime = null;
      this.mockAppointment = null;
      if (
        this.dateTimeOptions[this.mockAppointmentIdx] &&
        this.dateTimeOptions[this.mockAppointmentIdx].value.ID === 'mock'
      ) {
        this.dateTimeOptions.splice(this.mockAppointmentIdx, 1);
      }
      this.mockAppointmentIdx = null;
      this.checkStepsValid();
    };

    if (isConfirm) {
      const confirm = new ConfirmDialog(
        'fas fa-info',
        'Unselect Time',
        'Are you sure you want to unselect the time ?',
        'No',
        'Yes'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation) {
          proceed();
        }
        ref.close();
      });
    } else {
      proceed();
    }
  }

  runActionEvent(item, operation, isGroup = false) {
    if (item) {
      const p = {
        item,
        operation,
        isGroup,
      };
      this.runAction.emit(p);
    }
  }

  addSubType() {
    if (this.type && this.type.Code) {
      const payload: any = {
        merchantID: this.appointment['Merchant_key'],
        isActive: true,
        lookupType: 'AppointmentTypes',
        category: this.type.Code,
      };
      this.appointmentLookupService.list(payload, this.sessionType).subscribe((res) => {
        if (res) {
          this.subTypes = res;
        }
        this.setDefaultDurationAndBuffer();
      });
    }
  }

  subTypeSelect() {
    this.setDefaultDurationAndBuffer();
  }

  setDefaultDurationAndBuffer() {
    if (this.subType && this.subTypes.length > 0) {
      if (this.subType.DefaultDuration) {
        this.duration = Number(this.subType.DefaultDuration);
      }
      if (this.subType.Buffers_Pre) {
        this.bufferBefore = Number(this.subType.Buffers_Pre);
      } else {
        this.bufferBefore = this.merchantBeforeBuffer;
      }
      if (this.subType.Buffers_Post) {
        this.bufferAfter = Number(this.subType.Buffers_Post);
      } else {
        this.bufferAfter = this.merchantAfterBuffer;
      }
    } else if (this.type) {
      if (this.type.DefaultDuration) {
        this.duration = Number(this.type.DefaultDuration);
      }
      if (this.type.Buffers_Pre) {
        this.bufferBefore = Number(this.type.Buffers_Pre);
      } else {
        this.bufferBefore = this.merchantBeforeBuffer;
      }
      if (this.type.Buffers_Post) {
        this.bufferAfter = Number(this.type.Buffers_Post);
      } else {
        this.bufferAfter = this.merchantAfterBuffer;
      }
    }
    this.fillDateTimeOptions();
    this.renderMockAppointmentTimeCard();
  }

  replaceAllString(e) {
    if (e && typeof e == 'string') {
      return String(e).replace(/�/g, "'");
    }
  }

  getDateTimeDuration() {
    if (this.selectedDateTime) {
      const momSelectedDateTime = moment(this.selectedDateTime);
      if (this.bufferBefore) {
        momSelectedDateTime.add(this.bufferBefore, 'minute');
      }
      const startTime = momSelectedDateTime.format('h:mma');
      const endDateTime = moment(momSelectedDateTime).add(this.duration, 'minute').format('h:mma, dddd, MMMM DD, YYYY');
      return `${startTime} - ${endDateTime}`;
    } else {
      return '-';
    }
  }

  filterDates = (date: Date): boolean => {
    if (this.merchantWorkingHours) {
      const workDay = this.merchantWorkingHours.find(
        (item) => item['DayOfWeek.Label.Singular'] === moment(date).format('dddd') && item.NotOpen === '0'
      );
      return !!workDay;
    } else {
      return true;
    }
  };

  dateClass() {
    // calculate availability of date

    return (date: Date): MatCalendarCellCssClasses => {
      const dateDay = moment(date).startOf('day').toDate().getTime();
      const curDay = moment().startOf('day').toDate().getTime();
      const maxDay = this.maxScheduleDate
        ? moment(this.maxScheduleDate).startOf('day').toDate().getTime()
        : moment(date).endOf('month').startOf('day').toDate().getTime();
      if (dateDay < curDay || dateDay > maxDay) {
        return;
      }
      const workDay = this.merchantWorkingHours.find(
        (item) => item['DayOfWeek.Label.Singular'] === moment(date).format('dddd') && item.NotOpen === '0'
      );
      if (workDay) {
        const openingTime = moment(workDay.OpeningTime, 'HH:mm:ss');
        const closingTime = moment(workDay.ClosingTime, 'HH:mm:ss');
        const totalWorkingMinutes = closingTime.diff(openingTime, 'minute');

        const appointmentsOfDay = this.appointmentsOfMonth.filter(
          (it) => it.Date_Merchant === moment(date).format('YYYY-MM-DD')
        );
        if (appointmentsOfDay.length > 0) {
          const totalMinutes = appointmentsOfDay.reduce((acc, cur) => {
            // const appStart = moment(
            //   `${cur.Date_Merchant} ${cur.Time_Merchant}`,
            //   "YYYY-MM-DD HH:mm:ss"
            // );
            // const appEnd = moment(
            //   `${cur.Date_Merchant_End} ${cur.Time_Merchant_End}`,
            //   "YYYY-MM-DD HH:mm:ss"
            // );
            let exp = moment(`${cur.Date_Merchant} ${cur.Time}`, 'YYYY-MM-DD HH:mm');
            const appStart = moment(ClientDetails.convertTimeZone(exp, true)).subtract(
              Number(cur.Buffers_Pre),
              'minute'
            );
            exp = moment(`${cur.Date_Merchant} ${cur.Time_End}`, 'YYYY-MM-DD HH:mm');
            const appEnd = moment(ClientDetails.convertTimeZone(exp, true)).add(Number(cur.Buffers_Post), 'minute');
            return acc + appEnd.diff(appStart, 'minute') || 0;
          }, 0);

          const percent = Math.trunc((totalMinutes / totalWorkingMinutes) * 100);
          const rounded = Math.ceil(percent / 10) * 10;
          return `used-percent used-percent-${rounded}`;
        } else {
          return `used-percent used-percent-0`;
        }
      } else {
        return;
      }
    };
  }

  async getAppointmentsOfMonth(force = false) {
    const startDate = this.matCalendar ? this.matCalendar.monthView.activeDate : new Date();
    const month = moment(startDate).format('MMM');

    if (this.curMonthDisplay === month && !force) {
      return;
    }
    this.curMonthDisplay = month;
    const payload = {
      dentistID: this.selectedDentist.ID,
      dateBookingFrom: moment(startDate).startOf('day').format('YYYY-MM-DD'),
      dateBookingTo: startDate
        ? moment(startDate).endOf('month').format('YYYY-MM-DD')
        : this.maxScheduleDate || moment().endOf('month').format('YYYY-MM-DD'),
      statusCodes: 'WAITINGCONFIRMATION,BOOKED',
      // fields: "Date_Merchant,Time_Merchant,Date_Merchant_End,Time_Merchant_End",
      fields: 'Date_Merchant,Time,Time_End,Buffers_Pre,Buffers_Post',
    };

    this.appointmentsOfMonth = await this.appointmentService.getAppointmentList(payload, this.sessionType).toPromise();

    if (this.matCalendar) {
      this.matCalendar.updateTodaysDate();
    }
  }

  submit() {
    const momDateTime = ClientDetails.getMoment(this.selectedDateTime);

    if (this.bufferBefore) {
      momDateTime.add(this.bufferBefore, 'minute');
    }
    const bookDate = momDateTime.format(ClientDetails.formatUTC);
    const bookDateEnd = momDateTime.add(this.duration, 'minute').format(ClientDetails.formatUTC);
    const p: any = {
      bookDate,
      bookDateEnd,
      comment: this.comment,
      operation: 'proposeBooking',
      dentistID: this.selectedDentist.ID,
      smsConfirmAppointment: this.smsConfirmAppointment,
      smsAppointmentReminder: this.smsReminderAppointment,
      googleReviewPrompt: this.googleReviewPrompt,
    };

    if (this.type && this.type.Code) {
      p.typeCode = this.type.Code;
    }

    if (this.type && this.type.Description) {
      p.typeDescription = this.type.Description;
    }

    if (this.subType && this.subType.Code) {
      p.subTypeCode = this.subType.Code;
    }
    if (this.subType && this.subType.Description) {
      p.subTypeDescription = this.subType.Description;
    }

    if (this.bufferBefore) {
      p.buffersPre = this.bufferBefore;
    }
    if (this.bufferAfter) {
      p.buffersPost = this.bufferAfter;
    }

    this.confirm.emit(p);
  }

  isStep1Invalid() {
    const ret = this.applyForm.invalid || !this.selectedDateTime;
    return ret;
  }

  isStep2Invalid() {
    const ret = this.applyForm.invalid || !this.selectedDateTime;
    return ret;
  }

  checkStepsValid() {
    let ret = this.isStep1Invalid();
    this.isStep1Validate.emit(!ret);
    ret = this.isStep2Invalid();
    this.isStep2Validate.emit(!ret);
  }

  getActionResult(r) {
    if (r && r.ID) {
      this.appointment = r;
    }
  }

  viewDentist(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(DentistViewModalComponent, {
        data: id,
        width: '550px',
      });
      ref.componentInstance.close.subscribe((res) => {
        if (res == true) {
          ref.close();
        }
      });
    }
  }
}
