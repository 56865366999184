import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';
import { TestimonyCreateEditComponent } from '../../../feature/testimony/testimony-create-edit/testimony-create-edit.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { Settings } from '../../../shared/types/settings';
import { AuthenticationService } from '../../authentication/shared/authentication.service';
import { FAQModalMerchantComponent } from '../../helper/FAQ-modal-merchant/faq-modal-merchant.component';
import { SimplePageViewComponent } from '../../helper/simple-page-view/simple-page-view.component';

@Component({
  selector: 'wiki-footer',
  templateUrl: './wiki-footer.component.html',
  styleUrls: ['./wiki-footer.component.css'],
})
export class WikiFooterComponent implements OnInit {
  languages = [];

  settings = Settings.global;
  selectedLanguage = 'en';

  constructor(private translate: TranslateService) {}

  ngOnInit() {
    this.languages = this.settings['availableLanguages'];
    this.selectedLanguage = this.settings['language'];
  }

  changeLang() {
    this.translate.use(this.selectedLanguage);
  }

  contactUs() {
    const data = {
      targetType: 'us',
    };
    AuthenticationService.contactInputEvent.emit(data);
  }

  FAQ() {
    RootAppComponent.dialog.open(FAQModalMerchantComponent, {
      data: 'Frequent Asked Question',
      width: '800px',
    });
  }

  aboutUs() {
    RootAppComponent.dialog.open(SimplePageViewComponent, {
      data: 'aboutUs',
      width: '50%',
    });
  }

  credits() {
    RootAppComponent.dialog.open(SimplePageViewComponent, {
      data: 'credits',
      width: '50%',
    });
  }

  rateUs() {
    const ref = RootAppComponent.dialog.open(TestimonyCreateEditComponent, {
      width: '650px',
      panelClass: 'rm-bp',
    });
    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  openPublic() {
    window.open(this.settings['publicSiteFrontendLink'], '_blank');
  }

  openMailTo() {
    window.location.href = 'mailto:' + this.settings['contactEmail'];
    // window.open(this.settings['contactEmail'], "_blank");
  }

  goToADA() {
    const newWindow = window.open('https://www.ada.org.au', '_blank', 'noopener');
  }

  goToADIA() {
    const newWindow = window.open('https://www.adia.org.au', '_blank', 'noopener');
  }

  getFileLink(fileID) {
    return environment.nodeUrl + '/files/file-view/' + fileID;
  }
}
