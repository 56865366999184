import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { CustomerViewComponent } from '../../customer/customer-view/customer-view.component';
import { InvitationViewModalComponent } from '../../invitation/invitation-view-modal/invitation-view-modal.component';
import { MerchantViewComponent } from '../../merchant/merchant-view/merchant-view.component';
import { ProductViewModalComponent } from '../../product/product-view-modal/product-view-modal.component';
import { ContractCapacityCheckComponent } from '../contract-capacity-check/contract-capacity-check.component';
import { ContractCreditCheckComponent } from '../contract-credit-check/contract-credit-check.component';
import { ContractDetailFundingHistoryComponent } from '../contract-detail-funding-history/contract-detail-funding-history.component';
import { ContractIdentityCheckComponent } from '../contract-identity-check/contract-identity-check.component';
import { ContractService } from '../shared/contract.service';

@Component({
  selector: 'app-contract-detail',
  templateUrl: './contract-detail.component.html',
  styleUrls: ['./contract-detail.component.css'],
})
export class ContractDetailComponent implements OnInit {
  @Input()
  contractID;

  contract;
  isPromoterOrAdmin = false;

  contractTabTitle = 'overview';

  displayCreditCheck = false;
  displayCapacityCheck = false;
  displayIdentityCheck = false;
  hideBackButton = false;
  isParameter = false;

  paymentsTab = false;
  actionLogTab = false;
  kpiTab = false;
  contactsTab = false;
  fundingHistoryTab = false;
  documentsTab = false;

  @Input()
  setContractTab = 'overview';

  constructor(
    private activeRouter: ActivatedRoute,
    private contractService: ContractService,
    private authenticationService: AuthenticationService,
    private location: Location,
    private router: Router
  ) {}

  ngOnInit() {
    this.activeRouter.params.subscribe((res) => {
      if (res['contractID']) {
        this.contractID = res['contractID'];
        this.isParameter = true;
      }

      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isPromoterOrAdmin = res;

        this.contractService.fetchContractDetails$(this.contractID, null, this.isPromoterOrAdmin).subscribe((res) => {
          this.contract = res;
        });
      });
    });

    this.getContractTabTitle();
  }

  ngOnChanges() {
    this.getContractTabTitle();
  }

  displayCheckDetail(value) {
    if (value == 'CreditCheck') {
      // this.displayCreditCheck = true;
      // this.displayCapacityCheck = false;
      // this.displayIdentityCheck = false;

      this.viewCreditCheck();
    } else if (value == 'CapacityCheck') {
      // this.displayCreditCheck = false;
      // this.displayCapacityCheck = true;
      // this.displayIdentityCheck = false;

      this.viewCapacityCheck();
    } else if (value == 'IdentityCheck') {
      // this.displayCreditCheck = false;
      // this.displayCapacityCheck = false;
      // this.displayIdentityCheck = true;

      this.viewIdentityCheck();
    } else {
      this.displayCreditCheck = false;
      this.displayCapacityCheck = false;
      this.displayIdentityCheck = false;
    }
  }

  hideCheckDetail() {
    this.displayCreditCheck = false;
    this.displayCapacityCheck = false;
    this.displayIdentityCheck = false;
  }

  hideBtn(e) {
    this.hideBackButton = e;
  }

  goBack() {
    this.location.back();
  }

  getContractTabTitle() {
    if (this.setContractTab == 'overview') {
      this.contractTabTitle = 'Overview';
    } else if (this.setContractTab == 'repayment') {
      this.contractTabTitle = 'Borrower Repayments';
    } else if (this.setContractTab == 'invoice') {
      this.contractTabTitle = 'Invoice & Settlements';
    } else if (this.setContractTab == 'notes') {
      this.contractTabTitle = 'Notes';
    } else if (this.setContractTab == 'compliance') {
      this.contractTabTitle = 'Compliance Tasks';
    } else if (this.setContractTab == 'documents') {
      this.contractTabTitle = 'Documents';
    }
  }

  viewCreditCheck() {
    const ref = RootAppComponent.dialog.open(ContractCreditCheckComponent, {
      data: this.contractID,
      width: '1200px',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  viewCapacityCheck() {
    const ref = RootAppComponent.dialog.open(ContractCapacityCheckComponent, {
      data: this.contractID,
      width: '1200px',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  viewIdentityCheck() {
    const ref = RootAppComponent.dialog.open(ContractIdentityCheckComponent, {
      data: this.contractID,
      width: '1200px',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  viewMerchant(ID) {
    const ref = RootAppComponent.dialog.open(MerchantViewComponent, {
      data: ID,
      width: '600px',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  viewProduct(ID) {
    const ref = RootAppComponent.dialog.open(ProductViewModalComponent, {
      data: ID,
      width: '750px',
      panelClass: 'noCard',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  contact(id) {
    const data = {
      targetType: 'customer',
      targetID: id,
      asGuest: false,
      asProfile: true,
      asPractice: true,
    };
    AuthenticationService.contactInputEvent.emit(data);
  }

  openCustomerDetailsDialog(id) {
    const ref = RootAppComponent.dialog.open(CustomerViewComponent, {
      data: id,
      width: '600px',
      panelClass: 'noCard',
    });

    ref.componentInstance.closeModal.subscribe((res) => ref.close());
  }

  displayFundingHistory(id) {
    const ref = RootAppComponent.dialog.open(ContractDetailFundingHistoryComponent, {
      data: id,
      width: '600px',
    });

    ref.componentInstance.closeModal.subscribe((res) => ref.close());
  }

  openInviteViewDialog(ID) {
    const payload = {
      fields: 'ID',
    };
    this.contractService.onInvitation(this.contract['ID'], payload).subscribe((res) => {
      if (res && res['ID']) {
        const ref = RootAppComponent.dialog.open(InvitationViewModalComponent, {
          data: {
            invitationID: res['ID'],
            membership: res.membership,
          },
          width: '900px',
        });
        ref.componentInstance.close.subscribe((res) => {
          ref.close();
        });
        ref.componentInstance.openDetails.subscribe((id) => {
          ref.close();
          ref.afterClosed().subscribe((res) => {
            if (res.membership == true) {
              this.router.navigate(['/merchant', { outlets: { page: ['membership-view', id] } }]);
            } else {
              this.router.navigate(['/merchant', { outlets: { page: ['invitation-view', id] } }]);
            }
          });
        });
      }
    });
  }

  // tabChange(index) {
  //   if (index==1) {
  //     this.paymentsTab = true;
  //   }
  //   else if (index==2) {
  //     this.actionLogTab = true;
  //   }
  //   else if (index==3) {
  //     this.kpiTab = true;
  //   }
  //   else if (index==4) {
  //     this.contactsTab = true;
  //   }
  //   else if (index==5) {
  //     this.fundingHistoryTab = true;
  //   }
  //   else if (index==6) {
  //     this.documentsTab = true;
  //   }
  // }
}
