import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-informed-consent',
  templateUrl: './informed-consent.component.html',
  styleUrls: ['./informed-consent.component.css'],
})
export class InformedConsentComponent implements OnInit {
  @Input()
  merchantInformedConsent;

  @Output()
  agreement: EventEmitter<any> = new EventEmitter();

  isAgree = false;
  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      this.merchantInformedConsent = data;
    }
  }

  ngOnInit() {}

  changeAgreement() {
    this.agreement.emit(this.isAgree);
  }
}
