/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./feature-main-label-cover-view.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./feature-main-label-cover-view.component";
import * as i4 from "../shared/subscription-plan.service";
import * as i5 from "@angular/platform-browser";
import * as i6 from "@angular/material/dialog";
var styles_FeatureMainLabelCoverViewComponent = [i0.styles];
var RenderType_FeatureMainLabelCoverViewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FeatureMainLabelCoverViewComponent, data: {} });
export { RenderType_FeatureMainLabelCoverViewComponent as RenderType_FeatureMainLabelCoverViewComponent };
export function View_FeatureMainLabelCoverViewComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [], [[8, "src", 4]], [[null, "error"], [null, "load"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("error" === en)) {
        var pd_0 = (_co.onError() !== false);
        ad = (pd_0 && ad);
    } if (("load" === en)) {
        var pd_1 = (_co.onLoad() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "sub-label-view-header-modal"]], [[4, "background-image", null]], null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(4, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(5, { "default-background-image": 0 })], function (_ck, _v) { var _co = _v.component; var currVal_3 = "sub-label-view-header-modal"; var currVal_4 = _ck(_v, 5, 0, (_co.isImageError != false)); _ck(_v, 4, 0, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = true; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.postCover; _ck(_v, 1, 0, currVal_1); var currVal_2 = _co.postCoverURL; _ck(_v, 2, 0, currVal_2); }); }
export function View_FeatureMainLabelCoverViewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-feature-main-label-cover-view", [], null, null, null, View_FeatureMainLabelCoverViewComponent_0, RenderType_FeatureMainLabelCoverViewComponent)), i1.ɵdid(1, 638976, null, 0, i3.FeatureMainLabelCoverViewComponent, [i4.SubscriptionPlanService, i5.DomSanitizer, [2, i6.MAT_DIALOG_DATA]], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FeatureMainLabelCoverViewComponentNgFactory = i1.ɵccf("app-feature-main-label-cover-view", i3.FeatureMainLabelCoverViewComponent, View_FeatureMainLabelCoverViewComponent_Host_0, { mainFeatureLabelID: "mainFeatureLabelID", LastModified: "LastModified" }, { close: "close" }, []);
export { FeatureMainLabelCoverViewComponentNgFactory as FeatureMainLabelCoverViewComponentNgFactory };
