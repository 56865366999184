import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'binaryBool' })
export class BinaryBoolPipe implements PipeTransform {
  transform(value: '0' | '1' | 1 | 0) {
    if (typeof value === 'string') {
      return value === '1';
    } else {
      return value === 1;
    }
  }
}
