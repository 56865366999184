import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ErrorModalAuthenticationComponent } from './error-modal/error-modal.component';
import { LogoutComponent } from './logout/logout.component';
import { ResetUserNameComponent } from './reset-user-name/reset-user-name.component';
import { AuthenticationRoutingModule } from './shared/authentication-routing.module';
import { AuthenticationService } from './shared/authentication.service';
import { SignupComponent } from './signup/signup.component';

import { MatNativeDateModule } from '@angular/material';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { PasswordStrengthMeterModule } from 'angular-password-strength-meter';
import {
  AuthServiceConfig,
  FacebookLoginProvider,
  GoogleLoginProvider,
  SocialLoginModule,
} from 'angularx-social-login';
import { environment } from 'environments/environment';
import { NgPipesModule } from 'ngx-pipes';
import { AdminLoginComponent } from './admin-login/admin-login.component';
import { AuthenticationMethodViewComponent } from './authentication-method-view/authentication-method-view.component';
import { ChangeSocialMediaComponent } from './change-social-media/change-social-media.component';
import { CheckPasswordComponent } from './check-password/check-password.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { PasswordChangeComponent } from './password-change/password-change.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SecurityCodeCurrentUserComponent } from './security-code-current-user/security-code-current-user.component';
import { SecurityCodeInfoComponent } from './security-code-info/security-code-info.component';
import { SecurityCodeInput } from './security-code-input/security-code-input.component';
import { CustomDateTimePipe } from './shared/custom-date-time.pipe';
import { CustomDatePipe } from './shared/custom-date.pipe';
import { SafeHtmlPipe } from './shared/safe-html.pipe';
import { TimeLabelPast } from './shared/time-label-past.pipe';
import { SocialMediaInputComponent } from './social-media-input/social-media-input.component';

const config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider(environment.googleID),
  },
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider(environment.facebookID),
  },
]);

export function provideConfig() {
  return config;
}

@NgModule({
  imports: [
    AuthenticationRoutingModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgPipesModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatRadioModule,
    MatSelectModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatTableModule,
    MatTabsModule,
    MatTooltipModule,
    PasswordStrengthMeterModule,
    SocialLoginModule,
    TranslateModule,
  ],
  exports: [
    PasswordChangeComponent,
    MatFormFieldModule,
    LogoutComponent,
    SignupComponent,
    ForgotPasswordComponent,
    ResetUserNameComponent,
    ErrorModalAuthenticationComponent,
    ResetPasswordComponent,
    AdminLoginComponent,
    SecurityCodeInput,
    SecurityCodeInfoComponent,
    SocialMediaInputComponent,
    ChangeSocialMediaComponent,
    SecurityCodeCurrentUserComponent,
    CheckPasswordComponent,
    AuthenticationMethodViewComponent,
  ],
  declarations: [
    TimeLabelPast,
    CustomDatePipe,
    CustomDateTimePipe,
    SafeHtmlPipe,
    PasswordChangeComponent,
    LogoutComponent,
    SignupComponent,
    ForgotPasswordComponent,
    ResetUserNameComponent,
    ErrorModalAuthenticationComponent,
    ResetPasswordComponent,
    AdminLoginComponent,
    SecurityCodeInput,
    SecurityCodeInfoComponent,
    SocialMediaInputComponent,
    ChangeSocialMediaComponent,
    SecurityCodeCurrentUserComponent,
    CheckPasswordComponent,
    AuthenticationMethodViewComponent,
  ],
  providers: [
    TimeLabelPast,
    CustomDatePipe,
    CustomDateTimePipe,
    SafeHtmlPipe,
    AuthenticationService,
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig,
    },
  ],
})
export class AuthenticationModule {}
