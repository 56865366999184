import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'environments/environment';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';

@Component({
  selector: 'instant-offer-header-image',
  templateUrl: './instant-offer-header-image.component.html',
  styleUrls: ['./instant-offer-header-image.component.css'],
})
export class InstantOfferHeaderImageComponent implements OnInit {
  @Input() headerImage;
  @Input() hideZoomButton;

  profileLink;
  nodeServer = environment.nodeUrl;
  @Input()
  merchantID;

  constructor(private AuthenticationService: AuthenticationService) {}

  ngOnInit() {}

  getCircle(headerImage) {
    if (headerImage && headerImage.indexOf('?type=c') != -1) {
      return true;
    }

    return false;
  }
}
