<div class="full-width white-background" *ngIf="merchantID">
  <mat-card>
    <form #userContactForm="ngForm">
      <div class="card-header primary-gradient-img clearfix stacked">
        <div class="row clearfix">
          <button class="btn-close btn-clear mat-button white" (click)="closeEvent()">
            <mat-icon>clear</mat-icon>
          </button>
          <div class="clearfix">
            <h2 class="sm-h2 pull-left rm-mt rm-mb" *ngIf="thirdPartyAccessID && thirdPartyAccessIDs.length <= 0">
              Edit Third-Party API Key.
            </h2>

            <h2 class="sm-h2 pull-left rm-mt rm-mb" *ngIf="!thirdPartyAccessID && thirdPartyAccessIDs.length <= 0">
              Create Third-Party API Key.
            </h2>

            <h2 class="sm-h2 pull-left rm-mt rm-mb" *ngIf="!thirdPartyAccessID && thirdPartyAccessIDs.length > 0">
              Edit Third-Party Group API Key.
            </h2>
          </div>
        </div>
      </div>

      <mat-dialog-content class="stacked-card-view">
        <h3 class="sr-title rm-mt" *ngIf="thirdPartyAccessIDs.length <= 0">{{ merchantTradingAs }}</h3>

        <div class="full-width">
          <mat-form-field class="full-width" *ngIf="thirdPartyAccessIDs.length <= 0" appearance="outline">
            <mat-label>Label: Enter a unique label </mat-label>
            <input [(ngModel)]="comment" matInput placeholder="Label: Enter a unique label " name="Comment" required/>
          </mat-form-field>

          <mat-form-field
            class="full-width"
            *ngIf="!thirdPartyAccessID && thirdPartyAccessIDs.length <= 0"
            appearance="outline"
          >
            <mat-label>Third-Party Application</mat-label>

            <mat-select
              *ngIf="thirdPartyAccessIDs.length <= 0"
              [(ngModel)]="clientID"
              (ngModelChange)="getClientObject()"
              name="clientID"
              placeholder="Third-Party Application"
              required
            >
              <mat-select-trigger>
                <div class="full-width flex flex-cust">
                  <app-third-party-client-picture
                    class="third-party-access-thumbnail prod text-center pull-left mr"
                    [hideZoomButton]="true"
                    [thirdPartyClientID]="clientID"
                  >
                  </app-third-party-client-picture>

                  <p class="rm-m third-party-p full-width pull-left">
                    {{ clientName }}
                  </p>

                  <button
                    class="mr"
                    (click)="$event.stopPropagation(); view(clientID)"
                    mat-button
                    mat-icon-button
                  >
                    <mat-icon>visibility</mat-icon>
                  </button>
                </div>
              </mat-select-trigger>

              <mat-option *ngFor="let s of clients" [value]="s.ID">
                <div class="full-width flex flex-cust">
                  <app-third-party-client-picture
                    class="third-party-access-thumbnail prod text-center pull-left mr"
                    [hideZoomButton]="true"
                    [thirdPartyClientID]="s.ID"
                  >
                  </app-third-party-client-picture>

                  <p class="rm-m third-party-p full-width pull-left">
                    <strong>{{ s.Label }}</strong>
                  </p>

                  <span
                    *ngIf="isPromoterOrAdmin == true"
                    [ngClass]="{
                      'chip-enable': s.Is_Active === '1',
                      'chip-disable': !s.Is_Active === '0'
                    }"
                  >{{ s.Is_Active == '0' ? 'Not Active' : 'Active' }}</span
                  >

                  <button
                    class="mr"
                    (click)="$event.stopPropagation(); view(s['ID'])"
                    mat-button
                    mat-icon-button
                  >
                    <mat-icon style="width: 40px">visibility</mat-icon>
                  </button>
                </div>
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="full-width" *ngIf="thirdPartyAccessIDs.length <= 0" appearance="outline" required>
            <mat-label>Default Staff Member: All invitations will be sent from this staff member</mat-label>
            <mat-select
              [(ngModel)]="dentistID"
              name="dentistID"
              placeholder="Default Staff Member: All invitations will be sent from this staff member"
            >
              <mat-option *ngFor="let s of dentists" [value]="s.ID">
                {{ s.CalculatedName }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <!-- <mat-form-field class="full-width" appearance="outline">
            <mat-label>Access Type</mat-label>
            <mat-select name="typeCode" [(ngModel)]="typeCode" placeholder="Access Type" required>
              <mat-option *ngFor="let s of statusArray" [value]="s.Code">
                {{ s.Label }}
              </mat-option>
            </mat-select>
          </mat-form-field> -->

          <h3 class="sr-title" *ngIf="thirdPartyAccessIDs.length <= 0">Key Management</h3>

          <mat-checkbox
            class="full-width clearfix smb"
            *ngIf="!thirdPartyAccessID && thirdPartyAccessIDs.length <= 0"
            [(ngModel)]="removeOld"
            name="removeOld"
          >
            Remove all this Third-Party Applications Existed API Keys
          </mat-checkbox>

          <mat-checkbox
            class="full-width clearfix smb"
            *ngIf="thirdPartyAccessIDs.length <= 0"
            [(ngModel)]="isActive"
            name="isActive"
          >
            Active?
          </mat-checkbox>

          <mat-checkbox
            class="full-width clearfix smb"
            *ngIf="!thirdPartyAccessID && thirdPartyAccessIDs.length <= 0"
            [(ngModel)]="isManual"
            name="isManual"
          >
            Manually add API Key?
          </mat-checkbox>

          <mat-form-field
            class="full-width clearfix"
            *ngIf="!thirdPartyAccessID && isManual == true && thirdPartyAccessIDs.length <= 0"
            appearance="outline"
          >
            <mat-label>API Key</mat-label>
            <input [(ngModel)]="apiKey" matInput placeholder="API Key" name="apiKey" required/>
          </mat-form-field>

          <h3 class="sr-title rm-mb">Default Template</h3>

          <div class="full-width flex flex-cust mb">
            <p class="full-width small pull-left">Choose the template that will be applied to invitations sent.</p>
            <button
              class="ml btn pull-right"
              *ngIf="originTemplateID && templateID !== originTemplateID && originTemplateLabel"
              (click)="reset()"
              mat-icon-button
              style="height: 40px"
              color="primary"
              matToolTip="Reset to default template"
            >
              <mat-icon class="fas fa-undo primary-color"></mat-icon>
            </button>
            <button
              class="sml btn mr pull-right"
              (click)="openTemplateLookup()"
              mat-raised-button
              style="height: 40px"
              color="primary"
            >
              <span>Select Template</span><span class="ml mr"></span>
            </button>
          </div>

          <div class="full-width flex" *ngIf="templateID && templateLabel">
            <div class="full-width text-center secret-container">
              <div class="full-width text-left">
                <h3 class="full-width rm-m text-left">
                  {{ templateLabel }}

                  <button
                    class="ml smr pull-right btn"
                    (click)="viewTemplate(templateID)"
                    mat-button
                    mat-icon-button
                  >
                    <mat-icon>visibility</mat-icon>
                  </button>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </mat-dialog-content>

      <div class="drop-button text-center white-background">
        <hr/>

        <button
          class="ml btn-large mr"
          [disabled]="userContactForm.invalid || !templateID || !templateLabel"
          (click)="save()"
          mat-raised-button
          color="accent"
        >
          <span>Save</span>
        </button>
      </div>
    </form>
  </mat-card>
</div>
