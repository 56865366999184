import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable()
export class MerchantProspectService {
  constructor(private http: HttpClient) {}

  getList(payload, isPromoterOrAdmin = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (isPromoterOrAdmin == true) {
      return this.http
        .get(environment.nodeUrl + '/merchant-prospect/global', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/merchant-prospect/', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getMerchantProspectDetails(ID) {
    return this.http
      .get(environment.nodeUrl + '/merchant-prospect/' + ID)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  editMerchantProspect(ID, payload) {
    return this.http
      .put(environment.nodeUrl + '/merchant-prospect/global/' + ID, payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getMerchantProspectProfilePic(ID, payload = {}) {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }
    return this.http
      .get(`${environment.nodeUrl}/merchant-prospect/profile-picture/` + ID, options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getProfilePicStreamLink(ID) {
    if (ID) {
      return `${environment.nodeUrl}/merchant-prospect/profile-picture-stream/` + ID;
    }
    return null;
  }

  uploadMerchantProspectProfilePic(ID, payload = {}) {
    return this.http
      .put(`${environment.nodeUrl}/merchant-prospect/profile-picture/` + ID, payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }
}
