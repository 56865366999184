/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./selected-teeth-view.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../tooth-view/tooth-view.component.ngfactory";
import * as i3 from "../tooth-view/tooth-view.component";
import * as i4 from "@angular/common";
import * as i5 from "./selected-teeth-view.component";
var styles_SelectedTeethViewComponent = [i0.styles];
var RenderType_SelectedTeethViewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SelectedTeethViewComponent, data: {} });
export { RenderType_SelectedTeethViewComponent as RenderType_SelectedTeethViewComponent };
function View_SelectedTeethViewComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "teethShap"], ["val", "31"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-tooth-view", [], null, null, null, i2.View_ToothViewComponent_0, i2.RenderType_ToothViewComponent)), i1.ɵdid(2, 114688, null, 0, i3.ToothViewComponent, [], { tooth: [0, "tooth"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""]))], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit.toothNumber; _ck(_v, 4, 0, currVal_1); }); }
export function View_SelectedTeethViewComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_SelectedTeethViewComponent_1)), i1.ɵdid(1, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectedTeeth; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_SelectedTeethViewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-selected-teeth-view", [], null, null, null, View_SelectedTeethViewComponent_0, RenderType_SelectedTeethViewComponent)), i1.ɵdid(1, 114688, null, 0, i5.SelectedTeethViewComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SelectedTeethViewComponentNgFactory = i1.ɵccf("app-selected-teeth-view", i5.SelectedTeethViewComponent, View_SelectedTeethViewComponent_Host_0, { selectedTeeth: "selectedTeeth", isAdult: "isAdult" }, {}, []);
export { SelectedTeethViewComponentNgFactory as SelectedTeethViewComponentNgFactory };
