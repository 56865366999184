import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { TagsService } from '../shared/tags.service';

@Component({
  selector: 'app-tags-view',
  templateUrl: './tags-view.component.html',
  styleUrls: ['./tags-view.component.css'],
})
export class TagsViewComponent implements OnInit {
  @Input()
  tagID;

  tag;

  sessionType;

  constructor(
    private tagsService: TagsService,
    private activeRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private location: Location
  ) {}

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((st) => {
      this.sessionType = st;

      this.activeRoute.params.subscribe((params) => {
        if (params['tagID']) {
          this.tagID = params['tagID'];
        }

        if (this.tagID) {
          this.tagsService.getOneTag(this.tagID, {}, this.sessionType).subscribe((res) => {
            if (res) {
              this.tag = res;
            }
          });
        }
      });
    });
  }

  goback() {
    this.location.back();
  }
}
