import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
@Injectable()
export class AiScansService {
  constructor(private http: HttpClient) {}

  getAiScansList(payload): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    return this.http
      .get(`${environment.nodeUrl}/customer-prospect`, options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getAiScansDetails(ID, payload = {}, sessionType): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http
        .get(environment.nodeUrl + '/customer-prospect/global/' + ID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
      return this.http
        .get(environment.nodeUrl + '/customer-prospect/' + ID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/customer-prospect/public/' + ID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getAiScansListGlobal(payload, isAdminOrPromoter = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (isAdminOrPromoter == true) {
      return this.http
        .get(`${environment.nodeUrl}/customer-prospect/global`, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(`${environment.nodeUrl}/customer-prospect`, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getMerchantTag(payload, isPromoterOrAdmin = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (isPromoterOrAdmin == true) {
      return this.http
        .get(environment.nodeUrl + '/tag-merchant/global', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/tag-merchant', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  modifyMerchantTag(ID, payload, isAdminOrPromoter = false): Observable<any> {
    if (isAdminOrPromoter == true) {
      return this.http
        .put(environment.nodeUrl + '/tag-merchant/global/' + ID, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .put(environment.nodeUrl + '/tag-merchant/' + ID, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  createMerchantTag(payload, isAdminOrPromoter = false): Observable<any> {
    if (isAdminOrPromoter == true) {
      return this.http
        .post(environment.nodeUrl + '/tag-merchant/global', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .post(environment.nodeUrl + '/tag-merchant', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }
}
