import { Component, Input, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { UtilsService } from '../../services/utils.service';
import { ConfirmDialog } from '../../types/confirm-dialog';
import { NotifyAppComponent } from '../../types/notify-app-component';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { MobileLabelInputComponent } from '../mobile-label-input/mobile-label-input.component';
import { RootAppComponent } from '../root-component/root-component.component';

@Component({
  selector: 'app-mobile-input-advanced',
  templateUrl: './mobile-input-advanced.component.html',
  styleUrls: ['./mobile-input-advanced.component.css'],
})
export class MobileInputAdvancedComponent implements OnInit {
  @Input()
  targetID;

  @Input()
  targetMobileType;

  @Input()
  mobileTypes;

  @Input()
  myMobile;

  @Input()
  isDisabled = false;

  mobileType;
  isPrimaryMobile = true;
  @Input()
  type = 'current';
  sessionType;

  constructor(private utilService: UtilsService, private authenticationService: AuthenticationService) {}

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((st) => {
      this.sessionType = st;

      this.mobileType = this.targetMobileType;
    });
  }

  createPhoneLabel() {
    const ref = RootAppComponent.dialog.open(MobileLabelInputComponent, {
      data: {
        ownerID: this.targetID,
        type: this.type,
      },
      width: '800px',
    });

    ref.componentInstance.getResult.subscribe((res) => {
      if (res) {
        ref.close();
        this.mobileType = res.Label;
        this.mobileTypes.push(res.Label);
        if (res['IsPrimary'] == '1') {
          this.targetMobileType = res.Label;
        }
        this.getMobileByType(res.Label);
      }
    });

    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
        this.mobileType = this.targetMobileType;
        this.getMobileByType(this.mobileType);
      }
    });
  }

  removePhoneByLabel(label) {
    const confirmDialog = new ConfirmDialog(
      'warning',
      'Delete phone',
      'Are you sure to remove this phone with label permanently?',
      'Cancel',
      'Proceed'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
      width: '650px',
    });

    ref.componentInstance.onConfirm.subscribe((res) => {
      if (res == true) {
        this.utilService.deletePhoneByLabel(this.targetID, label, this.type, this.sessionType).subscribe((res) => {
          if (res) {
            NotifyAppComponent.displayToast('success', 'Delete phone', 'You have successfully deleted this phone');
            this.mobileType = this.targetMobileType;
            this.mobileTypes.splice(this.mobileTypes.indexOf(label), 1);
            this.getMobileByType(this.mobileType);
            ref.close();
          }
        });
      } else {
        ref.close();
      }
    });
  }

  editPhoneByLabel(label) {
    const ref = RootAppComponent.dialog.open(MobileLabelInputComponent, {
      data: {
        ownerID: this.targetID,
        label,
        isEdit: true,
        mobileNumber: this.myMobile,
        mobileTypes: this.mobileTypes,
        isPrimary: this.isPrimaryMobile,
        type: this.type,
      },
      width: '800px',
    });

    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });

    ref.componentInstance.getResult.subscribe((res) => {
      if (res) {
        ref.close();
        this.mobileType = res.Label;
        if (res['IsPrimary'] == '1') {
          this.targetMobileType = res.Label;
        }
        this.getMobileByType(res.Label);
      }
    });
  }

  getMobileByType(e) {
    if (e) {
      this.utilService.getMobileByLabel(this.targetID, e, this.type, this.sessionType).subscribe((res) => {
        if (res) {
          this.myMobile = res['Number'];
          this.isPrimaryMobile = res['IsPrimary'] == '1' ? true : false;
        }
      });
    }
  }

  convertToLowerCase(val) {
    if (val) {
      return val.toLowerCase();
    }
  }
}
