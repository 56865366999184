<div class="row clearfix">
  <h3 class="sm-h3 sr-title rm-mt rm-mb">Informed Consent: {{"PARAGRAPH.Dental and Oral Health" | translate}}</h3>
</div>
<mat-dialog-content class="infCons">
  <div class="row clearfix">
    <div class="consent-area">
      <div *ngIf="merchantInformedConsent" [innerHTML]="merchantInformedConsent | safeHtml"></div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="borderTop">
  <div class="full-width row clearfix text-center">
    <mat-slide-toggle class="slide-toggle-large alternate-theme" [(ngModel)]="isAgree">
      I make the declarations above
    </mat-slide-toggle>
  </div>
  <div class="full-width row clearfix text-center">
    <button class="proceed" [disabled]="!isAgree" (click)="changeAgreement()" mat-raised-button color="accent">
      Proceed
    </button>
  </div>
</mat-dialog-actions>