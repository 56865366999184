<div class="datepicker-container flex" *ngIf="isTime == false && isDate == true" [ngClass]="inputDateClass">
  <mat-form-field class="full-width" *ngIf="isMobile == false && displayDateInput == true" appearance="outline">
    <mat-label>{{ label }}</mat-label>
    <input
      [(ngModel)]="_date"
      [max]="maxCalendarDate"
      [min]="minCalendarDate"
      [disabled]="disabled && disabledAll"
      [matDatepicker]="picker"
      (dateChange)="sendDate($event.value, true, true)"
      (click)="openCalendar()"
      format="dd-mm-yyyy"
      matInput
      placeholder="{{ label }}"
    />

    <button
      class="btn-close pull-left mat-button clear-button-date"
      *ngIf="isClear == true && _date != null"
      (click)="removeDate()"
      matSuffix
      matTooltip="Clear date"
    >
      <mat-icon>close</mat-icon>
    </button>

    <mat-datepicker-toggle class="calendar-button-date" [for]="picker" matPrefix></mat-datepicker-toggle>
    <mat-datepicker #picker [disabled]="disabledAll" startView="{{ startView }}" matPrefix></mat-datepicker>
  </mat-form-field>

  <mat-form-field class="mobile full-width" *ngIf="isMobile == true && displayDateInput == true" appearance="outline">
    <mat-label>Click to enter date</mat-label>
    <input
      [(ngModel)]="_date"
      [max]="maxCalendarDate"
      [min]="minCalendarDate"
      [disabled]="disabledAll"
      (ngModelChange)="sendDate($event, true, true)"
      type="date"
      matInput
    />
  </mat-form-field>

  <div *ngIf="displayDateInput != true">
    <input
      class="display-none"
      [(ngModel)]="_date"
      [max]="maxCalendarDate"
      [min]="minCalendarDate"
      [disabled]="disabled && disabledAll"
      [matDatepicker]="picker2"
      (dateChange)="sendDate($event.value, true, true)"
      (click)="openCalendar()"
      format="dd-mm-yyyy"
      matInput
      placeholder="{{ label }}"
    />
    <mat-datepicker-toggle class="display-none calendar-button-date" [for]="picker2"></mat-datepicker-toggle>
    <mat-datepicker #picker2 [disabled]="disabledAll" startView="{{ startView }}"></mat-datepicker>
  </div>
</div>

<div class="datepicker-container flex" *ngIf="isTime == true && isDate == true" [ngClass]="inputDateClass">
  <mat-form-field class="qtr-width datepicker-container-qtr-width" *ngIf="isMobile == false" appearance="outline">
    <mat-label>{{ label }}</mat-label>
    <input
      [(ngModel)]="_date"
      [max]="maxCalendarDate"
      [min]="minCalendarDate"
      [disabled]="disabled && disabledAll"
      [matDatepicker]="picker"
      (dateChange)="sendDate($event.value, true, true)"
      (click)="openCalendar()"
      format="dd-mm-yyyy"
      matInput
      placeholder="{{ label }}"
    />

    <button
      class="btn-close pull-left mat-button clear-button-date"
      *ngIf="isClear == true && _date != null"
      (click)="removeDate()"
      matSuffix
      matTooltip="Clear date"
    >
      <mat-icon>close</mat-icon>
    </button>

    <mat-datepicker-toggle class="calendar-button-date" [for]="picker" matPrefix></mat-datepicker-toggle>
    <mat-datepicker #picker [disabled]="disabledAll" matPrefix></mat-datepicker>
  </mat-form-field>
  <mat-form-field class="mobile half-width" *ngIf="isMobile == true" appearance="outline">
    <input
      [(ngModel)]="_date"
      [max]="maxCalendarDate"
      [min]="minCalendarDate"
      [disabled]="disabledAll"
      (ngModelChange)="sendDate($event, true, true)"
      type="date"
      matInput
    />
  </mat-form-field>
  <app-time-input class="add-ml" [time]="selectedTime" [timePickerType]="timePickerType" (getTime)="changeTime($event)">
  </app-time-input>
</div>

<div class="datepicker-container flex ml" *ngIf="isTime == true && isDate == false" [ngClass]="inputDateClass">
  <button
    class="btn-close pull-left mat-button clear-button-date"
    *ngIf="isClear == true && _date != null"
    (click)="removeDate()"
    matTooltip="Clear date"
  >
    <mat-icon>close</mat-icon>
  </button>

  <app-time-input [time]="selectedTime" [timePickerType]="timePickerType" (getTime)="changeTime($event)">
  </app-time-input>
</div>
