import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { AuthenticationService } from '../../authentication/shared/authentication.service';
import { WikiService } from '../shared/wiki.service';
import { WikiLeftMenuComponent } from '../wiki-left-menu/wiki-left-menu.component';

@Component({
  selector: 'wiki-header',
  templateUrl: './wiki-header.component.html',
  styleUrls: ['./wiki-header.component.css'],
})
export class WikiHeaderComponent implements OnInit {
  isAdmin = false;
  notifications;
  sessionTypeParams;
  search;

  utils = new UtilsClass();

  constructor(
    private router: Router,
    private wikiService: WikiService,
    private activeRoute: ActivatedRoute,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit() {
    this.activeRoute.url.subscribe((res) => {
      this.sessionTypeParams = this.utils.getSessionTypeParams(res);

      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isAdmin = res;
      });
    });
  }

  newPage() {
    if (this.sessionTypeParams) {
      this.router.navigate([
        '/wiki-' + this.sessionTypeParams,
        {
          outlets: {
            wikiPageRoute: ['redirection', 'create'],
          },
        },
      ]);
    } else {
      this.router.navigate([
        '/wiki',
        {
          outlets: {
            wikiPageRoute: ['redirection', 'create'],
          },
        },
      ]);
    }
  }

  goHome() {
    if (this.sessionTypeParams) {
      this.router.navigate([
        '/wiki-' + this.sessionTypeParams,
        {
          outlets: {
            wikiPageRoute: ['home'],
          },
        },
      ]);
    } else {
      this.router.navigate(['/wiki']);
    }
  }

  searchEvent() {
    if (this.sessionTypeParams) {
      this.router.navigate([
        '/wiki-' + this.sessionTypeParams,
        {
          outlets: {
            wikiPageRoute: ['search', this.search],
          },
        },
      ]);
    } else {
      this.router.navigate([
        '/wiki',
        {
          outlets: {
            wikiPageRoute: ['search', this.search],
          },
        },
      ]);
    }

    // this.router.navigate([
    //   "/wiki",
    //   {
    //     outlets: {
    //       'wikiPageRoute': ["search", this.search
    //       ]
    //     }
    //   }
    // ]);
  }

  resetOrder() {
    let confirm = new ConfirmDialog('sort', '', 'Are you sure you want to Reset Articles order ?', 'No', 'Yes');

    let ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirm,
    });
    ref.componentInstance.onConfirm.subscribe((confirmation) => {
      if (confirmation === false) {
        ref.close();
      } else {
        this.wikiService.resetOrder().subscribe((res) => {
          if (res) {
            ref.close();
            NotifyAppComponent.displayToast('success', 'Successfull operation', 'Wiki Articles are in order ');

            WikiLeftMenuComponent.updateHeader.emit(true);
          }
        });
      }
    });
  }

  viewAs(c) {
    if (c == 'all') {
      this.sessionTypeParams = null;
      this.router.navigate(['/wiki']);
    } else if (c) {
      this.sessionTypeParams = c;

      this.router.navigate(['/wiki-' + c]);
    }
  }
}
