import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';

export class arrangement {
  status: string;
  startDate: string;
  endDate: string;
  payments: string;
  total1: string;
  underpaid: string;
  interest: string;
  shortfall: string;
  handleShortfall: string;
  catchupPeroid: string;
  total2: string;
  endOfLoan: string;
}

const ARRANGEMENT_DATA: arrangement[] = [
  {
    status: '',
    startDate: '',
    endDate: '',
    payments: '',
    total1: '',
    underpaid: '',
    interest: '',
    shortfall: '',
    handleShortfall: '',
    catchupPeroid: '',
    total2: '',
    endOfLoan: '',
  },
  {
    status: '',
    startDate: '',
    endDate: '',
    payments: '',
    total1: '',
    underpaid: '',
    interest: '',
    shortfall: '',
    handleShortfall: '',
    catchupPeroid: '',
    total2: '',
    endOfLoan: '',
  },
  {
    status: '',
    startDate: '',
    endDate: '',
    payments: '',
    total1: '',
    underpaid: '',
    interest: '',
    shortfall: '',
    handleShortfall: '',
    catchupPeroid: '',
    total2: '',
    endOfLoan: '',
  },
  {
    status: '',
    startDate: '',
    endDate: '',
    payments: '',
    total1: '',
    underpaid: '',
    interest: '',
    shortfall: '',
    handleShortfall: '',
    catchupPeroid: '',
    total2: '',
    endOfLoan: '',
  },
  {
    status: '',
    startDate: '',
    endDate: '',
    payments: '',
    total1: '',
    underpaid: '',
    interest: '',
    shortfall: '',
    handleShortfall: '',
    catchupPeroid: '',
    total2: '',
    endOfLoan: '',
  },
];

@Component({
  selector: 'app-contract-arrangements',
  templateUrl: './contract-arrangements.component.html',
  styleUrls: ['./contract-arrangements.component.css'],
  animations: [
    trigger('simpleFadeAnimation', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [style({ opacity: 0, height: 0 }), animate(300)]),
      transition(':leave', animate(300, style({ opacity: 0, height: '*' }))),
    ]),
  ],
})
export class ContractArrangementsComponent implements OnInit {
  @Input()
  status;

  @Input()
  contractID;

  isAdminOrPromoter = false;

  displayedColumns: string[] = [
    'status',
    'startDate',
    'endDate',
    'payments',
    'total1',
    'underpaid',
    'interest',
    'shortfall',
    'handleShortfall',
    'catchupPeroid',
    'total2',
    'endOfLoan',
  ];
  arrangementSource = ARRANGEMENT_DATA;

  newArrangement = false;

  constructor(private AuthenticationService: AuthenticationService, private activeRoute: ActivatedRoute) {}

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      if (params['status']) {
        this.status = params['status'];
      }

      this.AuthenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isAdminOrPromoter = res;
      });
    });
  }

  addNew() {}
}
