import { animate, animateChild, query, stagger, style, transition, trigger } from "@angular/animations";
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Optional,
  Output,
  SimpleChanges,
  ViewChild
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatButtonToggleGroup, MAT_DIALOG_DATA } from "@angular/material";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthenticationService } from "../../../core/authentication/shared/authentication.service";
import { Settings } from "../../../shared/types/settings";
import { UtilsClass } from "../../../shared/types/utils/utils.class";
import { RootAppComponent } from "../../../shared/components/root-component/root-component.component";
import * as _ from "lodash";
import { DocumentLinkViewComponent } from "../document-link-view/document-link-view.component";
import { DocumentLinkCreateComponent } from "../document-link-create/document-link-create.component";
import { IframeViewComponent } from "../../../shared/components/iframe-view/iframe-view.component";
import { DocumentService } from "../shared/document.service";
import { ConfirmDialog } from "../../../shared/types/confirm-dialog";
import { ConfirmDialogComponent } from "../../../shared/components/confirm-dialog/confirm-dialog.component";
import { NotifyAppComponent } from "../../../shared/types/notify-app-component";
import { environment } from "environments/environment";
import { ClipboardService } from "ngx-clipboard";
import { DocumentLinkShareComponent } from "../document-link-share/document-link-share.component";
import { LandingPageNewModalComponent } from "../landing-page-new-modal/landing-page-new-modal.component";
import { ProductService } from "../../product/shared/product.service";
import { DentistService } from "../../dentist/shared/dentist.service";

@Component({
  selector: 'document-list-thumbnail',
  templateUrl: './document-list-thumbnail.component.html',
  styleUrls: ['./document-list-thumbnail.component.css'],
  animations: [
    trigger("fade", [transition(":enter", [style({ opacity: 0 }), animate(".1s ease")])]),
    trigger("stagger", [transition(":enter", [query(":enter", stagger(".1s", [animateChild()]), { optional: true })])])
  ]
})
export class DocumentListThumbnailComponent implements OnInit {



  @Output()
  getLandingPageID = new EventEmitter();
  @Output()
  getLandingPageURL = new EventEmitter();
  @Output()
  getLandingPage = new EventEmitter();
  @Input()
  displayHeader = true;

  @Input()
  isActive = true;

  env = environment.nodeUrl

  @Input()
  visitorsType
  selectedType

  selectedStatus

  dentists = [];
  financedProductGroup = [];
  landingPages = [];
  util = new UtilsClass();
  orderByFilter = "-DateTimeCreated";

  @Output()
  close = new EventEmitter();

  settings = Settings.global;
  filter;
  serviceRef;
  isAdminOrPromoter = false;
  socialMediaType = "";
  currentUser;
  sessionType;

  selectedTagList: any = [];

  @Output()
  confirmShare = new EventEmitter();

  @Output()
  getSelectedTemplate = new EventEmitter();
  isModal = false;

  @Output()
  getSelectedTemplateID = new EventEmitter();
  currentPractice;

  renderValue = {};

  dynamicData = [];
  section = 1;
  limit = 100;
  displayLoadMore = false;
  finishLoading = false;

  utils = new UtilsClass();

  merchantID


  types = [
    {
      Code: 'FLandPg',
      Label: 'Finance Landing Page',
      Icon: 'fas fa-search-dollar'
    },
    {
      Code: 'PLandPg',
      Label: 'Product Landing Page',
      Icon: 'fas fa-images'
    },
  ]

  isDefault
  currentDentist;

  merchantTags = []
  defaultFilter = null;

  isClonedStored = false;
  isClonedDefault = false;
  isClonedPublic = false;
  cloneDefault = false;

  viewOnly = false;


  constructor(

    private dentistService: DentistService,
    private productService: ProductService,
    private _clipboardService: ClipboardService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private landingPageService: DocumentService,
    private authenticationService: AuthenticationService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {

      if (data.isModal === true) {
        this.isModal = data.isModal
      }
      if (data.isActive === true) {
        this.isActive = data.isActive
      }
      if (data.visitorsType) {
        this.visitorsType = data.visitorsType
      }

      if (data.cloneDefault != null) {
        this.cloneDefault = data.cloneDefault;
        this.isModal = true;
      }

      if (data.isClonedPublic != null) {
        this.isClonedPublic = data.isClonedPublic;
        this.isModal = true;
      }
      if (data.isClonedDefault != null) {
        this.isClonedDefault = data.isClonedDefault;
        this.isModal = true;
      }
      if (data.isClonedStored != null) {
        this.isClonedStored = data.isClonedStored;
        this.isModal = true;
      }

      if (data.viewOnly != null) {
        this.viewOnly = data.viewOnly;
        this.isModal = true;
      }


    }
  }

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      if (params["isDefault"] === 'true' || params["isDefault"] === true) {
        this.isDefault = true;
      }
      else if (params["isDefault"] === 'false' || params["isDefault"] === false) {
        this.isDefault = false;
      }


      this.authenticationService.getCurrentDentist().subscribe((CurrentDentist) => {
        this.currentDentist = CurrentDentist;

        this.authenticationService.getCurrentPractice().subscribe((currentPractice) => {
          this.currentPractice = currentPractice;
          this.authenticationService.getSessionType().subscribe((sessionType) => {
            this.sessionType = sessionType;



            this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
              this.isAdminOrPromoter = res;

              this.section = 1;
              this.landingPages = [];
              this.setup();
            });
          });
        });
      });
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isAdminOrPromoter = res;

      this.section = 1;
      this.landingPages = [];
      this.setup();
    });
  }


  toLowerCase(t) {
    if (t) {
      return String(t).toLocaleLowerCase();
    }
    return null;
  }

  setup() {



    this.dentistService.getList({ merchantID: this.merchantID, fields: 'ID,CalculatedName' }, this.isAdminOrPromoter).subscribe(res => {
      if (res && res.length > 0) {
        this.dentists = res;
      }
    })

    let p1 = {
      merchantID: this.merchantID,
      isFinance: true,
    };

    this.productService.getMerchantProductGroup(p1, this.isAdminOrPromoter).subscribe((res) => {
      if (res) {
        this.financedProductGroup = res;
      }
    })



    let payload = {
      section: this.section,
      isDefault: this.isDefault,
      onlyDefault: this.isDefault,
      limit: this.limit,
      isStored: true,
      addDefault: true,
      fields: 'ID,LastModified,DateTimeCreated,Merchant_key,Merchant_Trading_As,Label,Title,Description,Message,IsActive,Theme,DocumentFKs,Tags,IsDefault,IsPublic,Is_Stored,Document_key,Date.Expire,Product_Group.Label,Product_Group.Code,Treatment_Amount,Is_Finance,Contact_CalculatedName,Contact_key,Display_Appointment_Page,Display_Tips_Offers_Page,Display_Going_Green_Page,Header_Image,Type.Code,Facebook_Visitors,Linkedin_Visitors,Instagram_Visitors,Twitter_Visitors,Pinterest_Visitors,Email_Visitors,Practice_Site_Visitors,SMS_Visitors,Other_Visitors,Campaign_Visitors,Offer_Page_Visitors,QR_Scan_Visitors'
    };


    if (this.isDefault === false) {
      payload.addDefault = false;

    }


    this.landingPageService.getDocumentLinkList(payload, this.sessionType).subscribe((res) => {
      if (res) {
        if (res && res.length >= this.limit) {
          this.displayLoadMore = true;
        } else {
          this.displayLoadMore = false;
        }

        if (res.length > 0) {
          for (let i = 0; i < res.length; i++) {

            if (res[i]['IsDefault'] === '1' && this.isAdminOrPromoter != true) {
              res[i]['Visitors'] = 0
            }
            res[i]['fullName'] = '';
            if (res[i] && res[i]['Label']) {
              res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['Label'];
            }
            if (res[i] && res[i]['Description']) {
              res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['Description'];
            }
            if (res[i] && res[i]['Title']) {
              res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['Title'];
            }

            if (res[i] && res[i]['ID']) {
              res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['ID'];
            }
            if (res[i] && res[i]['Treatment_Amount']) {
              res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['Treatment_Amount'];
            }
            if (res[i] && res[i]['Message']) {
              res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['Message'];
            }
            if (res[i] && res[i]['Theme']) {
              res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['Theme'];
            }
            if (res[i] && res[i]['Merchant_Trading_As']) {
              res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['Merchant_Trading_As'];
            }
            if (res[i] && res[i]['Type.Label']) {
              res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['Type.Label'];
            }
            if (res[i] && res[i]['Type.Code']) {
              res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['Type.Code'];
            }
            if (res[i] && res[i]['Contact_CalculatedName']) {
              res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['Contact_CalculatedName'];
            }
            if (res[i] && res[i]['ID']) {
              res[i]['fullName'] = res[i]['fullName'] + ',' + res[i]['ID'];
            }

            if (res && res[i] && res[i]['DocumentFKs']) {
              res[i]['DocumentFKs'] = res[i]['DocumentFKs'].split('|');
            } else {
              res[i]['DocumentFKs'] = [];
            }

            if (res && res[i] && res[i]['Tags']) {
              res[i]['Tags'] = res[i]['Tags'].split('|');
              res[i]['Tags'] = _.uniq(res[i]['Tags']);
              this.merchantTags = this.merchantTags.concat(res[i]['Tags']);
            } else {
              res[i]['Tags'] = [];
              this.merchantTags = this.merchantTags.concat(res[i]['Tags']);
            }
            res[i]['isNew'] = this.isNew(res[i]['DateTimeCreated']);


            if (res[i]['IsDefault'] === '1' && this.isAdminOrPromoter != true) {
              res[i]['Visitors'] = 0
            }

          }


        }




        this.merchantTags = _.uniq(this.merchantTags);
        this.merchantTags = JSON.parse(JSON.stringify(this.merchantTags));

        this.landingPages = _.uniqBy(this.landingPages.concat(res), "ID");

        if (this.defaultFilter === null) {
          let index = this.landingPages.findIndex(item => {
            if (item && item.IsDefault === '0') {
              return true;
            }
            return false;
          })

          if (index != -1) {
            this.defaultFilter = '0'
          }
        }
      }
      this.finishLoading = true;
    });
  }
  isNew(date) {
    if (date) {
      const days = this.utils.daysPast(date);

      if (days < 15) {
        return true;
      } else {
        return false;
      }
    }

    return false;
  }



  loadMore() {
    this.section = this.section + 1;
    this.setup();
  }

  closeModal() {
    this.close.emit(true);
  }

  convertMessage(message) {
    if (message) {
      let result;
      result = this.utils.convertMessage(message, this.renderValue);
      return result;
    }

    return message;
  }


  EditSocialMedia(t) {
    if (t && t.ID) {
      this.router.navigate([
        "/merchant",
        {
          outlets: {
            page: ["template-social-media-edit", t.ID]
          }
        }
      ]);
    }
  }

  ngOnDestroy() {
    if (this.serviceRef) {
      this.serviceRef.unsubscribe();
    }
  }

  quickView(id) {
    if (id) {
      const ref2 = RootAppComponent.dialog.open(DocumentLinkViewComponent, {
        data: {
          linkID: id,
          displayClone: true
        },
        width: '950px',
      });



      ref2.componentInstance.clone.subscribe(doc => {
        if (doc && doc.ID) {

          ref2.close();
          ref2.afterClosed().subscribe(r => {
            this.clone(doc);
          })

        }
      })

      ref2.componentInstance.close.subscribe((res) => {
        ref2.close();
      });

    }
  }
  clone(document) {
    if (document && document.ID) {


      let m = this.merchantID || 'null'

      this.router.navigate([
        "/merchant",
        {
          outlets: {
            page: ["landing-page-clone", document.ID, m, 'true']
          }
        }
      ]);


    }
  }

  edit(t) {
    if (t) {


      let m = this.merchantID || 'null'

      this.router.navigate([
        "/merchant",
        {
          outlets: {
            page: ["landing-page-edit", t, m, 'true']
          }
        }
      ]);



    }
  }
  openLandingPage(record) {


    if (record && record.ID) {

      let link = `${Settings.global['publicSiteFrontendLink']}/landing-page-overview/${record.ID}`;

      if (record['IsDefault'] === '1') {
        link = `${Settings.global['publicSiteFrontendLink']}/landing-page-overview/${record.ID}?m=${this.merchantID}&c=${this.currentPractice.ID}`;
      }

      const ref2 = RootAppComponent.dialog.open(IframeViewComponent, {
        data: {
          link,
          extraDetails: record.IsDefault != '1' || this.isAdminOrPromoter == true
        },
        width: '80%',
        height: '92vh',
        panelClass: 'noCard',
      });

      ref2.componentInstance.close.subscribe((res) => {
        ref2.close();
      });

    }
  }


  getSocialMediaImage(t) {

    let img;
    if (t && t.ID) {

      if (t['Header_Image'] === 'merchant') {

        img = environment.nodeUrl + '/merchant/practice-picture-stream/' + this.currentPractice.ID;
      }
      else if (t['Header_Image']) {

        img = t['Header_Image'];
      }
      else {
        img = environment.nodeUrl + '/merchant/practice-picture-stream/' + this.currentPractice.ID;
      }
    }

    return img;
  }

  view(d) {

    if (d && d.ID) {

      if (this.isModal == true) {


        if (this.viewOnly == true) {

          this.openLandingPage(d)
        }
        else {
          this.insertLandingPage(d)
        }

      }
      else {
        this.router.navigate([
          '/merchant',
          {
            outlets: {
              page: ['landing-page-overview', d.ID],
            },
          },
        ]);
      }


    }
  }

  remove(id) {
    if (id) {
      const confirm = new ConfirmDialog(
        'fas fa-info',
        '',
        'Are you sure you want to remove this landing page ?',
        'No',
        'Yes'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation === false) {
          ref.close();
        } else {
          this.landingPageService.deleteDocumentLink(id, this.sessionType).subscribe((res) => {
            if (res) {
              this.removeElement(id)

              NotifyAppComponent.displayToast(
                'success',
                'Successful operation',
                'The Landing Page has been successfully removed'
              );

              ref.close();



            }

          });
        }
      });
    }
  }

  enable(ID) {
    const confirmDialog = new ConfirmDialog(
      'check_circle',
      'Enable Landing Page',
      '<p>Once the Landing Page is Active it will  appear as an option for the user.</p>',
      'Cancel',
      'Okay'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
      // SET TO PAYING CASH CARD
    });
    ref.componentInstance.onConfirm.subscribe((val) => {
      if (val == true) {
        ref.close();
        ref.afterClosed().subscribe((res) => {
          const payload = {
            isActive: true,
          };
          this.landingPageService.modifyDocumentLink(ID, payload, this.sessionType).subscribe((res) => {
            if (res) {
              this.replaceElement(res)


              NotifyAppComponent.displayToast('success', 'Success!', 'Landing Page is Active.');
            }
          });
        });
      } else {
        ref.close();
      }
    });
  }

  disable(ID) {
    const confirmDialog = new ConfirmDialog(
      'cancel',
      'Disable Landing Page',
      '<p>Once the Landing Page is not Active it will not appear as an option for the user.</p>',
      'Cancel',
      'Okay'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
      // SET TO PAYING CASH CARD
    });
    ref.componentInstance.onConfirm.subscribe((val) => {
      if (val == true) {
        ref.close();
        ref.afterClosed().subscribe((res) => {
          const payload = {
            isActive: false,
          };
          this.landingPageService.modifyDocumentLink(ID, payload, this.sessionType).subscribe((res) => {
            if (res) {

              this.replaceElement(res)
              NotifyAppComponent.displayToast('success', 'Success!', 'Landing Page is not Active.');
            }
          });
        });
      } else {
        ref.close();
      }
    });
  }

  replaceElement(record) {

    if (record && record.ID && this.landingPages && this.landingPages.length > 0) {

      record = this.buildRecord(record)

      let index = this.landingPages.findIndex(item => {
        if (item && item.ID && record && record.ID && record.ID == item.ID) {
          return true;
        }
        return false;
      })

      if (index !== -1) {
        this.landingPages[index] = record;
        this.landingPages = JSON.parse(JSON.stringify(this.landingPages))
      }
    }

  }

  buildRecord(_record) {


    let record = _record;
    record['fullName'] = '';
    if (record && record['Label']) {
      record['fullName'] = record['fullName'] + ',' + record['Label'];
    }
    if (record && record['Description']) {
      record['fullName'] = record['fullName'] + ',' + record['Description'];
    }
    if (record && record['Title']) {
      record['fullName'] = record['fullName'] + ',' + record['Title'];
    }

    if (record && record['ID']) {
      record['fullName'] = record['fullName'] + ',' + record['ID'];
    }
    if (record && record['Treatment_Amount']) {
      record['fullName'] = record['fullName'] + ',' + record['Treatment_Amount'];
    }
    if (record && record['Message']) {
      record['fullName'] = record['fullName'] + ',' + record['Message'];
    }
    if (record && record['Theme']) {
      record['fullName'] = record['fullName'] + ',' + record['Theme'];
    }
    if (record && record['Merchant_Trading_As']) {
      record['fullName'] = record['fullName'] + ',' + record['Merchant_Trading_As'];
    }
    if (record && record['Type.Label']) {
      record['fullName'] = record['fullName'] + ',' + record['Type.Label'];
    }
    if (record && record['Type.Code']) {
      record['fullName'] = record['fullName'] + ',' + record['Type.Code'];
    }
    if (record && record['Contact_CalculatedName']) {
      record['fullName'] = record['fullName'] + ',' + record['Contact_CalculatedName'];
    }
    if (record && record['ID']) {
      record['fullName'] = record['fullName'] + ',' + record['ID'];
    }

    if (record && record['DocumentFKs']) {
      record['DocumentFKs'] = record['DocumentFKs'].split('|');
    } else {
      record['DocumentFKs'] = [];
    }

    if (record && record['Tags']) {
      record['Tags'] = record['Tags'].split('|');
      record['Tags'] = _.uniq(record['Tags']);
      this.merchantTags = this.merchantTags.concat(record['Tags']);
    } else {
      record['Tags'] = [];
      this.merchantTags = this.merchantTags.concat(record['Tags']);
    }
    record['isNew'] = this.isNew(record['DateTimeCreated']);

    return record;
  }
  addElement(record) {

    if (record && record.ID) {


      record = this.buildRecord(record)

      this.landingPages.push(record)
      this.landingPages = JSON.parse(JSON.stringify(this.landingPages))

    }

  }

  removeElement(id) {
    if (id && this.landingPages && this.landingPages.length > 0) {
      let index = this.landingPages.findIndex(item => {
        if (item && item.ID && id && id == item.ID) {
          return true;
        }
        return false;
      })

      if (index !== -1) {
        this.landingPages.splice(index, 1)
        this.landingPages = JSON.parse(JSON.stringify(this.landingPages))
      }
    }

  }



  share(t) {

    if (t && t.ID) {

      if (t.IsActive === '1') {


        const ref2 = RootAppComponent.dialog.open(DocumentLinkShareComponent, {
          data: {
            landingPage: t,
            dentists: this.dentists,
            financedProductGroup: this.financedProductGroup
          },

          width: '80vw',
          panelClass: 'max-width-panel-1400'
        });



        ref2.componentInstance.close.subscribe((res) => {
          ref2.close();
        });
      }
      else {

        const confirmDialog = new ConfirmDialog(
          'check_circle',
          'Enable Landing Page',
          '<p>This Landing Page is <strong></strong>, Do you wish to Active it before proceed?</p>',
          'Cancel',
          'Okay'
        );

        const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
          data: confirmDialog,
          // SET TO PAYING CASH CARD
        });
        ref.componentInstance.onConfirm.subscribe((val) => {
          if (val == true) {
            ref.close();
            ref.afterClosed().subscribe((res) => {
              const payload = {
                isActive: true,
              };
              this.landingPageService.modifyDocumentLink(t.ID, payload, this.sessionType).subscribe((res) => {
                if (res) {

                  this.replaceElement(res)


                  NotifyAppComponent.displayToast('success', 'Success!', 'Landing Page is Active.');


                  const ref2 = RootAppComponent.dialog.open(DocumentLinkShareComponent, {
                    data: {
                      landingPage: res
                    },

                    width: '80vw',
                    panelClass: 'max-width-panel-1400'
                  });



                  ref2.componentInstance.close.subscribe((res) => {
                    ref2.close();
                  });

                }
              });
            });
          } else {
            ref.close();
          }
        });

      }
    }
  }

  newLandingPage() {

    const refLandingPageNewModalComponent = RootAppComponent.dialog.open(LandingPageNewModalComponent, {
      data: {
      },
      width: '650px',
    });

    refLandingPageNewModalComponent.componentInstance.getType.subscribe((type) => {
      refLandingPageNewModalComponent.close();

      refLandingPageNewModalComponent.afterClosed().subscribe(r => {



        let m = this.merchantID || 'null'

        this.router.navigate([
          "/merchant",
          {
            outlets: {
              page: ["landing-page-create", type, m, 'true']
            }
          }
        ]);



      })
    });

    refLandingPageNewModalComponent.componentInstance.close.subscribe((res) => {
      refLandingPageNewModalComponent.close();
    });
  }


  insertLandingPage(d) {
    if (d && d.ID) {

      if (this.cloneDefault === true && d && d.IsDefault === '1') {


        let p = {


          isPublic: this.isClonedPublic,
          isDefault: this.isClonedDefault,
          isStored: this.isClonedStored
        }

        this.landingPageService.cloneDocumentLink(d.ID, p, this.sessionType).subscribe(doc => {
          if (doc && doc.ID) {




            let link = this.landingPageService.getLinkByVisitorType(doc.ID, this.visitorsType);

            this.getLandingPage.emit(doc);
            this.getLandingPageID.emit(doc.ID);

            this.getLandingPageURL.emit(link);


          }
        })


      }
      else {
        let link = this.landingPageService.getLinkByVisitorType(d.ID, this.visitorsType);

        this.getLandingPage.emit(d);
        this.getLandingPageID.emit(d.ID);

        this.getLandingPageURL.emit(link);

      }


      this.getSelectedTemplate.emit(d)
      this.getSelectedTemplateID.emit(d.ID)
    }
  }
}
