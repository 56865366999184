<!-- grey button if no activity-log -->
<button *ngIf="!actionLogs" mat-icon-button matTooltip="No new activity" disabled>
  <mat-icon class="fa fa-bell"></mat-icon>
</button>

<!-- red button if new activity-log -->
<button
  *ngIf="actionLogs"
  class="bell-top-header"
  [matMenuTriggerFor]="activityMenu"
  (click)="loadData()"
  mat-icon-button
  color="accent"
  matTooltip="View new activity"
>
  <!-- <mat-icon class="fa fa-bell mat-accent"  matBadge="{{numOfTasks}}" matBadgeOverlap="false" matBadgeColor="accent"></mat-icon> -->

  <mat-icon class="fa fa-bell bell-top-header" *ngIf="actionLogs && actionLogs.length == 0"></mat-icon>
  <mat-icon
    class="fa fa-bell mat-accent "
    *ngIf="actionLogs && actionLogs.length > 0"
    matBadge="{{ numOfTasks }}"
    matBadgeOverlap="false"
    matBadgeColor="accent"
  ></mat-icon>
</button>
<mat-menu class="action-log-menu mat-elevation-z7" #activityMenu="matMenu" backdropClass="action-log-menu">
  <!-- <div class="sticky action-menu-heading"> -->

  <div class="row clearfix" (click)="$event.stopPropagation()">
    <h3 class="sr-title pull-left action-menu-title">Recent Activities</h3>

    <div class="pull-right title-menu">
      <button [matMenuTriggerFor]="actionMenu" (click)="$event.stopPropagation()" mat-icon-button>
        <mat-icon>more_vert</mat-icon>
      </button>

      <mat-menu #actionMenu="matMenu">
        <button class="btn-sml" (click)="markAllViewed()" mat-menu-item>
          <mat-icon class="fas fa-eye"></mat-icon>
          Mark all as read
        </button>
        <button
          class="btn-sml"
          *ngIf="isShowAllMessage == false"
          (click)="showAllMessages(); $event.stopPropagation()"
          mat-menu-item
        >
          <mat-icon class="fas fa-inbox"></mat-icon>
          Display All messages
        </button>
        <button
          class="btn-sml"
          *ngIf="isShowAllMessage == true"
          (click)="showUnreadMessage(); $event.stopPropagation()"
          mat-menu-item
        >
          <mat-icon class="fas fa-envelope"></mat-icon>
          Display Unread messages
        </button>
        <button class="btn-sml" (click)="clickLoadMore(); $event.stopPropagation()" mat-menu-item>
          <mat-icon class="fas fa-redo-alt"></mat-icon>
          Load More
        </button>
      </mat-menu>
    </div>
    <hr/>
  </div>

  <!-- </div> -->

  <div
    class="img action-menu-buttons"
    *ngFor="let c of actionLogs; let i = index"
    [ngStyle]="{ color: actionLogUtil.getSubjectColor(c['Action'], c['Subject']) }"
    [class.grey]="c['isViewed'] == 'true'"
    mat-menu-item
    role="menuitemcheckbox"
  >
    <mat-icon
      class="primary-icon fa"
      [ngStyle]="{ color: actionLogUtil.getSubjectColor(c['Action'], c['Subject']) }"
      [ngClass]="actionLogUtil.getSubjectIcon(c['Action'], c['Subject'])"
      (click)="showInvitationDetails(c)"
    ></mat-icon>

    <span
      class="action-details"
      [ngStyle]="{ color: actionLogUtil.getSubjectColor(c['Action'], c['Subject']) }"
      (click)="showInvitationDetails(c)"
    >
      <strong>{{ c['Who.Name'] }}</strong> - {{ actionLogUtil.changeSubject(c['Subject']) }}

      <span class="dateTime"> {{ dateFilter(c['DateTimeCreated']) }}, {{ timeFilter(c['DateTimeCreated']) }} </span>
      <span class="dateTime" *ngIf="!c['Note_Value']">
        {{ c['Description'] | customCurrency }}
      </span>
      <span class="dateTime" *ngIf="c['Note_Value']">
        {{ c['Note_Value'] | customCurrency }}
      </span>
      <span class="dateTime" *ngIf="!c['Description'] && !c['Note_Value']"> &nbsp; </span>
      <span class="dateTime"> {{ actionLogUtil.changeText(c['Subject']) }} By {{ c['Operator_Name'] }} </span>
    </span>

    <span class="iconBtn">
      <button
        *ngIf="c['isViewed'] == 'false'"
        (click)="markOneViewed(c['ID']); $event.stopPropagation()"
        mat-icon-button
        matTooltip="Mark as read"
      >
        <mat-icon class="fas fa-eye"></mat-icon>
      </button>
      <button
        *ngIf="c['isViewed'] == 'true'"
        (click)="markAsNotViewed(c['ID']); $event.stopPropagation()"
        mat-icon-button
        matTooltip="Mark as unread"
      >
        <mat-icon class="fas fa-eye-slash"></mat-icon>
      </button>

      <button
        *ngIf="c['isFlagged'] == false"
        (click)="addFlag(c['ID']); $event.stopPropagation()"
        mat-icon-button
        matTooltip="Not Starred"
      >
        <mat-icon>star_outline</mat-icon>
      </button>
      <button
        class="isStarred"
        *ngIf="c['isFlagged'] == true"
        (click)="removeFlag(c['ID']); $event.stopPropagation()"
        mat-icon-button
        matTooltip="Starred"
      >
        <mat-icon>star</mat-icon>
      </button>

      <button
        *ngIf="c['isArchived'] == 'false'"
        (click)="archived(c['ID']); $event.stopPropagation()"
        mat-icon-button
        matTooltip="Remove notification"
      >
        <mat-icon>close</mat-icon>
      </button>
    </span>
  </div>

  <div class="row clearfix text-center load-btn" (click)="$event.stopPropagation()">
    <button class="btn-sml" (click)="clickLoadMore(); $event.stopPropagation()" mat-flat-button>
      <mat-icon class="fas fa-redo-alt"></mat-icon>
      Load More
    </button>
  </div>
</mat-menu>
