/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./customer-prospect-tag-view.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../customer-prospect-list/customer-prospect-list.component.ngfactory";
import * as i3 from "../customer-prospect-list/customer-prospect-list.component";
import * as i4 from "../../../core/authentication/shared/authentication.service";
import * as i5 from "@angular/material/dialog";
import * as i6 from "@angular/router";
import * as i7 from "../../../shared/pipes/custom-date.pipe";
import * as i8 from "../shared/customerProspect.service";
import * as i9 from "../../customer/shared/customer.service";
import * as i10 from "../../existing-patient/shared/existing-patient.service";
import * as i11 from "../../marketing/shared/marketing.service";
import * as i12 from "../../product/shared/product.service";
import * as i13 from "../../../shared/services/utils.service";
import * as i14 from "../../../shared/pipes/custom-phone.pipe";
import * as i15 from "@ngx-translate/core";
import * as i16 from "../customer-prospect-list-global/customer-prospect-list-global.component.ngfactory";
import * as i17 from "../customer-prospect-list-global/customer-prospect-list-global.component";
import * as i18 from "@angular/common";
import * as i19 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i20 from "@angular/material/button";
import * as i21 from "@angular/cdk/a11y";
import * as i22 from "@angular/platform-browser/animations";
import * as i23 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i24 from "@angular/material/icon";
import * as i25 from "./customer-prospect-tag-view.component";
import * as i26 from "../../tags/shared/tags.service";
var styles_CustomerProspectTagViewComponent = [i0.styles];
var RenderType_CustomerProspectTagViewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CustomerProspectTagViewComponent, data: {} });
export { RenderType_CustomerProspectTagViewComponent as RenderType_CustomerProspectTagViewComponent };
function View_CustomerProspectTagViewComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-customer-prospect-list", [], null, null, null, i2.View_CustomerProspectListComponent_0, i2.RenderType_CustomerProspectListComponent)), i1.ɵdid(1, 770048, null, 0, i3.CustomerProspectListComponent, [i4.AuthenticationService, i5.MatDialog, i6.Router, i7.CustomDatePipe, i8.CustomerProspectService, i9.CustomerService, i10.ExistingPatientService, i11.MarketingService, i12.ProductService, i13.UtilsService, i14.CustomPhonePipe], { title: [0, "title"], IDs: [1, "IDs"] }, null), i1.ɵpid(131072, i15.TranslatePipe, [i15.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(3, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((i1.ɵunv(_v, 1, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent, 0), i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("KEYWORD.patient")))) + "s") + " under the tag ") + _co.tag.Label); var currVal_1 = _co.tag.Cards_key; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_CustomerProspectTagViewComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-customer-prospect-list-global", [], null, null, null, i16.View_CustomerProspectListGlobalComponent_0, i16.RenderType_CustomerProspectListGlobalComponent)), i1.ɵdid(1, 770048, null, 0, i17.CustomerProspectListGlobalComponent, [i13.UtilsService, i4.AuthenticationService, i5.MatDialog, i6.Router, i7.CustomDatePipe, i8.CustomerProspectService, i9.CustomerService, i10.ExistingPatientService, i11.MarketingService, i12.ProductService, i14.CustomPhonePipe], { title: [0, "title"], IDs: [1, "IDs"], merchantID: [2, "merchantID"] }, null), i1.ɵpid(131072, i15.TranslatePipe, [i15.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(3, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((i1.ɵunv(_v, 1, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent, 0), i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("KEYWORD.patient")))) + "s") + " under the Tag ") + _co.tag.Label); var currVal_1 = _co.tag.Cards_key; var currVal_2 = _co.tag.Merchant_key; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_CustomerProspectTagViewComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "full-width"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomerProspectTagViewComponent_2)), i1.ɵdid(2, 16384, null, 0, i18.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomerProspectTagViewComponent_3)), i1.ɵdid(4, 16384, null, 0, i18.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isPromoterOrAdmin != true); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.isPromoterOrAdmin == true); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_CustomerProspectTagViewComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i18.TitleCasePipe, []), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomerProspectTagViewComponent_1)), i1.ɵdid(2, 16384, null, 0, i18.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 11, "div", [["class", "full-width mt"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 10, "button", [["class", "clearfix pull-left"], ["color", "primary"], ["mat-raised-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i19.View_MatButton_0, i19.RenderType_MatButton)), i1.ɵdid(5, 180224, null, 0, i20.MatButton, [i1.ElementRef, i21.FocusMonitor, [2, i22.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), i1.ɵdid(6, 16384, null, 0, i6.RouterLink, [i6.Router, i6.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(7, 1), i1.ɵpod(8, { page: 0 }), i1.ɵpod(9, { outlets: 0 }), i1.ɵpad(10, 2), (_l()(), i1.ɵeld(11, 0, null, 0, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i23.View_MatIcon_0, i23.RenderType_MatIcon)), i1.ɵdid(12, 9158656, null, 0, i24.MatIcon, [i1.ElementRef, i24.MatIconRegistry, [8, null], [2, i24.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["keyboard_arrow_left"])), (_l()(), i1.ɵted(-1, 0, [" Audiences "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.tag && _co.tag.Cards_key); _ck(_v, 2, 0, currVal_0); var currVal_3 = "primary"; _ck(_v, 5, 0, currVal_3); var currVal_4 = _ck(_v, 10, 0, "/merchant", _ck(_v, 9, 0, _ck(_v, 8, 0, _ck(_v, 7, 0, "audiences-settings")))); _ck(_v, 6, 0, currVal_4); _ck(_v, 12, 0); }, function (_ck, _v) { var currVal_1 = (i1.ɵnov(_v, 5).disabled || null); var currVal_2 = (i1.ɵnov(_v, 5)._animationMode === "NoopAnimations"); _ck(_v, 4, 0, currVal_1, currVal_2); var currVal_5 = i1.ɵnov(_v, 12).inline; var currVal_6 = (((i1.ɵnov(_v, 12).color !== "primary") && (i1.ɵnov(_v, 12).color !== "accent")) && (i1.ɵnov(_v, 12).color !== "warn")); _ck(_v, 11, 0, currVal_5, currVal_6); }); }
export function View_CustomerProspectTagViewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-customer-prospect-tag-view", [], null, null, null, View_CustomerProspectTagViewComponent_0, RenderType_CustomerProspectTagViewComponent)), i1.ɵdid(1, 114688, null, 0, i25.CustomerProspectTagViewComponent, [i6.ActivatedRoute, i4.AuthenticationService, i26.TagsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomerProspectTagViewComponentNgFactory = i1.ɵccf("app-customer-prospect-tag-view", i25.CustomerProspectTagViewComponent, View_CustomerProspectTagViewComponent_Host_0, { tagID: "tagID" }, {}, []);
export { CustomerProspectTagViewComponentNgFactory as CustomerProspectTagViewComponentNgFactory };
