import { Component, Input, OnInit } from '@angular/core';
import { TeethShape } from '../shared/teeth-shape';

@Component({
  selector: 'app-tooth-view',
  templateUrl: './tooth-view.component.html',
  styleUrls: ['./tooth-view.component.css'],
})
export class ToothViewComponent implements OnInit {
  @Input() tooth: any = {};
  @Input() isAdult: boolean;
  @Input() selected = true;
  toothShape: any;

  constructor() {}

  ngOnInit() {
    this.toothShape = TeethShape.getToothShape(this.tooth.toothNumber, this.isAdult);
  }

  getClass() {
    if (this.selected == true) {
      return 'selected';
    } else {
      return 'notSelected';
    }
  }
}
