import { Component, EventEmitter, Inject, Input, Optional, Output, TemplateRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, of } from 'rxjs';
import { map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { labelAnimation } from '../../../shared/animations/label.animation';
import { onChangeAnimation } from '../../../shared/animations/onChange.animation';
import { DataTablePrintModalComponent } from '../../../shared/components/data-table/shared/modals/data-table-print-modal/data-table-print-modal.component';
import { DataTablePrintModalConfig } from '../../../shared/components/data-table/shared/types/data-table.type';
import { CustomDatePipe } from '../../../shared/pipes/custom-date.pipe';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { Merchant } from '../../merchant/shared/types/merchant.type';
import { SettlementService } from '../shared/settlement.service';
import { Settlement } from '../shared/types/settlement.type';
import { ContractStatement, ScheduleOfSettlements } from './shared/types/settlement-contract-list.type';

export class ScheduleOfSettlementRecord {
  date_due: string;
  amount: number;
  description: string;
  status: '0' | '1' | '5' | null;
}

export class SettlementContractListModalConfig {
  contractID: string;
  statusID: string;
  isContractApproved?: '0' | '1';
}

class ContractStatementRecord {
  date: string;
  label: string;
  credits: number;
  debits: number;
  balance: number;
}

class StatusItem {
  id: string;
  icon: string;
  label: string;
  settlementStatus: string[] | string;
  paymentStatus: string[] | string;
  tableName?: string[] | string;
}

@Component({
  selector: 'app-settlement-contract-list',
  templateUrl: './settlement-contract-list.component.html',
  styleUrls: ['./settlement-contract-list.component.css'],
  animations: [labelAnimation, onChangeAnimation],
  providers: [CustomDatePipe],
})
export class SettlementContractListComponent {
  @Output()
  closeModal = new EventEmitter();

  @Output()
  notFound = new EventEmitter();

  @Input()
  contractID ;

  @Input()
  isContractApproved: '0' | '1' = '0';

  @Input()
  statusID ;

  @Input()
  startDate: string;

  @Input()
  endDate: string;

  @Input()
  contractName: string;

  @Input()
  settlements: Settlement[];
  @Input()
  isMiniSettlementExpanded=false
  currentEmailAddress: string;

  isPromoterOrAdmin = false;
  isModal = false;
  updated = true;

  statusArray = STATUS_ARRAY;

  status: StatusItem ;

  currentPractice: Partial<Merchant>;

  settlements$ = combineLatest([
    this.activatedRoute.params,
    this.authenticationService.isPromoterOrAdmin(),
    this.authenticationService.getCurrentUser(),
    this.authenticationService.getCurrentPractice(),
  ]).pipe(
    mergeMap(([params, isPromoterOrAdmin, currentUser, currentPractice]) => {
      this.contractID = params['contractID'] ? params['contractID'] : this.contractID;

      this.isPromoterOrAdmin = isPromoterOrAdmin;
      this.currentEmailAddress = currentUser.data['emails.Email'];
      this.currentPractice = currentPractice;

      this.setStatus(this.statusID);

      return combineLatest([this.initializeSettlements$(), this.initializeScheduleOfSettlementsResult$()]).pipe(
        mergeMap(() => {
          return this.initializeContractStatement();
        }),
        switchMap(() => {
          return this.settlementService.getSettlements$();
        })
      );
    })
  );

  contractStatement$ = this.settlementService.getContractStatement$().pipe(
    map((contractStatement: ContractStatement) => {
      if (contractStatement && contractStatement.Dates.length > 0) {
        return this.getContractStatementTable(contractStatement);
      } else {
        return [];
      }
    })
  );

  scheduleOfPayments$ = this.settlementService.getScheduleOfSettlements$().pipe(
    map((scheduleOfSettlementsResult: ScheduleOfSettlements) => {
      if (scheduleOfSettlementsResult && scheduleOfSettlementsResult.SettlementsSchedule.Date_Due.length > 0) {
        return this.getPaymentScheduleTable(scheduleOfSettlementsResult);
      } else {
        return [];
      }
    })
  );

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private settlementService: SettlementService,
    private authenticationService: AuthenticationService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: SettlementContractListModalConfig,
    private dialog: MatDialog
  ) {
    if (data) {
      if (data.contractID) {
        this.contractID = data.contractID;
        this.isModal = true;
      }

      this.isContractApproved = data.isContractApproved || '0';

      this.statusID = data.statusID || null;

      this.setStatus('all');
    }
  }

  initializeScheduleOfSettlementsResult$() {
    const payload = { Contract_key: this.contractID };

    return this.settlementService.fetchScheduleOfSettlements$(payload, this.isPromoterOrAdmin).pipe(
      tap((scheduleOfSettlementsResult) => {
        this.settlementService.setScheduleOfSettlements(scheduleOfSettlementsResult);
      })
    );
  }

  initializeSettlements$() {
    const query = { payload: { contractID: this.contractID } };

    return this.settlementService.fetchSettlements$(query.payload).pipe(
      map((settlements: Settlement[]) => {
        this.setIsContractApproved(settlements);
        const uniqueSettlements = this.getUniqueSettlements(settlements);

        if (uniqueSettlements && uniqueSettlements.length > 0) {
          this.notFound.emit(false);
          this.startDate = uniqueSettlements[0].DateTimeCreated.split(' ')[0];
        } else {
          this.notFound.emit(true);
        }

        if (!this.settlements) {
          this.settlementService.setSettlements(uniqueSettlements);
        }

        return uniqueSettlements;
      })
    );
  }

  initializeContractStatement() {
    if (this.startDate) {
      return this.settlementService
        .fetchContractStatement$(
          {
            Contract_key: this.contractID,
            StartDate: this.startDate,
          },
          this.isPromoterOrAdmin
        )
        .pipe(
          tap((contractStatement) => {
            this.settlementService.setContractStatement(contractStatement);
          })
        );
    } else {
      return of(null);
    }
  }

  getContractStatementTable(contractStatement: ContractStatement) {
    const table: ContractStatementRecord[] = [];

    let balance = contractStatement.OpeningBalance;
    let totalCredits = 0;
    let totalDebits = 0;

    const openingBalanceRecord: ContractStatementRecord = {
      date: '',
      label: 'Opening Balance',
      credits: 0,
      debits: 0,
      balance: contractStatement.OpeningBalance,
    };

    table.push(openingBalanceRecord);

    const recordsLength = contractStatement.Dates.length;

    for (let index = 0; index < recordsLength; index++) {
      balance = balance - contractStatement.Credits[index] + contractStatement.Debits[index];

      totalCredits = totalCredits + contractStatement.Credits[index];
      totalDebits = totalDebits + contractStatement.Debits[index];

      const record: ContractStatementRecord = {
        date: contractStatement.Dates[index],
        label: contractStatement.Memos[index],
        credits: contractStatement.Credits[index],
        debits: contractStatement.Debits[index],
        balance,
      };

      table.push(record);
    }

    const totalRecord: ContractStatementRecord = {
      date: 'Total',
      label: '',
      credits: totalCredits,
      debits: totalDebits,
      balance,
    };

    table.push(totalRecord);

    return table;
  }

  getPaymentScheduleTable(scheduleOfSettlementsResult: ScheduleOfSettlements) {
    const settlementsSchedule = scheduleOfSettlementsResult.SettlementsSchedule;

    const table: ScheduleOfSettlementRecord[] = [];

    const recordsLength = settlementsSchedule.Date_Due.length;

    let totalAmount = 0;

    for (let index = 0; index < recordsLength; index++) {
      if (settlementsSchedule.Status[index] !== '5') {
        const invertedAmount = settlementsSchedule.Amount[index] * -1;
        totalAmount = totalAmount + invertedAmount;

        const record: ScheduleOfSettlementRecord = {
          date_due: settlementsSchedule.Date_Due[index],
          amount: settlementsSchedule.Amount[index],
          description: settlementsSchedule.Description[index],
          status: settlementsSchedule.Status[index],
        };

        table.push(record);
      }
    }

    if (table.length > 0) {
      const totalRecord: ScheduleOfSettlementRecord = {
        date_due: 'total',
        amount: totalAmount,
        description: '',
        status: null,
      };

      table.push(totalRecord);
    }

    return table;
  }

  isScheduleAmountTotal(scheduleOfSettlementRecords: ScheduleOfSettlementRecord[], amount: number) {
    const amounts: number[] = scheduleOfSettlementRecords.map((record) => record.amount);
    const amountIndex = amounts.findIndex((targetAmount) => targetAmount === amount);

    return amountIndex === amounts.length - 1;
  }

  setStatus(statusID: string) {
    this.status = (() => {
      switch (statusID) {
        case 'all':
          return STATUS_OPTIONS.ALL;

        case 'pending':
          return STATUS_OPTIONS.PENDING;

        case 'declined':
          return STATUS_OPTIONS.DECLINED;

        case 'cancelled':
          return STATUS_OPTIONS.CANCELLED;

        case 'paid':
          return STATUS_OPTIONS.PAID;

        case 'notPaid':
          return STATUS_OPTIONS.NOT_PAID;

        default:
          return STATUS_OPTIONS.ALL;
      }
    })();
  }

  sendStatementEmail() {
    this.settlementService
      .fetchContractStatement$(
        {
          Contract_key: this.contractID,
          StartDate: this.startDate,
          'Email.Address': this.currentEmailAddress,
          'Email.Send': '1',
        },
        this.isPromoterOrAdmin
      )
      .subscribe(() => {
        NotifyAppComponent.displayToast(
          'success',
          'Successful Operation',
          `Statement email sent to ${this.currentEmailAddress}`
        );
      });
  }

  sendScheduleEmail() {
    this.settlementService
      .fetchScheduleOfSettlements$(
        {
          Contract_key: this.contractID,
          'Email.Address': this.currentEmailAddress,
          'Email.Send': '1',
        },
        this.isPromoterOrAdmin
      )
      .subscribe(() => {
        NotifyAppComponent.displayToast(
          'success',
          'Successful Operation',
          `Schedule email sent to ${this.currentEmailAddress}`
        );
      });
  }

  openTablePrint(
    contractStatementRecords: ContractStatementRecord[],
    title: string,
    customComponents?: { [key: string]: TemplateRef<any> },
    formatHeaders?: { [key: string]: 'left' | 'right' | 'center' },
    rename?: { [key: string]: string }
  ) {
    this.dialog.open<DataTablePrintModalComponent, DataTablePrintModalConfig>(DataTablePrintModalComponent, {
      data: {
        table: contractStatementRecords,
        customComponents,
        title,
        formatHeaders,
        rename,
        practiceOrMerchant: this.currentPractice,
      },
      width: '700px',
    });
  }

  setIsContractApproved(settlements: Settlement[]) {
    if (settlements.length > 0) {
      const approvedSettlements = settlements.filter((settlement) => settlement['Approved.Date'] !== '0000-00-00');

      if (approvedSettlements.length > 0) {
        this.isContractApproved = '1';

        if (!this.contractName) {
          this.contractName = approvedSettlements[0]['Customer.CalculatedName'];
        }
      } else {
        this.isContractApproved = '0';
      }
    }
  }

  getUniqueSettlements(settlements) {
    const uniqueSettlements: Settlement[] = [];

    settlements.forEach((settlement) => {
      if (settlement.DrawDownGroupID) {
        const foundDrawDownGroupID =
          uniqueSettlements.findIndex((uniqueSettlement) => {
            if (uniqueSettlement.DrawDownGroupID) {
              return uniqueSettlement.DrawDownGroupID === settlement.DrawDownGroupID;
            } else {
              return -1;
            }
          }) !== -1;

        if (!foundDrawDownGroupID) {
          uniqueSettlements.push(settlement);
        }
      } else {
        uniqueSettlements.push(settlement);
      }
    });

    return uniqueSettlements;
  }

  settlementsChanged(newSettlements, settlements: Settlement[]) {
    this.updated = false;
    UtilsClass.startLoadingForce();
    setTimeout(() => {
      if (newSettlements && newSettlements.length > 0) {
        for (let j = 0; j < newSettlements.length; j++) {
          for (let i = 0; i < settlements.length; i++) {
            if (settlements[i] && newSettlements[j] && settlements[i]['ID'] === newSettlements[j]['ID']) {
              settlements[i] = newSettlements[j];
            }
          }
        }
      }

      this.updated = true;
      UtilsClass.stopLoadingForce();
    }, 1000);
  }

  closeClicked() {
    this.closeModal.emit(true);
  }
}

type STATUS_OPTION_TYPES = {
  [K in 'ALL' | 'PENDING' | 'DECLINED' | 'CANCELLED' | 'PAID' | 'NOT_PAID']: StatusItem;
};

const INITIAL_STATUS: StatusItem = {
  id: 'all',
  icon: 'fa-comment-dollar',
  label: 'All requests',
  settlementStatus: '*',
  paymentStatus: '*',
  tableName: '*',
};

const STATUS_ARRAY: StatusItem[] = [
  {
    id: 'all',
    icon: 'fa-comment-dollar',
    label: 'All requests',
    settlementStatus: '*',
    paymentStatus: '*',
    tableName: '*',
  },
  {
    id: 'pending',
    icon: 'fa-hourglass-half',
    label: 'Requested/Pending',
    settlementStatus: ['PEND', 'REQU'],
    paymentStatus: '*',
    tableName: ['Merchant', 'Supplier'],
  },

  {
    id: 'declined',
    icon: 'fa-ban',
    label: 'Declined',
    settlementStatus: ['DECL'],
    paymentStatus: '*',
    tableName: ['Merchant', 'Supplier'],
  },
  {
    id: 'cancelled',
    icon: 'fa-hand-paper',
    label: 'Withdrawn',
    settlementStatus: ['WDRN'],
    paymentStatus: '*',
    tableName: ['Merchant', 'Supplier'],
  },

  {
    id: 'paid',
    icon: 'fa-thumbs-up',
    label: 'Paid to practice',
    settlementStatus: ['APPR'],
    paymentStatus: ['Paid'],
    tableName: ['Merchant', 'Supplier'],
  },

  {
    id: 'notPaid',
    icon: 'fa-thumbs-down',
    label: 'Not paid',
    settlementStatus: ['APPR'],
    paymentStatus: ['Pending', 'Suspended', 'Cancelled', 'Blocked'],
    tableName: ['Merchant', 'Supplier'],
  },

  {
    id: 'refund',
    icon: 'fa-undo',
    label: 'Refund',
    settlementStatus: '*',
    paymentStatus: '*',
    tableName: ['Customer'],
  },
];

const STATUS_OPTIONS: STATUS_OPTION_TYPES = {
  ALL: {
    id: 'all',
    icon: 'fa-comment-dollar',
    label: 'All requests',
    settlementStatus: '*',
    paymentStatus: '*',
  },

  PENDING: {
    id: 'pending',
    icon: 'fa-hourglass-half',
    label: 'Requested/Pending',
    settlementStatus: ['PEND', 'REQU'],
    paymentStatus: '*',
  },

  DECLINED: {
    id: 'declined',
    icon: 'fa-ban',
    label: 'Declined',
    settlementStatus: ['DECL'],
    paymentStatus: '*',
  },

  CANCELLED: {
    id: 'cancelled',
    icon: 'fa-hand-paper',
    label: 'Withdrawn',
    settlementStatus: ['WDRN'],
    paymentStatus: '*',
  },

  PAID: {
    id: 'paid',
    icon: 'fa-thumbs-up',
    label: 'Paid to practice',
    settlementStatus: ['APPR'],
    paymentStatus: ['Paid'],
  },

  NOT_PAID: {
    id: 'notPaid',
    icon: 'fa-thumbs-down',
    label: 'Not paid',
    settlementStatus: ['APPR'],
    paymentStatus: ['Pending', 'Suspended', 'Cancelled', 'Blocked'],
  },
};
