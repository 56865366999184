import { Component, EventEmitter, Inject, Input, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { CustomerProspectService } from '../../customer-prospect/shared/customerProspect.service';
import { InvitationService } from '../../invitation/shared/services/invitation.service';
import { ProductService } from '../../product/shared/product.service';

@Component({
  selector: 'app-patient-treatment-view',
  templateUrl: './patient-treatment-view.component.html',
  styleUrls: ['./patient-treatment-view.component.css'],
})
export class PatientTreatmentViewComponent implements OnInit {
  @Input()
  invitationID;

  close = new EventEmitter();

  patient;
  customInv;
  minAmount;

  isModal = false;

  profileLink;
  sessionType = 'guest';

  constructor(
    private authenticationService: AuthenticationService,
    private invitationService: InvitationService,
    private productService: ProductService,
    private customerProspectService: CustomerProspectService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.invitationID) {
        this.invitationID = data.invitationID;
      }

      this.isModal = true;
    }
  }

  ngOnInit() {
    if (this.invitationID) {
      this.authenticationService.getSessionType().subscribe((sessionType) => {
        if (sessionType) {
          this.sessionType = sessionType;
        }

        this.invitationService.getInvitationDetails(this.invitationID, {}, this.sessionType, false).subscribe((inv) => {
          if (inv) {
            this.customInv = inv;

            this.getPicture();
            if (
              this.customInv &&
              this.customInv['Product.ThisGroupOnly.Code'] &&
              this.customInv['Merchant_Key'] &&
              this.customInv['treatment.Value']
            ) {
              const payload = {
                merchantKey: this.customInv['Merchant_Key'],
                amount: this.customInv['treatment.Value'],
                groupCode: this.customInv['Product.ThisGroupOnly.Code'],
                fields: 'Min(BasedOnMaxDuration.Repayments.Weekly)',
              };

              this.productService.getSuitableProductMinValue(payload).subscribe((res) => {
                if (res) {
                  this.minAmount = Number(Number(res['Min(BasedOnMaxDuration.Repayments.Weekly)']) / 7);
                }
              });
            }
          }
        });
      });
    }
  }

  closeModal() {
    this.close.emit(true);
  }

  getPicture() {
    this.profileLink = null;
    if (this.customInv['Prospect_key']) {
      this.profileLink = this.customerProspectService.getProfilePicStreamLink(this.customInv['Prospect_key']);
    }
  }
}
