<div class="rel" *ngIf="listDB.firstLoadEvent == false">
  <div class="row clearfix padded rel">
    <h1 class="dataHeader rm-mt transparent">Fetching data...</h1>
  </div>
</div>
<div [hidden]="listDB.data.length == 0">
  <div>
    <div class="head-content clearfix">
      <button class="pull-right mr" *ngIf="canCreate == true" (click)="create()" mat-raised-button color="accent">
        Create New
      </button>

      <div class="pull-right selection-items" @ngIfAnimation>
        <button
          class="animated mr"
          (click)="selectAll()"
          mat-stroked-button
          color=""
          matTooltip="Select All"
        >
          <mat-icon>border_all</mat-icon>
        </button>
        <button
          class="animated mr"
          (click)="selectPage()"
          mat-stroked-button
          color=""
          matTooltip="Select Page"
        >
          <mat-icon>border_outer</mat-icon>
        </button>

        <button
          class="animated mr"
          *ngIf="selectedIDs && selectedIDs.length > 0"
          [matMenuTriggerFor]="ActionBtnmenu"
          mat-stroked-button
          matTooltip="Options"
        >
          <mat-icon class="fas fa-cog"></mat-icon>  <span *ngIf="selectedIDs && selectedIDs.length>0"
            class="selected-number-item  accent-background">{{selectedIDs.length}}</span>
        </button>
        <mat-menu #ActionBtnmenu="matMenu">
          <button class="row select-btn" (click)="unselect()" mat-menu-item color="">
            <mat-icon>select_all</mat-icon> Deselect
          </button>

          <hr/>

          <button
            class="row select-btn"
            *ngIf="selectedIDs && selectedIDs.length > 0"
            (click)="enableGroup()"
            mat-menu-item
            color=""
          >
            <mat-icon>check_circle</mat-icon>
            Enable
          </button>

          <button
            class="row select-btn"
            *ngIf="selectedIDs && selectedIDs.length > 0"
            (click)="disableGroup()"
            mat-menu-item
            color=""
          >
            <mat-icon>cancel</mat-icon>
            Disable
          </button>
          <hr/>

          <button
            class="row select-btn"
            *ngIf="selectedIDs && selectedIDs.length > 0"
            (click)="removeGroup()"
            mat-menu-item
            color=""
          >
            <mat-icon>delete</mat-icon>
            Remove
          </button>
        </mat-menu>
      </div>

      <h2 class="dataHeader rm-m pull-left">
        {{ title }}
        <span class="badge" [endVal]="dataSource?.filteredData?.length" countUp></span>
      </h2>
    </div>
    <mat-card class="list-card no-box-card">
      <!-- Search -->
      <div class="row clearfix mb searchArea primary-gradient-img">
        <div class="row clearfix flex">
          <div class="full-width flex animated fadeInDown">
            <mat-form-field class="white full-width" appearance="outline">
              <mat-label>Label,Description...</mat-label>
              <input
                class="clearfix"
                (input)="setFilter($event.target.value, 'fullName')"
                name="Name"
                placeholder="Label,Description,Email,Address,Phone..."
                matInput
              />
            </mat-form-field>
          </div>

          <mat-form-field class="white half-width" appearance="outline">
            <mat-label>Status</mat-label>
            <mat-select
              class=""
              (selectionChange)="setFilter($event.value, 'Templates')"
              name="Status"
              placeholder="Status"
            >
              <mat-option [value]="null"> All</mat-option>
              <mat-option [value]="'1'"> Enabled</mat-option>
              <mat-option [value]="'0'"> Disabled</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="white half-width" appearance="outline">
            <mat-label>Nature</mat-label>
            <mat-select
              class=""
              (selectionChange)="setFilter($event.value, 'Nature.Code')"
              name="Status"
              placeholder="Status"
            >
              <mat-option [value]="null"> All</mat-option>
              <mat-option [value]="'Template'">
                <span class="component-component">Component</span>
              </mat-option>
              <mat-option [value]="'Style'">
                <span class="component-style">Style</span>
              </mat-option>
              <mat-option [value]="'PageTem'">
                <span class="component-page-template">Page Template</span>
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field
            class="white half-width"
            *ngIf="merchants && merchants.length > 1 && isDefault != true"
            appearance="outline"
          >
            <mat-label>{{ "KEYWORD.practice" | translate | titlecase }}s</mat-label>
            <mat-select
              class=""
              [placeholder]="('KEYWORD.practice' | translate | titlecase) + 's'"
              (selectionChange)="setFilter($event.value, 'Merchant_Key')"
              name="merchants"
            >
              <mat-option *ngFor="let a of merchants | orderBy" [value]="a.ID">
                {{ a.Label }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="white half-width" *ngIf="isDefault != true" appearance="outline">
            <mat-label>Access</mat-label>
            <mat-select
              class=""
              (selectionChange)="setFilter($event.value, 'Is_Default')"
              name="Access"
              placeholder="Access"
            >
              <mat-option [value]="null"> All</mat-option>
              <mat-option [value]="'1'"> Default</mat-option>
              <mat-option [value]="'0'"> User</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="row clearfix flex well miniWell animated fadeInDown" *ngIf="searchVals.value?.length > 0"></div>
      </div>
      <!-- Table -->
      <mat-table class="list-table" #table [dataSource]="dataSource" matSort>
        <!--- Note that these columns can be defined in any order.
                              The actual rendered columns are set as a property on the row definition" -->
        <ng-container cdkColumnDef="selectCheckBox" sticky>
          <mat-header-cell *cdkHeaderCellDef></mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <div class="margin-btm pull-left">
              <mat-checkbox
                [checked]="isSelected(row['ID'])"
                (change)="select(row['ID'], $event.checked)"
                color="accent"
              >
              </mat-checkbox>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Date">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Created At</mat-header-cell>
          <mat-cell
            *cdkCellDef="let row"
            (click)="view(row['ID'])"
            matTooltip="{{ row['DateTimeCreated'] | customDateTime }}"
          >
            {{ row.DateTimeCreated | TimeLabelPast }} ago
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Merchant">
          <mat-header-cell *cdkHeaderCellDef
                           mat-sort-header> {{ "KEYWORD.practice" | translate | titlecase}} </mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="view(row['ID'])" matTooltip="{{ row['Merchant_Trading_As'] }}">
            <span> {{ row['Merchant_Trading_As'] }}</span>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Label">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Label</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="view(row['ID'])" matTooltip="{{ row['Label'] }}">
            <h3>{{ row['Label'] }}</h3>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Nature">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Nature</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="view(row['ID'])">
            <h3 class="component-style" *ngIf="row['Nature.Code'] == 'Style'" matTooltip="Style">Style</h3>
            <h3 class="component-page-template" *ngIf="row['Nature.Code'] == 'PageTem'" matTooltip="Page Template">
              Page Template
            </h3>
            <h3 class="component-component" *ngIf="row['Nature.Code'] == 'Template'" matTooltip="Component">
              Component
            </h3>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Description">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Description</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="view(row['ID'])" matTooltip="{{ row['Description'] }}">
            <span> {{ row['Description'] }}</span>
          </mat-cell>
        </ng-container>
        <ng-container cdkColumnDef="Access">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Default</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="view(row['ID'])">
            <span
              class="list-label smaller"
              *ngIf="row['Is_Default'] == '1'"
              matTooltip="Default"
              style="background-color: rgb(90, 186, 71)"
            >
              <mat-icon>check</mat-icon>
            </span>
            <span
              class="list-label smaller"
              *ngIf="row['Is_Default'] != '1'"
              matTooltip="User"
              style="background-color: rgb(239, 71, 39)"
            >
              <mat-icon>close</mat-icon>
            </span>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="active">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Active</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="view(row['ID'])">
            <span
              class="list-label smaller"
              *ngIf="row['Is_Active'] == '1'"
              matTooltip="Active"
              style="background-color: rgb(90, 186, 71)"
            >
              <mat-icon>check</mat-icon>
            </span>
            <span
              class="list-label smaller"
              *ngIf="row['Is_Active'] != '1'"
              matTooltip="Inactive"
              style="background-color: rgb(239, 71, 39)"
            >
              <mat-icon>close</mat-icon>
            </span>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Actions">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Actions</mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <div style="float: right">
              <button [matMenuTriggerFor]="menu" mat-icon-button>
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button *ngIf="row['ID']" (click)="view(row['ID'])" mat-menu-item>
                  <mat-icon>visibility</mat-icon>
                  <span>View</span>
                </button>

                <button *ngIf="row['ID']" (click)="Edit(row['ID'])" mat-menu-item>
                  <mat-icon>edit</mat-icon>
                  <span>Edit</span>
                </button>

                <button *ngIf="row['ID']" (click)="clone(row['ID'])" mat-menu-item>
                  <mat-icon class="fas fa-clone"></mat-icon>
                  <span>Clone</span>
                </button>

                <hr *ngIf="row['Merchant_Key'] && isPromoterOrAdmin == true && !merchantID"/>

                <button
                  *ngIf="row['Merchant_Key'] && isPromoterOrAdmin == true && !merchantID"
                  (click)="viewMerchant(row['Merchant_Key'])"
                  mat-menu-item
                >
                  <mat-icon>person</mat-icon>
                  <span>View {{ "KEYWORD.practice" | translate | titlecase}}</span>
                </button>

                <hr/>

                <button *ngIf="row['Is_Active'] == '1'" (click)="disable(row['ID'])" mat-menu-item>
                  <mat-icon>cancel</mat-icon>
                  <span>Disable</span>
                </button>

                <button *ngIf="row['Is_Active'] != '1'" (click)="enable(row['ID'])" mat-menu-item>
                  <mat-icon>check_circle</mat-icon>
                  <span>Enable</span>
                </button>

                <hr/>

                <button *ngIf="row['ID']" (click)="delete(row['ID'])" mat-menu-item>
                  <mat-icon>delete</mat-icon>
                  <span>delete</span>
                </button>
              </mat-menu>
            </div>
          </mat-cell>
        </ng-container>
        <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *cdkRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
      <mat-paginator
        #paginator
        [length]="dataSource.filteredData.length"
        [pageIndex]="0"
        [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions"
      >
      </mat-paginator>
    </mat-card>
  </div>
</div>
<div class="clearfix full-width" *ngIf="listDB.data.length == 0 && listDB.firstLoadEvent != false">
  <app-empty-list-message
    *ngIf="canCreate == true"
    [message]="'There are no Component to display.'"
    [actionIcon]="'add'"
    [isAction]="true"
    [actionLabel]="'New Component'"
    (actionEvent)="create()"
  >
  </app-empty-list-message>
  <app-empty-list-message *ngIf="canCreate != true" [message]="'There are no Component to display.'" [isAction]="false">
  </app-empty-list-message>
</div>
