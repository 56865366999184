import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ArchwizardModule } from 'angular-archwizard';
import { NgDatepickerModule } from 'ng2-datepicker';
import { SharedModule } from '../../shared/shared.module';
import { ProductModule } from '../product/product.module';
import { PatientActionLogComponent } from './patient-action-log/patient-action-log.component';
import { PatientCardChangesModalComponent } from './patient-card-changes-modal/patient-card-changes-modal.component';
import { PatientCardDeferModalComponent } from './patient-card-defer-modal/patient-card-defer-modal.component';
import { PatientCardProceedComponent } from './patient-card-proceed/patient-card-proceed.component';
import { PatientCardStopModalComponent } from './patient-card-stop-modal/patient-card-stop-modal.component';
import { PatientContactsComponent } from './patient-contacts/patient-contacts.component';
import { PatientMessageListComponent } from './patient-message-list/patient-message-list.component';
import { PatientOverviewComponent } from './patient-overview/patient-overview.component';
import { PatientProfileMerchantComponent } from './patient-profile-merchant/patient-profile-merchant.component';
import { PatientSummaryComponent } from './patient-summary/patient-summary.component';
import { PatientRoutingModule } from './shared/patient-routing.module';
import { PatientService } from './shared/patient.service';

import { DentistModule } from '../dentist/dentist.module';
import { TreatmentModule } from '../treatment/treatment.module';

import { PatientContactDetailsCreateComponent } from './patient-contact-details-create/patient-contact-details-create.component';
import { PatientContactDetailsViewComponent } from './patient-contact-details-view/patient-contact-details-view.component';
import { PatientIdentificationCreateComponent } from './patient-identification-create/patient-identification-create.component';
import { PatientIdentificationViewComponent } from './patient-identification-view/patient-identification-view.component';
import { PatientIntroModalComponent } from './patient-intro-modal/patient-intro-modal.component';
import { PatientPersonalDetailsCreateComponent } from './patient-personal-details-create/patient-personal-details-create.component';
import { PatientPersonalDetailsViewComponent } from './patient-personal-details-view/patient-personal-details-view.component';
import { PatientRecordDetailsComponent } from './patient-record-details/patient-record-details.component';
import { PatientResidentialCreateComponent } from './patient-residential-create/patient-residential-create.component';

import { MatRippleModule } from '@angular/material';
import { PasswordStrengthMeterModule } from 'angular-password-strength-meter';
import { AuthenticationModule } from '../../core/authentication/authentication.module';
import { HelperModule } from '../../core/helper/helper.module';
import { CustomerProspectModule } from '../customer-prospect/customerProspect.module';
import { CustomerModule } from '../customer/customer.module';
import { ExisitingPersonViewPictureComponent } from '../existing-patient/existing-patient-view-picture/existing-patient-view-picture.component';
import { InformedConsentService } from '../informed-consent/shared/informed-consent.service';
import { InformedConsentComponent } from './informed-consent/informed-consent.component';
import { InvitationCardComponent } from './invitation-card/invitation-card.component';
import { InvitationStatusComponent } from './invitation-status/invitation-status.component';
import { MainCardComponent } from './main-card/main-card.component';
import { MerchantMainCardComponent } from './merchant-main-card/merchant-main-card.component';
import { DiscountInfoModalComponent } from './patient-card-discount-info-modal/patient-card-discount-info-modal.component';
import { PatientCardQuotationComponent } from './patient-card-quotation/patient-card-quotation.component';
import { PatientCardComponent } from './patient-card/patient-card.component';
import { PatientCreatePersonalComponent } from './patient-create-personal/patient-create-personal.component';
import { PatientCustomerLoginComponent } from './patient-customer-login/patient-customer-login.component';
import { PatientDashbordComponent } from './patient-dashbord/patient-dashbord.component';
import { PatientTreatmentViewComponent } from './patient-treatment-view/patient-treatment-view.component';
import { PatientUpdateDetailsComponent } from './patient-update-details/patient-update-details.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    CdkTableModule,
    ProductModule,

    HelperModule,
    SharedModule,
    CustomerModule,
    CustomerProspectModule,
    AuthenticationModule,
    ReactiveFormsModule,
    PatientRoutingModule,
    NgDatepickerModule,
    TreatmentModule,
    DentistModule,
    ArchwizardModule,
    MatRippleModule,
    PasswordStrengthMeterModule,
  ],
  declarations: [
    InformedConsentComponent,
    InvitationCardComponent,
    MainCardComponent,
    PatientCardComponent,

    ExisitingPersonViewPictureComponent,
    PatientCreatePersonalComponent,
    PatientActionLogComponent,
    PatientMessageListComponent,
    PatientContactsComponent,
    PatientOverviewComponent,
    PatientSummaryComponent,
    PatientProfileMerchantComponent,
    PatientCardProceedComponent,
    PatientCardChangesModalComponent,
    PatientCardDeferModalComponent,
    DiscountInfoModalComponent,
    PatientCardStopModalComponent,
    PatientPersonalDetailsCreateComponent,
    PatientContactDetailsCreateComponent,
    PatientIdentificationCreateComponent,
    PatientResidentialCreateComponent,
    PatientContactDetailsViewComponent,
    PatientPersonalDetailsViewComponent,
    PatientIdentificationViewComponent,
    PatientIntroModalComponent,
    PatientRecordDetailsComponent,
    PatientTreatmentViewComponent,
    PatientUpdateDetailsComponent,
    PatientCardQuotationComponent,
    PatientDashbordComponent,
    PatientCustomerLoginComponent,
    InvitationStatusComponent,
    MerchantMainCardComponent,
  ],
  exports: [
    InformedConsentComponent,
    InvitationCardComponent,
    MainCardComponent,
    PatientCardComponent,

    ExisitingPersonViewPictureComponent,
    PatientCreatePersonalComponent,
    PatientActionLogComponent,
    PatientMessageListComponent,
    PatientContactsComponent,
    PatientOverviewComponent,
    PatientSummaryComponent,
    PatientProfileMerchantComponent,
    PatientCardProceedComponent,
    PatientCardChangesModalComponent,
    PatientCardDeferModalComponent,
    DiscountInfoModalComponent,
    PatientCardStopModalComponent,
    PatientPersonalDetailsCreateComponent,
    PatientContactDetailsCreateComponent,
    PatientIdentificationCreateComponent,
    PatientResidentialCreateComponent,
    PatientContactDetailsViewComponent,
    PatientPersonalDetailsViewComponent,
    PatientIdentificationViewComponent,
    PatientIntroModalComponent,
    PatientRecordDetailsComponent,
    PatientTreatmentViewComponent,
    PatientUpdateDetailsComponent,

    PatientCardQuotationComponent,
    PatientDashbordComponent,
    PatientCustomerLoginComponent,
    InvitationStatusComponent,
    MerchantMainCardComponent,
  ],
  providers: [PatientService, InformedConsentService],
})
export class PatientModule {}
