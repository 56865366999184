<!--  Amount required slider -->
<div class="row clearfix consumer-slider">
  <div class="half-width">
    <div class="col-left row clearfix rel inlined-label flex">
      <label class="half-width slider-label inline accent-color">{{ label }}</label>
      <mat-form-field class="half-width inline slider-inputs">
        <input
          [(ngModel)]="amount"
          [min]="minAmount"
          [max]="maxAmount"
          (blur)="changeAmountValue()"
          matInput
          name="amountRequired"
          placeholder=""
          currencyMask
        />
      </mat-form-field>
    </div>
  </div>
  <div class="half-width">
    <div class="col-right">
      <div class="row clearfix rel">
        <!-- range slider for amount required -->
        <mat-slider
          class="full-width"
          [(ngModel)]="amount"
          [min]="minAmount"
          [max]="maxAmount"
          (change)="updateAmountValue($event)"
          step="{{ step }}"
          thumbLabel
        ></mat-slider>

        <p class="min-amount small">{{ minAmount | customCurrency }}</p>
        <p class="max-amount small">{{ maxAmount | customCurrency }}</p>
      </div>
    </div>
  </div>
</div>
