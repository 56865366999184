<div class="rel" *ngIf="instantOffer" [ngClass]="{ 'not-modal-container': isModal != true }">
  <form #resetForm="ngForm">
    <div
      class="card-header primary-gradient-img clearfix confirmModal"
      [attr.style]="'background:' + theme.background + ' !important' | safeStyle"
    >
      <button
        class="btn-close btn-clear mat-button smt white custom-close"
        *ngIf="isModal == true"
        (click)="closeEvent()"
      >
        <mat-icon>clear</mat-icon>
      </button>
      <div class="full-width flex main-title-container">
        <div class="row full-width title-container clearfix">
          <div class="title-container-sub">
            <instant-offer-header-image
              class="pull-left mr offer-image-header-view"
              [headerImage]="instantOffer['Header_Image']"
              [merchantID]="instantOffer['Merchant_key']"
              [hideZoomButton]="false"
            >
            </instant-offer-header-image>

            <h2 class="text-left title-header rm-m smt">
              {{ instantOffer.Title }}

              <span class="subLabel">
                <span class="mr" *ngIf="isPromoterOrAdmin == true">
                  {{ instantOffer.Merchant_Tranding_As }}
                </span>

                <span class="mr" *ngIf="instantOffer && instantOffer.documents && instantOffer.documents.length">
                  Attached Documents

                  <span class="badge mr" [endVal]="instantOffer.documents.length" countUp></span>
                </span>
              </span>
            </h2>
          </div>
        </div>
      </div>
    </div>

    <mat-dialog-content [ngClass]="{ 'no-modal-height': isModal != true }">
      <div class="full-width">
        <div class="full-width detail-container">
          <div class="box text-center">
            <h3
              class="sr-title text-left smt smb"
              *ngIf="settings && settings['context'] == 'beeze'"
              [attr.style]="'color:' + theme.color + ' !important' | safeStyle"
            >
              Customer
            </h3>
            <h3
              class="sr-title text-left smt smb"
              *ngIf="settings && settings['context'] != 'beeze'"
              [attr.style]="'color:' + theme.color + ' !important' | safeStyle"
            >
              {{ "KEYWORD.patient" | translate | titlecase }}
            </h3>

            <p class="text-left click-link rm-m good" (click)="viewPatient(instantOffer.CustomerProspect_key)">
              <strong>{{ instantOffer.CustomerProspect_CalculatedName }}</strong>
            </p>
          </div>

          <div class="box text-center" *ngIf="settings && settings['context'] == 'beeze'">
            <h3 class="sr-title text-left smt smb" [attr.style]="'color:' + theme.color + ' !important' | safeStyle">
              Amount to Finance
            </h3>
            <p class="text-left rm-m good">
              <strong>{{ instantOffer.Treatment_Amount | customCurrency }}</strong>
            </p>
          </div>
          <div class="box text-center" *ngIf="settings && settings['context'] != 'beeze'">
            <h3 class="sr-title text-left smt smb" [attr.style]="'color:' + theme.color + ' !important' | safeStyle">
              {{ "KEYWORD.treatment" | translate | titlecase }} Value
            </h3>
            <p class="text-left rm-m good">
              <strong>{{ instantOffer.Treatment_Amount | customCurrency }}</strong>
            </p>
          </div>

          <div class="box text-center">
            <h3 class="sr-title text-left smt smb" [attr.style]="'color:' + theme.color + ' !important' | safeStyle">
              Offer Status
            </h3>

            <p
              class="text-left pull-left text-status rm-m"
              *ngIf="
                instantOffer['Status.Code'] != '' &&
                instantOffer['Status.Code'] != 'Viewed' &&
                instantOffer['Status.Code'] != 'Rejected' &&
                instantOffer['Status.Code'] != 'NotProcd'
              "
              [attr.style]="'background:rgb(90, 186, 71) !important' | safeStyle"
            >
              <strong>{{ instantOffer['Status.Label'] || 'Sent' }}</strong>
            </p>

            <p
              class="text-left pull-left text-status rm-m"
              *ngIf="instantOffer['Status.Code'] == '' && instantOffer['Is_Active'] == '1'"
              [attr.style]="'background:rgb(6, 193, 141) !important' | safeStyle"
            >
              <strong>Sent</strong>
            </p>

            <p
              class="text-left pull-left text-status rm-m"
              *ngIf="instantOffer['Status.Code'] == '' && instantOffer['Is_Active'] != '1'"
              [attr.style]="'background:rgb(239, 71, 39) !important' | safeStyle"
            >
              <strong>Not Sent</strong>
            </p>

            <p
              class="text-left pull-left text-status rm-m"
              *ngIf="instantOffer['Status.Code'] == 'Viewed'"
              [attr.style]="'background:rgb(251, 169, 25) !important' | safeStyle"
            >
              <strong>{{ instantOffer['Status.Label'] }}</strong>
            </p>
            <p
              class="text-left pull-left text-status rm-m"
              *ngIf="instantOffer['Status.Code'] == 'Rejected' || instantOffer['Status.Code'] == 'NotProcd'"
              [attr.style]="'background:rgb(239, 71, 39) !important' | safeStyle"
            >
              <strong> Not Proceeded</strong>
            </p>
          </div>
        </div>

        <div class="full-width detail-container">
          <div class="box text-center">
            <h3 class="sr-title text-left smt smb" [attr.style]="'color:' + theme.color + ' !important' | safeStyle">
              Invitation Status
            </h3>
            <p
              class="text-left pull-left text-status rm-m"
              [style.background]="instantOffer['Status.Invitation.Colour'] || '#b4bcc1'"
            >
              <strong>{{ instantOffer['Status.Invitation.Short'] || '...' }}</strong>
            </p>
          </div>

          <div class="box text-center">
            <h3 class="sr-title text-left smt smb" [attr.style]="'color:' + theme.color + ' !important' | safeStyle">
              Application Status
            </h3>
            <p
              class="text-left pull-left text-status rm-m"
              [style.background]="instantOffer['Status.Application.Colour'] || '#b4bcc1'"
            >
              <strong>{{ instantOffer['Status.Application.Short'] || '...' }}</strong>
            </p>
          </div>

          <div class="box text-center">
            <h3 class="sr-title text-left smt smb" [attr.style]="'color:' + theme.color + ' !important' | safeStyle">
              Contract Status
            </h3>
            <p
              class="text-left pull-left text-status rm-m"
              [style.background]="instantOffer['Status.Contract.Colour'] || '#b4bcc1'"
            >
              <strong>{{ instantOffer['Status.Contract.Short'] || '...' }}</strong>
            </p>
          </div>
        </div>
        <div class="full-width detail-container">
          <div class="box text-center">
            <h3 class="sr-title text-left smt smb" [attr.style]="'color:' + theme.color + ' !important' | safeStyle">
              Source
            </h3>
            <p class="text-left rm-m">
              <strong class="mr"> {{ instantOffer['Source_Instant_Finance_Offer.Label'] || 'SMS' }}</strong>

              <mat-icon
                class="fas fa-mobile-alt"
                *ngIf="instantOffer.Send_SMS === '1'"
                style="font-size: 1.1rem; color: gray"
                [matTooltip]="('KEYWORD.patient' | translate | titlecase) + ' is notified by SMS'"
              ></mat-icon>
              <mat-icon
                class="fas fa-envelope"
                *ngIf="instantOffer.Send_Email === '1'"
                style="font-size: 1.1rem; color: gray"
                [matTooltip]="('KEYWORD.patient' | translate | titlecase) + ' is notified by Email'"
              ></mat-icon>
            </p>
          </div>

          <div class="box text-center">
            <h3 class="sr-title text-left smt smb" [attr.style]="'color:' + theme.color + ' !important' | safeStyle">
              Sender
            </h3>
            <p class="text-left click-link rm-m" (click)="viewDentist(instantOffer.Contact_key)">
              <strong>{{ instantOffer.Contact_CalculatedName }}</strong>
            </p>
          </div>

          <div class="box text-center">
            <h3 class="sr-title text-left smt smb" [attr.style]="'color:' + theme.color + ' !important' | safeStyle">
              Finance Type
            </h3>
            <p class="text-left rm-m">
              <mat-icon
                class=" fas {{
                  productUtil.getProductGroupIcon(instantOffer['Product_Group.Code'])
                }} product-group-ico-small mr"
              >
              </mat-icon>

              <strong> {{ productUtil.getLabel(instantOffer['Product_Group.Code']) }}</strong>
            </p>
          </div>
        </div>

        <h3
          class="sr-title text-left full-width mt smb"
          *ngIf="description"
          [attr.style]="'color:' + theme.color + ' !important' | safeStyle"
        >
          Description
        </h3>
        <div class="full-width flex">
          <read-more
            class="text-left full-width read-more-module22 read-more-message-input rm-m"
            *ngIf="description"
            [animated]="true"
            [text]="utils.URLify(description || '...', false)"
            [design]="'noPropagation'"
            [maxLength]="maxLength"
            [isCollapsed]="false"
            [hideButton]="!isModal"
            [hideButtonForce]="!isModal"
          >
          </read-more>
        </div>
        <h3
          class="sr-title text-left full-width mt smb"
          *ngIf="message"
          [attr.style]="'color:' + theme.color + ' !important' | safeStyle"
        >
          Message
        </h3>

        <div class="full-width flex">
          <read-more
            class="text-left full-width read-more-module22 read-more-message-input rm-m"
            *ngIf="message"
            [animated]="true"
            [text]="utils.URLify(message || '...', false)"
            [design]="'noPropagation'"
            [maxLength]="maxLength"
            [isCollapsed]="false"
            [hideButton]="!isModal"
            [hideButtonForce]="!isModal"
          >
          </read-more>
        </div>

        <h3
          class="sr-title text-left full-width mt smb"
          *ngIf="instantOffer && instantOffer.tags && instantOffer.tags.length > 0"
          [attr.style]="'color:' + theme.color + ' !important' | safeStyle"
        >
          Tags
        </h3>

        <mat-chip-list class="full-width clearfix" #chipList name="tags-patient">
          <mat-chip *ngFor="let tag of instantOffer.tags" [selectable]="false" [removable]="false">
            {{ tag }}
          </mat-chip>
        </mat-chip-list>

        <h3
          class="sr-title text-left pull-left text-center"
          *ngIf="instantOffer && instantOffer.documents && instantOffer.documents.length > 0"
          [attr.style]="'color:' + theme.color + ' !important' | safeStyle"
        >
          Attached Documents
          <span>({{ instantOffer.documents.length }})</span>
        </h3>

        <div class="full-width" *ngIf="instantOffer && instantOffer.documents && instantOffer.documents.length > 0">
          <app-ep-document-list
            class="document-instantOffer"
            [isDownload]="false"
            [isPrint]="false"
            [canRemove]="false"
            [justify]="'left'"
            [isModal]="false"
            [isFilter]="false"
            [defaultCheck]="false"
            [isRedirection]="false"
            [documents]="instantOffer.documents"
            [title]="''"
            [canCheck]="false"
            [listViewType]="'thumbnails'"
          >
          </app-ep-document-list>
        </div>
      </div>
    </mat-dialog-content>

    <hr/>

    <div class="full-width text-center clearfix" *ngIf="isModal != true" style="float: none">
      <button
        class="btn-large mt mb ml pull-left"
        [attr.style]="'background:' + theme.color + ' !important' | safeStyle"
        (click)="list()"
        mat-raised-button
        color="accent"
      >
        <mat-icon class="smr" style="font-size: 32px !important"> keyboard_arrow_left</mat-icon>
        Instant Finance Offer List
      </button>
    </div>
    <div class="drop-button text-center mt text-center full-width" *ngIf="isModal == true">
      <button
        class="btn-large pull-right"
        *ngIf="
          instantOffer &&
          instantOffer['Status.Code'] !== 'NotProcd' &&
          instantOffer.CustomerProspect_key &&
          instantOffer.Invitation_key
        "
        (click)="viewInvitation()"
        mat-raised-button
        color="primary"
      >
        View Invitation
      </button>

      <button
        class="btn-large pull-right"
        *ngIf="isPromoterOrAdmin == true"
        (click)="openIframe()"
        mat-raised-button
        color="primary"
      >
        Landing Page
      </button>

      <button
        class="btn-large ml mr pull-right"
        *ngIf="instantOffer && instantOffer.ID && isSubmit == true"
        [attr.style]="'background:' + theme.color + ' !important' | safeStyle"
        (click)="sendResult()"
        mat-raised-button
        color="accent"
      >
        Submit
      </button>

      <button class="btn-large pull-left" (click)="closeEvent()" mat-raised-button color="primary">close</button>
    </div>
  </form>
</div>
