import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { uniq, uniqBy } from 'lodash';
import { UtilsService } from '../../services/utils.service';
import { DocumentUtilClass } from '../../types/documentUtil';
import { NotifyAppComponent } from '../../types/notify-app-component';
import { UtilsClass } from '../../types/utils/utils.class';
import { EpDocumentViewComponent } from '../ep-document-view/ep-document-view.component';

@Component({
  selector: 'app-ep-document-list-mini',
  templateUrl: './ep-document-list-mini.component.html',
  styleUrls: ['./ep-document-list-mini.component.css'],
})
export class EpDocumentListMiniComponent implements OnInit {
  @Input() documents: any = [];

  @Input() cardID;

  @Input() view = 'consumer';

  @Input() title = ' Your Documents';
  @Input() instructionLine = 'Please click to view all of your treatment information documents.';
  @Input() description;
  @Input() instruction = false;
  @Input() isAction = false;
  @Input() actionLabel = 'Select';
  @Output() getDocument = new EventEmitter();

  @Input() displayDate = true;

  @Input() displayViewedDate = false;

  @Input() displayStatus = false;

  @Input() viewedDocuments = [];

  @Output() getViewedDocument = new EventEmitter();

  @Input() isDownload = true;
  @Input() isRedirection = true;
  @Input() isPrint = true;
  @Input() isReplace = false;

  @Input() isFilter = false;

  filter;

  _documents = [];
  viewedDate;
  presentedDate;

  utils = new UtilsClass();
  DocumentUtilClass = new DocumentUtilClass();

  constructor(private dialog: MatDialog, private utilService: UtilsService) {}

  ngOnInit() {
    if (this.documents && typeof this.documents == 'string' && this.documents.split(',').length > 0) {
      let __doc = this.documents.split(',');

      __doc = uniq(__doc);

      const ids = __doc.join(',');

      const p = {
        fields:
          'AWS_URL,AWS_Bucket_Name,DocumentType_Code,DateAdded,Card.Name,DateTimeCreated,Description,Extension,ID,Label,URL.Complete,Tags,ContainsSensitiveData,IsGallery',
      };

      this.utilService.getEpdocumentArray(ids, p).subscribe((res) => {
        if (res) {
          this._documents = res;
          for (let i = 0; i < this._documents.length; i++) {
            this.setDocumentType(i);
          }
        }
      });
    } else if (
      this.documents &&
      Array.isArray(this.documents) &&
      this.documents.length > 0 &&
      typeof this.documents[0] == 'string'
    ) {
      this.documents = uniq(this.documents);
      const ids = this.documents.join(',');

      const p = {
        fields:
          'AWS_URL,AWS_Bucket_Name,DocumentType_Code,DateAdded,Card.Name,DateTimeCreated,Description,Extension,ID,Label,URL.Complete,Tags,ContainsSensitiveData,IsGallery',
      };

      this.utilService.getEpdocumentArray(ids, p).subscribe((res) => {
        if (res) {
          this._documents = res;
          for (let i = 0; i < this._documents.length; i++) {
            this.setDocumentType(i);
          }
        }
      });
    } else if (
      this.documents &&
      Array.isArray(this.documents) &&
      this.documents.length > 0 &&
      typeof this.documents[0] == 'object'
    ) {
      this._documents = uniqBy(this.documents, 'ID');
      for (let i = 0; i < this._documents.length; i++) {
        this.setDocumentType(i);
      }
    } else {
      this._documents = [];
      for (let i = 0; i < this._documents.length; i++) {
        this.setDocumentType(i);
      }
    }
  }

  sendFile(doc) {
    this.getDocument.emit(doc);
  }

  viewFiles(i) {
    if (
      this._documents[i] &&
      this._documents[i]['URL.Complete'] &&
      this._documents[i]['URL.Complete'] != '' &&
      this._documents[i]['URL.Complete'].indexOf('youtube.com') == -1 &&
      this._documents[i]['URL.Complete'].indexOf('vimeo.com') == -1
    ) {
      window.open(this._documents[i]['URL.Complete'], '_blank');

      this.getViewedDocument.emit(this._documents[i]);
    } else {
      const ref = this.dialog.open(EpDocumentViewComponent, {
        data: {
          cardID: this.cardID,
          isDownload: this.isDownload,
          isPrint: this.isPrint,
          isReplace: this.isReplace,
          isRedirection: this.isRedirection,
          document: this._documents[i],
          title: this.title,
          description: this.description,
        },
        width: '800px',
        panelClass: 'noCard',
      });

      ref.componentInstance.loadDocument.subscribe((res) => {
        if (res) {
          this.getViewedDocument.emit(res);
        }
      });

      ref.componentInstance.closeModal.subscribe((res) => {
        ref.close();
      });

      ref.componentInstance.noFileFound.subscribe((res) => {
        NotifyAppComponent.displayToast('warning', 'Sorry!', 'No file Found');
      });
    }
  }

  isDocumentViewed(i) {
    if (
      this._documents &&
      this._documents.length > 0 &&
      this._documents[i] &&
      this._documents[i]['ID'] &&
      this.viewedDocuments &&
      this.viewedDocuments.length > 0
    ) {
      const ids = this.viewedDocuments.map((x) => x.id);
      if (ids.indexOf(this._documents[i]['ID']) != -1) {
        return true;
      }
    }

    return false;
  }

  getViewedDate(i) {
    if (
      this._documents &&
      this._documents.length > 0 &&
      this._documents[i] &&
      this._documents[i]['ID'] &&
      this.viewedDocuments &&
      this.viewedDocuments.length > 0
    ) {
      const ids = this.viewedDocuments.map((x) => x.id);
      if (ids.indexOf(this._documents[i]['ID']) != -1) {
        const _id = ids.indexOf(this._documents[i]['ID']);
        return this.viewedDocuments[_id]['viewedDate'];
      }
    }
  }

  setDocumentType(i) {
    this._documents[i]['type'] = this.utils.getDocumentType(this._documents[i]);
  }
}
