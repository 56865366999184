import { Component, Input } from '@angular/core';
import { ProductCustomer } from '../../../../../shared/services/product-customer.service';

@Component({
  selector: 'ipv-dental-view-customer-product',
  templateUrl: './dental-view-customer-product.component.html',
  styleUrls: ['./dental-view-customer-product.component.css'],
})
export class DentalViewCustomerProductComponent {
  @Input() productCustomer: ProductCustomer = null;
}
