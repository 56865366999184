<div class="row clearfix flex">
  <div class="full-width">
    <div class="clearfix flex labelMargin">
      <label class="">
        <span *ngIf="isContractView == true">Contract Date</span>
        <span *ngIf="isContractView == false">Quote Date</span>
      </label>
      <p class="valueMargin">
        <span *ngIf="isContractView == true && contract['ContractDate'] != '0000-00-00'"
        >{{ contract['ContractDate'] | customDate }}
        </span>
        <span *ngIf="isContractView == false && contract['ApplicationDate'] != '0000-00-00'">{{
          contract['ApplicationDate'] | customDate
          }}</span>
      </p>
    </div>

    <div class="clearfix flex labelMargin">
      <label class="">
        <span *ngIf="isContractView == true">Contract Expire</span>
        <span *ngIf="isContractView == false">Qutoe Expire</span>
      </label>
      <p class="valueMargin">
        <span *ngIf="isContractView == true && contract['FirstDrawDown.Before'] != '0000-00-00'">{{
          contract['FirstDrawDown.Before'] | customDate
          }}</span>
        <span *ngIf="isContractView == false && contract['ApplicationExpiryDate'] != '0000-00-00'">{{
          contract['ApplicationExpiryDate'] | customDate
          }}</span>
      </p>
    </div>

    <div class="clearfix flex labelMargin">
      <label class="">Loan Start Date</label>
      <p class="valueMargin">{{ contract['LoanStartDate'] | customDate }}</p>
    </div>
  </div>

  <div class="full-width">
    <div class="clearfix flex labelMargin">
      <label class="">Quotaion Status</label>
      <p class="valueMargin">{{ contract['Quotation.Status.Label'] }}</p>
    </div>

    <div class="clearfix flex labelMargin">
      <label class="">Contract Status</label>
      <p class="valueMargin">{{ contract['Status'] }}</p>
    </div>

    <div class="clearfix flex labelMargin">
      <label class="">Payments Status</label>
      <p class="valueMargin">{{ contract['PaymentStatus'] }}</p>
    </div>
  </div>

  <div class="full-width">
    <div class="clearfix flex labelMargin">
      <label class="">{{ "KEYWORD.practice" | translate | titlecase}} Name</label>
      <p class="valueMargin">{{ contract['MerchantName'] }}</p>
    </div>
  </div>

  <div class="full-width">
    <div class="clearfix flex labelMargin checkboxHeight">
      <label class="">Capacity Check</label>
      <p class="valueMargin">{{ contract['CapacityCheck'] | customDate }} {{ contract['CapacityCheck.Status'] }}</p>
    </div>

    <div class="clearfix flex labelMargin checkboxHeight">
      <label class="">Credit Check</label>
      <p class="valueMargin">{{ contract['CreditCheck'] | customDate }} {{ contract['CreditCheck.Status'] }}</p>
    </div>

    <div class="clearfix flex labelMargin checkboxHeight">
      <label class="">Identity Check</label>
      <p class="valueMargin">{{ contract['IdentityCheck'] | customDate }} {{ contract['IdentityCheckStatus'] }}</p>
    </div>
  </div>
</div>
