import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { NgPipesModule } from 'ngx-pipes';
import { SharedModule } from '../../shared/shared.module';
import { GoogleReviewReplyDialogComponent } from './dialogs/google-review-reply-dialog/google-review-reply-dialog.component';
import { GoogleReviewsBusinessSettingsDialogComponent } from './dialogs/google-reviews-business-settings-dialog/google-reviews-business-settings-dialog.component';
import { GoogleReviewsPromptDialogComponent } from './dialogs/google-reviews-prompt-dialog-modal/google-reviews-prompt-dialog.component';
import { GoogleReviewsSignInDialogComponent } from './dialogs/google-reviews-sign-in-dialog/google-reviews-sign-in-dialog.component';
import { GoogleReviewListComponent } from './google-review-list/google-review-list.component';
import { GoogleReviewComponent } from './google-review/google-review.component';
import { GoogleReviewsConfigComponent } from './google-reviews-config/google-reviews-config.component';
import { GoogleReviewsRoutingModule } from './shared/google-reviews-routing.module';
import { GoogleReviewsService } from './shared/google-reviews.service';

@NgModule({
  imports: [NgPipesModule, CommonModule, FormsModule, SharedModule, GoogleReviewsRoutingModule],
  declarations: [
    GoogleReviewComponent,
    GoogleReviewListComponent,
    GoogleReviewsConfigComponent,
    GoogleReviewReplyDialogComponent,
    GoogleReviewsBusinessSettingsDialogComponent,
    GoogleReviewsSignInDialogComponent,
    GoogleReviewsPromptDialogComponent,
  ],
  exports: [
    GoogleReviewComponent,
    GoogleReviewListComponent,
    GoogleReviewsConfigComponent,
    GoogleReviewReplyDialogComponent,
    GoogleReviewsBusinessSettingsDialogComponent,
    GoogleReviewsSignInDialogComponent,
    GoogleReviewsPromptDialogComponent,
  ],
  entryComponents: [
    GoogleReviewReplyDialogComponent,
    GoogleReviewsBusinessSettingsDialogComponent,
    GoogleReviewsSignInDialogComponent,
    GoogleReviewsPromptDialogComponent,
  ],
  providers: [GoogleReviewsService],
})
export class GoogleReviewsModule {}
