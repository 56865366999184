import { Component, EventEmitter, Inject, Input, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { UtilsService } from '../../services/utils.service';

@Component({
  selector: 'link-input',
  templateUrl: './link-input.component.html',
  styleUrls: ['./link-input.component.css'],
})
export class LinkInputComponent implements OnInit {
  @Input()
  icon = 'fas fa-link';
  close = new EventEmitter();
  getResult = new EventEmitter();
  link;
  title;

  displayTitle = false;
  constructor(
    private utilService: UtilsService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.displayTitle === true) {
        this.displayTitle = true;
      } else if (data.displayTitle === false) {
        this.displayTitle = false;
      }
    }
  }

  ngOnInit() {}

  isFontAwsome(icon) {
    if (icon) {
      if (icon.indexOf('fas ') !== -1 || icon.indexOf('fab-') !== -1) {
        return true;
      }

      return false;
    }

    return false;
  }
  send() {
    if (this.link) {
      this.utilService.createShortcut(this.link).subscribe((r) => {
        if (r && r.shortLink) {
          let title = this.link;
          if (this.title) {
            title = this.title;
          }

          const result = {
            link: this.link,
            shortLink: r.shortLink,
            title: this.title,
            htmlLink: `<a title="${title}" target="_blank" href="${r.shortLink}">${r.shortLink}</a>`,
          };

          if (this.title) {
            result.htmlLink = `<a title="${this.title}" target="_blank" href="${r.shortLink}">${this.title}</a>`;
          }

          this.getResult.emit(result);
        }
      });
    }
  }

  preview() {
    if (this.link) {
      this.utilService.createShortcut(this.link).subscribe((r) => {
        if (r && r.shortLink) {
          window.open(r.shortLink, '_blank');
        }
      });
    }
  }

  closeEvent() {
    this.close.emit(true);
  }
}
