import { Component, Inject, Input, OnInit, Optional } from '@angular/core';

import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-existing-patient-summary',
  templateUrl: './existing-patient-summary.component.html',
  styleUrls: ['./existing-patient-summary.component.css'],
})
export class ExistingPatientSummaryComponent implements OnInit {
  @Input() existingPatientID;

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data && typeof data == 'string') {
      this.existingPatientID = data;
    }
  }

  ngOnInit() {
    // this.existingPatientService.getExistingPatientDetails(4).subscribe(res => {
    //   this.existingPatient = res;
    // });
  }
}
