import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { MessageService } from '../shared/message.service';
import { MAT_DIALOG_DATA } from '@angular/material';
import { CustomPhonePipe } from '../../../shared/pipes/custom-phone.pipe';
import { animate, state, style, transition, trigger } from '@angular/animations';

import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import * as _ from 'lodash';
import { CustomerProspectViewModelComponent } from '../../customer-prospect/customer-prospect-view-model/customer-prospect-view-model.component';
import { SmsPreviewComponent } from '../sms-preview/sms-preview.component';
import { MessageUtil } from '../shared/messageUtil.type';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { FilterArrayMultipleOrContain } from '../../../shared/pipes/filter-array-object-or-contain.pipe';
import { TagCreateEditCardComponent } from '../../tags/tag-create-edit-card/tag-create-edit-card.component';
import { SmsConversationComponent } from '../sms-conversation/sms-conversation.component';
import { SmsPromoterComponent } from '../sms-promoter/sms-promoter.component';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';

@Component({
  selector: 'app-message-group-local-list',
  templateUrl: './message-group-local-list.component.html',
  styleUrls: ['./message-group-local-list.component.css'],
  animations: [
    trigger('simpleFadeAnimation', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [style({ opacity: 0, height: 0 }), animate(300)]),
      transition(':leave', animate(300, style({ opacity: 0, height: '*' }))),
    ]),
  ],
})
export class MessageGroupLocalListComponent implements OnInit {
  @Input()
  merchantID;

  @Input()
  messageGroupID;

  @Output()
  getRefresh = new EventEmitter();

  @Output()
  close = new EventEmitter();

  @Input()
  refresh = new EventEmitter();

  @Input()
  limit = 200;
  @Input()
  section = 1;

  @Input()
  status;

  @Input()
  messageStatus;
  @Input()
  itemsPerPage = 50;
  currentPage = 1;
  orderByFilter = 'cardName';
  @Input()
  contactID;

  @Input()
  cardID;

  @Input()
  messageID;

  @Input()
  isCancel = true;
  @Input()
  isViewOnly = false;

  isDestoyed = false;
  sessionType;
  isPromoterOrAdmin = false;

  serviceRef;

  @Input()
  hideHeader = false;
  @Input()
  isModal = false;

  @Input()
  title = 'Message Queue List';

  @Input()
  description;

  isLoaded = false;
  filterData;

  messages = [];
  messageLabel = 'SMS';
  @Input()
  isOneLine = true;

  messageUtil = new MessageUtil();

  statusColors = this.messageUtil.statusColors;
  messageStatusColors = this.messageUtil.messageStatusColors;

  filterStatusArray = [];
  filterMessageStatusArray = [];

  filterMessageStatus;
  filterStatus;

  @Input()
  isSMS = true;

  @Input()
  multipSelect = false;

  statusIcons = this.messageUtil.statusIcons;
  selectedIDs = [];

  constructor(
    private authenticationService: AuthenticationService,
    private messageService: MessageService,
    private customPhonePile: CustomPhonePipe,

    private router: Router,
    private filterArrayMultipleOrContain: FilterArrayMultipleOrContain,

    private activeRoute: ActivatedRoute,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.merchantID) {
        this.merchantID = data.merchantID;
        this.isModal = true;
      }

      if (data.messageGroupID) {
        this.messageGroupID = data.messageGroupID;
        this.isModal = true;
      }

      if (data.status) {
        this.status = data.status;
        this.isModal = true;
      }
      if (data.messageStatus) {
        this.messageStatus = data.messageStatus;
        this.isModal = true;
      }
    }
  }

  ngOnInit() {
    if (this.refresh) {
      this.refresh.subscribe(() => {
        this.refreshEvent();
      });
    }
    this.authenticationService.isPromoterOrAdmin().subscribe((isPromoterOrAdmin) => {
      this.isPromoterOrAdmin = isPromoterOrAdmin;

      this.authenticationService.getSessionType().subscribe((r) => {
        this.sessionType = r;
        this.setup();
        this.messageService.getMessageGroupStatusColors().subscribe((colors) => {
          if (colors) {
            this.statusColors = colors || {};
            this.messageUtil.setStatusColors(this.statusColors);
          }
        });

        this.messageService.getMessageGroupmessageStatusColors().subscribe((messageStatusColors) => {
          if (messageStatusColors) {
            this.messageStatusColors = messageStatusColors || {};
            this.messageUtil.setMessageStatusColors(this.messageStatusColors);
          }
        });
      });
    });
  }

  pageOne() {
    this.currentPage = 1;
  }

  setup() {
    this.section = 1;

    let p: any = {
      section: this.section,
      limit: this.limit,
      fields:
        'messageGroupID,cardID,cardName,cardEmail,cardMobile,isMessageBuilt,merchantID,contactID,messageID,status,messageStatus,messageSentDate,dateToBeSent,submittedDate,createdAt',
    };

    if (this.merchantID) {
      p.merchantID = this.merchantID;
    }
    if (this.contactID) {
      p.contactID = this.contactID;
    }
    if (this.status) {
      p.status = this.status;
    }
    if (this.messageStatus) {
      p.messageStatus = this.messageStatus;
    }
    if (this.messageGroupID) {
      p.messageGroupID = this.messageGroupID;
    }
    if (this.cardID) {
      p.cardID = this.cardID;
    }
    this.messageService.getMessageGroupLocalList(p, this.sessionType).subscribe((res) => {
      this.isLoaded = true;
      if (res && res.length > 0) {
        res = _.map(res, (item) => {
          if (item) {
            item.search = '';
            if (item._id) {
              item.search = item.search + ' ' + item._id;
            }
            if (item.cardName) {
              item.search = item.search + ' ' + item.cardName;
            }
            if (item.cardEmail) {
              item.search = item.search + ' ' + item.cardEmail;
            }
            if (item.messageStatus) {
              item.search = item.search + ' ' + item.messageStatus;
            }
            if (item.status) {
              item.search = item.search + ' ' + item.status;
            }

            if (item.messageGroupID) {
              item.search = item.search + ' ' + item.messageGroupID;
            }
            if (item.cardID) {
              item.search = item.search + ' ' + item.cardID;
            }
            if (item.contactID) {
              item.search = item.search + ' ' + item.contactID;
            }
            if (item.merchantID) {
              item.search = item.search + ' ' + item.merchantID;
            }
            if (item.messageID) {
              item.search = item.search + ' ' + item.messageID;
            }
            if (item._id) {
              item.search = item.search + ' ' + item._id;
            }
            if (item['cardMobile']) {
              item.search = item.search + ' ' + this.customPhonePile.transform(item['cardMobile']).replace(/\s/g, '');
            }
            if (item['cardEmail']) {
              item.search = item.search + ' ' + item['cardEmail'];
            }

            if (item['status']) {
              item['statusColor'] = this.getStatusColor(item['status'], true);

              if (this.filterStatusArray.indexOf(item['status']) == -1) {
                this.filterStatusArray.push(item['status']);
              }
            }

            if (item['cardID'] && item['status']) {
              item['canCancel'] = this.messageUtil.canUpdateMessageGroupStatusLocal(item['status'], 'Canceled');
            }

            if (item['messageStatus']) {
              item['messageStatusColor'] = this.getStatusColor(item['messageStatus'], false);

              if (this.filterMessageStatusArray.indexOf(item['messageStatus']) == -1) {
                this.filterMessageStatusArray.push(item['messageStatus']);
              }
            }
          }
          return item;
        });

        this.messages = res;

        const innerFunction = (section) => {
          section = section + 1;
          p.section = section;

          this.serviceRef = this.messageService.getMessageGroupLocalListHidden(p, this.sessionType).subscribe((res) => {
            if (res.length > 0) {
              res = _.map(res, (item) => {
                if (item) {
                  item.search = '';
                  if (item._id) {
                    item.search = item.search + ' ' + item._id;
                  }
                  if (item.cardName) {
                    item.search = item.search + ' ' + item.cardName;
                  }
                  if (item.cardEmail) {
                    item.search = item.search + ' ' + item.cardEmail;
                  }
                  if (item.messageStatus) {
                    item.search = item.search + ' ' + item.messageStatus;
                  }
                  if (item.status) {
                    item.search = item.search + ' ' + item.status;
                  }

                  if (item.messageGroupID) {
                    item.search = item.search + ' ' + item.messageGroupID;
                  }
                  if (item.cardID) {
                    item.search = item.search + ' ' + item.cardID;
                  }
                  if (item.contactID) {
                    item.search = item.search + ' ' + item.contactID;
                  }
                  if (item.merchantID) {
                    item.search = item.search + ' ' + item.merchantID;
                  }
                  if (item.messageID) {
                    item.search = item.search + ' ' + item.messageID;
                  }
                  if (item._id) {
                    item.search = item.search + ' ' + item._id;
                  }
                  if (item['cardMobile']) {
                    item.search =
                      item.search + ' ' + this.customPhonePile.transform(item['cardMobile']).replace(/\s/g, '');
                  }
                  if (item['cardEmail']) {
                    item.search = item.search + ' ' + item['cardEmail'];
                  }
                  if (item['cardID'] && item['status']) {
                    item['canCancel'] = this.messageUtil.canUpdateMessageGroupStatusLocal(item['status'], 'Canceled');
                  }
                  if (item['status']) {
                    item['statusColor'] = this.getStatusColor(item['status'], true);

                    if (this.filterStatusArray.indexOf(item['status']) == -1) {
                      this.filterStatusArray.push(item['status']);
                    }
                  }

                  if (item['messageStatus']) {
                    item['messageStatusColor'] = this.getStatusColor(item['messageStatus'], false);

                    if (this.filterMessageStatusArray.indexOf(item['messageStatus']) == -1) {
                      this.filterMessageStatusArray.push(item['messageStatus']);
                    }
                  }
                }
                return item;
              });

              this.messages = this.messages.concat(res);

              if (this.isDestoyed != true) {
                innerFunction(section);
              }
            } else {
              return true;
            }
          });
        };

        if (this.isDestoyed != true) {
          innerFunction(1);
        }
      }
    });
  }

  isSelected(id) {
    if (id && this.selectedIDs.indexOf(id) !== -1) {
      return true;
    }

    return false;
  }

  selectAll() {
    if (this.messages && this.messages.length > 0) {
      let msg = this.filterArrayMultipleOrContain.transform(this.messages, 'search', this.filterData);

      msg = this.filterArrayMultipleOrContain.transform(msg, ['status'], this.filterStatus);

      msg = this.filterArrayMultipleOrContain.transform(msg, ['messageStatus'], this.filterMessageStatus);

      this.selectedIDs = msg.map((d) => {
        return d.id;
      });
    }
  }

  attachTagAll() {
    const ids = this.messages.map((d) => {
      return d.cardID;
    });

    if (ids.length > 0) {
      const ref = RootAppComponent.dialog.open(TagCreateEditCardComponent, {
        data: {
          cardIDs: ids,
          type: 'StaticFilter',

          merchantID: this.merchantID,
        },
        width: '650px',
      });

      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });

      ref.componentInstance.getResult.subscribe((res) => {
        if (res) {
          ref.close();
        }
      });
    }
  }
  attachTagByMessageStatus(s) {
    if (s) {
      let msg = this.filterArrayMultipleOrContain.transform(this.messages, ['messageStatus'], s);

      const ids = msg.map((d) => {
        return d.cardID;
      });

      if (ids.length > 0) {
        const ref = RootAppComponent.dialog.open(TagCreateEditCardComponent, {
          data: {
            cardIDs: ids,
            type: 'StaticFilter',

            merchantID: this.merchantID,
          },
          width: '650px',
        });

        ref.componentInstance.close.subscribe((res) => {
          ref.close();
        });

        ref.componentInstance.getResult.subscribe((res) => {
          if (res) {
            ref.close();
          }
        });
      }
    }
  }
  attachTagByStatus(s) {
    if (s) {
      let msg = this.filterArrayMultipleOrContain.transform(this.messages, ['status'], s);

      const ids = msg.map((d) => {
        return d.cardID;
      });

      if (ids.length > 0) {
        const ref = RootAppComponent.dialog.open(TagCreateEditCardComponent, {
          data: {
            cardIDs: ids,
            type: 'StaticFilter',

            merchantID: this.merchantID,
          },
          width: '650px',
        });

        ref.componentInstance.close.subscribe((res) => {
          ref.close();
        });

        ref.componentInstance.getResult.subscribe((res) => {
          if (res) {
            ref.close();
          }
        });
      }
    }
  }

  attachTagSelectedIDs() {
    let ids = [];

    let messages = _.filter(this.messages, (item) => {
      if (item && item.id && this.selectedIDs.indexOf(item.id) !== -1) {
        return true;
      }

      return false;
    });

    if (messages && messages.length > 0) {
      ids = messages.map((d) => {
        return d.cardID;
      });
    }

    if (ids.length > 0) {
      const ref = RootAppComponent.dialog.open(TagCreateEditCardComponent, {
        data: {
          cardIDs: ids,
          type: 'StaticFilter',

          merchantID: this.merchantID,
        },
        width: '650px',
      });

      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });

      ref.componentInstance.getResult.subscribe((res) => {
        if (res) {
          ref.close();
        }
      });
    }
  }

  diselectAll() {
    if (this.messages && this.messages.length > 0) {
      this.selectedIDs = [];
    }
  }

  selectMessage(id) {
    if (id) {
      let index = this.selectedIDs.indexOf(id);
      if (index != -1) {
        this.selectedIDs.splice(index, 1);
      } else {
        this.selectedIDs.push(id);
      }
    }
  }
  preview(message) {
    if (message && message.message) {
      const ref = RootAppComponent.dialog.open(SmsPreviewComponent, {
        data: {
          cardID: message.cardID,
          card: {
            ID: message.cardID,
            cardID: message.cardID,
            FirtName: message.cardName,
          },
          message: message.message,
        },
        panelClass: ['noCard', 'bigger-screen'],
        width: '375px',
      });
      ref.componentInstance.close.subscribe((data) => {
        ref.close();
      });
    } else if (message.id) {
      this.messageService.getMessageLocalDetails(message.id, {}, this.sessionType).subscribe((r) => {
        if (r && r.id && r.message) {
          let index = this.messages.findIndex((item) => {
            if (item && item.id && item.id == r.id) {
              return true;
            }

            return false;
          });

          if (index != -1) {
            this.messages[index] = r;
            this.messages = _.clone(this.messages);
          }

          const ref = RootAppComponent.dialog.open(SmsPreviewComponent, {
            data: {
              cardID: r.cardID,
              card: {
                ID: message.cardID,
                cardID: message.cardID,
                FirtName: message.cardName,
              },
              message: r.message,
            },
            panelClass: ['noCard', 'bigger-screen'],
            width: '375px',
          });
          ref.componentInstance.close.subscribe((data) => {
            ref.close();
          });
        }
      });
    }
  }

  openPatientViewDialogContainer(id) {
    if (id && this.isViewOnly == true) {
      this.openPatientViewDialog(id);
    }
  }

  openPatientViewDialog(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(CustomerProspectViewModelComponent, {
        data: {
          patientID: id,
          isFullDetails: false,
        },
        width: '700px',
      });

      ref.componentInstance.close.subscribe((res) => {
        if (res == true) {
          ref.close();
        }
      });
    }
  }

  ngOnDestroy() {
    this.isDestoyed = true;
    if (this.serviceRef) {
      this.serviceRef.unsubscribe();
    }
  }
  closeEvent() {
    this.close.emit(true);
  }
  changePage(e) {
    if (e) {
      this.currentPage = e;
    }
  }
  orderBy(d) {
    if (d) {
      if (this.orderByFilter === d) {
        this.orderByFilter = '-' + d;
      } else {
        this.orderByFilter = d;
      }
    }
  }

  getStatusColor(s, isStatus = true) {
    return this.messageUtil.getStatusColor(s, isStatus);
  }

  cancelGroup() {
    if (this.selectedIDs && this.selectedIDs.length > 0) {
      const confirm = new ConfirmDialog(
        this.statusIcons['Canceled'],
        '',
        'Are you sure you want to <strong>Cancel</strong> the selected  ' + this.messageLabel + 's ?',
        'No',
        'Yes'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation === false) {
          ref.close();
        } else {
          let p = {
            ids: this.selectedIDs,
            status: 'Canceled',
          };
          this.messageService.modifyMessageLocalGroup(p, this.sessionType).subscribe((res) => {
            if (res && res.length > 0) {
              res.map((r) => {
                if (r && r.id) {
                  if (r['messageStatus']) {
                    r['messageStatusColor'] = this.getStatusColor(r['messageStatus'], false);
                  }
                  if (r['status']) {
                    r['statusColor'] = this.getStatusColor(r['status'], true);
                  }

                  let index = this.messages.findIndex((item) => {
                    if (item && item.id && item.id == r.id) {
                      return true;
                    }

                    return false;
                  });

                  if (index != -1) {
                    this.messages[index] = r;
                  }

                  if (this.filterStatusArray.indexOf('Canceled') == -1) {
                    this.filterStatusArray.push('Canceled');
                  }
                }
              });

              this.selectedIDs = [];
              this.messages = _.clone(this.messages);
              ref.close();

              this.getRefresh.emit(true);
            } else {
              ref.close();
            }
          });
        }
      });
    }
  }
  cancel(card) {
    if (card && card.id) {
      const confirm = new ConfirmDialog(
        this.statusIcons['Canceled'],
        '',
        'Are you sure you want to <strong>Cancel</strong> this  ' + this.messageLabel + ' ?',
        'No',
        'Yes'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation === false) {
          ref.close();
        } else {
          this.messageService.modifyMessageLocal(card.id, { status: 'Canceled' }, this.sessionType).subscribe((r) => {
            if (r && r.id) {
              let index = this.messages.findIndex((item) => {
                if (item && item.id && item.id == r.id) {
                  return true;
                }

                return false;
              });

              if (index != -1) {
                if (r['messageStatus']) {
                  r['messageStatusColor'] = this.getStatusColor(r['messageStatus'], false);
                }
                if (r['status']) {
                  r['statusColor'] = this.getStatusColor(r['status'], true);
                }

                this.messages[index] = r;
                this.messages = _.clone(this.messages);
              }

              if (this.filterStatusArray.indexOf('Canceled') == -1) {
                this.filterStatusArray.push('Canceled');
              }

              this.getRefresh.emit(true);
              ref.close();
            } else {
              ref.close();
            }
          });
        }
      });
    }
  }

  refreshEvent() {
    this.messages = JSON.parse(JSON.stringify([]));

    this.setup();
    this.getRefresh.emit(true);
  }

  patientDetailedView(id) {
    if (id) {
      const confirm = new ConfirmDialog(
        'fas fa-info',

        'You are about to leave this page.',
        'Do you want to continue?',
        'Cancel',
        'Okay'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
        width: '500px',
      });

      ref.componentInstance.onConfirm.subscribe((res) => {
        if (res == true) {
          ref.close();
          this.router.navigate([
            '/merchant',
            {
              outlets: {
                page: ['customer-profile', id],
              },
            },
          ]);
        } else {
          ref.close();
        }
      });
    }
  }

  sendSMS(id, merchantID) {
    if (id && merchantID) {
      const ref2 = RootAppComponent.dialog.open(SmsPromoterComponent, {
        data: {
          cardID: id,
          merchantID: merchantID,
          singleChat: true,
          contractID: id,
        },
        panelClass: 'noCard',
        width: '500px',
      });
      ref2.componentInstance.close.subscribe((data) => {
        ref2.close();
      });

      ref2.componentInstance.getResult.subscribe((data) => {
        if (data) {
          const ref = RootAppComponent.dialog.open(SmsConversationComponent, {
            data,
            panelClass: 'bigger-screen',
            width: '70%',
            height: '95vh',
          });
          const sub = ref.componentInstance.close.subscribe((data) => {
            ref.close();
          });
        }

        ref2.close();
      });
    }
  }

  sendSMSMerchant(id) {
    if (id) {
      const ref2 = RootAppComponent.dialog.open(SmsConversationComponent, {
        data: {
          cardID: id,
          singleChat: true,
        },
        panelClass: 'bigger-screen',
        width: '70%',
        height: '95vh',
      });
      const sub = ref2.componentInstance.close.subscribe((data) => {
        ref2.close();
      });
    }
  }

  hardRefresh(messageID) {
    if (messageID) {
      this.messageService.messageGroupHardRefresh({ messageID: messageID }, this.sessionType).subscribe((res) => {
        if (res && res.id) {
          let item = res;
          if (item['messageStatus']) {
            item['messageStatusColor'] = this.getStatusColor(item['messageStatus'], false);
          }

          if (item['status']) {
            item['statusColor'] = this.getStatusColor(item['status'], true);
          }

          let index = this.messages.findIndex((d) => {
            if (d && d.id && d.id == item.id) {
              return true;
            }
            return false;
          });
          if (index != -1) {
            this.messages[index] = item;
          }

          this.messages = JSON.parse(JSON.stringify(this.messages));

          this.getRefresh.emit(true);

          NotifyAppComponent.displayToast('success', 'Successful operation', '');
        }
      });
    }
  }
}
