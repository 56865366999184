<div *ngIf="isAdminOrPromoter == true">
  <div class="row clearfix mb flex" *ngIf="hideMerchantSelect == false">
    <div class="half-width flex">
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>Please Select a Merchant</mat-label>
        <mat-select class="" [(ngModel)]="merchantID" name="MerchantName4" placeholder="">
          <mat-option [value]="null">
            All
          </mat-option>
          <mat-option *ngFor="let m of merchants | orderBy: ['TradingAs', 'CalculatedName']" [value]="m['ID']">
            <span
              class="list-label pull-right"
              *ngIf="m['Status'] && m['Status'] == 'Active'"
              [style.background]="'#00d07a'"
            >
              {{ m['Status'] }}
            </span>

            <span
              class="list-label pull-right"
              *ngIf="m['Status'] && m['Status'] != 'Active'"
              [style.background]="'#ff5722'"
            >
              {{ m['Status'] }}
            </span>
            {{ m['TradingAs'] || m['CalculatedName'] }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div class="full-width">
        <button
          class="merchBtn"
          *ngIf="merchantID && merchantID != 'none'"
          (click)="viewMerchant()"
          mat-icon-button
          color="primary"
        >
          <mat-icon>person</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <div class="row clearfix">
    <app-settlement-customer-list-global *ngIf="merchantID != 'none'" [merchantID]="merchantID" [pageSize]="pageSize">
    </app-settlement-customer-list-global>
  </div>
  <div class="row clearfix">
    <div class="pleaseChoose" *ngIf="merchantID == 'none'" @ngIfAnimation>
      <p>
        <strong>No  {{ 'KEYWORD.practice' | translate }} Selected</strong>
      </p>
      <img class="character_warning" />
      <p>Choose Desired  {{ 'KEYWORD.practice' | translate }}</p>
    </div>
  </div>
</div>

<div class="row full-width clearfix" *ngIf="isAdminOrPromoter == false">
  <app-settlement-customer-list [pageSize]="pageSize"></app-settlement-customer-list>
</div>
