<mat-card class="card" *ngIf="newArrangement == false">
  <div class="colour-stripe"></div>
  <div class="row flex title">
    <h3 class="sr-title sm-h3 rm-m">Arrangements</h3>

    <button (click)="newArrangement = !newArrangement" mat-raised-button color="accent">
      <mat-icon>add</mat-icon> New
    </button>
  </div>
  <hr/>

  <div class="row clearfix flex controls well">
    <p>Show:</p>

    <mat-checkbox> Proposed</mat-checkbox>

    <mat-checkbox> Active</mat-checkbox>

    <mat-checkbox> Complete</mat-checkbox>

    <mat-checkbox> Superceded</mat-checkbox>

    <mat-checkbox> Cancelled</mat-checkbox>

    <mat-checkbox> Failed</mat-checkbox>

    <mat-checkbox> Reverted</mat-checkbox>
  </div>

  <div class="row clearfix">
    <table class="full-width" [dataSource]="arrangementSource" mat-table>
      <ng-container matColumnDef="status">
        <th *matHeaderCellDef mat-header-cell>Status</th>
        <td *matCellDef="let waiver" mat-cell>{{ waiver.status }}</td>
      </ng-container>

      <ng-container matColumnDef="startDate">
        <th *matHeaderCellDef mat-header-cell>Start Date</th>
        <td *matCellDef="let waiver" mat-cell>{{ waiver.startDate | customCurrency }}</td>
      </ng-container>

      <ng-container matColumnDef="endDate">
        <th *matHeaderCellDef mat-header-cell>End Date</th>
        <td *matCellDef="let waiver" mat-cell>{{ waiver.endDate }}</td>
      </ng-container>

      <ng-container matColumnDef="payments">
        <th *matHeaderCellDef mat-header-cell>Payments</th>
        <td *matCellDef="let waiver" mat-cell>{{ waiver.payments }}</td>
      </ng-container>

      <ng-container matColumnDef="total1">
        <th *matHeaderCellDef mat-header-cell>Total</th>
        <td *matCellDef="let waiver" mat-cell>{{ waiver.total1 }}</td>
      </ng-container>

      <ng-container matColumnDef="underpaid">
        <th *matHeaderCellDef mat-header-cell>Underpaid</th>
        <td *matCellDef="let waiver" mat-cell>{{ waiver.underpaid }}</td>
      </ng-container>

      <ng-container matColumnDef="interest">
        <th *matHeaderCellDef mat-header-cell>Interest</th>
        <td *matCellDef="let waiver" mat-cell>{{ waiver.interest | customCurrency }}</td>
      </ng-container>

      <ng-container matColumnDef="shortfall">
        <th *matHeaderCellDef mat-header-cell>Shortfall</th>
        <td *matCellDef="let waiver" mat-cell>{{ waiver.shortfall }}</td>
      </ng-container>

      <ng-container matColumnDef="handleShortfall">
        <th *matHeaderCellDef mat-header-cell>Handle Shortfall</th>
        <td *matCellDef="let waiver" mat-cell>{{ waiver.handleShortfall }}</td>
      </ng-container>

      <ng-container matColumnDef="catchupPeroid">
        <th *matHeaderCellDef mat-header-cell>Catchup Period</th>
        <td *matCellDef="let waiver" mat-cell>{{ waiver.catchupPeroid }}</td>
      </ng-container>

      <ng-container matColumnDef="total2">
        <th *matHeaderCellDef mat-header-cell>Total</th>
        <td *matCellDef="let waiver" mat-cell>{{ waiver.total2 }}</td>
      </ng-container>

      <ng-container matColumnDef="endOfLoan">
        <th *matHeaderCellDef mat-header-cell>At End Of Loan</th>
        <td *matCellDef="let waiver" mat-cell>{{ waiver.endOfLoan }}</td>
      </ng-container>

      <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
      <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>
    </table>
  </div>
</mat-card>

<mat-card class="card rel" *ngIf="newArrangement == true">
  <div class="colour-stripe"></div>
  <app-contract-arrangement-modal></app-contract-arrangement-modal>

  <div class="row clearfix btns">
    <button class="pull-left" (click)="newArrangement = !newArrangement" mat-raised-button color="primary">
      Cancel
    </button>
  </div>
</mat-card>
