<div>
  <aw-wizard class="templateCreate" #wizard [disableNavigationBar]="true" navBarLayout="large-filled-symbols">
    <aw-wizard-step [navigationSymbol]="{ symbol: '1' }" stepTitle="Define template" awOptionalStep>
      <div class="container" id="step-0">
        <div class="row clearfix text-center">
          <h2 class="sr-title inline-block">
            <span *ngIf="isEditMode == false">Create New Template</span>
            <span *ngIf="isEditMode == true">Edit Template</span>
          </h2>
        </div>

        <mat-card class="card clearfix full-width">
          <div class="card-header primary-gradient-img clearfix inModal mb">
            <div class="row clearfix">
              <div class="row titleArea clearfix noPad">
                <h2 class="summary-header rm-m">
                  Enter template details
                  <!-- <span class="subLabel">{{'' | translate}}</span> -->
                </h2>
              </div>
            </div>
          </div>

          <div class="row clearfix" *ngIf="isPromoterOrAdmin == true && false">
            <p class="help-label">Choose which {{ "KEYWORD.practice" | translate | titlecase}} this new template belongs
              to</p>
            <div class="row clearfix selectMerchant flex">
              <mat-form-field class="full-width mb" appearance="outline">
                <mat-label>Please Select a Merchant</mat-label>
                <mat-select [(ngModel)]="merchantID" name="MerchantName">
                  <mat-option [value]="null"> My Account</mat-option>
                  <mat-option *ngFor="let m of merchants | orderBy: ['TradingAs', 'CalculatedName']" [value]="m['ID']">
                    <span
                      class="list-label pull-right"
                      *ngIf="m['Status'] && m['Status'] == 'Active'"
                      [style.background]="'#00d07a'"
                    >
                      {{ m['Status'] }}
                    </span>

                    <span
                      class="list-label pull-right"
                      *ngIf="m['Status'] && m['Status'] != 'Active'"
                      [style.background]="'#ff5722'"
                    >
                      {{ m['Status'] }}
                    </span>
                    {{ m['TradingAs'] || m['CalculatedName'] }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <div class="full-width">
                <button
                  class="pull-right merchBtn"
                  *ngIf="merchantID && merchantID != 'none'"
                  (click)="viewMerchant()"
                  mat-raised-button
                  color="primary"
                >
                  <mat-icon>person</mat-icon>
                  <span class="mobHide">View Merchant</span>
                </button>
              </div>
            </div>
          </div>
          <form #templateForm="ngForm">
            <div class="row clearfix">
              <h3 class="sr-title sm-h3">Template Details</h3>

              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Label</mat-label>
                <input
                  [(ngModel)]="template.templateLabel"
                  name="templateLabel"
                  matInput
                  placeholder="Label"
                  required
                />
              </mat-form-field>

              <mat-form-field
                class="full-width"
                [ngClass]="{ 'mat-error': isDuplicateTag == true }"
                appearance="outline"
              >
                <mat-label>Template keyword or ID (unique)</mat-label>
                <input [(ngModel)]="template.templateTag" name="templateTag" matInput placeholder="Tag" required/>

                <mat-hint class="errorHint mb" *ngIf="isDuplicateTag == true">Please enter an unique label</mat-hint>
              </mat-form-field>

              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Template Purpose</mat-label>
                <mat-select
                  class="full-width"
                  [(ngModel)]="template.templatePurpose"
                  (selectionChange)="getTemplateForList()"
                  name="type"
                  required
                >
                  <mat-option *ngFor="let p of templatePurposeList" [value]="p">
                    {{ p.Label }}
                  </mat-option>
                  <hr/>
                  <mat-option [value]="null" (click)="createNewType()"> Create new type</mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field class="full-width" *ngIf="template.templatePurpose != null" appearance="outline">
                <mat-label>Template For</mat-label>
                <mat-select
                  class="full-width"
                  [(ngModel)]="template.templateFor"
                  (selectionChange)="getTemplateTargetList()"
                  name="templateFor"
                  required
                >
                  <mat-option *ngFor="let f of templateForList" [value]="f">
                    {{ f }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field class="full-width" *ngIf="template.templateFor != null" appearance="outline">
                <mat-label>Template Target</mat-label>
                <mat-select class="full-width" [(ngModel)]="template.templateTarget" name="target" required>
                  <mat-option *ngFor="let t of templateTargetList" [value]="t">
                    {{ t }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <h3 class="sr-title sm-h3">Template type</h3>

              <div class="full-width flex mt mb">
                <div class="clearfix full-width">
                  <mat-slide-toggle class="clearfix" [(ngModel)]="isDefault" name="isDefault"
                  >Publis this template
                  </mat-slide-toggle>
                </div>

                <div class="clearfix full-width">
                  <mat-slide-toggle class="clearfix" [(ngModel)]="marketingTemplate" name="marketingTemplate"
                  >It is a marketing Template
                  </mat-slide-toggle>
                </div>
              </div>
            </div>
          </form>
        </mat-card>

        <div class="row clearfix text-center mt">
          <button
            class="inline btn-new-invite btn-large"
            [disabled]="!templateForm.form.valid"
            (click)="checkTemplateTag()"
            type="button"
            mat-raised-button
            color="accent"
          >
            Next
            <mat-icon>chevron_right</mat-icon>
          </button>
        </div>
      </div>
    </aw-wizard-step>

    <aw-wizard-step class="mb" [navigationSymbol]="{ symbol: '2' }" stepTitle="Template Content">
      <div class="container" id="step-1">
        <mat-card class="card full-width">
          <div class="colour-stripe"></div>

          <div class="row clearfix">
            <button
              class="pull-right"
              *ngIf="isEditMode == true && templateID"
              (click)="viewTemplateEdit()"
              color="primary"
              mat-raised-button
            >
              <mat-icon>find_in_page</mat-icon>
              <span>View Current Template</span>
            </button>

            <h3 class="sr-title rm-m sm-h3">Template body</h3>

            <p class="small">
              Please choose how do you want to build your template body, either use the Editor or you can use Raw HTML
              and insert you page there
            </p>

            <div class="text-center full-width clearfix mb"></div>
          </div>

          <div class="row clearfix mt">
            <div class="reduce-padding mb clearfix mat-elevation-z1 full-width white-background">
              <div class="toggle-card row clearfix full-width mb">
                <mat-button-toggle-group class="btn-group row clearfix full-width text-center" [(ngModel)]="inputType">
                  <mat-button-toggle class="text-center qtr-width pull-left" [value]="'upload'">
                    Upload File
                  </mat-button-toggle>

                  <mat-button-toggle class="text-center qtr-width pull-left" [value]="'raw'">
                    HTML Input
                  </mat-button-toggle>

                  <mat-button-toggle class="text-center qtr-width pull-left" [value]="'editor'">
                    Editor
                  </mat-button-toggle>
                </mat-button-toggle-group>
              </div>

              <div class="full-width clearfix" *ngIf="inputType == 'upload'">
                <p class="ml">Upload EDM file , only accept .html</p>

                <div class="full-width text-center">
                  <app-file-uploader
                    class="text-center uploader"
                    [documentType]="'Email Body'"
                    [allowedExtensions]="allowedExtensionsHTML"
                    [fileName]="'email_html_body'"
                    [isGallery]="false"
                    [isDragable]="true"
                    (onCompleteAll)="onCompleteAllHTML($event)"
                  >
                  </app-file-uploader>
                </div>

                <div class="full-width text-center mb ml mr">
                  <app-file-list
                    class=""
                    [defaultLabel]="'Email Body'"
                    [files]="[emailBodyFileID]"
                    [canGallery]="false"
                    [canPrivateDocument]="false"
                    [canEdit]="false"
                    [canAction]="false"
                  >
                  </app-file-list>
                </div>
              </div>

              <div class="full-width clearfix" *ngIf="inputType == 'raw'">
                <p class="ml">
                  Use this component to create your EDM , you can preview the result in real time on the panel in the
                  right
                </p>

                <div class="full-width clearfix flex">
                  <div class="full-width mr clearfix">
                    <ngx-monaco-editor class="min-height-html" [(ngModel)]="content" [options]="editorOptions">
                    </ngx-monaco-editor>
                  </div>

                  <div class="full-width ml min-height">
                    <div
                      class="full-width clearfix"
                      class="small"
                      *ngIf="content"
                      [innerHTML]="content | safeHtml"
                    ></div>
                  </div>
                </div>
              </div>

              <div class="full-width clearfix" [hidden]="inputType != 'editor'">
                <p class="ml">
                  >Use the following component to create your EDM, drag and drop components from the panel in the right
                </p>

                <div class="full-width clearfix">
                  <email-editor [options]="options"></email-editor>
                </div>

                <div class="full-width clearfix mt mb ml">
                  <div class="form-group">
                    <label for="file">Import template from local file -- Only accept .json file</label>
                    <input
                      class="btn"
                      id="file"
                      (change)="handleFileInput($event.target.files)"
                      type="file"
                      accept=".json"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-card>

        <div class="text-center full-width clearfix mb">
          <button class="pull-left" type="button" mat-raised-button color="primary" awPreviousStep>
            <mat-icon>chevron_left</mat-icon>
            Back
          </button>

          <button
            class="text-center ml mr"
            *ngIf="isEditMode == true && templateID"
            (click)="keepCurrentTemplate()"
            type="button"
            color="accent"
            mat-raised-button
          >
            Keep current email
            <mat-icon>check_circle</mat-icon>
          </button>

          <button
            class="pull-right ml"
            [disabled]="
              (inputType == 'editor' && !template.templateSubject) ||
              (inputType == 'raw' && !content) ||
              (inputType == 'upload' && !emailBodyFileID)
            "
            (click)="createNewTemplate()"
            type="button"
            color="accent"
            mat-raised-button
          >
            Next
            <mat-icon>chevron_right</mat-icon>
          </button>

          <button
            class="pull-right ml"
            *ngIf="inputType == 'editor'"
            [matMenuTriggerFor]="exportMenu"
            mat-raised-button
            color="warn"
          >
            Export
            <mat-icon class="fas fa-external-link-alt"></mat-icon>
          </button>
          <mat-menu #exportMenu="matMenu">
            <button (click)="exportHtml()" mat-menu-item>
              <mat-icon class="fas fa-code"></mat-icon>
              Export HTML
            </button>
            <button (click)="exportJson()" mat-menu-item>
              <mat-icon class="fas fa-stream"></mat-icon> Export JSON
            </button>
          </mat-menu>

          <!-- <button mat-raised-button class="pull-right ml" color="accent" (click)="editorLoaded()">Load</button> -->
        </div>
      </div>
    </aw-wizard-step>

    <aw-wizard-step [navigationSymbol]="{ symbol: '3' }" stepTitle="Template Data">
      <form #templateForm2="ngForm">
        <div class="container" id="step-33">
          <mat-card class="card full-width">
            <div class="full-width flex clearfix">
              <div class="thrd-width clearfix text-center mr">
                <div class="colour-stripe"></div>
                <h3 class="sr-title sm-h3">Template thumbnail</h3>

                <div class="row clearfix logo-img image rel">
                  <div
                    class="displayUploadImg text-center clearfix"
                    *ngIf="isTemplatePic == false && isEditMode == true"
                  >
                    <app-ep-document-view-image
                      class="clearfix profile-pic-container"
                      [defaultPicture]="
                        'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/marketing-place-holder.png)'
                      "
                      [link]="templatePicLink"
                      [hideZoomButton]="false"
                    >
                    </app-ep-document-view-image>
                  </div>
                  <div class="displayUploadImg text-center clearfix" *ngIf="isTemplatePic == true && picSrc">
                    <app-ep-document-view-image
                      class="clearfix profile-pic-container"
                      [defaultPicture]="
                        'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/marketing-place-holder.png)'
                      "
                      [link]="picSrc"
                      [hideZoomButton]="false"
                    >
                    </app-ep-document-view-image>
                  </div>

                  <div
                    class="displayUploadImg text-center clearfix"
                    *ngIf="isTemplatePic == false && isEditMode != true"
                  >
                    <app-ep-document-view-image
                      class="clearfix profile-pic-container"
                      [defaultPicture]="
                        'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/marketing-place-holder.png)'
                      "
                      [hideZoomButton]="false"
                    >
                    </app-ep-document-view-image>
                  </div>

                  <div class="uploader full-width clearfix">
                    <!-- <h4 class="sr-title sm-h4 rm-mb">Upload product logo</h4> -->
                    <app-file-uploader
                      class="text-center clearfix full-width"
                      [isFixedAspectRatio]="false"
                      [isImageCropping]="true"
                      [canSkip]="false"
                      [limit]="1"
                      [uploadDirectly]="true"
                      [isDragable]="true"
                      [title]="'Click to upload thumbnail'"
                      (onCompleteAll)="onCompleteAll($event)"
                    >
                    </app-file-uploader>
                  </div>
                </div>
              </div>

              <div class="full-width clearfix ml">
                <div class="colour-stripe"></div>
                <h3 class="sr-title sm-h3">Template details</h3>

                <mat-form-field class="full-width animate" appearance="outline">
                  <mat-label>Email subject </mat-label>
                  <textarea
                    class="mat-block mini"
                    #subjectID
                    [(ngModel)]="template.templateSubject"
                    (keyup)="getcursorPosSubject()"
                    (mouseup)="getcursorPosSubject()"
                    matInput
                    placeholder="the subject line your recipients will see in the email"
                    name="templateSubject"
                    required
                  ></textarea>
                </mat-form-field>

                <div class="row clearfix">
                  <button class="pull-left" [matMenuTriggerFor]="persMenu1" mat-button color="primary">
                    <mat-icon class="fas fa-share"></mat-icon> Insert personalisation
                  </button>
                  <!-- patient personalisation message -->
                  <mat-menu #persMenu1="matMenu">
                    <button [matMenuTriggerFor]="patientPersMenu1"
                            mat-menu-item>{{ "KEYWORD.patient" | translate | titlecase }}</button>
                    <mat-menu #patientPersMenu1="matMenu">
                      <div *ngFor="let p of recipientPlaceHolder">
                        <button (click)="addPersonalisationSubject(p.code)" mat-menu-item>
                          {{ p.label }}
                        </button>
                      </div>
                    </mat-menu>


                    <button [matMenuTriggerFor]="dentistPersMenu1"
                            mat-menu-item>{{ "KEYWORD.practice" | translate | titlecase}}</button>
                    <mat-menu #dentistPersMenu1="matMenu">
                      <div *ngFor="let p of merchantPlaceHolder">
                        <button (click)="addPersonalisationSubject(p.code)" mat-menu-item>
                          {{ p.label }}
                        </button>
                      </div>
                    </mat-menu>

                    <!-- practice personalisation message -->
                    <button *ngIf="marketingTemplate == true" [matMenuTriggerFor]="practicePersMenu1" mat-menu-item>
                      {{ "KEYWORD.practitioner" | translate | titlecase }}
                    </button>
                    <mat-menu #practicePersMenu1="matMenu">
                      <div *ngFor="let p of dentistPlaceHolder">
                        <button (click)="addPersonalisationSubject(p.code)" mat-menu-item>
                          {{ p.label }}
                        </button>
                      </div>
                    </mat-menu>

                    <button *ngIf="marketingTemplate == true" [matMenuTriggerFor]="campaignPlaceHolder1" mat-menu-item>
                      Campaign
                    </button>
                    <mat-menu #campaignPlaceHolder1="matMenu">
                      <div *ngFor="let p of campaignPlaceHolder">
                        <button (click)="addPersonalisationSubject(p.code)" mat-menu-item>
                          {{ p.label }}
                        </button>
                      </div>
                    </mat-menu>
                  </mat-menu>
                </div>
                <h3 class="sr-title sm-h3">Call to Action</h3>

                <mat-form-field class="full-width" appearance="outline">
                  <mat-label> Call to Action (CTA)</mat-label>
                  <input
                    [(ngModel)]="template.actionCallLabel"
                    name="actionCallLabel"
                    matInput
                    placeholder="Call to Action (CTA)"
                  />
                </mat-form-field>

                <mat-form-field class="full-width animate" appearance="outline">
                  <mat-label>Description/Preview </mat-label>
                  <textarea
                    class="mat-block mini"
                    [(ngModel)]="template.actionCallDescription"
                    [maxLength]="350"
                    matInput
                    placeholder="Description/Preview"
                    name="actionCallDescription"
                  ></textarea>

                  <mat-hint class="pull-right" *ngIf="template && template.actionCallDescription" align="end"
                  >Text length: {{ template.actionCallDescription?.length || 0 }}/ 350
                  </mat-hint>
                </mat-form-field>

                <div class="full-width row clearfix mt mb">
                  <div class="full-width text-left mb" *ngIf="!tags || (tags && tags.length <= 0)">
                    <button class="pull-right merchBtn mt" (click)="createNewTag()" mat-raised-button color="primary">
                      <mat-icon>add</mat-icon> New Tag
                    </button>
                    <p>
                      No tags are found - Tags are applied to {{ "KEYWORD.patient" | translate }}s and used in marketing
                      to create audiences and
                      query {{ "KEYWORD.patient" | translate }}s.
                    </p>
                  </div>

                  <app-tag-list
                    [tags]="tags"
                    [openPosition]="'above'"
                    [title]="'Template Tags'"
                    [selectedTags]="defaultSelectedTags"
                    [tagFormatIsObject]="true"
                    [canRemove]="true"
                    [canCreate]="true"
                    [directRemoveDefault]="true"
                    [add]="addTag"
                    [hasFilter]="true"
                    [canSelect]="false"
                    [remove]="removetag"
                    (getRemovedTag)="getRemovedTag($event)"
                    (getCreate)="createNewTag()"
                    (getSelectedTags)="getSelectedTags($event)"
                  >
                  </app-tag-list>
                </div>

                <h3 class="sr-title sm-h3">Landing page</h3>

                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Landing page code </mat-label>
                  <input
                    [(ngModel)]="template.templateLandingPageCode"
                    name="landingPageCode"
                    matInput
                    placeholder="Code from public site  landing Page"
                  />
                </mat-form-field>

                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Landing page link (Not required if you insert the page code)</mat-label>
                  <input
                    [(ngModel)]="template.templateLandingPageURL"
                    name="landingPageLink"
                    matInput
                    placeholder="Link to the landing page"
                  />
                </mat-form-field>

                <div class="row clearfix">
                  <button class="pull-left" [matMenuTriggerFor]="persMenu10" mat-button color="primary">
                    <mat-icon class="fas fa-share"></mat-icon> Insert personalisation
                  </button>
                  <!-- patient personalisation message -->
                  <mat-menu #persMenu10="matMenu">
                    <button *ngFor="let p of LinkPlaceHolder" (click)="addPersonalisationlink(p.code)" mat-menu-item>
                      <span> {{ p.label }}</span>
                    </button>
                  </mat-menu>
                </div>

                <hr class="mb"/>

                <div class="clearfix full-width mb">
                  <mat-slide-toggle class="clearfix" [(ngModel)]="template.supportSMS" name="supportSMS"
                  >Does this template support SMS ?
                  </mat-slide-toggle>
                </div>
                <mat-form-field class="full-width animate" *ngIf="template.supportSMS == true" appearance="outline">
                  <mat-label>SMS Content</mat-label>
                  <textarea
                    class="mat-block mini"
                    #smsBodyID
                    [(ngModel)]="template.smsBody"
                    (keyup)="getcursorPosSMS()"
                    (mouseup)="getcursorPosSMS()"
                    matInput
                    placeholder="SMS Content"
                    name="smsBody"
                  ></textarea>

                  <mat-hint class="error-color mt" align="end"
                  >Post length:
                    <strong> {{ template.smsBody?.length || 0 }} </strong>
                  </mat-hint>
                </mat-form-field>
                <div class="row clearfix" *ngIf="template.supportSMS == true">
                  <button class="pull-left" [matMenuTriggerFor]="persMenu2" mat-button color="primary">
                    <mat-icon class="fas fa-share"></mat-icon> Insert personalisation
                  </button>
                  <!-- patient personalisation message -->
                  <mat-menu #persMenu2="matMenu">
                    <button [matMenuTriggerFor]="patientPersMenu2"
                            mat-menu-item>{{ "KEYWORD.patient" | translate | titlecase }}</button>
                    <mat-menu #patientPersMenu2="matMenu">
                      <div *ngFor="let p of recipientPlaceHolder">
                        <button (click)="addPersonalisationSMS(p.code)" mat-menu-item>
                          {{ p.label }}
                        </button>
                      </div>
                    </mat-menu>


                    <button [matMenuTriggerFor]="dentistPersMenu2"
                            mat-menu-item>{{ "KEYWORD.practice" | translate | titlecase}}</button>
                    <mat-menu #dentistPersMenu2="matMenu">
                      <div *ngFor="let p of merchantPlaceHolder">
                        <button (click)="addPersonalisationSMS(p.code)" mat-menu-item>
                          {{ p.label }}
                        </button>
                      </div>
                    </mat-menu>

                    <!-- practice personalisation message -->
                    <button *ngIf="marketingTemplate == true" [matMenuTriggerFor]="practicePersMenu2" mat-menu-item>
                      {{ "KEYWORD.practitioner" | translate | titlecase }}
                    </button>
                    <mat-menu #practicePersMenu2="matMenu">
                      <div *ngFor="let p of dentistPlaceHolder">
                        <button (click)="addPersonalisationSMS(p.code)" mat-menu-item>
                          {{ p.label }}
                        </button>
                      </div>
                    </mat-menu>

                    <button *ngIf="marketingTemplate == true" [matMenuTriggerFor]="campaignPlaceHolder2" mat-menu-item>
                      Campaign
                    </button>
                    <mat-menu #campaignPlaceHolder2="matMenu">
                      <div *ngFor="let p of campaignPlaceHolder">
                        <button (click)="addPersonalisationSMS(p.code)" mat-menu-item>
                          {{ p.label }}
                        </button>
                      </div>
                    </mat-menu>
                  </mat-menu>
                </div>

                <h3 class="sr-title sm-h3">Meta Data</h3>
                <p class="small">
                  Select Meta data linked to the template , it is used
                  when {{ "KEYWORD.practice" | translate | titlecase}} want to
                  use this template on a
                  campaign .
                </p>

                <div class="full-width flex mb">
                  <div class="clearfix thrd-width">
                    <mat-slide-toggle class="clearfix" [(ngModel)]="metaDataFinance" name="metaDataFinance"
                    > {{"KEYWORD.Treatment" | translate}} Price
                    </mat-slide-toggle>
                  </div>

                  <div class="clearfix thrd-width">
                    <mat-slide-toggle class="clearfix" [(ngModel)]="metaDataProductGroup" name="metaDataProductGroup">
                      Finance Offered
                    </mat-slide-toggle>
                  </div>
                </div>
                <div class="full-width flex mb">
                  <div class="clearfix thrd-width">
                    <mat-slide-toggle
                      class="clearfix"
                      [(ngModel)]="metaDataBestContactTime"
                      name="metaDataBestContactTime"
                    >
                      Best contact time list
                    </mat-slide-toggle>
                  </div>
                  <div class="clearfix thrd-width">
                    <mat-slide-toggle class="clearfix" [(ngModel)]="metaDataSalutation" name="metaDataSalutation">
                      Salutation list
                    </mat-slide-toggle>
                  </div>

                  <div class="clearfix thrd-width mb">
                    <mat-slide-toggle class="clearfix" [(ngModel)]="metaDataComment" name="metaDataComment"
                    >Comment text area
                    </mat-slide-toggle>
                  </div>
                </div>
              </div>
            </div>
          </mat-card>

          <div class="row clearfix text-center mt full-width">
            <button class="pull-left" type="button" mat-raised-button color="primary" awPreviousStep>
              <mat-icon>chevron_left</mat-icon>
              Back
            </button>

            <button
              class="pull-right ml"
              *ngIf="isEditMode == false"
              [disabled]="!templateForm2.form.valid"
              (click)="saveDesign(false)"
              mat-raised-button
              color="accent"
            >
              Create
              <mat-icon>chevron_right</mat-icon>
            </button>

            <button
              class="pull-right ml"
              *ngIf="isEditMode == true"
              [disabled]="!templateForm2.form.valid"
              (click)="saveDesign(true)"
              mat-raised-button
              color="accent"
            >
              Save
              <mat-icon>chevron_right</mat-icon>
            </button>
          </div>
        </div>
      </form>
    </aw-wizard-step>

    <aw-wizard-completion-step [navigationSymbol]="{ symbol: '4' }" stepTitle="Save">
      <div class="container" id="step-4">
        <mat-card class="card clearfix full-width">
          <div class="row clearfix">
            <div class="full-width text-center">
              <h1 class="sm-h1 sr-title">Well Done!</h1>
              <div class="row clearfix">
                <div class="svg-box">
                  <svg class="circular green-stroke">
                    <circle class="path" cx="75" cy="75" r="50" fill="none" stroke-width="5" stroke-miterlimit="10" />
                  </svg>
                  <svg class="checkmark green-stroke">
                    <g transform="matrix(0.79961,8.65821e-32,8.39584e-32,0.79961,-489.57,-205.679)">
                      <path class="checkmark__check" fill="none" d="M616.306,283.025L634.087,300.805L673.361,261.53" />
                    </g>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </mat-card>

        <div class="row clearfix text-center button-row">
          <button
            class="step-back ml mr"
            [routerLink]="['/merchant/', { outlets: { page: ['template-list-overview'] } }]"
            mat-raised-button
            color="primary"
          >
            <mat-icon>keyboard_arrow_left</mat-icon>
            Template List
          </button>

          <button class="step-next ml mr" (click)="viewTemplate()" mat-raised-button color="accent">
            <mat-icon>visibility</mat-icon>
            View Template
          </button>
        </div>
      </div>
    </aw-wizard-completion-step>
  </aw-wizard>
</div>
