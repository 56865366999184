<div>
  <div class="rel" *ngIf="existingPatientsDB.firstLoadEvent == false" @ngIfAnimation>
    <div class="row clearfix padded rel">
      <h1 class="dataHeader rm-mt transparent">Fetching data...</h1>
    </div>
  </div>

  <!-- <div *ngIf="existingPatientsDB.data.length == 0">
  <div class="row clearfix">
    <h1 class="dataHeader rm-mt rm-mb pull-left">
      ...
    </h1>
  </div>
  <mat-card class="list-card skeltal rel">
    <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
  </mat-card>
</div> -->
  <!-- doot doot -->

  <div [hidden]="existingPatientsDB.data.length == 0">
    <div class="row clearfix">
      <p class="pull-right" *ngIf="dateFrom != null || dateTo != null">
        <span *ngIf="dateFrom"
        >From:
          <strong>{{ dateFrom | customDateTime }} </strong>
        </span>

        <span *ngIf="dateTo">
          To:
          <strong>{{ dateTo | customDateTime }}</strong>
        </span>
      </p>

      <h1 class="dataHeader rm-mt rm-mb pull-left">
        {{ typeLabel | translate }}:
        <span class="badge" [endVal]="dataSource.filteredData.length" countUp></span>
      </h1>

      <button
        class="btn-small pull-right"
        id="newPatient"
        [routerLink]="['/merchant/', { outlets: { page: ['existing-patient-create'] } }]"
        mat-raised-button
        color="accent"
      >
        <mat-icon>person_add</mat-icon>
        New {{ "KEYWORD.patient" | translate | titlecase }}
      </button>
    </div>

    <mat-card class="list-card">
      <!-- search -->
      <div class="row clearfix mb searchArea primary-gradient-img">
        <div class="row clearfix">
          <div class="half-width" id="search">
            <mat-icon class="schIcon">search</mat-icon>
            <mat-select class="schSel" [formControl]="searchVals" matInput placeholder="Add Filters" multiple>
              <mat-select-trigger>
                {{ searchVals.value ? searchVals.value[0] : '' }}
                <span class="additional-selection" *ngIf="searchVals.value?.length > 1">
                  (+{{ searchVals.value.length - 1 }} others)
                </span>
              </mat-select-trigger>
              <mat-option *ngFor="let searchVal of searchValList" [value]="searchVal">{{ searchVal }}</mat-option>
            </mat-select>
          </div>
        </div>
        <div class="row clearfix flex well miniWell animated fadeInDown" *ngIf="searchVals.value?.length > 0">
          <app-date-input
            class="margin-btm"
            *ngIf="searchVals.value?.indexOf('Date Of Birth') !== -1"
            [startView]="'multi-year'"
            [isClear]="true"
            (selectedDate)="setFilter($event.target.value, 'DateOfBirth')"
            name="DateOfBirth"
          ></app-date-input>
          <mat-form-field class="margin-btm" *ngIf="searchVals.value?.indexOf('First Name') !== -1">
            <mat-label>First Name</mat-label>
            <input
              class="clearfix"
              (input)="setFilter($event.target.value, 'FirstName')"
              name="FirstName"
              placeholder="First Name"
              matInput
            />
          </mat-form-field>
          <mat-form-field class="margin-btm" *ngIf="searchVals.value?.indexOf('Last Name') !== -1">
            <mat-label>Last Name</mat-label>
            <input
              class="clearfix"
              (input)="setFilter($event.target.value, 'LastName')"
              name="LastName"
              placeholder="Last Name"
              matInput
            />
          </mat-form-field>
          <mat-form-field class="margin-btm" *ngIf="searchVals.value?.indexOf('Email') !== -1">
            <mat-label>Email</mat-label>
            <input
              class="clearfix"
              (input)="setFilter($event.target.value, 'Email')"
              name="Email"
              placeholder="Email"
              matInput
            />
          </mat-form-field>
          <mat-form-field class="margin-btm" *ngIf="searchVals.value?.indexOf('Mobile') !== -1">
            <mat-label>Mobile</mat-label>
            <input
              class="clearfix"
              (input)="setFilter($event.target.value, 'Mobile')"
              name="Mobile"
              placeholder="Mobile"
              matInput
            />
          </mat-form-field>
          <mat-form-field class="margin-btm" *ngIf="searchVals.value?.indexOf('Invitations') !== -1">
            <mat-label>Invitations</mat-label>
            <input
              class="clearfix"
              (input)="setFilter($event.target.value, 'AsInvitation')"
              name="Invitations"
              placeholder="Invitations"
              matInput
            />
          </mat-form-field>
          <mat-form-field class="margin-btm" *ngIf="searchVals.value?.indexOf('Customer') !== -1">
            <mat-label>Customer</mat-label>
            <input
              class="clearfix"
              (input)="setFilter($event.target.value, 'AsCustomer')"
              name="Customer"
              placeholder="Customer"
              matInput
            />
          </mat-form-field>
          <mat-form-field class="margin-btm" *ngIf="searchVals.value?.indexOf('Prospect') !== -1">
            <mat-label>Prospect</mat-label>
            <input
              class="clearfix"
              (input)="setFilter($event.target.value, 'AsProspect')"
              name="Prospect"
              placeholder="Prospect"
              matInput
            />
          </mat-form-field>
        </div>
      </div>

      <mat-table class="list-table" #table [dataSource]="dataSource" matSort>
        <!--- Note that these columns can be defined in any order.
                The actual rendered columns are set as a property on the row definition" -->
        <!-- ID Column -->

        <!-- Progress Column -->
        <ng-container cdkColumnDef="LastName">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header>Last Name</mat-header-cell>
          <mat-cell
            *cdkCellDef="let row"
            (click)="openQuickViewDialog(row['ID'])"
            matTooltip="{{ row.CalculatedName | lowercase | ucfirst }}"
          >
            {{ row.LastName | lowercase | ucfirst }}</mat-cell
          >
        </ng-container>
        <ng-container cdkColumnDef="FirstName">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> First Name</mat-header-cell>
          <mat-cell
            *cdkCellDef="let row"
            (click)="openQuickViewDialog(row['ID'])"
            matTooltip="{{ row.CalculatedName | lowercase | ucfirst }}"
          >
            {{ row.FirstName | lowercase | ucfirst }}</mat-cell
          >
        </ng-container>

        <!-- Name Column -->
        <ng-container cdkColumnDef="DateOfBirth">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header>Date of birth</mat-header-cell>
          <mat-cell
            *cdkCellDef="let row"
            (click)="openQuickViewDialog(row['ID'])"
            matTooltip="{{ row.DateOfBirth | customDate }}"
          >
            {{ row.DateOfBirth | customDate }}
          </mat-cell>
        </ng-container>
        <!-- Name Column -->

        <!-- Color Column -->
        <ng-container cdkColumnDef="mobile">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header>Phone/Mobile</mat-header-cell>
          <mat-cell
            *cdkCellDef="let row"
            (click)="openQuickViewDialog(row['ID'])"
            matTooltip="{{ row['Mobile'] | customPhone }}"
          >
            {{ row['Mobile'] | customPhone }}
          </mat-cell>
        </ng-container>
        <!-- Color Column -->
        <ng-container cdkColumnDef="email">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header>Email Address</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="openQuickViewDialog(row['ID'])" matTooltip="{{ row['Email'] }}">
            {{ row['Email'] }}
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="asCustomer">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header>{{ 'Customers' | translate }}</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="openQuickViewDialog(row['ID'])" matTooltip="{{ row.AsCustomer }}">
            {{ row.AsCustomer }}
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="asProspect">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header>Prospects</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="openQuickViewDialog(row['ID'])" matTooltip="{{ row.AsProspect }}">
            {{ row.AsProspect }}
          </mat-cell>
        </ng-container>

        <!-- <ng-container cdkColumnDef="asInvitation">
        <mat-header-cell *cdkHeaderCellDef mat-sort-header>{{"Invitations" | translate}}</mat-header-cell>
        <mat-cell *cdkCellDef="let row" matTooltip="{{row.AsInvitation  }}"> {{row.AsInvitation }} </mat-cell>
      </ng-container> -->

        <!-- Action Button Column -->
        <ng-container cdkColumnDef="Actions">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header>Actions</mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <div style="float: right">
              <button [matMenuTriggerFor]="menu" mat-icon-button>
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button (click)="openQuickViewDialog(row['ID'])" mat-menu-item>
                  <mat-icon>portrait</mat-icon>
                  <span>Quick View</span>
                </button>
                <button *ngIf="isPromoterOrAdmin == true" (click)="populateDetailCard(row['ID'])" mat-menu-item>
                  <mat-icon class="fas fa-address-card"></mat-icon>
                  <span>View Details Card</span>
                </button>

                <button *ngIf="row['AsInvitation'] != '0'" (click)="patientInvitation(row['ID'])" mat-menu-item>
                  <mat-icon>portrait</mat-icon>
                  <span>View {{ "KEYWORD.patient" | translate | titlecase }} Invitations</span>
                </button>

                <button *ngIf="row['Email']" (click)="contact(row['ID'])" mat-menu-item>
                  <mat-icon>contact_mail</mat-icon>
                  <span
                  >Contact {{ row.LastName | lowercase | ucfirst }}
                    {{ row.FirstName | lowercase | ucfirst }}</span
                  >
                </button>

                <button
                  *ngIf="
                    row['ID'] &&
                    row['ID'] != '00000000000000000000000000000000' &&
                    row['ID'] != '20202020202020202020202020202020'
                  "
                  (click)="patientLog(row['ExistingPatientID'])"
                  mat-menu-item
                >
                  <mat-icon class="fa fa-list-alt"></mat-icon>
                  <span>View {{ "KEYWORD.patient" | translate }} logs</span>
                </button>

                <button (click)="linkToInvitation(row)" mat-menu-item>
                  <mat-icon>email</mat-icon>
                  <span>New Invitation</span>
                </button>
              </mat-menu>
            </div>
          </mat-cell>
        </ng-container>
        <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *cdkRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
      <mat-paginator
        #paginator
        [length]="dataSource.filteredData.length"
        [pageIndex]="0"
        [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions"
      >
      </mat-paginator>
    </mat-card>
  </div>

  <div *ngIf="existingPatientsDB.data.length == 0 && existingPatientsDB.firstLoadEvent != false">
    <app-empty-list-message></app-empty-list-message>
  </div>
</div>
