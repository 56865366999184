<ngx-charts-chart
  [view]="[width, height]"
  [showLegend]="legend"
  [legendOptions]="legendOptions"
  [activeEntries]="activeEntries"
  [animations]="animations"
  (legendLabelClick)="onClick($event)"
  (legendLabelActivate)="onActivate($event)"
  (legendLabelDeactivate)="onDeactivate($event)"
>
  <svg:defs>
    <svg:clipPath [attr.id]="clipPathId">
      <svg:rect
        [attr.width]="dims.width + 10"
        [attr.height]="dims.height + 10"
        [attr.transform]="'translate(-5, -5)'"
      />
    </svg:clipPath>
  </svg:defs>
  <svg:g class="line-chart chart" [attr.transform]="transform">
    <svg:g
      *ngIf="xAxis"
      [xScale]="xScaleLine"
      [dims]="dims"
      [showLabel]="showXAxisLabel"
      [labelText]="xAxisLabel"
      [tickFormatting]="xAxisTickFormatting"
      [ticks]="xAxisTicks"
      (dimensionsChanged)="updateXAxisHeight($event)"
      ngx-charts-x-axis
    ></svg:g>
    <svg:g
      *ngIf="yAxis"
      [yScale]="yMainScale"
      [dims]="dims"
      [yOrient]="yOrientLeft"
      [showGridLines]="showYMainGridLines"
      [showLabel]="yMainAxisShowLabel"
      [labelText]="yMainAxisLabel"
      [tickFormatting]="yMainAxisTickFormatting"
      [ticks]="yMainAxisTicks"
      [referenceLines]="referenceLines"
      [showRefLines]="showRefLines"
      [showRefLabels]="showRefLabels"
      (dimensionsChanged)="updateYAxisWidth($event)"
      ngx-charts-y-axis
    ></svg:g>
    <svg:g
      *ngIf="yAxis && lineChart1.length"
      [yScale]="ySecondScale"
      [dims]="dims"
      [yOrient]="yOrientRight"
      [showGridLines]="showYSecondaryGridLines"
      [showLabel]="ySecondaryAxisShowLabel"
      [labelText]="ySecondaryAxisLabel"
      [tickFormatting]="ySecondaryAxisTickFormatting"
      [ticks]="ySecondaryAxisTicks"
      [referenceLines]="referenceLines"
      [showRefLines]="showRefLines"
      [showRefLabels]="showRefLabels"
      (dimensionsChanged)="updateYAxisWidth($event)"
      ngx-charts-y-axis
    ></svg:g>

    <svg:g [attr.clip-path]="clipPath">
      <svg:g *ngFor="let series of lineChart; trackBy: trackBy">
        <svg:g
          [xScale]="xScaleLine"
          [yScale]="yMainScale"
          [colors]="colorsLine"
          [data]="series"
          [activeEntries]="activeEntries"
          [scaleType]="scaleType"
          [curve]="curve"
          [rangeFillOpacity]="rangeFillOpacity"
          [animations]="animations"
          ngx-charts-line-series
        />
      </svg:g>

      <svg:g *ngFor="let series1 of lineChart1; trackBy: trackBy">
        <svg:g
          [xScale]="xScaleLine"
          [yScale]="ySecondScale"
          [colors]="colorsLine"
          [data]="series1"
          [activeEntries]="activeEntries"
          [scaleType]="scaleType"
          [curve]="curve"
          [rangeFillOpacity]="rangeFillOpacity"
          [animations]="animations"
          ngx-charts-line-series
        />
      </svg:g>

      <svg:g
        *ngIf="!tooltipDisabled"
        [dims]="dims"
        [xSet]="xSet"
        [xScale]="xScaleLine"
        [yScale]="yMainScale"
        [results]="combinedSeries"
        [colors]="colorsLine"
        [tooltipDisabled]="tooltipDisabled"
        (hover)="updateHoveredVertical($event)"
        ngx-charts-tooltip-area
      />

      <svg:g *ngFor="let series of lineChart">
        <svg:g
          [xScale]="xScaleLine"
          [yScale]="yMainScale"
          [colors]="colorsLine"
          [data]="series"
          [scaleType]="scaleType"
          [visibleValue]="hoveredVertical"
          [activeEntries]="activeEntries"
          [tooltipDisabled]="tooltipDisabled"
          (select)="onClick($event, series)"
          (activate)="onActivate($event)"
          (deactivate)="onDeactivate($event)"
          ngx-charts-circle-series
        />
      </svg:g>

      <svg:g *ngFor="let series of lineChart1">
        <svg:g
          [xScale]="xScaleLine"
          [yScale]="ySecondScale"
          [colors]="colorsLine"
          [data]="series"
          [scaleType]="scaleType"
          [visibleValue]="hoveredVertical"
          [activeEntries]="activeEntries"
          [tooltipDisabled]="tooltipDisabled"
          (select)="onClick($event, series)"
          (activate)="onActivate($event)"
          (deactivate)="onDeactivate($event)"
          ngx-charts-circle-series
        />
      </svg:g>
    </svg:g>
  </svg:g>
  <svg:g
    *ngIf="timeline"
    [attr.transform]="timelineTransform"
    [results]="comboArr"
    [view]="[timelineWidth, height]"
    [height]="timelineHeight"
    [scheme]="scheme"
    [customColors]="customColors"
    [scaleType]="scaleType"
    [legend]="legend"
    (select)="test($event)"
    (onDomainChange)="updateDomain($event)"
    ngx-charts-timeline
  >
    <svg:g *ngFor="let series of comboArr; trackBy: trackBy">
      <svg:g
        [xScale]="timelineXScale"
        [yScale]="timelineYScale"
        [colors]="colors"
        [data]="series"
        [scaleType]="scaleType"
        [curve]="curve"
        [hasRange]="hasRange"
        [animations]="animations"
        ngx-charts-line-series
      />
    </svg:g>
  </svg:g>
</ngx-charts-chart>
