import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { LookupService } from '../../services/lookup.service';
import { UtilsService } from '../../services/utils.service';
import { NotifyAppComponent } from '../../types/notify-app-component';
import { UtilsClass } from '../../types/utils/utils.class';

@Component({
  selector: 'app-address-input',
  templateUrl: './address-input.component.html',
  styleUrls: ['./address-input.component.css'],
})
export class AddressInputComponent implements OnInit {
  // Set Client
  public addressAPI = true;

  @Input()
  noIcons = true;
  @Input()
  isRequired = true;

  @Input()
  displayMap = true;

  @Input()
  postCode;

  @Input()
  state;

  @Input()
  streetNumber;

  @Input()
  unitNumber;

  @Input()
  streetName;

  @Input()
  latitude;

  @Input()
  longitude;

  @Input()
  streetType;

  @Input()
  suburb;

  suburbObject;
  streetTypeObject;
  adressObject;

  @Input()
  icon;

  iconAddres;

  @Input()
  canClose = true;

  @Input()
  isMiniView = false;

  @Input()
  isDisabled = false;

  @Input()
  clear = new EventEmitter();

  @Output()
  getAdress = new EventEmitter();

  @Output()
  getPostCode = new EventEmitter();

  @Output()
  getState = new EventEmitter();

  @Output()
  getSuburb = new EventEmitter();

  @Output()
  getStreetNumber = new EventEmitter();

  @Output()
  getStreetName = new EventEmitter();

  @Output()
  getUnitNumber = new EventEmitter();

  @Output()
  getStreetType = new EventEmitter();

  @Output()
  getLatitude = new EventEmitter();

  @Output()
  validate = new EventEmitter();

  @Output()
  getLongitude = new EventEmitter();

  suburbs = [];
  streeTypes = [];
  filteredStreetTypes = [];
  streetTypeObjectLabel;
  requiredLabel = 'required';
  hideStreetTypeOption = false;
  isValidate = false;
  util = new UtilsClass();

  isSelectedSuburb = false;

  invalidStreetType = false;

  constructor(
    private appState: UtilsService,
    private lookupService: LookupService,
    private _changeDetectionRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.iconAddres = this.icon;

    this.setUp();
    if (this.clear) {
      this.clear.subscribe((res) => {
        this.postCode = null;
        this.state = null;
        this.streetNumber = null;
        this.unitNumber = null;
        this.streetName = null;
        this.latitude = null;
        this.longitude = null;
        this.streetType = null;
        this.suburb = null;
        this.suburbObject = null;
        this.streetTypeObject = null;
        this.adressObject = null;
        this.streetTypeObjectLabel = null;

        this.sendResult();
      });
    }
  }

  ngAfterViewInit(): void {
    // Force another change detection in order to fix an occuring ExpressionChangedAfterItHasBeenCheckedError
    this._changeDetectionRef.detectChanges();
  }

  setUp() {
    if (this.isRequired == false) {
      this.requiredLabel = null;
    }
    UtilsClass.startLoading();

    this.lookupService.getLookup('CodeLookup', 'StreetType').subscribe((res) => {
      this.streeTypes = res;

      if (this.streetType) {
        for (let i = 0; i < this.streeTypes.length; i++) {
          if (this.streeTypes[i] && this.streeTypes[i]['Code'] == this.streetType) {
            this.streetTypeObject = this.streeTypes[i];
            this.streetTypeObjectLabel = this.streetTypeObject['Label'];
            break;
          }
        }
      } else {
        this.streetTypeObjectLabel = null;
      }

      if (this.suburb && this.postCode && this.state) {
        const payload = {
          fields: 'Locality,State,Postcode',
        };

        this.appState.getSuburbByStateByPostCode(this.suburb, this.state, this.postCode, payload).subscribe((res) => {
          UtilsClass.stopLoading();

          this.suburbs = res;

          if (this.suburbs && this.suburbs[0]) {
            this.suburbObject = this.suburbs[0];
            this.suburb = this.suburbs[0]['Locality'];
            this.state = this.suburbs[0]['State'];
            this.postCode = this.suburbs[0]['Postcode'];
            this.sendResult();
          } else {
            this.sendResult();
          }
        });
      } else if (this.suburb) {
        const payload = {
          fields: 'Locality,State,Postcode',
        };

        this.appState.getSuburb(this.suburb, payload).subscribe((res) => {
          UtilsClass.stopLoading();

          this.suburbs = res;

          if (this.suburb) {
            for (let i = 0; i < this.suburbs.length; i++) {
              if (this.suburbs[i] && this.suburbs[i]['Locality'] == this.suburb) {
                this.suburbObject = this.suburbs[i];
                this.state = this.suburbs[i]['State'];
                this.postCode = this.suburbs[i]['Postcode'];

                break;
              }
            }
            this.sendResult();
          } else {
            this.sendResult();
          }
        });
      } else if (!this.suburbObject && this.postCode) {
        this.postCodeChange(this.postCode);
      } else {
        this.sendResult();

        UtilsClass.stopLoading();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.streetType && changes.streetType && changes.streetType.previousValue != this.streetType) {
      for (let i = 0; i < this.streeTypes.length; i++) {
        if (this.streeTypes[i] && this.streeTypes[i]['Code'] == this.streetType) {
          this.streetTypeObject = this.streeTypes[i];
          this.streetTypeObjectLabel = this.streetTypeObject['Label'];

          break;
        }
      }
    }

    if (
      this.suburb &&
      this.postCode &&
      this.state &&
      ((changes.suburb && changes.suburb.previousValue != this.suburb) ||
        (changes.postCode && changes.postCode.previousValue != this.postCode) ||
        (changes.state && changes.state.previousValue != this.state))
    ) {
      const payload = {
        fields: 'Locality,State,Postcode',
      };

      this.appState.getSuburbByStateByPostCode(this.suburb, this.state, this.postCode, payload).subscribe((res) => {
        UtilsClass.stopLoading();

        this.suburbs = res;

        if (this.suburbs && this.suburbs[0]) {
          this.suburbObject = this.suburbs[0];
          this.suburb = this.suburbs[0]['Locality'];
          this.state = this.suburbs[0]['State'];
          this.postCode = this.suburbs[0]['Postcode'];
          this.sendResult();
        } else {
          this.sendResult();
        }
      });
    } else if (
      this.suburb &&
      this.state &&
      ((changes.suburb && changes.suburb.previousValue != this.suburb) ||
        (changes.state && changes.state.previousValue != this.state))
    ) {
      const payload = {
        fields: 'Locality,State,Postcode',
      };
      UtilsClass.startLoading();

      this.appState.getSuburb(this.suburb, payload).subscribe((res) => {
        UtilsClass.stopLoading();

        this.suburbs = res;

        if (this.suburb) {
          for (let i = 0; i < this.suburbs.length; i++) {
            if (this.suburbs[i] && this.suburbs[i]['Locality'] == this.suburb) {
              this.suburbObject = this.suburbs[i];
              this.state = this.suburbs[i]['State'];
              this.postCode = this.suburbs[i]['Postcode'];

              break;
            }
          }
          this.sendResult();
        }
      });
    } else if (
      !this.suburb &&
      this.postCode &&
      changes.postCode &&
      changes.postCode.previousValue != this.postCode &&
      !changes.postCode.firstChange
    ) {
      this.postCodeChange(this.postCode);
    } else {
      this.sendResult();

      UtilsClass.stopLoading();
    }
  }

  chooseSuburb(e) {
    if (e && typeof e == 'object' && e.Postcode && e.State && e.Locality) {
      this.postCode = e.Postcode;
      this.state = e.State;

      this.suburbObject = e;
      // this.suburb = e.Locality;
      this.isSelectedSuburb = false;

      this.sendResult();
    } else {
      this.state = null;
      this.postCode = null;
      this.sendResult();
    }
  }

  runCheck() {
    this.isSelectedSuburb = false;
    if (this.state == null || this.postCode == null) {
      this.isSelectedSuburb = true;
    }
  }

  displaySuburbFn(v) {
    return v && v.Locality ? v.Locality : v;
  }

  suburbChange(v) {
    if (v && typeof v == 'string') {
      const payload = {
        fields: 'Locality,State,Postcode',
      };

      UtilsClass.startLoading();
      this.appState.getSuburb(v, payload).subscribe((res) => {
        UtilsClass.stopLoading();

        this.suburbs = res;
      });
    } else {
      this.suburbObject = '';
      this.sendResult();
    }
  }

  postCodeChange(v) {
    const payload = {
      fields: 'Locality,State,Postcode',
    };
    this.postCode = v;

    UtilsClass.startLoading();
    this.appState.getSuburbByPostCode(this.postCode, payload).subscribe((res) => {
      UtilsClass.stopLoading();

      if (res && res[0] && res.length >= 0) {
        this.chooseSuburb(res[0]);
      } else {
        this.sendResult();

        NotifyAppComponent.displayToast('error', 'invalid', 'Post Code is invalid ');
      }
    });
  }

  changeUnitNumber(value) {
    this.unitNumber = value;
    this.sendResult();
  }

  changeStreetNumber(value) {
    this.streetNumber = value;
    this.sendResult();
  }

  changeStreetName(value) {
    this.streetName = value;
    this.sendResult();
  }

  changeState(value) {
    if (value) {
      this.state = value;
      this.sendResult();
    }
  }

  changeStreetType(value) {
    if (value) {
      this.streetTypeObject = value;
      this.sendResult();
    }
  }

  filterStreetType(value) {
    this.hideStreetTypeOption = false;

    const _checkType = this.streeTypes.filter((x) => x.Label === value);
    if (value && _checkType.length == 0) {
      this.invalidStreetType = true;
    }

    if (value) {
      this.filteredStreetTypes = this.streeTypes
        .map((x) => x.Label)
        .filter((x) => x.toLowerCase().includes(value.toLowerCase()));
      this.streetTypeObjectLabel = value;
      if (
        this.filteredStreetTypes &&
        this.filteredStreetTypes.length > 0 &&
        this.filteredStreetTypes[0].toLowerCase() === this.streetTypeObjectLabel.toLowerCase()
      ) {
        this.invalidStreetType = false;
        this.hideStreetTypeOption = true;

        this.getSelectedStreetType(this.filteredStreetTypes[0]);
      }
    } else {
      this.hideStreetTypeOption = false;
      this.streetTypeObjectLabel = null;
      this.streetTypeObject = null;

      this.sendResult();
    }
  }

  getSelectedStreetType(value) {
    if (value) {
      this.streetTypeObjectLabel = value;

      this.streetTypeObject = this.streeTypes.filter((x) => x.Label === value)[0];

      this.sendResult();
    }
  }

  sendResult() {
    let _streetType = null;
    let streetTypeLabel = null;

    if (this.streetTypeObject && this.streetTypeObject.Code) {
      _streetType = this.streetTypeObject.Code;
    } else {
      _streetType = this.streetType;
    }

    if (this.streetTypeObject && this.streetTypeObject.Label) {
      streetTypeLabel = this.streetTypeObject.Label;
    }

    let _suburb;
    let _state;
    let _postCode;

    if (this.suburbObject && this.suburbObject.Postcode && this.suburbObject.State && this.suburbObject.Locality) {
      _suburb = this.suburbObject.Locality;
      _state = this.suburbObject.State;
      _postCode = this.suburbObject.Postcode;
    }

    this.adressObject = {
      postCode: _postCode || null,
      suburb: _suburb || null,
      state: _state || null,
      streetNumber: this.streetNumber || null,
      unitNumber: this.unitNumber || null,
      streetName: this.streetName || null,
      streetType: _streetType,
      streetTypeLabel,
      latitude: this.latitude || null,
      longitude: this.longitude || null,
    };

    this.validateField();

    this.getAdress.emit(this.adressObject);

    this.getStreetName.emit(this.streetName);
    this.getPostCode.emit(this.streetTypeObject);
    this.getSuburb.emit(this.suburbObject);
    this.getState.emit(this.state);
    this.getStreetNumber.emit(this.streetNumber);
    this.getStreetType.emit(_streetType);
    this.getUnitNumber.emit(streetTypeLabel);
    this.getLatitude.emit(this.latitude);
    this.getLatitude.emit(this.longitude);
  }

  validateField() {
    let ruleValidator;
    if (this.isRequired != false) {
      ruleValidator = {
        streetName: 'string|required',
        streetNumber: 'string|required',
        streetType: 'string|required',
        streetTypeLabel: 'string|required',
        unitNumber: 'string',
        suburb: 'string|required',
        state: 'string|required',
        postCode: 'string|required',
      };
    } else {
      ruleValidator = {
        streetName: 'string',
        streetNumber: 'string',
        streetType: 'string',
        streetTypeLabel: 'string',
        unitNumber: 'string',
        suburb: 'string',
        state: 'string',
        postCode: 'string',
      };
    }
    this.isValidate = this.util.validateFields(this.adressObject, ruleValidator);

    this.validate.emit(this.isValidate);
  }

  notFoundEvent(v) {
    if (v == null) {
      this.displayMap = false;
    } else {
      this.displayMap = !v;
    }
  }

  getSelectedEvent(a) {
    if (a) {
      if (a.Latitude) {
        this.latitude = a.Latitude;

        this.sendResult();
      }

      if (a.Longitude) {
        this.longitude = a.Longitude;

        this.sendResult();
      }
    }
  }
}
