import { Component, EventEmitter, Inject, Input, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { map } from 'rxjs/operators';
import { AppStateService } from '../../../core/app-state/app-state.service';
import { ProductCatalogueItem } from '../shared/services/product-catalogue.service';

export interface DynamicProductListComponentConfig {
  modalTitle: string;
}

@Component({
  selector: 'ipv-dynamic-product-list',
  templateUrl: './dynamic-product-list.component.html',
  styleUrls: ['./dynamic-product-list.component.css'],
})
export class DynamicProductListComponent {
  @Output() getLength = new EventEmitter();
  @Output() productSelected: EventEmitter<ProductCatalogueItem> = new EventEmitter<ProductCatalogueItem>();

  @Input() pageSize = 20;
  @Input() miniDisplay = true;
  @Input() supplierID = null;
  @Input() defaultHeading = false;
  @Input() modalTitle = null;

  pageMode: 'page' | 'modal' = 'page';

  industryType$ = this.appStateService.getAppState$().pipe(map((appState) => appState.publicSettings.industryType));

  constructor(
    private appStateService: AppStateService,
    @Optional()
    private dialogRef: MatDialogRef<DynamicProductListComponent>,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    private data: DynamicProductListComponentConfig
  ) {
    if (this.dialogRef) {
      this.pageMode = 'modal';
      this.modalTitle = this.data.modalTitle ? this.data.modalTitle : '';
    }
  }

  getProductLength(s) {
    this.getLength.emit(s);
  }

  setProductSelected(any) {
    this.productSelected.emit(any);
  }
}
