<app-third-party-client-list
  class="full-width mb"
  *ngIf="displayApplication == true && isPromoterOrAdmin == true"
  [title]="titleApplication"
  [canCreate]="false"
  [pageSize]="pageSizeApp"
  [templateID]="templateID"
  (updateAllTemplate)="updateAllTemplate($event)"
>
</app-third-party-client-list>

<app-third-party-access-list
  class="full-width mt mb"
  *ngIf="displayAccess == true"
  [title]="titleAccess"
  [canCreate]="false"
  [pageSize]="pageSizeAccess"
  [templateID]="templateID"
>
</app-third-party-access-list>

<app-empty-list-message
  *ngIf="displayAccess != true && isPromoterOrAdmin != true && loaded == true"
  [message]="'There are no API Keys linked to this template.'"
  [actionIcon]="'visibility'"
  [isAction]="true"
  [actionLabel]="'View Template'"
  (actionEvent)="view()"
>
</app-empty-list-message>

<app-empty-list-message
  *ngIf="displayAccess != true && displayApplication != true && isPromoterOrAdmin == true && loaded == true"
  [message]="'There are no Third-Party Application or  API Keys linked to this template.'"
  [actionIcon]="'visibility'"
  [isAction]="true"
  [actionLabel]="'View Template'"
  (actionEvent)="view()"
>
</app-empty-list-message>
