/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./rating.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../node_modules/@ng-bootstrap/ng-bootstrap/ng-bootstrap.ngfactory";
import * as i4 from "@angular/forms";
import * as i5 from "@ng-bootstrap/ng-bootstrap";
import * as i6 from "./rating.component";
var styles_RatingComponent = [i0.styles];
var RenderType_RatingComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RatingComponent, data: {} });
export { RenderType_RatingComponent as RenderType_RatingComponent };
function View_RatingComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "span", [["class", "star"]], [[4, "fontSize", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "span", [["class", "half"]], [[4, "width", "%"]], null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(4, { color: 0 }), (_l()(), i1.ɵted(-1, null, ["\u2605"])), (_l()(), i1.ɵted(-1, null, ["\u2605 "]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _ck(_v, 4, 0, _co.color); _ck(_v, 3, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.fontSize; _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.context.fill; _ck(_v, 1, 0, currVal_1); }); }
export function View_RatingComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, [["starTemplate", 2]], null, 0, null, View_RatingComponent_1)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "ngb-rating", [["aria-valuemin", "0"], ["class", "d-inline-flex"], ["role", "slider"], ["style", "white-space: nowrap"], ["tabindex", "0"]], [[1, "aria-valuemax", 0], [1, "aria-valuenow", 0], [1, "aria-valuetext", 0], [1, "aria-disabled", 0]], [[null, "blur"], [null, "keydown"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("blur" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).handleBlur() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3).handleKeyDown($event) !== false);
        ad = (pd_1 && ad);
    } if (("mouseleave" === en)) {
        var pd_2 = (i1.ɵnov(_v, 3).reset() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i3.View_NgbRating_0, i3.RenderType_NgbRating)), i1.ɵprd(5120, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i5.NgbRating]), i1.ɵdid(3, 638976, null, 1, i5.NgbRating, [i5.NgbRatingConfig, i1.ChangeDetectorRef], { max: [0, "max"], rate: [1, "rate"], readonly: [2, "readonly"], starTemplate: [3, "starTemplate"] }, null), i1.ɵqud(603979776, 1, { starTemplateFromContent: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.max; var currVal_5 = _co.rating; var currVal_6 = true; var currVal_7 = i1.ɵnov(_v, 0); _ck(_v, 3, 0, currVal_4, currVal_5, currVal_6, currVal_7); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).max; var currVal_1 = i1.ɵnov(_v, 3).nextRate; var currVal_2 = i1.ɵnov(_v, 3).ariaValueText(); var currVal_3 = (i1.ɵnov(_v, 3).readonly ? true : null); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
export function View_RatingComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-rating", [], null, null, null, View_RatingComponent_0, RenderType_RatingComponent)), i1.ɵdid(1, 114688, null, 0, i6.RatingComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RatingComponentNgFactory = i1.ɵccf("app-rating", i6.RatingComponent, View_RatingComponent_Host_0, { rating: "rating", max: "max", color: "color", fontSize: "fontSize" }, {}, []);
export { RatingComponentNgFactory as RatingComponentNgFactory };
