import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WizardComponent } from 'angular-archwizard';
import { filter } from 'lodash';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { AuthenticationService } from '../../authentication/shared/authentication.service';
import { ModuleViewComponent } from '../module-view/module-view.component';
import { ModuleService } from '../shared/module.service';

@Component({
  selector: 'app-module-edit-create',
  templateUrl: './module-edit-create.component.html',
  styleUrls: ['./module-edit-create.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.animate', style({ opacity: 0 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.4s ease-out',
              keyframes([
                style({ opacity: 0, offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.animate', style({ opacity: 1 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.4s ease-in',
              keyframes([
                style({ opacity: 1, offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class ModuleEditCreateComponent implements OnInit {
  @Input()
  moduleID;
  @ViewChild('wizard', { static: false }) wizard: WizardComponent;

  titleHtml = 'Marketing Html Content';
  descriptionHtml = 'Choose how you want to add your HTML Conent';
  descriptionDocumentHtml = 'Upload file , only accept .html';
  descriptionRawHtml =
    'Use this component to create your HTML Content , you can preview the result in real time on the  panel  in the right ';
  descriptionEditorHtml =
    'Use the following component to create your HTML Content, drag and drop modals from the  panel  in the right';
  module;
  structure;
  isModal = false;
  label;
  description;
  status;
  icon = 'fas fa-cubes';
  isActive;
  createdRecord;
  statusArray = [
    {
      code: 'Beta',
      value: 'Beta',
    },
    {
      code: 'DisableForAll',
      value: 'Disable for all',
    },
    {
      code: 'EnableForAll',
      value: 'Enable for all',
    },
  ];
  pinchZoomProperties = [
    {
      wheel: true,
      autoHeight: true,
    },
  ];
  marketingDescriptions = [null];
  _marketingDescriptions = [null];
  settings = Settings.global;
  utils = new UtilsClass();
  wikiUrl;
  inputType = 'Document';
  postPictureLink;
  postPicture;
  loadingPost = false;
  postPictureLinkThumbnail;
  postPictureThumbnail;
  fileIDThumbnail;
  fileID;

  htmlContent;
  fileIDHtmlEP;

  maxFileSize = 5;
  allowExtensions = ['jpeg', 'jpg', 'png', 'gif', 'tiff', 'bmp', 'ico'];
  applyDownload = new EventEmitter();
  htmlResult = new EventEmitter();

  constructor(
    private moduleService: ModuleService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private activeRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    UtilsClass.scrollUp();
    this.activeRoute.params.subscribe((params) => {
      if (params['moduleID']) {
        this.moduleID = params['moduleID'];
      }
      this.setup();
    });
  }

  setup() {
    if (this.moduleID) {
      this.moduleService.getModuleDetails(this.moduleID, {}, 'admin').subscribe((res) => {
        if (res && res.ID) {
          this.module = res;
          if (this.module['Marketing_Html_Document_Key'] && this.module['Marketing_Conent_Type.Code'] === 'Document') {
            this.fileIDHtmlEP = this.module['Marketing_Html_Document_Key'];
            this.inputType = 'Document';
          } else if (
            this.module['Marketing_Conent_Type.Code'] === 'Editor' ||
            this.module['Marketing_Conent_Type.Code'] === 'Raw'
          ) {
            this.moduleService.getMarketingHTMLContent(res.ID).subscribe((_res) => {
              if (_res && _res.ID) {
                if (_res['Marketing_Html_Content']) {
                  this.htmlContent = _res['Marketing_Html_Content'];
                }
                if (_res['Marketing_Html_Content_Structure']) {
                  this.structure = JSON.parse(_res['Marketing_Html_Content_Structure']);
                }
                if (this.module['Marketing_Conent_Type.Code']) {
                  this.inputType = this.module['Marketing_Conent_Type.Code'];
                }
              }
            });
          }
          this.loadingPost = true;
          this.moduleService.getCoverImage(res.ID).subscribe((res) => {
            if (res) {
              this.postPicture = res;
            } else {
              this.postPicture = null;
            }
            this.loadingPost = false;
          });
          this.postPictureThumbnail = this.moduleService.getThumbnailImageStreamLink(res.ID, res.LastModified);
          this.buildModule();
        }
      });
    }
  }

  proceed() {
    UtilsClass.scrollUp();
    this.wizard.goToNextStep();
  }

  stepBack() {
    UtilsClass.scrollUp();
    this.wizard.goToPreviousStep();
  }

  buildModule() {
    if (this.module && this.module.ID) {
      this.moduleID = this.module.ID;
      if (this.module['Wiki_URL']) {
        this.wikiUrl = this.module['Wiki_URL'];
      }
      if (this.module['Label']) {
        this.label = this.module['Label'];
      }
      if (this.module['Description']) {
        this.description = this.module['Description'];
      }
      if (this.module['Marketing_Description']) {
        this.marketingDescriptions = this.module['Marketing_Description'].split('||');
        this._marketingDescriptions = this.module['Marketing_Description'].split('||');
      }
      if (this.module['Status.Code']) {
        this.status = this.module['Status.Code'];
      }
      if (this.module['Icon']) {
        this.icon = this.module['Icon'];
      }
      if (this.module['Isdepreciated'] === '1') {
        this.isActive = true;
      } else {
        this.isActive = false;
      }
    }
  }

  openLink(link) {
    if (link) {
      window.open(link, '_blank');
    }
  }

  onCompleteThumbnail(fileID) {
    if (fileID && fileID[0]) {
      this.fileIDThumbnail = fileID[0];
      this.postPictureThumbnail = null;
      this.postPictureLinkThumbnail = this.utils.getFileLink(this.fileIDThumbnail);
    } else {
      this.postPictureLinkThumbnail = null;
    }
  }

  moduleList() {
    this.router.navigate(['/merchant', { outlets: { page: ['module-list'] } }]);
  }

  step1() {
    this.wizard.goToStep(0);
  }

  step2() {
    this.wizard.goToStep(1);
  }

  step3() {
    this.wizard.goToStep(2);
  }

  onComplete(fileID) {
    if (fileID && fileID[0]) {
      this.fileID = fileID[0];
      this.postPicture = null;
      this.postPictureLink = this.utils.getFileLink(this.fileID);
    } else {
      this.postPictureLink = null;
    }
  }

  downloadImage() {
    this.applyDownload.emit(true);
  }

  isIndexOf(s, attribute) {
    if (s && attribute && s.indexOf(attribute) != -1) {
      return true;
    }
    return false;
  }

  addMarketingDescription() {
    this.marketingDescriptions.push(null);
    this._marketingDescriptions.push(null);
  }

  removeMarketingDescription(index) {
    this.marketingDescriptions.splice(index, 1);
    this._marketingDescriptions.splice(index, 1);
  }

  changeMarketing(index, value) {
    this._marketingDescriptions[index] = value;
  }

  saveResult(payload) {
    if (this.moduleID && payload) {
      this.moduleService.editModule(this.moduleID, payload).subscribe((res) => {
        if (res && res.ID) {
          this.createdRecord = res;
          UtilsClass.updateSideMenuOneModule.emit(res);
          NotifyAppComponent.displayToast('success', 'Success!', 'Module is Modified');
          this.proceed();
        }
      });
    }
  }

  viewModule(ID) {
    if (ID) {
      const ref = RootAppComponent.dialog.open(ModuleViewComponent, {
        data: {
          moduleID: ID,
        },
        width: '1000px',
      });
      ref.componentInstance.close.subscribe((res) => {
        if (res) {
          ref.close();
        }
      });
    }
  }

  edit(e) {
    if (this.moduleID) {
      const payload = {
        status: this.status,
        icon: this.icon,
        label: this.label,
        description: this.description,
        fileIDCover: this.fileID || null,
        fileIDThumbnail: this.fileIDThumbnail || null,
        wikiUrl: this.wikiUrl,

        htmlContentType: e.inputType || 'none',
        fileIDHtml: e.fileID || null,
        htmlContent: e.htmlContent || null,
        htmlContentStructure: e.structure || null,
      };
      this._marketingDescriptions = filter(this._marketingDescriptions, (item) => {
        if (item != null) {
          return true;
        }
        return false;
      });
      if (this._marketingDescriptions && this._marketingDescriptions.length > 0) {
        payload['marketingDescriptions'] = this._marketingDescriptions;
      }

      this.saveResult(payload);
    }
  }

  sendResult() {
    this.htmlResult.emit(true);
  }
}
