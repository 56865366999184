import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UtilsService } from '../../../shared/services/utils.service';
import { DataResponse } from '../../../shared/types/data-response.type';

@Injectable()
export class ProductService {
  private baseUrl = environment.nodeUrl;

  constructor(private http: HttpClient, private utilsService: UtilsService) {}

  getList(body, isPromoterOrAdmin = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        params = params.set(key, body[key]);
      }
    }

    options = {
      params,
    };
    if (isPromoterOrAdmin == true) {
      return this.http
        .get(environment.nodeUrl + '/product/global', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/product/', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getProductGroup(payload = {}): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };
    return this.http
      .get(environment.nodeUrl + '/product-group/', options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getMerchantProductGroup(payload = {}, isPromoterOrAdmin = false) {
    const params: HttpParams = this.utilsService.getHttpParamsFromPayload(payload);

    const options = { params };

    const endpoint = isPromoterOrAdmin ? 'merchant-product-group/global' : 'merchant-product-group/global';
    const url = `${this.baseUrl}/${endpoint}`;

    return this.http.get<DataResponse<MerchantProductGroup[]>>(url, options).pipe(map((response) => response['data']));
  }

  getOneProductGroup(payload): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};
    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }
    options = {
      params,
    };
    return this.http
      .get(`${environment.nodeUrl}/product-group`, options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getFiltersProduct() {
    let params: HttpParams = new HttpParams();
    let options = {};

    params = params.set('fields', 'ID,Label');
    options = {
      params,
    };
    return this.http.get(environment.nodeUrl + '/product/', options).pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      })
    );
  }

  /**
   * @Description
   * Get product details
   **/
  getProdDetails(ID) {
    return this.http.get(environment.nodeUrl + '/product/' + ID).pipe(map((res: HttpResponse<any>) => res['data']));
  }

  /**
   * @description
   * Get suitable product
   * @param('payload')
   **/
  getSuitableProduct(payload, isCold = false) {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };
    if (isCold == true) {
      return this.http
        .get(environment.nodeUrl + '/suitableProduct/cold/', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/suitableProduct/', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getSuitableProductMinValue(payload) {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    return this.http
      .get(environment.nodeUrl + '/suitableProduct/min/', options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }
  getSuitableProductStatistics(payload) {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    return this.http
      .get(environment.nodeUrl + '/suitableProduct/statistic/', options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getCalculator(productID, payload) {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };
    return this.http
      .get(environment.nodeUrl + '/product/calculator/' + productID, options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getCalculatorCustomer(productID, payload) {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };
    return this.http
      .get(environment.nodeUrl + '/product/calculator/customer/' + productID, options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getMerchantProductGroupHidden(payload = {}, isPromoterOrAdmin = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }
    const options = {
      params,
    };
    if (isPromoterOrAdmin == true) {
      return this.http
        .get(environment.nodeUrl + '/merchant-product-group/hidden/global', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/merchant-product-group/hidden', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  calculDuration(duration, sourceFreq, targetFreq) {
    const payload = {
      duration,
      sourceFreq,
      targetFreq,
    };

    return this.http
      .post(environment.nodeUrl + '/product/duration', payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  /**
   * @Description
   * Get product list
   **/
  getProdList() {
    return this.http.get(environment.nodeUrl + '/product').pipe(map((res: HttpResponse<any>) => res['data']));
  }
}

export class MerchantProductGroup {
  [key: string]: any;

  DateTimeCreated: string;
  GroupCode: string;
  GroupLabel: string;
  IsAvailable: '0' | '1';
  Merchant_key: string;
}
