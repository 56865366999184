import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import * as moment from 'moment';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { PerformanceService } from '../shared/performance.service';

@Component({
  selector: 'app-performance-dashboard-promoter-tools',
  templateUrl: './performance-dashboard-promoter-tools.component.html',
  styleUrls: ['./performance-dashboard-promoter-tools.component.css'],
  animations: [
    trigger('simpleFadeAnimation', [
      state('in', style({ opacity: 1, height: '*' })),
      transition(':enter', [style({ opacity: 0, height: 0 }), animate(300)]),
      transition(':leave', animate(300, style({ opacity: 0, height: 0 }))),
    ]),
  ],
})
export class PerformanceDashboardPromoterToolsComponent implements OnInit {
  @Input()
  colorScheme = Settings.global['chartColors'];

  chartColorScheme = {
    domain: this.colorScheme,
  };

  @Input()
  customColorScheme;

  chartColors = Settings.global['chartColors'];

  // DUAL AXIS CHART

  view: any[];

  gradient = false;
  showXAxis = true;
  showYAxis = true;
  showLegend = true;
  showXAxisLabel = true;
  showYAxisLabel = true;
  xAxisLabel = 'date';
  multiyAxisLabel = 'Portfolio Value';
  multiyAxisLabel2 = 'Arrears Value';
  autoScale = true;
  timeLine = true;
  animations = false;
  tooltipDisabled = false;

  // data for multi axis charts
  multi = [
    {
      name: 'Portfolio',
      series: [
        {
          name: new Date('2019-01-01T00:00:00'),
          value: '674846',
        },
        {
          name: new Date('2019-02-01T00:00:00'),
          value: '669791',
        },
        {
          name: new Date('2019-03-01T00:00:00'),
          value: '654031',
        },
        {
          name: new Date('2019-04-01T00:00:00'),
          value: '667575',
        },
        {
          name: new Date('2019-05-01T00:00:00'),
          value: '668126',
        },
      ],
    },
    {
      name: 'Accounts in Arrears',
      secondAxis: true,
      series: [
        {
          name: new Date('2019-01-01T00:00:00'),
          value: '66848',
        },
        {
          name: new Date('2019-02-01T00:00:00'),
          value: '69507',
        },
        {
          name: new Date('2019-03-01T00:00:00'),
          value: '76406',
        },
        {
          name: new Date('2019-04-01T00:00:00'),
          value: '74840',
        },
        {
          name: new Date('2019-05-01T00:00:00'),
          value: '58352',
        },
      ],
    },
  ];

  //  dentist signup

  dentistSignUp = [];

  practicesOnPlatform = [];

  invitesSentOpened = [];

  applications = [];

  loansVsDrawdowns = [];

  portfolioVsAccounts = [];

  avgCreditScore = [];

  avgLoanValue = [];

  engagementPercentage = [];

  utils = new UtilsClass();

  loadSignup = false;
  loadPractice = false;
  loadEngagement = false;
  loadSentOpen = false;
  loadApplication = false;
  loadSettlement = false;
  loadCredit = false;
  loadLoan = false;
  loadAccount = false;

  constructor(private performanceService: PerformanceService) {}

  ngOnInit() {
    this.setup();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.colorScheme) {
      this.chartColorScheme.domain = this.colorScheme;
    }
  }

  setup() {
    const _startDate = moment().add(-90, 'd').toDate();
    const _endDate = moment().toDate();
    const payload = {
      startDate: this.utils.EPdateFormat(_startDate),
      endDate: this.utils.EPdateFormat(_endDate),
    };
    this.performanceService.getWeeklyPerformance(payload, true).subscribe((res) => {
      if (
        res &&
        res['ToDate']['ToDate'] &&
        Array.isArray(res['ToDate']['ToDate']) &&
        res['ToDate']['ToDate'].length > 0
      ) {
        const toDates = res['ToDate']['ToDate'];

        const _dentistSignUpata = [];

        const _practicesOnPlatform = [];

        const _invitesSent = [];
        const _invitesViewed = [];

        const _appCommenced = [];
        const _appApproved = [];
        const _appActivated = [];

        const _valueBusinessWritten = [];
        const _drawdownDisbursed = [];

        const _portfolioBalance = [];
        const _valueAccountsInArrears = [];

        const _avgCreditScore = [];

        const _avgLoanValue = [];

        const _engagementPercentage = [];

        for (let i = 0; i < toDates.length; i++) {
          _dentistSignUpata.push({
            name: new Date(toDates[i]),
            value: res['NewPracticesThisWeek']['NewPracticesThisWeek'][i],
          });
          _practicesOnPlatform.push({
            name: new Date(toDates[i]),
            value: res['CumulativeActivePractices']['CumulativeActivePractices'][i],
          });

          _invitesSent.push({
            name: new Date(toDates[i]),
            value: res['Invites.Sent']['Invites']['Sent'][i],
          });

          _invitesViewed.push({
            name: new Date(toDates[i]),
            value: res['Invites.Viewed']['Invites']['Viewed'][i],
          });

          _appCommenced.push({
            name: new Date(toDates[i]),
            value: res['Applications.Commenced']['Applications']['Commenced'][i],
          });
          _appApproved.push({
            name: new Date(toDates[i]),
            value: res['Applications.Approved']['Applications']['Approved'][i],
          });
          _appActivated.push({
            name: new Date(toDates[i]),
            value: res['Applications.Activated']['Applications']['Activated'][i],
          });
          _valueBusinessWritten.push({
            name: new Date(toDates[i]),
            value: res['ValueBusinessWritten']['ValueBusinessWritten'][i],
          });
          _drawdownDisbursed.push({
            name: new Date(toDates[i]),
            value: res['MerchantDisbursals']['MerchantDisbursals'][i],
          });
          _portfolioBalance.push({
            name: new Date(toDates[i]),
            value: res['PortfolioBalance']['PortfolioBalance'][i],
          });
          _valueAccountsInArrears.push({
            name: new Date(toDates[i]),
            value: res['ValueAccountsInArrears']['ValueAccountsInArrears'][i],
          });
          _avgCreditScore.push({
            name: new Date(toDates[i]),
            value: res['AvgCreditScore']['AvgCreditScore'][i],
          });
          _avgLoanValue.push({
            name: new Date(toDates[i]),
            value: res['AvgLoanValue']['AvgLoanValue'][i],
          });

          _engagementPercentage.push({
            name: new Date(toDates[i]),
            value: res['EngagementPercentage']['EngagementPercentage'][i],
          });
        }

        this.dentistSignUp = [
          {
            name: 'Sign-Ups',
            series: _dentistSignUpata,
          },
        ];
        this.practicesOnPlatform = [
          {
            name: 'Practices',
            series: _practicesOnPlatform,
          },
        ];

        this.invitesSentOpened = [
          {
            name: 'Sent',
            series: _invitesSent,
          },
          {
            name: 'Viewed',
            series: _invitesViewed,
          },
        ];

        this.applications = [
          {
            name: 'Commenced',
            series: _appCommenced,
          },
          {
            name: 'Approved',
            series: _appApproved,
          },
          {
            name: 'Activated',
            series: _appActivated,
          },
        ];

        this.loansVsDrawdowns = [
          {
            name: 'Loans',
            series: _valueBusinessWritten,
          },
          {
            name: 'Drawdowns',
            series: _drawdownDisbursed,
          },
        ];

        this.portfolioVsAccounts = [
          {
            name: 'Portfolios',
            series: _portfolioBalance,
          },
          {
            name: 'Accounts in Arrears',
            secondAxis: true,
            series: _valueAccountsInArrears,
          },
        ];
        this.avgCreditScore = [
          {
            name: 'Average Credit Score',
            series: _avgCreditScore,
          },
        ];

        this.avgLoanValue = [
          {
            name: 'Average Loan Value',
            series: _avgLoanValue,
          },
        ];

        this.engagementPercentage = [
          {
            name: 'Engagement Percentage',
            series: _engagementPercentage,
          },
        ];
      }

      this.loadSignup = true;
      this.loadPractice = true;
      this.loadEngagement = true;
      this.loadSentOpen = true;
      this.loadApplication = true;
      this.loadSettlement = true;
      this.loadCredit = true;
      this.loadLoan = true;
      this.loadAccount = true;
    });
  }

  onResize(event) {
    // this.view = [event.target.innerWidth / 1.35, 400];
  }

  getRangeDentistSignedUp(e) {
    this.loadSignup = false;
    const payload = {
      startDate: this.utils.EPdateFormat(e.startDate),
      endDate: this.utils.EPdateFormat(e.endDate),
      fields: 'ToDate,NewPracticesThisWeek',
    };

    this.performanceService.getWeeklyPerformance(payload, true).subscribe((res) => {
      if (
        res &&
        res['ToDate']['ToDate'] &&
        Array.isArray(res['ToDate']['ToDate']) &&
        res['ToDate']['ToDate'].length > 0
      ) {
        const toDates = res['ToDate']['ToDate'];

        const data = [];
        for (let i = 0; i < toDates.length; i++) {
          const temp = {
            name: new Date(toDates[i]),
            value: res['NewPracticesThisWeek']['NewPracticesThisWeek'][i],
          };
          data.push(temp);
        }
        this.dentistSignUp = [
          {
            name: 'Sign-Ups',
            series: data,
          },
        ];
      }

      this.loadSignup = true;
    });
  }

  getRangePracticesOnPlatform(e) {
    this.loadPractice = false;
    const payload = {
      startDate: this.utils.EPdateFormat(e.startDate),
      endDate: this.utils.EPdateFormat(e.endDate),
      fields: 'ToDate,CumulativeActivePractices',
    };
    this.performanceService.getWeeklyPerformance(payload, true).subscribe((res) => {
      if (
        res &&
        res['ToDate']['ToDate'] &&
        Array.isArray(res['ToDate']['ToDate']) &&
        res['ToDate']['ToDate'].length > 0
      ) {
        const toDates = res['ToDate']['ToDate'];

        const data = [];
        for (let i = 0; i < toDates.length; i++) {
          const temp = {
            name: new Date(toDates[i]),
            value: res['CumulativeActivePractices']['CumulativeActivePractices'][i],
          };
          data.push(temp);
        }
        this.practicesOnPlatform = [
          {
            name: 'Practices',
            series: data,
          },
        ];
      }
      this.loadPractice = true;
    });
  }

  getRangeInvitesSentOpened(e) {
    this.loadSentOpen = false;
    const payload = {
      startDate: this.utils.EPdateFormat(e.startDate),
      endDate: this.utils.EPdateFormat(e.endDate),
      fields: 'ToDate,Invites.Sent,Invites.Viewed',
    };
    this.performanceService.getWeeklyPerformance(payload, true).subscribe((res) => {
      if (
        res &&
        res['ToDate']['ToDate'] &&
        Array.isArray(res['ToDate']['ToDate']) &&
        res['ToDate']['ToDate'].length > 0
      ) {
        const toDates = res['ToDate']['ToDate'];

        const sent = [];
        const viewed = [];
        for (let i = 0; i < toDates.length; i++) {
          sent.push({
            name: new Date(toDates[i]),
            value: res['Invites.Sent']['Invites']['Sent'][i],
          });
          viewed.push({
            name: new Date(toDates[i]),
            value: res['Invites.Viewed']['Invites']['Viewed'][i],
          });
        }
        this.invitesSentOpened = [
          {
            name: 'Sent',
            series: sent,
          },
          {
            name: 'Viewed',
            series: viewed,
          },
        ];
      }
      this.loadSentOpen = true;
    });
  }

  getRangeApplications(e) {
    this.loadApplication = false;
    const payload = {
      startDate: this.utils.EPdateFormat(e.startDate),
      endDate: this.utils.EPdateFormat(e.endDate),
      fields: 'ToDate,Applications.Commenced,Applications.Activated,Applications.Approved',
    };
    this.performanceService.getWeeklyPerformance(payload, true).subscribe((res) => {
      if (
        res &&
        res['ToDate']['ToDate'] &&
        Array.isArray(res['ToDate']['ToDate']) &&
        res['ToDate']['ToDate'].length > 0
      ) {
        const toDates = res['ToDate']['ToDate'];

        const commenced = [];
        const activated = [];
        const approved = [];
        for (let i = 0; i < toDates.length; i++) {
          commenced.push({
            name: new Date(toDates[i]),
            value: res['Applications.Commenced']['Applications']['Commenced'][i],
          });
          activated.push({
            name: new Date(toDates[i]),
            value: res['Applications.Activated']['Applications']['Activated'][i],
          });
          approved.push({
            name: new Date(toDates[i]),
            value: res['Applications.Approved']['Applications']['Approved'][i],
          });
        }
        this.applications = [
          {
            name: 'Commenced',
            series: commenced,
          },
          {
            name: 'Approved',
            series: approved,
          },
          {
            name: 'Activated',
            series: activated,
          },
        ];
      }
      this.loadApplication = true;
    });
  }

  getRangeLoansVsDrawdowns(e) {
    this.loadSettlement = false;
    const payload = {
      startDate: this.utils.EPdateFormat(e.startDate),
      endDate: this.utils.EPdateFormat(e.endDate),
      fields: 'ToDate,ValueBusinessWritten,MerchantDisbursals',
    };
    this.performanceService.getWeeklyPerformance(payload, true).subscribe((res) => {
      if (
        res &&
        res['ToDate']['ToDate'] &&
        Array.isArray(res['ToDate']['ToDate']) &&
        res['ToDate']['ToDate'].length > 0
      ) {
        const toDates = res['ToDate']['ToDate'];

        const loans = [];
        const drawdowns = [];
        for (let i = 0; i < toDates.length; i++) {
          loans.push({
            name: new Date(toDates[i]),
            value: res['ValueBusinessWritten']['ValueBusinessWritten'][i],
          });
          drawdowns.push({
            name: new Date(toDates[i]),
            value: res['MerchantDisbursals']['MerchantDisbursals'][i],
          });
        }
        this.loansVsDrawdowns = [
          {
            name: 'Loans',
            series: loans,
          },
          {
            name: 'Drawdowns',
            series: drawdowns,
          },
        ];
      }
      this.loadSettlement = true;
    });
  }

  getRangePortfolioVsAccounts(e) {
    this.loadAccount = false;
    const payload = {
      startDate: this.utils.EPdateFormat(e.startDate),
      endDate: this.utils.EPdateFormat(e.endDate),
      fields: 'ToDate,PortfolioBalance,ValueAccountsInArrears',
    };
    this.performanceService.getWeeklyPerformance(payload, true).subscribe((res) => {
      if (
        res &&
        res['ToDate']['ToDate'] &&
        Array.isArray(res['ToDate']['ToDate']) &&
        res['ToDate']['ToDate'].length > 0
      ) {
        const toDates = res['ToDate']['ToDate'];

        const portfolio = [];
        const arrears = [];
        for (let i = 0; i < toDates.length; i++) {
          portfolio.push({
            name: new Date(toDates[i]),
            value: res['PortfolioBalance']['PortfolioBalance'][i],
          });
          arrears.push({
            name: new Date(toDates[i]),
            value: res['ValueAccountsInArrears']['ValueAccountsInArrears'][i],
          });
        }
        this.portfolioVsAccounts = [
          {
            name: 'Portfolios',
            series: portfolio,
          },
          {
            name: 'Accounts in Arrears',
            secondAxis: true,
            series: arrears,
          },
        ];
      }
      this.loadAccount = true;
    });
  }

  getRangeAvgCreditScore(e) {
    this.loadCredit = false;
    const payload = {
      startDate: this.utils.EPdateFormat(e.startDate),
      endDate: this.utils.EPdateFormat(e.endDate),
      fields: 'ToDate,AvgCreditScore',
    };
    this.performanceService.getWeeklyPerformance(payload, true).subscribe((res) => {
      if (
        res &&
        res['ToDate']['ToDate'] &&
        Array.isArray(res['ToDate']['ToDate']) &&
        res['ToDate']['ToDate'].length > 0
      ) {
        const toDates = res['ToDate']['ToDate'];

        const avg = [];
        for (let i = 0; i < toDates.length; i++) {
          avg.push({
            name: new Date(toDates[i]),
            value: res['AvgCreditScore']['AvgCreditScore'][i],
          });
        }
        this.avgCreditScore = [
          {
            name: 'Average Credit Score',
            series: avg,
          },
        ];
      }
      this.loadCredit = true;
    });
  }

  getRangeAvgLoanValue(e) {
    this.loadLoan = false;
    const payload = {
      startDate: this.utils.EPdateFormat(e.startDate),
      endDate: this.utils.EPdateFormat(e.endDate),
      fields: 'ToDate,AvgLoanValue',
    };
    this.performanceService.getWeeklyPerformance(payload, true).subscribe((res) => {
      if (
        res &&
        res['ToDate']['ToDate'] &&
        Array.isArray(res['ToDate']['ToDate']) &&
        res['ToDate']['ToDate'].length > 0
      ) {
        const toDates = res['ToDate']['ToDate'];

        const avg = [];
        for (let i = 0; i < toDates.length; i++) {
          avg.push({
            name: new Date(toDates[i]),
            value: res['AvgLoanValue']['AvgLoanValue'][i],
          });
        }
        this.avgLoanValue = [
          {
            name: 'Average Loan Value',
            series: avg,
          },
        ];
      }

      this.loadLoan = true;
    });
  }

  getRangeEngagementPercentage(e) {
    this.loadEngagement = false;
    const payload = {
      startDate: this.utils.EPdateFormat(e.startDate),
      endDate: this.utils.EPdateFormat(e.endDate),
      fields: 'ToDate,EngagementPercentage',
    };
    this.performanceService.getWeeklyPerformance(payload, true).subscribe((res) => {
      if (
        res &&
        res['ToDate']['ToDate'] &&
        Array.isArray(res['ToDate']['ToDate']) &&
        res['ToDate']['ToDate'].length > 0
      ) {
        const toDates = res['ToDate']['ToDate'];

        const avg = [];
        for (let i = 0; i < toDates.length; i++) {
          avg.push({
            name: new Date(toDates[i]),
            value: res['EngagementPercentage']['EngagementPercentage'][i],
          });
        }
        this.engagementPercentage = [
          {
            name: 'Engagement Percentage',
            series: avg,
          },
        ];
      }
      this.loadEngagement = true;
    });
  }

  /**
   * trying to centralise
   */

  // getRange(e, _fields) {
  //   let fields = [];
  //   if (e && _fields && typeof _fields == 'string') {

  //     fields = _fields.split(",")

  //     let payload = {
  //       startDate: this.utils.EPdateFormat(e.startDate),
  //       endDate: this.utils.EPdateFormat(e.endDate),
  //       fields: `ToDate,${_fields}`,
  //     };

  //     this.performanceService.getWeeklyPerformance(payload).subscribe(res => {
  //       if (
  //         res &&
  //         res['ToDate']['ToDate'] &&
  //         Array.isArray(res['ToDate']['ToDate']) &&
  //         res['ToDate']['ToDate'].length > 0
  //       ) {
  //         let toDates = res['ToDate']['ToDate'];

  //         let data = [];
  //         for (var i = 0; i < toDates.length; i++) {
  //           for (var j = 0;j < fields.length; j++) {

  //           }
  //           let temp = {
  //             name: new Date(toDates[i]),
  //             value: res['NewPracticesThisWeek']['NewPracticesThisWeek'][i],
  //           };
  //           data.push(temp);
  //         }
  //         this.dentistSignUp = [
  //           {
  //             "name": "Sign-Ups",
  //             "series": data,
  //           }
  //         ];
  //       }
  //     })
  //   }
  // }
}
