/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./google-reviews-sign-in-dialog.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "../../../../shared/components/title-modal/title-modal.component.ngfactory";
import * as i7 from "../../../../shared/components/title-modal/title-modal.component";
import * as i8 from "@angular/material/dialog";
import * as i9 from "@angular/common";
import * as i10 from "./google-reviews-sign-in-dialog.component";
import * as i11 from "../../shared/google-reviews.service";
import * as i12 from "../../../../core/authentication/shared/authentication.service";
var styles_GoogleReviewsSignInDialogComponent = [i0.styles];
var RenderType_GoogleReviewsSignInDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GoogleReviewsSignInDialogComponent, data: {} });
export { RenderType_GoogleReviewsSignInDialogComponent as RenderType_GoogleReviewsSignInDialogComponent };
function View_GoogleReviewsSignInDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["style", "display: flex; justify-content: space-between"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "p", [["style", "max-width: 300px"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Connect to your Google My Business account by signing in."])), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "google-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.signIn() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "google-icon-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "img", [["class", "google-icon"], ["src", "https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "p", [["class", "btn-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Sign in with Google"])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "h3", [["class", "text-center primary sr-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Don't have an account?"])), (_l()(), i1.ɵeld(11, 0, null, null, 5, "div", [["style", "display: flex; justify-content: space-between"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "p", [["style", "max-width: 300px"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Create your free Google My Business account now by clicking the Create Account button. "])), (_l()(), i1.ɵeld(14, 0, null, null, 2, "a", [["color", "accent"], ["href", "https://www.google.com/business/"], ["mat-raised-button", ""], ["style", "display: block; height: 35px; margin-top: 20px; width: 188px"], ["target", "_blank"]], [[1, "tabindex", 0], [1, "disabled", 0], [1, "aria-disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 15)._haltDisabledEvents($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatAnchor_0, i2.RenderType_MatAnchor)), i1.ɵdid(15, 180224, null, 0, i3.MatAnchor, [i4.FocusMonitor, i1.ElementRef, [2, i5.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, [" Create Account "]))], function (_ck, _v) { var currVal_4 = "accent"; _ck(_v, 15, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 15).disabled ? (0 - 1) : (i1.ɵnov(_v, 15).tabIndex || 0)); var currVal_1 = (i1.ɵnov(_v, 15).disabled || null); var currVal_2 = i1.ɵnov(_v, 15).disabled.toString(); var currVal_3 = (i1.ɵnov(_v, 15)._animationMode === "NoopAnimations"); _ck(_v, 14, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
function View_GoogleReviewsSignInDialogComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["style", "text-align: center; margin-top: 30px"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.spinnerText; _ck(_v, 1, 0, currVal_0); }); }
export function View_GoogleReviewsSignInDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "ipv-title-modal", [], null, null, null, i6.View_TitleModalComponent_0, i6.RenderType_TitleModalComponent)), i1.ɵdid(1, 114688, null, 0, i7.TitleModalComponent, [i8.MAT_DIALOG_DATA], { icon: [0, "icon"], title: [1, "title"], customClass: [2, "customClass"], hideAllDefaultButtons: [3, "hideAllDefaultButtons"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 3, "div", [["content", ""]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GoogleReviewsSignInDialogComponent_1)), i1.ɵdid(4, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["spinner", 2]], null, 0, null, View_GoogleReviewsSignInDialogComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "blank"; var currVal_1 = "Setup Google Reviews"; var currVal_2 = "info"; var currVal_3 = true; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = (_co.isServiceReady === true); var currVal_5 = i1.ɵnov(_v, 5); _ck(_v, 4, 0, currVal_4, currVal_5); }, null); }
export function View_GoogleReviewsSignInDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "sr-google-reviews-sign-in-dialog", [], null, null, null, View_GoogleReviewsSignInDialogComponent_0, RenderType_GoogleReviewsSignInDialogComponent)), i1.ɵdid(1, 245760, null, 0, i10.GoogleReviewsSignInDialogComponent, [i8.MatDialogRef, i11.GoogleReviewsService, i1.NgZone, i12.AuthenticationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GoogleReviewsSignInDialogComponentNgFactory = i1.ɵccf("sr-google-reviews-sign-in-dialog", i10.GoogleReviewsSignInDialogComponent, View_GoogleReviewsSignInDialogComponent_Host_0, {}, {}, []);
export { GoogleReviewsSignInDialogComponentNgFactory as GoogleReviewsSignInDialogComponentNgFactory };
