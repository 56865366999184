<div class="row clearfix container">
  <div class="rel" *ngIf="listDB.firstLoadEvent == false" @ngIfAnimation>
    <div class="row clearfix padded rel">
      <h1 class="dataHeader rm-mt transparent">Fetching data...</h1>
    </div>
  </div>

  <div class="full-width flex" [hidden]="listDB.data.length == 0">
    <div class="full-width ml mr">
      <div class="row clearfix mt">
        <h1 class="dataHeader rm-m pull-left ml">
          {{ title }}
          <span class="badge" [endVal]="dataSource.filteredData.length" countUp></span>
        </h1>

        <div class="pull-right selection-items" @ngIfAnimation>


          <button mat-raised-button color="accent" class="share-social-media-button ml pull-right"
            (click)="syncSubPlans()">
            <mat-icon class="fas fa-sync smr"></mat-icon>Sync User Sub Plans
          </button>



          <button mat-stroked-button color="" class="animated smr" (click)="selectAll()"
            matTooltip="{{'Select All' | translate }}">
            <mat-icon>border_all</mat-icon>
          </button>

          <button
            class="row select-btn smr"
            (click)="selectPage()"
            mat-stroked-button
            color=""
            matTooltip="Select Article"
          >
            <mat-icon>border_outer</mat-icon>
          </button>

          <button
            class="animated mr"
            *ngIf="selectedIDs && selectedIDs.length > 0"
            [matMenuTriggerFor]="ActionBtnmenu"
            mat-stroked-button
            matTooltip="Options"
          >
            <mat-icon class="fas fa-cog"></mat-icon>  <span *ngIf="selectedIDs && selectedIDs.length>0"
              class="selected-number-item  accent-background">{{selectedIDs.length}}</span>
          </button>
          <mat-menu #ActionBtnmenu="matMenu">
            <button class="row select-btn" (click)="unselect()" mat-menu-item color="">
              <mat-icon>select_all</mat-icon> Deselect
            </button>

            <hr/>

            <button
              class="row select-btn"
              *ngIf="selectedIDs && selectedIDs.length > 0"
              (click)="changePlanGroup()"
              mat-menu-item
              color=""
            >
              <mat-icon class="fas fa-crown smr" style="color: gold"></mat-icon>
              Change plan
            </button>

            <button mat-menu-item color="" *ngIf="selectedIDs && selectedIDs.length>0" class="row select-btn"
              (click)="sync(true)">
              <mat-icon class="smr">sync</mat-icon> Sync User Sub Plans
            </button>


          </mat-menu>
        </div>
      </div>

      <div class="row clearfix">
        <mat-card class="list-card">
          <div class="row clearfix mb searchArea primary-gradient-img">
            <div class="row clearfix flex">
              <div class="full-width flex animated fadeInDown">
                <mat-icon class="mt mr">search</mat-icon>

                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Trading As, Email, Phone...etc</mat-label>
                  <input
                    class="clearfix"
                    [(ngModel)]="searchInput"
                    (input)="setFilter(searchInput, 'fullName')"
                    name="Labels"
                    matInput
                  />
                </mat-form-field>
              </div>
            </div>
          </div>

          <!-- Table -->
          <mat-table class="list-table" #table [dataSource]="dataSource" matSort>
            <ng-container cdkColumnDef="selectCheckBox" sticky>
              <mat-header-cell *cdkHeaderCellDef></mat-header-cell>
              <mat-cell *cdkCellDef="let row">
                <div class="margin-btm pull-left">
                  <mat-checkbox
                    [disabled]="isPromoterOrAdmin != true && row.type == 'default'"
                    [checked]="isSelected(row['Merchant_key'])"
                    (change)="select(row['Merchant_key'], $event.checked)"
                    color="accent"
                  >
                  </mat-checkbox>
                </div>
              </mat-cell>
            </ng-container>

            <ng-container cdkColumnDef="UserImage" sticky>
              <mat-header-cell *cdkHeaderCellDef mat-sort-header> </mat-header-cell>
              <mat-cell class="text-center" *cdkCellDef="let row" (click)="openCatelogueVIew(row['ID'])">
                <app-ep-document-view-image
                  class="subscription-plan-List"
                  [hideZoomButton]="true"
                  [defaultPicture]="
                    'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/profile_placeholder.png)'
                  "
                  [link]="getUserImage(row['Merchant_key'])"
                >
                </app-ep-document-view-image>
              </mat-cell>
            </ng-container>

            <ng-container cdkColumnDef="Practice" sticky>
              <mat-header-cell *cdkHeaderCellDef
                               mat-sort-header> {{ "KEYWORD.practice" | translate | titlecase}}</mat-header-cell>
              <mat-cell
                *cdkCellDef="let row"
                (click)="changePlan(row['ID'], row['Merchant_key'], row['SubscriptionPlan_key'])"
                matTooltip="{{ row['TradingAs'] }}"
              >
                <h3>
                  {{ row['TradingAs'] }}
                </h3>
              </mat-cell>
            </ng-container>

            <ng-container cdkColumnDef="SubscriptionPlanImage">
              <mat-header-cell *cdkHeaderCellDef mat-sort-header> </mat-header-cell>
              <mat-cell class="text-center" *cdkCellDef="let row" (click)="openCatelogueVIew(row['ID'])">
                <app-ep-document-view-image
                  class="subscription-plan-List-sub"
                  [hideZoomButton]="true"
                  [defaultPicture]="
                    'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/light_icon2.png)'
                  "
                  [link]="getSubscriptionPlanImage(row['SubscriptionPlan_key'])"
                >
                </app-ep-document-view-image>
              </mat-cell>
            </ng-container>

            <ng-container cdkColumnDef="plan">
              <mat-header-cell *cdkHeaderCellDef mat-sort-header> Plan </mat-header-cell>
              <mat-cell
                *cdkCellDef="let row"
                (click)="changePlan(row['ID'], row['Merchant_key'], row['SubscriptionPlan_key'])"
              >
                <h3 class="plan-title" [attr.style]="'color:' + row.color + ' !important' | safeStyle">
                  {{ row['SubscriptionPlan_Label'] }}
                </h3>
              </mat-cell>
            </ng-container>

            <ng-container cdkColumnDef="price">
              <mat-header-cell *cdkHeaderCellDef mat-sort-header> Price</mat-header-cell>
              <mat-cell
                *cdkCellDef="let row"
                (click)="changePlan(row['ID'], row['Merchant_key'], row['SubscriptionPlan_key'])"
              >
                <span>
                  {{ row.Price | customCurrency: '1.0-3' }}
                </span>
              </mat-cell>
            </ng-container>

            <ng-container cdkColumnDef="status">
              <mat-header-cell *cdkHeaderCellDef mat-sort-header>Status</mat-header-cell>
              <mat-cell
                *cdkCellDef="let row"
                (click)="changePlan(row['ID'], row['Merchant_key'], row['SubscriptionPlan_key'])"
              >
                <span class="chip" *ngIf="row['Status.Code'] == 'Approved'" style="background: rgb(90, 186, 71)">
                  Approved
                </span>
                <span class="chip" *ngIf="row['Status.Code'] == 'Pending'" style="background: rgb(255, 144, 18)">
                  Pending
                </span>

                <span class="chip" *ngIf="row['Status.Code'] == 'Requested'" style="background: rgb(126, 126, 126)">
                  Requested
                </span>
              </mat-cell>
            </ng-container>

            <ng-container cdkColumnDef="Date">
              <mat-header-cell *cdkHeaderCellDef mat-sort-header>Date</mat-header-cell>
              <mat-cell
                *cdkCellDef="let row"
                (click)="changePlan(row['ID'], row['Merchant_key'], row['SubscriptionPlan_key'])"
                matTooltip="{{ row['DateTimeCreated'] | customDateTime }}"
              >
                <span *ngIf="row['DateTimeCreated']"> {{ row['DateTimeCreated'] | TimeLabelPast }} ago</span>
              </mat-cell>
            </ng-container>

            <ng-container cdkColumnDef="isDefault">
              <mat-header-cell *cdkHeaderCellDef mat-sort-header> Default </mat-header-cell>
              <mat-cell
                *cdkCellDef="let row"
                (click)="changePlan(row['ID'], row['Merchant_key'], row['SubscriptionPlan_key'])"
              >
                <span
                  class="list-label smaller"
                  *ngIf="row['isDefault'] == true"
                  style="background-color: rgb(90, 186, 71)"
                >
                  <mat-icon>check</mat-icon>
                </span>
                <span
                  class="list-label smaller"
                  *ngIf="row['isDefault'] != true"
                  style="background-color: rgb(239, 71, 39)"
                >
                  <mat-icon>close</mat-icon>
                </span>
              </mat-cell>
            </ng-container>

            <ng-container cdkColumnDef="freeSMS">
              <mat-header-cell *cdkHeaderCellDef mat-sort-header> Free SMS </mat-header-cell>
              <mat-cell
                *cdkCellDef="let row"
                (click)="changePlan(row['ID'], row['Merchant_key'], row['SubscriptionPlan_key'])"
              >
                <span
                  class="list-label smaller"
                  *ngIf="row['Is_SMS_Free'] == '1'"
                  style="background-color: rgb(90, 186, 71)"
                >
                  <mat-icon>check</mat-icon>
                </span>
                <span
                  class="list-label smaller"
                  *ngIf="row['Is_SMS_Free'] != '1'"
                  style="background-color: rgb(239, 71, 39)"
                >
                  <mat-icon>close</mat-icon>
                </span>
              </mat-cell>
            </ng-container>

            <ng-container cdkColumnDef="Actions">
              <mat-header-cell *cdkHeaderCellDef mat-sort-header> Actions</mat-header-cell>
              <mat-cell *cdkCellDef="let row">
                <div style="float: right">
                  <button [matMenuTriggerFor]="menu" mat-icon-button>
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                    <button
                      (click)="changePlan(row['ID'], row['Merchant_key'], row['SubscriptionPlan_key'])"
                      mat-menu-item
                    >
                      <mat-icon class="fas fa-crown" style="color: gold"></mat-icon>
                      <span>Change plan</span>
                    </button>

                    <button
                      *ngIf="row && row['SubscriptionPlan_key']"
                      (click)="viewSubPlan(row['SubscriptionPlan_key'])"
                      mat-menu-item
                    >
                      <mat-icon>visibility</mat-icon>
                      <span>Quick view</span>
                    </button>

                    <button
                      *ngIf="row && row['SubscriptionPlan_key']"
                      (click)="viewPlan(row['SubscriptionPlan_key'])"
                      mat-menu-item
                    >
                      <mat-icon>description</mat-icon>
                      <span>View plan</span>
                    </button>

                    <button (click)="viewMerchant(row['Merchant_key'])" mat-menu-item>
                      <mat-icon>person</mat-icon>
                      <span>View merchant</span>
                    </button>

                    <hr/>

                    <button (click)="syncMerchant(false, row['Merchant_key'])" mat-menu-item>
                      <mat-icon>sync</mat-icon>
                      <span>Synchronize</span>
                    </button>

                    <button (click)="syncMerchant(true, row['Merchant_key'])" mat-menu-item>
                      <mat-icon>refresh</mat-icon>
                      <span>Reset</span>
                    </button>
                  </mat-menu>
                </div>
              </mat-cell>
            </ng-container>
            <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *cdkRowDef="let row; columns: displayedColumns"></mat-row>
          </mat-table>
          <mat-paginator
            #paginator
            [length]="dataSource.filteredData.length"
            [pageIndex]="0"
            [pageSize]="pageSize"
            [pageSizeOptions]="pageSizeOptions"
          >
          </mat-paginator>
        </mat-card>
      </div>
    </div>

    <div class="qtr-width flex mr ml" *ngIf="listDB && listDB && listDB.data.length > 0 && plans && plans.length > 0">
      <mat-card class="full-width plan-card">
        <div class="card-header primary-gradient-img clearfix inModal">
          <div class="row clearfix">
            <div class="row titleArea clearfix">
              <h2 class="summary-header rm-m">
                Overview
                <span class="subLabel"
                >Click on one of the items bellow to filter the user subscriptions list, Click on
                  <strong>Total</strong> To display all.</span
                >
              </h2>
            </div>
          </div>
        </div>

        <div class="full-width state-container">
          <div class="subStatcontainer animate">
            <div class="row clearfix static-inner mb">
              <div class="full-width static-left mb rel">
                <div class="static-left-inner">
                  <div class="row clearfix full-width rel" *ngIf="plans && plans.length > 0">
                    <div class="total anim">
                      <span class="totLab">Total</span>
                      <span class="totNum">{{ plans | objectPropertyValue: 'count' | toNumberArray | sum }}</span>
                    </div>

                    <app-chart-input
                      class="row"
                      [titleIsTop]="false"
                      [data]="plans"
                      [dataNameAttribute]="'SubscriptionPlan_Label'"
                      [dataValueAttribute]="'count'"
                      [chartType]="'pie'"
                      [customColorScheme]="customColorScheme"
                      [showLegend]="false"
                      [showLabel]="false"
                      [title]="''"
                    >
                    </app-chart-input>
                  </div>
                  <div class="row clearfix full-width text-center rel" *ngIf="statsLoaded == false">
                    <!-- css loader -->
                    <div class="lds-ellipsis">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                    <!-- /css loader -->
                  </div>
                </div>
              </div>
              <div class="full-width static-right mb">
                <div class="row clearfix">
                  <mat-list>
                    <mat-list-item
                      class="animate quickStat"
                      *ngFor="let p of plans; let i = index"
                      (click)="setFilter(p['SubscriptionPlan_key'], 'SubscriptionPlan_key')"
                    >
                      <h4 class="rm-m" [style.color]="p.color" mdLine>
                        <app-ep-document-view-image
                          class="subscription-plan-List-sub-header pull-left mr"
                          [hideZoomButton]="true"
                          [defaultPicture]="
                            'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/light_icon2.png)'
                          "
                          [link]="getSubscriptionPlanImage(p['SubscriptionPlan_key'])"
                        >
                        </app-ep-document-view-image>

                        {{ p['SubscriptionPlan_Label'] }}: {{ p['count'] }} users
                      </h4>

                      <mat-divider></mat-divider>
                    </mat-list-item>

                    <mat-list-item class="animate quickStat mt" (click)="setFilter(null, 'SubscriptionPlan_key')">
                      <h3 style="color: #444444" mdLine>
                        <mat-icon class="fas fa-hand-point-right"></mat-icon>
                        <strong> {{ totalCount || 0 }} subscriptions</strong>
                      </h3>
                    </mat-list-item>
                  </mat-list>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
  <div *ngIf="listDB.data.length == 0 && listDB.firstLoadEvent != false">
    <app-empty-list-message></app-empty-list-message>
  </div>
</div>
