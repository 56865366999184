import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { BankAccountService } from '../shared/bank-account.service';

@Component({
  selector: 'app-bank-account-view',
  templateUrl: './bank-account-view.component.html',
  styleUrls: ['./bank-account-view.component.css'],
})
export class BankAccountViewComponent implements OnInit {
  isModal = false;

  @Output()
  closeModal = new EventEmitter();

  @Input()
  bankAccountID;

  isPromoterOrAdmin = false;

  bankAccount;

  constructor(
    private bankAccountService: BankAccountService,
    private activeRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data && typeof data == 'string') {
      this.isModal = true;
      this.bankAccountID = data;
    }
  }

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      if (params['id']) {
        this.bankAccountID = params['id'];
      }
      if (this.bankAccountID) {
        this.authenticationService.isPromoterOrAdmin().subscribe((res1) => {
          this.isPromoterOrAdmin = res1;

          if (this.isPromoterOrAdmin != true) {
            this.closeModal.emit(true);
          }

          this.bankAccountService
            .getBankAccountDetails(this.bankAccountID, {}, this.isPromoterOrAdmin)
            .subscribe((res) => {
              if (res) {
                this.bankAccount = res;
              }
            });
        });
      }
    });
  }

  closeEvent() {
    this.closeModal.emit(true);
  }

  enable() {
    const confirmDialog = new ConfirmDialog(
      'check_circle',
      'Enable Bank Account',
      '<p>Are you sure?</p>',
      'Cancel',
      'Okay'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
      // SET TO PAYING CASH CARD
    });
    ref.componentInstance.onConfirm.subscribe((val) => {
      if (val == true) {
        ref.close();
        ref.afterClosed().subscribe((res) => {
          const payload = {
            active: true,
          };
          this.bankAccountService
            .editBankAccount(this.bankAccountID, payload, this.isPromoterOrAdmin)
            .subscribe((res) => {
              if (res) {
                this.bankAccount = res;

                NotifyAppComponent.displayToast('success', 'Success!', 'Beneficiary is enabled.');
              }
            });
        });
      } else {
        ref.close();
      }
    });
  }

  disable() {
    const confirmDialog = new ConfirmDialog('cancel', 'Disable Bank Account', '<p>Are you sure?</p>', 'Cancel', 'Okay');

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
      // SET TO PAYING CASH CARD
    });
    ref.componentInstance.onConfirm.subscribe((val) => {
      if (val == true) {
        ref.close();
        ref.afterClosed().subscribe((res) => {
          const payload = {
            active: false,
          };
          this.bankAccountService
            .editBankAccount(this.bankAccountID, payload, this.isPromoterOrAdmin)
            .subscribe((res) => {
              if (res) {
                this.bankAccount = res;

                NotifyAppComponent.displayToast('success', 'Success!', 'Beneficiary is enabled.');
              }
            });
        });
      } else {
        ref.close();
      }
    });
  }

  getCardType(d) {
    if (d === 'MC') {
      return 'MC';
    } else if (d === 'AX') {
      return 'AX';
    } else if (d === 'VS') {
      return 'VS';
    } else if (d === 'VIS') {
      return 'VS';
    } else if (d === 'VISA') {
      return 'VS';
    }

    return 'CC';
  }
}
