import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { MerchantService } from '../../merchant/shared/merchant.service';
import { AppointmentUtilClass } from '../shared/appointmentUtil';

export class AppointmentAvailabilityComponentDialogData {
  merchantID?: string;
}

@Component({
  selector: 'app-appointment-availability',
  templateUrl: './appointment-availability.component.html',
  styleUrls: ['./appointment-availability.component.css'],
})
export class AppointmentAvailabilityComponent implements OnInit {
  @Input() merchantID: string;
  @Input() merchant;

  isPromoterOrAdmin = false;
  sessionType = 'guest';

  isModal = false;
  appointmentLimitToDaysRadio: string; // '1' = Indefinitely into the future, '2' = Limited to days into the future
  appointmentLimitToDaysInput: string; // input value when `appointmentLimitToDaysRadio` is 2
  appointmentTimeStep: string;
  defaultBuffersPre: string;
  defaultBuffersPost: string;

  // data source
  startTimes = ['5', '10', '15', '30', '45', '60'];
  appointmentUtil = new AppointmentUtilClass();
  buffers = this.appointmentUtil.getBufferArray();

  @Output()
  closeModal = new EventEmitter();

  // Returns the updated merchant
  @Output()
  editedMerchant = new EventEmitter();

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: AppointmentAvailabilityComponentDialogData,
    private authenticationService: AuthenticationService,
    private merchantService: MerchantService
  ) {
    if (data) {
      this.isModal = true;

      if (data.merchantID) {
        this.merchantID = data.merchantID;
      }
    }
  }

  ngOnInit() {
    this.authenticationService.isPromoterOrAdmin().subscribe((isPromoterOrAdmin) => {
      this.isPromoterOrAdmin = isPromoterOrAdmin;

      this.authenticationService.getSessionType().subscribe((sessionType) => {
        this.sessionType = sessionType;

        this.fetchMerchant(this.isPromoterOrAdmin, this.merchantID, this.sessionType);
      });
    });
  }

  closeEvent(): void {
    this.closeModal.emit(true);
  }

  onSave(): void {
    const payload = {
      appointmentLimitToDays: this.appointmentLimitToDaysRadio === '1' ? '-1' : this.appointmentLimitToDaysInput,
      appointmentTimeStep: this.appointmentTimeStep,
      defaultBuffersPre: this.defaultBuffersPre,
      defaultBuffersPost: this.defaultBuffersPost,
    };

    if (this.isPromoterOrAdmin == true && this.merchantID) {
      this.merchantService.editMerchant(this.merchantID, payload).subscribe((res) => {
        if (res) {
          this.editedMerchant.emit(res);
          NotifyAppComponent.displayToast(
            'success',
            'Successful Operation',
            'You have successfully edited Appointment availability'
          );
          this.closeModal.emit(true);
        }
      });
    } else {
      this.authenticationService.editCurrentPractice(payload).subscribe((res) => {
        if (res) {
          this.editedMerchant.emit(res);
          NotifyAppComponent.displayToast(
            'success',
            'Successful Operation',
            'You have successfully edited Appointment availability'
          );
          this.closeModal.emit(true);
        }
      });
    }
  }

  private fetchMerchant(isPromoterOrAdmin: boolean, merchantID: string, sessionType: any): void {
    if (isPromoterOrAdmin == true && merchantID) {
      this.merchantService.getMerchantDetails(merchantID, {}, sessionType).subscribe((merchant) => {
        if (merchant && merchant.ID) {
          this.updateMerchant(merchant);
        }
      });
    } else {
      this.authenticationService.getCurrentPractice().subscribe((merchant) => {
        if (merchant && merchant.ID) {
          this.updateMerchant(merchant);
        }
      });
    }
  }

  private updateMerchant(merchant): void {
    this.merchant = merchant;
    this.merchantID = merchant.ID;

    const limitDays = merchant['Appointment_Limit_To_Days'];
    if (limitDays !== '-1') {
      this.appointmentLimitToDaysRadio = '2';
      this.appointmentLimitToDaysInput = limitDays;
    } else if (limitDays === '-1') {
      this.appointmentLimitToDaysRadio = '1';
    }

    const appointmentTimeStep = merchant['Appointment_Time_Step'];
    if (appointmentTimeStep) {
      this.appointmentTimeStep = appointmentTimeStep;
    }

    const defaultBuffersPre = merchant['Default_Buffers_Pre'];
    if (defaultBuffersPre) {
      this.defaultBuffersPre = defaultBuffersPre;
    }

    const defaultBuffersPost = merchant['Default_Buffers_Post'];
    if (defaultBuffersPost) {
      this.defaultBuffersPost = defaultBuffersPost;
    }
  }
}
