<div class="rel clearfix mb" *ngIf="filter">
  <div class="card-header primary-gradient-img clearfix inModal rel" *ngIf="viewModal == true">
    <div class="row clearfix">
      <button class="btn-close btn-clear mat-button white" *ngIf="isModal == true" (click)="closeEvent()">
        <mat-icon>clear</mat-icon>
      </button>
      <div class="row clearfix">
        <div class="row titleArea clearfix">
          <button class="pull-right" *ngIf="isEdit == true" (click)="edit()" mat-icon-button color="accent">
            <mat-icon class="fas fa-edit"></mat-icon>
          </button>

          <mat-icon class="pull-left">filter_list</mat-icon>
          <h2 class="summary-header rm-m">
            {{ filter['Label'] }}
          </h2>
        </div>
      </div>
      <div class="date-chip-area">
        <p class="small rm-m mr" *ngIf="filter.DateTimeCreated">
          <mat-icon class="fas fa-clock"></mat-icon>
          {{ filter.DateTimeCreated | TimeLabelPast }} ago
        </p>

        <p class="clearfix small rm-m">
          <span class="chip chip-enabled" *ngIf="filter['Active'] == '1'"> Enabled </span>
          <span class="chip chip-disabled" *ngIf="filter['Active'] != '1'"> Disabled </span>
        </p>
      </div>
    </div>
  </div>

  <h2 class="summary-header rm-mt" *ngIf="viewModal == false">
    <button class="pull-right" *ngIf="isEdit == true" (click)="edit()" mat-icon-button color="accent">
      <mat-icon class="fas fa-edit"></mat-icon>
    </button>

    {{ filter['Label'] }}
  </h2>

  <hr>

  <div class="row clearfix">
    <div class="row clearfix" *ngIf="filter['Description']">
      <label class="small">Filter Description</label>
      <p class="rm-mt">{{ filter['Description'] }}</p>
    </div>

    <div class="row clearfix">
      <div class="row clearfix well" *ngFor="let s of filter['Query']; let sectionIndex = index">
        <div class="andOr" *ngIf="filter['Query'].length > 1 && sectionIndex > 0">
          <span *ngIf="filter['Query'].length > 1 && s['Conjunction']['ToPrevious'] == 'and' && sectionIndex > 0"
          >And
          </span>
          <span *ngIf="filter['Query'].length > 1 && s['Conjunction']['ToPrevious'] == 'or' && sectionIndex > 0"
          >Or
          </span>
        </div>

        <label>
          Filter
          <span *ngIf="filter['Query'].length > 1"> {{ sectionIndex + 1 }}</span>
        </label>
        <h4 class="rm-mt">{{ s['Label'] }}</h4>

        <section class="rm-mt" *ngFor="let l of s['Lines']; let propertyIndex = index">
          <label>
            <span *ngIf="s['Lines'].length > 1 && s['Conjunction']['BetweenLines'] == 'and' && propertyIndex > 0"
            >AND
            </span>
            <span *ngIf="s['Lines'].length > 1 && s['Conjunction']['BetweenLines'] == 'or' && propertyIndex > 0"
            >OR
            </span>
            Property
            <span *ngIf="s['Lines'].length > 1"> {{ propertyIndex + 1 }}</span>
          </label>

          <p  *ngIf="hideTreatment!==true" class="rm-mt">{{ displayContent(s['TypeCode'], l) }}</p>
        </section>
      </div>
    </div>
  </div>

  <div class="text-center mt" *ngIf="isModal == true">
    <button (click)="closeEvent()" mat-raised-button color="accent" style="margin: 0px 10px">Close</button>

    <button (click)="openFilterView()" mat-raised-button color="primary" style="margin: 0px 10px">View Records</button>
  </div>

  <div class="full-width" *ngIf="filter.PatientIDs && isParams == true">
    <app-customer-prospect-list [filterID]="filterID"></app-customer-prospect-list>
  </div>

  <div class="full-width" *ngIf="isParams == true">
    <button class="pull-left" (click)="goback()" mat-raised-button color="primary">Back</button>
  </div>
</div>
