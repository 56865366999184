<div class="row clearfix">
  <div class="card-header primary-gradient-img clearfix inModal" *ngIf="isModal == true">
    <div class="row clearfix">
      <button class="btn-close btn-clear pull-right mat-button" (click)="closeModalEvent()">
        <mat-icon>clear</mat-icon>
      </button>

      <div class="row titleArea clearfix">
        <mat-icon class="pull-left fas fa-comment-dollar"></mat-icon>
        <h2 class="summary-header rm-m">Create Other Asset</h2>
      </div>
    </div>
  </div>

  <div class="row clearfix full-width cont" *ngIf="disabled == false">
    <div class="row clearfix flex">
      <div class="clearfix full-width">
        <div class="col-left">
          <h4 class="rm-mb">Operator modification</h4>
          <hr/>
          <div class="row clearfix full-width">
            <mat-form-field class="full-width" [ngClass]="highlightField('Updating_Reason.Code')" appearance="outline">
              <mat-label>Reason</mat-label>
              <mat-select [(ngModel)]="pcDetail.updatedReason" placeholder="Reason" name="reason" required>
                <mat-option *ngFor="let reason of persCircumUpdReasons" [value]="reason.Code">
                  {{ reason.Label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="clearfix full-width">
        <div class="col-left">
          <h4 class="rm-mb">Comments</h4>
          <hr/>
          <div class="row clearfix full-width">
            <mat-form-field
              class="full-width"
              [ngClass]="highlightField('Updating_Reason.Comment')"
              appearance="outline"
            >
              <mat-label>Leave a comment</mat-label>
              <textarea [(ngModel)]="pcDetail.updatedComment" matInput placeholder="Comment" name="comment"></textarea>
            </mat-form-field>
          </div>
        </div>
      </div>

      <!-- <div class="clearfix full-width">
              <div class="col-left">
                <h4 class="rm-mb">Files</h4>
                <hr>
                <div class="row clearfix full-width">
                  <app-file-uploader class=" text-center clearfix" [uploadDirectly]="true" [isDragable]="true" class="text-center" (onCompleteAll)="onCompleteAll($event)"
                    [limit]="1"></app-file-uploader>


                  <app-file-list class="compact" (remove)="removeFile($event)" [hasExtraInfo]="false" [canEdit]="true" [canAction]="false"
                    [files]="[fileID]"></app-file-list>
                </div>
              </div>
            </div> -->
    </div>
  </div>

  <div class="row clearfix full-width cont">
    <div class="row clearfix flex">
      <div class="clearfix full-width">
        <div class="row clearfix full-width">
          <mat-form-field class="full-width" [ngClass]="highlightField('Info.Description')" appearance="outline">
            <mat-label>Description</mat-label>
            <input [(ngModel)]="pcDetail.infoDescription" [disabled]="disabled" matInput placeholder="Description"/>
          </mat-form-field>

          <mat-form-field class="full-width" [ngClass]="highlightField('Asset.Value')" appearance="outline">
            <mat-label>Value</mat-label>
            <input [(ngModel)]="pcDetail.assetValue" [disabled]="disabled" matInput placeholder="Value" currencyMask/>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="row clearfix mt" *ngIf="disabled == false">
      <button
        class="pull-right"
        *ngIf="isModal == false"
        [disabled]="!pcDetail.updatedReason"
        (click)="submit()"
        mat-raised-button
        color="accent"
      >
        Submit
      </button>
      <button
        class="pull-right"
        *ngIf="isModal == true"
        [disabled]="!pcDetail.updatedReason"
        (click)="create()"
        mat-raised-button
        color="accent"
      >
        Create
      </button>
    </div>
  </div>
</div>
