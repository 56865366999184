<div class="container error-page">
  <mat-card class="error text-center">
    <div class="card-header error-gradient clearfix inModal">
      <div class="row clearfix text-left">
        <div class="row titleArea clearfix full-width">
          <mat-icon class="fas fa-wrench pull-left"></mat-icon>
          <h2 class="summary-header rm-m white">Site Offline</h2>
        </div>
      </div>
    </div>

    <img class="bannerImg"
         src="https://email-graphics-smileright.s3-ap-southeast-2.amazonaws.com/error-modal-banner.png"/>

    <div class="errorContent mb" *ngIf="!offlineMessage">
      <p class="lead">The site is currently offline for maintenance.</p>
      <p>Please check back again later.</p>
      <hr/>
    </div>

    <div class="errorContent mb" *ngIf="offlineMessage" [innerHTML]="offlineMessage | safeHtml"></div>

    <div class="button-row full-width text-center">
      <button class="mr ml" (click)="contactUs()" mat-raised-button color="warn">Contact us</button>

      <button class="mr ml" (click)="goHome()" mat-raised-button color="accent">
        Try Again <span *ngIf="countdown">({{ countdown / 1000 }}) </span>
      </button>
    </div>
  </mat-card>

  <p class="small information">
    If you continue to experience problems visiting {{ "BRANDING.Brand Name" | translate | titlecase }}, you might just
    need to
    clear your browser cache. You can find out how to do this on
    <a href="https://support.google.com/accounts/answer/32050?co=GENIE.Platform%3DDesktop&hl=en"
       target="_blank">Chrome</a>
    or <a href="https://support.apple.com/en-au/guide/safari/sfri47acf5d6/mac" target="_blank">Safari</a>
  </p>
</div>
