/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./security-code-info.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "@angular/common";
import * as i5 from "../shared/safe-html.pipe";
import * as i6 from "@angular/platform-browser";
import * as i7 from "@angular/material/dialog";
import * as i8 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i9 from "@angular/material/button";
import * as i10 from "@angular/cdk/a11y";
import * as i11 from "@angular/platform-browser/animations";
import * as i12 from "./security-code-info.component";
var styles_SecurityCodeInfoComponent = [i0.styles];
var RenderType_SecurityCodeInfoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SecurityCodeInfoComponent, data: {} });
export { RenderType_SecurityCodeInfoComponent as RenderType_SecurityCodeInfoComponent };
function View_SecurityCodeInfoComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "h2", [["class", "summary-header rm-m text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" For "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.name; _ck(_v, 3, 0, currVal_0); }); }
function View_SecurityCodeInfoComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "card-header primary-gradient-img clearfix inModal"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 12, "div", [["class", "row clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "button", [["class", "btn-close btn-clear mat-button white"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeEvent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(4, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["clear"])), (_l()(), i1.ɵeld(6, 0, null, null, 7, "div", [["class", "row clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "mat-icon", [["class", "pull-left mr mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(8, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["lock"])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "h3", [["class", "summary-header rm-m"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Two-factor authentication"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SecurityCodeInfoComponent_2)), i1.ɵdid(13, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 4, 0); _ck(_v, 8, 0); var currVal_4 = _co.name; _ck(_v, 13, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4).inline; var currVal_1 = (((i1.ɵnov(_v, 4).color !== "primary") && (i1.ɵnov(_v, 4).color !== "accent")) && (i1.ɵnov(_v, 4).color !== "warn")); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵnov(_v, 8).inline; var currVal_3 = (((i1.ɵnov(_v, 8).color !== "primary") && (i1.ɵnov(_v, 8).color !== "accent")) && (i1.ɵnov(_v, 8).color !== "warn")); _ck(_v, 7, 0, currVal_2, currVal_3); }); }
function View_SecurityCodeInfoComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["class", "bold rm-m text-center sr-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["Hi ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.name; _ck(_v, 1, 0, currVal_0); }); }
function View_SecurityCodeInfoComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "bold"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" : ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.cutIt(_co.mobileNumber); _ck(_v, 1, 0, currVal_0); }); }
function View_SecurityCodeInfoComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "row clearfix mb"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SecurityCodeInfoComponent_4)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 4, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" When you're ready to continue, click the button below and a secure code will be sent to your mobile ending in "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SecurityCodeInfoComponent_5)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, [". "])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Once you have the code, you'll be asked to enter it on the next screen."]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.isModal == false) && _co.name); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.mobileNumber; _ck(_v, 6, 0, currVal_1); }, null); }
function View_SecurityCodeInfoComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "row clearfix mb"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(1, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 0, 0, _ck(_v, 1, 0, i1.ɵnov(_v.parent, 0), _co.description)); _ck(_v, 0, 0, currVal_0); }); }
export function View_SecurityCodeInfoComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i5.SafeHtmlPipe, [i6.DomSanitizer]), (_l()(), i1.ɵeld(1, 0, null, null, 17, "div", [["class", "rel"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SecurityCodeInfoComponent_1)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 7, "mat-dialog-content", [["class", "text-center mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(5, 16384, null, 0, i7.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "row clearfix mb text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "img", [["class", "auth-img"], ["src", "https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/lock.gif"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SecurityCodeInfoComponent_3)), i1.ɵdid(9, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SecurityCodeInfoComponent_6)), i1.ɵdid(11, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 6, "div", [["class", "button-row mt text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 5, "button", [["class", "btn-large clearfix mb"], ["color", "accent"], ["mat-raised-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onYes() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_MatButton_0, i8.RenderType_MatButton)), i1.ɵdid(14, 180224, null, 0, i9.MatButton, [i1.ElementRef, i10.FocusMonitor, [2, i11.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, [" Send code! "])), (_l()(), i1.ɵeld(16, 0, null, 0, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(17, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["sms"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isModal == true); _ck(_v, 3, 0, currVal_0); var currVal_1 = !_co.description; _ck(_v, 9, 0, currVal_1); var currVal_2 = _co.description; _ck(_v, 11, 0, currVal_2); var currVal_5 = "accent"; _ck(_v, 14, 0, currVal_5); _ck(_v, 17, 0); }, function (_ck, _v) { var currVal_3 = (i1.ɵnov(_v, 14).disabled || null); var currVal_4 = (i1.ɵnov(_v, 14)._animationMode === "NoopAnimations"); _ck(_v, 13, 0, currVal_3, currVal_4); var currVal_6 = i1.ɵnov(_v, 17).inline; var currVal_7 = (((i1.ɵnov(_v, 17).color !== "primary") && (i1.ɵnov(_v, 17).color !== "accent")) && (i1.ɵnov(_v, 17).color !== "warn")); _ck(_v, 16, 0, currVal_6, currVal_7); }); }
export function View_SecurityCodeInfoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-security-code-info", [], null, null, null, View_SecurityCodeInfoComponent_0, RenderType_SecurityCodeInfoComponent)), i1.ɵdid(1, 114688, null, 0, i12.SecurityCodeInfoComponent, [[2, i7.MAT_DIALOG_DATA]], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SecurityCodeInfoComponentNgFactory = i1.ɵccf("app-security-code-info", i12.SecurityCodeInfoComponent, View_SecurityCodeInfoComponent_Host_0, { mobileNumber: "mobileNumber", name: "name", description: "description", isModal: "isModal" }, { onConfirm: "onConfirm", closeModal: "closeModal" }, []);
export { SecurityCodeInfoComponentNgFactory as SecurityCodeInfoComponentNgFactory };
