<div class="row clearfix" *ngIf="tag">
  <div class="card-header primary-gradient-img clearfix inModal rel">
    <div class="row clearfix">
      <button class="btn-close btn-clear mat-button white" (click)="closeEvent()">
        <mat-icon>clear</mat-icon>
      </button>
      <div class="row clearfix">
        <div class="row titleArea clearfix">
          <mat-icon class="pull-left">filter_list</mat-icon>
          <h2 class="summary-header rm-m">
            {{ tag['Label'] }}
          </h2>
        </div>
      </div>
    </div>
  </div>

  <div class="well tag">
    <label>Tag Label:</label>

    <h3 class="sr-title rm-mb">{{ tag.Label }}</h3>

    <div *ngIf="tag.Description">
      <label class="mt">Tag Description:</label>
      <p class="">{{ tag.Description }}</p>
    </div>
  </div>

  <div class="text-center mt">
    <button (click)="closeEvent()" mat-raised-button color="accent" style="margin: 0px 10px">Close</button>

    <button (click)="viewTag()" mat-raised-button color="primary" style="margin: 0px 10px">View Records</button>
  </div>
</div>
