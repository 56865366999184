import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';

import { MatSort } from '@angular/material';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Settings } from '../../types/settings';

@Component({
  selector: 'ipv-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.css'],
})
export class DataTableComponent implements OnInit, OnChanges, AfterViewInit {
  @Output()
  itemClicked = new EventEmitter<unknown>();

  @Output()
  itemChecked = new EventEmitter<unknown[]>();

  @Output()
  tableUpdated = new EventEmitter<any>();

  @Output()
  pageChanged = new EventEmitter<PageEvent>();

  @Input() table: { [key: string]: unknown }[] = [];
  @Input() hide: string[] = [];
  @Input() order: string[] = [];
  @Input() sortable: string[] = [];
  @Input() rename: { [key: string]: string } = {};
  @Input() customComponents: { [key: string]: TemplateRef<any> } = {};
  @Input() isFixedFirstRow = true;
  @Input() search: string;
  @Input() searchExceptions: string[];
  @Input() paginate = true;
  @Input() formatHeaders: { [key: string]: 'left' | 'right' | 'center' } = {};

  @Input() footerRow: { [key: string]: unknown } = null;
  @Input() footerCustomComponents: { [key: string]: TemplateRef<any> } = {};
  @Input() formatFooters: { [key: string]: 'left' | 'right' | 'center' } = {};
  @Input() footerSectionBackgroundColor = 'white';

  @Input() sections: { value: any; color: string; fontColor: string; customLayout?: boolean }[] = [];
  @Input() sectionColumn: string = null;

  @Input() pageSize = Settings.global['listPageSize'] || 20;
  @Input() pageIndex = 0;
  pageSizeOptions = [10, Number(this.pageSize), Number(this.pageSize) * 2, Number(this.pageSize) * 3];

  @Input() menuTemplate: TemplateRef<any>;

  @Input() titleCaseEnabled = true;
  @Input() hasCheckboxes = false;
  @Input() checkboxIdKey = 'ID';

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('isChecked', { static: true }) private isCheckedTemplate: TemplateRef<any>;

  headers: { key: string; title: string }[] = [];
  headerKeys: string[];

  datasource = new MatTableDataSource<{ [key: string]: unknown }>();

  constructor() {}

  ngOnInit() {
    this.datasource = new MatTableDataSource<{ [p: string]: unknown }>(this.table);

    if (this.paginate) {
      this.datasource.paginator = this.paginator;
      this.datasource.data = [...this.table];
    }
  }

  ngAfterViewInit() {
    this.datasource.sort = this.sort;
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (this.hasCheckboxes) {
      this.table = this.table.map((record) => ({ isChecked: false, ...record }));
      this.rename['isChecked'] = '';
      if (this.order.findIndex((item) => item === 'isChecked') === -1) {
        this.order.unshift('isChecked');
      }
      this.customComponents['isChecked'] = this.isCheckedTemplate;
    }

    this.generateHeaders();
    this.hideHeaders();
    this.orderHeaders();
    this.renameHeaders();
    this.extractHeaderKeys();

    const tableChanges = simpleChanges.table;

    if (tableChanges) {
      if (tableChanges.previousValue && tableChanges.currentValue) {
        if (tableChanges.previousValue !== tableChanges.currentValue) {
          this.datasource.data = [...this.table];
        }
      }
    }

    const searchChanges = simpleChanges.search;

    if (searchChanges) {
      if (searchChanges.previousValue && searchChanges.currentValue) {
        if (searchChanges.previousValue !== searchChanges.currentValue) {
          //
          const foundException =
            this.searchExceptions.findIndex((searchException) => searchException === this.search) > -1;

          if (!foundException) {
            this.datasource.filter = this.search.trim().toLowerCase();
          } else {
            this.datasource.filter = undefined;
          }
        }
      }
    }
  }

  generateHeaders() {
    this.headers = [];

    this.table.forEach((row) => {
      const keys = Object.keys(row);

      keys.forEach((key) => {
        const foundKey = this.headers.findIndex((header) => key === header.key) > -1;

        if (!foundKey) {
          this.headers.push({ key, title: key });
        }
      });
    });
  }

  orderHeaders() {
    if (this.order.length > 0) {
      let targetHeaders = this.headers.map((header) => header.key);

      this.order.forEach((headerItem) => {
        targetHeaders = targetHeaders.filter((oldHeader) => oldHeader !== headerItem);
      });

      this.headers = this.order.concat(targetHeaders).map((header) => ({ key: header, title: header }));
    }
  }

  hideHeaders() {
    if (this.hide.length > 0) {
      let targetHeaders = this.headers.map((header) => header.key);

      this.hide.forEach((headerItem) => {
        targetHeaders = targetHeaders.filter((oldHeader) => oldHeader !== headerItem);
      });

      this.headers = targetHeaders.map((header) => ({
        key: header,
        title: header,
      }));
    }
  }

  renameHeaders() {
    if (Object.keys(this.rename).length > 0) {
      for (const selectedKey in this.rename) {
        if (this.rename.hasOwnProperty(selectedKey)) {
          const headerItemIndex = this.headers.findIndex((header) => header.key === selectedKey);

          const foundHeader = headerItemIndex > -1;

          if (foundHeader) {
            this.headers[headerItemIndex].title = this.rename[selectedKey];
          }
        }
      }
    }
  }

  extractHeaderKeys() {
    const originalHeaderKeys = this.headers.map((header) => header.key);

    if (this.menuTemplate) {
      originalHeaderKeys.push('actions');
    }

    this.headerKeys = originalHeaderKeys;
  }

  detectedCustomComponent(key: string): boolean {
    return this.customComponents.hasOwnProperty(key);
  }

  detectedFooterCustomComponent(key: string): boolean {
    return this.footerCustomComponents.hasOwnProperty(key);
  }

  isSortable(key: string) {
    const sortableItemIndex = this.sortable.findIndex((header) => header === key);

    return sortableItemIndex > -1;
  }

  getAssociatedTemplate(key: string): TemplateRef<any> {
    if (this.customComponents.hasOwnProperty(key)) {
      return this.customComponents[key];
    }
  }

  getAssociatedFooterTemplate(key: string): TemplateRef<any> {
    if (this.footerCustomComponents.hasOwnProperty(key)) {
      return this.footerCustomComponents[key];
    }
  }

  getHeaderFormatting(key: string): 'left' | 'right' | 'center' {
    const formattingItemIndex = Object.keys(this.formatHeaders).findIndex((header) => header === key);

    if (formattingItemIndex > -1) {
      return this.formatHeaders[key];
    } else {
      return 'left';
    }
  }

  getFooterRowLength() {
    if (this.footerRow) {
      return Object.keys(this.footerRow).length;
    } else {
      return 0;
    }
  }

  emitItem(item: unknown, header: string) {
    if (header !== 'isChecked') {
      this.itemClicked.emit(item);
    }
  }

  getSectionColor(item) {
    const sections = this.sections.filter((section) => section.value === item[this.sectionColumn]);
    if (sections.length > 0) {
      return sections[0].color;
    } else {
      return 'white';
    }
  }

  getSectionFontColor(item) {
    const sections = this.sections.filter((section) => section.value === item[this.sectionColumn]);
    if (sections.length > 0) {
      return sections[0].fontColor;
    } else {
      return 'black';
    }
  }

  isCustomSection(item) {
    const sections = this.sections.filter((section) => section.value === item[this.sectionColumn]);
    if (sections.length > 0) {
      return sections[0].customLayout;
    } else {
      return false;
    }
  }

  setIsChecked(isChecked: boolean, isCheckedItem: unknown) {
    const id = isCheckedItem[this.checkboxIdKey];
    const tableSnapshot = this.table;
    const index = tableSnapshot.findIndex((item) => item[this.checkboxIdKey] === id);
    tableSnapshot[index].isChecked = isChecked;
    this.itemChecked.emit(this.table);
  }

  getPageEvent(pageEvent: PageEvent) {
    this.pageChanged.emit(pageEvent);
  }
}
