<mat-card class="rel supplier-card brandView clearfix mb">
  <form #addForm="ngForm">
    <div class="card-header primary-gradient-img clearfix inModal rel">
      <div class="row clearfix content">
        <button class="btn-close btn-clear mat-button white" (click)="closeModal()">
          <mat-icon>clear</mat-icon>
        </button>
        <div class="row titleArea clearfix">
          <h2 class="summary-header rm-m">
            Drugs or Medication
            <span class="subLabel">
              Let your {{ "KEYWORD.practitioner" | translate | titlecase }} know about the drugs or medications you are taking.
            </span>
          </h2>
        </div>
      </div>
    </div>
    <mat-dialog-content class="stacked-card-view indOvf">
      <div class="row clearfix drugs-input-container mt">
        <div class="full-width clearfix">
          <button
            class="pull-right mb"
            (click)="openSearch()"
            mat-raised-button
            color="primary"
            matTooltip="You can use the Medication lookup to check our
          list for existing drugs."
          >
            <mat-icon>search</mat-icon> Medication lookup
          </button>

          <h4 class="sr-title rm-m sm-h4 clearfix mb">Basic details</h4>
        </div>
        <div class="full-width clearfix">
          <div class="full-width flex full-width clearfix">
            <mat-form-field class="full-width animate" appearance="outline">
              <mat-label>Name of drug or medication</mat-label>
              <input
                class="mat-block"
                [(ngModel)]="drug.name"
                (change)="searchDrug(drug.name)"
                matInput
                placeholder="Name of drug or medication"
                name="drugname1"
                required
              />
            </mat-form-field>

            <button
              *ngIf="drug && drug.drugID"
              (click)="viewDrug(drug.drugID)"
              mat-icon-button
              color="primary"
              matTooltip="View information"
            >
              <mat-icon class="primary-color">visibility_on</mat-icon>
            </button>
          </div>

          <div class="full-width clearfix flex">
            <mat-form-field class="full-width animate mr" appearance="outline">
              <mat-label>Type of drug or medication</mat-label>
              <mat-select
                class="full-width animate"
                [(ngModel)]="drug.drugType"
                placeholder="Select type of drug or medication"
                name="drugType"
              >
                <mat-option *ngFor="let DrugType of drugTypes | OrderArrayObject: 'Label'" [value]="DrugType.Code">
                  {{ DrugType.Label }}
                </mat-option>
                <mat-option [value]="'other'"> Other</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field
              class="full-width animate"
              *ngIf="drug.drugType == 'other'"
              appearance="outline"
            >
              <mat-label>Enter Type of medication</mat-label>
              <input
                class="mat-block"
                [(ngModel)]="drug.drugTypeOther"
                matInput
                placeholder="Enter Type of medication"
                name="drugTypeOther"
              />
            </mat-form-field>
          </div>
        </div>
        <div class="full-width clearfix">
          <h4 class="sr-title rm-m sm-h4 clearfix mb">When did u start taking the drug or medication ?</h4>

          <div class="row clearfix flex text-center full-width" @simpleFadeAnimation>
            <app-date-month-input
              class="full-width"
              *ngIf="showStartDate == true"
              [defaultDays]="1"
              [defaultDate]="startDate"
              [startView]="'multi-year'"
              [isTime]="false"
              [maxDate]="'toDay'"
              [isClear]="true"
              (selectedDate)="selectDateToFilter($event, 'startDate')"
              label="Start date"
            >
            </app-date-month-input>
            <app-date-month-input
              class="full-width"
              *ngIf="showStopDate == true"
              [defaultDays]="'last'"
              [defaultDate]="stopDate"
              [startView]="'multi-year'"
              [isTime]="false"
              [minDate]="drug?.startDate"
              [maxDate]="'toDay'"
              [isClear]="true"
              (selectedDate)="selectDateToFilter($event, 'stopDate')"
              label="Stop date"
            >
            </app-date-month-input>
          </div>

          <div class="row clearfix flex text-center full-width" @simpleFadeAnimation>
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>How often do you take it ?</mat-label>
              <mat-select
                class="full-width animate"
                [(ngModel)]="drug.drugFrequency"
                placeholder="How often do you take it ?"
                name="drugTakenMeasurement"
              >
                <mat-option *ngFor="let f of doseFrequencies | OrderArrayObject: 'Label'" [value]="f.Code">
                  {{ f.Label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <!-- shown when type and name is selected -->

        <h4 class="sr-title rm-m sm-h4 full-width clearfix mb">Dosages</h4>

        <div class="full-width clearfix flex mr">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>How is it taken or applied?</mat-label>
            <mat-select
              class="full-width animate"
              [(ngModel)]="drug.doseType"
              placeholder="Select how your dose is taken"
              name="drugTakenType"
            >
              <mat-option
                *ngFor="let DrugDoseType of doseTypes | OrderArrayObject: 'Label'"
                [value]="DrugDoseType.Code"
              >
                {{ DrugDoseType.Label }}
              </mat-option>
              <mat-option [value]="'other'"> Other</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field
            class="full-width animate"
            *ngIf="drug.doseType == 'other'"
            appearance="outline"
          >
            <mat-label>Enter the dosage type</mat-label>
            <input
              class="mat-block"
              [(ngModel)]="drug.doseTypeOther"
              matInput
              placeholder="Enter the dose type"
              name="doseTypeOther"
            />
          </mat-form-field>
        </div>
        <div class="full-width clearfix flex">
          <mat-form-field class="full-width animate mr" appearance="outline">
            <mat-label>How many do you take ?</mat-label>
            <input
              class="mat-block"
              [(ngModel)]="drug.doseAmount"
              matInput
              type="number"
              placeholder="How many times do you take?"
              name="drugOtherDose"
            />
          </mat-form-field>

          <mat-form-field class="full-width mr" appearance="outline">
            <mat-label>How is it measured?</mat-label>
            <mat-select
              class="full-width animate"
              [(ngModel)]="drug.doseUnit"
              placeholder="Select the dose you're prescribed"
              name="drugTakenMeasurement"
            >
              <mat-option
                *ngFor="let DrugDoseMeasurement of doseUnits | OrderArrayObject: 'Label'"
                [value]="DrugDoseMeasurement.Code"
              >
                {{ DrugDoseMeasurement.Label }}
              </mat-option>
              <mat-option [value]="'other'"> Other</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field
            class="full-width animate"
            *ngIf="drug.doseUnit == 'other'"
            appearance="outline"
          >
            <mat-label>Enter the dosage unit</mat-label>
            <input
              class="mat-block"
              [(ngModel)]="drug.doseUnitOther"
              matInput
              placeholder="Enter the dose unit"
              name="doseUnitOther"
            />
          </mat-form-field>
        </div>

        <div class="row clearfix flex text-center full-width" @simpleFadeAnimation>
          <mat-form-field class="full-width animate" appearance="outline">
            <mat-label> is there anything else you want to tell us</mat-label>
            <textarea
              class="mat-block"
              [(ngModel)]="drug.note"
              matInput
              placeholder="do you have something to mention"
              name="templatefacebookPost"
            ></textarea>
          </mat-form-field>
        </div>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions>
      <div class="full-width drop-button clearfix text-center" @simpleFadeAnimation>
        <button class="mr" (click)="clearDrug()" mat-raised-button color="primary">
          <mat-icon>clear</mat-icon> Clear
        </button>
        <button
          class="ml"
          *ngIf="!medicationID"
          [disabled]="!addForm.form.valid"
          (click)="addMedication()"
          mat-raised-button
          color="accent"
        >
          <mat-icon>add</mat-icon> Add medication
        </button>
        <button
          class="ml"
          *ngIf="medicationID"
          [disabled]="!addForm.form.valid"
          (click)="addMedication()"
          mat-raised-button
          color="accent"
        >
          <mat-icon>edit</mat-icon> Edit medication
        </button>
      </div>
    </mat-dialog-actions>
  </form>
</mat-card>
