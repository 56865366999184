import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { InvitationTemplateLookupComponent } from '../../../feature/invitation-template/invitation-template-lookup/invitation-template-lookup.component';
import { InvitationTemplateMergerComponent } from '../../../feature/invitation-template/invitation-template-merger/invitation-template-merger.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { AuthenticationService } from '../../authentication/shared/authentication.service';
import { ThirdPartyService } from '../shared/third-party.service';

@Component({
  selector: 'app-third-party-client-quick-edit',
  templateUrl: './third-party-client-quick-edit.component.html',
  styleUrls: ['./third-party-client-quick-edit.component.css'],
})
export class ThirdPartyClientQuickEditComponent implements OnInit {
  @Input()
  thirdPartyClientID;

  @Input()
  thirdPartyClient;

  updateAll = true;
  templateIDOld;

  generateNew = false;

  secretKey;
  secretID;

  isAdressValid = false;
  isPhoneValid = false;
  isEmailValid = false;
  sessionType;

  label;
  description;
  isActive = true;
  _phone;
  _email;

  phone;
  email;

  templateID;
  templateLabel;

  @Output() close = new EventEmitter();
  @Output() getResult = new EventEmitter();

  constructor(
    private authenticationService: AuthenticationService,
    private thirdPartyService: ThirdPartyService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.thirdPartyClientID) {
        this.thirdPartyClientID = data.thirdPartyClientID;
      }
    }
  }

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((res) => {
      this.sessionType = res;

      this.setup();
    });
  }

  setup() {
    if (this.thirdPartyClientID) {
      this.thirdPartyService
        .getThirdPartyClientDetails(this.thirdPartyClientID, {}, this.sessionType)
        .subscribe((res) => {
          if (res) {
            this.thirdPartyClient = res;

            this.buildItem();
          }
        });
    }
  }

  buildItem() {
    if (this.thirdPartyClient && this.thirdPartyClient.ID) {
      if (this.thirdPartyClient['Label']) {
        this.label = this.thirdPartyClient['Label'];
      }

      if (this.thirdPartyClient['Description']) {
        this.description = this.thirdPartyClient['Description'];
      }

      if (this.thirdPartyClient['Is_Active'] === '0') {
        this.isActive = false;
      } else if (this.thirdPartyClient['Is_Active'] === '1') {
        this.isActive = true;
      }

      if (this.thirdPartyClient['Phone']) {
        this._phone = this.thirdPartyClient['Phone'];
      }

      if (this.thirdPartyClient['Template_Key']) {
        this.templateID = this.thirdPartyClient['Template_Key'];
        this.templateIDOld = this.thirdPartyClient['Template_Key'];
      }

      if (this.thirdPartyClient['Template_Label']) {
        this.templateLabel = this.thirdPartyClient['Template_Label'];
      }

      if (this.thirdPartyClient['Secrect_ID']) {
        this.secretID = this.thirdPartyClient['Secrect_ID'];
      }

      if (this.thirdPartyClient['Secrect_Key']) {
        this.secretKey = this.thirdPartyClient['Secrect_Key'];
      }

      if (this.thirdPartyClient['Email']) {
        this._email = this.thirdPartyClient['Email'];
      }
    }
  }

  isPhoneValidEvent(v) {
    this.isPhoneValid = v || false;
  }

  isEmailValidEvent(v) {
    this.isEmailValid = v || false;
  }

  getPhone(d) {
    this.phone = d;
  }

  getEmail(d) {
    this.email = d;
  }

  closeEvent() {
    this.close.emit(true);
  }

  save() {
    if (this.thirdPartyClientID) {
      const p = {
        label: this.label,
        description: this.description,
        isActive: this.isActive,
        phone: this.phone,
        email: this.email,
        secretKey: this.secretKey,
        secretID: this.secretID,
        generateNew: this.generateNew,
        templateID: this.templateID,
        templateLabel: this.templateLabel,
        updateAll: this.updateAll,
      };

      this.thirdPartyService.modifyThirdPartyClient(this.thirdPartyClientID, p).subscribe((res) => {
        if (res) {
          res.updateAll = this.updateAll;
          res.templateIDOld = this.templateIDOld;
          res.templateID = this.templateID;

          NotifyAppComponent.displayToast('success', 'Success!', 'Application is Modified');

          this.getResult.emit(res);
        }
      });
    }
  }

  openTemplateLookup() {
    const ref = RootAppComponent.dialog.open(InvitationTemplateLookupComponent, {
      data: {
        type: 'default',
        isModal: true,
        viewOnClick: false,
        viewOnly: false,
        hideTreatmentValue: true,
        isInvitationContext: false,
      },
      panelClass: 'bigger-screen',
      width: '820px',
    });
    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });

    ref.componentInstance.getTemplate.subscribe((result) => {
      if (result && result._id && result.label) {
        this.templateID = result._id;
        this.templateLabel = result.label;
        ref.close();
      }
    });
  }

  viewTemplate(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(InvitationTemplateMergerComponent, {
        data: {
          templateID: id,
          viewOnly: true,
          hideTreatmentValue: true,
        },
        width: '1200px',
      });
      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    }
  }
}
