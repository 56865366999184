import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { SideMenuService } from '../../../shared/services/side-menu.service';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { InvitationService } from '../shared/services/invitation.service';
import { InvitationUtilClass } from '../shared/types/invitation-util.class';

@Component({
  selector: 'app-invitation-overview',
  templateUrl: './invitation-overview.component.html',
  styleUrls: ['./invitation-overview.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('mat-card', style({ opacity: 0 }), { optional: true }),
        query(
          'mat-card',
          stagger('100ms', [
            animate(
              '0.4s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-50px)', offset: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('mat-card', style({ opacity: 1 }), { optional: true }),
        query(
          'mat-card',
          stagger('100ms', [
            animate(
              '0.4s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-50px)', offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
    trigger('labelAnimation', [
      transition('void => *', [
        query('p', style({ opacity: 0 }), { optional: true }),
        query(
          'p',
          stagger('100ms', [
            animate(
              '0.15s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateX(-30px)', offset: 0 }),
                style({ opacity: 1, transform: 'translateX(0)', offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class InvitationOverviewComponent implements OnInit {
  @Input()
  membership = false;

  displayInvitationList = false;
  chartColors = Settings.global['chartColors'];
  mouseOvered;
  mouseOvered0;
  mouseOvered1;
  mouseOvered2;
  mouseOvered3;
  mouseOvered4;

  invitationUtil = new InvitationUtilClass();

  util = new UtilsClass();
  isPromoterOrAdmin = false;
  _merchantID = null;
  _dateFrom;
  _dateTo;
  _status = null;

  merchantID = null;
  dateTo = null;
  dateFrom = null;

  invitationOverViewStatistics;
  invitationStatistics;

  merchantIDInvitation = null;
  dentistIDInvitation = null;
  productIDInvitation = null;
  dateFromInvitation;
  dateToInvitation;
  statusInvitation;

  constructor(
    private router: Router,
    private AuthenticationService: AuthenticationService,
    private invitationService: InvitationService,
    private dialog: MatDialog
  ) {
    // let ref = this.dialog.open(PerformanceIntroModalComponent, {
    //   width: "800px",
    //   panelClass: "noCard"
    // });
    // if (ref.componentInstance.showOnPerformanceFirstVisit == true) {
    //   ref.close();
    // }
    // else {
    //   const sub = ref.componentInstance.closeModal.subscribe(data => {
    //     ref.close();
    //   });
    // }
  }

  ngOnInit() {
    SideMenuService.goBack.subscribe((res) => {
      if (res == true) {
        this.goBack();
      }
    });

    this.AuthenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;

      this.applySearch();
    });
  }

  getMerchant(d) {
    this._merchantID = d;
  }

  getDateFrom(d) {
    this._dateFrom = d;
  }

  getDateTo(d) {
    this._dateTo = d;
  }

  getResult(r) {
    this.merchantID = r.merchantID;
    this.dateFrom = r.dateFrom;
    this.dateTo = r.dateTo;
    this.applySearch();
  }

  applySearch() {
    let payload = {};

    if (this.isPromoterOrAdmin == true) {
      payload = {
        merchantID: this.merchantID,
        dateTo: this.util.EPdateFormat(this.dateTo),
        dateFrom: this.util.EPdateFormat(this.dateFrom),
        selectMerchant: false,
        selectProduct: false,
        selectDentist: false,
        selectYears: false,
        selectMonths: false,
        selectDays: false,
        selectDateRange: false,
      };
    } else {
      payload = {
        dateTo: this.util.EPdateFormat(this.dateTo),
        dateFrom: this.util.EPdateFormat(this.dateFrom),
        selectMerchant: false,
        selectProduct: false,
        selectDentist: false,
        selectYears: false,
        selectMonths: false,
        selectDays: false,
        selectDateRange: false,
      };
    }

    this.invitationService.getStatistics(payload, this.isPromoterOrAdmin, this.membership).subscribe((res1) => {
      if (res1) {
        this.invitationStatistics = res1;
      }
    });

    this.invitationService.getOverviewStats(payload, this.isPromoterOrAdmin, this.membership).subscribe((res) => {
      // console.log(res);

      if (res) {
        for (const key in res) {
          if (res.hasOwnProperty(key)) {
            if (Array.isArray(res[key])) {
              for (let i = 0; i < res[key].length; i++) {
                res[key][i]['name'] = this.invitationUtil.getStatusLabel(res[key][i]['ID']);
              }
            }
          }
        }

        this.invitationOverViewStatistics = res;
      }
    });
  }

  logit(s) {}

  arrowDirection(array, index, attribute) {
    const previousIndex = index - 1;

    if (!array || array[index] == null || array[index][attribute] == null || Number(array[index][attribute]) == 0) {
      return false;
    } else if (index == 0) {
      return true;
    } else if (
      array[index] != null &&
      array[index][attribute] != null &&
      array[previousIndex] != null &&
      array[previousIndex][attribute] != null &&
      Number(array[index][attribute]) >= Number(array[previousIndex][attribute])
    ) {
      return true;
    } else {
      return false;
    }
  }

  goBack() {
    this.displayInvitationList = false;
  }

  displayList(dateFrom, dateTo, merchantIDInvitation, dentistIDInvitation, productIDInvitation, statusInvitation) {
    this.dateFromInvitation = dateFrom;
    this.dateToInvitation = dateTo;
    this.merchantIDInvitation = merchantIDInvitation;
    this.dentistIDInvitation = dentistIDInvitation;
    this.productIDInvitation = productIDInvitation;
    this.statusInvitation = statusInvitation;
    this.displayInvitationList = true;
  }

  performance() {
    this.router.navigate(['/merchant', { outlets: { page: ['invitation-performance'] } }]);
  }
}
