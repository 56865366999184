<div
  class="clearfix merchant-calculator calculator"
  *ngIf="productObject && estimateObject && durationObject && ProductID"
  @ngIfAnimation
  [class.customers]="isModal == true"
>
  <mat-dialog-content>
    <div class="calcContainer" [class.active]="isModal == true">
      <div class="row clearfix mt mb heading product-calc-header" [class.primary-gradient-img]="isModal == true">
        <div class="row clearfix" *ngIf="isModal == true">
          <!-- Modal dismiss button -->
          <button class="btn-close btn-clear mat-button pull-right" (click)="closePage()">
            <mat-icon>clear</mat-icon>
          </button>
        </div>

        <h1 class="sr-title text-center rm-m" *ngIf="productObject && productObject['MarketingLabel']">
          {{ productObject['MarketingLabel'] }}
        </h1>

        <button
          class="pull-right viewProduct"
          *ngIf="ProductID && ProductID != '00000000000000000000000000000000'"
          (click)="openProductViewDialog()"
          mat-raised-button
          color="accent"
          matTooltip="View Product "
        >
          Product details <mat-icon>business_center</mat-icon>
        </button>
      </div>
      <div class="greyArea clearfix">
        <div class="full-width">
          <div class="column text-center breakdown highlight">
            <p class="rm-m">Regular Repayments</p>

            <h1 class="sr-title rm-m">
              {{
              toNumber(estimateObject['Repayments.Regular']) +
              toNumber(estimateObject['Fees.Account_Keeping.Approx.PerRepayment']) | customCurrency
              }}
            </h1>
            <p class="small rm-mt">{{ estimateObject['Frequency.Units'] }}</p>

            <hr/>
            <p class="rm-mb" [class.positive]="isProceedButton == true && depositInc == true">
              Your first repayment will be
              <strong>{{
                toNumber(estimateObject['Deposit']) + toNumber(estimateObject['Repayments.First']) | customCurrency
                }}</strong>
            </p>
            <p class="small rm-mt">(First repayment &amp; deposit if required)</p>
          </div>
        </div>
      </div>

      <div class="row full-width clearfix flex mt mb animate" *ngIf="isTop == true && retailInfo == true">
        <div class="full-width animate">
          <div class="column text-center breakdown">
            <p class="small">Minimum Deposit Required</p>
            <hr class="display-block text-center"/>
            <h1 class="sm-h1 sr-title rm-mb">{{ toNumber(estimateObject['Deposit']) | customCurrency }}</h1>
            <p class="small rm-mt"></p>
          </div>
        </div>

        <div class="full-width animate">
          <div class="column text-center breakdown">
            <p class="small">Interest Rate</p>
            <hr class="display-block text-center"/>
            <h1 class="sm-h1 sr-title rm-mb">
              {{ toNumber(estimateObject['Interest.Rate']) / 100 | percent: '2.1-1' }}
            </h1>
          </div>
        </div>
        <div class="full-width animate" *ngIf="dentalInfo == false">
          <div class="column text-center breakdown highlight">
            <p class="small">Regular Repayments</p>
            <hr class="display-block text-center"/>
            <h1 class="sm-h1 sr-title rm-mb">
              {{
              toNumber(estimateObject['Repayments.Regular']) +
              toNumber(estimateObject['Fees.Account_Keeping.Approx.PerRepayment']) | customCurrency
              }}
            </h1>
            <p class="small rm-mt">{{ estimateObject['Frequency.Units'] }}</p>
          </div>
        </div>

        <div class="full-width animate" *ngIf="dentalInfo == false">
          <div class="column text-center breakdown">
            <p class="small">Total Paid</p>
            <hr class="display-block text-center"/>
            <h1 class="sm-h1 sr-title rm-mb" *ngIf="productObject['SubType'] == 'Payment Plan'">
              {{ toNumber(estimateObject['Repayments.Total']) + toNumber(estimateObject['Deposit']) | customCurrency }}
            </h1>
            <h1 class="sm-h1 sr-title rm-mb" *ngIf="productObject['SubType'] != 'Payment Plan'">
              {{ estimateObject['Repayments.Total'] | customCurrency }}
            </h1>
          </div>
        </div>
      </div>

      <div class="clearfix row">
        <div class="toggleSelect" *ngIf="isFrequency == true">
          <!-- <div class="col-left"> -->
          <div class="clearfix row text-center">
            <h3 class="sr-title rm-mb">How often would you like to make repayments?</h3>
            <mat-button-toggle-group
              class="full-width btn-group text-center"
              [(ngModel)]="frequency"
              (ngModelChange)="changeFrequency()"
            >
              <mat-button-toggle class="text-center" *ngFor="let freq of frequencys" [value]="freq">
                {{ durationLabel(freq) }}
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <!-- </div> -->
        </div>
      </div>

      <h3 class="clearfix primary-color text-center">Select loan duration</h3>
      <div class="selector-row row clearfix mb" *ngIf="dynamicAmount == true">
        <app-amount-slider-input
          [minAmount]="productObject['Parameter.MinLoanValue']"
          [maxAmount]="productObject['Parameter.MaxLoanValue']"
          [amount]="amountInput"
          (selectAmount)="selectAmount($event)"
        ></app-amount-slider-input>
      </div>
      <div class="selector-row row clearfix mb">
        <app-duration-slider-input
          [frequency]="frequency"
          [minPeriod]="minDuration"
          [maxPeriod]="maxDuration"
          [period]="durationInput"
          (selectPeriod)="selectDuration($event)"
        ></app-duration-slider-input>
      </div>

      <p class="small info" *ngIf="isModal == true">
        <mat-icon class="fas fa-random"></mat-icon>
        Before proceeding with your chosen payment option, you can adjust the repayment period and frequency to best
        suit your situation.
      </p>

      <div
        class="clearfix row full-width"
        *ngIf="depositInc == true && isPatientContribution == true && isFrequency == true"
      >
        <!-- show if deposit required -->
        <mat-form-field class="animated full-width fadeInDown mt" appearance="outline">
          <mat-label
          >Customer Contribution, which should be less than
            {{ toNumber(amount) - toNumber(productObject['Parameter.MinLoanValue']) | customCurrency }}</mat-label
          >

          <input
            class="mat-block"
            [(ngModel)]="patientContribution"
            [max]="toNumber(amount) - toNumber(productObject['Parameter.MinLoanValue'])"
            (change)="changePatientContribution()"
            name="contribution2"
            matInput
            value=""
            placeholder="Customer Contribution, which should be less than {{
              toNumber(amount) - toNumber(productObject['Parameter.MinLoanValue']) | customCurrency
            }}"
          />
        </mat-form-field>
      </div>
      <div class="clearfix row full-width" *ngIf="isFrequency == false">
        <div
          class="patient-contribution clearfix pull-left thrd-width"
          *ngIf="isPatientContribution == true && toNumber(amount) > toNumber(productObject['Parameter.MinLoanValue'])"
        >
          <mat-checkbox [(ngModel)]="depositInc" (ngModelChange)="changeContribution()" name="depositInc22"
          >Customer Contribution
          </mat-checkbox>
        </div>

        <!-- show if deposit required -->
        <mat-form-field
          class="animated two-thrd-width fadeInDown mt"
          *ngIf="depositInc == true && isPatientContribution == true"
          appearance="outline"
        >
          <mat-label
          >Customer Contribution , which should be less than
            {{ toNumber(amount) - toNumber(productObject['Parameter.MinLoanValue']) | customCurrency }}</mat-label
          >

          <input
            class="mat-block"
            [(ngModel)]="patientContribution"
            [max]="toNumber(amount) - toNumber(productObject['Parameter.MinLoanValue'])"
            (change)="changePatientContribution()"
            name="contribution2"
            matInput
            value=""
            placeholder="Customer Contribution , which should be less than {{
              toNumber(amount) - toNumber(productObject['Parameter.MinLoanValue']) | customCurrency
            }}"
          />
        </mat-form-field>
      </div>

      <div
        class="row flex full-width clearfix mt mb consumerCalcResults"
        *ngIf="isTop != true && dentalInfo == true"
      ></div>

      <div class="row full-width clearfix flex mt mb" *ngIf="isTop != true && retailInfo == true">
        <div class="full-width animate">
          <div class="column text-center breakdown">
            <p class="small">Minimum Deposit Required</p>
            <hr class="display-block text-center"/>
            <h1 class="sm-h1 sr-title rm-mb">{{ toNumber(estimateObject['Deposit']) | customCurrency }}</h1>
            <p class="small rm-mt"></p>
          </div>
        </div>

        <div class="full-width animate">
          <div class="column text-center breakdown">
            <p class="small">Interest Rate</p>
            <hr class="display-block text-center"/>
            <h1 class="sm-h1 sr-title rm-mb">
              {{ toNumber(estimateObject['Interest.Rate']) / 100 | percent: '2.1-1' }}
            </h1>
          </div>
        </div>
        <div class="full-width animate" *ngIf="dentalInfo == false">
          <div class="column text-center breakdown highlight">
            <p class="small">Regular Repayments</p>
            <hr class="display-block text-center"/>
            <h1 class="sm-h1 sr-title rm-mb">{{ regulatPayment | customCurrency }}</h1>
            <p class="small rm-mt">{{ durationLabel(frequency) }}</p>
          </div>
        </div>

        <div class="full-width animate" *ngIf="dentalInfo == false">
          <div class="column text-center breakdown">
            <p class="small">Total Paid</p>
            <hr class="display-block text-center"/>
            <h1 class="sm-h1 sr-title rm-mb" *ngIf="productObject['SubType'] == 'Payment Plan'">
              {{ toNumber(estimateObject['Repayments.Total']) + toNumber(estimateObject['Deposit']) | customCurrency }}
            </h1>
            <h1 class="sm-h1 sr-title rm-mb" *ngIf="productObject['SubType'] != 'Payment Plan'">
              {{ estimateObject['Repayments.Total'] | customCurrency }}
            </h1>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
</div>

<div
  class="clearfix full-width text-center"
  *ngIf="isProceedButton == true && productObject && estimateObject && durationObject && ProductID"
  [class.customers]="isModal == true"
>
  <p class="small mobShow">Scroll down for more details</p>
  <button class="btn-large nextBtn" (click)="proceedButton()" mat-raised-button type="submit" color="accent">
    Next
    <mat-icon>chevron_right</mat-icon>
  </button>
</div>
