import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthenticationModule } from './authentication/authentication.module';
import { EconnectModule } from './econnect/econnect.module';
import { HelpTopicsModule } from './help-topics/help-topics.module';
import { HelperModule } from './helper/helper.module';
import { LogModule } from './log/log.module';
import { ModuleModule } from './module/module.module';
import { SessionModule } from './session/session.module';
import { SettingsModule } from './settings/settings.module';
import { SubscriptionPlanModule } from './subscription-plan/subscription-plan.module';
import { ThemeModule } from './theme/theme.module';
import { ThirdPartyModule } from './third-party/third-party.module';
import { WikiModule } from './wiki/wiki.module';

@NgModule({
  exports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CommonModule,
    WikiModule,
    ModuleModule,
    SessionModule,
    AuthenticationModule,
    EconnectModule,
    LogModule,
    ThemeModule,
    HelpTopicsModule,
    HelperModule,
    SubscriptionPlanModule,
    ThirdPartyModule,
    SettingsModule,
  ],
})
export class CoreModule {}
