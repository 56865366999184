<div class="rel">
  <div class="pull-right">
    <!-- Modal dismiss button -->
    <button class="btn-close btn-clear mat-button" matDialogClose>
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <h3 class="sr-title rm-mt">Please contact me again in:</h3>
  <hr/>
  <div class="clearfix">
    <mat-form-field class="full-width" appearance="outline">
      <mat-label>Select Time</mat-label>
      <mat-select [(ngModel)]="choice" placeholder="Select Time">
        <mat-option *ngFor="let holdOff of holdOffs" [value]="holdOff">
          {{ holdOff.viewValue }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <br/>

  <div class="drop-buttons">
    <button class="btn-large" [disabled]="!choice" (click)="holdInv()" mat-raised-button color="accent">
      Okay
      <mat-icon>chevron_right</mat-icon>
    </button>
  </div>
</div>
