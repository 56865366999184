/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./invitation-redirection.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./invitation-redirection.component";
import * as i3 from "@angular/router";
import * as i4 from "../../../shared/services/user-data.service";
import * as i5 from "../shared/services/invitation.service";
import * as i6 from "../../../core/authentication/shared/authentication.service";
var styles_InvitationRedirectionComponent = [i0.styles];
var RenderType_InvitationRedirectionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InvitationRedirectionComponent, data: {} });
export { RenderType_InvitationRedirectionComponent as RenderType_InvitationRedirectionComponent };
export function View_InvitationRedirectionComponent_0(_l) { return i1.ɵvid(0, [], null, null); }
export function View_InvitationRedirectionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-invitation-redirection", [], null, null, null, View_InvitationRedirectionComponent_0, RenderType_InvitationRedirectionComponent)), i1.ɵdid(1, 114688, null, 0, i2.InvitationRedirectionComponent, [i3.ActivatedRoute, i3.Router, i4.UserDataService, i5.InvitationService, i6.AuthenticationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InvitationRedirectionComponentNgFactory = i1.ɵccf("app-invitation-redirection", i2.InvitationRedirectionComponent, View_InvitationRedirectionComponent_Host_0, {}, {}, []);
export { InvitationRedirectionComponentNgFactory as InvitationRedirectionComponentNgFactory };
