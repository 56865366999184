<p class="small">
  You can find the document for ePremium here, click on table name to display it fields. You can click on

  <button class="position" mat-button mat-icon-button>
    <mat-icon>visibility</mat-icon>
  </button>
  to display table details, click on fields Row to get more information.

  <br/>
  Click on

  <button class="position" mat-button mat-icon-button>
    <mat-icon>file_copy</mat-icon>
  </button>
  to copy the table name or field name, you can Bulk select fields to copy them as an Array or String to use them in
  your code.
</p>
<hr/>

<div class="flex full-width clearfix">
  <app-econnect-table-list class="thrd-width" (getTableID)="getTableID($event)"></app-econnect-table-list>
  <app-econnect-field-list class="ml full-width" [tableID]="tableID"></app-econnect-field-list>
</div>
