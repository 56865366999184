<div class="rel">
  <div class="card-header primary-gradient-img clearfix inModal" [ngClass]="classNames">
    <div class="row clearfix">
      <button class="btn-close btn-clear mat-button white" (click)="close()" matDialogClose>
        <mat-icon>clear</mat-icon>
      </button>
      <div class="row titleArea clearfix" style="width: 100%">
        <div class="title-section">
          <ng-container *ngIf="icon !== null">
            <mat-icon class="white pull-left" *ngIf="!classIcon" style="margin-right: 5px">{{ icon }}</mat-icon>
            <mat-icon *ngIf="classIcon" [ngClass]="'white pull-left ' + classIcon" style="margin-right: 5px"></mat-icon>
          </ng-container>

          <h2 class="summary-header rm-m" style="line-height: 24px; padding-left: 0px !important; white-space: nowrap">
            {{ title }}
          </h2>
        </div>
      </div>
    </div>
    <div>
      {{ subtitle }}
    </div>
  </div>
</div>
