import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { MerchantViewComponent } from '../../merchant/merchant-view/merchant-view.component';
import { MerchantService } from '../../merchant/shared/merchant.service';

import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { ExistingPatientListGlobalComponent } from '../existing-patient-list-global/existing-patient-list-global.component';

/**
 * @title Feature-rich data table
 */
@Component({
  selector: 'app-existing-patient-list-overview',
  templateUrl: './existing-patient-list-overview.component.html',
  styleUrls: ['./existing-patient-list-overview.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('*', style({ opacity: 0 }), { optional: true }),
        query(
          '*',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('*', style({ opacity: 1 }), { optional: true }),
        query(
          '*',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class ExistingPatientListOverViewComponent implements OnInit {
  allList = true;
  customerList = false;
  prospectList = false;
  invitationList = false;
  hideMerchantSelect = false;

  role;

  merchants = [];
  merchantCustomer = 'none';
  merchantProspect = 'none';
  merchantInvitation = 'none';
  merchantAll = 'none';

  constructor(private AuthenticationService: AuthenticationService, private merchantService: MerchantService) {}

  ngOnInit() {
    ExistingPatientListGlobalComponent.closeMerchantSelectEvent.subscribe((res) => {
      if (res == true) {
        this.hideMerchantSelect = true;
      } else {
        this.hideMerchantSelect = false;
      }
    });

    this.AuthenticationService.getCurrentUser().subscribe((res) => {
      if (res && res.success && res.data['Role'] && res.data['Role']) {
        this.role = res.data['Role'];

        if (this.role == 'admin' || this.role == 'promoter') {
          const payload = {
            fields: 'ID,TradingAs,CalculatedName,Status',
          };
          this.merchantService.getList(payload, true).subscribe((res) => {
            this.merchants = res;
          });
        }
      }
    });
  }

  viewMerchant(index) {
    let id = this.merchantAll;

    if (index == 2) {
      id = this.merchantCustomer;
    } else if (index == 3) {
      id = this.merchantProspect;
    } else if (index == 4) {
      id = this.merchantInvitation;
    }

    const ref = RootAppComponent.dialog.open(MerchantViewComponent, {
      data: id,
      width: '600px',
      // panelClass: "noCard"
      // This panelClass causes the modal to display with no background--do we require it?
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  loadList(i) {
    if (i == 1) {
      this.customerList = true;
    }
    if (i == 2) {
      this.prospectList = true;
    }
    if (i == 3) {
      this.invitationList = true;
    }
  }
}
