/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./treatment-brand-list-overview.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../operator/operator-filter/operator-filter.component.ngfactory";
import * as i3 from "../../operator/operator-filter/operator-filter.component";
import * as i4 from "../../../core/authentication/shared/authentication.service";
import * as i5 from "../../merchant/shared/merchant.service";
import * as i6 from "../../product/shared/product.service";
import * as i7 from "../../dentist/shared/dentist.service";
import * as i8 from "../treatment-brand-list/treatment-brand-list.component.ngfactory";
import * as i9 from "../treatment-brand-list/treatment-brand-list.component";
import * as i10 from "../shared/treatment.service";
import * as i11 from "@angular/material/dialog";
import * as i12 from "@angular/router";
import * as i13 from "../../../shared/pipes/custom-date.pipe";
import * as i14 from "@ngx-translate/core";
import * as i15 from "@angular/common";
import * as i16 from "./treatment-brand-list-overview.component";
var styles_TreatmentBrandListOverviewComponent = [i0.styles];
var RenderType_TreatmentBrandListOverviewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TreatmentBrandListOverviewComponent, data: { "animation": [{ type: 7, name: "ngIfAnimation", definitions: [{ type: 1, expr: "void => *", animation: [{ type: 11, selector: "*", animation: { type: 6, styles: { opacity: 0 }, offset: null }, options: { optional: true } }, { type: 11, selector: "*", animation: { type: 12, timings: "100ms", animation: [{ type: 4, styles: { type: 5, steps: [{ type: 6, styles: { opacity: 0, transform: "translateY(-75%)", offset: 0, height: 0 }, offset: null }, { type: 6, styles: { opacity: 1, transform: "translateY(0)", offset: 1, height: "*" }, offset: null }] }, timings: "0.8s ease-out" }] }, options: { optional: true } }], options: null }, { type: 1, expr: "* => void", animation: [{ type: 11, selector: "*", animation: { type: 6, styles: { opacity: 1 }, offset: null }, options: { optional: true } }, { type: 11, selector: "*", animation: { type: 12, timings: "100ms", animation: [{ type: 4, styles: { type: 5, steps: [{ type: 6, styles: { opacity: 1, transform: "translateY(0)", offset: 0, height: "*" }, offset: null }, { type: 6, styles: { opacity: 0, transform: "translateY(-75%)", offset: 1, height: 0 }, offset: null }] }, timings: "0.8s ease-in" }] }, options: { optional: true } }], options: null }], options: {} }] } });
export { RenderType_TreatmentBrandListOverviewComponent as RenderType_TreatmentBrandListOverviewComponent };
function View_TreatmentBrandListOverviewComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-operator-filter", [["class", "dash"], ["id", "part1"]], null, [[null, "search"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("search" === en)) {
        var pd_0 = (_co.applySearch($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_OperatorFilterComponent_0, i2.RenderType_OperatorFilterComponent)), i1.ɵdid(1, 114688, null, 0, i3.OperatorFilterComponent, [i4.AuthenticationService, i5.MerchantService, i6.ProductService, i7.DentistService], { displayMerchant: [0, "displayMerchant"], displayDateFrom: [1, "displayDateFrom"], displayDateTo: [2, "displayDateTo"], displayDentist: [3, "displayDentist"], selectDateRange: [4, "selectDateRange"], setAutoSelectMerchant: [5, "setAutoSelectMerchant"] }, { search: "search" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isDisplayMerchant; var currVal_1 = true; var currVal_2 = true; var currVal_3 = false; var currVal_4 = _co.selectedRange; var currVal_5 = true; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
function View_TreatmentBrandListOverviewComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-brand-list", [], null, null, null, i8.View_TreatmentBrandListComponent_0, i8.RenderType_TreatmentBrandListComponent)), i1.ɵdid(1, 770048, null, 0, i9.TreatmentBrandListComponent, [i10.TreatmentService, i11.MatDialog, i12.Router, i13.CustomDatePipe, i4.AuthenticationService], { merchantID: [0, "merchantID"], dateFrom: [1, "dateFrom"], dateTo: [2, "dateTo"], isActive: [3, "isActive"], pageSize: [4, "pageSize"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.merchantID; var currVal_1 = _co.dateFrom; var currVal_2 = _co.dateTo; var currVal_3 = true; var currVal_4 = _co.pageSize; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
function View_TreatmentBrandListOverviewComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "pleaseChoose"]], [[24, "@ngIfAnimation", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["No merchant selected"])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["class", "character_warning"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, [" Choose your desired merchant from the drop-down above to view their ", "s "])), i1.ɵpid(131072, i14.TranslatePipe, [i14.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = undefined; _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("KEYWORD.patient")); _ck(_v, 6, 0, currVal_1); }); }
function View_TreatmentBrandListOverviewComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TreatmentBrandListOverviewComponent_2)), i1.ɵdid(2, 16384, null, 0, i15.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "row clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TreatmentBrandListOverviewComponent_3)), i1.ɵdid(5, 16384, null, 0, i15.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "row clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TreatmentBrandListOverviewComponent_4)), i1.ɵdid(8, 16384, null, 0, i15.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isAdminOrPromoter == true); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.merchantID != "none"); _ck(_v, 5, 0, currVal_1); var currVal_2 = (_co.merchantID == "none"); _ck(_v, 8, 0, currVal_2); }, null); }
function View_TreatmentBrandListOverviewComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-operator-filter", [["class", "dash"], ["id", "part1"]], null, [[null, "search"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("search" === en)) {
        var pd_0 = (_co.applySearch($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_OperatorFilterComponent_0, i2.RenderType_OperatorFilterComponent)), i1.ɵdid(1, 114688, null, 0, i3.OperatorFilterComponent, [i4.AuthenticationService, i5.MerchantService, i6.ProductService, i7.DentistService], { displayMerchant: [0, "displayMerchant"], displayDateFrom: [1, "displayDateFrom"], displayDateTo: [2, "displayDateTo"], displayDentist: [3, "displayDentist"], selectDateRange: [4, "selectDateRange"], displayTimePicker: [5, "displayTimePicker"] }, { search: "search" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = false; var currVal_1 = true; var currVal_2 = true; var currVal_3 = false; var currVal_4 = _co.selectedRange; var currVal_5 = true; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
function View_TreatmentBrandListOverviewComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TreatmentBrandListOverviewComponent_6)), i1.ɵdid(2, 16384, null, 0, i15.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "row clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-brand-list", [], null, null, null, i8.View_TreatmentBrandListComponent_0, i8.RenderType_TreatmentBrandListComponent)), i1.ɵdid(5, 770048, null, 0, i9.TreatmentBrandListComponent, [i10.TreatmentService, i11.MatDialog, i12.Router, i13.CustomDatePipe, i4.AuthenticationService], { dateFrom: [0, "dateFrom"], dateTo: [1, "dateTo"], isActive: [2, "isActive"], pageSize: [3, "pageSize"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isAdminOrPromoter == true); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.dateFrom; var currVal_2 = _co.dateTo; var currVal_3 = true; var currVal_4 = _co.pageSize; _ck(_v, 5, 0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_TreatmentBrandListOverviewComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_TreatmentBrandListOverviewComponent_1)), i1.ɵdid(1, 16384, null, 0, i15.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TreatmentBrandListOverviewComponent_5)), i1.ɵdid(3, 16384, null, 0, i15.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isAdminOrPromoter == true); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.isAdminOrPromoter == false); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_TreatmentBrandListOverviewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-brand-list-overview", [], null, null, null, View_TreatmentBrandListOverviewComponent_0, RenderType_TreatmentBrandListOverviewComponent)), i1.ɵdid(1, 114688, null, 0, i16.TreatmentBrandListOverviewComponent, [i4.AuthenticationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TreatmentBrandListOverviewComponentNgFactory = i1.ɵccf("app-brand-list-overview", i16.TreatmentBrandListOverviewComponent, View_TreatmentBrandListOverviewComponent_Host_0, { pageSize: "pageSize" }, {}, []);
export { TreatmentBrandListOverviewComponentNgFactory as TreatmentBrandListOverviewComponentNgFactory };
