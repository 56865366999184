import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output, SimpleChanges } from '@angular/core';

import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CardService } from '../shared/card.service';

@Component({
  selector: 'app-invitation-customer-prospect-view-picture',
  templateUrl: './invitation-customer-prospect-view-picture.component.html',
  styleUrls: ['./invitation-customer-prospect-view-picture.component.css'],
})
export class InvitationCustomerProspectViewPictureComponent implements OnInit {
  @Input() patientID;
  @Input() modifiedDate = null;

  @Output() close = new EventEmitter();

  profileLink;

  constructor(
    private invitationService: CardService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data && typeof data == 'string') {
      this.patientID = data;
    }
  }

  ngOnInit() {
    if (this.patientID) {
      this.getProfilePicture();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.patientID.previousValue != changes.patientID.currentValue) {
      if (this.patientID) {
        this.getProfilePicture();
      } else {
        this.profileLink = null;
      }
    }
  }

  getProfilePicture() {
    this.profileLink = null;

    this.profileLink = this.invitationService.getCustomerProspectProfilePicStreamLink(
      this.patientID,
      this.modifiedDate
    );
  }

  closeEvent() {
    this.close.emit(true);
  }
}
