<div class="rel text-center">
  <mat-card class="card">
    <div class="row clearfix">
      <div class="pull-right">
        <!-- Modal dismiss button -->
        <button class="btn-close btn-clear mat-button" (click)="doNotShow()" matDialogClose>
          <mat-icon>clear</mat-icon>
        </button>
      </div>
      <!-- <div class="pull-left">
        <h3 class="sr-title rm-mt rm-mb">Welcome to the Dashboard!</h3>
      </div> -->
    </div>
    <hr/>
    <div class="colour-stripe"></div>

    <p>
      <img class="animated zoomIn feature-img character_clipboard"/>
    </p>

    <p class="lead">Welcome to your Finance Invitation Dashboard</p>
    <p>
      This is where you manage all your {{ "KEYWORD.patient" | translate }}s that have had invitations for finance sent
      to them.
    </p>
    <p>
      Offering your {{ "KEYWORD.patient" | translate }}s a convenient method to pay for their
      {{ "KEYWORD.treatment" | translate }} is a good way to improve your acceptance rates.
    </p>
    <p *ngIf="isModuleTutorialsActive == true">
      Follow the tutorial below and learn how to start sending your {{ "KEYWORD.patient" | translate }}s invitations for
      finance!
    </p>

    <hr/>
    <mat-checkbox [(ngModel)]="showOnInvitationFirstVisit">Please don't show this message again</mat-checkbox>
  </mat-card>
  <button class="gradient green btn-large" *ngIf="isModuleTutorialsActive == true" (click)="startTheTour()"
          mat-raised-button color="primary">
    See Tutorial
  </button>
  <button class="btn-large" (click)="doNotShow()" mat-raised-button color="accent" matDialogClose>
    Got It!
  </button>
</div>
