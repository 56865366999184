<div class="white-background clearfix full-width">
  <app-customer-view
    class=""
    *ngIf="existingPatient && existingPatient['ID'] && existingPatient['TableName'] == 'Customer'"
    [customerObject]="existingPatient"
    [customerID]="existingPatientID"
  ></app-customer-view>
  <app-prospect-view
    class=""
    *ngIf="existingPatient && existingPatient['ID'] && existingPatient['TableName'] == 'Prospect'"
    [prospectObject]="existingPatient"
    [prospectID]="existingPatient['ID']"
  ></app-prospect-view>
  <app-existing-patient-invitation-view
    class=""
    *ngIf="existingPatient && existingPatient['ID'] && existingPatient['TableName'] == 'Invitation'"
    [invitedPatientObject]="existingPatient"
    [invitationID]="existingPatient['ID']"
  ></app-existing-patient-invitation-view>

  <div class="clearfix row padded" *ngIf="isPromoterOrAdmin == true">
    <div class="well flex reducedPadding">
      <p class="full-width small" *ngIf="existingPatient['asInvitation']">
        Invitations: <span class="badge">{{ existingPatient['asInvitation'] }} </span>
      </p>

      <p class="full-width small" *ngIf="existingPatient['asProspect']">
        Prospect records: <span class="badge">{{ existingPatient['asProspect'] }} </span>
      </p>

      <p class="full-width small" *ngIf="existingPatient['asCustomer']">
        Customer records: <span class="badge">{{ existingPatient['asCustomer'] }} </span>
      </p>
    </div>
  </div>
</div>
