<div class="clearfix full-width flex flex-center justify-between" *ngIf="title && invitation && invitation.ID">
  <h3 class="sr-title pull-left">
    <mat-icon class="fas fa-notes-medical"></mat-icon>
    {{ title }}
  </h3>
</div>

<div
  class="clearfix full-width"
  *ngIf="
    invitation &&
    invitation.status &&
    invitation.status != 'voided' &&
    invitation.status != 'expired' &&
    invitation.status != 'notExist'
  "
>
  <div
    class="applicationStatusBar animate"
    [hidden]="invitation['Product.Label.Marketing'] && invitation['Product.Label.Marketing'] != ''"
  ></div>

  <div class="row clearfix flex funnel">
    <div
      class="step step-1"
      [class.disabled]="!invitation['DateTimeCreated'] || invitation['DateTimeCreated'] == '0000-00-00'"
    >
      <div class="row clearfix text-center">
        <mat-icon class="fas fa-envelope-open-text"></mat-icon>

        <h5>
          Created<span class="value" *ngIf="invitation['DateTimeCreated']"
        >: {{ invitation['DateTimeCreated'] | customDate }}</span
        >
        </h5>

        <p
          class="small bordered"
          *ngIf="invitation['Date.Expiry'] && invitation['Date.Expiry'] != '0000-00-00'"
          [style.border-color]="util.daysPastColor(invitation['DateTimeCreated'])"
          [style.color]="util.daysPastColor(invitation['DateTimeCreated'])"
        >
          {{ invitation['DateTimeCreated'] | TimeLabelPast }} ago
          <span class="backgroundColor" [style.background]="util.daysPastColor(invitation['DateTimeCreated'])"></span>
        </p>
      </div>
    </div>

    <div class="step step-2" [class.disabled]="!invitation['Date.Viewed'] || invitation['Date.Viewed'] == '0000-00-00'">
      <div class="row clearfix text-center">
        <mat-icon class="fas fa-eye"></mat-icon>
        <h5>
          Viewed<span class="value" *ngIf="invitation['Date.Viewed'] && invitation['Date.Viewed'] != '0000-00-00'"
        >: {{ invitation['Date.Viewed'] | customDate }}</span
        >
        </h5>

        <p
          class="small bordered"
          *ngIf="invitation['Date.Viewed'] && invitation['Date.Viewed'] != '0000-00-00'"
          [style.border-color]="util.daysPastColor(invitation['Date.Viewed'])"
          [style.color]="util.daysPastColor(invitation['Date.Viewed'])"
        >
          {{ invitation['Date.Viewed'] | TimeLabelPast }} ago
          <span class="backgroundColor" [style.background]="util.daysPastColor(invitation['Date.Viewed'])"></span>
        </p>
      </div>
    </div>

    <div
      class="step step-3"
      *ngIf="invitation['Date.MedicalHistory.Commenced'] && invitation['Date.MedicalHistory.Commenced'] != '0000-00-00'"
      [class.disabled]="
        !invitation['Date.MedicalHistory.Commenced'] || invitation['Date.MedicalHistory.Commenced'] == '0000-00-00'
      "
    >
      <div class="row clearfix text-center">
        <mat-icon class="fas fa-play-circle"></mat-icon>
        <h5>
          Commenced:
          <span
            class="value"
            *ngIf="
              invitation['Date.MedicalHistory.Commenced'] && invitation['Date.MedicalHistory.Commenced'] != '0000-00-00'
            "
          >{{ invitation['Date.MedicalHistory.Commenced'] | customDate }}</span
          >
        </h5>

        <p
          class="small bordered"
          *ngIf="
            invitation['Date.MedicalHistory.Commenced'] && invitation['Date.MedicalHistory.Commenced'] != '0000-00-00'
          "
          [style.border-color]="util.daysPastColor(invitation['Date.MedicalHistory.Commenced'])"
          [style.color]="util.daysPastColor(invitation['Date.MedicalHistory.Commenced'])"
        >
          {{ invitation['Date.MedicalHistory.Commenced'] | TimeLabelPast }} ago
          <span
            class="backgroundColor"
            [style.background]="util.daysPastColor(invitation['Date.MedicalHistory.Commenced'])"
          ></span>
        </p>
      </div>
    </div>

    <div
      class="step step-4"
      [class.disabled]="
        !invitation['Date.MedicalHistory.Completed'] || invitation['Date.MedicalHistory.Completed'] == '0000-00-00'
      "
    >
      <div class="row clearfix text-center">
        <mat-icon class="fas fa-check-circle"></mat-icon>
        <h5>
          Completed<span
          class="value"
          *ngIf="
              invitation['Date.MedicalHistory.Completed'] && invitation['Date.MedicalHistory.Completed'] != '0000-00-00'
            "
        >: {{ invitation['Date.MedicalHistory.Completed'] | customDate }}</span
        >
        </h5>

        <p
          class="small bordered"
          *ngIf="
            invitation['Date.MedicalHistory.Completed'] && invitation['Date.MedicalHistory.Completed'] != '0000-00-00'
          "
          [style.border-color]="util.daysPastColor(invitation['Date.MedicalHistory.Completed'])"
          [style.color]="util.daysPastColor(invitation['Date.MedicalHistory.Completed'])"
        >
          {{ invitation['Date.MedicalHistory.Completed'] | TimeLabelPast }} ago
          <span
            class="backgroundColor"
            [style.background]="util.daysPastColor(invitation['Date.MedicalHistory.Completed'])"
          ></span>
        </p>
      </div>
    </div>
  </div>
</div>
