<div style="display: flex">
  <app-ep-document-view-image
    class="headNav userIcon dialogProfilePicture"
    [isFullScreen]="false"
    [width]="'48px'"
    [height]="'48px'"
    [defaultPicture]="
      'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/profile_placeholder.png)'
    "
    [link]="patientImage"
    [hideZoomButton]="true"
    style="padding-left: 5px"
  >
  </app-ep-document-view-image>

  <span *ngIf="patient" style="font-weight: bold; padding-left: 5px; line-height: 24px">
    <div *ngIf="patient['CalculatedName']" style="display: flex">
      <mat-icon> person </mat-icon>
      <span style="padding-left: 5">
        <span> {{ patient['CalculatedName'] }} </span>
      </span>

      <mat-icon style="margin-left: 5px"> phone </mat-icon>
      <span style="padding-left: 5">
        <span> {{ patient['mobiles.Number'] }}</span>
      </span>
    </div>

    <div *ngIf="!patient['CalculatedName']" style="display: flex">
      <mat-icon> person </mat-icon>
      <span style="padding-left: 5">
        <span> {{ patient['firstName'] }} </span>
        <span> {{ patient['middleName'] }} </span>
        <span> {{ patient['lastName'] }} </span>
      </span>

      <mat-icon style="margin-left: 5px"> phone </mat-icon>
      <span style="padding-left: 5">
        <span> {{ patient['mobile'] }}</span>
      </span>
    </div>

    <div *ngIf="patient['email']" style="display: flex">
      <mat-icon> mail </mat-icon>
      <span style="padding-left: 5">
        <span> {{ patient['email'] }} </span>
      </span>
    </div>

    <div *ngIf="!patient['email'] && patient['emails.Email']" style="display: flex">
      <mat-icon> mail </mat-icon>
      <span style="padding-left: 5">
        <span> {{ patient['emails.Email'] }} </span>
      </span>
    </div>
  </span>
</div>

<button class="lookup-button" *ngIf="!displayOnly" (click)="openPatientLookupDialog()" mat-raised-button color="accent">
  <mat-icon> search</mat-icon>
  {{ "KEYWORD.patient" | translate | titlecase }} Lookup
</button>
