import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { AppStateService } from '../../../../core/app-state/app-state.service';
import { UtilsService } from '../../../../shared/services/utils.service';
import { DataResponse } from '../../../../shared/types/data-response.type';
import { QueryParameters } from '../../../../shared/types/query-parameters.type';

class ProductCustomerState {
  productCustomers: Partial<ProductCustomer>[] | ProductCustomer[];
  customerCalculatedNameFilter?: string;
  customerEmailFilter?: string;
  productLabelFilter?: string;
}

export class ProductCustomer {
  readonly 'ID'?: string;
  readonly 'DateTimeCreated'?: string;
  readonly 'LastModified'?: string;
  readonly 'LastModified_Human'?: string;
  readonly CustomerCalculatedName?: string;
  readonly CustomerEmail?: string;
  readonly CustomerMobile?: string;
  readonly ProductLabel?: string;
  'Owner_Card_key': string;
  'Master_Appliance_Profile_key': string;
  'SellPrice': number;
  'CostPrice': number;
  'TaxRate': number;
  'Quantity': string;
  'Discount_Amount': number;
  'Notes': string;
  'Invoice_key': string;
  'Merchant_key': string;
  'PurchaseDate': string;
  'SerialNumber'?: string;
  isChecked?: boolean;
}

export class ProductCustomerQuery {
  payload: Partial<ProductCustomer> &
    QueryParameters & {
      Merchant_key: string;
      calculatedNameFilter?: string;
      emailFilter?: string;
      productLabelFilter?: string;
    };
}

@Injectable({
  providedIn: 'root',
})
export class ProductCustomerService extends ComponentStore<ProductCustomerState> {
  private baseUrl = environment.nodeUrl;

  constructor(
    private utilsService: UtilsService,
    private httpClient: HttpClient,
    private appStateService: AppStateService
  ) {
    super({
      productCustomers: [],
      customerCalculatedNameFilter: '',
      customerEmailFilter: '',
      productLabelFilter: '',
    });
  }

  getProductCustomers<T extends ProductCustomer | Partial<ProductCustomer>>(): T[] {
    return this.get().productCustomers as T[];
  }

  getProductCustomers$<T extends ProductCustomer | Partial<ProductCustomer>>(): Observable<T[]> {
    return this.select((state) => state.productCustomers) as Observable<T[]>;
  }

  setProductCustomers(productCustomers: ProductCustomer[] | Partial<ProductCustomer>[]): void {
    this.patchState({ productCustomers });
  }

  fetchProductCustomers$<T extends ProductCustomer | Partial<ProductCustomer>>(
    payload?: ProductCustomerQuery['payload']
  ): Observable<T[]> {
    return this.appStateService.getAppState$().pipe(
      switchMap((appState) => {
        const params = this.utilsService.getHttpParamsFromPayload(payload);

        const options = { params };

        const endpoint = appState.isPromoterOrAdmin ? 'product-customers/global' : 'product-customers';

        const url = `${this.baseUrl}/${endpoint}`;

        return this.httpClient.get<DataResponse<T[]>>(url, options).pipe(map((response) => response['data']));
      })
    );
  }

  createProductCustomer$<T extends ProductCustomer | Partial<ProductCustomer>>(
    payload?: Partial<ProductCustomer>
  ): Observable<T> {
    return this.appStateService.getAppState$().pipe(
      switchMap((appState) => {
        const endpoint = appState.isPromoterOrAdmin ? 'product-customers/global' : 'product-customers';

        const url = `${this.baseUrl}/${endpoint}`;

        return this.httpClient.post<DataResponse<T>>(url, payload).pipe(map((response) => response.data));
      })
    );
  }

  updateProductCustomer$<T extends ProductCustomer | Partial<ProductCustomer>>(
    item: ProductCustomer | Partial<ProductCustomer>,
    payload?: Partial<ProductCustomer>
  ): Observable<T> {
    return this.appStateService.getAppState$().pipe(
      switchMap((appState) => {
        const endpoint = appState.isPromoterOrAdmin
          ? `product-customers/global/${item.ID}`
          : `product-customers/${item.ID}`;

        const url = `${this.baseUrl}/${endpoint}`;

        return this.httpClient.put<DataResponse<T>>(url, payload).pipe(map((response) => response.data));
      })
    );
  }

  deleteProductCustomer$(invoice: ProductCustomer | Partial<ProductCustomer>): Observable<string> {
    return this.appStateService.getAppState$().pipe(
      take(1),
      switchMap((appState) => {
        const endpoint = appState.isPromoterOrAdmin
          ? `product-customers/global/${invoice.ID}`
          : `product-customers/${invoice.ID}`;

        const url = `${this.baseUrl}/${endpoint}`;

        return this.httpClient.delete<DataResponse<Partial<string>>>(url).pipe(map((response) => response.data));
      })
    );
  }

  addProductCustomer$(itemWithID: ProductCustomer | Partial<ProductCustomer>) {
    const currentItems = this.get().productCustomers;
    const items = [...currentItems, itemWithID];
    this.patchState({ productCustomers: items });
    return of(itemWithID);
  }

  editProductCustomer$(itemName: ProductCustomer | Partial<ProductCustomer>) {
    const items = this.get().productCustomers;
    const targetIndex = items.findIndex((item) => itemName.ID === item.ID);
    items[targetIndex] = itemName;
    this.patchState({ productCustomers: items });
    return of(itemName);
  }

  removeProductCustomer$(itemName: ProductCustomer | Partial<ProductCustomer>): Observable<null> {
    // Remove item from local list
    const currentItems = this.get().productCustomers;
    const items = currentItems.filter((item) => itemName.ID !== item.ID);

    this.patchState({ productCustomers: items });

    return of(null);
  }

  getCustomerCalculatedNameFilter$() {
    return this.select((state) => state.customerCalculatedNameFilter);
  }

  setCustomerCalculatedNameFilter(customerCalculatedNameFilter: string): void {
    this.patchState({ customerCalculatedNameFilter });
  }

  getCustomerEmailFilter$() {
    return this.select((state) => state.customerEmailFilter);
  }

  setCustomerEmailFilter(customerEmailFilter: string): void {
    this.patchState({ customerEmailFilter });
  }

  getCustomerProductLabelFilter$() {
    return this.select((state) => state.productLabelFilter);
  }

  setCustomerProductLabelFilter(productLabelFilter: string): void {
    this.patchState({ productLabelFilter });
  }
}
