import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DentistryTypeCreateComponent } from '../dentistry-type-create/dentistry-type-create.component';
import { DentistryTypeEditComponent } from '../dentistry-type-edit/dentistry-type-edit.component';
import { DentistryTypesListComponent } from '../dentistry-types-list/dentistry-types-list.component';
import { DentistryTypesOverviewComponent } from '../dentistry-types-overview/dentistry-types-overview.component';

const routes: Routes = [
  {
    path: 'DentistryTypesOverviewComponent',
    component: DentistryTypesOverviewComponent,
  },

  {
    path: 'dentistry-types-list',
    component: DentistryTypesListComponent,
  },

  {
    path: 'DentistryTypeEditComponent',
    component: DentistryTypeEditComponent,
  },

  {
    path: 'DentistryTypeCreateComponent',
    component: DentistryTypeCreateComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DentistryTypesRoutingModule {}
