<div
  class="full-width mb"
  id="container-customer-background"
  [ngClass]="{ 'container-customer-background': isFullDetails == true }"
>
  <!-- <div id="blueBg" [hidden]="isFullDetails != true"></div> -->
  <div class="container2 mt">
    <div class="full-width clearfix flex header-container" *ngIf="merchant">
      <h2 class="sm-h2 sr-title full-width ml">{{ merchant['TradingAs'] }}</h2>
      <h4 class="sm-h4 sr-title phone-merchant full-width text-right mr">
        Need help? Call us now on
        <strong> {{ merchant['phones.Number'] | customPhone: 'landLine' }}</strong>
      </h4>
    </div>

    <section
      class="introduction clearfix"
      [ngClass]="{ 'no-display-container': isFullDetails != true }"
      [hidden]="!appointment"
    >
      <div class="full-width cover-background text-center" id="cover-background">
        <div class="merchant-profile-container clearfix" id="profile-background"></div>
      </div>
    </section>

    <section class="row clearfix" *ngIf="appointment">
      <mat-card
        class="mb mt clearfix"
        *ngIf="isCreated != true && isNotFound != true"
        [ngClass]="{ firstCard: isFullDetails == true }"
      >
        <div class="card-container text-center">
          <h2 class="lg-h2 sr-title text-center mt line-cross mb">
            <span class="title-cross"> Hello {{ appointment['CustomerOrProspect_FirstName'] }}! </span>
          </h2>
          <p class="text-center clearfix">Below are the details of your appointment.</p>

          <div class="full-width appointment-details clearfix flex mt text-left">
            <div class="thrd-width mr mt mb">
              <img
                class="full-width"
                src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/character/dentist-clipboard.png"
              />
            </div>

            <app-appointment-card class="full-width ml mb" [appointment]="appointment"></app-appointment-card>
          </div>

          <div class="full-width clearfix mb">
            <app-appointment-description
              class="full-width clearfix"
              [appointment]="appointment"
              [isFullDetails]="isFullDetails"
            >
            </app-appointment-description>
          </div>

          <div class="full-width clearfix text-center mb flex">
            <mat-card
              class="alt-option botton animate confirm full-width clearfix"
              *ngIf="
                isCreated != true &&
                isNotFound != true &&
                appointmentUtil.appointmentHasStatus('confirmBooking', appointment, 'guest') == true
              "
              (click)="runActionEvent(appointment, 'confirmBooking', false)"
              matRipple
            >
              <h2 class="rm-mb">Confirm Attendance</h2>
              <p class="mb">Let your {{"KEYWORD.dentist" | translate}} knows you will attend this appointment in
                time</p>

              <button class="warm" mat-button>
                <mat-icon aria-hidden="true">alarm_on</mat-icon> Confirm
              </button>
            </mat-card>
          </div>
        </div>
      </mat-card>
      <mat-card class="card full-width" *ngIf="isCreated != true && isNotFound != true">
        <div class="colour-stripe"></div>
        <div class="mb clearfix mt card-container mb">
          <h2
            class="lg-h2 sr-title text-center mt line-cross mb"
            *ngIf="
              appointmentUtil.appointmentHasStatus('cancelPatient', appointment, 'guest') == true ||
              appointmentUtil.appointmentHasStatus('requestDateChangeBooking', appointment, 'guest') == true ||
              appointmentUtil.appointmentHasStatus('requestReschedule', appointment, 'guest') == true
            "
          >
            <span class="title-cross"> Change something? </span>
          </h2>
          <p
            class="text-center clearfix"
            *ngIf="
              appointmentUtil.appointmentHasStatus('cancelPatient', appointment, 'guest') == true ||
              appointmentUtil.appointmentHasStatus('requestDateChangeBooking', appointment, 'guest') == true ||
              appointmentUtil.appointmentHasStatus('requestReschedule', appointment, 'guest') == true
            "
          >
            At any time, if you need to change your appointment, you can do so using the options below
          </p>
          <p
            class="text-center clearfix"
            *ngIf="
              appointmentUtil.appointmentHasStatus('cancelPatient', appointment, 'guest') == true ||
              appointmentUtil.appointmentHasStatus('requestDateChangeBooking', appointment, 'guest') == true ||
              appointmentUtil.appointmentHasStatus('requestReschedule', appointment, 'guest') == true
            "
          >
            *subject to our cancellation policy.
          </p>

          <div class="full-width text-center clearFix mt">
            <mat-card
              class="alt-option botton animate cancel botton full-width clearfix"
              *ngIf="appointmentUtil.appointmentHasStatus('cancelPatient', appointment, 'guest') == true"
              (click)="runActionEvent(appointment, 'cancelPatient', false)"
              matRipple
            >
              <h2 class="rm-mb">Cancel</h2>
              <p class="mb">
                If you no longer require the appointment, click the cancel button below to notify
                the {{"KEYWORD.practice" | translate}}.
              </p>

              <button class="hot" mat-button>
                <mat-icon aria-hidden="true">cancel</mat-icon>
                Cancel the appointment
              </button>
            </mat-card>

            <mat-card
              class="alt-option botton animate hold full-width clearfix"
              *ngIf="appointmentUtil.appointmentHasStatus('requestDateChangeBooking', appointment, 'guest') == true"
              (click)="runActionEvent(appointment, 'requestDateChangeBooking', false)"
              matRipple
            >
              <h2 class="rm-mb">Change</h2>
              <p class="mb">If the suggest date does not suit your, let us know so we can send you another booking</p>

              <button class="warm" mat-button>
                <mat-icon aria-hidden="true">alarm_off</mat-icon>
                Request a date change
              </button>
            </mat-card>

            <mat-card
              class="alt-option botton animate request full-width clearfix"
              *ngIf="appointmentUtil.appointmentHasStatus('requestReschedule', appointment, 'guest') == true"
              (click)="runActionEvent(appointment, 'requestReschedule', false)"
              matRipple
            >
              <h2 class="rm-mb">Request a rescheduling</h2>
              <p class="mb">Click here to request rescheduling of your appointment</p>

              <button class="warm" mat-button>
                Request a rescheduling
              </button>
            </mat-card>
          </div>

          <div class="full-width text-center clearFix">
            <div class="full-width text-center clearFix" *ngIf="appointmentUtil.canRequestNewAppointment(appointment)">
              <h2 class="lg-h2 sr-title text-center mt line-cross mb">
                <span class="title-cross"> Need new appointment? </span>
              </h2>

              <mat-card
                class="alt-option botton animate request full-width clearfix mb mt"
                *ngIf="appointmentUtil.canRequestNewAppointment(appointment)"
                (click)="requestAppointment(appointment)"
                matRipple
              >
                <h2 class="rm-mb">Request new appointment</h2>
                <p class="mb">Click here to request another appointment with your {{"KEYWORD.practice" | translate}}</p>

                <button class="warm" mat-button>
                  Request new appointment
                </button>
              </mat-card>
            </div>
          </div>
        </div>
      </mat-card>

      <mat-card class="firstCard clearfix" *ngIf="isCreated == true">
        <div class="row clearfix typesOfDentist add-mb mt mr ml flex" data-aos-once="true" data-aos="fade-right">
          <div class="thrd-width text-center mr">
            <img
              class="help-icon"
              src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/character/dentist_watch_1.png"
              [alt]="'Specialist ' + ('KEYWORD.dentistry' | translate) + ' services'"
            />
          </div>
          <div class="full-width ml" *ngIf="merchant">
            <h2 class="sr-title sm-h2 primary rm-mt">Thank you {{ firstName }}!</h2>
            <p class="lead text-left">Your {{"KEYWORD.dentist" | translate}} has been notified.</p>

            <p class="mb text-left" [ngClass]="{ lead: isFullDetails == true }">
              If you have any queries, please call our friendly team on
              <span class="bold" (click)="dial(appointment['Merchant_Phone'])">{{
                appointment['Merchant_Phone'] | customPhone
                }}</span>
              during regular business hours.
            </p>
          </div>
        </div>
      </mat-card>

      <mat-card class="firstCard clearfix" *ngIf="isNotFound == true">
        <div class="row clearfix typesOfDentist add-mb mt mr ml flex" data-aos-once="true" data-aos="fade-right">
          <div class="thrd-width text-center mr">
            <img
              class="help-icon"
              src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/character/dent_male_error.png"
              [alt]="'Specialist ' + ('KEYWORD.dentitry' | translate) + ' services'"
            />
          </div>
          <div class="full-width ml" *ngIf="merchant">
            <h2 class="sr-title sm-h2 primary rm-mt">Sorry!</h2>

            <p class="lead text-left">Appointment page is not found.</p>
            <p class="mb text-left" [ngClass]="{ lead: isFullDetails == true }">
              Please call our friendly team for more details on
              <span class="bold" (click)="dial(appointment['Merchant_Phone'])">{{
                appointment['Merchant_Phone'] | customPhone
                }}</span>
              during regular business hours.
            </p>
          </div>
        </div>

        <div class="full-width text-center clearFix mb">
          <div class="full-width text-center clearFix">
            <h2 class="lg-h2 sr-title text-center line-cross mb">
              <span class="title-cross"> Need new appointment? </span>
            </h2>

            <div class="full-width text-center flex">
              <mat-card
                class="alt-option botton animate request two-thrd-width clearfix mb mt"
                (click)="requestAppointment(appointment)"
                matRipple
              >
                <h2 class="rm-mb">Request new appointment</h2>
                <p class="mb">Click here to request another appointment with your {{"KEYWORD.practice" | translate}}</p>

                <button class="warm" mat-button>
                  Request new appointment
                </button>
              </mat-card>
            </div>
          </div>
        </div>
      </mat-card>

      <app-appointment-sync-calendar-card
        *ngIf="isNotFound != true"
        [appointment]="appointment"
        (success)="calendarSynced($event)"
      >
      </app-appointment-sync-calendar-card>
    </section>
  </div>
</div>

<app-appointment-action-hidden [runAction]="runAction" (result)="getActionResult($event)">
</app-appointment-action-hidden>
<!--
<div class="full-width clearfix booking-container" *ngIf="merchant && displayFooter == true">
  <mat-card class="booking-cta flex">
    <div class="full-width flex clearfix">
      <div class="footer-logo inline pull-left">
        <img *ngIf="merchantProfile" class="footer-img" [src]="merchantProfile" />
      </div>

      <div class="full-width">
        <div class="row clearfix flex text-center full-width">
          <div class="full-width">
            <p class="small text-left rm-m mr full-width clearfix" *ngIf="merchant['TradingAs']">
              {{ merchant["TradingAs"] }}
            </p>

            <p class="small text-left rm-m mr full-width clearfix" *ngIf="merchant['phones.Number']">
              {{ merchant["phones.Number"] | customPhone }}
            </p>

            <p class="small text-left rm-m mr full-width clearfix" *ngIf="merchant['addresses.Calculated']">
              {{ merchant["addresses.Calculated"] }}
            </p>

            <p class="small text-left rm-m mr full-width clearfix" (click)="openLink(merchant['URL'])"
              *ngIf="merchant['URL']">
              {{ cleanURL( merchant["URL"]) }}
            </p>
          </div>

          <div class="full-width text-right">
            <button mat-raised-button color="" class="open-hours-button clearfix text-right" (click)="tradingHours()"
              matTooltip="Opening Hours">
              Opening Hours
            </button>

            <div class="text-right icon-social-media clearfix" *ngIf="
                merchant['Facebook'] ||
                merchant['URL'] ||
                merchant['Twitter'] ||
                merchant['Tumblr'] ||
                merchant['Vimeo'] ||
                merchant['YouTube'] ||
                merchant['Instagram'] ||
                merchant['Pinterest'] ||
                merchant['LinkedIn']
              ">
              <mat-icon *ngIf="merchant['Facebook']" class="ml fab fa-facebook-f"
                (click)="openLink(merchant['Facebook'])" aria-label="Facebook" matTooltip="Facebook">
              </mat-icon>

              <mat-icon *ngIf="merchant['Twitter']" class="ml fab fa-twitter" (click)="openLink(merchant['Twitter'])"
                aria-label="Twitter" matTooltip="Twitter">
              </mat-icon>

              <mat-icon *ngIf="merchant['Tumblr']" class="ml fab fa-tumblr" (click)="openLink(merchant['Tumblr'])"
                aria-label="Tumblr" matTooltip="Tumblr">
              </mat-icon>

              <mat-icon *ngIf="merchant['Vimeo']" class="ml fab fa-vimeo-v" (click)="openLink(merchant['Vimeo'])"
                aria-label="Vimeo" matTooltip="Vimeo">
              </mat-icon>

              <mat-icon *ngIf="merchant['YouTube']" class="ml fab fa-youtube" (click)="openLink(merchant['YouTube'])"
                aria-label="YouTube" matTooltip="YouTube">
              </mat-icon>

              <mat-icon *ngIf="merchant['Instagram']" class="ml fab fa-instagram"
                (click)="openLink(merchant['Instagram'])" aria-label="Instagram" matTooltip="Instagram">
              </mat-icon>

              <mat-icon *ngIf="merchant['Pinterest']" class="ml fab fa-pinterest-p"
                (click)="openLink(merchant['Pinterest'])" aria-label="Pinterest" matTooltip="Pinterest">
              </mat-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
    <p class="clearfix"></p>
  </mat-card>
</div> -->
