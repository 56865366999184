import { Component, ElementRef, EventEmitter, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { TasksService } from '../shared/tasks.service';

import { DataSource } from '@angular/cdk/table';
import { FormControl } from '@angular/forms';
import { MatPaginator, MatSort } from '@angular/material';
import { BehaviorSubject, merge as observableMerge, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { TasksCreateComponent } from '../tasks-create/tasks-create.component';

@Component({
  selector: 'app-tasks-list',
  templateUrl: './tasks-list.component.html',
  styleUrls: ['./tasks-list.component.css'],
})
export class TasksListComponent implements OnInit {
  @Input()
  merchantID;

  pageSize = 10;
  pageSizeOptions = [10, Number(this.pageSize), Number(this.pageSize) * 2, Number(this.pageSize) * 3];

  util = new UtilsClass();

  displayedColumns = ['Name', 'Description', 'Completed', 'Actions'];
  filters = [];

  searchVals = new FormControl();

  searchValList = ['Name', 'Label', 'Message Type'];

  tasks = [];

  taskListFilter = 'task';

  public listDB: LoadRecords | null;
  dataSource: RecordDataSource | null;

  destroyEvent = new EventEmitter();

  filteredSize = null;
  isPromoterOrAdmin = false;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('filter', { static: false }) filter: ElementRef;

  constructor(private tasksService: TasksService, private authenticationService: AuthenticationService) {
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;
    });
  }

  ngOnInit() {
    this.listDB = new LoadRecords(this.tasksService, this.destroyEvent, this.merchantID, this.isPromoterOrAdmin);
    this.dataSource = new RecordDataSource(this.listDB, this.paginator, this.sort);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.listDB = new LoadRecords(this.tasksService, this.destroyEvent, this.merchantID, this.isPromoterOrAdmin);
    this.dataSource = new RecordDataSource(this.listDB, this.paginator, this.sort);
  }

  setFilter(event, field) {
    let filter;
    if (event) {
      filter = {
        field,
        value: event,
      };
    } else {
      filter = {
        field,
        value: '',
      };
    }

    this.dataSource.filter = filter;
    this.filteredSize = this.dataSource.filteredData.length;
  }

  createTask() {
    const ref = RootAppComponent.dialog.open(TasksCreateComponent, {
      data: {
        isModal: true,
      },
      width: '650px',
      panelClass: 'noCard',
    });

    ref.componentInstance.getResult.subscribe((res) => {
      if (res) {
        const item = res.filter((obj) => obj['Card_key'] == this.merchantID);
        this.dataSource.addItem = item[0];
        ref.close();
      }
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  editTask(ID) {
    const ref = RootAppComponent.dialog.open(TasksCreateComponent, {
      data: {
        assignedTaskID: ID,
      },
      width: '650px',
      panelClass: 'noCard',
    });

    ref.componentInstance.getResult.subscribe((res) => {
      if (res) {
        this.dataSource.replaceItem = res;
        ref.close();
      }
    });
    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  setTaskCompleted(assignedTaskID) {
    const confirm = new ConfirmDialog('fas fa-info', 'Set Task', 'Set task to completed manually', 'Abort', 'Proceed');
    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirm,
      width: '650px',
    });

    ref.componentInstance.onConfirm.subscribe((res) => {
      if (res == true) {
        this.tasksService.setTaskCompleted(assignedTaskID).subscribe((res) => {
          if (res) {
            NotifyAppComponent.displayToast('success', 'Set Task', `Successfully set task as completed.`);
            this.dataSource.replaceItem = res;
            ref.close();
          }
        });
      } else {
        ref.close();
      }
    });
  }

  setTaskUncompleted(assignedTaskID) {
    const confirm = new ConfirmDialog(
      'fas fa-info',
      'Set Task',
      'Set task to not completed manually',
      'Abort',
      'Proceed'
    );
    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirm,
      width: '650px',
    });

    ref.componentInstance.onConfirm.subscribe((confirm) => {
      if (confirm == true) {
        this.tasksService.setTaskUncompleted(assignedTaskID).subscribe((res) => {
          if (res) {
            NotifyAppComponent.displayToast('success', 'Set Task', `Successfully set task as not completed.`);
            this.dataSource.replaceItem = res;
            ref.close();
          }
        });
      } else {
        ref.close();
      }
    });
  }

  deleteTask(taskID) {
    const confirm = new ConfirmDialog(
      'fas fa-info',
      'Delete Task',
      'This task will be removed globally for all the merchant',
      'Abort',
      'Proceed'
    );
    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirm,
      width: '650px',
    });

    ref.componentInstance.onConfirm.subscribe((confirm) => {
      if (confirm == true) {
        this.tasksService.deletePracticeTask(taskID).subscribe((res) => {
          if (res) {
            NotifyAppComponent.displayToast('success', 'Delete Tasks', 'You have successfully deleted a task.');
            this.dataSource.removeItemByTaskID = res;
            ref.close();
          }
        });
      } else {
        ref.close();
      }
    });
  }

  ngOnDestroy() {
    this.destroyEvent.emit(true);
  }
}

export class LoadRecords implements OnInit {
  public dataChange: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  items: any;
  count: any;

  get data(): any[] {
    return this.dataChange.value;
  }

  firstLoad = false;

  get firstLoadEvent() {
    return this.firstLoad;
  }

  isDestoyed = false;

  serviceRef;

  constructor(private tasksService: TasksService, private destroyEvent, private merchantID, private isPromoterOrAdmin) {
    this.destroyEvent.subscribe((res) => {
      this.isDestoyed = res;
    });

    const payload = {
      section: 0,
      orderBy: 'Task.Type',
      merchantID: this.merchantID,
    };
    this.serviceRef = this.tasksService.getAssignedTasksList(payload, this.isPromoterOrAdmin).subscribe((res) => {
      this.count = res.length;
      this.items = res;
      this.dataChange.next(this.items.slice());
      this.firstLoad = true;
      const innerFunction = (section) => {
        section = section + 1;
        UtilsClass.loadingDataSection(section);
        payload.section = section;

        this.serviceRef = this.tasksService.getAssignedTasksList(payload, this.isPromoterOrAdmin).subscribe((res) => {
          if (res.length > 0) {
            this.count = res.count;
            this.items = this.items.concat(res);
            this.dataChange.next(this.items.slice());
            if (this.isDestoyed != true) {
              innerFunction(section);
            }
          } else {
            return true;
          }
        });
        UtilsClass.stopLoadingInterceptor();
      };

      if (this.isDestoyed != true) {
        innerFunction(1);
      }
    });
  }

  ngOnInit() {}

  ngOnDestroy() {
    if (this.serviceRef) {
      this.serviceRef.unsubscribe();
    }
  }
}

export class RecordDataSource extends DataSource<any> {
  _filterChange = new BehaviorSubject('');
  field = '';

  get filter(): any {
    return this._filterChange.value;
  }

  set filter(item: any) {
    this.field = item.field;
    this._filterChange.next(item.value);
  }

  filteredData: any[] = [];

  constructor(private _tableDatabase: LoadRecords, private _paginator: MatPaginator, private _sort: MatSort) {
    super();
    this._filterChange.subscribe(() => (this._paginator.pageIndex = 0));
  }

  set addItem(res) {
    this._tableDatabase.data.unshift(res);

    this._tableDatabase.dataChange.next(this._tableDatabase.data);
  }

  set replaceItem(item) {
    const _index = this._tableDatabase.data.findIndex((_obj) => _obj.ID == item.ID);
    this._tableDatabase.data[_index] = item;
    this._tableDatabase.dataChange.next(this._tableDatabase.data);
  }

  set removeItem(id) {
    const data = this._tableDatabase.data.filter((row) => row.ID != id);
    this._tableDatabase.dataChange.next(data);
  }

  set removeItemByTaskID(taskID) {
    const data = this._tableDatabase.data.filter((row) => row['Task_key'] != taskID);
    this._tableDatabase.dataChange.next(data);
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<any[]> {
    const displayDataChanges = [
      this._tableDatabase.dataChange,
      this._filterChange,
      this._paginator.page,
      this._sort.sortChange,
    ];

    return observableMerge(...displayDataChanges).pipe(
      map(() => {
        // Filter data
        this.filteredData = this._tableDatabase.data.slice().filter((item: any) => {
          let f = '';

          f = item[this.field] || '';

          const searchStr = f.toLowerCase();
          return searchStr.indexOf(this.filter.toLowerCase()) != -1;
        });
        const data = this.getSortedData(this.filteredData.slice());
        // Grab the page's slice of data.
        const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
        return data.splice(startIndex, this._paginator.pageSize);
      })
    );
  }

  disconnect() {}

  /** Returns a sorted copy of the database data. */
  getSortedData(data: any[]): any[] {
    // const data = data;
    if (!this._sort.active || this._sort.direction == '') {
      return data;
    }

    return data.sort((a, b) => {
      let propertyA: number | string = '';
      let propertyB: number | string = '';

      switch (this._sort.active) {
        case 'Name':
          [propertyA, propertyB] = [a['Task.Name'], b['Task.Name']];
          break;

        case 'Description':
          [propertyA, propertyB] = [a['Task.Description'], b['Task.Description']];
          break;
      }

      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

      return (valueA < valueB ? -1 : 1) * (this._sort.direction == 'asc' ? 1 : -1);
    });
  }
}
