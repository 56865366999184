/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./illness-allergy-icon.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "@angular/common";
import * as i5 from "./illness-allergy-icon.component";
var styles_IllnessAllergyIconComponent = [i0.styles];
var RenderType_IllnessAllergyIconComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_IllnessAllergyIconComponent, data: {} });
export { RenderType_IllnessAllergyIconComponent as RenderType_IllnessAllergyIconComponent };
function View_IllnessAllergyIconComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], [[4, "color", null]], null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.colour; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.iconLetters; _ck(_v, 1, 0, currVal_1); }); }
function View_IllnessAllergyIconComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[4, "color", null], [2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(1, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(2, 0, ["", ""]))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.colour; var currVal_1 = i1.ɵnov(_v, 1).inline; var currVal_2 = (((i1.ɵnov(_v, 1).color !== "primary") && (i1.ɵnov(_v, 1).color !== "accent")) && (i1.ɵnov(_v, 1).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.iconContent; _ck(_v, 2, 0, currVal_3); }); }
export function View_IllnessAllergyIconComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], [[8, "className", 0], [4, "background", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_IllnessAllergyIconComponent_1)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_IllnessAllergyIconComponent_2)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co.mdIcon == false); _ck(_v, 2, 0, currVal_2); var currVal_3 = (_co.mdIcon == true); _ck(_v, 4, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "mat-elevation-z3 illest-icon ", _co.illClass, ""); var currVal_1 = _co.colour; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_IllnessAllergyIconComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "illness-allergy-icon", [], null, null, null, View_IllnessAllergyIconComponent_0, RenderType_IllnessAllergyIconComponent)), i1.ɵdid(1, 114688, null, 0, i5.IllnessAllergyIconComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var IllnessAllergyIconComponentNgFactory = i1.ɵccf("illness-allergy-icon", i5.IllnessAllergyIconComponent, View_IllnessAllergyIconComponent_Host_0, { iconContent: "iconContent", illClass: "illClass", mdIcon: "mdIcon", numOfSteps: "numOfSteps", step: "step" }, {}, []);
export { IllnessAllergyIconComponentNgFactory as IllnessAllergyIconComponentNgFactory };
