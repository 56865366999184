import { Component, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'app-invitation-view-sms',
  templateUrl: './invitation-view-sms.component.html',
  styleUrls: ['./invitation-view-sms.component.css'],
})
export class InvitationViewSmsComponent implements OnInit {
  closeModal = new EventEmitter();

  isMobile = false;

  constructor() {}

  ngOnInit() {
    if (window.matchMedia('(min-width: 992px)').matches) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
  }
}
