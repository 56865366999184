import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';

import { MerchantService } from '../shared/merchant.service';

import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { UtilsClass } from '../../../shared/types/utils/utils.class';

@Component({
  selector: 'app-merchant-trading-hours-view',
  templateUrl: './merchant-trading-hours-view.component.html',
  styleUrls: ['./merchant-trading-hours-view.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.animate', style({ opacity: 0 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.15s ease-in-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-10%)', offset: 0, height: 0 }),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.animate', style({ opacity: 1 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.15s ease-in-out',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(5%)', offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class MerchantTradingHoursViewComponent implements OnInit {
  @Input() merchantID;
  @Input() isModal = false;
  @Input() displayBusinessHours = true;

  @Output() close = new EventEmitter();
  tradingHours;
  merchantTimezone;
  merchantTimezoneValue: any = 'void';
  util = new UtilsClass();
  isAdminOrPromoter = false;

  sessionType = 'guest';
  merchant;

  constructor(
    private authenticationService: AuthenticationService,
    private merchantService: MerchantService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      this.merchantID = data;

      this.isModal = true;
    }
  }

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((st) => {
      this.sessionType = st;

      this.authenticationService.isPromoterOrAdmin().subscribe((r) => {
        this.isAdminOrPromoter = r;

        if (this.merchantID) {
          const p = {
            fields: 'ID,TimeZoneCode,TimeZoneValue',
          };
          this.merchantService.getMerchantDetails(this.merchantID, p, this.sessionType).subscribe((m) => {
            if (m) {
              this.merchant = m;
              if (this.merchant['TimeZoneCode']) {
                this.merchantTimezone = this.merchant['TimeZoneCode'];
                if (this.merchant['TimeZoneValue']) {
                  this.merchantTimezoneValue = this.merchant['TimeZoneValue'];
                }
              }

              this.setup();
            }
          });
        } else {
          this.authenticationService.getCurrentPractice().subscribe((practice) => {
            if (practice) {
              this.merchant = practice;
              this.merchantID = practice.ID;
              if (this.merchant['TimeZoneCode']) {
                this.merchantTimezone = this.merchant['TimeZoneCode'];
                if (this.merchant['TimeZoneValue']) {
                  this.merchantTimezoneValue = this.merchant['TimeZoneValue'];
                }
              }

              this.setup();
            }
          });
        }
      });
    });
  }

  setup() {
    const payload = {
      merchantID: this.merchantID,
    };
    this.merchantService.getTradingHourListGlobal(payload).subscribe((res2) => {
      if (!this.merchantTimezone) {
        this.merchantTimezone = res2[0]['TimeZone'];
      }

      if (this.merchantTimezoneValue == 'void') {
        const v = this.util.getTimeZoneValueByCode(res2[0]['TimeZone']);

        if (v !== null) {
          this.merchantTimezone = res2[0]['TimeZone'];
          this.merchantTimezoneValue = v;
          this.updateTimeZone();
        }
      }

      const order = { Monday: 1, Tuesday: 2, Wednesday: 3, Thursday: 4, Friday: 5, Saturday: 6, Sunday: 7 };

      this.tradingHours = res2.sort(function (a, b) {
        return order[a['DayOfWeek.Label.Singular']] - order[b['DayOfWeek.Label.Singular']];
      });
    });
  }

  closeEvent() {
    this.close.emit(true);
  }

  updateTimeZone() {
    if (this.merchantTimezone && this.merchantTimezoneValue) {
      const p = {
        timeZoneCode: this.merchantTimezone,
        timeZoneValue: this.merchantTimezoneValue,
      };

      if (this.merchantID && this.isAdminOrPromoter == true) {
        this.merchantService.editMerchant(this.merchantID, p).subscribe((r) => {
          if (r) {
          }
        });
      } else if (
        this.sessionType == 'merchant' ||
        this.sessionType == 'admin' ||
        this.sessionType == 'promoter' ||
        this.sessionType == 'merchant-admin'
      ) {
        this.authenticationService.editCurrentPractice(p).subscribe((r) => {
          if (r) {
          }
        });
      }
    }
  }
}
