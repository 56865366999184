import { UtilSchema } from '../../../../../../../../../../../../shared/types/utils/utils.type';

export const PRACTICE_PERSONALIZATION_SCHEMA: UtilSchema[] = [
  {
    code: 'PracticeTradingAs',
    label: 'Trading As',
    field: 'TradingAs',
    group: '1',
  },
  {
    code: 'PracticeFirstName',
    label: 'First Name',
    field: 'FirstName',
    group: '1',
  },
  {
    code: 'PracticeLegalName',
    label: 'Legal Name',
    field: 'Name',
    group: '1',
  },
  {
    code: 'PracticeMobileNumber',
    label: 'Mobile',
    field: 'mobiles.Number',
    group: '2',
    icon: 'fas fa-mobile',
    isPhone: true,
    isLandLine: false,
  },
  {
    code: 'PracticePhoneNumber',
    label: 'Phone',
    field: 'phones.Number',
    group: '2',
    icon: 'fas fa-phone',
    isPhone: true,
    isLandLine: true,
  },

  {
    code: 'DedicatedSMSNumber',
    label: 'SMS Number',
    field: 'Twilio_Number',
    group: '2',
    icon: 'fas fa-sms',
    isPhone: true,
    isLandLine: false,
  },

  {
    code: 'PracticeEmail',
    label: 'Email',
    field: 'emails.Email',
    group: '2',
    icon: 'fas fa-at',
  },
  {
    code: 'PracticeAddress',
    label: 'Address',
    field: 'addresses.Calculated',
    group: '2',
    icon: 'fas fa-map-marker-alt',
  },
  {
    code: 'PracticeFacebook',
    label: 'Facebook',
    field: 'Facebook',
    group: '3',
    icon: 'fab fa-facebook-f',
  },
  {
    code: 'PracticeInstagram',
    label: 'Instagram',
    field: 'Instagram',
    group: '3',
    icon: 'fab fa-instagram',
  },
  {
    code: 'PracticeTwitter',
    label: 'Twitter',
    field: 'Twitter',
    group: '3',
    icon: 'fab fa-twitter',
  },
  {
    code: 'PracticeLinkedIn',
    label: 'LinkedIn',
    field: 'LinkedIn',
    group: '3',
    icon: 'fab fa-linkedin',
  },
  {
    code: 'PracticePinterest',
    label: 'Pinterest',
    field: 'Pinterest',
    group: '3',
    icon: 'fab fa-pinterest',
  },
  {
    code: 'PracticeTumblr',
    label: 'Tumblr',
    field: 'Tumblr',
    group: '3',
    icon: 'fab fa-tumblr',
  },
  {
    code: 'PracticeVimeo',
    label: 'Vimeo',
    field: 'Vimeo',
    group: '3',
    icon: 'fab fa-vimeo',
  },
  {
    code: 'PracticeYouTube',
    label: 'YouTube',
    field: 'YouTube',
    group: '3',
    icon: 'fab fa-youtube',
  },

  {
    code: 'PracticeWebSite',
    label: 'Website',
    field: 'URL',
    group: '3',
    icon: 'fas fa-globe',
  },

  {
    code: 'PracticeABN',
    label: 'ABN',
    field: 'ABN',
    group: '4',
  },

  {
    code: 'PracticeACN',
    label: 'ACN',
    field: 'ACN',
    group: '4',
  },
];
