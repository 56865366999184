import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-empty-list-message',
  templateUrl: './empty-list-message.component.html',
  styleUrls: ['./empty-list-message.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.row', style({ opacity: 0 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.row', style({ opacity: 1 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class EmptyListMessageComponent implements OnInit {
  @Input()
  title = '';

  @Input()
  message = 'There are no records to display.';

  @Input()
  isAction = false;

  @Input()
  actionIcon = 'add';

  @Input()
  actionLabel = 'Create';

  @Output()
  actionEvent = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  actionEventClick() {
    this.actionEvent.emit(true);
  }
}
