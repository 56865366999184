import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PracticeAppointmentCancellationPolicyComponent } from '../../../core/session/practice-appointment-cancellation-policy/practice-appointment-cancellation-policy.component';
import { AppointmentAvailabilityListComponent } from '../appointment-availability-list/appointment-availability-list.component';
import { AppointmentAvailabilityComponent } from '../appointment-availability/appointment-availability.component';
import { AppointmentCreateCustomerComponent } from '../appointment-create-customer/appointment-create-customer.component';
import { AppointmentCreateMerchantComponent } from '../appointment-create-merchant/appointment-create-merchant.component';
import { AppointmentCreateComponent } from '../appointment-create/appointment-create.component';
import { AppointmentIframeViewComponent } from '../appointment-iframe-view/appointment-iframe-view.component';
import { AppointmentLandingCreateComponent } from '../appointment-landing-create/appointment-landing-create.component';
import { AppointmentListCalendarComponent } from '../appointment-list calendar/appointment-list-calendar.component';
import { AppointmentListCalendarOverviewComponent } from '../appointment-list-calendar-overview/appointment-list-calendar-overview.component';
import { AppointmentLookupCreateComponent } from '../appointment-lookup-create/appointment-lookup-create.component';
import { AppointmentLookupEditComponent } from '../appointment-lookup-edit/appointment-lookup-edit.component';
import { AppointmentLookupListComponent } from '../appointment-lookup-list/appointment-lookup-list.component';
import { AppointmentLookupSortComponent } from '../appointment-lookup-sort/appointment-lookup-sort.component';
import { AppointmentPageCustomizeComponent } from '../appointment-page-customize/appointment-page-customize.component';
import { AppointmentSyncCalendarCardComponent } from '../appointment-sync-calendar-card/appointment-sync-calendar-card.component';
import { AppointmentViewComponent } from '../appointment-view/appointment-view.component';
import { RequestAppointmentViewComponent } from '../request-appointment-view/request-appointment-view.component';
import { ScheduleAppointmentViewComponent } from '../schedule-appointment-view/schedule-appointment-view.component';

const routes: Routes = [
  {
    path: 'AppointmentLandingCreateComponent',
    component: AppointmentLandingCreateComponent
  },
  {
    path: 'AppointmentViewComponent',
    component: AppointmentViewComponent
  },
  {
    path: 'AppointmentCreateCustomerComponent',
    component: AppointmentCreateCustomerComponent
  },
  {
    path: 'AppointmentCreateMerchantComponent',
    component: AppointmentCreateMerchantComponent
  },
  {
    path: 'AppointmentCreateComponent',
    component: AppointmentCreateComponent
  },
  {
    path: 'AppointmentListCalendarComponent',
    component: AppointmentListCalendarComponent
  },
  {
    path: 'AppointmentListCalendarOverviewComponent',
    component: AppointmentListCalendarOverviewComponent
  },
  {

    path: 'RequestAppointmentViewComponent',
    component: RequestAppointmentViewComponent
  },
  {
    path: 'ScheduleAppointmentViewComponent',
    component: ScheduleAppointmentViewComponent
  },

{
    path: 'PracticeAppointmentCancellationPolicyComponent',
    component: PracticeAppointmentCancellationPolicyComponent,
  },
  {
    path: 'AppointmentPageCustomizeComponent',
    component: AppointmentPageCustomizeComponent
  },
  {
    path: 'AppointmentAvailabilityComponent',
    component: AppointmentAvailabilityComponent,
  },
  {
    path: 'AppointmentIframeViewComponent',
    component: AppointmentIframeViewComponent,
  },
  {
    path: 'AppointmentLookupListComponent',
    component: AppointmentLookupListComponent,
  },
  {
    path: 'AppointmentLookupEditComponent',
    component: AppointmentLookupEditComponent,
  },
  {
    path: 'AppointmentAvailabilityListComponent',
    component: AppointmentAvailabilityListComponent,
  },
  {
    path: 'AppointmentLookupCreateComponent',
    component: AppointmentLookupCreateComponent,
  },
  {
    path: 'AppointmentLookupSortComponent',
    component: AppointmentLookupSortComponent,
  },
  {
    path: 'AppointmentSyncCalendarCardComponent',
    component: AppointmentSyncCalendarCardComponent,
  }

];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AppointmentRoutingModule { }
