import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, skip } from 'rxjs/operators';
import { User } from '../../../../../../../core/authentication/shared/shared/types/authentication.type';
import { Prospect } from '../../../../../../customer-prospect/shared/types/customer-prospect.type';
import { CustomerAndProspect } from '../../../../../../invitation/shared/types/invitation.type';
import { Note } from '../../../../../shared/type/notes.type';
import { NoteModalMode } from '../../../notes.modal';

export class NoteListToggles {
  archived = false;
  systemNotes = false;
}

@Component({
  selector: 'ipv-note-list',
  templateUrl: './note-list.component.html',
  styleUrls: ['./note-list.component.css'],
})
export class NoteListComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('noteTextFilter', { static: true }) noteTextFilter: ElementRef<HTMLInputElement>;

  @Output() noteListModeSet: EventEmitter<NoteModalMode> = new EventEmitter();
  @Output() noteSelected: EventEmitter<Note> = new EventEmitter();
  @Output() deleteButtonClicked: EventEmitter<Note> = new EventEmitter();
  @Output() archiveButtonClicked: EventEmitter<Note> = new EventEmitter();
  @Output() unarchiveButtonClicked: EventEmitter<Note> = new EventEmitter();
  @Output() loadMoreClicked: EventEmitter<void> = new EventEmitter();
  @Output() showSystemNotesToggled: EventEmitter<NoteListToggles> = new EventEmitter();
  @Output() showArchivedNotesToggled: EventEmitter<NoteListToggles> = new EventEmitter();
  @Output() closeModal: EventEmitter<void> = new EventEmitter();

  @Input() user: User;
  @Input() selectedPatient: Partial<CustomerAndProspect> | Prospect;
  @Input() notes: Note[];
  @Input() noteDates: Note[];
  @Input() showLoadMoreButton: boolean;
  @Input() parentRoute: string;

  showArchivedNotes = false;
  showSystemNotes = false;

  filteredNotes: Note[];

  noteTextFilterDebounceSubject: Subject<string>;

  todayTitle = moment().format('YYYY-MM-DD');
  yesterdayTitle = moment().add(-1, 'd').format('YYYY-MM-DD');
  filterText = '';

  constructor(private router: Router) {}

  ngOnInit() {
    this.filteredNotes = this.notes;
    this.noteTextFilterDebounceSubject = new BehaviorSubject<string>('');
    this.noteTextFilterDebounceSubject.pipe(untilDestroyed(this), skip(1), debounceTime(1300)).subscribe((noteText) => {
      this.filteredNotes = this.notes.filter((note) => note.Description.includes(noteText));
    });
  }

  ngOnDestroy() {
    /** required for untilDestroyed (Replace with @UntilDestroyed() Decorator in angular 9 and delete ngOnDestroy()) */
  }

  ngOnChanges() {
    /** This preserves the filter if the system notes option is toggled */
    this.filteredNotes = this.notes.filter((note) => note.Description.includes(this.filterText)).reverse();
    this.scrollNotes('bottom', true);
  }

  setNoteTextFilter(noteText: string) {
    this.noteTextFilterDebounceSubject.next(noteText);
    this.filterText = noteText;
  }

  clearNoteTextFilter() {
    this.filterText = '';
    this.noteTextFilter.nativeElement.value = '';
    this.noteTextFilterDebounceSubject.next('');
  }

  setNoteListToCreateMode() {
    this.noteListModeSet.emit('create');
  }

  setNoteListToUpdateMode(note: Note) {
    this.noteSelected.emit(note);
    this.noteListModeSet.emit('update');
  }

  setNoteListToViewMode(note: Note) {
    this.noteSelected.emit(note);
    this.noteListModeSet.emit('view');
  }

  loadMoreNotes() {
    this.loadMoreClicked.emit();
  }

  scrollNotes(scroll: any = 0, isAnimated = false) {
    let duration = 0;

    if (isAnimated === true) {
      duration = 1000;

      if (scroll === 'top') {
        setTimeout(() => {
          $('.activity-log-list-body').animate(
            {
              scrollTop: 0,
            },
            duration
          );
        }, 200);
      } else if (scroll === 'bottom') {
        const div = document.getElementsByClassName('note-list-body')[0];
        setTimeout(() => {
          $('.activity-log-list-body').animate(
            {
              scrollTop: div.scrollHeight - div.clientHeight + 50,
            },
            duration
          );
        }, 200);
      } else {
        setTimeout(() => {
          $('.activity-log-list-body').animate(
            {
              scrollTop: scroll,
            },
            duration
          );
        }, 200);
      }
    } else {
      if (scroll === 'top') {
        setTimeout(() => {
          $('.activity-log-list-body').animate(
            {
              scrollTop: 0,
            },
            duration
          );
        }, 200);
      } else if (scroll === 'bottom') {
        setTimeout(() => {
          const div = document.getElementsByClassName('note-list-body')[0];

          $('.activity-log-list-body').animate(
            {
              scrollTop: div.scrollHeight - div.clientHeight,
            },
            duration
          );
        }, 200);
      } else {
        setTimeout(() => {
          $('.activity-log-list-body').animate(
            {
              scrollTop: scroll,
            },
            duration
          );
        }, 200);
      }
    }
  }

  toggleHiddenSystemNotes(matCheckboxChange: MatCheckboxChange) {
    if (matCheckboxChange.checked) {
      this.showArchivedNotes = false;
    }
    this.showSystemNotesToggled.emit({ systemNotes: !matCheckboxChange.checked, archived: this.showArchivedNotes });
  }

  toggleHiddenArchivedNotes(matCheckboxChange: MatCheckboxChange) {
    if (matCheckboxChange.checked) {
      this.showSystemNotes = false;
    }
    this.showArchivedNotesToggled.emit({ archived: matCheckboxChange.checked, systemNotes: this.showSystemNotes });
  }

  deleteNote(note: Note) {
    this.deleteButtonClicked.emit(note);
  }

  archiveNote(note: Note) {
    this.archiveButtonClicked.emit(note);
  }

  unarchiveNote(note: Note) {
    this.unarchiveButtonClicked.emit(note);
  }

  getSharedWithList(note: Note) {
    const sharedWithList: string[] = [];

    if (note.View_By_Private === '1') {
      sharedWithList.push('Private');
    }

    if (note.View_By_Patient === '1') {
      sharedWithList.push('Patient');
    }

    if (note.View_By_Merchant === '1') {
      sharedWithList.push('Merchant');
    }

    if (note.View_By_Supplier === '1') {
      sharedWithList.push('Supplier');
    }

    if (note.View_By_Funder === '1') {
      sharedWithList.push('Funder');
    }

    const sharedWithListText = sharedWithList.join(', ');
    return sharedWithListText;
  }

  openRelationship(filteredNote: Note) {
    const route = filteredNote.Relationship_Route.split('/');

    if (this.parentRoute) {
      this.router
        .navigate([
          `/${this.parentRoute}`,
          {
            outlets: {
              page: route,
            },
          },
        ])
        .then(() => this.closeModal.emit());
    } else {
      this.router
        .navigate([
          {
            outlets: {
              page: route,
            },
          },
        ])
        .then(() => this.closeModal.emit());
    }
  }
}
