import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ipv-data-table-control-container',
  templateUrl: './data-table-control-container.component.html',
  styleUrls: ['./data-table-control-container.component.css'],
})
export class DataTableControlContainerComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
