<div class="clearfix flex row clearfix time-input" *ngIf="timePickerType == 'legacy'">
  <mat-icon class="fas fa-clock"></mat-icon>

  <div class="hour-input full-width">
    <mat-form-field class="time-class" appearance="outline">
      <input
        class=""
        #hoursInputID
        [value]="hours | number: '2.0-0'"
        [disabled]="disableHours"
        [required]="requiredLabel"
        (change)="changingHours($event.target.value)"
        (focus)="$event.target.select()"
        type="number"
        matInput
        name="hoursInputID2"
      />

      <div class="time-change-hours-class text-center">
        <mat-icon class="fas fa-chevron-up change-time" (click)="changeHoursUP()" color="primary"></mat-icon>
        <mat-icon class="fas fa-chevron-down change-time" (click)="changeHoursDown()" color="primary"></mat-icon>
      </div>
    </mat-form-field>
  </div>

  <div class="minute-input full-width">
    <mat-form-field class="time-hours-class" appearance="outline">
      <input
        class=""
        #minutesInputID
        [value]="minutes | number: '2.0-0'"
        [disabled]="disableMinutes"
        [required]="requiredLabel"
        (change)="changingMinutes($event.target.value)"
        (focus)="$event.target.select()"
        type="number"
        matInput
        name="minutesInputID2"
      />

      <div class="time-change-hours-class text-center">
        <mat-icon class="fas fa-chevron-up change-time" (click)="changeMinUP()" color="primary"></mat-icon>
        <mat-icon class="fas fa-chevron-down change-time" (click)="changeMinDown()" color="primary"></mat-icon>
      </div>
    </mat-form-field>
  </div>

  <mat-form-field class="time-am-pm-selection full-width" appearance="outline">
    <mat-select class="" [(ngModel)]="timePeriod" (ngModelChange)="changingtimePeriod($event)">
      <mat-option [value]="'AM'">AM</mat-option>
      <mat-option [value]="'PM'">PM</mat-option>
    </mat-select>
  </mat-form-field>
</div>
