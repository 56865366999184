<div class="row card-header modal-heading primary-gradient-img inModal rel clearfix flex flex-center">
  <button class="btn-close btn-clear mat-button abs-right" (click)="closeEvent()">
    <mat-icon>clear</mat-icon>
  </button>

  <div class="titleArea">
    <h2 class="summary-header white rm-m">Book a training session</h2>
  </div>
</div>

<mat-dialog-content class="text-center">
  <p class="smaller rm-m">
    Use the calendar below to select the best time for us to arrange a guided tour of our platform.
  </p>
  <p class="small info text-center clearfix mt">
    <mat-icon class="primary">info</mat-icon>
    Having trouble seeing calendar?
    <a target="_blank" href="{{ myExternalPageLink }}"> Click Here</a>
  </p>

  <iframe class="iframe-style full-width row clearfix" [src]="iframeSrc"></iframe>
</mat-dialog-content>
