<div class="rel" *ngIf="listDB.firstLoadEvent == false" @ngIfAnimation>
  <div class="row clearfix padded rel">
    <h1 class="dataHeader rm-mt transparent">Fetching data...</h1>
  </div>
</div>

<div [hidden]="listDB.data.length == 0">
  <div [hidden]="
      displayContractView == true ||
      displayInvitationView == true ||
      displayContractViewEP == true ||
      displayPersonalCirView == true ||
      displayCapacityCheck == true ||
      displayCreditCheck == true ||
      displaySettlmentList == true
    ">
    <h1 class="dataHeader">
      Contracts
      <!-- <span class="badge">{{listDB.data.length}}</span> -->

      <span class="badge" id="totalContracts">
        <mat-icon>email</mat-icon>
        <!-- <span countUp [endVal]="listDB.data.length"></span> -->
        <span [endVal]="dataSource.filteredData.length" countUp></span>
      </span>
    </h1>
    <p class="help-label dataHeader" *ngIf="approvedOrActive != null">List of Approved and Active Contracts</p>
    <mat-card class="list-card">
      <!-- Filters -->
      <div class="row clearfix searchArea primary-gradient-img">
        <div class="row flex justify">
          <!-- <div class="qtr-width flex" id="search">
            <mat-icon class="schIcon mt mr">search</mat-icon>
            <mat-form-field class="schSel full-width" appearance="outline">
              <mat-label>Add Filters</mat-label>
              <mat-select class="" matInput placeholder="Add Filters" [formControl]="searchVals"
                multiple>

                <mat-select-trigger>
                  {{searchVals.value ? searchVals.value[0] : ''}}
                  <span *ngIf="searchVals.value?.length > 1" class="additional-selection">
                    (+{{searchVals.value.length - 1}} others)
                  </span>
                </mat-select-trigger>
                <mat-option *ngFor="let searchVal of searchValList" [value]="searchVal">{{searchVal}}</mat-option>
              </mat-select>
            </mat-form-field>


          </div> -->

          <div class="full-width clearfix flex animated fadeInDown">
            <mat-icon class="schIcon mt mr">search</mat-icon>



            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Search by name, mobile, email, contract ID, invitation ID ...</mat-label>
              <input class="clearfix" (input)="setFilter($event.target.value, 'fullName')" name="CustomerFullName"
                placeholder="Search by name, mobile, email, contract ID, invitation ID ..." matInput />
            </mat-form-field>




            <mat-form-field class="ml half-width" appearance="outline"
            *ngIf="merchantsFilter && merchantsFilter.length>1">
            <mat-label>{{'Merchant' | translate}}</mat-label>
            <mat-select class="full-width" (selectionChange)="setFilter($event.value, 'Merchant_key')"
              name="ContractStatussss" placeholder="{{'Merchant' | translate}}">
              <mat-option [value]="null">
                All
              </mat-option>
              <mat-option *ngFor="let a of merchantsFilter" [value]="a.ID">

                <app-card-picture class="sms-merchant-pic-settlement text-center  mr " [hideZoomButton]="true"
                  [cardID]="a.ID" [hideZoomButton]="false" [tableName]="'Merchant'">
                </app-card-picture> <span style="height:50px; line-height: 50px;">{{a.Label}}</span>
              </mat-option>
            </mat-select>
          </mat-form-field>


            <mat-form-field class="ml half-width" appearance="outline"
              *ngIf="statusCodesFilter && statusCodesFilter.length>1">
              <mat-label>Contract Status</mat-label>
              <mat-select class="full-width" (selectionChange)="setFilter($event.value, 'Status.Code')"
                name="ContractStaasatus" placeholder="Contract Status">
                <mat-option [value]="null">
                  All
                </mat-option>
                <mat-option *ngFor="let a of  statusCodesFilter" [value]="a.code">
                  <strong class="chip chip-enable" [style.background]="a['color']">
                    <mat-icon class="{{a['icon']}} smr" style="font-size: 1rem !important;"></mat-icon>
                    {{a['label']}}
                  </strong>
                </mat-option>
              </mat-select>
            </mat-form-field>


          </div>


        </div>
      </div>

      <table class="list-table" #table [dataSource]="dataSource" mat-table matSort>
        <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->
        <!-- ID Column -->

        <ng-container matColumnDef="merchantPicture">
          <th class="largeFont" *matHeaderCellDef mat-header-cell mat-sort-header></th>
          <td *matCellDef="let row" (click)="openDetailsViewEP(row)" mat-cell>

            <app-card-picture class="sms-merchant-pic-settlement text-center pull-left " [hideZoomButton]="true"
              [cardID]="row.Merchant_key" [hideZoomButton]="false" [tableName]="'Merchant'">
            </app-card-picture>

          </td>
          <td class="largeFont" *matFooterCellDef mat-footer-cell></td>
        </ng-container>

        <ng-container matColumnDef="patientPicture">
          <th class="largeFont" *matHeaderCellDef mat-header-cell mat-sort-header></th>
          <td *matCellDef="let row" (click)="openDetailsViewEP(row)" mat-cell>


            <app-card-picture class="sms-merchant-pic-settlement text-center pull-left " [hideZoomButton]="true"
              [cardID]="row.Customer_key" [hideZoomButton]="false" [tableName]="'Customer'">
            </app-card-picture>

          </td>
          <td class="largeFont" *matFooterCellDef mat-footer-cell></td>
        </ng-container>


        <ng-container matColumnDef="StartDate">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            Start
          </th>
          <td *matCellDef="let row" (click)="openDetailsViewEP(row['ID'], row['Customer_key'])" mat-cell
            matTooltip="{{ row['ApplicationDate'] | customDateTime }}">
            {{ row['ApplicationDate'] | customDate }}
          </td>
          <td *matFooterCellDef mat-footer-cell></td>
        </ng-container>
        <ng-container matColumnDef="FinishDate">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            Finish
          </th>
          <td *matCellDef="let row" (click)="openDetailsViewEP(row['ID'], row['Customer_key'])" mat-cell
            matTooltip="{{ row['FinishDate'] | customDateTime }}">
            <span *ngIf="row['FinishDate'] != '0000-00-00'">{{ row['FinishDate'] | customDate }} </span>
            <span *ngIf="row['FinishDate'] == '0000-00-00'">N/A</span>
          </td>
          <td *matFooterCellDef mat-footer-cell></td>
        </ng-container>
        <ng-container matColumnDef="Merchant">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            Merchant
          </th>
          <td *matCellDef="let row" (click)="openDetailsViewEP(row['ID'], row['Customer_key'])" mat-cell>
            {{ row['MerchantName'] }}
          </td>
          <td *matFooterCellDef mat-footer-cell></td>
        </ng-container>

        <ng-container matColumnDef="Product">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            Product
          </th>
          <td *matCellDef="let row" (click)="openDetailsViewEP(row['ID'], row['Customer_key'])" mat-cell>
            {{ row['Product.Label.Internal'] }}
          </td>
          <td *matFooterCellDef mat-footer-cell></td>
        </ng-container>



        <ng-container matColumnDef="CustomerName">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            {{ "KEYWORD.patient" | translate | titlecase }}
          </th>
          <td *matCellDef="let row" (click)="openDetailsViewEP(row['ID'], row['Customer_key'])" mat-cell
            matTooltip="{{ row['Customer.FullName'] }}">
            <strong> {{ row['Customer.FullName'] }}</strong>
          </td>
          <td *matFooterCellDef mat-footer-cell></td>
        </ng-container>
        <ng-container matColumnDef="RepaymentAmount">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            Repayment
          </th>
          <td *matCellDef="let row" (click)="openDetailsViewEP(row['ID'], row['Customer_key'])" mat-cell
            matTooltip="{{ row['LoanInstalments'] }}">
            {{ row['LoanInstalments'] | customCurrency }} /
            {{ row['RepaymentFrequency.Noun.Singular'] }}
          </td>
          <td *matFooterCellDef mat-footer-cell>Totals</td>
        </ng-container>
        <ng-container matColumnDef="AmountInvoice">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            Loan Amount
          </th>
          <td *matCellDef="let row" (click)="openDetailsViewEP(row['ID'], row['Customer_key'])" mat-cell
            matTooltip="{{ row['Amount.FinancedBeforeDeposit'] | customCurrency }}">
            {{ row['Amount.FinancedBeforeDeposit'] | customCurrency }}
          </td>
          <td *matFooterCellDef mat-footer-cell>
            {{ getTotalColumn('Amount.FinancedBeforeDeposit') | customCurrency }}
          </td>
        </ng-container>
        <ng-container matColumnDef="Deposit">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            Deposit
          </th>
          <td *matCellDef="let row" (click)="openDetailsViewEP(row['ID'], row['Customer_key'])" mat-cell
            matTooltip="{{ row['Deposit'] | customCurrency }}">
            {{ row['Deposit'] | customCurrency }}
          </td>
          <td *matFooterCellDef mat-footer-cell>
            {{ getTotalColumn('Deposit') | customCurrency }}
          </td>
        </ng-container>
        <ng-container matColumnDef="Financed">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            Amount (Inc Dep)
          </th>
          <td *matCellDef="let row" (click)="openDetailsViewEP(row['ID'], row['Customer_key'])" mat-cell
            matTooltip="{{ row['Amount.Financed'] | customCurrency }}">
            {{ row['Amount.Financed'] | customCurrency }}
          </td>
          <td *matFooterCellDef mat-footer-cell>
            {{ getTotalColumn('Amount.Financed') | customCurrency }}
          </td>
        </ng-container>
        <ng-container matColumnDef="TimeElapsed">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>Time Elapsed</th>
          <td *matCellDef="let row" (click)="openQuotationDetailView(row)" mat-cell>
            <span class="list-label" [style.background]="utils.daysPastColor(row['ApplicationDate'])"
              matTooltip="{{ row['LastEvent.DateTimeCreated'] | customDateTime }}">
              {{ row['LastEvent.DateTimeCreated'] | TimeLabelPast }} ago
            </span>
          </td>
          <td *matFooterCellDef mat-footer-cell></td>
        </ng-container>
        <ng-container matColumnDef="Balance">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            Current Balance
          </th>
          <td *matCellDef="let row" (click)="openDetailsViewEP(row['ID'], row['Customer_key'])" mat-cell
            matTooltip="{{ row['Amount.Outstanding'] | customCurrency }}">
            {{ row['Amount.Outstanding'] | customCurrency }}
          </td>
          <td *matFooterCellDef mat-footer-cell>
            {{ getTotalColumn('Amount.Outstanding') | customCurrency }}
          </td>
        </ng-container>
        <ng-container matColumnDef="ArrearsAmount">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            Arrears Amount
          </th>
          <td *matCellDef="let row" (click)="openDetailsViewEP(row['ID'], row['Customer_key'])" mat-cell
            matTooltip="{{ row['Arrears.Amount'] | customCurrency }}">
            {{ row['Arrears.Amount'] | customCurrency }}
          </td>
          <td *matFooterCellDef mat-footer-cell>
            {{ getTotalColumn('Arrears.Amount') | customCurrency }}
          </td>
        </ng-container>
        <ng-container matColumnDef="ArrearsDay">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            Arrears Days
          </th>
          <td *matCellDef="let row" (click)="openDetailsViewEP(row['ID'], row['Customer_key'])" mat-cell
            matTooltip="{{ row['Arrears.days'] }}">
            {{ row['Arrears.days'] }}
          </td>
          <td *matFooterCellDef mat-footer-cell></td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            Status
          </th>
          <td *matCellDef="let row" (click)="openDetailsViewEP(row['ID'], row['Customer_key'])" mat-cell>
            <strong class="chip chip-enable" [style.background]="row['Status.Color']">
              <mat-icon class="{{row['Status.Icon']}} smr" style="font-size: 1rem !important;"></mat-icon>
              {{row['Status']}}
            </strong>

          </td>
          <td *matFooterCellDef mat-footer-cell></td>
        </ng-container>

        <ng-container matColumnDef="quotationStatus">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            Quotation Status
          </th>
          <td *matCellDef="let row" (click)="openDetailsViewEP(row['ID'], row['Customer_key'])" mat-cell>
            <strong class="chip chip-enable smr" [style.background]="row['Quotation.Color']">
              <mat-icon class="{{row['Quotation.Icon']}} smr" style="font-size: 1rem !important;"></mat-icon>
              {{row['Quotation.Status.Label']}}
            </strong>
          </td>
          <td *matFooterCellDef mat-footer-cell></td>
        </ng-container>

        <ng-container matColumnDef="Actions">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>

          </th>
          <td *matCellDef="let row" mat-cell>
            <div style="float: right">

              <app-invitation-actions *ngIf="row" [customerID]="row['Customer_key']"
                [invitationID]="row['Invitation_key']" [items]="items" [contractID]="row['ID']"
                [merchantID]="row['Merchant_key']" [dentistID]="row['DentistContact_key']"
                [campaignID]="row['Campaign_Key']" [contract]="row" [productID]="row['Product_key']">
              </app-invitation-actions>

              <!--
              <button [matMenuTriggerFor]="menu" mat-icon-button>
                <mat-icon>more_vert</mat-icon>
              </button> -->
              <mat-menu #menu="matMenu">


                <button [matMenuTriggerFor]="epVerMenu" mat-menu-item>
                  <mat-icon class="smr">description</mat-icon>
                  Contract
                </button>
                <mat-menu #epVerMenu="matMenu">
                  <button *ngIf="row['ID']" (click)="openDetailsViewEP(row['ID'], row['Customer_key'])" mat-menu-item>
                    <mat-icon class="smr">description</mat-icon>
                    <span>Contract Details</span>
                  </button>


                  <button *ngIf="isPromoterOrAdmin == true && row['ID']" (click)="viewContractLogs(row['ID'])"
                    mat-menu-item>
                    <mat-icon class="fa fa-list-alt smr"></mat-icon>
                    <span>View Contract Logs</span>
                  </button>






                  <button (click)="viewMessagesContract(row['ID'])" mat-menu-item>
                    <mat-icon class="fas fa-calendar-week smr"></mat-icon>
                    <span>View Events</span>
                  </button>

                  <mat-divider></mat-divider>


                  <button *ngIf="row['CapacityCheck.Status'] == 'Accept'"
                    (click)="openPersonalCircumstanceView(row['ID'])" mat-menu-item>
                    <mat-icon class="fas fa-user-tag smr" aria-hidden="true"></mat-icon>
                    <span>Personal Circumstances</span>
                  </button>


                  <button *ngIf="row['CapacityCheck.Status'] == 'Accept'" (click)="openCapacityCheckView(row['ID'])"
                    mat-menu-item>
                    <mat-icon class="fas fa-clipboard-check smr" aria-hidden="true"></mat-icon>
                    <span>Capacity Check</span>
                  </button>
                  <button *ngIf="row['CreditCheck.Status'] == 'Accept'" (click)="openCreditCheckView(row['ID'])"
                    mat-menu-item>
                    <mat-icon class="fas fa-clipboard-check smr" aria-hidden="true"></mat-icon>
                    <span>Credit Check</span>
                  </button>
                </mat-menu>


                <button [matMenuTriggerFor]="messageMenuInvitation" *ngIf="row['Invitation_key'] && row['Customer_key']"
                  mat-menu-item>
                  <mat-icon class="fas fa-envelope smr"></mat-icon>
                  Invitation
                </button>

                <mat-menu #messageMenuInvitation="matMenu">

                  <button *ngIf="row['Invitation_key']" (click)="openInvitationView(row['Invitation_key'])"
                    mat-menu-item>
                    <mat-icon class="smr"> description</mat-icon>
                    <span> Quick View </span>
                  </button>
                  <button *ngIf="row['Customer_key']"
                    (click)="fullInvitationDetails(row['Invitation_key'] , row['Customer_key'])" mat-menu-item>
                    <mat-icon class="fas fa-id-card smr"></mat-icon>
                    <span> Detailed view </span>
                  </button>
                  <mat-divider></mat-divider>



                  <button (click)="viewMessagesInvitation(row['Invitation_key'])" mat-menu-item>
                    <mat-icon class="fas fa-calendar-week smr"></mat-icon>
                    <span>View Events</span>
                  </button>

                  <button *ngIf="row['Product_key']" (click)="openProductView(row['Product_key'])" mat-menu-item>
                    <mat-icon class="smr">business_center</mat-icon>
                    <span>View Product</span>
                  </button>

                  <mat-divider></mat-divider>
                  <button *ngIf="row['Invitation_key'] " (click)="wakandaFiles(row['Invitation_key'])" mat-menu-item>
                    <mat-icon class="smr">attach_file</mat-icon>
                    <span>Wakanda Files</span>
                  </button>

                  <button *ngIf="row['Invitation_key'] " (click)="redirect(row['Invitation_key'])" mat-menu-item>
                    <mat-icon class="smr">link</mat-icon>
                    <span>Invitation link</span>
                  </button>
                  <!-- <mat-divider></mat-divider>
                  <button (click)="openCloneInvitationModal(row['Invitation_key'], row['Customer_key'])" mat-menu-item>
                    <mat-icon class="smr">file_copy</mat-icon>
                    <span>Clone</span>
                  </button> -->



                </mat-menu>

                <mat-divider></mat-divider>



                <button [matMenuTriggerFor]="messageMenuCustomer" *ngIf="row['Customer_key']" mat-menu-item>
                  <mat-icon class="fas fa-user smr"></mat-icon>
                  {{'Patient' | translate}}
                </button>
                <mat-menu #messageMenuCustomer="matMenu">

                  <button *ngIf="row['Customer_key']" (click)="quickView(row['Customer_key'])" mat-menu-item>
                    <mat-icon class="fas fa-user smr"></mat-icon>
                    <span> Quick view</span>
                  </button>

                  <button *ngIf="row['Customer_key']" (click)="openCustomerView(row['Customer_key'])" mat-menu-item>
                    <mat-icon class="fas fa-id-card smr"></mat-icon>
                    <span> Detailed view </span>
                  </button>

                  <button (click)="viewMessages(row['Customer_key'])" mat-menu-item>
                    <mat-icon class="fas fa-calendar-week smr"></mat-icon>
                    <span>View Events</span>
                  </button>


                  <mat-divider></mat-divider>

                  <button *ngIf="isModulePatientSMSActive == true && row['Customer_key']"
                    (click)="sendSMS(row['Customer_key'],row['Merchant_key'])" mat-menu-item>
                    <mat-icon class="fas fa-sms smr"></mat-icon>
                    <span>Send SMS</span>
                  </button>



                  <button *ngIf=" row['Customer_key']" (click)="sendEmail(row['Customer_key'])" mat-menu-item>
                    <mat-icon class="fas fa-at smr"></mat-icon>
                    <span>Send Email</span>
                  </button>


                </mat-menu>



                <button [matMenuTriggerFor]="messageMenuCustomersssss" *ngIf="row['Invitation_key']" mat-menu-item>
                  <mat-icon class="       {{'fas fa-tooth' | translate }} smr"></mat-icon>
                  {{'Dentist' | translate}}
                </button>
                <mat-menu #messageMenuCustomersssss="matMenu">

                  <button *ngIf="row['Invitation_key']" (click)="viewDentist(row['Invitation_key'])" mat-menu-item>
                    <mat-icon class="fas fa-user smr"></mat-icon>
                    <span> Quick view</span>
                  </button>

                  <mat-divider></mat-divider>

                  <button *ngIf="isModulePatientSMSActive == true && row['Invitation_key']"
                    (click)="contactSMSDentist(row['Invitation_key'])" mat-menu-item>
                    <mat-icon class="fas fa-sms smr"></mat-icon>
                    <span>Send SMS</span>
                  </button>



                  <button *ngIf=" row['Invitation_key']" (click)="contactDentist(row['Invitation_key'])" mat-menu-item>
                    <mat-icon class="fas fa-at smr"></mat-icon>
                    <span>Send Email</span>
                  </button>


                </mat-menu>

                <button *ngIf="row['Merchant_key']" (click)="viewMerchant(row['Merchant_key'])" mat-menu-item>
                  <mat-icon class="fas fa-building smr"></mat-icon>
                  View {{'merchant' | translate}}
                </button>



                <!-- <button mat-menu-item *ngIf="row['Invitation_key']" (click)="openInviteViewDetails(row['Invitation_key'])">
                  <mat-icon>description</mat-icon>
                  <span> {{"InvitationDetailsView" | translate}} </span>
                </button> -->



                <mat-divider></mat-divider>


                <button *ngIf="row['Status.Code'] == 'ACTIVE' && toNumber(row['Settlements.NotYetDrawn']) > 0"
                  (click)="createSettlement(row['ID'])" mat-menu-item>
                  <mat-icon class="fas fa-file-contract smr" aria-hidden="true"></mat-icon>
                  <span>New Settlement</span>
                </button>


                <mat-divider></mat-divider>


                <button [matMenuTriggerFor]="messageMenuInvitation4800" *ngIf="row['Customer_key'] && row['ID']"
                  mat-menu-item>
                  <mat-icon class="smr">note_add</mat-icon>
                  Notes
                </button>

                <mat-menu #messageMenuInvitation4800="matMenu">


                  <button (click)="createNote(row['Customer_key'], row['ID'])" mat-menu-item>
                    <mat-icon class="smr">note_add</mat-icon>
                    <span>Create Note</span>
                  </button>

                  <button (click)="viewNotes(row['Customer_key'])" mat-menu-item>
                    <mat-icon class="fa fa-list-alt smr"></mat-icon>
                    <span>View Notes</span>
                  </button>


                </mat-menu>





              </mat-menu>
            </div>
          </td>
          <td *matFooterCellDef mat-footer-cell></td>
        </ng-container>
        <tr class="headerRow" *matHeaderRowDef="displayedColumns; sticky: true" mat-header-row></tr>
        <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>
        <tr class="footerRow" *matFooterRowDef="displayedColumns; sticky: true" mat-footer-row></tr>
      </table>
      <mat-paginator #paginator [length]="dataSource.filteredData.length" [pageIndex]="0" [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions">
      </mat-paginator>
    </mat-card>
  </div>
  <div *ngIf="contractID != null && displayContractView == true">
    <app-contract-view [contractID]="contractID"></app-contract-view>
    <div class="full-width text-center" *ngIf="showDetailInList == false">
      <button class="" (click)="backToContractList()" mat-raised-button color="accent">
        <mat-icon>chevron_left</mat-icon>
        GoBack
      </button>
    </div>
  </div>
  <div *ngIf="invitationID != null && displayInvitationView == true">
    <!-- <app-invitation-view [invitationID]="invitationID"></app-invitation-view> -->
    <div class="full-width text-center">
      <button class="" (click)="cancelInviteViewDetails()" mat-raised-button color="accent">
        <mat-icon>chevron_left</mat-icon>
        Go Back
      </button>
    </div>
  </div>
  <div *ngIf="contractID != null && displayContractViewEP == true">
    <div class="full-width rel" *ngIf="showDetailInList == true">
      <button class="absPos btn-back" (click)="backToContractList()" mat-raised-button color="accent">
        <mat-icon>chevron_left</mat-icon>
        Show this {{ "KEYWORD.patient" | translate }}'s quotations
      </button>
    </div>
    <app-contract-detail [contractID]="contractID"></app-contract-detail>
    <div class="full-width text-center" *ngIf="showDetailInList == false">
      <button class="" (click)="backToContractList()" mat-raised-button color="accent">
        <mat-icon>chevron_left</mat-icon>
        Back to Contract List
      </button>
    </div>
  </div>
  <div *ngIf="contractID != null && displayPersonalCirView == true">
    <div class="full-width rel" *ngIf="showDetailInList == true">
      <button class="absPos btn-back" (click)="backToContractList()" mat-raised-button color="accent">
        <mat-icon>chevron_left</mat-icon>
        Show this {{ "KEYWORD.patient" | translate }}'s quotations
      </button>
    </div>
    <app-personal-circumstances [contractID]="contractID"></app-personal-circumstances>
    <div class="full-width text-center" *ngIf="showDetailInList == false">
      <button class="" (click)="backToContractList()" mat-raised-button color="accent">
        <mat-icon>chevron_left</mat-icon>
        Back to Contract List
      </button>
    </div>
  </div>
  <div *ngIf="contractID != null && displayCapacityCheck == true">
    <div class="full-width rel" *ngIf="showDetailInList == true">
      <button class="absPos btn-back" (click)="backToContractList()" mat-raised-button color="accent">
        <mat-icon>chevron_left</mat-icon>
        Show this {{ "KEYWORD.patient" | translate }}'s quotations
      </button>
    </div>
    <app-contract-capacity-check [contractID]="contractID"></app-contract-capacity-check>
    <div class="full-width text-center" *ngIf="showDetailInList == false">
      <button class="" (click)="backToContractList()" mat-raised-button color="accent">
        <mat-icon>chevron_left</mat-icon>
        Back to Contract List
      </button>
    </div>
  </div>
  <div *ngIf="contractID != null && displayCreditCheck == true">
    <div class="full-width rel" *ngIf="showDetailInList == true">
      <button class="absPos btn-back" (click)="backToContractList()" mat-raised-button color="accent">
        <mat-icon>chevron_left</mat-icon>
        Show this {{ "KEYWORD.patient" | translate }}'s quotations
      </button>
    </div>
    <app-contract-credit-check [contractID]="contractID"></app-contract-credit-check>
    <div class="full-width text-center" *ngIf="showDetailInList == false">
      <button class="" (click)="backToContractList()" mat-raised-button color="accent">
        <mat-icon>chevron_left</mat-icon>
        Back to Contract List
      </button>
    </div>
  </div>

  <div *ngIf="contractID != null && displaySettlmentList == true && showDetailInList == false">
    <app-settlement-list-global [dateFrom]="dateFrom" [dateTo]="dateTo" [contractID]="contractID">
    </app-settlement-list-global>
    <div class="full-width mt">
      <button class="" (click)="backToContractList()" mat-raised-button color="accent">
        <mat-icon>chevron_left</mat-icon>
        Back to Contract List
      </button>
    </div>
  </div>
</div>

<div *ngIf="listDB.data.length == 0 && listDB.firstLoadEvent != false">
  <app-empty-list-message></app-empty-list-message>
</div>
