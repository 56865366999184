<div class="row text-center clearfix" *ngIf="filtered != true"
  [ngClass]="filtered == true  ?  ['white-background',' manual-selection-container'] : 'manual-selection-container'    ">
  <mat-card class="rel card mt clearfix mb full-width" id="subscription-plan" style="max-width: 900px; float: none">
    <div class="clearfix">
      <div class="card-header primary-gradient-img clearfix inModal">
        <div class="row clearfix">
          <button class="btn-close btn-clear mat-button white" *ngIf="isModal == true" (click)="closeEvent()"
            tabIndex="-1">
            <mat-icon>clear</mat-icon>
          </button>
          <div class="row titleArea clearfix">
            <mat-icon class="pull-left patient-main-icon">account_circle</mat-icon>
            <h2 class="summary-header rm-m text-left">
              <!-- {{ beneficiary.CalculatedName}} -->
              {{title | translate}}

              <span class="subLabel text-left">
                {{description | translate}}
              </span>
            </h2>
          </div>

          <button class="btn mr new-patient-button pull-right" *ngIf="manualSelection!=true" (click)="newPatientQuick()"
            mat-raised-button color="accent">
            <mat-icon class="fas fa-user-plus smr" style="font-size: 1rem; margin-top: 10px"></mat-icon>
            New
            {{ "KEYWORD.patient" | translate | titlecase }}
          </button>

          <!-- <button class="btn-close btn-clear mat-button white" matDialogClose>
          <mat-icon>clear</mat-icon>
        </button> -->
        </div>
      </div>
      <mat-dialog-content id="messageGroupCreaste" [ngClass]="{ 'no-modal-height': isModalView != true }">
        <form class="lookup-form" #lookupForm="ngForm">
          <div class="full-width clearfix mt" *ngIf="
          merchants && merchants.length > 0 && currentMerchant && currentMerchant.ID && isPromoterOrAdmin == true
        ">
            <mat-form-field class="full-width" *ngIf="
              merchants && merchants.length > 0 && currentMerchant && currentMerchant.ID && isPromoterOrAdmin == true
            " appearance="outline">
              <mat-label>Merchant</mat-label>

              <mat-select [(ngModel)]="merchantID" (ngModelChange)="changeMerchant()" name="merchantID"
                placeholder="Merchant">
                <mat-select-trigger>
                  <div class="full-width flex flex-cust" *ngIf="merchant && merchant.ID">
                    <app-card-picture class="sms-merchant-pic prod text-center pull-left mr" [hideZoomButton]="true"
                      [cardID]="merchant.ID" [hideZoomButton]="false" [tableName]="'Merchant'"
                      [LastModified]="merchant['LastModified']">
                    </app-card-picture>
                    <p class="rm-m third-party-p full-width pull-left">
                      {{ merchant.TradingAs }}
                    </p>
                    <button class="mr" (click)="$event.stopPropagation(); viewMerchant(merchantID)" mat-button
                      mat-icon-button>
                      <mat-icon>visibility</mat-icon>
                    </button>
                  </div>
                </mat-select-trigger>
                <mat-option [value]="currentMerchant.ID">
                  <div class="full-width flex flex-cust">
                    <app-card-picture class="sms-merchant-pic prod text-center pull-left mr" [hideZoomButton]="true"
                      [cardID]="currentMerchant.ID" [hideZoomButton]="false" [tableName]="'Merchant'"
                      [LastModified]="currentMerchant['LastModified']">
                    </app-card-picture>
                    <p class="rm-m third-party-p full-width pull-left">
                      <strong>Promoter</strong>
                    </p>

                    <button class="mr" (click)="$event.stopPropagation(); viewMerchant(currentMerchant['ID'])"
                      mat-button mat-icon-button>
                      <mat-icon style="width: 40px">visibility</mat-icon>
                    </button>
                  </div>
                </mat-option>

                <mat-option *ngFor="let s of merchants | OrderArrayObject: ['TradingAs']" [value]="s.ID">
                  <div class="full-width flex flex-cust">
                    <app-card-picture class="sms-merchant-pic prod text-center pull-left mr" [hideZoomButton]="true"
                      [cardID]="s.ID" [hideZoomButton]="false" [tableName]="'Merchant'"
                      [LastModified]="s['LastModified']">
                    </app-card-picture>
                    <p class="rm-m third-party-p full-width pull-left">
                      {{ s.TradingAs }}
                    </p>

                    <button class="mr" (click)="$event.stopPropagation(); viewMerchant(s['ID'])" mat-button
                      mat-icon-button>
                      <mat-icon style="width: 40px">visibility</mat-icon>
                    </button>
                  </div>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <h3 class="sr-title sml rm-mb rm-mt full-width text-left">Basic Search</h3>
          <p class="full-width sml text-left mb smt">
            Search {{ "KEYWORD.patient" | translate | titlecase }}'s using one or more fields below with either full
            or
            partial data
          </p>

          <div class="full-width clearfix flex">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>First Name</mat-label>
              <input [(ngModel)]="partFirstName" matInput name="FirstName" placeholder="First Name" />
            </mat-form-field>

            <mat-form-field class="half-width sml smr" appearance="outline">
              <mat-label>Middle Name</mat-label>
              <input [(ngModel)]="partMiddleName" matInput name="LastMiddleName" placeholder="Middle Name" />
            </mat-form-field>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Last Name</mat-label>
              <input [(ngModel)]="partLastName" matInput name="LastName" placeholder="Last Name" />
            </mat-form-field>
          </div>
          <div class="full-width clearfix flex">
            <mat-form-field class="full-width smr" appearance="outline">
              <mat-label>Mobile</mat-label>
              <input [(ngModel)]="partMobile" type="text" matInput placeholder="Mobile" name="partMobile"
                pattern="\d{1,10}" />
            </mat-form-field>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Phone</mat-label>
              <input [(ngModel)]="partPhone" type="text" matInput placeholder="Phone" name="partPhone"
                pattern="\d{1,10}" />
            </mat-form-field>
          </div>
          <div class="full-width clearfix flex">
            <mat-form-field class="full-width smr" appearance="outline">
              <mat-label>Email</mat-label>
              <input [(ngModel)]="partEmail" matInput name="Email" placeholder="Email" />
            </mat-form-field>

            <app-date-input class="half-width" [defaultDate]="null" [isTime]="false" [startView]="'multi-year'"
              [maxDate]="'toDay'" [isClear]="true" (selectedDate)="selectDateToFilter($event)" label="Date of birth">
            </app-date-input>
          </div>

          <mat-form-field class="full-width " appearance="outline">
            <mat-label>{{ "KEYWORD.practice" | translate | titlecase}} Reference</mat-label>
            <input [(ngModel)]="partIDFromMerchant" matInput name="partIDFromMerchant"
              placeholder="{{ ' KEYWORD.practice'| translate | titlecase}} Reference" />
          </mat-form-field>


          <div class="full-width clearfix mb" *ngIf="tags && tags.length > 0">
            <app-tag-list class="full-width" [tags]="tags" [selectedTags]="selectedTagsDefault" [openPosition]="'above'"
              [tagFormatIsObject]="true" [title]="'Tags'" [canRemove]="true" [canCreate]="false"
              [directRemoveDefault]="true" [directRemove]="true" [hasFilter]="true" [canSelect]="false"
              (getSelectedTags)="getSelectedTags($event)">
            </app-tag-list>
          </div>

          <h3 class="sr-title  rm-m smb text-left">
            Advanced Search
          </h3>
          <mat-slide-toggle class="clearfix full-width mb" [(ngModel)]="panelOpenState" name="panelOpenState">
            Search {{ "KEYWORD.patient" | translate | titlecase }}'s using one or more fields below with either
            full
            or partial
            data
          </mat-slide-toggle>


          <div class="full-width" *ngIf="panelOpenState" @simpleFadeAnimation>

            <mat-form-field class="full-width" *ngIf="isPromoterOrAdmin == true" appearance="outline">
              <mat-label>ID</mat-label>
              <input [(ngModel)]="id" matInput name="FirstNameID" placeholder="ID" />
            </mat-form-field>

            <div class="full-width flex">
              <app-date-range-picker class="date-range-list-server full-width smr" [smallerInput]="false"
                [dateLabel]="'Join Date'" [selectedRange]="selectedRange" (getRange)="setDateRangeFilter($event)">
              </app-date-range-picker>

              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Limit results displayed</mat-label>
                <mat-select class="full-width" [(ngModel)]="maxRecords" name="maxRecords"
                  placeholder="Limit results displayed:">
                  <mat-option [value]="null">
                    <strong>No limit</strong>
                  </mat-option>
                  <mat-option *ngFor="let r of recordLength" [value]="r">
                    {{ r }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="full-width flex">
              <mat-form-field class="third-width smr" appearance="outline">
                <mat-label>Filer Addresses By</mat-label>
                <mat-select class="full-width" [(ngModel)]="addressType" name="currencyRecords"
                  placeholder="Address Type">
                  <mat-option [value]="'none'">
                    <strong>None</strong>
                  </mat-option>
                  <mat-option [value]="'state'">
                    <strong>State</strong>
                  </mat-option>
                  <mat-option [value]="'suburb'">
                    <strong>Suburb</strong>
                  </mat-option>
                  <mat-option [value]="'postCodeRange'">
                    <strong>Postcode Range</strong>
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <div class="full-width flex" *ngIf="addressType == 'state'">
                <mat-form-field class="full-width smr" appearance="outline">
                  <mat-label>State</mat-label>
                  <mat-select class="full-width" [(ngModel)]="state" (change)="stateChange()" name="State"
                    placeholder="State">
                    <mat-option [value]="null">
                      <strong>all</strong>
                    </mat-option>
                    <mat-option *ngFor="let r of states" [value]="r.code">
                      <strong>{{ r.code }}</strong> - {{ r.value }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <app-address-input class="full-width" *ngIf="addressType == 'suburb'" [isMiniView]="true"
                [canClose]="false" [isRequired]="false" (getAdress)="getAddress($event)">
              </app-address-input>

              <div class="full-width flex" *ngIf="addressType == 'postCodeRange'">
                <mat-form-field class="full-width smr" appearance="outline">
                  <mat-label>From</mat-label>
                  <input [(ngModel)]="postCodeFrom" matInput tyle="number" name="From" placeholder="From" />
                </mat-form-field>

                <mat-form-field class="full-width smr" appearance="outline">
                  <mat-label>To</mat-label>
                  <input [(ngModel)]="postCodeTo" matInput tyle="number" name="To" placeholder="To" />
                </mat-form-field>
              </div>
            </div>


            <h3 class="sr-title  smb text-left" *ngIf="displayMarketingConsent==true">
              Marketing
            </h3>
            <div class="full-width      "  *ngIf="displayMarketingConsent==true">
              <p class="rm-m pull-left mr">{{"Patient"| translate}}s consented to <strong>SMS</strong> Communication </p>
              <mat-radio-group class="alternate-theme pull-left ml" [(ngModel)]="marketingConsent" name="marketingConsent">
                <mat-radio-button [value]="null">All </mat-radio-button>
                <mat-radio-button [value]="true">Yes </mat-radio-button>
                <mat-radio-button [value]="false">No</mat-radio-button>

              </mat-radio-group>
            </div>
            <div class="full-width    smt mb "  *ngIf="displayMarketingConsent==true">
              <p class="rm-m pull-left  mr">{{"Patient"| translate}}s consented to <strong>Email</strong> Communication </p>
              <mat-radio-group class="alternate-theme  pull-left ml" [(ngModel)]="marketingConsentEmail" name="marketingConsentEmail">
                <mat-radio-button [value]="null">All </mat-radio-button>
                <mat-radio-button [value]="true">Yes </mat-radio-button>
                <mat-radio-button [value]="false">No</mat-radio-button>

              </mat-radio-group>
            </div>


            <div class="full-width " *ngIf="displayEmailMobile==true">
              <mat-checkbox class="pull-left text-left smt smb" [(ngModel)]="hasEmail" name="hasEmail">
                <span> <strong>Has a Email</strong> - Check box to filter customer that have a email address</span>
              </mat-checkbox>
            </div>

            <div class="full-width " *ngIf="displayEmailMobile==true">
              <mat-checkbox class="pull-left text-left smt smb" [(ngModel)]="hasMobile" name="hasMobile">





                <span><strong>Has a Mobile Number </strong> - Check box to filter that customer has a valid mobile
                  number.</span>
              </mat-checkbox>
            </div>




          </div>

        </form>
      </mat-dialog-content>
    </div>

    <div class="full-width text-center smb smt" *ngIf="manualSelection !=true">
      <hr class="mb" />


      <button class="btn-large pull-right" (click)="apply()" mat-raised-button color="accent">
        Search <mat-icon class="sml">keyboard_arrow_right</mat-icon>
      </button>






      <button class="an btn-large " mat-raised-button color="primary" *ngIf="isModal==true " (click)="closeEvent()">
        Close
      </button>




      <button mat-raised-button color="primary" class="btn-large  pull-left" (click)="goToPatientList()">
        All {{'KEYWORD.patient' | translate}}s
      </button>



    </div>

    <div class="full-width text-center smb smt" *ngIf="manualSelection ==true">
      <hr class="mb" />



      <button class="btn pull-right " (click)="apply()" mat-raised-button color="accent">
        Search <mat-icon class="sml">keyboard_arrow_right</mat-icon>
      </button>

      <button class="btn pull-right  mr " *ngIf="totalCards && totalCards.length " (click)="submit()" mat-raised-button
        color="accent">
        Submit <strong>({{totalCards.length}})</strong>
      </button>


      <button class="btn pull-right   mr" *ngIf="totalCards && totalCards.length " (click)="viewCards()"
        mat-raised-button color="primary">
        Check Selection <strong>({{totalCards.length}})</strong>
      </button>





      <button class="an btn pull-left  mr " mat-raised-button color="primary"
        *ngIf="isModal==true && manualSelection ==true" (click)="closeEvent()">
        Close
      </button>


      <button mat-raised-button color="primary" class="btn   pull-left" (click)="goToPatientList()">
        All {{'KEYWORD.patient' | translate}}s
      </button>



    </div>
  </mat-card>
</div>

<div class="full-width " *ngIf="filtered == true"
  [ngClass]="filtered == true  ?  ['white-background',' manual-selection-container'] : 'manual-selection-container'    ">

  <div class="clearfix" *ngIf="isPromoterOrAdmin == true && filtered == true">

    <mat-dialog-content id="messageGroupCreaste" [ngClass]="{ 'no-modal-height': isModalView != true }">


      <app-customer-prospect-list-global [merchantID]="merchantID" [partFirstName]="partFirstName" [dateFrom]="dateFrom"
        [getUnselectManual]="getRemoved" [state]="state" [id]="id" [partIDFromMerchant]="partIDFromMerchant"
        (getDetailedView)="getDetailedView($event)" (getSelectedCards)="getSelectedCardsEvent($event)"
        [manualSelection]="manualSelection" [postCodeFrom]="postCodeFrom" [postCodeTo]="postCodeTo" [suburb]="suburb"
        [maxRecords]="maxRecords" [marketingConsent]="marketingConsent" [marketingConsentEmail]="marketingConsentEmail"
        [dateTo]="dateTo" [hasEmail]="hasEmail" [hasMobile]="hasMobile" [partMiddleName]="partMiddleName"
        [birthDay]="birthDay" [partLastName]="partLastName" [partMobile]="partMobile" [partPhone]="partPhone"
        [partEmail]="partEmail" [tags]="_selectedTags">
      </app-customer-prospect-list-global>
    </mat-dialog-content>
    <div class="text-center full-width mb mt" *ngIf="manualSelection!==true">

      <button class="btn-large " (click)="backSearch()" mat-raised-button color="accent">
        <mat-icon class="smr">keyboard_arrow_left</mat-icon> {{backLabel}}
      </button>
    </div>

    <div class="text-center full-width mb mt" *ngIf="manualSelection===true">


      <button class="btn   pull-right " [disabled]="!totalCards || (totalCards && totalCards.length<=0)"
        (click)="submit()" mat-raised-button color="accent">
        Submit <strong *ngIf="totalCards && totalCards.length">({{totalCards.length}})</strong>
      </button>


      <button class="btn   pull-right  mr" *ngIf="totalCards && totalCards.length>0" (click)="viewCards()"
        mat-raised-button color="primary">
        Check Selection <strong *ngIf="totalCards && totalCards.length">({{totalCards.length}})</strong>
      </button>


      <button class="btn  " mat-raised-button color="primary" *ngIf="isModal==true" (click)="closeEvent()">
        Close
      </button>

      <button class="btn  pull-left" (click)="backSearch2()" mat-raised-button color="primary">
        <mat-icon class="smr">keyboard_arrow_left</mat-icon> {{backLabel}}
      </button>
    </div>

  </div>

  <div class="clearfix" *ngIf="isPromoterOrAdmin != true && filtered == true">
    <mat-dialog-content id="messageGroupCreaste" [ngClass]="{ 'no-modal-height': isModalView != true }">

      <app-customer-prospect-list [partFirstName]="partFirstName" [partMiddleName]="partMiddleName"
        (getDetailedView)="getDetailedView($event)" [birthDay]="birthDay" [getUnselectManual]="getRemoved"
        (getSelectedCards)="getSelectedCardsEvent($event)" [state]="state" [id]="id" [manualSelection]="manualSelection"
        [partIDFromMerchant]="partIDFromMerchant" [postCodeFrom]="postCodeFrom" [postCodeTo]="postCodeTo"
        [hasEmail]="hasEmail" [hasMobile]="hasMobile" [suburb]="suburb" [maxRecords]="maxRecords"
        [marketingConsent]="marketingConsent" [marketingConsentEmail]="marketingConsentEmail" [dateFrom]="dateFrom"
        [dateTo]="dateTo" [partLastName]="partLastName" [partMobile]="partMobile" [partPhone]="partPhone"
        [partEmail]="partEmail" [tags]="_selectedTags">
      </app-customer-prospect-list>
    </mat-dialog-content>
    <div class="text-center full-width mb mt" *ngIf="manualSelection!==true">

      <button class="btn-large " (click)="backSearch()" mat-raised-button color="accent">
        <mat-icon class="smr">keyboard_arrow_left</mat-icon> {{backLabel}}
      </button>
    </div>

    <div class="text-center full-width mb mt" *ngIf="manualSelection===true">


      <button class="btn   pull-right " [disabled]="!totalCards || (totalCards && totalCards.length<=0)"
        (click)="submit()" mat-raised-button color="accent">
        Submit <strong *ngIf="totalCards && totalCards.length">({{totalCards.length}})</strong>
      </button>


      <button class="btn   pull-right  mr" *ngIf="totalCards && totalCards.length>0" (click)="viewCards()"
        mat-raised-button color="primary">
        Check Selection <strong *ngIf="totalCards && totalCards.length">({{totalCards.length}})</strong>
      </button>


      <button class="btn  " mat-raised-button color="primary" *ngIf="isModal==true" (click)="closeEvent()">
        Close
      </button>

      <button class="btn  pull-left" (click)="backSearch2()" mat-raised-button color="primary">
        <mat-icon class="smr">keyboard_arrow_left</mat-icon> {{backLabel}}
      </button>
    </div>

  </div>
</div>
