import { Component, OnInit } from '@angular/core';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { ThemeService } from '../shared/theme.service';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { SettingsService } from '../../settings/shared/settings.service';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';

declare let $: any;

@Component({
  selector: 'app-theme-list',
  templateUrl: './theme-list.component.html',
  styleUrls: ['./theme-list.component.css'],
})
export class ThemeListComponent implements OnInit {
  themes = [];
  selectedTheme: any = {};
  selectedThemeID = Settings.global['themeId'] || null;

  settings = Settings.global;
  baseURL;

  constructor(private themeService: ThemeService, private router: Router, private SettingsService: SettingsService) {
    this.baseURL = environment.nodeUrl;
  }

  ngOnInit() {
    this.themeService.getThemeList().subscribe((res) => {
      this.themes = res;
      if (this.themes.length <= 0) {
        this.selectedTheme = {};
        this.saveAndApply();
      }
    });

    Settings.refreshSettings.subscribe((res) => {
      this.themeService.getThemeList().subscribe((res) => {
        this.themes = res;
        if (this.themes.length <= 0) {
          this.selectedTheme = {};
          this.saveAndApply();
        }
      });
    });
  }

  getColorClass(i, length) {
    if (length % 2 == 0) {
      return 'half-width';
    } else if (length % 3 == 0) {
      return 'thrd-width';
    } else {
      if (i < 3) {
        return 'thrd-width';
      } else {
        return 'qtr-width';
      }
    }
  }

  download(id) {
    const fileLink = environment.nodeUrl + '/files/file-view/' + id;
    window.location.href = fileLink;
  }

  editTheme(theme) {
    this.router.navigate(['/admin', { outlets: { adminPageRoute: ['theme-create', theme['_id']] } }]);
  }

  deleteTheme(theme) {
    let ref = RootAppComponent.dialog.open(ConfirmDialogComponent);
    ref.componentInstance.onConfirm.subscribe((res) => {
      if (res) {
        this.themeService.deleteTheme(theme._id).subscribe((res) => {
          ref.close();
          this.themes = this.themes.filter((item) => {
            return theme._id != item._id;
          });
          if (res['success']) {
            if (this.themes.length <= 0) {
              this.selectedTheme = {};
              this.saveAndApply();
            }
            NotifyAppComponent.displayToast('success', 'Successful operation', 'Theme has been successfully removed');
          }
        });
      }
    });
  }

  apply(theme) {
    this.selectedTheme = theme;
    this.selectedThemeID = theme['_id'];
    Settings.applyTheme(theme.cssFileId);
    Settings.global['BrandingBackgroundImageMerchant'] = theme['backgroundId'];

    Settings.global['BrandingBackgroundImageConsumer'] = theme['backgroundConsumerId'] || theme['backgroundId'];
    Settings.global['BrandingBackgroundImageSupplier'] = theme['backgroundSupplierId'] || theme['backgroundId'];
    Settings.global['BrandingBackgroundImageFunder'] = theme['backgroundFunderId'] || theme['backgroundId'];
    Settings.global['BrandingBackgroundImagePromoter'] = theme['backgroundPromoterId'] || theme['backgroundId'];

    Settings.global['BrandingLogoImage'] = theme['logoId'];
    Settings.global['BrandingIcon'] = theme['iconId'];
    Settings.global['chartColors'] = theme['chartColors'];
    Settings.global['characterWarningId'] = theme['characterWarningId'];
    Settings.global['characterErrorId'] = theme['characterErrorId'];
    Settings.global['characterCommenceApplicationId'] = theme['characterCommenceApplicationId'];
    Settings.global['characterPairId'] = theme['characterPairId'];
    Settings.global['characterPaymentScheduleId'] = theme['characterPaymentScheduleId'];
    Settings.global['characterSecrectId'] = theme['characterSecrectId'];
    Settings.global['characterClipboardId'] = theme['characterClipboardId'];
    Settings.global['characterClipboardThumbsupId'] = theme['characterClipboardThumbsupId'];
    Settings.global['characterThumbsupId'] = theme['characterThumbsupId'];
    Settings.global['characterIntroId'] = theme['characterIntroId'];
    Settings.global['loadingGifId'] = theme['loadingGifId'];
    Settings.global['userDataLoadingId'] = theme['userDataLoadingId'];
    Settings.global['themeColors'] = theme['scssColors'];

    if (theme && theme.scssColors) {
      let primaryColor;
      let accentColor;

      for (var i = 0; i < theme.scssColors.length; i++) {
        let item = theme.scssColors[i];
        if (item) {
          if (item && item['name'] && item['name'] === 'v1' && item['color']) {
            primaryColor = item['color'];
          }
          if (item && item['name'] && item['name'] === 'v2' && item['color']) {
            accentColor = item['color'];
          }
        }
      }
      if (accentColor && primaryColor) {
        Settings.global['primaryColor'] = primaryColor;
        Settings.global['accentColor'] = accentColor;
      }
    }

    Settings.applyImages();
  }

  getFileLink(fileID) {
    return environment.nodeUrl + '/files/file-view/' + fileID;
  }

  saveAndApplyTheme(theme) {
    if (theme) {
      if (theme && theme.scssColors) {
        let primaryColor;
        let accentColor;

        for (var i = 0; i < theme.scssColors.length; i++) {
          let item = theme.scssColors[i];
          if (item) {
            if (item && item['name'] && item['name'] === 'v1' && item['color']) {
              primaryColor = item['color'];
            }
            if (item && item['name'] && item['name'] === 'v2' && item['color']) {
              accentColor = item['color'];
            }
          }
        }

        if (this.settings && this.settings['_id'] && accentColor && primaryColor) {
          let payload = {
            primaryColor: primaryColor,
            accentColor: accentColor,
          };
          this.themeService.updateSettings(this.settings['_id'], payload).subscribe((r) => {
            if (r) {
              Settings.global['primaryColor'] = primaryColor;
              Settings.global['accentColor'] = accentColor;
            }
          });
        }
      }

      this.apply(theme);
      this.saveAndApply();
    }
  }

  saveAndApply() {
    Settings.global['themeId'] = this.selectedTheme['_id'] || null;
    Settings.global['cssId'] = this.selectedTheme['cssFileId'] || null;
    Settings.global['BrandingBackgroundImageMerchant'] = this.selectedTheme['backgroundId'];

    Settings.global['BrandingBackgroundImageConsumer'] =
      this.selectedTheme['backgroundConsumerId'] || this.selectedTheme['backgroundId'];
    Settings.global['BrandingBackgroundImageSupplier'] =
      this.selectedTheme['backgroundSupplierId'] || this.selectedTheme['backgroundId'];
    Settings.global['BrandingBackgroundImageFunder'] =
      this.selectedTheme['backgroundFunderId'] || this.selectedTheme['backgroundId'];
    Settings.global['BrandingBackgroundImagePromoter'] =
      this.selectedTheme['backgroundPromoterId'] || this.selectedTheme['backgroundId'];

    Settings.global['BrandingLogoImage'] = this.selectedTheme['logoId'];
    Settings.global['BrandingIcon'] = this.selectedTheme['iconId'];
    Settings.global['chartColors'] = this.selectedTheme['chartColors'];

    Settings.global['characterWarningId'] = this.selectedTheme['characterWarningId'];
    Settings.global['characterErrorId'] = this.selectedTheme['characterErrorId'];
    Settings.global['characterCommenceApplicationId'] = this.selectedTheme['characterCommenceApplicationId'];
    Settings.global['characterPairId'] = this.selectedTheme['characterPairId'];
    Settings.global['characterPaymentScheduleId'] = this.selectedTheme['characterPaymentScheduleId'];
    Settings.global['characterSecrectId'] = this.selectedTheme['characterSecrectId'];
    Settings.global['characterClipboardId'] = this.selectedTheme['characterClipboardId'];
    Settings.global['characterClipboardThumbsupId'] = this.selectedTheme['characterClipboardThumbsupId'];
    Settings.global['characterThumbsupId'] = this.selectedTheme['characterThumbsupId'];
    Settings.global['characterIntroId'] = this.selectedTheme['characterIntroId'];
    Settings.global['loadingGifId'] = this.selectedTheme['loadingGifId'];
    Settings.global['userDataLoadingId'] = this.selectedTheme['userDataLoadingId'];
    Settings.global['themeColors'] = this.selectedTheme['scssColors'];

    this.SettingsService.getSettings().subscribe(
      (res1: any) => {
        if (res1) {
          let theme = {
            themeId: this.selectedTheme['_id'] || null,
            cssId: this.selectedTheme['cssFileId'] || null,
            BrandingBackgroundImageMerchant: this.selectedTheme['backgroundId'],

            BrandingBackgroundImageConsumer: this.selectedTheme['backgroundConsumerId'],
            BrandingBackgroundImageSupplier: this.selectedTheme['backgroundSupplierId'],
            BrandingBackgroundImageFunder: this.selectedTheme['backgroundFunderId'],
            BrandingBackgroundImagePromoter: this.selectedTheme['backgroundPromoterId'],

            BrandingLogoImage: this.selectedTheme['logoId'],
            chartColors: this.selectedTheme['chartColors'],
            BrandingIcon: this.selectedTheme['iconId'],
            characterWarningId: this.selectedTheme['characterWarningId'],
            characterErrorId: this.selectedTheme['characterErrorId'],
            characterCommenceApplicationId: this.selectedTheme['characterCommenceApplicationId'],
            characterPairId: this.selectedTheme['characterPairId'],
            characterPaymentScheduleId: this.selectedTheme['characterPaymentScheduleId'],
            characterSecrectId: this.selectedTheme['characterSecrectId'],
            characterClipboardId: this.selectedTheme['characterClipboardId'],
            characterClipboardThumbsupId: this.selectedTheme['characterClipboardThumbsupId'],
            characterThumbsupId: this.selectedTheme['characterThumbsupId'],
            characterIntroId: this.selectedTheme['characterIntroId'],
            loadingGifId: this.selectedTheme['loadingGifId'],
            userDataLoadingId: this.selectedTheme['userDataLoadingId'],
            themeColors: this.selectedTheme['scssColors'],
          };

          this.SettingsService.updateSettings(res1['_id'], theme).subscribe((res) => {
            if (res['success'] && Settings.global['cssId'] != null) {
              NotifyAppComponent.displayToast('success', 'Successful operation', 'Theme has been successfully applied');
            }
          });
        }
      },
      (err: any) => {}
    );
  }
}
