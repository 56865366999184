<div class="rel">
  <div class="card-header primary-gradient-img clearfix inModal">
    <div class="row clearfix">
      <!-- Modal dismiss button -->
      <button class="btn-close btn-clear mat-button white" matDialogClose>
        <mat-icon>clear</mat-icon>
      </button>
      <!-- merchant calculated name -->
      <div class="row titleArea clearfix">
        <i class="white icon-tooth-solid rm-mt pull-left"></i>
        <h2 class="summary-header rm-m">
          Select teeth
          <span class="subLabel">Click to select the teeth which will be targeted in this treatment</span>
        </h2>
      </div>
    </div>
  </div>

  <div class="row clearfix text-center">
    <button class="sel-teeth" (click)="deselectAll()" mat-button matTooltip="Deselect all teeth">
      <div class="toothMapIcon">
        <span class="icon icon-tooth-blank"></span>
      </div>
    </button>
    <button class="sel-teeth" (click)="selectAll()" mat-button matTooltip="Select whole mouth">
      <div class="toothMapIcon">
        <span class="icon icon-tooth-full"></span>
      </div>
    </button>
    <button class="sel-teeth" (click)="selectTop()" mat-button matTooltip="Select top row">
      <div class="toothMapIcon">
        <span class="icon icon-tooth-top">
          <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span
        ><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span
        ><span class="path9"></span><span class="path10"></span><span class="path11"></span
        ><span class="path12"></span><span class="path13"></span><span class="path14"></span
        ><span class="path15"></span><span class="path16"></span><span class="path17"></span
        ><span class="path18"></span><span class="path19"></span><span class="path20"></span
        ><span class="path21"></span><span class="path22"></span><span class="path23"></span
        ><span class="path24"></span><span class="path25"></span><span class="path26"></span
        ><span class="path27"></span><span class="path28"></span><span class="path29"></span
        ><span class="path30"></span><span class="path31"></span><span class="path32"></span
        ><span class="path33"></span><span class="path34"></span><span class="path35"></span
        ><span class="path36"></span>
        </span>
      </div>
    </button>
    <button class="sel-teeth" (click)="selectBottom()" mat-button matTooltip="Select bottom row">
      <div class="toothMapIcon">
        <span class="icon icon-tooth-bottom"
        ><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span
        ><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span
        ><span class="path9"></span><span class="path10"></span><span class="path11"></span
        ><span class="path12"></span><span class="path13"></span><span class="path14"></span
        ><span class="path15"></span><span class="path16"></span><span class="path17"></span
        ><span class="path18"></span><span class="path19"></span><span class="path20"></span
        ><span class="path21"></span><span class="path22"></span><span class="path23"></span
        ><span class="path24"></span><span class="path25"></span><span class="path26"></span
        ><span class="path27"></span><span class="path28"></span><span class="path29"></span
        ><span class="path30"></span><span class="path31"></span><span class="path32"></span
        ><span class="path33"></span><span class="path34"></span><span class="path35"></span
        ><span class="path36"></span
        ></span>
      </div>
    </button>
    <button class="sel-teeth" (click)="selectQ1()" mat-button matTooltip="Select First Quadrant">
      <div class="toothMapIcon">
        <span class="icon icon-tooth-q1"
        ><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span
        ><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span
        ><span class="path9"></span><span class="path10"></span><span class="path11"></span
        ><span class="path12"></span><span class="path13"></span><span class="path14"></span
        ><span class="path15"></span><span class="path16"></span><span class="path17"></span
        ><span class="path18"></span><span class="path19"></span><span class="path20"></span
        ><span class="path21"></span><span class="path22"></span><span class="path23"></span
        ><span class="path24"></span><span class="path25"></span><span class="path26"></span
        ><span class="path27"></span><span class="path28"></span><span class="path29"></span
        ><span class="path30"></span><span class="path31"></span><span class="path32"></span
        ><span class="path33"></span><span class="path34"></span><span class="path35"></span
        ><span class="path36"></span
        ></span>
      </div>
    </button>
    <button class="sel-teeth" (click)="selectQ2()" mat-button matTooltip="Select Second Quadrant">
      <div class="toothMapIcon">
        <span class="icon icon-tooth-q2"
        ><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span
        ><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span
        ><span class="path9"></span><span class="path10"></span><span class="path11"></span
        ><span class="path12"></span><span class="path13"></span><span class="path14"></span
        ><span class="path15"></span><span class="path16"></span><span class="path17"></span
        ><span class="path18"></span><span class="path19"></span><span class="path20"></span
        ><span class="path21"></span><span class="path22"></span><span class="path23"></span
        ><span class="path24"></span><span class="path25"></span><span class="path26"></span
        ><span class="path27"></span><span class="path28"></span><span class="path29"></span
        ><span class="path30"></span><span class="path31"></span><span class="path32"></span
        ><span class="path33"></span><span class="path34"></span><span class="path35"></span
        ><span class="path36"></span
        ></span>
      </div>
    </button>
    <button class="sel-teeth" (click)="selectQ3()" mat-button matTooltip="Select Third Quadrant">
      <div class="toothMapIcon">
        <span class="icon icon-tooth-q3"
        ><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span
        ><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span
        ><span class="path9"></span><span class="path10"></span><span class="path11"></span
        ><span class="path12"></span><span class="path13"></span><span class="path14"></span
        ><span class="path15"></span><span class="path16"></span><span class="path17"></span
        ><span class="path18"></span><span class="path19"></span><span class="path20"></span
        ><span class="path21"></span><span class="path22"></span><span class="path23"></span
        ><span class="path24"></span><span class="path25"></span><span class="path26"></span
        ><span class="path27"></span><span class="path28"></span><span class="path29"></span
        ><span class="path30"></span><span class="path31"></span><span class="path32"></span
        ><span class="path33"></span><span class="path34"></span><span class="path35"></span
        ><span class="path36"></span
        ></span>
      </div>
    </button>
    <button class="sel-teeth" (click)="selectQ4()" mat-button matTooltip="Select Fourth Quadrant">
      <div class="toothMapIcon">
        <span class="icon icon-tooth-q4"
        ><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span
        ><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span
        ><span class="path9"></span><span class="path10"></span><span class="path11"></span
        ><span class="path12"></span><span class="path13"></span><span class="path14"></span
        ><span class="path15"></span><span class="path16"></span><span class="path17"></span
        ><span class="path18"></span><span class="path19"></span><span class="path20"></span
        ><span class="path21"></span><span class="path22"></span><span class="path23"></span
        ><span class="path24"></span><span class="path25"></span><span class="path26"></span
        ><span class="path27"></span><span class="path28"></span><span class="path29"></span
        ><span class="path30"></span><span class="path31"></span><span class="path32"></span
        ><span class="path33"></span><span class="path34"></span><span class="path35"></span
        ><span class="path36"></span
        ></span>
      </div>
    </button>
  </div>

  <hr/>
  <div class="row clearfix text-center">
    <!-- <div class="row clearfix"> -->
    <!-- <button mat-raised-button>
        <mat-icon>face</mat-icon> Adult</button>
      <button mat-raised-button>
        <mat-icon>child_care</mat-icon> Child</button> -->
    <!-- <mat-radio-group [(ngModel)]="isAdult" (ngModelChange)="change()">
        <mat-radio-button [value]=true>
          <mat-icon>face</mat-icon>Adult</mat-radio-button>
        <mat-radio-button [value]=false>
          <mat-icon>child_care</mat-icon>Child</mat-radio-button>
      </mat-radio-group>
    </div> -->

    <div class="clearfix teeth-button-array">
      <!--  BUTTON ARRAY -->
      <div class="row">
        <mat-button-toggle *ngFor="let tooth of teethTop" val="18">
          <app-tooth-edit [tooth]="tooth" [isAdult]="isAdult" (select)="selectTooth($event)"></app-tooth-edit>
        </mat-button-toggle>
      </div>
      <div class="row">
        <mat-button-toggle *ngFor="let tooth of teethBottom" val="48">
          <app-tooth-edit [tooth]="tooth" [isAdult]="isAdult" (select)="selectTooth($event)"></app-tooth-edit>
        </mat-button-toggle>
      </div>
    </div>
    <div class="clearfix"></div>

    <div class="row clearfix text-center">
      <button mat-raised-button color="primary" matDialogClose>Okay</button>
    </div>
  </div>
</div>
