import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSortModule } from '@angular/material/sort';
import { SharedModule } from '../../shared/shared.module';
import { ContactModule } from '../contact/contact.module';
import { ExistingPatientModule } from '../existing-patient/existing-patient.module';
import { MerchantModule } from '../merchant/merchant.module';
import { TestimonyRoutingModule } from './shared/testimony-routing.module';
import { TestimonyService } from './shared/testimony.service';
import { TestimonyCreateEditComponent } from './testimony-create-edit/testimony-create-edit.component';
import { TestimonyListComponent } from './testimony-list/testimony-list.component';
import { TestimonyViewComponent } from './testimony-view/testimony-view.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MerchantModule,
    ExistingPatientModule,
    ReactiveFormsModule,
    CdkTableModule,
    TestimonyRoutingModule,
    SharedModule,
    ContactModule,

    MatSortModule,
  ],
  declarations: [TestimonyCreateEditComponent, TestimonyListComponent, TestimonyViewComponent],
  exports: [TestimonyCreateEditComponent, TestimonyListComponent, TestimonyViewComponent],
  providers: [TestimonyService],
})
export class TestimonyModule {}
