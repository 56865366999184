import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatSlideToggleChange } from '@angular/material';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { MerchantTradingHoursOverviewComponent } from '../../merchant/merchant-trading-hours-overview/merchant-trading-hours-overview.component';
import { DentistService } from '../shared/dentist.service';

export class DentistWorkingHoursOverviewComponentDialogData {
  dentistID?: string;
  dentist?: any;
  workingHours?: any;
  isEdit?: boolean;
}

@Component({
  selector: 'app-dentist-working-hours-overview',
  templateUrl: './dentist-working-hours-overview.component.html',
  styleUrls: ['./dentist-working-hours-overview.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.animate', style({ opacity: 0 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.15s ease-in-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-10%)', offset: 0, height: 0 }),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.animate', style({ opacity: 1 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.15s ease-in-out',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(5%)', offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class DentistWorkingHoursOverviewComponent implements OnInit, OnChanges {
  @Input() dentistID: string = null;
  @Input() dentist: any = null;
  @Input() workingHours: any[] = null;
  @Input() isEdit = false;
  @Input() isModal = false;
  @Input() displayHighlight = false;
  @Input() displayBusinessHours = true;

  @Output() close = new EventEmitter();

  isPromoterOrAdmin = false;
  sessionType = 'guest';
  merchantTimezone;

  util = new UtilsClass();

  constructor(
    private authenticationService: AuthenticationService,
    private dentistService: DentistService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: DentistWorkingHoursOverviewComponentDialogData
  ) {
    if (data) {
      this.isModal = true;

      if (data.dentist) {
        this.dentist = data.dentist;
      }

      if (data.dentistID) {
        this.dentistID = data.dentistID;
      }

      if (data.workingHours) {
        this.updateWorkingHours(data.workingHours);
      }

      this.isEdit = data.isEdit === true;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {}

  ngOnInit() {
    this.authenticationService.isPromoterOrAdmin().subscribe((isPromoterOrAdmin) => {
      this.isPromoterOrAdmin = isPromoterOrAdmin;

      this.authenticationService.getSessionType().subscribe((sessionType) => {
        this.sessionType = sessionType;

        if (this.dentist && this.dentist.ID) {
          if (this.dentist['TimeZoneCode']) {
            this.merchantTimezone = this.dentist['TimeZoneCode'];
          } else if (this.workingHours) {
            this.merchantTimezone = this.workingHours[0]['TimeZone'];
          }
          if (this.workingHours === null) {
            this.fetchTradingHourList(this.dentistID, isPromoterOrAdmin);
          }
        } else if (this.dentistID) {
          if (this.dentistID) {
            this.dentistService.getContactDetails(this.dentistID, {}, sessionType).subscribe((res) => {
              this.dentist = res;

              if (this.dentist && this.dentist.ID) {
                if (this.dentist['TimeZoneCode']) {
                  this.merchantTimezone = this.dentist['TimeZoneCode'];
                } else if (this.workingHours) {
                  this.merchantTimezone = this.workingHours[0]['TimeZone'];
                }
                if (this.workingHours === null) {
                  this.fetchTradingHourList(this.dentistID, isPromoterOrAdmin);
                }
              }
            });
          }
        } else {
          this.authenticationService.getCurrentUser().subscribe((user) => {
            if (user && user.data && user.data.ID) {
              this.dentistID = user.data.ID;
              this.dentist = user.data;

              if (this.dentist && this.dentist.ID) {
                if (this.dentist['TimeZoneCode']) {
                  this.merchantTimezone = this.dentist['TimeZoneCode'];
                } else if (this.workingHours) {
                  this.merchantTimezone = this.workingHours[0]['TimeZone'];
                }
                if (this.workingHours === null) {
                  this.fetchTradingHourList(this.dentistID, isPromoterOrAdmin);
                }
              }
            }
          });
        }
      });
    });
  }

  closeEvent() {
    this.close.emit(true);
  }

  onToggleCanAccept(change: MatSlideToggleChange): void {
    const payload = {
      canAcceptAppointment: change.checked,
    };
    this.dentistService.modifyContact(this.dentistID, payload, this.sessionType).subscribe((res) => {
      // console.log(res);
      this.dentist = res;
    });
  }

  onColorPickerClose(color: string): void {
    const payload = {
      appointmentColor: color,
    };
    this.dentistService.modifyContact(this.dentistID, payload, this.sessionType).subscribe((res) => {
      // console.log(res);
      this.dentist = res;
    });
  }

  onEdit(): void {
    if (this.dentist) {
      const ref = RootAppComponent.dialog.open(MerchantTradingHoursOverviewComponent, {
        data: {
          merchantID: this.dentist.ContactForCard_key,
          dentistID: this.dentist.ID,
          isMerchant: false,
          isDentist: false,
        },
        width: '800px',
      });

      ref.componentInstance.close.subscribe((res) => {
        if (res == true) {
          ref.close();
        }
      });
      ref.componentInstance.getTradingHours.subscribe((res) => {
        if (res) {
          this.updateSpecificWorkingHour(res);
        }
      });
    }
  }

  private fetchContactDetails(dentistID: string, sessionType: any): void {
    if (dentistID) {
      this.dentistService.getContactDetails(dentistID, {}, sessionType).subscribe((res) => {
        this.dentist = res;
      });
    }
  }

  private fetchTradingHourList(dentistID: string, isPromoterOrAdmin: boolean): void {
    if (dentistID) {
      const payload = {
        dentistID,
      };

      this.dentistService.getTradingHourList(payload, isPromoterOrAdmin).subscribe((res) => {
        this.updateWorkingHours(res);
      });
    }
  }

  private updateWorkingHours(workingHours: any[]): void {
    if (workingHours && workingHours.length > 0) {
      const order = {
        Monday: 1,
        Tuesday: 2,
        Wednesday: 3,
        Thursday: 4,
        Friday: 5,
        Saturday: 6,
        Sunday: 7,
      };
      this.workingHours = workingHours.sort(function (a, b) {
        return order[a['DayOfWeek.Label.Singular']] - order[b['DayOfWeek.Label.Singular']];
      });
    }
  }

  updateSpecificWorkingHour(workingHour) {
    console.log(workingHour);
    this.workingHours = this.workingHours.map((it) => {
      if (it.ID === workingHour.ID) {
        return workingHour;
      } else {
        return it;
      }
    });
  }
}
