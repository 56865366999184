import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TestimonyCreateEditComponent } from '../testimony-create-edit/testimony-create-edit.component';
import { TestimonyListComponent } from '../testimony-list/testimony-list.component';
import { TestimonyViewComponent } from '../testimony-view/testimony-view.component';

const routes: Routes = [
  {
    path: 'testimony-create-edit',
    component: TestimonyCreateEditComponent,
  },
  {
    path: 'testimony-list',
    component: TestimonyListComponent,
  },
  {
    path: 'testimony-view',
    component: TestimonyViewComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TestimonyRoutingModule {}
