/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./change-social-media.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../social-media-input/social-media-input.component.ngfactory";
import * as i3 from "../social-media-input/social-media-input.component";
import * as i4 from "angularx-social-login";
import * as i5 from "../shared/authentication.service";
import * as i6 from "../../../shared/services/calendar.service";
import * as i7 from "../../../shared/services/calendar-share.service";
import * as i8 from "./change-social-media.component";
var styles_ChangeSocialMediaComponent = [i0.styles];
var RenderType_ChangeSocialMediaComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ChangeSocialMediaComponent, data: { "animation": [{ type: 7, name: "simpleFadeAnimation", definitions: [{ type: 0, name: "in", styles: { type: 6, styles: { opacity: 1, height: "*" }, offset: null }, options: undefined }, { type: 1, expr: ":enter", animation: [{ type: 6, styles: { opacity: 0, height: 0 }, offset: null }, { type: 4, styles: null, timings: 300 }], options: null }, { type: 1, expr: ":leave", animation: { type: 4, styles: { type: 6, styles: { opacity: 0, height: 0 }, offset: null }, timings: 300 }, options: null }], options: {} }] } });
export { RenderType_ChangeSocialMediaComponent as RenderType_ChangeSocialMediaComponent };
export function View_ChangeSocialMediaComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-social-media-input", [["class", "clearFix"]], null, [[null, "getRemove"], [null, "getResult"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("getRemove" === en)) {
        var pd_0 = (_co.getRemoveEvent($event) !== false);
        ad = (pd_0 && ad);
    } if (("getResult" === en)) {
        var pd_1 = (_co.getResultSocialMedia($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_SocialMediaInputComponent_0, i2.RenderType_SocialMediaInputComponent)), i1.ɵdid(1, 114688, null, 0, i3.SocialMediaInputComponent, [i4.AuthService, i5.AuthenticationService, i6.CalendarService, i7.CalendarShareService], { buttonStyle: [0, "buttonStyle"], facebookStatus: [1, "facebookStatus"], googleStatus: [2, "googleStatus"], facebookStatusLabel: [3, "facebookStatusLabel"], googleStatusLabel: [4, "googleStatusLabel"], facebookLabel: [5, "facebookLabel"], googleLabel: [6, "googleLabel"], facebookButtonLabel: [7, "facebookButtonLabel"], googleButtonLabel: [8, "googleButtonLabel"], facebookDate: [9, "facebookDate"], googleDate: [10, "googleDate"], directLogin: [11, "directLogin"], canRemove: [12, "canRemove"] }, { getResult: "getResult", getRemove: "getRemove" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "buttons"; var currVal_1 = _co.facebookStatus; var currVal_2 = _co.googleStatus; var currVal_3 = _co.facebookStatusLabel; var currVal_4 = _co.googleStatusLabel; var currVal_5 = _co.facebookLabel; var currVal_6 = _co.googleLabel; var currVal_7 = _co.facebookButtonLabel; var currVal_8 = _co.googleButtonLabel; var currVal_9 = _co.facebookDate; var currVal_10 = _co.googleDate; var currVal_11 = _co.directLogin; var currVal_12 = true; _ck(_v, 1, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12]); }, null); }
export function View_ChangeSocialMediaComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-change-social-media", [], null, null, null, View_ChangeSocialMediaComponent_0, RenderType_ChangeSocialMediaComponent)), i1.ɵdid(1, 114688, null, 0, i8.ChangeSocialMediaComponent, [i5.AuthenticationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ChangeSocialMediaComponentNgFactory = i1.ɵccf("app-change-social-media", i8.ChangeSocialMediaComponent, View_ChangeSocialMediaComponent_Host_0, {}, {}, []);
export { ChangeSocialMediaComponentNgFactory as ChangeSocialMediaComponentNgFactory };
