export class ConfirmDialogSingleButton {
  confirmMessage: string;
  buttonMessage: string;
  title: string;
  icon: string;
  displayDisplay;
  color;

  constructor(icon, title, confirmMessage, buttonMessage, displayDisplay = false, color = null) {
    this.icon = icon;
    this.title = title;
    this.confirmMessage = confirmMessage;
    this.buttonMessage = buttonMessage;
    this.displayDisplay = displayDisplay;

    this.color = color;
  }
}
