import { Merchant } from '../../feature/merchant/shared/types/merchant.type';
import { SessionType } from '../../shared/types/session-type.type';
import { ModuleOptions, PublicSettings } from '../../shared/types/settings';
import { User } from '../authentication/shared/shared/types/authentication.type';

export const initialPractice: Partial<Merchant> = null;

export const initialUser: User = null;

export const initialSessionType: SessionType = 'guest';

export const initialIsPromoterOrAdmin = false;

export const initialDentist = null;

export const initialPublicSettings: PublicSettings = null;

export const initialPublicModules: ModuleOptions = null;
