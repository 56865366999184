<div class="rel lookup">
  <div *ngIf="!hideFilter">
    <div class="clearfix">
      <div class="card-header primary-gradient-img clearfix inModal">
        <div class="row clearfix">
          <button
            class="btn-close btn-clear mat-button white"
            *ngIf="isModal == true"
            (click)="closeEvent()"
            tabIndex="-1"
          >
            <mat-icon>clear</mat-icon>
          </button>
          <div class="row titleArea clearfix">
            <mat-icon class="pull-left patient-main-icon">account_circle</mat-icon>
            <h2 class="summary-header rm-m">
              <!-- {{ beneficiary.CalculatedName}} -->
              Operator Lookup
              <span class="subLabel noCap"> You can search for operators using any (or all) of the field below: </span>
            </h2>
          </div>
        </div>
      </div>

      <div class="clearfix full-width previous-search" *ngIf="operators && operators.length > 0">
        <button class="clearfix pull-right" (click)="goForward()" mat-raised-button color="primary">
          {{ operators.length }} Operator(s)
          <mat-icon>keyboard_arrow_right</mat-icon>
        </button>
        <p class="rm-m mr line-height">View the result of your previous search</p>
      </div>

      <hr class="mb" *ngIf="operators && operators.length > 0"/>

      <form class="lookup-form mt" #lookupForm="ngForm">
        <mat-form-field class="full-width mt" appearance="outline">
          <mat-label>Operator Type</mat-label>
          <mat-select
            class=""
            [(ngModel)]="type"
            (ngModelChange)="changeType()"
            name="operatorType"
            placeholder="Operator Type"
            tabIndex="1"
            required
          >
            <mat-option *ngFor="let a of operatorTypes" [value]="a.value">
              {{ a.text }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="full-width" *ngIf="displayMerchantList === true" appearance="outline">
          <mat-label>Merchant list</mat-label>
          <mat-select class="" [(ngModel)]="contactForID" name="Merchant" placeholder="Merchant list" tabIndex="2">
            <mat-option [value]="null">
              All
            </mat-option>
            <mat-option *ngFor="let a of merchants" [value]="a.ID">
              <app-operator-merchant-view-picture
                class="operator-lookup-profile mr"
                [hideZoomButton]="true"
                [merchantID]="a.ID"
              >
              </app-operator-merchant-view-picture>
              <span class="trader-name">{{ a.TradingAs }}</span>
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="full-width" *ngIf="displaySupplierList === true" appearance="outline">
          <mat-label>Supplier list</mat-label>
          <mat-select class="" [(ngModel)]="contactForID" name="Supplier" placeholder="Supplier list" tabIndex="2">
            <mat-option [value]="null">
              All
            </mat-option>
            <mat-option *ngFor="let a of suppliers" [value]="a.ID">
              <app-operator-beneficiary-view-picture
                class="operator-lookup-profile mr"
                [hideZoomButton]="true"
                [beneficiaryID]="a.ID"
              >
              </app-operator-beneficiary-view-picture>
              <span class="trader-name">{{ a.TradingAs }}</span>
            </mat-option>
          </mat-select>
        </mat-form-field>
        <hr/>
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Name</mat-label>
          <input [(ngModel)]="name" matInput name="Profilename" placeholder="Name" tabIndex="4"/>
        </mat-form-field>

        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Username</mat-label>
          <input [(ngModel)]="username" matInput name="usename" placeholder="Username" tabIndex="8"/>
        </mat-form-field>

        <hr/>
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Trading as</mat-label>
          <input [(ngModel)]="tradingAs" matInput name="tradingAs" placeholder="Trading as" tabIndex="3"/>
        </mat-form-field>

        <div class="full-width clearfix flex">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Mobile</mat-label>
            <input [(ngModel)]="mobile" matInput name="mobile" placeholder="Mobile" tabIndex="5"/>
          </mat-form-field>

          <mat-form-field class="full-width ml" appearance="outline">
            <mat-label>Phone</mat-label>
            <input [(ngModel)]="phone" matInput name="phone" placeholder="Phone" tabIndex="6"/>
          </mat-form-field>
        </div>

        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Email</mat-label>
          <input
            [(ngModel)]="email"
            matInput
            name="email"
            type="email"
            tabIndex="7"
            pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
            placeholder="Email"
          />
        </mat-form-field>

        <br />
        <br />
        <div class="drop-buttons">
          <button
            class="inline btn-new-invite btn-large"
            [disabled]="
              !lookupForm.form.valid &&
              !name &&
              !email &&
              !mobile &&
              !phone &&
              !tradingAs &&
              !calculatedName &&
              !username &&
              !contactForID
            "
            (click)="getOperators()"
            mat-raised-button
            color="accent"
            tabIndex="6"
          >
            Search
          </button>
        </div>
      </form>
    </div>
  </div>

  <div *ngIf="hideFilter">
    <div class="card-header primary-gradient-img clearfix inModal">
      <div class="row clearfix">
        <button class="btn-close btn-clear mat-button white" *ngIf="isModal == true" (click)="closeEvent()">
          <mat-icon>clear</mat-icon>
        </button>
        <div class="row titleArea clearfix">
          <mat-icon class="pull-left">account_circle</mat-icon>
          <h2 class="summary-header rm-m">
            <!-- {{ beneficiary.CalculatedName}} -->
            Possible Match
            <span class="subLabel">
              You detected a possible matching operator records in our database. <br/>Please confirm if any of the
              matches bellow are are the same records as the one you are entering.
            </span>
          </h2>
        </div>
      </div>
    </div>

    <h1 class="sr-title sm-h1 rm-mt clearfix">
      Operators

      <span
        class="badge master"
        *ngIf="operators"
        [endVal]="
          (operators
            | FilterArrayMultipleOrContain: ['CalculatedName', 'UserName', 'UltimateTableName']:filterData
            | lengthOfArray) || 0
        "
        countUp
      ></span>
    </h1>
    <div class="clearfix flex flex-center">
      <mat-form-field class="full-width" *ngIf="operators && operators.length > 3" appearance="outline">
        <mat-label>Search results by Name, email or phone number</mat-label>
        <input [(ngModel)]="filterData" matInput name="search" tabIndex="1"/>
      </mat-form-field>

      <button
        class="actionMenuToggle ml mr"
        *ngIf="operators && operators.length > 3"
        [matMenuTriggerFor]="menu"
        mat-icon-button
      >
        <mat-icon class="fas fa-sort"></mat-icon>
      </button>

      <mat-menu #menu="matMenu">
        <button
          [ngClass]="{ 'accent-color': orderByFilter === 'CalculatedName' || orderByFilter === '-CalculatedName' }"
          (click)="orderBy('CalculatedName')"
          mat-menu-item
        >
          <span>Name</span>
          <span *ngIf="orderByFilter === 'CalculatedName' || orderByFilter === '-CalculatedName'">
            <mat-icon class="accent-color" *ngIf="orderByFilter === '-CalculatedName'">arrow_drop_up</mat-icon>
            <mat-icon class="accent-color" *ngIf="orderByFilter !== '-CalculatedName'">arrow_drop_down</mat-icon>
          </span>
        </button>

        <button
          [ngClass]="{ 'accent-color': orderByFilter === 'DateTimeCreated' || orderByFilter === '-DateTimeCreated' }"
          (click)="orderBy('DateTimeCreated')"
          mat-menu-item
        >
          <span>Join Date</span>
          <span *ngIf="orderByFilter === 'DateTimeCreated' || orderByFilter === '-DateTimeCreated'">
            <mat-icon class="accent-color" *ngIf="orderByFilter === '-DateTimeCreated'">arrow_drop_down</mat-icon>
            <mat-icon class="accent-color" *ngIf="orderByFilter !== '-DateTimeCreated'"> arrow_drop_up</mat-icon>
          </span>
        </button>
      </mat-menu>
    </div>

    <div class="patientContainer">
      <div
        class="patientRecord row clearfix"
        *ngFor="
          let operator of operators
            | FilterArrayMultipleOrContain: ['CalculatedName', 'UserName', 'UltimateTableName']:filterData
            | OrderArrayObject: [orderByFilter]
            | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage }
        "
        [ngClass]="{ activeExistingPatient: selectedOperator == operator }"
      >
        <div class="pull-right upper-side">
          <button
            class="position"
            *ngIf="displayOnClick == false && loginAsUserOnClick == false"
            (click)="openQuickViewDialog(operator)"
            mat-button
            mat-icon-button

          >
            <mat-icon>visibility</mat-icon>
          </button>

          <button [matMenuTriggerFor]="menu" mat-icon-button>
            <mat-icon>more_vert</mat-icon>
          </button>

          <mat-menu #menu="matMenu">
            <button (click)="openQuickViewDialog(operator)" mat-menu-item>
              <mat-icon>visibility</mat-icon>
              <span>Quick view</span>
            </button>

            <hr/>
            <button (click)="viewProfile(operator)" mat-menu-item>
              <mat-icon>person</mat-icon>
              <span>View Profile</span>
            </button>

            <button (click)="viewSession(operator)" mat-menu-item>
              <mat-icon>description</mat-icon>
              <span>View Session</span>
            </button>
          </mat-menu>
        </div>

        <div
          class="flex truncate"
          (click)="SelectRecordClickEvent(operator)"
          matTooltip="Click to choose {{ operator['CalculatedName'] }}"
        >
          <div class="profile-result full-width mb">
            <app-operator-view-picture class="pull-left patientIcon operatorLookup mr" [operatorID]="operator['ID']">
            </app-operator-view-picture>
            <div class="pull-left">
              <h3 class="sr-title rm-m">{{ operator.CalculatedName }}</h3>

              <p class="rm-m" matLine><strong>Username:</strong> {{ operator['UserName'] }}</p>

              <p class="rm-m" *ngIf="operator['UltimateTableName']" matLine>
                <!-- <mat-icon class="fas fa-mobile-alt small-icon"></mat-icon> -->
                <strong>Type:</strong> {{ operator.UltimateTableName }}
              </p>

              <p class="small" matLine>
                <span
                  class="chip-enable"
                  *ngIf="operator.DateTimeCreated"
                  matTooltip="{{ operator.DateTimeCreated | customDate }}"
                >
                  {{ operator.DateTimeCreated | TimeLabelPast }} ago
                </span>

                <span
                  *ngIf="operator.Enabled"
                  [ngClass]="{
                    'chip-enable': operator.Enabled == '1',
                    'chip-disable': operator.Enabled != '1'
                  }"
                >{{ operator.Enabled == '1' ? 'enabled' : 'disabled' }}
                </span>

                <span class="chip-enable" *ngIf="operator.IsAdministrator == '1'"> Admin </span>
              </p>
            </div>
          </div>
        </div>
        <mat-divider></mat-divider>
      </div>

      <div
        class="full-width"
        *ngIf="
          (operators
            | FilterArrayMultipleOrContain: ['CalculatedName', 'UserName', 'UltimateTableName']:filterData
            | lengthOfArray) <= 0
        "
      >
        <app-empty-list-message [message]="'No Record found'"></app-empty-list-message>
      </div>
    </div>

    <div
      class="full-width text-center page-container mt flex mb"
      *ngIf="
        (operators
          | FilterArrayMultipleOrContain: ['CalculatedName', 'UserName', 'UltimateTableName']:filterData
          | lengthOfArray) > 10
      "
    >
      <pagination-controls class="full-width clearfix" (pageChange)="changePage($event)" responsive="true">
      </pagination-controls>
    </div>

    <div class="drop-button text-center clearfix full-width">
      <ng-container *ngIf="hideFilter == true">
        <button
          class="inline btn-new-invite selectExistingPatient pull-left"
          (click)="goBack()"
          mat-raised-button
          color="accent"
        >
          <mat-icon>keyboard_arrow_left</mat-icon> Back
        </button>
      </ng-container>

      <button
        class="inline btn-new-invite selectExistingPatient pull-right"
        (click)="closeEvent()"
        mat-raised-button
        color="primary"
      >
        Close
      </button>
    </div>
  </div>
</div>
