import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { DocumentUrlViewComponent } from '../../../shared/components/document-url-view/document-url-view.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { UtilsService } from '../../../shared/services/utils.service';
import { Settings } from '../../../shared/types/settings';

@Component({
  selector: 'app-invitation-settings',
  templateUrl: './invitation-settings.component.html',
  styleUrls: ['./invitation-settings.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.tab-content-inner', style({ opacity: 0, height: 0 }), { optional: true }),
        query(
          '.tab-content-inner',
          stagger('250ms', [
            animate(
              '0.2s ease-in',
              keyframes([
                style({ opacity: 0, height: 0, offset: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, height: '*', offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.tab-content-inner', style({ opacity: 1, height: '*' }), { optional: true }),
        query(
          '.tab-content-inner',
          stagger('250ms', [
            animate(
              '0.2s ease-in',
              keyframes([
                style({ opacity: 1, height: '*', offset: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, height: 0, offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class InvitationSettingsComponent implements OnInit {
  @Input()
  merchantID;

  @Input()
  hideFilter = false;

  isModuleDiscountsActive = Settings.global['isModuleDiscountsActive'];
  isModuleTreatmentTemplateActive = Settings.global['isModuleTreatmentTemplateActive'];
  isModuleNoteFeaturesActive = Settings.global['isModuleNoteFeaturesActive'];
  isModuleMerchantInformedConsentActive = Settings.global['isModuleMerchantInformedConsentActive'];

  isAdminOrPromoter = false;

  msgList = [];

  settings = 'cashDiscount';
  personalisation = [
    {
      id: 'Title/Salutation',
      value: '{{Salutation}}',
    },
    {
      id: 'Last Name',
      value: '{{LastName}}',
    },
    {
      id: 'Dentist Name',
      value: '{{DentistName}}',
    },
    {
      id: 'Practice Name',
      value: '{{PracticeName}}',
    },
    {
      id: 'Practice Phone Number',
      value: '{{PracticePhoneNumber}}',
    },
    {
      id: 'Practice Address',
      value: '{{PracticeAddress}}',
    },
  ];

  myMsg: any = {};

  selectedMessage = {
    label: '',
    content: '',
  };

  purposeCodes = 'MerInv,MerInv_Mem,MerInv_Chk,MarCust,MarMer,MarMerPros,Contacts,MerSMS,MerPUInv';

  constructor(
    private authenticationService: AuthenticationService,
    private activeRoute: ActivatedRoute,
    private utilService: UtilsService
  ) {}

  ngOnInit() {
    this.utilService.getCurrentAccess().subscribe((access) => {
      if (access) {
        this.isModuleDiscountsActive = access['isModuleDiscountsActive'];
        this.isModuleTreatmentTemplateActive = access['isModuleTreatmentTemplateActive'];
        this.isModuleNoteFeaturesActive = access['isModuleNoteFeaturesActive'];
        this.isModuleMerchantInformedConsentActive = access['isModuleMerchantInformedConsentActive'];
      }
    });

    this.activeRoute.params.subscribe((params) => {
      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isAdminOrPromoter = res;
      });
    });
  }

  selectMessage(m) {
    this.selectedMessage = m;
    this.myMsg.label = this.selectedMessage.label;
    this.myMsg.content = this.selectedMessage.content;
  }

  createMessage() {
    this.myMsg = {};
  }

  addPersonalisation(p) {
    this.myMsg.content = this.myMsg.content + ' ' + p.value;
  }

  viewVideoInfo(videoTitle, docUrl) {
    const ref = RootAppComponent.dialog.open(DocumentUrlViewComponent, {
      data: {
        title: videoTitle,
        description: '',
        documentUrl: docUrl,
      },
      width: '800px',
      panelClass: 'noCard',
    });
    const sub = ref.componentInstance.closeModal.subscribe((data) => {
      ref.close();
    });
  }
}
