import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { AuthenticationService } from '../../authentication/shared/authentication.service';
import { HelperService } from '../shared/helper.service';

@Component({
  selector: 'app-simple-page-create-edit',
  templateUrl: './simple-page-create-edit.component.html',
  styleUrls: ['./simple-page-create-edit.component.css'],
})
export class SimplePageCreateEditComponent implements OnInit {
  @Output()
  close = new EventEmitter();

  @Input()
  simplePageCode;

  content;

  title;
  type;

  isEditMode = false;
  _content;
  simplePage;
  sessionType;

  constructor(
    private helperService: HelperService,
    private authenticationService: AuthenticationService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.simplePageCode) {
        this.simplePageCode = data.simplePageCode;
      }
    }
  }

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((st) => {
      this.sessionType = st;

      this.setup();
    });
  }

  setup() {
    if (this.simplePageCode) {
      this.helperService.getSimplePage(this.simplePageCode).subscribe((res) => {
        if (res) {
          this.simplePage = res;

          if (this.simplePage.title) {
            this.title = this.simplePage.title;
          }

          if (this.simplePage.content) {
            this.content = this.simplePage.content;
            this._content = this.simplePage.content;
          }

          if (this.simplePage.type) {
            this.type = this.simplePage.type;
          }

          this.isEditMode = true;
        }
      });
    }
  }

  save() {
    const payload = {
      type: this.type,
      title: this.title,
      content: this.content,
    };

    if (this.simplePageCode) {
      this.helperService.editSimplePage(payload).subscribe((res) => {
        if (res) {
          NotifyAppComponent.displayToast('success', 'The page has been successfully updated', 'Successfull update');

          this.closeEvent();
        }
      });
    } else {
      this.helperService.addSimplePage(payload).subscribe((res) => {
        if (res) {
          NotifyAppComponent.displayToast('success', 'The page has been successfully updated', 'Successfull update');
          this.closeEvent();
        }
      });
    }
  }

  changeTitle() {
    if (this.title && !this.type) {
      const type = String(this.title).toLocaleLowerCase();
      this.type = type.split(' ').join('_');
    }
  }

  getContent(d) {
    this.content = d;
  }

  closeEvent() {
    this.close.emit(true);
  }
}
