import { Component, EventEmitter, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { OperatorService } from '../shared/operator.service';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';

@Component({
  selector: 'app-password-prompt',
  templateUrl: './password-prompt.component.html',
  styleUrls: ['./password-prompt.component.css'],
})
export class PasswordPromptComponent implements OnInit {
  operatorID ;
  showPassword1 = false;
  sendAsPromoter = true;

  operator;
  isPromoterOrAdmin = false;

  password = this.generateRandomString;
  sessionType;
  close = new EventEmitter();

  getResult = new EventEmitter();

  constructor(
    private AuthenticationService: AuthenticationService,
    private operatorService: OperatorService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.operatorID) {
        this.operatorID = data.operatorID;
      }
    }
  }
  ngOnInit() {
    this.AuthenticationService.getSessionType().subscribe((res) => {
      this.sessionType = res;
      this.AuthenticationService.isPromoterOrAdmin().subscribe((isPromoterOrAdmin) => {
        this.isPromoterOrAdmin = isPromoterOrAdmin;

        this.operatorService.getOperatorDetails(this.operatorID , {selectCard:true}, this.sessionType).subscribe((r) => {
          if (r && r.ID) {
            this.operator = r;
          }
        });
      });
    });
  }

  changeHide1() {
    this.showPassword1 = !this.showPassword1;
  }

  generateRandomString() {
    const uppercaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const lowercaseLetters = 'abcdefghijklmnopqrstuvwxyz';
    const numbers = '0123456789';
    const specialCharacters = '!?$@#';

    let password = '';

    // Add one uppercase letter
    password += uppercaseLetters[Math.floor(Math.random() * uppercaseLetters.length)];

    // Add four numbers
    for (let i = 0; i < 4; i++) {
      password += numbers[Math.floor(Math.random() * numbers.length)];
    }

    // Add one special character
    password += specialCharacters[Math.floor(Math.random() * specialCharacters.length)];

    // Add one lowercase letter
    password += lowercaseLetters[Math.floor(Math.random() * lowercaseLetters.length)];

    // Fill the remaining characters with random letters and numbers
    for (let i = password.length; i < 14; i++) {
      const allCharacters = uppercaseLetters + lowercaseLetters + numbers;
      password += allCharacters[Math.floor(Math.random() * allCharacters.length)];
    }

    // Shuffle the password string
    password = password
      .split('')
      .sort(() => Math.random() - 0.5)
      .join('');

    return password;
  }
  closeEvent() {
    this.close.emit(true);
  }

  getRole() {
    if (this.operator && this.operator.Role) {
      let role = this.operator.role;

      if (role === 'MRC') {
        if (this.operator['UltimateTableName'] === 'Promoter') {
          return 'Promoter';
        }
        if (this.operator['IsAdministrator'] === '1') {
          return 'Merchant Admin';
        } else {
          return 'Merchant';
        }
      } else {
        if (this.operator['UltimateTableName'] === 'Promoter') {
          return 'Promoter';
        } else if (this.operator['UltimateTableName'] === 'Merchant') {
          if (this.operator['IsAdministrator'] === '1') {
            return 'Merchant Admin';
          } else {
            return 'Merchant';
          }
        } else if (this.operator['UltimateTableName'] === 'MerchantProspect') {
          return 'Merchant Prospect';
        } else if (
          this.operator['UltimateTableName'] === 'Customer' ||
          this.operator['UltimateTableName'] === 'CustomersAndProspects' ||
          this.operator['UltimateTableName'] === 'Prospect'
        ) {
          return 'Customer';
        }
      }
    }
  }

  submit()
  {
    if(this.operatorID)
    {
      const confirmDialogParams = {
        data: new ConfirmDialog(
          'fas fa-info',
          'Do you wish to continue?',
          '<p>You are about to send the user a reset Password Email.</p>',
          'No',
          'Yes'
        ),
      };
      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, confirmDialogParams);
      ref.componentInstance.onConfirm.subscribe((val) => {
        if (val == true) {
          this.operatorService.resetPassword(this.operatorID,{},this.sessionType).subscribe((res) => {
            if (res) {


              NotifyAppComponent.displayToast('success', 'Success', '');
              ref.close();
              ref.afterClosed().subscribe(r=>{
                this.getResult.emit(res)
              })
            }
          });
        } else {
          ref.close();
        }
      });
    }
  }
}
