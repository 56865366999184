<mat-card class="card clearfix" *ngIf="invitation && invitationID">
  <form #resendForm="ngForm" name="form">
    <div class="card-header text-left primary-gradient-img clearfix inModal">
      <div class="row clearfix">
        <!-- Modal dismiss button -->
        <button class="btn-close btn-clear mat-button white" (click)="closeDialog()" matDialogClose>
          <mat-icon>clear</mat-icon>
        </button>
        <!-- merchant calculated name -->
        <div class="row titleArea clearfix">
          <app-ep-document-view-image
            class="patientView userIconView pull-left"
            [defaultPicture]="
              'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/profile_placeholder.png)'
            "
            [link]="profileLink"
          ></app-ep-document-view-image>
          <h2 class="summary-header rm-m">
            {{ invitation['LastName'] }} {{ invitation['FirstName'] }}
            <span class="subLabel">Resend invitation</span>
          </h2>
        </div>
      </div>
    </div>

    <div class="row clearfix full-width">
      <div class="row clearfix">
        <div class="icons-col">
          <mat-icon>phone</mat-icon>
        </div>
        <div class="row-content-col">
          <!-- Mobile Number -->
          <app-phone-input
            class="full-width"
            [phoneNumber]="phoneNumber"
            [isLandLine]="false"
            [label]="'Mobile'"
            [required]="false"
            (getPhone)="getPhone($event)"
          >
          </app-phone-input>
        </div>
      </div>
      <div class="row clearfix">
        <div class="icons-col">
          <mat-icon>email</mat-icon>
        </div>
        <div class="row-content-col">
          <app-email-input
            class="full-width"
            [email]="email"
            [label]="'Email'"
            [required]="false"
            (getEmail)="getEmail($event)"
          >
          </app-email-input>
        </div>
      </div>
    </div>

    <div class="row clearfix mt mb">
      <div class="half-width">
        <div class="col-left text-center">
          <mat-checkbox [(ngModel)]="sendEmail" name="sendEmail" color="primary">
            Send Email
          </mat-checkbox>
        </div>
      </div>
      <div class="half-width">
        <div class="col-right text-center">
          <mat-checkbox [(ngModel)]="sendSms" name="sendSMS" color="primary">
            Send SMS
          </mat-checkbox>
        </div>
      </div>
    </div>

    <div class="row clearfix" id="scheduleSetting">
      <div class="row clearfix">
        <div class="half-width">
          <div class="col-left">
            <div class="icons-col">
              <mat-icon>drafts</mat-icon>
            </div>

            <div class="row-content-col">
              <h3 class="sr-title sm-h3 rm-mt mt rm-mb mb">Schedule Email</h3>
              <div class="input-control">
                <app-date-input
                  class="thin"
                  [label]="'Email Send Date'"
                  [defaultDate]="dateTobeSentDefault"
                  [minDate]="'today'"
                  (selectedDate)="selectDate($event)"
                ></app-date-input>
              </div>
            </div>
          </div>
        </div>

        <div class="half-width" *ngIf="sendSMS">
          <div class="icons-col">
            <mat-icon>phone_iphone</mat-icon>
          </div>

          <div class="row-content-col">
            <h3 class="sr-title sm-h3 rm-mt mt">Schedule SMS</h3>

            <div class="anim" @ngIfAnimation>
              <div class="input-control anim row clearfix">
                <app-date-input
                  class="thin"
                  [isTime]="true"
                  [label]="'SMS Send Date'"
                  [defaultDate]="dateTobeSentDefault"
                  [minDate]="'today'"
                  (selectedDate)="selectDate($event)"
                ></app-date-input>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr class="mb"/>
    <div class="clearfix full-width text-center">
      <button
        class="btn-large"
        [disabled]="!resendForm.form.valid || (!phoneNumber && !email) || (!sendEmail && !sendSms)"
        (click)="resend()"
        mat-raised-button
        color="accent"
      >
        Resend Invitation
      </button>
    </div>
  </form>
</mat-card>
