<div class="rel" *ngIf="firstLoadEvent == false" @ngIfAnimation>
  <div class="row clearfix padded rel">
    <h1 class="dataHeader rm-mt transparent">Fetching data...</h1>
  </div>
</div>

<div class="row clearfix">
  <button
    class="pull-right ml"
    [routerLink]="['/merchant/', { outlets: { page: ['appointment', 'appointment-create-merchant'] } }]"
    [routerLinkActive]="['active']"
    mat-raised-button
    color="primary"
  >
    <mat-icon>alarm_add</mat-icon> New Appointment
  </button>

  <h1 class="dataHeader rm-mt ml">
    <span *ngIf="title"> {{ title }}</span>
    <span *ngIf="!title && (firstName || lastName)">Appointments: {{ firstName }} {{ lastName }} </span>
    <span
      class="badge"
      *ngIf="appointmentEvents && appointmentEvents.length > 0"
      [endVal]="appointmentEvents.length"
      countUp
    ></span>

    <p class="small">Click on an appointment to display more details , double click on day of month to open day view,
      use status and {{ "KEYWORD.dentist" | translate}}s filters to search</p>
  </h1>
</div>
<div class="full-width clearfix flex row">
  <mat-card
    class="card container qtr-width filter-card mr"
    *ngIf="(dentistsUniqueList && dentistsUniqueList.length > 0) || (statusUniqueList && statusUniqueList.length > 0)"
  >
    <div class="colour-stripe clearfix"></div>
    <mat-card-header class="row">
      <mat-card-title>
        <p class="small rm-mt">Filter your appointments by status or {{"KEYWORD.dentist" | translate}}, Click on
          'filter' to apply the search</p>
      </mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <div class="full-width clearfix row">
        <h3 class="sr-title rm-mb" *ngIf="statusUniqueList && statusUniqueList.length > 0">
          <mat-checkbox [checked]="statusGlobal" (change)="changeGlobalStatus()"></mat-checkbox>

          Status
        </h3>

        <hr class="full-width"/>

        <div
          class="full-width clearfix flex ml mini-mb"
          *ngFor="
            let a of statusUniqueList
              | WithoutArrayMultipleValue: 'Code':['RESCHEDULED', 'REQUESTED']
              | OrderArrayObject: 'SortOrder'
          "
        >
          <mat-checkbox
            class="clearfix status-checkbox full-width"
            [checked]="isChecked(selectedStatus, a['Code'])"
            (change)="changeCheckedStatus($event, a['Code'])"
          >
            <h4 class="sm-h4 sr-title" [style.color]="utilAppointment.getStatusColor(a.Code)">{{ a['Label'] }}</h4>
            <p class="small">
              {{ utilAppointment.getStatusDescription(a.Code) }}
            </p>
          </mat-checkbox>

          <span class="chip-status pull-right mr" [style.background]="utilAppointment.getStatusColor(a.Code)">{{
            appointments | FilterArrayMultipleContainValue: ['Status.Code']:a['Code'] | lengthOfArray
            }}</span>
        </div>
      </div>
      <div class="full-width clearfix mt">
        <h3 class="sr-title mt rm-mb" *ngIf="dentistsUniqueList && dentistsUniqueList.length > 0">
          <mat-checkbox class="mini-mb" [checked]="dentistGlobal" (change)="changeGlobalDentist()"></mat-checkbox>

          {{ "KEYWORD.practitioner" | translate | titlecase }}
        </h3>

        <hr class="full-width"/>

        <div
          class="full-width clearfix flex view-dentist ml"
          *ngFor="let a of dentistsUniqueList | OrderArrayObject: 'MerchantContact_CalculatedName'"
        >
          <mat-checkbox
            class="full-width"
            [checked]="isChecked(selectedDentist, a['MerchantContact_key'])"
            (change)="changeCheckedDentist($event, a['MerchantContact_key'])"
          >
            <span *ngIf="a.MerchantContact_CalculatedName">{{ a.MerchantContact_CalculatedName }}</span>

            <span *ngIf="!a.MerchantContact_CalculatedName">Unassigned</span>
          </mat-checkbox>

          <mat-icon
            class="pull-right mr view-button-denist"
            *ngIf="a.MerchantContact_CalculatedName"
            (click)="openDentistViewDialog(a.MerchantContact_key)"
            color="primary"
          >
            visibility
          </mat-icon>
        </div>
      </div>

      <div class="search-action full-width clearfix mt text-center">
        <button class="text-center ml mr" (click)="filterResult()" mat-raised-button color="accent">
          <mat-icon>search</mat-icon>

          Filter
        </button>
      </div>
    </mat-card-content>
  </mat-card>

  <div class="full-width">
    <app-calendar-view
      class="full-width"
      [events]="appointmentEvents"
      [canEdit]="isEdit"
      [canRemove]="isRemove"
      [view]="viewType"
      [removeEvent]="removeCalendar"
      [editEvent]="editCalendar"
      (sendSelected)="eventSelected($event)"
    ></app-calendar-view>
  </div>
</div>

<app-appointment-action-hidden [runAction]="runAction" [merchantID]="merchantID" (result)="getActionResult($event)">
</app-appointment-action-hidden>
