import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { LookupService } from '../../../shared/services/lookup.service';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { PersonalCircumstancesService } from '../shared/personal-circumstances.service';

@Component({
  selector: 'app-pci-other-income',
  templateUrl: './other-income.component.html',
  styleUrls: ['./other-income.component.css'],
})
export class PCIOtherIncomeComponent implements OnInit {
  @Input()
  personalCirID;

  @Input()
  pciEntryObj;

  @Input()
  disabled = false;

  @Output()
  close = new EventEmitter();

  @Output()
  getResult = new EventEmitter();

  isModal = false;

  pcDetail = {
    updatedReason: null,
    updatedComment: null,
    infoDetails: null,
    infoType: null,
    incomeValue: null,
    incomeFrequency: null,
    modifiedField: null,
    fileID: null,
  };

  isPromoterOrAdmin = false;

  frequencies = [];

  persCircumUpdReasons = [];

  constructor(
    private authenticationService: AuthenticationService,
    private pciService: PersonalCircumstancesService,
    private lookupService: LookupService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.personalCirID) {
        this.personalCirID = data.personalCirID;
      }

      this.isModal = true;
    }
  }

  ngOnInit() {
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;
      if (this.isPromoterOrAdmin == true) {
        this.getDetails();

        this.lookupService.getLookup('CodeLookup', 'PersCircumUpdReason').subscribe((res) => {
          if (res) {
            this.persCircumUpdReasons = res;
          }
        });

        this.lookupService.getLookup('CodeLookup', 'Frequency').subscribe((res) => {
          if (res) {
            this.frequencies = res;
          }
        });
      }
    });
  }

  submit() {
    this.pciService.editPCIOtherIncome(this.pciEntryObj['ID'], this.pcDetail).subscribe((res) => {
      if (res) {
        NotifyAppComponent.displayToast('success', 'Modify record', 'You have successfully modified this record');
        this.pciEntryObj = res;

        this.getDetails();

        const modifiedField = this.pciEntryObj['Modified_Fields'].split('|');
        for (let i = 0; i < modifiedField.length; i++) {
          this.highlightField(modifiedField[i]);
        }

        this.getResult.emit(res);
      }
    });
  }

  create() {
    this.pcDetail['personalCircumstanceID'] = this.personalCirID;

    this.pciService.createPCIOtherIncome(this.pcDetail).subscribe((res) => {
      if (res) {
        NotifyAppComponent.displayToast('success', 'Create record', 'You have successfully created a record');

        this.getResult.emit(res);
      }
    });
  }

  getDetails() {
    if (this.pciEntryObj) {
      if (this.pciEntryObj['Updating_Reason.Code']) {
        this.pcDetail.updatedReason = this.pciEntryObj['Updating_Reason.Code'];
      }

      if (this.pciEntryObj['Updating_Reason.Comment']) {
        this.pcDetail.updatedComment = this.pciEntryObj['Updating_Reason.Comment'];
      }

      if (this.pciEntryObj['Info.Type']) {
        this.pcDetail.infoType = this.pciEntryObj['Info.Type'];
      }

      if (this.pciEntryObj['Income.Value']) {
        this.pcDetail.incomeValue = this.pciEntryObj['Income.Value'];
      }

      if (this.pciEntryObj['Income.Frequency']) {
        this.pcDetail.incomeFrequency = this.pciEntryObj['Income.Frequency'];
      }

      if (this.pciEntryObj['Modified_Fields']) {
        this.pcDetail.modifiedField = this.pciEntryObj['Modified_Fields'];
      }
    }
  }

  closeModalEvent() {
    this.close.emit(true);
  }

  onCompleteAll(e) {
    if (e && e[0]) {
      this.pcDetail.fileID = e[0];
    }
  }

  removeFile(e) {
    if (e && e == this.pcDetail.fileID) {
      this.pcDetail.fileID = null;
    }
  }

  highlightField(field) {
    let result = '';

    if (this.isModal == false && this.pcDetail.modifiedField) {
      const _modifiedField = this.pcDetail.modifiedField.split('|');

      if (_modifiedField.indexOf(field) != -1) {
        result = 'highlight';
      }
    }

    return result;
  }
}
