import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TreatmentChoiceAdvancedComponent } from '../treatment-choice-advanced/treatment-choice-advanced.component';
import { TreatmentChoiceComponent } from '../treatment-choice/treatment-choice.component';
import { TreatmentPlanCreateComponent } from '../treatment-plan-create/treatment-plan-create.component';
import { TreatmentPlanViewComponent } from '../treatment-plan-view/treatment-plan-view.component';

const routes: Routes = [
  {
    path: 'treatment-plan-create',
    component: TreatmentPlanCreateComponent,
  },
  {
    path: 'treatment-plan-view',
    component: TreatmentPlanViewComponent,
  },
  {
    path: 'treatment-choice',
    component: TreatmentChoiceComponent,
  },
  {
    path: 'treatment-choice-advanced',
    component: TreatmentChoiceAdvancedComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TreatmentPlanRoutingModule {}
