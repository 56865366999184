<div class="detections-key">
  <h4 class="sr-title" style="text-align: center; font-weight: bold">Detections Key</h4>
  <p style="text-align: center">Click tooth to display detected issues.</p>

  <div class="detections-key-list">
    <div class="detections-key-list-item">
      <div class="detection-color-chip" style="background-color: red"></div>
      <div class="detection-color-description">APICAL</div>
    </div>

    <div class="detections-key-list-item">
      <div class="detection-color-chip" style="background-color: purple"></div>
      <div class="detection-color-description">CARIES</div>
    </div>

    <div class="detections-key-list-item">
      <div class="detection-color-chip" style="background-color: orange"></div>
      <div class="detection-color-description">RESTORATIONS</div>
    </div>
  </div>
</div>

<div class="teeth-button-array">
  <!--  BUTTON ARRAY -->
  <div style="display: flex; justify-content: center">
    <mat-button-toggle *ngFor="let tooth of teethTop" val="18">
      <sr-tooth-detections [tooth]="tooth" [isAdult]="isAdult"></sr-tooth-detections>
    </mat-button-toggle>
  </div>
  <div style="display: flex; justify-content: center">
    <mat-button-toggle *ngFor="let tooth of teethBottom" val="48">
      <sr-tooth-detections [tooth]="tooth" [isAdult]="isAdult"></sr-tooth-detections>
    </mat-button-toggle>
  </div>
</div>
