<nav id="header">
  <div class="clearfix" *ngIf="appState$ | async as appState">




    <div class="pull-left">
      <h1 class="mat-h1 inline truncate" style="text-transform: math-auto;">{{ header | translate }}</h1>



      <app-invitation-new-button [isInviteButton]="true" [isQuoteButton]="true" [isMemberButton]="true"
        [isInHeader]="true">
      </app-invitation-new-button>

      <app-invitation-new-button *ngIf="appState.publicModules.isModulePatientPortalAccessActive"
        [isQuickInvitation]="true" [isInviteButton]="true" [isQuoteButton]="false" [isMemberButton]="false"
        [isInHeader]="true">
      </app-invitation-new-button>

      <button class="inline btn-new-invite gradient green"
        *ngIf="getModuleActive('isModuleFacilityActive') && appState.publicModules.isModuleFacilityActive"
        [ngClass]="{ 'not-active': !appState.publicModules.isModuleFacilityActive }" [routerLink]="
          isNavigationEnabled(appState.publicModules.isModuleFacilityActive, [
            '/merchant/',
            { outlets: { page: ['facility-invitation-create'] } }
          ])
        " [routerLinkActive]="['active']" [matTooltip]="'Facility Invitation'" (click)="
          openModule({
            code: 'isModuleFacilityActive',
            isActive: appState.publicModules.isModuleFacilityActive,
            isBeta: isBeta('isModuleFacilityActive'),
            showModule: !appState.publicModules.isModuleFacilityActive
          })
        " mat-raised-button color="accent">
        <mat-icon>local_atm</mat-icon>
        <span class="chip chipBeta" *ngIf="isBeta('isModuleFacilityActive')">&beta;</span>
      </button>

      <button class="inline btn-new-invite gradient green" *ngIf="context == 'feefunding'"
        [routerLink]="['/merchant/', { outlets: { page: ['fee-funding-bill-create'] } }]"
        [routerLinkActive]="['active']" [matTooltip]="'New Bill'" mat-raised-button color="accent">
        <i class="fas fa-money-bill-alt" aria-hidden="true"> </i>
      </button>

      <button class="inline btn-new-invite gradient purple" *ngIf="context == 'feefunding'"
        [routerLink]="['/merchant/', { outlets: { page: ['fee-funding-engagement-create'] } }]"
        [routerLinkActive]="['active']" [matTooltip]="'New Engagement'" mat-raised-button color="accent">
        <i class="fas fa-coffee" aria-hidden="true"> </i>
      </button>

      <button class="inline btn-new-invite gradient primary-gradient-img"
        *ngIf="getModuleActive('isModuleSettlementsActive') && appState.publicModules.isModuleSettlementsActive"
        [ngClass]="{ 'not-active': !appState.publicModules.isModuleSettlementsActive }" (click)="createSettlement()"
        appCustomTooltip [customToolTipContent]="getToolTip('settlement')"
        [customToolTipClass]="'additional-tooltip-class-header'" [customReplacement]="'bottom'"
        [customDisplay]="displayInfoToolTip && displayToolTip('settlement')" [customIndicatorPosition]="'bottom'"
        mat-raised-button color="accent">
        <i class="fas fa-piggy-bank" aria-hidden="true"> </i>
        <span class="chip chipBeta" *ngIf="isBeta('isModuleSettlementsActive') == true">&beta;</span>
      </button>
    </div>

    <div class="pull-right">
      <div class="desktop-buttons mobHide pull-left">
        <button class="inline btn-compact patLookup not-active"
          *ngIf="appState.publicModules.isModuleStockArtActive != true && getModuleActive('isModuleStockArtActive')"
          (click)="viewModule('isModuleStockArtActive')" mat-button appCustomTooltip
          [customToolTipContent]="getToolTip('treatmentGallery')"
          [customToolTipClass]="'additional-tooltip-class-header'" [customReplacement]="'bottom'"
          [customDisplay]="displayInfoToolTip && displayToolTip('treatmentGallery')"
          [customIndicatorPosition]="'bottom'">
          <mat-icon class="fas fa-image"></mat-icon>
        </button>

        <button class="inline btn-compact patLookup" *ngIf="isModuleStockArtActive == true && context != 'breeze' "
          appCustomTooltip [customToolTipContent]="getToolTip('treatmentGallery')"
          [customToolTipClass]="'additional-tooltip-class-header'" [customReplacement]="'bottom'"
          [customDisplay]="displayInfoToolTip && displayToolTip('treatmentGallery')"
          [customIndicatorPosition]="'bottom'" (click)="treatmentGalleryLookup()" mat-button>
          <mat-icon class="fas fa-image"></mat-icon>
          <span class="chip chipBeta" *ngIf="isBeta('isModuleStockArtActive') == true">&beta;</span>
        </button>

        <button class="inline btn-compact patLookup" *ipvIsPromoterOrAdmin (click)="operatorLookup()" mat-button
          matTooltip="Operator Lookup">
          <mat-icon class="fas fa-user-shield"></mat-icon>
        </button>

        <button class="inline btn-compact productLookup not-active pull-left" *ngIf="
            isModuleTreatmentGroupActive != true &&
            getModuleActive('isModuleTreatmentGroupActive') &&
            context != 'breeze'  && context != 'p4p'
          " (click)="viewModule('isModuleTreatmentGroupActive')" mat-button appCustomTooltip
          [customToolTipContent]="'<div><h3>Product lookup</h3> <p>You can search for procedures, treatment, suppliers, brands and products in a number of ways. Choose one of the following options to perform the search.</p></div>'"
          [customToolTipClass]="'additional-tooltip-class-header'" [customReplacement]="'bottom'"
          [customDisplay]="displayInfoToolTip" [customIndicatorPosition]="'bottom'">
          <i>
            <svg class="pull-left" id="treatLookIcon" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 153.34 136.08">
              <defs>
                <style>
                  .cls-1 {
                    fill: none;
                  }

                </style>
              </defs>
              <title>treatment-lookup</title>
              <circle class="cls-1" cx="259.58" cy="109.88" r="6.38"
                transform="translate(-210.22 122.86) rotate(-45)" />
              <path
                d="M283.57,186.33a2.43,2.43,0,0,1-.6.1H268.08v14.88a2.12,2.12,0,0,1-2.12,2.12H253.2a2.13,2.13,0,0,1-2.13-2.12V186.43H236.19a2.14,2.14,0,0,1-2.13-2.13V171.54a2.13,2.13,0,0,1,2.13-2.12h14.88V154.53a2.13,2.13,0,0,1,2.13-2.12H258a46.33,46.33,0,0,1,.18-17h-22a2.14,2.14,0,0,1-2.13-2.13V129a2.14,2.14,0,0,1,2.13-2.13h24.56a47,47,0,0,1,15.56-20,17,17,0,0,0-33.74,3H221.31a12.76,12.76,0,0,0-12.76,12.76v93.55A12.76,12.76,0,0,0,221.31,229h76.54a12.77,12.77,0,0,0,12.76-12.76V190.56A48.73,48.73,0,0,1,304,191,46.37,46.37,0,0,1,283.57,186.33Zm-24-82.83a6.38,6.38,0,1,1-6.38,6.38A6.37,6.37,0,0,1,259.58,103.5Z"
                transform="translate(-208.55 -92.87)" />
              <path
                d="M360.56,189.12l-19-19a4.57,4.57,0,0,0-3.24-1.34h-3.11a39.64,39.64,0,0,0-50.73-58.91,39.85,39.85,0,0,0-16.09,17,39.32,39.32,0,0,0-3,8.51,39.72,39.72,0,0,0-.23,17,39.15,39.15,0,0,0,2.94,8.83,39.83,39.83,0,0,0,17,18,39.66,39.66,0,0,0,43.27-3.61v3.11a4.6,4.6,0,0,0,1.33,3.24l19,19a4.6,4.6,0,0,0,3.24,1.34,4.52,4.52,0,0,0,3.22-1.34l5.4-5.4A4.59,4.59,0,0,0,360.56,189.12Zm-49.95-21.25a24.39,24.39,0,1,1,17.75-23.47A24.26,24.26,0,0,1,310.61,167.87Z"
                transform="translate(-208.55 -92.87)" />
              <path
                d="M316.39,139.58h-7.74v-7.75a1.11,1.11,0,0,0-1.11-1.1H300.9a1.11,1.11,0,0,0-1.1,1.1v7.75h-7.74a1.11,1.11,0,0,0-1.11,1.1v6.64a1.11,1.11,0,0,0,1.11,1.1h7.74v7.75a1.11,1.11,0,0,0,1.1,1.1h6.64a1.11,1.11,0,0,0,1.11-1.1v-7.75h7.74a1.1,1.1,0,0,0,1.1-1.1v-6.64A1.1,1.1,0,0,0,316.39,139.58Z"
                transform="translate(-208.55 -92.87)" />
            </svg>
          </i>
        </button>

        <button class="inline btn-compact patLookup not-active"
          *ngIf="isModuleNoteFeaturesActive != true && getModuleActive('isModuleNoteFeaturesActive')"
          (click)="viewModule('isModuleNoteFeaturesActive')" mat-button appCustomTooltip
          [customToolTipContent]="getToolTip('noteCalls')" [customToolTipClass]="'additional-tooltip-class-header'"
          [customReplacement]="'bottom'" [customDisplay]="displayInfoToolTip && displayToolTip('noteCalls')"
          [customIndicatorPosition]="'bottom'">
          <mat-icon>perm_phone_msg</mat-icon>
        </button>

        <button class="inline btn-compact patLookup" *ngIf="isModuleNoteFeaturesActive == true"
          [matMenuTriggerFor]="phoneMenu" mat-button appCustomTooltip [customToolTipContent]="getToolTip('noteCalls')"
          [customToolTipClass]="'additional-tooltip-class-header'" [customReplacement]="'bottom'"
          [customDisplay]="displayInfoToolTip && displayToolTip('noteCalls')" [customIndicatorPosition]="'bottom'">
          <mat-icon>perm_phone_msg</mat-icon>
          <span class="chip chipBeta" *ngIf="isBeta('isModuleNoteFeaturesActive') == true">&beta;</span>
        </button>

        <mat-menu #phoneMenu="matMenu">
          <button (click)="createNote('Phone call - Incoming')" mat-menu-item>
            <mat-icon class="rot-180">call_made</mat-icon>
            Phone in
            <span class="chip chipBeta" *ngIf="isBeta('isModuleNoteFeaturesActive') == true">&beta;</span>
          </button>
          <button (click)="createNote('Phone call - Outgoing')" mat-menu-item>
            <mat-icon>call_made</mat-icon>
            Phone out
            <span class="chip chipBeta" *ngIf="isBeta('isModuleNoteFeaturesActive') == true">&beta;</span>
          </button>
        </mat-menu>

        <button class="inline btn-compact productLookup pull-left"
          *ngIf="isModuleTreatmentGroupActive == true && context != 'breeze'   && context != 'p4p'"
          (click)="productLookup()" mat-button appCustomTooltip
          [customToolTipContent]="'<div><h3>Product lookup</h3> <p>You can search for procedures, treatment, suppliers, brands and products in a number of ways. Choose one of the following options to perform the search.</p></div>'"
          [customToolTipClass]="'additional-tooltip-class-header'" [customReplacement]="'bottom'"
          [customDisplay]="displayInfoToolTip" [customIndicatorPosition]="'bottom'">
          <i>
            <svg class="pull-left" id="treatLookIcon" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 153.34 136.08">
              <defs>
                <style>
                  .cls-1 {
                    fill: none;
                  }

                </style>
              </defs>
              <title>treatment-lookup</title>
              <circle class="cls-1" cx="259.58" cy="109.88" r="6.38"
                transform="translate(-210.22 122.86) rotate(-45)" />
              <path
                d="M283.57,186.33a2.43,2.43,0,0,1-.6.1H268.08v14.88a2.12,2.12,0,0,1-2.12,2.12H253.2a2.13,2.13,0,0,1-2.13-2.12V186.43H236.19a2.14,2.14,0,0,1-2.13-2.13V171.54a2.13,2.13,0,0,1,2.13-2.12h14.88V154.53a2.13,2.13,0,0,1,2.13-2.12H258a46.33,46.33,0,0,1,.18-17h-22a2.14,2.14,0,0,1-2.13-2.13V129a2.14,2.14,0,0,1,2.13-2.13h24.56a47,47,0,0,1,15.56-20,17,17,0,0,0-33.74,3H221.31a12.76,12.76,0,0,0-12.76,12.76v93.55A12.76,12.76,0,0,0,221.31,229h76.54a12.77,12.77,0,0,0,12.76-12.76V190.56A48.73,48.73,0,0,1,304,191,46.37,46.37,0,0,1,283.57,186.33Zm-24-82.83a6.38,6.38,0,1,1-6.38,6.38A6.37,6.37,0,0,1,259.58,103.5Z"
                transform="translate(-208.55 -92.87)" />
              <path
                d="M360.56,189.12l-19-19a4.57,4.57,0,0,0-3.24-1.34h-3.11a39.64,39.64,0,0,0-50.73-58.91,39.85,39.85,0,0,0-16.09,17,39.32,39.32,0,0,0-3,8.51,39.72,39.72,0,0,0-.23,17,39.15,39.15,0,0,0,2.94,8.83,39.83,39.83,0,0,0,17,18,39.66,39.66,0,0,0,43.27-3.61v3.11a4.6,4.6,0,0,0,1.33,3.24l19,19a4.6,4.6,0,0,0,3.24,1.34,4.52,4.52,0,0,0,3.22-1.34l5.4-5.4A4.59,4.59,0,0,0,360.56,189.12Zm-49.95-21.25a24.39,24.39,0,1,1,17.75-23.47A24.26,24.26,0,0,1,310.61,167.87Z"
                transform="translate(-208.55 -92.87)" />
              <path
                d="M316.39,139.58h-7.74v-7.75a1.11,1.11,0,0,0-1.11-1.1H300.9a1.11,1.11,0,0,0-1.1,1.1v7.75h-7.74a1.11,1.11,0,0,0-1.11,1.1v6.64a1.11,1.11,0,0,0,1.11,1.1h7.74v7.75a1.11,1.11,0,0,0,1.1,1.1h6.64a1.11,1.11,0,0,0,1.11-1.1v-7.75h7.74a1.1,1.1,0,0,0,1.1-1.1v-6.64A1.1,1.1,0,0,0,316.39,139.58Z"
                transform="translate(-208.55 -92.87)" />
            </svg>
          </i>
          <span class="chip chipBeta" *ngIf="isBeta('isModuleTreatmentGroupActive') == true">&beta;</span>
        </button>


        <button class="inline btn-compact patLookup not-active"
          *ngIf="isModulePatientSMSActive != true && getModuleActive('isModulePatientSMSActive')"
          (click)="viewModule('isModulePatientSMSActive')" mat-button appCustomTooltip
          [customToolTipContent]="getToolTip('SMS')" [customToolTipClass]="'additional-tooltip-class-header'"
          [customReplacement]="'bottom'" [customDisplay]="displayInfoToolTip && displayToolTip('SMS')"
          [customIndicatorPosition]="'bottom'">
          <mat-icon class="fas fa-sms"></mat-icon>
        </button>

        <button class="inline btn-compact patLookup" *ngIf="isModulePatientSMSActive == true"
          [matMenuTriggerFor]="menuListSMS" mat-button>
          <mat-icon class="fas fa-sms"></mat-icon>
          <span class="chip chipBeta" *ngIf="isBeta('isModulePatientSMSActive') == true">&beta;</span>
        </button>




        <mat-menu #menuListSMS="matMenu">


          <button (click)="openCreateGlobalSMS()" appCustomTooltip [customToolTipContent]="getToolTip('SMS')"
            [customToolTipClass]="'additional-tooltip-class-header1540'" [customReplacement]="'left'"
            [customDisplay]="displayInfoToolTip && displayToolTip('SMS')" [customIndicatorPosition]="'left'"
            mat-menu-item>
            <strong> {{'Patient'  | translate }} </strong>SMS
          </button>

          <!-- <button (click)="openCreateGlobalSMSPersonal()" *ngIf="isPromoterOrAdmin!=true" appCustomTooltip
            [customToolTipContent]="getToolTip('SMS')" [customToolTipClass]="'additional-tooltip-class-header1540'"
            [customReplacement]="'left'" [customDisplay]="displayInfoToolTip && displayToolTip('SMS')"
            [customIndicatorPosition]="'left'" mat-menu-item>
            <strong> {{'Patient'  | translate }} </strong> Personal SMS
          </button> -->

          <hr />
          <button (click)="openCreateGlobalSMSContact()" appCustomTooltip
            [customToolTipContent]="getToolTip('SMSContact')"
            [customToolTipClass]="'additional-tooltip-class-header1540'" [customReplacement]="'left'"
            [customDisplay]="displayInfoToolTip && displayToolTip('SMSContact')" [customIndicatorPosition]="'left'"
            mat-menu-item>
            <strong> Staff</strong> SMS
          </button>




        </mat-menu>



        <button class="inline btn-compact patLookup not-active"
          *ngIf="isModuleNoteFeaturesActive != true && getModuleActive('isModuleNoteFeaturesActive')"
          (click)="viewModule('isModuleNoteFeaturesActive')" mat-button appCustomTooltip
          [customToolTipContent]="getToolTip('notePatients')" [customToolTipClass]="'additional-tooltip-class-header'"
          [customReplacement]="'bottom'" [customDisplay]="displayInfoToolTip && displayToolTip('notePatients')"
          [customIndicatorPosition]="'bottom'">
          <mat-icon>note_add</mat-icon>
        </button>

        <button class="inline btn-compact patLookup" *ngIf="isModuleNoteFeaturesActive == true" (click)="openNotes()"
          mat-button appCustomTooltip [customToolTipContent]="getToolTip('notePatients')"
          [customToolTipClass]="'additional-tooltip-class-header'" [customReplacement]="'bottom'"
          [customDisplay]="displayInfoToolTip && displayToolTip('notePatients')" [customIndicatorPosition]="'bottom'">
          <mat-icon>note_add</mat-icon>
          <span class="chip chipBeta" *ngIf="isBeta('isModuleNoteFeaturesActive') == true">&beta;</span>
        </button>

        <button class="inline btn-clear btn-calc btn-compact not-active"
          *ngIf="isModuleTreatmentTemplateActive != true && getModuleActive('isModuleTreatmentTemplateActive')"
          (click)="viewModule('isModuleTreatmentTemplateActive')" mat-button appCustomTooltip
          [customToolTipContent]="getToolTip('treatmentTemplates')"
          [customToolTipClass]="'additional-tooltip-class-header-right'" [customReplacement]="'bottom'"
          [customDisplay]="displayInfoToolTip && displayToolTip('treatmentTemplates')"
          [customIndicatorPosition]="'bottom'">
          <mat-icon class="fas fa-brush"></mat-icon>
        </button>

        <button class="inline btn-clear btn-calc btn-compact" id="TreatmentTrigger"
          *ngIf="isModuleTreatmentTemplateActive == true && context != 'breeze'   && context != 'p4p' " appCustomTooltip
          [customToolTipContent]="getToolTip('treatmentTemplates')"
          [customToolTipClass]="'additional-tooltip-class-header-right'" [customReplacement]="'bottom'"
          [customDisplay]="displayInfoToolTip && displayToolTip('treatmentTemplates')"
          [customIndicatorPosition]="'bottom'" (click)="openTemplateLookup()" mat-button>
          <mat-icon class="fas fa-brush"></mat-icon>
          <span class="chip chipBeta" *ngIf="isBeta('isModuleTreatmentTemplateActive') == true">&beta;</span>
        </button>

        <button class="inline btn-clear btn-calc btn-compact not-active"
          *ngIf="isModuleDocumentLinkActive != true && getModuleActive('isModuleDocumentLinkActive')"
          (click)="viewModule('isModuleDocumentLinkActive')" mat-button appCustomTooltip
          [customToolTipContent]="getToolTip('landingPages')"
          [customToolTipClass]="'additional-tooltip-class-header-right'" [customReplacement]="'bottom'"
          [customDisplay]="displayInfoToolTip && displayToolTip('landingPages')" [customIndicatorPosition]="'bottom'">
          <mat-icon class="fas fa-folder-open"></mat-icon>
        </button>

        <button class="inline btn-clear btn-calc btn-compact" id="TreatmentTriggeropen"
          *ngIf="isModuleDocumentLinkActive == true" (click)="documentLinkLookup()" mat-button appCustomTooltip
          [customToolTipContent]="getToolTip('landingPages')"
          [customToolTipClass]="'additional-tooltip-class-header-right'" [customReplacement]="'bottom'"
          [customDisplay]="displayInfoToolTip && displayToolTip('landingPages')" [customIndicatorPosition]="'bottom'">
          <mat-icon class="fas fa-folder-open"></mat-icon>
          <span class="chip chipBeta" *ngIf="isBeta('isModuleDocumentLinkActive') == true">&beta;</span>
        </button>

        <button class="inline btn-compact patLookup not-active"
          *ngIf="isModulePatientsActive != true && getModuleActive('isModulePatientsActive')"
          (click)="viewModule('isModulePatientsActive')" mat-button appCustomTooltip
          [customToolTipContent]="getToolTip('patientLookup')"
          [customToolTipClass]="'additional-tooltip-class-header-right'" [customReplacement]="'bottom'"
          [customDisplay]="displayInfoToolTip && displayToolTip('patientLookup')" [customIndicatorPosition]="'bottom'">
          <mat-icon class="fas fa-users"></mat-icon>
        </button>



        <button class="inline btn-compact patLookup" *ngIf="isModulePatientsActive == true"
          [matMenuTriggerFor]="menuListLookup" mat-button>
          <mat-icon class="fas fa-users"></mat-icon>
          <span class="chip chipBeta" *ngIf="isBeta('isModulePatientsActive') == true">&beta;</span>
        </button>




        <mat-menu #menuListLookup="matMenu">
          <button (click)="patientLookup()" appCustomTooltip [customToolTipContent]="getToolTip('patientLookup')"
            [customToolTipClass]="'additional-tooltip-class-header1540'" [customReplacement]="'left'"
            [customDisplay]="displayInfoToolTip && displayToolTip('patientLookup')" [customIndicatorPosition]="'left'"
            mat-menu-item>
            <strong> {{'Patient'  | translate }} </strong>Lookup
          </button>
          <hr />
          <button (click)="contactLookup()" appCustomTooltip [customToolTipContent]="getToolTip('contactLookup')"
            [customToolTipClass]="'additional-tooltip-class-header1540'" [customReplacement]="'left'"
            [customDisplay]="displayInfoToolTip && displayToolTip('contactLookup')" [customIndicatorPosition]="'left'"
            mat-menu-item>
            <strong> Staff</strong> Lookup
          </button>




        </mat-menu>




        <button class="inline btn-compact patLookup" (click)="messageTemplate()" mat-button appCustomTooltip
          [customToolTipContent]="getToolTip('messageTemplates')"
          [customToolTipClass]="'additional-tooltip-class-header-right'" [customReplacement]="'bottom'"
          [customDisplay]="displayInfoToolTip && displayToolTip('messageTemplates')"
          [customIndicatorPosition]="'bottom'">
          <mat-icon class="fas fa-envelope-open-text"></mat-icon>
        </button>

        <button class="inline btn-clear btn-calc btn-compact" id="financeCalculatorTrigger" (click)="openCalcDialog()"
          mat-button appCustomTooltip [customToolTipContent]="getToolTip('calculator')"
          [customToolTipClass]="'additional-tooltip-class-header-right'" [customReplacement]="'bottom'"
          [customDisplay]="displayInfoToolTip && displayToolTip('calculator')" [customIndicatorPosition]="'bottom'">
          <mat-icon>attach_money</mat-icon>
        </button>
      </div>
      <div class="desktop-buttons mobShow pull-left">
        <button class="" [matMenuTriggerFor]="mobMenu" mat-stroked-button>Tools</button>

        <mat-menu #mobMenu="matMenu">
          <button class="not-active" *ngIf="
              isModuleStockArtActive != true &&
              getModuleActive('isModuleStockArtActive') &&
              context != 'breeze'
            " (click)="viewModule('isModuleStockArtActive')" mat-menu-item
            matTooltip="You do not have access to this Module">
            <mat-icon class="fas fa-image"></mat-icon>
            {{ "KEYWORD.procedure" | translate | titlecase }} Gallery
          </button>

          <button *ngIf="isModuleStockArtActive == true && context != 'breeze' " (click)="treatmentGalleryLookup()"
            mat-menu-item>
            <mat-icon class="fas fa-image"></mat-icon>
            {{ "KEYWORD.procedure" | translate | titlecase }} Gallery
            <span class="chip chipBeta" *ngIf="isBeta('isModuleStockArtActive') == true">&beta;</span>
          </button>

          <button *ngIf="isPromoterOrAdmin == true" (click)="operatorLookup()" mat-menu-item>
            <mat-icon class="fas fa-user-shield"></mat-icon>
            Operator Lookup
          </button>

          <button class="not-active" *ngIf="
              isModuleTreatmentGroupActive != true &&
              getModuleActive('isModuleTreatmentGroupActive') &&
              context != 'breeze' && context != 'p4p'
            " (click)="viewModule('isModuleTreatmentGroupActive')" mat-menu-item
            matTooltip="You do not have access to this Module">
            <i>
              <svg class="pull-left" id="treatLookIcon" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 153.34 136.08">
                <defs>
                  <style>
                    .cls-1 {
                      fill: none;
                    }

                  </style>
                </defs>
                <title>{{ "KEYWORD.treatment" | translate }}-lookup</title>
                <circle class="cls-1" cx="259.58" cy="109.88" r="6.38"
                  transform="translate(-210.22 122.86) rotate(-45)" />
                <path
                  d="M283.57,186.33a2.43,2.43,0,0,1-.6.1H268.08v14.88a2.12,2.12,0,0,1-2.12,2.12H253.2a2.13,2.13,0,0,1-2.13-2.12V186.43H236.19a2.14,2.14,0,0,1-2.13-2.13V171.54a2.13,2.13,0,0,1,2.13-2.12h14.88V154.53a2.13,2.13,0,0,1,2.13-2.12H258a46.33,46.33,0,0,1,.18-17h-22a2.14,2.14,0,0,1-2.13-2.13V129a2.14,2.14,0,0,1,2.13-2.13h24.56a47,47,0,0,1,15.56-20,17,17,0,0,0-33.74,3H221.31a12.76,12.76,0,0,0-12.76,12.76v93.55A12.76,12.76,0,0,0,221.31,229h76.54a12.77,12.77,0,0,0,12.76-12.76V190.56A48.73,48.73,0,0,1,304,191,46.37,46.37,0,0,1,283.57,186.33Zm-24-82.83a6.38,6.38,0,1,1-6.38,6.38A6.37,6.37,0,0,1,259.58,103.5Z"
                  transform="translate(-208.55 -92.87)" />
                <path
                  d="M360.56,189.12l-19-19a4.57,4.57,0,0,0-3.24-1.34h-3.11a39.64,39.64,0,0,0-50.73-58.91,39.85,39.85,0,0,0-16.09,17,39.32,39.32,0,0,0-3,8.51,39.72,39.72,0,0,0-.23,17,39.15,39.15,0,0,0,2.94,8.83,39.83,39.83,0,0,0,17,18,39.66,39.66,0,0,0,43.27-3.61v3.11a4.6,4.6,0,0,0,1.33,3.24l19,19a4.6,4.6,0,0,0,3.24,1.34,4.52,4.52,0,0,0,3.22-1.34l5.4-5.4A4.59,4.59,0,0,0,360.56,189.12Zm-49.95-21.25a24.39,24.39,0,1,1,17.75-23.47A24.26,24.26,0,0,1,310.61,167.87Z"
                  transform="translate(-208.55 -92.87)" />
                <path
                  d="M316.39,139.58h-7.74v-7.75a1.11,1.11,0,0,0-1.11-1.1H300.9a1.11,1.11,0,0,0-1.1,1.1v7.75h-7.74a1.11,1.11,0,0,0-1.11,1.1v6.64a1.11,1.11,0,0,0,1.11,1.1h7.74v7.75a1.11,1.11,0,0,0,1.1,1.1h6.64a1.11,1.11,0,0,0,1.11-1.1v-7.75h7.74a1.1,1.1,0,0,0,1.1-1.1v-6.64A1.1,1.1,0,0,0,316.39,139.58Z"
                  transform="translate(-208.55 -92.87)" />
              </svg>
            </i>
            Product Lookup
          </button>

          <button *ngIf="isModuleTreatmentGroupActive == true && context != 'breeze' && context != 'p4p'"
            (click)="productLookup()" mat-menu-item>
            <i>
              <svg class="pull-left" id="treatLookIcon" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 153.34 136.08">
                <defs>
                  <style>
                    .cls-1 {
                      fill: none;
                    }

                  </style>
                </defs>
                <title>{{ "KEYWORD.treatment" | translate }}-lookup</title>
                <circle class="cls-1" cx="259.58" cy="109.88" r="6.38"
                  transform="translate(-210.22 122.86) rotate(-45)" />
                <path
                  d="M283.57,186.33a2.43,2.43,0,0,1-.6.1H268.08v14.88a2.12,2.12,0,0,1-2.12,2.12H253.2a2.13,2.13,0,0,1-2.13-2.12V186.43H236.19a2.14,2.14,0,0,1-2.13-2.13V171.54a2.13,2.13,0,0,1,2.13-2.12h14.88V154.53a2.13,2.13,0,0,1,2.13-2.12H258a46.33,46.33,0,0,1,.18-17h-22a2.14,2.14,0,0,1-2.13-2.13V129a2.14,2.14,0,0,1,2.13-2.13h24.56a47,47,0,0,1,15.56-20,17,17,0,0,0-33.74,3H221.31a12.76,12.76,0,0,0-12.76,12.76v93.55A12.76,12.76,0,0,0,221.31,229h76.54a12.77,12.77,0,0,0,12.76-12.76V190.56A48.73,48.73,0,0,1,304,191,46.37,46.37,0,0,1,283.57,186.33Zm-24-82.83a6.38,6.38,0,1,1-6.38,6.38A6.37,6.37,0,0,1,259.58,103.5Z"
                  transform="translate(-208.55 -92.87)" />
                <path
                  d="M360.56,189.12l-19-19a4.57,4.57,0,0,0-3.24-1.34h-3.11a39.64,39.64,0,0,0-50.73-58.91,39.85,39.85,0,0,0-16.09,17,39.32,39.32,0,0,0-3,8.51,39.72,39.72,0,0,0-.23,17,39.15,39.15,0,0,0,2.94,8.83,39.83,39.83,0,0,0,17,18,39.66,39.66,0,0,0,43.27-3.61v3.11a4.6,4.6,0,0,0,1.33,3.24l19,19a4.6,4.6,0,0,0,3.24,1.34,4.52,4.52,0,0,0,3.22-1.34l5.4-5.4A4.59,4.59,0,0,0,360.56,189.12Zm-49.95-21.25a24.39,24.39,0,1,1,17.75-23.47A24.26,24.26,0,0,1,310.61,167.87Z"
                  transform="translate(-208.55 -92.87)" />
                <path
                  d="M316.39,139.58h-7.74v-7.75a1.11,1.11,0,0,0-1.11-1.1H300.9a1.11,1.11,0,0,0-1.1,1.1v7.75h-7.74a1.11,1.11,0,0,0-1.11,1.1v6.64a1.11,1.11,0,0,0,1.11,1.1h7.74v7.75a1.11,1.11,0,0,0,1.1,1.1h6.64a1.11,1.11,0,0,0,1.11-1.1v-7.75h7.74a1.1,1.1,0,0,0,1.1-1.1v-6.64A1.1,1.1,0,0,0,316.39,139.58Z"
                  transform="translate(-208.55 -92.87)" />
              </svg>
            </i>
            Product Lookup
            <span class="chip chipBeta" *ngIf="isBeta('isModuleTreatmentGroupActive') == true">&beta;</span>
          </button>

          <button class="not-active"
            *ngIf="isModuleNoteFeaturesActive != true && getModuleActive('isModuleNoteFeaturesActive')"
            (click)="viewModule('isModuleNoteFeaturesActive')" mat-menu-item
            matTooltip="You do not have access to this Module">
            <mat-icon>perm_phone_msg</mat-icon>
            Phone call
          </button>

          <button class="not-active"
            *ngIf="isModuleNoteFeaturesActive != true && getModuleActive('isModuleNoteFeaturesActive')"
            (click)="viewModule('isModuleNoteFeaturesActive')" mat-menu-item
            matTooltip="You do not have access to this Module">
            <mat-icon>note_add</mat-icon>
            Create note
          </button>

          <button class="not-active" *ngIf="
              isModuleTreatmentTemplateActive != true &&
              getModuleActive('isModuleTreatmentTemplateActive') &&
              context != 'breeze' && context != 'p4p'
            " (click)="viewModule('isModuleTreatmentTemplateActive')" mat-menu-item
            matTooltip="You do not have access to this Module">
            <mat-icon class="fas fa-brush"></mat-icon>
            {{ "KEYWORD.treatment" | translate | titlecase }} Template
          </button>

          <button id="TreatmentTrigger"
            *ngIf="isModuleTreatmentTemplateActive == true && context != 'breeze'&& context != 'p4p' "
            (click)="openTemplateLookup()" mat-menu-item>
            <mat-icon class="fas fa-brush"></mat-icon>
            {{ "KEYWORD.treatment" | translate | titlecase }} Template
            <span class="chip chipBeta" *ngIf="isBeta('isModuleTreatmentTemplateActive') == true">&beta;</span>
          </button>

          <button class="not-active" *ngIf="isModulePatientsActive != true && getModuleActive('isModulePatientsActive')"
            (click)="viewModule('isModulePatientsActive')" mat-menu-item
            matTooltip="You do not have access to this Module">
            <mat-icon class="fas fa-users"></mat-icon>
            {{ "KEYWORD.patient" | translate | titlecase }} lookup
          </button>

          <button *ngIf="isModulePatientsActive == true" (click)="patientLookup()" mat-menu-item>
            <mat-icon class="fas fa-users"></mat-icon>
            {{ "KEYWORD.patient" | translate | titlecase }} lookup
            <span class="chip chipBeta" *ngIf="isBeta('isModulePatientsActive') == true">&beta;</span>
          </button>

          <button id="financeCalculatorTrigger" (click)="openCalcDialog()" mat-menu-item>
            <mat-icon>attach_money</mat-icon>
            Financial Calculator
          </button>
        </mat-menu>
      </div>

      <app-activity-list-menu [numOfTasks]="numOfTasks" [isChangeRoute]="changeRouteEvent" [limit]="10"
        [merchantID]="merchantID" *ngIf="isModuleNoteFeaturesActive == true && isNoteMenuActive == true">
      </app-activity-list-menu>

      <session-compact-menu class="ml" [displayDashboard]="false" [displayMerchantLink]="false" [displayAdmin]="true">
      </session-compact-menu>

      <button class="inline btn-new-invite btn-compact" *ngIf="appointments" (click)="openAppointmentDialog()"
        mat-raised-button color="primary" matTooltip="Appointments">
        <mat-icon>alarm</mat-icon>
      </button>
    </div>
  </div>
</nav>
