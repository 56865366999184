import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ArchwizardModule } from 'angular-archwizard';
import { EmailEditorModule } from 'angular-email-editor';
import { ColorPickerModule } from 'ngx-color-picker';
import { PinchZoomModule } from 'ngx-pinch-zoom';
import { NgPipesModule } from 'ngx-pipes';
import { MerchantModule } from '../../feature/merchant/merchant.module';
import { SharedModule } from '../../shared/shared.module';
import { AuthenticationModule } from '../authentication/authentication.module';
import { SettingsRoutingModule } from '../settings/shared/settings-routing.module';
import { CardFooterComponent } from '../subscription-plan/card-footer/card-footer.component';
import { SubscriptionPlanCardComponent } from '../subscription-plan/subscription-plan-card/subscription-plan-card.component';
import { ModuleEditCreateComponent } from './module-edit-create/module-edit-create.component';
import { ModuleListComponent } from './module-list/module-list.component';
import { ModuleViewComponent } from './module-view/module-view.component';
import { ModuleRoutingModule } from './shared/module-routing.module';
import { ModuleService } from './shared/module.service';

@NgModule({
  imports: [
    ArchwizardModule,
    ReactiveFormsModule,
    CdkTableModule,
    CommonModule,
    ModuleRoutingModule,
    ColorPickerModule,
    NgPipesModule,
    FormsModule,
    SharedModule,
    EmailEditorModule,
    PinchZoomModule,
    SettingsRoutingModule,
    AuthenticationModule,
    MerchantModule,
  ],
  declarations: [
    ModuleListComponent,
    ModuleEditCreateComponent,
    SubscriptionPlanCardComponent,
    ModuleViewComponent,
    CardFooterComponent,
  ],
  exports: [
    ModuleListComponent,
    ModuleEditCreateComponent,
    SubscriptionPlanCardComponent,
    ModuleViewComponent,
    CardFooterComponent,
  ],
  providers: [ModuleService],
})
export class ModuleModule {}
