import { Component, EventEmitter, Inject, Input, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
@Component({
  selector: 'app-code-html-view',
  templateUrl: './html-view.component.html',
  styleUrls: ['./html-view.component.css'],
})
export class CodeHTMLViewComponent implements OnInit {
  @Input()
  icon = 'fas fa-eye';
  @Input()
  title = 'preview';
  @Input()
  description;
  @Input()
  isPreview = true;
  @Input()
  isCode = true;

  @Input()
  content;
  @Input()
  preview;
  viewType = 'preview';
  @Input()
  style = '';
  @Input()
  isModal = true;

  onConfirm = new EventEmitter();
  editorOptions = {
    fontSize: 12,
    formatOnType: true,
    formatOnPaste: true,
    language: 'html',
    autoIndent: true,
    autoClosingBrackets: 'always',
    dragAndDrop: true,
    fixedOverflowWidgets: true,
    theme: 'vs-dark',
    readOnly: true,
  };
  editorOptionsCss = {
    fontSize: 12,
    formatOnType: true,
    formatOnPaste: true,
    language: 'css',
    autoIndent: true,
    autoClosingBrackets: 'always',
    dragAndDrop: true,
    fixedOverflowWidgets: true,
    theme: 'vs-dark',
    readOnly: true,
  };
  close = new EventEmitter();
  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.icon) {
        this.icon = data.icon;
      }
      if (data.content) {
        this.content = data.content;
      }
      if (data.preview) {
        this.preview = data.preview;
      }

      if (data.style) {
        this.style = data.style;
      }
      if (data.icon) {
        this.icon = data.icon;
      }
      if (data.title) {
        this.title = data.title;
      }
      if (data.description) {
        this.description = data.description;
      }
      if (data.isPreview != null) {
        this.isPreview = data.isPreview;
      }
      if (data.isCode != null) {
        this.isCode = data.isCode;
      }
    }
  }

  ngOnInit() {}

  onClick() {
    this.onConfirm.emit(true);
    this.close.emit(true);
  }

  isFontAwsome(icon) {
    if (icon) {
      if (icon.indexOf('fas ') !== -1 || icon.indexOf('fab-') !== -1) {
        return true;
      }

      return false;
    }

    return false;
  }
}
