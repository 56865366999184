/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./problem-modal.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../pipes/safe-html.pipe";
import * as i3 from "@angular/platform-browser";
import * as i4 from "@angular/material/dialog";
import * as i5 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i6 from "@angular/material/button";
import * as i7 from "@angular/cdk/a11y";
import * as i8 from "@angular/platform-browser/animations";
import * as i9 from "./problem-modal.component";
var styles_ProblemModalComponent = [i0.styles];
var RenderType_ProblemModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProblemModalComponent, data: {} });
export { RenderType_ProblemModalComponent as RenderType_ProblemModalComponent };
export function View_ProblemModalComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.SafeHtmlPipe, [i3.DomSanitizer]), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "rel dash intro"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["class", "animated zoomIn config-img dashboard"], ["src", "https://s3.ap-southeast-2.amazonaws.com/images.smileright.com.au/hands-up.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 7, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(4, 16384, null, 0, i4.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "row clearfix add-mb"], ["style", "margin-top: 50px; font-weight: bold"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(6, 1), (_l()(), i1.ɵeld(7, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "button", [["class", "pull-right"], ["color", "accent"], ["mat-raised-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(9, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["Close"]))], function (_ck, _v) { var currVal_3 = "accent"; _ck(_v, 9, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i1.ɵnov(_v, 0), _co.message)); _ck(_v, 5, 0, currVal_0); var currVal_1 = (i1.ɵnov(_v, 9).disabled || null); var currVal_2 = (i1.ɵnov(_v, 9)._animationMode === "NoopAnimations"); _ck(_v, 8, 0, currVal_1, currVal_2); }); }
export function View_ProblemModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ipv-config-error-modal", [], null, null, null, View_ProblemModalComponent_0, RenderType_ProblemModalComponent)), i1.ɵdid(1, 114688, null, 0, i9.ProblemModalComponent, [i4.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProblemModalComponentNgFactory = i1.ɵccf("ipv-config-error-modal", i9.ProblemModalComponent, View_ProblemModalComponent_Host_0, {}, { closeModal: "closeModal" }, []);
export { ProblemModalComponentNgFactory as ProblemModalComponentNgFactory };
