import { Component, EventEmitter, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { SessionsService } from '../../../core/session/shared/sessions.service';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';

@Component({
  selector: 'app-merchant-data-import',
  templateUrl: './merchant-data-import.component.html',
  styleUrls: ['./merchant-data-import.component.css'],
})
export class MerchantDataImportComponent implements OnInit {
  @Input()
  merchantID;

  fileID;

  close = new EventEmitter();

  isModal = true;
  type = 'standard';

  constructor(
    private sessionService: SessionsService,
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      this.type = data.type;

      this.isModal = true;
    }
  }

  ngOnInit() {}

  onComplete(e) {
    if (e && e[0]) {
      this.fileID = e[0];
    }
  }

  removeFile(e) {
    if (e && e == this.fileID) {
      this.fileID = null;
    }
  }

  save() {
    const payload = {
      fileID: this.fileID,
    };
    this.sessionService.uploadImportData(payload).subscribe((res) => {
      if (res) {
        NotifyAppComponent.displayToast('success', 'Import Data', 'You have successfully uploaded the file.');
        this.close.emit(true);
      }
    });
  }

  downloadGuide(e) {
    if (e == 1) {
      window.open(
        'https://email-graphics-smileright.s3-ap-southeast-2.amazonaws.com/docs-20/Managing%2BYour%2BPatient%2BDatabase.pdf'
      );
    } else if (e == 2) {
      window.open('https://email-graphics-smileright.s3-ap-southeast-2.amazonaws.com/docs-20/Data-Export-D4W.pdf');
    } else if (e == 3) {
      window.open(
        'https://email-graphics-smileright.s3-ap-southeast-2.amazonaws.com/docs-20/Data-Export-Practice-Works.pdf'
      );
    }
  }

  closeEvent() {
    this.close.emit(true);
  }
}
