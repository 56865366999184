<ng-container *ngIf="title">
  <h3 class="sm-h3 sr-title text-center rm-mt" *ngIf="chartData.length > 0 && title && titleIsTop == true">
    {{ title }}
  </h3>
</ng-container>
<div class="chartContainer clearfix row">
  <ngx-charts-bar-vertical
    class="text-center full-width row clearfix chart-container"
    *ngIf="_chartType == 'barVertical'"
    [gradient]="gradient"
    [view]="view"
    [roundDomains]="roundDomains"
    [scheme]="chartColorScheme"
    [results]="chartData"
    [xAxis]="showXAxis"
    [yAxis]="showYAxis"
    [showGridLines]="showGridLines"
    [legend]="showLegend"
    [showXAxisLabel]="showXAxisLabel"
    [showYAxisLabel]="showYAxisLabel"
    [xAxisLabel]="xAxisLabel"
    [yAxisLabel]="yAxisLabel"
    (select)="onSelect($event)"
  >
  </ngx-charts-bar-vertical>

  <ngx-charts-bar-horizontal
    class="text-center full-width row clearfix chart-container"
    *ngIf="_chartType == 'barHorizontal'"
    [gradient]="gradient"
    [view]="view"
    [roundDomains]="roundDomains"
    [scheme]="chartColorScheme"
    [results]="chartData"
    [xAxis]="showXAxis"
    [yAxis]="showYAxis"
    [showGridLines]="showGridLines"
    [legend]="showLegend"
    [showXAxisLabel]="showXAxisLabel"
    [showYAxisLabel]="showYAxisLabel"
    [xAxisLabel]="xAxisLabel"
    [yAxisLabel]="yAxisLabel"
    (select)="onSelect($event)"
  >
  </ngx-charts-bar-horizontal>

  <ngx-charts-bar-vertical-2d
    class="text-center full-width row clearfix chart-container"
    *ngIf="_chartType == 'barVerticalGrouped'"
    [gradient]="gradient"
    [view]="view"
    [roundDomains]="roundDomains"
    [scheme]="chartColorScheme"
    [results]="chartData"
    [xAxis]="showXAxis"
    [yAxis]="showYAxis"
    [showGridLines]="showGridLines"
    [legend]="showLegend"
    [showXAxisLabel]="showXAxisLabel"
    [showYAxisLabel]="showYAxisLabel"
    [xAxisLabel]="xAxisLabel"
    [yAxisLabel]="yAxisLabel"
    (select)="onSelect($event)"
  >
  </ngx-charts-bar-vertical-2d>

  <ngx-charts-bar-horizontal-2d
    class="text-center full-width row clearfix chart-container"
    *ngIf="_chartType == 'barHorizontalGrouped'"
    [gradient]="gradient"
    [view]="view"
    [roundDomains]="roundDomains"
    [scheme]="chartColorScheme"
    [results]="chartData"
    [xAxis]="showXAxis"
    [yAxis]="showYAxis"
    [showGridLines]="showGridLines"
    [legend]="showLegend"
    [showXAxisLabel]="showXAxisLabel"
    [showYAxisLabel]="showYAxisLabel"
    [xAxisLabel]="xAxisLabel"
    [yAxisLabel]="yAxisLabel"
    (select)="onSelect($event)"
  >
  </ngx-charts-bar-horizontal-2d>

  <ngx-charts-bar-vertical-stacked
    class="text-center full-width row clearfix chart-container"
    *ngIf="_chartType == 'barVerticalStacked'"
    [gradient]="gradient"
    [view]="view"
    [roundDomains]="roundDomains"
    [scheme]="chartColorScheme"
    [results]="chartData"
    [xAxis]="showXAxis"
    [yAxis]="showYAxis"
    [showGridLines]="showGridLines"
    [legend]="showLegend"
    [showXAxisLabel]="showXAxisLabel"
    [showYAxisLabel]="showYAxisLabel"
    [xAxisLabel]="xAxisLabel"
    [yAxisLabel]="yAxisLabel"
    (select)="onSelect($event)"
  >
  </ngx-charts-bar-vertical-stacked>

  <ngx-charts-bar-horizontal-stacked
    class="text-center full-width row clearfix chart-container"
    *ngIf="_chartType == 'barHorizontalStacked'"
    [gradient]="gradient"
    [view]="view"
    [roundDomains]="roundDomains"
    [scheme]="chartColorScheme"
    [results]="chartData"
    [xAxis]="showXAxis"
    [yAxis]="showYAxis"
    [showGridLines]="showGridLines"
    [legend]="showLegend"
    [showXAxisLabel]="showXAxisLabel"
    [showYAxisLabel]="showYAxisLabel"
    [xAxisLabel]="xAxisLabel"
    [yAxisLabel]="yAxisLabel"
    (select)="onSelect($event)"
  >
  </ngx-charts-bar-horizontal-stacked>

  <ngx-charts-bar-vertical-normalized
    class="text-center full-width row clearfix chart-container"
    *ngIf="_chartType == 'barVerticalNormalized'"
    [gradient]="gradient"
    [view]="view"
    [roundDomains]="roundDomains"
    [scheme]="chartColorScheme"
    [results]="chartData"
    [xAxis]="showXAxis"
    [yAxis]="showYAxis"
    [showGridLines]="showGridLines"
    [legend]="showLegend"
    [showXAxisLabel]="showXAxisLabel"
    [showYAxisLabel]="showYAxisLabel"
    [xAxisLabel]="xAxisLabel"
    [yAxisLabel]="yAxisLabel"
    (select)="onSelect($event)"
  >
  </ngx-charts-bar-vertical-normalized>

  <ngx-charts-bar-horizontal-normalized
    class="text-center full-width row clearfix chart-container"
    *ngIf="_chartType == 'barHorizontalNormalized'"
    [gradient]="gradient"
    [view]="view"
    [roundDomains]="roundDomains"
    [scheme]="chartColorScheme"
    [results]="chartData"
    [xAxis]="showXAxis"
    [yAxis]="showYAxis"
    [showGridLines]="showGridLines"
    [legend]="showLegend"
    [showXAxisLabel]="showXAxisLabel"
    [showYAxisLabel]="showYAxisLabel"
    [xAxisLabel]="xAxisLabel"
    [yAxisLabel]="yAxisLabel"
    (select)="onSelect($event)"
  >
  </ngx-charts-bar-horizontal-normalized>

  <ngx-charts-pie-chart
    class="text-center full-width row clearfix chart-container"
    *ngIf="_chartType == 'pie'"
    [labels]="showLabel"
    [scheme]="chartColorScheme"
    [gradient]="gradient"
    [results]="chartData"
    [legend]="showLegend"
    [legendPosition]="legendPosition"
    [doughnut]="showDoughnut"
    [customColors]="customColorScheme"
    (select)="onSelect($event)"
  >
  </ngx-charts-pie-chart>

  <!-- custom colours -->
  <ngx-charts-pie-chart
    class="text-center full-width row clearfix chart-container"
    *ngIf="_chartType == 'pieColor'"
    [labels]="showLabel"
    [scheme]="chartColorScheme"
    [customColors]="schemeCustom"
    [gradient]="gradient"
    [results]="chartData"
    [legend]="showLegend"
    [legendPosition]="legendPosition"
    [doughnut]="showDoughnut"
    [customColors]="chartColorStandard"
    (select)="onSelect($event)"
  >
  </ngx-charts-pie-chart>

  <ngx-charts-pie-chart
    class="text-center full-width row clearfix chart-container"
    *ngIf="_chartType == 'pieZero'"
    [labels]="showLabel"
    [scheme]="chartColorScheme"
    [gradient]="gradient"
    [results]="chartData"
    [legend]="showLegend"
    [doughnut]="showDoughnut"
    [customColors]="customColorScheme"
    (select)="onSelect($event)"
    pie-zero
  >
  </ngx-charts-pie-chart>

  <ngx-charts-advanced-pie-chart
    class="text-center full-width row clearfix chart-container"
    *ngIf="_chartType == 'pieAdvanced'"
    [gradient]="gradient"
    [view]="view"
    [scheme]="chartColorScheme"
    [results]="chartData"
    (select)="onSelect($event)"
  >
  </ngx-charts-advanced-pie-chart>

  <ngx-charts-pie-grid
    class="text-center full-width row clearfix chart-container"
    *ngIf="_chartType == 'pieGrid'"
    [scheme]="chartColorScheme"
    [results]="chartData"
    (select)="onSelect($event)"
  >
  </ngx-charts-pie-grid>

  <ngx-charts-line-chart
    class="text-center full-width row clearfix chart-container"
    *ngIf="_chartType == 'line'"
    [gradient]="gradient"
    [view]="view"
    [roundDomains]="roundDomains"
    [scheme]="chartColorScheme"
    [results]="chartData"
    [xAxis]="showXAxis"
    [yAxis]="showYAxis"
    [showGridLines]="showGridLines"
    [legend]="showLegend"
    [showXAxisLabel]="showXAxisLabel"
    [showYAxisLabel]="showYAxisLabel"
    [xAxisLabel]="xAxisLabel"
    [yAxisLabel]="yAxisLabel"
    [timeline]="showTimeline"
    [showRefLines]="showRefLines"
    [showRefLabels]="showRefLabels"
    [autoScale]="autoScale"
    [trimXAxisTicks]="trimXAxisTicks"
    [legendPosition]="legendPosition"
    (select)="onSelect($event)"
    hideXAxisTicks
  >
  </ngx-charts-line-chart>

  <ngx-charts-area-chart
    class="text-center full-width row clearfix chart-container"
    *ngIf="_chartType == 'area'"
    [gradient]="gradient"
    [view]="view"
    [scheme]="chartColorScheme"
    [results]="chartData"
    [xAxis]="showXAxis"
    [yAxis]="showYAxis"
    [showGridLines]="showGridLines"
    [legend]="showLegend"
    [showXAxisLabel]="showXAxisLabel"
    [showYAxisLabel]="showYAxisLabel"
    [xAxisLabel]="xAxisLabel"
    [yAxisLabel]="yAxisLabel"
    [timeline]="showTimeline"
    [autoScale]="autoScale"
    (select)="onSelect($event)"
  >
  </ngx-charts-area-chart>

  <!-- double y axis chart - plot second line graph on same chart -->
  <!-- <app-double-axis-chart *ngIf="_chartType=='daxis'" 
      [view]="view"
      [scheme]="chartColorScheme"
      [results]="chartData"
      [gradient]="gradient"
      [xAxis]="showXAxis"
      [yAxis]="showYAxis"
      [legend]="showLegend"
      [showXAxisLabel]="showXAxisLabel"
      [yMainAxisShowLabel]="showYAxisLabel"
      [ySecondaryAxisShowLabel]="showYAxisLabel"
      [xAxisLabel]="xAxisLabel"
      [yMainAxisLabel]="yAxisLabel"
      [ySecondaryAxisLabel]="yAxisLabel2"
      [autoScale]="autoScale"
      [timeline]="showTimeline"
      [tooltipDisabled]="tooltipDisabled"
      [animations]="animations"
      (select)="onSelect($event)"> -->
  <!-- </app-double-axis-chart> -->

  <!-- combination bar and line chart -->
  <!-- <combo-chart-component
   [view]="view"
   [scheme]="comboBarScheme"
   [colorSchemeLine]="lineChartScheme"
   [results]="barChart"
   [animations]="animations"
   [lineChart]="lineChartSeries"
   [yAxisTickFormatting]="yLeftTickFormat"
   [yLeftAxisScaleFactor]="yLeftAxisScale"
   [yRightAxisScaleFactor]="yRightAxisScale"
   [yRightAxisTickFormatting]="yRightTickFormat"
   [gradient]="gradient"
   [xAxis]="showXAxis"
   [yAxis]="showYAxis"
   [legend]="showLegend"
   [legendTitle]="legendTitle"
   [legendPosition]="legendPosition"
   [showGridLines]="showGridLines"
   [showXAxisLabel]="showXAxisLabel"
   [showYAxisLabel]="showYAxisLabel"
   [showRightYAxisLabel]="showYAxisLabel"
   [xAxisLabel]="xAxisLabel"
   [yAxisLabel]="yAxisLabel"
   [yAxisLabelRight]="yAxisLabelRight">
   </combo-chart-component> -->
</div>
<ng-container *ngIf="title && titleIsTop != true">
  <h3 class="sm-h3 sr-title text-center rm-mt" *ngIf="chartData.length > 0 && title && titleIsTop != true">
    {{ title }}
  </h3>
</ng-container>
