import { animate, animateChild, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatButtonToggleGroup } from '@angular/material/button-toggle';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { uniq, uniqBy } from 'lodash';
import { ConfirmDialogMessageComponent } from '../../../shared/components/confirm-dialog-message/confirm-dialog-message.component';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { UtilsService } from '../../../shared/services/utils.service';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { AuthenticationService } from '../../authentication/shared/authentication.service';
import { TemplateReviewComponent } from '../template-review/template-review.component';
import { TemplateSocialMediaViewComponent } from '../template-social-media-view/template-social-media-view.component';

@Component({
  selector: 'app-media-gallery-templates-list',
  templateUrl: './media-gallery-templates-list.component.html',
  styleUrls: ['./media-gallery-templates-list.component.css'],
  animations: [
    trigger('fade', [transition(':enter', [style({ opacity: 0 }), animate('.1s ease')])]),
    trigger('stagger', [transition(':enter', [query(':enter', stagger('.1s', [animateChild()]), { optional: true })])]),
  ],
})
export class MediaGalleryTemplatesListComponent implements OnInit {
  tags = new FormControl();

  @ViewChild(MatButtonToggleGroup, { static: false })
  public toggleGroup: MatButtonToggleGroup;

  @Input()
  isBusiness = false;

  @Input()
  isBusinessOrDefault = true;

  @Input()
  displayHeader = true;

  @Input()
  icon = 'fas fa-bullhorn';
  @Input()
  isMerchantTemplate;
  @Input()
  isManualCreatedOrDefault = true;

  @Input()
  isCampaign = true;

  @Input()
  isDefault = true;

  @Input()
  isActive = true;

  @Input()
  isForMerchant = false;

  @Input()
  title = 'Templates';

  @Input()
  isAction = false;

  @Input()
  isManualCreated = false;

  @Input()
  social = false;

  @Input()
  description;

  @Input()
  actionLabel = 'Use this Template';

  @Input()
  actionIcon = 'assignment';

  @Input()
  emailLabel = 'Preview email';

  @Input()
  smsLabel = 'Preview sms';

  @Output()
  selectTemplate = new EventEmitter();

  @Output()
  actionProceed = new EventEmitter();

  selectedTagList: any = [];
  tagList = [];

  mustSupportSms;

  @Input()
  mode = 'list';

  @Input()
  isToggle = true;

  templates = [];

  tagsList = [];

  @Input()
  merchantID;

  @Input()
  isPrint = false;

  @Input()
  isProceed = false;

  @Input()
  isDownload = false;

  @Input()
  isMerchantCampaign = false;

  @Input()
  isPatientCampaign = false;

  util = new UtilsClass();

  selectedTemplate;
  selectedPreview;
  imageUrl: 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/profile-placeholder.png';

  templateIDPreview;

  preview = 'email';

  @Input()
  selectedTemplateID;

  @Input()
  previewEmailButton = true;

  @Input()
  previewSMSButton = true;

  @Input()
  previewLandingPageButton = true;

  @Input()
  editTemplateButton = true;

  @Input()
  editSocialMediaButton = false;

  @Input()
  viewSocialMediaButton = false;

  @Input()
  isIncludeSMSCheckbox = true;

  @Input()
  sendTestEmail = true;

  @Input()
  defaultTemplates = [];

  orderByFilter = '-DateTimeCreated';

  tabs = [
    {
      with: '*',
      without: '*',
      label: 'All',
    },

    {
      with: 'merchant-prospect',
      without: '*',
      label: 'MerchantProspect',
    },
    {
      with: 'promoter',
      without: '*',
      label: 'Promoter',
    },

    {
      with: 'merchant',
      without: '*',
      label: 'Merchant',
    },
    {
      with: 'prospect',
      without: 'merchant-prospect',
      label: 'Prospect',
    },
    {
      with: 'customer',
      without: '*',
      label: 'Customer',
    },
    {
      with: 'securityCode',
      without: '*',
      label: 'Security code',
    },
    {
      with: 'campaign',
      without: '*',
      label: 'Campaign',
    },
    {
      with: 'operator',
      without: '*',
      label: 'Operator',
    },
  ];

  tab = this.tabs[0];

  emailFilter = '*';

  smsFilter = '*';
  @Output()
  closeModal = new EventEmitter();

  settings = Settings.global;

  finishLoading = false;

  filter;
  serviceRef;
  isAdminOrPromoter = false;

  isMobile = false;

  socialMediaType = '';
  currentUser;
  currentPractice;
  section = 1;
  limit = 50;
  displayLoadMore = false;

  getTabLabel(t) {
    if (t == 'promoter') {
      return 'Promoter';
    } else if (t == 'merchant-prospect') {
      return 'Merchant prospect';
    } else if (t == 'merchant') {
      return 'Merchant';
    } else if (t == 'consumer') {
      return 'Consumer';
    } else if (t == 'securityCode') {
      return 'security code';
    } else if (t == 'campaign') {
      return 'Campaign';
    } else if (t == 'operator') {
      return 'Operator';
    }
  }

  constructor(
    private router: Router,
    private utilService: UtilsService,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit() {
    this.authenticationService.getCurrentPractice().subscribe((currentPractice) => {
      this.currentPractice = currentPractice;
      this.authenticationService.getCurrentUser().subscribe((u) => {
        if (u && u.data) {
          this.currentUser = u.data;
        }

        if (window.matchMedia('(min-width: 992px)').matches) {
          this.isMobile = false;
        } else {
          this.isMobile = true;
        }

        this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
          this.isAdminOrPromoter = res;

          if (this.isBusiness == false) {
            if (this.isMerchantCampaign == true) {
              this.tab = {
                with: 'merchant',
                without: '*',
                label: 'Merchant',
              };
            } else if (this.isPatientCampaign == true) {
              this.tab = {
                with: 'M-customer',
                without: '*',
                label: 'Customer',
              };
            }

            this.tabs = [
              {
                with: '*',
                without: '*',
                label: 'All',
              },

              {
                with: 'merchant-prospect',
                without: '*',
                label: 'MerchantProspect',
              },
              {
                with: 'promoter',
                without: '*',
                label: 'Promoter',
              },

              {
                with: 'merchant',
                without: '*',
                label: 'Merchant',
              },
              {
                with: 'M-Prospect',
                without: 'merchant-prospect',
                label: 'Prospect',
              },
              {
                with: 'M-customer',
                without: '*',
                label: 'Customer',
              },
            ];
          }

          this.section = 1;
          this.templates = [];

          if (this.isForMerchant == true) {
            this.setupMerchantTemplate();
          } else {
            this.setup();
          }
        });
      });
      // this.serviceRef = this.utilService.getTemplateList().subscribe(res => {
      //   console.log(res);
      // })
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      (changes.merchantID && changes.merchantID.previousValue && !changes.merchantID.currentValue) ||
      (changes.isBusinessOrDefault &&
        !changes.isBusinessOrDefault.previousValue &&
        changes.isBusinessOrDefault.currentValue) ||
      (changes.isManualCreatedOrDefault &&
        !changes.isManualCreatedOrDefault.previousValue &&
        changes.isManualCreatedOrDefault.currentValue) ||
      (changes.isCampaign && !changes.isCampaign.previousValue && changes.isCampaign.currentValue) ||
      (changes.isForMerchant && !changes.isForMerchant.previousValue && changes.isForMerchant.currentValue) ||
      (changes.isMerchantTemplate &&
        !changes.isMerchantTemplate.previousValue &&
        changes.isMerchantTemplate.currentValue) ||
      (changes.isDefault && !changes.isDefault.previousValue && changes.isDefault.currentValue) ||
      (changes.isManualCreated && !changes.isManualCreated.previousValue && changes.isManualCreated.currentValue) ||
      (changes.defaultTemplates && !changes.defaultTemplates.previousValue && changes.defaultTemplates.currentValue)
    ) {
      this.section = 1;
      this.templates = [];
      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isAdminOrPromoter = res;
        if (this.isForMerchant == true) {
          this.setupMerchantTemplate();
        } else {
          this.setup();
        }
      });
    } else if (
      changes.defaultTemplates &&
      changes.defaultTemplates.previousValue != changes.defaultTemplates.currentValue
    ) {
      this.section = 1;
      this.templates = [];
      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isAdminOrPromoter = res;
        if (this.isForMerchant == true) {
          this.setupMerchantTemplate();
        } else {
          this.setup();
        }
      });
    }
  }

  supportEmail(value) {
    const e = value.checked;
    if (e && e == true) {
      this.emailFilter = '1';
    } else {
      this.emailFilter = '*';
    }
  }

  supportSms(value) {
    const e = value.checked;

    if (e && e == true) {
      this.smsFilter = '1';
    } else {
      this.smsFilter = '*';
    }
  }

  setupMerchantTemplate() {
    if (this.defaultTemplates && this.defaultTemplates.length > 0) {
      for (let i = 0; i < this.defaultTemplates.length; i++) {
        if (this.selectedTemplateID && this.selectedTemplateID == this.defaultTemplates[i]['ID']) {
          this.selectedTemplate = this.defaultTemplates[i];
          this.templateIDPreview = this.selectedTemplate['ID'];
        }

        this.defaultTemplates[i]['socialMediaPost'] = '';
        if (this.defaultTemplates && this.defaultTemplates[i] && this.defaultTemplates[i]['FacebookPost_Key']) {
          this.defaultTemplates[i]['socialMediaPost'] = this.defaultTemplates[i]['socialMediaPost'] + '|facebook';
        }
        if (this.defaultTemplates && this.defaultTemplates[i] && this.defaultTemplates[i]['TwitterPost_Key']) {
          this.defaultTemplates[i]['socialMediaPost'] = this.defaultTemplates[i]['socialMediaPost'] + '|twitter';
        }
        if (this.defaultTemplates && this.defaultTemplates[i] && this.defaultTemplates[i]['LinkedInPost_Key']) {
          this.defaultTemplates[i]['socialMediaPost'] = this.defaultTemplates[i]['socialMediaPost'] + '|linkedin';
        }
        if (this.defaultTemplates && this.defaultTemplates[i] && this.defaultTemplates[i]['InstagramPost_Key']) {
          this.defaultTemplates[i]['socialMediaPost'] = this.defaultTemplates[i]['socialMediaPost'] + '|instagram';
        }

        if (this.defaultTemplates[i]['Tag.Label'] && this.defaultTemplates[i]['StaticList.Label']) {
          this.defaultTemplates[i]['StaticList.Label'] =
            this.defaultTemplates[i]['Tag.Label'] + '|' + this.defaultTemplates[i]['StaticList.Label'];
        } else if (this.defaultTemplates[i]['Tag.Label']) {
          this.defaultTemplates[i]['StaticList.Label'] = this.defaultTemplates[i]['Tag.Label'];
        }

        if (this.defaultTemplates && this.defaultTemplates[i] && this.defaultTemplates[i]['StaticList.Label']) {
          this.defaultTemplates[i]['StaticList.Label'] = this.defaultTemplates[i]['StaticList.Label'].split('|');

          this.tagsList = this.tagsList.concat(this.defaultTemplates[i]['StaticList.Label']);
          this.tagsList = uniq(this.tagsList);
          this.tagsList = JSON.parse(JSON.stringify(this.tagsList));
        } else {
          this.defaultTemplates[i]['StaticList.Label'] = [];
        }
      }

      this.templates = uniqBy(this.templates.concat(this.defaultTemplates), 'ID');
      this.finishLoading = true;
    } else {
      const payload = {
        section: this.section,

        limit: this.limit,
        isBusiness: this.isBusiness,
        isBusinessOrDefault: this.isBusinessOrDefault,
        isManualCreatedOrDefault: this.isManualCreatedOrDefault,
        isMerchantTemplate: this.isMerchantTemplate,
        isManualCreated: this.isManualCreated,
        isDefault: this.isDefault,
        isActive: this.isActive,
        isCampaign: this.isCampaign,
      };
      let _isAdminOrPromoter = this.isAdminOrPromoter;
      if (this.merchantID) {
        _isAdminOrPromoter = false;
      }
      this.utilService.getTemplateMerchantList(payload, _isAdminOrPromoter).subscribe((res) => {
        if (res) {
          if (res && res.length >= this.limit) {
            this.displayLoadMore = true;
          } else {
            this.displayLoadMore = false;
          }

          for (let i = 0; i < res.length; i++) {
            if (this.selectedTemplateID && this.selectedTemplateID == res[i]['ID']) {
              this.selectedTemplate = res[i];
              this.templateIDPreview = this.selectedTemplate['ID'];
            }

            res[i]['socialMediaPost'] = '';
            if (res && res[i] && res[i]['FacebookPost_Key']) {
              res[i]['socialMediaPost'] = res[i]['socialMediaPost'] + '|facebook';
            }
            if (res && res[i] && res[i]['TwitterPost_Key']) {
              res[i]['socialMediaPost'] = res[i]['socialMediaPost'] + '|twitter';
            }
            if (res && res[i] && res[i]['LinkedInPost_Key']) {
              res[i]['socialMediaPost'] = res[i]['socialMediaPost'] + '|linkedin';
            }
            if (res && res[i] && res[i]['InstagramPost_Key']) {
              res[i]['socialMediaPost'] = res[i]['socialMediaPost'] + '|instagram';
            }

            if (res[i]['Tag.Label'] && res[i]['StaticList.Label']) {
              res[i]['StaticList.Label'] = res[i]['Tag.Label'] + '|' + res[i]['StaticList.Label'];
            } else if (res[i]['Tag.Label']) {
              res[i]['StaticList.Label'] = res[i]['Tag.Label'];
            }
            if (res && res[i] && res[i]['StaticList.Label']) {
              res[i]['StaticList.Label'] = res[i]['StaticList.Label'].split('|');
              this.tagsList = this.tagsList.concat(res[i]['StaticList.Label']);
              this.tagsList = uniq(this.tagsList);
              this.tagsList = JSON.parse(JSON.stringify(this.tagsList));
            } else {
              res[i]['StaticList.Label'] = [];
            }
          }

          this.templates = uniqBy(this.templates.concat(res), 'ID');
        }
        this.finishLoading = true;
      });
    }
  }

  setup() {
    if (this.defaultTemplates && this.defaultTemplates.length > 0) {
      for (let i = 0; i < this.defaultTemplates.length; i++) {
        if (this.selectedTemplateID && this.selectedTemplateID == this.defaultTemplates[i]['ID']) {
          this.selectedTemplate = this.defaultTemplates[i];
          this.templateIDPreview = this.selectedTemplate['ID'];
        }

        this.defaultTemplates[i]['socialMediaPost'] = '';
        if (this.defaultTemplates && this.defaultTemplates[i] && this.defaultTemplates[i]['FacebookPost_Key']) {
          this.defaultTemplates[i]['socialMediaPost'] = this.defaultTemplates[i]['socialMediaPost'] + '|facebook';
        }
        if (this.defaultTemplates && this.defaultTemplates[i] && this.defaultTemplates[i]['TwitterPost_Key']) {
          this.defaultTemplates[i]['socialMediaPost'] = this.defaultTemplates[i]['socialMediaPost'] + '|twitter';
        }
        if (this.defaultTemplates && this.defaultTemplates[i] && this.defaultTemplates[i]['LinkedInPost_Key']) {
          this.defaultTemplates[i]['socialMediaPost'] = this.defaultTemplates[i]['socialMediaPost'] + '|linkedin';
        }
        if (this.defaultTemplates && this.defaultTemplates[i] && this.defaultTemplates[i]['InstagramPost_Key']) {
          this.defaultTemplates[i]['socialMediaPost'] = this.defaultTemplates[i]['socialMediaPost'] + '|instagram';
        }
        if (this.defaultTemplates[i]['Tag.Label'] && this.defaultTemplates[i]['StaticList.Label']) {
          this.defaultTemplates[i]['StaticList.Label'] =
            this.defaultTemplates[i]['Tag.Label'] + '|' + this.defaultTemplates[i]['StaticList.Label'];
        } else if (this.defaultTemplates[i]['Tag.Label']) {
          this.defaultTemplates[i]['StaticList.Label'] = this.defaultTemplates[i]['Tag.Label'];
        }

        if (this.defaultTemplates && this.defaultTemplates[i] && this.defaultTemplates[i]['StaticList.Label']) {
          this.defaultTemplates[i]['StaticList.Label'] = this.defaultTemplates[i]['StaticList.Label'].split('|');
          this.tagsList = this.tagsList.concat(this.defaultTemplates[i]['StaticList.Label']);
          this.tagsList = uniq(this.tagsList);
          this.tagsList = JSON.parse(JSON.stringify(this.tagsList));
        } else {
          this.defaultTemplates[i]['StaticList.Label'] = [];
        }
      }

      this.templates = uniqBy(this.templates.concat(this.defaultTemplates), 'ID');
      this.finishLoading = true;
    } else {
      const payload = {
        section: this.section,

        limit: this.limit,
        isBusiness: this.isBusiness,
        isBusinessOrDefault: this.isBusinessOrDefault,
        isManualCreatedOrDefault: this.isManualCreatedOrDefault,
        isManualCreated: this.isManualCreated,
        isActive: this.isActive,
        isDefault: this.isDefault,
        isCampaign: this.isCampaign,
        isMerchantTemplate: this.isMerchantTemplate,
      };
      let _isAdminOrPromoter = this.isAdminOrPromoter;
      if (this.merchantID) {
        _isAdminOrPromoter = false;
      }
      this.utilService.getTemplateList(payload, _isAdminOrPromoter).subscribe((res) => {
        if (res) {
          if (res && res.length >= this.limit) {
            this.displayLoadMore = true;
          } else {
            this.displayLoadMore = false;
          }

          for (let i = 0; i < res.length; i++) {
            if (this.selectedTemplateID && this.selectedTemplateID == res[i]['ID']) {
              this.selectedTemplate = res[i];
              this.templateIDPreview = this.selectedTemplate['ID'];
            }

            res[i]['socialMediaPost'] = '';
            if (res && res[i] && res[i]['FacebookPost_Key']) {
              res[i]['socialMediaPost'] = res[i]['socialMediaPost'] + '|facebook';
            }
            if (res && res[i] && res[i]['TwitterPost_Key']) {
              res[i]['socialMediaPost'] = res[i]['socialMediaPost'] + '|twitter';
            }
            if (res && res[i] && res[i]['LinkedInPost_Key']) {
              res[i]['socialMediaPost'] = res[i]['socialMediaPost'] + '|linkedin';
            }
            if (res && res[i] && res[i]['InstagramPost_Key']) {
              res[i]['socialMediaPost'] = res[i]['socialMediaPost'] + '|instagram';
            }

            if (res[i]['Tag.Label'] && res[i]['StaticList.Label']) {
              res[i]['StaticList.Label'] = res[i]['Tag.Label'] + '|' + res[i]['StaticList.Label'];
            } else if (res[i]['Tag.Label']) {
              res[i]['StaticList.Label'] = res[i]['Tag.Label'];
            }
            if (res && res[i] && res[i]['StaticList.Label']) {
              res[i]['StaticList.Label'] = res[i]['StaticList.Label'].split('|');
              this.tagsList = this.tagsList.concat(res[i]['StaticList.Label']);
              this.tagsList = uniq(this.tagsList);
              this.tagsList = JSON.parse(JSON.stringify(this.tagsList));
            } else {
              res[i]['StaticList.Label'] = [];
            }
          }

          this.templates = uniqBy(this.templates.concat(res), 'ID');
        }

        this.finishLoading = true;
      });
    }
  }

  getSelectedTags(e) {
    if (e && e.length > 0) {
      this.selectedTagList = e;
    } else {
      this.selectedTagList = [];
    }
  }

  getYesLabel(i) {
    if (i && i == '1') {
      return 'Yes';
    } else if (i && i == '0') {
      return 'No';
    } else {
      return 'No';
    }
  }

  previewTemplate(t) {
    if (t && t['Tag'] && t['ID']) {
      this.selectTemplate.emit(t);
      this.selectedTemplate = t;

      this.templateIDPreview = this.selectedTemplate['ID'];
    }
  }

  viewSocialMediaPosts(templateTag) {
    if (templateTag) {
      const ref = RootAppComponent.dialog.open(TemplateSocialMediaViewComponent, {
        data: {
          templateTag,
        },
        width: '800px',
        panelClass: 'noCard',
      });

      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    }
  }

  actionEvent(t) {
    if (t) {
      if (this.socialMediaType) {
        t.socialMediaType = this.socialMediaType;
      }
      this.selectedTemplate = t;
      this.selectTemplate.emit(t);
      this.actionProceed.emit(t);
      this.closeModal.emit(true);
    }
  }

  getTemplateImg(i) {
    if (this.templates[i] && this.templates[i]['ID'] && !this.templates[i]['updated']) {
      this.templates[i]['loading'] = true;

      this.utilService.getTemplateImage(this.templates[i]['ID']).subscribe((res) => {
        this.templates[i]['loading'] = false;

        if (res) {
          if (res['Thumbnail.Pic']) {
            this.templates[i]['pic'] = {
              Extension: 'png',
              PhysicalFileInBLOB: res['Thumbnail.Pic'],
            };
          } else {
            this.templates[i]['pic'] = null;
          }
          this.templates[i]['updated'] = true;
        } else {
          this.templates[i]['updated'] = true;
        }
      });
    }
  }

  getImageLink(id, LastModified) {
    return this.utilService.getTemplateImageStreamLink(id, LastModified);
  }

  getTags(t) {
    let result = [];
    if (t && t['Tag']) {
      result = t['Tag'].split('-');
    }

    return result;
  }

  previewTemplateModal(t, preview: any) {
    if (t && t['ID']) {
      this.selectedTemplate = t;

      const ref = RootAppComponent.dialog.open(TemplateReviewComponent, {
        data: {
          templateID: t['ID'],
          isPrint: this.isPrint,
          isProceed: this.isProceed,
          isDownload: this.isDownload,
          proceedLabel: this.actionLabel,
          previewInit: preview,
        },
        width: '700px',
      });

      ref.componentInstance.closeModal.subscribe((res) => {
        ref.close();
      });

      ref.componentInstance.proceed.subscribe((res) => {
        if (res) {
          ref.close();
          this.actionEvent(res);
        }
      });
    }
  }

  openLandingPage(t) {
    if (t && t['LandingPageURL']) {
      if (
        t['LandingPageURL'].indexOf('{{SITE_URL_Public}}') !== -1 ||
        t['LandingPageURL'].indexOf('{{SITE_URL_MERCHANT}}') !== -1
      ) {
        let nodeServer = environment.nodeUrl;
        if (nodeServer && nodeServer.indexOf('localhost') != -1) {
          nodeServer = 'https://sandbox.smileright.com.au/api';
        }

        let link = t['LandingPageURL'];

        link = link.replace('{{MERCHANT_CardKey}}', this.currentPractice.ID);
        link = link.replace('{{MERCHANT_TerminalCode}}', this.currentPractice.Terminal_Code);
        link = link.replace('{{SITE_URL_Public}}', this.settings['publicSiteFrontendLink']);

        link = link.replace('{{SITE_URL_MERCHANT}}/api', nodeServer);
        window.open(link, '_blank');
      } else {
        const url = t['LandingPageURL'];
        window.open(url, '_blank');
      }
    } else if (t && t['LandingPageCode']) {
      const url = `${this.settings['publicSiteFrontendLink']}/product/(page:main/${t['LandingPageCode']})`;

      window.open(url, '_blank');
    }
  }

  Edit(t) {
    if (t && t.ID) {
      this.router.navigate(['/merchant', { outlets: { page: ['template-edit', t.ID] } }]);
    }
  }

  EditSocialMedia(t) {
    if (t && t.ID) {
      this.router.navigate([
        '/merchant',
        {
          outlets: {
            page: ['marketing-social-media-template-edit', t.ID],
          },
        },
      ]);
    }
  }

  ngOnDestroy() {
    if (this.serviceRef) {
      this.serviceRef.unsubscribe();
    }
  }

  loadMore() {
    this.section = this.section + 1;
    if (this.isForMerchant == true) {
      this.setupMerchantTemplate();
    } else {
      this.setup();
    }
  }

  testCampaign(tag) {
    if (tag) {
      const title = 'Send Test Campaign';
      const description = `<p>To send a test Campaign, we will enable the marketing consent for you. </p>`;

      let subTitle;
      if (this.currentUser['emails.Email']) {
        subTitle = ` The message will be sent to: ${this.currentUser['emails.Email']}`;
      }

      this.utilService.getTemplateTag(tag, { fields: 'MetaData' }).subscribe((template) => {
        if (template) {
          if (template && template['MetaData'] && template['MetaData']['Amount']) {
            let label = 'Total Treatment Price';

            if (template['MetaData']['Amount']['Label']) {
              label = template['MetaData']['Amount']['Label'];
            } else {
              label = 'Treatment value';
            }

            const ref = RootAppComponent.dialog.open(ConfirmDialogMessageComponent, {
              data: {
                icon: 'message',
                title,
                yesLabel: 'Send',
                noLabel: 'Close',
                description,
                subTitle,
                isDateTimeInput: false,
                isDateInput: false,
                isTextArea: false,
                isTextInput: false,
                isPolicy: false,
                isCheckbox: false,
                displayNo: true,
                amount: 5000,
                amountLabel: label,
                isAmountInput: true,
                isAmountInputRequired: true,
              },
              width: '565px',
            });
            ref.componentInstance.closeModal.subscribe((res) => {
              ref.close();
            });
            ref.componentInstance.onConfirm.subscribe((res) => {
              if (res && res.confirm == true) {
                const p = {
                  amount: res.amount,
                };
                this.utilService.testTemplate(tag, p).subscribe((ress) => {
                  if (ress) {
                    ref.close();
                    NotifyAppComponent.displayToast(
                      'success',
                      'Successful Operation',
                      'You have successfully sent a test campaign to yourself, please check your email.'
                    );
                  }
                });
              }
            });
          } else {
            const title = 'Send Test Campaign';
            let description = `<p>To send a test Campaign, we will enable the marketing consent for you.<br><br>`;

            if (this.currentUser['emails.Email']) {
              description =
                description + ` The message will be sent to:  <strong>${this.currentUser['emails.Email']}</strong></p>`;
            } else {
              description = description + `</p>`;
            }
            const confirm = new ConfirmDialog('fas fa-info', title, description, 'No', 'Yes');

            const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
              data: confirm,
              width: '650px',
            });

            ref.componentInstance.onConfirm.subscribe((res) => {
              if (res == false) {
                ref.close();
              } else {
                this.utilService.testTemplate(tag).subscribe((res) => {
                  if (res) {
                    ref.close();
                    NotifyAppComponent.displayToast(
                      'success',
                      'Successful Operation',
                      'You have successfully sent a test campaign to yourself, please check your email.'
                    );
                  }
                });
              }
            });
          }
        }
      });
    }
  }
}
