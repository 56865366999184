import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { PatientIntroModalComponent } from '../patient-intro-modal/patient-intro-modal.component';

@Component({
  selector: 'app-patient-overview',
  templateUrl: './patient-overview.component.html',
  styleUrls: ['./patient-overview.component.css'],
})
export class PatientOverviewComponent implements OnInit {
  @Input() patient = {};

  constructor(
    private dialog: MatDialog,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      this.patient = data;
    }
  }

  ngOnInit() {
    const ref = this.dialog.open(PatientIntroModalComponent, {
      width: '800px',
      panelClass: 'noCard',
    });
    if (ref.componentInstance.showOnPatientFirstVisit == true) {
      ref.close();
    } else {
      const sub = ref.componentInstance.closeModal.subscribe((data) => {
        ref.close();
      });
    }
  }
}
