<app-ep-document-view-image
  class="full-width landing-page-header-pic"
  *ngIf="headerImage && headerImage != 'none' && headerImage != 'merchant'"
  [ngClass]="getCircle(headerImage) == true ? 'round-circle' : ''"
  [imageLink]="headerImage"
  [hideZoomButton]="hideZoomButton"
>
</app-ep-document-view-image>

<app-ep-document-view-image
  class="full-width landing-page-header-pic"
  *ngIf="headerImage && headerImage == 'none'"
  [imageLink]="'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/no-image.jpg'"
  [hideZoomButton]="hideZoomButton"
>
</app-ep-document-view-image>

<app-ep-document-view-image
  class="full-width landing-page-header-pic"
  *ngIf="!headerImage && settings && settings['context'] != 'breeze' && settings['context'] != 'p4p'"
  [imageLink]="
    'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/public/landing-page-header/Asset_6.png?type=b'
  "
  [hideZoomButton]="hideZoomButton"
>
</app-ep-document-view-image>

<app-ep-document-view-image
  class="full-width landing-page-header-pic"
  *ngIf="!headerImage && settings && settings['context'] == 'breeze'"
  [imageLink]="'https://breezelivingwebsite.s3.ap-southeast-2.amazonaws.com/Images/MSSS-3-600x600.jpg?type=c'"
  [hideZoomButton]="hideZoomButton"
>
</app-ep-document-view-image>

<app-ep-document-view-image
  class="full-width landing-page-header-pic"
  *ngIf="!headerImage && settings && settings['context'] == 'p4p'"
  [imageLink]="
    'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/duke-contact+-+Copy.png?type=b'
  "
  [hideZoomButton]="hideZoomButton"
>
</app-ep-document-view-image>

<app-ep-document-view-image
  class="full-width landing-page-header-pic round-circle"
  *ngIf="headerImage && nodeServer && headerImage == 'merchant' && merchantID"
  [imageLink]="nodeServer + '/merchant/practice-picture-stream/' + merchantID"
  [hideZoomButton]="hideZoomButton"
>
</app-ep-document-view-image>
