import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'price-service-edit',
  templateUrl: './price-service-edit.component.html',
  styleUrls: ['./price-service-edit.component.css'],
})
export class PriceServiceEditComponent implements OnInit {
  // @Input()
  // priceServiceID;

  @Output()
  close = new EventEmitter();

  @Output()
  saveObject = new EventEmitter();

  // priceService = null;

  // constructor(
  //   private dialog: MatDialog,
  //   private priceServiceService: PriceServiceService,
  //   @Optional()
  //   @Inject(MAT_DIALOG_DATA)
  //   public data: any) {
  //   if (data) {
  //     this.priceServiceID = data;
  //   }
  // }

  constructor() {}

  ngOnInit() {
    // this.priceServiceService.getPriceServiceDetails(this.priceServiceID).subscribe(res => {
    //   if (res) {
    //     this.priceService = res;
    //   }
    // })
  }

  // edit() {
  //   let payLoad = {

  //     priceCost: this.priceService['Price.Effective.Cost'],
  //     priceSale: this.priceService['Price.Effective.Sale'],
  //     descriptionDiagnosis: "test",
  //     descriptionTreatment: "test",

  //   }
  //   this.priceServiceService.modifyPriceService(this.priceServiceID, payLoad).subscribe(res => {

  //     if (res) {
  //       this.saveObject.emit(res);
  //     }

  //     this.close.emit(true);
  //   })
  // }
}
