/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dental-view-product.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./dental-view-product.component";
var styles_DentalViewProductComponent = [i0.styles];
var RenderType_DentalViewProductComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DentalViewProductComponent, data: {} });
export { RenderType_DentalViewProductComponent as RenderType_DentalViewProductComponent };
export function View_DentalViewProductComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["dental-view-product works!"]))], null, null); }
export function View_DentalViewProductComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ipv-dental-view-product", [], null, null, null, View_DentalViewProductComponent_0, RenderType_DentalViewProductComponent)), i1.ɵdid(1, 114688, null, 0, i2.DentalViewProductComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DentalViewProductComponentNgFactory = i1.ɵccf("ipv-dental-view-product", i2.DentalViewProductComponent, View_DentalViewProductComponent_Host_0, { customerAppliance: "customerAppliance" }, {}, []);
export { DentalViewProductComponentNgFactory as DentalViewProductComponentNgFactory };
