<div class="row clearfix patient-edit" *ngIf="code && label && description">
  <form #editForm="ngForm">
    <div class="card-header primary-gradient-img clearfix inModal">
      <div class="row clearfix">
        <button class="btn-close btn-clear mat-button white" *ngIf="isModal == true" (click)="closeEvent()">
          <mat-icon>clear</mat-icon>
        </button>
        <div class="row titleArea clearfix">
          <h2 class="summary-header rm-m">
            <mat-icon class="primary-color" *ngIf="icon && isIndexOf(icon, 'fa-') == false">{{ icon }}</mat-icon>

            <mat-icon class="fas {{ icon }}  primary-color" *ngIf="icon && isIndexOf(icon, 'fa-') != false"></mat-icon>

            <mat-icon class="fas fa-boxes primary-color" *ngIf="!icon"></mat-icon>
            Synchronize {{ Label }} with EP
          </h2>
        </div>
      </div>
    </div>

    <mat-dialog-content>
      <p class="small">
        This module does not exist on EP side , please update the details (this will only effect EP data )
      </p>

      <p class="small">Once the module is created , you can use it on Beta mode and access to module use list</p>

      <div class="row clearfix flex mt">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Code</mat-label>
          <input
            class="mat-block"
            [(ngModel)]="code"
            [disabled]="true"
            matInput
            placeholder="Code"
            name="Label"
            required
          />
        </mat-form-field>

        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Label</mat-label>
          <input
            class="mat-block"
            [(ngModel)]="label"
            matInput
            placeholder="Label"
            name="Code"
            required
          />
        </mat-form-field>
      </div>

      <div class="row clearfix mt">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Module Description</mat-label>
          <textarea
            [(ngModel)]="description"
            name="description"
            matInput
            placeholder="Module Description"
            required
          ></textarea>
        </mat-form-field>
      </div>

      <hr/>

      <div class="row clearfix text-right">
        <button class="" [disabled]="!editForm.form.valid" (click)="save()" mat-raised-button color="accent">
          Save
        </button>
      </div>
    </mat-dialog-content>
  </form>
</div>
