<div class="rel" *ngIf="editorComponent && editorComponent.ID">
  <div class="card-header primary-gradient-img clearfix confirmModal">
    <button class="btn-close btn-clear mat-button smt white custom-close" (click)="closeEvent()">
      <mat-icon>clear</mat-icon>
    </button>
    <div class="full-width flex main-title-container">
      <div class="row full-width title-container clearfix">
        <div class="title-container-sub">
          <h2 class="text-left title-header rm-m">
            {{ title }}

            <span class="subLabel" *ngIf="description">{{ description }}</span>
          </h2>
          <p class="clearfix small rm-mb">
            <span
              [ngClass]="{
                'chip-enable': editorComponent.Is_Active === '1',
                'chip-disable': !editorComponent.Is_Active === '0'
              }"
            >{{ editorComponent.Is_Active == '0' ? 'Not Active' : 'Active' }}</span
            >

            <span class="chip-enable" *ngIf="editorComponent.Is_Default === '1'">Default</span>

            <span
              class="chip-custom component-style"
              *ngIf="editorComponent['Nature.Code'] == 'Style'"
              matTooltip="Style"
            >Style</span
            >
            <span
              class="chip-custom component-page-template"
              *ngIf="editorComponent['Nature.Code'] == 'PageTem'"
              matTooltip="Page Template"
            >Page Template</span
            >
            <span
              class="chip-custom component-component"
              *ngIf="editorComponent['Nature.Code'] == 'Template'"
              matTooltip="Component"
            >Component</span
            >
          </p>
        </div>
      </div>
    </div>
  </div>

  <mat-dialog-content>
    <app-code-html-view
      *ngIf="isLoaded == true && (style || content)"
      [isModal]="false"
      [content]="preview"
      [style]="style"
      style="margin-top: -15px"
    ></app-code-html-view>

    <app-empty-list-message
      class="full-width"
      *ngIf="isLoaded == true && !style && !content"
      [title]="'No content'"
      [message]="'Nothing to display'"
    ></app-empty-list-message>
  </mat-dialog-content>
  <div class="drop-button text-center full-width">
    <button (click)="closeEvent()" mat-raised-button color="primary">Close</button>
  </div>
</div>
