<div class="row clearfix white-background" *ngIf="documentUrl">
  <!-- header area -->
  <div class="row clearfix cardheader primary-gradient-img inModal">
    <!-- close button -->
    <button
      class="btn-close btn-clear white pull-right mat-button"
      *ngIf="isModal == true"
      (click)="closeModalEvent()"
      mat-dialog-close
    >
      <mat-icon>clear</mat-icon>
    </button>
    <div class="row titleArea clearfix">
      <app-ep-document-view-image
        class="headNavViewthumbnail userIconViewthumbnail mr pull-left"
        [defaultPicture]="'url(' + defaultPicture + ')'"
      >
      </app-ep-document-view-image>
      <h2 class="summary-header rm-m white">
        {{ title }}
        <span class="subLabel">{{ description }}</span>
      </h2>
    </div>
  </div>
  <mat-dialog-content>
    <div class="full-width row clearfix">
      <div class="row clearfix text-center noOVerflow" *ngIf="type == 'video' && safeVideoURL">
        <iframe
          [src]="safeVideoURL"
          width="560"
          height="315"
          frameborder="0"
          webkitallowfullscreen
          mozallowfullscreen
          allowfullscreen
        ></iframe>
      </div>
      <div class="view-container" *ngIf="type == 'pdf' && safeVideoURL">
        <object
          class="full-width pdf-view-object"
          id="iframeDoc2"
          *ngIf="browser && isMicrosoftEdge == false"
          [data]="safeVideoURL"
          name="iframeDoc2"
          type="application/pdf"
        >
          <p>
            It appears you don't have a PDF plugin for this browser. No biggie... you can click here to download the PDF
            file.
          </p>
        </object>
        <div class="full-width clearfix" *ngIf="isMicrosoftEdge == true">
          <p>
            It appears you don't have a PDF plugin for this browser. No biggie... you can click here to download the PDF
            file.
          </p>
        </div>
      </div>
      <div class="view-container" *ngIf="type == 'image' && documentUrl">
        <img class="img-jpg" id="jpegDoc" [attr.src]="documentUrl"/>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="clearfix full-width text-center" *ngIf="isModal == true">
      <button class="btn-large" (click)="closeModalEvent()" mat-raised-button color="primary">Close</button>
    </div>
  </mat-dialog-actions>
</div>
