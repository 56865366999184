import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-selected-teeth-view',
  templateUrl: './selected-teeth-view.component.html',
  styleUrls: ['./selected-teeth-view.component.css'],
})
export class SelectedTeethViewComponent implements OnInit {
  @Input() selectedTeeth = [];
  @Input() isAdult = true;
  constructor() {}

  ngOnInit() {}
}
