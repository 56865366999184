import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'environments/environment';
import { ClipboardService } from 'ngx-clipboard';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { IframeViewComponent } from '../../../shared/components/iframe-view/iframe-view.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { CustomerProspectViewModelComponent } from '../../customer-prospect/customer-prospect-view-model/customer-prospect-view-model.component';
import { DentistViewComponent } from '../../dentist/dentist-view/dentist-view.component';
import { MerchantViewComponent } from '../../merchant/merchant-view/merchant-view.component';
import { ProductUtil } from '../../product/shared/productUtil.type';
import { InstantOfferService } from '../shared/instant-finance-offer.service';

@Component({
  selector: 'instant-offer-external-link-view',
  templateUrl: './instant-offer-external-link-view.component.html',
  styleUrls: ['./instant-offer-external-link-view.component.css'],
})
export class InstantOfferExternalLinkViewComponent implements OnInit {
  @Output()
  close = new EventEmitter();

  maxLength = 220;
  description;
  productDetails;

  link;
  linkDirect;
  @Input()
  instantOfferLinkID = '645c13681dc6ae8e98bde21c';
  utils = new UtilsClass();
  isSubmit = false;
  viewLinkOnly = false;
  isCollapsed = true;
  messageMode = 'all';
  instantOfferLink;
  getLink = new EventEmitter();
  sessionType;
  productUtil = new ProductUtil();
  isPromoterOrAdmin = false;
  theme;
  settings = Settings.global;
  getInstantOfferLink = new EventEmitter();
  getInstantOfferLinkID = new EventEmitter();
  getInstantOfferLinkURL = new EventEmitter();
  @Output()
  getResult = new EventEmitter();

  isModal = true;

  constructor(
    private activeRoute: ActivatedRoute,
    private router: Router,

    private _clipboardService: ClipboardService,
    private authenticationService: AuthenticationService,
    private instantOfferService: InstantOfferService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.viewLinkOnly != null) {
        this.viewLinkOnly = data.viewLinkOnly;
        this.isModal = true;
      }

      if (data.isSubmit != null) {
        this.isSubmit = data.isSubmit;
        this.isModal = true;
      }

      if (data && data.instantOfferLinkID) {
        this.instantOfferLinkID = data.instantOfferLinkID;

        this.isModal = true;
      }
      if (data && data.ID) {
        this.instantOfferLinkID = data.ID;

        this.isModal = true;
      }
      if (data && data.id) {
        this.instantOfferLinkID = data.id;

        this.isModal = true;
      }
    }
  }

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      if (params['instantOfferLinkID']) {
        this.instantOfferLinkID = params['instantOfferLinkID'];
        this.isModal = false;
      }

      this.authenticationService.getSessionType().subscribe((SessionType) => {
        this.sessionType = SessionType;
        this.authenticationService.isPromoterOrAdmin().subscribe((r) => {
          this.isPromoterOrAdmin = r;

          this.init();
        });
      });
    });
  }

  init() {
    if (this.instantOfferLinkID) {
      this.instantOfferService
        .getInstantOfferLink(this.instantOfferLinkID, { selectDocument: !this.viewLinkOnly }, this.sessionType)
        .subscribe((r) => {
          if (r && r.ID) {
            this.instantOfferLink = r;

            this.link = `${environment.nodeUrl}/offer-link/redirect/${r.ID}?t=if`;
            this.linkDirect = `${Settings.global['publicSiteFrontendLink']}/service/${this.instantOfferLink.ID}`;
            this.setup();
          }
        });
    }
  }
  setup() {
    if (this.instantOfferLink && this.instantOfferLink.ID) {
      this.theme = this.instantOfferLink.theme;

      if (this.instantOfferLink.description) {
        this.description = this.instantOfferLink.description.replace(/(\r\n|\r|\n)/g, '<br>');
        this.description = this.description.replace(/(<p><\/p>)/g, '<br>');
        this.description = this.description.replace(/(<p>&nbsp;<\/p>)/g, '<br>');

        this.description = this.description.replace(/(<br><br>)/g, '<br>');
      }

      if (this.instantOfferLink.productDetails) {
        this.productDetails = this.instantOfferLink.productDetails.replace(/(\r\n|\r|\n)/g, '<br>');
        this.productDetails = this.productDetails.replace(/(<p><\/p>)/g, '<br>');
        this.productDetails = this.productDetails.replace(/(<p>&nbsp;<\/p>)/g, '<br>');
        this.productDetails = this.productDetails.replace(/(<br><br>)/g, '<br>');
      }
    }
  }

  copy() {
    if (this.link) {
      this._clipboardService.copy(this.link);
      NotifyAppComponent.displayToast('success', 'Success', 'Copied');
    }
  }

  openIframe() {
    if (this.linkDirect) {
      const ref2 = RootAppComponent.dialog.open(IframeViewComponent, {
        data: {
          link: this.linkDirect,
          isSubmit: this.isSubmit,
        },
        width: '80%',
        height: '92vh',
        panelClass: 'noCard',
      });

      ref2.componentInstance.close.subscribe((res) => {
        ref2.close();
      });

      ref2.componentInstance.getSubmit.subscribe((res) => {
        if (res) {
          ref2.close();
          ref2.afterClosed().subscribe((r) => {
            this.sendResult();
          });
        }
      });
    }
  }
  closeEvent() {
    this.close.emit(true);
  }
  sendResult() {
    if (this.instantOfferLink && this.instantOfferLink.ID) {
      const instantOfferLink = `${Settings.global['publicSiteFrontendLink']}/offer/${this.instantOfferLink.ID}`;
      this.getResult.emit(this.instantOfferLink);
      this.getInstantOfferLink.emit(this.instantOfferLink);
      this.getInstantOfferLinkID.emit(this.instantOfferLink.ID);

      this.getInstantOfferLinkURL.emit(instantOfferLink);
    } else {
      this.getResult.emit(null);
    }
  }

  viewMerchant(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(MerchantViewComponent, {
        data: id,
        width: '600px',
      });
      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    }
  }
  viewDentist(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(DentistViewComponent, {
        data: id,
        width: '600px',
      });
      ref.componentInstance.close.subscribe((res) => {
        if (res == true) {
          ref.close();
        }
      });
    }
  }
  viewPatient(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(CustomerProspectViewModelComponent, {
        data: {
          patientID: id,
          isFullDetails: true,
        },
        width: '700px',
      });
      ref.componentInstance.close.subscribe((res) => {
        if (res == true) {
          ref.close();
        }
      });

      ref.componentInstance.detailedView.subscribe((res) => {
        if (res == true) {
          ref.close();
          ref.afterClosed().subscribe((r) => {
            this.closeEvent();
          });
        }
      });
    }
  }

  list() {
    this.router.navigate(['/merchant', { outlets: { page: ['instant-offer-list-overview'] } }]);
  }
  viewInvitation() {
    if (
      this.instantOfferLink &&
      this.instantOfferLink['Status.Code'] !== 'NotProcd' &&
      this.instantOfferLink['CustomerProspect_key'] &&
      this.instantOfferLink['Invitation_key']
    ) {
      this.router.navigate([
        '/merchant',
        {
          outlets: {
            page: [
              'customer-profile',
              this.instantOfferLink['CustomerProspect_key'],
              'treatment',
              this.instantOfferLink['Invitation_key'],
            ],
          },
        },
      ]);

      this.closeEvent();
    }
  }
}
