/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./admin-header.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../session/session-compact-menu/session-compact-menu.component.ngfactory";
import * as i3 from "../../../session/session-compact-menu/session-compact-menu.component";
import * as i4 from "../../../../shared/services/utils.service";
import * as i5 from "../../../session/shared/sessions.service";
import * as i6 from "../../../authentication/shared/authentication.service";
import * as i7 from "@angular/common";
import * as i8 from "./admin-header.component";
import * as i9 from "@angular/router";
var styles_AdminHeaderComponent = [i0.styles];
var RenderType_AdminHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AdminHeaderComponent, data: {} });
export { RenderType_AdminHeaderComponent as RenderType_AdminHeaderComponent };
export function View_AdminHeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "nav", [["id", "header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "pull-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h1", [["class", "mat-h1 inline"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "pull-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "session-compact-menu", [], null, null, null, i2.View_SessionCompactMenuComponent_0, i2.RenderType_SessionCompactMenuComponent)), i1.ɵdid(7, 114688, null, 0, i3.SessionCompactMenuComponent, [i4.UtilsService, i5.SessionsService, i6.AuthenticationService, i7.DOCUMENT], { displayAdmin: [0, "displayAdmin"], displayDashboard: [1, "displayDashboard"] }, null)], function (_ck, _v) { var currVal_1 = false; var currVal_2 = true; _ck(_v, 7, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.header || "Administration Dashboard"); _ck(_v, 4, 0, currVal_0); }); }
export function View_AdminHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "admin-header", [], null, null, null, View_AdminHeaderComponent_0, RenderType_AdminHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i8.AdminHeaderComponent, [i9.Router, i9.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AdminHeaderComponentNgFactory = i1.ɵccf("admin-header", i8.AdminHeaderComponent, View_AdminHeaderComponent_Host_0, {}, {}, []);
export { AdminHeaderComponentNgFactory as AdminHeaderComponentNgFactory };
