import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HelpTopicsContentsComponent } from '../help-topics-contents/help-topics-contents.component';
import { MaintenancePageComponent } from '../../helper/maintenance-page/maintenance-page.component';
import { SimplePageStandaloneComponent } from '../../helper/simple-page-standalone/simple-page-standalone.component';
import { TwoFactorModalComponent } from '../../helper/two-factor-modal/two-factor-modal.component';
import { TwoFactorInputComponent } from '../../helper/two-factor-input/two-factor-input.component';
import { SomethingWrongComponent } from '../../helper/something-wrong/something-wrong.component';
import { ErrorModalComponent } from '../../helper/error-modal/error-modal.component';
import { Page403Component } from '../../helper/page403/page403.component';
import { Page500Component } from '../../helper/page500/page500.component';
import { BookingModalComponent } from '../../helper/booking-modal/booking-modal.component';
import { CalendlyComponent } from '../../helper/calendly/calendly.component';
import { ZoomInInfoComponent } from '../../helper/zoom-in-info/zoom-in-info.component';
import { Page404Component } from '../../helper/page404/page404.component';
import { PrivacyComponent } from '../../helper/privacy/privacy.component';
import { MessageUsComponent } from '../../helper/message-us/message-us.component';
import { SimplePageEditComponent } from '../../helper/simple-page-edit/simple-page-edit.component';
import { HelpPageEditComponent } from '../../helper/help-page-edit/help-page-edit.component';
import { FAQModalMerchantComponent } from '../../helper/FAQ-modal-merchant/faq-modal-merchant.component';
import { HelpPageCreateComponent } from '../../helper/help-page-create/help-page-create.component';
import { FAQModalConsumerComponent } from '../../helper/FAQ-modal-consumer/faq-modal-consumer.component';
import { SimplePageCreateComponent } from '../../helper/simple-page-create/simple-page-create.component';
import { SimpleTimeOutComponent } from '../../helper/simple-time-out/simple-time-out.component';
import { TemplateReviewComponent } from '../../helper/template-review/template-review.component';
import { TemplateSocialMediaViewComponent } from '../../helper/template-social-media-view/template-social-media-view.component';
import { HelpPageViewComponent } from '../../helper/help-page-view/help-page-view.component';
import { ThankYouComponent } from '../../helper/thank-you/thank-you.component';
import { GettingStartedMerchantStep3Component } from '../../helper/getting-started-merchant/step3/step3.component';
import { GettingStartedMerchantStep2Component } from '../../helper/getting-started-merchant/step2/step2.component';
import { GettingStartedMerchantStep1Component } from '../../helper/getting-started-merchant/step1/step1.component';
import { GettingStartedMerchantComponent } from '../../helper/getting-started-merchant/getting-started-merchant.component';
import { SimplePageViewComponent } from '../../helper/simple-page-view/simple-page-view.component';
import { BetaInfoComponent } from '../../helper/beta-info/beta-info.component';
import { SimplePageCreateEditComponent } from '../../helper/simple-page-create-edit/simple-page-create-edit.component';
import { VersionViewComponent } from '../../helper/version-view/version-view.component';
import { PasswordResetEmptyPageComponent } from '../../helper/password-reset-empty-page/password-reset-empty-page.component';
import { InfoModalComponent } from '../../helper/info-modal/info-modal.component';

const routes: Routes = [
  {
    path: 'help-topics',
    component: HelpTopicsContentsComponent,
  },


  {
    path: 'InfoModalComponent',
    component: InfoModalComponent
  },

  {
    path: 'PasswordResetEmptyPageComponent',
    component: PasswordResetEmptyPageComponent
  },


  {
    path: 'VersionViewComponent',
    component: VersionViewComponent,
  },
  {
    path: 'SimplePageCreateEditComponent',
    component: SimplePageCreateEditComponent,
  },
  {
    path: 'BetaInfoComponent',
    component: BetaInfoComponent,
  },

  {
    path: 'simple-page-view',
    component: SimplePageViewComponent,
  },

  {
    path: 'GettingStartedMerchantComponent',
    component: GettingStartedMerchantComponent,
  },

  {
    path: 'GettingStartedMerchantStep1Component',
    component: GettingStartedMerchantStep1Component,
  },

  {
    path: 'GettingStartedMerchantStep2Component',
    component: GettingStartedMerchantStep2Component,
  },

  {
    path: 'GettingStartedMerchantStep3Component',
    component: GettingStartedMerchantStep3Component,
  },

  {
    path: 'ThankYouComponent',
    component: ThankYouComponent,
  },
  {
    path: 'TemplateSocialMediaViewComponent',
    component: TemplateSocialMediaViewComponent,
  },

  {
    path: 'help-page-view',
    component: HelpPageViewComponent,
  },

  {
    path: 'TemplateReviewComponent',
    component: TemplateReviewComponent,
  },

  {
    path: 'page-time-out',
    component: SimpleTimeOutComponent,
  },

  {
    path: 'page-time-out/:consumerID',
    component: SimpleTimeOutComponent,
  },

  {
    path: 'simple-page-create',
    component: SimplePageCreateComponent,
  },

  {
    path: 'help-page-create',
    component: HelpPageCreateComponent,
  },

  {
    path: 'FAQ-modal-consumer',
    component: FAQModalConsumerComponent,
  },
  {
    path: 'FAQ-modal-merchant',
    component: FAQModalMerchantComponent,
  },
  {
    path: 'message-us',
    component: MessageUsComponent,
  },
  {
    path: 'simple-page-edit',
    component: SimplePageEditComponent,
  },
  {
    path: 'help-page-eidt',
    component: HelpPageEditComponent,
  },
  {
    path: 'privacy',
    component: PrivacyComponent,
  },
  {
    path: '404',
    component: Page404Component,
  },
  {
    path: '404-not-found/:type',
    component: Page404Component,
  },
  {
    path: '404/:errorTitle/:errorContent',
    component: Page404Component,
  },

  {
    path: 'ZoomInInfoComponent',
    component: ZoomInInfoComponent,
  },
  {
    path: 'CalendlyComponent',
    component: CalendlyComponent,
  },

  {
    path: 'BookingModalComponent',
    component: BookingModalComponent,
  },

  {
    path: '500-error',
    component: Page500Component,
  },

  {
    path: '500/:code/:error',
    component: Page500Component,
  },
  {
    path: '500/:code',
    component: Page500Component,
  },
  {
    path: '500',
    component: Page500Component,
  },
  {
    path: '403',
    component: Page403Component,
  },
  {
    path: '403/:type',
    component: Page403Component,
  },
  {
    path: '403/:type/:title',
    component: Page403Component,
  },
  {
    path: '403/:type/:title/:error',
    component: Page403Component,
  },
  {
    path: 'error',
    component: ErrorModalComponent,
  },
  {
    path: 'something-wrong',
    component: SomethingWrongComponent,
  },
  {
    path: 'two-factor-input',
    component: TwoFactorInputComponent,
  },

  {
    path: 'two-factor-modal',
    component: TwoFactorModalComponent,
  },
  {
    path: 'view-page/:type',
    component: SimplePageStandaloneComponent,
  },
  {
    path: 'site-offline',
    component: MaintenancePageComponent,
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HelpTopicsRoutingModule {}
