<aw-wizard #wizard navBarLayout="large-filled-symbols">
  <aw-wizard-step [stepTitle]="('KEYWORD.patient' | translate | titlecase) + ' Information'" navigationSymbol="1">
    <!-- Step 1  -->
    <div class="container" id="step-1">
      <div class="row clearfix">
        <div class="full-width rel">
          <div class="text-center">
            <h2 class="sr-title inline-block rm-mt">Emergency {{ "KEYWORD.treatment" | translate | titlecase }}
              Invitation</h2>
            <div class="pat-lookup-btn">
              <!-- Patient Lookup Trigger -->
              <button class="btn-small" mat-raised-button color="primary">
                {{ "KEYWORD.patient" | translate | titlecase }} Lookup
                <mat-icon>contacts</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
      <mat-card>
        <div class="gradient green headerBar">
          <p class="rm-mt">
            <mat-icon>local_hospital</mat-icon>
          </p>
          <p class="rm-mt">Use an Emergency {{ "KEYWORD.treatment" | translate | titlecase }} Invitation if
            your {{ "KEYWORD.patient" | translate }} needs to be seen fast.</p>
          <p class="small">
            <em
            >Please start by entering the details of your patient. You can use the 'Patient Lookup' button to search
              your existing {{ "KEYWORD.patient" | translate }} records.</em
            >
          </p>
        </div>
        <div class="row clearfix">
          <form name="form">
            <div class="column">
              <div class="icons-col">
                <mat-icon>person</mat-icon>
              </div>
              <div class="row-content-col">
                <div class="clearfix">
                  <div class="half-width">
                    <div class="col-left">
                      <!-- Gender Select -->
                      <mat-select class="full-width" name="gender" placeholder="Please select a gender" required>
                        <mat-option *ngFor="let gender of genders" [value]="gender.Code">
                          {{ gender.Label }}
                        </mat-option>
                      </mat-select>
                    </div>
                  </div>
                  <div class="half-width">
                    <div class="col-right">
                      <!-- Title Select -->
                      <mat-select class="full-width" placeholder="Title" name="title" required>
                        <mat-option *ngFor="let title of titles" [value]="title.Label">
                          {{ title.Label }}
                        </mat-option>
                      </mat-select>
                    </div>
                  </div>
                </div>
                <div class="row clearfix flex">
                  <!-- First Name -->
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>First Name</mat-label>
                    <input class="mat-block" matInput placeholder="First Name " name="firstname" required/>
                  </mat-form-field>
                  <!-- Middle Name -->
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Middle Name</mat-label>
                    <input class="mat-block" matInput placeholder="Middle Name " name="middlename"/>
                  </mat-form-field>
                  <!-- Surname -->
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Surname</mat-label>
                    <input
                      class="mat-block"
                      matInput
                      placeholder="Surname"
                      name="lastname"
                      required
                    />
                  </mat-form-field>
                </div>
              </div>
            </div>
            <hr class="mt mb"/>
            <div class="column">
              <div class="row">
                <div class="icons-col">
                  <mat-icon>phone</mat-icon>
                </div>
                <div class="row-content-col">
                  <div class="row clearfix flex">
                    <!-- Mobile Number -->
                    <app-phone-input class="full-width" [required]="true"> </app-phone-input>
                    <app-phone-input class="full-width" [required]="false"> </app-phone-input>
                    <app-phone-input class="full-width" [required]="false"> </app-phone-input>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="icons-col">
                  <mat-icon>email</mat-icon>
                </div>
                <div class="row-content-col">
                  <!-- Email Address -->
                  <app-email-input class="full-width" [required]="true"></app-email-input>
                </div>
              </div>
            </div>
            <hr class="mt mb"/>
            <div class="column mb">
              <div class="half-width">
                <div class="row clearfix">
                  <div class="icons-col">
                    <mat-icon>info</mat-icon>
                  </div>
                  <div class="row-content-col">
                    <p class="">Please select reason for appointment</p>
                    <mat-button-toggle-group class="btn-group full-width text-center" value="">
                      <mat-button-toggle value="trauma">Trauma</mat-button-toggle>
                      <mat-button-toggle value="pain">Acute Pain</mat-button-toggle>
                      <mat-button-toggle value="cosmetic">Cosmetic</mat-button-toggle>
                    </mat-button-toggle-group>
                  </div>
                </div>
              </div>
              <div class="half-width">
                <div class="row clearfix">
                  <div class="icons-col">
                    <mat-icon>local_hospital</mat-icon>
                  </div>
                  <div class="row-content-col">
                    <p class="">Please set appointment date and time for {{ "KEYWORD.patient" | translate }}</p>
                    <app-date-input class="thin"></app-date-input>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </mat-card>
      <div class="row clearfix">
        <div class="full-width text-center step-button-row">
          <button class="step-back" mat-mini-fab color="primary">
            <mat-icon>keyboard_arrow_left</mat-icon>
          </button>
          <button class="btn-large" mat-raised-button type="submit" color="accent" awNextStep>
            Next
            <mat-icon>chevron_right</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <!--  / Step 1  -->
  </aw-wizard-step>
  <aw-wizard-step stepTitle="Review and Confirm" navigationSymbol="2">
    <!--  Step 2  -->
    <div class="container" id="step-2">
      <div class="row clearfix">
        <!-- <div class="half-width"> -->
        <mat-card>
          <div class="gradient green headerBar">
            <p class="rm-mt">
              <mat-icon>local_hospital</mat-icon>
            </p>
            <p class="rm-mt">Confirm details for emergency invitation and send.</p>
          </div>
          <div class="row clearfix reviewInfo">
            <div class="half-width">
              <div class="column">
                <div class="row clearfix">
                  <div class="icons-col">
                    <h3 class="sr-title sm-h3 rm-mt mt rm-mb mb">To:</h3>
                  </div>
                  <div class="row-content-col">
                    <h3 class="sm-h3 rm-mt mt rm-mb mb">{{ 'PLACEHOLDER.Name with Title' | translate }}</h3>
                  </div>
                </div>
              </div>
              <div class="column mb">
                <div class="row clearfix">
                  <div class="icons-col">
                    <mat-icon>email</mat-icon>
                  </div>
                  <div class="row-content-col">
                    <p class="rm-mt">{{ 'PLACEHOLDER.Email' | translate }}</p>
                  </div>
                </div>
                <div class="row clearfix">
                  <div class="icons-col">
                    <mat-icon>phone</mat-icon>
                  </div>
                  <div class="row-content-col">
                    <p class="rm-mt">
                      {{ 'PLACEHOLDER.Phone' | translate }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="half-width">
              <div class="column">
                <h3 class="sm-h3 rm-mt mt rm-mb mb"><span class="primary-color">Emergency Appointment</span></h3>
              </div>
              <div class="column mb">
                <div class="row clearfix">
                  <div class="icons-col">
                    <mat-icon>local_hospital</mat-icon>
                  </div>
                  <div class="row-content-col">
                    <p class="rm-mt">Appointment date/time: <strong>00/00/0000 at 00:00</strong></p>
                  </div>
                </div>
                <div class="row clearfix">
                  <div class="icons-col">
                    <mat-icon>monetisation_on</mat-icon>
                  </div>
                  <div class="row-content-col">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Approximate Cost $ (Optional)</mat-label>
                      <input class="mat-block" matInput placeholder="Approximate Cost $ (Optional)"/>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr/>
          <div class="row clearfix">
            <div class="half-width">
              <div class="column mb">
                <div class="icons-col">
                  <mat-icon>chat</mat-icon>
                </div>
                <div class="row-content-col">
                  <h3 class="sr-title sm-h3 rm-mt mt rm-mb mb">
                    Personal message to {{ "KEYWORD.patient" | translate }}
                  </h3>
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Enter your message</mat-label>
                    <textarea class="dentMsg" matInput placeholder="Enter your message"></textarea>
                  </mat-form-field>
                  <button
                    class="defaultMsg btn-small pull-left"
                    mat-raised-button
                    matTooltip="Insert default message to send with invitation"
                  >
                    Add Default Message
                    <mat-icon>comment</mat-icon>
                  </button>
                </div>
              </div>
            </div>
            <div class="half-width">
              <div class="column">
                <div class="icons-col">
                  <mat-icon>face</mat-icon>
                </div>
                <div class="row-content-col">
                  <h3 class="sr-title sm-h3 rm-mt mt rm-mb mb">Senders name to appear with message</h3>
                  <div class="clearfix">
                    <!-- Sender Select -->
                    <mat-select class="full-width" name="selectedSender" placeholder="Sender">
                      <mat-option *ngFor="let sender of senders" [value]="sender.ID">
                        {{ sender.CalculatedName }}
                      </mat-option>
                    </mat-select>
                  </div>
                </div>
              </div>
              <div class="column mb">
                <div class="icons-col">
                  <mat-icon>build</mat-icon>
                </div>
                <div class="row-content-col">
                  <h3 class="sr-title sm-h3 rm-mt mt rm-mb mb">Settings</h3>
                  <p>
                    The invitation will be sent to
                    <span class="invite-sent-to"></span>
                    <span class="invite-sent-to"></span>
                  </p>
                  <div class="alternate-theme">
                    <mat-checkbox name="sendSMS">Send SMS to: </mat-checkbox>
                    <span class="send-sms-to"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row clearfix">
            <div class="column mb">
              <div class="icons-col">
                <mat-icon>alarm</mat-icon>
              </div>
              <div class="row-content-col">
                <h3 class="sr-title sm-h3 rm-mt mt rm-mb mb">Schedule</h3>
                <p>
                  The invitation you created will be sent straight away to your
                  {{ "KEYWORD.patient" | translate }} after you click on 'send'. If you wish to change the scheduled
                  time please modify below.
                </p>
                <div class="row clearfix">
                  <div class="half-width">
                    <div class="col-left">
                      <div class="input-control">
                        <app-date-input class="thin"></app-date-input>
                      </div>
                      <button class="btn-small inline" *ngIf="false" mat-raised-button>
                        <mat-icon>visibility_on</mat-icon>
                        Preview Email
                      </button>
                    </div>
                  </div>
                  <div class="half-width">
                    <div class="col-right animated fadeInDown" *ngIf="sendSMS">
                      <div class="input-control">
                        <app-date-input class="thin"></app-date-input>
                      </div>
                      <button class="btn-small inline" *ngIf="false" mat-raised-button>
                        <mat-icon>visibility_on</mat-icon>
                        Preview SMS
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-card>
      </div>
      <div class="row clearfix">
        <div class="full-width step-button-row text-center">
          <button class="step-back" mat-mini-fab color="primary" awPreviousStep>
            <mat-icon>keyboard_arrow_left</mat-icon>
          </button>
          <button class="step-send" class="btn-large" mat-raised-button color="accent" awNextStep>
            Send
            <mat-icon>send</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <!--  / Step 2 -->
  </aw-wizard-step>
  <!-- The completion step -->
  <aw-wizard-completion-step title="Send!" navigationSymbol="3">
    <!--  Step 3 - finished -->
    <div class="container" id="step-3">
      <mat-card class="card successful">
        <div class="colour-stripe"></div>
        <div class="row clearfix">
          <div class="full-width text-center">
            <h1 class="sm-h1 sr-title">Well Done!</h1>
            <h3 class="sm-h3 rm-mt">Your invitation is complete</h3>
            <div class="row clearfix">
              <div class="svg-box">
                <svg class="circular green-stroke">
                  <circle class="path" cx="75" cy="75" r="50" fill="none" stroke-width="5" stroke-miterlimit="10" />
                </svg>
                <svg class="checkmark green-stroke">
                  <g transform="matrix(0.79961,8.65821e-32,8.39584e-32,0.79961,-489.57,-205.679)">
                    <path class="checkmark__check" fill="none" d="M616.306,283.025L634.087,300.805L673.361,261.53" />
                  </g>
                </svg>
              </div>
            </div>
            <button class="view-invite" class="btn-small" mat-raised-button color="primary">View Invitation</button>
            <button class="view-invite" class="btn-small" mat-raised-button color="accent">
              Invitation Link
            </button>
          </div>
        </div>
      </mat-card>
      <div class="row clearfix text-center">
        <button class="step-back" mat-raised-button color="primary">
          <mat-icon>keyboard_arrow_left</mat-icon>
          Dashboard
        </button>
      </div>
    </div>
    <!--  / Step 4  -->
  </aw-wizard-completion-step>
</aw-wizard>
