/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./customer-prospect-blank.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./customer-prospect-blank.component";
var styles_CustomerProspectBlankComponent = [i0.styles];
var RenderType_CustomerProspectBlankComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CustomerProspectBlankComponent, data: {} });
export { RenderType_CustomerProspectBlankComponent as RenderType_CustomerProspectBlankComponent };
export function View_CustomerProspectBlankComponent_0(_l) { return i1.ɵvid(0, [], null, null); }
export function View_CustomerProspectBlankComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-customer-prospect-blank", [], null, null, null, View_CustomerProspectBlankComponent_0, RenderType_CustomerProspectBlankComponent)), i1.ɵdid(1, 114688, null, 0, i2.CustomerProspectBlankComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomerProspectBlankComponentNgFactory = i1.ɵccf("app-customer-prospect-blank", i2.CustomerProspectBlankComponent, View_CustomerProspectBlankComponent_Host_0, {}, {}, []);
export { CustomerProspectBlankComponentNgFactory as CustomerProspectBlankComponentNgFactory };
