import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { UtilsClass } from '../../types/utils/utils.class';

@Component({
  selector: 'app-metadata-input',
  templateUrl: './metadata-input.component.html',
  styleUrls: ['./metadata-input.component.css'],
})
export class MetadataInputComponent implements OnInit {
  @Input()
  metadata = {
    Amount: {
      Label: 'Treatment Price',
      Type: 'R',
      Mandatory: '1',
    },
    Comment: {
      Label: 'Personal message to recipient',
      Type: 'T',
      Mandatory: '0',
    },
    ProductGroup: {
      Label: 'Finance Offered',
      Type: 'T',
      Mandatory: '1',
      Lookup: {
        Table: 'ProductGroup',
        CodeField: 'Group.Code',
        LabelField: 'Group.Label',
        FilterField: 'Available',
        FilterValue: '1',
      },
    },
    ContactTime: {
      Label: 'Preferred contact time',
      Type: 'T',
      Mandatory: '0',
      Lookup: {
        Table: 'CodeLookup',
        Category: 'ContactTime',
        CodeField: 'Code',
        LabelField: 'Label',
      },
    },
    Salutation: {
      Label: 'Salutation',
      Type: 'T',
      Mandatory: '1',
      Lookup: {
        Table: 'SimpleLookup',
        Category: 'Salutation',
        CodeField: 'Code',
        LabelField: 'Label',
      },
    },
  };

  metadatas = [];

  @Input()
  marketingUserData = {};

  @Output()
  getMetadata = new EventEmitter();

  @Output()
  getUserData = new EventEmitter();

  @Output()
  validate = new EventEmitter();

  isValidate = false;
  util = new UtilsClass();

  constructor() {}

  ngOnInit() {
    this.setup();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.setup();
  }

  setup() {
    this.metadatas = [];

    if (!this.marketingUserData) {
      this.marketingUserData = {};
    }

    if (this.metadata && this.metadata['Meta_Data)']) {
      this.metadata = this.metadata['Meta_Data)'];
    }

    if (this.metadata && typeof this.metadata == 'object') {
      for (const key in this.metadata) {
        if (this.metadata.hasOwnProperty(key)) {
          const o = this.metadata[key];
          o.ID = key;

          this.metadatas.push(o);
        }
      }

      if (this.metadatas && this.metadatas.length > 0) {
        for (let i = 0; i < this.metadatas.length; i++) {
          if (this.metadatas[i] && this.metadatas[i]['ID']) {
            if (!this.marketingUserData[this.metadatas[i]['ID']]) {
              this.marketingUserData[this.metadatas[i]['ID']] = null;
            }
          }
        }
      }
    } else if (this.metadata && Array.isArray(this.metadata)) {
      this.metadatas = this.metadata;

      if (this.metadatas && this.metadatas.length > 0) {
        for (let i = 0; i < this.metadatas.length; i++) {
          if (this.metadatas[i] && this.metadatas[i]['ID']) {
            if (!this.marketingUserData[this.metadatas[i]['ID']]) {
              this.marketingUserData[this.metadatas[i]['ID']] = null;
            }
          }
        }
      }
    }
  }

  chooseMetadata(e) {
    if (e && typeof e == 'object' && e.ID && e.Label) {
      this.sendResult();
    }
  }

  sendResult(id = null, type = null) {
    if (id && type) {
      const t = this.getType(type);
      if (t == 'number') {
        this.marketingUserData[id] = Number(this.marketingUserData[id]);
      }
    }

    this.validateField();
    this.getMetadata.emit(this.metadata);
    this.getUserData.emit(this.marketingUserData);
  }

  getRule(m) {
    let r = '';

    if (m['Type'] == 'R') {
      r = r + 'numeric';
    } else if (m['Type'] == 'B') {
      r = r + 'boolean';
    } else {
      r = r + 'string';
    }

    if (m['Mandatory'] == '1' && r == '') {
      r = r + 'required';
    }

    if (m['Mandatory'] == '1' && r != '') {
      r = r + '|required';
    }

    return r;
  }

  validateField() {
    const ruleValidator = {};

    for (let i = 0; i < this.metadatas.length; i++) {
      ruleValidator[this.metadatas[i]['ID']] = this.getRule(this.metadatas[i]);
    }

    this.isValidate = this.util.validateFields(this.marketingUserData, ruleValidator);

    this.validate.emit(this.isValidate);
  }

  getRequired(r) {
    if (r == '1') {
      return 'required';
    } else {
      return '';
    }
  }

  getRequiredBoolean(r) {
    if (r == '1') {
      return true;
    } else {
      return false;
    }
  }

  getType(r) {
    if (r == 'R') {
      return 'number';
    } else {
      return '';
    }
  }

  getLookupCode(e, id) {
    if (e && id) {
      this.marketingUserData[id] = e;
      this.sendResult();
    }
  }

  getPlaceHolderFromLabel(e) {
    if (e == 'Quoted amount' || e == 'Total Treatment Price' || e === 'Treatment Price') {
      return 'Please enter the price to be displayed in the offer';
    } else {
      return 'Please enter the ' + e;
    }
  }

  getLabel(e) {
    if (e == 'Quoted amount' || e == 'Total Treatment Price' || e === 'Treatment Price') {
      return 'Treatment Price';
    } else if (e == 'Product group') {
      return 'Finance Offered';
    } else {
      return e;
    }
  }
}
