import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TitleModalConfig } from '../title-modal/title-modal.component';

export class ModalTitle {
  icon?: string;
  classIcon?: string;
  title: string;
  customClass?: string;
}

@Component({
  selector: 'ipv-modal-title',
  templateUrl: './modal-title.component.html',
  styleUrls: ['./modal-title.component.css'],
})
export class ModalTitleComponent {
  @Output() closed: EventEmitter<void> = new EventEmitter();

  @Input() icon = 'info';
  @Input() classIcon = '';
  @Input() title = 'Dialog Title';
  @Input() subtitle: string;
  @Input() customClass: null | string = null;
  classNames = {};

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: TitleModalConfig
  ) {
    if (this.data) {
      this.icon = this.data.icon ? this.data.icon : this.icon;
      this.classIcon = this.data.classIcon ? this.data.classIcon : this.classIcon;
      this.title = this.data.title ? this.data.title : this.title;
      this.customClass = this.data.customClass ? this.data.customClass : this.customClass;
    }

    if (this.customClass === null) {
      this.customClass = this.icon;
    }

    const classNamesString = `{"${this.customClass}":true}`;

    this.classNames = JSON.parse(classNamesString);
  }

  close() {
    this.closed.emit();
  }
}
