import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSortModule } from '@angular/material';
import { PasswordStrengthMeterModule } from 'angular-password-strength-meter';
import { ClipboardModule } from 'ngx-clipboard';
import { NgPipesModule } from 'ngx-pipes';
import { OperatorChangePasswordComponent } from '../../feature/operator/operator-change-password/operator-change-password.component';
import { OperatorViewComponent } from '../../feature/operator/operator-view/operator-view.component';
import { OperatorService } from '../../feature/operator/shared/operator.service';
import { SharedModule } from '../../shared/shared.module';
import { AuthenticationModule } from '../authentication/authentication.module';
import { ActiveSessionViewComponent } from './active-session-view/active-session-view.component';
import { ActiveSessionsComponent } from './active-sessions/active-sessions.component';
import { AgentDetailsComponent } from './agent-details/agent-details.component';
import { ConnectionHistoryViewComponent } from './connection-history-view/connection-history-view.component';
import { ConnectionHistoryComponent } from './connection-history/connection-history.component';
import { PracticeAppointmentCancellationPolicyComponent } from './practice-appointment-cancellation-policy/practice-appointment-cancellation-policy.component';
import { SessionChangeAvatar } from './session-change-avatar/session-change-avatar.component';
import { SessionCompactMenuComponent } from './session-compact-menu/session-compact-menu.component';
import { SessionCountdownModalComponent } from './session-countdown-modal/session-countdown-modal.component';
import { SessionEditProfileComponent } from './session-edit-profile/session-edit-profile.component';
import { SessionExpiredModalComponent } from './session-expired-modal/session-expired-modal.component';
import { SessionOverViewComponent } from './session-over-view/session-over-view.component';
import { SessionViewPracticePictureComponent } from './session-view-practice-picture/session-view-practice-picture.component';
import { SessionViewProfilePictureComponent } from './session-view-profile-picture/session-view-profile-picture.component';
import { SessionRoutingModule } from './shared/session-routing.module';
import { SessionsService } from './shared/sessions.service';

@NgModule({
  imports: [
    NgPipesModule,
    ReactiveFormsModule,
    MatSortModule,
    AuthenticationModule,
    CommonModule,
    SessionRoutingModule,
    FormsModule,
    CdkTableModule,
    SharedModule,
    ClipboardModule,
    PasswordStrengthMeterModule,
  ],
  declarations: [
    AgentDetailsComponent,
    SessionViewProfilePictureComponent,
    SessionViewPracticePictureComponent,
    SessionEditProfileComponent,
    SessionChangeAvatar,
    SessionCompactMenuComponent,
    SessionOverViewComponent,
    ActiveSessionsComponent,
    ConnectionHistoryComponent,
    SessionCountdownModalComponent,
    SessionExpiredModalComponent,
    ActiveSessionViewComponent,
    ConnectionHistoryViewComponent,
    OperatorViewComponent,
    OperatorChangePasswordComponent,
    PracticeAppointmentCancellationPolicyComponent,
  ],
  exports: [
    AgentDetailsComponent,
    SessionViewProfilePictureComponent,
    SessionViewPracticePictureComponent,
    SessionEditProfileComponent,
    SessionChangeAvatar,
    SessionCompactMenuComponent,
    SessionOverViewComponent,
    ActiveSessionsComponent,
    ConnectionHistoryComponent,
    SessionCountdownModalComponent,
    SessionExpiredModalComponent,
    ActiveSessionViewComponent,
    ConnectionHistoryViewComponent,
    OperatorViewComponent,
    OperatorChangePasswordComponent,
    PracticeAppointmentCancellationPolicyComponent,
  ],
  providers: [SessionsService, OperatorService],
})
export class SessionModule {}
