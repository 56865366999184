<mat-card
  class="rel clearfix"
  class="rel"
  *ngIf="instantOfferLink"
  [ngClass]="{ 'not-modal-container': isModal != true }"
>
  <form #resetForm="ngForm">
    <div
      class="card-header primary-gradient-img clearfix confirmModal"
      [attr.style]="'background:' + theme.background + ' !important' | safeStyle"
    >
      <button
        class="btn-close btn-clear mat-button smt white custom-close"
        *ngIf="isModal == true"
        (click)="closeEvent()"
      >
        <mat-icon>clear</mat-icon>
      </button>
      <div class="full-width flex main-title-container">
        <div class="row full-width title-container clearfix">
          <div class="title-container-sub">
            <instant-offer-header-image
              class="pull-left mr offer-image-header-view"
              [headerImage]="instantOfferLink['headerImage']"
              [merchantID]="instantOfferLink['merchantID']"
              [hideZoomButton]="false"
            >
            </instant-offer-header-image>

            <h2 class="text-left title-header rm-m smt">
              {{ instantOfferLink.title }}

              <span class="subLabel">
                <span class="mr" *ngIf="isPromoterOrAdmin == true">
                  {{ instantOfferLink.merchantName }}
                </span>

                <span
                  class="mr"
                  *ngIf="instantOfferLink && instantOfferLink.documents && instantOfferLink.documents.length"
                >
                  Attached Documents

                  <span class="badge mr" [endVal]="instantOfferLink.documents.length" countUp></span>
                </span>
              </span>
            </h2>
          </div>
        </div>
      </div>
    </div>

    <mat-dialog-content [ngClass]="{ 'no-modal-height': isModal != true }">
      <div class="full-width">
        <div class="link-container text-center" *ngIf="link">
          <a class="link-class" (click)="openIframe()">{{ link }}</a>
        </div>

        <div class="full-width detail-container">
          <div class="box text-center">
            <h3 class="sr-title text-left smt smb" [attr.style]="'color:' + theme.color + ' !important' | safeStyle">
              {{ "KEYWORD.treatment" | translate | titlecase }} Value
            </h3>
            <p class="text-left rm-m good">
              <strong>{{ instantOfferLink.treatmentAmount | customCurrency }}</strong>
            </p>
          </div>
          <div class="box text-center">
            <h3 class="sr-title text-left smt smb" [attr.style]="'color:' + theme.color + ' !important' | safeStyle">
              Sender
            </h3>
            <p class="text-left click-link rm-m" (click)="viewDentist(instantOfferLink.senderID)">
              <strong>{{ instantOfferLink.senderName }}</strong>
            </p>
          </div>

          <div class="box text-center">
            <h3 class="sr-title text-left smt smb" [attr.style]="'color:' + theme.color + ' !important' | safeStyle">
              Finance Type
            </h3>
            <p class="text-left rm-m">
              <mat-icon
                class=" fas {{
                  productUtil.getProductGroupIcon(instantOfferLink['productGroup'])
                }} product-group-ico-small mr"
              >
              </mat-icon>

              <strong> {{ productUtil.getLabel(instantOfferLink['productGroup']) }}</strong>
            </p>
          </div>
        </div>

        <h3
          class="sr-title text-left full-width mt smb"
          *ngIf="description"
          [attr.style]="'color:' + theme.color + ' !important' | safeStyle"
        >
          Description
        </h3>
        <div class="full-width flex">
          <read-more
            class="text-left full-width read-more-module22 read-more-message-input rm-m"
            *ngIf="description"
            [animated]="true"
            [text]="utils.URLify(description || '...', false)"
            [design]="'noPropagation'"
            [maxLength]="maxLength"
            [isCollapsed]="false"
            [hideButton]="!isModal"
            [hideButtonForce]="!isModal"
          >
          </read-more>
        </div>
        <h3
          class="sr-title text-left full-width mt smb"
          *ngIf="productDetails"
          [attr.style]="'color:' + theme.color + ' !important' | safeStyle"
        >
          Product Details
        </h3>

        <div class="full-width flex">
          <read-more
            class="text-left full-width read-more-module22 read-more-message-input rm-m"
            *ngIf="productDetails"
            [animated]="true"
            [text]="utils.URLify(productDetails || '...', false)"
            [design]="'noPropagation'"
            [maxLength]="maxLength"
            [isCollapsed]="false"
            [hideButton]="!isModal"
            [hideButtonForce]="!isModal"
          >
          </read-more>
        </div>

        <h3
          class="sr-title text-left full-width mt smb"
          *ngIf="instantOfferLink && instantOfferLink.tags && instantOfferLink.tags.length > 0"
          [attr.style]="'color:' + theme.color + ' !important' | safeStyle"
        >
          Tags
        </h3>

        <mat-chip-list class="full-width clearfix" #chipList name="tags-patient">
          <mat-chip *ngFor="let tag of instantOfferLink.tags" [selectable]="false" [removable]="false">
            {{ tag }}
          </mat-chip>
        </mat-chip-list>

        <h3
          class="sr-title text-left pull-left text-center"
          *ngIf="instantOfferLink && instantOfferLink.documents && instantOfferLink.documents.length > 0"
          [attr.style]="'color:' + theme.color + ' !important' | safeStyle"
        >
          Attached Documents
          <span>({{ instantOfferLink.documents.length }})</span>
        </h3>

        <div
          class="full-width"
          *ngIf="instantOfferLink && instantOfferLink.documents && instantOfferLink.documents.length > 0"
        >
          <app-ep-document-list
            class="document-instantOfferLink"
            [isDownload]="false"
            [isPrint]="false"
            [canRemove]="false"
            [justify]="'left'"
            [isModal]="false"
            [isFilter]="false"
            [defaultCheck]="false"
            [isRedirection]="false"
            [documents]="instantOfferLink.documents"
            [title]="''"
            [canCheck]="false"
            [listViewType]="'thumbnails'"
          >
          </app-ep-document-list>
        </div>
      </div>
    </mat-dialog-content>

    <hr/>

    <div class="drop-button text-center mt clearfix full-width">
      <button
        class="btn-large pull-right"
        [attr.style]="'background:' + theme.color + ' !important' | safeStyle"
        (click)="copy()"
        mat-raised-button
        color="primary"
      >
        Copy Link
      </button>
      <button
        class="btn-large pull-right"
        [attr.style]="'background:' + theme.color + ' !important' | safeStyle"
        (click)="openIframe()"
        mat-raised-button
        color="primary"
      >
        Open
      </button>
      <button
        class="btn-large pull-right"
        [attr.style]="'background:' + theme.color + ' !important' | safeStyle"
        [matMenuTriggerFor]="ActionBtnmenu"
        mat-raised-button
        color="primary"
      >
        Share
      </button>
      <mat-menu #ActionBtnmenu="matMenu">
        <button class="row select-btn" (click)="unselect()" mat-menu-item color="">
          <mat-icon class="fab fa-facebook smr"></mat-icon> Facebook
        </button>

        <button class="row select-btn" (click)="unselect()" mat-menu-item color="">
          <mat-icon class="fab fa-twitter smr"></mat-icon> Twitter
        </button>

        <button class="row select-btn" (click)="unselect()" mat-menu-item color="">
          <mat-icon class="fab fa-pinterest smr"></mat-icon> Pintrest
        </button>
      </mat-menu>

      <button
        class="btn-large pull-left"
        [attr.style]="'background:' + theme.color + ' !important' | safeStyle"
        (click)="closeEvent()"
        mat-raised-button
        color="primary"
      >
        close
      </button>
    </div>
  </form>
</mat-card>
