<!--  Normal Thankyou -->
<mat-card class="card result-card" *ngIf="typeOfCard == 'thankyou'">
  <img class="animated zoomIn character_clipboard_thumbsup" />
  <div class="circle"></div>
  <h1 class="lg-h1 sr-title text-center">Thank you {{ firstName }}</h1>
  <p class="lead rm-mb text-center">We appreciate you using our services</p>

  <p class="text-center">
    If you have any queries, please call our friendly team on
    <span class="bold" (click)="dial(merchantPhone)">{{ merchantPhone | customPhone }}</span>, or
    <span class="bold" *ngIf="merchantID" (click)="contactMerchant()"> you can email us.</span>
  </p>

  <p class="text-center small" *ngIf="
      canLogin == true &&
      hideCustomerPortal != true &&
      (loginLabel != 'Login' || (loginLabel == 'Login' && sessionType != 'customer'))
    ">
    You can log in to have full access to your {{ "KEYWORD.treatment" | translate }} and our features:
  </p>
  <p class="text-center" *ngIf="
      canLogin == true &&
      hideCustomerPortal != true &&
      (loginLabel != 'Login' || (loginLabel == 'Login' && sessionType != 'customer'))
    ">
    <button class="animate" (click)="loginEvent()" mat-stroked-button color="primary">
      <mat-icon class="fas fa-sign-in-alt mr" *ngIf="loginLabel == 'Login' && sessionType != 'customer'"></mat-icon>
      <mat-icon class="fas fa-user-plus mr" *ngIf="loginLabel != 'Login'"></mat-icon>

      {{ loginLabel }}
    </button>
  </p>

  <div class="button-row">
    <button class="" *ngIf="canLogin == true && hideCustomerPortal != true && displayViewTreatment"
            (click)="loginEvent()" mat-raised-button color="accent">
      View {{"KEYWORD.Treatment" | translate}}
    </button>
    <button class="" *ngIf="merchantID" (click)="contactMerchant()" mat-raised-button color="primary">
      Contact us
    </button>
    <button class="" *ngIf="dentistAppointmentUrl || !hasBookedAppointment" (click)="makeAppointment()"
            mat-raised-button color="warn">
      <mat-icon class="fas fa-calendar-check"></mat-icon> Book Appointment
    </button>
  </div>
</mat-card>

<!--  Application Change -->
<mat-card class="card result-card" *ngIf="typeOfCard == 'applicationChange'">
  <img class="animated zoomIn character_clipboard_thumbsup" />
  <div class="circle"></div>
  <h1 class="lg-h1 sr-title text-center">Thank you {{ firstName }}</h1>
  <p class="lead rm-mb text-center">Thanks for your feedback.</p>
  <p class="text-center">
    We've sent your comments through to {{ merchantName }}, and they'll get in touch with you soon.
  </p>
  <p class="text-center">
    If you have any queries or wish to discuss your {{ "KEYWORD.treatment" | translate }} options, please call our
    friendly team on
    <span class="bold" (click)="dial(merchantPhone)">{{ merchantPhone | customPhone }}</span>, or
    <span class="bold" *ngIf="merchantID" (click)="contactMerchant()"> you can email us.</span>
  </p>

  <p class="text-center small" *ngIf="
      hideCustomerPortal != true && (loginLabel != 'Login' || (loginLabel == 'Login' && sessionType != 'customer'))
    ">
    You can also log in to have full access to your {{ "KEYWORD.treatment" | translate }} and our features:
  </p>

  <p class="text-center" *ngIf="
      canLogin == true &&
      hideCustomerPortal != true &&
      (loginLabel != 'Login' || (loginLabel == 'Login' && sessionType != 'customer'))
    ">
    <button class="animate" (click)="loginEvent()" mat-stroked-button color="primary">
      <mat-icon class="fas fa-sign-in-alt mr" *ngIf="loginLabel == 'Login' && sessionType != 'customer'"></mat-icon>
      <mat-icon class="fas fa-user-plus mr" *ngIf="loginLabel != 'Login'"></mat-icon>

      {{ loginLabel }}
    </button>
  </p>
  <div class="button-row">
    <button class="" *ngIf="canLogin == true && hideCustomerPortal != true && displayViewTreatment"
            (click)="loginEvent()" mat-raised-button color="accent">
      View {{"KEYWORD.Treatment" | translate}}
    </button>
    <button class="" *ngIf="merchantID" (click)="contactMerchant()" mat-raised-button color="primary">
      Contact us
    </button>
  </div>
</mat-card>

<!--  Decision Made -->
<mat-card class="card result-card" *ngIf="typeOfCard == 'decisionMade'">
  <img class="animated zoomIn character_clipboard_thumbsup" />
  <div class="circle"></div>
  <h1 class="lg-h1 sr-title text-center">Thank you {{ firstName }}</h1>
  <p class="lead rm-mb text-center">We appreciate you using our services!</p>
  <p>
    Your feedback and decision about your {{ "KEYWORD.treatment" | translate }} has been passed on to your
    {{ "KEYWORD.practitioner" | translate }}, and they'll be in touch soon!
  </p>

  <p class="text-center" *ngIf="dentistAppointmentUrl || !hasBookedAppointment">
    You can request an appointment at any time by pressing the
    <strong>book appointment</strong> button.
  </p>

  <p class="text-center">
    If you have any queries or wish to change your decision, please call our friendly team on
    <span class="bold" (click)="dial(merchantPhone)">{{ merchantPhone | customPhone }}</span>, or
    <span class="bold" *ngIf="merchantID" (click)="contactMerchant()"> you can email us</span>
    and let your {{ "KEYWORD.practitioner" | translate }} know.
  </p>

  <p class="text-center small" *ngIf="
      hideCustomerPortal != true && (loginLabel != 'Login' || (loginLabel == 'Login' && sessionType != 'customer'))
    ">
    You can log in to have full access to your {{ "KEYWORD.treatment" | translate }} and our features:
  </p>

  <p class="text-center" *ngIf="
      canLogin == true &&
      hideCustomerPortal != true &&
      (loginLabel != 'Login' || (loginLabel == 'Login' && sessionType != 'customer'))
    ">
    <button class="animate" (click)="loginEvent()" mat-stroked-button color="primary">
      <mat-icon class="fas fa-sign-in-alt mr" *ngIf="loginLabel == 'Login' && sessionType != 'customer'"></mat-icon>
      <mat-icon class="fas fa-user-plus mr" *ngIf="loginLabel != 'Login'"></mat-icon>

      {{ loginLabel }}
    </button>
  </p>
  <div class="button-row">
    <button class="" *ngIf="canLogin == true && hideCustomerPortal != true && displayViewTreatment"
            (click)="loginEvent()" mat-raised-button color="accent">
      View {{"KEYWORD.Treatment" | translate}}
    </button>
    <button class="" *ngIf="merchantID" (click)="contactMerchant()" mat-raised-button color="primary">
      Contact us
    </button>
  </div>
</mat-card>

<!-- Defer -->
<mat-card class="card result-card" *ngIf="typeOfCard == 'defer'">
  <img class="animated zoomIn character_clipboard_thumbsup" />
  <div class="circle"></div>
  <h1 class="lg-h1 sr-title text-center">Thank you {{ firstName }}</h1>
  <p class="lead rm-mb text-center">
    We'll get back to you in <strong>{{ weeks }}</strong>.
  </p>

  <p class="text-center" *ngIf="dentistAppointmentUrl || !hasBookedAppointment">
    You can request an appointment at any time by pressing the
    <strong>book appointment</strong> button.
  </p>

  <p class="text-center">
    If you have any queries or wish to change your decision, please call our friendly team on
    <span class="bold" (click)="dial(merchantPhone)">{{ merchantPhone | customPhone }}</span>, or
    <span class="bold" *ngIf="merchantID" (click)="contactMerchant()"> you can email us</span>
    and let your {{ "KEYWORD.practitioner" | translate }} know.
  </p>

  <p class="text-center small" *ngIf="
      hideCustomerPortal != true && (loginLabel != 'Login' || (loginLabel == 'Login' && sessionType != 'customer'))
    ">
    You can also log in to have full access to your {{ "KEYWORD.treatment" | translate }} and our features:
  </p>
  <p class="text-center" *ngIf="
      canLogin == true &&
      hideCustomerPortal != true &&
      (loginLabel != 'Login' || (loginLabel == 'Login' && sessionType != 'customer'))
    ">
    <button class="animate" (click)="loginEvent()" mat-stroked-button color="primary">
      <mat-icon class="fas fa-sign-in-alt mr" *ngIf="loginLabel == 'Login' && sessionType != 'customer'"></mat-icon>
      <mat-icon class="fas fa-user-plus mr" *ngIf="loginLabel != 'Login'"></mat-icon>

      {{ loginLabel }}
    </button>
  </p>
  <div class="button-row">
    <button class="" *ngIf="canLogin == true && hideCustomerPortal != true && displayViewTreatment"
            (click)="loginEvent()" mat-raised-button color="accent">
      View {{"KEYWORD.Treatment" | translate}}
    </button>
    <button class="" *ngIf="merchantID" (click)="contactMerchant()" mat-raised-button color="primary">
      Contact us
    </button>
  </div>
</mat-card>

<!--  Dont proceed -->
<mat-card class="card result-card" *ngIf="typeOfCard == 'dontProceed'">
  <img class="animated zoomIn character_clipboard_thumbsup" />
  <div class="circle"></div>
  <h1 class="lg-h1 sr-title text-center">Thank you {{ firstName }}</h1>
  <p class="lead rm-mb text-center">
    We're sorry to hear you <strong>don't wish to proceed</strong> with your {{"KEYWORD.treatment" | translate}}.
  </p>

  <p class="text-center">
    If you have any queries, wish to change your decision, or need to discuss your {{ "KEYWORD.treatment" | translate }}
    options please call our
    friendly team on
    <span class="bold" (click)="dial(merchantPhone)">{{ merchantPhone | customPhone }}</span>, or
    <span class="bold" *ngIf="merchantID" (click)="contactMerchant()"> you can email us.</span>
  </p>

  <p class="text-center small" *ngIf="
      hideCustomerPortal != true && (loginLabel != 'Login' || (loginLabel == 'Login' && sessionType != 'customer'))
    ">
    You can also log in to have full access to your {{ "KEYWORD.treatment" | translate }} and our features:
  </p>
  <p class="text-center" *ngIf="
      canLogin == true &&
      hideCustomerPortal != true &&
      (loginLabel != 'Login' || (loginLabel == 'Login' && sessionType != 'customer'))
    ">
    <button class="animate" (click)="loginEvent()" mat-stroked-button color="primary">
      <mat-icon class="fas fa-sign-in-alt mr" *ngIf="loginLabel == 'Login' && sessionType != 'customer'"></mat-icon>
      <mat-icon class="fas fa-user-plus mr" *ngIf="loginLabel != 'Login'"></mat-icon>

      {{ loginLabel }}
    </button>
  </p>
  <div class="button-row">
    <button class="" *ngIf="canLogin == true && hideCustomerPortal != true && displayViewTreatment"
            (click)="loginEvent()" mat-raised-button color="accent">
      View {{"KEYWORD.Treatment" | translate}}
    </button>
    <button class="" *ngIf="merchantID" (click)="contactMerchant()" mat-raised-button color="primary">
      Contact us
    </button>
  </div>
</mat-card>

<!--  No Finance -->
<mat-card class="card result-card" *ngIf="typeOfCard == 'noFinance'">
  <img class="animated zoomIn character_clipboard_thumbsup" />
  <div class="circle"></div>
  <h1 class="lg-h1 sr-title text-center">Thank you {{ firstName }}</h1>
  <p class="lead rm-mb text-center">Thanks for your feedback!</p>
  <p class="text-center">
    We've let your {{ "KEYWORD.practitioner" | translate | titlecase }} know that <strong>you're going to
    proceed</strong> with your
    {{ "PHRASE.treatment plan" | translate }} . They'll be in touch soon to confirm your appointment!
  </p>
  <p class="text-center">
    If you have any queries, wish to change your decision or need to discuss your {{ "KEYWORD.treatment" | translate }}
    options please call our
    friendly team on
    <span class="bold" (click)="dial(merchantPhone)">{{ merchantPhone | customPhone }}</span>, or
    <span class="bold" *ngIf="merchantID" (click)="contactMerchant()"> you can email us.</span>
  </p>

  <p class="text-center small" *ngIf="
      hideCustomerPortal != true && (loginLabel != 'Login' || (loginLabel == 'Login' && sessionType != 'customer'))
    ">
    You can also log in to have full access to your {{ "KEYWORD.treatment" | translate }} and our features:
  </p>

  <p class="text-center" *ngIf="
      canLogin == true &&
      hideCustomerPortal != true &&
      (loginLabel != 'Login' || (loginLabel == 'Login' && sessionType != 'customer'))
    ">
    <button class="animate" (click)="loginEvent()" mat-stroked-button color="primary">
      <mat-icon class="fas fa-sign-in-alt mr" *ngIf="loginLabel == 'Login' && sessionType != 'customer'"></mat-icon>
      <mat-icon class="fas fa-user-plus mr" *ngIf="loginLabel != 'Login'"></mat-icon>

      {{ loginLabel }}
    </button>
  </p>
  <div class="button-row">
    <button class="" *ngIf="canLogin == true && hideCustomerPortal != true && displayViewTreatment"
            (click)="loginEvent()" mat-raised-button color="accent">
      View {{"KEYWORD.Treatment" | translate}}
    </button>

    <button class="" *ngIf="merchantID" (click)="contactMerchant()" mat-raised-button color="primary">Contact us
    </button>
    <button class="" *ngIf="dentistAppointmentUrl || !hasBookedAppointment" (click)="makeAppointment()"
            mat-raised-button color="warn">
      <mat-icon class="fas fa-calendar-check"></mat-icon> Book Appointment
    </button>
  </div>
</mat-card>

<!-- PROCEED CASH -->

<mat-card class="card result-card" *ngIf="typeOfCard == 'proceedCash'">
  <img class="animated zoomIn character_payment_schedule" />
  <div class="circle"></div>

  <h2 class="lg-h1 sr-title text-center">Thanks for your response {{ firstName }}!</h2>
  <p class="lead rm-mb text-center">{{ title }}</p>
  <p class="text-center">
    We've confirmed that <strong>you intend to proceed</strong> with your {{ "KEYWORD.treatment" | translate }} and pay
    with cash or card on the
    day.
  </p>

  <p class="text-center" *ngIf="dentistAppointmentUrl || !hasBookedAppointment">
    You can request an appointment at any time by pressing the
    <strong>book appointment</strong> button.
  </p>

  <p class="text-center">
    If you have any queries or wish to change your decision, please call our friendly team on
    <span class="bold" (click)="dial(merchantPhone)">{{ merchantPhone | customPhone }}</span>, or
    <span class="bold" *ngIf="merchantID" (click)="contactMerchant()"> you can email us</span>
    and let your {{ "KEYWORD.practitioner" | translate }} know.
  </p>

  <p class="text-center small" *ngIf="
      hideCustomerPortal != true && (loginLabel != 'Login' || (loginLabel == 'Login' && sessionType != 'customer'))
    ">
    You can also log in to have full access to your {{ "KEYWORD.treatment" | translate }} and our features:
  </p>

  <p class="text-center" *ngIf="
      canLogin == true &&
      hideCustomerPortal != true &&
      (loginLabel != 'Login' || (loginLabel == 'Login' && sessionType != 'customer'))
    ">
    <button class="animate" (click)="loginEvent()" mat-stroked-button color="primary">
      <mat-icon class="fas fa-sign-in-alt mr" *ngIf="loginLabel == 'Login' && sessionType != 'customer'"></mat-icon>
      <mat-icon class="fas fa-user-plus mr" *ngIf="loginLabel != 'Login'"></mat-icon>

      {{ loginLabel }}
    </button>
  </p>
  <div class="button-row">
    <button class="" *ngIf="canLogin == true && hideCustomerPortal != true && displayViewTreatment"
            (click)="loginEvent()" mat-raised-button color="accent">
      View {{"KEYWORD.Treatment" | translate}}
    </button>

    <button class="" *ngIf="merchantID" (click)="contactMerchant()" mat-raised-button color="primary">Contact us
    </button>
    <button class="" *ngIf="dentistAppointmentUrl || !hasBookedAppointment" (click)="makeAppointment()"
            mat-raised-button color="warn">
      <mat-icon class="fas fa-calendar-check"></mat-icon> Book Appointment
    </button>
  </div>
</mat-card>

<!--  PROCEED DISCOUNT -->

<mat-card class="card result-card" *ngIf="typeOfCard == 'proceedDiscount'">
  <img class="animated zoomIn character_thumbsup" />
  <div class="circle"></div>

  <h1 class="lg-h1 sr-title text-center">Thanks for your response {{ firstName }}!</h1>
  <p class="lead rm-mb text-center">{{ title }}</p>
  <p class="text-center">
    We've confirmed that <strong>you intend to proceed</strong> with your {{ "KEYWORD.treatment" | translate }} and pay
    with cash or card on the
    day.
  </p>
  <p class="text-center">
    <strong>Your discount voucher has been emailed to your account!</strong>
  </p>
  <p class="text-center" *ngIf="dentistAppointmentUrl || !hasBookedAppointment">
    You can request an appointment at any time by pressing the
    <strong>book appointment</strong> button.
  </p>

  <p class="text-center">
    If you have any queries or wish to change your decision, please call our friendly team on
    <span class="bold" (click)="dial(merchantPhone)">{{ merchantPhone | customPhone }}</span>, or
    <span class="bold" *ngIf="merchantID" (click)="contactMerchant()"> you can email us</span>
    and let your {{ "KEYWORD.practitioner" | translate }} know.
  </p>
  <p class="text-center small" *ngIf="
      hideCustomerPortal != true && (loginLabel != 'Login' || (loginLabel == 'Login' && sessionType != 'customer'))
    ">
    You can also log in to have full access to your {{ "KEYWORD.treatment" | translate }} and our features:
  </p>
  <p class="text-center" *ngIf="
      canLogin == true &&
      hideCustomerPortal != true &&
      (loginLabel != 'Login' || (loginLabel == 'Login' && sessionType != 'customer'))
    ">
    <button class="animate" (click)="loginEvent()" mat-stroked-button color="primary">
      <mat-icon class="fas fa-sign-in-alt mr" *ngIf="loginLabel == 'Login' && sessionType != 'customer'"></mat-icon>
      <mat-icon class="fas fa-user-plus mr" *ngIf="loginLabel != 'Login'"></mat-icon>

      {{ loginLabel }}
    </button>
  </p>
  <div class="button-row">
    <button class="" *ngIf="canLogin == true && hideCustomerPortal != true && displayViewTreatment"
            (click)="loginEvent()" mat-raised-button color="accent">
      View {{"KEYWORD.Treatment" | translate}}
    </button>
    <button class="" *ngIf="merchantID" (click)="contactMerchant()" mat-raised-button color="primary">Contact us
    </button>
    <button class="" *ngIf="dentistAppointmentUrl || !hasBookedAppointment" (click)="makeAppointment()"
            mat-raised-button color="warn">
      <mat-icon class="fas fa-calendar-check"></mat-icon> Book Appointment
    </button>
  </div>
</mat-card>

<!--  EXPIRED OR REMOVED -->

<mat-card class="card result-card" *ngIf="typeOfCard == 'expiredRemoved'">
  <div class="tool-btn" *ngIf="dentistID || merchantID">
    <button [matMenuTriggerFor]="menu" mat-icon-button>
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button *ngIf="dentistID" (click)="viewDentist()" mat-menu-item>
        <mat-icon>perm_identity</mat-icon>
        <span>{{ 'ViewDentist' | translate }}</span>
      </button>

      <button *ngIf="merchantID" (click)="viewMerchant()" mat-menu-item>
        <mat-icon>account_circle</mat-icon>
        <span>{{ 'ViewMerchant' | translate }}</span>
      </button>

      <button *ngIf="dentistID" (click)="contactDentist()" mat-menu-item>
        <mat-icon>mail_outline</mat-icon>
        <span>{{ 'contactDentist' | translate }}</span>
      </button>

      <button *ngIf="merchantID" (click)="contactMerchant()" mat-menu-item>
        <mat-icon>contact_mail</mat-icon>
        <span>{{ 'contactMerchant' | translate }}</span>
      </button>
    </mat-menu>
  </div>

  <img class="animated zoomIn character_error" />
  <div class="circle"></div>

  <h1 class="lg-h1 sr-title text-center">Well this is awkward</h1>
  <p class="lead rm-mb text-center">{{ title }}</p>
  <p class="larger text-center">That's ok though! We can get you a new one right away.</p>
  <p class="add-mb text-center">
    To request a new invitation link, either call us at the {{ "KEYWORD.practice" | translate }} on
    <span class="bold" (click)="dial(merchantPhone)">{{ merchantPhone | customPhone }}</span>
    during our office hours, or during our office hours, or you can
    <span class="bold" *ngIf="merchantID" (click)="contactMerchant()">email us</span>
    and we will send you a new link.
  </p>
  <p class="text-center" *ngIf="
      hideCustomerPortal != true && (loginLabel != 'Login' || (loginLabel == 'Login' && sessionType != 'customer'))
    ">
    You can also log in to have full access to your {{ "KEYWORD.treatment" | translate }} and our features:
  </p>

  <p class="text-center" *ngIf="
      canLogin == true &&
      hideCustomerPortal != true &&
      (loginLabel != 'Login' || (loginLabel == 'Login' && sessionType != 'customer'))
    ">
    <button class="animate" (click)="loginEvent()" mat-stroked-button color="primary">
      <mat-icon class="fas fa-sign-in-alt mr" *ngIf="loginLabel == 'Login' && sessionType != 'customer'"></mat-icon>
      <mat-icon class="fas fa-user-plus mr" *ngIf="loginLabel != 'Login'"></mat-icon>

      {{ loginLabel }}
    </button>
  </p>
  <div class="button-row">
    <button class="" *ngIf="canLogin == true && hideCustomerPortal != true && displayViewTreatment"
            (click)="loginEvent()" mat-raised-button color="accent">
      View {{"KEYWORD.Treatment" | translate}}
    </button>

    <button class="" *ngIf="merchantID" (click)="contactMerchant()" mat-raised-button color="primary">
      Contact us
    </button>

    <button class="gradient green" *ngIf="merchantID" (click)="contactMerchant(subjectRequestInvitation)"
            mat-raised-button color="primary">
      Request new invitation
    </button>
  </div>
</mat-card>

<!--  PROCEED DISCOUNT -->

<mat-card class="card result-card" *ngIf="typeOfCard == 'commenced'">
  <img class="animated zoomIn character_thumbsup" />
  <div class="circle"></div>

  <h1 class="lg-h1 sr-title text-center">Thanks for your response {{ firstName }}!</h1>
  <p class="lead rm-mb text-center">
    You commenced your application for a {{ settings['brandName'] }} {{ productName || '' }} on
    {{ statusDate | customDate }}.
  </p>

  <p class="text-center small" *ngIf="
      hideCustomerPortal != true && (loginLabel != 'Login' || (loginLabel == 'Login' && sessionType != 'customer'))
    ">
    You will be Redirected to finalize your application, you can log in to have full access to your {{
    "KEYWORD.treatment" | translate }} and our
    features:
  </p>
  <p class="text-center" *ngIf="
      canLogin == true &&
      hideCustomerPortal != true &&
      (loginLabel != 'Login' || (loginLabel == 'Login' && sessionType != 'customer'))
    ">
    <button class="animate" (click)="loginEvent()" mat-stroked-button color="primary">
      <mat-icon class="fas fa-sign-in-alt mr" *ngIf="loginLabel == 'Login' && sessionType != 'customer'"></mat-icon>
      <mat-icon class="fas fa-user-plus mr" *ngIf="loginLabel != 'Login'"></mat-icon>
      {{ loginLabel }}
    </button>
  </p>
  <div class="button-row">
    <button class="" *ngIf="merchantID" (click)="contactMerchant()" mat-raised-button color="primary">
      Contact us
    </button>

    <button class="gradient green" (click)="goWakanda()" mat-raised-button color="primary">
      Finalise Application
      <span *ngIf="countdown">({{ countdown / 1000 }}) </span>
    </button>
  </div>
</mat-card>

<mat-card class="card result-card" *ngIf="typeOfCard == 'completed'">
  <img class="animated zoomIn character_thumbsup" />
  <div class="circle"></div>

  <h1 class="lg-h1 sr-title text-center">Congratulations {{ firstName }}!</h1>
  <p class="lead rm-mb text-center">
    You financed this {{ "KEYWORD.treatment" | translate }} with {{ settings['brandName'] }} , and your loan/payment
    plan is now Complete.
  </p>

  <p class="text-center small" *ngIf="
      canLogin == true &&
      hideCustomerPortal != true &&
      (loginLabel != 'Login' || (loginLabel == 'Login' && sessionType != 'customer'))
    ">
    You can log in to have full access to your {{ "KEYWORD.treatment" | translate }} and our features:
  </p>
  <p class="text-center" *ngIf="
      canLogin == true &&
      hideCustomerPortal != true &&
      (loginLabel != 'Login' || (loginLabel == 'Login' && sessionType != 'customer'))
    ">
    <button class="animate" (click)="loginEvent()" mat-stroked-button color="primary">
      <mat-icon class="fas fa-sign-in-alt mr" *ngIf="loginLabel == 'Login' && sessionType != 'customer'"></mat-icon>
      <mat-icon class="fas fa-user-plus mr" *ngIf="loginLabel != 'Login'"></mat-icon>
      {{ loginLabel }}
    </button>
  </p>
  <div class="button-row">
    <button class="" *ngIf="merchantID" (click)="contactMerchant()" mat-raised-button color="primary">
      Contact us
    </button>
  </div>
</mat-card>

<mat-card class="card result-card" *ngIf="typeOfCard == 'approved'">
  <img class="animated zoomIn character_thumbsup" />
  <div class="circle"></div>

  <h1 class="lg-h1 sr-title text-center">Congratulations {{ firstName }}!</h1>
  <p class="lead rm-mb text-center">
    Your application with {{ settings['brandName'] }} is now Conditionally Approved. Proceed to finalise your
    application.
  </p>

  <p class="text-center small" *ngIf="
      hideCustomerPortal != true && (loginLabel != 'Login' || (loginLabel == 'Login' && sessionType != 'customer'))
    ">
    You will be Redirected to finalize your application, you can log in to have full access to your {{
    "KEYWORD.treatment" | translate }} and our
    features:
  </p>
  <p class="text-center" *ngIf="
      canLogin == true &&
      hideCustomerPortal != true &&
      (loginLabel != 'Login' || (loginLabel == 'Login' && sessionType != 'customer'))
    ">
    <button class="btn-clear animate" (click)="loginEvent()" mat-button color="primary">
      <mat-icon class="fas fa-sign-in-alt mr" *ngIf="loginLabel == 'Login' && sessionType != 'customer'"></mat-icon>
      <mat-icon class="fas fa-user-plus mr" *ngIf="loginLabel != 'Login'"></mat-icon>
      {{ loginLabel }}
    </button>
  </p>
  <div class="button-row">
    <button class="" *ngIf="merchantID" (click)="contactMerchant()" mat-raised-button color="primary">
      Contact us
    </button>

    <button class="gradient green" (click)="goWakanda()" mat-raised-button color="primary">
      Finalise Application
      <span *ngIf="countdown">({{ countdown / 1000 }}) </span>
    </button>
  </div>
</mat-card>

<mat-card class="card result-card" *ngIf="typeOfCard == 'referred'">
  <img class="animated zoomIn character_thumbsup" />
  <div class="circle"></div>

  <h1 class="lg-h1 sr-title text-center">Assessment of your application is currently underway</h1>
  <p class="lead rm-mb text-center">
    Not much longer now and we will let you know via email when you can continue your application.
  </p>

  <p class="text-center small" *ngIf="
      hideCustomerPortal != true && (loginLabel != 'Login' || (loginLabel == 'Login' && sessionType != 'customer'))
    ">
    You can log in to have full access to your {{ "KEYWORD.treatment" | translate }} and our features:
  </p>
  <p class="text-center" *ngIf="
      canLogin == true &&
      hideCustomerPortal != true &&
      (loginLabel != 'Login' || (loginLabel == 'Login' && sessionType != 'customer'))
    ">
    <button class="btn-clear animate" (click)="loginEvent()" mat-button color="primary">
      <mat-icon class="fas fa-sign-in-alt mr" *ngIf="loginLabel == 'Login' && sessionType != 'customer'"></mat-icon>
      <mat-icon class="fas fa-user-plus mr" *ngIf="loginLabel != 'Login'"></mat-icon>
      {{ loginLabel }}
    </button>
  </p>
  <div class="button-row">
    <button class="" *ngIf="merchantID" (click)="contactMerchant()" mat-raised-button color="primary">
      Contact us
    </button>
  </div>
</mat-card>

<mat-card class="card result-card" *ngIf="typeOfCard == 'declined'">
  <img class="animated zoomIn character_error" />
  <div class="circle"></div>

  <h1 class="lg-h1 sr-title text-center">Your application was Unsuccessful</h1>
  <p class="lead rm-mb text-center">
    Unfortunately, this time, your application for finance was unsuccessful. Would you like to proceed still with
    {{ "KEYWORD.treatment" | translate }}(cash/card)?
  </p>

  <p class="text-center small" *ngIf="
      hideCustomerPortal != true && (loginLabel != 'Login' || (loginLabel == 'Login' && sessionType != 'customer'))
    ">
    You can log in to have full access to your {{ "KEYWORD.treatment" | translate }} and our features:
  </p>
  <p class="text-center" *ngIf="
      canLogin == true &&
      hideCustomerPortal != true &&
      (loginLabel != 'Login' || (loginLabel == 'Login' && sessionType != 'customer'))
    ">
    <button class="animate" (click)="loginEvent()" mat-stroked-button color="primary">
      <mat-icon class="fas fa-sign-in-alt mr" *ngIf="loginLabel == 'Login' && sessionType != 'customer'"></mat-icon>
      <mat-icon class="fas fa-user-plus mr" *ngIf="loginLabel != 'Login'"></mat-icon>
      {{ loginLabel }}
    </button>
  </p>
  <div class="button-row">
    <button class="" *ngIf="merchantID" (click)="contactMerchant()" mat-raised-button color="primary">
      Contact us
    </button>

    <button class="gradient green" *ngIf="merchantID" (click)="contactMerchant(subjectRequestInvitation)"
            mat-raised-button color="primary">
      Request new invitation
    </button>
  </div>
</mat-card>

<mat-card class="card result-card" *ngIf="typeOfCard == 'activated'">
  <img class="animated zoomIn character_clipboard_thumbsup" />
  <div class="circle"></div>

  <h1 class="lg-h1 sr-title text-center">Your loan/payment plan is now Active</h1>
  <p class="lead rm-mb text-center">
    You can now proceed with {{ "KEYWORD.treatment" | translate }} with your {{ "KEYWORD.practitioner" | translate }}.
  </p>

  <p class="text-center small" *ngIf="
      hideCustomerPortal != true && (loginLabel != 'Login' || (loginLabel == 'Login' && sessionType != 'customer'))
    ">
    You can log in to have full access to your {{ "KEYWORD.treatment" | translate }} and our features:
  </p>
  <p class="text-center" *ngIf="
      canLogin == true &&
      hideCustomerPortal != true &&
      (loginLabel != 'Login' || (loginLabel == 'Login' && sessionType != 'customer'))
    ">
    <button class="animate" (click)="loginEvent()" mat-stroked-button color="primary">
      <mat-icon class="fas fa-sign-in-alt mr" *ngIf="loginLabel == 'Login' && sessionType != 'customer'"></mat-icon>
      <mat-icon class="fas fa-user-plus mr" *ngIf="loginLabel != 'Login'"></mat-icon>
      {{ loginLabel }}
    </button>
  </p>
  <div class="button-row">
    <button class="" *ngIf="
        canLogin == true &&
        hideCustomerPortal != true &&
        (loginLabel != 'Login' || (loginLabel == 'Login' && sessionType != 'customer'))
      " (click)="loginEvent()" mat-raised-button color="accent">
      View {{"KEYWORD.Treatment" | translate}}
    </button>

    <button class="" *ngIf="merchantID" (click)="contactMerchant()" mat-raised-button color="primary">
      Contact us
    </button>
  </div>
</mat-card>

<mat-card class="card result-card" *ngIf="typeOfCard == 'palningToCommence'">
  <img class="animated zoomIn character_clipboard_thumbsup" />
  <div class="circle"></div>

  <h1 class="lg-h1 sr-title text-center">Yet to start your application!</h1>
  <p class="lead rm-mb text-center">
    You previously indicated you wish to finance but are yet to start the application. It is not too late to get
    started.
  </p>

  <p class="text-center small" *ngIf="
      hideCustomerPortal != true && (loginLabel != 'Login' || (loginLabel == 'Login' && sessionType != 'customer'))
    ">
    You will be Redirected to Begin your application, you can log in to have full access to your {{ "KEYWORD.treatment"
    | translate }} and our
    features:
  </p>
  <p class="text-center" *ngIf="
      canLogin == true &&
      hideCustomerPortal != true &&
      (loginLabel != 'Login' || (loginLabel == 'Login' && sessionType != 'customer'))
    ">
    <button class="animate" (click)="loginEvent()" mat-stroked-button color="primary">
      <mat-icon class="fas fa-sign-in-alt mr" *ngIf="loginLabel == 'Login' && sessionType != 'customer'"></mat-icon>
      <mat-icon class="fas fa-user-plus mr" *ngIf="loginLabel != 'Login'"></mat-icon>
      {{ loginLabel }}
    </button>
  </p>
  <div class="button-row">
    <button class="" *ngIf="merchantID" (click)="contactMerchant()" mat-raised-button color="primary">
      Contact us
    </button>

    <button class="gradient green" (click)="goWakanda()" mat-raised-button color="primary">
      Begin Application <span *ngIf="countdown">({{ countdown / 1000 }}) </span>
    </button>
  </div>
</mat-card>

<mat-card class="card result-card" *ngIf="typeOfCard == 'finalApproval'">
  <img class="animated zoomIn character_clipboard_thumbsup" />
  <div class="circle"></div>

  <h2 class="lg-h1 sr-title text-center">Your application is submitted!</h2>
  <p class="lead rm-mb text-center">
    Not much longer, {{ settings['brandName'] }} will notify you shortly via email to
    begin {{"KEYWORD.treatment" | translate}}.
  </p>

  <p class="text-center small" *ngIf="
      hideCustomerPortal != true && (loginLabel != 'Login' || (loginLabel == 'Login' && sessionType != 'customer'))
    ">
    You can log in to have full access to your {{ "KEYWORD.treatment" | translate }} and our features:
  </p>

  <p class="text-center" *ngIf="
      canLogin == true &&
      hideCustomerPortal != true &&
      (loginLabel != 'Login' || (loginLabel == 'Login' && sessionType != 'customer'))
    ">
    <button class="animate" (click)="loginEvent()" mat-stroked-button color="primary">
      <mat-icon class="fas fa-sign-in-alt mr" *ngIf="loginLabel == 'Login' && sessionType != 'customer'"></mat-icon>
      <mat-icon class="fas fa-user-plus mr" *ngIf="loginLabel != 'Login'"></mat-icon>

      {{ loginLabel }}
    </button>
  </p>
  <div class="button-row">
    <button class="" *ngIf="merchantID" (click)="contactMerchant()" mat-raised-button color="primary">
      Contact us
    </button>
  </div>
</mat-card>
