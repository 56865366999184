import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ActionLogService } from '../../action-log/shared/action-log.service';
import { InvitationService } from '../shared/services/invitation.service';

@Component({
  selector: 'app-invitation-progress-view',
  templateUrl: './invitation-progress-view.component.html',
  styleUrls: ['./invitation-progress-view.component.css'],
})
export class InvitationProgressViewComponent implements OnInit {
  color = 'primary';
  mode = 'buffer';
  progress = 'Invitation Created';
  Step = 'Invitation Card';
  value = 0;
  bufferValue = 0;

  colorInv = 'primary';
  modeInv = 'buffer';
  progressInv = 'Invitation Created';
  StepInv = 'Invitation Card';
  valueInv = 0;
  bufferValueInv = 0;

  ApplicationProgressLabel = 'Application Progress';

  @Input()
  invitation;

  @Input()
  invitationID;

  sessionType = 'guest';
  patientResponse = [];

  givenInformedConsentDate;
  enablePrinting = true;
  eventLogs = {
    emailViewed: null,
    emailSent: null,
    smsSent: null,
    respondTime: null,
  };

  constructor(
    private authenticationService: AuthenticationService,
    private invitationService: InvitationService,
    private actionlogService: ActionLogService
  ) {}

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((res) => {
      if (res) {
        this.sessionType = res;
      }

      if (this.invitationID) {
        this.setup();
      } else if (this.invitation && this.invitation.ID) {
        this.invitationID = this.invitation.ID;

        this.setup();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.invitationID && changes.invitationID.previousValue != changes.invitationID.currentValue) {
      this.authenticationService.getSessionType().subscribe((res) => {
        if (res) {
          this.sessionType = res;
        }

        if (this.invitationID) {
          this.setup();
        } else if (this.invitation && this.invitation.ID) {
          this.invitationID = this.invitation.ID;

          this.setup();
        }
      });
    } else if (
      (changes &&
        changes.invitation &&
        changes.invitation.previousValue &&
        !changes.invitation.previousValue.ID &&
        changes.invitation.currentValue &&
        changes.invitation.currentValue.ID) ||
      (changes &&
        changes.invitation &&
        changes.invitation.previousValue &&
        changes.invitation.previousValue.ID &&
        changes.invitation.currentValue &&
        changes.invitation.currentValue.ID &&
        changes.invitation.currentValue.ID != changes.invitation.previousValue.ID)
    ) {
      this.authenticationService.getSessionType().subscribe((res) => {
        if (res) {
          this.sessionType = res;
        }

        if (this.invitationID) {
          this.setup();
        } else if (this.invitation && this.invitation.ID) {
          this.invitationID = this.invitation.ID;

          this.setup();
        }
      });
    }
  }

  setup() {
    if (this.invitation && this.invitation.ID) {
      if (this.sessionType == 'customer') {
        this.getProgressCustomer();
      } else {
        this.getProgress();
      }

      if (this.invitation['PatientDeclineReasons.Labels'] && this.invitation['PatientDeclineReasons.Labels'] != '') {
        this.patientResponse = this.invitation['PatientDeclineReasons.Labels'].split('<BR>');
      }

      const payload = {
        invitationID: this.invitation['ID'],
      };
      this.actionlogService.getInvitationEvents(payload, this.sessionType).subscribe((res) => {
        if (res) {
          if (res.find((x) => x['Event.Code'] == 'INV-CrCmm.Msg.Email.View')) {
            this.eventLogs.emailViewed = res.find((x) => x['Event.Code'] == 'INV-CrCmm.Msg.Email.View')['DateTime_ISO'];
          }
          if (res.find((x) => x['Event.Code'] == 'INV-CrCmm.Msg.Email.Sent')) {
            this.eventLogs.emailSent = res.find((x) => x['Event.Code'] == 'INV-CrCmm.Msg.Email.Sent')['DateTime_ISO'];
          }
          if (res.find((x) => x['Event.Code'] == 'INV-CrCmm.Msg.SMS.Sent')) {
            this.eventLogs.smsSent = res.find((x) => x['Event.Code'] == 'INV-CrCmm.Msg.SMS.Sent')['DateTime_ISO'];
          }
          if (res.find((x) => x['Event.Code'] == 'INV-CrCmm.Rspd.Ini')) {
            this.eventLogs.respondTime = res.find((x) => x['Event.Code'] == 'INV-CrCmm.Rspd.Ini')['DateTime_ISO'];
          }
        }
      });
    } else if (this.invitationID) {
      this.invitationService.getInvitationDetails(this.invitationID, {}, this.sessionType, false).subscribe((res) => {
        this.invitation = res;
        if (this.sessionType == 'customer') {
          this.getProgressCustomer();
        } else {
          this.getProgress();
        }

        if (this.invitation['PatientDeclineReasons.Labels'] && this.invitation['PatientDeclineReasons.Labels'] != '') {
          this.patientResponse = this.invitation['PatientDeclineReasons.Labels'].split('<BR>');
        }

        const payload = {
          invitationID: this.invitation['ID'],
        };
        this.actionlogService.getInvitationEvents(payload, this.sessionType).subscribe((res) => {
          if (res) {
            if (res.find((x) => x['Event.Code'] == 'INV-CrCmm.Msg.Email.View')) {
              this.eventLogs.emailViewed = res.find((x) => x['Event.Code'] == 'INV-CrCmm.Msg.Email.View')[
                'DateTime_ISO'
              ];
            }
            if (res.find((x) => x['Event.Code'] == 'INV-CrCmm.Msg.Email.Sent')) {
              this.eventLogs.emailSent = res.find((x) => x['Event.Code'] == 'INV-CrCmm.Msg.Email.Sent')['DateTime_ISO'];
            }
            if (res.find((x) => x['Event.Code'] == 'INV-CrCmm.Msg.SMS.Sent')) {
              this.eventLogs.smsSent = res.find((x) => x['Event.Code'] == 'INV-CrCmm.Msg.SMS.Sent')['DateTime_ISO'];
            }
            if (res.find((x) => x['Event.Code'] == 'INV-CrCmm.Rspd.Ini')) {
              this.eventLogs.respondTime = res.find((x) => x['Event.Code'] == 'INV-CrCmm.Rspd.Ini')['DateTime_ISO'];
            }
          }
        });
      });
    }
  }

  getProgressCustomer() {
    let applicationStatus = this.invitation['Status.Application.Long'];
    let invitationStatus = this.invitation['Status.Invitation.Long'];
    let contractStatus = this.invitation['Status.Contract.Long'];
    let emailStatus = this.invitation['Status.Email.Long'];
    const productName = this.invitation['ProductName'];

    if (this.invitation && this.invitation['Campaign_Key']) {
      emailStatus = 'Campaign';
    } else if (emailStatus == '') {
      emailStatus = null;
    }

    if (invitationStatus == '') {
      invitationStatus = null;
    }

    if (contractStatus == '') {
      contractStatus = null;
    }

    if (applicationStatus == '') {
      applicationStatus = null;
    }

    if (
      (emailStatus == 'Sent' || emailStatus == 'Campaign' || emailStatus == 'Read') &&
      !contractStatus &&
      !invitationStatus &&
      !applicationStatus
    ) {
      this.value = 5;
      this.color = 'primary';
      this.progress = 'Email ' + emailStatus;
      this.bufferValue = 5;
      this.Step = 'Invitation Card';
      this.ApplicationProgressLabel = 'Application Progress';
    } else if (emailStatus == 'Delivered' && !contractStatus && !invitationStatus && !applicationStatus) {
      this.valueInv = 22;
      this.colorInv = 'primary';
      this.progressInv = 'Invitation: ' + this.invitation['Status.Invitation.Long_NE'];
      this.bufferValueInv = 22;
      this.StepInv = 'Invitation Card';
    } else if (emailStatus == 'To Be Sent' && !contractStatus && !invitationStatus && !applicationStatus) {
      this.valueInv = 10;
      this.colorInv = 'primary';
      this.progressInv = 'Invitation: ' + this.invitation['Status.Invitation.Long_NE'];
      this.bufferValueInv = 10;
      this.StepInv = 'Invitation Card';
    } else if (
      emailStatus != 'To Be Sent' &&
      emailStatus != 'Delivered' &&
      !contractStatus &&
      !invitationStatus &&
      !applicationStatus
    ) {
      this.valueInv = 10;
      this.colorInv = 'accent';
      this.progressInv = 'Invitation: ' + this.invitation['Status.Invitation.Long_NE'];
      this.bufferValueInv = 10;
      this.StepInv = 'Invitation Card';
    } else if (emailStatus != 'Sent' && !contractStatus && !invitationStatus && !applicationStatus) {
      this.value = 5;
      this.color = 'accent';
      this.progress = 'Email: ' + emailStatus;
      this.bufferValue = 5;
      this.Step = 'Invitation Card';
      this.ApplicationProgressLabel = 'Application Progress';
    } else if (emailStatus && invitationStatus.indexOf('Defered') != -1 && !contractStatus && !applicationStatus) {
      this.value = 10;
      this.color = 'accent';
      this.progress = 'Customer Choice: ' + this.invitation['PatientChoice.Label_LongThirdPerson'];
      this.bufferValue = 10;
      this.Step = 'Invitation Card';
      this.ApplicationProgressLabel = 'Application Progress';

      this.valueInv = 75;
      this.colorInv = 'primary';
      this.progressInv = 'Invitation: ' + invitationStatus;
      this.bufferValueInv = 75;
      this.StepInv = 'Invitation Card';
    } else if (emailStatus && invitationStatus.indexOf('Expired') != -1 && !contractStatus && !applicationStatus) {
      this.value = 100;
      this.color = 'accent';
      this.progress = 'Invitation: ' + invitationStatus;
      this.bufferValue = 100;
      this.Step = 'Invitation Card';
      this.ApplicationProgressLabel = 'Application Progress';
    } else if (
      emailStatus &&
      (invitationStatus.indexOf('Expired') != -1 || invitationStatus.indexOf('Not Proceeding') != -1) &&
      !contractStatus &&
      !applicationStatus
    ) {
      this.value = 100;
      this.color = 'accent';
      this.progress = 'Customer Choice: ' + this.invitation['PatientChoice.Label_LongThirdPerson'];
      this.bufferValue = 100;
      this.Step = 'Invitation Card';
      this.ApplicationProgressLabel = 'Application Progress';

      this.valueInv = 75;
      this.colorInv = 'accent';
      this.progressInv = 'Invitation: ' + invitationStatus;
      this.bufferValueInv = 75;
      this.StepInv = 'Invitation Card';
    } else if (emailStatus && invitationStatus.indexOf('Pay Cash') != -1 && !contractStatus && !applicationStatus) {
      this.valueInv = 100;
      this.colorInv = 'primary';
      this.progressInv = 'Invitation: ' + invitationStatus;
      this.bufferValueInv = 100;
      this.StepInv = 'Invitation Card';
    } else if (emailStatus && invitationStatus.indexOf('Intends') != -1 && !contractStatus && !applicationStatus) {
      this.value = 1;
      this.color = 'primary';
      this.progress = 'Invitation: ' + invitationStatus;
      this.bufferValue = 1;
      this.Step = 'Application Card';
    } else if (emailStatus && invitationStatus.indexOf('Discount') != -1 && !contractStatus && !applicationStatus) {
      this.valueInv = 100;
      this.colorInv = 'primary';
      this.progressInv = 'Invitation: ' + invitationStatus;
      this.bufferValueInv = 100;
      this.StepInv = 'Invitation Card';
    } else if (emailStatus && invitationStatus == 'Not Viewed' && !contractStatus && !applicationStatus) {
      this.value = 10;
      this.color = 'primary';
      this.progress = 'Invitation: ' + invitationStatus;
      this.bufferValue = 10;
      this.Step = 'Invitation Card';
      this.ApplicationProgressLabel = 'Application Progress';
    } else if (emailStatus && invitationStatus == 'Viewed' && !contractStatus && !applicationStatus) {
      this.value = 10;
      this.color = 'primary';
      this.progress = 'Invitation: ' + invitationStatus;
      this.bufferValue = 10;
      this.Step = 'Invitation Card';
      this.ApplicationProgressLabel = 'Application Progress';

      this.valueInv = 47;
      this.colorInv = 'primary';
      this.progressInv = 'Invitation: ' + invitationStatus;
      this.bufferValueInv = 47;
      this.StepInv = 'Invitation Card';
    } else if (emailStatus && invitationStatus == 'Intends to Proceed' && !contractStatus && !applicationStatus) {
      this.value = 47;
      this.color = 'primary';
      this.progress = 'Invitation: ' + invitationStatus;
      this.bufferValue = 47;
      this.Step = 'Invitation Card';
      this.ApplicationProgressLabel = 'Application Progress';

      this.valueInv = 65;
      this.colorInv = 'primary';
      this.progressInv = 'Invitation: ' + invitationStatus;
      this.bufferValueInv = 65;
      this.StepInv = 'Invitation Card';
    } else if (emailStatus && invitationStatus == 'Commenced' && !contractStatus && !applicationStatus) {
      this.value = 33;
      this.color = 'primary';
      this.progress = 'Invitation: ' + invitationStatus;
      this.bufferValue = 33;
      this.Step = 'Get Started ';
      this.ApplicationProgressLabel = 'Application Progress - ' + productName;
    } else if (emailStatus && invitationStatus == 'Via Web' && !contractStatus && !applicationStatus) {
      this.value = 33;
      this.color = 'primary';
      this.progress = 'Invitation: ' + invitationStatus;
      this.bufferValue = 33;
      this.Step = 'Get Started ';
      this.ApplicationProgressLabel = 'Application Progress - ' + productName;
    } else if (emailStatus && invitationStatus == 'Discuss/Modify' && !contractStatus && !applicationStatus) {
      this.value = 33;
      this.color = 'primary';
      this.progress = 'Invitation: ' + invitationStatus;
      this.bufferValue = 33;
      this.Step = 'Get Started ';
      this.ApplicationProgressLabel = 'Application Progress - ' + productName;

      this.valueInv = 75;
      this.colorInv = 'accent';
      this.progressInv = 'Invitation: ' + invitationStatus;
      this.bufferValueInv = 75;
      this.StepInv = 'Invitation Card';
    } else if (
      emailStatus &&
      invitationStatus &&
      applicationStatus &&
      applicationStatus.indexOf('Commenced') != -1 &&
      !contractStatus
    ) {
      this.value = 3;
      this.color = 'primary';
      this.progress = 'Application: ' + applicationStatus;
      this.bufferValue = 3;
      this.Step = 'Finalising ';
      this.ApplicationProgressLabel = 'Application Progress - ' + productName;
    } else if (
      emailStatus &&
      invitationStatus &&
      applicationStatus &&
      applicationStatus.indexOf('Application Complete') != -1 &&
      !contractStatus
    ) {
      this.value = 58;
      this.color = 'primary';
      this.progress = 'Application: ' + applicationStatus;
      this.bufferValue = 58;
      this.Step = 'Finalising ';
      this.ApplicationProgressLabel = 'Application Progress - ' + productName;
    } else if (
      emailStatus &&
      invitationStatus &&
      applicationStatus &&
      applicationStatus.indexOf('Application Pending') != -1 &&
      !contractStatus
    ) {
      this.value = 18;
      this.color = 'primary';
      this.progress = 'Application: ' + applicationStatus;
      this.bufferValue = 18;
      this.Step = 'Finalising ';
      this.ApplicationProgressLabel = 'Application Progress - ' + productName;
    } else if (
      emailStatus &&
      invitationStatus &&
      applicationStatus &&
      applicationStatus.indexOf('Referred') != -1 &&
      !contractStatus
    ) {
      this.value = 30;
      this.color = 'primary';
      this.progress = 'Application: ' + applicationStatus;
      this.bufferValue = 30;
      this.Step = 'Finalising ';
      this.ApplicationProgressLabel = 'Application Progress - ' + productName;
    } else if (
      emailStatus &&
      invitationStatus &&
      applicationStatus &&
      applicationStatus.indexOf('Approved') != -1 &&
      !contractStatus
    ) {
      this.value = 39;
      this.color = 'primary';
      this.progress = 'Application: ' + applicationStatus;
      this.bufferValue = 39;
      this.Step = 'Finalising ';
      this.ApplicationProgressLabel = 'Application Progress - ' + productName;
    } else if (
      emailStatus &&
      invitationStatus &&
      applicationStatus &&
      applicationStatus.indexOf('Declined') != -1 &&
      !contractStatus
    ) {
      this.value = 35;
      this.color = 'accent';
      this.progress = 'Application: ' + applicationStatus;
      this.bufferValue = 35;
      this.Step = 'Finalising ';
      this.ApplicationProgressLabel = 'Application Progress - ' + productName;
    } else if (emailStatus && invitationStatus && applicationStatus && contractStatus == 'Submitted') {
      this.value = 60;
      this.color = 'primary';
      this.progress = 'Contract: ' + contractStatus;
      this.bufferValue = 60;
      this.Step = 'Finalised ';
      this.ApplicationProgressLabel = 'Application Progress - ' + productName;
    } else if (emailStatus && invitationStatus && applicationStatus && contractStatus == 'Approved') {
      this.value = 70;
      this.color = 'primary';
      this.progress = 'Contract: ' + contractStatus;
      this.bufferValue = 70;
      this.Step = 'Finalised ';
      this.ApplicationProgressLabel = 'Application Progress - ' + productName;
    } else if (emailStatus && invitationStatus && applicationStatus && contractStatus == 'Active') {
      this.value = 100;
      this.color = 'primary';
      this.progress = 'Contract: ' + contractStatus;
      this.bufferValue = 100;
      this.Step = 'Finalised ';
      this.ApplicationProgressLabel = 'Application Progress - ' + productName;
    } else if (emailStatus && invitationStatus && applicationStatus && contractStatus == 'Complete') {
      this.value = 100;
      this.color = 'primary';
      this.progress = 'Contract: ' + contractStatus;
      this.bufferValue = 100;
      this.Step = 'Finalised ';
      this.ApplicationProgressLabel = 'Application Progress - ' + productName;
    }
  }

  getProgress() {
    let applicationStatus = this.invitation['Status.Application.Short'];
    let invitationStatus = this.invitation['Status.Invitation.Short'];
    let contractStatus = this.invitation['Status.Contract.Short'];
    let emailStatus = this.invitation['Status.Email.Short'];
    const productName = this.invitation['ProductName'];

    if (emailStatus == '') {
      emailStatus = null;
    }

    if (invitationStatus == '') {
      invitationStatus = null;
    }

    if (contractStatus == '') {
      contractStatus = null;
    }

    if (applicationStatus == '') {
      applicationStatus = null;
    }

    if (
      (emailStatus == 'Sent' || emailStatus == 'Read') &&
      !contractStatus &&
      !invitationStatus &&
      !applicationStatus
    ) {
      this.value = 5;
      this.color = 'primary';
      this.progress = 'Email ' + emailStatus;
      this.bufferValue = 5;
      this.Step = 'Invitation Card';
      this.ApplicationProgressLabel = 'Application Progress';
    } else if (emailStatus == 'Delivered' && !contractStatus && !invitationStatus && !applicationStatus) {
      this.valueInv = 22;
      this.colorInv = 'primary';
      this.progressInv = 'Invitation: ' + this.invitation['Status.Invitation.Short_NE'];
      this.bufferValueInv = 22;
      this.StepInv = 'Invitation Card';
    } else if (emailStatus == 'To Be Sent' && !contractStatus && !invitationStatus && !applicationStatus) {
      this.valueInv = 10;
      this.colorInv = 'primary';
      this.progressInv = 'Invitation: ' + this.invitation['Status.Invitation.Short_NE'];
      this.bufferValueInv = 10;
      this.StepInv = 'Invitation Card';
    } else if (
      emailStatus != 'To Be Sent' &&
      emailStatus != 'Delivered' &&
      !contractStatus &&
      !invitationStatus &&
      !applicationStatus
    ) {
      this.valueInv = 10;
      this.colorInv = 'accent';
      this.progressInv = 'Invitation: ' + this.invitation['Status.Invitation.Short_NE'];
      this.bufferValueInv = 10;
      this.StepInv = 'Invitation Card';
    } else if (emailStatus != 'Sent' && !contractStatus && !invitationStatus && !applicationStatus) {
      this.value = 5;
      this.color = 'accent';
      this.progress = 'Email: ' + emailStatus;
      this.bufferValue = 5;
      this.Step = 'Invitation Card';
      this.ApplicationProgressLabel = 'Application Progress';
    } else if (emailStatus && invitationStatus.indexOf('Defered') != -1 && !contractStatus && !applicationStatus) {
      this.value = 10;
      this.color = 'accent';
      this.progress = 'Customer Choice: ' + this.invitation['PatientChoice.Label_LongThirdPerson'];
      this.bufferValue = 10;
      this.Step = 'Invitation Card';
      this.ApplicationProgressLabel = 'Application Progress';

      this.valueInv = 75;
      this.colorInv = 'primary';
      this.progressInv = 'Invitation: ' + invitationStatus;
      this.bufferValueInv = 75;
      this.StepInv = 'Invitation Card';
    } else if (emailStatus && invitationStatus.indexOf('Expired') != -1 && !contractStatus && !applicationStatus) {
      this.value = 100;
      this.color = 'accent';
      this.progress = 'Invitation: ' + invitationStatus;
      this.bufferValue = 100;
      this.Step = 'Invitation Card';
      this.ApplicationProgressLabel = 'Application Progress';
    } else if (
      emailStatus &&
      (invitationStatus.indexOf('Expired') != -1 || invitationStatus.indexOf('Not Proceeding') != -1) &&
      !contractStatus &&
      !applicationStatus
    ) {
      this.value = 100;
      this.color = 'accent';
      this.progress = 'Customer Choice: ' + this.invitation['PatientChoice.Label_LongThirdPerson'];
      this.bufferValue = 100;
      this.Step = 'Invitation Card';
      this.ApplicationProgressLabel = 'Application Progress';

      this.valueInv = 75;
      this.colorInv = 'accent';
      this.progressInv = 'Invitation: ' + invitationStatus;
      this.bufferValueInv = 75;
      this.StepInv = 'Invitation Card';
    } else if (emailStatus && invitationStatus.indexOf('Pay Cash') != -1 && !contractStatus && !applicationStatus) {
      this.valueInv = 100;
      this.colorInv = 'primary';
      this.progressInv = 'Invitation: ' + invitationStatus;
      this.bufferValueInv = 100;
      this.StepInv = 'Invitation Card';
    } else if (emailStatus && invitationStatus.indexOf('Intends') != -1 && !contractStatus && !applicationStatus) {
      this.value = 1;
      this.color = 'primary';
      this.progress = 'Invitation: ' + invitationStatus;
      this.bufferValue = 1;
      this.Step = 'Application Card';
    } else if (emailStatus && invitationStatus.indexOf('Discount') != -1 && !contractStatus && !applicationStatus) {
      this.valueInv = 100;
      this.colorInv = 'primary';
      this.progressInv = 'Invitation: ' + invitationStatus;
      this.bufferValueInv = 100;
      this.StepInv = 'Invitation Card';
    } else if (emailStatus && invitationStatus == 'Not Viewed' && !contractStatus && !applicationStatus) {
      this.value = 10;
      this.color = 'primary';
      this.progress = 'Invitation: ' + invitationStatus;
      this.bufferValue = 10;
      this.Step = 'Invitation Card';
      this.ApplicationProgressLabel = 'Application Progress';
    } else if (emailStatus && invitationStatus == 'Viewed' && !contractStatus && !applicationStatus) {
      this.value = 10;
      this.color = 'primary';
      this.progress = 'Invitation: ' + invitationStatus;
      this.bufferValue = 10;
      this.Step = 'Invitation Card';
      this.ApplicationProgressLabel = 'Application Progress';

      this.valueInv = 47;
      this.colorInv = 'primary';
      this.progressInv = 'Invitation: ' + invitationStatus;
      this.bufferValueInv = 47;
      this.StepInv = 'Invitation Card';
    } else if (emailStatus && invitationStatus == 'Intends to Proceed' && !contractStatus && !applicationStatus) {
      this.value = 47;
      this.color = 'primary';
      this.progress = 'Invitation: ' + invitationStatus;
      this.bufferValue = 47;
      this.Step = 'Invitation Card';
      this.ApplicationProgressLabel = 'Application Progress';

      this.valueInv = 65;
      this.colorInv = 'primary';
      this.progressInv = 'Invitation: ' + invitationStatus;
      this.bufferValueInv = 65;
      this.StepInv = 'Invitation Card';
    } else if (emailStatus && invitationStatus == 'Commenced' && !contractStatus && !applicationStatus) {
      this.value = 33;
      this.color = 'primary';
      this.progress = 'Invitation: ' + invitationStatus;
      this.bufferValue = 33;
      this.Step = 'Get Started ';
      this.ApplicationProgressLabel = 'Application Progress - ' + productName;
    } else if (emailStatus && invitationStatus == 'Via Web' && !contractStatus && !applicationStatus) {
      this.value = 33;
      this.color = 'primary';
      this.progress = 'Invitation: ' + invitationStatus;
      this.bufferValue = 33;
      this.Step = 'Get Started ';
      this.ApplicationProgressLabel = 'Application Progress - ' + productName;
    } else if (emailStatus && invitationStatus == 'Discuss/Modify' && !contractStatus && !applicationStatus) {
      this.value = 33;
      this.color = 'primary';
      this.progress = 'Invitation: ' + invitationStatus;
      this.bufferValue = 33;
      this.Step = 'Get Started ';
      this.ApplicationProgressLabel = 'Application Progress - ' + productName;

      this.valueInv = 75;
      this.colorInv = 'accent';
      this.progressInv = 'Invitation: ' + invitationStatus;
      this.bufferValueInv = 75;
      this.StepInv = 'Invitation Card';
    } else if (
      emailStatus &&
      invitationStatus &&
      applicationStatus &&
      applicationStatus.indexOf('Commenced') != -1 &&
      !contractStatus
    ) {
      this.value = 3;
      this.color = 'primary';
      this.progress = 'Application: ' + applicationStatus;
      this.bufferValue = 3;
      this.Step = 'Finalising ';
      this.ApplicationProgressLabel = 'Application Progress - ' + productName;
    } else if (
      emailStatus &&
      invitationStatus &&
      applicationStatus &&
      applicationStatus.indexOf('Application Complete') != -1 &&
      !contractStatus
    ) {
      this.value = 58;
      this.color = 'primary';
      this.progress = 'Application: ' + applicationStatus;
      this.bufferValue = 58;
      this.Step = 'Finalising ';
      this.ApplicationProgressLabel = 'Application Progress - ' + productName;
    } else if (
      emailStatus &&
      invitationStatus &&
      applicationStatus &&
      applicationStatus.indexOf('Application Pending') != -1 &&
      !contractStatus
    ) {
      this.value = 18;
      this.color = 'primary';
      this.progress = 'Application: ' + applicationStatus;
      this.bufferValue = 18;
      this.Step = 'Finalising ';
      this.ApplicationProgressLabel = 'Application Progress - ' + productName;
    } else if (
      emailStatus &&
      invitationStatus &&
      applicationStatus &&
      applicationStatus.indexOf('Referred') != -1 &&
      !contractStatus
    ) {
      this.value = 30;
      this.color = 'primary';
      this.progress = 'Application: ' + applicationStatus;
      this.bufferValue = 30;
      this.Step = 'Finalising ';
      this.ApplicationProgressLabel = 'Application Progress - ' + productName;
    } else if (
      emailStatus &&
      invitationStatus &&
      applicationStatus &&
      applicationStatus.indexOf('Approved') != -1 &&
      !contractStatus
    ) {
      this.value = 39;
      this.color = 'primary';
      this.progress = 'Application: ' + applicationStatus;
      this.bufferValue = 39;
      this.Step = 'Finalising ';
      this.ApplicationProgressLabel = 'Application Progress - ' + productName;
    } else if (
      emailStatus &&
      invitationStatus &&
      applicationStatus &&
      applicationStatus.indexOf('Declined') != -1 &&
      !contractStatus
    ) {
      this.value = 35;
      this.color = 'accent';
      this.progress = 'Application: ' + applicationStatus;
      this.bufferValue = 35;
      this.Step = 'Finalising ';
      this.ApplicationProgressLabel = 'Application Progress - ' + productName;
    } else if (emailStatus && invitationStatus && applicationStatus && contractStatus == 'Submitted') {
      this.value = 60;
      this.color = 'primary';
      this.progress = 'Contract: ' + contractStatus;
      this.bufferValue = 60;
      this.Step = 'Finalised ';
      this.ApplicationProgressLabel = 'Application Progress - ' + productName;
    } else if (emailStatus && invitationStatus && applicationStatus && contractStatus == 'Approved') {
      this.value = 70;
      this.color = 'primary';
      this.progress = 'Contract: ' + contractStatus;
      this.bufferValue = 70;
      this.Step = 'Finalised ';
      this.ApplicationProgressLabel = 'Application Progress - ' + productName;
    } else if (emailStatus && invitationStatus && applicationStatus && contractStatus == 'Active') {
      this.value = 100;
      this.color = 'primary';
      this.progress = 'Contract: ' + contractStatus;
      this.bufferValue = 100;
      this.Step = 'Finalised ';
      this.ApplicationProgressLabel = 'Application Progress - ' + productName;
    } else if (emailStatus && invitationStatus && applicationStatus && contractStatus == 'Complete') {
      this.value = 100;
      this.color = 'primary';
      this.progress = 'Contract: ' + contractStatus;
      this.bufferValue = 100;
      this.Step = 'Finalised ';
      this.ApplicationProgressLabel = 'Application Progress - ' + productName;
    }
  }
}
