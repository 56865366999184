import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { HelperService } from '../shared/helper.service';

@Component({
  selector: 'app-simple-page-list',
  templateUrl: './simple-page-list.component.html',
  styleUrls: ['./simple-page-list.component.css'],
})
export class SimplePageListComponent implements OnInit {
  @Input() simplePage: Subject<any>;
  simplepages = [];

  private utils = new UtilsClass();

  page = {
    title: '',
    type: '',
    content: '',
  };

  constructor(private helperService: HelperService) {}

  ngOnInit() {
    Settings.refreshSettings.subscribe((res) => {
      this.helperService.getSimplePages().subscribe((res) => {
        this.simplepages = res;
      });
    });

    this.helperService.getSimplePages().subscribe((res) => {
      this.simplepages = res;
    });
    this.simplePage.subscribe((res) => {
      if (res.added) {
        this.simplepages.push(res.page);
      }
    });
  }

  removePage(event) {
    if (event.success) {
      this.simplepages.splice(this.simplepages.indexOf(event.page), 1);
    }
  }
}
