import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UtilsService } from '../../../shared/services/utils.service';
import { Settings } from '../../../shared/types/settings';

@Component({
  selector: 'app-invitation-intro-modal',
  templateUrl: './invitation-intro-modal.component.html',
  styleUrls: ['./invitation-intro-modal.component.css'],
})
export class InvitationIntroModalComponent implements OnInit {
  showOnInvitationFirstVisit = JSON.parse(localStorage.getItem('showOnInvitationFirstVisit'));

  @Output()
  closeModal = new EventEmitter();

  // @Output()
  // startTour = new EventEmitter();

  isModuleTutorialsActive = Settings.global['isModuleTutorialsActive'];

  constructor(private utilService: UtilsService) {}

  ngOnInit() {
    this.utilService.getCurrentAccess().subscribe((access) => {
      if (access) {
        this.isModuleTutorialsActive = access['isModuleTutorialsActive'];
      }
    });
  }

  doNotShow() {
    localStorage.setItem('showOnInvitationFirstVisit', JSON.stringify(this.showOnInvitationFirstVisit));

    this.closeModal.emit(this.showOnInvitationFirstVisit);
  }

  // startTheTour() {
  //   this.startTour.emit(this.startTour);
  // }
}
