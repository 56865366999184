import { Component, EventEmitter, Inject, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'sr-sms-preview-modal',
  templateUrl: './sms-preview-modal.component.html',
  styleUrls: ['./sms-preview-modal.component.css'],
})
export class SmsPreviewModalComponent implements OnInit {
  @Output() closed: EventEmitter<void> = new EventEmitter();

  message = '';

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data.message) {
      this.message = data.message;
    }
  }

  ngOnInit(): void {}

  close() {
    this.closed.emit();
  }
}
