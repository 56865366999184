import { animate, group, keyframes, query, stagger, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { AuthenticationService } from '../../authentication/shared/authentication.service';
import { SubscriptionPlanService } from '../shared/subscription-plan.service';

import { ActivatedRoute, Router } from '@angular/router';
import hexRgb from 'hex-rgb';
import * as $ from 'jquery';
import * as _ from 'lodash';
import { CalendlyComponent } from '../../../shared/components/calendly/calendly.component';
import { CodeHTMLViewComponent } from '../../../shared/components/html-view/html-view.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { ColorPalette } from '../../../shared/types/color-palette';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { ModuleViewComponent } from '../../module/module-view/module-view.component';
import { SubscriptionPlanCongratulationComponent } from '../subscription-plan-congratulation/subscription-plan-congratulation.component';
import { SubscriptionPlanViewModalComponent } from '../subscription-plan-view-modal/subscription-plan-view-modal.component';

@Component({
  selector: 'app-subscription-plan-list-overview',
  templateUrl: './subscription-plan-list-overview.component.html',
  styleUrls: ['./subscription-plan-list-overview.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.row', style({ opacity: 0 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.row', style({ opacity: 1 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
    trigger('slideInOut', [
      state('in', style({ height: '*', opacity: 0 })),
      transition(':leave', [
        style({ height: '*', opacity: 1 }),

        group([animate(300, style({ height: 0 })), animate('200ms ease-in-out', style({ opacity: '0' }))]),
      ]),
      transition(':enter', [
        style({ height: '0', opacity: 0 }),

        group([animate(300, style({ height: '*' })), animate('400ms ease-in-out', style({ opacity: '1' }))]),
      ]),
    ]),
  ],
})
export class SubscriptionPlanListOverviewComponent implements OnInit {
  @Input()
  maxLength = 300;
  @Input()
  subscriptionPlans = [];
  root = '/merchant';
  @Input()
  ids = [];
  @Input()
  displayFeatureCover = false;
  @Input()
  displayExtra = true;

  @Input()
  displayHeaderPlan = false;
  @Input()
  displaySubscribeButton = false;
  context = Settings.global['context']
  isConsumer = null;
  isMerchant = null;
  isMarketingOnly = false;
  @Input()
  featureType = 'all';

  features = [];
  sessionType;
  isActive = true;
  isPublic = null;
  isLoaded = false;

  currentPlanID;

  scrollAdded = false;
  utils = new UtilsClass();
  @Input()
  hideEmptyFeatures = true;
  isOwnPlan = false;

  constructor(
    private subscriptionPlanService: SubscriptionPlanService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private activeRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    UtilsClass.scrollUp();
    this.activeRoute.params.subscribe((params) => {
      if (params['ids']) {
        this.ids = params['ids'];
      }
      if (params['featureType']) {
        this.featureType = params['featureType'];
      }
      this.authenticationService.getSessionType().subscribe((res) => {
        this.sessionType = res;
        this.authenticationService.getCurrentPlan().subscribe((currentPlanID) => {
          if (currentPlanID) {
            this.currentPlanID = currentPlanID;
          }

          this.setup();
        });
      });
    });

    $(window).resize(() => {
      const width = $(window).width();
      if (width > 0 && width <= 1000) {
        this.maxLength = 500;
      } else {
        this.scrollAdded = false;
        this.maxLength = 300;
        this.scroll();
      }
    });
  }

  setup() {
    if (this.featureType === 'consumer') {
      this.root = '/consumer';
    } else {
      this.root = '/merchant';
    }

    if (this.subscriptionPlans && this.subscriptionPlans.length > 0) {
      this.buildItems();
    } else {
      this.subscriptionPlanService
        .getPromoterPlanListFull(
          {
            isConsumer: this.isConsumer,
            isMerchant: this.isMerchant,
            isActive: true,
            isPublic: true,
            addCustomization: true,
            selectCurrentPlan: true,
            ids: this.ids,
            orderBy: '-Is_Public,Price',
          },
          this.sessionType
        )
        .subscribe((list) => {
          if (list) {
            this.subscriptionPlans = _.map(list, (item) => {
              item.Price = Number(item.Price || 0);
              return item;
            });

            this.buildItems();
          }
        });
    }
    let isActive = this.isActive;

    let isPublic = this.isPublic;

    if (this.ids && this.ids.length > 0) {
      isActive = null;
      isPublic = null;
    }
    this.subscriptionPlanService
      .getFeatureMainLabelListFull(
        {
          isConsumer: this.isConsumer,
          isMarketingOnly: this.isMarketingOnly,
          isMerchant: this.isMerchant,
          isActive,
          isPublic,
          hideEmptyFeatures: this.hideEmptyFeatures,
        },
        this.sessionType
      )
      .subscribe((res) => {
        if (res) {
          this.features = res;

          this.buildItems();
        }
      });
  }

  isNew(date) {
    if (date) {
      const days = this.utils.daysPast(date);

      if (days < 30) {
        return true;
      } else {
        return false;
      }
    }

    return false;
  }

  onColorPickerClose(color: string): void {
    if (color) {
      const v = new ColorPalette(color);
      const palette = v.getPalette();

      if (palette && palette.length > 0) {
        const index = palette.findIndex((item) => {
          if (item && item.name == 800) {
            return true;
          } else {
            return false;
          }
        });

        if (palette[index] && palette[index]['hex']) {
          return palette[index]['hex'];
        }
      }
    }
  }

  buildItems() {
    if (this.features && this.features.length > 0 && this.subscriptionPlans && this.subscriptionPlans.length > 0) {
      this.subscriptionPlans = _.map(this.subscriptionPlans, (item) => {
        if (item && item.ID) {
          if (item.ID === this.currentPlanID && this.currentPlanID) {
            item.isPlanOwn = true;
            this.isOwnPlan = true;
          } else {
            item.isPlanOwn = false;
          }

          let color = Settings.global['primaryColor'] || '#1e88e5';
          let colorSecond;
          if (item['Color']) {
            const split = item['Color'].split('|');
            if (split[0]) {
              color = split[0];
            }
            if (split[1]) {
              colorSecond = split[1] || null;
            }
            if (color && !colorSecond) {
              colorSecond = this.onColorPickerClose(color);
            }
          }

          item.color = color;
          item.colorSecond = colorSecond;

          return item;
        }
      });

      for (let i = 0; i < this.features.length; i++) {
        if (this.features[i]) {
          let color = Settings.global['primaryColor'] || '#1e88e5';
          let colorSecond;
          if (this.features[i]['Color']) {
            const split = this.features[i]['Color'].split('|');
            if (split[0]) {
              color = split[0];
            }
            if (split[1]) {
              colorSecond = split[1] || null;
            }
            if (color && !colorSecond) {
              colorSecond = this.onColorPickerClose(color);
            }
          } else if (color && !colorSecond) {
            colorSecond = this.onColorPickerClose(color);
          }

          this.features[i].color = color;
          this.features[i].colorSecond = colorSecond;
        }
        let subFeatures = null;

        if (this.features[i] && this.features[i]['subFeatures'].length > 0) {
          subFeatures = this.features[i]['subFeatures'];
        }

        if (subFeatures) {
          subFeatures = _.map(subFeatures, (item) => {
            if (item && item.ID) {
              const subscriptionPlans = _.map(this.subscriptionPlans, (plan) => {
                let isActive = false;
                let isLimited = false;
                if (plan && plan.ID) {
                  if (
                    plan.subFeatureIDs &&
                    plan.subFeatureIDs.length > 0 &&
                    plan.subFeatureIDs.indexOf(item.ID) !== -1
                  ) {
                    isActive = true;
                    if (
                      plan.subFeatureLimitedIDs &&
                      plan.subFeatureLimitedIDs.length > 0 &&
                      plan.subFeatureLimitedIDs.indexOf(item.ID) !== -1
                    ) {
                      isLimited = true;
                    }
                  }
                  return {
                    id: plan.ID,
                    Web_Label: plan.Web_Label,
                    Price: plan.Price,
                    ID: plan.ID,
                    Recommended_Plan: plan.Recommended_Plan,
                    isActive,
                    isLimited,
                    isPlanOwn: plan.isPlanOwn,
                    price: Number(plan.Price),
                    color: plan.color,
                    colorSecond: plan.colorSecond,
                  };
                }
              });

              item.subscriptionPlans = subscriptionPlans || [];

              return item;
            }
          });

          this.features[i]['subFeatures'] = subFeatures;
        }
      }
      this.isLoaded = true;

      const width = $(window).width();
      if (width > 0 && width <= 900) {
        this.maxLength = 500;
      } else {
        this.maxLength = 300;
        this.scroll();
      }
    }
  }

  booking() {
    const ref = RootAppComponent.dialog.open(CalendlyComponent, {
      width: '650px',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  freeAccount() {
    window.open(Settings['global']['publicSiteFrontendLink'] + '/partners/(page:pricing-free)', '_blank');
  }

  getMainFeautePicture(id, LastModified) {
    return this.subscriptionPlanService.getFeatureMainLabelThumbnailImageStreamLink(id, LastModified);
  }

  buildBorderleft(color) {
    if (color) {
      const rbg = hexRgb(color);

      if (rbg) {
        return `rgb(${rbg.red}  ${rbg.green} ${rbg.blue} / 30%)`;
      } else {
        return color;
      }
    }
  }

  buildShadowBox(color) {
    if (color) {
      const rbg = hexRgb(color);

      if (rbg) {
        return ` 0px 2px 1px -1px rgb(${rbg.red}  ${rbg.green} ${rbg.blue} / 20%), 0px 1px 1px 0px rgb(${rbg.red}  ${rbg.green} ${rbg.blue} / 17%), 0px 1px 3px 0px rgb(${rbg.red}  ${rbg.green} ${rbg.blue} / 14%)`;
      } else {
        return null;
      }
    }
  }

  isIndexOf(s, attribute) {
    if (s && attribute && s.indexOf(attribute) != -1) {
      return true;
    }
    return false;
  }

  buildBorderColor(color) {
    if (color) {
      const rbg = hexRgb(color);

      if (rbg) {
        return `rgb(${rbg.red}  ${rbg.green} ${rbg.blue} / 30%)`;
      } else {
        return null;
      }
    }
  }

  scroll() {
    if (this.displayExtra == true) {
      const header = $('#subscription-plan-view-card-fixed');
      let sticky = null;
      if (header && header[0]) {
        sticky = Number(header[0].offsetTop - 150);
      }

      if (this.scrollAdded == false && sticky !== null && header) {
        $('.mat-sidenav-content').unbind('scroll');
        $('.mat-sidenav-content').on('scroll', (e) => {
          const pageOffset = Number(e.target.scrollTop || 0);
          const headerHidden = $('#subscription-plan-view-card-fixed-hidden');
          let stickyHidden = null;
          if (headerHidden && headerHidden[0]) {
            stickyHidden = Number(headerHidden[0].offsetTop) - 160;
          }

          if (sticky && header && headerHidden && stickyHidden && e && e.target) {
            if (pageOffset > sticky && pageOffset && pageOffset < stickyHidden) {
              $('#subscription-plan-view-card-fixed').addClass('subscription-plan-view-card-fixed-sticky');
              $('#subscription-plan-view-card-content').addClass('subscription-plan-view-card-content');
            } else {
              $('#subscription-plan-view-card-fixed').removeClass('subscription-plan-view-card-fixed-sticky');
              $('#subscription-plan-view-card-content').removeClass('subscription-plan-view-card-content');
            }
          }
        });

        this.scrollAdded = true;
      }
    }
  }

  viewModule(ID) {
    if (ID) {
      const ref = RootAppComponent.dialog.open(ModuleViewComponent, {
        data: {
          moduleID: ID,
          displaySubscribeButton: this.displaySubscribeButton,
        },
        width: '1000px',
      });
      ref.componentInstance.close.subscribe((res) => {
        if (res) {
          ref.close();
        }
      });
    }
  }

  goBackAll() {
    this.router.navigate([
      this.root,
      {
        outlets: {
          page: ['subscription-plan-overview-all', this.featureType],
        },
      },
    ]);
  }

  viewSubPlanModal(ID) {
    if (ID) {
      const ref = RootAppComponent.dialog.open(SubscriptionPlanViewModalComponent, {
        data: {
          subscriptionPlanID: ID,
          ids: this.ids,
          addCustomization: true,
          featureType: this.featureType,
        },
        width: '1000px',
      });
      ref.componentInstance.close.subscribe((res) => {
        if (res) {
          ref.close();
        }
      });

      ref.componentInstance.getPlan.subscribe((res) => {
        if (res && res.SubscriptionPlan_key) {
          ref.close();

          const ref2 = RootAppComponent.dialog.open(SubscriptionPlanCongratulationComponent, {
            width: '1000px',
            height: '600px',
            data: {
              subscriptionPlanID: res.SubscriptionPlan_key,
            },
            panelClass: 'noCard',
          });

          ref2.componentInstance.close.subscribe((res) => {
            ref2.close();
          });
        }
      });
    }
  }

  backgroundFullBox(color) {
    if (color) {
      const rbg = hexRgb(color);

      if (rbg) {
        return `rgb(${rbg.red}  ${rbg.green} ${rbg.blue} / 3%)`;
      } else {
        return color;
      }
    }
  }

  backgroundOwn(color, isOwned) {
    if (isOwned == true) {
      if (color) {
        const rbg = hexRgb(color);

        if (rbg) {
          return `rgb(${rbg.red}  ${rbg.green} ${rbg.blue} / 5%)`;
        } else {
          return color;
        }
      }
    } else {
      return '#fff';
    }
  }

  getSubFeature(c, mainColor) {
    if (c && c.split('|')[0]) {
      return c.split('|')[0];
    }

    return mainColor;
  }

  goMain(id) {
    if (id) {
      this.router.navigate([this.root, { outlets: { page: ['main-feature-view', id, this.featureType] } }]);
    }
  }

  openSubFeatreOut(id) {
    if (id) {
      this.router.navigate([this.root, { outlets: { page: ['sub-feature', id, this.featureType] } }]);
    }
  }

  ViewHtmlContent(c, title, description, date) {
    if (c) {
      const isPreview = true;
      const isCode = false;
      this.subscriptionPlanService.getSubFeatureHTMLContent(c, this.sessionType, date).subscribe((content) => {
        let width = '750px';
        if (content) {
          width = '75%';
        }

        const ref2 = RootAppComponent.dialog.open(CodeHTMLViewComponent, {
          data: {
            icons: 'fas fa-eye',
            title,
            description,
            content,
            isPreview,
            isCode,
          },
          width,
        });
        ref2.componentInstance.onConfirm.subscribe((res) => {
          ref2.close();
        });
      });
    }
  }
}
