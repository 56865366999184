import { Component, Input, OnInit } from '@angular/core';
import { TeethShape } from '../shared/teeth-shape';
@Component({
  selector: 'app-dental-arch-view',
  templateUrl: './dental-arch-view.component.html',
  styleUrls: ['./dental-arch-view.component.css'],
})
export class DentalArchViewComponent implements OnInit {
  @Input() selectedTeeth = [];
  @Input() isAdult = true;
  teethTop = TeethShape.getTopTeethNumbers(true);
  teethBottom = TeethShape.getBottomTeethNumbers(true);

  constructor() {}

  ngOnInit() {}

  isTeethExist(tooth) {
    if (this.selectedTeeth.indexOf(tooth) == -1) {
      return false;
    } else {
      return true;
    }
  }

  getToothObject(tooth) {
    return {
      toothNumber: tooth,
      isAdult: this.isAdult,
    };
  }
}
