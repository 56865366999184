import { MapsAPILoader } from '@agm/core';
import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import hexRgb from 'hex-rgb';
import * as $ from 'jquery';
import * as _ from 'lodash';
import * as moment from 'moment';
import { ClipboardService } from 'ngx-clipboard';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { MapViewComponent } from '../../../shared/components/map-view/map-view.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { CustomPhonePipe } from '../../../shared/pipes/custom-phone.pipe';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { SessionType } from '../../../shared/types/session-type.type';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { CustomerProspectCreateComponent } from '../../customer-prospect/customer-prospect-create/customer-prospect-create.component';
import { CustomerProspectViewModelComponent } from '../../customer-prospect/customer-prospect-view-model/customer-prospect-view-model.component';
import { InvitationLookupComponent } from '../../invitation/invitation-lookup/invitation-lookup.component';
import { MerchantViewComponent } from '../../merchant/merchant-view/merchant-view.component';
import { MessageService } from '../shared/message.service';
import { SmsPreviewComponent } from '../sms-preview/sms-preview.component';
import { SmsPromoterComponent } from '../sms-promoter/sms-promoter.component';
import { SmsSendComponent } from '../sms-send/sms-send.component';
import { SmsStatisticsComponent } from '../sms-statistics/sms-statistics.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { UtilsService } from '../../../shared/services/utils.service';
import { ContactLookupComponent } from '../../contact/contact-lookup/contact-lookup.component';
import { ContactCreateEditModalComponent } from '../../contact/contact-create-edit-modal/contact-create-edit-modal.component';
import { CardService } from '../../card/shared/card.service';
import { DentistViewComponent } from '../../dentist/dentist-view/dentist-view.component';
import { ContactInputComponent } from '../../contact-us/contact-input/contact-input.component';

@Component({
  selector: 'app-sms-conversation',
  templateUrl: './sms-conversation.component.html',
  styleUrls: ['./sms-conversation.component.css'],
})
export class SmsConversationComponent implements OnInit {
  @Output() close = new EventEmitter();

  @Input() cardID;
  @Input() merchantID;
  @Input() invitationID;
  @Input() contractID;

  @Input() financeOfferID;

  @Input() type = 'S';
  @Input() tableType = 'Contact';

  util = new UtilsClass();
  promoterGlobalNumber;
  merchantGlobalNumber;
  promoterStaffGlobalNumber;
  todayTitle = moment().format('YYYY-MM-DD');
  yesterdayTitle = moment().add(-1, 'd').format('YYYY-MM-DD');
  unreadMinDate = moment().add(-90, 'd').format('YYYY-MM-DD  HH:mm:ss');

  sessionType: SessionType = 'guest';

  isPromoterOrAdmin = false;
  isPromoterView = false;

  isRefreshRunning = false;
  personalMessageOnly = false;
  includeCampaign = false;
  isMerchantAdmin = false;
  promoterID;

  isModuleBulkSMS = Settings.global['isModuleBulkSMSActive'];
  dentists = [];

  geocoder;
  distanceAway;

  filterType = 'newest';

  @Input()
  marketingConsentDescription =
    'This customer has not consented to receiving marketing messages. By proceeding, you confirm the message is not marketing in nature.';

  @Input()
  marketingConsentTitle = '';

  unreadOneMessage = {};
  merchant;
  isRead = null;
  direction = null;
  orderBy = '-DateTimeCreated';
  message;
  defaultMessage;
  messageType;
  messageFilter;
  totalMessage;
  messageLoaded = false;
  messageIsLoading = false;
  loadMoreMessage = false;
  messages = [];
  messageDates = [];
  unreadMessage = {};
  displaySystemMessage = {};
  messageSection = 1;
  messageLimit = 200;
  maxLength = 120;

  selectedCardID;
  selectedCard;
  userFilter;
  loadMoreUser = false;
  userLoaded = false;
  userIsLoading = false;
  users = [];
  userSection = 1;
  userLimit = 7;

  isAlive = true;
  loadDefaultChat = true;

  primaryColor;
  templateTags;
  globalNumber;

  orderFilter = '-createdDateSeconds';
  isModal = false;

  singleChat = false;
  patientID;

  @Input()
  isContactStaff = false;

  @Input()
  sendOnly = false;

  isDentistsLoaded = false;

  globalLoad = false;

  recieverTableName = 'CustomersAndProspects';

  keyWord = 'KEYWORD.patient';

  loadMerchant = false;
  promoter;
  constructor(
    private _clipboardService: ClipboardService,
    private authenticationService: AuthenticationService,
    private mapsAPILoader: MapsAPILoader,
    private messageService: MessageService,
    private cardService: CardService,
    private customPhonePipe: CustomPhonePipe,

    private utilService: UtilsService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      this.buildData(data, true);
    }
  }

  ngOnInit() {
    this.authenticationService.getPromoter().subscribe((res) => {
      if (res && res.data) {
        this.promoter = res.data;
      } else if (res) {
        this.promoter = res;
      }

      if (this.promoter && this.promoter.ID) {
        this.promoterGlobalNumber = this.promoter['Twilio.Promoter'];
        this.merchantGlobalNumber = this.promoter['Twilio.MerchantGlobal'];
        this.promoterStaffGlobalNumber = this.promoter['Twilio.PromoterTeam'];
      }
    });
    this.globalLoad = false;
    this.activeRoute.params.subscribe((params) => {
      if (params['type'] && params['type'] === 'staff') {
        this.isContactStaff = true;
      }

      if (params['messageType']) {
        this.messageType = params['messageType'];
      }

      if (this.isContactStaff == true) {
        this.recieverTableName = 'Contact';
        this.keyWord = 'Staff Member';
      }

      if (params['data']) {
        this.buildData(decodeURIComponent(params['data']), false);
      }

      this.mapsAPILoader.load().then(() => {
        this.geocoder = new google.maps.Geocoder();
      });

      this.utilService.getCurrentAccess().subscribe((access) => {
        if (access) {
          this.isModuleBulkSMS = access['isModuleBulkSMSActive'];
        }

        this.primaryColor = Settings.global['primaryColor'];
        this.authenticationService.isPromoterOrAdmin().subscribe((r) => {
          this.isPromoterOrAdmin = r;
          if (this.isPromoterOrAdmin === false) {
            this.isPromoterView = false;
            this.loadDefaultChat = true;
          }
          this.authenticationService.getSessionType().subscribe((r) => {
            this.sessionType = r;
            this.authenticationService.isMerchantAdmin().subscribe((isMerchantAdmin) => {
              this.isMerchantAdmin = isMerchantAdmin;

              this.globalLoad = true;
              this.authenticationService
                .getDentistList(
                  {
                    fields: 'ID,CalculatedName',
                    orderBy: 'CalculatedName',
                    merchantID: this.merchantID,
                  },
                  this.isPromoterOrAdmin
                )
                .subscribe((res) => {
                  if (res) {
                    this.dentists = res;
                  }

                  this.isDentistsLoaded = true;
                });

              if (this.singleChat == true && this.cardID) {
                if ((this.sessionType === 'admin' || this.sessionType === 'promoter') && this.merchantID) {
                  this.authenticationService
                    .getMerchantDetails(this.merchantID, {}, this.sessionType)
                    .subscribe((r) => {
                      if (r) {
                        this.merchant = r;
                      }

                      this.loadMerchant = true;

                      this.selectCard({ Card_key: this.cardID, Card_TableName: this.recieverTableName });
                    });
                } else {
                  this.authenticationService.getCurrentPractice().subscribe((r) => {
                    if (r) {
                      this.merchant = r;
                    }

                    this.loadMerchant = true;

                    this.selectCard({ Card_key: this.cardID, Card_TableName: this.recieverTableName });
                  });
                }
              } else {
                if (this.isPromoterOrAdmin == true) {
                  this.authenticationService.getCurrentPractice().subscribe((r) => {
                    if (r && r.ID) {
                      this.promoterID = r.ID;
                    }

                    this.setup();
                  });
                } else {
                  this.setup();
                }
              }
            });
          });
        });
      });
    });
  }

  ngOnDestroy() {
    this.isAlive = false;
  }

  promoterConfiguration() {
    const ref2 = RootAppComponent.dialog.open(SmsPromoterComponent, {
      data: {
        merchantID: this.merchantID,
        templateTags: this.templateTags,
        includeCampaing: this.includeCampaign,
        globalNumber: this.globalNumber,
        messageType: this.messageType,
        loadDefaultChat: this.loadDefaultChat,
        isContactStaff: this.isContactStaff,
      },
      panelClass: 'noCard',
      width: '500px',
    });
    ref2.componentInstance.close.subscribe((data) => {
      ref2.close();
    });

    ref2.componentInstance.getResult.subscribe((data) => {
      if (data) {
        if (this.isContactStaff === true) {
          this.merchantID = data.merchantID;
        } else {
          this.merchantID = data.merchantID;
          this.templateTags = data.templateTags;
          this.includeCampaign = data.includeCampaing;
          this.globalNumber = data.globalNumber;
          this.messageType = data.messageType;
          this.isPromoterView = data.isPromoterView;
          this.loadDefaultChat = data.loadDefaultChat;
          this.singleChat = data.singleChat;
        }

        if (this.isAlive == true) {
          this.userIsLoading = true;
          this.setup();
        }
      }

      ref2.close();
    });
  }

  applyFilter(f = 'newest') {
    this.filterType = f;

    this.unreadMessage = {};

    if (this.filterType == 'unread') {
      this.orderFilter = '-createdDateSeconds';
      this.merchantFilter('-DateTimeCreated', 'In', false);
    } else if (this.filterType == 'newest') {
      this.orderFilter = '-createdDateSeconds';
      this.merchantFilter('-DateTimeCreated', null, null);
    } else if (this.filterType == 'oldest') {
      this.orderFilter = 'createdDateSeconds';
      this.merchantFilter('DateTimeCreated', null, null);
    } else if (this.filterType == 'incoming') {
      this.orderFilter = '-createdDateSeconds';
      this.merchantFilter('-DateTimeCreated', 'In', null);
    } else if (this.filterType == 'outcoming') {
      this.orderFilter = '-createdDateSeconds';
      this.merchantFilter('-DateTimeCreated', 'Out', null);
    }
  }
  merchantFilter(orderBy = null, direction = null, isRead = null) {
    this.userIsLoading = true;
    this.direction = direction;
    this.orderBy = orderBy;
    this.isRead = isRead;
    this.setup();
  }

  setup() {
    this.messageDates = JSON.parse(JSON.stringify([]));
    this.messages = JSON.parse(JSON.stringify([]));
    this.users = JSON.parse(JSON.stringify([]));
    this.selectedCard = null;
    this.selectedCardID = null;

    if (this.isPromoterOrAdmin === true && !this.merchantID) {
      this.isPromoterView = true;
    } else if (
      this.isPromoterOrAdmin === true &&
      this.merchantID &&
      this.promoterID &&
      this.merchantID == this.promoterID
    ) {
      this.isPromoterView = true;
    } else {
      this.isPromoterView = false;
    }

    if (this.loadDefaultChat == true) {
      this.getUserList();
    } else {
      this.userLoaded = true;
      this.userIsLoading = false;
    }

    if ((this.sessionType === 'admin' || this.sessionType === 'promoter') && this.merchantID) {
      this.authenticationService.getMerchantDetails(this.merchantID, {}, this.sessionType).subscribe((r) => {
        if (r) {
          this.merchant = r;
        }

        this.loadMerchant = true;
      });
    } else {
      this.authenticationService.getCurrentPractice().subscribe((r) => {
        if (r) {
          this.merchant = r;
        }

        this.loadMerchant = true;
      });
    }
  }

  getUserList(loadMore = false) {
    this.userIsLoading = true;
    const payload: any = {
      section: 1,
      limit: this.userLimit,
      merchantID: this.merchantID,
      type: this.type,
      isTemplateSupportSMS: true,
      messageType: this.messageType,
      includeCampaing: this.includeCampaign,
      globalNumber: this.globalNumber,
      templateTags: this.templateTags,
      orderBy: [this.orderBy],
      isRead: this.isRead,
      direction: this.direction,
      cardTable: this.recieverTableName,
      fields:
        'ID,BodyBase64,Is_Frontend_Read,LastModified,Destination,Display_Key,Invitation_key,DateTimeCreated,Card_key,Card_Name,Description,Type,Template_Type.Code,Template_Type.Label,' +
        'Direction,Priority,Subject,Card_NumberOrAddress,Status.Code,Status.Label,Date.Read,' +
        'Merchant_key,Merchant_Name,Card_TableName,Sender_Card_key,Sender_Card_Calculated_Name',
    };
    if (loadMore === true) {
      this.userSection = this.userSection + 1;
      payload.section = this.userSection;

      let notCardIDs = [];

      if (this.users && this.users.length > 0) {
        notCardIDs = _.map(this.users, (item) => {
          if (item && item.Card_key) {
            return item.Card_key;
          }

          return null;
        });

        notCardIDs = _.filter(notCardIDs, (e) => {
          if (e) {
            return true;
          }

          return false;
        });
      }

      payload.notCardIDs = notCardIDs;
    } else {
      this.userSection = 1;
      this.userLoaded = false;
    }

    this.messageService.getMessageUserList(payload, this.sessionType).subscribe((_res) => {
      if (_res && _res.length > 0) {
        let res = _res;

        res = _.map(res, (item) => {
          if (item) {
            item = this.buildMessage(item);
          }

          return item;
        });

        let __cardIDs = [];
        let __notCardIDs = [];
        res.forEach((item) => {
          if (item && item.Card_key && item.Is_Frontend_Read === '0' && item.Direction === 'In') {
            __cardIDs.push(item.Card_key);
            __notCardIDs.push(item.ID);
          } else if (item && item.Card_key) {
            __cardIDs.push(item.Card_key);
          }

          return true;
        });

        this.getUnreadCountUsers(__cardIDs, __notCardIDs);

        if (res && res[0] && res[0]['section']) {
          this.userSection = Number(res[0]['section']);
        }

        if (loadMore === true) {
          this.users = this.users.concat(res);

          this.users = JSON.parse(JSON.stringify(this.users));
        } else {
          this.users = res;

          this.users = JSON.parse(JSON.stringify(this.users));
        }

        if (res.length < this.userLimit) {
          this.loadMoreUser = false;
        } else {
          this.loadMoreUser = true;
        }
      } else {
        this.loadMoreUser = false;
      }
      this.userIsLoading = false;
      this.userLoaded = true;

      if (
        this.isAlive == true &&
        this.userIsLoading == false &&
        this.messageIsLoading == false &&
        this.isRefreshRunning == false
      ) {
        this.innerTimeOut();
      }
    });
  }

  getMessageList(loadMore = false) {
    this.messageIsLoading = true;
    if (this.selectedCard && this.selectedCard['ID']) {
      const payload = {
        section: 1,
        limit: this.messageLimit,
        merchantID: this.merchantID,

        messageType: this.messageType,
        includeCampaing: this.includeCampaign,
        globalNumber: this.globalNumber,
        templateTags: this.templateTags,
        type: this.type,
        cardTable: this.recieverTableName,
        isTemplateSupportSMS: true,
        cardID: this.selectedCard['ID'],
        fields:
          'ID,BodyBase64,LastModified,Destination,Display_Key,Invitation_key,DateTimeCreated,Card_key,Card_Name,Description,Type,Template_Type.Code,Template_Type.Label,' +
          'Direction,Priority,Subject,Card_NumberOrAddress,Status.Code,Status.Label,Reject_reason,' +
          'Merchant_key,Merchant_Name,Card_TableName,' +
          'Contract_Merchant_Key,Contract_Merchant_Name,Invitation_Merchant_Key,Invitation_Merchant_Name,InternalMessage_key,' +
          'Internal_Message_Recipient_key,Internal_Message_Recipient_TableName,' +
          'Template_Type.Code,Template_Type.Label,' +
          'Internal_Message_Sender_key,Internal_Message_Sender_TableName,Internal_Message_Sender_Name,' +
          'Sender_Card_key,Sender_Card_Calculated_Name,Message_Group_Key',
      };
      if (loadMore === true) {
        this.messageSection = this.messageSection + 1;
        payload.section = this.messageSection;
      } else {
        this.messageSection = 1;
        this.messageLoaded = false;
      }

      this.messageService.getMessageSMSList(payload, this.sessionType).subscribe((_res) => {
        if (_res && _res.length > 0) {
          let res = _res;

          res = _.map(res, (item) => {
            if (item) {
              item = this.buildMessage(item);
            }

            return item;
          });

          const index = this.users.findIndex((item) => {
            if (
              item &&
              item.Card_key &&
              this.selectedCard &&
              this.selectedCard['ID'] &&
              this.selectedCard['ID'] == item.Card_key
            ) {
              return true;
            }
            return false;
          });

          if (index === -1 && res && res[0] && res[0]['ID']) {
            this.users.push(res[0]);

            this.getUnreadCount(res[0].Card_key);

            this.users = JSON.parse(JSON.stringify(this.users));
            this.scrollUser('bottom');
          }

          if (loadMore === true) {
            this.messages = this.messages.concat(res);
            this.buildMessageList();
          } else {
            this.messages = res;
            this.buildMessageList();
            this.scrollMessage('bottom');
          }

          if (res.length < this.messageLimit) {
            this.loadMoreMessage = false;
          } else {
            this.loadMoreMessage = true;
          }
        } else {
          if (loadMore !== true) {
            this.messages = [];

            this.buildMessageList();
          }
          this.loadMoreMessage = false;
        }
        this.messageIsLoading = false;
        this.messageLoaded = true;

        if (
          this.isAlive == true &&
          this.userIsLoading == false &&
          this.messageIsLoading == false &&
          this.isRefreshRunning == false
        ) {
          this.innerTimeOut();
        }
      });
    }
  }

  buildMessageList() {
    if (this.messages && this.messages.length > 0) {
      this.buildDisplayMessage(this.messages);
      this.messageDates = _.uniqBy(this.messages, 'createDate');
    } else {
      if (this.selectedCard && this.selectedCard.Card_key) {
        this.displaySystemMessage[this.selectedCard.Card_key] = {};
      }
      this.messageDates = [];
    }
  }

  statisticsMessage() {
    if (this.selectedCard && this.selectedCard.ID) {
      const ref = RootAppComponent.dialog.open(SmsStatisticsComponent, {
        data: {
          cardID: this.selectedCard.ID,
          merchantID: this.merchantID,
          tableName: this.selectedCard.Card_TableName,
          LastModified: this.selectedCard.LastModified,
          cardName: this.selectedCard.CalculatedName,
          isTemplateSupportSMS: true,
          messageType: this.messageType,
          includeCampaing: this.includeCampaign,
          globalNumber: this.globalNumber,
          templateTags: this.templateTags,
          type: 'S',
        },
        panelClass: 'noCard',
        width: '454px',
        height: '750px',
      });

      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    }
  }

  editUnread(id, hidden = true) {
    if (this.isAlive == true) {
      if (id && (this.isPromoterOrAdmin !== true || this.isPromoterView != false)) {
        const payload = {
          cardID: id,
          startFromRaw: this.unreadMinDate,
          isRead: false,
          notCurrentSender: true,
          direction: 'In',
          type: 'S',
        };

        if (this.isPromoterOrAdmin === true) {
          payload.notCurrentSender = null;
        }

        if (hidden != true) {
          this.messageService.editRead(payload, this.sessionType).subscribe((stats) => {
            this.displaySystemMessage[id] = {};
            this.unreadOneMessage = {};
          });
        } else {
          this.messageService.editReadHidden(payload, this.sessionType).subscribe((stats) => {
            this.displaySystemMessage[id] = {};
            this.unreadOneMessage = {};
          });
        }
      }
    }
  }

  getUnreadCount(id, notIDs = null) {
    if (this.isAlive == true) {
      if (id && (this.isPromoterOrAdmin !== true || this.isPromoterView != false)) {
        const payload = {
          cardID: id,
          merchantID: this.merchantID,
          selectAll: false,
          isRead: false,
          notCurrentSender: true,
          isTemplateSupportSMS: true,
          messageType: this.messageType,
          includeCampaing: this.includeCampaign,
          globalNumber: this.globalNumber,
          templateTags: this.templateTags,
          cardTable: this.recieverTableName,
          direction: 'In',
          notIDs: notIDs || null,
          type: 'S',
        };

        if (this.isPromoterOrAdmin === true) {
          payload.notCurrentSender = null;
        }

        this.messageService.getMessageUserStatisticsListHidden(payload, this.sessionType).subscribe((stats) => {
          let totalMessage = 0;

          if (stats && stats[0] && stats[0]['value'] > 0) {
            totalMessage = Number(stats[0]['value']);
          } else {
            totalMessage = 0;
          }
          this.unreadMessage[id] = totalMessage;
        });
      }
    }
  }

  getUnreadCountUsers(cardIDs, notIDs = null) {
    if (this.isAlive == true) {
      if (cardIDs && cardIDs.length > 0 && (this.isPromoterOrAdmin !== true || this.isPromoterView != false)) {
        const payload = {
          cardIDs: cardIDs.join(','),
          merchantID: this.merchantID,
          selectAll: false,
          isRead: false,
          notCurrentSender: true,
          isTemplateSupportSMS: true,
          messageType: this.messageType,
          includeCampaing: this.includeCampaign,
          globalNumber: this.globalNumber,
          templateTags: this.templateTags,
          cardTable: this.recieverTableName,
          direction: 'In',
          notIDs: notIDs || null,
          type: 'S',
        };

        if (this.isPromoterOrAdmin === true) {
          payload.notCurrentSender = null;
        }

        this.messageService.getMessageUserStatisticsListHidden(payload, this.sessionType).subscribe((stats) => {
          if (stats && stats.length > 0) {
            for (var i = 0; i < stats.length; i++) {
              let totalMessage = 0;
              if (stats && stats[i] && stats[i]['cardID']) {
                if (stats && stats[i] && stats[i]['value'] > 0) {
                  totalMessage = Number(stats[i]['value']);
                } else {
                  totalMessage = 0;
                }

                this.unreadMessage[stats[i]['cardID']] = totalMessage;
              }
            }
          }
        });
      }
    }
  }

  selectCardNoConversation(u) {
    if (
      u &&
      u.Card_key &&
      (!this.selectedCard || (this.selectedCard && this.selectedCard.ID && this.selectedCard.ID != u.Card_key))
    ) {
      this.messageService.getCardDetails(u.Card_key, u.Card_TableName, {}, this.sessionType).subscribe((res) => {
        if (res) {
          this.selectedCard = res;

          if (this.selectedCard && this.selectedCard.tags && this.selectedCard.tags.length > 0) {
            this.selectedCard.miniTags = this.selectedCard.tags.slice(0, 4);
          }

          this.selectedCard['Card_TableName'] = u.Card_TableName;
          this.selectedCard['Card_key'] = u.Card_key;

          this.calculateDistance();
        }
      });
    } else if (this.selectedCard && this.selectedCard.ID && u && u.Card_key && u.Card_key === this.selectedCard.ID) {
      this.scrollMessage('bottom', true);
    }
  }

  selectCard(u) {
    if (
      u &&
      u.Card_key &&
      (!this.selectedCard || (this.selectedCard && this.selectedCard.ID && this.selectedCard.ID != u.Card_key))
    ) {
      // const payload = {
      //   cardID: u.Card_key,
      //   merchantID: this.merchantID,
      //   selectAll: true,

      //   messageType: this.messageType,
      //   includeCampaing: this.includeCampaign,
      //   globalNumber: this.globalNumber,
      //   templateTags: this.templateTags,
      //   cardTable: this.recieverTableName,

      //   isTemplateSupportSMS: true,
      //   type: 'S',
      // };

      this.editUnread(u.Card_key);

      // this.messageService.getMessageUserStatisticsList(payload, this.sessionType).subscribe((stats) => {
      //   if (stats && stats[0] && stats[0]['value'] > 0) {
      //     this.totalMessage = stats[0]['value'];
      //   } else {
      //     this.totalMessage = 0;
      //   }
      // });

      this.messageService.getCardDetails(u.Card_key, u.Card_TableName, {}, this.sessionType).subscribe((res) => {
        if (res) {
          this.selectedCard = res;

          if (this.selectedCard && this.selectedCard.tags && this.selectedCard.tags.length > 0) {
            this.selectedCard.miniTags = this.selectedCard.tags.slice(0, 4);
          }

          this.selectedCard['Card_TableName'] = u.Card_TableName;
          this.selectedCard['Card_key'] = u.Card_key;

          this.calculateDistance();
          this.getMessageList();
        }
      });
      this.unreadMessage[u.Card_key] = null;
    } else if (this.selectedCard && this.selectedCard.ID && u && u.Card_key && u.Card_key === this.selectedCard.ID) {
      this.editUnread(u.Card_key);
      this.unreadMessage[u.Card_key] = null;
      this.scrollMessage('bottom', true);
    }
  }

  calculateDistance() {
    this.distanceAway = null;
    if (this.selectedCard && this.selectedCard['ID']) {
      this.util
        .getDistanceAway(
          this.merchant['Address.Latitude'],
          this.merchant['Address.Longitude'],
          this.selectedCard['Address.Latitude'],
          this.selectedCard['Address.Longitude'],
          this.selectedCard['addresses.Calculated'],
          this.geocoder
        )
        .then((distanceAwayResult) => {
          if (distanceAwayResult) {
            this.distanceAway = distanceAwayResult.distanceAway;
            const latitude = distanceAwayResult.targetLatitude;
            const longitude = distanceAwayResult.targetLongitude;

            if (distanceAwayResult.generatedNewCoordinates) {
              this.messageService
                .addCoordinate(
                  this.selectedCard['ID'],
                  {
                    latitude,
                    longitude,
                  },
                  this.selectedCard['Card_TableName']
                )
                .subscribe((r) => {});
            }
          }
        });
    }
  }

  displayMap(u) {
    if (u) {
      const location = {
        zoom: 17,
        postCode: u['addresses.Postcode'],
        state: u['addresses.State'],
        streetType: u['addresses.Street Type'],
        streetName: u['addresses.Street Name'],
        streetNumber: u['addresses.Street Nr'],
        suburb: u['addresses.Suburb'],
        country: u['addresses.Country.Code'],
        addressLabel: u['addresses.Calculated'],
        latitude: u['Address.Latitude'],
        longitude: u['Address.Longitude'],
      };

      const ref = RootAppComponent.dialog.open(MapViewComponent, {
        data: location,
        panelClass: 'modal-map',
        width: '800px',
        height: '600px',
      });

      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    }
  }

  URLify(_string, patientID, invitationID) {
    const merchantLink = Settings.global['merchantFrontendLink'];

    let _invitationID;
    if (invitationID && invitationID !== '20202020202020202020202020202020') {
      _invitationID = invitationID;
    }

    if (_string) {
      let string = _string;
      let urls = string.match(/(https?:\/\/[^\s]+)/g) || [];

      let localURLs = string.match(/(http?:\/\/[^\s]+)/g);

      if (localURLs && localURLs.length > 0) {
        urls = urls.concat(localURLs);
      }
      if (urls) {
        urls = _.uniq(urls);
        urls.forEach((url) => {
          let title = this.util.getLinkTitle(url);

          if (title) {
            string = string
              .split(url)
              .join(
                '<a target="_blank"  style="margin-right:10px !important;" class="inner-link" href="' +
                  url +
                  '"> ' +
                  title +
                  ' </a>'
              );
          } else {
            string = string
              .split(url)
              .join(
                '<a target="_blank"  style="margin-right:10px !important;" class="inner-link" href="' +
                  url +
                  '"> ' +
                  url +
                  ' </a>'
              );
          }
        });
      }

      if (_invitationID && patientID) {
        string = string.replace(
          /###/g,
          `<a target="_blank" class="inner-link" href="${merchantLink}/merchant/(page:customer-profile/${patientID}/treatment/${_invitationID})">View Treatment Invitation</a>`
        );
      } else if (patientID) {
        string = string.replace(
          /###/g,
          `<a target="_blank" class="inner-link" href="${merchantLink}/merchant/(page:customer-profile/${patientID}/patient)">View Patient Profile</a>`
        );
      }

      string = string.replace(/\n/g, '<br>');
      string = string.replace(/   /g, '<br>');
      return string;
    }

    return '';
  }

  scrollUser(scroll: any = 0, isAnimated = false) {
    if (this.singleChat != true) {
      let duration = 0;

      if (isAnimated === true) {
        duration = 1000;

        if (scroll === 'top') {
          setTimeout(() => {
            $('#user-list-body').animate(
              {
                scrollTop: 0,
              },
              duration
            );
          }, 200);
        } else if (scroll === 'bottom') {
          const div = document.getElementById('user-list-body');
          setTimeout(() => {
            $('#user-list-body').animate(
              {
                scrollTop: div.scrollHeight - div.clientHeight,
              },
              duration
            );
          }, 200);
        } else {
          setTimeout(() => {
            $('#user-list-body').animate(
              {
                scrollTop: scroll,
              },
              duration
            );
          }, 200);
        }
      } else {
        if (scroll === 'top') {
          setTimeout(() => {
            $('#user-list-body').animate(
              {
                scrollTop: 0,
              },
              duration
            );
          }, 200);
        } else if (scroll === 'bottom') {
          setTimeout(() => {
            const div = document.getElementById('user-list-body');

            $('#user-list-body').animate(
              {
                scrollTop: div.scrollHeight - div.clientHeight,
              },
              duration
            );
          }, 200);
        } else {
          setTimeout(() => {
            $('#user-list-body').animate(
              {
                scrollTop: scroll,
              },
              duration
            );
          }, 200);
        }
      }
    }
  }

  scrollMessage(scroll: any = 0, isAnimated = false) {
    let duration = 0;

    if (isAnimated === true) {
      duration = 1000;

      if (scroll === 'top') {
        setTimeout(() => {
          $('#message-list-container').animate(
            {
              scrollTop: 0,
            },
            duration
          );
        }, 200);
      } else if (scroll === 'bottom') {
        const div = document.getElementById('message-list-container');
        setTimeout(() => {
          $('#message-list-container').animate(
            {
              scrollTop: div.scrollHeight - div.clientHeight + 50,
            },
            duration
          );
        }, 200);
      } else {
        setTimeout(() => {
          $('#message-list-container').animate(
            {
              scrollTop: scroll,
            },
            duration
          );
        }, 200);
      }
    } else {
      if (scroll === 'top') {
        setTimeout(() => {
          $('#message-list-container').animate(
            {
              scrollTop: 0,
            },
            duration
          );
        }, 200);
      } else if (scroll === 'bottom') {
        setTimeout(() => {
          const div = document.getElementById('message-list-container');

          $('#message-list-container').animate(
            {
              scrollTop: div.scrollHeight - div.clientHeight,
            },
            duration
          );
        }, 200);
      } else {
        setTimeout(() => {
          $('#message-list-container').animate(
            {
              scrollTop: scroll,
            },
            duration
          );
        }, 200);
      }
    }
  }

  getStatusColor(status) {
    let p = {
      background: this.buildRBD(this.primaryColor, '0.4'),
      color: this.primaryColor,
      isError: false,
      isWarning: false,
      isDelayed: false,
    };

    if (status === 'RECV') {
      p = {
        background: this.buildRBD('#02a826', '0.4'),
        color: '#02a826',
        isError: false,
        isWarning: false,
        isDelayed: false,
      };
    } else if (status == 'DLYD') {
      p = {
        background: this.buildRBD('#da910a', '0.6'),
        color: '#da910a',
        isError: false,
        isWarning: true,
        isDelayed: false,
      };
    } else if (status == '') {
      p = {
        background: this.buildRBD('#7e7d7d', '0.3'),
        color: '#7e7d7d',
        isError: false,
        isWarning: false,
        isDelayed: false,
      };
    } else if (status === 'SCHE' || status === 'PEND' || status === 'QUED') {
      p = {
        background: this.buildRBD('#7e7d7d', '0.3'),
        color: '#7e7d7d',
        isError: false,
        isWarning: false,
        isDelayed: true,
      };
    } else if (
      status === 'REJ' ||
      status == 'BNCE_H' ||
      status == 'BNCE_S' ||
      status == 'FAIL' ||
      status == 'INVL' ||
      status == 'BLACK' ||
      status == 'SPAM' ||
      status == 'Error' ||
      status == 'BOUNCE'
    ) {
      p = {
        background: this.buildRBD('#d00e00', '0.3'),
        color: '#d00e00',
        isError: true,
        isWarning: false,
        isDelayed: false,
      };
    }

    return p;
  }

  buildRBD(color, opacity = '0.8') {
    if (color) {
      const rbg = hexRgb(color);

      if (rbg) {
        let red = rbg.red;
        let green = rbg.green;
        let blue = rbg.blue;

        let result = this.tintColor(red, green, blue, 0.75);

        if (result) {
          red = result.red;
          green = result.green;
          blue = result.blue;

          result = this.tintColor(red, green, blue, 0.75);
        }

        return `rgb(${result.red},  ${result.green} ,${result.blue} , ${opacity})`;
      } else {
        return null;
      }
    }
  }

  tintColor(r, g, b, factor = 0.25) {
    const red = Number(r) + factor * (255 - r);
    const green = Number(g) + factor * (255 - g);
    const blue = Number(b) + factor * (255 - b);

    return {
      red: Number(red).toFixed(0),
      green: Number(green).toFixed(0),
      blue: Number(blue).toFixed(0),
    };
  }

  messageIsLoadingEvent(e) {
    this.messageIsLoading = e;
  }

  selectPatient() {
    let data: any = {
      merchantID: this.merchantID,
      onlyMyAccount: true,
      displayChatButton: false,
      useStrict: true,
      hasMobile: true,
    };

    if (this.isPromoterOrAdmin === true) {
      data = {
        merchantID: this.merchantID,
        onlyMyAccount: false,
        displayMerchantList: false,
        displayChatButton: false,
        useStrict: true,
        hasMobile: true,
      };
    }
    const ref = RootAppComponent.dialog.open(InvitationLookupComponent, {
      data: data,
      width: '600px',
    });
    ref.componentInstance.closeModal.subscribe((data) => {
      ref.close();
    });

    ref.componentInstance.getSelectedPatient.subscribe((u) => {
      if (u && u.ID) {
        const tableName = u['TableName'];
        this.messageService.getCardDetails(u.ID, tableName, {}, this.sessionType).subscribe((res) => {
          if (res) {
            this.selectedCard = res;
            if (this.selectedCard && this.selectedCard.tags && this.selectedCard.tags.length > 0) {
              this.selectedCard.miniTags = this.selectedCard.tags.slice(0, 4);
            }
            this.selectedCard['Card_TableName'] = tableName;
            this.selectedCard['Card_key'] = res.ID;

            this.calculateDistance();
            this.getMessageList();
          }

          ref.close();
        });
      }
    });

    ref.componentInstance.detailedView.subscribe((res) => {
      ref.close();
      ref.afterClosed().subscribe((r) => {
        this.closeEvent();
      });
    });
  }
  selectContact() {
    const ref = RootAppComponent.dialog.open(ContactLookupComponent, {
      data: {
        merchantID: this.merchantID,
        onlyMyAccount: true,
        displayChatButton: false,
        displayMerchantList: false,
        isNewStaff: false,
        hasMobile: true,
        useStrict: true,
      },
      width: '650px',
    });
    ref.componentInstance.closeModal.subscribe((data) => {
      ref.close();
    });

    ref.componentInstance.getSelectedContact.subscribe((u) => {
      if (u && u.ID) {
        const tableName = u['TableName'];

        debugger;
        this.messageService.getCardDetails(u.ID, tableName, {}, this.sessionType).subscribe((res) => {
          if (res) {
            this.selectedCard = res;
            if (this.selectedCard && this.selectedCard.tags && this.selectedCard.tags.length > 0) {
              this.selectedCard.miniTags = this.selectedCard.tags.slice(0, 4);
            }
            this.selectedCard['Card_TableName'] = tableName;
            this.selectedCard['Card_key'] = res.ID;

            this.calculateDistance();
            this.getMessageList();
          }

          ref.close();
        });
      }
    });

    ref.componentInstance.detailedView.subscribe((res) => {
      ref.close();
      ref.afterClosed().subscribe((r) => {
        this.closeEvent();
      });
    });
  }

  clearUserFilter() {
    this.userFilter = null;
  }

  clearMessageFilter() {
    this.messageFilter = null;
  }

  closeEvent() {
    this.close.emit(true);
  }

  getMessageEvent(msg) {
    this.message = msg;
  }

  fullScreenSMS() {
    let message;

    const ref = RootAppComponent.dialog.open(SmsSendComponent, {
      data: {
        merchant: this.merchant,
        card: this.selectedCard,
        tableName: this.selectedCard['Card_TableName'],
        message: this.message,
        isModal: true,
      },
      panelClass: 'noCard',
      width: '900px',
    });
    ref.componentInstance.close.subscribe((data) => {
      this.message = message;
      this.defaultMessage = message;

      ref.close();
    });

    ref.componentInstance.getResult.subscribe((data) => {
      if (data && data.createdRecord) {
        this.totalMessage = Number(this.totalMessage) + 1;

        data.createdRecord.hideRefresh = true;

        this.insertMessage(data.createdRecord, false, 'top', 'bottom', true);

        this.getLatestMessageUpdate(data.createdRecord);

        ref.close();
      }
    });

    ref.componentInstance.messageIsLoadingEvent.subscribe((data) => {
      this.messageIsLoading = data;
    });
    ref.componentInstance.getMessage.subscribe((data) => {
      message = data;
    });

    ref.backdropClick().subscribe((data) => {
      this.message = message;
      this.defaultMessage = message;
    });
  }

  resendSMS(msg) {
    if (msg && msg.Body && msg.Card_key) {
      this.messageIsLoading = true;

      const payload = {
        cardID: msg.Card_key,
        body: msg.Body,
        dentistID: null,
        patientID: null,
        merchantID: this.merchantID,
        invitationID: msg.Invitation_key,
        contactID: msg.Contract_key,
        iStaffMessage: this.isContactStaff,
      };

      if (msg.Card_TableName === 'Contact') {
        payload.cardID = msg.Card_key;
      } else if (
        msg.Card_TableName === 'Prospect' ||
        msg.Card_TableName === 'CustomersAndProspects' ||
        msg.Card_TableName === 'Customer'
      ) {
        payload.patientID = msg.Card_key;
      }

      if (this.selectedCard['MarketingConsent.Given'] != '1' && payload.patientID) {
        const confirmDialog = new ConfirmDialog(
          'info',
          `${this.marketingConsentTitle}`,
          `<p>${this.marketingConsentDescription}</p>`,
          'No',
          'Update consent to marketing'
        );

        const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
          data: confirmDialog,
        });

        ref.componentInstance.onConfirm.subscribe((res) => {
          if (res == true) {
            let payloadPatient = {
              isMarketing: true,
              marketingConsent: true,
            };
            this.cardService
              .modifyCard(payload.patientID, payloadPatient, this.recieverTableName, this.sessionType)
              .subscribe((r) => {
                this.messageService.sendSMS(payload, this.sessionType).subscribe((res) => {
                  if (res) {
                    let cardName;
                    if (this.selectedCard) {
                      cardName = this.selectedCard.CalculatedName;
                    }

                    this.selectedCard['MarketingConsent.Given'] = res['MarketingConsent.Given'];

                    if (this.selectedCard && this.selectedCard['Card_TableName'] === 'Merchant') {
                      cardName = this.selectedCard.TradingAs;
                    }

                    if (res && (res.Card_Name === '' || res.Card_Name === '')) {
                      res.Card_Name = cardName;
                    }
                    res.hideRefresh = true;

                    this.messageIsLoading = false;

                    this.totalMessage = Number(this.totalMessage) + 1;

                    NotifyAppComponent.displayToast('success', 'Successfull', 'Message is sent');

                    this.insertMessage(res, false, 'top', null, true);

                    this.getLatestMessageUpdate(res);
                  }

                  ref.close();
                });
              });
          } else {
            ref.close();
          }
        });
      } else {
        this.messageService.sendSMS(payload, this.sessionType).subscribe((res) => {
          if (res) {
            let cardName;
            if (this.selectedCard) {
              cardName = this.selectedCard.CalculatedName;
            }

            if (this.selectedCard && this.selectedCard['Card_TableName'] === 'Merchant') {
              cardName = this.selectedCard.TradingAs;
            }

            if (res && (res.Card_Name === '' || res.Card_Name === '')) {
              res.Card_Name = cardName;
            }
            res.hideRefresh = true;

            this.messageIsLoading = false;

            this.totalMessage = Number(this.totalMessage) + 1;

            NotifyAppComponent.displayToast('success', 'Successfull', 'Message is sent');

            this.insertMessage(res, false, 'top', null, true);

            this.getLatestMessageUpdate(res);
          }
        });
      }
    }
  }

  previewSMS(cardID, tableName, body, card) {
    if ((cardID || card) && body) {
      const ref = RootAppComponent.dialog.open(SmsPreviewComponent, {
        data: {
          card,
          cardID,
          tableName,
          message: body,
        },
        panelClass: ['noCard', 'bigger-screen'],
        width: '375px',
      });
      ref.componentInstance.close.subscribe((data) => {
        ref.close();
      });
    }
  }

  getResultEvent(d) {
    if (d && d.createdRecord) {
      if (
        d.createdRecord &&
        this.selectedCard &&
        this.selectedCard.ID &&
        d.createdRecord.Card_key === this.selectedCard.ID &&
        d.createdRecord.customerTags &&
        d.createdRecord.customerTags.length > 0
      ) {
        this.selectedCard.miniTags = d.createdRecord.customerTags.slice(0, 4);
      }

      if (d['MarketingConsent'] === '1') {
        this.selectedCard['MarketingConsent.Given'] = '1';
      }

      this.totalMessage = Number(this.totalMessage) + 1;
      d.createdRecord.hideRefresh = true;
      this.insertMessage(d.createdRecord, false, 'top', 'bottom', true);

      this.getLatestMessageUpdate(d.createdRecord);
    }
  }

  unarchivePatient() {
    if (this.selectedCard && this.selectedCard.ID) {
      const confirmDialog = new ConfirmDialog(
        'fas fa-trash-restore',
        'Do you wish to continue?',
        '<p><strong>Unarchive the Record?</strong></p><p class="noFurther">He/She will start recieving SMS communication.</p>',
        'No',
        'Yes, unarchive'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirmDialog,
      });

      ref.componentInstance.onConfirm.subscribe((res) => {
        if (res == true) {
          const payload = {
            status: 'Active',
          };

          this.cardService.modifyCustomerProspect(this.selectedCard.ID, payload, this.sessionType).subscribe((res) => {
            if (res) {
              this.selectedCard = JSON.parse(JSON.stringify(res));
              if (this.selectedCard && this.selectedCard.tags && this.selectedCard.tags.length > 0) {
                this.selectedCard.miniTags = this.selectedCard.tags.slice(0, 4);
              } else {
                this.selectedCard.miniTags = JSON.parse(JSON.stringify([]));
              }

              this.selectedCard['Card_TableName'] = this.recieverTableName;
              this.selectedCard['Card_key'] = res.ID;

              NotifyAppComponent.displayToast(
                'success',
                'Successful operation',
                'The patient has been successfully unarchived'
              );

              ref.close();
            }
          });
        } else {
          ref.close();
        }
      });
    }
  }

  insertMessage(_message, isUnread = false, userListScroll = null, messageListScroll = null, updateRecords = true) {
    let message = _message;

    if (message && message.ID && message.Card_key) {
      message = this.buildMessage(message);

      message.isUnread = isUnread;

      const index = this.users.findIndex((item) => {
        if (item && item.Card_key && item.Card_key === message.Card_key) {
          if (isUnread == true && message.Direction === 'In') {
            if (!this.unreadMessage[item.Card_key]) {
              this.unreadMessage[item.Card_key] = 1;
            } else {
              this.unreadMessage[item.Card_key] = this.unreadMessage[item.Card_key] + 1;
            }
          }
          return true;
        }
        return false;
      });

      if (index != -1) {
        this.users[index] = message;
        if (updateRecords === true) {
          this.users = JSON.parse(JSON.stringify(this.users));
        }
      } else {
        this.users.push(message);

        this.getUnreadCount(message.Card_key);

        if (updateRecords === true) {
          this.users = JSON.parse(JSON.stringify(this.users));
        }
        if (userListScroll !== null) {
          this.scrollUser(userListScroll, true);
        }
      }

      const indexMessageData = this.messageDates.findIndex((item) => {
        if (item && item.createDate === message.createDate) {
          return true;
        }
        return false;
      });

      if (indexMessageData === -1) {
        this.messageDates.push(message);
        if (updateRecords === true) {
          this.messageDates = JSON.parse(JSON.stringify(this.messageDates));
        }
      }

      if (message.Card_key && this.selectedCard && this.selectedCard.ID && this.selectedCard.ID === message.Card_key) {
        const indexMessageID = this.messages.findIndex((item) => {
          if (item && item.ID && item.ID === message.ID) {
            return true;
          }
          return false;
        });

        if (indexMessageID === -1) {
          this.messages.push(message);
        } else {
          this.messages[indexMessageID] = message;
        }

        if (updateRecords === true) {
          this.messages = JSON.parse(JSON.stringify(this.messages));
        }

        if (messageListScroll !== null) {
          this.scrollMessage(messageListScroll, true);
        }
      }
    }
  }

  innerTimeOut() {
    if (this.loadDefaultChat === true) {
      setTimeout(() => {
        if (this.loadDefaultChat === true) {
          if (this.isAlive == true && this.messageIsLoading == false && this.userIsLoading == false) {
            this.isRefreshRunning = true;
            this.refreshMessage(true, false);
            this.innerTimeOut();
          } else if (this.messageIsLoading == false && this.userIsLoading == false) {
            this.isRefreshRunning = true;
            this.innerTimeOut();
          }
        } else {
          this.isRefreshRunning = false;
        }
      }, 60 * 1000);
    } else {
      this.isRefreshRunning = false;
    }
  }

  refreshSelectedMessage(hidden = false, scroll = true) {
    if (this.selectedCard && this.selectedCard['Card_key']) {
      const cardID = this.selectedCard['Card_key'];
      const messages = _.orderBy(this.messages, ['createdDateSeconds']);

      let recentMessage;
      let minDate = null;
      if (messages && messages.length > 0) {
        recentMessage = messages[messages.length - 1];
      }

      if (recentMessage && recentMessage['DateTimeCreated']) {
        minDate = recentMessage['DateTimeCreated'];
      }

      const payload = {
        section: 1,
        limit: 100,
        merchantID: this.merchantID,
        type: this.type,
        addCardName: true,
        isTemplateSupportSMS: true,
        cardID: this.selectedCard['Card_key'],
        messageType: this.messageType,
        includeCampaing: this.includeCampaign,
        globalNumber: this.globalNumber,
        templateTags: this.templateTags,
        cardTable: this.recieverTableName,
        startFromRaw: minDate,
        fields:
          'ID,BodyBase64,LastModified,Destination,Display_Key,Invitation_key,DateTimeCreated,Card_key,Card_Name,Description,Type,Template_Type.Code,Template_Type.Label,' +
          'Direction,Priority,Subject,Card_NumberOrAddress,Status.Code,Status.Label,Reject_reason,' +
          'Merchant_key,Merchant_Name,Card_TableName,' +
          'Contract_Merchant_Key,Contract_Merchant_Name,Invitation_Merchant_Key,Invitation_Merchant_Name,InternalMessage_key,' +
          'Internal_Message_Recipient_key,Internal_Message_Recipient_TableName,' +
          'Template_Type.Code,Template_Type.Label,' +
          'Internal_Message_Sender_key,Internal_Message_Sender_TableName,Internal_Message_Sender_Name,' +
          'Sender_Card_key,Sender_Card_Calculated_Name,Message_Group_Key',
      };

      this.messageService.getMessageSMSList(payload, this.sessionType, hidden).subscribe((_res) => {
        if (
          _res &&
          _res.length > 0 &&
          this.isAlive == true &&
          this.userIsLoading == false &&
          this.messageIsLoading == false &&
          this.selectedCard &&
          this.selectedCard['Card_key'] === cardID
        ) {
          let res = _res;

          res = _.map(res, (it) => {
            if (it && it.DateTimeCreated) {
              it = this.buildMessage(it);
            }

            return it;
          });

          res = _.orderBy(res, ['createdDateSeconds']);

          for (let i = 0; i < res.length; i++) {
            this.insertMessage(res[i], true, null, null, false);
          }

          this.messageDates = JSON.parse(JSON.stringify(this.messageDates));
          this.messages = JSON.parse(JSON.stringify(this.messages));

          if (scroll === true) {
            this.scrollMessage('bottom', true);
          }

          // NotifyAppComponent.displayToast('info', 'You have a new SMS Message. \n Click on SMS icon to view', 'Info');
        }
      });
    }
  }

  refreshMessage(hidden = false, scroll = true) {
    if (this.filterType != 'oldest') {
      const users = _.orderBy(this.users, ['createdDateSeconds']);

      let recentMessage;
      let minDate = null;
      if (users && users.length > 0) {
        recentMessage = users[users.length - 1];
      }

      if (recentMessage && recentMessage['DateTimeCreated']) {
        minDate = recentMessage['DateTimeCreated'];
      }

      const payload = {
        section: 1,
        limit: 100,
        merchantID: this.merchantID,
        type: this.type,
        addCardName: true,
        isTemplateSupportSMS: true,
        orderBy: [this.orderBy],
        isRead: this.isRead,
        direction: this.direction,
        messageType: this.messageType,
        includeCampaing: this.includeCampaign,
        globalNumber: this.globalNumber,
        templateTags: this.templateTags,
        cardTable: this.recieverTableName,
        startFromRaw: minDate,
        fields:
          'ID,BodyBase64,LastModified,Destination,Display_Key,Invitation_key,DateTimeCreated,Card_key,Card_Name,Description,Type,Template_Type.Code,Template_Type.Label,' +
          'Direction,Priority,Subject,Card_NumberOrAddress,Status.Code,Status.Label,Reject_reason,' +
          'Merchant_key,Merchant_Name,Card_TableName,' +
          'Contract_Merchant_Key,Contract_Merchant_Name,Invitation_Merchant_Key,Invitation_Merchant_Name,InternalMessage_key,' +
          'Internal_Message_Recipient_key,Internal_Message_Recipient_TableName,' +
          'Template_Type.Code,Template_Type.Label,' +
          'Internal_Message_Sender_key,Internal_Message_Sender_TableName,Internal_Message_Sender_Name,' +
          'Sender_Card_key,Sender_Card_Calculated_Name,Message_Group_Key',
      };

      this.messageService.getMessageSMSList(payload, this.sessionType, hidden).subscribe((_res) => {
        if (
          _res &&
          _res.length > 0 &&
          this.isAlive == true &&
          this.userIsLoading == false &&
          this.messageIsLoading == false
        ) {
          let res = _res;

          res = _.map(res, (it) => {
            if (it && it.DateTimeCreated) {
              it = this.buildMessage(it);
            }

            return it;
          });

          res = _.orderBy(res, ['createdDateSeconds']);

          for (let i = 0; i < res.length; i++) {
            this.insertMessage(res[i], true, null, null, false);
          }

          this.users = JSON.parse(JSON.stringify(this.users));
          this.messageDates = JSON.parse(JSON.stringify(this.messageDates));
          this.messages = JSON.parse(JSON.stringify(this.messages));

          if (scroll === true) {
            this.scrollUser('top', true);
            this.scrollMessage('bottom', true);
          }

          // NotifyAppComponent.displayToast('info', 'You have a new SMS Message. \n Click on SMS icon to view', 'Info');
        }
      });
    }
  }

  refreshOneMessage(message, isHidden = true) {
    if (
      message &&
      message.ID &&
      (message['Status.Code'] === '' ||
        message['Status.Code'] === 'QUED' ||
        message['Status.Code'] === 'SENT' ||
        message['Status.Code'] === 'SCHE' ||
        message['Status.Code'] === 'UNKN' ||
        message['Status.Code'] === 'DLYD' ||
        message['Status.Code'] === 'PEND')
    ) {
      const cardName = message.Card_Name;

      this.messageService.getMessageDetails(message.ID, {}, this.sessionType, isHidden).subscribe((_res) => {
        if (_res && _res.ID) {
          const res = _res;

          if (res.Card_Name === '' || !res.Card_Name) {
            res.Card_Name = cardName;
          }

          if (res.Card_key === this.selectedCard.ID) {
            this.insertMessage(res, false, null, null, true);
          }

          const status = res['Status.Code'];

          if (status === 'RECV') {
            NotifyAppComponent.displayToast('success', 'Successfull', `Message has been Received .`);
          } else if (status == 'DLYD') {
            NotifyAppComponent.displayToast('warning', 'Warning', `Message has been Delayed .`);
          } else if (
            status === 'REJ' ||
            status == 'BNCE_H' ||
            status == 'BNCE_S' ||
            status == 'FAIL' ||
            status == 'INVL' ||
            status == 'BLACK' ||
            status == 'SPAM' ||
            status == 'Error' ||
            status == 'BOUNCE'
          ) {
            NotifyAppComponent.displayToast('error', 'Error', `Message failed to send .`);
          }
        }
      });
    }
  }

  getLatestMessageUpdate(message) {
    if (
      message &&
      message.ID &&
      (message['Status.Code'] === '' ||
        message['Status.Code'] === 'QUED' ||
        message['Status.Code'] === 'SENT' ||
        message['Status.Code'] === 'SCHE' ||
        message['Status.Code'] === 'UNKN' ||
        message['Status.Code'] === 'DLYD' ||
        message['Status.Code'] === 'PEND')
    ) {
      setTimeout(() => {
        if (this.isAlive == true) {
          this.refreshOneMessage(message, true);
        }
      }, 10 * 1000);
    }
  }

  buildMessage(_res) {
    const res = _res;

    if (res && res.ID) {
      if (res.BodyBase64) {
        res.Body = this.util.b64DecodeUnicode(res.BodyBase64);
      }

      if (res.Body) {
        let patientID;

        if (
          res.Card_TableName === 'Prospect' ||
          res.Card_TableName === 'CustomersAndProspects' ||
          res.Card_TableName === 'Customer'
        ) {
          patientID = res.Card_key;
        }

        res.BodyHTML = this.URLify(res.Body, patientID, res.Invitation_key);
      }
      res.search =
        res['Card_Name'] +
        ' ' +
        res['Body'] +
        ' ' +
        res['Card_TableName'] +
        ' ' +
        res['Card_NumberOrAddress'] +
        ' ' +
        res['Sender_Card_Calculated_Name'];
      res.createDate = moment(res['DateTimeCreated'], 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD');
      res.dateSentSeconds = moment(res['createDate'], 'YYYY-MM-DD').toDate().getTime();
      res.createdDateSeconds = moment(res['DateTimeCreated'], 'YYYY-MM-DD HH:mm:ss').toDate().getTime();
      if (res['Card_NumberOrAddress']) {
        res.phoneAddress = this.customPhonePipe.transform(res['Card_NumberOrAddress']);
        if (res.phoneAddress) {
          res.phoneAddress = res.phoneAddress.replace(/\s/g, '');
          res.search = res.search + ' ' + res.phoneAddress;
        }
      }
      if (res['Destination']) {
        res.destinationPhone = this.customPhonePipe.transform(res['Destination']);

        if (res.destinationPhone) {
          res.destinationPhone = res.destinationPhone.replace(/\s/g, '');
          res.search = res.search + ' ' + res.destinationPhone;
        }
      }
    }

    return res;
  }

  openPatientProfile(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(CustomerProspectViewModelComponent, {
        data: {
          patientID: id,
          isFullDetails: true,
        },
        width: '700px',
      });
      ref.componentInstance.close.subscribe((res) => {
        if (res == true) {
          ref.close();
        }
      });

      ref.componentInstance.detailedView.subscribe((res) => {
        if (res == true) {
          ref.close();
          ref.afterClosed().subscribe((r) => {
            this.closeEvent();
          });
        }
      });
    }
  }
  contactDentistByEmail(id) {
    if (id) {
      var data = {
        targetType: 'contact',
        targetID: id,
        asGuest: false,
        asProfile: true,
        asPractice: true,
      };
      const ref = RootAppComponent.dialog.open(ContactInputComponent, {
        width: '650px',
        data,
      });

      ref.componentInstance.closeModal.subscribe((res) => {
        ref.close();
      });
    }
  }
  openContactProfile(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(DentistViewComponent, {
        data: id,
        width: '550px',
      });
      ref.componentInstance.close.subscribe((res) => {
        if (res == true) {
          ref.close();
        }
      });
    }
  }
  modify(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(CustomerProspectCreateComponent, {
        data: {
          customerID: id,
          isFullDetails: true,
        },
        width: '800px',
      });
      ref.componentInstance.close.subscribe((res) => {
        if (res == true) {
          ref.close();
        }
      });
      ref.componentInstance.getResult.subscribe((res) => {
        if (res) {
          if (res.ID && this.selectedCard && this.selectedCard['Card_key'] === res.ID) {
            this.buildSelectedCard(res);
          }
          ref.close();
        }
      });
    }
  }

  modifyContact(id) {
    if (id) {
      const ref2 = RootAppComponent.dialog.open(ContactCreateEditModalComponent, {
        data: {
          contactID: id,
          isEmailRequired: false,
          isMobileRequired: true,
        },
        width: '850px',
      });

      ref2.componentInstance.close.subscribe((res) => {
        ref2.close();
      });
      ref2.componentInstance.getResult.subscribe((res) => {
        if (res) {
          if (res.ID && this.selectedCard && this.selectedCard['Card_key'] === res.ID) {
            this.buildSelectedCard(res);
          }
          ref2.close();
        }
      });
    }
  }

  modifyTags(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(CustomerProspectCreateComponent, {
        data: {
          customerID: id,
          isMinView: true,
        },
        width: '600px',
      });
      ref.componentInstance.close.subscribe((res) => {
        if (res == true) {
          ref.close();
        }
      });
      ref.componentInstance.getResult.subscribe((res) => {
        if (res) {
          if (res.ID && this.selectedCard && this.selectedCard['Card_key'] === res.ID) {
            this.buildSelectedCard(res);
          }
          ref.close();
        }
      });
    }
  }
  showMessage(cardID, messageID, isShow = true) {
    if (cardID && messageID && this.displaySystemMessage) {
      if (this.displaySystemMessage[cardID]) {
        this.displaySystemMessage[cardID][messageID] = isShow;
      } else {
        this.displaySystemMessage[cardID] = {
          messageID: isShow,
        };
      }
    }
  }

  personalMessageChange() {
    this.buildDisplayMessage(this.messages);
  }

  buildDisplayMessage(res) {
    if (res && res.length > 0 && this.selectedCard && this.selectedCard.Card_key) {
      res.map((item) => {
        if (
          item &&
          item.ID &&
          item.TemplateTag != 'promoter-SMS-Template' &&
          item.TemplateTag != 'merchant-SMS-Template' &&
          item.TemplateTag != 'DefaultInbox-SMS' &&
          item.TemplateTag != 'Promoter-SMS-Template-Bulk' &&
          item.TemplateTag != 'Merchant-SMS-Template-Bulk' &&
          item.TemplateTag != 'Promoter-SMS-Template-Staff' &&
          item.TemplateTag != 'Merchant-SMS-Template-Staff' &&
          item.TemplateTag != 'promoter-SMS-Template-Bulk' &&
          item.TemplateTag != 'merchant-SMS-Template-Bulk' &&
          item.TemplateTag != 'promoter-SMS-Template-Staff' &&
          item.TemplateTag != 'merchant-SMS-Template-Staff' &&
          item.TemplateTag != 'defaultInbox-SMS' &&
          item.Direction != 'In'
        ) {
          if (this.displaySystemMessage[this.selectedCard.Card_key]) {
            this.displaySystemMessage[this.selectedCard.Card_key][item.ID] = !this.personalMessageOnly;
          } else {
            const o = {};
            o[item.ID] = !this.personalMessageOnly;
            this.displaySystemMessage[this.selectedCard.Card_key] = o;
          }
        } else if (
          item &&
          item.ID &&
          (item.TemplateTag == 'promoter-SMS-Template' ||
            item.TemplateTag == 'merchant-SMS-Template' ||
            item.TemplateTag == 'Promoter-SMS-Template-Bulk' ||
            item.TemplateTag == 'Merchant-SMS-Template-Bulk' ||
            item.TemplateTag == 'Promoter-SMS-Template-Staff' ||
            item.TemplateTag == 'Merchant-SMS-Template-Staff' ||
            item.TemplateTag == 'promoter-SMS-Template-Bulk' ||
            item.TemplateTag == 'merchant-SMS-Template-Bulk' ||
            item.TemplateTag == 'promoter-SMS-Template-Staff' ||
            item.TemplateTag == 'merchant-SMS-Template-Staff' ||
            item.TemplateTag == 'defaultInbox-SMS' ||
            item.TemplateTag == 'DefaultInbox-SMS' ||
            item.Direction != 'In')
        ) {
          if (this.displaySystemMessage[this.selectedCard.Card_key]) {
            this.displaySystemMessage[this.selectedCard.Card_key][item.ID] = true;
          } else {
            const o = {};
            o[item.ID] = true;
            this.displaySystemMessage[this.selectedCard.Card_key] = o;
          }
        }
      });
    }
  }

  newPatient() {
    const ref2 = RootAppComponent.dialog.open(CustomerProspectCreateComponent, {
      data: {
        merchantID: this.merchantID,
        isEmailRequired: false,
        isMobileRequired: true,
      },
      width: '850px',
    });

    ref2.componentInstance.close.subscribe((res) => {
      ref2.close();
    });
    ref2.componentInstance.getResult.subscribe((res) => {
      if (res && res.ID) {
        ref2.close();

        this.buildSelectedCard(res);
        this.calculateDistance();
        this.getMessageList();
      }
    });
  }

  newContact() {
    const ref2 = RootAppComponent.dialog.open(ContactCreateEditModalComponent, {
      data: {
        merchantID: this.merchantID,
        isEmailRequired: false,
        isMobileRequired: true,
      },
      width: '850px',
    });

    ref2.componentInstance.close.subscribe((res) => {
      ref2.close();
    });
    ref2.componentInstance.getResult.subscribe((res) => {
      if (res && res.ID) {
        ref2.close();

        this.buildSelectedCard(res);
        this.calculateDistance();
        this.getMessageList();
      }
    });
  }
  buildSelectedCard(res) {
    if (res && res.ID) {
      this.selectedCard = JSON.parse(JSON.stringify(res));
      if (this.selectedCard && this.selectedCard.tags && this.selectedCard.tags.length > 0) {
        this.selectedCard.miniTags = this.selectedCard.tags.slice(0, 4);
      } else {
        this.selectedCard.miniTags = JSON.parse(JSON.stringify([]));
      }

      this.selectedCard['Card_TableName'] = this.recieverTableName;
      this.selectedCard['Card_key'] = res.ID;
    }
  }

  copy(d) {
    if (d) {
      this._clipboardService.copy(d);
      NotifyAppComponent.displayToast('success', 'Success', 'Copied');
    }
  }

  copyPhone(d) {
    if (d) {
      this._clipboardService.copy(this.customPhonePipe.transform(d));
      NotifyAppComponent.displayToast('success', 'Success', 'Copied');
    }
  }

  viewMerchant(ID) {
    if (ID) {
      let ref = RootAppComponent.dialog.open(MerchantViewComponent, {
        data: ID,
        width: '600px',
      });

      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    }
  }

  userUnreadMessage(cardID, message, isRead = false) {
    if (message && message.ID && message.Direction === 'In') {
      if (this.selectedCard && this.selectedCard.ID === cardID) {
        this.selectedCard.noUnreadUpdate = true;
      }

      this.messageService.editMessage(message.ID, { isRead: isRead }, this.sessionType).subscribe((r) => {
        if (isRead == true && r && r.previousRead == false) {
          if (!this.unreadMessage[cardID]) {
            this.unreadMessage[cardID] = 0;
          } else if (this.unreadMessage[cardID] > 1) {
            this.unreadMessage[cardID] = this.unreadMessage[cardID] - 1;
          } else {
            this.unreadMessage[cardID] = 0;
          }

          this.unreadOneMessage[message.ID] = false;
        } else if (isRead != true && r && r.previousRead == true) {
          if (!this.unreadMessage[cardID]) {
            this.unreadMessage[cardID] = 1;
          } else {
            this.unreadMessage[cardID] = this.unreadMessage[cardID] + 1;
          }

          this.unreadOneMessage[message.ID] = true;
        }
      });
    }
  }

  buildData(_data, isModal = false) {
    if (_data) {
      let data = _data;

      if (typeof data === 'string') {
        data = JSON.parse(data);
      }

      if (data && data.isContactStaff == true) {
        this.isContactStaff = true;

        this.isModal = isModal;
      } else if (data && data.isContactStaff == false) {
        this.isContactStaff = false;

        this.isModal = isModal;
      }

      if (data && data.singleChat == true) {
        this.singleChat = true;

        this.isModal = isModal;
      } else if (data && data.singleChat == false) {
        this.singleChat = false;

        this.isModal = isModal;
      }
      if (data && data.sendOnly == true) {
        this.sendOnly = true;

        this.isModal = isModal;
      } else if (data && data.sendOnly == false) {
        this.sendOnly = false;

        this.isModal = isModal;
      }

      if (data && data.cardID) {
        this.cardID = data.cardID;

        this.isModal = isModal;
      }
      if (data && data.patientID) {
        this.cardID = data.patientID;

        this.isModal = isModal;
      } else if (data && data.contactID) {
        this.cardID = data.contactID;

        this.isModal = isModal;
      }
      if (data && data.messageType) {
        this.messageType = data.messageType;

        this.isModal = isModal;
      }
      if (data && data.includeCampaing === true) {
        this.includeCampaign = data.includeCampaing;

        this.isModal = isModal;
      } else if (data && data.includeCampaing === false) {
        this.includeCampaign = data.includeCampaing;

        this.isModal = isModal;
      }
      if (data && data.templateTags) {
        this.templateTags = data.templateTags;

        this.isModal = isModal;
      }
      if (data && data.globalNumber) {
        this.globalNumber = data.globalNumber;

        this.isModal = isModal;
      }

      if (data && data.invitationID) {
        this.invitationID = data.invitationID;

        this.isModal = isModal;
      }

      if (data && data.financeOfferID) {
        this.financeOfferID = data.financeOfferID;

        this.isModal = isModal;
      }

      if (data && data.contractID) {
        this.contractID = data.contractID;

        this.isModal = isModal;
      }

      if (data && data.merchantID) {
        this.merchantID = data.merchantID;

        this.isModal = isModal;
      }
      if (data && data.loadDefaultChat == true) {
        this.loadDefaultChat = true;

        this.isModal = isModal;
      } else if (data && data.loadDefaultChat == false) {
        this.loadDefaultChat = false;

        this.isModal = isModal;
      }

      if (data && data.isModal == true) {
        this.isModal = isModal;
      }
    }
  }

  messageGroupList() {
    this.closeEvent();
    this.router.navigate(['/merchant', { outlets: { page: ['message-group-create'] } }]);
  }

  isPromoterSMS(message) {
    if (message) {
      if (
        String(message['TemplateTag']).toLocaleLowerCase() == String('Promoter-SMS-Template-Bulk').toLocaleLowerCase()
      ) {
        return true;
      }
      if (
        String(message['TemplateTag']).toLocaleLowerCase() == String('Promoter-SMS-Template-Staff').toLocaleLowerCase()
      ) {
        return true;
      }

      if (String(message['TemplateTag']).toLocaleLowerCase() == String('Promoter-SMS-Template').toLocaleLowerCase()) {
        return true;
      }

      if (this.promoter && this.promoter.ID) {
        if (message['Direction'] === 'In') {
          if (message['Destination'] == this.promoter['Twilio.Promoter']) {
            return true;
          } else if (message['Destination'] == this.promoter['Twilio.PromoterTeam']) {
            return true;
          }
        } else {
          if (message['Card_NumberOrAddress'] == this.promoter['Twilio.Promoter']) {
            return true;
          } else if (message['Card_NumberOrAddress'] == this.promoter['Twilio.PromoterTeam']) {
            return true;
          }
        }
      }
    }

    return false;
  }

  isCampaignSMS(message) {
    if (message && message['TemplateTag']) {
      if (String(message['TemplateTag']).toLocaleLowerCase().indexOf('campaign') !== -1) {
        return true;
      }
    }

    return false;
  }

  isMerchantSMS(message) {
    if (message) {
      if (
        String(message['TemplateTag']).toLocaleLowerCase() == String('Merchant-SMS-Template-Bulk').toLocaleLowerCase()
      ) {
        return true;
      }
      if (
        String(message['TemplateTag']).toLocaleLowerCase() == String('Merchant-SMS-Template-Staff').toLocaleLowerCase()
      ) {
        return true;
      }

      if (String(message['TemplateTag']).toLocaleLowerCase() == String('Merchant-SMS-Template').toLocaleLowerCase()) {
        return true;
      }

      if (message['Direction'] === 'In') {
        if (this.promoter && this.promoter.ID && message['Destination'] == this.promoter['Twilio.MerchantGlobal']) {
          return true;
        } else if (this.merchant && this.merchant.ID && message['Destination'] == this.merchant['Twilio_Number']) {
          return true;
        }
      } else {
        if (
          this.promoter &&
          this.promoter.ID &&
          message['Card_NumberOrAddress'] == this.promoter['Twilio.MerchantGlobal']
        ) {
          return true;
        } else if (
          this.merchant &&
          this.merchant.ID &&
          message['Card_NumberOrAddress'] == this.merchant['Twilio_Number']
        ) {
          return true;
        }
      }
    }

    return false;
  }

  getPhoneNature(message) {
    if (message && this.isPromoterOrAdmin == true) {
      if (message['Direction'] === 'In') {
        if (this.promoter && this.promoter.ID && message['Destination'] == this.promoter['Twilio.Promoter']) {
          return '(Promoter phone number)';
        } else if (
          this.promoter &&
          this.promoter.ID &&
          message['Destination'] == this.promoter['Twilio.PromoterTeam']
        ) {
          return '(Promoter Staff phone number)';
        } else if (
          this.promoter &&
          this.promoter.ID &&
          message['Destination'] == this.promoter['Twilio.MerchantGlobal']
        ) {
          return '(Merchant Global phone number)';
        } else if (this.merchant && this.merchant.ID && message['Destination'] == this.merchant['Twilio_Number']) {
          return '(Merchant phone number)';
        }

        return '';
      } else {
        if (this.promoter && this.promoter.ID && message['Card_NumberOrAddress'] == this.promoter['Twilio.Promoter']) {
          return '(Promoter phone number)';
        } else if (
          this.promoter &&
          this.promoter.ID &&
          message['Card_NumberOrAddress'] == this.promoter['Twilio.PromoterTeam']
        ) {
          return '(Promoter Staff phone number)';
        } else if (
          this.promoter &&
          this.promoter.ID &&
          message['Card_NumberOrAddress'] == this.promoter['Twilio.MerchantGlobal']
        ) {
          return '(Merchant Global phone number)';
        } else if (
          this.merchant &&
          this.merchant.ID &&
          message['Card_NumberOrAddress'] == this.merchant['Twilio_Number']
        ) {
          return '(Merchant phone number)';
        }
      }
    }

    return '';
  }

  getPhoneNumberType(message) {
    if (message && this.isPromoterOrAdmin == true) {
      if (message['Destination']) {
        return `<br><strong>&#8226; Sent to:</strong> ${this.customPhonePipe.transform(message['Destination'])} `;
      }
    }

    return '';
  }

  splitCamelCase(word) {
    // Use a regular expression to split the camel case word
    let splittedWord = word.replace(/([a-z])([A-Z])/g, '$1 $2');
    return splittedWord;
  }

  formatStringTemplate(inputString) {
    // Replace '-' with space
    let formattedString = inputString.replace(/-/g, ' ');

    // Split the formatted string into words
    let words = formattedString.split(' ');

    // Iterate over each word
    for (let i = 0; i < words.length; i++) {
      // Capitalize the first letter of each word
      words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
    }

    // Join the words back into a single string
    formattedString = words.join(' ');

    // Split camel case words
    formattedString = this.splitCamelCase(formattedString);

    return formattedString;
  }
  getTemplate(message) {
    if (message && this.isPromoterOrAdmin == true) {
      if (message['TemplateTag']) {
        return `<br><strong>&#8226; Template:</strong> ${this.formatStringTemplate(message['TemplateTag'])} `;
      }
    }

    return '';
  }

  displayMessageGroup(id) {
    if (id) {
      AuthenticationService.displayMessageGroup.emit(id);
    }
  }
}
