import { Component, EventEmitter, Inject, Input, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { ClipboardService } from 'ngx-clipboard';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { NotifyAppComponent } from '../../types/notify-app-component';

@Component({
  selector: 'app-html-simple-link-input',
  templateUrl: './html-simple-link-input.component.html',
  styleUrls: ['./html-simple-link-input.component.css'],
})
export class HtmlSimpleLinkInputComponent implements OnInit {
  @Input()
  link;

  @Input()
  title;

  @Input()
  content;

  @Input()
  linkTitle;

  defaultContent;
  defaultLink;

  @Input()
  type = 'image';

  @Input()
  description =
    'Click on Copy to Copy the code or Insert to add it to your content, You can Preview the element before proceeding.';

  @Input()
  isCopy = true;

  @Input()
  isPreview = true;

  @Input()
  isMerchant = true;

  @Input()
  isInsert = true;
  isModal = true;
  close = new EventEmitter();
  getResult = new EventEmitter();
  getCopy = new EventEmitter();
  buttonFontSizerem = [
    '0.5rem',
    '0.6rem',
    '0.7rem',
    '0.8rem',
    '0.9rem',
    '1rem',
    '1.1rem',
    '1.2rem',
    '1.3rem',
    '1.4rem',
    '1.5rem',
    '1.6rem',
    '1.7rem',
    '1.8rem',
    '1.9rem',
    '2rem',
    '2.1rem',
    '2.2rem',
    '2.3rem',
    '2.4rem',
    '2.5rem',
    '2.6rem',
    '2.7rem',
    '2.8rem',
    '2.9rem',
    '3rem',
    '3.1rem',
    '3.2rem',
  ];

  buttonFontSizepx = [
    '8px',
    '9px',
    '10px',
    '11px',
    '12px',
    '13px',
    '14px',
    '15px',
    '16px',
    '17px',
    '18px',
    '19px',
    '20px',
    '21px',
    '22px',
    '23px',
    '24px',
    '25px',
    '26px',
    '27px',
    '28px',
    '29px',
    '30px',
  ];

  getInsert = new EventEmitter();
  merchants;

  merchant;
  isPromoterOrAdmin = false;
  componentID = 'ID' + btoa(Math.random().toString()).substring(2, 20);
  style;

  linkColor = '#1b8bdd';
  linkFontSize = '1rem';

  width = '100%';
  height = '500px';

  extensionType;

  constructor(
    private authenticationService: AuthenticationService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private _clipboardService: ClipboardService
  ) {
    if (data) {
      if (data.title) {
        this.title = data.title;
        this.linkTitle = data.title;
      }

      if (data.extensionType) {
        this.extensionType = data.extensionType;
      }
      if (data.style) {
        this.style = data.style;
      }
      if (data.content) {
        this.content = data.content;
        this.defaultContent = data.content;
      }

      if (data.linkTitle) {
        this.linkTitle = data.linkTitle;
      }

      if (data.width) {
        this.width = data.width;
      }

      if (data.height) {
        this.height = data.height;
      }

      if (data.link) {
        this.link = data.link;
        this.defaultLink = data.link;
      }
      if (data.isCopy !== null) {
        this.isCopy = data.isCopy;
      }
      if (data.isPreview !== null) {
        this.isPreview = data.isPreview;
      }
      if (data.isMerchant !== null) {
        this.isMerchant = data.isMerchant;
      }

      if (data.type) {
        this.type = data.type;
      }
      if (data.isInsert !== null) {
        this.isInsert = data.isInsert;
      }
    }
  }

  ngOnInit() {
    this.authenticationService.isPromoterOrAdmin().subscribe((r) => {
      this.isPromoterOrAdmin = r;

      if (this.type === 'image') {
        this.width = '100%';
        this.height = 'auto';
      } else if (this.type == 'iframe') {
        this.width = '100%';
        this.height = '500px';
      }

      this.authenticationService.getCurrentPractice().subscribe((res) => {
        if (res) {
          this.merchant = res;
          this.build();
        }

        if (this.isPromoterOrAdmin == true) {
          this.authenticationService
            .getMerchantList({ fields: 'ID,Terminal_Code,TradingAs' })
            .subscribe((merchants) => {
              if (merchants) {
                this.merchants = merchants;

                if (this.merchant && this.merchant.ID) {
                  const index = this.merchants.findIndex((_obj) => _obj.ID == this.merchant.ID);

                  if (index != -1) {
                    this.merchant = this.merchants[index];
                  }
                }
              }
            });
        }
      });
    });
  }

  preview() {
    this.build();
    if (this.link) {
      window.open(this.link, '_blank');
    }
  }

  build() {
    if (this.defaultLink) {
      this.link = this.defaultLink;
    }

    if (this.defaultContent) {
      this.content = this.defaultContent;
    }
    if (this.merchant && this.merchant.ID && this.merchant.Terminal_Code) {
      if (this.link) {
        this.link = this.link
          .replace('MERCHANT_ID_INSERT', this.merchant.ID)
          .replace('MERCHANT_TERMINAL_CODE_INSERT', this.merchant.Terminal_Code);
      }

      if (this.content) {
        this.content = this.content
          .replace('MERCHANT_ID_INSERT', this.merchant.ID)
          .replace('MERCHANT_TERMINAL_CODE_INSERT', this.merchant.Terminal_Code);
      }
    }

    if (this.linkTitle && this.defaultContent) {
      this.content = this.defaultContent.replace('LINK_TEXT', this.linkTitle);
    }

    if (this.componentID && this.defaultContent) {
      this.content = this.defaultContent.replace('COMPONENT_ID', this.componentID);
    }

    if (this.type == 'link') {
      let inlineStyle = ` cursor: pointer;  text-decoration: underline;    display: block ;`;

      this.style = `/* START: ${this.title} Link*/\n\n#${this.componentID}{\n`;

      if (this.linkColor) {
        inlineStyle = inlineStyle + `color:${this.linkColor} ;`;
      } else {
        inlineStyle = inlineStyle + `color: #1b8bdd ;`;
      }
      if (this.linkFontSize) {
        inlineStyle = inlineStyle + `font-size:${this.linkFontSize};`;
      } else {
        inlineStyle = inlineStyle + `font-size: 1rem ;`;
      }

      this.style =
        this.style +
        `\n}\n\n#${this.componentID}:hover{\n
        text-decoration: none   !important;
    } `;

      this.style = this.style + ` \n/* END: ${this.title} Link*/\n\n`;

      if (this.link) {
        this.content = `<!--START: ${this.title} -->\n <a id = "${this.componentID}"  style = "${inlineStyle}"  href="${this.link}" target ="_blank" >${this.linkTitle}</a>\n<!--END: ${this.title}-->`;
      }
    } else if (this.type == 'iframe') {
      let iframeType;

      if (this.extensionType == 'html') {
        iframeType = 'text/html';
      } else if (this.extensionType == 'pdf') {
        iframeType = 'application/pdf';
      }

      const inlineStyle = `max-width: 100%;min-height:300px;margin: 0 auto;border: none;width:${this.width}; height:${this.height} `;

      this.style = `/* START: ${this.title} Iframe*/\n\n#${this.componentID} {\n
        \n
      }\n`;

      if (iframeType) {
        this.content = `<!--START: ${this.title} Iframe-->\n<iframe  id="${this.componentID}" type="${iframeType}"   style="${inlineStyle}"
            src="${this.link}" title="${this.title}">
        </iframe>\n<!--END:  ${this.title} Iframe-->`;
      } else {
        this.content = `<!--START: ${this.title} Iframe-->\n<iframe  id="${this.componentID}"  "style="${inlineStyle}"
            src="${this.link}" title="${this.title}">
        </iframe>\n<!--END:  ${this.title} Iframe-->`;
      }
    } else if (this.type == 'image') {
      this.content = `<!--START: ${this.title}-->\n<img id="${this.componentID}"  style="max-width:100%;width:${this.width};height:${this.height};" src="${this.link}" />\n<!--END: ${this.title}-->`;
      this.style = `/* START: ${this.title}*/\n\n#${this.componentID}{\n
        \n}\n/* END: ${this.title} */\n\n`;
    }
  }

  copyContent(d) {
    if (d) {
      this._clipboardService.copy(d);

      NotifyAppComponent.displayToast('success', 'success', 'Copied');
    }
  }

  copy() {
    this.build();
    if (this.content) {
      this.copyContent(this.content);
      const p = {
        content: this.content,
        style: this.style,
        isCopy: true,
        isReplace: false,
      };

      this.getCopy.emit(p);
      this.getResult.emit(p);
    }
  }

  insert() {
    this.build();
    if (this.content) {
      const p = {
        content: this.content,
        isCopy: false,
        isReplace: false,
        style: this.style,
      };

      this.getInsert.emit(p);
      this.getResult.emit(p);
    }
  }

  closeEvent() {
    this.close.emit(true);
  }
}
