import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { AuthenticationService } from '../../authentication/shared/authentication.service';
import { SubscriptionPlanService } from '../shared/subscription-plan.service';

import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { CalendlyComponent } from '../../../shared/components/calendly/calendly.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { SubscriptionPlanCongratulationComponent } from '../subscription-plan-congratulation/subscription-plan-congratulation.component';
import { Settings } from '../../../shared/types/settings';

@Component({
  selector: 'app-subscription-plan-card-list',
  templateUrl: './subscription-plan-card-list.component.html',
  styleUrls: ['./subscription-plan-card-list.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.animate', style({ opacity: 0 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.4s ease-out',
              keyframes([
                style({ opacity: 0, offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.animate', style({ opacity: 1 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.4s ease-in',
              keyframes([
                style({ opacity: 1, offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class SubscriptionPlanCardListComponent implements OnInit {
  subscriptionPlans = [];
  sessionType;
  root = '/merchant';

  @Input()
  featureType = 'all';

  selectedIDs = [];

  context = Settings.global['context']

  constructor(
    private subscriptionPlanService: SubscriptionPlanService,
    private authenticationService: AuthenticationService,
    private activeRoute: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    UtilsClass.scrollUp();
    this.activeRoute.params.subscribe((params) => {
      if (params['featureType']) {
        this.featureType = params['featureType'];
      }

      if (this.featureType === 'consumer') {
        this.root = '/consumer';
      } else {
        this.root = '/merchant';
      }
      this.authenticationService.getSessionType().subscribe((res) => {
        this.sessionType = res;
        this.subscriptionPlanService
          .getPromoterPlanList(
            {
              isActive: true,
              isPublic: true,
              selectCurrentPlan: true,
              addCustomization: true,
              orderBy: '-Is_Public,Price',
            },
            this.sessionType
          )
          .subscribe((list) => {
            if (list) {
              this.subscriptionPlans = list;
            }
          });
      });
    });
  }

  remove(id) {
    if (id) {
      const index = this.subscriptionPlans.findIndex((item) => {
        if (item && item.ID && item.ID == id) {
          return true;
        }
        return false;
      });

      if (index !== -1) {
        this.subscriptionPlans.splice(index, 1);
        this.subscriptionPlans = JSON.parse(JSON.stringify(this.subscriptionPlans));
      }
    }
  }

  comparePlans() {
    this.router.navigate([
      this.root,
      {
        outlets: {
          page: ['subscription-plan-overview-all', this.featureType],
        },
      },
    ]);
  }

  Features() {
    this.router.navigate([
      this.root,
      {
        outlets: {
          page: ['main-feature-list', this.featureType],
        },
      },
    ]);
  }

  booking() {
    const ref = RootAppComponent.dialog.open(CalendlyComponent, {
      width: '650px',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  congratulation() {
    const ref = RootAppComponent.dialog.open(SubscriptionPlanCongratulationComponent, {
      width: '1000px',
      height: '600px',
      panelClass: 'noCard',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  check(id) {
    if (id) {
      const index = this.selectedIDs.indexOf(id);

      if (index === -1) {
        this.selectedIDs.push(id);
      } else {
        this.selectedIDs.splice(index, 1);
      }
    }
  }

  compareSelected() {
    if (this.selectedIDs && this.selectedIDs.length > 0) {
      this.selectedIDs = _.uniq(this.selectedIDs);

      this.router.navigate([
        this.root,
        {
          outlets: {
            page: ['subscription-plan-overview', this.selectedIDs.join(','), this.featureType],
          },
        },
      ]);
    }
  }
}
