<ipv-title-modal
  [title]="'Google Reviews Business Settings '"
  [customClass]="'info'"
  [icon]="'settings'"
  [hideAllDefaultButtons]="true"
>
  <div content>
    <ng-container *ngIf="isServiceReady === true; else preparingServiceSpinner">
      <mat-vertical-stepper #googleReviewsAccountStepper [linear]="true" orientation="vertical">
        <mat-step id="step-1" [label]="selectAccountLabel" [editable]="isStep1Enabled">
          <ng-container>
            <ng-container *ngIf="businessAccounts$ | async as businessAccounts; else fetchingAccounts">
              <table
                class="mat-elevation-z8"
                *ngIf="isStep1Enabled"
                [dataSource]="businessAccounts"
                style="width: 100%"
                mat-table
              >
                <tr *matHeaderRowDef="displayedBusinessAccountColumns" mat-header-row></tr>

                <!-- Account Name Column -->
                <ng-container matColumnDef="accountName">
                  <th
                    *matHeaderCellDef="let businessAccount"
                    mat-header-cell
                    style="background-color: #b3b3b3; color: black; text-align: center"
                    matStepperNext
                  >
                    Account Name
                  </th>
                  <td
                    *matCellDef="let businessAccount"
                    (click)="
                      setSelectedBusinessAccountId(businessAccount.name, businessAccount.accountName);
                      googleReviewsAccountStepper.next()
                    "
                    mat-cell
                    style="cursor: pointer"
                  >
                    {{ businessAccount.accountName }}
                  </td>
                </ng-container>

                <!-- Account Type Column -->
                <ng-container matColumnDef="type">
                  <th
                    *matHeaderCellDef
                    mat-header-cell
                    style="background-color: #b3b3b3; color: black; text-align: center"
                  >
                    Type
                  </th>
                  <td
                    *matCellDef="let businessAccount"
                    (click)="setSelectedBusinessAccountId(businessAccount.name, businessAccount.accountName)"
                    mat-cell
                    matStepperNext
                    style="cursor: pointer"
                  >
                    {{ businessAccount.type }}
                  </td>
                </ng-container>

                <tr *matRowDef="let row; columns: displayedBusinessAccountColumns" mat-row></tr>
              </table>

              <b>
                <p *ngIf="!isStep1Enabled" style="text-align: center; color: green">Account Selected!</p>
              </b>
            </ng-container>

            <ng-template #fetchingAccounts>
              <p style="text-align: center">Fetching Google My Business Accounts...</p>
            </ng-template>
          </ng-container>
        </mat-step>

        <mat-step id="step-2" [label]="selectBusinessLabel" [editable]="isStep2Enabled">
          <ng-container *ngIf="!isStep1Enabled; else selectAccountFirstMessage">
            <ng-container *ngIf="businessLocations$ | async as businessLocations; else fetchingLocations">
              <table
                class="mat-elevation-z8"
                *ngIf="isStep2Enabled"
                [dataSource]="businessLocations"
                mat-table
                style="width: 100%"
              >
                <tr *matHeaderRowDef="displayedBusinessLocationColumns" mat-header-row></tr>

                <!-- Business Column -->
                <ng-container matColumnDef="business">
                  <th *matHeaderCellDef mat-header-cell style="background-color: #b3b3b3; color: black">Business</th>
                  <td
                    *matCellDef="let location"
                    (click)="setSelectedPlaceId(location.metadata.placeId, location.title, location.name)"
                    mat-cell
                    style="cursor: pointer"
                  >
                    {{ location.title }} - {{ location.storefrontAddress.regionCode }},
                    {{ location.storefrontAddress.locality }}, {{ location.storefrontAddress.sublocality }},
                    {{ location.storefrontAddress.addressLines[0] }}
                  </td>
                </ng-container>

                <tr *matRowDef="let row; columns: displayedBusinessLocationColumns" mat-row></tr>
              </table>

              <ng-container #settingPlaceId *ngIf="!isStep2Enabled && isPlaceIdSaving">
                <p style="text-align: center">Saving Business Details...</p>
              </ng-container>

              <button
                *ngIf="!isStep2Enabled && !isPlaceIdSaving"
                (click)="saveBusinessDetails()"
                mat-raised-button
                color="primary"
                style="display: block; margin: 0px auto"
              >
                Try again
              </button>
            </ng-container>

            <ng-template #fetchingLocations>
              <p style="text-align: center">Searching For Business...</p>
            </ng-template>
          </ng-container>

          <ng-template #selectAccountFirstMessage>
            <p style="text-align: left; font-weight: bold">Select an account first...</p>
          </ng-template>
        </mat-step>
      </mat-vertical-stepper>
    </ng-container>

    <ng-template #preparingServiceSpinner>
      <p style="text-align: center; margin-top: 30px">Preparing Google My Business Service...</p>
    </ng-template>
  </div>
</ipv-title-modal>
