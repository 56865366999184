import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Direction, SwingCardComponent, SwingStackComponent } from 'angular2-swing';

@Component({
  selector: 'app-swipe-cards',
  templateUrl: './swipe-cards.component.html',
  styleUrls: ['./swipe-cards.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.anim', style({ opacity: 0 }), { optional: true }),
        query(
          '.anim',
          stagger('100ms', [
            animate(
              '0.4s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-20%)', offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.anim', style({ opacity: 1 }), { optional: true }),
        query(
          '.anim',
          stagger('100ms', [
            animate(
              '0.4s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-20%)', offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class SwipeCardsComponent implements OnInit {
  posts = [
    {
      id: '234534756841',
      treatmentQty: '1',
      treatmentValue: '120.00',
      treatmentCategory: 'Whole of mouth',
      treatmentType: 'Whitening',
      treatmentBrand: 'Philips',
      treatmentProduct: 'Zoom! Take Home Kit',
      treatmentImage:
        'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/treatment/zoom-chair.jpg',
      description:
        'A bridge is a fixed dental restoration used to replace one or more missing teeth by joining an artificial tooth definitively to adjacent teeth or dental implants.',
      selected: false,
    },
    {
      id: '234534756842',
      treatmentQty: '1',
      treatmentValue: '48.00',
      treatmentCategory: 'Whole of mouth',
      treatmentType: 'Cleaning',
      treatmentBrand: 'Scale & Clean',
      treatmentProduct: 'Scale & Clean',
      treatmentImage:
        'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/treatment/scale-clean.jpg',
      description:
        'Fillings may be required if tooth structure has been lost due to decay or trauma. During a regular check-up, your dentist will examine your teeth for these defects, and using X-rays to pinpoint the location and extent of decay, will then decide on the best method to restore the integrity of your tooth, which may include fillings.',
      selected: false,
    },
    {
      id: '234534756843',
      treatmentQty: '1',
      treatmentValue: '225.00',
      treatmentCategory: 'Whole of mouth',
      treatmentType: 'Whitening',
      treatmentBrand: 'Philips',
      treatmentProduct: 'Zoom! In-chair whitening',
      treatmentImage:
        'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/treatment/zoom-chair.jpg',
      description:
        'There are many causes of teeth discolouration. Some of the most common include the consumption of highly coloured foods and drinks (berries, curry, coffee, tea, red wine). Some medications, aging, smoking and trauma can also cause teeth discolouration.',
      selected: false,
    },
  ];

  public stackConfig: any;
  // public showImage: boolean = false;
  public visibleElement: any;
  public currentPost = -1;
  public isPressed = false;
  public onInit = true;
  public voting = false;
  public commend: boolean = null;

  public anim = false;

  @Output() close = new EventEmitter();
  // close: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('postStacks', { static: false }) swingStack: SwingStackComponent;
  @ViewChildren('postCard') swingCards: QueryList<SwingCardComponent>;

  constructor() {
    this.stackConfig = {
      // Default setting only allows UP, LEFT and RIGHT so you can override this as below
      allowedDirections: [Direction.LEFT, Direction.RIGHT],
      // Now need to send offsetX and offsetY with element instead of just offset
      throwOutConfidence: (offsetX, offsetY, element) => {
        this.showLikeDislike(offsetX / element.offsetWidth);
        return Math.min(
          Math.max(Math.abs(offsetX) / (element.offsetWidth / 1.7), Math.abs(offsetY) / (element.offsetHeight / 2)),
          1
        );
      },
      throwOutDistance: (d) => {
        return 800;
      },
    };

    console.log('the posts: ', this.posts);
  }

  ngOnInit() {}

  onThrowOut(event) {
    console.info('Event:', event);
    const className = event.target.classList[1];
    const id = parseInt(className.substring('Post:'.length)); // Cut `Post:`
    this.currentPost = this.currentPost - 1;

    this.commend = true;
    const direction = event.throwDirection.toString();
    if (direction === `Symbol(LEFT)`) {
      // down vote
      this.commend = false;
    } else {
      this.commend = true;
    }
    // this.showVoting()
    this.popPost();
    // this.restProvider.votePost(id, this.commend).then((post: Post) => {
    // console.log("mon post", this.currentPost)
    // console.log("mon element", this.visibleElement)
    // console.info('Voted post:', post)
    // this.hideVoting()
    if (this.commend) {
      this.showJustLiked();
    } else {
      this.showJustdisliked();
    }
    // }).catch(err => {
    // this.hideVoting()
    // console.log("My err: ",err)
    // })
  }

  onDragEnd(event) {
    const hiddenLike = document.getElementById('hidden-liked');
    const hiddenDislike = document.getElementById('hidden-disliked');
    const bottomCard = document.getElementById('card_toolbar');

    // Fix hidden like/dislike
    hiddenLike.style.opacity = '0';
    hiddenDislike.style.opacity = '0';

    // Fix bottom card back color
    bottomCard && (bottomCard.style.backgroundColor = '#f9fbfd');
  }

  showVoting() {
    this.voting = true;
  }

  hideVoting() {
    this.voting = false;
  }

  // showModal() {
  //   let bool = true;

  //   if (bool) {
  //     let modalFirst = document.getElementById("modal-first");
  //     modalFirst.style.display = "block";
  //   }
  // }

  // modalClose() {
  //   let modalFirst = document.getElementById("modal-first");
  //   modalFirst.style.display = "none";
  // }

  showJustLiked() {
    const justLiked = document.getElementById('just-liked');
    if (!justLiked) {
      return;
    }

    justLiked.style.opacity = '1';
    this.anim = true;
    setTimeout(() => {
      if (justLiked) {
        justLiked.style.opacity = '0';
      }
      this.anim = false;
    }, 1000);
  }

  showJustdisliked() {
    const justDisliked = document.getElementById('just-disliked');
    if (!justDisliked) {
      return;
    }
    justDisliked.style.opacity = '1';
    this.anim = true;
    setTimeout(() => {
      justDisliked.style.opacity = '0';
      this.anim = false;
    }, 1000);
  }

  // htmlId(){
  //   console.log("mon post courrant", this.currentPost)
  //   return this.currentPost;
  // }

  voteUp(flag) {
    // this.showImage = true;
  }

  // swipeEvent(e){
  //   this.showImage = true;
  // }

  // isVoted() {
  //   if (this.currentPost < 0) {
  //     return null;
  //   }
  //   const post = this.posts[this.currentPost];
  //   if (!post.Votes) {
  //     return false;
  //   }
  //   const index = post.Votes.findIndex(v => v.user_id === DataProvider.auth.id);
  //   if (index > -1) {
  //     return post.Votes[index];
  //   } else {
  //     return null
  //   }
  // }

  // changeVote() {
  // const curVote = this.isVoted();

  // if (!curVote) { // If not voted
  //   console.info('not voted!')
  //   return
  // }
  // const post = this.posts[this.currentPost];

  // Revert vote
  // this.restProvider.votePost(post.id, !curVote.commend).then((post: Post) => {
  //   console.info('Changed vote:', post)
  //   // this.popPost()
  // }).catch((e) => {
  //   console.error(e)
  // })
  // }

  // cancelVote() {
  //   if (!this.isVoted()) { // If not voted
  //     console.info('not voted!')
  //     return
  //   }

  //   const post = this.posts[this.currentPost];

  //   // Revert vote
  //   this.restProvider.cancelVotePost(post.id).then((post: Post) => {
  //     console.info('Canceled vote:', post)
  //     this.popPost()
  //   }).catch((e) => {
  //     console.error(e)
  //   })
  // }

  votePost(commend: boolean = true) {
    const post = this.posts[this.currentPost];
    this.popPost();
    // Revert vote
    // this.restProvider.votePost(post.id, commend).then((post: Post) => {
    //   console.info('Changed vote:', post)
    // }).catch((e) => {
    //   console.error(e)
    // })
  }

  popPost() {
    this.posts.pop();
    this.currentPost = this.posts.length - 1;

    if (this.currentPost < 0) {
      this.goBack();
      return;
    }
  }

  goBack() {
    // this.navCtrl.pop();
    setTimeout(() => {
      this.close.emit(true);
    }, 1000);
  }

  pressed() {
    console.log('pressed');
  }

  active() {
    console.log('active');
  }

  onPress(event) {
    // event.preventDefault();
    this.isPressed = true;
  }

  onCancelPress(event) {
    // event.preventDefault();
    this.isPressed = false;
  }

  showLikeDislike(x_offset) {
    const hiddenLike = document.getElementById('hidden-liked');
    const hiddenDislike = document.getElementById('hidden-disliked');
    const bottomCard = document.getElementById('card_toolbar');

    /*If the x_offset is the same value twice in a row,
     then the image stopped moving,
     the Like/Dislike doesn't need to be displayed anymore*/

    console.info('Offset:', x_offset);

    if (x_offset > 0) {
      hiddenLike.style.opacity = '1';
      hiddenDislike.style.opacity = '0';
    } else if (x_offset < 0) {
      hiddenDislike.style.opacity = '1';
      hiddenLike.style.opacity = '0';
    } else {
      hiddenLike.style.opacity = '0';
      hiddenDislike.style.opacity = '0';
    }

    // Bottom bar color
    if (x_offset < 0) {
      // bottomCard.style.backgroundColor = `rgb(${-x_offset * 255}, 10, 100)`;
    } else if (x_offset > 0) {
      // bottomCard.style.backgroundColor = `rgb(10, ${x_offset * 255}, 100)`;
    } else {
      // bottomCard.style.backgroundColor = '#f9fbfd';
    }
  }

  onClickDislike() {
    const $element = this.swingCards.first.getNativeElement();
    $element.className += ' animated rotateOutUpLeft';
    const card = this.swingCards.first.getCard();
    setTimeout(() => {
      card.throwOut(-450, 0); // -1 means swipe left
    }, 400);
  }

  onClickLike() {
    const $element = this.swingCards.first.getNativeElement();
    $element.className += ' animated rotateOutUpRight';
    const card = this.swingCards.first.getCard();
    setTimeout(() => {
      card.throwOut(450, 0); // -1 means swipe right
    }, 400);
  }
}
