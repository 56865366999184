<div class="full-width html-input-main-container">
  <div class="clearfix full-width mb" *ngIf="isCss == true">
    <h3 class="sr-title mt" *ngIf="title">{{ title }}</h3>

    <div class="full-width clearfix">
      <div
        class="full-width pull-left description-container mr"
        *ngIf="description"
        [innerHTML]="description | clearText | safeHtml"
      ></div>
    </div>

    <div class="full-width clearfix">
      <div class="full-width clearfix" @ngIfAnimation>
        <div class="full-width flex">
          <div class="action-button-container">
            <button
              class="pull-right action-button smr"
              *ngIf="componentID && isComponent == true"
              (click)="copyID()"
              mat-icon-button
              color="accent"
              matTooltip="Copy Component ID"
            >
              <mat-icon class="fas fa-copy"></mat-icon>
            </button>

            <button
              class="pull-right action-button smr"
              *ngIf="isRawExist == true && isHalfView == false && isRawPreview == true"
              (click)="changeView(true)"
              mat-icon-button
              color="accent"
              matTooltip="Change view"
            >
              <mat-icon class="fas fa-columns"></mat-icon>
            </button>

            <button
              class="pull-right action-button smr"
              *ngIf="isRawExist == true && isHalfView == true && isRawPreview == true"
              (click)="changeView(false)"
              mat-icon-button
              color="accent"
              matTooltip="Change view"
            >
              <mat-icon class="fas fa-text-width"></mat-icon>
            </button>

            <button
              class="pull-right action-button smr"
              *ngIf="isRawExist == true"
              (click)="downloadHtmlContent(contentInnerRaw)"
              mat-icon-button
              color="accent"
              matTooltip="Download Html"
            >
              <mat-icon class="fas fa-download"></mat-icon>
            </button>
            <button
              class="pull-right action-button smr"
              *ngIf="contentChangedRaw == true"
              (click)="reset('Raw')"
              mat-icon-button
              color="accent"
              matTooltip="Reset"
            >
              <mat-icon class="fas fa-undo"></mat-icon>
            </button>

            <button
              class="pull-right action-button smr"
              (click)="EditSourceCode(contentInnerRaw, 'Raw')"
              mat-icon-button
              color="accent"
              matTooltip="Edit Souce Code"
            >
              <mat-icon class="fas fa-code"></mat-icon>
            </button>

            <button
              class="pull-right action-button smr"
              *ngIf="isRawExist == true"
              (click)="ViewHtmlContent(contentInnerRaw)"
              mat-icon-button
              color="accent"
              matTooltip="Preview"
            >
              <mat-icon class="fas fa-eye"></mat-icon>
            </button>
          </div>
        </div>
        <div class="full-width clearfix flex" *ngIf="isHalfView == true">
          <div class="full-width clearfix mb">
            <ngx-monaco-editor
              class="min-height-html"
              [(ngModel)]="contentInnerRaw"
              [options]="editorOptionsCss"
              (ngModelChange)="sendContent(contentInnerRaw, 'Raw')"
            >
            </ngx-monaco-editor>

            <p class="full-width info-description" style="margin-top: -5px">
              {{ descriptionRaw }}, press <strong>Shift+Alt+F</strong> inside the editor to apply auto formatting.
            </p>
          </div>
          <div class="full-width ml min-height" *ngIf="isRawPreview == true">
            <app-inner-html-isolated
              class="full-width single-html-more-text clearfix"
              *ngIf="isRawExist == true"
              [content]="contentInnerRaw"
            >
            </app-inner-html-isolated>
          </div>
        </div>

        <div class="full-width clearfix" *ngIf="isHalfView != true">
          <div class="full-width clearfix mb">
            <ngx-monaco-editor
              class="min-height-html"
              [(ngModel)]="contentInnerRaw"
              [options]="editorOptionsCss"
              (ngModelChange)="sendContent(contentInnerRaw, 'Raw')"
            >
            </ngx-monaco-editor>

            <p class="full-width info-description" style="margin-top: -5px">
              {{ descriptionRaw }}, press <strong>Shift+Alt+F</strong> inside the editor to apply auto formatting.
            </p>
          </div>
          <div class="full-width min-height" *ngIf="isRawPreview == true">
            <app-inner-html-isolated
              class="full-width single-html-more-text clearfix"
              *ngIf="isRawExist == true"
              [content]="contentInnerRaw"
            >
            </app-inner-html-isolated>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="clearfix full-width mb" *ngIf="isCss != true">
    <h3 class="sr-title mt" *ngIf="title">{{ title }}</h3>

    <div class="full-width flex clearfix">
      <div
        class="full-width pull-left description-container mr"
        *ngIf="description"
        [innerHTML]="description | clearText | safeHtml"
      ></div>

      <div class="clearfix ml pull-right option-container" *ngIf="displayEditorOptions == true">
        <mat-form-field class="status-input clearfix text-center" appearance="outline">
          <mat-label>Editor Type</mat-label>
          <mat-select [(ngModel)]="inputType" name="inputType" placeholder="Editor Type">
            <mat-option *ngIf="isTinyEditorActive == true" [value]="'TinyEditor'">
              <span><strong>TinyMCE</strong> Graphic Editor</span>
            </mat-option>
            <!-- <mat-option *ngIf="isFroalaEditorActive==true" [value]="'FroalaEditor'">
            <span><strong>Froala</strong> Graphic Editor</span>
          </mat-option> -->

            <mat-option *ngIf="isRawActive == true" [value]="'Raw'">
              <mat-icon class="fas fa-code mr"></mat-icon>
              <span>Code Editor</span>
            </mat-option>
            <mat-option *ngIf="isUnlayerActive == true" [value]="'Editor'">
              <span><strong>Unlayer</strong> Graphic Editor</span>
            </mat-option>
            <mat-option *ngIf="isTextActive == true" [value]="'Text'">
              <mat-icon class="fas fa-align-justify mr"></mat-icon>
              <span> Text Editor</span>
            </mat-option>

            <mat-option *ngIf="displayUploader == true" [value]="'Document'">
              <mat-icon class="fas fa-upload mr"></mat-icon>
              <span>Upload File</span>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="full-width clearfix">
      <div class="full-width clearfix" @ngIfAnimation [hidden]="inputType != 'Document'">
        <div class="full-width">
          <p class="full-width small text-left">{{ descriptionDocument }}</p>
        </div>
        <div class="full-width text-center flex" *ngIf="fileID">
          <app-file-content
            class="full-width file-content-container"
            [isModal]="false"
            [contentOnly]="true"
            [fileID]="fileID"
          >
          </app-file-content>
        </div>
        <div class="full-width text-center flex" *ngIf="!fileID && EPDocumentID">
          <app-ep-document-view
            class="full-width file-content-container"
            [isModal]="false"
            [contentOnly]="true"
            [documentID]="EPDocumentID"
          >
          </app-ep-document-view>
        </div>
        <div class="full-width text-center mb">
          <app-file-uploader
            class="text-center uploader mb"
            [documentType]="'Module Marketing HTML Content'"
            [allowedExtensions]="allowedExtensions"
            [fileName]="'email_html_body'"
            [isGallery]="false"
            [isDragable]="true"
            (onCompleteAll)="onCompleteAllHTML($event)"
          >
          </app-file-uploader>
        </div>
      </div>
      <div class="full-width clearfix" *ngIf="inputType == 'Raw'" @ngIfAnimation>
        <div class="full-width flex">
          <div class="action-button-container">
            <button
              class="pull-right action-button smr"
              *ngIf="isRawExist == true && isHalfView == false && isRawPreview == true"
              (click)="changeView(true)"
              mat-icon-button
              color="accent"
              matTooltip="Change view"
            >
              <mat-icon class="fas fa-columns"></mat-icon>
            </button>

            <button
              class="pull-right action-button smr"
              *ngIf="isRawExist == true && isHalfView == true && isRawPreview == true"
              (click)="changeView(false)"
              mat-icon-button
              color="accent"
              matTooltip="Change view"
            >
              <mat-icon class="fas fa-text-width"></mat-icon>
            </button>

            <button
              class="pull-right action-button smr"
              *ngIf="isRawExist == true"
              (click)="downloadHtmlContent(contentInnerRaw)"
              mat-icon-button
              color="accent"
              matTooltip="Download Html"
            >
              <mat-icon class="fas fa-download"></mat-icon>
            </button>

            <button
              class="pull-right action-button smr"
              *ngIf="contentChangedRaw == true"
              (click)="reset('Raw')"
              mat-icon-button
              color="accent"
              matTooltip="Reset"
            >
              <mat-icon class="fas fa-undo"></mat-icon>
            </button>

            <button
              class="pull-right action-button smr"
              (click)="EditSourceCode(contentInnerRaw, 'Raw')"
              mat-icon-button
              color="accent"
              matTooltip="Edit Souce Code"
            >
              <mat-icon class="fas fa-code"></mat-icon>
            </button>

            <button
              class="pull-right action-button smr"
              *ngIf="isRawExist == true"
              (click)="ViewHtmlContent(contentInnerRaw)"
              mat-icon-button
              color="accent"
              matTooltip="Preview"
            >
              <mat-icon class="fas fa-eye"></mat-icon>
            </button>
          </div>
        </div>
        <div class="full-width clearfix flex" *ngIf="isHalfView == true">
          <div class="full-width clearfix mb">
            <ngx-monaco-editor
              class="min-height-html"
              [(ngModel)]="contentInnerRaw"
              [options]="editorOptions"
              (ngModelChange)="sendContent(contentInnerRaw, 'Raw')"
            >
            </ngx-monaco-editor>
            <p class="full-width info-description" style="margin-top: -5px">
              {{ descriptionRaw }}, press <strong>Shift+Alt+F</strong> inside the editor to apply auto formatting.
            </p>
          </div>
          <div class="full-width ml min-height" *ngIf="isRawPreview == true">
            <app-inner-html-isolated
              class="full-width single-html-more-text clearfix"
              *ngIf="isRawExist == true"
              [content]="contentInnerRaw"
            >
            </app-inner-html-isolated>
          </div>
        </div>

        <div class="full-width clearfix" *ngIf="isHalfView != true">
          <div class="full-width clearfix mb">
            <ngx-monaco-editor
              class="min-height-html"
              [(ngModel)]="contentInnerRaw"
              [options]="editorOptionsCss"
              (ngModelChange)="sendContent(contentInnerRaw, 'Raw')"
            >
            </ngx-monaco-editor>

            <p class="full-width info-description" style="margin-top: -5px">
              {{ descriptionRaw }}, press <strong>Shift+Alt+F</strong> inside the editor to apply auto formatting.
            </p>
          </div>
          <div class="full-width min-height" *ngIf="isRawPreview == true">
            <app-inner-html-isolated
              class="full-width single-html-more-text clearfix"
              *ngIf="isRawExist == true"
              [content]="contentInnerRaw"
            >
            </app-inner-html-isolated>
          </div>
        </div>
      </div>

      <div class="full-width clearfix" @ngIfAnimation [hidden]="inputType != 'Text'">
        <div class="full-width flex">
          <div class="action-button-container">
            <button
              class="pull-right action-button smr"
              *ngIf="isTextExist == true"
              (click)="downloadHtmlContent(contentInnerText)"
              mat-icon-button
              color="accent"
              matTooltip="Download Html"
            >
              <mat-icon class="fas fa-download"></mat-icon>
            </button>
            <button
              class="pull-right action-button smr"
              *ngIf="contentChangedText == true"
              (click)="reset('Text')"
              mat-icon-button
              color="accent"
              matTooltip="Reset"
            >
              <mat-icon class="fas fa-undo"></mat-icon>
            </button>

            <button
              class="pull-right action-button smr"
              (click)="EditSourceCode(contentInnerText, 'Text')"
              mat-icon-button
              color="accent"
              matTooltip="Edit Souce Code"
            >
              <mat-icon class="fas fa-code"></mat-icon>
            </button>

            <button
              class="pull-right action-button smr"
              *ngIf="isTextExist == true"
              (click)="ViewHtmlContent(contencontentInnerTexttInner)"
              mat-icon-button
              color="accent"
              matTooltip="Preview"
            >
              <mat-icon class="fas fa-eye"></mat-icon>
            </button>
          </div>
        </div>
        <div class="full-width clearfix flex mb">
          <div class="full-width min-height-html clearfix">
            <app-text-editor-input
              class="html-input-text-editor"
              [content]="contentInnerText"
              [isMultiView]="false"
              [view]="'editor'"
              [title]="''"
              [isPreview]="false"
              [isAction]="false"
              (getContent)="sendContent($event, 'Text')"
              name="editor"
            >
            </app-text-editor-input>
            <p class="full-width info-description">
              {{ descriptionContent }}
            </p>
          </div>
        </div>
      </div>

      <div class="full-width clearfix" *ngIf="inputType == 'TinyEditor'" @ngIfAnimation>
        <div class="full-width flex">
          <div class="action-button-container">
            <button
              class="pull-right action-button smr"
              *ngIf="isTinyExist == true"
              (click)="downloadHtmlContent(contentInnerTiny)"
              mat-icon-button
              color="accent"
              matTooltip="Download Html"
            >
              <mat-icon class="fas fa-download"></mat-icon>
            </button>
            <button
              class="pull-right action-button smr"
              *ngIf="contentChangedTiny == true"
              (click)="reset('Tiny')"
              mat-icon-button
              color="accent"
              matTooltip="Reset"
            >
              <mat-icon class="fas fa-undo"></mat-icon>
            </button>

            <button
              class="pull-right action-button smr"
              (click)="EditSourceCode(contentInnerTiny, 'Tiny')"
              mat-icon-button
              color="accent"
              matTooltip="Edit Souce Code"
            >
              <mat-icon class="fas fa-code"></mat-icon>
            </button>

            <button
              class="pull-right action-button smr"
              *ngIf="isTinyExist == true"
              (click)="ViewHtmlContent(contentInnerTiny)"
              mat-icon-button
              color="accent"
              matTooltip="Preview"
            >
              <mat-icon class="fas fa-eye"></mat-icon>
            </button>
          </div>
        </div>
        <div class="full-width clearfix flex mb">
          <div class="full-width min-height-html clearfix">
            <editor
              [(ngModel)]="contentInnerTiny"
              [init]="TinyMCEOptions"
              (ngModelChange)="sendContent(contentInnerTiny, 'Tiny')"
              apiKey="{{ apiTinyKey }}"
            ></editor>

            <p class="full-width info-description">
              {{ descriptionContent }}
            </p>
          </div>
        </div>
      </div>

      <div class="full-width clearfix" @ngIfAnimation [hidden]="inputType != 'Editor'">
        <div class="full-width flex mb">
          <div class="action-button-container">
            <button
              class="pull-right action-button smr"
              (click)="exportHtml()"
              mat-icon-button
              color="accent"
              matTooltip="Download Html"
            >
              <mat-icon class="fas fa-download"></mat-icon>
            </button>
            <button
              class="pull-right action-button smr"
              (click)="exportJson()"
              mat-icon-button
              color="accent"
              matTooltip="Download Json"
            >
              <mat-icon class="fas fa-file-code"></mat-icon>
            </button>

            <button
              class="pull-right action-button smr"
              (click)="reset('Editor')"
              mat-icon-button
              color="accent"
              matTooltip="Reset"
            >
              <mat-icon class="fas fa-undo"></mat-icon>
            </button>

            <button
              class="pull-right action-button smr"
              (click)="ViewHtmlContentEditor()"
              mat-icon-button
              color="accent"
              matTooltip="Preview"
            >
              <mat-icon class="fas fa-eye"></mat-icon>
            </button>
          </div>
        </div>
        <div class="full-width clearfix">
          <email-editor
            class="min-height-html html-input-editor-container"
            id="htmlinputeditorcontainer"
            [tools]="tools"
            [options]="options()"
            (loaded)="editorLoaded()"
          ></email-editor>

          <p class="full-width info-description">
            {{ descriptionEditor }}
          </p>
        </div>
        <div class="full-width clearfix mt mb">
          <div class="form-group">
            <label for="file">Import template from local file -- Only accept .json file</label>
            <input class="btn" id="file" (change)="handleFileInput($event.target.files)" type="file" accept=".json"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
