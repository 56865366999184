import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { environment } from 'environments/environment';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { AppStateService } from '../../../core/app-state/app-state.service';
import { UtilsService } from '../../../shared/services/utils.service';
import { DataResponse } from '../../../shared/types/data-response.type';
import { Merchant, MerchantGroup, MerchantGroupQuery, MerchantQuery } from './types/merchant.type';
import { SmileMakeover, SmileMakeoverQuery } from './types/smile-makeover.type';

class MerchantState {
  merchants: Partial<Merchant>[] | Merchant[];
  merchant: Partial<Merchant> | Merchant;
  merchantGroups: Partial<MerchantGroup>[] | MerchantGroup[];
  smileMakeovers: Partial<SmileMakeover>[] | SmileMakeover[];
}

@Injectable()
export class MerchantService extends ComponentStore<MerchantState> {
  private baseUrl = environment.nodeUrl;

  private settingsUrl: string = environment.nodeUrl + '/version';

  constructor(private http: HttpClient, private utilsService: UtilsService, private appStateService: AppStateService) {
    super({
      merchants: [],
      merchant: null,
      merchantGroups: [],
      smileMakeovers: [],
    });
  }

  getMerchants$<T extends Merchant | Partial<Merchant>>(): Observable<T[]> {
    return this.select((state) => state.merchants) as Observable<T[]>;
  }

  setMerchants(merchants: Partial<Merchant>[] | Merchant[]): void {
    this.patchState({ merchants });
  }

  fetchMerchants$<T extends Merchant & Partial<Merchant>>(payload: MerchantQuery['payload']): Observable<Merchant[]> {
    return this.appStateService.getAppState$().pipe(
      switchMap((appState) => {
        const params = this.utilsService.getHttpParamsFromPayload(payload);

        const options = { params };

        const endpoint = appState.isPromoterOrAdmin ? 'merchant/global' : 'merchant';
        const url = `${this.baseUrl}/${endpoint}`;

        return this.http.get<DataResponse<T[]>>(url, options).pipe(
          map((response) => response['data']),
          take(1)
        );
      })
    );
  }

  getMerchantGroups$<T extends MerchantGroup | Partial<MerchantGroup>>(): Observable<T[]> {
    return this.select((state) => state.merchantGroups) as Observable<T[]>;
  }

  setMerchantGroups(merchantGroups: Partial<MerchantGroup>[] | MerchantGroup[]) {
    this.patchState({ merchantGroups });
  }

  fetchCurrentMerchantGroups$<T extends MerchantGroup | Partial<MerchantGroup>>(
    payload: MerchantGroupQuery['payload']
  ) {
    const params: HttpParams = this.utilsService.getHttpParamsFromPayload(payload);

    const options = { params };

    const endpoint = 'merchant/current-groups';
    const url = `${this.baseUrl}/${endpoint}`;

    return this.http.get<DataResponse<MerchantGroup[]>>(url, options).pipe(map((response) => response['data']));
  }

  getSmileMakeovers$<T extends SmileMakeover | Partial<SmileMakeover>>() {
    return this.select((state) => state.smileMakeovers) as Observable<T[]>;
  }

  setSmileMakeovers(smileMakeovers: SmileMakeover[] | Partial<SmileMakeover>[]) {
    this.patchState({ smileMakeovers });
  }

  fetchSmileMakeoverList<T extends SmileMakeover | Partial<SmileMakeover>>(
    payload: SmileMakeoverQuery['payload']
  ): Observable<T[]> {
    const params: HttpParams = this.utilsService.getHttpParamsFromPayload(payload);

    const options = { params };

    const endpoint = 'merchant/smile-makeover';
    const url = `${this.baseUrl}/${endpoint}`;

    return this.http.get<DataResponse<T[]>>(url, options).pipe(map((res) => res['data']));
  }

  createSmileMakeover(smileMakeover: Partial<SmileMakeover>) {
    const endpoint = '/merchant/smile-makeover';
    const url = `${this.baseUrl}/${endpoint}`;

    return this.http.post<DataResponse<SmileMakeover>>(url, smileMakeover).pipe(map((res) => res['data']));
  }

  deleteSmileMakeover(smileMakeover: SmileMakeover) {
    const endpoint = '/merchant/smile-makeover';
    const url = `${this.baseUrl}/${endpoint}/${smileMakeover.ID}`;

    return this.http.delete<DataResponse<SmileMakeover>>(url).pipe(map((res) => res['data']));
  }

  addSmileMakeover$(smileMakeoverWithID: SmileMakeover | Partial<SmileMakeover>) {
    const currentItems = this.get().smileMakeovers;
    const smileMakeovers = [...currentItems, smileMakeoverWithID].sort((a, b) =>
      a.DateTimeCreated.localeCompare(b.DateTimeCreated)
    );
    this.patchState({ smileMakeovers });
    return of(smileMakeoverWithID);
  }

  editSmileMakeover$(smileMakeover: SmileMakeover | Partial<SmileMakeover>) {
    const smileMakeovers = this.get().smileMakeovers.sort((a, b) => a.DateTimeCreated.localeCompare(b.DateTimeCreated));
    const targetIndex = smileMakeovers.findIndex((item) => smileMakeover.ID === item.ID);
    smileMakeovers[targetIndex] = smileMakeover;
    this.patchState({ smileMakeovers });
    return of(smileMakeover);
  }

  removeSmileMakeover$(smileMakeover: SmileMakeover | Partial<SmileMakeover>) {
    // Remove item from local list
    const currentItems = this.get().smileMakeovers;
    const smileMakeovers = currentItems
      .filter((item) => smileMakeover.ID !== item.ID)
      .sort((a, b) => a.DateTimeCreated.localeCompare(b.DateTimeCreated));

    this.patchState({ smileMakeovers });

    return of(smileMakeovers);
  }

  getList(payload, isPromoterOrAdmin = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (isPromoterOrAdmin == true) {
      return this.http
        .get(environment.nodeUrl + '/merchant/global/', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/merchant/', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getMerchantServicesGroupItemsList(payload): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    return this.http
      .get(environment.nodeUrl + '/merchant/merchant-services-group-items', options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getMerchantServicesGroupList(payload): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    return this.http
      .get(environment.nodeUrl + '/merchant/merchant-services-group', options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  createMerchantServicesGroup(merchantServiceGroup): Observable<any> {
    return this.http
      .post(environment.nodeUrl + '/merchant/merchant-services-group', merchantServiceGroup)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  deleteMerchantHealthFund(id): Observable<any> {
    return this.http
      .delete<any>(environment.nodeUrl + `/merchant/merchant-health-funds/${id}`)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  updateMerchantServicesGroup(merchantServiceGroup): Observable<any> {
    return this.http
      .put(environment.nodeUrl + `/merchant/merchant-services-group/${merchantServiceGroup.ID}`, merchantServiceGroup)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  deleteMerchantServicesGroup(id): Observable<any> {
    return this.http
      .delete<any>(environment.nodeUrl + `/merchant/merchant-services-group/${id}`)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getMerchantServicesItemsList(payload): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    return this.http
      .get(environment.nodeUrl + '/merchant/merchant-services-items', options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  createMerchantServicesItems(createMerchantServicesItems): Observable<any> {
    return this.http
      .post(environment.nodeUrl + '/merchant/merchant-services-items', createMerchantServicesItems)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  updateMerchantServicesItems(merchantServicesItem): Observable<any> {
    return this.http
      .put(environment.nodeUrl + `/merchant/merchant-services-items/${merchantServicesItem.ID}`, merchantServicesItem)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  deleteMerchantServicesItems(id): Observable<any> {
    return this.http
      .delete<any>(environment.nodeUrl + `/merchant/merchant-services-items/${id}`)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getInvitationDetails(ID, payload = {}, sessionType, isMembership = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }

    if (isMembership == true) {
      if (sessionType == 'admin' || sessionType == 'promoter') {
        return this.http
          .get(environment.nodeUrl + '/invitation-membership/global/' + ID, options)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
        return this.http
          .get(environment.nodeUrl + '/invitation-membership/' + ID, options)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else {
        return this.http
          .get(environment.nodeUrl + '/invitation-membership/public/' + ID, options)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      }
    } else {
      if (sessionType == 'admin' || sessionType == 'promoter') {
        return this.http
          .get(environment.nodeUrl + '/invitation/global/' + ID, options)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
        return this.http
          .get(environment.nodeUrl + '/invitation/' + ID, options)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else {
        return this.http
          .get(environment.nodeUrl + '/invitation/public/' + ID, options)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      }
    }
  }

  getPracticePic(ID, payload = {}) {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }
    return this.http
      .get(`${environment.nodeUrl}/merchant/practice-picture/` + ID, options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getPracticePicCover(ID, payload = {}) {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }
    return this.http
      .get(`${environment.nodeUrl}/merchant/practice-picture-cover/` + ID, options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getPracticeAppointmentCover(ID, payload = {}) {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }
    return this.http
      .get(`${environment.nodeUrl}/merchant/practice-appointment-cover/` + ID, options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getPracticePicStreamLink(ID) {
    if (ID) {
      return `${environment.nodeUrl}/merchant/practice-picture-stream/` + ID;
    }
    return null;
  }

  getPracticePicCoverStreamLink(ID) {
    if (ID) {
      return `${environment.nodeUrl}/merchant/practice-picture-cover-stream/` + ID;
    }
    return null;
  }

  getPracticeAppointmentCoverStreamLink(ID) {
    if (ID) {
      return `${environment.nodeUrl}/merchant/practice-appointment-cover-stream/` + ID;
    }
    return null;
  }

  getProfilePic(ID, payload = {}) {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }
    return this.http
      .get(`${environment.nodeUrl}/merchant/profile-picture/` + ID, options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getProfilePicStreamLink(ID) {
    if (ID) {
      return `${environment.nodeUrl}/merchant/profile-picture-stream/` + ID;
    }
    return null;
  }

  uploadProfilePic(ID, payload = {}) {
    return this.http
      .put(`${environment.nodeUrl}/merchant/profile-picture/` + ID, payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  uploadPracticePic(ID, payload = {}) {
    return this.http
      .put(`${environment.nodeUrl}/merchant/practice-picture/` + ID, payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  uploadPracticePicCover(ID, payload = {}) {
    return this.http
      .put(`${environment.nodeUrl}/merchant/practice-picture-cover/` + ID, payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  uploadPracticeAppointmentCover(ID, payload = {}) {
    return this.http
      .put(`${environment.nodeUrl}/merchant/practice-appointment-cover/` + ID, payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  addCoordinate(ID, body) {
    return this.http
      .put(environment.nodeUrl + '/merchant/coordinate/' + ID, body)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  editMerchant(ID, payload) {
    return this.http
      .put(environment.nodeUrl + '/merchant/global/' + ID, payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getMerchantDetails(ID, payload = {}, sessionType): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http
        .get(environment.nodeUrl + '/merchant/' + ID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/merchant/public/' + ID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getOne(ID, body) {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        params = params.set(key, body[key]);
      }
    }

    options = {
      params,
    };

    return this.http
      .get(environment.nodeUrl + '/merchant/' + ID, options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getSettings(): Observable<any> {
    return this.http.get(this.settingsUrl).pipe(
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }

  getTradingHourListGlobal(payload) {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    return this.http
      .get(environment.nodeUrl + '/merchant-working-hours/global', options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getTradingHourList(payload = null, isPromoterOrAdmin = false) {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }
    }

    options = {
      params,
    };

    if (isPromoterOrAdmin == true) {
      return this.http
        .get(environment.nodeUrl + '/merchant-working-hours/global', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/merchant-working-hours')
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  createTradingHour(payload, isPromoterOrAdmin = false) {
    if (isPromoterOrAdmin == true) {
      return this.http
        .post(environment.nodeUrl + '/merchant-working-hours/global', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .post(environment.nodeUrl + '/merchant-working-hours', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  editTradingHour(ID, payload, isPromoterOrAdmin = false) {
    if (isPromoterOrAdmin == true) {
      return this.http
        .put(environment.nodeUrl + '/merchant-working-hours/global/' + ID, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .put(environment.nodeUrl + '/merchant-working-hours/' + ID, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  createUniqueAuthentication(payload, isPromoterOrAdmin = false) {
    if (isPromoterOrAdmin == true) {
      return this.http
        .post(environment.nodeUrl + '/unique-authentication/global', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .post(environment.nodeUrl + '/unique-authentication', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  sendLinkUniqueAuthentication(payload, isPromoterOrAdmin = false) {
    if (isPromoterOrAdmin == true) {
      return this.http
        .post(environment.nodeUrl + '/unique-authentication/link/global', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .post(environment.nodeUrl + '/unique-authentication/link', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getUniqueAuthenticationList(payload = null, isPromoterOrAdmin = false) {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }
    }

    options = {
      params,
    };

    if (isPromoterOrAdmin == true) {
      return this.http
        .get(environment.nodeUrl + '/unique-authentication/global', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/unique-authentication', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  uniqueAuthenticationNotifyCustomer(code) {
    return this.http
      .put(environment.nodeUrl + '/unique-authentication/notify/' + code, {})
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  uniqueAuthenticationRequest(code) {
    return this.http
      .put(environment.nodeUrl + '/unique-authentication/request/' + code, {})
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  uniqueAuthenticationConfirm(payload) {
    return this.http
      .post(environment.nodeUrl + '/unique-authentication/confirm-code', payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  canAssitPatientProduct(merchantID = null, productID, sessionType = 'guest'): Observable<any> {
    if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
      return this.http
        .get(environment.nodeUrl + '/merchant/assist-product/' + productID, {})
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/merchant/assist-product/' + merchantID + '/' + productID, {})
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getProductList(body, isPromoterOrAdmin = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        params = params.set(key, body[key]);
      }
    }

    options = {
      params,
    };
    if (isPromoterOrAdmin == true) {
      return this.http
        .get(environment.nodeUrl + '/product/global', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/product/', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  practiceLandingPageOneByMerchant(id, payload) {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }
    return this.http
      .get(`${environment.nodeUrl}/merchantLandingPageCode/merchant/${id}`, options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  practiceLandingPageOneByCode(id, payload) {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }
    return this.http
      .get(`${environment.nodeUrl}/merchantLandingPageCode/${id}`, options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  practiceLandingPageCreate(payload, isPromoterOrAdmin = false) {
    let url = 'merchantLandingPageCode';
    if (isPromoterOrAdmin == true) {
      url += '/global';
    }
    return this.http.post(`${environment.nodeUrl}/${url}`, payload).pipe(map((res: HttpResponse<any>) => res['data']));
  }

  practiceLandingPageExist(id) {
    return this.http
      .get(`${environment.nodeUrl}/merchantLandingPageCode/exist/${id}`, {})
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getMerchantVisitorsByTarget(target, payload = {}, sessionType): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http
        .get(environment.nodeUrl + '/merchant-visitor/user/global/' + target, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/merchant-visitor/user/' + target, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getDentistryTypesList(payload): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    return this.http
      .get(environment.nodeUrl + '/merchant/dentistry-types', options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  createDentistryType(dentistryType): Observable<any> {
    return this.http
      .post(environment.nodeUrl + '/merchant/dentistry-types', dentistryType)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  updateDentistryType(dentistryTypes): Observable<any> {
    return this.http
      .put(environment.nodeUrl + `/merchant/dentistry-types/${dentistryTypes.ID}`, dentistryTypes)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  deleteDentistryType(id): Observable<any> {
    return this.http
      .delete<any>(environment.nodeUrl + `/merchant/dentistry-types/${id}`)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getMerchantHealthFundList(payload): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    return this.http
      .get(environment.nodeUrl + '/merchant/merchant-health-funds', options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  requestMerchantCode(): Observable<any> {
    return this.http
      .post(environment.nodeUrl + `/merchant/request-code/current`, {})
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  validateMerchantCode(body): Observable<any> {
    return this.http
      .post(environment.nodeUrl + `/merchant/validate-code/current`, body)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getMerchantProfileServicesItemsList(payload): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    return this.http
      .get(environment.nodeUrl + '/merchant/merchant-profile-services-items', options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  createMerchantProfileServicesItems(createMerchantServicesItems): Observable<any> {
    return this.http
      .post(environment.nodeUrl + '/merchant/merchant-profile-services-items', createMerchantServicesItems)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  deleteMerchantProfileServicesItems(id): Observable<any> {
    return this.http
      .delete<any>(environment.nodeUrl + `/merchant/merchant-profile-services-items/${id}`)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getMerchantProfileHealthFundsList(payload): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    return this.http
      .get(environment.nodeUrl + '/merchant/merchant-profile-health-funds', options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  modifyMerchantGroup(payload): Observable<any> {
    return this.http.put(`${environment.nodeUrl}/merchant/group`, payload).pipe(map((res: Response) => res['data']));
  }

  createMerchantProfileHealthFund(createMerchantHealthFund): Observable<any> {
    return this.http
      .post(environment.nodeUrl + '/merchant/merchant-profile-health-funds', createMerchantHealthFund)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  createMerchantHealthFund(dentistryType): Observable<any> {
    return this.http
      .post(environment.nodeUrl + '/merchant/merchant-health-funds', dentistryType)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  updateMerchantHealthFund(dentistryTypes): Observable<any> {
    return this.http
      .put(environment.nodeUrl + `/merchant/merchant-health-funds/${dentistryTypes.ID}`, dentistryTypes)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  deleteMerchantProfileHealthFund(id): Observable<any> {
    return this.http
      .delete<any>(environment.nodeUrl + `/merchant/merchant-profile-health-funds/${id}`)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getMerchantProfileDentistryTypesList(payload): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    return this.http
      .get(environment.nodeUrl + '/merchant/merchant-profile-dentistry-types', options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  createMerchantProfileDentistryType(createMerchantDentistryType): Observable<any> {
    return this.http
      .post(environment.nodeUrl + '/merchant/merchant-profile-dentistry-types', createMerchantDentistryType)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  deleteMerchantProfileDentistryType(id): Observable<any> {
    return this.http
      .delete<any>(environment.nodeUrl + `/merchant/merchant-profile-dentistry-types/${id}`)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getLandingPagePDFBlob(merchantID, type): Observable<any> {
    return this.http
      .post(environment.nodeUrl + '/r/pdf/' + type + '/' + merchantID, {})
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }
  getPublicSettings(): Observable<any> {
    return this.http.get(`${environment.nodeUrl}/settings/public`, {}).pipe(map((res: Response) => res['data']));
  }


  getGlobalSettings(): Observable<any> {
    return this.http.get(`${environment.nodeUrl}/settings/global`, {}).pipe(map((res: Response) => res['data']));
  }
  updateSettings(id, body): Observable<any> {
    return this.http.put(`${environment.nodeUrl}/settings/${id}`, body).pipe(map((res: Response) => res['data']));
  }

  cleanMessageGroupLocalData(): Observable<any> {
    return this.http
    .post(environment.nodeUrl + '/message-group/clean' , {})
    .pipe(map((res: HttpResponse<any>) => res['data']));
  }
}
