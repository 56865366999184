import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { MerchantService } from '../shared/merchant.service';

@Component({
  selector: 'app-discount-setting',
  templateUrl: './discount-setting.component.html',
  styleUrls: ['./discount-setting.component.css'],
})
export class DiscountSettingComponent implements OnInit {
  @Input()
  merchantID;

  @Output()
  getCashDiscount = new EventEmitter();

  @Output()
  getMerchant = new EventEmitter();

  @Output()
  closeModal = new EventEmitter();

  isPromoterOrAdmin = false;

  merchant;

  cashDiscountOveridden = true;

  cashDiscountOffered = false;
  cashDiscountMinAmount;
  cashDiscountPercent;
  cashDiscountExpiredDays;

  isModal = false;
  sessionType = 'guest';
  cashDiscountObject;

  constructor(
    private authenticationService: AuthenticationService,
    private merchantService: MerchantService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.merchantID) {
        this.merchantID = data.merchantID;
      }

      this.isModal = true;
    }
  }

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((sessionType) => {
      if (sessionType) {
        this.sessionType = sessionType;
      }
      this.setup();
    });
  }

  setup() {
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;

      if (this.isPromoterOrAdmin == true && this.merchantID) {
        this.merchantService.getMerchantDetails(this.merchantID, {}, this.sessionType).subscribe((res) => {
          if (res) {
            this.merchant = res;
            this.merchantID = this.merchant['ID'];
            this.buildObject();
          }
        });
      } else {
        this.authenticationService.getCurrentPractice().subscribe((res) => {
          if (res) {
            this.merchant = res;

            this.merchantID = this.merchant['ID'];
            this.buildObject();
          }
        });
      }
    });
  }

  buildObject() {
    if (this.merchant) {
      if (this.merchant['CashDiscount.Offered'] == '0') {
        this.cashDiscountOffered = false;
      } else {
        this.cashDiscountOffered = true;
      }

      if (this.merchant['CashDiscount.AllowOverride'] == '0') {
        this.cashDiscountOveridden = false;
      } else {
        this.cashDiscountOveridden = true;
      }

      this.cashDiscountPercent = Number(this.merchant['CashDiscount.Percentage'] || 0);

      this.cashDiscountExpiredDays = Number(this.merchant['CashDiscount.Expiry.Days'] || 0);

      this.cashDiscountMinAmount = Number(this.merchant['CashDiscount.MinInvoiceValue'] || 0);
    }
  }

  closeEvent() {
    this.closeModal.emit(true);
  }

  buildResultObject() {
    if (this.merchantID) {
      this.cashDiscountObject = {};

      if (this.cashDiscountPercent) {
        this.cashDiscountObject.cashDiscountPercent = this.cashDiscountPercent;
      } else {
        this.cashDiscountObject.cashDiscountPercent = 0;
      }

      if (this.cashDiscountExpiredDays) {
        this.cashDiscountObject.cashDiscountExpiredDays = this.cashDiscountExpiredDays;
      } else {
        this.cashDiscountObject.cashDiscountExpiredDays = 0;
      }

      if (this.cashDiscountMinAmount) {
        this.cashDiscountObject.cashDiscountMinAmount = this.cashDiscountMinAmount;
      } else {
        this.cashDiscountObject.cashDiscountMinAmount = 0;
      }

      this.cashDiscountObject.merchantID = this.merchantID;

      this.cashDiscountObject.cashDiscountOffered = this.cashDiscountOffered;

      this.cashDiscountObject.cashDiscountOveridden = this.cashDiscountOveridden;
    } else {
      this.cashDiscountObject = null;
    }
  }

  save() {
    let confirm = new ConfirmDialog(
      'fas fa-info',
      'Change default settings',
      'Are you sure you want to save this settings as Default for future invitations ?',
      'No',
      'Yes'
    );

    let ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirm,
    });
    ref.componentInstance.onConfirm.subscribe((confirmation) => {
      if (confirmation === false) {
        ref.close();
      } else {
        this.buildResultObject();
        if (this.isPromoterOrAdmin == true && this.merchantID && this.cashDiscountObject) {
          this.merchantService.editMerchant(this.merchantID, this.cashDiscountObject).subscribe((res) => {
            if (res) {
              this.cashDiscountPercent = res['CashDiscount.Percentage'];
              this.cashDiscountOffered = res['CashDiscount.Offered'] == '0' ? false : true;
              this.cashDiscountExpiredDays = res['CashDiscount.Expiry.Days'];
              this.cashDiscountOveridden = res['CashDiscount.AllowOverride'] == '0' ? false : true;
              this.cashDiscountMinAmount = res['CashDiscount.MinInvoiceValue'];

              this.getMerchant.emit(res);
              this.getCashDiscount.emit(this.cashDiscountObject);
              NotifyAppComponent.displayToast('success', 'Successful operation', 'Settings has been update');
            }
          });
        } else if (this.cashDiscountObject) {
          this.authenticationService.editCurrentPractice(this.cashDiscountObject).subscribe((res) => {
            if (res) {
              this.cashDiscountPercent = res['CashDiscount.Percentage'];
              this.cashDiscountOffered = res['CashDiscount.Offered'] == '0' ? false : true;
              this.cashDiscountExpiredDays = res['CashDiscount.Expiry.Days'];
              this.cashDiscountOveridden = res['CashDiscount.AllowOverride'] == '0' ? false : true;
              this.cashDiscountMinAmount = res['CashDiscount.MinInvoiceValue'];

              this.getMerchant.emit(res);
              this.getCashDiscount.emit(this.cashDiscountObject);
              NotifyAppComponent.displayToast('success', 'Successful operation', 'Settings has been update');
            }
          });
        }

        ref.close();
      }
    });
  }
}
