import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output, SimpleChanges } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import * as _ from 'lodash';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { FilterArrayMultipleContainValuePipe } from '../../pipes/filter-array-object-contain.pipe';
import { OrderArrayObject } from '../../pipes/order-array-object.pipe';
import { UtilsService } from '../../services/utils.service';
import { NotifyAppComponent } from '../../types/notify-app-component';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { EPDocumentCreateEditComponent } from '../ep-document-create-edit/ep-document-create-edit.component';
import { EpDocumentViewComponent } from '../ep-document-view/ep-document-view.component';
import { RootAppComponent } from '../root-component/root-component.component';

@Component({
  selector: 'app-ep-document-list',
  templateUrl: './ep-document-list.component.html',
  styleUrls: ['./ep-document-list.component.css'],
  providers: [FilterArrayMultipleContainValuePipe, OrderArrayObject],
  animations: [
    trigger('simpleFadeAnimation', [
      state('in', style({ opacity: 1, height: '*' })),
      transition(':enter', [style({ opacity: 0, height: 0 }), animate(300)]),
      transition(':leave', animate(300, style({ opacity: 0, height: 0 }))),
    ]),
  ],
})
export class EpDocumentListComponent implements OnInit {
  @Input() documents: any;

  @Input()
  displayViewButton = false;
  @Input()
  documentTypes;

  @Input()
  cardID;

  @Input() selectedIDs: any = [];
  @Input() requiredIDs: any = [];
  @Input() verifiedIDs: any = [];

  @Input() rejectedIDs: any = [];

  @Input() title = 'Documents';
  @Input() description;
  @Input() canAction = false;
  @Input() actionLabel = 'Select';
  @Input() showLabels = true;
  @Input() canActionGlobal = false;
  @Input() actionLabelGlobal = 'Select';

  @Input() sideFilter = false;
  @Input() fullsizeClick = false;

  @Input() justify = 'center';
  @Input() canCheck = false;
  @Input() checkLabel = 'Click to select';

  @Input()
  sendResultOnChange = false;
  @Input()
  defaultCheck = false;

  @Input()
  noViewOnClick = false;

  @Output() getViewDocument = new EventEmitter();

  @Output() getViewDocumentObject = new EventEmitter();

  @Input()
  listViewType = 'default';

  @Output() getDocument = new EventEmitter();
  @Output() getDocumentLength = new EventEmitter();

  @Output() getCheckedDocument = new EventEmitter();

  @Output() getUncheckedDocument = new EventEmitter();

  isAdminOrPromoter = false;

  @Output() getselectedDocuments = new EventEmitter();

  @Output() getselectedDocumentIDs = new EventEmitter();

  @Output() sendActionGlobal = new EventEmitter();
  @Output() remove = new EventEmitter();
  @Output() removeID = new EventEmitter();
  @Output() edit = new EventEmitter();
  @Output() editID = new EventEmitter();

  @Input() isDownload = true;
  @Input() isRedirection = true;
  @Input() isPrint = true;
  @Input() isFilter = false;
  @Input() isButtonFilter = false;

  @Input()
  directRemove = true;

  @Input()
  canRemove = false;

  @Input()
  canEdit = false;

  @Output() closeModal = new EventEmitter();

  @Input() refresh = new EventEmitter();

  @Input() addFile = new EventEmitter();
  @Input() addFiles = new EventEmitter();

  @Input() deleteFile = new EventEmitter();

  @Input()
  isModal = false;

  @Input()
  isPreviewModal = false;

  @Input()
  filter;

  @Input()
  orderByFilter = '-required';

  @Input() isVerify = false;
  @Output() applyVerify = new EventEmitter();

  _documents = [];
  _unfilteredDocuments = [];

  _selectedDocumentIDs = [];

  _requiredDocumentIDs = [];

  removedList = [];

  @Input() removeLabel = 'Remove';

  @Input()
  onlyView = true;

  @Input()
  miniEdit = false;

  sessionType = 'merchant';

  currentPracticeID;

  constructor(
    private OrderArrayObjectPipe: OrderArrayObject,
    private FilterArrayMultipleContainValuePipe: FilterArrayMultipleContainValuePipe,
    private dialog: MatDialog,
    private utilService: UtilsService,
    private authenticationService: AuthenticationService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.isDownload != null) {
        this.isDownload = data.isDownload;
      }

      if (data.isRedirection != null) {
        this.isRedirection = data.isRedirection;
      }

      if (data.isPrint != null) {
        this.isPrint = data.isPrint;
      }

      if (data.isFilter != null) {
        this.isFilter = data.isFilter;
      }

      if (data.isButtonFilter != null) {
        this.isButtonFilter = data.isButtonFilter;
        this.isModal = true;
      }

      if (data.defaultCheck != null) {
        this.defaultCheck = data.defaultCheck;
        this.isModal = true;
      }

      if (data.canCheck != null) {
        this.canCheck = data.canCheck;
        this.isModal = true;
      }

      if (data.canRemove != null) {
        this.canRemove = data.canRemove;
        this.isModal = true;
      }

      if (data.canAction != null) {
        this.canAction = data.canAction;
        this.isModal = true;
      }

      if (data.canActionGlobal != null) {
        this.canActionGlobal = data.canActionGlobal;
        this.isModal = true;
      }

      if (data.listViewType) {
        this.listViewType = data.listViewType;
        this.isModal = true;
      }

      if (data.removeLabel) {
        this.removeLabel = data.removeLabel;
        this.isModal = true;
      }

      if (data.actionLabel) {
        this.actionLabel = data.actionLabel;
        this.isModal = true;
      }

      if (data.actionLabelGlobal) {
        this.actionLabelGlobal = data.actionLabelGlobal;
        this.isModal = true;
      }

      if (data.title) {
        this.title = data.title;
        this.isModal = true;
      }

      if (data.description) {
        this.description = data.description;
        this.isModal = true;
      }

      if (data.checkLabel) {
        this.checkLabel = data.checkLabel;
      }

      if (data.cardID) {
        this.cardID = data.cardID;
        this.isModal = true;
      }

      if (data.documents) {
        this.documents = data.documents;
        this.isModal = true;
      }

      if (data.selectedIDs) {
        this.selectedIDs = data.selectedIDs;
        this.isModal = true;
      }

      if (data.requiredIDs) {
        this.requiredIDs = data.requiredIDs;
        this.isModal = true;
      }

      if (data.filter) {
        this.filter = data.filter;
      }

      if (data.isModal != null) {
        this.isModal = data.isModal;
      }
    }
  }

  ngOnInit() {
    this.authenticationService.getCurrentPractice().subscribe((p) => {
      if (p && p.ID) {
        this.currentPracticeID = p.ID;
      }

      this.authenticationService.getSessionType().subscribe((res) => {
        if (res) {
          this.sessionType = res;
        }

        this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
          this.isAdminOrPromoter = res;
          this.setup(false);
        });
      });
    });
    this.refresh.subscribe((res) => {
      if (res == true) {
        this.setup();
      }
    });

    this.addFile.subscribe((res) => {
      if (res && typeof res == 'string') {
        const p = {
          fields:
            'AWS_URL,AWS_Bucket_Name,DocumentType_Code,DateAdded,Card.Name,DateTimeCreated,Description,Extension,ID,Label,URL.Complete,Tags,ContainsSensitiveData,IsGallery',
        };

        this.utilService.getEpdocument(res, p, this.sessionType).subscribe((f) => {
          if (f) {
            this._documents.push(f);

            this._unfilteredDocuments = JSON.parse(JSON.stringify(this._documents));
          }
        });
      } else if (res && typeof res == 'object') {
        this._documents.push(res);
        this._unfilteredDocuments = JSON.parse(JSON.stringify(this._documents));
      }
    });

    this.deleteFile.subscribe((res) => {
      if (res && typeof res == 'string') {
        const index = this._documents.findIndex((r) => {
          if (r && r.ID && r.ID == res) {
            return true;
          }
          return false;
        });

        if (index != -1) {
          this._documents.splice(index, 1);
          this._unfilteredDocuments = JSON.parse(JSON.stringify(this._documents));
        }
      } else if (res && typeof res == 'object' && res.ID) {
        const index = this._documents.findIndex((r) => {
          if (r && r.ID && r.ID == res.ID) {
            return true;
          }
          return false;
        });

        if (index != -1) {
          this._documents.splice(index, 1);
          this._unfilteredDocuments = JSON.parse(JSON.stringify(this._documents));
        }
      }
    });

    this.addFiles.subscribe((_documents) => {
      let notIDs = [];

      if (this._documents && this._documents.length > 0) {
        notIDs = _.map(this._documents, (item) => {
          if (item && item.ID) {
            return item.ID;
          }

          return null;
        });

        if (notIDs && notIDs.length > 0) {
          notIDs = _.filter(notIDs, (item) => {
            if (item) {
              return true;
            }

            return false;
          });
        }
      }

      if (_documents && typeof _documents == 'string' && _documents.split(',').length > 0) {
        let __doc = _documents.split(',');

        __doc = _.uniq(__doc);

        const ids = __doc.join(',');

        const p = {
          notIDs,
          fields:
            'AWS_URL,AWS_Bucket_Name,DocumentType_Code,DateAdded,Card.Name,DateTimeCreated,Description,Extension,ID,Label,URL.Complete,Tags,ContainsSensitiveData,IsGallery',
        };

        this.utilService.getEpdocumentArray(ids, p).subscribe((res) => {
          if (res) {
            this._documents = _.uniqBy(this._documents.concat(res), 'ID');
            this.sendLength();
            this.setupSeletecdId(true);
            this.filterEvent();
          }
        });
      } else if (_documents && Array.isArray(_documents) && _documents.length > 0 && typeof _documents[0] == 'string') {
        _documents = _.uniq(_documents);
        const ids = _documents.join(',');

        const p = {
          notIDs,
          fields:
            'AWS_URL,AWS_Bucket_Name,DocumentType_Code,DateAdded,Card.Name,DateTimeCreated,Description,Extension,ID,Label,URL.Complete,Tags,ContainsSensitiveData,IsGallery',
        };

        this.utilService.getEpdocumentArray(ids, p).subscribe((res) => {
          if (res) {
            this._documents = _.uniqBy(this._documents.concat(res), 'ID');
            this.sendLength();
            this.setupSeletecdId(true);
            this.filterEvent();
          }
        });
      } else if (_documents && Array.isArray(_documents) && _documents.length > 0 && typeof _documents[0] == 'object') {
        this._documents = _.uniqBy(_documents, 'ID');
        this.sendLength();
        this.setupSeletecdId(true);
        this.filterEvent();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.filter && changes.filter.previousValue != changes.filter.currentValue) {
      this.filterEvent();
    } else if (
      changes.documents &&
      changes.documents.previousValue != changes.documents.currentValue &&
      JSON.stringify(changes.documents.previousValue) != JSON.stringify(changes.documents.currentValue)
    ) {
      this.setup(true);
    } else if (
      changes.documents &&
      this.sendResultOnChange == true &&
      changes.documents.previousValue != changes.documents.currentValue &&
      JSON.stringify(changes.documents.previousValue) != JSON.stringify(changes.documents.currentValue)
    ) {
      this.setup(true);
    }
  }

  setup(sendResult = true) {
    if (this.documents && typeof this.documents == 'string' && this.documents.split(',').length > 0) {
      let __doc = this.documents.split(',');

      __doc = _.uniq(__doc);

      const ids = __doc.join(',');

      const p = {
        fields:
          'AWS_URL,AWS_Bucket_Name,DocumentType_Code,DateAdded,Card.Name,DateTimeCreated,Description,Extension,ID,Label,URL.Complete,Tags,ContainsSensitiveData,IsGallery',
      };

      this.utilService.getEpdocumentArray(ids, p).subscribe((res) => {
        if (res) {
          this._documents = res;
          this.sendLength();
          this.setupSeletecdId(sendResult);
          this.filterEvent();
        }
      });
    } else if (
      this.documents &&
      Array.isArray(this.documents) &&
      this.documents.length > 0 &&
      typeof this.documents[0] == 'string'
    ) {
      this.documents = _.uniq(this.documents);
      const ids = this.documents.join(',');

      const p = {
        fields:
          'AWS_URL,AWS_Bucket_Name,DocumentType_Code,DateAdded,Card.Name,DateTimeCreated,Description,Extension,ID,Label,URL.Complete,Tags,ContainsSensitiveData,IsGallery',
      };

      this.utilService.getEpdocumentArray(ids, p).subscribe((res) => {
        if (res) {
          this._documents = res;
          this.sendLength();
          this.setupSeletecdId(sendResult);
          this.filterEvent();
        }
      });
    } else if (
      this.documents &&
      Array.isArray(this.documents) &&
      this.documents.length > 0 &&
      typeof this.documents[0] == 'object'
    ) {
      this._documents = _.uniqBy(this.documents, 'ID');
      this.sendLength();
      this.setupSeletecdId(sendResult);
    } else {
      this._documents = [];
      this.sendLength();
      this.setupSeletecdId(sendResult);
      this.filterEvent();
    }
  }

  setupSeletecdId(sendResult = true) {
    if (this.selectedIDs && typeof this.selectedIDs == 'string' && this.selectedIDs.split(',').length > 0) {
      this._selectedDocumentIDs = this.selectedIDs.split(',');
    } else if (
      this.selectedIDs &&
      Array.isArray(this.selectedIDs) &&
      this.selectedIDs.length > 0 &&
      typeof this.selectedIDs[0] == 'string'
    ) {
      this._selectedDocumentIDs = this.selectedIDs;
    }

    if (this.requiredIDs && typeof this.requiredIDs == 'string' && this.requiredIDs.split(',').length > 0) {
      this._requiredDocumentIDs = this.requiredIDs.split(',');
    } else if (
      this.requiredIDs &&
      Array.isArray(this.requiredIDs) &&
      this.requiredIDs.length > 0 &&
      typeof this.requiredIDs[0] == 'string'
    ) {
      this._requiredDocumentIDs = this.requiredIDs;
    }

    if (
      this._selectedDocumentIDs &&
      Array.isArray(this._selectedDocumentIDs) &&
      this._selectedDocumentIDs.length > 0 &&
      this._documents &&
      Array.isArray(this._documents) &&
      this._documents.length > 0
    ) {
      for (let i = 0; i < this._documents.length; i++) {
        if (this._documents[i] && this._documents[i]['ID']) {
          if (this._selectedDocumentIDs.indexOf(this._documents[i]['ID']) != -1) {
            this._documents[i]['checked'] = true;
          } else {
            this._documents[i]['checked'] = false;
          }
          this._documents[i]['required'] = false;
        }
      }
    } else {
      for (let i = 0; i < this._documents.length; i++) {
        if (this._documents[i] && this._documents[i]['ID']) {
          this._documents[i]['checked'] = this.defaultCheck;
        }
      }
    }

    if (
      this._requiredDocumentIDs &&
      Array.isArray(this._requiredDocumentIDs) &&
      this._requiredDocumentIDs.length > 0 &&
      this._documents &&
      Array.isArray(this._documents) &&
      this._documents.length > 0
    ) {
      for (let i = 0; i < this._requiredDocumentIDs.length; i++) {
        let r = this._requiredDocumentIDs[i];

        r = r.replace('_1', '');
        r = r.replace('_0', '');

        this._requiredDocumentIDs[i] = r;
      }

      for (let i = 0; i < this._documents.length; i++) {
        if (this._documents[i] && this._documents[i]['ID']) {
          let r = this._documents[i]['ID'];

          r = r.replace('_1', '');
          r = r.replace('_0', '');

          if (this._requiredDocumentIDs.indexOf(r) != -1) {
            this._documents[i]['checked'] = true;
            this._documents[i]['required'] = true;
          }
        }
      }
    }

    this._unfilteredDocuments = JSON.parse(JSON.stringify(this._documents));

    if (sendResult == true) {
      this.sendSelectedDocuments();
    }
  }

  sendFile(doc) {
    this.getDocument.emit(doc);
  }

  sendLength() {
    if (this._documents && this._documents.length > 0) {
      this.getDocumentLength.emit(this._documents.length);
    } else {
      this.getDocumentLength.emit(0);
    }
  }

  sendSelectedDocuments() {
    const ids = [];
    let d = [];
    if (this._documents && this._documents.length > 0) {
      d = this._documents.filter((item) => {
        if (item && item.checked == true) {
          return true;
        }
      });

      this.getselectedDocuments.emit(d);

      for (let i = 0; i < d.length; i++) {
        if (d[i] && d[i]['ID']) {
          ids.push(d[i]['ID']);
        }
      }
    }
    this.getselectedDocumentIDs.emit(ids);
    this.getselectedDocuments.emit(d);
  }

  sendCheckedFile(e, document) {
    if (document.checked == true) {
      this.getCheckedDocument.emit(document);
    } else {
      this.getUncheckedDocument.emit(document);
    }

    this.sendSelectedDocuments();
  }

  viewDocument(i) {
    if (
      this._documents[i] &&
      this._documents[i]['URL.Complete'] &&
      this._documents[i]['URL.Complete'] != '' &&
      this._documents[i]['URL.Complete'].indexOf('youtube.com') == -1 &&
      this._documents[i]['URL.Complete'].indexOf('vimeo.com') == -1
    ) {
      window.open(this._documents[i]['URL.Complete'], '_blank');
    } else {
      let p = {
        cardID: this.cardID,
        isDownload: this.isDownload,
        isPrint: this.isPrint,
        isVerify: this.isVerify,
        isRedirection: this.isRedirection,
        document: JSON.parse(JSON.stringify(this._documents[i])),
        title: this.title,
        description: this.description,
      };
      if (this.noViewOnClick == true) {
        this.getViewDocument.emit(p.document);
        this.getViewDocumentObject.emit(p);
      } else {
        const ref = this.dialog.open(EpDocumentViewComponent, {
          data: p,
          width: '800px',
          panelClass: 'noCard',
        });

        ref.componentInstance.closeModal.subscribe((res) => {
          ref.close();
        });

        ref.componentInstance.noFileFound.subscribe((res) => {
          NotifyAppComponent.displayToast('warning', 'Sorry!', 'We could not get the requested file(s)');
        });

        ref.componentInstance.applyVerify.subscribe((res) => {
          if (res == true) {
            const result = {
              isVerified: true,
              documentLabel: this._documents[i]['Label'],
            };
            this.applyVerify.emit(result);
            ref.close();
          } else {
            const result = {
              isVerified: false,
              documentLabel: this._documents[i]['Label'],
            };
            this.applyVerify.emit(result);
            ref.close();
          }
        });
      }
    }
  }

  closeEvent() {
    this.closeModal.emit(true);
  }

  actionEventGlobal() {
    this.sendActionGlobal.emit(true);
  }

  isRemoved(ID) {
    if (ID && this.removedList && this.removedList.length > 0) {
      const index = this.removedList.indexOf(ID);
      if (index != -1) {
        return true;
      }
      return false;
    }
    return false;
  }

  removeFile(file) {
    if (file && file.ID) {
      const ref = this.dialog.open(ConfirmDialogComponent);
      ref.componentInstance.onConfirm.subscribe((val) => {
        if (val == true) {
          if (this.directRemove == true) {
            const index = this._documents.findIndex((a) => {
              if (a && a.ID && a.ID == file.ID) {
                return true;
              } else {
                return false;
              }
            });

            if (index != -1) {
              this._documents.splice(index, 1);

              this.removedList.push(file.ID);
            }

            this.remove.emit(file);
            this.removeID.emit(file.ID);

            this.sendSelectedDocuments();

            ref.close();
          } else {
            this.remove.emit(file);
            this.removeID.emit(file.ID);

            ref.close();
          }
        } else {
          ref.close();
        }
      });
    }
  }

  isVerified(doc) {
    if (doc && doc.ID && this.verifiedIDs && this.verifiedIDs.length > 0 && this.verifiedIDs.indexOf(doc.ID) !== -1) {
      return true;
    }
    return false;
  }
  isRejected(doc) {
    if (doc && doc.ID && this.rejectedIDs && this.rejectedIDs.length > 0 && this.rejectedIDs.indexOf(doc.ID) !== -1) {
      return true;
    }
    return false;
  }
  editDocument(document) {
    if (document && document.ID) {
      const ref = RootAppComponent.dialog.open(EPDocumentCreateEditComponent, {
        data: {
          miniEdit: this.miniEdit,
          documentID: document.ID,
          types: this.documentTypes,
        },
        width: '750px',
      });
      ref.componentInstance.closeModal.subscribe((res) => {
        ref.close();
      });

      ref.componentInstance.result.subscribe((res) => {
        if (res && res.ID) {
          this.edit.emit(res);
          this.editID.emit(res.ID);

          const index = this._documents.findIndex((a) => {
            if (a && a.ID && a.ID == res.ID) {
              return true;
            } else {
              return false;
            }
          });

          if (index != -1) {
            this._documents[index] = res;
          }

          ref.close();
        }
      });
    }
  }

  filterEvent() {
    if (this.filter) {
      this._documents = this.FilterArrayMultipleContainValuePipe.transform(
        this._unfilteredDocuments,
        ['Description', 'Card.Name', 'Extension', 'ID', 'Label', 'URL.Complete', 'Tags'],
        this.filter
      );
    } else {
      this._documents = JSON.parse(JSON.stringify(this._unfilteredDocuments));
    }

    if (this.orderByFilter) {
      this._documents = this.OrderArrayObjectPipe.transform(this._documents, [this.orderByFilter]);
    }

    this.sendLength();
  }
}
