import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SettlementApproveViewComponent } from '../../settlement/settlement-approve-view/settlement-approve-view.component';
import { SettlementContractListComponent } from '../../settlement/settlement-contract-list/settlement-contract-list.component';
import { SettlementViewGroupComponent } from '../../settlement/settlement-view-group/settlement-view-group.component';
import { SettlementViewMiniComponent } from '../../settlement/settlement-view-mini/settlement-view-mini.component';
import { SettlementViewComponent } from '../../settlement/settlement-view/settlement-view.component';
import { ComplianceTaskComponent } from '../compliance-task/compliance-task.component';
import { ContractArrangementModalComponent } from '../contract-arrangement-modal/contract-arrangement-modal.component';
import { ContractCapacityCheckComponent } from '../contract-capacity-check/contract-capacity-check.component';
import { ContractCreditCheckComponent } from '../contract-credit-check/contract-credit-check.component';
import { ContractDetailAddPaymentComponent } from '../contract-detail-add-payment/contract-detail-add-payment.component';
import { ContractDetailContactsComponent } from '../contract-detail-contacts/contract-detail-contacts.component';
import { ContractDetailDocumentsComponent } from '../contract-detail-documents/contract-detail-documents.component';
import { ContractDetailFundingHistoryComponent } from '../contract-detail-funding-history/contract-detail-funding-history.component';
import { ContractDetailKPIComponent } from '../contract-detail-kpi/contract-detail-kpi.component';
import { ContractDetailOverviewComponent } from '../contract-detail-overview/contract-detail-overview.component';
import { ContractDetailPaymentsComponent } from '../contract-detail-payments/contract-detail-payments.component';
import { ContractDetailSharedSectionComponent } from '../contract-detail-shared-section/contract-detail-shared-section.component';
import { ContractDetailComponent } from '../contract-detail/contract-detail.component';
import { ContractDocumentViewComponent } from '../contract-document-view/contract-document-view.component';
import { ContractDocumentComponent } from '../contract-document/contract-document.component';
import { ContractIdentityCheckComponent } from '../contract-identity-check/contract-identity-check.component';
import { ContractIntroModalComponent } from '../contract-intro-modal/contract-intro-modal.component';
import { ContractOverviewComponent } from '../contract-overview/contract-overview.component';
import { ContractViewSimpleComponent } from '../contract-view-simple/contract-view-simple.component';
import { ContractViewComponent } from '../contract-view/contract-view.component';
import { ContractsViewComponent } from '../contracts-view/contracts-view.component';
import { PersonalCircumstancesViewComponent } from '../personal-circumstances-view/personal-circumstances-view.component';
import { PersonalCircumstancesComponent } from '../personal-circumstances/personal-circumstances.component';

const routes: Routes = [
  {
    path: 'contract-view',
    component: ContractViewComponent,
  },
  {
    path: 'SettlementViewComponent',
    component: SettlementViewComponent,
  },

  {
    path: 'ContractDocumentComponent',
    component: ContractDocumentComponent,
  },

  {
    path: 'ContractDocumentComponent/:contractID',
    component: ContractDocumentComponent,
  },

  {
    path: 'SettlementContractViewComponent',
    component: SettlementContractListComponent,
  },
  {
    path: 'SettlementViewMiniComponent',
    component: SettlementViewMiniComponent,
  },
  {
    path: 'SettlementViewGroupComponent',
    component: SettlementViewGroupComponent,
  },
  {
    path: 'contract-view-simple',
    component: ContractViewSimpleComponent,
  },
  {
    path: 'contract-document-view',
    component: ContractDocumentViewComponent,
  },



  {
    path: 'contract-overview',
    component: ContractOverviewComponent,
  },
  {
    path: 'contract-intro',
    component: ContractIntroModalComponent,
  },
  {
    path: 'contracts-view',
    component: ContractsViewComponent,
  },
  {
    path: 'contracts-view/:id',
    component: ContractsViewComponent,
  },

  {
    path: 'contract-detail',
    component: ContractDetailComponent,
  },
  {
    path: 'contract-detail-overview',
    component: ContractDetailOverviewComponent,
  },
  {
    path: 'contract-detail-payments',
    component: ContractDetailPaymentsComponent,
  },
  {
    path: 'contract-detail-kpi',
    component: ContractDetailKPIComponent,
  },
  {
    path: 'contract-detail-contacts',
    component: ContractDetailContactsComponent,
  },
  {
    path: 'contract-detail-funding-history',
    component: ContractDetailFundingHistoryComponent,
  },
  {
    path: 'contract-detail-documents',
    component: ContractDetailDocumentsComponent,
  },
  {
    path: 'contract-detail-sharedSection-component',
    component: ContractDetailSharedSectionComponent,
  },
  {
    path: 'personal-circumstances',
    component: PersonalCircumstancesComponent,
  },
  {
    path: 'personal-circumstances/:contractID',
    component: PersonalCircumstancesComponent,
  },
  {
    path: 'personal-circumstances-view',
    component: PersonalCircumstancesViewComponent,
  },
  {
    path: 'personal-circumstances-view/:personalCircumstanceID',
    component: PersonalCircumstancesViewComponent,
  },

  {
    path: 'contract-credit-check',
    component: ContractCreditCheckComponent,
  },
  {
    path: 'contract-capacity-check',
    component: ContractCapacityCheckComponent,
  },
  {
    path: 'contract-identity-check',
    component: ContractIdentityCheckComponent,
  },
  {
    path: 'compliance-task',
    component: ComplianceTaskComponent,
  },
  {
    path: 'compliance-task/:contractID',
    component: ComplianceTaskComponent,
  },
  {
    path: 'settlement-approve-view',
    component: SettlementApproveViewComponent,
  },
  {
    path: 'settlement-arrangement-modal',
    component: ContractArrangementModalComponent,
  },
  {
    path: 'contract-detail-add-payment',
    component: ContractDetailAddPaymentComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ContractRoutingModule {}
