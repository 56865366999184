import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import hexRgb from 'hex-rgb';
import { Settings } from '../../../shared/types/settings';

@Component({
  selector: 'app-zoom-in-info',
  templateUrl: './zoom-in-info.component.html',
  styleUrls: ['./zoom-in-info.component.css'],
})
export class ZoomInInfoComponent implements OnInit {
  zoomInImage = true;
  code;
  close = new EventEmitter();

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    if (data && data.code) {
      this.code = data.code;
    }
  }

  ngOnInit() {
    if (localStorage.getItem('zoomInImageModule') === 'true') {
      this.zoomInImage = true;
    }
  }

  closeEvent() {
    this.close.emit(true);
  }

  doNotShow() {
    localStorage.setItem('zoomInImageModule', JSON.stringify(this.zoomInImage));

    this.close.emit(this.zoomInImage);
  }

  doNotShow2() {
    localStorage.setItem('zoomInImageModule', JSON.stringify(this.zoomInImage));
  }

  buildBackground() {
    const color = Settings.global['primaryColor'] || '#1e88e5';
    if (color) {
      const rbg = hexRgb(color);

      if (rbg) {
        return `rgba(${rbg.red},  ${rbg.green} ,${rbg.blue} , 0.8)`;
      } else {
        return null;
      }
    }
  }
}
