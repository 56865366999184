import { Component, EventEmitter, Inject, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { environment } from 'environments/environment';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { FileInsertionComponent } from '../../../shared/components/file-insertion/file-insertion.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { ViewImageSimpleComponent } from '../../../shared/components/view-image-simple/view-image-simple.component';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { DocumentService } from '../shared/document.service';

@Component({
  selector: 'landing-page-header-lookup',
  templateUrl: './landing-page-header-lookup.component.html',
  styleUrls: ['./landing-page-header-lookup.component.css'],
})
export class LandingPageHeaderLookupComponent implements OnInit {
  @Output()
  getResult = new EventEmitter();

  @Output()
  close = new EventEmitter();

  viewOnly = false;

  link;
  defaultLinks = [
    'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/public/landing-page-header/arms-folded.png?type=b',
    'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/public/landing-page-header/Asset_1.png?type=b',
    'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/public/landing-page-header/Asset_3.png?type=b',
    'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/public/landing-page-header/Asset_4.png?type=b',
    'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/public/landing-page-header/Asset_6.png?type=b',
    'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dentist-sitting-left.png?type=b',
    'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/pen-and-phone-dentist.png?type=b',
    'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/public/landing-page-header/astonaut.png',
    'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/public/landing-page-header/dent-topIntro-thumbsup.png?type=b',
    'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/public/landing-page-header/dentist_hero_highfive.png',
    'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/public/landing-page-header/dentist_hero_pair.png',
    'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/public/landing-page-header/dentist_secrets.png',
    'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/public/landing-page-header/dentist_watch_1.png',
    'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/public/landing-page-header/dentist-clipboard-thumbsup.png',
    'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/public/landing-page-header/dentist-clipboard.png',
    'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/public/landing-page-header/dentist-computer.png',
    'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/public/landing-page-header/dentist-faq.png?type=b',
    'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/public/landing-page-header/dentist-help.png',
    'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/public/landing-page-header/dentist-patient-chair.png?type=b',
    'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/public/landing-page-header/dentist-thumbs-up.png?type=b',
    'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/public/landing-page-header/dentist-vip.png?type=b',
    'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/public/landing-page-header/dentist.png?type=b',
    'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/public/landing-page-header/dentistPatientHighFive.png?type=b',
    'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/public/landing-page-header/dont-panic.png?type=b',
    'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/public/landing-page-header/group-shot.png',
    'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/public/landing-page-header/patient-sit-chair.png?type=b',
    'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/public/landing-page-header/open-letter.png',
    'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/public/landing-page-header/party-hat.png',
    'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/public/landing-page-header/point.png',
    'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/public/landing-page-header/hands-up.png',
    'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/public/landing-page-header/smirk.png',
    'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/public/landing-page-header/thumbs-1.png',
    'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/public/landing-page-header/thumbs-2.png',
    'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/public/landing-page-header/toothbrush-1.png',
    'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/public/landing-page-header/toothbrush-2.png',
    'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/public/landing-page-header/wave.png',
  ];
  typeFilter = 'default';
  practiceLinks = [];
  settings = Settings.global;
  merchantID;
  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private authenticationService: AuthenticationService,
    private documentService: DocumentService
  ) {
    if (data && data.merchantID != null) {
      this.merchantID = data.merchantID;
    }
    if (data && data.viewOnly != null) {
      this.viewOnly = data.viewOnly;
    }
  }

  ngOnInit() {
    if (this.settings && this.settings['context'] === 'breeze') {
      this.defaultLinks = [
        'https://breezelivingwebsite.s3.ap-southeast-2.amazonaws.com/Images/info.png?type=c',
        'https://breezelivingwebsite.s3.ap-southeast-2.amazonaws.com/Images/Kirby+-+Made+in+America.png?type=c',
        'https://breezelivingwebsite.s3.ap-southeast-2.amazonaws.com/Images/Kirby+Assembly.jpg?type=c',
        'https://breezelivingwebsite.s3.ap-southeast-2.amazonaws.com/Images/Kirby_F5-Grab_0040-600x600.jpg?type=c',
        'https://breezelivingwebsite.s3.ap-southeast-2.amazonaws.com/Images/kirby_parts_111.jpg?type=c',
        'https://breezelivingwebsite.s3.ap-southeast-2.amazonaws.com/Images/Kirby-Hose-Mode-600x600.jpg?type=c',
        'https://breezelivingwebsite.s3.ap-southeast-2.amazonaws.com/Images/Kirby-Lifestyle-2455-600x600.jpg?type=c',
        'https://breezelivingwebsite.s3.ap-southeast-2.amazonaws.com/Images/Kirby-Lifestyle-3486-600x600.jpg?type=c',
        'https://breezelivingwebsite.s3.ap-southeast-2.amazonaws.com/Images/Kirby-Lifestyle-3934-600x600.jpg?type=c',
        'https://breezelivingwebsite.s3.ap-southeast-2.amazonaws.com/Images/Kirby-on-tile-600x600.jpg?type=c',
        'https://breezelivingwebsite.s3.ap-southeast-2.amazonaws.com/Images/kirby-on-tile-grout-cleaning.jpg?type=c',
        'https://breezelivingwebsite.s3.ap-southeast-2.amazonaws.com/Images/Kirby-shampoo-carpet.jpg?type=c',
        'https://breezelivingwebsite.s3.ap-southeast-2.amazonaws.com/Images/miracle-shine-action-600x600.jpg?type=c',
        'https://breezelivingwebsite.s3.ap-southeast-2.amazonaws.com/Images/Miracle-Shine-Kit-w-product-600x600.jpg?type=c',
        'https://breezelivingwebsite.s3.ap-southeast-2.amazonaws.com/Images/MSSS-1-600x600.jpg?type=c',
        'https://breezelivingwebsite.s3.ap-southeast-2.amazonaws.com/Images/MSSS-2-600x600.jpg?type=c',
        'https://breezelivingwebsite.s3.ap-southeast-2.amazonaws.com/Images/MSSS-3-600x600.jpg?type=c',
        'https://breezelivingwebsite.s3.ap-southeast-2.amazonaws.com/Images/MSSS-4-600x600.jpg?type=c',
        'https://breezelivingwebsite.s3.ap-southeast-2.amazonaws.com/Images/multi-surface-shampoo-system-avalir2.jpg?type=c',
        'https://breezelivingwebsite.s3.ap-southeast-2.amazonaws.com/Images/Multi-surface-shampoo-system-kit.jpg?type=c',
        'https://breezelivingwebsite.s3.ap-southeast-2.amazonaws.com/Images/Odourific+II.jpg?type=c',
        'https://breezelivingwebsite.s3.ap-southeast-2.amazonaws.com/Images/Odourific_II-2.jpg?type=c',
        'https://breezelivingwebsite.s3.ap-southeast-2.amazonaws.com/Images/Odourific_II.jpg?type=c',
        'https://breezelivingwebsite.s3.ap-southeast-2.amazonaws.com/Images/OxyAdditive_1000x1000-600x600.jpg?type=c',
        'https://breezelivingwebsite.s3.ap-southeast-2.amazonaws.com/Images/Pet-Stain-Remover-1000x1000-1-600x600.jpg?type=c',
        'https://breezelivingwebsite.s3.ap-southeast-2.amazonaws.com/Images/s-l1600.jpg?type=c',
        'https://breezelivingwebsite.s3.ap-southeast-2.amazonaws.com/Images/109273.jpg?type=c',
        'https://breezelivingwebsite.s3.ap-southeast-2.amazonaws.com/Images/12-Hose.jpg?type=c',
        'https://breezelivingwebsite.s3.ap-southeast-2.amazonaws.com/Images/152502.jpg?type=c',
        'https://breezelivingwebsite.s3.ap-southeast-2.amazonaws.com/Images/152584_FRONT_BrushRoll-600x600.jpg?type=c',
        'https://breezelivingwebsite.s3.ap-southeast-2.amazonaws.com/Images/156193-2.jpg?type=c',
        'https://breezelivingwebsite.s3.ap-southeast-2.amazonaws.com/Images/156294.jpg?type=c',
        'https://breezelivingwebsite.s3.ap-southeast-2.amazonaws.com/Images/159056G.jpg?type=c',
        'https://breezelivingwebsite.s3.ap-southeast-2.amazonaws.com/Images/159394.jpg?type=c',
        'https://breezelivingwebsite.s3.ap-southeast-2.amazonaws.com/Images/197394_Front_1000x1000_Jan2023-600x600.jpg?type=c',
        'https://breezelivingwebsite.s3.ap-southeast-2.amazonaws.com/Images/204811-6pack-600x600.jpg?type=c',
        'https://breezelivingwebsite.s3.ap-southeast-2.amazonaws.com/Images/204814-6pack.jpg?type=c',

        'https://breezelivingwebsite.s3.ap-southeast-2.amazonaws.com/Images/kir327614s.jpg?type=c',
        'https://breezelivingwebsite.s3.ap-southeast-2.amazonaws.com/Images/205811-2pack-600x600.jpg?type=c',
        'https://breezelivingwebsite.s3.ap-southeast-2.amazonaws.com/Images/205814-2pack-600x600.jpg?type=c',
        'https://breezelivingwebsite.s3.ap-southeast-2.amazonaws.com/Images/225406S_HOSE_CLOSE_UP_2000x2000-600x600.jpg?type=c',
        'https://breezelivingwebsite.s3.ap-southeast-2.amazonaws.com/Images/22ozCarpetandFabricCleaner1000x1000-600x600.jpeg?type=c',
        'https://breezelivingwebsite.s3.ap-southeast-2.amazonaws.com/Images/3-Pack-Belts-301291-600x600.jpg?type=c',
        'https://breezelivingwebsite.s3.ap-southeast-2.amazonaws.com/Images/31M%2B5B-UEXL._AC_.jpg?type=c',
        'https://breezelivingwebsite.s3.ap-southeast-2.amazonaws.com/Images/329613.jpg?type=c',
        'https://breezelivingwebsite.s3.ap-southeast-2.amazonaws.com/Images/32oz-CarpetShampoo-Pets-Back-1000x1000-1-600x600.jpeg?type=c',
        'https://breezelivingwebsite.s3.ap-southeast-2.amazonaws.com/Images/32oz-Miracle-Shine-1000x1000-1-600x600.jpeg?type=c',
        'https://breezelivingwebsite.s3.ap-southeast-2.amazonaws.com/Images/32oz-Miracle-Shine-Back-1000x1000-2-scaled-1-600x600.jpeg?type=c',
        'https://breezelivingwebsite.s3.ap-southeast-2.amazonaws.com/Images/32oz-Oxy-Additive-Back-1000x1000-1-scaled-1-600x600.jpeg?type=c',
        'https://breezelivingwebsite.s3.ap-southeast-2.amazonaws.com/Images/32oz-TileGrout-Cleaner-1000x1000-1-600x600.jpeg?type=c',
        'https://breezelivingwebsite.s3.ap-southeast-2.amazonaws.com/Images/32ozHardFloorCleaner_RTS_1000x1000-600x600.jpg?type=c',
        'https://breezelivingwebsite.s3.ap-southeast-2.amazonaws.com/Images/32ozShampooPreTreatment1000x1000.jpg?type=c',
        'https://breezelivingwebsite.s3.ap-southeast-2.amazonaws.com/Images/A2_package.png?type=c',
        'https://breezelivingwebsite.s3.ap-southeast-2.amazonaws.com/Images/Avalir-2-hero-10.jpg?type=c',
        'https://breezelivingwebsite.s3.ap-southeast-2.amazonaws.com/Images/Bed.jpg?type=c',
        'https://breezelivingwebsite.s3.ap-southeast-2.amazonaws.com/Images/Carpet+Shampoo+946+ml.jpeg?type=c',
        'https://breezelivingwebsite.s3.ap-southeast-2.amazonaws.com/Images/coffee-spill.jpg?type=c',
        'https://breezelivingwebsite.s3.ap-southeast-2.amazonaws.com/Images/DeodorizerDrops_VN-600x593.jpg?type=c',
        'https://breezelivingwebsite.s3.ap-southeast-2.amazonaws.com/Images/G3-filter-bags-3pk-1000x1000-510x510-1.jpg?type=c',
        'https://breezelivingwebsite.s3.ap-southeast-2.amazonaws.com/Images/Girl+with+Money+-+Low+Res.png?type=c',
        'https://breezelivingwebsite.s3.ap-southeast-2.amazonaws.com/Images/HFC+12+Oz.jpg?type=c',
        'https://breezelivingwebsite.s3.ap-southeast-2.amazonaws.com/Images/HFC_Conc-450x450.jpg?type=c',
        'https://breezelivingwebsite.s3.ap-southeast-2.amazonaws.com/Images/HomeFresh_CS-500x1016-1-600x600.jpg?type=c',
        'https://breezelivingwebsite.s3.ap-southeast-2.amazonaws.com/Images/HomeFresh_SF-500x1003-1-600x600.jpg?type=c',
        'https://breezelivingwebsite.s3.ap-southeast-2.amazonaws.com/Images/HomeFresh_Trio-1000x1000-1-scaled-1-600x600.jpg?type=c',

        'https://breezelivingwebsite.s3.ap-southeast-2.amazonaws.com/Images/s-l500.jpg?type=c',
        'https://breezelivingwebsite.s3.ap-southeast-2.amazonaws.com/Images/Scented+Tablets.jpg?type=c',
        'https://breezelivingwebsite.s3.ap-southeast-2.amazonaws.com/Images/Shampoo+1+gal+1.jpg?type=c',
        'https://breezelivingwebsite.s3.ap-southeast-2.amazonaws.com/Images/Shampoo+1+gal+2.jpg?type=c',
        'https://breezelivingwebsite.s3.ap-southeast-2.amazonaws.com/Images/Shampoo+12+oz.jpg?type=c',
        'https://breezelivingwebsite.s3.ap-southeast-2.amazonaws.com/Images/Shampoo+5+gal+unscented.jpg?type=c',
        'https://breezelivingwebsite.s3.ap-southeast-2.amazonaws.com/Images/Shampoo.jpg?type=c',
        'https://breezelivingwebsite.s3.ap-southeast-2.amazonaws.com/Images/tile-grout-cleaner.jpg?type=c',
        'https://breezelivingwebsite.s3.ap-southeast-2.amazonaws.com/Images/Tile-grout-kit-600x600.jpg?type=c',
        'https://breezelivingwebsite.s3.ap-southeast-2.amazonaws.com/Images/tileandgroutold.jpg?type=c',
        'https://breezelivingwebsite.s3.ap-southeast-2.amazonaws.com/Images/TurboAccessory-a2-768x768.jpg?type=c',
        'https://breezelivingwebsite.s3.ap-southeast-2.amazonaws.com/Images/TurboAccessory-single-a2-500x500-1.jpg?type=c',
        'https://breezelivingwebsite.s3.ap-southeast-2.amazonaws.com/Images/whyshampooworks.jpg?type=c',
        'https://breezelivingwebsite.s3.ap-southeast-2.amazonaws.com/Images/Zip+Brush.jpg?type=c',
        'https://breezelivingwebsite.s3.ap-southeast-2.amazonaws.com/Images/Zipp+Foam+22+oz.jpg?type=c',
        'https://breezelivingwebsite.s3.ap-southeast-2.amazonaws.com/Images/ZippBrush-1000x1000-600x600.jpg?type=c',
      ];
    } else if (this.settings && this.settings['context'] === 'p4p') {
      this.defaultLinks = [
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/duke-contact+-+Copy.png?type=b',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/duke-help.png?type=b',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/duke-racer.png?type=b',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/get-award.png?type=b',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/hold-clock.png?type=b',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/pointing.png?type=b',

        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/gay-pride-dachshund-dog-isolated-yellow-background.jpg?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dachshund-puppy-dog-bathing-wearing-coral-towel-isolated-pink-pastel-background.jpg?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/calm-labrador-dog-grooming-procedure-woman-holding-comb-with-wool-golden-retriever-sitting.jpg?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/happy-smiling-guy-with-dog-orange.jpg?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/happy-puppy-dog-smiling-isolated-purple-background.jpg?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/close-up-shot-pleased-woman-with-afro-hair-holds-two-puppies-spends-leisure-time-with-loyal-animal-friends-happy-have-newborn-french-bulldog-dogs.jpg?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/people-animals-relationship-concept-happy-positive-curly-african-american-woman-poses-with-pet-going-have-walk-outdoor-together-small-dog-licks-owner-cheek-expresses-love-being-cared.jpg?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/young-woman-with-afro-haircut-holding-puppy.jpg?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/happy-cheery-optimistic-man-posing-isolated-purple-wall-holding-hugging-dog-pug.jpg?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/portrait-beautiful-girl-keeping-pretty-pekingese-wants-kissing-him.jpg?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/small-cute-beagle-puppy-dog-looking-up.jpg?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/good-humored-woman-holds-dog-laughing-pink-background-emotional-sort-haired-girl-grey-hoodie-poses-with-corgi-isolated.jpg?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/red-haired-white-girl-posing-studio-with-her-pet-good-looking-young-lady-hat-holding-french-bulldog-bright.jpg?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/fashion-young-woman-posing-with-dog.jpg?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dark-skinned-lady-keeps-lips-rounded-wants-kiss-adorable-pet-plays-with-small-puppy.jpg?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/laughing-jocund-woman-sitting-floor-with-dog-her-knees-indoor-portrait-pleasant-lady-posing-with-puppy-pastel.jpg?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/wonderful-european-female-model-chilling-with-puppy-indoor-portrait-debonair-girl-enjoying-portraitshoot-with-her-cute-pet.jpg?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/veterinarian-checking-ears-adorable-french-bulldog-dog-isolated-image.jpg?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/cropped-close-up-professional-vet-examining-little-siberian-husky-puppy-using-stethoscope-her-vet-clinic-professionalism-medical-concept.jpg?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/adorable-puppy-chocolate-color-reception-vet-doctor-close-up-isolated-background-studio-photo-concept-care-education-obedience-training-raising-pets.jpg?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/veterinary-treatment-lovely-maltese-dog-friendly-veterinary.jpg?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/cute-white-labrador-puppy-lying-medical-table-during-ear-examination-by-young-veterinarian-protective-gloves-uniform.jpg?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/smile-doctor-kind-positive-middleaged-vet-checking-teeth-small.jpg?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/veterinarian-black-gloves-with-dog-cat-his-hands.jpg?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/pomeranian-dog-getting-injection-with-vaccine-during-appointment.jpg?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/veterinarian-examining-cute-dog.jpg?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/vet-examining-dog-cat-puppy-kitten-veterinarian-doctor-pet-check-up-vaccination.jpg?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/lovable-pretty-puppy-brown-color-closeup.jpg?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/young-man-veterinarian-examining-dog-table-veterinary-clinic-medicineanimals-health-care.jpg?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/cute-patient-male-vet-work-uniform-holding-little-beautiful-dog-which-is-sitting.jpg?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/this-isnt-chew-toy-cropped-shot-vet-trying-listen-bulldog-puppys-heartbeat.jpg?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/vet-examining-dog-cat.jpg?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/veterinary-doctors-holding-dog-cat.jpg?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/portrait-young-white-brown-dog-with-stethoscope.jpg?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/cute-dog-during-consultation.jpg?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/close-up-doctor-wearing-gloves.jpg?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/close-up-doctor-clinic-with-smiley-dog.jpg?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/golden-retriever-dog-ear-examination-by-doctor-during-appointment-veterinary-clinic.jpg?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/veterinarian-taking-care-pet.jpg?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/veterinarian-taking-care-pet-dog.jpg?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/veterinarian-checking-dog-medium-shot.jpg?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/close-up-veterinarian-taking-care-pet.jpg?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/2023-08-05_02h25_49.png?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/2023-08-05_01h53_37.png?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/2023-08-05_01h53_59.png?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/2023-08-05_01h54_20.png?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/2023-08-05_01h54_40.png?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/2023-08-05_01h55_14.png?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/2023-08-05_01h55_57.png?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/2023-08-05_01h56_21.png?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/2023-08-05_01h56_46.png?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/2023-08-05_01h57_11.png?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/2023-08-05_01h57_37.png?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/2023-08-05_01h58_32.png?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/2023-08-05_01h58_57.png?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/2023-08-05_02h00_04.png?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/2023-08-05_02h00_29.png?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/2023-08-05_02h01_04.png?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/2023-08-05_02h01_30.png?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/2023-08-05_02h02_35.png?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/2023-08-05_02h02_54.png?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/2023-08-05_02h03_43.png?type=c',

        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/turtle-woman-hands-close-up.jpg?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/hand-herpetologist-s-veterinarian-ointment-covers-shell-turtle.jpg?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/hamster-is-sitting-endive-leafs-white.jpg?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/two-fluffy-bunnies-look-signboard.jpg?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/beautiful-wavy-parrot-blue-color-sits-without-cage.jpg?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/budgerigar-studio.jpg?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/common-pet-parakeet.jpg?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/small-fluffy-rabbit-white.jpg?type=c',

        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/2023-08-05_02h04_09.png?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/cat-dog-are-looking-piece-paper.jpg?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/cute-pet-collage-isolated.jpg?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/cute-puppy-embraces-woman-nature-sunlight-generative-ai.jpg?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/cute-puppy-kitten-sit-together-yellow-background.jpg?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/cute-puppy-portrait-close-up-focus-nose-generative-ai.jpg?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/cute-puppy-sitting-table-looking-vase-generated-by-ai.jpg?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dog-cat-are-posing-photo-together.jpg?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dog-cat-are-sitting-together.jpg?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/dog-standing-up-with-its-head-up-its-hind-legs.jpg?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/funny-canines-gray-kittens-fashionable-blue-background-beautiful-fluffy-cats-chihuahua-pug-puppy-pomeranian-spitz-climb-out-hole-colored-background.jpg?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/pet2.png?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/pet5.png?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/pets1.png?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/small-dog-sits-blue-background-with-blue-background.jpg?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/2023-08-05_02h18_43.png?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/2023-08-05_02h18_18.png?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/2023-08-05_02h17_44.png?type=c',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/2023-08-05_02h17_22.png?type=c',
      ];
    }

    this.authenticationService.getCurrentPractice().subscribe((r) => {
      if (r && r.ID && !this.merchantID) {
        this.merchantID = r.ID;
      }

      const list = [{ ID: 'merchant', link: 'merchant' }];
      this.documentService.getDocumentLinkHeaders({ fields: 'ID,AWS_Bucket_Name,AWS_URL' }).subscribe((r) => {
        if (r && r.length > 0) {
          r.map((item) => {
            if (item && item.AWS_Bucket_Name && item.AWS_URL) {
              list.push({
                ID: item.ID,
                link: `${Settings.global['AWSS3Prefix']}/${item.AWS_Bucket_Name}${item.AWS_URL}?type=c`,
              });
            }
          });
        }

        this.practiceLinks = list;
      });
    });
  }

  getMerchantLink() {
    return environment.nodeUrl + '/merchant/practice-picture-stream/' + this.merchantID;
  }

  preview(link) {
    if (link) {
      const ref = RootAppComponent.dialog.open(ViewImageSimpleComponent, {
        data: {
          link,
        },

        width: '800px',
        panelClass: 'noCard',
      });
      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    }
  }

  submitOrPreview(d) {
    if (d) {
      if (this.viewOnly === true) {
        if (d == 'merchant' && this.merchantID) {
          this.preview(this.getMerchantLink());
        } else {
          this.preview(d);
        }
      } else {
        this.select(d);
      }
    }
  }

  select(link) {
    this.getResult.emit(link);
  }

  delete(id) {
    if (id) {
      const confirm = new ConfirmDialog('delete', '', 'Are you sure you want to delete this Document ?', 'No', 'Yes');
      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation === false) {
          ref.close();
        } else {
          this.documentService.deleteDocumentFromLandingPage(id).subscribe((r) => {
            if (r == true) {
              const index = this.practiceLinks.findIndex((item) => {
                if (item && item.ID && item.ID == id) {
                  return true;
                }
                return false;
              });

              if (index != -1) {
                this.practiceLinks.splice(index, 1);
              }

              NotifyAppComponent.displayToast(
                'success',
                'Successful operation',
                'The Document has been successfully deleted'
              );
              ref.close();
            }
          });
        }
      });
    }
  }

  upload() {
    const ref = RootAppComponent.dialog.open(FileInsertionComponent, {
      data: {
        documentType: 'LP_HI',
        displayGallery: false,
        isGallery: true,
        isFixedAspectRatio: true,
        roundCropper: true,
        aspectRatioType: 'full',
        documentNature: 'image',
        keepOriginal: false,
        maxFileSize: 1,
      },
      width: '600px',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
    ref.componentInstance.getResult.subscribe((document) => {
      if (document && document.ID && document.AWS_Bucket_Name && document.AWS_URL) {
        this.link = `${Settings.global['AWSS3Prefix']}/${document.AWS_Bucket_Name}${document.AWS_URL}?type=c`;

        this.practiceLinks.push({ ID: document.ID, link: this.link });
        this.typeFilter = 'user';
        ref.close();
      }
    });
  }

  closeEvent() {
    this.close.emit(true);
  }
}
