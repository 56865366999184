import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-notification-overview',
  templateUrl: './notification-overview.component.html',
  styleUrls: ['./notification-overview.component.css'],
})
export class NotificationOverviewComponent implements OnInit {
  notificationsExist;

  constructor() {}

  ngOnInit() {}
}
