<div class="rel text-center wizardModal intro">
  <mat-card class="card">
    <div class="card-header primary-gradient-img clearfix inModal">
      <div class="row clearfix">
        <!-- merchant calculated name -->
        <div class="row text-left clearfix">
          <!-- Modal dismiss button -->
          <button class="btn-close btn-clear mat-button white" (click)="doNotShow()">
            <mat-icon>clear</mat-icon>
          </button>
          <h2 class="summary-header rm-m">
            Welcome to the Settlement Request Interface!
            <span class="subLabel">Get paid quicker, reduce paperwork and manage your settlements from
              {{ "BRANDING.Brand Name" | translate | titlecase }}</span>
          </h2>
        </div>
      </div>
    </div>

    <mat-dialog-content>
      <aw-wizard class="settlementCreate" navBarLayout="large-filled-symbols">
        <aw-wizard-step [navigationSymbol]="{ symbol: 1 }">
          <img
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/smile_right/settlement-header-0.png"
            mat-card-image/>
          <div class="row clearfix text-center">
            <p class="lead rm-mt">
              As {{ "KEYWORD.treatment" | translate }} is completed, you can quickly schedule a request for payment to
              the {{"KEYWORD.practice" | translate}}.
            </p>
            <p class="larger">
              Using the following screen, you can record any HICAPS payments processed at the time
              of {{ "KEYWORD.treatment" | translate }} be
              credited directly to the {{ "KEYWORD.patient" | translate }}'s account. Click 'Learn more' below to see
              how...
            </p>
            <div class="control-buttons row clearfix mb">
              <button class="pull-left" mat-mini-fab color="primary" disabled>
                <mat-icon>keyboard_arrow_left</mat-icon>
              </button>
              <div class="pull-right">
                <h3 class="sr-title readMore">Learn more...</h3>
                <button class="pull-right" mat-mini-fab color="primary" awNextStep>
                  <mat-icon>chevron_right</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </aw-wizard-step>

        <!-- <img src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/smile_right/newSettlementRequest.png" class="thr-qtr-width" /> -->

        <aw-wizard-step [navigationSymbol]="{ symbol: 2 }">
          <img class="border-bottom"
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/smile_right/settlement-intro-slide-2.jpg"
               mat-card-image/>

          <div class="row clearfix text-center">
            <p class="lead rm-mt">
              <strong>1.</strong> Enter your {{ "KEYWORD.patient" | translate }}'s{{ "KEYWORD.treatment" | translate }}
              value and date of
              treatment.
            </p>
            <p class="add-mb">
              The first section allows you to enter the value of the work which has been completed, which is the amount
              you wish to draw from your {{ "KEYWORD.patient" | translate }}'s payment product.
            </p>
            <div class="control-buttons row clearfix mb">
              <button class="pull-left" mat-mini-fab color="primary" awPreviousStep>
                <mat-icon>keyboard_arrow_left</mat-icon>
              </button>
              <div class="pull-right">
                <h3 class="sr-title readMore">Next</h3>
                <button class="pull-right" mat-mini-fab color="primary" awNextStep>
                  <mat-icon>chevron_right</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </aw-wizard-step>

        <aw-wizard-step [navigationSymbol]="{ symbol: 3 }">
          <img class="border-bottom"
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/smile_right/settlement-intro-slide-3.jpg"
               mat-card-image/>

          <div class="row clearfix text-center">
            <p class="lead rm-mt"><strong>2.</strong> Enter any HICAPS claim or third party payments.</p>
            <p class="add-mb">
              The interface allows you to define deductions to a health insurance provider or any third parties which
              also require settlements.
            </p>
            <div class="control-buttons row clearfix mb">
              <button class="pull-left" mat-mini-fab color="primary" awPreviousStep>
                <mat-icon>keyboard_arrow_left</mat-icon>
              </button>
              <div class="pull-right">
                <h3 class="sr-title readMore">Next</h3>
                <button class="pull-right" mat-mini-fab color="primary" awNextStep>
                  <mat-icon>chevron_right</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </aw-wizard-step>

        <aw-wizard-step [navigationSymbol]="{ symbol: 4 }">
          <img class="border-bottom"
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/smile_right/settlement-intro-slide-4.jpg"
               mat-card-image/>
          <div class="row clearfix text-center">
            <p class="lead rm-mt">
              <strong>3.</strong> Next step is {{ "KEYWORD.patient" | translate }} acknowledgment. You can either choose
              to have an SMS sent to your
              {{"KEYWORD.patient" | translate}}, <em>or</em> print off an acknowledgement form for them to sign
            </p>
            <p class="add-mb">
              If you choose the SMS option you will need the {{ "KEYWORD.patient" | translate }} to give you the code as
              their authority, then enter
              it into the settlement form, alternatively you can upload the signed acknowledgement form at this point.
            </p>
            <div class="control-buttons row clearfix mb">
              <button class="pull-left" mat-mini-fab color="primary" awPreviousStep>
                <mat-icon>keyboard_arrow_left</mat-icon>
              </button>
              <div class="pull-right">
                <h3 class="sr-title readMore">Next</h3>
                <button class="pull-right" mat-mini-fab color="primary" awNextStep>
                  <mat-icon>chevron_right</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </aw-wizard-step>

        <aw-wizard-step [navigationSymbol]="{ symbol: 5 }">
          <img class="border-bottom"
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/smile_right/settlement-intro-slide-5.jpg"
               mat-card-image/>
          <div class="row clearfix text-center">
            <p class="lead rm-mt">
              <strong>4.</strong> Once authorised, simply attach the invoice, fill in
              the {{ "KEYWORD.treatment" | translate }} details, and send
              the request!
            </p>
            <p class="">
              {{ "BRANDING.Brand Name" | translate | titlecase }} will then review and approve the request... It's that
              easy!
            </p>
            <hr class="mb"/>
            <div class="control-buttons row clearfix">
              <button class="pull-left" mat-mini-fab color="primary" awPreviousStep>
                <mat-icon>keyboard_arrow_left</mat-icon>
              </button>
            </div>
          </div>
        </aw-wizard-step>
      </aw-wizard>
    </mat-dialog-content>

    <div class="row clearfix">
      <hr/>
      <mat-checkbox class="mt" [(ngModel)]="showOnSettlementCreate">Dont Show Again</mat-checkbox>
      <button class="btn-small pull-right" (click)="doNotShow()" mat-raised-button color="accent">
        Got It! &nbsp;
        <mat-icon class="fas fa-hand-point-right"></mat-icon>
      </button>
    </div>
  </mat-card>
</div>
