<mat-dialog-content *ngIf="globalLoad==true" class="sms-dialog-box"
  [ngClass]="(isModal!=true)?'integrated':'not-integrated'">
  <div class="full-width mt container-message flex" [ngClass]="(isModal!=true)?'integrated':'not-integrated'">
    <div class="user-list" *ngIf="singleChat!=true" [ngClass]="(isModal!=true)?'integrated':'not-integrated'">
      <div class="user-list-top">
        <div class="full-width smt smb flex text-center" *ngIf="merchant && merchant.ID">
          <app-card-picture class="side-card-picture-merchant" [cardID]="merchant.ID" [hideZoomButton]="false"
            [tableName]="'Merchant'" [LastModified]="merchant['LastModified']"></app-card-picture>
          <div class="full-width">
            <h3 class="sr-title text-left merchant-name" *ngIf="merchant && merchant.ID">
              <span class="title-click" (click)="viewMerchant(merchant.ID)" matTooltip="Click to view profile">
                {{ merchant.TradingAs }} </span>

              <div *ngIf="merchant.IsPromoter==='1' && isContactStaff==true && promoterStaffGlobalNumber">

                <mat-icon class="title-click" class="fas fa-mobile-alt " (click)="copyPhone(promoterStaffGlobalNumber)">
                </mat-icon>
                <span class="title-click" *ngIf="promoterStaffGlobalNumber"
                  (click)="copyPhone(promoterStaffGlobalNumber)" matTooltip="Click to copy the Number">
                  {{ promoterStaffGlobalNumber | customPhone }}
                </span>

              </div>

              <div *ngIf="merchant.IsPromoter==='1' && isContactStaff!=true && promoterGlobalNumber">


                <mat-icon class="title-click" class="fas fa-mobile-alt " (click)="copyPhone(promoterGlobalNumber)">
                </mat-icon>
                <span class="title-click" *ngIf="promoterGlobalNumber" (click)="copyPhone(promoterGlobalNumber)"
                  matTooltip="Click to copy the Number">
                  {{ promoterGlobalNumber | customPhone }}
                </span>
              </div>
              <div *ngIf="merchant.IsPromoter==='0' && merchant.Twilio_Number">

                <mat-icon class="title-click" class="fas fa-mobile-alt " (click)="copyPhone(merchant.Twilio_Number)">
                </mat-icon>
                <span class="title-click" *ngIf="merchant.Twilio_Number" (click)="copyPhone(merchant.Twilio_Number)"
                  matTooltip="Click to copy your dedicated Number">
                  {{ merchant.Twilio_Number | customPhone }}
                </span>


              </div>
            </h3>
          </div>
        </div>


        <p class="small text-center mr  smb rm-mt  ml">
          Chat with an existing {{keyWord | translate}} in the list or press
          Select {{ keyWord | translate | titlecase }} to start a new
          chat.
        </p>

        <div class="full-width mb text-center">
          <button class="btn mr" *ngIf="isContactStaff!=true" (click)="selectPatient()" mat-raised-button
            color="primary">
            Select {{ keyWord | translate | titlecase }}
          </button>


          <button class="btn mr" *ngIf="isContactStaff==true" (click)="selectContact()" mat-raised-button
            color="primary">
            Select Staff Member
          </button>



          <button mat-raised-button *ngIf="isContactStaff!=true" class="btn  mr " color="accent"
            [matTooltip]="'Add New' + (keyWord | translate | titlecase)" (click)="newPatient()">
            <mat-icon class="fas fa-user-plus smr" style="font-size: 1rem;margin-top: 10px;"></mat-icon> Add
          </button>


          <button mat-raised-button *ngIf="isContactStaff==true && (isPromoterOrAdmin==true  || isMerchantAdmin==true)"
            class="btn  mr " color="accent" [matTooltip]="'Add New' + (keyWord | translate | titlecase)"
            (click)="newContact()">
            <mat-icon class="fas fa-user-plus smr" style="font-size: 1rem;margin-top: 10px;"></mat-icon> Add
          </button>
          <button class="btn" *ngIf="isPromoterOrAdmin == true" (click)="promoterConfiguration()"
            matTooltip="Make another selection" mat-icon-button color="accent">
            <mat-icon class="fas fa-building primary-color"></mat-icon>
          </button>
        </div>

        <h3 class="sr-title chat-title full-width ml rm-mt rm-mb">
          Chats
          <span class="badge master"
            [endVal]="(users | FilterArrayMultipleOrContain: 'search':userFilter | lengthOfArray) || 0" countUp></span>

          <button class="pull-right filter-button" [matMenuTriggerFor]="filterMerchant" mat-icon-button>
            <mat-icon class="fas fa-filter accent-color"></mat-icon>
          </button>

          <mat-menu #filterMerchant="matMenu">
            <button [ngClass]="filterType == 'newest' ? 'accent-color' : ''" [matTooltip]="
                'This is ' +
                (keyWord | translate) + 's' +
                ' with messages (incoming & outgoing) sorted by date (Newest to oldest)'
              " (click)="applyFilter('newest')" mat-menu-item>
              <span>Newest </span>
            </button>

            <hr />
            <button [ngClass]="filterType == 'unread' ? 'accent-color' : ''" [matTooltip]="
                'This is ' +
                (keyWord | translate) + 's' +
                ' with unread messages at the top of the list, then continue with the newest filtering for the rest of the list.'
              " (click)="applyFilter('unread')" mat-menu-item>
              <span>Unread</span>
            </button>
            <hr />

            <!-- <button mat-menu-item [ngClass]="(filterType=='oldest')?'accent-color':''"
              [matTooltip]="'This is '+ (keyWord | translate) + 's' +' with messages (incoming & outgoing) sorted by date (Oldest to newest)'"
              (click)="applyFilter('oldest')">
              <span>Oldest </span>
            </button>

            <hr>
            -->
            <button [ngClass]="filterType == 'incoming' ? 'accent-color' : ''" [matTooltip]="
                'This is ' +
                (keyWord | translate) + 's' +
                ' with messages (incoming) sorted by date (Newest to oldest)'
              " (click)="applyFilter('incoming')" mat-menu-item>
              <span>Incoming </span>
            </button>
            <button [ngClass]="filterType == 'outgoing' ? 'accent-color' : ''" [matTooltip]="
                'This is ' +
                (keyWord | translate) + 's' +
                ' with messages (Outgoing) sorted by date (Newest to oldest)'
              " (click)="applyFilter('outgoing')" mat-menu-item>
              <span>Outgoing </span>
            </button>
          </mat-menu>
        </h3>

        <div class="full-width flex">
          <mat-form-field class="full-width no-line-input smr ml">

            <mat-icon matPrefix class=" fas fa-search sms_list-search_icon"></mat-icon>
            <input matInput name="userFilter" placeholder="Filter..." [(ngModel)]="userFilter">
            <mat-icon matSuffix *ngIf="userFilter" class="fas fa-times sms_list-search_icon_clear"
              (click)="clearUserFilter()"> </mat-icon>
          </mat-form-field>
        </div>
      </div>
      <div class="user-list-body" id="user-list-body" [ngClass]="isModal != true ? 'integrated' : 'not-integrated'">
        <div class="full-width flex fixed-direction" *ngFor="
            let user of users | OrderArrayObject: [orderFilter] | FilterArrayMultipleOrContain: 'search':userFilter;
            let index = index
          ">
          <div class="full-width flex" [ngClass]="
              unreadMessage &&
              unreadMessage[user['Card_key']] &&
              unreadMessage[user['Card_key']] > 0 &&
              user['Card_key'] != selectedCard?.Card_key
                ? 'user-container-not-read'
                : ''
            ">
            <div class="full-width user-container flex" [ngClass]="
                selectedCard && selectedCard.Card_key && selectedCard.Card_key == user.Card_key
                  ? 'primary-bg'
                  : 'user-container-not-selected'
              " (click)="selectCard(user)">
              <span class="chip chipBeta"
                *ngIf="unreadMessage && unreadMessage[user['Card_key']] && unreadMessage[user['Card_key']] > 0">{{ unreadMessage[user['Card_key']] }}</span>

              <app-card-picture class="pull-left side-card-picture mr" [tableName]="recieverTableName"
                [cardID]="user['Card_key']"></app-card-picture>

              <div class="full-width user-inner-card">
                <button class="documentLink-view-button" *ngIf="user && user['Card_key']"
                  [matMenuTriggerFor]="previewMenu" (click)="$event.stopPropagation()" color="primary" mat-icon-button
                  matTooltip="View Landing Page Details">
                  <mat-icon>more_vert</mat-icon>
                </button>

                <mat-menu #previewMenu="matMenu">
                  <button *ngIf="isContactStaff!=true" (click)="openPatientProfile(user['Card_key'])" mat-menu-item>
                    <mat-icon class="smr">visibility</mat-icon>
                    <span>{{ keyWord | translate | titlecase }} Profile</span>
                  </button>

                  <button *ngIf="isContactStaff!=true" mat-menu-item (click)=" modify(user['Card_key'])">
                    <mat-icon class="smr fas fa-edit"></mat-icon>
                    <span>Edit Profile</span>
                  </button>


                  <button *ngIf="isContactStaff!=true" mat-menu-item (click)=" modifyTags(user['Card_key'])">
                    <mat-icon class="smr fas fa-tags"></mat-icon>
                    <span>Edit Tags/ Marketing consents</span>
                  </button>


                  <button *ngIf="isContactStaff==true" (click)="openContactProfile(user['Card_key'])" mat-menu-item>
                    <mat-icon class="smr">visibility</mat-icon>
                    <span> View Profile</span>
                  </button>
                  <button *ngIf="isContactStaff==true" mat-menu-item (click)=" modifyContact(user['Card_key'])">
                    <mat-icon class="smr fas fa-edit"></mat-icon>
                    <span>Edit Profile</span>
                  </button>




                  <button mat-menu-item
                    *ngIf="unreadMessage && (!unreadMessage[user['Card_key']]  || unreadMessage[user['Card_key']]<=0) && user && user.Direction=='In' "
                    (click)="userUnreadMessage(user['Card_key'] , user ,false )">
                    <mat-icon class="fas fa-comment-slash smr"></mat-icon>
                    <span>Mark as Unread</span>
                  </button>

                  <button
                    *ngIf="unreadMessage && unreadMessage[user['Card_key']] && unreadMessage[user['Card_key']] > 0"
                    (click)="editUnread(user['Card_key'], false)" mat-menu-item>
                    <mat-icon class="fas fa-check-circle smr"></mat-icon>
                    <span>Mark as Read</span>
                  </button>
                </mat-menu>

                <h4 class="sr-title rm-m">{{ user['Card_Name'] }}</h4>

                <p class="small part-message rm-m" [ngClass]="
                    unreadMessage && unreadMessage[user['Card_key']] && unreadMessage[user['Card_key']] > 0
                      ? 'unread-message'
                      : ''
                  ">
                  {{ user['Body'] | textLength: maxLength }}
                </p>

                <div class="full-width flex">
                  <div class="full-width">
                    <mat-icon class="fas fa-reply pull-left direction-type out" *ngIf="user && user.Direction == 'Out'"
                      [ngClass]="
                        selectedCard && selectedCard.Card_key && selectedCard.Card_key == user.Card_key
                          ? 'selected-direction'
                          : ''
                      "></mat-icon>
                    <mat-icon class="fas fa-reply pull-left direction-type in" *ngIf="user && user.Direction == 'In'"
                      [ngClass]="
                        selectedCard && selectedCard.Card_key && selectedCard.Card_key == user.Card_key
                          ? 'selected-direction'
                          : ''
                      "></mat-icon>
                  </div>
                  <p class="small date-user-main smt full-width text-right rm-m" [ngClass]="
                      unreadMessage && unreadMessage[user['Card_key']] && unreadMessage[user['Card_key']] > 0
                        ? 'unread-message'
                        : ''
                    ">
                    {{ user['DateTimeCreated'] | customDate: 'DD-MM-YYYY hh:mm A' }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="full-width text-center no-user-found fixed-direction"
          *ngIf="(users | FilterArrayMultipleOrContain: 'search':userFilter | lengthOfArray) <= 0 && userLoaded == true">
          <mat-icon class="fas fa-comments"></mat-icon>

          <p>No Chat Found</p>
        </div>

        <div class="full-width fixed-direction user-list-load-more mt text-center" *ngIf="loadMoreUser == true">
          <button class="btn" (click)="getUserList(true)" mat-raised-button color="primary">Load more...</button>
        </div>
      </div>
      <div class="user-list-bottom">

        <div class="full-width  text-center">

          <button mat-raised-button class="btn ml mt pull-left " *ngIf="isModuleBulkSMS==true && isContactStaff!=true"
            color="primary" (click)="messageGroupList()">Bulk SMS</button>

          <button class="btn mr mt pull-right" *ngIf="filterType != 'oldest'  && isContactStaff!=true"
            (click)="refreshMessage()" mat-raised-button color="accent" matTooltip="Refresh">
            <mat-icon class="smr">sync</mat-icon> Refresh
          </button>


          <button class="btn mr mt pull-left" *ngIf="filterType != 'oldest'  && isContactStaff==true"
            (click)="refreshMessage()" mat-raised-button color="accent" matTooltip="Refresh">
            <mat-icon class="smr">sync</mat-icon> Refresh
          </button>
        </div>
      </div>
    </div>
    <div class="full-width message-list empty" *ngIf="!selectedCard">
      <button class="btn-close btn-clear mat-button pull-right mr" *ngIf="isModal==true" (click)="closeEvent()">
        <mat-icon>clear</mat-icon>
      </button>

      <app-empty-list-message *ngIf="singleChat != true && isContactStaff!=true"
        [message]="'Select a ' + (keyWord | translate | titlecase) + ' from the side menu or use the ' + (keyWord | translate | titlecase) + ' lookup.'"
        [isAction]="true" [actionLabel]="(keyWord | translate | titlecase) + ' Lookup'" [actionIcon]="null"
        (actionEvent)="selectPatient()">
      </app-empty-list-message>


      <app-empty-list-message *ngIf="singleChat != true && isContactStaff==true"
        [message]="'Select a ' + (keyWord | translate | titlecase) + ' from the side menu or use the ' + (keyWord | translate | titlecase) + ' lookup.'"
        [isAction]="true" [actionLabel]="(keyWord | translate | titlecase) + ' Lookup'" [actionIcon]="null"
        (actionEvent)="selectContact()">
      </app-empty-list-message>
    </div>




    <div class="full-width message-list" *ngIf="selectedCard && selectedCard.ID"
      [ngClass]="singleChat == true ? 'single-chat' : ''">
      <div class="full-width flex" [ngClass]="isModal != true ? 'integrated' : ''">
        <div class="card-header full-width primary-gradient-img  hide-750px clearfix stacked">
          <div class="row clearfix">
            <button class="btn-close btn-clear mat-button white" *ngIf="isModal==true" (click)="closeEvent()">
              <mat-icon>clear</mat-icon>
            </button>

            <div class="full-width flex">
              <app-card-picture class="pull-left side-card-picture-selected mr" [hideZoomButton]="false"
                [tableName]="recieverTableName" [cardID]="selectedCard['ID']"></app-card-picture>

              <div class="full-width selected-card-details ml">
                <h2
                  *ngIf="selectedCard.Card_TableName === 'Prospect' || selectedCard.Card_TableName === 'CustomersAndProspects' || selectedCard.Card_TableName === 'Customer' "
                  class="summary-header patientName text-left rm-m"
                  [matTooltip]="'View ' + (keyWord | translate | titlecase) + ' Profile'"
                  (click)="openPatientProfile(selectedCard['ID'])">
                  <strong *ngIf="selectedCard['Salutation']"> {{ selectedCard["Salutation"] }}</strong> <strong>
                    {{ selectedCard["CalculatedName"] }}</strong>

                  <span *ngIf="selectedCard['MarketingConsent.Given'] != '1'" style="font-size: 0.9rem;"
                    class="chip sml smr pull-right  chipBeta2">No marketing consent</span>

                  <span *ngIf="selectedCard['Status'] == 'Archived'" style="font-size: 0.9rem;"
                    class="chip sml smr pull-right  chipBeta2">Archived</span>






                </h2>

                <h2 class="summary-header text-left rm-m" *ngIf="
                    !(
                      selectedCard.Card_TableName === 'Prospect' ||
                      selectedCard.Card_TableName === 'CustomersAndProspects' ||
                      selectedCard.Card_TableName === 'Customer'
                    )
                  ">
                  <strong *ngIf="selectedCard['Salutation']"> {{ selectedCard['Salutation'] }}</strong>
                  <strong> {{ selectedCard['CalculatedName'] }}</strong>
                </h2>

                <div class="full-width flex">
                  <p class="full-width small text-left rm-m" *ngIf="selectedCard['mobiles.Number']">
                    <mat-icon class="fas fa-mobile-alt smr"></mat-icon>
                    {{ selectedCard['mobiles.Number'] | customPhone }}
                  </p>

                  <p class="full-width small text-left rm-m" *ngIf="selectedCard['emails.Email']">
                    <mat-icon class="fas fa-envelope smr"></mat-icon>
                    {{ selectedCard['emails.Email'] }}
                  </p>
                </div>
                <div class="full-width flex" *ngIf="selectedCard['addresses.Calculated']">
                  <p class="full-width small address text-left rm-m" *ngIf="selectedCard['addresses.Calculated']"
                    (click)="displayMap(selectedCard)">
                    <mat-icon class="fas fa-map-marker-alt smr"></mat-icon>
                    {{ selectedCard['addresses.Calculated'] }}
                  </p>

                  <p class="full-width small address text-left rm-m" *ngIf="distanceAway"
                    (click)="displayMap(selectedCard)">
                    <mat-icon class="fas fa-map-marked-alt smr"></mat-icon>
                    {{ distanceAway }} km away
                  </p>
                </div>
                <div class="full-width  "
                  *ngIf="(selectedCard['DateOfBirth'] && selectedCard['DateOfBirth'] !=='0000-00-00') || (selectedCard.miniTags && selectedCard.miniTags.length>0) ">

                  <mat-chip-list class="  prospect2-tags pull-left mr"
                    *ngIf="selectedCard && selectedCard.miniTags &&  selectedCard.miniTags.length>0 ">
                    <mat-chip *ngFor="let tag of selectedCard.miniTags    ">
                      {{tag}}
                    </mat-chip>

                    <mat-chip (click)="openPatientProfile(selectedCard['ID'])"> ...</mat-chip>
                  </mat-chip-list>

                  <p class="pull-left small text-left rm-m"
                    *ngIf="selectedCard['DateOfBirth'] && selectedCard['DateOfBirth'] !== '0000-00-00'"
                    style="line-height: 22px">
                    {{ selectedCard['DateOfBirth'] | customDate }} ({{ selectedCard['DateOfBirth'] | age }})
                  </p>
                </div>

                <p></p>
              </div>
            </div>
          </div>
        </div>


        <div class="card-header full-width primary-gradient-img top-for-min-screen  display-750px clearfix stacked">
          <div class="row clearfix">
            <button class="btn-close btn-clear mat-button white" *ngIf="isModal==true" (click)="closeEvent()">
              <mat-icon>clear</mat-icon>
            </button>

            <div class="full-width flex">
              <app-card-picture class="pull-left side-card-picture-selected-min mr" [hideZoomButton]="false"
                [tableName]="recieverTableName" [cardID]="selectedCard['ID']"></app-card-picture>

              <div class="full-width selected-card-details ml">
                <h2
                  *ngIf="selectedCard.Card_TableName === 'Prospect' || selectedCard.Card_TableName === 'CustomersAndProspects' || selectedCard.Card_TableName === 'Customer' "
                  class="summary-header patientName text-left rm-m"
                  [matTooltip]="'View ' + (keyWord | translate | titlecase) + ' Profile'"
                  (click)="openPatientProfile(selectedCard['ID'])">
                  <strong *ngIf="selectedCard['Salutation']"> {{ selectedCard["Salutation"] }}</strong> <strong>
                    {{ selectedCard["CalculatedName"] }}</strong>

                  <span *ngIf="selectedCard['MarketingConsent.Given'] != '1'" style="font-size: 0.9rem;"
                    class="chip sml mr pull-right  chipBeta2">No marketing consent</span>



                </h2>

                <h2 class="summary-header text-left rm-m" *ngIf="
                    !(
                      selectedCard.Card_TableName === 'Prospect' ||
                      selectedCard.Card_TableName === 'CustomersAndProspects' ||
                      selectedCard.Card_TableName === 'Customer'
                    )
                  ">
                  <strong *ngIf="selectedCard['Salutation']"> {{ selectedCard['Salutation'] }}</strong>
                  <strong> {{ selectedCard['CalculatedName'] }}</strong>
                </h2>

                <div class="full-width flex">
                  <p class="full-width small text-left rm-m" *ngIf="selectedCard['mobiles.Number']">
                    <mat-icon class="fas fa-mobile-alt smr"></mat-icon>
                    {{ selectedCard['mobiles.Number'] | customPhone }}
                  </p>

                  <p class="full-width small text-left rm-m" *ngIf="selectedCard['emails.Email']">
                    <mat-icon class="fas fa-envelope smr"></mat-icon>
                    {{ selectedCard['emails.Email'] }}
                  </p>
                </div>
                <div class="full-width flex" *ngIf="selectedCard['addresses.Calculated']">
                  <p class="full-width small address text-left rm-m" *ngIf="selectedCard['addresses.Calculated']"
                    (click)="displayMap(selectedCard)">
                    <mat-icon class="fas fa-map-marker-alt smr"></mat-icon>
                    {{ selectedCard['addresses.Calculated'] }}
                  </p>

                  <p class="full-width small address text-left rm-m" *ngIf="distanceAway"
                    (click)="displayMap(selectedCard)">
                    <mat-icon class="fas fa-map-marked-alt smr"></mat-icon>
                    {{ distanceAway }} km away
                  </p>
                </div>
                <div class="full-width  "
                  *ngIf="(selectedCard['DateOfBirth'] && selectedCard['DateOfBirth'] !=='0000-00-00') || (selectedCard.miniTags && selectedCard.miniTags.length>0) ">

                  <mat-chip-list class="  prospect2-tags pull-left mr"
                    *ngIf="selectedCard && selectedCard.tags &&  selectedCard.tags.length>0 ">
                    <mat-chip *ngFor="let tag of selectedCard.miniTags    ">
                      {{tag}}
                    </mat-chip>

                    <mat-chip (click)="openPatientProfile(selectedCard['ID'])"> ...</mat-chip>
                  </mat-chip-list>

                  <p class="pull-left small text-left rm-m"
                    *ngIf="selectedCard['DateOfBirth'] && selectedCard['DateOfBirth'] !== '0000-00-00'"
                    style="line-height: 22px">
                    {{ selectedCard['DateOfBirth'] | customDate }} ({{ selectedCard['DateOfBirth'] | age }})
                  </p>
                </div>

                <p></p>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="full-width message-list-banner flex hide-750px"
        *ngIf="messages && messages.length > 1 && sendOnly==false"
        [attr.style]="'background-color:' + buildRBD(primaryColor, 0.5) + ' !important' | safeStyle">
        <mat-form-field class="full-width smr search-input no-line-input ml">
          <mat-icon class="fas fa-search sms_list-search_icon" matPrefix></mat-icon>
          <input [(ngModel)]="messageFilter" matInput name="messageFilter" placeholder="Messages Filter..." />
          <mat-icon class="fas fa-times sms_list-search_icon_clear" *ngIf="messageFilter" (click)="clearMessageFilter()"
            matSuffix>
          </mat-icon>
        </mat-form-field>

        <div class="margin-btm clearfix personal-msg-checkbox mr"
          *ngIf="messageType!=='merchantPersonal' &&  messageType!=='merchantpersonal' &&  messageType!=='promoterpersonal' &&  messageType!=='promoterPersonal'">
          <mat-checkbox [(ngModel)]="personalMessageOnly" (ngModelChange)="personalMessageChange()" color="accent"
            name="personalMessageOnly">
            <strong class="primary-color personal-msg"> Personal messages only</strong></mat-checkbox>
        </div>

        <button class="btn statistics-message-button mr" *ngIf="totalMessage && totalMessage > 0"
          (click)="statisticsMessage()" mat-raised-button color="accent">
          <strong class="primary-color msg-stat">
            {{ messages | FilterArrayMultipleOrContain: 'search':messageFilter | lengthOfArray }}/{{
            totalMessage
            }}</strong>
          <mat-icon class="fas fa-comments primary-color sml mr"></mat-icon>
        </button>

        <button class="smt navigation" (click)="scrollMessage('bottom', true)" mat-icon-button>
          <mat-icon class="fas fa-chevron-down primary-color"></mat-icon>
        </button>

        <button class="smt navigation" (click)="scrollMessage('top', true)" mat-icon-button>
          <mat-icon class="fas fa-chevron-up primary-color"></mat-icon>
        </button>
      </div>
      <div class="full-width "
        [ngClass]="(selectedCard && selectedCard.ID && selectedCard['Status'] == 'Archived')?'archived-customer':''">
        <div class="full-width flex message-list-container-parent" *ngIf="sendOnly==false"
          [ngClass]="isModal != true ? 'integrated' : ''">
          <div class="full-width message-list-container" id="message-list-container"
            [ngClass]="!(messages && messages.length > 1) ? 'mini' : ''">
            <div class="full-width user-list-load-more mt text-center" *ngIf="
              loadMoreMessage == true &&
              (messages | FilterArrayMultipleOrContain: 'search':messageFilter | lengthOfArray) > 0
            ">
              <button class="btn" (click)="getMessageList(true)" mat-raised-button color="primary">Load
                more...</button>
            </div>

            <div class="full-width empty" *ngIf="
              (messages | FilterArrayMultipleOrContain: 'search':messageFilter | lengthOfArray) <= 0 &&
              messageLoaded == true
            ">
              <app-empty-list-message [message]="'No Message Found'" [actionIcon]="null" [isAction]="loadMoreMessage"
                [actionLabel]="'Load More Messages...'" (actionEvent)="getMessageList(true)">
              </app-empty-list-message>
            </div>

            <div class="full-width message-container"
              *ngFor="let messageDate of messageDates | OrderArrayObject: ['dateSentSeconds']">
              <div class="full-width date-divider mb" *ngIf="!messageFilter">
                <p class="text-center small" *ngIf="messageDate['createDate']">
                  <span *ngIf="messageDate['createDate'] != todayTitle && messageDate['createDate'] != yesterdayTitle">{{
                  messageDate['createDate'] | customDate: 'dddd, MMMM DD YYYY'
                  }}</span>
                  <span *ngIf="messageDate['createDate'] == todayTitle">Today</span>
                  <span *ngIf="messageDate['createDate'] == yesterdayTitle">Yesterday</span>
                </p>
                <hr />
              </div>

              <div class="full-width date-divider mb" *ngIf="
                messageFilter &&
                (messages
                  | FilterArrayMultipleOrContain: 'search':messageFilter
                  | FilterArrayMultipleValue: ['createDate']:messageDate['createDate']
                  | lengthOfArray) > 0
              ">
                <p class="text-center small" *ngIf="messageDate['createDate']">
                  <span *ngIf="messageDate['createDate'] != todayTitle && messageDate['createDate'] != yesterdayTitle">{{
                  messageDate['createDate'] | customDate: 'dddd, MMMM DD YYYY'
                  }}</span>
                  <span *ngIf="messageDate['createDate'] == todayTitle">Today</span>
                  <span *ngIf="messageDate['createDate'] == yesterdayTitle">Yesterday</span>
                </p>
                <hr />
              </div>

              <div class="full-width flex " style="margin-top: 5px !important;" *ngFor="
                let message of messages
                  | FilterArrayMultipleOrContain: 'search':messageFilter
                  | FilterArrayMultipleValue: ['createDate']:messageDate['createDate']
                  | OrderArrayObject: ['createdDateSeconds'];
                let j = index
              ">
                <!--
              <app-card-picture class=" side-card-picture-message  " [hideZoomButton]="false"
                [ngClass]="(message.Direction==='In')?'incoming':''" *ngIf=" message.Direction=='In' "
                [tableName]="selectedCard.Card_TableName" [LastModified]="selectedCard['LastModified']"
                [cardID]="message['Card_key']"></app-card-picture> -->

                <div class="not-shown-container" [ngClass]="
                  !(
                    selectedCard.Card_key &&
                    displaySystemMessage &&
                    message &&
                    message.ID &&
                    displaySystemMessage[selectedCard.Card_key] &&
                    displaySystemMessage[selectedCard.Card_key][message.ID] !== false
                  )
                    ? 'not-shown'
                    : ''
                ">
                  <div class="full-width message-details-main-container"
                    [ngClass]="message.Direction === 'In' ? 'incoming' : ''">
                    <p class="message-details small smb ml mr">

                      <button class="ml sms-preview-button" *ngIf="message.Direction === 'In'" [matMenuTriggerFor]="j"
                        mat-icon-button>
                        <mat-icon>more_vert</mat-icon>
                      </button>




                      <span class="smr" *ngIf="message['Sender_Card_Calculated_Name'] && message.Direction !== 'In'"
                        style="font-weight: 600"
                        matTooltip="Sent to:  {{ message['Destination'] | customPhone }}">{{ message['Sender_Card_Calculated_Name'] }}</span>
                      <span class="smr" *ngIf="
                        message['Internal_Message_Sender_Name'] &&
                        message.Direction !== 'In' &&
                        !message['Sender_Card_Calculated_Name']
                      " style="font-weight: 600"
                        matTooltip="Sent to:  {{ message['Destination'] | customPhone }}">{{ message['Internal_Message_Sender_Name'] }}</span>
                      <span class="smr" *ngIf="
                        message['Merchant_Name'] &&
                        message.Direction !== 'In' &&
                        !message['Sender_Card_Calculated_Name'] &&
                        !message['Internal_Message_Sender_Name']
                      " style="font-weight: 600" matTooltip=" Sent to: {{ message['Destination'] | customPhone }}">
                        {{ message['Merchant_Name'] }}
                      </span>
                      <!-- <span class="smr" *ngIf="message['Card_Name'] && message.Direction === 'In'"
                      style="font-weight: 600"
                      matTooltip="Sent to:  {{ message['Destination'] | customPhone }}">{{ message['Card_Name'] }}
                    </span> -->

                      <span class="date-message-inner"
                        matTooltip=" {{ message['DateTimeCreated'] | customDate: 'dddd, MMMM DD YYYY - hh:mm A' }}">
                        {{ message['DateTimeCreated'] | customDate: 'hh:mm A' }}</span>

                      <button class="ml sms-preview-button" *ngIf="message.Direction !== 'In'" [matMenuTriggerFor]="j"
                        mat-icon-button>
                        <mat-icon>more_vert</mat-icon>
                      </button>
                      <mat-menu #j="matMenu">
                        <button (click)="previewSMS(message['Card_key'], recieverTableName, message['Body'], null)"
                          mat-menu-item>
                          <mat-icon class="fas fa-mobile-alt smr"></mat-icon>
                          <span>Preview</span>
                        </button>

                        <hr *ngIf="message && message.Direction == 'In' && unreadOneMessage[message.ID] != true" />
                        <button *ngIf="message && message.Direction == 'In' && unreadOneMessage[message.ID] != true"
                          (click)="userUnreadMessage(message['Card_key'], message, false)" mat-menu-item>
                          <mat-icon class="fas fa-comment-slash smr"></mat-icon>
                          <span>Mark as Unread</span>
                        </button>

                        <hr *ngIf="message && message.Direction == 'In' && unreadOneMessage[message.ID] == true" />
                        <button *ngIf="message && message.Direction == 'In' && unreadOneMessage[message.ID] == true"
                          (click)="userUnreadMessage(message['Card_key'], message, true)" mat-menu-item>
                          <mat-icon class="fas fa-check-circle smr"></mat-icon>
                          <span>Mark as Read</span>
                        </button>

                        <hr *ngIf="message && message.Direction != 'In'" />
                        <button *ngIf="message && message.Direction != 'In'" (click)="resendSMS(message)" mat-menu-item>
                          <mat-icon class="fas fa-sms smr"></mat-icon>
                          <span>Resend</span>
                        </button>


                        <hr *ngIf="message && message.Message_Group_Key" />
                        <button *ngIf="message && message.Message_Group_Key"
                          (click)="displayMessageGroup(message.Message_Group_Key)" mat-menu-item>
                          <mat-icon class="fas fa-comments smr"></mat-icon>
                          <span> Bulk SMS Report</span>
                        </button>

                      </mat-menu>
                    </p>

                    <button class="btn-small readMoreBtn2 show" *ngIf="
                      personalMessageOnly == true &&
                      !(
                        selectedCard.Card_key &&
                        displaySystemMessage &&
                        message &&
                        message.ID &&
                        displaySystemMessage[selectedCard.Card_key] &&
                        displaySystemMessage[selectedCard.Card_key][message.ID] !== false
                      )
                    " [ngClass]="message.Direction === 'In' ? 'pull-left' : 'pull-right'"
                      (click)="showMessage(selectedCard.Card_key, message.ID, true)" mat-button>
                      Show
                      <mat-icon class="fas fa-sort-down icon1"></mat-icon>
                    </button>

                    <div class="full-width" *ngIf="
                      selectedCard.Card_key &&
                      displaySystemMessage &&
                      message &&
                      message.ID &&
                      displaySystemMessage[selectedCard.Card_key] &&
                      displaySystemMessage[selectedCard.Card_key][message.ID] !== false
                    ">
                      <div class="message-body flex ml mr">
                        <mat-icon class="refresh-text fas fa-redo" *ngIf="
                          message.hideRefresh != true &&
                          (message['Status.Code'] === '' ||
                            message['Status.Code'] === 'QUED' ||
                            message['Status.Code'] === 'SCHE' ||
                            message['Status.Code'] === 'UNKN' ||
                            message['Status.Code'] === 'DLYD' ||
                            message['Status.Code'] === 'PEND')
                        " [attr.style]="
                          'color:' + getStatusColor(message['Status.Code']).color + ' !important' | safeStyle
                        " (click)="refreshOneMessage(message, false)">
                        </mat-icon>

                        <!-- <mat-icon class="fas fa-clock error-icon"
                        *ngIf="getStatusColor(message['Status.Code']).isDelayed == true" [attr.style]="
                          'color:' + getStatusColor(message['Status.Code']).color + ' !important' | safeStyle
                        "></mat-icon> -->

                        <mat-icon class="fas fa-exclamation-triangle error-icon"
                          *ngIf="getStatusColor(message['Status.Code']).isWarning == true" [attr.style]="
                          'color:' + getStatusColor(message['Status.Code']).color + ' !important' | safeStyle
                        "></mat-icon>

                        <mat-icon class="fas fa-exclamation-circle error-icon"
                          *ngIf="getStatusColor(message['Status.Code']).isError == true" [attr.style]="
                          'color:' + getStatusColor(message['Status.Code']).color + ' !important' | safeStyle
                        "></mat-icon>

                        <div class="full-width">
                          <div class="full-width message-body-main" [ngClass]="
                            message && message.Direction == 'In' && unreadOneMessage[message.ID] == true
                              ? 'bold-message-body'
                              : ''
                          " [attr.style]="
                            'border:1px solid ' +
                              getStatusColor(message['Status.Code']).color +
                              ' !important;background:' +
                              getStatusColor(message['Status.Code']).background +
                              ' !important;' | safeStyle
                          " [innerHTML]="message['BodyHTML'] || '...' | safeHtml"></div>
                        </div>
                      </div>
                    </div>
                    <p class="message-details status full-width small" *ngIf="
                      selectedCard.Card_key &&
                      displaySystemMessage &&
                      message &&
                      message.ID &&
                      displaySystemMessage[selectedCard.Card_key] &&
                      displaySystemMessage[selectedCard.Card_key][message.ID] !== false
                    ">
                      <button class="btn-small readMoreBtn2" *ngIf="
                        personalMessageOnly == true &&
                        selectedCard &&
                        selectedCard.Card_key &&
                        message &&
                        message.ID &&
                        message.Direction !== 'In'
                      " (click)="showMessage(selectedCard.Card_key, message.ID, false)" mat-button>
                        Hide
                        <mat-icon class="fas fa-sort-up icon2"></mat-icon>
                      </button>

                      <span class="mr"
                        [attr.style]="'color:' + getStatusColor(message['Status.Code']).color + ' !important' | safeStyle">

                        <span class="chip2 chip2-enable "
                          *ngIf="  message && message.Direction !== 'In' && (message.TemplateTag==='Merchant-SMS-Template-Bulk' ||  message.TemplateTag==='merchant-SMS-Template-Bulk' )">
                          Bulk SMS
                        </span>
                        <span class="chip2 chip2-enable-p "
                          *ngIf="  message && message.Direction !== 'In' && (message.TemplateTag==='Promoter-SMS-Template-Bulk'|| message.TemplateTag==='promoter-SMS-Template-Bulk' )">
                          Promoter Bulk SMS
                        </span>


                        <mat-icon *ngIf="message.Direction !== 'In' && isPromoterSMS(message)" appCustomTooltip
                          [customReplacement]="'bottom'" [customToolTipClass]="'additional-tooltip-SMS'"
                          customToolTipContent="<strong>&#8226; Message sent by Promoter</strong>   {{getPhoneNumberType(message)}}  {{getTemplate(message)}} "
                          class="fas fa-user-shield   sms-source-info  smr"
                          [attr.style]="'color:' + getStatusColor(message['Status.Code']).color + ' !important' | safeStyle">
                        </mat-icon>

                        <mat-icon *ngIf="message.Direction !== 'In' && isMerchantSMS(message)"
                          [customToolTipClass]="'additional-tooltip-SMS'"
                          class="{{'fas fa-tooth' | translate}}   sms-source-info smr" appCustomTooltip
                          [customReplacement]="'bottom'"
                          customToolTipContent="<strong>&#8226; Message sent by {{'Merchant' | translate }} </strong>   {{getPhoneNumberType(message)}}  {{getTemplate(message)}} "
                          [attr.style]="'color:' + getStatusColor(message['Status.Code']).color + ' !important' | safeStyle">
                        </mat-icon>

                        <mat-icon *ngIf="message.Direction !== 'In' && isCampaignSMS(message)"
                          [customToolTipClass]="'additional-tooltip-SMS'" class="fas fa-bullhorn sms-source-info smr"
                          appCustomTooltip [customReplacement]="'bottom'"
                          customToolTipContent="<strong>&#8226; Campaign  message</strong>  {{getPhoneNumberType(message)}}  {{getTemplate(message)}} "
                          [attr.style]="'color:' + getStatusColor(message['Status.Code']).color + ' !important' | safeStyle">
                        </mat-icon>

                        <mat-icon
                          *ngIf="message.Direction !== 'In' && !isMerchantSMS(message) && !isPromoterSMS(message) &&  !isCampaignSMS(message)"
                          [customToolTipClass]="'additional-tooltip-SMS'" class="fas fa-robot   sms-source-info smr"
                          appCustomTooltip [customReplacement]="'bottom'"
                          customToolTipContent="<strong>&#8226; System generated message</strong> {{getPhoneNumberType(message)}} {{getTemplate(message)}}"
                          [attr.style]="'color:' + getStatusColor(message['Status.Code']).color + ' !important' | safeStyle">
                        </mat-icon>




                        {{ message['Status.Label'] || 'No Status' }}




                        <mat-icon *ngIf="message.Direction == 'In' && isPromoterSMS(message)"
                          class="fas fa-user-shield sms-source-info sml" appCustomTooltip [customReplacement]="'bottom'"
                          customToolTipContent="<strong>&#8226; Message sent to  Promoter</strong>  {{getPhoneNumberType(message)}} {{getPhoneNature(message) | translate}}  "
                          [attr.style]="'color:' + getStatusColor(message['Status.Code']).color + ' !important' | safeStyle">
                        </mat-icon>

                        <mat-icon *ngIf="message.Direction == 'In' && isMerchantSMS(message)"
                          class="{{'fas fa-tooth' | translate}}  sms-source-info  sml" appCustomTooltip
                          [customReplacement]="'bottom'"
                          customToolTipContent="<strong>&#8226; Message sent to {{'Merchant' | translate }}</strong>  {{getPhoneNumberType(message)}} {{getPhoneNature(message) | translate}}  "
                          [attr.style]="'color:' + getStatusColor(message['Status.Code']).color + ' !important' | safeStyle">
                        </mat-icon>



                        <mat-icon *ngIf="message.Direction == 'In' && isCampaignSMS(message)"
                          class="fas fa-bullhorn sms-source-info sml" appCustomTooltip [customReplacement]="'bottom'"
                          customToolTipContent="<strong>&#8226; Campaign  message</strong>  {{getPhoneNumberType(message)}} {{getPhoneNature(message) | translate}}  "
                          [attr.style]="'color:' + getStatusColor(message['Status.Code']).color + ' !important' | safeStyle">
                        </mat-icon>



                        <mat-icon
                          *ngIf="message.Direction == 'In' && !isMerchantSMS(message) && !isPromoterSMS(message) &&  !isCampaignSMS(message)"
                          class="fas fa-robot   sms-source-info sml" appCustomTooltip [customReplacement]="'bottom'"
                          customToolTipContent="<strong>&#8226; System generated message</strong>  {{getPhoneNumberType(message)}} {{getPhoneNature(message) | translate}}  "
                          [attr.style]="'color:' + getStatusColor(message['Status.Code']).color + ' !important' | safeStyle">
                        </mat-icon>



                      </span>

                      <button class="btn-small readMoreBtn2" *ngIf="
                        personalMessageOnly == true &&
                        selectedCard &&
                        selectedCard.Card_key &&
                        message &&
                        message.ID &&
                        message.Direction === 'In'
                      " (click)="showMessage(selectedCard.Card_key, message.ID, false)" mat-button>
                        Hide
                        <mat-icon class="fas fa-sort-up icon2"></mat-icon>
                      </button>
                    </p>
                  </div>

                  <app-card-picture class="side-card-picture-message"
                    *ngIf="message['Sender_Card_key'] && message.Direction !== 'In'" [hideZoomButton]="false"
                    [ngClass]="message.Direction === 'In' ? 'incoming' : ''" [tableName]="'Contact'"
                    [cardID]="message['Sender_Card_key']">
                  </app-card-picture>

                  <app-card-picture class="side-card-picture-message" *ngIf="
                    message['Internal_Message_Sender_key'] && message.Direction !== 'In' && !message['Sender_Card_key']
                  " [hideZoomButton]="false" [ngClass]="message.Direction === 'In' ? 'incoming' : ''"
                    [tableName]="message['Internal_Message_Sender_TableName']"
                    [cardID]="message['Internal_Message_Sender_key']">
                  </app-card-picture>

                  <app-card-picture class="side-card-picture-message" *ngIf="
                    message['Display_Key'] &&
                    message.Direction !== 'In' &&
                    !message['Sender_Card_key'] &&
                    !message['Internal_Message_Sender_key']
                  " [hideZoomButton]="false" [ngClass]="message.Direction === 'In' ? 'incoming' : ''"
                    [tableName]="'Merchant'" [cardID]="message['Display_Key']">
                  </app-card-picture>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="full-width message-action" *ngIf="selectedCard && selectedCard['Status'] != 'Archived'">


        <div class="send-sms-body full-width flex "
          *ngIf="selectedCard && selectedCard.ID && isDentistsLoaded==true && loadMerchant==true">

          <app-sms-send class="full-width ml" *ngIf="isContactStaff!=true"
            [showMarketingConsentWarning]="selectedCard['MarketingConsent.Given']!=='1'"
            [isContactStaff]="isContactStaff" [marketingConsentTitle]="marketingConsentTitle"
            [marketingConsentDescription]="marketingConsentDescription" [isCustomizedSender]="true" [isModal]="false"
            [message]="defaultMessage" [card]="selectedCard" [displaySaveAsTemplate]="true"
            [tableName]="recieverTableName" [merchant]="merchant" (getResult)="getResultEvent($event)"
            (getMessage)="getMessageEvent($event)" (messageIsLoadingEvent)="messageIsLoadingEvent($event)">
          </app-sms-send>
          <app-sms-send class="full-width ml" *ngIf="isContactStaff==true" [showMarketingConsentWarning]="false"
            [isContactStaff]="isContactStaff" [marketingConsentTitle]="marketingConsentTitle"
            [marketingConsentDescription]="marketingConsentDescription" [isCustomizedSender]="true" [isModal]="false"
            [message]="defaultMessage" [card]="selectedCard" [displaySaveAsTemplate]="true"
            [tableName]="recieverTableName" [merchant]="merchant" (getResult)="getResultEvent($event)"
            (getMessage)="getMessageEvent($event)" (messageIsLoadingEvent)="messageIsLoadingEvent($event)">
          </app-sms-send>

        </div>
      </div>

      <div class="full-width" *ngIf="selectedCard && selectedCard['Status'] == 'Archived'">

        <hr>

        <div class="full-width flex">
          <p class="info-request full-width ml mr">

            <mat-icon class="fas fa-exclamation-circle pull-left mr"></mat-icon>

            <strong> You cannot send an SMS to an archived {{'patient'  | translate}} record. Please choose <strong>Unarchive</strong> to enable SMS
              communication.</strong>
          </p>



          <button class="pull-right  btn  sml" (click)="unarchivePatient()"
            style="  height: 44px;  margin-top: 12px;  margin-right: 0px !important;" mat-raised-button color="accent">
            <mat-icon class="fas fa-trash-restore mr"></mat-icon> <span class="mr">Unarchive</span>
          </button>



        </div>

      </div>
    </div>
  </div>
</mat-dialog-content>
<div *ngIf="globalLoad==true" class="full-width message-footer"></div>
