<mat-card class="stacked-card-view prodView rel supplier-card clearfix mb" *ngIf="treatment && isEdit == false"
          @ngIfAnimation>
  <div class="card-header primary-gradient-img clearfix inModal rel">
    <div class="row clearfix content">
      <button class="btn-close btn-clear mat-button white" *ngIf="isModal == true" (click)="closeModal()">
        <mat-icon>clear</mat-icon>
      </button>
      <div class="row titleArea clearfix text-left">
        <app-product-view-logo class="supplierIcon pull-left" [productID]="treatment['ID']"
                               [LastModified]="treatment['LastModified']">
        </app-product-view-logo>
        <h2 class="summary-header rm-m heading-1" *ngIf="treatment['Label'] != 'N/A'">
          {{ treatmentUtil.getLabelTitle(treatment['Label']) }}
          <span class="subLabel"><ng-container *ngIf="treatment['Item_Code.Effective']">Item {{
            treatmentUtil.getLabel(treatment['Item_Code.Effective']) }}</ng-container>
            {{ treatmentUtil.getLabelSubTitle(treatment['Label']) }}</span>
        </h2>

        <h2 class="summary-header rm-m heading-2"
            *ngIf="treatment['Label'] == 'N/A' && treatment['Brand.Label'] != 'N/A'">
          {{ treatmentUtil.getLabelTitle(treatment['Brand.Label']) }}
          <span class="subLabel"><ng-container *ngIf="treatment['Item_Code.Effective']">Item {{
            treatmentUtil.getLabel(treatment['Item_Code.Effective']) }}</ng-container>
            {{ treatmentUtil.getLabelSubTitle(treatment['Brand.Label']) }}</span>
        </h2>

        <h2 class="summary-header rm-m heading-3"
            *ngIf="treatment['Label'] == 'N/A' && treatment['Brand.Label'] == 'N/A' && treatment['Type.Label'] != 'N/A'">
          {{ treatmentUtil.getLabelTitle(treatment['Type.Label']) }}
          <span class="subLabel"><ng-container *ngIf="treatment['Item_Code.Effective']">Item {{
            treatmentUtil.getLabel(treatment['Item_Code.Effective']) }}</ng-container>
            {{ treatmentUtil.getLabelSubTitle(treatment['Type.Label']) }}</span>
        </h2>

        <h2 class="summary-header rm-m heading-4" *ngIf="
            treatment['Label'] == 'N/A' &&
            treatment['Brand.Label'] == 'N/A' &&
            treatment['Type.Label'] == 'N/A' &&
            treatment['Category.Label'] != 'N/A'
          ">
          {{ treatmentUtil.getLabelTitle(treatment['Category.Label']) }}
          <span class="subLabel"><ng-container *ngIf="treatment['Item_Code.Effective']">Item {{
            treatmentUtil.getLabel(treatment['Item_Code.Effective']) }}</ng-container>
            {{ treatmentUtil.getLabelSubTitle(treatment['Category.Label']) }}</span>
        </h2>

        <!-- active chips -->
      </div>
      <div class="edit action-button">
        <div class="date-chip">
          <p class="small rm-m mr" *ngIf="treatment.DateTimeCreated && treatment.DateTimeCreated != '0000-00-00'">
            <mat-icon class="fas fa-clock"></mat-icon>
            {{ treatment.DateTimeCreated | TimeLabelPast }} ago
          </p>

          <p class="clearfix small rm-m" *ngIf="treatment['Active'] == '1' && isGlobal == true">
            <span class="chip-enable">Active</span>
          </p>
          <p class="clearfix small rm-m" *ngIf="treatment['Active'] == '0' && isGlobal == true">
            <span class="chip-disable">Not active</span>
          </p>

          <p class="clearfix small rm-m" *ngIf="treatment['IsOmitted'] == '1' && isGlobal != true">
            <span class="chip-disable">Not active</span>
          </p>
          <p class="clearfix small rm-m" *ngIf="treatment['IsOmitted'] == '0' && isGlobal != true">
            <span class="chip-enable"> Active</span>
          </p>
        </div>

        <button class="white" (click)="isEditMode()" mat-stroked-button>
          Edit
          <mat-icon>edit</mat-icon>
        </button>
      </div>
    </div>
    <div class="colourOverlay" [ngStyle]="{ background: treatment['Category.Colour'] }"></div>
  </div>

  <mat-dialog-content class="stacked-card-view prodView">
    <div class="product-item-view">
      <mat-accordion class="grey-row">
        <mat-expansion-panel class="text-left" [expanded]="true">
          <mat-expansion-panel-header>
            <h3 class="">
              <i>
                <svg class="pull-left" id="treatLookIcon" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 153.34 136.08">
                  <defs>
                    <style>
                      .cls-1 {
                        fill: none;
                      }
                    </style>
                  </defs>
                  <title>treatment-lookup</title>
                  <circle class="cls-1" cx="259.58" cy="109.88" r="6.38"
                          transform="translate(-210.22 122.86) rotate(-45)"/>
                  <path
                    d="M283.57,186.33a2.43,2.43,0,0,1-.6.1H268.08v14.88a2.12,2.12,0,0,1-2.12,2.12H253.2a2.13,2.13,0,0,1-2.13-2.12V186.43H236.19a2.14,2.14,0,0,1-2.13-2.13V171.54a2.13,2.13,0,0,1,2.13-2.12h14.88V154.53a2.13,2.13,0,0,1,2.13-2.12H258a46.33,46.33,0,0,1,.18-17h-22a2.14,2.14,0,0,1-2.13-2.13V129a2.14,2.14,0,0,1,2.13-2.13h24.56a47,47,0,0,1,15.56-20,17,17,0,0,0-33.74,3H221.31a12.76,12.76,0,0,0-12.76,12.76v93.55A12.76,12.76,0,0,0,221.31,229h76.54a12.77,12.77,0,0,0,12.76-12.76V190.56A48.73,48.73,0,0,1,304,191,46.37,46.37,0,0,1,283.57,186.33Zm-24-82.83a6.38,6.38,0,1,1-6.38,6.38A6.37,6.37,0,0,1,259.58,103.5Z"
                    transform="translate(-208.55 -92.87)"/>
                  <path
                    d="M360.56,189.12l-19-19a4.57,4.57,0,0,0-3.24-1.34h-3.11a39.64,39.64,0,0,0-50.73-58.91,39.85,39.85,0,0,0-16.09,17,39.32,39.32,0,0,0-3,8.51,39.72,39.72,0,0,0-.23,17,39.15,39.15,0,0,0,2.94,8.83,39.83,39.83,0,0,0,17,18,39.66,39.66,0,0,0,43.27-3.61v3.11a4.6,4.6,0,0,0,1.33,3.24l19,19a4.6,4.6,0,0,0,3.24,1.34,4.52,4.52,0,0,0,3.22-1.34l5.4-5.4A4.59,4.59,0,0,0,360.56,189.12Zm-49.95-21.25a24.39,24.39,0,1,1,17.75-23.47A24.26,24.26,0,0,1,310.61,167.87Z"
                    transform="translate(-208.55 -92.87)"/>
                  <path
                    d="M316.39,139.58h-7.74v-7.75a1.11,1.11,0,0,0-1.11-1.1H300.9a1.11,1.11,0,0,0-1.1,1.1v7.75h-7.74a1.11,1.11,0,0,0-1.11,1.1v6.64a1.11,1.11,0,0,0,1.11,1.1h7.74v7.75a1.11,1.11,0,0,0,1.1,1.1h6.64a1.11,1.11,0,0,0,1.11-1.1v-7.75h7.74a1.1,1.1,0,0,0,1.1-1.1v-6.64A1.1,1.1,0,0,0,316.39,139.58Z"
                    transform="translate(-208.55 -92.87)"/>
                </svg>
              </i>

              Product Description
            </h3>
          </mat-expansion-panel-header>

          <div class="row clearfix flex product-detail-tab">
            <div class="row clearfix flex full-width">
              <div class="half-width flex" *ngIf="treatment['ID']">
                <!-- product catalogue image -->
                <app-product-view-catalogue class="supplierIcon pull-left" [productID]="treatment['ID']"
                                            [LastModified]="treatment['LastModified']">
                </app-product-view-catalogue>
              </div>

              <div class="half-width">
                <div class="row clearfix">
                  <!-- <p class="clearfix description" [innerHTML]="treatment['Description'] | safeHtml"> -->
                  <!-- treatment product desctiption text -->

                  <read-more [text]="treatment['Description']" [maxLength]="500"></read-more>
                  <!-- </p> -->
                </div>
              </div>
            </div>
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel class="text-left" *ngIf="
            treatment['DefaultNotes.Content.Treatment.Effective'] ||
            treatment['DefaultNotes.Content.Diagnostic.Effective']
          ">
          <mat-expansion-panel-header>
            <h3 class="">
              <mat-icon class="fas fa-notes-medical"></mat-icon>
              Diagnosis &amp; Treatment
            </h3>
          </mat-expansion-panel-header>

          <div class="row clearfix">
            <div class="half-width" *ngIf="treatment['DefaultNotes.Content.Diagnostic.Effective']">
              <div class="col-left">
                <h4 class="rm-m">Diagnosis notes</h4>
                <p [innerHTML]="treatment['DefaultNotes.Content.Diagnostic.Effective'] | safeHtml"></p>
              </div>
            </div>
            <div class="half-width" *ngIf="treatment['DefaultNotes.Content.Treatment.Effective']">
              <div class="col-right">
                <h4 class="rm-m"> {{"KEYWORD.Treatment" | translate}} notes</h4>
                <p [innerHTML]="treatment['DefaultNotes.Content.Treatment.Effective'] | safeHtml"></p>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <h3 class="">
              <mat-icon class="fas fa-calculator"></mat-icon>
              Information &amp; Price
            </h3>
          </mat-expansion-panel-header>

          <div class="row clearfix flex wrapped text-left">
            <div class="details block full-width" *ngIf="treatment['Item_Code.Effective']">
              <mat-icon class="fas fa-qrcode mr"></mat-icon>
              <div class="row-content">
                <p class="rm-mb">Item Code</p>
                <h4 class="reduce-marg">
                  {{ treatmentUtil.getLabel(treatment['Item_Code.Effective']) }}
                </h4>
              </div>
            </div>

            <div class="details block full-width clicktext" *ngIf="treatment['Category.Label']">
              <mat-icon class="fas fa-th mr"></mat-icon>
              <div class="row-content">
                <p class="rm-mb">Service Category</p>
                <h4 class="reduce-marg" [ngStyle]="{ color: treatment['Category.Colour'] }"
                    (click)="viewCategory(treatment['Category_key'])">
                  {{ treatmentUtil.getLabel(treatment['Category.Label']) }}
                </h4>
              </div>
            </div>
          </div>
          <mat-divider></mat-divider>
          <div class="row clearfix flex wrapped text-left">
            <div class="details block full-width clicktext" *ngIf="treatment['Type.Label']"
                 matTooltip="Click to view Type details">
              <mat-icon class="fas fa-th-large mr"></mat-icon>
              <div class="row-content">
                <p class="rm-mb">Type</p>
                <h4 class="reduce-marg" [ngStyle]="{ color: treatment['Category.Colour'] }"
                    (click)="viewType(treatment['Type_key'])">
                  {{ treatmentUtil.getLabel(treatment['Type.Label']) }}
                </h4>
              </div>
            </div>

            <div class="" *ngIf="treatment['Brand.Label']">
              <div class="details block full-width clicktext" *ngIf="treatment['Brand_key']"
                   matTooltip="Click to view Brand details">
                <mat-icon class="fas fa-notes-medical mr"></mat-icon>
                <div class="row-content">
                  <p class="rm-mb">Brand</p>
                  <h4 class="reduce-marg" [ngStyle]="{ color: treatment['Category.Colour'] }"
                      (click)="viewBrand(treatment['Brand_key'])">
                    {{ treatmentUtil.getLabel(treatment['Brand.Label']) }}
                  </h4>
                </div>
              </div>

              <div class="details block full-width" *ngIf="!treatment['Brand_key']"
                   matTooltip="Click to view Brand details">
                <mat-icon class="fas fa-notes-medical mr"></mat-icon>
                <div class="row-content">
                  <p class="rm-mb">Brand</p>
                  <h4 class="reduce-marg" [ngStyle]="{ color: treatment['Category.Colour'] }">
                    {{ treatmentUtil.getLabel(treatment['Brand.Label']) }}
                  </h4>
                </div>
              </div>
            </div>
          </div>

          <hr/>

          <div class="row clearfix flex text-left"
               *ngIf="treatment['Manufacturer_Key.Effective'] || treatment['Distributor_Key.Effective']">
            <div class="half-width">
              <div class="row clearfix details flex" *ngIf="treatment['Manufacturer_Key.Effective']">
                <mat-icon class="fas fa-industry mr"></mat-icon>
                <div class="row-content clicktext" matTooltip="Click to view Manufacturer details">
                  <p class="rm-m">Manufactured by</p>
                  <h4 class="rm-m" (click)="viewSupplier(treatment['Manufacturer_Key.Effective'])">
                    {{ treatment['Manufacturer.Name.Effective'] }}
                  </h4>
                </div>
              </div>
            </div>
            <div class="half-width">
              <div class="row clearfix details flex" *ngIf="treatment['Distributor_Key.Effective']">
                <mat-icon class="fas fa-truck mr"></mat-icon>
                <div class="row-content clicktext" matTooltip="Click to view Distributor details">
                  <p class="rm-m">Distributed by</p>
                  <h4 class="rm-m" (click)="viewSupplier(treatment['Distributor_Key.Effective'])">
                    {{ treatment['Distributor.Name.Effective'] }}
                  </h4>
                </div>
              </div>
            </div>
          </div>

          <hr/>

          <div class="row clearfix text-left rel">
            <div class="row clearfix">
              <div class="icons-col">
                <mat-icon class="fas fa-donate"></mat-icon>
              </div>
              <div class="row-content-col row clearfix">
                <div class="row clearfix">
                  <!-- Set practice cost price -->
                  <p class="rm-mb">Purchase Price</p>
                  <h4 class="sr-title rm-m bold">{{ costPrice | customCurrency }}</h4>
                </div>
              </div>
            </div>
            <div class="rel mt">
              <mat-icon class="iocon fas fa-plus"></mat-icon>
              <mat-divider></mat-divider>
            </div>
            <div class="row clearfix">
              <div class="icons-col">
                <mat-icon class="fas fa-store"></mat-icon>
              </div>
              <div class="row-content-col row clearfix">
                <div class="row clearfix flex">
                  <!-- set overhead percentage - if modified this shows a modal to confirm it will impact other product prices -->
                  <div class="block full-width">
                    <p class="rm-mb">{{"KEYWORD.Practice" | translate}} overhead ($)</p>
                    <h4 class="accent-color rm-m bold">
                      {{ costOverheadPrice | customCurrency }}
                    </h4>
                  </div>
                  <div class="block full-width">
                    <p class="rm-mb">{{"KEYWORD.Practice" | translate}} overhead (%)</p>
                    <h4 class="accent-color rm-m bold" *ngIf="sellPrice > 0">
                      {{ toNumber(costOverheadPrice) / toNumber(sellPrice) | percent: '2.1-1' }}
                    </h4>
                    <h4 class="accent-color rm-m bold" *ngIf="sellPrice <= 0">
                      {{ 0 | percent: '2.1-1' }}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <div class="rel mt">
              <mat-icon class="iocon fas fa-equals"></mat-icon>
              <mat-divider></mat-divider>
            </div>
            <div class="row clearfix">
              <div class="icons-col">
                <mat-icon class="fas fa-wallet"></mat-icon>
              </div>
              <div class="row-content-col row clearfix">
                <!-- display calculated total cost -->
                <p class="rm-mb">Total cost</p>
                <h4 class="accent-color rm-mt bold">{{ costTotalPrice | customCurrency }}</h4>
              </div>
            </div>
            <mat-divider></mat-divider>
            <div class="row clearfix">
              <div class="icons-col">
                <mat-icon class="fas fa-coins"></mat-icon>
              </div>
              <div class="row-content-col row clearfix">
                <div class="row clearfix flex">
                  <div class="block full-width">
                    <!-- display calculated net profit -->
                    <p class="rm-mb">Sell Price ($)</p>
                    <h4 class="green-text rm-m bold">{{ sellPrice | customCurrency }}</h4>
                  </div>

                  <!-- set overhead percentage - if modified this shows a modal to confirm it will impact other product prices -->
                  <div class="block full-width">
                    <!-- display calculated net profit -->
                    <p class="rm-mb">Gross profit ($)</p>
                    <h4 class="green-text rm-m bold">{{ profitPrice | customCurrency }}</h4>
                  </div>
                  <div class="block full-width">
                    <!-- display calculated net profit -->
                    <p class="rm-mb">Gross profit (%)</p>
                    <h4 class="green-text rm-m bold" *ngIf="sellPrice > 0">
                      {{ toNumber(profitPrice) / toNumber(sellPrice) | percent: '2.1-1' }}
                    </h4>
                    <h4 class="green-text rm-m bold" *ngIf="sellPrice <= 0">
                      {{ 0 | percent: '2.1-1' }}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel class="text-left" *ngIf="
            treatment['DefaultNotes.Category'] ||
            treatment['DefaultNotes.Label'] ||
            treatment['DefaultNotes.Category.Effective'] ||
            (treatment['DefaultNotes.Label.Effective'] && isGlobal != true)
          ">
          <mat-expansion-panel-header>
            <h3 class="sr-title">
              <mat-icon class="fas fa-clipboard"></mat-icon>
              Additional notes
            </h3>
          </mat-expansion-panel-header>

          <div class="row clearfix flex">
            <!--  i dont know what these are -->

            <div class="row clearfix full-width" *ngIf="treatment['DefaultNotes.Category'] && isGlobal == true">
              <p class="small rm-m">Default Category Note</p>
              <h4 class="reduce-marg">{{ treatment['DefaultNotes.Category'] }}</h4>
            </div>

            <div class="row clearfix full-width" *ngIf="treatment['DefaultNotes.Label'] && isGlobal == true">
              <p class="small rm-m">Default Label Note</p>
              <h4 class="reduce-marg">{{ treatment['DefaultNotes.Label'] }}</h4>
            </div>

            <div class="row clearfix full-width"
                 *ngIf="treatment['DefaultNotes.Category.Effective'] && isGlobal != true">
              <p class="small rm-m">Category Note</p>
              <h4 class="reduce-marg">{{ treatment['DefaultNotes.Category.Effective'] }}</h4>
            </div>

            <div class="row clearfix full-width" *ngIf="treatment['DefaultNotes.Label.Effective'] && isGlobal != true">
              <p class="small rm-m">Label Note</p>
              <h4 class="reduce-marg">{{ treatment['DefaultNotes.Label.Effective'] }}</h4>
            </div>
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel class="text-left documentPanel">
          <mat-expansion-panel-header>
            <h3 class="">
              <mat-icon class="fas fa-folder-open"></mat-icon> Documents
              <span class="num">{{
                supplierDocuments.length +
                preDocuments.length +
                stockArtDocuments.length +
                postDocuments.length +
                marketingDocuments.length
                }}</span>
            </h3>
          </mat-expansion-panel-header>

          <div class="row clearfix">
            <mat-button-toggle-group class="btn-group text-center full-width with-icons" #group="matButtonToggleGroup"
                                     [(ngModel)]="groupFilter">
              <mat-button-toggle class="text-center full-width" [value]="'patient'">
                <mat-icon class="fas fa-user"></mat-icon>
                {{ "KEYWORD.patient" | translate | titlecase }} Documents
                <span class="num">{{
                  preDocuments.length + stockArtDocuments.length + postDocuments.length + marketingDocuments.length
                  }}</span>
              </mat-button-toggle>
              <mat-button-toggle class="text-center full-width" [value]="'dentist'">
                <mat-icon class="fas {{'fa-tooth' | translate}}"></mat-icon>
                {{ "KEYWORD.practitioner" | translate | titlecase }}
                Documents
                <span class="num">{{ supplierDocuments.length }}</span>
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>

          <div class="row clearfix" *ngIf="
              (stockArtDocuments && stockArtDocuments.length > 0) ||
              (supplierDocuments && supplierDocuments.length > 0) ||
              (preDocuments && preDocuments.length > 0) ||
              (marketingDocuments && marketingDocuments.length > 0) ||
              (postDocuments && postDocuments.length > 0)
            ">
            <div class="clearfix full-width"
                 *ngIf="supplierDocuments && supplierDocuments.length > 0 && groupFilter == 'dentist'">
              <app-ep-document-list [isDownload]="isDownload" [isRedirection]="isRedirection" [isPrint]="isPrint"
                [documents]="supplierDocuments"
                                    [title]="('KEYWORD.practitioner' | translate | titlecase) + ' documents'"
                                    [canCheck]="false"
                                    [defaultCheck]="false" [isButtonFilter]="true">
              </app-ep-document-list>
            </div>

            <div class="clearfix full-width"
                 *ngIf="preDocuments && preDocuments.length > 0 && groupFilter == 'patient'">
              <app-ep-document-list [isDownload]="isDownload" [isRedirection]="isRedirection" [isPrint]="isPrint"
                                    [documents]="preDocuments" [title]="'Pre documents'" [canCheck]="false"
                                    [defaultCheck]="false">
              </app-ep-document-list>
            </div>

            <div class="clearfix full-width"
                 *ngIf="postDocuments && postDocuments.length > 0 && groupFilter == 'patient'">
              <app-ep-document-list [isDownload]="isDownload" [isRedirection]="isRedirection" [isPrint]="isPrint"
                                    [documents]="postDocuments" [title]="'Post documents'" [canCheck]="false"
                                    [defaultCheck]="false">
              </app-ep-document-list>
            </div>

            <div class="clearfix full-width"
                 *ngIf="marketingDocuments && marketingDocuments.length > 0 && groupFilter == 'patient'">
              <app-ep-document-list [isDownload]="isDownload" [isRedirection]="isRedirection" [isPrint]="isPrint"
                                    [documents]="marketingDocuments" [title]="'Marketing documents'" [canCheck]="false"
                                    [defaultCheck]="false">
              </app-ep-document-list>
            </div>

            <div class="clearfix full-width"
                 *ngIf="stockArtDocuments && stockArtDocuments.length > 0 && groupFilter == 'patient'">
              <app-ep-document-list [isDownload]="isDownload" [isRedirection]="isRedirection" [isPrint]="isPrint"
                                    [documents]="stockArtDocuments"
                                    [title]="('KEYWORD.procedure' | translate | titlecase) +' Gallery'"
                                    [canCheck]="false" [defaultCheck]="false">
              </app-ep-document-list>
            </div>
          </div>

          <div class="row clearfix" *ngIf="
              (groupFilter == 'dentist' && supplierDocuments.length == 0) ||
              (groupFilter == 'patient' &&
                stockArtDocuments.length == 0 &&
                preDocuments.length == 0 &&
                postDocuments.length == 0 &&
                marketingDocuments.length == 0)
            ">
            <app-empty-list-message [title]="'No documents found'" [message]="'There are no documents in the database'">
            </app-empty-list-message>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </mat-dialog-content>
</mat-card>

<app-treatment-product-edit-merchant *ngIf="treatment && isEdit == true" [productID]="productID"
                                     [merchantID]="merchantID"
                                     (close)="closeModal()"></app-treatment-product-edit-merchant>
