import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-star-rating-create',
  templateUrl: './star-rating-create.component.html',
  styleUrls: ['./star-rating-create.component.css'],
})
export class StarRatingCreateComponent implements OnInit {
  starList: boolean[] = [false, false, false, false, false];

  @Input()
  rating;

  @Input()
  label = 'Your rating';

  @Output()
  setRating = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  setStar(data: any) {
    this.rating = data + 1;
    for (let i = 0; i <= 4; i++) {
      if (i <= data) {
        this.starList[i] = false;
      } else {
        this.starList[i] = true;
      }
    }
    this.setRating.emit(this.rating);
  }
}
