<div class="row clearfix">
  <div class="card-header primary-gradient-img clearfix inModal" *ngIf="isModal == true">
    <div class="row clearfix">
      <button class="btn-close btn-clear pull-right mat-button" (click)="closeModalEvent()">
        <mat-icon>clear</mat-icon>
      </button>

      <div class="row titleArea clearfix">
        <mat-icon class="pull-left fas fa-comment-dollar"></mat-icon>
        <h2 class="summary-header rm-m">Create House of Residence</h2>
      </div>
    </div>
  </div>

  <form #PCIForm="ngForm">
    <mat-dialog-content [ngStyle]="{ overflow: isModal == false ? 'unset' : 'scroll' }">
      <div class="row clearfix full-width cont" *ngIf="disabled == false">
        <div class="row clearfix flex">
          <div class="clearfix full-width">
            <div class="col-left">
              <h4 class="rm-mb">Operator modification</h4>
              <hr/>
              <div class="row clearfix full-width">
                <mat-form-field
                  class="full-width"
                  [ngClass]="highlightField('Updating_Reason.Code')"
                  appearance="outline"
                >
                  <mat-label>Reason</mat-label>
                  <mat-select [(ngModel)]="pcDetail.updatedReason" placeholder="Reason" name="reason" required>
                    <mat-option *ngFor="let reason of persCircumUpdReasons" [value]="reason.Code">
                      {{ reason.Label }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>

          <div class="clearfix full-width">
            <div class="col-left">
              <h4 class="rm-mb">Comments</h4>
              <hr/>
              <div class="row clearfix full-width">
                <mat-form-field
                  class="full-width"
                  [ngClass]="highlightField('Updating_Reason.Comment')"
                  appearance="outline"
                >
                  <mat-label>Leave a comment</mat-label>
                  <textarea
                    [(ngModel)]="pcDetail.updatedComment"
                    matInput
                    placeholder="Comment"
                    name="comment"
                  ></textarea>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row clearfix full-width cont">
        <div class="row clearfix flex">
          <div class="clearfix full-width">
            <div class="col-left">
              <h4 class="rm-mb">Property Address</h4>
              <hr/>
              <div class="row clearfix">
                <app-address-input
                  [displayMap]="false"
                  [postCode]="pcDetail.addressStreetPostCode"
                  [state]="pcDetail.addressStreetState"
                  [suburb]="pcDetail.addressStreetSuburb"
                  [streetNumber]="pcDetail.addressStreetNumber"
                  [streetName]="pcDetail.addressStreetName"
                  [unitNumber]="pcDetail.addressUnit"
                  [streetType]="pcDetail.addressStreetType"
                  [isDisabled]="disabled"
                  (getAdress)="getResidenceAddress($event)"
                  (validate)="isResidenceAddressValidEvent($event)"
                ></app-address-input>
              </div>
            </div>
          </div>

          <div class="clearfix full-width">
            <div class="col-left">
              <h4 class="rm-mb">Loan Type</h4>
              <hr/>
              <div class="row clearfix full-width">
                <mat-form-field [ngClass]="highlightField('Info.Current')" appearance="outline">
                  <mat-label>Is Current</mat-label>
                  <mat-select [(ngModel)]="pcDetail.infoCurrent" [disabled]="disabled" name="infoCurrent" required>
                    <mat-option value="0"> No</mat-option>
                    <mat-option value="1"> Yes</mat-option>
                  </mat-select>
                </mat-form-field>

                <div class="row clearfix flex">
                  <mat-form-field class="full-width" [ngClass]="highlightField('Info.StartYear')" appearance="outline">
                    <mat-label>Moved In - Year</mat-label>
                    <input
                      [(ngModel)]="pcDetail.infoStartYear"
                      [disabled]="disabled"
                      matInput
                      placeholder="Year"
                      name="infoStartYear"
                      required
                    />
                  </mat-form-field>
                  <mat-form-field class="full-width" [ngClass]="highlightField('Info.StartMonth')" appearance="outline">
                    <mat-label>Moved In - Month</mat-label>
                    <input
                      [(ngModel)]="pcDetail.infoStartMonth"
                      [disabled]="disabled"
                      matInput
                      placeholder="Month"
                      name="infoStartMonth"
                      required
                    />
                  </mat-form-field>
                </div>

                <p class="mb">
                  Time At This Address:
                  <span *ngIf="pcDetail.infoYearsAtAddress == '' && pcDetail.infoMonthSAtAddress == ''">
                    <span class="value">Till Now</span>
                  </span>
                  <span *ngIf="pcDetail.infoYearsAtAddress != '' || pcDetail.infoMonthSAtAddress != ''">
                    <span class="value">{{ pcDetail.infoYearsAtAddress }}</span> years
                    <span class="value">{{ pcDetail.infoMonthSAtAddress }}</span> months
                  </span>
                </p>

                <mat-form-field
                  class="full-width"
                  [ngClass]="highlightField('Info.HousingMode.Code')"
                  appearance="outline"
                >
                  <mat-label>Housing Status</mat-label>

                  <mat-select
                    [(ngModel)]="pcDetail.infoHousingModeCode"
                    [disabled]="disabled"
                    placeholder="Housing Status"
                    name="infoHousingModeCode"
                    required
                  >
                    <mat-option *ngFor="let house of housingModeList" [value]="house.Code">
                      {{ house.Label }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <!-- <div class="clearfix" *ngIf="pcDetail.infoHousingModeCode=='RNT'">
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Who Pays The Rent</mat-label>
                    <input matInput placeholder="Who Pays The Rent" value="" [disabled]="disabled">
                  </mat-form-field>

                </div> -->

                <div
                  class="clearfix"
                  *ngIf="pcDetail.infoHousingModeCode == 'HOM' || pcDetail.infoHousingModeCode == 'HOO'"
                >
                  <mat-form-field
                    class="full-width"
                    [ngClass]="highlightField('Info.WhoOwnsIt.Code')"
                    appearance="outline"
                  >
                    <mat-label>Who Owns The Property</mat-label>

                    <mat-select
                      [(ngModel)]="pcDetail.infoWhoOwnsItCode"
                      [disabled]="disabled"
                      (selectionChange)="ownerChange($event.value)"
                      placeholder="Who Owns The Property"
                      name="WhoOwnsTheProperty"
                    >
                      <mat-option *ngFor="let p of propertyOwnerList" [value]="p.Code">
                        {{ p.Label }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field
                    class="full-width"
                    *ngIf="pcDetail.infoWhoOwnsItCode == 'COP'"
                    [ngClass]="highlightField('Info.NumberOfOwners')"
                    appearance="outline"
                  >
                    <mat-label>Number Of Owners</mat-label>
                    <input
                      [(ngModel)]="pcDetail.inforNumberOfOwners"
                      [disabled]="disabled"
                      (ngModelChange)="calcuateEquityPercent($event)"
                      matInput
                      placeholder="Number Of Owners"
                      name="inforNumberOfOwners"
                    />
                  </mat-form-field>

                  <mat-form-field
                    class="full-width"
                    [ngClass]="highlightField('Info.MarketValue')"
                    appearance="outline"
                  >
                    <mat-label>Market Value</mat-label>
                    <input
                      [(ngModel)]="pcDetail.infoMarketValue"
                      [disabled]="disabled"
                      (ngModelChange)="calcuateEquityValue($event)"
                      matInput
                      placeholder="Market Value"
                      name="infoMarketValue"
                      currencyMask
                    />
                  </mat-form-field>

                  <mat-form-field
                    class="full-width"
                    [ngClass]="highlightField('Info.EquityPercent')"
                    appearance="outline"
                  >
                    <mat-label>Equity percentage</mat-label>
                    <input
                      [(ngModel)]="pcDetail.infoEquitPercent"
                      [options]="{ prefix: '', suffix: ' %' }"
                      matInput
                      placeholder="Equity percentage"
                      name="infoEquitPercent"
                      currencyMask
                      disabled
                    />
                  </mat-form-field>

                  <mat-form-field class="full-width" [ngClass]="highlightField('Asset.Value')" appearance="outline">
                    <mat-label>Equity In Property</mat-label>
                    <input
                      [(ngModel)]="pcDetail.assetValue"
                      matInput
                      placeholder="Equity In Property"
                      name="infoEquitAmount"
                      currencyMask
                      disabled
                    />
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row clearfix flex" *ngIf="pcDetail.infoHousingModeCode == 'HOM'">
          <div class="clearfix full-width">
            <div class="col-left">
              <h4 class="rm-mb">Property Loan</h4>
              <hr/>
              <div class="row clearfix full-width">
                <mat-form-field class="full-width" [ngClass]="highlightField('Info.Bank.Code')" appearance="outline">
                  <mat-label>Lender</mat-label>

                  <mat-select
                    [(ngModel)]="pcDetail.infoBankCode"
                    [disabled]="disabled"
                    placeholder="Lender"
                    name="Lender"
                  >
                    <mat-option *ngFor="let bank of bankList" [value]="bank.ID">
                      {{ bank.Name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field
                  class="full-width"
                  [ngClass]="highlightField('Info.TypeOfLoan.Code')"
                  appearance="outline"
                >
                  <mat-label>Loan Type</mat-label>

                  <mat-select
                    [(ngModel)]="pcDetail.infoTypeOfLoanCode"
                    [disabled]="disabled"
                    placeholder="Loan Type"
                    name="LoanType"
                  >
                    <mat-option *ngFor="let h of homeLoanTypeList" [value]="h.Code">
                      {{ h.Label }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field
                  class="full-width"
                  [ngClass]="highlightField('Info.FacilityLimit')"
                  appearance="outline"
                >
                  <mat-label>Facility Limit</mat-label>
                  <input
                    [(ngModel)]="pcDetail.infoFacilityLimit"
                    [disabled]="disabled"
                    matInput
                    placeholder="Facility Limit"
                    name="infoFacilityLimit"
                    currencyMask
                  />
                </mat-form-field>

                <mat-form-field class="full-width" [ngClass]="highlightField('Liability.Value')" appearance="outline">
                  <mat-label>Amount Outstanding</mat-label>
                  <input
                    [(ngModel)]="pcDetail.liabilityValue"
                    [disabled]="disabled"
                    matInput
                    placeholder="Amount Outstanding"
                    name="liabilityValue"
                    currencyMask
                  />
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="clearfix full-width">
            <div class="col-left">
              <h4 class="rm-mb">Applicant's Loan Repayments</h4>
              <hr/>
              <div class="row clearfix full-width">
                <mat-form-field class="full-width" [ngClass]="highlightField('Expense.Value')" appearance="outline">
                  <mat-label>Amount</mat-label>
                  <input
                    [(ngModel)]="pcDetail.expenseValue"
                    [disabled]="disabled"
                    matInput
                    placeholder="Amount"
                    name="expenseValue"
                    currencyMask
                  />
                </mat-form-field>

                <mat-form-field class="full-width" [ngClass]="highlightField('Expense.Frequency')" appearance="outline">
                  <mat-label>Frequency</mat-label>

                  <mat-select
                    [(ngModel)]="pcDetail.expenseFrequency"
                    [disabled]="disabled"
                    placeholder="Frequency"
                    name="Frequency"
                  >
                    <mat-option *ngFor="let fre of frequencies" [value]="fre.Code">
                      {{ fre.Label }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>

        <div
          class="row clearfix flex"
          *ngIf="pcDetail.infoHousingModeCode == 'RNT' || pcDetail.infoHousingModeCode == 'BRD'"
        >
          <div class="clearfix full-width">
            <div class="col-left">
              <h4 class="rm-mb" *ngIf="pcDetail.infoHousingModeCode == 'RNT'">Rental Details</h4>
              <h4 class="rm-mb" *ngIf="pcDetail.infoHousingModeCode == 'BRD'">Rental Details</h4>
              <hr/>
              <div class="row clearfix full-width">
                <mat-form-field class="full-width" [ngClass]="highlightField('Info.AgentName')" appearance="outline">
                  <mat-label>Agent/Landlord Name</mat-label>
                  <input
                    [(ngModel)]="pcDetail.infoAgentName"
                    [disabled]="disabled"
                    matInput
                    placeholder="Agent/Landlord Name"
                    name="infoAgentName"
                  />
                </mat-form-field>

                <mat-form-field
                  class="full-width"
                  [ngClass]="highlightField('Info.PropertyManager')"
                  appearance="outline"
                >
                  <mat-label>Property Manager</mat-label>
                  <input
                    [(ngModel)]="pcDetail.infoPropertyManager"
                    [disabled]="disabled"
                    matInput
                    placeholder="Property Manager"
                    name="infoPropertyManager"
                  />
                </mat-form-field>

                <mat-form-field class="full-width" [ngClass]="highlightField('Info.AgentContact')" appearance="outline">
                  <mat-label>Agent/Landlord Contact</mat-label>
                  <input
                    [(ngModel)]="pcDetail.infoAgentContact"
                    [disabled]="disabled"
                    matInput
                    placeholder="Agent/Landlord Contact"
                    name="infoAgentContact"
                  />
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="clearfix full-width">
            <div class="col-left">
              <h4 class="rm-mb">Applicant's Rent Payments</h4>
              <hr/>
              <div class="row clearfix full-width">
                <mat-form-field class="full-width" [ngClass]="highlightField('Expense.Value')" appearance="outline">
                  <mat-label>Amount</mat-label>
                  <input
                    [(ngModel)]="pcDetail.expenseValue"
                    [disabled]="disabled"
                    matInput
                    placeholder="Amount"
                    name="expenseValue"
                    currencyMask
                  />
                </mat-form-field>

                <mat-form-field class="full-width" [ngClass]="highlightField('Expense.Frequency')" appearance="outline">
                  <mat-label>Frequency</mat-label>

                  <mat-select
                    [(ngModel)]="pcDetail.expenseFrequency"
                    [disabled]="disabled"
                    placeholder="Frequency"
                    name="Frequency"
                  >
                    <mat-option *ngFor="let fre of frequencies" [value]="fre.Code">
                      {{ fre.Label }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-dialog-content>

    <div class="row clearfix mt" *ngIf="disabled == false">
      <button
        class="pull-right"
        *ngIf="isModal == false"
        [disabled]="!PCIForm.form.valid || isValidAddress == false"
        (click)="submit()"
        mat-raised-button
        color="accent"
      >
        Submit
      </button>
      <button
        class="pull-right"
        *ngIf="isModal == true"
        [disabled]="!PCIForm.form.valid || isValidAddress == false"
        (click)="create()"
        mat-raised-button
        color="accent"
      >
        Create
      </button>
    </div>
  </form>
</div>
