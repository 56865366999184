<mat-sidenav-container
  class="merchant-dash"
  #mainContentFrame
  [class.experimental]="isModuleExperimentalFeaturesActive == true"
  (window:resize)="onResize($event)"
>
  <mat-sidenav class="" #sidenav [mode]="navMode" [opened]="openFlag" [class.hidesidemenu]="displaySideMenu != true">
    <button class="btn-clear mobshow menu-button white" (click)="sidenav.close()" mat-button>
      <mat-icon>close</mat-icon>
    </button>
    <app-merchant-side-menu id="application-side-menu"></app-merchant-side-menu>
  </mat-sidenav>

  <!-- Side Menu -->

  <!-- flex container -->
  <div class="body-container">
    <!-- Header -->
    <app-merchant-header id="application-header" (isDrag)="isDragEvent($event)" (selectedType)="getNoteType($event)">
    </app-merchant-header>

    <!-- Content frame -->
    <div class="body-container-content" #mainDiv>
      <button
        class="btn-clear menu-trigger"
        (click)="sidenav.open()"
        style="width: 100px; position: absolute; top: 20px"
        mat-button
      >
        <mat-icon>menu</mat-icon>
      </button>

      <!--  Load application modules in here  -->

      <router-outlet class="mt" name="page"></router-outlet>

      <!--  End Load application modules in here  -->
    </div>
    <!--  Footer -->

    <app-merchant-footer id="application-footer"></app-merchant-footer>
  </div>
</mat-sidenav-container>
