import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { LookupService } from '../../../shared/services/lookup.service';
import * as _ from 'lodash';
import { MAT_DIALOG_DATA } from '@angular/material';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { ContactService } from '../shared/contact.service';
import { UtilsClass } from '../../../shared/types/utils/utils.class';

@Component({
  selector: 'contact-create-edit-modal',
  templateUrl: './contact-create-edit-modal.component.html',
  styleUrls: ['./contact-create-edit-modal.component.css'],
})
export class ContactCreateEditModalComponent implements OnInit {
  @Output()
  getResult = new EventEmitter();

  @Output()
  close = new EventEmitter();

  allowExtensions = ['jpeg', 'jpg', 'png','heic' , 'gif', 'tiff', 'bmp', 'ico'];
  isMobileRequired = true;
  isEmailRequired = false;

  postPictureLinkThumbnail;
  postPictureThumbnail;
  fileIDThumbnail;
  merchantID;
  sessionType;
  isMobileValid = false;
  isHomePhoneValid = false;
  isWorkPhoneValid = false;
  isEmailValid = false;
  isAdminOrPromoter = false;
  isAddressValid = true;

  utils = new UtilsClass();
  firstName;
  lastName;
  middleName;
  mobile;
  workPhone;
  homePhone;
  email;
  gender;
  title;
  postCode;
  state;
  suburb;
  streetNumber;
  streetName;
  unitNumber;
  streetType;
  latitude;
  longitude;

  genders = [];
  titles = [];

  isPromoterOrAdmin = false;
  merchants = [];

  contactID;

  isLoaded = false;
  contactType="STAFF";
  contact;
  contactTypes;
  constructor(
    private contactService: ContactService,
    private authenticationService: AuthenticationService,
    private lookupService: LookupService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data && data.merchantID) {
      this.merchantID = data.merchantID;
    }

    if (data && data.contactID) {
      this.contactID = data.contactID;
    }
    if (data && data.isMobileRequired === true) {
      this.isMobileRequired = true;
    } else if (data && data.isMobileRequired === false) {
      this.isMobileRequired = false;
    }
    if (data && data.isEmailRequired === true) {
      this.isEmailRequired = true;
    } else if (data && data.isEmailRequired === false) {
      this.isEmailRequired = false;
    }
  }

  ngOnInit() {
    this.lookupService.getLookup('CodeLookup', 'Gender').subscribe((data) => {
      this.genders = data;
    });
    this.lookupService.getLookup('SimpleLookup', 'Salutation').subscribe((data) => {
      this.titles = data;
    });

    this.lookupService.getLookup('CodeLookup', 'ContactType').subscribe((data) => {
      this.contactTypes = data;
    });

    this.authenticationService.isPromoterOrAdmin().subscribe((isPromoterOrAdmin) => {
      this.isPromoterOrAdmin = isPromoterOrAdmin;
      this.authenticationService.getSessionType().subscribe((r) => {
        this.sessionType = r;

        if (this.isPromoterOrAdmin == true) {
          if (!this.merchantID) {
            this.authenticationService.getCurrentPractice().subscribe((res) => {
              if (res && res.ID) {
                this.merchantID = res.ID;
              }

              this.setup();
            });
          } else {
            this.setup();
          }

          this.authenticationService.getMerchantList({ fields: 'ID,TradingAs' }).subscribe((merchants) => {
            if (merchants) {
              this.merchants = merchants;
            }
          });
        } else {
          this.setup();
        }
      });
    });
  }

  setup() {
    if (this.contactID) {
      this.contactService.getContactDetails(this.contactID, {}, this.sessionType).subscribe((r) => {
        if (r) {
          this.contact = r;

          if (r['Merchant_key']) {
            this.merchantID = r['Merchant_key'];
          }

          if (r['FirstName']) {
            this.firstName = r['FirstName'];
          }

          if (r['Name']) {
            this.lastName = r['Name'];
          }

          if (r['ContactType.Code']) {
            this.contactType = r['ContactType.Code'];
          }

          if (r['MiddleName']) {
            this.middleName = r['MiddleName'];
          }

          if (r['mobiles.Number']) {
            this.mobile = r['mobiles.Number'];
          }

          if (r['phones.Number']) {
            this.homePhone = r['phones.Number'];
          }
          if (r['emails.Email']) {
            this.email = r['emails.Email'];
          }

          if (r['Gender.Code']) {
            this.gender = r['Gender.Code'];
          }
          if (r['Salutation']) {
            this.title = r['Salutation'];
          }
          if (r['addresses.Postcode']) {
            this.postCode = r['addresses.Postcode'];
          }
          if (r['addresses.State']) {
            this.state = r['addresses.State'];
          }
          if (r['addresses.Suburb']) {
            this.suburb = r['addresses.Suburb'];
          }
          if (r['addresses.Street Nr']) {
            this.streetNumber = r['addresses.Street Nr'];
          }
          if (r['addresses.Street Name']) {
            this.streetName = r['addresses.Street Name'];
          }
          if (r['addresses.Unit']) {
            this.unitNumber = r['addresses.Unit'];
          }
          if (r['addresses.Street Type']) {
            this.streetType = r['addresses.Street Type'];
          }

          this.postPictureThumbnail = this.contactService.getProfilePicStreamLink(this.contactID);
        }

        this.isLoaded = true;
      });
    } else {
      this.isLoaded = true;
    }
  }

  getMobile(p) {
    this.mobile = p;
  }

  getHomePhone(p) {
    this.homePhone = p;
  }

  getWorkPhone(p) {
    this.workPhone = p;
  }

  validateMobile(v) {
    this.isMobileValid = v;
  }

  validateHomePhone(v) {
    this.isHomePhoneValid = v;
  }

  validateWorkPhone(v) {
    this.isWorkPhoneValid = v;
  }

  validateEmail(v) {
    this.isEmailValid = v;
  }

  getEmail(b) {
    this.email = b;
  }
  closeEvent() {
    this.close.emit(true);
  }

  save() {
    let payload: any = {
      mobile: this.mobile,
      phone: this.homePhone,
      email: this.email,
      firstName: this.firstName,
      lastName: this.lastName,
      merchantID: this.merchantID,
      gender: this.gender,
      title: this.title,
      contactTypeCode: this.contactType,
      suburb: this.suburb,
      state: this.state,
      postCode: this.postCode,
      streetNumber: this.streetNumber,
      streetName: this.streetName,
      unitNumber: this.unitNumber,
      streetType: this.streetType,
      latitude: this.latitude,
      longitude: this.longitude,
      fileID:this.fileIDThumbnail
    };

    if (this.contactID) {
      this.contactService.modifyContact(this.contactID, payload, this.sessionType).subscribe((r) => {
        if (r && r.ID) {


          r.tableName='Contact';
          r.TableName='Contact';

          this.getResult.emit(r);
        }
      });
    } else {
      this.contactService.createContact(payload, this.sessionType).subscribe((r) => {
        if (r && r.ID) {

          r.tableName='Contact';
          r.TableName='Contact';
          this.getResult.emit(r);
        }
      });
    }
  }

  isAddressValidEvent(d) {
    this.isAddressValid = d;
  }
  getAddress(adr) {
    if (adr) {
      this.suburb = adr.suburb;
      this.state = adr.state;
      this.postCode = adr.postCode;
      this.streetNumber = adr.streetNumber;
      this.streetName = adr.streetName;
      this.unitNumber = adr.unitNumber;
      this.streetType = adr.streetType;
      this.latitude = adr.latitude;
      this.longitude = adr.longitude;
    }
  }
  onCompleteThumbnail(fileID) {
    if (fileID && fileID[0]) {
      this.fileIDThumbnail = fileID[0];
      this.postPictureThumbnail = null;
      this.postPictureLinkThumbnail = this.utils.getFileLink(this.fileIDThumbnail);
    } else {
      this.postPictureLinkThumbnail = null;
    }
  }
}
