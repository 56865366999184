<div class="rel patient-card dc row clearfix" *ngIf="patient" @onChangeAnimation>
  <div class="animate clearfix">
    <div class="row full-width clearfix heading" *ngIf="embedded == true">
      <app-customer-prospect-view-picture
        class="patientIconViewProfile-patient mr pull-left"
        [patientID]="patient['ID']"
      >
      </app-customer-prospect-view-picture>

      <h2 class="summary-header rm-m ml">
        {{ patient.CalculatedName }}
        <span class="subLabel">{{ "KEYWORD.practitioner" | translate | titlecase }}</span>
      </h2>
    </div>

    <div class="card-header primary-gradient-img clearfix dentHeading" *ngIf="embedded == false">
      <div class="row clearfix">
        <!-- Modal dismiss button -->
        <button class="btn-close btn-clear mat-button white" *ngIf="isModal == true" (click)="closeModal()">
          <mat-icon>clear</mat-icon>
        </button>
        <!-- merchant calculated name -->
        <div class="row titleArea clearfix">
          <app-customer-prospect-view-picture
            class="patientIconViewProfile-patient mr pull-left"
            [patientID]="patient['ID']"
          >
          </app-customer-prospect-view-picture>
          <h2 class="summary-header rm-m">
            {{ patient.Salutation }} {{ patient.CalculatedName }}
            <span class="subLabel">{{ "KEYWORD.patient" | translate | titlecase }}
              - joined {{ patient.DateTimeCreated | TimeLabelPast }} ago </span>
          </h2>
        </div>
      </div>
    </div>
    <mat-dialog-content>
      <div class="full-width clearfix row">
        <div class="row mt clearfix details flex" *ngIf="patient['mobiles.Number']">
          <!-- <div class="icons"> -->
          <mat-icon class="ico fas fa-mobile"></mat-icon>
          <!-- </div> -->
          <!-- Phone numbers -->
          <div class="row-content">
            <!--  -->
            <p class="rm-mb rm-mt lead">{{ patient['mobiles.Number'] | customPhone }}</p>
            <p class="small">Mobile</p>
          </div>
        </div>
        <hr *ngIf="patient['phones.Number']"/>

        <div class="row mt clearfix details flex" *ngIf="patient['phones.Number']">
          <!-- <div class="icons"> -->
          <mat-icon class="ico fas fa-phone"></mat-icon>
          <!-- </div> -->
          <!-- Phone numbers -->
          <div class="row-content">
            <!--  -->
            <p class="rm-mb rm-mt lead">{{ patient['phones.Number'] | customPhone: 'landLine' }}</p>
            <p class="small">Home</p>
          </div>
        </div>
        <hr *ngIf="patient['emails.Email']"/>
        <!-- Email Addresses -->
        <div class="row mt clearfix details flex" *ngIf="patient['emails.Email']">
          <!-- <div class="icons"> -->
          <mat-icon class="ico fas fa-envelope"></mat-icon>
          <!-- </div> -->
          <div class="row-content">
            <p class="rm-mb rm-mt lead">{{ patient['emails.Email'] }}</p>
            <p class="small">Email</p>
          </div>
        </div>

        <hr *ngIf="patient['DateOfBirth'] && patient['DateOfBirth'] != '0000-00-00'"/>
        <!-- Email Addresses -->
        <div
          class="row mt clearfix details flex"
          *ngIf="patient['DateOfBirth'] && patient['DateOfBirth'] != '0000-00-00'"
        >
          <!-- <div class="icons"> -->
          <mat-icon class="ico fas fa-calendar"></mat-icon>
          <!-- </div> -->
          <div class="row-content">
            <p class="rm-mb rm-mt lead">
              {{ patient['DateOfBirth'] | customDate }} ({{ patient['DateOfBirth'] | age }})
            </p>
            <p class="small">Date of birth</p>
          </div>
        </div>

        <hr *ngIf="patient['addresses.Calculated']"/>
        <div class="row mt clearfix details flex" *ngIf="patient['addresses.Calculated']">
          <!-- <div class="icons"> -->
          <mat-icon class="ico">location_on</mat-icon>
          <!-- </div> -->
          <div class="row-content">
            <p class="rm-mb rm-mt address-click lead" (click)="displayMap()">{{ patient['addresses.Calculated'] }}</p>
            <p class="small address-click" (click)="displayMap()">Address</p>
          </div>
        </div>

        <hr *ngIf="patient['IDFromMerchant']"/>
        <div class="row mt clearfix details flex" *ngIf="patient['IDFromMerchant']">
          <!-- <div class="icons"> -->
          <mat-icon class="ico fas fa-clipboard"></mat-icon>
          <!-- </div> -->
          <div class="row-content">
            <p class="rm-mb rm-mt lead">{{ patient['IDFromMerchant'] }}</p>
            <p class="small">{{ "KEYWORD.practice" | translate | titlecase}} reference</p>
          </div>
        </div>

        <hr/>
        <div class="row mt clearfix">
          <mat-icon
            class="smr pull-left"
            *ngIf="patient['MarketingConsent.Given'] == '1'"
            style="color: rgb(90, 186, 71)"
          >check
          </mat-icon>
          <mat-icon
            class="smr pull-left"
            *ngIf="patient['MarketingConsent.Given'] == '0'"
            style="color: rgb(239, 71, 39)"
          >close
          </mat-icon>
          <p class="rm-mb rm-mt ">



            The {{"KEYWORD.patient" | translate}} consents to receive marketing communication via <strong> SMS</strong> <span
              matTooltip="{{'Last update '+(patient['MarketingConsent.When']| TimeLabelPast) +' ago'}}"
              *ngIf="patient['MarketingConsent.When']!='0000-00-00' || !patient['MarketingConsent.When']"> -
              ({{patient['MarketingConsent.When'] | customDate}})</span>
          </p>


        </div>

        <div class="row mt clearfix  ">


          <mat-icon *ngIf=" patient['MarketingConsent_Email.Given']=='1'" class="smr  pull-left"
            style="color: rgb(90, 186, 71);     ">check
          </mat-icon>
          <mat-icon *ngIf=" patient['MarketingConsent_Email.Given']=='0'" class="smr  pull-left"
            style="color: rgb(239, 71, 39);    ">close
          </mat-icon>
          <p class="rm-mb rm-mt ">



            The {{"KEYWORD.patient" | translate}} consents to receive marketing communication via <strong> Email</strong> <span
              matTooltip="{{'Last update '+(patient['MarketingConsent_Email.When']| TimeLabelPast) +' ago'}}"
              *ngIf="patient['MarketingConsent_Email.When']!='0000-00-00'  || !patient['MarketingConsent_Email.When'] "> -
              ({{patient['MarketingConsent_Email.When']| customDate}})</span>
          </p>


        </div>
        <div class="row  smt clearfix  ">


          <mat-icon *ngIf=" patient.ReferredCustomer=='1'" class="smr  pull-left" style="color: rgb(90, 186, 71) ; ">
            check
          </mat-icon>
          <mat-icon class="smr pull-left" *ngIf="patient.ReferredCustomer == '0'" style="color: rgb(239, 71, 39)">
            close
          </mat-icon>
          <p class="rm-mb rm-mt">This {{"KEYWORD.patient" | translate}} was referred</p>
        </div>

        <hr *ngIf="patient.tags && patient.tags.length > 0"/>

        <div class="row mt prospect-tags mb clearfix details" *ngIf="patient.tags && patient.tags.length > 0">
          <mat-chip-list>
            <mat-chip *ngFor="let tag of patient['tags'] | orderBy">
              {{ tag }}
            </mat-chip>
          </mat-chip-list>
        </div>
      </div>
    </mat-dialog-content>

    <hr *ngIf="isFullDetails == true"/>
    <div class="drop-button text-center mt text-center full-width" *ngIf="isFullDetails == true">
      <button
        class="btn-large"
        *ngIf="isFullDetails == true"
        (click)="openPatientDetails()"
        mat-raised-button
        color="accent"
      >
        Full {{"KEYWORD.patient" | translate | titlecase}} Details
      </button>
    </div>
  </div>
</div>
