import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class EconnectService {
  constructor(private http: HttpClient) {}

  getTableList(__params = {}): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (__params) {
      for (const key in __params) {
        if (__params.hasOwnProperty(key)) {
          params = params.set(key, __params[key]);
        }
      }

      options = {
        params,
      };
    }

    return this.http.get(`${environment.nodeUrl}/econnect-table`, options).map((res: HttpResponse<any>) => res['data']);
  }

  getTableDetails(id, __params = {}): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (__params) {
      for (const key in __params) {
        if (__params.hasOwnProperty(key)) {
          params = params.set(key, __params[key]);
        }
      }

      options = {
        params,
      };
    }

    return this.http
      .get(`${environment.nodeUrl}/econnect-table/${id}`, options)
      .map((res: HttpResponse<any>) => res['data']);
  }

  getFieldList(tableName, __params = {}): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (__params) {
      for (const key in __params) {
        if (__params.hasOwnProperty(key)) {
          params = params.set(key, __params[key]);
        }
      }

      options = {
        params,
      };
    }

    return this.http
      .get(`${environment.nodeUrl}/econnect-field/table/${tableName}`, options)
      .map((res: HttpResponse<any>) => res['data']);
  }

  getFieldDetails(id, __params = {}): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (__params) {
      for (const key in __params) {
        if (__params.hasOwnProperty(key)) {
          params = params.set(key, __params[key]);
        }
      }

      options = {
        params,
      };
    }

    return this.http
      .get(`${environment.nodeUrl}/econnect-field/${id}`, options)
      .map((res: HttpResponse<any>) => res['data']);
  }
}
