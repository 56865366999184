<div class="row clearfix mb searchArea searchArea2 primary-gradient-img">
  <div class="row clearfix flex">
    <div class="full-width flex">
      <div class="qtr-width rm-m flex">
        <app-date-input
          class="pull-left mr ml"
          *ngIf="displayDaySelector == true"
          [displayDateInput]="false"
          [defaultDate]="null"
          [isTime]="false"
          [minDate]="null"
          (selectedDate)="selectDate($event)"
          label="Book Date"
        >
        </app-date-input>

        <button
          class="pull-left mr ml white no-border-button"
          *ngIf="displayTodaySelector == true"
          (click)="viewToday()"
          mat-stroked-button
          mwlCalendarToday
        >
          Today
        </button>
      </div>

      <div class="full-width rm-m text-center">
        <mat-icon
          class="arrow-class white mr"
          [(viewDate)]="viewDate"
          [view]="view"
          (viewDateChange)="closeOpenMonthViewDay()"
          matRipple
          mwlCalendarPreviousView
        >arrow_left
        </mat-icon>

        <h1 class="dataHeader text-center rm-m white main-title-m">
          <span>{{ viewDate | calendarDate: view + 'ViewTitle':'en' }}</span>
        </h1>

        <mat-icon
          class="white ml arrow-class"
          [(viewDate)]="viewDate"
          [view]="view"
          (viewDateChange)="closeOpenMonthViewDay()"
          matRipple
          mwlCalendarNextView
        >arrow_right
        </mat-icon>
      </div>
      <div class="qtr-width rm-m flex">
        <button
          class="white mr"
          *ngIf="displayMonthView == true"
          (click)="viewTypeChange(CalendarView.Month)"
          mat-stroked-button
        >
          <strong> Month</strong>
        </button>

        <button
          class="white mr"
          *ngIf="displayWeekView == true"
          (click)="viewTypeChange(CalendarView.Week)"
          mat-stroked-button
        >
          <strong> Week</strong>
        </button>

        <button
          class="white mr"
          *ngIf="displayDayView == true"
          (click)="viewTypeChange(CalendarView.Day)"
          mat-stroked-button
        >
          <strong> Day</strong>
        </button>
      </div>
    </div>
  </div>
</div>
<div
  class="full-width clearfix text-center tool-container flex"
  *ngIf="(view == CalendarView.Week || view == CalendarView.Day) && zoomPanel == true"
>
  <div class="clearfix flex row clearfix time-input">
    <div class="minute-input ml pull-left">
      <p class="pull-left" color="primary">Hours Segment:</p>
      <mat-form-field class="time-hours-Segment time-hours-class" appearance="outline">
        <input
          class=""
          #minutesInputID
          [disabled]="true"
          [value]="hourSegments"
          (focus)="$event.target.select()"
          type="number"
          matInput
          name="minutesInputID2"
        />

        <div class="time-change-hours-class text-center">
          <mat-icon class="fas fa-chevron-up change-time" (click)="changeHoursUP()" color="primary"></mat-icon>
          <mat-icon class="fas fa-chevron-down change-time" (click)="changeHoursDown()" color="primary"></mat-icon>
        </div>
      </mat-form-field>
    </div>
  </div>

  <button
    class="mr ml"
    [disabled]="hourSegmentHeight <= 20"
    (click)="zomeIn()"
    color="primary"
    mat-button
    matTooltip="Zoom in"
  >
    <mat-icon class="fas fa-search-minus"></mat-icon> Zoom in
  </button>

  <button
    class="mr ml"
    [disabled]="hourSegmentHeight > 350"
    (click)="zomeOut()"
    color="primary"
    mat-button
    matTooltip="Zoom out"
  >
    <mat-icon class="fas fa-search-plus"></mat-icon> Zoom out
  </button>
</div>

<div class="clearfix full-width" [ngSwitch]="view">
  <mwl-calendar-month-view
    *ngSwitchCase="CalendarView.Month"
    [viewDate]="viewDate"
    [events]="events"
    [refresh]="refresh"
    [weekStartsOn]="weekStartsOn"
    [activeDayIsOpen]="activeDayIsOpen"
    (dayClicked)="dayClicked($event.day)"
    (eventClicked)="handleEvent('Clicked', $event.event)"
    (eventTimesChanged)="eventTimesChanged($event)"
  >
  </mwl-calendar-month-view>
  <mwl-calendar-week-view
    *ngSwitchCase="CalendarView.Week"
    [viewDate]="viewDate"
    [events]="events"
    [refresh]="refresh"
    [dayStartHour]="dayStartHour"
    [weekStartsOn]="weekStartsOn"
    [hourSegmentHeight]="hourSegmentHeight"
    [hourSegments]="hourSegments"
    [dayStartMinute]="dayStartMinute"
    (dayHeaderClicked)="dayHeaderClickedWeek($event)"
    (eventClicked)="handleEvent('Clicked', $event.event)"
    (eventTimesChanged)="eventTimesChanged($event)"
  >
  </mwl-calendar-week-view>
  <mwl-calendar-day-view
    *ngSwitchCase="CalendarView.Day"
    [viewDate]="viewDate"
    [events]="events"
    [refresh]="refresh"
    [dayStartHour]="dayStartHour"
    [hourSegmentHeight]="hourSegmentHeight"
    [hourSegments]="hourSegments"
    [dayStartMinute]="dayStartMinute"
    (eventClicked)="handleEvent('Clicked', $event.event)"
    (eventTimesChanged)="eventTimesChanged($event)"
  >
  </mwl-calendar-day-view>
</div>

<ng-container *ngIf="selectedDateEvents && selectedDateEvents.length > 0 && viewList == true">
  <br />
  <h3>{{ tableLabel }}</h3>
  <mat-table class="list-table" #table [dataSource]="selectedDateEvents" matSort>
    <ng-container cdkColumnDef="title" sticky>
      <mat-header-cell *cdkHeaderCellDef mat-sort-header>
        {{ titleColumnLabel }}
      </mat-header-cell>
      <mat-cell *cdkCellDef="let row" (click)="handleEvent('Clicked', row)">
        {{ row.title }}
      </mat-cell>
    </ng-container>

    <ng-container cdkColumnDef="color" sticky>
      <mat-header-cell *cdkHeaderCellDef mat-sort-header>
        {{ colorColumnLabel }}
      </mat-header-cell>
      <mat-cell *cdkCellDef="let row" (click)="handleEvent('Clicked', row)">
        <div class="colorDiv" [style.backgroundColor]="row.color.primary">&nbsp;</div>
      </mat-cell>
    </ng-container>

    <ng-container cdkColumnDef="colorType" sticky>
      <mat-header-cell *cdkHeaderCellDef mat-sort-header>
        {{ colorTypeColumnLabel }}
      </mat-header-cell>
      <mat-cell *cdkCellDef="let row" (click)="handleEvent('Clicked', row)">
        <div>
          {{ row.colorType }}
        </div>
      </mat-cell>
    </ng-container>

    <ng-container cdkColumnDef="startsAt">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header>
        {{ startsAtColumnLabel }}
      </mat-header-cell>
      <mat-cell *cdkCellDef="let row" (click)="handleEvent('Clicked', row)">
        {{ row.start | timeLeft }}
      </mat-cell>
    </ng-container>

    <ng-container cdkColumnDef="endsAt">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header>
        {{ endsAtColumnLabel }}
      </mat-header-cell>
      <mat-cell *cdkCellDef="let row" (click)="handleEvent('Clicked', row)">
        {{ row.end | timeLeft }}
      </mat-cell>
    </ng-container>

    <ng-container cdkColumnDef="actions">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header> Actions</mat-header-cell>
      <mat-cell *cdkCellDef="let row">
        <button (click)="handleEvent('Clicked', row)" mat-raised-button color="primary">
          <span>View</span>
        </button>

        <button
          *ngFor="let action of row.actions"
          (click)="handleEvent(action.a11yLabel, row)"
          color="{{ action.a11yLabel === 'Delete' ? 'accent' : 'primary' }}"
          mat-raised-button
        >
          <span>{{ action.a11yLabel }}</span>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *cdkRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
</ng-container>
