<mat-sidenav-container class="profile-container" *ngIf="dentist$ | async as dentist">
  <mat-sidenav class="profile-sidebar" #sidenavProfile *ngIf="hideSide == false" [mode]="navMode"
               [opened]="isSideMenuOpen" [position]="'end'">
    <div class="sticky">
      <section class="profile-header primary-gradient-img rel">
        <app-ep-document-view-image class="profile-lead-image" [defaultPicture]="
            'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/practice-pic.png'
          " [link]="profileLink" tabindex="0">
        </app-ep-document-view-image>

        <div class="profile-header-details">
          <h2>{{ dentist['CalculatedName'] }}</h2>
          <p class="small" *ngIf="dentist['DateTimeCreated']">
            Created Date: {{ dentist['DateTimeCreated'] | customDateTime }}
          </p>
          <p class="small" *ngIf="dentist['LastModified_Human']">
            Last modifed Date:
            {{ dentist['LastModified_Human'] | customDateTime }}
          </p>
        </div>
      </section>
    </div>
  </mat-sidenav>

  <div class="profile-content">
    <div class="full-width toggle-card mb clearfix">
      <mat-button-toggle-group class="btn-group session-profile rm-m mat-elevation-z4 full-width flex"
                               [(ngModel)]="settings">
        <mat-button-toggle class="full-width text-center" [value]="'profile'">
          <mat-icon class="fas fa-users"></mat-icon>
          {{ "KEYWORD.practice" | translate | titlecase}} Staff
        </mat-button-toggle>

        <mat-button-toggle class="full-width text-center" *ngIf="isPromoterOrAdmin == true" [value]="'message'">
          <mat-icon class="fas fa-comments"></mat-icon>
          Messages
        </mat-button-toggle>

        <mat-button-toggle class="full-width text-center" *ngIf="isPromoterOrAdmin == true" [value]="'document'">
          <mat-icon class="fas fa-file-alt"></mat-icon>
          Documents
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <div class="tab-content clearfix" *ngIf="settings == 'profile'">
      <form #dentistDetails="ngForm">
        <div class="row clearfix flex flexwrap">
          <mat-card class="card full-width two-third">
            <div class="colour-stripe"></div>
            <h3 class="sr-title rm-m sm-h3">
              Personal Details
            </h3>
            <hr class="mb"/>
            <div class="row clearfix">
              <div class="row clearfix flex">
                <div class="icons-col">
                  <mat-icon>person</mat-icon>
                </div>
                <div class="row-content-col">
                  <div class="row clearfix flex inputFlex">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Title</mat-label>
                      <mat-select class="full-width" [(ngModel)]="dentist['Salutation']"
                                  [disabled]="disableEditPractice"
                                  [ngClass]="{ removeArrow: disableEditPractice == true }"
                                  placeholder="Title" name="title">
                        <mat-option *ngFor="let title of titles" [value]="title.Label">
                          {{ title.Label }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Gender</mat-label>
                      <mat-select class="full-width" [(ngModel)]="dentist['Gender.Code']"
                                  [disabled]="disableEditPractice"
                                  [ngClass]="{ removeArrow: disableEditPractice == true }"
                                  placeholder="Gender" name="gender">
                        <mat-option *ngFor="let gender of genders" [value]="gender.Code">
                          {{ gender.Label }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="row clearfix flex inputFlex">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>First Name</mat-label>
                      <input [(ngModel)]="dentist['FirstName']" [disabled]="disableEditPractice" name="firstname"
                             matInput placeholder="First Name" required/>
                    </mat-form-field>
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Middle Name</mat-label>
                      <input [(ngModel)]="dentist['MiddleName']" [disabled]="disableEditPractice" name="middlename"
                             matInput placeholder="Middle Name"/>
                    </mat-form-field>
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Surname</mat-label>
                      <input [(ngModel)]="dentist['Name']" [disabled]="disableEditPractice" name="lastname" matInput
                             placeholder="Surname" required/>
                    </mat-form-field>
                  </div>
                </div>
              </div>

              <div class="row clearfix flex">
                <div class="icons-col">
                  <mat-icon>today</mat-icon>
                </div>
                <div class="row-content-col">
                  <div class="row clearfix flex">
                    <app-date-input class="full-width" [startView]="'multi-year'" [label]="'Date Of Birth'"
                                    [defaultDate]="dentist['DateOfBirth']" [disabled]="disableEditPractice"
                                    (selectedDate)="setBirthday($event)" name="DateOfBirth"></app-date-input>

                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Contact Type</mat-label>
                      <mat-select class="full-width" [(ngModel)]="dentist['ContactType.Code']" placeholder="Role"
                                  name="Role">
                        <mat-option *ngFor="let contactTypeCode of contactTypeCodes" [value]="contactTypeCode.Code">
                          {{ contactTypeCode.Label }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
          </mat-card>

          <mat-card class="card full-width third">
            <div class="colour-stripe"></div>
            <h3 class="sr-title rm-m sm-h3">
              Profile Image
            </h3>
            <hr class="mb"/>
            <div class="row clearfix text-center mt">
              <div class="upload-container">
                <app-file-uploader class="text-center clearfix full-width noLowerPad avatarUploader"
                                   *ngIf="disableEditPractice == false" [allowedExtensionTypes]="['image']" [limit]="1"
                                   [uploadDirectly]="true" [isDragable]="true" [keepOriginal]="false"
                                   [isFixedAspectRatio]="true"
                                   [resizeToHeight]="400" [resizeToWidth]="400"
                                   (onCompleteAll)="onCompleteAllPractice($event, dentist)">
                </app-file-uploader>
                <app-ep-document-view-image class="profile-lead-image" [defaultPicture]="
                    'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/profile_placeholder.png)'
                  " [link]="profileLink">
                </app-ep-document-view-image>
              </div>
              <p class="small" *ngIf="disableEditPractice == false">
                To upload a profile picture, please drag a
                <strong>.jpg or .png file</strong> into the grey area.
              </p>
            </div>
          </mat-card>

          <mat-card class="card full-width">
            <div class="colour-stripe"></div>
            <h3 class="sr-title rm-m sm-h3">Profile Description</h3>
            <hr class="mb"/>
            <div class="row clearfix">
              <p class="small rm-mb">
                Add some descriptive text about yourself, which will be seen by {{ "KEYWORD.patient" | translate }}s and
                {{"KEYWORD.dentist" | translate}}s previewing your
                profile.
              </p>
              <app-text-editor-input class="clearfix" *ngIf="disableEditPractice == false"
                                     [content]="dentist['Description']" [isPreview]="false" [title]="null"
                                     [isAction]="false"
                                     [isMultiView]="false" (getContent)="setProfileContent($event)" name="editor">
              </app-text-editor-input>
              <div *ngIf="disableEditPractice == true" [innerHTML]="dentist['Description'] | safeHtml"></div>
            </div>
          </mat-card>

          <mat-card class="card full-width third">
            <div class="colour-stripe"></div>
            <h3 class="sr-title rm-m sm-h3">
              Social Media
            </h3>
            <hr class="mb"/>

            <div class="row clearfix">
              <div class="row clearfix flex">
                <div class="icons-col">
                  <mat-icon class="fas fa-globe"></mat-icon>
                </div>
                <div class="row-content-col">
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Personal Website</mat-label>
                    <input [(ngModel)]="dentist['URL']" [disabled]="disableEditPractice" name="URL" matInput
                           placeholder="Please enter the address"/>
                  </mat-form-field>
                </div>
              </div>

              <div class="row clearfix flex">
                <div class="icons-col">
                  <mat-icon class="fab fa-facebook"></mat-icon>
                </div>
                <div class="row-content-col">
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Facebook</mat-label>
                    <input [(ngModel)]="dentist['Facebook']" [disabled]="disableEditPractice" name="facebook" matInput
                           placeholder="Facebook"/>
                  </mat-form-field>
                </div>
              </div>

              <div class="row clearfix flex">
                <div class="icons-col">
                  <mat-icon class="fab fa-instagram"></mat-icon>
                </div>
                <div class="row-content-col">
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Instagram</mat-label>
                    <input [(ngModel)]="dentist['Instagram']" [disabled]="disableEditPractice" name="instagram" matInput
                           placeholder="Instagram"/>
                  </mat-form-field>
                </div>
              </div>

              <div class="row clearfix flex">
                <div class="icons-col">
                  <mat-icon class="fab fa-twitter"></mat-icon>
                </div>
                <div class="row-content-col">
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Twitter</mat-label>
                    <input [(ngModel)]="dentist['Twitter']" [disabled]="disableEditPractice" name="twitter" matInput
                           placeholder="Twitter"/>
                  </mat-form-field>
                </div>
              </div>

              <div class="row clearfix flex">
                <div class="icons-col">
                  <mat-icon class="fab fa-linkedin"></mat-icon>
                </div>
                <div class="row-content-col">
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>LinkedIn</mat-label>
                    <input [(ngModel)]="dentist['LinkedIn']" [disabled]="disableEditPractice" name="linkedin" matInput
                           placeholder="LinkedIn"/>
                  </mat-form-field>
                </div>
              </div>

              <div class="row clearfix flex">
                <div class="icons-col">
                  <mat-icon class="fab fa-pinterest"></mat-icon>
                </div>
                <div class="row-content-col">
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Pinterest</mat-label>
                    <input [(ngModel)]="dentist['Pinterest']" [disabled]="disableEditPractice" name="Pinterest" matInput
                           placeholder="Pinterest"/>
                  </mat-form-field>
                </div>
              </div>

              <div class="row clearfix flex">
                <div class="icons-col">
                  <mat-icon class="fab fa-tumblr"></mat-icon>
                </div>
                <div class="row-content-col">
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Tumblr</mat-label>
                    <input [(ngModel)]="dentist['Tumblr']" [disabled]="disableEditPractice" name="Tumblr" matInput
                           placeholder="Tumblr"/>
                  </mat-form-field>
                </div>
              </div>

              <div class="row clearfix flex">
                <div class="icons-col">
                  <mat-icon class="fab fa-vimeo"></mat-icon>
                </div>
                <div class="row-content-col">
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Vimeo</mat-label>
                    <input [(ngModel)]="dentist['Vimeo']" [disabled]="disableEditPractice" name="Vimeo" matInput
                           placeholder="Vimeo"/>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </mat-card>

          <mat-card class="card full-width third">
            <div class="colour-stripe"></div>
            <h3 class="sr-title rm-m sm-h3">
              Contact Details
            </h3>
            <hr class="mb"/>
            <div class="row clearfix">
              <div class="row clearfix">
                <app-phone-input-advanced [type]="'contact'" [targetID]="dentist['ID']"
                                          [targetPhoneType]="dentist['phones.Primary']"
                                          [phoneTypes]="dentist['phones.Labels'] | splitText: ';'"
                                          [myPhoneNumber]="dentist['phones.Number']" [isDisabled]="disableEditPractice">
                </app-phone-input-advanced>
              </div>
              <div class="row clearfix">
                <app-mobile-input-advanced [type]="'contact'" [targetID]="dentist['ID']"
                  [targetMobileType]="dentist['mobiles.Primary']"
                                           [mobileTypes]="dentist['mobiles.Labels'] | splitText: ';'"
                                           [myMobile]="dentist['mobiles.Number']"
                                           [isDisabled]="disableEditPractice">
                </app-mobile-input-advanced>
              </div>
              <div class="row clearfix">
                <app-email-input-advanced [type]="'contact'" [targetID]="dentist['ID']"
                                          [targetEmailType]="dentist['emails.Primary']"
                                          [emailTypes]="dentist['emails.Labels'] | splitText: ';'"
                                          [myEmail]="dentist['emails.Email']" [isDisabled]="disableEditPractice">
                </app-email-input-advanced>
              </div>
            </div>

            <h3 class="sr-title rm-m mt sm-h3">Preferences</h3>
            <hr class="mb"/>
            <div class="row clearfix">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Contact Method</mat-label>
                <mat-select [(ngModel)]="dentist['PreferredContactMethod.Code']" [disabled]="disableEditPractice"
                            [ngClass]="{ removeArrow: disableEditPractice == true }" name="contactMethod"
                            placeholder="Contact Method">
                  <mat-option *ngFor="let contactMethod of preferredContactMethodOptions" [value]="contactMethod.Code">
                    {{ contactMethod.Label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Contact Time</mat-label>
                <mat-select [(ngModel)]="dentist['PreferredContactTime.Code']" [disabled]="disableEditPractice"
                            [ngClass]="{ removeArrow: disableEditPractice == true }" name="contactTime"
                            placeholder="Contact Time">
                  <mat-option *ngFor="let contactTime of preferredContactTimeOptions" [value]="contactTime.Code">
                    {{ contactTime.Label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Message Type</mat-label>
                <mat-select [(ngModel)]="dentist['PreferredMessageType.Code']" [disabled]="disableEditPractice"
                            [ngClass]="{ removeArrow: disableEditPractice == true }" name="messageType"
                            placeholder="Message Type">
                  <mat-option *ngFor="let messageType of preferredMessageTypeOptions" [value]="messageType.Code">
                    {{ messageType.Label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <div class="row clearfix">
                <hr/>

                <div class="row clearfix flex">
                  <mat-checkbox class="full-width mt" [(ngModel)]="marketingConsent" [disableRipple]="true"
                                [labelPosition]="'end'" [disabled]="disableEditPractice"
                                (click)="$event.preventDefault()"
                                name="electronic">
                    Consents to Marketing Material
                  </mat-checkbox>
                  <p class="full-width consentDate" *ngIf="dentist['MarketingConsent.Given'] | binaryBool">
                    {{ dentist['MarketingConsent.When'] | customDateTime }}
                  </p>
                </div>
              </div>
            </div>
          </mat-card>

          <mat-card class="card full-width">
            <div class="colour-stripe"></div>
            <h3 class="sr-title rm-m sm-h3">
              Address details
            </h3>
            <hr class="mb"/>
            <div class="row clearfix">
              <app-address-input-advanced [type]="'contact'" [targetID]="dentist['ID']"
                [targetAddressType]="dentist['addresses.Primary']"
                [addressTypes]="dentist['addresses.Labels'] | splitText: ';'"
                                          [myPostCode]="dentist['addresses.Postcode']"
                                          [myState]="dentist['addresses.State']"
                                          [mySuburb]="dentist['addresses.Suburb']"
                                          [myStreetNumber]="dentist['addresses.Street Nr']"
                                          [myStreetName]="dentist['addresses.Street Name']"
                                          [myUnitNumber]="dentist['addresses.Unit']"
                                          [myStreetType]="dentist['addresses.Street Type']"
                                          [myLatitude]="dentist['Address.Latitude']"
                                          [myLongitude]="dentist['Address.Longitude']"
                                          [isDisabled]="disableEditPractice">
              </app-address-input-advanced>
            </div>
          </mat-card>

          <mat-card class="card half-width passchange" *ngIf="dentistID === currentUser.contactKey">
            <div class="colour-stripe"></div>
            <h3 class="sr-title rm-m sm-h3">
              Login Details
            </h3>
            <hr class="mb"/>

            <div class="row clearfix">
              <app-password-change></app-password-change>
            </div>
          </mat-card>

          <mat-card class="card half-width passchange" *ngIf="dentistID === currentUser.contactKey">
            <div class="colour-stripe"></div>
            <h3 class="sr-title rm-m sm-h3">Connect social media</h3>
            <p class="small">Update your Social Media link to be able to use it to Login to the application.</p>

            <hr class="mb"/>
            <div class="row clearfix text-center mt">
              <app-change-social-media></app-change-social-media>
            </div>
          </mat-card>

          <mat-card class="card full-width third" *ngIf="context!='p4p' && context!='breeze' ">
            <div class="colour-stripe"></div>

            <div style="display: flex; justify-content: space-between">
              <h3 class="sr-title rm-m sm-h3">{{"PHRASE.dentistry practiced" | translate | titlecase}} </h3>
              <div>
                <button (click)="openAddContactDentistryTypesProperty(dentist)" mat-icon-button
                        style="display: block; height: 20px; line-height: 20px">
                  <mat-icon> edit_mode</mat-icon>
                </button>
              </div>
            </div>
            <hr class="mb"/>

            <mat-chip-list>
              <mat-chip *ngFor="let contactProfileDentistryType of contactProfileDentistryTypes" [color]="'primary'"
                        selected>{{ contactProfileDentistryType['Label'] }}</mat-chip>
            </mat-chip-list>
          </mat-card>
        </div>

        <div class="row clearfix full-width">
          <button class="pull-left" *ngIf="hideSide == false" (click)="location.back()" mat-raised-button
                  color="primary">
            <mat-icon>chevron_left</mat-icon>
            Back
          </button>

          <button class="pull-right" *ngIf="disableEditPractice == false" [disabled]="!dentistDetails.form.valid"
                  (click)="editPractice(dentist)" mat-raised-button color="accent">
            <mat-icon>save</mat-icon>
            Save
          </button>
        </div>
      </form>
    </div>

    <div class="tab-content clearfix" *ngIf="settings == 'message' && isPromoterOrAdmin == true">
      <app-message-timeline class="full-width" [cardID]="dentist['ID']"></app-message-timeline>

      <div class="row clearfix mt">
        <button class="" *ngIf="hideSide == false" (click)="location.back()" mat-raised-button color="primary">
          <mat-icon>chevron_left</mat-icon>
          Back
        </button>
      </div>
    </div>

    <div class="tab-content clearfix" *ngIf="settings == 'document' && isPromoterOrAdmin == true">
      <app-document-list-mini class="full-width" [cardID]="dentist['ID']"></app-document-list-mini>

      <div class="row clearfix mt">
        <button class="" *ngIf="hideSide == false" (click)="location.back()" mat-raised-button color="primary">
          <mat-icon>chevron_left</mat-icon>
          Back
        </button>
      </div>
    </div>
  </div>
</mat-sidenav-container>
