import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dentistry-types-overview',
  templateUrl: './dentistry-types-overview.component.html',
  styleUrls: ['./dentistry-types-overview.component.css'],
})
export class DentistryTypesOverviewComponent implements OnInit {
  tip: any;
  constructor() {}

  ngOnInit() {}

  createTip(tip) {
    this.tip = tip;
  }
}
