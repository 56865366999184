import { DataSource } from '@angular/cdk/collections';
import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Optional, Output, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import * as moment from 'moment';
import { BehaviorSubject, merge as observableMerge, Observable } from 'rxjs';
import 'rxjs/add/observable/merge';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/startWith';
import { map } from 'rxjs/operators';
import { OperatorViewComponent } from '../../../feature/operator/operator-view/operator-view.component';
import { OperatorService } from '../../../feature/operator/shared/operator.service';
import { MapViewComponent } from '../../../shared/components/map-view/map-view.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { CustomDatePipe } from '../../../shared/pipes/custom-date.pipe';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { CustomDateTimePipe } from '../../authentication/shared/custom-date-time.pipe';
import { LogViewComponent } from '../log-view/log-view.component';
import { LogService } from '../shared/log.service';

@Component({
  selector: 'app-log-user-list',
  templateUrl: './log-user-list.component.html',
  styleUrls: ['./log-user-list.component.css'],
  providers: [CustomDatePipe],
})
export class LogUserListComponent implements OnInit {
  @Input() userID;

  @Output()
  close: EventEmitter<any> = new EventEmitter();

  @Input()
  pageSize = 30;
  maxLogNumber = 2000;
  pageSizeOptions = [10, 30, 50, 100];
  dateFromFilter;
  dateToFilter;
  dateTo = new Date();
  _date = new Date();

  utils = new UtilsClass();
  displayedColumns = [
    'geolocation',
    'date',
    'module',
    'method',
    'type',
    'responseStatus',
    'status',
    'message',
    'browser',
    'os',
    'Actions',
  ];

  filters = [];
  selectedValue = '';
  selectedModule = '';
  selectedResponseStatus = '';
  selectedStatus = '';
  selectedRole = '';
  selectedField: any = {
    field: '',
    table: '',
    category: '',
    value: '',
  };

  filteredSize = null;
  public listDB: LoadRecords | null;
  dataSource: listDataSource | null;
  showFirstFilter = false;
  showSecondFilter = false;
  showDateFilter = false;
  showInput = true;
  session;
  isModal = false;

  destroyEvent = new EventEmitter();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('filter', { static: false }) filter: ElementRef;

  constructor(
    private logService: LogService,
    private customDate: CustomDatePipe,
    private customDateTime: CustomDateTimePipe,
    private OperatorService: OperatorService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    private data
  ) {
    if (data) {
      this.userID = data;
      this.isModal = true;
    }
  }

  ngOnInit() {
    this.OperatorService.getfullOperatorDetails(this.userID).subscribe((res) => {
      this.session = res;
    });

    const payloadRecord = {
      Voided: 0,
      user_id: this.userID,
      limit: 300,
      section: 0,
      fields: 'ID,date,module,method.type,status,success,message,user,role,os,browser,lat,lng',
    };
    const n = Number(Number(this.maxLogNumber) / 500);
    const maxSection = parseInt(String(n));

    this.listDB = new LoadRecords(this.logService, this.destroyEvent, payloadRecord, maxSection);
    this.dataSource = new listDataSource(this.listDB, this.paginator, this.sort);
  }

  closeEvent() {
    this.close.emit(true);
  }

  displayInfo() {
    const ref = RootAppComponent.dialog.open(OperatorViewComponent, {
      width: '600px',
      data: this.userID,
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  setFilter(event, field) {
    let filter;
    if (typeof event == 'object' && event != null) {
      const v = this.customDate.transform(event, Settings.global['dateFormat']);
      filter = {
        field,
        value: v,
      };
    } else if (event) {
      filter = {
        field,
        value: event,
      };
    } else {
      filter = {
        field,
        value: '',
      };
    }

    this.dataSource.filter = filter;
    this.filteredSize = this.dataSource.filteredData.length;
  }

  selectDateFromFilter(dateFrom) {
    this.dateFromFilter = dateFrom;
  }

  selectDateToFilter(dateTo) {
    this.dateToFilter = dateTo;
  }

  filterLogs() {
    let d1 = null;
    if (this.dateFromFilter) {
      d1 = this.utils.EPdateFormat(this.dateFromFilter);
    }

    let d2 = null;
    if (this.dateToFilter) {
      d2 = this.utils.EPdateFormat(this.dateToFilter);
    }

    const payloadRecord = {
      Voided: 0,
      dateMin: d1,
      dateMax: d2,
      user_id: this.userID,
      limit: 300,
      section: 0,
      fields: 'ID,date,module,method.type,status,success,message,user,role,os,browser,lat,lng',
    };
    const n = Number(Number(this.maxLogNumber) / 500);
    const maxSection = parseInt(String(n));

    this.listDB = new LoadRecords(this.logService, this.destroyEvent, payloadRecord, maxSection);
    this.dataSource = new listDataSource(this.listDB, this.paginator, this.sort);
  }

  displayUserInfo(id) {
    const ref = RootAppComponent.dialog.open(OperatorViewComponent, {
      data: id,
      height: '600px',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  displayLog(logObject) {
    const ref = RootAppComponent.dialog.open(LogViewComponent, {
      data: logObject,
    });
  }

  displayMap(user) {
    if (user) {
      const geolocation: any = {
        Latitude: null,
        Longitude: null,
        moreInfo: null,
      };
      if (user) {
        geolocation.moreInfo = this.moreInfoTohtml(user);
      }
      if (user.lat) {
        geolocation.Latitude = user.lat;
      }
      if (user.lng) {
        geolocation.Longitude = user.lng;
      }

      const ref = RootAppComponent.dialog.open(MapViewComponent, {
        data: {
          positions: [geolocation],
        },

        panelClass: 'modal-map',
        width: '800px',
        height: '600px',
      });

      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    }
  }

  moreInfoTohtml(moreInfo) {
    let html = '';
    if (moreInfo) {
      if (moreInfo.date) {
        html = html + '<strong>- Date: </strong>' + this.customDateTime.transform(moreInfo.date) + '<br>';
      }
      if (moreInfo.browser) {
        html = html + '<strong>- Browser: </strong>' + moreInfo.browser + '<br>';
      }
      if (moreInfo.os) {
        html = html + '<br><strong>- Operating system: </strong>' + moreInfo.os + '<br>';
      }
      return html;
    } else {
      return null;
    }
  }

  displayUserLocation(location) {
    const geolocation = [
      {
        Latitude: location.split('/')[0],
        Longitude: location.split('/')[1],
      },
    ];

    const ref = RootAppComponent.dialog.open(MapViewComponent, {
      data: {
        positions: geolocation,
      },

      panelClass: 'modal-map',
      width: '800px',
      height: '600px',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  selectDateFrom(dateFrom) {
    this.dateFromFilter = dateFrom;
  }

  selectDateTo(dateTo) {
    this.dateToFilter = dateTo;
  }

  ngOnDestroy() {
    this.destroyEvent.emit(true);
  }
}

export class LoadRecords implements OnInit {
  public dataChange: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public records: any[];
  public count: any;
  maxSection = 20;
  isDestoyed = false;
  payload;

  get data(): any[] {
    return this.dataChange.value;
  }

  firstLoad = false;

  get firstLoadEvent() {
    return this.firstLoad;
  }

  serviceRef;

  constructor(private logService: LogService, private destroyEvent, payload, maxSection = 20) {
    this.payload = payload;
    this.maxSection = maxSection;

    this.destroyEvent.subscribe((res) => {
      this.isDestoyed = res;
    });

    this.logService.getLogs(this.payload).subscribe((res) => {
      this.firstLoad = true;
      if (res.success) {
        this.count = res.count;
        this.records = res.data;
        this.dataChange.next(this.records.slice());
        if (this.isDestoyed != true) {
          this.innerFunction(1);
        }
      }
    });
  }

  innerFunction = (section) => {
    section = section + 1;
    this.payload.section = section;

    if (section > this.maxSection) {
      return true;
    } else {
      this.serviceRef = this.logService.getLogs(this.payload).subscribe((res) => {
        if (res.success) {
          if (res.data.length > 0) {
            this.count = res.count;
            this.records = this.records.concat(res.data);

            this.dataChange.next(this.records.slice());
            if (this.isDestoyed != true) {
              this.innerFunction(section);
            }
          } else {
            return true;
          }
        } else {
          return false;
        }
      });
    }
    UtilsClass.stopLoading();
  };

  ngOnInit() {}

  ngOnDestroy() {
    if (this.serviceRef) {
      this.serviceRef.unsubscribe();
    }
  }
}

export class listDataSource extends DataSource<any> {
  _filterChange = new BehaviorSubject('');
  field = '';

  get filter(): any {
    return this._filterChange.value;
  }

  set filter(item: any) {
    this.field = item.field;
    this._filterChange.next(item.value);
  }

  filteredData: any[] = [];
  utils = new UtilsClass();

  constructor(private _listDatabase: any, private _paginator: MatPaginator, private _sort: MatSort) {
    super();
    this._filterChange.subscribe(() => (this._paginator.pageIndex = 0));
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<any[]> {
    const displayDataChanges = [
      this._listDatabase.dataChange,
      this._filterChange,
      this._paginator.page,
      this._sort.sortChange,
    ];

    return observableMerge(...displayDataChanges).pipe(
      map(() => {
        // Filter data
        this.filteredData = this._listDatabase.data.slice().filter((item: any) => {
          let f = '';
          if (this.field === 'date') {
            f = item[this.field] || '';
            const dateA = moment(f).format('DD-MM-YYYY');
            const dateB = moment(this.filter).format('DD-MM-YYYY');
            return dateA === dateB || this.filter === '';
          } else if (this.field === 'user.session-type') {
            f = item.user['session-type'] || '';
            const searchStr = f.toLowerCase();
            return searchStr.indexOf(this.filter.toLowerCase()) != -1;
          } else if (this.field === 'responseStatus') {
            f = item['status'] || '';
            return f === this.filter || f === '';
          } else if (this.field === 'status') {
            if (this.filter === '') {
              return true;
            } else if (this.filter === 200) {
              if (item && item.response) {
                return !item.response.Error;
              }

              return false;
            } else {
              f = '';
              if (item && item.response && item.response.Error && item.response.Error.code) {
                f = item.response.Error.code;
              }

              return f === this.filter;
            }
          } else {
            f = item[this.field] || '';
            const searchStr = f.toLowerCase();
            return searchStr.indexOf(this.filter.toLowerCase()) != -1;
          }
        });
        const data = this.getSortedData(this.filteredData.slice());
        // Grab the page's slice of data.
        const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
        return data.splice(startIndex, this._paginator.pageSize);
      })
    );
  }

  disconnect() {}

  /** Returns a sorted copy of the database data. */
  getSortedData(data: any[]): any[] {
    // const data = data;
    if (!this._sort.active || this._sort.direction === '') {
      return data;
    }

    return data.sort((a, b) => {
      let propertyA: number | string = '';
      let propertyB: number | string = '';

      switch (this._sort.active) {
        case 'date':
          [propertyA, propertyB] = [moment(a['date']).toDate().getTime(), moment(b['date']).toDate().getTime()];
          break;
        case 'module':
          [propertyA, propertyB] = [a['baseUrl'], b['baseUrl']];
          break;
        case 'method':
          [propertyA, propertyB] = [a['url'], b['url']];
          break;
        case 'user.session-type':
          [propertyA, propertyB] = [a.user['session-type'], b.user['session-type']];
          break;
        case 'responseStatus':
          [propertyA, propertyB] = [a['status'], b['status']];
          break;
        case 'status':
          const labelA = this.utils.errorLabel(a).toLowerCase();
          const labelB = this.utils.errorLabel(b).toLowerCase();
          [propertyA, propertyB] = [labelA, labelB];
          break;
        case 'message':
          const valA = this.utils.getMessage(a).toLowerCase();
          const valB = this.utils.getMessage(b).toLowerCase();
          [propertyA, propertyB] = [valA, valB];
          break;
      }

      const valueA = isNaN(+propertyA) || propertyA === '' ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) || propertyB === '' ? propertyB : +propertyB;

      return (valueA < valueB ? -1 : 1) * (this._sort.direction === 'asc' ? 1 : -1);
    });
  }
}
