import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSortModule } from '@angular/material/sort';
import { ArchwizardModule } from 'angular-archwizard';
import { EmailEditorModule } from 'angular-email-editor';
import { MonacoEditorModule, NgxMonacoEditorConfig } from 'ngx-monaco-editor';
import { NgPipesModule } from 'ngx-pipes';
import { SharedModule } from '../../shared/shared.module';
import { ContactModule } from '../contact/contact.module';
import { ExistingPatientModule } from '../existing-patient/existing-patient.module';
import { MerchantModule } from '../merchant/merchant.module';
import { OperatorModule } from '../operator/operator.module';
import { TagsModule } from '../tags/tags.module';
import { PhysicalTemplateListComponent } from './physical-template-list/physical-template-list.component';
import { TemplateRoutingModule } from './shared/template-routing.module';
import { TemplateService } from './shared/template-service';
import { TemplateCreateEditComponent } from './template-create-edit/template-create-edit.component';
import { TemplateListOverviewComponent } from './template-list-overview/template-list-overview.component';
import { TemplateListComponent } from './template-list/template-list.component';
import { TemplatePictureViewComponent } from './template-picture-view/template-picture-view.component';
import { TemplatePurposeCreateEditComponent } from './template-purpose-create-edit/template-purpose-create-edit.component';
import { TemplatePurposeListComponent } from './template-purpose-list/template-purpose-list.component';

const monacoConfig: NgxMonacoEditorConfig = {
  baseUrl: '/assets',
  defaultOptions: { scrollBeyondLastLine: false },
  // onMonacoLoad: () => { console.log((<any>window).monaco); }
};

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MerchantModule,
    ExistingPatientModule,
    ReactiveFormsModule,
    CdkTableModule,
    TemplateRoutingModule,
    SharedModule,
    ContactModule,
    MatSortModule,
    OperatorModule,
    ArchwizardModule,
    NgPipesModule,
    EmailEditorModule,
    TagsModule,
    MonacoEditorModule.forRoot(monacoConfig),
  ],
  declarations: [
    TemplateListOverviewComponent,
    TemplateListComponent,
    TemplateCreateEditComponent,
    PhysicalTemplateListComponent,
    TemplatePictureViewComponent,
    TemplatePurposeCreateEditComponent,
    TemplatePurposeListComponent,
  ],
  exports: [
    TemplateListOverviewComponent,
    TemplateListComponent,
    TemplateCreateEditComponent,
    PhysicalTemplateListComponent,
    TemplatePictureViewComponent,
    TemplatePurposeCreateEditComponent,
    TemplatePurposeListComponent,
  ],
  providers: [TemplateService],
})
export class TemplateModule {}
