import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { transition, trigger, useAnimation } from '@angular/animations';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ngIfRowInAnimation, ngIfRowOutAnimation } from '../../../shared/animations/ngIf/ngIfRow.animation';
import { DefaultMessageService } from '../shared/default-message.service';
import { DefaultMessageUtilClass } from '../shared/defaultMessageUtil';

const animations = [
  trigger('ngIfAnimation', [
    transition('void => *', [useAnimation(ngIfRowInAnimation)]),
    transition('* => void', [useAnimation(ngIfRowOutAnimation)]),
  ]),
];

@Component({
  selector: 'app-default-message-view',
  templateUrl: './default-message-view.component.html',
  styleUrls: ['./default-message-view.component.css'],
  animations,
})
export class DefaultMessageViewComponent implements OnInit {
  @Output()
  closeModal = new EventEmitter();

  @Input() messageID;

  defaultMessageUtilClass = new DefaultMessageUtilClass();

  isModal = false;

  defaultMessage;
  isPromoterOrAdmin = false;
  sessionType;

  card: any = {
    ID: '00000000000000000000',
    Salutation: 'Mr',
    FirstName: 'John',
    MiddleName: 'Al',
    Name: 'Doe',
    'mobiles.Number': '0061101010101',
    'phones.Number': '0062202020202',
    'emails.Email': 'john.doe@smileright.com.au',
    'addresses.Calculated': 'PO Box 7795 Cloisters Square 6850 Western Australia',
  };
  merchant;
  promoter;
  contact;

  isLoaded = false;

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    data,
    private defaultMessageService: DefaultMessageService,
    private authenticationService: AuthenticationService
  ) {
    if (data) {
      this.isModal = true;

      if (data.messageID) {
        this.messageID = data.messageID;
      }

      if (data.merchant) {
        this.merchant = data.merchant;
      }
      if (data.promoter) {
        this.promoter = data.promoter;
      }
      if (data.contact) {
        this.contact = data.contact;
      }
      if (data.card) {
        this.card = data.card;
      }
    }
  }

  ngOnInit() {
    if (this.card && this.card.ID === '00000000000000000000') {
      this.authenticationService.getCurrentDentist().subscribe((r) => {
        if (r && r['Gender.Code'] && r['Gender.Code'] === 'F') {
          this.card.FirstName = 'Jane';
          this.card['emails.Email'] = 'jane.doe@smileright.com.au';
          if (r && r['Salutation']) {
            this.card['Salutation'] = r['Salutation'];
          } else {
            this.card['Salutation'] = 'Miss';
          }
        } else if (r && r['Salutation']) {
          this.card['Salutation'] = r['Salutation'];
        }

        this.setup();
      });
    } else {
      this.setup();
    }
  }

  setup() {
    this.isLoaded = false;
    this.authenticationService.getSessionType().subscribe((sessionType) => {
      this.sessionType = sessionType;
      this.authenticationService.isPromoterOrAdmin().subscribe((isPromoterOrAdmin) => {
        this.isPromoterOrAdmin = isPromoterOrAdmin;
        if (this.messageID) {
          this.defaultMessageService.getOneDefaultMessage(this.messageID, this.sessionType).subscribe((r) => {
            if (r) {
              this.defaultMessage = r;
            }

            this.isLoaded = true;
          });
        }
      });
    });
  }

  closeClicked() {
    this.closeModal.emit(true);
  }
}
