<div class="row clearfix defineMessage">
  <!-- blue heading -->
  <div class="card-header primary-gradient-img clearfix" *ngIf="isModal == true">
    <div class="row clearfix">
      <!-- Modal dismiss button -->
      <button class="btn-close btn-clear pull-right mat-button" (click)="closeEvent()">
        <mat-icon>clear</mat-icon>
      </button>
      <!-- merchant calculated name -->
      <div class="row titleArea clearfix">
        <mat-icon class="fas fa-bullhorn pull-left"></mat-icon>
        <h2 class="message-title summary-header rm-m">
          <span>Create New Message</span>
          <span class="subLabel" *ngIf="patientObject"
          >Contact {{ patientObject['CalculatedName'] }} via mobile
            {{ patientObject['mobiles.Number'] | customPhone }}</span
          >
        </h2>
      </div>
    </div>
  </div>
  <!-- end blue heading -->

  <h4 class="sm-h4 sr-title" *ngIf="isModal == false">Create new Message</h4>

  <div class="row clearfix">
    <mat-button-toggle-group
      class="btn-group text-center full-width with-icons"
      #group="matButtonToggleGroup"
      [(ngModel)]="option"
    >
      <mat-button-toggle class="text-center full-width" [value]="'setup'">
        <mat-icon class="fas fa-user"></mat-icon> Setup
      </mat-button-toggle>
      <mat-button-toggle class="text-center full-width" [value]="'mobile'">
        <mat-icon class="fas fa-eye"></mat-icon> Mobile Review
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>

  <div class="create-well">
    <form #msgForm="ngForm">
      <!--  mat dialog scrolling area -->
      <mat-dialog-content>
        <!-- half width flexy cols -->

        <div class="row clearfix" *ngIf="option == 'setup'">
          <div class="row clearfix" *ngIf="messageTemplates.length > 0">
            <div class="icons-col">
              <mat-icon class="fas fa-list-ul"></mat-icon>
            </div>
            <div class="row-content-col">
              <h3 class="sr-title sm-h3 rm-m mt">Choose From Template</h3>
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Choose a template</mat-label>
                <mat-select
                  [(ngModel)]="selectedTemplateMsg"
                  (selectionChange)="getTemplateMessage()"
                  name="template"
                  placeholder="Choose a template"
                >
                  <mat-option *ngFor="let m of messageTemplates" [value]="m">{{ m.Label }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="row clearfix">
            <div class="icons-col">
              <mat-icon class="fas fa-tags"></mat-icon>
            </div>
            <div class="row-content-col">
              <h3 class="sr-title sm-h3 rm-m mt">Message Title</h3>
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Please enter a label for this message</mat-label>
                <input
                  class="mat-block"
                  [(ngModel)]="label"
                  matInput
                  name="label"
                  placeholder="Please enter a label for this message"
                  required
                />
              </mat-form-field>
            </div>
          </div>
          <!-- message input area -->
          <div class="row clearfix">
            <div class="icons-col">
              <mat-icon class="fas fa-edit"></mat-icon>
            </div>
            <div class="row-content-col">
              <div class="row clearfix">
                <h3 class="sr-title sm-h3 rm-m mt">Message Body</h3>
                <div>
                  <mat-form-field class="full-width messageContent" appearance="outline">
                    <mat-label>Enter your message</mat-label>
                    <textarea
                      class="dentMsg increase-input-field"
                      #messageBodyID
                      [(ngModel)]="textMessage"
                      (ngModelChange)="getContentLength()"
                      (keyup)="getcursorPos()"
                      (mouseup)="getcursorPos()"
                      name="textMsg"
                      matInput
                      placeholder="Message Body Enter"
                      cols="30"
                      rows="50"
                      required
                    ></textarea>
                  </mat-form-field>
                </div>
              </div>
              <!-- insert personalisation -->
              <div class="row clearfix">
                <button class="pull-left" [matMenuTriggerFor]="persMenu" mat-button color="primary">
                  <mat-icon class="fas fa-share"></mat-icon> Insert personalisation
                </button>
                <!-- patient personalisation message -->
                <mat-menu #persMenu="matMenu">
                  <button [matMenuTriggerFor]="patientPersMenu" mat-menu-item>
                    <mat-icon>portrait</mat-icon>
                    {{ "KEYWORD.patient" | translate | titlecase }}
                  </button>
                  <mat-menu #patientPersMenu="matMenu">
                    <div *ngFor="let p of utilMessage.getPatientPersonalisation()">
                      <button (click)="addPersonalisation(p)" mat-menu-item>
                        {{ p.id }}
                      </button>
                    </div>
                  </mat-menu>


                  <button [matMenuTriggerFor]="dentistPersMenu" mat-menu-item>
                    <mat-icon>portrait</mat-icon>
                    {{ "KEYWORD.practitioner" | translate | titlecase }}
                  </button>
                  <mat-menu #dentistPersMenu="matMenu">
                    <div *ngFor="let p of utilMessage.getDentistPersonalisation()">
                      <button (click)="addPersonalisation(p)" mat-menu-item>
                        {{ p.id }}
                      </button>
                    </div>
                  </mat-menu>

                  <!-- practice personalisation message -->
                  <button [matMenuTriggerFor]="practicePersMenu" mat-menu-item>
                    <mat-icon>portrait</mat-icon>
                    {{ "KEYWORD.practice" | translate | titlecase}}
                  </button>
                  <mat-menu #practicePersMenu="matMenu">
                    <div *ngFor="let p of utilMessage.getMerchantPersonalisation()">
                      <button (click)="addPersonalisation(p)" mat-menu-item>
                        {{ p.id }}
                      </button>
                    </div>
                  </mat-menu>
                </mat-menu>
                <p class="small pull-right mr" [ngStyle]="{ color: textMessageCounter > 918 ? 'red' : '' }">
                  parts: {{ textMessagePart }}/6
                </p>

                <p class="small pull-right mr" [ngStyle]="{ color: textMessageCounter > 918 ? 'red' : '' }">
                  Total Characters count: {{ textMessageCounter }}/918
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="row clearfix" *ngIf="option == 'mobile'">
          <mat-dialog-content>
            <div class="marvel-device iphone8">
              <div class="top-bar"></div>
              <div class="sleep"></div>
              <div class="volume"></div>
              <div class="camera"></div>
              <div class="sensor"></div>
              <div class="speaker"></div>
              <div class="screen">
                <span class="messages">
                  <p class="speech-bubble preview" [innerHTML]="_textMessage | safeHtml"></p>
                </span>
              </div>

              <div class="home"></div>
              <div class="bottom-bar"></div>
            </div>
          </mat-dialog-content>
        </div>
      </mat-dialog-content>
      <!-- fixed action buttons -->
      <mat-dialog-actions>
        <div class="row clearfix">
          <button
            class=""
            [disabled]="!msgForm.form.valid"
            (click)="saveAsTemplate()"
            mat-raised-button
            color="primary"
          >
            Save as template
            <mat-icon>save</mat-icon>
          </button>

          <button
            class="pull-right"
            [disabled]="!msgForm.form.valid"
            (click)="sendMessage()"
            mat-raised-button
            color="accent"
          >
            Send message
            <mat-icon>check</mat-icon>
          </button>
        </div>
      </mat-dialog-actions>
    </form>
  </div>
</div>
