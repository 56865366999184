import { BehaviorSubject, merge as observableMerge, Observable } from 'rxjs';

import { map } from 'rxjs/operators';

import { DataSource } from '@angular/cdk/table';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { Settings } from '../../../shared/types/settings';
import { InvitationViewModalComponent } from '../../invitation/invitation-view-modal/invitation-view-modal.component';
import { ProductViewModalComponent } from '../../product/product-view-modal/product-view-modal.component';
import { ContractViewSimpleComponent } from '../contract-view-simple/contract-view-simple.component';
import * as _ from 'lodash';
import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { CustomDatePipe } from '../../../shared/pipes/custom-date.pipe';
import { SideMenuService } from '../../../shared/services/side-menu.service';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { EventLogListComponent } from '../../action-log/event-log-list/event-log-list.component';
import { MessageTimelineComponent, MessageType } from '../../message/message-timeline/message-timeline.component';
import { TextMessageCreateComponent } from '../../message/text-message-create/text-message-create.component';
import { NotesModal, NotesModalConfig } from '../../notes/modals/notes-modal/notes.modal';
import { ContractService } from '../shared/contract.service';
import { UtilsService } from '../../../shared/services/utils.service';
import { SmsPromoterComponent } from '../../message/sms-promoter/sms-promoter.component';
import { SmsConversationComponent } from '../../message/sms-conversation/sms-conversation.component';
import { CustomerProspectViewModelComponent } from '../../customer-prospect/customer-prospect-view-model/customer-prospect-view-model.component';
import { DentistViewComponent } from '../../dentist/dentist-view/dentist-view.component';
import { MerchantViewComponent } from '../../merchant/merchant-view/merchant-view.component';
import { CustomPhonePipe } from '../../../shared/pipes/custom-phone.pipe';
import { WakandaFilesComponent } from '../../invitation/wakanda-files-view/wakanda-files-view.component';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-contract-list-global',
  templateUrl: './contract-list-global.component.html',
  styleUrls: ['./contract-list-global.component.css'],
  providers: [CustomDatePipe],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.row', style({ opacity: 0 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.row', style({ opacity: 1 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({
                  opacity: 0,
                  transform: 'translateY(-75%)',
                  offset: 1.0,
                }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class ContractListGlobalComponent implements OnInit {
  @Input()
  approvedOrActive = null;

  @Input()
  dateFrom;

  @Input()
  dateTo;

  @Input()
  merchantID;

  @Input()
  maxRecords;
  @Input()
  customerID;

  @Input()
  partFirstName;

  @Input()
  partLastName;
  @Input()
  partMobile;
  @Input()
  email;
  @Input()
  statusCodes;
  @Input()
  quotationStatus;
  @Input()
  quotationStatusCodes;

  @Input()
  invitationID;
  @Input()
  invitationIDOrWakandaID;
  @Input()
  contractID;

  @Input()
  showDetailInList = false;

  @Input()
  status;

  @Output()
  createdSettlement = new EventEmitter();

  items = [
    'contract',
    'settlement',
    'invitation',
    'customer',
    'dentist',
    'merchant',
    'instantOffer',
    'appointment',
    'campaign',
    'landingPage',
    'note',
  ];

  @Output()
  myContractID = new EventEmitter();

  @Output()
  detailsView = new EventEmitter();

  public displayContractView = false;
  displayContractViewEP = false;
  displayPersonalCirView = false;
  displayCapacityCheck = false;
  displayCreditCheck = false;
  displayIdentityCheck = false;

  filtersLoaded = false;
  displayInvitationView = false;

  @Input()
  pageSize = Settings.global['listPageSize'] || 20;
  pageSizeOptions = [10, Number(this.pageSize), Number(this.pageSize) * 2, Number(this.pageSize) * 3];

  utils = new UtilsClass();
  displayedColumns = [
    'patientPicture',
    'CustomerName',
    'merchantPicture',
    'Merchant',
    'StartDate',
    'status',
    'status',
    'quotationStatus',
    'Product',
    // "FinishDate",
    'RepaymentAmount',
    // "AmountInvoice",
    // "Deposit",
    'Financed',
    'Balance',
    'ArrearsAmount',
    'ArrearsDay',
    'quotationStatus',
    'Actions',
  ];

  filters = [];

  searchVals = new FormControl();

  searchValList = ['Status', 'Customer FullName', 'Mobile', 'Email', 'Product Name', 'Loan Purpose'];

  public listDB: LoadRecords | null;
  dataSource: RecordDataSource | null;

  filteredSize = null;

  isPromoterOrAdmin = false;

  totalOriginalAmount: number;
  totalFinancedAmount: number;
  totalCurrentAmount: number;

  isModulePatientSMSActive = Settings.global['isModulePatientSMSActive'];
  isModuleNoteFeaturesActive = Settings.global['isModuleNoteFeaturesActive'];
  isModuleExperimentalFeaturesActive = Settings.global['isModuleExperimentalFeaturesActive'];
  isModuleTreatmentTemplateActive = Settings.global['isModuleTreatmentTemplateActive'];
  isModuleAppointmentActive = Settings.global['isModuleAppointmentActive'];

  isModulePatientPortalAccessActive = Settings.global['isModulePatientPortalAccessActive'];
  displaySettlmentList = false;
  sessionType;
  destroyEvent = new EventEmitter();
  merchantsFilter = [];
  statusCodesFilter = [];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('filter', { static: false }) filter: ElementRef;

  constructor(
    private utilsService: UtilsService,
    private contractService: ContractService,
    private dialog: MatDialog,
    private router: Router,
    private customDate: CustomDatePipe,
    private authenticationService: AuthenticationService,
    private customPhonePipe: CustomPhonePipe
  ) {}

  ngOnInit() {
    this.utilsService.getCurrentAccess().subscribe((access) => {
      if (access) {
        this.isModuleNoteFeaturesActive = access['isModuleNoteFeaturesActive'];
        this.isModuleTreatmentTemplateActive = access['isModuleTreatmentTemplateActive'];
        this.isModuleExperimentalFeaturesActive = access['isModuleExperimentalFeaturesActive'];
        this.isModuleAppointmentActive = access['isModuleAppointmentActive'];
        this.isModulePatientPortalAccessActive = access['isModulePatientPortalAccessActive'];
        this.isModulePatientSMSActive = access['isModulePatientSMSActive'];
      }
    });

    this.authenticationService.getSessionType().subscribe((s) => {
      this.sessionType = s;
    });
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;

      SideMenuService.goBack.subscribe((res) => {
        if (res == true) {
          this.backToContractList();
        }
      });
    });

    // const params = {
    //   merchantID: this.merchantID,
    //   dateFrom: this.dateFrom,
    //   dateTo: this.dateTo,
    //   customerID: this.customerID,
    //   status: this.status,

    //   maxRecords: this.maxRecords || null,

    //   statusCodes: this.statusCodes,
    //   quotationStatusCodes: this.quotationStatusCodes,
    //   quotationStatus: this.quotationStatus,
    //   partFirstName: this.partFirstName,
    //   partLastName: this.partLastName,
    //   partMobile: this.partMobile,
    //   email: this.email,
    //   invitationID: this.invitationID,
    //   invitationIDOrWakandaID: this.invitationIDOrWakandaID,
    //   contractID: this.contractID,
    // };

    // this.listDB = new LoadRecords(this.contractService, this.destroyEvent, params, this.customPhonePipe);
    // this.dataSource = new RecordDataSource(this.listDB, this.paginator, this.sort);

    // this.getDisplayedColumns();
  }

  ngOnChanges(changes: SimpleChanges) {
    const params = {
      merchantID: this.merchantID,
      dateFrom: this.dateFrom,
      dateTo: this.dateTo,
      customerID: this.customerID,
      status: this.status,

      maxRecords: this.maxRecords || null,

      statusCodes: this.statusCodes,
      quotationStatusCodes: this.quotationStatusCodes,
      quotationStatus: this.quotationStatus,
      partFirstName: this.partFirstName,
      partLastName: this.partLastName,
      partMobile: this.partMobile,
      email: this.email,
      invitationID: this.invitationID,
      invitationIDOrWakandaID: this.invitationIDOrWakandaID,
      contractID: this.contractID,
    };
    this.listDB = new LoadRecords(this.contractService, this.destroyEvent, params, this.customPhonePipe);
    this.dataSource = new RecordDataSource(this.listDB, this.paginator, this.sort);

    if (this.filtersLoaded == false) {
      this.filtersLoaded = true;
      this.listDB.getMerchants.subscribe((tags) => {
        this.merchantsFilter = JSON.parse(JSON.stringify(tags || []));
      });

      this.listDB.getStatusCodes.subscribe((tags) => {
        this.statusCodesFilter = JSON.parse(JSON.stringify(tags || []));
      });
    }

    this.getDisplayedColumns();
  }

  getDisplayedColumns() {
    this.displayedColumns = [
      'CustomerName',
      'Merchant',
      'StartDate',
      'status',
      'Product',
      'RepaymentAmount',
      'AmountInvoice',
      'Financed',
      'Deposit',
      'TimeElapsed',
      'quotationStatus',
      'Actions',
    ];
  }

  sumOriginalAmount() {
    this.totalOriginalAmount = 0;
    if (this.dataSource.filteredData) {
      for (let i = 0; i < this.dataSource.filteredData.length; i++) {
        if (this.dataSource.filteredData[i]['Amount.Original']) {
          this.totalOriginalAmount =
            this.totalOriginalAmount + Number(this.dataSource.filteredData[i]['Amount.Original']);
        }
      }
    }

    return this.totalOriginalAmount;
  }

  sumFinancedAmount() {
    this.totalFinancedAmount = 0;
    if (this.dataSource.filteredData) {
      for (let i = 0; i < this.dataSource.filteredData.length; i++) {
        if (this.dataSource.filteredData[i]['Amount.Financed']) {
          this.totalFinancedAmount =
            this.totalFinancedAmount + Number(this.dataSource.filteredData[i]['Amount.Financed']);
        }
      }
    }

    return this.totalFinancedAmount;
  }

  sumCurrentAmount() {
    this.totalCurrentAmount = 0;
    if (this.dataSource.filteredData) {
      for (let i = 0; i < this.dataSource.filteredData.length; i++) {
        if (this.dataSource.filteredData[i]['Amount.Outstanding']) {
          this.totalCurrentAmount =
            this.totalCurrentAmount + Number(this.dataSource.filteredData[i]['Amount.Outstanding']);
        }
      }
    }

    return this.totalCurrentAmount;
  }

  setFilter(event, field) {
    let filter;

    if (field == 'ApprovedOrActive') {
      let _event;
      if (event == true) {
        _event = '1';
      } else {
        _event = '0';
      }
      filter = {
        field,
        value: _event,
      };
    } else if (typeof event == 'object' && event != null) {
      const v = this.customDate.transform(event, Settings.global['dateFormat']);
      filter = {
        field,
        value: v,
      };
    } else if (event) {
      filter = {
        field,
        value: event,
      };
    } else {
      filter = {
        field,
        value: '',
      };
    }

    this.dataSource.filter = filter;
    this.filteredSize = this.dataSource.filteredData.length;
  }

  backToContractList() {
    this.contractID = null;

    this.displayContractView = false;
    this.displayContractViewEP = false;
    this.displayPersonalCirView = false;
    this.displayCapacityCheck = false;
    this.displayCreditCheck = false;
    this.displayIdentityCheck = false;
    this.displaySettlmentList = false;
    this.detailsView.emit(false);
  }

  openQuickViewDialog(ID) {
    const ref = this.dialog.open(ContractViewSimpleComponent, {
      data: ID,
      width: '800px',
    });

    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  openDetailContractDialog(ID) {
    this.contractID = ID;
    this.displayContractView = true;
    this.detailsView.emit(true);
  }

  openDetailsViewEP(ID, pID) {
    if (this.showDetailInList == true) {
      this.contractID = ID;
      // this.displayContractViewEP = true;
      // this.detailsView.emit(true);

      // emit contract id to parent
      this.myContractID.emit(ID);
    } else {
      const url = this.router.url;

      if (url.indexOf('promoter') != -1) {
        this.router.navigate([
          '/merchant',
          {
            outlets: { page: ['contract-overview-details', ID] },
            data: { title: 'Referred Application' },
          },
        ]);
      } else {
        this.router.navigate([
          '/merchant',
          {
            outlets: { page: ['contract-overview-details', ID] },
            data: { title: 'Referred Application' },
          },
        ]);
      }

      // this.router.navigate([
      //   "/merchant",
      // {
      //   outlets: {
      //     page: ['customer-profile', pID, 'contract', ID]
      //   }
      // }

      // { outlets: { page: ['quotation-contract-view', ID]}, data: { title: 'Referred Application' }}
      // ])
      // this.contractID = ID;
      // this.displaySettlmentList = true;
      // this.detailsView.emit(true);
    }
  }

  openInviteViewDetails(ID) {
    this.invitationID = ID;
    this.displayInvitationView = true;
  }

  cancelInviteViewDetails() {
    this.invitationID = null;
    this.displayInvitationView = false;
  }

  openPersonalCircumstanceView(id) {
    this.contractID = id;
    this.displayPersonalCirView = true;
    this.detailsView.emit(true);
  }

  openCapacityCheckView(id) {
    this.contractID = id;
    this.displayCapacityCheck = true;
    this.detailsView.emit(true);
  }

  openCreditCheckView(id) {
    this.contractID = id;
    this.displayCreditCheck = true;
    this.detailsView.emit(true);
  }

  openInvitationView(id) {
    if (id) {
      const ref = this.dialog.open(InvitationViewModalComponent, {
        data: {
          invitationID: id,
          membership: false,
        },
        width: '900px',
      });

      ref.componentInstance.close.subscribe((res) => {
        if (res == true) {
          ref.close();
        }
      });
    }
  }

  openProductView(id) {
    const ref = this.dialog.open(ProductViewModalComponent, {
      data: id,
      width: '750px',
      panelClass: 'noCard',
    });

    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  openCustomerView(id) {
    // let ref = this.dialog.open(CustomerViewComponent, {
    //   data: id,
    //   width: "600px",
    //   panelClass: "noCard"
    // });

    // ref.componentInstance.closeModal.subscribe(res => {
    //   ref.close();
    // })
    this.router.navigate([
      '/merchant',
      {
        outlets: {
          page: ['customer-profile', id, 'patient'],
        },
      },
    ]);
  }

  viewContractLogs(id) {
    const ref = this.dialog.open(EventLogListComponent, {
      data: {
        contractID: id,
        type: 'contract',
        itemID: id,
      },
      width: '900px',
    });
    ref.componentInstance.closeModal.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  viewMessagesContract(id) {
    const ref = this.dialog.open(MessageTimelineComponent, {
      data: {
        contractID: id,
        messageType: MessageType.message,
      },
      width: '900px',
    });
    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }
  viewMessagesInvitation(id) {
    const ref = this.dialog.open(MessageTimelineComponent, {
      data: {
        invitationID: id,
        messageType: MessageType.message,
      },
      width: '900px',
    });
    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }
  viewMessages(id) {
    const ref = this.dialog.open(MessageTimelineComponent, {
      data: {
        cardID: id,
        messageType: MessageType.message,
      },
      width: '900px',
    });
    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  createNote(patientID: string, contractID: string) {
    this.dialog.open<NotesModal, NotesModalConfig>(NotesModal, {
      data: {
        patientID,
        parentRoute: 'merchant',
        noteListMode: 'create',
        relationshipLink: {
          label: 'contract',
          route: `customer-profile/${patientID}/contract/${contractID}`,
          itemID: contractID,
        },
      },
    });
  }

  viewNotes(patientID: string) {
    this.dialog.open<NotesModal, NotesModalConfig>(NotesModal, {
      data: {
        parentRoute: 'merchant',
        patientID,
      },
    });
  }

  contactBySMS(id) {
    const ref = RootAppComponent.dialog.open(TextMessageCreateComponent, {
      data: {
        contractID: id,
      },
      width: '650px',
    });

    ref.componentInstance.closeModal.subscribe((res) => {
      ref.close();
    });
  }

  sendSMS(id, merchantID) {
    if (id && merchantID) {
      const ref2 = RootAppComponent.dialog.open(SmsPromoterComponent, {
        data: {
          cardID: id,
          merchantID: merchantID,
          singleChat: true,
          contractID: id,
        },
        panelClass: 'noCard',
        width: '500px',
      });
      ref2.componentInstance.close.subscribe((data) => {
        ref2.close();
      });

      ref2.componentInstance.getResult.subscribe((data) => {
        if (data) {
          const ref = RootAppComponent.dialog.open(SmsConversationComponent, {
            data,
            panelClass: 'bigger-screen',
            width: '70%',
            height: '95vh',
          });
          const sub = ref.componentInstance.close.subscribe((data) => {
            ref.close();
          });
        }

        ref2.close();
      });
    }
  }
  sendEmail(id) {
    if (id) {
      const data = {
        targetType: 'customerProspect',
        targetID: id,
        asGuest: false,
        asProfile: true,
        asPractice: true,
      };
      AuthenticationService.contactInputEvent.emit(data);
    }
  }
  contactByEmail(id) {
    const data = {
      targetType: 'contract',
      targetID: id,
      asGuest: false,
      asProfile: true,
      asPractice: true,
      subjectArray: [],
    };
    AuthenticationService.contactInputEvent.emit(data);
  }
  quickView(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(CustomerProspectViewModelComponent, {
        data: {
          patientID: id,
          isFullDetails: true,
        },
        width: '700px',
      });
      ref.componentInstance.detailedView.subscribe((res) => {
        ref.close();
        ref.afterClosed().subscribe((r) => {
          this.router.navigate([
            '/merchant',
            {
              outlets: {
                page: ['customer-profile', id],
              },
            },
          ]);
        });
      });

      ref.componentInstance.close.subscribe((res) => {
        if (res == true) {
          ref.close();
        }
      });
    }
  }
  toNumber(n) {
    if (n) {
      return Number(n);
    } else {
      return 0;
    }
  }
  fullInvitationDetails(invitationID, patientID) {
    if (invitationID && patientID) {
      this.router.navigate([
        '/merchant',
        {
          outlets: {
            page: ['customer-profile', invitationID, 'treatment', patientID],
          },
        },
      ]);
    }
  }

  viewMerchant(id) {
    const ref = this.dialog.open(MerchantViewComponent, {
      data: id,
      width: '600px',
    });
    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }
  viewDentist(invitationID) {
    if (invitationID) {
      this.contractService
        .getInvitationDetails(invitationID, { fields: 'ID,DentistContact_key' }, this.sessionType, false)
        .subscribe((res) => {
          if (res && res.DentistContact_key) {
            const ref = RootAppComponent.dialog.open(DentistViewComponent, {
              data: res.DentistContact_key,
              width: '550px',
            });
            ref.componentInstance.close.subscribe((res) => {
              if (res == true) {
                ref.close();
              }
            });
          }
        });
    }
  }

  contactDentist(invitationID) {
    if (invitationID) {
      this.contractService
        .getInvitationDetails(invitationID, { fields: 'ID,DentistContact_key' }, this.sessionType, false)
        .subscribe((res) => {
          if (res && res.DentistContact_key) {
            const data = {
              targetType: 'contact',
              targetID: res.DentistContact_key,
              asGuest: false,
              asProfile: true,
              asPractice: true,
            };
            AuthenticationService.contactInputEvent.emit(data);
          }
        });
    }
  }

  contactSMSDentist(invitationID) {
    if (invitationID) {
      this.contractService
        .getInvitationDetails(invitationID, { fields: 'ID,DentistContact_key' }, this.sessionType, false)
        .subscribe((res) => {
          if (res && res.DentistContact_key) {
            const ref2 = RootAppComponent.dialog.open(SmsConversationComponent, {
              data: {
                cardID: res.DentistContact_key,
                isContactStaff: true,
                singleChat: true,
              },
              panelClass: 'bigger-screen',
              width: '70%',
              height: '95vh',
            });
            const sub = ref2.componentInstance.close.subscribe((data) => {
              ref2.close();
            });
          }
        });
    }
  }
  redirect(invitationID) {
    if (invitationID) {
      this.contractService
        .getInvitationDetails(
          invitationID,
          { fields: 'ID,Instant_Finance_Offer_Key,WakandaID' },
          this.sessionType,
          false
        )
        .subscribe((invitation) => {
          if (invitation && invitation.Instant_Finance_Offer_Key) {
            const url = `${environment.nodeUrl}/d/` + invitation.Instant_Finance_Offer_Key;
            window.open(url, '_blank', 'noopener');
          } else if (invitation && invitation.WakandaID) {
            const url =
              Settings.global['consumerFrontendLink'] + '/consumer/(page:invitation-card/' + invitation.WakandaID + ')';
            window.open(url, '_blank', 'noopener');
          }
        });
    }
  }
  wakandaFiles(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(WakandaFilesComponent, {
        data: {
          invitationID: id,
        },
        width: '810px',
      });

      ref.componentInstance.closeEvent.subscribe((res) => {
        ref.close();
      });
    }
  }

  createSettlement(id) {
    if (id) {
      let p = { contractID: id };

      AuthenticationService.newSettlement.emit(p);

      // this.router.navigate(['/merchant', { outlets: { page: ['settlement-create', id] } }]);
    }
  }

  getTotalColumn(field) {
    return this.dataSource.filteredData.map((t) => t[field]).reduce((acc, value) => Number(acc) + Number(value), 0);
  }

  ngOnDestroy() {
    this.destroyEvent.emit(true);
  }
}

export class LoadRecords implements OnInit {
  public dataChange: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  items: any;
  count: any;
  public getStatusCodes = new EventEmitter();
  public getMerchants = new EventEmitter();

  merchants = [];
  statusCodes = [];
  get data(): any[] {
    return this.dataChange.value;
  }

  firstLoad = false;

  get firstLoadEvent() {
    return this.firstLoad;
  }

  util = new UtilsClass();
  isDestoyed = false;

  serviceRef;

  maxRecords = 0;

  constructor(
    private contractService: ContractService,
    private destroyEvent,
    private params,
    private customPhonePipe: CustomPhonePipe
  ) {
    this.destroyEvent.subscribe((res) => {
      this.isDestoyed = res;
    });

    let limit = Number(Settings.global['listItemNumber']);

    if (this.params.maxRecords) {
      limit = Number(this.params.maxRecords);
    }

    const payload = {
      section: 0,
      dateFrom: this.util.EPdateFormat(this.params.dateFrom),
      dateTo: this.util.EPdateFormat(this.params.dateTo),
      merchantID: this.params.merchantID,
      customerID: this.params.customerID,
      status: this.params.status,
      // onlyContract: true,

      partMobile: this.params.partMobile,

      invitationID: this.params.invitationID,
      invitationIDOrWakandaID: this.params.invitationIDOrWakandaID,
      statusCodes: this.params.statusCodes,
      quotationStatusCodes: this.params.quotationStatusCodes,
      quotationStatus: this.params.quotationStatus,
      partFirstName: this.params.partFirstName,
      partLastName: this.params.partLastName,
      email: this.params.email,
      contractID: this.params.contractID,
      limit: limit,
      fields:
        'ID,ApplicationDate,MerchantName,Status,Customer.FullName,Customer.Mobile,Customer.Email,FinishDate,Product.Label.Internal,Product.Label.Marketing,Product.Label.Web,' +
        'ProductName,LoanPurpose,CapacityCheck.Status,CreditCheck.Status,' +
        'Merchant_key,Invitation_key,Product_key,Customer_key,Settlements.NotYetDrawn,' +
        'Status.Code,LoanInstalments,RepaymentFrequency.Noun.Singular,FunderName,' +
        'Deposit,Amount.Outstanding,Amount.FinancedBeforeDeposit,Amount.Financed,' +
        'Arrears.days,Arrears.Amount,LastEvent.DateTimeCreated,LastEvent.EventCode,LastEvent.EventDescription,Quotation.Status.Code,Quotation.Status.Label',
    };

    if (this.params.status == 'other') {
      payload.status = '';
      payload['multipleStatus'] = 'DECLINED|REQUOTED|LOST';
    } else if (this.params.status == 'otherLoans') {
      payload.status = '';
      payload['multipleStatus'] = 'VOIDED|CANCELLED|WR_OFF';
    }

    this.serviceRef = this.contractService.getContractListGlobalPOST(payload).subscribe((res) => {
      this.count = res.length;
      this.items = res;

      for (let i = 0; i < this.items.length; i++) {
        this.buildRecord(i);
      }

      this.merchants = _.uniqBy(this.merchants, 'ID');
      this.getMerchants.emit(this.merchants);


      this.statusCodes = _.uniqBy(this.statusCodes, 'code');
      this.getStatusCodes.emit(this.statusCodes);

      this.dataChange.next(this.items.slice());
      this.firstLoad = true;

      this.maxRecords = this.maxRecords + (res.length || 0);

      const innerFunction = (section) => {
        const length = this.items.length;

        section = section + 1;
        UtilsClass.loadingDataSection(section);
        payload.section = section;

        this.serviceRef = this.contractService.getContractListGlobalPOST(payload).subscribe((res) => {
          if (res.length > 0) {
            this.maxRecords = this.maxRecords + (res.length || 0);

            this.count = res.count;
            this.items = this.items.concat(res);
            for (let i = length; i < this.items.length; i++) {
              this.buildRecord(i);
            }

            this.merchants = _.uniqBy(this.merchants, 'ID');
            this.getMerchants.emit(this.merchants);
            this.statusCodes = _.uniqBy(this.statusCodes, 'code');
            this.getStatusCodes.emit(this.statusCodes);

            this.dataChange.next(this.items.slice());
            if (
              (this.isDestoyed != true && !this.params.maxRecords) ||
              (this.isDestoyed != true &&
                this.params.maxRecords &&
                Number(this.params.maxRecords) > Number(this.maxRecords))
            ) {
              innerFunction(section);
            }
          } else {
            return true;
          }
        });
        UtilsClass.stopLoadingInterceptor();
      };

      if (
        (this.isDestoyed != true && !this.params.maxRecords) ||
        (this.isDestoyed != true && this.params.maxRecords && Number(this.params.maxRecords) > Number(this.maxRecords))
      ) {
        innerFunction(1);
      }
    });
  }

  buildRecord(i) {
    if (this.items[i]) {
      this.items[i]['fullName'] = '';

      if (this.items[i]['ID']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['ID'];
      }
      if (this.items[i]['MerchantName']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['MerchantName'];
      }

      if (this.items[i]['Status']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['Status'];
      }

      if (this.items[i]['Status.Code']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['Status.Code'];
      }

      if (this.items[i]['Quotation.Status.Code']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['Quotation.Status.Code'];
      }

      if (this.items[i]['Quotation.Status.Label']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['Quotation.Status.Label'];
      }

      if (this.items[i]['Customer.FullName']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['Customer.FullName'];
      }
      if (this.items[i]['Customer.Email']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['Customer.Email'];
      }
      if (this.items[i]['ProductName']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['ProductName'];
      }
      if (this.items[i]['Invitation_key']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['Invitation_key'];
      }
      if (this.items[i]['Merchant_key']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['Merchant_key'];
      }

      if (this.items[i]['Product.Label.Internal']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['Product.Label.Internal'];
      }
      if (this.items[i]['Product.Label.Marketing']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['Product.Label.Marketing'];
      }
      if (this.items[i]['Product.Label.Web']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['Product.Label.Web'];
      }

      if (this.items[i]['Customer.Mobile']) {
        this.items[i]['fullName'] =
          this.items[i]['fullName'] +
          ' ' +
          this.customPhonePipe.transform(this.items[i]['Customer.Mobile'], 'none').replace(/ /g, '');
      }
      if (this.items[i]['Customer.Mobile']) {
        this.items[i]['fullName'] =
          this.items[i]['fullName'] + ' ' + this.customPhonePipe.transform(this.items[i]['Customer.Mobile'], 'none');
      }
      if (this.items[i]['FunderName']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['FunderName'];
      }
      if (this.items[i]['LastEvent.EventDescription']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['LastEvent.EventDescription'];
      }
      if (this.items[i]['LastEvent.EventCode']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['LastEvent.EventCode'];
      }

      const p = {
        Label: this.items[i]['MerchantName'],
        ID: this.items[i]['Merchant_key'],
      };

      this.merchants.push(p);

      const p2 = {
        code: this.items[i]['Status.Code'],
        icon: this.items[i]['Status.Icon'],

        color: this.items[i]['Status.Color'],

        label: this.items[i]['Status'],
      };

      this.statusCodes.push(p2);
    }
  }

  ngOnInit() {}

  ngOnDestroy() {
    if (this.serviceRef) {
      this.serviceRef.unsubscribe();
    }
  }
}

export class RecordDataSource extends DataSource<any> {
  _filterChange = new BehaviorSubject('');
  field = '';

  get filter(): any {
    return this._filterChange.value;
  }

  set filter(item: any) {
    this.field = item.field;
    this._filterChange.next(item.value);
  }

  filteredData: any[] = [];

  constructor(private _tableDatabase: LoadRecords, private _paginator: MatPaginator, private _sort: MatSort) {
    super();
    this._filterChange.subscribe(() => (this._paginator.pageIndex = 0));
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<any[]> {
    const displayDataChanges = [
      this._tableDatabase.dataChange,
      this._filterChange,
      this._paginator.page,
      this._sort.sortChange,
    ];

    return observableMerge(...displayDataChanges).pipe(
      map(() => {
        // Filter data
        this.filteredData = this._tableDatabase.data.slice().filter((item: any) => {
          let f = '';

          f = item[this.field] || '';

          const searchStr = f.toLowerCase();
          return searchStr.indexOf(this.filter.toLowerCase()) != -1;
        });
        const data = this.getSortedData(this.filteredData.slice());
        // Grab the page's slice of data.
        const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
        return data.splice(startIndex, this._paginator.pageSize);
      })
    );
  }

  disconnect() {}

  /** Returns a sorted copy of the database data. */
  getSortedData(data: any[]): any[] {
    // const data = data;
    if (!this._sort.active || this._sort.direction == '') {
      return data;
    }

    return data.sort((a, b) => {
      let propertyA: number | string = '';
      let propertyB: number | string = '';

      switch (this._sort.active) {
        case 'StartDate':
          [propertyA, propertyB] = [
            moment(a['ApplicationDate']).toDate().getTime(),
            moment(b['ApplicationDate']).toDate().getTime(),
          ];
          break;

        case 'FinishDate':
          [propertyA, propertyB] = [
            moment(a['FinishDate']).toDate().getTime(),
            moment(b['FinishDate']).toDate().getTime(),
          ];
          break;
        case 'Merchant':
          [propertyA, propertyB] = [a['MerchantName'], b['MerchantName']];
          break;

        case 'Status':
          [propertyA, propertyB] = [a['Status'], b['Status']];
          break;
        case 'CustomerName':
          [propertyA, propertyB] = [a['Customer.FullName'], b['Customer.FullName']];
          break;
        case 'CustomerMobile':
          [propertyA, propertyB] = [a['Customer.Mobile'], b['Customer.Mobile']];
          break;
        case 'CustomerEmail':
          [propertyA, propertyB] = [a['Customer.Email'], b['Customer.Email']];
          break;
        case 'ProductName':
          [propertyA, propertyB] = [a['ProductName'], b['ProductName']];
          break;
        case 'LoanPurpose':
          [propertyA, propertyB] = [a['LoanPurpose'], b['LoanPurpose']];
          break;
        case 'CapacityCheckStatus':
          [propertyA, propertyB] = [a['CapacityCheck.Status'], b['CapacityCheck.Status']];
          break;
        case 'CreditCheckStatus':
          [propertyA, propertyB] = [a['CreditCheck.Status'], b['CreditCheck.Status']];
          break;
        case 'AmountInvoice':
          [propertyA, propertyB] = [a['Amount.FinancedBeforeDeposit'], b['Amount.FinancedBeforeDeposit']];
          break;
        case 'Financed':
          [propertyA, propertyB] = [a['Amount.Financed'], b['Amount.Financed']];
          break;
        case 'Deposit':
          [propertyA, propertyB] = [a['Deposit'], b['Deposit']];
          break;
        case 'Balance':
          [propertyA, propertyB] = [a['Amount.Outstanding'], b['Amount.Outstanding']];
          break;
      }

      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

      return (valueA < valueB ? -1 : 1) * (this._sort.direction == 'asc' ? 1 : -1);
    });
  }
}
