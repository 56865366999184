<app-general-settings [features]="['communication']"></app-general-settings>

<mat-card class="card">
  <div class="row clearfix">
    <div class="half-width">
      <div class="column">
        <label>Site Status</label>
        <br/>
        <mat-slide-toggle [checked]="checked">Site Online</mat-slide-toggle>
        <hr/>
        <p>Last Verification: <span class="bold">18 September 2015 12:10:59</span></p>
        <p>Last Cache Reset: <span class="bold">18 September 2015 12:10:59</span></p>

        <p>Next maintenance: <span class="bold">28 September 2016 12:10:59</span></p>
        <p>Last maintenance: <span class="bold">18 September 2015 12:10:59</span></p>
        <hr/>
        <!-- update mode -->
        <div class="clearfix">
          <mat-select class="full-width" placeholder="Update Mode" name="updateMode" required>
            <mat-option *ngFor="let updateMode of updateModes" [value]="updateMode.value">
              {{ updateMode.viewValue }}
            </mat-option>
          </mat-select>
        </div>
        <!-- update frequency -->
        <div class="clearfix">
          <mat-select class="full-width" placeholder="Update Frequency" name="updateFrequency" required>
            <mat-option *ngFor="let updateFrequency of updateFrequencys" [value]="updateFrequency.value">
              {{ updateFrequency.viewValue }}
            </mat-option>
          </mat-select>
        </div>
        <!-- update method -->
        <div class="clearfix">
          <mat-select class="full-width" placeholder="Update Method" name="updateMethod" required>
            <mat-option *ngFor="let updateMethod of updateMethods" [value]="updateMethod.value">
              {{ updateMethod.viewValue }}
            </mat-option>
          </mat-select>
        </div>
      </div>
    </div>
    <div class="half-width">
      <div class="column">
        <div class="row clearfix">
          <!-- site offline message -->
          <label>Custom site offline message</label>
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Enter site offline message</mat-label>
            <textarea matInput placeholder="Enter site offline message"></textarea>
          </mat-form-field>
        </div>
        <!-- set time zone -->
        <div class="clearfix">
          <mat-select class="full-width" placeholder="Local Time Zone" name="timeZone" required>
            <mat-option *ngFor="let timeZone of timeZones" [value]="timeZone.value">
              {{ timeZone.viewValue }}
            </mat-option>
          </mat-select>
        </div>
        <!-- Session timeout -->
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Session timeout (seconds)</mat-label>
          <input matInput placeholder="Session timeout (seconds)" value="600"/>
        </mat-form-field>
        <!-- Invitation expiration -->
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Invitation expiration (days)</mat-label>
          <input matInput placeholder="Invitation expiration (days)" value="90"/>
        </mat-form-field>
        <hr/>
        <div>
          <button class="btn-clear set-btn-width" mat-raised-button>
            <mat-icon>settings_applications</mat-icon>
            Force Update
          </button>
          <button class="btn-clear set-btn-width" mat-raised-button>
            <mat-icon>toc</mat-icon>
            Select Tables
          </button>
          <button class="btn-clear set-btn-width" mat-raised-button>
            <mat-icon>settings_backup_restore</mat-icon>
            Reload Cache
          </button>
          <button class="btn-clear set-btn-width" mat-raised-button>
            <mat-icon>input</mat-icon>
            Sync Lookup
          </button>
        </div>
        <hr/>
        <button class="btn-clear" mat-raised-button>
          <mat-icon>announcement</mat-icon>
          Error Log
        </button>
      </div>
    </div>
  </div>
</mat-card>
<div class="row clearfix">
  <span class="history"><mat-icon>history</mat-icon>data updated 0 days ago</span>
  <div class="pull-right">
    <button class="" mat-raised-button color="accent">Save changes</button>
  </div>
</div>
