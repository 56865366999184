<div class="rel">
  <div class="card-header primary-gradient-img clearfix confirmModal">
    <button class="btn-close btn-clear mat-button smt white custom-close" (click)="closeEvent()">
      <mat-icon>clear</mat-icon>
    </button>
    <div class="full-width flex main-title-container">
      <div class="main-icon-container text-center">
        <mat-icon class="main-icon accent-color" *ngIf="isFontAwsome(icon) != true"> {{ icon || 'info' }}</mat-icon>

        <mat-icon
          class="main-icon accent-color {{ icon || 'fas fa-info' }}"
          *ngIf="isFontAwsome(icon) == true"
        ></mat-icon>
      </div>
      <div class="row full-width title-container clearfix">
        <div class="title-container-sub" *ngIf="!headerTitle">
          <h2 class="text-left title-header rm-m" *ngIf="documentNature">Upload new {{ documentNature }}</h2>
          <h2 class="text-left title-header rm-m" *ngIf="!documentNature">Upload New Document</h2>
        </div>

        <div class="title-container-sub" *ngIf="headerTitle">
          <h2 class="text-left title-header rm-m">
            {{ headerTitle }}
          </h2>
        </div>
      </div>
    </div>
  </div>

  <mat-dialog-content>
    <div class="full-width smt">
      <div class="full-width" *ngIf="displayTitle == true">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Title</mat-label>
          <input [(ngModel)]="title" matInput placeholder="Insert a title" name="title"/>
        </mat-form-field>
      </div>

      <div class="full-width mb">
        <app-file-uploader
          class="text-center"
          [documentType]="'Shared Document'"
          [keepOriginal]="keepOriginal"
          [maxFileSize]="maxFileSize"
          [isFixedAspectRatio]="isFixedAspectRatio"
          [roundCropper]="roundCropper"
          [allowedExtensions]="allowedExtensions"
          [fileName]="'Shared Document'"
          [isGallery]="false"
          [aspectRatioType]="aspectRatioType"
          [isDragable]="true"
          (onCompleteAll)="onCompleFile($event)"
        >
        </app-file-uploader>
      </div>
      <div class="full-width" *ngIf="fileID">
        <app-file-list
          class="file-insertion2"
          [defaultLabel]="'Shared Document'"
          [displayThumbnail]="displayThumbnail"
          [files]="[fileID]"
          [canPatientDocument]="false"
          [canGallery]="false"
          [canPrivateDocument]="false"
          [canEdit]="false"
          [canAction]="false"
        >
        </app-file-list>
      </div>

      <div class="margin-btm pull-left" *ngIf="displayGallery == true">
        <mat-checkbox [(ngModel)]="isGallery" color="accent"> Add File to the Gallery</mat-checkbox>
      </div>
    </div>
  </mat-dialog-content>
  <hr/>
  <div class="drop-button text-center mt full-width">
    <button class="pull-right mr btn-large" [disabled]="!fileID" (click)="send()" mat-raised-button color="accent">
      Submit
    </button>

    <button class="pull-left btn-large" (click)="closeEvent()" mat-raised-button color="primary">Close</button>
  </div>
</div>
