import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output, SimpleChanges } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { OperatorService } from '../shared/operator.service';

@Component({
  selector: 'app-operator-beneficiary-view-picture',
  templateUrl: './operator-beneficiary-view-picture.component.html',
  styleUrls: ['./operator-beneficiary-view-picture.component.css'],
})
export class OperatorBeneficiaryViewPictureComponent implements OnInit {
  @Input() beneficiaryID;

  @Output() close = new EventEmitter();

  @Input() hideZoomButton = false;
  profileLink;

  constructor(
    private operatorService: OperatorService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data && typeof data == 'string') {
      this.beneficiaryID = data;
    }
  }

  ngOnInit() {
    if (this.beneficiaryID) {
      this.getProfilePicture();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.beneficiaryID.previousValue != changes.beneficiaryID.currentValue) {
      if (this.beneficiaryID) {
        this.getProfilePicture();
      } else {
        this.profileLink = null;
      }
    }
  }

  getProfilePicture() {
    this.profileLink = null;

    this.profileLink = this.operatorService.getSupplierPicStreamLink(this.beneficiaryID);
  }

  closeEvent() {
    this.close.emit(true);
  }
}
