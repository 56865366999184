import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ConfirmDialogMultipleComponent } from '../../../shared/components/confirm-dialog-multiple/confirm-dialog-multiple.component';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { EpDocumentRejectModalComponent } from '../../../shared/components/ep-document-reject-modal/ep-document-reject-modal.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { ContractService } from '../../contract/shared/contract.service';
import { OperatorService } from '../../operator/shared/operator.service';
import { SettlementService } from '../shared/settlement.service';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { ConfirmDialogSingleButton } from '../../../shared/types/confirm-dialog-single-button';
import { ConfirmDialogSingleButtonComponent } from '../../../shared/components/confirm-dialog-single-button/confirm-dialog-single-button.component';
import * as _ from 'lodash';
@Component({
  selector: 'app-settlement-approve-view',
  templateUrl: './settlement-approve-view.component.html',
  styleUrls: ['./settlement-approve-view.component.css'],
})
export class SettlementApproveViewComponent implements OnInit {
  @Input()
  settlementID;

  @Input()
  docObject;

  @Output()
  getResult = new EventEmitter();

  // contract = null;
  settlement;
  isGroupSettlements = false;
  groupSettlements;
  groupSettlementsAmount;
  items = [
    'settlement',
    'contract',
    'invitation',
    'customer',
    'dentist',
    'merchant',
    'instantOffer',
    'appointment',
    'campaign',
    'landingPage',
    'note',
  ];
  settledAmount;

  // statsData;
  // summaryChartData;
  // customSummaryChartColor;

  isPromoterOrAdmin = false;

  isShowFull = false;
  authorisedByForm = false;

  isVerifiedAllDocuments = false;

  isVerifiedAuthrisedForm: boolean;
  isVerifiedInvoice: boolean;

  declineReasons;
  payToSupplier;

  isRefund = false;
  isVerifiedRefundForm: boolean;

  requestBy;

  isPreTreatment = false;
  sessionType;
  isModal = false;

  verifiedIDs = [];
  rejectedIDs = [];
  @Output() close = new EventEmitter();

  constructor(
    private authenticationService: AuthenticationService,
    private contractService: ContractService,
    private settlementService: SettlementService,
    private operatorService: OperatorService,
    private dialog: MatDialog,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.settlementID) {
        this.settlementID = data.settlementID;
        this.isModal = true;
      }
    }
  }

  ngOnInit() {
    this.setup();
  }

  closeModalEvent() {
    this.close.emit(true);
  }

  setup() {
    this.authenticationService.getSessionType().subscribe((Type) => {
      this.sessionType = Type;

      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        let approvedData;

        const payload = {
          selectStats: true,
        };
        this.isPromoterOrAdmin = res;

        if (this.isPromoterOrAdmin == true) {
          const payloadSettlement = {
            selectDocument: true,
          };

          this.settlementService.getSettlementDetails(this.settlementID, payloadSettlement).subscribe((res) => {
            if (res) {
              if (res && res.document && res.document.length > 0) {
                res.document = _.uniqBy(res.document, 'ID');
              }
              this.settlement = res;

              if (
                this.settlement['Invoice.Reference'] == 'PreTreatment' ||
                this.settlement['Invoice.Reference'] == 'Purchase of Materials'
              ) {
                this.isPreTreatment = true;
              }

              if (this.settlement['SettleTo.TableName'] == 'Customer') {
                this.isRefund = true;
              }

              this.settledAmount = this.settlement['Settlement.Amount'];

              if (this.settlement['Insurance.Contribution'] != '0') {
                this.settledAmount =
                  Number(this.settlement['Settlement.Amount']) - Number(this.settlement['Insurance.Contribution']);
              }
              if (this.settlement.DrawDownGroupID) {
                this.isGroupSettlements = true;
                this.setupGroupSettlements();
              }
              this.authorisedByForm = this.settlement['ConsentedViaPaperForm'] == '1' ? true : false;

              if (this.settlement['Requested.By']) {
                this.operatorService.getfullOperatorDetails(this.settlement['Requested.By']).subscribe((res) => {
                  if (res) {
                    this.requestBy = res;
                  }
                });
              }

              // this.contractService
              //   .fetchContractDetails$(this.settlement['Contract_key'], payload, this.isPromoterOrAdmin)
              //   .subscribe((res) => {
              //     if (res) {
              //       this.contract = res;
              //       this.statsData = this.contract.stats;
              //       approvedData = this.statsData.filter((elem) => elem['ID'] == 'APPR')[0];
              //     }

              //     const payload2 = {
              //       onlySupplier: true,
              //       contractID: this.settlement['Contract_key'],
              //       fields: 'Status.Code,Settlement.Amount',
              //     };
              //     this.settlementService.getSettlementList(payload2, this.isPromoterOrAdmin).subscribe((res) => {
              //       if (res) {
              //         if (res.length > 0 && res.some((elem) => elem['Status.Code'] == 'APPR') == true) {
              //           const approvedSupplierSettlement = parseInt(
              //             res
              //               .filter((elem) => elem['Status.Code'] == 'APPR')
              //               .map((x) => x['Settlement.Amount'])
              //               .reduce((a, b) => (Number(a) + Number(b)).toString()),
              //             10
              //           );

              //           this.getSummaryChart(
              //             approvedData['Sum(Settlement.Amount)'] -
              //               approvedData['Sum(Insurance.Contribution)'] -
              //               approvedSupplierSettlement,
              //             approvedData['Sum(Insurance.Contribution)'],
              //             approvedSupplierSettlement,
              //             Number(this.contract['Settlements.NotYetDrawn'])
              //           );
              //         } else {
              //           this.getSummaryChart(
              //             approvedData['Sum(Settlement.Amount)'] - approvedData['Sum(Insurance.Contribution)'],
              //             approvedData['Sum(Insurance.Contribution)'],
              //             0,
              //             Number(this.contract['Settlements.NotYetDrawn'])
              //           );
              //         }
              //       }
              //     });
              //   });
            }
          });
        }
      });
    });
  }

  setupGroupSettlements() {
    const payload = {
      groupID: this.settlement['DrawDownGroupID'],
    };
    this.settlementService.getSettlementList(payload, this.isPromoterOrAdmin).subscribe((res) => {
      if (res) {
        this.groupSettlements = res;
        this.groupSettlementsAmount = this.groupSettlements
          .map((x) => x['Settlement.Amount'])
          .reduce((a, b) => Number(a) + Number(b));
        // this.groupSettlementsInvoiceAmount = this.groupSettlements.map(x => x['Invoice.Amount']).reduce((a,b) => Number(a) + Number(b));

        this.payToSupplier = this.groupSettlements
          .filter((x) => x['SettleTo.TableName'] == 'Supplier')
          .map((x) => x['Settlement.Amount'])
          .reduce((a, b) => Number(a) + Number(b));
      }
    });
  }

  // getSummaryChart(a?, b?, c?, d?) {
  //   this.summaryChartData = [
  //     {
  //       name: 'Settled to Practice',
  //       value: a || 0,
  //     },
  //     {
  //       name: 'Settled to Insurance',
  //       value: b || 0,
  //     },
  //     {
  //       name: 'Settled to Supplier',
  //       value: c || 0,
  //     },
  //     {
  //       name: 'Available Balance',
  //       value: d || 0,
  //     },
  //   ];
  //   this.customSummaryChartColor = [
  //     {
  //       name: 'Settled to Practice',
  //       value: '#1b8bdd',
  //     },
  //     {
  //       name: 'Settled to Insurance',
  //       value: '#ffbc00',
  //     },
  //     {
  //       name: 'Settled to Supplier',
  //       value: '#dd1b1b',
  //     },
  //     {
  //       name: 'Available Balance',
  //       value: '#00d07a ',
  //     },
  //   ];
  // }

  showFullDetails() {
    this.isShowFull = !this.isShowFull;
  }

  toNumber(n) {
    if (n) {
      return Number(n);
    } else {
      return 0;
    }
  }

  applyVerifyEvent(e) {
    if (e && e.isVerified == true) {
      if (e.documentLabel == 'Settlement authorisation form') {
        this.isVerifiedAuthrisedForm = true;
      } else if (e.documentLabel == 'Settlement invoice details') {
        this.isVerifiedInvoice = true;
      } else if (e.documentLabel == 'Settlement Refund form') {
        this.isVerifiedRefundForm = true;
      }
    } else if (e && e.isVerified == false) {
      if (e.documentLabel == 'Settlement authorisation form') {
        this.isVerifiedAuthrisedForm = false;
      } else if (e.documentLabel == 'Settlement invoice details') {
        this.isVerifiedInvoice = false;
      } else if (e.documentLabel == 'Settlement Refund form') {
        this.isVerifiedRefundForm = false;
      }
    }
  }

  verifyEvent(e, id) {
    if (id) {
      if (e === false) {
        let index = this.verifiedIDs.indexOf(id);

        if (index !== -1) {
          this.verifiedIDs.splice(index);
        }

        let index2 = this.rejectedIDs.indexOf(id);

        if (index2 === -1) {
          this.rejectedIDs.push(id);
        }
      } else if (e === true) {
        let index = this.rejectedIDs.indexOf(id);

        if (index !== -1) {
          this.rejectedIDs.splice(index);
        }

        let index2 = this.verifiedIDs.indexOf(id);

        if (index2 === -1) {
          this.verifiedIDs.push(id);
        }
      }

      this.verifiedIDs = _.uniq(this.verifiedIDs);
      this.rejectedIDs = _.uniq(this.rejectedIDs);
    }
  }

  declineSettlement() {
    const issuesAuthorisedForm = [
      'The form attached was not generated from the platform',
      'The form attached is blank',
      'The form attached belongs to another patient',
      'The practice has not signed the form',
      'The patient has not signed the form',
      'The settlement amount does not match the amount on the form',
    ];

    const issuesInvoice = [
      'Amount requested does not match in the invoice',
      'The invoice attached does not match patient financed',
      'The invoice attached is blank',
      'The document attached is not a valid invoice/receipt',
    ];

    const ref = RootAppComponent.dialog.open(EpDocumentRejectModalComponent, {
      data: {
        issuesAuthorisedForm,
        issuesInvoice,
        isConsentedByForm: this.authorisedByForm,
        isRefund: this.isRefund,
      },
      width: '650px',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });

    ref.componentInstance.getReasons.subscribe((res) => {
      if (res) {
        if (res.reasons.length > 0) {
          this.declineReasons = res.reasons.join();
        } else {
          this.declineReasons = '';
        }
        if (res.comment && res.reasons.length > 0) {
          this.declineReasons = this.declineReasons + ',' + res.comment;
        } else if (res.comment && res.reasons.length == 0) {
          this.declineReasons = res.comment;
        }

        this.settlementService
          .decline(this.settlementID, this.declineReasons, this.isGroupSettlements)
          .subscribe((res) => {
            if (res) {
              NotifyAppComponent.displayToast('success', 'Success!', 'You have successfully declined this settlement');
              this.getResult.emit(res);
              ref.close();
            }
          });
      }
    });
  }

  approveSettlementEvent() {
    if (
      this.settlement &&
      this.settlement['Merchant_key'] &&
      this.settlement['Customer_key'] &&
      this.settlement['Contract_key'] &&
      this.settlement['Is_Materials_Request'] === '1'
    ) {
      let p = {
        merchantID: this.settlement['Merchant_key'],
        settlementID: this.settlement['ID'],
        contractID: this.settlement['Contract_key'],
        customerID: this.settlement['Customer_key'],
      };

      this.settlementService.verifyMaterialRequest(p, this.sessionType).subscribe((validation) => {
        if (validation) {
          if (validation.valid === true) {
            this.approveSettlement();
          } else if (validation.error && validation.proceedType === 'cancel') {
            const ref2 = RootAppComponent.dialog.open(ConfirmDialogMultipleComponent, {
              data: {
                icon: 'fas fa-exclamation-triangle',
                description: `<p>${validation.errorHTML}</p> <p>In order to proceed, you will need to withdraw other requests.</p>`,
                yesLabel: 'Yes, proceed.',
                noLabel: 'Cancel',
                isSecondConfirmation: false,
                informationLabel: 'View Pending/Request ',
                title: 'Do you wish to continue?',
              },
              width: '500px',
            });

            ref2.componentInstance.confirmation.subscribe((confirmation) => {
              if (confirmation == true) {
                if (validation.settlementList && validation.settlementList.length > 0) {
                  let ids = _.map(validation.settlementList, (item) => {
                    return item.ID;
                  });

                  this.settlementService.cancelSettlementGroup({ ids: ids }, this.sessionType).subscribe((res) => {
                    if (res) {
                      NotifyAppComponent.displayToast('success', 'Success!', '');

                      ref2.close();
                      ref2.afterClosed().subscribe((r) => {
                        this.approveSettlement();
                      });
                    }
                  });
                } else {
                  NotifyAppComponent.displayToast('success', 'Success!', '');

                  ref2.close();
                  ref2.afterClosed().subscribe((r) => {
                    this.approveSettlement();
                  });
                }
              }
            });
            ref2.componentInstance.information.subscribe((information) => {
              if (information == true) {
                let data = {
                  contractID: this.settlement['Contract_key'],
                  statusCodes: ['REQU', 'PEND'],
                  pageSize: 100,
                };
                AuthenticationService.settlementList.emit(data);
              }
            });
          } else if (validation.errorHTML && validation.proceedType === 'approve') {
            const confirm = new ConfirmDialog(
              'fas fa-exclamation-triangle',
              'You can not request a material advance for  this contract.',
              `<p>${validation.errorHTML}</p>`,
              'Close',
              'View Application Settlements'
            );
            const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
              data: confirm,
              width: '600px',
            });
            ref.componentInstance.onConfirm.subscribe((confirmation) => {
              if (confirmation === false) {
                ref.close();
              } else {
                let data = {
                  contractID: this.settlement['Contract_key'],
                  pageSize: 100,
                };
                AuthenticationService.settlementList.emit(data);
              }
            });
          } else {
            const confirmDialog2 = new ConfirmDialogSingleButton(
              'fas fa-exclamation',
              'Material Advance is not available for  this practice.',
              `<p  style='text-align:center;width:100%';>${validation.errorHTML}</p>`,
              'OKAY',
              false,
              'rgb(199, 0, 0)'
            );
            const ref2 = RootAppComponent.dialog.open(ConfirmDialogSingleButtonComponent, {
              data: confirmDialog2,
            });
            ref2.componentInstance.close.subscribe((res) => {
              ref2.close();
            });
          }
        }
      });
    } else {
      this.approveSettlement();
    }
  }

  approveSettlement() {
    const id = this.settlementID;
    let groupID = '';

    if (this.settlement['DrawDownGroupID']) {
      groupID = this.settlement['DrawDownGroupID'];
    }

    if (id && groupID == '') {
      const confirm = new ConfirmDialog(
        'thumb_up_alt',
        '',
        'Are you sure you want to approve this settlement?',
        'No',
        'Yes'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation == true) {
          this.settlementService.approve(id, false).subscribe((res) => {
            if (res) {
              NotifyAppComponent.displayToast('success', 'Success!', 'Your Settlement has been approved');
              this.getResult.emit(res);
              ref.close();
            }
          });
        } else {
          ref.close();
        }
      });
    } else if (id && groupID != '') {
      const ref2 = RootAppComponent.dialog.open(ConfirmDialogMultipleComponent, {
        data: {
          icon: 'thumb_up_alt',
          description: 'This settlement is part of a settlemennt group.',
          yesLabel: 'Yes, proceed.',
          noLabel: 'Cancel',
          isSecondConfirmation: false,
          informationLabel: 'View settlement group',
          title: 'Are you sure you want to approve this settlement group ?',
        },
        width: '500px',
      });

      ref2.componentInstance.confirmation.subscribe((confirmation) => {
        if (confirmation == true) {
          this.settlementService.approve(id, true).subscribe((res) => {
            if (res) {
              NotifyAppComponent.displayToast('success', 'Success!', 'Your Settlement has been approved');
              this.getResult.emit(res);
              ref2.close();
            }
          });
        }
      });
      ref2.componentInstance.information.subscribe((information) => {
        if (information == true) {
          // this.viewSettlementGroup();
        }
      });
    }
  }

  reUploadDoc() {
    const confirm = new ConfirmDialog(
      'fas fa-info',
      'Invalid support document',
      'Clicking this will ask the reject reasons and send an email to the dentist to require he/she to re-upload the support document',
      'Cancel',
      'Proceed'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirm,
      width: '650px',
    });

    ref.componentInstance.onConfirm.subscribe((res) => {
      if (res == true) {
        ref.close();

        const issuesInvoice = [
          'Amount requested does not match in the invoice',
          'The invoice attached does not match patient financed',
          'The invoice attached is blank',
          'The document attached is not a valid invoice/receipt',
        ];

        const ref2 = RootAppComponent.dialog.open(EpDocumentRejectModalComponent, {
          data: {
            issuesInvoice,
          },
          width: '650px',
        });

        ref2.componentInstance.close.subscribe((res) => {
          ref2.close();
        });

        ref2.componentInstance.getReasons.subscribe((res) => {
          if (res) {
            let _declineReasons;
            if (res.reasons.length > 0) {
              _declineReasons = res.reasons.join();
            } else {
              _declineReasons = '';
            }
            if (res.comment && res.reasons.length > 0) {
              _declineReasons = _declineReasons + ',' + res.comment;
            } else if (res.comment && res.reasons.length == 0) {
              _declineReasons = res.comment;
            }

            this.settlementService.awaitingMerchantResponse(this.settlement['ID']).subscribe((res) => {
              if (res) {
                this.getResult.emit(res);

                const reasonsList = _declineReasons.split(',');
                let _string = '';
                for (let i = 0; i < reasonsList.length; i++) {
                  _string += `<li>${reasonsList[i]}</li>`;
                }
                _string = '<ul>' + _string + '</ul>';

                const payload = {
                  reasons: _string,
                };

                this.settlementService.sendReuploadDocMessage(this.settlement['ID'], payload).subscribe((res) => {
                  if (res) {
                    ref2.close();
                    NotifyAppComponent.displayToast(
                      'success',
                      'Send Message',
                      `You have successfully send a message to ${this.requestBy['CalculatedName']}`
                    );
                  }
                });
              }
            });
          }
        });
      } else {
        ref.close();
      }
    });
  }

  closeEvent() {
    this.close.emit(true);
  }
  getViewDocumentObject(d) {
    if (d) {
      this.docObject = null;
      setTimeout(() => {
        this.docObject = JSON.parse(JSON.stringify(d));
      }, 1000);
    }
  }
}
