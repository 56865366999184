<mat-card
  class="card wiki-inner-nav clearfix"
  *ngIf="(tags && tags.length > 0) || (popularPages && popularPages.length > 0)"
>
  <div class="card-header primary-gradient-img clearfix">
    <div class="row clearfix">
      <div class="row titleArea clearfix">
        <h2 class="summary-header rm-m">Summary</h2>
      </div>
    </div>
  </div>
  <h2 class="sr-title" *ngIf="popularPages && popularPages.length > 0">Popular Articles</h2>

  <div *ngIf="popularPages && popularPages.length > 0">
    <div class="row clearfix" *ngFor="let r of popularPages">
      <a class="clearfix link-class" (click)="moveToPage(r._id)" mat-button>
        {{ r.title }}
        <span class="ml view-span"> <mat-icon class="fas fa-eye extra-header-icon"></mat-icon>
          {{ r.views }} </span>
      </a>
    </div>
  </div>
  <div class="row clearfix">
    <hr *ngIf="popularPages && popularPages.length > 0"/>
    <h2 class="sr-title" *ngIf="tags && tags.length > 0">Tag cloud</h2>
    <mat-form-field class="full-width" *ngIf="tags && tags.length > 3" appearance="outline">
      <mat-label>Tag Filter</mat-label>
      <input [(ngModel)]="search" name="referenceLabel" matInput placeholder="Tag Filter"/>
    </mat-form-field>
    <mat-chip-list class="pull-left" *ngIf="tags && tags.length > 0">
      <mat-chip
        *ngFor="let r of tags | without: ['[object Object]'] | FilterArrayMultipleValue: '*':search"
        (click)="searchEvent(r)"
        style="cursor: pointer"
        matTooltip="Look for Articles with similiar keyword"
      >
        {{ r }}</mat-chip
      >
    </mat-chip-list>
  </div>
</mat-card>
