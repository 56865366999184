import { getCurrencySymbol } from '@angular/common';
import { Component, Input, OnChanges } from '@angular/core';
import { labelAnimation } from '../../../../../../shared/animations/label.animation';
import { Settings } from '../../../../../../shared/types/settings';

@Component({
  selector: 'ipv-funders-revenue-report-summary',
  templateUrl: './funders-revenue-report-summary.component.html',
  styleUrls: ['./funders-revenue-report-summary.component.css'],
  animations: [labelAnimation],
})
export class FundersRevenueReportSummaryComponent implements OnChanges {
  @Input() revenueReport = null;

  borrowersFeesLabel = `Borrower's Fees`;
  merchantFeesLabel = `Merchant's Fees`;
  borrowersInterestLabel = `Borrower's Total Interest`;
  chartCurrencyCode = getCurrencySymbol(Settings.global['currencyCode'], 'narrow');
  chartColorScheme = ['#CE066B', '#774692', '#168BDD'];

  ngOnChanges() {}
}
