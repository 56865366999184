<div class="rel" *ngIf="healthFunds && firstLoadEvent == false" @ngIfAnimation>
  <div class="row clearfix padded rel">
    <h1 class="dataHeader rm-mt transparent">Fetching data...</h1>
  </div>
</div>

<div [hidden]="healthFunds.length == 0" style="margin-top: 50px">
  <div>
    <mat-card class="list-card">
      <table class="list-table" #table [dataSource]="datasource" mat-table matSort style="width: 100%">
        <ng-container cdkColumnDef="Label" sticky>
          <th *matHeaderCellDef mat-header-cell mat-sort-header style="width: 250px">Label</th>
          <td *matCellDef="let row" mat-cell style="width: 250px">{{ row['Label'] | titlecase }}</td>
        </ng-container>

        <tr *matHeaderRowDef="displayedColumns; sticky: true" mat-header-row></tr>
        <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>
      </table>
      <mat-paginator
        #paginator
        [length]="healthFunds.length"
        [pageIndex]="0"
        [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions"
      >
      </mat-paginator>
    </mat-card>
  </div>
</div>

<div *ngIf="healthFunds && healthFunds.length == 0 && firstLoadEvent != false">
  <app-empty-list-message></app-empty-list-message>
</div>
