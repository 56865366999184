import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ContractService } from '../../contract/shared/contract.service';
import { PersonalCircumstancesService } from '../shared/personal-circumstances.service';

@Component({
  selector: 'app-pci-document',
  templateUrl: './personal-circumstance-document.component.html',
  styleUrls: ['./personal-circumstance-document.component.css'],
  animations: [
    trigger('onChangeAnimation', [
      transition('void => *', [
        query('.animate', style({ opacity: 0 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.15s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(0)', offset: 0, height: 0, margin: 0 }),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0, height: '*', margin: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.animate', style({ opacity: 1 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.15s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0, height: '*', margin: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(0)', offset: 1.0, height: 0, margin: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class PCIDocumentComponent implements OnInit {
  @Input()
  personalCirID;

  @Input()
  contractID;

  isModal = false;

  documents;

  constructor(
    private pcService: PersonalCircumstancesService,
    private activeRoute: ActivatedRoute,
    private contractSerivce: ContractService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.personalCirID) {
        this.personalCirID = data.personalCirID;
        this.isModal = true;
      }
    }
  }

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      if (params['personalCirID']) {
        this.personalCirID = params['personalCirID'];
      }

      if (this.personalCirID) {
        const payload = {
          fields: 'DateAdded,DateTimeCreated,Description,Extension,ID,Label,URL.Complete,URL.Relative,URL.Base',
        };

        this.pcService.getPCIDocument(this.personalCirID, payload).subscribe((res) => {
          if (res) {
            this.documents = res;
          }
        });
      }

      if (this.contractID) {
        const payload = {
          fields:
            'DateAdded,DateTimeCreated,Description,Extension,ID,Label,URL.Complete,URL.Relative,URL.Base,AWS_URL,AWS_Bucket_Name,DocumentType_Code',
        };

        this.contractSerivce.getConractDocument(this.contractID, payload).subscribe((res) => {
          if (res) {
            this.documents = res.filter(
              (x) =>
                x.DocumentType_Code == 'IDL' ||
                x.DocumentType_Code == 'IDP' ||
                x.DocumentType_Code == 'IDC' ||
                x.DocumentType_Code == 'PID' ||
                x.DocumentType_Code == 'POE' ||
                x.DocumentType_Code == 'PRA'
            );
          }
        });
      }
    });
  }
}
