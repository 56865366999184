/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./settlement-due-settlements-report-main.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../../shared/pipes/custom-currency.pipe";
import * as i4 from "../../../../../../shared/pipes/invert-number/invert-number.pipe";
import * as i5 from "../../../../../../shared/components/data-table/data-table.component.ngfactory";
import * as i6 from "../../../../../../shared/components/data-table/data-table.component";
import * as i7 from "../../../../../../shared/pipes/custom-date.pipe";
import * as i8 from "./settlement-due-settlements-report-main.component";
import * as i9 from "@angular/material/dialog";
import * as i10 from "../../services/settlement-due-settlements-report.service";
var styles_SettlementDueSettlementsReportMainComponent = [i0.styles];
var RenderType_SettlementDueSettlementsReportMainComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SettlementDueSettlementsReportMainComponent, data: {} });
export { RenderType_SettlementDueSettlementsReportMainComponent as RenderType_SettlementDueSettlementsReportMainComponent };
function View_SettlementDueSettlementsReportMainComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["style", "text-align: right"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵppd(2, 1), i1.ɵppd(3, 1)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent, 0), i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent, 1), _v.parent.context.$implicit.treatmentValue)))); _ck(_v, 1, 0, currVal_0); }); }
function View_SettlementDueSettlementsReportMainComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_SettlementDueSettlementsReportMainComponent_2)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = (_v.context.$implicit.treatmentValue > 0); _ck(_v, 1, 0, currVal_0); }, null); }
function View_SettlementDueSettlementsReportMainComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["style", "text-align: right"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵppd(2, 1), i1.ɵppd(3, 1)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent, 0), i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent, 1), _v.parent.context.$implicit.dueAmount)))); _ck(_v, 1, 0, currVal_0); }); }
function View_SettlementDueSettlementsReportMainComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_SettlementDueSettlementsReportMainComponent_4)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = (_v.context.$implicit.dueAmount !== 0); _ck(_v, 1, 0, currVal_0); }, null); }
function View_SettlementDueSettlementsReportMainComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["style", "text-align: right"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.typeCode; _ck(_v, 1, 0, currVal_0); }); }
export function View_SettlementDueSettlementsReportMainComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i3.CustomCurrencyPipe, [i2.CurrencyPipe]), i1.ɵpid(0, i4.InvertNumberPipe, []), i1.ɵqud(671088640, 1, { treatmentValue: 0 }), i1.ɵqud(671088640, 2, { dueAmount: 0 }), i1.ɵqud(671088640, 3, { typeCode: 0 }), (_l()(), i1.ɵeld(5, 0, null, null, 4, "ipv-data-table", [], null, null, null, i5.View_DataTableComponent_0, i5.RenderType_DataTableComponent)), i1.ɵdid(6, 4833280, null, 0, i6.DataTableComponent, [], { table: [0, "table"], rename: [1, "rename"], customComponents: [2, "customComponents"], formatHeaders: [3, "formatHeaders"] }, null), i1.ɵpod(7, { treatmentValue: 0, dueAmount: 1, typeCode: 2 }), i1.ɵpod(8, { treatmentValue: 0, dueAmount: 1, typeCode: 2 }), i1.ɵpod(9, { treatmentValue: 0, dueAmount: 1, typeCode: 2 }), (_l()(), i1.ɵand(0, [[1, 2], ["treatmentValue", 2]], null, 0, null, View_SettlementDueSettlementsReportMainComponent_1)), (_l()(), i1.ɵand(0, [[2, 2], ["dueAmount", 2]], null, 0, null, View_SettlementDueSettlementsReportMainComponent_3)), (_l()(), i1.ɵand(0, [[3, 2], ["typeCode", 2]], null, 0, null, View_SettlementDueSettlementsReportMainComponent_5))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.summaryReport.table; var currVal_1 = _ck(_v, 7, 0, "Treatment Value", "Amount Due", "Code"); var currVal_2 = _ck(_v, 8, 0, i1.ɵnov(_v, 10), i1.ɵnov(_v, 11), i1.ɵnov(_v, 12)); var currVal_3 = _ck(_v, 9, 0, "right", "right", "right"); _ck(_v, 6, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_SettlementDueSettlementsReportMainComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ipv-settlement-due-settlements-report-main", [], null, null, null, View_SettlementDueSettlementsReportMainComponent_0, RenderType_SettlementDueSettlementsReportMainComponent)), i1.ɵprd(512, null, i7.CustomDatePipe, i7.CustomDatePipe, []), i1.ɵdid(2, 770048, null, 0, i8.SettlementDueSettlementsReportMainComponent, [i9.MatDialog, i7.CustomDatePipe, i10.SettlementDueSettlementsReportService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var SettlementDueSettlementsReportMainComponentNgFactory = i1.ɵccf("ipv-settlement-due-settlements-report-main", i8.SettlementDueSettlementsReportMainComponent, View_SettlementDueSettlementsReportMainComponent_Host_0, { dueSettlementsReport: "dueSettlementsReport", startDate: "startDate", endDate: "endDate" }, {}, []);
export { SettlementDueSettlementsReportMainComponentNgFactory as SettlementDueSettlementsReportMainComponentNgFactory };
