/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./feature-sub-label-create-redirect.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./feature-sub-label-create-redirect.component";
import * as i3 from "@angular/router";
var styles_FeatureSubLabelCreateRedirectComponent = [i0.styles];
var RenderType_FeatureSubLabelCreateRedirectComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FeatureSubLabelCreateRedirectComponent, data: {} });
export { RenderType_FeatureSubLabelCreateRedirectComponent as RenderType_FeatureSubLabelCreateRedirectComponent };
export function View_FeatureSubLabelCreateRedirectComponent_0(_l) { return i1.ɵvid(0, [], null, null); }
export function View_FeatureSubLabelCreateRedirectComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-feature-sub-label-create-redirect", [], null, null, null, View_FeatureSubLabelCreateRedirectComponent_0, RenderType_FeatureSubLabelCreateRedirectComponent)), i1.ɵdid(1, 114688, null, 0, i2.FeatureSubLabelCreateRedirectComponent, [i3.Router, i3.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FeatureSubLabelCreateRedirectComponentNgFactory = i1.ɵccf("app-feature-sub-label-create-redirect", i2.FeatureSubLabelCreateRedirectComponent, View_FeatureSubLabelCreateRedirectComponent_Host_0, {}, {}, []);
export { FeatureSubLabelCreateRedirectComponentNgFactory as FeatureSubLabelCreateRedirectComponentNgFactory };
