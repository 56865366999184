import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSortModule } from '@angular/material/sort';
import { TranslateModule } from '@ngx-translate/core';
import { ArchwizardModule } from 'angular-archwizard';
import { NgDatepickerModule } from 'ng2-datepicker';
import { NgPipesModule } from 'ngx-pipes';
import { SharedModule } from '../../shared/shared.module';
import { ContactCreateEditComponent } from './contact-create-edit/contact-create-edit.component';
import { ContactListComponent } from './contact-list/contact-list.component';
import { ContactViewPictureComponent } from './contact-view-picture/contact-view-picture.component';
import { ContactViewComponent } from './contact-view/contact-view.component';
import { ContactRoutingModule } from './shared/contact-routing.module';
import { ContactService } from './shared/contact.service';
import { ContactLookupComponent } from './contact-lookup/contact-lookup.component';
import { CardModule } from '../card/card.module';
import { ContactCreateEditModalComponent } from './contact-create-edit-modal/contact-create-edit-modal.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    TranslateModule,
    ReactiveFormsModule,
    NgDatepickerModule,
    ArchwizardModule,
    ContactRoutingModule,
    NgPipesModule,
    CdkTableModule,
    MatSortModule,
    CardModule
  ],
  declarations: [ContactCreateEditComponent,ContactCreateEditModalComponent, ContactListComponent, ContactViewComponent, ContactViewPictureComponent, ContactLookupComponent],
  exports: [ContactCreateEditComponent,ContactCreateEditModalComponent, ContactListComponent, ContactViewComponent, ContactViewPictureComponent, ContactLookupComponent],
  providers: [ContactService],
})
export class ContactModule {}
