import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EconnectFieldViewComponent } from '../econnect-field-view/econnect-field-view.component';
import { EconnectOverviewComponent } from '../econnect-overview/econnect-overview.component';
import { EconnectTableViewComponent } from '../econnect-table-view/econnect-table-view.component';

const routes: Routes = [


  {
    path: 'EconnectTableViewComponent',
    component: EconnectTableViewComponent,
  },

  {
    path: 'EconnectFieldViewComponent',
    component: EconnectFieldViewComponent,
  },

  {
    path: 'EconnectOverviewComponent',
    component: EconnectOverviewComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class EconnectRoutingModule {}
