import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-ep-document-reject-modal.componnet.ts',
  templateUrl: './ep-document-reject-modal.component.html',
  styleUrls: ['./ep-document-reject-modal.component.css'],
})
export class EpDocumentRejectModalComponent implements OnInit {
  @Input()
  issuesInvoice = [];

  @Input()
  issuesAuthorisedForm = [];

  @Input()
  isConsentedByForm = false;

  @Input()
  isRefund = false;

  reasons = [];
  feedback = '';

  selectedRadio = false;

  @Output()
  getReasons = new EventEmitter();

  close = new EventEmitter();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.issuesAuthorisedForm != null) {
        this.issuesAuthorisedForm = data.issuesAuthorisedForm;
      }

      if (data.issuesInvoice != null) {
        this.issuesInvoice = data.issuesInvoice;
      }

      if (data.isConsentedByForm != null) {
        this.isConsentedByForm = data.isConsentedByForm;
      }

      if (data.isRefund != null) {
        this.isRefund = data.isRefund;
      }
    }
  }

  ngOnInit() {}

  selectReason(reason, _response) {
    const response = _response.checked;
    const index = this.reasons.indexOf(reason);

    if ((!this.reasons.length || index == -1) && response == true) {
      this.reasons.push(reason);
    } else {
      if (response == false && index != -1) {
        this.reasons.splice(index, 1);
      }
    }
    this.selectedRadio = true;
  }

  closeModal() {
    this.close.emit(true);
  }

  submit() {
    this.getReasons.emit({
      reasons: this.reasons,
      comment: this.feedback,
    });
  }
}
