import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { InvitationService } from '../shared/services/invitation.service';

import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { UtilsClass } from '../../../shared/types/utils/utils.class';

@Component({
  selector: 'app-invitation-communication-view',
  templateUrl: './invitation-communication-view.component.html',
  styleUrls: ['./invitation-communication-view.component.css'],
  animations: [
    trigger('onChangeAnimation', [
      transition('void => *', [
        query('.animate', style({ opacity: 0 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.15s ease-in-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(0)', offset: 0, height: 0 }),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.animate', style({ opacity: 1 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.15s ease-in-out',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(0)', offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class InvitationCommunicationViewComponent implements OnInit {
  @Input() invitationID;

  profileLink;

  util = new UtilsClass();
  sessionType;
  invitation;

  constructor(
    private authenticationService: AuthenticationService,
    private invitationService: InvitationService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.invitationID) {
        this.invitationID = data.invitationID;
      }
    }
  }

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((r) => {
      this.sessionType = r;

      this.invitationService.getOneInvitationCommunication(this.invitationID, this.sessionType).subscribe((res) => {
        if (res) {
          this.invitation = res;
          this.getPicture();
        }
      });
    });
  }

  getPicture() {
    this.profileLink = null;
    if (this.invitation['Prospect_key']) {
      this.profileLink = this.invitationService.getProfilePicStreamLink(this.invitation['Prospect_key']);
    }
  }
}
