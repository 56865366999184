import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';
import * as moment from 'moment';
import * as momentTimeZone from 'moment-timezone';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { UtilsService } from '../../../shared/services/utils.service';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { AuthenticationService } from '../../authentication/shared/authentication.service';
import { LanguageCreateComponent } from '../../language/language-create/language-create.component';
import { ModuleEditComponent } from '../module-edit/module-edit.component';
import { ModuleUtilClass } from '../shared/moduleUtil';
import { SettingsService } from '../shared/settings.service';

@Component({
  selector: 'app-general-settings',
  templateUrl: './general-settings.component.html',
  styleUrls: ['./general-settings.component.css'],
})
export class GeneralSettingsComponent implements OnInit {
  isOnline = false;
  analyticsID;
  fileUploaded = false;

  moduleFilter;

  EPServerlink;
  colorVariables = [];

  allowedExtensionTypes = ['image'];
  offlineMessage;
  templateType = 'masterAndManual';

  @Input()
  features = [
    'general',
    'charts',
    'languages',
    'communication',
    'industry',
    'session',
    'modules',
    'documents',
    'userInputSetting',
    'integration',
    'analytics',
    'masterAccount',
  ];

  themeScssFile = [];
  // contextsForm = new FormControl();
  contexts = [];

  labelSource;

  securityCodeMethods = ['sms', 'email', 'emailSms'];

  currencyList = [
    { code: 'USD', symbol: '$' },
    { code: 'CAD', symbol: 'CA$' },
    { code: 'EUR', symbol: '€' },
    { code: 'AUD', symbol: 'AU$' },
    { code: 'CNY', symbol: 'CN¥' },
    { code: 'JPY', symbol: '¥' },
  ];

  languageCode = '';
  languageLabel = '';
  index = 0;
  existingModuleCodes = [];
  private settings: any = {};
  private moduleUtils = new ModuleUtilClass();

  timeZone;
  timeZones = [];

  timeZoneUTC;
  timeZoneUTCs = [];

  simpleData2 = [
    {
      name: 'Germany',
      value: 40632,
    },
    {
      name: 'United States',
      value: 49737,
    },
    {
      name: 'France',
      value: 36745,
    },
    {
      name: 'United Kingdom',
      value: 36240,
    },
  ];

  simpleData = [
    {
      name: 'Germany',
      value: 40632,
    },
    {
      name: 'United States',
      value: 49737,
    },
    {
      name: 'France',
      value: 36745,
    },
    {
      name: 'United Kingdom',
      value: 36240,
    },
    {
      name: 'Spain',
      value: 33000,
    },
    {
      name: 'Italy',
      value: 35800,
    },
    {
      name: 'Australia',
      value: 47000,
    },
    {
      name: 'Morocco',
      value: 21000,
    },
    {
      name: 'Poland',
      value: 39150,
    },
    {
      name: 'Russia',
      value: 42920,
    },
  ];

  AdvancedData = [
    {
      name: 'Germany',
      series: [
        {
          name: '2010',
          value: 40632,
        },
        {
          name: '2000',
          value: 36953,
        },
        {
          name: '1990',
          value: 31476,
        },
        {
          name: '1980',
          value: 22500,
        },
        {
          name: '1970',
          value: 34000,
        },
        {
          name: '1960',
          value: 15000,
        },
      ],
    },
    {
      name: 'United States',
      series: [
        {
          name: '2010',
          value: 49737,
        },
        {
          name: '2000',
          value: 45986,
        },
        {
          name: '1990',
          value: 30060,
        },
        {
          name: '1980',
          value: 33500,
        },
        {
          name: '1970',
          value: 14000,
        },
        {
          name: '1960',
          value: 19000,
        },
      ],
    },
    {
      name: 'France',
      series: [
        {
          name: '2010',
          value: 36745,
        },
        {
          name: '2000',
          value: 34774,
        },
        {
          name: '1990',
          value: 21476,
        },
        {
          name: '1980',
          value: 12500,
        },
        {
          name: '1970',
          value: 24000,
        },
        {
          name: '1960',
          value: 19500,
        },
      ],
    },
    {
      name: 'United Kingdom',
      series: [
        {
          name: '2010',
          value: 36240,
        },
        {
          name: '2000',
          value: 32543,
        },
        {
          name: '1990',
          value: 20424,
        },
        {
          name: '1980',
          value: 20500,
        },
        {
          name: '1970',
          value: 31000,
        },
        {
          name: '1960',
          value: 18000,
        },
      ],
    },
  ];

  lineData = [
    {
      name: 'Slovenia',
      series: [
        {
          value: 6580,
          name: '2016-09-13T19:09:30.045Z',
        },
        {
          value: 4541,
          name: '2016-09-21T01:03:36.419Z',
        },
        {
          value: 6739,
          name: '2016-09-13T14:49:47.983Z',
        },
        {
          value: 5882,
          name: '2016-09-18T03:18:39.717Z',
        },
        {
          value: 2605,
          name: '2016-09-21T15:08:38.720Z',
        },
      ],
    },
    {
      name: 'South Georgia',
      series: [
        {
          value: 2589,
          name: '2016-09-13T19:09:30.045Z',
        },
        {
          value: 6222,
          name: '2016-09-21T01:03:36.419Z',
        },
        {
          value: 3915,
          name: '2016-09-13T14:49:47.983Z',
        },
        {
          value: 4843,
          name: '2016-09-18T03:18:39.717Z',
        },
        {
          value: 2200,
          name: '2016-09-21T15:08:38.720Z',
        },
      ],
    },
    {
      name: 'Namibia',
      series: [
        {
          value: 5756,
          name: '2016-09-13T19:09:30.045Z',
        },
        {
          value: 2723,
          name: '2016-09-21T01:03:36.419Z',
        },
        {
          value: 5477,
          name: '2016-09-13T14:49:47.983Z',
        },
        {
          value: 3430,
          name: '2016-09-18T03:18:39.717Z',
        },
        {
          value: 2833,
          name: '2016-09-21T15:08:38.720Z',
        },
      ],
    },
    {
      name: 'Zimbabwe',
      series: [
        {
          value: 5448,
          name: '2016-09-13T19:09:30.045Z',
        },
        {
          value: 5181,
          name: '2016-09-21T01:03:36.419Z',
        },
        {
          value: 6759,
          name: '2016-09-13T14:49:47.983Z',
        },
        {
          value: 5385,
          name: '2016-09-18T03:18:39.717Z',
        },
        {
          value: 3170,
          name: '2016-09-21T15:08:38.720Z',
        },
      ],
    },
    {
      name: 'French Southern Territories',
      series: [
        {
          value: 2107,
          name: '2016-09-13T19:09:30.045Z',
        },
        {
          value: 4203,
          name: '2016-09-21T01:03:36.419Z',
        },
        {
          value: 2716,
          name: '2016-09-13T14:49:47.983Z',
        },
        {
          value: 4209,
          name: '2016-09-18T03:18:39.717Z',
        },
        {
          value: 3268,
          name: '2016-09-21T15:08:38.720Z',
        },
      ],
    },
  ];

  newVariable = {
    isPalette: true,
    name: null,
    palette: null,
    color: '#000',
  };

  existingCodesLoaded = false;
  variableNameLabel = '<%= variableName %>';

  mapValidators = [
    {
      name: 'Kleber',
      value: 'kleber',
    },
    {
      name: 'Google Maps',
      value: 'google',
    },
  ];

  phoneValidators = [
    {
      name: 'Kleber',
      value: 'kleber',
    },
    {
      name: 'JS Validator',
      value: 'jsvalidator',
    },
  ];

  emailValidators = [
    {
      name: 'Kleber',
      value: 'kleber',
    },
    {
      name: 'JS Validator',
      value: 'jsvalidator',
    },
  ];

  themes = [
    { value: '0', viewValue: 'Smile Right' },
    { value: '1', viewValue: 'Pioneer Credit' },
  ];

  isPromoterOrAdmin = false;
  masterUsername;
  masterPassword;
  masterPasswordConfirmed;

  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private translate: TranslateService,
    private authService: AuthenticationService,
    private settingsService: SettingsService,
    private domSanitizer: DomSanitizer,
    private dialog: MatDialog
  ) {
    this.authService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;
    });
  }

  ngOnInit() {
    for (let i = -13; i < 14; i++) {
      this.timeZoneUTCs.push(i);
    }

    if (moment) {
      this.timeZones = momentTimeZone.tz.names();
    }

    this.activeRoute.params.subscribe((params) => {
      if (params['features']) {
        this.features = params['features'].split(',');
      }

      if (this.features.indexOf('modules') != -1) {
        this.settingsService.existingModuleCodes().subscribe((res) => {
          if (res && res.length > 0) {
            this.existingModuleCodes = res;
          }

          this.existingCodesLoaded = true;

          this.getSettings();
        });
      } else {
        this.getSettings();
      }
      Settings.refreshSettings.subscribe((res) => {
        this.getSettings();
      });
    });
  }

  getSettings() {
    this.settingsService.getSettings().subscribe(
      (res: any) => {
        if (res) {
          this.settings = res;

          if (this.settings.contexts) {
            this.contexts = this.settings.contexts;
          }

          if (this.settings.browserCacheLifeTime) {
            this.settings.browserCacheLifeTime = Number(this.settings.browserCacheLifeTime) / 3600;
          }

          if (this.settings.EPserver) {
            this.EPServerlink = this.settings.EPserver;
          }

          if (this.settings['themeDefaultScssColors']) {
            this.colorVariables = this.settings['themeDefaultScssColors'];
          }

          if (this.settings['ThemeDefaultScssFile']) {
            this.themeScssFile = [this.settings['ThemeDefaultScssFile']];
          }

          if (this.settings['MarketingTemplateMasterAndManual'] === true) {
            this.templateType = 'masterAndManual';
          } else if (this.settings['MarketingManual'] === true) {
            this.templateType = 'manual';
          } else {
            this.templateType = 'master';
          }
        }
        // this.settings.availableLanguages.filter((lang, index) => {
        //   if (lang._id == this.settings.language) {
        //     this.languageCode = lang.code;
        //     this.languageLabel = lang.label;
        //   }
        // });
      },
      (err: any) => {
        console.log(err);
      }
    );
  }

  getFileLink(fileID) {
    return environment.nodeUrl + '/files/file-view/' + fileID;
  }

  updateSiteInformation() {
    const siteInformation = {
      EPversion: this.settings.EPversion,
      EPrelease: this.settings.EPrelease,
      brandName: this.settings.brandName,
      snapLine: this.settings.snapLine,
      copyRight: this.settings.copyRight,
      address: this.settings.address,
      pageTitle: this.settings.pageTitle,
      abn: this.settings.abn,
      contactName: this.settings.contactName,
      contactPhone: this.settings.contactPhone,
      contactEmail: this.settings.contactEmail,
      twitter: this.settings.twitter,
      facebook: this.settings.facebook,
      youtube: this.settings.youtube,
      publicWebSite: this.settings.publicWebSite,
      instagram: this.settings.instagram,
    };
    this.updateSettings(siteInformation);
  }

  updateAnalyticsID() {
    localStorage.setItem('trackingID', this.analyticsID);
    NotifyAppComponent.displayToast('success', 'Successful operation', 'Settings has been successfully updated');
  }

  updateBranding() {
    const branding = {
      BrandingBackgroundImageMerchant: this.settings.BrandingBackgroundImageMerchant,
      BrandingBackgroundImageConsumer: this.settings.BrandingBackgroundImageConsumer,
      BrandingIcon: this.settings.BrandingIcon,
      BrandingLogoImage: this.settings.BrandingLogoImage,
    };

    this.updateSettings(branding);
  }

  updateLifeTimeDetails() {
    if (this.settings.browserCacheLifeTime) {
      this.settings.browserCacheLifeTime = Number(this.settings.browserCacheLifeTime) * 3600;
    }

    const lifetimeDetails = {
      securityCodeMethod: this.settings.securityCodeMethod,
      sessionLifeTime: this.settings.sessionLifeTime,
      sessionRemeberedLifeTime: this.settings.sessionRemeberedLifeTime,
      browserCacheLifeTime: this.settings.browserCacheLifeTime,
      sessionRefreshDuration: this.settings.sessionRefreshDuration,
      sessionConsumerLifeTime: this.settings.sessionConsumerLifeTime,
      storedGeoLocationNumber: this.settings.storedGeoLocationNumber,
      storedClientInfoNumber: this.settings.storedClientInfoNumber,
      isConsumerLogin: this.settings.isConsumerLogin,
      consumerNoLoginTimes: this.settings.consumerNoLoginTimes,
      loginCodeLifeTime: this.settings.loginCodeLifeTime,
      securityCodeLifeTime: this.settings.securityCodeLifeTime,

      practiceCodeLifeTime: this.settings.practiceCodeLifeTime,
      practiceCodeNumberOfUse: this.settings.practiceCodeNumberOfUse,

      authorizationCodeLifeTime: this.settings.authorizationCodeLifeTime,
      refreshTokenlifeTime: this.settings.refreshTokenlifeTime,
      accessTokenLifeTime: this.settings.accessTokenLifeTime,
      transactionTokenLifeTime: this.settings.transactionTokenLifeTime,

      consumerAuthenticationDuration: this.settings.consumerAuthenticationDuration,
    };
    this.updateSettings(lifetimeDetails);
  }

  sliderChangeModule(value, target, isBeta = false) {
    if (isBeta == true) {
      this.settings[target] = value.checked;
    } else {
      if (value && value.checked == true) {
        this.settings[target] = true;
        this.settings[target + 'Beta'] = true;
      } else if (value && value.checked == false) {
        this.settings[target] = false;
      }
    }

    this.updateModules();
  }

  siteOfflineSliderChange(value) {
    this.isOnline = value.checked;
    if (this.isOnline == false) {
      this.router.navigate(['/site-offline']);
    }
  }

  updateContext() {
    const modules = {
      context: this.settings.context,
      labelSource: this.settings.labelSource,
    };

    this.updateSettings(modules);
  }

  updateModules() {
    const modules = {
      context: this.settings.context,
      labelSource: this.settings.labelSource,
    };

    for (let i = 0; i < this.moduleUtils.modules.length; i++) {
      modules[this.moduleUtils.modules[i]['code']] = this.settings[this.moduleUtils.modules[i]['code']];
    }

    for (const key in modules) {
      if (modules.hasOwnProperty(key)) {
        if (key.indexOf('isModule') != -1) {
          modules[key + 'Beta'] = this.settings[key + 'Beta'];
        }
      }
    }

    this.settingsService.updateSettings(this.settings._id, modules).subscribe(
      (res: any) => {
        if (res.success) {
          this.settingsService.getPublicSettings().subscribe((settings) => {
            if (settings && settings[0]) {
              const s = this.settingsService.setSettings(res.data);

              Settings.global = settings[0];
              if (Settings.global && Settings.global['pageTitle']) {
                document.title = Settings.global['pageTitle'];
              }

              if (s) {
                NotifyAppComponent.displayToast(
                  'success',
                  'Successful operation',
                  'Settings has been successfully updated'
                );
              }

              UtilsService.currentAcccess = null;
              UtilsClass.updateSideMenu.emit(true);
            }
          });
        }
      },
      (err: any) => {
        console.log(err);
      }
    );
  }

  updateLanguages() {
    const language = {
      language: this.settings.language,
    };
    this.updateSettings(language);
  }

  updateCurrency() {
    const currencyDetails = {
      currencyCode: this.settings.currencyCode,
      currencyDisplaySymbol: this.settings.currencyDisplaySymbol,
      currencyDigits: this.settings.currencyDigits,
    };
    this.updateSettings(currencyDetails);
  }

  userInputSetting() {
    const applicationSetting = {
      dynamicUserTimeZone: this.settings.dynamicUserTimeZone,
      serverTimeZoneUTC: this.settings.serverTimeZoneUTC,
      serverTimeZone: this.settings.serverTimeZone,
      sessionLifeTime: this.settings.mapKleberResult,
      defaultSendInvitationSMS: this.settings.defaultSendInvitationSMS,
      displayEmojis: this.settings.displayEmojis,
      sessionRemeberedLifeTime: this.settings.mapKleberAutoComplet,
      fileAllowedExt: this.settings.fileAllowedExt,
      fileMaxSize: this.settings.fileMaxSize,
      dateFormat: this.settings.dateFormat,
      dateTimeFormat: this.settings.dateTimeFormat,
      timeFormat: this.settings.timeFormat,
      currencyCode: this.settings.currencyCode,
      currencyDisplaySymbol: this.settings.currencyDisplaySymbol,
      currencyDigits: this.settings.currencyDigits,
      defaultMobilePhonePrefix: this.settings['defaultMobilePhonePrefix'],
      defaultLandPhonePrefix: this.settings['defaultLandPhonePrefix'],
      defaultCountryPhonePrefix: this.settings['defaultCountryPhonePrefix'],
      phoneValidator: this.settings['phoneValidator'],
      emailValidator: this.settings['emailValidator'],
      mapValidator: this.settings['mapValidator'],
      isKleberEmailStrictMode: this.settings['isKleberEmailStrictMode'],
      isKleberPhoneStrictMode: this.settings['isKleberPhoneStrictMode'],
      phoneFormat: this.settings['phoneFormat'],
      landPhoneFormat: this.settings['landPhoneFormat'],
      dynamicPhonePrefix: this.settings['dynamicPhonePrefix'],
    };
    this.updateSettings(applicationSetting);
  }

  updateIntegration() {
    const applicationSetting = {
      calendarGuestBooking: this.settings.calendarGuestBooking,
      calendarConsumerBooking: this.settings.calendarConsumerBooking,
      calendarMerchantBooking: this.settings.calendarMerchantBooking,
      calendarFunderBooking: this.settings.calendarFunderBooking,
      calendarPartnerBooking: this.settings.calendarPartnerBooking,
      calendarSupplierBooking: this.settings.calendarSupplierBooking,
      calendarInvestorBooking: this.settings.calendarInvestorBooking,
      calendarSpecialistBooking: this.settings.calendarSpecialistBooking,

      consumerPublicDrift: this.settings.consumerPublicDrift,
      dentistPublicDrift: this.settings.dentistPublicDrift,
      specialistPublicDrift: this.settings.specialistPublicDrift,
      investorPublicDrift: this.settings.investorPublicDrift,
      merchantProspectPublicSiteDrift: this.settings.merchantProspectPublicSiteDrift,
      patientCardDrift: this.settings.patientCardDrift,
      merchantPublicSiteDrift: this.settings.merchantPublicSiteDrift,
      consumerDrift: this.settings.consumerDrift,
      merchantDrift: this.settings.merchantDrift,
      funderDrift: this.settings.funderDrift,
      supplierDrift: this.settings.supplierDrift,
      consumerLoginDrift: this.settings.consumerLoginDrift,
      merchantLoginDrift: this.settings.merchantLoginDrift,
      funderLoginDrift: this.settings.funderLoginDrift,
      supplierLoginDrift: this.settings.supplierLoginDrift,
      errorDrift: this.settings.errorDrift,
      unsubscribeDrift: this.settings.unsubscribeDrift,
    };
    this.updateSettings(applicationSetting);
  }

  updateFeatures() {
    const applicationSetting = {
      invitationLinkOnStep:this.settings.invitationLinkOnStep,
      demoBulkSMS:this.settings.demoBulkSMS,
      materialRequestActive:this.settings.materialRequestActive,
      scheduleErrorPeriod:this.settings.scheduleErrorPeriod,
      messageGroupDelay:this.settings.messageGroupDelay,
      messageGroupChunkLength:this.settings.messageGroupChunkLength,
      materialRequestPercent:this.settings.materialRequestPercent
    };
    this.updateSettings(applicationSetting);
  }


  updateCommunicationSetting() {
    const communicationSettinga = {
      listItemNumber: this.settings.listItemNumber,
      context: this.settings.context,
      logsLifeTime: this.settings.logsLifeTime,
      recordsLifeTime: this.settings.recordsLifeTime,
      nodeJSServer: this.settings.nodeJSServer,
      EPserver: this.EPServerlink,
      ECversion: this.settings.ECversion,
      listPageSize: this.settings.listPageSize,
      messageHost: this.settings.messageHost,
      kleberHost: this.settings.kleberHost,
      kleberWebServiceUrl: this.settings.kleberWebServiceUrl,
      kleverRequestKey: this.settings.kleverRequestKey,
      hubSpotKey: this.settings.hubSpotKey,
      hubSpotUserId: this.settings.hubSpotUserId,
      isWakandaIntegrated: this.settings.isWakandaIntegrated,
      wakandaInvitationCardLink: this.settings.wakandaInvitationCardLink,
      wakandaLALink: this.settings.wakandaLALink,
      wakandaPPLink: this.settings.wakandaPPLink,
      wakandaLink: this.settings.wakandaLink,
      vimeoApiToken: this.settings.vimeoApiToken,
      offlineMessage: this.offlineMessage,
      publicSiteFrontendLink: this.settings.publicSiteFrontendLink,
      merchantFrontendLink: this.settings.merchantFrontendLink,
      consumerFrontendLink: this.settings.consumerFrontendLink,
      promoterFrontendLink: this.settings.promoterFrontendLink,
      funderFrontendLink: this.settings.funderFrontendLink,
      supplierFrontendLink: this.settings.supplierFrontendLink,
      tmpFrontendLink: this.settings.tmpFrontendLink,
      integrationFrontendLink: this.settings.integrationFrontendLink,
      acticeSmsAuthentication: this.settings.acticeSmsAuthentication,
      S3DefaultRegion: this.settings.S3DefaultRegion,
      TinyAPIKey: this.settings.TinyAPIKey,
      FroalaAPIKey: this.settings.FroalaAPIKey,
      S3AccessKeyID: this.settings.S3AccessKeyID,
      secrectS3AccessKey: this.settings.secrectS3AccessKey,
      activeAWSS3: this.settings.activeAWSS3,
      defaultBucketName: this.settings.defaultBucketName,
      isFroalaEditorActive: this.settings.isFroalaEditorActive,
      isTinyEditorActive: this.settings.isTinyEditorActive,
      isUnlayerActive: this.settings.isUnlayerActive,
      facebookID: this.settings.facebookID,
      googleID: this.settings.googleID,
      AppleID: this.settings.AppleID,
      fbClientID: this.settings.fbClientID,
      googleClientID: this.settings.googleClientID,
      appleClientID: this.settings.appleClientID,
      msClientID: this.settings.msClientID,
      fbClientSecrect: this.settings.fbClientSecrect,
      appleClientSecrect: this.settings.appleClientSecrect,
      msClientSecrect: this.settings.msClientSecrect,
      googleClientSecrect: this.settings.googleClientSecrect,
      MarketingIsCampaign: this.settings.MarketingIsCampaign,
      MarketingTemplateMasterAndManual: this.settings.MarketingTemplateMasterAndManual,
      MarketingTemplateDefault: this.settings.MarketingTemplateDefault,
      MarketingManual: this.settings.MarketingManual,
      logActive: this.settings.logActive,
      useLocalSubPlanAccess: this.settings.useLocalSubPlanAccess,
      twilioID: this.settings.twilioID,
      twilioToken: this.settings.twilioToken,
      AWSS3Prefix: this.settings.AWSS3Prefix,
      AWSS3PublicFolder:this.settings.AWSS3PublicFolder,
      thumbnailS3Bucket: this.settings.thumbnailS3Bucket,
      thumbnailS3Folder: this.settings.thumbnailS3Folder,
    };

    let EPserverchanged = false;
    if (this.settings.EPserver != this.EPServerlink) {
      EPserverchanged = true;
    }

    this.settingsService.updateSettings(this.settings._id, communicationSettinga).subscribe(
      (res: any) => {
        if (res.success) {
          if (communicationSettinga['browserCacheLifeTime']) {
            this.settings.browserCacheLifeTime = Number(communicationSettinga['browserCacheLifeTime']) / 3600;
          }

          this.settingsService.getPublicSettings().subscribe((settings) => {
            if (settings && settings[0]) {
              const s = this.settingsService.setSettings(res.data);

              Settings.global = settings[0];
              if (Settings.global && Settings.global['pageTitle']) {
                document.title = Settings.global['pageTitle'];
              }

              if (s) {
                NotifyAppComponent.displayToast(
                  'success',
                  'Successful operation',
                  'Settings has been successfully updated'
                );

                const context = Settings.global['context'] || 'default';
                const translateHash = Settings.global['translateHash'] || '297749565899';
                if (context && translateHash) {
                  this.translate.use(context + '/' + translateHash);
                } else if (context) {
                  this.translate.use(context + '/297749565899');
                } else {
                  this.translate.use('default');
                }

                if (EPserverchanged == true) {
                  const confirm = new ConfirmDialog(
                    'fas fa-info',
                    '',
                    'Some of changes you made may affect your user experience , we advice you to reconnect to the application.',
                    'No, i want to  stay',
                    'Yes, log me out'
                  );

                  const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
                    data: confirm,
                  });
                  ref.componentInstance.onConfirm.subscribe((confirmation) => {
                    if (confirmation === false) {
                      ref.close();
                    } else {
                      ref.close();
                      this.logout();
                    }
                  });
                }
              }
            }
          });
        }
      },
      (err: any) => {
        console.log(err);
      }
    );
  }

  changeType() {
    if (this.templateType == 'masterAndManual') {
      this.settings['MarketingTemplateMasterAndManual'] = true;
      this.settings['MarketingTemplateDefault'] = false;
      this.settings['MarketingManual'] = false;
    } else if (this.templateType == 'master') {
      this.settings['MarketingTemplateMasterAndManual'] = false;
      this.settings['MarketingTemplateDefault'] = false;
      this.settings['MarketingManual'] = false;
    } else if (this.templateType == 'manual') {
      this.settings['MarketingTemplateMasterAndManual'] = false;
      this.settings['MarketingTemplateDefault'] = true;
      this.settings['MarketingManual'] = true;
    }
  }

  logout() {
    this.authService.logout().subscribe(
      (res) => {
        this.activeRoute.params.subscribe((params) => {
          this.authService.initCurrUser();
          if (params['consumerID']) {
            this.router.navigate(['/login', params['consumerID']]);
            // window.location.href = '/login/' + params['consumerID'];
          } else {
            this.router.navigate(['/login']);
            // window.location.href = "/login";
          }
        });
      },
      (err: any) => {
        console.log(err);
      }
    );
  }
  isFontAwsome(icon) {
    if (icon) {
      if (icon.indexOf('fas ') !== -1 || icon.indexOf('fab-') !== -1) {
        return true;
      }

      return false;
    }

    return false;
  }
  changeImage(fileInput, modelPreview, model) {
    this.settings[model] = fileInput.target.files[0];

    const reader = new FileReader();

    reader.onload = (e: any) => {
      this[modelPreview] = this.domSanitizer.bypassSecurityTrustUrl(e.target.result);
    };

    reader.readAsDataURL(fileInput.target.files[0]);
  }

  updateSettings(updatedData) {
    // const diff = this.utils.diffObjects(this.settings, this.oldSettings);

    this.settingsService.updateSettings(this.settings._id, updatedData).subscribe(
      (res: any) => {
        if (res.success) {
          if (updatedData.browserCacheLifeTime) {
            this.settings.browserCacheLifeTime = Number(updatedData.browserCacheLifeTime) / 3600;
          }

          this.settingsService.getPublicSettings().subscribe((settings) => {
            if (settings && settings[0]) {
              const s = this.settingsService.setSettings(res.data);

              Settings.global = settings[0];
              if (Settings.global && Settings.global['pageTitle']) {
                document.title = Settings.global['pageTitle'];
              }

              if (s) {
                NotifyAppComponent.displayToast(
                  'success',
                  'Successful operation',
                  'Settings has been successfully updated'
                );
              }
            }
          });
        }
      },
      (err: any) => {
        console.log(err);
      }
    );
  }

  fusionObject(source, target) {
    const result = source;
    for (const key in target) {
      if (target.hasOwnProperty(key) && !result.hasOwnProperty(key)) {
        result[key] = target[key];
      }
    }

    return result;
  }

  isFeature(label) {
    if (this.features.indexOf(label) !== -1) {
      return true;
    } else {
      return false;
    }
  }

  selectCurrency() {
    this.settings.currencyCode = this.settings.currency.symbol;
  }

  selectLang() {
    this.settings.availableLanguages.filter((lang, index) => {
      if (lang._id === this.settings.language) {
        this.languageCode = lang.code;
        this.languageLabel = lang.label;
      }
    });
  }

  removeExt(ext) {
    this.settings.fileAllowedExt.splice(this.settings.fileAllowedExt.indexOf(ext), 1);
  }

  addLanguagePage() {
    const ref = this.dialog.open(LanguageCreateComponent);
    ref.componentInstance.add.subscribe((data) => {
      this.settingsService.addLanguage(this.settings._id, data).subscribe(
        (res: any) => {
          if (res.success) {
            this.settingsService.getPublicSettings().subscribe((settings) => {
              if (settings && settings[0]) {
                const s = this.settingsService.setSettings(res.data);

                Settings.global = settings[0];
              }

              const s = this.settingsService.setSettings(res.data);
              this.settings = res.data;
              if (s) {
                NotifyAppComponent.displayToast(
                  'success',
                  'Successful operation',
                  'Settings has been successfully updated'
                );
              }
            });
          }
        },
        (err: any) => {
          console.log(err);
        }
      );
      ref.close();
    });
  }

  onCompletebranding(file, attribute) {
    if (file[0]) {
      this.settings[attribute] = file[0];
    }
  }

  getNewColorPalette(data) {
    this.newVariable.palette = data;
  }

  getNewCoreColor(color) {
    this.newVariable.color = color;
  }

  addNewVariable() {
    if (this.newVariable.color && this.newVariable.name) {
      this.colorVariables.push(JSON.parse(JSON.stringify(this.newVariable)));
      this.newVariable.name = null;
    }
  }

  removeColor(index) {
    if (this.colorVariables[index]) {
      this.colorVariables.splice(index, 1);
    }
  }

  isUploaded(res) {
    this.fileUploaded = res;
  }

  onCompleteAll(fileID) {
    if (fileID && fileID.length > 0) {
      this.themeScssFile = [fileID[0]];
    }
  }

  editCoreColor(color, index) {
    if (this.colorVariables[index] && this.colorVariables[index].color) {
      this.colorVariables[index].color = color;
    }
  }

  editColorPalette(data, index) {
    if (this.colorVariables[index] && data) {
      this.colorVariables[index].palette = data;
    }
  }

  updateCharts() {
    const chartsSetting = {
      chartShowXAxis: this.settings['chartShowXAxis'],
      chartShowYAxis: this.settings['chartShowYAxis'],
      chartShowLegend: this.settings['chartShowLegend'],
      chartShowXAxisLabel: this.settings['chartShowXAxisLabel'],
      chartShowYAxisLabel: this.settings['chartShowYAxisLabel'],
      chartShowGridLines: this.settings['chartShowGridLines'],
      chartAutoScale: this.settings['chartAutoScale'],
      chartShowDoughnut: this.settings['chartShowDoughnut'],
      chartShowTrimLabels: this.settings['chartShowTrimLabels'],
      chartShowTimeline: this.settings['chartShowTimeline'],
      chartShowRefLines: this.settings['chartShowRefLines'],
      chartShowRefLabels: this.settings['chartShowRefLabels'],
      chartBarPadding: this.settings['chartBarPadding'],
      chartShowLabel: this.settings['chartShowLabel'],
      chartGradient: this.settings['chartGradient'],
    };
    this.updateSettings(chartsSetting);
  }

  moduleUser(m) {
    if (m.code) {
      const isExisted = this.isModuleSynchronized(m.code);

      if (isExisted == true) {
        this.router.navigate(['/merchant', { outlets: { page: ['module-user-access', m.code] } }]);
      } else {
        if (m && m.code && m.title && m.description) {
          const ref = RootAppComponent.dialog.open(ModuleEditComponent, {
            data: {
              label: m.title,
              code: m.code,
              description: m.description,
            },
            width: '600px',
          });

          ref.componentInstance.closeModal.subscribe((res) => {
            ref.close();
          });

          ref.componentInstance.getResult.subscribe((res) => {
            if (res) {
              this.router.navigate(['/merchant', { outlets: { page: ['module-user-access', m.code] } }]);

              this.existingModuleCodes.push(m.code);
              ref.close();
            }
          });
        }
      }
    }
  }

  sliderChangeModuleBeta(value, m) {
    if (m && m.code) {
      if (value && value.checked == true) {
        const isExisted = this.isModuleSynchronized(m.code);

        if (isExisted == true) {
          this.settings[m.code + 'Beta'] = true;
          this.updateModules();
        } else {
          if (m && m.code && m.title && m.description) {
            const ref = RootAppComponent.dialog.open(ModuleEditComponent, {
              data: {
                label: m.title,
                code: m.code,
                description: m.description,
              },
              width: '600px',
            });

            ref.backdropClick().subscribe((res) => {
              this.settings[m.code + 'Beta'] = false;
            });

            ref.componentInstance.closeModal.subscribe((res) => {
              this.settings[m.code + 'Beta'] = false;
              ref.close();
            });

            ref.componentInstance.getResult.subscribe((res) => {
              if (res) {
                this.settings[m.code + 'Beta'] = true;
                this.updateModules();
                this.existingModuleCodes.push(m.code);
                ref.close();
              }
            });
          }
        }
      } else if (value && value.checked == false) {
        this.settings[m.code + 'Beta'] = false;

        this.updateModules();
      }
    }
  }

  isIndexOf(s, attribute) {
    if (s && attribute && s.indexOf(attribute) != -1) {
      return true;
    }

    return false;
  }

  getContent(c) {
    if (c) {
      this.offlineMessage = c;
    }
  }

  updatePassword() {
    if (this.isPromoterOrAdmin == true && this.settings && this.settings._id) {
      this.authService.isEPmaster().subscribe((res) => {
        if (res == true) {
          const confirm = new ConfirmDialog(
            'fas fa-info',
            'Do You want to proceed ?',
            'Your account is used as EP Master, we will have to log you out after changing Master Account',
            'No',
            'Yes'
          );

          const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
            data: confirm,
          });
          ref.componentInstance.onConfirm.subscribe((confirmation) => {
            if (confirmation === false) {
              ref.close();
            } else {
              const payLoad = {
                masterUsername: this.masterUsername,
                masterPassword: this.masterPassword,
                masterPasswordConfirmed: this.masterPasswordConfirmed,
              };

              this.settingsService.changeMaster(this.settings._id, payLoad).subscribe((res) => {
                if (res) {
                  this.authService.logout().subscribe(
                    (res) => {
                      ref.close();

                      this.router.navigate(['/login']);
                    },
                    (err: any) => {
                      console.log(err);
                    }
                  );
                } else {
                  ref.close();
                }
              });
            }
          });
        }
      });
    }
  }

  syncModuleEP(m) {
    if (m && m.code && m.title && m.description) {
      const ref = RootAppComponent.dialog.open(ModuleEditComponent, {
        data: {
          label: m.title,
          code: m.code,
          description: m.description,
        },
        width: '600px',
      });

      ref.componentInstance.closeModal.subscribe((res) => {
        ref.close();
      });

      ref.componentInstance.getResult.subscribe((res) => {
        if (res) {
          this.existingModuleCodes.push(m.code);
          ref.close();
        }
      });
    }
  }

  isModuleSynchronized(code) {
    if (this.existingModuleCodes && this.existingModuleCodes.length > 0) {
      if (code) {
        if (this.existingModuleCodes.indexOf(code) != -1) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return true;
    }
  }
}
