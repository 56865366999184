<mat-sidenav-container class="profile-container" *ngIf="merchant">
  <mat-sidenav class="profile-sidebar" #sidenavProfile [mode]="navMode" [opened]="openFlag" [position]="'end'">
    <div class="sticky">
      <section class="profile-header primary-gradient-img rel">
        <app-ep-document-view-image class="profile-lead-image" [defaultPicture]="
            'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/practice-pic.png'
          " [link]="profileLink" tabindex="0"></app-ep-document-view-image>

        <div class="profile-header-details">
          <h2>{{ merchant['TradingAs'] }}</h2>
          <p class="small" *ngIf="merchant['DateTimeCreated']">
            Created Date: {{ merchant['DateTimeCreated'] | customDateTime }}
          </p>
          <p class="small" *ngIf="merchant['LastModified_Human']">
            Last modifed Date: {{ merchant['LastModified_Human'] | customDateTime }}
          </p>
        </div>

        <div class="ctrlbtn" *ngIf="hideContents == false">
          <button class="documentCreated" (click)="uploadDocument()" mat-mini-fab color="accent"
                  matTooltip="Upload Document" mat-TooltipPosition="left">
            <mat-icon class="fas fa-file-upload"></mat-icon>
          </button>
        </div>
      </section>

      <div class="profile-sidebar-container">
        <div class="row clearfix">
          <button class="pull-right" *ngIf="isCreateTradingTime == false" (click)="setupTradingHours()" mat-icon-button>
            <mat-icon>create</mat-icon>
          </button>
          <h3 class="sr-title m-h2 rm-m">Business Hours</h3>
          <p class="small rm-mt">{{ "KEYWORD.practice" | translate | titlecase}} Timezone: {{ merchantTimezone }}</p>
          <div *ngIf="isCreateTradingTime != false">
            <p>Please Setup your trading hours</p>
            <button class="text-center" (click)="setupTradingHours()" mat-raised-button color="accent">Set up</button>
          </div>
          <div *ngIf="isCreateTradingTime == false">
            <mat-list>
              <mat-list-item class="flex flexList busHours" *ngFor="let t of tradingHours">
                <span class="small" *ngIf="t['NotOpen'] == '0'"><span class="dayLabel">{{ t['DayOfWeek.Label.Singular']
                  }}</span>
                  <span class="tradingHour">{{ util.timeConvert(t['OpeningTime'].slice(0, 5)) }} -
                    {{ util.timeConvert(t['ClosingTime'].slice(0, 5)) }}
                  </span>
                </span>
                <span class="small" *ngIf="t['NotOpen'] == '1'"><span class="dayLabel">{{ t['DayOfWeek.Label.Singular']
                  }}</span>
                  <span class="tradingHour">Close</span>
                </span>
              </mat-list-item>
            </mat-list>
          </div>
        </div>
      </div>
    </div>
  </mat-sidenav>

  <div class="profile-content">
    <div class="full-width toggle-card mb clearfix">
      <mat-button-toggle-group class="btn-group session-profile rm-m mat-elevation-z4 full-width flex"
                               [(ngModel)]="settings">
        <mat-button-toggle class="full-width text-center" [value]="'practice'">
          <mat-icon class="fas fa-store-alt"></mat-icon>
          {{ "KEYWORD.practice" | translate | titlecase}} Details
        </mat-button-toggle>

        <mat-button-toggle class="full-width text-center" [value]="'module'">
          <mat-icon class="fas fa-cubes"></mat-icon>
          {{ "KEYWORD.practice" | translate | titlecase}} Modules
        </mat-button-toggle>

        <mat-button-toggle class="full-width text-center" [value]="'staff'">
          <mat-icon class="fas fa-users"></mat-icon>
          {{ "KEYWORD.practice" | translate | titlecase}} Staff
        </mat-button-toggle>

        <mat-button-toggle class="full-width text-center" [value]="'invitation'">
          <mat-icon class="fas fa-cogs"></mat-icon>
          Invitation Settings
        </mat-button-toggle>

        <mat-button-toggle class="full-width text-center" [value]="'marketing'">
          <mat-icon class="fas fa-screwdriver"></mat-icon>
          Marketing Settings
        </mat-button-toggle>

        <mat-button-toggle class="full-width text-center" *ngIf="hideContents == false" [value]="'message'">
          <mat-icon class="fas fa-comments"></mat-icon>
          Messages
        </mat-button-toggle>

        <mat-button-toggle class="full-width text-center" [value]="'notes'">
          <mat-icon class="fas fa-sticky-note"></mat-icon>
          Notes
        </mat-button-toggle>

        <mat-button-toggle class="full-width text-center" [value]="'sms'">
          <mat-icon class="fas fa-sms"></mat-icon>
          SMS
        </mat-button-toggle>

        <mat-button-toggle class="full-width text-center" *ngIf="hideContents == false" [value]="'document'">
          <mat-icon class="fas fa-file-alt"></mat-icon>
          Documents
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <!-- prac settings -->
    <div class="tab-content clearfix" *ngIf="settings == 'practice'">
      <div class="row clearfix">
        <form #PracticeDetails="ngForm">
          <div class="row clearfix flex flexwrap">
            <!-- name and personal info -->
            <mat-card class="card full-width two-third">
              <div class="colour-stripe"></div>
              <h3 class="sr-title rm-m sm-h3">Main {{ "KEYWORD.practice" | translate }} details</h3>
              <hr class="mb"/>
              <div class="row clearfix">
                <div class="row clearfix flex full-width">
                  <div class="icons-col">
                    <mat-icon>store_mall_directory</mat-icon>
                  </div>
                  <div class="row-content-col flex">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Trading as</mat-label>
                      <input [(ngModel)]="merchant['TradingAs']" name="Trading" matInput placeholder="Trading as"
                             required/>
                    </mat-form-field>
                    <mat-form-field class="full-width" *ngIf="merchant['CardType'] != 'Commercial - Sole-Trader'"
                                    appearance="outline">
                      <mat-label>Legal entity name</mat-label>
                      <input [(ngModel)]="practiceLegalName" name="entity" matInput placeholder="Legal entity name"
                             readonly/>
                    </mat-form-field>
                    <mat-form-field class="full-width" *ngIf="merchant['CardType'] == 'Commercial - Sole-Trader'"
                                    appearance="outline">
                      <mat-label>Legal entity name</mat-label>
                      <input [(ngModel)]="practiceLegalName" name="entity" matInput placeholder="Legal entity name"
                             readonly/>
                    </mat-form-field>
                  </div>
                </div>

                <div class="row clearfix flex full-width">
                  <div class="icons-col">
                    <mat-icon>domain</mat-icon>
                  </div>
                  <div class="row-content-col flex">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>ABN</mat-label>
                      <input [(ngModel)]="merchant['ABN']" name="ABN" matInput placeholder="ABN"/>
                    </mat-form-field>
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>ACN</mat-label>
                      <input [(ngModel)]="merchant['ACN']" name="ACN" matInput placeholder="ACN"/>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </mat-card>

            <mat-card class="card full-width third">
              <div class="colour-stripe"></div>
              <h3 class="sr-title rm-m sm-h3">{{ "KEYWORD.practice" | translate | titlecase}} Image</h3>
              <hr class="mb"/>
              <div class="row clearfix text-center mt">
                <div class="upload-container">
                  <app-file-uploader class="text-center clearfix full-width noLowerPad avatarUploader"
                                     [allowedExtensionTypes]="['image']" [limit]="1" [uploadDirectly]="true"
                                     [isDragable]="true"
                                     [keepOriginal]="false" [isFixedAspectRatio]="true" [resizeToHeight]="400"
                                     [resizeToWidth]="400"
                                     (onCompleteAll)="onCompleteAllPractice($event)">
                  </app-file-uploader>
                  <app-ep-document-view-image class="profile-lead-image sml" [defaultPicture]="
                      'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/profile_placeholder.png)'
                    " [link]="profileLink">
                  </app-ep-document-view-image>
                </div>
                <p class="small">
                  To upload a profile picture, please drag a <strong>.jpg or .png file</strong> into the grey area.
                </p>
              </div>
            </mat-card>

            <mat-card class="card full-width">
              <div class="colour-stripe"></div>
              <h3 class="sr-title rm-m sm-h3">Profile Description</h3>
              <hr class="mb"/>
              <div class="row clearfix">
                <p class="small rm-mb">
                  Add some descriptive text about yourself, which will be seen by {{ "KEYWORD.patient" | translate }}s
                  and {{"KEYWORD.dentist" | translate}}s previewing your
                  profile.
                </p>
                <app-text-editor-input class="clearfix" [content]="profileDescription" [isPreview]="false"
                                       [title]="null" [isAction]="false" [isMultiView]="false"
                                       (getContent)="getProfileContent($event)"
                                       name="editor">
                </app-text-editor-input>
              </div>
            </mat-card>

            <mat-card class="card full-width third">
              <div class="colour-stripe"></div>
              <h3 class="sr-title rm-m sm-h3">
                Social Media
              </h3>
              <hr class="mb"/>

              <div class="row clearfix">
                <div class="row clearfix flex">
                  <div class="icons-col">
                    <mat-icon class="fas fa-globe"></mat-icon>
                  </div>
                  <div class="row-content-col">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Personal Website</mat-label>
                      <input [(ngModel)]="merchant['URL']" name="URL" matInput placeholder="Please enter the address"/>
                    </mat-form-field>
                  </div>
                </div>

                <div class="row clearfix flex">
                  <div class="icons-col">
                    <mat-icon class="fab fa-facebook"></mat-icon>
                  </div>
                  <div class="row-content-col">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Facebook</mat-label>
                      <input [(ngModel)]="merchant['Facebook']" name="facebook" matInput placeholder="Facebook"/>
                    </mat-form-field>
                  </div>
                </div>

                <div class="row clearfix flex">
                  <div class="icons-col">
                    <mat-icon class="fab fa-instagram"></mat-icon>
                  </div>
                  <div class="row-content-col">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Instagram</mat-label>
                      <input [(ngModel)]="merchant['Instagram']" name="instagram" matInput placeholder="Instagram"/>
                    </mat-form-field>
                  </div>
                </div>

                <div class="row clearfix flex">
                  <div class="icons-col">
                    <mat-icon class="fab fa-twitter"></mat-icon>
                  </div>
                  <div class="row-content-col">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Twitter</mat-label>
                      <input [(ngModel)]="merchant['Twitter']" name="twitter" matInput placeholder="Twitter"/>
                    </mat-form-field>
                  </div>
                </div>

                <div class="row clearfix flex">
                  <div class="icons-col">
                    <mat-icon class="fab fa-linkedin"></mat-icon>
                  </div>
                  <div class="row-content-col">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>LinkedIn</mat-label>
                      <input [(ngModel)]="merchant['LinkedIn']" name="linkedin" matInput placeholder="LinkedIn"/>
                    </mat-form-field>
                  </div>
                </div>

                <div class="row clearfix flex">
                  <div class="icons-col">
                    <mat-icon class="fab fa-pinterest"></mat-icon>
                  </div>
                  <div class="row-content-col">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Pinterest</mat-label>
                      <input [(ngModel)]="merchant['Pinterest']" name="Pinterest" matInput placeholder="Pinterest"/>
                    </mat-form-field>
                  </div>
                </div>

                <div class="row clearfix flex">
                  <div class="icons-col">
                    <mat-icon class="fab fa-tumblr"></mat-icon>
                  </div>
                  <div class="row-content-col">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Tumblr</mat-label>
                      <input [(ngModel)]="merchant['Tumblr']" name="Tumblr" matInput placeholder="Tumblr"/>
                    </mat-form-field>
                  </div>
                </div>

                <div class="row clearfix flex">
                  <div class="icons-col">
                    <mat-icon class="fab fa-vimeo"></mat-icon>
                  </div>
                  <div class="row-content-col">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Vimeo</mat-label>
                      <input [(ngModel)]="merchant['Vimeo']" name="Vimeo" matInput placeholder="Vimeo"/>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </mat-card>

            <mat-card class="card full-width two-third">
              <div class="colour-stripe"></div>
              <h3 class="sr-title rm-m sm-h3">Address details</h3>
              <hr class="mb"/>
              <app-address-input class="full-width" [displayMap]="false" [canClose]="false"
                                 [postCode]="merchantPostCode" [state]="merchantState" [suburb]="merchantSuburb"
                                 [streetNumber]="merchantStreetNumber" [streetName]="merchantStreetName"
                                 [unitNumber]="merchantUnitNumber" [streetType]="merchantStreetType"
                                 [latitude]="merchantLatitude"
                                 [longitude]="merchantLongitude" [isRequired]="true" (getAdress)="getAdress($event)"
                                 (validate)="isAdressValidEvent($event)">
              </app-address-input>
            </mat-card>

            <mat-card class="card full-width third">
              <div class="colour-stripe"></div>
              <h3 class="sr-title rm-m sm-h3">Contact Details</h3>
              <hr class="mb"/>
              <div class="row clearfix">
                <div class="row clearfix flex">
                  <div class="icons-col">
                    <mat-icon>phone</mat-icon>
                  </div>
                  <div class="row-content-col">
                    <div class="row clearfix">
                      <app-phone-input class="full-width" [isLandLine]="true" [label]="'Phone Number'" [required]="true"
                                       [phoneNumber]="merchantPhone" (validate)="validatePhoneEvent($event)"
                                       (getPhone)="getPhone($event)">
                      </app-phone-input>
                    </div>
                  </div>
                </div>
                <div class="row clearfix flex">
                  <div class="icons-col">
                    <mat-icon>phone_android</mat-icon>
                  </div>
                  <div class="row-content-col">
                    <div class="row clearfix">
                      <app-phone-input class="full-width" [isLandLine]="false" [label]="'Mobile'" [required]="false"
                                       [phoneNumber]="merchantMobile" (validate)="validateMobileEvent($event)"
                                       (getPhone)="getMobile($event)">
                      </app-phone-input>
                    </div>
                  </div>
                </div>
                <div class="row clearfix flex">
                  <div class="icons-col">
                    <mat-icon>email</mat-icon>
                  </div>
                  <div class="row-content-col">
                    <div class="row clearfix">
                      <app-email-input class="full-width" [label]="'Email'" [required]="true" [email]="merchantEmail"
                                       (getEmail)="getEmail($event)" (validate)="validateEmailEvent($event)">
                      </app-email-input>
                    </div>
                  </div>
                </div>
              </div>
            </mat-card>

            <mat-card class="card full-width third">
              <div class="colour-stripe"></div>

              <div style="display: flex; justify-content: space-between">
                <h3 class="sr-title rm-m sm-h3">Services Provided</h3>
                <div>
                  <button (click)="openAddServiceProfilePropertyDialog()" mat-icon-button
                          style="display: block; height: 20px; line-height: 20px">
                    <mat-icon> edit_mode</mat-icon>
                  </button>
                </div>
              </div>
              <hr class="mb"/>

              <mat-chip-list>
                <mat-chip *ngFor="let merchantProfileService of merchantProfileServices" [color]="'primary'" selected>{{
                  merchantProfileService['Service_Group_Item.Label']
                  }}</mat-chip>
              </mat-chip-list>
            </mat-card>

            <mat-card class="card full-width third" *ngIf="context!='p4p' && context!='breeze' ">
              <div class="colour-stripe"></div>

              <div style="display: flex; justify-content: space-between">
                <h3 class="sr-title rm-m sm-h3">Health Funds</h3>
                <div>
                  <button (click)="openAddHealthFundProfileProperty()" mat-icon-button
                          style="display: block; height: 20px; line-height: 20px">
                    <mat-icon> edit_mode</mat-icon>
                  </button>
                </div>
              </div>
              <hr class="mb"/>

              <mat-chip-list>
                <mat-chip *ngFor="let merchantProfileHealthFund of merchantProfileHealthFunds" [color]="'primary'"
                          selected>{{ merchantProfileHealthFund['HealthFund.Label'] }}</mat-chip>
              </mat-chip-list>
            </mat-card>

            <mat-card class="card full-width third" *ngIf="context!='p4p' && context!='breeze' ">
              <div class="colour-stripe"></div>

              <div style="display: flex; justify-content: space-between">
                <h3 class="sr-title rm-m sm-h3">{{"PHRASE.dentistry practiced" | translate | titlecase}} </h3>
                <div>
                  <button (click)="openAddDentistryTypesProperty()" mat-icon-button
                          style="display: block; height: 20px; line-height: 20px">
                    <mat-icon> edit_mode</mat-icon>
                  </button>
                </div>
              </div>
              <hr class="mb"/>

              <mat-chip-list>
                <mat-chip *ngFor="let merchantProfileDentistryType of merchantProfileDentistryTypes" [color]="'primary'"
                          selected>{{ merchantProfileDentistryType['Label'] }}</mat-chip>
              </mat-chip-list>
            </mat-card>

            <mat-card class="card full-width third">
              <div class="colour-stripe"></div>
              <h3 class="sr-title rm-m sm-h3">{{ "KEYWORD.patient" | translate | titlecase }} communication</h3>
              <hr class="mb"/>
              <div class="row clearfix">
                <div class="row clearfix flex">
                  <div class="icons-col">
                    <mat-icon class="fas fa-reply-all"></mat-icon>
                  </div>
                  <div class="row-content-col">
                    <app-email-input class="full-width" [email]="merchantReplyToEmail" [label]="'Reply-to address'"
                                     (getEmail)="getReplyToEmail($event)"
                                     (validate)="validateReplyToEmailEvent($event)">
                    </app-email-input>
                    <p class="small">
                      Enter an email address here and we will automatically forward you any
                      {{ "KEYWORD.patient" | translate }} responses to communication sent via the platform.
                    </p>
                  </div>
                </div>

                <div class="row clearfix flex">
                  <div class="icons-col">
                    <mat-icon class="fas fa-calendar-check"></mat-icon>
                  </div>
                  <div class="row-content-col">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Enter URL</mat-label>
                      <input [(ngModel)]="merchant.ThirdPartyBooking_URL" name="thirdPartyUrl" matInput
                             placeholder="Enter URL"/>
                    </mat-form-field>

                    <p class="small">
                      At the end of the invitation process you can route your {{ "KEYWORD.patient" | translate }}s to
                      your third-party booking engine by entering the URL here.

                      <br/>(Be sure to add the entire address, <strong>including</strong> the 'https' part of the URL.
                    </p>
                  </div>
                </div>
              </div>
            </mat-card>
          </div>
        </form>
        <div class="row clearfix">
          <!-- back btn -->
          <button class="pull-left" (click)="goBack()" mat-raised-button color="primary">
            <mat-icon>chevron_left</mat-icon>
            Back
          </button>

          <button class="pull-right" [disabled]="!PracticeDetails.form.valid" (click)="editPractice()" mat-raised-button
                  color="accent">
            <mat-icon>save</mat-icon>
            Save
          </button>
        </div>
      </div>
    </div>

    <!-- module settings -->
    <div class="tab-content clearfix" *ngIf="settings == 'module'">
      <app-user-access-view class="full-width" [cardID]="merchant['ID']"></app-user-access-view>
      <!-- back btn -->
      <div class="row clearfix mt">
        <button class="" (click)="goBack()" mat-raised-button color="primary">
          <mat-icon>chevron_left</mat-icon>
          Back
        </button>
      </div>
    </div>

    <!-- staff setting tab -->
    <div class="tab-content clearfix" *ngIf="settings == 'staff'">
      <div class="row clearfix full-width" *ngIf="!dentistID">
        <button class="pull-right merchBtn mr ml"
          [routerLink]="['/merchant/', { outlets: { page: ['dentist-invite-create'] } }]"
                [routerLinkActive]="['active']" mat-raised-button color="accent">
          <mat-icon>person_add</mat-icon>
          Add a Staff Member
        </button>
        <app-dentist-list class="noEmbedCard full-width" [merchantID]="merchant['ID']"></app-dentist-list>
      </div>

      <div class="row clearfix full-width" *ngIf="dentistID">
        <div class="full-width rel">
          <button (click)="displayDentistList()" mat-raised-button color="accent">
            <mat-icon>chevron_left</mat-icon>
            {{ "KEYWORD.dentist" | translate | titlecase }} List
          </button>
        </div>
        <app-dentist-profile [dentistID]="dentistID" [hideSide]="true"></app-dentist-profile>
      </div>
      <!-- back btn -->
      <div class="row clearfix adjust-staff">
        <button class="" (click)="goBack()" mat-raised-button color="primary">
          <mat-icon>chevron_left</mat-icon>
          Back
        </button>
      </div>
    </div>

    <!-- activity-log setting tab -->
    <div class="tab-content clearfix" *ngIf="settings == 'notes'">
      <ipv-notes-settings></ipv-notes-settings>

      <!-- back btn -->
      <div class="row clearfix adjust-invitation">
        <button class="" (click)="goBack()" mat-raised-button color="primary">
          <mat-icon>chevron_left</mat-icon>
          Back
        </button>
      </div>
    </div>

    <!-- sms setting tab -->
    <div class="tab-content clearfix" *ngIf="settings == 'sms'">
      <app-default-sms-settings></app-default-sms-settings>

      <!-- back btn -->
      <div class="row clearfix adjust-invitation">
        <button class="" (click)="goBack()" mat-raised-button color="primary">
          <mat-icon>chevron_left</mat-icon>
          Back
        </button>
      </div>
    </div>

    <!-- invitation setting tab -->
    <div class="tab-content clearfix" *ngIf="settings == 'invitation'">
      <app-invitation-settings></app-invitation-settings>

      <!-- back btn -->
      <div class="row clearfix adjust-invitation">
        <button class="" (click)="goBack()" mat-raised-button color="primary">
          <mat-icon>chevron_left</mat-icon>
          Back
        </button>
      </div>
    </div>

    <div class="tab-content clearfix" *ngIf="settings == 'marketing'">
      <app-marketing-settings class="full-width" [merchantID]="merchant['ID']" [hideFilter]="true">
      </app-marketing-settings>
    </div>

    <div class="tab-content clearfix" *ngIf="settings == 'message'">
      <app-message-timeline class="full-width" [cardID]="merchant['ID']"></app-message-timeline>

      <!-- back btn -->
      <div class="row clearfix mt">
        <button class="" (click)="goBack()" mat-raised-button color="primary">
          <mat-icon>chevron_left</mat-icon>
          Back
        </button>
      </div>
    </div>

    <div class="tab-content clearfix" *ngIf="settings == 'activity-log'">
      <app-action-log-list class="full-width" *ngIf="isPromoterOrAdmin != true" [targetID]="merchant['ID']"
                           [withoutAutoAction]="true" [withoutInternalAction]="true"></app-action-log-list>
      <app-action-log-list class="full-width" *ngIf="isPromoterOrAdmin == true" [targetID]="merchant['ID']">
      </app-action-log-list>

      <!-- back btn -->
      <div class="row clearfix mt">
        <button class="" (click)="goBack()" mat-raised-button color="primary">
          <mat-icon>chevron_left</mat-icon>
          Back
        </button>
      </div>
    </div>

    <div class="tab-content clearfix" *ngIf="settings == 'document'">
      <app-document-list-mini class="full-width" [cardID]="merchant['ID']"></app-document-list-mini>

      <!-- back btn -->
      <div class="row clearfix mt">
        <button class="" (click)="goBack()" mat-raised-button color="primary">
          <mat-icon>chevron_left</mat-icon>
          Back
        </button>
      </div>
    </div>
  </div>
</mat-sidenav-container>
