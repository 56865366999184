import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, Inject, Input, OnInit, Optional, ViewContainerRef } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-details-card-lending',
  templateUrl: './details-card-lending.component.html',
  styleUrls: ['./details-card-lending.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('mat-card', style({ opacity: 0 }), { optional: true }),
        query(
          'mat-card',
          stagger('100ms', [
            animate(
              '0.4s ease-out',
              keyframes([
                style({ opacity: 0, offset: 0, height: '0' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('mat-card', style({ opacity: 1 }), { optional: true }),
        query(
          'mat-card',
          stagger('100ms', [
            animate(
              '0.2s ease-out',
              keyframes([
                style({ opacity: 1, offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, offset: 1.0, height: '0' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class DetailsCardLendingComponent implements OnInit {
  @Input() customerID;

  displayedColumns = ['LastName', 'FirstName', 'ContactType', 'mobile', 'Mailout', 'messageType', 'Actions'];

  displayedColumnsQ = ['Date', 'QuotationStatus', 'ContractStatus', 'LoanAmount', 'Product', 'ProductType', 'Actions'];
  displayedColumnsC = ['Date', 'ContractStatus', 'LoanAmount', 'Product', 'ProductType', 'Actions'];
  displayedColumnsPM = ['CardType', 'BSB', 'AccountNumber', 'Expiry', 'AccountHolder', 'Active', 'Actions'];
  displayedColumnsPH = ['DueDate', 'Paid', 'Amount', 'Status', 'Rejected', 'Type', 'Actions'];

  constructor(
    public viewContainerRef: ViewContainerRef,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.customerID) {
        this.customerID = data.customerID;
      }
    }
  }

  ngOnInit() {}
}
