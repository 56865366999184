<ng-container *ngIf="industryType$ | async as industryType">
  <ng-container [ngSwitch]="industryType">
    <ipv-retail-product-list
      class="list-filter-card clearfix full-width"
      *ngSwitchCase="'retail'"
      [supplierID]="supplierID"
      [pageMode]="pageMode"
      [modalTitle]="modalTitle"
      (getLength)="getProductLength($event)"
      (productSelected)="setProductSelected($event)"
    >
    </ipv-retail-product-list>

    <ipv-retail-product-list
      class="list-filter-card clearfix full-width"
      *ngSwitchCase="'breeze'"
      [supplierID]="supplierID"
      [pageMode]="pageMode"
      [modalTitle]="modalTitle"
      (productSelected)="setProductSelected($event)"
      (getLength)="getProductLength($event)"
    >
    </ipv-retail-product-list>

    <ipv-dental-product-list
      class="list-filter-card clearfix full-width"
      *ngSwitchCase="'none'"
      [pageSize]="pageSize"
      [miniDisplay]="miniDisplay"
      [supplierID]="supplierID"
      [defaultHeading]="defaultHeading"
      (getLength)="getProductLength($event)"
    >
    </ipv-dental-product-list>

    <ipv-dental-product-list
      class="list-filter-card clearfix full-width"
      *ngSwitchCase="'dental'"
      [pageSize]="pageSize"
      [miniDisplay]="miniDisplay"
      [supplierID]="supplierID"
      [defaultHeading]="defaultHeading"
      (getLength)="getProductLength($event)"
    >
    </ipv-dental-product-list>
  </ng-container>
</ng-container>
