import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { WizardComponent } from 'angular-archwizard';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { LookupService } from '../../../shared/services/lookup.service';
import { Settings } from '../../../shared/types/settings';
import { InvitationLookupComponent } from '../../invitation/invitation-lookup/invitation-lookup.component';
import { InvitationService } from '../../invitation/shared/services/invitation.service';
import { MerchantLookupComponent } from '../../merchant/merchant-lookup/merchant-lookup.component';
import { SmsConversationComponent } from '../../message/sms-conversation/sms-conversation.component';
import { SmsPromoterComponent } from '../../message/sms-promoter/sms-promoter.component';
import { ReferralCreateSelectMerchantComponent } from '../referral-create-select-merchant/referral-create-select-merchant.component';

@Component({
  selector: 'app-referral-create',
  templateUrl: './referral-create.component.html',
  styleUrls: ['./referral-create.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.anim', style({ opacity: 0 }), { optional: true }),
        query(
          '.anim',
          stagger('100ms', [
            animate(
              '0.3s ease-out',
              keyframes([
                style({ opacity: 0, offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.anim', style({ opacity: 1 }), { optional: true }),
        query(
          '.anim',
          stagger('100ms', [
            animate(
              '0.3s ease-in',
              keyframes([
                style({ opacity: 1, offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class ReferralCreateComponent implements OnInit {
  @ViewChild(WizardComponent, { static: true }) public wizard: WizardComponent;

  headerType: String = 'referral';

  isAdult = true;

  messageOptions;
  dentMsg;

  invitationType: any = [];

  user = {
    firstname: '',
    title: '',
    lastname: '',
    middlename: '',
    gender: '',
    mobile: '',
    home_phone: '',
    work_phone: '',
    email: '',
  };

  isPromoterOrAdmin = false;

  newMerchant = false;

  genders = [];
  titles = [];
  merchantID;
  invitationID = '';

  senders = [];
  selectedSender = null;
  isSelectedSender = true;
  sendSMS = false;
  sendDate = new Date();
  sendTime = new Date();

  addMsg = false;

  align;

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private lookupService: LookupService,
    private invitationService: InvitationService
  ) {}

  ngOnInit() {
    this.lookupService.getLookup('CodeLookup', 'Gender').subscribe((data) => {
      this.genders = data;
    });
    this.lookupService.getLookup('SimpleLookup', 'Salutation').subscribe((data) => {
      this.titles = data;
    });
  }

  openAddMsg() {
    this.addMsg = !this.addMsg;
  }

  openPatientLookUpDialog() {
    const ref = this.dialog.open(InvitationLookupComponent, {
      data: {
        invitationID: this.invitationID,
        merchantID: this.merchantID,
        useStrict: true,
      },
      width: '600px',
    });

    ref.componentInstance.patientChat.subscribe((res) => {
      if (res && res.cardID) {
        if (res.isPromoterOrAdmin == true) {
          const ref2 = RootAppComponent.dialog.open(SmsPromoterComponent, {
            data: {
              merchantID: res.merchantID,
              cardID: res.cardID,
              singleChat: true,
            },
            panelClass: 'noCard',
            width: '500px',
          });
          ref2.componentInstance.close.subscribe((data) => {
            ref2.close();
          });

          ref2.componentInstance.getResult.subscribe((data) => {
            if (data) {
              const ref3 = RootAppComponent.dialog.open(SmsConversationComponent, {
                data,
                panelClass: 'bigger-screen',
                width: '70%',
                height: '95vh',
              });
              const sub = ref3.componentInstance.close.subscribe((data) => {
                ref3.close();
              });
            }

            ref2.close();
          });
        } else {
          const ref2 = RootAppComponent.dialog.open(SmsConversationComponent, {
            data: {
              merchantID: res.merchantID,
              cardID: res.cardID,
              singleChat: true,
            },
            panelClass: 'bigger-screen',
            width: '70%',
            height: '95vh',
          });
          const sub = ref2.componentInstance.close.subscribe((data) => {
            ref2.close();
          });
        }
      }
    });

    const sub = ref.componentInstance.close.subscribe((data) => {
      ref.close();
    });
    ref.componentInstance.detailedView.subscribe((res) => {
      ref.close();
    });
  }

  openMerchantLookUpDialog() {
    const ref = this.dialog.open(MerchantLookupComponent, {
      data: {
        invitationID: this.invitationID,
        merchantID: this.merchantID,
      },
      width: '550px',
    });
    const sub = ref.componentInstance.close.subscribe((data) => {
      ref.close();
    });
  }

  displayMerchantOption() {
    const ref = this.dialog.open(ReferralCreateSelectMerchantComponent, {
      data: {
        invitationID: this.invitationID,
        merchantID: this.merchantID,
      },
      width: '550px',
    });

    const subNew = ref.componentInstance.chooseNewMerchant.subscribe((data) => {
      this.newMerchant = true;
      // console.log('enter a new merchant');
      ref.close();
    });

    const subSel = ref.componentInstance.chooseSelMerchant.subscribe((data) => {
      this.newMerchant = false;
      // console.log('display merchant select');
      ref.close();

      const ref2 = this.dialog.open(MerchantLookupComponent, {
        data: {},
        width: '550px',
      });
    });
  }

  viewSentReferral() {
    const url = Settings.global['consumerFrontendLink'] + '/consumer/(page:referral-card)';
    window.open(url, '_blank');
  }

  onStepChange() {
    const step = this.wizard.currentStepIndex;
    this.invitationService.setProgress(this.invitationID, step).subscribe(() => {});
  }

  progress(p) {
    let step = 0;
    if (!p) {
      step = 0;
    } else if (p && p.page == 'create') {
      step = p.step;
    } else {
      step = 3;
    }

    switch (step) {
      case 0: {
        this.wizard.goToStep(0);
        break;
      }
      case 1: {
        // this.step2()
        break;
      }
      case 2: {
        // this.step3()
        break;
      }
      default: {
        this.wizard.goToStep(3);
      }
    }
  }

  cancel() {
    this.router.navigate(['/merchant', { outlets: { page: ['home'] } }]);
  }
}
