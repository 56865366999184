import { Component, Input, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { UtilsService } from '../../services/utils.service';
import { ConfirmDialog } from '../../types/confirm-dialog';
import { NotifyAppComponent } from '../../types/notify-app-component';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { EmailLabelInputComponent } from '../email-label-input/email-label-input.component';
import { RootAppComponent } from '../root-component/root-component.component';

@Component({
  selector: 'app-email-input-advanced',
  templateUrl: './email-input-advanced.component.html',
  styleUrls: ['./email-input-advanced.component.css'],
})
export class EmailInputAdvancedComponent implements OnInit {
  @Input()
  targetID;

  @Input()
  targetEmailType;

  @Input()
  emailTypes;

  @Input()
  myEmail;

  @Input()
  isDisabled = false;

  emailType;
  isPrimaryEmail = true;
  @Input()
  type = 'current';
  sessionType;

  constructor(private utilService: UtilsService, private authenticationService: AuthenticationService) {}

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((st) => {
      this.sessionType = st;

      this.emailType = this.targetEmailType;
    });
  }

  createEmailLabel() {
    const ref = RootAppComponent.dialog.open(EmailLabelInputComponent, {
      data: {
        ownerID: this.targetID,
        type: this.type,
      },
      width: '800px',
    });

    ref.componentInstance.getResult.subscribe((res) => {
      if (res) {
        ref.close();
        this.emailType = res.Label;
        this.emailTypes.push(res.Label);
        if (res['IsPrimary'] == '1') {
          this.targetEmailType = res.Label;
        }
        this.getEmailByType(res.Label);
      }
    });

    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
        this.emailType = this.targetEmailType;
        this.getEmailByType(this.emailType);
      }
    });
  }

  removeEmailByLabel(label) {
    const confirmDialog = new ConfirmDialog(
      'warning',
      'Delete Email',
      'Are you sure to remove this email with label permanently?',
      'Cancel',
      'Proceed'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
      width: '650px',
    });

    ref.componentInstance.onConfirm.subscribe((res) => {
      if (res == true) {
        this.utilService.deleteEmailByLabel(this.targetID, label, this.type, this.sessionType).subscribe((res) => {
          if (res) {
            NotifyAppComponent.displayToast('success', 'Delete Email', 'You have successfully deleted this email');
            this.emailType = this.targetEmailType;
            this.emailTypes.splice(this.emailTypes.indexOf(label), 1);
            this.getEmailByType(this.emailType);
            ref.close();
          }
        });
      } else {
        ref.close();
      }
    });
  }

  editEmailByLabel(label) {
    const ref = RootAppComponent.dialog.open(EmailLabelInputComponent, {
      data: {
        ownerID: this.targetID,
        label,
        isEdit: true,
        email: this.myEmail,
        emailTypes: this.emailTypes,
        isPrimary: this.isPrimaryEmail,
        type: this.type,
      },
      width: '800px',
    });

    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });

    ref.componentInstance.getResult.subscribe((res) => {
      if (res) {
        ref.close();
        this.emailType = res.Label;
        if (res['IsPrimary'] == '1') {
          this.targetEmailType = res.Label;
        }
        this.getEmailByType(res.Label);
      }
    });
  }

  getEmailByType(e) {
    if (e) {
      this.utilService.getEmailByLabel(this.targetID, e, this.type, this.sessionType).subscribe((res) => {
        if (res) {
          this.myEmail = res['Email'];
          this.isPrimaryEmail = res['IsPrimary'] == '1' ? true : false;
        }
      });
    }
  }

  convertToLowerCase(val) {
    if (val) {
      return val.toLowerCase();
    }
  }
}
