import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-google-reviews-prompt-dialog',
  templateUrl: './google-reviews-prompt-dialog.component.html',
  styleUrls: ['./google-reviews-prompt-dialog.component.css'],
})
export class GoogleReviewsPromptDialogComponent implements OnInit {
  @Input()
  title = 'Google Review';

  onConfirm = new EventEmitter();

  patientName = 'Patient Name';
  practiceName = 'Practice Name';
  merchantID: string;

  @Output()
  close = new EventEmitter();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<GoogleReviewsPromptDialogComponent>
  ) {
    if (this.data) {
      this.patientName = this.data.patientName !== undefined ? this.data.patientName : this.patientName;
      this.practiceName = this.data.practiceName !== undefined ? this.data.practiceName : this.practiceName;
      this.merchantID = this.data.merchantID !== undefined ? this.data.merchantID : this.merchantID;
    }
  }

  ngOnInit() {}

  onYes() {
    this.close.emit(true);
    this.dialogRef.close(true);
  }

  onNo() {
    this.close.emit(true);
    this.dialogRef.close(false);
  }

  closeEvent() {
    this.close.emit(true);
  }
}
