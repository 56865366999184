import { AfterViewInit, Directive, DoCheck, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';
import { NgModel } from '@angular/forms';

import { AuthenticationService } from '../../core/authentication/shared/authentication.service';
import { CustomCurrencyPipe } from '../pipes/custom-currency.pipe';
import { CustomDateTimePipe } from '../pipes/custom-date-time.pipe';
import { CustomDatePipe } from '../pipes/custom-date.pipe';
import { CustomPhonePipe } from '../pipes/custom-phone.pipe';
import { UserDataService } from '../services/user-data.service';
import { UtilsClass } from '../types/utils/utils.class';

@Directive({
  selector: '[userData]',
  providers: [NgModel, CustomPhonePipe, CustomDatePipe, CustomDateTimePipe, CustomCurrencyPipe],
})
export class UserDataDirective implements AfterViewInit, DoCheck {
  @Input() userData: any;
  @Input('pipe') pipe: any = null;

  private invitatioID: string;
  private content: any;
  private selectOptions: string;

  sessionType;

  util = new UtilsClass();

  ngAfterViewInit() {
    if (this.elt.nativeElement.nodeName == 'MD-SELECT') {
      // get initial select options
      this.selectOptions = this.elt.nativeElement.attributes['aria-owns'].value;
    }
  }

  ngDoCheck() {
    if (this.elt.nativeElement.attributes['aria-owns']) {
      // if options change
      if (this.elt.nativeElement.attributes['aria-owns'].value != this.selectOptions) {
        // set selected option
        if (this.content) {
          const model = this.userData;
          const tmpVal = this.util.selectObjectByString(this.content, model);
          if (tmpVal) {
            this.renderValue(tmpVal);
          } else if (tmpVal === null) {
            this.renderValue(null);
          }
        }
        // reset initial select options
        this.selectOptions = this.elt.nativeElement.attributes['aria-owns'].value;
      }
    }
  }

  constructor(
    private userDataService: UserDataService,
    private ngModel: NgModel,
    private CustomCurrencyPipe: CustomCurrencyPipe,
    private CustomPhonePipe: CustomPhonePipe,
    private CustomDatePipe: CustomDatePipe,
    private CustomDateTimePipe: CustomDateTimePipe,
    private authenticationService: AuthenticationService,
    private renderer: Renderer2,
    private elt: ElementRef
  ) {
    this.userDataService.userDataEvent.subscribe((data: any) => {
      this.invitatioID = data['invitation_id'];
      const content = data.content;
      this.content = content;
      if (content) {
        const model = this.userData;
        const tmpVal = this.util.selectObjectByString(content, model);
        if (tmpVal) {
          this.renderValue(tmpVal);
        } else if (tmpVal === null) {
          this.renderValue(null);
        }
      }
    });
  }

  @HostListener('change', ['$event'])
  @HostListener('selectionChange', ['$event'])
  ngOnChanges(event: any) {
    this.authenticationService.getSessionType().subscribe((sessionType) => {
      this.sessionType = sessionType;

      if (event.target || event.source || event.checked) {
        let value = null;
        let label = null;

        if (event.target) {
          value = event.target.value;
        } else if (event.source && event.source.triggerValue && event.value) {
          value = event.value;
          label = event.source.triggerValue;
        } else if (event.value) {
          value = event.value;
        } else if (event.checked === true) {
          value = true;
        } else if (event.checked === false) {
          value = false;
        }

        const id = this.invitatioID;

        // reset value to enable form validation
        this.ngModel.control.reset();
        // start spinner
        this.renderer.addClass(this.elt.nativeElement, 'wave-spinner');
        const body = {
          attribute: this.userData,
          value,
        };
        UtilsClass.savingData();
        this.userDataService.setUserData(id, body, this.sessionType).subscribe(
          (res: any) => {
            if (!res.success) {
              // reset to blank
              event.target ? (event.target.value = '') : (event.value = '');
            }

            if (label) {
              const bodyLabel = {
                attribute: this.userData + 'Label',
                value: label,
              };

              this.userDataService.setUserData(id, bodyLabel, this.sessionType).subscribe(
                (res1: any) => {
                  if (!res1.success) {
                    // reset to blank
                    event.target ? (event.target.value = '') : (event.value = '');
                  }

                  this.userDataService.userDataEvent.emit(res1.data);
                  // reset old value
                  this.ngModel.control.setValue(value);
                  // stop spinner
                  this.renderer.removeClass(this.elt.nativeElement, 'wave-spinner');
                },
                (err1: any) => {
                  // reset to blank
                  event.target ? (event.target.value = '') : (event.value = '');
                  // Log errors if any
                }
              );
            } else {
              this.userDataService.userDataEvent.emit(res.data);
              // reset old value
              this.ngModel.control.setValue(value);
              // stop spinner
              this.renderer.removeClass(this.elt.nativeElement, 'wave-spinner');
            }
          },
          (err: any) => {
            // reset to blank
            event.target ? (event.target.value = '') : (event.value = '');
            // Log errors if any
          }
        );
      }
    });
  }

  renderValue(tmpVal) {
    if (this.ngModel.name) {
      this.ngModel.update.emit(tmpVal);
    } else if (tmpVal === null) {
      this.elt.nativeElement.innerHTML = null;
    } else {
      if (this.pipe == 'phone') {
        this.elt.nativeElement.innerHTML = this.CustomPhonePipe.transform(tmpVal, null);
      } else if (this.pipe == 'date') {
        this.elt.nativeElement.innerHTML = this.CustomDatePipe.transform(tmpVal, null);
      } else if (this.pipe == 'dateTime') {
        this.elt.nativeElement.innerHTML = this.CustomDateTimePipe.transform(tmpVal, null);
      } else if (this.pipe == 'currency') {
        this.elt.nativeElement.innerHTML = this.CustomCurrencyPipe.transform(tmpVal, null, null, null);
      } else {
        this.elt.nativeElement.innerHTML = tmpVal;
      }
    }
  }
}
