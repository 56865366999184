<div class="full-width clearfix row" *ngIf="displayHeaderBar == true">
  <h1 class="dataHeader">
    <button
      class="mt pull-right"
      *ngIf="isAction == true"
      (click)="actionClickEvent()"
      mat-raised-button
      color="accent"
    >
      <mat-icon>{{ actionIcon }}</mat-icon>
      {{ actionLabel }}
    </button>

    {{ title }}

    <span
      class="badge"
      *ngIf="positions && positions.length > 0"
      [endVal]="
        positions
          | FilterArrayMultipleValue: filterMultiAttributes:filterMultiValues
          | FilterArrayMultipleContainValue: filterMultiContainAttributes:filterMultiContainValue
          | WithoutArrayMultipleValue: filterWithoutMultiAttributes:filterWithoutMultiValue
          | lengthOfArray
      "
      countUp
    ></span>
  </h1>
</div>

<div class="row clearfix full-width full-height rel" *ngIf="positions && positions.length > 0">
  <div class="action-special-position" *ngIf="displayHeaderBar == false && displayActions == true">
    <button class="actionMenuToggle" [matMenuTriggerFor]="actionsMenu" mat-raised-button>
      Actions <mat-icon>more_vert</mat-icon>
    </button>

    <mat-menu #actionsMenu="matMenu">
      <button *ngIf="showCustomAction == true" (click)="actionClickEvent()" mat-menu-item type="button">
        <mat-icon>{{ actionIcon }}</mat-icon>
        {{ actionLabel }}
      </button>

      <mat-divider *ngIf="showCustomAction == true"></mat-divider>

      <button *ngIf="practiceAddress" (click)="changeToCurrentPractice()" mat-menu-item type="button">
        <mat-icon class="fas fa-landmark"></mat-icon>
        <span>My {{ "KEYWORD.practice" | translate | titlecase}} Address</span>
      </button>

      <button (click)="changeToCurrent()" mat-menu-item type="button">
        <mat-icon>location_on</mat-icon>
        <span>My Current Location</span>
      </button>

      <button (click)="resetMap()" mat-menu-item type="button">
        <mat-icon>restore</mat-icon>
        <span>Reset Map</span>
      </button>
    </mat-menu>
  </div>

  <mat-card class="rm-p full-height rel">
    <div class="row clearfix mapHeader primary-gradient-img" *ngIf="displayHeaderBar == true">
      <div class="row clearfix" id="search">
        <p class="pull-left two-thrd-width small mt white">
          {{ description }}.
          <span class="text-center" *ngIf="practiceAddress || profileAddress">
            Select extra zoom options from the Action menu.
          </span>
        </p>
      </div>
      <button class="actionMenuToggle" [matMenuTriggerFor]="actionsMenu" mat-raised-button>
        Actions <mat-icon>more_vert</mat-icon>
      </button>

      <mat-menu #actionsMenu="matMenu">
        <button *ngIf="practiceAddress" (click)="changeToCurrentPractice()" mat-menu-item type="button">
          <mat-icon class="fas fa-landmark"></mat-icon>
          <span>My {{ "KEYWORD.practice" | translate | titlecase}} Address</span>
        </button>

        <button (click)="changeToCurrent()" mat-menu-item type="button">
          <mat-icon>location_on</mat-icon>
          <span>My Current Location</span>
        </button>

        <button (click)="resetMap()" mat-menu-item type="button">
          <mat-icon>restore</mat-icon>
          <span>Reset Map</span>
        </button>
      </mat-menu>

      <div class="pull-right closeModal">
        <button class="btn-close btn-clear mat-button" *ngIf="isModal == true" (click)="closeEvent()">
          <mat-icon>clear</mat-icon>
        </button>
      </div>
    </div>

    <!-- search bar -->
    <div class="form-group" *ngIf="hasMapSearch" @simpleFadeAnimation>
      <mat-form-field class="clearfix full-width" appearance="outline">
        <mat-label>Enter address</mat-label>
        <input
          class="form-control"
          #search
          (keydown.enter)="$event.preventDefault()"
          (blur)="addSearchMarker()"
          matInput
          type="text"
          placeholder="Search Nearest Location"
          autocorrect="off"
          autocapitalize="off"
          spellcheck="off"
          type="text"
        />
      </mat-form-field>
    </div>

    <agm-map
      class="mapDisplay mb full-height"
      [latitude]="toNumber(center.Latitude || positions[0].Latitude)"
      [longitude]="toNumber(center.Longitude || positions[0].Longitude)"
      [zoom]="zoom"
      [styles]="styles"
    >
      <!-- variable location marker for search -->
      <agm-marker
        [latitude]="latitude"
        [longitude]="longitude"
        [markerDraggable]="true"
        (dragEnd)="markerDragEnd($event)"
      ></agm-marker>

      <ng-container
        *ngFor="
          let position of positions
            | FilterArrayMultipleValue: filterMultiAttributes:filterMultiValues
            | FilterArrayMultipleContainValue: filterMultiContainAttributes:filterMultiContainValue
            | WithoutArrayMultipleValue: filterWithoutMultiAttributes:filterWithoutMultiValue;
          let i = index
        "
      >
        <agm-marker
          *ngIf="position.isHide == false"
          [iconUrl]="{ url: i === selectedIndex ? defaultIcon : position?.item?.icon }"
          [latitude]="toNumber(position.Latitude)"
          [longitude]="toNumber(position.Longitude)"
          [markerDraggable]="false"
          (markerClick)="selectItem(position.item)"
        >
          <agm-snazzy-info-window
            [isOpen]="position.isOpen || false"
            [maxWidth]="600"
            [maxHeight]="300"
            [closeWhenOthersOpen]="true"
            [openOnMarkerClick]="true"
          >
            <ng-template>
              <div class="card-header primary-gradient-img clearfix inModal">
                <div class="row clearfix">
                  <div class="row titleArea clearfix">
                    <mat-icon class="pull-left">location_on</mat-icon>
                    <h2 class="summary-header rm-m" *ngIf="position && position.label && !position.item['markerTitle']">
                      {{ position['label'] }}
                    </h2>
                  </div>
                </div>
              </div>

              <div class="column position-info">
                <p
                  class=""
                  *ngIf="
                    position &&
                    position.item &&
                    position.item[markerDescriptionAttribute] &&
                    !position.item['markerTitle']
                  "
                >
                  <!-- {{ position.item[markerDescriptionAttribute] }} -->

                  <!--
                <br /> -->
                  <ng-container *ngIf="position && position.item && position.item['addresses.Unit']">
                    {{ position.item['addresses.Unit'] }} /
                  </ng-container>
                  <ng-container *ngIf="position && position.item && position.item['addresses.Street Nr']">
                    {{ position.item['addresses.Street Nr'] }}
                  </ng-container>
                  <ng-container *ngIf="position && position.item && position.item['addresses.Street Name']">
                    {{ position.item['addresses.Street Name'] }}
                  </ng-container>
                  <ng-container *ngIf="position && position.item && position.item['addresses.Street Type']">
                    {{ position.item['addresses.Street Type'] }},
                  </ng-container>
                  <br />
                  <ng-container *ngIf="position && position.item && position.item['addresses.Suburb']">
                    {{ position.item['addresses.Suburb'] }}
                  </ng-container>
                  <ng-container *ngIf="position && position.item && position.item['addresses.State']">
                    {{ position.item['addresses.State'] }}
                  </ng-container>
                  <ng-container *ngIf="position && position.item && position.item['addresses.Postcode']">
                    {{ position.item['addresses.Postcode'] }}
                  </ng-container>
                </p>

                <p *ngIf="item && item['markerDescriptionAttribute']"></p>
              </div>
            </ng-template>
          </agm-snazzy-info-window>
        </agm-marker>
      </ng-container>

      <agm-marker
        *ngIf="practiceAddress"
        [iconUrl]="practiceMarker"
        [latitude]="toNumber(practiceAddress.Latitude)"
        [longitude]="toNumber(practiceAddress.Longitude)"
        [markerDraggable]="false"
        (markerClick)="selectItem(practiceAddress.item)"
      >
        <agm-snazzy-info-window
          [isOpen]="practiceAddress.isOpen || false"
          [maxWidth]="600"
          [maxHeight]="300"
          [closeWhenOthersOpen]="true"
          [openOnMarkerClick]="true"
        >
          <ng-template>
            <div class="card-header primary-gradient-img clearfix inModal">
              <div class="row clearfix">
                <div class="row titleArea clearfix">
                  <mat-icon class="pull-left">location_on</mat-icon>
                  <h2
                    class="summary-header rm-m"
                    *ngIf="practiceAddress && practiceAddress.item && practiceAddress.item['markerTitle']"
                  >
                    {{ practiceAddress.item['markerTitle'] }}
                  </h2>
                </div>
              </div>
            </div>

            <div class="column prac-info">
              <p class="" *ngIf="practiceAddress && practiceAddress.item && practiceAddress.item['markerTitle']">
                <!-- {{ practiceAddress.item[markerDescriptionAttribute] }}  -->

                <ng-container *ngIf="practiceAddress && practiceAddress.item && practiceAddress.item['unitNumber']">
                  {{ practiceAddress.item['unitNumber'] }} /
                </ng-container>
                <ng-container *ngIf="practiceAddress && practiceAddress.item && practiceAddress.item['streetNumber']">
                  {{ practiceAddress.item['streetNumber'] }}
                </ng-container>
                <ng-container *ngIf="practiceAddress && practiceAddress.item && practiceAddress.item['streetName']">
                  {{ practiceAddress.item['streetName'] }}
                </ng-container>
                <ng-container *ngIf="practiceAddress && practiceAddress.item && practiceAddress.item['streetType']">
                  {{ practiceAddress.item['streetType'] }},
                </ng-container>
                <br />
                <ng-container *ngIf="practiceAddress && practiceAddress.item && practiceAddress.item['suburb']">
                  {{ practiceAddress.item['suburb'] }}
                </ng-container>
                <ng-container *ngIf="practiceAddress && practiceAddress.item && practiceAddress.item['state']">
                  {{ practiceAddress.item['state'] }}
                </ng-container>
                <ng-container *ngIf="practiceAddress && practiceAddress.item && practiceAddress.item['postCode']">
                  {{ practiceAddress.item['postCode'] }}
                </ng-container>
              </p>
            </div>
          </ng-template>
        </agm-snazzy-info-window>
      </agm-marker>

      <agm-marker
        *ngIf="currentPossition"
        [iconUrl]="userMarker"
        [latitude]="toNumber(currentPossition.Latitude)"
        [longitude]="toNumber(currentPossition.Longitude)"
        [markerDraggable]="false"
        (markerClick)="selectItem(currentPossition.item)"
      >
        <agm-snazzy-info-window
          [isOpen]="true"
          [maxWidth]="300"
          [maxHeight]="300"
          [closeWhenOthersOpen]="true"
          [openOnMarkerClick]="true"
        >
          <ng-template>
            <div class="card-header primary-gradient-img clearfix inModal">
              <div class="row clearfix">
                <div class="row titleArea clearfix">
                  <mat-icon class="pull-left">location_on</mat-icon>
                  <h2 class="summary-header rm-m">Your current location</h2>
                </div>
              </div>
            </div>
            <div class="column current-info">
              <p>This is where your GPS indicates you are currently.</p>
            </div>
          </ng-template>
        </agm-snazzy-info-window>
      </agm-marker>

      <agm-circle
        *ngIf="showRadius"
        [latitude]="toNumber(practiceAddress.Latitude)"
        [longitude]="toNumber(practiceAddress.Longitude)"
        [radius]="radius"
        [fillColor]="'#0092eb'"
        [circleDraggable]="radiusDraggable"
        [editable]="false"
        (drag)="radiusDragEnd($event)"
        (radiusChange)="event('radiusChange', $event)"
      >
      </agm-circle>
    </agm-map>
  </mat-card>

  <!-- <mat-card class="test-position">
    <h5>Address: {{address}}</h5>
    <div>Latitude: {{latitude}}</div>
    <div>Longitude: {{longitude}}</div>
  </mat-card> -->
</div>
