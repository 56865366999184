<div class="rel editDetails">
  <div class="float-close">
    <button class="btn-close btn-clear mat-button" matDialogClose>
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <mat-card class="card" *ngIf="addressModal == false">
    <form #customerDetails="ngForm" *ngIf="selectedCustomer">
      <mat-dialog-content>
        <h2 class="mat-h2 sr-title">Select Primary Contact Details</h2>
        <div class="row clearfix">
          <div class="row clearfix">
            <div class="well row clearfix flex">
              <div class="half-width">
                <div class="col-left">
                  <mat-icon class="fas fa-phone-square"></mat-icon>
                  <span><strong>Phone Numbers</strong></span>
                  <hr class="mb"/>
                  <mat-list *ngFor="let subItem of getPhoneLabels(selectedCustomer)">
                    <mat-list-item (click)="(selectedNumber)" style="cursor: pointer"
                    ><strong>{{ subItem }}: </strong> &nbsp; <i>{{ getNumber(subItem) }}</i></mat-list-item
                    >
                    <mat-divider></mat-divider>
                  </mat-list>
                </div>
              </div>
              <div class="col-right">
                <mat-icon class="fas fa-envelope-square"></mat-icon>
                <span><strong>Email Addresses</strong></span>
                <hr class="mb"/>
                <mat-list *ngFor="let subItem of getEmailLabels(selectedCustomer)">
                  <mat-list-item (click)="(selectedEmail)" style="cursor: pointer"
                  ><strong>{{ subItem }}: </strong> &nbsp; <i>{{ getEmailAddress(subItem) }}</i></mat-list-item
                  >
                  <mat-divider></mat-divider>
                </mat-list>
              </div>
            </div>
          </div>
        </div>
      </mat-dialog-content>

      <div class="full-width text-center">
        <!-- <button mat-raised-button (click)="editDetail()" color="accent" [disabled]="!customerDetails.form.valid || isAdressValid==false || validateEmail==false || validatePhone==false">Save</button> -->
        <button (click)="editContactDetails()" mat-raised-button color="accent">Save</button>
      </div>
    </form>
  </mat-card>

  <mat-card class="card" *ngIf="addressModal == true">
    <form #customerDetails="ngForm" *ngIf="selectedCustomer">
      <mat-dialog-content>
        <h2 class="mat-h2 sr-title">Select Primary Address</h2>
        <div class="row clearfix">
          <div class="row clearfix">
            <div class="well row clearfix flex">
              <div class="row clearfix">
                <mat-icon class="fas fa-home"></mat-icon>
                <span>&nbsp;<strong> Address Book</strong></span>
                <hr class="mb"/>
                <mat-list *ngFor="let subItem of getAddressLabels(selectedCustomer)">
                  <mat-list-item (click)="(selectedAddress)" style="cursor: pointer"
                  ><strong>{{ subItem }}: </strong> &nbsp; <i>{{ getDiffAddress(subItem) }}</i></mat-list-item
                  >
                  <mat-divider></mat-divider>
                </mat-list>
              </div>
            </div>
          </div>
        </div>
      </mat-dialog-content>

      <div class="full-width text-center">
        <!-- <button mat-raised-button (click)="editDetail()" color="accent" [disabled]="!customerDetails.form.valid || isAdressValid==false || validateEmail==false || validatePhone==false">Save</button> -->
        <button (click)="editAddressDetails()" mat-raised-button color="accent">Save</button>
      </div>
    </form>
  </mat-card>
</div>
