<div class="icon-wrapper-2 like" *ngIf="type == 'like'" [class.anim]="anim == true">
  <span class="icon"><i class="fa fa-thumbs-up"></i></span>
  <div class="border"><span></span></div>
  <div class="spark">
    <span></span><span></span><span></span><span></span> <span></span><span></span><span></span><span></span>
    <span></span><span></span><span></span><span></span> <span></span><span></span><span></span><span></span>
    <span></span><span></span><span></span><span></span>
  </div>
</div>

<div class="icon-wrapper dislike" *ngIf="type == 'dislike'" [class.anim]="anim == true">
  <span class="icon"><i class="fa fa-thumbs-down"></i></span>
  <div class="border"><span></span></div>
  <div class="satellite">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </div>
</div>

<div class="icon-wrapper-2 heart" *ngIf="type == 'heart'" [class.anim]="anim == true">
  <span class="icon"><i class="fa fa-heart"></i></span>
  <div class="border"><span></span></div>
  <div class="spark">
    <span></span><span></span><span></span><span></span> <span></span><span></span><span></span><span></span>
    <span></span><span></span><span></span><span></span> <span></span><span></span><span></span><span></span>
    <span></span><span></span><span></span><span></span>
  </div>
</div>

<div class="icon-wrapper-2 star" *ngIf="type == 'star'" [class.anim]="anim == true">
  <span class="icon"><i class="fa fa-star"></i></span>
  <div class="border"><span></span></div>
  <div class="spark">
    <span></span><span></span><span></span><span></span> <span></span><span></span><span></span><span></span>
    <span></span><span></span><span></span><span></span> <span></span><span></span><span></span><span></span>
    <span></span><span></span><span></span><span></span>
  </div>
</div>

<div class="icon-wrapper-2 tooth" *ngIf="type == 'tooth'" [class.anim]="anim == true">
  <span class="icon"><i class="fa {{'fa-tooth' | translate}}"></i></span>
  <div class="border"><span></span></div>
  <div class="spark">
    <span></span><span></span><span></span><span></span> <span></span><span></span><span></span><span></span>
    <span></span><span></span><span></span><span></span> <span></span><span></span><span></span><span></span>
    <span></span><span></span><span></span><span></span>
  </div>
</div>
