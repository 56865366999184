/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./notes.modal.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "./shared/components/note-list/note-list.component.ngfactory";
import * as i5 from "./shared/components/note-list/note-list.component";
import * as i6 from "@angular/router";
import * as i7 from "./shared/components/note-form/note-form.component.ngfactory";
import * as i8 from "./shared/components/note-form/note-form.component";
import * as i9 from "../../../../core/app-state/app-state.service";
import * as i10 from "../../shared/services/notes.service";
import * as i11 from "../../../../shared/services/utils.service";
import * as i12 from "../../../../shared/components/empty-list-message/empty-list-message.component.ngfactory";
import * as i13 from "../../../../shared/components/empty-list-message/empty-list-message.component";
import * as i14 from "@ngx-translate/core";
import * as i15 from "@angular/common";
import * as i16 from "./shared/components/note-list-header/note-list-header.component.ngfactory";
import * as i17 from "../../../../shared/pipes/count-string-ids/count-string-ids.pipe";
import * as i18 from "./shared/components/note-list-header/note-list-header.component";
import * as i19 from "./shared/components/note-patient-list/note-patient-list.component.ngfactory";
import * as i20 from "./shared/components/note-patient-list/note-patient-list.component";
import * as i21 from "../../../customer-prospect/shared/customerProspect.service";
import * as i22 from "@angular/material/dialog";
import * as i23 from "./notes.modal";
var styles_NotesModal = [i0.styles];
var RenderType_NotesModal = i1.ɵcrt({ encapsulation: 0, styles: styles_NotesModal, data: {} });
export { RenderType_NotesModal as RenderType_NotesModal };
function View_NotesModal_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["class", "note-list-container__close-button btn-close btn-clear mat-button pull-right mr mt"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(2, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["clear"]))], function (_ck, _v) { _ck(_v, 2, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).inline; var currVal_1 = (((i1.ɵnov(_v, 2).color !== "primary") && (i1.ɵnov(_v, 2).color !== "accent")) && (i1.ɵnov(_v, 2).color !== "warn")); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_NotesModal_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ipv-note-list", [], null, [[null, "closeModal"], [null, "noteListModeSet"], [null, "loadMoreClicked"], [null, "deleteButtonClicked"], [null, "noteSelected"], [null, "showSystemNotesToggled"], [null, "showArchivedNotesToggled"], [null, "archiveButtonClicked"], [null, "unarchiveButtonClicked"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("closeModal" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } if (("noteListModeSet" === en)) {
        var pd_1 = (_co.setNoteListMode($event) !== false);
        ad = (pd_1 && ad);
    } if (("loadMoreClicked" === en)) {
        var pd_2 = (_co.loadMoreSelectedNotes() !== false);
        ad = (pd_2 && ad);
    } if (("deleteButtonClicked" === en)) {
        var pd_3 = (_co.deleteNote($event) !== false);
        ad = (pd_3 && ad);
    } if (("noteSelected" === en)) {
        var pd_4 = (_co.setSelectedNote($event) !== false);
        ad = (pd_4 && ad);
    } if (("showSystemNotesToggled" === en)) {
        var pd_5 = (_co.setSelectedNotesFilterBy($event) !== false);
        ad = (pd_5 && ad);
    } if (("showArchivedNotesToggled" === en)) {
        var pd_6 = (_co.setShowArchivedNotes($event) !== false);
        ad = (pd_6 && ad);
    } if (("archiveButtonClicked" === en)) {
        var pd_7 = (_co.archiveNote($event) !== false);
        ad = (pd_7 && ad);
    } if (("unarchiveButtonClicked" === en)) {
        var pd_8 = (_co.unarchiveNote($event) !== false);
        ad = (pd_8 && ad);
    } return ad; }, i4.View_NoteListComponent_0, i4.RenderType_NoteListComponent)), i1.ɵdid(1, 770048, null, 0, i5.NoteListComponent, [i6.Router], { user: [0, "user"], selectedPatient: [1, "selectedPatient"], notes: [2, "notes"], noteDates: [3, "noteDates"], showLoadMoreButton: [4, "showLoadMoreButton"], parentRoute: [5, "parentRoute"] }, { noteListModeSet: "noteListModeSet", noteSelected: "noteSelected", deleteButtonClicked: "deleteButtonClicked", archiveButtonClicked: "archiveButtonClicked", unarchiveButtonClicked: "unarchiveButtonClicked", loadMoreClicked: "loadMoreClicked", showSystemNotesToggled: "showSystemNotesToggled", showArchivedNotesToggled: "showArchivedNotesToggled", closeModal: "closeModal" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.user; var currVal_1 = _co.selectedPatient; var currVal_2 = _v.parent.parent.context.ngIf.selectedNotes; var currVal_3 = _v.parent.parent.context.ngIf.selectedNoteDates; var currVal_4 = _co.areMoreSelectedNotesAvailable; var currVal_5 = _co.parentRoute; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
function View_NotesModal_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ipv-note-form", [], null, [[null, "noteListModeSet"], [null, "closeModal"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("noteListModeSet" === en)) {
        var pd_0 = (_co.setNoteListMode($event) !== false);
        ad = (pd_0 && ad);
    } if (("closeModal" === en)) {
        var pd_1 = (_co.close() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i7.View_NoteFormComponent_0, i7.RenderType_NoteFormComponent)), i1.ɵdid(1, 770048, null, 0, i8.NoteFormComponent, [i9.AppStateService, i10.NotesService, i11.UtilsService, i6.Router], { formMode: [0, "formMode"], patient: [1, "patient"], practice: [2, "practice"], dentist: [3, "dentist"], user: [4, "user"], relationshipLink: [5, "relationshipLink"], selectedNote: [6, "selectedNote"], noteTypes: [7, "noteTypes"], areNotesPrivate: [8, "areNotesPrivate"], parentRoute: [9, "parentRoute"], prefillNoteAction: [10, "prefillNoteAction"] }, { noteListModeSet: "noteListModeSet", closeModal: "closeModal" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.noteListMode; var currVal_1 = _co.selectedPatient; var currVal_2 = _v.parent.parent.context.ngIf.practice; var currVal_3 = _v.parent.parent.context.ngIf.dentist; var currVal_4 = _v.parent.parent.context.ngIf.user.data; var currVal_5 = _co.relationshipLink; var currVal_6 = _co.selectedNote; var currVal_7 = _v.parent.parent.context.ngIf.enabledNoteTypes; var currVal_8 = _co.areNotesPrivate; var currVal_9 = _co.parentRoute; var currVal_10 = _co.prefillNoteAction; _ck(_v, 1, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10]); }, null); }
function View_NotesModal_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "app-empty-list-message", [], null, [[null, "actionEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("actionEvent" === en)) {
        var pd_0 = (_co.selectPatient(_v.parent.parent.context.ngIf.practice) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i12.View_EmptyListMessageComponent_0, i12.RenderType_EmptyListMessageComponent)), i1.ɵdid(1, 114688, null, 0, i13.EmptyListMessageComponent, [], { message: [0, "message"], isAction: [1, "isAction"], actionIcon: [2, "actionIcon"], actionLabel: [3, "actionLabel"] }, { actionEvent: "actionEvent" }), i1.ɵpid(131072, i14.TranslatePipe, [i14.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(3, 1), i1.ɵpid(131072, i14.TranslatePipe, [i14.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(5, 1), i1.ɵpid(131072, i14.TranslatePipe, [i14.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(7, 1)], function (_ck, _v) { var currVal_0 = (((("Select a " + i1.ɵunv(_v, 1, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent.parent, 0), i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("KEYWORD.patient"))))) + " from the side menu or use the ") + i1.ɵunv(_v, 1, 0, _ck(_v, 5, 0, i1.ɵnov(_v.parent.parent.parent, 0), i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 4).transform("KEYWORD.patient"))))) + " lookup."); var currVal_1 = true; var currVal_2 = null; var currVal_3 = (i1.ɵunv(_v, 1, 3, _ck(_v, 7, 0, i1.ɵnov(_v.parent.parent.parent, 0), i1.ɵunv(_v, 1, 3, i1.ɵnov(_v, 6).transform("KEYWORD.patient")))) + " Lookup"); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_NotesModal_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "note-list-container full-width empty"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotesModal_3)), i1.ɵdid(2, 16384, null, 0, i15.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "ipv-note-list-header", [], null, [[null, "closeEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("closeEvent" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i16.View_NoteListHeaderComponent_0, i16.RenderType_NoteListHeaderComponent)), i1.ɵprd(512, null, i17.CountStringIdsPipe, i17.CountStringIdsPipe, []), i1.ɵdid(5, 704512, null, 0, i18.NoteListHeaderComponent, [i17.CountStringIdsPipe], { selectedPatient: [0, "selectedPatient"], practice: [1, "practice"] }, { closeEvent: "closeEvent" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotesModal_4)), i1.ɵdid(7, 16384, null, 0, i15.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotesModal_5)), i1.ɵdid(9, 16384, null, 0, i15.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotesModal_6)), i1.ɵdid(11, 16384, null, 0, i15.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.selectedPatient; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.selectedPatient; var currVal_2 = _v.parent.context.ngIf.practice; _ck(_v, 5, 0, currVal_1, currVal_2); var currVal_3 = (_co.noteListMode === "list"); _ck(_v, 7, 0, currVal_3); var currVal_4 = (_co.noteListMode !== "list"); _ck(_v, 9, 0, currVal_4); var currVal_5 = (!_co.selectedPatient && (_co.noteListMode !== "create")); _ck(_v, 11, 0, currVal_5); }, null); }
function View_NotesModal_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "notes-dialog__container full-width"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["style", "width: 500px; height: 100%"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "ipv-note-patient-list", [], null, [[null, "noteModalModeSetToList"], [null, "selectPatientButtonClicked"], [null, "newPatientButtonClicked"], [null, "loadMoreClicked"], [null, "patientNameFilterUpdated"], [null, "filterByChanged"], [null, "noteSelected"], [null, "merchantSet"], [null, "areNotesPrivateChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("noteModalModeSetToList" === en)) {
        var pd_0 = (_co.setNoteListMode($event) !== false);
        ad = (pd_0 && ad);
    } if (("selectPatientButtonClicked" === en)) {
        var pd_1 = (_co.selectPatient(_v.context.ngIf.practice) !== false);
        ad = (pd_1 && ad);
    } if (("newPatientButtonClicked" === en)) {
        var pd_2 = (_co.newPatient(_v.context.ngIf.practice) !== false);
        ad = (pd_2 && ad);
    } if (("loadMoreClicked" === en)) {
        var pd_3 = (_co.loadMoreNotes() !== false);
        ad = (pd_3 && ad);
    } if (("patientNameFilterUpdated" === en)) {
        var pd_4 = (_co.setNameFilter($event) !== false);
        ad = (pd_4 && ad);
    } if (("filterByChanged" === en)) {
        var pd_5 = (_co.setNotesFilterBy($event) !== false);
        ad = (pd_5 && ad);
    } if (("noteSelected" === en)) {
        var pd_6 = (_co.selectNote($event) !== false);
        ad = (pd_6 && ad);
    } if (("merchantSet" === en)) {
        var pd_7 = (_co.setMerchant($event) !== false);
        ad = (pd_7 && ad);
    } if (("areNotesPrivateChanged" === en)) {
        var pd_8 = (_co.setAreNotesPrivate($event) !== false);
        ad = (pd_8 && ad);
    } return ad; }, i19.View_NotePatientListComponent_0, i19.RenderType_NotePatientListComponent)), i1.ɵdid(4, 245760, null, 0, i20.NotePatientListComponent, [i21.CustomerProspectService, i9.AppStateService], { practice: [0, "practice"], notes: [1, "notes"], showLoadMoreButton: [2, "showLoadMoreButton"] }, { merchantSet: "merchantSet", noteModalModeSetToList: "noteModalModeSetToList", selectPatientButtonClicked: "selectPatientButtonClicked", newPatientButtonClicked: "newPatientButtonClicked", patientNameFilterUpdated: "patientNameFilterUpdated", loadMoreClicked: "loadMoreClicked", filterByChanged: "filterByChanged", areNotesPrivateChanged: "areNotesPrivateChanged", noteSelected: "noteSelected" }), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_NotesModal_2)), i1.ɵdid(6, 16384, null, 0, i15.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i15.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.ngIf.practice; var currVal_1 = _v.context.ngIf.notes; var currVal_2 = _co.areMoreNotesAvailable; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform(_co.viewModel$)); _ck(_v, 6, 0, currVal_3); }, null); }
export function View_NotesModal_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i15.TitleCasePipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 4, "mat-dialog-content", [["class", "notes-dialog mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(2, 16384, null, 0, i22.MatDialogContent, [], null, null), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_NotesModal_1)), i1.ɵdid(4, 16384, null, 0, i15.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i15.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform(_co.viewModel$)); _ck(_v, 4, 0, currVal_0); }, null); }
export function View_NotesModal_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-notes-modal", [], null, null, null, View_NotesModal_0, RenderType_NotesModal)), i1.ɵdid(1, 245760, null, 0, i23.NotesModal, [[2, i22.MAT_DIALOG_DATA], i10.NotesService, i9.AppStateService, i22.MatDialogRef, i21.CustomerProspectService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NotesModalNgFactory = i1.ɵccf("app-notes-modal", i23.NotesModal, View_NotesModal_Host_0, {}, {}, []);
export { NotesModalNgFactory as NotesModalNgFactory };
