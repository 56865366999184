import { Component, Input, OnChanges, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { EMPTY_REPORT } from '../../../../../../shared/components/data-table/shared/data/data';
import { DataTablePrintModalComponent } from '../../../../../../shared/components/data-table/shared/modals/data-table-print-modal/data-table-print-modal.component';
import { DataTablePrintModalConfig } from '../../../../../../shared/components/data-table/shared/types/data-table.type';
import { TOTAL_REVENUE_REPORT_LABELS } from '../../data/revenue-report.data';
import { FundersRevenueReportService } from '../../services/funders-revenue-report.service';
import { RevenueReport, RevenueReportAccruedRecord, RevenueReportFeesAccrued } from '../../types/revenue-report.type';
import {
  BORROWER_FEES_ACCRUED_DIVIDER,
  BORROWER_INTEREST_ACCRUED_DIVIDER,
  MERCHANT_FEES_ACCRUED_DIVIDER,
} from './data/funders-revenue-report-accrued.data';

@Component({
  selector: 'ipv-funders-revenue-report-accrued',
  templateUrl: './funders-revenue-report-accrued.component.html',
  styleUrls: ['./funders-revenue-report-accrued.component.css'],
})
export class FundersRevenueReportAccruedComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('label', { static: false }) public label: TemplateRef<any>;
  @ViewChild('accrued', { static: false }) public accrued: TemplateRef<any>;
  @ViewChild('waviersOrRebates', { static: false }) public waviersOrRebates: TemplateRef<any>;
  @ViewChild('net', { static: false }) public accruedNet: TemplateRef<any>;
  @ViewChild('accruedPercentage', { static: false }) public accruedPercentage: TemplateRef<any>;

  @Input() revenueReport: RevenueReport;

  report: {
    fullTable: RevenueReportAccruedRecord[];
    table: RevenueReportAccruedRecord[];
    totalRecord: RevenueReportAccruedRecord;
  } = EMPTY_REPORT;

  interestLabel = `Total Interest`;

  constructor(private revenueReportService: FundersRevenueReportService, private dialog: MatDialog) {}

  ngOnInit() {
    this.revenueReportService
      .getPrintTableEvents$()
      .pipe(untilDestroyed(this))
      .subscribe((printTable) => {
        if (printTable === 'accrued') {
          this.openTablePrint();
        }
      });
  }

  ngOnChanges() {
    this.report = this.revenueReport ? this.getTables(this.revenueReport) : EMPTY_REPORT;
  }

  ngOnDestroy() {
    // required for untilDestroyed();
  }

  getTables(revenueReport: RevenueReport): {
    fullTable: RevenueReportAccruedRecord[];
    table: RevenueReportAccruedRecord[];
    totalRecord: RevenueReportAccruedRecord;
  } {
    let table: RevenueReportAccruedRecord[] = [];

    const borrowerFees = revenueReport.BorrowerFees_Accrued ? revenueReport.BorrowerFees_Accrued : [];
    table = this.getBorrowerTable(table, borrowerFees, revenueReport);

    const borrowerInterest = revenueReport.BorrowerInterestAccrued_Total
      ? revenueReport.BorrowerInterestAccrued_Total
      : [];
    table = this.getBorrowerInterestTable(table, borrowerInterest, revenueReport);

    table = this.getTotalBorrowerInterestAndFeesSection(table, borrowerFees, borrowerInterest, revenueReport);

    const merchantFees = revenueReport.MerchantFees_Accrued ? revenueReport.MerchantFees_Accrued : [];
    const merchantSubscriptions = revenueReport.MerchantSubscriptionFees_Accrued
      ? revenueReport.MerchantSubscriptionFees_Accrued.map((item) => ({ ...item, Label: 'Subscriptions' }))
      : [];
    const merchantFeesAndSubscriptions = merchantFees.concat(merchantSubscriptions);
    table = this.getMerchantTable(table, merchantFeesAndSubscriptions, revenueReport);

    const totalRecord = this.getReportTotal(revenueReport);
    const fullTable = table.concat(totalRecord);

    return { fullTable, table, totalRecord };
  }

  getReportTotal(revenueReport: RevenueReport) {
    return {
      label: 'Total Revenue',
      code: '',
      accrued: revenueReport.TotalRevenue_Accrued[0].Accrued,
      waviersOrRebates: revenueReport.TotalRevenue_Accrued[0].WaiversOrRebates,
      accruedNet: revenueReport.TotalRevenue_Accrued[0].Accrued_Net,
      accruedPercentage: 100,
    };
  }

  getBorrowerTable(
    table: RevenueReportAccruedRecord[],
    borrowerFees: RevenueReportFeesAccrued[],
    revenueReport: RevenueReport
  ) {
    const newTable = table;
    newTable.push(BORROWER_FEES_ACCRUED_DIVIDER);

    for (let index = 0; index < borrowerFees.length; index++) {
      const accruedPercentage =
        borrowerFees[index].Accrued_Net === 0
          ? 0
          : Number(
              ((borrowerFees[index].Accrued_Net / revenueReport.TotalRevenue_Accrued[0].Accrued_Net) * 100).toFixed(2)
            );

      if (borrowerFees[index]) {
        const record: RevenueReportAccruedRecord = {
          label: borrowerFees[index].Label,
          code: borrowerFees[index].Code,
          accrued: borrowerFees[index].Accrued,
          waviersOrRebates: borrowerFees[index].WaiversOrRebates,
          accruedNet: borrowerFees[index].Accrued_Net,
          accruedPercentage,
        };

        newTable.push(record);
      }
    }

    const borrowersFeesTotalRecord = revenueReport.BorrowerFeesAccrued_Total[0];
    const reportTotal = revenueReport.TotalRevenue_Accrued[0].Accrued_Net;

    let total = {
      label: 'Total',
      code: '',
      accrued: 0,
      waviersOrRebates: 0,
      accruedNet: 0,
      accruedPercentage: 0,
    };
    let totalPercentage = 0;

    if (borrowersFeesTotalRecord) {
      const borrowersFeesTotal = borrowersFeesTotalRecord.Accrued_Net;
      totalPercentage = this.getSectionTotalPercentage(borrowersFeesTotal, reportTotal);

      total = {
        label: 'Total',
        code: '',
        accrued: borrowersFeesTotalRecord.Accrued,
        waviersOrRebates: borrowersFeesTotalRecord.WaiversOrRebates,
        accruedPercentage: totalPercentage,
        accruedNet: borrowersFeesTotalRecord.Accrued_Net,
      };
    }

    newTable.push(total);

    return newTable;
  }

  getMerchantTable(
    table: RevenueReportAccruedRecord[],
    merchantFees: RevenueReportFeesAccrued[],
    revenueReport: RevenueReport
  ) {
    const newTable = table;
    newTable.push(MERCHANT_FEES_ACCRUED_DIVIDER);

    for (let index = 0; index < merchantFees.length; index++) {
      const accruedPercentage =
        merchantFees[index].Accrued_Net === 0
          ? 0
          : Number(
              ((merchantFees[index].Accrued_Net / revenueReport.TotalRevenue_Accrued[0].Accrued_Net) * 100).toFixed(2)
            );

      if (merchantFees[index]) {
        const record: RevenueReportAccruedRecord = {
          label: merchantFees[index].Label,
          code: merchantFees[index].Code,
          accrued: merchantFees[index].Accrued,
          waviersOrRebates: merchantFees[index].WaiversOrRebates,
          accruedNet: merchantFees[index].Accrued_Net,
          accruedPercentage,
        };

        newTable.push(record);
      }
    }

    const borrowersFeesTotalRecord = revenueReport.MerchantFeesAccrued_Total[0];
    const reportTotal = revenueReport.TotalRevenue_Accrued[0].Accrued_Net;

    let total = {
      label: 'Total',
      code: '',
      accrued: 0,
      waviersOrRebates: 0,
      accruedNet: 0,
      accruedPercentage: 0,
    };
    let totalPercentage = 0;

    if (borrowersFeesTotalRecord) {
      const borrowersFeesTotal = borrowersFeesTotalRecord.Accrued_Net;
      totalPercentage = this.getSectionTotalPercentage(borrowersFeesTotal, reportTotal);

      total = {
        label: 'Total',
        code: '',
        accrued: borrowersFeesTotalRecord.Accrued,
        waviersOrRebates: borrowersFeesTotalRecord.WaiversOrRebates,
        accruedPercentage: totalPercentage,
        accruedNet: borrowersFeesTotalRecord.Accrued_Net,
      };
    }

    total.accruedPercentage = total.accruedPercentage === 0 ? 0 : Number(total.accruedPercentage.toFixed(2));

    newTable.push(total);

    return newTable;
  }

  getBorrowerInterestTable(
    table: RevenueReportAccruedRecord[],
    borrowerInterest: RevenueReportFeesAccrued[],
    revenueReport: RevenueReport
  ) {
    const newTable = table;
    newTable.push(BORROWER_INTEREST_ACCRUED_DIVIDER);

    for (let index = 0; index < borrowerInterest.length; index++) {
      const accruedPercentage =
        borrowerInterest[index].Accrued_Net === 0
          ? 0
          : Number(
              ((borrowerInterest[index].Accrued_Net / revenueReport.TotalRevenue_Accrued[0].Accrued_Net) * 100).toFixed(
                2
              )
            );

      if (borrowerInterest[index]) {
        const record: RevenueReportAccruedRecord = {
          label: 'Interest',
          code: '',
          accrued: borrowerInterest[index].Accrued,
          waviersOrRebates: borrowerInterest[index].WaiversOrRebates,
          accruedNet: borrowerInterest[index].Accrued_Net,
          accruedPercentage,
        };

        newTable.push(record);
      }
    }

    return newTable;
  }

  getTotalBorrowerInterestAndFeesSection(
    table: RevenueReportAccruedRecord[],
    borrowerFees: RevenueReportFeesAccrued[],
    borrowerInterest: RevenueReportFeesAccrued[],
    revenueReport: RevenueReport
  ) {
    const borrowersFeesTotalRecord = revenueReport.BorrowerFeesAccrued_Total[0];
    const borrowerInterestTotalRecord = revenueReport.BorrowerInterestAccrued_Total[0];
    const reportTotal = revenueReport.TotalRevenue_Accrued[0].Accrued_Net;
    const total = {
      label: 'Total',
      code: '',
      accrued: 0,
      waviersOrRebates: 0,
      accruedNet: 0,
      accruedPercentage: 0,
    };

    total.label = 'Total Borrower Interest & Fees';

    if (borrowersFeesTotalRecord) {
      let totalPercentage = 0;
      const borrowersFeesTotal = borrowersFeesTotalRecord.Accrued_Net;
      totalPercentage = this.getSectionTotalPercentage(borrowersFeesTotal, reportTotal);

      total.accrued = total.accrued + borrowersFeesTotalRecord.Accrued;
      total.waviersOrRebates = total.waviersOrRebates + borrowersFeesTotalRecord.WaiversOrRebates;
      total.accruedPercentage = Number((total.accruedPercentage + totalPercentage).toFixed(2));
      total.accruedNet = total.accruedNet + borrowersFeesTotalRecord.Accrued_Net;
    }

    if (borrowerInterestTotalRecord) {
      let totalPercentage = 0;
      const borrowerInterestTotal = borrowerInterestTotalRecord.Accrued_Net;
      totalPercentage = this.getSectionTotalPercentage(borrowerInterestTotal, reportTotal);

      total.accrued = total.accrued + borrowerInterestTotalRecord.Accrued;
      total.waviersOrRebates = total.waviersOrRebates + borrowerInterestTotalRecord.WaiversOrRebates;
      total.accruedPercentage = Number((total.accruedPercentage + totalPercentage).toFixed(2));
      total.accruedNet = total.accruedNet + borrowerInterestTotalRecord.Accrued_Net;
    }

    const newTable = table;

    newTable.push(total);

    return newTable;
  }

  openTablePrint() {
    this.dialog.open<DataTablePrintModalComponent, DataTablePrintModalConfig>(DataTablePrintModalComponent, {
      data: {
        table: this.report.fullTable,
        title: 'Accrued Revenue Report from ' + this.revenueReport.StartDate + ' to ' + this.revenueReport.EndDate,
        customComponents: {
          label: this.label,
          accrued: this.accrued,
          waviersOrRebates: this.waviersOrRebates,
          accruedNet: this.accruedNet,
          accruedPercentage: this.accruedPercentage,
        },
        formatHeaders: {
          accrued: 'right',
          waviersOrRebates: 'right',
          accruedNet: 'right',
          accruedPercentage: 'right',
        },
        rename: { accruedNet: 'Net', accruedPercentage: 'Accrued %', waviersOrRebates: 'Waviers / Rebates' },
        sectionColumn: 'label',
        sections: [
          { value: 'Borrower Fees', color: '#CE066B', fontColor: 'white' },
          { value: 'Merchant Fees', color: '#168BDD', fontColor: 'white' },
          { value: 'Total Borrower Interest & Fees', color: '#E7E9EB', fontColor: 'black', customLayout: true },
          { value: 'Total Revenue', color: '#E7E9EB', fontColor: 'black', customLayout: true },
        ],
      },
      width: '900px',
    });
  }

  getSectionTotalPercentage(sectionTotal: number, reportTotal: number) {
    const percentage = sectionTotal === 0 ? 0 : Number(((sectionTotal / reportTotal) * 100).toFixed(2));

    return percentage;
  }

  isTotalSection(label: string) {
    return TOTAL_REVENUE_REPORT_LABELS.indexOf(label) !== -1;
  }
}
