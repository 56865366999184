<h3 class="sr-title sm-h3">Contact Details</h3>
<div class="row clearfix">
  <form class="column" #contactForm="ngForm">
    <div class="half-width">
      <div class="col-left">
        <div class="row clearfix">
          <div class="icons-col">
            <mat-icon>call</mat-icon>
          </div>
          <div class="row-content-col">
            <!-- Mobile Number -->
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Phone/Mobile</mat-label>
              <input
                class="mat-block"
                [(ngModel)]="patientContact.mobile"
                (change)="checkFormValidate(contactForm.form.valid)"
                name="mobile"
                matInput
                type="tel"
                placeholder="Phone/Mobile "
                name="mobile"
                pattern="([0-9]{10})"
                required
              />
            </mat-form-field>
            <!-- Home Phone -->
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Phone Home</mat-label>
              <input
                class="mat-block"
                [(ngModel)]="patientContact.homePhone"
                (change)="checkFormValidate(contactForm.form.valid)"
                name="homePhone"
                matInput
                placeholder="Phone Home"
                name="home_phone"
                pattern="([0-9]{10})"
              />
            </mat-form-field>
            <!-- Work Phone -->
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Phone/Work</mat-label>
              <input
                class="mat-block"
                [(ngModel)]="patientContact.workPhone"
                (change)="checkFormValidate(contactForm.form.valid)"
                name="workPhone"
                matInput
                placeholder="Phone/Work"
                name="work_phone"
                pattern="([0-9]{10})"
              />
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <div class="half-width">
      <div class="col-right">
        <div class="row clearfix">
          <div class="icons-col">
            <mat-icon>email</mat-icon>
          </div>
          <div class="row-content-col">
            <!-- Email Address -->
            <mat-form-field class="full-width" appearance="outline">
              <mat-label> Email</mat-label>
              <input
                class="mat-block"
                [(ngModel)]="patientContact.email"
                (change)="checkFormValidate(contactForm.form.valid)"
                name="email"
                matInput
                placeholder="{{ 'PLACEHOLDER.Email' | translate }}"
                type="email"
                pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                required
              />
            </mat-form-field>
          </div>
        </div>
        <div class="row clearfix">
          <div class="icons-col">
            <mat-icon>ring_volume</mat-icon>
          </div>
          <div class="row-content-col">
            <div class="row">
              <!-- preferred contact method -->
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Preferred Method of Contact</mat-label>
                <mat-select
                  class="full-width"
                  [(ngModel)]="patientContact.preferedContact"
                  (selectionChange)="checkFormValidate(contactForm.form.valid)"
                  name="preferedMethod"
                  placeholder="Preferred Method of Contact"
                  name="prefContactMethod"
                >
                  <mat-option *ngFor="let prefContactMethod of prefContactMethods" [value]="prefContactMethod.Code">
                    {{ prefContactMethod.Label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="row clearfix">
          <div class="icons-col">
            <mat-icon>schedule</mat-icon>
          </div>
          <div class="row-content-col">
            <div class="row">
              <!-- Best contact time -->
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>What's the best time to contact you?</mat-label>
                <mat-select
                  class="full-width"
                  [(ngModel)]="patientContact.time"
                  (selectionChange)="checkFormValidate(contactForm.form.valid)"
                  name="time"
                  placeholder="What's the best time to contact you?"
                  name="contactTime"
                >
                  <mat-option *ngFor="let contactTime of contactTimes" [value]="contactTime.Code">
                    {{ contactTime.Label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
