<div class="rel" *ngIf="existingPatientsDB && existingPatientsDB.firstLoadEvent == false" @ngIfAnimation>
  <div class="row clearfix padded rel">
    <h1 class="dataHeader rm-mt transparent">Fetching data...</h1>
  </div>
</div>

<div class="pull-right" *ngIf="viewControl == true ">
  <mat-form-field class="mr" *ngIf="marketingFilters.length > 0 && manualSelection!=true" appearance="outline">
    <mat-label>Marketing Filter</mat-label>
    <mat-select class="" (selectionChange)="changeFilter($event.value)" name="marketingFilter"
      placeholder="Marketing Filter">
      <mat-option (click)="addNewFilter()">
        <p class="small rm-mt rm-mb">
          <mat-icon class="addnew">add</mat-icon>
          Create new
        </p>
      </mat-option>
      <mat-divider></mat-divider>
      <mat-option [value]="null">
        All
      </mat-option>
      <mat-option *ngFor="let a of marketingFilters" [value]="a">
        {{ a['Label'] }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <button class="btn-small" *ngIf="manualSelection!=true" [matMenuTriggerFor]="appPatientMenu" mat-raised-button
    color="accent">
    <mat-icon>person_add</mat-icon>
    New {{ "KEYWORD.patient" | translate | titlecase }}
  </button>

  <mat-menu #appPatientMenu="matMenu">
    <button (click)="newPatientQuick()" mat-menu-item>
      <mat-icon class="fas fa-user-plus mr"></mat-icon>
      Quick Add
    </button>

    <button [routerLink]="['/merchant/', { outlets: { page: ['existing-patient-create'] } }]" mat-menu-item>
      <mat-icon class="fas fa-user-tag mr"></mat-icon>
      Detailed Add
    </button>
    <button mat-menu-item>
      <mat-icon>face</mat-icon>
      Invite - Update Profile
    </button>
  </mat-menu>

  <button class="btn-small ml" *ngIf="manualSelection!=true" (click)="uploadData()" mat-raised-button color="primary">
    <mat-icon class="fas fa-users"></mat-icon> &nbsp; Import data
  </button>

  <button class="btn-small ml" *ngIf="isModuleBoostReachActive == true && manualSelection!=true"
    (click)="openBoostDialog()" mat-raised-button color="accent">
    <mat-icon class="fas fa-rocket"></mat-icon> &nbsp; Boost reach
  </button>

  <button class="row select-btn ml" (click)="selectAll()" *ngIf=" manualSelection!=true" mat-stroked-button color=""
    matTooltip="Select All">
    <mat-icon>border_all</mat-icon>
  </button>

  <button class="row select-btn stroked" (click)="selectPage()" *ngIf=" manualSelection!=true" mat-stroked-button
    color="" matTooltip="Select Page">
    <mat-icon>border_outer</mat-icon>
  </button>



  <button class="btn pull-right  mt  mr" *ngIf=" manualSelection==true" (click)="unselectManual()" mat-raised-button>
    Deselect All <mat-icon class="sml">select_all</mat-icon>
  </button>

  <button class="btn pull-right  mt   mr" *ngIf=" manualSelection==true" (click)="selectPageManual()" mat-raised-button>
    Select Page <mat-icon class="sml">border_outer</mat-icon>
  </button>


  <button class="btn pull-right mt   mr" *ngIf=" manualSelection==true" (click)="selectAllManual()" mat-raised-button>
    Select All <mat-icon class="sml">border_all</mat-icon>
  </button>





  <button class="animated stroked" *ngIf="
     ( (selectedCustomerIDs && selectedCustomerIDs.length > 0) || (selectedProspectIDs && selectedProspectIDs.length > 0))&& manualSelection!=true
    " [matMenuTriggerFor]="ActionBtnmenu" mat-stroked-button matTooltip="Options">
    <mat-icon class="fas fa-cog"></mat-icon> <span *ngIf="(
      (selectedCustomerIDs && selectedCustomerIDs.length > 0) ||
      (selectedProspectIDs && selectedProspectIDs.length > 0))"
      class="selected-number-item  accent-background">{{selectedCustomerIDs.length+ selectedProspectIDs.length}}</span>
  </button>
  <mat-menu #ActionBtnmenu="matMenu">
    <button class="row select-btn" (click)="unselect()" mat-menu-item color="">
      <mat-icon>select_all</mat-icon>
      Deselect
    </button>
    <button class="row select-btn" (click)="createStaticFilterTag()" mat-menu-item color="">
      <mat-icon class="fas fa-tag"></mat-icon>
      Add {{ "KEYWORD.patient" | translate | titlecase }} Tag
    </button>
    <button class="row select-btn" (click)="removeStaticFilterTag()" mat-menu-item color="">
      <mat-icon class="fas fa-eraser"></mat-icon>
      Remove Tag
    </button>
    <button [matMenuTriggerFor]="appMarketingMenu" mat-menu-item>Marketing Consent</button>
    <button [matMenuTriggerFor]="appInvMenu" mat-menu-item>Send Invitation</button>

    <mat-menu #appMarketingMenu="matMenu">
      <button class="row select-btn" (click)="enableGroup()" mat-menu-item color="">
        <mat-icon class=" fas fa-sms smr"></mat-icon>
        Enable <strong> SMS</strong> Communication
      </button>

      <button class="row select-btn" (click)="disableGroup()" mat-menu-item color="">
        <mat-icon class=" fas fa-comment-slash smr"></mat-icon>
        Disable <strong> SMS</strong> Communication
      </button>


      <mat-divider></mat-divider>


      <button class="row select-btn" (click)="enableGroupEmail()" mat-menu-item color="">
        <mat-icon class=" fas fa-envelope-open-text smr"></mat-icon>
        Enable <strong> Email</strong> Communication
      </button>

      <button class="row select-btn" (click)="disableGroupEmail()" mat-menu-item color="">
        <mat-icon class=" fas fa-envelope smr"></mat-icon>
        Disable <strong> Email</strong> Communication
      </button>


    </mat-menu>

    <mat-menu #appInvMenu="matMenu">
      <button (click)="sendProfileUpdateInvGroup()" mat-menu-item>Profile Update</button>
      <button (click)="sendMedicalHistoryInvGroup()" mat-menu-item>Health History</button>
    </mat-menu>
  </mat-menu>
</div>

<div [hidden]="existingPatientsDB.data.length == 0">
  <div [hidden]="displayDetailCard == true || displayInvitations == true">
    <div class="row clearfix">
      <!-- <p class="pull-right" *ngIf="dateFrom != null || dateTo != null">
        <span *ngIf="dateFrom">From:
          <strong>{{ dateFrom | customDateTime }} </strong>
        </span>

        <span *ngIf="dateTo">
          To:
          <strong>{{ dateTo | customDateTime }}</strong>
        </span>
      </p> -->

      <h1 class="dataHeader rm-mt rm-mb">
        {{ title }}:
        <span class="badge">{{ dataSource.filteredData.length }}</span>
      </h1>
    </div>

    <mat-card class="list-card">
      <div class="row clearfix searchArea primary-gradient-img">
        <!-- search -->
        <div class="row clearfix flex">
          <div class="full-width flex animated fadeInDown">
            <mat-form-field class="full-width white" appearance="outline">
              <mat-label>Filter by first name, last name, email, reference, mobile..etc</mat-label>
              <input class="clearfix" (input)="setFilter($event.target.value, 'fullName')" name="FirstName"
                placeholder="First Name" matInput />
            </mat-form-field>

            <mat-form-field class="white half-width" appearance="outline" *ngIf="merchantTags.length > 0">
              <mat-label>Tags</mat-label>
              <mat-select class="" (selectionChange)="setFilter($event.value, 'Tags')" name="Tag" placeholder="Tag"
                multiple>
                <mat-option *ngFor="let a of merchantTags | orderBy" [value]="a">
                  {{ a }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="white half-width" *ngIf="merchantTags.length > 0" appearance="outline">
              <mat-label>Exclude Tags</mat-label>
              <mat-select class="" (selectionChange)="setFilter($event.value, 'NoTags')" name="Tag"
                placeholder="Exclude Tag" multiple>
                <mat-option *ngFor="let a of merchantTags | orderBy" [value]="a">
                  {{ a }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>

      <table class="list-table" #table [dataSource]="dataSource" mat-table matSort>
        <ng-container cdkColumnDef="checkbox" sticky>
          <th *matHeaderCellDef mat-header-cell></th>
          <td *matCellDef="let row" mat-cell matTooltip="Select {{ row.CalculatedName | lowercase | ucfirst }}">
            <mat-checkbox [checked]="isSelected(row['ID'], row['TableName'])"
              (change)="select(row['ID'], row['TableName'], $event.checked)" color="accent"></mat-checkbox>
          </td>
        </ng-container>



        <ng-container cdkColumnDef="checkboxManual" sticky>
          <th *matHeaderCellDef mat-header-cell></th>
          <td *matCellDef="let row" mat-cell matTooltip="Select {{ row.CalculatedName | lowercase | ucfirst }}">
            <mat-checkbox [checked]="isSelectedManual(row)" (change)="selectManual(row, $event.checked)" color="accent">
            </mat-checkbox>
          </td>
        </ng-container>



        <!-- / select bulk patients -->

        <ng-container cdkColumnDef="LastName" sticky>
          <th *matHeaderCellDef mat-header-cell mat-sort-header>Last Name</th>
          <td *matCellDef="let row" (click)="quickView(row['ID'])" mat-cell
            matTooltip="{{ row.CalculatedName | lowercase | ucfirst }}">
            {{ row.Name | lowercase | ucfirst }}
          </td>
        </ng-container>
        <ng-container cdkColumnDef="FirstName" sticky>
          <th *matHeaderCellDef mat-header-cell mat-sort-header>First Name</th>
          <td *matCellDef="let row" (click)="quickView(row['ID'])" mat-cell
            matTooltip="{{ row.CalculatedName | lowercase | ucfirst }}">
            {{ row.FirstName | lowercase | ucfirst }}
          </td>
        </ng-container>

        <!-- avatar -->

        <ng-container cdkColumnDef="profilePic" sticky>
          <th *matHeaderCellDef mat-header-cell mat-sort-header></th>
          <td *matCellDef="let row" mat-cell>
            <app-customer-prospect-view-picture class="col-pic" [patientID]="row['ID']"
              [LastModified]="row['LastModified']">
            </app-customer-prospect-view-picture>
          </td>
        </ng-container>

        <!-- DOB Column -->
        <ng-container cdkColumnDef="DateOfBirth">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>Date of birth</th>
          <td *matCellDef="let row" (click)="quickView(row['ID'])" mat-cell
            matTooltip="{{ row.DateOfBirth | customDate }}">
            {{ row.DateOfBirth | customDate }}
          </td>
        </ng-container>

        <!-- mobile Column -->
        <ng-container cdkColumnDef="mobile">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>Phone/Mobile</th>
          <td *matCellDef="let row" (click)="quickView(row['ID'])" mat-cell
            matTooltip="{{ row['mobiles.Number'] | customPhone }}">
            {{ row['mobiles.Number'] | customPhone }}
          </td>
        </ng-container>
        <!-- email Column -->
        <ng-container cdkColumnDef="email">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>Email Address</th>
          <td *matCellDef="let row" (click)="quickView(row['ID'])" mat-cell matTooltip="{{ row['emails.Email'] }}">
            {{ row['emails.Email'] }}
          </td>
        </ng-container>
        <!-- address Column -->
        <ng-container cdkColumnDef="address">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>Address</th>
          <td *matCellDef="let row" (click)="quickView(row['ID'])" mat-cell
            matTooltip="{{ row['addresses.Calculated'] }}">
            {{ row['addresses.Calculated'] }}
          </td>
        </ng-container>

        <ng-container cdkColumnDef="tags">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>Tags</th>
          <td *matCellDef="let row" (click)="quickView(row['ID'])" mat-cell>
            <mat-chip-list>
              <mat-chip *ngFor="let tag of row['tags']" [ngClass]="{'default-chip':indexOfTag(tag) }">{{tag}}
              </mat-chip>
            </mat-chip-list>
          </td>
        </ng-container>

        <ng-container cdkColumnDef="referred">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>Referred</th>
          <td *matCellDef="let row" (click)="quickView(row['ID'])" mat-cell>
            <span class="list-label smaller round" *ngIf="row['ReferredCustomer'] == '1'"
              style="background-color: rgb(90, 186, 71)">
              <mat-icon class="green">check</mat-icon>
            </span>
            <!-- <span *ngIf="row['ReferredCustomer']!='1'"><mat-icon class="red">clear</mat-icon></span> -->
          </td>
        </ng-container>

        <!-- marketing consent -->
        <ng-container cdkColumnDef="marketing">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Marketing</th>
          <td mat-cell *matCellDef="let row" (click)="quickView(row['ID'])">

            <span *ngIf="row['MarketingConsent.Given']=='1'" class="mr"
              matTooltip="{{'KEYWORD.patient' | translate | titlecase}} has given Marketing Consent via SMS ">
              <mat-icon class="fas fa-sms" style="color:grey"></mat-icon>
            </span>

            <span *ngIf="row['MarketingConsent_Email.Given']=='1'" class="mr"
              matTooltip="{{'KEYWORD.patient' | translate | titlecase}} has given Marketing Consent via Email ">
              <mat-icon class="fas fa-envelope" style="color:grey"></mat-icon>
            </span>

            <span *ngIf="row['MarketingConsent.Given']=='0' && row['MarketingConsent_Email.Given']=='0'"
              class="list-label  mr smaller round"
              matTooltip="{{'KEYWORD.patient' | translate | titlecase}} has NOT given Marketing Consent "
              style="background-color:rgb(233, 63, 63)">
              <mat-icon>close</mat-icon>
            </span>


          </td>
        </ng-container>
        <!-- / marketing consent -->

        <!--
<th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let row"> -->

        <!-- Action Button Column -->
        <ng-container cdkColumnDef="Actions" stickyEnd>
          <th *matHeaderCellDef mat-header-cell mat-sort-header>Actions</th>
          <td *matCellDef="let row" mat-cell>
            <div style="float: right">
              <button [matMenuTriggerFor]="menu" mat-icon-button>
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">




                <button mat-menu-item
                  *ngIf="row['ID'] && row['ID']!='00000000000000000000000000000000'&& row['ID']!='20202020202020202020202020202020'"
                  (click)="quickView(row['ID'])">

                  <span>Quick View</span>
                </button>


                <button mat-menu-item
                  *ngIf="row['ID'] && row['ID']!='00000000000000000000000000000000'&& row['ID']!='20202020202020202020202020202020'"
                  (click)="populateDetailCard(row['ID'])">

                  <span>Full {{'KEYWORD.patient' | translate | titlecase }} Details</span>
                </button>
                <mat-divider></mat-divider>
                <button mat-menu-item color=""
                  *ngIf="row['ID'] && row['ID']!='00000000000000000000000000000000'&& row['ID']!='20202020202020202020202020202020'"
                  class="row select-btn" (click)="quickEdit(row['ID'])">
                  <mat-icon class="fas fa-edit"></mat-icon>
                  Edit {{"KEYWORD.patient" | translate | titlecase }}
                </button>


                <button *ngIf="isModulePatientSMSActive == true" (click)="sendSMS(row['ID'])" mat-menu-item>
                  <mat-icon class="fas fa-sms"></mat-icon>
                  <span>Send SMS</span>
                </button>
                <button *ngIf="row['emails.Email']" mat-menu-item (click)="contact(row)">
                  <mat-icon class="fas fa-envelope"></mat-icon>
                  <span>Send Email</span>
                </button>

                <mat-divider></mat-divider>


                <button *ngIf="isModuleNoteFeaturesActive == true" (click)="createNote(row['ID'])" mat-menu-item>
                  <mat-icon>note_add</mat-icon>
                  <span>Create Note</span>
                </button>

                <button *ngIf="isModuleNoteFeaturesActive == true" (click)="viewNotes(row['ID'])" mat-menu-item>
                  <mat-icon class="fa fa-list-alt"></mat-icon>
                  <span>View Notes</span>
                </button>

                <button *ngIf="row['ID'] && isModuleAppointmentActive == true && isAppointmentDisabled != true"
                  (click)="requestAppointment(row['ID'])" mat-menu-item>
                  <mat-icon>alarm_add</mat-icon>
                  <span>New appointment</span>
                </button>



                <button mat-menu-item [matMenuTriggerFor]="patientInvLink" [hidden]="row['Status']=='Archived'"
                  *ngIf="isModuleTreatmentPlanActive==true && financedProductGroup">
                  <mat-icon>email</mat-icon>
                  <span>New Invitation</span>
                </button>

                <mat-menu #patientInvLink="matMenu">
                  <div *ngIf="isModuleTreatmentPlanActive == true && financedProductGroup">

                    <button mat-menu-item *ngFor="let p of financedProductGroup" (click)="linkToInvitation(row, p)">
                      <mat-icon class="position fas {{ p.Icon }} smr "></mat-icon> New
                      <strong>{{ p['Group.Label'] }}</strong>

                    </button>
                    <button
                      *ngIf="row && row['ID'] && isModulePatientHistoryActive == true && healthHistoryActive == true"
                      (click)="inviteMedicalHistory(row['ID'])" mat-menu-item>
                      <mat-icon class="smr">local_pharmacy</mat-icon>
                      <span>New <strong> Health History </strong> </span>
                    </button>
                  </div>
                </mat-menu>

                <mat-divider></mat-divider>

                <button *ngIf="row['Status'] != 'Archived'" (click)="archivePatient(row)" mat-menu-item>
                  <mat-icon>archive</mat-icon>
                  <span> Archive {{ "KEYWORD.patient" | translate }}</span>
                </button>

                <button *ngIf="row['Status'] == 'Archived'" (click)="unarchivePatient(row)" mat-menu-item>
                  <mat-icon>unarchive</mat-icon>
                  <span>Unarchive {{ "KEYWORD.patient" | translate | titlecase }}</span>
                </button>
              </mat-menu>
            </div>
          </td>
        </ng-container>
        <!-- <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *cdkRowDef="let row; columns: displayedColumns;"></mat-row> -->

        <tr *matHeaderRowDef="displayedColumns; sticky: true" mat-header-row></tr>
        <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>
      </table>
      <mat-paginator #paginator [length]="dataSource.filteredData.length" [pageIndex]="0" [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions">
      </mat-paginator>
    </mat-card>
  </div>
</div>

<div *ngIf="existingPatientsDB && existingPatientsDB.data.length == 0 && existingPatientsDB.firstLoadEvent != false">
  <app-empty-list-message></app-empty-list-message>
</div>
