<div class="row clearfix gridView" @fade>
  <div class="file-container">
    <mat-card class="card clearfix main-card-template">
      <div class="card-header primary-gradient-img clearfix inModal camps" *ngIf="displayHeader == true">
        <div class="row clearfix full-width content">
          <button class="btn-close btn-clear mat-button white" *ngIf="isModal == true" (click)="closeModal()">
            <mat-icon>clear</mat-icon>
          </button>

          <div class="row titleArea clearfix">
            <mat-icon class="pull-left">send</mat-icon>
            <h2 class="summary-header rm-m">
              {{ title }}
              <span class="subLabel" *ngIf="templates && templates.length"
              >Available templates: <span class="badge">{{ templates.length }}</span></span
              >
            </h2>
          </div>
        </div>
      </div>

      <!-- filter controls -->

      <div class="row clearfix" *ngIf="templates && templates.length > 3">
        <mat-button-toggle-group class="btn-group full-width" [(ngModel)]="socialMediaType">
          <mat-button-toggle value=""> All</mat-button-toggle>

          <mat-button-toggle value="Facebook">
            <mat-icon class="fab fa-facebook"></mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="Twitter">
            <mat-icon class="fab fa-twitter"></mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="Instagram">
            <mat-icon class="fab fa-instagram"></mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="LinkedIn">
            <mat-icon class="fab fa-linkedin"></mat-icon>
          </mat-button-toggle>

          <mat-button-toggle value="Pinterest">
            <mat-icon class="fab fa-pinterest"></mat-icon>
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>

      <mat-dialog-content class="full-width" [ngClass]="{ 'no-modal-height': isModal != true }">
        <div class="row clearfix flex" *ngIf="templates && templates.length > 3">
          <mat-form-field class="full-width mr" *ngIf="templates.length >= 4" appearance="outline">
            <mat-label>Filter by title,CTA, post, supplier, tags...etc</mat-label>
            <input
              [(ngModel)]="filter"
              matInput
              name="name"
              placeholder="Filter by label, post, supplier, tags...etc"
              tabindex="1"
            />
          </mat-form-field>

          <mat-form-field class="half-width" appearance="outline">
            <mat-label>Tags</mat-label>

            <mat-select class="margin-btm" [(ngModel)]="selectedTagList" name="Tags" multiple placeholder="Tags">
              <mat-option *ngFor="let t of tagsList | orderBy" [value]="t">
                {{ t }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="half-width" *ngIf="suppliers && suppliers.length > 0" appearance="outline">
            <mat-label>Suppliers</mat-label>
            <mat-select
              class="margin-btm"
              [(ngModel)]="selectedSuppliers"
              name="suplipers"
              multiple
              placeholder="Suppliers"
            >
              <mat-option *ngFor="let t of suppliers | orderBy: ['Label']" [value]="t.ID">
                {{ t.Label }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <div class="full-width filter-control">
            <mat-button-toggle-group
              class="btn-group group-list-filter"
              *ngIf="templates.length >= 4"
              [(ngModel)]="orderByFilter"
              name="sortListSetting"
            >
              <mat-button-toggle class="text-center" [value]="'-DateTimeCreated'" matTooltip="Sort by last added">
                <mat-icon class="fa fa-history"></mat-icon> Recently added
              </mat-button-toggle>
              <mat-button-toggle class="text-center" [value]="'Label'" matTooltip="Sort alphabetically">
                <mat-icon class="fa fa-sort-alpha-down"></mat-icon> Sort by name
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>

        <hr class="mb" *ngIf="templates && templates.length > 3"/>

        <div
          class="row clearfix text-center templateContainer flex flexwrap mb mt"
          *ngIf="templates && templates.length > 0"
          [@stagger]="templates.length"
          matchItemHeight="matchThis"
        >
          <mat-card
            class="template-preview file animate mat-elevation-z4 row"
            *ngFor="
              let t of templates
                | FilterArrayMultipleContainValue
                  : [
                      'SupplierLabels',
                      'ActionCall.Label',
                      'ActionCall.Description',
                      'CategoryLabels',
                      'BrandLabels',
                      'TypeLabels',
                      'Tag.Label',
                      'Tag.Description',
                      'Label',
                      'Post',
                      'Tags',
                      'SocialMediaType.Label'
                    ]
                  : filter
                | FilterArrayMultipleContainValue: ['StaticList.Label']:selectedTagList
                | FilterArrayMultipleContainValue: ['SocialMediaType.Code']:socialMediaType
                | FilterArrayMultipleContainValue: ['SupplierKeys']:selectedSuppliers
                | OrderArrayObject: [orderByFilter]
            "
            [@fade]
            [class.selectedTemplate]="selectedTemplate && t['ID'] == selectedTemplate['ID']"
            (click)="viewSocialMediaPosts(t)"
            matRipple
          >
            <button
              class="preview-btn"
              [matMenuTriggerFor]="previewMenu"
              (click)="$event.stopPropagation()"
              mat-flat-button
              color="accent"
              matTooltip="Preview"
            >
              <mat-icon class="fas fa-search"></mat-icon>
            </button>

            <mat-menu #previewMenu="matMenu">
              <button (click)="viewSocialMediaPosts(t)" mat-menu-item>
                <mat-icon class="fas fa-share-alt-square"></mat-icon>
                <span>Share this Post</span>
              </button>

              <button *ngIf="isAdminOrPromoter == true" (click)="EditSocialMedia(t)" mat-menu-item>
                <mat-icon class="fas fa-edit"></mat-icon>
                <span>Edit Social media Template</span>
              </button>
            </mat-menu>

            <div class="flex clearfix title-container">
              <div
                class="status-row icons-bar"
                *ngIf="t && t['SocialMediaType.Code'] == 'Facebook'"
                [style.background]="'#4267B2'"
                [ngClass]="{ 'icons-bar-alone': !t || (t && !t.Label) }"
              >
                <mat-icon class="fab fa-facebook-f" *ngIf="t['SocialMediaType.Code'] == 'Facebook'"></mat-icon>
              </div>
              <div
                class="status-row icons-bar"
                *ngIf="t && t['SocialMediaType.Code'] == 'Instagram'"
                [style.background]="'#E1306C'"
                [ngClass]="{ 'icons-bar-alone': !t || (t && !t.Label) }"
              >
                <mat-icon class="fab fa-instagram" *ngIf="t['SocialMediaType.Code'] == 'Instagram'"></mat-icon>
              </div>
              <div
                class="status-row icons-bar"
                *ngIf="t && t['SocialMediaType.Code'] == 'LinkedIn'"
                [style.background]="'#2867B2'"
                [ngClass]="{ 'icons-bar-alone': !t || (t && !t.Label) }"
              >
                <mat-icon class="fab fa-linkedin-in" *ngIf="t['SocialMediaType.Code'] == 'LinkedIn'"></mat-icon>
              </div>
              <div
                class="status-row icons-bar"
                *ngIf="t && t['SocialMediaType.Code'] == 'Twitter'"
                [style.background]="'#1DA1F2'"
                [ngClass]="{ 'icons-bar-alone': !t || (t && !t.Label) }"
              >
                <mat-icon class="fab fa-twitter" *ngIf="t['SocialMediaType.Code'] == 'Twitter'"></mat-icon>
              </div>

              <div
                class="status-row icons-bar"
                *ngIf="t && t['SocialMediaType.Code'] == 'Pinterest'"
                [style.background]="'#c8232c'"
                [ngClass]="{ 'icons-bar-alone': !t || (t && !t.Label) }"
              >
                <mat-icon class="fab fa-pinterest" *ngIf="t['SocialMediaType.Code'] == 'Pinterest'"></mat-icon>
              </div>

              <div class="full-width title-text" *ngIf="t && t['Label']">
                <p class="rm-m" [style.color]="getColorFromSocial(t['SocialMediaType.Code'])">
                  {{ t['Label'] }}
                </p>
              </div>
            </div>

            <div class="full-width cleafix image-container">
              <div
                class="template-header-tags full-width row clearfix matchThis"
                *ngIf="t['StaticList.Label'] && t['StaticList.Label'].length > 0"
              >
                <p
                  class="small inner-tags text-center rm-mt rm-mb"
                  *ngIf="t['StaticList.Label'] && t['StaticList.Label'].length > 0"
                >
                  <span *ngFor="let d of t['StaticList.Label'] | orderBy"> #{{ d }} </span>
                </p>
              </div>

              <app-ep-document-view-image
                class="squareImg social-media-thumbnail cleafix"
                [hideZoomButton]="true"
                [defaultPicture]="
                  'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/gettingstarted-img4.png)'
                "
                [link]="getImageLink(t['ID'], t['LastModified'])"
              >
              </app-ep-document-view-image>
            </div>

            <div class="template-header full-width row clearfix matchThis">
              <h4
                class="sr-title rm-mt rm-mb"
                *ngIf="t['ActionCall.Label'] && toLowerCase(t['Label']) !== toLowerCase(t['ActionCall.Label'])"
              >
                {{ t['ActionCall.Label'] }}
              </h4>
            </div>
          </mat-card>
        </div>

        <div class="text-center full-width clearfix">
          <button
            class="text-center btn-large ml mr"
            *ngIf="displayLoadMore == true"
            (click)="loadMore()"
            mat-raised-button
            color="accent"
          >
            Load More Posts <mat-icon>more_horiz</mat-icon>
          </button>
        </div>

        <div
          class="margin-div text-center mt mb"
          *ngIf="(!templates || (templates && templates.length <= 0)) && finishLoading == true"
        >
          <app-empty-list-message [title]="''" [message]="'No template found'"></app-empty-list-message>
        </div>
      </mat-dialog-content>

      <div class="drop-button mt">
        <button
          class="inline selectExistingPatient pull-left"
          *ngIf="isModal == true"
          (click)="closeModal()"
          mat-raised-button
          color="primary"
        >
          Close
        </button>
      </div>
    </mat-card>
  </div>
</div>
