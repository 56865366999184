<div class="container full-width error-page error text-center">
  <img class="character_error" *ngIf="!imageSource"/>
  <img *ngIf="imageSource" [src]="imageSource" />
  <div class="circle"></div>

  <h1 class="accent-color">404</h1>
  <h2>{{ errorTitle }}</h2>
  <p>{{ errorContent }}</p>

  <div class="drop-buttons" *ngIf="displayContact == true">
    <button class="btn-large mr ml" (click)="contactUs()" mat-raised-button color="primary">Contact us</button>
    <button class="btn-large" (click)="cancel()" mat-raised-button color="accent">Go back</button>
  </div>
</div>
