import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';

import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { MerchantViewComponent } from '../../merchant/merchant-view/merchant-view.component';

import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { HeaderService } from '../../../shared/services/header.service';

/**
 * @title Feature-rich data table
 */
@Component({
  selector: 'app-invitation-list-archives',
  templateUrl: './invitation-list-archives.component.html',
  styleUrls: ['./invitation-list-archives.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('*', style({ opacity: 0 }), { optional: true }),
        query(
          '*',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('*', style({ opacity: 1 }), { optional: true }),
        query(
          '*',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class InvitationListArchivesComponent implements OnInit {
  serviceRef;

  merchants = [];

  merchantID = 'none';

  role;

  hideFilter = false;

  @Input()
  membership = false;

  typeFilter = 'a';

  constructor(private AuthenticationService: AuthenticationService) {}

  ngOnInit() {
    this.AuthenticationService.getCurrentUser().subscribe((res) => {
      if (res && res.success && res.data['Role'] && res.data['Role']) {
        this.role = res.data['Role'];

        if (this.role == 'admin' || this.role == 'promoter') {
          const _mID = JSON.parse(localStorage.getItem('selectedMerchant'));
          if (_mID) {
            this.merchantID = _mID;
            // this.hideFilter = true;
          }
          HeaderService.getPromoterViewAsObject().subscribe((res) => {
            if (res && res['promoterViewAs'] && res['promoterViewAs'] == 'promoter') {
              this.merchantID = 'none';
              this.hideFilter = false;
            } else if (res && res['promoterViewAs'] && res['promoterViewAs'] == 'merchant') {
              this.merchantID = res['merchantID'];
              this.hideFilter = true;
            }
          });
        }
      }
    });
  }

  viewMerchant() {
    const ref = RootAppComponent.dialog.open(MerchantViewComponent, {
      data: this.merchantID,
      width: '600px',
    });

    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  applySearch(result) {
    this.merchantID = result.merchantID;
  }
}
