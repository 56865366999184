import { animate, keyframes, query, stagger, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { map as _map, uniq } from 'lodash';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { VideoTutorialsModalComponent } from '../../../shared/components/video-tutorials-modal/video-tutorials-modal.component';
import { CustomCurrencyPipe } from '../../../shared/pipes/custom-currency.pipe';
import { UserDataService } from '../../../shared/services/user-data.service';
import { UtilsService } from '../../../shared/services/utils.service';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { InvitationTemplateMergerComponent } from '../../invitation-template/invitation-template-merger/invitation-template-merger.component';
import { InvitationService } from '../../invitation/shared/services/invitation.service';
import { TreatmentChoiceAdvancedComponent } from '../../treatment-plan/treatment-choice-advanced/treatment-choice-advanced.component';
import { TreatmentService } from '../shared/treatment.service';
import { Treatment } from '../shared/treatment.type';
import { TreatmentDocumentImportOverviewComponent } from '../treatment-document-import-overview/treatment-document-import-overview.component';

@Component({
  selector: 'app-treatment-create',
  templateUrl: './treatment-create.component.html',
  styleUrls: ['./treatment-create.component.css'],
  animations: [
    trigger('visibilityChanged', [
      state('shown', style({ opacity: 1 })),
      state('hidden', style({ opacity: 0, height: '0' })),
      transition('shown => hidden', animate('600ms')),
      transition('hidden => shown', animate('300ms')),
    ]),
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.animate', style({ opacity: 0 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.2s ease-out',
              keyframes([
                style({ opacity: 0, offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.animate', style({ opacity: 1 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.2s ease-in',
              keyframes([
                style({ opacity: 1, offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class TreatmentCreateComponent implements OnInit {
  treatment: Treatment = new Treatment();
  @Input() invitationID: string;

  @Input() disableTreatmentValue = false;
  @Output() create = new EventEmitter();
  @Input() canEditTotalValue = true;
  @Output() clearData = new EventEmitter();
  @Output() refreshTotalCost = new EventEmitter();
  @Output() validate = new EventEmitter();
  @Input() forceRefreshTotalCost = new EventEmitter();
  @Input() justSelected = false;
  @Input() patientID;
  addFileTreatmentDocument = new EventEmitter();
  removeFileTreatmentDocument = new EventEmitter();
  refreshTreatmentDocument = new EventEmitter();
  canPatientDocument = true;
  addFileStockArt = new EventEmitter();
  removeFileStockArt = new EventEmitter();
  refreshStockArt = new EventEmitter();
  @Output() clearTemplate = new EventEmitter();
  @Output() informedConsentUpdated = new EventEmitter();
  @Output() simpleTreatmentDocumentsUpdated = new EventEmitter();
  @Output() EPTreatmentDocumentsUpdated = new EventEmitter();
  @Output() stockArtDocumentsUpdated = new EventEmitter();
  @Output() EPstockArtDocumentsUpdated = new EventEmitter();
  @Output() templateSelectAll = new EventEmitter();
  @Input() simpleTreatmentDocuments: any = [];
  @Input() EPTreatmentDocuments = [];
  @Input() stockArtDocuments: any = [];
  @Input() EPstockArtDocuments = [];
  @Input() informedConsent = [
    {
      categoryID: null,
      itemCode: null,
      typeID: null,
      brandID: null,
      productID: null,
      showStockArtDocumentsModal: true,
      preDocuments: [],
      stockArtDocuments: [],
      postDocuments: [],
      marketingDocuments: [],
    },
  ];
  documentTypes = ['Treatment Plan', 'Additional Document'];
  documentTypes2 = ['Image', 'Scan / X-Ray'];
  imageExtensionType = ['image', 'video'];
  isAdded;
  @Input()
  selectedTab = 0;
  @Input()
  refreshTreatment = new EventEmitter();
  @Input()
  invitationTemplateID;
  @Input()
  invitationTemplateLabel;
  isFirstConsent = false;
  @Input()
  merchantID;
  validateList = false;
  validateSimple = false;
  treatments: Treatment[] = [];
  @Input() totalTreatmentValue = 0;
  descriptionDiagnosis = null;
  descriptionTreatment = null;
  isAdult = null;
  teeth = [];
  uploadAllAdvanced = new EventEmitter();
  uploadAllSimple = new EventEmitter();
  @Input()
  treatmentDocumentLabel = 'Treatment Document';
  @Input()
  treatmentDocumentDescription;
  visiblityState1 = 'shown';
  isSimpleTreatmentCost = true;
  dontShowAgainAvancedType: boolean = JSON.parse(localStorage.getItem('dontShowAgainAvancedType'));
  isModuleAdvancedTreatmentActive = Settings.global['isModuleAdvancedTreatmentActive'];
  isModulePrivateDocumentActive = Settings.global['isModulePrivateDocumentActive'];
  isModuleExperimentalFeaturesActive = Settings.global['isModuleExperimentalFeaturesActive'];
  isModuleTreatmentGroupActive = Settings.global['isModuleTreatmentGroupActive'];
  isModuleStockArtActive = Settings.global['isModuleStockArtActive'];
  isAdminOrPromoter = false;
  isDownload = false;
  isRedirection = false;
  isPrint = false;
  showMyGallery = false;
  sessionType = 'guest';
  @Input()
  templates = [];

  visiblityState2 = 'shown';

  constructor(
    private authenticationService: AuthenticationService,
    private utilService: UtilsService,
    private treatmentService: TreatmentService,
    private invitationService: InvitationService,
    private userDataService: UserDataService,
    private customCurrencyPipe: CustomCurrencyPipe
  ) {}

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((sessionType) => {
      this.sessionType = sessionType;
      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isAdminOrPromoter = res;
        if (this.isAdminOrPromoter == true) {
          this.isDownload = true;
          this.isRedirection = true;
          this.isPrint = true;
        }
        this.utilService.getCurrentAccess().subscribe((access) => {
          if (access) {
            this.isModuleAdvancedTreatmentActive = access['isModuleAdvancedTreatmentActive'];
            this.isModulePrivateDocumentActive = access['isModulePrivateDocumentActive'];
            this.isModuleExperimentalFeaturesActive = access['isModuleExperimentalFeaturesActive'];
            this.isModuleTreatmentGroupActive = access['isModuleTreatmentGroupActive'];
            this.isModuleStockArtActive = access['isModuleStockArtActive'];
          }
          this.setup();
          this.refreshTreatment.subscribe((res) => {
            if (res === true) {
              this.setup(false);
            } else if (res) {
              this.setupByObject(res, false);
            }
          });

          this.forceRefreshTotalCost.subscribe(() => {
            this.getTreatPlanCost();
          });
        });
      });
    });
  }

  // getTreatmentDocumentModalOpened(d) {
  //   this.loadingTreatmentDocument = d;
  // }
  // getStockArtModalOpened(d) {
  //   this.loadingStockArt = d;
  // }
  linkData(data) {
    if (data) {
      this.simpleTreatmentDocuments = [];
      this.simpleTreatmentDocuments = data.treatmentDocuments;
      this.stockArtDocuments = [];
      this.stockArtDocuments = data.stockArtDocuments;
      if (data['user.cardKey'] && !this.merchantID) {
        this.merchantID = data['user.cardKey'];
      }
      this.informedConsent = data['treatmentPlan'];
      this.totalTreatmentValue = data.totalTreatmentValue || 0;
      if (this.totalTreatmentValue != 0) {
        this.validateSimple = true;
      } else {
        this.validateSimple = false;
      }
      this.sendValidate();
    }
  }

  setupByObject(res, create = true) {
    if (res) {
      if (res.patient && res.patient.prospectID) {
        this.patientID = res.patient.prospectID;
      }

      if (res.user && res.user.cardKey && !this.merchantID) {
        this.merchantID = res.user.cardKey;
      }

      if (res.templates) {
        this.templates = res.templates;
        this.templateSelectAll.emit(false);
      } else {
        this.templates = [];
        this.templateSelectAll.emit(true);
      }

      if (res.invitationTemplateID && res.invitationTemplateLabel) {
        this.invitationService
          .adjustInvitationTreatmentTemplate(this.invitationID, {}, this.isAdminOrPromoter)
          .subscribe((templates) => {
            if (templates) {
              this.templates = templates;
              this.templateSelectAll.emit(false);
            } else {
              this.templates = [];

              this.templateSelectAll.emit(true);
            }
          });
      } else {
        if (res.templates) {
          this.templates = res.templates;
          this.templateSelectAll.emit(false);
        } else {
          this.templates = [];
          this.templateSelectAll.emit(true);
        }
      }

      if (res && res.treatmentDocuments) {
        this.simpleTreatmentDocuments = JSON.parse(JSON.stringify(res.treatmentDocuments));
      } else {
        this.simpleTreatmentDocuments = JSON.parse(JSON.stringify([]));
      }
      if (res && res.stockArtDocuments) {
        this.stockArtDocuments = JSON.parse(JSON.stringify(res.stockArtDocuments));
      } else {
        this.stockArtDocuments = JSON.parse(JSON.stringify([]));
      }
      if (res && res.EPstockArtDocuments) {
        this.EPstockArtDocuments = JSON.parse(JSON.stringify(res.EPstockArtDocuments));
      } else {
        this.EPstockArtDocuments = JSON.parse(JSON.stringify([]));
      }
      if (res && res.EPTreatmentDocuments) {
        this.EPTreatmentDocuments = JSON.parse(JSON.stringify(res.EPTreatmentDocuments));
      } else {
        this.EPTreatmentDocuments = JSON.parse(JSON.stringify([]));
      }

      this.simpleTreatmentDocumentsUpdated.emit(this.simpleTreatmentDocuments);
      this.EPTreatmentDocumentsUpdated.emit(this.EPTreatmentDocuments);
      this.stockArtDocumentsUpdated.emit(this.stockArtDocuments);
      this.EPstockArtDocumentsUpdated.emit(this.EPstockArtDocuments);

      if (res.treatmentPlan && Array.isArray(res.treatmentPlan) && res.treatmentPlan.length > 0) {
        this.informedConsent = JSON.parse(JSON.stringify(res.treatmentPlan));
        this.informedConsentUpdated.emit(this.informedConsent);
      } else {
        this.informedConsent = JSON.parse(
          JSON.stringify([
            {
              categoryID: null,
              itemCode: null,
              typeID: null,
              brandID: null,
              productID: null,
              showStockArtDocumentsModal: true,
              stockArtDocuments: [],
              preDocuments: [],
              postDocuments: [],
              marketingDocuments: [],
            },
          ])
        );
      }

      if (res && (res.totalTreatmentValue || res.totalTreatmentValue === 0)) {
        this.totalTreatmentValue = res.totalTreatmentValue;

        if (this.totalTreatmentValue != 0) {
          this.validateSimple = true;
        } else {
          this.validateSimple = false;
        }

        this.sendValidate();
        if (create == true) {
          this.createSimpleTreatment(false, false, false, false);
        }
      }
    }
  }

  setup(create = true) {
    this.invitationService.getUserData(this.invitationID, null, this.sessionType).subscribe((data) => {
      this.setupByObject(data, create);
    });
  }

  getInformedConsent(o, index) {
    if (o && o.brandID && o.categoryID && o.typeID) {
      this.updateInformedConsent(o, index);
    }
  }

  updateInformedConsent(_o: any = {}, index = null) {
    const o = _o;
    if (o && o.brandID && o.categoryID && o.typeID) {
      if (index !== null) {
        o.index = index;
      }
      if (o) {
        o.showStockArtDocumentsModal = false;
      }
      if (this.merchantID) {
        o.merchantID = this.merchantID;
      }
      this.treatmentService.setLocalTreatmentPlan(this.invitationID, o, this.isAdminOrPromoter).subscribe((res) => {
        if (res) {
          if (this.informedConsent && this.informedConsent.length == 0) {
            this.isFirstConsent = true;
          }
          this.informedConsentUpdated.emit(this.informedConsent);
        }
      });
    }
  }

  addConsent() {
    this.informedConsent.push({
      categoryID: null,
      itemCode: null,
      typeID: null,
      brandID: null,
      showStockArtDocumentsModal: true,
      productID: null,
      preDocuments: [],
      stockArtDocuments: [],
      postDocuments: [],
      marketingDocuments: [],
    });
  }

  removeConsent(i) {
    if (this.informedConsent.length == 1) {
      const confirmDialog1 = new ConfirmDialog(
        'delete',
        'Are you sure?',
        '<p>Remove informed consent?</p> ',
        'No',
        'Yes, remove'
      );
      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirmDialog1,
      });
      ref.componentInstance.onConfirm.subscribe((val) => {
        if (val == true) {
          if (this.informedConsent && this.informedConsent[i]) {
            this.treatmentService.removeLocalTreatmentPlan(this.invitationID, i).subscribe((res) => {
              if (this.informedConsent.length == 1) {
                this.informedConsent = [
                  {
                    categoryID: null,
                    itemCode: null,
                    typeID: null,
                    brandID: null,
                    productID: null,
                    showStockArtDocumentsModal: true,
                    stockArtDocuments: [],
                    preDocuments: [],
                    postDocuments: [],
                    marketingDocuments: [],
                  },
                ];
              } else {
                this.informedConsent.splice(i, 1);
              }
              ref.close();
            });
          } else {
            this.informedConsent = [
              {
                categoryID: null,
                itemCode: null,
                typeID: null,
                brandID: null,
                productID: null,
                showStockArtDocumentsModal: true,
                stockArtDocuments: [],
                preDocuments: [],
                postDocuments: [],
                marketingDocuments: [],
              },
            ];
          }
          this.isFirstConsent = false;
          ref.close();
        } else {
          ref.close();
        }
      });
    } else if (this.informedConsent.length > 1 && i !== null) {
      const confirmDialog = new ConfirmDialog(
        'delete',
        'Are you sure?',
        '<p>Remove informed consent?</p> ',
        'No',
        'Yes, remove'
      );
      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirmDialog,
      });
      ref.componentInstance.onConfirm.subscribe((val) => {
        if (val == true) {
          if (this.informedConsent && this.informedConsent[i]) {
            this.treatmentService.removeLocalTreatmentPlan(this.invitationID, i).subscribe((res) => {
              if (this.informedConsent.length == 1) {
                this.informedConsent = [
                  {
                    categoryID: null,
                    itemCode: null,
                    typeID: null,
                    brandID: null,
                    productID: null,
                    preDocuments: [],
                    showStockArtDocumentsModal: true,
                    stockArtDocuments: [],
                    postDocuments: [],
                    marketingDocuments: [],
                  },
                ];
              } else {
                this.informedConsent.splice(i, 1);
              }
              ref.close();
            });
          } else {
            if (this.informedConsent.length == 1) {
              this.informedConsent = [
                {
                  categoryID: null,
                  itemCode: null,
                  typeID: null,
                  brandID: null,
                  productID: null,
                  showStockArtDocumentsModal: true,
                  stockArtDocuments: [],
                  preDocuments: [],
                  postDocuments: [],
                  marketingDocuments: [],
                },
              ];
            } else {
              this.informedConsent.splice(i, 1);
            }
            ref.close();
          }
        } else {
          ref.close();
        }
      });
    }
  }

  toggle1() {
    if (this.visiblityState1 === 'hidden') {
      this.visiblityState1 = 'shown';
    } else {
      this.visiblityState1 = 'hidden';
    }
  }

  toggle2() {
    if (this.visiblityState2 === 'hidden') {
      this.visiblityState2 = 'shown';
    } else {
      this.visiblityState2 = 'hidden';
    }
  }

  clearInformedConsent() {
    if (this.informedConsent && this.informedConsent.length == 1) {
      this.treatmentService.removeLocalTreatmentPlan(this.invitationID, 0).subscribe((res) => {
        this.informedConsent = [
          {
            categoryID: null,
            itemCode: null,
            typeID: null,
            brandID: null,
            productID: null,
            preDocuments: [],
            showStockArtDocumentsModal: true,
            stockArtDocuments: [],
            postDocuments: [],
            marketingDocuments: [],
          },
        ];
      });
    } else {
      this.informedConsent = [
        {
          categoryID: null,
          itemCode: null,
          typeID: null,
          brandID: null,
          productID: null,
          preDocuments: [],
          showStockArtDocumentsModal: true,
          stockArtDocuments: [],
          postDocuments: [],
          marketingDocuments: [],
        },
      ];
    }
  }

  clearInformedConsentEvent(isClear, i) {
    if (isClear == true && this.informedConsent && this.informedConsent[i]) {
      this.treatmentService.removeLocalTreatmentPlan(this.invitationID, i).subscribe((res) => {
        if (this.informedConsent.length == 1) {
          this.informedConsent = [
            {
              categoryID: null,
              itemCode: null,
              typeID: null,
              brandID: null,
              productID: null,
              preDocuments: [],
              showStockArtDocumentsModal: true,
              stockArtDocuments: [],
              postDocuments: [],
              marketingDocuments: [],
            },
          ];
        } else {
          this.informedConsent.splice(i, 1);
        }
      });
    }
  }

  getTreatPlanCost() {
    this.invitationService.getTreatmentPlanCost(this.invitationID).subscribe((res) => {
      this.totalTreatmentValue = res;
    });
  }

  getSimpleTreatment() {
    this.treatmentService.getSimpleTreatment(this.invitationID).subscribe((simpleTreatment) => {
      if (simpleTreatment && simpleTreatment.treatmentDocuments) {
        this.simpleTreatmentDocuments = [];
        this.simpleTreatmentDocuments = simpleTreatment.treatmentDocuments;
        this.stockArtDocuments = [];
        this.stockArtDocuments = simpleTreatment.stockArtDocuments;
        this.EPstockArtDocuments = [];
        this.EPstockArtDocuments = simpleTreatment.EPstockArtDocuments;
        this.EPTreatmentDocuments = [];
        this.EPTreatmentDocuments = simpleTreatment.EPTreatmentDocuments;
        this.simpleTreatmentDocumentsUpdated.emit(this.simpleTreatmentDocuments);
        this.EPTreatmentDocumentsUpdated.emit(this.EPTreatmentDocuments);
        this.stockArtDocumentsUpdated.emit(this.stockArtDocuments);
        this.EPstockArtDocumentsUpdated.emit(this.EPstockArtDocuments);
      }
      this.invitationService.getTreatmentPlanCost(this.invitationID).subscribe((res1) => {
        this.totalTreatmentValue = res1;
        if (this.totalTreatmentValue != 0) {
          this.validateSimple = true;
        } else {
          this.validateSimple = false;
        }
        this.sendValidate();
      });
    });
  }

  removeSimpleTreatmentDocument(id) {
    const data = {
      invitationID: this.invitationID,
      type: 'treatmentSimple',
      option: null,
    };
    this.treatmentService.removeTreatmentDocument(id, data).subscribe((result) => {
      this.getSimpleTreatment();
    });
  }

  removeSimpleTreatmentStockArtDocument(id) {
    const data = {
      invitationID: this.invitationID,
      type: 'treatmentStockArtSimple',
      option: null,
    };
    this.treatmentService.removeTreatmentDocument(id, data).subscribe((result) => {
      this.getSimpleTreatment();
    });
  }

  teethSelection(result) {
    this.isAdult = result.isAdult;
    this.teeth = result.selectedTeeth;
  }

  onSelectCategory(data) {}

  onSelectType(data) {}

  onSelectBrand(data) {
    this.treatment.treatmentBrand.id = data.Brand_key;
    this.treatment.treatmentBrand.Label = data.BrandLabel;
    this.treatment.treatmentType.Label = data.TreatmentTypeLabel;
    this.treatment.treatmentCategory.Label = data.TreatmentCategoryLabel;
    this.treatment.treatmentValue = data.DefaultSellPrice;
    this.treatment.treatmentCost = data.DefaultCostPrice;
    this.treatment.descriptionDiagnosis = data.Notes_Diagnosis;
    this.treatment.descriptionTreatment = data.Notes_Treatment;
  }

  createTreatmentSimple() {
    this.uploadAllSimple.emit();
  }

  changeAmount() {
    UtilsClass.savingData();
    const saveDate = {
      attribute: 'totalTreatmentValue',
      value: this.totalTreatmentValue,
    };
    this.userDataService.setUserData(this.invitationID, saveDate, this.sessionType).subscribe((res) => {
      this.refreshTotalCost.emit(this.totalTreatmentValue);
      if (this.totalTreatmentValue != 0) {
        this.validateSimple = true;
      } else {
        this.validateSimple = false;
      }

      // this.createSimpleTreatment(false, false, false, false)
      this.sendValidate();
    });
  }

  sendValidate() {
    this.validate.emit(this.validateList || this.validateSimple);
  }

  onCompleteAllSimple(data) {
    this.simpleTreatmentDocuments = data;
    this.createSimpleTreatment(data, false, false, false);
  }

  onCompleteAllStockArt(data) {
    this.stockArtDocuments = data;
    this.createSimpleTreatment(false, data, false, false);
  }

  createSimpleTreatment(documents = null, stockArtDocs = null, EPstockArtDocs = null, EPTreatmentDocs = null) {
    const treatmentValue = this.totalTreatmentValue;
    const payload = {
      id: this.invitationID,
      totalTreatmentValue: treatmentValue,
    };
    let treatmentDocuments = null;

    if (documents != false && Array.isArray(documents)) {
      const _document = _map(documents, (item) => {
        if (item && item._id) {
          return item._id;
        }

        return item;
      });

      documents = uniq(_document);
      treatmentDocuments = documents.join(',');
      payload['treatmentDocuments'] = treatmentDocuments;
    } else if (Array.isArray(this.simpleTreatmentDocuments)) {
      const _document = _map(this.simpleTreatmentDocuments, (item) => {
        if (item && item._id) {
          return item._id;
        }

        return item;
      });

      this.simpleTreatmentDocuments = uniq(_document);
      payload['treatmentDocuments'] = this.simpleTreatmentDocuments.join(',');
    }
    let stockArtDocuments = null;
    if (stockArtDocs != false && Array.isArray(stockArtDocs)) {
      const _document = _map(stockArtDocs, (item) => {
        if (item && item._id) {
          return item._id;
        }

        return item;
      });

      stockArtDocs = uniq(_document);
      stockArtDocuments = stockArtDocs.join(',');
      payload['stockArtDocuments'] = stockArtDocuments;
    } else if (Array.isArray(this.stockArtDocuments)) {
      const _document = _map(this.stockArtDocuments, (item) => {
        if (item && item._id) {
          return item._id;
        }

        return item;
      });

      this.stockArtDocuments = uniq(_document);
      payload['stockArtDocuments'] = this.stockArtDocuments.join(',');
    }
    let EPstockArtDocuments = null;
    if (EPstockArtDocs != false && Array.isArray(EPstockArtDocs)) {
      EPstockArtDocs = uniq(EPstockArtDocs);
      EPstockArtDocuments = EPstockArtDocs.join(',');
      payload['EPstockArtDocuments'] = EPstockArtDocuments;
    } else if (Array.isArray(this.EPstockArtDocuments)) {
      this.EPstockArtDocuments = uniq(this.EPstockArtDocuments);
      payload['EPstockArtDocuments'] = this.EPstockArtDocuments.join(',');
    }
    let EPTreatmentDocuments = null;
    if (EPTreatmentDocs != false && Array.isArray(EPTreatmentDocs)) {
      EPTreatmentDocs = uniq(EPTreatmentDocs);
      EPTreatmentDocuments = EPTreatmentDocs.join(',');
      payload['EPTreatmentDocuments'] = EPTreatmentDocuments;
    } else if (Array.isArray(this.EPTreatmentDocuments)) {
      this.EPTreatmentDocuments = uniq(this.EPTreatmentDocuments);
      payload['EPTreatmentDocuments'] = this.EPTreatmentDocuments.join(',');
    }

    this.treatmentService.createSimpleTreatment(payload).subscribe((res1) => {
      this.simpleTreatmentDocuments = [];
      this.simpleTreatmentDocuments = uniq(res1.treatmentDocuments);
      this.stockArtDocuments = [];
      this.stockArtDocuments = uniq(res1.stockArtDocuments);
      this.EPstockArtDocuments = [];
      this.EPstockArtDocuments = uniq(res1.EPstockArtDocuments);
      this.EPTreatmentDocuments = [];
      this.EPTreatmentDocuments = uniq(res1.EPTreatmentDocuments);
      this.simpleTreatmentDocumentsUpdated.emit(this.simpleTreatmentDocuments);
      this.EPTreatmentDocumentsUpdated.emit(this.EPTreatmentDocuments);
      this.stockArtDocumentsUpdated.emit(this.stockArtDocuments);
      this.EPstockArtDocumentsUpdated.emit(this.EPstockArtDocuments);
      this.refreshTotalCost.emit(this.totalTreatmentValue);
      if (this.totalTreatmentValue != 0) {
        this.validateSimple = true;
      } else {
        this.validateSimple = false;
      }
      this.sendValidate();
    });
  }

  onListLength(l) {
    if (l > 0) {
      this.isSimpleTreatmentCost = false;
      this.selectedTab = 1;
    } else {
      this.isSimpleTreatmentCost = true;
      this.selectedTab = 0;
    }
  }

  onValidateList(v) {
    this.validateList = v;
    this.sendValidate();
  }

  resetData() {
    this.treatment = new Treatment();
    this.isAdult = null;
    this.teeth = [];
  }

  displayAdvancedModalTutorial(tab) {
    this.selectedTab = tab;
    if (this.selectedTab == 0) {
    } else if (this.selectedTab == 1) {
      if (this.dontShowAgainAvancedType || this.dontShowAgainAvancedType == undefined) {
        const ref2 = RootAppComponent.dialog.open(TreatmentChoiceAdvancedComponent, {
          width: '600px',
        });
        ref2.componentInstance.dontShowAgain.subscribe((res) => {
          this.dontShowAgainAvancedType = res;
          ref2.close();
        });
        ref2.componentInstance.closeModal.subscribe((data) => {
          ref2.close();
        });
        ref2.componentInstance.SimpleTreatment.subscribe((data) => {
          ref2.close();
          if (data == true) {
            this.selectedTab = 0;
          }
        });
      }
    }
  }

  onSelectTabChange(v) {
    if (v.index != this.selectedTab) {
      this.displayAdvancedModalTutorial(v.index);
    }
  }

  getStockArtDocumentsEvent(docs) {
    if (docs && docs.length > 0) {
      const EPstockArtDocuments = this.EPstockArtDocuments;
      docs = uniq(docs);
      if (this.EPstockArtDocuments && this.EPstockArtDocuments.length > 0) {
        this.EPstockArtDocuments = uniq(docs.concat(EPstockArtDocuments));
        this.refreshStockArt.emit(true);
      } else {
        this.EPstockArtDocuments = uniq(docs.concat(EPstockArtDocuments));
      }
    }
  }

  checkStockArtDocuments(view = 'user') {
    const ref = RootAppComponent.dialog.open(TreatmentDocumentImportOverviewComponent, {
      data: {
        merchantID: this.merchantID,
        readonly: false,
        isGallery: true,
        loadUserDocument: true,
        loadICDocument: true,
        loadPatientDocument: true,
        patientID: this.patientID,
        selectedView: view,
        documents: this.EPstockArtDocuments,
        userDocumentType: ['SRVSTC', 'IGVIDEO'],
        ICDocumentType: ['SRVSTC', 'IGVIDEO'],
        displayUserDocumentType: false,
        displayICDocumentType: false,
        displayPatientDocumentType: false,
        isICSideMenu: false,
        isUserSideMenu: true,
        displayUserDocuments: true,
        displayICDocuments: true,
        buttonLabel: 'Images',
        displayPatientDocuments: true,
        patientDocumentType: ['MEDR_V', 'MEDR_X', 'MEDR_I', 'IGVIDEO'],
        natureICDocument: ['document', 'image', 'video'],
        natureUserDocument: ['image', 'video'],
        naturePatientDocument: ['image', 'video'],
        selectAnotherPatient: false,
      },
      width: '90vw',
    });
    ref.backdropClick().subscribe((data) => {
      ref.close();
    });
    ref.componentInstance.closeModal.subscribe((data) => {
      ref.close();
    });
    ref.componentInstance.getDocuments.subscribe((res) => {
      if (res && res.length > 0) {
        this.EPstockArtDocuments = JSON.parse(JSON.stringify(uniq(res)));
      }
      ref.close();
    });
  }

  checkTreatmentDocuments() {
    const ref = RootAppComponent.dialog.open(TreatmentDocumentImportOverviewComponent, {
      data: {
        merchantID: this.merchantID,
        readonly: false,
        isGallery: true,
        title: 'Practice, Patient Documents',
        loadUserDocument: true,
        loadICDocument: true,
        loadPatientDocument: true,
        patientID: this.patientID,
        selectedView: 'user',
        documents: this.EPTreatmentDocuments,
        userDocumentType: 'TRP',
        ICDocumentType: 'TRP',
        displayUserDocumentType: false,
        displayICDocumentType: false,
        isICSideMenu: false,
        isUserSideMenu: false,
        displayUserDocuments: true,
        displayICDocuments: false,
        buttonLabel: 'Documents',
        displayPatientDocuments: true,
        patientDocumentType: ['MEDR_R', 'MEDR_C', 'MEDR_T'],
        natureICDocument: ['document'],
        natureUserDocument: ['document'],
        naturePatientDocument: ['document'],

        selectAnotherPatient: false,
      },
      width: '90vw',
    });
    ref.backdropClick().subscribe((data) => {
      ref.close();
    });
    ref.componentInstance.closeModal.subscribe((data) => {
      ref.close();
    });
    ref.componentInstance.getDocuments.subscribe((res) => {
      if (res && res.length > 0) {
        this.EPTreatmentDocuments = JSON.parse(JSON.stringify(uniq(res)));
      }
      ref.close();
    });
  }

  getselectedTreatmentDocumentIDs(data) {
    this.createSimpleTreatment(false, false, false, data);
  }

  getselectedStockArtDocumentIDs(data) {
    this.createSimpleTreatment(false, false, data, false);
  }

  viewSavedTreatment(template) {
    if (template && template.id && template.options) {
      const ref = RootAppComponent.dialog.open(InvitationTemplateMergerComponent, {
        data: {
          templateID: template.id,
          selectDocuments: template.options.selectDocuments,
          selectImages: template.options.selectImages,
          selectTreatmentPlan: template.options.selectTreatmentPlan,
          selectTags: template.options.selectTags,
          selectTreatmentValue: template.options.selectTreatmentValue,
          selectMessage: template.options.selectMessage,
          selectDiscount: template.options.selectDiscount,
          selectHealthHistory: template.options.selectHealthHistory,
          selectGoogleReview: template.options.selectGoogleReview,
          displaySelected: true,
          viewOnly: true,
        },
        width: '1200px',
      });
      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    }
  }

  clearTreatment(template) {
    if (template && template.id) {
      let confirm = new ConfirmDialog(
        'delete',
        'Clear Invitation Template',
        'Are you sure you want to remove this template with all it content from this invitation?',
        'No',
        'Yes'
      );
      if (template && template.options && template.options.selectTreatmentValue == true && template.treatmentValue) {
        confirm = new ConfirmDialog(
          'delete',
          'Clear Invitation Template',
          'By clearing this Template, an amount of <strong>' +
            this.customCurrencyPipe.transform(template.treatmentValue) +
            '</strong> will be reduced from your treatment value. <br> <br>' +
            'Are you sure you want to remove this template with all it content from this invitation?',
          'No',
          'Yes'
        );
      }

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
        width: '500px',
      });
      ref.componentInstance.onConfirm.subscribe((res) => {
        if (res == true) {
          this.clearTemplate.emit(template.id);
          ref.close();
        } else {
          ref.close();
        }
      });
    }
  }

  launchAsModal(e, m) {
    // trigger component launch as modal, default to selected video
    const ref2 = RootAppComponent.dialog.open(VideoTutorialsModalComponent, {
      data: {
        isModal: true,
        mainCategory: m,
        subcategory: e,
        defaultSelectedTopic: e,
      },
      width: '800px',
    });
    ref2.componentInstance.closeModal.subscribe((res) => {
      if (res == true) {
        ref2.close();
      }
    });
  }
}
