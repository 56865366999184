import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';

@Component({
  selector: 'app-message-list-overview',
  templateUrl: './message-list-overview.component.html',
  styleUrls: ['./message-list-overview.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('*', style({ opacity: 0 }), { optional: true }),
        query(
          '*',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('*', style({ opacity: 1 }), { optional: true }),
        query(
          '*',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class MessageListOverviewComponent implements OnInit {
  isPromoterOrAdmin = false;

  merchantID = 'none';
  dateFrom;
  dateTo;

  isDisplayMerchant = true;

  searchApplied = false;

  sendNull = true;
  selectedRange = 'days90';

  templateTypeID = 'Personal';

  type;
  title = 'Personal Messages';

  typesGlobal = [
    {
      code: 'Personal',
      value: 'Personal Message',
      title: 'Personal Messages',
    },
    {
      code: 'TreatmentInv',
      value: 'Treatment Invitation',
      title: 'Treatment Invitation Messages',
    },
    {
      code: 'Application',
      value: 'Application',
      title: 'Application Messages',
    },
    {
      code: 'Settlement',
      value: 'Settlement',
      title: 'Settlement Messages',
    },
    {
      code: 'Medicalhistory',
      value: 'Medical history',
      title: 'Medical history Messages',
    },

    {
      code: 'System',
      value: 'System',
      title: 'System Messages',
    },
    {
      code: 'Payment',
      value: 'Payment',
      title: 'Payment Messages',
    },
    {
      code: 'Generic',
      value: 'Generic',
      title: 'Generic Messages',
    },

    {
      code: 'Appointment',
      value: 'Appointment',
      title: 'Appointment Messages',
    },
    {
      code: 'newSignup',
      value: 'New Signup',
      title: 'New Signup Messages',
    },
    {
      code: 'Investment',
      value: 'Investment',
      title: 'Investment Messages',
    },
    {
      code: 'Others',
      value: 'Others',
      title: 'Other Messages',
    },
  ];

  types = [
    {
      code: 'Personal',
      value: 'Personal Message',
      title: 'Personal Messages',
    },
    {
      code: 'TreatmentInv',
      value: 'Treatment Invitation',
      title: 'Treatment Invitation Messages',
    },
    {
      code: 'Settlement',
      value: 'Settlement',
      title: 'Settlement Messages',
    },
    {
      code: 'Medicalhistory',
      value: 'Medical history',
      title: 'Medical history Messages',
    },

    {
      code: 'Generic',
      value: 'Generic',
      title: 'Generic Messages',
    },

    {
      code: 'Appointment',
      value: 'Appointment',
      title: 'Appointment Messages',
    },
    {
      code: 'Others',
      value: 'Others',
      title: 'Other Messages',
    },
  ];
  constructor(private authenticationService: AuthenticationService, private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit() {
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;

      if (this.isPromoterOrAdmin) {
        this.type = this.typesGlobal[0];
      } else {
        this.type = this.types[0];
      }
    });
  }
  choose() {
    if (this.type) {
      if (this.type.code) {
        this.templateTypeID = this.type.code;
      }
      if (this.type.title) {
        this.title = this.type.title;
      }
    } else {
      this.templateTypeID = null;
      this.title = 'All messages';
    }
  }
  applySearch(result) {
    this.merchantID = result.merchantID;
    this.dateFrom = result.dateFrom;
    this.dateTo = result.dateTo;

    this.searchApplied = true;
    this.changeDetectorRef.detectChanges();
  }
}
