/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./settlements-payments-report-merchant-fees.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../../shared/pipes/custom-currency.pipe";
import * as i4 from "../../../../../../shared/pipes/invert-number/invert-number.pipe";
import * as i5 from "../../../../../../shared/components/data-table/data-table.component.ngfactory";
import * as i6 from "../../../../../../shared/components/data-table/data-table.component";
import * as i7 from "./settlements-payments-report-merchant-fees.component";
import * as i8 from "../../services/settlement-payments-report.service";
import * as i9 from "@angular/material/dialog";
var styles_SettlementsPaymentsReportMerchantFeesComponent = [i0.styles];
var RenderType_SettlementsPaymentsReportMerchantFeesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SettlementsPaymentsReportMerchantFeesComponent, data: {} });
export { RenderType_SettlementsPaymentsReportMerchantFeesComponent as RenderType_SettlementsPaymentsReportMerchantFeesComponent };
function View_SettlementsPaymentsReportMerchantFeesComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["style", "text-align: right"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵppd(2, 1), i1.ɵppd(3, 1)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent, 0), i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent, 1), _v.parent.context.$implicit.treatmentValue)))); _ck(_v, 1, 0, currVal_0); }); }
function View_SettlementsPaymentsReportMerchantFeesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_SettlementsPaymentsReportMerchantFeesComponent_2)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = (_v.context.$implicit.treatmentValue > 0); _ck(_v, 1, 0, currVal_0); }, null); }
function View_SettlementsPaymentsReportMerchantFeesComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["style", "text-align: right"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵppd(2, 1), i1.ɵppd(3, 1)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent, 0), i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent, 1), _v.parent.context.$implicit.fee)))); _ck(_v, 1, 0, currVal_0); }); }
function View_SettlementsPaymentsReportMerchantFeesComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_SettlementsPaymentsReportMerchantFeesComponent_4)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = (_v.context.$implicit.fee !== 0); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_SettlementsPaymentsReportMerchantFeesComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i3.CustomCurrencyPipe, [i2.CurrencyPipe]), i1.ɵpid(0, i4.InvertNumberPipe, []), i1.ɵqud(671088640, 1, { treatmentValue: 0 }), i1.ɵqud(671088640, 2, { fee: 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 4, "ipv-data-table", [], null, null, null, i5.View_DataTableComponent_0, i5.RenderType_DataTableComponent)), i1.ɵdid(5, 4833280, null, 0, i6.DataTableComponent, [], { table: [0, "table"], rename: [1, "rename"], customComponents: [2, "customComponents"], paginate: [3, "paginate"], formatHeaders: [4, "formatHeaders"] }, null), i1.ɵpod(6, { treatmentValue: 0 }), i1.ɵpod(7, { treatmentValue: 0, fee: 1 }), i1.ɵpod(8, { treatmentValue: 0, fee: 1 }), (_l()(), i1.ɵand(0, [[1, 2], ["treatmentValue", 2]], null, 0, null, View_SettlementsPaymentsReportMerchantFeesComponent_1)), (_l()(), i1.ɵand(0, [[2, 2], ["fee", 2]], null, 0, null, View_SettlementsPaymentsReportMerchantFeesComponent_3))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.reportTable.fullTable; var currVal_1 = _ck(_v, 6, 0, "Treatment Value"); var currVal_2 = _ck(_v, 7, 0, i1.ɵnov(_v, 9), i1.ɵnov(_v, 10)); var currVal_3 = false; var currVal_4 = _ck(_v, 8, 0, "right", "right"); _ck(_v, 5, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_SettlementsPaymentsReportMerchantFeesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ipv-settlements-payments-report-merchant-fees", [], null, null, null, View_SettlementsPaymentsReportMerchantFeesComponent_0, RenderType_SettlementsPaymentsReportMerchantFeesComponent)), i1.ɵdid(1, 770048, null, 0, i7.SettlementsPaymentsReportMerchantFeesComponent, [i8.SettlementPaymentsReportService, i9.MatDialog], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SettlementsPaymentsReportMerchantFeesComponentNgFactory = i1.ɵccf("ipv-settlements-payments-report-merchant-fees", i7.SettlementsPaymentsReportMerchantFeesComponent, View_SettlementsPaymentsReportMerchantFeesComponent_Host_0, { merchantFeeReport: "merchantFeeReport", merchant: "merchant" }, {}, []);
export { SettlementsPaymentsReportMerchantFeesComponentNgFactory as SettlementsPaymentsReportMerchantFeesComponentNgFactory };
