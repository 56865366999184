import { UtilSchema } from '../../../../../../../../../../../../shared/types/utils/utils.type';

export const PATIENT_PERSONALIZATION_SCHEMA: UtilSchema[] = [
  {
    code: 'PatientSalutation',
    label: 'Salutation',
    field: 'Salutation',
    group: '1',
  },
  {
    code: 'PatientFirstName',
    label: 'FirstName',
    field: 'FirstName',
    group: '1',
  },
  {
    code: 'PatientMiddleName',
    label: 'Middle',
    field: 'MiddleName',
    group: '1',
  },
  {
    code: 'PatientLastName',
    label: 'LastName',
    field: 'Name',
    group: '1',
  },
  {
    code: 'PatientMobileNumber',
    label: 'Mobile',
    field: 'mobiles.Number',
    group: '2',
    icon: 'fas fa-mobile',
    isPhone: true,
    isLandLine: false,
  },
  {
    code: 'PatientWorkPhoneNumber',
    label: 'Phone',
    field: 'phones.Number',
    group: '2',
    icon: 'fas fa-phone',
    isPhone: true,
    isLandLine: true,
  },
  {
    code: 'PatientEmail',
    label: 'Email',
    field: 'emails.Email',
    group: '2',
    icon: 'fas fa-at',
  },
  {
    code: 'PatientAddress',
    label: 'Address',
    field: 'addresses.Calculated',
    group: '2',
    icon: 'fas fa-map-marker-alt',
  },
];
