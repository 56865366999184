import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { Settings } from '../../../shared/types/settings';

@Component({
  selector: 'app-drug-list-overview',
  templateUrl: './drug-list-overview.component.html',
  styleUrls: ['./drug-list-overview.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('*', style({ opacity: 0 }), { optional: true }),
        query(
          '*',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('*', style({ opacity: 1 }), { optional: true }),
        query(
          '*',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class DrugListOverviewComponent implements OnInit {
  @Input()
  pageSize = Settings.global['listPageSize'] || 10;

  isAdminOrPromoter = false;

  dateFrom;
  dateTo;
  _dateFrom;
  _dateTo;
  selectedRange = 'all';
  isDisplaySlider = false;

  hideMerchantSelect = false;
  searchType = 'Promoter';

  constructor(private authenticationService: AuthenticationService) {}

  ngOnInit() {
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isAdminOrPromoter = res;
    });
  }

  displaySlideToggle(e) {
    this.isDisplaySlider = e;
  }

  applySearch(result) {
    this.dateFrom = result.dateFrom;
    this.dateTo = result.dateTo;
  }

  getDateFrom(d) {
    this._dateFrom = d;
  }

  getDateTo(d) {
    this._dateTo = d;
  }
}
