import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import * as moment from 'moment';
import { filter, map, mergeMap } from 'rxjs/operators';
import { AuthenticationService } from '../../../../common/core/authentication/shared/authentication.service';
import { ProductService } from '../../../../common/feature/product/shared/product.service';
import { TreatmentDocumentImportOverviewComponent } from '../../../../common/feature/treatment/treatment-document-import-overview/treatment-document-import-overview.component';
import { RootAppComponent } from '../../../../common/shared/components/root-component/root-component.component';
import { HeaderService } from '../../../../common/shared/services/header.service';
import { UtilsService } from '../../../../common/shared/services/utils.service';
import { ModuleOption, Settings } from '../../../../common/shared/types/settings';
import { UtilsClass } from '../../../../common/shared/types/utils/utils.class';

import { OperatorLookupComponent } from '../../../../common/feature/operator/operator-lookup/operator-lookup.component';
import { TreatmentInformedConsentCreateComponent } from '../../../../common/feature/treatment/treatment-informed-consent-create/treatment-informed-consent-create.component';
import { ConfirmDialogSingleButtonComponent } from '../../../../common/shared/components/confirm-dialog-single-button/confirm-dialog-single-button.component';
import { ConfirmDialogSingleButton } from '../../../../common/shared/types/confirm-dialog-single-button';

import { MatDialog } from '@angular/material/dialog';
import { AppStateService } from '../../../../common/core/app-state/app-state.service';
import { BetaInfoComponent } from '../../../../common/core/helper/beta-info/beta-info.component';
import { ModuleViewComponent } from '../../../../common/core/module/module-view/module-view.component';
import { ModuleService } from '../../../../common/core/module/shared/module.service';
import { ActionLogService } from '../../../../common/feature/action-log/shared/action-log.service';
import { DefaultMessageLookupComponent } from '../../../../common/feature/default-message/default-message-lookup/default-message-lookup.component';
import { InvitationTemplateLookupComponent } from '../../../../common/feature/invitation-template/invitation-template-lookup/invitation-template-lookup.component';
import { InvitationLookupComponent } from '../../../../common/feature/invitation/invitation-lookup/invitation-lookup.component';
import { SmsConversationComponent } from '../../../../common/feature/message/sms-conversation/sms-conversation.component';
import { SmsPromoterComponent } from '../../../../common/feature/message/sms-promoter/sms-promoter.component';
import { NotesModal, NotesModalConfig } from '../../../../common/feature/notes/modals/notes-modal/notes.modal';
import { FinanceCalculatorComponent } from '../../../../common/feature/product/finance-calculator/finance-calculator.component';
import { TreatmentProductViewComponent } from '../../../../common/feature/treatment/treatment-product-view/treatment-product-view.component';
import { TranslateService } from '@ngx-translate/core';
import { ContactLookupComponent } from '../../../../common/feature/contact/contact-lookup/contact-lookup.component';
import { PatientPersonalSMSComponent } from '../../../../common/feature/message/patient-personal-sms/patient-personal-sms.component';

@Component({
  selector: 'app-merchant-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderMerchantComponent implements OnInit, OnDestroy {
  header = 'Merchant Dashboard';

  mainModuleLoaded = false;
  isModuleTreatmentPlanActive = Settings.global['isModuleTreatmentPlanActive'];
  isModuleFacilityActive = Settings.global['isModuleFacilityActive'];
  isModuleMembershipActive = Settings.global['isModuleMembershipActive'];

  context = Settings.global['context'];
  isModulePatientPortalAccessActive = Settings.global['isModulePatientPortalAccessActive'];
  isModuleFeeFundingActive = Settings.global['isModuleFeeFundingActive'];
  isModuleEmergercyActive = Settings.global['isModuleEmergercyActive'];
  isModuleTutorialsActive = Settings.global['isModuleTutorialsActive'];
  isModuleExtendedProductsActive = Settings.global['isModuleExtendedProductsActive'];
  isModulePatientSMSActive = Settings.global['isModulePatientSMSActive'];
  isModuleExperimentalFeaturesActive = Settings.global['isModuleExperimentalFeaturesActive'];
  isModuleNoteFeaturesActive = Settings.global['isModuleNoteFeaturesActive'];
  isModulePatientHistoryActive = Settings.global['isModulePatientHistoryActive'];
  isModuleTreatmentTemplateActive = Settings.global['isModuleTreatmentTemplateActive'];
  isModuleSettlementsActive = Settings.global['isModuleSettlementsActive'];
  isModulePatientsActive = Settings.global['isModulePatientsActive'];

  isModuleStockArtActive = Settings.global['isModuleStockArtActive'];

  isModuleDocumentLinkActive = Settings.global['isModuleDocumentLinkActive'];
  isModuleTreatmentGroupActive = Settings.global['isModuleTreatmentGroupActive'];

  notifications;
  appointments;

  merchant: any = {};
  role = null;
  isPromoterOrAdmin = false;
  url;
  operator;

  modules: any = {};

  loadingStockArt;
  EPstockArtDocuments;
  merchantID = localStorage.getItem('selectedMerchant');

  isGlobal = false;

  @Output()
  isDrag = new EventEmitter();

  @Output()
  selectedType = new EventEmitter();

  isNoteMenuActive = true;

  routerPath;
  hasDialogInConstrcutor = false;

  util = new UtilsClass();

  numOfTasks;
  sessionType;
  toolTips = {};
  changeRouteEvent = new EventEmitter();

  appState$ = this.appStateService.getAppState$();

  displayInfoToolTip = true;

  constructor(
    public utilService: UtilsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private productService: ProductService,
    private moduleService: ModuleService,
    private actionLogService: ActionLogService,
    private appStateService: AppStateService,
    private dialog: MatDialog,
    private translateService: TranslateService
  ) {
    AuthenticationService.setTitle.subscribe((r) => {
      if (r) {
        this.header = r;
      }
    });
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        mergeMap((route) => route.data)
      )
      .subscribe((event) => {
        if (event['title']) {
          this.header = this.translateService.instant(event['title']);
          document.title = this.translateService.instant(event['title']);
        } else {
          document.title = this.translateService.instant(Settings.global['pageTitle']);
        }

        if (event['openDialog']) {
          this.routerPath = event['openDialog'];
          this.hasDialogInConstrcutor = true;
        }
      });
    this.authenticationService.getCurrentUser().subscribe((res) => {
      if (res && res.success) {
        this.operator = res;
        this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
          this.isPromoterOrAdmin = res;
          this.loadTasks();
          this.changeRouteEvent.emit(true);
        });
      }
    });
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      mergeMap((route) => route.data)
    );

    RootAppComponent.setCurrentAccess.subscribe((res) => {
      if (res) {
        this.setAccess(res);
      }
    });
  }

  ngOnInit() {
    AuthenticationService.getDisplayInfoToolTip.subscribe((res) => {
      this.displayInfoToolTip = res;
    });

    AuthenticationService.updateToolTip.subscribe((res) => {
      if (res === true) {
        this.utilService.getToolTip().subscribe((r) => {
          if (r) {
            this.toolTips = r;
          }
        });
      }
    });

    if (localStorage.getItem('displayInfoToolTip') === 'false') {
      this.displayInfoToolTip = false;
    }

    this.utilService.getToolTip().subscribe((r) => {
      if (r) {
        this.toolTips = r;
      }
    });
    this.authenticationService.getSessionType().subscribe((r) => {
      this.sessionType = r;

      UtilsClass.updateSideMenuOneModule.subscribe((res) => {
        this.updateOneModulEvent(res);
      });

      UtilsClass.updateSideMenuAllModule.subscribe((res) => {
        this.updateAllModulEvent(res);
      });

      RootAppComponent.updateSideMenuAllModule.subscribe((res) => {
        this.updateAllModulEvent(res);
      });

      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isPromoterOrAdmin = res;

        this.utilService.getCurrentAccess().subscribe((access) => {
          if (access) {
            this.setAccess(access);
          }
          this.authenticationService.getCurrentUser().subscribe((res) => {
            if (res && res.success && res.data['Role'] && res.data['Role']) {
              this.role = res.data['Role'];
            }

            // if (res && res.success == true) {
            //   this.operator = res;

            //   this.loadTasks();
            // }
          });

          if (this.isPromoterOrAdmin == true) {
            this.isGlobal = true;
          }

          HeaderService.getPromoterViewAsObject().subscribe((obj) => {
            if (obj && obj.merchantID && obj.promoterViewAs == 'merchant') {
              this.merchantID = obj.merchantID;
              this.loadTasks();
            } else if (obj && obj.promoterViewAs == 'promoter') {
              this.merchantID = null;
              this.loadTasks();
            }
          });
          this.getHeaderName();
        });
      });
      setTimeout(() => {
        this.moduleService
          .getModuleListHidden({ fields: 'Code,Status.Code,Icon,Label' }, this.sessionType)
          .subscribe((res) => {
            if (res && res.length > 0) {
              this.updateAllModulEvent(res);
            }

            this.mainModuleLoaded = true;
          });
      }, 1000);
    });
  }

  ngOnDestroy() {
    // required for untilDestroyed();
  }

  setAccess(res) {
    if (res) {
      this.isModulePatientSMSActive = res['isModulePatientSMSActive'];
      this.isModuleTreatmentPlanActive = res['isModuleTreatmentPlanActive'];
      this.isModuleFacilityActive = res['isModuleFacilityActive'];
      this.isModuleMembershipActive = res['isModuleMembershipActive'];
      this.isModulePatientPortalAccessActive = res['isModulePatientPortalAccessActive'];

      this.isModuleFeeFundingActive = res['isModuleFeeFundingActive'];
      this.isModuleEmergercyActive = res['isModuleEmergercyActive'];
      this.isModuleTutorialsActive = res['isModuleTutorialsActive'];
      this.isModuleExtendedProductsActive = res['isModuleExtendedProductsActive'];
      this.isModuleTreatmentGroupActive = res['isModuleTreatmentGroupActive'];
      this.isModulePatientsActive = res['isModulePatientsActive'];

      this.isModuleStockArtActive = res['isModuleStockArtActive'];
      this.isModuleExperimentalFeaturesActive = res['isModuleExperimentalFeaturesActive'];
      this.isModuleNoteFeaturesActive = res['isModuleNoteFeaturesActive'];
      this.isModulePatientHistoryActive = res['isModulePatientHistoryActive'];
      this.isModuleTreatmentTemplateActive = res['isModuleTreatmentTemplateActive'];
      this.isModuleSettlementsActive = res['isModuleSettlementsActive'];

      this.isModuleDocumentLinkActive = res['isModuleDocumentLinkActive'];
    }
  }

  getHeaderName() {
    this.authenticationService.getCurrentUser().subscribe(
      (res: any) => {
        this.merchant.calculatedName = res.data.CalculatedName;
      },
      (err: any) => {}
    );
  }

  documentLinkLookup() {
    this.router.navigate([
      '/merchant',
      {
        outlets: {
          page: ['document-link-list'],
        },
      },
    ]);
  }

  openCreateGlobalSMS() {
    if (this.sessionType === 'admin' || this.sessionType === 'promoter') {
      const ref2 = RootAppComponent.dialog.open(SmsPromoterComponent, {
        panelClass: 'noCard',
        width: '500px',
      });
      ref2.componentInstance.close.subscribe((data) => {
        ref2.close();
      });

      ref2.componentInstance.getResult.subscribe((data) => {
        if (data) {
          this.router.navigate([
            '/merchant',
            { outlets: { page: ['sms-conversation-promoter', encodeURIComponent(JSON.stringify(data))] } },
          ]);
        }

        ref2.close();
      });
    } else {

      const ref2 = RootAppComponent.dialog.open(PatientPersonalSMSComponent, {
        data: {
        },
        width: '700px',
      });

      ref2.componentInstance.confirmation.subscribe((confirmation) => {
        if (confirmation == true) {
          ref2.close()
          this.openCreateGlobalSMSPersonal();
        }
      });
      ref2.componentInstance.confirmationSecond.subscribe((information) => {
        if (information == true) {
          ref2.close()
          this.router.navigate(['/merchant', { outlets: { page: ['sms-conversation'] } }]);
        }
      });

    }
  }


  openCreateGlobalSMSPersonal() {
    this.router.navigate(['/merchant', { outlets: { page: ['sms-conversation-personal','merchantpersonal'] } }]);
  }

  openCreateGlobalSMSContact() {
    if (this.sessionType === 'admin' || this.sessionType === 'promoter') {
      const ref2 = RootAppComponent.dialog.open(SmsPromoterComponent, {
        data: {
          isContactStaff: true,
        },

        panelClass: 'noCard',
        width: '500px',
      });
      ref2.componentInstance.close.subscribe((data) => {
        ref2.close();
      });

      ref2.componentInstance.getResult.subscribe((data) => {
        if (data) {
          this.router.navigate([
            '/merchant',
            { outlets: { page: ['sms-conversation-promoter-type', 'staff', encodeURIComponent(JSON.stringify(data))] } },
          ]);
        }

        ref2.close();
      });
    } else {

      this.router.navigate(['/merchant', { outlets: { page: ['sms-conversation-type', 'staff'] } }]);
    }
  }

  openNotes() {
    RootAppComponent.dialog.open<NotesModal, NotesModalConfig>(NotesModal, {
      data: {
        parentRoute: 'merchant',
      },
    });
  }

  updateAllModulEvent(res) {
    if (res && res.length > 0) {
      for (let i = 0; i < res.length; i++) {
        const item = res[i];
        this.updateOneModulEvent(item);
      }
    }
  }

  updateOneModulEvent(item) {
    if (item && item.Code) {
      this.modules['isModule' + item.Code + 'Active'] = {
        isActive: false,
        isBeta: false,
        icon: null,
        label: null,
      };

      if (item['Status.Code'] === 'EnableForAll' || item['Status.Code'] === 'Beta') {
        this.modules['isModule' + item.Code + 'Active'].isActive = true;

        if (item['Status.Code'] === 'Beta') {
          this.modules['isModule' + item.Code + 'Active'].isBeta = true;
        }
      }

      if (item['Label']) {
        this.modules['isModule' + item.Code + 'Active'].label = item['Label'];
      }

      if (item['Icon']) {
        this.modules['isModule' + item.Code + 'Active'].icon = item['Icon'];
      }
    }
  }

  treatmentGalleryLookup() {
    // gallery

    const ref = RootAppComponent.dialog.open(TreatmentDocumentImportOverviewComponent, {
      data: {
        merchantID: this.merchantID,
        isDisplayMerchant: true,
        readonly: true,
        loadUserDocument: true,
        loadICDocument: true,
        selectedView: 'system',
        userDocumentType: ['SRVSTC', 'IGVIDEO'],
        ICDocumentType: ['SRVSTC', 'IGVIDEO'],
        displayUserDocumentType: true,
        displayICDocumentType: true,
        isICSideMenu: true,
        isUserSideMenu: true,
        isGallery: true,
        nature: ['document', 'image', 'video'],
        natureICDocument: ['document', 'image', 'video'],
        natureUserDocument: ['document', 'image', 'video'],
        naturePatientDocument: ['document', 'image', 'video'],
      },
      width: '90vw',
    });

    const sub = ref.componentInstance.closeModal.subscribe((data) => {
      ref.close();
    });
  }

  newPatientInv() {
    this.router.navigate(['/merchant', { outlets: { page: ['new-invitation'] } }]);
  }

  openAppointmentDialog() {
    // const ref = RootAppComponent.dialog.open(NotesModal, {
    //   width: '70%',
    //   height: '70%'
    // });
    // const sub = ref.componentInstance.closeModal.subscribe((data) => {
    //   ref.close();
    // });
  }

  viewedNotification(event) {
    event.target.classList.remove('btn-inverse-accent'); // To Remove
  }

  openCalcDialog() {
    const payload = {};
    this.productService.getMerchantProductGroup(payload, this.isPromoterOrAdmin).subscribe((res) => {
      if (res.length <= 0) {
        const confirmDialog = new ConfirmDialogSingleButton(
          'warning',
          'No Product Licenses Found',
          'Please contact Smile Right for more information',
          'Okay'
        );

        const ref = RootAppComponent.dialog.open(ConfirmDialogSingleButtonComponent, {
          data: confirmDialog,
          width: '650px',
        });

        ref.componentInstance.onConfirm.subscribe((res) => {
          ref.close();
        });
      } else {
        const ref = RootAppComponent.dialog.open(FinanceCalculatorComponent, {
          width: '1000px',
          panelClass: 'noCard',
          position: {
            top: '40px',
          },
        });
        ref.componentInstance.close.subscribe((res) => {
          if (res == true) {
            ref.close();
          }
        });
      }
    });
  }

  // openTutorial() {
  //   // this.tutorial.startTour();
  //   let ref = RootAppComponent.dialog.open(HelpTopicsContentsComponent, {
  //     data: this.tutorialHeader,
  //     width: "800px",
  //     panelClass: 'noCard',
  //   })

  //   ref.componentInstance.close.subscribe(res => {
  //     ref.close();
  //   })

  // }

  contactLookup() {
    const ref = RootAppComponent.dialog.open(ContactLookupComponent, {
      data: {
        onlyMyAccount: true,
        displayChatButton: true,
        viewOnClick:true,
        useStrict: true,
      },
      width: '650px',
    });
    ref.componentInstance.closeModal.subscribe((data) => {
      ref.close();
    });
    ref.componentInstance.contactChat.subscribe((data) => {
      if (data && data.cardID) {
        const ref2 = RootAppComponent.dialog.open(SmsConversationComponent, {
          data: {
            cardID: data.cardID,
            isContactStaff: true,
            singleChat: true,
          },
          panelClass: 'bigger-screen',
          width: '70%',
          height: '95vh',
        });

        ref2.backdropClick().subscribe((r) => {});
        const sub = ref2.componentInstance.close.subscribe((data) => {
          ref2.close();
        });
      }
    });
  }
  patientLookup() {
    const ref = RootAppComponent.dialog.open(InvitationLookupComponent, {
      data: {
        viewOnClick: true,
        isModal: true,
        useStrict: true,
        isHeader: true,
        displayMerchantList: true,
        displayFinanceOffer: true,
      },
      width: '600px',
    });

    ref.componentInstance.getResult.subscribe((res) => {
      if (res) {
        ref.close();

        this.router.navigate([
          '/merchant',
          {
            outlets: {
              page: ['customer-profile', res['ID']],
            },
          },
        ]);
      }
    });

    ref.componentInstance.patientChat.subscribe((res) => {
      if (res && res.cardID) {
        if (res.isPromoterOrAdmin == true) {
          const ref2 = RootAppComponent.dialog.open(SmsPromoterComponent, {
            data: {
              merchantID: res.merchantID,
              cardID: res.cardID,
              singleChat: true,
            },
            panelClass: 'noCard',
            width: '500px',
          });
          ref2.componentInstance.close.subscribe((data) => {
            ref2.close();
          });

          ref2.componentInstance.getResult.subscribe((data) => {
            if (data) {
              const ref = RootAppComponent.dialog.open(SmsConversationComponent, {
                data,
                panelClass: 'bigger-screen',
                width: '70%',
                height: '95vh',
              });

              ref.backdropClick().subscribe((r) => {});
              const sub = ref.componentInstance.close.subscribe((data) => {
                ref.close();
              });
            }

            ref2.close();
          });
        } else {
          const ref2 = RootAppComponent.dialog.open(SmsConversationComponent, {
            data: {
              merchantID: res.merchantID,
              cardID: res.cardID,
              singleChat: true,
            },
            panelClass: 'bigger-screen',
            width: '70%',
            height: '95vh',
          });
          ref2.backdropClick().subscribe((r) => {});

          const sub = ref2.componentInstance.close.subscribe((data) => {
            ref2.close();
          });
        }
      }
    });

    ref.componentInstance.detailedView.subscribe((res) => {
      ref.close();
    });
    ref.componentInstance.closeModal.subscribe((res) => {
      ref.close();
    });

    ref.backdropClick().subscribe((res) => {
      ref.close();
    });
  }

  openTemplateLookup() {
    const ref = RootAppComponent.dialog.open(InvitationTemplateLookupComponent, {
      data: {
        merchantID: this.merchantID,
        viewOnClick: true,
        isModal: true,
        isHeader: true,
      },
      panelClass: 'bigger-screen',
      width: '820px',
    });
    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  productLookup() {
    const ref = RootAppComponent.dialog.open(TreatmentInformedConsentCreateComponent, {
      data: {
        isModal: true,
        isGlobal: this.isGlobal,
      },
      width: '650px',
    });

    ref.componentInstance.getProductID.subscribe((productID) => {
      if (productID) {
        const ref1 = RootAppComponent.dialog.open(TreatmentProductViewComponent, {
          data: {
            productID,
            isGlobal: false,
          },
          width: '800px',
          panelClass: 'noCard',
        });

        ref1.componentInstance.close.subscribe((res) => {
          ref1.close();
        });
      }
    });

    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  operatorLookup() {
    const ref = RootAppComponent.dialog.open(OperatorLookupComponent, {
      data: {
        displayOnClick: false,
        isModal: true,
        isHeader: true,
      },
      width: '600px',
    });

    ref.componentInstance.getResult.subscribe((res) => {
      if (res && res['UltimateTableName']) {
        if (res['UltimateTableName'] === 'Merchant' || res['UltimateTableName'] === 'Promoter') {
          this.router.navigate([
            '/merchant',
            {
              outlets: {
                page: ['merchant-profile', res['Merchant_key'], 'staff', res['Card_key']],
              },
            },
          ]);
        } else if (
          res['UltimateTableName'] === 'Customer' ||
          res['UltimateTableName'] === 'Prospect' ||
          res['UltimateTableName'] === 'CustomersAndProspects'
        ) {
          this.router.navigate([
            '/merchant',
            {
              outlets: {
                page: ['customer-profile', res['Card_key']],
              },
            },
          ]);
        }
      }

      ref.close();
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }


  createSettlement()
  {
    AuthenticationService.newSettlement.emit({})
  }

  loadTasks() {
    if (this.operator) {
      let _dateFrom = moment('2020-03-10', 'YYYY-MM-DD HH:mm:ss');
      const _operatorCreateDate = moment(this.operator.data['DateTimeCreated'], 'YYYY-MM-DD HH:mm:ss');

      _dateFrom = _dateFrom.diff(_operatorCreateDate) > 0 ? _dateFrom : _operatorCreateDate;

      const payload = {
        dateFrom: this.util.EPdateFormat(_dateFrom.toISOString()),
        isInvitationActions: true,
        onlyUnread: true,
      };

      if (this.isPromoterOrAdmin == true) {
        payload['merchantID'] = this.merchantID;
      }

      this.actionLogService.getStatistics(payload, this.isPromoterOrAdmin, true).subscribe((res) => {
        if (res) {
          this.numOfTasks = res.global['Count(*)'];
        }
      });
    }
  }

  isBeta(code) {
    if (this.modules && this.modules[code] && this.modules[code].isBeta == true) {
      return true;
    }

    return false;
  }

  viewModule(code) {
    if (code) {
      const ref = RootAppComponent.dialog.open(ModuleViewComponent, {
        data: {
          moduleCode: code,
        },
        width: '1000px',
      });
      ref.componentInstance.close.subscribe((res) => {
        if (res) {
          ref.close();
        }
      });
    }
  }

  messageTemplate() {
    const ref2 = RootAppComponent.dialog.open(DefaultMessageLookupComponent, {
      data: {
        merchantID: this.merchantID,
        viewOnClick: true,
        messageMode: 'all',
        purposeCodeCreate: 'MerInv',
        createNew: true,
        isHeader: true,
      },
      panelClass: 'max-width-panel-1200',
      width: '90vw',
    });

    ref2.componentInstance.close.subscribe((res) => {
      ref2.close();
    });
  }

  createNote(prefillNoteAction: string) {
    this.dialog.open<NotesModal, NotesModalConfig>(NotesModal, {
      data: {
        parentRoute: 'merchant',
        prefillNoteAction,
      },
    });
  }

  openModule(parameters: { code: ModuleOption; isActive?: boolean; isBeta?: boolean; showModule?: boolean }) {
    if (parameters.isBeta && parameters.isActive) {
      this.ShowBeta(parameters.code);
    } else if (!parameters.isBeta && parameters.isActive) {
    } else if (parameters.showModule) {
      this.viewModule(parameters.code);
    }
  }



  displayTooltip(isActive: boolean, text = '') {
    return isActive ? text : 'You do not have access to this Module';
  }

  isNavigationEnabled(enabled: boolean, route: any) {
    return enabled ? route : null;
  }

  ShowBeta(b) {
    if (b) {
      const d = this.isBeta(b);
      if (d === true) {
        let show = false;

        if (localStorage.getItem('showBetaModule' + b) === 'true') {
          show = true;
        }

        if (show !== true) {
          const ref = RootAppComponent.dialog.open(BetaInfoComponent, {
            data: {
              code: b,
            },
            width: '650px',
            height: '650px',
            panelClass: 'noCard',
          });
          ref.componentInstance.close.subscribe((res) => {
            ref.close();
          });

          ref.componentInstance.getModule.subscribe((res) => {
            ref.close();
            this.viewModule(b);
          });
        }
      }
    }
  }
  getToolTip(code) {
    if (this.toolTips && this.toolTips[code]) {
      return this.toolTips[code];
    }

    return null;
  }

  displayToolTip(code) {
    if (this.toolTips && this.toolTips[code]) {
      return true;
    }

    return false;
  }
  getModuleActive(code) {
    if (this.modules && this.modules[code] && this.modules[code].isActive == true && this.mainModuleLoaded == true) {
      return true;
    }

    return false;
  }
}
