import { animate, animateChild, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { DentistService } from '../../dentist/shared/dentist.service';
import { InvitationLookupComponent } from '../../invitation/invitation-lookup/invitation-lookup.component';
import { MerchantService } from '../../merchant/shared/merchant.service';
import { SmsConversationComponent } from '../../message/sms-conversation/sms-conversation.component';
import { SmsPromoterComponent } from '../../message/sms-promoter/sms-promoter.component';

@Component({
  selector: 'app-action-log-interface',
  templateUrl: './action-log-interface.component.html',
  styleUrls: ['./action-log-interface.component.css'],
  animations: [
    trigger('fade', [transition(':enter', [style({ opacity: 0 }), animate('.6s ease')])]),
    trigger('stagger', [transition(':enter', [query(':enter', stagger('.3s', [animateChild()]), { optional: true })])]),
  ],
})
export class ActionLogInterfaceComponent implements OnInit {
  @Input()
  selectedPatient;

  @Input()
  selectedMerchantID;

  @Output()
  close = new EventEmitter();

  @Input()
  title = 'Create Notes';

  isModal = false;
  isPromoterOrAdmin = false;

  merchants;

  patient;
  operators;
  selectedOperator;

  startTime = '00:00:00';

  stopTime = '00:00:00';

  constructor(
    private authenticationService: AuthenticationService,
    private dentistService: DentistService,
    private merchantService: MerchantService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.patient) {
        this.selectedPatient = data.patient;
      }

      if (data.merchantID) {
        this.selectedMerchantID = data.merchantID;
      }

      this.isModal = true;
    }
  }

  ngOnInit() {
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;

      if (res == true) {
        const payload = {
          fields: 'ID,TradingAs,CalculatedName,Status',
        };
        this.merchantService.getList(payload, this.isPromoterOrAdmin).subscribe((res1) => {
          this.merchants = res1;
        });

        if (this.selectedMerchantID) {
          this.getOperators(this.selectedMerchantID);
        }
      } else {
        this.authenticationService.getCurrentUser().subscribe((res) => {
          if (res && res.success == true) {
            this.selectedOperator = res.data;
          }
        });
      }
    });
  }

  closeModal() {
    this.close.emit(true);
  }

  patientLookup() {
    const ref = RootAppComponent.dialog.open(InvitationLookupComponent, {
      data: {
        merchantID: this.selectedMerchantID,
        useStrict: true,
      },
      width: '600px',
    });
    ref.componentInstance.detailedView.subscribe((res) => {
      ref.close();
    });
    ref.componentInstance.getResult.subscribe((res) => {
      if (res) {
        this.selectedPatient = res;
        ref.close();
      }
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });

    ref.componentInstance.patientChat.subscribe((res) => {
      if (res && res.cardID) {
        if (res.isPromoterOrAdmin == true) {
          const ref2 = RootAppComponent.dialog.open(SmsPromoterComponent, {
            data: {
              merchantID: res.merchantID,
              cardID: res.cardID,
              singleChat: true,
            },
            panelClass: 'noCard',
            width: '500px',
          });
          ref2.componentInstance.close.subscribe((data) => {
            ref2.close();
          });

          ref2.componentInstance.getResult.subscribe((data) => {
            if (data) {
              const ref3 = RootAppComponent.dialog.open(SmsConversationComponent, {
                data,
                panelClass: 'bigger-screen',
                width: '70%',
                height: '95vh',
              });

              ref3.backdropClick().subscribe((r) => {});
              const sub = ref3.componentInstance.close.subscribe((data) => {
                ref3.close();
              });
            }

            ref2.close();
          });
        } else {
          const ref2 = RootAppComponent.dialog.open(SmsConversationComponent, {
            data: {
              merchantID: res.merchantID,
              cardID: res.cardID,
              singleChat: true,
            },
            panelClass: 'bigger-screen',
            width: '70%',
            height: '95vh',
          });

          ref2.backdropClick().subscribe((r) => {});
          const sub = ref2.componentInstance.close.subscribe((data) => {
            ref2.close();
          });
        }
      }
    });
  }

  getOperators(id) {
    if (id) {
      const payload = {
        merchantID: id,
        fields: 'ID,CalculatedName',
      };
      this.dentistService.getList(payload, this.isPromoterOrAdmin).subscribe((res) => {
        if (res) {
          this.operators = res;
        }
      });
    }
  }

  getStartTimeEvent(e) {
    if (e) {
      this.startTime = e;
    } else {
      this.startTime = '00:00:00';
    }
  }

  getDurationEvent(value) {
    if (value) {
      const _temp = this.startTime.split(':');
      let _h = Number(_temp[0]);
      let _m = Number(_temp[1]) + Number(value.minutes);
      let _s = Number(_temp[2]) + Number(value.seconds);

      if (_s >= 60) {
        _s = _s - 60;
        _m = _m + 1;
        if (_m >= 60) {
          _m = _m - 60;
          _h = _h + 1;
        }
      }
      if (_m >= 60) {
        _m = _m - 60;
        _h = _h + 1;
      }

      let m;
      let s;

      if (_s < 10) {
        s = '0' + _s;
      } else {
        s = '' + _s;
      }

      if (_m < 10) {
        m = '0' + _m;
      } else {
        m = '' + _m;
      }

      this.stopTime = _h + ':' + m + ':' + s;
    } else {
      this.stopTime = '00:00:00';
    }
  }
}
