import { Location } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { CustomerService } from '../../customer/shared/customer.service';

@Component({
  selector: 'app-address-edit-modal',
  templateUrl: './address-edit-modal.component.html',
  styleUrls: ['./address-edit-modal.component.css'],
})
export class AddressEditModalComponent implements OnInit {
  @Input() customerID;
  @Input() canEdit;
  customer = {};

  @Output()
  closeModal = new EventEmitter();

  isAdressValid = false;
  mySuburb;
  myState;
  myPostCode;
  myStreetNumber;
  myStreetName;
  myUnitNumber;
  myStreetType;
  myLatitude;
  myLongitude;

  addressLabels = [
    { value: 'holiday', name: 'Holiday' },
    { value: 'home', name: 'Home' },
    { value: 'physical', name: 'Physical' },
    { value: 'postal', name: 'Postal' },
    { value: 'relative', name: 'Relative' },
    { value: 'work', name: 'Work' },
  ];

  constructor(
    private location: Location,
    private customerService: CustomerService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data && typeof data == 'string') {
      this.customerID = data;
    }
  }

  ngOnInit() {}

  cancel() {
    this.location.back(); // <-- go back to previous location on cancel
  }

  editDetail() {
    const payload = {
      streetName: this.myStreetName,
      streetNumber: this.myStreetNumber,
      streetType: this.myStreetType,
      unitNumber: this.myUnitNumber,
      latitude: this.myLatitude,
      longitude: this.myLongitude,
      suburb: this.mySuburb,
      state: this.myState,
      postCode: this.myPostCode,
    };
    this.customerService.editCustomer(this.customer['ID'], payload).subscribe((res) => {
      if (res && typeof res == 'object') {
        this.customer = res;

        NotifyAppComponent.displayToast('success', 'Successful operation', 'Detail has been successfully updated');
      }
    });
  }

  getAdress(adr) {
    if (adr) {
      this.mySuburb = adr.suburb;
      this.myState = adr.state;
      this.myPostCode = adr.postCode;
      this.myStreetNumber = adr.streetNumber;
      this.myStreetName = adr.streetName;
      this.myUnitNumber = adr.unitNumber;
      this.myStreetType = adr.streetType;
      this.myLatitude = adr.latitude;
      this.myLongitude = adr.longitude;
    }
  }

  isAdressValidEvent(v) {
    this.isAdressValid = v || false;
  }
}
