import { Component, EventEmitter, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { InvitationTemplateService } from '../shared/invitation-template.service';

@Component({
  selector: 'app-invitation-template-third-party',
  templateUrl: './invitation-template-third-party.component.html',
  styleUrls: ['./invitation-template-third-party.component.css'],
})
export class InvitationTemplateThirdPartyComponent implements OnInit {
  accessIDs = [];
  clientIDs = [];

  close = new EventEmitter();
  closeAll = new EventEmitter();

  templateID;

  sessionType;
  isPromoterOrAdmin = false;

  modalDetails = false;

  constructor(
    private authenticationService: AuthenticationService,
    private invitationTemplateService: InvitationTemplateService,
    private router: Router,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.templateID) {
        this.templateID = data.templateID;
      }
      if (data.accessIDs) {
        let _accessIDs = data.accessIDs;

        if (typeof _accessIDs === 'string') {
          _accessIDs = _accessIDs.split(',');
        }

        if (_accessIDs && _accessIDs.length > 0 && Array.isArray(_accessIDs) == true) {
          this.accessIDs = _accessIDs;
          this.modalDetails = true;
        }
      }
      if (data.clientIDs) {
        let _clientIDs = data.clientIDs;

        if (typeof _clientIDs === 'string') {
          _clientIDs = _clientIDs.split(',');
        }

        if (_clientIDs && _clientIDs.length > 0 && Array.isArray(_clientIDs) == true) {
          this.clientIDs = _clientIDs;
          this.modalDetails = true;
        }
      }
    }
  }

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((r) => {
      this.sessionType = r;

      this.authenticationService.isPromoterOrAdmin().subscribe((isPromoterOrAdmin) => {
        this.isPromoterOrAdmin = isPromoterOrAdmin;

        if (this.modalDetails === false && this.templateID) {
          this.invitationTemplateService.getThirdPartyDetails(this.templateID).subscribe((res) => {
            if (res) {
              if ((res.accessIDs && res.accessIDs.length > 0) || (res.clientIDs && res.clientIDs.length > 0)) {
                if (res.accessIDs && res.accessIDs.length > 0) {
                  this.accessIDs = res.accessIDs;
                }
                if (res.clientIDs && res.clientIDs.length > 0) {
                  this.clientIDs = res.clientIDs;
                }
              } else {
                this.closeEvent();
              }
            }
          });
        }
      });
    });
  }

  closeEvent() {
    this.close.emit(true);
  }

  viewAll() {
    this.router.navigate(['/merchant', { outlets: { page: ['third-party-access-client-list', this.templateID] } }]);
    this.closeAll.emit(true);
  }
}
