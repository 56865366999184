<ng-container *ngIf="view == 'consumer'">
  <!-- consumer view -->
  <div class="row clearfix rel mb consumer" *ngIf="_documents && _documents.length > 0">
    <div class="text-center">
      <h2 class="sr-title summary-header text-center rm-mb" *ngIf="title">
        <span
          class="min-badge-mini-list"
          [matBadge]="_documents.length"
          matBadgeOverlap="false"
          matBadgeColor="accent"
          matBadgePosition="above after"
        >
          {{ title }}
        </span>
      </h2>
      <p class="detail-p" *ngIf="description">
        {{ description }}
      </p>
      <p class="small instruction">{{ instructionLine }}</p>

      <hr class="mb"/>
    </div>

    <div class="row clearfix" *ngIf="isFilter == true">
      <mat-form-field class="full-width clearfix" appearance="outline">
        <mat-label>Filter by: Label, Description, ID, Extension, URL, Complete or Tags</mat-label>
        <input class="mat-block" [(ngModel)]="filter" matInput name="filter" placeholder="Enter filter"/>
      </mat-form-field>
    </div>

    <div class="row clearfix" *ngIf="isAction != true">
      <div class="document-list mini action-false">
        <div class="animate rel {{ t.type }} list-item" *ngFor="let t of _documents; let i = index">
          <mat-card class="mat-elevation-z5" (click)="viewFiles(i)">
            <div class="type-icon">
              <mat-icon class="fas fa-css3" *ngIf="t.Extension == 'css'" matListIcon></mat-icon>
              <mat-icon class="fas fa-js" *ngIf="t.Extension == 'js'" matListIcon></mat-icon>
              <mat-icon class="fas fa-html5" *ngIf="t.Extension == 'html'" matListIcon></mat-icon>
              <mat-icon class="fas fa-file-alt" *ngIf="t.Extension == 'pdf'" matListIcon></mat-icon>
              <mat-icon
                class="fas fa-file-alt"
                *ngIf="
                  t.Extension != 'pdf' &&
                  t.Extension != 'css' &&
                  t.Extension != 'js' &&
                  t.Extension != 'html' &&
                  t.type == 'document'
                "
                matListIcon
              ></mat-icon>

              <mat-icon class="fas fa-image" *ngIf="t.type == 'image'" matListIcon></mat-icon>
              <mat-icon class="fas fa-video" *ngIf="t.type == 'video'" matListIcon></mat-icon>
              <mat-icon class="fas fa-file-csv" *ngIf="t.type == 'excel'" matListIcon></mat-icon>
              <mat-icon class="fas fa-link" *ngIf="t.type == 'link'" matListIcon></mat-icon>
            </div>

            <div
              class="card-image"
              [class.video-thumbnail]="t.type == 'video'"
              [class.docu-thumbnail]="t.type == 'pdf' || t.type == 'document' || t.type == 'image'"
              [class.link-thumbnail]="t.type == 'link'"
            >
              <app-ep-document-thumbnail-view
                class="document-list-mini"
                [hideZoomButton]="true"
                [document]="t"
                mat-card-image
              >
              </app-ep-document-thumbnail-view>
            </div>

            <mat-card-content>
              <h3 class="sr-title rm-mb" matLine>{{ t.Label }}</h3>

              <p class="small rm-mb" matLine>{{ t.Description }}</p>

              <span class="" *ngIf="displayStatus == true && isDocumentViewed(i) == true && displayViewedDate == true"
              >Viewed on: {{ getViewedDate(i) | customDateTime }}</span
              >

              <span
                class="pull-right chip chipSeen"
                *ngIf="displayStatus == true && isDocumentViewed(i) == true"
                matTooltip="Viewed"
              >
                <mat-icon>check</mat-icon>
              </span>

              <span class="pull-right label" *ngIf="displayDate == true"
              >{{ t.DateTimeCreated | TimeLabelPast }} ago</span
              >
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>

    <div class="row clearfix" *ngIf="isAction == true">
      <div class="document-list mini action-true">
        <div class="animate rel list-item" *ngFor="let t of _documents; let i = index" (click)="sendFile(_documents)">
          <mat-card class="mat-elevation-z5">
            <app-ep-document-thumbnail-view
              class="document-list-mini"
              [hideZoomButton]="true"
              [document]="t"
              mat-card-image
            >
            </app-ep-document-thumbnail-view>

            <mat-card-content>
              <h3 class="sr-title" matLine>
                {{ t.Label }}

                <button class="btn-small list-button mat-button" (click)="viewFiles(i)">
                  <mat-icon>visibility</mat-icon>
                </button>
              </h3>
              <label class="small" matLine> {{ t.Description }}</label>

              <span class="" *ngIf="displayStatus == true && isDocumentViewed(i) == true && displayViewedDate == true"
              >Viewed on: {{ getViewedDate(i) | customDateTime }}</span
              >

              <span
                class="pull-right chip chipSeen"
                *ngIf="displayStatus == true && isDocumentViewed(i) == true"
                matTooltip="Viewed"
              >
                <mat-icon>check</mat-icon>
              </span>

              <span class="pull-right label" *ngIf="displayDate == true"
              >{{ t.DateTimeCreated | TimeLabelPast }} ago</span
              >
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="view == 'merchant'">
  <!-- merchant view -->

  <div class="row clearfix rel mb merchant" *ngIf="_documents && _documents.length > 0">
    <div class="row clearfix">
      <h3 class="sr-title rm-mb shr-p" *ngIf="title">
        <span
          [matBadge]="_documents.length"
          matBadgeOverlap="false"
          matBadgeColor="accent"
          matBadgePosition="above after"
        >
          {{ title }}
        </span>
      </h3>
      <p class="detail-p hide-pr" *ngIf="description">
        {{ description }}
      </p>
      <p class="small instruction rm-m hide-pr">{{ instructionLine }}</p>
      <hr class=""/>
    </div>

    <div class="row clearfix hide-pr" *ngIf="isFilter == true">
      <mat-form-field class="full-width clearfix" appearance="outline">
        <mat-label>Filter by: Label, Description, ID, Extension, URL, Complete or Tags</mat-label>
        <input class="mat-block" [(ngModel)]="filter" matInput name="filter" placeholder="Enter filter"/>
      </mat-form-field>
    </div>

    <div class="row clearfix" *ngIf="isAction != true">
      <mat-list class="action-false">
        <mat-list-item class="animate rel list-item" *ngFor="let t of _documents; let i = index">
          <div class="row clearfix full-width flex flex-center rel">
            <mat-card class="pull-left pointer" (click)="viewFiles(i)">
              <div class="type-icon2">
                <mat-icon class="fas fa-css3" *ngIf="t.Extension == 'css'" matListIcon></mat-icon>
                <mat-icon class="fas fa-js" *ngIf="t.Extension == 'js'" matListIcon></mat-icon>
                <mat-icon class="fas fa-html5" *ngIf="t.Extension == 'html'" matListIcon></mat-icon>
                <mat-icon class="fas fa-file-alt" *ngIf="t.Extension == 'pdf'" matListIcon></mat-icon>
                <mat-icon
                  class="fas fa-file-alt"
                  *ngIf="
                    t.Extension != 'pdf' &&
                    t.Extension != 'css' &&
                    t.Extension != 'js' &&
                    t.Extension != 'html' &&
                    t.type == 'document'
                  "
                  matListIcon
                ></mat-icon>

                <mat-icon class="fas fa-image" *ngIf="t.type == 'image'" matListIcon></mat-icon>
                <mat-icon class="fas fa-video" *ngIf="t.type == 'video'" matListIcon></mat-icon>
                <mat-icon class="fas fa-file-csv" *ngIf="t.type == 'excel'" matListIcon></mat-icon>
                <mat-icon class="fas fa-link" *ngIf="t.type == 'link'" matListIcon></mat-icon>
              </div>
              <div
                class="card-image"
                [class.video-thumbnail]="t.type == 'video'"
                [class.docu-thumbnail]="t.type == 'pdf' || t.type == 'document' || t.type == 'image'"
                [class.link-thumbnail]="t.type == 'link'"
                matTooltip="View {{ t.type }}"
              >
                <app-ep-document-thumbnail-view
                  class="document-list-mini"
                  [hideZoomButton]="true"
                  [document]="t"
                  mat-card-image
                >
                </app-ep-document-thumbnail-view>
              </div>
            </mat-card>

            <div class="pull-left">
              <h4 class="sr-title rm-m" style="white-space: pre-wrap; max-width: 300px; overflow: visible" matLine>
                {{ t.Label }}
              </h4>
              <p class="small rm-m bold" style="white-space: pre-wrap; max-width: 300px; overflow: visible" matLine>
                {{ t.Description }}
              </p>
              <p class="small rm-m" matLine>
                <span class="" *ngIf="displayStatus == true && isDocumentViewed(i) == true && displayViewedDate == true"
                >Viewed on: {{ getViewedDate(i) | customDateTime }}</span
                >
                <span class="pull-right chip chipSeen" *ngIf="displayStatus == true && isDocumentViewed(i) == true">
                  <mat-icon>check</mat-icon>
                </span>
                <span class="pull-right label" *ngIf="displayDate == true"
                >{{ t.DateTimeCreated | TimeLabelPast }} ago</span
                >
              </p>
            </div>
          </div>
        </mat-list-item>
      </mat-list>
    </div>

    <div class="row clearfix" *ngIf="isAction == true">
      <div class="document-list mini action-true">
        <div
          class="animate rel list-item"
          *ngFor="let t of _documents; let i = index"
          (click)="sendFile(_documents)"
          matTooltip="{{ actionLabel }}"
        >
          <mat-card class="mat-elevation-z5">
            <app-ep-document-thumbnail-view
              class="document-list-mini"
              [hideZoomButton]="true"
              [document]="t"
              mat-card-image
            >
            </app-ep-document-thumbnail-view>

            <mat-card-content>
              <h3 class="sr-title" matLine>
                {{ t.Label }}

                <button class="btn-small list-button mat-button" (click)="viewFiles(i)" matTooltip="View Document">
                  <mat-icon>visibility</mat-icon>
                </button>
              </h3>
              <label class="small" matLine> {{ t.Description }}</label>

              <span class="" *ngIf="displayStatus == true && isDocumentViewed(i) == true && displayViewedDate == true"
              >Viewed on: {{ getViewedDate(i) | customDateTime }}</span
              >

              <span class="pull-right chip chipSeen" *ngIf="displayStatus == true && isDocumentViewed(i) == true">
                <mat-icon>check</mat-icon>
              </span>

              <span class="pull-right label" *ngIf="displayDate == true"
              >{{ t.DateTimeCreated | TimeLabelPast }} ago</span
              >
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
</ng-container>
