<div class="container">
  <div class="row clearfix full-width flex">
    <div class="full-width">
      <mat-card class="card clearfix" *ngIf="wiki && loading == false">
        <div class="card-header primary-gradient-img clearfix slim-header">
          <div class="row clearfix">
            <!-- Modal dismiss button -->

            <button
              class="btn-close btn-clear white pull-right mat-button"
              *ngIf="isModal == true"
              (click)="closeEvent()"
            >
              <mat-icon>clear</mat-icon>
            </button>

            <app-ep-document-view-image
              class="themeImage-mini mr pull-left"
              *ngIf="wiki && wiki.fileID"
              [hideZoomButton]="false"
              [imageLink]="getSrc(wiki.fileID)"
            >
            </app-ep-document-view-image>

            <!-- merchant calculated name -->
            <div class="row titleArea pull-left">
              <h2 class="message-title summary-header rm-m" *ngIf="wiki && wiki.group && wiki.group.title">
                {{ wiki.title }}
                <span class="value chipNew new" *ngIf="isNew(wiki['created_at']) == true">NEW</span>

                <span
                  class="subLabel poitner-cursor"
                  *ngIf="wiki.group && wiki.group._id"
                  (click)="moveToGroup(wiki.group._id)"
                >
                  <mat-icon
                    class="{{ wiki.group.icon }} extra-header-icon"
                    *ngIf="wiki.group && wiki.group.icon && isAwsome(wiki.group.icon) == true"
                  ></mat-icon>
                  <mat-icon
                    class="extra-header-icon"
                    *ngIf="wiki.group && wiki.group.icon && isAwsome(wiki.group.icon) != true"
                  >{{ wiki.group.icon }}</mat-icon
                  >

                  {{ wiki.group.title }}
                </span>
              </h2>
            </div>

            <div class="pull-right">
              <div class="pull-right" *ngIf="isPromoterOrAdmin == true">
                <button class="actionMenuToggle" [matMenuTriggerFor]="menu" mat-icon-button>
                  <mat-icon class="white">more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <button (click)="edit()" mat-menu-item>
                    <mat-icon class="fas fa-edit"></mat-icon>
                    <span>Edit</span>
                  </button>

                  <button (click)="editGoup()" mat-menu-item>
                    <mat-icon class="fas fa-edit"></mat-icon>
                    <span>Edit Category</span>
                  </button>

                  <button *ngIf="wiki.isActive != true" (click)="enable()" mat-menu-item>
                    <mat-icon class="fas fa-check-circle"></mat-icon>
                    <span>Enable</span>
                  </button>

                  <button *ngIf="wiki.isActive == true" (click)="disable()" mat-menu-item>
                    <mat-icon class="fas fa-ban"></mat-icon>
                    <span>Disable</span>
                  </button>

                  <button (click)="remove()" mat-menu-item>
                    <mat-icon class="fas fa-trash-alt"></mat-icon>
                    <span>Delete</span>
                  </button>
                </mat-menu>
              </div>

              <p class="small pull-right" *ngIf="wiki.created_at">
                <mat-icon class="fas fa-clock extra-header-icon"></mat-icon>
                {{ wiki.created_at | TimeLabelPast }} ago
              </p>

              <p class="small ml mr pull-right" *ngIf="wiki.created_at">
                <mat-icon class="fas fa-eye extra-header-icon"></mat-icon>
                {{ wiki.views || 1 }}
              </p>

              <p class="small mr pull-right" *ngIf="wiki['isActive'] == true && isPromoterOrAdmin == true">
                <span class="chip-enable">Active</span>
              </p>
              <p class="small mr pull-right" *ngIf="wiki['isActive'] != true && isPromoterOrAdmin == true">
                <span class="chip-disable">Not active</span>
              </p>

              <p
                class="small pull-right"
                *ngIf="wiki && wiki.roles && wiki.roles.length > 0 && isPromoterOrAdmin == true"
              >
                <span class="chip-only" *ngFor="let r of wiki.roles | orderBy" matTooltip="{{ r }}">{{
                  firstLetterUpperCase(r)
                  }}</span>
              </p>
            </div>
          </div>
        </div>

        <mat-dialog-content class="article-view" [ngClass]="{ 'no-modal-height': isModal != true }">
          <div class="row clearfix" [innerHTML]="wiki.content | safeHtml">
            <!-- content renders here -->
          </div>

          <hr *ngIf="wiki.references && wiki.references.length > 0"/>

          <h3 class="sm-h3 sr-title" *ngIf="wiki.references && wiki.references.length > 0">References</h3>

          <p class="reference-container" *ngFor="let r of wiki.references">
            <a class="link-class" (click)="redirectLink(r.label, r.link)">{{ r.label }}</a>
          </p>

          <div class="full-width clearfix row">
            <hr/>
            <mat-chip-list class="clearfix pull-left mr" *ngFor="let r of wiki.tags">
              <mat-chip
                class="poitner-cursor"
                (click)="searchEvent(r)"
                matTooltip="Look for Articles with similiar keyword"
              >
                {{ r }}</mat-chip
              >
            </mat-chip-list>
          </div>

          <div class="full-width clearfix mt" #container *ngIf="isPromoterOrAdmin == true">
            <button
              class="pull-right ml"
              [container]="container"
              [cbContent]="originLink + '/wiki/(wikiPageRoute:view-code/' + wiki.code + ')'"
              (click)="clickCopy()"
              ngxClipboard
              mat-mini-fab
              color="primary"
              matTooltip="Copy static article link"
            >
              <mat-icon>file_copy</mat-icon>
            </button>

            <i class="static-link pull-right">
              <strong>{{ originLink }}/wiki/(wikiPageRoute:view-code/{{ wiki.code }}) </strong>
            </i>
          </div>
        </mat-dialog-content>

        <div
          class="button-row row full-width"
          *ngIf="isModal == true && wiki && loading == false && (wiki.beforeID || wiki.afterID)"
        >
          <div class="full-width mt clearfix nagivation-button text-center">
            <button
              class="pull-left clearfix"
              *ngIf="wiki.beforeID"
              (click)="moveToPage(wiki.beforeID)"
              mat-button
              color="primary"
            >
              <mat-icon>keyboard_arrow_left</mat-icon>
              {{ wiki.beforeTitle }}
            </button>

            <button
              class="pull-right"
              *ngIf="wiki.afterID"
              (click)="moveToPage(wiki.afterID)"
              mat-button
              color="primary"
            >
              {{ wiki.afterTitle }}
              <mat-icon>keyboard_arrow_right</mat-icon>
            </button>
          </div>
        </div>
      </mat-card>

      <mat-dialog-content
        class="clearfix mt full-width"
        *ngIf="!wiki && loading == false"
        [ngClass]="{ 'no-modal-height': isModal != true }"
      >
        <app-empty-list-message
          class="full-width"
          *ngIf="sessionType != 'guest'"
          [title]="'Page Not found'"
          [message]="'This article does not exist anymore'"
        >
        </app-empty-list-message>

        <app-empty-list-message
          class="clearfix full-width"
          *ngIf="sessionType == 'guest'"
          [title]="'Page Not found'"
          [message]="'This article does not exist anymore, or you dont have access to it'"
          [isAction]="true"
          [actionIcon]="'lock_open'"
          [actionLabel]="'Login'"
          (actionEvent)="login()"
        >
        </app-empty-list-message>
      </mat-dialog-content>

      <div
        class="full-width clearfix nagivation-button"
        *ngIf="isModal != true && wiki && loading == false && (wiki.beforeID || wiki.afterID)"
      >
        <button
          class="pull-left clearfix"
          *ngIf="wiki.beforeID"
          (click)="moveToPage(wiki.beforeID)"
          mat-button
          color="primary"
        >
          <mat-icon>keyboard_arrow_left</mat-icon>
          {{ wiki.beforeTitle }}
        </button>

        <button class="pull-right" *ngIf="wiki.afterID" (click)="moveToPage(wiki.afterID)" mat-button color="primary">
          {{ wiki.afterTitle }}
          <mat-icon>keyboard_arrow_right</mat-icon>
        </button>
      </div>
    </div>

    <app-wiki-summary class="thrd-width ml" *ngIf="isModal != true"></app-wiki-summary>
  </div>
</div>
