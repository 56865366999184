<div class="full-width">
  <div class="card-header primary-gradient-img clearfix inModal">
    <button class="btn-close btn-clear mat-button" (click)="closeEvent()">
      <mat-icon>clear</mat-icon>
    </button>

    <div class="row titleArea clearfix">
      <mat-icon class="fas fa-search-dollar mr" style="
    font-size: 2.5rem;
    width: 2.5rem;
    line-height: 2.5rem;
    height: 2.5rem;
"></mat-icon>
      <h2 class="summary-header rm-m" *ngIf="merchant && merchant.TradingAs">
        {{merchant.TradingAs}}

      </h2>

      <h2 class="summary-header rm-m" *ngIf="!merchant">
        Material Advance Settings

      </h2>

    </div>
  </div>

  <mat-dialog-content>
    <div class="row full-width clearfix ">



      <p class="small rm-mt mb">Choose to Enable/Disable Material Advance and also purcent of the contract amount
        allowed
        for the
        {{'Merchant' | translate}} to draw from</p>


      <div class="full-width  flex ">

        <div class="full-width ">
          <mat-slide-toggle class="clearfix smt" [(ngModel)]="materialRequestActive"
            [disabled]="!materialRequestActiveDefault" name="materialRequestActive">
            {{ materialRequestActive === true ? 'Active' : 'Inactive' }}
          </mat-slide-toggle>


        </div>

        <div class="full-width ml  ">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label> Material Advance Percentage</mat-label>
            <input type="number" [(ngModel)]="materialRequestPercent" name="materialRequestPercent"
              [disabled]="!materialRequestActiveDefault" matInput placeholder=" Material Advance Percentage" />
            <span matSuffix>%</span>
          </mat-form-field>

        </div>
      </div>

      <div class="full-width smt flex" *ngIf="materialRequestActiveDefault!=true">

        <p class="error-request full-width rm-mt">

          <mat-icon class="fas fa-exclamation-circle pull-left mr"></mat-icon>

          Material request is globally <strong>disabled </strong>. Please adjust the settings in the
          admin page to enable
          modification of the
          merchant data.
        </p>
      </div>


    </div>
  </mat-dialog-content>

  <div class="full-width">
    <hr>

    <button class="btn-large pull-left " (click)="closeEvent()" type="button" mat-raised-button color="primary">
      Close
    </button>


    <button class="btn-large pull-right " (click)="save()" type="button" mat-raised-button color="accent">
      Save
    </button>


  </div>



</div>
