<div class="negative-corner bg-light-gray">
  <div id="blueBg"></div>

  <h3 class="white-header annotation">Step 2</h3>

  <section class="introduction">
    <div class="container">
      <div class="row clearfix introduction-hero">
        <div class="text pull-right half-width">
          <div class="col-left">
            <h1 class="white-header text-nowrap">Sending {{ "PHRASE.treatment plan" | translate | titlecase }}s</h1>
            <p class="white lead">
              Paper is a thing of the past, it’s time to go digital! We have made things easy with just a few clicks you
              will be communicating digitally in no time.
            </p>
          </div>
        </div>
        <div class="text pull-left half-width">
          <img class="sending-image"
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/gettingstarted-img6.png"
               mat-card-image/>
        </div>
      </div>
    </div>
  </section>

  <section class="top-section">
    <div class="container bg-white topIntro offset-content">
      <h2 class="heading-text">What do I need to do for my {{ "KEYWORD.patient" | translate }}s?</h2>
      <p class="paragraph-default">
        It's essential you send digital {{ "PHRASE.treatment plan" | translate }}s to
        <strong>ALL</strong> your {{ "KEYWORD.patient" | translate }}s, not just ones who might want finance. Why should
        only some {{ "KEYWORD.patient" | translate }}s get the digital experience, informed consent materials and
        communication tools, while others get a boring "black and white" document?
      </p>
      <p class="paragraph-default">
        Remember, {{ "BRANDING.Brand Name" | translate | titlecase }} is about {{ "KEYWORD.patient" | translate }}
        communication and
        education, not just finance. We've put together a checklist to help you communicate digital {{
        "KEYWORD.treatment" | translate }} from start
        to finish:
      </p>
    </div>
    <div class="container">
      <div class="basic-column centered-column offset-image">
        <div class="article-section">
          <span class="article-head">Articles</span>
          <ul class="check">
            <li>
              <div class="text">
                <p class="getting-started-page">
                  <strong>Prepare {{ "KEYWORD.patient" | translate | titlecase }} in Portal</strong> - Your
                  {{ "KEYWORD.patient" | translate }}s should be already imported into your portal, if not visit the
                  previous step to see how. Is your {{ "KEYWORD.patient" | translate }} new? No problem! All you need is
                  a name, mobile and email to communicate.
                </p>
              </div>
              <a class="article-links" target="_blank"
                 href="https://app.smileright.com.au/wiki/(wikiPageRoute:view-code/setting-up-your-profile">
                <mat-icon>description</mat-icon>
              </a>
            </li>
            <li>
              <div class="text">
                <p class="getting-started-page">
                  <strong>After {{ "KEYWORD.consultation" | translate | titlecase }}</strong> - Once
                  your {{ "KEYWORD.patient" | translate }} has had their
                  {{ "KEYWORD.consultation" | translate }}, complete the invitation process and press send, and the job
                  is done.
                </p>
              </div>
              <a class="article-links" target="_blank"
                 href="https://app.smileright.com.au/wiki/(wikiPageRoute:view-code/setting-up-your-profile">
                <mat-icon>description</mat-icon>
              </a>
            </li>
            <li>
              <div class="text">
                <p class="getting-started-page">
                  <strong>Send {{ "KEYWORD.treatment" | translate | titlecase }} in 30 Seconds</strong> - Save more time
                  by
                  using {{ "KEYWORD.treatment" | translate }} templates. These
                  pre-populate an offer for {{ "KEYWORD.treatment" | translate | titlecase }} including price, images,
                  videos,
                  informed consent material and
                  more. Use one provided by {{ "BRANDING.Brand Name" | translate | titlecase }} or clone and modify to
                  suit your
                  {{ "KEYWORD.practice" | translate }}.
                </p>
              </div>
              <a class="article-links" target="_blank"
                 href="https://app.smileright.com.au/wiki/(wikiPageRoute:view-code/setting-up-your-profile">
                <mat-icon>description</mat-icon>
              </a>
            </li>
            <li>
              <div class="text">
                <p class="getting-started-page">
                  <strong>Keep Track of Invitations</strong> - Sit back and get ready
                  - {{"PARAGRAPH.Core Getting Started Electronic Communication" | translate}}
                </p>
              </div>
              <a class="article-links" target="_blank"
                 href="https://app.smileright.com.au/wiki/(wikiPageRoute:view-code/setting-up-your-profile">
                <mat-icon>description</mat-icon>
              </a>
            </li>
          </ul>
        </div>

        <div class="card-column card-column-reverse mb">
          <div class="column-header">
            <h3 class="text-blue heading-text download-heading">Templates and Default Messages</h3>
          </div>
          <div class="column with-text">
            <h3 class="text-green heading-text download-heading">Templates</h3>
            <p class="download-paragraph">
              One of the most important things for getting started is creating your
              {{ "KEYWORD.practice" | translate }} templates - this helps you save time when
              sending {{ 'PHRASE.treatment plan' | translate }}s.
              Templates can be edited and customised for each {{ "KEYWORD.patient" | translate }} depending on
              {{ "KEYWORD.treatment" | translate }}. To create a template, go to the invitations tab and select new
              invitation > new invitation
              template:
            </p>
            <ul>
              <li>Give the template a name</li>
              <li>Give a default {{ "KEYWORD.treatment" | translate }} value (this can be edited)</li>
              <li>Upload or select any supporting documents and imagery (more can be added later)</li>
              <li>Define the {{ "KEYWORD.treatment" | translate }} to capture informed consent</li>
            </ul>
          </div>
          <div class="column with-image">
            <img class="column-image download-image"
              src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/gettingstarted-img7.png"
                 alt=""/>
          </div>
        </div>

        <div class="card-column mt-3">
          <div class="column with-image">
            <img class="column-image download-image"
              src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/gettingstarted-img8.png"
                 alt=""/>
          </div>
          <div class="column with-text">
            <h3 class="text-green heading-text download-heading">Default Messages</h3>
            <p class="download-paragraph">
              When you send a {{ 'PHRASE.treatment plan' | translate }}, you can attach a personalised message or
              general message to your
              {{ "KEYWORD.patient" | translate }}. Introduce your {{ "KEYWORD.patient" | translate }}s to their
              {{ 'PHRASE.treatment plan' | translate }} with a friendly message and remind them of things you discussed
              during {{ "KEYWORD.consultation" | translate }}. To
              make a new message template go to settings > invitation > default messages > create new message:
            </p>
            <ul>
              <li>Select Product Group</li>
              <li>Give the message a title (i.e., Welcome to {{ "KEYWORD.dental" | translate | uppercase }}
                _{{ "KEYWORD.practice" | translate | uppercase }})
              </li>
              <li>
                Start typing what you want the message to say. We recommend using the ‘insert personalisation’ button to
                have details auto filled.
              </li>
              <li>Press save!</li>
            </ul>
          </div>
        </div>
        <div class="footer-links">
          <button class="btn-large mt btn-green" [routerLink]="['/merchant', { outlets: { page: ['setting-up'] } }]"
                  mat-raised-button color="primary">
            <mat-icon fontSet="fas" fontIcon="fa-angle-left"></mat-icon>
            Back
          </button>
          <button class="btn-large mt btn-green" [routerLink]="['/merchant', { outlets: { page: ['getting-paid'] } }]"
                  mat-raised-button color="primary">
            Step Three
            <mat-icon fontSet="fas" fontIcon="fa-angle-right"></mat-icon>
          </button>
        </div>
      </div>
    </div>
  </section>
</div>
