<div class="rel lookup">
  <div class="clearfix">
    <div class="card-header primary-gradient-img clearfix inModal">
      <div class="row clearfix">
        <!-- Modal dismiss button -->
        <button class="btn-close btn-clear mat-button white" (click)="closeEvent()">
          <mat-icon>clear</mat-icon>
        </button>
        <!-- merchant calculated name -->

        <div class="row titleArea clearfix">
          <h2 class="summary-header rm-m">
            <mat-icon class="fas fa-store pull-left mr"></mat-icon>
            Select Merchant
          </h2>
        </div>
      </div>
    </div>

    <p class="small" *ngIf="isSlected == true">
      You can continue viewing the application as the last selected Merchant .
    </p>

    <div class="row clearfix flex">
      <mat-form-field class="mr full-width" appearance="outline">
        <mat-label>{{ merchantLabel }}</mat-label>
        <input
          [(ngModel)]="merchant"
          [matAutocomplete]="auto"
          (input)="filterMerchant($event)"
          name="MerchantName"
          type="text"
          placeholder="{{ merchantLabel }}"
          matInput
        />
        <mat-autocomplete
          #auto="matAutocomplete"
          [displayWith]="getOptionText"
          (optionSelected)="getSelectedMerchant($event.option.value)"
        >
          <mat-option *ngFor="let m of filterMerchantList" [value]="m">
            {{ m['TradingAs'] || m['CalculatedName'] }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <button
        class="list-button pull-right"
        *ngIf="merchantID"
        [matTooltip]="('KEYWORD.practice' | translate | titlecase)"
        (click)="viewMerchant()"
        mat-stroked-button
      >
        <mat-icon>person</mat-icon>
      </button>
    </div>

    <div class="row clearfix flex flex-center mb" *ngIf="merchant && merchant.ID" @simpleFadeAnimation>
      <app-merchant-view-picture class="mr view mat-elevation-z3" [merchantID]="merchant['ID']">
      </app-merchant-view-picture>

      <h3 class="sr-title full-width">{{ merchant['TradingAs'] }}</h3>
    </div>

    <div class="drop-buttons">
      <button class="inline btn-large ml mr" (click)="closeEvent()" mat-raised-button color="primary">
        View As Promoter
      </button>

      <button class="inline btn-large" (click)="apply()" mat-raised-button color="accent">{{ applyTitle }}</button>
    </div>
  </div>
</div>
