<ng-container [ngSwitch]="designType">
  <div class="flex" *ngSwitchCase="'raised'">
    <button
      class="pull-right ml"
      *ngIf="googleCalendarEnabled && (type === 'all' || type === 'google')"
      (click)="addToGoogleCalendar()"
      mat-raised-button
      color="primary"
      matTooltip="{{ googleButtonLabel }}"
    >
      <mat-icon>event</mat-icon> {{ googleButtonLabel }}
    </button>

    <button
      class="pull-right ml"
      *ngIf="type === 'all' || type === 'apple'"
      (click)="addToAppleCalendar()"
      mat-raised-button
      color="primary"
      matTooltip="{{ appleButtonLabel }}"
    >
      <mat-icon>event</mat-icon> {{ appleButtonLabel }}
    </button>

    <button
      class="pull-right ml"
      *ngIf="type === 'all' || type === 'microsoft'"
      (click)="addToMicrosoftCalendar()"
      mat-raised-button
      color="primary"
      matTooltip="{{ microsoftButtonLabel }}"
    >
      <mat-icon>event</mat-icon> {{ microsoftButtonLabel }}
    </button>
  </div>

  <ng-container *ngSwitchCase="'menu'">
    <button
      class="sideMenuButton"
      class="pull-right mr"
      [matMenuTriggerFor]="extendedMenu"
      mat-raised-button
      color="primary"
    >
      <mat-icon class="fas fa-calendar-check"></mat-icon> Sync Appointment
    </button>

    <mat-menu #extendedMenu="matMenu">
      <button
        *ngIf="googleCalendarEnabled && (type === 'all' || type === 'google')"
        (click)="addToGoogleCalendar()"
        mat-menu-item
      >
        <mat-icon class="fab fa-google mr"></mat-icon>
        {{ googleButtonLabel }}
        <mat-icon
          class="ml pull-right"
          *ngIf="displayConnectedIcon"
          [ngClass]="{
            warningStatus: !googleIsConnected,
            validStatus: googleIsConnected
          }"
        >{{ googleIsConnected ? 'power' : 'power_off' }}</mat-icon
        >
      </button>

      <button *ngIf="type === 'all' || type === 'apple'" (click)="addToAppleCalendar()" mat-menu-item>
        <mat-icon class="fab fa-apple mr"></mat-icon>
        {{ appleButtonLabel }}
        <mat-icon
          class="ml pull-right"
          *ngIf="displayConnectedIcon"
          [ngClass]="{
            warningStatus: !appleIsConnected,
            validStatus: appleIsConnected
          }"
        >{{ appleIsConnected ? 'power' : 'power_off' }}</mat-icon
        >
      </button>

      <button *ngIf="type === 'all' || type === 'microsoft'" (click)="addToMicrosoftCalendar()" mat-menu-item>
        <mat-icon class="fab fa-microsoft mr"></mat-icon>
        {{ microsoftButtonLabel }}
        <mat-icon
          class="ml pull-right"
          *ngIf="displayConnectedIcon"
          [ngClass]="{
            warningStatus: !microsoftIsConnected,
            validStatus: microsoftIsConnected
          }"
        >{{ microsoftIsConnected ? 'power' : 'power_off' }}</mat-icon
        >
      </button>
    </mat-menu>
  </ng-container>
</ng-container>
