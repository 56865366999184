<div class="rel dentist-card merchantView clearfix white-background"
     *ngIf="merchant && merchant.ID && products && products.length > 0">
  <div class="animate">
    <div class="card-header primary-gradient-img clearfix moduleList">
      <div class="row clearfix">
        <button class="btn-close btn-clear mat-button white" *ngIf="isModal" matDialogClose>
          <mat-icon>clear</mat-icon>
        </button>

        <div class="row titleArea clearfix mt full-width">
          <app-merchant-view-picture class="headNavView userIconView mr pull-left" *ngIf="isAdminOrPromoter == true"
                                     [merchantID]="merchantID">
          </app-merchant-view-picture>
          <h2 class="summary-header rm-m" *ngIf="isAdminOrPromoter == true">
            {{ merchant.TradingAs }}
            <span class="subLabel">Control product assistance accesses, which allow a merchant to proceed an application
              on behalf of a
              {{"KEYWORD.patient" | translate}}</span>
          </h2>

          <mat-icon class="fas fa-hands-helping mr pull-left header-icon"></mat-icon>

          <h2 class="summary-header rm-m" *ngIf="isAdminOrPromoter != true">
            {{ "KEYWORD.patient" | translate | titlecase }} portal assistance
            <span class="subLabel">List of products you are allowed to choose in order to proceed an application on
              behalf of your
              {{"KEYWORD.patient" | translate}}</span>
          </h2>
        </div>
      </div>
    </div>

    <mat-dialog-content class="full-width mb clearfix row" [ngClass]="{ 'no-modal-height': isModal != true }">
      <div class="clearfix full-width">
        <!-- <mat-form-field class="thrd-width ml" appearance="outline">

          <mat-label>Active filter</mat-label>

          <mat-select name="isQctive" class="ml" placeholder="Active filter" [(ngModel)]="isActiveFilter">
            <mat-option [value]="'*'">
              {{"All" | translate}}
            </mat-option>

            <mat-option [value]="true">
              {{"Active" | translate}}
            </mat-option>


            <mat-option [value]="false">
              {{"Inactive" | translate}}
            </mat-option>


          </mat-select>
        </mat-form-field> -->

        <mat-form-field class="half-width pull-left ml clearfix" *ngIf="products && products.length > 0"
                        appearance="outline">
          <mat-label>Filter by name or description</mat-label>

          <input class="full-width" [(ngModel)]="filterName" name="linkLabel" matInput
                 placeholder="Filter by name or description"/>
        </mat-form-field>

        <button class="pull-right actionMenuToggle" *ngIf="isEdit == true" [matMenuTriggerFor]="btnPatMenu"
                mat-raised-button>
          Actions <mat-icon>more_vert</mat-icon>
        </button>

        <mat-menu #btnPatMenu="matMenu">
          <button (click)="changeByType(true, 'pp')" mat-menu-item>
            <span>Enable only <strong>PP</strong> products </span>
          </button>

          <button (click)="changeByType(false, 'pp')" mat-menu-item>
            <span>Disable all <strong>PP</strong> products </span>
          </button>

          <mat-divider></mat-divider>

          <button (click)="changeByType(true, 'la')" mat-menu-item>
            <span>Enable only <strong>LA</strong> products </span>
          </button>

          <button (click)="changeByType(false, 'la')" mat-menu-item>
            <span>Disable all <strong>LA</strong> products </span>
          </button>

          <mat-divider></mat-divider>

          <button (click)="enableAll()" mat-menu-item>
            <span>Enable all products</span>
          </button>

          <button (click)="disabledAll()" mat-menu-item>
            <span>Disable all products</span>
          </button>
        </mat-menu>

        <button class="ml mr pull-right" *ngIf="isEdit == true && merchant['Allow_AssistBrrowerAtPOS'] != '1'"
                [style.background]="'#58AD69'" [style.color]="'#fff'" (click)="enableAssistance()" mat-raised-button
                [matTooltip]="'Enable ' + ('KEYWORD.patient' | translate | titlecase) + ' Assistance'">
          Enable Assistance
        </button>

        <button class="ml mr pull-right" *ngIf="isEdit == true && merchant['Allow_AssistBrrowerAtPOS'] == '1'"
                [style.background]="'#ee1023'" [style.color]="'#fff'" (click)="disableAssistance()" mat-raised-button
                [matTooltip]="'Disabled ' + ('KEYWORD.patient' | translate | titlecase) + ' Assistance'">
          Disabled Assistance
        </button>

        <button class="btn mr ml pull-right" [matMenuTriggerFor]="ActionBtnmenuSelect" mat-raised-button
                color="primary">
          <mat-icon class="fas fa-file-contract smr"></mat-icon>
          <span class="sml">Terms & Conditions</span>
        </button>
        <mat-menu #ActionBtnmenuSelect="matMenu">
          <button (click)="termsView()" mat-menu-item>
            <mat-icon class="smr">visibility</mat-icon>
            View
          </button>
          <button (click)="termsEdit()" mat-menu-item>
            <mat-icon class="smr">edit</mat-icon>
            Edit
          </button>
        </mat-menu>
      </div>

      <div class="row clearfix extra-mt">
        <div class="full-width">
          <div class="clearfix full-width module-card-flex row">
            <div class="thrd-width flex row card-container" *ngFor="
                let a of products
                  | OrderArrayObject: 'Label'
                  | FilterArrayMultipleValue: ['isActive']:[isActiveFilter]
                  | FilterArrayMultipleContainValue
                    : ['Description', 'Label', 'TandCs.Essentials', 'SubType']
                    : filterName;
                let index = index
              ">
              <mat-card class="card module-card full-width ml mr clearfix" id="module-card-tips"
                        [class.disabledModule]="a.isActive != true">
                <div class="row clearfix full-width">
                  <h3 class="sr-title sm-h3 rm-mt rm-mb">
                    {{ a['Label'] }}

                    <mat-slide-toggle class="pull-right" *ngIf="isEdit == true" [(ngModel)]="a['isActive']"
                                      [disabled]="merchant['Allow_AssistBrrowerAtPOS'] != '1'"
                                      (change)="sliderChange($event, a['ID'])"
                                      name="{{ a['ID'] }}+{{ index }}">
                      <span class="chip2 chipGood" *ngIf="a.isActive == true">Enabled</span>

                      <span class="chip2 chipBad" *ngIf="a.isActive != true">Disabled</span>
                    </mat-slide-toggle>

                    <span class="chip2 chipGood" *ngIf="a.isActive == true && isEdit != true">Enabled</span>

                    <span class="chip2 chipBad" *ngIf="a.isActive != true && isEdit != true">Disabled</span>
                  </h3>
                  <p class="small rm-m">
                    {{ a['SubType'] }}
                  </p>

                  <div class="mt" [innerHTML]="a.Description | safeHtml"></div>
                </div>
              </mat-card>
            </div>
          </div>
        </div>
      </div>
    </mat-dialog-content>
  </div>
</div>

<div *ngIf="!products || (products && products.length <= 0)">
  <app-empty-list-message [title]="'No Product found'" [message]="'You do not have any active product'">
  </app-empty-list-message>
</div>
