<div class="row clearfix patientRecord column" *ngIf="product">
  <div class="row clearfix flex">
    <div class="icons-col">
      <app-product-view-logo class="informed-consent-picture prod informed-consent text-center pull-left mr"
                             *ngIf="product" [productID]="product['ID']" [LastModified]="product['LastModified']">
      </app-product-view-logo>
    </div>
    <div class="row-content-col">
      <h3 class="sr-title reduce-marg text-left clicktext" (click)="viewProduct(product['ID'])">
        {{ treatmentUtil.getLabel(product.Label) }}
      </h3>

      <div class="row clearfix flex wrapped">
        <div class="block full-width clicktext mr" *ngIf="product['Category.Label']"
             matTooltip="Click to view Category">
          <p class="small text-left rm-m">Category</p>
          <h5 class="sr-title text-left reduce-marg" (click)="viewCategory(product['Category_key'])"
              style="color: rgba(0, 0, 0, 0.87)">
            {{ treatmentUtil.getLabel(product['Category.Label']) }}
          </h5>
        </div>

        <div class="block full-width clicktext mr" *ngIf="product['Type.Label']" matTooltip="Click to view Type">
          <p class="small text-left rm-m">Type</p>
          <h5 class="sr-title text-left reduce-marg" (click)="viewType(product['Type_key'])"
              style="color: rgba(0, 0, 0, 0.87)">
            {{ treatmentUtil.getLabel(product['Type.Label']) }}
          </h5>
        </div>

        <div class="block full-width clicktext" *ngIf="product['Brand.Label']" matTooltip="Click to view Brand">
          <div class="row-content">
            <p class="small text-left rm-m">Brand</p>
            <h5 class="sr-title text-left reduce-marg" (click)="viewBrand(product['Brand_key'])"
                style="color: rgba(0, 0, 0, 0.87)">
              {{ treatmentUtil.getLabel(product['Brand.Label']) }}
            </h5>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="full-width">
    <div class="row clearfix add-mb" *ngIf="
        (preAllDocuments.length > 0 ||
          stockArtAllDocuments.length > 0 ||
          postAllDocuments.length > 0 ||
          marketingAllDocuments.length > 0) &&
        isMiniList != true
      ">
      <div class="clearfix treatment-informed-consent-view-file-list full-width"
           *ngIf="preAllDocuments.length > 0 && displayPreDocuments == true">
        <div class="full-width doc-title mt smb">
          <div class="full-width">
            <p class="pull-left">Pre documents</p>
          </div>

          <div class="full-width">
            <hr/>
          </div>
        </div>
        <app-ep-document-list [listViewType]="DocumentlistViewType" [isDownload]="isDownload" [isPrint]="isPrint"
                              [displayViewButton]="false" [defaultCheck]="true" [isRedirection]="isRedirection"
                              [documents]="preAllDocuments" [requiredIDs]="preRequiredDocuments" [title]="''"
                              [canCheck]="false"
                              [selectedIDs]="preSelectedDocuments" [justify]="justify" [isModal]="isModal"
                              [isPreviewModal]="isPreviewModal">
        </app-ep-document-list>
      </div>
      <div class="clearfix treatment-informed-consent-view-file-list full-width"
           *ngIf="postAllDocuments.length > 0 && displayPostDocuments == true">
        <div class="full-width doc-title mt smb">
          <div class="full-width">
            <p class="pull-left">Post documents</p>
          </div>

          <div class="full-width">
            <hr/>
          </div>
        </div>
        <app-ep-document-list [listViewType]="DocumentlistViewType" [isDownload]="isDownload" [isPrint]="isPrint"
                              [displayViewButton]="false" [defaultCheck]="true" [isRedirection]="isRedirection"
                              [documents]="postAllDocuments" [requiredIDs]="postRequiredDocuments" [title]="''"
                              [canCheck]="false"
                              [selectedIDs]="postSelectedDocuments" [justify]="justify" [isModal]="isModal"
                              [isPreviewModal]="isPreviewModal">
        </app-ep-document-list>
      </div>

      <div class="clearfix treatment-informed-consent-view-file-list full-width"
           *ngIf="marketingAllDocuments.length > 0 && displayMarketingDocuments == true">
        <div class="full-width doc-title mt smb">
          <div class="full-width">
            <p class="pull-left">Marketing documents</p>
          </div>

          <div class="full-width">
            <hr/>
          </div>
        </div>
        <app-ep-document-list [listViewType]="DocumentlistViewType" [isDownload]="isDownload" [isPrint]="isPrint"
                              [displayViewButton]="false" [defaultCheck]="true" [isRedirection]="isRedirection"
                              [documents]="marketingAllDocuments" [requiredIDs]="marketingRequiredDocuments"
                              [title]="''" [canCheck]="false"
                              [selectedIDs]="marketingSelectedDocuments" [justify]="justify" [isModal]="isModal"
                              [isPreviewModal]="isPreviewModal">
        </app-ep-document-list>
      </div>

      <div class="clearfix treatment-informed-consent-view-file-list full-width"
           *ngIf="stockArtAllDocuments.length > 0 && displayStockArtDocuments == true">
        <div class="full-width doc-title mt smb">
          <div class="full-width">
            <p class="pull-left">Pre documents</p>
          </div>

          <div class="full-width">
            <hr/>
          </div>
        </div>
        <app-ep-document-list [listViewType]="DocumentlistViewType" [isDownload]="isDownload" [isPrint]="isPrint"
                              [displayViewButton]="false" [defaultCheck]="true" [isRedirection]="isRedirection"
                              [documents]="stockArtAllDocuments" [requiredIDs]="stockArtRequiredDocuments" [title]="''"
                              [canCheck]="false"
                              [selectedIDs]="stockArtSelectedDocuments" [justify]="justify" [isModal]="isModal"
                              [isPreviewModal]="isPreviewModal">
        </app-ep-document-list>
      </div>
    </div>
    <div class="row clearfix add-mb" *ngIf="
        (preAllDocuments.length > 0 ||
          stockArtAllDocuments.length > 0 ||
          postAllDocuments.length > 0 ||
          marketingAllDocuments.length > 0) &&
        isMiniList == true
      ">
      <div class="clearfix treatment-informed-consent-view-file-list full-width"
           *ngIf="preAllDocuments.length > 0 && displayPreDocuments == true">
        <div class="full-width doc-title mt smb">
          <div class="full-width">
            <p class="pull-left">{{ "KEYWORD.procedure" | translate | titlecase }} documents</p>
          </div>

          <div class="full-width">
            <hr/>
          </div>
        </div>
        <app-ep-document-list-mini class="white-title" [displayStatus]="true"
                                   [instructionLine]="treatmentInstructionLine" [displayStatus]="false" [title]="''"
                                   [displayDate]="false"
                                   [documents]="preAllDocuments" [isDownload]="false" [displayViewedDate]="false"
                                   [isFilter]="false"
                                   [view]="'consumer'">
        </app-ep-document-list-mini>
      </div>

      <div class="clearfix treatment-informed-consent-view-file-list full-width"
           *ngIf="postAllDocuments.length > 0 && displayPostDocuments == true">
        <div class="full-width doc-title mt smb">
          <div class="full-width">
            <p class="pull-left">Post documents</p>
          </div>

          <div class="full-width">
            <hr/>
          </div>
        </div>
        <app-ep-document-list-mini class="white-title" [displayStatus]="true"
                                   [instructionLine]="treatmentInstructionLine" [displayStatus]="false" [title]="''"
                                   [displayDate]="false"
                                   [documents]="postAllDocuments" [isDownload]="false" [displayViewedDate]="false"
                                   [isFilter]="false"
                                   [view]="'consumer'">
        </app-ep-document-list-mini>
      </div>

      <div class="clearfix treatment-informed-consent-view-file-list full-width"
           *ngIf="marketingAllDocuments.length > 0 && displayMarketingDocuments == true">
        <div class="full-width doc-title mt smb">
          <div class="full-width">
            <p class="pull-left">Marketing documents</p>
          </div>

          <div class="full-width">
            <hr/>
          </div>
        </div>
        <app-ep-document-list-mini class="white-title" [displayStatus]="true"
                                   [instructionLine]="treatmentInstructionLine" [displayStatus]="false" [title]="''"
                                   [displayDate]="false"
                                   [documents]="marketingAllDocuments" [isDownload]="false" [displayViewedDate]="false"
                                   [isFilter]="false"
                                   [view]="'consumer'">
        </app-ep-document-list-mini>
      </div>

      <div class="clearfix treatment-informed-consent-view-file-list full-width"
           *ngIf="stockArtAllDocuments.length > 0 && displayStockArtDocuments == true">
        <div class="full-width doc-title mt smb">
          <div class="full-width">
            <p class="pull-left">{{ "KEYWORD.procedure" | translate | titlecase }} Gallery</p>
          </div>

          <div class="full-width">
            <hr/>
          </div>
        </div>
        <app-ep-document-list-mini class="white-title" [displayStatus]="true"
                                   [instructionLine]="treatmentInstructionLine" [displayStatus]="false" [title]="''"
                                   [displayDate]="false"
                                   [documents]="stockArtAllDocuments" [isDownload]="false" [displayViewedDate]="false"
                                   [isFilter]="false"
                                   [view]="'consumer'">
        </app-ep-document-list-mini>
      </div>
    </div>
  </div>
</div>
