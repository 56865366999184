import { createReducer, on } from '@ngrx/store';
import { setCurrentDentistAction, setCurrentIsPromoterOrAdminAction, setCurrentPracticeAction, setCurrentPublicModulesAction, setCurrentPublicSettingsAction, setCurrentSessionTypeAction, setCurrentUserAction, } from './app-state.actions';
import { initialDentist, initialIsPromoterOrAdmin, initialPractice, initialPublicModules, initialPublicSettings, initialSessionType, initialUser, } from './app-state.state';
const ɵ0 = (state, action) => {
    const practice = action.practice;
    if (practice === undefined) {
        return state;
    }
    else {
        return practice;
    }
};
const originalCurrentPracticeReducer = createReducer(initialPractice, on(setCurrentPracticeAction, ɵ0));
export function currentPracticeReducer(state, action) {
    return originalCurrentPracticeReducer(state, action);
}
const ɵ1 = (state, action) => {
    const user = action.user;
    if (user === undefined) {
        return state;
    }
    else {
        return user;
    }
};
const originalCurrentUserReducer = createReducer(initialUser, on(setCurrentUserAction, ɵ1));
export function currentUserReducer(state, action) {
    return originalCurrentUserReducer(state, action);
}
const ɵ2 = (state, action) => action.sessionType;
export const originalCurrentSessionTypeReducer = createReducer(initialSessionType, on(setCurrentSessionTypeAction, ɵ2));
export function currentSessionTypeReducer(state, action) {
    return originalCurrentSessionTypeReducer(state, action);
}
const ɵ3 = (state, action) => action.isPromoterOrAdmin;
export const originalCurrentIsPromoterOrAdminReducer = createReducer(initialIsPromoterOrAdmin, on(setCurrentIsPromoterOrAdminAction, ɵ3));
export function currentIsPromoterOrAdminReducer(state, action) {
    return originalCurrentIsPromoterOrAdminReducer(state, action);
}
const ɵ4 = (state, action) => action.dentist;
export const originalCurrentDentistReducer = createReducer(initialDentist, on(setCurrentDentistAction, ɵ4));
export function currentDentistReducer(state, action) {
    return originalCurrentDentistReducer(state, action);
}
const ɵ5 = (state, action) => action.publicSettings;
export const originalCurrentPublicSettingsReducer = createReducer(initialPublicSettings, on(setCurrentPublicSettingsAction, ɵ5));
export function currentPublicSettingsReducer(state, action) {
    return originalCurrentPublicSettingsReducer(state, action);
}
const ɵ6 = (state, action) => action.publicModules;
export const originalCurrentPublicModulesReducer = createReducer(initialPublicModules, on(setCurrentPublicModulesAction, ɵ6));
export function currentPublicModulesReducer(state, action) {
    return originalCurrentPublicModulesReducer(state, action);
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6 };
