<div class="clearfix" *ngIf="isAdminOrPromoter == true">
  <div class="row clearfix selectMerchant">
    <button class="pull-right merchBtn"
      [routerLink]="['/merchant/', { outlets: { page: ['staff-list-map-overview'] } }]" [routerLinkActive]="['active']"
      mat-raised-button color="accent">
      <mat-icon>public</mat-icon>
      Staff Members Map
    </button>
    <button class="pull-right merchBtn mr ml" [matMenuTriggerFor]="menuListsasaS"
      *ngIf="isAdminOrPromoter==true  || isMerchantAdmin==true" mat-raised-button color="primary">
      <mat-icon>person_add</mat-icon> Add a Staff Member
    </button>

    <mat-menu #menuListsasaS="matMenu">
      <button (click)="createNewContact()" mat-menu-item>
        <strong>Without</strong> Sign-in Account
      </button>
      <hr />
      <button [routerLink]="['/merchant/', { outlets: { page: ['dentist-invite-create'] } }]" mat-menu-item>
        <strong>With</strong> Sign-in Account
      </button>

    </mat-menu>


    <button class="pull-right merchBtn" *ngIf="merchantID && merchantID != 'none'" (click)="viewMerchant()"
      mat-raised-button color="primary">
      <mat-icon>person</mat-icon>
      <span class="mobHide"> View {{ 'KEYWORD.practice' | translate }} </span>
    </button>

    <mat-form-field class="margin-btm half-width" appearance="outline">
      <mat-label>Please Select a Merchant</mat-label>
      <mat-select class=" " [(ngModel)]="merchantID" name="MerchantName" placeholder="Please Select a Merchant">
        <mat-option [value]="'none'">
          Please Select Merchant
        </mat-option>

        <!-- <mat-option [value]="null">
          All
        </mat-option> -->
        <mat-option *ngFor="let m of merchants | orderBy: ['TradingAs', 'CalculatedName']" [value]="m['ID']">
          <span class="list-label pull-right" *ngIf="m['Status'] && m['Status'] == 'Active'"
            [style.background]="'#00d07a'">
            {{ m['Status'] }}
          </span>

          <span class="list-label pull-right" *ngIf="m['Status'] && m['Status'] != 'Active'"
            [style.background]="'#ff5722'">
            {{ m['Status'] }}
          </span>
          {{ m['TradingAs'] || m['CalculatedName'] }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="row full-width clearfix" *ngIf="merchantID != 'none'">
    <app-dentist-list [merchantID]="merchantID"></app-dentist-list>
  </div>

  <div class="row clearfix" [hidden]="merchantID != 'none'">
    <div class="pleaseChoose" *ngIf="merchantID == 'none'" @ngIfAnimation>
      <p>
        <strong>No merchant selected</strong>
      </p>
      <img class="character_warning" />
      <p>
        Choose your desired merchant from the drop-down above to view their
        {{ "KEYWORD.patient" | translate }}s
      </p>
    </div>
  </div>
</div>

<div class="clearfix merchViewList" *ngIf="isAdminOrPromoter == false">
  <div class="pos-right">
    <button class="pull-right merchBtn"
      [routerLink]="['/merchant/', { outlets: { page: ['staff-list-map-overview'] } }]" [routerLinkActive]="['active']"
      mat-raised-button color="accent">
      <mat-icon>public</mat-icon>
      Staff Members Map
    </button>

    <button class="pull-right merchBtn mr ml" [matMenuTriggerFor]="menuListsasaS"
      *ngIf="isAdminOrPromoter==true  || isMerchantAdmin==true" mat-raised-button color="primary">
      <mat-icon>person_add</mat-icon> Add a Staff Member
    </button>

    <mat-menu #menuListsasaS="matMenu">
      <button (click)="createNewContact()" mat-menu-item>
        Staff Member <strong>Without</strong> Sign-in Account
      </button>
      <hr />
      <button [routerLink]="['/merchant/', { outlets: { page: ['dentist-invite-create'] } }]" mat-menu-item>
        Staff Member <strong>With</strong> Sign-in Account
      </button>

    </mat-menu>



    <!-- <button mat-raised-button color="primary" class="pull-right mr" [routerLink]="['/merchant/', {outlets: {'page': ['dentist-invite-create']}}]"
      [routerLinkActive]="['active']">
      <mat-icon>person_addd</mat-icon> Add a Staff Member
    </button> -->
  </div>
  <app-dentist-list class="clearfix full-width" id="part1"></app-dentist-list>
</div>
