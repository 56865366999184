import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { combineLatest, EMPTY, of } from 'rxjs';
import { debounceTime, expand, map, switchMap, tap } from 'rxjs/operators';
import { environment } from '../../../../../../../src/environments/environment';
import { AppStateService } from '../../../../../core/app-state/app-state.service';
import { AppState } from '../../../../../core/app-state/app-state.type';
import { STANDARD_KEYBOARD_DEBOUNCE_TIME } from '../../../../../shared/config/STANDARD_KEYBOARD_DEBOUNCE_TIME';
import { NotifyAppComponent } from '../../../../../shared/types/notify-app-component';
import { UtilsClass } from '../../../../../shared/types/utils/utils.class';
import { treatmentUtil } from '../../../../treatment/shared/treatmentUtil';
import {
  ProductCatalogueItem,
  ProductCatalogueQuery,
  ProductCatalogueService,
} from '../../../shared/services/product-catalogue.service';
import {
  ProductFormModalComponent,
  ProductFormModalConfig,
} from '../../modals/product-form.modal.ts/product-form.modal.ts.component';
import { ViewProductModalComponent, ViewProductModalConfig } from '../../modals/view-product.modal/view-product.modal';

@Component({
  selector: 'ipv-retail-product-list',
  templateUrl: './retail-product-list.component.html',
  styleUrls: ['./retail-product-list.component.css'],
})
export class RetailProductListComponent {
  @Output() productSelected: EventEmitter<ProductCatalogueItem> = new EventEmitter<ProductCatalogueItem>();

  @Input()
  isActive;

  @Input()
  supplierID;

  @Input()
  merchantID;

  @Input()
  manufacturerID;

  @Input()
  distributorID;

  @Input()
  typeID;

  @Input()
  categoryID;

  @Input()
  brandID;

  @Input()
  modalTitle = '';

  @Input()
  pageMode: 'page' | 'modal' = 'page';

  @Output()
  getLength = new EventEmitter();

  isFetchingInitialData = true;

  treatmentUtil = new treatmentUtil();
  utils = new UtilsClass();

  titleIcon = null;
  title = 'Products';

  hiddenColumns = [
    'ID',
    'DateTimeCreated',
    'LastModified',
    'LastModified_Human',
    'Description',
    'Supplier_key',
    'Product_Type_key',
    'Product_Category_key',
    'Size',
    'Colour',
    'Weight',
    'Width',
    'Height',
    'Length',
    'CostPrice',
    'TaxRate',
    'Product',
    'Model',
    'Thumbnail_Doc_key',
    'ProfileImage_Doc_key',
    'AdditionalImages_Doc_key',
    'Manufactured_Year',
  ];

  renamedColumns = {
    logo: 'Logo',
    Stock_Keeping_Unit: 'SKU',
    Universal_Product_Code: 'UPC',
    Is_Available: 'Available',
    SellPrice: 'Sell Price',
    Product: 'Product Label',
    Is_SerialNum_Mandatory: 'Serialised',
  };

  order = [
    'logo',
    'Model',
    'Product',
    'Stock_Keeping_Unit',
    'Universal_Product_Code',
    'Is_Available',
    'Is_SerialNum_Mandatory',
    'SellPrice',
  ];

  viewModel$ = this.appStateService.getAppState$().pipe(
    tap(console.log),
    switchMap((appState) =>
      this.getFilters$().pipe(
        switchMap(([isAvailableFilter, productFilter, modelFilter, upcFilter, skuFilter]) =>
          this.fetchData$({
            payload: {
              Merchant_key: appState.user.data.practiceKey,
              Is_Available: isAvailableFilter,
              Product: productFilter,
              Model: modelFilter,
              Universal_Product_Code: upcFilter,
              Stock_Keeping_Unit: skuFilter,
            },
          }).pipe(
            switchMap(() => this.getData$()),
            switchMap(([appliances]) =>
              this.getViewModel$({
                appState,
                appliances,
                isAvailableFilter,
                productFilter,
                modelFilter,
                upcFilter,
                skuFilter,
              })
            )
          )
        )
      )
    )
  );

  constructor(
    private applianceService: ProductCatalogueService,
    private dialog: MatDialog,
    private appStateService: AppStateService
  ) {}

  getFilters$() {
    return combineLatest([
      this.getIsActiveFilter$(),
      this.getProductFilter$(),
      this.getModelFilter$(),
      this.getUpcFilter$(),
      this.getSkuFilter$(),
    ]);
  }

  fetchData$(parameters: ProductCatalogueQuery) {
    return combineLatest([this.fetchAppliancesSections$(parameters)]);
  }

  getIsActiveFilter$() {
    return this.applianceService.getIsAvailableFilter$();
  }

  setIsAvailableFilter(isAvailable: '0' | '1') {
    this.applianceService.setIsAvailableFilter(isAvailable);
  }

  getProductFilter$() {
    return this.applianceService.getProductLabelFilter$().pipe(debounceTime(STANDARD_KEYBOARD_DEBOUNCE_TIME));
  }

  setProductFilter(product: string) {
    this.applianceService.setProductLabelFilter(product);
  }

  getModelFilter$() {
    return this.applianceService.getModelFilter$().pipe(debounceTime(STANDARD_KEYBOARD_DEBOUNCE_TIME));
  }

  setModelFilter(model: string) {
    this.applianceService.setModelFilter(model);
  }

  getSkuFilter$() {
    return this.applianceService.getSkuFilter$().pipe(debounceTime(STANDARD_KEYBOARD_DEBOUNCE_TIME));
  }

  setSkuFilter(sku: string) {
    this.applianceService.setSkuFilter(sku);
  }

  getUpcFilter$() {
    return this.applianceService.getUpcFilter$().pipe(debounceTime(STANDARD_KEYBOARD_DEBOUNCE_TIME));
  }

  setUpcFilter(upc: string) {
    this.applianceService.setUpcFilter(upc);
  }

  fetchAppliancesSections$(parameters: ProductCatalogueQuery) {
    this.applianceService.setProductCatalogue([]);

    const query: ProductCatalogueQuery = {
      payload: Object.assign(
        {
          section: 0,
          Supplier_key: this.supplierID,
        },
        parameters.payload
      ),
    };

    return this.applianceService.fetchProductCatalogue$<Partial<ProductCatalogueItem>>(query.payload).pipe(
      tap(() => {
        UtilsClass.stopLoadingInterceptor();
        query.payload.section++;
        UtilsClass.loadingDataSection(query.payload.section);
      }),
      expand((appliancesSection) =>
        appliancesSection.length > 0
          ? this.applianceService.fetchProductCatalogue$<Partial<ProductCatalogueItem>>(query.payload).pipe(
              tap(() => {
                UtilsClass.stopLoadingInterceptor();
              })
            )
          : EMPTY
      ),
      tap((appliancesSection) => {
        if (appliancesSection.length > 0) {
          query.payload.section++;
          UtilsClass.loadingDataSection(query.payload.section);
          const appliances = this.applianceService.getProductCatalogue();
          const newAppliances = appliances.concat(appliancesSection);
          this.applianceService.setProductCatalogue(newAppliances);
        }
      })
    );
  }

  getData$() {
    return combineLatest([this.getAppliances$()]);
  }

  getAppliances$() {
    return this.applianceService.getProductCatalogue$<Partial<ProductCatalogueItem>>().pipe(
      map((appliances: ProductCatalogueItem[]) => {
        return appliances.map((appliance) => {
          return {
            logo: '',
            ...appliance,
          };
        });
      })
    );
  }

  getViewModel$(parameters: {
    appState: AppState;
    appliances: Partial<ProductCatalogueItem>[];
    isAvailableFilter: string;
    productFilter: string;
    modelFilter: string;
    upcFilter: string;
    skuFilter: string;
  }) {
    const { appState, isAvailableFilter, productFilter, modelFilter, upcFilter, skuFilter } = parameters;
    let { appliances } = parameters;
    this.isFetchingInitialData = false;
    const { user, practice, sessionType, isPromoterOrAdmin, dentist } = appState;

    appliances = [
      {
        ID: '',
        DateTimeCreated: '',
        LastModified: '',
        LastModified_Human: '',
        Product: 'Product Name',
        Model: 'Model Number',
        Product_Type_key: '',
        Stock_Keeping_Unit: 'skuKey',
        Universal_Product_Code: 'upcKey',
        SellPrice: 0,
        CostPrice: 0,
        TaxRate: 0,
        Is_SerialNum_Mandatory: '1',
        Is_Available: '0',
        Size: '',
        Colour: '',
        Weight: '',
        Width: '',
        Height: '',
        Length: '',
        Manufactured_Year: '',
        Thumbnail_Doc_key: '',
        ProfileImage_Doc_key: '',
        AdditionalImages_Doc_key: '1|2|3|4',
        Description: 'hello world',
        Supplier_key: 'FDAEE08E8FECFD4EAF47B96FF5937ACA',
      },
    ];

    console.log(appliances);

    const result = {
      user,
      practice,
      sessionType,
      isPromoterOrAdmin,
      dentist,
      appliances,
      isAvailableFilter,
      productFilter,
      modelFilter,
      upcFilter,
      skuFilter,
      industryType: appState.publicSettings.industryType,
    };
    this.getLength.emit(appliances.length);
    return of(result);
  }

  viewProduct(product: ProductCatalogueItem, industryType: string) {
    const ref = this.dialog.open<ViewProductModalComponent, ViewProductModalConfig>(ViewProductModalComponent, {
      data: {
        product,
        industryType,
      },
      width: '800px',
    });
  }

  viewProductSelect(product: ProductCatalogueItem, industryType: string) {
    if (this.pageMode === 'page') {
      const ref = this.dialog.open<ViewProductModalComponent, ViewProductModalConfig>(ViewProductModalComponent, {
        data: {
          product,
          industryType,
        },
        width: '800px',
      });
    } else if (this.pageMode === 'modal') {
      this.productSelected.emit(product);
    }
  }

  updateProduct(product: ProductCatalogueItem, industryType: string) {
    const ref = this.dialog.open<ProductFormModalComponent, ProductFormModalConfig>(ProductFormModalComponent, {
      data: {
        existingProduct: product,
        industryType,
        formMode: 'update',
      },
      width: '800px',
    });
  }

  add(industryType: string) {
    const ref = this.dialog.open<ProductFormModalComponent, ProductFormModalConfig>(ProductFormModalComponent, {
      data: { industryType, selectedSupplierKey: this.supplierID },
      width: '800px',
    });
  }

  makeAvailable(appliance: Partial<ProductCatalogueItem> | ProductCatalogueItem) {
    this.applianceService
      .updateProductCatalogueItem$(appliance, { Is_Available: '1' })
      .pipe(
        switchMap((updatedAppliance) => {
          return this.applianceService.editProductCatalogueItem$(updatedAppliance);
        })
      )
      .subscribe(() => {
        NotifyAppComponent.displayToast('success', 'Successful operation', 'Successfully updated product');
      });
  }

  getThumbnailImage(product: ProductCatalogueItem) {
    return `${environment.nodeUrl}/files/file-view/${product.Thumbnail_Doc_key}`;
  }

  makeUnavailable(appliance: Partial<ProductCatalogueItem> | ProductCatalogueItem) {
    this.applianceService
      .updateProductCatalogueItem$(appliance, { Is_Available: '0' })
      .pipe(
        switchMap((updatedAppliance) => {
          return this.applianceService.editProductCatalogueItem$(updatedAppliance);
        })
      )
      .subscribe(() => {
        NotifyAppComponent.displayToast('success', 'Successful operation', 'Successfully updated product');
      });
  }
}
