<div class="full-width clearfix row" *ngIf="title || description" @slideInOut>
  <div class="pull-right closeModal">
    <button class="btn-close btn-clear mat-button" *ngIf="isModal == true" (click)="closeEvent()">
      <mat-icon>clear</mat-icon>
    </button>
  </div>

  <h1 class="dataHeader" *ngIf="title">
    <!-- Contracts -->
    {{ title }}
  </h1>

  <p class="lead rm mb" *ngIf="description">{{ description }}</p>
</div>

<div class="full-width clearfix row">
  <mat-card class="toggle-card mt mb clearfix">
    <div class="row clearfix" *ngIf="isCreditCard == true && isBank == true" @slideInOut>
      <mat-button-toggle-group
        class="btn-group row clearfix flex full-width text-center"
        [(ngModel)]="type"
        (ngModelChange)="validateEvent()"
      >
        <mat-button-toggle class="text-center full-width" [value]="'bank'">
          <mat-icon class="fa fa-university"></mat-icon>
          Pay by Cheque or Savings Account
        </mat-button-toggle>

        <mat-button-toggle class="text-center full-width" *ngIf="isCreditCard == true" [value]="'creditCard'">
          <mat-icon class="fa fa-money-check"></mat-icon>
          Pay by Debit/Credit Card
        </mat-button-toggle>
      </mat-button-toggle-group>
      <hr class="mb"/>
    </div>

    <div class="row clearfix full-width tabArea" *ngIf="isCreditCard == true && type == 'creditCard'" @slideInOut>
      <div class="thrd-width">
        <p class="lead mb rm-mt">Pay by Credit Card</p>
        <p class="mt">
          Your repayments will be processed on the due date by entering your debit/credit card information in the
          following fields.
        </p>
        <p class="mt" *ngIf="isBank == true">
          Alternatively, we can process your repayments from a Cheque or Savings bank account via direct debit. Press
          the 'Cheque or Savings Account' tab above
        </p>
        <div class="row flex flex-center" *ngIf="isAction == false">
          <p class="small mb mr">Accepted cards:</p>
          <div class="card-type visa" matTooltip="Visa"></div>
          <div class="card-type mastercard" matTooltip="Mastercard"></div>
        </div>
      </div>

      <div class="two-thrd-width">
        <app-credit-card-input
          [title]="titleCreditCard"
          [isRequired]="isRequired"
          [cardNumber]="cardNumber"
          [cardName]="cardName"
          [cardExpireDate]="cardExpireDate"
          [cardCVS]="cardCVS"
          (getCreditCard)="getCreditCardEvent($event)"
          (validate)="validateCreditCard($event)"
        ></app-credit-card-input>
      </div>
    </div>

    <div class="row clearfix full-width tabArea" *ngIf="isBank == true && type == 'bank'" @slideInOut>
      <div class="thrd-width">
        <p class="lead mb rm-mt">Pay by Bank Deposit</p>
        <p class="mb">
          Your repayments will be processed on the due date by entering your bank account information in the following
          fields via direct debit.
        </p>
        <p class="mt" *ngIf="isCreditCard == true">
          Alternatively, we can process your repayment on your debit or credit card. Press the 'Debit/Credit Card' tab
          above.
        </p>
      </div>

      <div class="two-thrd-width">
        <app-bank-account-input
          [title]="titleBank"
          [isRequired]="isRequired"
          [bankBSB]="bankBSB"
          [bankName]="bankName"
          [accountType]="accountType"
          [bankBranch]="bankBranch"
          [accountName]="accountName"
          [accountNumber]="accountNumber"
          (getBankAccount)="getBankAccountEvent($event)"
          (validate)="validateBank($event)"
        ></app-bank-account-input>
      </div>
    </div>

    <div class="row clearfix mt full-width text-center" *ngIf="isAction == true" @slideInOut>
      <div class="margin-div">
        <div class="pull-left flex flex-center" *ngIf="isCreditCard == true && type == 'creditCard'" @slideInOut>
          <p class="small mb mr">Accepted cards:</p>
          <div class="card-type visa" matTooltip="Visa"></div>
          <div class="card-type mastercard" matTooltip="Mastercard"></div>
        </div>

        <button class="btn-large pull-right" (click)="getActionEvent()" mat-raised-button color="accent">
          {{ actionLabel }}
          <mat-icon>keyboard_arrow_right</mat-icon>
        </button>
      </div>
    </div>

    <div class="row clearfix mt">
      <p class="small">
        By clicking 'Save', you authorise the direct debit and agree to the terms of the
        <a class="primary-color" (click)="ddrModal()">Direct Debit Request and the Direct Debit Service Agreement</a>
      </p>
    </div>
  </mat-card>
</div>
