<div class="rel text-center">
  <mat-card class="card">
    <div class="row clearfix">
      <div class="pull-right">
        <!-- Modal dismiss button -->
        <button class="btn-close btn-clear mat-button" (click)="onClose()">
          <mat-icon>clear</mat-icon>
        </button>
      </div>
    </div>
    <hr/>
    <div class="colour-stripe"></div>
    <p>
      <img class="animated zoomIn feature-img character_error"/>
    </p>
    <p class="lead">Your Current Browser Is Outdated!</p>
    <p>We Detect that You are using : Internet Explorer</p>
    <p>The site may not be running properly on this browser , We recommend using a newer one for optimal experience</p>
    <p>Please <a href="http://outdatedbrowser.com/en" target="_blank">click here</a> for lastest versions.</p>
    <hr/>
    <mat-checkbox [(ngModel)]="dontShowBrowserWarning">Please don't show again</mat-checkbox>
  </mat-card>
  <button class="btn-large" (click)="onClose()" mat-raised-button color="accent">Continue</button>
</div>
