<mat-list [hidden]="displayMerchantTasks == true">
  <mat-list-item class="row clearfix flex sticky primary-gradient-img">
    <h3 class="full-width">Merchants</h3>
    <h3 class="full-width" *ngFor="let task of tasks">{{ task['Name'] }}</h3>
  </mat-list-item>
  <mat-divider></mat-divider>

  <mat-list-item
    class="row clearfix flex clickList"
    *ngFor="let item of listObject | objectPropertyValue: 'Card_key' | unique; let i = index"
    [ngStyle]="{ 'background-color': i % 2 == 0 ? '#fff' : '#e3e3e3' }"
    (click)="openMerchantTask(item)"
  >
    <p class="full-width">{{ getMerchantName(item) }}</p>
    <p class="full-width" *ngFor="let task of getTask(item, listObject)">
      <span class="list-label smaller" *ngIf="task['IsCompleted'] == '1'" style="background-color: rgb(90, 186, 71)">
        <mat-icon>check</mat-icon>
      </span>
      <span class="list-label smaller" *ngIf="task['IsCompleted'] != '1'" style="background-color: rgb(239, 71, 39)">
        <mat-icon>close</mat-icon>
      </span>
    </p>
  </mat-list-item>
</mat-list>

<div class="clearfix" *ngIf="merchantID && displayMerchantTasks == true">
  <app-tasks-list *ngIf="merchantID != 'none'" [merchantID]="merchantID"></app-tasks-list>

  <div class="full-width text-center">
    <button class="mb mt pull-left" (click)="hideMerchantTask()" mat-raised-button color="primary">
      <mat-icon>keyboard_arrow_left</mat-icon>
      Onboarding Tasks
    </button>
  </div>
</div>
