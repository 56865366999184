<div class="row clearfix text-center starRating">
  <p class="small">
    Your {{ "KEYWORD.patient" | translate }}s have been giving your {{ "KEYWORD.practice" | translate }} an average
    rating of:
  </p>
</div>
<div class="row clearfix text-center starRating starWell">
  <a class="starList" *ngFor="let item of starList; let i = index" (click)="setStar(i)">
    <i class="fa" [ngClass]="{ 'fa-star-o': item, 'fa-star': !item }"></i>
  </a>
  <p class="lead">{{ rating }} stars</p>
</div>
<div class="row clearfix text-center starRating">
  <button class="btn-clear btn-small btnImprove" mat-button>
    <mat-icon>help_outline</mat-icon>
    How can I improve this?
  </button>
</div>
