<div id="loader">
  <mat-progress-bar [color]="'primary'" [value]="50" [bufferValue]="75" mode="query"></mat-progress-bar>

  <div class="lds-ring-main">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</div>

<div id="loaderForce">
  <mat-progress-bar [color]="'primary'" [value]="50" [bufferValue]="75" mode="query"></mat-progress-bar>

  <div class="lds-ring-main">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</div>

<div id="globalUserDataLoader">
  <h2>Getting Your Data...</h2>
  <img class="loading_gif"/>
</div>

<div class="initial-loader" *ngIf="!(isCurrentPractice && isCurrentAccess && isCurrentUser)">
  <p class="inner-percent" id="initial-loader-percent-custom">{{ percent }}%</p>
</div>

<router-outlet *ngIf="isCurrentPractice && isCurrentAccess && isCurrentUser"></router-outlet>

<div class="development fixed fixed-bottom fixed-left" *ngIf="showThemeSwitch === true">
  <button (click)="onSetTheme('default-theme')" mat-raised-button color="primary">
    {{ 'BRANDING.Primary Theme' | translate }}
  </button>

  <button (click)="onSetTheme('pioneer-theme')" mat-raised-button color="primary">
    {{ 'BRANDING.Secondary Theme' | translate }}
  </button>
</div>

<app-root-component *ngIf="isCurrentPractice == true && isCurrentAccess == true && isCurrentUser == true">
</app-root-component>
