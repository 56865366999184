import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ArchwizardModule } from 'angular-archwizard';
import { VerticalTimelineModule } from 'angular-vertical-timeline';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgPipesModule } from 'ngx-pipes';
import { SharedModule } from '../../shared/shared.module';
import { BankAccountModule } from '../bank-account/bank-account.module';
import { TreatmentModule } from '../treatment/treatment.module';
import { BeneficiaryCreateEditComponent } from './beneficiary-create-edit/beneficiary-create-edit.component';
import { beneficiaryDocumentGalleryOverviewComponent } from './beneficiary-document-gallery-overview/beneficiary-document-gallery-overview.component';
import { BeneficiaryListGlobalComponent } from './beneficiary-list-global/beneficiary-list-global.component';
import { BeneficiaryListMapComponent } from './beneficiary-list-map/beneficiary-list-map.component';
import { BenficiaryListOverview } from './beneficiary-list-overview/beneficiary-list-overview.component';
import { BeneficiaryListComponent } from './beneficiary-list/beneficiary-list.component';
import { BeneficiaryViewComponent } from './beneficiary-view/beneficiary-view.component';
import { BeneficiaryRoutingModule } from './shared/beneficiary-routing.module';
import { BeneficiaryService } from './shared/beneficiary.service';

import { BeneficiaryCatalogueViewComponent } from './beneficiary-catalogue-view/beneficiary-catalogue-view.component';
import { BeneficiarySubsidiaryCreateEditComponent } from './beneficiary-subsidiary-create-edit/beneficiary-subsidiary-create-edit.component';
import { BeneficiarySubsidiaryListViewComponent } from './beneficiary-subsidiary-list/beneficiary-subsidiary-list.component';

import { MatSortModule } from '@angular/material/sort';
import { ContactModule } from '../contact/contact.module';
import { DocumentModule } from '../document/document.module';
import { ProductModule } from '../product/product.module';
import { BeneficiaryPage } from './beneficiary.page';

@NgModule({
  imports: [
    NgPipesModule,
    CommonModule,
    BeneficiaryRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    CdkTableModule,
    ArchwizardModule,
    VerticalTimelineModule,
    BankAccountModule,
    NgxPaginationModule,
    DocumentModule,
    TreatmentModule,
    ContactModule,
    MatSortModule,
    ProductModule,
  ],
  declarations: [
    BeneficiaryViewComponent,
    beneficiaryDocumentGalleryOverviewComponent,
    BeneficiaryListMapComponent,
    BeneficiaryListComponent,
    BeneficiaryListGlobalComponent,
    BeneficiaryCreateEditComponent,
    BenficiaryListOverview,
    BeneficiaryCatalogueViewComponent,
    BeneficiarySubsidiaryListViewComponent,
    BeneficiarySubsidiaryCreateEditComponent,
    BeneficiaryPage,
  ],
  exports: [
    BeneficiaryViewComponent,
    beneficiaryDocumentGalleryOverviewComponent,
    BeneficiaryListMapComponent,
    BeneficiaryListComponent,
    BeneficiaryListGlobalComponent,
    BeneficiaryCreateEditComponent,
    BenficiaryListOverview,
    BeneficiaryCatalogueViewComponent,
    BeneficiarySubsidiaryListViewComponent,
    BeneficiarySubsidiaryCreateEditComponent,
  ],
  providers: [BeneficiaryService],
})
export class BeneficiaryModule {}
