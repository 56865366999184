<mat-card class="mt cd-height">
  <div>
    <h2>Campaign Summary Information</h2>
    <p>The following information concerns the current campaign that you have created. Please keep for your record.</p>
    <hr/>
    <div class="row">
      <div class="half-width">
        <h3>Campaign Details</h3>
        <hr/>
        <p>
          <em>Selected {{ "KEYWORD.dentist" | translate | titlecase }}</em
          >: {{ selectDentist }}
        </p>
        <p><em>Campaign Name</em>: {{ selectTemplate }}</p>
        <p><em>Description</em>: {{ myDescription }}</p>
        <p><em>Schedule Release</em>: {{ scheduledTime }}</p>
        <h3>Recipients</h3>
        <hr/>
        <p><em>Total Number of Recipients</em>: {{ this.totalRecipients }}</p>
        <p><em>Number Email of Recipients</em>: {{ this.totalEmails }}</p>
        <p><em>Number SMS of Recipients</em>: {{ this.totalSMSs }}</p>
      </div>
      <div class="half-width">
        <app-chart-input
          class="row"
          [data]="simpleData"
          [chartType]="'pie'"
          [title]="'Summary Campaign Graph'"
        ></app-chart-input>
      </div>
    </div>

    <div class="full-width">
      <button
        class="green txt-white"
        [routerLink]="['/merchant/', { outlets: { page: ['marketing-over-view'] } }]"
        mat-raised-button
        type="button"
      >
        <span>Finish</span>
        <mat-icon>check</mat-icon>
      </button>
    </div>
  </div>
</mat-card>
