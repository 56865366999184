import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { CalendarShareService } from '../../../shared/services/calendar-share.service';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { AuthenticationService } from '../shared/authentication.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css'],
})
export class LogoutComponent implements OnInit {


  @Output()
  close = new EventEmitter()

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private calendarShare: CalendarShareService
  ) {}




  ngOnInit() {}

  displayConfirmModal() {
    const confirmDialog = new ConfirmDialog('fas fa-info', 'Log out', 'Do you wish to log out?', 'No', 'Yes');
    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
      width: '300px',
      height: '180px',
    });
    ref.componentInstance.onConfirm.subscribe((res) => {
      if (res) {
        ref.close();
        this.logout();
      }
    });
  }

  logout() {
    let sessionType;
    let currentUser;
    this.authService.getCurrentUser().subscribe((currentUser2) => {
      if (currentUser2) {
        currentUser = currentUser2;
      }

      this.authService.getSessionType().subscribe((res) => {
        if (res) {
          sessionType = res;
        }
        this.authService.logout().subscribe(
          (res) => {


            this.calendarShare.googleSignOut();
            this.calendarShare.microsoftSignOut();

            this.activeRoute.params.subscribe((params) => {
              this.authService.initCurrUser();

              let navigation = 'medical';

              if (currentUser && currentUser.data && currentUser.data.navigation) {
                navigation = currentUser.data.navigation;
              }

              if (
                sessionType == 'customer' &&
                currentUser.data &&
                currentUser.data.loginSource &&
                currentUser.data.loginSource === 'practice-auth'
              ) {
                if (currentUser.data.merchantID) {
                  this.router.navigate(['/practice-auth/', currentUser.data.merchantID]);
                } else {
                  this.router.navigate(['/practice-auth/']);
                }
              } else if (
                sessionType == 'customer' &&
                currentUser.data.loginSource &&
                currentUser &&
                currentUser.data.ID &&
                !currentUser.data.merchantID &&
                !currentUser.data.invitationID &&
                !currentUser.data.medicalInvitationID
              ) {
                this.router.navigate(['/customer-login/' + navigation + '/patient/', currentUser.data.ID]);
              } else if (
                sessionType == 'customer' &&
                currentUser.data.loginSource &&
                currentUser &&
                currentUser.data.ID &&
                currentUser.data.merchantID &&
                !currentUser.data.invitationID &&
                !currentUser.data.medicalInvitationID
              ) {
                this.router.navigate([
                  '/customer-login/' + navigation + '/',
                  currentUser.data.ID,
                  currentUser.data.merchantID,
                ]);
              } else if (
                sessionType == 'customer' &&
                currentUser.data.loginSource &&
                currentUser &&
                currentUser.data.ID &&
                !currentUser.data.merchantID &&
                currentUser.data.invitationID &&
                !currentUser.data.medicalInvitationID
              ) {
                this.router.navigate(['/customer-login/' + navigation + '/invitation/', currentUser.data.invitationID]);
              } else if (
                sessionType == 'customer' &&
                currentUser.data.loginSource &&
                currentUser &&
                currentUser.data.ID &&
                !currentUser.data.merchantID &&
                !currentUser.data.invitationID &&
                currentUser.data.medicalInvitationID
              ) {
                this.router.navigate([
                  '/customer-login/' + navigation + '/medical-invitation/',
                  currentUser.data.medicalInvitationID,
                ]);
              } else {
                this.router.navigate(['/login']);
              }


              this.close.emit()

            });
          },
          (err: any) => {
            console.log(err);
          }
        );
      });
    });
  }
}
