import { BehaviorSubject, merge as observableMerge, Observable } from 'rxjs';

import { DataSource } from '@angular/cdk/table';
import { Component, ElementRef, EventEmitter, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { map } from 'rxjs/operators';
import { CustomDatePipe } from '../../../shared/pipes/custom-date.pipe';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { TestimonyService } from '../shared/testimony.service';

import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { UtilsService } from '../../../shared/services/utils.service';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { ProspectViewComponent } from '../../existing-patient/prospect-view/prospect-view.component';
import { MerchantViewComponent } from '../../merchant/merchant-view/merchant-view.component';
import { TestimonyViewComponent } from '../testimony-view/testimony-view.component';

@Component({
  selector: 'app-testimony-list',
  templateUrl: './testimony-list.component.html',
  styleUrls: ['./testimony-list.component.css'],
})
export class TestimonyListComponent implements OnInit {
  @Input()
  pageSize = 7;
  pageSizeOptions = [10, Number(this.pageSize), Number(this.pageSize) * 2, Number(this.pageSize) * 3];

  util = new UtilsClass();
  dateNow;

  displayedColumns = ['Avatar', 'Name', 'AccountType', 'Rating', 'Email', 'Active Status', 'CreatedDate', 'Actions'];
  filters = [];

  searchVals = new FormControl();

  searchValList = ['First Name', 'Last Name', 'Email', 'Rating', 'Active Status'];

  expandedRow: number;
  public listDB: LoadRecords | null;
  dataSource: RecordDataSource | null;

  filteredSize = null;

  destroyEvent = new EventEmitter();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('filter', { static: false }) filter: ElementRef;

  constructor(
    private UtilsService: UtilsService,
    private testimonyService: TestimonyService,
    private customDate: CustomDatePipe
  ) {}

  ngOnInit() {
    this.listDB = new LoadRecords(this.testimonyService, this.destroyEvent);
    this.dataSource = new RecordDataSource(this.listDB, this.paginator, this.sort);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.UtilsService.getServerDate().subscribe((res) => {
      this.dateNow = res;
    });

    this.listDB = new LoadRecords(this.testimonyService, this.destroyEvent);
    this.dataSource = new RecordDataSource(this.listDB, this.paginator, this.sort);
  }

  setFilter(event, field) {
    let filter;
    if (typeof event == 'object' && event != null) {
      const v = this.customDate.transform(event, Settings.global['dateFormat']);
      filter = {
        field,
        value: v,
      };
    } else if (event) {
      filter = {
        field,
        value: event,
      };
    } else {
      filter = {
        field,
        value: '',
      };
    }

    this.dataSource.filter = filter;
    this.filteredSize = this.dataSource.filteredData.length;
  }

  replaceImage(fileID) {
    if (fileID) {
      const s = fileID.replace(
        './assets/avatars/',
        'https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/avatars/'
      );
      return s;
    }
  }

  deleteTestimony(id) {
    const confirmMessage = new ConfirmDialog(
      'fas fa-info',
      'Delete Testimony',
      'Are you sure you want to detele this testimony?',
      'No',
      'Yes'
    );
    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmMessage,
      width: '650px',
    });
    ref.componentInstance.onConfirm.subscribe((res) => {
      if (res == true) {
        this.testimonyService.deleteTestimony(id).subscribe((res) => {
          this.dataSource.removeItem = id;
          NotifyAppComponent.displayToast('success', 'Successful operation', 'You successfully delele this testimony');
          ref.close();
        });
      } else {
        ref.close();
      }
    });
  }

  viewTestimony(id) {
    const ref = RootAppComponent.dialog.open(TestimonyViewComponent, {
      data: id,
      width: '650px',
      panelClass: 'noCard',
    });
    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });

    ref.componentInstance.update.subscribe((res) => {
      this.dataSource.replaceItem = res;
    });

    ref.componentInstance.delete.subscribe((res) => {
      ref.close();
      this.dataSource.removeItem = id;
    });
  }

  activateTestimony(id) {
    const confirmMessage = new ConfirmDialog(
      'fas fa-info',
      'Activate Testimony',
      'Are you sure you want to activate this testimony?',
      'No',
      'Yes'
    );
    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmMessage,
      width: '650px',
    });
    ref.componentInstance.onConfirm.subscribe((res) => {
      if (res == true) {
        const payload = {
          isActive: true,
        };
        this.testimonyService.activeTestimony(id, payload).subscribe((res) => {
          if (res) {
            this.dataSource.replaceItem = res;

            NotifyAppComponent.displayToast(
              'success',
              'Successful operation',
              'You successfully activate this testimony'
            );
            ref.close();
          }
        });
      } else {
        ref.close();
      }
    });
  }

  deactivateTestimony(id) {
    const confirmMessage = new ConfirmDialog(
      'fas fa-info',
      'Deactivate Testimony',
      'Are you sure you want to deactivate this testimony?',
      'No',
      'Yes'
    );
    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmMessage,
      width: '650px',
    });
    ref.componentInstance.onConfirm.subscribe((res) => {
      if (res == true) {
        const payload = {
          isActive: false,
        };
        this.testimonyService.activeTestimony(id, payload).subscribe((res) => {
          if (res) {
            this.dataSource.replaceItem = res;
            NotifyAppComponent.displayToast(
              'success',
              'Successful operation',
              'You successfully deactivate this testimony'
            );
            ref.close();
          }
        });
      } else {
        ref.close();
      }
    });
  }

  viewProspect(id) {
    RootAppComponent.dialog.open(ProspectViewComponent, {
      data: id,
      width: '750px',
      panelClass: 'noCard',
    });
  }

  viewMerchant(id) {
    const ref = RootAppComponent.dialog.open(MerchantViewComponent, {
      data: id,
      width: '600px',
    });
    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  ngOnDestroy() {
    this.destroyEvent.emit(true);
  }
}

export class LoadRecords implements OnInit {
  public dataChange: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  items: any;
  count: any;

  get data(): any[] {
    return this.dataChange.value;
  }

  firstLoad = false;

  get firstLoadEvent() {
    return this.firstLoad;
  }

  isDestoyed = false;

  serviceRef;

  constructor(private testimonyService: TestimonyService, private destroyEvent) {
    this.destroyEvent.subscribe((res) => {
      this.isDestoyed = res;
    });

    this.serviceRef = this.testimonyService.getTestimonyListGolbal().subscribe((res) => {
      this.count = res.length;
      this.items = res;

      this.dataChange.next(this.items.slice());
      this.firstLoad = true;
    });
  }

  ngOnInit() {}

  ngOnDestroy() {
    if (this.serviceRef) {
      this.serviceRef.unsubscribe();
    }
  }
}

export class RecordDataSource extends DataSource<any> {
  _filterChange = new BehaviorSubject('');
  field = '';

  get filter(): any {
    return this._filterChange.value;
  }

  set filter(item: any) {
    this.field = item.field;
    this._filterChange.next(item.value);
  }

  filteredData: any[] = [];

  constructor(private _tableDatabase: LoadRecords, private _paginator: MatPaginator, private _sort: MatSort) {
    super();
    this._filterChange.subscribe(() => (this._paginator.pageIndex = 0));
  }

  set replaceItem(item) {
    const _index = this._tableDatabase.data.findIndex((_obj) => _obj._id == item._id);
    this._tableDatabase.data[_index] = item;
    this._tableDatabase.dataChange.next(this._tableDatabase.data);
  }

  set removeItem(id) {
    const data = this._tableDatabase.data.filter((row) => row._id != id);
    this._tableDatabase.dataChange.next(data);
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<any[]> {
    const displayDataChanges = [
      this._tableDatabase.dataChange,
      this._filterChange,
      this._paginator.page,
      this._sort.sortChange,
    ];

    return observableMerge(...displayDataChanges).pipe(
      map(() => {
        // Filter data
        this.filteredData = this._tableDatabase.data.slice().filter((item: any) => {
          let f = '';

          f = item[this.field] || '';

          const searchStr = f.toLowerCase();
          return searchStr.indexOf(this.filter.toLowerCase()) != -1;
        });
        const data = this.getSortedData(this.filteredData.slice());
        // Grab the page's slice of data.
        const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
        return data.splice(startIndex, this._paginator.pageSize);
      })
    );
  }

  disconnect() {}

  /** Returns a sorted copy of the database data. */
  getSortedData(data: any[]): any[] {
    // const data = data;
    if (!this._sort.active || this._sort.direction == '') {
      return data;
    }

    return data.sort((a, b) => {
      let propertyA: number | string = '';
      let propertyB: number | string = '';

      switch (this._sort.active) {
        case 'Name':
          [propertyA, propertyB] = [a['firstName'] + ' ' + a['lastName'], b['firstName'] + ' ' + b['lastName']];
          break;

        case 'AccountType':
          [propertyA, propertyB] = [a['type'], b['type']];
          break;

        case 'Email':
          [propertyA, propertyB] = [a['email'], b['email']];
          break;

        case 'Rating':
          [propertyA, propertyB] = [a['starts'], b['starts']];
          break;
      }

      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

      return (valueA < valueB ? -1 : 1) * (this._sort.direction == 'asc' ? 1 : -1);
    });
  }
}
