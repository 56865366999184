<div class="full-width clearfix">
  <div class="main-banner blue-banner" *ngIf="displayHeader == true"></div>

  <div class="row clearfix container mt ml mr">
    <div class="clearfix container-inner-parent" *ngIf="displayHeader == true">
      <div class="clearfix container-inner mt">
        <div class="guid guid-right flex clearfix full-width">
          <div class="container guid-main" @ngIfAnimation data-aos="fade-right" data-aos-delay="400"
            data-aos-once="true">
            <div class="half-width txt-box">
              <div class="txt" @ngIfAnimation data-aos="fade-left" data-aos-delay="400" data-aos-once="true">
                <h1 class="text-left header-title-sub-list">Discover <br />our modules</h1>
                <p class="text-left header-description-sub-list">
                  Real {{ "KEYWORD.practice" | translate }} growth, We provide heap of functionalities to help you
                  improve it.
                </p>
              </div>
            </div>
            <div class="half-width img-box-left">
              <div class="guid-imgbox flex guid-imgbox-left">
                <img *ngIf="context!='p4p'" class="full-width"
                  src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/public/img/dentist-and-patient-computer.png" />
                <img *ngIf="context=='p4p'" class="full-width"
                  src="https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/p4p/dentist_hero_highfive.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class=" full-width flex mb clearfix" *ngIf="displayHeader==true && readOnly!=true">
      <div class="full-width clearfix  ">

        <button mat-raised-button color="accent" class="share-social-media-button ml pull-right" *ngIf="readOnly!=true"
          (click)="syncSubPlans()">
          <mat-icon class="fas fa-sync smr"></mat-icon>Sync User Sub Plans
        </button>


        <button mat-raised-button color="primary" class="share-social-media-button mr pull-right" *ngIf="readOnly!=true"
          (click)="sync(true)">
          <mat-icon class="fas fa-undo smr"></mat-icon>Reset
        </button>




        <button mat-raised-button color="primary" class="share-social-media-button mr pull-right "
          *ngIf="readOnly!=true" (click)="sync(false)">
          <mat-icon class="fas fa-save smr"></mat-icon>Save To EP
        </button>




      </div>
    </div>
    <mat-card class="rel full-width clearfix card-container">
      <div class="colour-stripe"></div>
      <div class="full-width flex">
        <div class="full-width ml mr">
          <div class="full-width flex">
            <mat-form-field class="full-width clearfix" *ngIf="modules && modules.length > 0 && displayFilter == true"
              appearance="outline">
              <mat-label>Filter by module name , description or Code</mat-label>
              <input class="full-width" [(ngModel)]="moduleFilter" [disabled]="isOrderCheck == true" name="linkLabel"
                matInput placeholder="Filter by module name , description or Code" />
            </mat-form-field>
            <button class="select-button-top sml" *ngIf="readOnly != true && displayHeader != true"
              (click)="sync(false)" mat-raised-button color="warn" style="width: 210px">
              <mat-icon class="fas fa-sync smr"></mat-icon>Synchronize
            </button>
            <button class="select-button-top sml" *ngIf="readOnly != true && displayHeader != true" (click)="sync(true)"
              mat-raised-button color="accent" style="width: 150px">
              <mat-icon class="fas fa-undo smr"></mat-icon>Reset
            </button>

            <button class="text-center sml select-button-top" *ngIf="canSelect == true"
              [disabled]="isOrderCheck == true" [matMenuTriggerFor]="ActionBtnmenuSelect" mat-raised-button
              style="width: 210px">
              <mat-icon class="far fa-object-group smr"></mat-icon> <span class="mr">Select</span>
              <span class="badge mat-elevation-z1 smr" *ngIf="selectedCodes && selectedCodes.length > 0"
                [style.background-color]="disabledModuleColor" [endVal]="selectedCodes.length" countUp></span>
            </button>

            <mat-menu #ActionBtnmenuSelect="matMenu">
              <button (click)="selectEvent('enabled', true)" mat-menu-item>
                Select <strong>Enabled</strong> Modules
              </button>
              <button (click)="selectEvent('beta', true)" mat-menu-item>Select <strong>Beta</strong> Modules</button>
              <button (click)="selectEvent('disabled', true)" mat-menu-item>
                Select <strong>Disabled</strong> Modules
              </button>
              <button (click)="selectEvent('all', true)" mat-menu-item>Select <strong>All</strong></button>
              <mat-divider></mat-divider>
              <button (click)="selectEvent('enabled', false)" mat-menu-item>
                Unselect <strong>Enabled</strong> Modules
              </button>
              <button (click)="selectEvent('beta', false)" mat-menu-item>Unselect <strong>Beta</strong> Modules</button>
              <button (click)="selectEvent('disabled', false)" mat-menu-item>
                Unselect <strong>Disabled</strong> Modules
              </button>
              <button (click)="selectEvent('all', false)" mat-menu-item>Unselect <strong>All</strong></button>
            </mat-menu>

            <button class="btn pull-right ml select-button-top"
              *ngIf="(canDragAndDrop == true && isOrderCheck == true) || isListView == true"
              [attr.style]="'background:' + colorSecond + ' !important' | safeStyle" (click)="sortOrderView(false)"
              mat-raised-button color="primary" matTooltip="Module Blocks View">
              <mat-icon class="fas fa-th"></mat-icon>
            </button>

            <button class="btn pull-right ml select-button-top"
              *ngIf="(canDragAndDrop == true && isOrderCheck == true) || isListView != true"
              [attr.style]="'background:' + colorSecond + ' !important' | safeStyle" (click)="listViewApply()"
              mat-raised-button color="primary" matTooltip="Module List View">
              <mat-icon class="fas fa-list"></mat-icon>
            </button>

            <button class="btn pull-right ml select-button-top"
              *ngIf="canDragAndDrop == true && readOnly != true && isOrderCheck != true"
              [attr.style]="'background:' + colorSecond + ' !important' | safeStyle" (click)="sortOrderView(true)"
              mat-raised-button color="accent" matTooltip="Sort View">
              <mat-icon class="fas fa-sort-numeric-down"></mat-icon>
            </button>

            <button class="btn pull-right ml select-button-top" *ngIf="
                canDragAndDrop == true && modules && modules.length > 1 && isOrderCheck == true && readOnly != true
              " [disabled]="disableSaveButton" (click)="saveOrder()" mat-raised-button color="accent"
              matTooltip="Save new order">
              <mat-icon>save</mat-icon>
            </button>
          </div>
          <div class="container-card-module full-width" *ngFor="let status of statusDivider"
            [cdkDropListDisabled]="isOrderCheck != true || canDragAndDrop != true"
            (cdkDropListDropped)="drop(status, $event)" cdkDropList>
            <h2 class="full-width mt mb text-center enabledModule" *ngIf="
                status === 'EnableForAll' &&
                (modules
                  | FilterArrayMultipleOrContain: ['Status.Code']:status
                  | FilterArrayMultipleOrContain: ['Code', 'Description', 'Label']:moduleFilter
                  | lengthOfArray) > 0
              " [attr.style]="'color:' + getColor(status) + ' !important' | safeStyle">
              <!-- <mat-icon class="fas fa-clipboard-check "></mat-icon>  -->

              Enabled Modules
              <span class="badge mat-elevation-z1"
                [attr.style]="'background:' + getColor(status) + ' !important' | safeStyle" [endVal]="
                  modules
                    | FilterArrayMultipleOrContain: ['Status.Code']:status
                    | FilterArrayMultipleOrContain: ['Code', 'Description', 'Label']:moduleFilter
                    | lengthOfArray
                " countUp></span>
              <hr class="mb module-div" [attr.style]="'background:' + getColor(status) + ' !important' | safeStyle" />
            </h2>

            <h2 class="full-width mt mb text-center betaModule" *ngIf="
                status === 'Beta' &&
                (modules
                  | FilterArrayMultipleOrContain: ['Status.Code']:status
                  | FilterArrayMultipleOrContain: ['Code', 'Description', 'Label']:moduleFilter
                  | lengthOfArray) > 0
              " [attr.style]="'color:' + getColor(status) + ' !important' | safeStyle">
              <!-- <mat-icon class="fas fa-vial "></mat-icon>  -->

              Beta Modules
              <span class="badge mat-elevation-z1"
                [attr.style]="'background:' + getColor(status) + ' !important' | safeStyle" [endVal]="
                  modules
                    | FilterArrayMultipleOrContain: ['Status.Code']:status
                    | FilterArrayMultipleOrContain: ['Code', 'Description', 'Label']:moduleFilter
                    | lengthOfArray
                " countUp></span>
              <hr class="mb module-div" [attr.style]="'background:' + getColor(status) + ' !important' | safeStyle" />
            </h2>
            <h2 class="full-width mt mb text-center disabledModule" *ngIf="
                status === 'DisableForAll' &&
                (modules
                  | FilterArrayMultipleOrContain: ['Status.Code']:status
                  | FilterArrayMultipleOrContain: ['Code', 'Description', 'Label']:moduleFilter
                  | lengthOfArray) > 0
              " [attr.style]="'color:' + getColor(status) + ' !important' | safeStyle">
              <!-- <mat-icon class="fas fa-ban "></mat-icon>  -->

              Disabled Modules
              <span class="badge mat-elevation-z1"
                [attr.style]="'background:' + getColor(status) + ' !important' | safeStyle" [endVal]="
                  modules
                    | FilterArrayMultipleOrContain: ['Status.Code']:status
                    | FilterArrayMultipleOrContain: ['Code', 'Description', 'Label']:moduleFilter
                    | lengthOfArray
                " countUp></span>
              <hr class="mb module-div" [attr.style]="'background:' + getColor(status) + ' !important' | safeStyle" />
            </h2>
            <div class="flex row module-container-card" *ngFor="
                let m of modules
                  | FilterArrayMultipleOrContain: ['Status.Code']:status
                  | FilterArrayMultipleOrContain: ['Code', 'Description', 'Label']:moduleFilter
                  | OrderArrayObject: 'SortOrder':true
              " [ngClass]="
                isOrderCheck == true
                  ? 'full-width'
                  : isListView == true
                  ? 'half-width'
                  : displayTwoCardRow != true
                  ? 'thrd-width'
                  : 'half-width'
              " [class.pull-left]="isListView == true" cdkDrag>
              <mat-card class="card module-card full-width leftCardBordeNoDrag row" id="module-card-tips"
                [class.listViewCard]="isListView == true" [class.checkboxSelect]="canSelect == true"
                [class.pointerCard]="isOrderCheck == true"
                [class.cardNoMargin]="isOrderCheck == true || isListView == true"
                [class.enabledModule]="m['Status.Code'] == 'EnableForAll'"
                [class.disabledModule]="m['Status.Code'] == '' || m['Status.Code'] == 'DisableForAll'"
                [class.betaModule]="m['Status.Code'] == 'Beta'" [class.leftCardBorderDrag]="isOrderCheck == true"
                [attr.style]="'border-left-color: ' + getBotderColor(m['Status.Code']) + ' !important' | safeStyle">
                <div class="item-placeholder full-width" *cdkDragPlaceholder></div>

                <mat-checkbox class="checkbox-module checkbox-module-position"
                  *ngIf="canSelect == true && isOrderCheck != true && isListView != true"
                  [checked]="isSelected(m['ID'], m['Code'])" (click)="$event.stopPropagation()"
                  (change)="selectModule(m['ID'], m['Code'])">
                </mat-checkbox>

                <mat-icon class="new-icon" *ngIf="
                    displayOwn === true &&
                    isOrderCheck != true &&
                    canSelect != true &&
                    isListView != true &&
                    isOwned(m['Code']) == true
                  " [style.color]="'#4BB543'" matTooltip="{{ accessOnTooltip }}">power
                </mat-icon>

                <mat-icon class="new-icon" *ngIf="
                    displayOwn === true &&
                    isOrderCheck != true &&
                    canSelect != true &&
                    isListView != true &&
                    isOwned(m['Code']) != true
                  " [style.color]="'#ff0000'" matTooltip="{{ accessOffTooltip }}">power_off
                </mat-icon>

                <mat-icon class="new-icon-list" *ngIf="
                    displayOwn === true &&
                    isOrderCheck != true &&
                    canSelect != true &&
                    isListView == true &&
                    isOwned(m['Code']) == true
                  " [style.color]="'#4BB543'" matTooltip="{{ accessOnTooltip }}">power
                </mat-icon>

                <mat-icon class="new-icon-list" *ngIf="
                    displayOwn === true &&
                    isOrderCheck != true &&
                    canSelect != true &&
                    isListView == true &&
                    isOwned(m['Code']) != true
                  " [style.color]="'#ff0000'" matTooltip="{{ accessOffTooltip }}">power_off
                </mat-icon>

                <div class="full-width">
                  <div class="big-icon-container clearfix text-center full-width"
                    *ngIf="isOrderCheck != true && isListView != true">
                    <mat-icon class="big-icon" *ngIf="isIndexOf(m.Icon, 'fa-') == false"
                      [attr.style]="'color:' + getColorIcon(m['Status.Code']) + ' !important' | safeStyle">
                      {{ m.Icon }}</mat-icon>
                    <mat-icon class="big-icon fas {{ m.Icon }}  " *ngIf="isIndexOf(m.Icon, 'fa-') != false"
                      [attr.style]="'color:' + getColorIcon(m['Status.Code']) + ' !important' | safeStyle">
                    </mat-icon>
                  </div>
                  <button class="pull-right action-button"
                    *ngIf="readOnly != true && isOrderCheck != true && isListView != true"
                    [matMenuTriggerFor]="ActionBtnmenu" (click)="$event.stopPropagation()" mat-icon-button
                    color="primary">
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #ActionBtnmenu="matMenu">
                    <button (click)="$event.stopPropagation(); viewModule(m['ID'])" mat-menu-item color="">
                      <mat-icon>visibility</mat-icon> View
                    </button>

                    <button (click)="$event.stopPropagation(); edit(m['ID'])" mat-menu-item color="">
                      <mat-icon>edit</mat-icon> Edit
                    </button>
                    <button (click)="$event.stopPropagation(); delete(m['ID'])" mat-menu-item color="">
                      <mat-icon>delete</mat-icon> Remove
                    </button>
                  </mat-menu>
                  <div class="full-width clearfix">
                    <button class="pull-right mr text-center" *ngIf="readOnly == true && isListView == true"
                      (click)="$event.stopPropagation(); viewModule(m['ID'])" mat-icon-button color="accent">
                      <mat-icon class="fas fa-eye text-center"
                        [attr.style]="'color:rgb(156, 156, 156)  !important' | safeStyle">
                      </mat-icon>
                    </button>

                    <button class="pull-right" *ngIf="readOnly != true && (isOrderCheck == true || isListView == true)"
                      [matMenuTriggerFor]="ActionBtnmenu" (click)="$event.stopPropagation()" mat-icon-button
                      color="primary">
                      <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #ActionBtnmenu="matMenu">
                      <button (click)="$event.stopPropagation(); viewModule(m['ID'])" mat-menu-item color="">
                        <mat-icon>visibility</mat-icon> View
                      </button>

                      <button (click)="$event.stopPropagation(); edit(m['ID'])" mat-menu-item color="">
                        <mat-icon>edit</mat-icon> Edit
                      </button>
                      <button (click)="$event.stopPropagation(); delete(m['ID'])" mat-menu-item color="">
                        <mat-icon>delete</mat-icon> Remove
                      </button>
                    </mat-menu>

                    <mat-checkbox class="checkbox-module pull-left mr"
                      *ngIf="canSelect == true && isOrderCheck != true && isListView == true"
                      [checked]="isSelected(m['ID'], m['Code'])" (click)="$event.stopPropagation()"
                      (change)="selectModule(m['ID'], m['Code'])" style="margin-top: 5px">
                    </mat-checkbox>

                    <mat-icon class="order-icon-label pull-left smr"
                      *ngIf="isIndexOf(m.Icon, 'fa-') == false && (isOrderCheck == true || isListView == true)"
                      [attr.style]="'color:' + getColorIcon(m['Status.Code']) + ' !important' | safeStyle">
                      {{ m.Icon }}</mat-icon>
                    <mat-icon class=" order-icon-label  pull-left fas {{ m.Icon }} smr "
                      *ngIf="isIndexOf(m.Icon, 'fa-') != false && (isOrderCheck == true || isListView == true)"
                      [attr.style]="'color:' + getColorIcon(m['Status.Code']) + ' !important' | safeStyle">
                    </mat-icon>

                    <h3 class="sr-title rm-mt rm-mb order-title-card"
                      [ngClass]="isOrderCheck != true && isListView != true ? 'text-center' : ''">
                      {{ m.Label }}
                    </h3>

                    <read-more class="text-center read-more-module mb"
                      *ngIf="isOrderCheck != true && isListView != true" [text]="m['Description']" [design]="'mode2'"
                      [hideButton]="false" [isCollapsed]="true" [maxLength]="maxText">
                    </read-more>

                    <read-more class="text-left full-width read-more-module mb"
                      *ngIf="isOrderCheck != true && isListView == true" [text]="m['Description']" [design]="'mode2'"
                      [hideButton]="false" [isCollapsed]="true" [maxLength]="60">
                    </read-more>
                  </div>
                </div>
                <div class="clearfix text-center full-width mt action-container smb"
                  *ngIf="readOnly == true && isOrderCheck != true && isListView != true">
                  <hr class="mb" />
                  <div class="full-width text-center">
                    <button class="btn pull-right" (click)="$event.stopPropagation(); viewModule(m['ID'])"
                      mat-raised-button>
                      More details
                    </button>
                  </div>
                </div>
                <div class="clearfix text-center full-width mt action-container smb"
                  *ngIf="readOnly != true && isOrderCheck != true && isListView != true">
                  <hr class="mb" />
                  <mat-form-field class="two-thrd-width status-input clearfix text-center"
                    (click)="$event.stopPropagation()" appearance="outline">
                    <mat-label>Status</mat-label>
                    <mat-select [(ngModel)]="m['Status.Code']" (ngModelChange)="updateModule(m['ID'], m['Status.Code'])"
                      name="contactMethod" placeholder="Status">
                      <mat-option *ngFor="let s of statusArray" [value]="s.code">
                        {{ s.value }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </mat-card>
            </div>
          </div>
        </div>
      </div>
    </mat-card>
  </div>

  <app-card-footer *ngIf="displayHeader == true" [firstBackgroundWhite]="false"
    [items]="['features', 'plans-overview', 'free', 'demo']" [featureType]="featureType"></app-card-footer>
</div>
