import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'environments/environment';
import { ClipboardService } from 'ngx-clipboard';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { UtilsService } from '../../../shared/services/utils.service';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { LandingPageShareComponent } from '../landing-page-share/landing-page-share.component';
import { MerchantService } from '../shared/merchant.service';

@Component({
  selector: 'app-merchant-visitor',
  templateUrl: './merchant-visitor.component.html',
  styleUrls: ['./merchant-visitor.component.css'],
  animations: [
    trigger('simpleFadeAnimation', [
      state('in', style({ opacity: 1, height: '*' })),
      transition(':enter', [style({ opacity: 0, height: 0 }), animate(300)]),
      transition(':leave', animate(300, style({ opacity: 0, height: 0 }))),
    ]),
  ],
})
export class MerchantVisitorComponent implements OnInit {
  @Input()
  target = 'practice';

  targetCode;
  @Input()
  merchantID;

  sessionType;

  visitors;

  isPromoterOrAdmin = false;

  merchant;

  terminalCode;
  practiceUniqueCode;
  practiceUniqueCodeOriginal;
  practiceUniqueCodeError = false;
  link;

  urlQR;
  urlQRShort;
  publicLink;

  setting = Settings.global;
  totalVisitor = 0;
  iFrameSrc;
  iFrameSrcSecured;
  loadIFrame = false;

  chartVisitorData = [];

  label;
  title;
  description;

  isModuleGoogleReviewActive = Settings.global['isModuleGoogleReviewActive'];
  downloadQRCodeDental = new EventEmitter();

  theme = {
    code: 'blue',
    background: 'linear-gradient(135deg, #0092eb 0%, #0dbad3 100%)',
    color: '#1b8bdd',
    color2: '#0dbad3',
  };

  settings = Settings.global;
  openLink;
  shareType;
  constructor(
    private utilService: UtilsService,
    private _clipboardService: ClipboardService,
    private _sanitizer: DomSanitizer,
    private merchantService: MerchantService,
    private authenticationService: AuthenticationService,
    private activeRoute: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      if (params['target']) {
        this.target = params['target'];
      }

      if (this.target === 'practice') {
        this.theme = {
          code: 'ohhappiness',
          background: ' linear-gradient(-135deg, #00b09b -10.31%, #96c93d  97.05%)',
          color: '#00b09b',
          color2: '#57cc99',
        };
      }

      this.utilService.getCurrentAccess().subscribe((access) => {
        if (access) {
          this.isModuleGoogleReviewActive = access['isModuleGoogleReviewActive'];
        }

        this.authenticationService.isPromoterOrAdmin().subscribe((isPromoterOrAdmin) => {
          this.isPromoterOrAdmin = isPromoterOrAdmin;
          this.authenticationService.getSessionType().subscribe((res) => {
            this.sessionType = res;

            if (this.isPromoterOrAdmin == true && this.merchantID) {
              this.merchantService
                .getMerchantDetails(this.merchantID, { fields: 'ID,Terminal_Code,TradingAs' }, this.sessionType)
                .subscribe((res) => {
                  if (res) {
                    this.merchant = res;

                    if (this.merchant['Terminal_Code']) {
                      this.terminalCode = this.merchant['Terminal_Code'];
                    }

                    this.setup();
                  }
                });
            } else {
              this.authenticationService.getCurrentPractice().subscribe((res) => {
                if (res) {
                  this.merchant = res;
                  if (this.merchant['Terminal_Code']) {
                    this.terminalCode = this.merchant['Terminal_Code'];
                  }

                  if (this.merchant['ID']) {
                    this.merchantID = this.merchant['ID'];
                  }
                  this.setup();
                }
              });
            }
          });
        });
      });
    });
  }

  setup() {
    this.merchantService.practiceLandingPageOneByMerchant(this.merchantID, { fields: 'Code' }).subscribe((res) => {
      this.practiceUniqueCode = res.Code;
      this.practiceUniqueCodeOriginal = res.Code;

      this.addLinks();
      if (this.targetCode) {
        this.merchantService.getMerchantVisitorsByTarget(this.targetCode, {}, this.sessionType).subscribe((res) => {
          if (res) {
            this.visitors = res;
            this.addChart();
          }
        });
      }
    });
  }

  addLinks(updateIframe = true) {
    if (this.target == 'practice' && this.terminalCode) {
      this.targetCode = 'PrcPage';
      this.label = 'Practice Page';
      this.shareType = 'goinggreen';
      this.link = this.setting['publicSiteFrontendLink'] + '/practice/' + this.terminalCode;
      this.urlQR = `${environment.nodeUrl}/r/going-green/${this.merchantID}/qr?t=qr`;
      this.utilService.createShortcut(this.urlQR).subscribe((r) => {
        if (r && r.shortLink) {
          this.urlQRShort = r.shortLink;
        }
      });
      this.publicLink = '/home/';
      this.openLink = `${this.settings['publicSiteFrontendLink']}/going-green/${this.merchantID}`;
      this.title = 'Go Green landing page';
      this.description =
        'This landing page is designed to educate patients about how you are moving from paper-based planning to digital treatment presentations and payment plan options. This can be used standalone or ideally integrated into your website as a button, hyperlink, or your web developer can embed it with a code “snippet” in minutes. Click on the share button to find out more. ';

      if (updateIframe == true) {
        this.updateIFrameSrc(this.link);
      }
    } else if (this.target == 'dental' && this.terminalCode) {
      this.targetCode = 'DntlTps';

      this.shareType = 'offers';
      this.label = 'Dental Tips and Offers';
      this.link = this.setting['publicSiteFrontendLink'] + '/dental/' + this.terminalCode;
      this.urlQR = `${environment.nodeUrl}/r/tips-offers/${this.merchantID}/qr?t=qr`;

      this.openLink = `${this.settings['publicSiteFrontendLink']}/tips-offers/${this.merchantID}`;
      this.utilService.createShortcut(this.urlQR).subscribe((r) => {
        if (r && r.shortLink) {
          this.urlQRShort = r.shortLink;
        }
      });
      this.publicLink = '/news/';

      if (updateIframe == true) {
        this.updateIFrameSrc(this.link);
      }

      this.title = 'Dental Tips and Offers landing page';
      this.description =
        'On this page, patients can explore content you have selected and landing pages for Marketing Campaigns you have delivered';
    }
  }

  addChart() {
    if (this.visitors) {
      this.totalVisitor = 0;
      this.chartVisitorData = [];

      if (this.visitors['Facebook_Visitors']) {
        this.totalVisitor = this.totalVisitor + Number(this.visitors['Facebook_Visitors']);
      }
      if (this.visitors['Linkedin_Visitors']) {
        this.totalVisitor = this.totalVisitor + Number(this.visitors['Linkedin_Visitors']);
      }
      if (this.visitors['Instagram_Visitors']) {
        this.totalVisitor = this.totalVisitor + Number(this.visitors['Instagram_Visitors']);
      }

      if (this.visitors['Pinterest_Visitors']) {
        this.totalVisitor = this.totalVisitor + Number(this.visitors['Pinterest_Visitors']);
      }
      if (this.visitors['Twitter_Visitors']) {
        this.totalVisitor = this.totalVisitor + Number(this.visitors['Twitter_Visitors']);
      }
      if (this.visitors['Email_Visitors']) {
        this.totalVisitor = this.totalVisitor + Number(this.visitors['Email_Visitors']);
      }

      if (this.visitors['Practice_Visitors'] && this.target != 'practice') {
        this.totalVisitor = this.totalVisitor + Number(this.visitors['Practice_Visitors']);
      }

      if (this.visitors['Dental_Visitors'] && this.target != 'dental') {
        this.totalVisitor = this.totalVisitor + Number(this.visitors['Dental_Visitors']);
      }

      if (this.visitors['QR_Visitors']) {
        this.totalVisitor = this.totalVisitor + Number(this.visitors['QR_Visitors']);
      }
      if (this.visitors['SMS_Visitors']) {
        this.totalVisitor = this.totalVisitor + Number(this.visitors['SMS_Visitors']);
      }

      if (this.visitors['Other_Visitors']) {
        this.totalVisitor = this.totalVisitor + Number(this.visitors['Other_Visitors']);
      }

      if (this.visitors['LandingPage_Visitors']) {
        this.totalVisitor = this.totalVisitor + Number(this.visitors['LandingPage_Visitors']);
      }

      this.chartVisitorData.push({
        name: `Email ${Math.trunc((Number(this.visitors['Email_Visitors']) / Number(this.totalVisitor)) * 100)}%`,
        value: Number(this.visitors['Email_Visitors']),
      });

      this.chartVisitorData.push({
        name: `SMS ${Math.trunc((Number(this.visitors['SMS_Visitors']) / Number(this.totalVisitor)) * 100)}%`,
        value: Number(this.visitors['SMS_Visitors']),
      });

      if (this.target != 'practice') {
        this.chartVisitorData.push({
          name: `Practice Page ${Math.trunc(
            (Number(this.visitors['Practice_Visitors']) / Number(this.totalVisitor)) * 100
          )}%`,
          value: Number(this.visitors['Practice_Visitors']),
        });
      }

      if (this.target != 'dental') {
        this.chartVisitorData.push({
          name: `Dental Tips and Offers page ${Math.trunc(
            (Number(this.visitors['Dental_Visitors']) / Number(this.totalVisitor)) * 100
          )}%`,
          value: Number(this.visitors['Dental_Visitors']),
        });
      }

      this.chartVisitorData.push({
        name: `QR Scan ${Math.trunc((Number(this.visitors['QR_Visitors']) / Number(this.totalVisitor)) * 100)}%`,
        value: Number(this.visitors['QR_Visitors']),
      });

      this.chartVisitorData.push({
        name: `Campaign landing page ${Math.trunc(
          (Number(this.visitors['LandingPage_Visitors']) / Number(this.totalVisitor)) * 100
        )}%`,
        value: Number(this.visitors['LandingPage_Visitors']),
      });

      this.chartVisitorData.push({
        name: `Facebook ${Math.trunc((Number(this.visitors['Facebook_Visitors']) / Number(this.totalVisitor)) * 100)}%`,
        value: Number(this.visitors['Facebook_Visitors']),
      });
      this.chartVisitorData.push({
        name: `Linkedin ${Math.trunc((Number(this.visitors['Linkedin_Visitors']) / Number(this.totalVisitor)) * 100)}%`,
        value: Number(this.visitors['Linkedin_Visitors']),
      });
      this.chartVisitorData.push({
        name: `Twitter ${Math.trunc((Number(this.visitors['Twitter_Visitors']) / Number(this.totalVisitor)) * 100)}%`,
        value: Number(this.visitors['Twitter_Visitors']),
      });
      this.chartVisitorData.push({
        name: `Instagram ${Math.trunc(
          (Number(this.visitors['Instagram_Visitors']) / Number(this.totalVisitor)) * 100
        )}%`,
        value: Number(this.visitors['Instagram_Visitors']),
      });

      this.chartVisitorData.push({
        name: `Pinterest ${Math.trunc(
          (Number(this.visitors['Pinterest_Visitors']) / Number(this.totalVisitor)) * 100
        )}%`,
        value: Number(this.visitors['Pinterest_Visitors']),
      });
      this.chartVisitorData.push({
        name: `Other ${Math.trunc((Number(this.visitors['Other_Visitors']) / Number(this.totalVisitor)) * 100)}%`,
        value: Number(this.visitors['Other_Visitors']),
      });
    }
  }

  updateIFrameSrc(src: string): void {
    this.iFrameSrcSecured = this._sanitizer.bypassSecurityTrustResourceUrl(src);
  }

  onFrameLoad(e) {
    if (!this.loadIFrame) {
      this.loadIFrame = true;
      this.changeDetectorRef.detectChanges();
    } else {
      this.loadIFrame = true;
    }
  }

  previewPage(url) {
    window.open(url, '_blank');
  }

  savePracticeUniqueCode() {
    if (this.practiceUniqueCode) {
      const payload = {
        code: this.practiceUniqueCode,
      };
      this.merchantService.practiceLandingPageCreate(payload, this.isPromoterOrAdmin).subscribe((res) => {
        if (res) {
          NotifyAppComponent.displayToast('success', 'Success', 'Your Practice Page code has been updated');
          this.practiceUniqueCodeOriginal = this.practiceUniqueCode;
          this.practiceUniqueCodeError = false;

          this.addLinks(false);
        } else {
          NotifyAppComponent.displayToast(
            'error',
            'Success',
            'This key word is already exist, please choose another one'
          );

          this.practiceUniqueCodeError = true;
        }
      });
    } else {
      if (this.practiceUniqueCodeOriginal) {
        this.practiceUniqueCode = this.practiceUniqueCodeOriginal;

        this.practiceUniqueCodeError = false;
        this.addLinks(false);
      }
    }
  }

  linkCopy(url) {
    if (url) {
      this._clipboardService.copyFromContent(url);
      NotifyAppComponent.displayToast('success', 'Copied!', '');
    }
  }

  share() {
    const ref = RootAppComponent.dialog.open(LandingPageShareComponent, {
      data: {
        type: this.shareType,
      },
      width: '80vw',
      panelClass: 'max-width-panel-1400',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  open() {
    if (this.openLink) {
      window.open(this.openLink, '_blank');
    }
  }
}
