import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output, SimpleChanges } from '@angular/core';

import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ContactService } from '../shared/contact.service';

@Component({
  selector: 'app-contact-view',
  templateUrl: './contact-view.component.html',
  styleUrls: ['./contact-view.component.css'],
  animations: [
    trigger('onChangeAnimation', [
      transition('void => *', [
        query('.animate', style({ opacity: 0 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.15s ease-in-out',
              keyframes([
                style({ opacity: 0, offset: 0, height: 0, margin: 0 }),
                style({ opacity: 1, offset: 1.0, height: '*', margin: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.animate', style({ opacity: 1 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.15s ease-in-out',
              keyframes([
                style({ opacity: 1, offset: 0, height: '*', margin: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, offset: 1.0, height: 0, margin: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class ContactViewComponent implements OnInit {
  @Input()
  contactID;

  @Output()
  close = new EventEmitter();

  contactObject;

  isModal = false;

  displayMapFlag = true;
  isPromoterOrAdmin = false;

  sessionType = 'merchant';

  constructor(
    private authenticationService: AuthenticationService,
    private contactService: ContactService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data && typeof data == 'string') {
      this.contactID = data;
      this.isModal = true;
    }
  }

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      if (params['contactID']) {
        this.contactID = params['contactID'];
      }

      this.authenticationService.getSessionType().subscribe((sessionType) => {
        if (sessionType) {
          this.sessionType = sessionType;
        }
        this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
          this.isPromoterOrAdmin = res;

          if (this.contactID) {
            this.contactService.getContactDetails(this.contactID, {}, this.sessionType).subscribe((res) => {
              this.contactObject = res;
            });
          }
        });
      });
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.contactID.previousValue != changes.contactID.currentValue) {
      if (this.contactID) {
        this.contactService.getContactDetails(this.contactID, {}, this.sessionType).subscribe((res) => {
          this.contactObject = res;
        });
      }
    }
  }

  closeModal() {
    this.close.emit(true);
  }

  contactDentist() {
    let data = {
      targetType: 'contact',
      targetID: this.contactObject.ID,
      asGuest: false,
      asProfile: true,
      asPractice: true,
    };
    AuthenticationService.contactInputEvent.emit(data);
  }

  notFoundEvent(e) {
    if (e == null) {
      this.displayMapFlag = false;
    } else {
      this.displayMapFlag = !e;
    }
  }

  modifyContact() {
    this.close.emit(true);

    this.router.navigate([
      '/merchant',
      {
        outlets: {
          page: ['contact-edit', this.contactObject.ID, this.contactObject['ContactForCardName']],
        },
      },
    ]);
  }
}
