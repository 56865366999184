<mat-card class="rel supplier-card clearfix mb" @ngIfAnimation>

  <div class="card-header primary-gradient-img clearfix stacked">
    <div class="row clearfix">
      <button class="btn-close btn-clear mat-button white" (click)="closeEvent()">
        <mat-icon>clear</mat-icon>
      </button>
      <div class="row titleArea clearfix">
        <h2 class="summary-header text-left m-m">
          Portals Access
        </h2>

      </div>
    </div>
  </div>
  <mat-dialog-content>
    <div class="full-width mb clearfix row">

      <p class="mb" *ngIf="operator && operator['CalculatedName']">
        Choose Portals Access <span *ngIf="operator && operator['CalculatedName']">For <strong>
            {{ operator['CalculatedName'] }}:</strong></span>
      </p>


      <p class="mb" *ngIf="!operator    ">
        Choose Portals Access for selected users
      </p>

      <div class="full-width text-left">
        <mat-checkbox name="publicSiteActive" class="text-left mt mb " [(ngModel)]="publicSiteActive">
          <strong>Access to public site: </strong> <br>

          - Access to Finance page. <br>
          - Create direct instant Offer. <br>
          - <a href="{{settings['publicSiteFrontendLink']}}"
            target="_bland">{{settings['publicSiteFrontendLink']}}/finance</a>
        </mat-checkbox>
      </div>
      <br>
      <div class="full-width text-left mt">
        <mat-checkbox name="mainAppActive" class="text-left smt smb " [(ngModel)]="mainAppActive">
          <strong>Access to {{'Merchant' | translate}} application </strong> <br>

          - Unlock full access to all features and services. <br>
          - <a href="{{settings['merchantFrontendLink']}}/finance"
            target="_bland">{{settings['merchantFrontendLink']}}</a>
        </mat-checkbox>
      </div>






    </div>
  </mat-dialog-content>

  <div class="row clearfix text-center  mt">
    <hr>

    <button class=" btn pull-left" (click)="closeEvent()" mat-raised-button color="primary">
      Close
    </button>

    <button class=" btn pull-right" (click)="save()" mat-raised-button color="accent">
      Save
    </button>

  </div>

</mat-card>
