import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'FilterArrayMultipleNotValue',
})
export class FilterArrayMultipleNotValuePipe implements PipeTransform {
  constructor() {}

  transform(expression: any, format: any, value: any) {
    try {
      let result = [];

      if (
        expression &&
        format &&
        Array.isArray(expression) &&
        typeof format == 'string' &&
        Array.isArray(value) &&
        value.length > 0
      ) {
        for (let i = 0; i < value.length; i++) {
          const _obj = {};

          _obj[format] = value[i];

          if (value[i] == '*') {
            return true;
          }

          const tmp = _.filter(expression, function (s) {
            return !(String(s[format]).toLocaleLowerCase() == String(value[i]).toLocaleLowerCase());
          });

          if (tmp.length > 0) {
            result = result.concat(tmp);
          }
        }

        return result;
      } else if (
        expression &&
        format &&
        Array.isArray(expression) &&
        Array.isArray(format) &&
        Array.isArray(value) &&
        value.length > 0
      ) {
        result = _.filter(expression, function (item) {
          for (let j = 0; j < format.length; j++) {
            for (let i = 0; i < value.length; i++) {
              if (value[i] == '*') {
                return true;
              }

              if (
                value[i] &&
                value[i] != '*' &&
                item[format[j]] &&
                String(item[format[j]]).toLocaleLowerCase() == String(value[i]).toLocaleLowerCase()
              ) {
                return false;
              }
            }
          }

          return true;
        });

        return result;
      } else if (
        expression &&
        format &&
        Array.isArray(expression) &&
        Array.isArray(format) &&
        value &&
        typeof value == 'string'
      ) {
        if (value == '*') {
          return expression;
        }

        result = _.filter(expression, function (item) {
          for (let j = 0; j < format.length; j++) {
            if (
              value &&
              item[format[j]] &&
              String(item[format[j]]).toLocaleLowerCase() == String(value).toLocaleLowerCase()
            ) {
              return false;
            }
          }

          return true;
        });

        return result;
      } else {
        return expression;
      }
    } catch (e) {
      return expression;
    }
  }
}
