/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./existing-patient-map-overview.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../existing-patient-list-map/existing-patient-list-map.component.ngfactory";
import * as i3 from "../existing-patient-list-map/existing-patient-list-map.component";
import * as i4 from "../shared/existing-patient.service";
import * as i5 from "../../../core/authentication/shared/authentication.service";
import * as i6 from "../../customer/shared/customer.service";
import * as i7 from "@ngx-translate/core";
import * as i8 from "../../operator/operator-filter/operator-filter.component.ngfactory";
import * as i9 from "../../operator/operator-filter/operator-filter.component";
import * as i10 from "../../merchant/shared/merchant.service";
import * as i11 from "../../product/shared/product.service";
import * as i12 from "../../dentist/shared/dentist.service";
import * as i13 from "@angular/common";
import * as i14 from "./existing-patient-map-overview.component";
var styles_ExistingPatientMapOverViewComponent = [i0.styles];
var RenderType_ExistingPatientMapOverViewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ExistingPatientMapOverViewComponent, data: { "animation": [{ type: 7, name: "ngIfAnimation", definitions: [{ type: 1, expr: "void => *", animation: [{ type: 11, selector: "*", animation: { type: 6, styles: { opacity: 0 }, offset: null }, options: { optional: true } }, { type: 11, selector: "*", animation: { type: 12, timings: "100ms", animation: [{ type: 4, styles: { type: 5, steps: [{ type: 6, styles: { opacity: 0, transform: "translateY(-75%)", offset: 0 }, offset: null }, { type: 6, styles: { opacity: 1, transform: "translateY(0)", offset: 1 }, offset: null }] }, timings: "0.8s ease-out" }] }, options: { optional: true } }], options: null }, { type: 1, expr: "* => void", animation: [{ type: 11, selector: "*", animation: { type: 6, styles: { opacity: 1 }, offset: null }, options: { optional: true } }, { type: 11, selector: "*", animation: { type: 12, timings: "100ms", animation: [{ type: 4, styles: { type: 5, steps: [{ type: 6, styles: { opacity: 1, transform: "translateY(0)", offset: 0 }, offset: null }, { type: 6, styles: { opacity: 0, transform: "translateY(-75%)", offset: 1 }, offset: null }] }, timings: "0.8s ease-in" }] }, options: { optional: true } }], options: null }], options: {} }] } });
export { RenderType_ExistingPatientMapOverViewComponent as RenderType_ExistingPatientMapOverViewComponent };
function View_ExistingPatientMapOverViewComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-existing-patient-list-map", [], null, null, null, i2.View_ExistingPatientListMapComponent_0, i2.RenderType_ExistingPatientListMapComponent)), i1.ɵdid(1, 770048, null, 0, i3.ExistingPatientListMapComponent, [i4.ExistingPatientService, i5.AuthenticationService, i6.CustomerService], { merchantID: [0, "merchantID"], dateFrom: [1, "dateFrom"], dateTo: [2, "dateTo"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.merchantID; var currVal_1 = _co.dateFrom; var currVal_2 = _co.dateTo; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_ExistingPatientMapOverViewComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "pleaseChoose"]], [[24, "@ngIfAnimation", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["No merchant selected"])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["class", "character_warning"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, [" Choose your desired merchant from the drop-down above to view their ", "s "])), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = undefined; _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("KEYWORD.patient")); _ck(_v, 6, 0, currVal_1); }); }
function View_ExistingPatientMapOverViewComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-operator-filter", [["id", "part1"]], null, [[null, "getMerchant"], [null, "getDateTo"], [null, "getDateFrom"], [null, "search"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("getMerchant" === en)) {
        var pd_0 = (_co.getMerchant($event) !== false);
        ad = (pd_0 && ad);
    } if (("getDateTo" === en)) {
        var pd_1 = (_co.getDateTo($event) !== false);
        ad = (pd_1 && ad);
    } if (("getDateFrom" === en)) {
        var pd_2 = (_co.getDateFrom($event) !== false);
        ad = (pd_2 && ad);
    } if (("search" === en)) {
        var pd_3 = (_co.applySearch($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i8.View_OperatorFilterComponent_0, i8.RenderType_OperatorFilterComponent)), i1.ɵdid(3, 114688, null, 0, i9.OperatorFilterComponent, [i5.AuthenticationService, i10.MerchantService, i11.ProductService, i12.DentistService], { displayMerchant: [0, "displayMerchant"], displayDateFrom: [1, "displayDateFrom"], displayStatus: [2, "displayStatus"], displayDateTo: [3, "displayDateTo"], displayProduct: [4, "displayProduct"], displayDentist: [5, "displayDentist"], displayAllMerchant: [6, "displayAllMerchant"] }, { getMerchant: "getMerchant", getDateTo: "getDateTo", getDateFrom: "getDateFrom", search: "search" }), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "row clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ExistingPatientMapOverViewComponent_2)), i1.ɵdid(6, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "row clearfix"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ExistingPatientMapOverViewComponent_3)), i1.ɵdid(9, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = true; var currVal_2 = true; var currVal_3 = false; var currVal_4 = true; var currVal_5 = false; var currVal_6 = false; var currVal_7 = false; _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_8 = (_co.merchantID != "none"); _ck(_v, 6, 0, currVal_8); var currVal_10 = (_co.merchantID == "none"); _ck(_v, 9, 0, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hideExtraComponents; _ck(_v, 1, 0, currVal_0); var currVal_9 = _co.hideExtraComponents; _ck(_v, 7, 0, currVal_9); }); }
function View_ExistingPatientMapOverViewComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-operator-filter", [["id", "part1"]], null, [[null, "getMerchant"], [null, "getDateTo"], [null, "getDateFrom"], [null, "search"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("getMerchant" === en)) {
        var pd_0 = (_co.getMerchant($event) !== false);
        ad = (pd_0 && ad);
    } if (("getDateTo" === en)) {
        var pd_1 = (_co.getDateTo($event) !== false);
        ad = (pd_1 && ad);
    } if (("getDateFrom" === en)) {
        var pd_2 = (_co.getDateFrom($event) !== false);
        ad = (pd_2 && ad);
    } if (("search" === en)) {
        var pd_3 = (_co.applySearch($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i8.View_OperatorFilterComponent_0, i8.RenderType_OperatorFilterComponent)), i1.ɵdid(3, 114688, null, 0, i9.OperatorFilterComponent, [i5.AuthenticationService, i10.MerchantService, i11.ProductService, i12.DentistService], { displayMerchant: [0, "displayMerchant"], displayDateFrom: [1, "displayDateFrom"], displayStatus: [2, "displayStatus"], displayDateTo: [3, "displayDateTo"], displayProduct: [4, "displayProduct"], displayDentist: [5, "displayDentist"] }, { getMerchant: "getMerchant", getDateTo: "getDateTo", getDateFrom: "getDateFrom", search: "search" }), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-existing-patient-list-map", [["id", "part1"]], null, null, null, i2.View_ExistingPatientListMapComponent_0, i2.RenderType_ExistingPatientListMapComponent)), i1.ɵdid(5, 770048, null, 0, i3.ExistingPatientListMapComponent, [i4.ExistingPatientService, i5.AuthenticationService, i6.CustomerService], { dateFrom: [0, "dateFrom"], dateTo: [1, "dateTo"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = false; var currVal_2 = true; var currVal_3 = false; var currVal_4 = true; var currVal_5 = false; var currVal_6 = false; _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_7 = _co.dateFrom; var currVal_8 = _co.dateTo; _ck(_v, 5, 0, currVal_7, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hideExtraComponents; _ck(_v, 1, 0, currVal_0); }); }
export function View_ExistingPatientMapOverViewComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ExistingPatientMapOverViewComponent_1)), i1.ɵdid(1, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ExistingPatientMapOverViewComponent_4)), i1.ɵdid(3, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isAdminOrPromoter == true); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.isAdminOrPromoter == false); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_ExistingPatientMapOverViewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-existing-patient-map-overview", [], null, null, null, View_ExistingPatientMapOverViewComponent_0, RenderType_ExistingPatientMapOverViewComponent)), i1.ɵdid(1, 114688, null, 0, i14.ExistingPatientMapOverViewComponent, [i5.AuthenticationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ExistingPatientMapOverViewComponentNgFactory = i1.ɵccf("app-existing-patient-map-overview", i14.ExistingPatientMapOverViewComponent, View_ExistingPatientMapOverViewComponent_Host_0, {}, {}, []);
export { ExistingPatientMapOverViewComponentNgFactory as ExistingPatientMapOverViewComponentNgFactory };
