import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { combineLatest, EMPTY, of } from 'rxjs';
import { debounceTime, expand, map, switchMap, tap } from 'rxjs/operators';
import { AppStateService } from '../../core/app-state/app-state.service';
import { AppState } from '../../core/app-state/app-state.type';
import { AuthenticationService } from '../../core/authentication/shared/authentication.service';
import { ConfirmDialogComponent } from '../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../shared/components/root-component/root-component.component';
import { STANDARD_KEYBOARD_DEBOUNCE_TIME } from '../../shared/config/STANDARD_KEYBOARD_DEBOUNCE_TIME';
import { ConfirmDialog } from '../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../shared/types/notify-app-component';
import { UtilsClass } from '../../shared/types/utils/utils.class';
import { BankAccountListUserComponent } from '../bank-account/bank-account-user/BankAccountListUserComponent';
import { TreatmentDocumentCreateComponent } from '../treatment/treatment-document-create/treatment-document-create.component';
import { BeneficiaryViewComponent } from './beneficiary-view/beneficiary-view.component';
import { Beneficiary, BeneficiaryQuery, BeneficiaryService } from './shared/beneficiary.service';

@Component({
  selector: 'ipv-beneficiary',
  templateUrl: './beneficiary.page.html',
  styleUrls: ['./beneficiary.page.css'],
})
export class BeneficiaryPage {
  util = new UtilsClass();

  isFetchingInitialData = true;

  pageSize = 10;
  pageIndex = 0;

  hiddenColumns = [
    'ID',
    'Label',
    'DateTimeCreated',
    'emails.Email',
    'CalculatedName',
    'Phone.Work.Number',
    'ParentOrganisation_key',
    'ParentOrganisation.Name',
    'PossibleContactTypes',
    'Type_Label',
  ];

  order = ['Company Logo', 'TradingAs', 'Type_Code', 'Active'];

  docListTitle;
  productListTitle;
  contactListTitle;
  contactCreateTitle;

  showDocList = false;
  showProductList = false;
  showContactList = false;
  showContactCreate = false;

  isEditContact = false;

  selectedSupplierID;
  selectedContactID;
  possibleContactTypes;

  // This is to prevent refetch of logos
  checkedItemsWithImages: Partial<Beneficiary>[] = [];

  viewModel$ = this.appStateService.getAppState$().pipe(
    switchMap((appState) =>
      this.getFilters$().pipe(
        switchMap(([tradingAs, typeCode]) =>
          this.fetchData$({ payload: { partTradingAs: tradingAs, typeCode } }).pipe(
            switchMap(() => this.getData$({ isPromoterOrAdmin: appState.isPromoterOrAdmin })),
            switchMap(([beneficiaries]) =>
              this.getViewModel$({
                appState,
                beneficiaries,
                tradingAs,
              })
            )
          )
        )
      )
    )
  );

  constructor(
    private beneficiaryService: BeneficiaryService,
    private appStateService: AppStateService,
    private dialog: MatDialog,
    private router: Router
  ) {}

  getFilters$() {
    return combineLatest([this.getTradingAsFilter$(), this.getTypeCodeFilter$()]);
  }

  getTradingAsFilter$() {
    return this.beneficiaryService.getTradingAsFilter$().pipe(debounceTime(STANDARD_KEYBOARD_DEBOUNCE_TIME));
  }

  setTradingAsFilter(TradingAs: string | null) {
    this.beneficiaryService.setTradingAsFilter(TradingAs);
  }

  getTypeCodeFilter$() {
    return this.beneficiaryService.getTypeCodeFilter$();
  }

  setTypeCodeFilter(typeCodeFilter: string) {
    this.beneficiaryService.setTypeCodeFilter(typeCodeFilter);
  }

  fetchData$(parameters: BeneficiaryQuery) {
    return combineLatest([this.fetchBeneficiarySections$(parameters)]);
  }

  fetchBeneficiarySections$(parameters: BeneficiaryQuery) {
    this.beneficiaryService.setBeneficiaries([]);

    const query: BeneficiaryQuery = {
      payload: Object.assign(
        {
          section: 0,
          orderBy: 'CalculatedName',
          fields:
            'ID,Label,DateTimeCreated,Active,LastModified,TradingAs,emails.Email,CalculatedName,Phone.Work.Number,Type_Code,Type_Label,ParentOrganisation_key,ParentOrganisation.Name,PossibleContactTypes',
        },
        parameters.payload
      ),
    };

    return this.beneficiaryService.fetchBeneficiaries$<Partial<Beneficiary>>(query.payload).pipe(
      tap(() => {
        UtilsClass.stopLoadingInterceptor();
        query.payload.section++;
        UtilsClass.loadingDataSection(query.payload.section);
      }),
      expand((beneficiariesSection) =>
        beneficiariesSection.length > 0
          ? this.beneficiaryService.fetchBeneficiaries$<Partial<Beneficiary>>(query.payload).pipe(
              tap(() => {
                UtilsClass.stopLoadingInterceptor();
              })
            )
          : EMPTY
      ),
        tap((beneficiariesSection) => {
          if (beneficiariesSection.length > 0) {
            query.payload.section++;
            UtilsClass.loadingDataSection(query.payload.section);
            const beneficiaries = this.beneficiaryService.getBeneficiaries();
            const newBeneficiaries = beneficiaries.concat(beneficiariesSection);
            this.beneficiaryService.setBeneficiaries(newBeneficiaries);
          }
        })
    );
  }

  getData$(parameters: { isPromoterOrAdmin }) {
    const { isPromoterOrAdmin } = parameters;
    if (!isPromoterOrAdmin) {
      this.hiddenColumns.push('Active');
      this.order = this.order.filter((orderItem) => orderItem !== 'Active');
    }
    return combineLatest([
      this.beneficiaryService.getBeneficiaries$<Partial<Beneficiary>>().pipe(
        map((beneficiaries) => {
          return beneficiaries.map((beneficiary) => beneficiary);
        })
      ),
    ]);
  }

  setIsChecked(isChecked: boolean, beneficiary: Partial<Beneficiary>, beneficiaries: Partial<Beneficiary>[]) {
    const id = beneficiary.ID;
    const index = beneficiaries.findIndex((item) => item.ID === id);
    beneficiaries[index].isChecked = isChecked;
    this.beneficiaryService.setBeneficiaries(beneficiaries);
  }

  getViewModel$(parameters: { appState: AppState; beneficiaries: Partial<Beneficiary>[]; tradingAs: string }) {
    const { appState, beneficiaries, tradingAs } = parameters;
    this.isFetchingInitialData = false;
    const { user, practice, sessionType, isPromoterOrAdmin, dentist } = appState;
    const result = {
      user,
      practice,
      sessionType,
      isPromoterOrAdmin,
      dentist,
      beneficiaries,
      tradingAs,
    };
    return of(result);
  }

  selectAll(beneficiaries: Partial<Beneficiary>[]) {
    const allSelectedBeneficiaries = beneficiaries.map((beneficiary) => ({ ...beneficiary, isChecked: true }));
    this.checkedItemsWithImages = allSelectedBeneficiaries;
    this.beneficiaryService.setBeneficiaries(allSelectedBeneficiaries);
  }

  unSelectAll(beneficiaries: Partial<Beneficiary>[]) {
    const allSelectedBeneficiaries = beneficiaries.map((beneficiary) => ({ ...beneficiary, isChecked: false }));
    this.checkedItemsWithImages = allSelectedBeneficiaries;
    this.beneficiaryService.setBeneficiaries(allSelectedBeneficiaries);
  }

  selectPage(beneficiaries: Partial<Beneficiary>[]) {
    const pageIndex = this.pageIndex + 1;
    const pageSize = this.pageSize;
    const itemsAfterFirstItem = pageSize - 1;
    const lastItemIndex = pageIndex * pageSize - 1;
    const firstItemIndex = lastItemIndex - itemsAfterFirstItem;

    for (let index = firstItemIndex; index < lastItemIndex + 1; index++) {
      if (beneficiaries[index]) {
        beneficiaries[index].isChecked = true;
      }
    }
    this.checkedItemsWithImages = beneficiaries;
    this.beneficiaryService.setBeneficiaries(beneficiaries);
  }

  disableGroup() {
    const selectedIDs = this.checkedItemsWithImages.map((beneficiary) => beneficiary.ID);
    const confirmDialog = new ConfirmDialog(
      'cancel',
      'Disable Selected Suppliers',
      '<p>Once the Supplier is disabled it will not appear as an option for the user.</p>',
      'Cancel',
      'Okay'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
      // SET TO PAYING CASH CARD
    });
    ref.componentInstance.onConfirm.subscribe((val) => {
      if (val === true) {
        ref.close();
        ref.afterClosed().subscribe((res) => {
          const payload = {
            isActive: false,
            ids: selectedIDs.join(','),
          };
          this.beneficiaryService.editBeneficiarytGroup(payload).subscribe((disabledBeneficiaries) => {
            disabledBeneficiaries.forEach((disabledBeneficiary) => {
              const index = this.checkedItemsWithImages.findIndex(
                (beneficiary) => beneficiary.ID === disabledBeneficiary.ID
              );
              this.checkedItemsWithImages[index] = disabledBeneficiary;
            });

            this.unSelectAll(this.checkedItemsWithImages);
            NotifyAppComponent.displayToast('success', 'Success!', 'Products are disabled.');
          });
        });
      } else {
        ref.close();
      }
    });
  }

  enableGroup() {
    const selectedIDs = this.checkedItemsWithImages.map((beneficiary) => beneficiary.ID);

    const confirmDialog = new ConfirmDialog(
      'cancel',
      'Enable Selected Suppliers',
      '<p>Once the Supplier is enabled it will not appear as an option for the user.</p>',
      'Cancel',
      'Okay'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
      // SET TO PAYING CASH CARD
    });

    ref.componentInstance.onConfirm.subscribe((val) => {
      if (val === true) {
        ref.close();
        ref.afterClosed().subscribe((res) => {
          const payload = {
            isActive: true,
            ids: selectedIDs.join(','),
          };
          this.beneficiaryService.editBeneficiarytGroup(payload).subscribe((enabledBeneficiaries) => {
            enabledBeneficiaries.forEach((enabledBeneficiary) => {
              const index = this.checkedItemsWithImages.findIndex(
                (beneficiary) => beneficiary.ID === enabledBeneficiary.ID
              );
              this.checkedItemsWithImages[index] = enabledBeneficiary;
            });

            this.unSelectAll(this.checkedItemsWithImages);
            NotifyAppComponent.displayToast('success', 'Success!', 'Products are enabled.');
          });
        });
      } else {
        ref.close();
      }
    });
  }

  addDocumentGroup(beneficiaries: Partial<Beneficiary>[]) {
    const selectedIDs = beneficiaries.map((beneficiary) => beneficiary.ID);
    const confirmDialog = new ConfirmDialog(
      'cancel',
      'Add New Document',
      '<p>You are about to create a document for multiple Suppliers</p>',
      'Cancel',
      'Okay'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
      // SET TO PAYING CASH CARD
    });
    ref.componentInstance.onConfirm.subscribe((val) => {
      if (val === true) {
        ref.close();
        ref.afterClosed().subscribe((res) => {
          const ref2 = this.dialog.open(TreatmentDocumentCreateComponent, {
            width: '750px',
            data: {
              multiCreateSupplier: true,
              _groupFilter: 'supplier',
              displayDocumentAssignment: false,
            },
          });

          ref2.componentInstance.getDocument.subscribe((payload) => {
            if (payload) {
              payload.ids = selectedIDs.join(',');

              this.beneficiaryService.addDocumentBeneficiaryGroup(payload).subscribe((response) => {
                if (response && response.length > 0) {
                  ref2.close();

                  this.unSelectAll(beneficiaries);

                  NotifyAppComponent.displayToast('success', 'Success!', 'Documents are added.');
                }
              });
            }
          });

          ref2.componentInstance.close.subscribe(() => {
            ref2.close();
          });
        });
      } else {
        ref.close();
      }
    });
  }

  openQuickViewDialog(ID) {
    const ref = this.dialog.open(BeneficiaryViewComponent, {
      data: ID,
      width: '600px',
      panelClass: 'noCard',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  editSupplier(ID, parentID) {
    if (parentID) {
      this.router.navigate([
        '/merchant',
        {
          outlets: {
            page: ['subsidiary-edit', ID],
          },
        },
      ]);
    } else {
      this.router.navigate([
        '/merchant',
        {
          outlets: {
            page: ['beneficiary-edit', ID],
          },
        },
      ]);
    }
  }

  addDocument(supplierID) {
    const ref = this.dialog.open(TreatmentDocumentCreateComponent, {
      width: '750px',
      data: {
        supplierID,
        _groupFilter: 'supplier',
        displayDocumentAssignment: false,
      },
    });

    ref.componentInstance.create.subscribe((res) => {
      if (res) {
        ref.close();
      }
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  viewDocuments(id, label) {
    this.showDocList = true;
    this.selectedSupplierID = id;
    this.docListTitle = 'Documents for ' + label;
  }

  viewProducts(id, label) {
    this.showProductList = true;
    this.selectedSupplierID = id;
    this.productListTitle = 'Treatment Products for: ' + label;
  }

  createBankAccounts(ID, userName) {
    this.router.navigate([
      '/merchant',
      {
        outlets: {
          page: ['bank-account-create', ID, userName],
        },
      },
    ]);
  }

  viewBankAccounts(ID, userName) {
    const ref = this.dialog.open(BankAccountListUserComponent, {
      data: {
        userID: ID,
        userName,
      },
      width: '800px',
    });

    ref.componentInstance.closeModal.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  contact(id) {
    const data = {
      targetType: 'beneficiary',
      targetID: id,
      asGuest: false,
      asProfile: true,
      asPractice: true,
    };
    AuthenticationService.contactInputEvent.emit(data);
  }

  createNewContact(ID, name, possibleContactTypes) {
    this.selectedSupplierID = ID;
    this.contactCreateTitle = name;
    this.possibleContactTypes = possibleContactTypes;
    this.showContactCreate = true;
  }

  viewContactsList(id, name) {
    this.showContactList = true;
    this.selectedSupplierID = id;
    this.contactListTitle = name;
  }

  disable(ID, beneficiaries: Partial<Beneficiary>[]) {
    const confirmDialog = new ConfirmDialog(
      'cancel',
      'Disable Supplier',
      '<p>Once the Supplier is disabled it will not appear as an option for the user.</p>',
      'Cancel',
      'Okay'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
      // SET TO PAYING CASH CARD
    });
    ref.componentInstance.onConfirm.subscribe((val) => {
      if (val == true) {
        ref.close();
        ref.afterClosed().subscribe((res) => {
          const payload = {
            active: false,
          };
          this.beneficiaryService.editBeneficiary(ID, payload).subscribe((res) => {
            if (res) {
              const index = beneficiaries.findIndex((beneficiary) => beneficiary.ID === res.ID);
              beneficiaries[index] = res;

              this.beneficiaryService.setBeneficiaries(beneficiaries);

              NotifyAppComponent.displayToast('success', 'Success!', 'Supplier is disabled.');
            }
          });
        });
      } else {
        ref.close();
      }
    });
  }

  enable(ID, beneficiaries: Partial<Beneficiary>[]) {
    const confirmDialog = new ConfirmDialog(
      'check_circle',
      'Enable Supplier',
      '<p>Once the Supplier is enabled it will  appear as an option for the user.</p>',
      'Cancel',
      'Okay'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
      // SET TO PAYING CASH CARD
    });
    ref.componentInstance.onConfirm.subscribe((val) => {
      if (val == true) {
        ref.close();
        ref.afterClosed().subscribe((res) => {
          const payload = {
            active: true,
          };
          this.beneficiaryService.editBeneficiary(ID, payload).subscribe((res) => {
            if (res) {
              const index = beneficiaries.findIndex((beneficiary) => beneficiary.ID === res.ID);
              beneficiaries[index] = res;
              this.beneficiaryService.setBeneficiaries(beneficiaries);
              NotifyAppComponent.displayToast('success', 'Success!', 'Supplier is enabled.');
            }
          });
        });
      } else {
        ref.close();
      }
    });
  }

  openCatelogueVIew(ID) {
    this.router.navigate([
      '/merchant',
      {
        outlets: {
          page: ['beneficiary-catalogue-view', ID],
        },
      },
    ]);
  }

  hideViewDetails() {
    this.showDocList = false;
    this.showProductList = false;
    this.showContactList = false;
    this.showContactCreate = false;
  }

  hideContactEdit() {
    this.isEditContact = false;
    this.selectedContactID = null;
  }

  openContactEditEvent(e) {
    if (e) {
      this.isEditContact = true;
      this.selectedContactID = e;
    }
  }

  countSelectedItems(beneficiaries: Partial<Beneficiary>[]) {
    return beneficiaries.filter((beneficiary) => beneficiary.isChecked).length;
  }

  updateIsChecked(beneficiaries: Partial<Beneficiary>[]) {
    this.checkedItemsWithImages = beneficiaries;
  }

  setPage(pageEvent: PageEvent) {
    this.pageIndex = pageEvent.pageIndex;
    this.pageSize = pageEvent.pageSize;
  }
}
