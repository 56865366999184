<div class="row clearfix" *ngIf="isModal!=true">
  <p class="lead rm-mb rm-mt" *ngIf="title">{{ title }}</p>
  <p class="small">
    {{description}}
  </p>
  <form #passwordChange="ngForm">
    <div class="row clearfix" *ngIf="isExistingPassword == true">
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>{{existingTitle}}</mat-label>
        <input [(ngModel)]="oldPassword" matInput name="p1" placeholder="{{existingTitle}}" value=""
          [type]="showPassword1!=true ? 'password' : 'text'" required autocomplete="off" />

        <mat-icon class="shp" (click)="changeHide1()" matSuffix>
          {{ showPassword1!=true ? 'visibility_off' : 'visibility' }}</mat-icon>

      </mat-form-field>
    </div>
    <div class="row clearfix">
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>New Password</mat-label>
        <input [(ngModel)]="newPassword1" [min]="8" matInput name="p2" placeholder="New Password" value=""
          [type]="showPassword2!=true ? 'password' : 'text'" pattern="^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,}$" required
          autocomplete="off" />

        <mat-icon class="shp" (click)="changeHide2()" matSuffix>
          {{ showPassword2!=true ? 'visibility_off' : 'visibility' }}</mat-icon>
      </mat-form-field>
    </div>
    <div class="row clearfix" *ngIf="newPassword1" @simpleFadeAnimation>
      <p class="small rm-mt">Password strength</p>
      <password-strength-meter [password]="newPassword1"></password-strength-meter>
    </div>
    <div class="row clearfix">
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>Confirm New Password</mat-label>
        <input [(ngModel)]="newPassword2" [min]="8" matInput name="p3" placeholder="Confirm New Password" value=""
          [type]="showPassword3!=true ? 'password' : 'text'" pattern="^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,}$" required
          autocomplete="off" />

        <mat-icon class="shp" (click)="changeHide3()" matSuffix>
          {{ showPassword3!=true ? 'visibility_off' : 'visibility' }}</mat-icon>

      </mat-form-field>
    </div>

    <!-- <p class="small">Your password must be 8-20 characters long, contain letters and numbers, and must not contain spaces, special characters, or emoji.</p> -->

    <div class="full-width row clearfix">

      <button class="pull-right" [disabled]="!passwordChange.form.valid || newPassword2 != newPassword1"
        (click)="updatePassword()" mat-raised-button color="accent">
        {{ buttonText }}
      </button>

    </div>
  </form>
</div>


<mat-card class="rel supplier-card clearfix mb" *ngIf="isModal==true">
  <mat-dialog-content class="stacked-card-view">
    <div class="card-header primary-gradient-img clearfix stacked">
      <div class="row clearfix">
        <button class="btn-close btn-clear mat-button white" *ngIf="canClose==true" (click)="closeEvent()">
          <mat-icon>clear</mat-icon>
        </button>
        <div class="row titleArea clearfix">
          <h2 class="summary-header rm-m text-left">
            {{ title }}

          </h2>

        </div>
      </div>
    </div>

    <div class="full-width  clearfix row">

      <div class="row clearfix mt">

        <p class="small  mb">
          {{description}}
        </p>
        <form #passwordChange="ngForm">
          <div class="row clearfix" *ngIf="isExistingPassword == true">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>{{existingTitle}}</mat-label>
              <input [(ngModel)]="oldPassword" matInput name="p1" placeholder="{{existingTitle}}" value=""
                [type]="showPassword1!=true ? 'password' : 'text'" required autocomplete="off" />

              <mat-icon class="shp" (click)="changeHide1()" matSuffix>
                {{ showPassword1!=true ? 'visibility_off' : 'visibility' }}</mat-icon>

            </mat-form-field>
          </div>
          <div class="row clearfix">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>New Password</mat-label>
              <input [(ngModel)]="newPassword1" [min]="8" matInput name="p2" placeholder="New Password" value=""
                [type]="showPassword2!=true ? 'password' : 'text'" pattern="^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,}$"
                required autocomplete="off" />

              <mat-icon class="shp" (click)="changeHide2()" matSuffix>
                {{ showPassword2!=true ? 'visibility_off' : 'visibility' }}</mat-icon>
            </mat-form-field>
          </div>
          <div class="row clearfix" *ngIf="newPassword1" @simpleFadeAnimation>
            <p class="small rm-mt">Password strength</p>
            <password-strength-meter [password]="newPassword1"></password-strength-meter>
          </div>
          <div class="row clearfix">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Confirm New Password</mat-label>
              <input [(ngModel)]="newPassword2" [min]="8" matInput name="p3" placeholder="Confirm New Password" value=""
                [type]="showPassword3!=true ? 'password' : 'text'" pattern="^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,}$"
                required autocomplete="off" />

              <mat-icon class="shp" (click)="changeHide3()" matSuffix>
                {{ showPassword3!=true ? 'visibility_off' : 'visibility' }}</mat-icon>

            </mat-form-field>
          </div>

          <!-- <p class="small">Your password must be 8-20 characters long, contain letters and numbers, and must not contain spaces, special characters, or emoji.</p> -->

          <div class="full-width text-center row clearfix" *ngIf="isLogout!=true">

            <button class="text-center mt btn-large"
              [disabled]="!passwordChange.form.valid || newPassword2 != newPassword1 || disableButton==true"
              (click)="updatePassword()" mat-raised-button color="accent">
              {{ buttonText }}
            </button>

          </div>



          <div class="full-width text-center row clearfix" *ngIf="isLogout==true">



            <app-logout class="   pull-left logout-description mt " (close)="closeEvent()"></app-logout>

            <button class="text-center mt btn-large pull-right"
              [disabled]="!passwordChange.form.valid || newPassword2 != newPassword1 || disableButton==true"
              (click)="updatePassword()" mat-raised-button color="accent">
              {{ buttonText }}
            </button>

          </div>
        </form>
      </div>


    </div>
  </mat-dialog-content>
</mat-card>
