import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';

class PaymentsReportState {
  printButtonClicked: 'merchant-fees' | 'patient-payments';
}

@Injectable()
export class SettlementPaymentsReportService extends ComponentStore<PaymentsReportState> {
  constructor() {
    super({
      printButtonClicked: null,
    });
  }

  getPrintButtonClicked() {
    return this.select((state) => state.printButtonClicked);
  }

  setPrintButtonClicked(tableName: 'merchant-fees' | 'patient-payments') {
    this.patchState({ printButtonClicked: tableName });
  }
}
