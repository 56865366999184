import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'sr-emoji-input-modal',
  templateUrl: './emoji-input-modal.component.html',
  styleUrls: ['./emoji-input-modal.component.css'],
})
export class EmojiInputModalComponent implements OnInit {
  @Output() closed: EventEmitter<void> = new EventEmitter();
  @Output() emojiClicked: EventEmitter<void> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  close() {
    this.closed.emit();
  }

  selectEmoji(emoji) {
    this.emojiClicked.emit(emoji.emoji.native);
  }
}
