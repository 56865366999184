<div class="rel dash intro">
  <div class="full-width text-center">
    <div class="card-header primary-gradient-img clearfix inModal rel">
      <div class="row clearfix content">
        <div class="row titleArea clearfix">
          <h2 class="summary-header rm-m" *ngIf="isEditMode == true">Edit Simple document</h2>

          <h2 class="summary-header rm-m" *ngIf="isEditMode == false">Create Simple document</h2>
        </div>
      </div>
    </div>
  </div>

  <mat-dialog-content>
    <div class="full-width flex">
      <div class="full-width">
        <div class="row clearfix flex mt">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Title</mat-label>
            <input
              [(ngModel)]="title"
              (ngModelChange)="changeTitle()"
              matInput
              placeholder="Title"
              name="title"
              required
            />
          </mat-form-field>
        </div>

        <div class="row clearfix flex mt" *ngIf="type && isEditMode != true">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Code</mat-label>
            <input [(ngModel)]="type" matInput placeholder="Code" name="type" required/>
          </mat-form-field>
        </div>

        <div class="row clearfix">
          <app-text-editor-input
            class="view-editor-sub-feature clearfix"
            [content]="_content"
            [isMultiView]="false"
            [view]="'editor'"
            [title]="''"
            [isPreview]="false"
            [isAction]="false"
            (getContent)="getContent($event)"
            name="editor"
          >
          </app-text-editor-input>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <div class="drop-button text-center full-width mt">
    <button class="pull-right btn" (click)="save()" mat-raised-button color="accent">Save</button>

    <button class="pull-left btn" (click)="closeEvent()" mat-raised-button color="primary">Close</button>
  </div>
</div>
