<div class="card-header primary-gradient-img clearfix inModal">
  <div class="row clearfix">
    <button class="btn-close btn-clear mat-button white" (click)="closeEvent()">
      <mat-icon>clear</mat-icon>
    </button>
    <div class="row titleArea clearfix">
      <mat-icon class="pull-left fas fa-search-dollar"
        style="font-size: 3rem; margin-right: 20px; line-height: 3rem; height: 3rem; width: 3rem"></mat-icon>
      <h2 class="summary-header rm-m">
        Request new Settlement
        <span class="subLabel">Please select type of your Settlement</span>
      </h2>
    </div>
  </div>
</div>

<mat-dialog-content>
  <div class="full-width  flex" *ngIf="error && proceedType==='none'">

    <p class="info-request full-width rm-mt">

      <mat-icon class="fas fa-exclamation-circle pull-left  mr"></mat-icon>

      <span [innerHTML]="error | safeHtml"> </span>
    </p>
  </div>

  <div class="full-width  flex" *ngIf="error && proceedType==='approve'">

    <p class="info-request full-width rm-mt">

      <mat-icon class="fas fa-exclamation-circle pull-left mr"></mat-icon>

      <span> {{error}} </span>

      <button class="ml btn pull-right" (click)="getCustomer()" type="button" mat-raised-button color="primary">
        View Application Settlements
      </button>

    </p>
  </div>



  <div class="row clearfix full-width flex flex-wrap">
    <button class="selection-btn button gradient yellow  animated zoomIn faster" (click)="sendType('finance')"
      mat-raised-button>
      <div class="btn-container">

        <div class="image-container-settlement"
          style="background-image: url('https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/8667924.jpg');">


        </div>

        <div class="text-container-settlement full-width" *ngIf=" context!='breeze'">
          <h3 class="">
            Settlement
            <mat-icon>chevron_right</mat-icon>
          </h3>
          <p class="small">
            Submit a request for the settlement as {{'treatment' | translate}} is provided to the
            {{'Patient' | translate}}.



          </p>

        </div>
        <div class="text-container-settlement full-width" *ngIf=" context=='breeze'">
          <h3 class="">
            Settlement
            <mat-icon>chevron_right</mat-icon>
          </h3>
          <p class="small">
            Submit a request for settlement when goods or services have been delivered to the {{'Patient' | translate}}.



          </p>

        </div>
      </div>
    </button>

    <button class="selection-btn button gradient green animated zoomIn faster delay-0-3s" *ngIf="disable==false "
      (click)="sendType('material')" mat-raised-button>
      <div class="btn-container">
        <div *ngIf="context!='p4p' &&  context!='breeze'" class="image-container-settlement"
          style="background-image: url('https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/2024-05-31_00h53_30.png');">
        </div>

        <div *ngIf="context=='p4p'" class="image-container-settlement"
          style="background-image: url('https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/2024-05-31_03h00_sa45sss.jpg');">
        </div>


        <div *ngIf="context=='breeze'" class="image-container-settlement"
          style="background-image: url('https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/vacc.jpg');">
        </div>
        <div class="text-container-settlement full-width" *ngIf=" context!='breeze'">
          <h3 class="">
            Materials Advance
            <mat-icon>chevron_right</mat-icon>
          </h3>
          <p class="small">
            Submit a request for the purchase of materials (e.g. lab fees) before providing the
            {{'Patient' | translate}} with {{'treatment' | translate}}.
          </p>

        </div>

        <div class="text-container-settlement full-width" *ngIf=" context=='breeze'">
          <h3 class="">
            Materials Advance
            <mat-icon>chevron_right</mat-icon>
          </h3>
          <p class="small">
            Submit a request for the purchase of materials before providing goods or services to the
            {{'Patient' | translate}}.



          </p>

        </div>
      </div>
    </button>

    <button class="selection-btn button gradient gray animated zoomIn faster delay-0-3s" *ngIf="disable== true"
      mat-raised-button>
      <div class="btn-container">
        <div *ngIf="context!='p4p' &&  context!='breeze'" class="image-container-settlement"
          style="background-image: url('https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/2024-05-31_00h53_30disabled.png');">
        </div>

        <div *ngIf="context=='p4p'" class="image-container-settlement"
          style="background-image: url('https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/2024-05-31_03h00_sa45sss147a.jpg');">
        </div>


        <div *ngIf="context=='breeze'" class="image-container-settlement"
          style="background-image: url('https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/vaccdisabled.jpg');">
        </div>


        <div class="text-container-settlement full-width">
          <h3 class="">
            Materials Advance
            <mat-icon>chevron_right</mat-icon>
          </h3>
          <p class="small">
            Promote your {{ "KEYWORD.practice" | translate | titlecase}}, by creating a landing page of your news ,
            services and
            products.
          </p>

        </div>
      </div>
    </button>




  </div>
</mat-dialog-content>
