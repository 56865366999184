import { Component, EventEmitter, Inject, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { MerchantService } from '../../merchant/shared/merchant.service';

@Component({
  selector: 'app-medical-history-privacy-modal',
  templateUrl: './medical-history-privacy-modal.component.html',
  styleUrls: ['./medical-history-privacy-modal.component.css'],
})
export class MedicalHistoryPrivacyModalComponent implements OnInit {
  @Output()
  closeModal = new EventEmitter();

  dentistID;
  dentist;
  merchant;
  sessionType = 'guest';

  constructor(
    private activeRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private merchantService: MerchantService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      this.dentistID = data;
    }
  }

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((sessionType) => {
      if (sessionType) {
        this.sessionType = sessionType;
      }

      if (this.dentistID) {
        this.merchantService.getMerchantDetails(this.dentistID, {}, this.sessionType).subscribe((res) => {
          this.merchant = res;
        });
      }
    });
  }

  close() {
    this.closeModal.emit(true);
  }
}
