<ipv-page
  *ngIf="viewModel$ | async as viewModel"
  [dataHeader]="{
    title: 'Serialised Products',
    counterValue: viewModel.productCustomers.length,
    imageSrc: null
  }"
  [isFetchingInitialData]="isFetchingInitialData"
  [headerSize]="'smaller'"
>
  <div class="row clearfix flex mb ctl">
    <div class="full-width flex">
      <button
        *ipvIsPromoterOrAdmin
        (click)="add(viewModel.industryType, viewModel.practice)"
        mat-raised-button
        style="margin-right: 5px"
        color="primary"
      >
        <mat-icon>add</mat-icon>
        Add Serialised Product
      </button>
    </div>
  </div>

  <ipv-data-table-control-container class="mt">
    <mat-form-field class="full-width" appearance="outline">
      <mat-label>Name</mat-label>
      <div style="display: flex">
        <input
          class="clearfix"
          [value]="viewModel.calculatedNameFilter"
          (input)="setCustomerCalculatedNameFilter($event.target.value)"
          name="Labels"
          matInput
        />
        <ipv-clear-search-button *ngIf="viewModel.calculatedNameFilter" (click)="setCustomerCalculatedNameFilter('')">
        </ipv-clear-search-button>
      </div>
    </mat-form-field>

    <mat-form-field class="full-width" appearance="outline">
      <mat-label>Email</mat-label>
      <div style="display: flex">
        <input
          class="clearfix"
          [value]="viewModel.emailFilter"
          (input)="setCustomerEmailFilter($event.target.value)"
          name="Labels"
          matInput
        />
        <ipv-clear-search-button *ngIf="viewModel.emailFilter" (click)="setCustomerEmailFilter('')">
        </ipv-clear-search-button>
      </div>
    </mat-form-field>

    <mat-form-field class="full-width" appearance="outline">
      <mat-label>Product</mat-label>
      <div style="display: flex">
        <input
          class="clearfix"
          [value]="viewModel.productLabelFilter"
          (input)="setCustomerProductLabelFilter($event.target.value)"
          name="Labels"
          matInput
        />
        <ipv-clear-search-button *ngIf="viewModel.productLabelFilter" (click)="setCustomerProductLabelFilter('')">
        </ipv-clear-search-button>
      </div>
    </mat-form-field>
  </ipv-data-table-control-container>

  <ipv-data-table
    [customComponents]="{
      CustomerNumber: phoneNUmber,
      PurchaseDate: purchaseDate,
      SellPrice: sellPrice,
      SerialNumber: serialNumber
    }"
    [titleCaseEnabled]="false"
    [table]="viewModel.productCustomers"
    [hide]="hiddenColumns"
    [rename]="renamedColumns"
    [order]="order"
    [menuTemplate]="actions"
    (itemClicked)="viewProduct($event, viewModel.industryType, viewModel.practice)"
  >
  </ipv-data-table>

  <ng-template #purchaseDate let-record>
    {{ record.PurchaseDate | customDate }}
  </ng-template>

  <ng-template #phoneNUmber let-record>
    {{ record.CustomerNumber | customPhone }}
  </ng-template>

  <ng-template #sellPrice let-record>
    {{ record.SellPrice | customCurrency }}
  </ng-template>

  <ng-template #serialNumber let-record>
    <span *ngIf="record.SerialNumber.length > 0"> {{ record.SerialNumber }} </span>
    <span *ngIf="!record.SerialNumber"> N/A </span>
  </ng-template>

  <ng-template #actions let-record>
    <button (click)="viewProduct(record, viewModel.industryType, viewModel.practice)" mat-menu-item>
      <mat-icon class="fas fa-box-open"></mat-icon>
      <span>View Product</span>
    </button>

    <button
      *ipvIsPromoterOrAdmin
      (click)="updateProduct(record, viewModel.industryType, viewModel.practice)"
      mat-menu-item
    >
      <mat-icon>description</mat-icon>
      <span>Edit Product</span>
    </button>
  </ng-template>
</ipv-page>
