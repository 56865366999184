<div class="rel" *ngIf="listDB.firstLoadEvent == false" @ngIfAnimation>
  <div class="row clearfix padded rel">
    <h1 class="dataHeader rm-mt transparent">Fetching data...</h1>
  </div>
</div>

<div [hidden]="listDB.data.length == 0">
  <!--
    <p class=" pull-right" *ngIf="dateFrom!=null || dateTo!=null ">
      <span *ngIf="dateFrom">From:
        <strong>{{dateFrom | customDateTime}} </strong>
      </span>

      <span *ngIf="dateTo"> To:
        <strong>{{dateTo | customDateTime}}</strong>
      </span>
    </p> -->
  <div>
    <div class="row clearfix pull-right noteGroupFilter">
      <mat-button-toggle-group
        class="btn-group text-center full-width with-icons"
        #groupB="matButtonToggleGroup"
        *ngIf="displayToggleSwitch == true"
        [(ngModel)]="groupFilter"
        (ngModelChange)="changeGroupFilter($event)"
      >
        <mat-button-toggle class="text-center full-width" [value]="'merchant'">
          <mat-icon class="fas {{'fa-tooth' | translate}}"></mat-icon> Merchant view activities
        </mat-button-toggle>
        <mat-button-toggle class="text-center full-width" [value]="'all'">
          <mat-icon class="fas fa-infinity"></mat-icon> All activities
        </mat-button-toggle>
      </mat-button-toggle-group>

      <button class="" *ngIf="targetID" (click)="createNote(targetID)" mat-raised-button color="accent">
        New Note
      </button>
    </div>

    <h1 class="dataHeader">
      <!-- Contracts -->
      Audit History
      <span class="badge" [endVal]="dataSource.filteredData.length" countUp></span>
    </h1>
    <mat-card class="list-card" [class.mat-elevation-z0]="shadow == false">
      <!-- Search -->
      <div class="row clearfix searchArea primary-gradient-img">
        <div class="row justify flex">
          <div class="qtr-width flex">
            <mat-icon class="schIcon mr mt">search</mat-icon>
            <mat-form-field class="schSel rm-ml" appearance="outline">
              <mat-label>Add Content Filters</mat-label>
              <mat-select class="schSel" [formControl]="searchVals" matInput placeholder="Add Content Filters" multiple>
                <mat-select-trigger>
                  {{ searchVals.value ? searchVals.value[0] : '' }}
                  <span class="additional-selection" *ngIf="searchVals.value?.length > 1">
                    (+{{ searchVals.value.length - 1 }} others)
                  </span>
                </mat-select-trigger>
                <mat-option *ngFor="let searchVal of searchValList" [value]="searchVal">{{ searchVal }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="full-width clearfix flex animated fadeInDown" *ngIf="searchVals.value?.length > 0">
            <mat-form-field
              class="full-width"
              *ngIf="searchVals.value?.indexOf('Patient Name') !== -1"
              appearance="outline"
            >
              <mat-label>{{"KEYWORD.patient" | translate | titlecase}} Name</mat-label>
              <input
                class="clearfix"
                (input)="setFilter($event.target.value, 'Who.Name')"
                name="PatientName"
                [placeholder]="('KEYWORD.patient' | translate | titlecase) + ' Name'"
                matInput
              />
            </mat-form-field>
            <mat-form-field
              class="full-width"
              *ngIf="searchVals.value?.indexOf('Operator Name') !== -1"
              appearance="outline"
            >
              <mat-label>Operator Name</mat-label>
              <input
                class="clearfix"
                (input)="setFilter($event.target.value, 'Operator_Name')"
                name="OperatorName"
                placeholder="Operator Name"
                matInput
              />
            </mat-form-field>
            <!-- <mat-form-field class=" full-width" *ngIf="searchVals.value?.indexOf('MerchantName') !== -1 ">
              <mat-label>Merchant Name</mat-label>
              <mat-select name="MerchantName" class=" full-width" placeholder="Merchant Name"
                (selectionChange)="setFilter($event.value ,'MerchantName')">
                <mat-option [value]="null">
                  {{"All" | translate}}
                </mat-option>
                <mat-option
                  *ngFor="let e of listDB.data  | objectPropertyValue : 'MerchantName' | unique | without: ['',null] | orderBy "
                  [value]="e">
                  {{e}}
                </mat-option>
              </mat-select>
            </mat-form-field> -->
            <mat-form-field class="full-width" *ngIf="searchVals.value?.indexOf('Type') !== -1" appearance="outline">
              <mat-label>Note Type</mat-label>
              <mat-select
                class="full-width"
                (selectionChange)="setFilter($event.value, 'Action')"
                name="Type"
                placeholder="Note Type"
              >
                <mat-option [value]="null">
                  All
                </mat-option>
                <mat-option
                  *ngFor="let e of listDB.data | objectPropertyValue: 'Action' | unique | without: ['', null] | orderBy"
                  [value]="e"
                >
                  {{ e }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="ml timeOutput clearfix" *ngIf="dateFrom != null || dateTo != null">
            <mat-icon class="pull-left mt">schedule</mat-icon>
            <div class="pull-right">
              <p class="small rm-mb">
                <span *ngIf="dateFrom"
                >From:
                  <strong>{{ dateFrom | customDateTime }} </strong>
                </span>
              </p>
              <p class="small rm-mt">
                <span *ngIf="dateTo">
                  To:
                  <strong>{{ dateTo | customDateTime }}</strong>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- Table -->
      <mat-table class="list-table" #table [dataSource]="dataSource" matSort>
        <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->
        <!-- ID Column -->

        <ng-container cdkColumnDef="Icon">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header></mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="openNoteView(row['ID'])">
            <mat-icon
              class="fa"
              [ngClass]="actionLogUtil.getSubjectIcon(row['Action'], row['Subject'])"
              [ngStyle]="{ color: actionLogUtil.getSubjectColor(row['Action'], row['Subject']) }"
            ></mat-icon>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Date">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Date</mat-header-cell>
          <mat-cell
            *cdkCellDef="let row"
            (click)="openNoteView(row['ID'])"
            matTooltip="{{ row['DateTimeCreated'] | customDate }}"
          >
            {{ row['DateTimeCreated'] | customDate }}
          </mat-cell>
        </ng-container>
        <ng-container cdkColumnDef="PatientName">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header>{{ "KEYWORD.patient" | translate | titlecase }}Name
          </mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="openNoteView(row['ID'])" matTooltip="{{ row['Who.Name'] }}">
            {{ row['Who.Name'] }}
          </mat-cell>
        </ng-container>
        <ng-container cdkColumnDef="OperatorName">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Operator Name</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="openNoteView(row['ID'])" matTooltip="{{ row['Operator_Name'] }}">
            {{ row['Operator_Name'] }}
          </mat-cell>
        </ng-container>
        <ng-container cdkColumnDef="MerchantName">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Merchant Name</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="openNoteView(row['ID'])" matTooltip="{{ row['MerchantName'] }}">
            {{ row['MerchantName'] }}
          </mat-cell>
        </ng-container>
        <ng-container cdkColumnDef="Type">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Type</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="openNoteView(row['ID'])" matTooltip="{{ row['Action'] }}">
            {{ row['Action'] }}
          </mat-cell>
        </ng-container>
        <ng-container cdkColumnDef="Subject">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Subject</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="openNoteView(row['ID'])" matTooltip="{{ row['Subject'] }}">
            {{ row['Subject'] }}
          </mat-cell>
        </ng-container>
        <ng-container cdkColumnDef="Result">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Result</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="openNoteView(row['ID'])" matTooltip="{{ row['Result'] }}">
            {{ row['Result'] }}
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Description">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Description</mat-header-cell>
          <mat-cell
            *cdkCellDef="let row"
            (click)="openNoteView(row['ID'])"
            matTooltip="{{ displayDescription(row['Description']) }}"
          >
            {{ displayDescription(row['Description']) }}
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="RelatedTo">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Related To</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="openNoteView(row['ID'])" matTooltip="{{ row['What.Table'] }}">
            {{ row['What.Table'] }}
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="fieldColor">
          <mat-header-cell *cdkHeaderCellDef></mat-header-cell>
          <mat-cell
            *cdkCellDef="let row"
            [ngStyle]="{ background: actionLogUtil.getSubjectColor(row['Action'], row['Subject']) }"
          >
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Actions">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Actions</mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <div style="float: right">
              <button [matMenuTriggerFor]="menu" mat-icon-button>
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button *ngIf="row['ID']" (click)="openNoteView(row['ID'])" mat-menu-item>
                  <mat-icon>description</mat-icon>
                  <span>{{ 'Note Details' | translate }}</span>
                </button>

                <button
                  *ngIf="row['Who.key'] && (row['Who.Table'] == 'Prospect' || row['Who.Table'] == 'Customer')"
                  (click)="createNote(row['Who.key'])"
                  mat-menu-item
                >
                  <mat-icon>subject</mat-icon>
                  <span>Create Note</span>
                </button>

                <button *ngIf="row['Who.key']" (click)="openPatientView(row['Who.key'])" mat-menu-item>
                  <mat-icon>person</mat-icon>
                  <span>{{ "KEYWORD.patient" | translate | titlecase}} View</span>
                </button>
              </mat-menu>
            </div>
          </mat-cell>
        </ng-container>
        <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row
          class="listRow separatedRow"
          *cdkRowDef="let row; columns: displayedColumns"
          [ngStyle]="{ 'border-top': '2px solid ' + actionLogUtil.getSubjectColor(row['Action'], row['Subject']) }"
        >
        </mat-row>
      </mat-table>
      <mat-paginator
        #paginator
        [length]="dataSource.filteredData.length"
        [pageIndex]="0"
        [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions"
      >
      </mat-paginator>
    </mat-card>
  </div>
  <!-- <div *ngIf="contractID!=null &&  displayContractView==true">
      <app-contract-view [contractID]="contractID"></app-contract-view>
      <div class="full-width text-center">

        <button mat-raised-button color="primary" class="mb mt pull-left" (click)="hideContractView()">
          <mat-icon>keyboard_arrow_left</mat-icon> {{"Settlement list" | translate}}
        </button>
      </div>
    </div> -->
</div>
<div *ngIf="listDB.data.length == 0 && listDB.firstLoadEvent != false">
  <app-empty-list-message></app-empty-list-message>
</div>
