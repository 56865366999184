import { Component, EventEmitter, Inject, Input, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { SessionsService } from '../../../core/session/shared/sessions.service';

@Component({
  selector: 'app-customer-prospect-current-profile',
  templateUrl: './customer-prospect-current-profile.component.html',
  styleUrls: ['./customer-prospect-current-profile.component.css'],
})
export class CustomerProspectCurrentProfileComponent implements OnInit {
  @Input()
  cardID;

  closeModal = new EventEmitter();

  isModal = false;
  profileLink;
  currentUser;
  isNewProfilePicture = false;

  constructor(
    private SessionsService: SessionsService,
    private authenticationService: AuthenticationService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.cardID) {
        this.cardID = data.cardID;

        this.isModal = true;
      }
    }
  }

  ngOnInit() {
    this.authenticationService.getCurrentUser().subscribe((res) => {
      if (res && res.data) {
        this.currentUser = res.data;

        this.getProfilePicture();
      }
    });
  }

  onCompleteAll(fileID) {
    if (fileID && fileID[0]) {
      const payload = {
        fileID: fileID[0],
      };
      this.SessionsService.uploadProfilePic(payload).subscribe((res) => {
        this.isNewProfilePicture = !this.isNewProfilePicture;
        this.getProfilePicture();
        AuthenticationService.updateProfilePicture.emit(res);
      });
    }
  }

  getProfilePicture() {
    this.profileLink = null;

    this.profileLink = this.authenticationService.getProfilePicStreamLink();
  }

  closeEvent(e) {
    this.closeModal.emit(e);
  }
}
