<div [hidden]="showPage == true">
  <mat-card>
    <div class="row clearfix">
      <!-- <h3 class="sm-h3 sr-title rm-mb rm-mt pull-left"> {{ "INTERNAL WIKI" }} </h3> -->
      <h3 class="sm-h3 sr-title rm-mb rm-mt pull-left">Help centre</h3>
      <div class="pull-right">
        <button class="btn-close btn-clear mat-button" (click)="closeModal()">
          <mat-icon>clear</mat-icon>
        </button>
      </div>
    </div>
    <mat-dialog-content>
      <mat-accordion class="helpTopics">
        <mat-expansion-panel
          class="help-parent"
          *ngFor="let c of category"
          (opened)="c.isOpen = true"
          (closed)="c.isOpen = false"
        >
          <mat-expansion-panel-header class="help-parent-header">
            <mat-icon class="closed fas fa-folder" *ngIf="c.isOpen == false" aria-hidden="true"></mat-icon>
            <mat-icon class="open fas fa-folder-open" *ngIf="c.isOpen == true" aria-hidden="true"></mat-icon>
            <span class="text">{{ c.name }}</span>
          </mat-expansion-panel-header>
          <div class="panel-content top-level">
            <mat-list class="sub-menu offset">
              <app-help-topics-sub-contents
                *ngFor="let label of c.labels"
                [label]="label"
                (showPageContent)="showPageContent($event)"
              ></app-help-topics-sub-contents>
            </mat-list>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </mat-dialog-content>
    <br/>
    <!--<div class="full-width text-center">
      <button mat-raised-button color="accent" class="btn-large" (click)="openTutorial()" matDialogClose>Lanuch
        Tutorails</button>
    </div>-->
  </mat-card>
</div>

<div *ngIf="showPage == true">
  <mat-card>
    <mat-dialog-content>
      <app-simple-page-view [simplePageCode]="'helptopics'"></app-simple-page-view>
    </mat-dialog-content>
    <div class="full-width text-center">
      <button class="btn-small" (click)="cancelPageContent()" mat-raised-button color="accent">
        <mat-icon>chevron_left</mat-icon>
        Back
      </button>
    </div>
  </mat-card>
</div>
