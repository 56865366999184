import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { InvitationTemplateService } from '../shared/invitation-template.service';

@Component({
  selector: 'app-invitation-template-clone',
  templateUrl: './invitation-template-clone.component.html',
  styleUrls: ['./invitation-template-clone.component.css'],
})
export class InvitationTemplateCloneComponent implements OnInit {
  @Input()
  invitationTemplateID;

  @Output()
  closeModal = new EventEmitter();

  @Output()
  closeAndEdit = new EventEmitter();

  @Input()
  label;
  @Input()
  description;

  @Input()
  merchantID;

  @Input()
  isDefault = false;
  isPromoterOrAdmin = false;

  invitationTemplate;
  merchant;
  @Output()
  getResult = new EventEmitter();

  constructor(
    private invitationTemplateService: InvitationTemplateService,
    private authenticationService: AuthenticationService,
    private router: Router,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.merchantID) {
        this.merchantID = data.merchantID;
      }

      if (data.invitationTemplateID) {
        this.invitationTemplateID = data.invitationTemplateID;
      }

      if (data.isDefault != null) {
        this.isDefault = data.isDefault;
      }
    }
  }

  ngOnInit() {
    this.authenticationService.getCurrentPractice().subscribe((practice) => {
      if (practice) {
        this.merchant = practice;
      }

      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isPromoterOrAdmin = res;

        if (this.invitationTemplateID) {
          const payload = {
            merchantID: this.merchantID,
          };

          this.invitationTemplateService
            .getInvitationTemplateDetails(this.invitationTemplateID, payload, this.isPromoterOrAdmin)
            .subscribe((res) => {
              if (res) {
                this.label = res.label + ' (Cloned)' || null;
                this.description = res.description || null;
              }
            });
        }
      });
    });
  }

  close() {
    this.closeModal.emit(true);
  }

  sendResult(isEdit = false) {
    const p = {
      label: this.label,
      description: this.description,
      type: 'user',
    };

    if (this.isDefault == true) {
      p.type = 'default';
    }

    this.invitationTemplateService
      .cloneInvitationTemplate(this.invitationTemplateID, p, this.isPromoterOrAdmin)
      .subscribe((res) => {
        if (res && res._id) {
          if (isEdit == true) {
            this.closeAndEdit.emit(true);

            this.router.navigate(['/merchant', { outlets: { page: ['invitation-template-edit', res._id] } }]);
          } else {
            this.getResult.emit(res);
          }
        }
      });
  }
}
