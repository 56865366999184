<div class="row clearfix" *ngIf="reportObj">
  <!-- blue heading -->
  <div class="card-header primary-gradient-img clearfix heading rm-bot-pad" *ngIf="isModal == true">
    <div class="row clearfix">
      <!-- Modal dismiss button -->
      <button class="btn-close btn-clear white pull-right mat-button" (click)="closeEvent()">
        <mat-icon>clear</mat-icon>
      </button>
      <!-- merchant calculated name -->
      <div class="row titleArea clearfix">
        <h2 class="message-title summary-header rm-m">
          Email Reputation Report
          <span class="subLabel">as at {{ reportObj.DateTimeCreated | customDateTime }}</span>
        </h2>
      </div>
    </div>

    <div class="action-button">
      <button (click)="latestData()" mat-raised-button color="accent">
        <mat-icon>update</mat-icon>
        Get latest
      </button>
    </div>
  </div>
  <!-- end blue heading -->

  <mat-dialog-content>
    <div class="row clearfix" *ngIf="loadingLatest == false">
      <div class="row clearfix grey-area upper flex">
        <div class="block text-center full-width">
          <p class="rm-mb small">Reputation:</p>
          <h2 class="rm-m sr-title bold">{{ reportObj.Reputation }}%</h2>
          <p class="small bold reduce-marg">
            <span class="chip mat-elevation-z1" [ngStyle]="{ 'background-color': '#' + emailReputationColor }">{{
              emailReputation
              }}</span>
          </p>
        </div>
        <div class="block text-center full-width">
          <p class="rm-mb small">Weekly Sends:</p>
          <h2 class="rm-mt sr-title bold">{{ reportObj['Sent.Weekly'] }}</h2>
        </div>
        <div class="block text-center full-width">
          <p class="rm-mb small">Monthly Sends:</p>
          <h2 class="rm-mt sr-title bold">{{ reportObj['Sent.Monthly'] }}</h2>
        </div>
        <div class="block text-center full-width">
          <p class="rm-mb small">Total Sent:</p>
          <h2 class="rm-mt sr-title bold">{{ reportObj['Sent.Total'] }}</h2>
        </div>
      </div>

      <div class="row clearfix flex">
        <div class="half-width mr">
          <h4 class="sr-title">About Reputation and Hourly Quota</h4>

          <p>
            Every {{ "BRANDING.Brand Name" | translate | titlecase }} account has a reputation and hourly quota. These
            two features
            help protect us from spammers that would otherwise send a lot of emails quickly, that could then damage the
            reputation of our sending servers and negatively affect other {{ "BRANDING.Brand Name" | translate |
            titlecase }} users.
          </p>
        </div>
        <div class="half-width ml">
          <h4 class="sr-title">Reputation</h4>

          <p>
            An account's reputation can be Poor, Fair, Good, or Excellent. {{ "BRANDING.Brand Name" | translate |
            titlecase }} uses
            many methods to define an account's reputation. We look at how many bounces, spam complaints, unsubscribes
            the account has to set the reputation value. So for example, the more bounces and spam complaints you see
            relative to your overall sending volume, the lower your reputation will be.
          </p>
          <p>
            Learn more about the factors we use to determine account reputation and how to improve a Poor reputation on
            the links below.
          </p>
        </div>
      </div>

      <div class="row buttons flex">
        <button class="btn-small" (click)="
            openWikiLink(
              'https://app.smileright.com.au/wiki/(wikiPageRoute:view-code/email-reputation:-high-bounce-rate)'
            )
          " mat-stroked-button>
          High Bounce Rate
        </button>
        <button class="btn-small" (click)="
            openWikiLink(
              'https://app.smileright.com.au/wiki/(wikiPageRoute:view-code/email-reputation:-high-unsubscribe-rate)'
            )
          " mat-stroked-button>
          High Unsubscribe Rate
        </button>
        <button class="btn-small" (click)="
            openWikiLink(
              'https://app.smileright.com.au/wiki/(wikiPageRoute:view-code/email-reputation:-high-spam-complaint-rate)'
            )
          " mat-stroked-button>
          High Spam Complaint Rate
        </button>
      </div>

      <hr/>

      <h4 class="sr-title reduce-marg">Last 30 days</h4>

      <div class="row clearfix">
        <div class="half-width">
          <div class="col-left">
            <mat-list>
              <mat-list-item>
                <div class="row clearfix full-width" matLine>
                  <p class="reduce-marg bold pull-left">Total Sent over 30 days</p>
                  <p class="reduce-marg bold pull-right">{{ reportObj['Last_30_days.Sent'] }}</p>
                </div>

                <mat-divider></mat-divider>
              </mat-list-item>

              <mat-list-item>
                <div class="row flex-row">
                  <div class="pull-left">
                    <p class="reduce-marg pull-left">Delivered</p>
                  </div>

                  <div class="pull-right">
                    <ngx-gauge class="no-title" [size]="35" [type]="'arch'" [value]="
                        toNumber(reportObj['Last_30_days.Sent']) -
                        toNumber(reportObj['Last_30_days.Hard_bounces']) -
                        toNumber(reportObj['Last_30_days.Soft_bounces']) -
                        toNumber(reportObj['Last_30_days.Rejects']) -
                        toNumber(reportObj['Last_30_days.Complaints']) -
                        toNumber(reportObj['Last_30_days.Unsubs'])
                      " [label]="''" [max]="reportObj['Last_30_days.Sent']" [thresholds]="moreIsLess" [thick]="6"
                               [cap]="'round'">
                    </ngx-gauge>

                    <p class="reduce-marg pull-left">
                      {{
                      toNumber(reportObj['Last_30_days.Sent']) -
                      toNumber(reportObj['Last_30_days.Hard_bounces']) -
                      toNumber(reportObj['Last_30_days.Soft_bounces']) -
                      toNumber(reportObj['Last_30_days.Rejects']) -
                      toNumber(reportObj['Last_30_days.Complaints']) -
                      toNumber(reportObj['Last_30_days.Unsubs'])
                      }}
                    </p>
                  </div>
                </div>
                <mat-divider></mat-divider>
              </mat-list-item>
            </mat-list>
          </div>
        </div>

        <div class="half-width">
          <div class="col-right">
            <mat-list>
              <mat-list-item>
                <div class="row flex-row">
                  <p class="reduce-marg bold">Deliverability</p>

                  <div class="pull-right">
                    <ngx-gauge class="no-title" [size]="35" [type]="'arch'" [value]="deliverability" [label]="''"
                               [max]="100" [thresholds]="moreIsLess" [thick]="6" [cap]="'round'">
                    </ngx-gauge>

                    <p class="reduce-marg pull-left bold">{{ deliverability }}%</p>
                  </div>
                </div>
              </mat-list-item>

              <mat-divider></mat-divider>
              <mat-list-item>
                <div class="row flex-row">
                  <div class="pull-left">
                    <p class="reduce-marg pull-left">Average Sends Daily</p>
                  </div>

                  <div class="pull-right">
                    <ngx-gauge class="no-title" [size]="35" [type]="'arch'"
                               [value]="toNumber(reportObj['Last_30_days.Sent']) / 30 | number" [label]="''" [max]="100"
                               [thresholds]="moreIsLess" [thick]="6" [cap]="'round'">
                    </ngx-gauge>

                    <p class="reduce-marg pull-left">{{ toNumber(reportObj['Last_30_days.Sent']) / 30 | number }}</p>
                  </div>
                </div>
                <mat-divider></mat-divider>
              </mat-list-item>
            </mat-list>
          </div>
        </div>
      </div>

      <div class="row clearfix mt">
        <!-- <h4>Last 30 days</h4>

        <div class="row clearfix flex">


          <div class="full-width text-center">
            <ngx-gauge class="full-width" [size]="120" [type]="gaugeType" [value]="reportObj['Last_30_days.Sent']"
              [label]="''" [max]="200" [thresholds]="moreIsLess" [thick]="12" [cap]="'round'">
            </ngx-gauge>

            <p class="rm-mt">Sent</p>
          </div>

          <div class="full-width text-center">
            <ngx-gauge class="full-width" [size]="120" [type]="gaugeType"
              [value]="toNumber(reportObj['Last_30_days.Sent']) - toNumber(reportObj['Last_30_days.Hard_bounces']) - toNumber(reportObj['Last_30_days.Soft_bounces'])
                  - toNumber(reportObj['Last_30_days.Rejects']) - toNumber(reportObj['Last_30_days.Complaints']) - toNumber(reportObj['Last_30_days.Unsubs'])" [label]="''"
              [max]="reportObj['Last_30_days.Sent']" [thresholds]="moreIsLess" [thick]="12" [cap]="'round'">
            </ngx-gauge>

            <p class="rm-mt">Delivered</p>
          </div>

          <div class="full-width text-center">

            <ngx-gauge class="full-width" [size]="120" [type]="gaugeType" [value]="deliverability" [label]="''"
              [append]="'%'" [max]="100" [thresholds]="moreIsLess" [thick]="12" [cap]="'round'">
            </ngx-gauge>


            <p class="rm-mt">Deliverability</p>
          </div>
        </div> -->

        <div class="row clearfix">
          <div class="half-width">
            <div class="col-left">
              <h4 class="sr-title reduce-marg">Campaign Data</h4>

              <mat-list>
                <mat-list-item>
                  <div class="row flex-row">
                    <p class="reduce-marg">Opens</p>

                    <div class="pull-right">
                      <ngx-gauge class="no-title" [size]="35" [type]="'arch'" [value]="reportObj['Last_30_days.Opens']"
                                 [label]="''" [max]="reportObj['Last_30_days.Sent']" [thresholds]="moreIsLess"
                                 [thick]="6"
                                 [cap]="'round'">
                      </ngx-gauge>

                      <p class="reduce-marg pull-left">{{ reportObj['Last_30_days.Opens'] }}</p>
                    </div>
                  </div>
                  <mat-divider></mat-divider>
                </mat-list-item>

                <mat-list-item>
                  <div class="row flex-row">
                    <p class="reduce-marg">Unique Opens</p>

                    <div class="pull-right">
                      <ngx-gauge class="no-title" [size]="35" [type]="'arch'"
                                 [value]="(9 / toNumber(allTarget)) * 100 | number: '1.0-1'" [label]="''"
                                 [max]="reportObj['Last_30_days.Sent']" [thresholds]="moreIsLess" [thick]="6"
                                 [cap]="'round'">
                      </ngx-gauge>

                      <p class="reduce-marg pull-left">{{ reportObj['Last_30_days.Unique_Opens'] }}</p>
                    </div>
                  </div>
                  <mat-divider></mat-divider>
                </mat-list-item>

                <mat-list-item>
                  <div class="row flex-row">
                    <p class="reduce-marg">Clicks</p>

                    <div class="pull-right">
                      <ngx-gauge class="no-title" [size]="35" [type]="'arch'" [value]="reportObj['Last_30_days.Clicks']"
                                 [label]="''" [max]="reportObj['Last_30_days.Sent']" [thresholds]="moreIsLess"
                                 [thick]="6"
                                 [cap]="'round'">
                      </ngx-gauge>

                      <p class="reduce-marg pull-left">{{ reportObj['Last_30_days.Clicks'] }}</p>
                    </div>
                  </div>
                  <mat-divider></mat-divider>
                </mat-list-item>

                <mat-list-item>
                  <div class="row flex-row">
                    <p class="reduce-marg">Unique Clicks</p>

                    <div class="pull-right">
                      <ngx-gauge class="no-title" [size]="35" [type]="'arch'"
                                 [value]="reportObj['Last_30_days.Unique_Clicks']" [label]="''"
                                 [max]="reportObj['Last_30_days.Sent']" [thresholds]="moreIsLess" [thick]="6"
                                 [cap]="'round'">
                      </ngx-gauge>

                      <p class="reduce-marg pull-left">{{ reportObj['Last_30_days.Unique_Clicks'] }}</p>
                    </div>
                  </div>
                  <mat-divider></mat-divider>
                </mat-list-item>
              </mat-list>
            </div>
          </div>
          <div class="half-width">
            <div class="col-right">
              <h4 class="sr-title reduce-marg">Reputation Data</h4>

              <mat-list>
                <mat-list-item>
                  <div class="row flex-row">
                    <div class="pull-left">
                      <p class="reduce-marg pull-left">Unsubscribes</p>
                    </div>

                    <div class="pull-right">
                      <ngx-gauge class="no-title" [size]="35" [type]="'arch'" [value]="reportObj['Last_30_days.Unsubs']"
                                 [label]="''" [max]="reportObj['Last_30_days.Sent']" [thresholds]="moreIsLess"
                                 [thick]="6"
                                 [cap]="'round'">
                      </ngx-gauge>

                      <p class="reduce-marg pull-left">{{ reportObj['Last_30_days.Unsubs'] }}</p>
                    </div>
                  </div>
                  <mat-divider></mat-divider>
                </mat-list-item>

                <mat-list-item>
                  <div class="row flex-row">
                    <div class="pull-left">
                      <p class="reduce-marg pull-left">Hard Bounces</p>
                    </div>

                    <div class="pull-right">
                      <ngx-gauge class="no-title" [size]="35" [type]="'arch'"
                                 [value]="reportObj['Last_30_days.Hard_bounces']" [label]="''"
                                 [max]="reportObj['Last_30_days.Sent']" [thresholds]="moreIsLess" [thick]="6"
                                 [cap]="'round'">
                      </ngx-gauge>

                      <p class="reduce-marg pull-left">{{ reportObj['Last_30_days.Hard_bounces'] }}</p>
                    </div>
                  </div>
                  <mat-divider></mat-divider>
                </mat-list-item>

                <mat-list-item>
                  <div class="row flex-row">
                    <div class="pull-left">
                      <p class="reduce-marg pull-left">Soft Bounces</p>
                    </div>

                    <div class="pull-right">
                      <ngx-gauge class="no-title" [size]="35" [type]="'arch'"
                                 [value]="reportObj['Last_30_days.Soft_bounces']" [label]="''"
                                 [max]="reportObj['Last_30_days.Sent']" [thresholds]="moreIsLess" [thick]="6"
                                 [cap]="'round'">
                      </ngx-gauge>

                      <p class="reduce-marg pull-left">{{ reportObj['Last_30_days.Soft_bounces'] }}</p>
                    </div>
                  </div>
                  <mat-divider></mat-divider>
                </mat-list-item>

                <mat-list-item>
                  <div class="row flex-row">
                    <div class="pull-left">
                      <p class="reduce-marg pull-left">Spam Complaints</p>
                    </div>

                    <div class="pull-right">
                      <ngx-gauge class="no-title" [size]="35" [type]="'arch'"
                                 [value]="reportObj['Last_30_days.Complaints']" [label]="''"
                                 [max]="reportObj['Last_30_days.Sent']" [thresholds]="moreIsLess" [thick]="6"
                                 [cap]="'round'">
                      </ngx-gauge>

                      <p class="reduce-marg pull-left">{{ reportObj['Last_30_days.Complaints'] }}</p>
                    </div>
                  </div>
                  <mat-divider></mat-divider>
                </mat-list-item>

                <mat-list-item>
                  <div class="row flex-row">
                    <div class="pull-left">
                      <p class="reduce-marg pull-left">Average bounces daily</p>
                    </div>

                    <div class="pull-right">
                      <ngx-gauge class="no-title" [size]="35" [type]="'arch'" [value]="
                          (toNumber(reportObj['Last_30_days.Hard_bounces']) +
                            toNumber(reportObj['Last_30_days.Soft_bounces'])) /
                            30 | number
                        " [label]="''" [max]="100" [thresholds]="moreIsLess" [thick]="6" [cap]="'round'">
                      </ngx-gauge>

                      <p class="reduce-marg pull-left">
                        {{
                        (toNumber(reportObj['Last_30_days.Hard_bounces']) +
                          toNumber(reportObj['Last_30_days.Soft_bounces'])) /
                        30 | number
                        }}
                      </p>
                    </div>
                  </div>
                  <mat-divider></mat-divider>
                </mat-list-item>
              </mat-list>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row clearfix" *ngIf="loadingLatest == true">
      <div class="row clearfix padded rel text-center">
        <img class="loader-percent add-mt"
          src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/userDataLoader.gif"
             alt=""/>
        <h4 class="sr-title reduce-marg">Fetching new data...</h4>
        <p>This can take a while. Please be {{"KEYWORD.patient" | translate}}...</p>
      </div>
    </div>
  </mat-dialog-content>
</div>
