import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MessageViewComponent } from '../../../../common/feature/message/message-view/message-view.component';
import { SmsConversationComponent } from '../../../../common/feature/message/sms-conversation/sms-conversation.component';
import { SmsPreviewComponent } from '../../../../common/feature/message/sms-preview/sms-preview.component';
import { SmsStatisticsComponent } from '../../../../common/feature/message/sms-statistics/sms-statistics.component';
import { MessageInputComponent } from '../../../../common/feature/message/message-input/message-input.component';
import { SmsSendComponent } from '../../../../common/feature/message/sms-send/sms-send.component';
import { DesktopPreviewComponent } from '../../../../common/feature/message/desktop-preview/desktop-preview.component';
import { MessageGroupViewComponent } from '../../../../common/feature/message/message-group-view/message-group-view.component';
import { MessageGroupLocalListComponent } from '../../../../common/feature/message/message-group-local-list/message-group-local-list.component';
import { MessageGroupCardListComponent } from '../../../../common/feature/message/message-group-card-list/message-group-card-list.component';
import { SmsPromoterComponent } from '../../../../common/feature/message/sms-promoter/sms-promoter.component';
import { MessageTimelineComponent } from '../../../../common/feature/message/message-timeline/message-timeline.component';
import { InvitationLookupSecondComponent } from '../../../../common/feature/invitation/invitation-lookup-second/invitation-lookup-second.component';
import { QuickInvitationComponent } from '../../../../common/feature/invitation/quick-invitation/quick-invitation.component';
import { EpDocumentWakandaCreateComponent } from '../../../../common/feature/invitation/ep-document-wakanda-create/ep-document-wakanda-create.component';
import { InvitationCommunicationCreate } from '../../../../common/feature/invitation/invitation-communication-create/invitation-communication-create.component';
import { InvitationCommunicationViewComponent } from '../../../../common/feature/invitation/invitation-communication-view/invitation-communication-view.component';
import { WakandaFilesComponent } from '../../../../common/feature/invitation/wakanda-files-view/wakanda-files-view.component';
import { InvitationLookupComponent } from '../../../../common/feature/invitation/invitation-lookup/invitation-lookup.component';
import { InvitationCustomerProspectEditComponent } from '../../../../common/feature/invitation/invitation-customer-prospect-edit/invitation-customer-prospect-edit.component';
import { InvitationNewButtonComponent } from '../../../../common/feature/invitation/invitation-new-button/invitation-new-button.component';
import { InvitationListDraftComponent } from '../../../../common/feature/invitation/invitation-list-draft/invitation-list-draft.component';
import { InvitationListDraftOverViewComponent } from '../../../../common/feature/invitation/invitation-list-draft-overview/invitation-list-draft-overview.component';
import { InvitationCloneInfoComponent } from '../../../../common/feature/invitation/invitation-clone-info/invitation-clone-info.component';
import { LendingInvitationCreateComponent } from '../../../../common/feature/invitation/lending-invitation-create/lending-invitation-create.component';
import { InvitationCreateComponent } from '../../../../common/feature/invitation/invitation-create/invitation-create.component';
import { BeforeStartingLendingComponent } from '../../../../common/feature/invitation/before-starting-lending/before-starting-lending.component';
import { InvitationPerformanceComponent } from '../../../../common/feature/invitation/invitation-performance/invitation-performance.component';
import { InvitationListComponent } from '../../../../common/feature/invitation/invitation-list/invitation-list.component';
import { InvitationExistingPatientListComponent } from '../../../../common/feature/invitation/invitation-existing-patient-list/invitation-existing-patient-list.component';
import { InvitationListOverViewComponent } from '../../../../common/feature/invitation/invitation-list-overview/invitation-list-overview.component';
import { InvitationListGlobalComponent } from '../../../../common/feature/invitation/invitation-list-global/invitation-list-global.component';
import { ColdApplicationComponent } from '../../../../common/feature/invitation/cold-application/cold-application.component';
import { InvitationViewModalComponent } from '../../../../common/feature/invitation/invitation-view-modal/invitation-view-modal.component';
import { InvitationListViewComponent } from '../../../../common/feature/invitation/invitation-list-view/invitation-list-view.component';
import { InvitationOverviewComponent } from '../../../../common/feature/invitation/invitation-overview/invitation-overview.component';
import { InvitationModificationComponent } from '../../../../common/feature/invitation/invitation-modification/invitation-modification.component';
import { BeforeStartingComponent } from '../../../../common/feature/invitation/before-starting/before-starting.component';
import { InvitationIntroModalComponent } from '../../../../common/feature/invitation/invitation-intro-modal/invitation-intro-modal.component';
import { InvitationViewComponent } from '../../../../common/feature/invitation/invitation-view/invitation-view.component';
import { InvitationResendComponent } from '../../../../common/feature/invitation/invitation-resend/invitation-resend.component';
import { DiscountEditComponent } from '../../../../common/feature/invitation/discount-edit/discount-edit.component';
import { InvitationDetailsCardComponent } from '../../../../common/feature/invitation/invitation-details-card/invitation-details-card.component';
import { TextMessageCreateComponent } from '../../../../common/feature/message/text-message-create/text-message-create.component';
import { CustomerProspectProfileComponent } from '../../../../common/feature/profils/customer-prospect-profile/customer-prospect-profile.component';
import { ProspectProfileTabsComponent } from '../../../../common/feature/profils/prospect-profile-tabs/prospect-profile-tabs.component';
import { CustomerProspectProfileTabsComponent } from '../../../../common/feature/profils/customer-prospect-profile-tabs/customer-prospect-profile-tabs.component';
import { CustomerProfileTabsComponent } from '../../../../common/feature/profils/customer-profile-tabs/customer-profile-tabs.component';
import { ProfileSessionEditComponent } from '../../../../common/feature/profils/profile-session-edit/profile-session-edit.component';
import { ProfileSessionOverViewComponent } from '../../../../common/feature/profils/profile-session-over-view/profile-session-over-view.component';
import { DentistProfileComponent } from '../../../../common/feature/profils/dentist-profile/dentist-profile.component';
import { ProspectDetailsCardEditComponent } from '../../../../common/feature/profils/prospect-details-card-edit/prospect-details-card-edit.component';
import { DetailsCardViewComponent } from '../../../../common/feature/profils/details-card-view/details-card-view.component';
import { CustomerDetailsCardEditComponent } from '../../../../common/feature/profils/customer-details-card-edit/customer-details-card-edit.component';
import { AddressEditModalComponent } from '../../../../common/feature/profils/address-edit-modal/address-edit-modal.component';
import { ContactDetailsEditModalComponent } from '../../../../common/feature/profils/contact-details-edit-modal/contact-details-edit-modal.component';
import { ChangePrimaryModalComponent } from '../../../../common/feature/profils/change-primary-modal/change-primary-modal.component';
import { CustomerProspectDetailsComponent } from '../../../../common/feature/profils/customer-prospect-details/customer-prospect-details.component';
import { MerchantProfileComponent } from '../../../../common/feature/profils/merchant-profile/merchant-profile.component';
import { FinanceCalculatorComponent } from '../../../../common/feature/product/finance-calculator/finance-calculator.component';
import { ProductListComponent } from '../../../../common/feature/product/product-list/product-list.component';
import { ProductCalculatorComponent } from '../../../../common/feature/product/product-calculator/product-calculator.component';
import { ProductViewModalComponent } from '../../../../common/feature/product/product-view-modal/product-view-modal.component';
import { SuitableProductCompactListComponent } from '../../../../common/feature/product/suitable-product-compact-list/suitable-product-compact-list.component';
import { SuitableProductListComponent } from '../../../../common/feature/product/suitable-product-list/suitable-product-list.component';
import { ProductDocumentLibraryCreateComponent } from '../../../../common/feature/product/product-document-library-create/product-document-library-create.component';
import { CustomerProspectListServerComponent } from '../../../../common/feature/customer-prospect/customer-prospect-list-server/customer-prospect-list-server.component';
import { CustomerProspectViewModelComponent } from '../../../../common/feature/customer-prospect/customer-prospect-view-model/customer-prospect-view-model.component';
import { CustomerProspectCreateComponent } from '../../../../common/feature/customer-prospect/customer-prospect-create/customer-prospect-create.component';
import { PatientDocumentCreateModalComponent } from '../../../../common/feature/customer-prospect/patient-document-create-modal/patient-document-create-modal.component';
import { PatientDocumentCreateComponent } from '../../../../common/feature/customer-prospect/patient-document-create/patient-document-create.component';
import { CustomerProspectCurrentProfileComponent } from '../../../../common/feature/customer-prospect/customer-prospect-current-profile/customer-prospect-current-profile.component';
import { CustomerProspectViewPictureComponent } from '../../../../common/feature/customer-prospect/customer-prospect-view-picture/customer-prospect-view-picture.component';
import { CustomerProspectListOverViewComponent } from '../../../../common/feature/customer-prospect/customer-prospect-list-overview/customer-prospect-list-overview.component';
import { CustomerProspectListGlobalComponent } from '../../../../common/feature/customer-prospect/customer-prospect-list-global/customer-prospect-list-global.component';
import { CustomerProspectBoostModalComponent } from '../../../../common/feature/customer-prospect/customer-prospect-boost-modal/customer-prospect-boost-modal.component';
import { PatientHealthcareComponent } from '../../../../common/feature/customer-prospect/patient-healthcare-create/patient-healthcare-create.component';
import { GroupInviteModalComponent } from '../../../../common/feature/customer-prospect/group-invite-modal/group-invite-modal.component';
import { CustomerProspectEditComponent } from '../../../../common/feature/customer-prospect/customer-prospect-edit/customer-prospect-edit.component';
import { CustomerProfileSummaryDialogComponent } from '../../../../common/feature/customer-prospect/customer-profile-summary-dialog/customer-profile-summary-dialog.component';
import { CustomerProspectListArchivesComponent } from '../../../../common/feature/customer-prospect/customer-prospect-list-archives/customer-prospect-list-archives.component';
import { CustomerProspectViewComponent } from '../../../../common/feature/customer-prospect/customer-prospect-view/customer-prospect-view.component';
import { CustomerProspectListMapComponent } from '../../../../common/feature/customer-prospect/customer-prospect-list-map/customer-prospect-list-map.component';
import { CustomerProspectMapOverviewComponent } from '../../../../common/feature/customer-prospect/customer-prospect-map-overview/customer-prospect-map-overview.component';
import { CustomerProspectListComponent } from '../../../../common/feature/customer-prospect/customer-prospect-list/customer-prospect-list.component';

const routes: Routes = [
  {
    path: 'CustomerProspectListServerComponent',
    component: CustomerProspectListServerComponent,
  },
  {
    path: 'CustomerProspectViewModelComponent',
    component: CustomerProspectViewModelComponent,
  },
  {
    path: 'CustomerProspectCreateComponent',
    component: CustomerProspectCreateComponent,
  },

  {
    path: 'PatientDocumentCreateModalComponent',
    component: PatientDocumentCreateModalComponent,
  },
  {
    path: 'PatientDocumentCreateComponent',
    component: PatientDocumentCreateComponent,
  },
  {
    path: 'customer-prospect-current',
    component: CustomerProspectCurrentProfileComponent,
  },

  {
    path: 'CustomerProspectViewPictureComponent',
    component: CustomerProspectViewPictureComponent,
  },
  {
    path: 'customer-prospect-list-overview',
    component: CustomerProspectListOverViewComponent,
  },
  {
    path: 'customer-prospect-list-global',
    component: CustomerProspectListGlobalComponent,
  },
  {
    path: 'customer-prospect-list',
    component: CustomerProspectListComponent,
  },
  {
    path: 'customer-prospect-map-overview',
    component: CustomerProspectMapOverviewComponent,
  },
  {
    path: 'customer-prospect-list-map',
    component: CustomerProspectListMapComponent,
  },
  {
    path: 'customer-prospect-view',
    component: CustomerProspectViewComponent,
  },

  {
    path: 'customer-prospect-list-archives',
    component: CustomerProspectListArchivesComponent,
  },

  {
    path: 'customer-profile-summary-dialog',
    component: CustomerProfileSummaryDialogComponent,
  },

  {
    path: 'customer-prospect-edit',
    component: CustomerProspectEditComponent,
  },
  {
    path: 'group-invite-modal',
    component: GroupInviteModalComponent,
  },
  {
    path: 'patient-healthcare-create',
    component: PatientHealthcareComponent,
  },
  {
    path: 'boost-modal',
    component: CustomerProspectBoostModalComponent,
  },

  {
    path: 'customer-prospect-profile',
    component: CustomerProspectProfileComponent,
  },

  {
    path: 'ProspectProfileTabsComponent',
    component: ProspectProfileTabsComponent,
  },
  {
    path: 'CustomerProspectProfileComponent',
    component: CustomerProspectProfileComponent,
  },

  {
    path: 'CustomerProspectProfileTabsComponent',
    component: CustomerProspectProfileTabsComponent,
  },
  {
    path: 'CustomerProfileTabsComponent',
    component: CustomerProfileTabsComponent,
  },
  {
    path: 'ProfileSessionEditComponent',
    component: ProfileSessionEditComponent,
  },

  {
    path: 'ProfileSessionOverViewComponent',
    component: ProfileSessionOverViewComponent,
  },
  {
    path: 'dentist-profile',
    component: DentistProfileComponent,
  },
  {
    path: 'prospect-details-card-edit',
    component: ProspectDetailsCardEditComponent,
  },
  {
    path: 'details-card-view',
    component: DetailsCardViewComponent,
  },
  {
    path: 'customer-details-card-edit',
    component: CustomerDetailsCardEditComponent,
  },
  {
    path: 'address-edit-modal',
    component: AddressEditModalComponent,
  },
  {
    path: 'contact-details-edit-modal',
    component: ContactDetailsEditModalComponent,
  },
  {
    path: 'change-primary-modal',
    component: ChangePrimaryModalComponent,
  },
  {
    path: 'customer-prospect-detail',
    component: CustomerProspectDetailsComponent,
  },
  {
    path: 'merchant-profile',
    component: MerchantProfileComponent,
  },
  {
    path: 'finance-calculator',
    component: FinanceCalculatorComponent,
  },

  {
    path: 'dynamic-product-list',
    component: ProductListComponent,
  },
  {
    path: 'product-calculator-component',
    component: ProductCalculatorComponent,
  },
  {
    path: 'product-view-modal',
    component: ProductViewModalComponent,
  },
  {
    path: 'suitable-product-compact-list',
    component: SuitableProductCompactListComponent,
  },
  {
    path: 'suitable-dynamic-product-list',
    component: SuitableProductListComponent,
  },
  {
    path: 'product-document-library-create',
    component: ProductDocumentLibraryCreateComponent,
  },

  {
    path: 'message-time',
    component: MessageTimelineComponent
  },
  {
    path: 'message-view',
    component: MessageViewComponent
  },
  {
    path: 'SmsPromoterComponent',
    component: SmsPromoterComponent
  },

  {
    path: 'MessageGroupCardListComponent',
    component: MessageGroupCardListComponent
  },
  {
    path: 'MessageGroupLocalListComponent',
    component: MessageGroupLocalListComponent
  },
  {
    path: 'MessageGroupViewComponent',
    component: MessageGroupViewComponent
  },
  {
    path: 'DesktopPreviewComponent',
    component: DesktopPreviewComponent
  },
  {
    path: 'SmsSendComponent',
    component: SmsSendComponent
  },
  {
    path: 'MessageInputComponent',
    component: MessageInputComponent
  },
  {
    path: 'SmsStatisticsComponent',
    component: SmsStatisticsComponent
  },
  {
    path: 'SmsPreviewComponent',
    component: SmsPreviewComponent
  },
  {
    path: 'SmsConversationComponent',
    component: SmsConversationComponent
  },
  {
    path: 'message-view/:messageID',
    component: MessageViewComponent
  },
  {
    path: 'TextMessageCreateComponent',
    component: TextMessageCreateComponent
  },
  {
    path: 'InvitationLookupSecondComponent',
    component: InvitationLookupSecondComponent,
  },
  {
    path: 'QuickInvitationComponent',
    component: QuickInvitationComponent,
  },
  {
    path: 'EpDocumentWakandaCreateComponent',
    component: EpDocumentWakandaCreateComponent,
  },
  {
    path: 'InvitationCommunicationViewComponent',
    component: InvitationCommunicationViewComponent,
  },
  {
    path: 'InvitationCommunicationCreate',
    component: InvitationCommunicationCreate,
  },
  {
    path: 'WakandaFilesComponent',
    component: WakandaFilesComponent,
  },
  {
    path: 'InvitationLookupComponent',
    component: InvitationLookupComponent,
  },
  {
    path: 'InvitationCustomerProspectEditComponent',
    component: InvitationCustomerProspectEditComponent,
  },

  {
    path: 'InvitationNewButtonComponent',
    component: InvitationNewButtonComponent,
  },

  {
    path: 'invitation-list-draft',
    component: InvitationListDraftComponent,
  },

  {
    path: 'invitation-list-draft-overview',
    component: InvitationListDraftOverViewComponent,
  },

  {
    path: 'invitation-clone-info-modal',
    component: InvitationCloneInfoComponent,
  },

  {
    path: 'invitation-lending-create',
    component: LendingInvitationCreateComponent,
  },
  {
    path: 'invitation-lending-create/:invitationID',
    component: LendingInvitationCreateComponent,
  },

  {
    path: 'invitation-create/:invitationID',
    component: InvitationCreateComponent,
  },

  {
    path: 'invitation-before-starting-lending-create',
    component: BeforeStartingLendingComponent,
  },

  {
    path: 'invitation-performance',
    component: InvitationPerformanceComponent,
  },

  {
    path: 'invitation-list',
    component: InvitationListComponent,
  },
  {
    path: 'invitation-existing-patient-list',
    component: InvitationExistingPatientListComponent,
  },

  {
    path: 'invitation-list-overview',
    component: InvitationListOverViewComponent,
  },
  {
    path: 'invitation-list-global',
    component: InvitationListGlobalComponent,
  },
  {
    path: 'apply',
    component: ColdApplicationComponent,
  },
  {
    path: 'invitation/:guid',
    component: InvitationCreateComponent,
  },
  {
    path: 'invitation-view-modal',
    component: InvitationViewModalComponent,
  },
  {
    path: 'invitation-list-view',
    component: InvitationListViewComponent,
  },
  {
    path: 'invitation-overview',
    component: InvitationOverviewComponent,
  },
  {
    path: 'invitation-modification',
    component: InvitationModificationComponent,
  },
  {
    path: 'before-starting',
    component: BeforeStartingComponent,
  },
  {
    path: 'invitation-intro-modal',
    component: InvitationIntroModalComponent,
  },
  {
    path: 'invitation-view/:id',
    component: InvitationViewComponent,
  },
  {
    path: 'invitation-resend',
    component: InvitationResendComponent,
  },
  {
    path: 'discount-edit-modal',
    component: DiscountEditComponent,
  },
  {
    path: 'DiscountEditComponent',
    component: DiscountEditComponent,
  },
  {
    path: 'invitation-details-card',
    component: InvitationDetailsCardComponent,
  },
  {
    path: 'invitation-details-card/:invitationID',
    component: InvitationDetailsCardComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ModalTopRoutingModule {}
