export class TemplateUtilClass {
  constructor() {}

  ngOnInit() {}

  getSubTag(tag) {
    let result;
    if (tag) {
      const _tag = tag.split('-');

      if (_tag.length > 3) {
        result = _tag.slice(3).join('-');
      }
    }
    return result;
  }

  getTemplageOwner(tag) {
    if (tag) {
      const __tag = tag.split(':')[1];

      if (__tag) {
        const campaignOwner = {
          P: 'Promoter',
          M: 'Merchant',
        };

        const _tag = tag.split(':')[1].split('-');

        if (_tag && _tag.length > 0) {
          if (_tag[0] == 'campaign') {
            return campaignOwner[_tag[1]];
          } else {
            return _tag[0];
          }
        }
      }
    }
    return null;
  }

  getTemplateTarget(tag) {
    if (tag) {
      const __tag = tag.split(':')[1];

      if (__tag) {
        const _tag = __tag.split('-');

        if (_tag && _tag.length > 0) {
          if (_tag[0] == 'campaign') {
            return _tag[2];
          } else {
            return _tag[1];
          }
        }
      }
    }
    return null;
  }

  getPurpose(tag) {
    if (tag) {
      let __tag = tag;

      if (tag.split(':').length > 0) {
        __tag = tag.split(':')[1];
      }

      if (__tag) {
        return __tag.split('-')[0];
      }
    }
    return null;
  }
}
