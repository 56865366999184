import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { ClientDetails } from '../../types/client-details';

@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.css'],
})
export class DateRangePickerComponent implements OnInit {
  @Input()
  showAllTime = false;

  @Input()
  dateLabel = 'Date';

  @Input()
  selectedRange = 'days90';
  @Input()
  sendNull;
  @Input() selected: {
    startDate: any;
    endDate?: any;
  } = { startDate: null };
  @Input() alwaysShowCalendars: boolean;
  @Input() showCustomRangeLabel = true;
  showRangeLabelOnInput: boolean;
  keepCalendarOpeningWithRange: boolean;
  maxDate: moment.Moment;
  minDate: moment.Moment;
  invalidDates: moment.Moment[] = [moment().add(2, 'days'), moment().add(3, 'days'), moment().add(5, 'days')];
  @Input() hasTimePicker = false;
  @Input() singleDatePicker = false;
  @Input() smallerInput = true;

  @Input() sendOnInit = true;

  @Input() disabled = false;

  rangesObject = {
    today: {
      startDate: moment().utcOffset(0).set('h', 0).set('m', 0).set('s', 1),
      endDate: moment().utcOffset(0).add(2, 'days').set('h', 23).set('m', 59).set('s', 59),
    },
    yesterday: {
      startDate: moment().utcOffset(0).subtract(1, 'days').set('h', 0).set('m', 0).set('s', 1),
      endDate: moment().utcOffset(0).subtract(1, 'days').set('h', 23).set('m', 59).set('s', 59),
    },
    days7: {
      startDate: moment().utcOffset(0).subtract(6, 'days').set('h', 0).set('m', 0).set('s', 1),
      endDate: moment().utcOffset(0).add(2, 'days').set('h', 23).set('m', 59).set('s', 59),
    },
    days30: {
      startDate: moment().utcOffset(0).subtract(29, 'days').set('h', 0).set('m', 0).set('s', 1),
      endDate: moment().utcOffset(0).add(2, 'days').set('h', 23).set('m', 59).set('s', 59),
    },
    days60: {
      startDate: moment().utcOffset(0).subtract(59, 'days').set('h', 0).set('m', 0).set('s', 1),
      endDate: moment().utcOffset(0).add(2, 'days').set('h', 23).set('m', 59).set('s', 59),
    },
    days90: {
      startDate: moment().utcOffset(0).subtract(89, 'days').set('h', 0).set('m', 0).set('s', 1),
      endDate: moment().utcOffset(0).add(2, 'days').set('h', 23).set('m', 59).set('s', 59),
    },
    days180: {
      startDate: moment().utcOffset(0).subtract(179, 'days').set('h', 0).set('m', 0).set('s', 1),
      endDate: moment().utcOffset(0).add(2, 'days').set('h', 23).set('m', 59).set('s', 59),
    },
    all: {
      startDate: moment().utcOffset(0).subtract(50, 'years').set('h', 0).set('m', 0).set('s', 1),
      endDate: moment().utcOffset(0).add(2, 'days').set('h', 23).set('m', 59).set('s', 59),
    },
  };

  @Input() ranges;

  @Output()
  getRange = new EventEmitter();

  isPormoterOrAdmin = false;

  initCount = 0;
  isInvalidDate = (m: moment.Moment) => {
    return this.invalidDates.some((d) => d.isSame(m, 'day'));
  };

  constructor() {
    this.maxDate = moment().add(2, 'weeks');
    this.minDate = moment().subtract(3, 'days');

    this.alwaysShowCalendars = true;
    this.keepCalendarOpeningWithRange = true;
    this.showRangeLabelOnInput = true;
    // this.selected = { startDate: moment().subtract(89, 'days'), endDate: moment() };
  }

  rangeClicked(range) {
    // console.log('[rangeClicked] range is : ', range);
  }

  getKeyFromDates(startDate, endDate) {
    for (const key in this.rangesObject) {
      const range = this.rangesObject[key];


      if (
        moment(startDate).set('h', 0).set('m', 0).set('s', 1).format(ClientDetails.formatUTC) == range.startDate.format(ClientDetails.formatUTC) &&
        moment(endDate).set('h', 23).set('m', 59).set('s', 59).format(ClientDetails.formatUTC) == range.endDate.format(ClientDetails.formatUTC)
      ) {


        return key;
      }
    }
    return null;
  }

  datesUpdated(range = null) {


    if (this.selected) {
      const result: any = {
        startDate: null,
        endDate: null,
      };

      if (this.selected.startDate != null) {
        result.startDate = moment(this.selected.startDate).format(ClientDetails.formatUTC);
      }
      if (this.selected.endDate != null) {
        result.endDate = moment(this.selected.endDate).format(ClientDetails.formatUTC);
      }
      if (this.selectedRange && this.initCount > 0 && this.sendOnInit === false) {
        result.dateRange = this.getKeyFromDates(this.selected.startDate, this.selected.endDate);

        this.getRange.emit(result);
      } else if (this.selectedRange && this.initCount === 0 && this.sendOnInit === false) {
        this.initCount = 1;
      } else {
        result.dateRange = this.getKeyFromDates(this.selected.startDate, this.selected.endDate);

        this.getRange.emit(result);
      }
    }
  }

  ngOnInit() {
    if (this.singleDatePicker) {
      this.ranges = {};

      if (this.hasTimePicker) {
        this.selected = {
          startDate: this.selected.startDate ? this.selected.startDate : moment(),
        };
      } else {
        this.selected = {
          startDate: this.selected.startDate ? this.selected.startDate : moment().utcOffset(0).set('h', 0).set('m', 0),
        };
      }
    } else {
      this.ranges = {
        Today: [this.rangesObject.today.startDate, this.rangesObject.today.endDate],
        Yesterday: [this.rangesObject.yesterday.startDate, this.rangesObject.yesterday.endDate],
        'Last 7 Days': [this.rangesObject.days7.startDate, this.rangesObject.days7.endDate],
        'Last 30 Days': [this.rangesObject.days30.startDate, this.rangesObject.days30.endDate],
        'Last 60 Days': [this.rangesObject.days60.startDate, this.rangesObject.days60.endDate],
        'Last 90 Days': [this.rangesObject.days90.startDate, this.rangesObject.days30.endDate],
        'Last 180 Days': [this.rangesObject.days180.startDate, this.rangesObject.days180.endDate],
        All: [this.rangesObject.all.startDate, this.rangesObject.all.endDate],
      };

      if (this.selectedRange && this.rangesObject[this.selectedRange]) {
        this.selected = this.rangesObject[this.selectedRange];

        if (this.sendOnInit === true) {
          this.datesUpdated();
        }
      } else if (this.sendNull === true) {
        this.selected = {
          startDate: null,
          endDate: null,
        };
        if (this.sendOnInit === true) {
          this.datesUpdated();
        }
      }
    }
  }

  getDefaultRange() {
    if (this.selectedRange && this.rangesObject[this.selectedRange]) {
      this.selected = this.rangesObject[this.selectedRange];
      this.datesUpdated();
    } else if (this.sendNull === true) {
      this.selected = {
        startDate: null,
        endDate: null,
      };
      this.datesUpdated();
    }
  }
}
