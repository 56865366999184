import { Component, EventEmitter, Inject, Input, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { DrugsViewComponent } from '../drugs-view/drugs-view.component';
import { DrugsService } from '../shared/drugs.service';

@Component({
  selector: 'drugs-customer-view',
  templateUrl: './drugs-customer-view.component.html',
  styleUrls: ['./drugs-customer-view.component.css'],
})
export class DrugsCustomerViewComponent implements OnInit {
  @Input()
  drugID;
  @Input()
  drug;
  closeModal = new EventEmitter();
  @Input()
  isModal = false;

  isAdminOrPromoter = false;
  sessionType;

  constructor(
    private authenticationService: AuthenticationService,
    private activeRoute: ActivatedRoute,
    private drugService: DrugsService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.drugID) {
        this.drugID = data.drugID;
        this.isModal = true;
      }
    }
  }

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      if (params['drugID']) {
        this.drugID = params['drugID'];
      }

      this.authenticationService.getSessionType().subscribe((res) => {
        this.sessionType = res;

        this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
          this.isAdminOrPromoter = res;

          if (this.drugID) {
            this.drugService.getMedicationDetails(this.drugID, {}, this.sessionType).subscribe((res) => {
              if (res) {
                this.drug = res;
              }
            });
          }
        });
      });
    });
  }

  closeEvent() {
    this.closeModal.emit(true);
  }

  viewDrug() {
    if (this.drug && this.drug.Drug_key) {
      const ref = RootAppComponent.dialog.open(DrugsViewComponent, {
        data: {
          drugID: this.drug.Drug_key,
        },
        width: '800px',
      });
      ref.componentInstance.closeModal.subscribe((res) => {
        ref.close();
      });
    }
  }
}
