<mat-card class="rel supplier-card brandView clearfix" *ngIf="testimony" @ngIfAnimation>
  <div class="card-header primary-gradient-img clearfix inModal rel">
    <div class="row clearfix content">
      <button class="btn-close btn-clear mat-button white" (click)="closeModalEvent()">
        <mat-icon>clear</mat-icon>
      </button>
      <div class="row titleArea clearfix">
        <h2 class="summary-header rm-m">User submitted testimonial</h2>
      </div>
      <div class="date-chip-area pull-right">
        <p class="small rm-m mr text-right">
          <mat-icon class="fas fa-clock"></mat-icon>
          {{ testimony.updated_at | TimeLabelPast }} ago
        </p>
      </div>
    </div>
  </div>

  <mat-dialog-content class="stacked-card-view">
    <div class="row clearfix">
      <div class="row clearfix flex">
        <div class="pull-left mr qtr-width viewUserAvtar">
          <img class="viewSelectAvatar" *ngIf="testimony['fileID']" src="{{ replaceImage(testimony['fileID']) }}"/>
          <!-- <img class="viewSelectAvatar" *ngIf="testimony['fileID']==null" src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/profile-placeholder.png"> -->
          <app-contact-view-picture
            *ngIf="!testimony['fileID'] || testimony['fileID'] == ''"
            [contactID]="testimony['userContactID']"
          ></app-contact-view-picture>
        </div>
        <div class="full-width ml">
          <button class="pull-right" [matMenuTriggerFor]="menu" mat-raised-button>
            <mat-icon>more_vert</mat-icon> Actions
          </button>
          <mat-menu #menu="matMenu">
            <button
              *ngIf="testimony['_id'] && testimony['type'] == 'guest' && testimony['userID']"
              (click)="viewProspect(testimony['userID'])"
              mat-menu-item
            >
              <mat-icon>description</mat-icon>
              <span>View Prospect</span>
            </button>

            <button
              *ngIf="
                testimony['_id'] &&
                testimony['type'] == 'user' &&
                testimony['role'] == 'merchant' &&
                testimony['userID']
              "
              (click)="viewMerchant(testimony['userID'])"
              mat-menu-item
            >
              <mat-icon>description</mat-icon>
              <span>View Merchant</span>
            </button>

            <button *ngIf="testimony['_id']" (click)="deleteTestimony(testimony['_id'])" mat-menu-item>
              <mat-icon>delete</mat-icon>
              <span>Delete Testimony</span>
            </button>
            <button
              *ngIf="testimony['_id'] && testimony['isActive'] != true"
              (click)="activateTestimony(testimony['_id'])"
              mat-menu-item
            >
              <mat-icon>thumb_up</mat-icon>
              <span>Activate Testimony</span>
            </button>
            <button
              *ngIf="testimony['_id'] && testimony['isActive'] == true"
              (click)="deactivateTestimony(testimony['_id'])"
              mat-menu-item
            >
              <mat-icon>thumb_down</mat-icon>
              <span>Deactivate Testimony</span>
            </button>
          </mat-menu>

          <div class="row clearfix">
            <label class="small">Author</label>
            <p class="rm-mt">{{ testimony['firstName'] }} {{ testimony['lastName'] }}</p>
          </div>

          <div class="row clearfix">
            <label class="small">Account Type</label>
            <p class="rm-mt">{{ testimony['type'] }} {{ testimony['role'] || '' }}</p>
          </div>
          <div class="row clearfix">
            <label class="small">Author's Email</label>
            <p class="rm-mt">{{ testimony['email'] }}</p>
          </div>
        </div>
      </div>
      <hr/>
      <div class="row clearfix">
        <div class="thrd-width">
          <label class="small">Created date</label>
          <p class="rm-mt">{{ testimony['created_at'] | TimeLabelPast }} ago</p>
        </div>
        <div class="thrd-width">
          <label class="small">Testimonial's Status</label>
          <p class="rm-mt">
            <span *ngIf="testimony['isActive'] != true" [ngStyle]="{ color: '#ff0000' }">Not Active</span>
            <span *ngIf="testimony['isActive'] == true" [ngStyle]="{ color: '#1ca90c' }">Active</span>
          </p>
        </div>
        <div class="thrd-width">
          <label class="small">Rating</label>
          <a class="starRating" *ngFor="let item of starList">
            <i class="fa-star" [ngClass]="{ far: item, fas: !item }"></i>
          </a>
        </div>
      </div>
      <!-- <div class="row clearfix well">
      <label class="small">{{"Title" | translate}}</label>
      <p class="rm-mt">{{testimony['title']}}</p>
    </div> -->
      <div class="row clearfix well">
        <label class="small">Content</label>
        <p class="rm-mt newline" [innerHTML]="testimony['content'] | safeHtml"></p>
      </div>
    </div>
  </mat-dialog-content>
</mat-card>
