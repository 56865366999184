<div class="row clearfix">
  <div class="card-header primary-gradient-img clearfix inModal">
    <div class="row clearfix">
      <button class="btn-close btn-clear mat-button white" *ngIf="isModal == true" (click)="closeModal()">
        <mat-icon>clear</mat-icon>
      </button>
      <div class="row titleArea clearfix">
        <mat-icon class="pull-left">lock</mat-icon>
        <h2 class="summary-header rm-m">
          <span *ngIf="isEdit == false">Create new address</span>
          <span *ngIf="isEdit == true">Update address</span>
          <span class="subLabel"></span>
        </h2>
      </div>
    </div>
  </div>
  <div class="row clearfix" *ngIf="isPrimary == true">
    <p class="small notice">
      <mat-icon>help</mat-icon>
      If you wish to set a new primary address, please create a new address and select that as 'primary'.
    </p>
  </div>
  <div class="row clearfix flex">
    <mat-icon class="fas fa-tag inline"></mat-icon>
    <mat-form-field class="full-width" [hidden]="isEdit == false" appearance="outline">
      <mat-label>Label</mat-label>
      <mat-select
        [(ngModel)]="label"
        (selectionChange)="getAddressByType(label)"
        placeholder="Address type"
        name="label"
      >
        <mat-option *ngFor="let type of addressTypes" [value]="type">
          <span>{{ type | titlecase }}</span>
          <!-- <span *ngIf="convertToLowerCase(type) == convertToLowerCase(targetAddressType)">{{ type | titlecase}}
            - {{"Primary" | translate}}</span> -->
        </mat-option>
        <hr/>
        <mat-option [value]="null"> Create new label</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="full-width" [hidden]="isEdit == true" appearance="outline">
      <mat-label>Label</mat-label>
      <input [(ngModel)]="label" matInput name="labelInput" placeholder="Label"/>
    </mat-form-field>

    <mat-checkbox class="full-width labelCheckbox" [(ngModel)]="isPrimary" [disabled]="isPrimary" name="isPrimary"
    >Set to primary
    </mat-checkbox>
  </div>

  <div class="row clearfix">
    <app-address-input
      class="full-width"
      [clear]="clearAddress"
      [displayMap]="false"
      [canClose]="false"
      [postCode]="myPostCode"
      [state]="myState"
      [suburb]="mySuburb"
      [streetNumber]="myStreetNumber"
      [streetName]="myStreetName"
      [unitNumber]="myUnitNumber"
      [streetType]="myStreetType"
      [isRequired]="isRequired"
      (validate)="isAdressValidEvent($event)"
      (getAdress)="getAdress($event)"
    >
    </app-address-input>
  </div>
  <button
    class=""
    *ngIf="isPrimary == false && isDefaultLabel == false"
    (click)="removeAddressByLabel()"
    mat-raised-button
    color="primary"
  >
    Delete
  </button>

  <button
    class="ml"
    *ngIf="isPrimary == false && isDefaultLabel == false"
    (click)="clearAddressEvent()"
    mat-raised-button
    color="primary"
  >
    clear
  </button>

  <button class="" *ngIf="isDefaultLabel == true" (click)="clearAddressEvent()" mat-raised-button color="primary">
    clear
  </button>
  <button
    class="pull-right"
    *ngIf="isEdit == false"
    [disabled]="!label || validateAddress == false"
    (click)="create()"
    mat-raised-button
    color="accent"
  >
    Create
  </button>
  <button
    class="pull-right"
    *ngIf="isEdit == true"
    [disabled]="!label"
    (click)="modify()"
    mat-raised-button
    color="accent"
  >
    Update
  </button>
</div>
