<div class="teethShapDeleted text-center animated fadeInDown" (click)="selectTooth()" val="42">
  <mat-icon class="close">cancel</mat-icon>
  <!-- <p>42</p> -->
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150.58 247">
    <defs>
      <style>
        .cls-1 {
          fill: #fff;
        }
      </style>
    </defs>
    <title>teeth</title>
    <path *ngFor="let t of toothShape" [ngClass]="t.class" [attr.d]="t.d" />
  </svg>
  <p class="tooth-number" *ngIf="!tooth.hideNumber">{{ tooth.toothNumber }}</p>
</div>
