import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { GoogleReviewReplyDialogComponent } from '../dialogs/google-review-reply-dialog/google-review-reply-dialog.component';
import { GoogleReviewsService } from '../shared/google-reviews.service';
import { GmbReview } from '../shared/types/gmb.type';

@Component({
  selector: 'sr-google-review',
  templateUrl: './google-review.component.html',
  styleUrls: ['./google-review.component.css'],
})
export class GoogleReviewComponent implements OnInit {
  @Output() replySent: EventEmitter<{ id: string; comment: string }> = new EventEmitter();

  @Input() googleReview: GmbReview;
  @Input() Google_Reviews_Location_ID: string;
  @Input() Google_Reviews_Account_ID: string;
  @Input() merchantID: string;

  @Input() sessionType = 'guest';

  constructor(private googleReviewService: GoogleReviewsService) {}

  ngOnInit(): void {}

  openReviewReplyModal(review: GmbReview) {
    let replyText;

    if (review.reviewReply) {
      replyText = review.reviewReply.comment;
    }

    const googleReviewReplyDialogComponent = RootAppComponent.dialog.open<
      GoogleReviewReplyDialogComponent,
      {
        clientName: string;
        comment?: string;
        profilePhotoReviewLink: string;
        starRating: number;
      }
    >(GoogleReviewReplyDialogComponent, {
      width: '500px',
      panelClass: 'merchCard',
      data: {
        clientName: review.reviewer.displayName,
        comment: replyText,
        profilePhotoReviewLink: review.reviewer.profilePhotoUrl,
        starRating: review.starRating as number,
      },
    });

    googleReviewReplyDialogComponent.afterClosed().subscribe((result) => {
      if (result) {
        this.replySent.emit({ id: review.reviewId, comment: result });
        this.googleReviewService
          .replyToReview(
            this.merchantID,
            this.sessionType,
            this.Google_Reviews_Account_ID,
            this.Google_Reviews_Location_ID,
            review.reviewId,
            result
          )
          .subscribe(() => {
            this.replySent.emit({ id: review.reviewId, comment: result });
          });
      }
    });
  }
}
