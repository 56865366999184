import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { MapViewComponent } from '../../../../../../../shared/components/map-view/map-view.component';
import { RootAppComponent } from '../../../../../../../shared/components/root-component/root-component.component';
import { CountStringIdsPipe } from '../../../../../../../shared/pipes/count-string-ids/count-string-ids.pipe';
import { UtilsClass } from '../../../../../../../shared/types/utils/utils.class';
import { CustomerProspectViewModelComponent } from '../../../../../../customer-prospect/customer-prospect-view-model/customer-prospect-view-model.component';
import { Prospect } from '../../../../../../customer-prospect/shared/types/customer-prospect.type';
import { CustomerAndProspect } from '../../../../../../invitation/shared/types/invitation.type';
import { Merchant } from '../../../../../../merchant/shared/types/merchant.type';

@Component({
  selector: 'ipv-note-list-header',
  templateUrl: './note-list-header.component.html',
  styleUrls: ['./note-list-header.component.css'],
  providers: [CountStringIdsPipe],
})
export class NoteListHeaderComponent implements OnDestroy, OnChanges {
  util = new UtilsClass();

  @Output() closeEvent: EventEmitter<void> = new EventEmitter();

  @Input() selectedPatient: Partial<CustomerAndProspect> | Prospect;

  @Input() practice: Partial<Merchant>;

  tags: string[];
  geocoder;
  distanceAway;

  constructor(private countStringIdsPipe: CountStringIdsPipe) {}

  ngOnDestroy() {
    /** required for untilDestroyed (Replace with @UntilDestroyed() Decorator in angular 9 and delete ngOnDestroy()) */
  }

  ngOnChanges() {
    this.calculateDistance();
    if (this.selectedPatient) {
      const tagsCount = this.countStringIdsPipe.transform(this.selectedPatient['Tag.Label']);
      this.tags = tagsCount > 0 ? this.getTags() : [];
    }
  }

  displayMap(patient: Partial<CustomerAndProspect> | Prospect) {
    if (patient) {
      const location = {
        zoom: 17,
        postCode: patient['addresses.Postcode'],
        state: patient['addresses.State'],
        streetType: patient['addresses.Street Type'],
        streetName: patient['addresses.Street Name'],
        streetNumber: patient['addresses.Street Nr'],
        suburb: patient['addresses.Suburb'],
        country: patient['addresses.Country.Code'],
        addressLabel: patient['addresses.Calculated'],
        latitude: patient['Address.Latitude'],
        longitude: patient['Address.Longitude'],
      };

      const mapViewModal = RootAppComponent.dialog.open(MapViewComponent, {
        data: location,
        panelClass: 'modal-map',
        width: '800px',
        height: '600px',
      });

      mapViewModal.componentInstance.close.subscribe(() => {
        mapViewModal.close();
      });
    }
  }

  openPatientProfile(ID: string) {
    if (ID) {
      const customerProspectViewModelComponent = RootAppComponent.dialog.open(CustomerProspectViewModelComponent, {
        data: {
          patientID: ID,
          isFullDetails: true,
        },
        width: '700px',
      });

      customerProspectViewModelComponent.componentInstance.close.pipe(untilDestroyed(this)).subscribe((isClosed) => {
        if (isClosed === true) {
          customerProspectViewModelComponent.close();
        }
      });

      customerProspectViewModelComponent.componentInstance.detailedView
        .pipe(untilDestroyed(this))
        .subscribe((showDetailedView) => {
          if (showDetailedView === true) {
            customerProspectViewModelComponent.close();
            customerProspectViewModelComponent
              .afterClosed()
              .pipe(untilDestroyed(this))
              .subscribe(() => {
                this.close();
              });
          }
        });
    }
  }

  calculateDistance() {
    this.distanceAway = null;
    if (this.selectedPatient && this.selectedPatient['ID']) {
      this.util
        .getDistanceAway(
          this.practice['Address.Latitude'],
          this.practice['Address.Longitude'],
          this.selectedPatient['Address.Latitude'].toString(),
          this.selectedPatient['Address.Longitude'].toString(),
          this.selectedPatient['addresses.Calculated'],
          this.geocoder
        )
        .then((distanceAwayResult) => {
          if (distanceAwayResult) {
            this.distanceAway = distanceAwayResult.distanceAway;
          }
        });
    }
  }

  getTags() {
    const tags = this.selectedPatient['Tag.Label'].split('|');
    if (tags.length > 3) {
      return tags.slice(0, 4);
    } else {
      return tags;
    }
  }

  close() {
    this.closeEvent.emit();
  }
}
