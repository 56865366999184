<mat-card class="card">
  <div class="card-header clearfix">
    <h3 class="sr-title sm-h3 rm-mt rm-mb">Documents Library</h3>
    <p class="rm-mt">
      View the default post-op and pre-op documents for each {{ "KEYWORD.treatment" | translate }} item
    </p>
    <hr/>
  </div>

  <mat-table #table [dataSource]="dataSource">
    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->
    <!-- ID Column -->
    <ng-container mdColumnDef="userId">
      <mat-header-cell *mdHeaderCellDef> ID</mat-header-cell>
      <mat-cell *mdCellDef="let row"> {{ row.id }} </mat-cell>
    </ng-container>
    <!-- Brand Column -->
    <ng-container mdColumnDef="brand">
      <mat-header-cell *mdHeaderCellDef> Brand</mat-header-cell>
      <mat-cell *mdCellDef="let row"> {{ row.brand }} </mat-cell>
    </ng-container>
    <!-- Docs  Column -->
    <ng-container mdColumnDef="docs">
      <mat-header-cell *mdHeaderCellDef> Documents</mat-header-cell>
      <mat-cell *mdCellDef="let row"> {{ row.docs }} </mat-cell>
    </ng-container>

    <!-- Type Column -->
    <ng-container mdColumnDef="userName">
      <mat-header-cell *mdHeaderCellDef>{{ "KEYWORD.treatment" | translate | titlecase }} Type</mat-header-cell>
      <mat-cell *mdCellDef="let row"> {{ row.name }} </mat-cell>
    </ng-container>
    <!-- Category Column -->
    <ng-container mdColumnDef="itemType">
      <mat-header-cell *mdHeaderCellDef>{{ "KEYWORD.treatment" | translate | titlecase }} Category</mat-header-cell>
      <mat-cell *mdCellDef="let row"> {{ row.itemType }} </mat-cell>
    </ng-container>
    <!-- Action Button Column -->
    <ng-container cdkColumnDef="Actions">
      <mat-header-cell *cdkHeaderCellDef> Actions</mat-header-cell>
      <mat-cell *cdkCellDef="let row">
        <div style="float: right">
          <button [matMenuTriggerFor]="menu" mat-icon-button>
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button (click)="openModal()" mat-menu-item>
              <mat-icon>folder_shared</mat-icon>
              <span>View Item</span>
            </button>
            <button mat-menu-item>
              <mat-icon>delete</mat-icon>
              <span>Archive</span>
            </button>
          </mat-menu>
        </div>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
  <mat-paginator #paginator [length]="exampleDatabase.data.length" [pageIndex]="0" [pageSize]="10"
                 [pageSizeOptions]="[5, 10, 25, 100]">
  </mat-paginator>
</mat-card>
