import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class MarketingService {
  params: HttpParams;

  constructor(private http: HttpClient) {}

  editMarketingSocialMedia(id, payload, isAdminOrPromoter = false) {
    if (isAdminOrPromoter == true) {
      const urlPatient = environment.nodeUrl + '/marketing/social-media/global/' + id;
      return this.http.put(urlPatient, payload).pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      const urlPatient = environment.nodeUrl + '/marketing/social-media/' + id;
      return this.http.put(urlPatient, payload).pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  addCampaignToPracticeLandingPage(id, payload, isAdminOrPromoter = false) {
    if (isAdminOrPromoter == true) {
      const urlPatient = environment.nodeUrl + '/marketing/practice/global/' + id;
      return this.http.put(urlPatient, payload).pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      const urlPatient = environment.nodeUrl + '/marketing/practice/' + id;
      return this.http.put(urlPatient, payload).pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  inviteGoogleReview(payload, isAdminOrPromoter = false): Observable<any> {
    if (isAdminOrPromoter == true) {
      return this.http
        .post(environment.nodeUrl + '/customer-prospect/google-review/global', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .post(environment.nodeUrl + '/customer-prospect/google-review', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  editMarketing(id, payload, isAdminOrPromoter = false) {
    if (isAdminOrPromoter == true) {
      const urlPatient = environment.nodeUrl + '/marketing/global/' + id;
      return this.http.put(urlPatient, payload).pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      const urlPatient = environment.nodeUrl + '/marketing/' + id;
      return this.http.put(urlPatient, payload).pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getMarketingLogo(ID, payload = {}) {
    const params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key) && payload[key]) {
          params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }

    return this.http
      .get(`${environment.nodeUrl}/marketing/logo-picture/` + ID, options)
      .pipe(map((res: Response) => res['data']));
  }

  getMarketingLogoStreamLink(ID, LastModified = null) {
    if (
      LastModified &&
      LastModified != '' &&
      LastModified != undefined &&
      LastModified != 'null' &&
      LastModified != 'undefined '
    ) {
      return `${environment.nodeUrl}/marketing/logo-picture-stream-cached/${LastModified}/` + ID;
    } else if (ID) {
      if (ID) {
        return `${environment.nodeUrl}/marketing/logo-picture-stream/` + ID;
      }
    }
    return null;
  }

  getMarketingFacebook(ID, payload = {}) {
    const params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key) && payload[key]) {
          params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }

    return this.http
      .get(`${environment.nodeUrl}/marketing/facebook-picture/` + ID, options)
      .pipe(map((res: Response) => res['data']));
  }

  getMarketingFacebookStreamLink(ID) {
    if (ID) {
      return `${environment.nodeUrl}/marketing/facebook-picture-stream/` + ID;
    }
    return null;
  }

  getMarketingTwitter(ID, payload = {}) {
    const params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key) && payload[key]) {
          params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }

    return this.http
      .get(`${environment.nodeUrl}/marketing/twitter-picture/` + ID, options)
      .pipe(map((res: Response) => res['data']));
  }

  getMarketingTwitterStreamLink(ID) {
    if (ID) {
      return `${environment.nodeUrl}/marketing/twitter-picture-stream/` + ID;
    }
    return null;
  }

  getMarketingLinkedin(ID, payload = {}) {
    const params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key) && payload[key]) {
          params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }

    return this.http
      .get(`${environment.nodeUrl}/marketing/linked-picture/` + ID, options)
      .pipe(map((res: Response) => res['data']));
  }

  getMarketingLinkedinStreamLink(ID) {
    if (ID) {
      return `${environment.nodeUrl}/marketing/linked-picture-stream/` + ID;
    }
    return null;
  }

  getMarketingInstagram(ID, payload = {}) {
    const params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key) && payload[key]) {
          params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }

    return this.http
      .get(`${environment.nodeUrl}/marketing/instragram-picture/` + ID, options)
      .pipe(map((res: Response) => res['data']));
  }

  getMarketingInstagramStreamLink(ID) {
    if (ID) {
      return `${environment.nodeUrl}/marketing/instragram-picture-stream/` + ID;
    }
    return null;
  }

  getMarketingTemplate(ID, payload = {}) {
    const params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key) && payload[key]) {
          params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }

    return this.http
      .get(`${environment.nodeUrl}/marketing/template/` + ID, options)
      .pipe(map((res: Response) => res['data']));
  }

  getMarketingTemplatePromoter(ID, payload = {}, isAdminOrPromoter = false) {
    const params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key) && payload[key]) {
          params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }

    if (isAdminOrPromoter == true) {
      return this.http
        .get(`${environment.nodeUrl}/marketing/template-promoter/global/` + ID, options)
        .pipe(map((res: Response) => res['data']));
    } else {
      return this.http
        .get(`${environment.nodeUrl}/marketing/template-promoter/` + ID, options)
        .pipe(map((res: Response) => res['data']));
    }
  }

  getMarketingSocialMedia(ID, type, payload = {}) {
    const params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key) && payload[key]) {
          params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }

    return this.http
      .get(`${environment.nodeUrl}/marketing/social-media/` + ID + '/' + type, options)
      .pipe(map((res: Response) => res['data']));
  }

  getMarketingSocialMediaStreamLink(ID, type) {
    if (ID) {
      return `${environment.nodeUrl}/marketing/social-media-stream/` + ID + '/' + type;
    }
    return null;
  }

  createSocialMediaMarketing(payload, isAdminOrPromoter = false) {
    if (isAdminOrPromoter == true) {
      const urlPatient = environment.nodeUrl + '/marketing/social-media-campaign/global';
      return this.http.post(urlPatient, payload).pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      const urlPatient = environment.nodeUrl + '/marketing/social-media-campaign';
      return this.http.post(urlPatient, payload).pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  createMarketing(payload, isAdminOrPromoter = false) {
    if (isAdminOrPromoter == true) {
      const urlPatient = environment.nodeUrl + '/marketing/global';
      return this.http.post(urlPatient, payload).pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      const urlPatient = environment.nodeUrl + '/marketing';
      return this.http.post(urlPatient, payload).pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getMarketingDetails(id, payload = {}, sessionType = 'guest'): Observable<any> {
    const params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key) && payload[key]) {
          params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }

    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http
        .get(environment.nodeUrl + '/marketing/global/' + id, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
      return this.http
        .get(environment.nodeUrl + '/marketing/' + id, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/marketing/public/' + id, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  activateCompaign(id, isAdminOrPromoter = false): Observable<any> {
    if (isAdminOrPromoter == true) {
      return this.http
        .put(environment.nodeUrl + '/marketing/active/global/' + id, {})
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .put(environment.nodeUrl + '/marketing/active/' + id, {})
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  cancelCompaign(id, isAdminOrPromoter = false): Observable<any> {
    if (isAdminOrPromoter == true) {
      return this.http
        .put(environment.nodeUrl + '/marketing/cancel/global/' + id, {})
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .put(environment.nodeUrl + '/marketing/cancel/' + id, {})
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  resendCompaign(id, payload, isAdminOrPromoter = false): Observable<any> {
    if (isAdminOrPromoter == true) {
      return this.http
        .put(environment.nodeUrl + '/marketing/resend/global/' + id, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .put(environment.nodeUrl + '/marketing/resend/' + id, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getStatistics(payload, isAdminOrPromoter = false, isHidden = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (isHidden == true) {
      if (isAdminOrPromoter == true) {
        return this.http
          .get(environment.nodeUrl + '/marketing/statistics-hidden/global', options)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else {
        return this.http
          .get(environment.nodeUrl + '/marketing/statistics-hidden', options)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      }
    } else {
      if (isAdminOrPromoter == true) {
        return this.http
          .get(environment.nodeUrl + '/marketing/statistics/global', options)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else {
        return this.http
          .get(environment.nodeUrl + '/marketing/statistics', options)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      }
    }
  }

  getList(payload, isAdminOrPromoter = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (isAdminOrPromoter == true) {
      return this.http
        .get(environment.nodeUrl + '/marketing/global', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/marketing', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getOneCampaign(id): Observable<any> {
    return this.http.get(environment.nodeUrl + '/marketing/' + id).pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getMerchantStatistics(): Observable<any> {
    return this.http
      .get(environment.nodeUrl + '/marketing/statistics-merchant')
      .pipe(map((res: Response) => res['data']));
  }

  getPatientStatistics(payload, isAdminOrPromoter = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }
    options = {
      params,
    };
    if (isAdminOrPromoter == true) {
      return this.http
        .get(environment.nodeUrl + '/marketing/statistics-patient/global', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/marketing/statistics-patient', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  sendTemplate(id, data) {
    return this.http.post(`${environment.nodeUrl}/marketing/test-template/${id}`, data);
  }

  getNonReachedTargetsList(payload): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }
    options = {
      params,
    };

    return this.http
      .get(`${environment.nodeUrl}/non-reached-targets`, options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getMarketingStaticTemplateList(payload, isAdminOrPromoter = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }

    if (isAdminOrPromoter == true) {
      return this.http
        .get(`${environment.nodeUrl}/template/global`, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(`${environment.nodeUrl}/template/`, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getStaticTemplatePicture(ID) {
    return this.http
      .get(`${environment.nodeUrl}/template/picture/` + ID, {})
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getCampaignMessageList(payload, isAdminOrPromoter = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }

    if (isAdminOrPromoter == true) {
      return this.http
        .get(`${environment.nodeUrl}/campaign-message/global/`, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(`${environment.nodeUrl}/campaign-message/`, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  ////////////////////////////////////////////////////////

  getFilterSections(payload): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }
    return this.http
      .get(`${environment.nodeUrl}/named-filter-property/`, options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  createFilter(payload, isAdminOrPromoter = false): Observable<any> {
    if (isAdminOrPromoter == true) {
      return this.http
        .post(environment.nodeUrl + '/group-filters/global', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .post(environment.nodeUrl + '/group-filters', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getFilterList(payload, isAdminOrPromoter = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }

    if (isAdminOrPromoter == true) {
      return this.http
        .get(`${environment.nodeUrl}/group-filters/global`, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(`${environment.nodeUrl}/group-filters/`, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getOneFilter(ID, isPromoterOrAdmin = false): Observable<any> {
    if (isPromoterOrAdmin == true) {
      return this.http
        .get(environment.nodeUrl + '/group-filters/global/' + ID, {})
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/group-filters/' + ID, {})
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  modifyFilter(id, body = {}, isPromoterOrAdmin = false): Observable<any> {
    if (isPromoterOrAdmin == true) {
      return this.http
        .put(environment.nodeUrl + '/group-filters/global/' + id, body)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .put(environment.nodeUrl + '/group-filters/' + id, body)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  activeFilter(id, body = {}, isPromoterOrAdmin = false): Observable<any> {
    if (isPromoterOrAdmin == true) {
      return this.http
        .put(environment.nodeUrl + '/group-filters/active/global/' + id, body)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .put(environment.nodeUrl + '/group-filters/active/' + id, body)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  removeFilter(id, isPromoterOrAdmin = false): Observable<any> {
    if (isPromoterOrAdmin == true) {
      return this.http
        .delete(environment.nodeUrl + '/group-filters/global/' + id, {})
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .delete(environment.nodeUrl + '/group-filters/' + id, {})
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  editFilterGroup(payload, sessionType): Observable<any> {
    if (sessionType == 'admin' || sessionType === 'promoter') {
      return this.http
        .put(`${environment.nodeUrl}/group-filters/group/global`, payload)
        .pipe(map((res: Response) => res['data']));
    } else {
      return this.http
        .put(`${environment.nodeUrl}/group-filters/group`, payload)
        .pipe(map((res: Response) => res['data']));
    }
  }
}
