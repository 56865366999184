import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, EventEmitter, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ClipboardService } from 'ngx-clipboard';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';

@Component({
  selector: 'app-staff-portal',
  templateUrl: './staff-portal.component.html',
  styleUrls: ['./staff-portal.component.css'],
  animations: [
    trigger('simpleFadeAnimation', [
      state('in', style({ opacity: 1, height: '*' })),
      transition(':enter', [style({ opacity: 0, height: 0 }), animate(300)]),
      transition(':leave', animate(300, style({ opacity: 0, height: 0 }))),
    ]),
  ],
})
export class StaffPortalComponent implements OnInit {
  settings = Settings.global;
  link;

  iFrameSrc;
  iFrameSrcSecured;
  loadIFrame = false;
  sessionType;

  downloadQRCodeDental = new EventEmitter();
  title = 'Staff Portal';
  constructor(
    private _clipboardService: ClipboardService,
    private _sanitizer: DomSanitizer,
    private authenticationService: AuthenticationService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((res) => {
      this.sessionType = res;

      this.link = `${this.settings['publicSiteFrontendLink']}/finance`;

      this.updateIFrameSrc(this.link);
    });
  }

  updateIFrameSrc(src: string): void {
    this.iFrameSrcSecured = this._sanitizer.bypassSecurityTrustResourceUrl(src);
  }

  onFrameLoad(e) {
    if (!this.loadIFrame) {
      this.loadIFrame = true;
      this.changeDetectorRef.detectChanges();
    } else {
      this.loadIFrame = true;
    }
  }

  linkCopy(url) {
    if (url) {
      this._clipboardService.copyFromContent(url);
      NotifyAppComponent.displayToast('success', 'Copied!', '');
    }
  }

  open(link) {
    if (link) {
      window.open(link, '_blank');
    }
  }
}
