<div class="rel">
  <div class="card-header primary-gradient-img clearfix confirmModal">
    <button class="btn-close btn-clear mat-button smt white custom-close" (click)="closeEvent()">
      <mat-icon>clear</mat-icon>
    </button>
    <div class="full-width flex main-title-container">
      <div class="row full-width title-container clearfix">
        <div class="title-container-sub">
          <h2 class="text-left title-header rm-m">
            Links/URL Directory

            <span class="subLabel" *ngIf="viewOnly == true">Choose a Link from the list to Preview the link.</span>
            <span class="subLabel" *ngIf="viewOnly != true">Choose a Link from the list to Insert it or on action
              Buttons for more options.</span>
          </h2>
        </div>
      </div>
    </div>
  </div>
  <div class="full-width">
    <div class="full-width flex">
      <mat-form-field class="full-width mr" appearance="outline">
        <mat-label>Search by Link, Label, Tags ...</mat-label>
        <input [(ngModel)]="filterData" (ngModelChange)="backToOne()" matInput name="search"
          placeholder="Search by Link, Label, Tags ..." tabIndex="1" />
      </mat-form-field>

      <button class="smt" [matMenuTriggerFor]="menu" mat-icon-button>
        <mat-icon class="fas fa-sort"></mat-icon>
      </button>

      <mat-menu #menu="matMenu">
        <button [ngClass]="{ 'accent-color': orderByFilter === 'Label' || orderByFilter === '-Label' }"
          (click)="orderBy('Label')" mat-menu-item>
          <span>Label</span>
          <span *ngIf="orderByFilter === 'Label' || orderByFilter === '-Label'">
            <mat-icon class="accent-color" *ngIf="orderByFilter === '-Label'">arrow_drop_up</mat-icon>
            <mat-icon class="accent-color" *ngIf="orderByFilter !== '-Label'">arrow_drop_down</mat-icon>
          </span>
        </button>

        <button
          [ngClass]="{ 'accent-color': orderByFilter === 'DateTimeCreated' || orderByFilter === '-DateTimeCreated' }"
          (click)="orderBy('-DateTimeCreated')" mat-menu-item>
          <span>Date</span>
          <span *ngIf="orderByFilter === 'DateTimeCreated' || orderByFilter === '-DateTimeCreated'">
            <mat-icon class="accent-color" *ngIf="orderByFilter === '-DateTimeCreated'">arrow_drop_down</mat-icon>
            <mat-icon class="accent-color" *ngIf="orderByFilter !== '-DateTimeCreated'"> arrow_drop_up</mat-icon>
          </span>
        </button>
      </mat-menu>
    </div>
    <div class="clearfix smt full-width flex flex-center">
      <mat-button-toggle-group class="btn-group rm-m selectType full-width flex tab" [(ngModel)]="typeFilter">
        <mat-button-toggle class="full-width" [value]="null">
          <span class="badge master"
            [endVal]="(merchantLinks | FilterArrayMultipleOrContain: 'search':filterData | lengthOfArray) || 0"
            countUp></span>
          All
        </mat-button-toggle>
        <mat-button-toggle class="full-width" [value]="'user'">
          <span class="badge master" [endVal]="
              (merchantLinks
                | FilterArrayMultipleOrContain: 'search':filterData
                | FilterArrayMultipleOrContain: 'type':'user'
                | lengthOfArray) || 0
            " countUp></span>
          {{ "KEYWORD.practice" | translate | titlecase}}
          <mat-icon class="fas fa-user-alt"></mat-icon>
        </mat-button-toggle>

        <mat-button-toggle class="full-width" [value]="'default'">
          <span class="badge master" [endVal]="
              (merchantLinks
                | FilterArrayMultipleOrContain: 'search':filterData
                | FilterArrayMultipleOrContain: 'type':'default'
                | lengthOfArray) || 0
            " countUp></span>
          {{ "BRANDING.Brand Name" | translate | titlecase }}
          <mat-icon class="fas {{'fa-tooth' | translate}}"></mat-icon>
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>
  <mat-dialog-content [ngClass]="
      (merchantLinks
        | FilterArrayMultipleOrContain: 'search':filterData
        | FilterArrayMultipleOrContain: 'type':typeFilter
        | lengthOfArray) > 20
        ? 'top-container'
        : 'top-container-no-pager'
    ">
    <div class="full-width">
      <div class="full-width">
        <div class="patientRecord row clearfix" *ngFor="
            let merchantLink of merchantLinks
              | FilterArrayMultipleOrContain: 'search':filterData
              | FilterArrayMultipleOrContain: 'type':typeFilter
              | OrderArrayObject: [orderByFilter]
              | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage }
          " (click)="previewOrSubmit(merchantLink)">
          <div class="full-width flex">
            <mat-icon class="fas {{'fa-tooth' | translate}} typeIcon accent-color"
              *ngIf="merchantLink.Is_Default == '1'"></mat-icon>
            <mat-icon class="fas fa-user-alt typeIcon primary-color" *ngIf="merchantLink.Is_Default == '0'"></mat-icon>

            <div class="full-width">
              <button class="documentLink-view-button pull-right" *ngIf="merchantLink && merchantLink['ID']"
                [matMenuTriggerFor]="previewMenu" (click)="$event.stopPropagation()" color="primary" mat-icon-button>
                <mat-icon>more_vert</mat-icon>
              </button>

              <mat-menu #previewMenu="matMenu">
                <button (click)="previewLink(merchantLink['Link'])" mat-menu-item>
                  <mat-icon>visibility</mat-icon>
                  <span>Preview</span>
                </button>


                <div class="qtr-width " style="display: none;">

                  <app-generate-qr class="full-width " [downloadLabel]="merchantLink.Label"
                    *ngIf="merchantLink && getRedirectURL(merchantLink.ID)" [source]="getRedirectURL(merchantLink.ID)"
                    [size]="150" [download]="merchantLink.downloadQRCode">
                  </app-generate-qr>

                </div>


                <button (click)="download(merchantLink)" mat-menu-item>
                  <mat-icon class="fas fa-qrcode"></mat-icon>
                  <span>Download QR Code</span>
                </button>


                <hr *ngIf="
                    merchantLink.Is_Default != '1' || (isPromoterOrAdmin == true && merchantLink.Is_Default == '1')
                  " />
                <button *ngIf="
                    merchantLink.Is_Default != '1' || (isPromoterOrAdmin == true && merchantLink.Is_Default == '1')
                  " (click)="edit(merchantLink['ID'])" mat-menu-item>
                  <mat-icon>edit</mat-icon>
                  <span>Edit</span>
                </button>
                <hr *ngIf="
                    merchantLink.Is_Default != '1' || (isPromoterOrAdmin == true && merchantLink.Is_Default == '1')
                  " />
                <button *ngIf="
                    merchantLink.Is_Default != '1' || (isPromoterOrAdmin == true && merchantLink.Is_Default == '1')
                  " (click)="delete(merchantLink['ID'])" mat-menu-item>
                  <mat-icon>delete</mat-icon>
                  <span>Delete</span>
                </button>
              </mat-menu>

              <p class="smt rm-mb accent-color" *ngIf="merchantLink.Is_Default == '1'">
                <strong>{{ merchantLink.Label }}</strong>
              </p>
              <p class="smt rm-mb primary-color" *ngIf="merchantLink.Is_Default == '0'">
                <strong>{{ merchantLink.Label }}</strong>
              </p>

              <a class="smb smt link-cust" (click)="$event.stopPropagation(); previewOrSubmit(merchantLink)"
                matTooltip="{{ merchantLink.Link }}">{{ merchantLink.Link | textLength: maxLength }}</a>

              <div class="full-width clearfix smb tag-list-lookup-merchant-list smt"
                *ngIf="merchantLink && merchantLink.tags && merchantLink.tags.length > 0">
                <mat-chip-list>
                  <mat-chip *ngFor="let tag of merchantLink['tags'] | orderBy">
                    {{ tag }}
                  </mat-chip>
                </mat-chip-list>
              </div>

              <p class="small rm-m date_label grey pull-left" *ngIf="merchantLink.DateTimeCreated">
                {{ merchantLink.DateTimeCreated | TimeLabelPast }} ago
              </p>
            </div>
          </div>
          <mat-divider></mat-divider>
        </div>

        <div class="full-width" *ngIf="
            (merchantLinks
              | FilterArrayMultipleOrContain: 'search':filterData
              | FilterArrayMultipleOrContain: 'type':typeFilter
              | lengthOfArray) <= 0 && isLoaded == true
          ">
          <app-empty-list-message [message]="'No Landing Page found'"></app-empty-list-message>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <div class="drop-button text-center mt full-width">
    <div class="full-width text-center page-container flex" *ngIf="
        (merchantLinks
          | FilterArrayMultipleOrContain: 'search':filterData
          | FilterArrayMultipleOrContain: 'type':typeFilter
          | lengthOfArray) > 20
      ">
      <div class="ml qtr-width flex pull-left">
        <mat-select class="page-size-in line-h pull-right" [(ngModel)]="itemsPerPage" style="padding-top: 16px"
          placeholder="Page size">
          <mat-option [value]="20"> 20</mat-option>
          <mat-option [value]="30"> 30</mat-option>
          <mat-option [value]="40"> 40</mat-option>
          <mat-option [value]="50"> 50</mat-option>
        </mat-select>
      </div>
      <pagination-controls class="full-width clearfix" (pageChange)="changePage($event)" responsive="true">
      </pagination-controls>
    </div>

    <hr />
    <button class="btn-large pull-right" *ngIf="isCreate == true" (click)="create()" mat-raised-button color="accent">
      <mat-icon class="smr">add</mat-icon> Add
    </button>
    <button class="btn-large pull-left" (click)="closeEvent()" mat-raised-button color="primary">close</button>
  </div>
</div>
