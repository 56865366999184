<div class="row clearfix flex full-width" *ngIf="!personalCircumstances && loading==false">

  <app-empty-list-message [title]="'Personal circumstances'"
    [message]="' There is no personal circumstances data available check.'">
  </app-empty-list-message>

</div>

<ng-container *ngIf="personalCircumstances && loading==false">
  <mat-card class="mb">
    <div class="colour-stripe"></div>

    <div class="row heading-row flex">
      <h3 class="sr-title full-width rm-m">
        <mat-icon class="fas fa-coins smr" style="font-size:1rem !important;"></mat-icon>
        Overviews
      </h3>

      <div class="full-width flex align-center control">
        <mat-form-field class="thrd-width mr" appearance="outline">
          <mat-label>Display frequency</mat-label>
          <mat-select [(ngModel)]="displayFrequency" (selectionChange)="changeDisplayFrequency($event.value)"
            name="frequency">
            <mat-option value="52">Weekly</mat-option>
            <mat-option value="26">Fortnightly</mat-option>
            <mat-option value="12">Monthly</mat-option>
            <mat-option value="1">Annually</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="half-width" appearance="outline">
          <mat-label>Information version</mat-label>
          <mat-select [(ngModel)]="personalCirID" (selectionChange)="changePersonalCircumstance($event.value)"
            name="version">
            <mat-option *ngFor="let v of personalCircumstanceVersions" [value]="v.value">
              {{ v.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <button class="ml decide-btn" *ngIf="checkstatus != 'Accept'" (click)="makeDecision()" mat-raised-button
          color="accent">
          <mat-icon class="fas fa-clipboard-check"></mat-icon> Decide
        </button>
      </div>
    </div>
    <hr class="mb" />

    <div class="row clearfix flex" matchItemHeight="matchThis">
      <div class="full-width column text-center">
        <div class="row clearfix">
          <h3 class="sr-title sm-h3 rm-mt">Income &amp; Expenses</h3>
        </div>
        <div class="clearfix rel rm-p">
          <div class="total" *ngIf="chart1 == 0" @simpleFadeAnimation>
            <span class="totLab">Net position</span>
            <span class="balance" [class.red]="netIncome <= 0">{{ calByFrequency(netIncome) | customCurrency }}</span>
          </div>

          <div class="total" *ngIf="chart1 == 1" @simpleFadeAnimation>
            <span class="totLab">Income total</span>
            <span class="balance">{{ calByFrequency(totalIncomePos) | customCurrency }}</span>
          </div>

          <div class="total" *ngIf="chart1 == 2" @simpleFadeAnimation>
            <span class="totLab">Expenses total</span>
            <span class="balance">{{ calByFrequency(totalExpenses) | customCurrency }}</span>
          </div>

          <ng-container *ngIf="chart1 == 0">
            <app-chart-input [showLegend]="false" [showLabel]="true" [data]="chartIncomeExpenses | orderBy: ['-name']"
              [chartType]="'pieZero'" [dataNameAttribute]="'name'" [dataValueAttribute]="'value'"></app-chart-input>
          </ng-container>

          <ng-container *ngIf="chart1 == 1">
            <app-chart-input [showLegend]="false" [showLabel]="true" [data]="chartIncome | orderBy: ['-name']"
              [chartType]="'pieZero'" [dataNameAttribute]="'name'" [dataValueAttribute]="'value'"></app-chart-input>
          </ng-container>

          <ng-container *ngIf="chart1 == 2">
            <app-chart-input [showLegend]="false" [showLabel]="true" [data]="chartExpenses | orderBy: ['-name']"
              [chartType]="'pieZero'" [dataNameAttribute]="'name'" [dataValueAttribute]="'value'"></app-chart-input>
          </ng-container>
        </div>
        <mat-form-field class="half-width mt no-float" appearance="outline">
          <mat-label>Display</mat-label>
          <mat-select [(ngModel)]="chart1">
            <mat-option [value]="0"> Income &amp; Expenses</mat-option>
            <mat-option [value]="1"> Income</mat-option>
            <mat-option [value]="2"> Expenses</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="full-width column text-center">
        <div class="row clearfix">
          <h3 class="sr-title sm-h3 rm-mt">Assets &amp; Liabilities</h3>
        </div>
        <div class="clearfix rel rm-p">
          <div class="total" *ngIf="chart2 == 0" @simpleFadeAnimation>
            <span class="totLab">Net Asset Position</span>
            <span class="balance">{{ netAssets | customCurrency }}</span>
            <div class="666666666"></div>
          </div>

          <div class="total" *ngIf="chart2 == 1" @simpleFadeAnimation>
            <span class="totLab">Total Assets</span>
            <span class="balance">{{ totalAssets | customCurrency }}</span>
          </div>

          <div class="total" *ngIf="chart2 == 2" @simpleFadeAnimation>
            <span class="totLab">Total Liabilities</span>
            <span class="balance">{{ totalLiabilities | customCurrency }}</span>
          </div>

          <ng-container *ngIf="chart2 == 0">
            <app-chart-input [showLegend]="false" [showLabel]="true"
              [data]="chartAssetsLiabilities | orderBy: ['-name']" [chartType]="'pieZero'" [dataNameAttribute]="'name'"
              [dataValueAttribute]="'value'"></app-chart-input>
          </ng-container>

          <ng-container *ngIf="chart2 == 1">
            <app-chart-input [showLegend]="false" [showLabel]="true" [data]="chartAssets | orderBy: ['-name']"
              [chartType]="'pieZero'" [dataNameAttribute]="'name'" [dataValueAttribute]="'value'"></app-chart-input>
          </ng-container>

          <ng-container *ngIf="chart2 == 2">
            <app-chart-input [showLegend]="false" [showLabel]="true" [data]="chartLiabilities | orderBy: ['-name']"
              [chartType]="'pieZero'" [dataNameAttribute]="'name'" [dataValueAttribute]="'value'"></app-chart-input>
          </ng-container>
        </div>
        <mat-form-field class="half-width mt no-float" appearance="outline">
          <mat-label>Display</mat-label>
          <mat-select [(ngModel)]="chart2">
            <mat-option [value]="0"> Assets &amp; Liabilities</mat-option>
            <mat-option [value]="1"> Assets</mat-option>
            <mat-option [value]="2"> Liabilities</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </mat-card>
  <!--
  <mat-card class="mb">
    <div class="colour-stripe"></div>

    <div class="row clearfix">
      <div class="row flex heading-row main">
        <h1 class="primary-color rm-m">
          <mat-icon class="far fa-folder"></mat-icon>
          Document
        </h1>

        <div class="net-block">
          <button *ngIf="isLockPCI == false" (click)="uploadDocument()" mat-raised-button color="accent">
            Add Document
          </button>
        </div>

      </div>
      <div class="row clearfix">
        <button class="full-width" (click)="documentTabVisible = !documentTabVisible" mat-flat-button>
          <ng-container *ngIf="!documentTabVisible"
          >Show details
            <mat-icon class="fas fa-chevron-down"></mat-icon>
          </ng-container>
          <ng-container *ngIf="documentTabVisible"
          >Hide details
            <mat-icon class="fas fa-chevron-up"></mat-icon>
          </ng-container>
        </button>
      </div>

      <div class="column" *ngIf="documentTabVisible == true" @simpleFadeAnimation>
        <div>
          <app-pci-document [contractID]="contractID"></app-pci-document>
        </div>
      </div>
    </div>
  </mat-card> -->

  <mat-card class="mb">
    <div class="colour-stripe"></div>

    <div class="row clearfix">
      <div class="row flex heading-row main">
        <h3 class="sr-title rm-m">
          <mat-icon class="fas fa-donate smr" style="font-size:1rem !important;"></mat-icon>
          Income
        </h3>

        <div class="net-block">
          <p class="small rm-m">Total Income</p>
          <p class="lead rm-m" [class.red]="totalIncome <= 0">
            {{
            calByFrequency(
              totalIncomeEmployment +
              totalIncomeInvestmentPropertys +
              totalIncomeShares +
              totalIncomeInterest +
              totalIncomeOtherIncomes
            ) | customCurrency
            }}
            <span class="peryear">/
              {{ displayFrequencyLabel }}
            </span>
          </p>
        </div>
      </div>
      <!-- <hr class="add-mb"> -->
      <div class="row clearfix">
        <button class="full-width" (click)="incomeTabVisible = !incomeTabVisible" mat-flat-button>
          <ng-container *ngIf="!incomeTabVisible">Show details
            <mat-icon class="fas fa-chevron-down"></mat-icon>
          </ng-container>
          <ng-container *ngIf="incomeTabVisible">Hide details
            <mat-icon class="fas fa-chevron-up"></mat-icon>
          </ng-container>
        </button>
      </div>
      <div class="column" *ngIf="incomeTabVisible == true" @simpleFadeAnimation>
        <div class="row clearfix">
          <!-- <div class="row clearfix">
            <h3 class="sr-title add-mt">{{personalCircumstances['EmploymentStatus.Label']}} - {{personalCircumstances['EmploymentType.Label']}}
            </h3>
          </div> -->

          <table class="econ-table">
            <tbody>
              <tr>
                <td class="text-left">
                  <p>Income total:</p>
                </td>
                <td class="text-right">
                  Per Weekly:
                  <span class="value">{{
                    (totalIncomeEmployment +
                      totalIncomeInvestmentPropertys +
                      totalIncomeShares +
                      totalIncomeInterest +
                      totalIncomeOtherIncomes) /
                    52 | customCurrency
                    }}</span>
                </td>
                <td class="text-right">
                  Per Fortnightly:
                  <span class="value">{{
                    (totalIncomeEmployment +
                      totalIncomeInvestmentPropertys +
                      totalIncomeShares +
                      totalIncomeInterest +
                      totalIncomeOtherIncomes) /
                    26 | customCurrency
                    }}</span>
                </td>
                <td class="text-right">
                  Per Monthly:
                  <span class="value">{{
                    (totalIncomeEmployment +
                      totalIncomeInvestmentPropertys +
                      totalIncomeShares +
                      totalIncomeInterest +
                      totalIncomeOtherIncomes) /
                    12 | customCurrency
                    }}</span>
                </td>
                <td class="text-right">
                  Per Annum:
                  <span class="value">{{
                    totalIncomeEmployment +
                    totalIncomeInvestmentPropertys +
                    totalIncomeShares +
                    totalIncomeInterest +
                    totalIncomeOtherIncomes | customCurrency
                    }}</span>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="row clearfix">
            <div *ngIf="employmentAndBenefits && employmentAndBenefits.length > 0">
              <div class="row clearfix add-mt">
                <!-- add additional expense -->
                <button class="pull-right" *ngIf="isLockPCI == false" (click)="openPCIEmploymentBenefitModal()"
                  mat-raised-button color="primary">
                  <mat-icon>add</mat-icon> Add
                </button>

                <h3 class="sr-title add-mt">Employment & Benefits</h3>
              </div>

              <mat-accordion>
                <mat-expansion-panel class="mat-elevation-z0"
                  *ngFor="let employment of employmentAndBenefits; let i = index"
                  [expanded]="employment['opened'] && employment['opened'] == true" (opened)="setStep(0)">
                  <mat-expansion-panel-header>
                    <p class="rm-m">
                      Income {{ i + 1 }} Details
                      <span class="ml" *ngIf="employment['Modified_Fields']">
                        <mat-icon class="fas fa-user-edit modified"></mat-icon>
                      </span>
                    </p>
                    <p class="rm-m">
                      {{ employment['TypeLabel'] }}:
                      <span class="value">
                        <span *ngIf="employment['Info.Employment.Role']">{{ employment['Info.Employment.Role'] }}</span>
                        <span *ngIf="employment['Info.EmploymentBenefitTypeLabel']">{{
                          employment['Info.EmploymentBenefitTypeLabel']
                          }}</span>
                      </span>
                    </p>
                    <p class="rm-m">
                      <span>Amount:
                        <strong class="sr-title">{{
                          calByFrequency(
                            calculateAnnualAmount(employment['Income.Value'], employment['Income.Frequency'])
                          ) | customCurrency
                          }}
                          <span class="peryear">/ {{ displayFrequencyLabel }}</span>
                        </strong></span>
                    </p>
                  </mat-expansion-panel-header>

                  <app-pci-employment-benefit *ngIf="step == 0" [pciEntryObj]="employment"
                    [title]="'Employment And Benefits'" [disabled]="isLockPCI"
                    (getResult)="modifyPCIEmploymentAndBenefits(i, $event)">
                  </app-pci-employment-benefit>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
            <div class="row clearfix add-mt" *ngIf="employmentAndBenefits && employmentAndBenefits.length == 0">
              <div class="pull-left op04">
                <h3 class="sr-title rm-m">Employment & Benefits</h3>
                <p class="small rm-mt">No Employment & Benefits</p>
              </div>
              <button class="pull-right" *ngIf="isLockPCI == false" (click)="openPCIEmploymentBenefitModal()"
                mat-raised-button color="primary">
                <mat-icon>add</mat-icon> Add
              </button>
              <hr class="op04" />
            </div>

            <div *ngIf="investmentPropertys && investmentPropertys.length > 0">
              <div class="row clearfix add-mt">
                <!-- add additional expense -->
                <button class="pull-right" *ngIf="isLockPCI == false" (click)="openPCIInvestmentPropertysModal()"
                  mat-raised-button color="primary">
                  <mat-icon>add</mat-icon> Add
                </button>

                <h3 class="sr-title add-mt">Investment Property</h3>
              </div>

              <mat-accordion>
                <mat-expansion-panel class="mat-elevation-z0"
                  *ngFor="let investment of investmentPropertys; let i = index"
                  [expanded]="investment['opened'] && investment['opened'] == true" (opened)="setStep(1)">
                  <mat-expansion-panel-header>
                    <p class="rm-m">
                      Investment property {{ i + 1 }} Details:
                      <span class="value">{{ investment['Adress.Calculated'] }}</span>
                      <span class="ml" *ngIf="investment['Modified_Fields']">
                        <mat-icon class="fas fa-user-edit modified"></mat-icon>
                      </span>
                    </p>

                    <p class="rm-m">
                      <span>
                        Amount:
                        <strong class="sr-title">{{
                          calByFrequency(
                            calculateAnnualAmount(investment['Income.Value'], investment['Income.Frequency'])
                          ) | customCurrency
                          }}
                          <span class="peryear">/ {{ displayFrequencyLabel }}</span>
                        </strong></span>
                    </p>
                  </mat-expansion-panel-header>

                  <app-pci-investment-property *ngIf="step == 1" [pciEntryObj]="investment"
                    [title]="'Investment Properties'" [disabled]="isLockPCI"
                    (getResult)="modifyInvestmentProperty(i, $event)">
                  </app-pci-investment-property>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
            <div class="row clearfix add-mt" *ngIf="shares && shares.length == 0">
              <div class="pull-left op04">
                <h3 class="sr-title rm-m">Investment Property</h3>
                <p class="small rm-mt">No investment property</p>
              </div>
              <button class="pull-right" *ngIf="isLockPCI == false" (click)="openPCIInvestmentPropertysModal()"
                mat-stroked-button>
                <mat-icon>add</mat-icon> Add
              </button>
              <hr class="op04" />
            </div>

            <div *ngIf="shares && shares.length > 0">
              <div class="row clearfix add-mt">
                <!-- add additional expense -->
                <button class="pull-right" *ngIf="isLockPCI == false" (click)="openPCISharesModal()" mat-raised-button
                  color="primary">
                  <mat-icon>add</mat-icon> Add
                </button>

                <h3 class="sr-title add-mt">Shares</h3>
              </div>

              <mat-accordion>
                <mat-expansion-panel class="mat-elevation-z0" *ngFor="let share of shares; let i = index"
                  [expanded]="share['opened'] && share['opened'] == true" (opened)="setStep(2)">
                  <mat-expansion-panel-header>
                    <p class="rm-m">
                      Share {{ i + 1 }} Details: <span class="value">{{ share['Info.Details'] }}</span>
                      <span class="ml" *ngIf="share['Modified_Fields']">
                        <mat-icon class="fas fa-user-edit modified"></mat-icon>
                      </span>
                    </p>

                    <p class="rm-m">
                      <span>
                        Amount:
                        <strong class="sr-title">{{
                          calByFrequency(calculateAnnualAmount(share['Income.Value'], share['Income.Frequency']))
                            | customCurrency
                          }}
                          <span class="peryear">/ {{ displayFrequencyLabel }}</span>
                        </strong></span>
                    </p>
                  </mat-expansion-panel-header>

                  <app-pci-shares *ngIf="step == 2" [pciEntryObj]="share" [title]="'Shares'" [disabled]="isLockPCI"
                    (getResult)="modifyPCIShare(i, $event)"></app-pci-shares>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
            <div class="row clearfix add-mt" *ngIf="shares && shares.length == 0">
              <div class="pull-left op04">
                <h3 class="sr-title rm-m">Shares - Financed</h3>
                <p class="small rm-mt">No shares</p>
              </div>
              <button class="pull-right" *ngIf="isLockPCI == false" (click)="openPCISharesModal()" mat-raised-button
                color="primary">
                <mat-icon>add</mat-icon> Add
              </button>
              <hr class="op04" />
            </div>

            <div *ngIf="interestAsIncomes && interestAsIncomes.length > 0">
              <div class="row clearfix add-mt">
                <!-- add additional expense -->
                <button class="pull-right" *ngIf="isLockPCI == false" (click)="openPCIInterestAsIncomesModal()"
                  mat-raised-button color="primary">
                  <mat-icon>add</mat-icon> Add
                </button>

                <h3 class="sr-title add-mt">Interest income for funds on deposit</h3>
              </div>

              <mat-accordion>
                <mat-expansion-panel class="mat-elevation-z0" *ngFor="let interest of interestAsIncomes; let i = index"
                  [expanded]="interest['opened'] && interest['opened'] == true" (opened)="setStep(3)">
                  <mat-expansion-panel-header>
                    <p class="rm-m">
                      Interest {{ i + 1 }} Details:
                      <span class="value">{{ interest['Info.AccountType.Label'] }}
                        {{ interest['Info.AccountName'] }}
                      </span>
                      <span class="ml" *ngIf="interest['Modified_Fields']">
                        <mat-icon class="fas fa-user-edit modified"></mat-icon>
                      </span>
                    </p>

                    <p class="rm-m">
                      <span>
                        Amount:
                        <strong class="sr-title">{{
                          calByFrequency(
                            calculateAnnualAmount(interest['Income.Value'], interest['Income.Frequency'])
                          ) | customCurrency
                          }}
                          <span class="peryear">/ {{ displayFrequencyLabel }}</span>
                        </strong></span>
                    </p>
                  </mat-expansion-panel-header>

                  <app-pci-interest-income *ngIf="step == 3" [pciEntryObj]="interest" [title]="'Interest As Incomes'"
                    [disabled]="isLockPCI" (getResult)="modifyPCIInterestAsIncomes(i, $event)">
                  </app-pci-interest-income>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
            <div class="row clearfix add-mt" *ngIf="interestAsIncomes && interestAsIncomes.length == 0">
              <div class="pull-left op04">
                <h3 class="sr-title rm-m">Interest income for funds on deposit</h3>
                <p class="small rm-mt">No interest income for funds on deposit</p>
              </div>
              <button class="pull-right" *ngIf="isLockPCI == false" (click)="openPCIInterestAsIncomesModal()"
                mat-raised-button color="primary">
                <mat-icon>add</mat-icon> Add
              </button>
              <hr class="op04" />
            </div>

            <div *ngIf="personalCircumstances['MonthsInCurrentJob'] && otherIncomes && otherIncomes.length > 0">
              <div class="row clearfix add-mt">
                <!-- add additional expense -->
                <button class="pull-right" *ngIf="isLockPCI == false" (click)="openPCIOtherIncomesModal()"
                  mat-raised-button color="primary">
                  <mat-icon>add</mat-icon> Add
                </button>

                <h3 class="sr-title add-mt">Other Income</h3>
              </div>

              <mat-accordion>
                <mat-expansion-panel class="mat-elevation-z0" *ngFor="let otherIncome of otherIncomes; let i = index"
                  [expanded]="otherIncome['opened'] && otherIncome['opened'] == true" (opened)="setStep(4)">
                  <mat-expansion-panel-header>
                    <p class="rm-m">
                      Other income {{ i + 1 }} Details: <span class="value">{{ otherIncome['Info.Type'] }}</span>
                      <span class="ml" *ngIf="otherIncome['Modified_Fields']">
                        <mat-icon class="fas fa-user-edit modified"></mat-icon>
                      </span>
                    </p>
                    <p class="rm-m">
                      <span>
                        Amount:
                        <strong class="sr-title">{{
                          calByFrequency(
                            calculateAnnualAmount(otherIncome['Income.Value'], otherIncome['Income.Frequency'])
                          ) | customCurrency
                          }}
                          <span class="peryear">/ {{ displayFrequencyLabel }}</span>
                        </strong></span>
                    </p>
                  </mat-expansion-panel-header>

                  <app-pci-other-income *ngIf="step == 4" [pciEntryObj]="otherIncome" [title]="'Other Incomes'"
                    [disabled]="isLockPCI" (getResult)="modifyOtherIncomes(i, $event)"></app-pci-other-income>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
            <div class="row clearfix add-mt" *ngIf="otherIncomes && otherIncomes.length == 0">
              <div class="pull-left op04">
                <h3 class="sr-title rm-m">Other Income</h3>
                <p class="small rm-mt">No other income</p>
              </div>
              <button class="pull-right" *ngIf="isLockPCI == false" (click)="openPCIOtherIncomesModal()"
                mat-raised-button color="primary">
                <mat-icon>add</mat-icon> Add
              </button>
              <hr class="op04" />
            </div>

            <!-- </div> -->

            <div class="row clearfix">
              <h3 class="sr-title add-mt">Employment History</h3>
            </div>

            <table class="econ-table">
              <tbody>
                <tr>
                  <td class="text-left">
                    <p>Number Of Jobs:</p>
                  </td>
                  <td class="text-right">
                    <mat-form-field class="thrd-width text-right" appearance="outline">
                      <mat-label>Number</mat-label>
                      <input [value]="personalCircumstances['NumberOfJobs']" matInput placeholder="Number" />
                    </mat-form-field>
                  </td>
                </tr>
                <tr *ngIf="personalCircumstances['MonthsInCurrentJob']">
                  <td class="text-left">
                    <p>Months In Current Job:</p>
                  </td>
                  <td class="text-right">
                    <mat-form-field class="thrd-width text-right" appearance="outline">
                      <mat-label>Months</mat-label>
                      <input [value]="personalCircumstances['MonthsInCurrentJob']" matInput placeholder="Months" />
                    </mat-form-field>
                  </td>
                </tr>
                <tr>
                  <td class="text-left">
                    <p>Work Hours Per Week:</p>
                  </td>
                  <td class="text-right">
                    <mat-form-field class="thrd-width text-right" appearance="outline">
                      <mat-label>Hours/week</mat-label>
                      <input [value]="personalCircumstances['WorkHoursPerWeek']" matInput placeholder="Hours/week" />
                    </mat-form-field>
                  </td>
                </tr>
                <tr>
                  <td class="text-left">
                    <p>Unemployed Periods:</p>
                    <p class="small">(Count)</p>
                  </td>
                  <td class="text-right">
                    <mat-form-field class="thrd-width text-right" appearance="outline">
                      <mat-label>Number</mat-label>
                      <input [value]="personalCircumstances['UnemploymentPeriods.Total']" matInput
                        placeholder="Number" />
                    </mat-form-field>
                  </td>
                </tr>
                <tr>
                  <td class="text-left">
                    <p>Unemployed Periods:</p>
                    <p class="small">(Average Duration In Weeks)</p>
                  </td>
                  <td class="text-right">
                    <mat-form-field class="thrd-width text-right" appearance="outline">
                      <mat-label>Number</mat-label>
                      <input [value]="personalCircumstances['UnemploymentPeriods.Avg']" matInput placeholder="Number" />
                    </mat-form-field>
                  </td>
                </tr>
              </tbody>
            </table>

            <div class="grey">
              <hr class="rm-mt" />
              <p class="small">
                Captured For: <span class="value">{{ personalCircumstances['MonthsEmploymentHistory'] }} months </span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <hr />
      <div class="details-footer">
        <div class="net-block pull-right">
          <p class="small rm-m">Net position</p>
          <p class="lead rm-m bold" [class.red]="netIncome <= 0" [class.green]="netIncome > 0">
            {{ calByFrequency(netIncome) | customCurrency }} <span class="peryear">/ {{ displayFrequencyLabel }} </span>
          </p>
        </div>
      </div>
    </div>
  </mat-card>

  <mat-card class="mb">
    <div class="colour-stripe"></div>

    <div class="row clearfix">
      <div class="row flex heading-row main">
        <h3 class="sr-title rm-m">
          <mat-icon class="fas fa-hand-holding-usd smr" style="font-size:1rem !important;"></mat-icon>
          Expenses
        </h3>

        <div class="net-block">
          <p class="small rm-m">Total Expenses</p>
          <p class="lead rm-m" [class.red]="totalIncomePos <= totalExpenses">
            {{ calByFrequency(totalExpenses) | customCurrency }}
            <span class="peryear">/ {{ displayFrequencyLabel }} </span>
          </p>
        </div>
      </div>
      <div class="row clearfix">
        <button class="full-width" (click)="expenseTabVisible = !expenseTabVisible" mat-flat-button>
          <ng-container *ngIf="!expenseTabVisible">Show details
            <mat-icon class="fas fa-chevron-down"></mat-icon>
          </ng-container>
          <ng-container *ngIf="expenseTabVisible">Hide details
            <mat-icon class="fas fa-chevron-up"></mat-icon>
          </ng-container>
        </button>
      </div>
      <!-- <hr class="add-mb"> -->
      <div class="column" *ngIf="expenseTabVisible" @simpleFadeAnimation>
        <table class="econ-table mt">
          <tbody>
            <tr>
              <td class="text-left">
                <p>Expenses total:</p>
              </td>
              <td class="text-right">
                Per Weekly:
                <span class="value">{{
                  (totalExpenseHouse +
                    totalExpenseInvestmentPropertys +
                    totalExpenseLivingExpenses +
                    totalExpensePersonalLoans +
                    totalExpenseCreditCards +
                    totalExpenseCars +
                    totalExpenseOtherVehicles +
                    totalExpenseTaxAndDebts +
                    totalExpenseInvestmentAndLoans +
                    totalExpenseShares) /
                  52 | customCurrency
                  }}</span>
              </td>
              <td class="text-right">
                Per Fortnightly:
                <span class="value">{{
                  (totalExpenseHouse +
                    totalExpenseInvestmentPropertys +
                    totalExpenseLivingExpenses +
                    totalExpensePersonalLoans +
                    totalExpenseCreditCards +
                    totalExpenseCars +
                    totalExpenseOtherVehicles +
                    totalExpenseTaxAndDebts +
                    totalExpenseInvestmentAndLoans +
                    totalExpenseShares) /
                  26 | customCurrency
                  }}</span>
              </td>
              <td class="text-right">
                Per Monthly:
                <span class="value">{{
                  (totalExpenseHouse +
                    totalExpenseInvestmentPropertys +
                    totalExpenseLivingExpenses +
                    totalExpensePersonalLoans +
                    totalExpenseCreditCards +
                    totalExpenseCars +
                    totalExpenseOtherVehicles +
                    totalExpenseTaxAndDebts +
                    totalExpenseInvestmentAndLoans +
                    totalExpenseShares) /
                  12 | customCurrency
                  }}</span>
              </td>
              <td class="text-right">
                Per Annum:
                <span class="value">{{
                  totalExpenseHouse +
                  totalExpenseInvestmentPropertys +
                  totalExpenseLivingExpenses +
                  totalExpensePersonalLoans +
                  totalExpenseCreditCards +
                  totalExpenseCars +
                  totalExpenseOtherVehicles +
                  totalExpenseTaxAndDebts +
                  totalExpenseInvestmentAndLoans +
                  totalExpenseShares | customCurrency
                  }}</span>
              </td>
            </tr>
          </tbody>
        </table>

        <div *ngIf="houseOfResidences">
          <div *ngIf="houseOfResidences && houseOfResidences.length > 0">
            <div class="row clearfix add-mt">
              <!-- add additional expense -->
              <!-- <button mat-raised-button color="primary" class="pull-right" (click)="openPCIHouseOfResidencesModal()">
                <mat-icon>add</mat-icon> Add
              </button> -->

              <h3 class="sr-title add-mt">Current House of Residence</h3>
            </div>

            <mat-accordion>
              <mat-expansion-panel class="mat-elevation-z0" *ngFor="let house of houseOfResidences; let i = index"
                [expanded]="house['opened'] && house['opened'] == true" (opened)="setStep(5)">
                <mat-expansion-panel-header>
                  <p class="rm-m">
                    House {{ i + 1 }} Details: <span class="value">{{ house['Adress.Calculated'] }}</span>
                    <span class="ml" *ngIf="house['Modified_Fields']">
                      <mat-icon class="fas fa-user-edit modified"></mat-icon>
                    </span>
                  </p>

                  <p class="rm-m">
                    <span>
                      Amount:
                      <strong class="sr-title">{{
                        calByFrequency(calculateAnnualAmount(house['Expense.Value'], house['Expense.Frequency']))
                          | customCurrency
                        }}
                        <span class="peryear">/ {{ displayFrequencyLabel }}</span>
                      </strong></span>
                  </p>
                </mat-expansion-panel-header>
                <ng-container *ngIf="house['Info.YearsAtAddress'] == '' && house['Info.MonthsAtAddress'] == ''">
                  <app-pci-house-residence *ngIf="step == 5" [pciEntryObj]="house" [title]="'House Of Residences'"
                    [disabled]="isLockPCI" (getResult)="modifyPCIHouseOfResidences(i, $event)">
                  </app-pci-house-residence>
                </ng-container>
              </mat-expansion-panel>
            </mat-accordion>
          </div>

          <div class="row clearfizx add-mt" *ngIf="houseOfResidences && houseOfResidences.length == 0">
            <div class="pull-left op04">
              <h3 class="sr-title rm-m">House of Residence</h3>
              <p class="small rm-mt">No house of residence</p>
            </div>
            <button class="pull-right" *ngIf="isLockPCI == false" (click)="openPCIHouseOfResidencesModal()"
              mat-raised-button color="primary">
              <mat-icon>add</mat-icon> Add
            </button>
            <hr class="op04" />
          </div>
        </div>

        <!-- rental house -->

        <div *ngIf="investmentPropertys && investmentPropertys.length > 0">
          <div class="row clearfix add-mt">
            <!-- add additional expense -->
            <button class="pull-right" *ngIf="isLockPCI == false" (click)="openPCIInvestmentPropertysModal()"
              mat-raised-button color="primary">
              <mat-icon>add</mat-icon> Add
            </button>

            <h3 class="sr-title add-mt">Investment Property</h3>
          </div>

          <mat-accordion>
            <mat-expansion-panel class="mat-elevation-z0" *ngFor="let investment of investmentPropertys; let i = index"
              [expanded]="investment['opened'] && investment['opened'] == true" (opened)="setStep(6)">
              <mat-expansion-panel-header>
                <!-- <p>House {{i + 1}} Details</p> -->
                <p class="rm-m">
                  Property {{ i + 1 }} Details: <span class="value">{{ investment['Adress.Calculated'] }}</span>
                  <span class="ml" *ngIf="investment['Modified_Fields']">
                    <mat-icon class="fas fa-user-edit modified"></mat-icon>
                  </span>
                </p>
                <p class="rm-m">
                  <span>
                    Amount:
                    <strong class="sr-title">{{
                      calByFrequency(
                        calculateAnnualAmount(investment['Expense.Value'], investment['Expense.Frequency'])
                      ) | customCurrency
                      }}
                      <span class="peryear">/ {{ displayFrequencyLabel }}</span>
                    </strong></span>

                  <!-- <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Annual Amount</mat-label>
                    <input matInput placeholder="Annual Amount"
                      [value]="(calculateAnnualAmount(investment['Expense.Value'], investment['Expense.Frequency']))"
                      currencyMask>
                  </mat-form-field> -->
                </p>
              </mat-expansion-panel-header>

              <app-pci-investment-property *ngIf="step == 6" [pciEntryObj]="investment"
                [title]="'Investment Properties'" [disabled]="isLockPCI"
                (getResult)="modifyInvestmentProperty(i, $event)">
              </app-pci-investment-property>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <!-- display if no rental house -->
        <div class="row clearfix add-mt" *ngIf="investmentPropertys && investmentPropertys.length == 0">
          <div class="pull-left op04">
            <h3 class="sr-title rm-m">Investment Property</h3>
            <p class="small rm-mt">No investment property</p>
          </div>
          <button class="pull-right" *ngIf="isLockPCI == false" (click)="openPCIInvestmentPropertysModal()"
            mat-raised-button color="primary">
            <mat-icon>add</mat-icon> Add
          </button>
          <hr class="op04" />
        </div>

        <!-- living expenses  -->
        <div *ngIf="livingExpenses && livingExpenses.length > 0">
          <div class="row clearfix add-mt">
            <h3 class="sr-title rm-m pull-left">Living Expenses</h3>
            <!-- add additional expense -->
            <button class="pull-right" *ngIf="isLockPCI == false" (click)="openPCILivingExpensesModal()"
              mat-raised-button color="primary">
              <mat-icon>add</mat-icon> Add
            </button>
          </div>

          <mat-accordion>
            <mat-expansion-panel class="mat-elevation-z0" *ngFor="let livingExpense of livingExpenses; let i = index"
              [expanded]="livingExpense['opened'] && livingExpense['opened'] == true" (opened)="setStep(7)">
              <mat-expansion-panel-header>
                <p class="rm-m">
                  {{ livingExpense['Info.SubType.Label'] }}
                  <span class="ml" *ngIf="livingExpense['Modified_Fields']">
                    <mat-icon class="fas fa-user-edit modified"></mat-icon>
                  </span>
                </p>

                <p class="rm-m">
                  <span>
                    Amount:
                    <strong class="sr-title">{{
                      calByFrequency(
                        calculateAnnualAmount(livingExpense['Expense.Value'], livingExpense['Expense.Frequency'])
                      ) | customCurrency
                      }}
                      <span class="peryear">/ {{ displayFrequencyLabel }}</span>
                    </strong></span>
                </p>
              </mat-expansion-panel-header>

              <app-pci-living-expense *ngIf="step == 7" [pciEntryObj]="livingExpense" [title]="'Living Expenses'"
                [disabled]="isLockPCI" (getResult)="modifyLivingExpenses(i, $event)"></app-pci-living-expense>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <!-- this will almost always have expenses, but show if none -->
        <div class="row clearfix add-mt" *ngIf="livingExpenses && livingExpenses.length == 0">
          <div class="pull-left op04">
            <h3 class="sr-title rm-m">Living Expenses</h3>
            <p class="small rm-mt">No living expenses</p>
          </div>
          <button class="pull-right" *ngIf="isLockPCI == false" (click)="openPCILivingExpensesModal()" mat-raised-button
            color="primary">
            <mat-icon>add</mat-icon> Add
          </button>
          <hr class="op04" />
        </div>

        <!-- shares -->
        <div *ngIf="shares && shares.length > 0">
          <div class="row clearfix add-mt">
            <!-- add additional expense -->
            <button class="pull-right" *ngIf="isLockPCI == false" (click)="openPCISharesModal()" mat-raised-button
              color="primary">
              <mat-icon>add</mat-icon> Add
            </button>

            <h3 class="sr-title add-mt">Shares</h3>
          </div>

          <mat-accordion>
            <mat-expansion-panel class="mat-elevation-z0" *ngFor="let share of shares; let i = index"
              [expanded]="share['opened'] && share['opened'] == true" (opened)="setStep(8)">
              <mat-expansion-panel-header>
                <p class="rm-m">
                  Shares {{ i + 1 }} Details: <span class="value">{{ share['Info.Details'] }}</span>
                  <span class="ml" *ngIf="share['Modified_Fields']">
                    <mat-icon class="fas fa-user-edit modified"></mat-icon>
                  </span>
                </p>

                <p class="rm-m">
                  <span>
                    Amount:
                    <strong class="sr-title">{{
                      calByFrequency(
                        calculateAnnualAmount(
                          share['Info.RepaymentsTotal.Amount'],
                          share['Info.RepaymentsTotal.Frequency']
                        )
                      ) | customCurrency
                      }}
                      <span class="peryear">/ {{ displayFrequencyLabel }}</span>
                    </strong></span>
                </p>
              </mat-expansion-panel-header>

              <app-pci-shares *ngIf="step == 8" [pciEntryObj]="share" [title]="'Shares'" [disabled]="isLockPCI"
                (getResult)="modifyPCIShare(i, $event)"></app-pci-shares>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <!-- display if no shares -->
        <div class="row clearfix add-mt" *ngIf="creditCards && creditCards.length == 0">
          <div class="pull-left op04">
            <h3 class="sr-title rm-m">Shares - Financed</h3>
            <p class="small rm-mt">No shares</p>
          </div>
          <button class="pull-right" *ngIf="isLockPCI == false" (click)="openPCISharesModal()" mat-raised-button
            color="primary">
            <mat-icon>add</mat-icon> Add
          </button>
          <hr class="op04" />
        </div>

        <!-- investment / property loans -->
        <div *ngIf="investmentAndLoans && investmentAndLoans.length > 0">
          <div class="row clearfix add-mt">
            <!-- add additional expense -->
            <button class="pull-right" *ngIf="isLockPCI == false" (click)="openPCIInvestmentAndLoansModal()"
              mat-raised-button color="primary">
              <mat-icon>add</mat-icon> Add
            </button>

            <h3 class="sr-title add-mt">Investment and Other Property Loans</h3>
          </div>
          <mat-accordion>
            <mat-expansion-panel class="mat-elevation-z0" *ngFor="let investment of investmentAndLoans; let i = index"
              [expanded]="investment['opened'] && investment['opened'] == true" (opened)="setStep(9)">
              <mat-expansion-panel-header>
                <p class="rm-m">
                  Investment {{ i + 1 }} Details: <span class="value">{{ investment['Info.Details'] }}</span>
                  <span class="ml" *ngIf="investment['Modified_Fields']">
                    <mat-icon class="fas fa-user-edit modified"></mat-icon>
                  </span>
                </p>

                <p class="rm-m">
                  <span>
                    Amount:
                    <strong class="sr-title">{{
                      calByFrequency(
                        calculateAnnualAmount(investment['Expense.Value'], investment['Expense.Frequency'])
                      ) | customCurrency
                      }}
                      <span class="peryear">/ {{ displayFrequencyLabel }}</span>
                    </strong></span>
                </p>
              </mat-expansion-panel-header>

              <app-pci-investment-loans *ngIf="step == 9" [pciEntryObj]="investment" [title]="'Investment And Loans'"
                [disabled]="isLockPCI" (getResult)="modifyInvestmentAndLoans(i, $event)">
              </app-pci-investment-loans>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <!-- display if no property/invest loans -->
        <div class="row clearfix add-mt" *ngIf="investmentAndLoans && investmentAndLoans.length == 0">
          <div class="pull-left op04">
            <h3 class="sr-title rm-m">Investment and Other Property Loans</h3>
            <p class="small rm-mt">No other loans</p>
          </div>
          <button class="pull-right" *ngIf="isLockPCI == false" (click)="openPCIInvestmentAndLoansModal()"
            mat-raised-button color="primary">
            <mat-icon>add</mat-icon> Add
          </button>
          <hr class="op04" />
        </div>

        <!-- Personal loans -->
        <div *ngIf="personalLoans && personalLoans.length > 0">
          <div class="row clearfix add-mt">
            <!-- add additional expense -->
            <button class="pull-right" *ngIf="isLockPCI == false" (click)="openPCIPersonalLoansModal()"
              mat-raised-button color="primary">
              <mat-icon>add</mat-icon> Add
            </button>

            <h3 class="sr-title add-mt">Personal Loans</h3>
          </div>

          <mat-accordion>
            <mat-expansion-panel class="mat-elevation-z0" *ngFor="let personalLoan of personalLoans; let i = index"
              [expanded]="personalLoan['opened'] && personalLoan['opened'] == true" (opened)="setStep(10)">
              <mat-expansion-panel-header>
                <p class="rm-m">
                  Loan {{ i + 1 }} Details:
                  <span class="value">{{ personalLoan['Info.Details'] }} {{ personalLoan['Info.Bank.Name'] }} </span>
                  <span class="ml" *ngIf="personalLoan['Modified_Fields']">
                    <mat-icon class="fas fa-user-edit modified"></mat-icon>
                  </span>
                </p>

                <p class="rm-m">
                  <span>
                    Amount:
                    <strong class="sr-title">{{
                      calByFrequency(
                        calculateAnnualAmount(personalLoan['Expense.Value'], personalLoan['Expense.Frequency'])
                      ) | customCurrency
                      }}
                      <span class="peryear">/ {{ displayFrequencyLabel }}</span>
                    </strong></span>
                </p>
              </mat-expansion-panel-header>

              <app-pci-personal-loan *ngIf="step == 10" [pciEntryObj]="personalLoan" [title]="'Personal Loans'"
                [disabled]="isLockPCI" (getResult)="modifyPCIPersonalLoan(i, $event)"></app-pci-personal-loan>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <!-- display if no personal loans -->
        <div class="row clearfix add-mt" *ngIf="creditCards && creditCards.length == 0">
          <div class="pull-left op04">
            <h3 class="sr-title rm-m">Personal Loans</h3>
            <p class="small rm-mt">No personal loans</p>
          </div>
          <button class="pull-right" *ngIf="isLockPCI == false" (click)="openPCIPersonalLoansModal()" mat-raised-button
            color="primary">
            <mat-icon>add</mat-icon> Add
          </button>
          <hr class="op04" />
        </div>

        <!-- credit cards and store cards -->
        <div *ngIf="creditCards && creditCards.length > 0">
          <div class="row clearfix add-mt">
            <!-- add additional expense -->
            <button class="pull-right" *ngIf="isLockPCI == false" (click)="openPCICreditCardModal()" mat-raised-button
              color="primary">
              <mat-icon>add</mat-icon> Add
            </button>

            <h3 class="sr-title add-mt">Credit &amp; Store Cards</h3>
          </div>

          <mat-accordion>
            <mat-expansion-panel class="mat-elevation-z0" *ngFor="let creditCard of creditCards; let i = index"
              [expanded]="creditCard['opened'] && creditCard['opened'] == true" (opened)="setStep(11)">
              <mat-expansion-panel-header>
                <p class="rm-m">
                  Card {{ i + 1 }} Details: <span class="value">{{ creditCard['Info.Details'] }}</span>
                  <span class="ml" *ngIf="creditCard['Modified_Fields']">
                    <mat-icon class="fas fa-user-edit modified"></mat-icon>
                  </span>
                </p>

                <p class="rm-m">
                  <span>
                    Amount:
                    <strong class="sr-title">{{
                      calByFrequency(
                        calculateAnnualAmount(
                          creditCard['Info.DeclaredRepayments.Value'],
                          creditCard['Info.DeclaredRepayments.Frequency']
                        )
                      ) | customCurrency
                      }}
                      <span class="peryear">/ {{ displayFrequencyLabel }}</span>
                    </strong></span>
                </p>
              </mat-expansion-panel-header>

              <app-pci-credit-card *ngIf="step == 11" [pciEntryObj]="creditCard" [title]="'Credit Cards'"
                [disabled]="isLockPCI" (getResult)="modifyPCICreditCard(i, $event)"></app-pci-credit-card>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <!-- no credit card -->
        <div class="row clearfix add-mt" *ngIf="creditCards && creditCards.length == 0">
          <div class="pull-left op04">
            <h3 class="sr-title rm-m">Credit / Store Cards</h3>
            <p class="small rm-mt">No credit cards</p>
          </div>
          <button class="pull-right" *ngIf="isLockPCI == false" (click)="openPCICreditCardModal()" mat-raised-button
            color="primary">
            <mat-icon>add</mat-icon> Add
          </button>
          <hr class="op04" />
        </div>

        <!-- car loans -->
        <div *ngIf="cars && cars.length > 0">
          <div class="row clearfix add-mt">
            <!-- add additional expense -->
            <button class="pull-right" *ngIf="isLockPCI == false" (click)="openPCICarModal()" mat-raised-button
              color="primary">
              <mat-icon>add</mat-icon> Add
            </button>

            <h3 class="sr-title add-mt">Car Loans</h3>
          </div>

          <mat-accordion>
            <mat-expansion-panel class="mat-elevation-z0" *ngFor="let car of cars; let i = index"
              [expanded]="car['opened'] && car['opened'] == true" (opened)="setStep(12)">
              <mat-expansion-panel-header>
                <p class="rm-m">
                  Car {{ i + 1 }} Details:
                  <span class="value">{{ car['Info.Make'] }}</span>
                  <span class="ml" *ngIf="car['Modified_Fields']">
                    <mat-icon class="fas fa-user-edit modified"></mat-icon>
                  </span>
                </p>
                <p class="rm-m">
                  <span>
                    Amount:
                    <strong class="sr-title">{{
                      calByFrequency(calculateAnnualAmount(car['Expense.Value'], car['Expense.Frequency']))
                        | customCurrency
                      }}
                      <span class="peryear">/ {{ displayFrequencyLabel }}</span>
                    </strong></span>
                </p>
              </mat-expansion-panel-header>

              <app-pci-car *ngIf="step == 12" [pciEntryObj]="car" [title]="'Cars'" [disabled]="isLockPCI"
                (getResult)="modifyPCICar(i, $event)"></app-pci-car>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <!-- display if no car loan -->
        <div class="row clearfix add-mt" *ngIf="cars && cars.length == 0">
          <div class="pull-left op04">
            <h3 class="sr-title rm-m">Car Loans</h3>
            <p class="small rm-mt">No car loans</p>
          </div>
          <button class="pull-right" *ngIf="isLockPCI == false" (click)="openPCICarModal()" mat-raised-button
            color="primary">
            <mat-icon>add</mat-icon> Add
          </button>
          <hr class="op04" />
        </div>

        <!-- car loans -->
        <div *ngIf="otherVehicles && otherVehicles.length > 0">
          <div class="row clearfix add-mt">
            <!-- add additional expense -->
            <button class="pull-right" *ngIf="isLockPCI == false" (click)="openPCIOtherVehiclesModal()"
              mat-raised-button color="primary">
              <mat-icon>add</mat-icon> Add
            </button>

            <h3 class="sr-title add-mt">Bike / Caravan / Boat Loans</h3>
          </div>

          <mat-accordion>
            <mat-expansion-panel class="mat-elevation-z0" *ngFor="let otherVehicle of otherVehicles; let i = index"
              [expanded]="otherVehicle['opened'] && otherVehicle['opened'] == true" (opened)="setStep(13)">
              <mat-expansion-panel-header>
                <p class="rm-m">
                  Loan {{ i + 1 }} Details: <span class="value">{{ otherVehicle['Info.Make'] }}</span>
                  <span class="ml" *ngIf="otherVehicle['Modified_Fields']">
                    <mat-icon class="fas fa-user-edit modified"></mat-icon>
                  </span>
                </p>
                <p class="rm-m">
                  <span>
                    Amount:
                    <strong class="sr-title">{{
                      calByFrequency(
                        calculateAnnualAmount(otherVehicle['Expense.Value'], otherVehicle['Expense.Frequency'])
                      ) | customCurrency
                      }}
                      <span class="peryear">/ {{ displayFrequencyLabel }}</span>
                    </strong></span>
                </p>
              </mat-expansion-panel-header>

              <app-pci-other-vehicle *ngIf="step == 13" [pciEntryObj]="otherVehicle" [title]="'Other Vehicles'"
                [disabled]="isLockPCI" (getResult)="modifyPCIOtherVehicle(i, $event)"></app-pci-other-vehicle>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <!-- display if no car loan -->
        <div class="row clearfix add-mt" *ngIf="otherVehicles && otherVehicles.length == 0">
          <div class="pull-left op04">
            <h3 class="sr-title rm-m">Bike / Caravan / Boat Loans</h3>
            <p class="small rm-mt">No bike / caravan / boat loans</p>
          </div>
          <button class="pull-right" *ngIf="isLockPCI == false" (click)="openPCIOtherVehiclesModal()" mat-raised-button
            color="primary">
            <mat-icon>add</mat-icon> Add
          </button>
          <hr class="op04" />
        </div>

        <!-- car loans -->
        <div *ngIf="taxAndDebts && taxAndDebts.length > 0">
          <div class="row clearfix add-mt">
            <!-- add additional expense -->
            <button class="pull-right" *ngIf="isLockPCI == false" (click)="openPCITaxAndDebtsModal()" mat-raised-button
              color="primary">
              <mat-icon>add</mat-icon> Add
            </button>

            <h3 class="sr-title add-mt">Tax / Government Debts</h3>
          </div>
          <mat-accordion>
            <mat-expansion-panel class="mat-elevation-z0" *ngFor="let taxAndDebt of taxAndDebts; let i = index"
              [expanded]="taxAndDebt['opened'] && taxAndDebt['opened'] == true" (opened)="setStep(14)">
              <mat-expansion-panel-header>
                <p class="rm-m">
                  Debt {{ i + 1 }} Details: <span class="value">{{ taxAndDebt['Info.SubType.Label'] }}</span>
                  <span class="ml" *ngIf="taxAndDebt['Modified_Fields']">
                    <mat-icon class="fas fa-user-edit modified"></mat-icon>
                  </span>
                </p>

                <p class="rm-m">
                  <span>
                    Amount:
                    <strong class="sr-title">{{
                      calByFrequency(
                        calculateAnnualAmount(taxAndDebt['Expense.Value'], taxAndDebt['Expense.Frequency'])
                      ) | customCurrency
                      }}
                      <span class="peryear">/ {{ displayFrequencyLabel }}</span>
                    </strong></span>
                </p>
              </mat-expansion-panel-header>

              <app-pci-tax-debt *ngIf="step == 14" [pciEntryObj]="taxAndDebt" [title]="'Tax And Debts'"
                [disabled]="isLockPCI" (getResult)="modifyPCITaxAndDebts(i, $event)"></app-pci-tax-debt>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <!-- display if no car loan -->
        <div class="row clearfix add-mt" *ngIf="otherVehicles && otherVehicles.length == 0">
          <div class="pull-left op04">
            <h3 class="sr-title rm-m">Tax / Government Debts</h3>
            <p class="small rm-mt">No tax or government debts</p>
          </div>
          <button class="pull-right" *ngIf="isLockPCI == false" (click)="openPCITaxAndDebtsModal()" mat-raised-button
            color="primary">
            <mat-icon>add</mat-icon> Add
          </button>
          <!-- <hr class="op04"> -->
        </div>

        <!-- </div> -->
      </div>

      <hr />
      <div class="details-footer">
        <div class="net-block pull-right">
          <p class="small rm-m">Net position</p>
          <p class="lead rm-m bold" [class.red]="netIncome <= 0" [class.green]="netIncome > 0">
            {{ calByFrequency(netIncome) | customCurrency }} <span class="peryear">/ {{ displayFrequencyLabel }} </span>
          </p>
        </div>
      </div>
    </div>
  </mat-card>

  <mat-card class="mb">
    <div class="colour-stripe"></div>

    <div class="row clearfix">
      <!-- <div class="column"> -->

      <div class="row flex heading-row main">
        <h3 class="sr-title rm-m">
          <mat-icon class="fas fa-file-invoice-dollar smr" style="font-size:1rem !important;"></mat-icon>
          Assets
        </h3>

        <div class="net-block">
          <p class="small rm-m">Total Assets</p>
          <p class="lead rm-m" [class.red]="totalAssets <= 0">{{ totalAssets | customCurrency }}</p>
        </div>
      </div>

      <div class="row clearfix">
        <button class="full-width" (click)="assetTabVisible = !assetTabVisible" mat-flat-button>
          <ng-container *ngIf="!assetTabVisible">Show details
            <mat-icon class="fas fa-chevron-down"></mat-icon>
          </ng-container>
          <ng-container *ngIf="assetTabVisible">Hide details
            <mat-icon class="fas fa-chevron-up"></mat-icon>
          </ng-container>
        </button>
      </div>

      <!-- <hr class="add-mb"> -->
      <div class="column" *ngIf="assetTabVisible" @simpleFadeAnimation>
        <!-- <h1></h1> -->

        <table class="econ-table mt">
          <tbody>
            <tr>
              <td class="text-left">
                <p>Current Assets:</p>
              </td>

              <td class="text-right">
                <mat-form-field class="thrd-width" appearance="outline">
                  <mat-label>Total Assets</mat-label>
                  <input [(ngModel)]="totalAssets" matInput placeholder="Total Assets" readonly currencyMask />
                </mat-form-field>
              </td>
            </tr>
          </tbody>
        </table>

        <div *ngIf="houseOfResidences && houseOfResidences.length > 0">
          <div class="row clearfix add-mt">
            <!-- add additional expense -->
            <!-- <button mat-raised-button color="primary" class="pull-right" (click)="openPCIHouseOfResidencesModal()">
              <mat-icon>add</mat-icon> Add
            </button> -->

            <h3 class="sr-title add-mt">Current House of Residence</h3>
          </div>

          <mat-accordion>
            <mat-expansion-panel class="mat-elevation-z0" *ngFor="let house of houseOfResidences; let i = index"
              [expanded]="house['opened'] && house['opened'] == true" (opened)="setStep(15)">
              <mat-expansion-panel-header>
                <p class="rm-m">
                  House {{ i + 1 }}: <span class="value">{{ house['Adress.Calculated'] }}</span>
                  <span class="ml" *ngIf="house['Modified_Fields']">
                    <mat-icon class="fas fa-user-edit modified"></mat-icon>
                  </span>
                </p>
                <p class="rm-m">
                  <span>
                    Asset: <strong class="sr-title">{{ house['Asset.Value'] | customCurrency }} </strong></span>

                  <!-- <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Asset</mat-label>
                    <input matInput placeholder="Asset" [(ngModel)]="house['Asset.Value']" currencyMask>
                  </mat-form-field> -->
                </p>
              </mat-expansion-panel-header>

              <app-pci-house-residence *ngIf="step == 15" [pciEntryObj]="house" [title]="'House Of Residences'"
                [disabled]="isLockPCI" (getResult)="modifyPCIHouseOfResidences(i, $event)"></app-pci-house-residence>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <!-- display if no car loan -->
        <div class="row clearfix add-mt" *ngIf="houseOfResidences && houseOfResidences.length == 0">
          <div class="pull-left op04">
            <h3 class="sr-title rm-m">House of Residence</h3>
            <p class="small rm-mt">No house of residence</p>
          </div>
          <button class="pull-right" *ngIf="isLockPCI == false" (click)="openPCIHouseOfResidencesModal()"
            mat-raised-button color="primary">
            <mat-icon>add</mat-icon> Add
          </button>
          <hr class="op04" />
        </div>

        <div *ngIf="investmentPropertys && investmentPropertys.length > 0">
          <div class="row clearfix add-mt">
            <!-- add additional expense -->
            <button class="pull-right" *ngIf="isLockPCI == false" (click)="openPCIInvestmentPropertysModal()"
              mat-raised-button color="primary">
              <mat-icon>add</mat-icon> Add
            </button>

            <h3 class="sr-title add-mt">Investment Property</h3>
          </div>

          <mat-accordion>
            <mat-expansion-panel class="mat-elevation-z0" *ngFor="let investment of investmentPropertys; let i = index"
              [expanded]="investment['opened'] && investment['opened'] == true" (opened)="setStep(16)">
              <mat-expansion-panel-header>
                <p class="rm-m">
                  Property {{ i + 1 }}: <span class="value">{{ investment['Adress.Calculated'] }}</span>
                  <span class="ml" *ngIf="investment['Modified_Fields']">
                    <mat-icon class="fas fa-user-edit modified"></mat-icon>
                  </span>
                </p>
                <p class="rm-m">
                  <span>
                    Asset: <strong class="sr-title">{{ investment['Asset.Value'] | customCurrency }}</strong></span>

                  <!-- <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Asset</mat-label>
                    <input matInput placeholder="Asset" [(ngModel)]="investment['Asset.Value']" currencyMask>
                  </mat-form-field> -->
                </p>
              </mat-expansion-panel-header>

              <app-pci-investment-property *ngIf="step == 16" [pciEntryObj]="investment"
                [title]="'Investment Properties'" [disabled]="isLockPCI"
                (getResult)="modifyInvestmentProperty(i, $event)">
              </app-pci-investment-property>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <!-- display if no car loan -->
        <div class="row clearfix add-mt" *ngIf="investmentPropertys && investmentPropertys.length == 0">
          <div class="pull-left op04">
            <h3 class="sr-title rm-m">Investment Property</h3>
            <p class="small rm-mt">No investment property</p>
          </div>
          <button class="pull-right" *ngIf="isLockPCI == false" (click)="openPCIInvestmentPropertysModal()"
            mat-raised-button color="primary">
            <mat-icon>add</mat-icon> Add
          </button>
          <hr class="op04" />
        </div>

        <div *ngIf="shares && shares.length > 0">
          <div class="row clearfix add-mt">
            <!-- add additional expense -->
            <button class="pull-right" *ngIf="isLockPCI == false" (click)="openPCISharesModal()" mat-raised-button
              color="primary">
              <mat-icon>add</mat-icon> Add
            </button>

            <h3 class="sr-title add-mt">Shares</h3>
          </div>

          <mat-accordion>
            <mat-expansion-panel class="mat-elevation-z0" *ngFor="let share of shares; let i = index"
              [expanded]="share['opened'] && share['opened'] == true" (opened)="setStep(17)">
              <mat-expansion-panel-header>
                <p class="rm-m">
                  Shares {{ i + 1 }}: <span class="value">{{ share['Info.Details'] }}</span>
                  <span class="ml" *ngIf="share['Modified_Fields']">
                    <mat-icon class="fas fa-user-edit modified"></mat-icon>
                  </span>
                </p>
                <p class="rm-m">
                  <span>
                    Asset: <strong class="sr-title">{{ share['Asset.Value'] | customCurrency }}</strong></span>

                  <!-- <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Asset</mat-label>
                    <input matInput placeholder="Asset" [(ngModel)]="share['Asset.Value']" currencyMask>
                  </mat-form-field> -->
                </p>
              </mat-expansion-panel-header>

              <app-pci-shares *ngIf="step == 17" [pciEntryObj]="share" [title]="'Shares'" [disabled]="isLockPCI"
                (getResult)="modifyPCIShare(i, $event)"></app-pci-shares>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <!-- display if no car loan -->
        <div class="row clearfix add-mt" *ngIf="shares && shares.length == 0">
          <div class="pull-left op04">
            <h3 class="sr-title rm-m">Shares</h3>
            <p class="small rm-mt">No shares</p>
          </div>
          <button class="pull-right" *ngIf="isLockPCI == false" (click)="openPCISharesModal()" mat-raised-button
            color="primary">
            <mat-icon>add</mat-icon> Add
          </button>
          <hr class="op04" />
        </div>

        <div *ngIf="interestAsIncomes && interestAsIncomes.length > 0">
          <div class="row clearfix add-mt">
            <!-- add additional expense -->
            <button class="pull-right" *ngIf="isLockPCI == false" (click)="openPCIInterestAsIncomesModal()"
              mat-raised-button color="primary">
              <mat-icon>add</mat-icon> Add
            </button>

            <h3 class="sr-title add-mt">Interest income for funds on deposit</h3>
          </div>

          <mat-accordion>
            <mat-expansion-panel class="mat-elevation-z0" *ngFor="let interest of interestAsIncomes; let i = index"
              [expanded]="interest['opened'] && interest['opened'] == true" (opened)="setStep(18)">
              <mat-expansion-panel-header>
                <p class="rm-m">
                  Account {{ i + 1 }}:
                  <span class="value">{{ interest['Info.AccountType.Label'] }} {{ interest['Info.AccountName'] }}
                  </span>
                  <span class="ml" *ngIf="interest['Modified_Fields']">
                    <mat-icon class="fas fa-user-edit modified"></mat-icon>
                  </span>
                </p>
                <p class="rm-m">
                  <span>
                    Asset: <strong class="sr-title">{{ interest['Asset.Value'] | customCurrency }}</strong></span>

                  <!-- <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Asset</mat-label>
                    <input matInput placeholder="Asset" [(ngModel)]="interest['Asset.Value']" currencyMask>
                  </mat-form-field> -->
                </p>
              </mat-expansion-panel-header>

              <app-pci-interest-income *ngIf="step == 18" [pciEntryObj]="interest" [title]="'Interest As Incomes'"
                [disabled]="isLockPCI" (getResult)="modifyPCIInterestAsIncomes(i, $event)"></app-pci-interest-income>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <!-- display if no car loan -->
        <div class="row clearfix add-mt" *ngIf="interestAsIncomes && interestAsIncomes.length == 0">
          <div class="pull-left op04">
            <h3 class="sr-title rm-m">Interest income for funds on deposit</h3>
            <p class="small rm-mt">No interest income for funds on deposit</p>
          </div>
          <button class="pull-right" *ngIf="isLockPCI == false" (click)="openPCIInterestAsIncomesModal()"
            mat-raised-button color="primary">
            <mat-icon>add</mat-icon> Add
          </button>
          <hr class="op04" />
        </div>

        <div *ngIf="furnitureHouseholdGoods && furnitureHouseholdGoods.length > 0">
          <div class="row clearfix add-mt">
            <!-- add additional expense -->
            <button class="pull-right" *ngIf="isLockPCI == false" (click)="openPCIFurnitureHouseholdGoodsModal()"
              mat-raised-button color="primary">
              <mat-icon>add</mat-icon> Add
            </button>

            <h3 class="sr-title add-mt">Furniture and Household Goods</h3>
          </div>

          <mat-accordion>
            <mat-expansion-panel class="mat-elevation-z0"
              *ngFor="let furniture of furnitureHouseholdGoods; let i = index"
              [expanded]="furniture['opened'] && furniture['opened'] == true" (opened)="setStep(19)">
              <mat-expansion-panel-header>
                <p class="rm-m">
                  Details: <span class="value">{{ furniture['Info.Description'] }}</span>
                  <span class="ml" *ngIf="furniture['Modified_Fields']">
                    <mat-icon class="fas fa-user-edit modified"></mat-icon>
                  </span>
                </p>
                <p class="rm-m">
                  <span>Asset: <strong class="sr-title">{{ furniture['Asset.Value'] | customCurrency }}</strong></span>

                  <!-- <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Asset</mat-label>
                    <input matInput placeholder="Asset" [(ngModel)]="furniture['Asset.Value']" currencyMask>
                  </mat-form-field> -->
                </p>
              </mat-expansion-panel-header>

              <app-pci-furniture-household *ngIf="step == 19" [pciEntryObj]="furniture"
                [title]="'Furniture Household Goods'" [disabled]="isLockPCI"
                (getResult)="modifyPCIFurnitureHouseholdGoods(i, $event)">
              </app-pci-furniture-household>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <div class="row clearfix add-mt" *ngIf="furnitureHouseholdGoods && furnitureHouseholdGoods.length == 0">
          <div class="pull-left op04">
            <h3 class="sr-title rm-m">Furniture and Household Goods</h3>
            <p class="small rm-mt">No furniture or household goods</p>
          </div>
          <button class="pull-right" *ngIf="isLockPCI == false" (click)="openPCIFurnitureHouseholdGoodsModal()"
            mat-raised-button color="primary">
            <mat-icon>add</mat-icon> Add
          </button>
          <hr class="op04" />
        </div>

        <div *ngIf="cars && cars.length > 0">
          <div class="row clearfix add-mt">
            <!-- add additional expense -->
            <button class="pull-right" *ngIf="isLockPCI == false" (click)="openPCICarModal()" mat-raised-button
              color="primary">
              <mat-icon>add</mat-icon> Add
            </button>

            <h3 class="sr-title add-mt">Car Loans</h3>
          </div>

          <mat-accordion>
            <mat-expansion-panel class="mat-elevation-z0" *ngFor="let car of cars; let i = index"
              [expanded]="car['opened'] && car['opened'] == true" (opened)="setStep(20)">
              <mat-expansion-panel-header>
                <p class="rm-m">
                  Car {{ i + 1 }}: <span class="value">{{ car['Info.Make'] }}</span>
                  <span class="ml" *ngIf="car['Modified_Fields']">
                    <mat-icon class="fas fa-user-edit modified"></mat-icon>
                  </span>
                </p>
                <p class="rm-m">
                  <span>Asset: <strong class="sr-title">{{ car['Asset.Value'] | customCurrency }}</strong></span>

                  <!-- <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Asset</mat-label>
                    <input matInput placeholder="Asset" [(ngModel)]="car['Asset.Value']" currencyMask>
                  </mat-form-field> -->
                </p>
              </mat-expansion-panel-header>

              <app-pci-car *ngIf="step == 20" [pciEntryObj]="car" [title]="'Cars'" [disabled]="isLockPCI"
                (getResult)="modifyPCICar(i, $event)"></app-pci-car>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <!-- display if no car loan -->
        <div class="row clearfix add-mt" *ngIf="cars && cars.length == 0">
          <div class="pull-left op04">
            <h3 class="sr-title rm-m">Cars</h3>
            <p class="small rm-mt">No cars</p>
          </div>
          <button class="pull-right" *ngIf="isLockPCI == false" (click)="openPCICarModal()" mat-raised-button
            color="primary">
            <mat-icon>add</mat-icon> Add
          </button>
          <hr class="op04" />
        </div>

        <div *ngIf="otherVehicles && otherVehicles.length > 0">
          <div class="row clearfix add-mt">
            <!-- add additional expense -->
            <button class="pull-right" *ngIf="isLockPCI == false" (click)="openPCIOtherVehiclesModal()"
              mat-raised-button color="primary">
              <mat-icon>add</mat-icon> Add
            </button>

            <h3 class="sr-title add-mt">Bike / Caravan / Boat Loans</h3>
          </div>

          <mat-accordion>
            <mat-expansion-panel class="mat-elevation-z0" *ngFor="let otherVehicle of otherVehicles; let i = index"
              [expanded]="otherVehicle['opened'] && otherVehicle['opened'] == true" (opened)="setStep(21)">
              <mat-expansion-panel-header>
                <p class="rm-m">
                  Asset {{ i + 1 }} Details: <span class="value">{{ otherVehicle['Info.Make'] }}</span>
                  <span class="ml" *ngIf="otherVehicle['Modified_Fields']">
                    <mat-icon class="fas fa-user-edit modified"></mat-icon>
                  </span>
                </p>
                <p class="rm-m">
                  <span>
                    Asset: <strong class="sr-title">{{ otherVehicle['Asset.Value'] | customCurrency }}</strong></span>

                  <!-- <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Asset</mat-label>
                    <input matInput placeholder="Asset" [value]="(calculateAnnualAmount(otherVehicle['Asset.Value']))"
                      currencyMask>
                  </mat-form-field> -->
                </p>
              </mat-expansion-panel-header>

              <app-pci-other-vehicle *ngIf="step == 21" [pciEntryObj]="otherVehicle" [title]="'Other Vehicles'"
                [disabled]="isLockPCI" (getResult)="modifyPCIOtherVehicle(i, $event)"></app-pci-other-vehicle>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <!-- display if no car loan -->
        <div class="row clearfix add-mt" *ngIf="otherVehicles && otherVehicles.length == 0">
          <div class="pull-left op04">
            <h3 class="sr-title rm-m">Bike / Caravan / Boat Loans</h3>
            <p class="small rm-mt">No bike / caravan / boat loans</p>
          </div>
          <button class="pull-right" *ngIf="isLockPCI == false" (click)="openPCIOtherVehiclesModal()" mat-raised-button
            color="primary">
            <mat-icon>add</mat-icon> Add
          </button>
          <hr class="op04" />
        </div>

        <div *ngIf="otherAssets && otherAssets.length > 0">
          <div class="row clearfix add-mt">
            <!-- add additional expense -->
            <button class="pull-right" *ngIf="isLockPCI == false" (click)="openPCIOtherAssetsModal()" mat-raised-button
              color="primary">
              <mat-icon>add</mat-icon> Add
            </button>

            <h3 class="sr-title add-mt">Other Assets</h3>
          </div>
          <mat-accordion>
            <mat-expansion-panel class="mat-elevation-z0" *ngFor="let otherAsset of otherAssets; let i = index"
              [expanded]="otherAsset['opened'] && otherAsset['opened'] == true" (opened)="setStep(22)">
              <mat-expansion-panel-header>
                <p class="rm-m">
                  Asset {{ i + 1 }} Details: <span class="value">{{ otherAsset['Info.Details'] }}</span>
                  <span class="ml" *ngIf="otherAsset['Modified_Fields']">
                    <mat-icon class="fas fa-user-edit modified"></mat-icon>
                  </span>
                </p>
                <p class="rm-m">
                  <span>Asset: <strong class="sr-title">{{ otherAsset['Asset.Value'] | customCurrency }}
                    </strong></span>

                  <!-- <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Asset</mat-label>
                    <input matInput placeholder="Asset" [(ngModel)]="otherAsset['Asset.Value']" currencyMask>
                  </mat-form-field> -->
                </p>
              </mat-expansion-panel-header>

              <app-pci-other-assets *ngIf="step == 22" [pciEntryObj]="otherAsset" [title]="'Other Assets'"
                [disabled]="isLockPCI" (getResult)="(modifyOtherAssets)"></app-pci-other-assets>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <!-- display if no car loan -->
        <div class="row clearfix add-mt" *ngIf="otherAssets && otherAssets.length == 0">
          <div class="pull-left op04">
            <h3 class="sr-title rm-m">Other Assets</h3>
            <p class="small rm-mt">No other assets</p>
          </div>
          <button class="pull-right" *ngIf="isLockPCI == false" (click)="openPCIOtherAssetsModal()" mat-raised-button
            color="primary">
            <mat-icon>add</mat-icon> Add
          </button>
          <!-- <hr class="op04"> -->
        </div>
      </div>

      <hr />
      <div class="details-footer">
        <div class="net-block pull-right">
          <p class="small rm-m">Net Asset Position</p>
          <p class="lead rm-m bold" [class.red]="totalAssetsPos <= 0" [class.green]="totalAssetsPos > 0">
            {{ totalAssetsPos | customCurrency }}
          </p>
        </div>
      </div>
    </div>
  </mat-card>

  <mat-card class="mb">
    <div class="colour-stripe"></div>

    <div class="row clearfix">
      <!-- <div class="column"> -->
      <!-- <h1 class="primary-color rm-mt">

      </h1> -->

      <div class="row flex main heading-row">
        <h3 class="sr-title rm-m">
          <mat-icon class="fas fa-comment-dollar smr" style="font-size:1rem !important;"></mat-icon>
          Liabilities
        </h3>

        <div class="net-block">
          <p class="small rm-m">Total Liabilities</p>
          <p class="lead rm-m" [class.red]="totalAssets <= totalLiabilities">{{ totalLiabilities | customCurrency }}</p>
        </div>
      </div>
      <!-- <hr class="add-mb"> -->
      <div class="row clearfix">
        <button class="full-width" (click)="liabilityTabVisible = !liabilityTabVisible" mat-flat-button>
          <ng-container *ngIf="!liabilityTabVisible">Show details
            <mat-icon class="fas fa-chevron-down"></mat-icon>
          </ng-container>
          <ng-container *ngIf="liabilityTabVisible">Hide details
            <mat-icon class="fas fa-chevron-up"></mat-icon>
          </ng-container>
        </button>
      </div>

      <div class="column" *ngIf="liabilityTabVisible" @simpleFadeAnimation>
        <table class="econ-table">
          <tbody>
            <tr>
              <td class="text-left">
                <p class="rm-m">Current Liabilities:</p>
              </td>

              <td class="text-right">
                <!-- <span class="value">
                  {{(totalLiabilityHouse + totalLiabilityInvestmentPropertys
                  + totalLiabilityPersonalLoans + totalLiabilityCreditCards + totalLiabilityCars + totalLiabilityOtherVehicles
                  + totalLiabilityTaxAndDebts + totalLiabilityInvestmentAndLoans + totalLiabilityShares) | customCurrency}}</span>
 -->

                <mat-form-field class="thrd-width" appearance="outline">
                  <mat-label>Annual Amount</mat-label>
                  <input [(ngModel)]="totalLiabilities" matInput placeholder="Annual Amount" readonly currencyMask />
                </mat-form-field>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="row clearfix">
          <div *ngIf="houseOfResidences && houseOfResidences.length > 0">
            <div class="row clearfix add-mt">
              <!-- add additional expense -->
              <!-- <button mat-raised-button color="primary" class="pull-right" (click)="openPCIHouseOfResidencesModal()">
                <mat-icon>add</mat-icon> Add
              </button> -->

              <h3 class="sr-title add-mt">Current House of Residence</h3>
            </div>
            <mat-accordion>
              <mat-expansion-panel class="mat-elevation-z0" *ngFor="let house of houseOfResidences; let i = index"
                [expanded]="house['opened'] && house['opened'] == true" (opened)="setStep(23)">
                <mat-expansion-panel-header>
                  <p class="rm-m">
                    House
                    <!-- {{i + 1}} -->
                    Details: <span class="value">{{ house['Adress.Calculated'] }}</span>
                    <span class="ml" *ngIf="house['Modified_Fields']">
                      <mat-icon class="fas fa-user-edit modified"></mat-icon>
                    </span>
                  </p>

                  <p class="rm-m">
                    Liability:

                    <span class="value sr-title"
                      *ngIf="house['Liability.Value']">{{ house['Liability.Value'] | customCurrency }}
                    </span>

                    <span class="value sr-title" *ngIf="!house['Liability.Value']">$0 </span>

                    <!-- <mat-form-field class="full-width" appearance="outline">
                        <mat-label>Liability</mat-label>
                        <input matInput placeholder="Liability" [(ngModel)]="house['Liability.Value']" currencyMask>
                      </mat-form-field> -->
                  </p>
                </mat-expansion-panel-header>
                <ng-container *ngIf="house['Info.YearsAtAddress'] == '' && house['Info.MonthsAtAddress'] == ''">
                  <app-pci-house-residence *ngIf="step == 23" [pciEntryObj]="house" [title]="'House Of Residences'"
                    [disabled]="isLockPCI" (getResult)="modifyPCIHouseOfResidences(i, $event)">
                  </app-pci-house-residence>
                </ng-container>
              </mat-expansion-panel>
            </mat-accordion>
          </div>

          <div class="row clearfizx add-mt" *ngIf="houseOfResidences && houseOfResidences.length == 0">
            <div class="pull-left op04">
              <h3 class="sr-title rm-m">House of Residence</h3>
              <p class="small rm-mt">No house of residence</p>
            </div>
            <button class="pull-right" *ngIf="isLockPCI == false" (click)="openPCIHouseOfResidencesModal()"
              mat-raised-button color="primary">
              <mat-icon>add</mat-icon> Add
            </button>
            <hr class="op04" />
          </div>

          <!-- rental house -->

          <div *ngIf="investmentPropertys && investmentPropertys.length > 0">
            <div class="row clearfix add-mt">
              <!-- add additional expense -->
              <button class="pull-right" *ngIf="isLockPCI == false" (click)="openPCIInvestmentPropertysModal()"
                mat-raised-button color="primary">
                <mat-icon>add</mat-icon> Add
              </button>

              <h3 class="sr-title add-mt">Investment Property</h3>
            </div>

            <mat-accordion>
              <mat-expansion-panel class="mat-elevation-z0"
                *ngFor="let investment of investmentPropertys; let i = index"
                [expanded]="investment['opened'] && investment['opened'] == true" (opened)="setStep(24)">
                <mat-expansion-panel-header>
                  <!-- <p></p> -->
                  <p class="rm-m">
                    Property {{ i + 1 }} Details: <span class="value">{{ investment['Adress.Calculated'] }}</span>
                    <span class="ml" *ngIf="investment['Modified_Fields']">
                      <mat-icon class="fas fa-user-edit modified"></mat-icon>
                    </span>
                  </p>
                  <p class="rm-m">
                    Liability:
                    <span class="value sr-title" *ngIf="investment['Liability.Value']">{{
                      investment['Liability.Value'] | customCurrency
                      }}</span>
                    <span class="value sr-title" *ngIf="!investment['Liability.Value']">$0</span>
                    <!-- <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Liability</mat-label>
                      <input matInput placeholder="Liability" [(ngModel)]="investment['Liability.Value']" currencyMask>
                    </mat-form-field> -->
                  </p>
                </mat-expansion-panel-header>

                <app-pci-investment-property *ngIf="step == 24" [pciEntryObj]="investment"
                  [title]="'Investment Properties'" [disabled]="isLockPCI"
                  (getResult)="modifyInvestmentProperty(i, $event)">
                </app-pci-investment-property>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
          <!-- display if no rental house -->
          <div class="row clearfizx add-mt" *ngIf="investmentPropertys && investmentPropertys.length == 0">
            <div class="pull-left op04">
              <h3 class="sr-title rm-m">Investment Property</h3>
              <p class="small rm-mt">No investment property</p>
            </div>
            <button class="pull-right" *ngIf="isLockPCI == false" (click)="openPCIInvestmentPropertysModal()"
              mat-raised-button color="primary">
              <mat-icon>add</mat-icon> Add
            </button>
            <hr class="op04" />
          </div>

          <!-- shares -->
          <div *ngIf="shares && shares.length > 0">
            <div class="row clearfix add-mt">
              <!-- add additional expense -->
              <button class="pull-right" *ngIf="isLockPCI == false" (click)="openPCISharesModal()" mat-raised-button
                color="primary">
                <mat-icon>add</mat-icon> Add
              </button>

              <h3 class="sr-title add-mt">Shares</h3>
            </div>

            <mat-accordion>
              <mat-expansion-panel class="mat-elevation-z0" *ngFor="let share of shares; let i = index"
                [expanded]="share['opened'] && share['opened'] == true" (opened)="setStep(25)">
                <mat-expansion-panel-header>
                  <p class="rm-m">
                    Shares {{ i + 1 }} Details: <span class="value">{{ share['Info.Details'] }}</span>
                    <span class="ml" *ngIf="share['Modified_Fields']">
                      <mat-icon class="fas fa-user-edit modified"></mat-icon>
                    </span>
                  </p>

                  <p class="rm-m">
                    Liability:
                    <span class="value sr-title"
                      *ngIf="share['Liability.Value']">{{ share['Liability.Value'] | customCurrency }}
                    </span>
                    <span class="value sr-title" *ngIf="!share['Liability.Value']">$0</span>
                  </p>
                </mat-expansion-panel-header>

                <app-pci-shares *ngIf="step == 25" [pciEntryObj]="share" [title]="'Shares'" [disabled]="isLockPCI"
                  (getResult)="modifyPCIShare(i, $event)"></app-pci-shares>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
          <!-- display if no shares -->
          <div class="row clearfix add-mt" *ngIf="shares && shares.length == 0">
            <div class="pull-left op04">
              <h3 class="sr-title rm-m">Shares - Financed</h3>
              <p class="small rm-mt">No shares</p>
            </div>
            <button class="pull-right" *ngIf="isLockPCI == false" (click)="openPCISharesModal()" mat-raised-button
              color="primary">
              <mat-icon>add</mat-icon> Add
            </button>
            <hr class="op04" />
          </div>

          <!-- Personal loans -->
          <div *ngIf="personalLoans && personalLoans.length > 0">
            <div class="row clearfix add-mt">
              <!-- add additional expense -->
              <button class="pull-right" *ngIf="isLockPCI == false" (click)="openPCIPersonalLoansModal()"
                mat-raised-button color="primary">
                <mat-icon>add</mat-icon> Add
              </button>

              <h3 class="sr-title add-mt">Personal Loans</h3>
            </div>

            <mat-accordion>
              <mat-expansion-panel class="mat-elevation-z0" *ngFor="let personalLoan of personalLoans; let i = index"
                [expanded]="personalLoan['opened'] && personalLoan['opened'] == true" (opened)="setStep(26)">
                <mat-expansion-panel-header>
                  <p class="rm-m">
                    Loan {{ i + 1 }} Details:
                    <span class="value">{{ personalLoan['Info.Details'] }} {{ personalLoan['Info.Bank.Name'] }} </span>
                    <span class="ml" *ngIf="personalLoan['Modified_Fields']">
                      <mat-icon class="fas fa-user-edit modified"></mat-icon>
                    </span>
                  </p>

                  <p class="rm-m">
                    Liability:
                    <span class="value sr-title" *ngIf="personalLoan['Liability.Value']">{{
                      personalLoan['Liability.Value'] | customCurrency
                      }}</span>
                    <span class="value sr-title" *ngIf="!personalLoan['Liability.Value']">$0</span>
                    <!--
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Liability</mat-label>
                      <input matInput placeholder="Liability" [(ngModel)]="personalLoan['Liability.Value']"
                        currencyMask>
                    </mat-form-field> -->
                  </p>
                </mat-expansion-panel-header>

                <app-pci-personal-loan *ngIf="step == 26" [pciEntryObj]="personalLoan" [title]="'Personal Loans'"
                  [disabled]="isLockPCI" (getResult)="modifyPCIPersonalLoan(i, $event)"></app-pci-personal-loan>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
          <!-- display if no personal loans -->
          <div class="add-mt row clearfix" *ngIf="personalLoans && personalLoans.length == 0">
            <div class="pull-left op04">
              <h3 class="sr-title rm-m">Personal Loans</h3>
              <p class="small rm-mt">No personal loans</p>
            </div>
            <button class="pull-right" *ngIf="isLockPCI == false" (click)="openPCIPersonalLoansModal()"
              mat-raised-button color="primary">
              <mat-icon>add</mat-icon> Add
            </button>
            <hr class="op04" />
          </div>

          <div *ngIf="investmentAndLoans && investmentAndLoans.length > 0">
            <div class="row clearfix add-mt">
              <!-- add additional expense -->
              <button class="pull-right" *ngIf="isLockPCI == false" (click)="openPCIInvestmentAndLoansModal()"
                mat-raised-button color="primary">
                <mat-icon>add</mat-icon> Add
              </button>

              <h3 class="sr-title add-mt">Investment and Other Property Loans</h3>
            </div>

            <mat-accordion>
              <mat-expansion-panel class="mat-elevation-z0" *ngFor="let investment of investmentAndLoans; let i = index"
                [expanded]="investment['opened'] && investment['opened'] == true" (opened)="setStep(27)">
                <mat-expansion-panel-header>
                  <p class="rm-m">
                    Details: <span class="value">{{ investment['Info.Details'] }}</span>
                    <span class="ml" *ngIf="investment['Modified_Fields']">
                      <mat-icon class="fas fa-user-edit modified"></mat-icon>
                    </span>
                  </p>
                  <p class="rm-m">
                    Liability:<span class="value sr-title" *ngIf="investment['Liability.Value']">{{
                    investment['Liability.Value'] | customCurrency
                    }}</span>
                    <span class="value sr-title" *ngIf="!investment['Liability.Value']">$0</span>

                    <!-- <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Liability</mat-label>
                      <input matInput placeholder="Liability" [(ngModel)]="investment['Liability.Value']" currencyMask>
                    </mat-form-field> -->
                  </p>
                </mat-expansion-panel-header>

                <app-pci-investment-loans *ngIf="step == 27" [pciEntryObj]="investment" [title]="'Investment And Loans'"
                  [disabled]="isLockPCI" (getResult)="modifyInvestmentAndLoans(i, $event)">
                </app-pci-investment-loans>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
          <!-- display if no Investment Loans -->
          <div class="row clearfix add-mt" *ngIf="investmentAndLoans && investmentAndLoans.length == 0">
            <div class="pull-left op04">
              <h3 class="sr-title rm-m">Investment Loans</h3>
              <p class="small rm-mt">No investment loans</p>
            </div>
            <button class="pull-right" *ngIf="isLockPCI == false" (click)="openPCIInvestmentAndLoansModal()"
              mat-raised-button color="primary">
              <mat-icon>add</mat-icon> Add
            </button>
            <hr class="op04" />
          </div>

          <div *ngIf="creditCards && creditCards.length > 0">
            <div class="row clearfix add-mt">
              <!-- add additional expense -->
              <button class="pull-right" *ngIf="isLockPCI == false" (click)="openPCICreditCardModal()" mat-raised-button
                color="primary">
                <mat-icon>add</mat-icon> Add
              </button>

              <h3 class="sr-title add-mt">Credit &amp; Store Cards</h3>
            </div>

            <mat-accordion>
              <mat-expansion-panel class="mat-elevation-z0" *ngFor="let creditCard of creditCards; let i = index"
                [expanded]="creditCard['opened'] && creditCard['opened'] == true" (opened)="setStep(28)">
                <mat-expansion-panel-header>
                  <p class="rm-m">
                    Details: <span class="value">{{ creditCard['Info.Details'] }}</span>
                    <span class="ml" *ngIf="creditCard['Modified_Fields']">
                      <mat-icon class="fas fa-user-edit modified"></mat-icon>
                    </span>
                  </p>
                  <p class="rm-m">
                    Liability:<span class="sr-title value" *ngIf="creditCard['Liability.Value']">{{
                    creditCard['Liability.Value'] | customCurrency
                    }}</span>

                    <span class="sr-title value" *ngIf="!creditCard['Liability.Value']">$0</span>
                  </p>
                </mat-expansion-panel-header>

                <app-pci-credit-card *ngIf="step == 28" [pciEntryObj]="creditCard" [title]="'Credit Cards'"
                  [disabled]="isLockPCI" (getResult)="modifyPCICreditCard(i, $event)"></app-pci-credit-card>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
          <!-- display if no Credit & Store Cards -->
          <div class="row clearfix add-mt" *ngIf="creditCards && creditCards.length == 0">
            <div class="pull-left op04">
              <h3 class="sr-title rm-m">Credit &amp; Store Cards</h3>
              <p class="small rm-mt">No credit cards</p>
            </div>
            <button class="pull-right" *ngIf="isLockPCI == false" (click)="openPCICreditCardModal()" mat-raised-button
              color="primary">
              <mat-icon>add</mat-icon> Add
            </button>
            <hr class="op04" />
          </div>

          <div *ngIf="cars && cars.length > 0">
            <div class="row clearfix add-mt">
              <!-- add additional expense -->
              <button class="pull-right" *ngIf="isLockPCI == false" (click)="openPCICarModal()" mat-raised-button
                color="primary">
                <mat-icon>add</mat-icon> Add
              </button>

              <h3 class="sr-title add-mt">Car Loans</h3>
            </div>

            <mat-accordion>
              <mat-expansion-panel class="mat-elevation-z0" *ngFor="let car of cars; let i = index"
                [expanded]="car['opened'] && car['opened'] == true" (opened)="setStep(29)">
                <mat-expansion-panel-header>
                  <p class="rm-m">
                    Details: <span class="value">{{ car['Info.Make'] }}</span>
                    <span class="ml" *ngIf="car['Modified_Fields']">
                      <mat-icon class="fas fa-user-edit modified"></mat-icon>
                    </span>
                  </p>
                  <p class="rm-m">
                    Liability:<span class="value sr-title" *ngIf="car['Liability.Value']">{{
                    car['Liability.Value'] | customCurrency
                    }}</span>
                    <span class="value sr-title" *ngIf="!car['Liability.Value']">$0</span>

                    <!-- <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Liability</mat-label>
                      <input matInput placeholder="Liability" [(ngModel)]="car['Liability.Value']" currencyMask>
                    </mat-form-field> -->
                  </p>
                </mat-expansion-panel-header>

                <app-pci-car *ngIf="step == 29" [pciEntryObj]="car" [title]="'Cars'" [disabled]="isLockPCI"
                  (getResult)="modifyPCICar(i, $event)"></app-pci-car>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
          <!-- display if no Car Loans -->
          <div class="row clearfix add-mt" *ngIf="cars && cars.length == 0">
            <div class="pull-left op04">
              <h3 class="sr-title rm-m">Car Loans</h3>
              <p class="small rm-mt">No car loans</p>
            </div>
            <button class="pull-right" *ngIf="isLockPCI == false" (click)="openPCICarModal()" mat-raised-button
              color="primary">
              <mat-icon>add</mat-icon> Add
            </button>
            <hr class="op04" />
          </div>

          <div *ngIf="otherVehicles && otherVehicles.length > 0">
            <div class="row clearfix add-mt">
              <!-- add additional expense -->
              <button class="pull-right" *ngIf="isLockPCI == false" (click)="openPCIOtherVehiclesModal()"
                mat-raised-button color="primary">
                <mat-icon>add</mat-icon> Add
              </button>

              <h3 class="sr-title add-mt">Bike / Caravan / Boat Loans</h3>
            </div>

            <mat-accordion>
              <mat-expansion-panel class="mat-elevation-z0" *ngFor="let otherVehicle of otherVehicles; let i = index"
                [expanded]="otherVehicle['opened'] && otherVehicle['opened'] == true" (opened)="setStep(30)">
                <mat-expansion-panel-header>
                  <p class="rm-m">
                    Loan {{ i + 1 }} Details: <span class="value">{{ otherVehicle['Info.Make'] }}</span>
                    <span class="ml" *ngIf="otherVehicle['Modified_Fields']">
                      <mat-icon class="fas fa-user-edit modified"></mat-icon>
                    </span>
                  </p>
                  <p class="rm-m">
                    <span *ngIf="otherVehicle['Liability.Value']">
                      Liability:
                      <strong class="sr-title">{{ otherVehicle['Liability.Value'] | customCurrency }} </strong></span>
                    <span *ngIf="!otherVehicle['Liability.Value']">
                      Liability: <strong class="sr-title">$0 </strong></span>

                    <!-- <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Liability</mat-label>
                      <input matInput placeholder="Liability" [(ngModel)]="otherVehicle['Liability.Value']"
                        currencyMask>
                    </mat-form-field> -->
                  </p>
                </mat-expansion-panel-header>

                <app-pci-other-vehicle *ngIf="step == 30" [pciEntryObj]="otherVehicle" [title]="'Other Vehicles'"
                  [disabled]="isLockPCI" (getResult)="modifyPCIOtherVehicle(i, $event)"></app-pci-other-vehicle>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
          <!-- display if no car loan -->
          <div class="row clearfix add-mt" *ngIf="otherVehicles && otherVehicles.length == 0">
            <div class="pull-left op04">
              <h3 class="sr-title rm-m">Bike / Caravan / Boat Loans</h3>
              <p class="small rm-mt">No bike / caravan / boat loans</p>
            </div>
            <button class="pull-right" *ngIf="isLockPCI == false" (click)="openPCIOtherVehiclesModal()"
              mat-raised-button color="primary">
              <mat-icon>add</mat-icon> Add
            </button>
            <hr class="op04" />
          </div>

          <div *ngIf="taxAndDebts && taxAndDebts.length > 0">
            <div class="row clearfix add-mt">
              <!-- add additional expense -->
              <button class="pull-right" *ngIf="isLockPCI == false" (click)="openPCITaxAndDebtsModal()"
                mat-raised-button color="primary">
                <mat-icon>add</mat-icon> Add
              </button>

              <h3 class="sr-title add-mt">Tax / Government Debts</h3>
            </div>

            <mat-accordion>
              <mat-expansion-panel class="mat-elevation-z0" *ngFor="let taxAndDebt of taxAndDebts; let i = index"
                [expanded]="taxAndDebt['opened'] && taxAndDebt['opened'] == true" (opened)="setStep(31)">
                <mat-expansion-panel-header>
                  <p class="rm-m">
                    Debt {{ i + 1 }} Details: <span class="value">{{ taxAndDebt['Info.SubType.Label'] }}</span>
                    <span class="ml" *ngIf="taxAndDebt['Modified_Fields']">
                      <mat-icon class="fas fa-user-edit modified"></mat-icon>
                    </span>
                  </p>
                  <p class="rm-m">
                    <span *ngIf="taxAndDebt['Liability.Value']">
                      Liability:
                      <strong class="sr-title">{{ taxAndDebt['Liability.Value'] | customCurrency }} </strong></span>

                    <span *ngIf="!taxAndDebt['Liability.Value']">
                      Liability: <strong class="sr-title">$0 </strong></span>

                    <!-- <mat-form-field class="full-width" appearance="outline">
                      <mat-label>Liability</mat-label>
                      <input matInput placeholder="Liability" [(ngModel)]="taxAndDebt['Liability.Value']" currencyMask>
                    </mat-form-field> -->
                  </p>
                </mat-expansion-panel-header>

                <app-pci-tax-debt *ngIf="step == 31" [pciEntryObj]="taxAndDebt" [title]="'Tax And Debts'"
                  [disabled]="isLockPCI" (getResult)="modifyPCITaxAndDebts(i, $event)"></app-pci-tax-debt>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
          <!-- display if no debts -->
          <div class="row clearfix add-mt" *ngIf="taxAndDebts && taxAndDebts.length == 0">
            <div class="pull-left op04">
              <h3 class="sr-title rm-m">Tax / Government Debts</h3>
              <p class="small rm-mt">No Tax / Government Debts</p>
            </div>
            <button class="pull-right" *ngIf="isLockPCI == false" (click)="openPCITaxAndDebtsModal()" mat-raised-button
              color="primary">
              <mat-icon>add</mat-icon> Add
            </button>
            <!-- <hr class="op04"> -->
          </div>
        </div>
      </div>

      <hr />
      <div class="details-footer">
        <div class="net-block pull-right">
          <p class="small rm-m">Net position</p>
          <p class="lead rm-m bold" [class.red]="totalAssetsPos <= 0" [class.green]="totalAssetsPos > 0">
            {{ totalAssetsPos | customCurrency }}
          </p>
        </div>
      </div>
    </div>
  </mat-card>
</ng-container>
