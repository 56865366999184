/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./invitation-settings.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i3 from "@angular/material/card";
import * as i4 from "@angular/platform-browser/animations";
import * as i5 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i6 from "@angular/material/button";
import * as i7 from "@angular/cdk/a11y";
import * as i8 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i9 from "@angular/material/icon";
import * as i10 from "../../merchant/discount-setting/discount-setting.component.ngfactory";
import * as i11 from "../../merchant/discount-setting/discount-setting.component";
import * as i12 from "../../../core/authentication/shared/authentication.service";
import * as i13 from "../../merchant/shared/merchant.service";
import * as i14 from "@angular/material/dialog";
import * as i15 from "./invitation-settings.component";
import * as i16 from "@angular/router";
import * as i17 from "../../../shared/services/utils.service";
var styles_InvitationSettingsComponent = [i0.styles];
var RenderType_InvitationSettingsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InvitationSettingsComponent, data: { "animation": [{ type: 7, name: "ngIfAnimation", definitions: [{ type: 1, expr: "void => *", animation: [{ type: 11, selector: ".tab-content-inner", animation: { type: 6, styles: { opacity: 0, height: 0 }, offset: null }, options: { optional: true } }, { type: 11, selector: ".tab-content-inner", animation: { type: 12, timings: "250ms", animation: [{ type: 4, styles: { type: 5, steps: [{ type: 6, styles: { opacity: 0, height: 0, offset: 0 }, offset: null }, { type: 6, styles: { opacity: 1, height: "*", offset: 1 }, offset: null }] }, timings: "0.2s ease-in" }] }, options: { optional: true } }], options: null }, { type: 1, expr: "* => void", animation: [{ type: 11, selector: ".tab-content-inner", animation: { type: 6, styles: { opacity: 1, height: "*" }, offset: null }, options: { optional: true } }, { type: 11, selector: ".tab-content-inner", animation: { type: 12, timings: "250ms", animation: [{ type: 4, styles: { type: 5, steps: [{ type: 6, styles: { opacity: 1, height: "*", offset: 0 }, offset: null }, { type: 6, styles: { opacity: 0, height: 0, offset: 1 }, offset: null }] }, timings: "0.2s ease-in" }] }, options: { optional: true } }], options: null }], options: {} }] } });
export { RenderType_InvitationSettingsComponent as RenderType_InvitationSettingsComponent };
export function View_InvitationSettingsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "container profiles"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 11, "mat-card", [["class", "card details mat-card"]], [[2, "_mat-animation-noopable", null]], null, null, i2.View_MatCard_0, i2.RenderType_MatCard)), i1.ɵdid(2, 49152, null, 0, i3.MatCard, [[2, i4.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(3, 0, null, 0, 9, "div", [["class", "tab-content clearfix"]], [[24, "@ngIfAnimation", 0]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 8, "div", [["class", "tab-content-inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 5, "div", [["class", "row clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 4, "button", [["class", "pull-right"], ["color", "primary"], ["mat-raised-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.viewVideoInfo("Cash discounts", "vimeo.com/355935655/d2720c17e1") !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(7, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.FocusMonitor, [2, i4.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, [" What's this? "])), (_l()(), i1.ɵeld(9, 0, null, 0, 1, "mat-icon", [["class", "fas fa-info-circle mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i8.View_MatIcon_0, i8.RenderType_MatIcon)), i1.ɵdid(10, 9158656, null, 0, i9.MatIcon, [i1.ElementRef, i9.MatIconRegistry, [8, null], [2, i9.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵeld(11, 0, null, null, 1, "app-discount-setting", [["class", "clearfix"]], null, null, null, i10.View_DiscountSettingComponent_0, i10.RenderType_DiscountSettingComponent)), i1.ɵdid(12, 114688, null, 0, i11.DiscountSettingComponent, [i12.AuthenticationService, i13.MerchantService, [2, i14.MAT_DIALOG_DATA]], { merchantID: [0, "merchantID"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_4 = "primary"; _ck(_v, 7, 0, currVal_4); _ck(_v, 10, 0); var currVal_7 = _co.merchantID; _ck(_v, 12, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 2)._animationMode === "NoopAnimations"); _ck(_v, 1, 0, currVal_0); var currVal_1 = undefined; _ck(_v, 3, 0, currVal_1); var currVal_2 = (i1.ɵnov(_v, 7).disabled || null); var currVal_3 = (i1.ɵnov(_v, 7)._animationMode === "NoopAnimations"); _ck(_v, 6, 0, currVal_2, currVal_3); var currVal_5 = i1.ɵnov(_v, 10).inline; var currVal_6 = (((i1.ɵnov(_v, 10).color !== "primary") && (i1.ɵnov(_v, 10).color !== "accent")) && (i1.ɵnov(_v, 10).color !== "warn")); _ck(_v, 9, 0, currVal_5, currVal_6); }); }
export function View_InvitationSettingsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-invitation-settings", [], null, null, null, View_InvitationSettingsComponent_0, RenderType_InvitationSettingsComponent)), i1.ɵdid(1, 114688, null, 0, i15.InvitationSettingsComponent, [i12.AuthenticationService, i16.ActivatedRoute, i17.UtilsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InvitationSettingsComponentNgFactory = i1.ɵccf("app-invitation-settings", i15.InvitationSettingsComponent, View_InvitationSettingsComponent_Host_0, { merchantID: "merchantID", hideFilter: "hideFilter" }, {}, []);
export { InvitationSettingsComponentNgFactory as InvitationSettingsComponentNgFactory };
