<mat-card class="rel clearfix card-container-list">

  <div class="card-header primary-gradient-img clearfix stacked" *ngIf="hideHeader!=true">
    <button class="btn-close btn-clear mat-button smt white custom-close" *ngIf="isModal == true"
      (click)="closeEvent()">
      <mat-icon>clear</mat-icon>
    </button>

    <div class="row clearfix">
      <div class="row titleArea clearfix">

        <h2 class="summary-header text-left rm-m">{{title}}</h2>


      </div>
    </div>
  </div>

  <mat-dialog-content id="messageGroupCreate" [ngClass]="{ 'no-modal-height': isModal != true }">
    <div class="full-width text-center" @simpleFadeAnimation>

      <h3 class="sr-title text-left  pull-left text-center   smb">
        {{ "patient" }}s

        <span class="badge master" *ngIf="cards" [endVal]="
              (cards
              | FilterArrayMultipleOrContain :'search' : filterData
                | lengthOfArray) || 0
            " countUp></span>
      </h3>





      <div class="clearfix flex flex-center">
        <mat-form-field class="full-width" *ngIf="cards.length > 3" appearance="outline">
          <mat-label>Search ...</mat-label>
          <input [(ngModel)]="filterData" matInput name="search" tabIndex="1" />
        </mat-form-field>

        <button class="actionMenuToggle ml mr" *ngIf="cards.length > 3" [matMenuTriggerFor]="menu" mat-icon-button>
          <mat-icon class="fas fa-sort"></mat-icon>
        </button>

        <mat-menu #menu="matMenu">
          <button
            [ngClass]="{ 'accent-color': orderByFilter === 'CalculatedName' || orderByFilter === '-CalculatedName' }"
            (click)="orderBy('CalculatedName')" mat-menu-item>
            <span>Name</span>
            <span *ngIf="orderByFilter === 'CalculatedName' || orderByFilter === '-CalculatedName'">
              <mat-icon class="accent-color" *ngIf="orderByFilter === '-CalculatedName'">arrow_drop_up</mat-icon>
              <mat-icon class="accent-color" *ngIf="orderByFilter !== '-CalculatedName'">arrow_drop_down</mat-icon>
            </span>
          </button>

          <button
            [ngClass]="{ 'accent-color': orderByFilter === 'DateTimeCreated' || orderByFilter === '-DateTimeCreated' }"
            (click)="orderBy('DateTimeCreated')" mat-menu-item>
            <span>Join Date</span>
            <span *ngIf="orderByFilter === 'DateTimeCreated' || orderByFilter === '-DateTimeCreated'">
              <mat-icon class="accent-color" *ngIf="orderByFilter === '-DateTimeCreated'">arrow_drop_down</mat-icon>
              <mat-icon class="accent-color" *ngIf="orderByFilter !== '-DateTimeCreated'"> arrow_drop_up</mat-icon>
            </span>
          </button>

          <button [ngClass]="{ 'accent-color': orderByFilter === 'emails.Email' || orderByFilter === '-emails.Email' }"
            (click)="orderBy('emails.Email')" mat-menu-item>
            <span>Email</span>
            <span *ngIf="orderByFilter === 'emails.Email' || orderByFilter === '-emails.Email'">
              <mat-icon class="accent-color" *ngIf="orderByFilter === '-emails.Email'">arrow_drop_up</mat-icon>
              <mat-icon class="accent-color" *ngIf="orderByFilter !== '-emails.Email'">arrow_drop_down</mat-icon>
            </span>
          </button>
        </mat-menu>
      </div>


      <hr class="card-devider">
      <div class="full-width  message-card-patient" [ngClass]=" { 'one-line' :  isOneLine==true  }">



        <app-empty-list-message *ngIf="showEmpty==true && (cards
          | FilterArrayMultipleOrContain :'search' : filterData
            | lengthOfArray) <= 0" [title]="''" [message]="'No record found'">
        </app-empty-list-message>


        <div class="full-width  message-card-container flex " *ngFor="let card of cards  | FilterArrayMultipleOrContain :'search' : filterData | OrderArrayObject: [orderByFilter] |
            paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage } ; let index=index"
          (click)="$event.stopPropagation();openPatientViewDialogContainer(card['ID']);"
          [ngClass]="{'pair': index % 2 == 1, 'pointer': isViewOnly==true }">

          <div class="pull-right upper-side">
            <button class="" *ngIf="isOneLine!=true && isViewOnly!=true" (click)="$event.stopPropagation();"
              [matMenuTriggerFor]="menu11" color="accent" mat-icon-button matTooltip="View  Details">
              <mat-icon>more_vert</mat-icon>
            </button>

            <mat-menu #menu11="matMenu">
              <button (click)="$event.stopPropagation();openPatientViewDialog(card['ID'])" mat-menu-item>
                <mat-icon class="smr">visibility</mat-icon>
                View {{'Patient' | translate}}
              </button>





              <hr *ngIf="isRemove == true" />
              <button *ngIf="isRemove == true" (click)="$event.stopPropagation();remove(card)" mat-menu-item
                color="accent">
                <mat-icon class="fas fa-trash smr"></mat-icon>
                {{removeTitle}}
              </button>
            </mat-menu>
          </div>


          <app-card-picture *ngIf=" card && isOneLine==true" class="side-card-picture-preview-bulk-sms2"
            [hideZoomButton]="false" [tableName]="'customer'" [LastModified]="card['LastModified']" [cardID]="card.ID">
          </app-card-picture>

          <app-card-picture *ngIf="card && isOneLine!=true" class="side-card-picture-preview-bulk-sms"
            [hideZoomButton]="false" [tableName]="'customer'" [LastModified]="card['LastModified']" [cardID]="card.ID">
          </app-card-picture>

          <div class="full-width ml">


            <h3 *ngIf="isOneLine!=true" class="sr-title text-left full-width  rm-m">
              {{card['CalculatedName']}}
            </h3>

            <div *ngIf="isOneLine==true" class="full-width flex">


              <h4 class="sr-title text-left full-width  rm-m mr">
                <span *ngIf="card['CalculatedName']">{{card['CalculatedName']}}</span>
              </h4>


              <p class="text-left  full-width mr small rm-m">
                <span *ngIf="card['mobiles.Number']"> {{card['mobiles.Number'] |  customPhone}}</span>
              </p>
              <p class="text-left  full-width  mr small rm-m">

                <span *ngIf="card['emails.Email']">{{card['emails.Email']}}</span>
              </p>
              <p *ngIf="card['DateTimeCreated']" class="text-right  mr  full-width   small rm-m"
                style="    margin-right: 30px !important;     color: rgb(155, 155, 155); "
                matTooltip="{{ card.DateTimeCreated | customDate }}">
                Joint at: {{  card.DateTimeCreated | TimeLabelPast }} ago</p>
              <p *ngIf="!card['DateTimeCreated']" class="text-right  mr  full-width   small rm-m"
                style="    margin-right: 30px !important;     color: rgb(155, 155, 155); ">...</p>

              <button class=" mr" *ngIf="isViewOnly!=true" (click)="$event.stopPropagation();"
                [matMenuTriggerFor]="menu2222" color="accent" mat-icon-button matTooltip="View  Details">
                <mat-icon>more_vert</mat-icon>
              </button>

              <mat-menu #menu2222="matMenu">
                <button (click)="$event.stopPropagation();openPatientViewDialog(card['ID'])" mat-menu-item>
                  <mat-icon class="smr">visibility</mat-icon>
                  View {{'Patient' | translate}}
                </button>



                <hr *ngIf="isRemove == true" />
                <button *ngIf="isRemove == true" (click)="$event.stopPropagation();remove(card)" mat-menu-item
                  color="accent">
                  <mat-icon class="fas fa-trash smr"></mat-icon>
                  {{removeTitle}}
                </button>
              </mat-menu>
            </div>




            <div *ngIf="isOneLine!=true">
              <p *ngIf="card['mobiles.Number']" class="text-left  full-width small rm-m">
                {{card['mobiles.Number'] |  customPhone}}
              </p>
              <div class="  full-width  flex">
                <p *ngIf="card['emails.Email']" class="text-left  full-width  small rm-m">{{card['emails.Email']}}</p>
                <p *ngIf="card['DateTimeCreated']" class="text-right  full-width   small rm-m"
                  style="    margin-right: 30px !important;     color: rgb(155, 155, 155); "
                  matTooltip="{{ card.DateTimeCreated | customDate }}">
                  Joint at: {{  card.DateTimeCreated | TimeLabelPast }} ago</p>
              </div>
            </div>




          </div>
        </div>

      </div>




    </div>
  </mat-dialog-content>


  <div class="full-width text-center page-container  flex " *ngIf="
  (cards
  | FilterArrayMultipleOrContain :'search' : filterData
    | lengthOfArray) > itemsPerPage">
    <pagination-controls class="full-width clearfix" (pageChange)="changePage($event)" responsive="true">
    </pagination-controls>
  </div>

  <div class="drop-button text-center clearfix  full-width" *ngIf="isModal == true && hideActions!=true">
    <hr>
    <button class=" btn mt" (click)="closeEvent()" mat-raised-button color="primary">
      Close
    </button>



  </div>
</mat-card>
