<div class="container__patient-list-container">
  <div class="patient-list-container__top">
    <div class="top__merchant-picture-container">
      <app-card-picture
        class="top__merchant-picture ml"
        [cardID]="practice.ID"
        [hideZoomButton]="false"
        [tableName]="'Merchant'"
        [LastModified]="practice['LastModified']"
      >
      </app-card-picture>

      <h3 class="top__merchant-title sr-title text-center smb smt full-width" *ipvIsPromoterOrAdmin="false">
        {{ practice.TradingAs }}
      </h3>

      <ipv-promoter-merchant-selector class="mt" (merchantSet)="setMerchant($event)"></ipv-promoter-merchant-selector>
    </div>

    <p class="top__instruction-text small text-center mr smb rm-mt ml">
      Select an existing {{ "KEYWORD.patient" | translate }} in the list to view a collection
      of {{ "KEYWORD.patient" | translate }} notes or use "Select
      {{ "KEYWORD.patient" | translate | titlecase }}" to add notes for a new patient.
    </p>

    <div class="top__actions flex">
      <button class="btn mr" (click)="selectPatient()" mat-raised-button color="primary">
        Select {{ "KEYWORD.patient" | translate | titlecase }}
      </button>

      <button
        class="btn"
        [matTooltip]="'Add New ' + ('KEYWORD.patient' | translate | titlecase)"
        (click)="newPatient()"
        mat-raised-button
        color="accent"
      >
        <mat-icon class="fas fa-user-plus smr" style="font-size: 1rem; margin-top: 10px"></mat-icon>
        Add
      </button>
    </div>

    <div style="display: block; margin: 0px auto; width: 95%">
      <mat-button-toggle-group class="mt mb full-width btn-group" [value]="false" (change)="setAreNotesPrivate($event)">
        <mat-button-toggle class="full-width" [value]="true"> My Notes</mat-button-toggle>
        <mat-button-toggle class="full-width" [value]="false"> Shared with Me</mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <div class="top__collections full-width smb text-center">
      <hr style="margin-top: 0px"/>

      <div style="display: flex; justify-content: space-between">
        <h3 class="collections__counter chat-title sr-title smt full-width ml rm-mt smb text-left">
          Notes
          <span
            class="counter__badge badge master"
            [endVal]="(notes | uniqueBy: 'Who.key' | lengthOfArray) || 0"
            countUp
          >
          </span>
        </h3>

        <button class="pull-right filter-button" [matMenuTriggerFor]="filterMerchant" mat-icon-button>
          <mat-icon class="fas fa-filter accent-color"></mat-icon>
        </button>

        <mat-menu #filterMerchant="matMenu">
          <button
            [ngClass]="filterBy == 'all' ? 'accent-color' : ''"
            (click)="changeFilterBy('all')"
            mat-menu-item
            matTooltip="Show all notes from oldest to newest"
          >
            <span>All </span>
          </button>

          <hr/>

          <button
            [ngClass]="filterBy == 'latest' ? 'accent-color' : ''"
            (click)="changeFilterBy('latest')"
            mat-menu-item
            matTooltip="Show the latest created notes"
          >
            <span>Latest </span>
          </button>

          <hr/>

          <button
            [ngClass]="filterBy == 'follow ups' ? 'accent-color' : ''"
            (click)="changeFilterBy('follow ups')"
            mat-menu-item
            matTooltip="Show all notes that were created with incomplete follow ups"
          >
            <span>Follow Ups</span>
          </button>
        </mat-menu>
      </div>

      <div class="collections__patients-filter full-width flex">
        <mat-form-field class="patients-filter__form-field full-width no-line-input smr ml">
          <mat-icon class="patients-filter__search-icon fas fa-search" matPrefix></mat-icon>

          <input
            #patientFilter
            [placeholder]="('KEYWORD.patient' | translate | titlecase) + 's' + ' Filter...'"
            (input)="setNameFilter($event.target.value)"
            name="patientFilter"
            matInput
          />

          <mat-icon
            class="patients-filter__clear-icon fas fa-times"
            *ngIf="patientFilter.value"
            (click)="clearPatientNameFilter()"
            matSuffix
          >
          </mat-icon>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div class="patient-list-container__body">
    <ng-container *ngIf="notes.length > 0; else noNotesFound">
      <div
        class="body__patient-list-item full-width flex fixed-direction"
        *ngFor="let note of notes | uniqueBy: 'Who.key'"
        [ngClass]="
          noteSelected && note['Who.key'] === noteSelected.ID ? 'primary-bg' : 'patient-list-item--not-selected'
        "
        (click)="selectNote(note['Who.key'], note['Who.Name'])"
      >
        <!--        [cardID]="note['Who.key']"-->
        <app-card-picture class="patient-list-item__profile-picture pull-left mr ml"></app-card-picture>

        <div class="patient-list-item__details full-width mr">
          <h4 class="sr-title rm-m">{{ note['Who.Name'] | titlecase }}</h4>

          <p class="patient-list-item__message small rm-m">
            {{ note['Description'] | textLength: 77 }}
          </p>

          <div class="patient-list-item__date-container full-width flex" style="justify-content: space-between">
            <div
              class="patient-list-item__date-container full-width flex"
              style="justify-content: space-between; margin-top: 5px"
            >
              <div style="color: red; display: flex; min-width: 20px">
                <div *ngIf="getFollowUpCount(note['Who.key']) !== 0">
                  <mat-icon class="fas fa-history" style="font-size: small; line-height: 26px; width: 15px"></mat-icon>
                  <span style="font-size: 15px; margin-left: 5px; line-height: 26px">
                    {{ getFollowUpCount(note['Who.key']) }}
                  </span>
                </div>
              </div>

              <p class="patient-list-item__date small smt text-right rm-m">
                {{ note['Dates.Action'] | customDate: 'DD-MM-YYYY hh:mm A' }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        class="body__load-more-patient-button-container full-width fixed-direction mt text-center"
        *ngIf="showLoadMoreButton === true"
      >
        <button class="btn" (click)="loadMore()" mat-raised-button color="primary">Load more...</button>
      </div>
    </ng-container>

    <ng-template #noNotesFound>
      <ng-container>
        <div class="body__no-patient-found full-width text-center fixed-direction">
          <mat-icon class="fas fa-sticky-note"></mat-icon>
          <p>No Notes Found</p>
        </div>
      </ng-container>
    </ng-template>
  </div>
</div>
