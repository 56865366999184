import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

import { MatDialog } from '@angular/material/dialog';
import { SimplePageCreateComponent } from '../../../helper/simple-page-create/simple-page-create.component';

@Component({
  selector: 'admin-documents-center',
  templateUrl: './admin-documents-center.component.html',
  styleUrls: ['./admin-documents-center.component.css'],
})
export class AdminDocumentsCenterComponent implements OnInit {
  page: Subject<any> = new Subject();
  constructor(private dialog: MatDialog) {}
  ngOnInit() {}

  addSimplePage() {
    const ref = this.dialog.open(SimplePageCreateComponent);
    ref.componentInstance.added.subscribe((res) => {
      if (res.added) {
        ref.close();
        this.page.next(res);
      }
    });
  }
}
