import { Component, EventEmitter, Inject, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-merchant-service-create',
  templateUrl: './merchant-service-create.component.html',
  styleUrls: ['./merchant-service-create.component.css'],
})
export class MerchantServiceCreateComponent implements OnInit {
  merchantCreateService = {
    Label: '',
    Description: '',
  };

  @Output() create = new EventEmitter();
  @Output() close = new EventEmitter();

  isModal = true;

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {}

  ngOnInit() {}

  save() {
    this.create.emit(this.merchantCreateService);
  }

  closeModalEvent() {
    this.close.emit(true);
  }

  getContent(c) {
    this.merchantCreateService.Description = c;
  }
}
