<div class="full-width">
  <div class="rel" *ngIf="listDB.firstLoadEvent == false && tableID" @ngIfAnimation>
    <div class="row clearfix padded rel">
      <h1 class="dataHeader rm-mt transparent">Fetching data...</h1>
    </div>
  </div>

  <div class="row clearfix flex full-width" [hidden]="listDB.data.length == 0">
    <div class="full-width">
      <div class="row clearfix">
        <div class="row flex ctl pull-right" @ngIfAnimation>
          <button
            class="row select-btn"
            (click)="selectAll()"
            mat-stroked-button
            color=""
            matTooltip="Select All"
          >
            <mat-icon>border_all</mat-icon>
          </button>

          <button
            class="row select-btn"
            (click)="selectPage()"
            mat-stroked-button
            color=""
            matTooltip="{{ 'Select Article' | translate }}"
          >
            <mat-icon>border_outer</mat-icon>
          </button>
          <button
            class="row select-btn"
            *ngIf="selectedIDs && selectedIDs.length > 0"
            (click)="unselect()"
            mat-stroked-button
            color=""
          >
            <mat-icon>select_all</mat-icon> Deselect
          </button>

          <button
            class="ml row select-btn"
            *ngIf="selectedIDs && selectedIDs.length > 0"
            (click)="copyString()"
            mat-stroked-button
            color=""
          >
            <mat-icon>content_copy</mat-icon>
            {{ 'Copy  as String' | translate }}
          </button>

          <button
            class="row select-btn"
            *ngIf="selectedIDs && selectedIDs.length > 0"
            (click)="copyArray()"
            mat-stroked-button
            color=""
          >
            <mat-icon>file_copy</mat-icon>
            {{ 'Copy  as Array' | translate }}
          </button>
        </div>

        <h2 class="dataHeader rm-mt" *ngIf="tableID">
          Fields <span *ngIf="tableID"> Of {{ tableID }}</span>
          <span class="badge" [endVal]="dataSource.filteredData.length" countUp></span>
        </h2>
      </div>

      <div class="row flex ctl flexwrap mb" @ngIfAnimation>
        <mat-card class="list-card full-width">
          <div class="row clearfix mb searchArea primary-gradient-img">
            <div class="row clearfix flex">
              <div class="full-width animated fadeInDown">
                <mat-form-field class="full-width clearfix" appearance="outline">
                  <mat-label>Field name</mat-label>
                  <input
                    class="clearfix"
                    (input)="setFilter($event.target.value, 'FieldName')"
                    name="FieldName"
                    placeholder="Field name"
                    matInput
                  />
                </mat-form-field>
              </div>
            </div>
          </div>

          <mat-table class="list-table supplies" #table [dataSource]="dataSource" mat-table matSort>
            <ng-container cdkColumnDef="selectCheckBox" sticky>
              <mat-header-cell *cdkHeaderCellDef></mat-header-cell>
              <mat-cell *cdkCellDef="let row">
                <div class="margin-btm pull-left">
                  <mat-checkbox
                    [checked]="isSelected(row['FieldName'])"
                    (change)="select(row['FieldName'], $event.checked)"
                    color="accent"
                  >
                  </mat-checkbox>
                </div>
              </mat-cell>
            </ng-container>

            <ng-container cdkColumnDef="copy" sticky>
              <mat-header-cell *cdkHeaderCellDef mat-sort-header> </mat-header-cell>
              <mat-cell *cdkCellDef="let row" matTooltip="{{ row['FieldName'] }}">
                <button class="position" (click)="copy(row['FieldName'])" mat-button mat-icon-button>
                  <mat-icon>file_copy</mat-icon>
                </button>
              </mat-cell>
            </ng-container>

            <ng-container cdkColumnDef="name" sticky>
              <mat-header-cell *cdkHeaderCellDef mat-sort-header> Name </mat-header-cell>
              <mat-cell *cdkCellDef="let row" (click)="openQuickViewDialog(row)" matTooltip="{{ row['FieldName'] }}">
                {{ row['FieldName'] }}
              </mat-cell>
            </ng-container>

            <ng-container cdkColumnDef="Deprecated">
              <mat-header-cell *cdkHeaderCellDef mat-sort-header> Deprecated Name </mat-header-cell>
              <mat-cell
                *cdkCellDef="let row"
                (click)="openQuickViewDialog(row)"
                matTooltip="{{ row['DepreciatedFieldName'] }}"
              >
                {{ row['DepreciatedFieldName'] }}
              </mat-cell>
            </ng-container>

            <ng-container cdkColumnDef="Type">
              <mat-header-cell *cdkHeaderCellDef mat-sort-header> Type </mat-header-cell>
              <mat-cell
                class="list-label"
                *cdkCellDef="let row"
                (click)="openQuickViewDialog(row)"
                matTooltip="{{ getType(row) }}"
              >
                {{ getType(row) }}
              </mat-cell>
            </ng-container>

            <ng-container cdkColumnDef="QueryComparitors">
              <mat-header-cell *cdkHeaderCellDef mat-sort-header> Comparitors </mat-header-cell>
              <mat-cell
                class="list-label"
                *cdkCellDef="let row"
                (click)="openQuickViewDialog(row)"
                matTooltip="{{ row['QueryComparitors'] }}"
              >
                {{ row['QueryComparitors'] }}
              </mat-cell>
            </ng-container>

            <ng-container cdkColumnDef="Description">
              <th *matHeaderCellDef mat-header-cell mat-sort-header>Description</th>
              <td *matCellDef="let row" (click)="openQuickViewDialog(row)" mat-cell>
                {{ row['Description'] }}
              </td>
            </ng-container>

            <ng-container cdkColumnDef="Comments">
              <th *matHeaderCellDef mat-header-cell mat-sort-header>Comments</th>
              <td *matCellDef="let row" (click)="openQuickViewDialog(row)" mat-cell>
                {{ row['Comments'] }}
              </td>
            </ng-container>

            <ng-container cdkColumnDef="CanGet">
              <mat-header-cell *cdkHeaderCellDef mat-sort-header> Get </mat-header-cell>
              <mat-cell *cdkCellDef="let row" (click)="openQuickViewDialog(row)">
                <span
                  class="list-label smaller"
                  *ngIf="row['CanGet'] == '1'"
                  matTooltip="This Fields can be selected"
                  style="background-color: rgb(90, 186, 71)"
                >
                  <mat-icon>check</mat-icon>
                </span>
                <span
                  class="list-label smaller"
                  *ngIf="row['CanGet'] != '1'"
                  matTooltip="This Fields can be selected"
                  style="background-color: rgb(239, 71, 39)"
                >
                  <mat-icon>close</mat-icon>
                </span>
              </mat-cell>
            </ng-container>

            <ng-container cdkColumnDef="IsMandatory">
              <mat-header-cell *cdkHeaderCellDef mat-sort-header>Mandatory</mat-header-cell>
              <mat-cell *cdkCellDef="let row" (click)="openQuickViewDialog(row)">
                <span
                  class="list-label smaller"
                  *ngIf="row['IsMandatory'] == '1'"
                  matTooltip="This Fields is Mandatory"
                  style="background-color: rgb(90, 186, 71)"
                >
                  <mat-icon>check</mat-icon>
                </span>
                <span
                  class="list-label smaller"
                  *ngIf="row['IsMandatory'] != '1'"
                  matTooltip="This Fields is not Mandatory"
                  style="background-color: rgb(239, 71, 39)"
                >
                  <mat-icon>close</mat-icon>
                </span>
              </mat-cell>
            </ng-container>

            <ng-container cdkColumnDef="CanQueryWithIN">
              <mat-header-cell *cdkHeaderCellDef mat-sort-header> IN </mat-header-cell>
              <mat-cell *cdkCellDef="let row" (click)="openQuickViewDialog(row)">
                <span
                  class="list-label smaller"
                  *ngIf="row['CanQueryWithIN'] == '1'"
                  matTooltip="You can perform an IN Query against this Field"
                  style="background-color: rgb(90, 186, 71)"
                >
                  <mat-icon>check</mat-icon>
                </span>
                <span
                  class="list-label smaller"
                  *ngIf="row['CanQueryWithIN'] != '1'"
                  matTooltip="You cannot perform  an IN Query against this Field"
                  style="background-color: rgb(239, 71, 39)"
                >
                  <mat-icon>close</mat-icon>
                </span>
              </mat-cell>
            </ng-container>

            <ng-container cdkColumnDef="CanSetOnce">
              <mat-header-cell *cdkHeaderCellDef mat-sort-header>Set Once</mat-header-cell>
              <mat-cell *cdkCellDef="let row" (click)="openQuickViewDialog(row)">
                <span
                  class="list-label smaller"
                  *ngIf="row['CanSetOnce'] == '1'"
                  matTooltip="This Fields can be Set Once in new record"
                  style="background-color: rgb(90, 186, 71)"
                >
                  <mat-icon>check</mat-icon>
                </span>
                <span
                  class="list-label smaller"
                  *ngIf="row['CanSetOnce'] != '1'"
                  matTooltip="This Fields cannot be Set Once in new record"
                  style="background-color: rgb(239, 71, 39)"
                >
                  <mat-icon>close</mat-icon>
                </span>
              </mat-cell>
            </ng-container>

            <ng-container cdkColumnDef="CanModify">
              <mat-header-cell *cdkHeaderCellDef mat-sort-header> Modify </mat-header-cell>
              <mat-cell *cdkCellDef="let row" (click)="openQuickViewDialog(row)">
                <span
                  class="list-label smaller"
                  *ngIf="row['CanModify'] == '1'"
                  matTooltip="This Fields can be  Modified"
                  style="background-color: rgb(90, 186, 71)"
                >
                  <mat-icon>check</mat-icon>
                </span>
                <span
                  class="list-label smaller"
                  *ngIf="row['CanModify'] != '1'"
                  matTooltip="This Fields cannot be  Modified"
                  style="background-color: rgb(239, 71, 39)"
                >
                  <mat-icon>close</mat-icon>
                </span>
              </mat-cell>
            </ng-container>

            <ng-container cdkColumnDef="CanQuery">
              <mat-header-cell *cdkHeaderCellDef mat-sort-header> Query </mat-header-cell>
              <mat-cell *cdkCellDef="let row" (click)="openQuickViewDialog(row)">
                <span
                  class="list-label smaller"
                  *ngIf="row['CanQuery'] == '1'"
                  matTooltip="You can perform a Query against this Field"
                  style="background-color: rgb(90, 186, 71)"
                >
                  <mat-icon>check</mat-icon>
                </span>
                <span
                  class="list-label smaller"
                  *ngIf="row['CanQuery'] != '1'"
                  matTooltip="You cannot perform a Query against this Field"
                  style="background-color: rgb(239, 71, 39)"
                >
                  <mat-icon>close</mat-icon>
                </span>
              </mat-cell>
            </ng-container>

            <ng-container cdkColumnDef="CanSort">
              <mat-header-cell *cdkHeaderCellDef mat-sort-header> Sort</mat-header-cell>
              <mat-cell *cdkCellDef="let row" (click)="openQuickViewDialog(row)">
                <span
                  class="list-label smaller"
                  *ngIf="row['CanSort'] == '1'"
                  matTooltip="You can perform an Order By against this Field"
                  style="background-color: rgb(90, 186, 71)"
                >
                  <mat-icon>check</mat-icon>
                </span>
                <span
                  class="list-label smaller"
                  *ngIf="row['CanSort'] != '1'"
                  matTooltip="You cannot perform a Order By against this Field"
                  style="background-color: rgb(239, 71, 39)"
                >
                  <mat-icon>close</mat-icon>
                </span>
              </mat-cell>
            </ng-container>

            <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row
              *cdkRowDef="let row; columns: displayedColumns"
              [class.selected-row]="row && row.ID && row.ID == fieldID"
            ></mat-row>
          </mat-table>
          <mat-paginator
            #paginator
            [length]="dataSource.filteredData.length"
            [pageIndex]="0"
            [pageSize]="pageSize"
            [pageSizeOptions]="pageSizeOptions"
          >
          </mat-paginator>
        </mat-card>
      </div>
    </div>
    <div class="margin-div text-center" *ngIf="listDB.data.length == 0 && listDB.firstLoadEvent != false && tableID">
      <app-empty-list-message></app-empty-list-message>
    </div>
  </div>
  <div class="margin-div text-center" *ngIf="!tableID">
    <app-empty-list-message [title]="''" [message]="'Please select a Table  '"></app-empty-list-message>
  </div>
</div>
