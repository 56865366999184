import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, mergeMap, skip } from 'rxjs/operators';
import { NotifyAppComponent } from '../../../../../../shared/types/notify-app-component';
import { NotesService } from '../../../../shared/services/notes.service';
import { NoteTypeCustomPayload } from '../../../../shared/type/notes.type';

export class NoteTypeFormConfig {
  formMode: 'create' | 'update';
  noteType?: {
    noteType: string;
    subjects: string[];
    results: string[];
    id: string;
  };
}

@Component({
  selector: 'ipv-note-type-form',
  templateUrl: './note-type-form.modal.html',
  styleUrls: ['./note-type-form.modal.css'],
})
export class NoteTypeFormModal implements OnInit, OnDestroy {
  @Output() created = new EventEmitter<NoteTypeCustomPayload>();
  @Output() updated = new EventEmitter<NoteTypeCustomPayload>();

  formMode: 'create' | 'update' = 'create';
  title = 'Create Note Type';
  id: string;

  noteTypeDebounceSubject = new BehaviorSubject<string>('');

  noteType = '';
  isNoteTypeUnique: boolean = null;
  subjects = [''];
  results = [''];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: NoteTypeFormConfig,
    private matDialogRef: MatDialogRef<NoteTypeFormModal>,
    private notesService: NotesService
  ) {
    if (data) {
      this.formMode = data.formMode || 'create';

      if (data.noteType) {
        this.id = data.noteType.id;
        this.noteType = data.noteType.noteType;
        this.subjects = data.noteType.subjects;
        this.results = data.noteType.results;
        this.isNoteTypeUnique = true;
      }
    }

    this.title = this.formMode === 'create' ? 'Create Note Type' : 'Update Note Type';
  }

  ngOnInit() {
    this.matDialogRef.updateSize('650px');

    this.noteTypeDebounceSubject
      .pipe(
        untilDestroyed(this),
        skip(1),
        debounceTime(1300),
        mergeMap((noteType) => {
          return this.notesService.fetchIsNoteLabelUnique$(noteType);
        })
      )
      .subscribe((isUnique) => {
        if (this.noteType.length > 0) {
          this.isNoteTypeUnique = isUnique;
        } else {
          this.isNoteTypeUnique = null;
        }
      });
  }

  setNoteType() {
    this.noteTypeDebounceSubject.next(this.noteType);
  }

  clear() {
    this.noteType = '';
    this.isNoteTypeUnique = null;
  }

  ngOnDestroy() {
    /** required for untilDestroyed (Replace with @UntilDestroyed() Decorator in angular 9 and delete ngOnDestroy()) */
  }

  addSubject() {
    this.subjects = [...this.subjects, ''];
  }

  setSubjectText(index: number, event: Event) {
    const text = (event.target as HTMLInputElement).value;
    this.subjects[index] = text;
  }

  getIsSubjectInvalid() {
    const isValid = this.subjects.findIndex((subject) => subject === '') === -1;
    return isValid;
  }

  getAreDuplicateSubjectsDetected() {
    let isDuplicateFound = false;

    this.subjects.forEach((inputSubject, index) => {
      const isDuplicate = this.subjects.filter((subject) => inputSubject === subject).length > 1;
      if (isDuplicate) {
        isDuplicateFound = true;
      }
    });

    return isDuplicateFound;
  }

  removeSubject(index) {
    this.subjects.splice(index, 1);
  }

  addResult() {
    this.results = [...this.results, ''];
  }

  setResultText(index: number, event: Event) {
    const text = (event.target as HTMLInputElement).value;
    this.results[index] = text;
  }

  removeResult(index) {
    this.results.splice(index, 1);
  }

  getIsResultInvalid() {
    const isValid = this.results.findIndex((result) => result === '') === -1;
    return isValid;
  }

  getAreDuplicateResultDetected() {
    let isDuplicateFound = false;

    this.results.forEach((inputResult, index) => {
      const isDuplicate = this.results.filter((results) => inputResult === results).length > 1;
      if (isDuplicate) {
        isDuplicateFound = true;
      }
    });

    return isDuplicateFound;
  }

  trackByItem(index: number, element: string): string {
    return element;
  }

  save() {
    if (this.getAreDuplicateSubjectsDetected()) {
      NotifyAppComponent.displayToast(
        'error',
        'Duplicate subjects detected',
        'Please remove any duplicate subjects and try again'
      );
    }

    if (this.getAreDuplicateResultDetected()) {
      NotifyAppComponent.displayToast(
        'error',
        'Duplicate results detected',
        'Please remove any results subjects and try again'
      );
    }

    if (!(this.getAreDuplicateResultDetected() || this.getAreDuplicateSubjectsDetected())) {
      const payload: NoteTypeCustomPayload = {
        actionType: this.noteType,
        subject: this.subjects,
        result: this.results,
      };

      this.created.emit(payload);
    }
  }

  update() {
    if (this.getAreDuplicateSubjectsDetected()) {
      NotifyAppComponent.displayToast(
        'error',
        'Duplicate subjects detected',
        'Please remove any duplicate subjects and try again'
      );
    }

    if (this.getAreDuplicateResultDetected()) {
      NotifyAppComponent.displayToast(
        'error',
        'Duplicate results detected',
        'Please remove any results subjects and try again'
      );
    }

    if (!(this.getAreDuplicateResultDetected() || this.getAreDuplicateSubjectsDetected())) {
      const payload: NoteTypeCustomPayload = {
        id: this.id,
        actionType: this.noteType,
        subject: this.subjects,
        result: this.results,
      };

      this.updated.emit(payload);
    }
  }
}
