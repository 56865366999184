<app-operator-filter
  class="dash"
  id="part1"
  [displayMerchant]="false"
  [displayDateFrom]="false"
  [displayDateTo]="false"
  [displayDentist]="false"
  (search)="applySearch($event)"
>
</app-operator-filter>

<mat-list
  class="mat-card mat-card-remove-padding"
  [hidden]="displayPatientSummary == true || displaySettlementList == true"
>
  <mat-list-item class="row clearfix flex sticky primary-gradient-img">
    <h4 class="full-width clickable" *ngFor="let d of displayedColumns" (click)="filterStatus(d)">{{ d }}</h4>
  </mat-list-item>

  <section *ngIf="summaryObject.length > 0">
    <mat-list-item
      class="row clearfix flex clickList"
      *ngFor="let item of summaryObject | orderBy: 'merchantName'; let i = index"
      [ngStyle]="{ 'background-color': i % 2 == 0 ? '#fff' : '#f4f4f4' }"
      (click)="openPatientSummary(item.merchantID)"
    >
      <p class="full-width small bold">{{ item.merchantName }}</p>
      <p class="full-width small">{{ item.OUTSTANDING | customCurrency }}</p>
      <p class="full-width small">{{ item.PAID | customCurrency }}</p>
      <p class="full-width small">{{ item.APPR | customCurrency }}</p>
      <p class="full-width small">{{ item.REQU | customCurrency }}</p>
      <p class="full-width small">{{ item.PEND | customCurrency }}</p>
      <p class="full-width small">{{ item.WDRN | customCurrency }}</p>
      <p class="full-width small">{{ item.DECL | customCurrency }}</p>
      <p class="full-width small">{{ item.VOID | customCurrency }}</p>
    </mat-list-item>
  </section>

  <mat-list-item class="row clearfix" *ngIf="summaryObject.length == 0">
    <app-empty-list-message class="full-width"></app-empty-list-message>
  </mat-list-item>

  <mat-list-item class="row clearfix flex stickyBottom primary-gradient-img">
    <h3 class="full-width">TOTAL</h3>
    <p class="full-width small">{{ calculateTotal('Outstanding', summaryObject) | customCurrency }}</p>
    <p class="full-width small">{{ calculateTotal('Settled', summaryObject) | customCurrency }}</p>
    <p class="full-width small">{{ calculateTotal('Approved', summaryObject) | customCurrency }}</p>
    <p class="full-width small">{{ calculateTotal('Requested', summaryObject) | customCurrency }}</p>
    <p class="full-width small">{{ calculateTotal('Pending', summaryObject) | customCurrency }}</p>
    <p class="full-width small">{{ calculateTotal('Withdrawn', summaryObject) | customCurrency }}</p>
    <p class="full-width small">{{ calculateTotal('Declined', summaryObject) | customCurrency }}</p>
    <p class="full-width small">{{ calculateTotal('Voided', summaryObject) | customCurrency }}</p>
  </mat-list-item>
</mat-list>

<div class="clearfix" *ngIf="merchantID && displayPatientSummary == true && displaySettlementList == false">
  <div class="row clearfix">
    <mat-list>
      <mat-list-item class="row clearfix flex sticky primary-gradient-img">
        <h3 class="full-width clickable" *ngFor="let d of patientDisplayedColumns" (click)="filterPatientStatus(d)">
          {{ d }}
        </h3>
      </mat-list-item>

      <section *ngIf="patientSummary.length > 0">
        <mat-list-item
          class="row clearfix flex clickList"
          *ngFor="let item of patientSummary | orderBy: 'patientName'; let i = index"
          [ngStyle]="{ 'background-color': i % 2 == 0 ? '#fff' : '#f4f4f4' }"
          (click)="openSettlementList(item.contractID)"
        >
          <p class="full-width small">{{ item.patientName }}</p>
          <p class="full-width small">{{ item.OUTSTANDING | customCurrency }}</p>
          <p class="full-width small">{{ item.PAID | customCurrency }}</p>
          <p class="full-width small">{{ item.APPR | customCurrency }}</p>
          <p class="full-width small">{{ item.REQU | customCurrency }}</p>
          <p class="full-width small">{{ item.PEND | customCurrency }}</p>
          <p class="full-width small">{{ item.WDRN | customCurrency }}</p>
          <p class="full-width small">{{ item.DECL | customCurrency }}</p>
          <p class="full-width small">{{ item.VOID | customCurrency }}</p>
        </mat-list-item>

        <mat-list-item class="row clearfix flex stickyBottom primary-gradient-img">
          <h3 class="full-width">TOTAL</h3>
          <p class="full-width small">{{ calculateTotal('Outstanding', patientSummary) | customCurrency }}</p>
          <p class="full-width small">{{ calculateTotal('Settled', patientSummary) | customCurrency }}</p>
          <p class="full-width small">{{ calculateTotal('Approved', patientSummary) | customCurrency }}</p>
          <p class="full-width small">{{ calculateTotal('Requested', patientSummary) | customCurrency }}</p>
          <p class="full-width small">{{ calculateTotal('Pending', patientSummary) | customCurrency }}</p>
          <p class="full-width small">{{ calculateTotal('Withdrawn', patientSummary) | customCurrency }}</p>
          <p class="full-width small">{{ calculateTotal('Declined', patientSummary) | customCurrency }}</p>
          <p class="full-width small">{{ calculateTotal('Voided', patientSummary) | customCurrency }}</p>
        </mat-list-item>
      </section>
    </mat-list>
  </div>
  <div class="full-width text-center">
    <button class="mb mt pull-left" (click)="hidePatientSummary()" mat-raised-button color="primary">
      <mat-icon>keyboard_arrow_left</mat-icon>
      Settlement Summary
    </button>
  </div>
</div>

<div class="clearfix" *ngIf="contractID && displaySettlementList == true">
  <app-settlement-list-global
    [dateFrom]="dateFrom"
    [dateTo]="dateTo"
    [contractID]="contractID"
  ></app-settlement-list-global>

  <div class="full-width text-center">
    <button class="mb mt pull-left" (click)="hideSettlementList()" mat-raised-button color="primary">
      <mat-icon>keyboard_arrow_left</mat-icon>
      Settlement Summary
    </button>
  </div>
</div>
