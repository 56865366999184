<mat-dialog-content class="notes-dialog">
  <ng-container *ngIf="viewModel$ | async as viewModel">
    <div class="notes-dialog__container full-width">
      <div style="width: 500px; height: 100%">
        <ipv-note-patient-list
          (noteModalModeSetToList)="setNoteListMode($event)"
          (selectPatientButtonClicked)="selectPatient(viewModel.practice)"
          (newPatientButtonClicked)="newPatient(viewModel.practice)"
          (loadMoreClicked)="loadMoreNotes()"
          (patientNameFilterUpdated)="setNameFilter($event)"
          (filterByChanged)="setNotesFilterBy($event)"
          (noteSelected)="selectNote($event)"
          (merchantSet)="setMerchant($event)"
          (areNotesPrivateChanged)="setAreNotesPrivate($event)"
          [showLoadMoreButton]="areMoreNotesAvailable"
          [practice]="viewModel.practice"
          [notes]="viewModel.notes"
        ></ipv-note-patient-list>
      </div>

      <div class="note-list-container full-width empty" *ngIf="viewModel$ | async">
        <button
          class="note-list-container__close-button btn-close btn-clear mat-button pull-right mr mt"
          (click)="close()"
          *ngIf="!selectedPatient"
        >
          <mat-icon>clear</mat-icon>
        </button>

        <ipv-note-list-header
          (closeEvent)="close()"
          [practice]="viewModel.practice"
          [selectedPatient]="selectedPatient"
        >
        </ipv-note-list-header>

        <ipv-note-list
          *ngIf="noteListMode === 'list'"
          (closeModal)="close()"
          (noteListModeSet)="setNoteListMode($event)"
          (loadMoreClicked)="loadMoreSelectedNotes()"
          (deleteButtonClicked)="deleteNote($event)"
          (noteSelected)="setSelectedNote($event)"
          (showSystemNotesToggled)="setSelectedNotesFilterBy($event)"
          (showArchivedNotesToggled)="setShowArchivedNotes($event)"
          (archiveButtonClicked)="archiveNote($event)"
          (unarchiveButtonClicked)="unarchiveNote($event)"
          [showLoadMoreButton]="areMoreSelectedNotesAvailable"
          [user]="user"
          [selectedPatient]="selectedPatient"
          [noteDates]="viewModel.selectedNoteDates"
          [notes]="viewModel.selectedNotes"
          [parentRoute]="parentRoute"
        ></ipv-note-list>

        <ipv-note-form
          *ngIf="noteListMode !== 'list'"
          (noteListModeSet)="setNoteListMode($event)"
          (closeModal)="close()"
          [formMode]="noteListMode"
          [patient]="selectedPatient"
          [practice]="viewModel.practice"
          [dentist]="viewModel.dentist"
          [user]="viewModel.user.data"
          [noteTypes]="viewModel.enabledNoteTypes"
          [selectedNote]="selectedNote"
          [areNotesPrivate]="areNotesPrivate"
          [relationshipLink]="relationshipLink"
          [parentRoute]="parentRoute"
          [prefillNoteAction]="prefillNoteAction"
        >
        </ipv-note-form>

        <app-empty-list-message
          *ngIf="!selectedPatient && noteListMode !== 'create'"
          (actionEvent)="selectPatient(viewModel.practice)"
          [message]="'Select a ' + ('KEYWORD.patient' | translate | titlecase) + ' from the side menu or use the ' + ('KEYWORD.patient' | translate | titlecase) + ' lookup.'"
          [isAction]="true"
          [actionLabel]="('KEYWORD.patient' | translate | titlecase) + ' Lookup'"
          [actionIcon]="null"
        >
        </app-empty-list-message>
      </div>
    </div>
  </ng-container>
</mat-dialog-content>
