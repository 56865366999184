import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'splitArray',
})
export class SplitArray implements PipeTransform {
  constructor() {}

  transform(expression: any, format: any) {
    try {
      let result = expression;

      let l = '|';

      if (format) {
        l = format;
      }

      if (expression && typeof expression == 'string') {
        result = expression.split(l);
      }

      return result;
    } catch (e) {
      return expression;
    }
  }
}
