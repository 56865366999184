<ipv-title-modal [title]="title" [classIcon]="'fas fa-print'" [hideAllDefaultButtons]="true">
  <div content>
    <title>test</title>

    <div class="padding: 10px; margin-bottom: 30px" id="print-section">
      <div class="section row clearfix mt" *ngIf="!loading">
        <div>
          <h1 class="summary-header rm-m sm-mb" [ngStyle]="{ 'text-align': printTitleAlignment }">{{ printTitle }}</h1>
        </div>
      </div>

      <div class="section row clearfix mt" *ngIf="!loading">
        <div class="pull-right" style="text-align: left">
          <p class="bold rm-mt" *ngIf="merchant" style="position: relative; top: 20px">{{ merchant['TradingAs'] }}</p>

          <div style="height: 60px; width: 50px; margin-top: 10px; margin-bottom: 10px; margin-left: auto">
            <app-merchant-view-modal-picture
              class="merchantView"
              *ngIf="merchant && merchant['ID']"
              [merchantID]="merchant['ID']"
            >
            </app-merchant-view-modal-picture>
          </div>

          <div style="position: relative; bottom: 40px">
            <p class="small rm-m" *ngIf="merchant">Phone: {{ merchant['phones.Number'] | customPhone }}</p>
            <p class="small rm-m" *ngIf="merchant">Address: {{ merchant['addresses.Calculated'] }}</p>
          </div>
        </div>
      </div>

      <ng-content></ng-content>

      <div class="section">
        <div class="pull-right power-by">
          <img
            [src]="'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/power_by_SR.png'"
          />
        </div>
      </div>
    </div>

    <div style="width: 100%; background: white; position: absolute; bottom: 0px; height: 30px; z-index: 2000">
      <div style="display: flex; width: 100%; justify-content: flex-end; margin-top: 15px">
        <button
          class="pull-left"
          [disabled]="loading"
          [printTitle]="printTitle"
          [useExistingCss]="true"
          color="accent"
          mat-raised-button
          style="margin-left: 10px"
          printSectionId="print-section"
          styleSheetFile="/assets/styles/print-history.css, /assets/styles/application.css, /assets/styles/css/fontawesome.min.css"
          ngxPrint
        >
          <mat-icon class="fas fa-print"></mat-icon>
          Print
        </button>
      </div>
    </div>
  </div>
</ipv-title-modal>
