import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { HeaderService } from '../../../shared/services/header.service';
import { UtilsClass } from '../../../shared/types/utils/utils.class';

@Component({
  selector: 'app-activity-log-list-overview',
  templateUrl: './activity-log-list-overview.component.html',
  styleUrls: ['./activity-log-list-overview.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('*', style({ opacity: 0 }), { optional: true }),
        query(
          '*',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('*', style({ opacity: 1 }), { optional: true }),
        query(
          '*',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class ActivityLogListOverviewComponent implements OnInit {
  isPromoterOrAdmin = false;

  merchants = [];
  dateFrom;
  dateTo;
  _dateFrom;
  _dateTo;
  _merchantID = null;
  merchantID = 'none';
  productID;
  status;
  dentistID;

  hideExtraComponents = false;
  isDisplayMerchant = true;

  groupFilter = 'merchant';

  utils = new UtilsClass();

  searchApplied = false;

  sendNull = true;
  selectedRange = 'days90';

  constructor(private authenticationService: AuthenticationService, private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit() {
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;

      if (this.isPromoterOrAdmin == false) {
        const __dateTo = moment(new Date(), 'DD-MM-YYYY hh:mm:ss');

        __dateTo.hours(23);
        __dateTo.minutes(59);

        this.dateTo = __dateTo.toDate();

        this.dateFrom = this.utils.getPastTime(this.dateTo, -7, 'day');
      }

      if (this.isPromoterOrAdmin == true) {
        HeaderService.getPromoterViewAsObject().subscribe((res) => {
          if (res && res['promoterViewAs'] && res['promoterViewAs'] == 'promoter') {
            this.merchantID = 'none';
            this.isDisplayMerchant = true;
          } else if (res && res['promoterViewAs'] && res['promoterViewAs'] == 'merchant') {
            this.merchantID = res['merchantID'];
            this.isDisplayMerchant = false;
            const __dateTo = moment(new Date(), 'DD-MM-YYYY hh:mm:ss');

            __dateTo.hours(23);
            __dateTo.minutes(59);

            this.dateTo = __dateTo.toDate();

            this.dateFrom = this.utils.getPastTime(this.dateTo, -7, 'day');
          }
        });

        const _mID = JSON.parse(localStorage.getItem('selectedMerchant'));

        if (_mID) {
          // this.isDisplayMerchant = false;
          this.merchantID = _mID;
          const __dateTo = moment(new Date(), 'DD-MM-YYYY hh:mm:ss');

          __dateTo.hours(23);
          __dateTo.minutes(59);

          this.dateTo = __dateTo.toDate();

          this.dateFrom = this.utils.getPastTime(this.dateTo, -7, 'day');
        }
      }
    });
  }

  getMerchant(d) {
    this._merchantID = d;
  }

  getDateFrom(d) {
    this._dateFrom = d;
  }

  getDateTo(d) {
    this._dateTo = d;
  }

  applySearch(result) {
    this.merchantID = result.merchantID;
    this.dateFrom = result.dateFrom;
    this.dateTo = result.dateTo;
    this.productID = result.productID;
    this.dentistID = result.dentistID;

    this.searchApplied = true;
    this.changeDetectorRef.detectChanges();
  }

  getGroupFilter(e) {
    this.groupFilter = e;
  }
}
