import { Location } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { CustomerService } from '../../customer/shared/customer.service';

@Component({
  selector: 'app-change-primary-modal',
  templateUrl: './change-primary-modal.component.html',
  styleUrls: ['./change-primary-modal.component.css'],
})
export class ChangePrimaryModalComponent implements OnInit {
  @Input() customerID;
  @Input() canEdit;
  @Input() addressModal = true;

  selectedCustomer = {};
  isPromoterOrAdmin = false;

  @Output() closeModal = new EventEmitter();

  mySuburb;
  selectedNumber: any;
  sessionType = 'guest';

  constructor(
    private location: Location,
    private customerService: CustomerService,
    private activeRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      this.customerID = data.customerID;
      this.addressModal = data.addressModal;
    }
  }

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((sessionType) => {
      if (sessionType) {
        this.sessionType = sessionType;
      }
      this.activeRoute.params.subscribe((params) => {
        this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
          this.isPromoterOrAdmin = res;
        });

        if (params['customerID']) {
          this.customerID = params['customerID'];
        }

        this.customerService.getCustomerDetails(this.customerID, {}, this.sessionType).subscribe((res) => {
          this.selectedCustomer = res;
        });
      });
    });
  }

  getPhoneLabels(selectedCustomer) {
    let result = [];
    if (selectedCustomer && selectedCustomer['phones.Labels']) {
      result = selectedCustomer['phones.Labels'].split(';');
    }
    return result;
  }

  getNumber(label) {
    if (label == 'Work') {
      return this.selectedCustomer['Phone.Work.Number'];
    } else if (label == 'Home') {
      return this.selectedCustomer['Phone.Home.Number'];
    } else if (label == 'Daytime') {
      return this.selectedCustomer['Phone.Daytime.Number'];
    } else if (label == 'Mobile') {
      return this.selectedCustomer['Phone.Mobile.Number'];
    } else if (label == 'Weekend') {
      return this.selectedCustomer['Phone.Weekend.Number'];
    } else {
      return null;
    }
  }

  getMobileLabels(selectedCustomer) {
    let result = [];
    if (selectedCustomer && selectedCustomer['mobiles.Labels']) {
      result = selectedCustomer['mobiles.Labels'].split(';');
    }
    return result;
  }

  getEmailLabels(selectedCustomer) {
    let result = [];
    if (selectedCustomer && selectedCustomer['emails.Labels']) {
      result = selectedCustomer['emails.Labels'].split(';');
    }
    return result;
  }

  getEmailAddress(label) {
    if (label == 'Business') {
      return this.selectedCustomer['email.Business'];
    } else if (label == 'Work') {
      return this.selectedCustomer['email.Work'];
    } else if (label == 'Home') {
      return this.selectedCustomer['email.Home'];
    } else if (label == 'Personal') {
      return this.selectedCustomer['email.Personal'];
    } else if (label == 'Relative') {
      return this.selectedCustomer['email.Relative'];
    } else {
      return null;
    }
  }

  getAddressLabels(selectedCustomer) {
    let result = [];
    if (selectedCustomer && selectedCustomer['addresses.Labels']) {
      result = selectedCustomer['addresses.Labels'].split(';');
    }
    return result;
  }

  getDiffAddress(label) {
    if (label == 'Physical') {
      return this.selectedCustomer['address.Physical.Calculated'];
    } else if (label == 'Work') {
      return this.selectedCustomer['address.Work.Calculated'];
    } else if (label == 'Home') {
      return this.selectedCustomer['address.Home.Calculated'];
    } else if (label == 'Postal') {
      return this.selectedCustomer['address.Postal.Calculated'];
    } else if (label == 'Holiday') {
      return this.selectedCustomer['address.Holiday.Calculated'];
    } else if (label == 'Relative') {
      return this.selectedCustomer['address.Relative.Calculated'];
    } else {
      return null;
    }
  }

  activatePrimaryNumber() {}

  cancel() {
    this.location.back(); // <-- go back to previous location on cancel
  }

  editContactDetails() {
    const payload = {
      selectedNumber: this.selectedCustomer['phones.Number'],
      selectedEmail: this.selectedCustomer['emails.Email'],
    };
    this.customerService.editCustomer(this.customerID, payload).subscribe((res) => {
      if (res && typeof res == 'object') {
        this.selectedCustomer = res;

        NotifyAppComponent.displayToast('success', 'Success!', 'Patients details have been updated');
      }
    });
  }

  editAddressDetails() {
    const payload = {
      selectedAddress: this.selectedCustomer['addresses.Calculated'],
    };
    this.customerService.editCustomer(this.customerID, payload).subscribe((res) => {
      if (res && typeof res == 'object') {
        this.selectedCustomer = res;

        NotifyAppComponent.displayToast('success', 'Success!', 'Patients details have been updated');
      }
    });
  }
}
