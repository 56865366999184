/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dental-view-product-invoice.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./dental-view-product-invoice.component";
var styles_DentalViewProductInvoiceComponent = [i0.styles];
var RenderType_DentalViewProductInvoiceComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DentalViewProductInvoiceComponent, data: {} });
export { RenderType_DentalViewProductInvoiceComponent as RenderType_DentalViewProductInvoiceComponent };
export function View_DentalViewProductInvoiceComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["dental-view-product works!"]))], null, null); }
export function View_DentalViewProductInvoiceComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ipv-dental-view-product-invoice", [], null, null, null, View_DentalViewProductInvoiceComponent_0, RenderType_DentalViewProductInvoiceComponent)), i1.ɵdid(1, 49152, null, 0, i2.DentalViewProductInvoiceComponent, [], null, null)], null, null); }
var DentalViewProductInvoiceComponentNgFactory = i1.ɵccf("ipv-dental-view-product-invoice", i2.DentalViewProductInvoiceComponent, View_DentalViewProductInvoiceComponent_Host_0, { invoiceAppliance: "invoiceAppliance" }, {}, []);
export { DentalViewProductInvoiceComponentNgFactory as DentalViewProductInvoiceComponentNgFactory };
