<button
  class="share-social-media"
  [url]="_url"
  [disabled]="isDisabled"
  [title]="_title"
  [description]="_post"
  [image]="_image"
  [autoSetMeta]="autoSetMeta"
  (opened)="openEvent()"
  (closed)="shareWindowClosed()"
  mat-raised-button
  color="accent"
  shareButton="{{ type }}"
  matTooltip="Copy Post & Share"
>
  {{ label }}
  <mat-icon class="{{ icon }}"></mat-icon>
</button>
