import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import * as _ from 'lodash';
import * as moment from 'moment';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { MessageService } from '../shared/message.service';
@Component({
  selector: 'desktop-preview',
  templateUrl: './desktop-preview.component.html',
  styleUrls: ['./desktop-preview.component.css'],
})
export class DesktopPreviewComponent implements OnInit {
  message;
  messageFilter = Settings.global['merchantFrontendLink'];

  cardID;

  sessionType;
  card;
  tableName;
  utils = new UtilsClass();
  merchant;
  timeNow = moment().format('HH:mm');
  close = new EventEmitter();
  merchantID;
  isPromoterOrAdmin = false;
  constructor(
    private authenticationService: AuthenticationService,
    private messageService: MessageService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data && data.message) {
      this.message = data.message;
    }
    if (data && data.cardID) {
      this.cardID = data.cardID;
    }
    if (data && data.merchantID) {
      this.merchantID = data.merchantID;
    }

    if (data && data.tableName) {
      this.tableName = data.tableName;
    }
    if (data && data.card) {
      this.card = data.card;
    }
  }
  ngOnInit() {
    if (this.messageFilter) {
      this.messageFilter = this.messageFilter.replace('http://', '');
      this.messageFilter = this.messageFilter.replace('https://', '');
    }

    this.authenticationService.getSessionType().subscribe((r) => {
      this.sessionType = r;
      this.authenticationService.isPromoterOrAdmin().subscribe((isPromoterOrAdmin) => {
        this.isPromoterOrAdmin = isPromoterOrAdmin;

        if (this.isPromoterOrAdmin == false || !this.merchantID) {
          this.authenticationService.getCurrentPractice().subscribe((r) => {
            if (r) {
              this.merchant = r;
            }
          });
        } else {
          this.authenticationService
            .getMerchantDetails(this.merchantID, { fields: 'TradingAs,ID' }, this.sessionType)
            .subscribe((r) => {
              if (r) {
                this.merchant = r;
              }
            });
        }
      });
      if (this.cardID && this.cardID != '00000000000000000000') {
        this.messageService
          .getCardDetails(
            this.cardID,
            this.tableName,
            { fields: 'ID,LastModified,FirstName,Name,MiddleName' },
            this.sessionType
          )
          .subscribe((res) => {
            if (res && res.ID) {
              this.card = res;
            }
          });
      }
    });

    if (!this.tableName && this.card && this.card['Card_TableName']) {
      this.tableName = this.card['Card_TableName'];
    }
  }

  chunkSubstr(str, size) {
    const numChunks = Math.ceil(str.length / size);
    const chunks = new Array(numChunks);

    for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
      chunks[i] = str.substr(o, size);
    }

    return chunks;
  }

  URLify(_string) {
    if (_string && this.utils.isContentHtml(_string) != true) {
      let string = _string;
      let urls = string.match(/(https?:\/\/[^\s]+)/g) || [];

      const localURLs = string.match(/(http?:\/\/[^\s]+)/g);

      if (localURLs && localURLs.length > 0) {
        urls = urls.concat(localURLs);
      }
      if (urls) {
        urls = _.uniq(urls);
        urls.forEach((url) => {
          const title = this.utils.getLinkTitle(url);

          if (title) {
            string = string
              .split(url)
              .join(
                '<a target="_blank"  style="margin-right:10px !important;" title="' +
                  title +
                  '"  class="inner-link" href="' +
                  url +
                  '"> ' +
                  title +
                  ' </a>'
              );
          } else {
            string = string
              .split(url)
              .join(
                '<a target="_blank"  style="margin-right:10px !important;" class="inner-link" href="' +
                  url +
                  '"> ' +
                  url +
                  ' </a>'
              );
          }
        });
      }

      string = string.replace(/\n/g, '<br>');
      string = string.replace(/   /g, '<br>');
      return string;
    } else if (_string && this.utils.isContentHtml(_string) == true) {
      let string = _string;

      string = string.replace(/\n/g, '<br>');
      string = string.replace(/   /g, '<br>');
      return string;
    }

    return _string;
  }

  closeEvent() {
    this.close.emit(true);
  }
}
