import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { environment } from 'environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AppStateService } from '../../../core/app-state/app-state.service';
import { UtilsService } from '../../../shared/services/utils.service';
import { DataResponse } from '../../../shared/types/data-response.type';
import { TreatmentPlan, TreatmentPlanQuery, TreatmentProduct, TreatmentProductQuery } from './treatment.type';

class TreatmentState {
  treatmentPlan: TreatmentPlan | Partial<TreatmentPlan>;
  treatmentProducts: Partial<TreatmentProduct>[] | TreatmentProduct[];
  isOmittedFilter: null | '0' | '1';
  labelItemCodeFilter: string;
  categoryIdFilter: string;
  typeIdFilter: string;
  brandIdFilter: string;
}

@Injectable()
export class TreatmentService extends ComponentStore<TreatmentState> {
  private baseUrl = environment.nodeUrl;

  constructor(
    private httpClient: HttpClient,
    private utilsService: UtilsService,
    private appStateService: AppStateService
  ) {
    super({
      treatmentPlan: null,
      treatmentProducts: [],
      isOmittedFilter: '0',
      labelItemCodeFilter: '',
      categoryIdFilter: undefined,
      typeIdFilter: undefined,
      brandIdFilter: undefined,
    });
  }

  getTreatmentPlan$<T extends TreatmentPlan | Partial<TreatmentPlan>>() {
    return this.select((state) => state.treatmentPlan) as Observable<T>;
  }

  setTreatmentPlan(treatmentPlan: TreatmentPlan | Partial<TreatmentPlan>) {
    this.patchState({ treatmentPlan });
  }

  fetchTreatmentPlan$<T extends TreatmentPlan | Partial<TreatmentPlan>>(payload: TreatmentPlanQuery['payload']) {
    return this.appStateService.getAppState$().pipe(
      switchMap((appState) => {
        const params = this.utilsService.getHttpParamsFromPayload(payload);

        const options = { params };

        const endpoint = (() => {
          const promoterRoute = 'treatment-plan/invitation/global';
          const merchantRoute = 'treatment-plan/invitation';
          const customerRoute = 'treatment-plan/invitation/customer';
          const publicRoute = 'treatment-plan/invitation/public';

          switch (appState.sessionType) {
            case 'admin':
              return promoterRoute;
            case 'promoter':
              return promoterRoute;
            case 'merchant':
              return merchantRoute;
            case 'merchant-admin':
              return merchantRoute;
            case 'customer':
              return customerRoute;
            default:
              return publicRoute;
          }
        })();

        const url = `${this.baseUrl}/${endpoint}/${payload.invitationID}`;

        return this.httpClient.get<DataResponse<TreatmentPlan>>(url, options).pipe(map((response) => response['data']));
      })
    );
  }

  getIsOmittedFilter$() {
    return this.select((state) => state.isOmittedFilter);
  }

  setIsOmittedFilter(isOmittedFilter: '0' | '1') {
    this.patchState({ isOmittedFilter });
  }

  getLabelItemCodeFilter$() {
    return this.select((state) => state.labelItemCodeFilter);
  }

  setLabelItemCodeFilter(labelItemCodeFilter: string) {
    this.patchState({ labelItemCodeFilter });
  }

  getCategoryIdFilter$() {
    return this.select((state) => state.categoryIdFilter);
  }

  setCategoryIdFilter(categoryIdFilter: string) {
    this.patchState({ categoryIdFilter });
  }

  getTypeIdFilter$() {
    return this.select((state) => state.typeIdFilter);
  }

  setTypeIdFilter(typeIdFilter: string) {
    this.patchState({ typeIdFilter });
  }

  getBrandIdFilter$() {
    return this.select((state) => state.brandIdFilter);
  }

  setBrandIdFilter(brandIdFilter: string) {
    this.patchState({ brandIdFilter });
  }

  getTreatmentProducts<T extends TreatmentProduct | Partial<TreatmentProduct>>(): T[] {
    return this.get().treatmentProducts as T[];
  }

  getTreatmentProducts$<T extends TreatmentProduct | Partial<TreatmentProduct>>() {
    return this.select((state) => state.treatmentProducts);
  }

  setTreatmentProducts(treatmentProducts: Partial<TreatmentProduct>[] | TreatmentProduct[]) {
    this.patchState({ treatmentProducts });
  }

  fetchTreatmentProducts$<T extends TreatmentProduct | Partial<TreatmentProduct>>(
    payload?: TreatmentProductQuery['payload']
  ): Observable<T[]> {
    return this.appStateService.getAppState$().pipe(
      switchMap((appState) => {
        const params = this.utilsService.getHttpParamsFromPayload(payload);

        const options = { params };

        const endpoint = appState.isPromoterOrAdmin ? 'treatment-product/global' : 'treatment-product';

        const url = `${this.baseUrl}/${endpoint}`;

        return this.httpClient.get<DataResponse<T[]>>(url, options).pipe(map((response) => response['data']));
      })
    );
  }

  getLocalTreatmentPlan(invitationID, body = {}, isPromoterOrAdmin = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        params = params.set(key, body[key]);
      }
    }

    options = {
      params,
    };

    if (isPromoterOrAdmin == true) {
      return this.httpClient
        .get(environment.nodeUrl + '/treatment-plan/local/global/' + invitationID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .get(environment.nodeUrl + '/treatment-plan/local/' + invitationID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  removeLocalTreatmentPlan(invitationID, index): Observable<any> {
    if (index !== null) {
      return this.httpClient
        .delete(environment.nodeUrl + '/treatment-plan/local/' + invitationID + '/' + index)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  setLocalTreatmentPlan(invitationID, body, isPromoterOrAdmin = false): Observable<any> {
    const index = body.index;

    if (isPromoterOrAdmin == true) {
      if (index !== null) {
        return this.httpClient
          .put(environment.nodeUrl + '/treatment-plan/local/global/' + invitationID + '/' + index, body)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else {
        return this.httpClient
          .put(environment.nodeUrl + '/treatment-plan/local/global/' + invitationID, body)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      }
    } else {
      if (index !== null) {
        return this.httpClient
          .put(environment.nodeUrl + '/treatment-plan/local/' + invitationID + '/' + index, body)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else {
        return this.httpClient
          .put(environment.nodeUrl + '/treatment-plan/local/' + invitationID, body)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      }
    }
  }

  viewInformedConsentInvitationDocument(invitationID, documentID, informedConsentID): Observable<any> {
    const body = {
      documentID,
      informedConsentID,
    };

    return this.httpClient
      .put(environment.nodeUrl + '/informed-consent-document/view/invitation/consent/' + invitationID, body)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getTreatmentPlanCustomerByInvitationID(invitationID, payload = {}, sessionType): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.httpClient
        .get(environment.nodeUrl + '/treatment-plan/invitation/global/' + invitationID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
      return this.httpClient
        .get(environment.nodeUrl + '/treatment-plan/invitation/' + invitationID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'customer' || sessionType == 'consumer') {
      return this.httpClient
        .get(environment.nodeUrl + '/treatment-plan/invitation/customer/' + invitationID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .get(environment.nodeUrl + '/treatment-plan/invitation/public/' + invitationID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  /**
   * @description
   * Create a new treatment treatment
   * @param payload
   */
  createAdvancedTreatment(payload) {
    return this.httpClient
      .post(environment.nodeUrl + '/treatment/advanced/' + payload.id, payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  createSimpleTreatment(payload) {
    return this.httpClient
      .post(environment.nodeUrl + '/treatment/simple/' + payload.id, payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  proceedWithAdvancedTreatment(invitationID) {
    return this.httpClient.post(environment.nodeUrl + '/treatment/advanced/proceed/' + invitationID, {}).pipe(
      catchError((error: any): Observable<any> => {
        return throwError(error);
      })
    );
  }

  proceedWithSimpleTreatment(invitationID) {
    return this.httpClient.post(environment.nodeUrl + '/treatment/simple/proceed/' + invitationID, {}).pipe(
      catchError((error: any): Observable<any> => {
        return throwError(error);
      })
    );
  }

  /**
   * @description
   * Get treatment details
   * @param id
   */
  getAdvancedTreatment(id) {
    return this.httpClient
      .get(environment.nodeUrl + '/treatment/advanced/' + id)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getSimpleTreatment(id) {
    return this.httpClient
      .get(environment.nodeUrl + '/treatment/simple/' + id)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  /**
   * @description
   * Remove simple treatment
   * @param id
   */
  deleteTreatment(payload) {
    let params: HttpParams = new HttpParams();
    let options = {};
    if (payload.index) {
      params = params.set('index', payload.index);
      options = {
        params,
      };
    }
    return this.httpClient
      .delete(environment.nodeUrl + '/treatment/advanced/' + payload.id, options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  removeTreatmentDocument(id, data) {
    return this.httpClient.put(`${environment.nodeUrl}/document/invitation/${id}`, data);
  }

  editTreatmentDocumentGroup(ids, _payload = {}, isPromoterOrAdmin = false) {
    let _ids;

    const payload = _payload || {};

    if (Array.isArray(ids)) {
      _ids = ids.join(',');
    }

    payload['ids'] = _ids;

    if (isPromoterOrAdmin == true) {
      return this.httpClient
        .put(environment.nodeUrl + '/treatment-document/user/group/global', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .put(environment.nodeUrl + '/treatment-document/user/group', payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getTreatmentDocumentListPOST(payload, isPromoterOrAdmin = false): Observable<any> {
    if (isPromoterOrAdmin == true) {
      return this.httpClient
        .post(environment.nodeUrl + '/treatment-document/list/global', payload)
        .pipe(map((res: HttpResponse<any>) => res));
    } else {
      return this.httpClient
        .post(environment.nodeUrl + '/treatment-document/list', payload)
        .pipe(map((res: HttpResponse<any>) => res));
    }
  }

  getTreatmentDocumentList(payload, isPromoterOrAdmin = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (isPromoterOrAdmin == true) {
      return this.httpClient
        .get(environment.nodeUrl + '/treatment-document/global', options)
        .pipe(map((res: HttpResponse<any>) => res));
    } else {
      return this.httpClient
        .get(environment.nodeUrl + '/treatment-document', options)
        .pipe(map((res: HttpResponse<any>) => res));
    }
  }

  editTreatmentDocumentGlobal(id, payload): Observable<any> {
    return this.httpClient
      .put(environment.nodeUrl + '/treatment-document/' + id, payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  deleteTreatmentDocumentGlobal(id): Observable<any> {
    return this.httpClient
      .delete(environment.nodeUrl + '/treatment-document/' + id)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  deleteTreatmentDocumentGroupGlobal(ids): Observable<any> {
    return this.httpClient
      .delete(environment.nodeUrl + '/treatment-document/group/' + ids)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  deleteTreatmentCategoryGlobal(id): Observable<any> {
    return this.httpClient
      .delete(environment.nodeUrl + '/treatment-category/' + id)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  deleteTreatmentCategoryGroupGlobal(ids): Observable<any> {
    return this.httpClient
      .delete(environment.nodeUrl + '/treatment-category/group/' + ids)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  editTreatmentDocument(id, payload, isPromoterOrAdmin = false): Observable<any> {
    if (isPromoterOrAdmin == true) {
      return this.httpClient
        .put(environment.nodeUrl + '/treatment-document/user/global/' + id, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .put(environment.nodeUrl + '/treatment-document/user/' + id, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  /**
   * @description
   * Get simple treatment categories
   * @param('body')
   */
  getTreatmentCategoryList(body, isPromoterOrAdmin = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        params = params.set(key, body[key]);
      }
    }

    options = {
      params,
    };

    if (isPromoterOrAdmin == true) {
      return this.httpClient
        .get(environment.nodeUrl + '/treatment-category/global', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .get(environment.nodeUrl + '/treatment-category/', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  createTreatmentDocument(payload = {}) {
    return this.httpClient
      .post(environment.nodeUrl + '/treatment-document', payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  // Treatment group new service //

  getCategoryList(payload, isPromoterOrAdmin = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }

    if (isPromoterOrAdmin == true) {
      return this.httpClient
        .get(`${environment.nodeUrl}/treatment-category/global`, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .get(`${environment.nodeUrl}/treatment-category`, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getCategoryDetails(ID, payload = {}): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }
    return this.httpClient
      .get(`${environment.nodeUrl}/treatment-category/${ID}`, options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  editCategoryGlobal(ID, payload): Observable<any> {
    return this.httpClient
      .put(`${environment.nodeUrl}/treatment-category/${ID}`, payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  editTreatmentCategoryGlobal(ID, payload): Observable<any> {
    return this.httpClient
      .put(`${environment.nodeUrl}/treatment-category/${ID}`, payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  createTreatmentCategoryGlobal(payload): Observable<any> {
    return this.httpClient
      .post(`${environment.nodeUrl}/treatment-category/`, payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getTreatmentTypeList(payload, isPromoterOrAdmin = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }
    if (isPromoterOrAdmin == true) {
      return this.httpClient
        .get(`${environment.nodeUrl}/treatment-type/global`, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .get(`${environment.nodeUrl}/treatment-type`, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getTypeDetails(ID, payload = {}): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }
    return this.httpClient
      .get(`${environment.nodeUrl}/treatment-type/${ID}`, options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  editTypeGlobal(ID, payload): Observable<any> {
    return this.httpClient
      .put(`${environment.nodeUrl}/treatment-type/${ID}`, payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  createTypeGlobal(payload): Observable<any> {
    return this.httpClient
      .post(`${environment.nodeUrl}/treatment-type/`, payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  editTreatmentCategoryGroupGlobal(payload = {}) {
    return this.httpClient
      .put(environment.nodeUrl + '/treatment-category/group', payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  editTreatmentTypeGroupGlobal(payload = {}) {
    return this.httpClient
      .put(environment.nodeUrl + '/treatment-type/group', payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getSupplierDetails(ID, payload = null, isPromoterOrAdmin = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }

    if (isPromoterOrAdmin == true) {
      return this.httpClient
        .get(environment.nodeUrl + '/beneficiary/global/' + ID, options)
        .pipe(map((res: Response) => res['data']));
    } else {
      return this.httpClient
        .get(environment.nodeUrl + '/beneficiary/' + ID, options)
        .pipe(map((res: Response) => res['data']));
    }
  }

  getSupplierList(payload, isPromoterOrAdmin = true): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };
    if (isPromoterOrAdmin == true) {
      return this.httpClient
        .get(environment.nodeUrl + '/beneficiary/global/', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .get(environment.nodeUrl + '/beneficiary/', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getProductList(payload, isPromoterOrAdmin = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }
    if (isPromoterOrAdmin == true) {
      return this.httpClient
        .get(`${environment.nodeUrl}/treatment-product-global`, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .get(`${environment.nodeUrl}/treatment-product`, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getProductDetails(ID, payload = {}): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }
    return this.httpClient
      .get(`${environment.nodeUrl}/treatment-product-global/${ID}`, options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  editProduct(ID, payload): Observable<any> {
    return this.httpClient
      .put(`${environment.nodeUrl}/treatment-product-global/${ID}`, payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  createProduct(payload): Observable<any> {
    return this.httpClient
      .post(`${environment.nodeUrl}/treatment-product-global/`, payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  addDocumentTreatmentProductGroup(ids, _payload = {}) {
    let _ids;

    const payload = _payload || {};

    if (Array.isArray(ids)) {
      _ids = ids.join(',');
    }

    payload['ids'] = _ids;

    return this.httpClient
      .post(environment.nodeUrl + '/treatment-product/document/group', payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  activeTreatmentProductGroup(ids, _payload = {}, isPromoterOrAdmin = false) {
    let _ids;

    const payload = _payload || {};

    if (Array.isArray(ids)) {
      _ids = ids.join(',');
    }

    payload['ids'] = _ids;

    return this.httpClient
      .put(environment.nodeUrl + '/treatment-product-global/active/group', payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  /// new brand logic

  getTreatmentDocumentDetail(id, body = {}, isPromoterOrAdmin = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        params = params.set(key, body[key]);
      }
    }

    options = {
      params,
    };

    if (isPromoterOrAdmin == true) {
      return this.httpClient
        .get(environment.nodeUrl + '/treatment-document/global/' + id, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .get(environment.nodeUrl + '/treatment-document/' + id, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getTreatmentCategoryDetails(id, body = {}) {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        params = params.set(key, body[key]);
      }
    }

    options = {
      params,
    };
    return this.httpClient
      .get(environment.nodeUrl + '/treatment-category/' + id, options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getTreatmentTypeDetails(id, body = {}) {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        params = params.set(key, body[key]);
      }
    }

    options = {
      params,
    };

    return this.httpClient
      .get(environment.nodeUrl + '/treatment-type/' + id, options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getTreatmentBrandDetails(id, body = {}, isPromoterOrAdmin = false) {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        params = params.set(key, body[key]);
      }
    }

    options = {
      params,
    };
    if (isPromoterOrAdmin == true) {
      return this.httpClient
        .get(environment.nodeUrl + '/treatment-brand/global/' + id, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .get(environment.nodeUrl + '/treatment-brand/' + id, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getTreatmentBrandDetailsGlobal(ID, payload = {}): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }
    return this.httpClient
      .get(`${environment.nodeUrl}/treatment-brand-global/${ID}`, options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getTreatmentBrandList(payload, isPromoterOrAdmin = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }
    if (isPromoterOrAdmin == true) {
      return this.httpClient
        .get(`${environment.nodeUrl}/treatment-brand/global`, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .get(`${environment.nodeUrl}/treatment-brand`, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getTreatmentBrandListGlobal(payload): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }
    return this.httpClient
      .get(`${environment.nodeUrl}/treatment-brand-global`, options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  createTreatmentBrandGlobal(payload): Observable<any> {
    return this.httpClient
      .post(`${environment.nodeUrl}/treatment-brand-global`, payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  editTreatmentBrand(ID, payload, isPromoterOrAdmin = false): Observable<any> {
    if (isPromoterOrAdmin == true) {
      return this.httpClient
        .put(`${environment.nodeUrl}/treatment-brand/global/` + ID, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .put(`${environment.nodeUrl}/treatment-brand/` + ID, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  editTreatmentBrandGroup(payload, isPromoterOrAdmin = false): Observable<any> {
    if (isPromoterOrAdmin == true) {
      return this.httpClient
        .put(`${environment.nodeUrl}/treatment-brand/group/global`, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .put(`${environment.nodeUrl}/treatment-brand/group`, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  editTreatmentBrandGlobal(ID, payload): Observable<any> {
    return this.httpClient
      .put(`${environment.nodeUrl}/treatment-brand-global/` + ID, payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  editTreatmentBrandGroupGlobal(payload): Observable<any> {
    return this.httpClient
      .put(`${environment.nodeUrl}/treatment-brand-global/group`, payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getProductCatalogue(ID, payload = {}) {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }
    return this.httpClient
      .get(`${environment.nodeUrl}/treatment-product-global/catalogue-picture/` + ID, options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getProductLogo(ID, payload = {}) {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }
    return this.httpClient
      .get(`${environment.nodeUrl}/treatment-product-global/logo-picture/` + ID, options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getProductCatalogueStreamLink(ID, LastModified = null) {
    if (
      LastModified &&
      LastModified != '' &&
      LastModified != undefined &&
      LastModified != 'null' &&
      LastModified != 'undefined '
    ) {
      return `${environment.nodeUrl}/treatment-product-global/catalogue-picture-stream-cached/${LastModified}/` + ID;
    } else if (ID) {
      return `${environment.nodeUrl}/treatment-product-global/catalogue-picture-stream/` + ID;
    }
    return null;
  }

  getProductLogoStreamLink(ID, LastModified = null) {
    if (
      LastModified &&
      LastModified != '' &&
      LastModified != undefined &&
      LastModified != 'null' &&
      LastModified != 'undefined '
    ) {
      return `${environment.nodeUrl}/treatment-product-global/logo-picture-stream-cached/${LastModified}/` + ID;
    } else if (ID) {
      return `${environment.nodeUrl}/treatment-product-global/logo-picture-stream/` + ID;
    }
    return null;
  }

  getTypeLogo(ID, payload = {}) {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }
    return this.httpClient
      .get(`${environment.nodeUrl}/treatment-type/logo-picture/` + ID, options)
      .pipe(map((res: Response) => res['data']));
  }

  getTypeLogoStreamLink(ID, LastModified = null) {
    if (
      LastModified &&
      LastModified != '' &&
      LastModified != undefined &&
      LastModified != 'null' &&
      LastModified != 'undefined '
    ) {
      return `${environment.nodeUrl}/treatment-type/logo-picture-stream-cached/${LastModified}/` + ID;
    } else if (ID) {
      return `${environment.nodeUrl}/treatment-type/logo-picture-stream/` + ID;
    }
    return null;
  }

  getCategoryLogo(ID, payload = {}) {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }

    return this.httpClient
      .get(`${environment.nodeUrl}/treatment-category/logo-picture/` + ID, options)
      .pipe(map((res: Response) => res['data']));
  }

  getCategoryLogoStreamLink(ID, LastModified = null) {
    if (
      LastModified &&
      LastModified != '' &&
      LastModified != undefined &&
      LastModified != 'null' &&
      LastModified != 'undefined '
    ) {
      return `${environment.nodeUrl}/treatment-category/logo-picture-stream-cached/${LastModified}/` + ID;
    } else if (ID) {
      return `${environment.nodeUrl}/treatment-category/logo-picture-stream/` + ID;
    }
    return null;
  }

  getBrandLogo(ID, payload = {}) {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }
    return this.httpClient
      .get(`${environment.nodeUrl}/treatment-brand-global/logo-picture/` + ID, options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getBrandLogoStreamLink(ID, LastModified = null) {
    if (
      LastModified &&
      LastModified != '' &&
      LastModified != undefined &&
      LastModified != 'null' &&
      LastModified != 'undefined '
    ) {
      return `${environment.nodeUrl}/treatment-brand-global/logo-picture-stream-cached/${LastModified}/` + ID;
    } else if (ID) {
      return `${environment.nodeUrl}/treatment-brand-global/logo-picture-stream/` + ID;
    }
    return null;
  }

  addDocumentTreatmentBrandGroupGlobal(payload): Observable<any> {
    return this.httpClient
      .post(`${environment.nodeUrl}/treatment-brand-global/document/group`, payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  addDocumentTreatmentBrandGlobal(id, payload): Observable<any> {
    return this.httpClient
      .post(`${environment.nodeUrl}/treatment-brand-global/document/` + id, payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  addDocumentTreatmentTypeGroupGlobal(payload): Observable<any> {
    return this.httpClient
      .post(`${environment.nodeUrl}/treatment-type/document/group`, payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  addDocumentTreatmentCategoryGroupGlobal(payload): Observable<any> {
    return this.httpClient
      .post(`${environment.nodeUrl}/treatment-category/document/group`, payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getTreatmentProductDetails(id, body = {}, isPromoterOrAdmin = false) {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        params = params.set(key, body[key]);
      }
    }

    options = {
      params,
    };
    if (isPromoterOrAdmin == true) {
      return this.httpClient
        .get(environment.nodeUrl + '/treatment-product/global/' + id, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .get(environment.nodeUrl + '/treatment-product/' + id, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getTreatmentProductDetailsGlobal(ID, payload = {}): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }
    return this.httpClient
      .get(`${environment.nodeUrl}/treatment-product-global/${ID}`, options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getTreatmentProductList(payload, isPromoterOrAdmin = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }
    if (isPromoterOrAdmin == true) {
      return this.httpClient
        .get(`${environment.nodeUrl}/treatment-product/global`, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .get(`${environment.nodeUrl}/treatment-product`, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getTreatmentProductListGlobal(payload): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }
    return this.httpClient
      .get(`${environment.nodeUrl}/treatment-product-global`, options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  createTreatmentProductGlobal(payload): Observable<any> {
    return this.httpClient
      .post(`${environment.nodeUrl}/treatment-product-global`, payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  editTreatmentProductDocument(ID, payload, isPromoterOrAdmin = false): Observable<any> {
    if (isPromoterOrAdmin == true) {
      return this.httpClient
        .put(`${environment.nodeUrl}/treatment-product/document/global/` + ID, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .put(`${environment.nodeUrl}/treatment-product/document/` + ID, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  editTreatmentProduct(ID, payload, isPromoterOrAdmin = false): Observable<any> {
    if (isPromoterOrAdmin == true) {
      return this.httpClient
        .put(`${environment.nodeUrl}/treatment-product/global/` + ID, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .put(`${environment.nodeUrl}/treatment-product/` + ID, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  editTreatmentProductGroup(payload, isPromoterOrAdmin = false): Observable<any> {
    if (isPromoterOrAdmin == true) {
      return this.httpClient
        .put(`${environment.nodeUrl}/treatment-product/group/global`, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .put(`${environment.nodeUrl}/treatment-product/group`, payload)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  editTreatmentProductGlobal(ID, payload): Observable<any> {
    return this.httpClient
      .put(`${environment.nodeUrl}/treatment-product-global/` + ID, payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  editTreatmentProductGroupGlobal(payload): Observable<any> {
    return this.httpClient
      .put(`${environment.nodeUrl}/treatment-product-global/group`, payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  addDocumentTreatmentProductGroupGlobal(payload): Observable<any> {
    return this.httpClient
      .post(`${environment.nodeUrl}/treatment-product-global/document/group`, payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  addDocumentTreatmentProductGlobal(id, payload): Observable<any> {
    return this.httpClient
      .post(`${environment.nodeUrl}/treatment-product-global/document/` + id, payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getTreatmentProductStatistics(ID, payload, isPromoterOrAdmin = false, isHidden = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }
    if (isHidden == true) {
      if (isPromoterOrAdmin == true) {
        return this.httpClient
          .get(`${environment.nodeUrl}/treatment-product-global/statistics-hidden/global/` + ID, options)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else {
        return this.httpClient
          .get(`${environment.nodeUrl}/treatment-product-global/statistics-hidden/` + ID, options)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      }
    } else {
      if (isPromoterOrAdmin == true) {
        return this.httpClient
          .get(`${environment.nodeUrl}/treatment-product-global/statistics/global/` + ID, options)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else {
        return this.httpClient
          .get(`${environment.nodeUrl}/treatment-product-global/statistics/` + ID, options)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      }
    }
  }

  getTreatmentBrandStatistics(ID, payload, isPromoterOrAdmin = false, isHidden = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }
    if (isHidden == true) {
      if (isPromoterOrAdmin == true) {
        return this.httpClient
          .get(`${environment.nodeUrl}/treatment-brand-global/statistics-hidden/global/` + ID, options)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else {
        return this.httpClient
          .get(`${environment.nodeUrl}/treatment-brand-global/statistics-hidden/` + ID, options)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      }
    } else {
      if (isPromoterOrAdmin == true) {
        return this.httpClient
          .get(`${environment.nodeUrl}/treatment-brand-global/statistics/global/` + ID, options)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else {
        return this.httpClient
          .get(`${environment.nodeUrl}/treatment-brand-global/statistics/` + ID, options)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      }
    }
  }

  getTreatmentTypeStatistics(ID, payload, isPromoterOrAdmin = false, isHidden = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }
    if (isHidden == true) {
      if (isPromoterOrAdmin == true) {
        return this.httpClient
          .get(`${environment.nodeUrl}/treatment-type/statistics-hidden/global/` + ID, options)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else {
        return this.httpClient
          .get(`${environment.nodeUrl}/treatment-type/statistics-hidden/` + ID, options)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      }
    } else {
      if (isPromoterOrAdmin == true) {
        return this.httpClient
          .get(`${environment.nodeUrl}/treatment-type/statistics/global/` + ID, options)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      } else {
        return this.httpClient
          .get(`${environment.nodeUrl}/treatment-type/statistics/` + ID, options)
          .pipe(map((res: HttpResponse<any>) => res['data']));
      }
    }
  }

  getTreatmentCategoryStatistics(ID, payload, isPromoterOrAdmin = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }
    if (isPromoterOrAdmin == true) {
      return this.httpClient
        .get(`${environment.nodeUrl}/treatment-category/statistics/global/` + ID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .get(`${environment.nodeUrl}/treatment-category/statistics/` + ID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  importDocumentTreatmentProduct(productID, ids, _payload = {}) {
    let _ids;
    const payload = _payload || {};
    if (Array.isArray(ids)) {
      _ids = ids.join(',');
    }
    payload['ids'] = _ids;

    return this.httpClient
      .post(environment.nodeUrl + '/treatment-product-global/document/import/' + productID, payload)
      .pipe(map((res: Response) => res['data']));
  }

  importDocumentTreatmentProductGroup(targetIDs, ids, _payload = {}) {
    let _ids;
    let _targetIDs;
    const payload = _payload || {};
    if (Array.isArray(ids)) {
      _ids = ids.join(',');
    }
    if (Array.isArray(targetIDs)) {
      _targetIDs = targetIDs.join(',');
    }
    payload['ids'] = _ids;
    payload['targetIDs'] = _targetIDs;
    return this.httpClient
      .post(environment.nodeUrl + '/treatment-product-global/document/import/group', payload)
      .pipe(map((res: Response) => res['data']));
  }

  importDocumentTreatmentBrand(brandID, ids, _payload = {}) {
    let _ids;
    const payload = _payload || {};
    if (Array.isArray(ids)) {
      _ids = ids.join(',');
    }
    payload['ids'] = _ids;
    return this.httpClient
      .post(environment.nodeUrl + '/treatment-brand-global/document/import/' + brandID, payload)
      .pipe(map((res: Response) => res['data']));
  }

  importDocumentTreatmentBrandGroup(targetIDs, ids, _payload = {}) {
    let _ids;
    let _targetIDs;
    const payload = _payload || {};
    if (Array.isArray(ids)) {
      _ids = ids.join(',');
    }
    if (Array.isArray(targetIDs)) {
      _targetIDs = targetIDs.join(',');
    }
    payload['ids'] = _ids;
    payload['targetIDs'] = _targetIDs;
    return this.httpClient
      .post(environment.nodeUrl + '/treatment-brand-global/document/import/group', payload)
      .pipe(map((res: Response) => res['data']));
  }

  importDocumentTreatmentType(typeID, ids, _payload = {}) {
    let _ids;
    const payload = _payload || {};
    if (Array.isArray(ids)) {
      _ids = ids.join(',');
    }
    payload['ids'] = _ids;
    return this.httpClient
      .post(environment.nodeUrl + '/treatment-type/document/import/' + typeID, payload)
      .pipe(map((res: Response) => res['data']));
  }

  importDocumentTreatmentTypeGroup(targetIDs, ids, _payload = {}) {
    let _ids;
    let _targetIDs;
    const payload = _payload || {};
    if (Array.isArray(ids)) {
      _ids = ids.join(',');
    }
    if (Array.isArray(targetIDs)) {
      _targetIDs = targetIDs.join(',');
    }
    payload['ids'] = _ids;
    payload['targetIDs'] = _targetIDs;
    return this.httpClient
      .post(environment.nodeUrl + '/treatment-type/document/import/group', payload)
      .pipe(map((res: Response) => res['data']));
  }

  importDocumentTreatmentCategory(categoryID, ids, _payload = {}) {
    let _ids;
    const payload = _payload || {};
    if (Array.isArray(ids)) {
      _ids = ids.join(',');
    }
    payload['ids'] = _ids;
    return this.httpClient
      .post(environment.nodeUrl + '/treatment-category/document/import/' + categoryID, payload)
      .pipe(map((res: Response) => res['data']));
  }

  importDocumentTreatmentCategoryGroup(targetIDs, ids, _payload = {}) {
    let _ids;
    let _targetIDs;
    const payload = _payload || {};
    if (Array.isArray(ids)) {
      _ids = ids.join(',');
    }
    if (Array.isArray(targetIDs)) {
      _targetIDs = targetIDs.join(',');
    }
    payload['ids'] = _ids;
    payload['targetIDs'] = _targetIDs;
    return this.httpClient
      .post(environment.nodeUrl + '/treatment-category/document/import/group', payload)
      .pipe(map((res: Response) => res['data']));
  }

  getTreatmentEPList(payload, isPromoterOrAdmin = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }
    if (isPromoterOrAdmin == true) {
      return this.httpClient
        .get(`${environment.nodeUrl}/treatment-ep/global`, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .get(`${environment.nodeUrl}/treatment-ep`, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }
}
