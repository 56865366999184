import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contract-list-active',
  templateUrl: './contract-list-active.component.html',
  styleUrls: ['./contract-list-active.component.css'],
})
export class ContractListActiveComponent implements OnInit {
  activeContracts = true;

  ngOnInit() {}
}
