/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tooth-delete.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i4 from "@angular/material/icon";
import * as i5 from "./tooth-delete.component";
var styles_ToothDeleteComponent = [i0.styles];
var RenderType_ToothDeleteComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ToothDeleteComponent, data: {} });
export { RenderType_ToothDeleteComponent as RenderType_ToothDeleteComponent };
function View_ToothDeleteComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, ":svg:path", [], [[1, "d", 0]], null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null)], function (_ck, _v) { var currVal_1 = _v.context.$implicit.class; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.d; _ck(_v, 0, 0, currVal_0); }); }
function View_ToothDeleteComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["class", "tooth-number"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tooth.toothNumber; _ck(_v, 1, 0, currVal_0); }); }
export function View_ToothDeleteComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "teethShapDeleted text-center animated fadeInDown"], ["val", "42"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectTooth() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "mat-icon", [["class", "close mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i3.View_MatIcon_0, i3.RenderType_MatIcon)), i1.ɵdid(2, 9158656, null, 0, i4.MatIcon, [i1.ElementRef, i4.MatIconRegistry, [8, null], [2, i4.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["cancel"])), (_l()(), i1.ɵeld(4, 0, null, null, 7, ":svg:svg", [["data-name", "Layer 1"], ["id", "Layer_1"], ["viewBox", "0 0 150.58 247"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, ":svg:defs", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, ":svg:style", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" .cls-1 { fill: #fff; } "])), (_l()(), i1.ɵeld(8, 0, null, null, 1, ":svg:title", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["teeth"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ToothDeleteComponent_1)), i1.ɵdid(11, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ToothDeleteComponent_2)), i1.ɵdid(13, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_2 = _co.toothShape; _ck(_v, 11, 0, currVal_2); var currVal_3 = !_co.tooth.hideNumber; _ck(_v, 13, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).inline; var currVal_1 = (((i1.ɵnov(_v, 2).color !== "primary") && (i1.ɵnov(_v, 2).color !== "accent")) && (i1.ɵnov(_v, 2).color !== "warn")); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_ToothDeleteComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-tooth-delete", [], null, null, null, View_ToothDeleteComponent_0, RenderType_ToothDeleteComponent)), i1.ɵdid(1, 114688, null, 0, i5.ToothDeleteComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ToothDeleteComponentNgFactory = i1.ɵccf("app-tooth-delete", i5.ToothDeleteComponent, View_ToothDeleteComponent_Host_0, { tooth: "tooth", isAdult: "isAdult" }, { select: "select" }, []);
export { ToothDeleteComponentNgFactory as ToothDeleteComponentNgFactory };
