/// <reference types="@types/googlemaps" />

import { GoogleMapsAPIWrapper, MapsAPILoader, MouseEvent } from '@agm/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  NgZone,
  OnInit,
  Optional,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'environments/environment';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ClientDetails } from '../../types/client-details';
import { NotifyAppComponent } from '../../types/notify-app-component';
import { UtilsClass } from '../../types/utils/utils.class';

declare var google: any;

// interface for circle
class marker {
  lat: number;
  lng: number;
  label?: string;
  draggable: boolean;
  content?: string;
  isShown: boolean;
  icon: string;
}

@Component({
  selector: 'app-map-multiple-view',
  templateUrl: './map-multiple-view.component.html',
  styleUrls: ['./map-multiple-view.component.css'],
  animations: [
    trigger('simpleFadeAnimation', [
      state('in', style({ opacity: 1, height: '*' })),
      transition(':enter', [style({ opacity: 0, height: 0 }), animate(300)]),
      transition(':leave', animate(300, style({ opacity: 0, height: 0 }))),
    ]),
  ],
})
export class MapMultipleViewComponent implements OnInit {
  @Input()
  displayActions = true;

  @Input()
  filterMultiContainValue = null;

  failed = [];

  @Input()
  filterMultiContainAttributes = null;

  @Input()
  filterWithoutMultiAttributes = null;

  @Input()
  filterWithoutMultiValue = null;

  @Input()
  filterMultiAttributes = null;

  @Input()
  filterMultiValues = null;

  mapCounter = 0;

  @Input()
  defaultIcon = 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/marker.png';

  profileMarker = 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/profil-marker.png';
  practiceMarker =
    'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/practice-marker.png';
  userMarker = 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/user-marker.png';

  @Input()
  title = 'Results';

  @Input()
  description = 'Click on marker for more details .';

  @Input()
  latitudeAttribute = 'Address.Latitude';

  @Input()
  longitudeAttribute = 'Address.Longitude';

  @Input()
  showCustomAction = true;

  @Input()
  streetNameAttribute = 'addresses.Street Name';

  @Input()
  streetNumberAttribute = 'addresses.Street Nr';

  @Input()
  unitNumberAttribute = 'addresses.Unit';

  @Input()
  stateAttribute = 'addresses.State';

  @Input()
  suburbAttribute = 'addresses.Suburb';

  @Input()
  streetTypeAttribute = 'addresses.Street Type';

  @Input()
  countryAttribute = 'addresses.Country.Code';

  @Input()
  postCodeAttribute = 'addresses.Postcode';

  @Input()
  markerTitleAttribute = 'CalculatedName';

  @Input()
  markerDescriptionAttribute = 'addresses.Calculated';

  @Input()
  dataArray = [];

  @Input()
  dataArayExtra = [];

  @Input()
  isAction = false;

  @Input()
  actionIcon = 'format_list_bulleted';

  @Input()
  actionLabel = 'List';

  @Input()
  selectedItem = new EventEmitter();

  @Output()
  getAction = new EventEmitter();

  @Input()
  displayHeaderBar = true;

  isModal = false;

  geocoder;

  country = 'AU';

  // search

  @Input()
  hasMapSearch = true;

  @ViewChild('search', { static: false }) searchElementRef: ElementRef;

  address: string;

  latitude: number;
  longitude: number;

  //  circle

  @Input()
  allowRadius = false;

  @Input()
  showRadius = false;

  @Input()
  radiusDraggable = true;

  @Input()
  radius = 5000;

  radiusLat = 0;
  radiusLong = 0;

  markers: marker[] = [];

  // --------------------------

  positions = [];

  center: any = {
    Latitude: null,
    Longitude: null,
  };

  profileAddress;
  practiceAddress;
  currentPossition;

  @Input()
  addMarker = new EventEmitter();

  @Input()
  resetPositions = new EventEmitter();

  @Output()
  close = new EventEmitter();

  isAddCoordinate = false;

  @Output()
  addCoordinate = new EventEmitter();

  @Output()
  getSelected = new EventEmitter();

  util = new UtilsClass();

  @Input()
  zoom = 12;

  isMapLoaded = false;
  addMarkerSubscription;
  clearTimeOut = [];

  selectedIndex;

  isNightMode = JSON.parse(localStorage.getItem('isDark'));

  @Input()
  isLightMode = false;

  styles;

  isLogged = false;

  constructor(
    private authenticationSerivce: AuthenticationService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private mapsApiLoader: MapsAPILoader,
    public gMaps: GoogleMapsAPIWrapper,
    private ngZone: NgZone
  ) {
    if (data) {
      if (data.dataArray && data.dataArray.length > 0) {
        this.dataArray = data.dataArray;
        this.isModal = true;
      }
      if (data.addMarker) {
        this.addMarker = data.addMarker;
        this.isModal = true;
      }

      if (data.resetPositions) {
        this.resetPositions = data.resetPositions;
        this.isModal = true;
      }

      if (data.selectedItem) {
        this.selectedItem = data.selectedItem;
      }

      if (data.streetNameAttribute) {
        this.streetNameAttribute = data.streetNameAttribute;
      }
      if (data.streetNumberAttribute) {
        this.streetNumberAttribute = data.streetNumberAttribute;
      }
      if (data.unitNumberAttribute) {
        this.unitNumberAttribute = data.unitNumberAttribute;
      }
      if (data.stateAttribute) {
        this.stateAttribute = data.stateAttribute;
      }
      if (data.suburbAttribute) {
        this.suburbAttribute = data.suburbAttribute;
      }
      if (data.streetTypeAttribute) {
        this.streetTypeAttribute = data.streetTypeAttribute;
      }
      if (data.countryAttribute) {
        this.countryAttribute = data.countryAttribute;
      }
      if (data.postCodeAttribute) {
        this.postCodeAttribute = data.postCodeAttribute;
      }
      if (data.markerTitleAttribute) {
        this.markerTitleAttribute = data.markerTitleAttribute;
      }

      if (data.markerDescriptionAttribute) {
        this.markerDescriptionAttribute = data.markerDescriptionAttribute;
      }

      if (data.actionIcon) {
        this.actionIcon = data.actionIcon;
      }
      if (data.actionLabel) {
        this.actionLabel = data.actionLabel;
      }

      if (data.isAction != null) {
        this.isAction = data.isAction;
      }
    }
  }

  ngOnInit() {
    this.authenticationSerivce.isLogin().subscribe((res) => {
      this.isLogged = res;

      this.resetPositions.subscribe((res) => {
        this.reset();
      });

      this.selectedItem.subscribe((res) => {
        this.selectItem(res);
      });

      this.addMarkerSubscription = this.addMarker.subscribe((res) => {
        if (res) {
          this.setLoadItemMarker(res);
        }
      });

      this.mapsApiLoader.load().then(() => {
        // required for search bar
        this.setCurrentLocation();
        this.geocoder = new google.maps.Geocoder();

        this.isMapLoaded = true;

        this.authenticationSerivce.isLogin().subscribe((res) => {
          if (res && res == true) {
            this.authenticationSerivce.getCurrentPractice().subscribe((practice) => {
              const _item = {};

              if (practice && practice['addresses.Postcode']) {
                _item['streetName'] = practice['addresses.Street Name'];
                _item['unitNumber'] = practice['addresses.Unit'];
                _item['streetNumber'] = practice['addresses.Street Nr'];
                _item['suburb'] = practice['addresses.Suburb'];
                _item['postCode'] = practice['addresses.Postcode'];
                _item['streetType'] = practice['addresses.Street Type'];
                _item['app-state'] = practice['addresses.State'];
                _item['country'] = practice['addresses.Country.Code'] || this.country;
                _item[this.markerTitleAttribute] = practice['CalculatedName'];
                _item[this.markerDescriptionAttribute] = practice['addresses.Calculated'];

                _item['Latitude'] = practice['Address.Latitude'];
                _item['Longitude'] = practice['Address.Longitude'];

                _item['isOpen'] = true;

                _item['markerTitle'] = 'Your Practice Address';

                this.practiceAddress = _item;

                this.setLoadItemMarker(this.practiceAddress, false, 'practice');
              }

              this.authenticationSerivce.getCurrentUser().subscribe((profile) => {
                const _item = {};

                if (profile && profile.data && profile.data['addresses.Postcode']) {
                  _item['streetName'] = profile.data['addresses.Street Name'];
                  _item['unitNumber'] = profile.data['addresses.Unit'];
                  _item['streetNumber'] = profile.data['addresses.Street Nr'];
                  _item['suburb'] = profile.data['addresses.Suburb'];
                  _item['postCode'] = profile.data['addresses.Postcode'];
                  _item['streetType'] = profile.data['addresses.Street Type'];
                  _item['app-state'] = profile.data['addresses.State'];
                  _item['country'] = profile.data['addresses.Country.Code'] || this.country;
                  _item[this.markerTitleAttribute] = profile.data['CalculatedName'];
                  _item[this.markerDescriptionAttribute] = profile.data['addresses.Calculated'];

                  _item['isOpen'] = true;

                  _item['markerTitle'] = 'Your Address';

                  _item['Latitude'] = profile.data['Address.Latitude'];
                  _item['Longitude'] = profile.data['Address.Longitude'];

                  this.profileAddress = _item;
                  this.setLoadItemMarker(this.profileAddress, false, 'profile');
                }
              });
            });
          }
        });
      });

      if (this.isNightMode == true) {
        this.styles = [
          { elementType: 'geometry', stylers: [{ color: '#242f3e' }] },
          { elementType: 'labels.text.stroke', stylers: [{ color: '#242f3e' }] },
          { elementType: 'labels.text.fill', stylers: [{ color: '#746855' }] },
          {
            featureType: 'administrative.locality',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#d59563' }],
          },
          {
            featureType: 'poi',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#d59563' }],
          },
          {
            featureType: 'poi.park',
            elementType: 'geometry',
            stylers: [{ color: '#263c3f' }],
          },
          {
            featureType: 'poi.park',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#6b9a76' }],
          },
          {
            featureType: 'road',
            elementType: 'geometry',
            stylers: [{ color: '#38414e' }],
          },
          {
            featureType: 'road',
            elementType: 'geometry.stroke',
            stylers: [{ color: '#212a37' }],
          },
          {
            featureType: 'road',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#9ca5b3' }],
          },
          {
            featureType: 'road.highway',
            elementType: 'geometry',
            stylers: [{ color: '#746855' }],
          },
          {
            featureType: 'road.highway',
            elementType: 'geometry.stroke',
            stylers: [{ color: '#1f2835' }],
          },
          {
            featureType: 'road.highway',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#f3d19c' }],
          },
          {
            featureType: 'transit',
            elementType: 'geometry',
            stylers: [{ color: '#2f3948' }],
          },
          {
            featureType: 'transit.station',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#d59563' }],
          },
          {
            featureType: 'water',
            elementType: 'geometry',
            stylers: [{ color: '#17263c' }],
          },
          {
            featureType: 'water',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#515c6d' }],
          },
          {
            featureType: 'water',
            elementType: 'labels.text.stroke',
            stylers: [{ color: '#17263c' }],
          },
        ];
      } else if (this.isLightMode == true) {
        this.styles = [
          {
            featureType: 'all',
            elementType: 'geometry.fill',
            stylers: [
              {
                weight: '2.00',
              },
            ],
          },
          {
            featureType: 'all',
            elementType: 'geometry.stroke',
            stylers: [
              {
                color: '#9c9c9c',
              },
            ],
          },
          {
            featureType: 'all',
            elementType: 'labels.text',
            stylers: [
              {
                visibility: 'on',
              },
            ],
          },
          {
            featureType: 'landscape',
            elementType: 'all',
            stylers: [
              {
                color: '#f2f2f2',
              },
            ],
          },
          {
            featureType: 'landscape',
            elementType: 'geometry.fill',
            stylers: [
              {
                color: '#ffffff',
              },
            ],
          },
          {
            featureType: 'landscape.man_made',
            elementType: 'geometry.fill',
            stylers: [
              {
                color: '#ffffff',
              },
            ],
          },
          {
            featureType: 'poi',
            elementType: 'all',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            featureType: 'road',
            elementType: 'all',
            stylers: [
              {
                saturation: -100,
              },
              {
                lightness: 45,
              },
            ],
          },
          {
            featureType: 'road',
            elementType: 'geometry.fill',
            stylers: [
              {
                color: '#eeeeee',
              },
            ],
          },
          {
            featureType: 'road',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#7b7b7b',
              },
            ],
          },
          {
            featureType: 'road',
            elementType: 'labels.text.stroke',
            stylers: [
              {
                color: '#ffffff',
              },
            ],
          },
          {
            featureType: 'road.highway',
            elementType: 'all',
            stylers: [
              {
                visibility: 'simplified',
              },
            ],
          },
          {
            featureType: 'road.arterial',
            elementType: 'labels.icon',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            featureType: 'transit',
            elementType: 'all',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            featureType: 'water',
            elementType: 'all',
            stylers: [
              {
                color: '#46bcec',
              },
              {
                visibility: 'on',
              },
            ],
          },
          {
            featureType: 'water',
            elementType: 'geometry.fill',
            stylers: [
              {
                color: '#c8d7d4',
              },
            ],
          },
          {
            featureType: 'water',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#070707',
              },
            ],
          },
          {
            featureType: 'water',
            elementType: 'labels.text.stroke',
            stylers: [
              {
                color: '#ffffff',
              },
            ],
          },
        ];
      } else {
        this.styles = '';
      }
    });
  }

  addSearchMarker() {
    this.mapsApiLoader.load().then(() => {
      const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          // get the place result
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();

          // verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          // set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.center = {
            Latitude: this.latitude,
            Longitude: this.longitude,
          };
          this.zoom = 14;
        });
      });
    });
  }

  // Get Current Location Coordinates
  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 8;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }

  actionClickEvent() {
    this.getAction.emit(true);
  }

  resetMap() {
    this.zoom = 8;
    this.getSelected.emit(null);

    this.selectedIndex = null;

    if (this.profileAddress) {
      this.center = this.profileAddress;
    }

    if (this.practiceAddress) {
      this.center = this.practiceAddress;
    }

    this.addMarkerSubscription.unsubscribe();
    for (let i = 0; i < this.clearTimeOut.length; i++) {
      clearTimeout(this.clearTimeOut[i]);
    }

    this.mapCounter = 0;

    this.addMarkerSubscription = this.addMarker.subscribe((res) => {
      if (res) {
        this.setLoadItemMarker(res);
      }
    });
  }

  reset() {
    this.positions = [];

    if (this.profileAddress) {
      this.center = this.profileAddress;
    }

    if (this.practiceAddress) {
      this.center = this.practiceAddress;
    }

    this.addMarkerSubscription.unsubscribe();
    for (let i = 0; i < this.clearTimeOut.length; i++) {
      clearTimeout(this.clearTimeOut[i]);
    }

    this.mapCounter = 0;

    this.addMarkerSubscription = this.addMarker.subscribe((res) => {
      if (res) {
        this.setLoadItemMarker(res);
      }
    });
  }

  ngOnDestroy() {
    this.reset();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.showRadius && (changes.showRadius.previousValue || changes.showRadius.currentValue)) {
      if (this.positions && this.positions.length > 0) {
        this.positions.forEach((x) => (x['isHide'] = false));
      }
    }
  }

  selectItem(item) {
    if (item) {
      this.getSelected.emit(item);

      let _result;

      this.selectedIndex;

      this.positions.map((x, index) => {
        if (x && x.item && x.item['ID']) {
          if (x.item['ID'] == item['ID']) {
            this.selectedIndex = index;

            _result = x;
            return index;
          }
        }
      });

      if (_result) {
        setTimeout(() => {
          this.zoom = 16;
          this.center = {
            Latitude: _result['Address.Latitude'] || _result['Latitude'],
            Longitude: _result['Address.Longitude'] || _result['Longitude'],
          };
        }, 200);
      }
    }
  }

  toNumber(n) {
    return Number(n);
  }

  closeEvent() {
    this.close.emit(true);
  }

  addMarkers(_dataArray = []) {
    for (let i = 0; i < _dataArray.length; i++) {
      this.setLoadItemMarker(_dataArray[i]);
    }
  }

  setLoadItemMarker(item, buildItem = true, type = null) {
    if (this.isMapLoaded == true) {
      this.setItemMarker(item, buildItem, type);
    } else {
      this.mapsApiLoader.load().then(() => {
        this.geocoder = new google.maps.Geocoder();
        this.isMapLoaded = true;
        this.setItemMarker(item, buildItem, type);
      });
    }
  }

  setItemMarker(item, buildItem = true, type = null) {
    let addressLineFull = '';
    let result;

    if (buildItem == true) {
      result = this.buildItem(item);
    } else {
      result = item;
    }

    if (result && (!result.Latitude || !result.Longitude || (result.Longitude == 0 && result.Latitude == 0))) {
      if (navigator.geolocation && typeof google !== 'undefined' && google) {
        const geoObject = {};

        geoObject['address'] = '';

        if (result['unitNumber'] && result['streetNumber'] && result['streetName']) {
          addressLineFull = addressLineFull + result['unitNumber'] + '/';
          geoObject['address'] = geoObject['address'] + result['unitNumber'] + ',';
        }

        if (result['streetNumber'] && result['streetName']) {
          addressLineFull = addressLineFull + result['streetNumber'] + ' ';

          geoObject['address'] = geoObject['address'] + result['streetNumber'] + ',';
        }
        if (result['streetName']) {
          addressLineFull = addressLineFull + result['streetName'] + ' ';

          geoObject['address'] = geoObject['address'] + result['streetName'] + ',';

          if (result['streetType']) {
            addressLineFull = addressLineFull + result['streetType'] + ' ';

            geoObject['address'] = geoObject['address'] + result['streetType'] + ',';
          }
        }
        addressLineFull = addressLineFull + result['suburb'] + ' ';
        if (result['state'] && result['postCode']) {
          addressLineFull = addressLineFull + result['state'] + ' ';
          geoObject['address'] = geoObject['address'] + result['state'] + ',';
        }

        if (result['postCode']) {
          addressLineFull = addressLineFull + result['postCode'];
          geoObject['address'] = geoObject['address'] + result['postCode'] + ',';
        }

        geoObject['address'] = geoObject['address'] + (result['country'] || 'AU');
        addressLineFull = addressLineFull + result['country'];

        if (geoObject['address']) {
          this.getGeoCode(geoObject, item, result, type);
        }
      }
    } else if (result && result.Latitude && result.Longitude) {
      const position = {
        Latitude: result.Latitude,
        Longitude: result.Longitude,
        isOpen: item['isOpen'] || false,
        time: null,
        label: result[this.markerTitleAttribute] || '',
        item: item || null,
        isHide: false,
      };

      if (type == 'practice') {
        this.practiceAddress = position;
        this.center = this.practiceAddress;
      } else if (type == 'profile') {
        this.profileAddress = position;
      } else {
        this.positions.push(position);
      }
    }
  }

  getGeoCode(geoObject, item, result, type) {
    this.mapCounter++;

    if (this.geocoder) {
      const c = setTimeout(() => {
        this.geocoder.geocode(geoObject, (results, status) => {
          if (results && results[0]) {
            const position = {
              Latitude: results[0].geometry.location.lat(),
              Longitude: results[0].geometry.location.lng(),
              isOpen: item['isOpen'] || false,
              time: null,
              label: result[this.markerTitleAttribute] || '',
              item: item || null,
              isHide: false,
            };

            if (this.isAddCoordinate == true && this.isLogged == true) {
              const _item = item;

              _item['latitude'] = position.Latitude;
              _item['longitude'] = position.Longitude;
              this.addCoordinate.emit(_item);
            }

            if (type == 'practice') {
              this.practiceAddress = position;
              this.center = this.practiceAddress;
            } else if (type == 'profile') {
              this.profileAddress = position;
            } else {
              this.positions.push(position);
            }
          } else {
            this.failed.push(geoObject);

            if (environment.production) {
              console.log('failed geocodes: ' + this.failed.length);
            } else {
              const failedGeocodeAddresses = this.failed;
              console.log({ failedGeocodeAddresses });
            }
          }
        });
      }, this.mapCounter * 200);

      this.clearTimeOut.push(c);
    }
  }

  buildItem(item) {
    const result = {};
    if (item) {
      if (this.streetNameAttribute && item[this.streetNameAttribute]) {
        result['streetName'] = item[this.streetNameAttribute];
      }
      if (this.unitNumberAttribute && item[this.unitNumberAttribute]) {
        result['unitNumber'] = item[this.unitNumberAttribute];
      }
      if (this.streetNumberAttribute && item[this.streetNumberAttribute]) {
        result['streetNumber'] = item[this.streetNumberAttribute];
      }
      if (this.suburbAttribute && item[this.suburbAttribute]) {
        result['suburb'] = item[this.suburbAttribute];
      }
      if (this.postCodeAttribute && item[this.postCodeAttribute]) {
        result['postCode'] = item[this.postCodeAttribute];
      }
      if (this.streetTypeAttribute && item[this.streetTypeAttribute]) {
        result['streetType'] = item[this.streetTypeAttribute];
      }
      if (this.stateAttribute && item[this.stateAttribute]) {
        result['app-state'] = item[this.stateAttribute];
      }
      if (this.countryAttribute && item[this.countryAttribute]) {
        result['country'] = item[this.countryAttribute];
      } else {
        result['country'] = this.country;
      }

      if (this.latitudeAttribute && item[this.latitudeAttribute] && Number(item[this.latitudeAttribute]) != 0) {
        result['Latitude'] = item[this.latitudeAttribute];
      } else {
        result['Latitude'] = null;
      }

      if (this.longitudeAttribute && item[this.longitudeAttribute] && Number(item[this.longitudeAttribute]) != 0) {
        result['Longitude'] = item[this.longitudeAttribute];
      } else {
        result['Longitude'] = null;
      }

      if (this.markerTitleAttribute && item[this.markerTitleAttribute]) {
        result[this.markerTitleAttribute] = item[this.markerTitleAttribute];
      } else {
        result[this.markerTitleAttribute] = '';
      }
      if (this.markerDescriptionAttribute && item[this.markerDescriptionAttribute]) {
        result[this.markerDescriptionAttribute] = item[this.markerDescriptionAttribute];
      } else {
        result[this.markerDescriptionAttribute] = null;
      }

      return result;
    }

    return null;
  }

  changeToCurrentPractice() {
    if (this.practiceAddress) {
      this.zoom = 15;
      if (this.currentPossition) {
        this.center = this.currentPossition;
      } else if (this.positions && this.positions.length > 0) {
        this.center = this.positions[this.positions.length - 1];
      } else {
        this.center = {
          Latitude: 0,
          Longitude: 0,
        };
      }

      setTimeout(() => {
        this.zoom = 15;
        this.center = this.practiceAddress;
      }, 200);
    }
  }

  changeToCurrentProfil() {
    if (this.profileAddress) {
      this.zoom = 12;
      if (this.currentPossition) {
        this.center = this.currentPossition;
      } else if (this.positions && this.positions.length > 0) {
        this.center = this.positions[this.positions.length - 1];
      } else {
        this.center = {
          Latitude: 0,
          Longitude: 0,
        };
      }

      setTimeout(() => {
        this.zoom = 12;
        this.center = this.profileAddress;
      }, 200);
    }
  }

  changeToCurrent() {
    if (!this.currentPossition) {
      if (ClientDetails.geolocation) {
        if ('geolocation' in navigator) {
          const _this = this;

          navigator.geolocation.getCurrentPosition(function (position) {
            if (position && position.coords) {
              _this.currentPossition = {
                Latitude: position.coords.latitude,
                Longitude: position.coords.longitude,
              };
              _this.center = _this.currentPossition;
              _this.zoom = 12;
            } else {
              NotifyAppComponent.displayToast('Error', 'Sorry!', 'We failed to locate your current position.');
            }
          });
        } else {
          NotifyAppComponent.displayToast('Error', 'Sorry!', 'We failed to locate your current position.');
        }
      } else {
        NotifyAppComponent.displayToast('Error', 'Sorry!', 'We failed to locate your current position.');
      }
    } else {
      this.center = {
        Latitude: null,
        Longitude: null,
      };
      this.zoom = 12;
      this.center = this.currentPossition;
    }
  }

  radiusDragEnd(event: any) {
    this.radiusLat = event.coords.lat;
    this.radiusLong = event.coords.lng;
    this.showHideMarkers();
  }

  event(type, event) {
    this.radius = event;
    this.showHideMarkers();
  }

  showHideMarkers() {
    if (this.positions && this.positions.length > 0) {
      for (let i = 0; i < this.positions.length; i++) {
        this.positions[i]['isHide'] = this.getDistanceBetween(
          this.positions[i]['Latitude'],
          this.positions[i]['Longitude'],
          this.radiusLat,
          this.radiusLong
        );
      }
    }
  }

  getDistanceBetween(lat1, long1, lat2, long2) {
    const from = new google.maps.LatLng(lat1, long1);
    const to = new google.maps.LatLng(lat2, long2);

    if (google.maps.geometry.spherical.computeDistanceBetween(from, to) <= this.radius) {
      return false;
    } else {
      return true;
    }
  }

  // get address for search bar

  markerDragEnd($event: MouseEvent) {
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.getAddress(this.latitude, this.longitude);
  }

  getAddress(latitude, longitude) {
    this.geocoder.geocode({ location: { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 14;
          this.address = results[0].formatted_address;
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }
    });
  }
}
