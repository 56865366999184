import { QueryParameters } from '../../../shared/types/query-parameters.type';

export class TreatmentPlan {
  [key: string]: any;

  'ID': string;
  readonly 'DateTimeCreated': string;
  readonly 'LastModified': string;
  readonly 'LastModified_Human': string;
  readonly 'Invitation_key': string;
  'Invitation.FirstName': string;
  'Invitation.LastName': string;
  'Merchant_key': string;
  'Merchant.Name': string;
  'Label': string;
  'Comments': string;
  'SellPrice.Calculated': number;
  'SellPrice.Overridden': number;
  'SellPrice.Effective': number;
  'Cost.Items.Calculated': number;
  'Cost.Overheads.Calculated': number;
  'Cost.Overheads.Effective': number;
  'Cost.Total.Calculated': number;
  'Cost.Total.Effective': number;
  'Profit.Calculated': number;
  'Profit.Effective': number;
  'DocumentsToSend.Applicable.SRVPRE': TreatmentPlanDocument[];
  'DocumentsToSend.Applicable.SRVPST': TreatmentPlanDocument[];
  'DocumentsToSend.Applicable.SRVMKT': TreatmentPlanDocument[];
  'DocumentsToSend.Applicable.Any': TreatmentPlanDocument[];
  'DocumentsToSend.Applicable.SRVSTC': TreatmentPlanDocument[];
  marketingDocuments?: TreatmentPlanDocument[];
  postDocuments?: TreatmentPlanDocument[];
  preDocuments?: TreatmentPlanDocument[];
  stockArtTreatmentDocuments?: TreatmentPlanDocument[];
  treatmentDocuments?: TreatmentPlanDocument[];
  viewedDocuments?: { id: string; viewedDate: string }[];
}

export class TreatmentPlanQuery {
  payload: Partial<TreatmentPlan> &
    QueryParameters & {
      invitationID: string;
      informedConsentID?: string;
      selectTreatmentDocument?: boolean;
      selectTreatmentStockArtDocument?: boolean;
    };
}

export class TreatmentPlanDocument {
  [key: string]: any;

  ID: string;
  Label: string;
  ContainsSensitiveData: '0' | '1';
  readonly DateAdded: string;
  readonly DateLastModified: string;
  readonly DateTimeCreated: string;
  Description: string;
  Extension: string;
  'URL.Base': string;
  'URL.Complete': string;
  'URL.Relative': string;
  AWS_URL: string;
}

export class Treatment {
  treatmentType: any;
  treatmentCategory: any;
  treatmentBrand: any;
  treatmentValue: any;
  treatmentCost: any;
  document: any;
  descriptionDiagnosis: any;
  descriptionTreatment: any;

  constructor() {
    this.document = [];
    this.treatmentCategory = {};
    this.treatmentType = {};
    this.treatmentBrand = {};
    this.treatmentValue = '';
    this.treatmentCost = '';
    this.descriptionDiagnosis = null;
    this.descriptionTreatment = null;
  }
}

export class TreatmentProduct {
  'ID': string;
  readonly 'LastModified': string;
  readonly 'LastModified_Human': string;
  readonly 'DateTimeCreated': string;
  'Label': string;
  'Active': '0' | '1';
  'Description': string;
  'Item_Code.Effective': string;
  'Item_Code.Inherited': string;
  'Item_Code.Overridden': string;
  'IsOmitted': '0' | '1';
  'Brand_key': string;
  'Brand.Label': string;
  'Brand.Active': '0' | '1';
  'Type_key': string;
  'Type.Label': string;
  'Type.Active': '0' | '1';
  'Category_key': string;
  'Category.Label': string;
  'Category.Colour': string;
  'Category.Active': '0' | '1';
  'IsNullProduct': '0' | '1';
  'Manufacturer_Key.Overridden': string;
  'Manufacturer.Name.Overridden': string;
  'Manufacturer_Key.Inherited': string;
  'Manufacturer.Name.Inherited': string;
  'Manufacturer_Key.Effective': string;
  'Manufacturer.Name.Effective': string;
  'Distributor_Key.Overridden': string;
  'Distributor.Name.Overridden': string;
  'Distributor_Key.Inherited': string;
  'Distributor.Name.Inherited': string;
  'Distributor_Key.Effective': string;
  'Distributor.Name.Effective': string;
  'Price.Sell.Overridden': '0' | '1' | '-1';
  'Price.Sell.Inherited': '0' | '1';
  'Price.Sell.Effective': '0' | '1';
  'Price.Cost.Item.Overridden': '0' | '1' | '-1';
  'Price.Cost.Item.Inherited': '0' | '1';
  'Price.Cost.Item.Effective': '0' | '1';
  'Price.Cost.Overheads.Inherited': '0' | '1';
  'Price.Cost.Overheads.Effective': '0' | '1';
  'Price.Cost.Total.Inherited': '0' | '1';
  'Price.Cost.Total.Effective': '0' | '1';
  'Price.Profit.Inherited': '0' | '1';
  'Price.Profit.Effective': '0' | '1';
  'DefaultNotes.Category.Overridden': string;
  'DefaultNotes.Category.Inherited': string;
  'DefaultNotes.Category.Effective': string;
  'DefaultNotes.Label.Overridden': string;
  'DefaultNotes.Label.Inherited': string;
  'DefaultNotes.Label.Effective': string;
  'DefaultNotes.Content.Diagnostic.Overridden': string;
  'DefaultNotes.Content.Diagnostic.Inherited': string;
  'DefaultNotes.Content.Diagnostic.Effective': string;
  'DefaultNotes.Content.Treatment.Overridden': string;
  'DefaultNotes.Content.Treatment.Inherited': string;
  'DefaultNotes.Content.Treatment.Effective': string;
  'AssociatedDocuments.Prod.SRVPRE.Included': any[];
  'AssociatedDocuments.Prod.SRVPRE.All': any[];
  'AssociatedDocuments.Prod.SRVSTC.Included': any[];
  'AssociatedDocuments.Prod.SRVSTC.All': any[];
  'AssociatedDocuments.Prod.SRVPST.Included': any[];
  'AssociatedDocuments.Prod.SRVPST.All': any[];
  'AssociatedDocuments.Prod.SRVMKT.Included': any[];
  'AssociatedDocuments.Prod.SRVMKT.All': any[];
  'AssociatedDocuments.Prod.SRV.Included': any[];
  'AssociatedDocuments.Prod.SRV.All': any[];
  'AssociatedDocuments.ProdAndBrand.SRVPRE.Included': { Document_key: string; Label: string; Mandatory: '0' | '1' }[];
  'AssociatedDocuments.ProdAndBrand.SRVPRE.All': {
    Document_key: string;
    Label: string;
    Mandatory: '0' | '1';
    Omitted: '0' | '1';
  }[];
  'AssociatedDocuments.ProdAndBrand.SRVSTC.Included': any[];
  'AssociatedDocuments.ProdAndBrand.SRVSTC.All': any[];
  'AssociatedDocuments.ProdAndBrand.SRVPST.Included': any[];
  'AssociatedDocuments.ProdAndBrand.SRVPST.All': any[];
  'AssociatedDocuments.ProdAndBrand.SRVMKT.Included': any[];
  'AssociatedDocuments.ProdAndBrand.SRVMKT.All': any[];
  'AssociatedDocuments.ProdAndBrand.SRV.Included': any[];
  'AssociatedDocuments.ProdAndBrand.SRV.All': any[];
  'AssociatedDocuments.ProdAndInherited.SRVPRE.All': {
    Document_key: string;
    Label: string;
    Mandatory: '0' | '1';
    Omitted: '0' | '1';
  }[];
  'AssociatedDocuments.ProdAndInherited.SRVPST.All': any[];
  'AssociatedDocuments.ProdAndInherited.SRVMKT.All': any[];
  'AssociatedDocuments.ProdAndInherited.SRVSPL.All': {
    Document_key: string;
    Label: string;
    Mandatory: '0' | '1';
    Omitted: '0' | '1';
  }[];
  'AssociatedDocuments.ProdAndInherited.SRV.All': any[];
  'AssociatedDocuments.ProdAndInherited.SRVSTC.All': any[];
  'NumberOfDocuments': string;
  'fullLabel'?: string;
  'isChecked'?: boolean;
}

export class TreatmentProductQuery {
  payload: Partial<TreatmentProduct> &
    QueryParameters & {
      includeOmmitted?: boolean;
      categoryID?: string;
      typeID?: string;
      brandID?: string;
      supplierID?: string;
      merchantID?: string;
      manufacturerID?: string;
      distributorID?: string;
      isNullProduct?: boolean;
      dateFrom?: string;
      dateTo?: string;
    };
}
