<mat-tab-group dynamicHeight="true">
  <mat-tab label="Service Groups">
    <ng-template matTabContent>
      <app-merchant-service-group-list class="full-width clearfix"></app-merchant-service-group-list>
    </ng-template>
  </mat-tab>

  <mat-tab label="Services">
    <ng-template matTabContent>
      <app-merchant-service-list class="full-width clearfix"></app-merchant-service-list>
    </ng-template>
  </mat-tab>
</mat-tab-group>
