<div class="rel text-center">
  <mat-card class="card">
    <div class="row clearfix">
      <div class="pull-right">
        <!-- Modal dismiss button -->
        <button class="btn-close btn-clear mat-button" (click)="doNotShow()" matDialogClose>
          <mat-icon>clear</mat-icon>
        </button>
      </div>
    </div>
    <div class="colour-stripe"></div>
    <hr/>
    <p>
      <img class="animated zoomIn feature-img character_Pair"/>
    </p>
    <h2 class="sr-title sm-h2 rm-mb rm-mt mb">
      Before you get started here are some important things you should know:
    </h2>
    <hr class="mb"/>

    <mat-dialog-content>
      <mat-list class="mb">
        <mat-list-item>An Email/SMS will be sent to your {{ "KEYWORD.patient" | translate }}, with an invitation to
          apply for the
          selected product
        </mat-list-item>
        <mat-list-item>You will need to attach a copy of the proposed {{ 'PHRASE.treatment plan' | translate }} and you
          may also
          choose to attach other information you wish your {{ "KEYWORD.patient" | translate }} to receive.
        </mat-list-item>
        <mat-list-item>In addition, you can personalise your invitation by entering a message and scheduling the date
          and time to
          send on the next screen.
        </mat-list-item>
      </mat-list>
    </mat-dialog-content>
    <div class="row clearfix text-center">
      <mat-checkbox [(ngModel)]="showBeforeStartingInvitation">Please don't show this message again</mat-checkbox>
    </div>
    <hr class="mb"/>
    <div class="button-row text-center inCard">
      <button (click)="doNotShow()" mat-raised-button color="primary">Got It!</button>
    </div>
  </mat-card>
</div>
