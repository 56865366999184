import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  NgZone,
  OnInit,
  Optional,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'environments/environment';
import * as FileSaver from 'file-saver';
import * as mime from 'mime-types';
import { DeviceDetectorService } from 'ngx-device-detector';
import { fromEvent as observableFromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ZoomInInfoComponent } from '../../../core/helper/zoom-in-info/zoom-in-info.component';
import { UtilsClass } from '../../types/utils/utils.class';
import { RootAppComponent } from '../root-component/root-component.component';

@Component({
  selector: 'app-blob-view',
  templateUrl: './blob-view.component.html',
  styleUrls: ['./blob-view.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.row', style({ opacity: 0 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.row', style({ opacity: 1 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class BlobViewComponent implements OnInit {
  @Output() download = new EventEmitter();

  @Output() proceed = new EventEmitter();

  @Output() print = new EventEmitter();

  @Input() applyPrint = new EventEmitter();

  @Input() applyDownload = new EventEmitter();

  @Input()
  text;

  @Input()
  link;
  isIframeLoaded = false;

  @Input() applyExpernalLink = new EventEmitter();

  @Output() closeModal = new EventEmitter();
  @Output() zoomInInfoEvent = new EventEmitter();
  @Input()
  content;

  @Input()
  description;

  @Input()
  title;

  @Input()
  label;

  @Input()
  extension = 'pdf';

  @Input()
  downloadPullRight = false;
  @Input()
  zoomInInfo = false;
  @Input()
  labelToPrint = 'document';

  @Input()
  labelDownload = 'Download';

  @Input()
  labelProceed = 'Proceed';

  @Input()
  labelPrint = 'Print';

  @Input()
  isDownload = true;

  @Input()
  isProceed = false;

  @Input()
  loadingImg = 'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/ep-loader.gif)';

  @Input()
  loading = false;

  @Input()
  isPrint = true;

  isMicrosoftEdge = false;

  fileRawURL;
  fileURL;
  fileURLString;

  scroll$: any;

  noTitleMode = false;

  isModal = false;

  fileID = this.extension + 'Doc';

  @Input()
  canProceed = false;

  @ViewChild('iframe', { static: false }) iframe: ElementRef;

  browser;

  @Input()
  zoomMode = 'toggle';

  @Input()
  Magnification = 4;

  @Input()
  enableScrollZoom = true;

  @Input()
  isInsideStaticContainer = true;

  isZoomed = false;

  pinchZoomProperties = [
    {
      wheel: true,
      autoHeight: true,
    },
  ];

  isError = false;
  isLoaded = false;

  util = new UtilsClass();

  documentType;
  mimeDocumentType;

  constructor(
    private deviceDetectorService: DeviceDetectorService,
    private domSanitizer: DomSanitizer,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    @Inject(NgZone) private zone: NgZone
  ) {
    if (data) {
      if (data.link) {
        this.link = data.link;

        this.isModal = true;
      }

      if (data.content) {
        this.content = data.content;

        this.isModal = true;
      }

      if (data.labelProceed) {
        this.labelProceed = data.labelProceed;
      }
      if (data.text) {
        this.text = data.text;
      }

      if (data.labelDownload) {
        this.labelDownload = data.labelDownload;
      }

      if (data.extension) {
        this.extension = data.extension;
      }
      if (data.label) {
        this.label = data.label;
      }
      if (data.isProceed != null) {
        this.isProceed = data.isProceed;
      }

      if (data.noTitleMode != null) {
        this.noTitleMode = data.noTitleMode;
      }

      if (data.downloadPullRight != null) {
        this.downloadPullRight = data.downloadPullRight;
      }

      if (data.isDownload != null) {
        this.isDownload = data.isDownload;
      }

      if (data.description) {
        this.description = data.description;
      }
      if (data.title) {
        this.title = data.title;
      }

      if (data.isPrint != null) {
        this.isPrint = data.isPrint;
      }

      if (data.labelPrint) {
        this.labelPrint = data.labelPrint;
      }

      if (data.labelToPrint) {
        this.labelToPrint = data.labelToPrint;
      }
    }
  }

  ngOnInit() {
    this.browser = this.deviceDetectorService.browser;

    if (this.browser == 'ms-edge') {
      this.isMicrosoftEdge = true;
    }

    if (this.content || this.link) {
      this.displayDoc();
    }

    this.applyPrint.subscribe((res) => {
      this.printEvent();
    });

    this.applyDownload.subscribe((res) => {
      this.downloadFile();
    });

    this.applyExpernalLink.subscribe((res) => {
      this.openInNewTab();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.content || this.link) {
      this.displayDoc();
    }
  }

  onZoomChange(event: any) {
    this.Magnification = event.value;
  }

  openInNewTab() {
    const href = this.fileURLString;
    window.open(href, '_blank');
  }

  proceedEvent() {
    this.proceed.emit(true);
  }

  downloadFile() {
    let mimeDocumentType = 'image/png';
    let extenstion = 'png';

    if (this.extension == 'document') {
      extenstion = 'docx';
      mimeDocumentType = mime.lookup(extenstion);
    } else if (this.extension) {
      extenstion = this.extension;
      mimeDocumentType = mime.lookup(this.extension);
    }

    if (this.link) {
      const link = document.createElement('a');
      link.href = this.link;
      link.target = '_blank';
      link.download = this.label + '.' + extenstion;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else if (this.content) {
      const blob = this.base64toBlob(this.content, mimeDocumentType);
      FileSaver.saveAs(blob, this.label + '.' + extenstion);
      this.download.emit(true);
      this.canProceed = true;
    }

    const contentType = this.util.getDocumentTypeFromExtension(this.extension);

    if (contentType == 'image') {
      this.fileID = 'imageDoc';
    } else {
      this.fileID = this.extension + 'Doc';
    }
  }

  printEventIframe(_fileID) {
    document.getElementById(_fileID)['contentWindow'].print();

    this.print.emit(true);
    this.canProceed = true;
  }

  printEvent() {
    const contentType = this.util.getDocumentTypeFromExtension(this.extension);

    if (contentType == 'image') {
      this.fileID = 'imageDoc';
    }

    let _fileID = this.fileID;

    if (this.extension == 'html') {
      _fileID = 'htmlDoc';

      this.printEventIframe(_fileID);
    } else if (this.fileID == 'pdfDoc') {
      _fileID = 'iframeDoc';

      this.printEventIframe(_fileID);
    } else if (this.fileID == 'imageDoc') {
      this.printEventIframe(_fileID);
    } else {
      const mywindow = window.open('', 'PRINT', 'height=600,width=600');

      mywindow.document.write('</head><body >');
      mywindow.document.write(document.getElementById(_fileID).innerHTML);
      mywindow.document.write('</body></html>');

      mywindow.document.close(); // necessary for IE >= 10
      mywindow.focus(); // necessary for IE >= 10*/

      mywindow.print();
      mywindow.close();

      this.print.emit(true);
      this.canProceed = true;
    }
  }

  displayDoc() {
    this.isError = false;
    this.isLoaded = false;

    this.documentType = this.util.getDocumentTypeFromExtension(this.extension);

    if (this.extension) {
      this.mimeDocumentType = mime.lookup(this.extension);
    }

    if (this.link) {
      if (this.link.indexOf(environment.nodeUrl) == -1) {
        if (this.link[0] == '/') {
          this.link = environment.nodeUrl + this.link;
        } else {
          this.link = environment.nodeUrl + '/' + this.link;
        }
      }

      this.fileRawURL = this.link;
      this.fileURL = this.domSanitizer.bypassSecurityTrustResourceUrl(this.fileRawURL);
    } else if (this.content) {
      const file = this.base64toBlob(this.content, this.mimeDocumentType);

      this.fileRawURL = window.URL.createObjectURL(file);

      this.fileURL = this.domSanitizer.bypassSecurityTrustResourceUrl(this.fileRawURL);

      if (this.fileURL && this.fileURL.changingThisBreaksApplicationSecurity) {
        this.fileURLString = this.fileURL.changingThisBreaksApplicationSecurity;
      }
    }
  }

  onFrameLoad(evt) {
    this.isIframeLoaded = true;
    if (this.iframe && this.iframe.nativeElement && this.iframe.nativeElement.blobWindow) {
      this.iframe.nativeElement.blobWindow.addEventListener(
        'onscroll',
        function (event) {
          alert('scroll!!');
          this.zone.run(() => {
            alert('scroll!!');
          });
        },
        false
      );

      this.scroll$ = observableFromEvent(this.iframe.nativeElement.blobWindow, 'scroll')
        .pipe(debounceTime(200))
        .subscribe((evt: any) => {
          if (this.iframe.nativeElement.contentWindow.scrollY > this.iframe.nativeElement.contentWindow.innerHeight) {
            alert('scroll bottom');
            this.zone.run(() => {
              alert('scroll!!');
            });
          }
        });
    }
  }

  base64toBlob(base64Data, contentType) {
    contentType = contentType || '';
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  closeModalEvent() {
    this.closeModal.emit(true);
  }

  onLoad() {
    this.isLoaded = true;
    this.isError = false;

    this.displayZoomInInfo();
  }

  onError() {
    this.isLoaded = false;
    this.isError = true;

    this.displayZoomInInfo();
  }

  displayZoomInInfo() {
    if (
      (this.content || this.link) &&
      this.zoomInInfo != true &&
      this.documentType == 'image' &&
      this.fileURL &&
      this.isError != true &&
      this.isLoaded != false
    ) {
      let show = false;

      if (localStorage.getItem('zoomInImageModule') === 'true') {
        show = true;
      }

      if (show !== true) {
        const ref = RootAppComponent.dialog.open(ZoomInInfoComponent, {
          data: {},
          width: '650px',
          height: '700px',
          panelClass: 'noCard',
        });

        const sub = ref.componentInstance.close.subscribe((data) => {
          ref.close();
        });

        this.zoomInInfo = true;
        this.zoomInInfoEvent.emit(true);
      }
    }
  }
}
