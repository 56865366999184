import { CdkTableModule } from "@angular/cdk/table";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  MatDialogModule,
  MatSidenavModule,
  MatSortModule,
  MatTableModule,
} from "@angular/material";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ArchwizardModule } from "angular-archwizard";
import { ClipboardModule } from "ngx-clipboard";
import { ColorPickerModule } from "ngx-color-picker";
import { NgPipesModule } from "ngx-pipes";
import { AuthenticationModule } from "../../core/authentication/authentication.module";
import { SharedModule } from "../../shared/shared.module";
import { CustomerProspectModule } from "../customer-prospect/customerProspect.module";
import { DentistModule } from "../dentist/dentist.module";
import { MerchantModule } from "../merchant/merchant.module";
import { OperatorModule } from "../operator/operator.module";
import { AppointmentActionHiddenComponent } from "./appointment-action-hidden/appointment-action-hidden.component";
import { AppointmentArchiveComponent } from "./appointment-archive/appointment-archive.component";
import { AppointmentAvailabilityListComponent } from "./appointment-availability-list/appointment-availability-list.component";
import { AppointmentAvailabilityComponent } from "./appointment-availability/appointment-availability.component";
import { AppointmentCardComponent } from "./appointment-card/appointment-card.component";
import { AppointmentCreateCustomerComponent } from "./appointment-create-customer/appointment-create-customer.component";
import { AppointmentCreateMerchantComponent } from "./appointment-create-merchant/appointment-create-merchant.component";
import { AppointmentCreateComponent } from "./appointment-create/appointment-create.component";
import { AppointmentDescriptionComponent } from "./appointment-description/appointment-description.component";
import { AppointmentIframeViewComponent } from "./appointment-iframe-view/appointment-iframe-view.component";
import { AppointmentLandingCreateComponent } from "./appointment-landing-create/appointment-landing-create.component";
import { AppointmentListCalendarComponent } from "./appointment-list calendar/appointment-list-calendar.component";
import { AppointmentListCalendarOverviewComponent } from "./appointment-list-calendar-overview/appointment-list-calendar-overview.component";
import { AppointmentListConsumerComponent } from "./appointment-list-consumer/appointment-list-consumer.component";
import { AppointmentListOverviewComponent } from "./appointment-list-overview/appointment-list-overview.component";
import { AppointmentListComponent } from "./appointment-list/appointment-list.component";
import { AppointmentLookupCreateComponent } from "./appointment-lookup-create/appointment-lookup-create.component";
import { AppointmentLookupEditComponent } from "./appointment-lookup-edit/appointment-lookup-edit.component";
import { AppointmentLookupListOverviewComponent } from "./appointment-lookup-list-overview/appointment-lookup-list-overview.component";
import { AppointmentLookupListComponent } from "./appointment-lookup-list/appointment-lookup-list.component";
import { AppointmentLookupSortComponent } from "./appointment-lookup-sort/appointment-lookup-sort.component";
import { AppointmentPageCustomizeComponent } from "./appointment-page-customize/appointment-page-customize.component";
import { AppointmentPublicOperationComponent } from "./appointment-public-operation/appointment-public-operation.component";
import { AppointmentSyncCalendarCardComponent } from "./appointment-sync-calendar-card/appointment-sync-calendar-card.component";
import { AppointmentViewComponent } from "./appointment-view/appointment-view.component";
import { RequestAppointmentViewComponent } from "./request-appointment-view/request-appointment-view.component";
import { ScheduleAppointmentTimeCardComponent } from "./schedule-appointment-time-card/schedule-appointment-time-card.component";
import { ScheduleAppointmentViewComponent } from "./schedule-appointment-view/schedule-appointment-view.component";
import { AppointmentRoutingModule } from "./shared/appointment-routing.module";
import { AppointmentService } from "./shared/appointment.service";
import { AppointmentLookupService } from "./shared/appointmentLookup.service";

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    CdkTableModule,
    MatSortModule,
    NgPipesModule,
    SharedModule,
    ClipboardModule,
    AuthenticationModule,
    AppointmentRoutingModule,
    ArchwizardModule,
    MatTableModule,
    ColorPickerModule,
    CustomerProspectModule,
    ColorPickerModule,
    DentistModule,
    MerchantModule,
    CustomerProspectModule,
    OperatorModule,
    MatSidenavModule,
    MatDialogModule,
  ],

  declarations: [
    AppointmentListComponent,
    AppointmentListOverviewComponent,
    AppointmentListCalendarComponent,
    AppointmentCreateComponent,
    AppointmentCreateCustomerComponent,
    AppointmentCreateMerchantComponent,
    AppointmentViewComponent,
    AppointmentArchiveComponent,
    AppointmentLandingCreateComponent,
    AppointmentPublicOperationComponent,
    AppointmentActionHiddenComponent,
    AppointmentListCalendarOverviewComponent,
    AppointmentListConsumerComponent,
    AppointmentDescriptionComponent,

    RequestAppointmentViewComponent,
    ScheduleAppointmentViewComponent,
    ScheduleAppointmentTimeCardComponent,

    AppointmentPageCustomizeComponent,
    AppointmentAvailabilityComponent,
    AppointmentIframeViewComponent,
    AppointmentLookupListComponent,
    AppointmentLookupEditComponent,
    AppointmentAvailabilityListComponent,
    AppointmentLookupListOverviewComponent,
    AppointmentCardComponent,
    AppointmentLookupCreateComponent,
    AppointmentLookupSortComponent,
    AppointmentSyncCalendarCardComponent,
  ],
  exports: [
    AppointmentListComponent,
    AppointmentListOverviewComponent,
    AppointmentListCalendarComponent,
    AppointmentCreateComponent,
    AppointmentCreateCustomerComponent,
    AppointmentCreateMerchantComponent,
    AppointmentViewComponent,
    AppointmentArchiveComponent,
    AppointmentLandingCreateComponent,
    AppointmentPublicOperationComponent,
    AppointmentActionHiddenComponent,
    AppointmentListCalendarOverviewComponent,
    AppointmentListConsumerComponent,
    AppointmentDescriptionComponent,

    RequestAppointmentViewComponent,
    ScheduleAppointmentViewComponent,
    ScheduleAppointmentTimeCardComponent,

    AppointmentPageCustomizeComponent,
    AppointmentAvailabilityComponent,
    AppointmentIframeViewComponent,
    AppointmentLookupListComponent,
    AppointmentLookupEditComponent,
    AppointmentAvailabilityListComponent,
    AppointmentLookupListOverviewComponent,
    AppointmentCardComponent,
    AppointmentLookupCreateComponent,
    AppointmentLookupSortComponent,
    AppointmentSyncCalendarCardComponent,
  ],
  providers: [AppointmentService, AppointmentLookupService],
})
export class AppointmentModule {}
