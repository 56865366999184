<div class="card-header primary-gradient-img clearfix inModal">
  <div class="row clearfix">
    <button class="btn-close btn-clear mat-button white" *ngIf="isModal == true" (click)="closeModal()">
      <mat-icon>clear</mat-icon>
    </button>
    <div class="row titleArea clearfix hasIcon">
      <mat-icon class="white pull-left fas fa-file-medical"></mat-icon>
      <h2 class="summary-header rm-m">Add New Document</h2>
    </div>
  </div>
</div>

<form #createTreatmentDoc="ngForm">
  <mat-dialog-content class="doc-create-edit indOvf" [ngClass]="{ 'no-modal-height': isModal != true }">
    <div class="full-width flex">
      <div class="full-width">
        <div class="row flex clearfix" *ngIf="multiCreate == false && multiCreateSupplier == false">
          <div class="row clearfix">
            <div class="row clearfix">
              <h3 class="sr-title rm-mt pull-left">Document Assignment</h3>
            </div>

            <div class="full-width toggle-card mb clearfix" *ngIf="displayDocumentAssignment == true">
              <mat-button-toggle-group class="btn-group text-center full-width with-icons"
                                       #groupB="matButtonToggleGroup" [(ngModel)]="_groupFilter"
                                       (ngModelChange)="changeDocumentAssignment(_groupFilter)" name="_groupFilter">
                <mat-button-toggle class="text-center full-width" [value]="'treatment'">
                  <mat-icon class="fas fa-id-card"></mat-icon>
                  {{ "KEYWORD.treatment" | translate | titlecase }} document
                </mat-button-toggle>
                <mat-button-toggle class="text-center full-width" [value]="'supplier'">
                  <mat-icon class="fas fa-parachute-box"></mat-icon> Suppplier document
                </mat-button-toggle>
              </mat-button-toggle-group>

              <mat-divider></mat-divider>
            </div>

            <div class="clearfix flex full-width" *ngIf="_groupFilter == 'supplier' && hideSupplier != true">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Suppplier</mat-label>

                <mat-select class=" " [(ngModel)]="supplierID" name="type" placeholder="Suppplier" required>
                  <mat-option *ngFor="let s of suppliers" [value]="s['ID']">
                    {{ s['Label'] }}
                  </mat-option>
                </mat-select>

                <button class="" *ngIf="supplierID" (click)="$event.stopPropagation(); viewSupplier(supplierID)"
                        matTooltip="View Suppplier" color="accent" mat-button matSuffix>
                  <mat-icon>visibility</mat-icon>
                </button>
              </mat-form-field>
            </div>

            <div class="block clearfix full-width clearfix clicktext"
              *ngIf="_groupFilter == 'supplier' && hideSupplier == true && supplierID && supplierName"
                 matTooltip="Click to view Supplier details">
              <p class="small rm-m">Supplier</p>
              <h4 class="reduce-marg" (click)="viewSupplier(supplierID)">
                {{ supplierName }}
              </h4>
            </div>

            <div class="clearfix flex full-width" *ngIf="
                (hideCategory != false || hideBrand != false || hideProduct != false || hideType != false) &&
                _groupFilter != 'supplier'
              ">
              <div class="block clearfix full-width clicktext"
                *ngIf="hideCategory != false && treatment && treatment['Category.Label']"
                   matTooltip="Click to view Category details">
                <p class="small rm-m">Category</p>
                <h4 class="reduce-marg" (click)="viewCategory(categoryID)">
                  {{ treatmentUtil.getLabel(treatment['Category.Label']) }}
                </h4>
              </div>
              <div class="block clearfix full-width clicktext"
                *ngIf="hideType != false && treatment && treatment['Type.Label']"
                   matTooltip="Click to view Type details">
                <p class="small rm-m">Type</p>
                <h4 class="reduce-marg" (click)="viewType(typeID)">
                  {{ treatmentUtil.getLabel(treatment['Type.Label']) }}
                </h4>
              </div>

              <div class="row clearfix flex full-width" *ngIf="hideBrand != false && brandLabel && brandID">
                <div class="row-content clearfix clicktext" matTooltip="Click to view Brand details">
                  <p class="small rm-m">Brand</p>
                  <h4 class="reduce-marg" (click)="viewBrand(brandID)">
                    {{ treatmentUtil.getLabel(brandLabel) }}
                  </h4>
                </div>
              </div>

              <div class="row clearfix flex full-width" *ngIf="hideProduct != false && productLabel && productID">
                <div class="row-content clearfix clicktext" matTooltip="Click to view Product details">
                  <p class="small rm-m">Product</p>
                  <h4 class="reduce-marg" (click)="viewProduct(productID)">
                    {{ treatmentUtil.getLabel(productLabel) }}
                  </h4>
                </div>
              </div>

              <div class="row clearfix flex full-width" *ngIf="small != false && (manufacturerID || distributorID)">
                <div class="block clearfix full-width clicktext" *ngIf="manufacturerID && manufacturerName"
                     matTooltip="Click to view Manufacturer details">
                  <p class="small rm-m">Manufacturer</p>
                  <h4 class="reduce-marg" (click)="viewSupplier(manufacturerID)">{{ manufacturerName }}</h4>
                </div>
                <div class="block clearfix full-width clicktext" *ngIf="distributorID && distributorName"
                     matTooltip="Click to view Distributor details">
                  <p class="small rm-m">Distributor</p>
                  <h4 class="reduce-marg" (click)="viewSupplier(distributorID)">{{ distributorName }}</h4>
                </div>
              </div>
            </div>

            <div class="clearfix" *ngIf="_groupFilter != 'supplier'">
              <div class="row clearfix flex full-width">
                <!-- category -->

                <mat-form-field class="full-width" *ngIf="hideCategory == false" appearance="outline">
                  <mat-label>Category</mat-label>

                  <mat-select class=" " [(ngModel)]="categoryID" (selectionChange)="onSelectSimpleCategory(categoryID)"
                              name="category" placeholder="Category" required>
                    <mat-option *ngFor="let category of categories" [value]="category['ID']">
                      {{ treatmentUtil.getLabel(category['Label']) }}
                    </mat-option>
                  </mat-select>

                  <button class="" *ngIf="categoryID" (click)="$event.stopPropagation(); viewCategory(categoryID)"
                          matTooltip="View Category" color="accent" mat-button matSuffix>
                    <mat-icon>visibility</mat-icon>
                  </button>
                </mat-form-field>

                <!-- type -->

                <mat-form-field class="full-width" *ngIf="hideType == false" appearance="outline">
                  <mat-label>Type</mat-label>

                  <mat-select class=" " [(ngModel)]="typeID" (selectionChange)="onSelectSimpleType(typeID)" name="type"
                              placeholder="Type">
                    <mat-option *ngFor="let type of types" [value]="type['ID']">
                      {{ treatmentUtil.getLabel(type['Label']) }}
                    </mat-option>
                  </mat-select>

                  <button class="" *ngIf="typeID" (click)="$event.stopPropagation(); viewType(typeID)"
                          matTooltip="View Type" color="accent" mat-button matSuffix>
                    <mat-icon>visibility</mat-icon>
                  </button>
                </mat-form-field>
              </div>
              <div class="row clearfix full-width flex">
                <!-- brand -->

                <mat-form-field class="full-width" *ngIf="hideBrand == false" appearance="outline">
                  <mat-label>Brand</mat-label>

                  <mat-select class=" " [(ngModel)]="brandID"
                              (selectionChange)="onSelectSimpleBrand(brandID); setSupplierFromBrand()" name="brand"
                              placeholder="Brand">
                    <mat-option *ngFor="let brand of brands" [value]="brand['ID']">
                      {{ treatmentUtil.getLabel(brand['Label']) }}
                    </mat-option>
                  </mat-select>

                  <button class="" *ngIf="brandID" (click)="$event.stopPropagation(); brandID(brandID)"
                          matTooltip="View Brand" color="accent" mat-button matSuffix>
                    <mat-icon>visibility</mat-icon>
                  </button>
                </mat-form-field>

                <!-- brand -->

                <!-- <div class="  full-width"> -->

                <mat-form-field class="full-width" *ngIf="hideProduct == false" appearance="outline">
                  <mat-label>Product</mat-label>

                  <mat-select class=" " [(ngModel)]="productID" (selectionChange)="setSupplierFromProduct()"
                              name="product" placeholder="Product">
                    <mat-option *ngFor="let product of products" [value]="product['ID']">
                      {{ treatmentUtil.getLabel(product['Label']) }}
                    </mat-option>
                  </mat-select>

                  <button class="" *ngIf="productID" (click)="$event.stopPropagation(); viewProduct(productID)"
                          matTooltip="View Product" color="accent" mat-button matSuffix>
                    <mat-icon>visibility</mat-icon>
                  </button>
                </mat-form-field>

                <!-- </div> -->
              </div>

              <div class="row clearfix flex full-width" *ngIf="distributorID || manufacturerID">
                <div class="block clearfix full-width clicktext" *ngIf="manufacturerID && manufacturerName"
                     matTooltip="Click to view Manufacturer details">
                  <p class="small rm-m">Manufacturer</p>
                  <h4 class="reduce-marg" (click)="viewSupplier(manufacturerID)">{{ manufacturerName }}</h4>
                </div>
                <div class="block clearfix full-width clicktext" class="block clearfix full-width clicktext"
                     *ngIf="distributorID && distributorName" matTooltip="Click to view Distributor details">
                  <p class="small rm-m">Distributor</p>
                  <h4 class="reduce-marg" (click)="viewSupplier(distributorID)">{{ distributorName }}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row clearfix mt">
          <div class="row clearfix">
            <h3 class="sr-title rm-mt">Document Settings</h3>
            <div class="row clearfix">
              <mat-form-field class="full-width primary-color" appearance="outline">
                <mat-label>Enter name of document</mat-label>
                <input class="mat-block" [(ngModel)]="label" name="documentName" placeholder="Enter name of document"
                       matInput required/>
              </mat-form-field>
            </div>
            <div class="row clearfix flex">
              <mat-form-field class="full-width rm-ml" appearance="outline">
                <mat-label>Document group</mat-label>
                <mat-select class="full-width" [(ngModel)]="group" [disabled]="disableGroup" name="docGroup"
                            placeholder="Document group" required>
                  <mat-option *ngFor="let dg of documentGroups" [value]="dg.code">
                    {{ dg.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <div class="ml" *ngIf="disableGroup == false">
                <mat-slide-toggle class="mt" [(ngModel)]="isRequired" name="consentPatient">
                  Mandatory reading
                </mat-slide-toggle>
              </div>
            </div>
            <div class="row clearfix">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Description</mat-label>
                <textarea class="dentMsg increase-input-field" [(ngModel)]="description" name="dentMsg" matInput
                          placeholder="Description"></textarea>
              </mat-form-field>
            </div>
            <div class="row clearfix">
              <mat-form-field class="full-width primary-color" appearance="outline">
                <mat-label>Enter some tags to define this document</mat-label>
                <input class="mat-block" [(ngModel)]="tags" name="documentTags"
                       placeholder="Enter some tags to define this document" matInput/>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="row clearfix">
          <div class="row clearfix">
            <h3 class="sr-title rm-mt">Document Link/Upload</h3>
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Document Type</mat-label>

              <mat-select class="full-width" [(ngModel)]="type" name="docType" placeholder="Document Type" required>
                <mat-option *ngFor="let dt of documentTypes" [value]="dt.code">
                  {{ dt.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <!-- video link -->
            <div class="row clearfix mt mb" *ngIf="type == 'video'">
              <mat-form-field class="full-width primary-color" appearance="outline">
                <mat-label>Enter Youtube/Vimeo URL</mat-label>
                <input class="mat-block" [(ngModel)]="url" name="documentVideoLonk"
                       placeholder="Enter Youtube/Vimeo URL" matInput required/>
              </mat-form-field>
            </div>
            <!-- external link -->
            <div class="row clearfix mt mb" *ngIf="type == 'url'">
              <mat-form-field class="full-width primary-color" appearance="outline">
                <mat-label>Enter external page URL</mat-label>
                <input class="mat-block" [(ngModel)]="url" name="documentPageLonk" placeholder="Enter external page URL"
                       matInput required/>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="row clearfix" *ngIf="type == 'fileID'">
          <app-file-uploader class="text-center" [isDragable]="true" [limit]="1"
                             (onCompleteAll)="onCompleteAllFile($event)"
                             (isUploaded)="checkUploadCompleted($event)"></app-file-uploader>

          <app-file-list [showInfo]="false" [hasExtraInfo]="false" [files]="fileID" [canPrivateDocument]="false"
                         [documentTypes]="[]" [canGallery]="false" [hasExtraInfo]="false" [invitationID]="''"
                         [canEdit]="false"
                         [canAction]="false" (remove)="removeNewTreatmentDocument($event)">
          </app-file-list>
        </div>

        <div class="row clearfix mt" *ngIf="type">
          <div class="row clearfix">
            <h3 class="sr-title rm-mt">Customized Thumbnail</h3>
            <p class="small mb">If you leave this field empty, the default image will be used as a thumbnail.</p>
          </div>
        </div>
        <div class="row clearfix" *ngIf="type">
          <app-file-uploader class="text-center" [isFixedAspectRatio]="true" [isDragable]="true" [limit]="1"
                             (onCompleteAll)="onCompleteAllFileThumbnail($event)"></app-file-uploader>

          <app-file-list [showInfo]="false" [hasExtraInfo]="false" [files]="thumbnailFileID"
                         [canPrivateDocument]="false" [documentTypes]="[]" [canGallery]="false" [hasExtraInfo]="false"
                         [invitationID]="''" [canEdit]="false" [canAction]="false"
                         (remove)="removeDocumentThumbnail($event)">
          </app-file-list>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <div class="button-row row text-center clearfix mt">
    <button class="text-center"
      [disabled]="!createTreatmentDoc.form.valid || (isUploadCompleted == false && type == 'Document')"
            (click)="createNewDoc()" mat-raised-button color="primary">
      Save
    </button>
  </div>
</form>
