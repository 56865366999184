<div class="rel text-center">
  <mat-icon class="xlarge-modal-icon primary-color">help</mat-icon>
  <h2 class="sr-title sm-h2 rm-mb mt" mat-dialog-title>Select Invitation Type</h2>
  <mat-dialog-content>
    <div class="row clearfix">
      <p class="larger">
        <strong>{{ "KEYWORD.treatment" | translate | titlecase }} Invitations</strong> are a quick and easy way to
        communicate
        proposed {{ "KEYWORD.treatment" | translate }} to {{ "KEYWORD.patient" | translate }}s
      </p>
    </div>
    <div class="row clearfix features">
      <div class="half-width">
        <div class="col-left">
          <h3 class="sm-h3 text-center sr-title rm-mb rm-mt">Simple</h3>
          <ul class="disc text-left">
            <li>Quick Entry</li>
            <li>Email &amp; SMS {{ "KEYWORD.patient" | translate }}s</li>
            <li>Schedule message delivery*</li>
            <li>Informed Consent</li>
            <li>Pre &amp; Post Op supporting documents*</li>
            <li>Upload {{ "KEYWORD.practice" | translate }} documents*</li>
            <li>Personalise {{ "KEYWORD.patient" | translate }} message*</li>
            <li>Finance Offered</li>
            <li>Automated {{ "KEYWORD.patient" | translate }} reminders</li>
          </ul>
        </div>
      </div>
      <div class="half-width">
        <div class="col-right">
          <h3 class="sm-h3 text-center sr-title rm-mb rm-mt">Advanced</h3>
          <ul class="disc text-left">
            <li>Line itemised {{ 'PHRASE.treatment plan' | translate }}</li>
            <li>Visual representation to {{ "KEYWORD.patient" | translate }}s</li>
            <li>Diagnosis &amp; {{ "KEYWORD.treatment" | translate }} descriptions</li>
            <li>Default pricing maintained by {{ "KEYWORD.practice" | translate }}</li>
            <li>Documents Supporting Pre/Post Op</li>
            <li>Informed Consent</li>
            <li>Email &amp; SMS {{ "KEYWORD.patient" | translate }}s</li>
            <li>Personalise {{ "KEYWORD.patient" | translate }} message*</li>
            <li>Upload supporting documents*</li>
            <li>Finance Offered</li>
            <li>Automated {{ "KEYWORD.patient" | translate }} reminders</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row clearfix text-center">
      <p class="small">*Optional features that you can select to use</p>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="button-row text-center add-mt">
    <div class="row clearfix text-center">
      <mat-checkbox [(ngModel)]="dontAskAgainType">Dont Show Again</mat-checkbox>
    </div>
    <hr class="mb"/>
    <button class="gradient green btn-large" (click)="startTheTour()" mat-raised-button color="primary">
      See the tutorial!
    </button>
    <button class="btn-large" (click)="onSimple()" mat-raised-button color="primary" matDialogClose>
      Simple
    </button>
    <!-- Can optionally provide a result for the closing dialog. -->
    <button class="btn-large" (click)="onAdvanced()" mat-raised-button color="accent">
      Advanced
    </button>
  </mat-dialog-actions>
</div>
