<div class="full-width">
  <p>Enter 3 first letters of your address to open the list</p>
  <!-- <mat-form-field class="full-width" appearance="outline">
    <input name="adress1" matInput placeholder="Adress (exemple: 32 Kincumber Dr, CROYDON VIC ) " required>
  </mat-form-field> -->

  <form class="example-form">
    <mat-form-field class="full-width" *ngIf="isGoogleMap == false">
      <mat-label>Adress (exemple: 32 Kincumber Dr, CROYDON VIC )</mat-label>
      <input
        #input
        [value]="input.value"
        [matAutocomplete]="auto"
        (keyup)="onSearchAddress(input.value)"
        name="address"
        type="text"
        placeholder="Adress (exemple: 32 Kincumber Dr, CROYDON VIC )"
        matInput
      />
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option
          *ngFor="let addr of addresses"
          [value]="addr.DisplayLine"
          (onSelectionChange)="onAddressSelected(addr)"
        >
          {{ addr.DisplayLine }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <div class="full-width" *ngIf="isGoogleMap == false">
      <app-map-view *ngIf="address" [positions]="[address.Geocode]" [addressLine]="address"></app-map-view>
    </div>

    <!-- google-->
    <div class="full-width" *ngIf="isGoogleMap == true">
      <mat-form-field class="full-width" appearance="outline"
      >.
        <mat-label>Address (example: 32 Kincumber Dr, CROYDON VIC )</mat-label>
        <input #search name="adress2" matInput placeholder="Address (example: 32 Kincumber Dr, CROYDON VIC )" required/>
      </mat-form-field>
      <div class="full-width">
        <app-map-view *ngIf="addressByGoogle" [positions]="[addressByGoogle]" [addressLine]="addressLine">
        </app-map-view>
      </div>
    </div>
  </form>
</div>
