/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./ai-scans-list-overview.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../ai-scans-list-global/ai-scans-list-global.component.ngfactory";
import * as i3 from "../../../shared/pipes/custom-date.pipe";
import * as i4 from "../ai-scans-list-global/ai-scans-list-global.component";
import * as i5 from "../../../shared/services/utils.service";
import * as i6 from "../../../core/authentication/shared/authentication.service";
import * as i7 from "@angular/material/dialog";
import * as i8 from "@angular/router";
import * as i9 from "../shared/services/ai-scans.service";
import * as i10 from "../../marketing/shared/marketing.service";
import * as i11 from "../../product/shared/product.service";
import * as i12 from "@angular/common";
import * as i13 from "./ai-scans-list-overview.component";
var styles_AiScansListOverViewComponent = [i0.styles];
var RenderType_AiScansListOverViewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AiScansListOverViewComponent, data: { "animation": [{ type: 7, name: "ngIfAnimation", definitions: [{ type: 1, expr: "void => *", animation: [{ type: 11, selector: "*", animation: { type: 6, styles: { opacity: 0 }, offset: null }, options: { optional: true } }, { type: 11, selector: "*", animation: { type: 12, timings: "100ms", animation: [{ type: 4, styles: { type: 5, steps: [{ type: 6, styles: { opacity: 0, transform: "translateY(-75%)", offset: 0, height: 0 }, offset: null }, { type: 6, styles: { opacity: 1, transform: "translateY(0)", offset: 1, height: "*" }, offset: null }] }, timings: "0.8s ease-out" }] }, options: { optional: true } }], options: null }, { type: 1, expr: "* => void", animation: [{ type: 11, selector: "*", animation: { type: 6, styles: { opacity: 1 }, offset: null }, options: { optional: true } }, { type: 11, selector: "*", animation: { type: 12, timings: "100ms", animation: [{ type: 4, styles: { type: 5, steps: [{ type: 6, styles: { opacity: 1, transform: "translateY(0)", offset: 0, height: "*" }, offset: null }, { type: 6, styles: { opacity: 0, transform: "translateY(-75%)", offset: 1, height: 0 }, offset: null }] }, timings: "0.8s ease-in" }] }, options: { optional: true } }], options: null }], options: {} }] } });
export { RenderType_AiScansListOverViewComponent as RenderType_AiScansListOverViewComponent };
function View_AiScansListOverViewComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-ai-scans-list-global", [], null, [[null, "detailView"], [null, "getSetPatients"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("detailView" === en)) {
        var pd_0 = (_co.detailView($event) !== false);
        ad = (pd_0 && ad);
    } if (("getSetPatients" === en)) {
        var pd_1 = (_co.getSetPatientsEvent($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_AiScansListGlobalComponent_0, i2.RenderType_AiScansListGlobalComponent)), i1.ɵprd(512, null, i3.CustomDatePipe, i3.CustomDatePipe, []), i1.ɵdid(2, 770048, null, 0, i4.AiScansListGlobalComponent, [i5.UtilsService, i6.AuthenticationService, i7.MatDialog, i8.Router, i3.CustomDatePipe, i9.AiScansService, i10.MarketingService, i11.ProductService], { merchantID: [0, "merchantID"], setPatients: [1, "setPatients"] }, { getSetPatients: "getSetPatients", detailView: "detailView" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.merchantAll; var currVal_1 = _co.setPatients; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_AiScansListOverViewComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "row clearfix"]], [[2, "PanelOpen", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "row clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AiScansListOverViewComponent_1)), i1.ɵdid(5, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = ((_co.merchantAll != "none") && _co.allList); _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.hideMerchantSelect == true); _ck(_v, 0, 0, currVal_0); }); }
export function View_AiScansListOverViewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-ai-scans-list-overview", [], null, null, null, View_AiScansListOverViewComponent_0, RenderType_AiScansListOverViewComponent)), i1.ɵdid(1, 114688, null, 0, i13.AiScansListOverViewComponent, [i6.AuthenticationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AiScansListOverViewComponentNgFactory = i1.ɵccf("app-ai-scans-list-overview", i13.AiScansListOverViewComponent, View_AiScansListOverViewComponent_Host_0, {}, {}, []);
export { AiScansListOverViewComponentNgFactory as AiScansListOverViewComponentNgFactory };
