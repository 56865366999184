/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./read-more.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../pipes/text-length.pipe";
import * as i4 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i5 from "@angular/material/icon";
import * as i6 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i7 from "@angular/material/button";
import * as i8 from "@angular/cdk/a11y";
import * as i9 from "@angular/platform-browser/animations";
import * as i10 from "../inner-html-isolated/inner-html-isolated.component.ngfactory";
import * as i11 from "../inner-html-isolated/inner-html-isolated.component";
import * as i12 from "../../pipes/safe-html.pipe";
import * as i13 from "@angular/platform-browser";
import * as i14 from "../../pipes/clear-text.pipe";
import * as i15 from "./read-more.component";
var styles_ReadMoreComponent = [i0.styles];
var RenderType_ReadMoreComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ReadMoreComponent, data: { "animation": [{ type: 7, name: "slideInOut", definitions: [{ type: 0, name: "in", styles: { type: 6, styles: { height: "*", opacity: 0 }, offset: null }, options: undefined }, { type: 1, expr: ":leave", animation: [{ type: 6, styles: { height: "*", opacity: 1 }, offset: null }, { type: 3, steps: [{ type: 4, styles: { type: 6, styles: { height: 0 }, offset: null }, timings: 300 }, { type: 4, styles: { type: 6, styles: { opacity: "0" }, offset: null }, timings: "200ms ease-in-out" }], options: null }], options: null }, { type: 1, expr: ":enter", animation: [{ type: 6, styles: { height: "0", opacity: 0 }, offset: null }, { type: 3, steps: [{ type: 4, styles: { type: 6, styles: { height: "*" }, offset: null }, timings: 300 }, { type: 4, styles: { type: 6, styles: { opacity: "1" }, offset: null }, timings: "400ms ease-in-out" }], options: null }], options: null }], options: {} }] } });
export { RenderType_ReadMoreComponent as RenderType_ReadMoreComponent };
function View_ReadMoreComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "read-more-text collapse"]], [[8, "innerHTML", 1], [24, "@slideInOut", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        $event.stopPropagation();
        var pd_0 = (_co.openReadMore() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "pointer-read-more": 0 }), i1.ɵppd(4, 1), i1.ɵpid(0, i3.TextLengthPipe, []), i1.ɵppd(6, 1)], function (_ck, _v) { var _co = _v.component; var currVal_2 = "read-more-text collapse"; var currVal_3 = _ck(_v, 3, 0, ((_co.hideButton != true) && (_co.isCollapsed == true))); _ck(_v, 2, 0, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 0, 0, _ck(_v, 6, 0, i1.ɵnov(_v.parent.parent.parent, 0), i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 5).transform(_co.URLify(i1.ɵunv(_v, 0, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent.parent.parent, 1), _co.currentText))), _co.maxLength)))); var currVal_1 = undefined; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_ReadMoreComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "read-more-text collapse"]], [[24, "@slideInOut", 0], [8, "innerHTML", 1]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        $event.stopPropagation();
        var pd_0 = (_co.openReadMore() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "pointer-read-more": 0 }), i1.ɵppd(4, 1), i1.ɵpid(0, i3.TextLengthPipe, []), i1.ɵppd(6, 1)], function (_ck, _v) { var _co = _v.component; var currVal_2 = "read-more-text collapse"; var currVal_3 = _ck(_v, 3, 0, ((_co.hideButton != true) && (_co.isCollapsed == true))); _ck(_v, 2, 0, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = undefined; var currVal_1 = i1.ɵunv(_v, 0, 1, _ck(_v, 6, 0, i1.ɵnov(_v.parent.parent.parent, 0), i1.ɵunv(_v, 0, 1, i1.ɵnov(_v, 5).transform(i1.ɵunv(_v, 0, 1, _ck(_v, 4, 0, i1.ɵnov(_v.parent.parent.parent, 1), _co.header)), _co.maxLength)))); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_ReadMoreComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "read-more-text open"]], [[24, "@slideInOut", 0], [8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(1, 1), i1.ɵppd(2, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = undefined; var currVal_1 = i1.ɵunv(_v, 0, 1, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent.parent, 0), _co.URLify(i1.ɵunv(_v, 0, 1, _ck(_v, 1, 0, i1.ɵnov(_v.parent.parent.parent, 1), _co.currentText))))); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_ReadMoreComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i4.View_MatIcon_0, i4.RenderType_MatIcon)), i1.ɵdid(1, 9158656, null, 0, i5.MatIcon, [i1.ElementRef, i5.MatIconRegistry, [8, null], [2, i5.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["expand_more"]))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).inline; var currVal_1 = (((i1.ɵnov(_v, 1).color !== "primary") && (i1.ɵnov(_v, 1).color !== "accent")) && (i1.ɵnov(_v, 1).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_ReadMoreComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i4.View_MatIcon_0, i4.RenderType_MatIcon)), i1.ɵdid(1, 9158656, null, 0, i5.MatIcon, [i1.ElementRef, i5.MatIconRegistry, [8, null], [2, i5.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["expand_less"]))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).inline; var currVal_1 = (((i1.ɵnov(_v, 1).color !== "primary") && (i1.ɵnov(_v, 1).color !== "accent")) && (i1.ɵnov(_v, 1).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_ReadMoreComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "rel"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReadMoreComponent_3)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReadMoreComponent_4)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReadMoreComponent_5)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 0, "hr", [], [[2, "hidden", null]], null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 6, "button", [["class", "btn-small readMoreBtn pull-right"], ["mat-button", ""]], [[2, "hidden", null], [1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        $event.stopPropagation();
        var pd_0 = (_co.toggleView() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_MatButton_0, i6.RenderType_MatButton)), i1.ɵdid(9, 180224, null, 0, i7.MatButton, [i1.ElementRef, i8.FocusMonitor, [2, i9.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(10, 0, [" Read ", " "])), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ReadMoreComponent_6)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ReadMoreComponent_7)), i1.ɵdid(14, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.header && _co.isCollapsed); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.header && _co.isCollapsed); _ck(_v, 4, 0, currVal_1); var currVal_2 = !_co.isCollapsed; _ck(_v, 6, 0, currVal_2); var currVal_8 = _co.isCollapsed; _ck(_v, 12, 0, currVal_8); var currVal_9 = !_co.isCollapsed; _ck(_v, 14, 0, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.hideToggle; _ck(_v, 7, 0, currVal_3); var currVal_4 = _co.hideToggle; var currVal_5 = (i1.ɵnov(_v, 9).disabled || null); var currVal_6 = (i1.ɵnov(_v, 9)._animationMode === "NoopAnimations"); _ck(_v, 8, 0, currVal_4, currVal_5, currVal_6); var currVal_7 = (_co.isCollapsed ? _co.textMore : _co.textLess); _ck(_v, 10, 0, currVal_7); }); }
function View_ReadMoreComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "read-more-text collapse just-text"]], [[24, "@slideInOut", 0], [8, "innerHTML", 1]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        $event.stopPropagation();
        var pd_0 = (_co.openReadMore() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "pointer-read-more": 0 }), i1.ɵppd(4, 1), i1.ɵpid(0, i3.TextLengthPipe, []), i1.ɵppd(6, 1)], function (_ck, _v) { var _co = _v.component; var currVal_2 = "read-more-text collapse just-text"; var currVal_3 = _ck(_v, 3, 0, (((_co.hideButton != true) && (_co.isCollapsed == true)) && (_co.canCollapse == true))); _ck(_v, 2, 0, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = undefined; var currVal_1 = i1.ɵunv(_v, 0, 1, _ck(_v, 6, 0, i1.ɵnov(_v.parent.parent.parent, 0), i1.ɵunv(_v, 0, 1, i1.ɵnov(_v, 5).transform(i1.ɵunv(_v, 0, 1, _ck(_v, 4, 0, i1.ɵnov(_v.parent.parent.parent, 1), _co.currentText)), _co.maxLength)))); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_ReadMoreComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "read-more-text collapse just-text-header"]], [[24, "@slideInOut", 0], [8, "innerHTML", 1]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        $event.stopPropagation();
        var pd_0 = (_co.openReadMore() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "pointer-read-more": 0 }), i1.ɵppd(4, 1), i1.ɵpid(0, i3.TextLengthPipe, []), i1.ɵppd(6, 1)], function (_ck, _v) { var _co = _v.component; var currVal_2 = "read-more-text collapse just-text-header"; var currVal_3 = _ck(_v, 3, 0, (((_co.hideButton != true) && (_co.isCollapsed == true)) && (_co.canCollapse == true))); _ck(_v, 2, 0, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = undefined; var currVal_1 = i1.ɵunv(_v, 0, 1, _ck(_v, 6, 0, i1.ɵnov(_v.parent.parent.parent, 0), i1.ɵunv(_v, 0, 1, i1.ɵnov(_v, 5).transform(i1.ɵunv(_v, 0, 1, _ck(_v, 4, 0, i1.ɵnov(_v.parent.parent.parent, 1), _co.header)), _co.maxLength)))); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_ReadMoreComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "read-more-text open"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-inner-html-isolated", [], null, null, null, i10.View_InnerHtmlIsolatedComponent_0, i10.RenderType_InnerHtmlIsolatedComponent)), i1.ɵdid(2, 114688, null, 0, i11.InnerHtmlIsolatedComponent, [], { content: [0, "content"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentText; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ReadMoreComponent_13(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-icon", [["class", "fas fa-sort-down icon1 mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i4.View_MatIcon_0, i4.RenderType_MatIcon)), i1.ɵdid(1, 9158656, null, 0, i5.MatIcon, [i1.ElementRef, i5.MatIconRegistry, [8, null], [2, i5.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).inline; var currVal_1 = (((i1.ɵnov(_v, 1).color !== "primary") && (i1.ɵnov(_v, 1).color !== "accent")) && (i1.ɵnov(_v, 1).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_ReadMoreComponent_14(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-icon", [["class", "fas fa-sort-up icon2 mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i4.View_MatIcon_0, i4.RenderType_MatIcon)), i1.ɵdid(1, 9158656, null, 0, i5.MatIcon, [i1.ElementRef, i5.MatIconRegistry, [8, null], [2, i5.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).inline; var currVal_1 = (((i1.ɵnov(_v, 1).color !== "primary") && (i1.ɵnov(_v, 1).color !== "accent")) && (i1.ɵnov(_v, 1).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_ReadMoreComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "button", [["class", "btn-small readMoreBtn2 pull-right"], ["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        $event.stopPropagation();
        var pd_0 = (_co.toggleView() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_MatButton_0, i6.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i7.MatButton, [i1.ElementRef, i8.FocusMonitor, [2, i9.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(2, 0, [" ", " "])), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ReadMoreComponent_13)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ReadMoreComponent_14)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.isCollapsed; _ck(_v, 4, 0, currVal_3); var currVal_4 = !_co.isCollapsed; _ck(_v, 6, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = (_co.isCollapsed ? _co.textMore : _co.textLess); _ck(_v, 2, 0, currVal_2); }); }
function View_ReadMoreComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "rel"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReadMoreComponent_9)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReadMoreComponent_10)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReadMoreComponent_11)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReadMoreComponent_12)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.header && _co.isCollapsed); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.header && _co.isCollapsed); _ck(_v, 4, 0, currVal_1); var currVal_2 = (((_co.currentText && (_co.currentText !== undefined)) && (_co.currentText !== "undefined")) && !_co.isCollapsed); _ck(_v, 6, 0, currVal_2); var currVal_3 = ((_co.hideButton != true) && (_co.hideButtonForce != true)); _ck(_v, 8, 0, currVal_3); }, null); }
function View_ReadMoreComponent_16(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "read-more-text collapse just-text"]], [[8, "innerHTML", 1]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        $event.stopPropagation();
        var pd_0 = (_co.openReadMore() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "pointer-read-more": 0 }), i1.ɵppd(4, 1), i1.ɵpid(0, i3.TextLengthPipe, []), i1.ɵppd(6, 1)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "read-more-text collapse just-text"; var currVal_2 = _ck(_v, 3, 0, (((_co.hideButton != true) && (_co.isCollapsed == true)) && (_co.canCollapse == true))); _ck(_v, 2, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 0, 0, _ck(_v, 6, 0, i1.ɵnov(_v.parent.parent.parent, 0), i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 5).transform(i1.ɵunv(_v, 0, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent.parent.parent, 1), _co.currentText)), _co.maxLength)))); _ck(_v, 0, 0, currVal_0); }); }
function View_ReadMoreComponent_17(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "read-more-text collapse just-text-header"]], [[8, "innerHTML", 1]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        $event.stopPropagation();
        var pd_0 = (_co.openReadMore() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "pointer-read-more": 0 }), i1.ɵppd(4, 1), i1.ɵpid(0, i3.TextLengthPipe, []), i1.ɵppd(6, 1)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "read-more-text collapse just-text-header"; var currVal_2 = _ck(_v, 3, 0, (((_co.hideButton != true) && (_co.isCollapsed == true)) && (_co.canCollapse == true))); _ck(_v, 2, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 0, 0, _ck(_v, 6, 0, i1.ɵnov(_v.parent.parent.parent, 0), i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 5).transform(i1.ɵunv(_v, 0, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent.parent.parent, 1), _co.header)), _co.maxLength)))); _ck(_v, 0, 0, currVal_0); }); }
function View_ReadMoreComponent_18(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "read-more-text open"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-inner-html-isolated", [], null, null, null, i10.View_InnerHtmlIsolatedComponent_0, i10.RenderType_InnerHtmlIsolatedComponent)), i1.ɵdid(2, 114688, null, 0, i11.InnerHtmlIsolatedComponent, [], { content: [0, "content"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentText; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ReadMoreComponent_20(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-icon", [["class", "fas fa-sort-down icon1 mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i4.View_MatIcon_0, i4.RenderType_MatIcon)), i1.ɵdid(1, 9158656, null, 0, i5.MatIcon, [i1.ElementRef, i5.MatIconRegistry, [8, null], [2, i5.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).inline; var currVal_1 = (((i1.ɵnov(_v, 1).color !== "primary") && (i1.ɵnov(_v, 1).color !== "accent")) && (i1.ɵnov(_v, 1).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_ReadMoreComponent_21(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-icon", [["class", "fas fa-sort-up icon2 mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i4.View_MatIcon_0, i4.RenderType_MatIcon)), i1.ɵdid(1, 9158656, null, 0, i5.MatIcon, [i1.ElementRef, i5.MatIconRegistry, [8, null], [2, i5.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).inline; var currVal_1 = (((i1.ɵnov(_v, 1).color !== "primary") && (i1.ɵnov(_v, 1).color !== "accent")) && (i1.ɵnov(_v, 1).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_ReadMoreComponent_19(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "button", [["class", "btn-small readMoreBtn2 pull-right"], ["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        $event.stopPropagation();
        var pd_0 = (_co.toggleView() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_MatButton_0, i6.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i7.MatButton, [i1.ElementRef, i8.FocusMonitor, [2, i9.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(2, 0, [" ", " "])), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ReadMoreComponent_20)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ReadMoreComponent_21)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.isCollapsed; _ck(_v, 4, 0, currVal_3); var currVal_4 = !_co.isCollapsed; _ck(_v, 6, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = (_co.isCollapsed ? _co.textMore : _co.textLess); _ck(_v, 2, 0, currVal_2); }); }
function View_ReadMoreComponent_15(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "rel"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReadMoreComponent_16)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReadMoreComponent_17)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReadMoreComponent_18)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReadMoreComponent_19)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.header && _co.isCollapsed); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.header && _co.isCollapsed); _ck(_v, 4, 0, currVal_1); var currVal_2 = (((_co.currentText && (_co.currentText !== undefined)) && (_co.currentText !== "undefined")) && !_co.isCollapsed); _ck(_v, 6, 0, currVal_2); var currVal_3 = ((_co.hideButton != true) && (_co.hideButtonForce != true)); _ck(_v, 8, 0, currVal_3); }, null); }
function View_ReadMoreComponent_23(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "read-more-text collapse just-text"]], [[24, "@slideInOut", 0], [8, "innerHTML", 1]], null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "pointer-read-more": 0 }), i1.ɵppd(4, 1), i1.ɵpid(0, i3.TextLengthPipe, []), i1.ɵppd(6, 1)], function (_ck, _v) { var _co = _v.component; var currVal_2 = "read-more-text collapse just-text"; var currVal_3 = _ck(_v, 3, 0, (((_co.hideButton != true) && (_co.isCollapsed == true)) && (_co.canCollapse == true))); _ck(_v, 2, 0, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = undefined; var currVal_1 = i1.ɵunv(_v, 0, 1, _ck(_v, 6, 0, i1.ɵnov(_v.parent.parent.parent, 0), i1.ɵunv(_v, 0, 1, i1.ɵnov(_v, 5).transform(i1.ɵunv(_v, 0, 1, _ck(_v, 4, 0, i1.ɵnov(_v.parent.parent.parent, 1), _co.currentText)), _co.maxLength)))); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_ReadMoreComponent_24(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "read-more-text collapse just-text-header"]], [[24, "@slideInOut", 0], [8, "innerHTML", 1]], null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "pointer-read-more": 0 }), i1.ɵppd(4, 1), i1.ɵpid(0, i3.TextLengthPipe, []), i1.ɵppd(6, 1)], function (_ck, _v) { var _co = _v.component; var currVal_2 = "read-more-text collapse just-text-header"; var currVal_3 = _ck(_v, 3, 0, (((_co.hideButton != true) && (_co.isCollapsed == true)) && (_co.canCollapse == true))); _ck(_v, 2, 0, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = undefined; var currVal_1 = i1.ɵunv(_v, 0, 1, _ck(_v, 6, 0, i1.ɵnov(_v.parent.parent.parent, 0), i1.ɵunv(_v, 0, 1, i1.ɵnov(_v, 5).transform(i1.ɵunv(_v, 0, 1, _ck(_v, 4, 0, i1.ɵnov(_v.parent.parent.parent, 1), _co.header)), _co.maxLength)))); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_ReadMoreComponent_25(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "read-more-text collapse just-text"]], [[8, "innerHTML", 1], [24, "@slideInOut", 0]], null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "pointer-read-more": 0 }), i1.ɵppd(4, 1), i1.ɵpid(0, i3.TextLengthPipe, []), i1.ɵppd(6, 1)], function (_ck, _v) { var _co = _v.component; var currVal_2 = "read-more-text collapse just-text"; var currVal_3 = _ck(_v, 3, 0, (((_co.hideButton != true) && (_co.isCollapsed == true)) && (_co.canCollapse == true))); _ck(_v, 2, 0, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 0, 0, _ck(_v, 6, 0, i1.ɵnov(_v.parent.parent.parent, 0), i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 5).transform(i1.ɵunv(_v, 0, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent.parent.parent, 1), _co.currentText)), _co.maxLength)))); var currVal_1 = undefined; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_ReadMoreComponent_26(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "read-more-text collapse just-text-header"]], [[8, "innerHTML", 1], [24, "@slideInOut", 0]], null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "pointer-read-more": 0 }), i1.ɵppd(4, 1), i1.ɵpid(0, i3.TextLengthPipe, []), i1.ɵppd(6, 1)], function (_ck, _v) { var _co = _v.component; var currVal_2 = "read-more-text collapse just-text-header"; var currVal_3 = _ck(_v, 3, 0, (((_co.hideButton != true) && (_co.isCollapsed == true)) && (_co.canCollapse == true))); _ck(_v, 2, 0, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 0, 0, _ck(_v, 6, 0, i1.ɵnov(_v.parent.parent.parent, 0), i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 5).transform(i1.ɵunv(_v, 0, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent.parent.parent, 1), _co.header)), _co.maxLength)))); var currVal_1 = undefined; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_ReadMoreComponent_27(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "read-more-text open"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-inner-html-isolated", [], null, null, null, i10.View_InnerHtmlIsolatedComponent_0, i10.RenderType_InnerHtmlIsolatedComponent)), i1.ɵdid(2, 114688, null, 0, i11.InnerHtmlIsolatedComponent, [], { content: [0, "content"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentText; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ReadMoreComponent_29(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-icon", [["class", "fas fa-sort-down icon1  mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i4.View_MatIcon_0, i4.RenderType_MatIcon)), i1.ɵdid(1, 9158656, null, 0, i5.MatIcon, [i1.ElementRef, i5.MatIconRegistry, [8, null], [2, i5.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).inline; var currVal_1 = (((i1.ɵnov(_v, 1).color !== "primary") && (i1.ɵnov(_v, 1).color !== "accent")) && (i1.ɵnov(_v, 1).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_ReadMoreComponent_30(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-icon", [["class", "fas fa-sort-up icon2 mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i4.View_MatIcon_0, i4.RenderType_MatIcon)), i1.ɵdid(1, 9158656, null, 0, i5.MatIcon, [i1.ElementRef, i5.MatIconRegistry, [8, null], [2, i5.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).inline; var currVal_1 = (((i1.ɵnov(_v, 1).color !== "primary") && (i1.ɵnov(_v, 1).color !== "accent")) && (i1.ɵnov(_v, 1).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_ReadMoreComponent_28(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "button", [["class", "btn-small readMoreBtn2 pull-right"], ["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        $event.stopPropagation();
        var pd_0 = (_co.toggleView() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_MatButton_0, i6.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i7.MatButton, [i1.ElementRef, i8.FocusMonitor, [2, i9.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(2, 0, [" ", " "])), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ReadMoreComponent_29)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ReadMoreComponent_30)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.isCollapsed; _ck(_v, 4, 0, currVal_3); var currVal_4 = !_co.isCollapsed; _ck(_v, 6, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = (_co.isCollapsed ? _co.textMore : _co.textLess); _ck(_v, 2, 0, currVal_2); }); }
function View_ReadMoreComponent_22(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "rel"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReadMoreComponent_23)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReadMoreComponent_24)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReadMoreComponent_25)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReadMoreComponent_26)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReadMoreComponent_27)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReadMoreComponent_28)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.header && _co.isCollapsed); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.header && _co.isCollapsed); _ck(_v, 4, 0, currVal_1); var currVal_2 = (!_co.header && _co.isCollapsed); _ck(_v, 6, 0, currVal_2); var currVal_3 = (_co.header && _co.isCollapsed); _ck(_v, 8, 0, currVal_3); var currVal_4 = (((_co.currentText && (_co.currentText !== undefined)) && (_co.currentText !== "undefined")) && !_co.isCollapsed); _ck(_v, 10, 0, currVal_4); var currVal_5 = ((_co.hideButton != true) && (_co.hideButtonForce != true)); _ck(_v, 12, 0, currVal_5); }, null); }
function View_ReadMoreComponent_32(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "read-more-text collapse just-text"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "pointer-read-more": 0 }), i1.ɵppd(4, 1), i1.ɵpid(0, i3.TextLengthPipe, []), i1.ɵppd(6, 1)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "read-more-text collapse just-text"; var currVal_2 = _ck(_v, 3, 0, (((_co.hideButton != true) && (_co.isCollapsed == true)) && (_co.canCollapse == true))); _ck(_v, 2, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 0, 0, _ck(_v, 6, 0, i1.ɵnov(_v.parent.parent.parent, 0), i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 5).transform(i1.ɵunv(_v, 0, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent.parent.parent, 1), _co.currentText)), _co.maxLength)))); _ck(_v, 0, 0, currVal_0); }); }
function View_ReadMoreComponent_33(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "read-more-text collapse just-text-header"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "pointer-read-more": 0 }), i1.ɵppd(4, 1), i1.ɵpid(0, i3.TextLengthPipe, []), i1.ɵppd(6, 1)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "read-more-text collapse just-text-header"; var currVal_2 = _ck(_v, 3, 0, (((_co.hideButton != true) && (_co.isCollapsed == true)) && (_co.canCollapse == true))); _ck(_v, 2, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 0, 0, _ck(_v, 6, 0, i1.ɵnov(_v.parent.parent.parent, 0), i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 5).transform(i1.ɵunv(_v, 0, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent.parent.parent, 1), _co.header)), _co.maxLength)))); _ck(_v, 0, 0, currVal_0); }); }
function View_ReadMoreComponent_34(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "read-more-text collapse just-text"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "pointer-read-more": 0 }), i1.ɵppd(4, 1), i1.ɵpid(0, i3.TextLengthPipe, []), i1.ɵppd(6, 1)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "read-more-text collapse just-text"; var currVal_2 = _ck(_v, 3, 0, (((_co.hideButton != true) && (_co.isCollapsed == true)) && (_co.canCollapse == true))); _ck(_v, 2, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 0, 0, _ck(_v, 6, 0, i1.ɵnov(_v.parent.parent.parent, 0), i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 5).transform(i1.ɵunv(_v, 0, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent.parent.parent, 1), _co.currentText)), _co.maxLength)))); _ck(_v, 0, 0, currVal_0); }); }
function View_ReadMoreComponent_35(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "read-more-text collapse just-text-header"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "pointer-read-more": 0 }), i1.ɵppd(4, 1), i1.ɵpid(0, i3.TextLengthPipe, []), i1.ɵppd(6, 1)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "read-more-text collapse just-text-header"; var currVal_2 = _ck(_v, 3, 0, (((_co.hideButton != true) && (_co.isCollapsed == true)) && (_co.canCollapse == true))); _ck(_v, 2, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 0, 0, _ck(_v, 6, 0, i1.ɵnov(_v.parent.parent.parent, 0), i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 5).transform(i1.ɵunv(_v, 0, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent.parent.parent, 1), _co.header)), _co.maxLength)))); _ck(_v, 0, 0, currVal_0); }); }
function View_ReadMoreComponent_36(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "read-more-text open"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-inner-html-isolated", [], null, null, null, i10.View_InnerHtmlIsolatedComponent_0, i10.RenderType_InnerHtmlIsolatedComponent)), i1.ɵdid(2, 114688, null, 0, i11.InnerHtmlIsolatedComponent, [], { content: [0, "content"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentText; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ReadMoreComponent_38(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-icon", [["class", "fas fa-sort-down icon1  mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i4.View_MatIcon_0, i4.RenderType_MatIcon)), i1.ɵdid(1, 9158656, null, 0, i5.MatIcon, [i1.ElementRef, i5.MatIconRegistry, [8, null], [2, i5.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).inline; var currVal_1 = (((i1.ɵnov(_v, 1).color !== "primary") && (i1.ɵnov(_v, 1).color !== "accent")) && (i1.ɵnov(_v, 1).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_ReadMoreComponent_39(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-icon", [["class", "fas fa-sort-up icon2 mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i4.View_MatIcon_0, i4.RenderType_MatIcon)), i1.ɵdid(1, 9158656, null, 0, i5.MatIcon, [i1.ElementRef, i5.MatIconRegistry, [8, null], [2, i5.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).inline; var currVal_1 = (((i1.ɵnov(_v, 1).color !== "primary") && (i1.ɵnov(_v, 1).color !== "accent")) && (i1.ɵnov(_v, 1).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_ReadMoreComponent_37(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "button", [["class", "btn-small readMoreBtn2 pull-right"], ["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        $event.stopPropagation();
        var pd_0 = (_co.toggleView() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_MatButton_0, i6.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i7.MatButton, [i1.ElementRef, i8.FocusMonitor, [2, i9.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(2, 0, [" ", " "])), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ReadMoreComponent_38)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ReadMoreComponent_39)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.isCollapsed; _ck(_v, 4, 0, currVal_3); var currVal_4 = !_co.isCollapsed; _ck(_v, 6, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = (_co.isCollapsed ? _co.textMore : _co.textLess); _ck(_v, 2, 0, currVal_2); }); }
function View_ReadMoreComponent_31(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "rel"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReadMoreComponent_32)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReadMoreComponent_33)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReadMoreComponent_34)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReadMoreComponent_35)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReadMoreComponent_36)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReadMoreComponent_37)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.header && _co.isCollapsed); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.header && _co.isCollapsed); _ck(_v, 4, 0, currVal_1); var currVal_2 = (!_co.header && _co.isCollapsed); _ck(_v, 6, 0, currVal_2); var currVal_3 = (_co.header && _co.isCollapsed); _ck(_v, 8, 0, currVal_3); var currVal_4 = (((_co.currentText && (_co.currentText !== undefined)) && (_co.currentText !== "undefined")) && !_co.isCollapsed); _ck(_v, 10, 0, currVal_4); var currVal_5 = ((_co.hideButton != true) && (_co.hideButtonForce != true)); _ck(_v, 12, 0, currVal_5); }, null); }
function View_ReadMoreComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["style", "    white-space: pre-line;"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReadMoreComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReadMoreComponent_8)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReadMoreComponent_15)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReadMoreComponent_22)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReadMoreComponent_31)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.design == "mode1"); _ck(_v, 2, 0, currVal_0); var currVal_1 = ((_co.design == "mode2") && (_co.animated == true)); _ck(_v, 4, 0, currVal_1); var currVal_2 = ((_co.design == "mode2") && (_co.animated != true)); _ck(_v, 6, 0, currVal_2); var currVal_3 = ((_co.design == "noPropagation") && (_co.animated == true)); _ck(_v, 8, 0, currVal_3); var currVal_4 = ((_co.design == "noPropagation") && (_co.animated != true)); _ck(_v, 10, 0, currVal_4); }, null); }
function View_ReadMoreComponent_40(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "lds-ellipsis"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [], null, null, null, null, null))], null, null); }
export function View_ReadMoreComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i12.SafeHtmlPipe, [i13.DomSanitizer]), i1.ɵpid(0, i14.ClearTextPipe, []), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReadMoreComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReadMoreComponent_40)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.loading !== true); _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.loading === true); _ck(_v, 5, 0, currVal_1); }, null); }
export function View_ReadMoreComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "read-more", [], null, null, null, View_ReadMoreComponent_0, RenderType_ReadMoreComponent)), i1.ɵdid(1, 638976, null, 0, i15.ReadMoreComponent, [i1.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ReadMoreComponentNgFactory = i1.ɵccf("read-more", i15.ReadMoreComponent, View_ReadMoreComponent_Host_0, { text: "text", header: "header", maxLength: "maxLength", design: "design", animated: "animated", isCollapsed: "isCollapsed", textMore: "textMore", textLess: "textLess", canCollapse: "canCollapse", hideButtonForce: "hideButtonForce", hideButton: "hideButton", loading: "loading" }, { getCollapse: "getCollapse" }, []);
export { ReadMoreComponentNgFactory as ReadMoreComponentNgFactory };
