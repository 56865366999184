<mat-card
  class="rel supplier-card brandView clearfix mb"
  id="subscription-plan"
  @ngIfAnimation
  [ngClass]="isContentPage == true ? 'create-container-content' : 'create-container'"
>
  <div class="card-header primary-gradient-img clearfix inModal rel" @ngIfAnimation>
    <div class="row clearfix content">
      <div class="row titleArea clearfix">
        <h2 class="message-title summary-header rm-m">
          <span *ngIf="!editorComponentID">Create Editor Component</span>
          <span *ngIf="editorComponentID">Edit Editor Component </span>
          <span class="subLabel">Insert a label and a description for editor component </span>
        </h2>
      </div>
    </div>
  </div>

  <div class="full-width" @ngIfAnimation>
    <aw-wizard
      class="template-subscription-plan-create"
      #wizard
      [disableNavigationBar]="true"
      navBarLayout="large-filled-symbols"
    >
      <aw-wizard-step [navigationSymbol]="{ symbol: '1' }" stepTitle="Define Editor Component" awOptionalStep>
        <div class="full-width clearfix mt">
          <form #resetform="ngForm">
            <h3 class="sr-title mt">Editor Component details</h3>
            <div class="row clearfix full-width flex mt">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Label</mat-label>
                <input [(ngModel)]="label" matInput placeholder="Label" name="label" required/>
              </mat-form-field>
            </div>

            <div class="full-width clearfix mb">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Description</mat-label>
                <textarea
                  class="min-height"
                  [(ngModel)]="description"
                  name="description"
                  matInput
                  placeholder="Description"
                  required
                ></textarea>
              </mat-form-field>
            </div>

            <div class="full-width clearfix smb">
              <mat-form-field class="qtr-width pull-left" appearance="outline">
                <mat-label>Type</mat-label>

                <mat-select [(ngModel)]="typeCode" name="typeCode" placeholder="Type">
                  <mat-option *ngFor="let m of types" [value]="m.Code">
                    {{ m.Label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-slide-toggle class="thrd-width mt ml pull-left" [(ngModel)]="isActive" name="isActive">
                is Active Component?
              </mat-slide-toggle>

              <mat-slide-toggle
                class="thrd-width mt pull-left"
                [(ngModel)]="isDefault"
                name="isDefault"
                appearance="outline"
              >
                Is Default Component?
              </mat-slide-toggle>
            </div>

            <div class="full-width clearfix" *ngIf="!editorComponentID">
              <mat-form-field class="qtr-width pull-left" appearance="outline">
                <mat-label>Nature</mat-label>

                <mat-select [(ngModel)]="natureCode" name="natureCode" placeholder="Nature">
                  <mat-option *ngFor="let m of natures" [value]="m.Code">
                    {{ m.Label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <p class="pull-left ml" *ngIf="natureCode === 'Style'">
                CSS content To style a specific element, the user can apply multiple ones while editing the Html content
              </p>
              <p class="pull-left ml" *ngIf="natureCode === 'Template'">
                HTML content, it should be a component to be <strong>Added</strong> to your content, e.g. footer, image,
                content block...etc.
              </p>
              <p class="pull-left ml" *ngIf="natureCode === 'Theme'">
                CSS content to style the whole html page, the user can only apply one at the time.
              </p>
              <p class="pull-left ml" *ngIf="natureCode === 'PageTem'">
                HTML and CSS to be apply at the begining of creating the content, it will override any other content.
              </p>
            </div>

            <hr class="mb"/>
            <div class="drop-button text-center full-width mt">
              <button class="btn-large pull-left" (click)="componentList()" mat-raised-button color="primary">
                <mat-icon>chevron_left</mat-icon> Component List
              </button>

              <button
                class="btn-large"
                [disabled]="!resetform.form.valid || !description"
                (click)="save(false)"
                mat-raised-button
                color="primary"
              >
                <mat-icon class="smr">save</mat-icon>Save Draft
              </button>

              <button
                class="btn-large pull-right"
                [disabled]="!resetform.form.valid || !description"
                (click)="proceed(true)"
                mat-raised-button
                color="accent"
              >
                Next
                <mat-icon>chevron_right</mat-icon>
              </button>
            </div>
          </form>
        </div>
      </aw-wizard-step>

      <aw-wizard-step [navigationSymbol]="{ symbol: '2' }" stepTitle="Add Content" awOptionalStep>
        <div class="full-width clearfix mt">
          <form #resetform="ngForm">
            <h3 class="sr-title half-width main-content-title mt">Element Content</h3>

            <div class="full-width" *ngIf="loaded == true">
              <div class="full-width" *ngIf="natureCode === 'Template'">
                <app-code-html-css-input
                  class="full-width"
                  [isComponent]="true"
                  [isEditStyle]="isEditStyle"
                  [componentID]="componentID"
                  [displayUploader]="false"
                  [content]="defaultContent"
                  [style]="defaultStyle"
                  [descriptionHTML]="descriptionHTML"
                  [descriptionRaw]="descriptionHTML"
                  [descriptionDocument]="descriptionHTML"
                  [descriptionEditor]="descriptionHTML"
                  (getIsEditStyle)="getIsEditStyleEvent($event)"
                  (getStyle)="getStyleComponent($event)"
                  (getContent)="getContentComponent($event)"
                >
                </app-code-html-css-input>
              </div>

              <div class="full-width" *ngIf="natureCode === 'Style'">
                <app-code-html-css-input
                  class="full-width"
                  [isComponent]="false"
                  [isEditStyle]="true"
                  [descriptionHTML]="descriptionHTML"
                  [displayUploader]="false"
                  [content]="defaultContent"
                  [style]="defaultStyle"
                  [descriptionRaw]="descriptionCSSHTML"
                  (getStyle)="getStyleStyle($event)"
                  (getContent)="getContentStyle($event)"
                >
                </app-code-html-css-input>
              </div>

              <div class="full-width" *ngIf="natureCode === 'PageTem'">
                <app-code-html-css-input
                  class="full-width"
                  [isComponent]="false"
                  [isEditStyle]="isEditStyle"
                  [descriptionHTML]="descriptionHTML"
                  [displayUploader]="false"
                  [content]="defaultContent"
                  [style]="defaultStyle"
                  [descriptionRaw]="descriptionHTML"
                  [descriptionDocument]="descriptionHTML"
                  [descriptionEditor]="descriptionHTML"
                  (getIsEditStyle)="getIsEditStyleEvent($event)"
                  (getStyle)="getStyleTemplate($event)"
                  (getContent)="getContentTemplate($event)"
                >
                </app-code-html-css-input>
              </div>

              <hr class="mb"/>
              <div class="drop-button text-center full-width mt">
                <button class="btn-large pull-left" (click)="stepBack(false)" mat-raised-button color="primary">
                  <mat-icon>chevron_left</mat-icon> Back
                </button>

                <button class="btn-large" (click)="save(false)" mat-raised-button color="primary">
                  <mat-icon class="smr">save</mat-icon>Save Draft
                </button>

                <button class="btn-large pull-right" (click)="save(true)" mat-raised-button color="accent">
                  Save
                  <mat-icon>chevron_right</mat-icon>
                </button>
              </div>
            </div>
          </form>
        </div>
      </aw-wizard-step>

      <aw-wizard-step [navigationSymbol]="{ symbol: '2' }" stepTitle="Done!" awOptionalStep>
        <div class="container mt" id="step-2" *ngIf="createdRecord && createdRecord.ID" @ngIfAnimation>
          <div class="animate clearfix">
            <div class="row clearfix">
              <mat-card class="card successful">
                <div class="row clearfix">
                  <div class="full-width text-center">
                    <h1 class="sm-h1 sr-title" [attr.style]="'color:' + color + ' !important' | safeStyle">
                      Well Done!
                    </h1>
                    <h3 class="sm-h3 rm-m" [attr.style]="'color:' + colorSecond + ' !important' | safeStyle">
                      Main feature Label has been Modified
                    </h3>
                    <div class="row clearfix">
                      <div class="svg-box">
                        <svg class="circular green-stroke">
                          <circle
                            class="path"
                            cx="75"
                            cy="75"
                            r="50"
                            fill="none"
                            stroke-width="5"
                            stroke-miterlimit="10"
                          />
                        </svg>
                        <svg class="checkmark green-stroke">
                          <g transform="matrix(0.79961,8.65821e-32,8.39584e-32,0.79961,-489.57,-205.679)">
                            <path
                              class="checkmark__check"
                              fill="none"
                              d="M616.306,283.025L634.087,300.805L673.361,261.53"
                            />
                          </g>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row clearfix text-center">
                  <button
                    class="smr"
                    *ngIf="createdRecord && createdRecord.ID"
                    (click)="view(createdRecord.ID)"
                    mat-raised-button
                    color="primary"
                  >
                    <mat-icon class="smr">visibility</mat-icon>
                    <span>View</span>
                  </button>

                  <button
                    class="smr"
                    *ngIf="createdRecord && createdRecord.ID"
                    (click)="createNew()"
                    mat-raised-button
                    color="accent"
                  >
                    <mat-icon class="smr">add</mat-icon>
                    <span>Create new</span>
                  </button>
                </div>
              </mat-card>
            </div>
          </div>
        </div>
      </aw-wizard-step>
    </aw-wizard>
  </div>
</mat-card>
