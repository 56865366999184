<mat-card class="card full-width two-third" *ngIf="displaySync()">
  <div class="colour-stripe"></div>

  <div class="full-width clearfix">
    <app-calendar-event-share
      [event]="calEvent"
      [appleIsConnected]="!!appointment.Apple_Calendar_Consumer_Key"
      [googleIsConnected]="!!appointment.Google_Calendar_Consumer_Key"
      [microsoftIsConnected]="!!appointment.Microsoft_Calendar_Consumer_Key"
      (success)="calendarSynced($event)"
    ></app-calendar-event-share>

    <p>
      <mat-icon class="fas fa-star pull-left mr goldStatus"> </mat-icon>

      You can add this appointment to your favorite calendar, click on button to the right to choose your platform
    </p>
  </div>
</mat-card>
