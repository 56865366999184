import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { uniqBy } from 'lodash';
import { Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { environment } from '../../../../../../src/environments/environment';
import { AppStateService } from '../../../../core/app-state/app-state.service';
import { UtilsService } from '../../../../shared/services/utils.service';
import { DataResponse } from '../../../../shared/types/data-response.type';
import { Note, NoteQuery, NoteType, NoteTypeQuery } from '../type/notes.type';

class NotesState {
  notes: Partial<Note>[] | Note[];
  enabledNoteTypes: Partial<NoteType>[] | NoteType[];
  noteTypes: Partial<NoteType>[] | NoteType[];
  noteTemplates: Partial<Note>[] | Note[];
  selectedNotes: Partial<Note>[] | Note[];
  selectedNoteDates: Partial<Note>[] | Note[];
}

@Injectable()
export class NotesService extends ComponentStore<NotesState> {
  private baseUrl = environment.nodeUrl;

  constructor(
    private utilsService: UtilsService,
    private httpClient: HttpClient,
    private appStateService: AppStateService
  ) {
    super({
      notes: [],
      enabledNoteTypes: [],
      noteTypes: [],
      noteTemplates: [],
      selectedNotes: [],
      selectedNoteDates: [],
    });
  }

  getNotes$<T extends Note | Partial<Note>>(): Observable<T[]> {
    return this.select((state) => state.notes) as Observable<T[]>;
  }

  setNotes(notes: Note[] | Partial<Note>[]): void {
    this.patchState({ notes });
  }

  fetchNotes$<T extends Note | Partial<Note>>(payload?: NoteQuery['payload']): Observable<T[]> {
    return this.appStateService.getAppState$().pipe(
      switchMap((appState) => {
        const params = this.utilsService.getHttpParamsFromPayload(payload);

        const options = { params };

        const endpoint = appState.isPromoterOrAdmin ? 'notes/global' : 'notes';

        const url = `${this.baseUrl}/${endpoint}`;

        return this.httpClient.get<DataResponse<T[]>>(url, options).pipe(map((response) => response['data']));
      })
    );
  }

  loadMoreNotes<T extends Note | Partial<Note>>(payload?: NoteQuery['payload']): Observable<T[]> {
    return this.fetchNotes$<T>(payload).pipe(
      tap((notesSection) => {
        if (notesSection.length > 0) {
          const currentNotes = this.get().notes as T[];
          const increasedNotes = currentNotes.concat(notesSection);
          this.patchState({ notes: increasedNotes });
        }
      })
    );
  }

  getSelectedNotes$<T extends Note | Partial<Note>>(): Observable<T[]> {
    return this.select((state) => state.selectedNotes) as Observable<T[]>;
  }

  getSelectedNotes<T extends Note | Partial<Note>>(): T[] {
    return this.get().selectedNotes as T[];
  }

  getSelectedNotesDates$<T extends Note | Partial<Note>>(): Observable<T[]> {
    return this.select((state) => state.selectedNoteDates) as Observable<T[]>;
  }

  setSelectedNotes(selectedNotes: Note[] | Partial<Note>[]): void {
    const selectedNoteDates = uniqBy(selectedNotes, 'Dates.Action');
    this.patchState({ selectedNotes, selectedNoteDates });
  }

  loadMoreSelectedNotes<T extends Note | Partial<Note>>(payload?: NoteQuery['payload']): Observable<T[]> {
    return this.fetchNotes$<T>(payload).pipe(
      tap((notesSection) => {
        if (notesSection.length > 0) {
          const currentNotes = this.get().notes as T[];
          const increasedNotes = currentNotes.concat(notesSection);
          this.patchState({ notes: increasedNotes });
        }
      })
    );
  }

  addSelectedNote$(noteWithID: Note | Partial<Note>, applyUpdate = true) {
    const currentItems = this.get().selectedNotes;
    const selectedNotes = [...currentItems, noteWithID];
    if (applyUpdate) {
      this.patchState({ selectedNotes });
    }
    return of(noteWithID);
  }

  editSelectedNotes$(note: Note | Partial<Note>, applyUpdate = true) {
    const selectedNotes = this.get().selectedNotes;
    const targetIndex = selectedNotes.findIndex((item) => note.ID === item.ID);
    selectedNotes[targetIndex] = note;
    if (applyUpdate) {
      this.patchState({ selectedNotes });
    }
    return of(note);
  }

  removeSelectedNote$(note: Note | Partial<Note>) {
    // Remove item from local list
    const currentItems = this.get().selectedNotes;
    const selectedNotes = currentItems.filter((item) => note.ID !== item.ID);

    this.patchState({ selectedNotes });

    return of(note);
  }

  getNoteTypes<T extends NoteType | Partial<NoteType>>(): T[] {
    return this.get().noteTypes as T[];
  }

  getNoteTypes$<T extends NoteType | Partial<NoteType>>(): Observable<T[]> {
    return this.select((state) => state.noteTypes) as Observable<T[]>;
  }

  setNoteTypes(noteTypes: NoteType[] | Partial<NoteType>[]): void {
    this.patchState({ noteTypes });
  }

  fetchNoteTypes$<T extends NoteType | Partial<NoteType>>(payload?: NoteTypeQuery['payload']): Observable<T[]> {
    return this.appStateService.getAppState$().pipe(
      switchMap((appState) => {
        const params = this.utilsService.getHttpParamsFromPayload(payload);

        const options = { params };

        const endpoint = appState.isPromoterOrAdmin ? 'action-log-code/global' : 'action-log-code';

        const url = `${this.baseUrl}/${endpoint}`;

        return this.httpClient.get<DataResponse<T[]>>(url, options).pipe(map((response) => response.data));
      })
    );
  }

  createNoteType$(payload): Observable<any> {
    return this.appStateService.getAppState$().pipe(
      switchMap((appState) => {
        const endpoint = appState.isPromoterOrAdmin ? 'action-log-code/global' : 'action-log-code';

        const url = `${this.baseUrl}/${endpoint}`;

        return this.httpClient.post<DataResponse<NoteType>>(url, payload).pipe(map((response) => response.data));
      })
    );
  }

  updateNoteType$(ID: string, payload): Observable<any> {
    return this.appStateService.getAppState$().pipe(
      switchMap((appState) => {
        const endpoint = appState.isPromoterOrAdmin ? 'action-log-code/global' : 'action-log-code';

        const url = `${this.baseUrl}/${endpoint}/${ID}`;

        return this.httpClient.put<DataResponse<NoteType>>(url, payload).pipe(map((response) => response.data));
      })
    );
  }

  enableNoteType$(ID: string): Observable<any> {
    return this.appStateService.getAppState$().pipe(
      switchMap((appState) => {
        const endpoint = appState.isPromoterOrAdmin ? 'action-log-code/global' : 'action-log-code';

        const url = `${this.baseUrl}/${endpoint}/${ID}`;

        return this.httpClient
          .put<DataResponse<NoteType>>(url, { Disabled: '0' })
          .pipe(map((response) => response.data));
      })
    );
  }

  disableNoteType$(ID: string): Observable<any> {
    return this.appStateService.getAppState$().pipe(
      switchMap((appState) => {
        const endpoint = appState.isPromoterOrAdmin ? 'action-log-code/global' : 'action-log-code';

        const url = `${this.baseUrl}/${endpoint}/${ID}`;

        return this.httpClient
          .put<DataResponse<NoteType>>(url, { Disabled: '1' })
          .pipe(map((response) => response.data));
      })
    );
  }

  deleteNoteType$(ID: string): Observable<any> {
    return this.appStateService.getAppState$().pipe(
      switchMap((appState) => {
        const endpoint = appState.isPromoterOrAdmin ? 'action-log-code/global' : 'action-log-code';

        const url = `${this.baseUrl}/${endpoint}/${ID}`;

        return this.httpClient.delete<DataResponse<string>>(url).pipe(map((response) => response.data));
      })
    );
  }

  getEnabledNoteTypes$<T extends NoteType | Partial<NoteType>>(): Observable<T[]> {
    return this.select((state) => state.enabledNoteTypes) as Observable<T[]>;
  }

  setEnabledNoteTypes(enabledNoteTypes: NoteType[] | Partial<NoteType>[]): void {
    this.patchState({ enabledNoteTypes });
  }

  fetchEnabledNoteTypes$(payload?: NoteTypeQuery): Observable<Partial<NoteType>[]> {
    return this.appStateService.getAppState$().pipe(
      switchMap((appState) => {
        const modifiedPayload = { ...payload, Disabled: '0', hasDefaultTypes: true };

        const params = this.utilsService.getHttpParamsFromPayload(modifiedPayload);

        const options = { params };

        const endpoint = appState.isPromoterOrAdmin ? 'action-log-code/global' : 'action-log-code';

        const url = `${this.baseUrl}/${endpoint}`;

        return this.httpClient.get<DataResponse<NoteType>>(url, options).pipe(map((response) => response.data));
      })
    );
  }

  createNote$<T extends Note | Partial<Note>>(payload?: Partial<Note>): Observable<T> {
    return this.appStateService.getAppState$().pipe(
      switchMap((appState) => {
        const endpoint = appState.isPromoterOrAdmin ? 'notes/global' : 'notes';

        const url = `${this.baseUrl}/${endpoint}`;

        return this.httpClient.post<DataResponse<T>>(url, payload).pipe(map((response) => response.data));
      })
    );
  }

  updateNote$<T extends Note | Partial<Note>>(note: Note | Partial<Note>, payload?: Partial<Note>): Observable<T> {
    return this.appStateService.getAppState$().pipe(
      switchMap((appState) => {
        const endpoint = appState.isPromoterOrAdmin ? `notes/global/${note.ID}` : `notes/${note.ID}`;

        const url = `${this.baseUrl}/${endpoint}`;

        return this.httpClient.put<DataResponse<T>>(url, payload).pipe(map((response) => response.data));
      })
    );
  }

  deleteNote$(note: Note | Partial<Note>): Observable<string> {
    return this.appStateService.getAppState$().pipe(
      switchMap((appState) => {
        const endpoint = appState.isPromoterOrAdmin ? `notes/global/${note.ID}` : `notes/${note.ID}`;

        const url = `${this.baseUrl}/${endpoint}`;

        return this.httpClient.delete<DataResponse<Partial<string>>>(url).pipe(map((response) => response.data));
      })
    );
  }

  getNoteTemplates$<T extends Note | Partial<Note>>(): Observable<T[]> {
    return this.select((state) => state.noteTemplates) as Observable<T[]>;
  }

  getNoteTemplates<T extends Note | Partial<Note>>(): T[] {
    return this.get().noteTemplates as T[];
  }

  setNoteTemplates(noteTemplates: Note[] | Partial<Note>[]): void {
    this.patchState({ noteTemplates });
  }

  fetchNoteTemplates$<T extends Note | Partial<Note>>(payload?: NoteQuery['payload']): Observable<T[]> {
    const params = this.utilsService.getHttpParamsFromPayload(payload);

    const options = { params };

    const endpoint = 'notes/templates';

    const url = `${this.baseUrl}/${endpoint}`;

    return this.httpClient.get<DataResponse<T[]>>(url, options).pipe(map((response) => response['data']));
  }

  addNoteTemplate$(noteWithID: Note | Partial<Note>, applyUpdate = true) {
    const currentItems = this.get().noteTemplates;
    const noteTemplates = [...currentItems, noteWithID];
    if (applyUpdate) {
      this.patchState({ noteTemplates });
    }
    return of(noteWithID);
  }

  editNoteTemplate$(note: Note | Partial<Note>, applyUpdate = true) {
    const noteTemplates = this.get().noteTemplates;
    const targetIndex = noteTemplates.findIndex((item) => note.ID === item.ID);
    noteTemplates[targetIndex] = note;
    if (applyUpdate) {
      this.patchState({ noteTemplates });
    }
    return of(note);
  }

  removeNoteTemplate$(note: Note | Partial<Note>) {
    // Remove item from local list
    const currentItems = this.get().noteTemplates;
    const noteTemplates = currentItems.filter((item) => note.ID !== item.ID);

    this.patchState({ noteTemplates });

    return of(note);
  }

  fetchIsNoteLabelUnique$(label: string): Observable<boolean> {
    const payload = { noteType: label };

    const params = this.utilsService.getHttpParamsFromPayload(payload);

    const options = { params };

    const endpoint = 'action-log-code/unique';

    const url = `${this.baseUrl}/${endpoint}`;

    return this.httpClient.get<DataResponse<boolean>>(url, options).pipe(map((response) => response.data));
  }
}
