import * as $ from 'jquery';
import * as moment from 'moment';
import * as momentTimeZone from 'moment-timezone';
import { Settings } from './settings';
export class ClientDetails {
  static geolocation = null;
  static ipAddress = null;
  static browser = null;
  static os = null;
  static device = null;
  static timeZone = null;
  static timeZoneUTC = null;
  static timeZoneProfileUpdated = false;
  static accessToken;

  static formatUTC = 'YYYY-MM-DDTHH:mm:ss';

  static timeZoneUTCProfileValue = null;
  static timeZoneUTCProfileCode = null;

  static getGeoLocation() {
    if (ClientDetails.geolocation) {
      return ClientDetails.geolocation;
    } else {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(function (position) {
          if (position && position.coords) {
            ClientDetails.geolocation = position.coords.latitude + '/' + position.coords.longitude;
            return ClientDetails.geolocation;
          } else {
            return null;
          }
        });
      } else {
        return null;
      }
    }
  }

  static getIPAddress() {
    if (ClientDetails.ipAddress) {
      return ClientDetails.ipAddress;
    } else {
      $('#loaderForce').show();

      $.getJSON('https://api.ipify.org?format=jsonp&callback=?', function (json) {
        $('#loaderForce').hide();
        if (json && json.ip) {
          ClientDetails.ipAddress = json.ip;
          return json.ip;
        } else {
          ClientDetails.ipAddress = null;
          return null;
        }
      }).fail(function () {
        $('#loaderForce').hide();
      });
    }
  }

  static getTimeZoneUTC() {
    if (ClientDetails.timeZoneUTC == null) {
      if (momentTimeZone && momentTimeZone.tz && momentTimeZone.tz.guess()) {
        ClientDetails.timeZone = momentTimeZone.tz.guess();
      }

      let durrationDiff = 0;
      if (ClientDetails.timeZone) {
        durrationDiff = momentTimeZone.tz.zone(ClientDetails.timeZone).utcOffset(new Date());

        if (durrationDiff) {
          durrationDiff = Number(Number(durrationDiff / 60).toFixed(0));
        }
      }

      if (durrationDiff != 0) {
        ClientDetails.timeZoneUTC = -durrationDiff;
      } else {
        ClientDetails.timeZoneUTC = 0;
      }
    }
  }

  static convertTimeZone(d, timeZone = true) {
    let customerTimeZone = 0;
    const serverTimeZoneUTC = Settings.global['serverTimeZoneUTC'];
    let result;

    if (ClientDetails && !ClientDetails.timeZoneUTC) {
      ClientDetails.getTimeZoneUTC();
    }

    if (ClientDetails.timeZoneUTCProfileCode && ClientDetails.timeZoneUTCProfileValue != null) {
      customerTimeZone = Number(ClientDetails.timeZoneUTCProfileValue);
    } else if (ClientDetails && ClientDetails.timeZoneUTC != null) {
      customerTimeZone = ClientDetails.timeZoneUTC;
    }

    if (timeZone != true) {
      result = moment(d).toDate();
    } else {
      result = moment(d)
        .add(-serverTimeZoneUTC + customerTimeZone, 'h')
        .toDate();
    }

    return result;
  }

  static getDate(d = new Date()) {
    const result = moment(d, ClientDetails.formatUTC).toDate();
    return result;
  }

  static getMoment(d = new Date()) {
    const result = moment(d, ClientDetails.formatUTC);
    return result;
  }

  constructor() {}
}
