import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ActionLogService } from '../shared/action-log.service';

@Component({
  selector: 'app-event-log-view',
  templateUrl: './event-log-view.component.html',
  styleUrls: ['./event-log-view.component.css'],
})
export class EventLogViewComponent implements OnInit {
  @Input()
  eventID = '';

  @Input()
  type = '';

  @Output()
  closeModal = new EventEmitter();

  isModal = false;
  isParameter = false;

  invitationEventLog;
  contractEventLog;
  messageEventLog;

  sessionType;

  constructor(
    private authenticationService: AuthenticationService,
    private activeRoute: ActivatedRoute,
    private actionlogService: ActionLogService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      this.eventID = data.eventID;
      this.type = data.type;

      this.isModal = true;
    }
  }

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      if (params['contractID']) {
        this.eventID = params['eventID'];
        this.isParameter = true;
      }

      this.authenticationService.getSessionType().subscribe((sessionType) => {
        this.sessionType = sessionType;

        if (this.type && this.type == 'invitation') {
          this.actionlogService.getOneInvitationEventView(this.eventID, this.sessionType).subscribe((resInv) => {
            this.invitationEventLog = resInv;
          });
        } else if (this.type && this.type == 'contract') {
          this.actionlogService.getOneContractEventView(this.eventID, this.sessionType).subscribe((resCon) => {
            this.contractEventLog = resCon;
          });
        } else if (this.type && this.type == 'message') {
          this.actionlogService.getOneMessageEventView(this.eventID, this.sessionType).subscribe((resMsg) => {
            this.messageEventLog = resMsg;
          });
        }
      });
    });
  }

  closeEvent() {
    this.closeModal.emit(true);
  }
}
