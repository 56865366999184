import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgPipesModule } from 'ngx-pipes';
import { WikiRoutingModule } from './shared/wiki-routing.module';
import { WikiCreateEditComponent } from './wiki-create-edit/wiki-create-edit.component';

import { CdkTableModule } from '@angular/cdk/table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSortModule, MatTreeModule } from '@angular/material';
import { MatRippleModule } from '@angular/material';
import { ClipboardModule } from 'ngx-clipboard';
import { NgHighlightModule } from 'ngx-text-highlight';
import { TestimonyModule } from '../../feature/testimony/testimony.module';
import { SharedModule } from '../../shared/shared.module';
import { HelperModule } from '../helper/helper.module';
import { SessionModule } from '../session/session.module';
import { WikiService } from './shared/wiki.service';
import { WikiFooterComponent } from './wiki-footer/wiki-footer.component';
import { WikiGroupEditComponent } from './wiki-group-edit/wiki-group-edit.component';
import { WikiGroupListComponent } from './wiki-group-list/wiki-group-list.component';
import { WikiGroupViewListComponent } from './wiki-group-view-list/wiki-group-view-list.component';
import { WikiGroupViewComponent } from './wiki-group-view/wiki-group-view.component';
import { WikiHeaderComponent } from './wiki-header/wiki-header.component';
import { WikiIntroductionComponent } from './wiki-introduction/wiki-introduction.component';
import { WikiLeftMenuComponent } from './wiki-left-menu/wiki-left-menu.component';
import { WikiListComponent } from './wiki-list/wiki-list.component';
import { WikiMainComponent } from './wiki-main/wiki-main.component';
import { WikiRedirectionComponent } from './wiki-redirection/wiki-redirection.component';
import { WikiSearchComponent } from './wiki-search/wiki-search.component';
import { WikiSummaryComponent } from './wiki-summary/wiki-summary.component';
import { WikiViewComponent } from './wiki-view/wiki-view.component';

@NgModule({
  imports: [
    CdkTableModule,
    MatSortModule,
    NgPipesModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    WikiRoutingModule,
    SharedModule,
    TestimonyModule,
    HelperModule,
    SessionModule,
    MatTreeModule,
    NgHighlightModule,
    MatRippleModule,
    ClipboardModule,
  ],
  declarations: [
    WikiCreateEditComponent,
    WikiViewComponent,
    WikiListComponent,
    WikiLeftMenuComponent,
    WikiHeaderComponent,
    WikiFooterComponent,
    WikiMainComponent,
    WikiGroupViewListComponent,
    WikiIntroductionComponent,
    WikiSearchComponent,
    WikiGroupViewComponent,
    WikiGroupEditComponent,
    WikiGroupListComponent,
    WikiSummaryComponent,
    WikiRedirectionComponent,
  ],
  exports: [
    WikiCreateEditComponent,
    WikiViewComponent,
    WikiListComponent,
    WikiLeftMenuComponent,
    WikiHeaderComponent,
    WikiFooterComponent,
    WikiMainComponent,
    WikiGroupViewListComponent,
    WikiIntroductionComponent,
    WikiSearchComponent,
    WikiGroupViewComponent,
    WikiGroupEditComponent,
    WikiGroupListComponent,
    WikiSummaryComponent,
    WikiRedirectionComponent,
  ],
  providers: [WikiService],
})
export class WikiModule {}
