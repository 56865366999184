<div class="rel existingCard">
  <button
    class="btn-close btn-clear adjust pull-right mat-button"
    *ngIf="isModal == true"
    (click)="closeEvent()"
    matDialogClose
  >
    <mat-icon>clear</mat-icon>
  </button>
  <mat-card class="existing-patient-summary" *ngIf="invitedPatient" @onChangeAnimation>
    <div class="animate">
      <!-- if patient has profile picture set, use as background image for mat-card-header -->
      <mat-card-header>
        <!-- ExistingPatient calculated name -->
        <h2 class="summary-header sr-title">
          {{ invitedPatient.Salutation }} {{ invitedPatient.FirstName }} {{ invitedPatient.LastName }}
        </h2>
      </mat-card-header>
      <!-- View full profile -->
      <div class="button-row">
        <!-- <button mat-fab color="accent" matTooltip="View patient profile" [routerLink]="['/merchant/', {outlets: {'page': ['patient-profile-full']}}]"
      [routerLinkActive]="['active']">
      <mat-icon>person</mat-icon>
    </button> -->
        <!-- <div class="row clearfix details flex"> -->
        <button
          class=""
          *ngIf="invitedPatient['Email']"
          (click)="contact()"
          mat-mini-fab
          color="accent"
          matTooltip="Contact {{ invitedPatient.FirstName }} {{ invitedPatient.LastName }}"
        >
          <mat-icon>contact_mail</mat-icon>
        </button>
        <!-- </div> -->
      </div>

      <mat-dialog-content>
        <div class="row clearfix">
          <div class="icons">
            <mat-icon class="fas fa-venus-mars"></mat-icon>
          </div>
          <!-- Gender -->
          <div class="row-content">
            <p class="lead">{{ invitedPatient['Gender.Label'] }}</p>
            <p class="small">Gender</p>
          </div>
        </div>
        <mat-divider></mat-divider>

        <div class="row clearfix">
          <div class="icons">
            <mat-icon>phone</mat-icon>
          </div>
          <!-- Phone numbers -->
          <div class="row-content">
            <p class="lead">{{ invitedPatient['Mobile'] | customPhone }}</p>
            <p class="small">Phone/Mobile</p>
            <!--  -->
            <p
              class="lead"
              *ngIf="invitedPatient['Phone.Home.Number'] && invitedPatient['Phone.Home.Number'] != 'undefined'"
            >
              {{ invitedPatient['Phone.Home'] | customPhone: 'landLine' }}
            </p>
            <p
              class="small"
              *ngIf="invitedPatient['Phone.Home.Number'] && invitedPatient['Phone.Home.Number'] != 'undefined'"
            >
              Home
            </p>

            <p
              class="lead"
              *ngIf="invitedPatient['Phone.Work.Number'] && invitedPatient['Phone.Work.Number'] != 'undefined'"
            >
              {{ invitedPatient['Phone.Work'] | customPhone: 'landLine' }}
            </p>
            <p
              class="small"
              *ngIf="invitedPatient['Phone.Work.Number'] && invitedPatient['Phone.Work.Number'] != 'undefined'"
            >
              Work
            </p>
          </div>
        </div>

        <mat-divider></mat-divider>
        <!-- Email Addresses -->
        <div class="row clearfix">
          <div class="icons">
            <mat-icon>email</mat-icon>
          </div>
          <div class="row-content">
            <p class="lead">{{ invitedPatient['Email'] }}</p>
            <p class="small">Primary Email</p>
          </div>
        </div>

        <mat-divider
          *ngIf="invitedPatient['addresses.Postcode'] && invitedPatient['addresses.Postcode'] != 'undefined'"
        ></mat-divider>
        <!-- Location Information -->
        <div
          class="row clearfix"
          *ngIf="invitedPatient['addresses.Postcode'] && invitedPatient['addresses.Postcode'] != 'undefined'"
        >
          <div class="icons">
            <mat-icon>location_on</mat-icon>
          </div>
          <div class="row-content">
            <p class="lead">{{ invitedPatient['addresses.Calculated'] }}</p>
            <p class="small">Home Address</p>
            <!--  -->
            <p class="lead">{{ invitedPatient['addresses.Postcode'] }}</p>
            <p class="small">Postal Address</p>
          </div>
        </div>

        <mat-divider
          *ngIf="invitedPatient['addresses.Postcode'] && invitedPatient['addresses.Postcode'] != 'undefined'"
        ></mat-divider>
        <!-- Social Media Accounts -->
      </mat-dialog-content>
    </div>
  </mat-card>
</div>
