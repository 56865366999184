<div class="session-menu" *ngIf="
    isLogin == true &&
    sessionType != 'guest' &&
    (sessionType == 'merchant' ||
      sessionType == 'merchant-admin' ||
      sessionType == 'admin' ||
      sessionType == 'promoter')
  ">
  <button class="inline btn-clear userMenuBtn" [matMenuTriggerFor]="notiMenu" mat-button>
    <span class="usersName">{{ userName }}</span>

    <span class="chipPromoter" *ngIf="isPromoterOrAdmin == true">
      <mat-icon class="fas fa-shield-alt"></mat-icon>
    </span>
    <app-ep-document-view-image class="headNav userIcon" [isFullScreen]="false" [defaultPicture]="
        'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/profile_placeholder.png)'
      " [link]="profileLink" [hideZoomButton]="true">
    </app-ep-document-view-image>
  </button>
  <mat-menu class="notification-menu" #notiMenu="matMenu">
    <button [routerLink]="['/merchant/', { outlets: { page: ['dentist-overview', 'personal'] } }]"
      [routerLinkActive]="['active']" mat-menu-item>
      <p class="small">
        <mat-icon class=" fas fa-user-circle mr"></mat-icon>
        My Profile
      </p>
    </button>
    <mat-divider></mat-divider>

    <button *ngIf="context != 'breeze'" (click)="booking()" mat-menu-item>
      <p class="small">
        <mat-icon class="fas fa-user-graduate mr"></mat-icon>
        Training Academy
      </p>
    </button>
    <mat-divider></mat-divider>

    <button *ngIf="displayDashboard == true && isPromoterOrAdmin == true" [routerLinkActive]="['active']" mat-menu-item
      routerLink="/merchant">
      <p class="small">
        <mat-icon class="mr">dashboard</mat-icon>
        Dashbord
      </p>
    </button>

    <mat-divider *ngIf="displayDashboard == true && isPromoterOrAdmin == true"></mat-divider>

    <button (click)="applyNightMode()" mat-menu-item>
      <p class="small" *ngIf="isNightMode == false || !isNightMode">
        <mat-icon class="mr">brightness_3</mat-icon> Night Mode
      </p>
      <p class="small" *ngIf="isNightMode == true">
        <mat-icon class="mr">wb_sunny</mat-icon>
        Day Mode
      </p>
    </button>



    <button
      *ngIf="(sessionType === 'merchant' || sessionType === 'merchant-admin' || sessionType === 'admin' || sessionType === 'promoter') && displayInfoToolTip!=false"
      mat-menu-item (click)="changeInfoToolTip(false)">
      <p class="small">
        <mat-icon class="fas fa-info-circle mr"></mat-icon>
        Disable Guide Tips
      </p>
    </button>


    <button
      *ngIf="(sessionType === 'merchant' || sessionType === 'merchant-admin' || sessionType === 'admin' || sessionType === 'promoter' ) && displayInfoToolTip==false"
      mat-menu-item (click)="changeInfoToolTip(true)">
      <p class="small">
        <mat-icon class="fas fa-info-circle mr"></mat-icon>
        Enable Guide Tips
      </p>
    </button>

    <mat-divider *ngIf="
    sessionType === 'merchant' ||
    sessionType === 'merchant-admin' ||
    sessionType === 'admin' ||
    sessionType === 'promoter'
  ">
    </mat-divider>
    <button *ngIf="sessionType === 'merchant' || sessionType === 'merchant-admin'"
      [routerLink]="['/merchant/', { outlets: { page: ['subscription-plan-view-current', 'current', 'merchant'] } }]"
      [routerLinkActive]="['active']" mat-menu-item>
      <p class="small">
        <mat-icon class="fas fa-crown mr" style="color: gold"></mat-icon>
        My Subscription
      </p>
    </button>
    <button *ngIf="sessionType === 'admin' || sessionType === 'promoter'"
      [routerLink]="['/merchant/', { outlets: { page: ['subscription-plan-view-current', 'current', 'all'] } }]"
      [routerLinkActive]="['active']" mat-menu-item>
      <p class="small">
        <mat-icon class="fas fa-crown mr" style="color: gold"></mat-icon>
        My Subscription
      </p>
    </button>



    <mat-divider *ngIf="isPromoterOrAdmin == true"></mat-divider>



    <button *ngIf="isPromoterOrAdmin == true" (click)="loginAsUser()" mat-menu-item>
      <p class="small bold  primary-color">
        <mat-icon class="fas fa-user-lock  primary-color mr"></mat-icon>
        Login as User
      </p>
    </button>

    <button *ngIf="displayAdmin == true && isPromoterOrAdmin == true" [routerLinkActive]="['active']" mat-menu-item
      routerLink="/admin">
      <p class="small bold primary-color">
        <mat-icon class="mr primary-color">security</mat-icon>
        Admin
      </p>
    </button>


    <button *ngIf="isPromoterOrAdmin == true" (click)="versionReport()" mat-menu-item>
      <p class="small bold  primary-color">
        <mat-icon class="fas fa-clipboard-list  primary-color mr "></mat-icon>
        Version Report
      </p>
    </button>



    <button *ngIf="isPromoterOrAdmin == true" [matMenuTriggerFor]="menu78980" mat-menu-item>
      <p class="small  bold primary-color">
        <mat-icon class="fas fa-desktop  primary-color mr"></mat-icon>
        Portals
      </p>
    </button>

    <mat-menu #menu78980="matMenu">

      <button (click)="portalRedirection('merchant')" *ngIf="displayMerchantLink==true" mat-menu-item>
        <mat-icon class="fas {{'fa-tooth' | translate}} smr" style="font-size:1rem;color:gray !important;"></mat-icon>
        <span> Merchant</span>
      </button>
      <mat-divider *ngIf="displayMerchantLink==true"></mat-divider>

      <button (click)="portalRedirection('promoter')" *ngIf="displayPromoterLink==true" mat-menu-item>
        <mat-icon class="fas fa-user-shield smr" style="font-size:1rem;color:gray !important;"></mat-icon>
        <span> Promoter</span>
      </button>
      <mat-divider *ngIf="displayPromoterLink==true"></mat-divider>

      <button (click)="portalRedirection('public')" mat-menu-item>
        <mat-icon class="fas fa-globe  smr" style="font-size:1rem;color:gray !important;"></mat-icon>
        <span> Public site</span>
      </button>
      <mat-divider></mat-divider>

      <button (click)="portalRedirection('financeOffer')" mat-menu-item>
        <mat-icon class="fas fa-search-dollar smr" style="font-size:1rem;color:gray !important;"></mat-icon>
        <span> Finance Offer Page</span>
      </button>


    </mat-menu>






    <mat-divider></mat-divider>



    <app-logout class="headerLogout"></app-logout>
  </mat-menu>
</div>

<div class="session-menu" *ngIf="isLogin == true && sessionType == 'customer'">
  <button class="inline btn-clear userMenuBtn" [matMenuTriggerFor]="notiMenu" mat-button>
    <span class="usersName">{{ userName }}</span>

    <app-ep-document-view-image class="headNav userIcon" [isFullScreen]="false" [defaultPicture]="
        'url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/profile_placeholder.png)'
      " [link]="profileLink" [hideZoomButton]="true">
    </app-ep-document-view-image>
  </button>
  <mat-menu class="notification-menu" #notiMenu="matMenu">
    <button [routerLink]="['/consumer-app/', { outlets: { page: ['profile'] } }]" [routerLinkActive]="['active']"
      mat-menu-item>
      <p class="small">
        <mat-icon class="mr">face</mat-icon>
        My Profile
      </p>
    </button>
    <mat-divider></mat-divider>

    <app-logout class="headerLogout"></app-logout>
  </mat-menu>
</div>
