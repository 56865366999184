<div>
  <div class="main-banner blue-banner"></div>
  <ng-container class="white-background">
    <section class="top container">
      <div class="top-leftbox">
        <div class="top-txt" @ngIfAnimation data-aos="fade-right" data-aos-delay="500" data-aos-once="true">
          <h1 class="getting-started-page">Lets take your {{ "KEYWORD.practice" | translate }} to the stars!</h1>
          <p class="getting-started-page">
            {{"PARAGRAPH.Core Getting Started Home Stars" | translate}}
          </p>
        </div>
      </div>
      <div class="top-rightbox" @ngIfAnimation data-aos="fade-left" data-aos-delay="500" data-aos-once="true">
        <div class="imgbox">
          <img class="img"
               src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/getting-started/Asset_1.png"/>
        </div>
      </div>
    </section>
    <section class="main container">
      <div class="title" @ngIfAnimation data-aos="fade-up" data-aos-delay="500" data-aos-once="true">
        <p class="getting-started-page theme-color-primary">
          We have made getting started simple by defining each role and how it interacts with the portal. Select the
          role which best describes you.
        </p>
      </div>
      <div class="dentist flex">
        <div class="half-width flex txt-box">
          <div class="txt" @ngIfAnimation data-aos="fade-right" data-aos-delay="500" data-aos-once="true">
            <h2 class="getting-started-page">{{ "KEYWORD.practitioner" | translate | titlecase }}</h2>
            <p class="getting-started-page">
              As a {{ 'PHRASE.dental practitioner' | translate }} you interact with
              {{ "KEYWORD.patient" | translate }}s the most and provide them with
              their {{ 'PHRASE.treatment plan' | translate }}s after a
              {{ "KEYWORD.consultation" | translate }}.
            </p>
            <button class="btn" [routerLink]="['/merchant/', { outlets: { page: ['getting-started-dental'] } }]"
                    mat-stroked-button>
              Getting Started - {{ "KEYWORD.practitioner" | translate | titlecase }}
              <mat-icon>chevron_right</mat-icon>
            </button>
          </div>
        </div>
        <div class="half-width img-box-right" @ngIfAnimation data-aos="fade-left" data-aos-delay="500"
             data-aos-once="true">
          <div class="main-imgbox">
            <img class="img"
                 src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/getting-started/Asset_2.png"/>
          </div>
        </div>
      </div>
      <div class="manager flex">
        <div class="half-width img-box-left" @ngIfAnimation data-aos="fade-right" data-aos-delay="500"
             data-aos-once="true">
          <div class="main-imgbox">
            <img class="img"
                 src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/getting-started/Asset_3.png"/>
          </div>
        </div>
        <div class="half-width flex txt-box" @ngIfAnimation data-aos="fade-left" data-aos-delay="500"
             data-aos-once="true">
          <div class="txt text-right">
            <h2 class="getting-started-page">{{ "KEYWORD.practice" | translate | titlecase}} Manager</h2>
            <p class="getting-started-page">
              The {{ "KEYWORD.practice" | translate }} manager helps to ensure the
              {{ "KEYWORD.practice" | translate }} is running smoothly behind the scenes for both
              {{ "KEYWORD.patient" | translate }}s and team members.
            </p>
            <button class="btn"
              [routerLink]="['/merchant/', { outlets: { page: ['getting-started-practice-manager'] } }]"
                    mat-stroked-button>
              Getting Started - Manager <mat-icon>chevron_right</mat-icon>
            </button>
          </div>
        </div>
      </div>
      <div class="recept flex">
        <div class="half-width flex txt-box" @ngIfAnimation data-aos="fade-right" data-aos-delay="500"
             data-aos-once="true">
          <div class="txt">
            <h2 class="getting-started-page">{{ "KEYWORD.receptionist" | translate | titlecase }}
              / {{ "KEYWORD.treatment" | translate | titlecase }} Coordinator</h2>
            <p class="getting-started-page">
              You are the first and last face {{ "KEYWORD.patient" | translate }}s will see, and who they will discuss
              things with such as payments, appointments, general enquires, and more.
            </p>
            <button class="btn" [routerLink]="['/merchant/', { outlets: { page: ['getting-started-coordinator'] } }]"
                    mat-stroked-button>
              Getting Started - Reception <mat-icon>chevron_right</mat-icon>
            </button>
          </div>
        </div>
        <div class="half-width img-box-right" @ngIfAnimation data-aos="fade-left" data-aos-delay="500"
             data-aos-once="true">
          <div class="main-imgbox">
            <img class="img"
                 src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/getting-started/Asset_4.png"/>
          </div>
        </div>
      </div>
      <div class="maket flex">
        <div class="half-width img-box-left" @ngIfAnimation data-aos="fade-right" data-aos-delay="500"
             data-aos-once="true">
          <div class="main-imgbox">
            <img class="img"
                 src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/getting-started/Asset_5.png"/>
          </div>
        </div>
        <div class="half-width flex txt-box" @ngIfAnimation data-aos="fade-left" data-aos-delay="500"
             data-aos-once="true">
          <div class="txt text-right">
            <h2 class="getting-started-page">Marketing Coordinator</h2>
            <p class="getting-started-page">
              You focus on maximising branding and digital marketing. From newsletters, new product emails, social media
              post to website presence for the {{ "KEYWORD.practice" | translate }}.
            </p>
            <button class="btn" [routerLink]="['/merchant/', { outlets: { page: ['getting-started-marketing'] } }]"
                    mat-stroked-button>
              Getting Started - Marketing <mat-icon>chevron_right</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </section>
  </ng-container>
</div>
