import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { environment } from 'environments/environment';
import { ClipboardService } from 'ngx-clipboard';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { IframeViewComponent } from '../../../shared/components/iframe-view/iframe-view.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { CustomCurrencyPipe } from '../../../shared/pipes/custom-currency.pipe';
import { CustomPhonePipe } from '../../../shared/pipes/custom-phone.pipe';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { DentistViewComponent } from '../../dentist/dentist-view/dentist-view.component';
import { ProductUtil } from '../../product/shared/productUtil.type';
import { DocumentLinkShareComponent } from '../document-link-share/document-link-share.component';
import { DocumentService } from '../shared/document.service';

@Component({
  selector: 'document-link-view',
  templateUrl: './document-link-view.component.html',
  styleUrls: ['./document-link-view.component.css'],
})
export class DocumentLinkViewComponent implements OnInit {
  getDocumentLink = new EventEmitter();
  getDocumentLinkID = new EventEmitter();
  getDocumentLinkURL = new EventEmitter();
  @Output()
  getResult = new EventEmitter();

  @Output()
  clone = new EventEmitter();
  @Output()
  close = new EventEmitter();

  maxLength = 220;

  displayClone = false;

  tableName;
  card: any = {
    ID: '00000000000000000000',
    Salutation: 'Mr',
    FirstName: 'John',
    MiddleName: 'Al',
    Name: 'Doe',
    'mobiles.Number': '0061101010101',
    'phones.Number': '0062202020202',
    'emails.Email': 'john.doe@smileright.com.au',
    'addresses.Calculated': 'PO Box 7795 Cloisters Square 6850 Western Australia',
  };
  loadMessage = false;
  loadContact = false;
  loadMerchant = false;
  loadPromoter = false;
  patientID;
  merchantID;
  landingPageLink;
  linkID;
  displayPatient = false;
  utils = new UtilsClass();
  isSubmit = false;
  isCollapsed = true;
  messageMode = 'all';
  link;
  getLink = new EventEmitter();
  sessionType;
  productUtil = new ProductUtil();
  cardID;
  merchant;
  promoter;
  contact;
  contactID;
  isPromoterOrAdmin = false;
  theme;
  loadCard;
  message;
  description;
  settings = Settings.global;

  env = environment.nodeUrl;

  currentPractice;
  currentDentist;
  constructor(
    private _clipboardService: ClipboardService,
    private customPhonePipe: CustomPhonePipe,
    private currencyPipe: CustomCurrencyPipe,
    private authenticationService: AuthenticationService,
    private documentService: DocumentService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data) {
      if (data && data.cardID) {
        this.cardID = data.cardID;
      }
      if (data && data.card) {
        this.card = data.card;
      }

      if (data && data.promoter) {
        this.promoter = data.promoter;
      }
      if (data && data.contact) {
        this.contact = data.contact;
      }
      if (data && data.contactID) {
        this.contactID = data.contactID;
      }

      if (data && data.merchantID) {
        this.merchantID = data.merchantID;
      }

      if (data && data.tableName) {
        this.tableName = data.tableName;
      }

      if (data && data.merchant) {
        this.merchant = data.merchant;
      }
      if (data && data.messageMode) {
        this.messageMode = data.messageMode;
      }

      if (data.displayClone != null) {
        this.displayClone = data.displayClone;
      }

      if (data.isSubmit != null) {
        this.isSubmit = data.isSubmit;
      }

      if (data && data.patientID) {
        this.patientID = data.patientID;
      }

      if (data && data.linkID) {
        this.linkID = data.linkID;
      }
    }
  }

  ngOnInit() {
    this.authenticationService.getCurrentPractice().subscribe((m) => {
      if (m && m.ID) {
        this.currentPractice = m;
      }

      this.authenticationService.getCurrentDentist().subscribe((d) => {
        if (d && d.ID) {
          this.currentDentist = d;
        }
        this.authenticationService.getSessionType().subscribe((SessionType) => {
          this.sessionType = SessionType;
          this.authenticationService.isPromoterOrAdmin().subscribe((r) => {
            this.isPromoterOrAdmin = r;

            if (this.card && this.card.ID === '00000000000000000000') {
              this.authenticationService.getCurrentDentist().subscribe((r) => {
                if (r && r['Gender.Code'] && r['Gender.Code'] === 'F') {
                  this.card.FirstName = 'Jane';
                  this.card['emails.Email'] = 'jane.doe@smileright.com.au';
                  if (r && r['Salutation']) {
                    this.card['Salutation'] = r['Salutation'];
                  } else {
                    this.card['Salutation'] = 'Miss';
                  }
                } else if (r && r['Salutation']) {
                  this.card['Salutation'] = r['Salutation'];
                }

                this.init();
              });
            } else {
              this.init();
            }
          });
        });
      });
    });
  }

  init() {
    if (this.linkID) {
      this.documentService
        .getDocumentLink(
          this.linkID,
          { selectDocument: true, selectContact: true, selectMerchant: true },
          this.sessionType
        )
        .subscribe((r) => {
          if (r && r.ID) {
            this.link = r;

            this.theme = r.theme;

            if ((this.card && this.card.ID) || this.cardID) {
              this.displayPatient = true;
            }

            this.loadMessage = true;

            if (this.link['IsDefault'] === '1') {
              this.merchant = this.currentPractice;
              this.merchantID = this.currentPractice.ID;

              this.contact = this.currentDentist;
              this.contactID = this.currentDentist.ID;

              this.setup();
            } else {
              this.setup();
            }
          }
        });
    }
  }

  viewDentist(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(DentistViewComponent, {
        data: id,
        width: '600px',
      });
      ref.componentInstance.close.subscribe((res) => {
        if (res == true) {
          ref.close();
        }
      });
    }
  }
  setup() {
    if (this.cardID) {
      this.authenticationService.getCardDetails(this.cardID, this.tableName, {}, this.sessionType).subscribe((r) => {
        if (r && r.ID) {
          this.card = r;
        }

        this.loadCard = true;

        this.buildContent();
      });
    } else {
      this.loadCard = true;

      this.buildContent();
    }

    if (!this.promoter) {
      this.authenticationService.getPromoter().subscribe((r) => {
        if (r) {
          this.promoter = r;
        }
        this.loadPromoter = true;

        this.buildContent();
      });
    } else {
      this.loadPromoter = true;

      this.buildContent();
    }

    if (!this.contactID && !this.contact) {
      this.authenticationService.getCurrentDentist().subscribe((r) => {
        if (r) {
          this.contact = r;
          this.contactID = r.ID;
        }
        this.loadContact = true;

        this.buildContent();
      });
    } else if (this.contact && this.contact.ID) {
      this.contactID = this.contact.ID;
      this.loadContact = true;

      this.buildContent();
    } else if (this.contactID) {
      this.authenticationService.getCardDetails(this.contactID, 'Contact', {}, this.sessionType).subscribe((r) => {
        if (r && r.ID) {
          this.contact = r;
          this.contactID = r.ID;
        }
        this.loadContact = true;

        this.buildContent();
      });
    }

    if (!this.merchantID && !this.merchant) {
      this.authenticationService.getCurrentPractice().subscribe((r) => {
        if (r) {
          this.merchant = r;
          this.merchantID = r.ID;

          this.buildLink();
        }
        this.loadMerchant = true;

        this.buildContent();
      });
    } else if (this.merchant && this.merchant.ID) {
      this.merchantID = this.merchant.ID;
      this.loadMerchant = true;

      this.buildLink();
      this.buildContent();
    } else if (this.merchantID) {
      this.authenticationService.getCardDetails(this.merchantID, 'Merchant', {}, this.sessionType).subscribe((r) => {
        if (r && r.ID) {
          this.merchant = r;
          this.merchantID = r.ID;

          this.buildLink();
        }
        this.loadMerchant = true;

        this.buildContent();
      });
    }
  }

  buildLink() {
    this.landingPageLink = `${Settings.global['publicSiteFrontendLink']}/landing-page-overview/${this.link.ID}`;
  }

  buildContent() {
    const isBold = true;
    if (
      this.loadCard == true &&
      this.loadMerchant == true &&
      this.loadPromoter == true &&
      this.loadContact == true &&
      this.link
    ) {
      this.message = this.utils.fillMessage(
        this.promoter,
        this.merchant,
        this.contact,
        this.card,
        this.link.Message,
        isBold,
        this.customPhonePipe,
        this.currencyPipe
      );
    }

    if (
      this.loadCard == true &&
      this.loadMerchant == true &&
      this.loadPromoter == true &&
      this.loadContact == true &&
      this.link
    ) {
      this.description = this.utils.fillMessage(
        this.promoter,
        this.merchant,
        this.contact,
        this.card,
        this.link.Description,
        isBold,
        this.customPhonePipe,
        this.currencyPipe
      );
    }
  }

  copy() {
    if (this.landingPageLink) {
      this._clipboardService.copy(this.landingPageLink);
      NotifyAppComponent.displayToast('success', 'Success', 'Copied');
    }
  }

  textWithoutHtml() {
    if (this.description) {
      let message = this.description;

      message = message.replace(/(<br>)/g, '\n');
      message = message.replace(/(&nbsp;)/g, ' ');
      message = message.replace(/(&ndash;)/g, ' ');
      message = message.replace(/(&mdash;)/g, ' ');

      message = message.replace(/(&nbsp)/g, ' ');
      message = message.replace(/(&ndash)/g, ' ');
      message = message.replace(/(&mdash)/g, ' ');

      message = message.replace(/ +/g, ' ');
      return message.replace(/<(?:.|\n)*?>/gm, ' ');
    }

    return '';
  }

  cloneEvent() {
    if (this.link && this.link.ID) {
      this.clone.emit(this.link);
    }
  }

  closeEvent() {
    this.close.emit(true);
  }
  sendResult() {
    if (this.link && this.link.ID) {
      const link = `${Settings.global['publicSiteFrontendLink']}/landing-page-overview/${this.link.ID}`;
      this.getResult.emit(this.link);
      this.getDocumentLink.emit(this.link);
      this.getDocumentLinkID.emit(this.link.ID);

      this.getDocumentLinkURL.emit(link);
    } else {
      this.getResult.emit(null);
    }
  }

  openIframe() {
    if (this.link && this.link.ID) {
      let link = `${Settings.global['publicSiteFrontendLink']}/landing-page-overview/${this.link.ID}`;

      if (this.link['IsDefault'] === '1' && this.isPromoterOrAdmin != true) {
        link = `${Settings.global['publicSiteFrontendLink']}/landing-page-overview/${this.link.ID}?m=${this.merchantID}&c=${this.contactID}`;
      }
      const ref2 = RootAppComponent.dialog.open(IframeViewComponent, {
        data: {
          link,
          isSubmit: this.isSubmit,
          extraDetails: this.link.IsDefault != '1' || this.isPromoterOrAdmin == true,
        },
        width: '80%',
        height: '92vh',
        panelClass: 'noCard',
      });

      ref2.componentInstance.close.subscribe((res) => {
        ref2.close();
      });

      ref2.componentInstance.getSubmit.subscribe((res) => {
        if (res) {
          ref2.close();
          ref2.afterClosed().subscribe((r) => {
            this.sendResult();
          });
        }
      });
    }
  }
  copyContent(t, type = 'others') {
    if (type && t && t.ID) {
      const url = t.getLinkByVisitorType(t.ID, type);
      this._clipboardService.copyFromContent(url);
      NotifyAppComponent.displayToast('success', 'Copied!', '');
    }
  }

  share() {
    if (this.link && this.link.ID) {
      if (this.link.IsActive === '1') {
        const ref2 = RootAppComponent.dialog.open(DocumentLinkShareComponent, {
          data: {
            landingPage: this.link,
          },

          width: '80vw',
          panelClass: 'max-width-panel-1400',
        });

        ref2.componentInstance.close.subscribe((res) => {
          ref2.close();
        });
      } else {
        const confirmDialog = new ConfirmDialog(
          'check_circle',
          'Enable Landing Page',
          '<p>This Landing Page is <strong></strong>, Do you wish to Active it before proceed?</p>',
          'Cancel',
          'Okay'
        );

        const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
          data: confirmDialog,
          // SET TO PAYING CASH CARD
        });
        ref.componentInstance.onConfirm.subscribe((val) => {
          if (val == true) {
            ref.close();
            ref.afterClosed().subscribe((res) => {
              const payload = {
                isActive: true,
              };
              this.documentService.modifyDocumentLink(this.link.ID, payload, this.sessionType).subscribe((res) => {
                if (res) {
                  NotifyAppComponent.displayToast('success', 'Success!', 'Landing Page is Active.');

                  const ref2 = RootAppComponent.dialog.open(DocumentLinkShareComponent, {
                    data: {
                      landingPage: this.link,
                    },

                    width: '80vw',
                    panelClass: 'max-width-panel-1400',
                  });

                  ref2.componentInstance.close.subscribe((res) => {
                    ref2.close();
                  });
                }
              });
            });
          } else {
            ref.close();
          }
        });
      }
    }
  }
}
