<div class="note-filter-container full-width flex" *ngIf="selectedPatient" style="background: #f1f8fe">
  <mat-form-field class="search-input-form-field full-width no-line-input ml">
    <mat-icon class="fas fa-search note-filter-container__search-icon" matPrefix></mat-icon>

    <input
      #noteTextFilter
      (input)="setNoteTextFilter($event.target.value)"
      name="noteTextFilter"
      matInput
      placeholder="Search Note..."
    />
  </mat-form-field>

  <div style="width: 50px; cursor: pointer">
    <mat-icon
      class="fas fa-times note-filter-container__icon-clear"
      *ngIf="noteTextFilter.value"
      (click)="clearNoteTextFilter()"
      matSuffix
      matInput
    >
    </mat-icon>
  </div>

  <div class="margin-btm clearfix system-notes-checkbox mr">
    <mat-checkbox
      [(ngModel)]="showSystemNotes"
      (change)="toggleHiddenSystemNotes($event)"
      color="accent"
      name="hideSystemNotes"
    >
      <strong class="primary-color personal-msg"> Show All Notes </strong></mat-checkbox
    >
  </div>

  <div class="margin-btm clearfix system-notes-checkbox mr">
    <mat-checkbox
      [(ngModel)]="showArchivedNotes"
      (change)="toggleHiddenArchivedNotes($event)"
      color="accent"
      name="hideArchivedNotes"
    >
      <strong class="primary-color personal-msg"> Show Archived Notes </strong></mat-checkbox
    >
  </div>

  <div *ngIf="notes.length > 0" style="display: flex">
    <mat-icon class="fas fa-comments primary-color sml mr" style="line-height: 55px"></mat-icon>
    <strong class="primary-color" style="padding-right: 10px; white-space: nowrap">
      {{ filteredNotes | lengthOfArray }} / {{ notes.length }}
    </strong>
  </div>

  <button class="smt navigation" (click)="scrollNotes('bottom', true)" mat-icon-button>
    <mat-icon class="fas fa-chevron-down primary-color"></mat-icon>
  </button>

  <button class="smt navigation" (click)="scrollNotes('top', true)" mat-icon-button>
    <mat-icon class="fas fa-chevron-up primary-color"></mat-icon>
  </button>
</div>

<div class="note-list-body full-width">
  <ng-container *ngIf="noteDates">
    <div
      class="body__load-more-patient-button-container full-width fixed-direction mt text-center"
      *ngIf="noteDates.length > 0"
    >
      <button class="btn" (click)="loadMoreNotes()" mat-raised-button color="primary">Load more...</button>
    </div>
  </ng-container>

  <div class="note-dates-section full-width" *ngFor="let noteDate of noteDates | OrderArrayObject: ['Dates.Action']">
    <div class="date-divider full-width mb" *ngIf="filteredNotes">
      <p class="date-divider__details text-center small" *ngIf="noteDate['Dates.Action']">
        <span *ngIf="noteDate['Dates.Action'] !== todayTitle && noteDate['Dates.Action'] !== yesterdayTitle">
          {{ noteDate['Dates.Action'] | customDate: 'dddd, MMMM DD YYYY' }}
        </span>
        <span *ngIf="noteDate['Dates.Action'] == todayTitle"> Today </span>
        <span *ngIf="noteDate['Dates.Action'] == yesterdayTitle"> Yesterday </span>
      </p>
      <hr/>
    </div>

    <ng-container *ngFor="let filteredNote of filteredNotes">
      <div class="note-list full-width flex mt" *ngIf="filteredNote['Dates.Action'] === noteDate['Dates.Action']">
        <div class="note-content ml mr">
          <div class="note-header">
            <div style="color: red; min-width: 20px; font-weight: bold; font-size: small">
              <span *ngIf="filteredNote.RecontactDone === '0' && filteredNote['Dates.Recontact'] !== '0000-00-00'">
                Follow up due: {{ filteredNote['Dates.Recontact'] | customDate }}
              </span>
            </div>

            <div style="display: flex">
              <div>
                <span class="note-time">
                  {{ filteredNote['Start.Time'] | customTime }}
                </span>
              </div>

              <div>
                <button class="note-menu-button ml" [matMenuTriggerFor]="noteTemplate" mat-icon-button>
                  <mat-icon>more_vert</mat-icon>
                </button>

                <mat-menu #noteTemplate="matMenu">
                  <button (click)="setNoteListToViewMode(filteredNote)" mat-menu-item>View</button>
                  <ng-container *ngIf="filteredNote.Operator_key === user.operatorID">
                    <button (click)="setNoteListToUpdateMode(filteredNote)" mat-menu-item>Edit</button>
                    <button
                      *ngIf="!filteredNote.Is_Archived || filteredNote.Is_Archived === '0'"
                      (click)="archiveNote(filteredNote)"
                      mat-menu-item
                    >
                      Archive
                    </button>
                    <button
                      *ngIf="filteredNote.Is_Archived === '1'"
                      (click)="unarchiveNote(filteredNote)"
                      mat-menu-item
                    >
                      Unarchive
                    </button>
                    <button (click)="deleteNote(filteredNote)" mat-menu-item>Delete</button>
                  </ng-container>
                </mat-menu>
              </div>
            </div>
          </div>

          <div class="sticky-note">
            <div class="note-value">
              <h3>{{ filteredNote.Description | textLength }}</h3>
            </div>

            <hr style="background: black"/>

            <div class="note-details mt">
              <div style="display: flex; flex-wrap: wrap; font-size: small">
                <div class="mr"><b> Note Type: </b> {{ filteredNote.Action }}</div>
                <div class="mr"><b> Note Subject: </b> {{ filteredNote.Subject }}</div>
                <div class="mr"><b> Result: </b> {{ filteredNote.Result }}</div>
              </div>

              <div style="display: flex; font-size: small; margin-top: 5px; margin-bottom: 5px">
                <div><b> Shared With: </b></div>
                <div style="margin-left: 5px">{{ getSharedWithList(filteredNote) }}</div>
              </div>

              <mat-chip-list *ngIf="filteredNote.Relationship_Label">
                <mat-chip (click)="openRelationship(filteredNote)" selected color="primary">
                  <mat-icon> link</mat-icon>
                  {{ filteredNote.Relationship_Label | titlecase }}
                </mat-chip>
              </mat-chip-list>
            </div>
          </div>

          <div class="note-footer">
            <div style="display: flex; font-size: small">
              <div class="mr" *ngIf="filteredNote['Operator_Name']" style="font-style: italic">
                Operator: {{ filteredNote['Operator_Name'] }}
              </div>

              <div *ngIf="filteredNote['Stop.Time'] !== '00:00:00'" style="font-style: italic">
                Duration: {{ filteredNote['Start.Time'] }} - {{ filteredNote['Stop.Time'] }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="list-button-padding full-width"></div>

  <button
    class="new-note-button"
    *ngIf="selectedPatient"
    (click)="setNoteListToCreateMode()"
    mat-raised-button
    color="accent"
  >
    <mat-icon> add</mat-icon>
    new note
  </button>
</div>
