import { Component, Input, OnInit } from '@angular/core';
import { DentalXrayScanDetectionItem } from '../../../feature/ai-scans/shared/types/dental-xray.type';
import { TeethShape } from '../../../feature/treatment/shared/teeth-shape';

@Component({
  selector: 'sr-dental-arch-detections',
  templateUrl: './dental-arch-detections.component.html',
  styleUrls: ['./dental-arch-detections.component.css'],
})
export class DentalArchDetectionsComponent implements OnInit {
  /**
   * Documentation example:
   * <example-url>http://localhost:4200/dental-arch-detections?noBack=true</example-url>
   */

  isAdult = true;
  teethBottom = [];
  teethTop = [];

  @Input() reportResults: {
    apical: DentalXrayScanDetectionItem[];
    caries: DentalXrayScanDetectionItem[];
    restorations: DentalXrayScanDetectionItem[];
  } = { apical: [], caries: [], restorations: [] };

  constructor() {}

  ngOnInit(): void {
    TeethShape.getTopTeethNumbers(this.isAdult).forEach((num) => {
      this.teethTop.push({
        hideNumber: false,
        toothNumber: num,
        apical: this.getApicals(num),
        caries: this.getCaries(num),
        restorations: this.getRestorations(num),
      });
    });
    TeethShape.getBottomTeethNumbers(this.isAdult).forEach((num) => {
      this.teethBottom.push({
        hideNumber: false,
        toothNumber: num,
        apical: this.getApicals(num),
        caries: this.getCaries(num),
        restorations: this.getRestorations(num),
      });
    });
  }

  getApicals(toothName: number) {
    if (typeof toothName === 'string') {
      toothName = parseInt(toothName, 10);
    }

    return this.reportResults.apical.filter((apical) => apical.toothName === toothName).map((apical) => apical.subtype);
  }

  getCaries(toothName: number) {
    if (typeof toothName === 'string') {
      toothName = parseInt(toothName, 10);
    }

    return this.reportResults.caries.filter((caries) => caries.toothName === toothName).map((caries) => caries.subtype);
  }

  getRestorations(toothName: number) {
    if (typeof toothName === 'string') {
      toothName = parseInt(toothName, 10);
    }

    return this.reportResults.restorations
      .filter((restorations) => restorations.toothName === toothName)
      .map((restorations) => restorations.subtype);
  }
}
