import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { QuotationContractViewComponent } from '../quotation-contract-view/quotation-contract-view.component';
import { QuotationDecisionModalComponent } from '../quotation-decision-modal/quotation-decision-modal.component';
import { QuotationDetailViewComponent } from '../quotation-detail-view/quotation-detail-view.component';
import { QuotationDocumentCreateComponent } from '../quotation-document-create/quotation-document-create.component';
const routes: Routes = [
  {
    path: 'quotation-detail-view',
    component: QuotationDetailViewComponent,
  },
  {
    path: 'quotation-contract-view',
    component: QuotationContractViewComponent,
  },
  {
    path: 'quotation-document-create',
    component: QuotationDocumentCreateComponent,
  },
  {
    path: 'decision-modal',
    component: QuotationDecisionModalComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class QuotationRoutingModule {}
