import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { HeaderService } from '../../../shared/services/header.service';
import { MerchantViewComponent } from '../../merchant/merchant-view/merchant-view.component';
import { MerchantService } from '../../merchant/shared/merchant.service';

@Component({
  selector: 'app-medical-history-list-overview',
  templateUrl: './medical-history-list-overview.component.html',
  styleUrls: ['./medical-history-list-overview.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('*', style({ opacity: 0 }), { optional: true }),
        query(
          '*',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('*', style({ opacity: 1 }), { optional: true }),
        query(
          '*',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class MedicalHistoryListOverViewComponent implements OnInit {
  merchants = [];

  merchantID = 'none';
  activeContracts = true;
  isAdminOrPromoter = null;
  hideMerchantSelect = false;

  constructor(
    private activeRoute: ActivatedRoute,
    private AuthenticationService: AuthenticationService,
    private merchantService: MerchantService
  ) {}

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      this.AuthenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isAdminOrPromoter = res;

        if (this.isAdminOrPromoter == true) {
          const payload = {
            fields: 'ID,TradingAs,CalculatedName,Status',
          };
          this.merchantService.getList(payload, this.isAdminOrPromoter).subscribe((res) => {
            this.merchants = res;
          });

          HeaderService.getPromoterViewAsObject().subscribe((res) => {
            if (res && res['promoterViewAs'] && res['promoterViewAs'] == 'promoter') {
              this.merchantID = null;
              this.hideMerchantSelect = false;
            } else if (res && res['promoterViewAs'] && res['promoterViewAs'] == 'merchant') {
              this.merchantID = res['merchantID'];
              this.hideMerchantSelect = true;
            }
          });

          const _mID = JSON.parse(localStorage.getItem('selectedMerchant'));

          if (_mID) {
            // this.hideMerchantSelect = true;
            this.merchantID = _mID;
          }
        }
      });
    });
  }

  viewMerchant() {
    const ref = RootAppComponent.dialog.open(MerchantViewComponent, {
      data: this.merchantID,
      width: '600px',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  hideDetails(e) {
    this.hideMerchantSelect = e;
  }
}
