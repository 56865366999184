import { animate, animateChild, query, stagger, style, transition, trigger } from '@angular/animations';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Optional,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatButtonToggleGroup } from '@angular/material';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { CustomPhonePipe } from '../../../shared/pipes/custom-phone.pipe';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { TagsService } from '../../tags/shared/tags.service';
import { SocialMediaService } from '../shared/social-media.service';
import { SocialMediaViewComponent } from '../social-media-view/social-media-view.component';

@Component({
  selector: 'app-social-media-gallery-list',
  templateUrl: './social-media-gallery-list.component.html',
  styleUrls: ['./social-media-gallery-list.component.css'],
  animations: [
    trigger('fade', [transition(':enter', [style({ opacity: 0 }), animate('.1s ease')])]),
    trigger('stagger', [transition(':enter', [query(':enter', stagger('.1s', [animateChild()]), { optional: true })])]),
  ],
})
export class SocialMediaGalleryListComponent implements OnInit {
  tags = new FormControl();

  @ViewChild(MatButtonToggleGroup, { static: false })
  public toggleGroup: MatButtonToggleGroup;

  suppliers = [];
  selectedSuppliers = [];
  @Input()
  displayHeader = true;

  @Input()
  isActive = true;

  @Input()
  title = 'Social Media Posts';

  @Input()
  campaignID;
  @Input()
  urlType;

  @Output()
  selectTemplate = new EventEmitter();

  @Output()
  actionProceed = new EventEmitter();
  @Input()
  mode = 'list';
  @Input()
  openModal = true;

  templates = [];
  tagsList = [];
  util = new UtilsClass();
  imageUrl: 'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/profile-placeholder.png';
  orderByFilter = '-DateTimeCreated';

  @Output()
  close = new EventEmitter();

  settings = Settings.global;
  filter;
  serviceRef;
  isAdminOrPromoter = false;
  isMobile = false;
  socialMediaType = '';
  currentUser;
  sessionType;

  selectedTagList: any = [];

  @Output()
  confirmShare = new EventEmitter();

  @Output()
  getSelectedTemplate = new EventEmitter();
  isModal = false;

  @Output()
  getSelectedTemplateID = new EventEmitter();
  currentPractice;

  renderValue = {};

  dynamicData = [];
  section = 1;
  limit = 100;
  displayLoadMore = false;
  finishLoading = false;

  utils = new UtilsClass();

  constructor(
    private router: Router,
    private customPhonePipe: CustomPhonePipe,
    private socialMediaService: SocialMediaService,
    private authenticationService: AuthenticationService,
    private tagService: TagsService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.campaignID) {
        this.campaignID = data.campaignID;
        this.isModal = true;
      }
      if (data.urlType) {
        this.urlType = data.urlType;
        this.isModal = true;
      }
    }
  }

  ngOnInit() {
    this.authenticationService.getCurrentPractice().subscribe((currentPractice) => {
      this.currentPractice = currentPractice;
      this.authenticationService.getSessionType().subscribe((sessionType) => {
        this.sessionType = sessionType;

        if (window.matchMedia('(min-width: 992px)').matches) {
          this.isMobile = false;
        } else {
          this.isMobile = true;
        }

        const p = {
          isPromoter: true,
          fields: 'ID,Label',
        };

        this.socialMediaService.getBeneficiaryList(p, this.sessionType).subscribe((res) => {
          if (res) {
            this.suppliers = res;
          }
        });

        this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
          this.isAdminOrPromoter = res;

          this.section = 1;
          this.templates = [];
          this.setup();
        });
      });
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isAdminOrPromoter = res;

      this.section = 1;
      this.templates = [];
      this.setup();
    });
  }

  getColorFromSocial(type) {
    if (type === 'Pinterest') {
      return '#c8232c';
    } else if (type === 'Facebook') {
      return '#4267B2';
    } else if (type === 'Instagram') {
      return '#E1306C';
    } else if (type === 'LinkedIn') {
      return '#2867B2';
    } else if (type === 'Twitter') {
      return '#1DA1F2';
    }

    return '#4267B2';
  }

  toLowerCase(t) {
    if (t) {
      return String(t).toLocaleLowerCase();
    }
    return null;
  }

  setup() {
    this.addDynamicData();

    const payload = {
      section: this.section,
      isActive: true,
      fields:
        'ID,DateTimeCreated,LastModified,Label,SocialMediaType.Code,SocialMediaType.Label,' +
        'SupplierKeys,SupplierLabels,Tags,Tag.Label,StaticList.Label,ActionCall.Label,ActionCall.Description',
      limit: this.limit,
    };

    this.socialMediaService.getSocialMediaList(payload, this.sessionType).subscribe((res) => {
      if (res) {
        if (res && res.length >= this.limit) {
          this.displayLoadMore = true;
        } else {
          this.displayLoadMore = false;
        }

        for (let i = 0; i < res.length; i++) {
          if (res[i]['Tag.Label'] && res[i]['StaticList.Label']) {
            res[i]['StaticList.Label'] = res[i]['Tag.Label'] + '|' + res[i]['StaticList.Label'];
          } else if (res[i]['Tag.Label']) {
            res[i]['StaticList.Label'] = res[i]['Tag.Label'];
          }

          if (res && res[i] && res[i]['StaticList.Label']) {
            res[i]['StaticList.Label'] = res[i]['StaticList.Label'].split('|');

            this.tagsList = this.tagsList.concat(res[i]['StaticList.Label']);
            this.tagsList = _.uniq(this.tagsList);
            this.tagsList = JSON.parse(JSON.stringify(this.tagsList));
          } else {
            res[i]['StaticList.Label'] = [];
          }

          if (res && res[i] && res[i]['SupplierKeys']) {
            res[i]['SupplierKeys'] = res[i]['SupplierKeys'].split('|');
          } else {
            res[i]['SupplierKeys'] = [];
          }

          if (res[i]['ActionCall.Label']) {
            res[i]['ActionCall.Label'] = this.convertMessage(res[i]['ActionCall.Label']);
          }
        }

        this.templates = _.uniqBy(this.templates.concat(res), 'ID');
      }
      this.finishLoading = true;
    });
  }

  addDynamicData() {
    if (this.currentPractice) {
      if (this.currentPractice['TradingAs']) {
        this.renderValue['practiceTradingAs'] = this.currentPractice['TradingAs'];

        this.dynamicData.push({
          label: 'Trading As',
          code: this.currentPractice['TradingAs'],
        });
      } else {
        this.renderValue['practiceTradingAs'] = '';
      }

      if (this.currentPractice['phones.Number']) {
        this.renderValue['practicePhoneNumber'] = this.customPhonePipe.transform(
          this.currentPractice['phones.Number'],
          'landLine'
        );
        this.dynamicData.push({
          label: 'Phone number',
          code: this.customPhonePipe.transform(this.currentPractice['phones.Number'], 'landLine'),
        });
      } else {
        this.renderValue['practicePhoneNumber'] = '';
      }

      if (this.currentPractice['emails.Email']) {
        this.renderValue['practiceEmail'] = this.currentPractice['emails.Email'];
        this.dynamicData.push({
          label: 'Email',
          code: this.currentPractice['emails.Email'],
        });
      } else {
        this.renderValue['practiceEmail'] = '';
      }

      if (this.currentPractice['addresses.Calculated']) {
        this.renderValue['practiceAddress'] = this.currentPractice['addresses.Calculated'];
        this.dynamicData.push({
          label: 'Address',
          code: this.currentPractice['addresses.Calculated'],
        });
      } else {
        this.renderValue['practiceAddress'] = '';
      }

      if (this.currentPractice['URL']) {
        this.renderValue['practiceSite'] = this.currentPractice['URL'];
        this.dynamicData.push({
          label: 'Web Site',
          code: this.currentPractice['URL'],
        });
      } else {
        this.renderValue['practiceSite'] = '';
      }
    }
  }

  loadMore() {
    this.section = this.section + 1;
    this.setup();
  }

  closeModal() {
    this.close.emit(true);
  }

  convertMessage(message) {
    if (message) {
      let result;
      result = this.utils.convertMessage(message, this.renderValue);
      return result;
    }

    return message;
  }

  viewSocialMediaPosts(template) {
    if (this.openModal == true) {
      const ref3 = RootAppComponent.dialog.open(SocialMediaViewComponent, {
        data: {
          templateID: template.ID,
          campaignID: this.campaignID,
          urlType: this.urlType,
        },
        width: '900px',
        panelClass: ['noCard', 'full-big-screen'],
      });
      ref3.componentInstance.confirmShare.subscribe((res) => {
        this.confirmShare.emit(res);
        ref3.close();
      });
      ref3.componentInstance.close.subscribe((res) => {
        ref3.close();
      });
    } else if (template && template.ID) {
      this.getSelectedTemplate.emit(template);
      this.getSelectedTemplateID.emit(template.ID);
    }
  }

  getImageLink(id, date) {
    return this.socialMediaService.getThumbnailImageStreamLink(id, date);
  }

  EditSocialMedia(t) {
    if (t && t.ID) {
      this.router.navigate([
        '/merchant',
        {
          outlets: {
            page: ['template-social-media-edit', t.ID],
          },
        },
      ]);
    }
  }

  ngOnDestroy() {
    if (this.serviceRef) {
      this.serviceRef.unsubscribe();
    }
  }
}
