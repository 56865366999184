<div class="rel dentist-card" *ngIf="dentist" @onChangeAnimation>
  <div class="animate">
    <mat-dialog-content class="DentistProfile">
      <div class="card-header primary-gradient-img clearfix stacked">
        <button class="btn-close btn-clear mat-button" (click)="closeEvent()">
          <mat-icon>clear</mat-icon>
        </button>

        <div class="row clearfix">
          <div class="row titleArea clearfix">
            <app-ep-document-view-image
              class="profile-lead-image"
              [hideZoomButton]="true"
              [defaultPicture]="
                'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/practice-pic.png'
              "
              [link]="profileLink"
              tabindex="0"
              alt="Your default profile image"
            >
            </app-ep-document-view-image>
            <h2 class="summary-header rm-m" *ngIf="dentist">
              {{ dentist?.Salutation }} {{ dentist?.FirstName }}
              {{ dentist?.Name }}
            </h2>
          </div>
        </div>
      </div>

      <div class="row clearfix profile">
        <!-- <div class="row clearfix mb" *ngIf="showProfileGallery==true"> -->

        <!-- Display user images as gallery (if more than one) -->
        <!-- <app-gallery [inCard]="true" [canPreview]="true"></app-gallery> -->

        <!-- Display user images as image (if only one ) -->
        <!-- <app-ep-document-view-image class="profile-inner-image" [hideZoomButton]="false"
          [defaultPicture]="'https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/practice-pic.png'" [document]="profilePic" tabindex="0"
          alt="Your default profile image">
        </app-ep-document-view-image> -->

        <!-- </div> -->

        <!-- profile description -->
        <div class="row clearfix bio" *ngIf="dentist['Description']">
          <read-more [text]="dentist['Description']" [maxLength]="450"></read-more>
        </div>
      </div>

      <!-- rm map view 04-20 -->
      <!-- <div class="row clearfix ">
        <app-map-view *ngIf="dentist['addresses.Calculated'] && displayMapFlag==true" [icon]="iconAddres" class="" [forceGettingAddress]='true'
          [canClose]="false" [unitNumber]="dentist['addresses.Unit']" [canClick]="false"
          [streetType]="dentist['addresses.Street Type']" [streetName]="dentist['addresses.Street Name']"
          [latitude]="dentist['Address.Latitude']" [longitude]="dentist['Address.Longitude']"
          [streetNumber]="dentist['addresses.Street Nr']" (notFoundEvent)="notFoundEvent($event)" [zoom]="17"
          [postCode]="dentist['addresses.Postcode']" [app-state]="dentist['addresses.State']"
          [suburb]="dentist['addresses.Suburb']" [moreInfo]="" [showDetails]="false"></app-map-view>
      </div> -->

      <div
        class="row clearfix mb"
        *ngIf="
          dentist['URL'] ||
          dentist['Facebook'] ||
          dentist['Twitter'] ||
          dentist['LinkedIn'] ||
          dentist['Instagram'] ||
          dentist['Pinterest'] ||
          dentist['Tumblr'] ||
          dentist['Vimeo']
        "
      >
        <hr />
        <div class="row clearfix socials flex flex-wrap">
          <!-- open social links in new tab -->
          <button
            *ngIf="dentist['URL']"
            (click)="openSocialLink(dentist['URL'])"
            mat-stroked-button
            matTooltip="Visit website"
          >
            Website
            <mat-icon class="fas fa-globe"></mat-icon>
          </button>
          <button
            *ngIf="dentist['Facebook']"
            (click)="openSocialLink(dentist['Facebook'])"
            mat-stroked-button
            matTooltip="Visit Facebook page"
          >
            Facebook
            <mat-icon class="fab fa-facebook"></mat-icon>
          </button>
          <button
            *ngIf="dentist['Twitter']"
            (click)="openSocialLink(dentist['Twitter'])"
            mat-stroked-button
            matTooltip="Visit Twitter page"
          >
            Twitter
            <mat-icon class="fab fa-twitter"></mat-icon>
          </button>
          <button
            *ngIf="dentist['LinkedIn']"
            (click)="openSocialLink(dentist['LinkedIn'])"
            mat-stroked-button
            matTooltip="Visit LinkedIn page"
          >
            LinkedIn
            <mat-icon class="fab fa-linkedin"></mat-icon>
          </button>
          <button
            *ngIf="dentist['Instagram']"
            (click)="openSocialLink(dentist['Instagram'])"
            mat-stroked-button
            matTooltip="Visit Instagram page"
          >
            Instagram
            <mat-icon class="fab fa-instagram"></mat-icon>
          </button>
          <button
            *ngIf="dentist['Pinterest']"
            (click)="openSocialLink(dentist['Pinterest'])"
            mat-stroked-button
            matTooltip="Visit Pinterest page"
          >
            Pinterest
            <mat-icon class="fab fa-pinterest"></mat-icon>
          </button>
          <button
            *ngIf="dentist['Tumblr']"
            (click)="openSocialLink(dentist['Tumblr'])"
            mat-stroked-button
            matTooltip="Visit Tumblr page"
          >
            Tumbler
            <mat-icon class="fab fa-tumblr"></mat-icon>
          </button>
          <button
            *ngIf="dentist['Vimeo']"
            (click)="openSocialLink(dentist['Vimeo'])"
            mat-stroked-button
            matTooltip="Visit Vimeo page"
          >
            Vimeo
            <mat-icon class="fab fa-vimeo"></mat-icon>
          </button>
        </div>
      </div>
    </mat-dialog-content>
  </div>
</div>
