import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DiscountSettingComponent } from '../discount-setting/discount-setting.component';
import { LandingPageEmbedComponent } from '../landing-page-embed/landing-page-embed.component';
import { LandingPageShareComponent } from '../landing-page-share/landing-page-share.component';
import { MerchantAccessCreateComponent } from '../merchant-access-create/merchant-access-create.component';
import { MerchantAccessListComponent } from '../merchant-access-list/merchant-access-list.component';
import { MerchantAuthCodeModalComponent } from '../merchant-auth-code-modal/merchant-auth-code-modal.component';
import { MerchantCreateComponent } from '../merchant-create/merchant-create.component';
import { MerchantDataImportComponent } from '../merchant-data-import/merchant-data-import.component';
import { MerchantInformedConsentComponent } from '../merchant-informed-consent/merchant-informed-consent.component';
import { MerchantLookupComponent } from '../merchant-lookup/merchant-lookup.component';
import { MerchantMapModalComponent } from '../merchant-map-modal/merchant-map-modal.component';
import { MerchantProductAssistantComponent } from '../merchant-product-assistant/merchant-product-assistant.component';
import { MerchantProductAssitantOverviewComponent } from '../merchant-product-assitant-overview/merchant-product-assitant-overview.component';
import { MerchantSelectListComponent } from '../merchant-select-list/merchant-select-list.component';
import { MerchantTradingHoursOverviewComponent } from '../merchant-trading-hours-overview/merchant-trading-hours-overview.component';
import { MerchantTradingHoursViewComponent } from '../merchant-trading-hours-view/merchant-trading-hours-view.component';
import { MerchantTradingHoursComponent } from '../merchant-trading-hours/merchant-trading-hours.component';
import { MerchantUpdateWebsiteComponent } from '../merchant-update-website-modal/merchant-update-website-modal.component';
import { MerchantViewModalComponent } from '../merchant-view-modal/merchant-view-modal.component';
import { MerchantViewPictureComponent } from '../merchant-view-picture/merchant-view-picture.component';
import { MerchantViewComponent } from '../merchant-view/merchant-view.component';
import { MerchantVisitorComponent } from '../merchant-visitor/merchant-visitor.component';
import { VersionUpdateComponent } from '../version-update/version-update.component';
import { PasswordPromptComponent } from '../../operator/password-prompt/password-prompt.component';
import { OperatorChangePasswordComponent } from '../../operator/operator-change-password/operator-change-password.component';
import { OperatorLookupComponent } from '../../operator/operator-lookup/operator-lookup.component';
import { OperatorContactComponent } from '../../operator/operator-contact/operator-contact.component';
import { OperatorFilterComponent } from '../../operator/operator-filter/operator-filter.component';
import { OperatorViewPictureComponent } from '../../operator/operator-view-picture/operator-view-picture.component';
import { OperatorViewComponent } from '../../operator/operator-view/operator-view.component';
import { SelfServiceLandingPageComponent } from '../../document/self-service-landing-page/self-service-landing-page.component';
import { DocumentLinkPdfCreateComponent } from '../../document/document-link-pdf-create/document-link-pdf-create.component';
import { LandingPageNewModalComponent } from '../../document/landing-page-new-modal/landing-page-new-modal.component';
import { DocumentLinkShareComponent } from '../../document/document-link-share/document-link-share.component';
import { DocumentListThumbnailComponent } from '../../document/document-list-thumbnail/document-list-thumbnail.component';
import { LandingPageHeaderLookupComponent } from '../../document/landing-page-header-lookup/landing-page-header-lookup.component';
import { DocumentLinkViewComponent } from '../../document/document-link-view/document-link-view.component';
import { DocumentCreateComponent } from '../../document/document-create/document-create.component';
import { DocumentLinkDetailedViewComponent } from '../../document/document-link-detailed-view/document-link-detailed-view.component';
import { DocumentLinkCreateComponent } from '../../document/document-link-create/document-link-create.component';
import { DocumentLinkLookupComponent } from '../../document/document-link-lookup/document-link-lookup.component';
import { MerchantSettlementComponent } from '../merchant-settlement/merchant-settlement.component';
import { OperatorLoginAccessComponent } from '../../operator/operator-login-access/operator-login-access.component';


const routes: Routes = [



  {
    path: 'MerchantSettlementComponent',
    component: MerchantSettlementComponent,
  },
  {
    path: 'SelfServiceLandingPageSelfServiceLandingPageComponent',
    component: SelfServiceLandingPageComponent,
  },
  {
    path: 'DocumentLinkPdfCreateDocumentLinkPdfCreateComponent',
    component: DocumentLinkPdfCreateComponent,
  },

  {
    path: 'LandingPageNewModalLandingPageNewModalComponent',
    component: LandingPageNewModalComponent,
  },
  {
    path: 'DocumentLinkShareDocumentLinkShareComponentComponent',
    component: DocumentLinkShareComponent,
  },
  {
    path: 'DocumentListThumbnailDocumentListThumbnailComponentComponent',
    component: DocumentListThumbnailComponent,
  },
  {
    path: 'LandingPageHeaderLookupLandingPageHeaderLookupComponentComponent',
    component: LandingPageHeaderLookupComponent,
  },
  {
    path: 'DocumentLinkViewDocumentLinkViewComponentComponent',
    component: DocumentLinkViewComponent,
  },
  {
    path: 'DocumentCreateDocumentCreateComponentComponent',
    component: DocumentCreateComponent,
  },
  {
    path: 'DocumentLinkDetailedViewDocumentLinkDetailedViewComponentComponent',
    component: DocumentLinkDetailedViewComponent,
  },

  {
    path: 'DocumentLinkCreateDocumentLinkCreateComponentComponent',
    component: DocumentLinkCreateComponent,
  },

  {
    path: 'DocumentLinkLookupDocumentLinkLookupComponentComponent',
    component: DocumentLinkLookupComponent,
  },

  {
    path: 'operator-view',
    component: OperatorViewComponent,
  },
  {
    path: 'operator-view/:operatorID',
    component: OperatorViewComponent,
  },
  {
    path: 'operator-view-profile',
    component: OperatorViewPictureComponent,
  },

  {
    path: 'operator-filter',
    component: OperatorFilterComponent,
  },

  {
    path: 'operator-contact',
    component: OperatorContactComponent,
  },
  {
    path: 'operator-filter',
    component: OperatorLookupComponent,
  },
  {
    path: 'operator-change-password',
    component: OperatorChangePasswordComponent,
  },
  {
    path: 'PasswordPromptComponent',
    component: PasswordPromptComponent,
  },
  {
    path:'OperatorLoginAccessComponent',
    component: OperatorLoginAccessComponent,
  },

  {
    path: 'merchant-create',
    component: MerchantCreateComponent,
  },
  {
    path: 'LandingPageEmbedComponent',
    component: LandingPageEmbedComponent,
  },
  {
    path: 'LandingPageShareComponent',
    component: LandingPageShareComponent,
  },
  {
    path: 'VersionUpdateComponent',
    component: VersionUpdateComponent,
  },

  {
    path: 'MerchantVisitorComponent',
    component: MerchantVisitorComponent,
  },

  {
    path: 'MerchantProductAssistantComponent',
    component: MerchantProductAssistantComponent,
  },

  {
    path: 'MerchantProductAssitantOverviewComponent',
    component: MerchantProductAssitantOverviewComponent,
  },

  {
    path: 'MerchantSelectListComponent',
    component: MerchantSelectListComponent,
  },

  {
    path: 'MerchantUpdateWebsiteComponent',
    component: MerchantUpdateWebsiteComponent,
  },

  {
    path: 'MerchantAccessListComponent/:moduleCode',
    component: MerchantAccessListComponent,
  },
  {
    path: 'MerchantAccessListComponent',
    component: MerchantAccessListComponent,
  },

  {
    path: 'merchant-view-pricture',
    component: MerchantViewPictureComponent,
  },
  {
    path: 'DiscountSettingComponent',
    component: DiscountSettingComponent,
  },

  {
    path: 'MerchantAccessCreateComponent',
    component: MerchantAccessCreateComponent,
  },

  {
    path: 'merchant-view',
    component: MerchantViewComponent,
  },
  {
    path: 'merchant-view-modal',
    component: MerchantViewModalComponent,
  },
  {
    path: 'merchant-trading-hours',
    component: MerchantTradingHoursComponent,
  },
  {
    path: 'merchant-trading-hours-overview',
    component: MerchantTradingHoursOverviewComponent,
  },
  {
    path: 'merchant-trading-hours-view',
    component: MerchantTradingHoursViewComponent,
  },
  {
    path: 'merchant-lookup',
    component: MerchantLookupComponent,
  },
  {
    path: 'merchant-informed-consent',
    component: MerchantInformedConsentComponent,
  },

  {
    path: 'data-import',
    component: MerchantDataImportComponent,
  },

  {
    path: 'merchant-map',
    component: MerchantMapModalComponent,
  },
  {
    path: 'merchant-auth-code-modal',
    component: MerchantAuthCodeModalComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MerchantRoutingModule {}
