<div class="rel text-center cdia">

  <div class="full-width flex">
    <div class="card-header primary-gradient-img full-width  clearfix confirmModal" [ngClass]="icon">
      <button class="btn-close btn-clear mat-button smt white custom-close" [ngClass]="!title ? 'no-title-close' : ''"
        matDialogClose>
        <mat-icon>clear</mat-icon>
      </button>
      <div class="full-width flex main-title-container" [ngClass]="{ 'main-icon-container-no-title': !title }">
        <div class="main-icon-container text-center">
          <mat-icon class="main-icon accent-color" *ngIf="isFontAwsome(icon) != true"> {{ icon || 'info' }}</mat-icon>
          <mat-icon class="main-icon accent-color {{ icon || 'fas fa-info' }}" *ngIf="isFontAwsome(icon) == true">
          </mat-icon>
        </div>
        <div class="row full-width title-container clearfix">
          <div class="title-container-sub">
            <h2 class="text-left title-header rm-m">
              {{ title   | translate }}
            </h2>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--
  <mat-icon class="xlarge-modal-icon accent-color">{{icon}}</mat-icon>
  <h2 mat-dialog-title class="sr-title rm-mb mt">{{title}}</h2> -->
  <mat-dialog-content>
    <div class="row clearfix mb">
      <div [innerHTML]="description | safeHtml"></div>
    </div>

    <div class="text-center mb" *ngIf="isInformation == true">
      <button (click)="onInformation()" mat-raised-button color="primary">{{ informationLabel }}</button>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="button-row text-center">
    <button *ngIf="isNo" mat-raised-button matDialogClose>{{ noLabel }}</button>

    <button *ngIf="yesLabel" (click)="onYes()" mat-raised-button color="accent">{{ yesLabel }}</button>

    <button *ngIf="isSecondConfirmation == true" (click)="onYesSecond()" mat-raised-button color="primary">
      {{ yesSecondLabel }}
    </button>
  </mat-dialog-actions>
</div>
