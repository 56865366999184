import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CalendarView } from 'angular-calendar';
import * as moment from 'moment';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { CalendarEventType } from '../../../shared/components/calendar-view/calendar-view.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { CustomerService } from '../../customer/shared/customer.service';
import { DentistViewModalComponent } from '../../dentist/dentist-view-modal/dentist-view-modal.component';
import { DentistService } from '../../dentist/shared/dentist.service';
import { MerchantViewModalComponent } from '../../merchant/merchant-view-modal/merchant-view-modal.component';
import { MerchantService } from '../../merchant/shared/merchant.service';
import { AppointmentViewComponent } from '../appointment-view/appointment-view.component';
import { AppointmentService } from '../shared/appointment.service';
import { AppointmentUtilClass } from '../shared/appointmentUtil';

import * as _ from 'lodash';
import { CustomDatePipe } from '../../../shared/pipes/custom-date.pipe';
import { UtilsService } from '../../../shared/services/utils.service';
import { Settings } from '../../../shared/types/settings';

@Component({
  selector: 'app-appointment-list-calendar',
  templateUrl: './appointment-list-calendar.component.html',
  styleUrls: ['./appointment-list-calendar.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('*', style({ opacity: 0 }), { optional: true }),
        query(
          '*',
          stagger('10ms', [
            animate(
              '0.2s ease-in',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-10%)', offset: 0 }),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('*', style({ opacity: 1 }), { optional: true }),
        query(
          '*',
          stagger('10ms', [
            animate(
              '0.2s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0 }),
                style({ opacity: 0, transform: 'translateY(-10%)', offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class AppointmentListCalendarComponent implements OnInit, OnChanges, OnDestroy {
  @Input() status;
  @Input() statusCodes;

  @Input() title = 'Appointments ';
  @Input() isEdit = false;
  @Input() isRemove = false;
  @Input() dateFrom;
  @Input() dateTo;

  @Input() patientID;
  @Input() dentistID;
  @Input() merchantID;
  @Input() viewType: CalendarView = CalendarView.Month;
  @Input()
  hasBookingDate;
  runAction = new EventEmitter();
  @Input() limit;
  settings = Settings.global;
  user;
  firstName;
  lastName;
  firstLoadEvent = false;
  sessionType;
  appointmentEvents;
  appointments;
  destroyEvent = new EventEmitter();
  util = new UtilsClass();
  utilAppointment = new AppointmentUtilClass();

  editCalendar = new EventEmitter();
  removeCalendar = new EventEmitter();
  dentistsUniqueList;
  statusUniqueList;

  selectedStatus = [];
  selectedDentist = [];

  navMode = 'side';
  openFlag = true;
  statusGlobal = true;

  dentistGlobal = true;
  serviceRef;

  constructor(
    private customDate: CustomDatePipe,
    private appointmentService: AppointmentService,
    private authenticationService: AuthenticationService,
    private merchantService: MerchantService,
    private customerService: CustomerService,
    private dentistService: DentistService,
    private utilService: UtilsService,
    private router: Router
  ) {}

  ngOnInit() {
    if (!this.limit) {
      this.limit = this.settings['listItemNumber'];
    }
    this.authenticationService.getSessionType().subscribe(async (sessionType) => {
      if (sessionType) {
        this.sessionType = sessionType;
      }

      await this.initData();
      this.firstLoadEvent = true;
    });
  }

  ngOnChanges() {
    this.initData();
  }

  buildAppointmentEvents(data) {
    if (data && data.length > 0) {
      this.appointmentEvents = data
        .filter((rec) => rec.Date && rec.Date != '0000-00-00')
        .map((rec) => {
          let title = `${rec.CustomerOrProspect_CalculatedName} - ${rec['Type.Label']}`;
          let start;
          let end;
          if (rec['SubType.Label']) {
            title += `: ${rec['SubType.Label']}`;
          }

          if (rec['MerchantContact_CalculatedName']) {
            title += `, Assigned to ${rec['MerchantContact_CalculatedName']} `;
          }

          if (rec['Date'] && rec['Date'] != '0000-00-00' && (!rec['Date_End'] || rec['Date_End'] == '0000-00-00')) {
            const time = this.customDate.transform(rec['Date'] + ' ' + rec['Time'], 'hh:mm A');
            title += `, At ${time} `;
            start = moment(
              this.customDate.transform(rec['Date'] + ' ' + rec['Time'], 'MM-DD-YYYY hh:mm A'),
              'MM-DD-YYYY hh:mm A'
            ).toDate();
          }

          if (rec['Date'] && rec['Date_End'] && rec['Date'] != '0000-00-00' && rec['Date_End'] != '0000-00-00') {
            const time = this.customDate.transform(rec['Date'] + ' ' + rec['Time'], 'hh:mm A');
            const time2 = this.customDate.transform(rec['Date_End'] + ' ' + rec['Time_End'], 'hh:mm A');
            title += ` From ${time} To ${time2}`;

            start = moment(
              this.customDate.transform(rec['Date'] + ' ' + rec['Time'], 'MM-DD-YYYY hh:mm A'),
              'MM-DD-YYYY hh:mm A'
            ).toDate();
            end = moment(
              this.customDate.transform(rec['Date_End'] + ' ' + rec['Time_End'], 'MM-DD-YYYY hh:mm A'),
              'MM-DD-YYYY hh:mm A'
            ).toDate();
          }

          const event: CalendarEventType = {
            id: rec.ID,
            start,
            title,
            color: this.getEventColor(rec),
            colorType: rec['Status.Label'],
          };

          if (end) {
            event.end = end;
          }
          return event;
        });
    } else {
      this.appointmentEvents = [];
    }
  }

  getEventColor(rec) {
    return {
      primary: this.utilAppointment.getStatusColor(rec),
      secondary: this.utilAppointment.getStatusColor(rec),
    };
  }

  async initData() {
    const promises: Promise<any>[] = [];

    if (this.merchantID) {
      const payload = {};
      promises.push(this.merchantService.getMerchantDetails(this.merchantID, payload, this.sessionType).toPromise());
    } else if (this.patientID) {
      const payload = {};
      promises.push(this.customerService.getCustomerDetails(this.patientID, payload, this.sessionType).toPromise());
    } else if (this.dentistID) {
      const payload = {};
      promises.push(this.dentistService.getContactDetails(this.merchantID, payload, this.sessionType).toPromise());
    }

    if (this.merchantID || this.patientID || this.dentistID) {
      const res = await Promise.all(promises);
      this.user = res[0];
      if (this.merchantID) {
        this.firstName = this.user.FirstName;
        this.lastName = this.user.Name;
      } else if (this.patientID) {
        this.firstName = this.user.FirstName;
        this.lastName = this.user.Name;
      } else if (this.dentistID) {
        this.firstName = this.user.FirstName;
        this.lastName = this.user.LastName;
      }
    }

    this.statusUniqueList = await this.utilService.getCodeLookup('AppointmentStatus').toPromise();

    const payloadRecord = {
      merchantID: this.merchantID,
      patientID: this.patientID,
      dentistID: this.dentistID,
      dateFrom: this.dateFrom,
      dateTo: this.dateTo,
      status: this.status,
      statusCodes: this.statusCodes,
      limit: this.limit,
      orderBy: '-Date,-Time,-LastModified,-DateTimeCreated',
      section: 0,
      hasBookingDate: this.hasBookingDate,
      fields:
        'Active,Campaign_Key,CustomerOrProspect_CalculatedName,CustomerOrProspect_Email,CustomerOrProspect_Mobile,' +
        'CustomerOrProspect_Key,Date,DateTimeCreated,ID,Invitation_key,MerchantContact_CalculatedName,Merchant_CalculatedName,' +
        'Merchant_key,Source.Label,Status.Code,Status.Label,Time,Date_Requested,Date_Booked,Type.Label,MerchantContact_key,' +
        'ConfirmationBySMS,ReminderBySMS,Type.DefaultDuration,SubType.DefaultDuration,Date_End,Time_End',
    };

    const innerFunction = (section) => {
      section = section + 1;
      UtilsClass.loadingDataSection(section);
      payloadRecord.section = section;

      this.serviceRef = this.appointmentService.getAppointmentList(payloadRecord, this.sessionType).subscribe((res) => {
        if (res.length > 0 && this.appointments && this.appointments.length > 0) {
          this.appointments = this.appointments.concat(this.appointments);
          innerFunction(section);
        } else {
          this.checkAll();
          this.buildAppointmentEvents(this.appointments);
        }
      });
    };

    this.serviceRef = this.appointmentService.getAppointmentList(payloadRecord, this.sessionType).subscribe((res) => {
      if (res) {
        this.appointments = res || [];
        this.checkAll();
        this.buildAppointmentEvents(this.appointments);

        if (res.length >= this.limit) {
          innerFunction(1);
        }
      }
    });
  }

  checkAll(type = null) {
    if (this.appointments && this.appointments.length > 0) {
      if (type == 'dentist') {
        this.dentistsUniqueList = _.uniqBy(this.appointments.concat([]), 'MerchantContact_key');

        if (this.dentistsUniqueList && this.dentistsUniqueList.length > 0) {
          this.dentistsUniqueList.map((item) => {
            if (item && (item['MerchantContact_key'] || item['MerchantContact_key'] == '')) {
              this.selectedDentist.push(item['MerchantContact_key']);
            }
          });
        }
      } else if (type == 'status') {
        if (this.statusUniqueList && this.statusUniqueList.length > 0) {
          this.statusUniqueList.map((item) => {
            if (item && (item['Code'] || item['Code'] == '')) {
              this.selectedStatus.push(item['Code']);
            }
          });
        }
      } else {
        this.dentistsUniqueList = _.uniqBy(this.appointments.concat([]), 'MerchantContact_key');

        if (this.dentistsUniqueList && this.dentistsUniqueList.length > 0) {
          this.dentistsUniqueList.map((item) => {
            if (item && (item['MerchantContact_key'] || item['MerchantContact_key'] == '')) {
              this.selectedDentist.push(item['MerchantContact_key']);
            }
          });
        }

        if (this.statusUniqueList && this.statusUniqueList.length > 0) {
          this.statusUniqueList.map((item) => {
            if (item && (item['Code'] || item['Code'] == '')) {
              this.selectedStatus.push(item['Code']);
            }
          });
        }
      }
    }
  }

  eventSelected(id) {
    this.openQuickViewDialog(id);
  }

  openQuickViewDialog(key) {
    const ref = RootAppComponent.dialog.open(AppointmentViewComponent, {
      data: {
        appointmentID: key,
        closeOnProposeBooking: true,
      },
      width: '900px',
      height: '90%',
      panelClass: 'noCard',
    });

    ref.componentInstance.proposeBooking.subscribe((res) => {
      if (res) {
        ref.close();
        ref.afterClosed().subscribe((r) => {
          this.runAction.emit(res);
        });
      }
    });

    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });

    ref.componentInstance.updateAppointment.subscribe((res) => {
      if (res && res.ID) {
        let title = `${res.CustomerOrProspect_CalculatedName} - ${res['Type.Label']}`;
        if (res['SubType.Label']) {
          title += `: ${res['SubType.Label']}`;
        }

        if (res['MerchantContact_CalculatedName']) {
          title += `, Assigned to ${res['MerchantContact_CalculatedName']} `;
        }

        if (res['Date'] && res['Date'] != '0000-00-00' && (!res['Date_End'] || res['Date_End'] == '0000-00-00')) {
          const time = this.customDate.transform(res['Date'] + ' ' + res['Time'], 'hh:mm A');
          title += `, At ${time} `;
        }

        if (res['Date'] && res['Date_End'] && res['Date'] != '0000-00-00' && res['Date_End'] != '0000-00-00') {
          const time = this.customDate.transform(res['Date'] + ' ' + res['Time'], 'hh:mm A');
          const time2 = this.customDate.transform(res['Date_End'] + ' ' + res['Time_End'], 'hh:mm A');
          title += ` From ${time} To ${time2}`;
        }

        const event: CalendarEventType = {
          id: res.ID,
          start: moment(`${res.Date} ${res.Time}`).toDate(),
          title,
          color: this.getEventColor(res),
          colorType: res['Status.Label'],
        };

        this.editCalendar.emit(event);
      }
    });

    ref.componentInstance.deleteAppointment.subscribe((res) => {
      if (res) {
        this.removeCalendar.emit(res);
      }
    });
  }

  getActionResult(res) {
    if (res && res.ID) {
      let title = `${res.CustomerOrProspect_CalculatedName} - ${res['Type.Label']}`;
      if (res['SubType.Label']) {
        title += `: ${res['SubType.Label']}`;
      }

      if (res['MerchantContact_CalculatedName']) {
        title += `, Assigned to ${res['MerchantContact_CalculatedName']} `;
      }

      if (res['Date'] && res['Date'] != '0000-00-00' && (!res['Date_End'] || res['Date_End'] == '0000-00-00')) {
        const time = this.customDate.transform(res['Date'] + ' ' + res['Time'], 'hh:mm A');
        title += `, At ${time} `;
      }

      if (res['Date'] && res['Date_End'] && res['Date'] != '0000-00-00' && res['Date_End'] != '0000-00-00') {
        const time = this.customDate.transform(res['Date'] + ' ' + res['Time'], 'hh:mm A');
        const time2 = this.customDate.transform(res['Date_End'] + ' ' + res['Time_End'], 'hh:mm A');
        title += ` From ${time} To ${time2}`;
      }

      const event: CalendarEventType = {
        id: res.ID,
        start: moment(`${res.Date} ${res.Time}`).toDate(),
        title,
        color: this.getEventColor(res),
        colorType: res['Status.Label'],
      };

      this.editCalendar.emit(event);
    }
  }

  openMerchantViewDialog(key) {
    const ref = RootAppComponent.dialog.open(MerchantViewModalComponent, {
      data: key,
      width: '550px',
    });

    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  openDentistViewDialog(key) {
    const ref = RootAppComponent.dialog.open(DentistViewModalComponent, {
      data: key,
      width: '550px',
    });

    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  gotoCustomerPage(key) {
    this.router.navigate([
      '/merchant',
      {
        outlets: {
          page: ['customer-profile', key, 'patient'],
        },
      },
    ]);
  }

  ngOnDestroy() {
    this.destroyEvent.emit(true);
    if (this.serviceRef) {
      this.serviceRef.unsubscribe();
    }
  }

  isChecked(array, id) {
    if (array && array.length > 0) {
      if (array.indexOf(id) != -1) {
        return true;
      }
    }
    return false;
  }

  changeCheckedStatus(e, id) {
    if (e) {
      const c = e.checked;

      if (c === true) {
        const v = this.isChecked(this.selectedStatus, id);
        if (v == false) {
          this.selectedStatus.push(id);
        }
      } else {
        const v = this.isChecked(this.selectedStatus, id);
        if (v == true) {
          const i = this.selectedStatus.indexOf(id);

          if (i != -1) {
            this.selectedStatus.splice(i, 1);
          }
        }
      }
    }
  }

  changeCheckedDentist(e, id) {
    if (e) {
      const c = e.checked;

      if (c === true) {
        const v = this.isChecked(this.selectedDentist, id);
        if (v == false) {
          this.selectedDentist.push(id);
        }
      } else {
        const v = this.isChecked(this.selectedDentist, id);
        if (v == true) {
          const i = this.selectedDentist.indexOf(id);

          if (i != -1) {
            this.selectedDentist.splice(i, 1);
          }
        }
      }
    }
  }

  clearFiltersDentist() {
    this.selectedDentist = [];
  }

  clearFiltersStatus() {
    this.selectedStatus = [];
  }

  changeGlobalStatus() {
    if (this.statusGlobal == true) {
      this.clearFiltersStatus();
      this.statusGlobal = false;
    } else {
      this.checkAll('status');
      this.statusGlobal = true;
    }
  }

  changeGlobalDentist() {
    if (this.dentistGlobal == true) {
      this.clearFiltersDentist();
      this.dentistGlobal = false;
    } else {
      this.checkAll('dentist');
      this.dentistGlobal = true;
    }
  }

  filterResult() {
    const data = this.appointments.concat([]).filter((item) => {
      if (item) {
        if (this.selectedDentist.length > 0 && this.selectedStatus.length > 0) {
          if (
            this.selectedDentist.indexOf(item['MerchantContact_key']) != -1 &&
            this.selectedStatus.indexOf(item['Status.Code']) != -1
          ) {
            return true;
          } else {
            return false;
          }
        } else if (this.selectedDentist.length > 0 && this.selectedDentist.indexOf(item['MerchantContact_key']) != -1) {
          return true;
        } else if (this.selectedStatus.length > 0 && this.selectedStatus.indexOf(item['Status.Code']) != -1) {
          return true;
        }
        return false;
      }
      return false;
    });

    this.buildAppointmentEvents(data);
  }
}
