import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TipCreateComponent } from '../tip-create/tip-create.component';
import { TipEditComponent } from '../tip-edit/tip-edit.component';
import { TipListComponent } from '../tip-list/tip-list.component';
import { TipOverviewComponent } from '../tip-overview/tip-overview.component';
import { TipViewComponent } from '../tip-view/tip-view.component';

const routes: Routes = [
  {
    path: 'tip-list',
    component: TipListComponent,
  },
  {
    path: 'tip-create',
    component: TipCreateComponent,
  },

  {
    path: 'TipOverviewComponent',
    component: TipOverviewComponent,
  },
  {
    path: 'TipViewComponent',
    component: TipViewComponent,
  },

  {
    path: 'TipEditComponent',
    component: TipEditComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TipRoutingModule {}
