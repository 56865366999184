import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import * as moment from 'moment';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { MarketingService } from '../shared/marketing.service';

@Component({
  selector: 'app-marketing-campagin-edit-modal',
  templateUrl: './marketing-campagin-edit-modal.component.html',
  styleUrls: ['./marketing-campagin-edit-modal.component.css'],
})
export class MarketingCampaginEditModalComponent implements OnInit {
  @Input()
  campaignID;

  @Input()
  campaignName;

  @Input()
  actionCallLabel;

  @Input()
  actionCallDescription;

  @Input()
  actionCallLabelDefault;

  @Input()
  actionCallDescriptionDefault;

  @Input()
  isPublicCampaign = true;

  hasExpirationDate = false;
  @Output()
  getResult = new EventEmitter();

  isModal = false;

  close = new EventEmitter();

  whenToExpired = moment().add(1, 'months').toDate();
  expirationDate = moment().add(1, 'months').toDate();
  sessionType;

  isPromoterOrAdmin = false;

  constructor(
    private marketingService: MarketingService,
    private authenticationService: AuthenticationService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.campaignID != null) {
        this.campaignID = data.campaignID;
        this.isModal = true;
      }
    }
  }

  ngOnInit() {
    this.authenticationService.isPromoterOrAdmin().subscribe((isPromoterOrAdmin) => {
      this.isPromoterOrAdmin = isPromoterOrAdmin;
    });
    this.authenticationService.getSessionType().subscribe((res) => {
      this.sessionType = res;

      this.setup();
    });
  }

  setup() {
    if (this.campaignID) {
      const p = {
        fields:
          'ID,ActionCall.Label,ActionCall.Description,' +
          'CampaignStatus,WhenToExpire,Label,Description,Template.ActionCall_Label,Template.ActionCall_Description',
      };
      this.marketingService.getMarketingDetails(this.campaignID, p, this.sessionType).subscribe((res) => {
        if (res && res.ID) {
          this.campaignID = res.ID;
          if (res['Label']) {
            this.campaignName = res['Label'];
          }
          if (res['ActionCall.Label']) {
            this.actionCallLabel = res['ActionCall.Label'];
          } else if (res['Template.ActionCall_Label']) {
            this.actionCallLabel = res['Template.ActionCall_Label'];
          } else {
            this.actionCallLabel = res['Label'];
          }

          if (res['ActionCall.Description']) {
            this.actionCallDescription = res['ActionCall.Description'];
          } else if (res['Template.ActionCall_Description']) {
            this.actionCallDescription = res['Template.ActionCall_Description'];
          } else {
            this.actionCallDescription = res['Description'];
          }

          if (res['Template.ActionCall_Label']) {
            this.actionCallLabelDefault = res['Template.ActionCall_Label'];
          }
          if (res['Template.ActionCall_Description']) {
            this.actionCallDescriptionDefault = res['Template.ActionCall_Description'];
          }

          if (res['Is_Public']) {
            this.isPublicCampaign = res['Is_Public'];
          }

          if (res['WhenToExpire']) {
            this.whenToExpired = moment(res.WhenToExpire, 'YYYY-MM-DD hh:mm:ss').toDate();
            this.expirationDate = moment(res.WhenToExpire, 'YYYY-MM-DD hh:mm:ss').toDate();
            this.hasExpirationDate = true;
          } else {
            this.hasExpirationDate = false;
          }
        }
      });
    }
  }

  selectedExpirationDate(e) {
    this.expirationDate = e;
  }

  create() {
    if (this.campaignID) {
      const p: any = {
        label: this.campaignName,
        actionCallLabel: this.actionCallLabel,
        actionCallDescription: this.actionCallDescription,
        isPublicCampaign: this.isPublicCampaign,
      };

      if (this.hasExpirationDate) {
        p.expirationDate = this.expirationDate;
      }

      this.marketingService.editMarketing(this.campaignID, p, this.isPromoterOrAdmin).subscribe((res) => {
        if (res) {
          NotifyAppComponent.displayToast('success', 'Successful Operation', 'Campaign has been updated.');
          this.getResult.emit(res);
        }
      });
    }
  }

  closeEvent() {
    this.close.emit(true);
  }

  resetLabel() {
    this.actionCallLabel = this.actionCallLabelDefault;
  }

  resetDescription() {
    this.actionCallDescription = this.actionCallDescriptionDefault;
  }
}
