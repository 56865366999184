<div class="rel" *ngIf="listDB.firstLoadEvent == false" @ngIfAnimation>
  <div class="row clearfix padded rel">
    <h1 class="dataHeader rm-mt transparent">Fetching data...</h1>
  </div>
</div>

<div [hidden]="listDB.data.length == 0">
  <p class="pull-right" *ngIf="dateFrom != null || dateTo != null">
    <span *ngIf="dateFrom"
    >From:
      <strong>{{ dateFrom | customDateTime }} </strong>
    </span>

    <span *ngIf="dateTo">
      To:
      <strong>{{ dateTo | customDateTime }}</strong>
    </span>
  </p>
  <div>
    <h1 class="dataHeader rm-m">
      <!-- Contracts -->
      {{ 'KEYWORD.practice' | translate }} Prospects
      <span class="badge" [endVal]="dataSource.filteredData.length" countUp></span>
    </h1>
    <mat-card class="list-card">
      <!-- Search -->
      <div class="row clearfix searchArea primary-gradient-img">
        <div class="row justify flex">
          <div class="full-width row clearfix flex">
            <mat-icon class="schIcon mt">search</mat-icon>

            <mat-form-field class="full-width input-adjust" appearance="outline">
              <mat-label>Trading as</mat-label>
              <input
                class="clearfix"
                (input)="setFilter($event.target.value, 'TradingAs')"
                name="FirstName"
                placeholder=""
                matInput
              />
            </mat-form-field>

            <mat-form-field class="full-width input-adjust" appearance="outline">
              <mat-label>Phone</mat-label>
              <input
                class="clearfix"
                (input)="setFilter(convertPhone($event.target.value), 'phones.Number')"
                name="Phone"
                placeholder="Phone"
                matInput
              />
            </mat-form-field>

            <mat-form-field class="full-width input-adjust" appearance="outline">
              <mat-label>State</mat-label>
              <input
                class="clearfix"
                (input)="setFilter($event.target.value, 'addresses.State')"
                name="State"
                placeholder="State"
                matInput
              />
            </mat-form-field>

            <mat-form-field class="full-width input-adjust" appearance="outline">
              <mat-label>Postcode</mat-label>
              <input
                class="clearfix"
                (input)="setFilter($event.target.value, 'addresses.Postcode')"
                name="Postcode"
                placeholder="Postcode"
                matInput
              />
            </mat-form-field>

            <!-- <mat-form-field class="full-width mat-select-adjust" appearance="outline">
              <mat-label>Status</mat-label>
              <mat-select name="merchantName" class="full-width" placeholder="" (selectionChange)="setFilter($event.value ,'Status.Label')">
                <mat-option *ngFor="let a of  listDB.data  | objectPropertyValue : 'Status.Label' | unique | without: ['',null] | orderBy "
                  [value]="a">
                  {{a}}
                </mat-option>
              </mat-select>
            </mat-form-field> -->
          </div>
        </div>

        <!-- <div class="row clearfix">
          <div class="half-width" id="search">
            <mat-icon class="schIcon">search</mat-icon>
            <mat-select class="schSel" matInput placeholder="Add Filters" [formControl]="searchVals" multiple>

              <mat-select-trigger>
                {{searchVals.value ? searchVals.value[0] : ''}}
                <span *ngIf="searchVals.value?.length > 1" class="additional-selection">
                  (+{{searchVals.value.length - 1}} others)
                </span>
              </mat-select-trigger>
              <mat-option *ngFor="let searchVal of searchValList" [value]="searchVal">{{searchVal}}</mat-option>
            </mat-select>

          </div>

        </div> -->
        <div class="row clearfix flex well miniWell animated fadeInDown" *ngIf="searchVals.value?.length > 0"></div>
      </div>

      <!-- Table -->
      <mat-table class="list-table" #table [dataSource]="dataSource" matSort>
        <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->
        <!-- ID Column -->

        <ng-container cdkColumnDef="TradingAS">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Trading as</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="openQuickViewDialog(row['ID'])" matTooltip="{{ row['TradingAs'] }}">
            {{ row['TradingAs'] }}
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Phone">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Phone</mat-header-cell>
          <mat-cell
            *cdkCellDef="let row"
            (click)="openQuickViewDialog(row['ID'])"
            matTooltip="{{ row['phones.Number'] | customPhone: 'landLine' }}"
          >
            {{ row['phones.Number'] | customPhone: 'landLine' }}
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Email">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Email</mat-header-cell>
          <mat-cell
            *cdkCellDef="let row"
            (click)="openQuickViewDialog(row['ID'])"
            matTooltip="{{ row['emails.Email'] }}"
          >
            {{ row['emails.Email'] }}
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Address">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Address</mat-header-cell>
          <mat-cell
            *cdkCellDef="let row"
            (click)="openQuickViewDialog(row['ID'])"
            matTooltip="{{ row['addresses.Calculated'] }}"
          >
            {{ row['addresses.Unit'] }} {{ row['addresses.Street Nr'] }} {{ row['addresses.Street Name'] }}
            {{ row['addresses.Street Type'] }}
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="State">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> State</mat-header-cell>
          <mat-cell
            *cdkCellDef="let row"
            (click)="openQuickViewDialog(row['ID'])"
            matTooltip="{{ row['addresses.State'] }}"
          >
            {{ row['addresses.State'] }}
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Postcode">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Postcode</mat-header-cell>
          <mat-cell
            *cdkCellDef="let row"
            (click)="openQuickViewDialog(row['ID'])"
            matTooltip="{{ row['addresses.Postcode'] }}"
          >
            {{ row['addresses.Postcode'] }}
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Status">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Status</mat-header-cell>
          <mat-cell
            *cdkCellDef="let row"
            (click)="openQuickViewDialog(row['ID'])"
            matTooltip="{{ row['Status.Label'] }}"
          >
            {{ row['Status.Label'] }}
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="CreatedDate">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Join Date</mat-header-cell>
          <mat-cell
            *cdkCellDef="let row"
            (click)="openQuickViewDialog(row['ID'])"
            matTooltip="{{ row['DateTimeCreated'] | customDate }}"
          >
            {{ row['DateTimeCreated'] | customDate }}
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Actions">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Actions</mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <div style="float: right">
              <button [matMenuTriggerFor]="menu" mat-icon-button>
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button *ngIf="row['ID']" (click)="openQuickViewDialog(row['ID'])" mat-menu-item>
                  <mat-icon>description</mat-icon>
                  <span>Profile</span>
                </button>

                <button *ngIf="row['ID']" (click)="contact(row['ID'])" mat-menu-item>
                  <mat-icon>message</mat-icon>
                  <span>Contact merchant</span>
                </button>

                <button *ngIf="row['ID']" (click)="uploadDoc(row['ID'])" mat-menu-item>
                  <mat-icon class="fa fa-file-upload"></mat-icon>
                  <span>{{ '' | translate }}</span>
                </button>
              </mat-menu>
            </div>
          </mat-cell>
        </ng-container>
        <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *cdkRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
      <mat-paginator
        #paginator
        [length]="dataSource.filteredData.length"
        [pageIndex]="0"
        [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions"
      >
      </mat-paginator>
    </mat-card>
  </div>
</div>
<div *ngIf="listDB.data.length == 0 && listDB.firstLoadEvent != false">
  <app-empty-list-message></app-empty-list-message>
</div>
