import { Pipe, PipeTransform } from '@angular/core';
import { uniqBy } from 'lodash';

@Pipe({
  name: 'uniqueBy',
})
export class UniqueByPipe implements PipeTransform {
  constructor() {}

  transform(targetObjects: { [key: string]: any }[], property: string) {
    try {
      const result = uniqBy(targetObjects, property);

      return result;
    } catch (e) {
      return targetObjects;
    }
  }
}
