import { Component, EventEmitter, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { UtilsClass } from '../../../shared/types/utils/utils.class';

@Component({
  selector: 'discount-info-modal',
  templateUrl: './patient-card-discount-info-modal.component.html',
  styleUrls: ['./patient-card-discount-info-modal.component.css'],
})
export class DiscountInfoModalComponent implements OnInit {
  closeModal = new EventEmitter();

  closeProceeding = new EventEmitter();

  utils = new UtilsClass();

  @Input()
  customInvDate;

  @Input()
  treatmentAmount: number;

  @Input()
  discountAmount: number;

  cashDiscountDateCounter;

  interval;

  expireTill;

  displayDiscount = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    if (data) {
      if (data.customInvDate) {
        this.customInvDate = data.customInvDate;
      }

      if (data.treatmentAmount) {
        this.treatmentAmount = Number(data.treatmentAmount);
      }

      if (data.discountAmount) {
        this.discountAmount = Number(data.discountAmount);
      }
    }
  }

  ngOnInit() {
    // console.log(this.customInvDate);

    if (this.customInvDate && this.customInvDate != '0000-00-00') {
      this.displayDiscount = moment().isBefore(moment(this.customInvDate, 'YYYY-MM-DD'));

      const _now = new Date().getTime();

      const target = moment(this.customInvDate, 'YYYY-MM-DD').toDate().getTime();

      this.expireTill = target - _now;

      this.countDown();
    }
  }

  countDown() {
    this.interval = setInterval(() => {
      this.expireTill = this.expireTill - 1000;

      if (this.expireTill > 0) {
        this.cashDiscountDateCounter = this.utils.convertDateObject(this.expireTill / 1000);
      } else {
        this.displayDiscount = false;
        this.cashDiscountDateCounter = null;
        clearInterval(this.interval);
      }
    }, 1000);
  }

  close() {
    this.closeModal.emit(true);
  }

  closeProceed() {
    this.closeProceeding.emit(true);
  }
}
