<div class="rel" *ngIf="listDB.firstLoadEvent == false">
  <div class="row clearfix padded rel">
    <h1 class="dataHeader rm-mt transparent">Fetching data...</h1>
  </div>
</div>
<div [hidden]="listDB.data.length == 0">
  <div class="row clearfix">
    <h1 class="dataHeader rm-m pull-left">
      {{ title }}
      <span class="badge" [endVal]="dataSource.filteredData.length" countUp></span>
    </h1>

    <div class="pull-right rel buttonrow">
      <button class="clearfix pull-right" (click)="createMessage()" mat-raised-button color="accent">
        <mat-icon>add</mat-icon>
        {{ createMessageButtonLabel }}
      </button>
    </div>

    <div class="pull-right selection-items" @ngIfAnimation>
      <mat-button-toggle-group class="btn-group rm-m selectType" [(ngModel)]="typeFilter"
                               (ngModelChange)="setFilter(typeFilter, 'IsPromoterTemplate')">
        <mat-button-toggle class="" [value]="null"> All</mat-button-toggle>

        <mat-button-toggle class="" [value]="'0'"> {{"KEYWORD.Practice" | translate}}</mat-button-toggle>

        <mat-button-toggle class="" [value]="'1'">
          {{ "BRANDING.Brand Name" | translate | titlecase }}
        </mat-button-toggle>



      </mat-button-toggle-group>
    </div>
    <button mat-icon-button class="sml pull-right   mr " matTooltip="Open all contents " (click)="collapse( false)">
      <mat-icon class="fas fa-window-maximize"></mat-icon>
    </button>

    <button mat-icon-button class="sml  pull-right  " matTooltip="Close all  contents" (click)="collapse(true)">
      <mat-icon class="fas fa-window-minimize"></mat-icon>
    </button>
  </div>

  <div class="row clearfix">
    <mat-card class="list-card list-card-template-container">
      <!-- Search -->
      <div class="row clearfix mb">
        <div class="row clearfix searchArea primary-gradient-img">
          <div class="row clearfix flex">
            <div class="full-width flex animated fadeInDown">
              <mat-icon class="mt mr">search</mat-icon>


              <mat-form-field class=" full-width  margin-btm" appearance="outline">
                <mat-label>Search...</mat-label>
                <input class="clearfix" (input)="setFilter($event.target.value, 'fullName')" name="Label" placeholder="Search..."
                       matInput/>
              </mat-form-field>

              <mat-form-field class=" half-width margin-btm" appearance="outline">
                <mat-label>Product Group</mat-label>
                <mat-select class=" " (selectionChange)="setFilter($event.value, 'Group.Code')" name="Product"
                            placeholder="Product Group">
                  <mat-option [value]="null">
                    All
                  </mat-option>
                  <mat-option *ngFor="
                      let p of productGroups
                    " [value]="p.code">
                    {{ p['value'] }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field class="  half-width margin-btm" appearance="outline">
                <mat-label>Message Purpose</mat-label>
                <mat-select class="" (selectionChange)="setFilter($event.value, 'Purpose.Code')" name="Product"
                            placeholder="Message Purpose">
                  <mat-option [value]="null">
                    All
                  </mat-option>
                  <mat-option *ngFor="
                      let p of purposeGroups
                    " [value]="p.code">
                    {{ defaultMessageUtilClass.getPurposeLabel(p['value']) | translate }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field class="white half-width" appearance="outline" *ngIf="merchantTags.length > 0">
                <mat-label>Tags</mat-label>
                <mat-select name="Tag" class="" placeholder="Tag" (selectionChange)="setFilter($event.value, 'tags')"
                  multiple>
                  <mat-option *ngFor="let a of merchantTags       | orderBy" [value]="a">
                    {{a}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-slide-toggle class="  half-widthpull-right" [checked]="selectedFavouriteValue"
                (change)="toggleFavourite($event)">
                <span *ngIf="selectedFavouriteValue==true">Show All</span>
                <span *ngIf="selectedFavouriteValue!=true">Show Active Only</span>
              </mat-slide-toggle>





            </div>
          </div>
        </div>
      </div>
      <!-- Table -->
      <mat-table class="list-table" #table [dataSource]="dataSource" matSort>
        <!--- Note that these columns can be defined in any order.
                      The actual rendered columns are set as a property on the row definition" -->
        <ng-container cdkColumnDef="PurposeCode" sticky>
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Message Purpose</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="viewMessage(row['ID'])"
                    matTooltip="{{ defaultMessageUtilClass.getPurposeLabel(row['Purpose.Label']) }}">
            {{ defaultMessageUtilClass.getPurposeLabel(row['Purpose.Label']) }}</mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="ProductGroupCode" sticky>
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Product Group</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="viewMessage(row['ID'])">
            {{ row['Group.Code'] }}</mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Label" sticky>
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> {{"Title" | translate}} </mat-header-cell>
          <mat-cell (click)="viewMessage(row['ID'])" *cdkCellDef="let row" matTooltip="{{row['Label']}}">
            <p style="    white-space: pre-line;"> <strong> {{row['Label']}} </strong> </p>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Content">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> {{"Content" | translate}} </mat-header-cell>
          <mat-cell *cdkCellDef="let row" mdTooltipClass="msgContent">

            <div class="full-width flex" style="position: relative;">
              <message-input *ngIf="loadContact==true &&  loadMerchant==true &&  loadPromoter==true && row['Content']"
                class="full-width  message-input-component-template2 mr ml" [isModal]="false" [displayMore]="true"
                [maxLength]="0" [isCollapsed]="isCollapsed" [message]="row['Content'] " [showAllCustomization]="true"
                [card]="card" [promoter]="promoter" [merchant]="merchant" [contact]="contact" [textLength]="0"
                [displayEmoji]="false" [displayMessageTemplate]="false" [displayPatient]="false"
                [displayContact]="false" [displayMerchant]="false" [displayPromoter]="false" [displayMarketing]="false"
                [displayExpend]="false" [displayDentalOffers]="false" [displayDocument]="false" [saveAsTemplate]="false"
                [isSMSPreview]="true" [isDesktopPreview]="true" [displaySaveAsTemplate]="false" [encode]="false"
                [isView]="true" [isMessageConverted]="true">
              </message-input>
            </div>

          </mat-cell>
        </ng-container>
        <ng-container cdkColumnDef="tags">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> {{"Tags" | translate}} </mat-header-cell>


          <mat-cell *cdkCellDef="let row">
            <mat-chip-list class="template-tag-lookup2">
              <mat-chip *ngFor="let tag of row['tags']">{{tag}}
              </mat-chip>
            </mat-chip-list>
          </mat-cell>



        </ng-container>

        <ng-container cdkColumnDef="Purpose">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Purpose</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="viewMessage(row['ID'])" matTooltip="{{ row['Purpose.Label'] }}"
                    mdTooltipClass="msgContent">
            {{ row['Purpose.Label'] }}
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Favourite">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Active</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="viewMessage(row['ID'])">
            <!-- <span *ngIf="row['Favourite']=='1'"><mat-icon [ngStyle]="{'color':'red'}">favorite</mat-icon></span>
            <span *ngIf="row['Favourite']!='1'"><mat-icon [ngStyle]="{'color':'red'}">favorite_border</mat-icon></span> -->

            <span class="list-label smaller" *ngIf="row['Favourite'] == '1'" style="background-color: rgb(90, 186, 71)">
              <mat-icon>check</mat-icon>
            </span>
            <span class="list-label smaller" *ngIf="row['Favourite'] != '1'" style="background-color: rgb(239, 71, 39)">
              <mat-icon>close</mat-icon>
            </span>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="type">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Template</mat-header-cell>
          <mat-cell (click)="viewMessage(row['ID'])" *cdkCellDef="let row">
            <span *ngIf="row['IsPromoterTemplate']=='1' && row['Purpose.Code']!=='PromSMS'" class="chip">
              {{"BRANDING.Brand Name" | translate}}
            </span>
            <span *ngIf="row['IsPromoterTemplate']==='0'  && row['Purpose.Code']!=='PromSMS'" class="chip chip-enable">
              {{'Merchant' | translate}}
            </span>
            <span class="chip" *ngIf="row['Purpose.Code'] === 'PromSMS'"> Promoter </span>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="CreatedDate">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Create Date</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="viewMessage(row['ID'])"
                    matTooltip="{{ row['DateTimeCreated'] | customDateTime }}">
            <span>{{ row['DateTimeCreated'] | TimeLabelPast }} ago</span>
          </mat-cell>
        </ng-container>
        <ng-container cdkColumnDef="Actions">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Actions</mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <div style="float: right">
              <button [matMenuTriggerFor]="menu" mat-icon-button>
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button *ngIf="row['ID']" (click)="viewMessage(row['ID'])" mat-menu-item>
                  <mat-icon>description</mat-icon>
                  <span>View</span>
                </button>
                <hr/>
                <button *ngIf="
                    row['ID'] &&
                    (row['IsPromoterTemplate'] !== '1' ||
                      (row['IsPromoterTemplate'] === '1' && isPromoterOrAdmin == true))
                  " (click)="editMessage(row)" mat-menu-item>
                  <mat-icon>edit</mat-icon>
                  <span>Edit</span>
                </button>

                <button *ngIf="row['ID']" (click)="clone(row)" mat-menu-item>
                  <mat-icon>content_copy</mat-icon>
                  <span>Clone</span>
                </button>

                <hr/>

                <button *ngIf="
                    row['ID'] &&
                    row['Favourite'] != '1' &&
                    (row['IsPromoterTemplate'] !== '1' ||
                      (row['IsPromoterTemplate'] === '1' && isPromoterOrAdmin == true))
                  " (click)="setFavourite(row['ID'])" mat-menu-item>
                  <mat-icon>check_circle</mat-icon>
                  <span>Enable</span>
                </button>
                <button *ngIf="
                    row['ID'] &&
                    row['Favourite'] == '1' &&
                    (row['IsPromoterTemplate'] !== '1' ||
                      (row['IsPromoterTemplate'] === '1' && isPromoterOrAdmin == true))
                  " (click)="cancelFavourite(row['ID'])" mat-menu-item>
                  <mat-icon>remove_circle</mat-icon>
                  <span>Disable</span>
                </button>

                <hr/>
                <button *ngIf="
                    row['ID'] &&
                    (row['IsPromoterTemplate'] !== '1' ||
                      (row['IsPromoterTemplate'] === '1' && isPromoterOrAdmin == true))
                  " (click)="deleteMessage(row.ID)" mat-menu-item>
                  <mat-icon>remove</mat-icon>
                  <span>Remove</span>
                </button>
              </mat-menu>
            </div>
          </mat-cell>
        </ng-container>
        <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *cdkRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
      <mat-paginator #paginator [length]="dataSource.filteredData.length" [pageIndex]="0" [pageSize]="pageSize"
                     [pageSizeOptions]="pageSizeOptions">
      </mat-paginator>
    </mat-card>
  </div>
</div>
<div class="clearfix full-width" *ngIf="listDB.data.length == 0 && listDB.firstLoadEvent != false">
  <app-empty-list-message [actionIcon]="'add'" [isAction]="true" [actionLabel]="'New Personal Message'"
                          (actionEvent)="createMessage()"></app-empty-list-message>
</div>
