/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./marketing-campaign-create-edit-overview.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../marketing-campaign-create-edit/marketing-campaign-create-edit.component.ngfactory";
import * as i3 from "../marketing-campaign-create-edit/marketing-campaign-create-edit.component";
import * as i4 from "@angular/router";
import * as i5 from "../../merchant/shared/merchant.service";
import * as i6 from "../../../core/authentication/shared/authentication.service";
import * as i7 from "../shared/marketing.service";
import * as i8 from "../../../shared/services/utils.service";
import * as i9 from "../../dentist/shared/dentist.service";
import * as i10 from "../../tags/shared/tags.service";
import * as i11 from "@angular/material/dialog";
import * as i12 from "@angular/common";
import * as i13 from "./marketing-campaign-create-edit-overview.component";
var styles_MarketingCampaignCreateEditOverviewComponent = [i0.styles];
var RenderType_MarketingCampaignCreateEditOverviewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MarketingCampaignCreateEditOverviewComponent, data: {} });
export { RenderType_MarketingCampaignCreateEditOverviewComponent as RenderType_MarketingCampaignCreateEditOverviewComponent };
function View_MarketingCampaignCreateEditOverviewComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "row clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-marketing-campaign-create-edit", [], null, null, null, i2.View_MarketingCampaignCreateEditComponent_0, i2.RenderType_MarketingCampaignCreateEditComponent)), i1.ɵdid(2, 114688, null, 0, i3.MarketingCampaignCreateEditComponent, [i4.Router, i5.MerchantService, i6.AuthenticationService, i7.MarketingService, i8.UtilsService, i9.DentistService, i4.ActivatedRoute, i10.TagsService, [2, i11.MAT_DIALOG_DATA]], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_MarketingCampaignCreateEditOverviewComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_MarketingCampaignCreateEditOverviewComponent_1)), i1.ɵdid(1, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isPromoterOrAdmin == false); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_MarketingCampaignCreateEditOverviewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-marketing-campaign-create-edit-overview", [], null, null, null, View_MarketingCampaignCreateEditOverviewComponent_0, RenderType_MarketingCampaignCreateEditOverviewComponent)), i1.ɵdid(1, 114688, null, 0, i13.MarketingCampaignCreateEditOverviewComponent, [i6.AuthenticationService, i4.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MarketingCampaignCreateEditOverviewComponentNgFactory = i1.ɵccf("app-marketing-campaign-create-edit-overview", i13.MarketingCampaignCreateEditOverviewComponent, View_MarketingCampaignCreateEditOverviewComponent_Host_0, {}, {}, []);
export { MarketingCampaignCreateEditOverviewComponentNgFactory as MarketingCampaignCreateEditOverviewComponentNgFactory };
