<div class="row clearfix" *ngIf="isLoad==true">
  <div class="row clearfix messages" *ngIf="messages && messages.length > 0">
    <div class="card-header primary-gradient-img clearfix stacked" *ngIf="isModal == true" style="margin-top: -24px">
      <div class="row clearfix">
        <button class="btn-close btn-clear mat-button" (click)="closeEvent()">
          <mat-icon>clear</mat-icon>
        </button>
        <h2 class="sm-h2 pull-left rm-mt rm-mb">
          Messages
          <span class="badge">
            <span [endVal]="messages.length" countUp></span>
          </span>
        </h2>
      </div>
    </div>

    <div *ngIf="isModal == false">
      <div class="colour-stripe"></div>
      <div class="row clearfix">
        <button class="pull-right" [matMenuTriggerFor]="menu" mat-raised-button color="accent">
          Create message
          <mat-icon>more_vert</mat-icon>
        </button>

        <mat-menu #menu="matMenu">
          <button (click)="contactByEmail()" mat-menu-item>Create Email message</button>

          <button *ngIf="patientID && merchantID && isModulePatientSMSActive == true"
            (click)="sendSMS(patientID, merchantID)" mat-menu-item>
            Send SMS
          </button>

          <button *ngIf="sessionType && (sessionType == 'admin' || sessionType == 'promoter')" (click)="contactBySMS()"
            mat-menu-item>
            Create SMS message
          </button>

          <button *ngIf="patientID" (click)="createNote(patientID)" mat-menu-item>
            <mat-icon>note_add</mat-icon>
            <span>Create Note</span>
          </button>

          <button *ngIf="patientID" (click)="viewNotes(patientID)" mat-menu-item>
            <mat-icon class="fa fa-list-alt"></mat-icon>
            <span>View notes</span>
          </button>
        </mat-menu>

        <h1 class="sm-h1 sr-title rm-mb rm-mt">
          Messages
          <span class="badge">
            <span [endVal]="messages.length" countUp></span>
          </span>
        </h1>
      </div>
    </div>

    <mat-dialog-content class="indOvf smt">
      <vertical-timeline [@stagger]="messages.length">
        <vertical-timeline-card class="event-c-holder" *ngFor="let m of messages" [@fade]
          [dateValue]="m['DateTimeCreated'] | convertDate"
          [timeString]="m['DateTimeCreated'] | customDateTime: 'HH:mm'">


          <div class="card-header  header-lookup-note-message row ">
            <div class="row clearfix flex flex-center mb">
              <div class="full-width">

                <button class="btn-small view-c-event pull-right " *ngIf="m['Type'] == 'E' || m['Type'] == 'S'"
                  (click)="viewContent(m['ID'], m['Type'])" mat-icon-button color="primary"
                  matTooltip="View Message Content">
                  <mat-icon class="fas fa-search smr primary-color"></mat-icon>
                </button>

                <button class="btn-small  view-c-event pull-right " *ngIf="m['Type'] == 'Note Message'"
                  (click)="viewNote(m['ID'])" mat-icon-button color="primary" matTooltip="View Note Content">
                  <mat-icon class="fas fa-search primary-color smr"></mat-icon>
                </button>


                <h4 class="summary-header rm-m primary-color smt smb">

                  <span class="ml">{{ m['DateTimeCreated']  | customDateTime: 'DD MMMM YYYY' }}</span>

                </h4>
              </div>
            </div>


          </div>



          <div class="full-width row  clearfix" *ngIf="m['Description']">
            <p [innerHTML]="m['Description'] | safeHtml" class="rm-mt"
              style="white-space: pre-wrap;margin-top: -10px !important;"></p>
          </div>
          <div class="full-width row  clearfix" *ngIf="!m['Description']">
            <p [innerHTML]="'...'" class="rm-mt" style="white-space: pre-wrap;margin-top: -10px !important;"></p>
          </div>




          <hr>

          <div class=" row  clearfix">


            <span class="badge pull-left  accent-background" style=" font-size: 0.8rem;margin: 0px;margin-top: 3px;"
              *ngIf="m['Type'] != 'E' && m['Type'] != 'S'">{{ m['Type'] | translate }}</span>
            <span class="badge pull-left accent-background" style=" font-size: 0.8rem;margin: 0px;margin-top: 3px;"
              *ngIf="m['Type'] == 'E'">Email</span>
            <span class="badge pull-left accent-background" style="font-size: 0.8rem;margin: 0px;margin-top: 3px;"
              *ngIf="m['Type'] == 'S'">SMS</span>



            <span *ngIf="(m['Type'] == 'E' || m['Type'] == 'S') &&  m['Status.Label']" class="pull-left sml "
              [attr.style]=" 'color:' + m['Status.Color'] + ' !important' | safeStyle ">

              <span style="  font-size: 0.8rem;"> {{ m['Status.Label'] }}</span>

              <mat-icon class="{{m['Status.Icon']}} status-m-event"
                [attr.style]="     'color:' + m['Status.Color'] + ' !important' | safeStyle "></mat-icon>

            </span>




            <span class="pull-right  small" matTooltip=" {{(m['Date.sent'] +' '+ m['Time.sent']) | customDateTime }} "
              style="    font-size: 0.8rem !important;    color: gray;    font-style: italic;margin-top:4px !important;"
              *ngIf="m['Date.sent'] != '0000-00-00' && m['Date.sent'] && m['Date.sent']!=''">{{(m['Date.sent'] +' '+ m['Time.sent']) | TimeLabelPast }}
              ago</span>

            <span class="pull-right   small" matTooltip=" {{m['DateTimeCreated']| customDateTime }} "
              style="    font-size: 0.8rem !important;    color: gray;    font-style: italic;margin-top:4px !important;"
              *ngIf="m['Date.sent'] == '0000-00-00' || m['Date.sent']=='' || !m['Date.sent'] ">{{ m['DateTimeCreated'] | TimeLabelPast }}
              ago</span>

          </div>



        </vertical-timeline-card>
      </vertical-timeline>
    </mat-dialog-content>
  </div>

  <div class="row clearfix" *ngIf="messages && messages.length == 0">
    <app-empty-list-message [title]="'No Communications Found'"></app-empty-list-message>
  </div>
</div>
