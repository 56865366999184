<div class="row clearfix" *ngIf="isPromoterOrAdmin == true">
  <app-operator-filter
    class="dash"
    id="part1"
    [sendNull]="true"
    [selectDateRange]="selectedRange"
    [displayMerchant]="isDisplayMerchant"
    [displayDateFrom]="true"
    [setAutoSelectMerchant]="true"
    [displayDateTo]="true"
    [displayDentist]="false"
    (search)="applySearch($event)"
  ></app-operator-filter>

  <div class="row clearfix">
    <app-marketing-campaign-message-list
      *ngIf="merchantID != 'none' && searchApplied == true"
      [dateFrom]="dateFrom"
      [dateTo]="dateTo"
      [campaignID]="campaignID"
      [status]="status"
      [statusNative]="statusNative"
    ></app-marketing-campaign-message-list>
  </div>
  <div class="row clearfix">
    <div class="pleaseChoose" *ngIf="merchantID == 'none'" @ngIfAnimation>
      <p>
        <strong>No Merchant Selected</strong>
      </p>
      <img class="character_warning" />
      <p>Choose Desired Merchant</p>
    </div>
  </div>
</div>

<div class="row clearfix" *ngIf="isPromoterOrAdmin == false">
  <app-operator-filter
    class="dash"
    [sendNull]="true"
    [selectDateRange]="selectedRange"
    [displayMerchant]="false"
    [displayDateFrom]="true"
    [displayDateTo]="true"
    [displayTimePicker]="true"
    [displayDentist]="false"
    (search)="applySearch($event)"
  ></app-operator-filter>

  <app-marketing-campaign-message-list
    *ngIf="searchApplied == true"
    [dateFrom]="dateFrom"
    [dateTo]="dateTo"
    [campaignID]="campaignID"
    [status]="status"
    [statusNative]="statusNative"
  ></app-marketing-campaign-message-list>
</div>
