import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Location } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ConfirmDialogSingleButtonComponent } from '../../../shared/components/confirm-dialog-single-button/confirm-dialog-single-button.component';
import { ConfirmDialogSingleButton } from '../../../shared/types/confirm-dialog-single-button';
import { CustomerViewComponent } from '../../customer/customer-view/customer-view.component';
import { InvitationViewModalComponent } from '../../invitation/invitation-view-modal/invitation-view-modal.component';
import { ProductViewModalComponent } from '../../product/product-view-modal/product-view-modal.component';
import { SettlementService } from '../../settlement/shared/settlement.service';
import { ContractService } from '../shared/contract.service';

@Component({
  selector: 'app-contract-view-simple',
  templateUrl: './contract-view-simple.component.html',
  styleUrls: ['./contract-view-simple.component.css'],
  animations: [
    trigger('onChangeAnimation', [
      transition('void => *', [
        query('.animate', style({ opacity: 0 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.15s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(0)', offset: 0, height: 0, margin: 0 }),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0, height: '*', margin: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.animate', style({ opacity: 1 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.15s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0, height: '*', margin: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(0)', offset: 1.0, height: 0, margin: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class ContractViewSimpleComponent implements OnInit {
  @Input()
  contractID;

  isParameter = false;
  isPromoterOrAdmin = false;
  contract = null;
  settlements = [];

  @Output()
  createdSettlement = new EventEmitter();

  @Output()
  close = new EventEmitter();

  requestStatus = ['REQU'];
  approveStatus = ['APPR'];
  declinedStatus = ['DECL'];

  constructor(
    private activeRoute: ActivatedRoute,
    private router: Router,
    private location: Location,
    private dialog: MatDialog,
    private contractService: ContractService,
    private settlementService: SettlementService,
    private authenticationService: AuthenticationService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data && typeof data == 'string') {
      this.contractID = data;
    }
  }

  ngOnInit() {
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;

      this.activeRoute.params.subscribe((params) => {
        if (params['id']) {
          this.contractID = params['id'];
          this.isParameter = true;
        }

        const payload = {
          selectStats: true,
        };

        this.contractService
          .fetchContractDetails$(this.contractID, payload, this.isPromoterOrAdmin)
          .subscribe((res) => {
            this.contract = res;
          });
      });
    });
  }

  closeModal() {
    this.close.emit(true);
  }

  getSettlements() {
    const payload = {
      contractID: this.contractID,
      fields: 'ID',
    };

    this.settlementService.getSettlementList(payload).subscribe((res1) => {
      this.settlements = res1;
    });
  }

  // ngOnChanges(changes: SimpleChanges) {

  //   this.contractService.getContractDetails(this.contractID).subscribe(res => {
  //     this.contract = res;
  //     console.log(this.contract)

  //   })
  // }

  viewProduct() {
    const ref = this.dialog.open(ProductViewModalComponent, {
      data: this.contract['Product_key'],
      width: '750px',
      panelClass: 'noCard',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  toNumber(n) {
    if (n) {
      return Number(n);
    } else {
      return 0;
    }
  }

  createSettlement() {
    if (this.contract && this.contract['ID']) {

      let p = { contractID:this.contract['ID'] }

      AuthenticationService.newSettlement.emit(p);

      // this.router.navigate(['/merchant', { outlets: { page: ['settlement-create', this.contract['ID']] } }]);
    }
  }

  viewInvitation() {
    const payload = {
      fields: 'ID',
    };

    this.contractService.onInvitation(this.contract['ID'], payload).subscribe((res) => {
      if (res && res['ID']) {
        const ref = this.dialog.open(InvitationViewModalComponent, {
          data: {
            invitationID: res['ID'],
            membership: res.membership,
          },

          width: '900px',
        });

        ref.componentInstance.close.subscribe((res) => {
          ref.close();
        });

        ref.componentInstance.openDetails.subscribe((id) => {
          ref.close();
          ref.afterClosed().subscribe((res) => {
            if (res.membership == true) {
              this.router.navigate(['/merchant', { outlets: { page: ['membership-view', id] } }]);
            } else {
              this.router.navigate(['/merchant', { outlets: { page: ['invitation-view', id] } }]);
            }
          });
        });
      }
    });
  }

  viewCustomer() {
    const ref = this.dialog.open(CustomerViewComponent, {
      data: this.contract['Customer_key'],
      width: '750px',
      panelClass: 'noCard',
    });
  }

  canDrawDown() {
    if (this.contract && this.contract['NextDrawDown.Before'] == '0000-00-00') {
      return true;
    } else if (this.contract && this.contract['NextDrawDown.Before']) {
      const result = moment(this.contract['NextDrawDown.Before'], 'YYYY-MM-DD').isAfter(new Date());

      return result;
    }
  }

  openConfirmDialog() {
    const confirmDialog = new ConfirmDialogSingleButton(
      'fas fa-question',
      'What is MSF',
      '<p>MSF stands for Merchant Service Fee.</p><p>All credit card transactions, (including contactless and card not present e.g. online), pre-paid and international debit card transactions, incur a merchant service fee.</p> <p>The merchant service fee is made up by the interchange fee set by the card schemes, and costs incurred by us to process the transactions.</p> ',
      'OKAY'
    );

    const ref = this.dialog.open(ConfirmDialogSingleButtonComponent, {
      data: confirmDialog,
    });

    ref.componentInstance.onConfirm.subscribe((res) => {
      ref.close();
    });
  }
}
