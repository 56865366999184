import { Component, Input, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { UtilsService } from '../../services/utils.service';
import { ConfirmDialog } from '../../types/confirm-dialog';
import { NotifyAppComponent } from '../../types/notify-app-component';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { PhoneLabelInputComponent } from '../phone-label-input/phone-label-input.component';
import { RootAppComponent } from '../root-component/root-component.component';

@Component({
  selector: 'app-phone-input-advanced',
  templateUrl: './phone-input-advanced.component.html',
  styleUrls: ['./phone-input-advanced.component.css'],
})
export class PhoneInputAdvancedComponent implements OnInit {
  @Input()
  targetID;

  @Input()
  targetPhoneType;

  @Input()
  phoneTypes;

  @Input()
  myPhoneNumber;

  @Input()
  isDisabled = false;

  phoneType;
  isPrimaryPhone = true;
  @Input()
  type = 'current';
  sessionType;

  constructor(private utilService: UtilsService, private authenticationService: AuthenticationService) {}

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((st) => {
      this.sessionType = st;

      this.phoneType = this.targetPhoneType;
    });
  }

  createPhoneLabel() {
    const ref = RootAppComponent.dialog.open(PhoneLabelInputComponent, {
      data: {
        ownerID: this.targetID,
        type: this.type,
      },
      width: '800px',
    });

    ref.componentInstance.getResult.subscribe((res) => {
      if (res) {
        ref.close();
        this.phoneType = res.Label;
        this.phoneTypes.push(res.Label);
        if (res['IsPrimary'] == '1') {
          this.targetPhoneType = res.Label;
        }
        this.getPhoneByType(res.Label);
      }
    });

    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
        this.phoneType = this.targetPhoneType;
        this.getPhoneByType(this.phoneType);
      }
    });
  }

  removePhoneByLabel(label) {
    const confirmDialog = new ConfirmDialog(
      'warning',
      'Delete phone',
      'Are you sure to remove this phone with label permanently?',
      'Cancel',
      'Proceed'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
      width: '650px',
    });

    ref.componentInstance.onConfirm.subscribe((res) => {
      if (res == true) {
        this.utilService.deletePhoneByLabel(this.targetID, label, this.type, this.sessionType).subscribe((res) => {
          if (res) {
            NotifyAppComponent.displayToast('success', 'Delete phone', 'You have successfully deleted this phone');
            this.phoneType = this.targetPhoneType;
            this.phoneTypes.splice(this.phoneTypes.indexOf(label), 1);
            this.getPhoneByType(this.phoneType);
            ref.close();
          }
        });
      } else {
        ref.close();
      }
    });
  }

  editPhoneByLabel(label) {
    const ref = RootAppComponent.dialog.open(PhoneLabelInputComponent, {
      data: {
        ownerID: this.targetID,
        label,
        isEdit: true,
        phoneNumber: this.myPhoneNumber,
        phoneTypes: this.phoneTypes,
        isPrimary: this.isPrimaryPhone,
        type: this.type,
      },
      width: '800px',
    });

    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });

    ref.componentInstance.getResult.subscribe((res) => {
      if (res) {
        ref.close();
        this.phoneType = res.Label;
        if (res['IsPrimary'] == '1') {
          this.targetPhoneType = res.Label;
        }
        this.getPhoneByType(res.Label);
      }
    });
  }

  getPhoneByType(e) {
    if (e) {
      this.utilService.getPhoneByLabel(this.targetID, e, this.type, this.sessionType).subscribe((res) => {
        if (res) {
          this.myPhoneNumber = res['Number'];
          this.isPrimaryPhone = res['IsPrimary'] == '1' ? true : false;
        }
      });
    }
  }

  convertToLowerCase(val) {
    if (val) {
      return val.toLowerCase();
    }
  }
}
