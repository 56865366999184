import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MerchantServiceCreateComponent } from '../merchant-service-create/merchant-service-create.component';
import { MerchantServiceEditComponent } from '../merchant-service-edit/merchant-service-edit.component';
import { MerchantServiceGroupCreateComponent } from '../merchant-service-group-create/merchant-service-group-create.component';
import { MerchantServiceGroupEditComponent } from '../merchant-service-group-edit/merchant-service-group-edit.component';
import { MerchantServiceGroupListComponent } from '../merchant-service-group-list/merchant-service-group-list.component';
import { MerchantServiceListComponent } from '../merchant-service-list/merchant-service-list.component';
import { MerchantServicesOverviewComponent } from '../merchant-services-overview/merchant-services-overview.component';

const routes: Routes = [
  {
    path: 'merchant-service-list',
    component: MerchantServiceListComponent,
  },

  {
    path: 'MerchantServiceOverviewComponent',
    component: MerchantServicesOverviewComponent,
  },

  {
    path: 'MerchantServiceEditComponent',
    component: MerchantServiceEditComponent,
  },

  {
    path: 'merchant-service-group-list',
    component: MerchantServiceGroupListComponent,
  },

  {
    path: 'MerchantServiceGroupEditComponent',
    component: MerchantServiceGroupEditComponent,
  },

  {
    path: 'MerchantServiceCreateComponent',
    component: MerchantServiceCreateComponent,
  },

  {
    path: 'MerchantServiceCreateGroupComponent',
    component: MerchantServiceGroupCreateComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MerchantServicesRoutingModule {}
