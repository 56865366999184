<!-- <div *ngIf=" customInv && invitationID" id="blueBg">
</div> -->
<div class="rel" *ngIf="customInv && invitationID" @ngIfAnimation>
  <div class="container" id="customer-card">
    <h1 class="white ap-title">
      Hi
      <span>{{ customInv['FirstName'] }} {{ customInv['LastName'] }}</span>
    </h1>
    <h2 class="white ap-subtitle">This is your personalised Customer Card!</h2>
    <mat-card class="custCard">
      <div class="row clearfix text-center">
        <p class="larger rm-mb">You are applying to borrow</p>
        <h1 class="text-center lead primary-color rm-mt rm-mb loanValue">
          <span>{{ customInv['Service.Value'] | customCurrency }}</span>
        </h1>
        <p class="rm-mt mb small">
          on <span>{{ customInv['DateTimeCreated'] | customDateTime }}</span> with a
          <strong>{{ customInv['Product.Label.Marketing'] }} </strong>
        </p>
      </div>

      <hr class="mt mb"/>

      <div class="row clearfix full-width mb">
        <h2 class="sr-title sm-h2 rm-mb rm-mt">
          <mat-icon>monetization_on</mat-icon>
          The reason for loan
        </h2>

        <div class="row-content-col">
          <p>Select the reason for finance.</p>
          <div class="clearfix mt text-center centered">
            <mat-select
              class="full-width clearfix"
              [(ngModel)]="serviceType"
              placeholder="Please select"
              name="serviceType"
              required
            >
              <mat-option *ngFor="let s of serviceTypes | objectPropertyValue: 'Label'" [value]="s">
                {{ s }}
              </mat-option>
            </mat-select>
          </div>
        </div>
      </div>

      <hr class="mt mb" *ngIf="customInv['MessageFromDentist']"/>

      <div class="row clearfix full-width" *ngIf="customInv['MessageFromDentist']">
        <h2 class="sr-title sm-h2 rm-mb rm-mt">
          <mat-icon>drafts</mat-icon>
          A message from your account manager
        </h2>

        <div class="row-content-col">
          <p>{{ customInv['MessageFromDentist'] }}</p>
          <label>
            <em>Best regards,</em>
          </label>
          <p>{{ customInv['DentistContactName'] }}</p>
        </div>

        <!-- <hr class="mt mb"> -->
      </div>

      <app-document-list-consumer-simple
        class="row clearfix full-width mb"
        [invitationID]="customInv.WakandaID"
      ></app-document-list-consumer-simple>

      <!-- <hr class="mt mb" *ngIf="consumerCanChange==true"> -->

      <div
        class="row clearfix full-width mb mt"
        id="payment-panel"
        *ngIf="
          customInv['Product.PatientCanChoose'] == '1' ||
          customInv['Product_Key'] == '' ||
          customInv['Product_Key'] == '00000000000000000000000000000000'
        "
      >
        <div class="card-header finance-header primary-gradient-img">
          <div class="text-center">
            <p class="lead rm-mb">Need a hand with paying?</p>
            <p>
              Select from one of the following trusted inance options, apply online and get approved fast - <br/>it's
              quick and simple with no manual paperwork.
            </p>
          </div>
        </div>
        <app-suitable-product-compact-list
          *ngIf="customInv['Treatment.Value'] && customInv['Product.ThisGroupOnly.Code']"
          [frequency]="'1'"
          [displaySimulator]="false"
          [groupCode]="customInv['Product.ThisGroupOnly.Code']"
          [proceedLabel]="'Select'"
          [merchantKey]="customInv['Merchant_Key']"
          [amount]="customInv['Treatment.Value']"
          (select)="selectProduct($event)"
        ></app-suitable-product-compact-list>
      </div>

      <hr
        class="mt mb"
        *ngIf="customInv['Product_Key'] && customInv['Product_Key'] != '00000000000000000000000000000000'"
      />
      <div
        class="row clearfix full-width"
        *ngIf="customInv['Product_Key'] && customInv['Product_Key'] != '00000000000000000000000000000000'"
      >
        <h2 class="sr-title sm-h2 rm-mb rm-mt">
          <mat-icon>business_center</mat-icon>
          Choose Duration
        </h2>

        <!-- <div class="row-content-col"> -->

        <app-product-calculator
          class=""
          [isTop]="true"
          [moreInfo]="false"
          [dentalInfo]="false"
          [retailInfo]="true"
          [duration]="customInv['Quoted.Repayments.Count'] || null"
          [displayDeposit]="true"
          [ProductID]="customInv['Product_Key']"
          [dynamicAmount]="false"
          [fixedAmount]="customInv['Treatment.Value'] && customInv['Service.Value']"
          [frequency]="frequencyValidation(customInv['Quoted.Repayments.Frequency.Code'])"
          [patientContribution]="customInv['CustomerContribution']"
          [isFrequency]="true"
          [isPatientContribution]="true"
          (estimation)="calculator($event)"
        >
        </app-product-calculator>

        <!-- </div> -->
      </div>

      <div class="row clearfix text-center button-proceed add-mt">
        <button class="btn-large btn-grey pull-left" (click)="openPatientCardStopModal()" mat-raised-button>
          Don't proceed
          <mat-icon>reply</mat-icon>
        </button>
        <button
          class="btn-large pull-right"
          [disabled]="!frequency || !duration || !productID || !serviceType"
          (click)="showPaymentCard()"
          mat-raised-button
          color="accent"
        >
          Proceed
          <mat-icon>chevron_right</mat-icon>
        </button>
      </div>
    </mat-card>
  </div>
</div>
