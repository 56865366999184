import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class CalendarService {
  constructor(private http: HttpClient) {}

  getCalendarDetails(id, sessionType) {
    let url = 'calendar';
    switch (sessionType) {
      case 'promoter':
      case 'admin':
        url += '/global';
        break;
      case 'merchant':
      case 'merchant-admin':
        break;
      default:
        throw new Error('wrong session type');
    }

    return this.http.get(`${environment.nodeUrl}/${url}/${id}`, {}).pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      })
    );
  }

  getCalendarList(payload, sessionType) {
    let url = 'calendar';
    switch (sessionType) {
      case 'promoter':
      case 'admin':
        url += '/global';
        break;
      case 'merchant':
      case 'merchant-admin':
        break;
      default:
        throw new Error('wrong session type');
        break;
    }

    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };
    return this.http.get(`${environment.nodeUrl}/${url}`, options).pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      })
    );
  }

  syncCalendar(payload, sessionType = 'merchant') {
    let url = 'calendar';

    switch (sessionType) {
      case 'promoter':
      case 'admin':
        url += '/global';
        break;
      case 'merchant':
      case 'merchant-admin':
        break;
      default:
        break;
    }

    return this.http.post(`${environment.nodeUrl}/${url}`, payload).pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      })
    );
  }

  editCalendar(id, payload, sessionType = 'merchant') {
    let url = 'calendar';

    switch (sessionType) {
      case 'promoter':
      case 'admin':
        url += '/global';
        break;
      case 'merchant':
      case 'merchant-admin':
        break;
      default:
        break;
    }

    return this.http.put(`${environment.nodeUrl}/${url}/${id}`, payload).pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      })
    );
  }
}
