import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { UtilsService } from '../../services/utils.service';
import { ConfirmDialog } from '../../types/confirm-dialog';
import { NotifyAppComponent } from '../../types/notify-app-component';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../root-component/root-component.component';

@Component({
  selector: 'app-mobile-label-input.component',
  templateUrl: './mobile-label-input.component.html',
  styleUrls: ['./mobile-label-input.component.css'],
})
export class MobileLabelInputComponent implements OnInit {
  @Input()
  ownerID;

  @Input()
  label;

  @Input()
  isEdit = false;

  @Output()
  getResult = new EventEmitter();

  @Output()
  close = new EventEmitter();

  isModal = false;

  @Input()
  myMobileNumber = null;

  @Input()
  isPrimary = false;

  @Input()
  mobileTypes;

  clearMobile = new EventEmitter();

  validateMobile = false;
  isRequired = true;
  @Input()
  type = 'current';
  sessionType;

  constructor(
    private utilService: UtilsService,
    private authenticationService: AuthenticationService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.ownerID) {
        this.ownerID = data.ownerID;
      }

      if (data.isEdit) {
        this.isEdit = data.isEdit;
      }

      if (data.label) {
        this.label = data.label;
      }
      if (data.type) {
        this.type = data.type;
      }
      if (data.mobileNumber) {
        this.myMobileNumber = data.mobileNumber;
      }

      if (data.isPrimary) {
        this.isPrimary = data.isPrimary;
      }

      if (data.mobileTypes) {
        this.mobileTypes = data.mobileTypes;
      }

      this.isModal = true;
    }
  }

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((st) => {
      this.sessionType = st;

      if (this.isEdit == true) {
        this.isRequired = false;
      }
    });
  }

  closeModal() {
    this.close.emit(true);
  }

  getMobile(m) {
    this.myMobileNumber = m;
  }

  validateMobileEvent(v) {
    this.validateMobile = v;
  }

  create() {
    const payload = {
      ownerID: this.ownerID,
      label: this.label,
      number: this.myMobileNumber,
      isPrimary: this.isPrimary,
    };

    this.utilService.createMobileByLabel(payload, this.type, this.sessionType).subscribe((res) => {
      if (res) {
        NotifyAppComponent.displayToast(
          'success',
          'Create new Address',
          'You have successfully created a new mobile nunmber with label ' + res.Label
        );
        this.getResult.emit(res);
        this.mobileTypes.push(this.label);
        this.close.emit(true);
      }
    });
  }

  modify() {
    const payload = {
      number: this.myMobileNumber || 'void',
      isPrimary: this.isPrimary || false,
    };

    this.utilService
      .modifyMobileByLabel(this.ownerID, this.label, payload, this.type, this.sessionType)
      .subscribe((res) => {
        if (res) {
          NotifyAppComponent.displayToast(
            'success',
            'Update Address',
            'You have successfully updating the mobile number'
          );
          this.getResult.emit(res);
        }
      });
  }

  getMobileByType(e) {
    if (e) {
      this.utilService.getMobileByLabel(this.ownerID, e, this.type, this.sessionType).subscribe((res) => {
        if (res) {
          this.myMobileNumber = res['Number'];
          this.isPrimary = res['IsPrimary'] == '1' ? true : false;
        }
      });
    } else {
      this.isPrimary = null;
      this.isEdit = false;
      this.clearMobile.emit(true);
      this.isRequired = true;
    }
  }

  convertToLowerCase(val) {
    if (val) {
      return val.toLowerCase();
    }
  }

  removePhoneByLabel() {
    const confirmDialog = new ConfirmDialog(
      'warning',
      'Delete phone',
      'Are you sure to remove this phone with label permanently?',
      'Cancel',
      'Proceed'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
      width: '650px',
    });

    ref.componentInstance.onConfirm.subscribe((res) => {
      if (res == true) {
        this.utilService.deletePhoneByLabel(this.ownerID, this.label, this.type, this.sessionType).subscribe((res) => {
          if (res) {
            NotifyAppComponent.displayToast('success', 'Delete phone', 'You have successfully deleted this phone');
            this.mobileTypes.splice(this.mobileTypes.indexOf(this.label), 1);
            ref.close();
            this.close.emit(true);
          }
        });
      } else {
        ref.close();
      }
    });
  }
}
