import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { UtilsService } from '../../services/utils.service';
import { NotifyAppComponent } from '../../types/notify-app-component';

@Component({
  selector: 'app-user-access-create',
  templateUrl: './user-access-create.component.html',
  styleUrls: ['./user-access-create.component.css'],
})
export class UserAccessCreateComponent implements OnInit {
  @Input()
  cardID;

  access = [];
  filterName;

  accessList = [];

  @Output()
  updateAccess = new EventEmitter();

  @Output()
  updateAccessCode = new EventEmitter();

  @Input()
  isActiveFilter = '*';
  @Input()
  hideHeader = false;

  constructor(private utilService: UtilsService) {}

  ngOnInit() {
    this.setup();
  }

  setup() {
    this.utilService.getAccessList().subscribe((accessList) => {
      if (accessList) {
        this.accessList = accessList;

        this.utilService.getGlobalAccess(this.cardID).subscribe((res) => {
          if (res) {
            this.access = res;
          }
        });
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.cardID && changes.cardID.previousValue && !changes.cardID.currentValue) {
      this.setup();
    }
  }

  sliderChange(value, code) {
    if (value && value.checked == true) {
      this.utilService.addAccessUser(code, this.cardID).subscribe((res) => {
        if (res) {
          this.updateAccessCode.emit(code);
          this.updateAccess.emit(res);
          NotifyAppComponent.displayToast('success', 'Successfull', 'Successful operation');
        }
      });
    } else if (value && value.checked == false) {
      this.utilService.removeAccessUser(code, this.cardID).subscribe((res) => {
        if (res) {
          this.updateAccess.emit(res);
          this.updateAccessCode.emit(code);

          NotifyAppComponent.displayToast('success', 'Successfull', 'Successful operation');
        }
      });
    }
  }
}
