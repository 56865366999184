/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./admin-theme.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i3 from "@angular/material/card";
import * as i4 from "@angular/platform-browser/animations";
import * as i5 from "../../../theme/theme-list/theme-list.component.ngfactory";
import * as i6 from "../../../theme/theme-list/theme-list.component";
import * as i7 from "../../../theme/shared/theme.service";
import * as i8 from "@angular/router";
import * as i9 from "../../../settings/shared/settings.service";
import * as i10 from "@angular/common";
import * as i11 from "./admin-theme.component";
import * as i12 from "../../../authentication/shared/authentication.service";
var styles_AdminThemeComponent = [i0.styles];
var RenderType_AdminThemeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AdminThemeComponent, data: {} });
export { RenderType_AdminThemeComponent as RenderType_AdminThemeComponent };
function View_AdminThemeComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "mat-card", [["class", "full-width row clearfix  mat-card"]], [[2, "_mat-animation-noopable", null]], null, null, i2.View_MatCard_0, i2.RenderType_MatCard)), i1.ɵdid(1, 49152, null, 0, i3.MatCard, [[2, i4.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "app-theme-list", [], null, null, null, i5.View_ThemeListComponent_0, i5.RenderType_ThemeListComponent)), i1.ɵdid(3, 114688, null, 0, i6.ThemeListComponent, [i7.ThemeService, i8.Router, i9.SettingsService], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0); }); }
export function View_AdminThemeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_AdminThemeComponent_1)), i1.ɵdid(1, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isAdmin == true); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AdminThemeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-admin-theme", [], null, null, null, View_AdminThemeComponent_0, RenderType_AdminThemeComponent)), i1.ɵdid(1, 114688, null, 0, i11.AdminThemeComponent, [i12.AuthenticationService, i8.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AdminThemeComponentNgFactory = i1.ɵccf("app-admin-theme", i11.AdminThemeComponent, View_AdminThemeComponent_Host_0, {}, {}, []);
export { AdminThemeComponentNgFactory as AdminThemeComponentNgFactory };
