import { BehaviorSubject, merge as observableMerge, Observable } from 'rxjs';

import { DataSource } from '@angular/cdk/table';
import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Optional,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { CustomDatePipe } from '../../../shared/pipes/custom-date.pipe';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';

import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { SideMenuService } from '../../../shared/services/side-menu.service';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { MerchantTradingHoursOverviewComponent } from '../../merchant/merchant-trading-hours-overview/merchant-trading-hours-overview.component';
import { MerchantViewComponent } from '../../merchant/merchant-view/merchant-view.component';
import { OperatorService } from '../../operator/shared/operator.service';
import { DentistService } from '../shared/dentist.service';
import { SmsConversationComponent } from '../../message/sms-conversation/sms-conversation.component';
import { DentistViewComponent } from '../dentist-view/dentist-view.component';
import { CustomPhonePipe } from '../../../shared/pipes/custom-phone.pipe';
import { PasswordPromptComponent } from '../../operator/password-prompt/password-prompt.component';
import { DentistInviteCreateComponent } from '../dentist-invite-create/dentist-invite-create.component';
import { ContactCreateEditModalComponent } from '../../contact/contact-create-edit-modal/contact-create-edit-modal.component';
import { OperatorLoginAccessComponent } from '../../operator/operator-login-access/operator-login-access.component';

@Component({
  selector: 'app-dentist-list',
  templateUrl: './dentist-list.component.html',
  styleUrls: ['./dentist-list.component.css'],
  providers: [CustomDatePipe],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.row', style({ opacity: 0 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.row', style({ opacity: 1 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class DentistListComponent implements OnInit {
  @Input()
  merchantID;

  util = new UtilsClass();
  invitationID = null;
  isPromoterOrAdmin = false;
  isMerchantAdmin = false;

  isModal = false;

  close = new EventEmitter();

  title = 'Staff Members';
  @Input()
  pageSize = Settings.global['listPageSize'] || 20;
  pageSizeOptions = [10, Number(this.pageSize), Number(this.pageSize) * 2, Number(this.pageSize) * 3];

  utils = new UtilsClass();

  displayedColumns = [
    'ProfileImage',
    'DentistName',
    'DentistPhone',
    'DentistMobile',
    'DentistEmail',

    'Merchant',
    'Login',
    'LastAccess',
    'Type',
    'CreatedDate',
    'Actions',
  ];
  filters = [];

  searchVals = new FormControl();

  searchValList = ['Merchant', 'Trading as', 'Full Name', 'Email', 'Phone', 'Mobile'];

  expandedRow: number;
  public listDB: LoadRecords | null;
  dataSource: RecordDataSource | null;

  filteredSize = null;
  sessionType = 'merchant';

  destroyEvent = new EventEmitter();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('filter', { static: false }) filter: ElementRef;

  constructor(
    private DentistService: DentistService,
    private dialog: MatDialog,
    private customDate: CustomDatePipe,
    private authenticationService: AuthenticationService,
    private router: Router,
    private operatorService: OperatorService,
    private customPhonePipe: CustomPhonePipe,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.pageSize) {
        this.pageSize = data.pageSize;
        this.isModal = true;
      }

      if (data.title) {
        this.title = data.title;
        this.isModal = true;
      }

      if (data.merchantID) {
        this.merchantID = data.merchantID;
        this.isModal = true;
      }
    }
  }

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((sessionType) => {
      if (sessionType) {
        this.sessionType = sessionType;
      }

      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isPromoterOrAdmin = res;

        this.authenticationService.isMerchantAdmin().subscribe((res2) => {
          this.isMerchantAdmin = res2;

          this.displayColumns();
        });

        SideMenuService.goBack.subscribe((res) => {
          if (res == true) {
            this.hideViewDetails();
          }
        });

        this.listDB = new LoadRecords(
          this.DentistService,
          this.operatorService,
          this.destroyEvent,
          this.merchantID,
          this.isPromoterOrAdmin,
          this.isMerchantAdmin,
          this.customPhonePipe
        );
        this.dataSource = new RecordDataSource(this.listDB, this.paginator, this.sort);
      });
    });
  }

  displayColumns() {
    if (this.isPromoterOrAdmin == true) {
      this.displayedColumns = [
        'ProfileImage',
        // "TradingAS",
        'DentistName',
        'DentistPhone',
        'DentistMobile',
        'DentistEmail',
        // 'MerchantName',
        'LastAccess',
        'Type',
        'hasAccount',
        'IsAdmin',
        'Login',
        'CreatedDate',
        'Actions',
      ];
    } else if (this.isMerchantAdmin == true) {
      this.displayedColumns = [
        'ProfileImage',
        'DentistName',
        'DentistPhone',
        'DentistMobile',
        'DentistEmail',
        'LastAccess',
        'Type',
        'hasAccount',
        'IsAdmin',
        'Login',
        'CreatedDate',
        'Actions',
      ];
    } else {
      this.displayedColumns = [
        'ProfileImage',
        // "TradingAS",
        'DentistName',
        'DentistPhone',
        'DentistMobile',
        'DentistEmail',
        'LastAccess',
        'Type',
        'CreatedDate',
        'Actions',
      ];
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;

      this.authenticationService.isMerchantAdmin().subscribe((res2) => {
        this.isMerchantAdmin = res2;

        this.displayColumns();
      });

      this.listDB = new LoadRecords(
        this.DentistService,
        this.operatorService,
        this.destroyEvent,
        this.merchantID,
        this.isPromoterOrAdmin,
        this.isMerchantAdmin,
        this.customPhonePipe
      );
      this.dataSource = new RecordDataSource(this.listDB, this.paginator, this.sort);
    });
  }
  createNewContact() {
    const ref2 = RootAppComponent.dialog.open(ContactCreateEditModalComponent, {
      width: '850px',
    });

    ref2.componentInstance.close.subscribe((res) => {
      ref2.close();
    });
    ref2.componentInstance.getResult.subscribe((res) => {
      if (res && res.ID) {
        ref2.close();
        ref2.afterClosed().subscribe((r) => {
          this.router.navigate([
            '/merchant',
            {
              outlets: {
                page: ['dentist-profile', res.ID],
              },
            },
          ]);
        });
      }
    });
  }
  setFilter(event, field) {
    let filter;

    if (field == 'ApprovedOrActive') {
      let _event;
      if (event == true) {
        _event = '1';
      } else {
        _event = '0';
      }
      filter = {
        field,
        value: _event,
      };
    } else if (typeof event == 'object' && event != null) {
      const v = this.customDate.transform(event, Settings.global['dateFormat']);
      filter = {
        field,
        value: v,
      };
    } else if (event) {
      filter = {
        field,
        value: event,
      };
    } else {
      filter = {
        field,
        value: '',
      };
    }

    this.dataSource.filter = filter;
    this.filteredSize = this.dataSource.filteredData.length;
  }

  disableLogin(operatorID) {
    if (operatorID && (this.isPromoterOrAdmin == true || this.isMerchantAdmin == true)) {
      const confirmMsg = new ConfirmDialog(
        'fas fa-info',
        'Disable login access',
        'Are you sure to disable the login access for this user?',
        'No',
        'Yes'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirmMsg,
        width: '650px',
      });

      ref.componentInstance.onConfirm.subscribe((res) => {
        if (res == true) {
          this.operatorService.disableLogin(operatorID, this.sessionType).subscribe((res) => {
            if (res) {
              const p = {
                hasLoginData: true,
              };
              this.DentistService.getContactDetails(res.Card_key, p, this.sessionType).subscribe((res2) => {
                if (res2) {
                  NotifyAppComponent.displayToast(
                    'success',
                    'Disable login access',
                    'You have successfully disabled the login access for this user.'
                  );
                  ref.close();
                  this.dataSource.replaceItem = this.getPicture(res2);
                }
              });
            }
          });
        } else {
          ref.close();
        }
      });
    }
  }
  closeEvent() {
    this.close.emit(true);
  }

  passwordUpdate(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(PasswordPromptComponent, {
        data: {
          operatorID: id,
        },
        width: '650px',
        panelClass: 'noCard',
      });

      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
      ref.componentInstance.getResult.subscribe((res) => {
        ref.close();
      });
    }
  }

  loginAsUser(item) {
    if (item) {
      if (item && item['ID'] && item['operatorID']) {
        const confirm = new ConfirmDialog(
          'fas fa-info',
          'You need to logout',
          'In order to change the connected user you need to logout',
          'Cancel',
          'Logout and Proceed'
        );

        const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
          data: confirm,
          disableClose: true,
        });
        ref.componentInstance.onConfirm.subscribe((confirmation) => {
          if (confirmation === false) {
            ref.close();
          } else {
            const body = {
              cardID: item['ID'],
            };
            this.authenticationService.loginAsUser(body).subscribe((res) => {
              if (res) {
                this.authenticationService.getCurrentUserForce().subscribe((getCurrentUserForce) => {
                  NotifyAppComponent.displayToast('success', 'success', 'Successful operation');

                  if (getCurrentUserForce && getCurrentUserForce.data) {
                    if (
                      getCurrentUserForce.data.SessionType == 'admin' ||
                      getCurrentUserForce.data.SessionType == 'merchant' ||
                      getCurrentUserForce.data.SessionType == 'merchant-admin' ||
                      getCurrentUserForce.data.SessionType == 'promoter'
                    ) {
                      UtilsClass.startLoading();
                      window.location.href = Settings.global['merchantFrontendLink'] + '/merchant';
                    } else if (getCurrentUserForce.data.SessionType == 'customer') {
                      UtilsClass.startLoading();
                      window.location.href = Settings.global['consumerFrontendLink'] + '/consumer-app/(page:home)';
                    }
                  }
                });
              }
            });
          }
        });
      }
    }
  }

  operatorAccess(operatorID) {
    if (operatorID) {
      const ref = RootAppComponent.dialog.open(OperatorLoginAccessComponent, {
        data: {
          operatorID: operatorID,
        },
        width: '480px',
        panelClass: ['noCard'],
      });
      ref.componentInstance.getResult.subscribe((res) => {
        ref.close();
      });
      ref.componentInstance.close.subscribe((res) => {
        ref.close();
      });
    }
  }

  enableLogin(operatorID) {
    if (operatorID && (this.isPromoterOrAdmin == true || this.isMerchantAdmin == true)) {
      const confirmMsg = new ConfirmDialog(
        'fas fa-info',
        'Enable login access',
        'Are you sure to enable the login access for this user?',
        'No',
        'Yes'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirmMsg,
        width: '650px',
      });

      ref.componentInstance.onConfirm.subscribe((res) => {
        if (res == true) {
          this.operatorService.enableLogin(operatorID, this.sessionType).subscribe((res) => {
            if (res) {
              const p = {
                hasLoginData: true,
              };
              this.DentistService.getContactDetails(res.Card_key, p, this.sessionType).subscribe((res2) => {
                if (res2) {
                  NotifyAppComponent.displayToast(
                    'success',
                    'Enable login access',
                    'You have successfully enabled the login access for this user.'
                  );

                  ref.close();

                  this.dataSource.replaceItem = this.getPicture(res2);
                }
              });
            }
          });
        } else {
          ref.close();
        }
      });
    }
  }

  createLogin(id) {
    this.router.navigate(['/merchant', { outlets: { page: ['dentist-invite-create', id] } }]);
    this.closeEvent();
  }

  getPicture(item) {
    let result;
    result = item;

    if (result) {
      result['profileLink'] = this.DentistService.getProfilePicStreamLink(result['ID']);
    }
    return result;
  }

  unsetMerchantAdmin(id) {
    this.operatorService.unsetMerchantAdmin(id).subscribe((res) => {
      if (res) {
        const item = {
          contactID: res['Card_key'],
          isAdmin: res['IsAdministrator'] == '1' ? true : false,
        };

        this.dataSource.replaceIsAdminFlag = item;

        NotifyAppComponent.displayToast('success', 'Set Role', 'This operator role is merchant');
      }
    });
  }

  setMerchantAdmin(id) {
    this.operatorService.setMerchantAdmin(id).subscribe((res) => {
      if (res) {
        const item = {
          contactID: res['Card_key'],
          isAdmin: res['IsAdministrator'] == '1' ? true : false,
        };

        this.dataSource.replaceIsAdminFlag = item;

        NotifyAppComponent.displayToast('success', 'Set Role', 'This operator role is merchant-admin');
      }
    });
  }

  openQuick(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(DentistViewComponent, {
        data: id,
        width: '550px',
      });
      ref.componentInstance.close.subscribe((res) => {
        if (res == true) {
          ref.close();
        }
      });
    }
  }

  openQuickViewDialog(ID) {
    // let ref = this.dialog.open(DentistViewComponent, {
    //   data: ID,
    //   width: "600px"
    // });

    // ref.componentInstance.close.subscribe(res => {
    //   if (res == true) {
    //     ref.close()
    //   }
    // })
    this.router.navigate(['/merchant', { outlets: { page: ['dentist-profile', ID] } }]);

    this.closeEvent();
  }

  merchantView(ID) {
    const ref = this.dialog.open(MerchantViewComponent, {
      data: ID,
      width: '600px',
    });

    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  editWorkingHours(merchantID, ID) {
    if (ID) {
      const ref = RootAppComponent.dialog.open(MerchantTradingHoursOverviewComponent, {
        data: {
          merchantID,
          dentistID: ID,
          isMerchant: false,
          isDentist: false,
        },
        width: '800px',
      });

      ref.componentInstance.close.subscribe((res) => {
        if (res == true) {
          ref.close();
        }
      });
    }
  }
  contact(id) {
    const data = {
      targetType: 'contact',
      targetID: id,
      asGuest: false,
      asProfile: true,
      asPractice: true,
    };
    AuthenticationService.contactInputEvent.emit(data);
  }

  contactSMS(id) {
    if (id) {
      const ref2 = RootAppComponent.dialog.open(SmsConversationComponent, {
        data: {
          cardID: id,
          isContactStaff: true,
          singleChat: true,
        },
        panelClass: 'bigger-screen',
        width: '70%',
        height: '95vh',
      });
      const sub = ref2.componentInstance.close.subscribe((data) => {
        ref2.close();
      });
    }
  }
  editContactDetails(item) {
    if (item && item.ID) {
      const ref2 = RootAppComponent.dialog.open(DentistInviteCreateComponent, {
        data: {
          isModal: true,
          displayEditOperator: false,
          contactID: item.ID,
        },
        panelClass: ['max-width-panel-1200', 'noCard', 'card-relative'],
        width: '90vw',
      });

      ref2.componentInstance.close.subscribe((res) => {
        ref2.close();
      });
      ref2.componentInstance.getResult.subscribe((res) => {
        if (res && res.ID) {
          ref2.close();

          this.dataSource.replaceItem = this.getPicture({ ...item, ...res });
        }
      });
    }
  }
  toNumber(n) {
    if (n) {
      return Number(n);
    } else {
      return 0;
    }
  }

  hideViewDetails() {}

  ngOnDestroy() {
    this.destroyEvent.emit(true);
  }
}

export class LoadRecords implements OnInit {
  public dataChange: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  items: any;
  count: any;

  get data(): any[] {
    return this.dataChange.value;
  }

  firstLoad = false;

  get firstLoadEvent() {
    return this.firstLoad;
  }

  serviceRef;
  isDestoyed = false;

  constructor(
    private DentistService: DentistService,
    private operatorService: OperatorService,
    private destroyEvent,
    private merchantID,
    private isPromoterOrAdmin,
    private isMerchantAdmin,
    private customPhonePipe: CustomPhonePipe
  ) {
    this.destroyEvent.subscribe((res) => {
      this.isDestoyed = res;
    });

    const payload = {
      section: 0,
      orderBy: 'CalculatedName',
      merchantID: this.merchantID,
      selectOperator: true,
      hasLoginData: true,
      fields:
        'ID,TradingAs,LastModified,ContactType.Label,ContactForCard_key,ContactForCardName,CalculatedName,mobiles.Number,emails.Email,DateTimeCreated,phones.Number,' +
        'EConnectLogin.Permission,EConnectLogin.LastAccessed',
    };

    if (this.isPromoterOrAdmin == true || this.isMerchantAdmin == true) {
      payload.fields = payload.fields + ',EConnectLogin.Econnect_Key';
    }

    this.serviceRef = this.DentistService.getList(payload, this.isPromoterOrAdmin).subscribe((res) => {
      this.count = res.length;
      this.items = res;

      for (let i = 0; i < this.items.length; i++) {
        this.buildRecord(i);
      }

      this.dataChange.next(this.items.slice());
      this.firstLoad = true;
      const innerFunction = (section) => {
        const length = this.items.length;
        section = section + 1;
        UtilsClass.loadingDataSection(section);
        payload.section = section;

        this.serviceRef = this.DentistService.getList(payload, this.isPromoterOrAdmin).subscribe((res) => {
          if (res.length > 0) {
            this.count = res.count;
            this.items = this.items.concat(res);

            for (let i = length; i < this.items.length; i++) {
              this.buildRecord(i);
            }

            this.dataChange.next(this.items.slice());
            if (this.isDestoyed != true) {
              innerFunction(section);
            }
          } else {
            return true;
          }
        });
        UtilsClass.stopLoadingInterceptor();
      };

      if (this.isDestoyed != true) {
        innerFunction(1);
      }
    });
  }

  buildRecord(i) {
    if (this.items[i]) {
      this.items[i]['fullName'] = '';

      if (this.items[i]['ID']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['ID'];
      }
      if (this.items[i]['operatorID']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['operatorID'];
      }
      if (this.items[i]['operatorUserName']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['operatorUserName'];
      }
      if (this.items[i]['hasOperatorAccount']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['hasOperatorAccount'];
      }
      if (this.items[i]['Name']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['Name'];
      }
      if (this.items[i]['FirstName']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['FirstName'];
      }
      if (this.items[i]['MiddleName']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['MiddleName'];
      }
      if (this.items[i]['CalculatedName']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['CalculatedName'];
      }

      if (this.items[i]['mobiles.Number']) {
        this.items[i]['fullName'] =
          this.items[i]['fullName'] +
          ' ' +
          this.customPhonePipe.transform(this.items[i]['mobiles.Number'], 'none').replace(/ /g, '');
      }
      if (this.items[i]['emails.Email']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['emails.Email'];
      }
      if (this.items[i]['addresses.Calculated']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['addresses.Calculated'];
      }
      if (this.items[i]['ContactType.Label']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['ContactType.Label'];
      }
      if (this.items[i]['ContactType.Code']) {
        this.items[i]['fullName'] = this.items[i]['fullName'] + ' ' + this.items[i]['ContactType.Code'];
      }

      if (this.items[i]['phones.Number']) {
        this.items[i]['fullName'] =
          this.items[i]['fullName'] +
          ' ' +
          this.customPhonePipe.transform(this.items[i]['phones.Number'], 'landLine').replace(/ /g, '');
      }
    }
  }

  ngOnInit() {}

  ngOnDestroy() {
    if (this.serviceRef) {
      this.serviceRef.unsubscribe();
    }
  }
}

export class RecordDataSource extends DataSource<any> {
  _filterChange = new BehaviorSubject('');
  field = '';

  get filter(): any {
    return this._filterChange.value;
  }

  set filter(item: any) {
    this.field = item.field;
    this._filterChange.next(item.value);
  }

  set replaceItem(item) {
    const _index = this._tableDatabase.data.findIndex((_obj) => _obj.ID == item.ID);
    this._tableDatabase.data[_index] = item;
    this._tableDatabase.dataChange.next(this._tableDatabase.data);
  }

  set replaceIsAdminFlag(item) {
    const _index = this._tableDatabase.data.findIndex((_obj) => _obj.ID == item.contactID);

    this._tableDatabase.data[_index]['IsAdmin'] = item.isAdmin;
    this._tableDatabase.dataChange.next(this._tableDatabase.data);
  }

  filteredData: any[] = [];

  constructor(private _tableDatabase: LoadRecords, private _paginator: MatPaginator, private _sort: MatSort) {
    super();
    this._filterChange.subscribe(() => (this._paginator.pageIndex = 0));
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<any[]> {
    const displayDataChanges = [
      this._tableDatabase.dataChange,
      this._filterChange,
      this._paginator.page,
      this._sort.sortChange,
    ];

    return observableMerge(...displayDataChanges).pipe(
      map(() => {
        // Filter data
        this.filteredData = this._tableDatabase.data.slice().filter((item: any) => {
          let f = '';

          f = item[this.field] || '';

          const searchStr = f.toLowerCase();
          return searchStr.indexOf(this.filter.toLowerCase()) != -1;
        });
        const data = this.getSortedData(this.filteredData.slice());
        // Grab the page's slice of data.
        const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
        return data.splice(startIndex, this._paginator.pageSize);
      })
    );
  }

  disconnect() {}

  /** Returns a sorted copy of the database data. */
  getSortedData(data: any[]): any[] {
    // const data = data;
    if (!this._sort.active || this._sort.direction == '') {
      return data;
    }

    return data.sort((a, b) => {
      let propertyA: number | string = '';
      let propertyB: number | string = '';

      switch (this._sort.active) {
        case 'CreatedDate':
          [propertyA, propertyB] = [
            moment(a['DateTimeCreated']).toDate().getTime(),
            moment(b['DateTimeCreated']).toDate().getTime(),
          ];
          break;
        case 'TradingAS':
          [propertyA, propertyB] = [a['TradingAs'], b['TradingAs']];
          break;

        case 'MerchantName':
          [propertyA, propertyB] = [a['ContactForCardName'], b['ContactForCardName']];
          break;

        case 'DentistName':
          [propertyA, propertyB] = [a['CalculatedName'], b['CalculatedName']];
          break;
        case 'DentistMobile':
          [propertyA, propertyB] = [a['mobiles.Number'], b['mobiles.Number']];
          break;
        case 'DentistEmail':
          [propertyA, propertyB] = [a['emails.Email'], b['emails.Email']];
          break;
        case 'DentistPhone':
          [propertyA, propertyB] = [a['phones.Number'], b['phones.Number']];
          break;
        case 'Type':
          [propertyA, propertyB] = [a['ContactType.Label'], b['ContactType.Label']];
          break;
        case 'Login':
          [propertyA, propertyB] = [a['EConnectLogin.Permission'], b['EConnectLogin.Permission']];
          break;
        case 'LastAccess':
          [propertyA, propertyB] = [
            moment(a['EConnectLogin.LastAccessed']).toDate().getTime(),
            moment(b['EConnectLogin.LastAccessed']).toDate().getTime(),
          ];
          break;
      }

      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

      return (valueA < valueB ? -1 : 1) * (this._sort.direction == 'asc' ? 1 : -1);
    });
  }
}
