import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { environment } from 'environments/environment';
import { Observable, of, throwError, timer } from 'rxjs';
import { catchError, first, map, switchMap, take } from 'rxjs/operators';
import { Merchant } from '../../../feature/merchant/shared/types/merchant.type';
import { UtilsService } from '../../../shared/services/utils.service';
import { ClientDetails } from '../../../shared/types/client-details';
import { DataResponse } from '../../../shared/types/data-response.type';
import { SessionType } from '../../../shared/types/session-type.type';
import { Settings } from '../../../shared/types/settings';
import {
  setCurrentDentistAction,
  setCurrentIsPromoterOrAdminAction,
  setCurrentPracticeAction,
  setCurrentPublicModulesAction,
  setCurrentSessionTypeAction,
  setCurrentUserAction,
} from '../../app-state/app-state.actions';
import { selectAppState } from '../../app-state/app-state.selectors';
import { AppStateService } from '../../app-state/app-state.service';
import { User } from './shared/types/authentication.type';

@Injectable()
export class AuthenticationService {
  static serverUnreachable = new EventEmitter();
  static contactUSEvent = new EventEmitter();
  static contactInputEvent = new EventEmitter();

  static displayMessageGroup = new EventEmitter();

  static settlementList = new EventEmitter();

  static newSettlement = new EventEmitter();
  static SMSConversation = new EventEmitter();
  static viewMerchant = new EventEmitter();
  static setTitle = new EventEmitter();
  static updateHealthHistory = new EventEmitter();
  static viewDentist = new EventEmitter();
  static updateDrift = new EventEmitter();
  static setDrift = new EventEmitter();
  static updateProfilePicture = new EventEmitter();

  static updatePracticePicture = new EventEmitter();

  static updatePracticePictureCover = new EventEmitter();

  static loginAsUser = new EventEmitter();

  static customerLogin = new EventEmitter();
  static loginLabel = new EventEmitter();
  static profilePic = null;
  static practicePic = null;
  static stopWakandaCountDown = new EventEmitter();
  static appoinmentAccess = new EventEmitter();
  static healthHistoryAccess = new EventEmitter();

  static clearCookie: EventEmitter<any> = new EventEmitter<any>();
  static getDisplayInfoToolTip: EventEmitter<any> = new EventEmitter<any>();
  static updateToolTip: EventEmitter<any> = new EventEmitter<any>();
  static loginEvent: EventEmitter<any> = new EventEmitter<any>();
  static signupObject: EventEmitter<any> = new EventEmitter<any>();
  static refreshSignup: EventEmitter<any> = new EventEmitter<any>();

  private baseUrl = environment.nodeUrl;

  private loginUrlTwoStep: string = environment.nodeUrl + '/auth/signin/two-step';
  private currentUser: any;
  private currentfinancedProductGroups;
  private currentPractice: any;
  private currentDentist: any;
  private authenticationMethod;
  promoter = null;
  private userUrl: string = environment.nodeUrl + '/current';
  private logoutUrl: string = environment.nodeUrl + '/auth/signout';

  timerOut = null;
  timerCountDown = null;
  reviceCountDown = null;
  subscriptionTimerOut = null;
  subscriptionTimerCountDown = null;
  subscriptionReviveCountDown = null;
  isLoggedIn = false;
  public redirectUrl: string;
  public redirectParams: string;

  public sendTimeOut = new EventEmitter<any>();
  public sendCountDown = new EventEmitter<any>();
  public sendReviveSession = new EventEmitter<any>();

  public syncPlan = new EventEmitter<any>();
  syncPlanCountDown = null;
  subscriptionsyncPlanCountDown = null;

  public syncModule = new EventEmitter<any>();
  syncModuleCountDown = null;
  subscriptionsyncModuleCountDown = null;

  private currentPlanID;
  private currentPlanTransactionID;

  constructor(private httpClient: HttpClient, private store: Store, private appStateService: AppStateService) {}

  isModuleOwn(code): Observable<any> {
    if (code) {
      if (UtilsService.currentAcccess && UtilsService.currentAcccess.type == 'user') {
        let name = code;

        name = name.replace('isModule', '');
        name = name.replace('Active', '');

        if (name.length > 0) {
          name = name[0].toUpperCase() + name.substr(1);
        }

        name = 'isModule' + name + 'Active';

        if (name && UtilsService.currentAcccess && UtilsService.currentAcccess[name] === true) {
          return of(true);
        } else {
          return of(false);
        }
      } else {
        return this.httpClient.get(environment.nodeUrl + '/module-user-access/access/current').pipe(
          map((res: HttpResponse<any>) => {
            const result = res['data'] || null;

            if (result && result.type == 'user') {
              UtilsService.currentAcccess = result;

              let name = code;

              name = name.replace('isModule', '');
              name = name.replace('Active', '');

              if (name.length > 0) {
                name = name[0].toUpperCase() + name.substr(1);
              }

              name = 'isModule' + name + 'Active';

              if (name && UtilsService.currentAcccess && UtilsService.currentAcccess[name] === true) {
                return true;
              } else {
                return false;
              }
            } else {
              let name = code;

              name = name.replace('isModule', '');
              name = name.replace('Active', '');

              if (name.length > 0) {
                name = name[0].toUpperCase() + name.substr(1);
              }

              name = 'isModule' + name + 'Active';

              if (name && result && result[name] === true) {
                return true;
              } else {
                return false;
              }
            }
          }),
          catchError((error: any): Observable<any> => {
            return null;
          })
        );
      }
    } else {
      return of(false);
    }
  }

  getCurrentPlan(): Observable<any> {
    if (this.currentPlanID) {
      return of(this.currentPlanID);
    } else if (
      this.currentUser &&
      this.currentUser !== 'none' &&
      this.currentUser.data &&
      this.currentUser.data.subscriptionPlanID
    ) {
      this.currentPlanID = this.currentUser.data.subscriptionPlanID;
      return of(this.currentPlanID);
    } else {
      return this.httpClient.get(environment.nodeUrl + '/subscription-plan-merchant/current').pipe(
        map((res: HttpResponse<any>) => {
          const result = res['data'] || null;

          if (result) {
            this.currentPlanID = result;
          }
          return result;
        }),
        catchError((error: any): Observable<any> => {
          return null;
        })
      );
    }
  }

  getCurrentPlanTransactionID(): Observable<any> {
    if (this.currentPlanTransactionID) {
      return of(this.currentPlanTransactionID);
    } else if (
      this.currentUser &&
      this.currentUser !== 'none' &&
      this.currentUser.data &&
      this.currentUser.data.subscriptionPlanTransactionID
    ) {
      this.currentPlanTransactionID = this.currentUser.data.subscriptionPlanTransactionID;
      return of(this.currentPlanTransactionID);
    } else {
      return this.httpClient.get(environment.nodeUrl + '/subscription-plan-merchant/transaction-current').pipe(
        map((res: HttpResponse<any>) => {
          const result = res['data'] || null;

          if (result) {
            this.currentPlanTransactionID = result;
          }
          return result;
        }),
        catchError((error: any): Observable<any> => {
          return null;
        })
      );
    }
  }

  setCurrentPlanTransactionID(id) {
    if (id) {
      this.currentPlanTransactionID = id;
    }
  }

  setCurrentPlan(id) {
    if (id) {
      this.currentPlanID = id;
    }
  }

  getCurrentPlanForce(hidden = false, erace = true): Observable<any> {
    let url = '/subscription-plan-merchant/current';

    if (hidden == true) {
      url = '/subscription-plan-merchant/current-hidden';
    }

    return this.httpClient.get(environment.nodeUrl + url).pipe(
      map((res: HttpResponse<any>) => {
        const result = res['data'] || null;

        if (result && erace == true) {
          this.currentPlanID = result;
        }

        return result;
      }),
      catchError((error: any): Observable<any> => {
        return null;
      })
    );
  }

  getCurrentPlanTransactionForce(hidden = false, erace = true): Observable<any> {
    let url = '/subscription-plan-merchant/transaction-current';

    if (hidden == true) {
      url = '/subscription-plan-merchant/transaction-current-hidden';
    }

    return this.httpClient.get(environment.nodeUrl + url).pipe(
      map((res: HttpResponse<any>) => {
        const result = res['data'] || null;

        if (result && erace == true) {
          this.currentPlanTransactionID = result;
        }

        return result;
      }),
      catchError((error: any): Observable<any> => {
        return null;
      })
    );
  }

  getCurrentForceAccess(hidden = false): Observable<any> {
    let url = '/module-user-access/access/current';

    if (hidden == true) {
      url = '/module-user-access/access/current-hidden';
    }

    return this.httpClient.get(environment.nodeUrl + url).pipe(
      map((res: HttpResponse<any>) => {
        const result = res['data'] || null;

        if (result && result.type == 'user') {
          UtilsService.currentAcccess = result;
        }
        return result;
      }),
      catchError((error: any): Observable<any> => {
        return null;
      })
    );
  }

  getCurrentAccess(): Observable<any> {
    if (this.currentUser === 'none' && UtilsService.currentAcccess && UtilsService.currentAcccess.type == 'guest') {
      this.store.dispatch(setCurrentPublicModulesAction({ publicModules: UtilsService.currentAcccess }));
      return of(UtilsService.currentAcccess);
    } else if (
      this.currentUser &&
      this.currentUser !== 'none' &&
      UtilsService.currentAcccess &&
      UtilsService.currentAcccess.type == 'user'
    ) {
      this.store.dispatch(setCurrentPublicModulesAction({ publicModules: UtilsService.currentAcccess }));
      return of(UtilsService.currentAcccess);
    } else {
      return this.httpClient.get(environment.nodeUrl + '/module-user-access/access/current').pipe(
        map((res: HttpResponse<any>) => {
          const result = res['data'] || null;

          if (result) {
            UtilsService.currentAcccess = result;
            this.store.dispatch(setCurrentPublicModulesAction({ publicModules: UtilsService.currentAcccess }));
          }
          return result;
        }),
        catchError((error: any): Observable<any> => {
          return null;
        })
      );
    }
  }

  getTest(): Observable<any> {
    const result = this.httpClient.get(environment.nodeUrl + '/invitations/1225');

    return result;
  }

  getPromoter() {
    if (this.promoter != null) {
      return new Observable<any>((observer) => {
        observer.next(this.promoter);
      });
    } else {
      return this.httpClient.get(environment.nodeUrl + '/promoter').pipe(
        map((res) => {
          this.promoter = res['data'];
          return res['data'];
        })
      );
    }
  }

  getPublicInvitationByWakandaDetails(id) {
    return this.httpClient
      .get(`${environment.nodeUrl}/invitation/public-wakanda/${id}`, {})
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getPublicInvitationDetails(id) {
    return this.httpClient
      .get(`${environment.nodeUrl}/invitation/public/${id}`, {})
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getPublicAppointmentDetails(id) {
    return this.httpClient
      .get(`${environment.nodeUrl}/appointment/public/${id}`, {})
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getPublicInvitationDetailsByWakanda(id) {
    return this.httpClient
      .get(`${environment.nodeUrl}/invitation/public-wakanda/${id}`, {})
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getPublicMedicalInvitationDetails(id) {
    return this.httpClient
      .get(`${environment.nodeUrl}/invitation-communication/public/${id}`, {})
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  editProfile(payload = {}) {
    return this.httpClient
      .put(`${environment.nodeUrl}/current/profile`, payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  practiceLandingPageOneByMerchant(id, payload) {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }
    return this.httpClient
      .get(`${environment.nodeUrl}/merchantLandingPageCode/merchant/${id}`, options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  fetchCurrentUser$() {
    return this.store.select(selectAppState).pipe(
      take(1),
      switchMap(() => {
        if (this.currentUser && this.currentUser.data) {
          this.getCurrentTimeZone();
          this.store.dispatch(setCurrentUserAction({ user: this.currentUser }));
          return of(this.currentUser);
        } else {
          return this.httpClient.get(this.userUrl).pipe(
            map((response) => {
              if (!response || (response && !response['data'])) {
                this.resetCurrentTimeZone();
                ClientDetails.getTimeZoneUTC();

                this.currentUser = 'none';
                this.store.dispatch(setCurrentUserAction({ user: null }));

                return null;
              } else {
                this.currentUser = response;
                this.getCurrentTimeZone();
                this.applyTimer();
                this.store.dispatch(setCurrentUserAction({ user: this.currentUser }));
                return this.currentUser;
              }
            }),
            catchError((error: any): Observable<any> => {
              return throwError(error);
            })
          );
        }
      })
    );
  }

  getCurrentUser(): Observable<any> {
    if (this.currentUser === 'none') {
      this.store.dispatch(setCurrentUserAction({ user: null }));
      return of(null);
    } else if (this.currentUser && this.currentUser.data) {
      this.getCurrentTimeZone();
      this.store.dispatch(setCurrentUserAction({ user: this.currentUser }));
      return of(this.currentUser);
    } else {
      return this.httpClient.get(this.userUrl).pipe(
        map((response) => {
          if (!response || (response && !response['data'])) {
            this.resetCurrentTimeZone();
            ClientDetails.getTimeZoneUTC();

            this.currentUser = 'none';
            this.store.dispatch(setCurrentUserAction({ user: null }));

            return null;
          } else {
            this.currentUser = response;

            this.getCurrentTimeZone();

            this.applyTimer();
            this.store.dispatch(setCurrentUserAction({ user: this.currentUser }));
            return this.currentUser;
          }
        }),
        catchError((error: any): Observable<any> => {
          return throwError(error);
        })
      );
    }
  }

  getCurrentUserForce(hidden = false): Observable<any> {
    let url = environment.nodeUrl + '/current';

    if (hidden == true) {
      url = environment.nodeUrl + '/current/hidden';
    }

    return this.httpClient.get(url).pipe(
      map((res: HttpResponse<any>) => {
        const _res = res;

        if (!_res || (_res && !_res['data'])) {
          this.resetCurrentTimeZone();
          ClientDetails.getTimeZoneUTC();

          this.currentUser = 'none';
          this.store.dispatch(setCurrentUserAction({ user: null }));

          return null;
        }

        UtilsService.currentAcccess = null;
        this.currentPlanID = null;
        this.currentUser = null;
        this.currentfinancedProductGroups = null;
        this.authenticationMethod = null;
        this.currentPractice = null;
        this.currentDentist = null;

        AuthenticationService.profilePic = null;
        AuthenticationService.practicePic = null;

        localStorage.setItem('selectedMerchant', null);
        localStorage.removeItem('selectedMerchant');

        AuthenticationService.clearCookie.emit(true);

        this.resetCurrentTimeZone();
        this.currentUser = res;
        this.store.dispatch(setCurrentUserAction({ user: this.currentUser }));

        this.applyTimer();
        this.getCurrentTimeZone();
        this.store.dispatch(setCurrentUserAction({ user: this.currentUser }));
        return this.currentUser;
      }),
      catchError((error: any): Observable<any> => {
        return throwError(error);
      })
    );
  }

  editCurrentUser(data): Observable<any> {
    return this.httpClient.put(`${environment.nodeUrl}/current/profile`, data).pipe(
      map((res: HttpResponse<any>) => {
        if (res && res['data']) {
          this.currentUser = res;
          this.getCurrentTimeZone();
          this.store.dispatch(setCurrentUserAction({ user: this.currentUser }));
          return this.currentUser;
        } else {
          return null;
        }
      })
    );
  }

  updateCurrentUserLocal(data) {
    if (data && this.currentUser && this.currentUser !== 'none') {
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          this.currentUser[key] = data[key];
          this.store.dispatch(setCurrentUserAction({ user: this.currentUser }));
        }
      }
    }
  }

  setCurrentUser(user) {
    if (user && user.data) {
      this.currentUser = user;
      this.currentUser.success = true;
      this.store.dispatch(setCurrentUserAction({ user: this.currentUser }));
      this.getCurrentTimeZone();
    } else if (user) {
      this.currentUser = {
        data: user,
        success: true,
      };
      this.store.dispatch(setCurrentUserAction({ user: this.currentUser }));
      this.getCurrentTimeZone();
    }
  }

  fetchCurrentSessionType$(): Observable<SessionType> {
    return this.appStateService.getAppState$().pipe(
      take(1),
      switchMap((appState) => {
        if (appState.user) {
          const role = appState.user.data.Role;
          switch (role) {
            case 'admin':
              this.store.dispatch(setCurrentSessionTypeAction({ sessionType: 'admin' }));
              return of<SessionType>('admin').pipe(first());

            case 'promoter':
              this.store.dispatch(setCurrentSessionTypeAction({ sessionType: 'admin' }));
              return of<SessionType>('admin').pipe(first());

            case 'merchant':
              this.store.dispatch(setCurrentSessionTypeAction({ sessionType: 'merchant' }));
              return of<SessionType>('merchant').pipe(first());

            case 'merchant-admin':
              this.store.dispatch(setCurrentSessionTypeAction({ sessionType: 'merchant' }));
              return of<SessionType>('merchant').pipe(first());

            case 'supplier':
              this.store.dispatch(setCurrentSessionTypeAction({ sessionType: 'supplier' }));
              return of<SessionType>('supplier').pipe(first());

            case 'funder':
              this.store.dispatch(setCurrentSessionTypeAction({ sessionType: 'funder' }));
              return of<SessionType>('funder').pipe(first());

            case 'customer':
              this.store.dispatch(setCurrentSessionTypeAction({ sessionType: 'customer' }));
              return of<SessionType>('customer').pipe(first());

            case 'consumer':
              this.store.dispatch(setCurrentSessionTypeAction({ sessionType: 'customer' }));
              return of<SessionType>('customer').pipe(first());

            default:
              this.store.dispatch(setCurrentSessionTypeAction({ sessionType: 'guest' }));
              return of<SessionType>('guest').pipe(first());
          }
        } else {
          return this.httpClient.get(this.userUrl).pipe(
            map((response: DataResponse<User> | 'none') => {
              if (response === 'none') {
                this.store.dispatch(setCurrentSessionTypeAction({ sessionType: 'guest' }));
                return 'guest';
              }

              if (!response || (response && !response.data)) {
                this.store.dispatch(setCurrentSessionTypeAction({ sessionType: 'guest' }));
                return 'guest';
              }

              this.getCurrentTimeZone();

              let role;

              if (response && response.success && response.data['Role'] && response.data['Role']) {
                role = response.data['Role'];

                switch (role) {
                  case 'admin':
                    this.store.dispatch(setCurrentSessionTypeAction({ sessionType: 'admin' }));
                    return 'admin';

                  case 'promoter':
                    this.store.dispatch(setCurrentSessionTypeAction({ sessionType: 'admin' }));
                    return 'admin';

                  case 'merchant':
                    this.store.dispatch(setCurrentSessionTypeAction({ sessionType: 'merchant' }));
                    return 'merchant';

                  case 'merchant-admin':
                    this.store.dispatch(setCurrentSessionTypeAction({ sessionType: 'merchant' }));
                    return 'merchant';

                  case 'supplier':
                    this.store.dispatch(setCurrentSessionTypeAction({ sessionType: 'supplier' }));
                    return 'supplier';

                  case 'funder':
                    this.store.dispatch(setCurrentSessionTypeAction({ sessionType: 'funder' }));
                    return 'funder';

                  case 'customer':
                    this.store.dispatch(setCurrentSessionTypeAction({ sessionType: 'customer' }));
                    return 'customer';

                  case 'consumer':
                    this.store.dispatch(setCurrentSessionTypeAction({ sessionType: 'consumer' }));
                    return 'customer';

                  default:
                    this.store.dispatch(setCurrentSessionTypeAction({ sessionType: 'guest' }));
                    return 'guest';
                }
              } else {
                this.store.dispatch(setCurrentSessionTypeAction({ sessionType: 'guest' }));
                return 'guest';
              }
            }),
            catchError((): Observable<any> => {
              return throwError('guest');
            })
          );
        }
      })
    );
  }

  getSessionType(): Observable<SessionType> {
    if (this.currentUser === 'none') {
      return of('guest');
    } else if (this.currentUser && this.currentUser !== 'none') {
      const role = this.currentUser.data['Role'];

      switch (role) {
        case 'admin':
          this.store.dispatch(setCurrentSessionTypeAction({ sessionType: 'admin' }));
          return of<SessionType>('admin').pipe(first());

        case 'promoter':
          this.store.dispatch(setCurrentSessionTypeAction({ sessionType: 'admin' }));
          return of<SessionType>('admin').pipe(first());

        case 'merchant':
          this.store.dispatch(setCurrentSessionTypeAction({ sessionType: 'merchant' }));
          return of<SessionType>('merchant').pipe(first());

        case 'merchant-admin':
          this.store.dispatch(setCurrentSessionTypeAction({ sessionType: 'merchant' }));
          return of<SessionType>('merchant').pipe(first());

        case 'supplier':
          this.store.dispatch(setCurrentSessionTypeAction({ sessionType: 'supplier' }));
          return of<SessionType>('supplier').pipe(first());

        case 'funder':
          this.store.dispatch(setCurrentSessionTypeAction({ sessionType: 'funder' }));
          return of<SessionType>('funder').pipe(first());

        case 'customer':
          this.store.dispatch(setCurrentSessionTypeAction({ sessionType: 'customer' }));
          return of<SessionType>('customer').pipe(first());

        case 'consumer':
          this.store.dispatch(setCurrentSessionTypeAction({ sessionType: 'customer' }));
          return of<SessionType>('customer').pipe(first());

        default:
          this.store.dispatch(setCurrentSessionTypeAction({ sessionType: 'guest' }));
          return of<SessionType>('guest').pipe(first());
      }
    } else {
      return this.httpClient.get<DataResponse>(this.userUrl).pipe(
        map((response) => {
          if (!response || (response && !response.data)) {
            this.currentUser = 'none';
            return 'guest';
          }

          this.currentUser = response;
          this.getCurrentTimeZone();

          let role;

          if (
            this.currentUser &&
            this.currentUser !== 'none' &&
            this.currentUser.success &&
            this.currentUser.data['Role'] &&
            this.currentUser.data['Role']
          ) {
            role = this.currentUser.data['Role'];

            switch (role) {
              case 'admin':
                this.store.dispatch(setCurrentSessionTypeAction({ sessionType: 'admin' }));
                return 'admin';

              case 'promoter':
                this.store.dispatch(setCurrentSessionTypeAction({ sessionType: 'admin' }));
                return 'admin';

              case 'merchant':
                this.store.dispatch(setCurrentSessionTypeAction({ sessionType: 'merchant' }));
                return 'merchant';

              case 'merchant-admin':
                this.store.dispatch(setCurrentSessionTypeAction({ sessionType: 'merchant' }));
                return 'merchant';

              case 'supplier':
                this.store.dispatch(setCurrentSessionTypeAction({ sessionType: 'supplier' }));
                return 'supplier';

              case 'funder':
                this.store.dispatch(setCurrentSessionTypeAction({ sessionType: 'funder' }));
                return 'funder';

              case 'customer':
                this.store.dispatch(setCurrentSessionTypeAction({ sessionType: 'customer' }));
                return 'customer';

              case 'consumer':
                this.store.dispatch(setCurrentSessionTypeAction({ sessionType: 'consumer' }));
                return 'customer';

              default:
                this.store.dispatch(setCurrentSessionTypeAction({ sessionType: 'guest' }));
                return 'guest';
            }
          } else {
            return 'guest';
          }
        }),
        catchError((): Observable<any> => {
          this.store.dispatch(setCurrentSessionTypeAction({ sessionType: 'guest' }));
          return throwError('guest');
        })
      );
    }
  }

  getSessionTypeHidden(): Observable<any> {
    if (this.currentUser === 'none') {
      return of('guest');
    } else if (this.currentUser && this.currentUser !== 'none') {
      let role;
      role = this.currentUser.data['Role'];

      if (role == 'admin' || role == 'promoter') {
        this.store.dispatch(setCurrentSessionTypeAction({ sessionType: 'admin' }));
        return of('admin');
      } else if (role == 'merchant') {
        this.store.dispatch(setCurrentSessionTypeAction({ sessionType: 'merchant' }));
        return of('merchant');
      } else if (role == 'merchant-admin') {
        this.store.dispatch(setCurrentSessionTypeAction({ sessionType: 'merchant' }));
        return of('merchant');
      } else if (role == 'supplier') {
        this.store.dispatch(setCurrentSessionTypeAction({ sessionType: 'supplier' }));
        return of('supplier');
      } else if (role == 'funder') {
        this.store.dispatch(setCurrentSessionTypeAction({ sessionType: 'funder' }));
        return of('funder');
      } else if (role == 'customer' || role == 'consumer') {
        this.store.dispatch(setCurrentSessionTypeAction({ sessionType: 'customer' }));
        return of('customer');
      } else {
        this.store.dispatch(setCurrentSessionTypeAction({ sessionType: 'guest' }));
        return of('guest');
      }
    } else {
      return this.httpClient.get(environment.nodeUrl + '/current/hidden').pipe(
        map((res: HttpResponse<any>) => {
          const _res = res;

          if (!_res || (_res && !_res['data'])) {
            this.currentUser = 'none';
            return 'guest';
          }
          this.currentUser = _res;
          this.getCurrentTimeZone();

          let role;
          if (
            this.currentUser &&
            this.currentUser !== 'none' &&
            this.currentUser.success &&
            this.currentUser.data['Role'] &&
            this.currentUser.data['Role']
          ) {
            role = this.currentUser.data['Role'];

            if (role == 'admin' || role == 'promoter') {
              this.store.dispatch(setCurrentSessionTypeAction({ sessionType: 'admin' }));
              return 'admin';
            } else if (role == 'merchant' || role == 'merchant-admin') {
              this.store.dispatch(setCurrentSessionTypeAction({ sessionType: 'merchant' }));
              return 'merchant';
            } else if (role == 'funder') {
              this.store.dispatch(setCurrentSessionTypeAction({ sessionType: 'funder' }));
              return 'funder';
            } else if (role == 'supplier') {
              this.store.dispatch(setCurrentSessionTypeAction({ sessionType: 'supplier' }));
              return 'supplier';
            } else if (role == 'customer') {
              this.store.dispatch(setCurrentSessionTypeAction({ sessionType: 'customer' }));
              return 'customer';
            } else if (role == 'consumer') {
              this.store.dispatch(setCurrentSessionTypeAction({ sessionType: 'consumer' }));
              return 'consumer';
            } else {
              this.store.dispatch(setCurrentSessionTypeAction({ sessionType: 'guest' }));
              return 'guest';
            }
          } else {
            this.store.dispatch(setCurrentSessionTypeAction({ sessionType: 'guest' }));
            return 'guest';
          }
        }),
        catchError((error: any): Observable<any> => {
          return throwError('guest');
        })
      );
    }
  }

  isLogin(): Observable<any> {
    if (this.currentUser === 'none') {
      return of(false);
    } else if (
      this.currentUser &&
      this.currentUser !== 'none' &&
      this.currentUser.data &&
      this.currentUser.data['Role'] != 'guest'
    ) {
      return of(true);
    } else {
      return this.httpClient.get(this.userUrl).pipe(
        map((res: HttpResponse<any>) => {
          const _res = res;

          if (!_res || (_res && !_res['data'])) {
            return false;
          }

          if (_res && _res['data'] && _res['data']['Role'] !== 'guest') {
            return true;
          } else {
            return false;
          }
        }),
        catchError((error: any): Observable<any> => {
          return of(false);
        })
      );
    }
  }

  fetchIsPromoterOrAdmin$() {
    return this.appStateService.getAppState$().pipe(
      take(1),
      switchMap((appState) => {
        const untypedUser = appState.user as unknown;
        const typedUser = untypedUser as User | 'none';

        if (typedUser === 'none') {
          this.store.dispatch(setCurrentIsPromoterOrAdminAction({ isPromoterOrAdmin: false }));
          return of(false);
        }

        if (appState.user) {
          const role = appState.user.data['Role'];

          if (role === 'admin' || role === 'promoter') {
            this.store.dispatch(setCurrentIsPromoterOrAdminAction({ isPromoterOrAdmin: true }));
            return of(true);
          } else {
            this.store.dispatch(setCurrentIsPromoterOrAdminAction({ isPromoterOrAdmin: false }));
            return of(false);
          }
        } else {
          return this.httpClient.get(this.userUrl).pipe(
            map((response: DataResponse) => {
              if (!response || (response && !response['data'])) {
                this.store.dispatch(setCurrentUserAction({ user: 'none' }));
                this.store.dispatch(setCurrentIsPromoterOrAdminAction({ isPromoterOrAdmin: false }));
                return false;
              }

              const untypedResponse = response as unknown;
              const typedResponse = untypedResponse as User;

              this.store.dispatch(setCurrentUserAction({ user: typedResponse }));
              this.currentUser = response;
              this.getCurrentTimeZone();

              this.applyTimer();

              let role;

              if (
                this.currentUser &&
                this.currentUser !== 'none' &&
                this.currentUser.success &&
                this.currentUser.data['Role'] &&
                this.currentUser.data['Role']
              ) {
                role = this.currentUser.data['Role'];

                if (role === 'admin' || role === 'promoter') {
                  this.store.dispatch(setCurrentIsPromoterOrAdminAction({ isPromoterOrAdmin: true }));
                  return true;
                } else {
                  this.store.dispatch(setCurrentIsPromoterOrAdminAction({ isPromoterOrAdmin: false }));
                  return false;
                }
              } else {
                this.store.dispatch(setCurrentIsPromoterOrAdminAction({ isPromoterOrAdmin: false }));
                return false;
              }
            }),
            catchError((error: any): Observable<any> => {
              this.store.dispatch(setCurrentIsPromoterOrAdminAction({ isPromoterOrAdmin: false }));
              return throwError(false);
            })
          );
        }
      })
    );
  }

  isPromoterOrAdmin(): Observable<boolean> {
    if (this.currentUser === 'none') {
      this.store.dispatch(setCurrentIsPromoterOrAdminAction({ isPromoterOrAdmin: false }));
      return of(false);
    } else if (this.currentUser && this.currentUser !== 'none') {
      const role = this.currentUser.data['Role'];

      if (role === 'admin' || role === 'promoter') {
        this.store.dispatch(setCurrentIsPromoterOrAdminAction({ isPromoterOrAdmin: true }));
        return of(true);
      } else {
        this.store.dispatch(setCurrentIsPromoterOrAdminAction({ isPromoterOrAdmin: false }));
        return of(false);
      }
    } else {
      return this.httpClient.get(this.userUrl).pipe(
        map((response: DataResponse) => {
          if (!response || (response && !response['data'])) {
            this.currentUser = 'none';
            this.store.dispatch(setCurrentUserAction({ user: null }));
            this.store.dispatch(setCurrentIsPromoterOrAdminAction({ isPromoterOrAdmin: false }));
            return false;
          }

          this.currentUser = response;
          const untypedUser = response as unknown;
          const typedUser = untypedUser as User;
          this.store.dispatch(setCurrentUserAction({ user: typedUser }));
          this.getCurrentTimeZone();

          this.applyTimer();

          let role;

          if (
            this.currentUser &&
            this.currentUser !== 'none' &&
            this.currentUser.success &&
            this.currentUser.data['Role'] &&
            this.currentUser.data['Role']
          ) {
            role = this.currentUser.data['Role'];

            if (role === 'admin' || role === 'promoter') {
              this.store.dispatch(setCurrentIsPromoterOrAdminAction({ isPromoterOrAdmin: true }));
              return true;
            } else {
              this.store.dispatch(setCurrentIsPromoterOrAdminAction({ isPromoterOrAdmin: false }));
              return false;
            }
          } else {
            this.store.dispatch(setCurrentIsPromoterOrAdminAction({ isPromoterOrAdmin: true }));
            return false;
          }
        }),
        catchError((error: any): Observable<any> => {
          this.store.dispatch(setCurrentIsPromoterOrAdminAction({ isPromoterOrAdmin: false }));
          return throwError(false);
        })
      );
    }
  }

  isCustomer(): Observable<any> {
    if (this.currentUser === 'none') {
      return of(false);
    } else if (this.currentUser && this.currentUser !== 'none' && this.currentUser.data) {
      let role;
      role = this.currentUser.data['Role'];
      if (role == 'customer') {
        return of(true);
      } else {
        return of(false);
      }
    } else {
      return this.httpClient.get(this.userUrl).pipe(
        map((res: HttpResponse<any>) => {
          const _res = res;

          if (!_res || (_res && !_res['data'])) {
            this.currentUser = 'none';
            return false;
          }

          this.currentUser = res;

          this.getCurrentTimeZone();

          this.applyTimer();

          let role;
          if (
            this.currentUser &&
            this.currentUser !== 'none' &&
            this.currentUser.success &&
            this.currentUser.data['Role'] &&
            this.currentUser.data['Role']
          ) {
            role = this.currentUser.data['Role'];
            if (role == 'customer') {
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        }),
        catchError((error: any): Observable<any> => {
          return throwError(error);
        })
      );
    }
  }

  getCardDetails(ID, tableName, payload = {}, sessionType): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (tableName === 'Contact') {
      if (sessionType == 'admin' || sessionType == 'promoter') {
        return this.httpClient.get(environment.nodeUrl + '/contact/' + ID, options).pipe(
          map((_res: HttpResponse<any>) => {
            const res = _res;
            if (res && res['data']) {
              res['data']['TableName'] = tableName;
              res['data']['tableName'] = tableName;
            }

            return res['data'];
          })
        );
      } else {
        return this.httpClient.get(environment.nodeUrl + '/contact/public/' + ID, options).pipe(
          map((_res: HttpResponse<any>) => {
            const res = _res;
            if (res && res['data']) {
              res['data']['TableName'] = tableName;
              res['data']['tableName'] = tableName;
            }

            return res['data'];
          })
        );
      }
    } else if (tableName === 'Merchant' || tableName === 'Promoter') {
      if (sessionType == 'admin' || sessionType == 'promoter') {
        return this.httpClient.get(environment.nodeUrl + '/merchant/' + ID, options).pipe(
          map((_res: HttpResponse<any>) => {
            const res = _res;
            if (res && res['data']) {
              res['data']['TableName'] = tableName;
              res['data']['tableName'] = tableName;
            }

            return res['data'];
          })
        );
      } else {
        return this.httpClient.get(environment.nodeUrl + '/merchant/public/' + ID, options).pipe(
          map((_res: HttpResponse<any>) => {
            const res = _res;
            if (res && res['data']) {
              res['data']['TableName'] = tableName;
              res['data']['tableName'] = tableName;
            }

            return res['data'];
          })
        );
      }
    } else {
      if (sessionType == 'admin' || sessionType == 'promoter') {
        return this.httpClient.get(environment.nodeUrl + '/customer-prospect/global/' + ID, options).pipe(
          map((_res: HttpResponse<any>) => {
            const res = _res;
            if (res && res['data']) {
              res['data']['TableName'] = 'CustomersAndProspects';
              res['data']['tableName'] = 'CustomersAndProspects';
            }

            return res['data'];
          })
        );
      } else if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
        return this.httpClient.get(environment.nodeUrl + '/customer-prospect/' + ID, options).pipe(
          map((_res: HttpResponse<any>) => {
            const res = _res;
            if (res && res['data']) {
              res['data']['TableName'] = 'CustomersAndProspects';
              res['data']['tableName'] = 'CustomersAndProspects';
            }

            return res['data'];
          })
        );
      } else {
        return this.httpClient.get(environment.nodeUrl + '/customer-prospect/public/' + ID, options).pipe(
          map((_res: HttpResponse<any>) => {
            const res = _res;
            if (res && res['data']) {
              res['data']['TableName'] = tableName;
              res['data']['TableName'] = 'CustomersAndProspects';
              res['data']['tableName'] = 'CustomersAndProspects';
            }

            return res['data'];
          })
        );
      }
    }
  }

  isMerchantAdmin(): Observable<any> {
    if (this.currentUser === 'none') {
      return of(false);
    } else if (this.currentUser && this.currentUser !== 'none' && this.currentUser.data) {
      let role;
      role = this.currentUser.data['Role'];
      if (role == 'merchant-admin') {
        return of(true);
      } else {
        return of(false);
      }
    } else {
      return this.httpClient.get(this.userUrl).pipe(
        map((res: HttpResponse<any>) => {
          const _res = res;

          if (!_res || (_res && !_res['data'])) {
            this.currentUser = 'none';
            return false;
          }

          this.currentUser = res;
          this.getCurrentTimeZone();

          this.applyTimer();

          let role;
          if (
            this.currentUser &&
            this.currentUser !== 'none' &&
            this.currentUser.success &&
            this.currentUser.data['Role'] &&
            this.currentUser.data['Role']
          ) {
            role = this.currentUser.data['Role'];
            if (role == 'merchant-admin') {
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        }),
        catchError((error: any): Observable<any> => {
          return throwError(error);
        })
      );
    }
  }

  isAdmin(): Observable<any> {
    if (this.currentUser === 'none') {
      return of(false);
    } else if (this.currentUser && this.currentUser !== 'none' && this.currentUser.data) {
      let role;
      role = this.currentUser.data['Role'];
      if (role == 'admin') {
        return of(true);
      } else {
        return of(false);
      }
    } else {
      return this.httpClient.get(this.userUrl).pipe(
        map((res: HttpResponse<any>) => {
          const _res = res;

          if (!_res || (_res && !_res['data'])) {
            this.currentUser = 'none';
            return false;
          }

          this.currentUser = res;

          this.getCurrentTimeZone();
          this.applyTimer();

          let role;
          if (
            this.currentUser &&
            this.currentUser !== 'none' &&
            this.currentUser.success &&
            this.currentUser.data['Role'] &&
            this.currentUser.data['Role']
          ) {
            role = this.currentUser.data['Role'];
            if (role == 'admin') {
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        }),
        catchError((error: any): Observable<any> => {
          return throwError(error);
        })
      );
    }
  }

  fetchCurrentPractice$() {
    this.store.select(selectAppState).pipe(
      take(1),
      switchMap((appState) => {
        if (appState.practice) {
          return of(appState.practice);
        } else {
          const endpoint = 'current/practice';
          const url = `${this.baseUrl}/${endpoint}`;

          return this.httpClient.get(url).pipe(
            map((response: HttpResponse<DataResponse<Partial<Merchant>>>) => {
              if (response['data'] === 'none') {
                this.currentPractice = null;
              } else {
                this.currentPractice = response['data'];
              }

              this.store.dispatch(setCurrentPracticeAction({ practice: this.currentPractice }));

              return this.currentPractice;
            }),
            catchError((error: any) => {
              return throwError(error);
            })
          );
        }
      })
    );
  }

  getCurrentPractice(): Observable<Partial<Merchant>> {
    if (this.currentPractice === 'none') {
      this.store.dispatch(setCurrentPracticeAction({ practice: null }));
      return of(null);
    }

    const endpoint = 'current/practice';
    const url = `${this.baseUrl}/${endpoint}`;

    if (this.currentPractice) {
      this.store.dispatch(setCurrentPracticeAction({ practice: this.currentPractice }));
      return of(this.currentPractice);
    } else {
      return this.httpClient.get(url).pipe(
        map((response: HttpResponse<DataResponse<Partial<Merchant>>>) => {
          this.currentPractice = response['data'];

          this.store.dispatch(setCurrentPracticeAction({ practice: this.currentPractice }));

          return this.currentPractice;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
    }
  }

  getCurrentPracticeForce() {
    return this.httpClient.get(`${environment.nodeUrl}/current/practice`, {}).pipe(
      map((res: Response) => {
        this.currentPractice = res['data'];

        this.store.dispatch(setCurrentPracticeAction({ practice: this.currentPractice }));

        return this.currentPractice;
      }),
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }

  editCurrentPractice(payload = {}) {
    return this.httpClient.put(`${environment.nodeUrl}/current/practice`, payload).pipe(
      map((res: HttpResponse<any>) => {
        if (res && res['data']) {
          this.currentPractice = res['data'];

          this.store.dispatch(setCurrentPracticeAction({ practice: this.currentPractice }));

          return this.currentPractice;
        } else {
          return null;
        }
      })
    );
  }

  setCurrentPractice(practice) {
    this.currentPractice = practice;
    this.store.dispatch(setCurrentPracticeAction({ practice: this.currentPractice }));
  }

  getCurrentDentist() {
    if (this.currentDentist === 'none') {
      this.store.dispatch(setCurrentDentistAction({ dentist: null }));
      return of(null);
    } else if (this.currentDentist != null) {
      this.store.dispatch(setCurrentDentistAction({ dentist: this.currentDentist }));
      return of(this.currentDentist);
    } else {
      return this.httpClient.get(`${environment.nodeUrl}/current/dentist`).pipe(
        map((res: HttpResponse<any>) => {
          this.currentDentist = res['data'];

          if (this.currentDentist) {
            this.store.dispatch(setCurrentDentistAction({ dentist: this.currentDentist }));
            return this.currentDentist;
          } else {
            this.currentDentist = 'none';
            this.store.dispatch(setCurrentDentistAction({ dentist: null }));
            return null;
          }
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
    }
  }

  getCurrentCard() {
    if (this.currentDentist === 'none') {
      return of(null);
    } else if (this.currentDentist != null && this.currentDentist !== 'none') {
      return of(this.currentDentist);
    } else {
      return this.httpClient.get(`${environment.nodeUrl}/current/card`).pipe(
        map((res: HttpResponse<any>) => {
          this.currentDentist = res['data'];

          if (this.currentDentist) {
            return this.currentDentist;
          } else {
            this.currentDentist = 'none';
            return null;
          }
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
    }
  }

  getCurrentDentistForce() {
    return this.httpClient.get(`${environment.nodeUrl}/current/dentist`, {}).pipe(
      map((res: Response) => {
        this.currentDentist = res['data'];

        return this.currentDentist;
      }),
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }

  loginOauth2(body): Observable<any> {
    return this.httpClient.post(environment.nodeUrl + '/connect/authorize', body).pipe(
      map((res: HttpResponse<any>) => {
        this.initCurrUser();
        return res['data'];
      })
    );
  }

  verifyClientApp(id): Observable<any> {
    return this.httpClient.get(environment.nodeUrl + '/connect/client-verify/' + id, {}).pipe(
      map((res: HttpResponse<any>) => {
        this.initCurrUser();
        return res['data'];
      })
    );
  }

  exchangeTransactionToken(body): Observable<any> {
    return this.httpClient.put(environment.nodeUrl + '/connect/transaction-exchange', body).pipe(
      map((res: HttpResponse<any>) => {
        this.initCurrUser();
        return res['data'];
      })
    );
  }

  login(body, type = 'merchant'): Observable<any> {
    const headers = new HttpHeaders().set('isLogin', 'true');

    if (type == 'merchant') {
      return this.httpClient.post(environment.nodeUrl + '/auth/signin/merchant', body, { headers }).pipe(
        map((res: HttpResponse<any>) => {
          this.initCurrUser();
          return res['data'];
        })
      );
    } else if (type == 'promoter') {
      return this.httpClient.post(environment.nodeUrl + '/auth/signin/promoter', body, { headers }).pipe(
        map((res: HttpResponse<any>) => {
          this.initCurrUser();
          return res['data'];
        })
      );
    } else if (type == 'funder') {
      return this.httpClient.post(environment.nodeUrl + '/auth/signin/funder', body, { headers }).pipe(
        map((res: HttpResponse<any>) => {
          this.initCurrUser();
          return res['data'];
        })
      );
    } else if (type == 'admin') {
      return this.httpClient.post(environment.nodeUrl + '/auth/signin/promoter', body, { headers }).pipe(
        map((res: HttpResponse<any>) => {
          this.initCurrUser();
          return res['data'];
        })
      );
    } else if (type == 'consumer') {
      return this.httpClient.post(environment.nodeUrl + '/auth/signin/consumer', body, { headers }).pipe(
        map((res: HttpResponse<any>) => {
          this.initCurrUser();
          return res['data'];
        })
      );
    } else if (type == 'supplier') {
      return this.httpClient.post(environment.nodeUrl + '/auth/signin/supplier', body, { headers }).pipe(
        map((res: HttpResponse<any>) => {
          this.initCurrUser();
          return res['data'];
        })
      );
    } else {
      return this.httpClient.post(environment.nodeUrl + '/auth/signin', body, { headers }).pipe(
        map((res: HttpResponse<any>) => {
          this.initCurrUser();
          return res['data'];
        })
      );
    }
  }

  loginTwoStep(body): Observable<any> {
    const headers = new HttpHeaders().set('isLogin', 'true');
    return this.httpClient.post(this.loginUrlTwoStep, body, { headers }).pipe(
      map((res: HttpResponse<any>) => {
        this.initCurrUser();
        return res['data'];
      })
    );
  }

  loginAsUser(body): Observable<any> {
    return this.httpClient.post(environment.nodeUrl + `/auth/login-as-user`, body).pipe(
      map((res: HttpResponse<any>) => {
        this.initCurrUser();
        return res['data'];
      })
    );
  }

  loginWithToken(body): Observable<any> {
    return this.httpClient.post(environment.nodeUrl + `/auth/transaction-token`, body).pipe(
      map((res: HttpResponse<any>) => {
        this.initCurrUser();
        return res['data'];
      })
    );
  }

  getModuleListHidden(payload = null, sessionType) {
    let url = 'ep-module/hidden';

    if (sessionType == 'admin' || sessionType == 'promoter') {
      url = 'ep-module/hidden/global';
    }

    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }

    return this.httpClient.get(`${environment.nodeUrl}/${url}`, options).pipe(
      map((res: HttpResponse<any>) => {
        return res['data'];
      })
    );
  }

  getCustomerProspectDetails(ID, payload = {}, sessionType): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.httpClient
        .get(environment.nodeUrl + '/customer-prospect/global/' + ID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (sessionType == 'merchant' || sessionType == 'merchant-admin') {
      return this.httpClient
        .get(environment.nodeUrl + '/customer-prospect/' + ID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .get(environment.nodeUrl + '/customer-prospect/public/' + ID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  applySubscriptionPlanSync() {
    const syncPlanTimer = 1000 * 60 * 10;
    if (this.subscriptionsyncPlanCountDown != null) {
      this.subscriptionsyncPlanCountDown.unsubscribe();
    }

    this.syncPlanCountDown = timer(1000 * 60 * 10, syncPlanTimer);

    this.subscriptionsyncPlanCountDown = this.syncPlanCountDown.subscribe(() => {
      this.syncPlan.emit(true);
    });

    // var syncPlanTimer = 1000 * 15;
    // if (this.subscriptionsyncPlanCountDown != null) {
    //   this.subscriptionsyncPlanCountDown.unsubscribe();
    // }

    // this.syncPlanCountDown = timer(0, syncPlanTimer);

    // this.subscriptionsyncPlanCountDown = this.syncPlanCountDown.subscribe(() => {
    //   this.syncPlan.emit(true)

    // })
  }

  applyModyleSync() {
    // var syncModuleTimer = 1000 * 60 * 60;
    const syncModuleTimer = 1000 * 60 * 30;
    if (this.subscriptionsyncModuleCountDown != null) {
      this.subscriptionsyncModuleCountDown.unsubscribe();
    }

    this.syncModuleCountDown = timer(1000 * 60 * 30, syncModuleTimer);

    this.subscriptionsyncModuleCountDown = this.syncModuleCountDown.subscribe(() => {
      this.syncModule.emit(true);
    });
  }

  applyReviver() {
    const reviveSessionTime = 1000 * 60 * 10;

    if (this.subscriptionReviveCountDown != null) {
      this.subscriptionReviveCountDown.unsubscribe();
    }

    this.reviceCountDown = timer(1000 * 60 * 10, reviveSessionTime);

    this.subscriptionReviveCountDown = this.reviceCountDown.subscribe(() => {
      this.sendReviveSession.emit(true);
    });
  }

  applyTimer() {
    if (this.currentUser && this.currentUser !== 'none' && this.currentUser.data && this.currentUser.data) {
      this.applyReviver();
      this.applySubscriptionPlanSync();
      this.applyModyleSync();
      const timeout = this.currentUser.data.expiredLeftTime || 0;
      let counterdown = 0;
      if (timeout - 60000 > 0) {
        counterdown = timeout - 60000;
      }

      if (this.subscriptionTimerOut != null) {
        this.subscriptionTimerOut.unsubscribe();
      }

      if (this.subscriptionTimerCountDown != null) {
        this.subscriptionTimerCountDown.unsubscribe();
      }

      this.timerOut = timer(timeout);

      this.timerCountDown = timer(counterdown);

      this.subscriptionTimerOut = this.timerOut.subscribe(() => {
        this.sendTimeOut.emit(true);
      });

      this.subscriptionTimerCountDown = this.timerCountDown.subscribe(() => {
        this.sendCountDown.emit(true);
      });
    }
  }

  logout(): Observable<any> {
    return this.httpClient.post(this.logoutUrl, {}).pipe(
      map((res: HttpResponse<any>) => {
        const result = res;
        UtilsService.currentAcccess = null;
        this.currentPlanID = null;
        this.currentUser = 'none';

        this.authenticationMethod = null;
        this.currentPractice = 'none';
        this.currentDentist = 'none';

        AuthenticationService.profilePic = null;
        AuthenticationService.practicePic = null;
        localStorage.setItem('sessionId', null);
        localStorage.removeItem('sessionId');

        this.resetCurrentTimeZone();
        localStorage.setItem('selectedMerchant', null);
        localStorage.removeItem('selectedMerchant');

        ClientDetails.accessToken = null;
        AuthenticationService.loginEvent.emit(true);
        AuthenticationService.clearCookie.emit(true);
        this.store.dispatch(setCurrentPracticeAction({ practice: 'none' }));
        this.store.dispatch(setCurrentUserAction({ user: 'none' }));
        this.store.dispatch(setCurrentSessionTypeAction({ sessionType: 'guest' }));
        this.store.dispatch(setCurrentIsPromoterOrAdminAction({ isPromoterOrAdmin: false }));
        return result;
      }),
      catchError((error: any) => throwError(error))
    );
  }

  initCurrUser() {
    this.currentPlanID = null;
    this.currentUser = null;

    this.currentfinancedProductGroups = null;
    this.authenticationMethod = null;
    this.currentPractice = null;
    this.currentDentist = null;
    UtilsService.currentAcccess = null;
    this.resetCurrentTimeZone();
  }

  requestConsumerCode(id): Observable<any> {
    return this.httpClient
      .post(environment.nodeUrl + `/customer-prospect/request-code/${id}`, {})
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  resetPicture() {
    AuthenticationService.profilePic = null;
    AuthenticationService.practicePic = null;
  }

  refreshAlive() {
    return this.httpClient.put(`${environment.nodeUrl}/current/session/alive`, null).pipe(
      map((res: HttpResponse<any>) => {
        this.applyReviver();
        return res;
      })
    );
  }

  refreshAliveHidden() {
    return this.httpClient.put(`${environment.nodeUrl}/current/session/alive-hidden`, null).pipe(
      map((res: HttpResponse<any>) => {
        this.applyReviver();
        return res;
      })
    );
  }

  refreshSession() {
    return this.httpClient.post(`${environment.nodeUrl}/current/session/refresh`, null).pipe(
      map((res: HttpResponse<any>) => {
        return res;
      })
    );
  }

  forgotPassword(body) {
    return this.httpClient
      .post(environment.nodeUrl + '/auth/password/forgot/', body)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  resetPassword(body) {
    return this.httpClient
      .post(environment.nodeUrl + '/auth/password/reset/', body)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  checkToken(body) {
    return this.httpClient
      .post(environment.nodeUrl + '/auth/check/token/', body)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  checkUserEmail(payload): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };
    return this.httpClient
      .get(environment.nodeUrl + '/auth/password/forgot', options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  isEPmaster(): Observable<any> {
    const options = {};

    return this.httpClient
      .get(environment.nodeUrl + '/settings/is-master', options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  signUpConsumer(payload) {
    return this.httpClient
      .post(`${environment.nodeUrl}/auth/signup-consumer`, payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  requestCode() {
    return this.httpClient
      .put(`${environment.nodeUrl}/current/request-code`, {})
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  checkPassword(password) {
    const payload = {
      password,
    };
    return this.httpClient
      .put(`${environment.nodeUrl}/current/check-password`, payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  hasProfilePicture() {
    return this.httpClient
      .get(`${environment.nodeUrl}/current/profile-picture/exist`, {})
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  changeCurrentUserName(payload) {
    return this.httpClient
      .put(`${environment.nodeUrl}/current/userName`, payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  changeSocialMedia(payload) {
    return this.httpClient
      .put(`${environment.nodeUrl}/current/social-media`, payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  uploadProfilePicByLink(payload) {
    return this.httpClient.put(`${environment.nodeUrl}/current/profile-picture-link`, payload).pipe(
      map((res: HttpResponse<any>) => {
        if (res && res['data']) {
          AuthenticationService.profilePic = res['data'];

          AuthenticationService.updateProfilePicture.emit(res['data']);
          return res['data'];
        } else {
          return null;
        }
      })
    );
  }

  clearSocialMedia(type, payload) {
    return this.httpClient
      .put(`${environment.nodeUrl}/current/clear-social-media/${type}`, payload)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getAuthenticationMethod(cardKey = null, invitationID = null, sessionType = 'guest') {
    if (cardKey) {
      if (sessionType == 'admin' || sessionType == 'promoter') {
        if (
          this.authenticationMethod &&
          this.authenticationMethod.sessionType === 'admin' &&
          this.authenticationMethod.cardKey === cardKey
        ) {
          return of(this.authenticationMethod);
        } else {
          return this.httpClient
            .get(`${environment.nodeUrl}/auth/authentication-method-card/global/${cardKey}`, {})
            .pipe(
              map((res: HttpResponse<any>) => {
                if (res && res['data']) {
                  this.authenticationMethod = res['data'];
                  this.authenticationMethod.cardKey = cardKey;
                  this.authenticationMethod.sessionType = 'admin';
                }

                return res['data'];
              })
            );
        }
      } else {
        if (
          this.authenticationMethod &&
          this.authenticationMethod.sessionType === 'user' &&
          this.authenticationMethod.cardKey === cardKey
        ) {
          return of(this.authenticationMethod);
        } else {
          return this.httpClient.get(`${environment.nodeUrl}/auth/authentication-method-card/${cardKey}`, {}).pipe(
            map((res: HttpResponse<any>) => {
              if (res && res['data']) {
                this.authenticationMethod = res['data'];
                this.authenticationMethod.cardKey = cardKey;
                this.authenticationMethod.sessionType = 'user';
              }

              return res['data'];
            })
          );
        }
      }
    } else if (invitationID) {
      if (sessionType == 'admin' || sessionType == 'promoter') {
        if (
          this.authenticationMethod &&
          this.authenticationMethod.sessionType === 'admin' &&
          this.authenticationMethod.invitationID === invitationID
        ) {
          return of(this.authenticationMethod);
        } else {
          return this.httpClient
            .get(`${environment.nodeUrl}/auth/authentication-method-invitation/global/${invitationID}`, {})
            .pipe(
              map((res: HttpResponse<any>) => {
                if (res && res['data']) {
                  this.authenticationMethod = res['data'];
                  this.authenticationMethod.invitationID = invitationID;
                  this.authenticationMethod.sessionType = 'admin';
                }

                return res['data'];
              })
            );
        }
      } else {
        if (
          this.authenticationMethod &&
          this.authenticationMethod.sessionType === 'user' &&
          this.authenticationMethod.invitationID === invitationID
        ) {
          return of(this.authenticationMethod);
        } else {
          return this.httpClient
            .get(`${environment.nodeUrl}/auth/authentication-method-invitation/${invitationID}`, {})
            .pipe(
              map((res: HttpResponse<any>) => {
                if (res && res['data']) {
                  this.authenticationMethod = res['data'];
                  this.authenticationMethod.invitationID = invitationID;
                  this.authenticationMethod.sessionType = 'user';
                }

                return res['data'];
              })
            );
        }
      }
    } else {
      if (this.authenticationMethod && this.authenticationMethod.sessionType === 'current') {
        return of(this.authenticationMethod);
      } else {
        return this.httpClient.get(`${environment.nodeUrl}/current/authentication-method`, {}).pipe(
          map((res: HttpResponse<any>) => {
            if (res && res['data']) {
              this.authenticationMethod = res['data'];
              this.authenticationMethod.sessionType = 'current';
            }

            return res['data'];
          })
        );
      }
    }
  }

  socialMediaExist(type = 'facebook', token) {
    if (type == 'facebook') {
      return this.httpClient
        .get(`${environment.nodeUrl}/operator/facebook-exist/${token}`, {})
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (type == 'google') {
      return this.httpClient
        .get(`${environment.nodeUrl}/operator/google-exist/${token}`, {})
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else if (type == 'twitter') {
      return this.httpClient
        .get(`${environment.nodeUrl}/operator/twitter-exist/${token}`, {})
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  userNameExist(userName) {
    return this.httpClient
      .get(`${environment.nodeUrl}/operator/exist/${userName}`, {})
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getCurrentTimeZone() {
    if (Settings && Settings.global && Settings.global['dynamicUserTimeZone'] == true) {
      if (
        this.currentUser &&
        this.currentUser !== 'none' &&
        this.currentUser.data &&
        this.currentUser.data['TimeZoneCode']
      ) {
        ClientDetails.timeZoneUTCProfileValue = Number(this.currentUser.data['TimeZoneValue']);
        ClientDetails.timeZoneUTCProfileCode = this.currentUser.data['TimeZoneCode'];
      }
    } else {
      ClientDetails.timeZoneUTCProfileValue = null;
      ClientDetails.timeZoneUTCProfileCode = null;
    }
  }

  resetCurrentTimeZone() {
    ClientDetails.timeZoneUTCProfileValue = null;
    ClientDetails.timeZoneUTCProfileCode = null;
    ClientDetails.timeZoneProfileUpdated = false;
  }

  getMerchantList(payload, isPromoterOrAdmin = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (isPromoterOrAdmin == true) {
      return this.httpClient
        .get(environment.nodeUrl + '/merchant/global/', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .get(environment.nodeUrl + '/merchant/', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getMerchantDetails(ID, payload = {}, sessionType): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.httpClient
        .get(environment.nodeUrl + '/merchant/' + ID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .get(environment.nodeUrl + '/merchant/public/' + ID, options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getDentistList(body, isPromoterOrAdmin = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        params = params.set(key, body[key]);
      }
    }

    options = {
      params,
    };
    if (isPromoterOrAdmin == true) {
      return this.httpClient
        .get(environment.nodeUrl + '/contact/global', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.httpClient
        .get(environment.nodeUrl + '/contact/', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getCustomerProspectProfilePicStreamLink(ID, LastModified = null) {
    if (
      LastModified &&
      LastModified != '' &&
      LastModified != undefined &&
      LastModified != 'null' &&
      LastModified != 'undefined '
    ) {
      return `${environment.nodeUrl}/customer-prospect/profile-picture-stream-cached/${LastModified}/` + ID;
    } else if (ID) {
      return `${environment.nodeUrl}/customer-prospect/profile-picture-stream/` + ID;
    }
    return null;
  }

  getCurrentFinancedProductGroup(): Observable<any> {
    if (
      this.currentUser &&
      this.currentUser !== 'none' &&
      this.currentUser.data &&
      this.currentUser.data.financedProductGroups &&
      this.currentUser.data.financedProductGroups.length > 0
    ) {
      return of(this.currentUser.data.financedProductGroups);
    }
    if (this.currentfinancedProductGroups) {
      return of(this.currentfinancedProductGroups);
    } else {
      const payload = { isFinance: true };
      let params: HttpParams = new HttpParams();
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }
      const options = {
        params,
      };

      return this.httpClient.get(environment.nodeUrl + '/merchant-product-group', options).pipe(
        map((res: HttpResponse<any>) => {
          if (res && res['data']) {
            this.currentfinancedProductGroups = res['data'];
          }

          return res['data'];
        })
      );
    }
  }

  getCurrentFinancedProductGroupHidden(): Observable<any> {
    if (
      this.currentUser &&
      this.currentUser !== 'none' &&
      this.currentUser.data &&
      this.currentUser.data.financedProductGroups &&
      this.currentUser.data.financedProductGroups.length > 0
    ) {
      return of(this.currentUser.data.financedProductGroups);
    }
    if (this.currentfinancedProductGroups) {
      return of(this.currentfinancedProductGroups);
    } else {
      const payload = { isFinance: true };
      let params: HttpParams = new HttpParams();
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }
      const options = {
        params,
      };

      return this.httpClient.get(environment.nodeUrl + '/merchant-product-group/hidden', options).pipe(
        map((res: HttpResponse<any>) => {
          if (res && res['data']) {
            this.currentfinancedProductGroups = res['data'];
          }

          return res['data'];
        })
      );
    }
  }

  getProfilePicStreamLink() {
    return `${environment.nodeUrl}/current/profile-picture-stream/` + btoa(Math.random().toString()).substring(2, 20);
  }

  getPracticePicStreamLink() {
    return `${environment.nodeUrl}/current/practice-picture-stream/` + btoa(Math.random().toString()).substring(2, 20);
  }

  getPracticePicCoverStreamLink() {
    return (
      `${environment.nodeUrl}/current/practice-picture-cover-stream/` + btoa(Math.random().toString()).substring(2, 20)
    );
  }

  getPracticeAppointmentCoverStreamLink() {
    return (
      `${environment.nodeUrl}/current/practice-appointment-cover-stream/` +
      btoa(Math.random().toString()).substring(2, 20)
    );
  }
}
