var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { EventEmitter, OnChanges, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { UtilsClass } from '../../types/utils/utils.class';
export class ImageViewListComponent {
    constructor(dialog, authenticationService, data) {
        this.dialog = dialog;
        this.authenticationService = authenticationService;
        this.data = data;
        this.actionButtonLabel = 'Send'; // just label of action button button , default 'Send'
        this.multipleActionButtonLabel = 'Send';
        this.title = 'Image Gallery';
        this.description = 'Select images';
        this.sendAction = new EventEmitter(); // emit link when you click on action button
        this.sendMultipleActions = new EventEmitter(); // emit array of checked links on click on action button
        this.sendSelectedImages = new EventEmitter(); // once u select an images , emit selected array
        this.closeModal = new EventEmitter();
        this.sessionType = 'merchant';
        this.isAdminOrPromoter = false;
        this.isModal = false;
        this.fullsizeClick = false;
        this.utils = new UtilsClass();
        if (data) {
            this.isModal = true;
            if (data.imageLinks != null) {
                this.imageLinks = data.imageLinks;
            }
            if (data.canDownload != null) {
                this.canDownload = data.canDownload;
            }
            if (data.canAction != null) {
                this.canAction = data.canAction;
            }
            if (data.canCheck != null) {
                this.canCheck = data.canCheck;
            }
            if (data.canMultipleDowload != null) {
                this.canMultipleDowload = data.canMultipleDowload;
            }
            if (data.canMultipleAction != null) {
                this.canMultipleAction = data.canMultipleAction;
            }
            if (data.actionButtonLabel != null) {
                this.actionButtonLabel = data.actionButtonLabel;
            }
            if (data.multipleActionButtonLabel != null) {
                this.multipleActionButtonLabel = data.multipleActionButtonLabel;
            }
        }
    }
    ngOnInit() {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield Promise.all([
                this.authenticationService.getSessionType().toPromise(),
                this.authenticationService.isPromoterOrAdmin().toPromise(),
            ]);
            this.sessionType = res[0];
            this.isAdminOrPromoter = res[1];
            this.setUp();
        });
    }
    ngOnChanges() {
        this.setUp();
    }
    setUp() {
        if (this.imageLinks) {
            this._imageLinks = this.imageLinks.map((it) => {
                return {
                    link: it,
                    checked: false,
                };
            });
        }
    }
    downloadImage(imageLink) {
        return __awaiter(this, void 0, void 0, function* () {
            const base64 = yield this.utils.convertImageLinkToBase64(imageLink.link);
            this.utils.downloadBase64File(base64, 'download.png');
        });
    }
    multipleActionEvent() {
        const selectedImages = this._imageLinks.filter((it) => it.checked).map((it) => it.link);
        this.sendSelectedImages.emit(selectedImages);
    }
    closeEvent() {
        this.closeModal.emit(true);
    }
}
