/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./data-header.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../directives/countup.directive";
import * as i3 from "@angular/common";
import * as i4 from "../../pipes/safe-url.pipe";
import * as i5 from "@angular/platform-browser";
import * as i6 from "./data-header.component";
var styles_DataHeaderComponent = [i0.styles];
var RenderType_DataHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DataHeaderComponent, data: {} });
export { RenderType_DataHeaderComponent as RenderType_DataHeaderComponent };
function View_DataHeaderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "img", [], [[8, "src", 4]], null, null, null, null)), i1.ɵppd(1, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 0, 0, _ck(_v, 1, 0, i1.ɵnov(_v.parent.parent, 0), _co.imageSrc)); _ck(_v, 0, 0, currVal_0); }); }
function View_DataHeaderComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" : "]))], null, null); }
function View_DataHeaderComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "badge"], ["countUp", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 4210688, null, 0, i2.CountUpDirective, [i1.ElementRef], { options: [0, "options"], endVal: [1, "endVal"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ""; var currVal_1 = _co.counterValue; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_DataHeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "h1", [["class", "dataHeader rm-mt"], ["style", "display: flex; flex-direction: row; align-items: center"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DataHeaderComponent_2)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(4, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DataHeaderComponent_3)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DataHeaderComponent_4)), i1.ɵdid(8, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.imageSrc; _ck(_v, 3, 0, currVal_0); var currVal_2 = _co.counterValue; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.counterValue; _ck(_v, 8, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.title; _ck(_v, 4, 0, currVal_1); }); }
function View_DataHeaderComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "img", [], [[8, "src", 4]], null, null, null, null)), i1.ɵppd(1, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 0, 0, _ck(_v, 1, 0, i1.ɵnov(_v.parent.parent, 0), _co.imageSrc)); _ck(_v, 0, 0, currVal_0); }); }
function View_DataHeaderComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" : "]))], null, null); }
function View_DataHeaderComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "badge"], ["countUp", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 4210688, null, 0, i2.CountUpDirective, [i1.ElementRef], { options: [0, "options"], endVal: [1, "endVal"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ""; var currVal_1 = _co.counterValue; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_DataHeaderComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "h2", [["class", "dataHeader rm-mt"], ["style", "display: flex; flex-direction: row; align-items: center"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DataHeaderComponent_6)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(4, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DataHeaderComponent_7)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DataHeaderComponent_8)), i1.ɵdid(8, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.imageSrc; _ck(_v, 3, 0, currVal_0); var currVal_2 = _co.counterValue; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.counterValue; _ck(_v, 8, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.title; _ck(_v, 4, 0, currVal_1); }); }
export function View_DataHeaderComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i4.SafeUrlPipe, [i5.DomSanitizer]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DataHeaderComponent_1)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DataHeaderComponent_5)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.headerSize === "standard"); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.headerSize === "smaller"); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_DataHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ipv-data-header", [], null, null, null, View_DataHeaderComponent_0, RenderType_DataHeaderComponent)), i1.ɵdid(1, 49152, null, 0, i6.DataHeaderComponent, [], null, null)], null, null); }
var DataHeaderComponentNgFactory = i1.ɵccf("ipv-data-header", i6.DataHeaderComponent, View_DataHeaderComponent_Host_0, { title: "title", imageSrc: "imageSrc", counterValue: "counterValue", headerSize: "headerSize" }, {}, []);
export { DataHeaderComponentNgFactory as DataHeaderComponentNgFactory };
