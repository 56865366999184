import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { DentistViewComponent } from '../../dentist/dentist-view/dentist-view.component';
import { DentistService } from '../../dentist/shared/dentist.service';
import { InvitationUtilClass } from '../../invitation/shared/types/invitation-util.class';
import { MerchantViewComponent } from '../../merchant/merchant-view/merchant-view.component';
import { MerchantService } from '../../merchant/shared/merchant.service';
import { ProductViewModalComponent } from '../../product/product-view-modal/product-view-modal.component';
import { ProductService } from '../../product/shared/product.service';

import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';

@Component({
  selector: 'app-operator-filter',
  templateUrl: './operator-filter.component.html',
  styleUrls: ['./operator-filter.component.css'],
})
export class OperatorFilterComponent implements OnInit {
  invitationUtil = new InvitationUtilClass();

  selectedFilter = [];

  toppings = new FormControl();

  filterList = [];

  isAdminOrPromoter = false;

  @Input()
  selectDefaultAccount = false;

  @Input()
  displayMerchant = true;

  @Input()
  displayDateFrom = true;

  @Input()
  displayStatus = false;

  @Input()
  displayDateTo = true;

  @Input()
  displayProduct = true;

  @Input()
  displayDentist = true;

  @Input()
  displaySearch = false;

  @Input()
  displaySearchFilter = true;

  @Input()
  isTimeTo = true;

  @Input()
  isTimeFrom = true;
  @Input()
  displayDateRange = true;

  @Input()
  isTimeSecondTo = true;

  @Input()
  isTimeSecondFrom = true;
  @Input()
  displayDateSecondRange = false;

  @Input()
  displayAllMerchant = true;

  @Input()
  selectedMerchant;

  @Input()
  selectedStatus;

  @Input()
  selectedDentist;

  @Input()
  selectedProduct;

  @Input()
  selectedDateFrom;

  @Input()
  selectedDateTo;

  @Input()
  selectDateRange = 'days90';

  @Input()
  selectedDateSecondFrom;

  @Input()
  selectedDateSecondTo;

  @Input()
  selectDateSecondRange = 'days90';

  @Input()
  dateToSecondLabel = 'Date To';

  @Input()
  dateSecondLabel = 'Date';

  @Input()
  dateFromSecondLabel = 'Date From';

  currentPracticeID;

  @Input()
  sendNull;

  @Input()
  dateToLabel = 'Date To';

  @Input()
  dateLabel = 'Date';

  @Input()
  dateFromLabel = 'Date From';

  @Input()
  merchantLabel = 'Select Merchant';

  @Input()
  productLabel = 'Select Product';

  @Input()
  dentistLabel = 'Select Staff';

  @Input()
  searchLabel = 'Search';

  @Input()
  statusLabel = 'Invitation Status';

  @Input()
  displayTimePicker = false;

  /**
   * autoCompletedMerchant: ability to using mat-autocomplete to auto fill the merchant
   */
  @Input()
  autoCompletedMerchant = false;

  /**
   * autoSelectMerchant: ability to get the merchantID from local storage,
   * and apply to each component
   */
  @Input()
  autoSelectMerchant = false;

  /**
   * applyAutoSelectMerchant: ability to set the the merchantID to local storage
   */
  @Input()
  setAutoSelectMerchant = false;

  @Input()
  showCustomRangeLabel = true ;

  @Output()
  getMerchant = new EventEmitter();

  @Output()
  getProduct = new EventEmitter();

  @Output()
  getDentist = new EventEmitter();

  @Output()
  getStatus = new EventEmitter();

  @Output()
  getDateTo = new EventEmitter();

  @Output()
  getDateFrom = new EventEmitter();

  @Output()
  getDateSecondTo = new EventEmitter();

  @Output()
  getDateSecondFrom = new EventEmitter();

  @Output()
  search = new EventEmitter();

  merchants = [];
  products = [];
  dentists = [];

  statusArray = [
    {
      name: null,
      value: 'All',
    },

    {
      name: 'viewed',
      value: 'Viewed',
    },
    {
      name: 'referred',
      value: 'Referred',
    },
    {
      name: 'notProceeding',
      value: 'Not Proceeding',
    },
    {
      name: 'changed',
      value: 'Changed',
    },
    {
      name: 'notViewed',
      value: 'Not Viewed',
    },
    {
      name: 'expired',
      value: 'Expired',
    },
    {
      name: 'approved',
      value: 'Approved',
    },
    {
      name: 'proceedingCash',
      value: 'Paying Cash or Card',
    },
    {
      name: 'intendProceed',
      value: 'Intend to proceed',
    },
    {
      name: 'financed',
      value: 'Financed',
    },
    {
      name: 'commenced',
      value: 'Commenced',
    },
  ];

  dateRangeArray = [
    {
      name: 'today',
      value: 'Today',
    },

    {
      name: 'yesterday',
      value: 'Yesterday',
    },

    {
      name: '7Days',
      value: '7 days',
    },

    {
      name: '14Days',
      value: '14 days',
    },

    {
      name: '1Month',
      value: '1 month',
    },

    {
      name: '2Month',
      value: '2 months',
    },

    {
      name: '3Month',
      value: '3 months',
    },

    {
      name: '6Month',
      value: '6 months',
    },
    {
      name: '1Year',
      value: '1 year',
    },

    {
      name: 'all',
      value: 'All time',
    },
  ];

  utils = new UtilsClass();

  filterMerchantList;
  selectedMerchantName;

  isChangeMerchant = false;

  resultDateRangeSecond;
  resultDateFromSecond;
  resultDateToSecond;

  resultDateRange;
  resultDateFrom;
  resultDateTo;
  constructor(
    private AuthenticationService: AuthenticationService,
    private merchantService: MerchantService,
    private productService: ProductService,
    private contacService: DentistService
  ) {}

  ngOnInit() {
    this.AuthenticationService.getCurrentPractice().subscribe((r) => {
      if (r && r.ID) {
        this.currentPracticeID = r.ID;
      }

      // if (this.displayDateFrom == true || this.displayDateTo == true) {
      //   this.filterList.push(
      //     {
      //       name: "Custom Date Range",
      //       value: "customDateRange"
      //     }
      //   )
      // }

      if (this.displayDentist == true) {
        this.filterList.push({
          name: 'Staff',
          value: 'staff',
        });
      }

      this.AuthenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isAdminOrPromoter = res;

        if (this.displayMerchant == true && this.isAdminOrPromoter == true) {
          const payload = {
            fields: 'ID,TradingAs,CalculatedName,Status',
          };
          this.merchantService.getList(payload).subscribe((res1) => {
            this.merchants = this.merchants = JSON.parse(JSON.stringify(res1));
            this.filterMerchantList = res1;
            if (this.selectDefaultAccount == true) {
              this.AuthenticationService.getCurrentUser().subscribe((practice) => {
                if (practice && practice.data && practice.data['practiceKey']) {
                  this.selectedMerchant = practice.data['practiceKey'];
                  this.getMerchant.emit(this.selectedMerchant);
                }
              });
            } else {
              this.getMerchant.emit(this.selectedMerchant);
            }
          });
        } else {
          this.getMerchant.emit(this.selectedMerchant);
        }

        if (this.displayDentist == true && this.isAdminOrPromoter == true) {
          const payload = {
            fields: 'ID,TradingAs,CalculatedName',
          };
          if (this.selectDefaultAccount == true) {
            this.AuthenticationService.getCurrentUser().subscribe((practice) => {
              if (practice && practice.data && practice.data['practiceKey']) {
                this.selectedMerchant = practice.data['practiceKey'];
              }

              payload['merchantID'] = this.selectedMerchant;

              this.contacService.getList(payload, this.isAdminOrPromoter).subscribe((res1) => {
                this.dentists = res1;
              });
            });
          } else {
            this.contacService.getList(payload, this.isAdminOrPromoter).subscribe((res1) => {
              this.dentists = res1;
            });
          }
        }

        if (this.displayProduct == true) {
          const payload = {
            fields: 'ID,Label,SubType,MarketingLabel,WebLabel',
          };
          this.productService.getList(payload, this.isAdminOrPromoter).subscribe((res1) => {
            this.products = res1;
            this.getProduct.emit(this.selectedProduct);
          });
        } else {
          this.getProduct.emit(this.selectedProduct);
        }
      });
    });

    this.getDateSecondFrom.emit(this.selectedDateSecondFrom);
    this.getDateSecondTo.emit(this.selectedDateSecondTo);

    this.getDateFrom.emit(this.selectedDateFrom);
    this.getDateTo.emit(this.selectedDateTo);
    this.getDentist.emit(this.selectedDentist);
  }

  changeDateSecondRange(name) {
    this.selectDateSecondRange = name;

    let _dateTo = new Date();
    let _dateFrom;

    const _h = moment(_dateTo, 'DD-MM-YYYY hh:mm:ss');

    _h.hours(23);
    _h.minutes(59);

    _dateTo = _h.toDate();

    if (name == 'today') {
      _dateFrom = moment(_dateTo, 'DD-MM-YYYY hh:mm:ss');
      _dateFrom.hours(0);
      _dateFrom.minutes(0);

      _dateFrom = new Date(_dateFrom.toDate());
    } else if (name == 'yesterday') {
      _dateFrom = this.utils.getPastTime(_dateTo, -1, 'day');
      _dateTo = this.utils.getPastTime(_dateTo, -1, 'day');
    } else if (name == '7Days') {
      _dateFrom = this.utils.getPastTime(_dateTo, -7, 'day');
    } else if (name == '14Days') {
      _dateFrom = this.utils.getPastTime(_dateTo, -14, 'day');
    } else if (name == '1Month') {
      _dateFrom = this.utils.getPastTime(_dateTo, -1, 'month');
    } else if (name == '2Month') {
      _dateFrom = this.utils.getPastTime(_dateTo, -2, 'month');
    } else if (name == '3Month') {
      _dateFrom = this.utils.getPastTime(_dateTo, -3, 'month');
    } else if (name == '6Month') {
      _dateFrom = this.utils.getPastTime(_dateTo, -6, 'month');
    } else if (name == '1Year') {
      _dateFrom = this.utils.getPastTime(_dateTo, -1, 'year');
    } else if (name == 'all') {
      _dateFrom = null;
      _dateTo = null;
    }

    this.selectedDateSecondFrom = _dateFrom;
    this.selectedDateSecondTo = _dateTo;

    this.sendResult();
  }

  changeDateRange(name) {
    this.selectDateRange = name;

    let _dateTo = new Date();
    let _dateFrom;

    const _h = moment(_dateTo, 'DD-MM-YYYY hh:mm:ss');

    _h.hours(23);
    _h.minutes(59);

    _dateTo = _h.toDate();

    if (name == 'today') {
      _dateFrom = moment(_dateTo, 'DD-MM-YYYY hh:mm:ss');
      _dateFrom.hours(0);
      _dateFrom.minutes(0);

      _dateFrom = new Date(_dateFrom.toDate());
    } else if (name == 'yesterday') {
      _dateFrom = this.utils.getPastTime(_dateTo, -1, 'day');
      _dateTo = this.utils.getPastTime(_dateTo, -1, 'day');
    } else if (name == '7Days') {
      _dateFrom = this.utils.getPastTime(_dateTo, -7, 'day');
    } else if (name == '14Days') {
      _dateFrom = this.utils.getPastTime(_dateTo, -14, 'day');
    } else if (name == '1Month') {
      _dateFrom = this.utils.getPastTime(_dateTo, -1, 'month');
    } else if (name == '2Month') {
      _dateFrom = this.utils.getPastTime(_dateTo, -2, 'month');
    } else if (name == '3Month') {
      _dateFrom = this.utils.getPastTime(_dateTo, -3, 'month');
    } else if (name == '6Month') {
      _dateFrom = this.utils.getPastTime(_dateTo, -6, 'month');
    } else if (name == '1Year') {
      _dateFrom = this.utils.getPastTime(_dateTo, -1, 'year');
    } else if (name == 'all') {
      _dateFrom = null;
      _dateTo = null;
    }

    this.selectedDateFrom = _dateFrom;
    this.selectedDateTo = _dateTo;

    this.sendResult();
  }

  dateFromSecondEvent(d) {
    this.selectedDateSecondFrom = d;
    this.selectDateSecondRange = null;

    this.getDateSecondFrom.emit(this.selectedDateSecondFrom);
  }

  dateToSecondEvent(d) {
    this.selectedDateTo = d;
    this.selectDateRange = null;
    this.getDateTo.emit(this.selectedDateTo);
  }

  dateFromEvent(d) {
    this.selectedDateFrom = d;
    this.selectDateRange = null;

    this.getDateFrom.emit(this.selectedDateFrom);
  }

  dateToEvent(d) {
    this.selectedDateTo = d;
    this.selectDateRange = null;
    this.getDateTo.emit(this.selectedDateTo);
  }

  sendResult() {
    const result = {
      status: this.selectedStatus || null,
      dentistID: this.selectedDentist || null,
      dateFrom: this.selectedDateFrom || null,
      dateTo: this.selectedDateTo || null,
      dateSecondFrom: this.selectedDateSecondFrom || null,
      dateSecondTo: this.selectedDateSecondTo || null,
      productID: this.selectedProduct || null,
      merchantID: this.selectedMerchant || null,
      dateRange: this.resultDateRange || null,
      dateRangeSecond: this.resultDateRangeSecond || null,
    };

    if (this.isAdminOrPromoter == true) {
      result['isChangeMerchant'] = this.isChangeMerchant;
    }

    if (
      this.selectedDateTo != this.resultDateTo ||
      this.selectedDateFrom != this.resultDateFrom ||
      this.selectedDateSecondTo != this.resultDateToSecond ||
      this.selectedDateSecondFrom != this.resultDateFromSecond
    ) {
      this.resultDateTo = this.selectedDateTo;
      this.resultDateFrom = this.selectedDateFrom;
      this.resultDateToSecond = this.selectedDateSecondTo;
      this.resultDateFromSecond = this.selectedDateSecondFrom;

      this.search.emit(result);
    }
  }

  filterMerchant(value) {
    if (value) {
      this.filterMerchantList = this.merchants.filter((x) =>
        x['TradingAs'].toLowerCase().includes(value.toLowerCase())
      );
    }
  }

  getSelectedMerchant(value) {
    if (value) {
      this.selectedMerchantName = value.TradingAs;
      this.selectedMerchant = value.ID;

      this.changeMerchant();
    }
  }

  changeMerchant() {
    this.selectedProduct = null;
    this.selectedDentist = null;

    if (this.displayProduct == true) {
      const payload = {
        merchantID: this.selectedMerchant,
        fields: 'ID,Label,SubType,MarketingLabel,WebLabel',
      };
      this.productService.getList(payload, this.isAdminOrPromoter).subscribe((res1) => {
        this.products = res1;
      });
    }

    if (this.displayDentist == true) {
      const payload = {
        merchantID: this.selectedMerchant,
        fields: 'ID,TradingAs,CalculatedName',
      };
      this.contacService.getList(payload, this.isAdminOrPromoter).subscribe((res1) => {
        this.dentists = res1;
      });
    }

    // if (this.isAdminOrPromoter == true && this.setAutoSelectMerchant == true) {
    //   if (typeof this.selectedMerchant === 'string') {
    //     localStorage.setItem('selectedMerchant', JSON.stringify(this.selectedMerchant));
    //   } else if (typeof this.selectedMerchant === 'object' && this.selectedMerchant && this.selectedMerchant.ID) {
    //     localStorage.setItem('selectedMerchant', JSON.stringify(this.selectedMerchant.ID));
    //   }
    // }

    this.getMerchant.emit(this.selectedMerchant);

    this.isChangeMerchant = true;
    this.sendResult();
  }

  changeProduct() {
    this.getProduct.emit(this.selectedProduct);
  }

  checkFilter(v) {
    if (this.selectedFilter && this.selectedFilter.indexOf(v) != -1) {
      return true;
    } else {
      return false;
    }
  }

  changeStatus() {
    this.getStatus.emit(this.selectedStatus);
  }

  changeDentist() {
    this.getDentist.emit(this.selectedDentist);
  }

  viewMerchant(id) {
    const ref = RootAppComponent.dialog.open(MerchantViewComponent, {
      data: id,
      width: '600px',
    });
    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  viewProduct(id) {
    const ref = RootAppComponent.dialog.open(ProductViewModalComponent, {
      data: id,
      width: '750px',
      panelClass: 'noCard',
    });
    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  viewDentist(id) {
    const ref = RootAppComponent.dialog.open(DentistViewComponent, {
      data: id,
      width: '600px',
    });
    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  applySearch() {
    this.sendResult();
  }

  getRangeApplications(e) {
    this.resultDateRange = e.dateRange;

    this.selectedDateFrom = e.startDate;
    this.selectedDateTo = e.endDate;

    this.sendResult();
  }

  getRangeSecondApplications(e) {
    this.selectedDateSecondFrom = e.startDate;
    this.selectedDateSecondTo = e.endDate;

    this.resultDateRangeSecond = e.dateRange;

    this.sendResult();
  }
}
