<mat-accordion>
  <mat-expansion-panel
    class="treatmentPanel"
    *ngFor="let t of treatmentPanels; let i = index"
    [expanded]="step === i"
    (opened)="setStep(i)"
  >
    <mat-expansion-panel-header>
      <!-- show if no {{ "KEYWORD.treatment" | translate }} label is set -->
      <h3 class="sr-title full-width rm-m" *ngIf="!t.treatmentItemLabel">
        <mat-icon class="fas {{'fa-tooth' | translate}}"></mat-icon>
        {{ "KEYWORD.treatment" | translate | titlecase }} item {{ i + 1 }}
      </h3>
      <!-- show if user sets custom {{ "KEYWORD.treatment" | translate }} label -->
      <h3 class="sr-title full-width rm-m" *ngIf="t.treatmentItemLabel">
        <mat-icon class="fas {{'fa-tooth' | translate}}"></mat-icon>
        {{ t.treatmentItemLabel }}
      </h3>
      <!-- type is displayed when a product type is set -->
      <p class="rm-m full-width" *ngIf="type == true">Type: TYPE</p>
      <!-- quantity is set from the qty field (pre-filled to '1') -->
      <p class="rm-m full-width" *ngIf="t.treatmentItemQty">Qty: {{ t.treatmentItemQty }}</p>
      <!-- price is set from the price field -->
      <p class="rm-m bold qtr-width text-right" *ngIf="t.treatmentItemPrice">
        {{ t.treatmentItemPrice | customCurrency }}
      </p>
    </mat-expansion-panel-header>

    <div class="treatment-item-row">
      <mat-divider></mat-divider>

      <!-- cat/type/brand pickers -->
      <div class="row clearfix">
        <div class="icons-col">
          <mat-icon class="fas fa-pencil-alt"></mat-icon>
        </div>
        <div class="row-content-col">
          <div class="row clearfix">
            <h3 class="sr-title sm-h3 rm-m mt">Define {{"KEYWORD.treatment" | translate}}</h3>
          </div>
          <app-treatment-informed-consent-create></app-treatment-informed-consent-create>
        </div>
      </div>

      <!-- quantity of items and value -->
      <div class="row clearfix">
        <div class="icons-col">
          <mat-icon class="fas fa-search-dollar"></mat-icon>
        </div>
        <div class="row-content-col">
          <div class="defineTreat row clearfix">
            <h3 class="sr-title sm-h3 rm-m mt">Enter name, quantity and value</h3>
            <label
            >The name of your {{ "KEYWORD.treatment" | translate }} and a default price will automatically be applied
              here - you can optionally
              change this.</label
            >
            <div class="row clearfix flex mt">
              <!-- automatically set {{ "KEYWORD.treatment" | translate }} label to product name when selected. can be overwritten by operator -->
              <mat-form-field class="full-width" appearance="outline">
                <mat-label> {{"KEYWORD.Treatment" | translate}} item name</mat-label>
                <input
                  class="mat-block"
                  [(ngModel)]="t.treatmentItemLabel"
                  matInput
                  [placeholder]="('KEYWORD.treatment' | translate | titlecase) + ' item name'"
                  name="treatItemName"
                  value="Treatment item {{ i + 1 }}"
                  required
                />
              </mat-form-field>
              <!-- quantity defaults to 1. If more than 1 tooth is picked, this is updated. Operator can overwrite -->
              <mat-form-field class="qtr-width">
                <mat-label>Quantity</mat-label>
                <input
                  class="mat-block"
                  [(ngModel)]="t.treatmentItemQty"
                  matInput
                  placeholder="Quantity"
                  name="treatItemQty"
                  value="1"
                  type="number"
                  required
                />
              </mat-form-field>
              <!-- price is inherited from product, multiplied by qty. If 2x quantity, price is price x 2 -->
              <mat-form-field class="qtr-width">
                <mat-label>Value ($)</mat-label>
                <input
                  class="mat-block"
                  [(ngModel)]="t.treatmentItemPrice"
                  matInput
                  placeholder="Value ($)"
                  name="treatItemValue"
                  value="$1,000"
                  required
                />
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>

      <div class="row clearfix odd mt">
        <mat-accordion>
          <mat-expansion-panel [expanded]="t.treatmentItemPrice">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <h3 class="mat-raised-button mat-warn faux-button">
                  <mat-icon class="fas {{'fa-tooth' | translate}}"></mat-icon>
                  Optional {{ "KEYWORD.treatment" | translate }} settings
                </h3>
              </mat-panel-title>
              <mat-panel-description>
                <p>Define teeth, severity,{{ "KEYWORD.treatment" | translate }} notes and add additional documents</p>
              </mat-panel-description>
            </mat-expansion-panel-header>

            <!-- teeth picker  -->
            <div class="row clearfix mt">
              <div class="icons-col">
                <mat-icon class="fas fa-grimace"></mat-icon>
              </div>
              <div class="row-content-col">
                <div class="teethSelect row">
                  <h3 class="sr-title sm-h3 rm-mt"><span class="optional">Optional:</span> Select teeth</h3>
                  <div class="row clearfix mb">
                    <app-dental-arch-create [clearDataEvent]="clearData" (selectTeeth)="teethSelection($event)">
                    </app-dental-arch-create>
                  </div>
                </div>
              </div>
            </div>

            <div class="row clearfix">
              <div class="icons-col">
                <mat-icon class="fas fa-comment-dots"></mat-icon>
              </div>
              <div class="row-content-col">
                <div class="row clearfix">
                  <h3 class="sr-title sm-h3 rm-mt"><span class="optional">Optional:</span>
                    Add {{ "KEYWORD.treatment" | translate }} notes</h3>
                </div>
                <div class="catTypeBrand row clearfix flex">
                  <!-- Diagnosis -->
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Diagnosis notes</mat-label>
                    <textarea
                      class="mat-block"
                      matInput
                      placeholder="Diagnosis notes"
                      name="diagnosisNotes"
                      value=""
                      required
                    ></textarea>
                  </mat-form-field>
                  <!--{{ "KEYWORD.treatment" | translate | titlecase }}-->
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label> {{"KEYWORD.Treatment" | translate}} notes</mat-label>
                    <textarea
                      class="mat-block"
                      matInput
                      [placeholder]="('KEYWORD.treatment' | translate | titlecase) + ' notes'"
                      name="treatmentNotes"
                      value=""
                      required
                    ></textarea>
                  </mat-form-field>
                </div>
              </div>
            </div>

            <!-- severity -->
            <div class="row clearfix">
              <div class="icons-col">
                <mat-icon class="fas fa-bolt"></mat-icon>
              </div>
              <div class="row-content-col">
                <div class="teethSelect row">
                  <h3 class="sr-title sm-h3 rm-m">
                    <span class="optional">Optional:</span> Set importance/severity
                    of {{"KEYWORD.treatment" | translate}}
                  </h3>
                  <label
                  >This will display a small indicator for your {{ "KEYWORD.patient" | translate }} to quickly judge the
                    importance of completing
                    this {{ "KEYWORD.treatment" | translate }} item.</label
                  >
                  <div class="row clearfix mb">
                    <app-star-rating-create class="pull-left" [label]="'Severity rating'"></app-star-rating-create>
                  </div>
                </div>
              </div>
            </div>

            <div class="additionalDocumentDisplay row clearfix">
              <div class="row clearfix">
                <div class="icons-col">
                  <mat-icon class="fas fa-file-upload"></mat-icon>
                </div>
                <div class="row-content-col">
                  <h3 class="sr-title sm-h3 rm-m"><span class="optional">Optional:</span> Attach additional files</h3>
                  <label>Upload any {{ "KEYWORD.treatment" | translate }} photos, scans, or additional documents for
                    your {{ "KEYWORD.patient" | translate }}.</label>
                </div>
              </div>
              <app-file-uploader class="text-center" [isDragable]="true"></app-file-uploader>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>

    <mat-action-row>
      <button class="mr" mat-raised-button>
        Clear fields
        <mat-icon>block</mat-icon>
      </button>
      <button
        class="mr"
        *ngIf="i >= 1"
        [disabled]="t.canDelete == false"
        (click)="removeTreatmentRow(t)"
        mat-raised-button
      >
        Remove {{"KEYWORD.treatment" | translate}}
        <mat-icon class="accent-color">delete</mat-icon>
      </button>
      <button class="" (click)="newTreatmentRow()" mat-raised-button color="primary">
        Add {{"KEYWORD.treatment" | translate}}
        <mat-icon>add</mat-icon>
      </button>
    </mat-action-row>
  </mat-expansion-panel>
</mat-accordion>
