import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WizardComponent } from 'angular-archwizard';
import { ColorPalette } from '../../../shared/types/color-palette';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { AuthenticationService } from '../../authentication/shared/authentication.service';
import { SubscriptionPlanService } from '../shared/subscription-plan.service';

@Component({
  selector: 'app-feature-main-label-create-edit',
  templateUrl: './feature-main-label-create-edit.component.html',
  styleUrls: ['./feature-main-label-create-edit.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.animate', style({ opacity: 0 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.4s ease-out',
              keyframes([
                style({ opacity: 0, offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.animate', style({ opacity: 1 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.4s ease-in',
              keyframes([
                style({ opacity: 1, offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class FeatureMainLabelCreateEditComponent implements OnInit {
  @Input()
  mainFeatureLabelID;

  @Input()
  saveOrder = new EventEmitter();
  @ViewChild('wizard', { static: false }) wizard: WizardComponent;

  mainFeatureLabel;

  isModal = false;
  color = Settings.global['primaryColor'] || '#1e88e5';
  colorSecond;
  _colorSecond;
  _color = Settings.global['primaryColor'] || '#1e88e5';

  label;
  description;
  isActive = true;
  icon = 'fas fa-shapes';

  code;

  useDefaultColor = false;
  createdRecord;

  pinchZoomProperties = [
    {
      wheel: true,
      autoHeight: true,
    },
  ];

  isUnique = true;
  settings = Settings.global;
  utils = new UtilsClass();
  postPictureLink;
  postPicture;

  maxFileSize = 5;
  allowExtensions = ['jpeg', 'jpg', 'png','heic', 'gif', 'tiff', 'bmp', 'ico'];
  applyDownload = new EventEmitter();

  loadingPost = false;
  postPictureLinkThumbnail;
  postPictureThumbnail;
  fileIDThumbnail;
  fileID;
  root = '/merchant';

  sessionType;

  isSideMenu = false;
  isPublic = true;
  isMerchant = true;
  isConsumer = false;
  sideMenuLabel;
  isSubPlanFeature = true;

  constructor(
    private subscriptionPlanService: SubscriptionPlanService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private activeRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    UtilsClass.scrollUp();
    this.activeRoute.params.subscribe((params) => {
      if (params['mainFeatureLabelID']) {
        this.mainFeatureLabelID = params['mainFeatureLabelID'];
      }

      this.authenticationService.getSessionType().subscribe((res) => {
        this.sessionType = res;

        this.setup();
      });
    });
  }

  changeLabel() {
    if (this.label && !this.sideMenuLabel) {
      this.sideMenuLabel = this.label;
      if (!this.code) {
        this.code = this.label.replace(/ /g, '_');
        this.code = this.code.replace(/#/g, '_');
        this.code = this.code.replace(/\//g, '_');
        this.isUniqueEvent();
      }
    }
  }

  setup() {
    if (this.mainFeatureLabelID) {
      this.subscriptionPlanService
        .getFeatureMainLabelDetails(this.mainFeatureLabelID, {}, this.sessionType)
        .subscribe((res) => {
          if (res && res.ID) {
            this.mainFeatureLabel = res;
            this.buildFeature();
            this.loadingPost = true;
            this.subscriptionPlanService.getFeatureMainLabelCoverImage(res.ID).subscribe((res) => {
              if (res) {
                this.postPicture = res;
              } else {
                this.postPicture = null;
              }
              this.loadingPost = false;
            });
            this.postPictureThumbnail = this.subscriptionPlanService.getFeatureMainLabelThumbnailImageStreamLink(
              res.ID,
              res.LastModified
            );
          } else if (this.color && !this.colorSecond) {
            this.onColorPickerClose(this.color);
          }
        });
    } else if (this.color && !this.colorSecond) {
      this.onColorPickerClose(this.color);
    }
  }

  changeDefaultColor() {
    if (this.useDefaultColor === true) {
      this.color = Settings.global['primaryColor'] || '#1e88e5';
      this._color = Settings.global['primaryColor'] || '#1e88e5';
      this.onColorPickerClose(this.color);
    }
  }

  buildFeature() {
    if (this.mainFeatureLabel && this.mainFeatureLabel.ID) {
      this.mainFeatureLabelID = this.mainFeatureLabel.ID;

      if (this.mainFeatureLabel.FE_Code) {
        this.code = this.mainFeatureLabel.FE_Code;
      } else if (this.mainFeatureLabel.MainLabel) {
        this.code = this.mainFeatureLabel.MainLabel.replace(/ /g, '_');
        this.code = this.code.replace(/#/g, '_');
        this.code = this.code.replace(/\//g, '_');
        this.isUniqueEvent();
      }

      if (this.mainFeatureLabel['MainLabel']) {
        this.label = this.mainFeatureLabel['MainLabel'];
      }
      if (this.mainFeatureLabel['Description']) {
        this.description = this.mainFeatureLabel['Description'];
      }

      if (this.mainFeatureLabel['Icon']) {
        this.icon = this.mainFeatureLabel['Icon'];
      }
      if (this.mainFeatureLabel['Is_Side_Menu'] === '0') {
        this.isSideMenu = false;
      } else {
        this.isSideMenu = true;
      }

      if (this.mainFeatureLabel['Is_Private'] === '1') {
        this.isPublic = false;
      } else {
        this.isPublic = true;
      }

      if (this.mainFeatureLabel['Is_Merchant_Feature'] === '0') {
        this.isMerchant = false;
      } else {
        this.isMerchant = true;
      }

      if (this.mainFeatureLabel['Is_Marketing_Only'] === '1') {
        this.isSubPlanFeature = false;
      } else {
        this.isSubPlanFeature = true;
      }

      if (this.mainFeatureLabel['Is_Consumer_Feature'] === '0') {
        this.isConsumer = false;
      } else {
        this.isConsumer = true;
      }
      if (this.mainFeatureLabel['Side_Menu_Label']) {
        this.sideMenuLabel = this.mainFeatureLabel['Side_Menu_Label'];
      } else if (this.mainFeatureLabel['MainLabel']) {
        this.sideMenuLabel = this.mainFeatureLabel['MainLabel'];
      }

      if (this.mainFeatureLabel['Color']) {
        this.useDefaultColor = false;
        const split = this.mainFeatureLabel['Color'].split('|');

        if (split[0]) {
          this.color = split[0];
          this._color = this.color;
        }

        if (split[1]) {
          this.colorSecond = split[1] || null;
          this._colorSecond = this.colorSecond;
        }

        if (this.color && !this.colorSecond) {
          this.onColorPickerClose(this.color);
        }
      } else if (this.color && !this.colorSecond) {
        this.onColorPickerClose(this.color);
      }

      if (this.mainFeatureLabel['Is_Deactivated'] === '1') {
        this.isActive = false;
      } else {
        this.isActive = true;
      }
    } else {
      if (this.color && !this.colorSecond) {
        this.onColorPickerClose(this.color);
      }
    }
  }

  proceed() {
    UtilsClass.scrollUp();
    this.wizard.goToNextStep();
  }

  stepBack() {
    UtilsClass.scrollUp();
    this.wizard.goToPreviousStep();
  }

  openLink(link) {
    if (link) {
      window.open(link, '_blank');
    }
  }

  onCompleteThumbnail(fileID) {
    if (fileID && fileID[0]) {
      this.fileIDThumbnail = fileID[0];
      this.postPictureThumbnail = null;
      this.postPictureLinkThumbnail = this.utils.getFileLink(this.fileIDThumbnail);
    } else {
      this.postPictureLinkThumbnail = null;
    }
  }

  moduleList() {
    this.router.navigate([this.root, { outlets: { page: ['module-list'] } }]);
  }

  step1() {
    this.wizard.goToStep(0);
  }

  step2() {
    this.wizard.goToStep(1);
  }

  step3() {
    this.wizard.goToStep(2);
  }

  onComplete(fileID) {
    if (fileID && fileID[0]) {
      this.fileID = fileID[0];
      this.postPicture = null;
      this.postPictureLink = this.utils.getFileLink(this.fileID);
    } else {
      this.postPictureLink = null;
    }
  }

  downloadImage() {
    this.applyDownload.emit(true);
  }

  isIndexOf(s, attribute) {
    if (s && attribute && s.indexOf(attribute) != -1) {
      return true;
    }
    return false;
  }

  onColorPickerClose(color: string): void {
    if (color) {
      this.color = color;

      const v = new ColorPalette(color);
      const palette = v.getPalette();

      if (palette && palette.length > 0) {
        const index = palette.findIndex((item) => {
          if (item && item.name == 800) {
            return true;
          } else {
            return false;
          }
        });

        if (palette[index] && palette[index]['hex']) {
          this.colorSecond = palette[index]['hex'];
          this._colorSecond = this.colorSecond;
        }
      }
    }
  }

  onColorPickerClose2(color: string): void {
    if (color) {
      this.colorSecond = color;
    }
  }

  viewMainFeature(ID) {
    if (ID) {
      this.router.navigate([this.root, { outlets: { page: ['main-feature-view', ID] } }]);
    }
  }

  save(isProceed = true) {
    const payload = {
      label: this.label,
      description: this.description,
      fileIDCover: this.fileID || null,
      fileIDThumbnail: this.fileIDThumbnail || null,
      color: this.color,
      colorSecond: this.colorSecond,
      icon: this.icon,
      isActive: this.isActive,
      useDefaultColor: this.useDefaultColor,
      isConsumer: this.isConsumer,
      isMerchant: this.isMerchant,
      sideMenuLabel: this.sideMenuLabel,
      isPublic: this.isPublic,
      isSideMenu: this.isSideMenu,
      code: this.code,
      isMarketingOnly: !this.isSubPlanFeature,
    };

    if (this.mainFeatureLabelID) {
      this.subscriptionPlanService.editFeatureMainLabel(this.mainFeatureLabelID, payload).subscribe((res) => {
        if (res && res.ID) {
          this.mainFeatureLabel = res;
          this.buildFeature();

          NotifyAppComponent.displayToast('success', 'Success!', 'Module is Modified');

          if (isProceed == true) {
            this.proceed();
          }
        }
      });
    } else {
      payload.isActive = false;
      this.subscriptionPlanService.createFeatureMainLabel(payload).subscribe((res) => {
        if (res && res.ID) {
          this.mainFeatureLabel = res;

          window.history.replaceState({}, '', `/merchant/(page:main-feature-edit-create/${res.ID})`);
          this.buildFeature();
          NotifyAppComponent.displayToast('success', 'Success!', 'Module is Modified');
          if (isProceed == true) {
            this.proceed();
          }
        }
      });
    }
  }

  finish() {
    if (this.mainFeatureLabel && this.mainFeatureLabel.ID) {
      const payload = {
        isActive: this.isActive,
      };

      this.subscriptionPlanService.editFeatureMainLabel(this.mainFeatureLabel.ID, payload).subscribe((res) => {
        if (res && res.ID) {
          this.createdRecord = res;

          NotifyAppComponent.displayToast('success', 'Success!', 'Module is Modified');
          this.proceed();
        }
      });
    }
  }

  featureList() {
    this.router.navigate([this.root, { outlets: { page: ['main-feature-list'] } }]);
  }

  saveOrderEvent() {
    this.saveOrder.emit(true);
  }

  getOrderEvent(d) {
    if (d === true) {
      this.finish();
    }
  }

  isUniqueEvent() {
    if (this.code) {
      const payload: any = { code: this.code };

      if (this.mainFeatureLabelID) {
        payload.id = this.mainFeatureLabelID;
      }

      this.subscriptionPlanService.isUniqueFeatureMainLabel(payload).subscribe((res) => {
        if (res === true) {
          this.isUnique = true;
        } else {
          this.isUnique = false;
        }
      });
    }
  }
}
