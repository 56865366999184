import { Component } from '@angular/core';
import { combineLatest, EMPTY, of } from 'rxjs';
import { debounceTime, expand, switchMap, tap } from 'rxjs/operators';
import { AppStateService } from '../../../core/app-state/app-state.service';
import { AppState } from '../../../core/app-state/app-state.type';
import { onChangeAnimation } from '../../../shared/animations/onChange.animation';
import { STANDARD_KEYBOARD_DEBOUNCE_TIME } from '../../../shared/config/STANDARD_KEYBOARD_DEBOUNCE_TIME';
import { SessionType } from '../../../shared/types/session-type.type';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { MerchantSelect } from '../../merchant/shared/types/merchant.type';
import { InformedConsent, InformedConsentQuery, InformedConsentService } from '../shared/informed-consent.service';

@Component({
  selector: 'ipv-informed-consent-treatment-presented',
  templateUrl: './informed-consent-treatment-presented.page.html',
  styleUrls: ['./informed-consent-treatment-presented.page.css'],
  animations: [onChangeAnimation],
})
export class InformedConsentTreatmentPresentedPage {
  util = new UtilsClass();

  isFetchingInitialData = true;

  selectedMerchant: MerchantSelect;

  viewModel$ = this.appStateService.getAppState$().pipe(
    switchMap((appState) =>
      this.getFilters$({ initialMerchantID: appState.user.data.merchantKey }).pipe(
        switchMap(
          ([
            merchantIdFilter,
            firstNameFilter,
            lastNameFilter,
            emailFilter,
            isInformedConsentGivenFilter,
            dateRangeFilter,
          ]) =>
            this.fetchData$({
              payload: {
                merchantIdFilter,
                dateFromFilter: dateRangeFilter.startDate,
                dateToFilter: dateRangeFilter.endDate,
                firstNameFilter,
                lastNameFilter,
                emailFilter,
                isInformedConsentGivenFilter,
              },
            }).pipe(
              switchMap(() => this.getData$()),
              switchMap(([informedConsentList]) =>
                this.getViewModel$({
                  appState,
                  informedConsentList,
                  firstNameFilter,
                  lastNameFilter,
                  emailFilter,
                  isInformedConsentGivenFilter,
                  merchantIdFilter,
                })
              )
            )
        )
      )
    )
  );

  dateFrom: string;
  dateTo: string;

  informedConsentGivenFilter: '0' | '1' = '1';

  isPromoterOrAdmin: boolean;
  sessionType: SessionType;

  informedConsentListLength = 0;

  dateInitialised = false;
  nullEmitted = false;

  constructor(private informedConsentService: InformedConsentService, private appStateService: AppStateService) {}

  getFilters$(parameters: { initialMerchantID: string }) {
    const { initialMerchantID } = parameters;
    return combineLatest([
      this.getMerchantIdFilter$({ initialMerchantID }),
      this.getFirstNameFilter$(),
      this.getLastNameFilter$(),
      this.getEmailFilter$(),
      this.getInformedConsentGivenFilter$(),
      this.getDateRangeFilter$(),
    ]);
  }

  setMerchantIdFilter(merchant: MerchantSelect) {
    this.informedConsentService.setMerchantIdFilter(merchant.ID);
    this.selectedMerchant = merchant;
  }

  getMerchantIdFilter$(parameters: { initialMerchantID: string }) {
    const { initialMerchantID } = parameters;
    if (this.isFetchingInitialData) {
      this.informedConsentService.setMerchantIdFilter(initialMerchantID);
    }
    return this.informedConsentService.getMerchantIdFilter$();
  }

  getFirstNameFilter$() {
    return this.informedConsentService.getFirstNameFilter$().pipe(debounceTime(STANDARD_KEYBOARD_DEBOUNCE_TIME));
  }

  setFirstNameFilter(firstName: string) {
    this.informedConsentService.setFirstNameFilter(firstName);
  }

  getLastNameFilter$() {
    return this.informedConsentService.getLastNameFilter$().pipe(debounceTime(STANDARD_KEYBOARD_DEBOUNCE_TIME));
  }

  setLastNameFilter(lastName: string) {
    this.informedConsentService.setLastNameFilter(lastName);
  }

  getEmailFilter$() {
    return this.informedConsentService.getEmailFilter$().pipe(debounceTime(STANDARD_KEYBOARD_DEBOUNCE_TIME));
  }

  setEmailFilter(email: string) {
    this.informedConsentService.setEmailFilter(email);
  }

  getDateRangeFilter$() {
    return this.informedConsentService.getDateRangeFilter$();
  }

  setDateRangeFilter(range: { startDate: string; endDate: string }) {
    if (!this.dateInitialised) {
      this.dateInitialised = true;
    } else {
      if (this.nullEmitted === false) {
        this.nullEmitted = true;
      } else {
        this.informedConsentService.setDateRangeFilter({
          startDate: this.util.EPdateFormat(range.startDate),
          endDate: this.util.EPdateFormat(range.endDate),
        });
      }
    }
  }

  getInformedConsentGivenFilter$() {
    return this.informedConsentService
      .getInformedConsentGivenFilter$()
      .pipe(debounceTime(STANDARD_KEYBOARD_DEBOUNCE_TIME));
  }

  setInformedConsentGivenFilter(isInformedConsentGiven: '0' | '1') {
    this.informedConsentService.setInformedConsentGivenFilter(isInformedConsentGiven);
  }

  fetchData$(parameters: InformedConsentQuery) {
    return combineLatest([this.fetchInformedConsentListSections$(parameters)]);
  }

  getData$() {
    return combineLatest([this.informedConsentService.getInformedConsentList$<InformedConsent>()]);
  }

  getViewModel$(parameters: {
    appState: AppState;
    informedConsentList: InformedConsent[];
    firstNameFilter: string;
    lastNameFilter: string;
    emailFilter: string;
    isInformedConsentGivenFilter: '0' | '1';
    merchantIdFilter: string;
  }) {
    const {
      appState,
      informedConsentList,
      firstNameFilter,
      lastNameFilter,
      emailFilter,
      isInformedConsentGivenFilter,
      merchantIdFilter,
    } = parameters;

    this.isFetchingInitialData = false;
    this.informedConsentListLength = informedConsentList.length;
    const { user, practice, sessionType, isPromoterOrAdmin, dentist } = appState;
    const result = {
      user,
      practice,
      sessionType,
      isPromoterOrAdmin,
      dentist,
      informedConsentList,
      firstNameFilter,
      lastNameFilter,
      emailFilter,
      isInformedConsentGivenFilter,
      merchantIdFilter,
    };

    return of(result);
  }

  fetchInformedConsentListSections$(parameters: InformedConsentQuery) {
    this.informedConsentService.setInformedConsentList([]);

    const {
      merchantIdFilter,
      dateFromFilter,
      dateToFilter,
      firstNameFilter,
      lastNameFilter,
      emailFilter,
      isInformedConsentGivenFilter,
    } = parameters.payload;

    const query: InformedConsentQuery = {
      payload: {
        limit: 150,
        section: 1,
        merchantID: merchantIdFilter,
        DateFrom: dateFromFilter,
        DateTo: dateToFilter,
        'Invitation.FirstName': firstNameFilter,
        'Invitation.LastName': lastNameFilter,
        'Invitation.Email': emailFilter,
        'InformedConsent.Given.Flag': isInformedConsentGivenFilter,
      },
    };

    return this.informedConsentService.fetchInformedConsentList$(query.payload).pipe(
      tap(() => {
        UtilsClass.stopLoadingInterceptor();
        query.payload.section++;
        UtilsClass.loadingDataSection(query.payload.section);
      }),
      expand((informedConsentListSection) =>
        informedConsentListSection.length > 0
          ? this.informedConsentService.fetchInformedConsentList$(query.payload).pipe(
              tap(() => {
                UtilsClass.stopLoadingInterceptor();
              })
            )
          : EMPTY
      ),
      tap((informedConsentListSection) => {
        if (informedConsentListSection.length > 0) {
          query.payload.section++;
          UtilsClass.loadingDataSection(query.payload.section);
          const informedConsentList = this.informedConsentService.getInformedConsentList();
          const newInformedConsentList = informedConsentList.concat(informedConsentListSection);
          this.informedConsentService.setInformedConsentList(newInformedConsentList);
        }
      })
    );
  }
}
