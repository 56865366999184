<div class="container create-container">
  <div
    class="row clearfix full-width flex"
    *ngIf="
      displayStaffAppointment != true && displayAppointmentLookupSorting != true && displayAppointmentLookup != true
    "
  >
    <div class="full-width disable-booking" *ngIf="isAppointmentDisabled == true && isFirstLoad == true">
      <div class="full-width clearfix booking-engine-panel">
        <div class="card-header primary-gradient-img clearfix confirmModal disable-modal">
          <div class="row clearfix">
            <div class="row titleArea clearfix">
              <mat-icon class="white pull-left">error</mat-icon>
              <h2 class="summary-header rm-m">
                You no longer have access to our appointment feature.
                <span class="subLabel"
                >By enabling 3rd party booking engine, all your appointments will be redirected to Booking Engine URL.
                </span>
              </h2>
            </div>
          </div>
        </div>

        <div class="booking-engine-panel-inner mt">
          <h3 class="sm-h4 sr-title mt">3rd party booking engine</h3>

          <p class="clearfix small">
            After {{"KEYWORD.patient" | translate}}s have reviewed the {{ "KEYWORD.treatment" | translate }} and made a
            selection to proceed, they are routed by default to
            request an appointment. If you would like to use a 3rd party booking engine, switch on and configure the
            settings.
          </p>
          <mat-slide-toggle class="full-width mr mb" [(ngModel)]="useThirdPartyBookingURL"
          >Use 3rd party booking engine
          </mat-slide-toggle>
          <div class="row clearfix" *ngIf="useThirdPartyBookingURL">
            <div class="icons-col">
              <mat-icon class="fas fa-calendar-check"></mat-icon>
            </div>
            <div class="row-content-col">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Booking Engine URL</mat-label>
                <input [(ngModel)]="thirdPartyBookingURL" name="thirdPartyUrl" matInput placeholder="Enter URL"/>
              </mat-form-field>

              <p class="small">
                At the end of the invitation process you can route your {{ "KEYWORD.patient" | translate }}s to your
                third-party booking engine by entering the URL here. <br/>(Be sure to add the entire address,
                <strong>including</strong> the 'https' part of the URL.
              </p>
            </div>
          </div>

          <hr class="mt"/>
          <div class="row clearfix text-center mt">
            <button
              class="mr"
              [disabled]="thirdPartyBookingURL === '' && useThirdPartyBookingURL"
              (click)="onSaveThirdPartyBookingURL(thirdPartyBookingURL)"
              mat-raised-button
              color="accent"
              matTooltip=" Click to save"
            >
              Save
            </button>
            <button
              class="mr"
              [disabled]="!thirdPartyBookingURL || thirdPartyBookingURL == ''"
              (click)="onTestBookingUrl()"
              mat-raised-button
              color="primary"
              matTooltip=" Click to Test Url"
            >
              Test URL
              <mat-icon class="fas fa-external-link-alt"></mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="half-width flex">
      <!-- Preview -->

      <div class="full-width">
        <mat-card class="full-height card-centered full-width add-pad review">
          <div class="primary-gradient-img heading">
            <h2 class="sm-h2 white rm-m">How the page will look like in your website</h2>
          </div>

          <div class="rel" *ngIf="loadIFrame != true" @ngIfAnimation>
            <div class="row clearfix padded rel">
              <h1 class="dataHeader rm-mt transparent">Loading appointment page...</h1>
            </div>
          </div>

          <iframe
            class="iframe-style full-width row clearfix mt"
            *ngIf="iframeSrc"
            [src]="iframeSrc"
            (load)="onFrameLoad($event)"
            style="border: none !important"
          ></iframe>
        </mat-card>
      </div>
    </div>

    <!-- Configuration -->
    <div class="half-width">
      <div class="col-right">
        <mat-card class="card-centered clearfix add-pad review">
          <!-- blue heading -->

          <div class="primary-gradient-img heading">
            <h2 class="sm-h2 white rm-m">Customize you own {{"KEYWORD.practice" | translate}} appointment page</h2>
          </div>

          <div class="row clearfix mt">
            <p class="clearfix small">
              You can use the appointments feature in several areas of the application,
              your {{"KEYWORD.practice" | translate}} website and even
              in the footer of your emails. All can be customised to suit.
            </p>

            <div class="row clearfix" #container2>
              <!-- Look and feel -->

              <h3 class="sm-h4 sr-title" *ngIf="appointmentLandingPage">QR code for your appointment page</h3>

              <div class="row clearfix" *ngIf="appointmentLandingPage">
                <div class="full-width clearfix flex">
                  <app-generate-qr

                  [downloadLabel]="'Appointment Landing Page'"
                    class="mr"
                    *ngIf="appointmentLandingPage"
                    [source]="appointmentLandingPage"
                    [size]="115"
                    [download]="downloadQRCode"
                  >
                  </app-generate-qr>

                  <div class="full-width ml">
                    <p class="small ml clearfix">
                      Your unique QR code. The code will redirect your {{"KEYWORD.patient" | translate}}s to you
                      appointment page personalised to
                      your {{"KEYWORD.practice" | translate}}. You can use your QR code on marketing material that you
                      produce such as brochures,
                      newsletters, email footers, etc.
                    </p>

                    <button class="clearfix" (click)="downloadQRCode.emit()" mat-raised-button color="accent">
                      Download QR code
                    </button>
                  </div>
                </div>
              </div>

              <h3 class="sm-h4 sr-title">Look and feel</h3>
              <p class="clearfix small">
                Your {{"KEYWORD.practice" | translate}} appointment page can be personalised to suit including the
                title, message to {{"KEYWORD.patient" | translate}}s,
                colour scheme, background image and contact details etc.
              </p>
              <button
                class="mr"
                (click)="previewToNewTab()"
                mat-raised-button
                color="primary"
                matTooltip=" Click to preview"
              >
                Preview
              </button>
              <button
                class="mr"
                (click)="personaliseLookAndFeel()"
                mat-raised-button
                color="accent"
                matTooltip=" Click to personalise"
              >
                Personalise
              </button>

              <!-- Website & email link -->
              <h3 class="sm-h4 sr-title">Website & email link</h3>
              <p class="clearfix small">
                The simplest way to get the appointments enabled on your website and email footers is to provide the
                unique link below and get your web designer to add it throughout your webpages and email footers. To
                make it easier we have also supplied "Booking" buttons that can be download and supplied.
              </p>
              <!-- <a class="link-preview" href="queryParams">{{queryParams}}</a> -->
              <a class="link-preview mb" href="{{ appointmentLandingPage }}" target="_blank">
                <span class="text">{{ appointmentLandingPage }}</span>
              </a>
              <button
                class="mr"
                *ngIf="appointmentLandingPage"
                (click)="goToLink()"
                mat-raised-button
                color="primary"
                matTooltip=" Click to preview"
              >
                Preview
              </button>
              <button
                class="mr"
                [cbContent]="appointmentLandingPage"
                [container]="container2"
                (click)="linkCopy()"
                ngxClipboard
                mat-raised-button
                color="accent"
                matTooltip="Click to copy the Link"
              >
                Copy Link
              </button>

              <button
                class="mr"
                (click)="downloadButtons()"
                mat-raised-button
                color="accent"
                matTooltip=" Click to dowload buttons"
              >
                Download Buttons
              </button>

              <!-- Advanced Integration -->
              <h3 class="sm-h4 sr-title">Advanced Integration</h3>
              <p class="clearfix small">
                If you want to provide a smoother experience for {{"KEYWORD.patient" | translate}}s, you can set up an
                "iframe" on your webpage.
                Rather than {{"KEYWORD.patient" | translate}}s been routed to a new webpage, a popup screen will appear
                over your existing webpage
                with the appointment page. Supply the code below to your web developer to embed this feature. To make it
                easier we have also supplied "Booking" buttons that can be download and supplied.
              </p>
              <div class="row clearfix">
                <pre class="iframe-code-source" *ngIf="iframeCode">{{ iframeCode }}</pre>
                <div class="clearfix text-right flex">
                  <mat-form-field class="mr" appearance="outline">
                    <mat-label>Width</mat-label>

                    <input
                      class="mat-block"
                      [(ngModel)]="width"
                      (ngModelChange)="updatePreview(false)"
                      type="number"
                      matInput
                      name="Width"
                      required
                      placeholder="Width"
                    />

                    <span class="posPrefix" matSuffix> px </span>
                  </mat-form-field>

                  <mat-form-field class="" appearance="outline">
                    <mat-label>Height</mat-label>

                    <input
                      class="mat-block"
                      [(ngModel)]="height"
                      (ngModelChange)="updatePreview(false)"
                      type="number"
                      matInput
                      name="Height"
                      required
                      placeholder="Height"
                    />
                    <span class="posPrefix" matSuffix> px </span>
                  </mat-form-field>
                </div>
                <div class="clearfix" #container>
                  <button
                    class="mr"
                    (click)="previewAdvanceIntegration()"
                    mat-raised-button
                    color="primary"
                    matTooltip=" Click to preview"
                  >
                    Preview
                  </button>
                  <button
                    class="mr"
                    [cbContent]="iframeCode"
                    [container]="container"
                    (click)="clickCopy()"
                    ngxClipboard
                    mat-raised-button
                    color="accent"
                    matTooltip="Click to copy Iframe Code"
                  >
                    Copy Code
                  </button>
                  <button
                    class="mr"
                    (click)="downloadButtons()"
                    mat-raised-button
                    color="accent"
                    matTooltip=" Click to dowload buttons"
                  >
                    Download Buttons
                  </button>
                </div>
              </div>

              <!-- Cancellation Policy -->
              <h3 class="sm-h4 sr-title">Cancellation Policy</h3>
              <p class="clearfix small">
                You can personalise your {{"KEYWORD.practice" | translate}} cancellation policy to suit including the
                notice period a {{"KEYWORD.patient" | translate}} must
                provide, cancellation fee charged and SMS reminders given.
              </p>
              <button
                class="mr"
                (click)="previewCancellationPolicy()"
                mat-raised-button
                color="primary"
                matTooltip=" Click to preview"
              >
                Preview
              </button>
              <button
                class="mr"
                (click)="personaliseCancellationPolicy()"
                mat-raised-button
                color="accent"
                matTooltip=" Click to personalise"
              >
                Personalise
              </button>

              <!-- Appointment availability -->
              <h3 class="sm-h4 sr-title">Appointment availability</h3>
              <p class="clearfix small">
                Set up your schedule to include times you’re {{"KEYWORD.practice" | translate}} is typically available
                including days open, start
                and closing times and any date-specific rules.
              </p>
              <button
                class="mr"
                (click)="personaliseAppointmentAvailability()"
                mat-raised-button
                color="accent"
                matTooltip=" Click to personalise"
              >
                Personalise
              </button>

              <button class="mr" (click)="staffAvailability()" mat-raised-button color="accent">
                Staff availability
              </button>

              <h3 class="sm-h4 sr-title">Appointment lookup</h3>
              <p class="clearfix small">Set up your appointment types and best contact times for
                your {{ "KEYWORD.patient" | translate }}.</p>

              <button class="mr" (click)="appointmentLookupSorting()" mat-raised-button color="accent">
                Personalise
              </button>

              <!-- 3rd party booking engine -->
              <h3 class="sm-h4 sr-title">3rd party booking engine</h3>

              <p class="clearfix small">
                After {{ "KEYWORD.patient" | translate }}s have reviewed the {{ "KEYWORD.treatment" | translate }} and
                made a selection to proceed, they are routed by default
                to request an appointment. If you would like to use a 3rd party booking engine, switch on and configure
                the settings.
              </p>
              <mat-slide-toggle class="full-width mr mb" [(ngModel)]="useThirdPartyBookingURL"
              >Use 3rd party booking engine
              </mat-slide-toggle>
              <div class="row clearfix" *ngIf="useThirdPartyBookingURL">
                <div class="icons-col">
                  <mat-icon class="fas fa-calendar-check"></mat-icon>
                </div>
                <div class="row-content-col">
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Booking Engine URL</mat-label>
                    <input [(ngModel)]="thirdPartyBookingURL" name="thirdPartyUrl" matInput placeholder="Enter URL"/>
                  </mat-form-field>

                  <p class="small">
                    At the end of the invitation process you can route your {{ "KEYWORD.patient" | translate }}s to your
                    third-party booking engine by entering the URL here. <br/>(Be sure to add the entire address,
                    <strong>including</strong> the 'https' part of the URL.
                  </p>
                </div>
              </div>

              <div class="row clearfix text-right">
                <button
                  class="mr"
                  [disabled]="thirdPartyBookingURL === '' && useThirdPartyBookingURL"
                  (click)="onSaveThirdPartyBookingURL(thirdPartyBookingURL)"
                  mat-raised-button
                  color="accent"
                  matTooltip=" Click to save"
                >
                  Save
                </button>
                <button
                  class="mr"
                  [disabled]="!thirdPartyBookingURL || thirdPartyBookingURL == ''"
                  (click)="onTestBookingUrl()"
                  mat-raised-button
                  color="primary"
                  matTooltip=" Click to Test Url"
                >
                  Test URL
                  <mat-icon class="fas fa-external-link-alt"></mat-icon>
                </button>
              </div>

              <!-- 3rd party calendars -->
              <h3 class="sm-h4 sr-title">3rd party calendars</h3>
              <p class="clearfix small">
                Connect your 3rd party calendars including iCloud, Office 365, Exchange and Google.
              </p>
              <div class="row clearfix text-center mt">
                <app-social-media-input
                  class="clearfix"
                  *ngIf="currentPractice"
                  [displayFacebook]="false"
                  [displayGoogle]="false"
                  [displayiCloudCalendar]="true"
                  [displayGoogleCalendar]="true"
                  [displayMSExchange]="true"
                  [iCloudCalendarID]="
                    currentPractice && currentPractice.calendar ? currentPractice.calendar['Apple.ID'] : null
                  "
                  [googleCalendarID]="
                    currentPractice && currentPractice.calendar ? currentPractice.calendar['Google.ID'] : null
                  "
                  [msExchangeID]="
                    currentPractice && currentPractice.calendar ? currentPractice.calendar['Microsoft.ID'] : null
                  "
                  [iCloudCalendarStatus]="
                    currentPractice?.calendar && currentPractice?.calendar['Apple.ID'] ? 'valid' : 'warning'
                  "
                  [googleCalendarStatus]="
                    currentPractice?.calendar && currentPractice?.calendar['Google.ID'] ? 'valid' : 'warning'
                  "
                  [msExchangeStatus]="
                    currentPractice?.calendar && currentPractice?.calendar['Microsoft.ID'] ? 'valid' : 'warning'
                  "
                  [iCloudCalendarDate]="
                    currentPractice?.calendar && currentPractice?.calendar['Apple.AddedDate']
                      ? currentPractice?.calendar['Apple.AddedDate']
                      : null
                  "
                  [googleCalendarDate]="
                    currentPractice?.calendar && currentPractice?.calendar['Google.AddedDate']
                      ? currentPractice?.calendar['Google.AddedDate']
                      : null
                  "
                  [msExchangeDate]="
                    currentPractice?.calendar && currentPractice?.calendar['Microsoft.AddedDate']
                      ? currentPractice?.calendar['Microsoft.AddedDate']
                      : null
                  "
                  [iCloudCalendarButtonLabel]="
                    currentPractice && currentPractice.calendar && currentPractice?.calendar['Apple.ID']
                      ? 'Disconnect iCloud Calendar'
                      : 'Connect iCloud Calendar'
                  "
                  [googleCalendarButtonLabel]="
                    currentPractice && currentPractice.calendar && currentPractice?.calendar['Google.ID']
                      ? 'Disconnect Google Calendar'
                      : 'Connect Google Calendar'
                  "
                  [msExchangeButtonLabel]="
                    currentPractice && currentPractice.calendar && currentPractice?.calendar['Microsoft.ID']
                      ? 'Disconnect MS Exchange'
                      : 'Connect MS Exchange'
                  "
                  (getResult)="updateCurrentPracticeCalendar($event)"
                  iCloudCalendarLabel="iCloud Calendar"
                  googleCalendarLabel="Google Calendar"
                  msExchangeLabel="Microsoft Calendar"
                ></app-social-media-input>
              </div>
            </div>
          </div>
        </mat-card>
      </div>
    </div>
  </div>
  <div class="row clearfix full-width flex" *ngIf="displayStaffAppointment == true">
    <mat-card class="card-centered clearfix add-pad review text-center full-width mr ml">
      <!-- blue heading -->

      <div class="primary-gradient-img heading mb">
        <h2 class="sm-h2 white rm-m">
          Staff appointment availability
          <p class="rm-mt small">
            Manage your staffs availability for appointment along with their workings hours, you can also customized the
            appointment color for your staff to be used on appointment calendar
          </p>
        </h2>

        <button class="pull-left" (click)="backToMain()" mat-raised-button color="accent">
          <mat-icon>keyboard_arrow_left</mat-icon> Go back
        </button>
      </div>

      <div class="row clearfix mt full-width">
        <app-appointment-availability-list [merchantID]="merchantID" [title]="''" [description]="''">
        </app-appointment-availability-list>
      </div>

      <div class="full-width clearfix mt">
        <button class="pull-left" (click)="backToMain()" mat-raised-button color="accent">
          <mat-icon>keyboard_arrow_left</mat-icon> Go back
        </button>
      </div>
    </mat-card>
  </div>

  <div class="row clearfix full-width white-background" *ngIf="displayAppointmentLookup == true">
    <h2 class="lg-h2 sr-title ml" color="primary">Appointment Types and best contact times</h2>
    <p class="rm-mb ml">
      Set your appointment types and best contact times for your {{"KEYWORD.patient" | translate}}s to be used in your
      appointment page
    </p>

    <div class="full-width clearfix">
      <div class="row clearfix mt full-width">
        <app-appointment-lookup-list-overview [pageSize]="10" [displayAdmin]="displayAdmin" [merchantID]="merchantID">
        </app-appointment-lookup-list-overview>
      </div>

      <div class="full-width clearfix mt">
        <button class="pull-left" (click)="backToMain()" mat-raised-button color="accent">
          <mat-icon>keyboard_arrow_left</mat-icon> Go back
        </button>
      </div>
    </div>
  </div>

  <div class="row clearfix full-width" *ngIf="displayAppointmentLookupSorting == true">
    <div class="full-width clearfix">
      <div class="row clearfix mt full-width">
        <app-appointment-lookup-sort [merchantID]="merchantID"></app-appointment-lookup-sort>
      </div>

      <div class="full-width clearfix mt">
        <button class="pull-left" (click)="backToMain()" mat-raised-button color="accent">
          <mat-icon>keyboard_arrow_left</mat-icon> Go back
        </button>
      </div>
    </div>
  </div>
</div>
