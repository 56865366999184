import { Component, OnInit } from '@angular/core';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { HelperService } from '../shared/helper.service';

@Component({
  selector: 'app-message-us',
  templateUrl: './message-us.component.html',
  styleUrls: ['./message-us.component.css'],
})
export class MessageUsComponent implements OnInit {
  messageDetails = {
    firstName: '',
    lastName: '',
    email: '',
    subject: '',
    body: '',
  };

  brandName = Settings.global['brandName'];

  public messageSent = false;

  constructor(private helperService: HelperService) {}

  ngOnInit() {}

  contactus() {
    this.helperService.contactus(this.messageDetails).subscribe((res) => {
      if (res['success']) {
        this.resetData();
        NotifyAppComponent.displayToast('success', 'Success!', 'Your message has been sent.');
      }
    });
  }

  resetData() {
    this.messageSent = true;
    this.messageDetails = {
      firstName: '',
      lastName: '',
      email: '',
      subject: '',
      body: '',
    };
  }
}
