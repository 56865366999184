import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';

class DueSettlementsReportState {
  printButtonClicked: boolean;
}

@Injectable()
export class SettlementDueSettlementsReportService extends ComponentStore<DueSettlementsReportState> {
  constructor() {
    super({
      printButtonClicked: null,
    });
  }

  getPrintButtonClicked() {
    return this.select((state) => state.printButtonClicked);
  }

  setPrintButtonClicked(printButtonClicked: boolean) {
    return this.patchState({ printButtonClicked });
  }
}
