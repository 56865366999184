/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./existing-patient-view-picture.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/components/ep-document-view-image/ep-document-view-image.component.ngfactory";
import * as i3 from "../../../shared/components/ep-document-view-image/shared/ep-document-view-image.service";
import * as i4 from "../../../shared/components/ep-document-view-image/ep-document-view-image.component";
import * as i5 from "../../../core/authentication/shared/authentication.service";
import * as i6 from "@angular/material/dialog";
import * as i7 from "@angular/platform-browser";
import * as i8 from "../../../shared/services/utils.service";
import * as i9 from "./existing-patient-view-picture.component";
import * as i10 from "../shared/existing-patient.service";
var styles_ExisitingPersonViewPictureComponent = [i0.styles];
var RenderType_ExisitingPersonViewPictureComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ExisitingPersonViewPictureComponent, data: {} });
export { RenderType_ExisitingPersonViewPictureComponent as RenderType_ExisitingPersonViewPictureComponent };
export function View_ExisitingPersonViewPictureComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-ep-document-view-image", [["class", "full-width existing-view-vp"]], null, null, null, i2.View_EpDocumentViewImageComponent_0, i2.RenderType_EpDocumentViewImageComponent)), i1.ɵprd(131584, null, i3.EpDocumentViewImageService, i3.EpDocumentViewImageService, []), i1.ɵdid(2, 770048, null, 0, i4.EpDocumentViewImageComponent, [i5.AuthenticationService, i6.MatDialog, i7.DomSanitizer, [2, i6.MAT_DIALOG_DATA], i1.NgZone, i8.UtilsService, i3.EpDocumentViewImageService], { defaultPicture: [0, "defaultPicture"], link: [1, "link"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "url(https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/profile_placeholder.png)"; var currVal_1 = _co.profileLink; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_ExisitingPersonViewPictureComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-existing-patient-view-picture", [], null, null, null, View_ExisitingPersonViewPictureComponent_0, RenderType_ExisitingPersonViewPictureComponent)), i1.ɵdid(1, 638976, null, 0, i9.ExisitingPersonViewPictureComponent, [i10.ExistingPatientService, [2, i6.MAT_DIALOG_DATA]], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ExisitingPersonViewPictureComponentNgFactory = i1.ɵccf("app-existing-patient-view-picture", i9.ExisitingPersonViewPictureComponent, View_ExisitingPersonViewPictureComponent_Host_0, { existingPersonID: "existingPersonID" }, { close: "close" }, []);
export { ExisitingPersonViewPictureComponentNgFactory as ExisitingPersonViewPictureComponentNgFactory };
