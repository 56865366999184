import { transition, trigger, useAnimation } from '@angular/animations';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';

import {
  ngIfStackedCardViewInAnimation,
  ngIfStackedCardViewOutAnimation,
} from '../../../../../../../../../../shared/animations/ngIf/ngIfStackedCardView.animation';

import { MatDialog } from '@angular/material/dialog';
import { EmojiEvent } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable } from 'rxjs';
import { AppStateService } from '../../../../../../../../../../core/app-state/app-state.service';
import { AppState } from '../../../../../../../../../../core/app-state/app-state.type';
import { User } from '../../../../../../../../../../core/authentication/shared/shared/types/authentication.type';
import { RootAppComponent } from '../../../../../../../../../../shared/components/root-component/root-component.component';
import { UtilsService } from '../../../../../../../../../../shared/services/utils.service';
import { Settings } from '../../../../../../../../../../shared/types/settings';
import { UtilsClass } from '../../../../../../../../../../shared/types/utils/utils.class';
import { UtilSchema } from '../../../../../../../../../../shared/types/utils/utils.type';
import { Dentist } from '../../../../../../../../../dentist/shared/dentist.type';
import { DocumentListThumbnailComponent } from '../../../../../../../../../document/document-list-thumbnail/document-list-thumbnail.component';
import { CustomerAndProspect } from '../../../../../../../../../invitation/shared/types/invitation.type';
import { MerchantLinkCreateEditComponent } from '../../../../../../../../../merchant-link/merchant-link-create-edit/merchant-link-create-edit.component';
import { MerchantLinkLookupComponent } from '../../../../../../../../../merchant-link/merchant-link-lookup/merchant-link-lookup.component';
import { Merchant } from '../../../../../../../../../merchant/shared/types/merchant.type';
import { DENTIST_PERSONALIZATION_SCHEMA } from './shared/data/DENTIST_PERSONALIZATION_SCHEMA';
import { PATIENT_PERSONALIZATION_SCHEMA } from './shared/data/PATIENT_PERSONALIZATION_SCHEMA';
import { PRACTICE_PERSONALIZATION_SCHEMA } from './shared/data/PRACTICE_PERSONALIZATION_SCHEMA';
import {
  NoteTemplateFormModalComponent,
  NoteTemplateFormModalConfig,
} from './shared/modals/note-template-form-modal.component';
import { NoteTemplateModalComponent, NoteTemplateModalConfig } from './shared/modals/note-template-modal.component';
import { NoteTextAreaExpandedModalConfig, NoteTextModalComponent } from './shared/modals/note-text-modal.component';

@Component({
  selector: 'ipv-note-input',
  templateUrl: './note-input.component.html',
  styleUrls: ['./note-input.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        useAnimation(ngIfStackedCardViewInAnimation, {
          params: {
            timings: '0.8s',
          },
        }),
      ]),
      transition('* => void', [
        useAnimation(ngIfStackedCardViewOutAnimation, {
          params: {
            timings: '0.8s',
          },
        }),
      ]),
    ]),
  ],
})
export class NoteInputComponent implements OnInit, OnDestroy {
  util = new UtilsClass();

  @ViewChild('noteTextArea', { static: true }) noteTextAreaRef: ElementRef<HTMLTextAreaElement>;

  @Output() textUpdated: EventEmitter<string> = new EventEmitter();

  @Input() label = 'Enter a note here';

  @Input() practice: Partial<Merchant>;
  @Input() patient: Partial<CustomerAndProspect> | User;
  @Input() dentist: Dentist;

  @Input() messageTemplateID;

  @Input() size: 'large' | 'normal' | 'small' = 'normal';

  @Input() text: string;

  @Input() disabled: boolean;

  @Input() hideTemplateSaveButton: boolean;
  @Input() hideExpandButton: boolean;
  @Input() hideTemplateSelectButton: boolean;

  viewModel$: Observable<AppState>;

  displayEmojis: boolean;
  isModuleAppointmentActive: boolean;
  isModulePatientSMSActive: boolean;
  isModulePracticeLandingPageActive: boolean;
  isModuleDocumentLinkActive: boolean;
  isModuleCustomMessagesActive: boolean;

  patientPersonalizationSchema = this.sanitizeCodes(PATIENT_PERSONALIZATION_SCHEMA);

  patientPersonalizationMenu: UtilSchema[];

  dentistPersonalizationSchema = this.sanitizeCodes(DENTIST_PERSONALIZATION_SCHEMA);
  dentistPersonalizationMenu: UtilSchema[];

  practicePersonalizationSchema = this.sanitizeCodes(PRACTICE_PERSONALIZATION_SCHEMA);
  practicePersonalizationMenu: UtilSchema[];

  groups: string[];

  constructor(private appStateService: AppStateService, private utilsService: UtilsService, private dialog: MatDialog) {
    this.viewModel$ = this.appStateService.getAppState$();

    this.displayEmojis = Settings.global['displayEmojis'];
    this.isModuleAppointmentActive = Settings.global['isModuleAppointmentActive'];
    this.isModulePatientSMSActive = Settings.global['isModulePatientSMSActive'];
    this.isModulePracticeLandingPageActive = Settings.global['isModulePracticeLandingPageActive'];
    this.isModuleDocumentLinkActive = Settings.global['isModuleDocumentLinkActive'];
    this.isModuleCustomMessagesActive = Settings.global['isModuleCustomMessagesActive'];

    this.practicePersonalizationMenu = [];
    this.dentistPersonalizationMenu = [];
    this.patientPersonalizationMenu = [];

    this.groups = ['1', '2', '3', '4', '5'];
    this.disabled = false;
  }

  ngOnInit() {
    this.generatePatientPersonalizationMenu();
    this.generateDentistPersonalizationMenu();
    this.generatePracticePersonalizationMenu();
  }

  ngOnDestroy() {
    /** required for untilDestroyed (Replace with @UntilDestroyed() Decorator in angular 9 and delete ngOnDestroy()) */
  }

  insertTextAtCursor(text) {
    const noteTextArea = this.noteTextAreaRef.nativeElement;
    const noteTextAreaValue = noteTextArea.value;
    const cursorStart = noteTextArea.selectionStart;
    const cursorEnd = noteTextArea.selectionEnd;
    const noteTextAreaLength = noteTextArea.value.length;

    noteTextArea.focus();

    if (noteTextAreaLength > 0) {
      const textBefore = noteTextAreaValue.substring(0, cursorStart);
      const textAfter = noteTextAreaValue.substring(cursorEnd);
      noteTextArea.value = textBefore + text + textAfter;
      noteTextArea.selectionEnd = (textBefore + text).length;
      this.textUpdated.emit(noteTextArea.value);
    } else {
      noteTextArea.value = text;
      noteTextArea.selectionEnd = text.length;
      this.textUpdated.emit(noteTextArea.value);
    }
  }

  clearNote() {
    this.noteTextAreaRef.nativeElement.value = null;
    this.textUpdated.emit('');
  }

  insertEmoji(emoji: EmojiEvent) {
    this.insertTextAtCursor(emoji.emoji.native + ' ');
  }

  openNoteTemplateList() {
    const noteTemplateModal = this.dialog.open<NoteTemplateModalComponent, NoteTemplateModalConfig>(
      NoteTemplateModalComponent,
      {
        data: {
          practice: this.practice,
          patient: this.patient,
          dentist: this.dentist,
        },
      }
    );

    noteTemplateModal.componentInstance.templateSelected.pipe(untilDestroyed(this)).subscribe((noteTemplate) => {
      this.text = noteTemplate.Description;
    });
  }

  openNoteTemplateFormModal() {
    const noteTemplateFormModal = this.dialog.open<NoteTemplateFormModalComponent, NoteTemplateFormModalConfig>(
      NoteTemplateFormModalComponent,
      {
        data: {
          noteText: this.text,
          practice: this.practice,
          patient: this.patient,
          dentist: this.dentist,
        },
      }
    );

    noteTemplateFormModal.componentInstance.apply.pipe(untilDestroyed(this)).subscribe((noteText) => {
      this.text = noteText;
    });
  }

  insertLink() {
    const merchantLinkCreateEdit = RootAppComponent.dialog.open(MerchantLinkCreateEditComponent, {
      data: {
        createTemplate: false,
        displayAddTemplate: true,
        displayLookup: true,
      },
      width: '800px',
    });

    merchantLinkCreateEdit.componentInstance.close.pipe(untilDestroyed(this)).subscribe((res) => {
      merchantLinkCreateEdit.close();
    });

    merchantLinkCreateEdit.componentInstance.getResult.pipe(untilDestroyed(this)).subscribe((link) => {
      this.insertTextAtCursor(' ' + link['shortLink'] + '?t=ct ');
      merchantLinkCreateEdit.close();
    });

    merchantLinkCreateEdit.componentInstance.getLookup.pipe(untilDestroyed(this)).subscribe(() => {
      const ref2 = RootAppComponent.dialog.open(MerchantLinkLookupComponent, {
        data: {
          merchantID: this.practice.ID,
          viewOnly: false,
          isCreate: false,
        },
        width: '600px',
        height: '80vh',
      });

      ref2.componentInstance.close.pipe(untilDestroyed(this)).subscribe((res) => {
        ref2.close();
      });

      ref2.componentInstance.getResult.pipe(untilDestroyed(this)).subscribe((link) => {
        this.insertTextAtCursor(' ' + link['shortLink'] + '?t=ct ');
        ref2.close();
        ref2.afterClosed().subscribe((r) => {
          merchantLinkCreateEdit.close();
        });
      });
    });
  }

  documentLinkLookup() {
    const ref2 = RootAppComponent.dialog.open(DocumentListThumbnailComponent, {
      data: {
        isModal: true,
        viewOnly: false,
        isClonedStored: false,
        isClonedDefault: false,
        isClonedPublic: false,
        cloneDefault: true,
      },
      width: '85vw',

      panelClass: 'noCard',
    });

    ref2.componentInstance.close.subscribe(() => {
      ref2.close();
    });

    ref2.componentInstance.getLandingPageURL.subscribe((link) => {
      if (link) {
        this.utilsService.createShortcut(link).subscribe((r) => {
          if (r && r.shortLink) {
            if (link.Is_Finance === '1') {
              this.insertTextAtCursor(' ' + r.shortLink + '?t=df ');
            } else {
              this.insertTextAtCursor(' ' + r.shortLink + '?t=d ');
            }
            ref2.close();
          }
        });
      }
    });
  }

  updateText() {
    this.textUpdated.emit(this.text);
  }

  sanitizeCodes(utilSchemas: UtilSchema[]) {
    return utilSchemas.map((schema) => {
      const code = schema.code;
      const sanitizedCode = code.replace('{{', '').replace('}}', '');
      return { ...schema, code: sanitizedCode };
    });
  }

  expandNoteText() {
    const noteTextAreaExpandedModal = this.dialog.open<NoteTextModalComponent, NoteTextAreaExpandedModalConfig>(
      NoteTextModalComponent,
      {
        data: {
          noteText: this.noteTextAreaRef.nativeElement.value,
          patient: this.patient,
          dentist: this.dentist,
          practice: this.practice,
        },
      }
    );

    noteTextAreaExpandedModal.componentInstance.noteTextUpdated.pipe(untilDestroyed(this)).subscribe((noteText) => {
      this.noteTextAreaRef.nativeElement.value = noteText;
      this.textUpdated.emit(noteText);
    });
  }

  generatePatientPersonalizationMenu() {
    this.patientPersonalizationSchema.forEach((originalPatientPersonalizationSchemaItem) => {
      if (this.patient) {
        const patientPersonalizationSchemaItemValue = this.patient[originalPatientPersonalizationSchemaItem.field];
        if (patientPersonalizationSchemaItemValue) {
          const patientPersonalizationMenuItem = originalPatientPersonalizationSchemaItem;
          patientPersonalizationMenuItem.value = patientPersonalizationSchemaItemValue;
          patientPersonalizationMenuItem.code = ` {{${patientPersonalizationMenuItem.code}}} `;
          this.patientPersonalizationMenu.push(patientPersonalizationMenuItem);
        }
      }
    });
  }

  generateDentistPersonalizationMenu() {
    this.dentistPersonalizationSchema.forEach((originalDentistPersonalizationSchemaItem) => {
      if (this.patient) {
        const dentistPersonalizationSchemaItemValue = this.patient[originalDentistPersonalizationSchemaItem.field];
        if (dentistPersonalizationSchemaItemValue) {
          const dentistPersonalizationMenuItem = originalDentistPersonalizationSchemaItem;
          dentistPersonalizationMenuItem.value = dentistPersonalizationSchemaItemValue;
          dentistPersonalizationMenuItem.code = ` {{${dentistPersonalizationMenuItem.code}}} `;
          this.dentistPersonalizationMenu.push(dentistPersonalizationMenuItem);
        }
      }
    });
  }

  generatePracticePersonalizationMenu() {
    this.practicePersonalizationSchema.forEach((originalPracticePersonalizationSchemaItem) => {
      if (this.patient) {
        const practicePersonalizationSchemaItemValue = this.patient[originalPracticePersonalizationSchemaItem.field];
        if (practicePersonalizationSchemaItemValue) {
          const practicePersonalizationMenuItem = originalPracticePersonalizationSchemaItem;
          practicePersonalizationMenuItem.value = practicePersonalizationSchemaItemValue;
          practicePersonalizationMenuItem.code = ` {{${practicePersonalizationMenuItem.code}}} `;
          this.practicePersonalizationMenu.push(practicePersonalizationMenuItem);
        }
      }
    });
  }
}
