import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'environments/environment';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';

@Injectable()
export class OperatorService {
  private tipUrl: string = environment.nodeUrl + '/tip';

  constructor(private http: HttpClient) {}

  /**
   * @description
   * get full operator details
   * @param id
   */

  getOperatorDetails(id, payload = {} , sessionType='promoter') {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params: params,
      };
    }

    if(sessionType==='merchant-admin' || sessionType=='merchant')
    {
      return this.http
      .get(`${environment.nodeUrl}/operator/merchant/${id}`, options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
    }
    else {
      return this.http
      .get(`${environment.nodeUrl}/operator/${id}`, options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
    }

  }

  getfullOperatorDetails(id) {
    return this.http
      .get(`${environment.nodeUrl}/operator/full/${id}`)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getProfilePic(ID, payload = {}) {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params: params,
      };
    }
    return this.http
      .get(`${environment.nodeUrl}/operator/profile-picture/` + ID, options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  getProfilePicStreamLink(ID) {
    if (ID) return `${environment.nodeUrl}/operator/profile-picture-stream/` + ID;
    return null;
  }

  contactOperator(id, payload) {
    return this.http.post(`${environment.nodeUrl}/message/operator/${id}`, payload);
  }

  getOperatorList(payload) {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params: params,
      };
    }
    return this.http.get(`${environment.nodeUrl}/operator/`, options).pipe(map((res) => res['data']));
  }

  enableLogin(ID, sessionType): Observable<any> {
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http.put(`${environment.nodeUrl}/operator/enableLogin/global/${ID}`, {}).pipe(map((res) => res['data']));
    }
    else {
      return this.http.put(`${environment.nodeUrl}/operator/enableLogin/${ID}`, {}).pipe(map((res) => res['data']));
    }

  }

  disableLogin(ID, sessionType): Observable<any> {
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http.put(`${environment.nodeUrl}/operator/disableLogin/global/${ID}`, {}).pipe(map((res) => res['data']));
    }
    else {
      return this.http.put(`${environment.nodeUrl}/operator/disableLogin/${ID}`, {}).pipe(map((res) => res['data']));
    }

  }


  resetPassword(ID ,payload, sessionType): Observable<any> {
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http.put(`${environment.nodeUrl}/operator/update-password-prompt/global/${ID}`, payload).pipe(map((res) => res['data']));

    }
    else {
      return this.http.put(`${environment.nodeUrl}/operator/update-password-prompt/${ID}`, payload).pipe(map((res) => res['data']));
    }

  }

  operatorLookup(body): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (var key in body) {
      if (body.hasOwnProperty(key)) {
        params = params.set(key, body[key]);
      }
    }

    options = {
      params: params,
    };

    return this.http
      .get(environment.nodeUrl + '/operator/lookup/global', options)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  setMerchantAdmin(ID): Observable<any> {
    return this.http.put(`${environment.nodeUrl}/operator/setMerchantAdmin/${ID}`, {}).pipe(map((res) => res['data']));
  }

  unsetMerchantAdmin(ID): Observable<any> {
    return this.http
      .put(`${environment.nodeUrl}/operator/unsetMerchantAdmin/${ID}`, {})
      .pipe(map((res) => res['data']));
  }

  userNameExist(userName) {
    return this.http
      .get(`${environment.nodeUrl}/operator/exist/${userName}`, {})
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  changePassword(ID, payload) {
    return this.http.put(`${environment.nodeUrl}/operator/password-admin/${ID}`, payload);
  }

  getMerchantList(payload, isPromoterOrAdmin = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params: params,
    };

    if (isPromoterOrAdmin == true) {
      return this.http
        .get(environment.nodeUrl + '/merchant/global/', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/merchant/', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getSupplierList(payload, isPromoterOrAdmin = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params: params,
    };

    if (isPromoterOrAdmin == true) {
      return this.http
        .get(environment.nodeUrl + '/beneficiary/global/', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/beneficiary/', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }
  getPracticePicStreamLink(ID, LastModified=null) {
    if (
      LastModified &&
      LastModified != '' &&
      LastModified != undefined &&
      LastModified != 'null' &&
      LastModified != 'undefined '
    ) {
      return `${environment.nodeUrl}/merchant/practice-picture-stream-cached/${LastModified}/` + ID;
    } else if (ID) {
      if (ID) return `${environment.nodeUrl}/merchant/practice-picture-stream/` + ID;
    }
    return null;
  }

  getSupplierPicStreamLink(ID,  LastModified=null) {
    if (
      LastModified &&
      LastModified != '' &&
      LastModified != undefined &&
      LastModified != 'null' &&
      LastModified != 'undefined '
    ) {
      return `${environment.nodeUrl}/beneficiary/profile-picture-stream-cached/${LastModified}/` + ID;
    } else if (ID) {
      if (ID) return `${environment.nodeUrl}/beneficiary/profile-picture-stream/` + ID;
    }
    return null;
  }

  modifyPortalAccess(ID,payload ,  sessionType): Observable<any> {
    if (sessionType == 'admin' || sessionType == 'promoter') {
      return this.http.put(`${environment.nodeUrl}/operator/portal-access/global/${ID}`, payload).pipe(map((res) => res['data']));
    }
    else {
      return this.http.put(`${environment.nodeUrl}/operator/portal-access/${ID}`, payload).pipe(map((res) => res['data']));
    }

  }

}
