import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output, ViewChild } from '@angular/core';

import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { WizardComponent } from 'angular-archwizard';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { BankAccountListUserComponent } from '../bank-account-user/BankAccountListUserComponent';
import { BankAccountViewComponent } from '../bank-account-view/bank-account-view.component';
import { BankAccountService } from '../shared/bank-account.service';

@Component({
  selector: 'app-bank-account-create-edit',
  templateUrl: './bank-account-create-edit.component.html',
  styleUrls: ['./bank-account-create-edit.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.row', style({ opacity: 0 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.row', style({ opacity: 1 }), { optional: true }),
        query(
          '.row',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(-75%)', offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class BankAccountCreateEditComponent implements OnInit {
  @Input()
  userID;

  @Input()
  userName;

  title = null;
  description = 'Please Choose your account type';

  bankAccount: any = {};
  bankAccountCreated: any = {};

  validateBank = false;
  isPromoterOrAdmin = false;

  isModal = false;

  @Output()
  closeModal = new EventEmitter();

  @ViewChild(WizardComponent, { static: true }) public wizard: WizardComponent;

  constructor(
    private authenticationService: AuthenticationService,
    private activeRoute: ActivatedRoute,
    private bankAccountService: BankAccountService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.userID) {
        this.isModal = true;
        this.userID = data.userID;
      }

      if (data.userName) {
        this.userName = data.userName;
      }
    }
  }

  closeEvent() {
    this.closeModal.emit(true);
  }

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      if (params['id']) {
        this.userID = params['id'];
      }

      if (params['userName']) {
        this.userName = params['userName'];
      }
      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isPromoterOrAdmin = res;
        if (this.userID) {
          this.bankAccount.userID = this.userID;
        }
      });
    });
  }

  validateBankEvent(v) {
    this.validateBank = v || false;
  }

  getBankResult(r) {
    if (r) {
      if (r.paymentType == 'bank') {
        this.bankAccount.expiredDate = null;
        this.bankAccount.securityCode = null;
        this.bankAccount.bsb = r.bsb;
        this.bankAccount.bankName = r.bankName;
        this.bankAccount.bankBranch = r.bankBranch;
        this.bankAccount.accountHolder = r.accountHolder;
        this.bankAccount.accountNumber = r.accountNumber;
        this.bankAccount.accountType = r.accountType;
        this.bankAccount.paymentType = r.paymentType;
      } else if (r.paymentType == 'creditCard') {
        this.bankAccount.expiredDate = r.expiredDate;
        this.bankAccount.securityCode = r.securityCode;
        this.bankAccount.bsb = null;
        this.bankAccount.bankName = null;
        this.bankAccount.bankBranch = null;
        this.bankAccount.accountHolder = r.accountHolder;
        this.bankAccount.accountNumber = r.accountNumber;
        this.bankAccount.accountType = r.accountType;
        this.bankAccount.paymentType = r.paymentType;
      }
    }
  }

  createBankAccount() {
    this.bankAccountService.createBankAccount(this.bankAccount, this.isPromoterOrAdmin).subscribe((res) => {
      if (res) {
        this.bankAccountCreated = res;
        NotifyAppComponent.displayToast('success', 'Success!', 'Beneficiary has been create');

        this.wizard.goToNextStep();
      }
    });
  }

  viewBankAccount() {
    if (this.bankAccountCreated && this.bankAccountCreated.ID) {
      const ref = RootAppComponent.dialog.open(BankAccountViewComponent, {
        data: this.bankAccountCreated.ID,
        width: '600px',
      });

      ref.componentInstance.closeModal.subscribe((res) => {
        if (res == true) {
          ref.close();
        }
      });
    }
  }

  viewBankAccounts() {
    if (this.bankAccountCreated && this.bankAccountCreated.ID) {
      const ref = RootAppComponent.dialog.open(BankAccountListUserComponent, {
        data: {
          userID: this.userID,
          userName: this.userName,
        },
        width: '800px',
      });

      ref.componentInstance.closeModal.subscribe((res) => {
        if (res == true) {
          ref.close();
        }
      });
    }
  }
}
