import { Component, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'app-customer-list-intro-modal',
  templateUrl: './customer-list-intro-modal.component.html',
  styleUrls: ['./customer-list-intro-modal.component.css'],
})
export class CustomerListIntroModalComponent implements OnInit {
  showOnPatientListFirstVisit = JSON.parse(localStorage.getItem('showOnPatientListFirstVisit'));

  closeModal = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  doNotShow() {
    localStorage.setItem('showOnPatientListFirstVisit', JSON.stringify(this.showOnPatientListFirstVisit));

    this.closeModal.emit(this.showOnPatientListFirstVisit);
  }
}
