import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSortModule } from '@angular/material/sort';
import { NgPipesModule } from 'ngx-pipes';
import { SharedModule } from '../../shared/shared.module';
import { DentistService } from '../dentist/shared/dentist.service';
import { MessageModule } from '../message/message.module';
import { OperatorModule } from '../operator/operator.module';
import { ProductService } from '../product/shared/product.service';
import { DocumentCreateComponent } from './document-create/document-create.component';
import { DocumentLinkCreateComponent } from './document-link-create/document-link-create.component';
import { DocumentLinkDetailedViewRedirectionComponent } from './document-link-detailed-view-redirection/document-link-detailed-view-redirection.component';
import { DocumentLinkDetailedViewComponent } from './document-link-detailed-view/document-link-detailed-view.component';
import { DocumentLinkListOverviewComponent } from './document-link-list-overview/document-link-list-overview.component';
import { DocumentLinkListComponent } from './document-link-list/document-link-list.component';
import { DocumentLinkLookupComponent } from './document-link-lookup/document-link-lookup.component';
import { DocumentLinkPdfCreateComponent } from './document-link-pdf-create/document-link-pdf-create.component';
import { DocumentLinkShareComponent } from './document-link-share/document-link-share.component';
import { DocumentLinkViewComponent } from './document-link-view/document-link-view.component';
import { DocumentListMiniComponent } from './document-list-mini/document-list-mini.component';
import { DocumentListThumbnailComponent } from './document-list-thumbnail/document-list-thumbnail.component';
import { DocumentListComponent } from './document-list/document-list.component';
import { LandingPageHeaderImageComponent } from './landing-page-header-image/landing-page-header-image.component';
import { LandingPageHeaderLookupComponent } from './landing-page-header-lookup/landing-page-header-lookup.component';
import { LandingPageNewModalComponent } from './landing-page-new-modal/landing-page-new-modal.component';
import { SelfServiceLandingPageComponent } from './self-service-landing-page/self-service-landing-page.component';
import { DocumentRoutingModule } from './shared/document-routing.module';
import { DocumentService } from './shared/document.service';

@NgModule({
  imports: [
    NgPipesModule,
    CommonModule,
    FormsModule,
    CdkTableModule,
    CommonModule,
    ReactiveFormsModule,
    DocumentRoutingModule,
    SharedModule,
    MatSortModule,
    OperatorModule,
    MessageModule,
  ],
  declarations: [
    DocumentCreateComponent,
    DocumentListComponent,
    DocumentListMiniComponent,
    DocumentLinkCreateComponent,
    DocumentLinkListComponent,
    DocumentLinkListOverviewComponent,
    DocumentLinkLookupComponent,
    DocumentLinkViewComponent,
    LandingPageHeaderLookupComponent,
    LandingPageHeaderImageComponent,
    DocumentLinkDetailedViewComponent,
    DocumentLinkDetailedViewRedirectionComponent,
    DocumentListThumbnailComponent,
    DocumentLinkShareComponent,
    LandingPageNewModalComponent,
    DocumentLinkPdfCreateComponent,
    SelfServiceLandingPageComponent,
  ],
  exports: [
    DocumentCreateComponent,
    DocumentListComponent,
    DocumentListMiniComponent,
    DocumentLinkCreateComponent,
    DocumentLinkListComponent,
    DocumentLinkListOverviewComponent,
    DocumentLinkLookupComponent,
    DocumentLinkViewComponent,
    LandingPageHeaderLookupComponent,
    LandingPageHeaderImageComponent,
    DocumentLinkDetailedViewComponent,
    DocumentLinkDetailedViewRedirectionComponent,
    DocumentListThumbnailComponent,
    DocumentLinkShareComponent,
    LandingPageNewModalComponent,
    DocumentLinkPdfCreateComponent,
    SelfServiceLandingPageComponent,
  ],
  providers: [DocumentService, ProductService, DentistService],
})
export class DocumentModule {}
