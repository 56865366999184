import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable()
export class ActivityLogService {
  constructor(private http: HttpClient) {}

  getNoteTypeList(payload = {}, isPromoterOrAdmin = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    if (payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          params = params.set(key, payload[key]);
        }
      }

      options = {
        params,
      };
    }

    if (isPromoterOrAdmin == true) {
      return this.http.get(environment.nodeUrl + '/action-log-code/global', options).pipe(
        map((res: HttpResponse<any>) =>
          res['data']
            .map((noteType) => {
              const simulatedSystemTypeNumber = Math.round(Math.random());
              const simulatedSystemEnabledNumber = Math.round(Math.random());

              let enabled = false;
              let systemType = false;

              if (simulatedSystemTypeNumber === 1) {
                enabled = true;
              }

              if (simulatedSystemEnabledNumber === 1) {
                systemType = true;
              }

              return { ...noteType, enabled, systemType };
            })
            .filter((noteType) => noteType.systemType === false)
        )
      );
    } else {
      return this.http.get(environment.nodeUrl + '/action-log-code', options).pipe(
        map((res: HttpResponse<any>) =>
          res['data']
            .map((noteType) => {
              const simulatedSystemTypeNumber = Math.round(Math.random());
              const simulatedSystemEnabledNumber = Math.round(Math.random());

              let enabled = false;
              let systemType = false;

              if (simulatedSystemTypeNumber === 1) {
                enabled = true;
              }

              if (simulatedSystemEnabledNumber === 1) {
                systemType = true;
              }

              return { ...noteType, enabled, systemType };
            })
            .filter((noteType) => noteType.systemType === false)
        )
      );
    }
  }

  deleteNoteType(ID, isPromoterOrAdmin = false): Observable<any> {
    if (isPromoterOrAdmin == true) {
      return this.http
        .delete(environment.nodeUrl + '/action-log-code/global/' + ID)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .delete(environment.nodeUrl + '/action-log-code/' + ID)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }

  getNoteList(payload, isPromoterOrAdmin = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    let options = {};

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        params = params.set(key, payload[key]);
      }
    }

    options = {
      params,
    };

    if (isPromoterOrAdmin == true) {
      return this.http
        .get(environment.nodeUrl + '/action-log/global', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    } else {
      return this.http
        .get(environment.nodeUrl + '/action-log', options)
        .pipe(map((res: HttpResponse<any>) => res['data']));
    }
  }
}
