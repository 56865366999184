import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ContractService } from '../shared/contract.service';

@Component({
  selector: 'app-contracts-view',
  templateUrl: './contracts-view.component.html',
  styleUrls: ['./contracts-view.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.anim', style({ opacity: 0 }), { optional: true }),
        query(
          '.anim',
          stagger('100ms', [
            animate(
              '0.2s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(-20%)', offset: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
    trigger('onChangeAnimation', [
      transition('void => *', [
        query('.animate', style({ opacity: 0 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.15s ease-out',
              keyframes([
                style({ opacity: 0, transform: 'translateY(0)', offset: 0, height: 0, margin: 0 }),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0, height: '*', margin: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.animate', style({ opacity: 1 }), { optional: true }),
        query(
          '.animate',
          stagger('100ms', [
            animate(
              '0.15s ease-in',
              keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0, height: '*', margin: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, transform: 'translateY(0)', offset: 1.0, height: 0, margin: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
    trigger('ngIfGrow', [
      transition('void => *', [
        query('.animG', style({ opacity: 0 }), { optional: true }),
        query(
          '.animG',
          stagger('100ms', [
            animate(
              '0.3s ease-out',
              keyframes([
                style({ opacity: 0, offset: 0, height: 0, margin: 0 }),
                style({ opacity: 1, offset: 1.0, height: '*', margin: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class ContractsViewComponent implements OnInit {
  @Input()
  customerID;

  @Input()
  isSimple = false;

  @Input()
  isShowGoBackBtn = true;

  @Output()
  closeModal = new EventEmitter();

  isParameter = false;
  selectedContract;
  contract = null;
  settlements = [];
  contracts = [];

  contractKey = null;

  isPromoterOrAdmin = false;
  isDisplaySettlementCreate = false;
  isModal = false;

  constructor(
    private router: Router,
    private contractService: ContractService,
    private authenticationService: AuthenticationService,
    private activeRoute: ActivatedRoute,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.customerID) {
        this.customerID = data.customerID;
        this.isModal = true;
      }

      if (data.isSimple != null) {
        this.isSimple = data.isSimple;
      }
    }
  }

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      if (params['customerID']) {
        this.customerID = params['customerID'];
        this.isParameter = true;
      }

      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isPromoterOrAdmin = res;

        const payload = {
          customerID: this.customerID,
          onlyActive: true,
          fields:
            'ID,Customer_key,DateTimeCreated,ProductName,ApplicationDate,Settlements.NotYetDrawn,Amount.Invoice,Settlements.Drawn,' +
            'NextDrawDown.Before',
        };

        this.contractService.getContractList(payload, this.isPromoterOrAdmin).subscribe((res) => {
          if (res) {
            this.contracts = res;
          }
        });
      });
    });
  }

  selectContract(_contract) {
    this.selectedContract = _contract;
  }

  closeModalEvent() {
    this.closeModal.emit(true);
  }

  contractView() {
    this.closeModal.emit(true);
    this.router.navigate(['/merchant', { outlets: { page: ['contract-view', this.selectedContract.ID] } }]);
  }
}
