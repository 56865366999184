import * as moment from 'moment';
import { UtilsClass } from '../../../shared/types/utils/utils.class';

export class AppointmentUtilClass {
  util = new UtilsClass();

  operations = {
    proposeBooking: {
      code: 'proposeBooking',
      icon: 'add_alarm',
      label: 'Propose Booking',
      color: this.getStatusColor('BOOKED'),
    },
    confirmBooking: {
      code: 'confirmBooking',
      icon: 'alarm_on',
      label: 'Confirm booking',
      color: this.getStatusColor('BOOKED'),
    },
    confirmBookingMerchant: {
      code: 'confirmBookingMerchant',
      icon: 'alarm_on',
      label: 'Confirm booking',
      color: this.getStatusColor('BOOKED'),
    },
    requestDateChangeBooking: {
      code: 'requestDateChangeBooking',
      icon: 'alarm_off',
      label: 'Request a date change',
      color: this.getStatusColor('REQUESTDATECHANGE'),
    },

    requestReschedule: {
      code: 'requestReschedule',
      icon: 'alarm_add',
      label: 'Request a rescheduling',
      color: this.getStatusColor('REQUESTRESCHEDULE'),
    },
    requestNew: {
      code: 'requestNew',
      icon: 'calendar_today',
      label: 'Request an Appointment',
      color: this.getStatusColor('WAITINGCONFIRMATION'),
    },
    reschedule: {
      code: 'reschedule',
      icon: 'alarm_add',
      label: 'Reschedule',
      color: this.getStatusColor('RESCHEDULED'),
    },
    attend: {
      code: 'attend',
      icon: 'check_circle',
      label: 'Mark as Attended',
      color: this.getStatusColor('ATTENDED'),
    },
    miss: {
      code: 'miss',
      icon: 'av_timer',
      label: 'Mark as missed',
      color: this.getStatusColor('MISSED'),
    },
    cancelMerchant: {
      code: 'cancelMerchant',
      icon: 'cancel',
      label: 'Cancel the appointment',
      color: this.getStatusColor('CANCELEDBYPRACTICE'),
    },
    cancelPatient: {
      code: 'cancelPatient',
      icon: 'cancel',
      label: 'Cancel the appointment',
      color: this.getStatusColor('CANCELED'),
    },

    assignDentist: {
      code: 'assignDentist',
      icon: 'person_pin',
      label: 'Assign Dentist',
    },
  };

  constructor() {}

  getStatusColor(item) {
    let _status;
    if (typeof item === 'object' && item['Status.Code']) {
      _status = item['Status.Code'];
    } else if (typeof item === 'string') {
      _status = item;
    }

    if (_status) {
      if (_status === 'REQUESTED') {
        return '#ff4500';
      } else if (_status === 'BOOKED') {
        return '#7ac74f';
      } else if (_status === 'RESCHEDULED') {
        return '#d65703';
      } else if (_status === 'CANCELED') {
        return '#ee1023';
      } else if (_status === 'CANCELEDBYPRACTICE') {
        return '#ee1023';
      } else if (_status === 'ATTENDED') {
        return '#abc32f';
      } else if (_status === 'MISSED') {
        return '#e30561';
      } else if (_status === 'REQUESTRESCHEDULE') {
        return '#ff8d00';
      } else if (_status === 'WAITINGCONFIRMATION') {
        return '#ff8d00';
      } else if (_status === 'REQUESTDATECHANGE') {
        return '#ffbc00';
      }
    }
    return '#a0a0a0';
  }

  getTitle(item) {
    let _status;
    if (typeof item === 'object' && item['Status.Code']) {
      _status = item['Status.Code'];
    } else if (typeof item === 'string') {
      _status = item;
    }

    if (_status) {
      if (_status === 'WAITINGCONFIRMATION') {
        return 'Waiting for confirmation';
      } else if (_status === 'REQUESTED') {
        return 'Appointment Requested';
      } else if (_status === 'BOOKED') {
        return 'Appointment  Booked';
      } else if (_status === 'CANCELED') {
        return 'Appointment Canceled';
      } else if (_status === 'CANCELEDBYPRACTICE') {
        return 'Canceled By Practice';
      } else if (_status === 'ATTENDED') {
        return 'Appointment Attended';
      } else if (_status === 'MISSED') {
        return 'Appointment Missed';
      } else if (_status === 'REQUESTRESCHEDULE') {
        return 'Request Rescheduling ';
      } else if (_status === 'REQUESTDATECHANGE') {
        return 'Request date change';
      }
    }
  }

  getMessage(item) {
    if (item) {
      let result;

      if (item['Status.Code'] === 'WAITINGCONFIRMATION' && item['Date_Rescheduled'] == '0000-00-00') {
        result = {
          message: item['Merchant_Comment'] || null,
          title: 'Message from the practice',
        };
      } else if (item['Status.Code'] === 'WAITINGCONFIRMATION' && item['Date_Rescheduled'] != '0000-00-00') {
        result = {
          message: item['Rescheduled_Comment'] || null,
          title: 'Message from the practice',
        };
      } else if (item['Status.Code'] === 'MISSED') {
        result = {
          message: item['Missed_Comment'] || null,
          title: 'Message from the practice',
        };
      } else if (item['Status.Code'] === 'CANCELED') {
        result = {
          message: item['Canceled_Comment'] || null,
          title: 'Canceling Patient Note',
        };
      } else if (item['Status.Code'] === 'CANCELEDBYPRACTICE') {
        result = {
          message: item['Canceled_Merchant_Comment'] || null,
          title: 'Canceling Practice Note',
        };
      } else if (item['Status.Code'] === 'REQUESTRESCHEDULE') {
        result = {
          message: item['Request_Reschedule_Comment'] || null,
          title: 'Your message',
        };
      } else if (item['Status.Code'] === 'REQUESTDATECHANGE') {
        result = {
          message: item['Request_Date_Change_Comment'] || null,
          title: 'Your message',
        };
      }

      return result;
    }

    return null;
  }

  getStatusDescription(item, isMini = true) {
    let _status;
    if (typeof item === 'object' && item['Status.Code']) {
      _status = item['Status.Code'];
    } else if (typeof item === 'string') {
      _status = item;
    }

    if (_status) {
      if (_status === 'REQUESTED') {
        if (isMini == true) {
          return 'Requested, waiting for booking time.';
        } else {
          return 'Appointment request is sent, waiting for booking suggestion.';
        }
      } else if (_status === 'BOOKED') {
        if (isMini == true) {
          return 'Booking date has been confirmed.';
        } else {
          return 'Booking date has been confirmed by the patient.';
        }
      } else if (_status === 'RESCHEDULED') {
        if (isMini == true) {
          return 'booked and rescheduled.';
        } else {
          return 'booked and rescheduled.';
        }
      } else if (_status === 'CANCELED' && item['Date'] && item['Date'] != '0000-00-00') {
        if (isMini == true) {
          return 'Booked and canceled.';
        } else {
          return 'The appointment was canceled after confirming the booking.';
        }
      } else if (_status === 'CANCELED' && (!item['Date'] || item['Date'] == '0000-00-00')) {
        if (isMini == true) {
          return 'Requested and canceled.';
        } else {
          return 'Requested and canceled before suggesting a booking.';
        }
      } else if (_status === 'CANCELEDBYPRACTICE' && item['Date'] && item['Date'] != '0000-00-00') {
        if (isMini == true) {
          return 'Booked and canceled by the practice.';
        } else {
          return 'The appointment was canceled  by the practice after confirming the booking.';
        }
      } else if (_status === 'CANCELEDBYPRACTICE' && (!item['Date'] || item['Date'] == '0000-00-00')) {
        if (isMini == true) {
          return 'Requested and canceled  by the practice.';
        } else {
          return 'Requested and canceled  by the practice before suggesting a booking.';
        }
      } else if (_status === 'ATTENDED') {
        if (isMini == true) {
          return 'Was attended by the patient.';
        } else {
          return 'Patient attended the appointment on time.';
        }
      } else if (_status === 'MISSED') {
        if (isMini == true) {
          return 'Patient did not attend.';
        } else {
          return 'The patient missed the Appointment.';
        }
      } else if (_status === 'REQUESTRESCHEDULE') {
        if (isMini == true) {
          return 'Patient requested a rescheduling.';
        } else {
          return 'Patient requested a rescheduling of a booked appointment.';
        }
      } else if (_status === 'WAITINGCONFIRMATION') {
        if (isMini == true) {
          return 'Booking suggested waiting for confirmation.';
        } else {
          return 'Booking suggested waiting for patient confirmation.';
        }
      } else if (_status === 'REQUESTDATECHANGE') {
        if (isMini == true) {
          return 'Requesting a booking suggestion.';
        } else {
          return 'The booking time was not suitable, the patient is requesting a date change.';
        }
      }
    }
    return '';
  }

  getOperationsBySessionType(sessionType) {
    const result = [];
    if (
      sessionType === 'merchant' ||
      sessionType === 'promoter' ||
      sessionType === 'admin' ||
      sessionType === 'merchant-admin'
    ) {
      result.push(this.operations['cancelMerchant']);
      result.push(this.operations['attend']);
      result.push(this.operations['miss']);
      result.push(this.operations['assignDentist']);
      result.push(this.operations['confirmBookingMerchant']);
    } else {
      result.push(this.operations['cancelPatient']);
      result.push(this.operations['confirmBooking']);
      result.push(this.operations['requestDateChangeBooking']);

      result.push(this.operations['requestReschedule']);
    }

    return result;
  }

  getOperations(item, sessionType) {
    const result = [];

    if (item && item['Status.Code']) {
      const _s = item['Status.Code'];

      if (_s === 'REQUESTED') {
        if (sessionType === 'admin' || sessionType === 'promoter') {
          result.push(this.operations['cancelMerchant']);
          result.push(this.operations['proposeBooking']);
        } else if (sessionType === 'merchant' || sessionType === 'merchant-admin') {
          result.push(this.operations['cancelMerchant']);
          result.push(this.operations['proposeBooking']);
        } else if (sessionType === 'customer' || sessionType === 'consumer' || sessionType === 'guest') {
          result.push(this.operations['cancelPatient']);
        } else {
          result.push(this.operations['cancelPatient']);
        }
      } else if (_s === 'BOOKED') {
        if (sessionType === 'admin' || sessionType === 'promoter') {
          result.push(this.operations['reschedule']);

          if (
            item['Date'] != '0000-00-00' &&
            this.util.getDateTimeZone(item['Date'], 'YYYY-MM-DD').isSameOrBefore() == true
          ) {
            result.push(this.operations['miss']);
            result.push(this.operations['attend']);
          } else {
            result.push(this.operations['cancelMerchant']);
          }
        } else if (sessionType === 'merchant' || sessionType === 'merchant-admin') {
          result.push(this.operations['reschedule']);

          if (
            item['Date'] != '0000-00-00' &&
            this.util.getDateTimeZone(item['Date'], 'YYYY-MM-DD').isSameOrBefore() == true
          ) {
            result.push(this.operations['miss']);
            result.push(this.operations['attend']);
          } else {
            result.push(this.operations['cancelMerchant']);
          }
        } else if (sessionType === 'customer' || sessionType === 'consumer') {
          if (this.isDatePass(item) === false) {
            result.push(this.operations['cancelPatient']);

            result.push(this.operations['requestReschedule']);
          }
        } else {
          if (this.isDatePass(item) === false) {
            result.push(this.operations['cancelPatient']);

            result.push(this.operations['requestReschedule']);
          }
        }
      } else if (_s === 'REQUESTRESCHEDULE') {
        if (sessionType === 'admin' || sessionType === 'promoter') {
          result.push(this.operations['cancelMerchant']);
          result.push(this.operations['reschedule']);
        } else if (sessionType === 'merchant' || sessionType === 'merchant-admin') {
          result.push(this.operations['cancelMerchant']);
          result.push(this.operations['reschedule']);
        } else if (sessionType === 'customer' || sessionType === 'consumer') {
          result.push(this.operations['cancelPatient']);
        } else {
          result.push(this.operations['cancelPatient']);
        }
      } else if (_s === 'WAITINGCONFIRMATION') {
        if (sessionType === 'admin' || sessionType === 'promoter') {
          result.push(this.operations['reschedule']);

          if (
            item['Date'] != '0000-00-00' &&
            this.util.getDateTimeZone(item['Date'], 'YYYY-MM-DD').isSameOrBefore() == true
          ) {
            result.push(this.operations['miss']);
            result.push(this.operations['attend']);
          } else {
            result.push(this.operations['cancelMerchant']);
            result.push(this.operations['confirmBookingMerchant']);
          }
        } else if (sessionType === 'merchant' || sessionType === 'merchant-admin') {
          result.push(this.operations['reschedule']);

          if (
            item['Date'] != '0000-00-00' &&
            this.util.getDateTimeZone(item['Date'], 'YYYY-MM-DD').isSameOrBefore() == true
          ) {
            result.push(this.operations['miss']);
            result.push(this.operations['attend']);
          } else {
            result.push(this.operations['cancelMerchant']);
            result.push(this.operations['confirmBookingMerchant']);
          }
        } else if (sessionType === 'customer' || sessionType === 'consumer') {
          if (this.isDatePass(item) === false) {
            result.push(this.operations['cancelPatient']);

            result.push(this.operations['requestDateChangeBooking']);
            result.push(this.operations['confirmBooking']);
          }
        } else {
          if (this.isDatePass(item) === false) {
            result.push(this.operations['cancelPatient']);

            result.push(this.operations['requestDateChangeBooking']);
            result.push(this.operations['confirmBooking']);
          }
        }
      } else if (_s === 'REQUESTDATECHANGE') {
        if (sessionType === 'admin' || sessionType === 'promoter') {
          result.push(this.operations['cancelMerchant']);
          result.push(this.operations['proposeBooking']);
        } else if (sessionType === 'merchant' || sessionType === 'merchant-admin') {
          result.push(this.operations['cancelMerchant']);
          result.push(this.operations['proposeBooking']);
        } else if (sessionType === 'customer' || sessionType === 'consumer') {
          result.push(this.operations['cancelPatient']);
        } else {
          result.push(this.operations['cancelPatient']);
        }
      }
    }

    return result;
  }

  appointmentHasStatus(s, item, sessionType) {
    if (s) {
      const r = this.getOperations(item, sessionType);

      if (r && r.length > 0) {
        const i = r.findIndex((item) => {
          if (item && item.code && item.code == s) {
            return true;
          }
          return false;
        });
        if (i != -1) {
          return true;
        }
      }
    }

    return false;
  }

  getDurationArray() {
    const result = [
      {
        code: '10',
        label: '10 mins',
      },
      {
        code: '15',
        label: '15 mins',
      },
      {
        code: '30',
        label: '30 mins',
      },
      {
        code: '60',
        label: '60 mins',
      },
      {
        code: '90',
        label: '90 mins',
      },
      {
        code: '120',
        label: '2 hours',
      },
      {
        code: '180',
        label: '3 hours',
      },
    ];

    return result;
  }

  getBufferArray() {
    const result = [
      {
        code: '0',
        label: 'No buffer',
      },
      {
        code: '10',
        label: '10 mins',
      },
      {
        code: '15',
        label: '15 mins',
      },
      {
        code: '30',
        label: '30 mins',
      },
      {
        code: '45',
        label: '45 mins',
      },
      {
        code: '60',
        label: '60 mins',
      },
    ];

    return result;
  }

  buildCalendarEvent(appointment) {
    let start;
    let end;
    let summary = `${appointment.CustomerOrProspect_CalculatedName} - ${appointment['Type.Label']}`;
    if (appointment['SubType.Label']) {
      summary += `: ${appointment['SubType.Label']}`;
    }

    if (appointment['MerchantContact_CalculatedName']) {
      summary += `, Assigned to ${appointment['MerchantContact_CalculatedName']} `;
    }

    if (
      appointment['Date'] &&
      appointment['Date'] != '0000-00-00' &&
      (!appointment['Date_End'] || appointment['Date_End'] == '0000-00-00')
    ) {
      const time = moment(
        this.util.getDateTimeZone(appointment['Date'] + ' ' + appointment['Time'], 'MM-DD-YYYY hh:mm A')
      ).format('hh:mm A');
      summary += `, At ${time} `;
      start = this.util.getDateTimeZone(appointment['Date'] + ' ' + appointment['Time'], 'MM-DD-YYYY hh:mm A').toDate();

      end = this.util
        .getDateTimeZone(appointment['Date_End'] + ' ' + appointment['Time_End'], 'MM-DD-YYYY hh:mm A')
        .toDate();
    }

    if (
      appointment['Date'] &&
      appointment['Date_End'] &&
      appointment['Date'] != '0000-00-00' &&
      appointment['Date_End'] != '0000-00-00'
    ) {
      const time = moment(
        this.util.getDateTimeZone(appointment['Date'] + ' ' + appointment['Time'], 'MM-DD-YYYY hh:mm A')
      ).format('hh:mm A');
      const time2 = moment(
        this.util.getDateTimeZone(appointment['Date_End'] + ' ' + appointment['Time_End'], 'MM-DD-YYYY hh:mm A')
      ).format('hh:mm A');

      summary += ` From ${time} To ${time2}`;

      start = this.util.getDateTimeZone(appointment['Date'] + ' ' + appointment['Time'], 'MM-DD-YYYY hh:mm A').toDate();
      end = this.util
        .getDateTimeZone(appointment['Date_End'] + ' ' + appointment['Time_End'], 'MM-DD-YYYY hh:mm A')
        .toDate();
    }

    return {
      summary,
      start: {
        dateTime: start,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      end: {
        dateTime: end,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    };
  }

  canRequestNewAppointment(appointment) {
    if (appointment) {
      if (
        appointment['Status.Code'] == 'ATTENDED' ||
        appointment['Status.Code'] == 'MISSED' ||
        appointment['Status.Code'] == 'CANCELED' ||
        appointment['Status.Code'] == 'CANCELEDBYPRACTICE' ||
        (appointment['Status.Code'] == 'BOOKED' && this.isDatePass(appointment) == true) ||
        (appointment['Status.Code'] == 'WAITINGCONFIRMATION' && this.isDatePass(appointment) == true)
      ) {
        return true;
      } else {
        return false;
      }
    }

    return false;
  }

  isDatePass(item) {
    if (
      item &&
      item['Date'] != '0000-00-00' &&
      this.util.getDateTimeZone(item['Date'], 'YYYY-MM-DD').isAfter() == true
    ) {
      return false;
    } else {
      return true;
    }
  }
}
