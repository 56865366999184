import { Component, EventEmitter, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { AuthenticationService } from '../../authentication/shared/authentication.service';
import { SubscriptionPlanService } from '../shared/subscription-plan.service';

@Component({
  selector: 'app-feature-sub-label-quick-edit',
  templateUrl: './feature-sub-label-quick-edit.component.html',
  styleUrls: ['./feature-sub-label-quick-edit.component.css'],
})
export class FeatureSubLabelQuickEditComponent implements OnInit {
  subFeatureID;
  subFeature;

  isConsumer;
  isActive;
  isNew;
  isMerchant;
  isSideMenu;
  isMainSideMenu;
  icon = 'fas fa-tooth';
  label;
  sideMenuLabel;
  pinchZoomProperties = [
    {
      wheel: true,
      autoHeight: true,
    },
  ];

  isSubPlanFeature = true;
  code;
  postPictureLink;
  postPicture;
  fileID;
  isUnique = true;
  mainLabel;
  utils = new UtilsClass();
  maxFileSize = 5;
  allowExtensions = ['jpeg', 'jpg', 'png','heic', 'gif', 'tiff', 'bmp', 'ico'];
  applyDownload = new EventEmitter();
  loadingPost = false;
  color = Settings.global['primaryColor'] || '#1e88e5';
  colorSecond;
  description;
  isPublic;
  sessionType;

  maxLength = 300;

  context = Settings.global['context'];
  close = new EventEmitter();
  getResult = new EventEmitter();

  constructor(
    private authenticationService: AuthenticationService,
    private subscriptionPlanService: SubscriptionPlanService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.subFeatureID) {
        this.subFeatureID = data.subFeatureID;
      }
    }
  }

  ngOnInit() {



    this.authenticationService.getSessionType().subscribe((res) => {
      this.sessionType = res;

      this.setup();
    });
  }

  changeLabel() {
    if (this.label && !this.sideMenuLabel) {
      this.sideMenuLabel = this.label;

      if (!this.code) {
        if (this.label && this.mainLabel) {
          this.code = (this.mainLabel + '_' + this.label).replace(/ /g, '_');
          this.code = this.code.replace(/#/g, '_');
          this.code = this.code.replace(/\//g, '_');
          this.isUniqueEvent();
        }
      }
    }
  }

  setup() {
    if (this.subFeatureID) {
      const p = {
        selectMain: false,
        fields:
          'ID,DateTimeCreated,Icon,' +
          'SubLabel,Is_Marketing_Only,Is_Deactivated,Description,Is_NewFeature,Is_Side_Menu,MainLabel,Side_Menu_Label,Is_Private,Is_Merchant_Feature,Is_Consumer_Feature,Is_Main_Side_Menu',
      };
      this.subscriptionPlanService
        .getFeatureSubLabelDetails(this.subFeatureID, p, this.sessionType)
        .subscribe((res) => {
          if (res) {
            this.subFeature = res;
            if (res.SubLabel) {
              this.label = res.SubLabel;
            }

            if (res.MainLabel) {
              this.mainLabel = res.MainLabel;
            }

            if (res.FE_Code) {
              this.code = res.FE_Code;
            } else if (this.label && this.mainLabel) {
              this.code = (this.mainLabel + '_' + this.label).replace(/ /g, '_');
              this.code = this.code.replace(/#/g, '_');
              this.code = this.code.replace(/\//g, '_');
              this.isUniqueEvent();
            }

            if (res.Color) {
              this.color = res.Color.split('|')[0];
              this.colorSecond = res.Color.split('|')[1];
            }
            if (res.Description) {
              this.description = res.Description;
            }

            this.loadingPost = true;
            this.subscriptionPlanService.getFeatureSubLabelCoverImage(res.ID).subscribe((res) => {
              if (res) {
                this.postPicture = res;
              } else {
                this.postPicture = null;
              }
              this.loadingPost = false;
            });

            if (res['Is_Marketing_Only'] === '1') {
              this.isSubPlanFeature = false;
            } else {
              this.isSubPlanFeature = true;
            }

            if (res['Icon']) {
              this.icon = res['Icon'];
            }
            if (res['Is_Side_Menu'] === '0') {
              this.isSideMenu = false;
            } else {
              this.isSideMenu = true;
            }

            if (res['Is_Private'] === '1') {
              this.isPublic = false;
            } else {
              this.isPublic = true;
            }

            if (res['Is_Merchant_Feature'] === '0') {
              this.isMerchant = false;
            } else {
              this.isMerchant = true;
            }

            if (res['Is_Main_Side_Menu'] === '0') {
              this.isMainSideMenu = false;
            } else {
              this.isMainSideMenu = true;
            }

            if (res['Is_Consumer_Feature'] === '0') {
              this.isConsumer = false;
            } else {
              this.isConsumer = true;
            }
            if (res['Side_Menu_Label']) {
              this.sideMenuLabel = res['Side_Menu_Label'];
            } else if (res['SubLabel']) {
              this.sideMenuLabel = res['SubLabel'];
            }

            if (res['Is_Deactivated'] === '1') {
              this.isActive = false;
            } else {
              this.isActive = true;
            }

            if (res['Is_NewFeature'] === '1') {
              this.isNew = true;
            } else {
              this.isNew = false;
            }
          }
        });
    }
  }

  getContent(d) {
    this.description = d;
  }

  isUniqueEvent() {
    if (this.code) {
      const payload: any = { code: this.code };

      if (this.subFeatureID) {
        payload.id = this.subFeatureID;
      }

      this.subscriptionPlanService.isUniqueFeaturSubLabel(payload).subscribe((res) => {
        if (res === true) {
          this.isUnique = true;
        } else {
          this.isUnique = false;
        }
      });
    }
  }

  save() {
    if (this.subFeatureID) {
      const payload: any = {
        code: this.code,
        description: this.description,
        label: this.label,
        isActive: this.isActive,
        icon: this.icon || null,
        isNew: this.isNew,
        fileIDCover: this.fileID || null,
        isConsumer: this.isConsumer,
        isMerchant: this.isMerchant,
        sideMenuLabel: this.sideMenuLabel,
        isPublic: this.isPublic,
        isSideMenu: this.isSideMenu,
        isMainSideMenu: this.isMainSideMenu,
        isMarketingOnly: !this.isSubPlanFeature,
      };

      this.subscriptionPlanService.editFeatureSubLabel(this.subFeatureID, payload).subscribe((res) => {
        if (res) {
          this.getResult.emit(res);
          NotifyAppComponent.displayToast('success', 'Success!', 'Sub Feature Label is Modified');
        }
      });
    }
  }

  closeEvent() {
    this.close.emit(true);
  }
}
