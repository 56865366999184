<div class="full-width clearfix mt">
  <button class="addDocument pull-right mr" [routerLink]="['/admin/', 'theme-create']" mat-mini-fab color="primary">
    <mat-icon>add</mat-icon>
  </button>

  <p>Click on a theme to preview it , it will not be saved until you click on "Apply &amp; Save" .</p>
  <p>if you did not save your changes the theme will be active untill you reload the page .</p>
  <div class="full-width clearfix row">
    <div class="qtr-width cards mt" *ngFor="let theme of themes">
      <mat-card
        class="themeCard themeBackground clearfix ml mr"
        [style.background-image]="'url(' + baseURL + '/files/' + theme.backgroundId + '/view' + ')'"
        [ngClass]="{ selectedTheme: theme._id == selectedThemeID }"
      >
        <div class="row clearfix cardheader cardheaderPadding primary-gradient-img inModal">
          <div class="action-button pull-right">
            <button class="actionMenuToggle" [matMenuTriggerFor]="menu" mat-icon-button>
              <mat-icon class="whiteColor">more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button (click)="deleteTheme(theme)" mat-menu-item matTooltip="Delete theme">
                <mat-icon>delete</mat-icon>

                Delete
              </button>
              <button (click)="editTheme(theme)" mat-menu-item matTooltip="Edit theme">
                <mat-icon>mode_edit</mat-icon>

                Edit
              </button>

              <button
                *ngIf="theme.cssFileId"
                (click)="download(theme.cssFileId)"
                mat-menu-item
                matTooltip="Download CSS"
              >
                <mat-icon>get_app</mat-icon>

                CSS
              </button>

              <button
                *ngIf="theme.scssFileId"
                (click)="download(theme.scssFileId)"
                mat-menu-item
                matTooltip="Download SCSS"
              >
                <mat-icon>get_app</mat-icon>

                SCSS
              </button>
            </mat-menu>
          </div>

          <div class="row titleArea clearfix">
            <h2 class="summary-header rm-m white ml" matTooltip="{{ theme.description || theme.name }}">
              {{ theme.name }}

              <span class="chip2 chipGood" *ngIf="theme._id == selectedThemeID">Active</span>
            </h2>
          </div>
        </div>

        <div class="theme-container clearfix">
          <div class="full-width text-center clearfix row flex">
            <app-ep-document-view-image
              class="themeImage text-center"
              *ngIf="theme.backgroundId && baseURL"
              [hideZoomButton]="false"
              [imageLink]="baseURL + '/files/' + theme.backgroundId + '/view'"
            >
            </app-ep-document-view-image>

            <app-ep-document-view-image
              class="themeImage text-center"
              *ngIf="theme.logoId && baseURL"
              [hideZoomButton]="false"
              [imageLink]="baseURL + '/files/' + theme.logoId + '/view'"
            >
            </app-ep-document-view-image>
          </div>

          <div
            class="full-width text-center clearfix row flex mt mb"
            *ngIf="theme && theme['scssColors'] && theme['scssColors'].length > 0"
          >
            <div
              class="color-dev text-center"
              *ngFor="let s of theme['scssColors']"
              [style.background]="s['color']"
            ></div>
          </div>

          <div
            class="full-width text-center clearfix row flex mt mb"
            *ngIf="theme && (!theme['scssColors'] || (theme['scssColors'] && theme['scssColors'].length <= 0))"
          >
            <div class="color-dev text-center"></div>

            <div class="color-dev text-center"></div>

            <div class="color-dev text-center"></div>

            <div class="color-dev text-center"></div>

            <div class="color-dev text-center"></div>
          </div>

          <mat-card-actions>
            <div class="full-width text-center clearfix row">
              <button
                class="btn btn-primary"
                (click)="apply(theme)"
                mat-raised-button
                type="submit"
                color="primary"
                matTooltip="Apply Theme"
              >
                <mat-icon>format_paint</mat-icon>Apply
              </button>

              <button
                class="btn btn-primary"
                (click)="saveAndApplyTheme(theme)"
                mat-raised-button
                type="submit"
                color="accent"
                matTooltip="Apply And Save Theme"
              >
                Apply &amp; Save
              </button>
            </div>
          </mat-card-actions>
        </div>
      </mat-card>
    </div>
  </div>
</div>
