<div class="supplier-card">
  <div class="product-item-view">
    <mat-accordion class="grey-row">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <h3 class="">
            <mat-icon class="fas fa-id-badge"></mat-icon>
            Customer
          </h3>
        </mat-expansion-panel-header>

        <div class="row clearfix flex wrapped text-left mt">
          <div class="details block full-width">
            <mat-icon class="fas fa-info-circle mr"></mat-icon>
            <div class="row-content">
              <p class="rm-mb">Name</p>
              <h4 class="reduce-marg">
                {{ invoiceAppliance['CustomerCalculatedName'] }}
              </h4>
            </div>
          </div>

          <div class="details block full-width">
            <mat-icon class="fas fa-info-circle mr"></mat-icon>
            <div class="row-content">
              <p class="rm-mb">Email</p>
              <h4 class="reduce-marg">
                {{ invoiceAppliance['CustomerEmail'] }}
              </h4>
            </div>
          </div>

          <div class="details block full-width">
            <mat-icon class="fas fa-info-circle mr"></mat-icon>
            <div class="row-content">
              <p class="rm-mb">Mobile</p>
              <h4 class="reduce-marg">
                {{ invoiceAppliance['CustomerNumber'] | customPhone }}
              </h4>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>

  <div class="product-item-view">
    <mat-accordion class="grey-row">
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          <h3 class="">
            <mat-icon class="fas fa-calculator"></mat-icon>
            Details
          </h3>
        </mat-expansion-panel-header>

        <div class="row clearfix flex wrapped text-left mt">
          <div class="details block full-width">
            <mat-icon class="fas fa-info-circle mr"></mat-icon>
            <div class="row-content">
              <p class="rm-mb">Invoice Number</p>
              <h4 class="reduce-marg">
                {{ invoiceAppliance['Invoice_Number'] }}
              </h4>
            </div>
          </div>

          <div class="details block full-width">
            <mat-icon class="fas fa-calendar mr"></mat-icon>
            <div class="row-content">
              <p class="rm-mb">Date</p>
              <h4 class="reduce-marg">
                {{ invoiceAppliance['DateTimeCreated'] | customDate }}
              </h4>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>

  <div class="product-item-view">
    <mat-accordion class="grey-row">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <h3 class="">
            <mat-icon class="fas fa-dollar-sign"></mat-icon>
            Product
          </h3>
        </mat-expansion-panel-header>

        <div class="row clearfix flex wrapped text-left mt">
          <div class="details block full-width">
            <mat-icon class="fas fa-info-circle mr"></mat-icon>
            <div class="row-content">
              <p class="rm-mb">Product</p>
              <h4 class="reduce-marg">
                {{ invoiceAppliance['ProductLabel'] }}
              </h4>
            </div>
          </div>

          <div class="details block full-width">
            <mat-icon class="fas fa-dollar-sign mr"></mat-icon>
            <div class="row-content">
              <p class="rm-mb">Sell Price</p>
              <h4 class="reduce-marg">
                {{ invoiceAppliance['SellPrice' | customCurrency] }}
              </h4>
            </div>
          </div>

          <div class="details block full-width">
            <mat-icon class="fas fa-dollar-sign mr"></mat-icon>
            <div class="row-content">
              <p class="rm-mb">Total</p>
              <h4 class="reduce-marg">
                {{ invoiceAppliance['Invoice_Total'] | customCurrency }}
              </h4>
            </div>
          </div>

          <div class="details block full-width">
            <mat-icon class="fas fa-dollar-sign mr"></mat-icon>
            <div class="row-content">
              <p class="rm-mb">{{ 'Amount Borrowed' | customCurrency }}</p>
              <h4 class="reduce-marg">
                {{ invoiceAppliance['Amount_Borrowed'] }}
              </h4>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>

  <div class="product-item-view">
    <mat-accordion class="grey-row">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <h3 class="">
            <mat-icon class="fas fa-sticky-note"></mat-icon>
            Notes
          </h3>
        </mat-expansion-panel-header>

        <read-more [text]="invoiceAppliance['Notes']" [maxLength]="500"></read-more>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
