import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'environments/environment';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { AuthenticationService } from '../../authentication/shared/authentication.service';
import { WikiService } from '../shared/wiki.service';

@Component({
  selector: 'app-wiki-group-edit',
  templateUrl: './wiki-group-edit.component.html',
  styleUrls: ['./wiki-group-edit.component.css'],
})
export class WikiGroupEditComponent implements OnInit {
  allowedExtensionTypes = ['image'];

  @Input()
  groupID;

  @Input()
  code;

  title;
  description;
  roles;
  isActive = true;

  fileSrc;
  menu;
  orderBy;
  icon;
  fileID;

  sessionType;

  @Output() closeModal = new EventEmitter();

  @Output() result = new EventEmitter();

  isPromoterOrAdmin = false;
  isModal = false;
  group;

  constructor(
    public authenticationService: AuthenticationService,
    private router: Router,
    private wikiService: WikiService,
    private activeRoute: ActivatedRoute,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.groupID) {
        this.groupID = data.groupID;
        this.isModal = true;
      }
      if (data.code) {
        this.code = data.code;
        this.isModal = true;
      }
    }
  }

  ngOnInit() {
    this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
      this.isPromoterOrAdmin = res;
    });

    this.activeRoute.params.subscribe((params) => {
      if (params['groupID']) {
        this.groupID = params['groupID'];
      }

      if (params['code']) {
        this.code = params['code'];
      }

      this.authenticationService.getSessionType().subscribe((res) => {
        if (res) {
          this.sessionType = res;
        }

        if (this.groupID || this.code) {
          this.wikiService.getWikiGroupDetails(this.groupID, this.code, {}, this.sessionType).subscribe((res3) => {
            if (res3) {
              this.group = res3;

              if (this.group.title) {
                this.title = this.group.title;
              }

              if (this.group.description) {
                this.description = this.group.description;
              }

              if (this.group.menu) {
                this.menu = this.group.menu;
              }

              if (this.group.orderBy) {
                this.orderBy = this.group.orderBy;
              }

              if (this.group.icon) {
                this.icon = this.group.icon;
              }

              if (this.group.fileID) {
                this.fileID = this.group.fileID;

                this.fileSrc = `${environment.nodeUrl}/files/file-view/${this.fileID}`;
              }

              if (this.group.isActive != null) {
                this.isActive = this.group.isActive;
              }

              if (this.group.roles && this.group.roles.length > 0) {
                this.roles = this.group.roles;
              }
            }
          });
        }
      });
    });
  }

  closeEvent() {
    this.closeModal.emit(true);
  }

  isGroupRoleActive(role) {
    if (this.roles && this.roles.length > 0 && this.roles.indexOf(role) != -1) {
      return true;
    }

    return false;
  }

  sliderChangeGroupRole(value, target) {
    if (value && value.checked == true && this.roles && this.roles.indexOf(target) == -1) {
      this.roles.push(target);
    } else if (value && value.checked == false) {
      const index = this.roles.indexOf(target);

      if (index != -1) {
        this.roles.splice(index, 1);
      }
    }
  }

  update() {
    const payload = {
      title: this.title,
      description: this.description,
      roles: this.roles,
      isActive: this.isActive,
      fileID: this.fileID,
      icon: this.icon,
      menu: this.menu,
      orderBy: this.orderBy,
      code: this.code,
    };

    this.wikiService.updateWikiGroup(this.groupID, payload).subscribe((res) => {
      if (res) {
        this.result.emit(res);

        NotifyAppComponent.displayToast('success', 'Successfull operation', 'Wiki Article is Updated ');
      }
    });
  }

  isAwsome(icon) {
    if (icon && icon.indexOf('fa-') != -1) {
      return true;
    }

    return false;
  }

  onCompleteCategoryAll(fileID) {
    if (fileID && fileID[0]) {
      this.fileID = fileID[0];
      this.fileSrc = `${environment.nodeUrl}/files/file-view/${this.fileID}`;
    }
  }
}
