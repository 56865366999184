<div class="rel dash intro white-background">
  <mat-dialog-content class="calculator-content">
    <div class="calm">
      <div class="body-main">
        <div class="body-l">
          <div class="body-lt">
            <h3 class="rm-mt">What is your target for free access?</h3>
            <p>
              Present a minimum number of {{"PHRASE.treatment plan"}}s per month digitally using the platform, and we
              will
              automatically wave your monthly subscription fee*
            </p>
            <p>
              Using the simple calculator below, choose your plan, answer a few questions, and we will tell you your
              target.
            </p>
            <h4 *ngIf="subscriptionPlans && subscriptionPlans.length > 0">Choose Your Plan</h4>
            <div class="tselbox" *ngIf="subscriptionPlans && subscriptionPlans.length > 0">
              <select class="top-sel" [(ngModel)]="subscriptionPlanID" (change)="select()">
                <option *ngFor="let s of subscriptionPlans" [value]="s.ID">{{ s.Web_Label }}</option>
              </select>
              <span class="top-sel-ar"><i class="fas">&#xf107;</i></span>
            </div>
          </div>
          <div class="lt-main">
            <ng-container>
              <div class="full-width">
                <h4>
                  How many {{ 'PHRASE.dental practitioner' | translate }}s work at your
                  {{ "KEYWORD.practice" | translate }}?
                </h4>
                <div class="item">
                  <div class="item-l">
                    <input (change)="onChangeDent($event)" type="number" min="1" max="15" value="{{ dent_num }}"/>
                  </div>
                  <div class="item-r">
                    <mat-slider (input)="onInputDent($event)" tickInterval="1000" step="1" min="1" max="15"
                                value="{{ dent_num }}"></mat-slider>
                  </div>
                </div>
                <label class="description full-width text-right">Provide the number of {{ "KEYWORD.practitioner" |
                  translate }}s working full-time and part time e.g.
                  1x Full-time,1 Part-time = 1.5.</label>
              </div>
              <div class="full-width">
                <h4>Day per week they work?</h4>
                <div class="item">
                  <div class="item-l">
                    <input (change)="onChangeDay($event)" type="number" min="1" max="7" value="{{ day_num }}"/>
                  </div>
                  <div class="item-r">
                    <mat-slider (input)="onInputDay($event)" tickInterval="1000" step="1" min="1" max="7"
                                value="{{ day_num }}"></mat-slider>
                  </div>
                </div>
              </div>
              <div class="full-width">
                <h4>Number of {{ "KEYWORD.patient" | translate }}s each {{ "KEYWORD.practitioner" | translate }} sees
                  per day?</h4>
                <div class="item">
                  <div class="item-l sed">
                    <input (change)="onChangePat($event)" type="number" min="1" max="50" value="{{ pat_num }}"/>
                  </div>
                  <div class="item-r">
                    <div class="lsl">
                      <mat-slider (input)="onInputPat($event)" tickInterval="1000" step="1" min="1" max="50"
                                  value="{{ pat_num }}"></mat-slider>
                      <br/>
                    </div>
                  </div>
                </div>
                <label class="description full-width text-right">Most {{ "KEYWORD.practitioner" | translate }}s on
                  average see
                  10-12 {{ "KEYWORD.patient" | translate }}s per day</label>
              </div>
              <div class="full-width">
                <h4>How many {{ 'PHRASE.treatment plan' | translate }}s does
                  each {{ "KEYWORD.practitioner" | translate }} present each day?</h4>
                <div class="item">
                  <div class="item-l">
                    <input (change)="onChangePlan($event)" type="number" min="1" max="20" value="{{ plan_num }}"/>
                  </div>
                  <div class="item-r">
                    <div class="lsl">
                      <mat-slider (input)="onInputPlan($event)" tickInterval="1000" step="1" min="1" max="20"
                                  value="{{ plan_num }}"></mat-slider>
                      <br/>
                    </div>
                  </div>
                </div>

                <label class="description full-width text-right">Most {{ "KEYWORD.practitioner" | translate }}s on
                  average present
                  3-4 {{ 'PHRASE.treatment plan' | translate }}s per day.</label>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="body-r">
          <div class="rt">
            <h2>RESULTS</h2>
          </div>
          <div class="chart-g">
            <div class="chart-body">
              <ngx-charts-pie-chart [view]="view" [results]="data" [gradient]="gradient" [legend]="showLegend"
                                    [legendPosition]="legendPosition" [labels]="showLabels" [scheme]="colorScheme"
                                    [doughnut]="isDoughnut">
              </ngx-charts-pie-chart>
              <div class="chart-ru" [attr.style]="'color:' + goalColor + ' !important' | safeStyle">
                {{ calculPercent(treat, your) }}
              </div>
            </div>
          </div>
          <div class="chart-txtbox full-width flex">
            <div class="txtbox-r full-width">
              <p>You’re seeing on average</p>
              <span [attr.style]="'color:' + patientColor + ' !important' | safeStyle">{{ pat }}</span><br/>
              <p>{{ "KEYWORD.patient" | translate }}s<br/>per month</p>
            </div>
            <div class="txtbox-l full-width">
              <p>You present on average</p>
              <span [attr.style]="'color:' + treatmentColor + ' !important' | safeStyle">{{ treat }}</span><br/>
              <p>{{"PHRASE.treatment plan" | translate}}s <br/>per month</p>
            </div>
          </div>
          <hr/>

          <div class="chart-txtbox full-width mb flex">
            <div class="txtbox-r full-width">
              <p>Your target</p>
              <span [attr.style]="'color:' + targetColor + ' !important' | safeStyle">{{ your }}</span>
            </div>
            <div class="txtbox-l full-width">
              <p>Your Goal</p>
              <span *ngIf="goal > 0 && your > 0" [attr.style]="'color:' + goalColor + ' !important' | safeStyle">+{{
                goal }}</span>
              <span class="qualify" *ngIf="goal <= 0 || your == 0"
                    [attr.style]="'color:' + goalColor + ' !important' | safeStyle"><mat-icon
                class="fas fa-thumbs-up smr"></mat-icon> You Qualify!</span>
            </div>
          </div>
          <div class="chart-bot">
            <p>{{ 'PHRASE.treatment plan' | translate }}s communicated via the platform per month.</p>
            <p>Reach this target, and your subscription is automatically free for that month.</p>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <div class="drop-button text-center full-width white-background">
    <hr/>
    <button class="btn-large mt action-card-button mb" [style.background]="'#222B54'" (click)="closeEvent()"
            mat-raised-button color="accent">
      Close
    </button>
  </div>
</div>
