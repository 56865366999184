<div *ngIf="appointment && appointment.ID && appointment['Status.Code']">
  <div class="full-width" *ngIf="appointment['Status.Code'] == 'WAITINGCONFIRMATION' && isMerchant != true">
    <p
      class="mb text-center"
      *ngIf="
        appointment['Date'] && appointment['Date'] !== '0000-00-00' && appointmentUtil.isDatePass(appointment) != true
      "
      [ngClass]="{ lead: isFullDetails == true }"
    >
      Following your request {{ appointment['Date'] | TimeLabelPast }} ago, your {{"KEYWORD.practice" | translate}} has
      scheduled an appointment
      for you.
    </p>

    <p
      class="mb text-center"
      *ngIf="
        appointment['Date'] && appointment['Date'] !== '0000-00-00' && appointmentUtil.isDatePass(appointment) == true
      "
      [ngClass]="{ lead: isFullDetails == true }"
    >
      The suggested appointment date is already passed. Please feel free to request a new appointment.
    </p>
  </div>

  <div class="full-width" *ngIf="appointment['Status.Code'] == 'WAITINGCONFIRMATION' && isMerchant == true">
    <div class="full-width flex">
      <h4 class="primary-color subtitle rm-mb rm-mt">Next Steps</h4>
    </div>

    <p>Your {{"KEYWORD.patient" | translate}} has not confirm attendance yet, you can proceed with one of the following
      options</p>
  </div>

  <div class="full-width" *ngIf="appointment['Status.Code'] == 'REQUESTED' && isMerchant != true">
    <p class="mb text-center" [ngClass]="{ lead: isFullDetails == true }">
      You requested this appointment {{ appointment['DateTimeCreated'] | TimeLabelPast }} ago.
      The {{"KEYWORD.practice" | translate}} will contact
      you with booking time during regular hours.
    </p>
  </div>

  <div class="full-width" *ngIf="appointment['Status.Code'] == 'REQUESTED' && isMerchant == true">
    <div class="full-width flex">
      <h4 class="primary-color subtitle rm-mb rm-mt">Next Steps</h4>
    </div>

    <p>
      Your {{"KEYWORD.patient" | translate}} is requesting an appointment with your {{"KEYWORD.practice" | translate}},
      you can proceed with one of the following options
    </p>
  </div>

  <div class="full-width" *ngIf="appointment['Status.Code'] == 'BOOKED' && isMerchant != true">
    <p
      class="mb text-center"
      *ngIf="
        appointment['Date'] && appointment['Date'] !== '0000-00-00' && appointmentUtil.isDatePass(appointment) != true
      "
      [ngClass]="{ lead: isFullDetails == true }"
    >
      You have confirmed an Appointment with your {{"KEYWORD.practice" | translate}}, let
      your {{"KEYWORD.dentist" | translate}} know if you need to change something
    </p>

    <p
      class="mb text-center"
      *ngIf="
        appointment['Date'] && appointment['Date'] !== '0000-00-00' && appointmentUtil.isDatePass(appointment) == true
      "
      [ngClass]="{ lead: isFullDetails == true }"
    >
      Appointment date is already passed. Please feel free to request a new appointment.
    </p>
  </div>

  <div class="full-width" *ngIf="appointment['Status.Code'] == 'BOOKED' && isMerchant == true">
    <div class="full-width flex">
      <h4 class="primary-color subtitle rm-mb rm-mt">Next Steps</h4>
    </div>

    <p>Your {{"KEYWORD.patient" | translate}} confirm the suggested booking, You can mark it as Attended after the
      appointment.</p>
  </div>

  <div class="full-width" *ngIf="appointment['Status.Code'] == 'CANCELED' && isMerchant != true">
    <p class="mb text-center" [ngClass]="{ lead: isFullDetails == true }">
      You have canceled this appointment, your {{"KEYWORD.dentist" | translate}} has been notified
    </p>
  </div>

  <div class="full-width" *ngIf="appointment['Status.Code'] == 'CANCELED' && isMerchant == true">
    <div class="full-width flex">
      <h4 class="primary-color subtitle rm-mb rm-mt">All done !</h4>
    </div>

    <p>The Appointment has been canceled by your {{"KEYWORD.patient" | translate}}, there is no further steps</p>
  </div>

  <div class="full-width" *ngIf="appointment['Status.Code'] == 'CANCELEDBYPRACTICE' && isMerchant != true">
    <p class="mb text-center" [ngClass]="{ lead: isFullDetails == true }">
      Your {{"KEYWORD.dentist" | translate}} has canceled this appointment, please make contact during regular hours for
      more details.
    </p>
  </div>
  <div class="full-width" *ngIf="appointment['Status.Code'] == 'CANCELEDBYPRACTICE' && isMerchant == true">
    <div class="full-width flex">
      <h4 class="primary-color subtitle rm-mb rm-mt">All done !</h4>
    </div>

    <p>You have canceled this appointment, there is no further steps</p>
  </div>

  <div class="full-width" *ngIf="appointment['Status.Code'] == 'ATTENDED' && isMerchant != true">
    <p
      class="mb text-center"
      *ngIf="appointment['Date'] && appointment['Date'] !== '0000-00-00'"
      [ngClass]="{ lead: isFullDetails == true }"
    >
      Thank you for attending this appointment on time.
    </p>
  </div>
  <div class="full-width" *ngIf="appointment['Status.Code'] == 'ATTENDED' && isMerchant == true">
    <div class="full-width flex">
      <h4 class="primary-color subtitle rm-mb rm-mt">All done !</h4>
    </div>

    <p>Your {{"KEYWORD.patient" | translate}} has attended the appointment on time, there is no further steps</p>
  </div>

  <div class="full-width" *ngIf="appointment['Status.Code'] == 'MISSED' && isMerchant != true">
    <p
      class="mb text-center"
      *ngIf="appointment['Date'] && appointment['Date'] !== '0000-00-00'"
      [ngClass]="{ lead: isFullDetails == true }"
    >
      Sorry to heard you have missed this appointment, you can request a new appointment when it is suitable.
    </p>
  </div>

  <div class="full-width" *ngIf="appointment['Status.Code'] == 'MISSED' && isMerchant == true">
    <div class="full-width flex">
      <h4 class="primary-color subtitle rm-mb rm-mt">All done !</h4>
    </div>

    <p>The appointment has been missed, please contact your {{"KEYWORD.patient" | translate}} for more details</p>
  </div>

  <div class="full-width" *ngIf="appointment['Status.Code'] == 'REQUESTRESCHEDULE' && isMerchant != true">
    <p class="mb text-center" [ngClass]="{ lead: isFullDetails == true }">
      You have request a rescheduling, Your {{"KEYWORD.dentist" | translate}} will schedule another appointment for you.
    </p>
  </div>

  <div class="full-width" *ngIf="appointment['Status.Code'] == 'REQUESTRESCHEDULE' && isMerchant == true">
    <div class="full-width flex">
      <h4 class="primary-color subtitle rm-mb rm-mt">Next Steps</h4>
    </div>

    <p>Your {{"KEYWORD.patient" | translate}} is requesting a rescheduling , Respond to
      your {{"KEYWORD.patient" | translate}}s with one of the following options</p>
  </div>

  <div class="full-width" *ngIf="appointment['Status.Code'] == 'REQUESTDATECHANGE' && isMerchant != true">
    <p class="mb text-center" [ngClass]="{ lead: isFullDetails == true }">
      You requested a date change of this appointment {{ appointment['Date_Request_Date_Change'] | TimeLabelPast }} ago,
      Your {{"KEYWORD.dentist" | translate}} will schedule another appointment for you.
    </p>
  </div>

  <div class="full-width" *ngIf="appointment['Status.Code'] == 'REQUESTDATECHANGE' && isMerchant == true">
    <div class="full-width flex">
      <h4 class="primary-color subtitle rm-mb rm-mt">Next Steps</h4>
    </div>

    <p>Your {{"KEYWORD.patient" | translate}} is requesting another booking time , Respond to
      your {{"KEYWORD.patient" | translate}}s with one of the following options</p>
  </div>
</div>
