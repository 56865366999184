import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared/shared.module';

import { CdkTableModule } from '@angular/cdk/table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ArchwizardModule } from 'angular-archwizard';

import { MerchantWelcomeCardComponent } from './merchant-welcome-card/merchant-welcome-card.component';
import { MerchantRoutingModule } from './shared/merchant-routing.module';

import { MomentModule } from 'ngx-moment';
import { NgPipesModule } from 'ngx-pipes';
import { MerchantCreateComponent } from './merchant-create/merchant-create.component';
import { MerchantInviteComponent } from './merchant-invite/merchant-invite.component';
import { MerchantListMapComponent } from './merchant-list-map/merchant-list-map.component';
import { MerchantListComponent } from './merchant-list/merchant-list.component';
import { MerchantTradingHoursOverviewComponent } from './merchant-trading-hours-overview/merchant-trading-hours-overview.component';
import { MerchantTradingHoursViewComponent } from './merchant-trading-hours-view/merchant-trading-hours-view.component';
import { MerchantTradingHoursComponent } from './merchant-trading-hours/merchant-trading-hours.component';
import { MerchantViewPictureComponent } from './merchant-view-picture/merchant-view-picture.component';
import { MerchantViewComponent } from './merchant-view/merchant-view.component';

import { NgxPaginationModule } from 'ngx-pagination';
import { QuillModule } from 'ngx-quill';
import { MerchantAccessCreateComponent } from './merchant-access-create/merchant-access-create.component';
import { MerchantAccessListComponent } from './merchant-access-list/merchant-access-list.component';
import { MerchantInformedConsentComponent } from './merchant-informed-consent/merchant-informed-consent.component';
import { MerchantLookupComponent } from './merchant-lookup/merchant-lookup.component';

import {
  MatButtonModule,
  MatCardModule,
  MatDialogModule,
  MatGridListModule,
  MatIconModule,
  MatProgressSpinnerModule,
} from '@angular/material';
import { MatSortModule } from '@angular/material/sort';
import { ClipboardModule } from 'ngx-clipboard';
import { DefaultMessageModule } from '../default-message/default-message.module';
import { DentistModule } from '../dentist/dentist.module';
import { DentistService } from '../dentist/shared/dentist.service';
import { DocumentModule } from '../document/document.module';
import { GoogleReviewsModule } from '../google-reviews/google-reviews.module';
import { MessageModule } from '../message/message.module';
import { OperatorModule } from '../operator/operator.module';
import { AddContactDentistryTypesProfileDialogComponent } from './dialogs/add-contact-dentistry-types-profile-dialog/add-contact-dentistry-types-profile-dialog.component';
import { AddDentistryTypesProfileDialogComponent } from './dialogs/add-dentistry-types-profile-dialog/add-dentistry-types-profile-dialog.component';
import { AddHealthFundProfileDialogComponent } from './dialogs/add-health-fund-profile-dialog/add-health-fund-profile-dialog.component';
import { AddServiceProfileDialogComponent } from './dialogs/add-service-profile-dialog/add-service-profile-dialog.component';
import { LandingPageEmbedComponent } from './landing-page-embed/landing-page-embed.component';
import { LandingPageShareComponent } from './landing-page-share/landing-page-share.component';
import { MerchantAuthCodeModalComponent } from './merchant-auth-code-modal/merchant-auth-code-modal.component';
import { MerchantDataImportComponent } from './merchant-data-import/merchant-data-import.component';
import { MerchantListNotActiveComponent } from './merchant-list-not-active/merchant-list-not-active.component';
import { MerchantMapModalComponent } from './merchant-map-modal/merchant-map-modal.component';
import { MerchantProductAssistantComponent } from './merchant-product-assistant/merchant-product-assistant.component';
import { MerchantProductAssitantOverviewComponent } from './merchant-product-assitant-overview/merchant-product-assitant-overview.component';
import { MerchantSelectListComponent } from './merchant-select-list/merchant-select-list.component';
import { MerchantUpdateWebsiteComponent } from './merchant-update-website-modal/merchant-update-website-modal.component';
import { MerchantViewModalComponent } from './merchant-view-modal/merchant-view-modal.component';
import { MerchantVisitorComponent } from './merchant-visitor/merchant-visitor.component';
import { MerchantService } from './shared/merchant.service';
import { StaffPortalComponent } from './staff-portal/staff-portal.component';
import { VersionUpdateComponent } from './version-update/version-update.component';
import { MerchantSettlementComponent } from './merchant-settlement/merchant-settlement.component';

@NgModule({
  imports: [
    MatButtonModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatGridListModule,
    MatCardModule,
    MatIconModule,
    MatDialogModule,
    NgPipesModule,
    CommonModule,
    ClipboardModule,
    MomentModule,
    MerchantRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    CdkTableModule,
    ArchwizardModule,
    SharedModule,
    NgxPaginationModule,
    QuillModule,
    MatSortModule,
    DentistModule,
    DefaultMessageModule,
    MessageModule,
    DocumentModule,
    OperatorModule,
    GoogleReviewsModule,
  ],
  declarations: [
    MerchantInformedConsentComponent,
    MerchantViewPictureComponent,
    MerchantAccessListComponent,
    MerchantListMapComponent,
    MerchantListComponent,
    MerchantWelcomeCardComponent,
    MerchantViewComponent,
    MerchantViewModalComponent,
    MerchantInviteComponent,
    MerchantCreateComponent,
    MerchantTradingHoursComponent,
    MerchantTradingHoursOverviewComponent,
    MerchantTradingHoursViewComponent,
    MerchantAccessCreateComponent,
    MerchantLookupComponent,
    MerchantDataImportComponent,
    MerchantDataImportComponent,
    MerchantSelectListComponent,
    MerchantDataImportComponent,
    MerchantMapModalComponent,
    MerchantUpdateWebsiteComponent,
    MerchantAuthCodeModalComponent,
    MerchantProductAssistantComponent,
    MerchantProductAssitantOverviewComponent,
    MerchantVisitorComponent,
    MerchantListNotActiveComponent,
    AddServiceProfileDialogComponent,
    AddHealthFundProfileDialogComponent,
    AddContactDentistryTypesProfileDialogComponent,
    AddDentistryTypesProfileDialogComponent,
    StaffPortalComponent,
    LandingPageEmbedComponent,
    LandingPageShareComponent,
    VersionUpdateComponent,
    MerchantSettlementComponent,
  ],

  exports: [
    MerchantInformedConsentComponent,
    MerchantViewPictureComponent,
    MerchantAccessListComponent,
    MerchantListMapComponent,
    MerchantListComponent,
    MerchantWelcomeCardComponent,
    MerchantViewComponent,
    MerchantViewModalComponent,
    MerchantInviteComponent,
    MerchantCreateComponent,
    MerchantTradingHoursComponent,
    MerchantTradingHoursOverviewComponent,
    MerchantTradingHoursViewComponent,
    MerchantAccessCreateComponent,
    MerchantLookupComponent,
    MerchantDataImportComponent,
    MerchantDataImportComponent,
    MerchantSelectListComponent,
    MerchantDataImportComponent,
    MerchantMapModalComponent,
    MerchantUpdateWebsiteComponent,
    MerchantAuthCodeModalComponent,
    MerchantProductAssistantComponent,
    MerchantProductAssitantOverviewComponent,
    MerchantVisitorComponent,
    MerchantListNotActiveComponent,
    AddServiceProfileDialogComponent,
    AddHealthFundProfileDialogComponent,
    AddContactDentistryTypesProfileDialogComponent,
    AddDentistryTypesProfileDialogComponent,
    StaffPortalComponent,
    LandingPageEmbedComponent,
    LandingPageShareComponent,
    VersionUpdateComponent,
    MerchantSettlementComponent,
  ],

  entryComponents: [
    AddServiceProfileDialogComponent,
    AddHealthFundProfileDialogComponent,
    AddContactDentistryTypesProfileDialogComponent,
    AddDentistryTypesProfileDialogComponent,
  ],

  providers: [DentistService, MerchantService],
})
export class MerchantModule {}
