<div class="container">
  <!-- ===== Details Page ===== -->
  <h2 class="sr-title">{{ "KEYWORD.practice" | translate | titlecase}} Setting</h2>
  <mat-card class="card">
    <mat-card-header>
      <p class="lead rm-mt">Your {{ "KEYWORD.practice" | translate | titlecase}} Information</p>
    </mat-card-header>
    <div class="row clearfix">
      <div class="half-width">
        <div class="column">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Company Name</mat-label>
            <input matInput placeholder="Company Name" value="Leading Edge Dental"/>
          </mat-form-field>
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Trading as</mat-label>
            <input matInput placeholder="Trading as" value="Leading Edge Dental"/>
          </mat-form-field>
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>ABN</mat-label>
            <input matInput placeholder="ABN" value="0000 0000 0000"/>
          </mat-form-field>

          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Copyright Line</mat-label>
            <input matInput placeholder="Copyright Line" value="Leading Edge Dental - Copyright 2017"/>
          </mat-form-field>
        </div>
      </div>
      <div class="half-width">
        <div class="column">
          <div class="row clearfix">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Merchant Phone Number</mat-label>
              <input matInput placeholder="Merchant Phone Number" value="02 1234 1233"/>
            </mat-form-field>
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Merchant Mobile</mat-label>
              <input matInput placeholder="Merchant Mobile" value="0400 000 000"/>
            </mat-form-field>
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Merchant Email</mat-label>
              <input matInput placeholder="Merchant Email" value="info@smileright.com.au"/>
            </mat-form-field>
          </div>

          <div class="row clearfix mb">
            <label>Header Image</label>
          </div>

          <div class="row clearfix">
            <div class="row clearfix">
              <app-file-uploader></app-file-uploader>
            </div>
          </div>
          <hr/>
        </div>
      </div>
    </div>
  </mat-card>

  <mat-card class="card">
    <mat-card-header>Location</mat-card-header>
    <div class="row clearfix">
      <app-address-input
        [postCode]="myPostCode"
        [state]="myState"
        [suburb]="mySuburb"
        [streetNumber]="myStreetNumber"
        [streetName]="myStreetName"
        [unitNumber]="myUnitNumber"
        [streetType]="myStreetType"
        [latitude]="myLatitude"
        [longitude]="myLongitude"
        (getAdress)="getAdress($event)"
        (validate)="isAdressValid($event)"
      >
      </app-address-input>
    </div>
    <div class="clearfix">
      <h1>TEST</h1>
      <p>{{ mySuburb }} {{ myPostCode }}====================</p>
    </div>
  </mat-card>

  <div class="row clearfix">
    <div class="full-width">
      <button class="step-back" (click)="cancel()" mat-mini-fab color="primary">
        <mat-icon>keyboard_arrow_left</mat-icon>
      </button>
    </div>
  </div>
</div>
