import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { MerchantViewComponent } from '../../merchant/merchant-view/merchant-view.component';
import { MessageService } from '../shared/message.service';

@Component({
  selector: 'app-sms-promoter',
  templateUrl: './sms-promoter.component.html',
  styleUrls: ['./sms-promoter.component.css'],
  animations: [
    trigger('simpleFadeAnimation', [
      state('in', style({ opacity: 1, height: '*' })),
      transition(':enter', [style({ opacity: 0, height: 0 }), animate(300)]),
      transition(':leave', animate(300, style({ opacity: 0, height: 0 }))),
    ]),
  ],
})
export class SmsPromoterComponent implements OnInit {
  @Input()
  merchantID = null;

  @Input()
  cardID;
  merchants = [];
  merchant;

  isContactStaff = false;
  currentMerchant;
  getResult = new EventEmitter();
  close = new EventEmitter();
  sessionType;
  isPromoterOrAdmin = false;

  singleChat = false;
  loadDefaultChat = true;

  contractID;
  invitationID;

  financeOfferID;

  messageType = 'promoterPersonal';

  messageTypes = [
    {
      code: 'all',
      label: 'All Messages',
      globalNumber: 'all',
    },

    {
      code: 'merchant',
      label: 'Merchant View',
      globalNumber: 'all',
    },
    {
      code: 'merchantAll',
      label: 'Merchant View (Promoter)',
      globalNumber: 'all',
    },
    {
      code: 'promoterMerchantpersonal',
      label: 'Personal Messages (Merchant & Promoter)',
      globalNumber: 'all',
    },
    {
      code: 'promoter',
      label: 'Promoter Messages',
      globalNumber: 'promoter',
    },
    {
      code: 'promoterPersonal',
      label: 'Promoter Messages (Personal only)',
      globalNumber: 'promoter',
    },
    {
      code: 'customized',
      label: 'Filter by Template',
    },
  ];

  messageStaffTypes = [
    {
      code: 'all',
      label: 'All Messages',
      globalNumber: 'all',
    },
    {
      code: 'merchantpersonal',
      label: 'Merchant Messages',
      globalNumber: 'all',
    },
    {
      code: 'promoterMerchantpersonal',
      label: 'Personal Messages (Merchant & Promoter)',
      globalNumber: 'all',
    },

    {
      code: 'promoterPersonal',
      label: 'Promoter Messages',
      globalNumber: 'promoter',
    },
  ];

  templateTags = [];

  templates = [];

  inCludeCampaignMessages = false;
  globalNumber = 'promoter';
  globalNumbers = [
    {
      code: 'all',
      label: 'All Numbers',
    },
    {
      code: 'promoter',
      label: 'Promoter  Number',
    },
    // {
    //   code: 'promoterStaff',
    //   label: 'Promoter Staff Number',
    // },
    {
      code: 'merchant',
      label: 'Merchant Global Number',
    },
  ];

  promoterID;

  constructor(
    private authenticationService: AuthenticationService,
    private messageService: MessageService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data) {
      if (data && data.merchantID) {
        this.merchantID = data.merchantID;
      }
      if (data && data.patientID) {
        this.cardID = data.patientID;
      }

      if (data && data.cardID) {
        this.cardID = data.cardID;
      }

      if (data && data.contractID) {
        this.contractID = data.contractID;
      }

      if (data && data.invitationID) {
        this.invitationID = data.invitationID;
      }

      if (data && data.financeOfferID) {
        this.financeOfferID = data.financeOfferID;
      }

      if (data && data.isContactStaff == true) {
        this.isContactStaff = true;
      } else if (data && data.isContactStaff == false) {
        this.isContactStaff = false;
      }

      if (data && data.globalNumber) {
        this.globalNumber = data.globalNumber;
      }
      if (data && data.merchantID) {
        this.merchantID = data.merchantID;
      }
      if (data && data.inCludeCampaignMessages != null) {
        this.inCludeCampaignMessages = data.inCludeCampaignMessages;
      }
      if (data && data.loadDefaultChat != null) {
        this.loadDefaultChat = data.loadDefaultChat;
      }
      if (data && data.singleChat != null) {
        this.singleChat = data.singleChat;
      }

      if (data && data.messageType) {
        this.messageType = data.messageType;
      }
      if (data && data.templateTags && typeof data.templateTags === 'string') {
        this.templateTags = data.templateTags.split(',');
      } else if (data && data.templateTags && Array.isArray(data.templateTags) === true) {
        this.templateTags = JSON.parse(JSON.stringify(data.templateTags));
      }
    }
  }

  ngOnInit() {
    this.authenticationService.getCurrentPractice().subscribe((res) => {
      if (res) {
        this.currentMerchant = res;
        this.promoterID = res.ID;
      }

      if (this.isContactStaff === true) {
        this.globalNumber = 'all';
      }
      this.authenticationService.isPromoterOrAdmin().subscribe((isPromoterOrAdmin) => {
        this.isPromoterOrAdmin = isPromoterOrAdmin;
        this.authenticationService.getSessionType().subscribe((r) => {
          this.sessionType = r;

          this.setup();
        });
      });
    });
  }

  setup() {
    this.authenticationService
      .getMerchantList({ fields: 'ID,TradingAs,LastModified' }, this.isPromoterOrAdmin)
      .subscribe((ms) => {
        if (ms) {
          this.merchants = ms;

          if (this.promoterID && !this.merchantID) {
            this.merchantID = this.promoterID;
          }
          if (this.merchantID) {
            const v = this.merchants.findIndex((item) => {
              if (item && item.ID && item.ID == this.merchantID) {
                return true;
              }

              return false;
            });

            if (v !== -1) {
              this.merchant = this.merchants[v];
            }
          }
        }
      });
    this.messageService
      .getMarketingStaticTemplateList(
        {
          fields: 'ID,Tag,Label',
          isSupportSMS: true,
          limit: 1000,
          selectAll: true,
          notUserTemplate: true,
        },
        this.isPromoterOrAdmin
      )
      .subscribe((templates) => {
        if (templates) {
          this.templates = templates;
        }
      });
  }

  getClientObject() {
    if (this.merchantID) {
      const v = this.merchants.findIndex((item) => {
        if (item && item.ID && item.ID == this.merchantID) {
          return true;
        }

        return false;
      });

      if (v !== -1) {
        this.merchant = this.merchants[v];
      }
    }
  }

  viewMerchant(id) {
    const ref = RootAppComponent.dialog.open(MerchantViewComponent, {
      data: id,
      width: '600px',
    });
    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  closeEvent() {
    this.close.emit(true);
  }

  submit() {
    if (this.isContactStaff != true) {
      const p = {
        merchantID: this.merchantID || null,
        messageType: this.messageType || null,
        globalNumber: 'all',
        includeCampaing: this.inCludeCampaignMessages || null,
        templateTags: this.templateTags.join(','),
        isPromoterView: false,
        loadDefaultChat: this.loadDefaultChat,
        cardID: this.cardID,
        singleChat: this.singleChat,

        contractID: this.contractID,
        invitationID: this.invitationID,
        financeOfferID: this.financeOfferID,
      };

      // if(this.messageType)
      // {
      //   let index = this.messageTypes.findIndex(el=>{
      //     if(el && el.code == this.messageType)
      //     {
      //       return true ;
      //     }

      //     return false
      //   })

      //   if(index !==-1)
      //   {
      //     let messageTypeValue = this.messageTypes[index]

      //     if(messageTypeValue && messageTypeValue)
      //     {
      //       if(messageTypeValue.code && messageTypeValue.code!=='customized')
      //       {

      //       }
      //     }
      //   }
      // }

      if (this.singleChat == true) {
        p.loadDefaultChat = false;
      }

      if (this.promoterID == this.merchantID || !this.merchantID) {
        p.isPromoterView = true;
      }

      this.getResult.emit(p);
    } else {
      const p1 = {
        merchantID: this.merchantID || null,
        messageType: this.messageType || null,
      };

      this.getResult.emit(p1);
    }
  }
}
