<div class="text-center full-width" *ngIf="loading == true">
  <div class="ep-view-img file-loadn">
    <div class="spinner">
      <div class="double-bounce1"></div>
      <div class="double-bounce2"></div>
    </div>
  </div>
</div>

<div
  class="clearfix row full-width full-height"
  *ngIf="loading != true"
  [ngClass]="{ 'no-title-mode-top': noTitleMode == true }"
>
  <div class="full-width row clearfix cardheader primary-gradient-img" *ngIf="description || title">
    <button
      class="btn-close btn-clear white pull-right mat-button"
      *ngIf="isModal == true && (description || title)"
      (click)="closeModalEvent()"
    >
      <mat-icon>clear</mat-icon>
    </button>

    <p class="lead rm-mt shadow-text" *ngIf="title">
      <strong>{{ title }}</strong>
    </p>
    <p *ngIf="description">{{ description }}</p>
  </div>

  <div class="full-height clearfix full-width row" *ngIf="content || link" @ngIfAnimation>
    <button
      class="btn-close btn-clear mat-button white"
      *ngIf="isModal == true && !description && !title && noTitleMode != true"
      (click)="closeModalEvent()"
    >
      <mat-icon>clear</mat-icon>
    </button>

    <div class="full-width clearfix" *ngIf="text" [innerHTML]="text | safeHtml"></div>

    <div class="document full-height" id="fileContainer">
      <div
        class="full-width text-center clearfix white-background"
        *ngIf="isError == true && isLoaded == false && (content || link) && documentType == 'image'"
      >
        <app-empty-list-message
          class="clearfix full-width"
          [title]="'Sorry!'"
          [message]="'We could not display the Image'"
          [isAction]="true"
          [actionIcon]="'cloud_download'"
          [actionLabel]="'Download'"
          (actionEvent)="downloadFile()"
        >
        </app-empty-list-message>
      </div>
      <div
        class="image-loading"
        *ngIf="isLoaded == false && isError == false && (content || link) && documentType == 'image'"
      >
        <div class="spinner-inner">
          <div class="double-bounce1"></div>
          <div class="double-bounce2"></div>
          <!-- <div class="double-bounce3"></div> -->
        </div>
      </div>

      <div
        class="image-loading"
        *ngIf="

          isIframeLoaded == false &&
          (((content || link) && extension == 'pdf') ||
            ((content || link) && extension == 'html' && browser && isMicrosoftEdge == false))
        "
      >
        <div class="spinner-inner">
          <div class="double-bounce1"></div>
          <div class="double-bounce2"></div>
          <!-- <div class="double-bounce3"></div> -->
        </div>
      </div>

      <div
        class="full-width text-center clearfix white-background"
        *ngIf="(content || link) && documentType == 'document' && fileURL"
      >
        <app-empty-list-message
          class="clearfix full-width"
          [title]="''"
          [message]="'Click on button below to download the document'"
          [isAction]="true"
          [actionIcon]="'cloud_download'"
          [actionLabel]="'Download'"
          (actionEvent)="downloadFile()"
        >
        </app-empty-list-message>
      </div>

      <div
        class="view-container full-height"
        *ngIf="(content || link) && documentType == 'image' && fileURL"
        [hidden]="isError == true || isLoaded == false"
      >
        <pinch-zoom
          class="full-height pinchZoom pinchZoom-blob"
          [limit-zoom]="25"
          [wheel]="true"
          [wheelZoomFactor]="0.5"
          [double-tap]="true"
          [properties]="pinchZoomProperties"
        >
          <img [src]="fileURL" (error)="onError()" (load)="onLoad()" />
        </pinch-zoom>

        <div class="hidden-iframe" *ngIf="browser && isMicrosoftEdge == false">
          <iframe
            class="full-height full-width blob-view-iframe pdf-view-iframe min-height"
            id="imageDoc"
            #iframe
            [attr.src]="fileURL"
            sandbox="allow-same-origin allow-scripts     allow-popups allow-forms "
            name="imageDoc"
            target="_parent"
            iframe-tracker
            type="image/png"
          ></iframe>
        </div>
      </div>

      <div
        class="view-container full-height"
        *ngIf="(content || link) && extension == 'html' && browser && isMicrosoftEdge == false"
        iframe-tracker
      >
        <iframe
          class="full-height full-width blob-view-iframe flex min-height"
          id="htmlDoc"
          #iframe
          [attr.src]="fileURL"
          (load)="onFrameLoad($event)"
          sandbox="allow-same-origin   allow-scripts allow-popups allow-forms"
          name="htmlDoc"
          target="_parent"
          iframe-tracker
          type="text/html"
        ></iframe>
      </div>

      <div
        class="view-container full-height min-video-height"
        *ngIf="(content || link) && documentType == 'video' && fileURL"
      >
        <video class="full-width" controls="true" playsinline autoplay controlsList="nodownload" width="100%">
          <source src="{{ fileRawURL }}" type="{{ mimeDocumentType }}"/>

          <app-empty-list-message
            class="clearfix full-width"
            [title]="'Sorry!'"
            [message]="'Your browser does not support the video tag.'"
            [isAction]="true"
            [actionIcon]="'cloud_download'"
            [actionLabel]="'Download'"
            (actionEvent)="downloadFile()"
          >
          </app-empty-list-message>
        </video>
      </div>

      <div class="view-container" *ngIf="(content || link) && extension == 'pdf'" [hidden]="isIframeLoaded==false">
        <div *ngIf="fileRawURL" lass="full-width text-center clearfix mb mt  ">
          <ng2-pdfjs-viewer
            class="min-height"
            [pdfSrc]="fileRawURL"
            [openFile]="false"
            [downloadFileName]="labelToPrint"
          >
          </ng2-pdfjs-viewer>
        </div>

        <div class="hidden-iframe" *ngIf="browser && isMicrosoftEdge == false">
          <iframe
            class="full-height full-width blob-view-iframe pdf-view-iframe"
            id="iframeDoc"
            #iframe
            [attr.src]="fileURL"
            (load)="onFrameLoad($event)"
            sandbox="allow-same-origin     allow-scripts allow-popups allow-forms"
            name="iframeDoc"
            target="_parent"
            iframe-tracker
            type="application/pdf"
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</div>

<hr *ngIf="isPrint == true || isDownload == true || isProceed == true"/>

<div
  class="row clearfix text-center printable-buttons"
  *ngIf="isPrint == true || isDownload == true || isProceed == true"
  [ngClass]="{ 'no-title-mode-bottom': noTitleMode == true }"
>
  <div [ngClass]="{ 'pull-left': downloadPullRight != true }" [ngClass]="{ 'pull-right': downloadPullRight == true }">
    <print-btn
      class=""
      *ngIf="
        isPrint == true && (content || link) && (extension == 'pdf' || documentType == 'image' || extension == 'html')
      "
      [buttonType]="'wide'"
      [label]="labelPrint"
      (click)="printEvent($event)"
    ></print-btn>

    <button class="mr ml" *ngIf="noTitleMode == true" (click)="closeModalEvent()" mat-raised-button color="primary">
      Close
    </button>


    <button mat-raised-button color="accent" class="download-button" matTooltip="Download "
      *ngIf="isDownload==true  && (content || link) && (extension == 'pdf' || documentType=='document' || documentType=='image' || extension == 'html' || extension == 'xlsx' || extension == 'csv' || extension == 'zip' || extension == 'tsv')"
      (click)="downloadFile()">
      Download <mat-icon class="fas fa-download"></mat-icon>
    </button>
  </div>

  <button
    class="pull-right"
    *ngIf="
      isProceed &&
      (content || link) &&
      (documentType == 'video' ||
        extension == 'pdf' ||
        documentType == 'document' ||
        documentType == 'image' ||
        extension == 'html')
    "
    [disabled]="!canProceed"
    (click)="proceedEvent()"
    mat-raised-button
    color="accent"
    matTooltip="{{ proceedLabel }}"
  >
    {{ labelProceed }}
    <mat-icon>keyboard_arrow_right</mat-icon>
  </button>
</div>
