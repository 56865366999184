import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output, SimpleChanges } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/switchMap';
import { OperatorChangePasswordComponent } from '../../../feature/operator/operator-change-password/operator-change-password.component';
import { OperatorContactComponent } from '../../../feature/operator/operator-contact/operator-contact.component';
import { OperatorService } from '../../../feature/operator/shared/operator.service';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { MapViewComponent } from '../../../shared/components/map-view/map-view.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { AuthenticationService } from '../../authentication/shared/authentication.service';
import { LogUserListComponent } from '../../log/log-user-list/log-user-list.component';
import { AgentDetailsComponent } from '../agent-details/agent-details.component';
import { SessionsService } from '../shared/sessions.service';

@Component({
  selector: 'app-active-session-view',
  templateUrl: './active-session-view.component.html',
  styleUrls: ['./active-session-view.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('.stacked-card-view', style({ opacity: 0 }), { optional: true }),
        query(
          '.stacked-card-view',
          stagger('100ms', [
            animate(
              '0.8s ease-out',
              keyframes([
                style({ opacity: 0, offset: 0, height: 0 }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 1, offset: 1.0, height: '*' }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
      transition('* => void', [
        query('.stacked-card-view', style({ opacity: 1 }), { optional: true }),
        query(
          '.stacked-card-view',
          stagger('100ms', [
            animate(
              '0.8s ease-in',
              keyframes([
                style({ opacity: 1, offset: 0, height: '*' }),
                // style({opacity: .5, transform: 'translateY(35px)', offset: 0.3}),
                style({ opacity: 0, offset: 1.0, height: 0 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class ActiveSessionViewComponent implements OnInit {
  @Input() sessionID;
  session;
  operator;

  @Input()
  abnDetails = true;

  @Output()
  close = new EventEmitter();

  @Output() sessionDeleted = new EventEmitter<string>();

  @Output()
  loading = false;
  profileLink;
  subjectArray = [];

  isPromoterOrAdmin = false;
  isModal = false;
  currentUser;

  sessionType;
  constructor(
    private sessionService: SessionsService,
    private operatorService: OperatorService,
    private authenticationService: AuthenticationService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data) {
      this.sessionID = data;
      this.isModal = true;
    }
  }

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe(async (st) => {

      this.sessionType=st

    this.authenticationService.isPromoterOrAdmin().subscribe(async (res) => {
      this.isPromoterOrAdmin = res;
      if (!this.sessionID) {
        const params = await this.activeRoute.params.toPromise();
        if (params && params['sessionID ']) {
          this.sessionID = params['sessionID '];
        }
      }
      if (this.sessionID) {
        this.sessionService.getOneSession(this.sessionID).subscribe((res1) => {
          this.session = res1;

          this.operatorService.getfullOperatorDetails(this.session.operatorID).subscribe((op) => {
            this.operator = op;
          });
        });
      }
    });

    this.authenticationService.getCurrentUser().subscribe((res) => {
      if (res && res.data) {
        this.currentUser = res.data;
      }
    });
  });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.sessionService.getOneSession(this.sessionID).subscribe((res1) => {
      this.session = res1;
      this.loading = true;
    });
  }

  closeEvent() {
    this.close.emit(true);
  }

  moreInfoTohtml(moreInfo) {
    let html = '';
    if (moreInfo) {
      if (moreInfo.browser) {
        html = html + '<strong>- Browser: </strong>' + moreInfo.browser + ' (Version ' + moreInfo.browser_version + ')';
      }
      if (moreInfo.device) {
        html = html + '<br><strong>- Device: </strong>' + moreInfo.device;
      }
      if (moreInfo.os) {
        html = html + '<br><strong>- Operating system: </strong>' + moreInfo.os;

        html = html + '<br><strong>- Version: </strong>' + moreInfo.os_version;
      }
      return html;
    } else {
      return null;
    }
  }

  displayMap() {
    const geolocation = this.session.geoLocation;
    if (geolocation && geolocation.moreInfo) {
      geolocation.moreInfo = this.moreInfoTohtml(geolocation.moreInfo);
    }
    if (geolocation.lat) {
      geolocation.Latitude = geolocation.lat;
    }
    if (geolocation.lng) {
      geolocation.Longitude = geolocation.lng;
    }

    geolocation.time = geolocation.last_check || new Date();

    const ref = RootAppComponent.dialog.open(MapViewComponent, {
      data: {
        positions: [geolocation],
      },

      panelClass: 'modal-map',
      width: '800px',
      height: '600px',
    });

    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  disableLogin() {
    this.operatorService.disableLogin(this.session.operatorID,this.sessionType).subscribe((res) => {
      if (res) {
        this.operator.enabled = false;
        NotifyAppComponent.displayToast('success', 'Disable Login', 'This operator is not able to login');
      }
    });
  }

  enableLogin() {
    this.operatorService.enableLogin(this.session.operatorID,this.sessionType).subscribe((res) => {
      if (res) {
        this.operator.enabled = true;
        NotifyAppComponent.displayToast('success', 'Enable Login', 'This operator is able to login');
      }
    });
  }

  displayUserInfo() {
    this.router.navigate(['/merchant', { outlets: { page: ['operator-session-overview', this.session.operatorID] } }]);
    this.closeEvent();
  }

  contactUser() {
    RootAppComponent.dialog.open(OperatorContactComponent, {
      width: '600px',
      data: this.session.operatorID,
    });
  }

  viewUserLogs() {
    const ref = RootAppComponent.dialog.open(LogUserListComponent, {
      data: this.session.operatorID,
      width: '90%',
      height: '80%',
    });

    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  includesOwnSession(ids: String[]) {
    return ids.includes(this.currentUser.sessionID);
  }

  deleteSession() {
    const isOwnSession = this.includesOwnSession([this.session._id]);
    const confirmDialogParams = isOwnSession
      ? {
          data: new ConfirmDialog(
            'fas fa-info',
            'Are you sure?',
            '<p>You are about to remove your connected session, you need to logout to proceed with this operation</p>',
            'No',
            'Yes'
          ),
        }
      : {
          data: new ConfirmDialog(
            'fas fa-info',
            'Are you sure?',
            '<p>Removing the session, the user will be disconnected  </p>',
            'No',
            'Yes'
          ),
        };
    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, confirmDialogParams);
    ref.componentInstance.onConfirm.subscribe((val) => {
      if (val == true) {
        this.sessionService.deleteSession(this.session._id).subscribe((res) => {
          if (res['success']) {
            if (isOwnSession) {
              this.authenticationService.logout().subscribe(
                () => {
                  this.router.navigate(['/login']);
                },
                (err: any) => {
                  console.log(err);
                }
              );
            } else {
              NotifyAppComponent.displayToast(
                'success',
                'Successfull operation',
                'Session has been successfully removed'
              );
              this.sessionDeleted.next(this.session);
            }

            ref.close();
            this.closeEvent();
          }
        });
      } else {
        ref.close();
      }
    });
  }

  changePassword() {
    const ref = RootAppComponent.dialog.open(OperatorChangePasswordComponent, {
      data: this.session.operatorID,
      width: '650px',
      panelClass: 'noCard',
    });

    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  viewAgentDetails() {
    const label = `Agent Details: ${this.session.CalculatedName}`;

    const ref = RootAppComponent.dialog.open(AgentDetailsComponent, {
      data: {
        label,
        agents: [this.session.clientInfo],
        sessionID: this.sessionID,
      },
    });

    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  goToOperatorSessionOverview() {
    this.router.navigate(['/merchant', { outlets: { page: ['operator-session-overview', this.session.operatorID] } }]);
  }
}
