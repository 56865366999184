<ipv-page
  *ngIf="viewModel$ | async as viewModel"
  [dataHeader]="{
    title: title,
    counterValue: viewModel.treatmentProducts.length,
    imageSrc: titleIcon
  }"
  [isFetchingInitialData]="isFetchingInitialData"
  [headerSize]="'smaller'"
>
  <div class="row clearfix flex mb ctl">
    <div class="full-width flex">
      <button
        *ipvIsPromoterOrAdmin
        (click)="add(viewModel.treatmentProducts)"
        mat-raised-button
        style="margin-right: 5px"
        color="primary"
      >
        <mat-icon>add</mat-icon>
        Add Product
      </button>

      <button class="mr" (click)="goToGuide()" mat-raised-button style="margin-right: 5px !important" color="accent">
        Download Guide
        <mat-icon class="fas fa-file-download"></mat-icon>
      </button>

      <button class="mr" (click)="goToShop()" mat-raised-button style="margin-right: 5px" color="primary">
        ADA Login
        <mat-icon class="fas fa-shopping-basket"></mat-icon>
      </button>
    </div>

    <mat-button-toggle-group class="btn-group rm-m half-width" (change)="setIsOmittedFilter($event.value)">
      <mat-button-toggle class="" [value]="null">
        All
      </mat-button-toggle>

      <mat-button-toggle class="" [value]="'0'">
        Active
      </mat-button-toggle>

      <mat-button-toggle class="" [value]="'1'">
        Not Active
      </mat-button-toggle>
    </mat-button-toggle-group>

    <button
      class="row select-btn"
      (click)="selectAll(viewModel.treatmentProducts)"
      mat-stroked-button
      matTooltip="Select All"
    >
      <mat-icon>border_all</mat-icon>
    </button>

    <button
      class="row select-btn"
      (click)="selectPage(viewModel.treatmentProducts)"
      mat-stroked-button
      matTooltip="Select Page"
    >
      <mat-icon>border_outer</mat-icon>
    </button>

    <button
      class="animated"
      *ngIf="countSelectedItems(viewModel.treatmentProducts)"
      [matMenuTriggerFor]="ActionBtnmenu"
      mat-stroked-button
      matTooltip="Options"
    >
      <mat-icon class="fas fa-cog"></mat-icon>
    </button>

    <mat-menu #ActionBtnmenu="matMenu">
      <button class="row select-btn" (click)="unSelectAll(viewModel.treatmentProducts)" mat-menu-item>
        <mat-icon>select_all</mat-icon>
        Deselect
      </button>

      <button class="row select-btn" (click)="disableGroup(viewModel.isPromoterOrAdmin)" mat-menu-item>
        <mat-icon>cancel</mat-icon>
        Disable
      </button>

      <button
        class="row select-btn"
        (click)="enableGroup(viewModel.practice.ID, viewModel.isPromoterOrAdmin)"
        mat-menu-item
      >
        <mat-icon>check_circle</mat-icon>
        Enable
      </button>

      <button class="row select-btn" *ipvIsPromoterOrAdmin (click)="addDocumentGroup()" mat-menu-item color="">
        <mat-icon>add_circle</mat-icon>
        Add document
      </button>

      <button class="row select-btn" *ipvIsPromoterOrAdmin (click)="importDocumentGroup()" mat-menu-item color="">
        <mat-icon>import_export</mat-icon>
        Import document
      </button>
    </mat-menu>
  </div>

  <ipv-data-table-control-container class="mt">
    <mat-form-field class="full-width" appearance="outline">
      <mat-label>Label, Item Code</mat-label>
      <div style="display: flex">
        <input
          class="clearfix"
          [value]="viewModel.labelItemCode"
          (input)="setLabelItemCodeFilter($event.target.value)"
          name="Labels"
          matInput
        />
        <ipv-clear-search-button *ngIf="viewModel.labelItemCode" (click)="setLabelItemCodeFilter('')">
        </ipv-clear-search-button>
      </div>
    </mat-form-field>

    <mat-form-field class="full-width" appearance="outline">
      <mat-label>Category</mat-label>
      <mat-select class="" (selectionChange)="setCategoryIdFilter($event.value)" name="Category" placeholder="Category">
        <mat-option [value]="null">
          All
        </mat-option>
        <mat-option
          *ngFor="
            let a of viewModel.treatmentProducts
              | objectPropertyValue: 'Category.Label'
              | unique
              | without: ['', null]
              | orderBy
          "
          [value]="a"
        >
          {{ treatmentUtil.getLabel(a) }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="full-width" appearance="outline">
      <mat-label>Type</mat-label>
      <mat-select class="" (selectionChange)="setTypeIdFilter($event.value)" name="Type" placeholder="Type">
        <mat-option [value]="null">
          All
        </mat-option>
        <mat-option
          *ngFor="
            let a of viewModel.treatmentProducts
              | objectPropertyValue: 'Type.Label'
              | unique
              | without: ['', null]
              | orderBy
          "
          [value]="a"
        >
          {{ treatmentUtil.getLabel(a) }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="full-width" appearance="outline">
      <mat-label>Brand</mat-label>
      <mat-select class="" (selectionChange)="setBrandIdFilter($event.value)" name="Brand" placeholder="Brand">
        <mat-option [value]="null">
          All
        </mat-option>
        <mat-option
          *ngFor="
            let a of viewModel.treatmentProducts
              | objectPropertyValue: 'Brand.Label'
              | unique
              | without: ['', null, 'N/A']
              | orderBy
          "
          [value]="a"
        >
          {{ treatmentUtil.getLabel(a) }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </ipv-data-table-control-container>

  <ipv-data-table
    [hasCheckboxes]="true"
    [table]="viewModel.treatmentProducts"
    [hide]="hiddenColumns"
    [rename]="renamedColumns"
    [order]="order"
    [menuTemplate]="actions"
    [customComponents]="{
      logo: logo,
      Active: isActiveIndicator
    }"
    (pageChanged)="setPage($event)"
    (itemClicked)="viewTreatment($event.ID)"
    (itemChecked)="updateIsChecked($event)"
  >
  </ipv-data-table>

  <ng-template #actions let-record>
    <button (click)="viewTreatment(record['ID'])" mat-menu-item>
      <mat-icon class="fas fa-box-open"></mat-icon>
      <span>View Product</span>
    </button>

    <button (click)="editTreatment(record['ID'], viewModel.treatmentProducts)" mat-menu-item>
      <mat-icon>description</mat-icon>
      <span>Edit Product</span>
    </button>

    <button (click)="viewCategory(record['Category_key'])" mat-menu-item>
      <mat-icon class="fas fa-th"></mat-icon>
      <span>View Category</span>
    </button>

    <button (click)="viewType(record['Type_key'])" mat-menu-item>
      <mat-icon class="fas fa-th-large"></mat-icon>
      <span>View Type</span>
    </button>

    <button (click)="viewBrand(record['Brand_key'])" mat-menu-item>
      <mat-icon class="fas fa-parachute-box"></mat-icon>
      <span>View Brand</span>
    </button>

    <button (click)="viewDocuments(record['ID'], treatmentUtil.getLabel(record['Label']))" mat-menu-item>
      <mat-icon>book</mat-icon>
      <span>View Product Documents</span>
    </button>

    <button (click)="viewSupplier(record['Manufacturer_Key.Effective'])" mat-menu-item>
      <mat-icon class="fas fa-industry mr"></mat-icon>
      <span>Manufacturer {{ record['Manufacturer.Name.Effective'] }}</span>
    </button>

    <button (click)="viewSupplier(record['Distributor_Key.Effective'])" mat-menu-item>
      <mat-icon class="fas fa-truck mr"></mat-icon>
      <span>Distributor {{ record['Distributor.Name.Effective'] }}</span>
    </button>

    <ng-container *ipvIsPromoterOrAdmin>
      <button (click)="addDocument(record['ID'])" mat-menu-item>
        <mat-icon>add_circle</mat-icon>
        <span>Add Document</span>
      </button>

      <button (click)="importDocument(record['ID'])" mat-menu-item>
        <mat-icon>import_export</mat-icon>
        <span>Import Document</span>
      </button>

      <button
        *ngIf="record['Active'] == '1'"
        (click)="disable(record['ID'], viewModel.isPromoterOrAdmin, viewModel.treatmentProducts)"
        mat-menu-item
      >
        <mat-icon>cancel</mat-icon>
        <span>Disable</span>
      </button>

      <button
        *ngIf="record['Active'] == '0'"
        (click)="enable(record['ID'], viewModel.treatmentProducts, viewModel.isPromoterOrAdmin)"
        mat-menu-item
      >
        <mat-icon>check_circle</mat-icon>
        <span>Enable</span>
      </button>
    </ng-container>
  </ng-template>
</ipv-page>

<ng-template #logo let-record>
  <!--    <app-product-view-logo class="supplierIcon2 prod text-center" [hideZoomButton]="true"-->
  <!--                           [productID]="record['ID']"   [LastModified]="record['LastModified']">-->
  <!--    </app-product-view-logo>-->
</ng-template>

<ng-template #isActiveIndicator let-record>
  <ipv-list-active-label [isActive]="record['Active'] | binaryBool"></ipv-list-active-label>
</ng-template>
