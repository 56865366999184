import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { AuthenticationService } from '../../authentication/shared/authentication.service';
import { WikiService } from '../shared/wiki.service';
import { WikiLeftMenuComponent } from '../wiki-left-menu/wiki-left-menu.component';

@Component({
  selector: 'app-wiki-group-view-list',
  templateUrl: './wiki-group-view-list.component.html',
  styleUrls: ['./wiki-group-view-list.component.css'],
})
export class WikiGroupViewListComponent implements OnInit {
  groups;

  loading = false;

  utils = new UtilsClass();

  sessionTypeParams;

  sessionType;

  constructor(
    public authenticationService: AuthenticationService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private wikiService: WikiService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {}

  ngOnInit() {
    this.activeRoute.url.subscribe((res) => {
      const _res = this.utils.getSessionTypeParams(res);

      if (_res) {
        this.sessionTypeParams = _res;
      }

      this.authenticationService.getSessionType().subscribe((res) => {
        if (res && (res == 'admin' || res == 'promoter') && this.sessionTypeParams) {
          this.sessionType = this.sessionTypeParams;
        } else {
          this.sessionType = res;
          this.sessionTypeParams = null;
        }
        this.setup();
      });
    });

    WikiLeftMenuComponent.updateHeader.subscribe((res) => {
      if (res) {
        this.setup();
      }
    });
  }

  setup() {
    this.loading = true;
    this.groups = [];

    this.wikiService.getWikiMenu({}, this.sessionType).subscribe((res3) => {
      if (res3) {
        this.loading = false;

        this.groups = res3;
      }
    });
  }

  moveToPage(id) {
    if (id) {
      if (this.sessionTypeParams) {
        this.router.navigate([
          '/wiki-' + this.sessionTypeParams,
          {
            outlets: {
              wikiPageRoute: ['view', id],
            },
          },
        ]);
      } else {
        this.router.navigate([
          '/wiki',
          {
            outlets: {
              wikiPageRoute: ['view', id],
            },
          },
        ]);
      }
    }
  }

  moveToGroup(id) {
    if (id) {
      if (this.sessionTypeParams) {
        this.router.navigate([
          '/wiki-' + this.sessionTypeParams,
          {
            outlets: {
              wikiPageRoute: ['group', id],
            },
          },
        ]);
      } else {
        this.router.navigate([
          '/wiki',
          {
            outlets: {
              wikiPageRoute: ['group', id],
            },
          },
        ]);
      }
    }
  }

  isNew(date) {
    if (date) {
      const days = this.utils.daysPast(date);

      if (days < 15) {
        return true;
      } else {
        return false;
      }
    }

    return false;
  }

  isAwsome(icon) {
    if (icon && icon.indexOf('fa-') != -1) {
      return true;
    }

    return false;
  }
}
