<div class="rel contact-card dc row clearfix" *ngIf="contactObject" @onChangeAnimation>
  <div class="animate clearfix">
    <div class="card-header primary-gradient-img clearfix dentHeading">
      <div class="row clearfix">
        <!-- Modal dismiss button -->
        <button class="btn-close btn-clear mat-button white" *ngIf="isModal == true" (click)="closeModal()">
          <mat-icon>clear</mat-icon>
        </button>
        <!-- merchant calculated name -->
        <div class="row titleArea clearfix">
          <app-contact-view-picture class="headNavView userIconView mr pull-left" [contactID]="contactObject['ID']" [LastModified]="contactObject['LastModified']">
          </app-contact-view-picture>
          <h2 class="summary-header rm-m">
            {{ contactObject.Salutation }} {{ contactObject.CalculatedName }}
            <span class="subLabel" *ngIf="contactObject.TradingAs">{{ contactObject.TradingAs }}</span>
          </h2>
        </div>
      </div>
      <div
        class="action-button"
        *ngIf="contactObject['emails.Email'] != null || contactObject['addresses.Calculated'] != null"
      >
        <button class="actionMenuToggle" [matMenuTriggerFor]="btnDentMenu" mat-raised-button>
          Actions <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #btnDentMenu="matMenu">
          <button *ngIf="isPromoterOrAdmin == true" (click)="modifyContact()" mat-menu-item>
            <mat-icon>edit</mat-icon>
            <span class="mobHide">Edit Contact</span>
          </button>

          <button *ngIf="contactObject['emails.Email']" (click)="contactDentist()" mat-menu-item>
            <mat-icon>contact_mail</mat-icon>
            <span class="mobHide">Contact {{ contactObject.CalculatedName }}</span>
          </button>
          <!-- <button mat-menu-item *ngIf="contactObject['addresses.Calculated']" (click)="displayMap()">
            <mat-icon>place</mat-icon>
            <span class="mobHide">{{"View on map" | translate}}</span>
          </button> -->
        </mat-menu>
      </div>
    </div>

    <div class="full-width clearfix row">
      <div class="row mt clearfix details flex" *ngIf="contactObject['mobiles.Number']">
        <mat-icon class="ico">phone_android</mat-icon>

        <div class="row-content">
          <p class="rm-mb rm-mt lead">{{ contactObject['mobiles.Number'] | customPhone }}</p>
          <p class="small">Mobile</p>
        </div>
      </div>

      <hr *ngIf="contactObject['phones.Number']"/>
      <div class="row mt clearfix details flex" *ngIf="contactObject['phones.Number']">
        <mat-icon class="ico">phone</mat-icon>

        <div class="row-content">
          <p class="rm-mb rm-mt lead">{{ contactObject['phones.Number'] | customPhone: 'landLine' }}</p>
          <p class="small">Work</p>
        </div>
      </div>
      <hr *ngIf="contactObject['emails.Email']"/>
      <div class="row mt clearfix details flex" *ngIf="contactObject['emails.Email']">
        <mat-icon class="ico">email</mat-icon>
        <div class="row-content">
          <p class="rm-mb rm-mt lead">{{ contactObject['emails.Email'] }}</p>
          <p class="small">Email</p>
        </div>
      </div>

      <hr *ngIf="contactObject['ContactType.Label']"/>
      <div class="row mt clearfix details flex" *ngIf="contactObject['ContactType.Label']">
        <mat-icon class="ico">contact_mail</mat-icon>

        <div class="row-content">
          <p class="rm-mb rm-mt lead">{{ contactObject['ContactType.Label'] }}</p>
          <p class="small">Contact Type</p>
        </div>
      </div>

      <hr *ngIf="contactObject['RelativeType.Label']"/>
      <div class="row mt clearfix details flex" *ngIf="contactObject['RelativeType.Label']">
        <mat-icon class="ico">sentiment_very_satisfied</mat-icon>

        <div class="row-content">
          <p class="rm-mb rm-mt lead">{{ contactObject['RelativeType.Label'] }}</p>
          <p class="small">Relative Type</p>
        </div>
      </div>

      <hr *ngIf="contactObject['addresses.Calculated']"/>
      <div class="row mt clearfix details flex" *ngIf="contactObject['addresses.Calculated']">
        <mat-icon class="ico">location_on</mat-icon>
        <div class="row-content">
          <p class="rm-mb rm-mt lead">{{ contactObject['addresses.Calculated'] }}</p>
          <p class="small">Address</p>
        </div>
      </div>

      <hr *ngIf="contactObject['addresses.Calculated']"/>

      <app-map-view
        class="mt mb"
        *ngIf="contactObject['addresses.Calculated'] && displayMapFlag == true"
        [icon]="iconAddres"
        [forceGettingAddress]="true"
        [canClose]="canClose"
        [unitNumber]="contactObject['addresses.Unit']"
        [canClick]="false"
        [streetType]="contactObject['addresses.Street Type']"
        [streetName]="contactObject['addresses.Street Name']"
        [latitude]="contactObject['Address.Latitude']"
        [longitude]="contactObject['Address.Longitude']"
        [streetNumber]="contactObject['addresses.Street Nr']"
        [zoom]="17"
        [postCode]="contactObject['addresses.Postcode']"
        [state]="contactObject['addresses.State']"
        [suburb]="contactObject['addresses.Suburb']"
        [moreInfo]=""
        (notFoundEvent)="notFoundEvent($event)"
      >
      </app-map-view>
    </div>

    <div
      class="row clearfix text-center"
      *ngIf="
        !contactObject['mobiles.Number'] &&
        !contactObject['phones.Number'] &&
        !contactObject['emails.Email'] &&
        !contactObject['addresses.Calculated']
      "
    >
      <h4>No details to show</h4>
      <!-- <p>Click the button to start fill more details</p> -->
      <button *ngIf="isPromoterOrAdmin == true" (click)="modifyContact()" mat-raised-button color="accent">
        Fill more details
      </button>
    </div>
  </div>
</div>
