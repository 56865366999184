import { animate, animateChild, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output, SimpleChanges } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { ActionLogViewComponent } from '../action-log-view/action-log-view.component';
import { ActionLogService } from '../shared/action-log.service';
import { ActionLogUtilClass } from '../shared/actionLogUtil';

@Component({
  selector: 'app-activity-list',
  templateUrl: './activity-list.component.html',
  styleUrls: ['./activity-list.component.css'],
  animations: [
    trigger('fade', [transition(':enter', [style({ opacity: 0 }), animate('.6s ease')])]),
    trigger('stagger', [transition(':enter', [query(':enter', stagger('.3s', [animateChild()]), { optional: true })])]),
  ],
})
export class ActivityListComponent implements OnInit {
  @Input()
  targetID = '';

  @Input()
  itemID = '';

  @Input()
  merchantID;

  @Input()
  dateFrom;

  @Input()
  dateTo;

  @Input()
  limit;

  @Input()
  isInvitationActions = true;

  @Input()
  section;

  @Output()
  getTotalActivities = new EventEmitter();

  isModal = false;
  isPromoterOrAdmin = false;
  settings = Settings.global;

  actionLogs;
  eventLogs;
  combinedLogs;

  emptyList = false;
  util = new UtilsClass();
  actionLogUtil = new ActionLogUtilClass();
  sessionType;

  constructor(
    private authenticationService: AuthenticationService,
    private actionlogService: ActionLogService,
    private router: Router,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.targetID) {
        this.targetID = data.targetID;
      }

      if (data.itemID) {
        this.itemID = data.itemID;
      }

      this.isModal = true;
    }
  }

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((sessionType) => {
      this.sessionType = sessionType;

      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isPromoterOrAdmin = res;

        const payload = {
          isInvitationActions: this.isInvitationActions,
          limit: this.limit,
          section: this.section,
          dateFrom: this.util.EPdateFormat(this.dateFrom),
          dateTo: this.util.EPdateFormat(this.dateTo),
        };

        if (this.isPromoterOrAdmin == true) {
          payload['merchantID'] = this.merchantID;
        }

        this.actionlogService.getActionLogList(payload, this.sessionType).subscribe((res) => {
          this.actionLogs = res;
          this.getTotalActivities.emit(res.length);
          if (this.actionLogs && this.actionLogs.length > 0) {
            this.emptyList = false;
          } else {
            this.emptyList = true;
          }
        });
      });
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.authenticationService.getSessionType().subscribe((sessionType) => {
      this.sessionType = sessionType;
      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isPromoterOrAdmin = res;

        const payload = {
          isInvitationActions: true,
          dateFrom: this.util.EPdateFormat(this.dateFrom),
          dateTo: this.util.EPdateFormat(this.dateTo),
        };

        if (this.isPromoterOrAdmin == true) {
          payload['merchantID'] = this.merchantID;
        }

        this.actionlogService.getActionLogList(payload, this.sessionType).subscribe((res) => {
          this.actionLogs = res;
          this.getTotalActivities.emit(res.length);
          if (this.actionLogs && this.actionLogs.length > 0) {
            this.emptyList = false;
          } else {
            this.emptyList = true;
          }
        });
      });
    });
  }

  viewLog(id) {
    const ref = RootAppComponent.dialog.open(ActionLogViewComponent, {
      data: id,
      width: '650px',
    });

    ref.componentInstance.closeModal.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  dateFilter(date) {
    return moment(date).format('dddd Do MMM');
  }

  timeFilter(date) {
    return moment(date).format('h:mm a');
  }

  showInvitationDetails(whoKey, whatKey, tablename) {
    if (tablename == 'Invitation') {
      // this.router.navigate([
      //   "/merchant",
      //   { outlets: { 'page': ["invitation-view", ID] } }
      // ]);
      this.router.navigate([
        '/merchant',
        {
          outlets: {
            page: ['customer-profile', whoKey, 'treatment', whatKey],
          },
        },
      ]);
    }
  }
}
