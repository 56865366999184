<div class="card-header primary-gradient-img clearfix inModal">
  <div class="row clearfix">
    <button class="btn-close btn-clear mat-button white" (click)="closeEvent()">
      <mat-icon>clear</mat-icon>
    </button>
    <div class="row titleArea clearfix">
      <mat-icon
        class="pull-left fas fa-folder"
        style="font-size: 3rem; margin-right: 20px; line-height: 3rem; height: 3rem; width: 3rem"
      ></mat-icon>
      <h2 class="summary-header rm-m">
        Create new Landing Page
        <span class="subLabel">Please select type of your landing page</span>
      </h2>
    </div>
  </div>
</div>

<mat-dialog-content>
  <div class="row clearfix flex flex-wrap">
    <button
      class="selection-btn button gradient purple animated zoomIn faster"
      (click)="sendType('PLandPg')"
      mat-raised-button
    >
      <div class="btn-container">
        <img
          src="https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/main/images/viral-marketing.png"
        />
        <h3 class="">
          Product Landing Page
          <mat-icon>chevron_right</mat-icon>
        </h3>
        <p class="small">
          Promote your {{ "KEYWORD.practice" | translate | titlecase}}, by creating a landing page of your news ,
          services and
          products.
        </p>
      </div>
    </button>

    <button
      class="selection-btn button gradient red animated zoomIn faster delay-0-3s"
      (click)="sendType('FLandPg')"
      mat-raised-button
    >
      <div class="btn-container">
        <img src="https://s3.ap-southeast-2.amazonaws.com/application.assets.resources/public/img/money-idea.png" />
        <h3 class="">
          Finance Landing Page
          <mat-icon>chevron_right</mat-icon>
        </h3>
        <p class="small">
          Create a Finance offer and share it with your customers, they can start an application right from the page.
        </p>
      </div>
    </button>
  </div>
</mat-dialog-content>
