import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ContractService } from '../shared/contract.service';

import { animate, state, style, transition, trigger } from '@angular/animations';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { UtilsService } from '../../../shared/services/utils.service';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { QuotationDecisionModalComponent } from '../../quotation/quotation-decision-modal/quotation-decision-modal.component';
import { QuotationDocumentCreateComponent } from '../../quotation/quotation-document-create/quotation-document-create.component';

export class Info {
  label: string;
  value: string;
}

@Component({
  selector: 'app-contract-supporting-documents',
  templateUrl: './contract-supporting-documents.component.html',
  styleUrls: ['./contract-supporting-documents.component.css'],
  animations: [
    trigger('simpleFadeAnimation', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [style({ opacity: 0, height: 0 }), animate(300)]),
      transition(':leave', animate(300, style({ opacity: 0, height: '*' }))),
    ]),
  ],
})
export class ContractSupportingDocumentsComponent implements OnInit {
  infoList: Info[] = [];

  @Input()
  contractID;

  @Input()
  @Output()
  close = new EventEmitter();

  isModal = false;

  isPromoterOrAdmin = false;

  @Output()
  hideBackButton = new EventEmitter();

  showAddComment = false;

  documents;

  capacityDetails;

  IDDocuments = [];
  employDocuments = [];
  residenceDocuments = [];

  selectedDocID;
  selectedDocType; // Determine ngClass in template

  customerID;
  lockedPCID;
  unlockPCID;

  mulCheckLists = [];
  selectCheckList;
  contractDetails;

  isPassDecision = false;

  util = new UtilsClass();

  constructor(
    private activeRouter: ActivatedRoute,
    private contractSerivce: ContractService,
    private authenticationService: AuthenticationService,
    private utilService: UtilsService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      this.contractID = data;
      this.isModal = true;
    }
  }

  ngOnInit() {
    this.activeRouter.params.subscribe((params) => {
      if (params['contractID']) {
        this.contractID = params['contractID'];
      }

      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isPromoterOrAdmin = res;

        if (this.contractID) {
          const payloadContract = {
            // fields: 'Customer.FullName',
          };

          this.contractSerivce
            .fetchContractDetails$(this.contractID, payloadContract, this.isPromoterOrAdmin)
            .subscribe((res) => {
              if (res) {
                this.contractDetails = res;
                this.customerID = res.Customer_key;
              }
            });

          this.contractSerivce.getCapacityCheckDetails(this.contractID).subscribe((res) => {
            if (res) {
              this.lockedPCID = res.PersonalCircumstances_key;
              this.unlockPCID = res.UnlockedPersonalCircum_key;
            }
          });

          const payload = {
            fields:
              'DateAdded,DateTimeCreated,Description,Extension,ID,Label,URL.Complete,URL.Relative,URL.Base,AWS_URL,AWS_Bucket_Name,DocumentType_Code,PromoterDecision',
          };

          this.contractSerivce.getConractDocument(this.contractID, payload).subscribe((res) => {
            if (res) {
              this.IDDocuments = res.filter(
                (x) =>
                  x.DocumentType_Code == 'IDL' ||
                  x.DocumentType_Code == 'IDP' ||
                  x.DocumentType_Code == 'IDC' ||
                  x.DocumentType_Code == 'PID'
              );

              this.employDocuments = res.filter((x) => x.DocumentType_Code == 'POE');

              this.residenceDocuments = res.filter((x) => x.DocumentType_Code == 'PRA');
            }
          });
        }
      });
    });
  }

  toNumber(value) {
    return Number(value);
  }

  addSupportingDocument() {
    const ref = RootAppComponent.dialog.open(QuotationDocumentCreateComponent, {
      data: {},
      width: '500px',
    });
    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  // decision modal
  makeDecision() {
    const ref = RootAppComponent.dialog.open(QuotationDecisionModalComponent, {
      data: {
        checkType: 'document',
      },
      width: '600px',
    });

    ref.componentInstance.closeModal.subscribe((res) => {
      ref.close();
    });

    ref.componentInstance.getDecision.subscribe((res) => {
      if (res) {
        const _decision = res == 'Accept' ? 'PASS' : 'REJECT';

        const payload = {
          promoterDecision: _decision,
        };

        this.contractSerivce.promoterDecision(this.selectedDocID, payload).subscribe((res) => {
          if (res) {
            ref.close();

            NotifyAppComponent.displayToast('success', 'Promoter decision', 'You have successfully saved the decision');

            if (_decision == 'REJECT') {
              ref.componentInstance.getDeclineReason.subscribe((res) => {
                if (res) {
                  // send email to customer for re-upload document

                  const reasonsList = res.split(',');
                  let _string = '';
                  for (let i = 0; i < reasonsList.length; i++) {
                    _string += `<li>${reasonsList[i]}</li>`;
                  }
                  _string = '<ul>' + _string + '</ul>';

                  const payload = {
                    documentType: this.selectedDocType,
                    reasons: _string,
                  };

                  this.contractSerivce.sendConsumerReuploadDocMessage(this.contractID, payload).subscribe((res) => {
                    if (res) {
                      NotifyAppComponent.displayToast(
                        'success',
                        'Send Message',
                        `You have successfully send a message`
                      );
                    }
                  });
                }
              });
            }
          }
        });
      }
    });
  }

  viewDocs(ID, docType) {
    this.selectedDocID = null;

    if (ID) {
      this.selectedDocType = docType;
      this.selectedDocID = ID;

      if (this.selectedDocType == 'id') {
        const payload = {
          ownerID: this.customerID,
        };

        this.contractSerivce.getIDDocumentGlobal(payload).subscribe((res) => {
          if (res && res.length > 0) {
            if (res.length > 1) {
              this.mulCheckLists = res;
              this.selectCheckList = res[0];
              this.changeCheckListValue(res[0]);
            } else {
              this.mulCheckLists = [];
              this.changeCheckListValue(res[0]);
            }
          }
        });
      } else if (this.selectedDocType == 'employment') {
        const payload = {
          personalCircumstanceID: this.unlockPCID,
        };
        this.contractSerivce.getPCIEmploymentAndBenefit(payload, this.isPromoterOrAdmin).subscribe((res) => {
          if (res && res.length > 0) {
            if (res.length > 1) {
              this.mulCheckLists = res;
              this.selectCheckList = res[0];
              this.changeCheckListValue(res[0]);
            } else {
              this.mulCheckLists = [];
              this.changeCheckListValue(res[0]);
            }
          }
        });
      } else if (this.selectedDocType == 'residence') {
        const payload = {
          personalCircumstanceID: this.unlockPCID,
        };
        this.contractSerivce.getPCIHouseOfResidence(payload, this.isPromoterOrAdmin).subscribe((res) => {
          if (res && res.length > 0) {
            if (res.length > 1) {
              this.mulCheckLists = res;
              this.selectCheckList = res[0];
              this.changeCheckListValue(res[0]);
            } else {
              this.mulCheckLists = [];
              this.changeCheckListValue(res[0]);
            }
          }
        });
      }

      this.checkPromoterDecision();
    }
  }

  checkPromoterDecision() {
    this.isPassDecision = false;

    const payload = {
      fields: 'PromoterDecision',
    };

    this.utilService.getEpdocument(this.selectedDocID, payload, 'admin').subscribe((res) => {
      if (res && res.PromoterDecision && res.PromoterDecision == 'PASS') {
        this.isPassDecision = true;
      }
    });
  }

  changeCheckListValue(value) {
    if (value) {
      if (this.selectedDocType == 'id') {
        if (value['Type.Code'] == 'IDL') {
          this.infoList = [
            {
              label: 'ID Holder',
              value: value['Owner.Name'],
            },
            {
              label: 'License Number',
              value: value['Number'],
            },
            {
              label: 'State of Issue',
              value: value['Issuer.Code'],
            },
          ];
        } else if (value['Type.Code'] == 'IDP') {
          this.infoList = [
            {
              label: 'Passport Name',
              value: value['Owner.Name'],
            },
            {
              label: 'Passport Number',
              value: value['Number'],
            },
            {
              label: 'Place of Issue',
              value: value['Issuer.Code'],
            },
          ];
        } else if (value['Type.Code'] == 'IDC') {
          this.infoList = [
            {
              label: 'ID Holder',
              value: value['Owner.Name'],
            },
            {
              label: 'ID Number',
              value: value['Number'],
            },
            // {
            //   label: 'State of Issue',
            //   value: value['Issuer.Code']
            // },
          ];
        } else if (value['Type.Code'] == 'PID') {
          this.infoList = [
            {
              label: 'ID Holder',
              value: value['Owner.Name'],
            },
            {
              label: 'ID Number',
              value: value['Number'],
            },
            // {
            //   label: 'State of Issue',
            //   value: value['Issuer.Code']
            // },
          ];
        }
      } else if (this.selectedDocType == 'employment') {
        this.infoList = [
          {
            label: 'Employer Name',
            value: value['Info.Employment.Employer.Name'],
          },
          {
            label: 'Employee Role',
            value: value['Info.Employment.Role'],
          },
          {
            label: 'Income Amount',
            value: `$${value['Income.Value']}`,
          },
          {
            label: 'Income Frequency',
            value: this.convertFrequency(value['Income.Frequency']),
          },
          {
            label: 'Employment Type',
            value: value['Info.Employment.Type.Label'],
          },
        ];
      } else if (this.selectedDocType == 'residence') {
        this.infoList = [
          {
            label: 'Property Owner Name',
            value: this.contractDetails['Customer.FullName'],
          },
          {
            label: 'Property Address',
            value: value['Adress.Calculated'],
          },
        ];
      }
    }
  }

  convertFrequency(value) {
    let result;
    if (value && value == '1') {
      result = 'Weekly';
    } else if (value && value == '2') {
      result = 'Fortnightly';
    } else if (value && value == '4') {
      result = 'Monthly';
    } else if (value && value == '12') {
      result = 'Quarterly';
    } else if (value && value == '26') {
      result = 'Half Yearly';
    } else if (value && value == '48') {
      result = 'Yearly';
    } else {
      result = '';
    }

    return result;
  }
}
