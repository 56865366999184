<div class="rel">
  <div class="card-header primary-gradient-img clearfix confirmModal">
    <button class="btn-close btn-clear mat-button smt white custom-close" (click)="closeEvent()">
      <mat-icon>clear</mat-icon>
    </button>
    <div class="full-width flex main-title-container">
      <div class="row full-width title-container clearfix">
        <div class="title-container-sub">
          <h2 class="text-left title-header rm-m">
            {{ "KEYWORD.dental" | translate | titlecase }} Tips

            <span class="subLabel">Explore all things {{ 'KEYWORD.dental' | translate }} via a wide range of articles,
              fact sheets and
              videos that the team at {{ "BRANDING.Brand Name" | translate | titlecase }} Sandbox have assembled.</span>
          </h2>
        </div>
      </div>
    </div>
  </div>

  <mat-dialog-content style="max-height: 58vh !important">
    <div class="full-width">
      <div class="pull-left mb flex thrd-width text-center" *ngFor="let d of offers">
        <mat-card class="editor-card-preview row file animate mat-elevation-z4 full-width"
                  (click)="submitOrPreview(d.code)">
          <button class="action-button-gallery btn" *ngIf="viewOnly != true"
                  (click)="$event.stopPropagation(); preview(d.code)" mat-icon-button matTooltip="Preview">
            <mat-icon class="accent-color">visibility</mat-icon>
          </button>

          <div class="full-width gallery-card-campaign-support-container">
            <app-ep-document-view-image class="gallery-card-campaign-support" [defaultPicture]="d.image"
                                        [hideZoomButton]="false">
            </app-ep-document-view-image>
          </div>
          <hr/>
          <div class="full-width text-center">
            <h3 class="sr-title editor-description">{{ d.label }}</h3>
          </div>
        </mat-card>
      </div>
    </div>
  </mat-dialog-content>
  <div class="drop-button text-center mt full-width">
    <hr/>
    <button class="btn-large" (click)="closeEvent()" mat-raised-button color="primary">close</button>
  </div>
</div>
