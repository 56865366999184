import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faq-merchant-modal',
  templateUrl: './faq-modal-merchant.component.html',
  styleUrls: ['./faq-modal-merchant.component.css'],
})
export class FAQModalMerchantComponent implements OnInit {
  simplePageCode = 'Frequent Asked Questions';

  panelOpenState = false;

  constructor() {}

  ngOnInit() {}
}
