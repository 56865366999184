import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-patient-update-details',
  templateUrl: './patient-update-details.component.html',
  styleUrls: ['./patient-update-details.component.css'],
})
export class PatientUpdateDetailsComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
