import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { TreatmentService } from '../shared/treatment.service';

@Component({
  selector: 'app-document-list-consumer-simple',
  templateUrl: './document-list-consumer-simple.component.html',
  styleUrls: ['./document-list-consumer-simple.component.css'],
})
export class DocumentListConsumerSimpleComponent implements OnInit {
  @Input() invitationID;

  @Output() isExist: EventEmitter<any> = new EventEmitter();

  treatments = [];

  totalCost = 0;

  constructor(private treatmentService: TreatmentService) {}

  ngOnInit() {
    if (this.invitationID) {
      this.treatmentService.getSimpleTreatment(this.invitationID).subscribe((res) => {
        if (res && res['treatmentDocuments'] && res['treatmentDocuments'].length > 0) {
          this.treatments = res['treatmentDocuments'];

          this.isExist.emit(true);
        } else {
          this.isExist.emit(false);
        }
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.invitationID) {
      this.treatmentService.getSimpleTreatment(this.invitationID).subscribe((res) => {
        if (res && res['treatmentDocuments'] && res['treatmentDocuments'].length > 0) {
          this.treatments = res['treatmentDocuments'];

          this.isExist.emit(true);
        } else {
          this.isExist.emit(false);
        }
      });
    }
  }
}
