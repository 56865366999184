<!-- 2FA input -->
<mat-form-field class="full-width large-input">
  <mat-label>Secure Code</mat-label>
  <input id="auth-input" matInput placeholder="Secure Code" value=""/>
</mat-form-field>

<!-- Show counter -->
<div class="resend-countdown text-center">
  <p class="small">Didn't recieve your code? We can resend your code in <span>00</span> seconds.</p>
</div>
<!-- Hide button until counter at zero -->
<div class="resend-button text-center" *ngIf="counterFinished">
  <p class="small">Didn't recieve your code?</p>
  <button class="btn-small" mat-raised-button>Send again</button>
</div>
