import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, of, Subscription } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { GoogleReviewsService } from '../shared/google-reviews.service';
import { GmbReview, GmbStarRating } from '../shared/types/gmb.type';

@Component({
  selector: 'sr-google-review-list',
  templateUrl: './google-review-list.component.html',
  styleUrls: ['./google-review-list.component.css'],
})
export class GoogleReviewListComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();

  Google_Reviews_Account_ID: string;
  Google_Reviews_Location_ID: string;
  Google_Review_Place_ID: string;

  Google_Reviews_Business_Name: string;
  Google_Reviews_Business_Address: string;
  Google_Reviews_Business_Rating: number;
  Google_Reviews_Business_Review_Count: number;

  merchantID;

  nameFilter;
  orderByDateFilter = '-updateTime';
  orderByRatingFilter = '';
  dateFromFilter = null;
  dateToFilter = null;

  isCheckingAccount = true;
  isGoogleReviewsEnabled = false;
  isServiceReady = false;
  isFetchingGoogleReviews = true;

  googleReviews: GmbReview[] = [];

  sessionType = 'guest';

  businessInfo$: Observable<{
    Google_Reviews_Business_Name: string;
    Google_Reviews_Business_Address: string;
    Google_Reviews_Business_Rating: number;
    Google_Reviews_Business_Star_Rating: number;
    Google_Reviews_Business_Review_Count: number;
  }> = of(null);

  constructor(
    private googleReviewsService: GoogleReviewsService,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((sessionType) => {
      this.sessionType = sessionType;

      this.authenticationService.getCurrentPractice().subscribe((merchant) => {
        this.merchantID = merchant['ID'];
        this.Google_Reviews_Account_ID = merchant['Google_Reviews_Account_ID'];
        this.Google_Reviews_Location_ID = merchant['Google_Reviews_Location_ID'];
        this.Google_Review_Place_ID = merchant['Google_Review_Place_ID'];

        if (this.Google_Reviews_Account_ID) {
          this.isCheckingAccount = false;
          this.isGoogleReviewsEnabled = true;
          this.googleReviewsService.onLoad().subscribe(() => {
            this.isServiceReady = true;
            this.getBusinessInfo();
          });
        } else {
          this.isCheckingAccount = false;
          this.isGoogleReviewsEnabled = false;
        }
      });
    });
  }

  getBusinessInfo() {
    this.isFetchingGoogleReviews = true;
    this.businessInfo$ = this.googleReviewsService
      .getLocation(this.merchantID, this.sessionType, this.Google_Reviews_Location_ID)
      .pipe(
        mergeMap((location) => {
          return this.googleReviewsService
            .getReviews(
              this.merchantID,
              this.sessionType,
              this.Google_Reviews_Account_ID,
              this.Google_Reviews_Location_ID
            )
            .pipe(
              map((placeReview) => {
                this.googleReviews = placeReview.reviews.map((review) => {
                  switch (review.starRating) {
                    case GmbStarRating.ONE:
                      review.starRating = 1;
                      break;

                    case GmbStarRating.TWO:
                      review.starRating = 2;
                      break;

                    case GmbStarRating.THREE:
                      review.starRating = 3;
                      break;

                    case GmbStarRating.FOUR:
                      review.starRating = 4;
                      break;

                    case GmbStarRating.FIVE:
                      review.starRating = 5;
                      break;

                    default:
                      review.starRating = 0;
                      break;
                  }

                  const date = new Date(review.updateTime);

                  review.updateTime = date;

                  review['reviewer.displayName'] = review.reviewer.displayName;

                  return review;
                });

                return {
                  Google_Reviews_Business_Name: location.title,
                  Google_Reviews_Business_Address: location.storefrontAddress.addressLines[0],
                  Google_Reviews_Business_Rating: placeReview.averageRating,
                  Google_Reviews_Business_Star_Rating: Math.round(placeReview.averageRating),
                  Google_Reviews_Business_Review_Count: placeReview.totalReviewCount,
                };
              }),
              tap(() => {
                this.isFetchingGoogleReviews = false;
              })
            );
        })
      );
  }

  setDateFrom(date) {
    this.dateFromFilter = date;
  }

  setDateTo(date) {
    this.dateToFilter = date;
  }

  updateReply(replyUpdate: { id: string; comment: string }) {
    const reviewIndex = this.googleReviews.findIndex((review) => review.reviewId === replyUpdate.id);
    const newGoogleReviews = this.googleReviews;
    newGoogleReviews[reviewIndex].reviewReply.comment = replyUpdate.comment;
    this.googleReviews = [...newGoogleReviews];

    NotifyAppComponent.displayToast('success', 'Success', 'Reply sent');
  }

  setGoogleReviewData(temporaryGoogleReviewData: {
    Google_Reviews_Account_ID: string;
    Google_Reviews_Location_ID: string;
    Google_Review_Place_ID: string;
    Google_Reviews_Business_Name: string;
    Google_Reviews_Business_Address: string;
    Google_Reviews_Business_Rating: number;
    Google_Reviews_Business_Star_Rating: number;
    Google_Reviews_Business_Review_Count: number;
    Temporary_Google_Review_Refresh_Token: string;
  }) {
    this.authenticationService.getCurrentPractice().subscribe((merchant) => {
      this.merchantID = merchant['ID'];
      this.Google_Reviews_Account_ID = merchant['Google_Reviews_Account_ID'];
      this.Google_Reviews_Location_ID = merchant['Google_Reviews_Location_ID'];
      this.Google_Review_Place_ID = merchant['Google_Review_Place_ID'];

      this.isCheckingAccount = false;
      this.isGoogleReviewsEnabled = true;
      this.googleReviewsService.onLoad().subscribe(() => {
        this.isServiceReady = true;
        this.getBusinessInfo();
      });
    });
  }

  disconnectAccount() {
    const confirmDialog: any = new ConfirmDialog(
      'settings',
      'Are you sure?',
      '<p>You are about to disconnect your Google My Business account. You will loose access to the Google Reviews feature and patients will not be able to perform reviews</p> ',
      'No',
      'Yes, disconnect'
    );

    const confirmDialogRef = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
      width: '400px',
    });

    confirmDialogRef.componentInstance.onConfirm.subscribe((confirmed) => {
      if (confirmed === false) {
        confirmDialogRef.close();
      } else {
        confirmDialogRef.close();

        this.googleReviewsService.disconnectGoogleReviewAccount(this.sessionType, this.merchantID).subscribe(
          () => {
            NotifyAppComponent.displayToast(
              'success',
              'Success',
              'Google Review Account Disconnected. Google Reviews Disabled.'
            );
          },
          (error) => {
            NotifyAppComponent.displayToast(
              'error',
              'Error',
              'Something went wrong when trying to disconnect your Google account for Google reviews.'
            );
          }
        );
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
