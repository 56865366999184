import { Location } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output, SimpleChanges } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'environments/environment';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { UtilsService } from '../../../shared/services/utils.service';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';
import { ConfirmOptionDialog } from '../../../shared/types/confirm-option-dialog';
import { NotifyAppComponent } from '../../../shared/types/notify-app-component';
import { Settings } from '../../../shared/types/settings';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { EventLogListComponent } from '../../action-log/event-log-list/event-log-list.component';
import { ActionLogService } from '../../action-log/shared/action-log.service';
import { ExistingPatientSummaryComponent } from '../../existing-patient/existing-patient-summary/existing-patient-summary.component';
import { InvitationTemplateMergerComponent } from '../../invitation-template/invitation-template-merger/invitation-template-merger.component';
import { TreatmentSaveModalComponent } from '../../invitation-template/treatment-save-modal/treatment-save-modal.component';
import { MerchantViewComponent } from '../../merchant/merchant-view/merchant-view.component';
import { SmsConversationComponent } from '../../message/sms-conversation/sms-conversation.component';
import { SmsPromoterComponent } from '../../message/sms-promoter/sms-promoter.component';
import { NotesModal, NotesModalConfig } from '../../notes/modals/notes-modal/notes.modal';
import { PatientCardChangesModalComponent } from '../../patient/patient-card-changes-modal/patient-card-changes-modal.component';
import { PatientCardDeferModalComponent } from '../../patient/patient-card-defer-modal/patient-card-defer-modal.component';
import { PatientCardStopModalComponent } from '../../patient/patient-card-stop-modal/patient-card-stop-modal.component';
import { PatientService } from '../../patient/shared/patient.service';
import { ProductViewModalComponent } from '../../product/product-view-modal/product-view-modal.component';
import { ProductService } from '../../product/shared/product.service';
import { InvitationResendComponent } from '../invitation-resend/invitation-resend.component';
import { InvitationService } from '../shared/services/invitation.service';
import { WakandaFilesComponent } from '../wakanda-files-view/wakanda-files-view.component';

@Component({
  selector: 'app-invitation-view',
  templateUrl: './invitation-view.component.html',
  styleUrls: ['./invitation-view.component.css'],
})
export class InvitationViewComponent implements OnInit {
  enablePrinting = true;

  @Input()
  membership = false;

  @Input()
  invitationID;

  @Input()
  isIntegrated = false;

  @Output()
  goBack = new EventEmitter();

  subjectArray = [];

  isParameter = false;

  invitation = null;
  dateNow;
  cardID;

  deletedInvitation = [];

  context = Settings.global['context'];

  settings = Settings.global;

  isModuleAdvancedTreatmentActive = Settings.global['isModuleAdvancedTreatmentActive'];
  isModuleDiscountsActive = Settings.global['isModuleDiscountsActive'];
  isModuleNoteFeaturesActive = Settings.global['isModuleNoteFeaturesActive'];
  isModuleTreatmentTemplateActive = Settings.global['isModuleTreatmentTemplateActive'];
  isModuleExperimentalFeaturesActive = Settings.global['isModuleExperimentalFeaturesActive'];
  isModulePatientSMSActive = Settings.global['isModulePatientSMSActive'];

  items = [
    'invitation',
    'contract',
    'customer',
    'dentist',
    'merchant',
    'instantOffer',
    'appointment',
    'campaign',
    'landingPage',
    'settlement',
    'note',
  ];
  brandName = Settings.global['brandName'];

  color = 'primary';
  mode = 'buffer';
  progress = 'Invitation Created';
  Step = 'Invitation Card';
  value = 0;
  bufferValue = 0;

  colorInv = 'primary';
  modeInv = 'buffer';
  progressInv = 'Invitation Created';
  StepInv = 'Invitation Card';
  valueInv = 0;
  bufferValueInv = 0;

  ApplicationProgressLabel = 'Application Progress';

  isFinance = false;

  docViewed = false;
  consViewed = false;
  consComplete = false;

  isAdvancedTreatment = true;
  isSimpleTreatment = true;
  isPromoterOrAdmin = false;

  chartColors = Settings.global['chartColors'];

  profileLink;

  unseenDocs;
  seenDocs;
  seenPercent;

  documents = [];
  documentChartData = [];

  util = new UtilsClass();

  eventLogs = {
    emailViewed: null,
    emailSent: null,
    smsSent: null,
    respondTime: null,
  };

  patientResponse = [];

  givenInformedConsentDate;

  oldStatusBlock = false;

  contentHeight: number;
  sessionType = 'guest';

  constructor(
    private productService: ProductService,
    private actionlogService: ActionLogService,
    private UtilsService: UtilsService,
    private authenticationService: AuthenticationService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private location: Location,
    private dialog: MatDialog,
    private invitationService: InvitationService,
    private patientService: PatientService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      this.invitationID = data;
    }
  }

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((sessionType) => {
      if (sessionType) {
        this.sessionType = sessionType;
      }

      this.UtilsService.getCurrentAccess().subscribe((access) => {
        if (access) {
          this.isModuleAdvancedTreatmentActive = access['isModuleAdvancedTreatmentActive'];
          this.isModuleDiscountsActive = access['isModuleDiscountsActive'];
          this.isModuleNoteFeaturesActive = access['isModuleNoteFeaturesActive'];
          this.isModuleTreatmentTemplateActive = access['isModuleTreatmentTemplateActive'];
          this.isModuleExperimentalFeaturesActive = access['isModuleExperimentalFeaturesActive'];
          this.isModulePatientSMSActive = access['isModulePatientSMSActive'];
        }

        this.UtilsService.getServerDate().subscribe((res) => {
          this.dateNow = res;

          this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
            this.isPromoterOrAdmin = res;

            this.authenticationService.getCurrentUser().subscribe((res) => {
              if (res && res.success && res.data['merchantKey'] && res.data['merchantKey']) {
                this.cardID = res.data['merchantKey'];
              }
            });

            this.activeRoute.params.subscribe((params) => {
              if (params['id']) {
                this.invitationID = params['id'];
                this.isParameter = true;
              }

              this.invitationService
                .getInvitationDetails(this.invitationID, {}, this.sessionType, this.membership)
                .subscribe((res) => {
                  this.invitation = res;

                  this.getPicture();

                  const informedPayload = {
                    invitationID: this.invitation['ID'],
                  };
                  this.invitationService.getInformedConsent(informedPayload, this.sessionType).subscribe((res) => {
                    if (res && res.length > 0) {
                      if (res[0]['InformedConsent.Given.Flag'] == '1') {
                        this.givenInformedConsentDate = res[0]['InformedConsent.Given.When'];
                        this.enablePrinting = true;
                      }
                    }
                  });
                });
            });
          });
        });
      });
    });
  }

  getInvitationUpdate(d) {
    if (d && d.ID) {
      this.invitation= d;
    }
  }


  sendSMS(id, merchantID) {
    if (id) {
      if (this.sessionType == 'admin' || this.sessionType == 'promoter') {
        const ref2 = RootAppComponent.dialog.open(SmsPromoterComponent, {
          data: {
            merchantID,
            cardID: id,
            singleChat: true,
          },
          panelClass: 'noCard',
          width: '500px',
        });
        ref2.componentInstance.close.subscribe((data) => {
          ref2.close();
        });

        ref2.componentInstance.getResult.subscribe((data) => {
          if (data) {
            const ref = RootAppComponent.dialog.open(SmsConversationComponent, {
              data,
              panelClass: 'bigger-screen',
              width: '70%',
              height: '95vh',
            });
            const sub = ref.componentInstance.close.subscribe((data) => {
              ref.close();
            });
          }

          ref2.close();
        });
      } else {
        const ref2 = RootAppComponent.dialog.open(SmsConversationComponent, {
          data: {
            cardID: id,
            singleChat: true,
          },
          panelClass: 'bigger-screen',
          width: '70%',
          height: '95vh',
        });
        const sub = ref2.componentInstance.close.subscribe((data) => {
          ref2.close();
        });
      }
    }
  }

  viewPatient() {
    // let ref = this.dialog.open(CustomerProspectViewComponent, {
    //   data: this.invitation['Prospect_key'],
    //   width: "600px",
    //   panelClass: "noCard"
    // });
    // ref.componentInstance.closeModal.subscribe(res => {
    //   if (res == true) {
    //     ref.close();
    //   }
    // })

    this.router.navigate([
      '/merchant',
      {
        outlets: {
          page: ['customer-profile', this.invitation['Prospect_key'], 'patient'],
        },
      },
    ]);
  }

  getPicture() {
    this.profileLink = null;

    if (this.invitation['Prospect_key']) {
      this.profileLink = this.invitationService.getProfilePicStreamLink(this.invitation['Prospect_key']);
    }
  }

  cloneInvitation() {
    const confirm = new ConfirmDialog(
      'fas fa-info',
      '',
      'Are you sure you want to clone this invitation ?',
      'No',
      'Yes'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirm,
    });
    ref.componentInstance.onConfirm.subscribe((confirmation) => {
      if (confirmation === false) {
        ref.close();
      } else {
        this.invitationService.cloneInvitation(this.invitationID, this.isPromoterOrAdmin, false).subscribe((res) => {
          if (res) {
            ref.close();

            if (this.context == 'retail') {
              this.router.navigate(['/merchant', { outlets: { page: ['lending-invitation-create', res] } }]);
            } else {
              this.router.navigate(['/merchant', { outlets: { page: ['invitation-create', res] } }]);
            }
          } else {
            ref.close();
          }
        });
      }
    });
  }

  createNote(patientID: string, invitationID: string) {
    this.dialog.open<NotesModal, NotesModalConfig>(NotesModal, {
      data: {
        patientID,
        parentRoute: 'merchant',
        noteListMode: 'create',
        relationshipLink: {
          label: 'invitation',
          route: `customer-profile/${patientID}/treatment/${invitationID}`,
          itemID: patientID,
        },
      },
    });
  }

  viewNotes(patientID: string) {
    this.dialog.open<NotesModal, NotesModalConfig>(NotesModal, {
      data: {
        parentRoute: 'merchant',
        patientID,
      },
    });
  }

  getDocuments(e) {
    if (e) {
      this.documents = e;
    }
  }

  getViewedDocuments(e) {
    if (e) {
      this.documentChartData = [
        {
          name: 'Documents yet to view',
          value: this.documents.length - e.length,
        },
        {
          name: 'Viewed Documents',
          value: e.length,
        },
      ];
      this.unseenDocs = this.documents.length - e.length;
      this.seenDocs = e.length;

      this.seenPercent = e.length / this.documents.length;
    }
  }

  redirect(invitation) {
    if (invitation && invitation.Instant_Finance_Offer_Key) {
      const url = `${environment.nodeUrl}/d/` + invitation.Instant_Finance_Offer_Key;
      window.open(url, '_blank', 'noopener');
    } else if (invitation && invitation.WakandaID) {
      const url =
        Settings.global['consumerFrontendLink'] + '/consumer/(page:invitation-card/' + invitation.WakandaID + ')';
      window.open(url, '_blank', 'noopener');
    }
  }

  payCashInvitation() {
    const confirmDialog = new ConfirmDialog(
      'fas fa-info',
      'Paying with Cash/Card',
      '<p>Your patient has agreed on their treatment and plans to settle their account by paying with cash or card at the time of their appointment.</p> <p class="noFurther">They will not recieve further notifications from the Smile Right system.</p>',
      'Cancel',
      'Okay'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
      // SET TO PAYING CASH CARD
    });
    ref.componentInstance.onConfirm.subscribe((val) => {
      if (val == true) {
        ref.close();
        ref.afterClosed().subscribe((res) => {
          this.patientService.notFinanceInivation(this.invitationID, true).subscribe((res) => {
            if (res) {
              this.invitation = res;
              NotifyAppComponent.displayToast(
                'success',
                'Status Updated',
                'Patient will pay cash or card at the time of their appointment.'
              );
            }
          });
        });
      } else {
        ref.close();
      }
    });
  }

  deferInvitation() {
    const confirmOptionDialog = new ConfirmOptionDialog(
      'access_alarms',
      'Defer Treatment',
      '<p>Your patient has requested to hold off further notifications about their treatment for a period of time.</p>',
      'Please select time to defer',
      'Cancel',
      'Okay'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmOptionDialog,
    });

    ref.componentInstance.onConfirm.subscribe((res) => {
      if (res == true) {
        ref.close();
        ref.afterClosed().subscribe((res) => {
          const ref1 = RootAppComponent.dialog.open(PatientCardDeferModalComponent, {
            data: {
              invitationID: this.invitationID,
              force: true,
            },
            width: '500px',
          });
          let deferWeeks;
          ref1.componentInstance.done.subscribe((res3) => {
            deferWeeks = res3;
          });
          ref1.componentInstance.invitation.subscribe((res) => {
            this.invitation = res;

            ref1.close();
          });
        });
      }
    });

    // LAUNCH DEFER TREATMENT MODAL ON 'OK' CLICK
  }

  contractLog() {
    const ref = this.dialog.open(EventLogListComponent, {
      data: {
        contractID: this.invitation['contactID'],
        type: 'contract',
        itemID: this.invitation['contactID'],
      },
      width: '900px',
    });

    ref.componentInstance.closeModal.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  patientLog() {
    const ref = this.dialog.open(EventLogListComponent, {
      data: {
        customerID: this.invitation['Prospect_key'],
        target: 'customer',
        targetID: this.invitation['Prospect_key'],
      },
      width: '900px',
    });

    ref.componentInstance.closeModal.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  invitationLog() {
    const ref = this.dialog.open(EventLogListComponent, {
      data: {
        invitationID: this.invitation['ID'],
        type: 'invitation',
        itemID: this.invitation['ID'],
      },
      width: '900px',
    });

    ref.componentInstance.closeModal.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  changesToInvitation() {
    const confirmDialog = new ConfirmDialog(
      'fas fa-info',
      'Changes requested',
      '<p>Your patient requests to modify their treatment plan before proceeding.</p>',
      'Cancel',
      'Okay'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,

      // LAUNCH CHANGES TO INVITATION MODAL ON 'OK' CLICK
    });
    ref.componentInstance.onConfirm.subscribe((res) => {
      if (res == true) {
        ref.close();
        ref.afterClosed().subscribe((res) => {
          const ref1 = RootAppComponent.dialog.open(PatientCardChangesModalComponent, {
            data: {
              invitationID: this.invitationID,
              force: true,
            },
            width: '500px',
          });

          ref1.componentInstance.invitation.subscribe((data) => {
            this.invitation = data;

            ref1.close();
            ref1.afterClosed().subscribe((res) => {
              if (data && data.newID) {
                const ref2 = RootAppComponent.dialog.open(ConfirmDialogComponent, {
                  data: new ConfirmDialog(
                    'fas fa-info',
                    'Create new invitation?',
                    "<p>By pressing 'Okay' you will start creating a new invitation.</p>",
                    'Cancel',
                    'Okay'
                  ),
                });
                ref2.componentInstance.onConfirm.subscribe((res) => {
                  if (res == true) {
                    ref2.close();
                    ref2.afterClosed().subscribe((res) => {
                      if (this.context == 'retail') {
                        this.router.navigate([
                          '/merchant',
                          { outlets: { page: ['lending-invitation-create', data.newID] } },
                        ]);
                      } else {
                        this.router.navigate(['/merchant', { outlets: { page: ['invitation-create', data.newID] } }]);
                      }
                    });
                  } else {
                    ref2.close();
                  }
                });
              }
            });
          });
        });
      }
    });
  }

  createTemplate() {
    const _ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: {
        confirmMessage: '<p>Are you sure  You want to create a Treatment Template based on this invitation ? .</p>',
        noMessage: 'Cancel',
        yesMessage: 'Yes',
        title: 'Apply Treatment  Template',
        icon: 'check_circle',
        isInformation: false,
      },
      // SET TO PAYING CASH CARD
    });

    _ref.componentInstance.onConfirm.subscribe((val) => {
      if (val == true) {
        _ref.close();
        _ref.afterClosed().subscribe((res) => {
          const ref = RootAppComponent.dialog.open(TreatmentSaveModalComponent, {
            data: {},

            width: '450px',
          });

          ref.componentInstance.result.subscribe((payload) => {
            if (payload) {
              const _payload = payload;

              this.invitationService
                .createInvitationTreatmentTemplate(this.invitation['WakandaID'], _payload)
                .subscribe((res) => {
                  if (res) {
                    NotifyAppComponent.displayToast('success', 'Successful operation', 'Treatment Template is Saved');

                    if (res._id) {
                      ref.close();
                      ref.afterClosed().subscribe((c) => {
                        const ref2 = this.dialog.open(InvitationTemplateMergerComponent, {
                          data: {
                            templateID: res._id,
                            viewOnly: true,
                          },

                          width: '1200px',
                        });

                        ref2.componentInstance.delete.subscribe((res) => {
                          ref2.close();
                        });

                        ref2.componentInstance.close.subscribe((res) => {
                          ref2.close();
                        });
                      });
                    } else {
                      NotifyAppComponent.displayToast(
                        'Error',
                        'Error',
                        'We could not create a treatment template based on this invitation'
                      );
                    }
                  }
                });
            }
          });

          ref.componentInstance.closeModal.subscribe((res) => {
            if (res == true) {
              ref.close();
            }
          });
        });
      } else {
        _ref.close();
      }
    });
  }

  notProceedingInvitation() {
    const confirmDialog = new ConfirmDialog(
      'remove_circle',
      'Does not wish to proceed',
      '<p>Your patient has decided not to proceed with their treatment.</p><p class="noFurther">They will not recieve further notifications from the Smile Right system.</p>',
      'Cancel',
      'Okay'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,

      // LAUNCH NOT PROCEEDING REASONS / QUESTIONS MODAL ON 'OKAY' CLICK
    });

    ref.componentInstance.onConfirm.subscribe((res) => {
      if (res == true) {
        ref.close();
        ref.afterClosed().subscribe((res) => {
          const ref1 = RootAppComponent.dialog.open(PatientCardStopModalComponent, {
            data: {
              invitationID: this.invitationID,
              force: true,
            },

            width: '500px',
            panelClass: 'flex',
            disableClose: true,
          });

          ref1.componentInstance.invitation.subscribe((data) => {
            this.invitation = data;
            ref1.close();
          });
        });
      }
    });
  }

  deleteInvitation() {
    const confirmDialog = new ConfirmDialog(
      'delete',
      'Archive Invitation?',
      '<p><strong>Are you sure?</strong></p><p class="noFurther">They will not recieve further notifications from the Smile Right system.</p>',
      'No',
      'Yes, archive'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
    });
    ref.componentInstance.onConfirm.subscribe((val) => {
      if (val == true) {
        this.invitationService
          .deleteInvitation(this.invitationID, this.isPromoterOrAdmin, this.membership)
          .subscribe((res) => {
            if (res == true) {
              this.deletedInvitation.push(this.invitationID);

              this.invitation['Voided'] = '1';

              NotifyAppComponent.displayToast(
                'success',
                'Successfull operation',
                'The invitation has been successfully archived'
              );
              ref.close();
            }
          });

        ref.close();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.invitationID && changes.invitationID.previousValue != changes.invitationID.currentValue) {
      this.authenticationService.getSessionType().subscribe((res) => {
        if (res) {
          this.sessionType = res;
        }

        this.invitationService
          .getInvitationDetails(this.invitationID, {}, this.sessionType, this.membership)
          .subscribe((res) => {
            this.invitation = res;
          });
      });
    }
  }

  contact() {
    const data = {
      targetType: 'invitation',
      targetID: this.invitation['ID'],
      asGuest: false,
      asProfile: true,
      asPractice: true,
      subjectArray: this.subjectArray,
    };
    AuthenticationService.contactInputEvent.emit(data);
  }

  openCampaign(id) {
    this.router.navigate(['/merchant', { outlets: { page: ['marketing-campaign-view', id] } }]);
  }

  openProductViewDialog() {
    const ref = this.dialog.open(ProductViewModalComponent, {
      data: this.invitation['Product_Key'],
      width: '750px',
      panelClass: 'noCard',
    });

    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  openMerchantDialog() {
    const ref = this.dialog.open(MerchantViewComponent, {
      data: this.invitation['Merchant_Key'],
      width: '600px',
    });
    ref.componentInstance.close.subscribe((res) => {
      ref.close();
    });
  }

  openCustomerDialog() {
    const ref = this.dialog.open(ExistingPatientSummaryComponent, {
      data: this.invitation['ExistingPatientID'],
      width: '750px',
      panelClass: 'noCard',
    });
  }

  resendInvitation() {
    const ref = this.dialog.open(InvitationResendComponent, {
      data: {
        invitationID: this.invitation['ID'],
        membership: this.membership,
      },
      width: '600px',
      panelClass: 'noCard',
    });

    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });

    ref.componentInstance.getInvitation.subscribe((res) => {
      if (res) {
        this.invitation = res;
      }
    });
  }

  cancel() {
    this.location.back(); // <-- go back to previous location on cancel
  }

  checkAdvancedTreatment(b) {
    this.isAdvancedTreatment = b;
  }

  checkSimpleTreatment(b) {
    this.isSimpleTreatment = b;
  }

  contractDetails() {
    this.router.navigate(['/merchant', { outlets: { page: ['contract-view', this.invitation['contactID']] } }]);
  }

  convertPatientResponse(val) {
    let result;
    if (val) {
      return val.split('<BR>');
    }
  }

  wakandaFiles(id) {
    if (id) {
      const ref = RootAppComponent.dialog.open(WakandaFilesComponent, {
        data: {
          invitationID: id,
        },
        width: '810px',
      });

      ref.componentInstance.closeEvent.subscribe((res) => {
        ref.close();
      });
    }
  }

  // parts for printing

  getFileLink(fileID) {
    return environment.nodeUrl + '/files/file-view/' + fileID;
  }

  goBackEvent() {
    if (this.isIntegrated == true) {
      this.goBack.emit(true);
    }
  }
}
