import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'treatment-choice',
  templateUrl: './treatment-choice.component.html',
  styleUrls: ['./treatment-choice.component.css'],
})
export class TreatmentChoiceComponent implements OnInit {
  dontShowAgainType = JSON.parse(localStorage.getItem('dontShowAgainSimpleType'));

  @Output()
  isSimple = new EventEmitter();

  @Output()
  dontShowAgain = new EventEmitter();

  @Output()
  startTreatmentTour = new EventEmitter();

  @Output()
  closeModal = new EventEmitter();

  constructor() {}

  ngOnInit() {
    if (this.dontShowAgainType == true) {
      this.isSimple.emit(true);
      this.closeModal.emit(true);
    }
  }

  onSimple() {
    this.isSimple.emit(true);
    this.closeModal.emit(true);
  }

  onAdvanced() {
    this.isSimple.emit(false);
    this.closeModal.emit(true);
  }

  doNotShow() {
    localStorage.setItem('dontShowAgainSimpleType', JSON.stringify(this.dontShowAgainType));

    this.dontShowAgain.emit(this.dontShowAgainType);

    this.isSimple.emit(false);
  }

  startTheTour() {
    this.startTreatmentTour.emit(true);
  }
}
