<div class="file-container">
  <div class="row clearfix">
    <div class="pull-left">
      <p>Filter by</p>
    </div>
    <div class="pull-right">
      <mat-card class="uploader"> UPLOAD</mat-card>
    </div>
  </div>

  <mat-card class="card uploadsCard" @ngIfAnimation>
    <div class="row clearfix breadcrumbs">
      <!-- breadcrumb array -->
      <label>
        <div class="inline" *ngFor="let d of directorys">
          <button class="btn-small" mat-button>{{ d.name }}</button>
          <span>/</span>
        </div>
      </label>
    </div>
    <div class="file-content-area row clearfix">
      <mat-list>
        <!-- list any directories creates -->
        <h3 class="animate" *ngIf="folders?.length > 0" mat-subheader>Folders</h3>
        <mat-list-item class="directory animate" *ngFor="let folder of folders">
          <mat-icon mat-list-icon>folder</mat-icon>
          <h4 mat-line>{{ folder.name }}</h4>
          <p mat-line>{{ folder.updated | date }}</p>
          <!-- action menu for folders -->
          <button (click)="actionButtonMenu()" mat-icon-button>
            <mat-icon>more_vert</mat-icon>
          </button>
          <!-- <mat-menu #folderMenu="matMenu">
              <button mat-menu-item>Rename</button>
              <button mat-menu-item>Delete</button>
            </mat-menu> -->
        </mat-list-item>
        <!-- uploaded items -->
        <mat-divider *ngIf="files?.length > 0"></mat-divider>
        <h3 class="animate" *ngIf="files?.length > 0" mat-subheader>Files</h3>
        <mat-list-item class="file animate" *ngFor="let f of files">
          <!-- display image preview -->
          <mat-icon mat-list-icon>portrait</mat-icon>

          <h4 mat-line>{{ f.name }}</h4>
          <p mat-line>{{ f.updated | date }}</p>
          <!-- action menu for files -->
          <button (click)="actionButtonMenu()" mat-icon-button>
            <mat-icon>more_vert</mat-icon>
          </button>
          <!-- <mat-menu #fileMenu="matMenu">
              <button mat-menu-item>View</button>
              <button mat-menu-item>Rename</button>
              <button mat-menu-item>Delete</button>
            </mat-menu> -->
        </mat-list-item>
      </mat-list>
    </div>
  </mat-card>
</div>
