<div class="row clearfix" *ngIf="treatments.length > 0">
  <h2 class="sr-title sm-h2 rm-mb rm-mt">
    <mat-icon>attach_file</mat-icon>
    Attached documents
  </h2>

  <div class="row-content-col">
    <app-file-list class="" [files]="treatments" [invitationID]="invitationID" [canEdit]="false" [canAction]="false">
    </app-file-list>
  </div>
</div>
