import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';

import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class FileService {
  constructor(private http: HttpClient) {}

  getFile(id) {
    return this.http.get(environment.nodeUrl + '/files/' + id).pipe(map((res: HttpResponse<any>) => res['data']));
  }

  updateMetaDate(id, body) {
    return this.http
      .put(environment.nodeUrl + '/files/meta/' + id, body)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }

  deleteFile(id) {
    return this.http.delete(environment.nodeUrl + '/files/' + id);
  }

  getArrayFile(IDArray) {
    let ids;

    if (Array.isArray(IDArray)) {
      ids = IDArray.join(',');
    } else {
      ids = IDArray;
    }

    return this.http
      .get(environment.nodeUrl + '/files/array/' + ids)
      .pipe(map((res: HttpResponse<any>) => res['data']));
  }
}
