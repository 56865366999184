import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tip-overview',
  templateUrl: './tip-overview.component.html',
  styleUrls: ['./tip-overview.component.css'],
})
export class TipOverviewComponent implements OnInit {
  tip: any;
  constructor() {}

  ngOnInit() {}

  createTip(tip) {
    this.tip = tip;
  }
}
