import { RevenueReportActualRecord } from '../../../types/revenue-report.type';

export const BORROWER_FEES_ACTUAL_DIVIDER: RevenueReportActualRecord = {
  label: `Borrower Fees`,
  code: '',
  actual: null,
  refunds: null,
  actualNet: null,
  actualPercentage: null,
};

export const BORROWER_INTEREST_ACTUAL_DIVIDER: RevenueReportActualRecord = {
  label: `Borrower Interest`,
  code: '',
  actual: null,
  refunds: null,
  actualNet: null,
  actualPercentage: null,
};

export const MERCHANT_FEES_ACTUAL_DIVIDER: RevenueReportActualRecord = {
  label: `Merchant Fees`,
  code: '',
  actual: null,
  refunds: null,
  actualNet: null,
  actualPercentage: null,
};

export const EMPTY_ACTUAL_SECTION_TOTAL = {
  label: 'Total',
  code: '',
  actual: 0,
  refunds: 0,
  actualPercentage: 0,
  actualNet: 0,
};
