<div class="rel text-center">
  <mat-icon class="large-modal-icon">help</mat-icon>
  <h3 class="sr-title sm-h3 no-pad">Forgotten your username?</h3>

  <p class="lead rm-mt rm-mb">Don't worry, we can recover it for you.</p>
  <p class="">Simply enter your email address below, and we will send you your username.</p>

  <div class="clearfix">
    <!-- Email Address -->
    <mat-form-field class="full-width" appearance="outline">
      <mat-label>Email</mat-label>
      <input class="mat-block" matInput placeholder="Please enter email" value=""/>
    </mat-form-field>
  </div>
  <br/>

  <div class="drop-buttons">
    <button class="btn-large" mat-raised-button color="accent">Submit</button>
  </div>
</div>
