import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsClass } from '../../../shared/types/utils/utils.class';
import { CustomerProspectService } from '../../customer-prospect/shared/customerProspect.service';
import { MedicalHistoryService } from '../shared/medical-history.service';

import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { ContactViewComponent } from '../../contact/contact-view/contact-view.component';
import { CustomerProspectViewComponent } from '../../customer-prospect/customer-prospect-view/customer-prospect-view.component';
import { MerchantService } from '../../merchant/shared/merchant.service';

export type NgxGaugeType = 'full' | 'arch' | 'semi';

@Component({
  selector: 'app-medical-history-view',
  templateUrl: './medical-history-view.component.html',
  styleUrls: ['./medical-history-view.component.css'],
})
export class MedicalHistoryViewComponent implements OnInit, OnChanges {
  @Input()
  medicalHistoryID;

  @Input()
  title = 'Detailed Health History';
  medicalHistory;
  printableMedicalHistory = {};

  @Input()
  isConsumer = false;

  util = new UtilsClass();
  step = 0;

  @Input()
  drugAlert = false;

  merchant;

  patient = {};
  gender;
  phoneNumber;
  addressCalculated;
  dateOfBirth;
  email;
  mobile;
  gaugeType: NgxGaugeType = 'semi';
  gaugeValue = 0;
  gaugeLabel;

  moreIsLess = {
    '0': { color: '#db291b' },
    '1': { color: '#ff5722' },
    '2': { color: '#ff9800' },
    '3': { color: '#ffc107' },
    '4': { color: '#ffeb3b' },
    '5': { color: '#8bc34a' },
  };

  lessIsMore = {
    '1': { color: '#8bc34a' },
    '2': { color: '#ffeb3b' },
    '3': { color: '#ffc107' },
    '4': { color: '#ff9800' },
    '5': { color: '#ff5722' },
  };

  guardianID;
  guardian;

  hasGp = false;
  gpDoctorID;
  gpDoctor;

  // patientID;

  hasHealthFund = false;
  healthFund;

  hasMedicare = false;
  mediCare;

  selectedVersion = 'current';

  previousAllergyList = [];
  previousIllnessList = [];

  profileLink;

  sessionType = 'merchant';

  medicalHistoryList = [];
  isAdminOrPromoter = false;
  invitation;
  illness = [];
  allergy = [];

  noWarning = false;
  updateMedicationHistory = false;
  noAction = false;

  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private medicalHistoryService: MedicalHistoryService,
    private customerProspectService: CustomerProspectService,
    private merchantService: MerchantService
  ) {}

  ngOnInit() {
    this.authenticationService.isPromoterOrAdmin().subscribe((isAdminOrPromoter) => {
      this.isAdminOrPromoter = isAdminOrPromoter;

      this.authenticationService.getSessionType().subscribe((res) => {
        this.sessionType = res;

        if (this.sessionType == 'customer') {
          this.noWarning = true;
          this.updateMedicationHistory = true;
          this.noAction = true;
        }

        this.activeRoute.params.subscribe((params) => {
          if (params['medicalHistoryID']) {
            this.medicalHistoryID = params['medicalHistoryID'];
          }

          this.setup();
        });
      });
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.medicalHistoryID.firstChange != true &&
      changes.medicalHistoryID.previousValue != changes.medicalHistoryID.currentValue
    ) {
      this.authenticationService.getSessionType().subscribe((sessionType) => {
        this.sessionType = sessionType;

        this.setup();
      });
    }
  }

  getProgress(item) {
    if (item && item.Status == 'Complete') {
      return 100;
    } else if (item && item.Step) {
      const step = Number(item.Step);

      return Number(Number((step / 36) * 100).toFixed(0));
    } else {
      return 0;
    }
  }

  getProgressColor(a) {
    if (a) {
      if (a >= 0 && a < 20) {
        return '#ff0800';
      } else if (a >= 20 && a < 40) {
        return '#f6631e';
      } else if (a >= 40 && a < 60) {
        return '#ec8422';
      } else if (a >= 60 && a < 80) {
        return '#84ec22';
      } else if (a >= 80) {
        return '#00d07a';
      }
    }
    return '#ff0800';
  }

  setup() {
    if (this.medicalHistoryID) {
      const payload = {
        selectInvitation: false,
      };

      this.medicalHistoryService
        .getMedicalHistoryFullDetails(this.medicalHistoryID, payload, this.sessionType)
        .subscribe((res) => {
          if (res) {
            if (res.invitation) {
              this.invitation = res.invitation;
            } else {
              this.invitation = null;
            }

            if (res.medicalHistory) {
              this.medicalHistory = res.medicalHistory;
            } else {
              this.medicalHistory = null;
            }

            if (res.patient) {
              this.patient = res.patient;
            } else {
              this.patient = null;
            }

            if (res.merchant) {
              this.merchant = res.merchant;
            } else {
              this.merchant = null;
            }

            if (res.illness) {
              this.illness = res.illness;
            } else {
              this.illness = [];
            }

            if (res.allergy) {
              this.allergy = res.allergy;
            } else {
              this.allergy = [];
            }

            if (res.doctor && res.doctor.ID) {
              this.hasGp = true;
              this.gpDoctorID = res.doctor.ID;
              this.gpDoctor = res.doctor;
            } else {
              this.hasGp = false;
              this.gpDoctorID = null;
              this.gpDoctor = null;
            }

            if (res.guardian && res.guardian.ID) {
              this.guardianID = res.guardian.ID;
              this.guardian = res.guardian;
            } else {
              this.guardianID = null;
              this.guardian = null;
            }

            if (res.mediCare && res.mediCare.ID) {
              this.hasMedicare = true;
              this.mediCare = {
                number: res.mediCare['Number'],
                expiry: res.mediCare['Date.Expiry'],
                reference: res.mediCare['Text1'],
              };
            } else {
              this.hasMedicare = false;
              this.mediCare = null;
            }

            if (res.healthFund && res.healthFund['ID']) {
              this.hasHealthFund = true;

              if (res.healthFund['Issuer.Code'] == 'OTHRS') {
                this.healthFund = {
                  name: res.healthFund['Issuer.LabelOther'],
                  number: res.healthFund['Number'],
                };
              } else {
                this.healthFund = {
                  name: res.healthFund['Issuer.Label'],
                  number: res.healthFund['Number'],
                };
              }
            } else {
              this.hasHealthFund = false;
              this.healthFund = null;
            }
            this.buildPatient();
            this.buildPrintableMedicalHistory(res.medicalHistory);
          }
        });
    }
  }

  setStep(s) {
    this.step = 0;
  }

  buildPatient() {
    if (this.patient) {
      this.gender = this.patient['Gender.Code'];
      this.phoneNumber = this.patient['phones.Number'];
      this.addressCalculated = this.patient['addresses.Calculated'];
      this.dateOfBirth = this.patient['DateOfBirth'];
      this.email = this.patient['emails.Email'];
      this.mobile = this.patient['mobiles.Number'];
      this.getPicture();
    }
  }

  buildPrintableMedicalHistory(medicalHistory) {
    if (medicalHistory) {
      this.printableMedicalHistory['cleansHowOften'] = medicalHistory['CleansHowOften'] <= '1';
      this.printableMedicalHistory['cleansHowOftenTimes'] = medicalHistory['CleansHowOften'];
      this.printableMedicalHistory['consciousOfColourAppearance'] = medicalHistory['ConsciousOfColourAppearance'] > '0';
      this.printableMedicalHistory['consciousOfColourAppearanceLevel'] = medicalHistory['ConsciousOfColourAppearance'];
      this.printableMedicalHistory['dateActivated'] = UtilsClass.dateParse(medicalHistory['Date.Activated']);
      this.printableMedicalHistory['dateCompleted'] = UtilsClass.dateParse(medicalHistory['Date.Completed']);
      this.printableMedicalHistory['dateTimeCreated'] = UtilsClass.dateParse(medicalHistory['DateTimeCreated']);
      this.printableMedicalHistory['dentalProblemDescription'] = medicalHistory['DentalProblemDescription'];
      this.printableMedicalHistory['doesBiteCheeks'] = medicalHistory['DoesBiteCheeks'] === '1';
      this.printableMedicalHistory['doesClenchOrGrind'] = medicalHistory['DoesClenchOrGrind'] === '1';
      this.printableMedicalHistory['drinkingTimes'] = medicalHistory['DrinkingTimes'];
      this.printableMedicalHistory['drinkWine'] = medicalHistory['DrinkWine'] === '1';
      this.printableMedicalHistory['familyStressDescription'] = medicalHistory['FamilyStressDescription'];
      this.printableMedicalHistory['foodCaughtInTeeth'] = medicalHistory['FoodCaughtInTeeth'] === '1';
      this.printableMedicalHistory['formFilledOnBehalf'] = medicalHistory['FormFilledOnBehalf'] === '1';
      this.printableMedicalHistory['hasBadBreath'] = medicalHistory['HasBadBreath'] > '0';
      this.printableMedicalHistory['hasBadBreathLevel'] = medicalHistory['HasBadBreath'];
      this.printableMedicalHistory['hasBiteAdjusted'] = medicalHistory['HasBiteAdjusted'] === '1';
      this.printableMedicalHistory['hasClickingLockingJaw'] = medicalHistory['HasClickingLockingJaw'] === '1';
      this.printableMedicalHistory['hasDentalProblem'] = medicalHistory['HasDentalProblem'] === '1';
      this.printableMedicalHistory['hasFamilyStress'] = medicalHistory['HasFamilyStress'] === '1';
      this.printableMedicalHistory['hasHeadacheOrJawOrFacialPain'] =
        medicalHistory['HasHeadacheOrJawOrFacialPain'] === '1';
      this.printableMedicalHistory['hasPeridontalTreatment'] = medicalHistory['HasPeridontalTreatment'] === '1';
      this.printableMedicalHistory['hasSeriousIllness'] = medicalHistory['HasSeriousIllness'] === '1';
      this.printableMedicalHistory['hasSoreMuscles'] = medicalHistory['HasSoreMuscles'] === '1';
      this.printableMedicalHistory['hurtsWhenBiteHard'] = medicalHistory['HurtsWhenBiteHard'] === '1';
      this.printableMedicalHistory['gumsBleed'] = medicalHistory['GumsBleed'] === '1';
      this.printableMedicalHistory['isBreastFeeding'] = medicalHistory['IsBreastFeeding'] === '1';
      this.printableMedicalHistory['isPregnant'] = medicalHistory['IsPregnant'] === '1';
      this.printableMedicalHistory['isSmoking'] = medicalHistory['IsSmoking'] === '1';
      this.printableMedicalHistory['name'] = medicalHistory['Card.Name'];
      this.printableMedicalHistory['needsHelpWithForm'] = medicalHistory['NeedsHelpWithForm'] === '1';
      this.printableMedicalHistory['pregnancyMonths'] = medicalHistory['PregnancyMonths'];
      this.printableMedicalHistory['readsWritesEnglish'] = medicalHistory['ReadsWritesEnglish'] === '1';
      this.printableMedicalHistory['sensitiveTeethWithHot'] = medicalHistory['SensitiveTeethWithHot'] > '0';
      this.printableMedicalHistory['sensitiveTeethWithHotLevel'] = medicalHistory['SensitiveTeethWithHot'];
      this.printableMedicalHistory['sensitiveTeethWithCold'] = medicalHistory['SensitiveTeethWithCold'] > '0';
      this.printableMedicalHistory['sensitiveTeethWithColdLevel'] = medicalHistory['SensitiveTeethWithColdLevel'];
      this.printableMedicalHistory['sensitiveTeethWithSweet'] = medicalHistory['SensitiveTeethWithSweet'] > '0';
      this.printableMedicalHistory['sensitiveTeethWithSweetLevel'] = medicalHistory['SensitiveTeethWithSweetLevel'];
      this.printableMedicalHistory['sensitiveTeethWithChew'] = medicalHistory['SensitiveTeethWithChew'] > '0';
      this.printableMedicalHistory['sensitiveTeethWithChewLevel'] = medicalHistory['SensitiveTeethWithChewLevel'];
      this.printableMedicalHistory['smokingTimes'] = medicalHistory['SmokingTimes'];
      this.printableMedicalHistory['speakInPrivateToStaff'] = medicalHistory['SpeakInPrivateToStaff'] === '1';
      this.printableMedicalHistory['status'] = medicalHistory['Status'];
      this.printableMedicalHistory['terrified'] = medicalHistory['TerrifiedLevel'] >= '2';
      this.printableMedicalHistory['terrifiedLevel'] = medicalHistory['TerrifiedLevel'];
      this.printableMedicalHistory['wearsNightPlate'] = medicalHistory['WearsNightPlate'] === '1';
    }
  }

  getPicture() {
    this.profileLink = null;
    if (this.medicalHistory && this.medicalHistory['Card_key'] && this.isConsumer != true) {
      this.profileLink = this.customerProspectService.getProfilePicStreamLink(this.medicalHistory['Card_key']);
    } else if (this.medicalHistory && this.medicalHistory['Merchant_key'] && this.isConsumer == true) {
      this.profileLink = this.merchantService.getProfilePicStreamLink(this.medicalHistory['Merchant_key']);
    }
  }

  viewContact(ID) {
    const ref = RootAppComponent.dialog.open(ContactViewComponent, {
      data: ID,
      width: '650px',
    });

    ref.componentInstance.close.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  openQuickViewDialog(ID) {
    const ref = RootAppComponent.dialog.open(CustomerProspectViewComponent, {
      data: ID,
      width: '650px',
      panelClass: 'noCard',
    });

    ref.componentInstance.closeModal.subscribe((res) => {
      if (res == true) {
        ref.close();
      }
    });
  }

  proceedEvent() {
    this.router.navigate(['/consumer-app', { outlets: { page: ['medical'] } }]);
  }
}
