<div *ngIf="invitation && invitation.ID">
  <div
    class="applicationStatusBar animate"
    [hidden]="invitation['Product.Label.Marketing'] && invitation['Product.Label.Marketing'] != ''"
  >
    <div class="row clearfix mb">
      <div class="fifty-percent right">
        <h4 class="primary-color sm-h4 rm-mb">
          <span class="pull-left">
            <mat-icon class="fas fa-arrow-alt-circle-right"></mat-icon> Status - {{ StepInv }}
          </span>
          <span
            class="pull-right status-box list-label"
            *ngIf="invitation['Voided'] == '0'"
            [style.background]="invitation['Status.Invitation.Colour'] || '#b4bcc1'"
          >{{ progressInv }}
          </span>
          <span
            class="pull-right status-box list-label"
            *ngIf="invitation['Voided'] == '1'"
            [style.backgroundColor]="'#EF4727'"
          >
            Deleted
          </span>
        </h4>
      </div>
    </div>

    <section class="progress-section rel" *ngIf="!invitation['creditRemaining']">
      <div class="prog-marker s-1 invBar">
        Sent
      </div>
      <div class="prog-marker s-2 invBar">
        Delivered
      </div>
      <div class="prog-marker s-3 invBar">
        Viewed
      </div>
      <div class="prog-marker s-4 invBar">
        Decision Made
      </div>
      <div class="prog-marker s-5 invBar">
        Finish
      </div>
      <mat-progress-bar
        class="mt progress-margin"
        [color]="colorInv"
        [mode]="modeInv"
        [value]="valueInv"
        [bufferValue]="bufferValueInv"
      >
      </mat-progress-bar>
    </section>
  </div>

  <div
    class="row clearfix"
    *ngIf="invitation['Product.Label.Marketing'] && invitation['Product.Label.Marketing'] != ''"
  >
    <h3 class="sm-h3 sr-title rm-p">Application Progress</h3>
    <h4 class="sr-subtitle">
      <span class="part-name">{{ Step }}:</span>
      <span class="part-name">{{ progress }}</span>
      <span class="status-box list-label" *ngIf="invitation['Voided'] == '1'" [style.backgroundColor]="'#EF4727'">
        Deleted
      </span>
    </h4>
    <div class="abs-right"></div>
    <section class="progress-section rel">
      <div class="prog-marker s-1 appBar">Commenced</div>
      <div class="prog-marker s-2 appBar">
        Submitted
      </div>
      <div class="prog-marker s-3 appBar">
        Credit Approval
      </div>
      <div class="prog-marker s-4 appBar">
        Finalised
      </div>
      <div class="prog-marker s-5 appBar">
        Contract Activated
      </div>
      <div class="prog-marker s-6 appBar">
        <mat-icon class="fas fa-flag-checkered"></mat-icon>
        Finish
      </div>

      <!--  Dark blue = complete, Light blue = pending, dotted = not reached yet -->
      <mat-progress-bar
        class="progress-margin"
        [(color)]="color"
        [(value)]="value"
        [(bufferValue)]="bufferValue"
        [mode]="mode"
      >
      </mat-progress-bar>
    </section>
  </div>

  <div class="row clearfix mt">
    <app-invitation-conversion-funnel [invitation]="invitation" [eventLogs]="eventLogs" [dateNow]="dateNow">
    </app-invitation-conversion-funnel>
  </div>
</div>
