import { Component, EventEmitter, Inject, Input, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { MerchantService } from '../../merchant/shared/merchant.service';

import * as moment from 'moment';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../../../shared/components/root-component/root-component.component';
import { ConfirmDialog } from '../../../shared/types/confirm-dialog';

@Component({
  selector: 'app-discount-edit',
  templateUrl: './discount-edit.component.html',
  styleUrls: ['./discount-edit.component.css'],
})
export class DiscountEditComponent implements OnInit {
  @Input()
  treatmentAmount;

  @Input()
  cashDiscountPercent;

  @Input()
  cashDiscountExpiredDays;

  @Input()
  cashDiscountOffered;

  @Input()
  merchantID;

  @Input()
  minAmmount;

  cashDiscountAmount;
  applyAsDefault = false;
  isPromoterOrAdmin = false;
  isModal = true;
  closeModal = new EventEmitter();
  getResult = new EventEmitter();

  sessionType;
  merchant;
  cashDiscountDate;

  constructor(
    private authenticationService: AuthenticationService,
    private merchantService: MerchantService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.treatmentAmount) {
        this.treatmentAmount = data.treatmentAmount;
      } else if (data.treat_plan_cost) {
        this.treatmentAmount = data.treat_plan_cost;
      }
      if (data.merchantID) {
        this.merchantID = data.merchantID;
      }
      if (data.cashDiscountPercent) {
        this.cashDiscountPercent = data.cashDiscountPercent;
      }

      if (data.cashDiscountOffered != null) {
        this.cashDiscountOffered = data.cashDiscountOffered;
      }
      if (data.cashDiscountExpiredDays) {
        this.cashDiscountExpiredDays = data.cashDiscountExpiredDays;
      }
      this.isModal = true;
    }
  }

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((sessionType) => {
      if (sessionType) {
        this.sessionType = sessionType;
      }

      this.authenticationService.isPromoterOrAdmin().subscribe((res) => {
        this.isPromoterOrAdmin = res;

        if (this.isPromoterOrAdmin == true && this.merchantID) {
          this.merchantService.getMerchantDetails(this.merchantID, {}, this.sessionType).subscribe((res) => {
            if (res) {
              this.merchant = res;
              this.merchantID = this.merchant['ID'];
              this.setup();
            }
          });
        } else {
          this.authenticationService.getCurrentPractice().subscribe((res) => {
            if (res) {
              this.merchant = res;

              this.merchantID = this.merchant['ID'];
              this.setup();
            }
          });
        }
      });
    });
  }

  setup() {
    if (this.merchant && this.merchantID) {
      this.calculDiscount();
    }
  }

  resetDefault() {
    if (this.merchant && this.merchantID) {
      if (this.merchant['CashDiscount.Expiry.Days']) {
        this.cashDiscountExpiredDays = Number(this.merchant['CashDiscount.Expiry.Days']);
      }
      if (this.merchant['CashDiscount.Percentage']) {
        this.cashDiscountPercent = Number(this.merchant['CashDiscount.Percentage']);
      }

      this.cashDiscountOffered = true;

      this.calculDiscount();
    }
  }

  calculDiscount() {
    if (this.cashDiscountExpiredDays) {
      this.cashDiscountDate = moment().add(this.cashDiscountExpiredDays, 'd');
    } else {
      this.cashDiscountDate = null;
    }

    if (this.treatmentAmount && this.cashDiscountPercent) {
      this.cashDiscountAmount = (this.treatmentAmount * this.cashDiscountPercent) / 100;
    }
  }

  save() {
    if (this.applyAsDefault == true) {
      const confirm = new ConfirmDialog(
        'fas fa-info',
        'Change default settings',
        'Are you sure you want to save this settings as Default for future invitations ?',
        'No',
        'Yes'
      );

      const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
        data: confirm,
      });
      ref.componentInstance.onConfirm.subscribe((confirmation) => {
        if (confirmation === false) {
          ref.close();
        } else {
          const payload = {
            cashDiscountPercent: this.cashDiscountPercent,
            cashDiscountExpiredDays: this.cashDiscountExpiredDays,
            cashDiscountOffered: this.cashDiscountOffered,
          };
          if (this.isPromoterOrAdmin == true && this.merchantID) {
            this.merchantService.editMerchant(this.merchantID, payload).subscribe((res) => {
              if (res && res.ID) {
                this.merchant = res;
                this.sendResult();
              }
            });
          } else {
            this.authenticationService.editCurrentPractice(payload).subscribe((res) => {
              if (res && res.ID) {
                this.merchant = res;
                this.sendResult();
              }
            });
          }

          ref.close();
        }
      });
    } else {
      this.sendResult();
    }
  }

  sendResult() {
    const result = {
      cashDiscountPercent: this.cashDiscountPercent,
      cashDiscountExpiredDays: this.cashDiscountExpiredDays,
      cashDiscountOffered: this.cashDiscountOffered,
      merchant: this.merchant,
    };

    this.getResult.emit(result);
  }

  closeEvent() {
    this.closeModal.emit(true);
  }
}
