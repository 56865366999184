import { Component, Inject, Input, TemplateRef } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'environments/environment';
import { Merchant } from '../../../../../../feature/merchant/shared/types/merchant.type';
import { Settings } from '../../../../../types/settings';
import { DataTablePrintModalConfig } from '../../types/data-table.type';

@Component({
  selector: 'ipv-data-table-print-modal',
  templateUrl: './data-table-print-modal.component.html',
  styleUrls: ['./data-table-print-modal.component.css'],
})
export class DataTablePrintModalComponent {
  @Input() title = 'Print Preview';
  @Input() printTitle = '';
  @Input() table: any[] = [];
  @Input() hide: string[] = [];
  @Input() order: string[] = [];
  @Input() sortable: string[] = [];
  @Input() rename: { [key: string]: string } = {};
  @Input() customComponents: { [key: string]: TemplateRef<any> } = {};
  @Input() isFixedFirstRow = true;
  @Input() search: string;
  @Input() searchExceptions: string[];
  @Input() paginate = true;
  @Input() pageSize = Settings.global['listPageSize'] || 20;
  @Input() menuTemplate: TemplateRef<any>;
  @Input() searchAreaTemplate = '';
  @Input() formatHeaders: { [key: string]: 'left' | 'right' | 'center' } = {};
  @Input() practiceOrMerchant: Partial<Merchant> | Merchant;
  @Input() sections: { value: any; color: string; fontColor: string }[] = [];
  @Input() sectionColumn: string = null;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: DataTablePrintModalConfig
  ) {
    if (this.data) {
      this.title = data.title || '';
      this.printTitle = data.printTitle || '';
      this.table = data.table || [];
      this.hide = data.hide || [];
      this.order = data.order || [];
      this.sortable = data.sortable || [];
      this.rename = data.rename || {};
      this.customComponents = data.customComponents || {};
      this.isFixedFirstRow = data.isFixedFirstRow || true;
      this.search = data.search;
      this.searchExceptions = data.searchExceptions || [];
      this.paginate = data.paginate || true;
      this.pageSize = data.pageSize || 20;
      this.menuTemplate = data.menuTemplate;
      this.searchAreaTemplate = data.searchAreaTemplate || '';
      this.formatHeaders = data.formatHeaders || {};
      this.practiceOrMerchant = data.practiceOrMerchant || null;
      this.sections = data.sections || [];
      this.sectionColumn = data.sectionColumn || null;
    }
  }

  getFileLink(fileID) {
    return environment.nodeUrl + '/files/file-view/' + fileID;
  }
}
