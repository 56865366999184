<div class="teethShap" [ngClass]="{ selected: tooth.selected }" (click)="selectTooth()" val="48">
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150.58 247">
    <defs>
      <style>
        .cls-1 {
          fill: #fff;
        }
      </style>
    </defs>
    <title>teeth</title>
    <path *ngFor="let t of toothShape" [ngClass]="t.class" [attr.d]="t.d" />
  </svg>
  <p *ngIf="!tooth.hideNumber" style="text-align: center">{{ tooth.toothNumber }}</p>
</div>
