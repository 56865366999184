<div class="negative-corner bg-light-gray">
  <div id="blueBg"></div>

  <h3 class="white-header annotation">Step 3</h3>

  <section class="introduction">
    <div class="container">
      <div class="row clearfix introduction-hero">
        <div class="text pull-right half-width">
          <div class="col-left">
            <h1 class="white-header">Getting Paid</h1>
            <p class="white lead">Provided {{ "KEYWORD.treatment" | translate }}? Now it’s time to get paid by putting
              in a settlement request.</p>
          </div>
        </div>
        <div class="text pull-left half-width">
          <img class="getting-paid-image"
            src="https://s3-ap-southeast-2.amazonaws.com/application.assets.resources/main/images/gettingstarted-img9.png"
               mat-card-image/>
        </div>
      </div>
    </div>
  </section>

  <section class="top-section">
    <div class="container bg-white topIntro offset-content">
      <h2 class="heading-text">Settlement Request</h2>
      <p class="paragraph-default">
        Once {{ "KEYWORD.treatment" | translate }} has been provided, you can request a settlement for
        the {{ 'KEYWORD.procedure' | translate }} amount and what you have
        invoiced your {{ "KEYWORD.patient" | translate }}. There is no limit to the number of requests you can make.
      </p>
      <p class="paragraph-default">
        To process a settlement request, you will need a few things from your end and the
        {{ "KEYWORD.patient" | translate }}. We have broken down each step for you to follow with this helpful
        checklist:
      </p>
    </div>
    <div class="container">
      <div class="basic-column centered-column offset-image">
        <div class="article-section">
          <span class="article-head">Articles</span>
          <ul class="check">
            <li>
              <div class="text">
                <p class="getting-started-page">
                  <strong>New Settlement Request</strong> - In your blue menu bar go to settlements > new settlement
                  request. You can also press the blue "piggybank" at the top of every page. Search your
                  {{ "KEYWORD.patient" | translate }} and select their {{ 'PHRASE.treatment plan' | translate }}.
                </p>
              </div>
              <a class="article-links" target="_blank"
                 href="https://app.smileright.com.au/wiki/(wikiPageRoute:view-code/requesting-settlements">
                <mat-icon>description</mat-icon>
              </a>
            </li>
            <li>
              <div class="text">
                <p class="getting-started-page">
                  <strong>Settlement Amount</strong> - {{"PHRASES.Core Getting Started Settlement Amount" | translate}}
                </p>
              </div>
              <a class="article-links" target="_blank"
                 href="https://app.smileright.com.au/wiki/(wikiPageRoute:view-code/requesting-settlements">
                <mat-icon>description</mat-icon>
              </a>
            </li>
            <li>
              <div class="text">
                <p class="getting-started-page">
                  <strong>Authorise Settlement</strong> - Once happy with your request, confirm settlement with your
                  {{ "KEYWORD.patient" | translate }}. We will send an SMS with an authorisation code. You need this
                  code to continue with the settlement request. When you have the code, your
                  {{ "KEYWORD.patient" | translate }} is good to go.
                </p>
              </div>
              <a class="article-links" target="_blank"
                 href="https://app.smileright.com.au/wiki/(wikiPageRoute:view-code/requesting-settlements">
                <mat-icon>description</mat-icon>
              </a>
            </li>
            <li>
              <div class="text">
                <p class="getting-started-page">
                  <strong>Finalise Settlement</strong> - Upload a copy of your {{"KEYWORD.treatment" | translate}}
                  invoice and submit for
                  processing.
                </p>
              </div>
              <a class="article-links" target="_blank"
                 href="https://app.smileright.com.au/wiki/(wikiPageRoute:view-code/requesting-settlements">
                <mat-icon>description</mat-icon>
              </a>
            </li>
            <li>
              <div class="text">
                <p class="getting-started-page">
                  <strong>Tracking Payments</strong> - View your request status by going to your blue menu bar and
                  pressing settlements > settlements. You can see your "approved"
                  {{ "KEYWORD.patient" | translate }}s and the maximum value you can claim. Click on the
                  {{ "KEYWORD.patient" | translate }} to see a detailed view of all claim and payment made.
                </p>
              </div>
              <a class="article-links" target="_blank"
                 href="https://app.smileright.com.au/wiki/(wikiPageRoute:view-code/requesting-settlements">
                <mat-icon>description</mat-icon>
              </a>
            </li>
          </ul>
        </div>
        <div class="footer-links">
          <button class="btn-large mt btn-green"
                  [routerLink]="['/merchant', { outlets: { page: ['sending-treatments-plans'] } }]" mat-raised-button
                  color="primary">
            <mat-icon fontSet="fas" fontIcon="fa-angle-left"></mat-icon>
            Step Two
          </button>
        </div>
      </div>
    </div>
  </section>
</div>
