<div class="row clearfix defineMessage">
  <form #msgForm="ngForm">
    <!-- blue heading -->
    <div class="card-header primary-gradient-img clearfix">
      <div class="row clearfix">
        <!-- Modal dismiss button -->
        <button class="btn-close btn-clear pull-right mat-button" (click)="closeEvent()">
          <mat-icon>clear</mat-icon>
        </button>
        <!-- merchant calculated name -->
        <div class="row titleArea clearfix">
          <mat-icon class="fas fa-tag pull-left"></mat-icon>
          <h2 class="message-title summary-header rm-m">
            <span *ngIf="isRemoved != true">Create {{nature}}s</span>
            <span *ngIf="isRemoved == true">Remove {{nature}}s</span>
            <span class="subLabel">Define {{nature}}s for {{ "KEYWORD.patient" | translate }}</span>
          </h2>
        </div>
      </div>
    </div>
    <!-- end blue heading -->

    <div class="create-well">
      <!--  mat dialog scrolling area -->
      <mat-dialog-content>
        <div class="clearfix full-width mt">
          <app-tag-select [isModal]="false" [merchantID]="merchantID" [openPosition]="'above'" [title]="subTitle"
            [canRemove]="true" [canCreate]="!isRemoved" [directRemoveDefault]="true" [directRemove]="true"
            [hasFilter]="true" [canSelect]="false" (getSelectedTags)="getSelectedTags($event)"
            selectTitle="Add one or Multiple {{nature}}s">
          </app-tag-select>
        </div>
      </mat-dialog-content>
      <!-- fixed action buttons -->
      <mat-dialog-actions>
        <div class="row clearfix text-center ">
          <button [disabled]="!selectedTags || (selectedTags && selectedTags.length <= 0)" (click)="createTag()"
            mat-raised-button color="accent">
            Save changes
            <mat-icon>check</mat-icon>
          </button>
        </div>
      </mat-dialog-actions>
    </div>
  </form>
</div>
