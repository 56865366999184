<app-treatment-create
  [disableTreatmentValue]="disableTreatmentValue"
  [patientID]="patientID"
  [selectedTab]="selectedTab"
  [merchantID]="merchantID"
  [forceRefreshTotalCost]="ForceRefreshTotalCost"
  [invitationID]="invitationID"
  [refreshTreatment]="refreshTreatment"
  [treatmentDocumentDescription]="treatmentDocumentDescription"
  [treatmentDocumentLabel]="treatmentDocumentLabel"
  [totalTreatmentValue]="totalTreatmentValue"
  [informedConsent]="informedConsent"
  [canEditTotalValue]="canEditTotalValue"
  [invitationTemplateID]="invitationTemplateID"
  [invitationTemplateLabel]="invitationTemplateLabel"
  [simpleTreatmentDocuments]="simpleTreatmentDocuments"
  [EPTreatmentDocuments]="EPTreatmentDocuments"
  [stockArtDocuments]="stockArtDocuments"
  [EPstockArtDocuments]="EPstockArtDocuments"
  (refreshTotalCost)="refreshTotalCostEvent($event)"
  (templateSelectAll)="templateSelectAllEvent($event)"
  (validate)="onValidate($event)"
  (informedConsentUpdated)="setInformedConsent($event)"
  (clearTemplate)="clearTemplateEvent($event)"
  (create)="createTreatment($event)"
  (simpleTreatmentDocumentsUpdated)="setSimpleTreatmentDocuments($event)"
  (EPTreatmentDocumentsUpdated)="setEpTreatmentDocuments($event)"
  (stockArtDocumentsUpdated)="setStockArtDocuments($event)"
  (EPstockArtDocumentsUpdated)="setEPStockartDocuments($event)"
>
</app-treatment-create>
