/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./no-merchant-list-message.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./no-merchant-list-message.component";
var styles_NoMerchantListMessageComponent = [i0.styles];
var RenderType_NoMerchantListMessageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NoMerchantListMessageComponent, data: {} });
export { RenderType_NoMerchantListMessageComponent as RenderType_NoMerchantListMessageComponent };
export function View_NoMerchantListMessageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "row clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "animated fadeInDown pleaseChoose"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["No merchant selected messages"])), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [["class", "character_warning"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" Choose your desired merchant from the drop-down above to view their ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dataName; _ck(_v, 7, 0, currVal_0); }); }
export function View_NoMerchantListMessageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ipv-no-merchant-list-message", [], null, null, null, View_NoMerchantListMessageComponent_0, RenderType_NoMerchantListMessageComponent)), i1.ɵdid(1, 114688, null, 0, i2.NoMerchantListMessageComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NoMerchantListMessageComponentNgFactory = i1.ɵccf("ipv-no-merchant-list-message", i2.NoMerchantListMessageComponent, View_NoMerchantListMessageComponent_Host_0, { dataName: "dataName" }, {}, []);
export { NoMerchantListMessageComponentNgFactory as NoMerchantListMessageComponentNgFactory };
