<div class="rel" *ngIf="listDB && listDB?.firstLoadEvent == false" @ngIfAnimation>
  <div class="row clearfix padded rel">
    <h1 class="dataHeader rm-mt transparent">Fetching data...</h1>
  </div>
</div>

<div [hidden]="!listDB || (listDB && !listDB.data) || (listDB && listDB.data && listDB.data.length == 0)">
  <div class="row clearfix">
    <h1 class="dataHeader rm-m pull-left">
      {{ title }}
      <span class="badge" [endVal]="dataSource?.filteredData?.length" countUp></span>
    </h1>

    <div class="pull-right rel buttonrow">
      <button class="clearfix pull-right" (click)="createNew()" mat-raised-button color="accent">
        <mat-icon>add</mat-icon>
        Create new Template
      </button>
    </div>

    <div class="pull-right selection-items" @ngIfAnimation>
      <button
        class="animated mr"
        (click)="selectAll()"
        mat-stroked-button
        color=""
        matTooltip="Select All"
      >
        <mat-icon>border_all</mat-icon>
      </button>
      <button
        class="animated mr"
        (click)="selectPage()"
        mat-stroked-button
        color=""
        matTooltip="Select Page"
      >
        <mat-icon>border_outer</mat-icon>
      </button>

      <button
        class="animated mr"
        *ngIf="selectedIDs && selectedIDs.length > 0"
        [matMenuTriggerFor]="ActionBtnmenu"
        mat-stroked-button
        matTooltip="Options"
      >
        <mat-icon class="fas fa-cog"></mat-icon>  <span *ngIf="selectedIDs && selectedIDs.length>0"
          class="selected-number-item  accent-background">{{selectedIDs.length}}</span>
      </button>
      <mat-menu #ActionBtnmenu="matMenu">
        <button class="row select-btn" (click)="unselect()" mat-menu-item color="">
          <mat-icon>select_all</mat-icon> Deselect
        </button>

        <hr/>

        <button
          class="row select-btn"
          *ngIf="selectedIDs && selectedIDs.length > 0"
          (click)="enableGroup()"
          mat-menu-item
          color=""
        >
          <mat-icon>check_circle</mat-icon>
          Enable
        </button>

        <button
          class="row select-btn"
          *ngIf="selectedIDs && selectedIDs.length > 0"
          (click)="disableGroup()"
          mat-menu-item
          color=""
        >
          <mat-icon>cancel</mat-icon>
          Disable
        </button>

        <button
          class="row select-btn"
          *ngIf="selectedIDs && selectedIDs.length > 0"
          (click)="removeGroup()"
          mat-menu-item
          color=""
        >
          <mat-icon>delete</mat-icon>
          Remove
        </button>
      </mat-menu>
    </div>
  </div>

  <div class="row clearfix">
    <mat-card class="list-card">
      <div class="row clearfix mb searchArea primary-gradient-img">
        <div class="row clearfix flex">
          <div class="full-width flex animated fadeInDown">
            <mat-icon class="mt mr">search</mat-icon>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Filter by title,CTA, post, supplier, Tags...etc</mat-label>
              <input class="clearfix" (input)="setFilter($event.target.value, 'fullName')" name="tag" matInput/>
            </mat-form-field>

            <mat-form-field class="half-width" appearance="outline">
              <mat-label>Type</mat-label>

              <mat-select
                class="margin-btm"
                (selectionChange)="setFilter($event.value, 'SocialMediaType.Code')"
                name="Type"
                placeholder="Type"
              >
                <mat-option [value]="null">
                  All
                </mat-option>

                <mat-option [value]="'Facebook'">
                  <mat-icon class="fab fa-facebook" [style.color]="getColorFromSocial('Facebook')"></mat-icon>
                  <span [style.color]="getColorFromSocial('Facebook')">Facebook</span>
                </mat-option>
                <mat-option [value]="'LinkedIn'">
                  <mat-icon class="fab fa-linkedin" [style.color]="getColorFromSocial('LinkedIn')"></mat-icon>
                  <span [style.color]="getColorFromSocial('LinkedIn')">LinkedIn</span>
                </mat-option>
                <mat-option [value]="'Twitter'">
                  <mat-icon class="fab fa-twitter" [style.color]="getColorFromSocial('Twitter')"></mat-icon>
                  <span [style.color]="getColorFromSocial('Twitter')">Twitter</span>
                </mat-option>
                <mat-option [value]="'Instagram'">
                  <mat-icon class="fab fa-instagram" [style.color]="getColorFromSocial('Instagram')"></mat-icon>
                  <span [style.color]="getColorFromSocial('Instagram')">Instagram</span>
                </mat-option>

                <mat-option [value]="'Pinterest'">
                  <mat-icon class="fab fa-pinterest" [style.color]="getColorFromSocial('Pinterest')"></mat-icon>
                  <span [style.color]="getColorFromSocial('Pinterest')">Pinterest</span>
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="white half-width" *ngIf="merchantTags.length > 0" appearance="outline">
              <mat-label>Tags</mat-label>
              <mat-select
                class=""
                (selectionChange)="setFilter($event.value, 'Tags')"
                name="Tag"
                placeholder="Tag"
                multiple
              >
                <mat-option *ngFor="let a of merchantTags | orderBy" [value]="a">
                  {{ a }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="half-width" appearance="outline">
              <mat-label>Active</mat-label>

              <mat-select
                class="margin-btm"
                (selectionChange)="setFilter($event.value, 'isActiveReal')"
                name="IsDefault"
                placeholder="Active"
              >
                <mat-option [value]="null">
                  All
                </mat-option>
                <mat-option [value]="'1'"> Active</mat-option>

                <mat-option [value]="'1'"> Inactive</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>

      <!-- Table -->
      <mat-table class="list-table" #table [dataSource]="dataSource" matSort>
        <ng-container cdkColumnDef="selectCheckBox" sticky>
          <mat-header-cell *cdkHeaderCellDef></mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <div class="margin-btm pull-left">
              <mat-checkbox
                [checked]="isSelected(row['ID'])"
                (change)="select(row['ID'], $event.checked)"
                color="accent"
              >
              </mat-checkbox>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Picture" sticky>
          <mat-header-cell *cdkHeaderCellDef mat-sort-header>Picture</mat-header-cell>
          <mat-cell class="text-center" *cdkCellDef="let row" (click)="viewTemplate(row['ID'])">
            <app-social-media-thumbnail-view
              class="supplierIcon2-thumbnail prod text-center"
              *ngIf="row"
              [hideZoomButton]="true"
              [socialMediaID]="row['ID']"
              [LastModified]="row['LastModified']"
            >
            </app-social-media-thumbnail-view>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Label" sticky>
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Label</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="viewTemplate(row['ID'])" matTooltip="{{ row['Label'] }}">
            <h3>{{ row['Label'] }}</h3>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Type">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Type</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="viewTemplate(row['ID'])" matTooltip="{{ row['Purpose_Label'] }}">
            <div *ngIf="row['SocialMediaType.Code'] == 'Pinterest'">
              <mat-icon class="fab fa-pinterest" [style.color]="'#c8232c'"></mat-icon>
              <span class="ml" [style.color]="'#c8232c'"> Pinterest</span>
            </div>

            <div *ngIf="row['SocialMediaType.Code'] == 'Facebook'">
              <mat-icon class="fab fa-facebook" [style.color]="'#4267B2'"></mat-icon>
              <span class="ml" [style.color]="'#4267B2'"> Facebook</span>
            </div>

            <div *ngIf="row['SocialMediaType.Code'] == 'LinkedIn'">
              <mat-icon class="fab fa-linkedin" [style.color]="'#2867B2'"></mat-icon>
              <span class="ml" [style.color]="'#2867B2'"> LinkedIn</span>
            </div>

            <div *ngIf="row['SocialMediaType.Code'] == 'Twitter'">
              <mat-icon class="fab fa-twitter" [style.color]="'#1DA1F2'"></mat-icon>
              <span class="ml" [style.color]="'#1DA1F2'"> Twitter</span>
            </div>

            <div *ngIf="row['SocialMediaType.Code'] == 'Instagram'">
              <mat-icon class="fab fa-instagram" [style.color]="'#E1306C'"></mat-icon>
              <span class="ml" [style.color]="'#E1306C'">Instagram</span>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="CTA">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Call To Action</mat-header-cell>
          <mat-cell
            *cdkCellDef="let row"
            (click)="viewTemplate(row['ID'])"
            matTooltip="{{ convertMessage(row['ActionCall.Label']) }}"
          >
            {{ convertMessage(row['ActionCall.Label']) }}
          </mat-cell>
        </ng-container>
        <ng-container cdkColumnDef="Tags">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Tags</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="viewTemplate(row['ID'])">
            <div *ngIf="row && row['Tags']">
              <mat-chip-list>
                <mat-chip *ngFor="let tag of row['Tags'] | orderBy" [ngClass]="{ 'default-chip': indexOfTag(tag) }">
                  {{ tag }}
                </mat-chip>
              </mat-chip-list>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Date">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Date Created</mat-header-cell>
          <mat-cell
            *cdkCellDef="let row"
            (click)="viewTemplate(row['ID'])"
            matTooltip="{{ row['DateTimeCreated'] | customDateTime }}"
          >
            <span class="list-label" [style.background]="'rgb(90, 186, 71)'">
              {{ row['DateTimeCreated'] | TimeLabelPast }} ago
            </span>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="active">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Active</mat-header-cell>
          <mat-cell *cdkCellDef="let row" (click)="openQuickViewDialog(row['ID'])">
            <span
              class="list-label smaller"
              *ngIf="row['Active'] == '1'"
              matTooltip="Active"
              style="background-color: rgb(90, 186, 71)"
            >
              <mat-icon>check</mat-icon>
            </span>
            <span
              class="list-label smaller"
              *ngIf="row['Active'] != '1'"
              matTooltip="Inactive"
              style="background-color: rgb(239, 71, 39)"
            >
              <mat-icon>close</mat-icon>
            </span>
          </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="Actions">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header> Actions</mat-header-cell>
          <mat-cell *cdkCellDef="let row">
            <div style="float: right">
              <button [matMenuTriggerFor]="menu" mat-icon-button>
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button (click)="viewTemplate(row['ID'])" mat-menu-item>
                  <mat-icon>description</mat-icon>
                  <span>View Template</span>
                </button>

                <button (click)="EditSocialMedia(row['ID'])" mat-menu-item>
                  <mat-icon>edit</mat-icon>
                  <span>Edit Template</span>
                </button>

                <hr/>

                <button *ngIf="row['Active'] == '1'" (click)="disable(row['ID'])" mat-menu-item>
                  <mat-icon>cancel</mat-icon>
                  <span>Disable</span>
                </button>

                <button *ngIf="row['Active'] != '1'" (click)="enable(row['ID'])" mat-menu-item>
                  <mat-icon>check_circle</mat-icon>
                  <span>Enable</span>
                </button>

                <hr/>
                <button (click)="removed(row['ID'])" mat-menu-item>
                  <mat-icon>remove_circle</mat-icon>
                  <span>{{ 'Remove Template' | translate }}</span>
                </button>
              </mat-menu>
            </div>
          </mat-cell>
        </ng-container>
        <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *cdkRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
      <mat-paginator
        #paginator
        [length]="dataSource?.filteredData?.length"
        [pageIndex]="0"
        [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions"
      >
      </mat-paginator>
    </mat-card>
  </div>
</div>
<div *ngIf="listDB?.data?.length == 0 && listDB?.firstLoadEvent != false">
  <div class="pull-right rel buttonrow">
    <button class="clearfix pull-right" (click)="createNew()" mat-raised-button color="primary">
      <mat-icon>add</mat-icon>
      Create new Template
    </button>
  </div>

  <app-empty-list-message></app-empty-list-message>
</div>
