<mat-dialog-content class="reduce">
  <div class="card-header primary-gradient-img clearfix inModal noShrink">
    <div class="row clearfix">
      <!-- Modal dismiss button -->
      <button class="btn-close btn-clear mat-button white" (click)="close()">
        <mat-icon>clear</mat-icon>
      </button>
      <div class="row titleArea clearfix">
        <h2 class="summary-header rm-m">Your Health Information and Our Privacy Policy</h2>
      </div>
    </div>
  </div>

  <div class="row clearfix">
    <p>
      <strong>In accordance with the Australian Privacy Principles contained in the Commonwealth Privacy Act 1988
        (Privacy
        Act) and applicable State legislation.</strong>
    </p>
    <p>
      {{ "BRANDING.Brand Name" | translate | titlecase }}
      <ng-container *ngIf="merchant">and {{ merchant['TradingAs'] }}</ng-container>
      respects your right to privacy and thus has systems and processes in place to ensure it complies with the
      Australian Privacy Principles. This statement is a summary of the {{"KEYWORD.practice" | translate}}'s privacy
      policy. The complete policy
      is available upon request.
    </p>

    <p>
      {{ "BRANDING.Brand Name" | translate | titlecase }}
      <ng-container *ngIf="merchant">and {{ merchant['TradingAs'] }}</ng-container>
      collects information about you to provide health services to you. Personal information such as your name, address
      and health insurance details are used to address accounts and send relevant correspondence, as well as processing
      payments and writing to you about our services and any issues affecting your health care.
    </p>

    <p>
      {{ "BRANDING.Brand Name" | translate | titlecase }}
      <ng-container *ngIf="merchant">and {{ merchant['TradingAs'] }}</ng-container>
      may disclose your health information to other health care professionals or third parties, or require it from them
      if, in our judgement, it is necessary in the context of your care. Your health information may also be used for
      research purposes, in study groups or at seminars; however, in such situations, your personal identity will not be
      disclosed without your consent.
    </p>

    <p>
      You may choose not to provide {{ "BRANDING.Brand Name" | translate | titlecase }}
      <ng-container *ngIf="merchant">and {{ merchant['TradingAs'] }}</ng-container>
      with information relevant to your care. In this instance [{{ "KEYWORD.practice" | translate | uppercase }} NAME]
      may not be
      able to provide a service to you, or the service we are asked to provide may not be appropriate for your needs.
      Importantly, if you do not provide information that may be relevant to your care or that is otherwise requested by
      {{ "BRANDING.Brand Name" | translate | titlecase }}
      <ng-container *ngIf="merchant">and {{ merchant['TradingAs'] }}</ng-container>
      , you could suffer some harm or other adverse outcomes.
    </p>

    <p>
      Your health history, treatment records, x-rays and any other material relevant to your care will be stored by
      {{ "BRANDING.Brand Name" | translate | titlecase }}
      <ng-container *ngIf="merchant">and {{ merchant['TradingAs'] }}</ng-container>
      . The privacy policy sets out how you can access your records or seek correction of your records. It also
      specifies how you can report suspected privacy breaches and how {{ "BRANDING.Brand Name" | translate | titlecase
      }} and
      <ng-container *ngIf="merchant">{{ merchant['TradingAs'] }}</ng-container>
      will deal with such a situation.
    </p>

    <p>
      As part of its electronic records system, {{ "BRANDING.Brand Name" | translate | titlecase }}
      <ng-container *ngIf="merchant">and {{ merchant['TradingAs'] }}</ng-container>
      may rely on cloud storage providers located outside Australia. {{ "BRANDING.Brand Name" | translate | titlecase }}
      <ng-container *ngIf="merchant">and {{ merchant['TradingAs'] }}</ng-container>
      will comply with its obligations under Australian privacy laws in relation to all offshore storage situations.
    </p>

    <p *ngIf="merchant">
      The {{ "BRANDING.Brand Name" | translate | titlecase }}
      <ng-container *ngIf="merchant">and {{ merchant['TradingAs'] }}</ng-container>
      Business Manager can be contacted at the {{"KEYWORD.practice" | translate}} during business hours
      on {{ merchant['emails.Email'] }} or
      emailed at {{ merchant['phones.Number'] }} if you have any concerns or questions about a privacy matter.
    </p>

    <hr/>
    <p>
      A full copy of {{ "BRANDING.Brand Name" | translate | titlecase }}'s Privacy Policy can be read by
      <strong><a class="" href="https://smileright.com.au/consumer/(page:privacy)" target="_blank"
                 title="Terms and Conditions">clicking here.</a></strong>
    </p>
  </div>
</mat-dialog-content>
