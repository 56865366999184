import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationService } from '../../../core/authentication/shared/authentication.service';
import { UtilsService } from '../../services/utils.service';
import { ConfirmDialog } from '../../types/confirm-dialog';
import { NotifyAppComponent } from '../../types/notify-app-component';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { RootAppComponent } from '../root-component/root-component.component';

@Component({
  selector: 'app-address-label-input.component',
  templateUrl: './address-label-input.component.html',
  styleUrls: ['./address-label-input.component.css'],
})
export class AddressLabelInputComponent implements OnInit {
  @Input()
  ownerID;

  @Input()
  label;

  @Input()
  isEdit = false;

  @Output()
  getResult = new EventEmitter();

  @Output()
  close = new EventEmitter();

  isModal = false;

  @Input()
  mySuburb = null;

  @Input()
  myState = null;

  @Input()
  myPostCode = null;

  @Input()
  myStreetNumber = null;

  @Input()
  myStreetName = null;

  @Input()
  myUnitNumber = null;

  @Input()
  myStreetType = null;

  @Input()
  isPrimary = false;

  @Input()
  addressTypes;

  clearAddress = new EventEmitter();

  validateAddress = false;
  myMobile = null;
  myPhoneNumber = null;
  myEmail = null;
  validateMobile = false;
  validatePhone = false;
  validateEmail = false;

  defaultLabel = ['Physical', 'Postal'];

  isDefaultLabel = false;

  isRequired = true;
  adr: any = {};
  @Input()
  type = 'current';
  sessionType;

  constructor(
    private utilService: UtilsService,
    private authenticationService: AuthenticationService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    if (data) {
      if (data.ownerID) {
        this.ownerID = data.ownerID;
      }

      if (data.isEdit) {
        this.isEdit = data.isEdit;
      }
      if (data.type) {
        this.type = data.type;
      }
      if (data.label) {
        this.label = data.label;
      }

      if (data.unit) {
        this.myUnitNumber = data.unit;
      }

      if (data.streetNumber) {
        this.myStreetNumber = data.streetNumber;
      }

      if (data.streetName) {
        this.myStreetName = data.streetName;
      }

      if (data.streetType) {
        this.myStreetType = data.streetType;
      }

      if (data.suburb) {
        this.mySuburb = data.suburb;
      }

      if (data.state) {
        this.myState = data.state;
      }

      if (data.postCode) {
        this.myPostCode = data.postCode;
      }

      if (data.isPrimary) {
        this.isPrimary = data.isPrimary;
      }

      if (data.addressTypes) {
        this.addressTypes = data.addressTypes;
      }

      this.isModal = true;
    }
  }

  ngOnInit() {
    this.authenticationService.getSessionType().subscribe((st) => {
      this.sessionType = st;
      if (this.label) {
        if (this.defaultLabel.indexOf(this.label) != -1) {
          this.isDefaultLabel = true;
        } else {
          this.isDefaultLabel = false;
        }
      }

      if (this.isEdit == true) {
        this.isRequired = false;
      }
    });
  }

  closeModal() {
    this.close.emit(true);
  }

  // getAdress(adr) {
  //   if (adr) {
  //     console.log(adr)
  //     this.mySuburb = adr.suburb;
  //     this.myState = adr.app-state;
  //     this.myPostCode = adr.postCode;
  //     this.myStreetNumber = adr.streetNumber;
  //     this.myStreetName = adr.streetName;
  //     this.myUnitNumber = adr.unitNumber;
  //     this.myStreetType = adr.streetType;
  //   }
  // }

  isAdressValidEvent(e) {
    this.validateAddress = e;
  }

  create() {
    if (this.adr) {
      const payload = {
        ownerID: this.ownerID,
        label: this.label,
        unitNumber: this.adr.unitNumber,
        streetNumber: this.adr.streetNumber,
        streetName: this.adr.streetName,
        streetType: this.adr.streetType,
        suburb: this.adr.suburb,
        state: this.adr.state,
        postCode: this.adr.postCode,
        latitude: this.adr.latitude,
        longitude: this.adr.longitude,
        isPrimary: this.isPrimary,
      };

      this.utilService.createAddressByLabel(payload, this.type, this.sessionType).subscribe((res) => {
        if (res) {
          NotifyAppComponent.displayToast(
            'success',
            'Create new Address',
            'You have successfully created a new address with label ' + res.Label
          );
          this.getResult.emit(res);
          this.addressTypes.push(this.label);
          this.close.emit(true);
        }
      });
    }
  }

  modify() {
    if (this.adr) {
      const payload = {
        unitNumber: this.adr.unitNumber || 'void',
        streetNumber: this.adr.streetNumber || 'void',
        streetName: this.adr.streetName || 'void',
        streetType: this.adr.streetType || 'void',
        suburb: this.adr.suburb || 'void',
        state: this.adr.state || 'void',
        postCode: this.adr.postCode || 'void',
        latitude: this.adr.latitude || 'void',
        longitude: this.adr.longitude || 'void',
        isPrimary: this.isPrimary || false,
      };

      this.utilService
        .modifyAddressByLabel(this.ownerID, this.label, payload, this.type, this.sessionType)
        .subscribe((res) => {
          if (res) {
            NotifyAppComponent.displayToast('success', 'Update Address', 'You have successfully updating the address');
            this.getResult.emit(res);
          }
        });
    }
  }

  getAddressByType(e) {
    if (e) {
      if (e) {
        if (this.defaultLabel.indexOf(e) != -1) {
          this.isDefaultLabel = true;
        } else {
          this.isDefaultLabel = false;
        }
      }

      this.utilService.getAddressByLabel(this.ownerID, e, this.type, this.sessionType).subscribe((res) => {
        if (res) {
          this.myUnitNumber = res['Unit'];
          this.myStreetNumber = res['Street Nr'];
          this.myStreetName = res['Street Name'];
          this.myStreetType = res['Street Type'];
          this.mySuburb = res['Suburb'];
          this.myState = res['State'];
          this.myPostCode = res['Postcode'];
          this.isPrimary = res['IsPrimary'] == '1' ? true : false;
        }
      });
    } else {
      this.isPrimary = null;
      this.isEdit = false;
      this.clearAddress.emit(true);
      this.isRequired = true;
    }
  }

  convertToLowerCase(val) {
    if (val) {
      return val.toLowerCase();
    }
  }

  removeAddressByLabel() {
    const confirmDialog = new ConfirmDialog(
      'warning',
      'Delete Address',
      'Are you sure to remove this address with label permanently?',
      'Cancel',
      'Proceed'
    );

    const ref = RootAppComponent.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
      width: '650px',
    });

    ref.componentInstance.onConfirm.subscribe((res) => {
      if (res == true) {
        this.utilService
          .deleteAddressByLabel(this.ownerID, this.label, this.type, this.sessionType)
          .subscribe((res) => {
            if (res) {
              NotifyAppComponent.displayToast(
                'success',
                'Delete Address',
                'You have successfully deleted this address'
              );
              this.addressTypes.splice(this.addressTypes.indexOf(this.label), 1);
              ref.close();
              this.close.emit(true);
            }
          });
      } else {
        ref.close();
      }
    });
  }

  clearAddressEvent() {
    this.clearAddress.emit(true);
  }

  getAdress(adr) {
    if (adr) {
      this.adr.suburb = adr.suburb;
      this.adr.state = adr.state;
      this.adr.postCode = adr.postCode;
      this.adr.streetNumber = adr.streetNumber;
      this.adr.streetName = adr.streetName;
      this.adr.unitNumber = adr.unitNumber;
      this.adr.streetType = adr.streetType;
      this.adr.latitude = adr.latitude;
      this.adr.longitude = adr.longitude;
    }
  }
}
