/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./admin-languages.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../settings/general-settings/general-settings.component.ngfactory";
import * as i3 from "../../../settings/general-settings/general-settings.component";
import * as i4 from "@angular/router";
import * as i5 from "@ngx-translate/core";
import * as i6 from "../../../authentication/shared/authentication.service";
import * as i7 from "../../../settings/shared/settings.service";
import * as i8 from "@angular/platform-browser";
import * as i9 from "@angular/material/dialog";
import * as i10 from "./admin-languages.component";
var styles_AdminLanguagesComponent = [i0.styles];
var RenderType_AdminLanguagesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AdminLanguagesComponent, data: {} });
export { RenderType_AdminLanguagesComponent as RenderType_AdminLanguagesComponent };
export function View_AdminLanguagesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-general-settings", [], null, null, null, i2.View_GeneralSettingsComponent_0, i2.RenderType_GeneralSettingsComponent)), i1.ɵdid(1, 114688, null, 0, i3.GeneralSettingsComponent, [i4.Router, i4.ActivatedRoute, i5.TranslateService, i6.AuthenticationService, i7.SettingsService, i8.DomSanitizer, i9.MatDialog], { features: [0, "features"] }, null), i1.ɵpad(2, 1)], function (_ck, _v) { var currVal_0 = _ck(_v, 2, 0, "languages"); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AdminLanguagesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-admin-languages", [], null, null, null, View_AdminLanguagesComponent_0, RenderType_AdminLanguagesComponent)), i1.ɵdid(1, 114688, null, 0, i10.AdminLanguagesComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AdminLanguagesComponentNgFactory = i1.ɵccf("app-admin-languages", i10.AdminLanguagesComponent, View_AdminLanguagesComponent_Host_0, {}, {}, []);
export { AdminLanguagesComponentNgFactory as AdminLanguagesComponentNgFactory };
