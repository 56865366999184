<div class="rel editDetails">
  <div class="float-close">
    <button class="btn-close btn-clear mat-button" matDialogClose>
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <mat-card class="card">
    <form #customerDetails="ngForm" *ngIf="customer">
      <mat-dialog-content>
        <h2 class="mat-h2 sr-title">Contact Details</h2>
        <div class="row clearfix">
          <div class="icons-col">
            <mat-icon>phone</mat-icon>
          </div>

          <div class="row clearfix">
            <mat-select class="full-width" name="phoneLabels" placeholder="Type of Contact Number">
              <mat-option *ngFor="let p of phoneLabels" [value]="p.name">
                {{ p.name }}
              </mat-option>
            </mat-select>
            <hr class="mb"/>
            <app-phone-input
              class="full-width"
              [label]="'Phone Number'"
              [required]="true"
              (validate)="validatePhoneEvent($event)"
            >
            </app-phone-input>
          </div>
          <hr class="mb"/>
          <div class="row clearfix">
            <div class="icons-col">
              <mat-icon>email</mat-icon>
            </div>
            <!-- <app-phone-input class="full-width" (validate)="validatePhoneEvent($event)" (getPhone)="getPhone($event)"
                        [phoneNumber]="customer['phones.Number']" [isLandLine]="true" [label]="'Phone Number'" [required]="true">
                      </app-phone-input> -->
            <mat-select class="full-width" name="emailLabels" placeholder="Type of Email Address">
              <mat-option *ngFor="let e of emailLabels" [value]="e.name">
                {{ e.name }}
              </mat-option>
            </mat-select>
            <hr class="mb"/>
            <!-- <app-email-input class="full-width" [email]="customer['emails.Email']" (getEmail)="getEmail($event)"
                        [label]="'Email'" [required]="true" (validate)="validateEmailEvent($event)">
                      </app-email-input> -->
            <app-email-input
              class="full-width"
              [label]="'Email'"
              [required]="true"
              (validate)="validateEmailEvent($event)"
            >
            </app-email-input>
          </div>
        </div>
      </mat-dialog-content>

      <div class="full-width text-center">
        <!-- <button mat-raised-button (click)="editDetail()" color="accent" [disabled]="!customerDetails.form.valid || isAdressValid==false || validateEmail==false || validatePhone==false">Save</button> -->
        <button (click)="editDetail()" mat-raised-button color="accent">Save</button>
      </div>
    </form>
  </mat-card>
</div>
