import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.css'],
})
export class RatingComponent implements OnInit {
  @Input() rating = 0;
  @Input() max = 5;
  @Input() color = 'goldenrod';
  @Input() fontSize = '2rem';

  constructor() {}

  ngOnInit() {}
}
