<!-- Modal dismiss button -->
<div class="rel merchFaqModal">
  <div class="card-header primary-gradient-img clearfix inModal">
    <div class="row clearfix">
      <!-- Modal dismiss button -->
      <button class="btn-close btn-clear mat-button white" matDialogClose>
        <mat-icon>clear</mat-icon>
      </button>
      <!-- merchant calculated name -->
      <div class="row titleArea clearfix">
        <h2 class="summary-header rm-m">Frequently asked questions</h2>
      </div>
    </div>
  </div>

  <div id="faq-tabs">
    <!-- <mat-tab-group id="faq-tabs" dynamicHeight="true" class="controlHeight"> -->

    <!-- <mat-tab label="merchant-faq1">
      <ng-template mat-tab-label><strong>Frequently Asked Questions</strong></ng-template> -->
    <mat-dialog-content>
      <mat-accordion class="faqAccordion">
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon class="fas fa-clock"></mat-icon> <strong>What are your business opening hours?</strong>
            </mat-panel-title>
            <mat-panel-description> &nbsp;</mat-panel-description>
          </mat-expansion-panel-header>
          <p>
            We are open <strong>{{ 'BRANDING.Operating Hours AWST' | translate }}</strong> <br/>
            ({{ 'BRANDING.Operating Hours EST' | translate }})
          </p>
          <p class="small">*non daylight savings time</p>
        </mat-expansion-panel>

        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon class="fas fa-phone"></mat-icon>
              <strong>Is there a contact number I can call for support?</strong>
            </mat-panel-title>
            <mat-panel-description> &nbsp;</mat-panel-description>
          </mat-expansion-panel-header>
          <p>If you have any queries please call our friendly support team on
            <strong>{{ 'BRANDING.Contact Phone' | translate }}</strong>
          </p>
        </mat-expansion-panel>

        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon class="fas fa-user"></mat-icon>
              <strong>Are my {{ "KEYWORD.patient" | translate }}s eligble?</strong>
            </mat-panel-title>
            <mat-panel-description> &nbsp;</mat-panel-description>
          </mat-expansion-panel-header>
          <h3>Eligibility:</h3>
          <ul>
            <li>Be at least 18 years old.</li>
            <li>Provide proof of identity.(Passport, License, Age Card, Credit Card, Bank Statement, Bill, etc.)</li>
            <li>Be an Australian citizen, permanent resident or have a valid visa*.</li>
          </ul>
          <p class="small">
            *
            <a
              href="https://s3-ap-southeast-2.amazonaws.com/email-graphics-smileright/docs-17/list-acceptable-visas.pdf"
              target="_blank" title="List of acceptable visas" angulartics2On="click"
              analytics-event="Download Visa Form">Valid&nbsp;visa (PDF 183kb)</a>&nbsp;(Opens in new window) To find
            out whether your Visa qualifies you please feel free call our Personal
            Lending Centre on {{ 'BRANDING.Contact Phone' | translate }} to discuss.
          </p>
          <h3>What information do I need to provide?</h3>
          <ul>
            <li>Details of your current financial situation.(Statement)</li>
            <li>Proof of employment (Current payslip).</li>
            <li>Proof of Identity (If we are unable to identify you electronically).</li>
          </ul>
          <p>
            We may use the contact information that you give us to contact you to help you complete your application or
            if we need to discuss your application with you.
          </p>
          <h3>What happens when I apply?</h3>
          <p>
            When you have finished completing the application we will give you feedback on your application, typically
            in 60 seconds. Once conditionally approved your contract is presented online for your review and electronic
            acceptance. <br/>
            After we have completed our loan assessment and your loan is approved, a copy of your accepted contract will
            then be emailed to you for your records.
          </p>
          <p>
            {{"PARAGRAPH.Core FAQ Modal Notified" | translate}}
          </p>
          <p>It's that easy!</p>
        </mat-expansion-panel>

        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon class="fas fa-user-clock"></mat-icon> <strong>How long does it take for approval?</strong>
            </mat-panel-title>
            <mat-panel-description> &nbsp;</mat-panel-description>
          </mat-expansion-panel-header>
          <p class="dark">
            The application process is "real-time" and will provide your {{ "KEYWORD.patient" | translate }} with an
            instant credit decision based on the information provided. Once approved your contract is presented online
            for your review and electronic acceptance. A copy of your accepted contract will then be emailed to your
            {{ "KEYWORD.patient" | translate }} for your records and {{"PARAGRAPH.Core FAQ Modal Notified" | translate}}
            .
          </p>
        </mat-expansion-panel>

        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon class="fas fa-user-check"></mat-icon>
              <strong>What does a {{ "KEYWORD.patient" | translate }} need for a payment plan? </strong>
            </mat-panel-title>
            <mat-panel-description> &nbsp;</mat-panel-description>
          </mat-expansion-panel-header>
          <h3>Eligibility:</h3>
          <ul>
            <li>Be at least 18 years old.</li>
            <li>Have regular permanent income.</li>
            <li>Be an Australian citizen, permanent resident or have a valid visa*.</li>
          </ul>
          <p class="small">
            *
            <a
              href="https://s3-ap-southeast-2.amazonaws.com/email-graphics-smileright/docs-17/list-acceptable-visas.pdf"
              target="_blank" title="List of acceptable visas" angulartics2On="click"
              analytics-event="Download Visa Form">Valid&nbsp;visa (PDF 183kb)</a>&nbsp;(Opens in new window) To find
            out whether your Visa qualifies you please feel free to call our
            Personal Lending Centre on {{ 'BRANDING.Contact Phone' | translate }} to discuss.
          </p>
          <h3>What information does my {{ "KEYWORD.patient" | translate }} need to provide?</h3>
          <ul>
            <li>Basic personal information.</li>
            <li>Proof of Identity (If we are unable to identify you electronically)</li>
          </ul>
          <p class="dark">
            We may use the contact information that you give us to contact you to help you complete your application.
          </p>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon class="fas fa-user-edit"></mat-icon>
              <strong>What does a {{ "KEYWORD.patient" | translate }} need to apply for a Personal Loan?</strong>
            </mat-panel-title>
            <mat-panel-description> &nbsp;</mat-panel-description>
          </mat-expansion-panel-header>
          <h3>Eligibility:</h3>
          <ul>
            <li>Be at least 18 years old.</li>
            <li>Have regular permanent income.</li>
            <li>Be an Australian citizen, permanent resident or have a valid visa*.</li>
          </ul>
          <p class="small">
            *
            <a
              href="https://s3-ap-southeast-2.amazonaws.com/email-graphics-smileright/docs-17/list-acceptable-visas.pdf"
              target="_blank" title="List of acceptable visas" angulartics2On="click"
              analytics-event="Download Visa Form">Valid&nbsp;visa (PDF 183kb)</a>&nbsp;(Opens in new window) To find
            out whether your {{ "KEYWORD.patient" | translate }}s Visa qualifies
            you please feel free to call our Personal Lending Centre on {{ 'BRANDING.Contact Phone' | translate }} to
            discuss.
          </p>
          <h3>What information does my {{ "KEYWORD.patient" | translate }} need to provide?</h3>
          <ul>
            <li>Details of current financial situation (Bank statement).</li>
            <li>Proof of employment (Most recent payslip).</li>
            <li>Proof of Identity.</li>
          </ul>
          <p>
            We may use the contact information that is given us to contact your {{ "KEYWORD.patient" | translate }} to
            help you complete your application or if we need to discuss your
            {{ "KEYWORD.patient" | translate }}s application with them.
          </p>
        </mat-expansion-panel>

        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon class="fas fa-search-dollar"></mat-icon> <strong>What is the Limit for a Personal Loan?</strong>
            </mat-panel-title>
            <mat-panel-description> &nbsp;</mat-panel-description>
          </mat-expansion-panel-header>
          <p>You can apply online to borrow <strong>up to $30,000.</strong></p>
          <p>If you need an amount greater than this, please call a member of our team to discuss your needs.</p>
        </mat-expansion-panel>

        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon class="fas fa-search-dollar"></mat-icon> <strong>What is the limit of a Payment Plan?</strong>
            </mat-panel-title>
            <mat-panel-description> &nbsp;</mat-panel-description>
          </mat-expansion-panel-header>
          <p>
            Your {{ "KEYWORD.patient" | translate }} can apply online for any amount
            <strong>between $100 and $2000.</strong>
          </p>
          <p>
            If you need an amount greater then this, please call our friendly team to discuss your needs on
            <strong>{{ 'BRANDING.Contact Phone' | translate }}.</strong>
          </p>
        </mat-expansion-panel>

        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon class="fas fa-user-clock"></mat-icon>
              <strong>Is it possible to for my {{ "KEYWORD.patient" | translate }} change repayment dates?</strong>
            </mat-panel-title>
            <mat-panel-description> &nbsp;</mat-panel-description>
          </mat-expansion-panel-header>
          <p>
            If your {{ "KEYWORD.patient" | translate }} needs to change the date of your repayments please call our
            friendly support team on <strong>{{ 'BRANDING.Contact Phone' | translate }}</strong>
          </p>
        </mat-expansion-panel>

        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon class="fas fa-hand-holding-usd"></mat-icon>
              <strong>Is your {{ "KEYWORD.patient" | translate }} having trouble with repayments?</strong>
            </mat-panel-title>
            <mat-panel-description> &nbsp;</mat-panel-description>
          </mat-expansion-panel-header>
          <p>We are very sorry to hear that.</p>
          <p>
            Life doesn't always go as planned, and at {{ "BRANDING.Brand Name" | translate | titlecase }} we understand
            that
            sometimes your {{ "KEYWORD.patient" | translate }} might need some assistance and we are keen to help.
          </p>
          <p>Click <a [routerLink]="['../consumer-help-hardship']">here</a> to find out more.</p>
        </mat-expansion-panel>

        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon class="fas fa-comments"></mat-icon>
              <strong>Can my {{ "KEYWORD.patient" | translate }} nominate someone else to discuss their
                {{ "BRANDING.Brand Name" | translate | titlecase }} account?</strong>
            </mat-panel-title>
            <mat-panel-description> &nbsp;</mat-panel-description>
          </mat-expansion-panel-header>
          <p>
            If your {{ "KEYWORD.patient" | translate }} wishes to nominate someone to discuss their account with us,
            click
            <a href="/assets/docs/letter_of_authority.pdf" target="_blank" title="Letter of Authority Form"
               angulartics2On="click" analytics-event="Download Letter of Authority">
              here</a>
            to complete the following form.
          </p>
        </mat-expansion-panel>
      </mat-accordion>
    </mat-dialog-content>
  </div>
</div>
