<div class="row clearfix white-background" *ngIf="file && files && files.length > 0">
  <!-- header area -->
  <div class="row clearfix cardheader primary-gradient-img inModal" *ngIf="contentOnly != true">
    <!-- close button -->
    <button
      class="btn-close btn-clear white pull-right mat-button"
      *ngIf="isModal == true"
      (click)="closeModalEvent()"
      matDialogClose
    >
      <mat-icon>clear</mat-icon>
    </button>

    <button
      class="btn-close btn-clear mr ml white pull-right mat-button"
      *ngIf="isModal == true && isFullScreenModal != true"
      (click)="fullScreenModal()"
    >
      <mat-icon class="fas fa-expand"></mat-icon>
    </button>

    <button
      class="btn-close btn-clear mr ml white pull-right mat-button"
      *ngIf="isModal == true && isFullScreenModal == true"
      (click)="fullScreenModal()"
    >
      <mat-icon class="fas fa-compress"></mat-icon>
    </button>
    <app-ep-document-view-image
      class="file-thumbnail-content pull-right"
      *ngIf="defaultImage"
      [hideZoomButton]="true"
      [defaultPicture]="defaultImage"
    >
    </app-ep-document-view-image>

    <!-- title text -->
    <div class="row titleArea clearfix" *ngIf="showInfo == true">
      <!-- display doc label -->
      <!-- display doc description -->
      <h2 class="summary-header rm-m white">
        <span class="numberPill" *ngIf="files.length > 1">{{ fileIndex + 1 }}/{{ files.length }}</span>

        {{ file.fileName }}
        <span class="subLabel" *ngIf="file.documentType && file.documentType != 'undefined'"
        >{{ file.documentType }}
        </span>
        <span class="subLabel" *ngIf="file.contentType && file.contentType != 'undefined'"
        >{{ file.contentType }}
          <span *ngIf="file.length"> - {{ bytesToSize(file.length) }}</span>
        </span>
      </h2>

      <!-- date and time created -->
      <p class="small rm-m" *ngIf="file.uploadDate && file.uploadDate != '0000-00-00'">
        <mat-icon class="fas fa-clock"></mat-icon>
        {{ file.uploadDate | TimeLabelPast }} ago
      </p>
    </div>
    <!-- action menu -->
  </div>
  <!-- end header -->

  <!-- start scrollable content pane -->

  <mat-dialog-content class="preview-image-container" [ngClass]="{ 'no-modal-height': isModal != true }">
    <div class="full-width row clearfix">
      <div class="row clearfix text-center noOVerflow" *ngIf="file">
        <!-- json blob view component -->

        <div class="video clearfix row text-center min-video-height full-width" *ngIf="videoSource">
          <video class="full-width" controls="true" playsinline autoplay controlsList="nodownload" width="100%">
            <source src="{{ videoSource }}" type="{{ file.contentType }}"/>

            <app-empty-list-message
              class="clearfix full-width"
              [title]="'Sorry!'"
              [message]="'Your browser does not support the video tag.'"
              [isAction]="true"
              [actionIcon]="'cloud_download'"
              [actionLabel]="'Download'"
              (actionEvent)="download()"
            >
            </app-empty-list-message>
          </video>
        </div>

        <div class="video clearfix row text-center min-height full-width" *ngIf="documentSource">
          <app-empty-list-message
            class="clearfix full-width"
            [title]="''"
            [message]="'Click on button below to download the document'"
            [isAction]="true"
            [actionIcon]="'cloud_download'"
            [actionLabel]="'Download'"
            (actionEvent)="download()"
          >
          </app-empty-list-message>
        </div>

        <div class="view-container full-height min-height" *ngIf="htmlUrl" iframe-tracker>
          <iframe
            class="full-height full-width flex"
            id="htmlDoc"
            #iframe
            [attr.src]="htmlUrl"
            sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
            name="htmlDoc"
            target="_parent"
            iframe-tracker
            type="text/html"
          ></iframe>
        </div>

        <div class="view-container row text-center min-height clearfix full-width" *ngIf="pdfUrl">
          <div lass="full-width text-center clearfix mb mt  ">
            <ng2-pdfjs-viewer class="min-height pdf-view-review" [pdfSrc]="fileLink" [openFile]="false">
            </ng2-pdfjs-viewer>
          </div>
        </div>

        <div class="view-container" *ngIf="imgUrl && imgUrlString; &quot;min-height&quot;">
          <div
            class="full-width text-center clearfix white-background ep-view-img-simple-defaul"
            *ngIf="isError == true && isLoaded == false"
          >
            <app-empty-list-message
              class="clearfix full-width"
              [title]="'Sorry!'"
              [message]="'We could not display the Image'"
              [isAction]="true"
              [actionIcon]="'cloud_download'"
              [actionLabel]="'Download'"
              (actionEvent)="download()"
            >
            </app-empty-list-message>
          </div>
          <div class="image-loading" *ngIf="isLoaded == false && isError == false">
            <!-- <div class="lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div> -->

            <div class="spinner-inner">
              <div class="double-bounce1"></div>
              <div class="double-bounce2"></div>
              <!-- <div class="double-bounce3"></div> -->
            </div>
          </div>

          <div
            class="view-container full-height min-height"
            *ngIf="imgUrl"
            [hidden]="isError == true || isLoaded == false"
          >
            <pinch-zoom
              class="full-height pinchZoom pinchZoom-file min-height"
              [limit-zoom]="25"
              [wheel]="true"
              [wheelZoomFactor]="0.5"
              [double-tap]="true"
              [properties]="pinchZoomProperties"
            >
              <img [src]="imgUrl" (error)="onError()" (load)="onLoad()" />
            </pinch-zoom>
          </div>
        </div>

        <div class="hidden-iframe" *ngIf="imgUrl || pdfUrl">
          <iframe
            id="iframeDoc"
            #iframe
            *ngIf="pdfUrl"
            [attr.src]="pdfUrl"
            name="iframepdfDoc"
            type="application/pdf"
            target="_parent"
          ></iframe>

          <iframe
            id="iframeDoc"
            #iframe
            *ngIf="imgUrl"
            [attr.src]="imgUrl"
            name="iframepdfDoc"
            target="_parent"
            type="img/png"
          ></iframe>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <!-- end scrolling pane -->
  <!-- begin actions footer -->
  <mat-dialog-actions *ngIf="contentOnly != true">
    <!-- lower information area -->
    <div class="clearfix row" *ngIf="file">
      <!-- lower title -->
      <p class="detail-p p-small-icon pull-left" *ngIf="title">
        <mat-icon class="fas fa-folder-open"></mat-icon>
        {{ title }}
      </p>
      <!-- lower description -->
      <p class="detail-p p-small-icon pull-right" *ngIf="description">
        {{ description }}
        <mat-icon class="fas fa-info-circle"></mat-icon>
      </p>
    </div>

    <!-- button controls -->
    <div class="row clearfix full-width" *ngIf="file">
      <div class="row clearfix buttonrow full-width" [ngClass]="{ 'text-center': files.length <= 1 }">
        <!-- view in new window/tab -->

        <button
          class="ml btn-small"
          *ngIf="canEdit"
          [ngClass]="{ 'pull-left': files.length > 1 }"
          (click)="onClickDeleteButton(file)"
          mat-raised-button
          matTooltip="Remove file"
          color="accent"
        >
          Remove
          <mat-icon class="sml fas fa-trash"></mat-icon>
        </button>

        <button
          class="ml btn-small"
          *ngIf="isModal == true || isExternalLink == true"
          [ngClass]="{ 'pull-left': files.length > 1 }"
          (click)="openInNewTab()"
          mat-raised-button
          color="primary"
          matTooltip="View document in new Tab"
        >
          Open in new tab
          <mat-icon class="sml fas fa-external-link-alt"></mat-icon>
        </button>
        <!-- download file -->
        <button
          class="btn-small"
          [ngClass]="{ 'pull-left': files.length > 1 }"
          (click)="download()"
          mat-raised-button
          matTooltip="Download {{ file.filename }} Document"
          color="primary"
        >
          Download
          <mat-icon class="sml fas fa-download"></mat-icon>
        </button>

        <button
          class="ml btn-small"
          *ngIf="!file?.filename"
          [ngClass]="{ 'pull-left': files.length > 1 }"
          (click)="getFileDetails(file)"
          mat-raised-button
          matTooltip="Details "
          color="primary"
        >
          View
          <mat-icon class="sml fas fa-search"></mat-icon>
        </button>

        <button
          class="btn-small text-center ml mr"
          *ngIf="canAction"
          (click)="sendFile(file)"
          mat-raised-button
          type="submit"
          color="accent"
        >
          {{ actionLabel }}
        </button>

        <!-- paginate forwards -->
        <button
          class="step-back pull-right"
          *ngIf="files.length > 1"
          [disabled]="fileIndex == files.length - 1"
          (click)="getNextFile()"
          mat-mini-fab
          color="primary"
        >
          <mat-icon>keyboard_arrow_right</mat-icon>
        </button>
        <!-- paginate back -->
        <button
          class="step-back pull-right"
          *ngIf="files.length > 1"
          [disabled]="fileIndex == 0"
          (click)="getPreviousFile()"
          mat-mini-fab
          color="primary"
        >
          <mat-icon>keyboard_arrow_left</mat-icon>
        </button>
      </div>
    </div>
  </mat-dialog-actions>
  <!-- end actions footer -->
</div>
