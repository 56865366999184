import { createAction, props } from '@ngrx/store';
import { Dentist } from '../../feature/dentist/shared/dentist.type';
import { Merchant } from '../../feature/merchant/shared/types/merchant.type';
import { SessionType } from '../../shared/types/session-type.type';
import { ModuleOptions, PublicSettings } from '../../shared/types/settings';
import { User } from '../authentication/shared/shared/types/authentication.type';

export const setCurrentPracticeAction = createAction(
  '[App] Set Current Practice',
  props<{ practice: Partial<Merchant> | 'none' }>()
);

export const setCurrentUserAction = createAction('[App] Set Current Practice', props<{ user: User | 'none' }>());

export const setCurrentSessionTypeAction = createAction(
  '[App] Set Current SessionType',
  props<{ sessionType: SessionType }>()
);

export const setCurrentIsPromoterOrAdminAction = createAction(
  '[App] Set Current isPromoterOrAdmin',
  props<{ isPromoterOrAdmin: boolean }>()
);

export const setCurrentDentistAction = createAction('[App] Set Current Dentist', props<{ dentist: Dentist }>());

export const setCurrentPublicSettingsAction = createAction(
  '[App] Set Current Public Settings',
  props<{ publicSettings: PublicSettings }>()
);

export const setCurrentPublicModulesAction = createAction(
  '[App] Set Current Public Modules',
  props<{ publicModules: ModuleOptions }>()
);
