<div class="rel">
  <div class="card-header primary-gradient-img clearfix confirmModal" *ngIf="isModal == true">
    <button
      class="btn-close btn-clear mat-button smt white custom-close"
      [ngClass]="!title ? 'no-title-close' : ''"
      (click)="closeEvent()"
    >
      <mat-icon>clear</mat-icon>
    </button>
    <div class="full-width flex main-title-container" [ngClass]="!title ? 'main-icon-container-no-title' : ''">
      <div class="main-icon-container text-center">
        <mat-icon class="main-icon accent-color fas fa-window-maximize"></mat-icon>
      </div>
      <div class="row full-width title-container clearfix">
        <div class="title-container-sub">
          <h2 class="text-left title-header rm-m">
            {{ title }}
            <span class="subLabel" *ngIf="description">{{ description }}</span>
          </h2>
        </div>
      </div>
    </div>
  </div>

  <mat-dialog-content [ngClass]="{ 'no-modal-height': isModal != true }">
    <div class="full-width flex mt">
      <div class="full-width">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Link</mat-label>
          <input [(ngModel)]="link" matInput placeholder="Link" name="Link"/>
        </mat-form-field>

        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Page Title</mat-label>
          <input [(ngModel)]="title" matInput placeholder="Page Title" name="Title"/>
        </mat-form-field>

        <div class="full-width">
          <h3 class="sr-title full-width">Window Configuration</h3>
          <div class="full-width flex smb">
            <mat-form-field class="full-width mr" appearance="outline">
              <mat-label>Popup Height</mat-label>
              <input [(ngModel)]="popupHeight" matInput type="number" placeholder="Popup Height" name="popupHeight"/>
              <span matSuffix>px</span>
            </mat-form-field>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Popup Width</mat-label>
              <input [(ngModel)]="popupWidth" matInput type="number" placeholder="Popup Width" name="popupWidth"/>
              <span matSuffix>px</span>
            </mat-form-field>
          </div>
          <div class="full-width flex mb">
            <mat-form-field class="full-width mr" appearance="outline">
              <mat-label>Position from Top</mat-label>
              <input [(ngModel)]="popupTop" matInput type="number" placeholder="Position from Top" name="popupRight"/>
              <span matSuffix>px</span>
            </mat-form-field>
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Position from Left</mat-label>
              <input [(ngModel)]="popupLeft" matInput type="number" placeholder="Position from Left" name="popupLeft"/>
              <span matSuffix>px</span>
            </mat-form-field>
          </div>

          <h3 class="sr-title full-width">Popup Button</h3>

          <div class="full-width smb">
            <mat-form-field class="pull-left mr" style="width: 250px" appearance="outline">
              <mat-label>Button Text</mat-label>
              <input [(ngModel)]="buttonText" matInput placeholder="Button Text" name="ButtonText"/>
            </mat-form-field>

            <mat-form-field class="pull-left mr" style="width: 170px" appearance="outline">
              <mat-label>Font Size</mat-label>

              <mat-select [(ngModel)]="buttonFontSize" name="buttonFontSize" placeholder="Font Size">
                <mat-option *ngFor="let m of buttonFontSizepx" [value]="m">
                  {{ m }}
                </mat-option>
                <hr/>
                <mat-option *ngFor="let m of buttonFontSizerem" [value]="m">
                  {{ m }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-checkbox
              class="pull-left mt mr sml"
              [(ngModel)]="isLargeButton"
              name="isLargeButton"
              style="width: 150px"
            >
              Large Button
            </mat-checkbox>
          </div>

          <div class="full-width mb">
            <input
              class="color-input-sub pull-left"
              [(colorPicker)]="buttonBackground"
              [style.background]="buttonBackground"
              [cpOutputFormat]="'hex'"
              name="buttonBackground"
            />
            <p class="mr rm-mb sml pull-left">Background Colour</p>

            <input
              class="color-input-sub pull-left"
              [(colorPicker)]="buttonColor"
              [style.background]="buttonColor"
              [cpOutputFormat]="'hex'"
              name="buttonColor"
            />

            <p class="mr rm-mb sml pull-left">Color</p>

            <button
              class="ml pull-left btn-large"
              *ngIf="isLargeButton == true"
              [attr.style]="
                'color:' +
                  buttonColor +
                  ' !important; background:' +
                  buttonBackground +
                  ' !important; font-size:' +
                  buttonFontSize +
                  ' !important;' | safeStyle
              "
              mat-raised-button
              color="primary"
            >
              {{ buttonText }}
            </button>

            <button
              class="ml pull-left btn"
              *ngIf="isLargeButton != true"
              [attr.style]="
                'color:' +
                  buttonColor +
                  ' !important; background:' +
                  buttonBackground +
                  ' !important; font-size:' +
                  buttonFontSize +
                  ' !important;' | safeStyle
              "
              mat-raised-button
              color="primary"
            >
              {{ buttonText }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <hr/>
  <div class="drop-button text-center mt full-width" *ngIf="isModal == true">
    <button
      class="pull-right btn-large"
      [disabled]="!link"
      (click)="inserResult(false)"
      mat-raised-button
      color="accent"
    >
      Insert
    </button>
    <button
      class="pull-right btn-large mr"
      [disabled]="!link"
      (click)="inserResult(true)"
      mat-raised-button
      color="primary"
    >
      Copy
    </button>

    <button
      class="pull-right btn-large mr"
      [disabled]="!link"
      (click)="copyClickEvent()"
      mat-raised-button
      color="primary"
    >
      Copy Click Event
    </button>

    <button class="pull-left btn-large" [disabled]="!link" (click)="previewBuild()" mat-raised-button color="primary">
      Preview
    </button>
  </div>
</div>
