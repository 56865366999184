/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./generate-qr.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/angularx-qrcode/angularx-qrcode.ngfactory";
import * as i3 from "angularx-qrcode";
import * as i4 from "./generate-qr.component";
var styles_GenerateQRComponent = [i0.styles];
var RenderType_GenerateQRComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GenerateQRComponent, data: {} });
export { RenderType_GenerateQRComponent as RenderType_GenerateQRComponent };
export function View_GenerateQRComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "qrcode", [["class", "qr-code"]], null, null, null, i2.View_QRCodeComponent_0, i2.RenderType_QRCodeComponent)), i1.ɵdid(1, 573440, null, 0, i3.QRCodeComponent, [i1.Renderer2], { qrdata: [0, "qrdata"], errorCorrectionLevel: [1, "errorCorrectionLevel"], width: [2, "width"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "qrcode", [["class", "downloadqr"]], null, null, null, i2.View_QRCodeComponent_0, i2.RenderType_QRCodeComponent)), i1.ɵdid(3, 573440, null, 0, i3.QRCodeComponent, [i1.Renderer2], { qrdata: [0, "qrdata"], errorCorrectionLevel: [1, "errorCorrectionLevel"], width: [2, "width"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.source; var currVal_1 = "M"; var currVal_2 = _co.size; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.source; var currVal_4 = "M"; var currVal_5 = _co.downloadSize; _ck(_v, 3, 0, currVal_3, currVal_4, currVal_5); }, null); }
export function View_GenerateQRComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-generate-qr", [], null, null, null, View_GenerateQRComponent_0, RenderType_GenerateQRComponent)), i1.ɵdid(1, 114688, null, 0, i4.GenerateQRComponent, [i1.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GenerateQRComponentNgFactory = i1.ɵccf("app-generate-qr", i4.GenerateQRComponent, View_GenerateQRComponent_Host_0, { source: "source", size: "size", downloadSize: "downloadSize", download: "download", downloadLabel: "downloadLabel" }, {}, []);
export { GenerateQRComponentNgFactory as GenerateQRComponentNgFactory };
